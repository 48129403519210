import React from "react";

import { Helmet } from "react-helmet";

import FullScreenLoader from "shared/components/FullScreenLoader";
import Icon from "shared/components/Icon";
import { UnderlineLink } from "shared/components/Links";
import { APP_VERO_URL } from "shared/config/constants";
import { SurveyStatus } from "shared/interfaces/api";
import SurveyBackground from "surveys/components/Backgrounds/SurveyBackground";
import { GetSurveyResponse } from "surveys/interfaces/api";

import LandingStep from "./LandingStep";
import QuestionStep from "./QuestionStep";
import { Container, ContentContainer, Modal } from "./styled";

interface VerificationOfResidencyProps {
  survey: GetSurveyResponse;
}

const VerificationOfResidency = ({ survey }: VerificationOfResidencyProps) => {
  return (
    <>
      <Helmet>
        <title>Verification Of Residency</title>
      </Helmet>
      <Container>
        <ContentContainer>
          <div className="powered-by">
            <span>
              <div>Powered by</div>
              <Icon.LogoIcon className="logo" />
            </span>
            <span className="learn-more">
              Learn more {/* @ts-ignore */}
              <UnderlineLink target="_blank" href={APP_VERO_URL}>
                <b>Click here</b>
              </UnderlineLink>
              .
            </span>
          </div>
          <Modal className="modal">
            {survey?.status === SurveyStatus.NOTIFIED && (
              <LandingStep applicantName={survey?.applicantName} />
            )}
            {survey?.status === SurveyStatus.VERIFIED && (
              <QuestionStep survey={survey} />
            )}
            {survey?.status === SurveyStatus.SUBMITTED && <FullScreenLoader />}
          </Modal>
        </ContentContainer>
        <SurveyBackground />
      </Container>
    </>
  );
};

export default VerificationOfResidency;
