import * as Yup from "yup";

import { ZipCodeInput } from "shared/components/Form";
import { validAddress } from "shared/forms/validation/fieldValidators";

export const CREATE_PROPERTY_FIELDS = Object.freeze({
  propertyType: "propertyType",
  name: "name",
  address: "address",
  city: "city",
  state: "state",
  zipcode: "zipcode",
});

export const CreatePropertyValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required").max(100),
  propertyType: Yup.string().required("Property type is required"),
  address: validAddress.required("Street address is required"),
  city: Yup.string().required("City is required").max(50),
  state: Yup.string().required("State is required"),
  zipcode: ZipCodeInput.schema.required("Zip code is required"),
});

export const initialValuesCreateProperty = {
  name: undefined,
  propertyType: undefined,
  address: undefined,
  city: undefined,
  state: undefined,
  zipcode: undefined,
};
