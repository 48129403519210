import React from "react";

import { Col } from "antd-latest";

import { VerificationItemTile } from "shared/components/Verification";

import FlexContainer from "../FlexContainer";
import Icon from "../Icon";
import Spacer from "../Spacer";
import Tile from "../Tile";
import { RegularText, SmallTitle } from "../Typography";

import {
  InlineStatusRow,
  MediumTextPrimary,
  NoRequirementSectionTile,
} from "./styled";

interface NoRequirementSectionProps {
  name: string;
}

const NoRequirementSection = ({ name }: NoRequirementSectionProps) => (
  <VerificationItemTile
    verificationStatus={
      <InlineStatusRow type="flex" align="middle">
        <Col xs={24} md={8}>
          <SmallTitle strong>{name}</SmallTitle>
        </Col>
        <Spacer hideGteMd />
        <Col xs={24} md={14}>
          <FlexContainer alignItems="center">
            <RegularText>Verification not required</RegularText>
          </FlexContainer>
        </Col>
        <Spacer hideGteMd />
      </InlineStatusRow>
    }
  >
    <Tile.Inner inset>
      <NoRequirementSectionTile
        alignItems="center"
        flexDirection="column"
        justifyContent="space-around"
      >
        <Icon.GrayInfoIcon />
        <MediumTextPrimary>
          {name} is not a requirement in the prequalification settings
        </MediumTextPrimary>
      </NoRequirementSectionTile>
    </Tile.Inner>
  </VerificationItemTile>
);

export default NoRequirementSection;
