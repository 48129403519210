import React from "react";

import PropTypes from "prop-types";

import { Avatar as StyledAvatar } from "./styled";
import { SIZES } from "./utils";

interface AvatarProps {
  alt?: string;
  src?: string;
  size?: typeof SIZES | any;
  [key: string]: any;
}

const Avatar = ({ src, alt, size, ...props }: AvatarProps) => {
  const initials = alt && alt.match(/\b(\w)/g).join("");

  return (
    <StyledAvatar
      data-testid="avatar"
      shape="square"
      src={src}
      alt={alt}
      size={size}
      {...props}
    >
      {initials}
    </StyledAvatar>
  );
};

Avatar.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string,
  size: PropTypes.oneOf(Object.values(SIZES)),
};

Avatar.defaultProps = {
  size: "default",
  alt: undefined,
  src: undefined,
};

Avatar.SIZES = SIZES;

export default Avatar;
