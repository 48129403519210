import React from "react";

import ROUTES from "renter/config/routes";
import { Application } from "renter/interfaces/api/application";
import { Button } from "shared/components/Button";
import FlexContainer from "shared/components/FlexContainer";
import {
  APPLICATION_STATUSES,
  DEAL_UI_STATUS_CODES,
} from "shared/config/constants";
import { transformRoute } from "shared/utils/routing";

const getAdditionalInfoMessage = (application: Application) => {
  const { dealUiStatus, status, hasExtraUnpaidPayments, isSubmitted } =
    application;
  if (
    dealUiStatus === DEAL_UI_STATUS_CODES.condApproved &&
    status !== APPLICATION_STATUSES.submitted
  ) {
    return "Additional information required.";
  }

  if (hasExtraUnpaidPayments && isSubmitted) {
    return "Additional payment is requested.";
  }

  return "";
};

const getMakePaymentActions = ({
  application,
  history,
}: {
  application: Application;
  history: { push: (route: string) => void };
}) => {
  const onViewClick = () =>
    history.push(
      transformRoute(ROUTES.application, {
        id: application.id,
      })
    );

  const onPaymentClick = () =>
    history.push(
      transformRoute(ROUTES.applicationPayment, {
        id: application.id,
      })
    );

  return () => (
    <FlexContainer nodesMargin="0 4px">
      {/* @ts-ignore */}
      <Button type="secondary" onClick={onViewClick}>
        View
      </Button>
      {/* @ts-ignore */}
      <Button type="primary" onClick={onPaymentClick}>
        Make Payment
      </Button>
    </FlexContainer>
  );
};

const showFooterActions = (dealStatus: DEAL_UI_STATUS_CODES) =>
  [
    DEAL_UI_STATUS_CODES.invitesSent,
    DEAL_UI_STATUS_CODES.applicationsIncomplete,
    DEAL_UI_STATUS_CODES.partiallySubmitted,
    DEAL_UI_STATUS_CODES.submitted,
  ].includes(dealStatus);

export { getAdditionalInfoMessage, getMakePaymentActions, showFooterActions };
