import isString from "lodash/isString";
import moment from "moment";

import { FILE_NAME_DATE } from "shared/config/constants";
import pdfThumbUrl from "shared/images/pdfThumbUrl";

export const downloadFileByDataURL = (dataUrl, fileName) => {
  const link = document.createElement("a");
  link.href = dataUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

// Download PDF

export const downloadTextFile = (contentString, filename) => {
  const link = document.createElement("a");
  const blobData = new Blob([contentString], {
    type: "text/plain",
  });

  link.setAttribute("href", window.URL.createObjectURL(blobData));
  link.setAttribute("download", filename);

  link.dataset.downloadurl = ["text/plain", link.download, link.href].join(":");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadPDF = (content, name) => {
  const link = document.createElement("a");
  link.href = `data:application/octet-stream;base64,${content}`;
  link.setAttribute("download", name);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const arrayBufferToBase64 = (buffer) => {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const length = bytes.byteLength;

  for (let i = 0; i < length; i += 1) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

export const base64ToArrayBuffer = (base64) => {
  const binary_string = window.atob(base64);
  const len = binary_string.length;
  const bytes = new Uint8Array(len);

  for (let i = 0; i < len; i += 1) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
};

export const sanitizeFileName = ({
  fileName = "document",
  extension = ".pdf",
}) => {
  let bareName = isString(fileName) && fileName ? fileName : "document";
  const validExtension = isString(extension) && extension ? extension : ".pdf";
  const splitter = validExtension.startsWith(".") ? "" : ".";

  if (bareName.toLowerCase().endsWith(extension)) {
    bareName = bareName.slice(0, bareName.length - extension.length);
  }

  bareName = bareName.replace(/[^a-zA-Z\d\_\.\-]/g, "_") || "document";

  return `${bareName}${splitter}${validExtension}`;
};

export const fileNameFromWords = ({
  words = [],
  extension = ".pdf",
  splitter = "_",
  timestamp = false,
}) => {
  const date = moment().format(FILE_NAME_DATE);
  const parts = timestamp ? [...words, date] : words;

  const bareName = parts
    .map((item) => (item || "").replace(/[^a-zA-Z\d\_\.\-]/g, "-"))
    .join(splitter);
  return `${bareName}${extension}`;
};

export const getThumbUrl = (file) =>
  file?.name?.match(/\.pdf$/) ? pdfThumbUrl : file?.url;
