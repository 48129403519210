import { FileUpload } from "admin/interfaces";
import { DocumentDraft } from "admin/pages/Documents/interfaces";
import ConfirmationModal from "shared/components/Modals/ConfirmationModal/ConfirmationModalContainer";
import useModal from "shared/hooks/useModal";
import { useUploadValidation } from "shared/hooks/useUploadValidation";

const useFileUpload = () => {
  const { openModalDialog } = useModal();
  const { isFileNameValid } = useUploadValidation();

  const draftIsExist = (drafts: DocumentDraft[], fileName: string) => {
    /** Note: splitting draft name "draft(1).pdf" on separate groups:
     * 0). full match - "draft(1).pdf"
     * 1). draft(
     * 2). 1
     * 3). ).pdf */
    const versionRegex = /(.+\()(\d+)(\)\..{3,4}$)/;
    const version = fileName?.match(versionRegex)?.[2];
    let draftName = "";

    if (version) {
      const nextVersion = Number(version) + 1;
      draftName = fileName?.replace(versionRegex, `$1${nextVersion}$3`);
    } else {
      /** Note: Adding version (1) to the draft */
      draftName = fileName?.replace(/(.+)(\..{3,4}$)/, "$1(1)$2");
    }

    return drafts.find((doc) => doc.name === draftName);
  };

  const fileUpload = ({
    file,
    drafts = [],
    isDraftSuccess,
    isNoDaftUpload,
  }: FileUpload) => {
    const { name } = file;
    if (!isFileNameValid(name, true)) {
      return;
    }

    const draft = draftIsExist(drafts, name);

    if (draft) {
      const context = {
        title: "New document uploading",
        subtitle: `Document: ${name}`,
        message: `The draft of this document has already exists. Would you like to continue to work with the draft?`,
        submitButtonLabel: "Ok",
      };

      openModalDialog(ConfirmationModal, context).afterClose.then((result) => {
        if (result) {
          isDraftSuccess(draft);
        }
      });
    } else {
      isNoDaftUpload();
    }
  };

  return { fileUpload };
};

export default useFileUpload;
