import React from 'react'
import _ from 'lodash'
import { buildClassName } from 'shared/utils/vega'
import { Timestamp } from 'shared/components/vega/Timestamp'

const ServiceCard = ({ label = undefined, value = undefined, status = undefined, timestamp = undefined, path = undefined, children = null }) => {
  const title = !_.isNil(value) && _.toString(value)
  const resolvedStatus = !_.isNil(status) && (_.isString(status) ? status : !!status ? 'pass' : 'fail')

  const body = <div className={buildClassName(['vega flex gap small column card service', !_.isNil(status) && 'status-bar', resolvedStatus, path && 'link'])}>
    <div className='vega flex gap small column'>
      {title && <span className='vega title'>{title}</span>}
      {children && <div className='vega flex gap small column'>{children}</div>}
      {timestamp && <Timestamp timestamp={timestamp} />}
    </div>

    {label && <div className='vega label'>{label}</div>}
  </div>

  if (path) {
    return <a href={path}>{body}</a>
  }

  return body
}

export default ServiceCard
