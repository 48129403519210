import styled from "@emotion/styled";

const YardiRemovePropertyConfirmationContent = styled("div")({
  flexDirection: "column",
  display: "flex",
  ".title": {
    marginTop: "8px",
    marginBottom: 0,
  },
  ".content": {
    marginTop: "25px",
    marginBottom: "25px",
  },
});

const Text = styled("span")((props) => ({
  fontSize: "15px",
  fontWeight: `${props.fontWeight || 300}`,
}));

export { YardiRemovePropertyConfirmationContent, Text };
