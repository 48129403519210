import React, { useEffect, useRef, useState } from "react";

import {
  useGetAllUnitsSimple,
  useGetYardiUnit,
  useYardiResolveLinkUnit,
} from "manager/hooks/api";
import useModal from "shared/hooks/useModal";
import { setFormValuesAndValidate } from "shared/utils/forms";

import { RESOLVE_UNIT_LINK_OPTIONS } from "./yardiAlertsResolvers.config";
import YardiLinkUnitResolverModal from "./YardiLinkUnitResolverModal";

const YardiLinkUnitResolverModalContainer = () => {
  const { currentModalContext } = useModal();
  const { yardiProperty, alert, provider } = currentModalContext;
  const { yardiUnit } = useGetYardiUnit({
    provider,
    yardiPropertyId: yardiProperty?.id,
    yardiUnitId: alert.refPk,
  });
  const veroPropertyId = yardiProperty?.property?.id;
  const { units: veroUnits } = useGetAllUnitsSimple(veroPropertyId);
  const { resolveAlertLinkUnit } = useYardiResolveLinkUnit();
  const [currentSelection, setCurrentSelection] = useState(
    RESOLVE_UNIT_LINK_OPTIONS.CREATE_NEW_UNIT
  );
  const formikRef = useRef();
  const isUnitAvailable = yardiUnit?.isActive;

  const submit = async (values) => {
    let payload;
    if (currentSelection === RESOLVE_UNIT_LINK_OPTIONS.LINK_EXISTING_UNIT) {
      const { veroUnitId, unitListing } = values;
      const unit = { id: veroUnitId };
      payload = {
        unit,
        unitListing,
      };
    } else if (currentSelection === RESOLVE_UNIT_LINK_OPTIONS.CREATE_NEW_UNIT) {
      const { unitListing, ...unitData } = values;
      const unit = {
        ...unitData,
        property: veroPropertyId,
      };
      payload = {
        unit,
        unitListing,
      };
    }
    await resolveAlertLinkUnit({ provider, alertId: alert?.id, body: payload });
  };

  useEffect(() => {
    if (!!formikRef.current && currentSelection) {
      let values = {};
      if (
        currentSelection === RESOLVE_UNIT_LINK_OPTIONS.CREATE_NEW_UNIT &&
        yardiProperty
      ) {
        values = {
          name: yardiUnit?.name,
          unitType: yardiUnit?.unitType,
          bedrooms: yardiUnit?.bedrooms,
          bathrooms: yardiUnit?.bathrooms,
          squareFeet: yardiUnit?.squareFeet,
        };
      }

      const unitListingObj = isUnitAvailable
        ? {
            unitListing: {
              rent: yardiUnit?.rent,
              securityDeposit: yardiUnit?.securityDeposit,
              unitAvailableOn: yardiUnit?.unitAvailableOn,
            },
          }
        : {};
      values = { ...values, ...unitListingObj };

      setFormValuesAndValidate(formikRef, values);
    }
  }, [formikRef.current, yardiUnit, currentSelection]);

  const yardiUnitName = `${yardiUnit?.name}, ${yardiProperty?.name}`;

  return (
    <YardiLinkUnitResolverModal
      yardiUnitName={yardiUnitName}
      currentSelection={currentSelection}
      setCurrentSelection={setCurrentSelection}
      veroUnits={veroUnits}
      submit={submit}
      createListing={isUnitAvailable}
      formikRef={formikRef}
    />
  );
};

export default YardiLinkUnitResolverModalContainer;
