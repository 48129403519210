import { Col, Row } from 'antd'
import _ from 'lodash'

import Collapse from 'shared/components/Collapse'
import { EvictionsType } from 'shared/components/CreditAndBackgroundCheck/interfaces'
import {
  CriminalRecordsPeriod,
  CriminalRecordsTitleContainer,
} from 'shared/components/CreditAndBackgroundCheck/styled'
import Spacer from 'shared/components/Spacer'
import Tile from 'shared/components/Tile'
import { SmallText } from 'shared/components/Typography'

import { printDollarsFromCents } from 'shared/utils/dollar-print'
import { calcTileInnerBorderBottom, formatDate } from 'shared/utils/ui'

type EvictionsProps = {
  isEvictions?: boolean
  evictions?: EvictionsType
}

const Evictions = ({ isEvictions, evictions }: EvictionsProps) => {
  const evictionsByState = evictions?.evictionRecordGroup || []

  return (
    <>
      {isEvictions && (
        <Tile
          header={{
            title: 'Eviction Related Proceedings',
          }}
        >
          {evictionsByState.map((stateEvictions, stateIndex) => (
            <Collapse
              key={stateEvictions.state}
              small
              header={({ CollapseIcon, collapsed }) => (
                <Tile.Inner
                  borderBottom={
                    calcTileInnerBorderBottom(evictionsByState, stateIndex) ||
                    !collapsed
                  }
                >
                  <CriminalRecordsTitleContainer
                    data-testid={`housing-court-records-${stateEvictions.state}`}
                  >
                    <div>
                      <strong>State {stateEvictions.state},</strong>
                    </div>
                    <CriminalRecordsPeriod>
                      {stateEvictions.endDate
                        ? `${formatDate(
                            stateEvictions.startDate,
                          )} - ${formatDate(stateEvictions.endDate)}`
                        : `${formatDate(stateEvictions.startDate)}`}
                    </CriminalRecordsPeriod>
                    <CollapseIcon />
                  </CriminalRecordsTitleContainer>
                </Tile.Inner>
              )}
            >
              {stateEvictions.records.map((record, recordIndex) => {
                const recordKey = `${stateEvictions.state}-${recordIndex}`
                return (
                  <Tile.Inner
                    key={recordKey}
                    data-testid={`housing-court-record-${recordIndex}`}
                    inset
                    borderBottom={
                      stateIndex === evictionsByState.length - 1
                        ? calcTileInnerBorderBottom(
                            stateEvictions.records,
                            recordIndex,
                          )
                        : true
                    }
                  >
                    <Row type="flex">
                      <Col xs={12} md={4} lg={3} xl={2}>
                        <Tile.Label>Date</Tile.Label>
                        {formatDate(record.date)}
                      </Col>
                      <Col xs={12} md={8} lg={4}>
                        <Tile.Label>Record Source</Tile.Label>
                        {_.get(record, 'recordSource')}
                      </Col>
                      <Col xs={12} md={4} lg={3} xl={2}>
                        <Tile.Label>File Number</Tile.Label>
                        {record.fileNumber}
                      </Col>
                      <Col xs={12} md={4} lg={3} xl={2}>
                        <Tile.Label>Internal Record ID</Tile.Label>
                        {_.get(record, 'internalRecordId')}
                      </Col>
                      <Col xs={12} md={8} lg={4}>
                        <Spacer hideGteMd />
                        <Tile.Label>Case Type</Tile.Label>
                        {record.caseType}
                      </Col>
                      <Col xs={24} md={8} lg={6} xl={5}>
                        <Spacer hideGteMd />
                        <Tile.Label>Court Reported Amount*</Tile.Label>
                        {printDollarsFromCents(
                          _.get(record, 'judgementAmount'),
                        )}
                      </Col>
                      <Col xs={12} md={8} lg={4}>
                        <Spacer hideGteMd />
                        <Tile.Label>Release Date</Tile.Label>
                        {formatDate(_.get(record, 'releaseDate'))}
                      </Col>
                    </Row>
                  </Tile.Inner>
                )
              })}
            </Collapse>
          ))}
          {evictionsByState.length > 0 && (
            <Tile.Inner borderBottom>
              <Row type="flex">
                <Col xs={12} md={12} lg={12} xl={12}>
                  <SmallText>
                    *Court Reported Amount means the amount reported by the
                    court as a final judgment amount, or an amount reflecting
                    full or partial satisfaction of such judgment and may
                    include attorney or court fees or costs.
                  </SmallText>
                </Col>
              </Row>
            </Tile.Inner>
          )}
        </Tile>
      )}
    </>
  )
}

export default Evictions
