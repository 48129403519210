import dayjs from "dayjs";
import Icon from "shared/components/Icon";
import Table from "shared/components/Table";
import { DATE_TIME_FORMAT } from "shared/config/constants";
import { printDollarsFromCents } from "shared/utils/dollar-print";
import { Fee } from "../interfaces";

const UnitFees = ({ fees, loading = false }) => (
  <Table
    columns={[
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
      },
      {
        title: "Category",
        dataIndex: "category",
        key: "category",
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
      },
      {
        title: "Can Apply To",
        dataIndex: "canApplyTo",
        key: "canApplyTo",
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
      },
      {
        title: "Provider",
        dataIndex: "provider",
        key: "provider",
      },
      {
        title: "Account",
        dataIndex: "account",
        key: "account",
      },
      {
        title: "Pulled At",
        dataIndex: "createdAt",
        key: "createdAt",
      },
    ]}
    rows={(fees || []).map((fee: Fee) => ({
      key: fee.identifier,
      values: [
        fee.name,
        fee.description,
        fee.category,
        fee.type,
        fee.canApplyTo.join(", "),
        printDollarsFromCents(fee.amount),
        fee.provider,
        fee.account,
        <small>{dayjs(fee.createdAt).format(DATE_TIME_FORMAT)}</small>,
      ],
    }))}
    columnBreakpoints={{
      xs: {
        column1: "name",
        column2: "amount",
      },
      sm: undefined,
    }}
    placeholder={{
      Icon: Icon.NoDataIcon,
      label: "Fees",
    }}
    pagination={false}
    noClickableRows
    alt
    loading={loading}
  />
);

export default UnitFees;
