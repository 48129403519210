import React from "react";

import Icon from "shared/components/Icon";
import Spacer from "shared/components/Spacer";
import { MediumTitle } from "shared/components/Typography";

import { NoVerificationsPlaceholderContent } from "./styled";

export interface NoVerificationsPlaceholderProps {
  message?: string;
  column?: boolean;
}

const NoVerificationsPlaceholder = ({
  message = "No verifications available",
  column = false,
}: NoVerificationsPlaceholderProps) => {
  return (
    <NoVerificationsPlaceholderContent
      alignItems="center"
      justifyContent="center"
      flexDirection={column ? "column" : "row"}
    >
      <Icon.NoVerificationsAvailable />
      {column && <Spacer size={Spacer.SIZES.sm} />}
      <MediumTitle className="message">{message}</MediumTitle>
    </NoVerificationsPlaceholderContent>
  );
};

export default NoVerificationsPlaceholder;
