import React, { ChangeEvent, ElementType, FocusEventHandler } from "react";

import { useField } from "formik";

import { createEventLikeObject } from "shared/utils/dom";

const isEvent = (data) =>
  data instanceof Event || data?.nativeEvent instanceof Event;

export type VeroFormFieldProps = {
  as: ElementType;
  name: string;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onChange?: (value: ChangeEvent<HTMLInputElement> | any) => void;
  [key: string]: unknown;
};

export const VeroFormField: React.FC<VeroFormFieldProps> = ({
  name,
  as: Component,
  onChange: onChangeCallback = undefined,
  onBlur: onBlurCallback = undefined,
  ...props
}) => {
  const [field, meta] = useField(name);

  const onChange = (data) => {
    const event = isEvent(data) ? data : createEventLikeObject(name, data);
    field.onChange(event);
  };

  const onBlur = () => field.onBlur(createEventLikeObject(name, field.value));

  const error = (meta.touched && meta.error) || undefined;

  return (
    <Component
      name={name}
      /** Note: Some components, such as Tooltip, bypass events with undefined
       * (onBlur: undefined, onMouseEvent: undefined,...etc.)
       *  that overrides the default implementation with {...props}
       */
      onChange={onChangeCallback || onChange}
      onBlur={onBlurCallback || onBlur}
      value={field.value}
      error={error}
      checked={field.checked}
      {...props}
    />
  );
};
