import React, { ReactElement } from "react";

import { Col, Row } from "antd";
import { Form, Formik } from "formik";

import Banner from "shared/components/Banner";
import { VeroFormField } from "shared/components/Form";
import { Modal } from "shared/components/Modal";
import RadioGroupBoxedList from "shared/components/RadioGroupBoxedList";
import Spacer from "shared/components/Spacer";
import { RegularText } from "shared/components/Typography";

import {
  CloseListingValidationSchema,
  INITIAL_VALUES,
  CLOSE_LISTING_TYPE,
  CLOSE_LISTING_FIELDS,
} from "./closeListing.config";
import CloseListingInnerFormElements from "./CloseListingInnerFormElements";
import { CustomLabel } from "./styled";

import { ICloseListingProps } from ".";

const CloseListingModal = ({
  unit,
  onSubmit,
}: ICloseListingProps): ReactElement => {
  return (
    <Formik
      validationSchema={CloseListingValidationSchema}
      validateOnChange
      validateOnMount
      initialValues={INITIAL_VALUES}
      onSubmit={(values, formikApi) => {
        onSubmit({ ...values }, formikApi);
      }}
    >
      {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
      {({ setFieldValue, isSubmitting, errors, values, submitForm }) => (
        <Form>
          {/* @ts-ignore */}
          <Modal
            title="Close the unit listing"
            subtitle={
              <RegularText data-testid="subtitle">
                Unit: {unit.name}, {unit.property.address}
              </RegularText>
            }
            submit={submitForm}
            submitting={isSubmitting}
            closeOnSubmit={false}
            submitButtonLabel="Close Listing"
            width={500}
          >
            <Modal.Body>
              <Row gutter={[20, 20]}>
                <Col>
                  <CustomLabel>
                    Select your reason for closing this listing
                  </CustomLabel>
                  <Spacer size={Spacer.SIZES.sm} />
                  {/* @ts-ignore */}
                  <VeroFormField
                    as={RadioGroupBoxedList}
                    id={CLOSE_LISTING_FIELDS.unitListingCloseType}
                    name={CLOSE_LISTING_FIELDS.unitListingCloseType}
                    data-testid={CLOSE_LISTING_FIELDS.unitListingCloseType}
                    listItems={[
                      {
                        id: CLOSE_LISTING_TYPE.CLOSE_LISTING,
                        title: "Mark this unit as having no listing",
                      },
                      {
                        id: CLOSE_LISTING_TYPE.MARK_UNIT_AS_RENTED,
                        title: "Mark this unit as rented",
                      },
                    ]}
                    currentSelection={values.unitListingCloseType}
                    onChangeSelection={(value) => {
                      setFieldValue("unitListingCloseType", value);
                    }}
                  />
                  <Spacer size={Spacer.SIZES.sm} />
                </Col>
              </Row>
              {values.unitListingCloseType ===
                CLOSE_LISTING_TYPE.MARK_UNIT_AS_RENTED && (
                <CloseListingInnerFormElements />
              )}
              <Row>
                <Col>
                  <Spacer size={Spacer.SIZES.sm} />
                  <Banner message="Once the listing is closed, you will no longer be able to invite applicants or manage applicants and leases until you create a new listing." />
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default CloseListingModal;
