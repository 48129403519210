/* eslint-disable no-return-assign */
import React from "react";

import { useGetApplicationInvitation } from "renter/hooks/api";
import { useTranslation } from "renter/hooks/useTranslation";
import { useActiveUser, useListApplicantPayments } from "shared/hooks/api";
import { getQueryParams } from "shared/utils/routing";

import ApplicantPayments from "./ApplicantPayments";

const ApplicantPaymentsContainer = () => {
  const { t } = useTranslation({ keyPrefix: "renter-app" });

  const {
    applicationId,
    deal,
    company,
  }: {
    applicationId?: string | number;
    deal?: number;
    company?: string;
  } = getQueryParams();

  const { activeUser } = useActiveUser();

  const applicant = `${company}/${deal}:email/${activeUser?.email}`;

  const { payments, isLoading: isPaymentsLoading } =
    useListApplicantPayments(applicant);

  const { application, isApplicationLoading } =
    useGetApplicationInvitation(applicationId);

  return (
    <ApplicantPayments
      name={t("payments.name")}
      loading={isApplicationLoading || isPaymentsLoading}
      payments={payments}
      application={application}
      applicationId={applicationId}
    />
  );
};

export default ApplicantPaymentsContainer;
