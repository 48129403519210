import React from "react";

import { Steps } from "antd";

import { Step } from "./styled";

export interface VerticalStaticNumberedStepsProps {
  className?: string;
  items: {
    label?: React.ReactNode;
    content?: React.ReactNode;
  }[];
}

const VerticalStaticNumberedSteps = ({
  items,
  className = undefined,
}: VerticalStaticNumberedStepsProps) => {
  return (
    <Steps
      className={className}
      current={-1}
      labelPlacement="vertical"
      direction="vertical"
      size="small"
    >
      {items.map((item, i) => {
        // NOTE: just need some key, the list is static
        const key = i + 1;
        return <Step title={item.label} description={item.content} key={key} />;
      })}
    </Steps>
  );
};

export default VerticalStaticNumberedSteps;
