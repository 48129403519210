import styled from "@emotion/styled";

import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";
import { AlignedCenterFlexPartial } from "shared/styles/commonStyles";

const commonCol = {
  ...AlignedCenterFlexPartial,
  padding: "12px 24px",
};

const headerHeight = "67px";

const secondColSmall = "380px";
const firstColSmall = `calc(100% - ${secondColSmall})`;

type Props = {
  isCancelledDeal: any;
  isDeclinedDeal: any;
};

const getDealHeaderSecondColBg = (props: Props) => {
  if (props.isDeclinedDeal) {
    return COLORS.lightRed2;
  }

  if (props.isCancelledDeal) {
    return COLORS.lightGray4;
  }

  return COLORS.violetTheme.lightViolet;
};

const DealHeaderContent = styled.div((props: Props) => ({
  ...AlignedCenterFlexPartial,
  color: COLORS.white,
  width: "100%",
  alignItems: "stretch",
  "& > div": {
    minHeight: headerHeight,
  },
  fontWeight: 500,
  ".deal-header-first-col": {
    ...commonCol,
    gap: "10px",
    flexWrap: "wrap",
    // width: firstColLarge,
    justifyContent: "space-between",
    flex: 1,
    backgroundColor: COLORS.lightViolet,
    borderRadius: "4px 0 0 4px",
    ".deal-header-title-container": {
      ...AlignedCenterFlexPartial,
      marginRight: "18px",
      fontWeight: 600,
      fontSize: "17px",
      ".deal-header-title-text-wrapper": {
        maxWidth: "100%",
      },
    },
    ".integration-mark": {
      marginLeft: "23px",
      minWidth: "20px",

      [MEDIA_QUERIES.w.ltMd]: {
        marginLeft: "0px",
      },
    },
    ".deal-header-assign-unit": {
      marginLeft: "30px",
      whiteSpace: "nowrap",
      color: COLORS.violetTheme.lightGreen,

      [MEDIA_QUERIES.w.ltMd]: {
        marginLeft: "10px",
        marginRight: "30px",
      },
    },
  },
  ".deal-header-second-col": {
    ...commonCol,
    justifyContent: "space-between",
    backgroundColor: getDealHeaderSecondColBg(props),
    borderRadius: "0 4px 4px 0",
    ".deal-header-inactive": {
      ...AlignedCenterFlexPartial,
      span: {
        marginLeft: "16px",
        color: COLORS.white,
      },
      "&.cancelled": {
        span: {
          color: COLORS.gray2,
        },
      },
    },
    ".deal-header-actions": {
      ...AlignedCenterFlexPartial,
      ".deal-header-pdf-download": {
        marginRight: "30px",
      },
    },
  },
  [MEDIA_QUERIES.w.ltXl]: {
    ".deal-header-first-col": {
      width: firstColSmall,
    }
  },
  [MEDIA_QUERIES.w.ltMd]: {
    flexDirection: "column",
    height: "auto",
    maxHeight: "none",
    ".deal-header-first-col": {
      alignItems: "stretch",
      flexDirection: "column",
      gap: 0,
      width: "100%",
      borderRadius: 0,
      paddingTop: 0,
      paddingBottom: 0,
      ".deal-header-title-container": {
        width: "100%",
        minHeight: "67px",
        margin: 0,
        paddingRight: "20px",
        ".dropdown-chevron": {},
        "&.deal-header-title-no-unit": {
          justifyContent: "space-between",
          paddingRight: 0,
        },
      },
      ".integration-mark": {
        marginLeft: "10px",
      },
    },
    ".deal-header-second-col": {
      borderRadius: 0,
      width: "100%",
      minHeight: "61px",
      height: "61px",
      backgroundColor: COLORS.background,
      color: COLORS.grayTheme.activeTextColor,
      ".deal-header-inactive": {
        span: {
          color: COLORS.primary,
        },
      },
      ".deal-header-pdf-download": {
        display: "none",
      },
    },
  },
}));

const DealUnitHeaderItemsContent = styled(FlexContainer)({
  ...AlignedCenterFlexPartial,
  // flexWrap: "wrap",
  justifyContent: "flex-end",
  // fontWeight: 400,
  ".deal-rent": {
    textAlign: "end",
  },
  ".edit-rent-icon": {
    marginLeft: "8px",
    cursor: "pointer",
  },
});

const UnitPopoverContent = styled.div({
  padding: "16px",
  minWidth: "250px",
  ".popover-title": {
    fontSize: "12px",
    fontWeight: 600,
    marginBottom: "16px",
  },
  ".popover-items": {
    border: "none",
    p: {
      margin: 0,
    },
    "p + p": {
      marginTop: "10px",
    },
  },
  ".application-title": {
    marginTop: "16px",
  },
});

const TitlePopoverContent = styled.div({
  padding: "16px",
  maxWidth: "290px",
  ".popover-title": {
    marginBottom: "6px",
  },
});

const InfoDark = styled(Icon.InfoDark)`
  cursor: pointer;
  margin-left: 20px;
`;

const MainText = styled.div({
  whiteSpace: "nowrap",
  '& > a': {
    color: COLORS.white
  },
  '& > a:hover': {
    textDecoration: 'underline'
  }
});

export {
  DealHeaderContent,
  DealUnitHeaderItemsContent,
  UnitPopoverContent,
  TitlePopoverContent,
  InfoDark,
  MainText,
};
