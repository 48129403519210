import React, { useState } from 'react'

import { Col, Row } from 'antd'
import { Formik, Form } from 'formik'
import PropTypes from 'prop-types'

import { useMediaQuery } from 'react-responsive'

import AddressAutocompleteInput from 'shared/components/AddressAutocompleteInput'
import { Button } from 'shared/components/Button'
import { ClickwrapCheckbox } from 'shared/components/ClickwrapCheckbox'
import { ExperianCollectorFields } from 'shared/components/ExperianCollectorFields'
import {
  Input,
  VeroFormField,
  StateSelect,
  ZipCodeInput,
  PhoneNumberInput,
} from 'shared/components/Form'
import Icon from 'shared/components/Icon'
import { UnderlineLink } from 'shared/components/Links'

import Spacer from 'shared/components/Spacer'
import { BREAKPOINT_RESOLUTIONS } from 'shared/config/constants'
import { showError } from 'shared/utils/forms'

import { ExperianAuthTokenParams } from '../../interfaces/api/experianAuth'

import { ADDRESS_FIELDS, EXPERIAN_COLLECTOR } from './constants'
import validationSchema from './formValidation'
import {
  AuthFormContainer,
  FooterActions,
  CenteredFlexContainer,
} from './styled'

const EXPERIAN_USER_PROFILE = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  address: '',
  unit: '',
  city: '',
  state: '',
  zipcode: '',
  ssn: '',
  hdimPayload: '',
  jscPayload: '',
  termsAgreed: false,
}

const ExperianAuthForm = ({
  activeUser = {},
  onSubmit,
  redirect,
  pactSafeConfig,
  isPactSafeConfigLoading,
}) => {
  const initialValues = { ...EXPERIAN_USER_PROFILE, ...activeUser }
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT_RESOLUTIONS.sm })
  const [maskSSN, setMaskSSN] = useState(true)
  const showSSN = () => setMaskSSN(false)
  const hideSSN = () => setMaskSSN(true)

  const setExperianCollectorValues = (values) => {
    const { adx } = window as any
    adx.validate(EXPERIAN_COLLECTOR.jscInputId)
    const jscInput = document.getElementById(
      EXPERIAN_COLLECTOR.jscInputId,
    ) as HTMLInputElement
    const hdimInput = document.getElementById(
      EXPERIAN_COLLECTOR.hdimInputId,
    ) as HTMLInputElement

    values.jscPayload = jscInput.value
    values.hdimPayload = hdimInput.value
  }

  const experianAuthFormatter = (values): ExperianAuthTokenParams => {
    return {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      currentAddress: values.unit
        ? `${values.address} ${values.unit}`
        : values.address,
      currentCity: values.city,
      currentState: values.state,
      currentZip: values.zipcode,
      phoneNumber: values.phoneNumber.slice(-10),
      jscPayload: values.jscPayload,
      hdimPayload: values.hdimPayload,
      ssn: values.ssn,
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validateOnChange
      validationSchema={validationSchema}
      onSubmit={({ ssn, ...rest }, { setSubmitting, setErrors }) => {
        const values = { ssn: ssn.replace(/\D+/g, ''), ...rest }
        setExperianCollectorValues(values)
        const experianParams: ExperianAuthTokenParams =
          experianAuthFormatter(values)
        console.log(experianParams)
        onSubmit(experianParams, { setSubmitting, setErrors })
      }}
    >
      {({
        isSubmitting,
        dirty,
        setFieldValue,
        setFieldTouched,
        touched,
        errors,
      }) => {
        return (
          <Form data-testid="application-setup-form">
            <AuthFormContainer noPadding expand rowGap>
              <Row gutter={14} type="flex">
                <Col xs={24} sm={12}>
                  <VeroFormField
                    as={Input}
                    id="firstName"
                    name="firstName"
                    label="First Name"
                    required
                    type="text"
                  />
                  {isMobile && <Spacer size={Spacer.SIZES.sm} />}
                </Col>
                <Col xs={24} sm={12}>
                  <VeroFormField
                    as={Input}
                    id="lastName"
                    name="lastName"
                    label="Last Name"
                    required
                    type="text"
                  />
                </Col>
              </Row>
              <Row gutter={14} type="flex">
                <Col xs={24} sm={12}>
                  <VeroFormField
                    as={Input}
                    id="email"
                    name="email"
                    label="Email"
                    required
                    type="email"
                  />
                  {isMobile && <Spacer size={Spacer.SIZES.sm} />}
                </Col>
                <Col xs={24} sm={12}>
                  <VeroFormField
                    as={PhoneNumberInput}
                    id="phoneNumber"
                    name="phoneNumber"
                    label="Phone"
                    setFieldValue={setFieldValue}
                    required
                    type="text"
                    disableDropdown
                  />
                </Col>
              </Row>
              <Row gutter={[15, 15]} type="flex">
                <Col xs={24} sm={16}>
                  <AddressAutocompleteInput
                    index={0}
                    setFieldValue={setFieldValue}
                    error={showError(ADDRESS_FIELDS.address, touched, errors)}
                    id={ADDRESS_FIELDS.address}
                    name={ADDRESS_FIELDS.address}
                    fieldMapping={{
                      address: ADDRESS_FIELDS.address,
                      city: ADDRESS_FIELDS.city,
                      state: ADDRESS_FIELDS.state,
                      country: ADDRESS_FIELDS.country,
                      zipcode: ADDRESS_FIELDS.zipcode,
                    }}
                    onBlur={() => setFieldTouched(ADDRESS_FIELDS.address)}
                  />
                </Col>
                <Col xs={12} sm={8}>
                  <VeroFormField
                    as={Input}
                    id="unit"
                    name="unit"
                    label="Apt/Unit"
                    type="text"
                  />
                </Col>
                <Col xs={12} sm={8}>
                  <VeroFormField
                    as={Input}
                    id={ADDRESS_FIELDS.city}
                    name={ADDRESS_FIELDS.city}
                    label="City"
                    required
                    type="text"
                  />
                </Col>
                <Col xs={12} sm={8}>
                  <VeroFormField
                    as={StateSelect}
                    id={ADDRESS_FIELDS.state}
                    name={ADDRESS_FIELDS.state}
                    label="State"
                    required
                    type="text"
                  />
                </Col>
                <Col xs={12} sm={8}>
                  <VeroFormField
                    as={ZipCodeInput}
                    id={ADDRESS_FIELDS.zipcode}
                    name={ADDRESS_FIELDS.zipcode}
                    label="Zipcode"
                    required
                    type="text"
                  />
                </Col>
              </Row>
              <Row type="flex">
                <Col span={24}>
                  <VeroFormField
                    as={Input}
                    id="ssn"
                    name="ssn"
                    label="SSN (optional)"
                    type={maskSSN ? 'password' : 'text'}
                  />
                  {isMobile && <Spacer size={Spacer.SIZES.lg} />}
                </Col>
                <CenteredFlexContainer onClick={maskSSN ? showSSN : hideSSN}>
                  {maskSSN ? (
                    <Icon.ShowPasswordIcon />
                  ) : (
                    <Icon.HidePasswordIcon />
                  )}
                </CenteredFlexContainer>
              </Row>
              {!isPactSafeConfigLoading && (
                <VeroFormField
                  id="termsAgreed"
                  as={ClickwrapCheckbox}
                  name="termsAgreed"
                  required
                  groupKey={pactSafeConfig?.termsOfServiceExperianGroupKey}
                  userEmail={initialValues?.email}
                  onChange={(e) => {
                    setFieldValue('termsAgreed', e.target.checked)
                  }}
                />
              )}
              <ExperianCollectorFields
                jscInputId={EXPERIAN_COLLECTOR.jscInputId}
                hdimInputId={EXPERIAN_COLLECTOR.hdimInputId}
              />
              <FooterActions>
                <UnderlineLink onClick={redirect} size="lg">
                  Cancel
                </UnderlineLink>
                <Button
                  id="form-submit"
                  type="primary"
                  htmlType="submit"
                  loading={isSubmitting}
                  data-testid="submit-button"
                  disabled={!dirty}
                >
                  Submit
                </Button>
              </FooterActions>
            </AuthFormContainer>
          </Form>
        )
      }}
    </Formik>
  )
}

ExperianAuthForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  activeUser: PropTypes.object,
}

ExperianAuthForm.defaultProps = {}

export default ExperianAuthForm
