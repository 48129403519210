import React from "react";

import { Col, Row } from "antd";

import { formatDate, formatPhone, getValueOrNA } from "shared/utils/ui";

import FlexContainer from "../FlexContainer";
import Icon from "../Icon";
import Tile from "../Tile";
import { RegularText } from "../Typography";

import { InfoListIcon } from "./styled";

export interface PersonDocumentCardProps {
  dateOfBirth: string;
  address?: string;
  age?: number;
  phoneNumber?: string;
  documentClass?: string;
  identificationNumber?: string;
  expirationDate?: string;
  inset?: boolean;
}

/**
 * Card component, displaying person info and document information.
 */
const PersonDocumentCard = ({
  dateOfBirth,
  address = undefined,
  age,
  phoneNumber = undefined,
  identificationNumber = undefined,
  expirationDate = undefined,
  inset = false,
}: PersonDocumentCardProps) => {
  const attributes = [
    {
      label: "Document ID",
      value: getValueOrNA(identificationNumber),
      icon: <Icon.IdCardIconXs />,
    },
    {
      label: "Expiration Date",
      value: formatDate(expirationDate),
      icon: <Icon.FileFailedIconXs />,
    },
    {
      label: "Date of Birth",
      value: formatDate(dateOfBirth),
      icon: <Icon.CalendarIconXs />,
    },
    {
      label: "Address",
      value: getValueOrNA(address),
      icon: <Icon.LocationIconXs />,
    },
    {
      label: "Phone Number",
      value: formatPhone(phoneNumber),
      icon: <Icon.PhoneTelephoneIconXs />,
    },
    { label: "Age", value: getValueOrNA(age), icon: <Icon.PeopleIconXs /> },
  ];

  return (
    <Tile.Inner inset={inset}>
      <Row gutter={[16, 5]}>
        {attributes.map((attr) => (
          <Col key={attr.label} xs={24} sm={12} md={8} lg={6}>
            <Col>
              <Tile.Label>
                <FlexContainer alignItems="center">
                  <InfoListIcon>{attr.icon}</InfoListIcon>
                  <RegularText extraStrong>{attr.label}</RegularText>
                </FlexContainer>
              </Tile.Label>
              <RegularText>{attr.value}</RegularText>
            </Col>
          </Col>
        ))}
      </Row>
    </Tile.Inner>
  );
};

export default PersonDocumentCard;
