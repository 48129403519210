import React, { CSSProperties, Key, useState } from "react";

import { Button } from "antd-latest";

import FlexContainer from "shared/components/FlexContainer";
import { COLORS } from "shared/config/constants";

const color = COLORS.lightViolet;
const baseStyle: CSSProperties = {
  marginRight: "10px",
  fontWeight: 400,
  borderColor: color,
  padding: "0px 14px",
  height: "30px",
};
const activeStyle: CSSProperties = {
  ...baseStyle,
  backgroundColor: color,
  color: "white",
};
const inactiveStyle: CSSProperties = {
  ...baseStyle,
  backgroundColor: "white",
  color,
};

export type ChipsRadioButtonProps = {
  menuItems: { key: Key; name: string }[];
  activeItem: Key;
  onChange: (key: Key) => void;
};

export const ChipsRadioButton: React.FC<ChipsRadioButtonProps> = ({
  menuItems,
  activeItem,
  onChange,
}) => {
  const [active, setActive] = useState(activeItem);
  const onClick = (key: Key) => {
    onChange(key);
    setActive(key);
  };
  return (
    <FlexContainer>
      {menuItems.map((item) => (
        <Button
          style={item.key === active ? activeStyle : inactiveStyle}
          key={item.key}
          onClick={() => onClick(item.key)}
          size="small"
        >
          {item.name}
        </Button>
      ))}
    </FlexContainer>
  );
};
