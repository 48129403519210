import React, { Key, useEffect, useMemo, useState } from "react";

import isFunction from "lodash/isFunction";

import FlexContainer from "shared/components/FlexContainer";
import { Select, SelectProps } from "shared/components/Form/Select";
import { ActionLink } from "shared/components/Links";
import { ACTION_LINK_THEMES } from "shared/components/Links/ActionLink";
import { DropdownOverlay } from "shared/styles/commonStyles";

import { StyledSelect, DropdownMenu } from "./styled";

const MULTIPLE_SELECTED_LABEL = "Multiple selected";
const ALL_SELECTED_LABEL = "All selected";

const resolveInitialValue = (value, defaultValue) => {
  return (value || defaultValue)?.map((key) => String(key));
};

export type MultiselectProps = SelectProps & {
  value?: Key[];
  defaultValue?: Key[];
  onChange?: (value: Key) => void;
  options?: {
    key: string | number;
    label: string;
  }[];
  label?: string;
};

export const Multiselect: React.FC<MultiselectProps> = ({
  id,
  name,
  value,
  defaultValue,
  onChange,
  options = [],
  optionFilterProp,
  ...props
}) => {
  const initialValue = resolveInitialValue(value, defaultValue);
  const [internalValue, setInternalValue] = useState(initialValue);

  const optionLabelMap = useMemo(
    () => new Map(options.map((option) => [String(option.key), option.label])),
    [options]
  );

  const selectAll = () => {
    const newValue = options.map((option) => option.key);
    setInternalValue(newValue);
    if (isFunction(onChange)) {
      onChange(newValue);
    }
  };

  const clear = () => {
    setInternalValue([]);
    if (isFunction(onChange)) {
      onChange([]);
    }
  };

  const resolvePlaceholder = (selected) => {
    if (selected?.length === 1) {
      return optionLabelMap.get(selected[0]);
    }
    if (selected?.length === options?.length) {
      return ALL_SELECTED_LABEL;
    }
    if (selected?.length > 1) {
      return `${MULTIPLE_SELECTED_LABEL} (${selected?.length})`;
    }
    return null;
  };

  useEffect(() => {
    const newInitialValue = resolveInitialValue(value, defaultValue);
    setInternalValue(newInitialValue);
  }, [value]);

  return (
    <StyledSelect
      id={id}
      name={name}
      data-testid="multiselect"
      mode="multiple"
      showArrow
      value={internalValue}
      defaultValue={defaultValue}
      optionFilterProp={optionFilterProp}
      onChange={onChange}
      maxTagCount={0}
      maxTagPlaceholder={(selected) => resolvePlaceholder(selected)}
      dropdownRender={(menu) => (
        <>
          <DropdownOverlay />
          <DropdownMenu>
            <FlexContainer
              className="header-container"
              justifyContent="space-between"
              onMouseDown={(e) => e.preventDefault()}
            >
              <ActionLink
                onClick={selectAll}
                theme={ACTION_LINK_THEMES.greenForViolet}
                standardCase
                standardWeight
              >
                Select All
              </ActionLink>
              <ActionLink
                onClick={clear}
                theme={ACTION_LINK_THEMES.greenForViolet}
                standardCase
                standardWeight
              >
                Clear
              </ActionLink>
            </FlexContainer>
            {menu}
          </DropdownMenu>
        </>
      )}
      {...props}
    >
      {options.map((option) => (
        <Select.Option key={option.key} label={option.label}>
          {option.label}
        </Select.Option>
      ))}
    </StyledSelect>
  );
};
