import axios, { AxiosResponse } from 'axios'
import _ from 'lodash'

import ROUTES, { VEGA_BASE_URL } from 'shared/config/apiRoutes'
import {
  EmergencyContact,
  EmploymentHistory,
  Reference,
  ResidentialHistory,
} from 'shared/interfaces/api'
import type {
  Document,
  OcrJob,
  OcrResult,
} from 'shared/interfaces/api/document'
import { transformRoute } from 'shared/utils/routing'
import { setPrefix } from 'shared/utils/vrn'

import { API_URL } from './setupApi'
import { notify } from './utils'

const PAYMENT_SERVICE_PATH = 'api/payment'
const DOCUMENT_SERVICE_PATH = 'api/document'

const api = {
  getApplicationInvitation: async ({ applicationId }) =>
    axios.get(
      `${API_URL}${transformRoute(ROUTES.applicationInvitation, {
        applicationId,
      })}`,
    ),
  getActiveUserApplicantType: async ({ applicationId }) =>
    axios.get(
      `${API_URL}${transformRoute(ROUTES.getActiveUserApplicantType, {
        applicationId,
      })}`,
    ),
  setApplicantType: async ({ applicationId, applicantType }) =>
    axios.patch(
      `${API_URL}${transformRoute(ROUTES.setApplicantType, { applicationId })}`,
      { applicantType },
    ),
  editIncomeAndAssets: async ({ applicationId, income, assets }) =>
    axios.post(
      `${API_URL}${transformRoute(ROUTES.editIncomeAndAssets, {
        applicationId,
      })}`,
      {
        income,
        assets,
      },
    ),
  getApplicationIncomeAssetVerification: async ({ applicationId }) =>
    axios.get(
      `${API_URL}${transformRoute(
        ROUTES.getApplicationIncomeAssetVerification,
        {
          applicationId,
        },
      )}`,
    ),
  getApplications: () =>
    axios.get(`${API_URL}${ROUTES.applications}`).then(({ data }) => data),
  getApplicantLeads: () =>
    axios
      .get(`${API_URL}${ROUTES.applications}`, { params: { leads: true } })
      .then(({ data }) => data),
  getFullApplication: async ({ applicationId }) =>
    axios
      .get(
        `${API_URL}${transformRoute(ROUTES.getFullApplication, {
          applicationId,
        })}`,
      )
      .then(({ data }) => data),
  getApplicationBackgroundCheck: async ({ applicationId }) =>
    axios.get(
      `${API_URL}${transformRoute(ROUTES.getApplicationBackgroundCheck, {
        applicationId,
      })}`,
    ),
  updateApplicantReasonToStay: async ({ applicationId, reason }) =>
    axios.post(
      `${API_URL}${transformRoute(ROUTES.updateApplicantReasonToStay, {
        applicationId,
      })}`,
      {
        reasonToStay: reason,
      },
    ),
  resetBackgroundAndCreditCheck: ({
    applicationId,
  }: {
    applicationId: string | number
  }) =>
    axios.post(
      `${API_URL}${transformRoute(ROUTES.resetBackgroundAndCreditCheck, {
        applicationId,
      })}`,
    ),
  completeCreditAndBackgroundCheck: async ({ applicationId }) =>
    axios.post(
      `${API_URL}${transformRoute(ROUTES.completeCreditAndBackground, {
        applicationId,
      })}`,
    ),
  submitApplication: async ({ applicationId }) =>
    axios.post(
      `${API_URL}${transformRoute(ROUTES.submitApplication, {
        applicationId,
      })}`,
    ),
  getApplicationSummary: async ({ applicationId }) =>
    axios.get(
      `${API_URL}${transformRoute(ROUTES.getApplicationSummary, {
        applicationId,
      })}`,
    ),
  getConditionalRequest: async ({ applicationId }) =>
    axios.get(
      `${API_URL}${transformRoute(ROUTES.conditionalRequest, {
        applicationId,
      })}`,
    ),
  submitConditionalRequest: async ({
    applicationId,
    values: { guarantorEmail, firstName, lastName },
  }) =>
    axios.post(
      `${API_URL}${transformRoute(ROUTES.conditionalRequest, {
        applicationId,
      })}`,
      {
        ...(guarantorEmail && { guarantorEmail }),
        ...(firstName && { firstName }),
        ...(lastName && { lastName }),
      },
    ),

  withdrawApplication: ({ applicationId }) =>
    axios.post(
      `${API_URL}${transformRoute(ROUTES.withdrawApplication, {
        applicationId,
      })}`,
    ),
  getApplicantEmergencyContacts: async () =>
    axios
      .get<EmergencyContact[]>(
        `${API_URL}${transformRoute(ROUTES.getApplicantEmergencyContacts)}`,
      )
      .then(({ data }) => data),
  getApplicantResidentialHistory: async () =>
    axios
      .get<ResidentialHistory[]>(
        `${API_URL}${transformRoute(ROUTES.getApplicantResidentialHistory)}`,
      )
      .then(({ data }) => data),
  getApplicantEmploymentHistory: async () =>
    axios
      .get<EmploymentHistory[]>(
        `${API_URL}${transformRoute(ROUTES.getApplicantEmploymentHistory)}`,
      )
      .then(({ data }) => data),
  getApplicantReferences: async () =>
    axios
      .get<Reference[]>(
        `${API_URL}${transformRoute(ROUTES.getApplicantReferences)}`,
      )
      .then(({ data }) => data),
  getApplicantCheckInfo: ({
    applicationId,
  }: {
    applicationId: string | number
  }) =>
    axios
      .get(
        `${API_URL}${transformRoute(ROUTES.getApplicantCheckInfo, {
          applicationId,
        })}`,
      )
      .then(({ data }) => data),
  updateApplicantCheckInfo: async ({ applicationId, values }) =>
    axios.put(
      `${API_URL}${transformRoute(ROUTES.updateApplicantCheckInfo, {
        applicationId,
      })}`,
      values,
    ),
  getApplicationMarketingSources: ({ applicationId }) =>
    axios
      .get(
        `${API_URL}${transformRoute(ROUTES.applicationMarketingSources, {
          applicationId,
        })}`,
      )
      .then((response) => response.data),
  getActivePlaidApplication: async () =>
    axios.get(`${API_URL}${ROUTES.getActivePlaidApplication}`),

  setScreeningAgreementDate: ({ applicationId }) =>
    axios.post(
      `${API_URL}${transformRoute(ROUTES.setScreeningAgreementDate, {
        applicationId,
      })}`,
    ),
  viewFullSSN: ({ applicationId }) =>
    axios
      .post(
        `${API_URL}${transformRoute(ROUTES.viewFullSSN, {
          applicationId,
        })}`,
      )
      .then(({ data }) => data),
  inviteDependents: ({ applicationId, payload }) =>
    axios.post(
      `${API_URL}${transformRoute(ROUTES.inviteDependents, { applicationId })}`,
      payload,
    ),
  getIncomeSkipReasons: ({ applicationId }) =>
    axios
      .get(
        `${API_URL}${transformRoute(ROUTES.getIncomeSkipReasons, {
          applicationId,
        })}`,
      )
      .then((response) => response.data),
  setIncomeSkipReasons: ({ applicationId, skipReasons }) =>
    axios.post(
      `${API_URL}${transformRoute(ROUTES.setIncomeSkipReasons, {
        applicationId,
      })}`,
      {
        reason: skipReasons,
      },
    ),
  trackCurrentApplicationStep: ({ applicationId, stepName }) =>
    axios.post(
      `${API_URL}${transformRoute(ROUTES.trackCurrentApplicationStep, {
        applicationId,
      })}`,
      {
        stepName,
      },
    ),
  getApplicationFromApplicationService: (id) => {
    if (id) {
      id = setPrefix(id, 'application')

      return axios
        .get(`${API_URL}/application/${encodeURIComponent(id)}`)
        .then((response) => response.data)
        .catch((error) => ({
          application: id,
          error: error?.errors,
        }))
    }
  },
  listApplicantsFromApplicationService: (application) => {
    if (application) {
      application = setPrefix(application, 'application')

      return axios
        .get(`${API_URL}/next/applicant`, {
          params: {
            application,
          },
        })
        .then((response) => response.data)
        .catch((error) => ({
          application,
          error: error?.errors,
        }))
    }
  },
  getApplicantFromApplicationService: (id) => {
    if (id) {
      id = setPrefix(id, 'applicant')

      return axios
        .get(`${API_URL}/next/applicant/${encodeURIComponent(id)}`)
        .then((response) => response.data)
        .catch((error) => ({
          application: id,
          error: error?.errors,
        }))
    }
  },
  listApplicantWorkflows: (location, detailed = false) => {
    if (location) {
      return axios
        .get(`${API_URL}/next/applicant/workflow`, {
          params: _.pickBy({
            location,
            detailed,
          }),
          // @ts-ignore
          returnRaw: true,
        })
        .then((response) => response.data)
        .catch((error) => ({
          location,
          error: error?.errors,
        }))
    }
  },
  createApplicantWorkflow: (data) => {
    return axios
      .post(`${API_URL}/next/applicant/workflow`, data, {
        // @ts-ignore
        convertToSnakeCase: false,
        returnRaw: true,
      })
      .then((response) => response.data)
      .catch((error) => ({
        error: error?.errors,
      }))
  },
  updateApplicantWorkflow: ({ id, data }) => {
    return axios
      .put(
        `${API_URL}/next/applicant/workflow/${encodeURIComponent(id)}`,
        data,
        {
          // @ts-ignore
          convertToSnakeCase: false,
          returnRaw: true,
        },
      )
      .then((response) => response.data)
      .catch((error) => ({
        error: error?.errors,
      }))
  },
  deleteApplicantWorkflow: (id) => {
    return axios
      .delete(`${API_URL}/next/applicant/workflow/${encodeURIComponent(id)}`, {
        // @ts-ignore
        convertToSnakeCase: false,
        returnRaw: true,
      })
      .then((response) => response.data)
      .catch((error) => ({
        error: error?.errors,
      }))
  },
  listApplicantWorkflowRunsForApplicant: (applicant) => {
    if (applicant) {
      applicant = setPrefix(applicant, 'applicant')

      return axios
        .get(`${API_URL}/next/applicant/workflow/run`, {
          params: {
            applicant,
          },
        })
        .then((response) => response.data)
        .catch((error) => ({
          applicant,
          error: error?.errors,
        }))
    }
  },
  runApplicantWorkflow: (applicant) => {
    if (applicant) {
      applicant = setPrefix(applicant, 'applicant')

      return axios
        .post(`${API_URL}/next/applicant/workflow/run`, {
          applicant,
        })
        .then((response) => response.data)
        .catch((error) => ({
          applicant,
          error: error?.errors,
        }))
    }
  },
  getApplicantWorkflowRun: (id) => {
    if (id) {
      id = setPrefix(id, 'applicant-workflow-run')

      return axios
        .get(`${API_URL}/next/applicant/workflow/run/${encodeURIComponent(id)}`)
        .then((response) => response.data)
        .catch((error) => ({
          error: error?.errors,
        }))
    }
  },
  beginPrequalification: ({ applicationId, payload }) =>
    axios.post(
      `${API_URL}${transformRoute(ROUTES.beginPrequalification, {
        applicationId,
      })}`,
      payload,
    ),
  areVettGuestsEnabled: (applicationId) =>
    axios
      .get(
        `${API_URL}${transformRoute(ROUTES.areVettGuestsEnabled, {
          applicationId,
        })}`,
      )
      .then((response) => response.data),
  listApplicantPayments: (applicant) =>
    axios
      .get(`${VEGA_BASE_URL}/${PAYMENT_SERVICE_PATH}`, {
        params: {
          applicant,
        },
      })
      .then((response) => response.data),
  upsertApplicantPayment: (applicant, location, chargePoint) => {
    return axios
      .post(
        `${VEGA_BASE_URL}/${PAYMENT_SERVICE_PATH}?view-only=true`,
        {
          applicant,
          location,
          chargePoint,
        },
        {
          // @ts-ignore
          convertToSnakeCase: false,
        },
      )
      .then((response) => response.data)
      .catch(() => null)
  },
  getPaymentById: (paymentId) =>
    axios.get(
      `${VEGA_BASE_URL}/${PAYMENT_SERVICE_PATH}/${encodeURIComponent(
        paymentId,
      )}`,
    ),
  createApplicationPayment: (payload) =>
    axios.post(
      `${VEGA_BASE_URL}/${PAYMENT_SERVICE_PATH}`,
      payload,
      // @ts-ignore
      { convertToSnakeCase: false },
    ),
  getApplicationFull: (applicationId) =>
    axios.get(
      `${API_URL}${transformRoute(`/applications/${applicationId}/full/`, {
        applicationId,
      })}`,
    ),
  getOcrResults: (person: string, includeRemoved) =>
    axios.get<OcrResult[]>(
      `${VEGA_BASE_URL}/${DOCUMENT_SERVICE_PATH}/ocr-result`,
      {
        params: {
          person,
          'include-removed': includeRemoved,
        },
      },
    ),
  getOcrResultById: (id: string) =>
    axios.get<OcrResult>(
      `${VEGA_BASE_URL}/${DOCUMENT_SERVICE_PATH}/ocr-result/${id}`,
      {
        params: {
          'url-only': false,
        },
      },
    ),
  getOcrResultUrlById: (id: string) =>
    axios.get<OcrResult>(
      `${VEGA_BASE_URL}/${DOCUMENT_SERVICE_PATH}/ocr-result/${id}`,
      {
        params: {
          'url-only': true,
        },
      },
    ),
  patchOcrResultById: async ({
    id,
    data,
  }: {
    id: string
    data: {
      payer: string | null
      formYear?: string | null
      payPeriodStartDate?: string | null
      payPeriodEndDate?: string | null
      payDate?: string | null
      grossAmount: string | null
      netAmount?: string | null
    }
  }) =>
    axios.patch(
      `${VEGA_BASE_URL}/${DOCUMENT_SERVICE_PATH}/ocr-result/${id}`,
      { annotation: { ...data } },
      {
        // @ts-ignore
        convertToSnakeCase: false,
      },
    ),
  deleteOcrResults: (id: string) =>
    axios
      .delete(`${VEGA_BASE_URL}/${DOCUMENT_SERVICE_PATH}/ocr-result/${id}`, {
        data: { removeReason: 'excluded-by-employee' },
        // @ts-ignore
        convertToSnakeCase: false,
      })
      .then(({ data }) => data),
  getOcrJobs: (id?: string, person?: string) =>
    axios.get<OcrJob[]>(`${VEGA_BASE_URL}/${DOCUMENT_SERVICE_PATH}/ocr-job`, {
      params: {
        ...(id && { 'document-id': id }),
        ...(person && { person }),
      },
    }),
  getDocuments: (owner: string) =>
    axios.get<Document[]>(`${VEGA_BASE_URL}/${DOCUMENT_SERVICE_PATH}`, {
      params: {
        owner,
      },
    }),
  getDocumentDetail: (id: string) =>
    axios.get<Document>(`${VEGA_BASE_URL}/${DOCUMENT_SERVICE_PATH}/${id}`, {
      params: {
        detailed: true,
      },
    }),
  deleteDocument: (id: string) =>
    axios
      .delete(`${VEGA_BASE_URL}/${DOCUMENT_SERVICE_PATH}/${id}`, {
        data: { removeReason: 'excluded-by-employee' },
        // @ts-ignore
        convertToSnakeCase: false,
      })
      .then(({ data }) => data),
  createDocument: ({
    description,
    filename,
    ocr,
    owner,
    ownerType,
  }: Pick<Document, 'description' | 'filename' | 'owner' | 'ownerType'> & {
    ocr: boolean
  }): Promise<AxiosResponse<Document, any>> =>
    axios.post(
      `${VEGA_BASE_URL}/${DOCUMENT_SERVICE_PATH}`,
      {
        description,
        filename,
        ocr,
        owner,
        ownerType,
      },
      {
        // @ts-ignore
        convertToSnakeCase: false,
      },
    ),
  uploadDocument: (uploadUrl: string, file: File) =>
    fetch(uploadUrl, {
      body: file,
      headers: { 'Content-Type': file.type },
      method: 'PUT',
    }),
}

export type MoveApplicationRequest = {
  applicationId: string | number
  dealId: string
  comment?: string
  reason?: number
}

export const moveApplicationToAnotherDeal = ({
  applicationId,
  dealId,
  comment,
  reason,
}: MoveApplicationRequest) =>
  notify(
    () =>
      axios.patch(
        `${API_URL}${transformRoute(ROUTES.moveApplication, {
          applicationId,
        })}`,
        { deal: dealId, comment, reason },
      ),
    'Moved applicant to another application',
    'Failed to move applicant',
  )

export const generateApplicationPDF = async ({ applicationId }) =>
  axios
    .post(
      `${API_URL}${transformRoute(ROUTES.generateApplicationPDF, {
        applicationId,
      })}`,
    )
    .then((response) => response.data)

export default api
