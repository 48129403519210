import React from "react";

import { NotFoundError } from "shared/lib/errors";
import Error404Page from "shared/pages/Error404Page";
import ErrorPage from "shared/pages/ErrorPage";

const is404 = (error) => error instanceof NotFoundError;

type ErrorBoundaryProps = {
  children: React.ReactNode;
  [key: string]: any;
};
type ErrorBoundaryState = {
  error?: string;
};

export default class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props) {
    super(props);
    this.state = { error: undefined };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error, info) {
    this.setState({ error });
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;
    let render = children;

    if (error && is404(error)) {
      render = <Error404Page />;
    } else if (error) {
      render = <ErrorPage />;
    }

    return render;
  }
}
