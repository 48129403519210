import sharedApi from "shared/lib/api";

import surveyApi from "./surveyApi";

const api = {
  ...sharedApi,
  ...surveyApi,
};

export default api;
