import React, { useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";

import ConfirmationModal from "shared/components/Modals/ConfirmationModal";
import { NOTIFICATIONS } from "shared/config/constants";
import { usePermissions } from "shared/hooks";
import { useViewFullSSN } from "shared/hooks/api";
import useModal from "shared/hooks/useModal";
import { ApiError } from "shared/lib/errors";
import { formatSSN, openNotification } from "shared/utils/ui";

import SSNViewer from "./SSNViewer";

const SHOW_SSN_TIMEOUT = 30000;

const SSNViewerContainer = ({ applicationId, maskedSSN }) => {
  const ssnTimerRef = useRef();
  const { openModalDialog, closeActiveModalDialog } = useModal();
  const { canViewFullSSN } = usePermissions();
  const { viewFullSSN } = useViewFullSSN();
  const [visibleSSN, setVisibleSSN] = useState(maskedSSN);

  const isMaskedSSNShown = maskedSSN === visibleSSN;

  const hideFullSSN = () => {
    setVisibleSSN(maskedSSN);
    clearTimeout(ssnTimerRef.current);
  };

  const showFullSSN = (fullSSN) => {
    const formattedSSN = formatSSN(fullSSN);
    setVisibleSSN(formattedSSN);

    ssnTimerRef.current = setTimeout(() => {
      hideFullSSN();
      openNotification(
        `For your security, you must re-authenticate every 30 seconds to view the full SSN / ITIN.`,
        NOTIFICATIONS.info
      );
    }, SHOW_SSN_TIMEOUT);
  };

  const onViewSSNSubmit = () =>
    viewFullSSN(
      { applicationId },
      {
        onSuccess: ({ fullSsn }) => {
          showFullSSN(fullSsn);
          closeActiveModalDialog();
        },
        onError: (error) => {
          let errorMessage = "Failed to fetch SSN / ITIN.";
          if (error instanceof ApiError) {
            errorMessage = error?.errors?.error;
          }
          openNotification(errorMessage, NOTIFICATIONS.error);
        },
      }
    );

  const openConfirmationModal = () =>
    openModalDialog(ConfirmationModal, {
      title: "Confirm to proceed",
      submitButtonLabel: "Confirm",
      message:
        "You are accessing the applicant's full Social Security Number / ITIN. Please observe privacy rules and regulations when handling sensitive information. VERO will log this action for security and audit purposes.",
      onConfirm: () => onViewSSNSubmit(),
    });

  useEffect(() => {
    return () => clearTimeout(ssnTimerRef.current);
  }, []);

  return (
    <SSNViewer
      visibleSSN={visibleSSN}
      isMaskedSSNShown={isMaskedSSNShown}
      openConfirmationModal={openConfirmationModal}
      hideSSN={hideFullSSN}
      canViewFullSSN={canViewFullSSN}
    />
  );
};

SSNViewerContainer.propTypes = {
  applicationId: PropTypes.number.isRequired,
  maskedSSN: PropTypes.string.isRequired,
};

export default SSNViewerContainer;
