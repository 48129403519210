import React, { createRef, useEffect } from "react";

import BaseMaskedInput, { MaskedInputProps } from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

import { Input as BaseInput, InputProps } from "shared/components/Form/Input";
import { mergeRefs } from "shared/utils/react";
import { convertDollarsToCents, convertCentsToDollars } from "shared/utils/ui";

import { getFormikMaskedInput } from "./maskedInputCreator";
import { Input, StyledMaskedInput } from "./styled";

const PrivateMaskedInput: React.FC<
  InputProps & Omit<MaskedInputProps, "size">
> = (props) => (
  <Input
    data-testid="masked-input"
    as={BaseMaskedInput}
    // @ts-ignore
    guide={false}
    {...props}
  />
);

/** Note: Library component react-text-mask is not relying on state
 *  that makes it hard to clear the field programmatically if needed.
 *  For this purpose we obtain html node ref and align state value with
 *  html input value using useEffect().
 *
 *  MaskedInputMoney ref is exposed to parent in case it's needed for
 *  FormikMaskedInput refactoring
 */
type MaskedInputMoneyProps = MaskedInputProps & {
  value?: string;
};

const MaskedInputMoney = React.forwardRef(
  ({ value = "", ...props }: MaskedInputMoneyProps, ref) => {
    const forwardRef = createRef<HTMLInputElement>();

    useEffect(() => {
      if (forwardRef.current) {
        forwardRef.current.value = value;
        // @ts-ignore
        forwardRef.current.inputElement.value = value;
      }
    }, [value, forwardRef]);

    return (
      <StyledMaskedInput
        {...props}
        mask={createNumberMask({
          prefix: "$",
          allowDecimal: true,
          decimalLimit: 2,
        })}
        ref={mergeRefs(forwardRef, ref)}
      />
    );
  }
);

export type MoneyInputProps = InputProps & {
  withoutPostfix?: boolean;
  label: string;
};

/** Note: MoneyInput ref is exposed to parent in case it's needed for
 *  FormikMaskedInput refactoring
 */
const MoneyInput = Object.assign(
  React.forwardRef(
    ({ withoutPostfix, label, ...props }: MoneyInputProps, ref) => (
      <BaseInput
        type="text"
        data-testid="money-input"
        as={MaskedInputMoney}
        ref={ref}
        label={`${label}${!withoutPostfix ? " ($)" : ""}`}
        {...props}
      />
    )
  ),
  {
    unmask: (value) => {
      const dollars = value.replace(/[$,]/g, "");
      return dollars === "" ? null : convertDollarsToCents(Number(dollars));
    },
    schema: () => /(^[0-9.]+$)/,
    convertInitialValue: (value) => convertCentsToDollars(value),
  }
);

// TODO: Hanna - consider refactoring of this component as MoneyInput
const IncomeRentRatioInput = Object.assign(
  (props: MaskedInputProps) => (
    <PrivateMaskedInput
      data-testid="income-rent-ratio-input"
      {...props}
      type="text"
      mask={createNumberMask({
        prefix: "",
        suffix: props.suffix || "x",
        allowDecimal: true,
        requireDecimal: false,
        decimalLimit: 1,
        includeThousandsSeparator: false,
      })}
      guide
    />
  ),
  {
    unmask: (value: string) => Number(value.replace(/[x,_]/g, "")),
    schema: () => /(^[0-9.]+$)/,
  }
);

const PercentageInput = Object.assign(
  React.forwardRef(
    ({ withoutPostfix, label, ...props }: MoneyInputProps, ref) => (
      <PrivateMaskedInput
        data-testid="percentage-input"
        {...props}
        ref={ref}
        type="text"
        label={`${label}${!withoutPostfix ? " (%)" : ""}`}
        mask={createNumberMask({
          prefix: "",
          suffix: "%",
          allowDecimal: true,
          requireDecimal: false,
          decimalLimit: 1,
          includeThousandsSeparator: false,
        })}
        guide
      />
    )
  ),
  {
    unmask: (value: string) => Number(value.replace(/[%,_]/g, "")),
    schema: () => /(^[0-9.]+$)/,
  }
);

const FormikMaskedInput = Object.assign(
  getFormikMaskedInput(PrivateMaskedInput),
  {
    // @ts-ignore
    Money: getFormikMaskedInput(MoneyInput),
    IncomeRentRatio: getFormikMaskedInput(IncomeRentRatioInput),
    PercentageInput: getFormikMaskedInput(PercentageInput),
  }
);

export const MaskedInput = Object.assign(PrivateMaskedInput, {
  Money: MoneyInput,
  IncomeRentRatio: IncomeRentRatioInput,
  PercentageInput,
  Formik: FormikMaskedInput,

  unmask: (value: string) => {
    const dollars = value.replace(/[$,]/g, "");
    return dollars === "" ? null : convertDollarsToCents(Number(dollars));
  },

  schema: () => /(^[0-9]+$)/,
  convertInitialValue: (value: any) => convertCentsToDollars(value),
});
