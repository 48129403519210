import _ from 'lodash'
import React from 'react'
import Well from 'shared/components/Well'
import { Formula } from './Formula'
import { Explanation } from './Explanation'
import Result from './Result'

const HistoryEntry = ({ entry, small = false, detailed = false }) => {
  const resolvedEntryFormula = !_.isNil(entry?.formula) && entry?.formula
  const resolvedEntryEvaluatedFormula = !_.isNil(entry?.evaluatedFormula) && entry?.evaluatedFormula
  const annotations = detailed && _.map(resolvedEntryFormula?.match(/#([^:]+):/g), t => _.trim(t, '#:'))

  return (
    <div className='vega container flex column history-entry'>
      <Explanation explanation={entry?.explanation} small={small} badges={annotations} />

      {detailed && <div className='vega entry-formula'>
        <Well white>
          {resolvedEntryFormula && <Formula formula={resolvedEntryFormula} highlight />}
          {resolvedEntryEvaluatedFormula && <Formula formula={resolvedEntryEvaluatedFormula} className='evaluated' />}
        </Well>
      </div>}

      {detailed && <Result result={entry?.result} />}
    </div>
  )
}

export default HistoryEntry
