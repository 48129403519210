import React, { Dispatch, SetStateAction } from "react";

import {
  BluemoonHiddenPropertiesRequest,
  BluemoonProperty,
} from "manager/interfaces/api/bluemoon";
import Icon from "shared/components/Icon";
import { Modal } from "shared/components/Modal";
import Table from "shared/components/Table";
import { RegularText } from "shared/components/Typography";
import TablePage from "shared/templates/TablePage";

import { TableWrapper } from "./styled";

interface BluemoonHiddenPropertiesModalProps {
  states: { label: string; key: string }[];
  searchParams: BluemoonHiddenPropertiesRequest;
  handleSearchParams: (key: string, value: string) => void;
  hasInputValue: boolean;
  onPaginationChange: (currentPage: number) => void;
  pagination: {
    page: number;
    pageSize: number;
  };
  propertiesCount: number;
  properties: BluemoonProperty[];
  isBluemoonHiddenPropertiesFetching: boolean;
  unhideBluemoonProperties: (params: { bluemoonProperties: number[] }) => void;
  isUnhideBluemoonPropertiesLoading: boolean;
  selectedProperties: number[];
  setSelectedProperties: Dispatch<SetStateAction<number[]>>;
}

const BluemoonHiddenPropertiesModal = ({
  states,
  searchParams,
  handleSearchParams,
  hasInputValue,
  onPaginationChange,
  pagination,
  propertiesCount,
  properties,
  isBluemoonHiddenPropertiesFetching,
  unhideBluemoonProperties,
  isUnhideBluemoonPropertiesLoading,
  selectedProperties,
  setSelectedProperties,
}: BluemoonHiddenPropertiesModalProps) => {
  const getRows = () =>
    (properties || []).map((property: BluemoonProperty) => ({
      key: property.id,
      values: [
        <RegularText>
          {property.name} {property.fullAddress}
        </RegularText>,
        <RegularText light>{property.licenseCode}</RegularText>,
        <RegularText light>{property.state}</RegularText>,
      ],
    }));

  return (
    <Modal
      title="Hidden Properties"
      subtitle={
        <RegularText weak light>
          Bluemoon Properties
        </RegularText>
      }
      submitButtonLabel="Unhide"
      submit={() =>
        unhideBluemoonProperties({ bluemoonProperties: selectedProperties })
      }
      submitting={isUnhideBluemoonPropertiesLoading}
      submitButtonDisabled={!selectedProperties.length}
      width="950px"
    >
      <Modal.Body>
        <RegularText weak light>
          Hiding a property removes it from lists on the binding screen. It also
          pulls the property from generating alerts on the Bluemoon Dashboard.
          Select &apos;Unhide&apos; and save to allow binding to a VERO
          property.
        </RegularText>
        <TableWrapper>
          <TablePage
            tables={[
              {
                filterBar: {
                  search: {
                    action: (value: string) =>
                      handleSearchParams("searchValue", value),
                    placeholder: "Search by property, address, or license...",
                    width: 440,
                    debounce: true,
                    value: searchParams.searchValue,
                    disabled: isBluemoonHiddenPropertiesFetching,
                  },
                  dropdownFilters: [
                    {
                      key: "state",
                      label: "State",
                      value: searchParams.state,
                      onChange: (selectedFilter: string) =>
                        handleSearchParams(
                          "state",
                          selectedFilter === "" ? null : selectedFilter
                        ),
                      items: states,
                      disabled: isBluemoonHiddenPropertiesFetching,
                    },
                  ],
                },
                node: (
                  <Table
                    alt
                    loading={isBluemoonHiddenPropertiesFetching}
                    searching={hasInputValue}
                    noClickableRows
                    columns={[
                      {
                        title: "Bluemoon Properties",
                        dataIndex: "property",
                        key: "property",
                      },
                      {
                        title: "License ID",
                        dataIndex: "licenseId",
                        key: "licenseId",
                      },
                      {
                        title: "State",
                        dataIndex: "state",
                        key: "state",
                      },
                    ]}
                    rows={getRows()}
                    columnBreakpoints={{
                      xs: {
                        column1: "property",
                        column2: "lisenseId",
                      },
                      sm: ["property", "licenseId", "state"],
                      md: ["property", "licenseId", "state"],
                    }}
                    placeholder={{
                      Icon: Icon.NoDataIcon,
                      label: "Hidden Properties",
                    }}
                    checkboxActions={[]}
                    onRowSelection={setSelectedProperties}
                    pagination={{
                      current: pagination?.page,
                      onChange: onPaginationChange,
                      total: propertiesCount,
                      defaultPageSize: pagination?.pageSize,
                    }}
                  />
                ),
              },
            ]}
          />
        </TableWrapper>
      </Modal.Body>
    </Modal>
  );
};

export default BluemoonHiddenPropertiesModal;
