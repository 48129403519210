import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import Icon from "shared/components/Icon";
import { DIMENSIONS, COLORS } from "shared/config/constants";

const ContainerMobile = styled("div")<{ example?: boolean }>((props) => ({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  zIndex: 100,
  height: DIMENSIONS.header.ltMd.height,
  background: "#FFF",
  display: "flex",
  justifyContent: "flex-start",
  borderBottom: `1px solid ${COLORS.borders}`,
  padding: "5px 20px",
  ...(props.example && {
    position: "relative",
    width: "100%",
  }),
}));

const HeaderActionContainer = styled.div`
  display: flex;
  align-self: center;
  justify-content: flex-end;
  width: 100%;
  gap: 28px;
`;

const LogoContainer = styled(Link)`
  display: flex;
  align-items: center;
  margin-left: 24px;
`;

const HelpIcon = styled(Icon.Help)`
  cursor: pointer;
  fill: #2d2943;
`;

export { ContainerMobile, HeaderActionContainer, LogoContainer, HelpIcon };
