import React from "react";

import FailureModal from "./FailureModal";

interface FailureModalProps {
  submit: () => void;
}

const FailureModalContainer = ({ submit }: FailureModalProps) => {
  return <FailureModal title="Unable to verify" submit={submit} />;
};

export default FailureModalContainer;
