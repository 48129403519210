import React from "react";

import { FormikHelpers } from "formik";
import { useHistory } from "react-router-dom";

import { useSaveBluemoonLicense } from "manager/hooks/api/bluemoonQueries";
import useModal from "shared/hooks/useModal";
import { onDialogSubmitError } from "shared/utils/api";

import BluemoonConnectLicenseModal from "./BluemoonConnectLicenseModal";
import { BluemoonLicense } from "./interfaces";

const BluemoonConnectLicenseModalContainer = () => {
  const { saveBluemoonLicense } = useSaveBluemoonLicense();

  const { closeActiveModalDialog } = useModal();
  const history = useHistory();

  const onSubmit = (
    values: BluemoonLicense,
    formikApi: FormikHelpers<BluemoonLicense>
  ) =>
    saveBluemoonLicense(values, {
      onSuccess: closeActiveModalDialog,
      onError: onDialogSubmitError(
        formikApi,
        history,
        "Failed to save the bluemoon license."
      ),
    });

  return <BluemoonConnectLicenseModal submit={onSubmit} />;
};

export default BluemoonConnectLicenseModalContainer;
