import React from "react";

import pluralize from "pluralize";
import PropTypes from "prop-types";

import { MODALS } from "manager/config/constants";
import Icon from "shared/components/Icon";
import { Modal } from "shared/components/Modal";
import Well from "shared/components/Well";

import { YardiImportPropertyConfirmationContent } from "./styled";

const YardiImportPropertyConfirmationModal = ({ numOfImportedProperties }) => {
  return (
    <Modal
      id={MODALS.yardiImportPropertiesConfirmation}
      centered
      showSubmitButton={false}
      showCancelLink={false}
    >
      <Modal.Body noPaddingTop noPaddingBottom>
        <YardiImportPropertyConfirmationContent>
          <Icon.OperationSuccess data-testid="yardi-property-import-success" />
          <h3 className="success-text">
            {numOfImportedProperties}{" "}
            {pluralize("property", numOfImportedProperties)}&nbsp;
            {pluralize("has", numOfImportedProperties)} been imported to VERO!
          </h3>
          <Well>
            Please complete the setup for each property in order for it to be
            connected to VERO
          </Well>
        </YardiImportPropertyConfirmationContent>
      </Modal.Body>
    </Modal>
  );
};

YardiImportPropertyConfirmationModal.propTypes = {
  numOfImportedProperties: PropTypes.number.isRequired,
};

export default YardiImportPropertyConfirmationModal;
