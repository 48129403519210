import React, { useEffect } from "react";

import { Row, Col } from "antd";
import { Formik } from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";

import { Button } from "shared/components/Button";
import FlexContainer from "shared/components/FlexContainer";
import { Select, VeroFormField } from "shared/components/Form";
import Icon from "shared/components/Icon";
import { UnderlineLink } from "shared/components/Links";
import LoaderContainer from "shared/components/LoaderContainer";
import Spacer from "shared/components/Spacer";
import Tile from "shared/components/Tile";
import Tooltip from "shared/components/Tooltip";
import Well from "shared/components/Well";
import { showError } from "shared/utils/forms";

import { StyledLoader, StyledPropertyEmailForm } from "../styled";
import { DEFAULT_KEY } from "../utils";

const PropertyEmailTemplatesForm = ({
  configurations,
  isLoading,
  currentConfigurationId,
  onSubmit,
  registerFormChange,
  typeName,
}) => {
  return (
    <LoaderContainer loading={isLoading} Loader={<StyledLoader />}>
      <Formik
        initialValues={{
          customEmailConfigurationId:
            currentConfigurationId?.toString() || DEFAULT_KEY,
        }}
        validationSchema={Yup.object().shape({
          customEmailConfigurationId: Yup.string().required(
            "This field is required"
          ),
        })}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({
          resetForm,
          isSubmitting,
          touched,
          errors,
          setFieldValue,
          dirty,
          isValid,
          values,
        }) => {
          useEffect(() => {
            registerFormChange(typeName, {
              isValid,
              dirty,
              submitForm: () => onSubmit(values),
              isSubmitting,
            });
          }, [isValid, dirty, values, isSubmitting]);

          return (
            <StyledPropertyEmailForm>
              <Tile.Inner>
                <Row
                  gutter={[16, 8]}
                  type="flex"
                  className="email-configuration-row"
                >
                  <Col xl={6} lg={8} md={12} sm={12} xs={24}>
                    <VeroFormField
                      id="customEmailConfigurationId"
                      name="customEmailConfigurationId"
                      as={Select}
                      error={showError(
                        "customEmailConfigurationId",
                        touched,
                        errors
                      )}
                      label="Email Template"
                      onChange={(newValue) =>
                        setFieldValue("customEmailConfigurationId", newValue)
                      }
                    >
                      <Select.Option key={DEFAULT_KEY}>
                        Company Default
                      </Select.Option>
                      {configurations.map((item) => (
                        <Select.Option key={item.id}>{item.name}</Select.Option>
                      ))}
                    </VeroFormField>
                  </Col>
                  <Col className="email-configuration-col">
                    <span>Select the preferred email template</span>
                    <Tooltip
                      title={
                        <Well noBorder>
                          Use the Company Default or select an alternative
                          template specific to this property from the dropdown.
                        </Well>
                      }
                    >
                      <Icon.InfoIcon className="extra-info" />
                    </Tooltip>
                  </Col>
                </Row>
                <Spacer />
                <FlexContainer alignItems="center" justifyContent="right">
                  <div className="reset-link">
                    <UnderlineLink onClick={resetForm} disabled={!dirty}>
                      Reset
                    </UnderlineLink>
                  </div>
                  <Button
                    className="submit-button"
                    htmlType="submit"
                    type="primary"
                    loading={isSubmitting}
                    disabled={!dirty}
                  >
                    Save
                  </Button>
                </FlexContainer>
              </Tile.Inner>
            </StyledPropertyEmailForm>
          );
        }}
      </Formik>
    </LoaderContainer>
  );
};

PropertyEmailTemplatesForm.propTypes = {
  configurations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  currentConfigurationId: PropTypes.number,
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  registerFormChange: PropTypes.func.isRequired,
  typeName: PropTypes.string.isRequired,
};

PropertyEmailTemplatesForm.defaultProps = {
  configurations: [],
  currentConfigurationId: undefined,
};

export default PropertyEmailTemplatesForm;
