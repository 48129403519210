import React, { Key, ReactNode } from "react";

import {
  AdditionalQuestionModal,
  DealSetupAttachmentsModal,
} from "manager/components/Modal";
import {
  useGetDealSetup,
  useUpdateDealSetup,
} from "manager/hooks/api/dealSetupQueries";
import { CONTENT_TYPE } from "shared/config/constants";
import useModal from "shared/hooks/useModal";

import DealSetupForm from "../DealSetupForm";

interface PortfolioDealSetupSettingsContainerProps {
  children: ReactNode | ReactNode[];
  dealSetupId: Key;
}

const PortfolioDealSetupSettingsContainer = ({
  children,
  dealSetupId,
}: PortfolioDealSetupSettingsContainerProps) => {
  const { openModalDialog } = useModal();

  const { updateDealSetup } = useUpdateDealSetup();

  const saveChanges = (
    values: { id: number; data: any },
    resolve: () => void,
    reject: () => void
  ) => {
    updateDealSetup(
      // @ts-ignore
      { id: values.id, dealSetup: values.data },
      { onSuccess: resolve, onError: reject }
    );
  };

  const { dealSetup, isDealSetupLoading, isDealSetupFetching } =
    useGetDealSetup(dealSetupId);

  const openQuestionsModal = (context) =>
    openModalDialog(AdditionalQuestionModal, {
      forSettingsLabel: "Portfolio Settings",
      contentType: CONTENT_TYPE.dealSetup,
      ...context,
    });

  const openAttachmentsModal = ({
    items,
    dealSetupId: objectId,
    title,
    newItemProps,
  }) =>
    openModalDialog(DealSetupAttachmentsModal, {
      title,
      items,
      objectId,
      forSettingsLabel: "Portfolio Settings",
      contentType: CONTENT_TYPE.dealSetup,
      newItemProps,
    });

  return (
    // @ts-ignore
    <DealSetupForm
      forSettingsLabel="Portfolio Settings"
      saveChanges={saveChanges}
      dealSetup={dealSetup}
      loading={isDealSetupLoading}
      fetching={isDealSetupFetching}
      openQuestionsModal={openQuestionsModal}
      openAttachmentsModal={openAttachmentsModal}
    >
      {children}
    </DealSetupForm>
  );
};

export default PortfolioDealSetupSettingsContainer;
