import axios from "axios";

import ROUTES from "admin/config/apiRoutes";
import { API_URL } from "shared/lib/api/setupApi";
import { transformRoute } from "shared/utils/routing";

const api = {
  getAdminCompanies: async () =>
    axios.get(`${API_URL}${ROUTES.getAdminCompanies}`),
  getCompaniesToAssignDocuments: ({
    documentNames,
  }: {
    documentNames: string[];
  }) =>
    axios.get(
      `${API_URL}${ROUTES.getAdminCompanies}?docs=${documentNames.join(
        "&docs="
      )}`
    ),
  assignDocuments: ({
    companyId,
    documentIds,
  }: {
    companyId: number;
    documentIds: number[];
  }) =>
    axios.post(
      `${API_URL}${transformRoute(ROUTES.assignDocuments, { id: companyId })}`,
      { document_templates: documentIds }
    ),
  unassignDocument: ({
    companyId,
    documentIds,
  }: {
    companyId: number;
    documentIds: number[];
  }) =>
    axios.post(
      `${API_URL}${transformRoute(ROUTES.unassignDocuments, {
        id: companyId,
      })}`,
      {
        document_templates: documentIds,
      }
    ),
  deleteDocument: (companyId: number) =>
    axios.delete(
      `${API_URL}${transformRoute(ROUTES.documentTemplate, { id: companyId })}`
    ),
};

export default api;
