import axios from "axios";

import ROUTES from "admin/config/apiRoutes";
import { CreateDocument, SaveDocumentDraft } from "admin/interfaces/api";
import { PreviewDocument } from "admin/interfaces/api/previewDocument";
import { API_URL } from "shared/lib/api/setupApi";
import { transformRoute } from "shared/utils/routing";

const api = {
  getDocumentTemplates: () =>
    axios.get(`${API_URL}${ROUTES.documentTemplates}`),
  uploadDocumentTemplate: ({
    name,
    type,
    riderType,
    tags,
    fields,
    fileContent,
    numberOfPages,
  }: CreateDocument) =>
    axios.post(`${API_URL}${ROUTES.documentTemplates}`, {
      name,
      type,
      riderType,
      tags,
      fields,
      fileContent,
      numberOfPages,
    }),
  getCompaniesDocumentAssigned: (documentId: number | string) =>
    axios.get(
      `${API_URL}${transformRoute(ROUTES.getCompaniesDocumentAssigned, {
        id: documentId,
      })}`
    ),
  previewDocumentTemplate: ({
    name,
    type,
    fields,
    fileContent,
  }: PreviewDocument) =>
    axios
      .post(`${API_URL}${ROUTES.previewDocumentTemplate}`, {
        name,
        type,
        fields,
        fileContent,
      })
      .then(({ data }) => data),
  getDocumentDrafts: () => axios.get(`${API_URL}${ROUTES.getDocumentDrafts}`),
  getDocumentDraft: (documentId: number | string) =>
    axios.get(
      `${API_URL}${transformRoute(ROUTES.getDocumentDraft, {
        id: documentId,
      })}`
    ),
  postDocumentDrafts: (payload: SaveDocumentDraft) =>
    axios.post(`${API_URL}${ROUTES.getDocumentDrafts}`, payload),
};

export default api;
