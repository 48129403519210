import styled from "@emotion/styled";

import BaseButton from "shared/components/Button/Button";
import { colors } from "shared/config/constants";

const Button = styled(BaseButton)`
  &.ant-btn-secondary {
    border-color: ${colors.secondary[700]};
    .button-children {
      color: ${colors.secondary[700]};
    }
    div[data-testid="bordered-suffix-icon"] {
      padding-left: 0;
      border-color: transparent;
    }
    path {
      stroke: ${colors.secondary[700]};
    }
  }
  &.ant-btn-primary {
    background: ${colors.secondary[700]};
  }
`;

export { Button };
