import React from "react";

import capitalize from "lodash/capitalize";
import snakeCase from "lodash/snakeCase";

import { REQUIRED_QUESTIONS_NAMES } from "manager/components/DealSetupSettings/AdditionalQuestions";
import { AdditionalQuestionModalContext } from "manager/components/Modal/AdditionalQuestionModal/interfaces";
import { APPLICANT_TYPES_LABEL } from "manager/config/constants";
import {
  useDeleteAdditionalQuestion,
  useToggleAdditionalQuestionActive,
} from "manager/hooks/api/additionalQuestionsQueries";
import { useToggleRequiredQuestionActive } from "manager/hooks/api/dealSetupQueries";
import { AdditionalQuestionModel } from "manager/interfaces/api/additionalQuestion";
import ConfirmationModal from "shared/components/Modals/ConfirmationModal";
import { useActiveCompany } from "shared/hooks";
import { useAnswerTypeOptions } from "shared/hooks/api";
import useModal from "shared/hooks/useModal";
import { ApplicantType } from "shared/interfaces/misc";

import AdditionalQuestionsTable from "./AdditionalQuestionsTable";

interface AdditionalQuestionsTableContainerProps {
  questions: AdditionalQuestionModel[];
  applicantType: ApplicantType;
  fetching: boolean;
  openQuestionsModal?: Function;
  objectId?: number;
  description?: string;
  disabled?: boolean;
}

const AdditionalQuestionsTableContainer = ({
  questions,
  applicantType,
  fetching,
  openQuestionsModal,
  objectId,
  description,
  disabled,
}: AdditionalQuestionsTableContainerProps) => {
  const { openModalDialog } = useModal();
  const { activeCompany } = useActiveCompany();

  const { answerTypeOptions } = useAnswerTypeOptions();
  const { deleteAdditionalQuestion } = useDeleteAdditionalQuestion();

  const {
    toggleAdditionalQuestionActive,
    isToggleAdditionalQuestionActiveLoading,
  } = useToggleAdditionalQuestionActive(objectId);

  const {
    toggleRequiredQuestionActive,
    isToggleRequiredQuestionActiveLoading,
  } = useToggleRequiredQuestionActive(objectId);

  const openAdditionalQuestionModal = (
    context: AdditionalQuestionModalContext
  ) => {
    openQuestionsModal({
      objectId,
      applicantType,
      ...context,
    });
  };

  const openDeleteAdditionalQuestionConfirmation = (
    additionalQuestionId: number
  ) =>
    // @ts-ignore
    openModalDialog(ConfirmationModal, {
      title: `Delete Question For ${capitalize(
        APPLICANT_TYPES_LABEL[applicantType]
      )}`,
      submitButtonLabel: "Okay, got it",
      message:
        "You are attempting to permanently delete this application question.",
      description: `This question will no longer appear on applicants for ${activeCompany?.name}.`,
      onConfirm: () => deleteAdditionalQuestion(additionalQuestionId),
    });

  const onChangeIsActiveQuestion = (id) => {
    if (REQUIRED_QUESTIONS_NAMES[id]) {
      toggleRequiredQuestionActive({
        id: objectId,
        requiredQuestionsId: snakeCase(id),
      });
    } else {
      toggleAdditionalQuestionActive(id);
    }
  };

  return (
    <AdditionalQuestionsTable
      isLoading={
        isToggleAdditionalQuestionActiveLoading ||
        isToggleRequiredQuestionActiveLoading ||
        fetching
      }
      questions={questions}
      applicantType={applicantType}
      description={description}
      disabled={disabled}
      openAdditionalQuestionModal={openAdditionalQuestionModal}
      openDeleteAdditionalQuestionConfirmation={
        openDeleteAdditionalQuestionConfirmation
      }
      onChangeIsActiveQuestion={onChangeIsActiveQuestion}
      answerTypeOptions={answerTypeOptions}
    />
  );
};

AdditionalQuestionsTableContainer.defaultProps = {
  objectId: undefined,
  openQuestionsModal: () => {},
  description: undefined,
  disabled: false,
};

export default AdditionalQuestionsTableContainer;
