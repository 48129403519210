import styled from "@emotion/styled";

import { MEDIA_QUERIES } from "shared/config/constants";

const TypeSection = styled.div`
  background-color: #f5f5f5;
  border: 1px solid #e8e7ee;
  height: min-content;
  color: #807e8d;
  maxw-idth: 450px;
  font-size: 12px;
  ${MEDIA_QUERIES.w.ltLg} {
    max-width: 100%;
    width: auto;
  }
`;
export { TypeSection };
