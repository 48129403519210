class Store {
  static instance;

  static initialize(creator) {
    this.instance = new Store(creator).store;
  }

  constructor(creator) {
    this.store = creator();
  }
}

export default Store;
