import React from "react";

import {
  Action,
  Actions,
  Title,
  TitleContainer,
  Subtitle,
  Icon,
  RenameLink,
} from "./styled";

const PageTitle = (props) => <Title {...props} />;

PageTitle.Actions = Actions;
PageTitle.Action = Action;
PageTitle.Container = TitleContainer;
PageTitle.Subtitle = Subtitle;
PageTitle.Icon = Icon;
PageTitle.RenameLink = RenameLink;

export default PageTitle;
