import React, { useEffect, useMemo, useState } from "react";

import { useFormikContext } from "formik";
import flatMap from "lodash/flatMap";
import get from "lodash/get";
import isNumber from "lodash/isNumber";
import PropTypes from "prop-types";

import { CreateUnitModal } from "manager/components/Modal";
import { useAutocreateVeroUnits } from "manager/hooks/api";
import { SelectExtension } from "manager/pages/Marketplace/integrations/yardi/shared/mapping/styled";
import { Select, VeroFormField } from "shared/components/Form";
import { ActionLink } from "shared/components/Links";
import useModal from "shared/hooks/useModal";
import { destringifyWithPrefix } from "shared/utils/number";
import { stringify } from "shared/utils/string";

import AutoCreateModal from "./AutoCreateModal";
import AutoCreateTaskSubmittedModal from "./AutoCreateTaskSubmittedModal";
import { SelectContainer } from "./styled";

const listUnitNames = (units) => {
  const unitNames = (units || []).map((unit) => unit.name);
  if (unitNames.length > 11) {
    const subList = unitNames.slice(0, 10);
    const diff = unitNames.length - 10;
    return `${subList.join(", ")} and ${diff} others`;
  }
  return unitNames.join(", ");
};

const VeroUnitsDropdown = ({
  name,
  setFieldValue,
  createNewUnit,
  unitItems,
}) => (
  <SelectContainer>
    <VeroFormField
      as={Select}
      id={name}
      name={name}
      label="Select or create VERO unit"
      allowClear
      onChange={(value) => setFieldValue(name, value)}
      customDropdownFooter={
        <SelectExtension data-testid="select-dropdown-extension">
          <ActionLink onMouseDown={createNewUnit}>+ NEW UNIT</ActionLink>
        </SelectExtension>
      }
    >
      {unitItems}
    </VeroFormField>
  </SelectContainer>
);

VeroUnitsDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  createNewUnit: PropTypes.func.isRequired,
  unitItems: PropTypes.node.isRequired,
};

const getUsedVeroUnits = (...unitTrees) => {
  return flatMap(
    unitTrees.filter((unitTree) => !!unitTree),
    (unitTree) =>
      Object.values(unitTree)
        .map((unitObj) => unitObj.veroUnit)
        .filter((veroUnit) => !!veroUnit)
        .map((veroUnit) => stringify(veroUnit))
  );
};

const useUnitConfigSection = (
  provider,
  buildPropPath,
  buildLabel,
  veroUnits,
  veroUnitsMap,
  yardiUnits,
  veroProperty,
  yardiProperty,
  checkedItems,
  DescriptionExtension
) => {
  const { openModalDialog } = useModal();
  const { setFieldValue, values } = useFormikContext();
  const [usedUnits, setUsedUnits] = useState(new Set([]));

  const isLroEnabled = !!values.isLroEnabled;

  const buildVeroUnitPropPath = (yardiUnit) =>
    `${buildPropPath(yardiUnit)}.veroUnit`;

  const buildRentPropPath = (yardiUnit) => `${buildPropPath(yardiUnit)}.rent`;

  const getSelectedVeroUnitId = (yardiUnit) =>
    get(values, buildVeroUnitPropPath(yardiUnit));

  const getSelectedVeroUnit = (yardiUnit) =>
    veroUnitsMap[stringify(getSelectedVeroUnitId(yardiUnit))];

  const createNewUnit = (yardiUnit) => {
    const afterUnitCreate = (newUnit) => {
      setFieldValue(buildVeroUnitPropPath(yardiUnit), stringify(newUnit.id));
    };
    const initialValues = {
      name: yardiUnit.name,
      unitType: yardiUnit.unitType,
      bedrooms: yardiUnit.bedrooms,
      bathrooms: yardiUnit.bathrooms,
      squareFeet: yardiUnit.squareFeet,
    };
    const context = {
      propertyId: veroProperty?.id,
      propertyName: veroProperty?.name,
      initialValues,
      afterUnitCreate,
    };
    openModalDialog(CreateUnitModal, context);
  };

  const { autocreateVeroUnits, isLoading: isAutocreating } =
    useAutocreateVeroUnits(provider, yardiProperty?.id);

  const autoCreateUnits = () => {
    if (checkedItems.length) {
      const checkedItemsSet = new Set(checkedItems);
      const selectedUnits = (yardiUnits || []).filter((unit) =>
        checkedItemsSet.has(unit.id)
      );
      const selectedUnitsStr = listUnitNames(selectedUnits);
      const onSave = () => {
        const onSuccess = () => {
          const context = {
            title: "Auto-create VERO units task submitted",
            propertyName: veroProperty.name,
            description:
              "The auto-create operation is in progress. Please try refreshing this page after a few minutes",
          };

          openModalDialog(AutoCreateTaskSubmittedModal, context);
        };
        return autocreateVeroUnits(
          {
            yardiUnits: checkedItems,
          },
          { onSuccess }
        );
      };

      const context = {
        title: "Auto-create VERO units?",
        propertyName: veroProperty.name,
        wellMessage: `The following units will be created within VERO: ${selectedUnitsStr}`,
        description:
          "When auto creating units in VERO we will use the unit name and listing information provided by the External Provider.",
        onSave,
      };

      openModalDialog(AutoCreateModal, context);
    }
  };

  const unitItems = useMemo(
    () =>
      (veroUnits || []).map((type) => (
        <Select.Option
          key={type.id}
          disabled={usedUnits.has(stringify(type.id))}
        >
          {type.name}
        </Select.Option>
      )),
    [veroUnits, usedUnits]
  );

  const tableItems = useMemo(
    () =>
      yardiUnits.map((yardiUnit) => {
        return {
          key: yardiUnit.id,
          isUnitLinked: Boolean(getSelectedVeroUnit(yardiUnit)),
          label: buildLabel(yardiUnit),
          description: (
            <>
              <VeroUnitsDropdown
                name={buildVeroUnitPropPath(yardiUnit)}
                setFieldValue={(name, value) => {
                  setFieldValue(name, value);
                  const veroUnitId = stringify(value);
                  let rent = null;
                  if (veroUnitId) {
                    rent = yardiUnit.rent;
                    if (
                      !isLroEnabled &&
                      veroUnitsMap[veroUnitId] &&
                      isNumber(veroUnitsMap[veroUnitId].rent)
                    ) {
                      rent = veroUnitsMap[veroUnitId].rent;
                    }
                  }
                  const rentPropPath = buildRentPropPath(yardiUnit);
                  setFieldValue(rentPropPath, rent);
                }}
                createNewUnit={() => createNewUnit(yardiUnit)}
                unitItems={unitItems}
              />
              {!!DescriptionExtension && (
                <DescriptionExtension
                  yardiUnit={yardiUnit}
                  veroUnit={getSelectedVeroUnit(yardiUnit)}
                  isLroEnabled={isLroEnabled}
                />
              )}
            </>
          ),
        };
      }),
    [yardiUnits, unitItems, isLroEnabled]
  );

  useEffect(() => {
    const usedVeroUnits = getUsedVeroUnits(
      values.availableUnits,
      values.waitUnits,
      values.otherUnits
    );
    setUsedUnits(new Set(usedVeroUnits));
  }, [values]);

  return {
    tableItems,
    autoCreateUnits,
    isAutocreating,
  };
};

// NOTE: need to add a prefix to the id of the units to force Formik to treat the structure as
// an object and not an array (the ID is an number, so Formik will use a list)
const YARDI_UNIT_ID_PREFIX = "unitIdPrefix-";
const VERO_MARKETING_SOURCE_ID_PREFIX = "mrkSrcIdPrefix-";
const VERO_LEAD_SOURCE_ID_PREFIX = "leadSrcIdPrefix-";
const VERO_AGENT_ID_PREFIX = "agentsIdPrefix-";

const stringifyYardiUnitId = (id) => `${YARDI_UNIT_ID_PREFIX}${id}`;
const stringifyVeroMarketingSourceId = (id) =>
  `${VERO_MARKETING_SOURCE_ID_PREFIX}${id}`;
const stringifyVeroAgentId = (id) => `${VERO_AGENT_ID_PREFIX}${id}`;
const stringifyVeroLeadSourceId = (id) => `${VERO_LEAD_SOURCE_ID_PREFIX}${id}`;

const destringifyVeroMarketingSourceId = (id) => {
  return destringifyWithPrefix(id, VERO_MARKETING_SOURCE_ID_PREFIX);
};

const destringifyVeroAgentId = (id) => {
  return destringifyWithPrefix(id, VERO_AGENT_ID_PREFIX);
};

const destringifyVeroLeadSourceId = (id) => {
  return destringifyWithPrefix(id, VERO_LEAD_SOURCE_ID_PREFIX);
};

export {
  useUnitConfigSection,
  stringifyYardiUnitId,
  stringifyVeroMarketingSourceId,
  stringifyVeroAgentId,
  stringifyVeroLeadSourceId,
  destringifyVeroMarketingSourceId,
  destringifyVeroAgentId,
  destringifyVeroLeadSourceId,
};
