import React from "react";

import { Row, Col } from "antd";

import { formatDate } from "shared/utils/ui";

import Spacer from "../Spacer";
import Tile from "../Tile";
import { RegularText } from "../Typography";

import { PropertyDetails } from "./verification.interfaces";

const PropertyDetailsCard = ({
  tennancyPeriodStart,
  tennancyPeriodEnd,
  address,
}: PropertyDetails) => {
  const startDate = formatDate(tennancyPeriodStart);
  const endDate = tennancyPeriodEnd ? formatDate(tennancyPeriodEnd) : "Present";
  return (
    /* @ts-ignore */
    <Tile noBorder equalHeight>
      <Tile.Inner>
        <RegularText strong>PROPERTY DETAILS</RegularText>
      </Tile.Inner>
      <Tile.Inner>
        <Row>
          <Col>
            <Tile.Label>Tennancy Period</Tile.Label>
            <RegularText strong>{`${startDate} - ${endDate}`}</RegularText>
          </Col>
        </Row>
        <Spacer size={Spacer.SIZES.md} />
        <Row>
          <Col>
            <Tile.Label>Address</Tile.Label>
            <RegularText>{address}</RegularText>
          </Col>
        </Row>
        <Spacer />
      </Tile.Inner>
    </Tile>
  );
};

export default PropertyDetailsCard;
