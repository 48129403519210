import styled from "@emotion/styled";

import { StyledFooterBar } from "manager/pages/Marketplace/integrations/yardi/shared/mapping/styled";
import Container from "shared/components/Container";
import Icon from "shared/components/Icon";
import Menu from "shared/components/Menu";
import Tile from "shared/components/Tile";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";
import { AlignedCenterFlexPartial } from "shared/styles/commonStyles";

const YardiPropertyTile = styled(Tile)({
  marginBottom: 0,
});

const YardiPropertyTileContent = styled(Tile.Inner)({
  height: "63px",
  ...AlignedCenterFlexPartial,
  ".property-header": {
    ...AlignedCenterFlexPartial,
  },
  span: {
    marginRight: "15px",
  },
  ".edit-link": {
    marginLeft: "15px",
  },
  [MEDIA_QUERIES.w.ltMd]: {
    justifyContent: "space-between",
    b: {
      marginLeft: "10px",
    },
    ".property-header": {
      alignItems: "flex-start",
      flexDirection: "column",
    },
  },
});

const MappingHintTitle = styled.div({
  color: COLORS.primary,
  fontWeight: 400,
  fontSize: "16px",
  paddingTop: "24px",
  paddingBottom: "24px",
});

const RightLane = styled.div({
  width: "232px",
  marginRight: "15px",
  [MEDIA_QUERIES.w.ltLg]: {
    display: "none",
  },
});

const ConfigurationMenuTile = styled(Tile)({
  display: "flex",
  top: "95px",
});

const ConfigSectionMenu = styled(Menu)({
  minWidth: "150px",
  maxWidth: "230px",
  height: "100%",
  li: {
    padding: "12px 14px",
    outline: "none",
    div: {
      width: "100px",
    },
  },
});

const ContentLane = styled.div({
  width: "100%",
  height: "100%",
});

const ConfigFooterBar = styled(StyledFooterBar)({
  justifyContent: "flex-end",
});

const YardiPropertyConfigContainer = styled(Container)(({ firstColWidth }) => ({
  ".searchable-list-header": {
    borderLeft: "none",
    borderRight: "none",
    borderTop: "none",
    height: "50px",
    display: "flex",
    "&>div": {
      flexGrow: 1,
    },
  },
  ".searchable-list-body": {
    borderLeft: "none",
    borderRight: "none",
  },
  ".searchable-list": {
    borderBottom: "none",
  },
  ".first-col": {
    width: firstColWidth,
  },
  ".header-from, .field-from": {
    width: `${firstColWidth} !important`,
    minWidth: `${firstColWidth} !important`,
    marginLeft: "24px !important",
    paddingLeft: "0 !important",
  },
}));

const SpinnerIcon = styled(Icon.LoadingSpinnerGreenIcon)({
  height: "15px",
  width: "15px",
});

export {
  YardiPropertyTile,
  YardiPropertyTileContent,
  YardiPropertyConfigContainer,
  MappingHintTitle,
  RightLane,
  ConfigurationMenuTile,
  ConfigSectionMenu,
  ContentLane,
  ConfigFooterBar,
  SpinnerIcon,
};
