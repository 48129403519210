import React from "react";

import { Form, Formik } from "formik";

import ErrorList from "shared/components/ErrorList";
import { Modal } from "shared/components/Modal";
import { BREAKPOINT_TYPES } from "shared/config/constants";
import withBreakpoint from "shared/hocs/withBreakpoint";

import { CreateUnitValidationSchema } from "./createUnit.config";
import CreateUnitInnerFormElements from "./CreateUnitInnerFormElements";
import { CreateUnitModalProps } from "./interfaces";

const CreateUnitModal = ({
  breakpoint,
  submit,
  propertyName,
  initialValues = {
    name: undefined,
    unitType: undefined,
    bedrooms: undefined,
    bathrooms: undefined,
    squareFeet: undefined,
  },
}: CreateUnitModalProps) => (
  <Formik
    validationSchema={CreateUnitValidationSchema}
    initialValues={initialValues}
    isInitialValid={CreateUnitValidationSchema.isValidSync(initialValues)}
    onSubmit={submit}
  >
    {({ submitForm, isSubmitting, errors }) => (
      <Form>
        <Modal
          title="Create New Unit"
          subtitle={
            <span data-testid="subtitle">
              Property: <strong>{propertyName}</strong>
            </span>
          }
          submit={submitForm}
          submitting={isSubmitting}
          closeOnSubmit={false}
          submitButtonLabel="Create Unit"
          fullScreen={BREAKPOINT_TYPES.xs === breakpoint}
        >
          {errors.nonFieldErrors && (
            <Modal.Body noPaddingBottom>
              <ErrorList errors={errors.nonFieldErrors} />
            </Modal.Body>
          )}
          <Modal.Body>
            <CreateUnitInnerFormElements />
          </Modal.Body>
        </Modal>
      </Form>
    )}
  </Formik>
);

export default withBreakpoint(CreateUnitModal);
