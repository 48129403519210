import React from "react";

import { Typography } from "antd";
import pluralize from "pluralize";

import {
  CollapsibleCheckboxList,
  SearchableList,
} from "shared/components/Form";
import Icon from "shared/components/Icon";
import { Modal, SIZES } from "shared/components/Modal";
import SelectedItemList from "shared/components/SelectedItemList";

import { AssignEmployeesToPropertyModalProps } from "./interfaces";

const { Text } = Typography;

const AssignEmployeesToPropertyModal = ({
  submit,
  employees = [],
  loading,
  submitting,
  isMobile,
  propertyName,
  searchAction,
  checkedItemsOnChange,
  selectedEmployees = [],
  hasInputValue,
}: AssignEmployeesToPropertyModalProps) => (
  <Modal
    title="Assign employees to property"
    subtitle={
      <span data-testid="subtitle">
        Property: <strong>{propertyName}</strong>
      </span>
    }
    submit={submit}
    submitButtonLabel="Assign"
    submitButtonSize={SIZES.lg}
    width="auto"
    style={{ maxWidth: 946 }}
    submitting={submitting}
    closeOnSubmit={false}
    submitButtonDisabled={selectedEmployees?.length === 0}
    fullScreen={isMobile}
    footer={
      <SelectedItemList
        label="employee"
        items={selectedEmployees?.map((employee) => ({
          key: employee.id,
          label: `${employee.user.firstName} ${employee.user.lastName}`,
        }))}
      />
    }
  >
    <Modal.Body noMobilePaddingX>
      <SearchableList
        loading={loading}
        search={{
          fieldName: "search",
          label: "Search employee...",
          action: searchAction,
          width: "370px",
          debounce: true,
        }}
        List={CollapsibleCheckboxList}
        listContainerProps={{
          checkedItemsOnChange,
        }}
        listProps={{
          col1Width: "350px",
          items: employees?.map((employee) => ({
            key: employee.id,
            label: `${employee.user.firstName} ${employee.user.lastName}`,
            description: (
              <Text type="secondary">
                {`Assigned to ${employee.numAssignedProperties} ${pluralize(
                  "property",
                  employee.numAssignedProperties
                )}`}
              </Text>
            ),
          })),
        }}
        placeholder={{
          label: "Employees",
          Icon: Icon.NoEmployeesIcon,
        }}
        hasInputValue={hasInputValue}
      />
    </Modal.Body>
  </Modal>
);

export default AssignEmployeesToPropertyModal;
