import React from "react";

import { useGetAllTags } from "admin/hooks/api";
import { useGetDocumentTypes, useGetRiderTypes } from "shared/hooks/api";
import { formatDocumentName } from "shared/utils/ui";

import FinishDocumentModal from "./FinishDocumentModal";
import {
  FinishDocumentModalFormik,
  FinishDocumentModalFormikProps,
} from "./interfaces";

interface FinishDocumentModalContainerProps {
  fileName: string;
  onFinishOnboarding: ({
    name,
    type,
    riderType,
    tags,
  }: {
    name: string;
    type: string;
    riderType: string;
    tags: {
      name: string;
    }[];
  }) => void;
  error: FinishDocumentModalFormikProps;
}

const FinishDocumentModalContainer = ({
  fileName,
  onFinishOnboarding,
  error,
}: FinishDocumentModalContainerProps) => {
  const { documentTypes, isDocumentTypesLoading } = useGetDocumentTypes();
  const { riderTypes, isRiderTypesLoading } = useGetRiderTypes();
  const { tags, isTagsLoading } = useGetAllTags();

  const onSubmit = (values: FinishDocumentModalFormik) =>
    onFinishOnboarding({
      name: formatDocumentName(values.name),
      type: values.type,
      riderType: values.riderType,
      tags: values.tags.map((tag) => ({ name: tag })),
    });

  return (
    <FinishDocumentModal
      onSubmit={onSubmit}
      fileName={fileName}
      error={error}
      documentTypes={documentTypes}
      riderTypes={riderTypes}
      tags={tags}
      isDocumentTypesLoading={isDocumentTypesLoading}
      isTagsLoading={isTagsLoading}
      isRiderTypesLoading={isRiderTypesLoading}
    />
  );
};

export default FinishDocumentModalContainer;
