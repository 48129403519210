import React, { ElementType } from "react";

import { Button } from "shared/components/Button";
import Icon from "shared/components/Icon";

import { StyledToolbar } from "./styled";

export type MarkdownToolbarProps = {
  CustomActions?: ElementType[];
  disabled?: boolean;
  hideBold?: boolean;
  hideHead1?: boolean;
  hideHead2?: boolean;
  hideHead3?: boolean;
  hideItalic?: boolean;
  hideLink?: boolean;
  onFormatClick: (format: {
    open: string;
    close: string;
    defaultText: string;
  }) => void;
  hideDefaultActions?: boolean;
};

export const MarkdownToolbar = ({
  onFormatClick,
  CustomActions,
  disabled,
  hideBold,
  hideItalic,
  hideHead1,
  hideHead2,
  hideHead3,
  hideLink,
  hideDefaultActions,
}: MarkdownToolbarProps) => {
  const onBoldClick = onFormatClick({
    open: "**",
    close: "**",
    defaultText: "Bold Text",
  });
  const onItalicClick = onFormatClick({
    open: "*",
    close: "*",
    defaultText: "Italic Text",
  });
  const onHead1Click = onFormatClick({
    open: "#",
    close: "#",
    defaultText: "Header 1",
  });
  const onHead2Click = onFormatClick({
    open: "##",
    close: "##",
    defaultText: "Header 2",
  });
  const onHead3Click = onFormatClick({
    open: "###",
    close: "###",
    defaultText: "Header 3",
  });
  const onLinkClick = onFormatClick({
    open: "[",
    close: "](https://example.com)",
    defaultText: "Link Text",
  });

  return (
    <StyledToolbar>
      {!hideBold && !hideDefaultActions && (
        <Button
          onClick={onBoldClick}
          disabled={disabled}
          className="panel-action"
          data-testid="bold-action"
        >
          <strong>B</strong>
        </Button>
      )}
      {!hideItalic && !hideDefaultActions && (
        <Button
          onClick={onItalicClick}
          disabled={disabled}
          className="panel-action"
          data-testid="italic-action"
        >
          <em>I</em>
        </Button>
      )}
      {!hideHead1 && !hideDefaultActions && (
        <Button
          onClick={onHead1Click}
          disabled={disabled}
          className="panel-action"
          data-testid="h1-action"
        >
          H1
        </Button>
      )}
      {!hideHead2 && !hideDefaultActions && (
        <Button
          onClick={onHead2Click}
          disabled={disabled}
          className="panel-action"
          data-testid="h2-action"
        >
          H2
        </Button>
      )}
      {!hideHead3 && !hideDefaultActions && (
        <Button
          onClick={onHead3Click}
          disabled={disabled}
          className="panel-action"
          data-testid="h3-action"
        >
          H3
        </Button>
      )}
      {!hideLink && !hideDefaultActions && (
        <Button
          onClick={onLinkClick}
          disabled={disabled}
          className="panel-action"
          data-testid="link-action"
        >
          <Icon.Link width="16px" height="16px" />
        </Button>
      )}
      {CustomActions?.map((Action, i) => {
        const key = i + 1;
        return (
          <Action key={key} onFormatClick={onFormatClick} disabled={disabled} />
        );
      })}
    </StyledToolbar>
  );
};
