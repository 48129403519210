import React, { ChangeEventHandler } from "react";

import debounce from "lodash/debounce";

import { Input, InputProps } from "shared/components/Form/Input";
import { INPUT_SIZES } from "shared/config/constants";

import { SearchIcon, SearchLgIcon } from "./styled";

export type SearchProps = InputProps & {
  id: string;
  onSearch: (query: string) => void;
  debounce?: boolean;
  debounceTimeout?: number;
  disabled?: boolean;
  ActionIcon?: React.ReactNode;
  label?: string;
};
export const Search: React.FC<SearchProps> = ({
  onSearch,
  id,
  size = INPUT_SIZES.default,
  debounce: shouldDebounce,
  debounceTimeout = 500,
  disabled,
  ActionIcon,
  ...props
}) => {
  const handleSearch = (value) => {
    onSearch(value);
  };

  const Icon = size === INPUT_SIZES.lg ? SearchLgIcon : SearchIcon;

  const debounceSearch = debounce((value) => onSearch(value), debounceTimeout);
  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (shouldDebounce) {
      debounceSearch(e.target.value);
    }
  };

  return (
    <Input
      data-testid="search"
      id={id}
      name="search-input"
      suffix={
        ActionIcon || (
          <Icon
            onClick={() => {
              const input = document.getElementById(id) as HTMLInputElement;
              handleSearch(input.value);
            }}
          />
        )
      }
      onChange={onChange}
      onPressEnter={(event: any) => handleSearch(event.target.value)}
      allowClear
      size={size}
      withContainer
      disabled={disabled}
      {...props}
    />
  );
};
