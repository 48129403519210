import { useQuery } from "react-query";

import api from "manager/lib/api";
import { NOTIFICATIONS } from "shared/config/constants";
import { openNotification } from "shared/utils/ui";

export const useGetCompanyDashboardData = (
  companyId: number,
  period: number
) => {
  const { data, isLoading } = useQuery(
    ["getCompanyDashboardData", Number(companyId), Number(period)],
    () => api.getCompanyDashboardData(period),
    {
      // NOTE: BE calculates dashboard data once per day, so we are caching the results for 1h
      cacheTime: 1 * 60 * 60 * 1000,
      onError: () =>
        openNotification(
          "Failed to get company dashboard data",
          NOTIFICATIONS.error
        ),
    }
  );

  return {
    companyDashboardData: data,
    isCompanyDashboardDataLoading: isLoading,
  };
};
