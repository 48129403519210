import * as Yup from "yup";

const CreateUnitListingValidationSchema = Yup.object().shape({
  securityDepositDisabled: Yup.bool().default(false),
  availabilityDateDisabled: Yup.bool().default(false),
  rent: Yup.number()
    .nullable()
    .positive("Please enter a rent amount that is greater than $0")
    .required("Rent is required"),
  securityDeposit: Yup.number()
    .nullable()
    .when("securityDepositDisabled", {
      is: false,
      then: Yup.number()
        .positive("Please enter a security deposit that is greater than $0")
        .nullable()
        .min(0),
    }),
  unitAvailableOn: Yup.string()
    .nullable()
    .notRequired()
    .when("availabilityDateDisabled", {
      is: false,
      then: Yup.string().nullable().required("Date Available is required"),
    }),
});

export { CreateUnitListingValidationSchema };
