import React from "react";

import styled from "@emotion/styled";
import { Checkbox as BaseCheckbox } from "antd";

import { COLORS } from "shared/config/constants";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Container = styled(({ inline, ...props }) => <div {...props} />)(
  (props) => ({
    ...(props.inline === true && {
      display: "inline-block",
    }),
  })
);

const Checkbox = styled(BaseCheckbox)({
  marginRight: "12px",
  ".ant-checkbox.ant-checkbox-indeterminate .ant-checkbox-inner": {
    borderColor: COLORS.checkbox,
    "&:after": {
      height: "1px",
    },
  },
  ".ant-checkbox-disabled.ant-checkbox-checked": {
    ".ant-checkbox-inner": {
      backgroundColor: COLORS.gray1,
      borderColor: COLORS.gray1,
      "&:after": {
        borderColor: COLORS.white,
      },
    },
  },
});

const Label = styled("label")({
  display: "inline-flex",
  cursor: "pointer",
  marginBottom: 0,
  alignItems: "center",
});

const DisabledWrapper = styled.span<{ disabled?: boolean }>({
  padding: "2px",
});

export { Container, Checkbox, Label, DisabledWrapper };
