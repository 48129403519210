import React from "react";

import { Row, Col } from "antd";

import { Form, Formik } from "formik";

import { ExperianAuthResponse } from "manager/interfaces/api/experianAuth";
import { Input, VeroFormField } from "shared/components/Form";
import { SIZES } from "shared/components/Modal";
import Spacer from "shared/components/Spacer";

import { RegularText, ExtraStrongText } from "shared/components/Typography";
import { useDeviceType } from "shared/hooks";

import { StyledLink } from "../styled";

import { otpValidationSchema } from "./formValidation";
import { StyledModal } from "./styled";

interface OtpVerificationModalProps {
  title: string;
  submittedUser?: {
    phoneNumber?: string;
  };
  experianResponse: ExperianAuthResponse;
  submit: (values: any, { setSubmitting }) => Promise<void>;
}

interface FormikOTPInitialValues {
  otpCode: string;
  authSession: string;
}

const OtpVerificationModal = ({
  title,
  submittedUser,
  experianResponse,
  submit,
}: OtpVerificationModalProps) => {
  const { isMobile } = useDeviceType();
  const initialValues: FormikOTPInitialValues = {
    otpCode: "",
    authSession: experianResponse?.experian?.authSession,
  };
  const tryRequestKIQ = async (
    setFieldValue,
    submitForm: () => Promise<void>
  ) => {
    setFieldValue("otpCode", "000000");
    await submitForm();
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={otpValidationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        await submit(values, { setSubmitting });
      }}
      enableReinitialize
    >
      {({ isSubmitting, isValid, dirty, submitForm, setFieldValue }) => {
        const isSubmitDisabled = !(isValid && dirty);
        return (
          <Form>
            <StyledModal
              title={title}
              submitButtonLabel="Submit Code"
              submitButtonSize={SIZES.lg}
              submit={submitForm}
              submitting={isSubmitting}
              showCancelLink={false}
              isMobile={isMobile}
              submitButtonDisabled={isSubmitDisabled}
            >
              <Spacer size={Spacer.SIZES.sm} />
              <RegularText>
                For your security, we ask that you confirm your identity with a
                text message to the number below.
              </RegularText>
              <Spacer />
              <ExtraStrongText className="phone-number">
                (***) ***-*{submittedUser.phoneNumber.slice(-3)}
              </ExtraStrongText>
              <Spacer size={Spacer.SIZES.sm} />
              <RegularText>
                You will receive a 6-digit code to enter that helps us confirm
                your identity and keep your data safe.
              </RegularText>
              <Spacer size={Spacer.SIZES.sm} />
              <RegularText>
                By requesting a security code, you are authorizing us to contact
                you for the sole purpose of conducting a verification
                transaction. Standard message rates may apply.
              </RegularText>
              <Spacer size={Spacer.SIZES.lg} />
              <Row>
                <Col className="otpField" md={18}>
                  <VeroFormField
                    as={Input}
                    id="otpCode"
                    name="otpCode"
                    placeholder="######"
                    type="text"
                    error="Enter 6-digit code"
                  />
                </Col>
              </Row>
              <Spacer />
              <RegularText>
                If you no longer have access to this phone number, you can
                proceed with{" "}
                <StyledLink
                  onClick={async () => tryRequestKIQ(setFieldValue, submitForm)}
                >
                  security questions instead
                </StyledLink>
                .
              </RegularText>
              <Spacer />
            </StyledModal>
          </Form>
        );
      }}
    </Formik>
  );
};

export default OtpVerificationModal;
