const FIELD_DATA_TYPES = {
  BOOLEAN: "boolean",
  CURRENCY: "currency",
  DATE: "date",
  DAY: "day",
  DECPERCENT: "decpercent",
  EMAIL: "email",
  ENUM: "enum",
  INTEGER: "integer",
  NUMERIC: "numeric",
  PHONE: "phone",
  TEXT: "text",
  TEXTAREA: "textarea",
};

const TOOLTIP_MESSAGES = {
  BOOLEAN: "A single selection response or a True and False statement",
  CURRENCY: "A monetary value ($)",
  DATE: "i.e. YYYY-MM-DD",
  DAY: "A day of the month displayed as a number between 1 to 31",
  DECPERCENT: "A percentage value",
  EMAIL: "i.e. mikesmith@gmail.com",
  INTEGER: "A whole number",
  NUMERIC: "A number input that can also contain decimal points",
  PHONE: "i.e. (555) 555-5555",
  TEXT: "Single-line text input",
  TEXTAREA: "Multi-line text input",
  ENUM: "A selection from a specified list of values",
};

export { FIELD_DATA_TYPES, TOOLTIP_MESSAGES };
