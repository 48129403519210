import styled from "@emotion/styled";

import Icon from "shared/components/Icon";

export const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const TooltipContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const InfoIcon = styled(Icon.LargeInfoIcon)`
  width: 24px;
  height: 24px;
  margin-left: 2px;
`;
