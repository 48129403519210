export const request = (state, args) => ({
  requested: (state && state.requested) || false,
  fetching: (state && state.fetching) || false,
  error: (state && state.error) || null,
  data: (state && state.data) || null,
  ...args,
});

export const requestStart = (state, args) =>
  request(
    {
      requested: true,
      fetching: true,
      data: (state && state.data) || null,
    },
    args
  );

export const requestRefetchStart = (state) => ({
  ...state,
  requested: true,
  fetching: true,
});

export const requestSuccess = (state, args) =>
  request(
    {
      requested: true,
      fetching: false,
      error: null,
      data: state && state.data,
    },
    args
  );

export const requestError = (state, args) =>
  request(
    {
      requested: true,
      fetching: false,
      error: state && state.error,
      data: (state && state.data) || null,
    },
    args
  );

export const formatAttachment = ({
  id,
  filename,
  upload,
  pending,
  skipped,
  toBeDeleted,
  isPublic,
  uploadedByApplicant,
  isRestricted,
}) => ({
  id,
  name: filename,
  url: upload,
  pending: Boolean(pending),
  skipped: Boolean(skipped),
  toBeDeleted: Boolean(toBeDeleted),
  isPublic: Boolean(isPublic),
  uploadedByApplicant: Boolean(uploadedByApplicant),
  isRestricted: Boolean(isRestricted),
});

export const mapAttachments = (attachments) =>
  attachments.length > 0
    ? attachments.map((attach) => formatAttachment(attach))
    : null;

export const mapRequiredAttachments = (props) => {
  const { name, id, attachment } = props;
  return {
    name,
    skipped: attachment?.[0]?.skipped,
    metadata: {
      requiredAttachmentId: id,
    },
    files: attachment && mapAttachments(attachment),
  };
};

export const mapOtherAttachments = (attachment) => ({
  metadata: {
    requiredAttachmentId: null,
  },
  file: attachment && formatAttachment(attachment),
});
