import React from "react";

import { SearchableCheckboxListWithHeader } from "shared/components/Form";
import Icon from "shared/components/Icon";
import MessagesListBox from "shared/components/MessagesListBox";
import { Modal } from "shared/components/Modal";
import SelectedItemList from "shared/components/SelectedItemList";
import { WeakText } from "shared/components/Typography";
import { BREAKPOINT_TYPES } from "shared/config/constants";
import withBreakpoint from "shared/hocs/withBreakpoint";

import { AssignFormulaToPropertiesModalProps } from "./interfaces";
import { AssignFormulaToPropertiesModalContent } from "./styled";

const AssignFormulaToPropertiesModal = ({
  breakpoint,
  listApiRef,
  formulaName,
  items,
  visibleItems,
  initiallyCheckedItems,
  checkedItems,
  setCheckedItems,
  saveAssignments,
  selectedItemsSummary,
  warnings,
  isLoading,
  isUpdating,
  searchProps,
  hasWarnings,
}: AssignFormulaToPropertiesModalProps) => (
  <Modal
    title="Manage formula availability"
    subtitle={
      <>
        Formula: <strong>{formulaName}</strong>
      </>
    }
    submit={saveAssignments}
    submitButtonLabel="CONFIRM"
    // @ts-ignore
    submitButtonSize="lg"
    submitting={isUpdating}
    width="auto"
    style={{ maxWidth: 946 }}
    closeOnSubmit={false}
    submitButtonDisabled={!checkedItems?.length}
    fullScreen={BREAKPOINT_TYPES.xs === breakpoint}
    footer={<SelectedItemList label="property" items={selectedItemsSummary} />}
  >
    <Modal.Body noMobilePaddingX>
      <AssignFormulaToPropertiesModalContent hasWarnings={hasWarnings}>
        <p>
          <WeakText>
            Select properties or entire portfolios where you&apos;d like this
            recommendation formula to be active
          </WeakText>
        </p>
        {/* @ts-ignore */}
        <SearchableCheckboxListWithHeader
          listProps={{
            items,
            visibleItems,
            col1Width: "225px",
          }}
          listContainerProps={{
            checkedItemsOnChange: (changedItems) =>
              setCheckedItems(changedItems),
            detachParentChildren: false,
            checkedItems: initiallyCheckedItems,
          }}
          searchProps={searchProps}
          headerProps={{
            firstColLabel: "Select all",
          }}
          placeholder={{
            label: "Properties",
            Icon: Icon.NoPropertiesIcon,
          }}
          listApiRef={listApiRef}
          loading={isLoading}
          hasInputValue={false}
        />
        {/* @ts-ignore */}
        {hasWarnings && <MessagesListBox noBorderTop messages={warnings} />}
      </AssignFormulaToPropertiesModalContent>
    </Modal.Body>
  </Modal>
);

export default withBreakpoint(AssignFormulaToPropertiesModal);
