import React from "react";

import { Col, Divider, Form, Row } from "antd";
import { Formik, FormikHelpers } from "formik";

import { Button } from "shared/components/Button";
import FlexContainer from "shared/components/FlexContainer";
import { Input, VeroFormField } from "shared/components/Form";
import Spacer from "shared/components/Spacer";
import Tile from "shared/components/Tile";
import { RegularText, SmallText } from "shared/components/Typography";

import { ValidationSchema, initialValues } from "./config";
import { EmailVerificationFormProps } from "./interfaces";
import { LandingStepWrapper } from "./styled";

interface LandingStepProps {
  applicantName: string;
  submit: (
    values: EmailVerificationFormProps,
    formikHelpers: FormikHelpers<EmailVerificationFormProps>
  ) => void;
}

const LandingStep = ({ applicantName, submit }: LandingStepProps) => (
  <LandingStepWrapper>
    {/* @ts-ignore */}
    <Tile noBorder noMarginBottom className="header">
      {/* @ts-ignore */}
      <RegularText className="title">
        Welcome to <br />
        <span className="title-vero">VERO</span>
      </RegularText>
      <Spacer size="xl" />
      <div className="description">
        {/* @ts-ignore */}
        <RegularText>
          Complete the Verification of Residency for: <b>{applicantName}</b>
        </RegularText>
        <Divider />
        <SmallText weak>
          You’re listed as a previous manager or landlord. Take a few minutes{" "}
          <br />
          to answer questions regarding your former tenant.
        </SmallText>
      </div>
    </Tile>
    <Formik
      validationSchema={ValidationSchema}
      initialValues={initialValues}
      onSubmit={submit}
    >
      {({ submitForm, isSubmitting }) => (
        <Form>
          {/* @ts-ignore */}
          <Tile noBorder noMarginBottom className="content">
            <SmallText>
              Enter the email where this invitation was received to begin
            </SmallText>
            <Row>
              <Col xs={24} md={17}>
                {/* @ts-ignore */}
                <VeroFormField
                  as={Input}
                  required
                  id="email"
                  name="email"
                  placeholder="Enter receiving email address"
                />
              </Col>
              <Col xs={24} md={7} className="btn-col">
                <FlexContainer justifyContent="flex-end">
                  {/* @ts-ignore */}
                  <Button
                    onClick={submitForm}
                    loading={isSubmitting}
                    type="primary"
                  >
                    Get started
                  </Button>
                </FlexContainer>
              </Col>
            </Row>
          </Tile>
        </Form>
      )}
    </Formik>
  </LandingStepWrapper>
);

export default LandingStep;
