import { Helmet } from "react-helmet";
import ErrorList from "shared/components/ErrorList";
import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import Spacer from "shared/components/Spacer";
import { LargeTitle, RegularText } from "shared/components/Typography";
import { SetPasswordForm } from "shared/forms/SetPasswordForm";
import AuthPage from "shared/templates/AuthPage";
import { PageLoadSpinner, PasswordRecoverySetPasswordPageCard } from "./styled";

const PasswordRecoverySetPasswordPage = ({
  token,
  submit,
  email,
  loading,
  error,
  formikRef,
}) => {
  const initValues = {
    email,
    password: "",
    passwordConfirmation: "",
    termsOfService: false,
  };

  return (
    <>
      <Helmet>
        <title>Password Recovery</title>
      </Helmet>
      <AuthPage>
        <PasswordRecoverySetPasswordPageCard>
          <FlexContainer justifyContent="center">
            <Icon.LogoIcon />
          </FlexContainer>
          <Spacer size={Spacer.SIZES.md} />
          <LargeTitle>Password Recovery</LargeTitle>
          <RegularText weak>Please set your new password.</RegularText>
          <Spacer size={Spacer.SIZES.md} />

          {loading ? (
            <PageLoadSpinner data-testid="loading-icon" />
          ) : (
            <>
              {error && <ErrorList errors={error} />}
              {!error && (
                <SetPasswordForm
                  initialValues={initValues}
                  showEmail
                  submit={submit}
                  token={token}
                  formikRef={formikRef}
                />
              )}
            </>
          )}
        </PasswordRecoverySetPasswordPageCard>
      </AuthPage>
    </>
  );
};

export default PasswordRecoverySetPasswordPage;
