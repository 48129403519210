import styled from "@emotion/styled";

import { MEDIA_QUERIES } from "shared/config/constants";

const containerStyles = (props) => ({
  backgroundColor: "#FFF",
  borderStyle: "solid",
  borderColor: "#E8E7EE",
  ...(props.hasPadding && {
    paddingLeft: "40px",
    paddingRight: "20px",
    [MEDIA_QUERIES.w.ltSm]: {
      paddingLeft: "20px",
    },
    [MEDIA_QUERIES.w.ltMd]: {
      borderLeft: 0,
      borderRight: 0,
    },
    [MEDIA_QUERIES.w.gteSm]: {
      paddingLeft: "24px",
    },
    [MEDIA_QUERIES.w.gteLg]: {
      paddingLeft: "40px",
    },
  }),
});
export interface StyledProps {
  hasContainerPadding?: boolean;
  hasContainerDivider?: boolean;
  hasComponentPadding?: boolean;
  hasComponentDivider?: boolean;
  isLastElement?: boolean;
}

const ContainerDivider = styled.div(
  ({ hasContainerPadding: hasPadding, ...props }: StyledProps) => ({
    borderWidth: `${props.hasContainerDivider ? "1px" : "0"} 1px 0 1px`,
    ...(props.isLastElement && {
      borderBottom: "1px solid #E8E7EE",
    }),
    ...containerStyles({ ...props, hasPadding }),
  })
);

const ComponentDivider = styled.div(
  ({ hasComponentPadding: hasPadding, ...props }: StyledProps) => ({
    borderWidth: `${props.hasComponentDivider ? "1px" : "0"} 1px 0 1px`,
    ...(props.isLastElement && {
      borderBottom: "1px solid #E8E7EE",
    }),
    ...containerStyles({ ...props, hasPadding }),
  })
);

const Divider = styled.div(() => ({
  marginBottom: "16px",
  [MEDIA_QUERIES.w.ltSm]: {
    marginBottom: "12px",
  },
}));

export { ComponentDivider, ContainerDivider, Divider };
