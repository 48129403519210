import React from "react";

import { useParams } from "react-router-dom";

import { getQueryParams } from "shared/utils/routing";

import { LeaseRentOptions, LeaseRentOptionsProps } from "./component";

export const LeaseRentOptionsPage: React.FC<{
  propertyMagicFragment?: string;
  unitMagicFragment?: string;
}> = ({ propertyMagicFragment = null, unitMagicFragment = null }) => {
  let props: LeaseRentOptionsProps;

  // eslint-disable-next-line no-restricted-globals
  const mock = new URLSearchParams(location.search).has("mock");

  const {
    "lease-start": leaseStart,
    "lease-months": leaseMonthsParam,
    application: appId,
  } = getQueryParams();

  const leaseMonths = Number(leaseMonthsParam) || undefined;

  if (propertyMagicFragment && unitMagicFragment) {
    props = {
      propertyId: {
        propertyId: propertyMagicFragment,
        unitId: unitMagicFragment,
      },
      isTestMode: mock,
    };
  } else {
    const params = useParams<{ propertyHashId: string; unitHashId: string }>();
    props = {
      propertyId: {
        propertyId: params.propertyHashId,
        unitId: params.unitHashId,
      },
      isTestMode: mock,
    };
  }

  props.leaseMonths = leaseMonths;
  props.leaseStart = leaseStart as string;
  props.appId = appId as string;

  return (
    <div style={{ height: "100%", background: "white" }}>
      <LeaseRentOptions {...props} />
    </div>
  );
};
