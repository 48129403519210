import React from "react";

import { PopoverContent } from "shared/components/Form/Input";

import { INTEGRATIONS_MARK_SIZE } from "./IntegrationMark.confg";
import { IntegrationMarkProps } from "./interfaces";
import { StyledPopover } from "./styled";

const IntegrationMark = ({
  IntegrationIcon,
  popoverLabel,
  size = INTEGRATIONS_MARK_SIZE.sm,
  disabledPopover = false,
  popoverStyle,
  popoverClassName,
  markRef,
}: IntegrationMarkProps) => (
  <StyledPopover
    size={size}
    className="integration-mark-popover"
    disabled={disabledPopover}
    withoutOverlay
    overlayStyle={popoverStyle}
    style={popoverStyle}
    overlayClassName={popoverClassName}
    // @ts-ignore
    trigger="hover"
    content={<PopoverContent>{popoverLabel}</PopoverContent>}
  >
    <span className="integration-mark" ref={markRef}>
      <IntegrationIcon />
    </span>
  </StyledPopover>
);

export default IntegrationMark;
