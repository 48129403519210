import React, { useEffect } from "react";

import { Field, useFormikContext } from "formik";

import { LUMEN_FILTER_CONDITION_AMOUNT_TIME_OPERATOR_TYPE } from "manager/config/lumen.config";
import { Input, Select } from "shared/components/Form";
import { addDefaultObjectValues, showError } from "shared/utils/forms";

import BaseCondition from "./BaseCondition";
import { commonFilterConditionPropTypes } from "./lumenFilterConditions.config";
import {
  getBaseConditionPath,
  getConditionAmountOfTimeOperatorOptions,
  getConditionPropPath,
} from "./lumenFilterConditions.util";

const ConditionNumberOfRecords = ({ idx, onDelete }) => {
  const { values, errors, touched, setFieldTouched, setFieldValue } =
    useFormikContext();
  const numOfRecordsOperatorProp = "numOfRecordsOperator";
  const numOfRecordsValueProp = "numOfRecordsValue";
  const numOfRecordsBasePath = getBaseConditionPath(idx);
  const numOfRecordsOperatorPropPath = getConditionPropPath(
    idx,
    numOfRecordsOperatorProp
  );
  const numOfRecordsValuePropPath = getConditionPropPath(
    idx,
    numOfRecordsValueProp
  );

  useEffect(() => {
    const defaultValues = {
      [numOfRecordsOperatorProp]:
        LUMEN_FILTER_CONDITION_AMOUNT_TIME_OPERATOR_TYPE.LESS_THAN,
      [numOfRecordsValueProp]: null,
    };
    addDefaultObjectValues(
      values,
      numOfRecordsBasePath,
      defaultValues,
      setFieldValue
    );
  }, []);

  return (
    <BaseCondition conditionLabel="Number of records:" onDelete={onDelete}>
      <div className="medium-select">
        <Field
          name={numOfRecordsOperatorPropPath}
          id={numOfRecordsOperatorPropPath}
          label="Select"
          as={Select}
          error={showError(numOfRecordsOperatorPropPath, touched, errors)}
          onChange={(value) =>
            setFieldValue(numOfRecordsOperatorPropPath, value)
          }
          onBlur={() => setFieldTouched(numOfRecordsOperatorPropPath, true)}
        >
          {getConditionAmountOfTimeOperatorOptions().map((option) => (
            <Select.Option key={option.key}>{option.label}</Select.Option>
          ))}
        </Field>
      </div>

      <span className="than-concat">than</span>

      <div className="normal-input">
        <Field
          id={numOfRecordsValuePropPath}
          name={numOfRecordsValuePropPath}
          label="Number of records"
          as={Input}
          type="number"
          error={showError(numOfRecordsValuePropPath, touched, errors)}
        />
      </div>
    </BaseCondition>
  );
};

ConditionNumberOfRecords.propTypes = {
  ...commonFilterConditionPropTypes,
};

export default ConditionNumberOfRecords;
