import PropTypes from "prop-types";
import * as Yup from "yup";

import { validUrl } from "shared/forms/validation/fieldValidators";

export const openApiSettingsPropType = PropTypes.shape({
  webhookEnabled: PropTypes.bool,
  webhookUrl: PropTypes.string,
  redirectUrl: PropTypes.string,
});

export const validationSchema = Yup.object().shape({
  webhookEnabled: Yup.boolean(),
  webhookUrl: validUrl.when("webhookEnabled", {
    is: true,
    then: Yup.string().required(
      "Webhook url is required when notifications are enabled"
    ),
  }),
  redirectUrl: validUrl,
});
