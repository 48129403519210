import React from "react";

import PropTypes from "prop-types";

import Icon from "shared/components/Icon";
import Tooltip from "shared/components/Tooltip";

import { LogoContainer, LogoPreview, IconContainer } from "./styled";

const PropertyLogo = ({
  openUploadLogoModal,
  propertyLogo,
  canUploadPropertyLogo,
}) => {
  return (
    <LogoContainer>
      {propertyLogo ? (
        <LogoPreview
          alt="property-logo"
          src={propertyLogo}
          data-testid="preview-logo"
        />
      ) : (
        <Icon.DefaultLogoIcon data-testid="default-logo" />
      )}
      <Tooltip
        theme="dark"
        title={
          !canUploadPropertyLogo &&
          "You're not allowed to perform this action, please contact our support"
        }
        trigger="click"
      >
        <IconContainer>
          <Icon.UploadIcon
            onClick={openUploadLogoModal}
            data-testid="upload-icon"
          />
        </IconContainer>
      </Tooltip>
    </LogoContainer>
  );
};

PropertyLogo.propTypes = {
  openUploadLogoModal: PropTypes.func.isRequired,
  propertyLogo: PropTypes.string,
  canUploadPropertyLogo: PropTypes.bool,
};

PropertyLogo.defaultProps = {
  propertyLogo: null,
  canUploadPropertyLogo: false,
};

export default PropertyLogo;
