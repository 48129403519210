import React, { useState, ReactNode } from "react";

import { BREAKPOINT_TYPES } from "shared/config/constants";

import { ChevronUpIcon, ChevronDownIcon, Header, Children } from "./styled";

interface CollapseProps {
  header: (collapsed) => void;
  children: ReactNode;
  defaultCollapsed?: boolean;
  small?: boolean;
  onlyCollapseBelowBreakpoint?: typeof BREAKPOINT_TYPES | string;
  childrenFullHeight?: boolean;
  [key: string]: any;
}

const Collapse = ({
  header,
  children,
  defaultCollapsed = true,
  small = false,
  onlyCollapseBelowBreakpoint = undefined,
  childrenFullHeight = false,
  ...props
}: CollapseProps) => {
  const [collapsed, setCollapsed] = useState(defaultCollapsed);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };
  const iconProps = { onlyCollapseBelowBreakpoint };
  const CollapseIcon = (innerProps) => (
    <>
      {collapsed ? (
        <ChevronDownIcon {...iconProps} {...innerProps} />
      ) : (
        <ChevronUpIcon {...iconProps} {...innerProps} />
      )}
    </>
  );

  return (
    <div {...props}>
      <Header
        onClick={toggleCollapse}
        onKeyPress={toggleCollapse}
        role="button"
        tabIndex={0}
        onlyCollapseBelowBreakpoint={onlyCollapseBelowBreakpoint}
      >
        {header({ CollapseIcon, collapsed })}
      </Header>
      <Children
        small={small}
        onlyCollapseBelowBreakpoint={onlyCollapseBelowBreakpoint}
        collapsed={collapsed}
        fullHeight={childrenFullHeight}
      >
        {children}
      </Children>
    </div>
  );
};

export default Collapse;
