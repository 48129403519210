import styled from "@emotion/styled";

import Icon from "shared/components/Icon";

export const LoadingIcon = styled(Icon.LoadingSpinnerGreenLargeIcon)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
`;
