import _ from 'lodash'
import React, { useState } from 'react'
import { useApplicantWorkflowMutations } from 'shared/hooks/api'
import { FormulaEditor } from 'shared/components/vega/ExpressionEngine'
import BasicEditor from './BasicEditor'

const ApplicantWorkflowEditor = ({ workflow, canEdit = false }) => {
  const [resolvedWorkflow, setWorkflow] = useState(workflow)
  const [expressionFormula, setExpressionFormula] = useState(resolvedWorkflow?.expression?.formula)

  const { updateApplicantWorkflow, deleteApplicantWorkflow } = useApplicantWorkflowMutations()

  const canSave = canEdit && _.some([resolvedWorkflow?.expression?.formula !== expressionFormula])
  const onReset = () => setExpressionFormula(resolvedWorkflow?.expression?.formula)
  const onDelete = () => deleteApplicantWorkflow(resolvedWorkflow?.id)

  const onSave = async () => {
    setWorkflow(await updateApplicantWorkflow({
      id: resolvedWorkflow?.id,
      data: {
        formula: expressionFormula
      }
    }))
  }

  return (
    <div className='vega flex no-wrap no-gap applicant-workflow workflow-editor'>
      <BasicEditor
        formula={expressionFormula}
        canEdit={canEdit}
        onChange={setExpressionFormula}
      />

      <FormulaEditor
        formula={expressionFormula}
        canSave={canSave}
        canEdit={canEdit}
        onChange={setExpressionFormula}
        onReset={onReset}
        onSave={onSave}
        onDelete={onDelete}
      />
    </div>
  )
}

export default ApplicantWorkflowEditor
