import React from "react";

import { useRenamePortfolio } from "manager/hooks/api";
import useModal from "shared/hooks/useModal";

import RenamePortfolioModal from "./RenamePortfolioModal";

interface RenamePortfolioModalContainerProps {
  name: string;
  portfolioId: string | number;
}

const RenamePortfolioModalContainer = ({
  name,
  portfolioId,
}: RenamePortfolioModalContainerProps) => {
  const { closeActiveModalDialog } = useModal();
  const { renamePortfolio } = useRenamePortfolio({
    oldName: name,
    portfolioId,
  });
  const submit = ({ name: newName }: { name: string }) => {
    renamePortfolio(newName, {
      onSuccess: () => closeActiveModalDialog(),
    });
  };

  return <RenamePortfolioModal name={name} submit={submit} />;
};

export default RenamePortfolioModalContainer;
