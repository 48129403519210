import produce from "immer";
import merge from "lodash/merge";
import { normalize } from "normalizr";
import { handleActions } from "redux-actions";

import * as dealActions from "shared/state/Deal/actions";
import * as dealSchemas from "shared/state/Deal/schema";
import * as unitActions from "shared/state/Unit/actions";

import * as actions from "../actions";
import * as schemas from "../schema";

const initialState = {};

const propertyEntityReducer = handleActions(
  {
    [actions.receiveProperty]: (state, action) => {
      const { property } = action.payload;
      return produce(state, (draft) =>
        merge(
          draft,
          normalize([property], schemas.propertyList).entities.property
        )
      );
    },
    [actions.receiveProperties]: (state, action) => {
      const { properties } = action.payload;
      return produce(state, (draft) =>
        merge(
          draft,
          normalize(properties, schemas.propertyList).entities.property
        )
      );
    },
    [dealActions.receiveDeals]: (state, action) => {
      const { deals } = action.payload;
      return produce(state, (draft) =>
        merge(draft, normalize(deals, dealSchemas.dealList).entities.property)
      );
    },
    [unitActions.receiveUnit]: (state, action) =>
      produce(state, (draft) =>
        merge(
          draft,
          normalize([action.payload.unit.property], schemas.propertyList)
            .entities.property
        )
      ),
    [dealActions.receiveDeal]: (state, action) => {
      const { deal } = action.payload;
      return produce(state, (draft) =>
        merge(draft, normalize([deal], dealSchemas.dealList).entities.property)
      );
    },
  },
  initialState
);

export default propertyEntityReducer;
