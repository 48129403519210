import React from "react";

import App from "shared/components/App";
import { RenderModal } from "shared/components/Modal";
import { APPS } from "shared/config/constants";

import AdminRouter from "./AdminRouter";

const AdminApp = () => {
  const id: string = APPS.admin;

  return (
    <App
      id={id}
      render={({ user, notifications }) => (
        <>
          <AdminRouter user={user} notifications={notifications} />
          <RenderModal />
        </>
      )}
    />
  );
};

export default AdminApp;
