import React from "react";

import Icon from "shared/components/Icon";

import { LoadingContainer } from "./styled";

const FullScreenLoader = () => (
  <LoadingContainer>
    <Icon.LoadingSpinnerGreenLargeIcon />
  </LoadingContainer>
);

export default FullScreenLoader;
