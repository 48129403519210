import React from "react";

import { StyledSteps, StyledStep, DarkTheme } from "./styled";

export const NUMBERED_STEP_DIRECTION = Object.freeze({
  horizontal: "horizontal",
  vertical: "vertical",
});

export const NUMBERED_STEP_SIZE = Object.freeze({
  default: "default",
  small: "small",
});

export interface NumberedStepsProps {
  steps:
    | string[]
    | {
        label?: string;
        completed?: boolean;
      }[];
  current: number;
  onStepSelection?: (index: number) => void;
  isStepDisabled?: (index: number) => boolean;
  direction?: "horizontal" | "vertical";
  size?: "default" | "small";
  theme?: DarkTheme | any;
  className?: string;
}

export const NumberedSteps = ({
  steps,
  current,
  onStepSelection,
  isStepDisabled,
  direction,
  size,
  theme,
  className,
}: NumberedStepsProps) => {
  return (
    <StyledSteps
      labelPlacement="vertical"
      direction={direction}
      size={size}
      theme={theme}
      current={current}
      onChange={(index) => {
        return onStepSelection && onStepSelection(index);
      }}
      className={className}
    >
      {steps.map((item, index) => {
        const key = `step-item-${index}`;
        return (
          <StyledStep
            key={key}
            vertical={direction === "vertical" ? 1 : 0}
            title={item.label}
            status={item.completed && current !== index ? "finish" : null}
            disabled={isStepDisabled && isStepDisabled(index)}
            theme={theme}
          />
        );
      })}
    </StyledSteps>
  );
};
