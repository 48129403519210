import { routerMiddleware as createRouterMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";

import { APPS } from "shared/config/constants";
import { reducers as sharedReducers } from "shared/state";
import { getActiveApp, isRenterLikeApp } from "shared/utils/auth";
import {
  initModalManagerSyncListener,
  modalManagerReduxSyncMiddleware,
} from "shared/utils/modalmanager";

import createRootReducer from "./reducers";
import createRootSaga from "./sagas";

const history = createBrowserHistory();
const routerMiddleware = createRouterMiddleware(history);

const sagaMiddleware = createSagaMiddleware();
const middlewares = [
  sagaMiddleware,
  routerMiddleware,
  modalManagerReduxSyncMiddleware,
];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const getStoreRoot = () => {
  if (isRenterLikeApp()) {
    return APPS.renter;
  }
  return getActiveApp();
};

function configureStore(initialState, appReducers, sagas) {
  const storeRoot = getStoreRoot();

  const getRootReducer = () => {
    const applicationReducer = createRootReducer(history, {
      shared: sharedReducers,
      ...(appReducers && { [storeRoot]: appReducers }),
    });

    return (state, action) => {
      return applicationReducer(state, action);
    };
  };

  const store = createStore(
    getRootReducer(),
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  sagaMiddleware.run(createRootSaga(sagas));

  if (process.env.NODE_ENV !== "production" && module.hot) {
    module.hot.accept("./reducers", () => {
      store.replaceReducer(getRootReducer());
    });
  }

  initModalManagerSyncListener(store);

  return store;
}

export { configureStore, history };
