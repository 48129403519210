import dayjs from "dayjs";
import * as Yup from "yup";

enum CLOSE_LISTING_FIELDS {
  unitListingCloseType = "unitListingCloseType",
  rentedFrom = "rentedFrom",
  rentedUntil = "rentedUntil",
  rent = "rent",
  securityDeposit = "securityDeposit",
}

enum CLOSE_LISTING_TYPE {
  CLOSE_LISTING,
  MARK_UNIT_AS_RENTED,
}

const INITIAL_VALUES = {
  unitListingCloseType: null,
  rentedFrom: null,
  rentedUntil: null,
  rent: null,
  securityDeposit: null,
};

const CloseListingValidationSchema = Yup.object().shape({
  unitListingCloseType: Yup.number()
    .oneOf([
      CLOSE_LISTING_TYPE.CLOSE_LISTING,
      CLOSE_LISTING_TYPE.MARK_UNIT_AS_RENTED,
    ])
    .nullable()
    .required("Please select an option to close the unit"),
  rentedFrom: Yup.date()
    .nullable()
    .when("rentedUntil", (rentedUntil, schema) => {
      const maxLimit = rentedUntil;
      return schema.test(
        "rentedFrom",
        "Lease start date cannot be after the end date",
        (value) => {
          return !value || !dayjs(value).isAfter(maxLimit);
        }
      );
    }),
  rentedUntil: Yup.date().nullable(),
  rent: Yup.number().nullable(),
  securityDeposit: Yup.number().nullable(),
});

export {
  CloseListingValidationSchema,
  INITIAL_VALUES,
  CLOSE_LISTING_FIELDS,
  CLOSE_LISTING_TYPE,
};
