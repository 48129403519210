import React, { useState, Key } from "react";

import find from "lodash/find";
import isArray from "lodash/isArray";
import moment from "moment";

import FilterPopover from "shared/components/FilterPopover";
import { DatePicker, Select } from "shared/components/Form";
import Icon from "shared/components/Icon";
import { UnderlineLink } from "shared/components/Links";
import { BREAKPOINT_TYPES, SORT_TYPES } from "shared/config/constants";
import withBreakpoint from "shared/hocs/withBreakpoint";

import SwitchLight from "../Form/SwitchLight/SwitchLight";

import { DROPDOWN_FILTER_MODES } from "./config";

import {
  Container,
  FilterItem,
  Text,
  Search,
  Select as StyledSelect,
  StyledMultiselect,
  SortOrder,
  DatePickerSpacer,
} from "./styled";

const { Option } = Select;

const TOOLTIP_FILTER_BAR = [BREAKPOINT_TYPES.sm, BREAKPOINT_TYPES.md];

export type SortType = {
  label: string;
  onClick: () => void;
};

interface IDropdownFilters {
  key?: string;
  onChange: (value: any) => void;
  value?: any;
  items?: {
    label: any;
    key: Key;
  }[];
  label?: string;
  mode?: string | typeof DROPDOWN_FILTER_MODES;
  order?: number;
  minWidth?: any;
  disabled?: boolean;
  showOnEnd?: boolean;
}

export interface FilterPopoverProps {
  breakpoint?: BREAKPOINT_TYPES;
  search?: {
    action: any;
    placeholder: string;
    width?: number;
    value?: string;
    debounce?: boolean;
    disabled?: boolean;
  };
  onDropdownVisibleChange?: () => void;
  sort?: {
    ascending: SortType;
    descending: SortType;
    disabled?: boolean;
  };
  dropdownFilters?: IDropdownFilters[];
  checkboxFilters?: {
    key?: string;
    label?: string;
    onChange: (value: any) => void;
    value?: boolean;
    order?: number;
    disabled?: boolean;
  }[];
  dateRange?: {
    label?: string;
    setValues?: (date) => void;
    fromValue?: Date | string;
    toValue?: Date | string;
    date1Id?: string;
    date2Id?: string;
    disabled?: boolean;
  };
  clearFilters?: {
    onClick: () => void;
    order?: number;
    grow?: boolean;
  };
  alt?: boolean;
  debounce?: boolean;
  loadingDropdownOptions?: boolean;
  breakpointFilterPopover?: string[];
  action?: {
    key?: string;
    node?: any;
  };
  justifyContent?: string;
  displayOnMobile?: boolean;
}

const FilterBar = ({
  breakpoint = undefined,
  search = undefined,
  sort = undefined,
  dropdownFilters = undefined,
  checkboxFilters = undefined,
  dateRange = undefined,
  onDropdownVisibleChange = undefined,
  loadingDropdownOptions = false,
  clearFilters = undefined,
  breakpointFilterPopover = undefined,
  alt = false,
  action = undefined,
  justifyContent = undefined,
  ...props
}: FilterPopoverProps) => {
  const [sortOrder, setSortOrder] = useState(SORT_TYPES.ASCENDING);

  const breakpointForPopover = breakpointFilterPopover || TOOLTIP_FILTER_BAR;
  const isShowPopover =
    breakpointForPopover.indexOf(breakpoint) > -1 &&
    (sort || dropdownFilters || dateRange);

  const toggleSortOrder = () => {
    const oppositeSort =
      sortOrder === SORT_TYPES.ASCENDING
        ? SORT_TYPES.DESCENDING
        : SORT_TYPES.ASCENDING;
    setSortOrder(oppositeSort);
    sort[sortOrder].onClick();
  };

  const filterPopoverProps = {
    sort,
    dateRange,
    dropdownFilters,
    checkboxFilters,
    onDropdownVisibleChange,
    loadingDropdownOptions,
    clearFilters,
  };

  const dropdownFilterStart = isArray(dropdownFilters)
    ? dropdownFilters?.filter((dp: IDropdownFilters) => !dp.showOnEnd)
    : [];
  const dropdownFilterEnd = isArray(dropdownFilters)
    ? dropdownFilters?.filter((dp: IDropdownFilters) => dp.showOnEnd)
    : [];

  const filterDropdownRender = (dropdownFiltersArray: IDropdownFilters[]) =>
    dropdownFiltersArray?.map(
      ({
        key: dropdownKey,
        onChange,
        items,
        mode,
        label: dropdownLabel,
        order,
        value,
        minWidth,
        disabled,
      }) => {
        return mode === DROPDOWN_FILTER_MODES.multiselect ? (
          <FilterItem key={dropdownKey} order={order}>
            <StyledMultiselect
              value={value}
              options={items}
              placeholder={dropdownLabel}
              onChange={onChange}
              optionFilterProp="label"
              id={dropdownKey}
              name={dropdownKey}
              onDropdownVisibleChange={onDropdownVisibleChange}
              loading={loadingDropdownOptions}
              minWidth={minWidth}
              disabled={disabled}
              dropdownMatchSelectWidth={false}
            />
          </FilterItem>
        ) : (
          <FilterItem key={dropdownKey} order={order}>
            <StyledSelect
              data-testid="select"
              value={
                loadingDropdownOptions || !find(items, ["key", value])
                  ? items[0]?.key
                  : value
              }
              onChange={onChange}
              id={dropdownKey}
              name={dropdownKey}
              optionFilterProp="label"
              onDropdownVisibleChange={onDropdownVisibleChange}
              loading={loadingDropdownOptions}
              minWidth={minWidth}
              disabled={disabled}
              dropdownMatchSelectWidth={false}
            >
              {items.map(({ label, key }) => (
                <Option value={key} key={key} label={label}>
                  {label}
                </Option>
              ))}
            </StyledSelect>
          </FilterItem>
        );
      }
    );

  return (
    <Container alt={alt} justifyContent={justifyContent} {...props}>
      <div>
        {search && (
          <FilterItem>
            <Search
              id="search-table"
              name="search-table"
              value={search.value}
              label={search.placeholder}
              style={{ width: search.width ? search.width : 200 }}
              onSearch={search.action}
              debounce={search.debounce}
              disabled={search.disabled}
            />
          </FilterItem>
        )}
        {isShowPopover ? (
          // @ts-ignore
          <FilterPopover {...filterPopoverProps} />
        ) : (
          <>
            {sort && (
              <FilterItem
                onClick={toggleSortOrder}
                role="button"
                disabled={sort.disabled}
              >
                <SortOrder>
                  {sortOrder === SORT_TYPES.ASCENDING ? (
                    <Icon.ArrowDownIcon />
                  ) : (
                    <Icon.ArrowUpIcon />
                  )}
                  <Text>{sort[sortOrder].label}</Text>
                </SortOrder>
              </FilterItem>
            )}
            {filterDropdownRender(dropdownFilterStart)}
            {checkboxFilters?.map(
              ({
                key: dropdownKey,
                onChange,
                label,
                order,
                value,
                disabled,
              }) => (
                <FilterItem key={dropdownKey} order={order && order}>
                  <SwitchLight
                    id={dropdownKey}
                    value={value}
                    onChange={onChange}
                    title={<span>{label}</span>}
                    disabled={disabled}
                    noPadding
                  />
                </FilterItem>
              )
            )}

            {dateRange && (
              <FilterItem title={dateRange.label}>
                <DatePicker
                  setFieldValue={(name, date) => {
                    dateRange.setValues({
                      [name]: date,
                      [dateRange.date2Id]: null,
                    });
                  }}
                  id={dateRange.date1Id}
                  name={dateRange.date1Id}
                  label="From"
                  value={dateRange.fromValue}
                  disabled={dateRange.disabled}
                />
                <DatePickerSpacer>&ndash;</DatePickerSpacer>
                <DatePicker
                  setFieldValue={(name, date) => {
                    dateRange.setValues({
                      [name]: date,
                    });
                  }}
                  id={dateRange.date2Id}
                  name={dateRange.date2Id}
                  label="To"
                  value={dateRange.toValue}
                  disabledDate={(date) =>
                    dateRange.fromValue &&
                    moment(date).isBefore(dateRange.fromValue)
                  }
                  disabled={dateRange.disabled}
                />
              </FilterItem>
            )}
          </>
        )}
        {filterDropdownRender(dropdownFilterEnd)}
        {clearFilters && !isShowPopover && (
          <FilterItem
            order={clearFilters.order}
            noShrink
            grow={clearFilters.grow}
          >
            <UnderlineLink onClick={clearFilters.onClick}>
              Clear filters
            </UnderlineLink>
          </FilterItem>
        )}
      </div>
      {action && action.node}
    </Container>
  );
};

FilterBar.SORT_TYPES = SORT_TYPES;

export default withBreakpoint(FilterBar);
