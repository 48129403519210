import React from "react";

import { useHistory } from "react-router-dom";

import {
  CreateUnitModal,
  CreateUnitSuccessModal,
  UnassignPropertyFromPortfolioModal,
} from "manager/components/Modal";
import AssignEmployeesToProperty from "manager/components/Modal/AssignEmployeesToPropertyModal/";
import ROUTES from "manager/config/routes";
import { Property } from "manager/interfaces/api";
import useModal from "shared/hooks/useModal";

import {
  AssignEmployeesToPropertyParams,
  CreateUnitParams,
  UnassignFromPortfolioParams,
} from "../interfaces";

import PropertiesTable from "./PropertiesTable";

interface PropertiesTableContainerProps {
  refreshPortfolioData: () => void;
  hasInputValue: boolean;
  properties: Property[];
  loading: boolean;
}

const PropertiesTableContainer = ({
  refreshPortfolioData,
  hasInputValue,
  properties,
  loading,
}: PropertiesTableContainerProps) => {
  const history = useHistory();
  const { openModalDialog } = useModal();

  const createUnit = ({ propertyId, propertyName }: CreateUnitParams) => {
    const params = {
      propertyId,
      propertyName,
      afterUnitCreate: (unit) => {
        refreshPortfolioData();

        openModalDialog(CreateUnitSuccessModal, {
          unitId: unit.id,
          unitName: unit.name,
          propertyName: unit.propertyName,
        });
      },
    };
    openModalDialog(CreateUnitModal, params);
  };

  const onRowClick = (key: string) =>
    history.push(ROUTES.property.replace(":id", key));

  const assignEmployeesToProperty = (
    context: AssignEmployeesToPropertyParams
  ) => openModalDialog(AssignEmployeesToProperty, context);
  const unassignFromPortfolio = (context: UnassignFromPortfolioParams) =>
    openModalDialog(UnassignPropertyFromPortfolioModal, context);

  return (
    <PropertiesTable
      hasInputValue={hasInputValue}
      properties={properties}
      loading={loading}
      createUnit={createUnit}
      assignEmployeesToProperty={assignEmployeesToProperty}
      unassignFromPortfolio={unassignFromPortfolio}
      onRowClick={onRowClick}
    />
  );
};

export default PropertiesTableContainer;
