import React from "react";

import { Col, Row } from "antd";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";

import { sourcePropType } from "manager/utils/propTypes";
import DetectableOverflow from "shared/components/DetectableOverflow";
import {
  Input,
  PhoneNumberInput,
  Select,
  VeroFormField,
} from "shared/components/Form";
import { Modal } from "shared/components/Modal";
import NumberTitle from "shared/components/NumberTitle";
import {
  BREAKPOINT_TYPES,
  PROVIDER,
  VALIDATION_MESSAGES,
} from "shared/config/constants";
import {
  validFirstName,
  validLastName,
  validEmail,
  validPhone,
} from "shared/forms/validation/fieldValidators";
import withBreakpoint from "shared/hocs/withBreakpoint";
import { breakpointPropType } from "shared/utils/propTypes";

const INITIAL_VALUES = {
  email: undefined,
  firstName: undefined,
  lastName: undefined,
  phoneNumber: undefined,
  property: undefined,
  floorplan: undefined,
  leadSource: undefined,
};

export const getLeadValidationSchema = (
  leadSourceRequired,
  floorplanRequired
) =>
  Yup.object().shape({
    email: validEmail.required(VALIDATION_MESSAGES.fieldRequired),
    firstName: validFirstName.required(VALIDATION_MESSAGES.fieldRequired),
    lastName: validLastName.required(VALIDATION_MESSAGES.fieldRequired),
    phoneNumber: validPhone,
    property: Yup.number().nullable(),
    floorplan: Yup.string().concat(
      floorplanRequired
        ? Yup.string().required(VALIDATION_MESSAGES.fieldRequired)
        : null
    ),
    leadSource: Yup.number().concat(
      leadSourceRequired
        ? Yup.number().required(VALIDATION_MESSAGES.fieldRequired)
        : null
    ),
  });

const isFloorplanRequired = (selectedProperty) => {
  return selectedProperty?.provider === PROVIDER.ENTRATA;
};

const InviteLeadModal = ({
  breakpoint,
  submit,
  leadSourceRequired,
  selectedProperty,
  prescreeningProperties,
  isPrescreeningPropertiesLoading,
  onPropertyChange,
  leadSources,
  leadSourcesLoading,
}) => {
  return (
    <Formik
      initialValues={INITIAL_VALUES}
      validationSchema={getLeadValidationSchema(
        leadSourceRequired,
        isFloorplanRequired(selectedProperty)
      )}
      enableReinitialize
      onSubmit={submit}
    >
      {({ submitForm, isSubmitting, setFieldValue, setFieldTouched }) => {
        return (
          <Form>
            <Modal
              title="Invite lead to prequalify"
              submitButtonLabel="Invite"
              submit={submitForm}
              submitting={isSubmitting}
              closeOnSubmit={false}
              width="auto"
              style={{ maxWidth: 946 }}
              fullScreen={BREAKPOINT_TYPES.xs === breakpoint}
            >
              <Modal.Body spacer>
                <NumberTitle number={1}>Enter lead information</NumberTitle>
                <Row gutter={[20, 32]}>
                  <Col md={{ span: 8 }}>
                    <VeroFormField
                      as={Input}
                      id="email"
                      name="email"
                      label="Email"
                      required
                      type="email"
                    />
                  </Col>
                  <Col md={{ span: 8 }}>
                    <VeroFormField
                      as={Input}
                      id="firstName"
                      name="firstName"
                      label="First Name"
                      required
                      type="text"
                    />
                  </Col>
                  <Col md={{ span: 8 }}>
                    <VeroFormField
                      as={Input}
                      id="lastName"
                      name="lastName"
                      label="Last Name"
                      required
                      type="text"
                    />
                  </Col>
                </Row>
                <Row gutter={[20, 32]} className="row-margin-space-double">
                  <Col md={{ span: 8 }}>
                    <VeroFormField
                      as={PhoneNumberInput}
                      id="phoneNumber"
                      name="phoneNumber"
                      type="text"
                      label="Phone Number"
                      setFieldValue={setFieldValue}
                    />
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Body spacer>
                <NumberTitle number={2}>
                  Find and select property details
                </NumberTitle>
                <Row gutter={[20, 32]}>
                  <Col md={{ span: 8 }}>
                    <VeroFormField
                      as={Select}
                      name="property"
                      id="property"
                      label="Select Property"
                      optionFilterProp="label"
                      loading={isPrescreeningPropertiesLoading}
                      onChange={(propertyId) =>
                        onPropertyChange(
                          propertyId,
                          setFieldValue,
                          setFieldTouched
                        )
                      }
                    >
                      {prescreeningProperties
                        .filter(
                          (property) =>
                            property.provider !== PROVIDER.ENTRATA ||
                            property.floorplans?.length > 0
                        )
                        .map((property) => (
                          <Select.Option
                            key={property.id}
                            label={property.name}
                            value={property.id}
                          >
                            <DetectableOverflow title={property.name}>
                              {property.name}
                            </DetectableOverflow>
                          </Select.Option>
                        ))}
                    </VeroFormField>
                  </Col>
                  {selectedProperty?.provider === PROVIDER.ENTRATA && (
                    <Col md={{ span: 8 }}>
                      <VeroFormField
                        as={Select}
                        name="floorplan"
                        id="floorplan"
                        label="Select Floor Plan"
                        optionFilterProp="label"
                        loading={isPrescreeningPropertiesLoading}
                        required={isFloorplanRequired(selectedProperty)}
                        onChange={(floorplanId) =>
                          setFieldValue("floorplan", floorplanId)
                        }
                      >
                        {selectedProperty.floorplans.map((floorplan) => (
                          <Select.Option
                            key={floorplan.id}
                            label={floorplan.name}
                            value={floorplan.id}
                          >
                            <DetectableOverflow title={floorplan.name}>
                              {floorplan.name}
                            </DetectableOverflow>
                          </Select.Option>
                        ))}
                      </VeroFormField>
                    </Col>
                  )}
                </Row>
              </Modal.Body>
              <Modal.Body>
                <NumberTitle number={3}>
                  How did the lead first come in contact with you?
                </NumberTitle>
                <Row gutter={[20, 32]}>
                  <Col md={{ span: 8 }}>
                    <VeroFormField
                      as={Select}
                      name="leadSource"
                      id="leadSource"
                      required={leadSourceRequired}
                      label="Select Lead Source"
                      loading={leadSourcesLoading}
                    >
                      {leadSources.map((source) => (
                        <Select.Option key={source.id} value={source.id}>
                          {source.value}
                        </Select.Option>
                      ))}
                    </VeroFormField>
                  </Col>
                </Row>
              </Modal.Body>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};

InviteLeadModal.propTypes = {
  breakpoint: breakpointPropType.isRequired,
  submit: PropTypes.func.isRequired,
  leadSourceRequired: PropTypes.bool.isRequired,
  selectedProperty: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    yardiConnected: PropTypes.bool.isRequired,
    provider: PropTypes.string,
    floorplans: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      })
    ),
  }),
  prescreeningProperties: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      yardiConnected: PropTypes.bool.isRequired,
    })
  ),
  isPrescreeningPropertiesLoading: PropTypes.bool.isRequired,
  onPropertyChange: PropTypes.func.isRequired,
  leadSources: PropTypes.arrayOf(sourcePropType),
  leadSourcesLoading: PropTypes.bool.isRequired,
};

InviteLeadModal.defaultProps = {
  prescreeningProperties: [],
  leadSources: [],
};

export default withBreakpoint(InviteLeadModal);
