import React from "react";

import Icon from "shared/components/Icon";
import { SIZES } from "shared/components/Modal";
import Spacer from "shared/components/Spacer";
import { RegularText } from "shared/components/Typography";

import { useDeviceType } from "shared/hooks";

import { StyledModal } from "../styled";

interface ErrorModalProps {
  title: string;
  submit: () => void;
  customMessage?: any;
}

const ErrorModal = ({ title, submit, customMessage }: ErrorModalProps) => {
  const { isMobile } = useDeviceType();

  return (
    <StyledModal
      title={title}
      titleIcon={<Icon.OrangeExclamationHollow width={32} height={32} />}
      submitButtonLabel="Retry"
      submitButtonSize={SIZES.lg}
      isMobile={isMobile}
      onCancelLinkClick={submit}
    >
      {
        customMessage
        ? customMessage
        : <>
            <Spacer size={Spacer.SIZES.sm} />
            <RegularText>
              There is currently an issue with connecting to Experian&apos;s systems.
            </RegularText>
            <Spacer size={Spacer.SIZES.md} />
            <RegularText>
              Please try again or contact support for assistance.
            </RegularText>
            <Spacer size={Spacer.SIZES.sm} />
          </>
      }
    </StyledModal>
  );
};

export default ErrorModal;
