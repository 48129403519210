import React from "react";

import { Field, FieldArray, FormikErrors, useFormikContext } from "formik";

import {
  AddAttachmentsForm,
  AddAttachmentsLink,
} from "manager/components/Form/AddAttachmentsForm";
import ErrorList from "shared/components/ErrorList";
import { Checkbox, TextArea } from "shared/components/Form";

import { Attachments } from "../styled";

import { Form } from "./types";

type Props = {
  index: number;
};
const ConditionallyApproveForm = ({ index }: Props) => {
  const { values, errors } = useFormikContext<Form>();
  const formData = values?.applicants?.[index];
  const attachmentsEnabled = formData?.requiresAdditionalAttachment;
  const messageEnabled = formData?.readMessage;
  const formErrors = errors?.applicants?.[index] as FormikErrors<
    Form["applicants"][0]
  >;

  return (
    <>
      {/* @ts-ignore */}
      {formErrors?.nonFieldErrors && (
        // @ts-ignore
        <ErrorList errors={formErrors.nonFieldErrors} />
      )}

      <p>What do they need to do?</p>

      <Field
        id={`applicants.${index}.requiresAdditionalGuarantor`}
        name={`applicants.${index}.requiresAdditionalGuarantor`}
        label="Add a guarantor"
        as={Checkbox}
        error={formErrors?.requiresAdditionalGuarantor}
      />
      <br />
      <Field
        id={`applicants.${index}.requiresAdditionalAttachment`}
        name={`applicants.${index}.requiresAdditionalAttachment`}
        label="Add additional attachments"
        as={Checkbox}
        error={formErrors?.requiresAdditionalAttachment}
      />
      <br />
      <Attachments>
        <FieldArray
          name={`applicants.${index}.additionalAttachments`}
          render={({ push, remove }) => (
            <>
              <AddAttachmentsForm
                remove={remove}
                disabled={!attachmentsEnabled}
                index={index}
                name={`applicants.${index}.additionalAttachments`}
                errors={formErrors?.additionalAttachments}
              />
              {attachmentsEnabled && (
                <AddAttachmentsLink addAttachment={push} />
              )}
            </>
          )}
        />
      </Attachments>
      <br />
      <Field
        as={Checkbox}
        name={`applicants.${index}.readMessage`}
        id={`applicants.${index}.readMessage`}
        label="Read the message below"
        error={formErrors?.readMessage}
      />
      <br />
      <Field
        as={TextArea}
        id={`applicants.${index}.customMessage`}
        name={`applicants.${index}.customMessage`}
        label="Your message"
        rows={5}
        error={formErrors?.customMessage}
        disabled={!messageEnabled}
      />
    </>
  );
};

export default ConditionallyApproveForm;
