import React from "react";

import styled from "@emotion/styled";

import { COLORS } from "shared/config/constants";

import { StyledLink, StyledStandardLink } from "../styled";

const activeStyles = {
  color: `${COLORS.underlineLink.hover.text} !important`,
  borderBottomColor: `${COLORS.underlineLink.hover.borderBottom} !important`,
};

const styles = {
  color: COLORS.underlineLink.default.text,
  borderBottom: `1px solid ${COLORS.underlineLink.default.borderBottom}`,
  lineHeight: "20px",
  "&:hover": activeStyles,
};

const getStyles = (props) => ({
  ...styles,
  ...(props.dashed && {
    textTransform: "uppercase",
    borderBottomStyle: "dashed",
    fontWeight: 500,
    ...activeStyles,
    "&:hover": {
      color: `${COLORS.underlineLink.default.text} !important`,
      borderBottomColor: `${COLORS.underlineLink.default.borderBottom} !important`,
    },
  }),
  ...(props.light && {
    borderBottomColor: COLORS.underlineLink.light.borderBottom,
    color: COLORS.underlineLink.light.text,
    "&:hover": {
      color: `${COLORS.underlineLink.light.borderBottom} !important`,
      borderBottomColor: `${COLORS.underlineLink.light.borderBottom} !important`,
    },
  }),
  ...(props.violetTheme && {
    color: `${COLORS.violetTheme.green} !important`,
    borderBottomColor: `${COLORS.violetTheme.green} !important`,
    "&:hover": {
      color: `${COLORS.violetTheme.lightGreen} !important`,
      borderBottomColor: `${COLORS.violetTheme.lightGreen} !important`,
    },
  }),
  ...(props.disabled && {
    color: `${COLORS.disabledText} !important`,
    borderBottomColor: `${COLORS.disabledText} !important`,
  }),
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const UnderlineLink = styled(({ dashed, light, ...props }) => (
  <StyledLink {...props} />
  // @ts-ignore
))(getStyles);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StandardUnderlineLink = styled(({ dashed, light, ...props }) => (
  <StyledStandardLink {...props} />
  // @ts-ignore
))(getStyles);

export { UnderlineLink, StandardUnderlineLink, styles, getStyles };
