import React, { Key } from "react";

import { Radio, Row, Col } from "antd";
import { Form, Formik, FieldArray, FormikHelpers } from "formik";
import pluralize from "pluralize";

import { YARDI_RELATED_UNIT_MESSAGES } from "manager/pages/Marketplace/integrations/yardi/shared";
import DetectableOverflow from "shared/components/DetectableOverflow";
import ErrorList from "shared/components/ErrorList";
import {
  Select,
  DatePicker,
  MaskedInput,
  VeroFormField,
} from "shared/components/Form";
import { Modal, SIZES } from "shared/components/Modal";
import Notice from "shared/components/Notice";
import NumberTitle from "shared/components/NumberTitle";
import Spacer from "shared/components/Spacer";
import { RegularText } from "shared/components/Typography";
import Well from "shared/components/Well";
import OptionItem from "shared/interfaces/api/optionItem";
import { INTEGRATION_TYPES } from "shared/config/constants";
import {
  InviteApplicantsForm,
  InviteApplicantsLink,
} from "shared/forms/InviteApplicantsForm";
import { useDeviceType } from "shared/hooks";

import { InitialValues } from "./interfaces";
import InvitationLeadSourceSelection from "./InvitationLeadSourceSelection";
import { MODES } from "./util";

export interface InviteApplicantsModalProps {
  properties?: { id: Key; name: string }[];
  isPropertiesLoading: boolean;
  units?: {
    id: Key;
    name: string;
    listingPresented: boolean;
    numberOfPotentialDeals?: number;
    numberOfActiveDeals?: number;
    integrationType?: string;
  }[];
  isUnitsLoading: boolean;
  applicantTypes?: { [key: string]: OptionItem };
  applicantTypesLoading?: boolean;
  leadSources?: { id: Key; value: string }[];
  leadSourcesLoading: boolean;
  submit: (
    values: InitialValues,
    formikApi: FormikHelpers<InitialValues>
  ) => void;
  selectUnit: (value, setValues, values) => void;
  selectProperty: (value, setValues, values) => void;
  mode: string;
  schema: any;
  onModeChange: (e: Event, values) => void;
  initialValues: InitialValues;
  disableInvitingWithoutUnit?: boolean;
  hideUnitsAttachedToAnotherActiveDeal?: boolean;
}

const InviteApplicantsModal = ({
  properties = [],
  isPropertiesLoading,
  units = [],
  isUnitsLoading,
  applicantTypes,
  applicantTypesLoading,
  leadSources = [],
  leadSourcesLoading,
  submit,
  selectUnit,
  selectProperty,
  mode,
  schema,
  onModeChange,
  initialValues,
  disableInvitingWithoutUnit = false,
  hideUnitsAttachedToAnotherActiveDeal,
}: InviteApplicantsModalProps) => {
  const { isMobile } = useDeviceType();
  const offsetNum = mode === MODES.TO_UNIT ? 1 : 0;

  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      validateOnMount={false}
      onSubmit={submit}
      enableReinitialize
    >
      {({
        isSubmitting,
        errors = undefined,
        touched,
        setValues,
        setFieldTouched,
        values,
        submitForm,
        setFieldValue,
        validateForm,
      }) => {
        const selectedUnit = units?.find(
          ({ id }) => id === Number(values.unit)
        );

        const unitListingCreationDisabled =
          !selectedUnit?.listingPresented &&
          INTEGRATION_TYPES.YARDI === selectedUnit?.integrationType;

        const isSubmitDisabled = !!unitListingCreationDisabled;
        const showInvitationInputFields = !unitListingCreationDisabled;

        return (
          <Form>
            <Modal
              title="Invite Applicants"
              submitButtonLabel="Invite"
              submitButtonSize={SIZES.lg}
              width="auto"
              style={{ maxWidth: 956 }}
              submit={submitForm}
              submitting={isSubmitting}
              submitButtonDisabled={isSubmitDisabled}
              closeOnSubmit={false}
              fullScreen={isMobile}
            >
              {!disableInvitingWithoutUnit && (
                <Modal.Body data-testid="unit-selection-radio-group" spacer>
                  <Radio.Group
                    onChange={(event) =>
                      onModeChange(event.target.value, values)
                    }
                    value={mode}
                  >
                    <Radio value={MODES.TO_UNIT}>To Unit</Radio>
                    <Radio value={MODES.WITHOUT_UNIT}>Without Unit</Radio>
                  </Radio.Group>
                </Modal.Body>
              )}
              {errors.nonFieldErrors && (
                <Modal.Body noPaddingBottom>
                  <ErrorList errors={errors.nonFieldErrors} />
                </Modal.Body>
              )}
              {mode === MODES.TO_UNIT && (
                <>
                  <Modal.Body spacer>
                    <NumberTitle number={1}>
                      Select property and unit
                    </NumberTitle>
                    <Row gutter={[20, 25]}>
                      <Col md={8}>
                        <VeroFormField
                          as={Select}
                          name="property"
                          id="property"
                          loading={isPropertiesLoading}
                          label="Property"
                          optionFilterProp="label"
                          onChange={(value) =>
                            selectProperty(value, setValues, values)
                          }
                          data-testid="property-dropdown"
                        >
                          {properties.map(({ id, name }) => (
                            <Select.Option key={id} label={name}>
                              <DetectableOverflow title={name}>
                                {name}
                              </DetectableOverflow>
                            </Select.Option>
                          ))}
                        </VeroFormField>
                      </Col>
                      <Col md={8}>
                        <VeroFormField
                          as={Select}
                          name="unit"
                          id="unit"
                          label="Unit"
                          disabled={!values.property}
                          loading={isUnitsLoading}
                          onChange={(value) =>
                            selectUnit(value, setValues, values)
                          }
                          data-testid="unit-dropdown"
                          optionFilterProp="label"
                        >
                          {units.map(
                            ({
                              numberOfActiveDeals,
                              numberOfPotentialDeals,
                              name,
                              id,
                            }) => {
                              const dealPluralize = pluralize(
                                "application",
                                numberOfPotentialDeals
                              );

                              const title = `${name} ${
                                numberOfPotentialDeals
                                  ? `(${numberOfPotentialDeals} ${dealPluralize} in progress )`
                                  : ""
                              }`;
                              if (
                                hideUnitsAttachedToAnotherActiveDeal &&
                                numberOfActiveDeals > 0
                              ) {
                                return null;
                              }
                              return (
                                <Select.Option key={id} label={title}>
                                  <DetectableOverflow title={title}>
                                    {name}{" "}
                                    {!!numberOfPotentialDeals && (
                                      <i>
                                        ({numberOfPotentialDeals}{" "}
                                        {dealPluralize} in progress)
                                      </i>
                                    )}
                                  </DetectableOverflow>
                                </Select.Option>
                              );
                            }
                          )}
                        </VeroFormField>
                      </Col>
                    </Row>
                  </Modal.Body>
                  {values.unitListingRequired && (
                    <>
                      <Spacer />
                      <Modal.Body noPaddingTop noPaddingBottom>
                        <Well>
                          <Notice type="warning">
                            <strong>
                              The current unit doesn&apos;t have an active
                              listing.
                              {unitListingCreationDisabled && (
                                <div>
                                  {
                                    YARDI_RELATED_UNIT_MESSAGES.disabledUnitListingCreate
                                  }
                                </div>
                              )}
                            </strong>
                          </Notice>
                        </Well>
                      </Modal.Body>
                      {showInvitationInputFields && (
                        <>
                          <Spacer size={Spacer.SIZES.md} />
                          <Modal.Body noPaddingTop>
                            <RegularText weak data-testid="hint">
                              Please fill data for the new listing to invite
                              applicants to this unit:
                            </RegularText>
                            <Spacer />
                            <Row gutter={[20, 25]}>
                              <Col md={8}>
                                {/* @ts-ignore */}
                                <MaskedInput.Formik.Money
                                  id="rent"
                                  name="unitListing.rent"
                                  label="Unit Rent"
                                  data-testid="rent-input"
                                  error={
                                    touched.unitListing?.rent &&
                                    errors.unitListing?.rent
                                  }
                                />
                              </Col>
                              <Col md={8}>
                                {/* @ts-ignore */}
                                <MaskedInput.Formik.Money
                                  id="securityDeposit"
                                  name="unitListing.securityDeposit"
                                  label="Security Deposit"
                                  data-testid="deposit-input"
                                  error={
                                    touched.unitListing?.securityDeposit &&
                                    errors.unitListing?.securityDeposit
                                  }
                                />
                              </Col>
                              <Col md={8}>
                                <VeroFormField
                                  as={DatePicker}
                                  id="unitAvailableOn"
                                  name="unitListing.unitAvailableOn"
                                  label="Date Available"
                                  data-testid="date-input"
                                />
                              </Col>
                            </Row>
                          </Modal.Body>
                        </>
                      )}
                    </>
                  )}
                </>
              )}

              {showInvitationInputFields && (
                <>
                  <Modal.Body spacer>
                    <NumberTitle number={offsetNum + 1}>
                      Fill info about applicants
                    </NumberTitle>
                    <FieldArray
                      name="applicants"
                      render={(arrayHelpers) => (
                        <>
                          <InviteApplicantsForm
                            applicantTypes={applicantTypes}
                            applicantTypesLoading={applicantTypesLoading}
                            applicants={values.applicants}
                            errors={errors.applicants}
                            touched={touched.applicants}
                            deleteApplicant={arrayHelpers.remove}
                          />
                          <Spacer size={Spacer.SIZES.sm} />
                          <InviteApplicantsLink
                            applicants={values.applicants}
                            checkValidation={validateForm}
                            addApplicant={arrayHelpers.push}
                          />
                        </>
                      )}
                    />
                    <Spacer size={Spacer.SIZES.sm} />
                  </Modal.Body>
                  <InvitationLeadSourceSelection
                    number={offsetNum + 2}
                    leadSources={leadSources}
                    leadSourcesLoading={leadSourcesLoading}
                    touched={touched}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                  />
                </>
              )}
              {!showInvitationInputFields && <Spacer size={Spacer.SIZES.md} />}
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};

export default InviteApplicantsModal;
