import React from "react";

import PropTypes from "prop-types";

import Icon from "shared/components/Icon";
import { ActionLink } from "shared/components/Links";
import Tile from "shared/components/Tile";

const ManagerProfileMagicLink = ({ openShareLinks }) => {
  return (
    <>
      <Tile.Label>My Links</Tile.Label>
      <ActionLink
        Icon={Icon.QRCodeGreenIcon}
        onClick={openShareLinks}
        data-testid="share-link"
      >
        Share
      </ActionLink>
    </>
  );
};

ManagerProfileMagicLink.propTypes = {
  openShareLinks: PropTypes.func.isRequired,
};

export default ManagerProfileMagicLink;
