import React from "react";

import find from "lodash/find";
import kebabCase from "lodash/kebabCase";
import _ from "lodash"
import DetectableOverflowTooltip from "shared/components/DetectableOverflowTooltip";
import FileSelect from "shared/components/FileSelect";
import FlexContainer from "shared/components/FlexContainer";
import { UploadWall, SwitchTitle } from "shared/components/Form";
import { AttachmentFile } from "shared/components/Form/UploadWall";
import Icon from "shared/components/Icon";
import LargeLoader from "shared/components/LargeLoader";
import { ActionLink, ImageLink } from "shared/components/Links";
import Tile from "shared/components/Tile";
import { RegularText } from "shared/components/Typography";
import { MAX_FILE_UPLOAD_SIZE } from "shared/config/constants";
import useModal from "shared/hooks/useModal";
import Table from "shared/components/Table";
import InformationModal from "shared/components/Modals/InformationModal";
import AttachmentsHeaders from "./AttachmentHeaders";
import { ATTACHMENTS_ALLOWED_FILE_TYPES_STRING } from "./Attachments.config";
import { mapUploadedFile } from "./Attachments.utils";
import AttachmentVisibilitySwitchTitle from "./AttachmentVisibilitySwitchTitle";
import { OtherAttachmentMapped, RequiredAttachment } from "./interfaces";
import {
  Document,
  DocumentTitle,
  DocumentList,
  FolderWrapper,
  FolderItems,
  StyledLink,
  Support,
  WarningBanner,
  SupportingDataText,
  DescriptionText,
} from "./styled";

interface AttachmentsProps {
  header: {
    title: string | Node;
  };
  requiredAttachments: RequiredAttachment[];
  otherAttachments: OtherAttachmentMapped[];
  uploadAttachment: Function;
  deleteAttachment: Function;
  cancelApplicationAttachments: (callback: () => void) => void;
  inEditMode: boolean;
  loading: boolean;
  showAttachmentsVisibilityAction: boolean;
  showAttachmentsHeaders: boolean;
  attachmentsChangedVisiblility: AttachmentFile[];
  onChangeAttachmentVisibility: (
    isPublic: boolean,
    file: AttachmentFile
  ) => void;
  confirmApplicationAttachments: (
    attachmentsChangedVisiblility: AttachmentFile[],
    callback: () => void
  ) => void;
  skipAttachment: Function;
  openViewEditModal: (document: RequiredAttachment) => void;

  failedDocuments?: any[];
  documents?: any[];
  applicantDocuments?: any[];
  canViewOcrResults?: boolean;
  canViewRestrictedAttachments?: boolean;
}

const Attachments = ({
  header,
  requiredAttachments,
  otherAttachments,
  uploadAttachment,
  deleteAttachment,
  cancelApplicationAttachments,
  inEditMode,
  loading,
  showAttachmentsVisibilityAction,
  showAttachmentsHeaders,
  attachmentsChangedVisiblility,
  onChangeAttachmentVisibility,
  confirmApplicationAttachments,
  skipAttachment,
  openViewEditModal,
  failedDocuments,
  documents,
  applicantDocuments,
  canViewOcrResults,
  canViewRestrictedAttachments
}: AttachmentsProps) => {
  const jointDocuments = _.cloneDeep(documents)

  if (!_.isEmpty(applicantDocuments)) {
    _.forEach(applicantDocuments, (document) => {
      if (_.get(document, 'tags', []).includes('restricted')) {
        _.assign(document, { isRestricted: true })
      }

      if ((canViewRestrictedAttachments && _.get(document, 'isRestricted')) || !_.get(document, 'isRestricted')) {
        const formType = _.get(document, 'description')
        const existingBucket = _.find(jointDocuments, (doc) => {
          return doc.name.toLowerCase() === formType.toLowerCase()
        })
        if (existingBucket) {
          existingBucket.attachment.push(document)
        } else {
          jointDocuments.push({
            name: formType,
            attachment: [document],
            metadata: {},
            skipped: false,
          })
        }
      }
    })
  }

  const { openModalDialog } = useModal();
  const supportingDataColumns = [
    {
      title: "Selected Document Type",
      dataIndex: "key",
      key: "keyField",
    },
    {
      title: "File Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Failed Reason",
      dataIndex: "reason",
      key: "reason",
    },
    {
      title: "Document Image",
      dataIndex: "image",
      key: "image",
    },
  ];

  const supportingResponsiveColumns = {
    xs: {
      column1: "key",
      column2: "name",
      column3: "reason",
      column4: "image"
    },
    sm: ["key", "name", "reason"],
    md: ["key", "name", "reason", "image"],
  };

  const noDataPlaceholder = {
    Icon: Icon.NoDataIcon,
    label: "Signals",
  };

  const formTypeMap = {
    '1099': '1099',
    'w2': 'W-2',
    'W-2': 'W-2',
    'paystub': 'Paystub',
    '3 or more most recent paystubs': 'Paystub',
    '2 most recent bank statements': 'Bank Statement',
  }

  const renderSupportingData = (failedDocument) => {
    const selectedFormType = formTypeMap[_.get(failedDocument, 'description')]
    const imageUrl = _.get(failedDocument, 'url')

    return {
      key: selectedFormType,
      values: [
        <FlexContainer>
          <RegularText>{selectedFormType}</RegularText>
        </FlexContainer>,
        <FlexContainer>
          <RegularText>{`${_.get(failedDocument, 'fileName')}.${_.get(failedDocument, 'extension')}`}</RegularText>
        </FlexContainer>,
        <FlexContainer>
          <RegularText>{`${_.chain(failedDocument).get('failedReason').lowerCase().capitalize().value()}`}</RegularText>
        </FlexContainer>,
        <ImageLink imageUrl={imageUrl}/>,
      ],
    };
  };

  const itemRender = (originalNode, file) => {
    const uploadedByApplicant = !_.get(file, 'tags', []).includes('manager-upload')

    return (
    <>
      {showAttachmentsVisibilityAction && (
        <FlexContainer alignItems="center" justifyContent="center">
          <RegularText>
            Uploaded by {uploadedByApplicant ? "Applicant" : "Manager" }
          </RegularText>
        </FlexContainer>
      )}
      {originalNode}
      {showAttachmentsVisibilityAction && (
        <SwitchTitle
          className="internal-only-switch"
          title={
            <AttachmentVisibilitySwitchTitle
              uploadedByApplicant={uploadedByApplicant}
              isRestricted={file?.isRestricted}
            />
          }
          onChange={(value) => onChangeAttachmentVisibility(value, file)}
          value={
            (find(attachmentsChangedVisiblility, { id: file?.id }) ?? file)
              ?.isPublic
          }
          
          disabled={!_.isEmpty(file?.id) || file?.isRestricted}
        />
      )}
    </>
  )};

  return (
    <Tile header={header}>
      {showAttachmentsHeaders && inEditMode && (
        <AttachmentsHeaders
          requiredAttachments={requiredAttachments}
          otherAttachments={otherAttachments}
          confirmApplicationAttachments={confirmApplicationAttachments}
          cancelApplicationAttachments={cancelApplicationAttachments}
          loading={loading}
          attachmentsChangedVisiblility={attachmentsChangedVisiblility}
        />
      )}
      <Tile.Inner size="sm">
        {loading && <LargeLoader showLoader absoluteCenter />}
        <DocumentList type="flex">
          {jointDocuments?.map((document, index) => {
            const { attachment, skipped, name, metadata } = document;
            const showSkipButton = !skipped && skipAttachment && _.isEmpty(attachment);

            const FilesView =
              (
                <FolderWrapper
                  marginTop={showAttachmentsVisibilityAction}
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  data-testid="attachments-multiple-files-wrapper"
                >
                  <Icon.FolderFileIcon />
                  <FolderItems>
                    {attachment.length} files uploaded
                  </FolderItems>
                  <ActionLink
                    onClick={() => openViewEditModal(document)}
                    // @ts-ignore
                    disabled={loading}
                  >
                    {inEditMode ? "View / Edit" : "View"}
                  </ActionLink>
                </FolderWrapper>
              );

            return (
              <Document
                key={kebabCase(`${name}${index}`)}
                data-testid={kebabCase(`upload-${name}${index}`)}
                flexDirection="column"
                withBorderRight
              >
                <FlexContainer justifyContent="space-between">
                  <DocumentTitle>
                    <DetectableOverflowTooltip
                      title={name}
                      theme="light"
                      placement="topLeft"
                    >
                      {name}
                    </DetectableOverflowTooltip>
                  </DocumentTitle>
                </FlexContainer>
                {FilesView}
                {showSkipButton && (
                  <StyledLink
                    onClick={() =>
                      skipAttachment(document.metadata?.requiredAttachmentId)
                    }
                  >
                    I don’t have it
                  </StyledLink>
                )}
              </Document>
            );
          })}
        </DocumentList>
          <Document
            expand
            marginTop={showAttachmentsVisibilityAction}
            flexDirection="column"
            data-testid="other-docs-title"
            key="other-docs-title"
          >
            {!_.isEmpty(failedDocuments) && canViewOcrResults &&
              <FlexContainer alignItems="center" justifyContent="space-between">
                <WarningBanner>
                  <Icon.TextRecognition className="text-recognition-icon"/>
                  <span className="banner-message">
                    A.I. Document Scan could not process the following uploads as they do not match any of the supported document types (W-2 tax form, 1099 tax form, Paystub).
                  </span>
                  <SupportingDataText
                    className="view-detail-button"
                    onClick={() => {
                      openModalDialog(InformationModal, {
                        title: "A.I. Document Scan Failures",
                        description: (
                          <div>
                            <DescriptionText>
                              A.I. Document Scan could not process the following uploads as they do not match any of the supported document types <strong>(W-2 tax form, 1099 tax form, Bank Statement, Paystub)</strong>. Please have the applicant verify their document and try uploading another file.
                            </DescriptionText>
                            <Table
                              placeholder={noDataPlaceholder}
                              columns={supportingDataColumns}
                              columnBreakpoints={
                                supportingResponsiveColumns
                              }
                              rows={failedDocuments.map(renderSupportingData)}
                              pagination={false}
                              scroll={{ y: 600 }}
                              noClickableRows
                            />
                          </div>
                        ),
                        centered: true,
                        width: 1000,
                      });
                    }}
                  >
                    <strong>
                    VIEW DETAILS
                    </strong>
                  </SupportingDataText>
                </WarningBanner>
              </FlexContainer>
            }
          </Document>
        <Support>
          <p>
            Supported Formats: <strong>PNG</strong>, <strong>JPEG</strong>,{" "}
            <strong>PDF</strong>
          </p>
          <p>
            Max file size: <strong>5 MB</strong>
          </p>
        </Support>
      </Tile.Inner>
    </Tile>
  );
};

export default Attachments;
