import axios from "axios";

import ROUTES from "shared/config/apiRoutes";
import { transformRoute } from "shared/utils/routing";

import { API_URL } from "./setupApi";

const api = {
  getPortfolios: (params) =>
    axios.get(`${API_URL}${ROUTES.portfolios}`, {
      params: {
        name__icontains: params?.name,
        num_properties__gt: params?.numPropertiesGt,
        page: params?.page,
        page_size: params?.pageSize,
        ordering: params?.ordering,
      },
    }),
  getPortfoliosList: (params) =>
    axios.get(`${API_URL}${ROUTES.portfoliosList}`, {
      params: {
        property_id: params?.propertyId,
        name__icontains: params?.name,
        include_default: params?.includeDefault,
      },
    }),
  updatePortfolio: (params) =>
    axios.patch(
      `${API_URL}${transformRoute(ROUTES.portfolio, {
        portfolioId: params.portfolioId,
      })}`,
      { name: params?.name }
    ),
  getPortfolio: (portfolioId) =>
    axios
      .get(
        `${API_URL}${transformRoute(ROUTES.portfolio, {
          portfolioId,
        })}`
      )
      .then(({ data }) => data),
  createPortfolio: ({ name, sourcePortfolio }) =>
    axios.post(`${API_URL}${ROUTES.portfolios}`, { name, sourcePortfolio }),
  assignPropertiesToPortfolio: (portfolioId, properties) =>
    axios.post(
      `${API_URL}${transformRoute(ROUTES.assignProperties, { portfolioId })}`,
      { properties }
    ),
  getPortfoliosAssociated: () =>
    axios
      .get(`${API_URL}${ROUTES.portfoliosAssociated}`)
      .then(({ data }) => data),
};

export default api;
