import React from "react";

import PropTypes from "prop-types";

import { useGetYardiPushOperationMessage } from "manager/hooks/api";
import Icon from "shared/components/Icon";
import Table from "shared/components/Table/Table/Table";

const OperationMessageTable = ({ id }) => {
  const { yardiOperetionMessages, isLoadingMessages } =
    useGetYardiPushOperationMessage(id);

  return (
    <Table
      alt
      withBorder
      noClickableRows
      columns={[
        {
          title: "Severity",
          dataIndex: "severity",
          key: "severity",
        },
        {
          title: "Message",
          dataIndex: "message",
          key: "message",
        },
        {
          title: "Created At",
          dataIndex: "createdAt",
          key: "createdAt",
        },
      ]}
      rows={yardiOperetionMessages.map((message) => ({
        key: message.id,
        values: [message.severity, message.message, message.createdAt],
      }))}
      loading={isLoadingMessages}
      placeholder={{
        label: "Operation Message",
        Icon: Icon.NoDataIcon,
      }}
      columnBreakpoints={{
        xs: {
          column1: "severity",
          column2: "message",
          column3: "createdAt",
        },
        sm: ["severity", "message", "createdAt"],
      }}
    />
  );
};

OperationMessageTable.propTypes = {
  id: PropTypes.number.isRequired,
};

export default OperationMessageTable;
