import styled from "@emotion/styled";

import { MEDIA_QUERIES } from "shared/config/constants";
import { OverflowEllipsis } from "shared/styles/commonStyles";

const Title = styled.div`
  display: inline-flex;
  max-width: 450px;

  .title-span {
    ${OverflowEllipsis}
  }

  .note-tag {
    margin-left: 10px;
  }

  ${MEDIA_QUERIES.w.ltMd} {
    max-width: calc(100vw - 400px);
  }

  ${MEDIA_QUERIES.w.ltSm} {
    max-width: calc(100vw - 48px);
  }

  ${MEDIA_QUERIES.w.gteMd} {
    width: calc(100vw - 510px);
  }
`;

const PopoverContent = styled.div`
  padding: 16px;
  max-width: 400px;
  overflow-wrap: break-word;

  .popover-title {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  .popover-items {
    border: none;
    p {
      margin: 0;
    }

    p + p {
      margin-top: 10px;
    }
  }
`;

export { Title, PopoverContent };
