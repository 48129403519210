import styled from "@emotion/styled";

import { MEDIA_QUERIES } from "shared/config/constants";

import { PortalWall, TilesGrid } from "../styled";

const PropertiesWall = styled(PortalWall)`
  .title {
    text-align: center;
    margin-bottom: 40px;
  }

  .header {
    padding: 40px;
  }

  .footer {
    padding: 10px 48px;
    text-align: right;
  }
`;

const PropertiesTilesGrid = styled(TilesGrid)`
  padding-bottom: 40px;
  overflow: auto;

  .magic-property-tile {
    margin: 0px 6px 18px 6px;
  }

  ${MEDIA_QUERIES.w.ltMd} {
    .magic-property-tile {
      margin: 0px 0 30px 0;
    }

    overflow: visible;
  }
`;

const MobilePropertiesWall = styled.div`
  padding-top: 64px;
  padding-bottom: 50px;
  position: relative;

  .logo {
    max-width: 60px;
    max-height: 60px;
    margin: 20px 0;
  }

  .title {
    text-align: center;
    margin-bottom: 20px;
  }

  .main-content {
    height: calc(100vh - 120px);
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .pagination-controls {
    margin: 0 auto;
  }
`;

export { PropertiesWall, PropertiesTilesGrid, MobilePropertiesWall };
