const PREFIX = "Shared/Type/";

export const GET_PROPERTY_TYPES = `${PREFIX}GET_PROPERTY_TYPES`;
export const GET_PROPERTY_TYPES_REQUEST = `${PREFIX}GET_PROPERTY_TYPES_REQUEST`;
export const GET_PROPERTY_TYPES_SUCCESS = `${PREFIX}GET_PROPERTY_TYPES_SUCCESS`;
export const GET_PROPERTY_TYPES_ERROR = `${PREFIX}GET_PROPERTY_TYPES_ERROR`;
export const RECEIVE_PROPERTY_TYPES = `${PREFIX}RECEIVE_PROPERTY_TYPES`;

export const GET_APPLICANT_TYPES = `${PREFIX}GET_APPLICANT_TYPES`;
export const GET_APPLICANT_TYPES_REQUEST = `${PREFIX}GET_APPLICANT_TYPES_REQUEST`;
export const GET_APPLICANT_TYPES_SUCCESS = `${PREFIX}GET_APPLICANT_TYPES_SUCCESS`;
export const GET_APPLICANT_TYPES_ERROR = `${PREFIX}GET_APPLICANT_TYPES_ERROR`;
export const RECEIVE_APPLICANT_TYPES = `${PREFIX}RECEIVE_APPLICANT_TYPES`;

export const GET_REQUIRED_ATTACHMENT_TYPES = `${PREFIX}GET_REQUIRED_ATTACHMENT_TYPES`;
export const GET_REQUIRED_ATTACHMENT_TYPES_REQUEST = `${PREFIX}GET_REQUIRED_ATTACHMENT_TYPES_REQUEST`;
export const GET_REQUIRED_ATTACHMENT_TYPES_SUCCESS = `${PREFIX}GET_REQUIRED_ATTACHMENT_TYPES_SUCCESS`;
export const GET_REQUIRED_ATTACHMENT_TYPES_ERROR = `${PREFIX}GET_REQUIRED_ATTACHMENT_TYPES_ERROR`;
export const RECEIVE_REQUIRED_ATTACHMENT_TYPES = `${PREFIX}RECEIVE_REQUIRED_ATTACHMENT_TYPES`;

export const GET_APPLICATION_STATUSES = `${PREFIX}GET_APPLICATION_STATUSES`;
export const GET_APPLICATION_STATUSES_REQUEST = `${PREFIX}GET_APPLICATION_STATUSES_REQUEST`;
export const GET_APPLICATION_STATUSES_SUCCESS = `${PREFIX}GET_APPLICATION_STATUSES_SUCCESS`;
export const GET_APPLICATION_STATUSES_ERROR = `${PREFIX}GET_APPLICATION_STATUSES_ERROR`;
export const RECEIVE_APPLICATION_STATUSES = `${PREFIX}RECEIVE_APPLICATION_STATUSES`;

export const GET_DEAL_STATUSES = `${PREFIX}GET_DEAL_STATUSES`;
export const GET_DEAL_STATUSES_REQUEST = `${PREFIX}GET_DEAL_STATUSES_REQUEST`;
export const GET_DEAL_STATUSES_SUCCESS = `${PREFIX}GET_DEAL_STATUSES_SUCCESS`;
export const GET_DEAL_STATUSES_ERROR = `${PREFIX}GET_DEAL_STATUSES_ERROR`;
export const RECEIVE_DEAL_STATUSES = `${PREFIX}RECEIVE_DEAL_STATUSES`;

export const GET_UNIT_STATUSES = `${PREFIX}GET_UNIT_STATUSES`;
export const GET_UNIT_STATUSES_REQUEST = `${PREFIX}GET_UNIT_STATUSES_REQUEST`;
export const GET_UNIT_STATUSES_SUCCESS = `${PREFIX}GET_UNIT_STATUSES_SUCCESS`;
export const GET_UNIT_STATUSES_ERROR = `${PREFIX}GET_UNIT_STATUSES_ERROR`;
export const RECEIVE_UNIT_STATUSES = `${PREFIX}RECEIVE_UNIT_STATUSES`;
