import React, { useState } from "react";

import { useMediaQuery } from "react-responsive";

import Icon from "shared/components/Icon";
import { BackLink } from "shared/components/Links";
import { Modal } from "shared/components/Modal";
import SelectableTiles from "shared/components/SelectableTiles";
import { BREAKPOINT_RESOLUTIONS } from "shared/config/constants";

import { usePermissions } from "shared/hooks";
import { conditionalItem } from "shared/utils/array";

import { DOWNLOAD_PROPERTIES_OPTIONS } from "../../MagicLinksDashboard.config";

const useExportPropertiesStep = ({
  title,
  exportTitle,
  onSubmit,
  onBackClick,
}) => {
  const [activeOptions, setOptions] = useState([]);
  const isLarge = useMediaQuery({ maxWidth: BREAKPOINT_RESOLUTIONS.lg });
  const [isSubmitting, setSubmitting] = useState(false);
  const { preventInvitesToTheApplicationWithoutUnit } = usePermissions();

  const onOptionClick = (value) => {
    if (activeOptions.includes(value)) {
      const newOptions = activeOptions.filter((item) => item !== value);
      setOptions(newOptions);
    } else {
      setOptions([...activeOptions, value]);
    }
  };

  const submit = async () => {
    setSubmitting(true);
    await onSubmit(activeOptions);
  };

  const exportOptions = [
    {
      key: DOWNLOAD_PROPERTIES_OPTIONS.ALL_UNITS,
      name: "All Units",
      Icon: <Icon.UnitsIcon />,
    },
    ...conditionalItem(!preventInvitesToTheApplicationWithoutUnit, {
      key: DOWNLOAD_PROPERTIES_OPTIONS.ALL_PROPERTIES,
      name: "All Properties",
      Icon: <Icon.PropertiesIcon />,
    }),
  ];

  return {
    modalProps: {
      title,
      submitButtonLabel: "Confirm",
      width: isLarge ? "95%" : 946,
      submitButtonDisabled: activeOptions.length === 0,
      footer: <BackLink onClick={onBackClick}>Back</BackLink>,
      submit,
      submitting: isSubmitting,
    },
    modalBody: (
      <Modal.Body>
        <SelectableTiles
          title={exportTitle}
          tiles={exportOptions}
          activeTiles={activeOptions}
          onTileClick={onOptionClick}
        />
      </Modal.Body>
    ),
    response: activeOptions,
  };
};

export default useExportPropertiesStep;
