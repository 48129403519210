import React from "react";

import { Col, Row } from "antd";

import Collapse from "shared/components/Collapse";
import Tile from "shared/components/Tile";

export interface IVerificationItemTile {
  children: React.ReactNode;
  verificationStatus: React.ReactNode;
}

const VerificationItemTile = ({
  verificationStatus,
  children,
}: IVerificationItemTile) => {
  return (
    <Tile>
      <Collapse
        header={({ CollapseIcon, collapsed }) => (
          <Tile.Inner borderBottom={!collapsed}>
            <Row type="flex" justify="space-between">
              <Col xs={22} md={20} lg={20} xl={14}>
                {verificationStatus}
              </Col>
              <Col>
                <CollapseIcon />
              </Col>
            </Row>
          </Tile.Inner>
        )}
        defaultCollapsed={false}
      >
        {children}
      </Collapse>
    </Tile>
  );
};

export default VerificationItemTile;
