import React from "react";

import PropTypes from "prop-types";

import Icon from "shared/components/Icon";
import { Modal } from "shared/components/Modal";
import Well from "shared/components/Well";

import { Subtitle, HeaderIcon, ModalBody, Description } from "./styled";

const TeaserModal = ({
  subtitleMessage,
  confirmLabel,
  description,
  SubtitleIcon,
}) => (
  <Modal
    width="530px"
    subtitle={
      <Subtitle>
        <HeaderIcon>
          <SubtitleIcon />
        </HeaderIcon>
        <span>{subtitleMessage}</span>
      </Subtitle>
    }
    submitButtonLabel={confirmLabel}
    showCancelLink={false}
  >
    <ModalBody data-testid="teaser-body">
      <Well transparent>
        <Description>{description}</Description>
      </Well>
    </ModalBody>
  </Modal>
);

TeaserModal.propTypes = {
  subtitleMessage: PropTypes.node.isRequired,
  description: PropTypes.node.isRequired,
  SubtitleIcon: PropTypes.elementType,
  confirmLabel: PropTypes.string,
};

TeaserModal.defaultProps = {
  SubtitleIcon: Icon.ConnectLinkIcon,
  confirmLabel: "Got it",
};

export default TeaserModal;
