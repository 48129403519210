import React, { useState } from "react";

import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import CreateUnitListingModal from "manager/components/Modal/CreateUnitListingModal";
import { openCreateUnitWithSuccessModal } from "manager/components/Modal/CreateUnitModal";
import EditUnitModal from "manager/components/Modal/EditUnitModal";
import UnitInviteApplicantsModal from "manager/components/Modal/UnitInviteApplicantsModal";
import {
  useDeleteUnit,
  useGetPropertyUnits,
  useToggleUnitDisplayOnPortal,
} from "manager/hooks/api";
import { propertySettingsPropTypes } from "manager/utils/propTypes";
import ConfirmationModal from "shared/components/Modals/ConfirmationModal";
import { DEFAULT_PAGINATION } from "shared/config/constants";
import { usePermissions } from "shared/hooks";
import useModal from "shared/hooks/useModal";

import UnitsTable from "./UnitsTable";

const UnitsTableContainer = ({ propertyId, propertySettings }) => {
  const history = useHistory();
  const { openModalDialog } = useModal();
  const { isMagicPortalEnabled } = usePermissions();
  const { toggleUnitDisplayOnPortal, isSavingDisplayOnPortal } =
    useToggleUnitDisplayOnPortal();
  const { deleteUnit } = useDeleteUnit();

  const defaultFilters = {
    propertyId,
    name: null,
    ordering: null,
    ...DEFAULT_PAGINATION,
  };

  const [filters, setFilters] = useState(defaultFilters);

  const { units, isUnitsLoading, isUnitsFetching, refetchUnits } =
    useGetPropertyUnits(propertyId, filters);

  const inviteApplicants = ({
    unitId,
    unitName,
    unitProvider,
    unitPmsConnected,
    propertyName,
  }) => {
    openModalDialog(UnitInviteApplicantsModal, {
      unit: {
        id: unitId,
        name: unitName,
        provider: unitProvider,
        pmsConnected: unitPmsConnected,
      },
      property: {
        name: propertyName,
      },
    });
  };

  const openCreateUnitModal = (property) =>
    openCreateUnitWithSuccessModal(property);
  const openEditUnitModal = (unit) => openModalDialog(EditUnitModal, { unit });
  const openDeleteUnitModal = (unit) => {
    const onConfirm = () => deleteUnit(unit?.id);
    const context = {
      title: "Delete this unit?",
      subtitle: (
        <span>
          Unit:{" "}
          <strong>
            {unit?.name}, {unit?.propertyName}
          </strong>
        </span>
      ),
      message: `Please note that once you delete this unit you won't be able to create a listing and invite applicants to it.`,
      noDescriptionIcon: true,
      submitButtonLabel: "Delete",
      onConfirm,
    };
    openModalDialog(ConfirmationModal, context);
  };
  const createUnitListing = (context) =>
    openModalDialog(CreateUnitListingModal, context);

  // On search we reset the pagination and update the filters
  const onSearchStringChange = (name) => {
    const newFilters = {
      ...filters,
      ...DEFAULT_PAGINATION,
      name,
    };
    setFilters(newFilters);
  };

  // On search we update the filters
  const onSortOrderChange = (ordering) => {
    const newFilters = {
      ...filters,
      ordering,
    };
    setFilters(newFilters);
  };

  // On page change we update the filters
  const onPaginationChange = (page) => {
    const newFilters = {
      ...filters,
      page,
    };
    setFilters(newFilters);
  };

  const onToggleUnitDisplayOnPortal = (unitId) =>
    toggleUnitDisplayOnPortal(unitId, {
      onSuccess: refetchUnits,
    });

  return (
    <UnitsTable
      propertyId={propertyId}
      propertySettings={propertySettings}
      history={history}
      units={units?.results}
      unitsCount={units?.count}
      isUnitsLoading={
        isUnitsLoading || isUnitsFetching || isSavingDisplayOnPortal
      }
      filters={filters}
      onSearchStringChange={onSearchStringChange}
      onPaginationChange={onPaginationChange}
      onSortOrderChange={onSortOrderChange}
      inviteApplicants={inviteApplicants}
      createUnit={openCreateUnitModal}
      editUnit={openEditUnitModal}
      deleteUnit={openDeleteUnitModal}
      createUnitListing={createUnitListing}
      isMagicPortalEnabled={isMagicPortalEnabled}
      onToggleUnitDisplayOnPortal={onToggleUnitDisplayOnPortal}
    />
  );
};

UnitsTableContainer.propTypes = {
  propertyId: PropTypes.string.isRequired,
  propertySettings: propertySettingsPropTypes,
};

UnitsTableContainer.defaultProps = {
  propertySettings: undefined,
};

export default UnitsTableContainer;
