import React from "react";

import { useParams } from "react-router-dom";

import { FeeInventoryLevel } from "manager/interfaces/api";

import HoldingDepositSettings from "./HoldingDepositSettings";

const PortfolioSettingsHoldingDepositContainer = () => {
  const { id } = useParams<{ id: string }>();
  const portfolioId = Number(id);

  return (
    <HoldingDepositSettings
      id={portfolioId}
      level={FeeInventoryLevel.PORTFOLIO}
    />
  );
};

export default PortfolioSettingsHoldingDepositContainer;
