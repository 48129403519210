import React from "react";

import { Popover } from "shared/components/Popover";

import { UserProfilePopoverProps } from "../interfaces";
import UserProfile from "../UserProfile";

import { Container } from "./styled";

const UserProfilePopover = ({ children, user }: UserProfilePopoverProps) => (
  <>
    {/* @ts-ignore */}
    <Popover
      placement="bottomRight"
      trigger="hover"
      align={{
        offset: [-10, 10],
      }}
      title={user.name}
      content={
        <Container>
          <UserProfile {...user} />
        </Container>
      }
      overlayClassName="user-popover"
    >
      {children}
    </Popover>
  </>
);

export default UserProfilePopover;
