import { connectRouter } from "connected-react-router";
import reduceReducers from "reduce-reducers";
import { combineReducers } from "redux";

const getReducer = (newReducer, existingReducer) =>
  existingReducer ? reduceReducers(newReducer, existingReducer) : newReducer;

export const createRootReducer = (history, reducerGroups) => {
  const entityReducers = {};
  const requestReducers = {};
  const commonReducers = {};

  Object.keys(reducerGroups).forEach((reducerGroupKey) => {
    const reducerGroup = reducerGroups[reducerGroupKey];
    const featureReducerKeys = Object.keys(reducerGroup);

    requestReducers[reducerGroupKey] = {};

    featureReducerKeys.forEach((featureReducerKey) => {
      const featureReducer = reducerGroup[featureReducerKey];

      if (featureReducer?.entities) {
        entityReducers[featureReducerKey] = getReducer(
          featureReducer.entities,
          entityReducers?.[featureReducerKey]
        );
      }
      if (featureReducer?.requests) {
        requestReducers[reducerGroupKey][featureReducerKey] =
          featureReducer.requests;
      }
      if (featureReducer?.common) {
        commonReducers[featureReducerKey] = getReducer(
          featureReducer.common,
          commonReducers?.[featureReducerKey]
        );
      }
    });

    if (requestReducers[reducerGroupKey]) {
      requestReducers[reducerGroupKey] = combineReducers(
        requestReducers[reducerGroupKey]
      );
    }
  });

  return combineReducers({
    router: connectRouter(history),
    entities: combineReducers(entityReducers),
    requests: combineReducers(requestReducers),
    ...commonReducers,
  });
};

export default createRootReducer;
