import React from "react";

import { Form, Formik, FormikHelpers } from "formik";

import { BasicInfoForm } from "renter/components/BasicInfoForm";
import { BasicInfoValidationSchema } from "renter/utils/validationSchemas";
import ErrorList from "shared/components/ErrorList";
import { Modal } from "shared/components/Modal";
import Spacer from "shared/components/Spacer";
import { VeroFormikProps } from "shared/interfaces";
import { UserProfile } from "shared/interfaces/api";

interface EditBasicInfoModalProps {
  submit: (
    values: UserProfile,
    formikHelpers: FormikHelpers<UserProfile>
  ) => void;
  profile: UserProfile;
}

const EditBasicInfoModal = ({
  submit,
  profile = {},
}: EditBasicInfoModalProps) => {
  const initialValues = {
    firstName: profile.firstName || "",
    middleName: profile.middleName || "",
    lastName: profile.lastName || "",
    birthdate: profile.birthdate || "",
    phoneNumber: profile.phoneNumber || "",
  };

  return (
    <Formik
      validationSchema={BasicInfoValidationSchema}
      initialValues={initialValues}
      isInitialValid={BasicInfoValidationSchema.isValidSync(initialValues)}
      onSubmit={submit}
    >
      {({
        errors,
        submitForm,
        isValid,
        isSubmitting,
      }: VeroFormikProps<UserProfile>) => (
        <Form>
          <Modal
            title="Edit basic info"
            submit={submitForm}
            submitButtonLabel="Save Changes"
            submitting={isSubmitting}
            closeOnSubmit={false}
            submitButtonDisabled={!isValid}
          >
            {errors?.nonFieldErrors && (
              <Modal.Body noPaddingBottom>
                <ErrorList errors={errors.nonFieldErrors} />
              </Modal.Body>
            )}
            <Modal.Body>
              <BasicInfoForm />
              <Spacer />
            </Modal.Body>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default EditBasicInfoModal;
