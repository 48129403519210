import React from "react";

import capitalize from "lodash/capitalize";
import lowerCase from "lodash/lowerCase";
import pluralize from "pluralize";

import { Title, List, Item } from "./styled";

const getItemCount = (item, label) => {
  const allItemsSelected = item.selected === item.total;
  const pluralLabel = lowerCase(
    pluralize(label, allItemsSelected ? 2 : item.selected)
  );
  return `${allItemsSelected ? "all " : ""}${item.selected} ${pluralLabel}`;
};

export interface SelectedItemListProps {
  label: string;
  items: {
    key?: string | number;
    label?: string;
    selected?: number;
    total?: number;
    [key: string]: any;
  };
}

const SelectedItemList = ({ label, items }: SelectedItemListProps) => {
  const totalSelectedItems = items
    .map((item) => (item.selected !== undefined ? item.selected : 1))
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  const title = capitalize(pluralize(label, 2));

  return (
    <>
      <Title data-testid="title">
        {`Selected ${title}: (${totalSelectedItems})`}
      </Title>
      <List>
        {items
          .filter((item) => item.selected === undefined || item.selected > 0)
          .map((item) => (
            <Item key={item.key} data-testid={`item-${item.key}`}>
              {item.selected !== undefined && item.total !== undefined
                ? `${item.label} — ${getItemCount(item, label)}`
                : item.label}
            </Item>
          ))}
      </List>
    </>
  );
};

export default SelectedItemList;
