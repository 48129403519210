import _ from 'lodash'
import React from 'react'
import styled from '@emotion/styled'
import Icon from 'shared/components/Icon'
import { colors, typography } from 'shared/config/constants'
import { buildClassName } from 'shared/utils/vega'
import HistoryEntry from './HistoryEntry'

const HistoryEntryContainer = styled(({ className, entry, stepNumber, detailed = false }) => {
  return (
    <div className={buildClassName([className, 'vega', 'status-bar', _.isBoolean(entry?.result) && (!!entry?.result ? 'pass' : 'fail'), detailed && 'detailed'])}>
      {detailed && <span className='step-number'>{stepNumber}</span>}

      <div className='history-inner-container'>
        {!detailed && <div className='vega icon large'>
          {entry?.result ? <Icon.CheckGreenSmall className='vega icon large' /> : <Icon.CrossRedSmall className='vega icon large' />}
        </div>}

        <HistoryEntry entry={entry} detailed={detailed} />
      </div>
    </div>
  )
})({
  paddingBottom: '1rem',
  gap: '1rem',
  '&:last-child': {
    paddingBottom: 0
  },
  '&.detailed': {
    display: 'block',
    position: 'relative',
    borderLeftStyle: 'solid',
    borderLeftWidth: '.25rem',
    paddingLeft: '2rem',
    paddingRight: '2rem'
  },
  '&.detailed .step-number': {
    position: 'absolute',
    width: '1.5rem',
    height: '1.5rem',
    fontSize: 'calc(1.5rem / 2)',
    padding: 'calc(1rem - (1.5rem / 2))',
    left: 'calc(-1 * (1.5rem / 1.86))',
    color: typography.textColors.light.secondary,
    textAlign: 'center',
    borderRadius: '100%',
    backgroundColor: colors.neutral.base
  },
  '&.detailed.pass .step-number': {
    color: typography.textColors.dark.primary,
    backgroundColor: colors.success.base
  },
  '&.detailed.fail .step-number': {
    color: typography.textColors.dark.primary,
    backgroundColor: colors.error.base
  },
  '.history-inner-container': {
    display: 'flex',
    gap: '1rem',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    borderBottomColor: colors.neutral.base,
    paddingBottom: '1rem'
  },
  '&:last-child .history-inner-container': {
    borderBottomStyle: 'none',
    paddingBottom: 0
  }
})

export default HistoryEntryContainer
