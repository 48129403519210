import { queryCache, useMutation, useQuery } from "react-query";

import { GetApplicationChargesRequest } from "manager/interfaces/api";
import api from "manager/lib/api";
import { NOTIFICATIONS } from "shared/config/constants";
import { openNotification } from "shared/utils/ui";

const GET_APPLICATION_CHARGES = "GET_APPLICATION_CHARGES";

export const useGetApplicationCharges = (
  params: GetApplicationChargesRequest
) => {
  const { data: applicationCharges, isLoading: isApplicationChargesLoading } =
    useQuery(
      [GET_APPLICATION_CHARGES, params],
      () => api.getApplicationCharges(params),
      {
        onError: () =>
          openNotification(
            "Failed to load application charges",
            NOTIFICATIONS.error
          ),
      }
    );

  return {
    applicationCharges,
    isApplicationChargesLoading,
  };
};

export const useSaveApplicationCharges = () => {
  const [saveApplicationCharges, { isLoading: isApplicationChargesLoading }] =
    useMutation(api.saveApplicationCharges, {
      onSuccess: () => {
        queryCache.invalidateQueries(GET_APPLICATION_CHARGES);
        openNotification("All changes saved!", NOTIFICATIONS.info);
      },
      onError: () =>
        openNotification("Failed to save the changes", NOTIFICATIONS.error),
    });

  return {
    saveApplicationCharges,
    isApplicationChargesLoading,
  };
};
