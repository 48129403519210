import React from "react";

import { Formik } from "formik";

import { Button, BUTTON_SIZES } from "shared/components/Button";
import FlexContainer from "shared/components/FlexContainer";
import { UploadWall } from "shared/components/Form/UploadWall";
import Icon from "shared/components/Icon";
import { UnderlineLink } from "shared/components/Links";
import Spacer from "shared/components/Spacer";
import { LargeTitle, RegularText } from "shared/components/Typography";
import { VeroModal } from "shared/components/vero-modal";
import { MAX_FILE_UPLOAD_SIZE_1MB } from "shared/config/constants";

import { ApplicantsCheckList, MessageWrapper, Subtitle } from "../styled";

import { DealSummary } from "../types";

import { useApproveDealModal } from "./context";
import { useHook } from "./hooks";

export interface ApproveDealModalProps {
  deal: DealSummary;
}

export const ApproveDealModalV2 = ({ deal }: ApproveDealModalProps) => {
  const state = useApproveDealModal();
  const { showCountyRecordsPendingMessage, uploadFile, approve } = useHook(
    deal,
    state.hide
  );
  const { cancel$, submit$, submitting } = state;

  return (
    <Formik initialValues={{}} onSubmit={() => submit$.next(approve())}>
      {({ submitForm }) => (
        <VeroModal {...state}>
          <LargeTitle>Approve Application</LargeTitle>
          <Subtitle>
            <RegularText strong>Applicants:&nbsp;</RegularText>
            <ApplicantsCheckList inline items={deal?.applicantsSummary} />
          </Subtitle>

          <Spacer size={Spacer.SIZES.default} />

          <div className="form-group">
            <p className="form-control-label">
              Please note that once you approve these applicants, they will be
              notified.
            </p>

            {showCountyRecordsPendingMessage && (
              <MessageWrapper>
                <FlexContainer>
                  <div className="message-icon">
                    <Icon.OrangeExclamation />
                  </div>
                  <RegularText strong>
                    You are approving an application without considering the
                    county criminal records search, which can take up to 14
                    days. Please confirm you&apos;d like to perform this
                    decision.
                  </RegularText>
                </FlexContainer>
              </MessageWrapper>
            )}

            <p className="form-control-label">
              Choose which attachments to include:
              <br />
              (Optional)
            </p>

            {/* @ts-ignore */}
            <UploadWall
              maxCount={5}
              maxSize={MAX_FILE_UPLOAD_SIZE_1MB}
              existingFiles={[]}
              uploadFile={uploadFile}
            />
          </div>

          <Spacer size={Spacer.SIZES.md} />
          <FlexContainer
            justifyContent="flex-end"
            gap="24px"
            alignItems="center"
          >
            <UnderlineLink onClick={() => cancel$.next()}>Cancel</UnderlineLink>
            <Button
              type="primary"
              onClick={submitForm}
              size={BUTTON_SIZES.lg}
              loading={submitting}
            >
              Approve
            </Button>
          </FlexContainer>
        </VeroModal>
      )}
    </Formik>
  );
};
