import React from "react";

import { useHistory } from "react-router-dom";

import ROUTES from "shared/config/routes";
import { useSendNewInvitationLink } from "shared/hooks/api";

import SetPasswordLinkExpiredPage from "./SetPasswordLinkExpiredPage";

interface SetPasswordLinkExpiredContainerProps {
  token: string;
}

const SetPasswordLinkExpiredContainer = ({
  token,
}: SetPasswordLinkExpiredContainerProps) => {
  const history = useHistory();

  const { sendNewInvitationLink, isSendNewInvitationLink } =
    useSendNewInvitationLink({ token });

  const requestNewLink = () =>
    sendNewInvitationLink(undefined, {
      onSuccess: ({ data }) =>
        history.push(ROUTES.setPasswordLinkSent, { email: data.email }),
    });

  return (
    <SetPasswordLinkExpiredPage
      requestNewLink={requestNewLink}
      isRequesting={isSendNewInvitationLink}
    />
  );
};

export default SetPasswordLinkExpiredContainer;
