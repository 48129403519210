import { useState } from "react";

import every from "lodash/every";
import some from "lodash/some";

import { NOTIFICATIONS } from "shared/config/constants";
import { openNotification } from "shared/utils/ui";

const useFormsManager = (config) => {
  const [forms, setForms] = useState({});
  const { onSubmitSuccess, onSubmitFail } = config || {};

  const onDefaultSubmitSuccess = () =>
    openNotification(
      "The changes have been successfully saved!",
      NOTIFICATIONS.info
    );

  const onDefaultSubmitFail = () =>
    openNotification("Failed to save changes.", NOTIFICATIONS.error);

  const onSuccess = onSubmitSuccess || onDefaultSubmitSuccess;
  const onFail = onSubmitFail || onDefaultSubmitFail;

  const isAllValid = every(Object.values(forms), "isValid");
  const isSomeDirty = some(Object.values(forms), "dirty");
  const isSomeSubmitting = some(Object.values(forms), "isSubmitting");

  const submitAllForms = () =>
    Promise.all(Object.values(forms).map((form) => form.submitForm()))
      .then(onSuccess)
      .catch(onFail);

  const registerFormChange = (
    formKey,
    { isValid, dirty, submitForm, isSubmitting }
  ) => {
    const updatedForms = {
      ...forms,
      [formKey]: {
        isValid,
        dirty,
        submitForm,
        isSubmitting,
      },
    };

    setForms(updatedForms);
  };

  return {
    registerFormChange,
    isAllValid,
    isSomeDirty,
    submitAllForms,
    isSomeSubmitting,
  };
};

export default useFormsManager;
