import { NOTIFICATIONS } from "shared/config/constants";
import { openNotification } from "shared/utils/ui";

// https://github.com/sindresorhus/filename-reserved-regex/blob/main/index.js#L4
// eslint-disable-next-line no-control-regex
const FILENAME_RESERVED_REGEX = /[<>:"/\\|?*\u0000-\u001F]/g;
const WINDOWS_RESERVED_REGEX = /^(con|prn|aux|nul|com\d|lpt\d)$/i;

export const useUploadValidation = () => {
  const filenameReservedRegex = new RegExp(FILENAME_RESERVED_REGEX);
  const windowsReservedRegex = new RegExp(WINDOWS_RESERVED_REGEX);

  const isFileNameValid = (name, showNotification) => {
    let isValid = true;
    if (!name || name.length > 255) {
      isValid = false;
    }

    if (filenameReservedRegex.test(name) || windowsReservedRegex.test(name)) {
      isValid = false;
    }

    if (name === "." || name === "..") {
      isValid = false;
    }

    if (!isValid && showNotification) {
      openNotification(
        `The name of the file you uploaded: "${name}" is invalid. Please, specify a different file name and try again.`,
        NOTIFICATIONS.error
      );
    }

    return isValid;
  };

  const isFileFormatValid = (name, acceptFormats) => {
    if (!acceptFormats.some((format) => name.toLowerCase().endsWith(format))) {
      openNotification(
        "The format of the selected file is not supported",
        NOTIFICATIONS.error
      );
      return false;
    }
    return true;
  };

  const isFileSizeValid = (maxSize, size, name) => {
    if (maxSize && size > maxSize) {
      openNotification(
        `The file: ${name} exceeds the max size allowed`,
        NOTIFICATIONS.error
      );
      return false;
    }
    return true;
  };

  return {
    isFileNameValid,
    isFileFormatValid,
    isFileSizeValid,
  };
};
