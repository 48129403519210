import React from "react";

import PropTypes from "prop-types";

import Collapse from "shared/components/Collapse";
import FileSelect from "shared/components/FileSelect";
import { Popover } from "shared/components/Popover";
import Tile from "shared/components/Tile";

import ApplicantsCategoriesList from "./ApplicantsCategoriesList";
import FieldsList from "./FieldsList";
import { FieldsPanelProps } from "./interfaces";
import {
  Panel,
  Title,
  Subtitle,
  StyledHeader,
  NewFieldContainer,
  NewFieldLink,
  NewFieldWrapper,
  FieldsWrapper,
  StyledField,
  PopoverContent,
} from "./styled";

const EXPORT_FIELDS_POPUP = (
  <PopoverContent>
    <h3>Exporting fields configuration</h3>
    <p>
      You can export fields configuration as xml file that you can apply on a
      clean document later.
    </p>
    <b>
      Please note that fields imported from existing PDF are bound to that PDF
      and are not re-usable.
    </b>
  </PopoverContent>
);

const IMPORT_FIELDS_POPUP = (
  <PopoverContent>
    <h3>Importing fields configuration</h3>
    <p>You can import fields configuration from xml file on your computer.</p>
  </PopoverContent>
);

const FieldsPanel = ({
  addField,
  accessibleFields,
  createField,
  onAnnotationFileSelect,
  exportAnnotations,
  isDocumentLoaded,
}: FieldsPanelProps) => (
  // @ts-ignore
  <Panel>
    <NewFieldWrapper>
      <NewFieldContainer>
        <NewFieldLink plus onClick={createField} data-testid="new-field">
          New Field
        </NewFieldLink>
      </NewFieldContainer>
      <Title>Accessible Fields</Title>
      <Subtitle>(You can drag and drop fields to the document)</Subtitle>
    </NewFieldWrapper>

    <FieldsWrapper noPaddingTop>
      {accessibleFields.map((accessibleFieldCategory) => (
        <Tile key={accessibleFieldCategory.name} sec>
          <Collapse
            key={accessibleFieldCategory.name}
            header={({ CollapseIcon }) => (
              <Tile.Inner small style={{ fontSize: "18px" }}>
                <StyledHeader>
                  {accessibleFieldCategory.name} <CollapseIcon />
                </StyledHeader>
              </Tile.Inner>
            )}
          >
            <Tile.Inner style={{ padding: "0px 20px 8px 20px" }}>
              {Array.isArray(accessibleFieldCategory.fields) ? (
                <FieldsList
                  fields={accessibleFieldCategory.fields}
                  addField={addField}
                />
              ) : (
                <ApplicantsCategoriesList
                  // @ts-ignore
                  applicantsDataByCategories={accessibleFieldCategory.fields}
                  addField={addField}
                />
              )}
            </Tile.Inner>
          </Collapse>
        </Tile>
      ))}
      <Tile sec>
        <Collapse
          header={({ CollapseIcon }) => (
            <Tile.Inner small style={{ fontSize: "18px", minWidth: 280 }}>
              <StyledHeader>
                Import/Export <CollapseIcon />
              </StyledHeader>
            </Tile.Inner>
          )}
        >
          <Tile.Inner style={{ padding: "0 35px 8px" }}>
            {/* @ts-ignore */}
            <Popover
              content={EXPORT_FIELDS_POPUP}
              trigger="hover"
              mouseEnterDelay={1}
            >
              {/* @ts-ignore */}
              <StyledField onClick={exportAnnotations}>
                Export fields
              </StyledField>
            </Popover>
            {/* @ts-ignore */}
            <Popover
              content={IMPORT_FIELDS_POPUP}
              trigger="hover"
              mouseEnterDelay={1}
            >
              <div>
                <FileSelect
                  Node={StyledField}
                  onFileSelect={onAnnotationFileSelect}
                  innerProps={{ children: "Import Fields" }}
                  acceptFormats="application/xml"
                  disabled={!isDocumentLoaded}
                />
              </div>
            </Popover>
          </Tile.Inner>
        </Collapse>
      </Tile>
    </FieldsWrapper>
  </Panel>
);

FieldsPanel.propTypes = {
  accessibleFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      fields: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    })
  ),
  addField: PropTypes.func.isRequired,
  createField: PropTypes.func.isRequired,
  exportAnnotations: PropTypes.func.isRequired,
  onAnnotationFileSelect: PropTypes.func.isRequired,
  isDocumentLoaded: PropTypes.bool.isRequired,
};

FieldsPanel.defaultProps = {
  accessibleFields: [],
};

export default FieldsPanel;
