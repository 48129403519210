import styled from "@emotion/styled";

import FlexContainer from "shared/components/FlexContainer";
import Tile from "shared/components/Tile";
import { SmallTitle, RegularText } from "shared/components/Typography";
import { COLORS, colors } from "shared/config/constants";

import { UnderlineLink } from "../../Links";

const AccordionContainer = styled.div`
  padding: 20px;
  background-color: white;
`;

const OcrolusSignalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .status-icon {
    margin-right: 20px;
  }
`;

const HeaderMainLabel = styled(SmallTitle)`
  margin-right: 5px !important;
  font-weight: 600 !important;
  color: ${(props) => props.color} !important;
`;

const TileCustom = styled(Tile)`
  margin-bottom: 0;
  border-color: white;
  .tile-header {
    padding: 15px;
    align-items: center;
    border-bottom-color: transparent;
  }
  .tile-header-title-container {
    width: 100%;
    > div,
    h2 > div {
      width: 100%;
      align-items: center;
    }
    div {
      font-size: 15px;
      font-weight: 400;
    }
    .main-header-left {
      &.vor {
        width: 100%;
        > div {
          width: 70%;
        }
      }
      .bold {
        font-size: 17px;
        font-weight: 500;
      }
    }
  }
  &.valid {
    background-color: ${COLORS.lightGreen};
  }
  &.invalid {
    background-color: ${COLORS.lightOrange};
  }
  &.fraudulent {
    background-color: ${COLORS.lightRed};
  }
  &.removed {
    background-color: ${colors.neutral[100]};
  }
  &.invalid.can-view {
    cursor: pointer;
  }
`;

const CapitalizedContainer = styled(FlexContainer)`
  text-transform: capitalize;
`;

const SupportingDataText = styled(RegularText)`
  cursor: pointer;
  color: ${COLORS.violetTheme.lightViolet};
`;

const FraudFormImageLink = styled(UnderlineLink)`
  display: flex;
  flex-wrap: wrap;
  color: ${(props) => props.color} !important;
  margin-right: 10px;
  border-bottom-color: ${(props) => props.color};
  &:hover,
  &:hover h4 {
    color: #1a1a1a !important;
    border-bottom-color: #000000 !important;
  }
`;

const NoFraudFormImageContainer = styled(FlexContainer)`
  flex-wrap: wrap;
  color: ${(props) => props.color};
  margin-right: 10px;
`;

export {
  AccordionContainer,
  OcrolusSignalHeader,
  HeaderMainLabel,
  TileCustom,
  CapitalizedContainer,
  SupportingDataText,
  FraudFormImageLink,
  NoFraudFormImageContainer,
};
