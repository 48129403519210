import styled from "@emotion/styled";
import { Col } from "antd-latest";

import { COLORS } from "shared/config/constants";

export const PopoverContainer = styled.div`
  width: 278px;
`;

export const Column = styled(Col)`
  border-right: 1px solid ${COLORS.borders};
  border-bottom: 1px solid ${COLORS.borders};
  padding: 12px 0px 12px 16px;
`;
