import React from "react";

import { Modal } from "shared/components/Modal";

import { ConfirmLeaveDocumentProps } from "./interfaces";

import { Description } from "./styled";

const ConfirmLeaveDocumentModal = ({
  onSubmit,
  submitting,
}: ConfirmLeaveDocumentProps) => (
  <Modal
    title="Save Changes?"
    submitButtonLabel="Yes"
    submit={onSubmit}
    submitting={submitting}
    cancelLinkLabel="No"
  >
    <Modal.Body data-testid="no-active-documents-message">
      <Description>Would you like to save changes before leave?</Description>
    </Modal.Body>
  </Modal>
);

export default ConfirmLeaveDocumentModal;
