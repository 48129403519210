export const FIELD_TYPES = {
  VERO_SMART_FIELD: "SMART_VERO_PRIORITY",
  BLUEMOON_SMART_FIELD: "SMART_BLUEMOON_PRIORITY",
  MANUAL: "MANUAL",
};

export const APP_SOURCE_TYPE = {
  VERO: "vero",
  LEASING: "leasing",
  APPLICANT: "LeaseApplication",
};
