import styled from "@emotion/styled";

import { ActionLink } from "shared/components/Links";
import { MEDIA_QUERIES } from "shared/config/constants";

const StyledActionLink = styled(ActionLink)`
  ${MEDIA_QUERIES.w.ltSm} {
    margin-right: 16px;
  }
  ${MEDIA_QUERIES.h.ltSm} {
    margin-right: 16px;
  }
`;

const ExportWrapper = styled.div`
  padding: 10px;
  ${MEDIA_QUERIES.w.ltSm} {
    padding-left: 0;
  }
`;

const StyledContainer = styled.div`
  label[for="ON_HOLD"] {
    white-space: nowrap;
  }
`;

export { StyledActionLink, ExportWrapper, StyledContainer };
