import { queryCache, useMutation, useQuery } from "react-query";

import { NOTIFICATIONS } from "shared/config/constants";
import { useNotFoundErrorHandler } from "shared/utils/queries.utils";
import { openNotification } from "shared/utils/ui";
import {
  ChangeSurveyQuestionRequest,
  VerifyEmailRequest,
} from "surveys/interfaces/api/survey";
import api from "surveys/lib/api";

const GET_SURVEY = "getSurvey";

const QUERY_KEYS = Object.freeze({
  getSurvey: (id: number) => [GET_SURVEY, Number(id)],
});

export const useGetSurvey = ({ surveyHashId }) => {
  const notFoundErrorHandler = useNotFoundErrorHandler();

  const { data: survey, isLoading: isSurveyLoading } = useQuery(
    QUERY_KEYS.getSurvey(surveyHashId),
    () => api.getSurvey(surveyHashId),
    {
      retry: false,
      enabled: Boolean(surveyHashId),
      onError: (error) => {
        if (!notFoundErrorHandler(error)) {
          openNotification("Failed to load survey", NOTIFICATIONS.error);
        }
      },
    }
  );

  return {
    survey,
    isSurveyLoading,
  };
};

export const useNextSurveyQuestion = () => {
  const [mutate, { isLoading }] = useMutation(
    (payload: ChangeSurveyQuestionRequest) => api.nextSurveyQuestion(payload),
    {
      onSuccess: (data) => {
        if (!data) {
          queryCache.invalidateQueries(GET_SURVEY);
        }
      },
      onError: () => {
        openNotification("Failed to save the answer.", NOTIFICATIONS.error);
      },
    }
  );

  return {
    nextSurveyQuestion: mutate,
    isNextSurveyQuestionLoading: isLoading,
  };
};

export const usePrevSurveyQuestion = () => {
  const [mutate, { isLoading }] = useMutation(
    (payload: ChangeSurveyQuestionRequest) => api.prevSurveyQuestion(payload),
    {
      onError: () => {
        openNotification("Failed to save the answer.", NOTIFICATIONS.error);
      },
    }
  );

  return {
    prevSurveyQuestion: mutate,
    isPrevSurveyQuestionLoading: isLoading,
  };
};

export const useVerifyEmail = () => {
  const [mutate, response] = useMutation(
    (payload: VerifyEmailRequest) => api.verifyEmail(payload),
    {
      onSuccess: () => {
        queryCache.invalidateQueries(GET_SURVEY);
        openNotification(`Your email is verified`, NOTIFICATIONS.info);
      },
      onError: () =>
        openNotification(
          "Email does not match invitation email address.",
          NOTIFICATIONS.error
        ),
    }
  );

  return {
    verifyEmail: mutate,
    response,
  };
};
