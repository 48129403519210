import styled from "@emotion/styled";
import { Avatar as BaseAvatar } from "antd";

import { COLORS } from "shared/config/constants";

const Avatar = styled(BaseAvatar)`
  border: 1px solid ${COLORS.borders};
  width: 40px;
  height: 40px;
  &.ant-avatar-lg {
    width: 60px;
    height: 60px;
    font-size: 17px;
  }
  &.ant-avatar-sm {
    width: 28px;
    height: 28px;
    font-size: 12px;
  }
  .ant-avatar-string {
    transform: none !important;
    position: static;
    font-weight: 500;
  }
  &:not(.ant-avatar-image) {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${COLORS.avatar.background};
    color: ${COLORS.primary};
  }
`;

export { Avatar };
