/* eslint-disable camelcase */
import { Key } from "react";

import { LumenResponse } from "manager/lib/lumenApi";
import { ApplicationStep, DEAL_UI_STATUS_CODES } from "shared/config/constants";

import { Applicant, ApplicantType } from "./applicant";
import { CountyCriminal } from "./county-criminal";

export interface Deal {
  id: string;
  applications: App[];
  dependents: Dependent[];
  status: DEAL_UI_STATUS_CODES;
  agent: Agent;
  unit?: Unit;
  property?: Property;
  fees: Fees;
  lumenEnabled: boolean;
  lumen?: Lumen;
  moveInDate?: Date;
  countyCriminal?: CountyCriminal;
  permission: Permission;
  yardi?: Yardi;
  conditionalProceed: boolean;
  isApproved: boolean;
  isMagicDeal?: boolean;
  isOpenApiDeal?: boolean;
  isArchived?: boolean;
}

export interface Fees {
  rent: number;
  isRentPaid?: boolean;
  holdingDeposit?: number;
  isHoldingDepositPaid: boolean;
  securityDeposit: number;
  isSecurityDepositPaid: boolean;
  isSecurityDepositDeducted?: boolean;
}

export interface Agent {
  id: string;
  firstName: string;
  lastName: string;
}

export interface Unit {
  name?: string;
  yardiConnected?: boolean;
  unitType?: string;
  bedrooms?: number;
  bathrooms?: string;
  squareFootage?: number;
  availableOn?: Date;
  hasLroEnabled?: boolean;
  externalId?: string;
}

export interface Property {
  name: string;
  address: string;
  yardiConnected?: boolean;
  yardiPropertyId?: number;
}

export interface ApplicantShort {
  id: Key;
  skippedBgcc: boolean;
}

export interface LumenRevision {
  id: number;
  deleted: Date;
  createdAt: Date;
  updatedAt: Date;
  status: string;
  isCurrentRevision: boolean;
  useCreditScore: boolean;
  useIncomeRentRatio: boolean;
  useAssets: boolean;
  useCollections: boolean;
  useCriminalRecords: boolean;
  useHousingCourtRecords: boolean;
  useVerifications: boolean;
  minCreditScore: number;
  idealCreditScore: number;
  creditScoreScopeWithGuarantors: string;
  creditRecordsScope: string;
  useCreditRecordSafetyNet: boolean;
  incomeRentRatioPeriod: string;
  minIncomeRentRatio: number;
  idealIncomeRentRatio: number;
  incomeRentRatioWithGuarantors: string;
  minIncomeRentRatioWithGuarantor: any;
  idealIncomeRentRatioWithGuarantor: any;
  minCashRunwayRatioWithGuarantor: any;
  idealCashRunwayRatioWithGuarantor: string;
  cashRunwayRatioWithGuarantors: string;
  totalIncomeWithGuarantor: string;
  incomeConsidered: string;
  minAssetsRatio: any;
  idealAssetsRatio: any;
  totalAssetsWithGuarantor: any;
  maxCollectionsAmount: any;
  useCollectionRecordSafetyNet: boolean;
  useCriminalRecordSafetyNet: boolean;
  useHousingCourtRecordSafetyNet: boolean;
  verifyIdentity: boolean;
  lumenFormula: number;
  employee: number;
  filterCollectionRecords: LumenRecords[];
  filterCreditPublicRecords: LumenRecords[];
  filterCriminalRecords: LumenRecords[];
  filterHousingCourtRecords: LumenRecords[];
  validationStatus: {
    assets: string;
    collectionRecords: string;
    credit: string;
    criminalRecords: string;
    housingCourtRecords: string;
    income: string;
    verifications: string;
  };
}

interface LumenRecords {
  conditions: {
    conditionSubType: string;
    conditionType: string;
    timeOperator: string;
    timeUnit: string;
    timeValue: number;
  }[];
  formulaRevision: number;
  id: number;
  mode: string;
  type: string;
}

export interface Lumen extends Pick<LumenResponse, "messages" | "score"> {}

export enum PaymentStatus {
  created = "created",
  unpaid = "unpaid",
  charged = "charged",
  paid = "paid",
  refunded = "refunded",
  partially_refunded = "partially_refunded",
  disputed = "disputed",
  canceled = "canceled",
}

export enum PaymentType {
  application_fee = "application_fee",
  processing_fee = "processing_fee",
  holding_deposit = "holding_deposit",
  security_deposit = "security_deposit",
}

export interface Payment {
  type?: PaymentType | string;
  amount?: number;
  name?: string;
  status?: PaymentStatus;
  paidAt?: Date;
}

export interface App {
  id: string;
  isPrimary?: boolean;
  hideCriminalHistory?: boolean;
  fraudIndicators?: string[];
  isSubmitted: boolean;
  applicant: Applicant;
  step?: ApplicationStep;
  inviteLink?: string;
  permission?: AppPermission;
  isBlocked?: boolean;
  argyleStatus?: ArgyleStatus;
  payments?: Payment[];
  isCriminalComplete?: boolean;
}

export enum ArgyleStatus {
  NOT_SYNCED = "NOT_SYNCED",
  SHOULD_SYNC = "SHOULD_SYNC",
  SYNC_IN_PROGRESS = "SYNC_IN_PROGRESS",
  SYNC_COMPLETED = "SYNC_COMPLETED",
  SYNC_FAILED = "SYNC_FAILED",
  OCR_PROCESSING = "OCR_PROCESSING",
}

export interface Permission {
  noApprove?: boolean;
  noApproveReason?: string;
  noArchive?: boolean;
  noArchiveReason?: string;
  noCancel?: boolean;
  noCancelReason?: string;
  noRequestInfo?: boolean;
  noRequestInfoReason?: string;
  noConditionalProceed?: boolean;
  noConditionalProceedReason?: string;
  noDecline?: boolean;
  noDeclineReason?: string;
  noCreateLease?: boolean;
  noCreateLeaseReason?: string;
  noEditRent?: boolean;
  noEditRentReason?: string;
  canSendToPms?: boolean;
}

export interface AppPermission {
  noMove?: boolean;
  noMoveReason?: string;
  noRemove?: boolean;
  noRemoveReason?: string;
  showSsn?: boolean;
  canUnblock?: boolean;
}

export interface Yardi {
  pCode?: string;
  tCode?: string;
}

export interface Dependent {
  id: string;
  lastName?: string;
  firstName?: string;
  age?: number;
  relationship?: string;
  type?: ApplicantType;
}
