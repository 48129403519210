import React, { useState } from "react";

import styled from "@emotion/styled";
import { Field } from "formik";

import { YardiRemoveMainApplicantReasonFormExtension } from "manager/pages/Marketplace/integrations/yardi/shared";
import { TextArea } from "shared/components/Form";
import { FormGroups } from "shared/styles/commonStyles";
import { fileUploadHandler } from "shared/utils/upload";

export const useUpload = () => {
  const [attachments, setAttachments] = useState<any[]>([]);

  const uploadFile = async (file) => {
    const item = await fileUploadHandler(file);
    setAttachments((currentAtt) => [...currentAtt, file]);
    return item;
  };

  const excludeFile = async (file) => {
    setAttachments((currentAttachments) =>
      currentAttachments.filter((f) => f.uid !== file.uid)
    );
  };

  return { attachments, uploadFile, excludeFile };
};

const YardiRemoveMainApplicantContainer = styled("div")({
  ...FormGroups,
  ".description": {
    fontWeight: 300,
    margin: 0,
  },
  ".reason-select": {
    marginTop: "24px",
    marginBottom: "32px",
  },
});

export type RemovePrimaryYardiExtensionProps = {
  unit?: {
    yardiConnected?: boolean;
  };
  property?: {
    yardiConnected?: boolean;
    yardiPropertyId?: number;
  };
  isPrimary: boolean;
};

export type { YardiReasonFormExtensionFields } from "manager/pages/Marketplace/integrations/yardi/shared/YardiReasonFormExtension/types";

export const useRemovePrimaryYardiExtension = (
  props: RemovePrimaryYardiExtensionProps
) => {
  const show =
    (props.unit || props.property)?.yardiConnected && props.isPrimary;
  const extension = show ? (
    <YardiRemoveMainApplicantContainer>
      <YardiRemoveMainApplicantReasonFormExtension property={props.property} />
      <Field
        as={TextArea}
        id="extraParams.comment"
        name="extraParams.comment"
        label="Additional comment (Optional)"
        rows={5}
      />
    </YardiRemoveMainApplicantContainer>
  ) : null;
  return { extension };
};
