import React from "react";

import { Modal } from "shared/components/Modal";
import Spacer from "shared/components/Spacer";

const UnassignEmployeeFromPortfolioModal = ({
  employeeName,
  portfolioName,
  onSubmit,
  submitting,
}) => (
  <Modal
    title={`Unassign ${employeeName} from portfolio?`}
    subtitle={
      <span data-testid="subtitle">
        Portfolio: <strong>{portfolioName}</strong>
      </span>
    }
    submit={onSubmit}
    submitButtonLabel="Unassign"
    submitting={submitting}
  >
    <Spacer />
  </Modal>
);

export default UnassignEmployeeFromPortfolioModal;
