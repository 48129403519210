import axios from "axios";

import MANAGER_API_ROUTES from "manager/config/apiRoutes";
import { API_URL } from "shared/lib/api/setupApi";
import { transformRoute } from "shared/utils/routing";

const api = {
  getYardiPlatformTypes: () =>
    axios
      .get(`${API_URL}${MANAGER_API_ROUTES.yardiPlatformTypes}`)
      .then((response) => response.data),
  getYardiPcodePoints: () =>
    axios
      .get(`${API_URL}${MANAGER_API_ROUTES.yardiPcodePoints}`)
      .then((response) => response.data),
  getYardiTcodePoints: () =>
    axios
      .get(`${API_URL}${MANAGER_API_ROUTES.yardiTcodePoints}`)
      .then((response) => response.data),
  getYardiLeaseSignEventPoints: () =>
    axios
      .get(`${API_URL}${MANAGER_API_ROUTES.yardiLeaseSignEventPoints}`)
      .then((response) => response.data),
  getYardiSecurityDepositPreferences: () =>
    axios
      .get(`${API_URL}${MANAGER_API_ROUTES.yardiSecurityDepositPreferences}`)
      .then((response) => response.data),
  getYardiSyncIntervals: () =>
    axios
      .get(`${API_URL}${MANAGER_API_ROUTES.yardiSyncIntervals}`)
      .then((response) => response.data),
  postYardiCredentials: (provider, credentials) =>
    axios
      .post(`${API_URL}${MANAGER_API_ROUTES.yardiCredentials}`, credentials, {
        params: { provider },
      })
      .then((response) => response.data),
  patchYardiCredentials: (provider, credentials) =>
    axios
      .patch(
        `${API_URL}${MANAGER_API_ROUTES.yardiUpdateCredentials}`,
        credentials,
        { params: { provider } }
      )
      .then((response) => response.data),
  getYardiCredentials: (provider) =>
    axios
      .get(`${API_URL}${MANAGER_API_ROUTES.yardiCredentials}`, {
        params: { provider },
      })
      .then((response) => response.data),
  deleteYardiCredentials: (provider) =>
    axios.delete(`${API_URL}${MANAGER_API_ROUTES.yardiCredentials}`, {
      params: { provider },
    }),
  getYardiProperties: ({
    isActive,
    page,
    pageSize,
    status,
    nameOrAddress,
    ordering,
    truncated,
    provider,
  }) =>
    axios
      .get(`${API_URL}${MANAGER_API_ROUTES.yardiProperties}`, {
        params: {
          page,
          status,
          name_or_address: nameOrAddress,
          page_size: pageSize,
          is_active: isActive,
          ordering,
          truncated,
          provider,
        },
      })
      .then((response) => response.data),
  getYardiProperty: (provider, yardiPropertyId) =>
    axios
      .get(
        transformRoute(`${API_URL}${MANAGER_API_ROUTES.yardiProperty}`, {
          yardiPropertyId,
        }),
        { params: { provider } }
      )
      .then((response) => response.data),
  importPmsProperties: (provider, properties) =>
    axios
      .post(
        `${API_URL}${MANAGER_API_ROUTES.importPmsProperties}?provider=${provider}`,
        properties
      )
      .then((response) => response.data),
  getYardiAlerts: (
    provider,
    yardiPropertyId,
    resolved,
    alertClass,
    page = 1,
    pageSize = 10
  ) =>
    axios
      .get(`${API_URL}${MANAGER_API_ROUTES.yardiAlerts}`, {
        params: {
          provider,
          page,
          page_size: pageSize,
          property_id: yardiPropertyId,
          alert_class: alertClass,
          resolved,
        },
      })
      .then((response) => response.data),
  putYardiProperty: (provider, yardiPropertyId, veroPropertyId) =>
    axios
      .put(
        transformRoute(`${API_URL}${MANAGER_API_ROUTES.yardiProperty}`, {
          yardiPropertyId,
        }),
        {
          property: { id: veroPropertyId },
        },
        { params: { provider } }
      )
      .then((response) => response.data),
  putYardiPropertyMappings: (provider, id, mappings) =>
    axios
      .put(
        transformRoute(
          `${API_URL}${MANAGER_API_ROUTES.yardiPropertyMappings}`,
          {
            id,
          }
        ),
        mappings,
        { params: { provider } }
      )
      .then((response) => response.data),
  postYardiPull: (provider) =>
    axios
      .post(transformRoute(`${API_URL}${MANAGER_API_ROUTES.yardiPull}`), null, {
        params: { provider },
      })
      .then((response) => response.data),
  deleteYardiPropertyMappings: (provider, id, removeFromActive) =>
    axios
      .delete(
        transformRoute(
          `${API_URL}${MANAGER_API_ROUTES.yardiPropertyMappings}`,
          {
            id,
          }
        ),
        {
          params: {
            provider,
            reset_active: removeFromActive,
          },
        }
      )
      .then((response) => response.data),
  getYardiPropertyReasons: ({ id, type }) =>
    axios
      .get(
        transformRoute(`${API_URL}${MANAGER_API_ROUTES.yardiPropertyReasons}`, {
          id,
        }),
        {
          params: {
            type,
          },
        }
      )
      .then((response) => response.data),
  getYardiIntegrationStatus: (provider) =>
    axios
      .get(`${API_URL}${MANAGER_API_ROUTES.yardiIntegrationStatus}`, {
        params: { provider },
      })
      .then((response) => response.data),
  getYardiAttachmentTypes: (provider) =>
    axios
      .get(`${API_URL}${MANAGER_API_ROUTES.attachmentTypes}`, {
        params: { provider },
      })
      .then((response) => response.data),
  getYardiChargeTypes: (provider) =>
    axios
      .get(`${API_URL}${MANAGER_API_ROUTES.chargeTypes}`, {
        params: { provider },
      })
      .then((response) => response.data),
  patchYardiMappingConfiguration: (provider, configuration) =>
    axios
      .patch(
        `${API_URL}${MANAGER_API_ROUTES.yardiMappingConfiguration}`,
        configuration,
        { params: { provider } }
      )
      .then((response) => response.data),
  getYardiUnits: (provider, yardiPropertyId) =>
    axios
      .get(
        transformRoute(`${API_URL}${MANAGER_API_ROUTES.yardiUnits}`, {
          yardiPropertyId,
        }),
        { params: { provider } }
      )
      .then((response) => response.data),
  getYardiUnit: (provider, yardiPropertyId, yardiUnitId) =>
    axios
      .get(
        transformRoute(`${API_URL}${MANAGER_API_ROUTES.yardiUnit}`, {
          yardiPropertyId,
          yardiUnitId,
        }),
        { params: { provider } }
      )
      .then((response) => response.data),
  postAutocreateVeroUnits: (provider, yardiPropertyId, configuration) =>
    axios
      .post(
        transformRoute(`${API_URL}${MANAGER_API_ROUTES.autoCreateVeroUnits}`, {
          yardiPropertyId,
        }),
        configuration,
        { params: { provider } }
      )
      .then((response) => response.data),
  getYardiMarketingSources: (provider, propertyId) =>
    axios
      .get(
        transformRoute(
          `${API_URL}${MANAGER_API_ROUTES.yardiMarketingSources}`,
          {
            id: propertyId,
          }
        ),
        { params: { provider } }
      )
      .then((response) => response.data),
  postAutoCreateVeroMarketingSources: (provider, yardiPropertyId) =>
    axios
      .post(
        transformRoute(
          `${API_URL}${MANAGER_API_ROUTES.autoCreateMarketingSources}`,
          {
            id: yardiPropertyId,
          }
        ),
        null,
        { params: { provider } }
      )
      .then((response) => response.data),
  getYardiAgents: (provider, propertyId) =>
    axios
      .get(
        transformRoute(`${API_URL}${MANAGER_API_ROUTES.yardiAgents}`, {
          id: propertyId,
        }),
        { params: { provider } }
      )
      .then((response) => response.data),
  getYardiAgentsOnVeroProperties: (veroPropertiesIds) =>
    axios
      .get(
        transformRoute(
          `${API_URL}${MANAGER_API_ROUTES.yardiAgentsOnVeroProperties}`
        ),
        {
          params: {
            vero_properties: veroPropertiesIds,
          },
        }
      )
      .then((response) => response.data),
  putYardiAgentsOnPropertiesMapping: ({ mappings }) =>
    axios
      .put(
        `${API_URL}${MANAGER_API_ROUTES.yardiAgentsOnPropertiesMapping}`,
        mappings
      )
      .then((response) => response.data),
  getYardiLeadSources: () =>
    axios
      .get(`${API_URL}${MANAGER_API_ROUTES.yardiLeadSources}`)
      .then((response) => response.data),
  putYardiLeadSourceMapping: (provider, configuration) =>
    axios
      .put(
        `${API_URL}${MANAGER_API_ROUTES.yardiLeadSourcesMappings}`,
        configuration,
        { params: { provider } }
      )
      .then((response) => response.data),
  getYardiPushOperationTracking: (dealId) =>
    axios
      .get(
        transformRoute(
          `${API_URL}${MANAGER_API_ROUTES.yardiPushOperationTracking}`,
          { id: dealId }
        )
      )
      .then((response) => response.data),
  getYardiPushOperationMessage: (id) =>
    axios
      .get(
        transformRoute(
          `${API_URL}${MANAGER_API_ROUTES.yardiPushOperationMessage}`,
          { id }
        )
      )
      .then((response) => response.data),
  getYardiActionTracking: (dealId) =>
    axios
      .get(
        transformRoute(
          `${API_URL}${MANAGER_API_ROUTES.yardiYardiActionTracking}`,
          { id: dealId }
        )
      )
      .then((response) => response.data),
  getYardiVeroEvent: (dealId) =>
    axios
      .get(
        transformRoute(`${API_URL}${MANAGER_API_ROUTES.yardiVeroEvent}`, {
          id: dealId,
        })
      )
      .then((response) => response.data),
  getYardiClientReqRes: (id) =>
    axios
      .get(
        transformRoute(`${API_URL}${MANAGER_API_ROUTES.yardiClientReqRes}`, {
          id,
        })
      )
      .then((response) => response.data),
  getYardiClientReqResSoapResponse: (id) =>
    axios
      .get(
        transformRoute(
          `${API_URL}${MANAGER_API_ROUTES.yardiClientReqResSoapResponse}`,
          {
            id,
          }
        )
      )
      .then((response) => response.data),
  getYardiClientReqResServiceResponse: (id) =>
    axios
      .get(
        transformRoute(
          `${API_URL}${MANAGER_API_ROUTES.yardiClientReqResYardiServiceResponse}`,
          {
            id,
          }
        )
      )
      .then((response) => response.data),
  getYardiVersion: (provider) =>
    axios
      .get(`${API_URL}${MANAGER_API_ROUTES.yardiVersion}`, {
        params: { provider },
      })
      .then((response) => response.data),
  getYardiDealStatus: (id, taskCode) => {
    const params = taskCode ? { task_code: taskCode } : undefined;
    return axios
      .get(
        transformRoute(`${API_URL}${MANAGER_API_ROUTES.yardiDealStatusSync}`, {
          id,
        }),
        {
          params,
        }
      )
      .then((response) => response.data);
  },
  postYardiDealStatusSync: (id) =>
    axios
      .post(
        transformRoute(`${API_URL}${MANAGER_API_ROUTES.yardiDealStatusSync}`, {
          id,
        })
      )
      .then((response) => response.data),
};

export default api;
