import React from "react";

import styled from "@emotion/styled";
import { Typography, Col } from "antd";

import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

const { Text } = Typography;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledCol = styled(({ withBorder, ...props }) => <Col {...props} />)(
  (props) => ({
    backgroundColor: COLORS.tile.alt.innerBackground,
    border: "none",
    boxSizing: "border-box",
    padding: 24,
    ...(props.withBorder && {
      borderRight: "4px solid #443D6D",
      marginBottom: 0,
      [MEDIA_QUERIES.w.ltMd]: {
        border: "none",
        marginBottom: 10,
      },
    }),
  })
);

const IncomeAssetVerificationNotes = styled(Text)({
  display: "block",
  fontSize: "12px",
  marginTop: "24px",
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Statistic = styled(({ null: reassignToAvoidError, ...props }) => (
  <Text {...props} />
))((props) => ({
  fontWeight: "500",
  fontSize: "22px",
  paddingRight: "10px",
  textTransform: "capitalize",
  small: {
    fontSize: "15px",
  },
  ...(props.null && {
    color: "rgba(253, 253, 253, 0.5) !important",
  }),
}));

const DataItem = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: 30px;
`;

export { StyledCol, IncomeAssetVerificationNotes, Statistic, DataItem };
