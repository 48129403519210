import React, { useState } from "react";

import { Link, StandardLink, Text, Content } from "./styled";

export interface IIconLink {
  children?: React.ReactNode;
  Icon: React.ElementType;
  to?: string | any;
  onClick?: () => void;
  size?: "sm" | "default";
  hideTextOnMobile?: boolean;
  hoverableIcon?: boolean;
  right?: boolean;
  disabled?: boolean;
  nowrap?: boolean;
  "data-testid"?: string;
  light?: boolean;
  [key: string]: any;
}

const IconLink = ({
  children = undefined,
  Icon,
  onClick = undefined,
  to = undefined,
  hideTextOnMobile = false,
  size = "default",
  hoverableIcon = true,
  right = false,
  disabled = false,
  "data-testid": dataTestId = "icon-link",
  ...props
}: IIconLink) => {
  const [hovered, setHovered] = useState(false);
  let link;

  const allProps = {
    ...props,
    "data-testid": dataTestId,
    onMouseEnter: () => setHovered(true),
    onMouseLeave: () => setHovered(false),
    size,
  };

  const content = (
    <Content right={right}>
      <Icon
        hovered={hoverableIcon ? String(hovered) : undefined}
        data-testid={`${dataTestId}-icon`}
      />
      <Text
        {...props}
        size={size}
        hideTextOnMobile={hideTextOnMobile}
        right={right}
      >
        {children}
      </Text>
    </Content>
  );

  if (to) {
    link = (
      <Link {...allProps} to={to} disabled={disabled}>
        {content}
      </Link>
    );
  } else {
    link = (
      <StandardLink {...allProps} onClick={onClick} disabled={disabled}>
        {content}
      </StandardLink>
    );
  }

  return link;
};

export default IconLink;
