import styled from "@emotion/styled";

import Avatar from "shared/components/Avatar";
import Icon from "shared/components/Icon";
import Tile from "shared/components/Tile";
import {
  COLORS,
  MEDIA_QUERIES,
  BREAKPOINT_RESOLUTIONS,
} from "shared/config/constants";

const Container = styled.div`
  background-color: ${COLORS.background};
  height: 100vh;
  width: 100vw;
`;

const Content = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-top: 100px;
  max-width: ${BREAKPOINT_RESOLUTIONS.lg}px;

  ${MEDIA_QUERIES.w.ltLg} {
    max-width: ${BREAKPOINT_RESOLUTIONS.md}px;
  }
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 64px;

  ${MEDIA_QUERIES.w.ltLg} {
    margin-bottom: 40px;
  }
`;

const Title = styled.h1`
  margin-bottom: 24px !important;
`;

const Subtitle = styled.p`
  font-size: 15px;
  font-weight: 300;
`;

const Company = styled(Tile)`
  cursor: pointer;

  ${MEDIA_QUERIES.w.gteMd} {
    height: 224px;
  }
`;

const CompanyInner = styled(Tile.Inner)`
  display: flex;
  padding: 32px;
`;

const DeactivatedCompanyInner = styled(CompanyInner)`
  padding-bottom: 23px;
`;

const CompanyLogo = styled(Avatar)`
  ${MEDIA_QUERIES.w.ltMd} {
    margin-right: 12px;
  }
`;

const CompanyInfo = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${MEDIA_QUERIES.w.ltMd} {
    flex-direction: row;
    justify-content: flex-start;
  }
`;

const CompanyName = styled.h3`
  font-size: 17px;
  margin-top: 16px;
  margin-bottom: 24px !important;
  max-width: 300px;

  ${MEDIA_QUERIES.w.gteMd} {
    max-width: 300px;
  }
  ${MEDIA_QUERIES.w.ltMd} {
    margin-top: 0;
  }
`;

const DeactivatedCompanyName = styled(CompanyName)`
  margin-bottom: 1px !important;
  color: ${COLORS.lightGray4};
`;

const CompanyAddress = styled.p`
  margin-bottom: 0;
  font-weight: 300;
  font-size: 12px;
  color: ${COLORS.secondary};

  ${MEDIA_QUERIES.w.gteMd} {
    max-width: 300px;
  }
`;

const DeactivatedCompanyAddress = styled(CompanyAddress)`
  color: ${COLORS.gray1};
`;

const DeactivatedMessage = styled.p`
  color: ${COLORS.lightRed2};
  font-size: 12px;
  margin-bottom: 2px;
  margin-left: 0.5em;
  display: inline-block;
`;

const CompanyArrow = styled.div`
  display: flex;
  align-items: center;

  ${MEDIA_QUERIES.w.ltMd} {
    display: none;
  }
`;

const NoCompaniesIcon = styled(Icon.NoCompaniesIcon)`
  display: block;
  margin: 0 auto;
`;

const LoadingSpinner = styled(Icon.LoadingSpinnerGreenLargeIcon)`
  display: block;
  margin: 0 auto;
`;

export {
  Container,
  Title,
  Subtitle,
  Content,
  Header,
  Company,
  CompanyInner,
  CompanyLogo,
  CompanyInfo,
  CompanyName,
  CompanyAddress,
  CompanyArrow,
  DeactivatedCompanyName,
  DeactivatedCompanyAddress,
  DeactivatedCompanyInner,
  DeactivatedMessage,
  NoCompaniesIcon,
  LoadingSpinner,
};
