import React from "react";

import Spacer from "shared/components/Spacer";
import Tooltip from "shared/components/Tooltip";
import { PERSONA_CHECK_STATUS } from "shared/config/constants";

import FlexContainer from "../FlexContainer";
import Icon from "../Icon";
import { RegularText, SmallText } from "../Typography";
import Well from "../Well";

import { ReasonListWrapper } from "./styled";

import { mapFailureReasons } from "./utils";

export interface VerificationErrorMessagesProps {
  checks: {
    requirement: null | "required" | "not_required";
    name: string;
    reasons: string[];
    status: NonNullable<"passed" | "failed" | "not_applicable">;
  }[];
}

const VerificationErrorMessages = ({
  checks,
}: VerificationErrorMessagesProps) => {
  const failedChecks = checks
    .filter((check) => check.status === PERSONA_CHECK_STATUS.FAILED)
    .filter(
      (check) => check.requirement === "required" || check.requirement === null
    );
  const warningChecks = checks
    .filter((check) => check.status === PERSONA_CHECK_STATUS.FAILED)
    .filter((check) => check.requirement === "not_required");

  const failureReasonsChecksMap = mapFailureReasons(failedChecks);
  const potentialFraudRisksChecksMap = mapFailureReasons(warningChecks);

  const renderFailureReasons = () => {
    return (
      <Well error>
        <SmallText strong>FAILURE REASONS</SmallText>
        <ReasonListWrapper>
          {Array.from(failureReasonsChecksMap.keys()).map((reason) => {
            const reasonChecks = Array.from(
              failureReasonsChecksMap.get(reason)
            );
            return (
              <FlexContainer
                key={reason}
                alignItems="center"
                className="reason-row"
              >
                <Tooltip
                  title={
                    <>
                      <SmallText strong>Snap error codes</SmallText>
                      <ul>
                        {reasonChecks.map((check) => (
                          // @ts-ignore
                          <li key={check}>
                            <SmallText>{check}</SmallText>
                          </li>
                        ))}
                      </ul>
                    </>
                  }
                  theme="light"
                  placement="topRight"
                >
                  <Icon.OrangeExclamationSmall className="info-icon" />
                </Tooltip>
                <RegularText>{reason}</RegularText>
              </FlexContainer>
            );
          })}
        </ReasonListWrapper>
      </Well>
    );
  };

  const renderPotentialFraudRisks = () => {
    return (
      <>
        {failureReasonsChecksMap.size > 0 && <Spacer size="sm" />}
        <Well lightWarning>
          <SmallText strong>POTENTIAL FRAUD RISKS</SmallText>
          <ReasonListWrapper>
            {Array.from(potentialFraudRisksChecksMap.keys()).map((reason) => {
              const reasonChecks = Array.from(
                potentialFraudRisksChecksMap.get(reason)
              );
              return (
                <FlexContainer
                  key={reason}
                  alignItems="center"
                  className="reason-row"
                >
                  <Tooltip
                    title={
                      <>
                        <SmallText strong>Snap error codes</SmallText>
                        <ul>
                          {reasonChecks.map((check) => (
                            // @ts-ignore
                            <li key={check}>
                              <SmallText>{check}</SmallText>
                            </li>
                          ))}
                        </ul>
                      </>
                    }
                    theme="light"
                    placement="topRight"
                  >
                    <Icon.OrangeExclamationSmall className="info-icon" />
                  </Tooltip>
                  <RegularText>{reason}</RegularText>
                </FlexContainer>
              );
            })}
          </ReasonListWrapper>
        </Well>
      </>
    );
  };

  return (
    <>
      {failureReasonsChecksMap.size > 0 && renderFailureReasons()}
      {potentialFraudRisksChecksMap.size > 0 && renderPotentialFraudRisks()}
    </>
  );
};

export default VerificationErrorMessages;
