import {
  KeyPrefix,
  Namespace,
  useTranslation as useTranslationBase,
  UseTranslationOptions,
} from "react-i18next";

import { APPS } from "shared/config/constants";

export const useTranslation = (
  options: UseTranslationOptions<KeyPrefix<"renter">>
) =>
  useTranslationBase<Namespace<"renter">, KeyPrefix<"renter">>(
    APPS.renter,
    options
  );
