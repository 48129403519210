import { SessionStorageCollectionManager } from "shared/utils/sessionStorage";

export const LEADS_FILTERS_STORAGE = "LEADS_FILTERS_STORAGE";

export const leadsFiltersStorage = new SessionStorageCollectionManager({
  storageKey: LEADS_FILTERS_STORAGE,
});

export const ORDER_BY_NAMES = Object.freeze({
  firstName: "applicant__user__first_name",
  lastName: "applicant__user__last_name",
  phoneNumber: "applicant__user__phone_number",
  invitedDate: "invited_date",
});

export const ORDER_BY = Object.freeze({
  applicant: {
    ascend: [ORDER_BY_NAMES.firstName, ORDER_BY_NAMES.lastName].join(),
    descend: [ORDER_BY_NAMES.firstName, ORDER_BY_NAMES.lastName]
      .map((name) => `-${name}`)
      .join(),
  },
  phoneNumber: {
    ascend: ORDER_BY_NAMES.phoneNumber,
    descend: `-${ORDER_BY_NAMES.phoneNumber}`,
  },
  invitedDate: {
    ascend: ORDER_BY_NAMES.invitedDate,
    descend: `-${ORDER_BY_NAMES.invitedDate}`,
  },
});

export const combineFilters = ({
  filters,
  pagination,
  ordering,
  showArchived,
  leadType,
}) => {
  const propertyIdFilter = (filters.propertyId || []).join(",");

  return {
    ...filters,
    ...pagination,
    ordering,
    isArchived: showArchived ? null : false,
    propertyId: propertyIdFilter,
    leadType,
  };
};

export const blankFilters = {
  nameOrEmail: "",
  dateMin: null,
  dateMax: null,
  leadStatus: "",
  propertyId: [],
  leadType: "",
};

export const KEYS = Object.freeze({
  filters: "filters",
  pagination: "pagination",
  ordering: "ordering",
  showArchived: "showArchived",
  leadType: "leadType",
});

export const getInitialOrderingColumn = (ordering) => {
  const columnKey = Object.keys(ORDER_BY).find((key) => {
    return (
      ORDER_BY[key].ascend === ordering || ORDER_BY[key].descend === ordering
    );
  });

  return columnKey
    ? {
        [columnKey]:
          ORDER_BY[columnKey].ascend === ordering ? "ascend" : "descend",
      }
    : {};
};
