import React, { useRef } from "react";

import { FormikHelpers } from "formik";

import {
  useUpdateLROSettings,
  useGetDealSetup,
  useUpdateDealSetup,
} from "manager/hooks/api/dealSetupQueries";
import { DealSetup } from "manager/interfaces/api";

import LeaseRentOptions from "./LeaseRentOptions";

interface LeaseRentOptionsContainerProps {
  dealSetupId: number;
  hasRevenueManagementExpirationEnabled?: boolean;
  disabled?: boolean;
  onSubmit?: () => void;
  submitButtonLabel?: string;
  showSecondaryButton?: boolean;
  onSecondaryButtonClick?: () => void;
  secondaryButtonLabel?: string;
}

const LeaseRentOptionsContainer = ({
  dealSetupId,
  hasRevenueManagementExpirationEnabled,
  disabled,
  onSubmit,
  submitButtonLabel,
  showSecondaryButton = false,
  onSecondaryButtonClick,
  secondaryButtonLabel,
}: LeaseRentOptionsContainerProps) => {
  const formRef = useRef(null);

  const { dealSetup, isDealSetupLoading } = useGetDealSetup(dealSetupId);
  const { updateDealSetup } = useUpdateDealSetup();
  const { updateLRO } = useUpdateLROSettings(dealSetup?.id);

  const saveChanges = async (values: DealSetup) => {
    if (formRef.current?.isValid && !disabled) {
      if (hasRevenueManagementExpirationEnabled) {
        updateLRO({
          enabled: values?.lroConfig.enabled,
          selectionValidityLifespan:
            values?.lroConfig.selectionValidityLifespan,
        });
      } else {
        // @ts-ignore
        await updateDealSetup({
          id: dealSetup.id,
          dealSetup: values,
        });
      }
    }
  };

  const defaultOnSubmit = async (
    values: DealSetup,
    { resetForm }: FormikHelpers<DealSetup>
  ) => {
    if (!disabled) {
      await saveChanges(values);
      resetForm({ values });
    }
  };

  const shouldDisableSubmitButton = !onSubmit;
  const shouldShowExpirationInputField = hasRevenueManagementExpirationEnabled;
  return (
    <LeaseRentOptions
      loading={isDealSetupLoading}
      dealSetup={dealSetup}
      saveChanges={saveChanges}
      onSubmit={onSubmit || defaultOnSubmit}
      formRef={formRef}
      disabled={disabled}
      shouldDisableSubmitButton={shouldDisableSubmitButton}
      submitButtonLabel={submitButtonLabel}
      onSecondaryButtonClick={onSecondaryButtonClick}
      secondaryButtonLabel={secondaryButtonLabel}
      showSecondaryButton={showSecondaryButton}
      shouldShowExpirationInputField={shouldShowExpirationInputField}
    />
  );
};

export default LeaseRentOptionsContainer;
