import { CSSProperties } from "react";

import styled from "@emotion/styled";

import { MEDIA_QUERIES } from "shared/config/constants";

export const DealWrapper = styled("div")({
  display: "flex",
  fontWeight: 400,
  [MEDIA_QUERIES.w.ltSm]: {
    flexDirection: "column",
  },
});

export const ColumnData = styled("div")(
  (props: { width: CSSProperties["width"] }) => ({
    minWidth: "120px",
    width: props?.width,
    marginBottom: 0,
    display: "flex",
    alignItems: "center",
    marginRight: "10px",
    [MEDIA_QUERIES.w.ltSm]: {
      marginBottom: "10px",
    },
    "&>:last-child": {
      marginRight: 0,
    },
  })
);

export const Applications = styled("div")({
  display: "flex",
  flexDirection: "column",
});

export const ApplicantName = styled("div")({
  marginBottom: "10px",
  whiteSpace: "break-spaces",
  "&:last-child": {
    marginBottom: 0,
  },
});

export const Address = styled("div")({
  display: "flex",
  flexDirection: "column",
  whiteSpace: "break-spaces",
});

export const Property = styled("div")({
  fontSize: "12px",
  color: "#807E8D",
});
