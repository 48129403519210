import styled from "@emotion/styled";

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .background-row {
    display: flex;
    justify-content: space-between;
    height: 50%;
  }

  .background-row.top {
    align-items: flex-start;
  }

  .background-row.bottom {
    align-items: flex-end;
  }

  img {
    max-height: 100%;
  }

  .lower-left-bg {
    max-width: 600px;
  }
`;

export { Background };
