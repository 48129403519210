import React, { useEffect, useState } from "react";

import { FormikHelpers } from "formik";
import merge from "lodash/merge";
import { useHistory } from "react-router-dom";

import {
  useGetLeadSources,
  useGetPropertiesDropdown,
  useGetPropertyUnitsDropdown,
} from "manager/hooks/api";
import { useGetApplicantTypes } from "shared/hooks/api";
import { getIntegrationType } from "manager/utils/integration.util";
import { isListingValid } from "manager/utils/unit.util";
import { initialValues as applicantsInitialValues } from "shared/forms/InviteApplicantsForm";

import { useActiveCompany, usePermissions } from "shared/hooks";
import useModal from "shared/hooks/useModal";
import { onDialogSubmitError } from "shared/utils/api";

import { InitialValues } from "./interfaces";
import InviteApplicantsModal from "./InviteApplicantsModal";
import { getValidationSchema, MODES, inviteApplicants } from "./util";

const EMPTY_UNIT_LISTING = {
  rent: null,
  unitAvailableOn: "",
  securityDeposit: null,
};

const prepareSubmitData = ({
  applicants,
  unit,
  unitListingRequired,
  unitListing,
  leadSource,
}: InitialValues) => ({
  applicants,
  unitId: unit,
  unitListing: unitListingRequired && {
    rent: unitListing.rent,
    unitAvailableOn: unitListing.unitAvailableOn,
    securityDeposit: unitListing.securityDeposit,
    unit,
  },
  leadSource,
});

const InviteApplicantsModalContainer = () => {
  const history = useHistory();
  const { closeActiveModalDialog } = useModal();
  const [mode, setMode] = useState(MODES.TO_UNIT);
  const [selectedProperty, setSelectedProperty] = useState();

  let initialValues: InitialValues = {
    ...applicantsInitialValues,
    leadSource: undefined,
  };

  const { preventInvitesToTheApplicationWithoutUnit } = usePermissions();

  const { properties, isPropertiesLoading } = useGetPropertiesDropdown();

  const {
    units: unitsData,
    isUnitsLoading,
    refetchUnits,
  } = useGetPropertyUnitsDropdown({ propertyId: selectedProperty });
  const units = unitsData?.map((unit) => ({
    id: unit.id,
    name: unit.name,
    listingPresented: isListingValid(unit.unitListingCurrent),
    numberOfPotentialDeals: unit.unitListingCurrent?.numberOfPotentialDeals,
    numberOfActiveDeals: unit.unitListingCurrent?.numberOfActiveDeals,
    integrationType: getIntegrationType(unit),
  }));

  const { activeCompany } = useActiveCompany();
  const {
    showOnlyUnitsNotAttachedToAnotherActiveDeal:
      hideUnitsAttachedToAnotherActiveDeal,
  } = activeCompany || {};
  const { applicantTypes, isLoading: applicantTypesLoading } =
    useGetApplicantTypes();
  const { leadSources, isLoading: leadSourcesLoading } =
    useGetLeadSources(true);

  const submit = (
    values: InitialValues,
    formikApi: FormikHelpers<InitialValues>
  ) =>
    inviteApplicants({
      values: prepareSubmitData(values),
      reject: onDialogSubmitError(formikApi, history),
      history,
      closeActiveModalDialog,
    });

  const selectUnit = (value, setValues, values) => {
    const newUnit = units.find(({ id }) => id === Number(value));
    const unitListingRequired = newUnit && !newUnit.listingPresented;

    setValues({ ...values, unit: value, unitListingRequired }, true);
  };

  useEffect(() => {
    refetchUnits();
  }, [selectedProperty]);

  const selectProperty = (value, setValues, values) => {
    setSelectedProperty(value);
    setValues(
      {
        ...values,
        property: value,
        unit: "",
        unitListingRequired: false,
        unitListing: {
          ...EMPTY_UNIT_LISTING,
        },
      },
      true
    );
  };

  const onModeChange = (value, values) => {
    setMode(value);
    initialValues = {
      ...initialValues,
      applicants: merge(initialValues.applicants, values.applicants),
    };
  };

  const getInitialValues = () => {
    initialValues = {
      ...applicantsInitialValues,
      leadSource: undefined,
    };

    if (mode === MODES.TO_UNIT) {
      initialValues = {
        ...initialValues,
        unit: "",
        property: "",
        unitListingRequired: false,
        unitListing: {
          ...EMPTY_UNIT_LISTING,
        },
      };
    }

    return initialValues;
  };

  return (
    <InviteApplicantsModal
      properties={properties}
      isPropertiesLoading={isPropertiesLoading}
      units={units}
      isUnitsLoading={isUnitsLoading}
      applicantTypes={applicantTypes}
      applicantTypesLoading={applicantTypesLoading}
      leadSources={leadSources}
      leadSourcesLoading={leadSourcesLoading}
      submit={submit}
      selectUnit={selectUnit}
      selectProperty={selectProperty}
      onModeChange={onModeChange}
      mode={mode}
      schema={getValidationSchema(mode, !!activeCompany?.yardiConnected)}
      initialValues={getInitialValues()}
      disableInvitingWithoutUnit={preventInvitesToTheApplicationWithoutUnit}
      hideUnitsAttachedToAnotherActiveDeal={
        hideUnitsAttachedToAnotherActiveDeal
      }
    />
  );
};

export default InviteApplicantsModalContainer;
