import React from "react";

import { Row, Col } from "antd";

import { formatPhone } from "shared/utils/ui";

import Spacer from "../Spacer";
import Tile from "../Tile";
import { RegularText } from "../Typography";

import { LandLordInfoContainer } from "./styled";
import { LandLordDetails } from "./verification.interfaces";

const LandLordInfoCard = ({
  fullName,
  phoneNumber,
  email,
}: LandLordDetails) => (
  <LandLordInfoContainer noBorder equalHeight>
    <Tile.Inner>
      <RegularText strong>LANDLORD INFORMATION</RegularText>
    </Tile.Inner>
    <Tile.Inner>
      <Row gutter={[48, 16]} type="flex">
        <Col>
          <Tile.Label>Landlord Name</Tile.Label>
          <RegularText strong>{fullName}</RegularText>
        </Col>
        <Col>
          <Tile.Label>Landlord&apos;s Phone</Tile.Label>
          <RegularText>{formatPhone(phoneNumber)}</RegularText>
        </Col>
      </Row>
      <Spacer size={Spacer.SIZES.md} />
      <Row>
        <Col>
          <Tile.Label>Landlord&apos;s Email</Tile.Label>
          <RegularText className="email">{email}</RegularText>
        </Col>
      </Row>
      <Spacer />
    </Tile.Inner>
  </LandLordInfoContainer>
);

export default LandLordInfoCard;
