import axios from "axios";

import MANAGER_API_ROUTES from "manager/config/apiRoutes";
import { API_URL } from "shared/lib/api/setupApi";
import { transformRoute } from "shared/utils/routing";

const api = {
  postMarketingSource: (marketingSource) =>
    axios
      .post(`${API_URL}${MANAGER_API_ROUTES.marketingSources}`, marketingSource)
      .then((response) => response.data),
  patchMarketingSource: ({ id, marketingSource }) =>
    axios
      .patch(
        `${API_URL}${transformRoute(MANAGER_API_ROUTES.marketingSource, {
          id,
        })}`,
        marketingSource
      )
      .then((response) => response.data),
  deleteMarketingSource: ({ id }) =>
    axios
      .delete(
        `${API_URL}${transformRoute(MANAGER_API_ROUTES.marketingSource, {
          id,
        })}`
      )
      .then((response) => response.data),
  putMarketingSources: ({ propertyId, marketingSources }) =>
    axios
      .put(
        transformRoute(
          `${API_URL}${MANAGER_API_ROUTES.marketingSourcesFullSave}`,
          {
            propertyId,
          }
        ),
        marketingSources
      )
      .then((response) => response.data),

  getLeadSources: (excludeSystem) =>
    axios
      .get(`${API_URL}${MANAGER_API_ROUTES.leadSources}`, {
        params: {
          exclude_system: excludeSystem,
        },
      })
      .then((response) => response.data),
  postLeadSource: (leadSource) =>
    axios
      .post(`${API_URL}${MANAGER_API_ROUTES.leadSources}`, leadSource)
      .then((response) => response.data),
  patchLeadSource: ({ id, leadSource }) =>
    axios
      .patch(
        `${API_URL}${transformRoute(MANAGER_API_ROUTES.leadSource, {
          id,
        })}`,
        leadSource
      )
      .then((response) => response.data),
  deleteLeadSource: ({ id }) =>
    axios
      .delete(
        `${API_URL}${transformRoute(MANAGER_API_ROUTES.leadSource, {
          id,
        })}`
      )
      .then((response) => response.data),
  putLeadSources: (leadSources) =>
    axios
      .put(`${API_URL}${MANAGER_API_ROUTES.leadSourcesFullSave}`, leadSources)
      .then((response) => response.data),
};

export default api;
