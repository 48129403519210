import React, { Key } from "react";

import { useEditIncomeAndAssets } from "shared/hooks/api";
import useModal from "shared/hooks/useModal";
import { EditIncomeAndAssetsParams } from "shared/interfaces/api";

import EditIncomeAssetsModal from "./EditIncomeAssetsModal";
import { ApplicationSummary } from "./interfaces";

interface EditIncomeAssetsModalContainerProps {
  applicationId: Key;
  deal: {
    id: Key;
    applicantsSummary: ApplicationSummary[];
    rent: number;
  };
  refetchFullApplication?: any;
}

const EditIncomeAssetsModalContainer = ({
  applicationId,
  deal,
  refetchFullApplication,
}: EditIncomeAssetsModalContainerProps) => {
  const { closeActiveModalDialog } = useModal();
  const { editIncomeAndAssets } = useEditIncomeAndAssets(applicationId);

  const submit = ({ income, assets }: EditIncomeAndAssetsParams) =>
    editIncomeAndAssets(
      { income, assets },
      {
        onSuccess: () => {
          refetchFullApplication();
          closeActiveModalDialog();
        },
      }
    );

  const application = deal?.applicantsSummary?.find(
    (item) => item.id === applicationId
  );
  const initialValues = {
    income: application?.income.value || 0,
    assets: application?.assets.value || 0,
  };
  const rent = deal?.rent;

  return (
    <EditIncomeAssetsModal
      initialValues={initialValues}
      application={application}
      rent={rent}
      submit={submit}
    />
  );
};

export default EditIncomeAssetsModalContainer;
