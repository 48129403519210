import SHARED_ROUTES from "shared/config/routes";

const ROUTES = {
  ...SHARED_ROUTES,
  // Main
  dashboard: "/",
  // Employee
  employees: "/employees",
  employee: "/employees/:id",
  // Permissions
  permissions: "/permissions",
  // Property
  properties: "/properties",
  property: "/properties/:id",
  propertySetupTab: "/properties/:id/:tabName",
  // Portfolio
  portfolios: "/portfolios",
  portfolio: "/portfolios/:id",
  portfolioSettings: "/portfolios/:id/settings",
  portfolioSettingsTab: "/portfolios/:id/settings/:tabName",
  // Unit
  units: "/units",
  unit: "/units/:id",
  unitListings: "/units/:id/listings",
  unitDeals: "/units/:id/deals",
  unitFees: "/units/:id/fees",
  unitLumen: "/units/:id/lumen",
  unitWorkflow: "/units/:id/workflow",

  // Settings
  settings: "/settings",
  // Marketplace
  marketplace: "/marketplace",
  integrationLogin: "/integrations/:provider/login",
  integrationEditCredentials: "/integrations/:provider/edit-credentials",
  integrationDashboard: "/integrations/:provider/dashboard",
  integrationSetupProperty: "/integrations/:provider/property/:propertyId",
  integrationConfigProperty:
    "/integrations/:provider/property/:propertyId/details",
  integrationSetupChargeTypes: "/integrations/:provider/charge-types",
  integrationSetupDocumentTypes: "/integrations/:provider/document-types",
  integrationAlerts: "/integrations/:provider/alerts",
  integrationSetupLeadSources: "/integrations/:provider/lead-sources",
  integrationSetupSyncSettings: "/integrations/:provider/sync-settings",
  // Bluemoon
  bluemoonDashboard: "/integrations/bluemoon-dashboard",
  bluemoonFieldsMapping: "/integrations/bluemoon-fields-mapping",
  bluemoonPropertyBindings: "/integrations/bluemoon-property-bindings",
  bluemoonBoundProperties: "/integrations/bluemoon-dashboard/bound-properties",
  // Lumen
  lumen: "/lumen",
  lumenDashboard: "/lumen/dashboard",
  lumenFormulaRevision: "/lumen/formulas/:formulaId/revisions/:revisionId",
  // Applications
  deal: "/deals/:id",
  dealExtraDetails: "/deal-extra-details/:id",
  deals: "/deals",
  dealActivity: "/deals/:id/activity",
  dealLumen: "/deals/:id/lumen",
  dealLedger: "/deals/:id/ledger",

  // Leads / Prescreening
  leads: "/leads",
  lead: "/leads/:id",

  // Company
  applicationsSetup: "/applications-setup",
  applicationsSetupTab: "/applications-setup/:tabName",
  prescreeningSetup: "/prequalification-setup",

  // Renters and applicants
  applicants: "/applicants",
  applicant: "/applicants/:id",
  // Mobile routes
  notifications: "/notifications",
  myProfile: "/my-profile",
  // Company roles
  companyRole: "/company-roles/:id",
  // Auth
  selectCompany: "/select-company",
  notificationsSettings: "/notifications-settings",
  // Magic Links
  magicLinks: "/magic-links",
  magicLinksContactManager: "/magic-links/contact-manager",
  magicLinksProperties: "/magic-links/properties",
  magicLinksAgents: "/magic-links/agents",
  downloadCenter: "/download-center",
  // Open API
  openApiSettings: "/openapi-settings",
  // Email Builder
  emailBuilder: "/email-builder/create/:defaultConfigurationId",
  emailBuilderEdit: "/email-builder/edit/:templateId",

  // Experian
  experianAuthentication: "/experian/auth",
};

const APPLICATION_LEASE_SETUP_TABS = {
  screeningSetup: "screening-setup",
  applicationSetup: "application-setup",
  workflow: "workflow",
  lumen: "lumen",
  additionalSettings: "additional-settings",
  emailTemplates: "email-templates",
  applicationCharges: "application-charges",
};

const PROPERTY_SETTINGS_TABS = {
  units: "units",
  screeningSetup: "screening-setup",
  applicationSetup: "application-setup",
  workflow: "workflow",
  lumen: "lumen",
  additionalSettings: "additional-settings",
  emailTemplates: "email-templates",
  applicationCharges: "application-charges",
  prescreening: "prescreening",
  yardiLROSetup: "yardi-lro-setup",
};

const PORTFOLIO_SETTINGS_TABS = {
  screeningSetup: "screening-setup",
  applicationSetup: "application-setup",
  workflow: "workflow",
  lumen: "lumen",
  applicationCharges: "application-charges",
  prescreening: "prescreening",
  yardiLROSetup: "yardi-lro-setup",
};

const GROUPED_ROUTES_KEYS = Object.freeze({
  DASHBOARD: "DASHBOARD",
  DEALS: "DEALS",
  PROPERTIES_AND_PORTFOLIOS: "PROPERTIES_AND_PORTFOLIOS",
  MARKETPLACE: "MARKETPLACE",
  SETTINGS: "SETTINGS",
  LEADS: "LEADS",
  EXPERIAN_AUTHENTICATION: "EXPERIAN_AUTHENTICATION",
});

const GROUPED_ROUTES = {
  [GROUPED_ROUTES_KEYS.DASHBOARD]: [ROUTES.dashboard],
  [GROUPED_ROUTES_KEYS.DEALS]: [
    ROUTES.deals,
    ROUTES.application,
    ROUTES.applicationAttachments,
    ROUTES.applicationCreditBackground,
    ROUTES.applicationIdVerification,
    ROUTES.applicationVorVerifications,
    ROUTES.applicationIncomeAssets,
    ROUTES.applicationRenterProfile,
    ROUTES.applicationVerifications,
    ROUTES.applicationWorkflow,
    ROUTES.applicationPayments,
    ROUTES.applicationLumen,
    ROUTES.deal,
    ROUTES.dealExtraDetails,
    ROUTES.dealActivity,
    ROUTES.dealLedger,
  ],
  [GROUPED_ROUTES_KEYS.PROPERTIES_AND_PORTFOLIOS]: [
    ROUTES.properties,
    ROUTES.property,
    ROUTES.propertySetupTab,
    ROUTES.units,
    ROUTES.unit,
    ROUTES.unitListings,
    ROUTES.unitDeals,
    ROUTES.unitFees,
    ROUTES.unitWorkflow,
    ROUTES.unitLumen,
    ROUTES.portfolios,
    ROUTES.portfolio,
    ROUTES.portfolioSettings,
    ROUTES.portfolioSettingsTab,
  ],
  [GROUPED_ROUTES_KEYS.MARKETPLACE]: [
    ROUTES.marketplace,
    ROUTES.integrationLogin,
    ROUTES.integrationEditCredentials,
    ROUTES.integrationDashboard,
    ROUTES.integrationSetupProperty,
    ROUTES.integrationConfigProperty,
    ROUTES.integrationSetupChargeTypes,
    ROUTES.integrationSetupDocumentTypes,
    ROUTES.integrationAlerts,
    ROUTES.integrationSetupLeadSources,
    ROUTES.integrationSetupSyncSettings,
    ROUTES.bluemoonDashboard,
    ROUTES.bluemoonFieldsMapping,
    ROUTES.bluemoonPropertyBindings,
    ROUTES.bluemoonBoundProperties,
  ],
  [GROUPED_ROUTES_KEYS.SETTINGS]: [
    ROUTES.settings,
    ROUTES.magicLinks,
    ROUTES.magicLinksContactManager,
    ROUTES.magicLinksProperties,
    ROUTES.magicLinksAgents,
    ROUTES.lumen,
    ROUTES.lumenDashboard,
    ROUTES.lumenFormulaRevision,
    ROUTES.employees,
    ROUTES.employee,
    ROUTES.permissions,
    ROUTES.openApiSettings,
    ROUTES.downloadCenter,
    ROUTES.emailBuilder,
    ROUTES.emailBuilderEdit,
    ROUTES.applicationsSetup,
    ROUTES.applicationsSetupTab,
    ROUTES.prescreeningSetup,
  ],
  [GROUPED_ROUTES_KEYS.LEADS]: [ROUTES.leads, ROUTES.lead],
  [GROUPED_ROUTES_KEYS.EXPERIAN_AUTHENTICATION]: [
    ROUTES.experianAuthentication,
  ],
};

export default ROUTES;

export {
  APPLICATION_LEASE_SETUP_TABS,
  PROPERTY_SETTINGS_TABS,
  PORTFOLIO_SETTINGS_TABS,
  GROUPED_ROUTES,
  GROUPED_ROUTES_KEYS,
};
