import styled from "@emotion/styled";

import { Checkbox } from "shared/components/Form/Checkbox";

const Manager = styled.label`
  font-size: 15px;
  font-weight: 400;
`;

const CheckboxContainer = styled.div`
  display: flex;
  margin-top: 35px;
  margin-bottom: 33px;
  flex-direction: column;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 5px;
  margin-right: 7px;
  margin-left: -1px;
`;

const StyledCheckboxGroup = styled(Checkbox.Group)`
  display: flex;
  flex-direction: column;
  .ant-checkbox-group-item {
    margin-bottom: 12px;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export {
  LoadingContainer,
  CheckboxContainer,
  Manager,
  StyledCheckbox,
  StyledCheckboxGroup,
};
