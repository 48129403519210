import React from "react";

import { useHistory } from "react-router-dom";

import ROUTES from "manager/config/routes";
import Icon from "shared/components/Icon";
import AnimatedPendingActionModal from "shared/components/Modals/AnimatedPendingActionModal";
import { COMMON_PENDING_ANIMATION_MESSAGES } from "shared/config/constants";
import { transformRoute } from "shared/utils/routing";

import { YardiConnectionModalProps } from "./interfaces";
import { YardiConnectionUnderlineLink } from "./styled";

const LOADING_STATUSES = [
  ...COMMON_PENDING_ANIMATION_MESSAGES,
  "Connection confirmed!",
];

const TIMEOUT = 3000;

const YardiConnectionModal = ({
  provider,
  cancelYardiConnectionOperation,
  isLoading,
}: YardiConnectionModalProps) => {
  const history = useHistory();

  const navigateAfterConnection = () =>
    history.push(
      transformRoute(ROUTES.integrationDashboard, {
        provider,
      })
    );

  return (
    <AnimatedPendingActionModal
      finalMessageElement={() => <Icon.OperationSuccess />}
      messages={LOADING_STATUSES}
      timeout={TIMEOUT}
      afterCloseOperation={navigateAfterConnection}
    >
      <YardiConnectionUnderlineLink
        disabled={!isLoading}
        onClick={cancelYardiConnectionOperation}
      >
        Cancel connection
      </YardiConnectionUnderlineLink>
    </AnimatedPendingActionModal>
  );
};

export default YardiConnectionModal;
