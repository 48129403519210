import React from "react";

import PropTypes from "prop-types";

import Banner from "shared/components/Banner";
import Icon from "shared/components/Icon";

const ConditionallyProceedInfoBanner = ({ lumenEnabled }) => {
  const message = `Conditionally proceed with the application to view the criminal background results.${
    lumenEnabled ? " This will recalculate Lumen." : ""
  }`;
  return <Banner message={message} Icon={Icon.OrangeExclamationSmall} />;
};

ConditionallyProceedInfoBanner.propTypes = {
  lumenEnabled: PropTypes.bool,
};

ConditionallyProceedInfoBanner.defaultProps = {
  lumenEnabled: false,
};

export default ConditionallyProceedInfoBanner;
