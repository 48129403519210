import styled from "@emotion/styled";

import { MEDIA_QUERIES } from "shared/config/constants";

const FiltersContainer = styled.div`
  position: fixed;
  top: 60px;
  left: 0;
  width: 100vw;
  z-index: 200;

  .close-icon {
    position: absolute;
    right: 28px;
    top: 27px;
  }

  .close-icon:hover {
    cursor: pointer;
  }

  .tile-header {
    flex-direction: row;
  }

  ${MEDIA_QUERIES.w.gteMd} {
    top: 70px;
  }
`;

export { FiltersContainer };
