import { useQuery } from "react-query";

import api from "shared/lib/api";
import { PactSafeConfigParams } from "shared/lib/api/configApi";

export const useGetSSOConfig = () => {
  const { data: ssoConfig, isLoading: isSSOConfigLoading } = useQuery(
    ["getSSOConfig"],
    () => api.getSSOConfig(),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );

  return {
    ssoConfig,
    isSSOConfigLoading,
  };
};

export const useGetPactSafeConfig = (data?: PactSafeConfigParams) => {
  const { data: pactSafeConfig, isLoading: isPactSafeConfigLoading } = useQuery(
    ["getPactSafeConfig"],
    () => api.getPactSafeConfig(data),
    {
      retry: false,
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );

  return {
    pactSafeConfig,
    isPactSafeConfigLoading,
  };
};
