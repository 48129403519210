import { Key } from "react";

import axios from "axios";

import MANAGER_ROUTES from "manager/config/apiRoutes";
import ROUTES, { VEGA_BASE_URL } from "shared/config/apiRoutes";
import { DEAL_UI_STATUS_CODES } from "shared/config/constants";
import { API_URL } from "shared/lib/api/setupApi";
import { transformRoute } from "shared/utils/routing";
import { notifyError } from "shared/utils/ui";

export const getDeals = ({
  dateMin,
  dateMax,
  propertyId,
  unitId,
  unitPropertyOrApplicant,
  dealCategory,
  dealStatus,
  lastLumenResult,
  pageSize,
  page,
  ordering,
  isArchived,
  hasYardiStatus,
  isAnyApplicationFeePaid,
}) =>
  axios
    .get(`${API_URL}${ROUTES.deals}`, {
      params: {
        created_at__gte: dateMin,
        created_at__lte: dateMax,
        property_id__in: propertyId,
        unit_id: unitId,
        unit_or_property_or_applicant: unitPropertyOrApplicant,
        deal_category: dealCategory,
        deal_status: dealStatus,
        page_size: pageSize,
        last_lumen_score: lastLumenResult,
        page,
        ordering,
        is_archived: isArchived,
        is_any_application_fee_paid: isAnyApplicationFeePaid,
        has_yardi_status: hasYardiStatus,
      },
      // @ts-ignore
      clearStorageAndReloadOnBadRequest: true,
    })
    .then(({ data }) => data);

export const getDealsCounts = ({
  dateMin,
  dateMax,
  propertyId,
  unitId,
  unitPropertyOrApplicant,
  dealStatus,
  lastLumenResult,
  isArchived,
  hasYardiStatus,
  isAnyApplicationFeePaid,
}) =>
  axios
    .get(`${API_URL}${ROUTES.dealsCount}`, {
      params: {
        created_at__gte: dateMin,
        created_at__lte: dateMax,
        property_id__in: propertyId,
        unit_id: unitId,
        unit_or_property_or_applicant: unitPropertyOrApplicant,
        deal_status: dealStatus,
        last_lumen_score: lastLumenResult,
        is_archived: isArchived,
        is_any_application_fee_paid: isAnyApplicationFeePaid,
        has_yardi_status: hasYardiStatus,
      },
      // @ts-ignore
      clearStorageAndReloadOnBadRequest: true,
    })
    .then(({ data }) => data);

export const getDeal = (id) =>
  axios
    .get(transformRoute(`${API_URL}${ROUTES.deal}`, { id }))
    .then(({ data }) => data);

export const updateDealArchiveStatus = ({ id, isArchived }) =>
  axios.patch(
    transformRoute(`${API_URL}${MANAGER_ROUTES.updateDealArchiveStatus}`, {
      id,
    }),
    { isArchived }
  );

export const assignDealToUnitCheck = ({ dealId, unitId, newLeaseStartDate }) =>
  axios
    .get(
      `${API_URL}${transformRoute(MANAGER_ROUTES.assignDealToUnitCheck, {
        id: dealId,
      })}`,
      {
        params: {
          target_unit_id: unitId,
          new_preferred_lease_start_date: newLeaseStartDate,
        },
      }
    )
    .then(({ data }) => data);

export type DealToMove = {
  id: string;
  applications: { firstName: string; lastName: string }[];
  propertyName: string;
  unitName: string;
  canApplicationBeMoved: boolean;
  dealStatus: DEAL_UI_STATUS_CODES;
};

export const getDealsToMove = (id: string | number) =>
  axios
    .get<DealToMove[]>(`${API_URL}${ROUTES.dealsToMove}`, {
      params: {
        source_deal: id,
      },
    })
    .then(({ data }) => data)
    .catch((err) => {
      notifyError("Failed to load applications to move applicant.");
      throw err;
    });

export const recalculateLumen = ({ id }: { id: Key }) =>
  axios.post(
    transformRoute(`${API_URL}${ROUTES.recalculateLumen}`, {
      id,
    })
  );

export const getApplicationIds = (dealId) =>
  axios
    .get(
      `${API_URL}${transformRoute(MANAGER_ROUTES.applicationIds, {
        id: dealId,
      })}`
    )
    .then(({ data }) => data);

export const getLedgerRecords = (params) =>
  axios.get(`${VEGA_BASE_URL}/api/ledger`, {
    params,
  })
