import { schema } from "normalizr";

import { unitListing } from "shared/lib/schema";
import { property } from "shared/state/Property/schema";

export const unit = new schema.Entity("unit", {
  property,
  unitListingCurrent: unitListing,
});

export const unitList = [unit];
