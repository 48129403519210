import React from "react";

import { useTranslation } from "react-i18next";
import { Switch, Route, Redirect } from "react-router-dom";

import ROUTES, {
  GROUPED_ROUTES,
  GROUPED_ROUTES_KEYS,
} from "admin/config/routes";
import DocumentConstructor from "admin/pages/DocumentConstructor";
import Documents from "admin/pages/Documents";
import Login from "admin/pages/Login";
import Icon from "shared/components/Icon";

import { APPS } from "shared/config/constants";
import Error404Page from "shared/pages/Error404Page";
import Layout from "shared/templates/Layout";

import { AdminAppProps } from "./interfaces";

const AdminRouter = ({ user, notifications }: AdminAppProps) => {
  const { t } = useTranslation(APPS.admin, { keyPrefix: "admin-router" });

  const header = {
    logoLink: "/",
    notifications,
    user,
  };
  const { DOCUMENTS } = GROUPED_ROUTES_KEYS;
  const sidebar = {
    items: [
      {
        key: DOCUMENTS,
        text: t("sidebar-title.documents"),
        icon: Icon.DocumentsIcon,
        route: ROUTES.documents,
      },
    ],
    groupedRoutes: GROUPED_ROUTES,
  };

  const backLinks = {
    documentConstructor: {
      children: t("back-link.documents"),
      to: ROUTES.documents,
    },
    draftConstructor: {
      children: t("back-link.drafts"),
      to: ROUTES.draftDocuments,
    },
  };

  return (
    <Switch>
      {/* Redirects */}
      <Redirect exact from="/" to={ROUTES.documents} />
      {/* Documents */}
      {[ROUTES.documents, ROUTES.draftDocuments].map((route) => (
        <Route
          exact
          key={route}
          path={route}
          render={() => (
            <Layout
              sidebar={sidebar}
              header={{
                ...header,
              }}
            >
              <Documents />
            </Layout>
          )}
        />
      ))}
      <Route
        exact
        path={ROUTES.documentConstructor}
        render={() => (
          <DocumentConstructor
            sidebar={sidebar}
            header={header}
            backLink={backLinks.documentConstructor}
          />
        )}
      />
      <Route
        exact
        path={ROUTES.draftConstructor}
        render={() => (
          <DocumentConstructor
            sidebar={sidebar}
            header={header}
            backLink={backLinks.draftConstructor}
          />
        )}
      />
      {/* Auth views */}
      <Route exact path={ROUTES.login} render={() => <Login />} />
      {/* Other views */}
      <Route render={() => <Error404Page />} />
    </Switch>
  );
};

export default AdminRouter;
