import styled from "@emotion/styled";

import BaseFooterBar from "shared/components/FooterBar";
import Well from "shared/components/Well";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";
import { LumenImages } from "shared/images";

export const LumenFormulaFootBarContent = styled(BaseFooterBar)`
  justify-content: space-between;
  .underline-link {
    margin-right: 24px;
  }
`;

export const LumenFormulaConfiguratorContent = styled.div`
  .error-message {
    position: absolute;
  }
`;

export const LumenFormulaStepperContent = styled(Well)`
  margin-bottom: 32px;
`;

export const LumenFormulaStepperBanner = styled.div`
  background-color: ${COLORS.violetTheme.backgroundColorDark};
  color: ${COLORS.well.background};
  height: 82px;
  display: flex;
  align-items: center;
  padding: 0;

  .banner-icon-1 {
    margin: 0 25px;
    height: 100%;
    width: 47px;
    min-width: 47px;
    background-image: url(${LumenImages.LumenFormulaStepper1});
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .banner-icon-2 {
    position: relative;
    height: 79px;
    min-width: 130px;
    margin-right: -14px;
    margin-left: auto;
    background-image: url(${LumenImages.LumenFormulaStepper2});
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .banner-message {
    padding-left: 24px;
    font-size: 28px;
  }

  ${MEDIA_QUERIES.w.ltLg} {
    display: flex;
    height: auto;
    padding: 24px;

    .banner-icon-1 {
      height: 44px;
      width: 47px;
      min-width: 47px;
      margin: 0;
    }
    .banner-icon-2 {
      display: none;
    }

    .banner-message {
      padding-left: 20px;
      font-size: 22px;
    }
  }

  ${MEDIA_QUERIES.w.ltMd} {
    padding: 10px;

    .banner-icon-1 {
      margin: 0 5px;
    }

    .banner-message {
      padding-left: 10px;
      font-size: 17px;
    }
  }
`;

export const LumenFormulaStepperBody = styled.div`
  color: ${COLORS.well.background};
  height: 100%;
  display: flex;
  align-items: center;

  .numbered-stepper {
    ${MEDIA_QUERIES.w.ltMd} {
      display: none;
    }
  }
`;
export const LumenHowItWorksContainer = styled.div`
  margin-left: 20px;
`;
