import axios from "axios";

import ROUTES from "shared/config/apiRoutes";
import { API_URL } from "shared/lib/api/setupApi";
import { transformRoute } from "shared/utils/routing";

const api = {
  previewDocument: (id) =>
    axios
      .post(
        `${API_URL}${transformRoute(ROUTES.previewDocument, {
          id,
        })}`
      )
      .then((response) => response.data),
  updateRiderType: ({ id, riderType }) =>
    axios
      .patch(
        `${API_URL}${transformRoute(ROUTES.updateRiderType, {
          id,
        })}`,
        { riderType }
      )
      .then((response) => response.data),
};

export default api;
