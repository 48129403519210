import styled from "@emotion/styled";

import FlexContainer from "shared/components/FlexContainer";
import { MEDIA_QUERIES } from "shared/config/constants";

const SpaceWrapper = styled("div")({
  marginRight: 30,
});

const Container = styled(FlexContainer)({
  padding: "10px 0 20px",
  [MEDIA_QUERIES.w.ltMd]: {
    paddingLeft: 25,
  },
});

export { SpaceWrapper, Container };
