
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import { Col, Row } from "antd";
import { Form, Formik, FormikHelpers } from "formik";
import isEmpty from "lodash/isEmpty";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

import { RENTER_GLOBAL_CONFIG_KEYS } from "renter/config/constants";
import ROUTES from "renter/config/routes";
import { ApplicationPaymentModel } from "renter/interfaces/api/applicationPayments";
import ErrorList from "shared/components/ErrorList";
import { Checkbox, Input, VeroFormField } from "shared/components/Form";
import { Error } from "shared/components/Form/styled";
import Icon from "shared/components/Icon";
import { UnderlineLink } from "shared/components/Links";
import { Modal } from "shared/components/Modal";
import Spacer from "shared/components/Spacer";
import { useDeviceType } from "shared/hooks";
import { useGlobalConfigItem } from "shared/hooks/useGlobalConfig";
import { UserProfile } from "shared/interfaces/api";
import { showError } from "shared/utils/forms";
import { deferred } from "shared/utils/misc.util";

import { printDollarsFromCents } from "shared/utils/dollar-print";
import {
  CardNumberHint,
  Container,
  FaintLabel,
  PaymentInProgress,
  StripeBox,
  Strong,
  getInputOptions,
} from "./styled";

const STRIPE_FIELD_VALID = "valid";
const STRIPE_FIELD_INVALID = "invalid";

const VALIDATION_ERRORS = Object.freeze({
  fullName: "Full Name is required",
  cardNumber: "Your card number is invalid.",
  cardCvc: "Your card's security code is invalid.",
  cardExpiry: "Your card's expiration date is invalid.",
  termsAgreed: "You need to agree to Stripe’s Terms of Service.",
});

const validationSchema = Yup.object().shape({
  fullName: Yup.string().required(VALIDATION_ERRORS.fullName),
  cardNumber: Yup.string()
    .oneOf([STRIPE_FIELD_VALID], VALIDATION_ERRORS.cardNumber)
    .required(VALIDATION_ERRORS.cardNumber),
  cardCvc: Yup.string()
    .oneOf([STRIPE_FIELD_VALID], VALIDATION_ERRORS.cardCvc)
    .required(VALIDATION_ERRORS.cardCvc),
  cardExpiry: Yup.string()
    .oneOf([STRIPE_FIELD_VALID], VALIDATION_ERRORS.cardExpiry)
    .required(VALIDATION_ERRORS.cardExpiry),
  termsAgreed: Yup.bool().oneOf([true], VALIDATION_ERRORS.termsAgreed),
});

interface PaymentFormValues {
  nonFieldErrors?: Array<string>;
  fullName: string;
  cardNumber: string;
  cardCvc: string;
  cardExpiry: string;
  termsAgreed: boolean;
}

interface ApplicantPaymentModalProps {
  paymentDetails: ApplicationPaymentModel;
  submitPayment: (
    values: PaymentFormValues,
    formikBag: FormikHelpers<PaymentFormValues>
  ) => void;
  applicantProfile: UserProfile;
  isWaitingForPayment: boolean;
}

const ApplicantPaymentModal = ({
  paymentDetails,
  submitPayment,
  applicantProfile,
  isWaitingForPayment,
}: ApplicantPaymentModalProps) => {
  const initialValues: PaymentFormValues = {
    fullName: "",
    cardNumber: "",
    cardCvc: "",
    cardExpiry: "",
    termsAgreed: false,
  };

  const history = useHistory();
  const { isMobile } = useDeviceType();
  const amount = printDollarsFromCents(paymentDetails?.amount);

  const applicantPaymentModalConfig = useGlobalConfigItem(
    RENTER_GLOBAL_CONFIG_KEYS.applicantPaymentModalConfig
  );
  const showBackToApplications =
    !applicantPaymentModalConfig?.hideBackToApplicationLink &&
    applicantProfile?.hasMultipleOngoingApplications;

  const FeeLines = paymentDetails?.lineItems?.map((item) => {
    const label =
      (applicantPaymentModalConfig?.getFeeTypeLabel
        ? applicantPaymentModalConfig?.getFeeTypeLabel(item.type)
        : item.name || item.feeName) || "Unknown fee";
    const value = printDollarsFromCents(item.amount);

    return (
      <div key={label}>
        {label}:&nbsp;<Strong>{value}.</Strong>
      </div>
    );
  });

  const PaymentAmountDescription = (
    <>
      {FeeLines}
      <Spacer />
      Your card will be charged a total amount of <Strong>{amount}.</Strong>
      <br />
      Please pay to continue.
    </>
  );

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={submitPayment}
      validateOnChange
    >
      {({
        handleSubmit,
        touched,
        errors,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
      }) => {
        const handleStripeChange = (event) => {
          if (event.complete) {
            setFieldValue(event.elementType, STRIPE_FIELD_VALID);
          } else if (event.error) {
            setFieldValue(event.elementType, STRIPE_FIELD_INVALID);
          } else if (event.empty) {
            setFieldValue(event.elementType, "");
          }

          deferred(() => setFieldTouched(event.elementType, true));
        };

        return (
          <Form>
            {/* @ts-ignore */}
            <Modal
              title="Almost there!"
              submit={handleSubmit}
              closeOnSubmit={false}
              submitButtonLabel="Pay & Continue"
              footer={
                showBackToApplications && (
                  /* @ts-ignore */
                  <UnderlineLink
                    onClick={() => {
                      history.push(ROUTES.applications);
                    }}
                    data-testid="close-link"
                  >
                    Back to Applications
                  </UnderlineLink>
                )
              }
              submitButtonFullWidth={isMobile}
              submitting={isSubmitting}
              showSubmitButton={!isWaitingForPayment}
              disableCancelLink={isSubmitting}
              showCancelLink={!isWaitingForPayment}
            >
              {errors.nonFieldErrors && (
                <Modal.Body noPaddingBottom>
                  <ErrorList errors={errors.nonFieldErrors} />
                </Modal.Body>
              )}
              <Modal.Body noPaddingTop={!isEmpty(errors.nonFieldErrors)}>
                {PaymentAmountDescription}
                {isWaitingForPayment ? (
                  <PaymentInProgress>
                    <Strong>Your payment is being processed...</Strong>
                  </PaymentInProgress>
                ) : (
                  <>
                    <CardNumberHint>
                      <Strong>Enter your debit or credit card details</Strong>
                      <StripeBox>
                        <FaintLabel>Powered by</FaintLabel> <Icon.StripeIcon />
                      </StripeBox>
                    </CardNumberHint>
                    <Container>
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <CardNumberElement
                            id="cardNumber"
                            options={{
                              style: getInputOptions(),
                              classes: {
                                base: showError("cardNumber", touched, errors)
                                  ? "StripeElement invalid"
                                  : "StripeElement",
                              },
                            }}
                            onChange={handleStripeChange}
                          />
                          {showError("cardNumber", touched, errors) && (
                            <Error>{errors.cardNumber}</Error>
                          )}
                        </Col>
                      </Row>
                      <Row gutter={[16, 16]}>
                        <Col span={12}>
                          <CardExpiryElement
                            id="cardExpiry"
                            options={{
                              style: getInputOptions(),
                              classes: {
                                base: showError("cardExpiry", touched, errors)
                                  ? "StripeElement invalid"
                                  : "StripeElement",
                              },
                            }}
                            onChange={handleStripeChange}
                          />
                          {showError("cardExpiry", touched, errors) && (
                            <Error>{errors.cardExpiry}</Error>
                          )}
                        </Col>
                        <Col span={12}>
                          <CardCvcElement
                            id="cardCvc"
                            options={{
                              style: getInputOptions(),
                              classes: {
                                base: showError("cardCvc", touched, errors)
                                  ? "StripeElement invalid"
                                  : "StripeElement",
                              },
                            }}
                            onChange={handleStripeChange}
                          />
                          {showError("cardCvc", touched, errors) && (
                            <Error>{errors.cardCvc}</Error>
                          )}
                        </Col>
                      </Row>
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          {/* @ts-ignore */}
                          <VeroFormField
                            as={Input}
                            id="fullName"
                            name="fullName"
                            placeholder="Full Name"
                          />
                        </Col>
                      </Row>
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          {/* @ts-ignore */}
                          <VeroFormField
                            id="termsAgreed"
                            label={
                              <>
                                <span>I agree to&nbsp;</span>
                                {/* @ts-ignore */}
                                <UnderlineLink
                                  target="_blank"
                                  rel="noreferrer"
                                  href="https://stripe.com/checkout/legal"
                                >
                                  Stripe’s Terms of Service
                                </UnderlineLink>
                              </>
                            }
                            as={Checkbox}
                            name="termsAgreed"
                          />
                        </Col>
                      </Row>
                    </Container>
                  </>
                )}
              </Modal.Body>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ApplicantPaymentModal;
