import isNumber from "lodash/isNumber";
import * as Yup from "yup";

import { VALIDATION_MESSAGES } from "shared/config/constants";
import { initialValues as applicantsInitialValues } from "shared/forms/InviteApplicantsForm";
import {
  validEmail,
  validFirstName,
  validLastName,
} from "shared/forms/validation/fieldValidators";

export interface Applicant {
  id?: number;
  firstName: string;
  lastName: string;
  email: string;
}

export interface Lead extends Applicant {}

export const emptyLead: Applicant = {
  id: undefined,
  firstName: "",
  lastName: "",
  email: "",
};

export interface InviteApplicantsValues {
  applicants: Applicant[];
  leads: Lead[];
  nonFieldErrors?: string[];
}

export const initialValues: InviteApplicantsValues = {
  ...applicantsInitialValues,
  leads: [emptyLead],
};

export const validationSchema = Yup.object().shape({
  applicants: Yup.array()
    .when("leads", {
      is: (leads: Lead[]) => leads?.length && isNumber(leads[0].id),
      then: Yup.array().of(
        Yup.object().shape({
          email: validEmail,
          firstName: validFirstName,
          lastName: validLastName,
        })
      ),
      otherwise: Yup.array()
        .of(
          Yup.object().shape({
            email: validEmail.required(VALIDATION_MESSAGES.fieldRequired),
            firstName: validFirstName.required(
              VALIDATION_MESSAGES.fieldRequired
            ),
            lastName: validLastName.required(VALIDATION_MESSAGES.fieldRequired),
          })
        )
        .required("At least one invite is required"),
    })
    // @ts-ignore
    .uniqueBy(
      ({ email }) => email?.toLowerCase(),
      "Each invite must be sent to a unique email. Please review and edit the email addresses."
    ),
  leads: Yup.lazy((leads) =>
    Yup.array().of(
      Yup.object().shape({
        id:
          leads?.length === 1
            ? Yup.number()
            : Yup.number().required(VALIDATION_MESSAGES.fieldRequired),
        label: Yup.string(),
      })
    )
  ),
});
