import React from "react";

import { useUnassignEmployeePortfoliosProperties } from "manager/hooks/api";
import { NOTIFICATIONS } from "shared/config/constants";
import { openNotification } from "shared/utils/ui";

import UnassignEmployeeFromPortfolioModal from "./UnassignEmployeeFromPortfolioModal";

interface UnassignEmployeeFromPortfolioModalContainerProps {
  values: {
    properties: (string | number)[];
    portfolios: (string | number)[];
  };
  employeeName: string;
  employeeId: string | number;
  portfolioName: string;
}

const UnassignEmployeeFromPortfolioModalContainer = ({
  values,
  employeeName,
  employeeId,
  portfolioName,
}: UnassignEmployeeFromPortfolioModalContainerProps) => {
  const {
    isUnassignEmployeePortfoliosPropertiesLoading,
    unassignEmployeePortfoliosProperties,
  } = useUnassignEmployeePortfoliosProperties({
    employeeId,
    values,
  });

  const onSubmit = () =>
    unassignEmployeePortfoliosProperties(null, {
      onSuccess: () =>
        openNotification(
          `${employeeName} has been successfully unassigned from ${portfolioName} portfolio`,
          NOTIFICATIONS.info
        ),
      onError: () =>
        openNotification(
          `Failed to unassign ${employeeName} from ${portfolioName} portfolio`,
          NOTIFICATIONS.error
        ),
    });

  return (
    <UnassignEmployeeFromPortfolioModal
      employeeName={employeeName}
      portfolioName={portfolioName}
      onSubmit={onSubmit}
      submitting={isUnassignEmployeePortfoliosPropertiesLoading}
    />
  );
};

export default UnassignEmployeeFromPortfolioModalContainer;
