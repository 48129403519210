import styled from "@emotion/styled";

import { COLORS } from "shared/config/constants";

export const Badge = styled.span(() => ({
  border: "1px solid #787395",
  padding: "1px 4px",
  fontSize: "10px",
  fontWeight: 400,
  color: "black",
  textTransform: "uppercase",
}));

export const ApplicantInfoWrapper = styled.div`
  svg {
    cursor: pointer;
  }

  .applicant-name-title {
    text-decoration: underline;
    margin-bottom: 6px;
    cursor: pointer;
    width: 250px;
  }

  .yardi-tag {
    width: 60px;
    display: flex;
    align-self: center;
    margin-right: 0px;
    margin-left: 24px;
    padding-left: 0px !important;
    padding-right: 4px !important;
    border-radius: 4px;
  }

  .action-link {
    text-decoration: underline;
    cursor: pointer;
    user-select: none;
  }

  .payment-well {
    padding-top: 8px;
    padding-left: 12px;
    padding-bottom: 1px;
    width: 140px;

    .title {
      display: block;
    }

    .detail {
      display: block;
      margin-bottom: 4px;
    }

    .value {
      margin-bottom: 8px;

      svg {
        height: 14px;
      }
    }
  }
`;
