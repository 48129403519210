import { useQuery } from "react-query";

import { NOTIFICATIONS } from "shared/config/constants";
import api from "shared/lib/api";
import { openNotification } from "shared/utils/ui";

export const useGetRoles = () => {
  const response = useQuery(
    ["getRoles"],
    () => api.listRoles().then(({ data }) => data),
    {
      onError: () =>
        openNotification("Failed to load roles", NOTIFICATIONS.error),
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
  return response;
};
