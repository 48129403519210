import { Key } from "react";

import axios from "axios";
import snakeCase from "lodash/snakeCase";

import { queryCache } from "react-query";

import { GET_DEAL_KEY } from "manager/hooks/api";

import ROUTES from "shared/config/apiRoutes";
import { transformRoute } from "shared/utils/routing";

import { API_URL } from "./setupApi";
import { notify } from "./utils";

const cancelDeclineApiCall = (
  route,
  id,
  attachments,
  extraParams,
  sendEmail?: string,
  emailBody?: string
) => {
  const formData = new FormData();
  if (sendEmail) {
    formData.set("send_email", sendEmail);
  }
  if (emailBody) {
    formData.set("email_body", emailBody);
  }
  Object.keys(extraParams || {}).forEach((extraParam) => {
    formData.set(snakeCase(extraParam), extraParams[extraParam]);
  });
  (attachments || []).forEach((att, idx) => {
    formData.set(`file${idx + 1}`, att);
  });
  return axios.post(`${API_URL}${transformRoute(route, { id })}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const approveApiCall = (route, id, attachments, extraParams) => {
  const formData = new FormData();
  Object.keys(extraParams || {}).forEach((extraParam) => {
    formData.set(snakeCase(extraParam), extraParams[extraParam]);
  });
  (attachments || []).forEach((att, idx) => {
    formData.set(`file${idx + 1}`, att);
  });
  return axios.post(`${API_URL}${transformRoute(route, { id })}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const inviteApplicants = async ({
  applicants,
  unitListing,
  unit,
  noListingUnit,
  leadSource,
  externalPMSId,
}) =>
  axios.post(`${API_URL}${ROUTES.inviteApplicant}`, {
    applicants,
    unitListing,
    unit,
    noListingUnit,
    leadSource,
    externalPMSId,
  });

export const applicantInviteApplicants = async ({
  applicants,
  otherOccupants,
  dealId,
}) =>
  axios.post(
    `${API_URL}${transformRoute(ROUTES.applicantInviteApplicants, {
      id: dealId,
    })}`,
    {
      applicants,
      otherOccupants,
    }
  );

export type RemoveApplicantFromDealProps = {
  applicationId: string | number;
  id: string;
  comment?: string;
  reason?: number;
};

export const removeApplicationFromDeal = async ({
  applicationId,
  id,
  comment,
  reason,
}: RemoveApplicantFromDealProps) =>
  notify(
    () =>
      axios.post(
        `${API_URL}${transformRoute(ROUTES.removeApplicationFromDeal, {
          id,
        })}`,
        {
          application: applicationId,
          comment,
          reason,
        }
      ),
    "Successfully removed applicant",
    "Failed to remove applicant"
  );

export const inviteApplicantsToDeal = async ({ applicants, id }) =>
  axios.post(`${API_URL}${ROUTES.inviteApplicantsToDeal}`, {
    applicants,
    deal: id,
  });

export const getApplicantDeal = async (id) =>
  axios.get(`${API_URL}${transformRoute(ROUTES.applicantDeal, { id })}`);

export const getDeal = async (id) =>
  axios.get(`${API_URL}${transformRoute(ROUTES.deal, { id })}`);

export const generateDealPDF = async (id) =>
  axios
    .post(
      `${API_URL}${transformRoute(ROUTES.generateDealPDF, {
        id,
      })}`
    )
    .then((response) => response.data);

export const cancelDeal = async ({
  id,
  sendEmail,
  emailBody,
  attachments,
  extraParams,
}) => {
  return notify(
    () =>
      cancelDeclineApiCall(
        ROUTES.cancelDeal,
        id,
        attachments,
        extraParams,
        sendEmail,
        emailBody
      ),
    "Successfully canceled",
    "Failed to cancel"
  );
};
export const approveDeal = async ({ id, attachments, extraParams }) => {
  return notify(
    async () => {
      const data = await approveApiCall(
        ROUTES.approveDeal,
        id,
        attachments,
        extraParams
      );
      queryCache.invalidateQueries(GET_DEAL_KEY);
      return data;
    },
    "Successfully approved",
    "Failed to approve"
  );
};

export const declineDeal = async ({ id, attachments, extraParams }) => {
  return notify(
    () =>
      cancelDeclineApiCall(ROUTES.declineDeal, id, attachments, extraParams),
    "Successfully declined",
    "Failed to decline"
  );
};
export const conditionallyApproveDeal = async ({ data, id }) => {
  return notify(
    () =>
      axios.post(
        `${API_URL}${transformRoute(ROUTES.conditionallyApproveDeal, {
          id,
        })}`,
        data
      ),
    "Successfully requested more info",
    "Failed to request more info"
  );
};
export const conditionallyProceed = async ({ id }) => {
  return axios.post(
    `${API_URL}${transformRoute(ROUTES.conditionallyProceed, { id })}`
  );
};

export const sendDealToPMS = async ({ id }) => {
  return notify(async () =>
    axios.post(
      `${API_URL}${transformRoute(ROUTES.sendToPms, {
        id,
      })}`
    )
  );
};

export const resendToPms = async ({ id }) => {
  return notify(async () =>
    axios.post(
      `${API_URL}${transformRoute(ROUTES.resendToPms, {
        id,
      })}`
    )
  );
};

export const getManager = ({ id }) =>
  axios
    .get(`${API_URL}${transformRoute(ROUTES.contactManger, { id })}`)
    .then(({ data }) => data);

export const contactManager = ({
  id,
  applications,
  otherOccupants,
  additionalMessage,
}) =>
  axios.post(`${API_URL}${transformRoute(ROUTES.contactManger, { id })}`, {
    applications,
    otherOccupants,
    additionalMessage,
  });

export const getDeals = ({
  dateMin,
  dateMax,
  propertyId,
  unitPropertyOrApplicant,
  dealStatus,
  leaseStatus,
  pageSize,
  page,
  ordering,
}) =>
  axios.get(`${API_URL}${ROUTES.deals}`, {
    params: {
      created_at__gte: dateMin,
      created_at__lte: dateMax,
      property_id__in: propertyId,
      unit_or_property_or_applicant: unitPropertyOrApplicant,
      deal_status: dealStatus,
      lease_status: leaseStatus,
      page_size: pageSize,
      page,
      ordering,
    },
  });
export const editDealRent = ({ dealId, rent }) =>
  axios.post(
    `${API_URL}${transformRoute(ROUTES.editDealRent, {
      id: dealId,
    })}`,
    { rent }
  );

export const assignDealToUnit = ({
  id,
  unitId,
  newLeaseStartDate,
  extraParams,
}) =>
  axios
    .post(`${API_URL}${transformRoute(ROUTES.assignDealToUnit, { id })}`, {
      unit: unitId,
      new_preferred_lease_start_date: newLeaseStartDate,
      ...extraParams,
    })
    .then(({ data }) => data);

export const getUnitListing = (id: Key) =>
  axios
    .get(`${API_URL}${transformRoute(ROUTES.dealUnitListing, { id })}`)
    .then(({ data }) => data);

export const getPrimaryApplicant = (id: Key) =>
  axios
    .get(`${API_URL}${transformRoute(ROUTES.dealApplications, { id })}`, {
      params: {
        position: "primary",
      },
    })
    .then(({ data }) => data);

export const updateDealRevenueDetails = ({ dealId, values }) =>
  axios.patch(
    `${API_URL}${transformRoute(ROUTES.updateDealRevenueDetails, {
      id: dealId,
    })}`,
    values
  );

export const assignDealRevenueManagement = ({ dealId, values }) =>
  axios.patch(
    `${API_URL}${transformRoute(ROUTES.assignDealRevenueManagement, {
      id: dealId,
    })}`,
    values
  );

export const getDealApplicants = (id: Key) =>
  axios
    .get(`${API_URL}${transformRoute(ROUTES.dealApplications, { id })}`)
    .then(({ data }) => data);
