import React from "react";

import { useFormikContext } from "formik";

import { LUMEN_FILTER_CONDITION_TYPE } from "manager/config/lumen.config";
import { Button } from "shared/components/Button";
import FlexContainer from "shared/components/FlexContainer";

import { getDefaultConditionTypeValues } from "../utils";

const LUMEN_CONDITIONS = Object.freeze({
  [LUMEN_FILTER_CONDITION_TYPE.TIME_PERIOD]: "TIME PERIOD",
  [LUMEN_FILTER_CONDITION_TYPE.AMOUNT]: "AMOUNT",
  [LUMEN_FILTER_CONDITION_TYPE.KEYWORDS]: "KEYWORDS",
});

interface AddConditionPanelProps {
  push: (object: any) => void;
  mode: string;
}

const AddConditionPanel = ({ push, mode }: AddConditionPanelProps) => {
  const { values } = useFormikContext();

  const keywordsConditions =
    // @ts-ignore
    values.conditions.filter(
      (condition) => condition.conditionType === LUMEN_CONDITIONS.KEYWORDS
    );

  const availableConditionTypes = (Object.keys(LUMEN_CONDITIONS) || []).filter(
    (type) => {
      if (type === LUMEN_CONDITIONS.KEYWORDS) {
        // NOTE: we are allowing one filter to have 2 keywords conditions
        return keywordsConditions.length < 2;
      }
      // @ts-ignore
      return !values.conditions.find(
        (condition) => condition.conditionType === type
      );
    }
  );

  return (
    <FlexContainer className="filter-buttons">
      {availableConditionTypes.map((conditionType) => {
        return (
          <Button
            className="filter-button"
            plus
            key={conditionType}
            onClick={() =>
              push({
                conditionType,
                ...getDefaultConditionTypeValues(
                  conditionType,
                  mode,
                  keywordsConditions
                ),
              })
            }
          >
            {LUMEN_CONDITIONS[conditionType]}
          </Button>
        );
      })}
    </FlexContainer>
  );
};

export default AddConditionPanel;
