import React from "react";

import {
  useGetYardiChargeTypes,
  useGetYardiCredentials,
  useUpdateYardiMappingConfiguration,
} from "manager/hooks/api";
import { useGetIntegrationProvider } from "manager/pages/Marketplace/config";
import { BackLinkProps } from "shared/interfaces/misc";

import { MAPPING_TYPES_COMPLETED_PROPS } from "../shared";

import SetupYardiChargeTypes from "./SetupYardiChargeTypes";

interface SetupYardiChargeTypesContainerProps {
  backLink: BackLinkProps;
}

const SetupYardiChargeTypesContainer = ({
  backLink,
}: SetupYardiChargeTypesContainerProps) => {
  const { provider } = useGetIntegrationProvider();
  const { yardiChargeTypes } = useGetYardiChargeTypes(provider);
  const { yardiCredentials } = useGetYardiCredentials(provider);
  const { updateYardiMappingConfiguration } =
    useUpdateYardiMappingConfiguration({
      provider,
      completeSaveMessage: "Charge types mapping successfully updated",
      incompleteSaveMessage:
        "Progress Saved. Charge types mapping is incomplete.",
      completedProp: MAPPING_TYPES_COMPLETED_PROPS.chargeTypes,
    });

  const initialValues = yardiCredentials
    ? {
        applicationFeeChargeType: yardiCredentials.applicationFeeChargeType,
        securityDepositChargeType: yardiCredentials.securityDepositChargeType,
        firstMonthRentChargeType: yardiCredentials.firstMonthRentChargeType,
      }
    : {};

  const onFormSubmit = (data, callbacks) => {
    const { setSubmitting, resetForm } = callbacks;
    setSubmitting(true);
    const onSettled = () => setSubmitting(false);
    const onSuccess = () => {
      resetForm(data);
    };
    updateYardiMappingConfiguration(data, { onSuccess, onSettled });
  };

  const onSave = (data) => {
    updateYardiMappingConfiguration(data);
  };

  const props = {
    backLink,
    yardiChargeTypes,
    initialValues,
    yardiCredentials,
    onFormSubmit,
    onSave,
  };

  return <SetupYardiChargeTypes {...props} />;
};

export default SetupYardiChargeTypesContainer;
