import styled from "@emotion/styled";

import { Button } from "shared/components/Button";
import { ActionLink } from "shared/components/Links";
import { COLORS } from "shared/config/constants";

export const UploadLogoButton = styled(Button)`
  margin: 20px 0;
`;

export const ChangeLogoButton = styled(ActionLink)`
  margin: 20px 0;
`;

export const UploadWrapper = styled.div`
  width: 100%;
  height: 360px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.background};
`;

export const Instructions = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LogoWrapper = styled.div`
  width: 240px;
  height: 240px;
  background-color: #ededef;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LogoPreview = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export const LogoDescription = styled.p`
  padding: 0 10px;
  text-align: center;
`;
