import React from "react";

import { RadioChangeEvent } from "antd/lib/radio";

import RequiredIndicator from "shared/components/RequiredIndicator";

import { Error } from "../styled";

import { Label, RadioGroup as StyledRadioGroup, Radio } from "./styled";

export type RadioGroupOptions = {
  value?: number | string | boolean;
  label?: React.ReactNode;
  disabled?: boolean;
};

export type RadioGroupProps<T> = {
  block?: boolean;
  className?: string;
  disabled?: boolean;
  error?: string | string[];
  id: string;
  label?: React.ReactNode;
  name?: string;
  onChange?: (e: RadioChangeEvent) => void;
  options: T[];
  value?: string | number | boolean;
  required?: boolean;
  radioOptionRenderer?: (option: T) => React.ReactNode;
};

export const RadioGroup = <T extends object = RadioGroupOptions>({
  id,
  label,
  required = false,
  onChange = () => {},
  options,
  name,
  block,
  disabled = false,
  className,
  error,
  value,
  radioOptionRenderer,
}: RadioGroupProps<T>) => {
  return (
    <div className={className}>
      {label && (
        <Label htmlFor={id} className="radio-label">
          {label}
          <RequiredIndicator required={required} />
        </Label>
      )}
      <StyledRadioGroup
        id={id}
        value={value}
        name={name}
        onChange={onChange}
        disabled={disabled}
      >
        {options.map((opt) => {
          if (radioOptionRenderer) {
            return radioOptionRenderer(opt);
          }
          const option = opt as RadioGroupOptions;
          return (
            <Radio
              data-testid={`${id}-option-${option.value}`}
              key={String(option.value)}
              value={option.value}
              block={block}
              disabled={option.disabled}
            >
              {option.label}
            </Radio>
          );
        })}
      </StyledRadioGroup>
      {error && !Array.isArray(error) && <Error>{error}</Error>}
      {error &&
        Array.isArray(error) &&
        error.map((e) => <Error key={e}>{e}</Error>)}
    </div>
  );
};
