import { DEAL_UI_STATUS_CODES } from "shared/config/constants";

export const LEAD_STATUS_ITEMS = [
  {
    label: "All Statuses",
    key: "",
  },
  {
    label: "Invite Sent",
    key: DEAL_UI_STATUS_CODES.invitesSent,
  },
  {
    label: "In Progress",
    key: DEAL_UI_STATUS_CODES.applicationsIncomplete,
  },
  {
    label: "Completed",
    key: DEAL_UI_STATUS_CODES.submitted,
  },
];
