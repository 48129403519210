import React from "react";

import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import { RegularText } from "shared/components/Typography";

import { StyledWell } from "./styled";

const MobileWarningNotice = () => (
  <StyledWell>
    <FlexContainer alignItems="center">
      <FlexContainer className="icon-wrapper" alignItems="center">
        <Icon.OrangeExclamation />
      </FlexContainer>
      <RegularText strong>
        We recommend using the Custom Email Builder on a desktop for the best
        user experience.
      </RegularText>
    </FlexContainer>
  </StyledWell>
);

export default MobileWarningNotice;
