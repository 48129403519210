import React from "react";

import dayjs from "dayjs";
import isEmpty from "lodash/isEmpty";

import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import { SmallText } from "shared/components/Typography";
import { DATE_TIME_FORMAT, IntegrationProvider } from "shared/config/constants";

import {
  StatusContainer,
  PrimaryActionButton,
  LinkAction,
  LogoSmall,
  PopoverContainer,
  SyncTitle,
} from "./styled";
import { IntegrationLogo, IntegrationStatus } from "./types";

const SyncStatusPopover = ({
  isSuspended,
  onRefresh,
  isRefreshing,
  onViewDetails,
  beginIntegrationSetup,
  onFinishSetup,
  lastSync,
  healthCheckStatus = IntegrationStatus.NOT_CONNECTED,
  healthCheckMessage,
  provider,
}) => {
  const isDisconnected = healthCheckStatus === IntegrationStatus.NOT_CONNECTED;
  const hideRefreshContainer = [IntegrationStatus.SUSPENDED].includes(
    healthCheckStatus
  );

  const renderActions = () => {
    switch (healthCheckStatus) {
      case IntegrationStatus.NOT_CONNECTED:
        return (
          <LinkAction onClick={beginIntegrationSetup}>
            Begin Integration Setup
          </LinkAction>
        );

      case IntegrationStatus.CONNECTED:
      case IntegrationStatus.IN_PROGRESS:
      case IntegrationStatus.NEEDS_UPDATE:
      case IntegrationStatus.IN_ERROR:
        return provider === IntegrationProvider.yardi ? (
          <LinkAction onClick={onViewDetails}>View Details</LinkAction>
        ) : (
          <></>
        );
      case IntegrationStatus.SETUP_INCOMPLETE:
        return <LinkAction onClick={onFinishSetup}>Finish Setup</LinkAction>;
      case IntegrationStatus.SUSPENDED:
        return <></>;
      default:
        return provider === IntegrationProvider.yardi ? (
          <LinkAction onClick={onViewDetails}>View Details</LinkAction>
        ) : (
          <></>
        );
    }
  };

  return (
    <StatusContainer flexDirection="column">
      <PopoverContainer
        className="title-container"
        justifyContent="space-between"
        alignItems="center"
      >
        {!isDisconnected && (
          <LogoSmall
            src={IntegrationLogo[provider]}
            data-testid="integration-status-logo"
          />
        )}
        {renderActions()}
      </PopoverContainer>
      {!isDisconnected && (
        <>
          {!hideRefreshContainer && (
            <>
              <PopoverContainer
                justifyContent="space-between"
                alignItems="center"
                className="show-top-border"
              >
                <FlexContainer flexDirection="column">
                  <SmallText extraStrong>Last sync:</SmallText>
                  <SmallText>
                    {lastSync ? dayjs(lastSync).format(DATE_TIME_FORMAT) : "-"}
                  </SmallText>
                </FlexContainer>
                {!isSuspended && (
                  <FlexContainer flexDirection="row" alignItems="center">
                    <PrimaryActionButton
                      type="link"
                      PrefixIcon={Icon.RefreshIcon}
                      onClick={onRefresh}
                      refreshing={isRefreshing}
                      disabled={isRefreshing}
                    >
                      {isRefreshing ? "REFRESHING" : "REFRESH"}
                    </PrimaryActionButton>
                  </FlexContainer>
                )}
              </PopoverContainer>
            </>
          )}
          {!isEmpty(healthCheckMessage) && (
            <PopoverContainer
              className="show-top-border"
              justifyContent="space-between"
              alignItems="center"
            >
              <FlexContainer flexDirection="column">
                <FlexContainer alignItems="center">
                  <healthCheckMessage.integrationIcon
                    height={14.33}
                    width={14.33}
                  />
                  <SyncTitle extraStrong>{healthCheckMessage?.title}</SyncTitle>
                </FlexContainer>
                <SmallText>
                  {healthCheckMessage?.descriptionComponent
                    ? healthCheckMessage.descriptionComponent(provider)
                    : healthCheckMessage.description}
                </SmallText>
              </FlexContainer>
            </PopoverContainer>
          )}
        </>
      )}
    </StatusContainer>
  );
};

export default SyncStatusPopover;
