import React from "react";

import {
  useSaveApplicationCharges,
  useGetApplicationCharges,
} from "manager/hooks/api";
import { FeeInventoryLevel } from "manager/interfaces/api";
import useModal from "shared/hooks/useModal";

import HoldingDepositPaymentPointInfoModal from "./HoldingDepositPaymentPointInfoModal";
import HoldingDepositSettings, {
  HoldingDepositFormValues,
} from "./HoldingDepositSettings";

interface HoldingDepositSettingsContainerProps {
  id: number;
  level: FeeInventoryLevel;
  showUnitTypeHoldingDepositMapping?: boolean;
}

const HoldingDepositSettingsContainer = ({
  id,
  level,
  showUnitTypeHoldingDepositMapping,
}: HoldingDepositSettingsContainerProps) => {
  const { openModalDialog } = useModal();
  const { applicationCharges, isApplicationChargesLoading } =
    useGetApplicationCharges({
      id,
      level,
    });

  const { saveApplicationCharges } = useSaveApplicationCharges();

  const saveChanges = async (values: HoldingDepositFormValues) => {
    await saveApplicationCharges({ id, level, payload: values });
  };

  const openPaymentPointInfo = () => {
    openModalDialog(HoldingDepositPaymentPointInfoModal);
  };

  return (
    <HoldingDepositSettings
      applicationCharges={applicationCharges}
      isLoading={isApplicationChargesLoading}
      saveChanges={saveChanges}
      objectId={id}
      showUnitTypeHoldingDepositMapping={showUnitTypeHoldingDepositMapping}
      openPaymentPointInfo={openPaymentPointInfo}
    />
  );
};

export default HoldingDepositSettingsContainer;
