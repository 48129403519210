import styled from "@emotion/styled";

import { COLORS } from "shared/config/constants";

interface ContainerProps {
  "aria-valuemax"?: any;
  "aria-valuemin"?: number;
  "aria-valuenow"?: number;
}

const Container = styled("div")<ContainerProps>`
  height: 2px;
  width: 100%;
  background-color: ${COLORS.progressBar.background};
  display: flex;
`;

const Progress = styled("div")<{ progress: number }>`
  width: ${(props) => props.progress}%;
  background-color: ${COLORS.progressBar.progress};
`;

export { Container, Progress };
