import React from "react";

import { SubmitResultContainer } from "renter/components/MagicLink/StepConfirmation/styled";
import Icon from "shared/components/Icon";

import { DealType } from "shared/config/constants";

export type StepErrorProps = {
  dealType?: DealType;
};
const StepError = ({ dealType = DealType.SCREENING }: StepErrorProps) => {
  const dealTypeLabel =
    dealType === DealType.SCREENING ? "application" : "prequalification";
  const errorMessage = "Oops!";
  const notificationMessage = `We weren't able to start your ${dealTypeLabel}. Please reach out to the leasing agent.`;
  return (
    <SubmitResultContainer data-testid="step-error">
      <Icon.OrangeExclamationHollow data-testid="icon-exclamation" />
      <p className="error-message">{errorMessage}</p>
      <p data-testid="notification-message">{notificationMessage}</p>
    </SubmitResultContainer>
  );
};

export default StepError;
