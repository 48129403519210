import * as Yup from "yup";

export const CREATE_UNIT_FIELDS = Object.freeze({
  name: "name",
  unitType: "unitType",
  bedrooms: "bedrooms",
  bathrooms: "bathrooms",
  squareFeet: "squareFeet",
});

export const CreateUnitValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required").max(100),
  unitType: Yup.string().nullable().max(255),
  bedrooms: Yup.number()
    .integer("Please enter a whole number for the number of bedrooms.")
    .min(0)
    .nullable(),
  bathrooms: Yup.number()
    .min(0)
    .nullable()
    .test(
      "bathrooms",
      "For half bathrooms, please enter a number ending in .5. Otherwise, remove any decimals for the number of full bathrooms.",
      (number) => (number > 0 ? number % 0.5 === 0 : true)
    ),
  squareFeet: Yup.number().min(0).nullable(),
});
