import React from "react";

import { LumenDescriptionModal } from "manager/components/Lumen";
import FlexContainer from "shared/components/FlexContainer";
import useModal from "shared/hooks/useModal";

import { InfoIcon, LumenHowItWorks } from "./styled";

interface ILumenHowItWorksAction {
  hideText?: boolean;
}

const LumenHowItWorksAction = ({ hideText }: ILumenHowItWorksAction) => {
  const { openModalDialog } = useModal();

  const showDescription = () => openModalDialog(LumenDescriptionModal);

  return (
    <FlexContainer>
      <InfoIcon onClick={showDescription} />
      {!hideText && (
        <LumenHowItWorks onClick={showDescription}>
          HOW IT WORKS
        </LumenHowItWorks>
      )}
    </FlexContainer>
  );
};
export default LumenHowItWorksAction;
