import { AxiosResponse } from "axios";

import { sleep } from "shared/utils/async";
import { notifyError, notifySuccess } from "shared/utils/ui";

export const notify = async <T = unknown>(
  request: () => Promise<AxiosResponse<T>>,
  success = null,
  error = null
) => {
  try {
    const data = await request();
    if (success) notifySuccess(success);
    return data;
  } catch (err) {
    // eslint-disable-next-line no-ex-assign
    err = err || {};
    const message: string = err.message || err.detail;
    if (err.statusCode === 403)
      notifyError(
        "You are not allowed to perform this action. Please contact your administrator."
      );
    else if (message?.match(/Lumen is not enabled/i)) {
      // do not notify error
    } else if (error) notifyError(error);
    throw err;
  }
};

export const notifyUnwrap = async <T = unknown>(
  request: () => Promise<AxiosResponse<T>>,
  success = undefined,
  error = undefined
) => notify(request, success, error).then(({ data }) => data);

export const retry = async <T = unknown>(
  request: () => Promise<AxiosResponse<T>>,
  retries = 3,
  delay = 1000,
  backoff = 1.5
  // eslint-disable-next-line consistent-return
) => {
  for (let i = 0, wait = delay; i <= retries; ++i, wait *= backoff)
    try {
      // eslint-disable-next-line no-await-in-loop
      return await request();
    } catch (err) {
      if (i === retries) throw err;
      // eslint-disable-next-line no-await-in-loop
      await sleep(wait);
    }
};
