import React, { useState, useEffect } from "react";

import { Select as BaseSelect } from "antd";
import { SelectProps as BaseSelectProps, SelectValue } from "antd/lib/select";

import RequiredIndicator from "shared/components/RequiredIndicator";
import { DropdownOverlay } from "shared/styles/commonStyles";

import { Container, Label, Error, OptionalText } from "../styled";

import {
  Select as StyledSelect,
  DropdownMenu,
  dropdownStyles,
  dropdownStylesViolet,
} from "./styled";

export type SelectProps = BaseSelectProps<SelectValue> & {
  id?: string;
  name?: string;
  disabled?: boolean;
  label?: string;
  error?: string | string[];
  required?: boolean;
  optional?: boolean;
  violetTheme?: boolean;
  customDropdownFooter?: React.ReactNode;
  optionFilterProp?: string;
  tokenSeparators?: string[];
};

const _Select: React.FC<SelectProps> = ({
  label,
  id,
  name,
  disabled,
  onChange,
  error,
  required,
  optional,
  value: baseValue,
  defaultValue: baseDefaultValue,
  violetTheme,
  customDropdownFooter,
  loading,
  optionFilterProp = "children",
  onBlur,
  ...props
}) => {
  const [value, setValue] = useState<SelectValue>(
    baseValue || baseDefaultValue
  );
  const [searchingValue, setSearchingValue] = useState<string | undefined>();

  useEffect(() => {
    setValue(baseValue);
  }, [baseValue]);

  return (
    <>
      <Container>
        {label && (
          <Label
            htmlFor={id}
            value={Boolean(value || searchingValue)}
            disabled={disabled}
            violetTheme={violetTheme}
          >
            {label}
            <RequiredIndicator required={required} />
          </Label>
        )}
        <StyledSelect
          id={id}
          name={name}
          data-testid="select"
          showSearch
          filterOption
          optionFilterProp={optionFilterProp}
          dropdownRender={(menu) => (
            <>
              <DropdownOverlay />
              <DropdownMenu violetTheme={violetTheme}>
                {menu}
                {customDropdownFooter}
              </DropdownMenu>
            </>
          )}
          dropdownStyle={violetTheme ? dropdownStylesViolet : dropdownStyles}
          defaultValue={baseDefaultValue}
          value={value}
          onChange={(newValue) => {
            setSearchingValue(undefined);
            if (onChange) {
              onChange(newValue, undefined);
            }
          }}
          onSearch={setSearchingValue}
          disabled={disabled}
          error={!!error}
          violetTheme={violetTheme}
          loading={loading}
          onBlur={onBlur}
          {...props}
        />
      </Container>
      {error && !Array.isArray(error) && (
        <Error className="error-message">{error}</Error>
      )}
      {error &&
        Array.isArray(error) &&
        error.map((e) => <Error key={e}>{e}</Error>)}
      {optional && <OptionalText>Optional</OptionalText>}
    </>
  );
};

export const Select = Object.assign(_Select, {
  Option: BaseSelect.Option,
  OptGroup: BaseSelect.OptGroup,
});
