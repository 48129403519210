/* eslint-disable react/prop-types */
import React, { useMemo } from "react";

import { useFormikContext } from "formik";
import get from "lodash/get";
import groupBy from "lodash/groupBy";
import PropTypes from "prop-types";

import {
  LUMEN_FILTER_MODE,
  LUMEN_SECTION_TYPE,
  NEW_LUMEN_FILTERS,
} from "manager/config/lumen.config";
import FlexContainer from "shared/components/FlexContainer";
import { SwitchTitle } from "shared/components/Form/SwitchTitle";
import { ActionLink } from "shared/components/Links";
import Spacer from "shared/components/Spacer";
import { SmallErrorText, SmallText } from "shared/components/Typography";
import useModal from "shared/hooks/useModal";

import { NewCollectionsFilterModal } from "../NewCollectionsFilterModal";
import NewFilterModal from "../NewFilterModal";
import { ConfigurationSection } from "../styled";

import LumenFilterRow from "./LumenFilterRow";

const LumenFilterRows = ({
  filters = [],
  editFilter,
  removeFilter,
  lumenSection,
}) => {
  return (
    <>
      {filters.map((filter, idx) => (
        <div key={filter.id || filter.uiid}>
          <SmallText extraStrong>FILTER #{idx + 1}</SmallText>
          <LumenFilterRow
            type={filter.type}
            conditions={filter.conditions}
            lumenSection={lumenSection}
            onEdit={() => editFilter(filter)}
            onDelete={() => removeFilter(filter)}
          />
        </div>
      ))}
    </>
  );
};

const LumenFilters = ({
  formulaName,
  lumenSection,
  filtersProp,
  crucialFiltersText,
  ignoredFiltersText,
  safetyNetText,
  safetyNetProp,
}) => {
  const { values, errors, setFieldValue } = useFormikContext();
  const { openModalDialog } = useModal();

  const filters = get(values, filtersProp) || [];

  const filtersError = get(errors, filtersProp);

  const { crucialFilters, ignoredFilters } = useMemo(() => {
    const split = groupBy(filters, (f) => f.mode);
    return {
      crucialFilters: split[LUMEN_FILTER_MODE.CRUCIAL] || [],
      ignoredFilters: split[LUMEN_FILTER_MODE.IGNORED] || [],
    };
  }, [filters]);

  const openNewFilterModal = ({ mode, data, callback }) => {
    openModalDialog(
      !Object.hasOwn(NEW_LUMEN_FILTERS, lumenSection)
        ? NewFilterModal
        : NewCollectionsFilterModal,
      {
        formValues: values,
        lumenSection,
        mode,
        data,
        formulaName,
        subtitle:
          mode === LUMEN_FILTER_MODE.CRUCIAL
            ? crucialFiltersText
            : ignoredFiltersText,
      }
    ).afterClose.then(callback);
  };

  const createNewFilter = (mode) => {
    const callback = (result) => {
      if (result) {
        const newFilters = [...filters, result];
        setFieldValue(filtersProp, newFilters);
      }
    };

    openNewFilterModal({ mode, callback });
  };

  const editFilter = (filter) => {
    const callback = (result) => {
      if (result) {
        const newFilters = filters.map((f) => (f === filter ? result : f));
        setFieldValue(filtersProp, newFilters);
      }
    };

    openNewFilterModal({ mode: filter.mode, data: filter, callback });
  };

  const removeFilter = (filter) => {
    const newFilters = filters.filter((f) => f !== filter);
    setFieldValue(filtersProp, newFilters);
  };

  return (
    <>
      <ConfigurationSection>
        <div className="configuration-section-header">Crucial</div>
        <div className="configuration-section-body">
          <FlexContainer flexDirection="column" fullWidth>
            {crucialFiltersText}
            <Spacer />
            <LumenFilterRows
              filters={crucialFilters}
              lumenSection={lumenSection}
              editFilter={editFilter}
              removeFilter={removeFilter}
            />
            <ActionLink
              plus
              onClick={() => createNewFilter(LUMEN_FILTER_MODE.CRUCIAL)}
            >
              NEW FILTER
            </ActionLink>
            {filtersError && <SmallErrorText>{filtersError}</SmallErrorText>}
          </FlexContainer>
        </div>
      </ConfigurationSection>

      <ConfigurationSection>
        <div className="configuration-section-header">Ignored</div>
        <div className="configuration-section-body">
          <FlexContainer flexDirection="column" fullWidth>
            {ignoredFiltersText}
            <Spacer />
            <LumenFilterRows
              filters={ignoredFilters}
              editFilter={editFilter}
              removeFilter={removeFilter}
              lumenSection={lumenSection}
            />
            <ActionLink
              plus
              onClick={() => createNewFilter(LUMEN_FILTER_MODE.IGNORED)}
            >
              NEW FILTER
            </ActionLink>
          </FlexContainer>
        </div>
      </ConfigurationSection>

      <ConfigurationSection>
        <div className="configuration-section-header">
          Safety net&nbsp;
          <SwitchTitle
            noPadding
            value={get(values, safetyNetProp)}
            onChange={(value) => setFieldValue(safetyNetProp, value)}
          />
        </div>
        <div className="configuration-section-body">{safetyNetText}</div>
      </ConfigurationSection>
    </>
  );
};

LumenFilters.propTypes = {
  formulaName: PropTypes.string,
  lumenSection: PropTypes.oneOf(Object.values(LUMEN_SECTION_TYPE)).isRequired,
  filtersProp: PropTypes.string.isRequired,
  crucialFiltersText: PropTypes.string.isRequired,
  ignoredFiltersText: PropTypes.string.isRequired,
  safetyNetText: PropTypes.string.isRequired,
  safetyNetProp: PropTypes.string.isRequired,
};

export default LumenFilters;
