import Cookies from "js-cookie";
import * as Yup from "yup";

import { validEmail } from "shared/forms/validation/fieldValidators";

export const LAST_LOGIN_EMAIL = "LAST_LOGIN_EMAIL";

export const initialValues = {
  email: Cookies.get(LAST_LOGIN_EMAIL) || "",
  password: "",
};

export const ValidationSchema = Yup.object().shape({
  email: validEmail.required("Email is required."),
  password: Yup.string().required("Password is required."),
});
