
import { Col, Row } from "antd";
import _ from "lodash";
import PropTypes from "prop-types";
import { printDollarsFromCents } from "shared/utils/dollar-print";

import { incomeAssetsSummaryPropType } from "manager/utils/propTypes";
import Spacer from "shared/components/Spacer";
import Tile from "shared/components/Tile";
import { MediumTitle } from "shared/components/Typography";
import StatedEmployers from "shared/pages/ApplicationSummary/components/StatedEmployers";

export const IncomeSummary = ({ income, summary, statedEmployments }) => {
  const statedEmployers = _.map(statedEmployments, "employer");

  return (
    <Row>
      <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
        <Spacer hideGteLg />
        <Tile.Label>Annual Income Before Taxes</Tile.Label>
        <MediumTitle strong data-testid="income-before-taxes">
          {printDollarsFromCents(income)}
        </MediumTitle>
      </Col>
      <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
        <Spacer hideGteLg />
        <Tile.Label>Average Monthly Income</Tile.Label>
        <MediumTitle strong data-testid="average-monthly-income">
          {printDollarsFromCents(income ? income / 12 : null)}
        </MediumTitle>
      </Col>
      <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
        <Spacer hideGteLg />
        <Tile.Label>Active Income Streams</Tile.Label>
        <MediumTitle strong data-testid="active-income-streams">
          {summary?.numberOfActiveIncomeStreams == null
            ? "N/A"
            : summary?.numberOfActiveIncomeStreams}
        </MediumTitle>
      </Col>
      <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
        <Spacer hideGteLg />
        <Tile.Label>Stated Employers</Tile.Label>
        <StatedEmployers
          employers={statedEmployers}
          data-testid="income-employer"
        />
      </Col>
    </Row>
  );
};

IncomeSummary.propTypes = {
  summary: incomeAssetsSummaryPropType.isRequired,
  income: PropTypes.number.isRequired,
  statedEmployments: PropTypes.any,
};
