import { notification } from "antd";
import copyToClipboard from "copy-to-clipboard";
import { CountryCode, parsePhoneNumberFromString } from "libphonenumber-js";
import findIndex from "lodash/findIndex";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import isObject from "lodash/isObject";
import isPlainObject from "lodash/isPlainObject";
import isString from "lodash/isString";
import kebabCase from "lodash/kebabCase";
import moment from "moment";
import pluralize from "pluralize";

import ErrorList from "shared/components/ErrorList";
import FlexContainer from "shared/components/FlexContainer";
import Notice from "shared/components/Notice";
import {
  DATE_FORMAT,
  BREAKPOINT_RESOLUTIONS,
  BREAKPOINT_TYPES,
  COLORS,
  NOTIFICATIONS,
  DATE_TIME_FORMAT,
} from "shared/config/constants";

export const getBreakpoint = (resolution) => {
  let breakpoint;

  if (resolution < BREAKPOINT_RESOLUTIONS.xs) {
    breakpoint = BREAKPOINT_TYPES.xs;
  } else if (
    resolution >= BREAKPOINT_RESOLUTIONS.sm &&
    resolution < BREAKPOINT_RESOLUTIONS.md
  ) {
    breakpoint = BREAKPOINT_TYPES.sm;
  } else if (
    resolution >= BREAKPOINT_RESOLUTIONS.md &&
    resolution < BREAKPOINT_RESOLUTIONS.lg
  ) {
    breakpoint = BREAKPOINT_TYPES.md;
  } else if (
    resolution >= BREAKPOINT_RESOLUTIONS.lg &&
    resolution < BREAKPOINT_RESOLUTIONS.xl
  ) {
    breakpoint = BREAKPOINT_TYPES.lg;
  } else if (
    resolution >= BREAKPOINT_RESOLUTIONS.xl &&
    resolution < BREAKPOINT_RESOLUTIONS.xxl
  ) {
    breakpoint = BREAKPOINT_TYPES.xl;
  } else if (
    resolution >= BREAKPOINT_RESOLUTIONS.xxl &&
    resolution < BREAKPOINT_RESOLUTIONS.xxxl
  ) {
    breakpoint = BREAKPOINT_TYPES.xxl;
  } else {
    breakpoint = BREAKPOINT_TYPES.xxxl;
  }

  return breakpoint;
};

export const getResolution = (breakpoint) => BREAKPOINT_RESOLUTIONS[breakpoint];

/** Formats given number to string with commas
 *  Example: 1000000 -> 1,000,000
 */
export const numberWithCommas = (num) => {
  const parts = num?.toString().split(".") || [];
  parts[0] = parts[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

export const numberWithDecimals = (number, decimals) =>
  parseFloat(String(Math.round(Number(number) * 100) / 100)).toFixed(decimals);

export const numberRoundedWithoutDecimals = (number) =>
  `${Math.round(parseFloat(number))}`;

export const convertDollarsToCents = (number) => Math.round(number * 100);
export const convertCentsToDollars = (number) => number / 100;

export const formatIncomeRentRatio = (ratio) => {
  let result;

  if (ratio) {
    result = `${numberWithDecimals(ratio, 1)}x`;
  } else {
    result = "N/A";
  }

  return result;
};

export const formatCreditScore = (score, isReport = false) => {
  let label;
  let style;

  if (score > 750) {
    label = "Excellent";
    style = {
      color: COLORS.incomeAssets.verified,
    };
  } else if (score > 700) {
    label = "Good";
    style = {
      color: COLORS.incomeAssets.verified,
    };
  } else if (score > 640) {
    label = "Fair";
    style = {
      color: COLORS.incomeAssets.unVerified,
    };
  } else if (score > 580) {
    label = "Poor";
    style = {
      color: COLORS.incomeAssets.unVerified,
    };
  } else {
    label = "Very Poor";
    style = {
      color: COLORS.incomeAssets.unVerified,
    };
  }

  if (!isReport) {
    style = {};
  }

  return score ? (
    <>
      {score} <small style={style}>({label})</small>
    </>
  ) : (
    "N/A"
  );
};

export const yesNo = (bool: boolean, additionalConditionBool?: boolean) => {
  let result = "N/A";

  if (bool === true && !additionalConditionBool) {
    result = "Yes";
  } else if (bool === false && !additionalConditionBool) {
    result = "No";
  }

  return result;
};

export const filterOnClick =
  (
    callback,
    actionElements = ["A", "circle", "INPUT", "UL", "LI", "svg", "path"]
  ) =>
  (event) => {
    const isActionElement = actionElements.indexOf(event.target.tagName) > -1;

    if (!isActionElement) {
      callback(event);
    }
  };

export const formatDate = (date) =>
  date ? moment(date).format(DATE_FORMAT) : "N/A";
export const formatDateTime = (date) =>
  date ? moment(date).format(DATE_TIME_FORMAT) : "N/A";
export const getDateObject = (dateString) => moment(dateString, DATE_FORMAT);
export const calculateDuration = (startDate, endDate) => {
  const start = moment(startDate);
  const end = moment(endDate);

  const months = end.diff(start, "months");
  const days = end.diff(start, "days");

  let duration = "N/A";
  if (months) {
    const month = end.date() < start.date() ? end.month() - 1 : end.month();
    const dayInLastMonth = new Date(end.year(), month, start.date());
    const diffDays = end.diff(dayInLastMonth, "days");

    if (diffDays !== 0) {
      duration = `${months} ${pluralize(
        "month",
        months
      )} ${diffDays} ${pluralize("day", diffDays)}`;
    } else {
      duration = `${months} ${pluralize("month", months)}`;
    }
  } else if (days) {
    duration = `${days} ${pluralize("day", days)}`;
  }
  return duration;
};

export const openNotification = (
  message,
  type = NOTIFICATIONS.info,
  options = undefined
) => {
  let resolvedMessage = message;

  if (isArray(resolvedMessage)) {
    resolvedMessage =
      resolvedMessage.length > 1 ? (
        <ErrorList
          errors={resolvedMessage}
          listStyleType="disc"
          color={COLORS.primary}
          noMarginBottom
        />
      ) : (
        resolvedMessage[0]
      );
  }

  if (NOTIFICATIONS.warning === type) {
    resolvedMessage = (
      <FlexContainer justifyContent="center">
        <Notice type="warning" fontWeight={400}>
          {message}
        </Notice>
      </FlexContainer>
    );
  }

  notification.open({
    description: resolvedMessage,
    className: type,
    ...options,
  });
};

export const notifyError = (message, options = undefined) =>
  openNotification(message, NOTIFICATIONS.error, options);
export const notifyWarning = (message, options = undefined) =>
  openNotification(message, NOTIFICATIONS.warning, options);
export const notifyInfo = (message, options = undefined) =>
  openNotification(message, NOTIFICATIONS.info, options);
export const notifySuccess = (message, options = undefined) =>
  openNotification(message, NOTIFICATIONS.info, options);

export const handleFormSubmit = ({
  submit,
  values,
  setSubmitting,
  setErrors,
  resetForm,
  resolve: resolveArg,
  reject: rejectArg,
}) => {
  const resolve = () => {
    setSubmitting(false);

    if (resetForm) {
      resetForm();
    }

    setTimeout(() => {
      // Stop errors from showing for the resetted form
      setErrors({});
    }, 0);

    if (resolveArg) {
      resolveArg();
    }
  };
  /* eslint-disable require-yield */
  function* reject(errors) {
    setErrors(errors);
    setSubmitting(false);
    if (rejectArg) {
      rejectArg();
    }
  }
  /* eslint-enable require-yield */
  submit(values, resolve, reject);
};

export const getMailToLink = (emails) => `mailto:${emails.join(",")}`;

export const contactByEmails = (
  emails = [],
  message = "The emails are copied to clipboard!"
) => {
  copyToClipboard(emails.join(";"));
  notifyInfo(message);
};

export const calcTileInnerBorderBottom = (array, index) =>
  index < array.length - 1;

export const getValueOrNA = (value) => {
  if (!value || value === "NA") {
    return "N/A";
  }
  return value;
};

export const requestLoading = (requested, loading) => !requested || loading;

export const handleCallRequest = ({
  action,
  requested,
  loading,
  error,
  cached,
}) => {
  const anotherObjectCached =
    requested && !loading && !error && cached === false;

  if (!requested || anotherObjectCached) {
    action();
  }
};

export const requestFinished = (requested, loading, cached) =>
  requested && !loading && cached;

export function* indexGenerator(startIndex = 1) {
  let index = startIndex;
  while (true) {
    yield index;
    index += 1;
  }
}

export const downloadDocument = ({ name, path }) => {
  const link = document.createElement("a");
  link.setAttribute("href", path);
  link.setAttribute("download", name);
  link.setAttribute("target", "_blank");
  link.click();
};

export const getPeriod = (start, end, status = "") => {
  const toMessage = end
    ? formatDate(end)
    : status === "inactive"
    ? "Inactive"
    : "Current";

  return `${formatDate(start)} - ${toMessage}`;
};

export const isSubstrInStr = (string, substring) =>
  new RegExp(substring, "i").test(string);

export const safeParsePhoneNumber = (
  phoneNumber,
  defaultCountry?: CountryCode
) => {
  let parsedPhoneNumber = null;
  try {
    parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber, defaultCountry);
  } catch (e) {
    // NOOP
  }
  return parsedPhoneNumber;
};

export const formatPhone = (number, defaultCountry?: CountryCode) => {
  if (!number) {
    return "N/A";
  }

  const phoneNumber = safeParsePhoneNumber(`+${number}`, defaultCountry);

  if (!phoneNumber) return number;

  if (phoneNumber.country === "US") {
    return `${phoneNumber.formatNational()}`;
  }
  return phoneNumber.formatInternational();
};

export const filterArrayByMultipleFields = (array, filters, searchingValue) => {
  return array?.filter((item) =>
    Object.keys(filters).some((key) => {
      return filters[key](item[key], searchingValue);
    })
  );
};

export const formatDocumentName = (fileName, extension = ".pdf") => {
  const isNameContainsExtension = fileName.endsWith(extension);

  return isNameContainsExtension ? fileName : `${fileName}${extension}`;
};

export const navigateToError = (errors) => {
  if (!isEmpty(errors)) {
    const errorNames = Object.keys(errors)
      .filter((name) => {
        const isError =
          isObject(errors[name]) || isArray(errors[name])
            ? !isEmpty(errors[name])
            : Boolean(errors[name]);
        return isError;
      })
      .map((name) => kebabCase(name));
    const ids = errorNames.map((name) => `#${name}`).join();

    // Note: Finds the first element in the DOM regardless of selectors order
    const errorElement = ids && document.querySelector(ids);
    const errorHash = errorElement && `#${errorElement.id}`;

    if (errorHash) {
      window.location.hash = "";
      window.location.hash = errorHash;
    }
  }
};

/**
 * Scrolls to the first erroneous section id it will find.
 * @param {*} errors backend-originating errors structured in a single level map of {string: array|object}
 */
export const scrollToErrorSection = (errors) => {
  const targetIds = [];

  Object.entries(errors).forEach(([name, error]) => {
    if (Array.isArray(error)) {
      const errorIndex = findIndex(error, (el) => !isEmpty(el));
      if (errorIndex > -1) {
        targetIds.push(`${kebabCase(name)}-${errorIndex}`);
      }
    } else if (isPlainObject(error) || isString(error)) {
      if (!isEmpty(error)) {
        targetIds.push(kebabCase(name));
      }
    }
  });

  // Note: Finds the first element in the DOM regardless of selectors order
  const selector = targetIds.map((id) => `#${id}`).join();
  const errorElement = selector && document.querySelector(selector);
  const errorHash = errorElement && `#${errorElement.id}`;

  if (errorHash) {
    window.location.hash = "";
    window.location.hash = errorHash;
  }
};

export const insertScript = (path, dest = document.head) => {
  const scriptAlreadyInserted = dest.querySelector(`script[src="${path}"]`);

  return scriptAlreadyInserted
    ? Promise.resolve()
    : new Promise((resolve) => {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.onload = resolve;
        script.src = path;
        dest.appendChild(script);
      });
};

export const insertScripts = async (dependencies = []) => {
  await Promise.all(
    dependencies.map((path) => {
      return insertScript(path);
    })
  );
};

export const arrayMove = (arr, initialIdx, targetIdx) => {
  const tempArr = [...arr];
  tempArr.splice(targetIdx, 0, tempArr.splice(initialIdx, 1)[0]);
  return tempArr;
};

export const getDurationLabel = (durationType, durationValue) => {
  return (
    durationType &&
    durationValue &&
    `${durationValue} ${pluralize(durationType, durationValue).toLowerCase()}`
  );
};

export const formatSSN = (fullSSN) => {
  const formattedSSN =
    fullSSN?.length === 9
      ? `${fullSSN.substr(0, 3)}-${fullSSN.substr(3, 2)}-${fullSSN.substr(
          5,
          4
        )}`
      : fullSSN;

  return formattedSSN;
};

/**
 * Defines the type of a param in a help text message that comes from the BE.
 */
export const HELP_TEXT_PARAM_TYPE = Object.freeze({
  DATE_TIME: "datetime",
  DATE: "date",
});

const HELP_TEXT_PARAM_FORMATTER = Object.freeze({
  [HELP_TEXT_PARAM_TYPE.DATE_TIME]: formatDateTime,
  [HELP_TEXT_PARAM_TYPE.DATE]: formatDate,
});

/**
 * Formats a help message delivered in this shape `This is a text message with {{someParam}} delivered on {{dataParam}}.` by replacing
 * the param `keys` contained in the message and formatting their values with the corresponding formatter defined in the `type` field of the param.
 *
 * Each param must have:
 * `key` - string placeholder that represents that param in the help message
 * `value` - the actual value of the param
 * `type` - the type of formatting we need to apply to the value
 *
 * @param {*} helpText string
 * @param {*} params array of `{ key, value, type }`
 * @returns string
 */
export const formatHelpText = (helpText, params) => {
  let result = helpText;

  params.forEach((param) => {
    const formatter = HELP_TEXT_PARAM_FORMATTER[param.type];
    const replaceValue = formatter ? formatter(param.value) : params.value;
    result = result.replace(new RegExp(`{{${param.key}}}`, "g"), replaceValue);
  });

  return result;
};

/**
 * Replaces negative values in the provided array with the equal proportion
 * of the leftover from provided width. The main goal is the negative items
 * to "split" the leftover width between themselves.
 *
 * e.g. If there's an array like "[6, 10, -1, 20, -1]" and the total width
 * is 60, then the total width of the positive items is 35, so the leftover
 * is (60 - 36 = 24) and the resulting array would be "[6, 10, 12, 20, 12]".
 *
 * @param arr the array of items
 * @param width the total width
 * @returns {*} the updated array
 */
export const replaceNegativeWithLeftoverSpace = (arr, width) => {
  const sum = arr.filter((i) => i > 0).reduce((prev, curr) => prev + curr, 0);
  const countNegative = arr.filter((i) => i < 0).length;
  if (countNegative > 0) {
    const portion = (width - sum) / countNegative;
    return arr.map((i) => {
      if (i < 0) {
        return Math.floor(portion);
      }
      return i;
    });
  }
  return arr;
};
