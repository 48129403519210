import { toDollars } from "../utils";

import { Balance, BankAccount } from "./interfaces";

export const mapBalances = (
  incomeAsset?: Record<string, any>,
  skippedReason?: string,
  skipped?: boolean
): Balance => {
  const bankAccounts: BankAccount[] =
    incomeAsset?.accounts?.map((account) => ({
      accountType: account.accountType,
      availableBalance: toDollars(account.availableBalance) ?? undefined,
      currentBalance: toDollars(account.currentBalance),
      institutionName: account.institutionName,
      name: account.name,
    })) ?? [];

  const currentBalance = bankAccounts.reduce(
    (sum, cur) => sum + cur.currentBalance,
    0
  );

  return {
    verified: !!incomeAsset?.assetsVerificationStatus,
    skipped: skipped ?? undefined,
    skippedReason: skippedReason ?? undefined,
    availableCash: toDollars(incomeAsset?.totalAssets) ?? 0,
    currentBalance,
    bankAccounts,
  };
};
