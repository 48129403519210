import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

import { ClickwrapCheckbox } from "shared/components/ClickwrapCheckbox";
import ErrorList from "shared/components/ErrorList";
import { VeroFormField } from "shared/components/Form";
import { Input } from "shared/components/Form/Input";
import { PasswordInput } from "shared/components/Form/PasswordInput";
import Spacer from "shared/components/Spacer";
import { showError } from "shared/utils/forms";

import { baseSchemaFields } from "./config";
import { SetPasswordFormFormikProps, SetPasswordFormProps } from "./interfaces";
import { SubmitButton } from "./styled";

const getTermsAndConditionsValidationSchema = ({
  showVeroTermsClickwrap,
}: {
  showVeroTermsClickwrap: boolean;
}) => {
  const termsOfService = showVeroTermsClickwrap
    ? Yup.boolean()
        .required()
        .oneOf([true], "The terms of service must be accepted.")
    : Yup.boolean();

  return Yup.object().shape({
    ...baseSchemaFields,
    termsOfService,
  });
};

export const SetPasswordForm = ({
  formikRef,
  initialValues,
  submit,
  token,
  showEmail,
  showVeroTermsClickwrap,
  pactSafeGroupKey,
  isPactSafeConfigLoading,
  applicationId,
}: SetPasswordFormProps) => {
  const schema = getTermsAndConditionsValidationSchema({
    showVeroTermsClickwrap,
  });

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={schema}
      validateOnBlur={false}
      onSubmit={({ password }) =>
        submit({ token, email: initialValues.email, password })
      }
    >
      {({ errors, touched, isSubmitting }: SetPasswordFormFormikProps) => (
        <Form noValidate data-testid="set-password-form">
          {errors.nonFieldErrors && (
            <ErrorList errors={errors.nonFieldErrors} />
          )}
          {showEmail && (
            <>
              <Field
                name="email"
                id="email"
                type="email"
                label="Email"
                as={Input}
                disabled
              />
              <Spacer />
            </>
          )}
          <Field
            name="password"
            id="password"
            label="Password"
            as={PasswordInput}
            error={showError("password", touched, errors)}
          />
          <Spacer />
          <Field
            name="passwordConfirmation"
            id="passwordConfirmation"
            label="Confirm Password"
            as={PasswordInput}
            error={showError("passwordConfirmation", touched, errors)}
          />
          <Spacer />
          {showVeroTermsClickwrap && !isPactSafeConfigLoading && (
            <>
              <VeroFormField
                as={ClickwrapCheckbox}
                id="termsOfService"
                name="termsOfService"
                groupKey={pactSafeGroupKey}
                userEmail={initialValues.email}
                customData={{ applicationId }}
              />
              <Spacer />
            </>
          )}
          <SubmitButton
            loading={isSubmitting}
            data-testid="submit-button"
            type="primary"
            htmlType="submit"
          >
            Set Password
          </SubmitButton>
        </Form>
      )}
    </Formik>
  );
};
