import React from "react";

import { Redirect, Switch } from "react-router-dom";

import ROUTES from "manager/config/routes";
import { usePermissions } from "shared/hooks";
import { ConditionalRoute } from "shared/utils/routing";

import MagicLinksCompany from "./MagicLinksCompany";
import MagicLinksDisabled from "./MagicLinksContactManager";
import MagicLinksDashboard from "./MagicLinksDashboard";

const MagicLinksRouter = () => {
  const { permissionsLoaded, isMagicLinksEnabled } = usePermissions();

  return (
    <Switch>
      <Redirect
        exact
        from={ROUTES.magicLinks}
        to={ROUTES.magicLinksProperties}
      />
      <ConditionalRoute
        path={ROUTES.magicLinksProperties}
        redirectTo={ROUTES.magicLinksContactManager}
        isInitialized={permissionsLoaded}
        isEnabled={isMagicLinksEnabled}
      >
        <MagicLinksCompany />
        <MagicLinksDashboard />
      </ConditionalRoute>
      <ConditionalRoute
        path={ROUTES.magicLinksAgents}
        redirectTo={ROUTES.magicLinksContactManager}
        isInitialized={permissionsLoaded}
        isEnabled={isMagicLinksEnabled}
      >
        <MagicLinksCompany />
        <MagicLinksDashboard />
      </ConditionalRoute>
      <ConditionalRoute
        path={ROUTES.magicLinksContactManager}
        isInitialized={permissionsLoaded}
        isEnabled={!isMagicLinksEnabled}
        redirectTo={ROUTES.magicLinksProperties}
      >
        <MagicLinksDisabled />
      </ConditionalRoute>
    </Switch>
  );
};

export default MagicLinksRouter;
