import { queryCache, useMutation, useQuery } from "react-query";

import api from "renter/lib/api";
import { NOTIFICATIONS } from "shared/config/constants";
import { openNotification } from "shared/utils/ui";

import { QUERY_KEYS as APPLICATION_QUERY_KEYS } from "./applicationQueries";

const GET_INCOME_ASSETS = "getIncomeAndAsset";
const POLLING_INTERVAL_MILLIS = 5000;

export const INCOME_ASSETS_QUERY_KEYS = Object.freeze({
  getIncomeAsset: (id) => [GET_INCOME_ASSETS, Number(id)],
});

export const useGetIncomeAssets = (
  { applicationId, isPollingActive = false },
  config = { enabled: true }
) => {
  const {
    data: incomeAssets,
    isLoading: isIncomeAssetsLoading,
    isFetching: isIncomeAssetsFetching,
    refetch: refetchIncomeAssets,
    isFetchedAfterMount: isIncomeAssetsFetched,
  } = useQuery(
    INCOME_ASSETS_QUERY_KEYS.getIncomeAsset(applicationId),
    () => api.getIncomeAndAsset({ applicationId }),
    {
      onError: (error) => {
        const { incomeAssetRequired } = error?.errors || {};

        if (incomeAssetRequired) {
          /** Note: Removing query cache causing immediate data reload
           *  Clean up old income assets cache with setQueryData instead */
          queryCache.setQueryData(
            INCOME_ASSETS_QUERY_KEYS.getIncomeAsset(applicationId),
            undefined
          );
        } else {
          openNotification(
            "Failed to load income and balances",
            NOTIFICATIONS.error
          );
        }
      },
      refetchInterval: isPollingActive ? POLLING_INTERVAL_MILLIS : false,
      enabled: config.enabled,
    }
  );

  return {
    incomeAssets,
    isIncomeAssetsLoading,
    isIncomeAssetsFetching,
    refetchIncomeAssets,
    isIncomeAssetsFetched,
  };
};

export const useSkipIncomeAssetsVerification = (applicationId) => {
  const [skipIncomeAndAssets, { isLoading: isSkipSubmitting }] = useMutation(
    api.skipIncomeAndAsset,
    {
      onSuccess: (data) => {
        queryCache.setQueryData(
          INCOME_ASSETS_QUERY_KEYS.getIncomeAsset(applicationId),
          data
        );
        queryCache.invalidateQueries(
          APPLICATION_QUERY_KEYS.getApplicationInvitation(applicationId)
        );
      },
      onError: () => {
        openNotification(
          "Failed to skip income and balances",
          NOTIFICATIONS.error
        );
      },
    }
  );

  return {
    skipIncomeAndAssets,
    isSkipSubmitting,
  };
};

export const useConfirmIncomeAssets = (applicationId) => {
  const [confirmIncomeAssets, { isLoading: isConfirmingIncomeAssets }] =
    useMutation(api.confirmIncome, {
      onSuccess: () =>
        queryCache.invalidateQueries(
          APPLICATION_QUERY_KEYS.getApplicationInvitation(applicationId)
        ),
      onError: () => {
        openNotification(
          "Failed to confirm income and balances",
          NOTIFICATIONS.error
        );
      },
    });

  return {
    confirmIncomeAssets,
    isConfirmingIncomeAssets,
  };
};

export const useEditIncomeAssetsNote = (applicationId) => {
  const [editIncomeAssetsNote, { isLoading: isEditNoteSubmitting }] =
    useMutation(api.editIncomeAssetsNote, {
      onSuccess: () => {
        queryCache.invalidateQueries(
          INCOME_ASSETS_QUERY_KEYS.getIncomeAsset(applicationId)
        );
      },
      onError: () => {
        openNotification(
          "Failed to edit income and balances note",
          NOTIFICATIONS.error
        );
      },
    });

  return { editIncomeAssetsNote, isEditNoteSubmitting };
};

export const useResetIncomeAssets = (applicationId) => {
  const [resetIncomeAndAssets, { isLoading: isResettingIncomeAssets }] =
    useMutation(api.resetIncomeAndAssets, {
      onSuccess: () => {
        queryCache.setQueryData(
          INCOME_ASSETS_QUERY_KEYS.getIncomeAsset(applicationId),
          undefined
        );
        queryCache.invalidateQueries(
          APPLICATION_QUERY_KEYS.getApplicationInvitation(applicationId)
        );
      },
      onError: () => {
        openNotification(
          "Failed to reset income and balances",
          NOTIFICATIONS.error
        );
      },
    });

  return { resetIncomeAndAssets, isResettingIncomeAssets };
};
