export const WEBVIEWER_OPTIONS = {
  annotation: {
    Width: 200,
    Height: 25,
    Padding: [0, 0, 0, 0],
    FontSize: "16px",
    FillColor: [211, 211, 211, 0.5],
    TextColor: [0, 165, 228],
    StrokeThickness: 1,
    StrokeColor: [0, 165, 228],
    TextAlign: "center",
  },
  checkbox: {
    Width: 20,
    Height: 20,
  },
  field: {
    TextColor: [0, 0, 0],
    FontSize: "16px",
  },
  signature: {
    BgColor: [1, 1, 1],
    TextColor: [0, 0, 0],
    TextAlign: "center",
    FontSize: 16,
  },
};

export const TOOL_MODES = {
  AnnotationEdit: "AnnotationEdit",
};

export const ANNOTATION_TYPES = {
  field: "field",
  checkbox: "checkbox",
  signature: "signature",
  checkboxGroupSignature: "checkboxGroupSignature",
};

export const PDF_TRON_DEPENDENCIES = [
  "/webviewer/core/CoreControls.js",
  "/webviewer/core/pdf/PDFNet.js",
];

export const LICENSE_KEY_NAME = "REACT_APP_PDFTRON_LICENSE_KEY";
