import React from "react";

import { useParams } from "react-router-dom";

import MagicLinkInvalidPage from "./MagicLinkInvalidPage";

const MagicLinkInvalidPageContainer = () => {
  const { entityName }: { entityName: string } = useParams();

  return <MagicLinkInvalidPage entityName={entityName} />;
};

export default MagicLinkInvalidPageContainer;
