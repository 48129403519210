import React from "react";

import { useFormikContext } from "formik";
import PropTypes from "prop-types";

import { CheckboxWithUnorderedList } from "shared/components/Form";
import Settings from "shared/components/Settings";
import { usePermissions } from "shared/hooks";
import { useCountyCriminalSearchSyncOptions } from "shared/hooks/api";
import { conditionalItem } from "shared/utils/array";

import ApplicationSetupHeader from "../Header";
import ResidenceEmploymentDuration, {
  DEFAULT_DURATION_TYPE,
  DEFAULT_DURATION_VALUE,
} from "../ResidenceEmploymentDuration";
import { StyledSkeleton as Skeleton } from "../styled";
import {
  getAllowManagersToViewSSNItem,
  getAssetsVerificationItem,
  getBlockApplicationSubmitIfNoIncomeItem,
  getCountyCriminalSearchItem,
} from "../utils";

const guarantorPersonaIdentification = "requiresGuarantorPersonaIdentification";
const guarantorArgyleVerification = "requiresGuarantorArgyleVerification";
const renterProfileGuarantors = "requiresRenterProfileGuarantor";

/** Income & Assets */
const incomeAssetVerification = "requiresIncomeAssetGuarantors";
const assetVerificationWhenLinkedEmployment =
  "requiresAssetVerifLinkedEmploymentGuarantors";
const blockApplicationSubmitIfNoIncome =
  "blockDealSubmitIfIncomeDataIsMissingGuarantors";

/** Background and credit check */
const backgroundCreditCheckGuarantors =
  "requiresBackgroundCreditCheckGuarantors";
const allowManagerViewSSN = "allowManagerViewSsnGuarantor";
const countyCriminalSearch = "countyCriminalSearchGuarantorsSync";

/** Residential History Guarantor */
const residentialHistoryDurationFlag =
  "requiresResidentialHistoryDurationGuarantor";
const residentialHistoryDurationType = "residentialHistoryRangeGuarantor";
const residentialHistoryDurationValue = "residentialHistoryDurationGuarantor";

/** Employment History Guarantor */
const employmentHistoryDurationFlag =
  "requiresEmploymentHistoryDurationGuarantor";
const employmentHistoryDurationType = "employmentHistoryRangeGuarantor";
const employmentHistoryDurationValue = "employmentHistoryDurationGuarantor";

const ScreeningForGuarantors = ({ loading, disabled }) => {
  const { values, setFieldValue, setValues } = useFormikContext();
  const { isArgyleEnabled, isPersonaEnabled, isCountyCriminalSearchEnabled } =
    usePermissions();

  const {
    countyCriminalSearchSyncOptions,
    isCountyCriminalSearchSyncOptionsLoading,
  } = useCountyCriminalSearchSyncOptions();

  const isLoading = loading || isCountyCriminalSearchSyncOptionsLoading;

  return (
    <Settings
      header={
        <Skeleton loading={isLoading}>
          <ApplicationSetupHeader
            title="Screening for guarantors"
            subTitle="Please select screening level you would like to require from guarantors"
          />
        </Skeleton>
      }
      nodes={[
        ...conditionalItem(isPersonaEnabled, {
          key: 10,
          component: (
            <Skeleton loading={loading}>
              <CheckboxWithUnorderedList
                id="guarantor-persona-identification"
                label="Identity Verification"
                onChange={(checked) => {
                  setFieldValue(guarantorPersonaIdentification, checked);
                }}
                value={values[guarantorPersonaIdentification]}
                disabled={disabled}
              />
            </Skeleton>
          ),
        }),
        ...conditionalItem(isArgyleEnabled, {
          key: 15,
          component: (
            <Skeleton loading={loading}>
              <CheckboxWithUnorderedList
                id="guarantor-link-employment"
                label="Link Employment"
                onChange={(checked) => {
                  setFieldValue(guarantorArgyleVerification, checked);
                }}
                value={values[guarantorArgyleVerification]}
                disabled={disabled}
              />
            </Skeleton>
          ),
        }),
        {
          key: 20,
          component: (
            <Skeleton loading={loading}>
              <CheckboxWithUnorderedList
                id="income-asset-verification-for-guarantors"
                label="Income &amp; Balances Verification"
                value={values[incomeAssetVerification]}
                items={[
                  { label: "Income", id: "income-for-guarantors" },
                  getAssetsVerificationItem(
                    isArgyleEnabled,
                    values,
                    assetVerificationWhenLinkedEmployment,
                    disabled
                  ),
                  {
                    label: "Block Applications Without Verified Income",
                    id: "block-application-submission-if-no-income-guarantors",
                    disabled,
                    children: [
                      getBlockApplicationSubmitIfNoIncomeItem(
                        values,
                        blockApplicationSubmitIfNoIncome,
                        true
                      ),
                    ],
                  },
                ]}
                onChange={(value) => {
                  setFieldValue(incomeAssetVerification, value);
                }}
                onItemChildChange={(id, value) => {
                  setFieldValue(id, value);
                }}
                disabled={disabled}
              />
            </Skeleton>
          ),
        },
        {
          key: 30,
          component: (
            <Skeleton loading={loading}>
              <CheckboxWithUnorderedList
                id="background-credit-check-for-guarantors"
                label="Background &amp; Credit check"
                value={values[backgroundCreditCheckGuarantors]}
                disabled={disabled}
                onChange={(checked) => {
                  setFieldValue(backgroundCreditCheckGuarantors, checked);
                }}
                onItemChildChange={(id, value) => {
                  setFieldValue(id, value);
                }}
                items={[
                  { label: "Credit Check", id: "credit-check-for-guarantors" },
                  {
                    label: "Eviction Related Proceedings History",
                    id: "housing-court-records-history-guarantors",
                  },
                  {
                    label: "Background Check",
                    id: "background-check-for-guarantors",
                    disabled,
                    children: [
                      getAllowManagersToViewSSNItem(
                        allowManagerViewSSN,
                        values
                      ),
                    ],
                  },
                  ...conditionalItem(
                    isCountyCriminalSearchEnabled,
                    getCountyCriminalSearchItem(
                      countyCriminalSearch,
                      countyCriminalSearchSyncOptions,
                      disabled
                    )
                  ),
                ]}
              />
            </Skeleton>
          ),
        },
        {
          key: 40,
          component: (
            <Skeleton loading={loading}>
              <CheckboxWithUnorderedList
                id="renter-profile-for-guarantors"
                label="Renter Profile"
                disabled
                items={[
                  {
                    id: "residential-history-duration-guarantors",
                    label: "Residential History",
                    disabled,
                    children: [
                      {
                        id: residentialHistoryDurationFlag,
                        label: "Set Minimum History",
                        checked: values[residentialHistoryDurationFlag],
                        checkedContent: (
                          <ResidenceEmploymentDuration
                            durationTypeFieldName={
                              residentialHistoryDurationType
                            }
                            durationValueFieldName={
                              residentialHistoryDurationValue
                            }
                            disabled={disabled}
                          />
                        ),
                      },
                    ],
                  },
                  {
                    id: "employment-history-duration-guarantors",
                    label: "Employment History",
                    disabled,
                    children: [
                      {
                        id: employmentHistoryDurationFlag,
                        label: "Set Minimum History",
                        checked: values[employmentHistoryDurationFlag],
                        checkedContent: (
                          <ResidenceEmploymentDuration
                            durationTypeFieldName={
                              employmentHistoryDurationType
                            }
                            durationValueFieldName={
                              employmentHistoryDurationValue
                            }
                            disabled={disabled}
                          />
                        ),
                      },
                    ],
                  },
                  {
                    label: "Lease Preferences (optional)",
                    id: "lease-preferences",
                  },
                  {
                    label: "Additional Information",
                    id: "additional-information-for-guarantors",
                  },
                ]}
                value={values[renterProfileGuarantors]}
                onChange={(value) => {
                  setFieldValue(renterProfileGuarantors, value);
                }}
                onItemChildChange={(id, value) => {
                  if (id === residentialHistoryDurationFlag) {
                    setValues({
                      ...values,
                      [residentialHistoryDurationFlag]: value,
                      [residentialHistoryDurationType]: DEFAULT_DURATION_TYPE,
                      [residentialHistoryDurationValue]: DEFAULT_DURATION_VALUE,
                    });
                  } else if (id === employmentHistoryDurationFlag) {
                    setValues({
                      ...values,
                      [employmentHistoryDurationFlag]: value,
                      [employmentHistoryDurationType]: DEFAULT_DURATION_TYPE,
                      [employmentHistoryDurationValue]: DEFAULT_DURATION_VALUE,
                    });
                  } else {
                    setFieldValue(id, value);
                  }
                }}
              />
            </Skeleton>
          ),
        },
      ]}
    />
  );
};

ScreeningForGuarantors.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

ScreeningForGuarantors.defaultProps = {
  disabled: false,
  loading: false,
};

export default ScreeningForGuarantors;
