import isEmpty from 'lodash/isEmpty'

import { APPLICATION } from 'shared/config/routes'
import { useGetDocumentDetail } from 'shared/hooks/api'
import type { Document } from 'shared/interfaces/api/document'
import { getFraudRiskTextAndColor } from 'shared/utils/document'

import { RegularText } from '../Typography'

import { LumenReadyLink } from './styled'

interface LumenReadyProps {
  applicationId: string
  id: string
}

const ocrStatus: Record<
  Exclude<Document['ocrJobStatus'], 'succeeded'>,
  string
> = {
  created: 'In Progress',
  failed: 'Failed',
  'in-progress': 'In Progress',
}

export const LumenReady = ({ applicationId, id }: LumenReadyProps) => {
  const { document } = useGetDocumentDetail(id)

  if (isEmpty(document)) return null

  const { ocrJobStatus, fraudRiskLevel } = document

  const linkToIAndB = APPLICATION.applicationIncomeAssets.replace(
    ':id',
    applicationId,
  )

  const fraudRiskTextAndColor = getFraudRiskTextAndColor(fraudRiskLevel)
  return ocrJobStatus === 'succeeded' ? (
    <>
      {fraudRiskTextAndColor.color === 'unset' ? (
        <RegularText>{fraudRiskTextAndColor.text}</RegularText>
      ) : (
        <LumenReadyLink to={linkToIAndB} color={fraudRiskTextAndColor.color}>
          {fraudRiskTextAndColor.text}
        </LumenReadyLink>
      )}
    </>
  ) : (
    <RegularText>{ocrStatus[ocrJobStatus]}</RegularText>
  )
}
