import styled from "@emotion/styled";
import { Typography } from "antd";

import { MEDIA_QUERIES } from "shared/config/constants";

const { Text } = Typography;

const Container = styled("div")({
  padding: "40px 0",
  [MEDIA_QUERIES.w.gteXl]: {
    maxWidth: "1200px",
  },
});

const FeeTitle = styled(Text)({
  fontWeight: "normal",
  fontSize: "17px",
  color: "#2D2943",
});

const Fade = styled("span")({
  color: "#807E8D",
  fontWeight: 300,
});

FeeTitle.Fade = Fade;

const FeeTitleContainer = styled("div")({
  paddingBottom: "24px",
});

const Notice = styled("div")({
  display: "flex",
});

export { Container, FeeTitle, FeeTitleContainer, Notice };
