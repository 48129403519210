import React from "react";

import { Col, Row } from "antd";
import { Form, Formik, FieldArray } from "formik";
import * as Yup from "yup";

import {
  getLeadSourceSchema,
  InvitationLeadSourceSelection,
} from "manager/components/Modal/InviteApplicantsModal";
import ErrorList from "shared/components/ErrorList";
import { Input, VeroFormField } from "shared/components/Form";
import { Modal } from "shared/components/Modal";
import NumberTitle from "shared/components/NumberTitle";
import Spacer from "shared/components/Spacer";
import {
  InviteApplicantsForm,
  InviteApplicantsLink,
  ValidationSchema as ApplicantsValidationSchema,
  initialValues as applicantsInitialValues,
} from "shared/forms/InviteApplicantsForm";
import { useDeviceType } from "shared/hooks";
import { useGetApplicantTypes } from "shared/hooks/api";

import {
  UnitInviteApplicantsDataFormikProps,
  UnitInviteApplicantsModalProps,
} from "./interfaces";

const getValidationSchema = (context) => {
  return Yup.object()
    .shape({
      ...ApplicantsValidationSchema,
    })
    .concat(getLeadSourceSchema(context.leadSourceRequired));
};

const UnitInviteApplicantsModal = ({
  submit,
  context,
}: UnitInviteApplicantsModalProps) => {
  const { unit, property } = context;
  const { isMobile } = useDeviceType();
  const { applicantTypes, isLoading: applicantTypesLoading } =
    useGetApplicantTypes();

  const initialValues = {
    ...applicantsInitialValues,
    externalPMSId: undefined,
    leadSource: undefined,
  };

  const showPmsIdSection =
    unit?.pmsConnected && ["entrata", "real-page"].includes(unit?.provider);

  return (
    <Formik
      validationSchema={getValidationSchema(context)}
      initialValues={initialValues}
      enableReinitialize
      onSubmit={(values, formikApi) =>
        submit({ ...values, unitId: unit?.id }, formikApi)
      }
    >
      {({
        values,
        errors,
        touched,
        submitForm,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        validateForm,
      }: UnitInviteApplicantsDataFormikProps) => (
        <Form>
          <Modal
            title="Invite Applicants"
            subtitle={
              <span data-testid="subtitle">
                Unit:
                <strong>{` ${unit?.name}, ${property?.name}`}</strong>
              </span>
            }
            submitButtonLabel="Invite Applicants"
            submit={submitForm}
            submitting={isSubmitting}
            closeOnSubmit={false}
            width="auto"
            style={{ maxWidth: 946 }}
            fullScreen={isMobile}
          >
            {errors.nonFieldErrors && (
              <Modal.Body noPaddingBottom>
                <ErrorList errors={errors.nonFieldErrors} />
              </Modal.Body>
            )}
            <Modal.Body spacer>
              <NumberTitle number={1}>Fill info about applicants</NumberTitle>
              <FieldArray
                name="applicants"
                render={(arrayHelpers) => (
                  <>
                    <InviteApplicantsForm
                      applicantTypes={applicantTypes}
                      applicantTypesLoading={applicantTypesLoading}
                      applicants={values.applicants}
                      errors={errors.applicants}
                      touched={touched.applicants}
                      deleteApplicant={arrayHelpers.remove}
                      showPmsIdSection={showPmsIdSection}
                    />
                    <Spacer size={Spacer.SIZES.sm} />
                    <InviteApplicantsLink
                      applicants={values.applicants}
                      checkValidation={validateForm}
                      addApplicant={arrayHelpers.push}
                    />
                    <Spacer size={Spacer.SIZES.sm} />
                  </>
                )}
              />
            </Modal.Body>
            {showPmsIdSection && (
              <Modal.Body spacer>
                <NumberTitle number={2}>
                  Is there a PMS ID to associate with this application?
                  (Optional)
                </NumberTitle>
                <Row gutter={[20, 25]}>
                  <Col md={8}>
                    <VeroFormField
                      as={Input}
                      id="externalPMSId"
                      name="externalPMSId"
                      label="Application PMS ID"
                      type="text"
                    />
                  </Col>
                </Row>
              </Modal.Body>
            )}
            <InvitationLeadSourceSelection
              number={showPmsIdSection ? 3 : 2}
              leadSources={context.leadSources}
              leadSourcesLoading={context.leadSourcesLoading}
              touched={touched}
              errors={errors}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
            />
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default UnitInviteApplicantsModal;
