import {
  INCOME_ASSETS_SKIPPED_REASON,
  LIVING_SITUATION_TYPES,
} from "shared/config/constants";
import { conditionalItem } from "shared/utils/array";
import { SessionStorageCollectionManager } from "shared/utils/sessionStorage";

export const ACTIVE_APPLICATION_ID = "ACTIVE_APPLICATION_ID";

export const activeApplicationIdStorage = new SessionStorageCollectionManager({
  storageKey: ACTIVE_APPLICATION_ID,
});

const LIVING_CAMPUS_FAMILY = new Set([
  LIVING_SITUATION_TYPES.campus.key,
  LIVING_SITUATION_TYPES.familyOrFriends.key,
]);

export const isResidenceOwner = (livingSituation) =>
  livingSituation === LIVING_SITUATION_TYPES.homeOwner.key;

export const isResidenceRenter = (livingSituation) =>
  livingSituation === LIVING_SITUATION_TYPES.renter.key;

export const isResidenceCampusFamily = (livingSituation) =>
  LIVING_CAMPUS_FAMILY.has(livingSituation);

export const isResidenceOther = (livingSituation) =>
  livingSituation === LIVING_SITUATION_TYPES.other.key;

export const getSkippedIncomeAssetsInfo = (reason, bankName) => {
  let message;
  let label;

  switch (reason) {
    case INCOME_ASSETS_SKIPPED_REASON.BankError:
      message = `An error occurred while individual was attempting to connect to their bank: ${bankName}`;
      label = "Error";
      break;

    case INCOME_ASSETS_SKIPPED_REASON.UserRefusedSharingCredentials:
      message = `Individual chose not to share their bank credentials`;
      label = "Skipped";
      break;

    case INCOME_ASSETS_SKIPPED_REASON.BankNotFound:
      message = `Individual was unable to locate their Bank: ${bankName}`;
      label = "Not Found";

      break;

    default:
      message = "Individual skipped verification";
      label = "Skipped";
  }

  return { message, label };
};

export const showConditionallyProceed = (application) => {
  return (
    application?.hasConditionallyProceedEnabled &&
    !application?.isConditionallyProceeded &&
    application?.applicationSummary?.hasSsn
  );
};

export const getApplicationFeesLabel = (applicant, prefix = "") => {
  const fees = [
    ...conditionalItem(
      applicant?.hasApplicationFee,
      `${prefix}application fee`
    ),
    ...conditionalItem(
      applicant?.hasHoldingDeposit && !applicant?.isHoldingDepositUnpaid,
      `${prefix}holding deposit`
    ),
  ];
  return fees.join(" and ");
};
