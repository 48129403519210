import React from "react";

import Icon from "shared/components/Icon";
import InfoPage from "shared/templates/InfoPage";

import { SetPasswordLinkExpiredPageProps } from "./interfaces";

const SetPasswordLinkExpiredPage = ({
  requestNewLink,
  isRequesting,
}: SetPasswordLinkExpiredPageProps) => (
  <InfoPage
    title="Link has expired"
    description="For your security, the link has a set expiration period."
    MainIcon={Icon.OrangeExclamationHollow}
    button={{
      children: "Request New Link",
      onClick: requestNewLink,
      loading: isRequesting,
    }}
  />
);

export default SetPasswordLinkExpiredPage;
