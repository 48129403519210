import React from "react";

import PropTypes from "prop-types";

import { Popover } from "shared/components/Popover";
import Tag from "shared/components/Tag";
import Well from "shared/components/Well";
import { COLORS } from "shared/config/constants";
import { useEllipsisTooltip } from "shared/hooks";

import {
  TEAM_NOTE_LABEL,
  systemAuditPropType,
  SYSTEM_AUDIT_NOTE_TAG,
} from "../config";

import { PopoverContent, Title } from "./styled";

const ActivityTitle = ({ audit, index }) => {
  const { note, description, tag } = audit;
  const title = [description, note].filter((v) => !!v).join(" ");
  const { elementRef: activityRef, tooltipTitle: isOverflowed } =
    useEllipsisTooltip({ title });

  return (
    <Title>
      <Popover
        disabled={!isOverflowed}
        // @ts-ignore
        trigger="hover"
        content={
          <PopoverContent>
            <p className="popover-title">{description || TEAM_NOTE_LABEL}</p>
            {!!note && (
              <Well className="popover-items">
                {note.split("\n").map((line) => (
                  <div key={line}>
                    <span>{line}</span>
                    <br />
                  </div>
                ))}
              </Well>
            )}
          </PopoverContent>
        }
      >
        <span
          className="title-span"
          data-testid={`unit-${index}-activity`}
          ref={activityRef}
        >
          {title}
        </span>
      </Popover>
      {tag === SYSTEM_AUDIT_NOTE_TAG.id && (
        <Tag
          className="note-tag"
          backgroundColor={COLORS.violetTheme.lightBlueViolet}
          color={COLORS.violetTheme.white}
        >
          {TEAM_NOTE_LABEL}
        </Tag>
      )}
    </Title>
  );
};

ActivityTitle.propTypes = {
  audit: systemAuditPropType,
  index: PropTypes.number.isRequired,
};

ActivityTitle.defaultProps = {
  audit: undefined,
};

export default ActivityTitle;
