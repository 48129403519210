/* eslint-disable max-classes-per-file */
import { HTTP_ERROR_CODES, NOTIFICATIONS } from "shared/config/constants";
import ROUTES from "shared/config/routes";

import { toCamelCaseKeys } from "shared/utils/misc.util";
import { openNotification } from "shared/utils/ui";

const getErrors = (errors) => toCamelCaseKeys(errors);

export class TUError extends Error {
  constructor(error = {}) {
    super();
    this.statusCode = error.response.statusCode;
    this.errors = getErrors(error.response.data.errors);
  }

  static TU_ERROR_KEYS = ["explanation", "error_code", "message"];

  static isTUError = (error) =>
    error?.response?.data?.errors &&
    Array.isArray(error.response.data.errors) &&
    this.TU_ERROR_KEYS.every((key) => key in error.response.data.errors[0]);
}

export class ApiError extends Error {
  static SESSION_EXPIRED = "SESSION_EXPIRED";

  static NETWORK_ERROR = "NETWORK_ERROR";

  static SETUP_ERROR = "SETUP_ERROR";

  constructor(statusCode, errors = []) {
    const message = `API request failed (status code: ${statusCode})`;
    super(message);
    this.message = message;
    this.statusCode = statusCode;
    this.errors = getErrors(errors);
  }

  toString() {
    return this.message;
  }
}

export class NotFoundError extends Error {}

export const logError = (error) => {
  // eslint-disable-next-line no-console
  return console.log(error);
};

export const notifyAboutErrors = (
  error,
  defaultMessage = "Something went wrong"
) => {
  if (
    error.errors?.nonFieldErrors instanceof Array &&
    error.errors?.nonFieldErrors.length > 0
  ) {
    error.errors.nonFieldErrors.map((item) =>
      openNotification(item, NOTIFICATIONS.error)
    );
  }
  openNotification(defaultMessage, NOTIFICATIONS.error);
};

// Simple error handling function copied from handleSagaError
export const handleError = (error, reject, defaultMessage) => {
  const isApiError = error instanceof ApiError;

  if (isApiError) {
    if (reject) {
      reject(error.errors);
    }

    if (error.statusCode === HTTP_ERROR_CODES.notFound) {
      window.location.replace(ROUTES.notFound);
    } else if (
      error.statusCode !== ApiError.SESSION_EXPIRED &&
      error.statusCode !== HTTP_ERROR_CODES.unauthorized
    ) {
      if (defaultMessage && window.location.pathname !== ROUTES.notFound) {
        notifyAboutErrors(error, defaultMessage);
      }
    }
  } else {
    logError(error);

    if (reject) {
      reject({
        nonFieldErrors: ["Something went wrong"],
      });
    }
  }
};
