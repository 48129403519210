import React from 'react'

import { Row, Col } from 'antd'
import { Form, Formik } from 'formik'
import _ from 'lodash'
import * as Yup from 'yup'

import type { Income } from 'renter/interfaces/api/income'
import { Input, VeroFormField } from 'shared/components/Form'
import { Modal } from 'shared/components/Modal'
import { usePatchOcrResultById } from 'shared/hooks/api'
import useModal from 'shared/hooks/useModal'
import { mapIncomeStreamVerificationMethodToType } from 'shared/utils/employmentAndIncome'
import { convertCentsToDollars } from 'shared/utils/ui'

import { RevertButton } from '../styled'

interface EditW2or1099ModalProps {
  stream: Income
  submit: (values) => void
}

const validationSchema = Yup.object().shape({
  type: Yup.string(),
  payer: Yup.string().nullable(),
  formYear: Yup.number().nullable(),
  grossAmount: Yup.number().nullable(),
})

const EditW2or1099Modal = ({ stream, submit }: EditW2or1099ModalProps) => {
  const {
    selfApi: { closeDialog },
  } = useModal()
  const { patchOcrResultById } = usePatchOcrResultById()
  const initialValues = {
    type: mapIncomeStreamVerificationMethodToType(
      stream.incomeVerificationMethod,
    ),
    payer: stream.description,
    formYear: stream.year,
    grossAmount: stream.grossAmount
      ? convertCentsToDollars(stream.grossAmount)
      : null,
  }

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      validateOnMount
      onSubmit={(values) => {
        const filteredValues = _.chain(values)
          .pickBy(function (value, key) {
            return key !== 'type' && initialValues[key] !== value
          })
          .mapValues((value) => (value === '' ? null : value))
          .value()
        submit({
          id: stream.sourceOcrResult,
          data: filteredValues,
        })
      }}
      enableReinitialize
    >
      {({ isValid, submitForm }) => {
        return (
          <Form>
            <Modal
              title="Edit supporting income"
              submitButtonLabel="Save"
              cancelLinkLabel="Cancel"
              footer={
                <RevertButton
                  onClick={() => {
                    patchOcrResultById({
                      id: stream.sourceOcrResult,
                      data: {
                        payer: null,
                        formYear: null,
                        grossAmount: null,
                      },
                    })
                    closeDialog()
                  }}
                >
                  Revert changes
                </RevertButton>
              }
              submit={submitForm}
              centered
              showSubmitButton
              submitButtonDisabled={!isValid}
              width="650px"
            >
              <Modal.Body>
                <Row gutter={[20, 25]}>
                  <Col md={12}>
                    <VeroFormField
                      as={Input}
                      id="type"
                      name="type"
                      label="Type"
                      data-testid="type-input"
                      disabled
                    />
                  </Col>
                  <Col md={12}>
                    <VeroFormField
                      as={Input}
                      id="payer"
                      name="payer"
                      label="Payer Name"
                      data-testid="payer-input"
                    />
                  </Col>
                </Row>
                <Row gutter={[20, 25]}>
                  <Col md={12}>
                    <VeroFormField
                      as={Input}
                      id="formYear"
                      name="formYear"
                      label="Year"
                      data-testid="formYear-input"
                      disableMaskedInput
                    />
                  </Col>
                  <Col md={12}>
                    <VeroFormField
                      as={Input}
                      id="grossAmount"
                      name="grossAmount"
                      label="Gross Amount"
                      data-testid="grossAmount-input"
                      disableMaskedInput
                    />
                  </Col>
                </Row>
              </Modal.Body>
            </Modal>
          </Form>
        )
      }}
    </Formik>
  )
}

export default EditW2or1099Modal
