import React from "react";

import PropTypes from "prop-types";

import {
  useCustomEmailConfigurationSendTest,
  useDefaultEmailConfigurationSendTest,
  useDeleteCustomEmailConfiguration,
  useGetCustomEmailConfigurations,
  useRestoreDefaultEmailConfiguration,
  useSetCustomEmailConfigurationAsDefault,
} from "manager/hooks/api";
import ConfirmationModal from "shared/components/Modals/ConfirmationModal";
import useModal from "shared/hooks/useModal";

import { getEmailConfigurationInitialValues } from "../utils";

import CompanyEmailTemplatesForm from "./CompanyEmailTemplatesForm";

const CompanyEmailTemplatesFormContainer = ({
  defaultConfiguration,
  registerFormChange,
}) => {
  const { openModalDialog } = useModal();

  const { id: defaultConfigurationId, typeName } = defaultConfiguration;
  const { customEmailConfigurations, isCustomConfigurationsLoading } =
    useGetCustomEmailConfigurations({
      typeName,
    });

  const { setCustomEmailConfigurationAsDefault } =
    useSetCustomEmailConfigurationAsDefault();

  const { restoreDefaultEmailConfiguration } =
    useRestoreDefaultEmailConfiguration();

  const { deleteCustomEmailConfiguration } =
    useDeleteCustomEmailConfiguration();

  const { defaultEmailConfigurationSendTest, isSendingDefaultEmail } =
    useDefaultEmailConfigurationSendTest();

  const { customEmailConfigurationSendTest, isSendingCustomEmail } =
    useCustomEmailConfigurationSendTest();

  const currentConfiguration = getEmailConfigurationInitialValues(
    customEmailConfigurations
  );

  const onSubmit = async (values) => {
    if (values.customEmailConfigurationId) {
      await setCustomEmailConfigurationAsDefault({
        customEmailConfigurationId: values.customEmailConfigurationId,
        typeName,
      });
    } else if (values.isDefaultConfiguration) {
      await restoreDefaultEmailConfiguration({ typeName });
    }
  };

  const onDeleteCustomEmailConfiguration = (option) =>
    openModalDialog(ConfirmationModal, {
      title: `Delete template?`,
      subtitle: option.label,
      message:
        "Once the template is deleted it can not be recovered. Please confirm that you would like to delete this template.",
      onConfirm: () =>
        deleteCustomEmailConfiguration({ id: option.value, typeName }),
    });

  const sendTestEmail = (values) => {
    if (values.customEmailConfigurationId) {
      customEmailConfigurationSendTest(values.customEmailConfigurationId);
    } else if (values.isDefaultConfiguration) {
      defaultEmailConfigurationSendTest({ typeName });
    }
  };

  return (
    <CompanyEmailTemplatesForm
      defaultConfigurationId={defaultConfigurationId}
      currentConfiguration={currentConfiguration}
      onSubmit={onSubmit}
      isLoading={isCustomConfigurationsLoading}
      onDeleteCustomEmailConfiguration={onDeleteCustomEmailConfiguration}
      customEmailConfigurations={customEmailConfigurations}
      sendTestEmail={sendTestEmail}
      isSendingEmail={isSendingCustomEmail || isSendingDefaultEmail}
      registerFormChange={registerFormChange}
    />
  );
};

CompanyEmailTemplatesFormContainer.propTypes = {
  defaultConfiguration: PropTypes.shape({
    id: PropTypes.number.isRequired,
    typeName: PropTypes.string.isRequired,
  }).isRequired,
  registerFormChange: PropTypes.func.isRequired,
};

export default CompanyEmailTemplatesFormContainer;
