import * as Yup from "yup";

import {
  LUMEN_FILTER_CONDITION_AMOUNT_TIME_OPERATOR_LABELS,
  LUMEN_FILTER_CONDITION_AMOUNT_TIME_OPERATOR_TYPE,
  LUMEN_FILTER_CONDITION_SUB_TYPE,
  LUMEN_FILTER_CONDITION_TYPE,
  LUMEN_FORMULA_MAX_VALUES,
} from "manager/config/lumen.config";
import { VALIDATION_MESSAGES } from "shared/config/constants";

import { LUMEN_CONDITION_KEYS } from "../NewCollectionsFilterModal/utils";

export const getBaseConditionPath = (idx) => `conditions[${idx}]`;

export const getConditionPropPath = (idx, prop) =>
  `${getBaseConditionPath(idx)}[${prop}]`;

export const CONDITIONS_VALIDATION_SCHEMA = Yup.object().shape({
  type: Yup.string().required("The filter type is required"),
  conditions: Yup.array().of(
    Yup.lazy((cond) => {
      if (cond.conditionType === LUMEN_FILTER_CONDITION_TYPE.TIME_PERIOD) {
        return Yup.object().shape({
          timeOperator: Yup.string().required("The time operator is required"),
          timeValue: Yup.number().when("timeOperator", {
            is: LUMEN_FILTER_CONDITION_AMOUNT_TIME_OPERATOR_TYPE.BETWEEN,
            then: Yup.number().nullable(),
            otherwise: Yup.number()
              .nullable()
              .required(VALIDATION_MESSAGES.fieldRequired)
              .positive("Field value should be positive.")
              .lessThan(
                LUMEN_FORMULA_MAX_VALUES.TIME.maxValue,
                LUMEN_FORMULA_MAX_VALUES.TIME.message
              ),
          }),
          timeUnit: Yup.string().when("timeOperator", {
            is: LUMEN_FILTER_CONDITION_AMOUNT_TIME_OPERATOR_TYPE.BETWEEN,
            then: Yup.string().nullable(),
            otherwise: Yup.string().required(VALIDATION_MESSAGES.fieldRequired),
          }),
          startDate: Yup.date()
            .nullable()
            .when("timeOperator", {
              is: LUMEN_FILTER_CONDITION_AMOUNT_TIME_OPERATOR_TYPE.BETWEEN,
              then: Yup.date()
                .nullable()
                .required(VALIDATION_MESSAGES.fieldRequired),
            }),
          endDate: Yup.date()
            .nullable()
            .when("timeOperator", {
              is: LUMEN_FILTER_CONDITION_AMOUNT_TIME_OPERATOR_TYPE.BETWEEN,
              then: Yup.date()
                .nullable()
                .min(Yup.ref("startDate"), VALIDATION_MESSAGES.endDate)
                .required(VALIDATION_MESSAGES.fieldRequired),
            }),
        });
      }

      if (
        cond.conditionType === LUMEN_FILTER_CONDITION_TYPE.AMOUNT ||
        cond.conditionType === LUMEN_FILTER_CONDITION_TYPE.JUDGEMENT_AMOUNT
      ) {
        return Yup.object().shape({
          amountOperator: Yup.string().required(
            "The amount operator is required"
          ),
          amountValue: Yup.number()
            .nullable()
            .required("The amount value is required")
            .positive("The amount value should be positive")
            .lessThan(
              LUMEN_FORMULA_MAX_VALUES.AMOUNT.maxValue,
              LUMEN_FORMULA_MAX_VALUES.AMOUNT.message
            ),
        });
      }

      if (
        cond.conditionType === LUMEN_FILTER_CONDITION_TYPE.PUBLIC_RECORD_TYPE
      ) {
        return Yup.object().shape({
          subtype: Yup.string().required("The public record type is required"),
        });
      }

      if (
        cond.conditionType === LUMEN_FILTER_CONDITION_TYPE.KEYWORDS &&
        cond.conditionSubType === LUMEN_FILTER_CONDITION_SUB_TYPE.EVICTIONS
      ) {
        return Yup.object().shape({
          keywords: Yup.array().when("other", {
            is: true,
            then: Yup.array().of(
              Yup.string().max(255, "Keyword must be 255 characters at most")
            ),
            otherwise: Yup.array()
              .of(
                Yup.string().max(255, "Keyword must be 255 characters at most")
              )
              .min(1, "At least one keyword is required"),
          }),
          other: Yup.boolean(),
          otherKeywords: Yup.array().when("other", {
            is: true,
            then: Yup.array()
              .of(
                Yup.string().max(255, "Keyword must be 255 characters at most")
              )
              .min(1, "At least one keyword is required"),
            otherwise: Yup.array().of(
              Yup.string().max(255, "Keyword must be 255 characters at most")
            ),
          }),
        });
      }

      if (cond.conditionType === LUMEN_FILTER_CONDITION_TYPE.KEYWORDS) {
        return Yup.object().shape({
          keywords: Yup.array()
            .of(Yup.string().max(255, "Keyword must be 255 characters at most"))
            .min(1, "At least one keyword is required"),
        });
      }

      if (
        cond.conditionType ===
        LUMEN_FILTER_CONDITION_TYPE.CRIMINAL_RECORD_SEVERITY
      ) {
        return Yup.object().shape({
          severity: Yup.string().required("The severity is required"),
        });
      }

      if (
        cond.conditionType === LUMEN_FILTER_CONDITION_TYPE.NUMBER_OF_RECORDS
      ) {
        return Yup.object().shape({
          numOfRecordsOperator: Yup.string().required(
            "The operator is required"
          ),
          numOfRecordsValue: Yup.number()
            .nullable()
            .required("The value is required")
            .positive("The value should be positive")
            .lessThan(
              LUMEN_FORMULA_MAX_VALUES.NUM_RECORDS.maxValue,
              LUMEN_FORMULA_MAX_VALUES.NUM_RECORDS.message
            ),
        });
      }

      return Yup.object();
    })
  ),
});

export const COLLECTIONS_CONDITIONS_VALIDATION_SCHEMA = Yup.object().shape({
  conditions: Yup.array()
    .of(
      Yup.lazy((cond) => {
        if (cond.conditionType === LUMEN_FILTER_CONDITION_TYPE.TIME_PERIOD) {
          return Yup.object().shape({
            conditionSubType: Yup.string().required(
              VALIDATION_MESSAGES.fieldRequired
            ),
            timeOperator: Yup.string().required(
              VALIDATION_MESSAGES.fieldRequired
            ),
            timeValue: Yup.number().when("timeOperator", {
              is: LUMEN_CONDITION_KEYS.BETWEEN,
              then: Yup.number().nullable(),
              otherwise: Yup.number()
                .nullable()
                .required(VALIDATION_MESSAGES.fieldRequired)
                .positive("Field value should be positive.")
                .lessThan(
                  LUMEN_FORMULA_MAX_VALUES.TIME.maxValue,
                  LUMEN_FORMULA_MAX_VALUES.TIME.message
                ),
            }),
            timeUnit: Yup.string().when("timeOperator", {
              is: LUMEN_CONDITION_KEYS.BETWEEN,
              then: Yup.string().nullable(),
              otherwise: Yup.string().required(
                VALIDATION_MESSAGES.fieldRequired
              ),
            }),
            startDate: Yup.date()
              .nullable()
              .when("timeOperator", {
                is: LUMEN_CONDITION_KEYS.BETWEEN,
                then: Yup.date()
                  .nullable()
                  .required(VALIDATION_MESSAGES.fieldRequired),
              }),
            endDate: Yup.date()
              .nullable()
              .when("timeOperator", {
                is: LUMEN_CONDITION_KEYS.BETWEEN,
                then: Yup.date()
                  .nullable()
                  .min(Yup.ref("startDate"), VALIDATION_MESSAGES.endDate)
                  .required(VALIDATION_MESSAGES.fieldRequired),
              }),
          });
        }

        if (cond.conditionType === LUMEN_FILTER_CONDITION_TYPE.AMOUNT) {
          return Yup.object().shape({
            conditionSubType: Yup.string().required(
              VALIDATION_MESSAGES.fieldRequired
            ),
            amountOperator: Yup.string().required(
              VALIDATION_MESSAGES.fieldRequired
            ),
            amountValue: Yup.string()
              .nullable()
              .required(VALIDATION_MESSAGES.fieldRequired),
          });
        }

        if (cond.conditionType === LUMEN_FILTER_CONDITION_TYPE.KEYWORDS) {
          return Yup.object().shape({
            conditionSubType: Yup.string().required(
              VALIDATION_MESSAGES.fieldRequired
            ),
            keywords: Yup.array()
              .of(
                Yup.string().max(255, "Keyword must be 255 characters at most.")
              )
              .min(1, "At least one keyword is required."),
          });
        }

        return Yup.object();
      })
    )
    .min(1, "At lease one filter is required"),
});

export const getConditionAmountOfTimeOperatorOptions = (
  showBetween = false
) => {
  const options = [
    {
      key: LUMEN_FILTER_CONDITION_AMOUNT_TIME_OPERATOR_TYPE.MORE_THAN,
      label: LUMEN_FILTER_CONDITION_AMOUNT_TIME_OPERATOR_LABELS.MORE_THAN,
    },
    {
      key: LUMEN_FILTER_CONDITION_AMOUNT_TIME_OPERATOR_TYPE.LESS_THAN,
      label: LUMEN_FILTER_CONDITION_AMOUNT_TIME_OPERATOR_LABELS.LESS_THAN,
    },
  ];

  if (showBetween) {
    options.push({
      key: LUMEN_FILTER_CONDITION_AMOUNT_TIME_OPERATOR_TYPE.BETWEEN,
      label: LUMEN_FILTER_CONDITION_AMOUNT_TIME_OPERATOR_LABELS.BETWEEN,
    });
  }

  return options;
};
