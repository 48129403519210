import React from "react";

import { useHistory, useParams } from "react-router-dom";

import {
  DeactivateEmployeeModal,
  CreateUnitModal,
  EditEmployeeModal,
  UnassignEmployeeFromPropertyModal,
  UnassignEmployeeFromPortfolioModal,
  AssignEmployeeToPropertiesModal,
} from "manager/components/Modal";
import ROUTES from "manager/config/routes";
import {
  useGetEmployee,
  useGetEmployeePortfoliosProperties,
} from "manager/hooks/api";
import useModal from "shared/hooks/useModal";
import { transformRoute } from "shared/utils/routing";

import Employee from "./Employee";
import { CreateUnitProps, UnassignFromPropertyProps } from "./interfaces";

interface EmployeeContainerProps {
  backLink: any;
}

const EmployeeContainer = ({ backLink }: EmployeeContainerProps) => {
  const history = useHistory();
  const { openModalDialog } = useModal();
  const { id }: { id?: string | number } = useParams();
  const employeeId = Number(id);

  const { portfolios, isPortfoliosLoading } =
    useGetEmployeePortfoliosProperties(employeeId);

  const { employee, isEmployeeLoading, refetchEmployee } =
    useGetEmployee(employeeId);

  const unassignFromProperty = (context: UnassignFromPropertyProps) =>
    openModalDialog(UnassignEmployeeFromPropertyModal, context);
  const unassignFromPortfolio = (context: UnassignFromPropertyProps) =>
    openModalDialog(UnassignEmployeeFromPortfolioModal, context);
  const deactivate = () =>
    openModalDialog(DeactivateEmployeeModal, {
      employeeId,
      name: employee?.user?.firstName,
    });

  const openPropertySettings = (propertyId) =>
    history.push(transformRoute(ROUTES.property, { id: propertyId }));
  const assignToProperties = () =>
    openModalDialog(AssignEmployeeToPropertiesModal, {
      employeeId,
      firstName: employee?.user?.firstName,
      lastName: employee?.user?.lastName,
    });
  const createUnit = (context: CreateUnitProps) =>
    openModalDialog(CreateUnitModal, context);

  const goToProperyPage = (key: string) =>
    history.push(ROUTES.property.replace(":id", key));

  const editEmployee = () =>
    openModalDialog(EditEmployeeModal, {
      employeeData: {
        employeeId,
        firstName: employee.user?.firstName,
        lastName: employee.user?.lastName,
        email: employee.user?.email,
      },
    }).afterClose.then(() => refetchEmployee());

  return (
    <Employee
      portfolios={portfolios}
      portfoliosLoading={isPortfoliosLoading}
      employee={employee}
      employeeLoading={isEmployeeLoading}
      backLink={backLink}
      unassignFromProperty={unassignFromProperty}
      unassignFromPortfolio={unassignFromPortfolio}
      deactivate={deactivate}
      openPropertySettings={openPropertySettings}
      assignToProperties={assignToProperties}
      createUnit={createUnit}
      goToProperyPage={goToProperyPage}
      editEmployee={editEmployee}
    />
  );
};

export default EmployeeContainer;
