import React, { useState } from "react";

import { useMediaQuery } from "react-responsive";

import FlexContainer from "shared/components/FlexContainer";
import { Select } from "shared/components/Form";
import Icon from "shared/components/Icon";
import {
  MediumTitle,
  RegularText,
  SmallText,
} from "shared/components/Typography";
import { BREAKPOINT_RESOLUTIONS } from "shared/config/constants";

import { MetricItems, LastUpdated } from "./DashboardHeader.util";
import { Tile, TileInner, TileDivider } from "./styled";

interface DashboardHeaderProps {
  companyName: string;
  companyAddress: string;
  dealsSubmitted: number;
  dealsApproved: number;
  conversionRate: number;
  updatedAt: string;
  shouldExpand: boolean;
  dashboardPeriods: {
    id: number;
    value: string;
  }[];
  selectedPeriod: number;
  setSelectedPeriod: (value: number) => void;
}

const DashboardHeader = ({
  companyName,
  companyAddress,
  dealsSubmitted,
  dealsApproved,
  conversionRate,
  updatedAt,
  shouldExpand,
  dashboardPeriods,
  selectedPeriod,
  setSelectedPeriod,
}: DashboardHeaderProps) => {
  const [isExpanded, setExpanded] = useState(shouldExpand);
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT_RESOLUTIONS.md });

  const ChevronIcon =
    isExpanded && isMobile
      ? Icon.DropdownButtonChevronUpIcon
      : Icon.DropdownButtonChevronDownIcon;

  const toggleExpandedSection = () => setExpanded(!isExpanded);

  return (
    <Tile>
      <TileInner className="tile-header">
        <FlexContainer alignItems="center" justifyContent="space-between">
          <FlexContainer className="title-container">
            <MediumTitle className="title">{companyName}</MediumTitle>
            <SmallText className="address" extraStrong>
              <Icon.AddressIcon />
              {companyAddress}
            </SmallText>
          </FlexContainer>
          {dashboardPeriods && (
            <Select
              id="dashboard-period"
              name="dashboard-period"
              className="dashboard-select"
              value={selectedPeriod}
              onChange={(x) => setSelectedPeriod(x as number)}
              violetTheme
            >
              {dashboardPeriods.map((period) => (
                <Select.Option key={period.id} value={period.id}>
                  {period.value}
                </Select.Option>
              ))}
            </Select>
          )}
        </FlexContainer>
      </TileInner>
      <TileDivider className="divider" alt horizontal />
      <TileInner className="tile-body">
        {!isMobile && (
          <>
            <LastUpdated updatedAt={updatedAt} />
            <MetricItems
              dealsSubmitted={dealsSubmitted || 0}
              dealsApproved={dealsApproved || 0}
              conversionRate={conversionRate}
            />
          </>
        )}
        {isMobile && (
          <>
            <FlexContainer alignItems="center" justifyContent="space-between">
              <RegularText className="dropdown-title">Metrics</RegularText>
              <ChevronIcon
                className="dropdown-chevron"
                onClick={toggleExpandedSection}
              />
            </FlexContainer>
            {isExpanded && (
              <>
                <MetricItems
                  dealsSubmitted={dealsSubmitted}
                  dealsApproved={dealsApproved}
                  conversionRate={conversionRate}
                />
                <LastUpdated updatedAt={updatedAt} />
              </>
            )}
          </>
        )}
      </TileInner>
    </Tile>
  );
};

export default DashboardHeader;
