import styled from "@emotion/styled";

import { Typography } from "antd";

import Container from "shared/components/Container";
import FlexContainer from "shared/components/FlexContainer";
import { UnderlineLink } from "shared/components/Links";
import { Modal } from "shared/components/Modal";
import {
  BREAKPOINT_RESOLUTIONS,
  COLORS,
  MEDIA_QUERIES,
} from "shared/config/constants";

const { Text } = Typography;

const AuthFormContainer = styled(Container)`
  @media (min-width: ${BREAKPOINT_RESOLUTIONS.sm}px) {
    row-gap: 32px;
  }
  row-gap: 15px;
  span.ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: ${COLORS.blueViolet};
      border-color: ${COLORS.blueViolet};
      &:after: {
        border-color: ${COLORS.blueViolet};
      }
    }
  }
`;

const AuthContainer = styled.div`
  background-color: ${COLORS.white};
  width: 100%;
  height: auto;
  min-height: 100%;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-content: center;
`;

const Content = styled.div`
  margin-left: auto;
  margin-right: auto;
  @media (max-width: ${BREAKPOINT_RESOLUTIONS.xs}px) {
    margin-left: 30px;
    margin-right: 30px;
  }
  @media (min-width: ${BREAKPOINT_RESOLUTIONS.sm}px) {
    width: 450px;
  }
`;

const QuestionsContent = styled.div`
  font-family: Roboto, sans-serif;
  border: 1px solid #000000;
  padding-top: 40px;
  padding-left: 30px;
  padding-right: 30px;
  background-color: #eeeeee;
`;

const QuestionContainer = styled.div`
  label {
    color: #2d2943;
    margin-bottom: 20px;
  }
  .ant-radio-group {
    display: flex;
    flex-direction: column;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: #2d2943; !important ;
  }

  .ant-radio-checked .ant-radio-inner:after {
    background-color: #2d2943;
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: #2d2943;
  }

  @media (min-width: ${BREAKPOINT_RESOLUTIONS.md}px) {
    width: 600px;
  }
`;

const NoticeContent = styled.div`
  margin-top: 40px;
  margin-left: 40px;
  margin-right: 40px;
  @media (max-width: ${BREAKPOINT_RESOLUTIONS.xs}px) {
    margin-left: 30px;
    margin-right: 30px;
  }
  @media (min-width: ${BREAKPOINT_RESOLUTIONS.sm}px) {
    width: 500px;
  }
`;

const ButtonsFooter = styled.div`
  display: flex;
  margin-top: 40px;
  margin-left: 20px;
  margin-bottom: 100px;

  button.ant-btn-primary {
    padding: 25px 28px;
  }

  button.ant-btn-secondary {
    margin-right: 20px;
    padding-top: 25px;
    padding-bottom: 25px;
    span {
      margin-right: 0;
    }
  }
`;

const HeaderContainer = styled.div`
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 40px;
`;

const HeaderSide = styled.div`
  width: 100px;
`;

const Header = styled.div`
  margin-bottom: 32px;

  ${MEDIA_QUERIES.w.ltLg} {
    margin-bottom: 40px;
  }
`;

const ExperianContent = styled.div`
  margin-bottom: 32px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  @media (min-width: ${BREAKPOINT_RESOLUTIONS.md}px) {
    width: 800px;
  }
`;

const Message = styled(Text)`
  font-family: Roboto, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.3;
  flex: 1;
`;

const IconWrapper = styled.div`
  height: 40px;
  width: 40px;
`;

const QuestionsHeader = styled.div`
  font-family: Roboto, sans-serif;
  text-transform: uppercase;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  color: white;
  background-color: #000000;
`;

const Title = styled.h1`
  @media (min-width: ${BREAKPOINT_RESOLUTIONS.sm}px) {
    margin-bottom: 24px !important;
  }
  margin-bottom: 12px !important;
  font-size: 28px !important;
  line-height: 1.3 !important;
`;

const Subtitle = styled.p`
  font-size: 15px;
  font-weight: 300;
`;

const FooterActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 24px;
`;

const AuthFooter = styled.div`
  display: flex;
  column-gap: 4px;
  font-size: 12px;
  margin-top: 24px;
`;

const StyledLink = styled(UnderlineLink)`
  color: ${COLORS.violetTheme.lightViolet2} !important;
  border-bottom-color: ${COLORS.violetTheme.lightViolet2} !important;
`;

const BottomLinkContainer = styled.div`
  margin-top: 80px;
`;

const CenteredFlexContainer = styled(FlexContainer)`
  column-gap: 16px;
  z-index: 1;
  svg {
    margin-left: -40px;
    margin-top: 7px;
  }
`;

interface ModalProps {
  isMobile: boolean;
}

const StyledModal = styled(Modal)<ModalProps>`
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    width: 480px;
    padding: 32px;
    border-radius: 30px;
    ${({ isMobile }) =>
      `
      ${MEDIA_QUERIES.w.ltMd} {
        width: ${isMobile ? "100%" : "480px"}
      }
    `}
  }
  .ant-modal-header {
    padding: 0;
  }
  .ant-modal-footer {
    padding: 0;
  }
`;

const ExperianLogo = styled.img`
  margin-bottom: 30px;
`;

export {
  AuthFormContainer,
  AuthContainer,
  BottomLinkContainer,
  ExperianLogo,
  Content,
  HeaderContainer,
  HeaderSide,
  NoticeContent,
  QuestionsContent,
  QuestionsHeader,
  QuestionContainer,
  Header,
  Title,
  IconWrapper,
  Subtitle,
  FooterActions,
  Message,
  ExperianContent,
  AuthFooter,
  StyledLink,
  ButtonsFooter,
  CenteredFlexContainer,
  StyledModal,
};
