import React, { useState } from "react";

import PropTypes from "prop-types";

import { basicPropertyPropType } from "shared/utils/propTypes";

import { sectionType } from "../../shared/propTypes";
import {
  veroUnitsPropType,
  yardiUnitsPropType,
} from "../../shared/yardi.config";

import {
  useUnitConfigSection,
} from "./configureYardiPropertySection.util";
import {
  Section,
  SectionTitle,
  SectionSubTitle,
  RowLabelContainer,
} from "./styled";
import { YardiUnitsConfigSection } from "./YardiUnitsConfigSection";

const YardiSectionWaitUnits = ({
  section,
  veroUnits,
  veroUnitsMap,
  yardiUnits,
  veroProperty,
  yardiProperty,
  firstColWidth,
  loading,
  provider,
}) => {
  const [checkedItems, setCheckedItems] = useState([]);

  const buildPropPath = (yardiUnit) =>
    `waitUnits[${yardiUnit.id}]`;

  const buildLabel = (yardiUnit) => (
    <RowLabelContainer>
      <span className="label-without-input" title={yardiUnit.name}>
        {yardiUnit.name}
      </span>
    </RowLabelContainer>
  );

  const { tableItems, autoCreateUnits, isAutocreating } = useUnitConfigSection(
    provider,
    buildPropPath,
    buildLabel,
    veroUnits,
    veroUnitsMap,
    yardiUnits,
    veroProperty,
    yardiProperty,
    checkedItems
  );

  return (
    <>
      <Section height="auto" noPaddingBottom>
        <SectionTitle>{section.name}</SectionTitle>
        <SectionSubTitle>
          <div>
            Yardi uses Wait Units as placeholders. This allows an applicant to
            apply without having a unit available right then.
          </div>
        </SectionSubTitle>
        <YardiUnitsConfigSection
          sectionId={section.anchor}
          items={tableItems}
          popoverText="When auto creating units in VERO we will use the unit name provided by the External Provider."
          checkedItems={checkedItems}
          setCheckedItems={setCheckedItems}
          autoCreateUnits={autoCreateUnits}
          firstColWidth={firstColWidth}
          loading={loading || isAutocreating}
        />
      </Section>
    </>
  );
};

YardiSectionWaitUnits.propTypes = {
  section: sectionType.isRequired,
  veroUnits: veroUnitsPropType,
  veroUnitsMap: PropTypes.object.isRequired,
  yardiUnits: yardiUnitsPropType,
  veroProperty: basicPropertyPropType,
  yardiProperty: basicPropertyPropType,
  firstColWidth: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  provider: PropTypes.string.isRequired,
};

YardiSectionWaitUnits.defaultProps = {
  veroUnits: [],
  yardiUnits: [],
  veroProperty: null,
  yardiProperty: null,
  loading: false,
};

export default YardiSectionWaitUnits;
