

import {
  ApplicationSummaryCard,
  CardSize,
  CardStatus,
} from "shared/components/ApplicationSummaryCard";
import {
  ApplicationSummaryTooltip,
  TooltipColumnWidth,
} from "shared/components/ApplicationSummaryTooltip";
import Tooltip from "shared/components/Tooltip";
import { ApplicantType } from "shared/graphql";

import { printDollarsFromCents } from "shared/utils/dollar-print";
import { CREDIT_SCORE_SUCCESS, CREDIT_SCORE_WARN } from "../utils";

export interface CreditScoreCardProps {
  skipped?: boolean;
  skippedReason?: string;
  creditScore?: number;
  latePayment30?: number;
  latePayment60?: number;
  latePayment90?: number;
  creditBalance?: number;
  creditAvailablePercent?: number;
  collectionAccounts?: number;
  collectionTotal?: number;
  onClick?: () => void;
  applicantType?: ApplicantType;
  lumenEnabled?: boolean;
  useCreditScore?: boolean;
  useCollections?: boolean;
  onlyGuarantors?: boolean;
  applicantonlyGuarantors?: boolean;
  guarantorsAndApplicant?: boolean;
  creditLumenRecords?: {
    crucial: boolean;
    safetyNet: boolean;
    negativeScore: boolean;
    idealScore?: boolean;
    goodScore?: boolean;
  };
  collectionsLumenRecords?: {
    crucial: boolean;
    safetyNet: boolean;
  };
}

const CREDIT_SCORE = "CREDIT_SCORE";
const COLLECTIONS = "COLLECTIONS";

export const CreditScoreCard = ({
  skipped,
  skippedReason,
  creditScore,
  latePayment30,
  latePayment60,
  latePayment90,
  creditBalance,
  creditAvailablePercent,
  collectionAccounts,
  collectionTotal,
  onClick,
  lumenEnabled,
  useCreditScore,
  useCollections,
  onlyGuarantors,
  applicantonlyGuarantors,
  creditLumenRecords,
  collectionsLumenRecords,
}: CreditScoreCardProps) => {
  const collectionProps = () => {
    const { crucial: crucialCollection, safetyNet: safetyNetCollection } =
      collectionsLumenRecords;

    if (crucialCollection) {
      return {
        title: creditScore,
        status: CardStatus.error,
      };
    }
    if (safetyNetCollection) {
      return {
        title: creditScore,
        status: CardStatus.warn,
      };
    }

    return {
      title: creditScore,
      status: CardStatus.lumenSuccess,
    };
  };
  const creditScoreProps = () => {
    const {
      negativeScore: crucialCreditNegative,
      crucial: crucialCreditScore,
      safetyNet: safetyNetCreditScore,
      idealScore,
      goodScore,
    } = creditLumenRecords;
    if (crucialCreditNegative) {
      if (safetyNetCreditScore) {
        return {
          title: creditScore,
          status: CardStatus.warn,
        };
      }
      return {
        title: creditScore,
        status: CardStatus.error,
      };
    }

    if (idealScore) {
      if (crucialCreditScore) {
        return {
          title: creditScore,
          status: CardStatus.error,
        };
      }
      if (safetyNetCreditScore) {
        return {
          title: creditScore,
          status: CardStatus.warn,
        };
      }
      return {
        title: creditScore,
        status: CardStatus.lumenSuccess,
      };
    }
    if (goodScore) {
      if (crucialCreditScore) {
        return {
          title: creditScore,
          status: CardStatus.error,
        };
      }
      if (safetyNetCreditScore) {
        return {
          title: creditScore,
          status: CardStatus.warn,
        };
      }
      return {
        title: creditScore,
        status: CardStatus.lumenGood,
      };
    }

    if (safetyNetCreditScore) {
      return {
        title: creditScore,
        status: CardStatus.warn,
      };
    }

    return {
      title: creditScore,
      status: CardStatus.error,
    };
  };

  const creditScoreNone = () => {
    const {
      negativeScore,
      crucial: crucialCreditScore,
      safetyNet: safetyNetCreditScore,
      idealScore,
      goodScore,
    } = creditLumenRecords;

    return (
      !crucialCreditScore &&
      !safetyNetCreditScore &&
      !negativeScore &&
      !idealScore &&
      !goodScore
    );
  };

  const mostNegative = () => {
    const creditProps = creditScoreProps();
    const collectionsProps = collectionProps();

    if (creditScoreNone()) {
      return COLLECTIONS;
    }

    if (creditProps.status === CardStatus.error) {
      return CREDIT_SCORE;
    }

    if (collectionsProps.status === CardStatus.error) {
      return COLLECTIONS;
    }
    if (creditProps.status === CardStatus.warn) {
      return CREDIT_SCORE;
    }

    if (collectionsProps.status === CardStatus.warn) {
      return COLLECTIONS;
    }

    if (creditProps.status === CardStatus.lumenGood) {
      return CREDIT_SCORE;
    }

    return CREDIT_SCORE || COLLECTIONS;
  };

  const additionalProps = () => {
    if (lumenEnabled) {
      if (skipped) {
        return {
          title: "Skipped",
          status: CardStatus.error,
        };
      }
      if (!creditScore) {
        return {
          title: "Not Enough Info",
          status: CardStatus.unavailable,
        };
      }

      if (applicantonlyGuarantors) {
        return {
          title: creditScore,
          status: CardStatus.lumenSuccess,
        };
      }

      if (useCollections && useCreditScore) {
        const creditProps = creditScoreProps();
        const collectionsProps = collectionProps();

        const negativeStatus = mostNegative();
        if (negativeStatus === CREDIT_SCORE) {
          return creditProps;
        }

        if (negativeStatus === COLLECTIONS) {
          return collectionsProps;
        }

        return creditProps || collectionsProps;
      }

      if (useCreditScore) {
        return creditScoreProps();
      }

      if (useCollections) {
        return collectionProps();
      }
    }

    if (skipped || !creditScore) {
      return {
        title: skipped ? "Not Completed" : "Not Enough Info",
        status: CardStatus.unavailable,
      };
    }

    if (creditScore > CREDIT_SCORE_SUCCESS) {
      return {
        title: creditScore,
        status: CardStatus.success,
      };
    }
    if (creditScore > CREDIT_SCORE_WARN) {
      return {
        title: creditScore,
        status: CardStatus.warn,
      };
    }
    return {
      title: creditScore,
      status: CardStatus.error,
    };
  };

  const hideColor = () => {
    if (!useCreditScore && !useCollections) {
      return true;
    }

    if (mostNegative() === CREDIT_SCORE && useCreditScore && onlyGuarantors) {
      return true;
    }

    return false;
  };

  const card = (
    <div>
      <ApplicationSummaryCard
        subtitle="Credit Score"
        size={CardSize.Large}
        onClick={onClick}
        hideColor={lumenEnabled && hideColor()}
        {...additionalProps()}
      />
    </div>
  );

  return skipped ? (
    <Tooltip title={skippedReason}>{card}</Tooltip>
  ) : (
    <ApplicationSummaryTooltip
      layout={[
        {
          title: "30/60/90 Late Pymt:",
          subtitle: `${latePayment30 ?? 0}/${latePayment60 ?? 0}/${
            latePayment90 ?? 0
          }`,
          width: TooltipColumnWidth.full,
        },
        {
          title: "Tot. Credit Bal:",
          subtitle: printDollarsFromCents(creditBalance),
          width: TooltipColumnWidth.half,
        },
        {
          title: "Credit Available:",
          subtitle: creditAvailablePercent
            ? `${creditAvailablePercent.toFixed(2)}%`
            : "-",
          width: TooltipColumnWidth.half,
        },
        {
          title: "Collection Accts:",
          subtitle: collectionAccounts ?? 0,
          width: TooltipColumnWidth.half,
        },
        {
          title: "Collections Total:",
          subtitle: printDollarsFromCents(collectionTotal ?? 0),
          width: TooltipColumnWidth.half,
        },
      ]}
    >
      {card}
    </ApplicationSummaryTooltip>
  );
};
