const INTEGRATION_TYPES = Object.freeze({
  YARDI: "YARDI",
  BLUEMOON: "BLUEMOON",
  ENTRATA: "ENTRATA",
  REAL_PAGE: "REAL-PAGE",
});

export enum IntegrationProvider {
  yardi = "yardi",
  bluemoon = "bluemoon",
  entrata = "entrata",
  realPage = "real-page",
}

const YARDI_SECURITY_DEPOSIT_PREFERENCES = Object.freeze({
  RENT: "Rent",
  VERO: "VERO",
  YARDI: "YARDI",
});

const PERSONA_VERIFICATION_TYPES = Object.freeze({
  DATABASE: "verification/database",
  PHONE_2FA: "verification/phone-number",
  GOVERNMENT_ID: "verification/government-id",
  SELFIE: "verification/selfie",
  DOCUMENT: "verification/document",
});

const PERSONA_VERIFICATION_TYPES_LABELS = Object.freeze({
  [PERSONA_VERIFICATION_TYPES.DATABASE]: "Database",
  [PERSONA_VERIFICATION_TYPES.PHONE_2FA]: "Phone number",
  [PERSONA_VERIFICATION_TYPES.GOVERNMENT_ID]: "Government ID",
  [PERSONA_VERIFICATION_TYPES.SELFIE]: "Selfie",
  [PERSONA_VERIFICATION_TYPES.DOCUMENT]: "Document",
});

const PERSONA_VERIFICATION_PREFIXES = {
  [PERSONA_VERIFICATION_TYPES.GOVERNMENT_ID]: "id_",
  [PERSONA_VERIFICATION_TYPES.SELFIE]: "selfie_",
  [PERSONA_VERIFICATION_TYPES.DATABASE]: "database_",
};

// This constants represents "persona_inquiry_status" property returned by the API
// it should be renamed in the future to PERSONA_INQUIRY_VERIFICATION_STATUS
const PERSONA_VERIFICATION_STATUS = Object.freeze({
  COMPLETED: "completed",
  PASSED: "passed",
  FAILED: "failed",
  EXPIRED: "expired",
});

// This constants represents "persona_status" property returned by the API
// it should be renamed in the future to PERSONA_VERIFICATION_STATUS
const PERSONA_STATUS = Object.freeze({
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
  EXPIRED: "EXPIRED",
  PENDING: "NOT_STARTED",
  IN_PROGRESS: "IN_PROGRESS",
});

export enum PERSONA_VERIFICATION_STATUS_TYPES {
  COMPLETED = "completed",
  FAILED = "failed",
  EXPIRED = "expired",
}

const VERIFICATION_OF_RESIDENCY_STATUS = Object.freeze({
  SUBMITTED: "submitted",
  WAITING: "waiting",
  NOTIFIED: "notified",
  VERIFIED: "verified",
  STARTED: "started",
});

export enum VERIFICATION_OF_RESIDENCY_STATUS_TYPES {
  SUBMITTED = "submitted",
  WAITING = "waiting",
  NOTIFIED = "notified",
  VERIFIED = "verified",
  STARTED = "started",
}

const PERSONA_ID_CLASS = Object.freeze({
  CITIZEN_CERTIFICATE: "cct",
  CONSULAR_ID: "cid",
  DRIVER_LICENSE: "dl",
  HEALTHCARE_INSURANCE_CARD: "hic",
  NATIONAL_ID: "id",
  INTERNAL_PASSPORT: "ipp",
  LONG_TERM_PASS_CARD: "ltpass",
  MILITARY_ID: "mid",
  MY_NUBMER_CARD: "myn",
  NBI_CARD: "nbi",
  NRIC: "nric",
  OFW_ID: "ofw",
  PAN_CARD: "pan",
  POSTAL_ID: "pid",
  PASSPORT: "pp",
  PASSPORT_CARD: "ppc",
  PERMANENT_RESIDENT_CARD: "pr",
  RESIDENCY_PERMIT: "rp",
  SOCIAL_SECURITY_ID: "sss",
  UMID: "umid",
  VOTER_ID: "vid",
  VISA: "visa",
  WORK_PERMIT: "wp",
});

export enum PersonaIdClass {
  CITIZEN_CERTIFICATE = "cct",
  CONSULAR_ID = "cid",
  DRIVER_LICENSE = "dl",
  HEALTHCARE_INSURANCE_CARD = "hic",
  NATIONAL_ID = "id",
  INTERNAL_PASSPORT = "ipp",
  LONG_TERM_PASS_CARD = "ltpass",
  MILITARY_ID = "mid",
  MY_NUBMER_CARD = "myn",
  NBI_CARD = "nbi",
  NRIC = "nric",
  OFW_ID = "ofw",
  PAN_CARD = "pan",
  POSTAL_ID = "pid",
  PASSPORT = "pp",
  PASSPORT_CARD = "ppc",
  PERMANENT_RESIDENT_CARD = "pr",
  RESIDENCY_PERMIT = "rp",
  SOCIAL_SECURITY_ID = "sss",
  UMID = "umid",
  VOTER_ID = "vid",
  VISA = "visa",
  WORK_PERMIT = "wp",
}

// NOTE: goran - Taken over from https://docs.withpersona.com/reference/verifications#government-id-verifications
const PERSONA_ID_CLASS_LABELS = Object.freeze({
  [PERSONA_ID_CLASS.CITIZEN_CERTIFICATE]: "Citizen Certificate",
  [PERSONA_ID_CLASS.CONSULAR_ID]: "Consular ID",
  [PERSONA_ID_CLASS.DRIVER_LICENSE]: "Driver's License",
  [PERSONA_ID_CLASS.HEALTHCARE_INSURANCE_CARD]: "Healthcare Insurance Card",
  [PERSONA_ID_CLASS.NATIONAL_ID]: "National ID",
  [PERSONA_ID_CLASS.INTERNAL_PASSPORT]: "Internal Passport",
  [PERSONA_ID_CLASS.LONG_TERM_PASS_CARD]: "Long-Term Pass Card",
  [PERSONA_ID_CLASS.MILITARY_ID]: "Military ID",
  [PERSONA_ID_CLASS.MY_NUBMER_CARD]: "My Number Card",
  [PERSONA_ID_CLASS.NBI_CARD]: "NBI Card",
  [PERSONA_ID_CLASS.NRIC]: "NRIC",
  [PERSONA_ID_CLASS.OFW_ID]: "OFW ID",
  [PERSONA_ID_CLASS.PAN_CARD]: "PAN card",
  [PERSONA_ID_CLASS.POSTAL_ID]: "Postal ID",
  [PERSONA_ID_CLASS.PASSPORT]: "Passport",
  [PERSONA_ID_CLASS.PASSPORT_CARD]: "Passport Card",
  [PERSONA_ID_CLASS.PERMANENT_RESIDENT_CARD]: "Permanent Resident Card",
  [PERSONA_ID_CLASS.RESIDENCY_PERMIT]: "Residency Permit",
  [PERSONA_ID_CLASS.SOCIAL_SECURITY_ID]: "Social Security ID",
  [PERSONA_ID_CLASS.UMID]: "UMID",
  [PERSONA_ID_CLASS.VOTER_ID]: "Voter ID",
  [PERSONA_ID_CLASS.VISA]: "Visa",
  [PERSONA_ID_CLASS.WORK_PERMIT]: "Work Permit",
});

const PERSONA_FILE_TYPE = Object.freeze({
  DOCUMENT_FRONT_PHOTO: "document_front_photo",
  DOCUMENT_BACK_PHOTO: "document_back_photo",
  SELFIE_LEFT_PHOTO: "selfie_left_photo",
  SELFIE_CENTER_PHOTO: "selfie_center_photo",
  SELFIE_RIGHT_PHOTO: "selfie_right_photo",
});

const PERSONA_FILE_TYPE_LABELS = Object.freeze({
  [PERSONA_FILE_TYPE.DOCUMENT_FRONT_PHOTO]: "Document front photo",
  [PERSONA_FILE_TYPE.DOCUMENT_BACK_PHOTO]: "Document back photo",
  [PERSONA_FILE_TYPE.SELFIE_LEFT_PHOTO]: "Selfie left photo",
  [PERSONA_FILE_TYPE.SELFIE_CENTER_PHOTO]: "Selfie center photo",
  [PERSONA_FILE_TYPE.SELFIE_RIGHT_PHOTO]: "Selfie right photo",
});

const PERSONA_CHECK_STATUS = Object.freeze({
  PASSED: "passed",
  FAILED: "failed",
  NOT_APPLICABLE: "not_applicable",
});

// NOTE: goran - taken over from https://docs.withpersona.com/docs/verification-checks#check-list

const PERSONA_CHECKS_REASONS_MAP = Object.freeze({
  id_aamva_database_lookup: {
    reasons: {
      insufficient_match:
        "ID details did not sufficiently match an entry in the AAMVA database.",
    },
  },
  id_account_comparison: {
    reasons: {
      property_conflict:
        "ID details like name or birthdate are inconsistent with the account details.",
      face_mismatch:
        "Face is inconsistent with the selfie found on the account.",
      unsupported_comparison_language:
        "Comparison attributes includes characters from an unsupported language.",
    },
  },
  id_age_comparison: {
    reasons: {
      failed_requirement: " ID details do not meet age requirements.",
    },
  },
  id_age_inconsistency_detection: {
    reasons: {
      inconsistent_age:
        "The age listed on the ID appears to be different than the age estimated from the age portrait.",
    },
  },
  id_barcode_detection: {
    reasons: {
      barcode_not_decoded: "The barcode on the ID cannot be decoded.",
      barcode_not_detected: "The barcode on the ID is not found.",
      barcode_unreadable: "The barcode on the ID is unreadable.",
      barcode_not_found: "The barcode on the ID is not found.",
      back_missing:
        "The image does not contain the whole backside of the card.",
    },
  },
  id_barcode_inconsistency_detection: {
    reasons: {
      format_invalid: "The barcode does not follow ID specifications.",
      subfile_missing: "The barcode is missing data blocks required by the ID.",
      legacy_version_found:
        "The barcode uses a version format that was replaced 10 years before the issue date.",
      required_field_missing: "The barcode is missing required fields.",
      designators_invalid: "The barcode has invalid data block names.",
      field_invalid: "The barcode has fields in invalid formats.",
      issuer_invalid: "The barcode specifies an issuer that is invalid.",
      issuer_mismatch: "The barcode has inconsistent issuer information.",
      revision_invalid:
        "Barcode revision is inconsistent with genuine IDs from the provided issuer.",
    },
  },
  id_blur_detection: {
    reasons: {
      too_blurry: "The submission is blurry.",
    },
  },
  id_color_inconsistency_detection: {
    reasons: {
      too_few_colors:
        "The colors detected on the ID appear to be different than the expected colors.",
    },
  },
  id_compromised_detection: {
    reasons: {
      publicly_available: "The ID is likely a sample ID or publicly available.",
      fraudulent: "The ID is reported as fraudulent.",
      sample_document: "The ID contains a word that indicates it is a sample.",
    },
  },
  id_disallowed_country_detection: {
    reasons: {
      not_allowed_by_configuration:
        "The country of the ID is not allowed based on template requirements.",
      selected_country_mismatch:
        "The country of the ID does not match the country selected by the user.",
    },
  },
  id_disallowed_type_detection: {
    reasons: {
      not_allowed_by_configuration:
        "The ID type is not allowed based on template requirements.",
      disallowed_designation:
        "The ID has a designation that is not allowed based on template requirements.",
      restricted_type:
        "The ID type cannot be collected due to federal compliance requirements.",
      type_mismatch:
        "The ID type detected does not match the ID type the user selected.",
    },
  },
  id_double_side_detection: {
    reasons: {
      side_submitted_twice:
        "The back and front submissions of the ID are of the same side.",
    },
  },
  id_electronic_replica_detection: {
    reasons: {
      digital_icon_detected:
        "The submission may contain an icon which is often found on a replica.",
      digital_text_found:
        "The submission may contain digital text which is often found on a replica.",
      photocopy_found: "The submission may be a replica of a photocopy.",
      replica_detected: "The submission may be a generic replica.",
      screenshot_found: "The submission may be a replica of a screenshot.",
      virtual_camera_detected:
        "The submission may have been submitted using a virtual camera.",
      device_found:
        "The submission may be a replica of an image presented on another electronic device or screen.",
    },
  },
  id_entity_detection: {
    reasons: {
      confidence_score_below_threshold:
        "The submission does not appear to include a government ID.",
      cannot_verify_id:
        "The submission does not appear to include a government ID.",
    },
  },
  id_expired_detection: {
    reasons: {
      expired: "The ID is expired.",
    },
  },
  id_extracted_properties_detection: {
    reasons: {
      property_not_extracted: "A required property was not extracted.",
      no_required_properties:
        "The template is not configured to require extraction of certain properties.",
    },
  },
  id_extraction_inconsistency_detection: {
    reasons: {
      property_mismatch:
        "Details extracted from the front of the ID are different than the details extracted from the barcode.",
      aamva_issuer_mismatch:
        "Details extracted from the front of the ID are different than the details extracted from the AAMVA Issuer.",
    },
  },
  id_fabrication_detection: {
    reasons: {
      camouflage_passport:
        "The submitted ID is believed to have been issued by a no longer existent country.",
      fabricated_id_version:
        "The submitted ID version was unlikely to be valid and issued by a government entity.",
    },
  },
  id_glare_detection: {
    reasons: {
      too_much_glare: "The submission has glare.",
    },
  },
  id_handwriting_detection: {
    reasons: {
      handwriting_found: "The ID appears to have handwriting on it.",
    },
  },
  id_inconsistent_repeat_detection: {
    reasons: {
      inconsistent_details:
        "The face portrait matches that of a previously submitted ID with different details.",
      inconsistent_face:
        "The ID details matches that of a previously submitted ID with a different face portrait.",
    },
  },
  id_inquiry_comparison: {
    reasons: {
      property_conflict:
        "ID details like name or birthdate are inconsistent between different submission attempts.",
      unsupported_comparison_language:
        "Comparison attributes includes characters from an unsupported language.",
    },
  },
  id_mrz_detection: {
    reasons: {
      mrz_not_found:
        "The encoded, machine-readable text on the ID is not found.",
      mrz_incomplete:
        "The full area of the encoded, machine-readable text on the ID was not detected.",
    },
  },
  id_mrz_inconsistency_detection: {
    reasons: {
      mrz_checksum_invalid:
        "The encoded, machine-readable text on the ID has invalid checksum(s).",
    },
  },
  id_po_box_detection: {
    reasons: {
      address_is_po_box: "The address is a PO box.",
    },
  },
  id_portrait_clarity_detection: {
    reasons: {
      portrait_not_clear: "The face portrait is unclear and out of focus.",
    },
  },
  id_portrait_detection: {
    reasons: {
      portrait_not_found: "The submission has no face portrait.",
    },
  },
  id_public_figure_detection: {
    reasons: {
      public_figure_detected:
        "The face portrait appears to match that of a known public figure.",
    },
  },
  id_real_id_detection: {
    reasons: {
      federal_limits_undetected: "ID's compliance type could not be detected.",
      federal_limits_apply:
        "ID does not meet federal guidelines for REAL Enhanced IDs.",
    },
  },
  id_repeat_detection: {
    reasons: {
      repeat:
        "The ID details and face portrait match that of a previously submitted ID on a different account.",
    },
  },
  id_selfie_comparison: {
    reasons: {
      face_mismatch:
        "The face portrait on the ID and the selfie appear to be different faces.",
    },
  },
  id_tamper_detection: {
    reasons: {
      exif_inconsistency:
        "The submission has inconsistencies in the file metadata that imply image tampering.",
      image_manipulated:
        "The submission appears to have been digitally tampered.",
    },
  },
  id_unprocessable_submission_detection: {
    reasons: {
      submission_invalid: "The submission is unprocessable.",
    },
  },
  id_valid_dates_detection: {
    reasons: {
      issuer_rules_inconsistency:
        "The dates on the ID appear to be in violation of issuing authority rules.",
    },
  },
  selfie_account_comparison: {
    reasons: {
      face_mismatch:
        "The selfie is inconsistent with the selfie of the account.",
    },
  },
  selfie_face_covering_detection: {
    reasons: {
      face_covering_detected:
        "The selfie contains a person with a PPE face covering.",
    },
  },
  selfie_glare_detection: {
    reasons: {
      glare: "The submission has glare.",
    },
  },
  selfie_glasses_detection: {
    reasons: {
      glasses:
        "Glasses are detected and may interfere with selfie face detection.",
    },
  },
  selfie_id_comparison: {
    reasons: {
      face_mismatch:
        "The selfie and the face portrait in the ID appear to be different faces.",
      suspiciously_high_similarity:
        "The selfie and the face portrait in the ID appear to be the exact same submission.",
    },
  },
  selfie_liveness_detection: {
    reasons: {
      scene_changed: "The images appear to have different backgrounds.",
      device_detected: "The selfie does not appear to be a live person.",
      replica_2d_detected: "The selfie appears to be a 2d replica.",
      frame_detected: "The selfie appears to be a framed replica.",
      replica_detected:
        "The selfie has characteristics that are indicative of a spoof.",
    },
  },
  selfie_multiple_faces_detection: {
    reasons: {
      face_conflict: "The selfies appear to be different faces across poses.",
      multiple_faces_detected: "The selfies has multiple faces in the frame.",
    },
  },
  selfie_pose_detection: {
    reasons: {
      required_poses_not_found:
        "The selfies appear to be incorrectly positioned in one or more poses.",
      face_cropped: "The selfie does not capture the entire face.",
      face_too_far: "The selfie captured was too small.",
      face_quality: "The selfie captured is unclear and out of focus",
    },
  },
  selfie_pose_repeat_detection: {
    reasons: {
      poses_repeated: "The selfies appear to repeat across poses.",
    },
  },
  selfie_public_figure_detection: {
    reasons: {
      public_figure:
        "The selfie submission appears to be of a known public figure.",
    },
  },
  selfie_suspicious_entity_detection: {
    reasons: {
      suspicious_photo: "The selfie submission appears to be a government ID.",
    },
  },
  database_address_comparison: {
    reasons: {
      insufficient_match:
        "The address provided did not sufficiently match any databases.",
    },
  },
  database_address_deliverable_detection: {
    reasons: {
      not_deliverable:
        "The address provided does not appear to be deliverable.",
    },
  },
  database_address_residential_detection: {
    reasons: {
      commercial_address: "The address provided appears to be commercial.",
      unknown_address_type: "The address type provided is not known.",
    },
  },
  database_birthdate_comparison: {
    reasons: {
      insufficient_match:
        "The birthdate provided did not sufficiently match any databases.",
    },
  },
  database_deceased_detection: {
    reasons: {
      person_is_deceased:
        "The information provided match the records of a deceased person.",
    },
  },
  database_identity_comparison: {
    reasons: {
      property_conflict:
        "Claimed attributes do not match what was found on the identity record.",
    },
  },
  database_inquiry_comparison: {
    reasons: {
      property_conflict:
        "Claimed attributes like name or birthdate are inconsistent with what was submitted.",
      unsupported_comparison_language:
        "Comparison attributes includes characters from an unsupported language.",
    },
  },
  database_name_comparison: {
    reasons: {
      insufficient_match:
        "The name provided did not sufficiently match any databases.",
    },
  },
  database_phone_number_comparison: {
    reasons: {
      insufficient_match:
        "The phone number provided did not sufficiently match any databases.",
    },
  },
  database_po_box_detection: {
    reasons: {
      address_is_po_box: "The address is a PO box.",
    },
  },
  database_social_security_number_comparison: {
    reasons: {
      insufficient_match:
        "The SSN provided did not sufficiently match any databases.",
    },
  },
  database_two_plus_two_detection: {
    reasons: {
      insufficient_match:
        "The identity provided did not sufficiently match a record in the authoritative or issuing databases.",
    },
  },
  document_compromised_detection: {
    reasons: {
      publicly_available:
        "The submission exists in publicly available sources.",
    },
  },
  document_expired_detection: {
    reasons: {
      expired: "Document has expired.",
    },
  },
  document_fabrication_detection: {
    reasons: {
      fabricated: "Document is fabricated.",
    },
  },
  document_portrait_detection: {
    reasons: {
      portrait_not_found: "The submission is missing a face.",
    },
  },
  document_tamper_detection: {
    reasons: {
      exif_inconsistency:
        "The submission has inconsistencies in the file metadata that imply image tampering.",
      image_manipulated:
        "The submission appears to have been digitally tampered.",
    },
  },
  document_extracted_properties_detection: {
    reasons: {
      match_rejected: "Match was rejected for a pattern.",
      match_missing: "The submission is missing a match pattern.",
    },
  },
  document_recency_detection: {
    reasons: {
      no_issue_date: "The submission is missing an issue date.",
      not_recent: "Document is not recent enough.",
    },
  },
  document_selfie_comparison: {
    reasons: {
      face_mismatch:
        "The face portrait on the document and the selfie appear to be different faces.",
    },
  },
  document_type_detection: {
    reasons: {
      type_mismatch: "The submission is not one of the expected types.",
    },
  },
  document_unprocessable_submission_detection: {
    reasons: {
      unprocessable:
        "The submission is not processable. (e.g. malformed document)",
    },
  },
  document_pdf_modification_detection: {
    reasons: {
      modified_pdf_structure:
        "The internal PDF structure suggests that the PDF has been modified after creation.",
      inconsistent_timestamps:
        "The creation date and modification date of the PDF are different.",
    },
  },
  document_handwriting_detection: {
    reasons: {
      handwriting_detected: "Handwriting was detected.",
      signature_detected: "Signature was detected.",
    },
  },
});

export {
  INTEGRATION_TYPES,
  PERSONA_CHECKS_REASONS_MAP,
  PERSONA_CHECK_STATUS,
  PERSONA_FILE_TYPE,
  PERSONA_FILE_TYPE_LABELS,
  PERSONA_ID_CLASS,
  PERSONA_ID_CLASS_LABELS,
  PERSONA_STATUS,
  PERSONA_VERIFICATION_STATUS,
  PERSONA_VERIFICATION_TYPES,
  PERSONA_VERIFICATION_TYPES_LABELS,
  PERSONA_VERIFICATION_PREFIXES,
  VERIFICATION_OF_RESIDENCY_STATUS,
  YARDI_SECURITY_DEPOSIT_PREFERENCES,
};
