import React from "react";

import { FlexContainerProps } from "./interfaces";
import { BaseFlexContainer } from "./styled";

const FlexContainer = (props: FlexContainerProps) => (
  <BaseFlexContainer {...props} />
);

export default FlexContainer;
