import React from "react";

import { Formik } from "formik";
import { Helmet } from "react-helmet";

import { useGetIntegrationProvider } from "manager/pages/Marketplace/config";
import {
  FooterButton,
  StyledFooterBar,
  YardiMappingHeader,
} from "manager/pages/Marketplace/integrations/yardi/shared/mapping/styled";
import YardiDropdownMappingField from "manager/pages/Marketplace/integrations/yardi/shared/YardiDropdownMappingField";
import YardiMappingPage from "manager/pages/Marketplace/integrations/yardi/shared/YardiMappingPage";
import { YardiTitleContainer } from "manager/pages/Marketplace/integrations/yardi/styled";
import Container from "shared/components/Container";
import { PreventLeaveFormModal } from "shared/components/Form";
import { BackLink } from "shared/components/Links";
import PageTitle from "shared/components/PageTitle";
import Spacer from "shared/components/Spacer";
import { IntegrationProvider } from "shared/config/constants";
import { BackLinkProps } from "shared/interfaces/misc";

import {
  YardiAttachmentPropType,
  YardiAttachmentMappingValuesPropType,
  CredentialsHeaderType,
  DOCUMENT_TYPES,
} from "../shared/yardi.config";
import YardiHeaderCredentials from "../shared/YardiHeaderCredentials";

const title = "Setup Document types";

interface SetupYardiDocumentTypesProps {
  backLink: BackLinkProps;
  yardiAttachmentTypes?: Array<YardiAttachmentPropType>;
  initialValues?: YardiAttachmentMappingValuesPropType;
  onFormSubmit: (data: any, callbacks: any) => void;
  onSave: (values: any) => void;
  yardiCredentials?: CredentialsHeaderType;
  isYardiAttachmentTypesLoading?: boolean;
}

const SetupYardiDocumentTypes = ({
  backLink,
  yardiAttachmentTypes,
  initialValues,
  onFormSubmit,
  onSave,
  yardiCredentials,
  isYardiAttachmentTypesLoading,
}: SetupYardiDocumentTypesProps) => {
  const dropdownPlaceholder = "Select document type";
  const options = (yardiAttachmentTypes || []).map((type) => ({
    value: type.id,
    label: type.value,
    step: type.step,
  }));

  const { provider } = useGetIntegrationProvider();

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onFormSubmit}
        enableReinitialize
        validateOnMount
      >
        {({ dirty, isSubmitting, isValid, submitForm, values }) => (
          <>
            <Container expand>
              <Helmet>
                <title>{title}</title>
              </Helmet>
              {backLink && (
                <BackLink.Container>
                  <BackLink {...backLink} />
                </BackLink.Container>
              )}
              <PageTitle.Container center>
                <div>
                  <YardiTitleContainer>Document types</YardiTitleContainer>
                </div>
                <YardiHeaderCredentials yardiCredentials={yardiCredentials} />
              </PageTitle.Container>
              <YardiMappingPage
                loading={isYardiAttachmentTypesLoading}
                title={
                  <YardiMappingHeader>
                    Please map the External Provider document types to the
                    corresponding VERO document types
                  </YardiMappingHeader>
                }
                from="VERO"
                to="External Provider"
                elements={DOCUMENT_TYPES.filter(
                  // NOTE: for Entrata we need to hide executedLeasePdfAttachmentType, for Yardi show everything
                  ({ id }) =>
                    (provider === IntegrationProvider.entrata &&
                      id !== "executedLeasePdfAttachmentType") ||
                    provider === IntegrationProvider.yardi
                ).map(({ id, label, step: elementStep }) => ({
                  title: label,
                  dropdownPlaceholder,
                  node: (
                    <YardiDropdownMappingField
                      name={id}
                      dropdownPlaceholder={dropdownPlaceholder}
                      // @ts-ignore
                      options={options.filter(
                        ({ step }) => step === elementStep
                      )}
                    />
                  ),
                }))}
              />
              <PreventLeaveFormModal
                title="Exit document types setup"
                subtitle="Would you like to save your changes?"
                submitForm={() => onSave(values)}
                isSubmitting={isSubmitting}
                preventLeaveWhen={dirty}
                cancelLinkLabel="No"
                submitButtonLabel="Yes"
                customOnCancel={undefined}
                customOnConfirm={undefined}
                onNextLocationTriggered={undefined}
              >
                <Spacer />
              </PreventLeaveFormModal>
            </Container>
            <Container noPaddingBottom noPaddingTop stickToBottom>
              <StyledFooterBar>
                <FooterButton
                  type="primary"
                  onClick={submitForm}
                  disabled={!isValid}
                  loading={isSubmitting}
                >
                  CONFIRM
                </FooterButton>
              </StyledFooterBar>
            </Container>
          </>
        )}
      </Formik>
    </>
  );
};

export default SetupYardiDocumentTypes;
