import React, { useEffect, useState } from "react";

import { Col, Row } from "antd";

import { useMediaQuery } from "react-responsive";

import Icon from "shared/components/Icon";
import {
  BREAKPOINT_RESOLUTIONS,
  PERSONA_VERIFICATION_TYPES,
} from "shared/config/constants";
import useModal from "shared/hooks/useModal";
import { getFullnameData } from "shared/utils/applicant";

import {
  PERSONA_CHECK_STATUS,
  VerificationCheck,
} from "shared/utils/interfaces";

import { Button } from "../Button";
import FlexContainer from "../FlexContainer";
import InformationModal from "../Modals/InformationModal";
import Table from "../Table";
import Tile from "../Tile";

import { MediumTitle, RegularText } from "../Typography";

import {
  CapitalizedContainer,
  InfoListIcon,
  ResponsiveChecksButton,
} from "./styled";
import { filterChecks, formatRowStr } from "./utils";

export interface PersonDocumentCardProps {
  firstName: string;
  middleName?: string;
  lastName: string;
  verificationType?: string;
  verificationChecks: VerificationCheck[];
  inset?: boolean;
  isRenter?: boolean;
}

interface RenderContainerProps {
  label?: string;
  content: string | JSX.Element;
  isMobile: boolean;
}

const desktopColumns = [
  {
    title: " Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Reasons",
    dataIndex: "reasons",
    key: "reasons",
  },
  {
    title: "Required",
    dataIndex: "required",
    key: "required",
  },
];

const responsiveColumns = {
  xs: {
    column1: "name",
    column2: "reasons",
    column3: "required",
  },
  sm: ["name", "required"],
  md: ["name", "reasons", "required"],
};

const noDataPlaceholder = {
  Icon: Icon.NoDataIcon,
  label: "Checks",
};

export const getStatusIcon = (status: string) => {
  return status === PERSONA_CHECK_STATUS.PASSED ? (
    <Icon.CheckGreenSmall />
  ) : (
    <Icon.CrossRedSmall />
  );
};

const renderContainer = ({
  label = "",
  content,
  isMobile,
}: RenderContainerProps) => (
  <CapitalizedContainer>
    {isMobile && content && <RegularText strong>{label}&nbsp;</RegularText>}
    {content}
  </CapitalizedContainer>
);

/**
 * Table component to display checks in a modal
 */
const ChecksViewer = ({
  firstName,
  middleName = undefined,
  lastName,
  verificationType = undefined,
  verificationChecks,
  inset = false,
  isRenter = true,
}: PersonDocumentCardProps) => {
  const { openModalDialog } = useModal();
  const { fullNameOrNA } = getFullnameData({ firstName, middleName, lastName });
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT_RESOLUTIONS.sm });
  const [verificationChecksState, setVerificationChecksState] =
    useState(verificationChecks);

  useEffect(() => {
    const filteredChecks = filterChecks(
      verificationChecks,
      PERSONA_CHECK_STATUS.NOT_APPLICABLE
    );
    setVerificationChecksState(filteredChecks);
  }, []);

  const rowMapping = ({
    name,
    reasons,
    requirement,
    status,
  }: VerificationCheck) => {
    const capitalizedName = formatRowStr(name, verificationType);
    const capitalizeReasons = formatRowStr(
      reasons.join(", "),
      verificationType
    );
    const formattedRequirement = requirement === "required" ? "Yes" : "No";

    return {
      key: name,
      values: [
        renderContainer({
          content: (
            <>
              <InfoListIcon>{getStatusIcon(status)}</InfoListIcon>
              {capitalizedName}
            </>
          ),
          isMobile,
        }),
        renderContainer({
          label: "Reasons:",
          content: capitalizeReasons,
          isMobile,
        }),
        renderContainer({
          label: "Required:",
          content: formattedRequirement,
          isMobile,
        }),
      ],
    };
  };

  return (
    <Tile.Inner inset={inset}>
      <Row>
        <Col xs={24} md={12}>
          {verificationType !== PERSONA_VERIFICATION_TYPES.SELFIE && (
            <FlexContainer alignItems="center">
              <InfoListIcon>
                <Icon.PeopleIconMd />
              </InfoListIcon>
              <MediumTitle strong>{fullNameOrNA}</MediumTitle>
            </FlexContainer>
          )}
        </Col>
        {!isRenter && (
          <Col xs={24} md={12}>
            <ResponsiveChecksButton>
              <Button
                type="primary"
                onClick={() => {
                  openModalDialog(InformationModal, {
                    title: "Verifications",
                    description: (
                      <div>
                        <Table
                          id={verificationType}
                          columns={desktopColumns}
                          columnBreakpoints={responsiveColumns}
                          placeholder={noDataPlaceholder}
                          rows={verificationChecksState.map(rowMapping)}
                          pagination={false}
                          scroll={{ y: 600 }}
                          noClickableRows
                          alt
                        />
                      </div>
                    ),
                    centered: true,
                    width: 1000,
                  });
                }}
              >
                Verification Checks
              </Button>
            </ResponsiveChecksButton>
          </Col>
        )}
      </Row>
    </Tile.Inner>
  );
};

export default ChecksViewer;
