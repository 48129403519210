import React from "react";

import { Container, Progress } from "./styled";

export interface IProgressBar {
  progress: number;
  [key: string]: any;
}

const ProgressBar = ({ progress, ...props }: IProgressBar) => (
  <Container
    title={`Progress: ${progress}%`}
    {...props}
    role="progressbar"
    aria-valuenow={progress}
    aria-valuemin={0}
    aria-valuemax={100}
    data-testid="progress-bar"
  >
    <Progress progress={progress} />
  </Container>
);

export default ProgressBar;
