import styled from "@emotion/styled";

import { Button } from "shared/components/Button";
import BaseFooterBar from "shared/components/FooterBar";
import Icon from "shared/components/Icon";
import PageTitle from "shared/components/PageTitle";
import { MEDIA_QUERIES, colors, COLORS } from "shared/config/constants";

const PdfDownloadAction = styled(PageTitle.Action)({
  [MEDIA_QUERIES.w.ltMd]: {
    display: "none",
  },
});

const DesktopApplicantDropdown = styled(PageTitle.Action)({
  [MEDIA_QUERIES.w.ltMd]: {
    display: "none",
  },
});

const MobileApplicantDropdown = styled(PageTitle.Action)({
  [MEDIA_QUERIES.w.gteMd]: {
    display: "none",
  },
});

const LoadingSpinner = styled(Icon.LoadingSpinnerGreenLargeIcon)({
  display: "block",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translateY(-50%, -50%)",
});

const chatButtonWidth = 50;

const FooterBar = styled(BaseFooterBar)({
  [MEDIA_QUERIES.w.ltMd]: {
    flexDirection: "row-reverse",
    padding: `0 ${chatButtonWidth + 35}px`,
  },
  [MEDIA_QUERIES.w.gteLg]: {
    flexDirection: "row-reverse",
    padding: `0 ${chatButtonWidth + 20}px`,
  },
  [MEDIA_QUERIES.w.gteMd]: {
    flexDirection: "row-reverse",
    padding: `0 ${chatButtonWidth + 10}px`,
  },
});

const FooterButton = styled(Button)({
  "&:not(:last-of-type)": {
    marginRight: "16px",
  },
  [MEDIA_QUERIES.w.gteLg]: {
    "&:nth-of-type(1)": {
      order: 3,
    },
    "&:nth-of-type(2)": {
      order: 2,
    },
    "&:nth-of-type(3)": {
      order: 1,
    },
  },
});

const VEROLynkButton = styled(Button)`
  background-color: none;
  border: none;
  color: ${colors.secondary[500]};
  text-decoration: underline;
  text-transform: none;
  font-weight: 400;
  .button-inner {
    padding-left: 0 !important;
  }
`;

const WarningBanner = styled.div`
  background-color: ${COLORS.lightOrange2};
  color: ${COLORS.darkOrange};
  border: 1px solid ${COLORS.darkOrange};
  border-radius: 12px;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0;
  margin-bottom: 12px;

  .icon {
    margin-left: 16px;
  }

  .view-detail-button {
    margin-right: 16px;
  }

  .banner-message {
    flex: 1;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 14px;
  }
`;

export {
  PdfDownloadAction,
  DesktopApplicantDropdown,
  MobileApplicantDropdown,
  LoadingSpinner,
  FooterBar,
  FooterButton,
  VEROLynkButton,
  WarningBanner
};
