import React from "react";

import { useMediaQuery } from "react-responsive";

import { InviteApplicantsModal } from "manager/components/Modal";
import { InviteLeadModal } from "manager/components/Prescreening";
import { Button, DropdownButton } from "shared/components/Button";
import { BREAKPOINT_RESOLUTIONS } from "shared/config/constants";
import { usePermissions } from "shared/hooks";
import useModal from "shared/hooks/useModal";

const getHeaderAction = ({
  showInviteLead,
  inviteApplicants,
  inviteLead,
  isMobile,
  canInviteLead,
  canInviteLeadsToApply,
}) => {
  const tooltipProps = {
    title: "You do not have the required permissions to perform this action.",
  };

  if (showInviteLead) {
    return (
      <DropdownButton
        size={isMobile ? "sm" : undefined}
        items={[
          {
            key: "INVITE_APPLICANTS",
            label: "Invite applicants to apply",
            onClick: inviteApplicants,
            disabled: false,
            tooltip: false,
          },
          {
            key: "INVITE_LEAD",
            label: "Invite lead to prequalify",
            onClick: inviteLead,
            disabled: !canInviteLead,
            tooltip: !canInviteLead && tooltipProps,
          },
        ]}
      >
        Invite
      </DropdownButton>
    );
  }
  return (
    <Button
      plus={!!isMobile}
      data-testid="invite-applicants-button"
      size={isMobile ? "sm" : undefined}
      type="primary"
      onClick={inviteApplicants}
    >
      Invite Applicants
    </Button>
  );
};

export const useManagerHeaderConfig = () => {
  const {
    isPrescreeningEnabled,
    canInviteLead,
    permissionsLoaded,
    canInviteLeadsToApply,
  } = usePermissions();
  const { openModalDialog } = useModal();
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT_RESOLUTIONS.sm });
  const showInviteLead = isPrescreeningEnabled && canInviteLead;

  const inviteApplicants = () => openModalDialog(InviteApplicantsModal);
  const inviteLead = () => openModalDialog(InviteLeadModal);

  const headerAction = getHeaderAction({
    showInviteLead,
    inviteApplicants,
    inviteLead,
    isMobile,
    canInviteLead,
    canInviteLeadsToApply,
  });

  return {
    headerAction: permissionsLoaded ? headerAction : null,
  };
};
