const MAGIC_LINK_PAGES = Object.freeze({
  INVALID_PAGE: "INVALID_PAGE",
  PROPERTIES_PORTAL: "PROPERTIES_PORTAL",
  UNITS_PORTAL: "UNITS_PORTAL",
  LEASE_RENT_OPTIONS_PAGE: "LEASE_RENT_OPTIONS_PAGE",
  MAGIC_LINK_LANDING_PAGE: "MAGIC_LINK_LANDING_PAGE",
});

const MAGIC_LINK_LANDING_STEP = Object.freeze({
  LANDING_STEP: "LANDING_STEP",
  CONFIRMATION_STEP: "CONFIRMATION_STEP",
  ERROR_STEP: "ERROR_STEP",
});

export { MAGIC_LINK_LANDING_STEP, MAGIC_LINK_PAGES };
