import React, { useState } from "react";

import lowerCase from "lodash/lowerCase";
import { useHistory } from "react-router-dom";

import { YardiImportPropertyModal } from "manager/components/Modal";
import YardiRemovePropertyConfirmationModal from "manager/components/Modal/YardiRemovePropertyConfirmationModal";
import ROUTES from "manager/config/routes";
import {
  useGetYardiCredentials,
  useGetYardiIntegrationStatus,
  useGetYardiProperties,
} from "manager/hooks/api";
import { useGetIntegrationProvider } from "manager/pages/Marketplace/config";
import useModal from "shared/hooks/useModal";
import { BackLinkProps } from "shared/interfaces/misc";

import { transformRoute } from "shared/utils/routing";

import YardiDashboard from "./YardiDashboard";
import { YARDI_PROPERTIES_STATUS } from "./yardiDashboard.config";

const isSetupStatusComplete = (setupStatus) =>
  lowerCase(setupStatus) === "completed";

const TABLE_PAGE_SIZE = 10;

const blankFilters = {
  status: null,
  nameOrAddress: "",
};

interface YardiDashboardContainerProps {
  company?: {
    name?: string;
    shortName?: string;
    logo?: string;
    slug: string;
  };
  backLink?: BackLinkProps;
}

export interface FetchYardiPropertiesParams {
  isActive: any;
  page: number;
  pageSize: number;
  status: string;
  nameOrAddress: string;
  ordering: string;
}

const YardiDashboardContainer = ({
  company,
  backLink,
}: YardiDashboardContainerProps) => {
  const history = useHistory();
  const { provider } = useGetIntegrationProvider();
  const [filters, setFilters] = useState({ ...blankFilters });
  const [currentOrdering, setCurrentOrdering] = useState(undefined);
  const [currentPagination, setCurrentPagination] = useState({
    page: 1,
    pageSize: TABLE_PAGE_SIZE,
  });
  const { yardiCredentials } = useGetYardiCredentials(provider);
  const { yardiIntegrationStatus } = useGetYardiIntegrationStatus(provider);
  const {
    yardiProperties,
    isLoading: isTableLoading,
    refetchProperties,
  } = useGetYardiProperties({
    provider,
    isActive: true,
    page: currentPagination.page,
    pageSize: currentPagination.pageSize,
    status: filters.status,
    nameOrAddress: filters.nameOrAddress,
    ordering: currentOrdering,
    truncated: undefined,
  });

  const updateFilters = (values) => {
    if (values) {
      setFilters({ ...filters, ...values });
    } else {
      setFilters({ ...blankFilters });
    }
  };

  const importedYardiProperties = yardiProperties?.results ?? [];
  const totalCount = yardiProperties?.count || 0;
  const { openModalDialog } = useModal();
  const isFilterActive = Boolean(filters.status || filters.nameOrAddress);

  const data = {
    numPropertiesConnected: yardiIntegrationStatus?.numberOfProperties || 0,
    numUnitsConnected: yardiIntegrationStatus?.numberOfUnits || 0,
    areDocumentTypesComplete: isSetupStatusComplete(
      yardiIntegrationStatus?.attachmentTypesStatus
    ),
    areChargeTypesComplete: isSetupStatusComplete(
      yardiIntegrationStatus?.chargeTypesStatus
    ),
    areLeadSourcesComplete: isSetupStatusComplete(
      yardiIntegrationStatus?.leadSourcesStatus
    ),
    areSyncSettingsComplete: isSetupStatusComplete(
      yardiIntegrationStatus?.syncConfigStatus
    ),
  };

  const isSetupComplete = isSetupStatusComplete(
    yardiIntegrationStatus?.overallConfigStatus
  );

  const editDocumentTypes = () => {
    history.push(
      transformRoute(ROUTES.integrationSetupDocumentTypes, {
        provider,
      })
    );
  };

  const editChargeTypes = () => {
    history.push(
      transformRoute(ROUTES.integrationSetupChargeTypes, {
        provider,
      })
    );
  };

  const editLeadSources = () => {
    history.push(
      transformRoute(ROUTES.integrationSetupLeadSources, {
        provider,
      })
    );
  };

  const editSyncSettings = () => {
    history.push(
      transformRoute(ROUTES.integrationSetupSyncSettings, {
        provider,
      })
    );
  };

  const afterPropertiesImport = () => {
    refetchProperties();
  };

  const importYardiPropertiesAction = () => {
    const context = {
      afterPropertiesImport,
      company,
      provider,
    };
    openModalDialog(YardiImportPropertyModal, context);
  };

  const editPropertySetup = (property) => {
    if (property.status === YARDI_PROPERTIES_STATUS.SETUP_NOT_STARTED) {
      const propertySetupUrl = transformRoute(ROUTES.integrationSetupProperty, {
        provider,
        propertyId: property.id,
      });
      history.push(propertySetupUrl);
    } else {
      const propertySetupUrl = transformRoute(
        ROUTES.integrationConfigProperty,
        {
          provider,
          propertyId: property.id,
        }
      );
      history.push(propertySetupUrl);
    }
  };

  const deleteProperty = async (property) => {
    if (property.status !== YARDI_PROPERTIES_STATUS.COMPLETED) {
      const context = {
        provider,
        title: "Remove property from the integration list?",
        content:
          "You will be able to add the property back to the list by clicking on the ‘Import properties’ button.",
        propertyId: property.id,
        propertyName: property.name,
        removeFromActive: true,
      };

      openModalDialog(YardiRemovePropertyConfirmationModal, context);
    }
  };

  const summaryData = {
    isSetupComplete,
    editDocumentTypes,
    editChargeTypes,
    editSyncSettings,
    editLeadSources,
    ...data,
  };

  const tableData = {
    importedYardiProperties,
    importYardiPropertiesAction,
    editPropertySetup,
    deleteProperty,
    isTableLoading,
    currentPagination,
    setCurrentPagination,
    setCurrentOrdering,
    totalCount,
    isFilterActive,
    filters,
    updateFilters,
  };

  const dashboardProps = {
    tableData,
    summaryData,
    yardiCredentials,
    backLink,
  };

  return <YardiDashboard {...dashboardProps} />;
};

export default YardiDashboardContainer;
