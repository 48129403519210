import React, { ElementType, ReactNode } from "react";

import BaseIcon from "shared/components/Icon";

import { Well } from "./styled";

interface BannerProps {
  message: string | ReactNode;
  Icon?: ElementType;
  className?: string;
}

const Banner = ({
  className,
  message,
  Icon = BaseIcon.OrangeExclamationSmall,
}: BannerProps) => (
  <Well noBorder white className={className}>
    <Icon className="extra-message-icon" />
    {message}
  </Well>
);

export default Banner;
