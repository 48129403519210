import React from "react";

import PropTypes from "prop-types";

import ROUTES from "manager/config/routes";
import { useManagerHeaderConfig } from "manager/hooks/useManagerHeaderConfig";
import { Header } from "shared/components/Header";
import MobileHeader from "shared/components/Header/MobileHeader";
import { ActionLink } from "shared/components/Links";
import { MobileSidebar } from "shared/components/Sidebar";
import DrawerHeader from "shared/components/Sidebar/MobileSidebar/DrawerHeader";
import Layout from "shared/templates/Layout";
import { clearCachedActiveCompany } from 'shared/utils/auth'

// --- Manager Customization of Layout components ---

const ManagerDrawerHeader = (props) => (
  <DrawerHeader
    {...props}
    InlineAction={
      <ActionLink onClick={() => {
        clearCachedActiveCompany()
        window.location.href = ROUTES.selectCompany
      }}>Switch Company</ActionLink>
    }
  />
);

ManagerDrawerHeader.propTypes = {
  ...DrawerHeader.propTypes,
};

ManagerDrawerHeader.defaultProps = {
  ...DrawerHeader.defaultProps,
};

const ManagerMobileSidebar = (props) => (
  <MobileSidebar {...props} DrawerHeader={ManagerDrawerHeader} />
);

ManagerMobileSidebar.propTypes = {
  ...MobileSidebar.propTypes,
};

ManagerMobileSidebar.defaultProps = {
  ...MobileSidebar.defaultProps,
};

const ManagerMobileHeader = (props) => (
  <MobileHeader {...props} MobileSidebar={ManagerMobileSidebar} />
);

ManagerMobileHeader.propTypes = {
  ...MobileHeader.propTypes,
};

ManagerMobileHeader.defaultProps = {
  ...MobileHeader.defaultProps,
};

const ManagerLayoutHeader = (props) => (
  <Header {...props} MobileHeader={ManagerMobileHeader} />
);

ManagerLayoutHeader.propTypes = {
  ...Header.propTypes,
};

ManagerLayoutHeader.defaultProps = {
  ...Header.defaultProps,
};

const LayoutContainer = (props) => {
  const { headerAction } = useManagerHeaderConfig();

  const { header } = props;
  const extendedHeader = {
    ...header,
    headerAction,
  };

  return (
    <Layout
      {...props}
      header={extendedHeader}
      LayoutHeader={ManagerLayoutHeader}
    />
  );
};

LayoutContainer.propTypes = {
  header: PropTypes.object.isRequired,
  sidebar: PropTypes.object,
  children: PropTypes.node,
  withoutBackToTop: PropTypes.bool,
};

export default LayoutContainer;
