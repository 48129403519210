import { all } from "redux-saga/effects";

/* Sagas */
import { rootSaga as sharedRootSaga } from "shared/state";

const createRootSaga = (sagas) =>
  function* rootSaga() {
    yield all([sharedRootSaga(), ...(sagas || [])]);
  };

export default createRootSaga;
