import { useCallback, useMemo, useState } from "react";

import copyToClipboard from "copy-to-clipboard";
import { useHistory } from "react-router-dom";

import ROUTES from "manager/config/routes";
import {
  useGetApplicationAuditsCount,
  usePrimaryApplicantDeal,
  useUnitListingDeal,
} from "manager/hooks/api";
import { useGetApplicationSummary } from "manager/hooks/application-summary/useGetApplicationSummary";

import { NOTIFICATIONS } from "shared/config/constants";
import { useDeviceType } from "shared/hooks";
import useModal from "shared/hooks/useModal";
import { transformRoute } from "shared/utils/routing";
import { openNotification } from "shared/utils/ui";
import { setPrefix } from "shared/utils/vrn";

import { DealInviteApplicantsModal } from "../Modal";
import { useOpenCreateDealAuditNoteModal } from "../SystemAuditsTable";

import { mapToHeaderDeal } from "./header";
import { isCanceledDeal, isDeclinedDeal } from "./header/utils";
import { mapDealToApplicantsSummary, mapDealToProps } from "./utils";

export const useAppSummary = (dealId: string) => {
  const {
    deal,
    isLoading: loading,
    dealRefetch,
  } = useGetApplicationSummary({ dealId });

  const { lumenResult, lumenRevision, applicantsSummaryShort } = deal ?? {};

  const { applicationAuditsCount } = useGetApplicationAuditsCount(dealId);
  const isPrimaryExist = deal?.applications?.find((appl) => appl?.isPrimary);
  const {
    primaryApplicantDeal,
    isPrimaryApplicantDealLoading,
    primaryApplicantDealRefetch,
  } = usePrimaryApplicantDeal(dealId, {
    enabled: isPrimaryExist,
    retry: false,
  });

  const refetchDeal = () => {
    dealRefetch();
    primaryApplicantDealRefetch();
  };

  const preferredLeaseDurationMonths =
    primaryApplicantDeal?.preferredLeaseDurationMonths;

  const { unitListingDeal, isUnitListingDealLoading } = useUnitListingDeal(
    dealId,
    {
      retry: false,
      enabled: deal?.unit?.name,
    }
  );

  const { rent } = (!isUnitListingDealLoading && unitListingDeal) || {};

  const { isMobile } = useDeviceType();
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpanded = () => setIsExpanded(!isExpanded);

  const history = useHistory();
  const { openModalDialog } = useModal();
  const { openCreateAuditNoteModal } = useOpenCreateDealAuditNoteModal({
    dealId,
  });

  const contactAll = useCallback(() => {
    if (!deal) return;
    copyToClipboard(deal.applications.map((e) => e.applicant.email).join(", "));
    openNotification(
      "Applicants' emails copied to clipboard. Open your email client and paste values to contact applicants.",
      NOTIFICATIONS.info
    );
  }, [deal]);
  const addNotes = () => openCreateAuditNoteModal();
  const invite = () =>
    openModalDialog(DealInviteApplicantsModal, {
      dealId,
      refetchDeal,
      applicantsSummary: mapDealToApplicantsSummary(deal),
    });

  const isInactiveDealValue =
    deal?.status &&
    (isCanceledDeal(deal.status) || isDeclinedDeal(deal.status));

  const goBack = () => {
    history.push(transformRoute(ROUTES.deals, { id: dealId }));
  };

  const headerProps = useMemo(
    () => mapToHeaderDeal(deal, dealId, unitListingDeal, primaryApplicantDeal),
    [deal, dealId, unitListingDeal, primaryApplicantDeal]
  );

  const {
    dealModalProps,
    title,
    appWellProps,
    apps,
    actionBarProps,
    deps,
    countyRecordsProps,
  } = useMemo(
    () =>
      mapDealToProps(
        dealId,
        deal,
        preferredLeaseDurationMonths,
        rent,
        lumenRevision,
        lumenResult,
        applicantsSummaryShort
      ),
    [
      deal,
      dealId,
      preferredLeaseDurationMonths,
      rent,
      lumenRevision,
      lumenResult,
      applicantsSummaryShort,
    ]
  );

  return {
    loading:
      loading || isUnitListingDealLoading || isPrimaryApplicantDealLoading,
    apps,
    deps,
    appWellProps,
    dealModalProps,
    headerProps,
    actionBarProps,
    title,
    isMobile,
    isExpanded,
    contactAll,
    addNotes,
    invite: !isInactiveDealValue && invite,
    goBack,
    toggleExpanded,
    refetchDeal,
    countyRecordsProps,
    applicationAuditsCount,
    conditionalProceed: deal?.conditionalProceed,
    dealStatus: deal?.status,
    fraudIndicators: deal?.fraudIndicators,
    applicationVrn:
      deal?.id && setPrefix(`${deal.companySlug}/${deal.id}`, "application"),
  };
};
