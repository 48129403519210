import React, { useState } from "react";

import { useGetYardiUnitTypes } from "manager/hooks/api/yardiUnitTypesQueries";
import { YardiUnitTypesRequest } from "manager/interfaces/api";
import { DEFAULT_PAGINATION } from "shared/config/constants";

import YardiUnitTypesTable from "./YardiUnitTypesTable";

interface YardiUnitTypesContainerProps {
  propertyId: number;
}

const YardiUnitTypesTableContainer = ({
  propertyId,
}: YardiUnitTypesContainerProps) => {
  const defaultFilters: YardiUnitTypesRequest = {
    propertyId,
    ordering: null,
    ...DEFAULT_PAGINATION,
  };

  const [filters, setFilters] = useState(defaultFilters);
  const { unitTypes, isUnitTypesLoading } = useGetYardiUnitTypes(filters);

  const onPageChanged = (page: number) =>
    setFilters({
      ...filters,
      page,
    });

  return (
    <YardiUnitTypesTable
      unitTypes={unitTypes}
      loading={isUnitTypesLoading}
      page={filters.page}
      pageSize={filters.pageSize}
      onPageChange={onPageChanged}
    />
  );
};

export default YardiUnitTypesTableContainer;
