import React from "react";

import { useFormikContext } from "formik";

import {
  LUMEN_SECTION_TYPE_LABELS,
  LUMEN_SECTION_VALIDATION_STATUS,
  LUMEN_VALIDATION_STATUS_FIELD_FOR_SECTION,
} from "manager/config/lumen.config";
import { Button } from "shared/components/Button";
import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import { UnderlineLink } from "shared/components/Links";
import BackLink from "shared/components/Links/BackLink";
import { ExtraStrongText } from "shared/components/Typography";

import { LumenFormulaFootBarProps } from "./interfaces";
import { LumenFormulaFootBarContent } from "./styled";

const LumenFormulaFootBar = ({
  currentSection,
  prevSection,
  nextSection,
  navigateToPrev,
  saveAndExit,
  validationStatus,
}: LumenFormulaFootBarProps) => {
  const prevSectionLabel =
    prevSection && LUMEN_SECTION_TYPE_LABELS[prevSection];

  const { values, isValid, submitForm } = useFormikContext();

  const isLastSection = nextSection === null;

  const isFormulaCompleted = () => {
    const currentSectionKey =
      LUMEN_VALIDATION_STATUS_FIELD_FOR_SECTION[currentSection];

    return (
      isLastSection &&
      isValid &&
      Object.keys(validationStatus || {})
        .filter((key) => key !== currentSectionKey)
        .every((key) => {
          return (
            validationStatus[key] !== LUMEN_SECTION_VALIDATION_STATUS.INCOMPLETE
          );
        })
    );
  };

  return (
    <LumenFormulaFootBarContent>
      <div>
        {prevSection && (
          <BackLink size={BackLink.SIZES.large} onClick={navigateToPrev}>
            <ExtraStrongText>{prevSectionLabel}</ExtraStrongText>
          </BackLink>
        )}
      </div>
      <FlexContainer alignItems="center">
        <UnderlineLink
          onClick={() => saveAndExit(values)}
          disabled={!isValid}
          className="underline-link"
        >
          Save & Exit
        </UnderlineLink>
        {nextSection && (
          <Button
            type="primary"
            onClick={submitForm}
            SuffixIcon={Icon.ButtonForwardArrowIcon}
          >
            NEXT STEP
          </Button>
        )}
        {!nextSection && (
          <Button
            type="primary"
            onClick={submitForm}
            disabled={!isFormulaCompleted()}
          >
            COMPLETE SETUP
          </Button>
        )}
      </FlexContainer>
    </LumenFormulaFootBarContent>
  );
};

export default LumenFormulaFootBar;
