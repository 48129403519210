import React, { Key } from "react";

import {
  CollapsibleList,
  CollapsibleListProps,
} from "shared/components/Form/CollapsibleList";

import { Checkbox } from "./styled";

export type CollapsibleCheckboxListDerivedProps = {
  listProps: CollapsibleListProps;
  checkedItems: Key[];
  indeterminateItems: Key[];
  disabledItems?: Key[];
  toggleCheckbox: () => void;
};

export const CollapsibleCheckboxListDerived = ({
  listProps,
  checkedItems,
  indeterminateItems,
  toggleCheckbox,
  disabledItems = [],
}: CollapsibleCheckboxListDerivedProps) => {
  const isChecked = (key) => checkedItems.indexOf(key) > -1;
  const isIndeterminate = (key) => indeterminateItems.indexOf(key) > -1;
  const isDisabled = (key) => disabledItems.indexOf(key) > -1;
  return (
    <CollapsibleList
      {...listProps}
      setSelectedItems={toggleCheckbox}
      renderCollapseInput
      renderInput={({ item, onChange }) => {
        const key = `checkbox-${item.key}`;
        const indeterminate = isIndeterminate(item.key);
        const disabled = isDisabled(item.key);

        return (
          <Checkbox
            id={key}
            name={key}
            data-testid={key}
            checked={isChecked(item.key)}
            indeterminate={indeterminate}
            data-indeterminate={indeterminate}
            label={!item.collapsible && item.label}
            onChange={onChange}
            disabled={disabled}
          />
        );
      }}
    />
  );
};
