import React from "react";

import PropTypes from "prop-types";

import Icon from "shared/components/Icon";
import { Popover } from "shared/components/Popover";
import {
  MediumTitle,
  RegularText,
  SmallTitle,
} from "shared/components/Typography";
import Well from "shared/components/Well";

import {
  StatedEmployersPopoverContent,
  MultipleEmployersWrapper,
} from "./styled";

const StatedEmployers = ({ employers }) => {
  const numberOfEmployers = employers?.length;
  return (
    <Popover
      className="popover"
      withoutOverlay
      disabled={!numberOfEmployers || numberOfEmployers < 2}
      placement="bottomRight"
      // @ts-ignore
      trigger="hover"
      content={
        <StatedEmployersPopoverContent>
          <Well noBorder>
            {employers?.map((employer) => (
              <RegularText key={employer} className="employer-name">
                {employer}
              </RegularText>
            ))}
          </Well>
        </StatedEmployersPopoverContent>
      }
    >
      {!numberOfEmployers && <MediumTitle strong>N/A</MediumTitle>}
      {numberOfEmployers === 1 && (
        <MediumTitle strong className="text-ellipsis" title={employers[0]}>
          {employers[0]}
        </MediumTitle>
      )}
      {numberOfEmployers > 1 && (
        <MultipleEmployersWrapper>
          <MediumTitle strong>{employers.length}</MediumTitle>
          <SmallTitle>&nbsp;employers&nbsp;</SmallTitle>
          <Icon.ChevronUpIcon width={12} height={12} />
        </MultipleEmployersWrapper>
      )}
    </Popover>
  );
};

StatedEmployers.propTypes = {
  employers: PropTypes.arrayOf(PropTypes.string),
};

StatedEmployers.defaultProps = {
  employers: [],
};

export default StatedEmployers;
