import React, { useState } from "react";

import copyToClipboard from "copy-to-clipboard";
import { kebabCase } from "lodash";

import { useDownloadQRCode } from "manager/hooks/api";
import { DEAL_TYPES } from "shared/config/constants";
import useModal from "shared/hooks/useModal";

import ShareMagicLinksModal from "./ShareMagicLinksModal";

const ShareMagicLinksModalContainer = () => {
  const {
    currentModalContext: {
      name,
      magicLink,
      magicPortalLink,
      prescreeningMagicLink,
      type,
    },
  } = useModal();
  const { downloadQRCode } = useDownloadQRCode();

  const getFormattedName = (link) => {
    let formattedName;
    if (link === magicLink) {
      formattedName = kebabCase(`${name} magic link`);
    } else if (link === prescreeningMagicLink) {
      formattedName = kebabCase(`${name} prequalification magic link`);
    } else {
      formattedName = kebabCase(`${name} magic portal`);
    }
    return formattedName;
  };

  const [mode, setMode] = useState(DEAL_TYPES.SCREENING);

  const onCopyLink = (link) => copyToClipboard(link);

  const onDownloadQRCode = (link) => {
    const formattedName = getFormattedName(link);
    downloadQRCode(link, formattedName);
  };

  const onDealModeChange = (value) => {
    setMode(value);
  };

  return (
    <ShareMagicLinksModal
      name={name}
      magicLink={magicLink}
      magicPortalLink={magicPortalLink}
      prescreeningMagicLink={prescreeningMagicLink}
      onDealModeChange={onDealModeChange}
      mode={mode}
      onCopyLink={onCopyLink}
      onDownloadQRCode={onDownloadQRCode}
      type={type}
    />
  );
};

export default ShareMagicLinksModalContainer;
