import React, { Key } from "react";

import { openCreateUnitWithSuccessModal } from "../CreateUnitModal";

import CreatePropertySuccessModal from "./CreatePropertySuccessModal";

interface CreatePropertySuccessModalContainerProps {
  propertyId: Key;
  propertyName: string;
}

const CreatePropertySuccessModalContainer = ({
  propertyName,
  propertyId,
}: CreatePropertySuccessModalContainerProps) => {
  const submit = () =>
    openCreateUnitWithSuccessModal({ propertyName, propertyId });

  return <CreatePropertySuccessModal submit={submit} name={propertyName} />;
};

export default CreatePropertySuccessModalContainer;
