import React from "react";

import PropTypes from "prop-types";

import { MODALS } from "renter/config/constants";
import Icon from "shared/components/Icon";
import { Modal, SIZES } from "shared/components/Modal";

import {
  TextHeader,
  SubmittedHeader,
  SubmittedTitle,
  SubmittedSubHeader,
  SubmittedBody,
  Logo,
  ContactText,
  BuildingsIcon,
} from "./styled";

const ApplicationSubmittedModal = ({
  propertyName,
  unitName,
  firstName,
  companyName,
  logoUrl,
  redirectToApplication,
}) => {
  const propertyUnitName = [propertyName, unitName]
    .filter((item) => Boolean(item))
    .join(", ");

  return (
    <Modal
      submitButtonLabel="View my application"
      width="900px"
      showCancelLink={false}
      submitButtonCentered
      submitButtonSize={SIZES.lg}
      submit={redirectToApplication}
      subtitle={
        <SubmittedHeader>
          {logoUrl && <Logo src={logoUrl} data-testid="logo" />}
          <SubmittedTitle data-testid="submitted-title">
            Congratulations, {firstName}! You’ve successfully applied for the
            following:
          </SubmittedTitle>
          <SubmittedSubHeader data-testid="submitted-subheader">
            {propertyUnitName ? (
              <>
                <BuildingsIcon data-testid="building-icon" /> {propertyUnitName}
              </>
            ) : (
              <>
                Company Name:&nbsp;
                <strong>{companyName}</strong>
              </>
            )}
          </SubmittedSubHeader>
        </SubmittedHeader>
      }
      id={MODALS.applicationSubmitted}
      submitFooter={
        <ContactText>
          Powered by <Icon.LogoIcon width={50} height={12} />
        </ContactText>
      }
    >
      <Modal.Body noMobilePaddingX noPaddingBottom>
        <SubmittedBody data-testid="submitted-body">
          <TextHeader>Thank you for your application!</TextHeader>
          <p>
            Usually it takes from 1 to 5 days for the manager to process
            applications. You will receive an email as soon as the application
            is processed. In the meantime, you can track its status in the
            “Submitted Applications” page.
          </p>
        </SubmittedBody>
      </Modal.Body>
    </Modal>
  );
};

ApplicationSubmittedModal.propTypes = {
  firstName: PropTypes.string.isRequired,
  propertyName: PropTypes.string,
  unitName: PropTypes.string,
  companyName: PropTypes.string,
  logoUrl: PropTypes.string,
  redirectToApplication: PropTypes.func.isRequired,
};

ApplicationSubmittedModal.defaultProps = {
  propertyName: undefined,
  unitName: undefined,
  companyName: undefined,
  logoUrl: undefined,
};

export default ApplicationSubmittedModal;
