import React from "react";

import { Col, Row } from "antd";
import { useFormikContext } from "formik";

import {
  Input,
  Select,
  StateSelect,
  VeroFormField,
  ZipCodeInput,
} from "shared/components/Form";

import { CREATE_PROPERTY_FIELDS } from "./createProperty.config";
import {
  CreatePropertyInnerFormElementsProps,
  FormikValuePropertyProps,
} from "./interfaces";

const CreatePropertyInnerFormElements = ({
  propertyTypes,
}: CreatePropertyInnerFormElementsProps) => {
  const { errors }: FormikValuePropertyProps = useFormikContext();
  return (
    <>
      <Row gutter={[20, 25]}>
        <Col md={12}>
          <VeroFormField
            id={CREATE_PROPERTY_FIELDS.name}
            name={CREATE_PROPERTY_FIELDS.name}
            label="Property Name"
            data-testid={CREATE_PROPERTY_FIELDS.name}
            required
            as={Input}
          />
        </Col>
        <Col md={12}>
          <VeroFormField
            name={CREATE_PROPERTY_FIELDS.propertyType}
            id={CREATE_PROPERTY_FIELDS.propertyType}
            label="Property Type"
            data-testid={CREATE_PROPERTY_FIELDS.propertyType}
            as={Select}
            required
            error={errors?.propertyType}
          >
            {propertyTypes?.map((propertyType) => (
              <Select.Option key={propertyType.id}>
                {propertyType.value}
              </Select.Option>
            ))}
          </VeroFormField>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <VeroFormField
            id={CREATE_PROPERTY_FIELDS.address}
            name={CREATE_PROPERTY_FIELDS.address}
            label="Street Address"
            data-testid={CREATE_PROPERTY_FIELDS.address}
            required
            as={Input}
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <VeroFormField
            id={CREATE_PROPERTY_FIELDS.city}
            name={CREATE_PROPERTY_FIELDS.city}
            label="City"
            data-testid={CREATE_PROPERTY_FIELDS.city}
            as={Input}
            required
          />
        </Col>
      </Row>
      <br />
      <Row gutter={[20, 25]}>
        <Col md={12}>
          <VeroFormField
            name={CREATE_PROPERTY_FIELDS.state}
            id={CREATE_PROPERTY_FIELDS.state}
            label="State"
            data-testid={CREATE_PROPERTY_FIELDS.state}
            as={StateSelect}
            required
          />
        </Col>
        <Col md={12}>
          <VeroFormField
            id={CREATE_PROPERTY_FIELDS.zipcode}
            name={CREATE_PROPERTY_FIELDS.zipcode}
            as={ZipCodeInput}
            data-testid={CREATE_PROPERTY_FIELDS.zipcode}
            required
          />
        </Col>
      </Row>
    </>
  );
};

export default CreatePropertyInnerFormElements;
