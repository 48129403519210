import React from "react";

import { useHistory } from "react-router-dom";

import { inviteApplicants } from "manager/components/Modal/InviteApplicantsModal/util";
import { useGetLeadSources } from "manager/hooks/api";
import { useActiveCompany } from "shared/hooks";
import useModal from "shared/hooks/useModal";
import { onDialogSubmitError } from "shared/utils/api";

import UnitInviteApplicantsModal from "./UnitInviteApplicantsModal";

const UnitInviteApplicantsModalContainer = () => {
  const history = useHistory();
  const { currentModalContext, closeActiveModalDialog } = useModal();

  const { leadSources, isLoading: leadSourcesLoading } =
    useGetLeadSources(true);
  const { activeCompany } = useActiveCompany();

  const submit = (values, formikApi) =>
    inviteApplicants({
      values,
      reject: onDialogSubmitError(formikApi, history),
      history,
      closeActiveModalDialog,
    });

  const context = {
    ...currentModalContext,
    leadSources: leadSources || [],
    leadSourcesLoading,
    leadSourceRequired: !!activeCompany?.yardiConnected,
  };

  return <UnitInviteApplicantsModal context={context} submit={submit} />;
};

export default UnitInviteApplicantsModalContainer;
