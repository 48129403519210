import React, { Key, useState } from "react";

import { CollapsibleList } from "shared/components/Form/CollapsibleList";

import {
  annotateItem,
  CheckboxItem,
  CheckboxPartition,
  handleCheckboxCheck,
  handleCheckboxUncheck,
} from "shared/utils/checkbox";

import { CollapsibleListProps } from "../CollapsibleList/CollapsibleList";

import { Checkbox, CollapsingContainer } from "./styled";

export type CollapsibleCheckboxContainerProps = {
  listProps: CollapsibleListProps;
  checkedItemsOnChange?: (keys: Key[]) => void;
};

export const CollapsibleCheckboxContainer: React.FC<CollapsibleCheckboxContainerProps> =
  ({ listProps, checkedItemsOnChange }) => {
    const [checkedItems, setCheckedItems] = useState<Key[]>([]);
    const [indeterminateItems, setIndeterminateItems] = useState<Key[]>([]);

    const isChecked = (key) => checkedItems.indexOf(key) > -1;
    const isIndeterminate = (key) => indeterminateItems.indexOf(key) > -1;

    const toggleCheckbox = (item: CheckboxItem) => {
      const annotatedItem = annotateItem(item, listProps.items);
      const checking = !isChecked(annotatedItem.key);
      let newItems: CheckboxPartition = {
        checked: [...checkedItems],
        indeterminate: [...indeterminateItems],
      };

      if (checking) {
        newItems = handleCheckboxCheck(annotatedItem, newItems);
      } else {
        newItems = handleCheckboxUncheck(annotatedItem, newItems);
      }

      // @ts-ignore
      const newCheckedItems = [...new Set(newItems.checked)];
      setCheckedItems(newCheckedItems);
      // @ts-ignore
      setIndeterminateItems([...new Set(newItems.indeterminate)]);

      if (checkedItemsOnChange) {
        checkedItemsOnChange(newCheckedItems);
      }
    };

    return (
      <CollapsibleList
        {...listProps}
        setSelectedItems={toggleCheckbox}
        selectedItems={checkedItems}
        renderCollapseInput
        hideIcon
        renderInput={({ item, onChange }) => {
          const key = `checkbox-${item.key}`;
          const indeterminate = isIndeterminate(item.key);
          const Component = item.component;
          return item.collapsible ? (
            <Checkbox
              id={key}
              name={key}
              data-testid={key}
              checked={isChecked(item.key)}
              indeterminate={indeterminate}
              data-indeterminate={indeterminate}
              label={item.component}
              onChange={onChange}
            />
          ) : (
            <CollapsingContainer>
              {
                // @ts-ignore
                <Component {...item.componentProps} />
              }
            </CollapsingContainer>
          );
        }}
      />
    );
  };
