import React from "react";

import {
  ApplicationSummaryCard,
  CardSize,
  CardStatus,
} from "shared/components/ApplicationSummaryCard";
import {
  ApplicationSummaryTooltip,
  TooltipColumnWidth,
} from "shared/components/ApplicationSummaryTooltip";
import Tooltip from "shared/components/Tooltip";

import { getValue } from "../utils";

export interface CriminalCardProps {
  records?: number;
  highestSeverity?: string;
  lastRecord?: string;
  onClick?: () => void;
  skipped?: boolean;
  skippedReason?: string;
  lumenEnabled?: boolean;
  lumenRecords?: {
    crucial?: boolean;
    safetyNet?: boolean;
  };
  useCriminalRecords?: boolean;
}

export const CriminalCard = ({
  records,
  highestSeverity,
  lastRecord,
  onClick,
  skipped,
  skippedReason,
  lumenEnabled,
  lumenRecords,
  useCriminalRecords,
}: CriminalCardProps) => {
  const additionalProps = () => {
    if (lumenEnabled) {
      const { crucial, safetyNet } = lumenRecords ?? {};
      if (skipped) {
        return {
          title: "SKIPPED",
          status: CardStatus.error,
        };
      }
      if (crucial) {
        return {
          title: "YES",
          status: CardStatus.error,
        };
      }
      if (safetyNet) {
        return {
          title: "YES",
          status: CardStatus.warn,
        };
      }
      if (!records && records !== 0) {
        return {
          title: "IN PROCESS",
          status: CardStatus.unavailable,
        };
      }
      return {
        title: records > 0 ? "Yes" : "No",
        status: CardStatus.lumenSuccess,
      };
    }
    if (!records && records !== 0) {
      return {
        title: "IN PROGRESS",
        status: CardStatus.unavailable,
      };
    }
    if (records > 0) {
      return {
        title: "YES",
        status: CardStatus.error,
      };
    }
    return {
      title: "NO",
      status: CardStatus.success,
    };
  };

  const card = (
    <div>
      <ApplicationSummaryCard
        onClick={onClick}
        subtitle="Criminal Records"
        size={CardSize.Small}
        {...additionalProps()}
        hideColor={lumenEnabled && !useCriminalRecords}
      />
    </div>
  );

  return skipped ? (
    <Tooltip title={skippedReason}>{card}</Tooltip>
  ) : (
    <ApplicationSummaryTooltip
      layout={[
        {
          title: "Total Records:",
          subtitle: `${records ?? 0}`,
          width: TooltipColumnWidth.half,
        },
        {
          title: "Highest Severity:",
          subtitle: getValue(highestSeverity),
          width: TooltipColumnWidth.half,
        },
        {
          title: "Most Recent Record:",
          subtitle: getValue(lastRecord),
          width: TooltipColumnWidth.full,
        },
      ]}
    >
      {card}
    </ApplicationSummaryTooltip>
  );
};
