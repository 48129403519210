import styled from "@emotion/styled";

import { Button } from "shared/components/Button";
import FooterBar from "shared/components/FooterBar";
import Tile from "shared/components/Tile";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";
import { AlignedCenterFlexPartialCss } from "shared/styles/commonStyles";

const SetupYardiPropertyTitle = styled("h5")`
  font-size: 16px;
  margin-top: 16px;
  margin-bottom: 34px;
`;

const StyledTileInner = styled(Tile.Inner)`
  .vero-properties-select-container {
    width: 100%;
    height: 95px;
    ${{ ...AlignedCenterFlexPartialCss }}
    flex-direction: column;
    div:first-of-type {
      width: 335px;
    }
    ${MEDIA_QUERIES.w.ltSm} {
      div:first-of-type: {
        width: 100%;
      }
    }
  }
  .vero-properties-select {
    width: 100%;
  }
  .vero-properties-select-address {
    margin: 0;
    color: ${COLORS.typography.text};
    font-size: 12px;
    margin-top: 16px;
  }
  .action-button {
    margin-bottom: 44px;
  }
`;

const SelectExtension = styled("div")`
  border-top: 1px solid ${COLORS.borders};
  padding: 10px 15px;
`;

const StyledFooterBar = styled(FooterBar)`
  justify-content: flex-end;
`;

const FooterButton = styled(Button)`
  &:not(:last-of-type) {
    margin-right: 16px;
  }
  ${MEDIA_QUERIES.w.btwMdLg} {
    flex-grow: 1;
    flex-basis: 0;
  }
  ${MEDIA_QUERIES.w.gteLg} {
    &:nth-of-type(1) {
      order: 3;
    }
    &:nth-of-type(2) {
      order: 2;
    }
    &:nth-of-type(3) {
      order: 1;
    }
  }
`;

const YardiMappingHeader = styled("div")`
  padding: 23px 30px;
`;

export {
  SetupYardiPropertyTitle,
  StyledTileInner,
  SelectExtension,
  StyledFooterBar,
  FooterButton,
  YardiMappingHeader,
};
