import React, { useEffect, useState } from "react";

import {
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe, PaymentMethodCreateParams } from "@stripe/stripe-js";
import { isEmpty } from "lodash";

import {
  useGetApplicantProfile,
  useGetApplicationInvitation,
  useGetPaymentById,
} from "renter/hooks/api";
import { ApplicationPaymentModel } from "renter/interfaces/api/applicationPayments";
import { NOTIFICATIONS } from "shared/config/constants";
import useModal from "shared/hooks/useModal";
import { UNPAID_STATUS } from "shared/interfaces/types";
import { openNotification } from "shared/utils/ui";

import ApplicantPaymentModal from "./ApplicantPaymentModal";

const { REACT_APP_STRIPE_PUBLIC_KEY } = process.env;
const REFETCH_TIMEOUT = 5000;

interface PaymentModalContext {
  applicationId?: number;
  payment?: any;
}

const ApplicantPaymentModalContainer = () => {
  const elements = useElements();
  const stripe = useStripe();
  const [refetchInterval, setRefetchInterval] = useState<number>();
  const [paymentSent, setPaymentSent] = useState<boolean>(false);
  const isWaitingForPayment = !!refetchInterval;
  const { currentModalContext, selfApi } = useModal();
  const { applicationId, payment }: PaymentModalContext = currentModalContext;

  const { applicantProfile } = useGetApplicantProfile();

  let paymentFromPaymentService = payment;

  let paymentDetails: ApplicationPaymentModel;

  if (paymentFromPaymentService) {
    const { payment } = useGetPaymentById(paymentFromPaymentService.paymentId, {
      refetchInterval,
    });

    const paymentStatus = payment?.status || "";

    paymentDetails = {
      amount: payment?.expectedAmount,
      applicantPaymentStatus: paymentStatus.toUpperCase(),
      currency: "usd",
      isPaid: !isEmpty(paymentStatus) && !UNPAID_STATUS.includes(paymentStatus),
      lineItems: payment?.lineItems,
      stripeClientSecret: payment?.stripeClientSecret,
      ipAddress: "",
      successfullySentToStripeAt: payment?.paidAt,
    };

    paymentFromPaymentService = payment;
  }

  const { refetchApplicationInvitation } =
    useGetApplicationInvitation(applicationId);

  const submitPayment = async ({ fullName }, formikBag) => {
    const card = elements.getElement(CardNumberElement);

    const billingDetails: PaymentMethodCreateParams.BillingDetails = {
      name: fullName,
      email: applicantProfile?.email,
      address: {
        line2: paymentDetails?.ipAddress,
      },
    };

    if (applicantProfile?.phoneNumber) {
      billingDetails.phone = applicantProfile.phoneNumber;
    }

    const result = await stripe.confirmCardPayment(
      paymentDetails?.stripeClientSecret,
      {
        payment_method: {
          card,
          billing_details: billingDetails,
        },
      }
    );

    if (result.error) {
      formikBag.setErrors({ nonFieldErrors: [result.error.message] });
    } else {
      setPaymentSent(true);
      await refetchApplicationInvitation();
      /** Note: Starting recurring checks of payment status */
      setRefetchInterval(REFETCH_TIMEOUT);
    }
  };

  const onPaymentCompleted = () => {
    setRefetchInterval(null);
    selfApi.closeDialogWithValue(true);

    openNotification(
      "Your payment has been successfully accepted",
      NOTIFICATIONS.info
    );
  };

  useEffect(() => {
    if (paymentDetails) {
      /** Note: The payment was sent to stripe but webhook has not yet set the field to is_paid=true */
      const isPaymentProcessing = paymentSent && !paymentDetails.isPaid;

      if (isPaymentProcessing && !refetchInterval) {
        /** Note: Starting recurring checks if payment is being processed  */
        setRefetchInterval(REFETCH_TIMEOUT);
      }

      /** Note: Stopping recurring calls if payment is completed */
      if (refetchInterval && paymentDetails.isPaid) {
        onPaymentCompleted();
      }
    }
  }, [paymentDetails]);

  return (
    <ApplicantPaymentModal
      applicantProfile={applicantProfile}
      paymentDetails={paymentDetails}
      submitPayment={submitPayment}
      isWaitingForPayment={isWaitingForPayment}
    />
  );
};

const StripeApplicantPaymentModal = () => {
  // NOTE: Avoiding reloading Stripe Promise on rerender
  // https://stackoverflow.com/questions/64693509/unsupported-prop-change-on-elements-you-cannot-change-the-stripe-prop-after-s
  const [stripePromise] = useState(() =>
    loadStripe(REACT_APP_STRIPE_PUBLIC_KEY)
  );
  return (
    <Elements stripe={stripePromise}>
      <ApplicantPaymentModalContainer />
    </Elements>
  );
};

export default StripeApplicantPaymentModal;
