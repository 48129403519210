import styled from "@emotion/styled";

import { RegularText as BaseRegularText } from "shared/components/Typography";

const RegularText = styled(BaseRegularText)`
  &.archived {
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
    }
  }
`;

export { RegularText };
