import styled from "@emotion/styled";

import { Popover } from "shared/components/Popover";
import Tag from "shared/components/Tag";
import Well from "shared/components/Well/Well";
import { COLORS } from "shared/config/constants";
import { AlignedCenterFlexPartialCss } from "shared/styles/commonStyles";

import { INTEGRATIONS_MARK_SIZE } from "./IntegrationMark.confg";

export const StyledPopover = styled(Popover)<{ size?: string }>`
  &.integration-mark {
    display: inline-flex;
    align-items: center;
    margin-left: ${({ size }) =>
      size === INTEGRATIONS_MARK_SIZE.sm ? "8px" : "11px"};
    vertical-align: middle;
  }
`;

export const DealIntegrationExtraInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  margin-bottom: 7px;
`;

export const DealIntegrationExtraInfo = styled(Well)`
  ${{ ...AlignedCenterFlexPartialCss }}
  border: none;
  padding: 6px 9px;
  &:not(:first-of-type) {
    margin-top: 4px;
  }
  .ant-divider {
    height: 8px;
    margin: 0 16px;
  }
  .inline-extra-info {
    display: flex;
  }
`;

export const PaidStatusContainer = styled.div<{ disabled?: boolean }>`
  ${{ ...AlignedCenterFlexPartialCss }};
  color: ${({ disabled }) => disabled && COLORS.disabledText};
  margin-left: 9px;
  .paid-icon {
    margin-left: 5px;
  }
`;

export const DealIntegrationExtraInfoTrackingContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4px;

  ul {
    padding: 0 20px;
    margin: 0;

    .status-message {
      padding-top: 8px;
    }
  }
`;

export const DealIntegrationExtraInfoTrackingMessages = styled(Well)`
  height: 100px;
  overflow-y: auto;
  border: none;
  padding: 6px 9px;
  margin-bottom: 10px;

  .messages-label {
    font-size: 13px;
    font-weight: 500;
  }
`;

export const YardiDealSyncStatusTag = styled(Tag)`
  display: flex;
  align-items: center;

  .status-text {
    margin-left: 8px;
  }
`;
