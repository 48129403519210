import React, { useEffect, useState } from "react";

import { Col, Row } from "antd";
import { Helmet } from "react-helmet";

import Container from "shared/components/Container";
import DropdownMenu from "shared/components/DropdownMenu";
import FilterBar from "shared/components/FilterBar";
import Icon from "shared/components/Icon";
import { BackLink, UnderlineLink } from "shared/components/Links";
import PageTitle from "shared/components/PageTitle";
import Spacer from "shared/components/Spacer";
import Tile from "shared/components/Tile";
import { formatPhone } from "shared/utils/ui";

import EmployeePortfoliosList from "./EmployeePortfoliosList";
import { EmployeeProps } from "./interfaces";
import {
  EmployeeActions,
  AssignToPropertiesLink,
  LoaderContainer,
  EmployeeStatus,
  EditEmployeeLink,
} from "./styled";
import { getPortfolios } from "./utils";

const Employee = ({
  employee,
  portfolios,
  assignToProperties,
  unassignFromProperty,
  deactivate,
  backLink,
  employeeLoading,
  portfoliosLoading,
  unassignFromPortfolio,
  openPropertySettings,
  createUnit,
  goToProperyPage,
  editEmployee,
}: EmployeeProps) => {
  const [search, setSearch] = useState();
  const [displayedPortfolios, setDisplayedPortfolios] = useState([]);

  const fullName = `${employee?.user?.firstName} ${employee?.user?.lastName}`;

  useEffect(() => {
    if (portfolios) {
      const filteredPortfolios = getPortfolios(portfolios, search);
      setDisplayedPortfolios(filteredPortfolios);
    }
  }, [search, portfolios]);

  return (
    <>
      <Helmet>
        <title>{fullName}</title>
      </Helmet>
      <Container noPaddingBottom>
        <BackLink.Container>
          <BackLink {...backLink} />
        </BackLink.Container>
        <PageTitle.Container>
          <PageTitle>
            <PageTitle.Icon>
              <Icon.PersonIcon />
            </PageTitle.Icon>
            {!employeeLoading && fullName}{" "}
            <EditEmployeeLink onClick={editEmployee}>
              Edit employee
            </EditEmployeeLink>
          </PageTitle>
        </PageTitle.Container>
      </Container>
      <Container noPaddingTop noMobilePaddingX>
        <Tile>
          <Tile.Inner>
            <Row type="flex">
              <Col xs={22} md={17} xl={18}>
                <Row type="flex" gutter={24}>
                  <Col xs={24} md={12} xl={6}>
                    <Tile.Label>Email</Tile.Label>
                    <UnderlineLink href={`mailto:${employee?.user?.email}`}>
                      {employee?.user?.email}
                    </UnderlineLink>
                  </Col>
                  <Col xs={0} xl={1}>
                    <Tile.Divider />
                  </Col>
                  <Col xs={24} md={12} xl={4}>
                    <Spacer hideGteMd />
                    <Tile.Label>Phone Number</Tile.Label>
                    <UnderlineLink href={`tel:${employee?.user?.phoneNumber}`}>
                      {formatPhone(employee?.user?.phoneNumber)}
                    </UnderlineLink>
                  </Col>
                  <Col xs={0} xl={1}>
                    <Tile.Divider />
                  </Col>
                  <Col xs={24} md={12} xl={4}>
                    <Spacer hideGteXl />
                    <Tile.Label>Role</Tile.Label>
                    {employee?.role?.name}
                  </Col>
                  <Col xs={0} xl={1}>
                    <Tile.Divider />
                  </Col>
                  <Col xs={24} md={12} xl={3}>
                    <Spacer hideGteXl />
                    <Tile.Label>Assigned to Properties</Tile.Label>
                    {employee?.numAssignedProperties}
                  </Col>
                  <Col xs={0} xl={1}>
                    <Tile.Divider />
                  </Col>
                  <Col xs={24} md={12} xl={3}>
                    <Spacer hideGteXl />
                    <Tile.Label>Status</Tile.Label>
                    <EmployeeStatus isActive={employee?.isActive}>
                      {employee?.isActive ? "Active" : "Deactivated"}
                    </EmployeeStatus>
                  </Col>
                  <Col xs={0} xl={1}>
                    <Tile.Divider />
                  </Col>
                </Row>
              </Col>
              <Col xs={2} md={7} xl={6}>
                <EmployeeActions>
                  {employee?.isActive && (
                    <AssignToPropertiesLink onClick={assignToProperties}>
                      Manage Access
                    </AssignToPropertiesLink>
                  )}
                  <DropdownMenu
                    placement="bottomRight"
                    items={
                      employee?.isActive
                        ? [
                            {
                              key: "MANAGE_ACCESS",
                              label: "Manage Access",
                              onClick: assignToProperties,
                              ltMdOnly: true,
                            },
                            {
                              key: "DEACTIVATE",
                              label: "Deactivate",
                              onClick: deactivate,
                            },
                          ]
                        : [
                            {
                              key: "DEACTIVATED",
                              label: "Employee was deactivated",
                              disabled: true,
                              onClick: () => {},
                            },
                          ]
                    }
                  />
                </EmployeeActions>
              </Col>
            </Row>
          </Tile.Inner>
        </Tile>
        <Tile
          header={{
            title: "Assigned to Properties",
          }}
        >
          <FilterBar
            alt
            search={{
              action: (value) => setSearch(value),
              placeholder: "Search by property or address",
              width: 350,
              debounce: true,
              disabled: portfoliosLoading,
            }}
          />
          {portfoliosLoading ? (
            <LoaderContainer>
              <Icon.LoadingSpinnerGreenLargeIcon data-testid="loading" />
            </LoaderContainer>
          ) : (
            <EmployeePortfoliosList
              unassignFromProperty={unassignFromProperty}
              unassignFromPortfolio={unassignFromPortfolio}
              openPropertySettings={openPropertySettings}
              createUnit={createUnit}
              portfolios={displayedPortfolios}
              hasSearch={!!search}
              employee={employee}
              goToProperyPage={goToProperyPage}
            />
          )}
        </Tile>
      </Container>
    </>
  );
};

export default Employee;
