import { createAction } from "redux-actions";

import * as types from "./types";

export const openModal = createAction(types.OPEN_MODAL, (id, context) => ({
  id,
  context,
}));
export const closeActiveModal = createAction(types.CLOSE_ACTIVE_MODAL);
export const unrenderActiveModal = createAction(types.UNRENDER_ACTIVE_MODAL);
export const updateModalContext = createAction(
  types.UPDATE_MODAL_CONTEXT,
  (context) => ({
    context,
  })
);
