import { Global } from '@emotion/react'
import { colors, typography, dropShadow } from 'shared/config/constants'

const VegaGlobalStyles = () => {
  return (
    <Global
      styles={{
        'p.vega': {
          marginBottom: 0
        },
        'p.vega.centered': {
          textAlign: 'center'
        },
        'pre.vega': {
          marginBottom: 0
        },
        'code.vega': {
          display: 'block'
        },
        'ul.vega': {
          margin: 0,
          padding: 0,
          listStyle: 'none'
        },
        // core
        '.vega.list.bottom > *': {
          paddingBottom: '1rem',
          marginBottom: '1rem',
          borderBottomStyle: 'solid',
          borderBottomWidth: '1px',
          borderBottomColor: colors.neutral.base
        },
        '.vega.list.bottom > :last-child': {
          paddingBottom: 0,
          margingBottom: 0,
          borderBottomStyle: 'none'
        },
        '.vega.link': {
          textDecoration: 'underline',
          textDecorationColor: colors.neutral.base
        },
        '.vega.link:hover': {
          textDecorationColor: colors.primary.base
        },
        '.vega.title': {
          fontSize: '1.45rem',
          lineHeight: 'normal'
        },
        '.vega.title.large': {
          fontSize: '1.75rem',
          fontWeight: 400
        },
        '.vega.title.x-large': {
          fontSize: '2rem'
        },
        '.vega.badge': {
          color: typography.textColors.light.primary,
          fontSize: '.8rem',
          padding: '.25rem',
          marginLeft: '.25rem',
          borderStyle: 'solid',
          borderColor: colors.neutral.base,
          backgroundColor: colors.shades[0],
          borderWidth: '1px'
        },
        '.vega.badge.small': {
          padding: '.15rem',
        },
        '.vega.button': {
          cursor: 'pointer'
        },
        '.vega.button:hover': {
          color: colors.accent.base
        },
        '.vega.icon': {
          width: '1.2rem',
          height: '1.2rem',
          verticalAlign: 'middle'
        },
        '.vega.icon.large': {
          width: '3rem',
          height: '3rem'
        },
        '.vega.icon.small': {
          width: '.85rem',
          height: '.85rem'
        },
        '.vega.icon.button': {
          fill: colors.primary.base,
          margin: 0
        },
        '.vega.icon.button:hover': {
          fill: colors.secondary.base
        },
        '.vega.toggle .ant-switch': {
          verticalAlign: 'text-top',
          marginLeft: '.5rem'
        },
        '.vega.toggle .ant-switch-checked': {
          backgroundColor: colors.secondary[500]
        },
        '.vega.off': {
          opacity: '.25'
        },
        '.vega.invert': {
          color: `${typography.textColors.dark.primary} !important`
        },
        // containers
        '.vega.container, .vega.content': {
          backgroundColor: colors.shades[0]
        },
        '.vega.content': {
          padding: '1rem'
        },
        '.vega.content.neutral': {
          backgroundColor: colors.neutral[200]
        },
        // flex
        '.vega.flex': {
          display: 'flex',
          gap: '1rem',
          flexWrap: 'wrap'
        },
        '.vega.flex.column': {
          flexDirection: 'column'
        },
        '.vega.flex.gap.small': {
          gap: '.5rem'
        },
        '.vega.flex.gap.large': {
          gap: '2rem'
        },
        '.vega.flex.no-wrap': {
          flexWrap: 'nowrap'
        },
        '.vega.flex.no-gap': {
          gap: 0
        },
        'section.vega.flex.column': {
          alignItems: 'stretch'
        },
        // grid
        '.vega.grid': {
          display: 'grid'
        },
        '.vega.grid.table > header': {
          borderRightStyle: 'solid',
          borderRightWidth: '1px',
          borderRightColor: colors.neutral.base,
          paddingLeft: '.5rem',
          paddingRight: '.5rem'
        },
        '.vega.grid.table > div': {
          padding: '2rem .5rem'
        },
        '.vega.grid.table > header, .vega.grid.table > div': {
          borderBottomStyle: 'solid',
          borderBottomWidth: '1px',
          borderBottomColor: colors.neutral.base
        },
        '.vega.grid.table > header.sortable .desc, .vega.grid.table > header.sortable .asc': {
          opacity: .5,
          width: '1.5rem',
          height: '1.5rem'
        },
        '.vega.grid.table > header.sorted .button': {
          fontWeight: 'bold'
        },
        '.vega.grid.table > header.sorted.desc .desc, .vega.grid.table > header.sorted.asc .asc': {
          opacity: 1
        },
        '.vega.grid.table > header.sort, .vega.grid.table > div.sort': {
          backgroundColor: colors.neutral[200]
        },
        '.vega.grid.table > div.hover': {
          cursor: 'pointer',
          backgroundColor: colors.neutral[100]
        },
        '.vega.sticky': {
          position: 'sticky'
        },
        '.vega.sticky.top': {
          top: 0
        },
        '.vega.sticky.bottom': {
          bottom: 0
        },
        '.vega.actions': {
          display: 'flex',
          gap: '.75rem',
          justifyContent: 'flex-end',
          alignSelf: 'flex-start',
          flexWrap: 'wrap'
        },
        '.vega.actions.end': {
          alignSelf: 'flex-end'
        },
        '.vega.actions.sticky': {
          padding: '.5rem'
        },
        '.vega.content.popover': {
          maxWidth: '16rem'
        },
        '.vega.card': {
          padding: '.5rem',
          borderStyle: 'solid',
          borderWidth: '1px',
          borderColor: colors.neutral.base,
          minWidth: '15rem',
          maxWidth: '25rem'
        },
        '.vega.status-bar': {
          borderWidth: '.5rem',
          borderColor: colors.neutral.base
        },
        '.vega.card.link, .vega.link:has(.vega.card)': {
          textDecoration: 'none'
        },
        '.vega.card.link:hover, .vega.link:hover:has(.vega.card)': {
          boxShadow: '1px 1px 10px rgba(0, 0, 0, 0.1)'
        },
        '.vega.card.status-bar': {
          borderLeftStyle: 'solid',
          paddingLeft: '1rem'
        },
        '.vega.card.service.status-bar': {
          borderRightWidth: '1px',
          borderTopWidth: '1px',
          borderBottomWidth: '1px',
          borderRightColor: colors.neutral.base,
          borderTopColor: colors.neutral.base,
          borderBottomColor: colors.neutral.base
        },
        '.vega.flex.spread, .vega.actions.spread, .vega.card.service.flex, header.vega.flex': {
          justifyContent: 'space-between'
        },
        'header.vega.status-bar': {
          borderBottomStyle: 'solid'
        },
        '.vega.entry-formula::before': {
          content: '\'formula\''
        },
        '.vega.result::before': {
          content: '\'result\''
        },
        '.vega.entry-formula::before, .vega.result::before': {
          position: 'absolute',
          top: 0,
          right: 0,
          padding: '.25rem',
          backgroundColor: colors.neutral.base
        },
        '.vega.lumen-score-label > .sub-title': {
          color: typography.textColors.dark.secondary
        },
        '.vega.icon.lumen': {
          fill: colors.shades['0']
        },
        '.vega.formula.formatted, .vega.code > .editor .vega': {
          padding: '.5rem !important'
        },
        '.vega.formula > pre': {
          margin: '0 !important'
        },
        '.vega.formula:not(.formatted) > pre, .vega.formula:not(.formatted) > pre *': {
          backgroundColor: `${colors.shades['0']} !important`
        },
        '.vega.formula-editor .code': {
          overflow: 'auto',
          flexGrow: 2
        },
        '.vega.formula-editor .editor': {
          fontFamily: 'monospace',
          fontSize: '1rem'
        },
        '.vega.formula-editor textarea.vega::selection': {
          '-webkit-text-fill-color': 'white'
        },
        '.vega.loader': {
          width: '.65rem',
          height: '.65rem',
          border: `2px solid ${typography.textColors.light.neutral}`,
          borderBottomColor: 'transparent',
          borderRadius: '50%',
          display: 'inline-block',
          boxSizing: 'border-box',
          animation: 'rotation 1s linear infinite',
          '@keyframes rotation': {
            '0%': {
              transform: 'rotate(0deg)'
            },
            '100%': {
              transform: 'rotate(360deg)'
            }
          }
        },
        '.vega.dropdown-container .vega.dropdown': {
          position: 'absolute',
          left: 0,
          top: 0,
          zIndex: '9',
          width: '100%',
          maxHeight: '25rem',
          overflowY: 'auto',
          boxShadow: dropShadow.xs.boxShadow
        },
        '.vega.filter-bar': {
          gridTemplateColumns: '3fr 1fr',
          gap: '1rem'
        },
        // group alignment
        'header.vega.flex, .vega.flex.centered, .vega.actions': {
          alignItems: 'center'
        },
        'section.vega.flex, .vega.flex.start': {
          alignItems: 'flex-start'
        },
        'header.vega, footer.vega': {
          padding: '.75rem'
        },
        'header.vega.no-padding, footer.vega.no-padding': {
          padding: 0
        },
        '.vega.indent': {
          paddingLeft: '1rem'
        },
        '.vega.label, .vega.icon': {
          marginRight: '.5rem'
        },
        '.vega.info.label, .vega.result .vega.badge, .vega.flex > .badge': {
          margin: 0
        },
        '.vega.history-entry, .vega.formula-editor .code, .vega.workflow-editor > :only-child, .vega.lumen.workflow-editor > .settings > .ant-tabs': {
          width: '100%'
        },
        '.vega.workflow-editor > *': {
          width: '50%'
        },
        '.vega.formula.formatted, .vega.formula.formatted > pre, .vega.formula-editor .editor': {
          height: '100%'
        },
        '.vega.sub-title.block, .vega.link.block': {
          display: 'block'
        },
        '.vega.entry-formula, .vega.result, .vega.dropdown-container': {
          position: 'relative'
        },
        '.vega.button.inline, .vega.icon.button.inline, .vega.loader.inline': {
          marginLeft: '.3rem'
        },
        // group font / text
        'pre.vega, code.vega': {
          textWrap: 'wrap',
          overflowWrap: 'anywhere'
        },
        'pre.vega.info': {
          fontFamily: typography.textStyles.desktop.paragraph1.fontFamily
        },
        '.vega.sub-title, .vega.label, .vega.name, .vega.info, .vega.amount, code.vega, pre.vega': {
          fontSize: '1rem'
        },
        '.vega.title.smaller, .vega.label.smaller, .vega.name.smaller, .vega.info.smaller': {
          fontSize: 'smaller'
        },
        '.vega.title.larger, .vega.label.larger, .vega.name.larger, .vega.info.larger': {
          fontSize: 'larger'
        },
        '.vega.title, .vega.label, .vega.name, .vega.info, .vega.amount': {
          fontWeight: 300
        },
        '.vega.title.small, .vega.badge.large': {
          fontSize: '1.15rem',
          fontWeight: 400
        },
        '.vega.title.x-large, .vega.badge': {
          fontWeight: 500
        },
        '.vega.id, .vega.badge.small, .vega.timestamp.small, .vega.card .vega.timestamp, .vega.disclaimer, .vega.explanation.model, .vega.explanation.model .timestamp, .vega.entry-formula::before, .vega.result::before': {
          fontSize: '.65rem'
        },
        '.vega.title, .vega.sub-title, .vega.label, .vega.name, .vega.button.label, header.vega.button': {
          textTransform: 'capitalize'
        },
        '.vega.badge, .vega.button': {
          textTransform: 'uppercase'
        },
        '.vega.id, .vega.slug, .vega.email': {
          textTransform: 'lowercase'
        },
        '.vega.button.key': {
          textTransform: 'unset'
        },
        // group colors
        '.vega.sub-title, .vega.label, .vega.slug': {
          color: typography.textColors.light.secondary
        },
        '.vega.id, .vega.disclaimer, .vega.formula.evaluated': {
          color: typography.textColors.light.neutral
        },
        '.vega.badge.success, .vega.badge.pass, .vega.badge.passed, .vega.badge.complete, .vega.badge.in-progress, .vega.badge.next, .vega.badge.warn, .vega.badge.warning, .vega.badge.retry, .vega.badge.fail, .vega.badge.failed, .vega.badge.error': {
          borderStyle: 'none'
        },
        '.vega.badge.success, .vega.badge.pass, .vega.badge.passed, .vega.badge.complete, label.ant-radio-button-wrapper-checked.vega.pass, .vega.content.info.success': {
          color: typography.textColors.dark.primary,
          backgroundColor: colors.success.base
        },
        '.vega.badge.in-progress, .vega.badge.next, label.ant-radio-button-wrapper-checked.vega.in-progress, .vega.content.info.in-progress': {
          color: typography.textColors.dark.primary,
          backgroundColor: colors.secondary.base
        },
        '.vega.badge.warn, .vega.badge.warning, .vega.badge.retry, label.ant-radio-button-wrapper-checked.vega.warn, .vega.content.info.warn': {
          color: typography.textColors.dark.primary,
          backgroundColor: colors.warning.base
        },
        '.vega.badge.fail, .vega.badge.failed, .vega.badge.error, label.ant-radio-button-wrapper-checked.vega.fail, .vega.content.info.error': {
          color: typography.textColors.dark.primary,
          backgroundColor: colors.error.base
        },
        '.vega.status-bar.success, .vega.status-bar.pass, .vega.status-bar.passed, .vega.status-bar.complete, label.ant-radio-button-wrapper-checked.vega.pass': {
          borderColor: colors.success.base
        },
        '.vega.status-bar.in-progress, .vega.status-bar.next, label.ant-radio-button-wrapper-checked.vega.in-progress': {
          borderColor: colors.secondary.base
        },
        '.vega.status-bar.warn, .vega.status-bar.warning, .vega.status-bar.retry, label.ant-radio-button-wrapper-checked.vega.warn': {
          borderColor: colors.warning.base
        },
        '.vega.status-bar.fail, .vega.status-bar.failed, .vega.status-bar.error, label.ant-radio-button-wrapper-checked.vega.fail': {
          borderColor: colors.error.base
        },
        '.vega.lumen-score-label.pass, label.ant-radio-button-wrapper.vega.pass:hover': {
          color: colors.success.base
        },
        '.vega.lumen-score-label.warn, label.ant-radio-button-wrapper.vega.warn:hover': {
          color: colors.warning.base
        },
        '.vega.lumen-score-label.fail, label.ant-radio-button-wrapper.vega.fail:hover': {
          color: colors.error.base
        },
        '.vega.formula.formatted > pre, .vega.formula-editor .code, .vega.formula-editor .editor': {
          backgroundColor: `${colors.neutral['100']} !important`
        },
        '.vega.grid.table > header, .vega.table > header, .vega.table > footer': {
          backgroundColor: colors.neutral[100]
        }
      }}
    />
  )
}

export default VegaGlobalStyles
