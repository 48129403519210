import axios from "axios";

import ROUTES from "shared/config/apiRoutes";
import { transformRoute } from "shared/utils/routing";

import { API_URL } from "./setupApi";

const api = {
  getCompany: async (id) =>
    axios.get(`${API_URL}${transformRoute(ROUTES.company, { id })}`),
  getCompanies: () => axios.get(`${API_URL}${ROUTES.companies}`),
  getPermissions: (companyId) =>
    axios.get(`${API_URL}${ROUTES.companyPermissions}`, {
      headers: {
        Company: companyId,
      },
    }),
};

export default api;
