import axios from "axios";

import ROUTES, { API_URL, VEGA_BASE_URL } from "shared/config/apiRoutes";
import {
  GetApplicantUserProfileResponse,
  UserProfile,
} from "shared/interfaces/api/auth";
import { ApiError } from "shared/lib/errors";
import {
  getActiveApp
} from "shared/utils/auth";

const api = {
  getUserProfile: () =>
    axios
      .get<UserProfile>(`${API_URL}${ROUTES.userProfile}`)
      .then(({ data }) => data),
  login: async (email, password) => {
    const response = await axios.post(
      `${VEGA_BASE_URL}/api/auth/login`,
      {
        email,
        password
      },
      {
        // NOTE: An `axios-auth-refresh` option. Needed to skip the automatic token refresh if this call fails.
        skipAuthRefresh: true,
      }
    );

    return response.data;
  },
  loginSelfSignedDeepLink: (linkHash) =>
    axios.post(
      `${API_URL}${ROUTES.loginSelfSignedDeepLink}`,
      { linkHash, app: getActiveApp() },
      {
        // NOTE: An `axios-auth-refresh` option. Needed to skip the automatic token refresh if this call fails.
        skipAuthRefresh: true,
      }
    ).then(({ data }) => data),
  requestNewToken: async (refreshToken) => {
    const response = await axios.post(
      `${VEGA_BASE_URL}/api/auth/sso`,
      {
        refreshToken,
      },
      {
        // NOTE: we need to skip the global error handling because errors from this request are handled specifically
        // (there's a try-catch where on error we logout the user)
        skipInterceptorErrorHandling: true,
        // NOTE: An `axios-auth-refresh` option. Needed to skip the automatic token refresh if this call fails.
        skipAuthRefresh: true,
      }
    );

    return response.data;
  },
  setPassword: async (token, password) => {
    try {
      return await axios.post(`${API_URL}${ROUTES.setPassword}`, {
        link_hash: token,
        password,
      });
    } catch (error) {
      if (error.statusCode === 400 && error?.errors?.errors) {
        // Transform error details schema into something Formik can use
        throw new ApiError(error.statusCode, error.errors.errors);
      } else {
        throw error;
      }
    }
  },
  validateSetPasswordToken: async (token) => {
    const response = await axios.get(
      `${API_URL}${ROUTES.validateSetPasswordToken}`,
      {
        params: {
          link_hash: token,
        },
      }
    );
    const updatedResponse = { ...response };
    updatedResponse.data = {
      ...response.data,
      email: response.data.userEmail,
    };
    delete updatedResponse.data.userEmail;
    return updatedResponse.data;
  },
  sendNewInvitationLink: async (token) => {
    const response = await axios.post(
      `${API_URL}${ROUTES.sendNewInvitationLink}`,
      { link_hash: token }
    );
    const updatedResponse = { ...response };
    updatedResponse.data = {
      ...response.data,
      email: response.data.userEmail,
    };
    delete updatedResponse.data.userEmail;
    return updatedResponse;
  },
  sendPasswordRecoveryEmail: async (email) => {
    const response = await axios.post(
      `${API_URL}${ROUTES.sendPasswordRecoveryEmail}`,
      {
        user_email: email,
      }
    );
    return response;
  },
  validateResetPasswordToken: async (token) => {
    const response = await axios.get(
      `${API_URL}${ROUTES.validateResetPasswordToken}`,
      {
        params: {
          link_hash: token,
        },
      }
    );
    const updatedResponse = { ...response };
    updatedResponse.data = {
      ...response.data,
      email: response.data.userEmail,
    };
    delete updatedResponse.data.userEmail;
    return updatedResponse.data;
  },
  resetPassword: async (token, password) => {
    const response = await axios.post(`${API_URL}${ROUTES.resetPassword}`, {
      link_hash: token,
      password,
    });
    return response;
  },
  getApplicantUserProfile: async () => {
    const response = await axios.get<GetApplicantUserProfileResponse>(
      `${API_URL}${ROUTES.applicantUserProfile}`
    );
    return {
      ...response.data,
      ...response.data.profile,
    } as UserProfile;
  },
  setApplicantUserProfile: async (params: UserProfile) => {
    const response = await axios.put(
      `${API_URL}${ROUTES.applicantUserProfile}`,
      {
        birthdate: params?.birthdate,
        profile: {
          firstName: params?.firstName,
          middleName: params?.middleName,
          lastName: params?.lastName,
          phoneNumber: params?.phoneNumber,
        },
      }
    );
    const updatedResponse = { ...response };
    updatedResponse.data = {
      ...response.data,
      ...response.data.profile,
    };
    delete updatedResponse.data.profile;
    return updatedResponse;
  },
};

export default api;
