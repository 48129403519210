import React, { ReactNode, useMemo, useRef } from "react";

import { Col, Row } from "antd";
import { FormikProps } from "formik";
import startCase from "lodash/startCase";

import {
  ApplicationChargesModel,
  FeeInventoryItem,
  FeePaymentPoint,
  FeeTarget,
} from "manager/interfaces/api";
import FlexContainer from "shared/components/FlexContainer";
import {
  FormPromptShell,
  MaskedInput,
  RadioGroup,
} from "shared/components/Form";
import Icon from "shared/components/Icon";
import Skeleton from "shared/components/Skeleton";
import Spacer from "shared/components/Spacer";
import Tile from "shared/components/Tile";
import Tooltip from "shared/components/Tooltip";
import {
  RegularText,
  SmallText,
  SmallTitle,
} from "shared/components/Typography";
import {
  deferred,
  getFeeManagementSubtitle,
  isEmptyValue,
} from "shared/utils/misc.util";

import { printDollarsFromCents } from "shared/utils/dollar-print";
import YardiUnitTypesTable from "../YardiUnitTypesTable";

export interface HoldingDepositFormValues {
  amount?: number;
  holdingDepositPaymentPoint: FeePaymentPoint;
  holdingDepositChargeTarget: FeeTarget;
}

interface HoldingDepositProps {
  applicationCharges?: ApplicationChargesModel;
  isLoading: boolean;
  saveChanges: (values: HoldingDepositFormValues) => Promise<any>;
  objectId: number;
  showUnitTypeHoldingDepositMapping: boolean;
  openPaymentPointInfo: () => void;
}

const getFeeDescription = (
  resolvedFee: FeeInventoryItem,
  currentValue?: number
): React.ReactNode => {
  if (currentValue) {
    return (
      <SmallText>
        Holding deposit line displayed to the user:{" "}
        <strong>{printDollarsFromCents(currentValue)}</strong>
      </SmallText>
    );
  }

  if (currentValue === 0 || (isEmptyValue(currentValue) && !resolvedFee)) {
    return (
      <SmallText>
        Holding deposit will not be displayed to the applicant
      </SmallText>
    );
  }

  if (isEmptyValue(currentValue) && resolvedFee) {
    return (
      <>
        <SmallText>
          Holding deposit line displayed to the user:{" "}
          <strong>{printDollarsFromCents(resolvedFee.amount)}</strong>
        </SmallText>
        <Spacer size={Spacer.SIZES.xs} />
        <SmallText>
          This amount is based on{" "}
          {startCase(resolvedFee.level.toLocaleLowerCase())} Settings that are
          currently in place.
        </SmallText>
      </>
    );
  }

  return null;
};

const SectionShell = ({ children }: { children: ReactNode }) => {
  return (
    <Row gutter={[0, 16]}>
      <Col xxl={6} xl={8} lg={10} md={12} sm={24}>
        <Spacer size={Spacer.SIZES.sm} />
        {children}
      </Col>
    </Row>
  );
};

const HoldingDepositSettings = ({
  applicationCharges,
  isLoading,
  saveChanges,
  objectId,
  showUnitTypeHoldingDepositMapping,
  openPaymentPointInfo,
}: HoldingDepositProps) => {
  const formRef = useRef();

  const initialValues: HoldingDepositFormValues = useMemo(
    () => ({
      amount: applicationCharges?.amount,
      holdingDepositPaymentPoint:
        applicationCharges?.holdingDepositPaymentPoint,
      holdingDepositChargeTarget:
        applicationCharges?.holdingDepositChargeTarget,
    }),
    [applicationCharges]
  );

  const onSubmit = (
    values: HoldingDepositFormValues,
    formikBag: FormikProps<HoldingDepositFormValues>
  ) =>
    saveChanges(values).then(() => deferred(formikBag.resetForm({ values })));

  const feesDisabled = applicationCharges?.feesDisabled;
  const disableFeeManagement = applicationCharges?.disableFeeManagement;
  const showHoldingDeposits =
    !feesDisabled && !disableFeeManagement && showUnitTypeHoldingDepositMapping;

  const hasAmount = applicationCharges?.amount > 0;
  const showBottomBorderOnAmount = hasAmount || showHoldingDeposits;

  return (
    <FormPromptShell
      initialValues={initialValues}
      formRef={formRef}
      onSubmit={onSubmit}
      saveChanges={saveChanges}
    >
      {({ values }) => {
        const FeeDescription = getFeeDescription(
          applicationCharges?.resolvedFee,
          values.amount
        );

        return (
          <Skeleton loading={isLoading}>
            <Tile
              header={{
                title: "Holding deposits",
                subtitle: getFeeManagementSubtitle(
                  applicationCharges,
                  "Determine the deposit amount applicants need to pay to hold a unit"
                ),
                collapsibleOnDesktop: true,
                collapsibleOnMobile: true,
              }}
            >
              {!feesDisabled && !disableFeeManagement && (
                <Tile.Inner borderBottom={showBottomBorderOnAmount}>
                  <SectionShell>
                    <FlexContainer alignItems="center">
                      <SmallTitle>Default deposit amount</SmallTitle>&nbsp;
                      <Tooltip title="If the deposit amount is $0.00, a holding deposit will not be visible to applicants.">
                        <Icon.InfoIcon />
                      </Tooltip>
                    </FlexContainer>

                    <Spacer size={Spacer.SIZES.md} />
                    {/* @ts-ignore */}
                    <MaskedInput.Formik.Money
                      id="amount"
                      name="amount"
                      label="Deposit Amount"
                    />
                  </SectionShell>
                  {FeeDescription && (
                    <Row gutter={[0, 24]}>
                      <Col>{FeeDescription}</Col>
                      <Spacer size={Spacer.SIZES.sm} />
                    </Row>
                  )}
                </Tile.Inner>
              )}
              {hasAmount && (
                <>
                  <Tile.Inner borderBottom>
                    <SectionShell>
                      <FlexContainer alignItems="center">
                        <SmallTitle>
                          When to request a holding deposit
                        </SmallTitle>
                        &nbsp;
                        <Icon.InfoIcon
                          className="action-icon"
                          onClick={openPaymentPointInfo}
                        />
                      </FlexContainer>
                      <Spacer size={Spacer.SIZES.md} />
                      <RadioGroup
                        id="holdingDepositPaymentPoint"
                        name="holdingDepositPaymentPoint"
                        options={[
                          {
                            value: FeePaymentPoint.BEFORE_APPLICATION_SUBMIT,
                            label: "Before Application Submission",
                          },
                          {
                            value: FeePaymentPoint.AFTER_APPLICATION_APPROVE,
                            label: "After Application Approval",
                          },
                        ]}
                        block
                      />
                    </SectionShell>
                  </Tile.Inner>
                  <Tile.Inner borderBottom={showHoldingDeposits}>
                    <SectionShell>
                      <FlexContainer alignItems="center">
                        <SmallTitle>Where to apply holding deposit</SmallTitle>
                        &nbsp;
                        <Tooltip title="If enabled, VERO will automatically deduct the holding deposit amount from the selected option.">
                          <Icon.InfoIcon />
                        </Tooltip>
                      </FlexContainer>
                      <Spacer size={Spacer.SIZES.md} />
                      <RadioGroup
                        id="holdingDepositChargeTarget"
                        name="holdingDepositChargeTarget"
                        options={[
                          {
                            value: FeeTarget.SECURITY_DEPOSIT,
                            label: "Apply to Security Deposit",
                          },
                          {
                            value: FeeTarget.NONE,
                            label:
                              "Do not apply holding deposit to any other charge",
                          },
                        ]}
                        block
                      />
                    </SectionShell>
                  </Tile.Inner>
                </>
              )}
              {showHoldingDeposits && (
                <Tile.Inner>
                  <Row gutter={[0, 48]}>
                    <Col>
                      <Spacer size={Spacer.SIZES.sm} />
                      <FlexContainer alignItems="center">
                        <SmallTitle>Deposits based on unit type</SmallTitle>
                        &nbsp;
                        <Tooltip title="The default holding deposit is applied if the selected unit type is not listed or assigned a deposit in the table below.">
                          <Icon.InfoIcon />
                        </Tooltip>
                      </FlexContainer>
                      <RegularText weak>
                        The below unit types and corresponding holding deposits
                        were discovered in Yardi
                      </RegularText>
                    </Col>
                  </Row>
                  <Spacer size={Spacer.SIZES.md} />
                  <YardiUnitTypesTable propertyId={objectId} />
                </Tile.Inner>
              )}
            </Tile>
          </Skeleton>
        );
      }}
    </FormPromptShell>
  );
};

export default HoldingDepositSettings;
