import React from "react";

import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import StepConfirmation from "renter/components/MagicLink/StepConfirmation";
import MainBackground from "shared/components/Backgrounds/MainBackground";
import Icon from "shared/components/Icon";
import { refPropType } from "shared/utils/propTypes";

import {
  LoadingSpinner,
  Container,
  ContentContainer,
  VeroLogo,
  Modal,
} from "../styled";

import { MAGIC_LINK_LANDING_STEP } from "./magicLinkLanding.config";
import StepLanding from "./StepLanding";

const title = "Let's get started";

const MagicLinkLanding = ({
  submit,
  isCompanyProfileLoading,
  currentStep,
  email,
  logoUrl,
  formikRef,
}) => {
  const resolvedLogo = logoUrl ? (
    <img src={logoUrl} alt="Logo" className="logo" />
  ) : (
    <Icon.LogoGrayIcon />
  );

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container>
        <ContentContainer>
          <div className="logo-container">
            {!isCompanyProfileLoading && resolvedLogo}
          </div>
          <Modal className="modal">
            {isCompanyProfileLoading && <LoadingSpinner />}
            {!isCompanyProfileLoading && (
              <>
                {currentStep === MAGIC_LINK_LANDING_STEP.LANDING_STEP && (
                  <StepLanding submit={submit} formikRef={formikRef} />
                )}
                {currentStep === MAGIC_LINK_LANDING_STEP.CONFIRMATION_STEP && (
                  <StepConfirmation email={email} />
                )}
              </>
            )}
            <div className="powered-by inner">
              Powered by <VeroLogo className="logo" />
            </div>
          </Modal>
          <div className="powered-by">
            Powered by <VeroLogo className="logo" />
          </div>
        </ContentContainer>
        <MainBackground />
      </Container>
    </>
  );
};

MagicLinkLanding.propTypes = {
  submit: PropTypes.func.isRequired,
  isCompanyProfileLoading: PropTypes.bool.isRequired,
  currentStep: PropTypes.oneOf(Object.values(MAGIC_LINK_LANDING_STEP))
    .isRequired,
  email: PropTypes.string,
  logoUrl: PropTypes.string,
  formikRef: refPropType.isRequired,
};

MagicLinkLanding.defaultProps = {
  email: "",
  logoUrl: undefined,
};

export default MagicLinkLanding;
