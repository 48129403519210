import React from "react";

import { useFormikContext } from "formik";

import { ConfigurationPanel, LumenFilters } from "manager/components/Lumen";
import {
  LUMEN_SECTION_TYPE,
  LUMEN_SECTION_TYPE_LABELS,
} from "manager/config/lumen.config";
import { SwitchTitle } from "shared/components/Form/SwitchTitle";

import { LumenFormulaFormikValues } from "./interfaces";

const LumenFormulaStepHousingRecords = () => {
  const { values, setFieldValue } =
    useFormikContext<LumenFormulaFormikValues>();
  return (
    <>
      <strong>
        <SwitchTitle
          id="useHousingCourtRecords"
          title={
            LUMEN_SECTION_TYPE_LABELS[LUMEN_SECTION_TYPE.HOUSING_COURT_RECORDS]
          }
          value={values.useHousingCourtRecords}
          onChange={(value) => setFieldValue("useHousingCourtRecords", value)}
          noPadding
          disabled={!values.canUseHousingCourtRecords}
          tooltip={
            !values.canUseHousingCourtRecords
              ? "Eviction related proceedings are not allowed on this formula because it is assigned to a property in a state or a county where using these kinds of records is not allowed."
              : null
          }
        />
      </strong>
      {/* @ts-ignore */}
      <ConfigurationPanel isEnabled={!!values.useHousingCourtRecords}>
        <LumenFilters
          lumenSection={LUMEN_SECTION_TYPE.HOUSING_COURT_RECORDS}
          filtersProp="filterHousingCourtRecords"
          crucialFiltersText={`Any records that are considered "Crucial" will cause the applicants and group to be flagged as "Negative" and the suggested action will be "Decline"`}
          ignoredFiltersText={`Any records that are considered "Ignored" will not have any impact on the group or individual. The Lumen status and suggested action will not be impacted.`}
          safetyNetText={`Any records that do not meet the criteria of the above categories will be considered "Safety net". This will cause the applicant and group to be flagged as "Neutral" and the suggested action will be "Review Background".`}
          safetyNetProp="useHousingCourtRecordSafetyNet"
        />
      </ConfigurationPanel>
    </>
  );
};

export default LumenFormulaStepHousingRecords;
