import React from "react";

import { MenuItem, RadioButtonsTheme } from "./interfaces";
import { Container, RadioGroup, RadioButton } from "./styled";

interface RadioButtonsProps {
  menuItems: MenuItem[];
  activeItem: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  theme?: RadioButtonsTheme;
}

const RadioButtons = ({
  menuItems,
  activeItem,
  onChange,
  disabled = false,
  theme = undefined,
}: RadioButtonsProps) => (
  <Container
    data-testid="radio-buttons-container"
    themeName={theme}
    className="radio-buttons-container"
  >
    <RadioGroup
      defaultValue={activeItem}
      value={activeItem}
      disabled={disabled}
      buttonStyle="solid"
      onChange={(value) => onChange(value.target?.value)}
    >
      {menuItems.map((item) => (
        <RadioButton
          themeName={theme}
          key={item.key}
          value={item.key}
          data-testid={`button-${item.key}`}
        >
          {item.name}
        </RadioButton>
      ))}
    </RadioGroup>
  </Container>
);

export default RadioButtons;
