import React from "react";

import { Row, Col } from "antd";
import { Formik } from "formik";

import { Input, VeroFormField } from "shared/components/Form";
import Icon from "shared/components/Icon";
import { Modal } from "shared/components/Modal";
import Spacer from "shared/components/Spacer";
import Tooltip from "shared/components/Tooltip";
import {
  RegularText,
  SmallText,
  LargeTitle,
} from "shared/components/Typography";

import { Field } from "../interfaces";

import { EnumValidationSchema } from "./config";
import { SubTitleContainer, ColumnInputWrapper } from "./styled";

export interface EnumFieldDescriptionModalProps {
  fieldType: string;
  fieldsRow: Field;
  isBluemoonFieldUpdating?: boolean;
  tooltipMessage?: string;
  submit?: (values: Field) => void;
}

const EnumFieldDescriptionModal = ({
  fieldType,
  fieldsRow,
  isBluemoonFieldUpdating,
  tooltipMessage,
  submit,
}: EnumFieldDescriptionModalProps) => (
  <Formik
    initialValues={fieldsRow}
    onSubmit={submit}
    validationSchema={EnumValidationSchema}
  >
    {({
      submitForm,
      isSubmitting,
      values,
      setFieldValue,
      handleBlur,
      dirty,
      isValid,
    }) => (
      <Modal
        title="Field Descriptions"
        submitButtonLabel="Save"
        submit={isValid && submitForm}
        submitting={isSubmitting || isBluemoonFieldUpdating}
        submitButtonDisabled={
          !dirty || !isValid || isSubmitting || isBluemoonFieldUpdating
        }
        closeOnSubmit={false}
        subtitle={
          <SubTitleContainer alignItems="center">
            <RegularText weak>{`Data Style • `}&nbsp;</RegularText>
            <Tooltip title={tooltipMessage} placement="topLeft" trigger="hover">
              <SubTitleContainer alignItems="center">
                <RegularText>{`${fieldType}`}</RegularText>
                <Icon.InputInfoIcon />
              </SubTitleContainer>
            </Tooltip>
          </SubTitleContainer>
        }
      >
        <Modal.Body>
          <Row gutter={[24, 0]} type="flex" align="bottom">
            <Col xs={11}>
              <SmallText weak>System Value</SmallText>
              {fieldsRow?.bluemoonChoices.map((bluemoonChoice) => (
                <ColumnInputWrapper key={bluemoonChoice?.option}>
                  <VeroFormField
                    as={Input}
                    name={bluemoonChoice?.option}
                    id={bluemoonChoice?.option}
                    value={bluemoonChoice?.option}
                    disabled
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldValue("sample", e.target.value)
                    }
                    onBlur={handleBlur}
                  />
                  <Spacer />
                </ColumnInputWrapper>
              ))}
            </Col>
            <Col xs={2}>
              {fieldsRow?.bluemoonChoices.map((item) => (
                <ColumnInputWrapper key={item.option}>
                  <LargeTitle>=</LargeTitle>
                  <Spacer />
                </ColumnInputWrapper>
              ))}
            </Col>
            <Col xs={11}>
              <SmallText weak>Customizeable Display Value</SmallText>
              {fieldsRow?.choices.map((choice, index) => (
                <ColumnInputWrapper key={`choices[${choice.id}]`}>
                  <VeroFormField
                    as={Input}
                    name={`choices[${index}]`}
                    id={`choices[${index}]`}
                    value={fieldsRow.choices[index]?.nickname}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldValue(`choices[${index}]`, {
                        ...fieldsRow?.choices[index],
                        nickname: e.target.value,
                      })
                    }
                    onBlur={handleBlur}
                  />
                  <Spacer />
                </ColumnInputWrapper>
              ))}
            </Col>
          </Row>
          <RegularText weak>
            Descriptions to guide the user during lease build
          </RegularText>
          <Spacer />
          <Row gutter={[20, 25]}>
            <Col xs={24}>
              <VeroFormField
                as={Input}
                name="sample"
                id="sample"
                label="Field Sample"
                value={values.sample}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFieldValue("sample", e.target.value)
                }
                onBlur={handleBlur}
              />
              <SmallText weak>
                Input sample to be displayed on the lease builder
              </SmallText>
            </Col>
          </Row>
          <Row gutter={[20, 25]}>
            <Col xs={24}>
              <VeroFormField
                as={Input}
                name="description"
                id="description"
                label="Field Description"
                value={values.description}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFieldValue("description", e.target.value)
                }
                onBlur={handleBlur}
              />
              <SmallText weak>
                Further details to be displayed on the lease builder
              </SmallText>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    )}
  </Formik>
);

export default EnumFieldDescriptionModal;
