import React from "react";

import { Field, useFormikContext } from "formik";

import { Input } from "shared/components/Form";
import Icon from "shared/components/Icon";

import { Attachment, DeleteIconContainer, InputContainer } from "./styled";

type Props = {
  disabled: boolean;
  remove: (index: number) => any;
  index: number;
  errors?: Record<number, any>;
  // eslint-disable-next-line react/no-unused-prop-types
  name?: string;
};

const AddAttachmentsForm = ({ index, remove, disabled, errors }: Props) => {
  const { values } = useFormikContext<any>();
  const attachments = values?.applicants?.[index]?.additionalAttachments;

  return (
    <>
      {attachments?.map((_, i) => (
        // @ts-ignore
        <Attachment disabled={disabled}>
          {i > 0 && <br />}
          <InputContainer>
            <Field
              as={Input}
              name={`applicants.${index}.additionalAttachments.${i}`}
              id={`applicants.${index}.additionalAttachments.${i}`}
              label={`#${i + 1} Attachment Name`}
              disabled={disabled}
              error={errors?.[i]}
              maxLength={255}
            />
          </InputContainer>
          {attachments?.length > 1 && (
            <DeleteIconContainer>
              <Icon.HoverTracker
                Icon={Icon.DeleteSmallIcon}
                onClick={() => remove(i)}
                data-testid={`delete-icon-${index}-${i}`}
              />
            </DeleteIconContainer>
          )}
        </Attachment>
      ))}
    </>
  );
};

export default AddAttachmentsForm;
