import styled from "@emotion/styled";
import { Typography } from "antd";

import { Input } from "shared/components/Form";

const { Text } = Typography;

export const PageInput = styled(Input)({
  width: "55px",
});

export const Container = styled("div")({
  display: "flex",
  alignItems: "center",
  "& > *": {
    marginRight: "10px",
  },
});

export const Label = styled(Text)({
  whiteSpace: "nowrap",
});
