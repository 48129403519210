import React, { useState } from "react";

import forIn from "lodash/forIn";
import isEmpty from "lodash/isEmpty";
import moment from "moment";

import { DROPDOWN_FILTER_MODES } from "shared/components/FilterBar";
import {
  Select as StyledSelect,
  StyledMultiselect,
} from "shared/components/FilterBar/styled";
import { DatePicker, Select } from "shared/components/Form";
import SwitchLight from "shared/components/Form/SwitchLight/SwitchLight";
import { MobileFiltersProps } from "shared/components/MobileFilters/interfaces";
import { Modal } from "shared/components/Modal";
import Tile from "shared/components/Tile";
import useModal from "shared/hooks/useModal";

import { StyledTile } from "./styled";

const { Option } = Select;

const FilterMobileModal = () => {
  const {
    currentModalContext: {
      title,
      dropdownFilters,
      checkboxFilters,
      dateRange,
      setFilters,
      clearFilters,
      loadingDropdownOptions,
      onDropdownVisibleChange,
    },
    closeActiveModalDialog,
  }: {
    currentModalContext: MobileFiltersProps;
    closeActiveModalDialog: () => void;
  } = useModal();

  const [dropdownFiltersState, setDropdownFiltersState] = useState({});
  const [checkboxFiltersState, setCheckboxFiltersState] = useState({});
  const [dateFilterState, setDateFilterState] = useState({
    [dateRange?.date1Id]: dateRange?.fromValue,
    [dateRange?.date2Id]: dateRange?.toValue,
  });

  const submitFilters = () => {
    if (setFilters) {
      setFilters({
        ...dropdownFiltersState,
        ...dateFilterState,
        ...checkboxFiltersState,
      });
    } else {
      forIn(dropdownFiltersState, (value, key) => {
        dropdownFilters.find((item) => item.key === key).onChange(value);
      });
      if (dateRange) {
        dateRange.setValues(dateFilterState);
      }
    }
  };

  return (
    <Modal
      title={title || "Filters"}
      submit={submitFilters}
      submitButtonLabel="Filter"
      cancelLinkLabel={!isEmpty(clearFilters) ? "Clear all" : ""}
      onCancelLinkClick={() => {
        clearFilters.onClick();
        closeActiveModalDialog();
      }}
      fullScreen
    >
      <Modal.Body noPaddingX>
        {dropdownFilters &&
          dropdownFilters.map(
            ({
              key: dropdownKey,
              label: dropdownLabel,
              items,
              value,
              mode,
            }) => {
              return mode === DROPDOWN_FILTER_MODES.multiselect ? (
                <StyledTile
                  header={{
                    title: dropdownLabel,
                    collapsibleOnDesktop: false,
                    collapsibleOnMobile: true,
                  }}
                  key={dropdownKey}
                >
                  <Tile.Inner inset>
                    {/* @ts-ignore */}
                    <StyledMultiselect
                      /* @ts-ignore */
                      value={value}
                      /* @ts-ignore */
                      options={items}
                      placeholder={dropdownLabel}
                      onChange={(val) =>
                        setDropdownFiltersState({
                          ...dropdownFiltersState,
                          [dropdownKey]: val,
                        })
                      }
                      id={dropdownKey}
                      name={dropdownKey}
                      onDropdownVisibleChange={onDropdownVisibleChange}
                      loading={loadingDropdownOptions}
                      dropdownMatchSelectWidth={false}
                    />
                  </Tile.Inner>
                </StyledTile>
              ) : (
                <StyledTile
                  header={{
                    title: dropdownLabel,
                    collapsibleOnDesktop: false,
                    collapsibleOnMobile: true,
                  }}
                  key={dropdownKey}
                >
                  <Tile.Inner inset>
                    {/* @ts-ignore */}
                    <StyledSelect
                      data-testid="select"
                      value={value || items[0].key}
                      onChange={(val) =>
                        setDropdownFiltersState({
                          ...dropdownFiltersState,
                          [dropdownKey]: val,
                        })
                      }
                      id={dropdownKey}
                      onDropdownVisibleChange={onDropdownVisibleChange}
                      loading={loadingDropdownOptions}
                    >
                      {items.map(({ label, key }) => (
                        <Option value={key} key={key}>
                          {label}
                        </Option>
                      ))}
                    </StyledSelect>
                  </Tile.Inner>
                </StyledTile>
              );
            }
          )}

        {checkboxFilters &&
          checkboxFilters.map(({ key: checkboxKey, label, value }) => (
            <StyledTile
              header={{
                title: label,
                collapsibleOnDesktop: false,
                collapsibleOnMobile: true,
              }}
              key={checkboxKey}
            >
              <Tile.Inner inset>
                <SwitchLight
                  id={checkboxKey}
                  value={checkboxFiltersState[checkboxKey] ?? value}
                  onChange={(ev) => {
                    setCheckboxFiltersState({
                      ...checkboxFiltersState,
                      [checkboxKey]: ev,
                    });
                  }}
                  title={<span>{label}</span>}
                  noPadding
                />
              </Tile.Inner>
            </StyledTile>
          ))}

        {dateRange && (
          <StyledTile
            header={{
              title: dateRange.label,
              collapsibleOnDesktop: false,
              collapsibleOnMobile: true,
            }}
          >
            <Tile.Inner inset>
              {/* @ts-ignore */}
              <DatePicker
                setFieldValue={(name, date) =>
                  setDateFilterState({
                    [name]: date,
                    [dateRange.date2Id]: null,
                  })
                }
                id={dateRange.date1Id}
                name={dateRange.date1Id}
                label="From"
                value={dateFilterState[dateRange.date1Id]}
                disabled={dateRange.disabled}
              />
              <br />
              {/* @ts-ignore */}
              <DatePicker
                setFieldValue={(val, date) =>
                  setDateFilterState({ ...dateFilterState, [val]: date })
                }
                id={dateRange.date2Id}
                name={dateRange.date2Id}
                label="To"
                value={dateFilterState[dateRange.date2Id]}
                disabledDate={(date) =>
                  dateFilterState[dateRange.date1Id] &&
                  moment(date).isBefore(dateFilterState[dateRange.date1Id])
                }
                disabled={dateRange.disabled}
              />
            </Tile.Inner>
          </StyledTile>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default FilterMobileModal;
