import { createAction } from "redux-actions";

import * as types from "./types";

export const receiveUser = createAction(types.RECEIVE_USER, (user) => ({
  user,
}));
export const receiveUsers = createAction(types.RECEIVE_USERS, ({ users }) => ({
  users,
}));
