import React from "react";

import { Row, Col } from "antd";

import CircularPercentageBar from "shared/components/CircularPercentageBar";
import ConditionalText from "shared/components/ConditionalText";
import { COLORS as BASE_COLORS } from "shared/config/constants";

import Tile from "../Tile";
import { RegularText } from "../Typography";

import { VORScoreContainer, ChartContainer } from "./styled";

export interface VoRScoreProps {
  surveyScore: number;
  percentage: number;
}

const VoRScore = ({ surveyScore, percentage }: VoRScoreProps) => {
  return (
    <VORScoreContainer noBorder>
      <ConditionalText
        className="popup-text"
        placeholderPopoverText={
          "NPS is the positive rating score in the Verification of" +
          " Residency; out of the total possible."
        }
      />
      <Tile.Inner noPaddingBottom>
        <RegularText strong>VoR SCORE</RegularText>
      </Tile.Inner>
      <Tile.Inner noPadding>
        <ChartContainer alignItems="center" justifyContent="flex-end">
          <Row>
            <Col>
              <CircularPercentageBar
                percentage={percentage}
                customLabel={surveyScore}
                colour={BASE_COLORS.lightViolet}
              />
            </Col>
          </Row>
        </ChartContainer>
      </Tile.Inner>
    </VORScoreContainer>
  );
};
export default VoRScore;
