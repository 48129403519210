import React from "react";

import { Col } from "antd";

import FlexContainer from "shared/components/FlexContainer";

import {
  SandWatchIcon,
  TileNoIntegrationContainer,
  NotIntegrationTitle,
  NotIntegrationText,
  NotIntegrationDesc,
} from "./styled";

export const TileNoIntegration = () => (
  <Col>
    <TileNoIntegrationContainer
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <FlexContainer>
        <SandWatchIcon />
        <NotIntegrationTitle>Coming soon...</NotIntegrationTitle>
      </FlexContainer>
      <NotIntegrationText>Even more integrations</NotIntegrationText>
      <NotIntegrationDesc>
        Contact your account manager and tell us what partners to consider
      </NotIntegrationDesc>
    </TileNoIntegrationContainer>
  </Col>
);
