export const applicantPersonaIdentification =
  "requiresApplicantOccupantPersonaIdentification";
export const applicantArgyleVerification =
  "requiresApplicantOccupantArgyleVerification";
export const renterProfile = "requiresRenterProfileApplicantOccupant";
export const renterProfileLeasePreferences =
  "requiresLeasesPreferencesApplicant";
export const renterProfileReferences = "requiresReferencesApplicant";

/** Income & Assets */
export const incomeAndAssetVerification =
  "requiresIncomeAssetApplicantOccupant";
export const assetVerificationWhenLinkedEmployment =
  "requiresAssetVerifLinkedEmploymentApplicantOccupant";
export const blockApplicationSubmitIfNoIncome =
  "blockDealSubmitIfIncomeDataIsMissingApplicantOccupant";

/** Background and credit check */
export const backgroundCreditCheck =
  "requiresBackgroundCreditCheckApplicantOccupant";
export const allowManagerViewSSN = "allowManagerViewSsnApplicantOccupant";
export const countyCriminalSearch = "countyCriminalSearchApplicantOccupantSync";
export const usesConditionallyProceed = "usesConditionallyProceed";

/** Verification of Residency */
export const verificationOfResidency = "requiresVerificationOfResidency";

/** Residential History Applicants/Occupants */
export const residentialHistoryDurationFlag =
  "requiresResidentialHistoryDurationApplicantOccupant";
export const residentialHistoryDurationType =
  "residentialHistoryRangeApplicantOccupant";
export const residentialHistoryDurationValue =
  "residentialHistoryDurationApplicantOccupant";

/** Employment History Applicants/Occupants */
export const employmentHistoryDurationFlag =
  "requiresEmploymentHistoryDurationApplicantOccupant";
export const employmentHistoryDurationType =
  "employmentHistoryRangeApplicantOccupant";
export const employmentHistoryDurationValue =
  "employmentHistoryDurationApplicantOccupant";
