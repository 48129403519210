import React, { useMemo } from "react";

import { Col, Row } from "antd";
import { Form, Formik } from "formik";
import capitalize from "lodash/capitalize";
import { Helmet } from "react-helmet";
import * as Yup from "yup";

import { Button } from "shared/components/Button";
import Container from "shared/components/Container";
import {
  Input,
  Select,
  VeroFormField,
  PasswordInput,
} from "shared/components/Form";
import { BackLink, UnderlineLink } from "shared/components/Links";
import { IntegrationProvider } from "shared/config/constants";
import { EntrataLogoLargeSvg, RealPageLogoLargeSvg } from "shared/icons";
import { YardiLogo } from "shared/images";
import { BackLinkProps } from "shared/interfaces/misc";

import { YARDI_INTERFACES } from "./shared/yardi.config";
import { StyledIntegrationLogin, YardiVersion } from "./styled";

// eslint-disable-next-line react/prop-types
const FormFieldShell = ({ children }) => (
  <Row gutter={[0, 24]} data-testid="form-field">
    <Col span={24}>{children}</Col>
  </Row>
);

// TODO: Move these interfaces to separate file

interface CredentialsType {
  endpointUrl?: string;
  serverName?: string;
  databaseName?: string;
  platform?: string;
  username?: string;
  password?: string;
  interfaceName?: string;
  inventoryProviderCompanyId?: string;
  inventoryProvider: string;
}

interface IntegrationLoginProps {
  submit: (values: CredentialsType, setSubmitting: any) => void;
  cancel: () => void;
  backLink: BackLinkProps;
  disabled?: boolean;
  editMode?: boolean;
  savedYardiCredentials?: CredentialsType;
  yardiPlatformTypes?: string[];
  yardiVersion?: string;
  isSubmitLoading?: boolean;
  provider: IntegrationProvider;
}

const IntegrationLogin = ({
  provider,
  disabled,
  editMode,
  savedYardiCredentials,
  yardiPlatformTypes,
  submit,
  cancel,
  yardiVersion,
  isSubmitLoading,
  backLink,
}: IntegrationLoginProps) => {
  const title = capitalize(provider);

  const subTitle = editMode
    ? `Please update ${title} credentials and sign in to continue`
    : `Please sign in with your ${title} credentials to continue`;
  const saveButton = editMode ? "SAVE CHANGES" : "SIGN IN";

  // eslint-disable-next-line consistent-return
  const { Logo, ValidationSchema, initialValues } = useMemo(() => {
    // eslint-disable-next-line default-case
    switch (provider) {
      case IntegrationProvider.yardi:
        return {
          Logo: YardiLogo,
          ValidationSchema: Yup.object().shape({
            endpointUrl: Yup.string()
              .url("Company URL must be a valid URL")
              .required("Company URL is required"),
            serverName: Yup.string().required("Server name is required"),
            platform: Yup.string().required("Platform is required"),
            databaseName: Yup.string().required("Database name is required"),
            username: Yup.string().required("Username is required"),
            password: Yup.string().required("Password is required"),
            interfaceName: Yup.string().required("Interface name is required"),
          }),
          initialValues: {
            endpointUrl: savedYardiCredentials?.endpointUrl ?? "",
            serverName: savedYardiCredentials?.serverName ?? "",
            databaseName: savedYardiCredentials?.databaseName ?? "",
            platform: savedYardiCredentials?.platform ?? "",
            username: savedYardiCredentials?.username ?? "",
            password: savedYardiCredentials?.password ?? "",
            interfaceName: savedYardiCredentials?.interfaceName ?? "",
            inventoryProvider: provider,
          },
        };
      case IntegrationProvider.entrata:
        return {
          Logo: EntrataLogoLargeSvg,
          ValidationSchema: Yup.object().shape({
            endpointUrl: Yup.string()
              .url("Company URL must be a valid URL")
              .required("Company URL is required"),
            username: Yup.string().required("Username is required"),
            password: Yup.string().required("Password is required"),
          }),
          initialValues: {
            endpointUrl: savedYardiCredentials?.endpointUrl ?? "",
            username: savedYardiCredentials?.username ?? "",
            password: savedYardiCredentials?.password ?? "",
            inventoryProvider: provider,
          },
        };
      case IntegrationProvider.realPage:
        return {
          Logo: RealPageLogoLargeSvg,
          ValidationSchema: Yup.object().shape({
            inventoryProviderCompanyId:
              Yup.string().required("PMC ID is required"),
          }),
          initialValues: {
            inventoryProviderCompanyId:
              savedYardiCredentials?.inventoryProviderCompanyId ?? "",
            inventoryProvider: provider,
          },
        };
    }
  }, [provider, savedYardiCredentials]);

  return (
    <Container expand noPadding>
      <StyledIntegrationLogin>
        <Helmet>
          <title>{title}</title>
        </Helmet>

        <Container
          className="yardi-login-content"
          noMobilePaddingX
          noPaddingTop
          maxWidth="xl"
        >
          {backLink && (
            <BackLink.Container className="back-link">
              <BackLink {...backLink} />
            </BackLink.Container>
          )}
          <img src={Logo} alt={provider} />
          <p className="subtitle">{subTitle}</p>
          <Formik
            validationSchema={ValidationSchema}
            initialValues={initialValues}
            enableReinitialize
            validateOnMount
            onSubmit={(values, { setSubmitting }) => {
              submit(values, setSubmitting);
            }}
          >
            {({ submitForm, isSubmitting }) => (
              <Form>
                {provider !== IntegrationProvider.realPage ? (
                  <div>
                    <FormFieldShell>
                      <VeroFormField
                        id="endpointUrl"
                        name="endpointUrl"
                        label="Company URL"
                        type="text"
                        disabled={disabled}
                        as={Input}
                      />
                    </FormFieldShell>
                    {/* NOTE: find better way to handle forms for different providers */}
                    {IntegrationProvider.yardi === provider && (
                      <>
                        <FormFieldShell>
                          <VeroFormField
                            id="serverName"
                            name="serverName"
                            label="Server Name"
                            type="text"
                            disabled={disabled}
                            as={Input}
                          />
                        </FormFieldShell>
                        <FormFieldShell>
                          <VeroFormField
                            id="databaseName"
                            name="databaseName"
                            label="Database Name"
                            type="text"
                            disabled={disabled}
                            as={Input}
                          />
                        </FormFieldShell>
                        <FormFieldShell>
                          <VeroFormField
                            id="platform"
                            name="platform"
                            label="Platform"
                            disabled={disabled}
                            as={Select}
                          >
                            {yardiPlatformTypes?.map((yardiPlatform) => (
                              <Select.Option key={yardiPlatform}>
                                {`${yardiPlatform}`}
                              </Select.Option>
                            ))}
                          </VeroFormField>
                        </FormFieldShell>
                        <FormFieldShell>
                          <VeroFormField
                            id="interfaceName"
                            name="interfaceName"
                            label="Interface Name"
                            disabled={disabled}
                            as={Select}
                          >
                            {YARDI_INTERFACES.map((yardiInterface) => (
                              <Select.Option
                                key={yardiInterface.id}
                                value={yardiInterface.id}
                                data-testid={yardiInterface.id}
                              >
                                {yardiInterface.label}
                              </Select.Option>
                            ))}
                          </VeroFormField>
                        </FormFieldShell>
                      </>
                    )}
                    <FormFieldShell>
                      <VeroFormField
                        id="username"
                        name="username"
                        label="Username"
                        type="text"
                        disabled={disabled}
                        autoComplete="new-password"
                        as={Input}
                      />
                    </FormFieldShell>

                    <FormFieldShell>
                      <VeroFormField
                        id="password"
                        name="password"
                        label="Password"
                        disabled={disabled}
                        autoComplete="new-password"
                        as={PasswordInput}
                      />
                    </FormFieldShell>
                  </div>
                ) : (
                  <div>
                    <FormFieldShell>
                      <VeroFormField
                        id="inventoryProviderCompanyId"
                        name="inventoryProviderCompanyId"
                        label="PMC ID"
                        type="text"
                        disabled={disabled}
                        as={Input}
                      />
                    </FormFieldShell>
                  </div>
                )}
                <Button
                  loading={isSubmitLoading}
                  type="primary"
                  data-testid="submit-btn"
                  className="submit-button"
                  disabled={isSubmitting || disabled}
                  onClick={submitForm}
                >
                  {saveButton}
                </Button>
                {editMode && (
                  <div className="yardi-cancel-container">
                    <UnderlineLink disabled={isSubmitting} onClick={cancel}>
                      Cancel
                    </UnderlineLink>
                  </div>
                )}
                {yardiVersion && (
                  <YardiVersion>Version: {yardiVersion}</YardiVersion>
                )}
              </Form>
            )}
          </Formik>
        </Container>
      </StyledIntegrationLogin>
    </Container>
  );
};

export default IntegrationLogin;
