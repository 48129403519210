import cloneDeep from "lodash/cloneDeep";

import { generateId } from "shared/utils/misc.util";

import ModalManagerDataHandler from "./ModalManagerDataHandler";

const MODAL_EVENTS = Object.freeze({
  OPEN_MODAL: "OPEN_MODAL",
  DESTROY_MODAL: "DESTROY_MODAL",
  DESTROY_ALL: "DESTROY_ALL",
  CLOSE_ALL: "CLOSE_ALL",
  UPDATE_CONTEXT: "UPDATE_CONTEXT",
  UPDATE_STATE: "UPDATE_STATE",
});

const listeners = [];

let dataHandler = new ModalManagerDataHandler();

const notifyListeners = (type, data) => {
  listeners.forEach((cb) => cb(type, data));
};

const prepareOpenModalData = (
  id,
  component,
  data,
  closeDialog,
  closeDialogWithValue,
  destroyDialog,
  setClosingValue,
  resolveWithCloseValue
) => ({
  id,
  component,
  data,
  resolveWithCloseValue,
  selfApi: {
    closeDialog,
    closeDialogWithValue,
    destroyDialog,
    setClosingValue,
    closingValue: undefined,
  },
  visible: true,
});

const baseDestroyModal = (id, shouldNotifyListeners = true) => {
  const modalData = dataHandler.getCurrentModalById(id);
  if (modalData) {
    if (shouldNotifyListeners) {
      notifyListeners(MODAL_EVENTS.DESTROY_MODAL, modalData);
    }
    modalData.resolveWithCloseValue();
    dataHandler.removeCurrentModalById(id);
  }
};

const baseUpdateModalState = (id, visible, shouldNotifyListeners = true) => {
  const oldModalContext = dataHandler.getCurrentModalById(id);
  if (oldModalContext) {
    const updatedModalContext = {
      ...oldModalContext,
      visible,
    };
    dataHandler.updateCurrentModal(updatedModalContext);
    if (shouldNotifyListeners) {
      notifyListeners(MODAL_EVENTS.UPDATE_STATE, updatedModalContext);
    }
  }
};

/**
 * General Modal Manager.
 * Responsible for storing and handling the active modals (not rendering).
 */
class ModalManager {
  /**
   * Opens the provided component as a modal.
   * @param component the modal component
   * @param data the context/data for the modal
   * @param closeOtherModals closes other opened modals (default: true)
   * @returns {
   *  {
   *    closeDialog: (function(): void),
   *    destroyDialog: (function(): void),
   *    updateContext: (function(*=): void),
   *    id: *,
   *    afterClose: Promise
   *  }
   *}
   * an object representing the API for working with the opened modal.
   * Return description:
   *  - closeDialog: closes the current modal
   *  - updateContext: updates the context data of the current modal
   *  - id: the id of the current modal
   *  - afterClose: promise to be resolved on closing the modal (the value will be the <i>closingValue</i>)
   */
  static openModal(component, data, closeOtherModals = true) {
    if (closeOtherModals) {
      ModalManager.destroyAll();
    }
    const id = generateId("modal-");

    const updateContext = (context, partialUpdate) =>
      ModalManager.updateModalContext(id, context, partialUpdate);

    const closeDialog = () => ModalManager.updateModalState(id, false);
    const destroyDialog = () => ModalManager.destroyModal(id);

    const setClosingValue = (value) => {
      const modalData = dataHandler.getCurrentModalById(id);
      if (modalData) {
        modalData.selfApi.closingValue = value;
      }
    };

    let resolve = null;
    const afterClose = new Promise((resolveFn) => {
      resolve = resolveFn;
    });

    const resolveWithCloseValue = () => {
      const modalData = dataHandler.getCurrentModalById(id);
      resolve(modalData?.selfApi?.closingValue);
    };

    const closeDialogWithValue = (value) => {
      setClosingValue(value);
      closeDialog();
    };

    const newModalContext = prepareOpenModalData(
      id,
      component,
      data,
      closeDialog,
      closeDialogWithValue,
      destroyDialog,
      setClosingValue,
      resolveWithCloseValue
    );
    dataHandler.addNewModal(newModalContext);

    notifyListeners(MODAL_EVENTS.OPEN_MODAL, newModalContext);
    return {
      id: newModalContext.id,
      closeDialog,
      closeDialogWithValue,
      destroyDialog,
      updateContext,
      afterClose,
    };
  }

  /**
   * Destroys the modal with the provided id.
   * @param id the id of the modal
   */
  static destroyModal(id) {
    baseDestroyModal(id);
  }

  /**
   * Updates the context of the modal with the provided id.
   * @param id the id of the modal
   * @param data the updated context data
   * @param {Boolean} partialUpdate weather the context should be merged with the current context
   */
  static updateModalContext(id, data, partialUpdate) {
    const oldModalContext = dataHandler.getCurrentModalById(id);
    if (oldModalContext) {
      const updatedModalContext = {
        ...oldModalContext,
        data: partialUpdate
          ? {
              ...oldModalContext.data,
              ...data,
            }
          : data,
      };
      dataHandler.updateCurrentModal(updatedModalContext);
      notifyListeners(MODAL_EVENTS.UPDATE_CONTEXT, updatedModalContext);
    }
  }

  /**
   * Updates the state of the modal (visible or not).
   * @param id the id of the modal
   * @param visible the new state, visible or not
   */
  static updateModalState(id, visible) {
    baseUpdateModalState(id, visible);
  }

  /**
   * Close all modal instances.
   * (sets visible=false on all available modals)
   */
  static closeAll() {
    dataHandler
      .getAllCurrentModals()
      .map((data) => data.id)
      .reverse()
      .forEach((modalId) => baseUpdateModalState(modalId, false, false));
    notifyListeners(MODAL_EVENTS.CLOSE_ALL);
  }

  /**
   * Destroys all modal instances.
   */
  static destroyAll() {
    dataHandler
      .getAllCurrentModals()
      .map((data) => data.id)
      .reverse()
      .forEach((modalId) => baseDestroyModal(modalId, false));
    notifyListeners(MODAL_EVENTS.DESTROY_ALL);
  }

  /**
   * Gets a clone of the current modal data.
   */
  static getModalData() {
    return cloneDeep(dataHandler.getAllCurrentModals());
  }

  /**
   * Subscribes to the updates/events related to the modals handling.
   * @param cb the listener
   * @returns {{unsubscribe: unsubscribe}} object containing the unsubscribe function
   */
  static subscribe(cb) {
    listeners.push(cb);
    const unsubscribe = () => {
      const idx = listeners.indexOf(cb);
      if (idx > -1) {
        listeners.splice(idx, 1);
      }
    };
    return {
      unsubscribe,
    };
  }

  /**
   * WARNING: This is a private method used only for the tests.
   *
   * DON'T USE IT ANYWHERE ELSE!!!
   *
   */
  // eslint-disable-next-line no-underscore-dangle
  static _setDataHandler(newDataHandler) {
    dataHandler = newDataHandler;
  }
}

export { ModalManager, MODAL_EVENTS };
