import React, { useEffect } from "react";

import { Field, Formik, useFormikContext } from "formik";
import isEmpty from "lodash/isEmpty";

import {
  LUMEN_FILTER_MODE,
  LUMEN_FILTER_TYPES_PER_SECTION,
  LUMEN_SECTION_TYPE,
  LUMEN_FILTER_LABELS,
  LUMEN_CREDIT_RECORDS_SCOPE,
  LUMEN_FILTER_TYPE,
  LUMEN_FILTER_CONDITION_TYPE,
  LUMEN_FILTER_CONDITION_SUB_TYPE,
} from "manager/config/lumen.config";
import { Select } from "shared/components/Form";
import { Modal } from "shared/components/Modal";
import { LightText } from "shared/components/Typography";
import VerticalStaticNumberedSteps from "shared/components/VerticalStaticNumberedSteps";
import useModal from "shared/hooks/useModal";
import { generateId } from "shared/utils/misc.util";

import LumenFilterConditions, {
  CONDITIONS_VALIDATION_SCHEMA,
} from "../LumenFilterConditions";

import { EVICTION_CASE_TYPES } from "../NewCollectionsFilterModal/utils";

import { NewFilterModalContent } from "./styled";

const LABEL_PER_SECTION = Object.freeze({
  [LUMEN_SECTION_TYPE.CREDIT]: "credit profile",
  [LUMEN_SECTION_TYPE.CRIMINAL_RECORDS]: "criminal records",
  [LUMEN_SECTION_TYPE.HOUSING_COURT_RECORDS]: "eviction related proceedings",
});

const LABEL_PER_MODE = Object.freeze({
  [LUMEN_FILTER_MODE.CRUCIAL]: "Crucial",
  [LUMEN_FILTER_MODE.IGNORED]: "Ignored",
});

// eslint-disable-next-line react/prop-types
const FilterTypeSelector = ({ filterTypes = [], mode }) => {
  const { errors, setFieldTouched, setValues, values } = useFormikContext();

  const initFilter = (type) =>
    setValues({ mode, type, uiid: generateId() }, true);

  useEffect(() => {
    if (filterTypes?.length === 1 && isEmpty(values)) {
      initFilter(filterTypes[0]);
    }
  }, [filterTypes]);

  return (
    <div className="type-select">
      <Field
        name="type"
        id="type"
        label="Select Type"
        as={Select}
        error={errors.type}
        onChange={(value) => initFilter(value)}
        onBlur={() => setFieldTouched("type", true)}
        disabled={filterTypes.length <= 1}
      >
        {filterTypes.map((type) => (
          <Select.Option key={type}>{LUMEN_FILTER_LABELS[type]}</Select.Option>
        ))}
      </Field>
    </div>
  );
};

const getFilterTypes = (formValues, lumenSection, data) => {
  if (lumenSection === LUMEN_SECTION_TYPE.CREDIT) {
    const creditRecordsScope = formValues?.creditRecordsScope;
    // NOTE: We need to limit the options based on the "creditRecordsScope" only if it's a new filter or
    // the existing filter type matches the "creditRecordsScope"
    if (
      creditRecordsScope === LUMEN_CREDIT_RECORDS_SCOPE.ONLY_PUBLIC &&
      (!data || data.type === LUMEN_FILTER_TYPE.PUBLIC_RECORDS)
    ) {
      return [LUMEN_FILTER_TYPE.PUBLIC_RECORDS];
    }
    if (
      creditRecordsScope === LUMEN_CREDIT_RECORDS_SCOPE.ONLY_BANKRUPTCIES &&
      (!data || data.type === LUMEN_FILTER_TYPE.BANKRUPTCIES)
    ) {
      return [LUMEN_FILTER_TYPE.BANKRUPTCIES];
    }
  }
  return LUMEN_FILTER_TYPES_PER_SECTION[lumenSection];
};

const getInitialValues = (data) => {
  if (!data) {
    return null;
  }

  // NOTE: if there is formulaRevision that data is from BE and we need to map it
  if (!data.formulaRevision) {
    return data;
  }

  const conditions = data.conditions?.map((condition) => {
    if (
      condition.conditionType === LUMEN_FILTER_CONDITION_TYPE.KEYWORDS &&
      condition.conditionSubType === LUMEN_FILTER_CONDITION_SUB_TYPE.EVICTIONS
    ) {
      const keywords =
        condition.keywords?.filter((keyword) =>
          EVICTION_CASE_TYPES.includes(keyword)
        ) || [];
      const otherKeywords =
        condition.keywords?.filter(
          (keyword) => !EVICTION_CASE_TYPES.includes(keyword)
        ) || [];

      return {
        ...condition,
        keywords,
        otherKeywords,
        other: !!otherKeywords.length,
      };
    }

    return condition;
  });

  return { ...data, conditions };
};

const INITIAL_VALUES = {};

const NewFilterModal = () => {
  const {
    currentModalContext: { formValues, lumenSection, mode, data },
    selfApi,
  } = useModal();
  const filterTypes = getFilterTypes(formValues, lumenSection, data);
  const sectionLabel = LABEL_PER_SECTION[lumenSection];
  const modeLabel = LABEL_PER_MODE[mode];

  const onSubmit = (values) => {
    selfApi.closeDialogWithValue(values);
  };

  const initialValues = getInitialValues(data) || INITIAL_VALUES;
  const inEditMode = !!data;

  const title = inEditMode
    ? `Edit "${modeLabel}" filter for ${sectionLabel}`
    : `Add new "${modeLabel}" filter for ${sectionLabel}`;

  const submitButtonLabel = inEditMode ? "SAVE" : "ADD & SAVE";

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={CONDITIONS_VALIDATION_SCHEMA}
    >
      {({ isSubmitting, submitForm }) => (
        <Modal
          title={title}
          submitButtonLabel={submitButtonLabel}
          submit={submitForm}
          submitting={isSubmitting}
          closeOnSubmit={false}
          width="946px"
        >
          <Modal.Body>
            <NewFilterModalContent>
              <VerticalStaticNumberedSteps
                items={[
                  {
                    label: "Select the record type",
                    content: (
                      <FilterTypeSelector
                        filterTypes={filterTypes}
                        mode={mode}
                      />
                    ),
                  },
                  {
                    label: (
                      <>
                        Apply conditions <LightText>(optional)</LightText>
                      </>
                    ),
                    content: (
                      <LumenFilterConditions lumenSection={lumenSection} />
                    ),
                  },
                ]}
              />
            </NewFilterModalContent>
          </Modal.Body>
        </Modal>
      )}
    </Formik>
  );
};

export default NewFilterModal;
