import styled from "@emotion/styled";

import { COLORS } from "shared/config/constants";
import { ReactComponent as TrianglesImage } from "shared/images/triangle-diagonal-small.svg";

const StyledMarkdownTips = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    padding: 18px 32px;
    background: ${COLORS.lightViolet};
    position: relative;

    .header-title {
      color: ${COLORS.white};
    }

    .header-image {
      position: absolute;
      top: -2px;
      right: -10px;
    }
  }

  .content {
    padding: 32px;
    background: ${COLORS.violetTheme.lightBlueViolet};

    .tip {
      margin-bottom: 32px;

      .tip-title {
        color: ${COLORS.primary};
      }

      .tip-content {
        color: ${COLORS.white};
      }
    }
  }
`;

const GreenTrianglesImage = styled(TrianglesImage)`
  width: 120px;

  path {
    fill: ${COLORS.green};
  }
`;

export { StyledMarkdownTips, GreenTrianglesImage };
