import { Col, Row } from 'antd'
import pluralize from 'pluralize'

import Collapse from 'shared/components/Collapse'
import {
  CountyCriminalRecordsProps,
  CriminalRecordsType,
} from 'shared/components/CreditAndBackgroundCheck/interfaces'
import {
  BreakWordSpan,
  CountyCriminalRecordsTitle,
  CountyCriminalSearchStatusMessageWrapper,
  CriminalRecordsCount,
  CriminalRecordsPeriod,
  CriminalRecordsTitleContainer,
  NoCountyCriminalRecordsForApplicant,
} from 'shared/components/CreditAndBackgroundCheck/styled'
import {
  getCountyCriminalRecordHeader,
  getCriminalSearchStatusMessage,
  shouldShowCountyCriminalRecords,
  shouldShowCountyCriminalRecordsList,
  shouldShowCountyCriminalRecordsStatusMessage,
  shouldShowNoCountyCriminalRecordsMessage,
} from 'shared/components/CreditAndBackgroundCheck/utils'
import Icon from 'shared/components/Icon'
import Spacer from 'shared/components/Spacer'
import Tile from 'shared/components/Tile'
import { MediumTitle } from 'shared/components/Typography'
import { APPLICATION_COUNTY_CRIMINAL_SEARCH_STATUS } from 'shared/config/constants'

import {
  calcTileInnerBorderBottom,
  formatDate,
  getValueOrNA,
} from 'shared/utils/ui'

// when in the report we have this constant it means that the record does not belong to a specific state
// but to a category (like OFAC, Sex Offender, Most Wanted)
const EMPTY_STATE = '__'

type StateCriminalRecordsProps = {
  criminalRecords?: CriminalRecordsType
  hideCriminalRecords: boolean
  canAccessCountyCriminalRecords?: boolean
  countyCriminalRecords?: CountyCriminalRecordsProps
  countyCriminalSearchStatus?: typeof APPLICATION_COUNTY_CRIMINAL_SEARCH_STATUS
  hasCountyCriminalSearch?: boolean
  hasTuCountyCriminalSearch?: boolean
}

const StateCriminalRecords = ({
  canAccessCountyCriminalRecords,
  countyCriminalRecords,
  countyCriminalSearchStatus,
  criminalRecords,
  hasCountyCriminalSearch,
  hasTuCountyCriminalSearch,
  hideCriminalRecords,
}: StateCriminalRecordsProps) => {
  const criminalRecordsByState = criminalRecords?.criminalRecordGroup || []
  const criminalRecordsByCounty =
    countyCriminalRecords?.criminalRecordsByCounty || []

  const showCountyCriminalRecords = shouldShowCountyCriminalRecords(
    canAccessCountyCriminalRecords,
    countyCriminalSearchStatus,
    hasCountyCriminalSearch,
    hasTuCountyCriminalSearch,
  )

  const showCountyCriminalRecordsList = shouldShowCountyCriminalRecordsList(
    criminalRecordsByCounty,
  )

  const showNoCountyCriminalRecordsMessage =
    shouldShowNoCountyCriminalRecordsMessage(
      countyCriminalSearchStatus,
      criminalRecordsByCounty,
    )

  const countCriminalSearchStatusMessage = getCriminalSearchStatusMessage(
    countyCriminalSearchStatus,
  )

  const showCountyCriminalRecordsStatusMessage =
    shouldShowCountyCriminalRecordsStatusMessage(
      hasCountyCriminalSearch,
      hasTuCountyCriminalSearch,
      countCriminalSearchStatusMessage,
    )

  return (
    <>
      {!hideCriminalRecords && (
        <>
          <Tile
            header={{
              title: 'State Criminal Records',
            }}
          >
            {criminalRecordsByState.map((stateRecords, stateIndex) => (
              <Collapse
                key={`${stateRecords.state}-${stateRecords.category}`}
                small
                header={({ CollapseIcon, collapsed }) => (
                  <Tile.Inner
                    borderBottom={
                      calcTileInnerBorderBottom(
                        criminalRecordsByState,
                        stateIndex,
                      ) || !collapsed
                    }
                  >
                    <CriminalRecordsTitleContainer
                      data-testid={`criminal-records-${stateRecords.state}-${stateRecords.category}`}
                    >
                      <div>
                        {stateRecords.state !== EMPTY_STATE ? (
                          <strong>State {stateRecords.state},</strong>
                        ) : (
                          <strong>{stateRecords.category},</strong>
                        )}
                        <CriminalRecordsCount>
                          {pluralize(
                            'Record',
                            stateRecords.records.length,
                            true,
                          )}
                        </CriminalRecordsCount>
                      </div>
                      <CriminalRecordsPeriod>
                        {stateRecords.endDate
                          ? `${formatDate(
                              stateRecords.startDate,
                            )} - ${formatDate(stateRecords.endDate)}`
                          : `${formatDate(stateRecords.startDate)}`}
                      </CriminalRecordsPeriod>
                      <CollapseIcon />
                    </CriminalRecordsTitleContainer>
                  </Tile.Inner>
                )}
              >
                {stateRecords.records.map((record, recordIndex) => {
                  const recordKey = `${stateRecords.state}-${recordIndex}`
                  return (
                    <Tile.Inner
                      key={recordKey}
                      data-testid={`criminal-record-${recordIndex}`}
                      inset
                      borderBottom={
                        stateIndex === criminalRecordsByState.length - 1
                          ? calcTileInnerBorderBottom(
                              stateRecords.records,
                              recordIndex,
                            )
                          : true
                      }
                    >
                      <Row type="flex" gutter={[{ xs: 10, md: 15, xl: 0 }, 0]}>
                        <Col xs={12} md={4} lg={3} xl={2}>
                          <Tile.Label>Date</Tile.Label>
                          {formatDate(record.date)}
                        </Col>
                        <Col xs={12} md={5} lg={4} xl={4}>
                          <Tile.Label>Charge Type</Tile.Label>
                          {getValueOrNA(record.chargeText)}
                        </Col>
                        <Col xs={12} md={8} lg={5} xl={5}>
                          <Spacer hideGteMd />
                          <Tile.Label>Docket</Tile.Label>
                          <BreakWordSpan>{record.docket}</BreakWordSpan>
                        </Col>
                        <Col xs={0} xl={1}>
                          <Tile.Divider
                            bottomStick={
                              stateRecords.records.length !== 1 &&
                              recordIndex === 0
                            }
                            topStick={
                              stateRecords.records.length !== 1 &&
                              recordIndex === stateRecords.records.length - 1
                            }
                            fullHeight={
                              recordIndex !== 0 &&
                              recordIndex !== stateRecords.records.length - 1
                            }
                          />
                        </Col>
                        <Col xs={12} md={7} lg={4}>
                          <Spacer hideGteMd />
                          <Tile.Label>Outcome</Tile.Label>
                          {getValueOrNA(record.outcome)}
                        </Col>
                        <Col xs={12} md={4} lg={3} xl={5}>
                          <Spacer hideGteLg />
                          <Tile.Label>Severity</Tile.Label>
                          {getValueOrNA(record.severity)}
                        </Col>
                      </Row>
                    </Tile.Inner>
                  )
                })}
              </Collapse>
            ))}
          </Tile>
          {showCountyCriminalRecords && (
            <Tile
              header={{
                title: (
                  <CountyCriminalRecordsTitle alignItems="center">
                    County Criminal Records
                    {showCountyCriminalRecordsStatusMessage && (
                      <div className="warning-icon">
                        <Icon.OrangeExclamationSmall />
                      </div>
                    )}
                  </CountyCriminalRecordsTitle>
                ),
              }}
            >
              {showCountyCriminalRecordsStatusMessage && (
                <CountyCriminalSearchStatusMessageWrapper
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <MediumTitle className="message">
                    {countCriminalSearchStatusMessage}
                  </MediumTitle>
                </CountyCriminalSearchStatusMessageWrapper>
              )}
              {showNoCountyCriminalRecordsMessage && (
                <NoCountyCriminalRecordsForApplicant
                  alignItems="center"
                  justifyContent="center"
                >
                  <MediumTitle className="message">
                    Applicant has no county criminal records.
                  </MediumTitle>
                </NoCountyCriminalRecordsForApplicant>
              )}
              {showCountyCriminalRecordsList &&
                criminalRecordsByCounty.map(
                  (countyRecord, countyRecordIndex) => (
                    <Collapse
                      key={countyRecord.county}
                      small
                      header={({ CollapseIcon, collapsed }) => (
                        <Tile.Inner
                          borderBottom={
                            calcTileInnerBorderBottom(
                              criminalRecordsByState,
                              countyRecordIndex,
                            ) || !collapsed
                          }
                        >
                          {getCountyCriminalRecordHeader(
                            countyRecord,
                            CollapseIcon,
                          )}
                        </Tile.Inner>
                      )}
                    >
                      {countyRecord.records.map((record, recordIndex) => (
                        <Tile.Inner
                          key={record.courtRecordId}
                          data-testid={`county-criminal-record-${record.courtRecordId}`}
                          inset
                          borderBottom={
                            countyRecordIndex ===
                            criminalRecordsByCounty.length - 1
                              ? calcTileInnerBorderBottom(
                                  countyRecord.records,
                                  recordIndex,
                                )
                              : true
                          }
                        >
                          <Row
                            type="flex"
                            gutter={[{ xs: 10, md: 15, lg: 0 }, 0]}
                          >
                            <Col xs={12} md={4} lg={3}>
                              <Tile.Label>Date</Tile.Label>
                              {formatDate(record.date)}
                            </Col>
                            <Col xs={12} md={6} lg={5}>
                              <Tile.Label>Court Record</Tile.Label>
                              <BreakWordSpan>
                                {getValueOrNA(record.courtRecordId)}
                              </BreakWordSpan>
                            </Col>
                            <Col xs={0} xl={1}>
                              <Tile.Divider
                                bottomStick={
                                  countyRecord.records.length !== 1 &&
                                  recordIndex === 0
                                }
                                topStick={
                                  countyRecord.records.length !== 1 &&
                                  recordIndex ===
                                    countyRecord.records.length - 1
                                }
                                fullHeight={
                                  recordIndex !== 0 &&
                                  recordIndex !==
                                    countyRecord.records.length - 1
                                }
                              />
                            </Col>
                            <Col xs={12} md={5} lg={5}>
                              <Spacer hideGteMd />
                              <Tile.Label>Charge Status</Tile.Label>
                              <BreakWordSpan>
                                {record.chargeStatus}
                              </BreakWordSpan>
                            </Col>
                            <Col xs={12} md={5} lg={5}>
                              <Spacer hideGteMd />
                              <Tile.Label>Charge Description</Tile.Label>
                              <BreakWordSpan>
                                {getValueOrNA(record.chargeDescription)}
                              </BreakWordSpan>
                            </Col>
                            <Col xs={12} md={4} lg={5}>
                              <Spacer hideGteMd />
                              <Tile.Label>Severity</Tile.Label>
                              <BreakWordSpan>
                                {getValueOrNA(record.severity)}
                              </BreakWordSpan>
                            </Col>
                          </Row>
                        </Tile.Inner>
                      ))}
                    </Collapse>
                  ),
                )}
            </Tile>
          )}
        </>
      )}
    </>
  )
}

export default StateCriminalRecords
