import React from "react";

import { Typography } from "antd";

import { NoSearchResultsIcon } from "shared/icons";

import { Container } from "./styled";

const { Text } = Typography;

const PLACEHOLDER_TYPES = {
  search: "SEARCH",
  noData: "NO_DATA",
};

export interface PlaceholderProps {
  type: string;
  label: string;
  Icon?: any;
}

const Placeholder = ({ type, label, Icon }: PlaceholderProps) => (
  <Container data-testid="placeholder">
    {type === PLACEHOLDER_TYPES.search && (
      <NoSearchResultsIcon data-testid="no-search-results-icon" />
    )}
    {type === PLACEHOLDER_TYPES.noData && <Icon data-testid="no-data-icon" />}
    <Text style={{ marginLeft: "16px" }} type="secondary">
      {type === PLACEHOLDER_TYPES.search && "No Search Results"}
      {type === PLACEHOLDER_TYPES.noData && `No ${label} Here`}
    </Text>
  </Container>
);

Placeholder.TYPES = PLACEHOLDER_TYPES;

export default Placeholder;
