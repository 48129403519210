import React, { useMemo } from "react";

import { Field, Formik } from "formik";
import * as Yup from "yup";

import { LUMEN_FORMULA_TYPES } from "manager/config/lumen.config";
import {
  Checkbox,
  Input,
  Select,
  RadioGroup,
  VeroFormField,
} from "shared/components/Form";
import { Modal } from "shared/components/Modal";
import Spacer from "shared/components/Spacer";
import Tooltip from "shared/components/Tooltip";
import {
  RegularText,
  SmallText,
  SmallTitle,
} from "shared/components/Typography";
import Well from "shared/components/Well";
import useModal from "shared/hooks/useModal";
import { sanitizeStringSearchValue } from "shared/utils/string";

import { CreateLumenFormulaModalContent } from "./styled";

const INITIAL_VALUES = {
  formulaType: LUMEN_FORMULA_TYPES.blank,
  name: "",
  sections: {
    useCreditScore: true,
    useVerifications: true,
    useIncomeRentRatio: true,
    useAssets: false,
    useCollections: true,
    useCriminalRecords: true,
    useHousingCourtRecords: true,
  },
  formulaTemplateId: null,
};

const CreateLumenFormulaModal = () => {
  const {
    selfApi,
    currentModalContext: {
      usedNames,
      lumenFormulaTemplates,
      isLumenFormulaTemplatesLoading,
    },
  } = useModal();

  const ValidationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string()
          .trim()
          .required("Name is required")
          .test(
            "uniqueFormulaName",
            "The name of the formula must be unique",
            (value) => {
              const sanitizedValue = sanitizeStringSearchValue(value);
              return !usedNames.includes(sanitizedValue);
            }
          ),
        formulaTemplateId: Yup.mixed()
          .nullable()
          .when("formulaType", {
            is: LUMEN_FORMULA_TYPES.template,
            then: Yup.number()
              .nullable()
              .required("Please select a template formula"),
          }),
      }),
    [usedNames]
  );

  const submit = (values) => {
    selfApi.closeDialogWithValue(values);
  };

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      onSubmit={submit}
      validateOnMount
      validationSchema={ValidationSchema}
    >
      {({
        values,
        setFieldValue,
        errors,
        touched,
        isValid,
        isSubmitting,
        submitForm,
      }) => {
        const isBlankFormulaSelected =
          LUMEN_FORMULA_TYPES.blank === values.formulaType;

        const isTemplateFormulaSelected =
          LUMEN_FORMULA_TYPES.template === values.formulaType;

        const getSubtitle = () => {
          const subtitlePostfix = isBlankFormulaSelected
            ? " variables you would like to screen for."
            : " template you’re going to use for it";
          return `Please enter the formula name and select the ${subtitlePostfix}`;
        };

        const showTemplateDescription =
          isTemplateFormulaSelected && values.formulaTemplateId;

        const templateDescription = values.formulaTemplateId
          ? lumenFormulaTemplates?.find(
              (f) => f.id === Number(values.formulaTemplateId)
            )?.description
          : null;

        const showNoTemplatesMessage =
          isTemplateFormulaSelected && lumenFormulaTemplates?.length < 1;

        return (
          <Modal
            title="Create new formula"
            submitButtonLabel="CONFIRM & CONTINUE"
            submit={submitForm}
            submitting={isSubmitting}
            submitButtonDisabled={!isValid}
            width="516px"
          >
            <Modal.Body noPaddingTop>
              <CreateLumenFormulaModalContent>
                <Well noBorder>
                  <RadioGroup
                    id="formulaType"
                    name="formulaType"
                    value={values.formulaType}
                    options={[
                      {
                        label: "Blank formula",
                        value: LUMEN_FORMULA_TYPES.blank,
                      },
                      {
                        label: "From template",
                        value: LUMEN_FORMULA_TYPES.template,
                      },
                    ]}
                    onChange={(value) => {
                      setFieldValue("formulaType", value);
                    }}
                  />
                </Well>
                <Spacer size={Spacer.SIZES.md} />
                <RegularText>{getSubtitle()}</RegularText>
                <Spacer size={Spacer.SIZES.sm} />
                <Field
                  name="name"
                  id="name"
                  label="Name"
                  as={Input}
                  error={touched.name && errors.name}
                  formikContext={{
                    trimOnBlur: true,
                    setFieldValue,
                  }}
                />
                <Spacer size={Spacer.SIZES.md} />
                {isBlankFormulaSelected && (
                  <div>
                    <SmallTitle strong className="formula-create-question">
                      Which variables would you like to screen individuals for?
                    </SmallTitle>
                    <div className="formula-create-checkbox">
                      <Field
                        id="sections.useCreditScore"
                        name="sections.useCreditScore"
                        label="Credit profile"
                        as={Checkbox}
                      />
                    </div>
                    <div className="formula-create-checkbox">
                      <Field
                        id="sections.useVerifications"
                        name="sections.useVerifications"
                        label="Verifications"
                        as={Checkbox}
                      />
                    </div>
                    <div className="formula-create-checkbox">
                      <Field
                        id="sections.useIncomeRentRatio"
                        name="sections.useIncomeRentRatio"
                        label="Income"
                        as={Checkbox}
                      />
                    </div>
                    <div className="formula-create-checkbox">
                      <Field
                        id="sections.useCollections"
                        name="sections.useCollections"
                        label="Collections"
                        as={Checkbox}
                      />
                    </div>
                    <div className="formula-create-checkbox">
                      <Field
                        id="sections.useCriminalRecords"
                        name="sections.useCriminalRecords"
                        label="Criminal Records"
                        as={Checkbox}
                      />
                    </div>
                    <div className="formula-create-checkbox">
                      <Field
                        id="sections.useHousingCourtRecords"
                        name="sections.useHousingCourtRecords"
                        label="Eviction Related Proceedings"
                        as={Checkbox}
                      />
                    </div>
                  </div>
                )}
                {isTemplateFormulaSelected && (
                  <VeroFormField
                    name="formulaTemplateId"
                    id="formulaTemplateId"
                    label="Select formula template"
                    as={Select}
                    error={
                      touched.formulaTemplateId && errors.formulaTemplateId
                    }
                    onChange={(value) =>
                      setFieldValue("formulaTemplateId", value)
                    }
                    loading={isLumenFormulaTemplatesLoading}
                    disabled={lumenFormulaTemplates?.length < 1}
                  >
                    {(lumenFormulaTemplates || []).map((formula) => (
                      <Select.Option key={formula.id}>
                        <Tooltip
                          trigger="hover"
                          title={formula.description}
                          theme="light"
                        >
                          {formula.name}
                        </Tooltip>
                      </Select.Option>
                    ))}
                  </VeroFormField>
                )}
                {showTemplateDescription && (
                  <>
                    <Spacer size={Spacer.SIZES.sm} />
                    <SmallText>{templateDescription}</SmallText>
                  </>
                )}
                {showNoTemplatesMessage && (
                  <>
                    <Spacer size={Spacer.SIZES.sm} />
                    <SmallText>
                      There are no formula templates available at the moment
                    </SmallText>
                  </>
                )}
              </CreateLumenFormulaModalContent>
            </Modal.Body>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default CreateLumenFormulaModal;
