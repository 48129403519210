import React from "react";

import { Field, useFormikContext } from "formik";

interface StateSelectFieldProps {
  defaultPlaceholder?: string;
  statesCallback?: (
    value: string,
    label: string,
    setFieldValue: (
      field: string,
      value: string,
      shouldValidate?: boolean
    ) => void
  ) => void;
  className?: string;
  options: {
    value: string;
    id: string;
  }[];
}

export const SelectDropdownField = ({
  defaultPlaceholder = null,
  statesCallback = null,
  className = null,
  options = [],
}: StateSelectFieldProps) => {
  const { setFieldValue } = useFormikContext();

  const stateChange = (e) => {
    if (e.target.value === defaultPlaceholder) {
      statesCallback("", defaultPlaceholder, setFieldValue);
      return;
    }
    const key = options.filter((option) => {
      return option.value === e.target.value;
    })[0].id;
    statesCallback(key, e.target.value, setFieldValue);
  };

  return (
    <Field
      className={className}
      name="state"
      as="select"
      onChange={stateChange}
    >
      {defaultPlaceholder && <option key="">{defaultPlaceholder}</option>}
      {options &&
        options.map((option) => (
          <option key={option.value}>{option.value}</option>
        ))}
    </Field>
  );
};
