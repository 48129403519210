import styled from "@emotion/styled";
import { Typography } from "antd";

import { COLORS } from "admin/config/constants";
import Container from "shared/components/Container";
import { ActionLink } from "shared/components/Links";
import SlidingPanel from "shared/components/SlidingPanel";

const { Text } = Typography;

export const Panel = styled(SlidingPanel)`
  background-color: ${COLORS.documentConstructor.primary};
`;

export const Title = styled(Text)`
  color: ${COLORS.documentConstructor.text};
  font-size: 20px;
`;

export const Subtitle = styled(Text)`
  color: ${COLORS.documentConstructor.text};
  font-size: 12px;
`;

export const StyledField = styled(Text)<{ shifted?: boolean }>`
  color: ${COLORS.documentConstructor.text};
  font-size: 14px;
  cursor: pointer;
  font-weight: 500;
  display: flex;
  align-items: center;
  &:hover {
    color: ${COLORS.documentConstructor.hoveredText};
  }
  &::before {
    content: "•";
    color: ${COLORS.documentConstructor.fieldMark};
    padding-right: 10px;
  }
  ${({ shifted }) =>
    !!shifted &&
    `
  padding-left: 20px;
  `}
`;

export const FieldDescription = styled(Text)`
  color: ${COLORS.documentConstructor.secondaryText};
`;

export const NewFieldContainer = styled.div`
  display: flex;
  justify-content: right;
`;

export const NewFieldLink = styled(ActionLink)`
color: ${COLORS.documentConstructor.actionLink.primary}

&:hover {
  color: ${COLORS.documentConstructor.actionLink.hover};
},
`;

export const StyledHeader = styled.div`
display: flex;
justify-content: space-between;
color: ${COLORS.documentConstructor.text};
align-items: center;
& > svg {
  filter : invert(100%);

`;

export const NoFieldsMessage = styled.p`
  color: #ffffff;
`;

export const IconWrapper = styled.span`
  height: 17px;
  margin-left: 4px;
`;

export const NewFieldWrapper = styled(Container)`
  position: absolute;
  height: 130px;
`;

export const FieldsWrapper = styled(Container)`
  position: absolute;
  top: 130px;
  display: block;
  min-height: auto;
  height: calc(100% - 130px);
  overflow-y: auto;
`;

export const PopoverContent = styled.div`
  max-width: 300px;
  padding: 20px;
`;
