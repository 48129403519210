import React, { useEffect, useState } from "react";

import isEmpty from "lodash/isEmpty";
import { queryCache } from "react-query";

import {
  useGetPermissions,
  useGetYardiAlerts,
  useGetYardiProperties,
} from "manager/hooks/api";
import { useGetIntegrationProvider } from "manager/pages/Marketplace/config";
import { PERMISSIONS } from "shared/config/authConstants";
import { useActiveCompany } from "shared/hooks";
import { backLinkPropType } from "shared/utils/propTypes";
import { getQueryParams } from "shared/utils/routing";

import YardiAlerts from "./YardiAlerts";
import { YARDI_ALERT_CLASSES } from "./YardiAlerts.constants";

const TABLE_PAGE_SIZE = 10;

const YardiAlertsContainer = ({ backLink }) => {
  const { activeCompany } = useActiveCompany();
  const { permissions } = useGetPermissions(activeCompany?.id);
  const { provider } = useGetIntegrationProvider();

  const permissionsLoaded = !isEmpty(permissions);
  const isIntegrationsAdmin =
    permissionsLoaded && permissions[PERMISSIONS.INTEGRATIONS_ADMIN];

  const initialPagination = {
    page: 1,
    pageSize: TABLE_PAGE_SIZE,
  };

  const alertClassFromParams = getQueryParams().category;

  const initialAlertClass = isIntegrationsAdmin
    ? undefined
    : YARDI_ALERT_CLASSES.UnitAvailability;

  const [currentPagination, setCurrentPagination] = useState(initialPagination);
  const [currentAlertClass, setCurrentAlertClass] = useState(
    Number(alertClassFromParams) || initialAlertClass
  );
  const [currentYardiPropertyId, setCurrentYardiPropertyId] = useState();
  const [currentResolved, setCurrentResolved] = useState(true);
  const allFilters = [
    currentPagination,
    currentAlertClass,
    currentYardiPropertyId,
    currentResolved,
  ];

  const {
    yardiAlerts,
    isLoading: isTableLoading,
    refetchAlerts,
  } = useGetYardiAlerts(
    provider,
    currentYardiPropertyId,
    currentResolved ? undefined : false,
    currentAlertClass,
    currentPagination.page,
    currentPagination.pageSize
  );

  const alerts = yardiAlerts?.results || [];
  const totalCount = yardiAlerts?.count || 0;

  const { yardiProperties } = useGetYardiProperties({
    provider,
    page: 1,
    pageSize: 100000,
  });
  const properties = yardiProperties?.results || [];

  const clearFilters = () => {
    setCurrentAlertClass(initialAlertClass);
    setCurrentYardiPropertyId();
    setCurrentResolved(true);
    setCurrentPagination({ ...initialPagination });
    refetchAlerts();
  };

  useEffect(() => {
    queryCache.invalidateQueries();
  }, allFilters);

  // if filter changes the pagination is reset to the first page
  const onChangeAlertClass = (value) => {
    setCurrentAlertClass(value);
    setCurrentPagination({ ...initialPagination });
  };

  const onChangeYardiPropertyId = (value) => {
    setCurrentYardiPropertyId(value);
    setCurrentPagination({ ...initialPagination });
  };

  const onChangeResolved = (value) => {
    setCurrentResolved(value);
    setCurrentPagination({ ...initialPagination });
  };

  const props = {
    properties,
    alerts,
    currentAlertClass,
    currentYardiPropertyId,
    currentResolved,
    setCurrentAlertClass: onChangeAlertClass,
    setCurrentYardiPropertyId: onChangeYardiPropertyId,
    setCurrentResolved: onChangeResolved,
    isTableLoading,
    currentPagination,
    setCurrentPagination,
    clearFilters,
    totalCount,
    isIntegrationsAdmin,
    backLink,
    provider,
  };
  return <YardiAlerts {...props} />;
};

YardiAlertsContainer.propTypes = {
  backLink: backLinkPropType.isRequired,
};

export default YardiAlertsContainer;
