import axios from "axios";

import ROUTES, { VEGA_BASE_URL } from "shared/config/apiRoutes";
import { transformRoute } from "shared/utils/routing";

import { API_URL } from "./setupApi";

const api = {
  getUnits: (params) => {
    return axios.get(`${API_URL}${ROUTES.units}`, {
      params: {
        property_id: params?.propertyId,
        name__icontains: params?.name,
        status: params?.status,
        bedrooms: params?.bedrooms,
        bathrooms: params?.bathrooms,
        page: params?.page,
        page_size: params?.pageSize,
        ordering: params?.ordering,
        start_date: params?.startDate,
        end_date: params?.endDate,
        show_on_hold: params?.showOnHold,
      },
    });
  },
  getUnitsTable: (params) =>
    axios.get(`${API_URL}${ROUTES.units}`, {
      params: {
        name__icontains: params?.name,
        page: params?.page,
        page_size: params?.pageSize,
      },
    }),
  getAllUnitsSimple: ({ propertyId }) =>
    axios.get(
      transformRoute(`${API_URL}${ROUTES.allUnitsSimple}`, { propertyId })
    ),
  createUnit: ({ name, unitType, bedrooms, bathrooms, squareFeet, property }) =>
    axios.post(`${API_URL}${ROUTES.units}`, {
      name,
      unitType,
      bedrooms,
      bathrooms,
      squareFeet,
      property,
    }),
  getUnitsListForDealAssignment: ({ propertyId }) =>
    axios.get(`${API_URL}${ROUTES.getUnitsListForDealAssignment}`, {
      params: {
        property_id: propertyId,
      },
    }),
  deleteUnit: ({ id }) =>
    axios.delete(`${API_URL}${transformRoute(ROUTES.unit, { id })}`),
  getUnit: ({ id }) =>
    axios.get(`${API_URL}${transformRoute(ROUTES.unit, { id })}`),
  getUnitFees: (location) =>
    axios.get(`${VEGA_BASE_URL}/api/fee`, {
      params: {
        location,
      },
    }),
  editUnit: ({
    name,
    unitType,
    bedrooms,
    bathrooms,
    squareFeet,
    property,
    id,
  }) =>
    axios.patch(`${API_URL}${transformRoute(ROUTES.unit, { id })}`, {
      name,
      unitType,
      bedrooms,
      bathrooms,
      squareFeet,
      property,
    }),
};

export default api;
