import React from "react";

import { FormikProps, FormikHelpers } from "formik";

import { DealSetup } from "manager/interfaces/api";
import { FormPromptShell } from "shared/components/Form";

import { ValidationSchema } from "./config";
import LeaseRentOptionsSettings from "./LeaseRentOptionsSettings";

const INITIAL_VALUES = {};

interface LeaseRentOptionsProps {
  loading: boolean;
  dealSetup: DealSetup;
  saveChanges: (values: DealSetup) => void;
  onSubmit: (values: DealSetup, form: FormikHelpers<DealSetup>) => void;
  formRef: React.Ref<FormikProps<DealSetup>>;
  disabled: boolean;
  shouldDisableSubmitButton?: boolean;
  submitButtonLabel?: string;
  showSecondaryButton: boolean;
  onSecondaryButtonClick?: () => void;
  setFieldValue?: (fieldName: string, value: number) => void;
  secondaryButtonLabel?: string;
  shouldShowExpirationInputField?: boolean;
}

const LeaseRentOptions = ({
  loading,
  dealSetup,
  saveChanges,
  onSubmit,
  formRef,
  disabled,
  shouldDisableSubmitButton = true,
  submitButtonLabel,
  showSecondaryButton,
  onSecondaryButtonClick,
  secondaryButtonLabel,
  shouldShowExpirationInputField,
}: LeaseRentOptionsProps) => {
  const getPromptProps = ({ isValid }) => {
    return {
      title: "Unsaved changes",
      subtitle: isValid
        ? "Would you like to save the changes?"
        : "The changes you made are not valid and will not be saved.",
      submitButtonLabel: isValid ? "Yes" : "OK",
      cancelLinkLabel: isValid ? "No" : null,
    };
  };

  return (
    <FormPromptShell
      initialValues={dealSetup || INITIAL_VALUES}
      validationSchema={ValidationSchema}
      onSubmit={onSubmit}
      submitButtonLabel={submitButtonLabel}
      shouldDisableSubmitButton={shouldDisableSubmitButton}
      saveChanges={saveChanges}
      formRef={formRef}
      getPromptProps={getPromptProps}
      showSecondaryButton={showSecondaryButton}
      onSecondaryButtonClick={onSecondaryButtonClick}
      secondaryButtonLabel={secondaryButtonLabel}
    >
      <LeaseRentOptionsSettings
        loading={loading}
        disabled={disabled}
        dealSetup={dealSetup}
        shouldShowExpirationInputField={shouldShowExpirationInputField}
        // @ts-ignore
        setFieldValue={formRef?.current?.setFieldValue}
        // @ts-ignore
        setFieldError={formRef?.current?.setFieldError}
      />
    </FormPromptShell>
  );
};

export default LeaseRentOptions;
