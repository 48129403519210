import styled from "@emotion/styled";

const AssignEmployeeSuccessWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  .modal-description {
    margin-top: 23px;
    margin-bottom: 26px;
    text-align: center;
    font-weight: 500;
    font-size: 17px;
  }
`;

export { AssignEmployeeSuccessWrapper };
