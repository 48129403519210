import axios from "axios";

import ROUTES from "manager/config/apiRoutes";
import { API_URL } from "shared/lib/api/setupApi";
import { transformRoute } from "shared/utils/routing";

const api = {
  togglePropertyDisplayOnPortal: (propertyId) =>
    axios.patch(
      `${API_URL}${transformRoute(ROUTES.togglePropertyDisplayOnPortal, {
        propertyId,
      })}`
    ),

  toggleUnitDisplayOnPortal: (unitId) =>
    axios.patch(
      `${API_URL}${transformRoute(ROUTES.toggleUnitDisplayOnPortal, {
        unitId,
      })}`
    ),
};

export default api;
