import React, { useEffect, useState } from "react";

import { Select } from "antd-latest";
import isFunction from "lodash/isFunction";

import RequiredIndicator from "shared/components/RequiredIndicator";

// NOTE: goran - We need to find a better way to import the styles for antd-latest.
// It should be at root level ideally, but because of the project setup we have at
// the moment, we are not left with many options.
// When we try to import the less style into the `bundle.less` file, we get the following error:
// `FATAL ERROR: Ineffective mark-compacts near heap limit Allocation failed - JavaScript heap out of memory`
// The reason for the memory leak we are experience there might be out current nodejs version v14.16.1
// and how it manages the memory when we are building the styles, but also incompatibility of other tools we
// use during the build process.

import "shared/styles/antd-latest.less";
import { DropdownOverlay } from "shared/styles/commonStyles";

import { Container, Label, Error, OptionalText } from "../styled";

import { ValueType, VeroSelectProps } from "./interfaces";
import { StyledSelect4, DropdownMenuWrapper, dropdownStyles } from "./styled";

export const Select4 = ({
  id,
  name,
  value,
  defaultValue,
  label,
  onChange,
  required,
  disabled,
  error,
  optional,
  optionFilterProp = "children",
  customDropdownFooter,
  containerClassName,
  onBlur,
  onClear,
  ...otherProps
}: VeroSelectProps) => {
  const [internalValue, setInternalValue] = useState<ValueType>(
    value || defaultValue
  );
  const [searchValue, setSearchValue] = useState<string>(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const closeDropdown = () => setDropdownVisible(false);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  /** Note: When calling onClear() newValue=undefined,
   *  providing defaultValue to reset */
  const onChangeCallback = (newValue = defaultValue, options) => {
    setSearchValue(null);
    if (isFunction(onChange)) {
      onChange(newValue, options);
    }
    setInternalValue(newValue);
  };

  const onClearCallback = () => {
    if (isFunction(onBlur)) {
      onBlur();
    }

    if (isFunction(onClear)) {
      onClear();
    }
  };

  return (
    <>
      <Container className={containerClassName}>
        {label && (
          <Label
            htmlFor={id}
            value={Boolean(internalValue || searchValue)}
            disabled={disabled}
          >
            {label}
            <RequiredIndicator required={required} />
          </Label>
        )}
        <StyledSelect4
          data-testid="select-4"
          id={id}
          name={name}
          disabled={disabled}
          showSearch
          filterOption
          value={internalValue}
          defaultValue={defaultValue}
          onChange={onChangeCallback}
          error={error}
          onSearch={setSearchValue}
          optionFilterProp={optionFilterProp}
          dropdownStyle={dropdownStyles}
          open={dropdownVisible}
          onDropdownVisibleChange={(open) => {
            setDropdownVisible(open);
          }}
          dropdownRender={(menu) => (
            <>
              <DropdownOverlay onClick={closeDropdown} />
              <DropdownMenuWrapper>
                {menu}
                {/* NOTE: If your custom dropdown action should close dropdown on click, call closeDropdown callback. Check storybook for example */}
                {customDropdownFooter && customDropdownFooter(closeDropdown)}
              </DropdownMenuWrapper>
            </>
          )}
          onClear={onClearCallback}
          {...otherProps}
        />
      </Container>
      {error && !Array.isArray(error) && (
        // @ts-ignore
        <Error className="error-message">{error}</Error>
      )}
      {error &&
        Array.isArray(error) &&
        // @ts-ignore
        error.map((e) => <Error key={e}>{e}</Error>)}
      {optional && <OptionalText>Optional</OptionalText>}
    </>
  );
};

export const Select4Option = Select.Option;
