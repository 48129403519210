import { normalize } from "normalizr";
import { handleActions } from "redux-actions";

import * as dealSetupActions from "shared/state/DealSetup/actions";
import { dealSetupList } from "shared/state/DealSetup/schema";

import {
  receiveAdditionalQuestions,
  removeAdditionalQuestions,
} from "../actions";
import { additionalQuestionList } from "../schema";

const initialState = {};

const additionalQuestionsEntityReducer = handleActions(
  {
    [receiveAdditionalQuestions]: (state, action) => {
      const { items } = action.payload;
      return {
        ...state,
        ...normalize(items, additionalQuestionList).entities.additionalQuestion,
      };
    },
    [removeAdditionalQuestions]: (state, action) => {
      const { ids } = action.payload;
      const newState = {};

      Object.keys(state)
        .filter((id) => !ids.includes(id))
        .forEach((id) => {
          newState[id] = { ...state[id] };
        });

      return newState;
    },
    [dealSetupActions.receiveDealSetup]: (state, action) => {
      const dealSetup = action.payload;
      return {
        ...state,
        ...normalize([dealSetup], dealSetupList).entities.additionalQuestion,
      };
    },
  },
  initialState
);

export default additionalQuestionsEntityReducer;
