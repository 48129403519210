import styled from "@emotion/styled";

import { COLORS } from "shared/config/constants";

const Container = styled("table")`
  font-size: 12px;
`;

const Header = styled("thead")``;

const HeaderCell = styled("th")`
  color: ${COLORS.paymentHistory.month};
  font-weight: 400;
  padding: 0 6px 4px 6px;
  &:first-of-type {
    padding-left: 0;
  }
  &:last-of-type {
    padding-right: 0;
  }
`;

const Body = styled("tbody")``;

const Row = styled("tr")``;

const BodyCell = styled("td")`
  padding: 6px;
  &:first-of-type {
    padding-left: 0;
  }
  &:last-of-type {
    padding-right: 0;
  }
`;

const HistoryCell = styled("div")`
  border-radius: 50%;
  height: 8px;
  width: 8px;
`;

const OnTime = styled(HistoryCell)`
  background-color: ${COLORS.paymentHistory.onTime};
`;

const Late30Days = styled(HistoryCell)`
  background-color: ${COLORS.paymentHistory.late30Days};
`;

const Late60Days = styled(HistoryCell)`
  background-color: ${COLORS.paymentHistory.late60Days};
`;

const Late90Days = styled(HistoryCell)`
  background-color: ${COLORS.paymentHistory.late90Days};
`;

const Late120Days = styled(HistoryCell)`
  background-color: {COLORS.paymentHistory.late120Days};
`;

const Unclassified = styled(HistoryCell)`
  background-color: ${COLORS.paymentHistory.unclassified};
`;

const Legend = styled("span")`
  display: flex;
  align-items: center;
`;

const LegendText = styled("span")`
  margin-left: 9px;
  font-size: 12px;
  color: ${COLORS.paymentHistory.legend};
`;

export {
  Container,
  Header,
  HeaderCell,
  Body,
  Row,
  BodyCell,
  OnTime,
  Late30Days,
  Late60Days,
  Late90Days,
  Late120Days,
  Unclassified,
  Legend,
  LegendText,
};
