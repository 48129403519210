import React from "react";

import Icon from "shared/components/Icon";
import InfoPage from "shared/templates/InfoPage";

const MagicLinkV2InvalidPage = () => (
  <div className="invalid-magic-link">
    <InfoPage
      title="Oops!"
      description="Looks like this link is invalid. Please reach out to the leasing agent for next steps."
      MainIcon={Icon.OrangeExclamationHollow}
    />
  </div>
);

export default MagicLinkV2InvalidPage;
