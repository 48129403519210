import styled from "@emotion/styled";

const Applicant = styled("div")({
  display: "flex",
});

const ApplicantIcon = styled("div")({
  marginRight: "12px",
});

const ApplicantRole = styled("small")({
  display: "flex",
  fontWeight: 300,
});

const NotSubmittedText = styled("div")({
  padding: "12px 0",
});

const LinkEllipsisWrapper = styled("div")({
  textOverflow: "ellipsis",
  overflow: "hidden",
  paddingRight: "3px",
  whiteSpace: "nowrap",
});

export {
  Applicant,
  ApplicantIcon,
  ApplicantRole,
  NotSubmittedText,
  LinkEllipsisWrapper,
};
