import styled from "@emotion/styled";

import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

const ApplicationGroupWrapper = styled.div`
  margin: 32px -24px 0 -24px;
  padding: 12px 24px;
  background: ${COLORS.itemActiveBg};
  border-radius: 2px;

  ${MEDIA_QUERIES.w.ltSm} {
    margin: 32px -10px 0 -10px;
    padding: 12px 12px 24px 12px;
  }

  .inner-section {
    overflow-y: scroll;
    height: 120px;
    margin-top: 12px;
    border: 1px solid ${COLORS.borders};
    border-radius: 2px;
    padding-top: 8px;

    ul {
      color: ${COLORS.violetTheme.lightBlueViolet};
      padding-left: 30px;

      li {
        margin-bottom: 2px;

        div {
          margin-left: -5px;
        }
      }
    }
  }
`;

export { ApplicationGroupWrapper };
