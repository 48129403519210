import { useMutation, useQuery } from "react-query";

import { NOTIFICATIONS } from "shared/config/constants";
import api from "shared/lib/api";
import { openNotification } from "shared/utils/ui";

const ARE_VETT_GUESTS_ENABLED = "areVettGuestsEnabled";

const useBeginPrequalification = () => {
  const [beginPrequalification, { isLoading: isBeginPrequalificationLoading }] =
    useMutation(api.beginPrequalification, {
      onError: () => {
        openNotification(
          "Failed to begin prequalification.",
          NOTIFICATIONS.error
        );
      },
    });

  return {
    beginPrequalification,
    isBeginPrequalificationLoading,
  };
};

export const useAreVettGuestsEnabled = (applicationId) => {
  const { data, isLoading } = useQuery(
    [ARE_VETT_GUESTS_ENABLED],
    () => api.areVettGuestsEnabled(applicationId),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      onError: () => {
        openNotification("Failed Vett Fetch.", NOTIFICATIONS.error);
      },
    }
  );

  return {
    areVettGuestsEnabledData: data,
    isVettGuestsEnabledLoading: isLoading,
  };
};

export { useBeginPrequalification };
