import { Key } from "react";

import { queryCache, useMutation, useQuery } from "react-query";

import api from "manager/lib/api";
import { NOTIFICATIONS } from "shared/config/constants";
import { openNotification } from "shared/utils/ui";

const GET_COMPANIES = "getCompanies";
const GET_COMPANY = "getCompany";
const GET_PERMISSIONS = "getPermissions";

export const COMPANY_QUERY_KEYS = Object.freeze({
  getCompanies: () => [GET_COMPANIES],
  getCompany: (companyId: Key) => [GET_COMPANY, { companyId }],
  getPermissions: (companyId: Key) => [GET_PERMISSIONS, Number(companyId)],
});

export const useCreateOpenApiUser = () => {
  const [mutate, { isLoading, data }] = useMutation(api.createOpenApiUser, {
    onError: () => {
      openNotification("Failed to create an openAPI user", NOTIFICATIONS.error);
    },
  });

  return {
    createOpenApiUser: mutate,
    isCreatingUser: isLoading,
    openApiUser: data,
  };
};

export const useTestOpenApiWebhook = () => {
  const [mutate, { isLoading }] = useMutation(api.testOpenApiWebhook, {
    onSuccess: () =>
      openNotification("Webhook is accessible", NOTIFICATIONS.info),
    onError: () =>
      openNotification("Webhook is not accessible", NOTIFICATIONS.error),
  });

  return {
    testOpenApiWebhook: mutate,
    isTestingWebhook: isLoading,
  };
};

export const useResetOpenApiPassword = () => {
  const [mutate, { isLoading, data }] = useMutation(api.resetOpenApiPassword, {
    onError: () => {
      openNotification(
        "Failed to reset Open Api password",
        NOTIFICATIONS.error
      );
    },
  });

  return {
    resetOpenApiPassword: mutate,
    isResettingPassword: isLoading,
    password: data?.password,
  };
};

export const useSetOpenApiSettings = () => {
  const [mutate, { isLoading }] = useMutation(api.setOpenApiSettings, {
    onSuccess: () => {
      queryCache.invalidateQueries(COMPANY_QUERY_KEYS.getCompanies());
      openNotification(
        "Successfully updated Open API settings",
        NOTIFICATIONS.info
      );
    },
    onError: () =>
      openNotification("Failed to save Open API settings", NOTIFICATIONS.error),
  });

  return {
    setOpenApiSettings: mutate,
    isSettingOpenApiSettings: isLoading,
  };
};

export const useGetCompanies = () => {
  const { data, isLoading } = useQuery(
    COMPANY_QUERY_KEYS.getCompanies(),
    () => api.getCompanies(),
    {
      onError: () =>
        openNotification("Failed to load companies", NOTIFICATIONS.error),
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
  return { companies: data, isGetCompaniesLoading: isLoading };
};

export const useDismissCompany = () => {
  const [mutate, { isLoading }] = useMutation(api.dismissCompany, {
    onSuccess: () => {
      openNotification("Successfully dismissed company", NOTIFICATIONS.info);
      queryCache.invalidateQueries(COMPANY_QUERY_KEYS.getCompanies());
    },
    onError: () =>
      openNotification(`Failed to dismiss company`, NOTIFICATIONS.error),
  });

  return {
    dismissCompany: mutate,
    isDismissingCompanyLoading: isLoading,
  };
};

export const useGetPermissions = (companyId: Key) => {
  const { data, isLoading } = useQuery(
    COMPANY_QUERY_KEYS.getPermissions(companyId),
    () => api.getPermissions(companyId),
    {
      enabled: !!companyId,
      onError: () =>
        openNotification("Failed to load permissions", NOTIFICATIONS.error),
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
  return { permissions: data, isPermissionsLoading: isLoading };
};

export const useGetCompany = (companyId: Key) => {
  const { data, isLoading } = useQuery(
    COMPANY_QUERY_KEYS.getCompany(companyId),
    () => api.getCompany(companyId),
    {
      enabled: !!companyId,
      onError: () => openNotification("Failed to load company", NOTIFICATIONS.error)
    }
  );
  return { company: data, isGetCompanyLoading: isLoading };
};
