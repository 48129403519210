import styled from "@emotion/styled";

const CreateLumenFormulaModalContent = styled.div({
  marginTop: "24px",
  ".formula-create-checkbox:not(:last-of-type)": {
    marginBottom: "13px",
  },
  ".formula-create-question": {
    marginBottom: "20px",
  },
});

export { CreateLumenFormulaModalContent };
