import React from "react";

import { ActionLink } from "shared/components/Links";
import useModal from "shared/hooks/useModal";
import { IntegrationsLinkSvg } from "shared/images";

import { BluemoonConnectLicenseModal } from "../bluemoonAlertsResolvers";

import {
  NoLicenceSectionContainer,
  NoLicenceSectionText,
  NoLicenceLinkImage,
} from "./styled";

const messages = {
  DASHBOARD_PAGE: "You haven’t connected any licenses",
  FIELDS_MAPPING: "No fields have been mapped, connect a license to map fields",
};

const NoLicenceSection = ({ page }) => {
  const { openModalDialog } = useModal();

  return (
    <NoLicenceSectionContainer
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <NoLicenceLinkImage src={IntegrationsLinkSvg} alt="Integrations" />
      <NoLicenceSectionText>{messages[page]}</NoLicenceSectionText>
      <ActionLink onClick={() => openModalDialog(BluemoonConnectLicenseModal)}>
        connect a licence
      </ActionLink>
    </NoLicenceSectionContainer>
  );
};

export default NoLicenceSection;
