import React from "react";

import { useGetSSOState } from "shared/utils/auth";
import { SSOProviderProps } from "shared/utils/interfaces";

import { StyledSSOButton } from "./styled";

export interface SSOButtonProps {
  provider: SSOProviderProps;
  preValidation?: () => void;
}

const SSOButton = ({ provider, preValidation }: SSOButtonProps) => {
  // @ts-ignore
  const { providerViewConfig, loginUrl } = useGetSSOState(provider);

  const redirectToSSOProvider = () => {
    if (!preValidation || preValidation()) {
      window.location.href = loginUrl;
    }
  };

  return (
    <StyledSSOButton
      type="secondary"
      onClick={redirectToSSOProvider}
      color={providerViewConfig.color}
      PrefixIcon={providerViewConfig.Icon}
    >
      <span className="label">{providerViewConfig.buttonLabel}</span>
    </StyledSSOButton>
  );
};

export default SSOButton;
