import React, { useEffect, useState } from "react";

import { Popover as BasePopover } from "antd";
import { PopoverProps as BasePopoverProps } from "antd/lib/popover";
import ReactDOM from "react-dom";

import { PopoverContext } from "./popoverContext";
import { DarkOverlay, Title } from "./styled";

export type PopoverProps = {
  withoutOverlay?: boolean;
  title?: string;
  titleAddon?: React.ReactNode;
  disabled?: boolean;
  closeAfterTimeout?: number;
} & BasePopoverProps;

const DarkOverlayPortal = ({ visible }) =>
  ReactDOM.createPortal(<DarkOverlay visible={visible} />, document.body);

export const Popover: React.FC<PopoverProps> = ({
  children,
  title,
  titleAddon,
  withoutOverlay,
  disabled,
  closeAfterTimeout,
  ...props
}) => {
  const [visible, setVisible] = useState(false);

  const context = {
    closePopover: () => {
      setVisible(false);
    },
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (closeAfterTimeout && visible) {
      const timer = setTimeout(() => {
        setVisible(false);
      }, closeAfterTimeout);

      return () => clearTimeout(timer);
    }
  }, [closeAfterTimeout, visible]);

  return (
    <>
      <PopoverContext.Provider value={context}>
        {!withoutOverlay && (
          <DarkOverlayPortal visible={visible && !disabled} />
        )}
        <BasePopover
          visible={visible && !disabled}
          onVisibleChange={(visibility) => setVisible(visibility)}
          title={
            title && (
              <>
                <Title level={2}>{title}</Title>
                {titleAddon && titleAddon}
              </>
            )
          }
          {...props}
        >
          {children}
        </BasePopover>
      </PopoverContext.Provider>
    </>
  );
};
