import styled from "@emotion/styled";

import BaseCheckList from "shared/components/ApplicantsCheckList";
import { UnderlineLink } from "shared/components/Links";
import { SmallTitle } from "shared/components/Typography";
import { MEDIA_QUERIES } from "shared/config/constants";

export const Subtitle = styled.span`
  display: flex;
  flex-wrap: wrap;
  gap: 8;
`;

export const ApplicantsCheckList = styled(BaseCheckList)`
  flex: 1;
`;

export const LeadsTitle = styled(SmallTitle)`
  margin-bottom: 40px !important;
`;

export const AddMoreLeadsUnderlineLink = styled(UnderlineLink)`
  margin-left: 40px;

  ${MEDIA_QUERIES.w.ltMd} {
    margin-left: 0px;
  }
`;
