import styled from "@emotion/styled";

import FooterBar from "shared/components/FooterBar";

const Panel = styled(FooterBar)(
  (props: { externalStyles?: Record<string, any> }) => ({
    display: "flex",
    justifyContent: "center",
    ...(props.externalStyles && { ...props.externalStyles }),
  })
);

const CentralTools = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& > *:not(:last-child)": {
    marginRight: "10px",
  },
});

const LeftTools = styled(CentralTools)({
  marginRight: "auto",
});

const RightTools = styled(CentralTools)({
  marginLeft: "auto",
});

export const ViewerPanel = Object.assign(Panel, {
  CentralTools,
  LeftTools,
  RightTools,
});
