import { normalize } from "normalizr";
import { handleActions } from "redux-actions";

import * as employeeActions from "shared/state/Employee/actions";
import * as employeeSchemas from "shared/state/Employee/schema";

import * as actions from "../actions";
import * as schemas from "../schema";

const initialState = {};

const rolesEntityReducer = handleActions(
  {
    [actions.receiveRoles]: (state, action) => {
      const { roles } = action.payload;
      return {
        ...state,
        ...normalize(roles, schemas.roleList).entities.role,
      };
    },
    [employeeActions.receiveEmployees]: (state, action) => {
      const { employees } = action.payload;

      return {
        ...state,
        ...normalize(employees, employeeSchemas.employeeList).entities.role,
      };
    },
    [employeeActions.receiveEmployee]: (state, action) => {
      const { employee } = action.payload;

      return {
        ...state,
        ...normalize([employee], employeeSchemas.employeeList).entities.role,
      };
    },
  },
  initialState
);

export default rolesEntityReducer;
