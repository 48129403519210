import styled from "@emotion/styled";

import { MEDIA_QUERIES } from "shared/config/constants";

const YardiAlertResolverWrapper = styled("div")({
  ".section-label": {
    fontWeight: 300,
    marginBottom: "24px",
  },
});

const Cell = styled("div")({
  minHeight: "70px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  [MEDIA_QUERIES.w.ltSm]: {
    height: "auto",
  },
});

export { YardiAlertResolverWrapper, Cell };
