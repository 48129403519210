import React from "react";

import { useRequestCountyCriminalSearch } from "manager/hooks/api";
import ApplicantsCheckList from "shared/components/ApplicantsCheckList";
import FlexContainer from "shared/components/FlexContainer";
import ConfirmationModal from "shared/components/Modals/ConfirmationModal";
import { RegularText } from "shared/components/Typography";
import { COUNTY_CRIMINAL_SEARCH_STATUS } from "shared/config/constants";
import { ModalManager } from "shared/utils/modalmanager";
import { formatDateTime } from "shared/utils/ui";

import { CountyRecordsProps } from "./types";

export const useRequestCountyRecords = (
  deal: CountyRecordsProps,
  refetch?: () => void
) => {
  const successMessage = deal?.lumenEnabled
    ? "You have successfully requested county criminal records for this application! Your Lumen suggestion will be recalculated when received."
    : "You have successfully requested county criminal records for this application! You will receive a notification when the records are available to view.";

  const {
    requestCountyCriminalSearch,
    isLoading: isRequestCountyCriminalSearchLoading,
  } = useRequestCountyCriminalSearch(successMessage);

  const openRequestCountyRecordsModal = (countyCriminalSearchStatus) => {
    const isSearchPartiallyRequested =
      countyCriminalSearchStatus.value ===
      COUNTY_CRIMINAL_SEARCH_STATUS.PARTIALLY_REQUESTED;

    const applicantList = deal.applicantsSummary.filter(
      (applicant) => applicant.isCountyCriminalSearchEligible
    );

    let description = null;
    if (isSearchPartiallyRequested) {
      const dateTimeStartedAt = formatDateTime(
        deal.countyCriminalSearchStartedAt
      );
      let initiator = " automatically ";
      if (deal.countyCriminalSearchRequestedByName) {
        initiator = ` by ${deal.countyCriminalSearchRequestedByName} `;
      }
      description = `A county criminal records search was last initiated${initiator}on ${dateTimeStartedAt}.
      A search will only be performed for applicants who have not been previously requested.`;
    }

    return ModalManager.openModal(ConfirmationModal, {
      title: "Requesting County Records ",
      subtitle: (
        <RegularText weak>
          Please note this request can take several days. You will receive an
          email notification when results are complete
        </RegularText>
      ),
      message: (
        <FlexContainer flexDirection="column">
          <RegularText weak>
            A request will be submitted for the following applicants:
          </RegularText>
          <ApplicantsCheckList inline items={applicantList} />
        </FlexContainer>
      ),
      description,
    }).afterClose.then(async (result) => {
      if (result) {
        await requestCountyCriminalSearch(deal.id);
        // NOTE: Refetch the Lumen result to get the updated state for Lumen.
        refetch?.();
      }
    });
  };

  return {
    openRequestCountyRecordsModal,
    isRequestCountyCriminalSearchLoading,
  };
};
