import styled from "@emotion/styled";

import BaseCheckList from "shared/components/ApplicantsCheckList";
import { COLORS } from "shared/config/constants";

export const Subtitle = styled.span`
  display: flex;
  flex-wrap: wrap;
  gap: 8;
`;

export const ApplicantsCheckList = styled(BaseCheckList)`
  flex: 1;
`;

export const Note = styled.p`
  color: ${COLORS.textColorPrimary};
`;
