import styled from "@emotion/styled";

import List from "shared/components/List";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

import {
  FieldLabelProps,
  FieldProps,
  FieldsProps,
  StyledListItemProps,
} from "./interfaces";

const PropertyName = styled.label`
  font-size: 15px;
  font-weight: 400;
`;

const Fields = styled.div<FieldsProps>`
  display: flex;
  ${MEDIA_QUERIES.w.ltSm} {
    flex-direction: column;
  }
  ${(props) =>
    !!props.last &&
    `
      margin-bottom: 30px;
    `}
`;

const Field = styled.div<FieldProps>`
  width: ${(props) => props.width};
  ${MEDIA_QUERIES.w.ltSm} {
    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
    ${(props) =>
      !!props.first &&
      `
    font-weight: 500
    `}
  }
`;

const FieldLabel = styled.div<FieldLabelProps>`
  color: ${COLORS.checkboxList.field.label.text};
  font-size: 12px;
  font-weight: 300;
  ${(props) =>
    !!props.first &&
    `
    ${MEDIA_QUERIES.w.ltSm} {
      display: none;
    }
  `}
`;

const Label = styled.label`
  margin-top: 32px;
  font-size: 15px;
  font-weight: 300;
`;

const StyledListItem = styled(List.Item)<StyledListItemProps>`
  ${(props) =>
    !!props.last &&
    `
    margin-bottom: 32px;
  `}
`;

export { Label, PropertyName, Fields, Field, FieldLabel, StyledListItem };
