import React from "react";

import { useTranslation } from "renter/hooks/useTranslation";

import { AddApplicantLink } from "./styled";

const InviteApplicants = ({
  addOccupant,
  addGuarantor,
  addApplicant,
  hideOccupantsButAllowDependents,
  hideGuarantorAsApplicantType,
}) => {
  const { t } = useTranslation({ keyPrefix: "renter-app.applications" });

  return (
    <>
      <AddApplicantLink data-testid="add-applicant" plus onClick={addApplicant}>
        {t("applicant")}
      </AddApplicantLink>
      {!hideGuarantorAsApplicantType && (
        <AddApplicantLink
          data-testid="add-guarantor"
          plus
          onClick={addGuarantor}
        >
          {t("guarantor")}
        </AddApplicantLink>
      )}
      {!hideOccupantsButAllowDependents && (
        <AddApplicantLink data-testid="add-occupant" plus onClick={addOccupant}>
          {t("occupant")}
        </AddApplicantLink>
      )}
    </>
  );
};

export default InviteApplicants;
