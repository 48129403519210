import React, { ChangeEvent } from "react";

import { Col, Row } from "antd-latest";
import dayjs from "dayjs";
import { Helmet } from "react-helmet";

import { FadeIn, SlideDirection, useFadeIn } from "shared/components/animated";
import { Checkbox, DatePicker, Input, Select4 } from "shared/components/Form";
import { Select4Option } from "shared/components/Form/Select4";
import LoaderContainer, {
  BigLoadingSpinner,
} from "shared/components/LoaderContainer";
import Spacer from "shared/components/Spacer";
import {
  LightText,
  MediumTitle,
  SmallErrorText,
  SmallText,
  SmallTitle,
  StrongText,
} from "shared/components/Typography";
import { COLORS } from "shared/config/constants";

import { InfoWell, LetsGo, SpacerSmall, SpacerTiny } from "./blocks";
import { ChipsRadioButton } from "./chips-radio-buttons";
import { LeaseRentOptionsConfirmed } from "./confirmed";
import { SearchResults } from "./results";
import { SpacerTop } from "./styled";
import { PropertyUnitId } from "./types";
import { useLeaseRentOptions } from "./use-lease-rent-options";
import { getMonthLabel } from "./utils";

export type LeaseRentOptionsProps = {
  propertyId: PropertyUnitId;
  firstName?: string;
  email?: string;
  isTestMode?: boolean;
  leaseStart?: string;
  leaseMonths?: number;
  appId?: string;
};

const breakpoints = { xs: 24, sm: 18, xl: 16, xxl: 12 };

export const LeaseRentOptions: React.FC<LeaseRentOptionsProps> = (props) => {
  const {
    email,
    setEmail,
    leaseTerm,
    leaseTerms,
    loading,
    priceRange,
    priceRanges,
    setPriceRangeByKey,
    setLeaseTerm,
    setStart,
    setEnd,
    isFlexibleStart,
    isFlexibleTerm,
    setIsFlexibleStart,
    setIsFlexibleTerm,
    units,
    unitsByDay,
    listType,
    toggleListType,
    sort,
    setSort,
    unit,
    setUnit,
    confirmed,
    dealCreated,
    valid,
    setValid,
    onSubmit,
    submitting,
    property,
    range,
    loadingProperty,
    title,
    application,
    header,
    hideListToggle,
    hideFlexibleStart,
    isDisabledDate,
    selectionValidityLifespan,
  } = useLeaseRentOptions(props);

  const showConfirmed = !application && property && unit && dealCreated;
  const showUnitAvailableDate = dayjs(property.availableDate).isAfter(dayjs());
  const fade = useFadeIn({
    stagger: 100,
    delay: 500,
    direction: SlideDirection.left,
    amount: 50,
  });

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {showConfirmed && (
        <LeaseRentOptionsConfirmed
          link={confirmed}
          email={email}
          property={property}
          unit={unit}
        />
      )}
      {!showConfirmed && (
        <>
          <Row
            justify="center"
            style={{ backgroundColor: COLORS.backgroundGrey2 }}
          >
            <Col {...breakpoints} style={{ padding: "64px 24px 16px 24px" }}>
              <FadeIn {...fade()}>
                <SmallTitle>{header}</SmallTitle>
              </FadeIn>
              <LoaderContainer loading={loadingProperty}>
                <FadeIn {...fade({ delay: 0 })}>
                  <MediumTitle strong>
                    {property.property}, {property.unit}
                  </MediumTitle>
                  <LightText>
                    {property.street} {property.city}, {property.stateCode}{" "}
                    {property.zipCode}
                  </LightText>
                </FadeIn>
              </LoaderContainer>
            </Col>
          </Row>
          <Row justify="center" style={{ backgroundColor: COLORS.white }}>
            <Col {...breakpoints} style={{ padding: "24px" }}>
              {!application && (
                <>
                  <FadeIn {...fade()}>
                    <SmallTitle>Tell us about yourself</SmallTitle>
                  </FadeIn>

                  <SpacerSmall />
                  <FadeIn {...fade()}>
                    <Input
                      // @ts-ignore
                      id="email"
                      name="email"
                      label="Email"
                      value={email}
                      type="email"
                      required
                      onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                        setValid(ev.target.checkValidity());
                        setEmail(ev.target.value);
                      }}
                    />

                    {!valid && email && (
                      <SmallErrorText>Invalid email</SmallErrorText>
                    )}
                    <Spacer />
                  </FadeIn>
                </>
              )}

              <FadeIn {...fade()}>
                <SmallTitle>Your leasing preferences</SmallTitle>
              </FadeIn>

              <SpacerSmall />
              {showUnitAvailableDate && (
                <FadeIn {...fade()}>
                  <SmallText style={{ opacity: 0.5 }}>
                    <StrongText>This unit</StrongText> is available from{" "}
                    <StrongText>
                      {dayjs(property.availableDate).format("MMMM D, YYYY")}
                    </StrongText>
                  </SmallText>
                  <SpacerSmall />
                </FadeIn>
              )}

              <FadeIn {...fade()}>
                <Row gutter={[24, 24]}>
                  <Col xs={24} sm={12}>
                    {/* @ts-ignore */}
                    <DatePicker
                      id="startDate"
                      name="startDate"
                      label="Start date"
                      value={range.start?.toISOString()}
                      onChange={(x: string) => x && setStart(dayjs(x))}
                      disabledDate={isDisabledDate}
                    />
                    <SpacerTiny />
                    {!hideFlexibleStart && (
                      <Checkbox
                        id="dateFlexible"
                        name="dateFlexible"
                        label="My date is flexible"
                        checked={isFlexibleStart}
                        // @ts-ignore
                        onChange={(ev: ChangeEvent<HTMLInputElement>) =>
                          setIsFlexibleStart(ev.target.checked)
                        }
                      />
                    )}
                  </Col>
                  <Col xs={24} sm={12}>
                    <Select4
                      id="term"
                      name="term"
                      label="Lease duration"
                      disabled={isFlexibleTerm}
                      onChange={(term) => setLeaseTerm(+term)}
                      value={leaseTerm}
                    >
                      {leaseTerms.map((term, key) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Select4Option key={key} value={term}>
                          {getMonthLabel(term)}
                        </Select4Option>
                      ))}
                    </Select4>
                    <SpacerTiny />
                    <Checkbox
                      id="termFlexible"
                      name="termFlexible"
                      label="My lease duration is flexible"
                      checked={isFlexibleTerm}
                      // @ts-ignore
                      onChange={(ev: ChangeEvent<HTMLInputElement>) =>
                        setIsFlexibleTerm(ev.target.checked)
                      }
                    />
                  </Col>
                </Row>
              </FadeIn>
              <Spacer />
              <FadeIn {...fade()}>
                <SmallTitle>Rent price</SmallTitle>
              </FadeIn>
              <SpacerSmall />
              <FadeIn {...fade()}>
                <ChipsRadioButton
                  menuItems={priceRanges}
                  activeItem={priceRange.key}
                  onChange={setPriceRangeByKey}
                />
                <Spacer size={Spacer.SIZES.lg} />
              </FadeIn>

              <LoaderContainer
                Loader={<BigLoadingSpinner data-testid="loading" />}
                fetching={loading}
              >
                <SearchResults
                  {...{
                    start: range.start,
                    setEnd,
                    units,
                    unitsByDay,
                    listType,
                    toggleListType,
                    sort,
                    setSort,
                    unit,
                    setUnit,
                    flexibleStart: isFlexibleStart,
                    loading,
                    hideToggle: hideListToggle,
                  }}
                />
              </LoaderContainer>
              <Spacer />
              <FadeIn {...fade()}>
                <InfoWell />
              </FadeIn>
              <SpacerTop big={!!selectionValidityLifespan} />
              <LetsGo
                {...{
                  unit,
                  valid,
                  selectionValidityLifespan,
                  onClick: onSubmit,
                  loading: submitting,
                  breakpoints,
                }}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
