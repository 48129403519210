import React from "react";

import styled from "@emotion/styled";
import PropTypes from "prop-types";

import { CollapsibleRadioList, ListWrapper } from "shared/components/Form";
import Icon from "shared/components/Icon";
import { Modal } from "shared/components/Modal";
import { LightText } from "shared/components/Typography";
import { BREAKPOINT_TYPES, COLORS } from "shared/config/constants";
import withBreakpoint from "shared/hocs/withBreakpoint";
import { TextEllipsis } from "shared/styles/commonStyles";
import { breakpointPropType, keyPropType } from "shared/utils/propTypes";

const AssignAgentToDealModalContent = styled.div({
  ".assign-agent-list": {
    borderRadius: "2px",
  },
  ".description-cell": {
    ...TextEllipsis,
  },
  ".ant-radio-wrapper": {
    whiteSpace: "pre-wrap",
    "span:last-child": {
      wordBreak: "break-word",
    },
  },
  ".selected-item": {
    backgroundColor: COLORS.lightGreen,
  },
});

const AssignAgentToDealModal = ({
  breakpoint,
  unitName,
  selectedAgent,
  changeAgent,
  submit,
  isAgentsLoading,
  agents,
  isAssigningAgent,
}) => {
  return (
    <Modal
      title="Assign agent to the application"
      subtitle={
        unitName ? (
          <>
            <strong>Unit:</strong> {unitName}
          </>
        ) : null
      }
      submit={submit}
      submitting={isAssigningAgent}
      submitButtonLabel="CONFIRM"
      width="auto"
      closeOnSubmit={false}
      style={{ maxWidth: 776 }}
      fullScreen={BREAKPOINT_TYPES.xs === breakpoint}
    >
      <Modal.Body>
        <AssignAgentToDealModalContent>
          <ListWrapper
            hasInputValue={false}
            className="assign-agent-list"
            List={CollapsibleRadioList}
            listProps={{
              col1Width: "250px",
              setFieldValue: (item) => {
                changeAgent(item);
              },
              selectedItem: selectedAgent,
              items: isAgentsLoading
                ? []
                : (agents || []).map((agent) => ({
                    key: agent.id,
                    label: (
                      <>
                        {agent.name}
                        {agent.isCurrent && <LightText>&nbsp;(me)</LightText>}
                      </>
                    ),
                    description: [{ node: agent.email, noSeparator: true }],
                    className:
                      agent.id === selectedAgent ? "selected-item" : undefined,
                  })),
              itemProps: {
                noBorderBottom: false,
                firstItemNoBorderTop: false,
              },
            }}
            listContainerProps={{
              groupName: "agents",
            }}
            loading={isAgentsLoading}
            placeholder={{
              label: "Agents",
              Icon: Icon.NoEmployeesIcon,
            }}
          />
        </AssignAgentToDealModalContent>
      </Modal.Body>
    </Modal>
  );
};

AssignAgentToDealModal.propTypes = {
  breakpoint: breakpointPropType.isRequired,
  unitName: PropTypes.string,
  selectedAgent: keyPropType.isRequired,
  changeAgent: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  isAgentsLoading: PropTypes.bool.isRequired,
  agents: PropTypes.arrayOf(
    PropTypes.shape({
      id: keyPropType.isRequired,
      name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      isCurrent: PropTypes.bool.isRequired,
    })
  ),
  isAssigningAgent: PropTypes.bool.isRequired,
};

AssignAgentToDealModal.defaultProps = {
  unitName: undefined,
  agents: [],
};

export default withBreakpoint(AssignAgentToDealModal);
