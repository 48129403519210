import React from "react";

import { Form, Formik, FieldArray } from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";

import { MODALS } from "renter/config/constants";
import { applicationGroupPropType } from "renter/utils/propTypes";
import ErrorList from "shared/components/ErrorList";
import { Modal } from "shared/components/Modal";
import Spacer from "shared/components/Spacer";
import {
  AddOccupantsForm,
  AddOccupantsLink,
  ValidationSchema as OccupantsValidationSchema,
  initialValues,
} from "shared/forms/AddOccupantsForm";
import { keyPropType } from "shared/utils/propTypes";

import { useGetApplications } from "../../../../hooks/api";
import ApplicationGroup from "../ApplicationGroup/ApplicationGroup";

import {
  AddOccupantsModalProps,
  AddOccupantsModalDataFormikProps,
} from "./interfaces";

const ValidationSchema = Yup.object().shape({
  ...OccupantsValidationSchema,
});

const AddOccupantsModal = ({
  submit,
  context,
  closeActiveModalDialog,
}: AddOccupantsModalProps) => {
  const { refetchApplications } = useGetApplications();

  return (
    <Formik
      validationSchema={ValidationSchema}
      initialValues={initialValues}
      isInitialValid={ValidationSchema?.isValidSync(initialValues)}
      onSubmit={(values, { resetForm, setSubmitting, setErrors }) => {
        const onSuccess = async () => {
          await refetchApplications?.();
          closeActiveModalDialog();
          setSubmitting(false);
          resetForm();
          setErrors({});
        };

        const onFail = (errors) => {
          setErrors(errors);
          setSubmitting(false);
        };

        submit({ ...values, onSuccess, onFail });
      }}
    >
      {({
        values,
        errors,
        submitForm,
        isSubmitting,
        isValid,
        validateForm,
      }: AddOccupantsModalDataFormikProps) => (
        <Form>
          <Modal
            title="Invite occupants"
            subtitle={
              <>
                <div data-testid="subtitle">
                  {context.property && context.unit ? (
                    <>
                      Home:&nbsp;
                      <strong>
                        {context.property}, {context.unit}
                      </strong>
                    </>
                  ) : (
                    <>
                      Company Name:&nbsp;
                      <strong>{context.companyName}</strong>
                    </>
                  )}
                </div>
                {!!context.applicationGroup?.length && (
                  <ApplicationGroup
                    applicationGroup={context.applicationGroup}
                  />
                )}
              </>
            }
            submitButtonLabel="Invite Occupants"
            submitButtonDisabled={!isValid}
            submit={submitForm}
            submitting={isSubmitting}
            closeOnSubmit={false}
            id={MODALS.addOccupants}
          >
            {errors.nonFieldErrors && (
              <Modal.Body noPaddingBottom>
                <ErrorList errors={errors.nonFieldErrors} />
              </Modal.Body>
            )}
            <Modal.Body noPaddingTop={!!errors.nonFieldErrors}>
              <FieldArray
                name="occupants"
                render={(arrayHelpers) => (
                  <>
                    <AddOccupantsForm
                      occupants={values.occupants}
                      deleteOccupant={arrayHelpers.remove}
                    />
                    <Spacer size={Spacer.SIZES.sm} />
                    <AddOccupantsLink
                      checkValidation={validateForm}
                      addOccupant={arrayHelpers.push}
                    />
                  </>
                )}
              />
            </Modal.Body>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

AddOccupantsModal.propTypes = {
  submit: PropTypes.func.isRequired,
  context: PropTypes.shape({
    property: PropTypes.string.isRequired,
    unit: PropTypes.string.isRequired,
    dealId: keyPropType.isRequired,
    companyName: PropTypes.string.isRequired,
    applicationGroup: applicationGroupPropType,
  }).isRequired,
};

export default AddOccupantsModal;
