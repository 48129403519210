import { createAction } from "redux-actions";

import * as types from "./types";

export const receiveApplications = createAction(
  types.RECEIVE_APPLICATIONS,
  ({ applications }) => ({ applications })
);

export const receiveApplication = createAction(
  types.RECEIVE_APPLICATION,
  ({ application }) => ({ application })
);
