import React from "react";

import { Col, Row } from "antd";

import DropdownMenu from "shared/components/DropdownMenu";
import Icon from "shared/components/Icon";
import Skeleton from "shared/components/Skeleton";
import Tile from "shared/components/Tile";

import { PropertiesDetailProps } from "../interfaces";

import {
  ActionContainer,
  DropdownContainer,
  MainAction,
  MainActionDivider,
} from "./styled";

const PropertiesDetail = ({
  portfolio,
  assignProperties,
  openSettings,
  loading,
}: PropertiesDetailProps) => (
  <Tile>
    <Tile.Inner>
      <Skeleton noMarginBottom title={false} loading={loading}>
        <Row type="flex">
          <Col xs={4} md={2}>
            <Tile.Label>Properties</Tile.Label>
            <span data-testid="propertiesCount">
              {portfolio?.numProperties}
            </span>
          </Col>
          <Col xs={2} md={1}>
            <Tile.Divider />
          </Col>
          <Col xs={3} md={1}>
            <Tile.Label>Units</Tile.Label>
            <span data-testid="totalUnits">{portfolio?.numUnits}</span>
          </Col>
          <Col xs={1} md={1}>
            <Tile.Divider />
          </Col>
          <Col xs={14} md={19}>
            <ActionContainer>
              <MainAction onClick={assignProperties}>
                Assign Properties
              </MainAction>
              <MainActionDivider type="vertical" />
              <MainAction
                onClick={openSettings}
                Icon={Icon.SettingsSingleIcon}
                data-testid="settings"
              >
                Settings
              </MainAction>
              <DropdownContainer>
                <DropdownMenu
                  placement="bottomRight"
                  items={[
                    {
                      key: "ASSIGN_PROPERTIES",
                      label: "Assign properties",
                      onClick: assignProperties,
                    },
                    {
                      key: "OPEN_SETTINGS",
                      label: "Settings",
                      onClick: openSettings,
                    },
                  ]}
                />
              </DropdownContainer>
            </ActionContainer>
          </Col>
        </Row>
      </Skeleton>
    </Tile.Inner>
  </Tile>
);

export default PropertiesDetail;
