import styled from "@emotion/styled";
import { Typography } from "antd";

import { COLORS } from "manager/config/constants";
import { ActionLink as BaseActionLink } from "shared/components/Links";

const { Text } = Typography;

const List = styled.ul`
  padding-left: 16px;
`;

const ListItem = styled.div`
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }

  & * {
    font-size: 17px !important;
    font-weight: normal !important;
    color: ${COLORS.dealSetupSettingsModal.text};
  }
`;

List.ListItem = ListItem;

const Subtitle = styled(Text)`
  font-size: 15px;
`;

const ActionLink = styled(BaseActionLink)`
  max-width: max-content;
  border-bottom: ${({ dashed }) => dashed && "1px dashed"};
`;

export { List, Subtitle, ActionLink };
