import _ from "lodash";

import {
  PERSONA_CHECKS_REASONS_MAP,
  PERSONA_VERIFICATION_PREFIXES,
  PERSONA_VERIFICATION_STATUS,
  VERIFICATION_OF_RESIDENCY_STATUS,
  VERIFICATION_TYPE,
} from "shared/config/constants";

import {
  PERSONA_CHECK_STATUS,
  VerificationCheck,
} from "../../utils/interfaces";
import { TAG_COLORS } from "../Tag";

const UNKNOWN_REASON_DESCRIPTION = "Unknown reason.";

export const mapFailureReasons = (checks) => {
  const failureReasonChecksMap = new Map();

  const addCheckForReasonDescription = (reasonDescription, checkName) => {
    const existingChecks = failureReasonChecksMap.get(reasonDescription);
    if (existingChecks) {
      existingChecks.add(checkName);
      failureReasonChecksMap.set(reasonDescription, existingChecks);
    } else {
      failureReasonChecksMap.set(reasonDescription, new Set([checkName]));
    }
  };

  checks.forEach((check) => {
    if (!check.reasons || check.reasons.length === 0) {
      addCheckForReasonDescription(UNKNOWN_REASON_DESCRIPTION, check.name);
    } else {
      check.reasons.forEach((reason) => {
        const description =
          PERSONA_CHECKS_REASONS_MAP[check.name]?.reasons[reason] ||
          _.capitalize(_.lowerCase(reason)).trim() ||
          UNKNOWN_REASON_DESCRIPTION;
        addCheckForReasonDescription(description, check.name);
      });
    }
  });

  return failureReasonChecksMap;
};

export const VERIFICATION_STATUS_INFO_FOR_TYPE = Object.freeze({
  [VERIFICATION_TYPE.ID]: {
    [PERSONA_VERIFICATION_STATUS.COMPLETED]: {
      label: "Identity Verified",
      color: TAG_COLORS.GREEN,
    },
    [PERSONA_VERIFICATION_STATUS.FAILED]: {
      label: "Identity Unverified",
      color: TAG_COLORS.RED,
    },
    [PERSONA_VERIFICATION_STATUS.EXPIRED]: {
      label: "Identity Expired",
    },
  },
});

export const VERIFICATION_ACCORDION_FOR_STATUS = Object.freeze({
  [PERSONA_VERIFICATION_STATUS.COMPLETED]: "verified",
  [PERSONA_VERIFICATION_STATUS.PASSED]: "verified",
  [PERSONA_VERIFICATION_STATUS.FAILED]: "unverified",
  [VERIFICATION_OF_RESIDENCY_STATUS.SUBMITTED]: "verified",
  [VERIFICATION_OF_RESIDENCY_STATUS.WAITING]: "unverified",
  [VERIFICATION_OF_RESIDENCY_STATUS.STARTED]: "unverified",
});

export const formatRowStr = (str: string, verificationType) => {
  const prefix = PERSONA_VERIFICATION_PREFIXES[verificationType];
  const formattedStr = prefix ? str.replace(prefix, "") : str;
  return formattedStr.replaceAll("_", " ");
};

export const filterChecks = (
  verificationChecks: VerificationCheck[],
  personaCheckStatus: PERSONA_CHECK_STATUS
): VerificationCheck[] => {
  return verificationChecks.filter(
    (check) => check.status !== personaCheckStatus
  );
};
