import React, { useState } from "react";

import { StarFilledIcon, StarOutlineIcon } from "shared/icons";

import { StyledIcon } from "./styled";

export interface StarButtonProps {
  onClick: () => void;
  active?: boolean;
  [key: string]: any;
}

const StarButton = ({ onClick, active = false, ...props }: StarButtonProps) => {
  const [isHovering, setIsHovering] = useState(false);
  let icon;

  if (active) {
    icon = <StarFilledIcon data-testid="filled-icon" />;
  } else {
    icon = <StarOutlineIcon hovered={isHovering} data-testid="outline-icon" />;
  }

  return (
    <StyledIcon
      data-testid="star-button"
      role="button"
      onClick={onClick}
      tabIndex={0}
      onKeyPress={onClick}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      {...props}
    >
      {icon}
    </StyledIcon>
  );
};

export default StarButton;
