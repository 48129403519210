import React from "react";

import { Col, Divider, Row } from "antd-latest";

import pluralize from "pluralize";

import { FadeIn } from "shared/components/animated";
import FlexContainer from "shared/components/FlexContainer";
import Spacer from "shared/components/Spacer";
import {
  MediumTitle,
  RegularText,
  SmallText,
} from "shared/components/Typography";

import { ApplicantType } from "shared/graphql";

const breakpoints = {
  // flex: 5,
  lg: 6,
  md: 12,
  sm: 12,
  xs: 24,
};

export type DependentProps = {
  firstName?: string;
  lastName?: string;
  relationship?: string;
  age?: number;
  type?: ApplicantType;
};

export const Dependents: React.FC<{
  dependents: DependentProps[];
}> = ({ dependents = [] }) => (
  <>
    {dependents?.map((dep) => (
      <>
        <Row gutter={[15, 15]}>
          <Col {...breakpoints} style={{ position: "relative", padding: 15 }}>
            <FadeIn delay={400}>
              <MediumTitle strong>
                {dep.firstName} {dep.lastName}
              </MediumTitle>
              <FlexContainer justifyContent="flex-start" alignItems="center">
                <SmallText light extraStrong>
                  {(dep.type ?? ApplicantType.occupant).toUpperCase()}
                </SmallText>
              </FlexContainer>
            </FadeIn>
          </Col>
          <Col {...breakpoints} style={{ position: "relative", padding: 15 }}>
            <FadeIn delay={500}>
              <RegularText>
                Relationship:{" "}
                <span style={{ textTransform: "capitalize" }}>
                  {dep.relationship?.toLowerCase()}
                </span>
              </RegularText>
              <br />
              <RegularText light>
                Age: {dep.age} {pluralize("year")} old
              </RegularText>
            </FadeIn>
          </Col>
        </Row>
        <Spacer />
        <Divider />
      </>
    ))}
  </>
);
