import React from "react";

import { useGetStates } from "manager/hooks/api";

import { StateSelect } from "./StateSelect";

export const StateSelectContainer = (props: any) => {
  const { states, isStatesLoading } = useGetStates();

  return <StateSelect {...props} states={states} loading={isStatesLoading} />;
};
