import React, { useEffect, useRef } from "react";

import { Field } from "formik";

import { Input } from "shared/components/Form";
import { extractAddressDetails } from "shared/utils/address";

type FieldMapping = {
  address: string;
  city: string;
  state: string;
  country: string;
  zipcode: string;
};

type AddressAutocompleteInputProps = {
  index: number;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  error: any;
  id: string;
  name: string;
  fieldMapping: FieldMapping;
  onBlur: () => void;
  disabled?: boolean;
  label?: string;
  required?: boolean;
  trimOnBlur?: boolean;
};

const AddressAutocompleteInput: React.FC<AddressAutocompleteInputProps> = ({
  index,
  setFieldValue,
  error,
  id,
  name,
  fieldMapping,
  onBlur,
  disabled = false,
  label = "Address",
  required = true,
  trimOnBlur = true,
}) => {
  const inputRef = useRef(null);

  const setFormikFields = (addressDetails) => {
    Object.entries(addressDetails).forEach(([key, value]) => {
      setFieldValue(fieldMapping[key], value);
    });
  };

  const handlePlaceChange = (autocompleteInstance) => {
    const place = autocompleteInstance.getPlace();
    if (place && place.address_components) {
      const addressDetails = extractAddressDetails(place);
      setFormikFields(addressDetails);
      onBlur();
    }
  };

  useEffect(() => {
    const { google } = window as any;

    const initAutocomplete = () => {
      if (google && google.maps && google.maps.places && inputRef.current) {
        const autocompleteInstance = new google.maps.places.Autocomplete(
          inputRef.current.input,
          {
            types: ["address"],
            componentRestrictions: { country: "us" },
          }
        );
        autocompleteInstance.addListener("place_changed", () =>
          handlePlaceChange(autocompleteInstance)
        );
      }
    };

    if (google) {
      initAutocomplete();
    } else {
      window.initGoogleMaps = initAutocomplete;
    }
  }, [index, setFieldValue, window.google]);

  return (
    <Field id={id} name={name}>
      {({ field }) => (
        <Input
          {...field}
          label={label}
          type="text"
          id={id}
          ref={inputRef}
          placeholder=""
          required={required}
          disabled={disabled}
          error={error}
          onBlur={onBlur}
          formikContext={{
            trimOnBlur,
            setFieldValue,
          }}
        />
      )}
    </Field>
  );
};

export default AddressAutocompleteInput;
