import { Key } from "react";
import { queryCache, useMutation, useQuery } from "react-query";
import _ from "lodash";
import lumenApi, {
  AssignLumenFormulaRequest,
  DuplicateLumenFormulaRequest,
  getLumenResultForDeal,
  LumenFormulaRevisionRequest,
  LumenFormulaTemplateRequest,
  RenameLumenFormulaRequest,
  listLumenV2Formulas,
  getLatestLumenV2Run,
  runLumenV2,
  createLumenV2Formula,
  updateLumenV2Formula,
  deleteLumenV2Formula,
  deleteExplanation
} from "manager/lib/lumenApi";
import { NOTIFICATIONS } from "shared/config/constants";
import { openNotification } from "shared/utils/ui";

const LUMEN_FORMULAS = "getLumenFormulas";
const LUMEN_FORMULA_REVISION = "getLumenFormulaRevision";
const LUMEN_RESULT_FOR_DEAL = "getLumenResultForDeal";
const LUMEN_FORMULA_ASSIGNMENTS = "getLumenFormulaAssignments";
const LUMEN_FORMULA_TEMPLATES = "getLumenFormulaTemplates";
const LIST_LUMEN_V2_FORMULAS = "listLumenV2Formulas";
const GET_LATEST_LUMEN_V2_RUN = "getLatestLumenV2Run";

const useGetLumenFormulas = (enabled) => {
  const { data, isLoading } = useQuery(
    [LUMEN_FORMULAS],
    () => lumenApi.getLumenFormulas(),
    {
      enabled,
      refetchOnMount: "always",
      onError: () => {
        openNotification("Failed to get Lumen formulas.", NOTIFICATIONS.error);
      },
    }
  );

  return {
    lumenFormulas: data || [],
    isLoading,
  };
};

const useCreateLumenFormula = () => {
  const [mutate, status] = useMutation(
    (createFormulaDto) => lumenApi.createLumenFormula(createFormulaDto),
    {
      onError: () => {
        openNotification(
          "Failed to create Lumen formula.",
          NOTIFICATIONS.error
        );
      },
      onSuccess: () => {
        queryCache.invalidateQueries([LUMEN_FORMULAS]);
      },
    }
  );

  return {
    createLumenFormula: mutate,
    isLoading: status.isLoading,
  };
};

const useEditLumenFormula = () => {
  const [mutate, status] = useMutation(
    (formulaId: Key) => lumenApi.editLumenFormula(formulaId),
    {
      onError: (error) => {
        if (typeof Object?.values(error)[1]?.message === "string") {
          openNotification(
            Object.values(error)[1]?.message,
            NOTIFICATIONS.error
          );
        } else {
          openNotification(
            "Failed to edit Lumen formula.",
            NOTIFICATIONS.error
          );
        }
      },
      onSuccess: () => {
        queryCache.invalidateQueries([LUMEN_FORMULAS]);
      },
    }
  );

  return {
    editLumenFormula: mutate,
    isLoading: status.isLoading,
  };
};

const useRenameLumenFormula = () => {
  const [mutate, status] = useMutation(
    ({ formulaId, renameFormulaDto }: RenameLumenFormulaRequest) =>
      lumenApi.renameLumenFormula({ formulaId, renameFormulaDto }).then(() => {
        openNotification(
          `The Lumen formula has been successfully renamed to "${renameFormulaDto.name}".`,
          NOTIFICATIONS.info
        );
      }),
    {
      onError: () => {
        openNotification(
          "Failed to rename Lumen formula.",
          NOTIFICATIONS.error
        );
      },
      onSuccess: () => {
        queryCache.invalidateQueries([LUMEN_FORMULAS]);
      },
    }
  );

  return {
    renameLumenFormula: mutate,
    isLoading: status.isLoading,
  };
};

const useDuplicateLumenFormula = () => {
  const [mutate, status] = useMutation(
    ({ formulaId, duplicateFormulaDto }: DuplicateLumenFormulaRequest) =>
      lumenApi.duplicateLumenFormula({ formulaId, duplicateFormulaDto }),
    {
      onError: () => {
        openNotification(
          "Failed to duplicate Lumen formula.",
          NOTIFICATIONS.error
        );
      },
      onSuccess: () => {
        queryCache.invalidateQueries([LUMEN_FORMULAS]);
      },
    }
  );

  return {
    duplicateLumenFormula: mutate,
    isLoading: status.isLoading,
  };
};

const useDeleteLumenFormula = () => {
  const [mutate, status] = useMutation(
    (formulaId) =>
      lumenApi.deleteLumenFormula(formulaId).then(() => {
        openNotification(
          `The Lumen formula has been successfully deleted.`,
          NOTIFICATIONS.info
        );
      }),
    {
      onError: () => {
        openNotification(
          "Failed to delete Lumen formula.",
          NOTIFICATIONS.error
        );
      },
      onSuccess: () => {
        queryCache.invalidateQueries([LUMEN_FORMULAS]);
      },
    }
  );

  return {
    deleteLumenFormula: mutate,
    isLoading: status.isLoading,
  };
};

const useGetLumenFormulaRevision = (formulaId, revisionId) => {
  const { data, isLoading } = useQuery(
    [LUMEN_FORMULA_REVISION, formulaId, revisionId],
    () => lumenApi.getLumenFormulaRevision({ formulaId, revisionId }),
    {
      enabled: !!formulaId && !!revisionId,
      refetchOnMount: "always",
      onError: () => {
        openNotification(
          "Failed to get Lumen formula revision.",
          NOTIFICATIONS.error
        );
      },
    }
  );

  return {
    lumenFormulaRevision: data,
    isLoading,
  };
};

const useUpdateLumenFormulaRevision = () => {
  const [mutate, status] = useMutation(
    ({ formulaId, revisionId, updateDto }: LumenFormulaRevisionRequest) =>
      lumenApi.updateLumenFormulaRevision({ formulaId, revisionId, updateDto }),
    {
      onError: () => {
        openNotification(
          "Failed to update Lumen formula revision.",
          NOTIFICATIONS.error
        );
      },
      onSuccess: () => {
        queryCache.invalidateQueries([LUMEN_FORMULA_REVISION]);
      },
    }
  );

  return {
    updateLumenFormulaRevision: mutate,
    isLoading: status.isLoading,
  };
};

const useFinishLumenFormulaRevision = () => {
  const [mutate, status] = useMutation(
    ({ formulaId, revisionId, updateDto }: LumenFormulaRevisionRequest) =>
      lumenApi.completeLumenFormulaRevision({
        formulaId,
        revisionId,
        updateDto,
      }),
    {
      onError: () => {
        openNotification(
          "Failed to finish Lumen formula revision.",
          NOTIFICATIONS.error
        );
      },
      onSuccess: () => {
        queryCache.invalidateQueries([LUMEN_FORMULA_REVISION]);
      },
    }
  );

  return {
    finishLumenFormulaRevision: mutate,
    isLoading: status.isLoading,
  };
};

const useGetLumenResultForDeal = (dealId, lumenEnabled) => {
  const { data, isLoading, refetch } = useQuery(
    [LUMEN_RESULT_FOR_DEAL, dealId],
    () => getLumenResultForDeal({ dealId }),
    {
      enabled: !!dealId && !!lumenEnabled,
      refetchOnMount: "always",
    }
  );

  return {
    lumenResult: data,
    refetchLumenResult: refetch,
    isLoading,
  };
};

export function useListLumenV2Formulas (location: string, detailed: boolean = false) {
  const { data: lumenFormulas, isLoading, refetch } = useQuery(
    [LIST_LUMEN_V2_FORMULAS, _.pickBy({ location, detailed })],
    () => listLumenV2Formulas(location, detailed),
    {
      enabled: !!location
    }
  )

  return {
    lumenFormulas,
    isLoading,
    refetch
  };
}

export function useGetLatestLumenV2Run (application, showLumen) {
  const { data: lumenRun, isLoading, refetch } = useQuery(
    [GET_LATEST_LUMEN_V2_RUN, { application }],
    () => getLatestLumenV2Run(application),
    {
      enabled: !!(showLumen && application)
    }
  )

  return {
    lumenRun,
    isLoading,
    refetch
  }
}

export function useLumenV2Mutations () {
  const handlers = (s) => ({
    onSuccess: async (data) => {
      await queryCache.invalidateQueries('listLumenV2Formulas')
      openNotification(_.chain([s, data?.id]).compact().join(' ').value(), NOTIFICATIONS.info)
    },
    onError: (error) => openNotification(error?.message || error?.errorMessage || error, NOTIFICATIONS.error)
  })

  const [runLumenV2Mutaion, runLumenV2Status] = useMutation(runLumenV2, handlers('ran lumen'))
  const [createLumenV2FormulaMutation, createLumenV2FormulaStatus] = useMutation(createLumenV2Formula, handlers('created lumen formula'))
  const [updateLumenV2FormulaMutation, updateLumenV2FormulaStatus] = useMutation(updateLumenV2Formula, handlers('saved lumen formula'))
  const [deleteLumenV2FormulaMutation, deleteLumenV2FormulaStatus] = useMutation(deleteLumenV2Formula, handlers('deleted lumen formula'))

  return {
    runLumenV2: runLumenV2Mutaion,
    runLumenV2Loading: runLumenV2Status?.isLoading,
    createLumenV2Formula: createLumenV2FormulaMutation,
    createLumenV2FormulaLoading: createLumenV2FormulaStatus?.isLoading,
    updateLumenV2Formula: updateLumenV2FormulaMutation,
    updateLumenV2FormulaLoading: updateLumenV2FormulaStatus?.isLoading,
    deleteLumenV2Formula: deleteLumenV2FormulaMutation,
    deleteLumenV2FormulaLoading: deleteLumenV2FormulaStatus?.isLoading
  }
}

export function useDeleteExplanation () {
  const [mutate, status] = useMutation(
    (id) => deleteExplanation(id)
  )

  return {
    deleteExplanation: mutate,
    isLoading: status.isLoading
  }
}

const useGetLumenFormulaAssignments = (formulaId) => {
  const { data, isLoading } = useQuery(
    [LUMEN_FORMULA_ASSIGNMENTS, formulaId],
    () => lumenApi.getLumenFormulaAssignments(formulaId),
    {
      enabled: !!formulaId,
      refetchOnMount: "always",
      onError: () => {
        openNotification(
          "Failed to get Lumen formula assignments.",
          NOTIFICATIONS.error
        );
      },
    }
  );

  return {
    lumenFormulaAssignments: data,
    isLoading,
  };
};

const useAssignLumenFormula = () => {
  const [mutate, status] = useMutation(
    ({ formulaId, assignments }: AssignLumenFormulaRequest) =>
      lumenApi.assignLumenFormula({
        formulaId,
        assignments,
      }),
    {
      onError: () => {
        openNotification(
          "Failed to assign Lumen formula.",
          NOTIFICATIONS.error
        );
      },
      onSuccess: () => {
        queryCache.invalidateQueries([LUMEN_FORMULAS]);
      },
    }
  );

  return {
    assignLumenFormula: mutate,
    isLoading: status.isLoading,
  };
};

const useGetLumenFormulaTemplates = (enabled?: boolean) => {
  const { data, isLoading } = useQuery(
    [LUMEN_FORMULA_TEMPLATES],
    () => lumenApi.getLumenFormulaTemplates(),
    {
      enabled,
      refetchOnMount: "always",
      onError: (err: any) => {
        if (err.detail)
          openNotification(
            "Failed to get Lumen formula templates.",
            NOTIFICATIONS.error
          );
      },
    }
  );

  return {
    lumenFormulaTemplates: data,
    isLoading,
  };
};

const useMarkLumenFormulaAsTemplate = () => {
  const [mutate, status] = useMutation(
    ({ formulaId, markFormulaAsTemplateDto }: LumenFormulaTemplateRequest) =>
      lumenApi
        .markLumenFormulaAsTemplate({
          formulaId,
          markFormulaAsTemplateDto,
        })
        .then(() => {
          openNotification(
            `The "${markFormulaAsTemplateDto.name}" Lumen formula template has been successfully created.`,
            NOTIFICATIONS.info
          );
        }),
    {
      onError: () => {
        openNotification(
          "Failed to mark Lumen formula as template.",
          NOTIFICATIONS.error
        );
      },
      onSuccess: () => {
        queryCache.invalidateQueries([LUMEN_FORMULA_TEMPLATES]);
      },
    }
  );

  return {
    markLumenFormulaAsTemplate: mutate,
    isLoading: status.isLoading,
  };
};

export {
  useGetLumenFormulas,
  useCreateLumenFormula,
  useEditLumenFormula,
  useRenameLumenFormula,
  useDuplicateLumenFormula,
  useDeleteLumenFormula,
  useGetLumenFormulaRevision,
  useUpdateLumenFormulaRevision,
  useFinishLumenFormulaRevision,
  useGetLumenResultForDeal,
  useGetLumenFormulaAssignments,
  useAssignLumenFormula,
  useGetLumenFormulaTemplates,
  useMarkLumenFormulaAsTemplate,
};
