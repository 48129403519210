import React from "react";

import useModal from "shared/hooks/useModal";

import EditBasicInfoModal from "./EditBasicInfoModal";

const EditBasicInfoModalContainer = () => {
  const { currentModalContext } = useModal();
  const { submit, profile } = currentModalContext;

  return <EditBasicInfoModal submit={submit} profile={profile} />;
};

export default EditBasicInfoModalContainer;
