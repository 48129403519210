import { useEffect } from "react";

export const useGoogleMapsApi = () => {
  useEffect(() => {
    const loadGoogleMapsApi = async () => {
      if (window.google) {
        window.initGoogleMaps?.();
      } else {
        window.initGoogleMaps = (): void => {};
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_PLATFORM_API_KEY}&libraries=places&callback=initGoogleMaps`;
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
      }
    };

    loadGoogleMapsApi();
  }, []);
};
