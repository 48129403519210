import { generateId } from "shared/utils/misc.util";
import { stringify } from "shared/utils/string";

const NEW_SOURCE_ID_PREFIX = "NEW_SOURCE_";

const cleanupSources = (sources) => {
  return (sources || []).map((source) => {
    const updatedSource = { id: source.id, value: source.name };
    if ((stringify(updatedSource.id) || "").includes(NEW_SOURCE_ID_PREFIX)) {
      delete updatedSource.id;
    }
    return updatedSource;
  });
};

const isSourceLinked = (source) => !!source.yardiSourceLinked;

const convertRawSources = (sources) =>
  (sources || []).map((source) => ({
    id: source.id,
    name: source.value,
    yardiSourceId: source.yardiSourceId,
    isLinked: isSourceLinked(source),
  }));

const generateSourceId = () => {
  return generateId(NEW_SOURCE_ID_PREFIX);
};

export { generateSourceId, cleanupSources, isSourceLinked, convertRawSources };
