import React, { Dispatch, SetStateAction } from "react";

import { Employee } from "manager/interfaces/api/employee";
import { DropdownButton } from "shared/components/Button";
import { BackLinkProps, Pagination } from "shared/interfaces";
import TablePage from "shared/templates/TablePage";

import EmployeesTable from "./EmployeesTable";
import { SearchParams } from "./interface";

interface ExportEmployeeButtonProps {
  exportEmployees: () => void;
  exportAllEmployees: () => void;
  disabled: boolean;
}

const ExportEmployeeButton = ({
  exportEmployees,
  exportAllEmployees,
  disabled,
}: ExportEmployeeButtonProps) => (
  <DropdownButton
    type="secondary"
    light
    disabled={disabled}
    items={[
      {
        key: "all",
        label: "All Employees",
        onClick: exportAllEmployees,
      },
      {
        key: "filtered",
        label: "Filtered Employees",
        onClick: exportEmployees,
      },
    ]}
  >
    Export Employees
  </DropdownButton>
);

interface EmployeesProps {
  isEmployeeRolesDropdownLoading: boolean;
  employeesCount: number;
  handleSearchParams: (key: string, value: string) => void;
  searchParams: SearchParams;
  disableSerchAndFilters: boolean;
  employeeRoles: { label: string; key: string | number }[];
  handleAllSearchParams: (params: SearchParams) => void;
  exportEmployees: (params?: SearchParams) => void;
  isExportingEmployees: boolean;
  hasInputValue: boolean;
  setPagination: Dispatch<SetStateAction<Pagination>>;
  pagination: Pagination;
  backLink: BackLinkProps;
  isEmployeesLoading: boolean;
  employees: Employee[];
}

const Employees = ({
  isEmployeeRolesDropdownLoading,
  employeesCount,
  handleSearchParams,
  searchParams,
  disableSerchAndFilters,
  employeeRoles,
  handleAllSearchParams,
  exportEmployees,
  isExportingEmployees,
  hasInputValue,
  setPagination,
  pagination,
  backLink,
  isEmployeesLoading,
  employees,
}: EmployeesProps) => {
  return (
    <TablePage
      backLink={backLink}
      title="Employees"
      tables={[
        {
          filterBar: {
            search: {
              action: (value) => handleSearchParams("nameOrEmail", value),
              placeholder: "Search by name or email",
              width: 340,
              debounce: true,
              value: searchParams.nameOrEmail,
              disabled: disableSerchAndFilters,
            },
            dropdownFilters: [
              {
                key: "roleId",
                label: "Role",
                value: searchParams.roleId,
                onChange: (selectedFilter) =>
                  handleSearchParams(
                    "roleId",
                    selectedFilter === "" ? null : selectedFilter
                  ),
                items: employeeRoles,
                disabled: disableSerchAndFilters,
              },
            ],
            checkboxFilters: [
              {
                key: "activeOnly",
                label: "Active users only",
                onChange: (targetValue) =>
                  handleSearchParams("activeOnly", targetValue),
                value: Boolean(searchParams.activeOnly),
                disabled: disableSerchAndFilters,
              },
            ],
            loadingDropdownOptions: isEmployeeRolesDropdownLoading,
            setFilters: handleAllSearchParams,
            action: {
              key: "EXPORT EMPLOYEES",
              node: (
                <ExportEmployeeButton
                  exportEmployees={() =>
                    exportEmployees({
                      ...searchParams,
                      activeOnly: searchParams.activeOnly || undefined,
                    })
                  }
                  exportAllEmployees={() => exportEmployees({} as SearchParams)}
                  disabled={employeesCount === 0 || isExportingEmployees}
                />
              ),
            },
          },
          node: (
            <EmployeesTable
              hasInputValue={hasInputValue}
              setPagination={setPagination}
              pagination={pagination}
              employees={employees}
              loading={isEmployeesLoading}
              employeesCount={employeesCount}
            />
          ),
        },
      ]}
    />
  );
};

export default Employees;
