import capitalize from "lodash/capitalize";
import flatten from "lodash/flatten";
import startCase from "lodash/startCase";

import { ANNOTATION_TYPES } from "admin/components/PDFTronViewer/webviewer";
import {
  getCheckboxGroupItemName,
  getCheckboxGroupName,
} from "admin/config/checkboxGroupSignature.config";
import {
  FIELD_TYPES,
  FIELD_TYPES_LABELS,
  FIELD_CATEGORIES_LABELS,
} from "admin/config/constants";

const sortByCollapsible = (
  { collapsible: collapsibleA },
  { collapsible: collapsibleB }
) => {
  if (collapsibleA && !collapsibleB) {
    return -1;
  }
  if (!collapsibleA && collapsibleB) {
    return 1;
  }

  return 0;
};

const formatNestedFields = (fields) =>
  Object.keys(fields)
    .map((fieldName) => {
      const { id, type, name, category, sample, description, smartSource } =
        fields[fieldName];
      const isPlainField = Boolean(id);

      return isPlainField
        ? {
            id,
            name,
            displayedName: startCase(fieldName),
            type:
              type === FIELD_TYPES.checkbox
                ? ANNOTATION_TYPES.checkbox
                : ANNOTATION_TYPES.field,
            displayedType: FIELD_TYPES_LABELS[type],
            displayedCategory: FIELD_CATEGORIES_LABELS[category],
            sample,
            description,
            dataMapping: capitalize(
              smartSource?.attribute?.replaceAll("_", " ")
            ),
            format: smartSource?.formatter,
          }
        : {
            collapsible: true,
            displayedName: startCase(fieldName),
            fields: formatNestedFields(fields[fieldName]),
          };
    })
    // @ts-ignore
    .sort(sortByCollapsible);

export const formatApplicantsFields = (applicantsFields) => {
  const result = {};

  Object.keys(applicantsFields).forEach((applicantsByCategory) => {
    const categoryName = startCase(applicantsByCategory);

    result[categoryName] = Object.keys(
      applicantsFields[applicantsByCategory]
    ).map((applicantNumber) =>
      formatNestedFields(
        applicantsFields[applicantsByCategory][applicantNumber]
      )
    );
  });

  return result;
};

export const formatDocumentFields = (fields) =>
  fields.map((field) => ({
    id: field.id,
    displayedName: startCase(field.name),
    type:
      field.type === FIELD_TYPES.checkbox
        ? ANNOTATION_TYPES.checkbox
        : ANNOTATION_TYPES.field,
    name: field.name,
    displayedType: FIELD_TYPES_LABELS[field.type],
    displayedCategory: FIELD_CATEGORIES_LABELS[field.category],
    sample: field.sample,
    description: field.description,
    dataMapping: capitalize(field.smartSource?.attribute?.replaceAll("_", " ")),
    format: field.smartSource?.formatter,
  }));

export const formatSignatureFields = (signatures) => {
  const categories =
    signatures?.categories?.map(({ name: categoryName, fields }) => {
      return {
        displayedName: startCase(categoryName),
        collapsible: true,
        fields: fields.map(({ name, value }) => ({
          displayedName: startCase(name),
          name: value,
          type: ANNOTATION_TYPES.signature,
        })),
      };
    }) || [];

  const otherFields =
    signatures?.otherFields?.map(({ name, value }) => ({
      displayedName: startCase(name),
      name: value,
      type: ANNOTATION_TYPES.signature,
    })) || [];

  const checkboxGroupSignatures = {
    displayedName: "Grouped Checkboxes",
    collapsible: true,
    fields:
      flatten(
        signatures.checkboxGroupSignatures?.map(({ name, min, allowed }) => {
          const meta = {
            groupName: name,
            min,
            allowed,
            controllerDisplayedName: `${startCase(name)} (Controller)`,
            controllerName: getCheckboxGroupName({ name, min, allowed }),
            checkboxName: getCheckboxGroupItemName({ name }),
            checkboxDisplayedName: `${startCase(name)} (Checkbox)`,
          };

          return [
            {
              displayedName: meta.controllerDisplayedName,
              name: meta.controllerName,
              type: ANNOTATION_TYPES.checkboxGroupSignature,
              meta: { ...meta },
              displayedType: "Checkbox Group Controller",
              description:
                "Invisible field that controls checkbox group. (1 per group)",
            },
            {
              displayedName: meta.checkboxDisplayedName,
              name: meta.checkboxName,
              type: ANNOTATION_TYPES.checkboxGroupSignature,
              meta: { ...meta },
              displayedType: FIELD_TYPES_LABELS.CB,
              description: `Required selections: ${meta.min}. Allowed selections: ${meta.allowed}`,
              shifted: true,
            },
          ];
        })
      ) || [],
  };

  const result = [...categories, checkboxGroupSignatures, ...otherFields];
  return result;
};
