import React from "react";

import DetectableOverflowTooltip from "shared/components/DetectableOverflowTooltip";
import { RegularText, SmallText } from "shared/components/Typography";
import { getFullnameData } from "shared/utils/applicant";

import { ApplicationGroupProps } from "./interfaces";
import { ApplicationGroupWrapper } from "./styled";

const ApplicationGroup = ({
  applicationGroup,
  message = "Applicants already invited to your group:",
  showType = true,
}: ApplicationGroupProps) => (
  <ApplicationGroupWrapper>
    <SmallText>{message}</SmallText>
    <div className="inner-section">
      <ul>
        {applicationGroup.map((user, idx) => {
          const { fullNameOrNA } = getFullnameData(user);
          const key = idx + 1;
          return (
            <li key={key}>
              {/* @ts-ignore */}
              <DetectableOverflowTooltip theme="light" placement="topLeft">
                <RegularText light>
                  {fullNameOrNA} {showType && `(${user.type})`}{" "}
                  {user.email && <>- {user.email}</>}
                </RegularText>
              </DetectableOverflowTooltip>
            </li>
          );
        })}
      </ul>
    </div>
  </ApplicationGroupWrapper>
);

export default ApplicationGroup;
