export const RADIO_BUTTONS_THEMES = Object.freeze({
  violet: "violet",
  darkViolet: "darkViolet",
  lightViolet: "lightViolet",
  blue: "blue",
  gray: "gray",
  accent400: "accent400",
  secondary400: "secondary400",
  primary300: "primary300",
} as const);

export type RadioButtonsTheme =
  typeof RADIO_BUTTONS_THEMES[keyof typeof RADIO_BUTTONS_THEMES];

export interface MenuItem {
  key: string;
  name: string;
}
