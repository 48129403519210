import React, { useEffect } from "react";

import { Field, useFormikContext } from "formik";

import { TagsInput } from "shared/components/Form";
import { addDefaultObjectValues, showError } from "shared/utils/forms";

import BaseCondition from "./BaseCondition";
import { commonFilterConditionPropTypes } from "./lumenFilterConditions.config";
import {
  getBaseConditionPath,
  getConditionPropPath,
} from "./lumenFilterConditions.util";

const ConditionKeywords = ({ idx, onDelete }) => {
  const { values, errors, touched, setFieldTouched, setFieldValue } =
    useFormikContext();
  const keywordsProp = "keywords";
  const keywordsBasePath = getBaseConditionPath(idx);
  const keywordsPropPath = getConditionPropPath(idx, keywordsProp);
  useEffect(() => {
    const defaultValues = {
      [keywordsProp]: [],
    };
    addDefaultObjectValues(
      values,
      keywordsBasePath,
      defaultValues,
      setFieldValue
    );
  }, []);

  return (
    <BaseCondition conditionLabel="Charge name contains:" onDelete={onDelete}>
      <div className="w-100 keywords-input-field-container">
        <Field
          name={keywordsPropPath}
          id={keywordsPropPath}
          label="Enter"
          as={TagsInput}
          error={showError(keywordsPropPath, touched, errors)}
          onChange={(value) => setFieldValue(keywordsPropPath, value)}
          onBlur={() => setFieldTouched(keywordsPropPath, true)}
          tokenSeparators={[',']}
        />
      </div>
    </BaseCondition>
  );
};

ConditionKeywords.propTypes = {
  ...commonFilterConditionPropTypes,
};

export default ConditionKeywords;
