import React, { useEffect, useState } from "react";

import filter from "lodash/filter";
import uniq from "lodash/uniq";

import AssignDocumentModal from "admin/components/Modal/AssignDocumentModal";
import PreviewDocumentModal from "admin/components/Modal/PreviewDocumentModal";
import UnassignDocumentModal from "admin/components/Modal/UnassignDocumentModal";
import { useDeleteDocument } from "admin/hooks/api";
import ConfirmationModal from "shared/components/Modals/ConfirmationModal";
import useModal from "shared/hooks/useModal";
import { sanitizeStringSearchValue } from "shared/utils/string";

import { Document } from "../interfaces";

import DocumentsTable from "./DocumentsTable";

interface DocumentsTableContainerProps {
  isLoading: boolean;
  documents: Document[];
  searchParams: { docNameOrCompanyNameOrTagName: string };
}

const DocumentsTableContainer = ({
  isLoading,
  documents = [],
  searchParams: { docNameOrCompanyNameOrTagName },
}: DocumentsTableContainerProps) => {
  const { openModalDialog } = useModal();
  const openAssignDocumentModal = (docs) =>
    openModalDialog(AssignDocumentModal, { documents: docs });
  const openUnassignDocumentModal = (document) =>
    openModalDialog(UnassignDocumentModal, { document });
  const openPreviewDocumentModal = (document) =>
    openModalDialog(PreviewDocumentModal, { document });

  const [filteredDocuments, setFilteredDocuments] = useState<Document[]>([]);

  const handleFilteredDocuments = (key) => {
    let filteredList: any = documents;

    if (key) {
      const documentNames = filter(documents, (document) =>
        sanitizeStringSearchValue(document.name)?.includes(key)
      );

      const companyNames = filter(
        documents,
        (document) =>
          filter(document.companiesAssigned, (companyName) =>
            sanitizeStringSearchValue(companyName)?.includes(key)
          ).length > 0
      );

      const tagNames = filter(
        documents,
        (document) =>
          filter(document.tags, (tag) =>
            sanitizeStringSearchValue(tag.name)?.includes(key)
          ).length > 0
      );

      filteredList = uniq([...documentNames, ...companyNames, ...tagNames]);
    }
    return filteredList;
  };

  const { deleteDocument } = useDeleteDocument();

  const handleDeleteDocument = ({
    documentId,
    documentName,
  }: {
    documentId: number;
    documentName: string;
  }) => {
    const context = {
      title: "Are you sure?",
      subtitle: `Document: ${documentName}`,
      message:
        "Removing this document will permanently delete it and it cannot be recovered. Are you sure you would like to do this?",
      submitButtonLabel: "Yes",
    };

    openModalDialog(ConfirmationModal, context).afterClose.then(() =>
      deleteDocument({
        documentId,
        documentName,
      })
    );
  };

  useEffect(() => {
    setFilteredDocuments(documents);
  }, [documents]);

  useEffect(() => {
    setFilteredDocuments(
      handleFilteredDocuments(docNameOrCompanyNameOrTagName.toLowerCase())
    );
  }, [docNameOrCompanyNameOrTagName]);

  return (
    <DocumentsTable
      loading={isLoading}
      documents={filteredDocuments}
      openAssignDocumentModal={openAssignDocumentModal}
      openUnassignDocumentModal={openUnassignDocumentModal}
      openPreviewDocumentModal={openPreviewDocumentModal}
      handleDeleteDocument={handleDeleteDocument}
    />
  );
};

export default DocumentsTableContainer;
