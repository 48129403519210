import React, { useState, useEffect } from "react";

import { RcCustomRequestOptions, RcFile } from "antd/lib/upload/interface";

import { useUploadPropertyLogo } from "manager/hooks/api/propertyQueries";
import { MAX_FILE_UPLOAD_SIZE } from "shared/config/constants";
import useModal from "shared/hooks/useModal";
import { useUploadValidation } from "shared/hooks/useUploadValidation";

import { FILE_STATUS, getBase64, UPLOAD_ALLOWED_FILE_TYPES } from "./config";
import UploadPropertyLogoModal from "./UploadPropertyLogoModal";

const UploadPropertyLogoModalContainer = () => {
  const { currentModalContext, selfApi } = useModal();
  const { isFileSizeValid, isFileNameValid, isFileFormatValid } =
    useUploadValidation();
  const { propertySettings } = currentModalContext;

  const [customRequestOnSuccess, setCustomRequestOnSuccess] =
    useState<Function>();

  const { uploadPropertyLogo, isLoading: isConfirming } =
    useUploadPropertyLogo();
  const isUpdatingProcess = Boolean(propertySettings.logo);

  const [document, setDocument] = useState({
    file: null,
    preview: propertySettings.logo,
    isLoading: false,
    status: null,
  });

  const uploadDocuments = async ({ file }) => {
    if (file.status === FILE_STATUS.uploading) {
      setDocument((prevDocument) => ({
        ...prevDocument,
        status: FILE_STATUS.uploading,
      }));
    }

    if (file.status === FILE_STATUS.done) {
      const preview = await getBase64(file.originFileObj);
      setDocument({
        preview,
        file: file.originFileObj,
        status: FILE_STATUS.done,
        isLoading: false,
      });
    }

    if (file.status === FILE_STATUS.error) {
      setDocument((prevDocument) => ({
        ...prevDocument,
        status: FILE_STATUS.error,
      }));
    }
  };

  /* NOTE: Custom request should be resolved
    after the file status changed to "uploading" */
  const customRequest = ({ onSuccess }: RcCustomRequestOptions) => {
    setCustomRequestOnSuccess(() => onSuccess);
  };

  const beforeUpload = (file: RcFile) => {
    const { name, size } = file;
    return (
      isFileNameValid(name, true) &&
      isFileSizeValid(MAX_FILE_UPLOAD_SIZE, size, name) &&
      isFileFormatValid(name, UPLOAD_ALLOWED_FILE_TYPES)
    );
  };

  useEffect(() => {
    if (document.status === FILE_STATUS.uploading) {
      customRequestOnSuccess?.();
    }
  }, [document]);

  const onSubmit = async () => {
    // @ts-ignore
    await uploadPropertyLogo({
      id: propertySettings.id,
      file: document.file,
    });

    selfApi.closeDialogWithValue(true);
  };

  return (
    <UploadPropertyLogoModal
      document={document}
      uploadDocuments={uploadDocuments}
      beforeUpload={beforeUpload}
      onSubmit={onSubmit}
      isUpdatingProcess={isUpdatingProcess}
      propertyName={propertySettings?.name}
      isConfirming={isConfirming}
      customRequest={customRequest}
    />
  );
};

export default UploadPropertyLogoModalContainer;
