import React from "react";

import pluralize from "pluralize";

import { UnitIntegrationMark } from "manager/components/IntegrationMark";
import { getIntegrationType } from "manager/utils/integration.util";
import Container from "shared/components/Container";
import DropdownMenu from "shared/components/DropdownMenu";
import FlexContainer from "shared/components/FlexContainer";
import { Popover, PopoverContent } from "shared/components/Popover";
import Tooltip from 'shared/components/Tooltip'

import { INTEGRATION_TYPES } from "shared/config/constants";
import { conditionalItem } from "shared/utils/array";

import { UnitHeaderProps } from "../interfaces";

import {
  StyledSkeleton,
  HeaderUnitInfo,
  HeaderUnitDetails,
  UnitName,
  UnitTile,
  MobileDetails,
  UnitNameWrapper,
  ActionLink,
  UnitDetailsWrapper,
  PropertyIntegrationMarkContainer,
} from "./styled";
import UnitStatus from "./UnitStatus";

const UnitHeader = ({
  unit,
  inviteApplicants,
  loading,
  editUnit,
  deleteUnit,
  isMobile,
  isAbleToInviteApplicants,
  hasListing,
  showOnlyUnitsNotAttachedToAnotherActiveDeal,
}: UnitHeaderProps) => {
  const shouldDisableInviteApplicants = (numOfActiveDeals) =>
    showOnlyUnitsNotAttachedToAnotherActiveDeal && numOfActiveDeals > 0;
  const shouldDisableInvite = shouldDisableInviteApplicants(
    unit?.unitListingCurrent?.numberOfActiveDeals
  );
  const integrationTypeEntrata =
    getIntegrationType(unit?.property || unit) === INTEGRATION_TYPES.ENTRATA;
  const Actions = (
    <DropdownMenu
      // @ts-ignore
      onClick={(e) => e.stopPropagation()}
      items={[
        {
          key: "EDIT",
          label: "Edit",
          onClick: editUnit,
        },
        ...conditionalItem(!hasListing, {
          key: "DELETE",
          label: "Delete",
          onClick: deleteUnit,
          disabled: hasListing,
        }),
      ]}
    />
  );

  return (
    <Container noPadding>
      {/* @ts-ignore */}
      <StyledSkeleton loading={loading} paragraph={false} />
      {!loading &&
        (isMobile ? (
          <UnitTile
            alt
            // @ts-ignore
            insetStyle
            header={{
              title: (
                <FlexContainer
                  fullWidth
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <FlexContainer alignItems="baseline">
                    Unit
                    <Tooltip theme='light' title={unit?.fqlVrn}>
                      <UnitName>{unit?.name}</UnitName>
                    </Tooltip>
                    <PropertyIntegrationMarkContainer
                      opacity={integrationTypeEntrata ? 0.75 : 1}
                    >
                      <UnitIntegrationMark
                        property={unit?.property}
                        unit={unit}
                        dark
                      />
                    </PropertyIntegrationMarkContainer>
                  </FlexContainer>
                  {Actions}
                </FlexContainer>
              ),
              withoutBorderBottom: true,
              defaultCollapsed: true,
              collapsibleOnDesktop: true,
              collapsibleOnMobile: true,
            }}
          >
            <UnitStatus status={unit?.status} />
            <MobileDetails>{unit?.property?.name}</MobileDetails>
            <MobileDetails>Unit Type: {unit?.unitType}</MobileDetails>
            <MobileDetails>
              {unit?.bedrooms} {pluralize("bedroom", unit?.bedrooms)},{" "}
              {unit?.bathrooms}{" "}
              {pluralize("bathroom", Number(unit?.bathrooms ?? 0))},{" "}
              {unit?.squareFeet} Square feet
            </MobileDetails>
          </UnitTile>
        ) : (
          <FlexContainer fullWidth>
            <HeaderUnitInfo alignItems="center" flexGrow="1">
              <UnitNameWrapper alignItems="baseline">
                Unit
                <Tooltip theme='light' title={unit?.fqlVrn}>
                  <UnitName>{unit?.name}</UnitName>
                </Tooltip>
                <PropertyIntegrationMarkContainer
                  opacity={integrationTypeEntrata ? 0.75 : 1}
                >
                  <UnitIntegrationMark
                    property={unit?.property}
                    unit={unit}
                    dark
                  />
                </PropertyIntegrationMarkContainer>
              </UnitNameWrapper>
              <UnitStatus status={unit?.status} />
            </HeaderUnitInfo>
            <HeaderUnitDetails
              justifyContent="space-between"
              alignItems="center"
              flexGrow="3"
            >
              <UnitDetailsWrapper
                flexDirection="column"
                justifyContent="space-between"
                fullHeight
              >
                <span>{unit?.property?.name}</span>
                <span>Unit Type: {unit?.unitType}</span>
                <span>
                  {unit?.bedrooms} {pluralize("bedroom", unit?.bedrooms)},{" "}
                  {unit?.bathrooms}{" "}
                  {pluralize("bathroom", Number(unit?.bathrooms ?? 0))},{" "}
                  {unit?.squareFeet} Square feet
                </span>
              </UnitDetailsWrapper>
              <FlexContainer alignItems="center">
                {isAbleToInviteApplicants && (
                  // @ts-ignore
                  <Popover
                    className="popover"
                    withoutOverlay
                    disabled={hasListing && !shouldDisableInvite}
                    content={
                      <PopoverContent>
                        {hasListing && shouldDisableInvite
                          ? "This unit is already attached to an application."
                          : null}
                        {!hasListing
                          ? "Applicants cannot be invited to a unit without a listing"
                          : null}
                      </PopoverContent>
                    }
                  >
                    <div>
                      <ActionLink
                        disabled={
                          !hasListing || (hasListing && shouldDisableInvite)
                        }
                        onClick={() => {
                          inviteApplicants({
                            id: unit?.id,
                            name: unit?.name,
                            propertyName: unit?.property?.name,
                          });
                        }}
                      >
                        Invite Applicants
                      </ActionLink>
                    </div>
                  </Popover>
                )}
                {Actions}
              </FlexContainer>
            </HeaderUnitDetails>
          </FlexContainer>
        ))}
    </Container>
  );
};

export default UnitHeader;
