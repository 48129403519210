import React, { useEffect, useState } from "react";

import { filter } from "lodash";
import { useHistory } from "react-router-dom";

import ROUTES from "admin/config/routes";
import { transformRoute } from "shared/utils/routing";
import { sanitizeStringSearchValue } from "shared/utils/string";

import { DocumentDraft } from "../interfaces";

import DraftsTable from "./DraftsTable";

interface DraftsTableContainerProps {
  isLoading: boolean;
  documents: DocumentDraft[];
  searchParams: { draftName: string };
}

const DraftsTableContainer = ({
  isLoading,
  documents,
  searchParams: { draftName },
}: DraftsTableContainerProps) => {
  const history = useHistory();
  const [filteredDocuments, setFilteredDocuments] = useState<DocumentDraft[]>(
    []
  );

  const openConstructorDocument = (draft) =>
    history.push(transformRoute(ROUTES.draftConstructor, { id: draft.id }));

  const handleFilteredDocuments = (key) => {
    let filteredList: any = documents;

    if (key) {
      filteredList = filter(documents, (document) =>
        sanitizeStringSearchValue(document.name)?.includes(key)
      );
    }

    return filteredList;
  };

  useEffect(() => {
    setFilteredDocuments(documents);
  }, [documents]);

  useEffect(() => {
    setFilteredDocuments(handleFilteredDocuments(draftName.toLowerCase()));
  }, [draftName]);

  return (
    <DraftsTable
      loading={isLoading}
      drafts={filteredDocuments}
      openConstructorDocument={openConstructorDocument}
    />
  );
};

export default DraftsTableContainer;
