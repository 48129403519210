import React from "react";

import { UnderlineLink } from "shared/components/Links";
import PaymentHistory from "shared/components/PaymentHistory";
import Settings from "shared/components/Settings";

import CheckboxList from "../pages/Notifications/CheckboxList/CheckboxList";
import FrequencyTitle from "../pages/Notifications/FrequencyTitle";
import NotificationsHeader from "../pages/Notifications/Header/Header";

const APPLICATION = {
  name: "Jessica Smith Application",
  summary: {
    incomeAssetVerification: {
      totalAnnualIncome: {
        value: 240000,
        edited: true,
      },
      incomeRentRatio: 48,
      totalAssets: {
        value: 15000,
        edited: false,
      },
      notes: "Some random note",
    },
    creditBackgroundCheck: {
      averageCreditScore: 795,
      criminalRecords: false,
      evictions: false,
    },
    otherApplicants: [
      {
        id: 2,
        name: "James Smith",
        email: "james.smith@gmail.com",
        incomeRentRatio: 20,
        averageCreditScore: 800,
        criminalRecords: false,
        evictions: false,
        role: "Guarantor",
        income: {
          status: "Edited",
          value: 3000,
        },
        assets: {
          status: "Verified",
          value: 32322,
        },
      },
      {
        id: 3,
        name: "Sara Smith",
        email: "sara.smith@gmail.com",
        incomeRentRatio: 47,
        averageCreditScore: 790,
        criminalRecords: false,
        evictions: false,
        role: "Applicant",
        income: {
          status: "Unverified",
          value: 3222,
        },
        assets: {
          status: "Unverified",
          value: 32232,
        },
      },
    ],
  },
  renterProfile: {
    type: "Applicant",
    name: "Jessica Smith",
    email: "jessica.smith@gmail.com",
    phoneNumber: "+3485960006",
    dateOfBirth: new Date(1992, 1, 21),
    emergencyContacts: [
      {
        id: 1,
        name: "Anna Bells",
        email: "anna.bells@gmail.com",
        phoneNumber: "+3485960006",
        relationship: "Sister",
      },
    ],
    residentialHistory: [
      {
        id: 1,
        periodStart: new Date(2019, 2, 21),
        address: "717 Victoria Drive, Brooklyn, NY 11212",
        mortgage: 5000,
      },
      {
        id: 2,
        periodStart: new Date(2018, 2, 21),
        periodEnd: new Date(2019, 2, 21),
        address: "9231 Applegate Street, Jackson Heights, NY 11372",
        monthlyRent: 5000,
        landlord: {
          name: "James Sorrowell",
          email: "james.sorrowell@gmail.com",
          phoneNumber: "+3485960006",
        },
      },
    ],
    employmentHistory: [
      {
        id: 1,
        periodStart: new Date(2019, 2, 21),
        company: {
          name: "City New York Corporation",
          address: "1st Square street, 89 New York, USA, AR 21002",
        },
        jobTitle: "Sales Manager",
        manager: {
          name: "David Myee",
          phoneNumber: "+1 768-555-4555",
        },
        annualSalary: 50000,
      },
      {
        id: 2,
        periodStart: new Date(2018, 2, 21),
        periodEnd: new Date(2019, 2, 21),
        company: {
          name: "Real Estate Corporation",
          address: "9231 Applegate Street, Jason Heights, USA, 11372",
        },
        jobTitle: "Sales Manager",
        annualSalary: 50000,
      },
    ],
    references: [
      {
        id: 1,
        name: "Sara Smitwell",
        email: "sara.smitwell@gmail.com",
        relationship: "Sister in Law",
      },
      {
        id: 2,
        name: "Dan Smitwell",
        email: "dan.smitwell@gmail.com",
        relationship: "Brother in Law",
      },
    ],
    additionalInfo: [
      {
        id: 1,
        question: "Do you have pets?",
        answer: "Yes, 2 cats",
      },
      {
        id: 2,
        question: "Do you smoke?",
        answer: "No",
      },
    ],
  },
  incomeAndAssets: {
    income: {
      verifiedPercentage: 100,
      annualIncomeBeforeTaxes: 140000,
      averageMonthlyIncome: 11666,
      employer: "David Edison",
      notes: "This is the random text of note",
    },
    assets: {
      verifiedPercentage: 70,
      totalCashAvailable: 15000,
      numberOfAccounts: 4,
    },
    incomeStreams: [
      {
        id: 1,
        source: "VERO",
        active: true,
        frequency: "Biweekly",
        lastPayment: new Date(2019, 9, 20),
        annualIncomePostTax: 99630,
        maxPayment: 29450,
        averagePayment: 5002,
      },
      {
        id: 2,
        source: "VERO",
        active: false,
        frequency: "Biweekly",
        lastPayment: new Date(2019, 2, 19),
        annualIncomePostTax: 88470,
        maxPayment: 4210,
        averagePayment: 4210,
      },
    ],
    accounts: [
      {
        id: 1,
        name: "Plaid 410 K",
        type: "Brokerage",
        cashAvailable: 0,
        currentBalance: 89450,
      },
      {
        id: 2,
        name: "Plaid Checking",
        type: "Brokerage",
        cashAvailable: 15000,
        currentBalance: 15000,
      },
    ],
  },
  creditAndBackgroundCheck: {
    creditCheck: {
      name: "Jessica Smith",
      alias: "Dee, D. Land",
      score: 795,
      accounts: [
        {
          id: 1,
          name: "FRD MOTOR CR",
          status: "Current",
          terms: "Installment",
          currentBalance: 374,
          scheduledPayments: 374,
          currentAccountRating: "Paid or paying as agreed",
          type: "Automobile",
          dateOpened: new Date(2019, 2, 19),
          worstPaymentStatus: null,
          pastDueBalance: 0,
          lateDays: {
            30: 0,
            60: 0,
            90: 0,
          },
          paymentHistory: [
            {
              year: 2020,
              months: {
                1: PaymentHistory.STATUSES.ON_TIME,
              },
            },
            {
              year: 2019,
              months: {
                1: PaymentHistory.STATUSES.ON_TIME,
                2: PaymentHistory.STATUSES.ON_TIME,
                3: PaymentHistory.STATUSES.LATE_30_DAYS,
                4: PaymentHistory.STATUSES.ON_TIME,
                5: PaymentHistory.STATUSES.ON_TIME,
                6: PaymentHistory.STATUSES.ON_TIME,
                7: PaymentHistory.STATUSES.ON_TIME,
                8: PaymentHistory.STATUSES.ON_TIME,
                9: PaymentHistory.STATUSES.ON_TIME,
                10: PaymentHistory.STATUSES.ON_TIME,
                11: PaymentHistory.STATUSES.ON_TIME,
                12: PaymentHistory.STATUSES.ON_TIME,
              },
            },
            {
              year: 2018,
              months: {
                1: PaymentHistory.STATUSES.LATE_30_DAYS,
                2: PaymentHistory.STATUSES.ON_TIME,
                3: PaymentHistory.STATUSES.LATE_90_DAYS,
                4: PaymentHistory.STATUSES.ON_TIME,
                5: PaymentHistory.STATUSES.ON_TIME,
                6: PaymentHistory.STATUSES.ON_TIME,
                7: PaymentHistory.STATUSES.ON_TIME,
                8: PaymentHistory.STATUSES.LATE_120_DAYS,
                9: PaymentHistory.STATUSES.ON_TIME,
                10: PaymentHistory.STATUSES.ON_TIME,
                11: PaymentHistory.STATUSES.ON_TIME,
                12: PaymentHistory.STATUSES.ON_TIME,
              },
            },
            {
              year: 2017,
              months: {
                1: PaymentHistory.STATUSES.ON_TIME,
                2: PaymentHistory.STATUSES.ON_TIME,
                3: PaymentHistory.STATUSES.ON_TIME,
                4: PaymentHistory.STATUSES.ON_TIME,
                5: PaymentHistory.STATUSES.ON_TIME,
                6: PaymentHistory.STATUSES.ON_TIME,
                7: PaymentHistory.STATUSES.ON_TIME,
                8: PaymentHistory.STATUSES.ON_TIME,
                9: PaymentHistory.STATUSES.ON_TIME,
                10: PaymentHistory.STATUSES.ON_TIME,
                11: PaymentHistory.STATUSES.LATE_30_DAYS,
                12: PaymentHistory.STATUSES.ON_TIME,
              },
            },
          ],
        },
        {
          id: 2,
          name: "AV SPECIAL",
          status: "Current",
          terms: "Installment",
          currentBalance: 374,
          scheduledPayments: 374,
          currentAccountRating: "Paid or paying as agreed",
          type: "Automobile",
          dateOpened: new Date(2019, 2, 19),
          worstPaymentStatus: null,
          pastDueBalance: 0,
          lateDays: {
            30: 0,
            60: 0,
            90: 0,
          },
          paymentHistory: [
            {
              year: 2020,
              months: {
                1: PaymentHistory.STATUSES.ON_TIME,
              },
            },
            {
              year: 2019,
              months: {
                1: PaymentHistory.STATUSES.ON_TIME,
                2: PaymentHistory.STATUSES.ON_TIME,
                3: PaymentHistory.STATUSES.LATE_30_DAYS,
                4: PaymentHistory.STATUSES.ON_TIME,
                5: PaymentHistory.STATUSES.ON_TIME,
                6: PaymentHistory.STATUSES.ON_TIME,
                7: PaymentHistory.STATUSES.ON_TIME,
                8: PaymentHistory.STATUSES.ON_TIME,
                9: PaymentHistory.STATUSES.ON_TIME,
                10: PaymentHistory.STATUSES.ON_TIME,
                11: PaymentHistory.STATUSES.ON_TIME,
                12: PaymentHistory.STATUSES.ON_TIME,
              },
            },
            {
              year: 2018,
              months: {
                1: PaymentHistory.STATUSES.LATE_30_DAYS,
                2: PaymentHistory.STATUSES.ON_TIME,
                3: PaymentHistory.STATUSES.LATE_90_DAYS,
                4: PaymentHistory.STATUSES.ON_TIME,
                5: PaymentHistory.STATUSES.ON_TIME,
                6: PaymentHistory.STATUSES.ON_TIME,
                7: PaymentHistory.STATUSES.ON_TIME,
                8: PaymentHistory.STATUSES.LATE_120_DAYS,
                9: PaymentHistory.STATUSES.ON_TIME,
                10: PaymentHistory.STATUSES.ON_TIME,
                11: PaymentHistory.STATUSES.ON_TIME,
                12: PaymentHistory.STATUSES.ON_TIME,
              },
            },
            {
              year: 2017,
              months: {
                1: PaymentHistory.STATUSES.ON_TIME,
                2: PaymentHistory.STATUSES.ON_TIME,
                3: PaymentHistory.STATUSES.ON_TIME,
                4: PaymentHistory.STATUSES.ON_TIME,
                5: PaymentHistory.STATUSES.ON_TIME,
                6: PaymentHistory.STATUSES.ON_TIME,
                7: PaymentHistory.STATUSES.ON_TIME,
                8: PaymentHistory.STATUSES.ON_TIME,
                9: PaymentHistory.STATUSES.ON_TIME,
                10: PaymentHistory.STATUSES.ON_TIME,
                11: PaymentHistory.STATUSES.LATE_30_DAYS,
                12: PaymentHistory.STATUSES.ON_TIME,
              },
            },
          ],
        },
      ],
      totalBalance: 569,
      limit: 7900,
      percentageAvailable: 86,
      scoreFactors: [
        "Not enough balance decreases on active non-mortgage accounts.",
        "Length of time accounts have been established is too short.",
        "Months since most recent delinquency is too short.",
      ],
      fraudIndicators: [
        "Address submitted is a non-residential address.",
        "Initial fraud alert on file.",
      ],
      addresses: [
        {
          id: 1,
          reportedAddress: "332 Small World Av, Anaheim, CA 92850",
          reportedDate: new Date(2019, 9, 28),
        },
        {
          id: 2,
          reportedAddress: "928 North Gregory Drive, Astoria, NY 11105",
          reportedDate: new Date(2019, 9, 24),
        },
      ],
    },
    criminalRecords: {
      sexualCrime: false,
      felony: true,
      biggestSeverity: null,
      records: [
        {
          id: 1,
          date: new Date(2016, 7, 17),
          state: "Ohio",
          docket: "46575865868",
          outcome: "MULTI-OFFENSE - CONV-7 - DOC",
          judgement: "INFRACTION CLASS B hffkjghkkfkf",
          severity: null,
          felony: true,
          balance: 6000.45,
        },
        {
          id: 2,
          date: new Date(2018, 4, 2),
          state: "Ohio",
          docket: "46575865868",
          outcome: "MULTI-OFFENSE - CONV-7 - DOC",
          judgement: "INFRACTION CLASS B hffkjghkkfkf",
          severity: null,
          felony: true,
          balance: 6000.45,
        },
        {
          id: 3,
          date: new Date(2018, 4, 2),
          state: "Ohio",
          docket: "46575865868",
          outcome: "MULTI-OFFENSE - CONV-7 - DOC",
          judgement: "INFRACTION CLASS B hffkjghkkfkf",
          severity: null,
          felony: true,
          balance: 6000.45,
        },
        {
          id: 4,
          date: new Date(2016, 2, 9),
          state: "Alyasaka",
          docket: "46575865868",
          outcome: "MULTI-OFFENSE - CONV-7 - DOC",
          judgement: "INFRACTION CLASS B hffkjghkkfkf",
          severity: null,
          felony: true,
          balance: 6000.45,
        },
        {
          id: 5,
          date: new Date(2016, 3, 11),
          state: "New York",
          docket: "46575865868",
          outcome: "MULTI-OFFENSE - CONV-7 - DOC",
          judgement: "INFRACTION CLASS B hffkjghkkfkf",
          severity: null,
          felony: true,
          balance: 6000.45,
        },
        {
          id: 6,
          date: new Date(2016, 1, 9),
          state: "New York",
          docket: "46575865868",
          outcome: "MULTI-OFFENSE - CONV-7 - DOC",
          judgement: "INFRACTION CLASS B hffkjghkkfkf",
          severity: null,
          felony: true,
          balance: 6000.45,
        },
      ],
    },
    evictions: [
      {
        id: 1,
        date: new Date(2013, 5, 8),
        state: "Alyasaka",
        docket: "46575865868",
        outcome: "MULTI-OFFENSE - CONV-7 - DOC",
        judgement: "INFRACTION CLASS B hffkjghkkfkf",
        caseType: "Forcible Entry/Detainer",
        balance: 6000.45,
      },
      {
        id: 2,
        date: new Date(2013, 1, 7),
        state: "Alyasaka",
        docket: "46575865868",
        outcome: "MULTI-OFFENSE - CONV-7 - DOC",
        judgement: "INFRACTION CLASS B hffkjghkkfkf",
        caseType: "Forcible Entry/Detainer",
        balance: 6000.45,
      },
    ],
  },
};

const DEAL = {
  unit: {
    name: "Unit 1",
    availableDate: new Date(2020, 9, 7),
    address: "30b, Manhatten Living Complex, 1st Square street",
    portfolio: "New York Properties portfolio",
    rent: 12000,
  },
  status: "Waiting for applicants",
  incomeAssetVerification: {
    totalAnnualIncome: {
      value: 240000,
      edited: true,
    },
    incomeRentRatio: 48,
    totalAssets: {
      value: 15000,
      edited: true,
    },
  },
  creditBackgroundCheck: {
    averageCreditScore: 795,
    criminalRecords: false,
    evictions: null,
  },
  applicants: [
    {
      id: 1,
      submitted: true,
      name: "Jessica Smith",
      email: "jessica.smith@gmail.com",
      incomeRentRatio: 28,
      averageCreditScore: 800,
      criminalRecords: false,
      evictions: false,
      role: "Applicant",
      income: {
        status: "Verified",
        value: 15000,
      },
      assets: {
        status: "Verified",
        value: 3500,
      },
    },
    {
      id: 2,
      submitted: true,
      name: "James Smith",
      email: "james.smith@gmail.com",
      incomeRentRatio: 20,
      averageCreditScore: 800,
      criminalRecords: false,
      evictions: false,
      role: "Guarantor",
      income: {
        status: "Edited",
        value: 32222,
      },
      assets: {
        status: "Verified",
        value: 18000,
      },
    },
    {
      id: 3,
      submitted: true,
      name: "Sara Smith",
      email: "sara.smith@gmail.com",
      incomeRentRatio: 47,
      averageCreditScore: 790,
      criminalRecords: false,
      evictions: false,
      role: "Applicant",
      income: {
        status: "Unverified",
        value: 18333,
      },
      assets: {
        status: "Unverified",
        value: 19000,
      },
    },
    {
      id: 4,
      name: "Tim Smith",
      submitted: false,
    },
    {
      id: 5,
      name: "Sara Smith",
      submitted: false,
    },
  ],
  occupants: [
    {
      id: 1,
      name: "Lily Farrel",
      age: 13,
      type: "Daughter",
    },
  ],
};

const NOTIFICATIONS = [
  {
    key: 1,
    message: (
      <>
        <UnderlineLink to="/applications/:id">Smith Family</UnderlineLink>{" "}
        Application Group has submitted application
      </>
    ),
    action: {
      label: "View Application",
      callback: () => console.log("View application"),
    },
    subtitle: "30a, Manhatten Living Complex",
    time: new Date(2019, 9, 20),
  },
  {
    key: 2,
    message: (
      <>
        Johns Family has signed{" "}
        <UnderlineLink to="/applications/:id">the lease</UnderlineLink>
      </>
    ),
    action: {
      label: "Countersign",
      callback: () => console.log("Countersign"),
    },
    subtitle: "21b, Luxury See Living Complex",
    time: new Date(2019, 9, 19),
  },
  {
    key: 3,
    message: "13c Unit lease expires in 3 days",
    action: {
      label: "Manage Lease",
      callback: () => console.log("Manage lease"),
    },
    subtitle: "21b, Luxury See Living Complex",
    time: new Date(2019, 9, 17),
    viewed: true,
  },
  {
    key: 4,
    message: (
      <>
        <UnderlineLink to="/applications/:id">Smith Family</UnderlineLink>{" "}
        Application Group has submitted application
      </>
    ),
    action: {
      label: "View Application",
      callback: () => console.log("View application"),
    },
    subtitle: "30a, Manhatten Living Complex",
    time: new Date(2019, 9, 10),
  },
  {
    key: 5,
    message: (
      <>
        Johns Family has signed{" "}
        <UnderlineLink to="/applications/:id">the lease</UnderlineLink>
      </>
    ),
    action: {
      label: "Countersign",
      callback: () => console.log("Countersign"),
    },
    subtitle: "21b, Luxury See Living Complex",
    time: new Date(2019, 9, 9),
  },
  {
    key: 6,
    message: (
      <>
        Johns Family has signed{" "}
        <UnderlineLink to="/applications/:id">the lease</UnderlineLink>
      </>
    ),
    action: {
      label: "Countersign",
      callback: () => console.log("Countersign"),
    },
    subtitle: "21b, Luxury See Living Complex",
    time: new Date(2019, 9, 9),
    viewed: true,
  },
  {
    key: 7,
    message: (
      <>
        Johns Family has signed{" "}
        <UnderlineLink to="/applications/:id">the lease</UnderlineLink>
      </>
    ),
    action: {
      label: "Countersign",
      callback: () => console.log("Countersign"),
    },
    subtitle: "21b, Luxury See Living Complex",
    time: new Date(2019, 9, 9),
  },
];

const PROPERTY = {
  name: "Manhatten Living Complex",
  address: "928 North Gregory Drive, Astoria, NY 11105",
  type: "Condo",
  totalUnits: {
    all: 200,
    rented: 145,
  },
  portfolio: {
    id: 1,
    name: "Cheap Apartments",
  },
  units: [
    {
      id: 1,
      name: "30b",
      status: "Not Rented",
      dateAvailable: new Date(2020, 2, 3),
      rent: 4000,
    },
    {
      id: 2,
      name: "31a",
      status: "Rented",
      dateAvailable: new Date(2020, 9, 3),
      rent: 4000,
    },
    {
      id: 3,
      name: "31b",
      status: null,
      dateAvailable: new Date(2020, 2, 3),
      rent: 1000,
    },
    {
      id: 4,
      name: "32a",
      status: null,
      dateAvailable: null,
      rent: null,
    },
    {
      id: 5,
      name: "32b",
      status: null,
      dateAvailable: null,
      rent: null,
    },
    {
      id: 6,
      name: "33a",
      status: "Not Rented",
      dateAvailable: new Date(2021, 3, 9),
      rent: null,
    },
    {
      id: 7,
      name: "33b",
      status: "Rented",
      dateAvailable: new Date(2021, 9, 9),
      rent: 5000,
    },
  ],
  usersAssigned: [
    {
      id: 1,
      name: "Jake Smith",
    },
    {
      id: 2,
      name: "Jon Doe",
    },
  ],
};

const UNIT = {
  id: 1,
  name: "30b",
  property: {
    id: 1,
    name: "Manhatten Living Complex",
    address: "1st Square St, New York, AR 11101",
  },
  assignedEmployee: {
    id: 1,
    name: "Alex Smith",
  },
  bedrooms: 2,
  bathrooms: 2,
  squareFeet: 755,
  listing: {
    dateAvailable: new Date(2020, 3, 8),
    rent: 2000,
    additionalQuestions: [
      {
        id: 1,
        question: "Do you have pets?",
      },
      {
        id: 2,
        question: "Do you smoke?",
      },
      {
        id: 3,
        question: "How long have you lived in New York?",
      },
    ],
  },
  leases: [
    {
      id: 1,
      start: new Date(2019, 3, 9),
      end: new Date(2019, 6, 9),
      applicants: [
        { id: 1, name: "Cody Garbrandt" },
        { id: 2, name: "Jon Doe" },
      ],
      status: "Expired",
      listingRent: 778,
      leaseRent: 578,
    },
    {
      id: 2,
      start: new Date(2020, 1, 9),
      end: new Date(2020, 12, 9),
      applicants: [
        { id: 3, name: "Jessica Smith" },
        { id: 4, name: "John Smith" },
        { id: 5, name: "Aaron Smith" },
      ],
      status: "Signed by all applicants",
      listingRent: 778,
      leaseRent: 578,
      current: true,
    },
  ],
};

const PORTFOLIO = {
  name: "Cheap Apartments",
  properties: [
    {
      id: 1,
      name: "Banhatten Living Complex",
      address: "2nd Square St, 89 New York, AR 21002",
      units: {
        all: 38,
        rented: 20,
      },
    },
    {
      id: 2,
      name: "Banhatten Living Complex",
      address: "2nd Square St, 89 New York, AR 21002",
      units: {
        all: 38,
        rented: 20,
      },
    },
    {
      id: 3,
      name: "Banhatten Living Complex",
      address: "2nd Square St, 89 New York, AR 21002",
      units: {
        all: 38,
        rented: 20,
      },
    },
  ],
};

const APPLICANT = {
  name: "Aaron Smith",
  email: "aaron.smith@gmail.com",
  phoneNumber: "+1 (333) 322-3223",
  dateOfBirth: new Date(1992, 1, 21),
};

// TODO: remove
const USER = {
  name: "David Boyee",
  email: "david.boyee@gmail.com",
  phone: "+395766060606",
  signOut: () => console.log("Signed out"),
};

const NOTIFICATIONS_SETTINGS = {
  name: "Notifications",
  defaultActiveTab: "myNotifications",
  tabs: [
    {
      key: "myNotifications",
      label: "My Notifications",
    },
    {
      key: "applicantNotifications",
      label: "Applicant Notifications",
    },
  ],
  items: [
    {
      key: "myNotifications",
      node: (
        <>
          <Settings
            header={
              <NotificationsHeader subTitle="These are emails that will be sent to employees/vero users" />
            }
            hasComponentDivider={false}
            nodes={[
              {
                key: 1,
                component: (
                  <CheckboxList
                    items={[
                      {
                        id: "applicant-has-been-invited-to-an-existing-deal-by-another-applicant",
                        label:
                          "An applicant has been invited to apply to an existing application",
                        description: "by another applicant",
                        value: true,
                      },
                      {
                        id: "applicant-has-been-invited-to-an-existing-deal-by-an-employee",
                        label:
                          "An applicant has been invited to apply to an existing application",
                        description: "by an employee",
                        value: true,
                      },
                      {
                        id: "one-applicant-has-completed-their-application",
                        label: "One applicant has completed their application",
                        value: true,
                      },
                      {
                        id: "all-applicants-have-completed-their-application",
                        label:
                          "All applicants have completed their application",
                        value: true,
                      },
                      {
                        id: "an-applicant-has-started-the-application-process",
                        label:
                          "An applicant has started the application process",
                        value: true,
                      },
                      {
                        id: "a-deal-has-been-approved",
                        label: "An application has been approved",
                        value: true,
                      },
                      {
                        id: "a-deal-has-been-conditionally-approved",
                        label: "An application has been conditionally approved",
                        value: true,
                      },
                      {
                        id: "a-deal-has-been-declined",
                        label: "An application has been declined",
                        value: true,
                      },
                    ]}
                    onChange={(id, value) => {
                      console.log("checkbox selected", id, value);
                    }}
                    title="Application notifications:"
                  />
                ),
              },
              {
                key: 2,
                component: (
                  <CheckboxList
                    items={[
                      {
                        id: "the-lease-has-been-sent-to-applicants",
                        label: "The lease has been sent to applicant(s)",
                        value: true,
                      },
                      {
                        id: "an-applicant-has-signed-the-lease",
                        label: "An applicant has signed the lease",
                        value: true,
                      },
                      {
                        id: "all-applicants-have-signed-the-lease",
                        label: "All applicants have signed the lease",
                        value: true,
                      },
                      {
                        id: "the-lease-has-been-countersigned",
                        label: "The lease has been countersigned",
                        value: true,
                      },
                    ]}
                    onChange={(id, value) => {
                      console.log("checkbox selected", id, value);
                    }}
                    title="Leasing notifications:"
                  />
                ),
              },
              {
                key: 3,
                component: (
                  <CheckboxList
                    items={[
                      {
                        id: "a-new-property-has-been-created",
                        label: "A new property has been created",
                        value: true,
                      },
                    ]}
                    onChange={(id, value) => {
                      console.log("checkbox selected", id, value);
                    }}
                    title="Properties"
                  />
                ),
              },
            ]}
          />
          <Settings
            header={<NotificationsHeader title="Email reports" />}
            nodes={[
              {
                key: 4,
                component: (
                  <CheckboxList
                    isBoldTitle={false}
                    items={[
                      {
                        id: "daily",
                        label: "Daily",
                        value: true,
                      },
                      {
                        id: "weekly",
                        label: "Weekly",
                        value: true,
                      },
                      {
                        id: "monthly",
                        label: "Monthly",
                        value: true,
                      },
                    ]}
                    onChange={(id, value) => {
                      console.log("checkbox selected", id, value);
                    }}
                    title={
                      <FrequencyTitle title="Select frequency of receiving the reports" />
                    }
                  />
                ),
              },
            ]}
          />
        </>
      ),
    },
    {
      key: "applicantNotifications",
      node: (
        <Settings
          header={
            <NotificationsHeader subTitle="These are emails that will be sent to applicants" />
          }
          hasComponentDivider={false}
          nodes={[
            {
              key: 1,
              component: (
                <CheckboxList
                  items={[
                    {
                      id: "an-applicant-has-been-invited-to-apply",
                      label: "An applicant has been invited to apply",
                      value: true,
                      disabled: true,
                    },
                    {
                      id: "an-applicant-needs-to-add-additional-information",
                      label:
                        "An applicant needs to add additional information to their application",
                      value: true,
                      disabled: true,
                    },
                    {
                      id: "a-deal-has-been-approved",
                      label: "An application has been approved",
                      description:
                        "notification goes to all parties on the application",
                      value: true,
                    },
                  ]}
                  onChange={(id, value) => {
                    console.log("checkbox selected", id, value);
                  }}
                  title="Application notifications:"
                />
              ),
            },
            {
              key: 2,
              component: (
                <CheckboxList
                  items={[
                    {
                      id: "an-applicant-is-ready-to-be-signed",
                      label: "A lease is ready to be signed",
                      value: true,
                      disabled: true,
                    },
                    {
                      id: "the-lease-has-been-executed-by-a-single-applicant",
                      label:
                        "The lease has been executed by a single applicant",
                      value: true,
                    },
                    {
                      id: "the-lease-has-been-executed-by-all-applicants",
                      label: "The lease has been executed by all applicants",
                      value: true,
                    },
                    {
                      id: "the-lease-has-been-countersigned",
                      label: "The lease has been countersigned.",
                      value: true,
                    },
                  ]}
                  onChange={(id, value) => {
                    console.log("checkbox selected", id, value);
                  }}
                  title="Leasing notifications:"
                />
              ),
            },
          ]}
        />
      ),
    },
  ],
};

export {
  APPLICATION,
  DEAL,
  NOTIFICATIONS_SETTINGS,
  USER,
  PROPERTY,
  UNIT,
  PORTFOLIO,
  APPLICANT,
  NOTIFICATIONS,
};
