import React from "react";

import { Select } from "shared/components/Form";

import { Zoom } from "./styled";

const ZOOM_OPTIONS = {
  "25%": "25",
  "50%": "50",
  "75%": "75",
  "100%": "100",
  "125%": "125",
  "150%": "150",
  "175%": "175",
  "200%": "200",
};

const options = Object.keys(ZOOM_OPTIONS).map((label) => (
  <Select.Option key={ZOOM_OPTIONS[label]}>{label}</Select.Option>
));

type SelectProps = Record<string, any>; // TODO: update to correct SelectProps when done
export type ZoomSelectProps = SelectProps & {
  onChange: (zoom: number) => void;
  value?: string;
};

const _ZoomSelect: React.FC<ZoomSelectProps> = ({
  value,
  onChange,
  ...other
}: ZoomSelectProps) => (
  // @ts-ignore
  <Zoom
    value={value}
    defaultValue={value}
    onChange={onChange}
    placeholder="Zoom"
    {...other}
  >
    {options}
  </Zoom>
);

export const ZoomSelect = Object.assign(_ZoomSelect, {
  OPTIONS: ZOOM_OPTIONS,
  PERCENT_COEFFICIENT: 0.01,
  STEP: 25,
});
