import React, { useState } from "react";

import { ActionLinkProps } from "../interfaces";

import {
  StyledActionLink,
  StyledStandardActionLink,
  IconContainer,
  ACTION_LINK_THEMES,
} from "./styled";

const ActionLink = ({
  children: baseChildren = undefined,
  to = undefined,
  plus = false,
  onClick = undefined,
  Icon = undefined,
  right = undefined,
  dashed = undefined,
  standardCase = undefined,
  standardWeight = undefined,
  ...otherProps
}: ActionLinkProps) => {
  const [hovered, setHovered] = useState(false);
  const props = {
    right,
    dashed,
    standardCase,
    standardWeight,
    ...otherProps,
    onMouseEnter: () => setHovered(true),
    onMouseLeave: () => setHovered(false),
  };
  const children = (
    <>
      {Icon && (
        <IconContainer data-testid="icon" right={right}>
          <Icon hovered={String(hovered)} />
        </IconContainer>
      )}
      {plus && "+ "}
      {baseChildren}
    </>
  );
  let link;

  if (to) {
    link = (
      <StyledActionLink disabled={undefined} {...props} to={to}>
        {children}
      </StyledActionLink>
    );
  } else if (onClick) {
    link = (
      <StyledStandardActionLink
        disabled={undefined}
        {...props}
        onClick={onClick}
      >
        {children}
      </StyledStandardActionLink>
    );
  } else {
    link = (
      <StyledStandardActionLink disabled={undefined} {...props}>
        {children}
      </StyledStandardActionLink>
    );
  }

  return link;
};

export default ActionLink;

export { ACTION_LINK_THEMES };
