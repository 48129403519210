import styled from "@emotion/styled";

import BaseWell from "shared/components/Well";
import { COLORS } from "shared/config/constants";

const Well = styled(BaseWell)`
  background-color: ${COLORS.white};
  display: flex;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  border-color: ${COLORS.borders};
  width: 100%;

  .extra-message-icon {
    margin-right: 16px;
    min-height: 20px;
    min-width: 20px;
  }
`;

export { Well };
