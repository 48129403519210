import { closeActiveModal, openModal } from "shared/state/Modal/actions";
import { activeModalId } from "shared/state/Modal/selectors";

import { MODAL_EVENTS, ModalManager } from "./ModalManager";

/**
 * Redux middleware for closing ModalManager modals when a Redux modal is opened.
 */
const modalManagerReduxSyncMiddleware = () => (next) => (action) => {
  if (action === openModal) {
    ModalManager.destroyAll();
  }
  return next(action);
};

/**
 * Listener to ModalManager events that closes the Redux modals when a ModalManager modal is opened.
 */
const initModalManagerSyncListener = (reduxStore) => {
  return ModalManager.subscribe((type) => {
    if (
      type === MODAL_EVENTS.OPEN_MODAL &&
      !!activeModalId(reduxStore.getState())
    ) {
      reduxStore.dispatch(closeActiveModal());
    }
  });
};

/**
 * This module is used to synchronize Redux and ModalManager.
 * When a modal is opened in one mode, all modals from the other mode should be closed.
 */
export { modalManagerReduxSyncMiddleware, initModalManagerSyncListener };
