import React from "react";

import styled from "@emotion/styled";

import BaseTile from "shared/components/Tile";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

interface TileProps {
  disabled?: boolean;
}
interface TileInnerProps {
  children: React.ReactNode | Array<any>[];
}

const Tile = styled(BaseTile)<TileProps>`
  cursor: ${(props) => !props.disabled && "pointer"};
  width: 480px;
  height: 240px;
  margin-right: 16px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(120, 115, 149, 0.25);

  ${MEDIA_QUERIES.w.ltSm} {
    width: calc(100% - 40px);
    margin-right: 0;
    height: 200px;
  }
  > div {
    height: 100%;
  }
`;

const InnerContainer = styled.div`
  position: relative;
  height: 100%;
`;

const TileInner = ({ children }: TileInnerProps) => (
  <BaseTile.Inner>
    <InnerContainer>{children}</InnerContainer>
  </BaseTile.Inner>
);

const TileDescription = styled.div<TileProps>`
  align-self: end;
  color: ${(props) => props.disabled && COLORS.secondary};
`;

const TileTitle = styled.h2<TileProps>`
  font-size: 22px;
  font-weight: 500;
  color: ${(props) => props.disabled && COLORS.disabledText};
`;

const TileSubtitle = styled.h3<TileProps>`
  font-size: 17px;
  font-weight: 500;
  display: flex;
  align-items: center;
  color: ${(props) => props.disabled && COLORS.disabledText};
  .subtitle-icon {
    margin-right: 10px;
  }
`;

const TileContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  height: 100%;
`;

export {
  Tile,
  TileInner,
  TileTitle,
  TileSubtitle,
  TileDescription,
  TileContent,
};
