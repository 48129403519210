import React from "react";

import { Typography } from "antd";

import { MODALS } from "renter/config/constants";
import { Modal } from "shared/components/Modal";

import { EditApplicationProps } from "./interfaces";
import { Apartment } from "./styled";

const { Text } = Typography;

const EditApplicationModal = ({
  apartment,
  loading,
  submit,
}: EditApplicationProps) => (
  <Modal
    title="Edit existing application"
    subtitle={
      apartment && (
        <>
          Home: <Apartment>{apartment}</Apartment>
        </>
      )
    }
    id={MODALS.editApplication}
    width="auto"
    style={{ maxWidth: 516 }}
    submit={submit}
    submitting={loading}
    submitButtonLabel="Yes"
    cancelLinkLabel="No"
    closeOnSubmit={false}
  >
    <Modal.Body>
      <Text data-testid="message">
        <p>
          Editing your application will allow you to make changes to all items
          that you submitted. This action will pause the approval process and
          the leasing manager will be alerted that an edit occurred.
        </p>
        Would you like to continue?
      </Text>
    </Modal.Body>
  </Modal>
);

export default EditApplicationModal;
