import axios from "axios";

import ROUTES from "manager/config/apiRoutes";
import { CompanyDashboardData } from "manager/interfaces/api/dashboard";
import { API_URL } from "shared/lib/api/setupApi";

const api = {
  getCompanyDashboardData: (period: number) =>
    axios
      .get<CompanyDashboardData>(`${API_URL}${ROUTES.companyDashboard}`, {
        params: { period },
      })
      .then(({ data }) => data),
};

export default api;
