import { useMutation, useQuery, queryCache } from "react-query";

import api from "admin/lib/api";
import { NOTIFICATIONS } from "shared/config/constants";
import { openNotification } from "shared/utils/ui";

import { DOCUMENT_TEMPLATE_QUERY_KEYS } from "./documentTemplateQueries";

export const TAGS_QUERY_KEYS = Object.freeze({
  getTags: () => ["getTags"],
});

export const useGetAllTags = () => {
  const { data: tags, isLoading: isTagsLoading } = useQuery(
    TAGS_QUERY_KEYS.getTags(),
    () => api.getTags().then(({ data }) => data),
    {
      onError: () =>
        openNotification("Failed to load tags", NOTIFICATIONS.error),
    }
  );
  return {
    tags,
    isTagsLoading,
  };
};

export const useCreateTag = () => {
  const [mutate, response] = useMutation(api.createTag, {
    onSuccess: () => {
      queryCache.invalidateQueries(TAGS_QUERY_KEYS.getTags());
      queryCache.invalidateQueries(
        DOCUMENT_TEMPLATE_QUERY_KEYS.getDocumentTemplates()
      );
    },
    onError: () =>
      openNotification("Failed to create a tag", NOTIFICATIONS.error),
  });

  return {
    createTag: mutate,
    response,
  };
};

export const useAddTagToTemplate = () => {
  const [mutate, response] = useMutation(api.addTagToTemplate, {
    onSuccess: () =>
      queryCache.invalidateQueries(
        DOCUMENT_TEMPLATE_QUERY_KEYS.getDocumentTemplates()
      ),
    onError: () =>
      openNotification(
        "Failed to add a tag to the document",
        NOTIFICATIONS.error
      ),
  });

  return {
    addTagToTemplate: mutate,
    response,
  };
};

export const useDeleteTagFromTemplate = () => {
  const [mutate, response] = useMutation(api.deleteTagFromTemplate, {
    onSuccess: () =>
      queryCache.invalidateQueries(
        DOCUMENT_TEMPLATE_QUERY_KEYS.getDocumentTemplates()
      ),
    onError: () =>
      openNotification(
        "Failed to delete a tag from the document",
        NOTIFICATIONS.error
      ),
  });

  return {
    deleteTagFromTemplate: mutate,
    response,
  };
};
