import { Col, Row } from "antd";
import {
  applicationSummaryPropType,
  incomeAssetsAccountsPropType,
  incomeAssetsSummaryPropType,
  skippedIncomeAssetsPropType,
} from "manager/utils/propTypes";
import PropTypes from "prop-types";
import ApplicationDataTag from "shared/components/ApplicationDataTag";
import Spacer from "shared/components/Spacer";
import Tile from "shared/components/Tile";
import { MediumTitle } from "shared/components/Typography";
import { printDollarsFromCents } from "shared/utils/dollar-print";

export const Balances = ({
  accounts,
  assets,
  summary,
  applicationSummary,
  skippedIncomeAssets,
  singleRowData,
}) => {
  return (
    <Tile noBorder equalHeight={singleRowData}>
      <Tile.Inner data-testid="assets-summary">
        <h2>
          Balances{" "}
          <ApplicationDataTag
            verificationStatus={summary?.assetsVerificationStatus}
            verificationSkipped={applicationSummary?.skippedAsset}
            skippedLabel={skippedIncomeAssets.label}
            skippedMessage={skippedIncomeAssets.message}
          />
        </h2>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Spacer hideGteLg />
            <Tile.Label>Total Cash Available</Tile.Label>
            <MediumTitle strong data-testid="total-cash-available">
              {printDollarsFromCents(assets)}
            </MediumTitle>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Spacer hideGteLg />
            <Tile.Label>Number of Accounts</Tile.Label>
            <MediumTitle strong data-testid="accounts-count">
              {accounts?.length == null ? "N/A" : accounts?.length}
            </MediumTitle>
          </Col>
        </Row>
      </Tile.Inner>
    </Tile>
  );
};

Balances.propTypes = {
  accounts: incomeAssetsAccountsPropType,
  assets: PropTypes.number.isRequired,
  summary: incomeAssetsSummaryPropType.isRequired,
  applicationSummary: applicationSummaryPropType.isRequired,
  skippedIncomeAssets: skippedIncomeAssetsPropType.isRequired,
  singleRowData: PropTypes.bool.isRequired,
};

Balances.defaultProps = {
  accounts: [],
};
