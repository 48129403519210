import { useMutation, useQuery } from "react-query";

import api from "manager/lib/api";
import { NOTIFICATIONS } from "shared/config/constants";
import { openNotification } from "shared/utils/ui";

export const SCREENING_QUERY_KEYS = Object.freeze({
  getCompanyScreeningCriteriaDocument: (companyId) => [
    "getCompanyScreeningCriteriaDocument",
    Number(companyId),
  ],

  getPropertyScreeningCriteriaDocument: (propertyId) => [
    "getPropertyScreeningCriteriaDocument",
    Number(propertyId),
  ],
});

export const useUploadCompanyScreeningCriteriaDocument = () => {
  const [
    mutate,
    { data: document, error: uploadingError, isLoading: isUploadingDocument },
  ] = useMutation(api.uploadCompanyScreeningCriteriaDocument, {
    onSuccess: () => {
      openNotification(
        "Screening Criteria has been uploaded correctly.",
        NOTIFICATIONS.info
      );
    },
    onError: (error) => {
      const message =
        error.errors?.upload ||
        "Failed to upload applicant screening criteria document.";
      openNotification(message, NOTIFICATIONS.error);
    },
  });

  return {
    uploadCompanyScreeningCriteriaDocument: mutate,
    document,
    uploadingError,
    isUploadingDocument,
  };
};

export const useUploadPropertyScreeningCriteriaDocument = () => {
  const [
    mutate,
    { data: document, error: uploadingError, isLoading: isUploadingDocument },
  ] = useMutation(api.uploadPropertyScreeningCriteriaDocument, {
    onSuccess: () => {
      openNotification(
        "Screening Criteria has been uploaded correctly.",
        NOTIFICATIONS.info
      );
    },
    onError: (error) => {
      const message =
        error.errors?.upload ||
        "Failed to upload applicant screening criteria document.";
      openNotification(message, NOTIFICATIONS.error);
    },
  });

  return {
    uploadPropertyScreeningCriteriaDocument: mutate,
    document,
    uploadingError,
    isUploadingDocument,
  };
};

export const useDeleteCompanyScreeningCriteriaDocument = () => {
  const [mutate, { error: deletionError, isLoading: isDeletingDocument }] =
    useMutation(api.deleteCompanyScreeningCriteriaDocument, {
      onError: () => {
        const message =
          "Failed to delete applicant screening criteria document.";
        openNotification(message, NOTIFICATIONS.error);
      },
    });

  return {
    deleteCompanyScreeningCriteriaDocument: mutate,
    deletionError,
    isDeletingDocument,
  };
};

export const useDeletePropertyScreeningCriteriaDocument = () => {
  const [mutate, { error: deletionError, isLoading: isDeletingDocument }] =
    useMutation(api.deletePropertyScreeningCriteriaDocument, {
      onError: () => {
        const message =
          "Failed to delete applicant screening criteria document.";
        openNotification(message, NOTIFICATIONS.error);
      },
    });

  return {
    deletePropertyScreeningCriteriaDocument: mutate,
    deletionError,
    isDeletingDocument,
  };
};

export const useGetCompanyScreeningCriteriaDocument = ({ companyId }) => {
  const {
    data: document,
    isLoading,
    refetch: refetchCompanyScreeningDocument,
    isFetching,
  } = useQuery(
    SCREENING_QUERY_KEYS.getCompanyScreeningCriteriaDocument(companyId),
    () => api.getCompanyScreeningCriteriaDocument(companyId),
    {
      enabled: Boolean(companyId),
    }
  );

  return {
    document,
    isDocumentLoading: isLoading || isFetching,
    refetchCompanyScreeningDocument,
  };
};

export const useGetPropertyScreeningCriteriaDocument = ({ propertyId }) => {
  const {
    data: document,
    isLoading,
    refetch: refetchPropertyScreeningDocument,
    isFetching,
  } = useQuery(
    SCREENING_QUERY_KEYS.getPropertyScreeningCriteriaDocument(propertyId),
    () => api.getPropertyScreeningCriteriaDocument(propertyId),
    {
      enabled: Boolean(propertyId),
    }
  );

  return {
    document,
    isDocumentLoading: isLoading || isFetching,
    refetchPropertyScreeningDocument,
  };
};
