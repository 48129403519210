import React from "react";

import { Typography } from "antd";

import {
  CollapsibleCheckboxList,
  SearchableList,
} from "shared/components/Form";
import Icon from "shared/components/Icon";
import { Modal, SIZES } from "shared/components/Modal";
import SelectedItemList from "shared/components/SelectedItemList";

import { AssignPropertiesToPortfolioModalProps } from "./interfaces";
import {
  InfoIcon,
  Title,
  WarningContainer,
  WarningText,
  WarningIcon,
} from "./styled";

const { Text } = Typography;

const AssignPropertiesToPortfolioModal = ({
  properties,
  loading,
  submitting,
  assignPropertiesToPortfolio,
  checkedItemsOnChange,
  enableSerchAndFilters,
  portfolioName,
  selectedProperties = [],
  actionSearch,
  showWarning,
  hasInputValue,
  isMobile,
}: AssignPropertiesToPortfolioModalProps) => (
  <Modal
    title="Assign Properties"
    subtitle={
      <span data-testid="subtitle">
        Portfolio: <strong>{portfolioName}</strong>
      </span>
    }
    submit={assignPropertiesToPortfolio}
    submitButtonLabel="Assign"
    submitButtonSize={SIZES.lg}
    submitButtonDisabled={selectedProperties.length === 0}
    width="auto"
    style={{ maxWidth: 946 }}
    submitting={submitting}
    closeOnSubmit
    fullScreen={isMobile}
    footer={
      <SelectedItemList
        label="property"
        items={selectedProperties.map((property) => ({
          key: property.id,
          label: property.name,
        }))}
      />
    }
  >
    <Modal.Body noMobilePaddingX noPaddingTop>
      <Title>Select which properties you want to assign</Title>
      <SearchableList
        loading={loading}
        search={{
          fieldName: "search",
          label: "Search name or address...",
          action: actionSearch,
          width: "370px",
          debounce: true,
          // @ts-ignore
          disabled: !enableSerchAndFilters,
        }}
        List={CollapsibleCheckboxList}
        listContainerProps={{
          checkedItemsOnChange,
        }}
        listProps={{
          col1Width: "350px",
          items: properties?.map((property) => ({
            key: property.id,
            label: property.name,
            description: <Text type="secondary">No portfolio</Text>,
            popover: !property.isDealSetupFromPortfolio && {
              content: "Custom settings are applied to this property",
            },
          })),
        }}
        placeholder={{
          label: "Properties",
          Icon: Icon.NoPropertiesIcon,
        }}
        hasInputValue={hasInputValue}
      />
      {showWarning && (
        <WarningContainer>
          <div>
            <WarningIcon />
          </div>
          <WarningText>
            Properties with this icon <InfoIcon /> have custom application and
            screening settings. These properties won’t inherit portfolio
            settings. If you’d like to edit this, navigate to the property and
            choose “reset to portfolio settings”.
          </WarningText>
        </WarningContainer>
      )}
    </Modal.Body>
  </Modal>
);

export default AssignPropertiesToPortfolioModal;
