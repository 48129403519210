import { UNIT_STATUSES } from "manager/config/constants";
import {
  INTEGRATION_TYPES,
  YARDI_SECURITY_DEPOSIT_PREFERENCES,
} from "shared/config/constants";

const isListingValid = (unitListing) =>
  !!(unitListing?.isCurrent && !unitListing?.isClosed);

const hasListing = (unit) => isListingValid(unit?.unitListingCurrent);

export { isListingValid, hasListing };

export const checkSecurityDepositControlledByIntegration = (
  currentIntegrationType,
  company
) => {
  const depositPreference =
    company?.yardiConfigurations?.securityDepositPreference;
  return (
    INTEGRATION_TYPES.YARDI === currentIntegrationType &&
    (!depositPreference ||
      depositPreference !== YARDI_SECURITY_DEPOSIT_PREFERENCES.VERO)
  );
};

export const shouldSecurityDepositUseRentValue = (
  currentIntegrationType,
  company
) => {
  const depositPreference =
    company?.yardiConfigurations?.securityDepositPreference;
  return (
    INTEGRATION_TYPES.YARDI === currentIntegrationType &&
    depositPreference === YARDI_SECURITY_DEPOSIT_PREFERENCES.RENT
  );
};

export const isMonthlyRentDisabled = (integrationType, company) =>
  INTEGRATION_TYPES.YARDI === integrationType &&
  !company?.yardiConfigurations?.rentEditableDuringLeasing;

export const checkIsAbleToInviteApplicant = (unit) => {
  return [UNIT_STATUSES.AL.key, UNIT_STATUSES.PL.key].includes(unit?.status);
};
