import React from "react";

import { Checkbox, Divider } from "antd";

import DropdownMenu from "shared/components/DropdownMenu";
import ActionLink from "shared/components/Links/ActionLink";
import UnderlineLink from "shared/components/Links/UnderlineLink";
import { HoverTracker } from "shared/icons/icons.utils";

import { FooterBar, Item } from "./styled";

export interface MobileActionBarProps {
  dropdownItems: any[];
  primaryAction: {
    onClick: () => void;
    label: string;
  };
  secondaryAction: {
    onClick: () => void;
    label: string;
    icon?: any;
  };
  selectedRowsLength: number;
  allRowsLength: number;
  checkboxOnChange: (() => void) | ((event: any) => void);
  withBorder?: boolean;
}

const MobileActionBar = ({
  dropdownItems,
  primaryAction,
  selectedRowsLength,
  allRowsLength,
  checkboxOnChange,
  secondaryAction,
}: MobileActionBarProps) => (
  <FooterBar data-testid="mobile-action-bar">
    <Item>
      <Checkbox
        indeterminate={
          selectedRowsLength > 0 && selectedRowsLength < allRowsLength
        }
        checked={selectedRowsLength === allRowsLength}
        onChange={checkboxOnChange}
      />
      <Divider type="vertical" />
    </Item>
    <Item>
      <ActionLink onClick={primaryAction.onClick} data-testid="primary-action">
        {primaryAction.label}
      </ActionLink>
    </Item>
    <Item>
      <Divider type="vertical" />
      {selectedRowsLength > 1 ? (
        <>
          {secondaryAction.icon !== undefined ? (
            <HoverTracker
              Icon={secondaryAction.icon}
              onClick={secondaryAction.onClick}
              style={{ cursor: "pointer" }}
            />
          ) : (
            <UnderlineLink
              onClick={secondaryAction.onClick}
              data-testid="secondary-action"
            >
              {secondaryAction.label}
            </UnderlineLink>
          )}
        </>
      ) : (
        <DropdownMenu
          data-testid="dropdown-menu"
          placement="topRight"
          items={dropdownItems}
        />
      )}
    </Item>
  </FooterBar>
);

export default MobileActionBar;
