import React from "react";

import { Col } from "antd";
import PropTypes from "prop-types";

import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import { UnderlineLink } from "shared/components/Links";
import Spacer from "shared/components/Spacer";
import Tile from "shared/components/Tile";
import { RegularText } from "shared/components/Typography";
import { useEmergencyContactTypes } from "shared/hooks/api";
import SSNViewer from "shared/pages/ApplicationSummary/components/SSNViewer";
import { isManagerApp } from "shared/utils/auth";
import { formatDate, formatPhone } from "shared/utils/ui";

import { PersonInfoDetails, PersonIcon, PersonName } from "./styled";

const Person = ({
  applicationId,
  name,
  phoneNumber,
  email,
  dateOfBirth,
  relationshipType,
  relationship,
  ssn,
  canShowSSN,
}) => {
  const { emergencyContactTypes } = useEmergencyContactTypes();

  const relationshipTypeValue = emergencyContactTypes?.find(
    (type) => type.id === relationshipType
  )?.value;

  return (
    <FlexContainer>
      <PersonIcon>
        <Icon.PersonIcon />
      </PersonIcon>
      <PersonInfoDetails>
        <Col md={{ span: 14 }} lg={{ span: 12 }}>
          <Tile.Label>Name</Tile.Label>
          <PersonName>{name}</PersonName>
          {phoneNumber && (
            <>
              <Spacer />
              <Tile.Label>Phone Number</Tile.Label>
              <UnderlineLink href={`tel:${phoneNumber}`}>
                {formatPhone(phoneNumber)}
              </UnderlineLink>
            </>
          )}
          {isManagerApp() && canShowSSN && (
            <>
              <Spacer />
              <Tile.Label>Social Security Number / ITIN</Tile.Label>
              {ssn ? (
                <SSNViewer applicationId={applicationId} maskedSSN={ssn} />
              ) : (
                "N/A"
              )}
            </>
          )}
        </Col>
        <Col md={{ span: 10 }} lg={{ span: 12 }}>
          {email && (
            <div className="text-ellipsis">
              <Spacer hideGteMd />
              <Tile.Label>Email</Tile.Label>
              <UnderlineLink href={`mailto:${email}`}>{email}</UnderlineLink>
            </div>
          )}
          {dateOfBirth && (
            <>
              {email && <Spacer />}
              <Tile.Label>Date of Birth</Tile.Label>
              {formatDate(dateOfBirth)}
            </>
          )}
          {relationshipTypeValue && (
            <>
              {dateOfBirth && <Spacer />}
              <Tile.Label>Relationship</Tile.Label>
              <FlexContainer>
                {relationshipTypeValue}
                {relationship && (
                  <>
                    <div data-testid="divider" className="divider">
                      <Tile.Divider />
                    </div>
                    <div>
                      <RegularText light>{relationship}</RegularText>
                    </div>
                  </>
                )}
              </FlexContainer>
            </>
          )}
        </Col>
      </PersonInfoDetails>
    </FlexContainer>
  );
};

Person.propTypes = {
  applicationId: PropTypes.number,
  name: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string,
  email: PropTypes.string,
  dateOfBirth: PropTypes.string,
  relationshipType: PropTypes.string,
  relationship: PropTypes.string,
  ssn: PropTypes.string,
  canShowSSN: PropTypes.bool,
};

Person.defaultProps = {
  applicationId: undefined,
  email: undefined,
  dateOfBirth: undefined,
  relationshipType: undefined,
  relationship: undefined,
  phoneNumber: undefined,
  ssn: undefined,
  canShowSSN: false,
};

export default Person;
