import React from "react";

import PropTypes from "prop-types";

import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import { SmallText } from "shared/components/Typography";
import { formatDate } from "shared/utils/ui";

import { RegularText } from "./styled";

const DealStatusColumn = ({
  isArchived,
  lastArchivedAt,
  dealUiStatus,
  status,
  actionLink,
}) => (
  <div data-testid="deal-status">
    {isArchived ? (
      <FlexContainer flexDirection="column">
        <RegularText className="archived" data-testid="deal-status-archived">
          <Icon.ArchivedApplication />
          Archived on {formatDate(lastArchivedAt)}
        </RegularText>
        <SmallText light>Last status: {dealUiStatus}</SmallText>
      </FlexContainer>
    ) : (
      <>
        {status}
        {actionLink}
      </>
    )}
  </div>
);

DealStatusColumn.propTypes = {
  isArchived: PropTypes.bool,
  lastArchivedAt: PropTypes.string,
  dealUiStatus: PropTypes.string,
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  actionLink: PropTypes.node,
};

DealStatusColumn.defaultProps = {
  isArchived: false,
  lastArchivedAt: undefined,
  dealUiStatus: undefined,
  status: undefined,
  actionLink: undefined,
};

export default DealStatusColumn;
