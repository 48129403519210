import React from 'react'
import _ from 'lodash'
import TaskStepContainer from './TaskStepContainer'
import { getTimings, resolveTaskStatus } from './utils'

const TaskSteps = ({ applicantWorkflow }) => {
  const completeTasks = _.map(applicantWorkflow?.tasks?.complete, task => ({
    slug: task,
    status: 'complete',
    timings: getTimings(applicantWorkflow, task)
  }))

  const nextTask = !_.isEmpty(applicantWorkflow?.tasks?.next) && {
    slug: _.first(applicantWorkflow?.tasks?.next),
    status: 'next',
    timings: getTimings(applicantWorkflow, _.first(applicantWorkflow?.tasks?.next))
  }

  const waitTasks = _.chain(applicantWorkflow?.tasks?.next).tail().map(task => ({
    slug: task,
    status: 'possible',
    timings: getTimings(applicantWorkflow, task)
  })).value()

  const possibleTasks = _.map(applicantWorkflow?.tasks?.possible, task => ({
    slug: task,
    status: 'possible',
    timings: getTimings(applicantWorkflow, task)
  }))

  const failedTasks = applicantWorkflow?.tasks?.failure && {
    slug: !nextTask ? 'no-task-defined' : 'failed',
    status: 'failed',
    description: applicantWorkflow?.tasks?.failure?.explanation?.output?.title || applicantWorkflow?.tasks?.failure?.formula
  }

  const tasks = _.chain([completeTasks, nextTask, waitTasks, failedTasks, !applicantWorkflow?.done && possibleTasks]).flatten().compact().map(task => _.set(task, 'status', resolveTaskStatus(task))).value()

  return (
    <div>
      {_.map(tasks, (task) => <TaskStepContainer key={_.get(task, 'slug')} task={task} />)}
    </div>
  )
}

export default TaskSteps
