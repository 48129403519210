import * as Yup from "yup";

import { VALIDATION_MESSAGES } from "shared/config/constants";
import {
  validEmail,
  validFirstName,
  validLastName,
} from "shared/forms/validation/fieldValidators";

export const ValidationSchema = {
  applicants: Yup.array()
    .of(
      Yup.object().shape({
        email: validEmail.required(VALIDATION_MESSAGES.fieldRequired),
        firstName: validFirstName.required(VALIDATION_MESSAGES.fieldRequired),
        lastName: validLastName.required(VALIDATION_MESSAGES.fieldRequired),
      })
    )
    // @ts-ignore
    .uniqueBy(
      ({ email }) => email?.toLowerCase(),
      "Each invite must be sent to a unique email. Please review and edit the email addresses."
    )
    .required("At least one invite is required"),
};

export const initialValues = {
  applicants: [{ email: "", firstName: "", lastName: "" }],
};
