import styled from "@emotion/styled";

import Icon from "shared/components/Icon";
import { COLORS } from "shared/config/constants";

interface StyleProps {
  violet?: boolean;
  withMargin?: boolean;
}

export const EmptyIcon = styled("div")<StyleProps>`
  width: 4px;
  height: 4px;
  background-color: ${(props) =>
    props.violet ? COLORS.violetTheme.emptyIcon : COLORS.bulletList.gray};
  margin-left: ${(props) => (props.withMargin ? "4px" : "12px")};
  margin-right: 7px;
  border-radius: 50%;
  flex-shrink: 0;
  ...(props.violet && {
    backgroundColor: COLORS.violetTheme.emptyIcon,
  }),
`;

export const CheckIcon = styled(Icon.CheckIcon)<StyleProps>`
  margin-right: 3px;
  flex-shrink: 0;
  margin-left: ${(props) => props.withMargin && "8px"};
`;

export const SignedIcon = styled(Icon.DealSignedIcon)`
  margin-right: 1px;
`;
