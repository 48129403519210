import React from "react";

import styled from "@emotion/styled";

import capitalize from "lodash/capitalize";

import Icon from "shared/components/Icon";

import { MEDIA_QUERIES } from "shared/config/constants";

const getBreakpoint = (breakpoint) =>
  MEDIA_QUERIES.w[`gte${capitalize(breakpoint)}`];

const iconStyles = (props) => ({
  cursor: "pointer",
  marginLeft: "10px",
  minWidth: "11px",
  ...(props.onlyCollapseBelowBreakpoint && {
    [getBreakpoint(props.onlyCollapseBelowBreakpoint)]: {
      display: "none",
    },
  }),
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ChevronUpIcon = styled(({ onlyCollapseBelowBreakpoint, ...props }) => (
  <Icon.ChevronBoldUpIcon {...props} />
))(iconStyles);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ChevronDownIcon = styled(({ onlyCollapseBelowBreakpoint, ...props }) => (
  <Icon.ChevronBoldDownIcon {...props} />
))(iconStyles);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Header = styled(({ onlyCollapseBelowBreakpoint, ...props }) => (
  <div {...props} />
))((props) => ({
  cursor: "pointer",
  ...(props.onlyCollapseBelowBreakpoint && {
    [getBreakpoint(props.onlyCollapseBelowBreakpoint)]: {
      cursor: "default",
    },
  }),
}));

const Children = styled(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ collapsed, small, onlyCollapseBelowBreakpoint, fullHeight, ...props }) => (
    <div {...props} />
  )
)((props) => ({
  ...(props.collapsed === true && {
    display: "none",
    ...(props.onlyCollapseBelowBreakpoint && {
      [getBreakpoint(props.onlyCollapseBelowBreakpoint)]: {
        display: "block",
      },
    }),
  }),
  ...(props.small === true && {
    fontSize: "12px",
  }),
  ...(props.fullHeight === true && {
    height: "100%",
  }),
}));

export { ChevronUpIcon, ChevronDownIcon, Header, Children };
