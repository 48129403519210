import isNil from "lodash/isNil";

import { FadeInProps } from "./FadeIn";

export type UseFadeInProps = FadeInProps & {
  stagger?: number;
};

export const useFadeIn = (props: UseFadeInProps) => {
  let delay = props?.delay || 0;
  return (override?: UseFadeInProps) => {
    const result: FadeInProps = {
      ...props,
      delay,
      ...override,
    };
    const stagger = override?.stagger ?? props?.stagger;
    if (stagger && isNil(override?.delay)) delay += stagger;
    return result;
  };
};
