import React from "react";

import UploadPropertyLogoModal from "manager/components/Modal/UploadPropertyLogoModal";
import { propertySettingsPropTypes } from "manager/utils/propTypes";
import { usePermissions } from "shared/hooks";
import useModal from "shared/hooks/useModal";

import PropertyLogo from "./PropertyLogo";

const PropertyLogoContainer = ({ propertySettings }) => {
  const { openModalDialog } = useModal();
  const { canUploadPropertyLogo } = usePermissions();

  const openUploadLogoModal = () => {
    if (propertySettings && canUploadPropertyLogo) {
      openModalDialog(UploadPropertyLogoModal, {
        propertySettings,
      });
    }
  };

  return (
    <PropertyLogo
      openUploadLogoModal={openUploadLogoModal}
      propertyLogo={propertySettings?.logo}
      canUploadPropertyLogo={canUploadPropertyLogo}
    />
  );
};

PropertyLogoContainer.propTypes = {
  propertySettings: propertySettingsPropTypes,
};

PropertyLogoContainer.defaultProps = {
  propertySettings: undefined,
};

export default PropertyLogoContainer;
