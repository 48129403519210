import React from "react";

import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";

import { PrimaryText, TextFaintSmall } from "manager/pages/MagicLinks/styled";
import { Button } from "shared/components/Button";
import FilterBar from "shared/components/FilterBar";
import FlexContainer from "shared/components/FlexContainer";
import { SwitchTitle } from "shared/components/Form";
import Icon from "shared/components/Icon";
import { ActionLink } from "shared/components/Links";
import MobileFilters from "shared/components/MobileFilters";
import Table from "shared/components/Table";
import Tooltip from "shared/components/Tooltip";
import {
  BREAKPOINT_RESOLUTIONS,
  BREAKPOINT_TYPES,
} from "shared/config/constants";
import withBreakpoint from "shared/hocs/withBreakpoint";

import "./PropertiesTable.styles.css";
import { MAGIC_LINK_TYPE } from "../../config";
import {
  FiltersContainer,
  ActionButtonWrapper,
  MobileActionWrapper,
} from "../styled";

import {
  CellContent,
  NoImageFlex,
  SwitchWrapper,
  ThumbnailImage,
} from "./styled";
import UnitsList from "./UnitsList";

// eslint-disable-next-line react/prop-types
const Thumbnail = ({ name, url }) => {
  // eslint-disable-next-line react/prop-types
  const firstLetter = name?.[0]?.toUpperCase() || "N/A";

  return url ? (
    <ThumbnailImage src={url} alt={name} data-testid={`thumbnail_${name}`} />
  ) : (
    <NoImageFlex
      justifyContent="center"
      alignItems="center"
      data-testid={`no-image_${name}`}
    >
      {firstLetter}
    </NoImageFlex>
  );
};

const PropertiesTable = ({
  isPropertiesLoading,
  properties,
  portfolios,
  updateFilters,
  filters,
  onTableChange,
  togglePropertyBranding,
  breakpoint,
  onExportLinks,
  onShareLinks,
}) => {
  const isLarge = useMediaQuery({ minWidth: BREAKPOINT_RESOLUTIONS.lg });
  const isMiddle = useMediaQuery({ maxWidth: BREAKPOINT_RESOLUTIONS.md });
  const isSmall = useMediaQuery({ maxWidth: BREAKPOINT_RESOLUTIONS.sm - 1 });

  const getUnitsList = (record) => {
    const property = properties.find((item) => item.id === record.key) || {};
    const { units, name } = property;
    return (
      <UnitsList
        units={units}
        onShareLinks={onShareLinks}
        propertyName={name}
      />
    );
  };

  const tableFilters = {
    title: "Filter Properties",
    search: {
      action: (value) => updateFilters({ nameOrAddress: value }),
      placeholder: "Search by name or address",
      width: isMiddle ? 260 : 320,
      debounce: true,
      disabled: false,
      value: filters.nameOrAddress,
    },
    dropdownFilters: [
      {
        key: "portfolios",
        label: "Properties",
        onChange: (value) => updateFilters({ portfolioId: value }),
        value: filters.portfolioId,
        items: [
          {
            label: "All Portfolios",
            key: "",
          },

          ...portfolios.map((item) => ({
            label: item.name,
            key: `${item.id}`,
          })),
        ],
        minWidth: 280,
      },
    ],
    action: {
      key: "EXPORT_LINKS",
      node: (
        <ActionButtonWrapper>
          <Button
            type="secondary"
            onClick={onExportLinks}
            data-testid="export-links-button"
          >
            Export
          </Button>
        </ActionButtonWrapper>
      ),
    },
  };

  const columnsArray = [
    {
      title: "",
      dataIndex: "empty-left",
      key: "empty-left",
      width: 1,
      className: "magic-links-property-empty-left",
      colSpan: 0,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      width: isLarge && 360,
      colSpan: 3,
      className: "magic-links-property-name",
      align: "left",
    },
    {
      title: "",
      dataIndex: "x",
      key: "x",
      width: 20,
      colSpan: 0,
    },
    {
      title: "Property Branding",
      dataIndex: "branding",
      key: "branding",
      align: "right",
    },
    {
      title: "",
      dataIndex: "share",
      key: "share",
      width: 150,
    },
    {
      title: "",
      dataIndex: "empty-right",
      key: "empty-right",
      width: 1,
      className: "magic-links-property-empty-right",
    },
  ];

  const getColumnValuesArray = (property) => {
    const rowValuesArray = [
      <></>,
      <FlexContainer nodesMargin="0 10px 0 0" alignItems="center">
        <div>
          <Thumbnail name={property.name} url={property.thumbnailLogo} />
        </div>
        <CellContent>
          <PrimaryText data-testid={`name-${property.id}`}>
            {property.name}
          </PrimaryText>
          <div>
            <TextFaintSmall data-testid={`address-${property.id}`}>
              {property.address}
            </TextFaintSmall>
          </div>
        </CellContent>
      </FlexContainer>,
      <>
        {breakpoint === BREAKPOINT_TYPES.xs && (
          <UnitsList
            units={property.units}
            onShareLinks={onShareLinks}
            propertyName={property.name}
          />
        )}
      </>,
      <SwitchWrapper>
        {isSmall && <PrimaryText>Property Branding</PrimaryText>}
        <Tooltip
          title={
            !property.thumbnailLogo &&
            "This property doesn't have a logo uploaded"
          }
          theme="light"
        >
          <div className="tooltip-inner">
            <SwitchTitle
              value={property.usePropertyBranding}
              onChange={() => togglePropertyBranding(property)}
              noPadding
              data-testid={`branding-${property.id}`}
              disabled={!property.thumbnailLogo}
            />
          </div>
        </Tooltip>
      </SwitchWrapper>,

      <div>
        <ActionLink
          data-testid={`share-${property.id}`}
          Icon={Icon.QRCodeGreenIcon}
          onClick={() =>
            onShareLinks({
              magicLink: property.magicLink,
              magicPortalLink:
                property.displayOnPortal && property.magicPortalLink,
              name: property.name,
              prescreeningMagicLink: property.prescreeningMagicLink,
              type: MAGIC_LINK_TYPE.PROPERTY,
            })
          }
        >
          Share
        </ActionLink>
      </div>,
      <></>,
    ];
    return rowValuesArray;
  };

  return (
    <>
      <FiltersContainer>
        <FilterBar {...tableFilters} justifyContent="left" />
        {isSmall && (
          <MobileActionWrapper>
            <Button type="secondary" onClick={onExportLinks}>
              Export
            </Button>
          </MobileActionWrapper>
        )}
        <MobileFilters {...tableFilters} />
      </FiltersContainer>
      <Table
        searching={Boolean(filters.nameOrAddress)}
        alt
        loading={isPropertiesLoading}
        columns={columnsArray}
        columnBreakpoints={{
          xs: {
            column1: "name",
            column2: "branding",
          },
          sm: ["empty-left", "name", "x", "branding", "share", "empty-right"],
          md: ["empty-left", "name", "x", "branding", "share", "empty-right"],
          lg: ["empty-left", "name", "x", "branding", "share", "empty-right"],
        }}
        placeholder={{
          Icon: Icon.NoPropertiesIcon,
          label: "Properties",
        }}
        rows={properties?.map((property) => ({
          key: property.id,
          values: getColumnValuesArray(property),
        }))}
        onChange={onTableChange}
        noClickableRows
        expandable={["share", "x"]}
        expandedRowRender={getUnitsList}
        expandIconColumnIndex={2}
        expandedRowClassName={() => "magic-links-property-units-expanded"}
        size={isMiddle ? "small" : undefined}
        className="magic-links-dashboard-properties-table"
      />
    </>
  );
};

PropertiesTable.propTypes = {
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      magicLink: PropTypes.string.isRequired,
      units: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          magicLink: PropTypes.string.isRequired,
        })
      ),
    })
  ),
  filters: PropTypes.shape({
    nameOrAddress: PropTypes.string,
    portfolioId: PropTypes.string,
  }).isRequired,
  isPropertiesLoading: PropTypes.bool.isRequired,
  onTableChange: PropTypes.func.isRequired,
  updateFilters: PropTypes.func.isRequired,
  portfolios: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  togglePropertyBranding: PropTypes.func.isRequired,
  breakpoint: PropTypes.oneOf(Object.keys(BREAKPOINT_TYPES)).isRequired,
  onExportLinks: PropTypes.func.isRequired,
  onShareLinks: PropTypes.func.isRequired,
  disableLinks: PropTypes.bool,
};

PropertiesTable.defaultProps = {
  properties: [],
  portfolios: [],
  disableLinks: false,
};

export default withBreakpoint(PropertiesTable);
