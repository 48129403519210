import React from "react";

import Collapse from "shared/components/Collapse";
import { UnderlineLink } from "shared/components/Links";
import Tooltip from "shared/components/Tooltip";
import { formatDateTime } from "shared/utils/ui";

import { BluemoonDashboardHeaderProps } from "../interfaces";

import {
  HeaderContainer,
  HeaderColumn,
  HeaderTitle,
  Text,
  CollapseHeader,
} from "./styled";

const BluemoonDashboardHeader = ({
  dashboardData,
}: BluemoonDashboardHeaderProps) => {
  const {
    lastSyncedAt,
    lastSyncedLicenseName,
    licensesConnected,
    licensesDeactivated,
    fieldsConnected,
  } = dashboardData ?? {};
  return (
    <HeaderContainer alignItems="center" justifyContent="space-around">
      <Collapse
        defaultCollapsed
        className="collapse-container"
        onlyCollapseBelowBreakpoint="md"
        header={({ CollapseIcon }) => (
          <CollapseHeader>
            <CollapseIcon className="collapse-icon" />
            <HeaderColumn
              alignItems="center"
              flexWrap="wrap"
              justifyContent="space-around"
              className="collapse-header-column"
            >
              <HeaderTitle>Bluemoon Last updated</HeaderTitle>
              <Text light>{`${
                formatDateTime(lastSyncedAt) !== "N/A"
                  ? formatDateTime(lastSyncedAt)
                  : ""
              } ${
                lastSyncedLicenseName ? `(${lastSyncedLicenseName})` : ""
              }`}</Text>
            </HeaderColumn>
          </CollapseHeader>
        )}
      >
        <HeaderColumn flexWrap="wrap" justifyContent="space-around">
          <div>
            <HeaderTitle>Bluemoon Last updated</HeaderTitle>
            <Text light>{`${
              formatDateTime(lastSyncedAt) !== "N/A"
                ? formatDateTime(lastSyncedAt)
                : ""
            } ${
              lastSyncedLicenseName ? `(${lastSyncedLicenseName})` : ""
            }`}</Text>
          </div>
        </HeaderColumn>
        <HeaderColumn flexWrap="wrap" justifyContent="space-around">
          <Tooltip title="View connected licenses" placement="topLeft">
            <HeaderTitle>
              <UnderlineLink>licenses</UnderlineLink>
            </HeaderTitle>
          </Tooltip>
          <div>
            <Text light>{licensesConnected} Connected</Text>
            <Text light>{licensesDeactivated} Deactivated</Text>
          </div>
        </HeaderColumn>
        <HeaderColumn flexWrap="wrap" justifyContent="space-around">
          <Tooltip title="View connected fields" placement="topLeft">
            <HeaderTitle>
              <UnderlineLink to="/integrations/bluemoon-fields-mapping">
                Fields
              </UnderlineLink>
            </HeaderTitle>
          </Tooltip>
          <div>
            <Text light>{fieldsConnected} Connected</Text>
          </div>
        </HeaderColumn>
      </Collapse>
    </HeaderContainer>
  );
};
export default BluemoonDashboardHeader;
