import _ from 'lodash'
import React from 'react'
import Icon from 'shared/components/Icon'

const Workflows = ({ workflows, readOnlyWorkflows = [], builder = undefined }) => {
  const noWorkflows = _.isEmpty(workflows) && _.isEmpty(readOnlyWorkflows)

  return (
    <div className='vega content flex no-wrap column'>
      <p className='vega info'>
        <Icon.OrangeExclamation className='vega icon' />
        <span>This is an experimental editor.</span>
      </p>

      {noWorkflows && <p className='vega content info'>No Workflows</p>}

      {_.chain(workflows).compact().map(workflow => builder(workflow)).value()}
      {_.chain(readOnlyWorkflows).compact().map(workflow => builder(workflow, true)).value()}
    </div>
  )
}

export default Workflows
