import styled from "@emotion/styled";

import { Table } from "antd";

import DropdownMenu from "shared/components/DropdownMenu";
import { COLORS } from "shared/config/constants";

const { Column } = Table;

const StyledDropdownMenu = styled(DropdownMenu)`
  justify-content: flex-end;
`;

const TableColumn = styled(Column)<{ expand?: boolean }>((props) => ({
  flexGrow: props.expand ? 1 : 0,
  "&:not(:last-of-type)": {
    marginRight: "12px",
  },
}));

const Spacer = styled.div`
  margin-top: 16px;
`;

const SmallText = styled.span`
  font-size: 12px;
  color: ${COLORS.textColorPrimary};
  margin-bottom: 10px;
`;

const BgText = styled.span`
  background: ${COLORS.tag.default.background};
  padding: 6px 8px;
`;

const Wrapper = styled.div`
  margin-bottom: 20px;
  min-width: 50%;
`;

export { StyledDropdownMenu, TableColumn, Spacer, SmallText, BgText, Wrapper };
