import React from "react";

import useModal from "shared/hooks/useModal";

import ArgyleEmployerSearch from "./ArgyleEmployerSearch";

const ArgyleEmployerSearchContainer = () => {
  const { currentModalContext } = useModal();
  const { companyName } = currentModalContext || {};
  return <ArgyleEmployerSearch companyName={companyName} />;
};

export default ArgyleEmployerSearchContainer;
