import React from "react";

import { Col, Row } from "antd";

import { Input, VeroFormField } from "shared/components/Form";
import Spacer from "shared/components/Spacer";

import { CREATE_UNIT_FIELDS } from "./createUnit.config";

const CreateUnitInnerFormElements = () => (
  <>
    <Row gutter={[20, 25]}>
      <Col md={{ span: 12 }}>
        <VeroFormField
          id={CREATE_UNIT_FIELDS.name}
          name={CREATE_UNIT_FIELDS.name}
          data-testid={CREATE_UNIT_FIELDS.name}
          label="Unit Name"
          type="text"
          as={Input}
          required
        />
      </Col>
      <Col md={{ span: 12 }}>
        <VeroFormField
          id={CREATE_UNIT_FIELDS.unitType}
          name={CREATE_UNIT_FIELDS.unitType}
          data-testid={CREATE_UNIT_FIELDS.unitType}
          label="Unit Type"
          type="text"
          optional
          as={Input}
        />
      </Col>
    </Row>
    <Spacer />
    <Row gutter={[20, 25]}>
      <Col md={{ span: 8 }}>
        <VeroFormField
          id={CREATE_UNIT_FIELDS.bedrooms}
          name={CREATE_UNIT_FIELDS.bedrooms}
          data-testid={CREATE_UNIT_FIELDS.bedrooms}
          label="Bedrooms"
          type="number"
          optional
          min={0}
          as={Input}
        />
      </Col>
      <Col md={{ span: 8 }}>
        <VeroFormField
          id={CREATE_UNIT_FIELDS.bathrooms}
          name={CREATE_UNIT_FIELDS.bathrooms}
          data-testid={CREATE_UNIT_FIELDS.bathrooms}
          label="Bathrooms"
          type="number"
          step={0.5}
          optional
          min={0}
          as={Input}
        />
      </Col>
      <Col md={{ span: 8 }}>
        <VeroFormField
          id={CREATE_UNIT_FIELDS.squareFeet}
          name={CREATE_UNIT_FIELDS.squareFeet}
          data-testid={CREATE_UNIT_FIELDS.squareFeet}
          label="Square Feet"
          type="number"
          optional
          min={0}
          as={Input}
        />
      </Col>
    </Row>
  </>
);

export default CreateUnitInnerFormElements;
