import React, { useState, ReactElement } from "react";

import { Popover, PopoverContent } from "shared/components/Popover";

import {
  Container,
  Item,
  EmptyIcon,
  CheckIcon,
  ToggleDisplayAllLink,
  ToggleDisplayAllHoverLink,
  ItemIcon,
  PopoverList,
} from "./styled";

interface ItemProps {
  key?: string | number;
  label: string;
  singed?: boolean;
  checked?: boolean;
  Icon?: ReactElement | any;
}

interface CheckListProps {
  items: ItemProps[];
  defaultItemsDisplayed?: number;
  inline?: boolean;
  violet?: boolean;
  withoutComma?: boolean;
  getCustomIcon?: (item) => void;
  onHoverActive?: boolean;
}

const CheckList = ({
  items,
  defaultItemsDisplayed = undefined,
  inline = false,
  violet = false,
  withoutComma = false,
  getCustomIcon = undefined,
  onHoverActive = false,
  ...props
}: CheckListProps) => {
  const [displayingAll, setDisplayingAll] = useState(false);
  const toggleDisplayingAll = () => setDisplayingAll(!displayingAll);
  const itemsToDisplay =
    defaultItemsDisplayed && !displayingAll
      ? items.slice(0, defaultItemsDisplayed)
      : items;
  const title = items.map((item) => item.label).join(", ");

  const getDefaultIcon = (isChecked) =>
    isChecked ? (
      <CheckIcon data-testid="check-icon" />
    ) : (
      <EmptyIcon data-testid="empty-icon" violet={violet} />
    );

  return (
    <Container inline={inline} {...props} title={title}>
      {itemsToDisplay.map((item) => (
        <Item
          key={item.key}
          data-testid={`item-${item.key}`}
          inline={inline}
          violet={violet}
          withoutComma={withoutComma}
        >
          {getCustomIcon ? getCustomIcon(item) : getDefaultIcon(item.checked)}
          {item.label}
          {item.Icon && (
            <ItemIcon>
              <item.Icon />
            </ItemIcon>
          )}
        </Item>
      ))}
      {!onHoverActive &&
        defaultItemsDisplayed &&
        items.length > defaultItemsDisplayed && (
          <ToggleDisplayAllLink onClick={toggleDisplayingAll} dashed>
            {displayingAll ? "Show Less" : "Show All"}
          </ToggleDisplayAllLink>
        )}
      {onHoverActive &&
        defaultItemsDisplayed &&
        items.length > defaultItemsDisplayed && (
          // @ts-ignore
          <Popover
            trigger="hover"
            placement="bottom"
            withoutOverlay
            content={
              <PopoverContent>
                <PopoverList>
                  {items.slice(defaultItemsDisplayed).map((item) => (
                    <li key={item.key}>{item.label}</li>
                  ))}
                </PopoverList>
              </PopoverContent>
            }
          >
            <ToggleDisplayAllHoverLink>
              {`${items.length - itemsToDisplay.length} MORE`}
            </ToggleDisplayAllHoverLink>
          </Popover>
        )}
    </Container>
  );
};

export default CheckList;
