import React from "react";

import { Col, Row } from "antd";
import dayjs from "dayjs";
import { useFormikContext } from "formik";
import get from "lodash/get";

import {
  DatePicker,
  Input,
  Select,
  VeroFormField,
} from "shared/components/Form";
import { RegularText, SmallText } from "shared/components/Typography";

import {
  getBaseConditionPath,
  getConditionPropPath,
} from "../../LumenFilterConditions/lumenFilterConditions.util";
import {
  TIME_OPERATOR_OPTIONS,
  TIME_TYPE_OPTIONS,
  TIME_UNIT_OPTIONS,
  LUMEN_CONDITION_KEYS,
} from "../utils";

import BaseCondition from "./BaseCondition";
import { ConditionWrapper, RadioGroup, EmptySpacer } from "./styled";

interface ConditionTimeProps {
  onDelete: () => void;
  idx: number;
}

const ConditionTime = ({ idx, onDelete }: ConditionTimeProps) => {
  const conditionSubTypeProp = "conditionSubType";
  const timeOperatorProp = "timeOperator";
  const timeValueProp = "timeValue";
  const timeUnitProp = "timeUnit";
  const startDateProp = "startDate";
  const endDateProp = "endDate";
  const timeBasePath = getBaseConditionPath(idx);

  const conditionSubTypePropPath = getConditionPropPath(
    idx,
    conditionSubTypeProp
  );
  const timeOperatorPropPath = getConditionPropPath(idx, timeOperatorProp);
  const timeValuePropPath = getConditionPropPath(idx, timeValueProp);
  const timeUnitPropPath = getConditionPropPath(idx, timeUnitProp);
  const startDatePropPath = getConditionPropPath(idx, startDateProp);
  const endDatePropPath = getConditionPropPath(idx, endDateProp);

  const { values, setFieldValue, setFieldTouched } = useFormikContext();

  const conditionValues = get(values, timeBasePath);

  const isBetweenSelected =
    conditionValues?.timeOperator === LUMEN_CONDITION_KEYS.BETWEEN;

  const onTimeOperatorChange = (value: string) => {
    let updatedValues = {};
    if (value === LUMEN_CONDITION_KEYS.BETWEEN) {
      updatedValues = {
        timeValue: null,
        timeUnit: LUMEN_CONDITION_KEYS.YEARS,
      };
      setFieldTouched(startDatePropPath, false);
      setFieldTouched(endDatePropPath, false);
    } else {
      updatedValues = {
        startDate: null,
        endDate: null,
      };
      setFieldTouched(timeValuePropPath, false);
    }
    setFieldValue(timeBasePath, {
      ...conditionValues,
      ...updatedValues,
      timeOperator: value,
    });
  };

  // TODO dusan move this to shared space
  const isBeforeDate = (isoBeforeDate: string) => (date: Date) =>
    dayjs(date).isBefore(isoBeforeDate);

  const isDateDisabled = (date: Date) => {
    const isBeforeStartDate = isBeforeDate(conditionValues?.startDate);
    return isBeforeStartDate(date);
  };

  return (
    <BaseCondition conditionLabel="Time period" onDelete={onDelete}>
      <ConditionWrapper fullWidth={isBetweenSelected}>
        <Row gutter={[isBetweenSelected ? 12 : 32, 16]} type="flex">
          <Col span={isBetweenSelected ? 6 : 8}>
            <SmallText light>Time since account was</SmallText>
            <VeroFormField
              as={Select}
              name={conditionSubTypePropPath}
              id={conditionSubTypePropPath}
            >
              {TIME_TYPE_OPTIONS.map((option) => (
                <Select.Option key={option.key}>{option.label}</Select.Option>
              ))}
            </VeroFormField>
          </Col>
          <Col span={isBetweenSelected ? 6 : 8}>
            <SmallText light>Is</SmallText>
            <VeroFormField
              as={Select}
              name={timeOperatorPropPath}
              id={timeOperatorPropPath}
              onChange={onTimeOperatorChange}
            >
              {TIME_OPERATOR_OPTIONS.map((option) => (
                <Select.Option key={option.key}>{option.label}</Select.Option>
              ))}
            </VeroFormField>
          </Col>
          {isBetweenSelected ? (
            <>
              <Col span={5}>
                <EmptySpacer />
                <VeroFormField
                  as={DatePicker}
                  name={startDatePropPath}
                  id={startDatePropPath}
                  placeholder="MM/DD/YYYY"
                />
              </Col>
              <Col className="between-operator-and-col">
                <EmptySpacer />
                <RegularText>and</RegularText>
              </Col>
              <Col span={5}>
                <EmptySpacer />
                <VeroFormField
                  as={DatePicker}
                  name={endDatePropPath}
                  id={endDatePropPath}
                  placeholder="MM/DD/YYYY"
                  disabledDate={isDateDisabled}
                />
              </Col>
            </>
          ) : (
            <Col span={8}>
              <EmptySpacer />
              <VeroFormField
                as={Input}
                type="number"
                min={0}
                id={timeValuePropPath}
                name={timeValuePropPath}
                placeholder="Enter an amount"
              />
            </Col>
          )}
        </Row>
        {!isBetweenSelected && (
          <Row gutter={[32, 16]} type="flex" justify="end">
            <Col span={8}>
              <VeroFormField
                as={RadioGroup}
                id={timeUnitPropPath}
                name={timeUnitPropPath}
                optionType="button"
                size="small"
                options={TIME_UNIT_OPTIONS.map((option) => ({
                  value: option.key,
                  label: option.label,
                }))}
              />
            </Col>
          </Row>
        )}
      </ConditionWrapper>
    </BaseCondition>
  );
};

export default ConditionTime;
