import React, { ReactElement } from "react";

import {
  useTransition,
  useSpring,
  useChain,
  config,
  useSpringRef,
  animated,
} from "@react-spring/web";

import { useMounted } from "shared/hooks/useMounted";

import {
  Card,
  FIRST_COLUMN_CARDS,
  SECOND_COLUMN_CARDS,
  THIRD_COLUMN_CARDS,
} from "./config";

const useAnimateApplicant = () => {
  const { mounted: open } = useMounted();

  const springApi = useSpringRef();
  const spring = useSpring({
    ref: springApi,
    config: config.stiff,
    from: { opacity: 0, y: 24 },
    to: {
      opacity: open ? 1 : 0,
      y: open ? 0 : 24,
    },
    delay: 50,
  });

  const transitionGroup1 = useSpringRef();
  const transitionGroup2 = useSpringRef();
  const transitionGroup3 = useSpringRef();
  const transition = useTransition(open ? FIRST_COLUMN_CARDS : [], {
    ref: transitionGroup1,
    trail: 400 / FIRST_COLUMN_CARDS.length,
    from: { opacity: 0, scale: 0 },
    enter: { opacity: 1, scale: 1 },
  });

  const transition2 = useTransition(open ? SECOND_COLUMN_CARDS : [], {
    ref: transitionGroup2,
    trail: 400 / SECOND_COLUMN_CARDS.length,
    from: { opacity: 0, scale: 0 },
    enter: { opacity: 1, scale: 1 },
  });

  const transition3 = useTransition(open ? THIRD_COLUMN_CARDS : [], {
    ref: transitionGroup3,
    trail: 400 / THIRD_COLUMN_CARDS.length,
    from: { opacity: 0, scale: 0 },
    enter: { opacity: 1, scale: 1 },
  });

  useChain(
    [springApi, transitionGroup1, transitionGroup2, transitionGroup3],
    [0, 0.2, 0.4, 0.6]
  );

  return { transition, transition2, transition3, spring };
};

type Props = {
  getCard: (card: Card) => ReactElement;
  column: 0 | 1 | 2;
};

export const AnimatedCards: React.FC<Props> = ({
  getCard: getCard,
  column,
}) => {
  const { transition, transition2, transition3, spring } =
    useAnimateApplicant();
  const t =
    column === 0 ? transition : column === 1 ? transition2 : transition3;
  return (
    <animated.div style={spring}>
      {t((style, item) => (
        <animated.div style={style}>{getCard(item)}</animated.div>
      ))}
    </animated.div>
  );
};
