import styled from "@emotion/styled";

import { Select } from "shared/components/Form/Select";
import { COLORS } from "shared/config/constants";

export const StyledSelect = styled(Select)`
  .ant-select-selection__choice {
    color: ${COLORS.primary};
    background: transparent;
    border: none;
  }

  .ant-select-selection {
    overflow: hidden;
    cursor: pointer;
  }

  .ant-select-search--inline {
    display: inline;
    position: absolute;
  }
`;

export const DropdownMenu = styled.div`
  transform: translateY(-4px);

  .header-container {
    padding: 10px 15px;
  }

  .ant-select-dropdown-menu-item {
    color: ${COLORS.secondary};

    &:hover {
      color: ${COLORS.primary};
      background-color: ${COLORS.itemActiveBg} !important;
      & svg {
        color: ${COLORS.lightGreen2} !important;
      }
    }
    &-selected {
      color: ${COLORS.primary};
      background-color: ${COLORS.background} !important;
      & svg {
        color: ${COLORS.lightGreen2} !important;
      }
    }
  }
`;
