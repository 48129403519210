import React, { useMemo } from "react";

import keyBy from "lodash/keyBy";

import { LUMEN_FORMULA_STATUS } from "manager/config/lumen.config";
import { Button } from "shared/components/Button";
import DropdownMenu from "shared/components/DropdownMenu";
import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import { ActionLink } from "shared/components/Links";
import { Popover, PopoverContent } from "shared/components/Popover";
import RadioButtons, {
  RADIO_BUTTONS_THEMES,
} from "shared/components/RadioButtons";
import Table from "shared/components/Table/Table/Table";
import { RegularText, WeakText } from "shared/components/Typography";
import { useDeviceType } from "shared/hooks";
import { formatDate } from "shared/utils/ui";

import { FormulaTable } from "./interfaces";
import { LUMEN_FORMULA_FILTER } from "./lumenDashboard.config";
import PropertiesListPopover from "./PropertiesListPopover";
import { TableWrapper, TableHeader } from "./styled";

const LumenFormulaTable = ({
  lumenFormulas,
  formulaStatusFilter,
  setFormulaStatusFilter,
  createNewLumenFormula,
  assignFormula,
  duplicateFormula,
  editFormula,
  renameFormula,
  deleteFormula,
  showMarkFormulaAsTemplate,
  markFormulaAsTemplate,
  isTableLoading,
}: FormulaTable) => {
  const { isMobile } = useDeviceType();

  const formulaMap = useMemo(
    () => keyBy(lumenFormulas, (f) => f.id),
    [lumenFormulas]
  );

  return (
    <TableWrapper>
      <TableHeader>
        <FlexContainer className="table-header-title">
          <FlexContainer alignItems="center">
            <h2>Formulas</h2>
            <Button
              type="secondary"
              className="new-formula-mobile"
              onClick={createNewLumenFormula}
            >
              + NEW FORMULA
            </Button>
          </FlexContainer>
          <RadioButtons
            activeItem={formulaStatusFilter}
            onChange={setFormulaStatusFilter}
            theme={RADIO_BUTTONS_THEMES.gray}
            menuItems={[
              {
                key: LUMEN_FORMULA_FILTER.ACTIVE,
                name: "Active",
              },
              {
                key: LUMEN_FORMULA_FILTER.DRAFT,
                name: "Drafts",
              },
              {
                key: LUMEN_FORMULA_FILTER.ALL,
                name: "All",
              },
            ]}
          />
        </FlexContainer>
        <Button
          type="secondary"
          className="new-formula"
          onClick={createNewLumenFormula}
        >
          + NEW FORMULA
        </Button>
      </TableHeader>
      <Table
        alt
        columns={[
          {
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: (a, b) =>
              a.name.props.children.localeCompare(b.name.props.children),
          },
          {
            title: "Created by",
            dataIndex: "createdBy",
            key: "createdBy",
            sorter: (a, b) =>
              a.createdBy.props.children.localeCompare(
                b.createdBy.props.children
              ),
          },
          {
            title: "Last Updated",
            dataIndex: "updatedAt",
            key: "updatedAt",
            sorter: (a, b) => {
              const d1 = formulaMap[a.key].updatedAt || "";
              const d2 = formulaMap[b.key].updatedAt || "";
              return d1.localeCompare(d2);
            },
          },
          {
            title: "Portfolios",
            dataIndex: "numPortfoliosAssigned",
            key: "numPortfoliosAssigned",
            sorter: (a, b) =>
              a.numPortfoliosAssigned.props.children -
              b.numPortfoliosAssigned.props.children,
          },
          {
            title: "Individual Properties",
            dataIndex: "numPropertiesAssigned",
            key: "numPropertiesAssigned",
            sorter: (a, b) =>
              a.numPropertiesAssigned.props.children -
              b.numPropertiesAssigned.props.children,
          },
        ]}
        rows={lumenFormulas.map((formula) => ({
          key: formula.id,
          values: [
            <strong>{formula.name}</strong>,
            <WeakText>{formula.createdBy}</WeakText>,
            <strong>{formatDate(formula.updatedAt)}</strong>,
            <PropertiesListPopover assignments={formula.portfolios} />,
            <PropertiesListPopover assignments={formula.properties} />
          ],
          primaryAction: (
            <div className="row-actions">
              {formula.status === LUMEN_FORMULA_STATUS.COMPLETED && (
                <ActionLink
                  className="assign-link"
                  onClick={() => assignFormula(formula)}
                >
                  ASSIGN TO
                </ActionLink>
              )}
              <DropdownMenu
                items={[
                  {
                    key: "duplicate",
                    disabled:
                      formula.status !== LUMEN_FORMULA_STATUS.COMPLETED,
                    label: (
                      // @ts-ignore
                      <Popover
                        disabled={
                          formula.status === LUMEN_FORMULA_STATUS.COMPLETED
                        }
                        overlayStyle={{ zIndex: 9999 }}
                        content={
                          <PopoverContent>
                            Only a completed formula can be duplicated
                          </PopoverContent>
                        }
                      >
                        Duplicate
                      </Popover>
                    ),
                    onClick: () => duplicateFormula(formula),
                  },
                  {
                    key: "edit",
                    label: "Edit",
                    onClick: () => editFormula(formula),
                  },
                  {
                    key: "rename",
                    label: "Rename",
                    onClick: () => renameFormula(formula),
                  },
                  ...(showMarkFormulaAsTemplate
                    ? [
                        {
                          key: "markAsTemplate",
                          label: (
                            // @ts-ignore
                            <Popover
                              disabled={
                                formula.status ===
                                LUMEN_FORMULA_STATUS.COMPLETED
                              }
                              overlayStyle={{ zIndex: 9999 }}
                              content={
                                <PopoverContent>
                                  Only a completed formula can be marked as
                                  template
                                </PopoverContent>
                              }
                            >
                              Mark as template
                            </Popover>
                          ),
                          onClick: () => markFormulaAsTemplate(formula),
                          disabled:
                            formula.status !==
                            LUMEN_FORMULA_STATUS.COMPLETED,
                        },
                      ]
                    : []),
                  {
                    key: "remove",
                    disabled: formula.numPropertiesAssigned > 0,
                    label: (
                      // @ts-ignore
                      <Popover
                        disabled={!formula.numPropertiesAssigned}
                        overlayStyle={{ zIndex: 9999 }}
                        content={
                          <PopoverContent>
                            You are not able to remove this formula because
                            it is assigned to some properties
                          </PopoverContent>
                        }
                      >
                        Remove
                      </Popover>
                    ),
                    onClick: () => deleteFormula(formula),
                  },
                ]}
              />
            </div>
          ),
        }))}
        columnBreakpoints={{
          xs: {
            column1: "name",
            column2: "numPropertiesAssigned",
          },
          sm: ["name", "createdBy", "updatedAt", "numPropertiesAssigned"],
        }}
        showActionColumn
        noClickableRows
        placeholder={{
          label: "Formulas",
          Icon: Icon.LumenSmallBackground,
        }}
        onRowClick={() => {}}
        loading={isTableLoading}
      />
    </TableWrapper>
  );
};

export default LumenFormulaTable;
