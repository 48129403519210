import styled from "@emotion/styled";

import FlexContainer from "shared/components/FlexContainer";
import { MEDIA_QUERIES } from "shared/config/constants";

const ChartTitle = styled(FlexContainer)`
  margin-bottom: 40px;

  h4 {
    margin-right: 16px;
  }

  div {
    font-size: 40px;
  }

  ${MEDIA_QUERIES.w.ltLg} {
    h4 {
      font-size: 15px !important;
    }

    div {
      font-size: 30px;
    }
  }
`;

const Chart = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
`;

export { ChartTitle, Chart };
