import { css } from "@emotion/core";
import styled from "@emotion/styled";

import { COLORS } from "shared/config/constants";
import NoPropertiesImage from "shared/icons/no-properties.svg";
import { OverflowEllipsisMultiline } from "shared/styles/commonStyles";

const Tile = styled.section`
  width: 290px;
  height: 200px;
  /** Vertical flex collapses tiles without min-height on older iphones */
  min-height: 200px;
  background: ${COLORS.layoutBodyBackground};
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid ${COLORS.layoutBodyBackground};
  box-shadow: 0px 4px 22px ${COLORS.lightGrayBoxShadow};

  .property-tile-header {
    background: ${COLORS.primary};
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    overflow: hidden;
    text-align: center;

    & > span {
      color: ${COLORS.white};
      ${OverflowEllipsisMultiline(2)}
    }
  }

  .property-tile-content {
    height: calc(100% - 60px);
  }

  .property-photo {
    height: 100%;
    width: 110px;

    ${({ photo }) =>
      photo
        ? css`
            background: url("${photo}");
            background-size: cover;
            background-repeat: no-repeat;
            background-position-y: center;
          `
        : css`
            background: url("${NoPropertiesImage}");
            background-size: contain;
            background-repeat: no-repeat;
            background-position-x: 5px;
            background-position-y: center;
            background-color: ${COLORS.white};
          `}
  }

  .property-description {
    padding: 4px 10px;
    display: flex;
    flex-direction: column;
  }

  .property-address {
    height: 70px;
    flex-grow: 1;
    color: ${COLORS.textColorPrimary};

    & > span {
      ${OverflowEllipsisMultiline(4)}
    }
  }

  .property-price {
    color: ${COLORS.green};
  }
`;

export { Tile };
