import React, { Key } from "react";

import { useHistory } from "react-router-dom";

import { useCreateUnit } from "manager/hooks/api/unitsQueries";
import { Unit } from "manager/interfaces/api/unit";
import useModal from "shared/hooks/useModal";
import { onDialogSubmitError } from "shared/utils/api";

import CreateUnitModal from "./CreateUnitModal";

interface CreateUnitModalContainerProps {
  initialValues: {
    name: string;
    unitType: string;
    bedrooms: string;
    bathrooms: string;
    squareFeet: string;
  };
  propertyId: Key;
  propertyName: string;
  afterUnitCreate: (unit: Unit) => void;
}

const CreateUnitModalContainer = ({
  propertyId,
  propertyName,
  initialValues,
  afterUnitCreate,
}: CreateUnitModalContainerProps) => {
  const history = useHistory();
  const { closeActiveModalDialog } = useModal();
  const { createUnit } = useCreateUnit();

  const submit = (values, formikApi) => {
    const onSuccess = (newUnit) => {
      closeActiveModalDialog();
      if (afterUnitCreate) {
        afterUnitCreate(newUnit);
      }
    };

    const onError = onDialogSubmitError(formikApi, history);

    createUnit({ ...values, property: propertyId }, { onSuccess, onError });
  };

  return (
    <CreateUnitModal
      propertyName={propertyName}
      initialValues={initialValues}
      submit={submit}
    />
  );
};

export default CreateUnitModalContainer;
