import axios from "axios";

import ROUTES from "manager/config/apiRoutes";
import { API_URL } from "shared/lib/api/setupApi";
import { transformRoute } from "shared/utils/routing";

const api = {
  /**
   * @param {object} params
   * @param {string} params.name - filename
   * @param {number} params.companyId - companyId
   * @param {ArrayBuffer} params.upload - uploading file
   * @returns {Promise<{ id: number, file: string, companyId: number, name: string }>}
   */
  uploadCompanyScreeningCriteriaDocument: ({ name, companyId, upload }) => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("company_id", companyId);
    formData.append("upload", upload);

    return axios
      .post(
        `${API_URL}${ROUTES.uploadCompanyScreeningCriteriaDocument}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(({ data }) => data);
  },

  /**
   * @param {object} params
   * @param {string} params.name - filename
   * @param {number} params.propertyId - propertyId
   * @param {ArrayBuffer} params.upload - uploading file
   * @returns {Promise<{ id: number, file: string, propertyId: number, name: string }>}
   */
  uploadPropertyScreeningCriteriaDocument: ({ name, propertyId, upload }) => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("property_id", propertyId);
    formData.append("upload", upload);

    return axios
      .post(
        `${API_URL}${ROUTES.uploadPropertyScreeningCriteriaDocument}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(({ data }) => data);
  },

  /**
   * @param {number} companyId
   */
  deleteCompanyScreeningCriteriaDocument: (companyId) =>
    axios.delete(
      transformRoute(
        `${API_URL}${ROUTES.deleteCompanyScreeningCriteriaDocument}`,
        {
          companyId,
        }
      )
    ),

  /**
   * @param {number} propertyId
   */
  deletePropertyScreeningCriteriaDocument: (propertyId) =>
    axios.delete(
      transformRoute(
        `${API_URL}${ROUTES.deletePropertyScreeningCriteriaDocument}`,
        {
          propertyId,
        }
      )
    ),

  /**
   * @param {number} companyId
   * @returns {Promise.<{ id: number, file: number, fileUrl: string, companyId: number }>}
   */
  getCompanyScreeningCriteriaDocument: (companyId) =>
    axios
      .get(
        transformRoute(
          `${API_URL}${ROUTES.getCompanyScreeningCriteriaDocument}`,
          {
            companyId,
          }
        )
      )
      .then(({ data }) => data),

  /**
   * @param {number} propertyId
   * @returns {Promise.<{ id: number, file: number, fileUrl: string, propertyId: number }>}
   */
  getPropertyScreeningCriteriaDocument: (propertyId) =>
    axios
      .get(
        transformRoute(
          `${API_URL}${ROUTES.getPropertyScreeningCriteriaDocument}`,
          {
            propertyId,
          }
        )
      )
      .then(({ data }) => data),
};

export default api;
