import React, { useState } from "react";

import DropdownMenu from "shared/components/DropdownMenu";
import Icon from "shared/components/Icon";

import { Button } from "./styled";

export interface DropdownButtonProps {
  children: string;
  disabled?: boolean;
  items: any[];
  type?: any;
  light?: boolean;
  className?: string;
  [key: string]: any;
}

const DropdownButton = ({
  children,
  items,
  disabled = false,
  type = "primary",
  light = false,
  className = undefined,
  ...props
}: DropdownButtonProps) => {
  const [visible, setVisible] = useState(false);

  return (
    <DropdownMenu
      items={items}
      placement="topRight"
      onVisibleChange={(status) => {
        setVisible(status);
      }}
      light={light}
      className={className}
    >
      <Button
        type={type}
        BorderedSuffixIcon={
          visible
            ? Icon.DropdownButtonChevronUpIcon
            : Icon.DropdownButtonChevronDownIcon
        }
        disabled={disabled}
        {...props}
      >
        {children}
      </Button>
    </DropdownMenu>
  );
};

export default DropdownButton;
