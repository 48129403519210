import React, { useState } from "react";

import { TooltipPlacement } from "antd-latest/lib/tooltip";

import Banner from "shared/components/Banner";
import { Button } from "shared/components/Button";
import Icon from "shared/components/Icon";
import { UnderlineLink } from "shared/components/Links";
import Tooltip from "shared/components/Tooltip";

import { RegularText } from "shared/components/Typography";
import { useDeviceType } from "shared/hooks";

import {
  BarItemContainer,
  Container,
  BindPropertiesLink,
  MobileButton,
} from "./styled";

interface BarItemProps {
  label: string;
  value: number | string;
  onClick?: () => void;
  tooltipText?: string;
  tooltipPlacement?: TooltipPlacement;
}

const BarItem = ({
  label,
  value,
  onClick,
  tooltipText,
  tooltipPlacement,
}: BarItemProps) => (
  <BarItemContainer alignItems="center">
    <RegularText strong className="label">
      {label}
    </RegularText>
    <Tooltip title={tooltipText} placement={tooltipPlacement}>
      <UnderlineLink className="value" onClick={onClick}>
        {value}
      </UnderlineLink>
    </Tooltip>
  </BarItemContainer>
);

interface BluemoonPropertiesBarProps {
  notBoundProperties: number;
  fullyBoundProperties: number;
  hiddenProperties: number;
  hasNotBoundProperties: boolean;
  handleBindProperties: () => void;
  openHiddenPropertiesModal: () => void;
  openFullyBoundedPropertiesPage: () => void;
  openPropertiesBindingPage: () => void;
}

const BluemoonPropertiesBar = ({
  notBoundProperties,
  fullyBoundProperties,
  hiddenProperties,
  hasNotBoundProperties,
  handleBindProperties,
  openHiddenPropertiesModal,
  openFullyBoundedPropertiesPage,
  openPropertiesBindingPage,
}: BluemoonPropertiesBarProps) => {
  const { isMobile } = useDeviceType();
  const [isExpanded, setExpanded] = useState(true);

  const ChevronIcon =
    isExpanded && isMobile ? Icon.ChevronUpIcon : Icon.ChevronDownIcon;

  const toggleExpandedSection = () => setExpanded(!isExpanded);

  return (
    <>
      <Container
        alignItems="center"
        hasNotBoundProperties={hasNotBoundProperties}
      >
        {!isMobile && (
          <div className="bind-properties-btn">
            <Button type="secondary" onClick={handleBindProperties}>
              BIND PROPERTIES
            </Button>
          </div>
        )}
        <BarItem
          label="PROPERTIES NOT BOUND"
          value={notBoundProperties}
          onClick={openPropertiesBindingPage}
          tooltipText="Begin binding properties"
          tooltipPlacement="topLeft"
        />
        {isMobile && (
          // @ts-ignore
          <ChevronIcon
            className="dropdown-chevron"
            onClick={toggleExpandedSection}
          />
        )}
        {isExpanded && (
          <>
            <Tooltip title="View bound properties list" placement="bottom">
              <BarItem
                label="PROPERTIES FULLY BOUND"
                value={fullyBoundProperties}
                tooltipText="View bound properties list"
                tooltipPlacement="bottomRight"
                onClick={openFullyBoundedPropertiesPage}
              />
            </Tooltip>
            <Tooltip title="View hidden properties">
              <BarItem
                label="PROPERTIES HIDDEN"
                value={hiddenProperties}
                tooltipText="View hidden properties"
                tooltipPlacement="topRight"
                onClick={openHiddenPropertiesModal}
              />
            </Tooltip>
          </>
        )}
      </Container>
      {isMobile && (
        <MobileButton type="secondary" onClick={handleBindProperties}>
          BIND PROPERTIES
        </MobileButton>
      )}
      {hasNotBoundProperties && (
        <Banner
          message={
            <RegularText>
              Some properties are not bound. Click{" "}
              <Tooltip title="Begin binding properties">
                <BindPropertiesLink onClick={handleBindProperties}>
                  BIND PROPERTIES
                </BindPropertiesLink>
              </Tooltip>{" "}
              to begin merging Bluemoon and VERO properties. You can create a
              new VERO property if one does not exist or hide a Bluemoon
              property to resolve warnings without binding.
            </RegularText>
          }
        />
      )}
    </>
  );
};

export default BluemoonPropertiesBar;
