
import dayjs from "dayjs";
import pickBy from "lodash/pickBy";
import PropTypes from "prop-types";
import { printDollarsFromCents } from "shared/utils/dollar-print";

import ROUTES from "renter/config/routes";
import FlexContainer from "shared/components/FlexContainer";
import { ActionLink } from "shared/components/Links";
import { ACTION_LINK_THEMES } from "shared/components/Links/ActionLink";
import Tooltip from "shared/components/Tooltip";
import { useEllipsisTooltip } from "shared/hooks";
import { getQueryParams, transformRoute } from "shared/utils/routing";
import { getValueOrNA, numberWithCommas } from "shared/utils/ui";

import {
  StyledTile,
  UnitAvailableTitle,
  UnitDetails,
  UnitNameTitle,
  UnitWhiteText,
} from "./styled";

const UnitTile = ({
  name,
  listing,
  minRent,
  unitListingsCount,
  bedrooms,
  bathrooms,
  squareFeet,
  hashedId,
  noBedroomDescription,
  hasLroEnabled,
  propertyHashedId,
  companyUuid,
  propertyUuid,
  isMagicLinksV2,
  isPrescreeningMagicLinksV2,
}) => {
  const availableDate =
    listing?.unitAvailableOn && dayjs(listing?.unitAvailableOn);
  const formattedDate =
    availableDate && availableDate.isAfter(dayjs())
      ? `on ${availableDate.format("M/D/YYYY")}`
      : "Now";

  const { elementRef, tooltipTitle } = useEllipsisTooltip({ title: name });

  const useRevenueManagementRent = hasLroEnabled && minRent;
  const rent = useRevenueManagementRent ? minRent : listing?.rent;
  const rentLabel =
    useRevenueManagementRent && unitListingsCount > 1 ? "Starting at" : "Rent";

  const formattedRent = printDollarsFromCents(rent);
  const formattedSecurityDeposit = printDollarsFromCents(
    listing?.securityDeposit
  );
  const noBedroomDescriptionTooltip =
    (!bedrooms || bedrooms === 0) && noBedroomDescription?.length >= 9
      ? noBedroomDescription
      : "";

  const applyLabel = isPrescreeningMagicLinksV2 ? "Prequalify" : "Apply";
  let applyRoute;
  if (isMagicLinksV2 || isPrescreeningMagicLinksV2) {
    const { application } = getQueryParams();

    let baseRoute;
    const queryParams = pickBy({
      company: companyUuid,
      "property-source": "vero",
      property: propertyUuid,
      unit: name,
      application,
    });

    if (isPrescreeningMagicLinksV2) {
      queryParams["show-landing"] = "true";
      baseRoute = ROUTES.prescreeningMagicLinkV2GetStarted;
    } else {
      baseRoute = ROUTES.magicLinkV2GetStarted;
    }

    const queryParamsString = Object.keys(queryParams)
      .map((p) => `${p}=${queryParams[p]}`)
      .join("&");
    applyRoute = `${baseRoute}?${queryParamsString}`;
  } else if (hasLroEnabled) {
    applyRoute = transformRoute(ROUTES.magicPortalPropertyUnit, {
      propertyHashId: propertyHashedId,
      unitHashId: hashedId,
      companyHashId: hashedId,
    });
  } else {
    applyRoute = transformRoute(ROUTES.magicLinkGetStarted, {
      propertyHashId: propertyHashedId,
      unitHashId: hashedId,
      companyHashId: hashedId,
    });
  }

  return (
    <StyledTile className="magic-unit-tile">
      <div className="tile-row">
        <Tooltip title={tooltipTitle} theme="light">
          <UnitNameTitle className="text-ellipsis" ref={elementRef}>
            {name}
          </UnitNameTitle>
        </Tooltip>
        <UnitAvailableTitle>
          Available {getValueOrNA(formattedDate)}
        </UnitAvailableTitle>
      </div>
      <div className="unit-details">
        <FlexContainer className="unit-amenities text-ellipsis">
          <Tooltip title={noBedroomDescriptionTooltip} theme="light">
            <UnitWhiteText className="text-ellipsis bordered-item no-bedroom-description">
              {!bedrooms || bedrooms === 0
                ? noBedroomDescription
                : `${bedrooms}Bd`}
            </UnitWhiteText>
          </Tooltip>
          {bathrooms && (
            <UnitWhiteText className="bordered-item">
              {bathrooms}Ba
            </UnitWhiteText>
          )}
          {squareFeet && (
            <UnitWhiteText className="bordered-item">
              {numberWithCommas(squareFeet)} Sqft
            </UnitWhiteText>
          )}
        </FlexContainer>
        <UnitWhiteText>
          {rentLabel} {formattedRent}
        </UnitWhiteText>
      </div>
      <div className="tile-row">
        <UnitDetails>Sec. Deposit {formattedSecurityDeposit}</UnitDetails>
        <div>
          <ActionLink
            plus
            theme={ACTION_LINK_THEMES.greenForViolet}
            to={applyRoute}
            nowrap
          >
            {applyLabel}
          </ActionLink>
        </div>
      </div>
    </StyledTile>
  );
};

UnitTile.propTypes = {
  bathrooms: PropTypes.string,
  bedrooms: PropTypes.number,
  listing: PropTypes.shape({
    rent: PropTypes.number,
    securityDeposit: PropTypes.number,
    unitAvailableOn: PropTypes.string,
  }),
  minRent: PropTypes.number,
  unitListingsCount: PropTypes.number,
  name: PropTypes.string.isRequired,
  squareFeet: PropTypes.number,
  hashedId: PropTypes.string.isRequired,
  noBedroomDescription: PropTypes.string.isRequired,
  hasLroEnabled: PropTypes.bool.isRequired,
  propertyHashedId: PropTypes.string.isRequired,
  companyUuid: PropTypes.string,
  propertyUuid: PropTypes.string,
  isPrescreeningMagicLinksV2: PropTypes.bool,
  isMagicLinksV2: PropTypes.bool,
};

UnitTile.defaultProps = {
  squareFeet: undefined,
  listing: undefined,
  minRent: undefined,
  unitListingsCount: undefined,
  bathrooms: undefined,
  bedrooms: undefined,
  // eslint-disable-next-line react/default-props-match-prop-types
  hasLroEnabled: false,
  // eslint-disable-next-line react/default-props-match-prop-types
  propertyHashedId: undefined,
  companyUuid: undefined,
  propertyUuid: undefined,
};

export default UnitTile;
