import styled from "@emotion/styled";

import { Modal } from "shared/components/Modal";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

const Container = styled.div`
  padding-left: 8px;

  .share-title {
    display: inline-block;
    margin-bottom: 24px;
  }

  .share-row {
    padding: 8px;
    margin-bottom: 24px;
    background: ${COLORS.lightGray2};
    border: 1px solid ${COLORS.borders};
    border-radius: 2px;
  }

  .share-row > .ant-row-flex {
    margin: none;
  }

  .share-row > .ant-row-flex > .ant-col {
    flex-grow: 1;
    padding: 20px 0;
  }

  .share-row > .ant-row-flex svg {
    flex-shrink: 0;
  }

  .share-row > .ant-row-flex > .ant-col:not(:first-of-type) {
    text-align: center;
    border-left: 1px solid ${COLORS.borders};
  }

  .share-row-name {
    padding: 0 0 0 40px;
    display: inline-block;
    max-width: 350px;
  }

  .share-row-qr,
  .share-row-link {
    margin: auto;
    margin-top: 20px;
  }

  .share-row-qr > span,
  .share-row-link > span {
    margin-right: 0;
  }

  .share-row > .ant-row-flex .share-row-desc {
    padding: 0 40px;
  }

  ${MEDIA_QUERIES.w.ltLg} {
    .share-row > .ant-row-flex .share-row-desc {
      padding: 0;
      flex-wrap: nowrap;
    }

    .share-row-name {
      padding: 0 10px;
    }
  }

  ${MEDIA_QUERIES.w.ltMd} {
    padding: 12px 8px 20px;

    .share-row {
      padding: 4px 4px 18px;
      margin-bottom: 20px;
    }

    .share-row:last-of-type {
      margin-bottom: 0;
    }

    .share-title {
      font-weight: 500;
      margin-bottom: 15px;
    }

    .share-row > .ant-row-flex > .ant-col {
      padding-top: 4px;
    }

    .share-row > .ant-row-flex > .ant-col:first-of-type {
      text-align: center;
    }

    .share-row > .ant-row-flex > .ant-col:not(:last-of-type) {
      border: none;
    }

    .share-row > .ant-row-flex .share-row-desc {
      display: inline-block;
      position: relative;
    }

    .share-row-name {
      text-align: center;
      max-width: 400px;
    }

    .share-row-name > div {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      padding: 0 4px;
    }

    .share-row-name h4 {
      margin-left: 5px;
    }
  }
`;

const ModalBody = styled(Modal.Body)`
  padding: 32px 40px;

  ${MEDIA_QUERIES.w.ltMd} {
    padding: 32px 20px 25px;
  }
`;

export { Container, ModalBody };
