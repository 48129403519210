import axios from "axios";

import ROUTES from "manager/config/apiRoutes";
import { AdditionalQuestionModel } from "manager/interfaces/api/additionalQuestion";
import { API_URL } from "shared/lib/api/setupApi";
import { transformRoute } from "shared/utils/routing";

const api = {
  createQuestion: (question: AdditionalQuestionModel) =>
    axios
      .post<AdditionalQuestionModel>(
        `${API_URL}${ROUTES.additionalQuestions}`,
        question
      )
      .then(({ data }) => data),
  updateQuestion: ({ id, ...question }: Partial<AdditionalQuestionModel>) =>
    axios
      .patch<AdditionalQuestionModel>(
        `${API_URL}${transformRoute(ROUTES.additionalQuestion, { id })}`,
        question
      )
      .then(({ data }) => data),
  deleteQuestion: (id: number) =>
    axios.delete(
      `${API_URL}${transformRoute(ROUTES.additionalQuestion, { id })}`
    ),
  toggleAdditionalQuestionActive: (id: number) =>
    axios.patch(
      `${API_URL}${transformRoute(ROUTES.toggleAdditionalQuestionActive, {
        id,
      })}`
    ),
};

export default api;
