import React, { useMemo } from "react";

import { useFormikContext } from "formik";
import capitalize from "lodash/capitalize";

import {
  LUMEN_SECTION_TYPE,
  LUMEN_SECTION_TYPE_LABELS,
  LUMEN_SECTION_VALIDATION_STATUS,
  LUMEN_VALIDATION_STATUS_FIELD_FOR_SECTION,
} from "manager/config/lumen.config";
import FlexContainer from "shared/components/FlexContainer";
import {
  NumberedSteps,
  NUMBERED_STEPS_THEMES,
} from "shared/components/NumberedSteps";

import LumenHowItWorksAction from "../LumenDashboard/LumenHowItWorksAction";

import {
  LumenFormulaFormikValues,
  LumenFormulaStepperProps,
} from "./interfaces";
import {
  LumenFormulaStepperContent,
  LumenFormulaStepperBanner,
  LumenFormulaStepperBody,
  LumenHowItWorksContainer,
} from "./styled";

const COMPLETED_VALIDATION_STATUS = [
  LUMEN_SECTION_VALIDATION_STATUS.COMPLETE,
  LUMEN_SECTION_VALIDATION_STATUS.UNUSED,
];

export const LumenFormulaStepper = ({
  formulaName,
  currentSection,
  saveAndChangeSection,
  validationStatus,
}: LumenFormulaStepperProps) => {
  const { values, isValid } = useFormikContext<LumenFormulaFormikValues>();

  const steps = useMemo(
    () =>
      Object.keys(LUMEN_SECTION_TYPE)
        .filter((sectionType) => {
          // NOTE: The assets formula section will come later since we don't have the required
          // data to define it and use it at the moment.
          return LUMEN_SECTION_TYPE.ASSETS !== sectionType;
        })
        .map((sectionType) => {
          const validationStatusField =
            LUMEN_VALIDATION_STATUS_FIELD_FOR_SECTION[sectionType];
          const status = validationStatus[validationStatusField];
          return {
            sectionType,
            label: capitalize(LUMEN_SECTION_TYPE_LABELS[sectionType]),
            completed: COMPLETED_VALIDATION_STATUS.includes(status),
          };
        }),
    [validationStatus]
  );

  const currentSectionIndex = steps.findIndex(
    (item) => item.sectionType === currentSection
  );

  const isStepDisabled = (index) =>
    steps.slice(0, index).some((item) => !item.completed);

  const onStepSelection = (index) => {
    const nextStep = steps[index].sectionType;
    if (isValid) {
      saveAndChangeSection(values, nextStep);
    }
  };

  return (
    <LumenFormulaStepperContent noPadding data-testid="lumen-formula-stepper">
      <LumenFormulaStepperBanner>
        <div className="banner-icon-1" />
        <FlexContainer alignItems="center" className="banner-message">
          <span>{formulaName} formula setup</span>
          <LumenHowItWorksContainer>
            <LumenHowItWorksAction />
          </LumenHowItWorksContainer>
        </FlexContainer>
        <div className="banner-icon-2" />
      </LumenFormulaStepperBanner>
      <LumenFormulaStepperBody>
        <NumberedSteps
          className="numbered-stepper"
          steps={steps}
          current={currentSectionIndex}
          onStepSelection={onStepSelection}
          isStepDisabled={isStepDisabled}
          theme={NUMBERED_STEPS_THEMES.dark}
        />
      </LumenFormulaStepperBody>
    </LumenFormulaStepperContent>
  );
};
