import styled from "@emotion/styled";

import FlexContainer from "shared/components/FlexContainer";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

const SwitchWrapper = styled("div")({
  width: "80px",
  marginLeft: "auto",
  marginRight: "50px",
  [MEDIA_QUERIES.w.ltSm]: {
    marginLeft: 0,
    width: "auto",
    display: "flex",
    alignItems: "center",
  },
  ".tooltip-inner": {
    padding: "5px",
  },
});

const NoImageFlex = styled(FlexContainer)({
  width: "48px",
  height: "48px",
  border: `1px solid ${COLORS.secondary}`,
});

const ThumbnailImage = styled("img")({
  width: "48px",
});

const CellContent = styled("div")({
  minWidth: "50px",
});

export { SwitchWrapper, NoImageFlex, ThumbnailImage, CellContent };
