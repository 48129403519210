import React from "react";
import _ from 'lodash'
import AdditionalQuestions from "manager/components/DealSetupSettings/AdditionalQuestions";
import ApplicationFees from "manager/components/DealSetupSettings/ApplicationFees";
import { LeaseRentOptionsContainer } from "manager/components/DealSetupSettings/LeaseRentOptions";
import PortfolioDealSetupSettings from "manager/components/DealSetupSettings/PortfolioDealSetupSettings";
import RequiredAttachments from "manager/components/DealSetupSettings/RequiredAttachments";
import RequiredAttachmentsBackgroundSetup from "manager/components/DealSetupSettings/RequiredAttachmentsBackgroundSetup";
import RequiredAttachmentsUnverifiedIncome from "manager/components/DealSetupSettings/RequiredAttachmentsUnverifiedIncome";
import ScreeningForApplicantsAndOccupants from "manager/components/DealSetupSettings/ScreeningForApplicantsAndOccupants";
import ScreeningForGuarantors from "manager/components/DealSetupSettings/ScreeningForGuarantors";
import { PortfolioHoldingDepositSettings } from "manager/components/HoldingDeposit";
import { PrescreeningSettings } from "manager/components/Prescreening";
import SettingsTabs from "manager/components/SettingsTabs";
import { PORTFOLIO_SETTINGS_TABS } from 'manager/config/routes'
import SettingsPage from "manager/templates/SettingsPage";
import LoaderContainer, {
  BigLoadingSpinner,
} from "shared/components/LoaderContainer";
import { conditionalItem } from "shared/utils/array";
import { ApplicantWorkflowStudio } from "shared/components/vega/ApplicantWorkflow";
import { LumenStudio } from "shared/components/vega/LumenStudio";
import { PortfolioSettingsProps } from "./interfaces";

const PortfolioSettings = ({
  backLink,
  portfolio,
  loading,
  tabName,
  onTabChange,
  enableHoldingDeposit,
  isPrescreeningEnabled,
  isYardiLROEnabled,
}: PortfolioSettingsProps) => {
  const portfolioName = portfolio?.name;
  const dealSetupId = portfolio?.dealSetup;
  const prescreeningDealSetupId = portfolio?.prescreeningDealSetup;

  return (
    <LoaderContainer loading={loading} Loader={<BigLoadingSpinner />}>
      <SettingsPage
        title={`${portfolioName} Portfolio Settings`}
        subtitle="Please set settings for this portfolio"
        backLink={backLink}
      >
        <SettingsTabs
          activeTab={tabName}
          onTabChange={onTabChange}
          items={[
            {
              key: PORTFOLIO_SETTINGS_TABS.screeningSetup,
              label: "Screening Setup",
              node: (
                <PortfolioDealSetupSettings dealSetupId={dealSetupId}>
                  {/* @ts-ignore */}
                  <ScreeningForApplicantsAndOccupants />
                  <ScreeningForGuarantors />
                </PortfolioDealSetupSettings>
              ),
            },
            {
              key: PORTFOLIO_SETTINGS_TABS.applicationSetup,
              label: "Application Setup",
              node: (
                <PortfolioDealSetupSettings dealSetupId={dealSetupId}>
                  <ApplicationFees />
                  <AdditionalQuestions />
                  <RequiredAttachments />
                  <RequiredAttachmentsUnverifiedIncome />
                  <RequiredAttachmentsBackgroundSetup />
                </PortfolioDealSetupSettings>
              ),
            },
            {
              key: PORTFOLIO_SETTINGS_TABS.workflow,
              label: "Workflow",
              node: (
                <ApplicantWorkflowStudio
                  location={`${portfolio?.companySlug}#portfolio:${_.kebabCase(portfolio?.name)}`}
                  readOnlyLocations={[
                    portfolio?.companySlug
                  ]}
                />
              ),
            },
            {
              key: PORTFOLIO_SETTINGS_TABS.lumen,
              label: "Lumen",
              node: (
                <LumenStudio
                  location={`${portfolio?.companySlug}#portfolio:${_.kebabCase(portfolio?.name)}`}
                  readOnlyLocations={[
                    portfolio?.companySlug
                  ]}
                />
              ),
            },
            ...conditionalItem(enableHoldingDeposit, {
              key: PORTFOLIO_SETTINGS_TABS.applicationCharges,
              label: "Application Charges",
              node: <PortfolioHoldingDepositSettings />,
            }),
            ...conditionalItem(isPrescreeningEnabled, {
              key: PORTFOLIO_SETTINGS_TABS.prescreening,
              label: "Prequalification",
              node: (
                <PrescreeningSettings dealSetupId={prescreeningDealSetupId} />
              ),
            }),
            ...conditionalItem(isYardiLROEnabled, {
              key: PORTFOLIO_SETTINGS_TABS.yardiLROSetup,
              label: "Yardi Lease Rent Options",
              node: <LeaseRentOptionsContainer dealSetupId={dealSetupId} />,
            }),
          ]}
        />
      </SettingsPage>
    </LoaderContainer>
  );
};

export default PortfolioSettings;
