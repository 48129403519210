import React from "react";

import { useFormikContext } from "formik";

import {
  ConfigurationPanel,
  ConfigurationSection,
} from "manager/components/Lumen";
import {
  LUMEN_SECTION_TYPE,
  LUMEN_SECTION_TYPE_LABELS,
} from "manager/config/lumen.config";
import { RadioGroup } from "shared/components/Form";
import { SwitchTitle } from "shared/components/Form/SwitchTitle";

import { LumenFormulaFormikValues } from "./interfaces";

const LumenFormulaStepVerifications = () => {
  const { values, setFieldValue } =
    useFormikContext<LumenFormulaFormikValues>();
  return (
    <>
      <strong>
        <SwitchTitle
          id="useVerifications"
          title={LUMEN_SECTION_TYPE_LABELS[LUMEN_SECTION_TYPE.VERIFICATIONS]}
          value={values.useVerifications}
          onChange={(value) => setFieldValue("useVerifications", value)}
          noPadding
        />
      </strong>
      {/* @ts-ignore */}
      <ConfigurationPanel isEnabled={!!values.useVerifications}>
        <ConfigurationSection>
          <div className="configuration-section-header">
            Should identity verification be taken into account when evaluating
            an application?
          </div>
          <div className="configuration-section-body">
            <RadioGroup
              options={[
                {
                  value: true,
                  label: (
                    <>
                      <strong>Yes</strong> - Failure to verify identity will
                      lead to a negative recommendation
                    </>
                  ),
                },
                {
                  value: false,
                  label: (
                    <>
                      <strong>No</strong> - Failure to verify identity won’t
                      impact the recommendation
                    </>
                  ),
                },
              ]}
              id="verifyIdentity"
              name="verifyIdentity"
              block
            />
          </div>
        </ConfigurationSection>
      </ConfigurationPanel>
    </>
  );
};

export default LumenFormulaStepVerifications;
