import { useQuery } from "react-query";

import type { ListOfAssetRequestParams } from "renter/interfaces/api/assetService";

import api from "renter/lib/api";
import { NOTIFICATIONS } from "shared/config/constants";
import { openNotification } from "shared/utils/ui";

const GET_ASSETS_KEY = "getAssets";

export const useGetAssets = (
  requestParams: ListOfAssetRequestParams,
  enable: boolean
) => {
  const { data, isLoading, refetch } = useQuery(
    [GET_ASSETS_KEY, requestParams.person, requestParams.types],
    () => api.getAssets(requestParams),
    {
      enabled: enable,
      onError: () =>
        openNotification("Failed to get the assets", NOTIFICATIONS.error),
    }
  );

  return {
    refetchAssets: refetch,
    assetData: data,
    isAssetsDataLoading: isLoading,
  };
};
