import React from "react";

import {
  COLORS,
  VERIFICATION_TYPE_LABELS,
  PERSONA_VERIFICATION_STATUS,
  VERIFICATION_OF_RESIDENCY_STATUS,
  PERSONA_VERIFICATION_STATUS_TYPES,
  VERIFICATION_OF_RESIDENCY_STATUS_TYPES,
  VERIFICATION_TYPES,
} from "shared/config/constants";
import { formatDate, getValueOrNA } from "shared/utils/ui";

import Icon from "../Icon";
import { RegularText } from "../Typography";

import { StatusWrapper, StatusLabel, IconLabelStatus } from "./styled";
import VerificationStatusShell from "./VerificationStatusShell";

const VERIFICATION_STATUS_VIEW_INFO = Object.freeze({
  [PERSONA_VERIFICATION_STATUS.COMPLETED]: {
    label: "Verified",
    Icon: Icon.CheckGreenBig,
    IconInline: Icon.CheckGreenSmall,
    color: COLORS.violetTheme.lightGreen,
  },
  [PERSONA_VERIFICATION_STATUS.PASSED]: {
    label: "Verified",
    Icon: Icon.CheckGreenBig,
    IconInline: Icon.CheckGreenSmall,
    color: COLORS.violetTheme.lightGreen,
  },
  [PERSONA_VERIFICATION_STATUS.FAILED]: {
    label: "Unverified",
    Icon: Icon.CrossRedBig,
    IconInline: Icon.CrossRedSmall,
    color: COLORS.dangerRed,
  },
  [PERSONA_VERIFICATION_STATUS.EXPIRED]: {
    label: "Expired",
    Icon: Icon.OrangeExclamation,
    IconInline: Icon.OrangeExclamationSmall,
    color: COLORS.midOrange,
  },
  [VERIFICATION_OF_RESIDENCY_STATUS.SUBMITTED]: {
    label: "Submitted",
    Icon: Icon.CheckGreenBig,
    IconInline: Icon.CheckGreenSmall,
    color: COLORS.violetTheme.lightGreen,
  },
  [VERIFICATION_OF_RESIDENCY_STATUS.WAITING]: {
    label: "Not submitted",
    Icon: null,
    IconInline: null,
    color: COLORS.dangerRed,
  },
  [VERIFICATION_OF_RESIDENCY_STATUS.STARTED]: {
    label: "Not submitted",
    Icon: null,
    IconInline: null,
    color: COLORS.dangerRed,
  },
  [VERIFICATION_OF_RESIDENCY_STATUS.NOTIFIED]: {
    label: "Notified",
    Icon: null,
    IconInline: null,
    color: COLORS.midOrange,
  },
  [VERIFICATION_OF_RESIDENCY_STATUS.VERIFIED]: {
    label: "Notified",
    Icon: null,
    IconInline: null,
    color: COLORS.midOrange,
  },
});

/**
 * Component that displays the verification status details.
 */

export interface IVerificationStatus {
  type: VERIFICATION_TYPES;
  status:
    | PERSONA_VERIFICATION_STATUS_TYPES
    | VERIFICATION_OF_RESIDENCY_STATUS_TYPES;
  verificationDate?: string;
  inline?: boolean;
  showTitle?: boolean;
}

const VerificationStatus = ({
  type,
  status,
  verificationDate = undefined,
  inline = false,
  showTitle = true,
}: IVerificationStatus) => {
  const statusInfo = VERIFICATION_STATUS_VIEW_INFO[status];
  const typeLabel = VERIFICATION_TYPE_LABELS[type];

  return (
    <>
      {inline && (
        <VerificationStatusShell
          verificationType={type}
          typeLabel={typeLabel}
          StatusIcon={statusInfo?.IconInline}
          statusLabel={statusInfo?.label}
          verificationDate={verificationDate}
          showTitle={showTitle}
        />
      )}
      {!inline && (
        <StatusWrapper>
          <IconLabelStatus>
            {statusInfo?.Icon && (
              <statusInfo.Icon width="32" height="32" className="status-icon" />
            )}
            <StatusLabel color={statusInfo?.color}>
              {getValueOrNA(statusInfo?.label)}
            </StatusLabel>
          </IconLabelStatus>
          <RegularText strong>On {formatDate(verificationDate)}</RegularText>
        </StatusWrapper>
      )}
    </>
  );
};

export default VerificationStatus;
