import React, { useState } from 'react'
import { Toggle } from 'shared/components/vega/Toggle'
import { HistoryEntries } from 'shared/components/vega/ExpressionEngine'
import LumenV2Header from './LumenV2Header'
import LumenSummary from './LumenSummary'
import { findLumenResult } from './utils'

const LumenV2 = ({ lumenRun, applicantId = undefined, detailed = false, summary = false, card = false }) => {
  const [resolvedDetailed, setDetailed] = useState(false)

  if (summary) {
    return <LumenSummary lumenRun={lumenRun} />
  }

  const result = findLumenResult(lumenRun, applicantId)

  return (
    <div className='vega container'>
      <LumenV2Header lumenRun={lumenRun} result={result} card={card} />

      {lumenRun?.error && !card && <div className='vega content info error'>
        <span className='vega info error'>{lumenRun?.error?.message || lumenRun?.error?.errorMessage}</span>
      </div>}

      {result?.evaluation && !card && <div className='vega content'>
        {detailed && <div className='vega actions'>
          <Toggle
            label='Show Details'
            checked={resolvedDetailed}
            onChange={() => setDetailed(!resolvedDetailed)}
          />
        </div>}

        {result?.evaluation?.history && <HistoryEntries entries={result.evaluation?.history} detailed={resolvedDetailed} />}
      </div>}
    </div>
  )
}

export default LumenV2
