import {
  BACKSPACE_KEY,
  colors,
  isDevelopmentEnv,
} from "shared/config/constants";
import { useOnUnmountRegistry } from "shared/hooks";
import { listenToChildrenChange } from "shared/utils/dom";

// NOTE: Regex that have two digits e.g. 05 and 11/05
const regex1 = /^([0-9]{2}\/)?[0-9]{2}$/;

// NOTE: Regex that have one digit and "/" e.g. 5/ and 11/5/
const regex2 = /^([0-9]{2}\/)?[0-9]\/$/;

// NOTE: Regex that have multiple "/" e.g. 05// and 11/05///
const regex3 = /^([0-9]{2}\/)?[0-9]{2}\/{2,}$/;

// NOTE: Regex that have one or more "/" at the end of date e.g. 05/05/2000/ 05/05/2000///
const regex4 = /^([0-9]{2}\/){2}([0-9]{4}\/{1,})$/;

const formatDate = (e) => {
  const { value: inputValue } = e.target;

  // Don't add anything on Backspace
  if (e.key !== BACKSPACE_KEY) {
    if (inputValue.match(regex1)) {
      e.target.value = inputValue.concat("/");
    } else if (inputValue.match(regex2)) {
      e.target.value = inputValue
        .split("/")
        .map((dateItem) =>
          dateItem.length === 1 ? "0".concat(dateItem) : dateItem
        )
        .join("/");
    } else if (inputValue.match(regex3)) {
      e.target.value = inputValue.replace(/\/{2,}/g, "/");
    } else if (inputValue.match(regex4)) {
      e.target.value = inputValue
        .split("/")
        .filter((dateItem) => dateItem !== "")
        .join("/");
    }
  }
};

const useGetCalendarContainer = (monthPicker) => {
  const { registerOnUnmountAction } = useOnUnmountRegistry();

  const getDatePickerContainer = () => {
    const newDiv = document.createElement("div");
    document.body.appendChild(newDiv);
    let currentInput;
    let savedInput;

    const callback = () => {
      currentInput = newDiv.querySelector(".ant-calendar-input");
      if (savedInput !== currentInput) {
        if (savedInput) {
          savedInput.removeEventListener("keyup", formatDate);
          savedInput = null;
        }
        if (currentInput) {
          currentInput.addEventListener("keyup", formatDate);
          savedInput = currentInput;
        }
      }
    };

    const observer = listenToChildrenChange(newDiv, callback);

    registerOnUnmountAction(() => {
      observer.disconnect();
      newDiv.remove();
      if (savedInput) {
        savedInput.removeEventListener("keyup", formatDate);
        savedInput = null;
      }
    });

    return newDiv;
  };

  return monthPicker ? undefined : getDatePickerContainer;
};

const checkDevIncompatibility = ({ setFieldValue, onChange }) => {
  if (isDevelopmentEnv) {
    if (setFieldValue && onChange) {
      // eslint-disable-next-line no-console
      console.warn("setFieldValue() is not compatible with onChange()");
    }

    if (setFieldValue) {
      // eslint-disable-next-line no-console
      console.warn(
        `setFieldValue() is used by outdated API, please consider using onChange() instead`
      );
    }
  }
};

const PaperPropsSx = {
  "& .MuiPickersDay-root": {
    "&.Mui-selected": {
      backgroundColor: colors.secondary[500],
      "&:hover": {
        backgroundColor: colors.secondary[500],
      },
      "&:focus": {
        backgroundColor: colors.secondary[500],
      },
    },
    "&.Mui-disabled": {
      backgroundColor: "#F9FAFC",
      cursor: "not-allowed",
      pointerEvents: "auto",
    },
  },
  "& .MuiButtonBase-root-MuiPickersDay-root.Mui-selected": {
    backgroundColor: colors.secondary[500],
  },
  "& .MuiPickersToolbar-root-MuiDatePickerToolbar-root": {
    backgroundColor: colors.secondary[500],
  },
  "& .MuiDialogActions-root": {
    "& .MuiButton-root": {
      color: colors.secondary[500],
    },
  },
  "& .MuiYearPicker-root": {
    "& .PrivatePickersYear-modeDesktop": {
      "& .Mui-selected": {
        backgroundColor: colors.secondary[500],
      },
    },
  },
  "& .MuiMonthPicker-root": {
    "& .MuiTypography-h5": {
      backgroundColor: colors.secondary[500],
      "&:hover": {
        backgroundColor: colors.secondary[500],
      },
      "&:focus": {
        backgroundColor: colors.secondary[500],
      },
    },
  },
};

const DialogPropsSx = {
  "& .MuiPickersDay-root": {
    "&.Mui-selected": {
      backgroundColor: colors.secondary[500],
      "&:hover": {
        backgroundColor: colors.secondary[500],
      },
      "&:focus": {
        backgroundColor: colors.secondary[500],
      },
    },
    "&.Mui-disabled": {
      backgroundColor: "#F9FAFC",
      cursor: "not-allowed",
      pointerEvents: "auto",
    },
  },
  "& .MuiButtonBase-root-MuiPickersDay-root.Mui-selected": {
    backgroundColor: colors.secondary[500],
  },
  "& .MuiPickersToolbar-root": {
    backgroundColor: colors.secondary[500],
  },
  "& .MuiIconButton-sizeMedium": {
    color: "white",
  },
  "& .MuiTypography-overline ": {
    color: "white",
  },
  "& .MuiTypography-h4": {
    color: "white",
  },
  "& .MuiDialogActions-root": {
    "& .MuiButton-root": {
      color: colors.secondary[500],
    },
  },
  "& .MuiYearPicker-root": {
    "& .PrivatePickersYear-modeMobile": {
      "& .Mui-selected": {
        backgroundColor: colors.secondary[500],
      },
    },
  },
  "& .MuiMonthPicker-root": {
    "& .MuiTypography-h5": {
      backgroundColor: colors.secondary[500],
      "&:hover": {
        backgroundColor: colors.secondary[500],
      },
      "&:focus": {
        backgroundColor: colors.secondary[500],
      },
    },
  },
  "& .MuiPickersDay-root Mui-disabled": {
    backgroundColor: colors.error[500],
  },
};

const TextInputSx = {
  label: {
    color: "rgb(128, 126, 141) !important",
    fontSize: "15px",
    fontFamily: "Work Sans,sans-serif",
  },
  input: {
    color: "rgb(45, 41, 67)",
    fontSize: "15px",
    fontFamily: "Work Sans,sans-serif",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: colors.secondary[500],
    },
  },
  "& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused": {
    color: colors.secondary[500],
  },
  "& .MuiButtonBase-root-MuiPickersDay-root.Mui-selected": {
    backgroundColor: colors.secondary[500],
  },
  "& .MuiFormLabel-root": {
    "& .MuiFormLabel-asterisk": {
      color: "rgb(45, 41, 67) !important",
      fontSize: "15px",
      fontFamily: "Work Sans,sans-serif",
    },
  },
};

export {
  useGetCalendarContainer,
  formatDate,
  checkDevIncompatibility,
  PaperPropsSx,
  DialogPropsSx,
  TextInputSx,
};
