import React, { useContext, useState } from 'react'

import { Dropdown, Menu } from 'antd'
import type { ExpandIconProps } from 'antd/lib/table'
import _ from 'lodash'
import capitalize from 'lodash/capitalize'
import isEmpty from 'lodash/isEmpty'

import type { IncomeStream } from 'renter/interfaces/api/income'
import FlexContainer from 'shared/components/FlexContainer'
import Icon from 'shared/components/Icon'
import Tile from 'shared/components/Tile'
import { CollapseButton } from 'shared/components/Tile/styled'
import { RegularText, SmallText } from 'shared/components/Typography'
import {
  APPLICATION_STATUSES,
  isProductionEnv,
  MAX_INCOMES_NUMBER_IN_STREAM,
} from 'shared/config/constants'
import { usePermissions } from 'shared/hooks'
import useModal from 'shared/hooks/useModal'
import type { OcrResult } from 'shared/interfaces/api/document'

import { isManagerApp } from 'shared/utils/auth'
import { getFraudRiskTextAndColor } from 'shared/utils/document'
import { printDollarsFromCents } from 'shared/utils/dollar-print'
import { mapIncomeStreamVerificationMethodToSource } from 'shared/utils/employmentAndIncome'

import ApplicationContext from '../../applicationContext'

import { EditIncomeStreamModal } from './EditIncomeStreamModal'
import { StreamDetailTable } from './StreamDetailTable'
import {
  IncomeStreamsTable as StyledTable,
  IncomeStreamHeader,
  MoreLink,
  StyledMenu,
} from './styled'

interface IncomeStreamsTableProps {
  streams: IncomeStream[]
  type: 'excluded' | 'included'
  ocrResults?: OcrResult
  canViewOcrResults: boolean
  canEditOcrResults: boolean
}

const incomeStreamTableId = 'income-streams-table'

export const IncomeStreamsTable = ({
  streams,
  type,
  ocrResults,
  canViewOcrResults,
  canEditOcrResults,
}: IncomeStreamsTableProps) => {
  const {
    application: { applicantVrn, dealDecisionIsMade, status },
  } = useContext(ApplicationContext)
  const { canEditIncomeStreams } = usePermissions()
  const [viewExclusion, setViewExclusion] = useState({})
  const [expandedRowKeys, setExpandedRowKeys] = useState([])
  const renderFraudRisk =
    isManagerApp() && canViewOcrResults && type === 'excluded'
  const renderExclusionReasons = type === 'excluded'

  const getHighestFraudRiskLevel = (ocrResultIds) => {
    const results = _.map(ocrResultIds, (sourceOcrResult) =>
      _.find(ocrResults, { id: sourceOcrResult }),
    )
    const riskLevels = _.chain(results).map('fraudRiskLevel').value()

    if (_.includes(riskLevels, 'VERY_HIGH')) {
      return 'VERY_HIGH'
    }
    if (_.includes(riskLevels, 'HIGH')) {
      return 'HIGH'
    }
    if (_.includes(riskLevels, 'MEDIUM')) {
      return 'MEDIUM'
    }
    if (_.includes(riskLevels, 'LOW')) {
      return 'LOW'
    }
    return 'UNSUPPORTED'
  }

  const determineFraudRisk = (ocrResultIds) => {
    const riskLevel = getHighestFraudRiskLevel(ocrResultIds)
    return getFraudRiskTextAndColor(riskLevel)
  }

  const handleMoreClick = (identifier) => {
    setViewExclusion({
      ...viewExclusion,
      [identifier]: !viewExclusion[identifier],
    })
  }

  const { openModalDialog } = useModal()

  const columns = [
    {
      title: <IncomeStreamHeader>Source</IncomeStreamHeader>,
      dataIndex: 'source',
      render: (_, { description }) => <RegularText>{description}</RegularText>,
    },
    ...(renderExclusionReasons
      ? [
          {
            title: <IncomeStreamHeader>Exclusion Reason(s)</IncomeStreamHeader>,
            dataIndex: 'excludedReasons',
            render: (_text, { identifier, excludedReasons }) => {
              return (
                <FlexContainer flexDirection="row">
                  <ul
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '8px',
                      paddingLeft: '20px',
                      margin: 0,
                    }}
                  >
                    {_.map(excludedReasons, (reason, index) => (
                      <li
                        key={index}
                        style={{
                          listStyleType: 'unset',
                          display:
                            Number(index) === 0 || viewExclusion[identifier]
                              ? 'list-item'
                              : 'none',
                        }}
                      >
                        <RegularText>{reason}</RegularText>
                      </li>
                    ))}
                  </ul>
                  <MoreLink
                    style={{
                      display:
                        excludedReasons &&
                        excludedReasons.length > 1 &&
                        !viewExclusion[identifier]
                          ? 'unset'
                          : 'none',
                    }}
                    onClick={(e) => {
                      e.stopPropagation()
                      handleMoreClick(identifier)
                    }}
                  >
                    View
                  </MoreLink>
                </FlexContainer>
              )
            },
          },
        ]
      : []),
    ...(renderFraudRisk
      ? [
          {
            title: (
              <FlexContainer alignItems="center" flexDirection="row" gap="4px">
                <IncomeStreamHeader>Fraud Risk</IncomeStreamHeader>
                <Icon.BlueQuestionMark
                  height={14}
                  cursor="pointer"
                  width={14}
                  onClick={() =>
                    window?.Intercom?.(
                      'showArticle',
                      isProductionEnv ? 9621394 : 9641772,
                    )
                  }
                />
              </FlexContainer>
            ),
            dataIndex: 'fraudRisk',
            render: (_text, { incomes }) => {
              const sourceOcrResults = _.map(
                incomes,
                (income) => income.sourceOcrResult,
              )
              const fraudRisk = determineFraudRisk(sourceOcrResults)
              return (
                <RegularText style={{ color: fraudRisk.color }}>
                  {fraudRisk.text}
                </RegularText>
              )
            },
          },
        ]
      : []),
    {
      title: <IncomeStreamHeader>Verification</IncomeStreamHeader>,
      dataIndex: 'verification',
      render: (_, { annotationDetail, streamVerificationMethod }) => (
        <FlexContainer flexDirection="column">
          <RegularText>
            {mapIncomeStreamVerificationMethodToSource(
              streamVerificationMethod,
            )}
          </RegularText>
          {annotationDetail?.lastModifiedBy?.name && (
            <RegularText>
              (Manually {type === 'excluded' ? 'excluded' : 'included'} by{' '}
              {annotationDetail.lastModifiedBy.name})
            </RegularText>
          )}
        </FlexContainer>
      ),
    },
    {
      title: <IncomeStreamHeader>Pay Frequency</IncomeStreamHeader>,
      dataIndex: 'payFrequency',
      render: (_, { frequency }) => (
        <RegularText>{capitalize(frequency)}</RegularText>
      ),
    },
    {
      title: <IncomeStreamHeader>Projected Annual Income</IncomeStreamHeader>,
      dataIndex: 'income',
      render: (_, { estimatedAnnualGrossAmount, estimatedAnnualNetAmount }) => (
        <RegularText>
          {printDollarsFromCents(
            estimatedAnnualGrossAmount ?? estimatedAnnualNetAmount ?? 0,
          )}{' '}
          {type === 'included' &&
            `(${estimatedAnnualGrossAmount ? 'Gross' : 'Net'})`}
        </RegularText>
      ),
    },
    ...(isManagerApp()
      ? [
          {
            title: '',
            width: 50,
            render: (_, stream) => {
              const menuItems = [
                ...(!dealDecisionIsMade &&
                status !== APPLICATION_STATUSES.inProgress &&
                canEditIncomeStreams
                  ? [
                      <Menu.Item
                        key={stream.identifier}
                        onClick={() => {
                          openModalDialog(EditIncomeStreamModal, {
                            applicantVrn,
                            stream,
                            type,
                          })
                        }}
                      >{`${
                        type === 'excluded' ? 'Include' : 'Exclude'
                      } Income Stream`}</Menu.Item>,
                    ]
                  : []),
              ]

              if (menuItems.length === 0) return null

              return (
                <Dropdown
                  getPopupContainer={(triggerNode) =>
                    document.getElementById(incomeStreamTableId) ?? triggerNode
                  }
                  placement="bottomRight"
                  overlay={
                    <StyledMenu
                      onClick={({ domEvent }) => domEvent.stopPropagation()}
                    >
                      {menuItems.map((item) => item)}
                    </StyledMenu>
                  }
                  trigger={['click']}
                >
                  <Icon.DropdownIcon
                    height={50}
                    style={{
                      cursor: 'pointer',
                      display: 'block',
                      margin: 'auto',
                      transform: 'rotate(90deg)',
                    }}
                    width={24}
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                  />
                </Dropdown>
              )
            },
          },
          {
            title: '',
            render: null,
          },
        ]
      : []),
  ]

  return (
    <>
      {!isEmpty(streams) && (
        <Tile.Inner noPaddingTop>
          <FlexContainer alignItems="center" flexDirection="row" gap="8px">
            <Icon.InfoBlueIcon height={16} />
            <SmallText>
              {`VERO displays up to ${MAX_INCOMES_NUMBER_IN_STREAM} supporting documents or transactions for
              each income stream.`}
            </SmallText>
          </FlexContainer>
          <StyledTable
            // @ts-ignore
            id={incomeStreamTableId}
            columns={columns}
            expandIcon={({
              record,
              expanded,
              onExpand,
            }: ExpandIconProps<IncomeStream>) =>
              !isEmpty(record?.incomes) ? (
                <CollapseButton
                  collapsed={!expanded}
                  collapsibleOnDesktop
                  collapsibleOnMobile
                  onClick={onExpand}
                  style={{ float: 'right' }}
                >
                  <Icon.ChevronDownIcon
                    style={{ display: 'block', margin: 'auto' }}
                  />
                </CollapseButton>
              ) : undefined
            }
            expandIconAsCell={false}
            expandIconColumnIndex={columns.length - 1}
            expandedRowKeys={expandedRowKeys}
            onRow={(record: IncomeStream) => ({
              onClick: () => {
                if (isEmpty(record?.incomes)) return
                if (expandedRowKeys.includes(record.id))
                  setExpandedRowKeys((prev) =>
                    prev.filter((id) => record.id !== id),
                  )
                else setExpandedRowKeys((prev) => [...prev, record.id])
              },
            })}
            rowClassName={(record: IncomeStream) =>
              isEmpty(record?.incomes) && 'not-expandable'
            }
            expandRowByClick
            expandedRowRender={(stream) => (
              <StreamDetailTable
                incomeStream={stream as IncomeStream}
                canViewOcrResults={canViewOcrResults}
                canEditOcrResults={canEditOcrResults}
              />
            )}
            dataSource={streams}
            noBorderBottom
            noClickableRows
            pagination={false}
            rowKey={({ id }) => id}
            scroll={{ x: true }}
            size="middle"
          />
        </Tile.Inner>
      )}
      {isEmpty(streams) && (
        <Tile.Inner key={0} data-testid={`income-stream-${0}`} noPaddingTop>
          <FlexContainer
            alignItems="center"
            flexDirection="row"
            justifyContent="center"
            gap="8px"
          >
            <Icon.InfoBlueIcon />
            <RegularText>
              {type === 'excluded'
                ? 'There is no excluded income available for this application.'
                : 'There is no income available for this application.'}
            </RegularText>
          </FlexContainer>
        </Tile.Inner>
      )}
    </>
  )
}
