const applications = "/applicant/applications/";
const application = `${applications}:applicationId/`;

const APPLICATION_ROUTES = {
  applications,
  applicationInvitation: application,
  getActiveUserApplicantType: `${application}get-type/`,
  setApplicantType: `${application}set-type/`,
  getApplicationIncomeAssetVerification: `${application}get-income-asset/`,
  getApplicationBackgroundCheck: `${application}background-credit-check/`,
  updateApplicantReasonToStay: `${application}update-reason-to-stay/`,
  resetBackgroundAndCreditCheck: `${application}reset-bcc/`,
  completeCreditAndBackground: `${application}complete-credit-and-background-check/`,
  submitApplication: `${application}submit/`,
  getApplicationPayment: `${application}payment/`,
  getApplicationSummary: `${application}full/`,
  conditionalRequest: `${application}conditional-request/`,
  withdrawApplication: `${application}withdraw/`,
  getApplicantCheckInfo: `${application}get-applicant-check-info/`,
  updateApplicantCheckInfo: `${application}update-applicant-check-info/`,
  applicationMarketingSources: `${application}marketing-sources/`,
  getActivePlaidApplication: `${applications}get-active-plaid-application/`,
  setScreeningAgreementDate: `${application}set-document-agreement-date/`,
  inviteDependents: `${application}add-dependents/`,
  getIncomeSkipReasons: `${application}income-skip-reasons/`,
  setIncomeSkipReasons: `${application}skip_block_deal_submit_if_income_missing/`,
  trackCurrentApplicationStep: `${application}start-step/`,
  beginPrequalification: `${application}begin-prequalification/`,
  areVettGuestsEnabled: `${application}are-vett-guests-enabled/`,
};

const managerApplications = "/applications/";
const managerApplication = `${managerApplications}:applicationId/`;

const MANAGER_APPLICATION_ROUTES = {
  editIncomeAndAssets: `${managerApplication}edit-income-assets/`,
  getFullApplication: `${managerApplication}full/`,
  generateApplicationPDF: `${managerApplication}generate-pdf/`,
  moveApplication: `${managerApplications}:applicationId/move/`,
  viewFullSSN: `${managerApplication}full-ssn/`,
};

const AUTH_ROUTES = {
  loginSelfSignedDeepLink: "/user/self-signed-deeplink/",
  userProfile: "/user/profile/",
  setPassword: "/user/invitation/",
  validateSetPasswordToken: "/user/invitation/",
  sendNewInvitationLink: "/user/request-new-link/",
  sendPasswordRecoveryEmail: "/user/recover-password/",
  resetPassword: "/user/reset-password/",
  validateResetPasswordToken: "/user/reset-password/",
  applicantUserProfile: "/applicant/profile/",
  getApplicantEmergencyContacts: "/applicant/emergency-contacts/",
  getApplicantResidentialHistory: "/applicant/residential-histories/",
  getApplicantEmploymentHistory: "/applicant/employment-histories/",
  getApplicantReferences: "/applicant/references/",
};

const CONFIG_ROUTES = {
  ssoConfig: `/config/sso/`,
  pactSafeConfig: `/config/pactsafe/`,
};

const ARGYLE_ROUTES = {
  argyleEmployerSearch: `/argyle/items/`,
};

const companies = "/companies/";
const company = `${companies}:id/`;
const companyPermissions = `${companies}permissions/`;

const COMPANY_ROUTES = {
  companies,
  company,
  companyPermissions,
};

const applicantDeals = "/applicant/deals/";
const applicantDeal = `${applicantDeals}:id/`;

const deals = "/deals/";
const deal = `${deals}:id/`;
const dealsToMove = `${deals}move/`;
const inviteApplicant = `${deals}invite-applicant/`;
const inviteApplicantsToDeal = `${deals}invite-applicant/`;
const dealsCount = `${deals}applications-summary/`;
const assignDealToUnit = `${deal}assign-unit/`;
const cancelDeal = `${deal}cancel/`;
const approveDeal = `${deal}approve/`;
const declineDeal = `${deal}decline/`;
const conditionallyApproveDeal = `${deal}conditionally-approve/`;
const conditionallyProceed = `${deal}conditionally-proceed/`;
const removeApplicationFromDeal = `${deal}remove-application/`;
const contactManger = `${applicantDeal}contact-manager/`;
const editDealRent = `${deal}edit-rent/`;
const recalculateLumen = `${deal}recalculate_lumen/`;
const dealUnitListing = `${deal}unit-listing/`;
const primaryApplicant = `${deal}primary-applicant/`;
const updateDealRevenueDetails = `${deal}update-deal-revenue-details/`;
const assignDealRevenueManagement = `${deal}assign-revenue-management/`;
const dealApplications = `${deal}applications/`;
const sendToPms = `${deal}manual-send-to-pms/`;
const resendToPms = `${deal}resend-to-pms/`;

const generateDealPDF = `${deal}generate-pdf/`;

const DEAL_ROUTES = {
  applicantInviteApplicants: `${applicantDeal}invite/`,
  inviteApplicant,
  inviteApplicantsToDeal,
  applicantDeals,
  applicantDeal,
  deals,
  deal,
  dealsToMove,
  assignDealToUnit,
  cancelDeal,
  approveDeal,
  declineDeal,
  conditionallyApproveDeal,
  conditionallyProceed,
  removeApplicationFromDeal,
  generateDealPDF,
  contactManger,
  editDealRent,
  recalculateLumen,
  dealUnitListing,
  primaryApplicant,
  updateDealRevenueDetails,
  assignDealRevenueManagement,
  dealApplications,
  sendToPms,
  resendToPms,
  dealsCount,
};

const EMPLOYEE_ROUTES = {
  getEmployees: "/employees/",
  getEmployee: "/employees/:employeeId/",
  deactivateEmployee: "/employees/:employeeId/deactivate/",
  employeePortfoliosProperties:
    "/employees/:employeeId/portfolios-and-properties/",
  assignPortfoliosProperties: "/employees/:employeeId/assign/",
  unassignPortfoliosProperties: "/employees/:employeeId/unassign/",
  exportEmployees: "/employees/export/",
  editEmployee: "/employees/:employeeId/",
};

const properties = "/properties/";
const allPropertiesSimple = "/properties/all/";
const propertiesWithInvitation = `${properties}with-invitation/`;
const propertiesWithLead = `${properties}with-lead/`;
const property = `${properties}:propertyId/`;

const assignPropertyToPortfolio = `${property}assign_portfolio/`;
const unassignPropertyFromPortfolio = `${property}unassign_portfolio/`;
const assignEmployees = `${property}assign_employees/`;
const listEmployees = `${property}list-property-employees/`;
const listEmployeesWithAssignments = `${property}list_employees/`;
const copyDealSetupFromPortfolio = `${property}copy-deal-setup-from-portfolio/`;
const resetToPortfolioSettings = `${property}reset-to-portfolio-settings/`;
const copyPrescreeningDealSetupFromPortfolio = `${property}copy-prescreening-deal-setup-from-portfolio/`;
const resetToPrescreeningPortfolioSettings = `${property}reset-to-prescreening-portfolio-settings/`;
const propertiesWithUnitListing = `${properties}list-names-and-addresses/`;
const uploadPropertyLogo = `${property}logo-upload/`;

// TODO (POD2-148): Remove the Perf postfix as soon as we have removed the old endpoints.
const propertiesPerf = "/performance/properties/";
const propertyPerf = `${propertiesPerf}:propertyId/`;
const listEmployeesPerf = `${propertyPerf}list-property-employees/`;

const PROPERTY_ROUTES = {
  properties,
  allPropertiesSimple,
  propertiesWithInvitation,
  propertiesWithLead,
  unassignPropertyFromPortfolio,
  property,
  assignPropertyToPortfolio,
  assignEmployees,
  listEmployees,
  listEmployeesWithAssignments,
  copyDealSetupFromPortfolio,
  resetPropertyToPortfolioSettings: resetToPortfolioSettings,
  copyPrescreeningDealSetupFromPortfolio,
  resetToPrescreeningPortfolioSettings,
  propertiesWithUnitListing,
  uploadPropertyLogo,
  propertiesPerf,
  listEmployeesPerf,
};

const ROLES_ROUTES = {
  listRoles: "/roles/",
  getRole: "/roles/:id/",
};

const TYPE_ROUTES = {
  states: "/types/states/",
  propertyTypes: "/types/properties/",
  requiredAttachmentTypes: "/types/required-attachments/",
  applicantTypes: "/types/applicants/",
  applicationStatuses: "/types/application-statuses/",
  dealStatuses: "/types/deal-statuses/",
  dealCategories: "/types/deal-categories/",
  countries: "/types/countries/",
  pets: "/types/pets/",
  unitStatuses: "/types/unit-statuses/",
  industries: "/types/industries/",
  knobRiderOptions: "/types/knob-rider-options/",
  windowGuardOptions: "/types/window-guard-options/",
  kidOptions: "/types/kid-options/",
  riderTypes: "/types/document-riders/",
  documentTypes: "/types/document-statuses/",
  livingSituationTypes: "/types/living-situation-options/",
  durationTypes: "/types/duration-options/",
  answerTypeOptions: "/types/additional-question-answer-type/",
  emergencyContactTypes: "/types/yardi-emergency-contact/",
  countyCriminalSearchSyncOptions: "/types/county-criminal-sync-type/",
};

const units = "/units/";
const getUnitsListForDealAssignment = "/units/list-for-deal-assignment/";
const allUnitsSimple = `${units}property/:propertyId/all/`;
const unit = `${units}:id/`;

const UNIT_ROUTES = {
  units,
  getUnitsListForDealAssignment,
  allUnitsSimple,
  unit,
};

const unitListings = "/unit-listings/";
const closeUnitListing = `${unitListings}:id/close/`;
const closeListingAndMarkUnitAsRented = `${unitListings}:id/close-and-mark-unit-as-rented/`;
const editUnitListing = `${unitListings}:id/`;

const UNIT_LISTING_ROUTES = {
  unitListings,
  closeUnitListing,
  editUnitListing,
  closeListingAndMarkUnitAsRented,
};

const portfolios = "/portfolios/";
const portfoliosList = "/portfolios/list-names/";
const portfolio = `${portfolios}:portfolioId/`;
const assignProperties = `${portfolio}assign_properties/`;
const portfoliosAssociated = `${portfolios}associated/`;
const portfolioDealSetup = `${portfolio}get_deal_setup/`;

const PORTFOLIO_ROUTES = {
  portfolios,
  portfoliosList,
  portfolio,
  assignProperties,
  portfoliosAssociated,
  portfolioDealSetup,
};

const dealSetups = "/deal-setups/";
const dealSetup = `${dealSetups}:id/`;

const DEAL_SETUP_ROUTES = {
  defaultDealSetup: `${dealSetups}company_deal_setup/`,
  defaultPrescreeningDealSetup: `${dealSetups}company_prescreening_deal_setup/`,
  dealSetups,
  dealSetup,
  updateDealSetup: `${dealSetup}save_changes/`,
  toggleRequiredQuestionActive: `${dealSetup}required-questions-toggle-is-active/`,
  enableLROConfig: `${dealSetup}enable-lro-config/`,
  updateLROConfig: `${dealSetup}lro-config/`,
};

const requiredAttachments = "/attachments/";
const requiredAttachment = `${requiredAttachments}:id/`;

const REQUIRED_ATTACHMENT_ROUTES = {
  requiredAttachments,
  requiredAttachment,
};
const incomeAndAssets = "/applicant/income-assets/";
const incomeAndAsset = `${incomeAndAssets}:id/`;

const INCOME_AND_ASSET_ROUTES = {
  getPlaidCredentials: `${application}prepare-plaid/`,
  submitPlaidMetadata: `${application}plaid-callback/`,
  getIncomeAndAsset: `${application}get-income-asset/`,
  skipIncomeAndAsset: `${application}skip-income-asset/`,
  confirmIncome: `${application}confirm-income/`,
  resetIncomeAndAssets: `${application}reset-income-asset/`,
  editIncomeAssetsNote: `${incomeAndAsset}edit-note/`,
};

const BACKGROUND_AND_CREDIT_CHECK_ROUTES = {
  getBackgroundAndCreditCheck: `${application}background-credit-check/`,
  getCreditCheckQuiz: `${application}retrieve-exam/`,
  submitSSN: `${application}enter-ssn/`,
  submitCreditCheckQuiz: `${application}evaluate-exam/`,
};

const DOCUMENT_TEMPLATES = {
  previewDocument: "/document-templates/:id/preview/",
  updateRiderType: "/company-templates/:id/update-rider-type/",
};

const MARKETING_SOURCES_BASE_URL = "/marketing-sources/";

const SOURCES_ROUTES = {
  marketingSources: `${MARKETING_SOURCES_BASE_URL}`,
};

const partners = "/partners/";
const PARTNER_ROUTES = {
  partners,
};

const DISCLAIMERS_BASE_URL = "/disclaimers/";
const DISCLAIMER_ROUTES = {
  getDisclaimer: `${DISCLAIMERS_BASE_URL}:code/`,
};

const INTERCOM_ROUTES = {
  getIntercomHash: "/person/me/token?provider=intercom",
};

const EXPERIAN_AUTH_ROUTES = {
  getExperianAuthToken: "/api/person/me/token?provider=experian",
};

const API_ROUTES = {
  ...APPLICATION_ROUTES,
  ...MANAGER_APPLICATION_ROUTES,
  ...AUTH_ROUTES,
  ...CONFIG_ROUTES,
  ...COMPANY_ROUTES,
  ...DEAL_ROUTES,
  ...EMPLOYEE_ROUTES,
  ...PROPERTY_ROUTES,
  ...ROLES_ROUTES,
  ...TYPE_ROUTES,
  ...UNIT_ROUTES,
  ...UNIT_LISTING_ROUTES,
  ...PORTFOLIO_ROUTES,
  ...DEAL_SETUP_ROUTES,
  ...REQUIRED_ATTACHMENT_ROUTES,
  ...INCOME_AND_ASSET_ROUTES,
  ...BACKGROUND_AND_CREDIT_CHECK_ROUTES,
  ...DOCUMENT_TEMPLATES,
  ...SOURCES_ROUTES,
  ...PARTNER_ROUTES,
  ...DISCLAIMER_ROUTES,
  ...INTERCOM_ROUTES,
  ...ARGYLE_ROUTES,
  ...EXPERIAN_AUTH_ROUTES,
};

/**
 * Base API url we use to compose the API routes for all backend calls.
 */
export const API_URL = process.env.REACT_APP_API_URL;
export const VEGA_BASE_URL = process.env.REACT_APP_VEGA_BASE_URL;
export default API_ROUTES;
