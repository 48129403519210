import { APPLICATION_ANSWER_TYPES } from "shared/config/constants";

export const QUESTION_KEYS = {
  pets: {
    question: "Do you have pets?",
    answerTypesKey: null,
    answerAdditionalInfoKey: "petComments",
  },
  smokes: { question: "Do you smoke?", answerTypesKey: null },
  vehicles: {
    question: "Do you currently have a vehicle?",
    answerTypesKey: null,
  },
  industry: {
    question: "Which industry do you work in?",
    answerTypesKey: APPLICATION_ANSWER_TYPES.industry,
  },
  windowGuard: {
    question: "Would you like window guards to be installed in the home?",
    answerTypesKey: APPLICATION_ANSWER_TYPES.windowGuard,
  },
  knobRider: {
    question: "Would you like stove knob covers to be installed in the home?",
    answerTypesKey: APPLICATION_ANSWER_TYPES.knobRider,
  },
  kid: {
    question: "Do you have kids that will live with you in the home?",
    answerTypesKey: APPLICATION_ANSWER_TYPES.kid,
  },
  marketingSource: {
    question: "How did you hear about the home?",
    answerTypesKey: APPLICATION_ANSWER_TYPES.marketingSource,
  },
};
