import React, { useEffect } from "react";

import { Formik } from "formik";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import * as Yup from "yup";

import { YardiTitleContainer } from "manager/pages/Marketplace/integrations/yardi/styled";
import Button from "shared/components/Button/Button";
import Container from "shared/components/Container";
import FlexContainer from "shared/components/FlexContainer";
import FooterBar from "shared/components/FooterBar";
import { PreventLeaveFormModal } from "shared/components/Form";
import { RadioGroup } from "shared/components/Form/RadioGroup";
import { BackLink } from "shared/components/Links";
import PageTitle from "shared/components/PageTitle";
import Spacer from "shared/components/Spacer";
import Tile from "shared/components/Tile";
import { YES_NO_OPTIONS } from "shared/config/constants";
import {
  backLinkPropType,
  OptionsPropType,
  refPropType,
} from "shared/utils/propTypes";

import YardiHeaderCredentials from "../shared/YardiHeaderCredentials";

import { SectionTitleLight, SectionQuestion } from "./styled";

const title = "Setup External Provider Property";

const initialValues = {
  syncInterval: undefined,
  pcodeCreationPoint: undefined,
  tcodeCreationPoint: undefined,
  sendAddressIntoGuestCard: undefined,
  leaseSignPoint: undefined,
  securityDepositPreference: undefined,
  alertEmailInterval: undefined,
  alertEmailRecipients: [],
};

const SyncSettingsTile = ({
  /* eslint-disable react/prop-types */
  secTitle,
  id,
  label,
  setFieldValue,
  options,
  children,
  /* eslint-enable react/prop-types */
}) => {
  return (
    <Tile
      data-testid={`tile-${id}`}
      header={{
        title: secTitle,
      }}
    >
      <Container>
        <RadioGroup
          options={options}
          id={id}
          name={id}
          label={<SectionQuestion>{label}</SectionQuestion>}
          onChange={(value) => setFieldValue(id, value)}
          block
        />
        {children}
      </Container>
    </Tile>
  );
};

const ValidationSchema = Yup.object().shape({
  alertEmailRecipients: Yup.array().of(
    Yup.string().email("Must be a valid email address")
  ),
});

const SetupYardiSyncSettings = ({
  backLink,
  onSubmit,
  saveData,
  formikRef,
  autoSyncOptions,
  prospectCreationOptions,
  tenantCreationOptions,
  leaseSignEventOptions,
  securityDepositOptions,
  updateOptions,
}) => {
  return (
    <Container expand noPaddingBottom>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {backLink && (
        <BackLink.Container>
          <BackLink {...backLink} />
        </BackLink.Container>
      )}
      <PageTitle.Container center>
        <div>
          <YardiTitleContainer>Sync Settings</YardiTitleContainer>
        </div>
        <YardiHeaderCredentials loadCredentials />
      </PageTitle.Container>
      <Formik
        initialValues={initialValues}
        validationSchema={ValidationSchema}
        onSubmit={onSubmit}
        enableReinitialize
        validateOnChange
        innerRef={formikRef}
      >
        {({
          dirty,
          setFieldValue,
          isSubmitting,
          isValid,
          submitForm,
          values,
        }) => {
          useEffect(() => {
            updateOptions(values);
          }, [values]);

          return (
            <>
              <SyncSettingsTile
                secTitle={
                  <span>
                    Auto Sync
                    <SectionTitleLight>
                      &nbsp;(All times listed are EST)
                    </SectionTitleLight>
                  </span>
                }
                id="syncInterval"
                label="How often would you like to automatically sync with your External Provider?"
                setFieldValue={setFieldValue}
                options={autoSyncOptions}
              />

              <SyncSettingsTile
                secTitle="Prospect Creation"
                id="pcodeCreationPoint"
                label="Which action would you like to trigger the creation of a prospect within your External Provider?"
                setFieldValue={setFieldValue}
                options={prospectCreationOptions}
              />

              <SyncSettingsTile
                secTitle="Tenant Creation"
                id="tcodeCreationPoint"
                label="Which action would you like to trigger the creation of a tenant within your External Provider?"
                setFieldValue={setFieldValue}
                options={tenantCreationOptions}
              />

              <SyncSettingsTile
                secTitle="Address Sync"
                id="sendAddressIntoGuestCard"
                label="Would you like to populate the guest card in your External Provider with the applicant's current address?"
                setFieldValue={setFieldValue}
                options={YES_NO_OPTIONS}
              />

              <SyncSettingsTile
                secTitle="Lease Creation"
                id="rentEditableDuringLeasing"
                label="Would you like the 'Monthly rent' field to be editable on the lease creation screen?"
                setFieldValue={setFieldValue}
                options={YES_NO_OPTIONS}
              />

              <SyncSettingsTile
                secTitle="Lease Sign Event"
                id="leaseSignPoint"
                label="Which action would you like to trigger the lease sign event of a tenant within your External Provider?"
                setFieldValue={setFieldValue}
                options={leaseSignEventOptions}
              />

              <SyncSettingsTile
                secTitle="Security Deposit Preferences"
                id="securityDepositPreference"
                label="Which security deposit value should we consider when syncing data with your External Provider?"
                setFieldValue={setFieldValue}
                options={securityDepositOptions}
              />

              <Container noPadding stickToBottom>
                <FooterBar>
                  <FlexContainer justifyContent="flex-end" fullWidth>
                    <Button
                      data-testid="submit-btn"
                      type="primary"
                      onClick={submitForm}
                      disabled={!isValid}
                      loading={isSubmitting}
                    >
                      CONFIRM
                    </Button>
                  </FlexContainer>
                </FooterBar>
              </Container>

              <PreventLeaveFormModal
                title="Exit Sync Settings?"
                subtitle={
                  isValid
                    ? "Would you like to save your changes?"
                    : "The current unsaved changes are not valid, if you exit you will lose the progress. Are you sure you want to continue?"
                }
                submitForm={() => {
                  if (isValid) {
                    saveData(values);
                  }
                }}
                isSubmitting={isSubmitting}
                preventLeaveWhen={dirty}
                cancelLinkLabel="No"
                submitButtonLabel="Yes"
                customOnCancel={(continueNavigation, closePrompt) => {
                  if (!isValid) {
                    closePrompt();
                  } else {
                    continueNavigation();
                  }
                }}
              >
                <Spacer />
              </PreventLeaveFormModal>
            </>
          );
        }}
      </Formik>
    </Container>
  );
};

SetupYardiSyncSettings.propTypes = {
  backLink: backLinkPropType.isRequired,
  onSubmit: PropTypes.func.isRequired,
  saveData: PropTypes.func.isRequired,
  formikRef: refPropType.isRequired,
  autoSyncOptions: OptionsPropType,
  prospectCreationOptions: OptionsPropType,
  tenantCreationOptions: OptionsPropType,
  leaseSignEventOptions: OptionsPropType,
  securityDepositOptions: OptionsPropType,
  updateOptions: PropTypes.func.isRequired,
};

SetupYardiSyncSettings.defaultProps = {
  autoSyncOptions: [],
  prospectCreationOptions: [],
  tenantCreationOptions: [],
  leaseSignEventOptions: [],
  securityDepositOptions: [],
};

export default SetupYardiSyncSettings;
