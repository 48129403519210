import React from "react";

import { Formik } from "formik";
import PropTypes from "prop-types";

import DeleteTitle from "shared/components/DeleteTitle";
import { Modal } from "shared/components/Modal";
import { SmallTitle } from "shared/components/Typography";
import { keyPropType } from "shared/utils/propTypes";

import { AttachmentValidationSchema } from "./config";
import DealSetupAttachmentsInnerFormElements from "./DealSetupAttachmentsInnerFormElements";
import { List, Subtitle } from "./styled";

const ListItem = ({ label, isDefault, onRemoveItem }) => (
  <List.ListItem>
    {isDefault ? (
      <SmallTitle>{label}</SmallTitle>
    ) : (
      <DeleteTitle action={onRemoveItem}>{label}</DeleteTitle>
    )}
  </List.ListItem>
);

ListItem.propTypes = {
  label: PropTypes.string.isRequired,
  isDefault: PropTypes.bool,
  onRemoveItem: PropTypes.func.isRequired,
};

ListItem.defaultProps = {
  isDefault: false,
};

const DealSetupAttachmentsModal = ({
  title,
  forSettingsLabel,
  initialValues,
  submitChanges,
  isDirty,
  isSubmitting,
  savedItems,
  newItems,
  onRemoveSavedItem,
  onRemoveNewItem,
  onSaveNewItem,
  isNewItemShown,
  setNewItemShown,
}) => (
  <Modal
    title={title}
    width="auto"
    style={{ maxWidth: 776 }}
    subtitle={
      forSettingsLabel && (
        <Subtitle>
          For <b>{forSettingsLabel}</b>
        </Subtitle>
      )
    }
    submitButtonLabel="Save Changes"
    submit={submitChanges}
    submitButtonDisabled={!isDirty}
    closeOnSubmit
    submitting={isSubmitting}
  >
    <Modal.Body noPaddingBottom>
      <List data-testid="items-list">
        {savedItems.map(({ id, ...itemProps }) => (
          <ListItem
            key={id}
            {...itemProps}
            onRemoveItem={() => onRemoveSavedItem(id)}
          />
        ))}
        {newItems.map(({ id, ...itemProps }) => (
          <ListItem
            key={id}
            {...itemProps}
            onRemoveItem={() => onRemoveNewItem(id)}
          />
        ))}
      </List>
    </Modal.Body>
    <Formik
      validationSchema={AttachmentValidationSchema}
      initialValues={initialValues}
      onSubmit={(values, { resetForm }) => {
        onSaveNewItem(values);
        resetForm();
      }}
    >
      <DealSetupAttachmentsInnerFormElements
        isNewItemShown={isNewItemShown}
        setNewItemShown={setNewItemShown}
      />
    </Formik>
  </Modal>
);

DealSetupAttachmentsModal.propTypes = {
  title: PropTypes.string.isRequired,
  forSettingsLabel: PropTypes.string.isRequired,
  initialValues: PropTypes.object.isRequired,
  submitChanges: PropTypes.func.isRequired,
  isDirty: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  savedItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: keyPropType.isRequired,
    })
  ),
  newItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: keyPropType.isRequired,
    })
  ),
  onRemoveSavedItem: PropTypes.func.isRequired,
  onRemoveNewItem: PropTypes.func.isRequired,
  onSaveNewItem: PropTypes.func.isRequired,
  isNewItemShown: PropTypes.bool.isRequired,
  setNewItemShown: PropTypes.func.isRequired,
};

DealSetupAttachmentsModal.defaultProps = {
  savedItems: [],
  newItems: [],
};

export default DealSetupAttachmentsModal;
