import React from "react";

import { YardiAssignDealToNonYardiUnitReasonFormExtension } from "manager/pages/Marketplace/integrations/yardi/shared";
import { getIntegrationType } from "manager/utils/integration.util";
import { INTEGRATION_TYPES } from "shared/config/constants";

import { ConfigurationProps } from "./interfaces";

const YARDI_WARNING =
  "This application is currently synced with Yardi. You are attempting to assign the application to a unit that is not synced with Yardi. Doing this will cause the prospect in Yardi to be cancelled and the application will no longer sync with Yardi. Are you sure you would like to proceed?";

const MODAL_STEP = Object.freeze({
  INITIAL_STEP: "INITIAL_STEP",
  CONFIRMATION_STEP: "CONFIRMATION_STEP",
});

const getIntegrationWarning = (currentIntegrationType: string) => {
  if (currentIntegrationType === INTEGRATION_TYPES.YARDI) {
    return YARDI_WARNING;
  }
  return undefined;
};

const getIntegrationModalExtension = (currentIntegrationType, property) => {
  if (currentIntegrationType === INTEGRATION_TYPES.YARDI) {
    return (
      <YardiAssignDealToNonYardiUnitReasonFormExtension
        property={property}
        marginTop
      />
    );
  }
  return undefined;
};

const getConfiguration = (
  currentUnit,
  currentProperty,
  selectedUnit,
  currentModalStep,
  defaultSubmit,
  moveToNextStep
) => {
  const currentIntegrationType = getIntegrationType(
    currentUnit || currentProperty
  );
  const selectedIntegrationType = getIntegrationType(selectedUnit);
  const integrationMismatch =
    currentIntegrationType &&
    selectedUnit &&
    currentIntegrationType !== selectedIntegrationType;
  let configuration: ConfigurationProps = {
    submit: defaultSubmit,
  };

  if (integrationMismatch) {
    if (currentModalStep === MODAL_STEP.INITIAL_STEP) {
      configuration = {
        submitButtonLabel: "Next Step",
        submit: moveToNextStep,
        masterWarning: undefined,
      };
    } else if (currentModalStep === MODAL_STEP.CONFIRMATION_STEP) {
      configuration = {
        submitButtonLabel: "Approve",
        submit: defaultSubmit,
        masterWarning: getIntegrationWarning(currentIntegrationType),
        modalExtension: getIntegrationModalExtension(
          currentIntegrationType,
          currentProperty
        ),
      };
    }
  }
  return configuration;
};

export { getConfiguration, MODAL_STEP };
