import React from 'react'

import isEmpty from 'lodash/isEmpty'

import { useGetDocumentDetail } from 'shared/hooks/api'

import { RegularText } from '../Typography'

import { Link } from './styled'

interface DocumentLinkProps {
  documentName: string
  id: string
}

export const DocumentLink = ({ documentName, id }: DocumentLinkProps) => {
  const { document, isError, isLoading } = useGetDocumentDetail(id)

  return (
    <>
      {isLoading || isError || isEmpty(document?.url) ? (
        <RegularText>{documentName}</RegularText>
      ) : (
        <Link href={document.url} target="_blank">
          {documentName}
        </Link>
      )}
    </>
  )
}
