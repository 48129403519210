import { useEffect } from "react";

import { queryCache } from "react-query";
import { useHistory } from "react-router-dom";

import ROUTES from "manager/config/routes";
import {
  INTEGRATION_QUERY_KEYS,
  usePollIntegrationStatuses,
  useStartYardiPull,
} from "manager/hooks/api";
import { NOTIFICATIONS } from "shared/config/constants";
import { transformRoute } from "shared/utils/routing";
import { openNotification } from "shared/utils/ui";

import { getSelectedIntegrationProvider } from "../config";

import { IIntegrationSyncStatus } from "./IntegrationSyncStatus";
import {
  IntegrationStatus,
  PollIntervalMilli,
  getIntegrationStatusConfig,
} from "./types";

export const useIntegrationSyncStatus = ({
  provider,
}: IIntegrationSyncStatus) => {
  const history = useHistory();
  const {
    integrationStatuses,
    isPollingActive,
    isLoading,
    isFetching,
    isPollIntegrationStatusesError,
    startPolling,
    stopPolling,
  } = usePollIntegrationStatuses(PollIntervalMilli);

  const selectedIntegrationStatus = getSelectedIntegrationProvider(
    integrationStatuses,
    provider
  );

  const { isConnected, isSuspended, status, lastSync } =
    selectedIntegrationStatus ?? {};

  const beginIntegrationSetup = () => history.push(ROUTES.marketplace);
  const onFinishSetup = () =>
    history.push(
      transformRoute(
        isConnected ? ROUTES.integrationDashboard : ROUTES.integrationLogin,
        { provider }
      )
    );
  const onViewDetails = () => {
    history.push(transformRoute(ROUTES.integrationAlerts, { provider }));
  };

  const { text, circleBackgroundColor, message } = getIntegrationStatusConfig(
    provider,
    status
  );

  const { startYardiPull, isLoading: isPulling } = useStartYardiPull({
    provider,
    onSuccess: () => {
      startPolling();
    },
  });

  useEffect(() => {
    if (status === IntegrationStatus.IN_PROGRESS && !isPollingActive) {
      startPolling();
    }

    if (status !== IntegrationStatus.IN_PROGRESS && isPollingActive) {
      stopPolling();
      queryCache.invalidateQueries(
        INTEGRATION_QUERY_KEYS.getIntegrationStatuses()
      );
      if (isPollIntegrationStatusesError) {
        openNotification(
          "Failed to refresh external provider integration",
          NOTIFICATIONS.error
        );
      }
    }
  }, [status, isPollIntegrationStatusesError, isPollingActive]);

  const onRefresh = () => {
    startYardiPull();
  };

  const isRefreshing =
    isPulling ||
    isPollingActive ||
    isLoading ||
    isFetching ||
    status === IntegrationStatus.IN_PROGRESS;

  return {
    isSuspended,
    lastSync,
    healthCheckStatus: status,
    healthCheckText: text,
    healthCheckMessage: message,
    onViewDetails,
    onFinishSetup,
    beginIntegrationSetup,
    circleBackgroundColor,
    isRefreshing,
    onRefresh,
  };
};
