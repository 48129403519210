import styled from "@emotion/styled";

import { COLORS } from "shared/config/constants";

const List = styled.ul<{
  color?: string;
  listStyleType?: string;
  noMarginBottom?: boolean;
}>`
  color: ${({ color }) => color || COLORS.input.invalid.message};
  padding-left: 0;
  display: inline-block;
  text-align: left;
  list-style-type: ${({ listStyleType }) => listStyleType || "none"};
  ${({ noMarginBottom }) => noMarginBottom && "margin-bottom: 0;"}
`;

const Item = styled.li``;

export { List, Item };
