import React from "react";

import FlexContainer from "shared/components/FlexContainer";
import { LoadingContainer } from "shared/components/Form/SearchableList/styled";
import Icon from "shared/components/Icon";
import Placeholder from "shared/components/Table/Placeholder";

import { YardiMappingContent } from "./styled";

interface ElementType {
  title: any;
  node: JSX.Element;
  info?: JSX.Element;
}

interface PlaceholderType {
  label: string;
  Icon?: any;
}

interface YardiMappingPageProps {
  title?: any;
  from: any;
  to: Node | string;
  elements: Array<ElementType>;
  loading?: boolean;
  placeholder?: PlaceholderType;
}

const YardiMappingPage = ({
  title,
  from,
  to,
  elements,
  loading,
  placeholder,
}: YardiMappingPageProps) => {
  return (
    <YardiMappingContent className="mapping-content">
      {title}
      <div className="columns">
        <div className="header-from">{from}</div>
        <div className="header-to">{to}</div>
      </div>
      {loading && (
        <LoadingContainer>
          <Icon.LoadingSpinnerGreenLargeIcon data-testid="loading" />
        </LoadingContainer>
      )}
      {!loading &&
        (elements || []).length > 0 &&
        elements.map((element) => (
          <FlexContainer
            alignItems="center"
            className="field-container"
            // @ts-ignore
            key={element.title}
          >
            <FlexContainer
              alignItems="center"
              nodesMargin="0 10px 0"
              className="field-from"
            >
              {element.title}
              {element.info}
            </FlexContainer>
            <FlexContainer alignItems="center" className="field-to">
              {element.node}
            </FlexContainer>
          </FlexContainer>
        ))}
      {!loading && (elements || []).length === 0 && placeholder && (
        <Placeholder type={Placeholder.TYPES.noData} {...placeholder} />
      )}
    </YardiMappingContent>
  );
};

export default YardiMappingPage;
