import { createAction } from "redux-actions";

import * as types from "./types";

export const receiveUnitListing = createAction(
  types.RECEIVE_UNIT_LISTING,
  ({ unitListing }) => ({
    unitListing,
  })
);
