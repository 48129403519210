import isNull from "lodash/isNull";
import isUndefined from "lodash/isUndefined";

// Remove consecutive whitespace anywhere in the string
const sanitizeWhitespace = (val) => val.replace(/\s\s+/g, " ");

const sanitizeStringSearchValue = (value) => {
  return value && value.toLowerCase && sanitizeWhitespace(value.toLowerCase());
};

const stringify = (val) => (isUndefined(val) || isNull(val) ? val : `${val}`);

const valueMatchesFilter = (value, filter) => {
  return !filter || (stringify(value) || "").includes(filter);
};

const areSameAsStrings = (val1, val2) => stringify(val1) === stringify(val2);

export {
  sanitizeStringSearchValue,
  valueMatchesFilter,
  areSameAsStrings,
  stringify,
};
