import kebabCase from "lodash/kebabCase";
import { useQuery, useMutation, queryCache } from "react-query";

import api from "manager/lib/api";
import { NOTIFICATIONS } from "shared/config/constants";
import { downloadFileByDataURL } from "shared/utils/pdf";
import { openNotification } from "shared/utils/ui";

export const useGetMagicLinksAgents = (filters = {}, config = {}) => {
  const response = useQuery(
    ["getMagicLinksAgents", filters],
    () => api.getMagicLinksAgents(filters),
    {
      onError: () => {
        openNotification("Failed to load agents", NOTIFICATIONS.error);
      },
      ...config,
    }
  );

  return response;
};

export const useGetCompanyMagicLink = () => {
  const response = useQuery(
    ["getCompanyMagicLink"],
    () => api.getCompanyMagicLink(),
    {
      onError: () => {
        openNotification(
          "Failed to load company magic link",
          NOTIFICATIONS.error
        );
      },
    }
  );

  return response;
};

export const useMagicLinkQRCode = () => {
  const [mutation, status] = useMutation(api.getMagicLinkQRCode, {
    onError: () => {
      openNotification("Failed to generate QR code", NOTIFICATIONS.error);
    },
  });
  return {
    generateQRCode: mutation,
    ...status,
  };
};

export const useDownloadQRCode = () => {
  const { generateQRCode } = useMagicLinkQRCode();
  const downloadQRCode = async (magicLink, name) => {
    const { qrCode } = await generateQRCode({ magicLink });
    const url = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(
      qrCode
    )}`;
    const filename = kebabCase(name);
    downloadFileByDataURL(url, filename);
  };

  return { downloadQRCode };
};

export const useGetMagicLinksProperties = (filters = {}) => {
  const response = useQuery(
    ["getMagicLinksProperties", filters],
    () => api.getMagicLinksProperties(filters),
    {
      onError: () => {
        openNotification("Failed to load properties", NOTIFICATIONS.error);
      },
    }
  );

  return response;
};

export const useTogglePropertyBranding = () => {
  const [mutation, status] = useMutation(api.togglePropertyBranding, {
    onError: () => {
      openNotification(
        "Failed to change property branding",
        NOTIFICATIONS.error
      );
    },
  });
  return {
    togglePropertyBranding: mutation,
    ...status,
  };
};

export const useMagicLinksExportAgents = () => {
  const [mutation, status] = useMutation(api.magicLinksExportAgents, {
    onSuccess: () => {
      queryCache.invalidateQueries(["getDownloads"]);
      queryCache.invalidateQueries(["getManagerProfile"]);
      openNotification(
        "Download has been successfully requested",
        NOTIFICATIONS.info
      );
    },
    onError: () => {
      openNotification("Failed to export agents", NOTIFICATIONS.error);
    },
  });
  return {
    exportAgents: mutation,
    ...status,
  };
};

export const useMagicLinksExportProperties = () => {
  const [mutation, status] = useMutation(api.magicLinksExportProperties, {
    onSuccess: () => {
      queryCache.invalidateQueries(["getDownloads"]);
      queryCache.invalidateQueries(["getManagerProfile"]);
      openNotification(
        "Download has been successfully requested",
        NOTIFICATIONS.info
      );
    },
    onError: () => {
      openNotification("Failed to export properties", NOTIFICATIONS.error);
    },
  });
  return {
    exportProperties: mutation,
    ...status,
  };
};

export const useGetDownloads = ({ onError }) => {
  const response = useQuery(["getDownloads"], () => api.getDownloads(), {
    onError: () => {
      onError();
      openNotification("Failed to get downloads", NOTIFICATIONS.error);
    },
  });
  return response;
};
