import { YARDI_RELATED_UNIT_MESSAGES } from "manager/pages/Marketplace/integrations/yardi/shared";
import { INTEGRATION_TYPES } from "shared/config/constants";

export const getDisabledMessages = (integrationType: string) => {
  let disabledCloseMessage: string;
  let disabledCreateMessage: string;
  if (INTEGRATION_TYPES.YARDI === integrationType) {
    disabledCloseMessage = YARDI_RELATED_UNIT_MESSAGES.disabledUnitListingClose;
    disabledCreateMessage =
      YARDI_RELATED_UNIT_MESSAGES.disabledUnitListingCreate;
  }
  return {
    disabledCloseMessage,
    disabledCreateMessage,
  };
};
