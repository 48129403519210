import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { Select } from "antd-latest";

import {
  COLORS,
  TRANSITIONS,
  ANTD_LATEST_PREFIX,
} from "shared/config/constants";

import { VeroSelectProps } from "./interfaces";

export const StyledSelect4 = styled(Select)<VeroSelectProps>`
  min-width: 150px;
  width: 100%;
  transition: ${TRANSITIONS.default};

  &.${ANTD_LATEST_PREFIX}-select-focused {
    .${ANTD_LATEST_PREFIX}-select-selector {
      box-shadow: none !important;
    }
  }

  &.${ANTD_LATEST_PREFIX}-select-open {
    .${ANTD_LATEST_PREFIX}-select-selector {
      border-color: transparent !important;
      border-bottom-color: ${COLORS.select.input.open.borderBottom} !important;
      box-shadow: 0px 1px 6px rgba(126, 153, 168, 0.45) !important;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .${ANTD_LATEST_PREFIX}-select-selector {
    ${(props) =>
      props.minWidth
        ? css`
            min-width: ${props.minWidth};
          `
        : css`
            min-width: 150px;
          `};
    border-radius: 2px;
    ${(props) =>
      !!props.error &&
      css`
        border-color: ${COLORS.input.invalid.border} !important;
        box-shadow: none;
        outline: none;
      `};
  }

  .${ANTD_LATEST_PREFIX}-select-arrow .anticon {
    color: ${COLORS.select.icon};
  }
`;

export const DropdownMenuWrapper = styled.div`
  transform: translateY(-4px);

  .${ANTD_LATEST_PREFIX}-select-item {
    color: ${COLORS.secondary};
  }

  .${ANTD_LATEST_PREFIX}-select-item-option-disabled {
    color: ${COLORS.borderColorBase} !important;
  }

  .${ANTD_LATEST_PREFIX}-select-item-option-selected:not(.${ANTD_LATEST_PREFIX}-select-item-option-disabled) {
    color: ${COLORS.primary};
    background-color: ${COLORS.background} !important;
  }

  .${ANTD_LATEST_PREFIX}-select-item-option-active:not(.${ANTD_LATEST_PREFIX}-select-item-option-disabled) {
    color: ${COLORS.primary};
    background-color: ${COLORS.itemActiveBg} !important;
  }
`;

export const dropdownStyles = {
  border: "2px solid red !important",
  borderRadius: "2px",
  boxShadow: "0px 1px 6px rgba(126, 153, 168, 0.45)",
};
