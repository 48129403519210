import { useState } from 'react'

import { RadioChangeEvent } from 'antd/lib/radio'
import isEmpty from 'lodash/isEmpty'

import { COLORS, NOTIFICATIONS } from 'shared/config/constants'
import { useUploadDocument } from 'shared/hooks/api'
import useModal from 'shared/hooks/useModal'
import type { DocumentInApplication } from 'shared/interfaces/api/document'
import { isManagerApp } from 'shared/utils/auth'
import { openNotification } from 'shared/utils/ui'

import FlexContainer from '../FlexContainer'
import Icon from '../Icon'
import { Modal as BaseModal } from '../Modal/Modal'
import Spacer from '../Spacer'
import { RegularText, SmallText, SmallTitle } from '../Typography'

import {
  DocumentName,
  DocumentType,
  DocumentTypeOptions,
  Modal,
  SuccessMessageContainer,
} from './styled'

interface DocumentsUploadModalProps {
  acceptedFiles: File[]
  applicationId: number
  applicantVrn: string
  personVrn: string
  requiredDocuments: DocumentInApplication[]
}

const internalDocument: DocumentInApplication = {
  attachmentRequestId: 0,
  documents: [],
  formType: 'internal-document',
  isRequired: false,
  name: 'Internal Document',
  ocr: false,
  requiredQty: 0,
  skipped: false,
}

export const DocumentsUploadModal = ({
  acceptedFiles,
  applicationId,
  applicantVrn,
  personVrn,
  requiredDocuments,
}: DocumentsUploadModalProps) => {
  const {
    selfApi: { closeDialog },
  } = useModal()
  const [animate, setAnimate] = useState(false)
  const [selectedFormTypeName, setSelectedFormTypeName] = useState<
    string | null
  >(null)
  const [processedDocumentIndex, setProcessedDocumentIndex] = useState(0)
  const { uploadDocument, isLoading } = useUploadDocument(applicationId)
  const updatedRequirdDocuments = [
    ...requiredDocuments,
    ...(isManagerApp() ? [internalDocument] : []),
  ]
  const onRadioCheck = ({ target: { value } }: RadioChangeEvent) => {
    setSelectedFormTypeName(value)
  }

  return (
    <Modal
      closable={!animate}
      closeOnSubmit={false}
      showCancelLink={false}
      showSubmitButton={!animate}
      submitButtonDisabled={!selectedFormTypeName}
      submit={() => {
        const isInternalDocument = selectedFormTypeName === 'Internal Document'
        if (!isInternalDocument && isEmpty(personVrn)) {
          openNotification(
            'Applicant has not yet started their application',
            NOTIFICATIONS.error,
          )
          return
        }
        const file = acceptedFiles[processedDocumentIndex]
        setAnimate(true)
        uploadDocument({
          description: selectedFormTypeName,
          file,
          filename: file.name,
          ocr: !!updatedRequirdDocuments.find(
            ({ name }) => name === selectedFormTypeName,
          )?.ocr,
          owner: isInternalDocument
            ? `vrn:applicant::${applicantVrn}`
            : `vrn:person::${personVrn}`,
          ownerType: isInternalDocument ? 'applicant' : 'person',
        })
        setTimeout(() => {
          if (processedDocumentIndex + 1 === acceptedFiles.length) {
            closeDialog()
          } else {
            setAnimate(false)
            setSelectedFormTypeName(null)
            setProcessedDocumentIndex((prev) => prev + 1)
          }
        }, 1500)
      }}
      submitButtonLabel="UPLOAD"
      submitting={isLoading}
      style={{ maxWidth: 423 }}
      width="auto"
    >
      <BaseModal.Body>
        <FlexContainer alignItems="center" flexDirection="column">
          <Icon.Document height={120} width={96} />
          <DocumentName>
            {acceptedFiles[processedDocumentIndex].name}
          </DocumentName>
          <SmallText>
            Upload {processedDocumentIndex + 1} of {acceptedFiles.length}
          </SmallText>
          <Spacer size={Spacer.SIZES.default} />
          <SmallTitle strong>What kind of file is this?</SmallTitle>
          <Spacer size={Spacer.SIZES.default} />
          <DocumentTypeOptions
            animate={animate}
            onChange={onRadioCheck}
            value={selectedFormTypeName}
            selectedOptionIndex={updatedRequirdDocuments.findIndex(
              ({ name }) => selectedFormTypeName === name,
            )}
            touched={!!selectedFormTypeName}
          >
            {updatedRequirdDocuments.map(({ formType, name }, index) => (
              <DocumentType
                key={`${formType}_${index.toString()}`}
                value={name}
              >
                <RegularText>{name}</RegularText>
              </DocumentType>
            ))}
            <SuccessMessageContainer
              alignItems="center"
              className="success-message-container"
              flexDirection="column"
              gap="16px"
              numberOfOptions={updatedRequirdDocuments.length}
            >
              <Icon.SuccessIcon fill={COLORS.green} height={168} width={168} />
              <SmallTitle>Success!</SmallTitle>
            </SuccessMessageContainer>
          </DocumentTypeOptions>
        </FlexContainer>
      </BaseModal.Body>
    </Modal>
  )
}
