import React, { useEffect, useMemo, useState } from "react";

import { ModalManagerDataRenderer } from "shared/components/Modal/ModalManagerDataRenderer";
import { ModalManager } from "shared/utils/modalmanager";

export const RenderModalContainer = () => {
  const [dataFromModalManager, setDataFromModalManager] = useState(null);

  const modalsFromModalManager = useMemo(
    () =>
      dataFromModalManager
        ? dataFromModalManager.map((dataForModal) => (
            <ModalManagerDataRenderer key={dataForModal.id} {...dataForModal} />
          ))
        : undefined,
    [dataFromModalManager]
  );

  useEffect(() => {
    const { unsubscribe } = ModalManager.subscribe(() => {
      setDataFromModalManager(ModalManager.getModalData());
    });

    return () => unsubscribe();
  }, []);

  return <>{modalsFromModalManager}</>;
};
