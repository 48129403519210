import React from "react";

import { useTranslation } from "renter/hooks/useTranslation";
import Tile from "shared/components/Tile";
import { EmergencyContact } from "shared/interfaces/api";
import Person from "shared/pages/ApplicationSummary/RenterProfile/Person";

interface EmergencyContactsProps {
  emergencyContacts?: EmergencyContact[];
}

const EmergencyContacts = ({ emergencyContacts }: EmergencyContactsProps) => {
  const { t } = useTranslation({
    keyPrefix: "personal-info.emergency-contacts",
  });

  return (
    <Tile
      equalHeight
      header={{
        title: t("title"),
      }}
    >
      {emergencyContacts?.length ? (
        emergencyContacts.map((contact) => (
          <Tile.Inner
            key={contact.id}
            borderBottom
            data-testid={`emergency-contact-${contact.id}`}
          >
            <Person
              name={contact?.name || `${contact.firstName} ${contact.lastName}`}
              phoneNumber={contact?.phone || contact.phoneNumber}
              relationship={contact.relationship}
              relationshipType={contact.type || contact.relationship}
            />
          </Tile.Inner>
        ))
      ) : (
        <Tile.Inner key={0} borderBottom data-testid={`emergency-contact-${0}`}>
          {t("no-items")}
        </Tile.Inner>
      )}
    </Tile>
  );
};

export default EmergencyContacts;
