import React from "react";

import Icon from "shared/components/Icon";
import { Modal } from "shared/components/Modal";

import { StatusNotificationModalContent } from "./styled";

export interface IStatusNotificationModal {
  message: React.ReactNode;
  StatusIcon?: React.ElementType;
  extraContent?: React.ReactNode | React.ReactChild;
}

const StatusNotificationModal = ({
  message,
  StatusIcon = Icon.OperationSuccess,
  extraContent = undefined,
}) => {
  return (
    <Modal centered showSubmitButton={false} showCancelLink={false}>
      <Modal.Body noPaddingTop noPaddingBottom>
        <StatusNotificationModalContent>
          <StatusIcon />
          <h3 className="status-text">{message}</h3>
          {extraContent}
        </StatusNotificationModalContent>
      </Modal.Body>
    </Modal>
  );
};

export default StatusNotificationModal;
