import React from "react";

import PropTypes from "prop-types";

import { DOWNLOAD_STATUSES } from "manager/config/constants";
import { PrimaryText } from "manager/pages/MagicLinks/styled";
import Icon from "shared/components/Icon";
import { ActionLink } from "shared/components/Links";
import Table from "shared/components/Table";
import withBreakpoint from "shared/hocs/withBreakpoint";
import { formatDateTime } from "shared/utils/ui";

const DownloadCenter = ({ downloads, isDownloadsLoading }) => (
  <Table
    alt
    loading={isDownloadsLoading}
    columns={[
      {
        title: "File Name",
        dataIndex: "filename",
        key: "filename",
        width: 350,
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        width: 150,
      },
      {
        title: "Category",
        dataIndex: "category",
        key: "category",
        width: 150,
      },
      {
        title: "Created At",
        dataIndex: "createdAt",
        key: "createdAt",
        width: 150,
      },
      {
        title: "",
        dataIndex: "download",
        key: "download",
        width: 150,
      },
    ]}
    columnBreakpoints={{
      xs: {
        column1: "filename",
        column2: "status",
      },
      sm: ["filename", "status", "download"],
      md: ["filename", "status", "download"],
      lg: ["filename", "status", "category", "createdAt", "download"],
      xl: ["filename", "status", "category", "createdAt", "download"],
    }}
    placeholder={{
      Icon: Icon.NoSearchResultsIcon,
      label: "Downloads",
    }}
    rows={downloads?.map((download) => {
      const isCompleted = download.status === DOWNLOAD_STATUSES.completed;
      return {
        key: download.id,
        values: [
          <div data-testid={`name-${download.id}`}>{download.filename}</div>,
          <PrimaryText>{download.status}</PrimaryText>,
          <div>{download.category}</div>,
          <div>{formatDateTime(download.createdAt)}</div>,
          <div>
            <ActionLink
              disabled={!isCompleted}
              href={download.url}
              data-testid="copy-link"
            >
              Download
            </ActionLink>
          </div>,
        ],
      };
    })}
    expandable={["category", "createdAt", "download"]}
    noClickableRows
  />
);

DownloadCenter.propTypes = {
  downloads: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      filename: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    })
  ),
  isDownloadsLoading: PropTypes.bool.isRequired,
};

DownloadCenter.defaultProps = {
  downloads: [],
};

export default withBreakpoint(DownloadCenter);
