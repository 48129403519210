import { AttachmentFile } from "shared/components/Form/UploadWall";

export interface RequiredAttachment {
  name?: string;
  files?: AttachmentFile[];
  metadata?: {
    requiredAttachmentId: number;
  };
  skipped?: boolean;
  existingFiles?: AttachmentFile[];
}

export interface OtherAttachmentMapped {
  metadata?: {
    requiredAttachmentId: number;
  };
  file: AttachmentFile;
}

export interface UploadAttachmentProps {
  file: AttachmentFile;
  metadata: {
    requiredAttachmentId?: number;
  };
}

export enum VisibleAttachments {
  ALL = "ALL",
  PUBLIC = "PUBLIC",
}
