import React from "react";

import {
  PieChart as BasePieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Legend,
  PieLabelRenderProps,
  Tooltip,
} from "recharts";

import { COLORS as BASE_COLORS } from "shared/config/constants";

export interface PieChartProps {
  width?: string | number;
  height?: string | number;
  data: {
    name: string;
    value: number;
  }[];
}

const COLORS = [
  BASE_COLORS.lightViolet,
  BASE_COLORS.dashboard.lightViolet2,
  BASE_COLORS.blueViolet,
];
// NOTE: https://recharts.org/en-US/api/Pie
const INNER_RADIUS = 60;
const OUTER_RADIUS = 80;
const PADDING_ANGLE = 5;

const PieChart = ({ width = "100%", height = "100%", data }: PieChartProps) => {
  const renderLabel = (props: PieLabelRenderProps) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, percent, innerRadius, outerRadius } = props;
    const radius = 25 + +innerRadius + (+outerRadius - +innerRadius);
    const x = +cx + +radius * Math.cos(-midAngle * RADIAN);
    const y = +cy + +radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="black"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <ResponsiveContainer debounce={1} width={width} height={height}>
      <BasePieChart>
        <Pie
          data={data}
          dataKey="value"
          innerRadius={INNER_RADIUS}
          outerRadius={OUTER_RADIUS}
          paddingAngle={PADDING_ANGLE}
          label={renderLabel}
          isAnimationActive={false}
        >
          {data.map((_item, index) => {
            const key = `cell-${index}`;
            return <Cell key={key} fill={COLORS[index]} />;
          })}
        </Pie>
        <Tooltip />
        <Legend />
      </BasePieChart>
    </ResponsiveContainer>
  );
};

export default PieChart;
