import { queryCache } from "react-query";
import * as Yup from "yup";

import { GET_DEAL_SETUP, DEAL_QUERY_KEYS } from "manager/hooks/api";
import { NOTIFICATIONS } from "shared/config/constants";
import api from "shared/lib/api";
import { openNotification } from "shared/utils/ui";

export const ITEM_NAME = "itemName";

export const AttachmentValidationSchema = Yup.object().shape({
  [ITEM_NAME]: Yup.string().required("This field is required"),
});

export const receiveDealSetup = async () => {
  queryCache.invalidateQueries(GET_DEAL_SETUP);
  queryCache.invalidateQueries(DEAL_QUERY_KEYS.getDefaultDealSetup());
};

export const bulkUpdateAttachments = async (data, resolve, reject) => {
  const { newItems, removedItems } = data;
  if (newItems.length > 0) {
    await api
      .createAttachments({ attachments: newItems })
      .then(() => {})
      .catch(() => {
        reject();
        openNotification(
          "Failed to save the list of attachments.",
          NOTIFICATIONS.error
        );
      });
  }
  if (removedItems.length > 0) {
    await removedItems.forEach((item) => api.deleteAttachment({ id: item }));
  }
  resolve();
  receiveDealSetup();
  openNotification(
    "The list of Attachments have been successfully changed",
    NOTIFICATIONS.info
  );
};
