import styled from "@emotion/styled";
import { Divider } from "antd";

import { ActionLink } from "shared/components/Links";
import { MEDIA_QUERIES } from "shared/config/constants";

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
`;

const MainAction = styled(ActionLink)`
  ${MEDIA_QUERIES.w.ltMd} {
    display: none;
  }
`;

const DropdownContainer = styled.div`
  ${MEDIA_QUERIES.w.gteMd} {
    display: none;
  }
`;

const MainActionDivider = styled(Divider)`
  ${MEDIA_QUERIES.w.ltMd} {
    display: none;
  }
`;

export { ActionContainer, DropdownContainer, MainAction, MainActionDivider };
