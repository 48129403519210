import axios from "axios";

import ROUTES from "shared/config/apiRoutes";
import { transformRoute } from "shared/utils/routing";

import { API_URL } from "./setupApi";

const api = {
  createAttachments: ({ attachments }) =>
    axios.post(
      `${API_URL}${ROUTES.requiredAttachments}`,
      attachments.map((item) => ({ ...item, dealSetup: item.objectId }))
    ),
  deleteAttachment: ({ id }) =>
    axios.delete(
      `${API_URL}${transformRoute(ROUTES.requiredAttachment, { id })}`
    ),
};

export default api;
