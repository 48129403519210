import axios from "axios";
import { VEGA_BASE_URL } from "shared/config/apiRoutes";

const PAYMENT_SERVICE_PATH = "api/payment";

const api = {
  getPaymentAccountMappings: (params) =>
    axios.get(`${VEGA_BASE_URL}/${PAYMENT_SERVICE_PATH}/account-mapping`, {
      params,
    }),
  createPaymentAccountMapping: (payload) =>
    axios.post(
      `${VEGA_BASE_URL}/${PAYMENT_SERVICE_PATH}/account-mapping`,
      payload,
      // @ts-ignore
      { convertToSnakeCase: false }
    ),
};

export default api;
