import filter from "lodash/filter";
import first from "lodash/first";
import { queryCache, useMutation, useQuery } from "react-query";

import api from "manager/lib/api";
import { NOTIFICATIONS } from "shared/config/constants";
import { openNotification } from "shared/utils/ui";

const GET_DEFAULT_EMAIL_CONFIGURATIONS = "getDefaultEmailConfigurations";
const GET_DEFAULT_EMAIL_CONFIGURATION = "getDefaultEmailConfiguration";
const GET_CUSTOM_EMAIL_CONFIGURATIONS = "getCustomEmailConfigurations";
const GET_CUSTOM_EMAIL_CONFIGURATION = "getCustomEmailConfiguration";
const GET_PROPERTY_EMAIL_CONFIGURATIONS = "getPropertyEmailConfigurations";
const GET_EMAIL_TEMPLATES_VARIABLES = "getEmailTemplatesVariables";

export const useGetDefaultEmailConfigurations = (
  params,
  config = { enabled: true }
) => {
  const { typeName } = params || {};
  const {
    data: defaultEmailConfigurations,
    isLoading: isDefaultConfigurationsLoading,
  } = useQuery(
    [GET_DEFAULT_EMAIL_CONFIGURATIONS, typeName],
    () => api.getDefaultEmailConfigurations({ typeName }),
    {
      onError: () =>
        openNotification(
          "Failed to load default email configurations.",
          NOTIFICATIONS.error
        ),
      ...config,
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
  return {
    defaultEmailConfigurations,
    isDefaultConfigurationsLoading,
  };
};

export const useGetDefaultEmailConfigurationById = (
  id,
  config = { enabled: true }
) => {
  const {
    data: defaultEmailConfiguration,
    isLoading: isDefaultConfigurationLoading,
  } = useQuery(
    [GET_DEFAULT_EMAIL_CONFIGURATION, Number(id)],
    () => api.getDefaultEmailConfiguration(id),
    {
      onError: () =>
        openNotification(
          "Failed to load default email configuration.",
          NOTIFICATIONS.error
        ),
      enabled: Boolean(id) && config.enabled,
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
  return {
    defaultEmailConfiguration,
    isDefaultConfigurationLoading,
  };
};

export const useGetCustomEmailConfigurations = (
  { typeName },
  config = { enabled: true }
) => {
  const {
    data: customEmailConfigurations,
    isLoading: isCustomConfigurationsLoading,
  } = useQuery(
    [GET_CUSTOM_EMAIL_CONFIGURATIONS, typeName],
    () => api.getCustomEmailConfigurations({ typeName }),
    {
      onError: () =>
        openNotification(
          "Failed to load custom email configurations.",
          NOTIFICATIONS.error
        ),
      ...config,
    }
  );
  return {
    customEmailConfigurations,
    isCustomConfigurationsLoading,
  };
};

export const useGetCustomEmailConfiguration = (
  id,
  config = { enabled: true }
) => {
  const {
    data: customEmailConfiguration,
    isLoading: isCustomConfigurationLoading,
  } = useQuery(
    [GET_CUSTOM_EMAIL_CONFIGURATION, Number(id)],
    () => api.getCustomEmailConfiguration(id),
    {
      onError: () =>
        openNotification(
          "Failed to load custom email templates.",
          NOTIFICATIONS.error
        ),
      enabled: Boolean(id) && config.enabled,
    }
  );
  return {
    customEmailConfiguration,
    isCustomConfigurationLoading,
  };
};

export const useDeleteCustomEmailConfiguration = () => {
  const [
    deleteCustomEmailConfiguration,
    { isLoading: isDeletingCustomConfiguration },
  ] = useMutation(({ id }) => api.deleteCustomEmailConfiguration(id), {
    onSuccess: (data, { id, typeName }) => {
      const key = [GET_CUSTOM_EMAIL_CONFIGURATIONS, typeName];
      const currentData = queryCache.getQueryData(key);

      if (currentData) {
        queryCache.setQueryData(
          key,
          filter(currentData, (item) => item.id !== id)
        );
      }
    },
    onError: () =>
      openNotification(
        "Failed to delete the custom email template.",
        NOTIFICATIONS.error
      ),
  });

  return {
    deleteCustomEmailConfiguration,
    isDeletingCustomConfiguration,
  };
};

export const useCreateCustomEmailConfiguration = () => {
  const [
    createCustomEmailConfiguration,
    { isLoading: isCustomConfigurationCreating },
  ] = useMutation(api.createCustomEmailConfiguration, {
    onSuccess: () => {
      openNotification(
        "The custom email template has been successfully created.",
        NOTIFICATIONS.info
      );
    },
    onError: () =>
      openNotification(
        "Failed to create a custom email template.",
        NOTIFICATIONS.error
      ),
    throwOnError: true,
  });

  return {
    createCustomEmailConfiguration,
    isCustomConfigurationCreating,
  };
};

export const useUpdateCustomEmailConfiguration = () => {
  const [
    updateCustomEmailConfiguration,
    { isLoading: isCustomConfigurationUpdating },
  ] = useMutation(api.updateCustomEmailConfiguration, {
    onSuccess: (data, params) => {
      queryCache.setQueryData(
        [GET_CUSTOM_EMAIL_CONFIGURATION, params.id],
        data
      );

      openNotification(
        "The custom email template has been successfully updated.",
        NOTIFICATIONS.info
      );
    },
    onError: () =>
      openNotification(
        "Failed to update the custom email template.",
        NOTIFICATIONS.error
      ),
    throwOnError: true,
  });

  return {
    updateCustomEmailConfiguration,
    isCustomConfigurationUpdating,
  };
};

export const useSetCustomEmailConfigurationAsDefault = () => {
  const [
    setCustomEmailConfigurationAsDefault,
    { isLoading: isCustomConfigurationSettingAsDefault },
  ] = useMutation(
    ({ customEmailConfigurationId }) =>
      api.setCustomEmailConfigurationAsDefault(customEmailConfigurationId),
    {
      onSuccess: (value, { typeName }) => {
        const cachedItems =
          queryCache.getQueryData([
            GET_CUSTOM_EMAIL_CONFIGURATIONS,
            typeName,
          ]) || [];

        queryCache.setQueryData(
          [GET_CUSTOM_EMAIL_CONFIGURATIONS, typeName],
          cachedItems.map((item) => {
            if (item.id === value.id) {
              return value;
            }

            return { ...item, selected: false };
          })
        );
      },
      onError: () =>
        openNotification(
          "Failed to set the custom email template as default.",
          NOTIFICATIONS.error
        ),
    }
  );

  return {
    setCustomEmailConfigurationAsDefault,
    isCustomConfigurationSettingAsDefault,
  };
};

export const useRestoreDefaultEmailConfiguration = () => {
  const [
    restoreDefaultEmailConfiguration,
    { isLoading: isRestoringDefaultConfiguration },
  ] = useMutation(api.restoreDefaultEmailConfiguration, {
    onSuccess: (value, { typeName }) => {
      const cachedItems =
        queryCache.getQueryData([GET_CUSTOM_EMAIL_CONFIGURATIONS, typeName]) ||
        [];

      queryCache.setQueryData(
        [GET_CUSTOM_EMAIL_CONFIGURATIONS, typeName],
        cachedItems.map((item) => {
          return { ...item, selected: false };
        })
      );
    },
    onError: () =>
      openNotification(
        "Failed to restore the default email configuration.",
        NOTIFICATIONS.error
      ),
  });

  return {
    restoreDefaultEmailConfiguration,
    isRestoringDefaultConfiguration,
  };
};

// Property Email Configurations

export const useCreatePropertyEmailConfiguration = () => {
  const [
    createPropertyEmailConfiguration,
    { isLoading: isCreatingPropertyEmailConfiguration },
  ] = useMutation(
    ({ propertyId, customEmailConfigurationId }) =>
      api.createPropertyEmailConfiguration({
        propertyId,
        customEmailConfigurationId,
      }),
    {
      onSuccess: (data, { propertyId, typeName }) => {
        /* Note: BE deletes old configuration and creates a new one,
         * there can be only one configuration per property */
        const key = [
          GET_PROPERTY_EMAIL_CONFIGURATIONS,
          Number(propertyId),
          typeName,
        ];
        queryCache.setQueryData(key, [data]);
      },
      onError: () =>
        openNotification(
          "Failed to customize the property email configuration",
          NOTIFICATIONS.error
        ),
    }
  );

  return {
    createPropertyEmailConfiguration,
    isCreatingPropertyEmailConfiguration,
  };
};

export const useGetPropertyEmailConfiguration = ({ propertyId, typeName }) => {
  const {
    data: propertyEmailConfigurations,
    isLoading: isPropertyEmailConfigurationLoading,
  } = useQuery(
    [GET_PROPERTY_EMAIL_CONFIGURATIONS, Number(propertyId), typeName],
    () => api.getPropertyEmailConfigurations({ propertyId, typeName }),
    {
      onError: () =>
        openNotification(
          "Failed to get the property email configuration",
          NOTIFICATIONS.error
        ),
      retry: false,
    }
  );

  return {
    propertyEmailConfiguration: first(propertyEmailConfigurations),
    isPropertyEmailConfigurationLoading,
  };
};

export const useDeletePropertyEmailConfiguration = () => {
  const [
    deletePropertyEmailConfiguration,
    { isLoading: isDeletingPropertyEmailConfiguration },
  ] = useMutation(
    ({ configurationId }) =>
      api.deletePropertyEmailConfiguration(configurationId),
    {
      onSuccess: (_data, { propertyId, typeName }) => {
        const key = [
          GET_PROPERTY_EMAIL_CONFIGURATIONS,
          Number(propertyId),
          typeName,
        ];
        /* Note: BE removes old configuration,
         * there can be only one configuration per property */
        queryCache.setQueryData(key, []);
      },
      onError: () =>
        openNotification(
          "Failed to delete the property email configuration",
          NOTIFICATIONS.error
        ),
    }
  );

  return {
    deletePropertyEmailConfiguration,
    isDeletingPropertyEmailConfiguration,
  };
};

// Send test emails

export const useDefaultEmailConfigurationSendTest = () => {
  const [
    defaultEmailConfigurationSendTest,
    { isLoading: isSendingDefaultEmail },
  ] = useMutation(api.defaultEmailConfigurationSendTest, {
    onSuccess: () =>
      openNotification(
        "The selected test email has been sent.",
        NOTIFICATIONS.info
      ),
    onError: () =>
      openNotification("Failed to send a test email.", NOTIFICATIONS.error),
  });

  return {
    defaultEmailConfigurationSendTest,
    isSendingDefaultEmail,
  };
};

export const useCustomEmailConfigurationSendTest = () => {
  const [
    customEmailConfigurationSendTest,
    { isLoading: isSendingCustomEmail },
  ] = useMutation(api.customEmailConfigurationSendTest, {
    onSuccess: () =>
      openNotification(
        "The selected test email has been sent.",
        NOTIFICATIONS.info
      ),
    onError: () =>
      openNotification("Failed to send a test email.", NOTIFICATIONS.error),
  });

  return {
    customEmailConfigurationSendTest,
    isSendingCustomEmail,
  };
};

// Email Template Variables

export const useGetEmailTemplatesVariables = () => {
  const { data: emailVariables, isLoading: isEmailVariablesLoading } = useQuery(
    GET_EMAIL_TEMPLATES_VARIABLES,
    api.getEmailTemplateVariables,
    {
      onError: () =>
        openNotification(
          "Failed to load email variables.",
          NOTIFICATIONS.error
        ),
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );

  return {
    emailVariables,
    isEmailVariablesLoading,
  };
};
