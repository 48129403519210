import React, { useContext } from "react";

import styled from "@emotion/styled";
import { Typography } from "antd";
import { Col, Row } from "antd-latest";
import _ from "lodash";

import NonMatchingCriteriaNote, {
  APPLICANT_NOTE_KEYWORD,
} from "shared/components/Applicant/NonMatchingCriteriaNote";
import ArgyleEmployerSearch from "shared/components/ArgyleEmployerSearchModal";
import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import { ImageLink, UnderlineLink } from "shared/components/Links";
import Spacer from "shared/components/Spacer";
import Tag, { TAG_COLORS } from "shared/components/Tag";
import Tile from "shared/components/Tile";
import Tooltip from "shared/components/Tooltip";
import { RegularText } from "shared/components/Typography";
import {
  ANCHOR_LINKS,
  COLORS,
  MEDIA_QUERIES,
  colors,
} from "shared/config/constants";
import { useGetOcrResults } from "shared/hooks/api/applicationQueries";
// it was created in renter side...should have been in shared but we'll get to that later
import useModal from "shared/hooks/useModal";
import { useGetEmployments } from "renter/hooks/api";
import { isManagerApp } from "shared/utils/auth";
import {
  calcTileInnerBorderBottom,
  formatPhone,
  getPeriod,
} from "shared/utils/ui";

import { printDollarsFromCents } from "shared/utils/dollar-print";
import ApplicationContext from "./applicationContext";
import { VEROLynkButton } from "./styled";
import { ApplicationSummary } from "./types";

const { Text } = Typography;

const CompanyAddress = styled(Text)({
  fontSize: "12px",
  fontWeight: 300,
});
const ManagerName = styled("span")({
  [MEDIA_QUERIES.w.gteMd]: {
    display: "block",
  },
  [MEDIA_QUERIES.w.ltMd]: {
    "&::after": {
      content: "', '",
    },
  },
});

export const UploadBadgeW2 = styled("span")({
  border: `1px solid ${colors.accent.base}`,
  borderRadius: "2px",
  padding: "1px 4px",
  color: COLORS.lightBlue,
  fontWeight: 400,
  fontSize: "8px",
  whiteSpace: "nowrap",
});

export const EmploymentDocumentBadge = styled(UploadBadgeW2)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  height: 20px;
  font-size: 12px;
  text-transform: uppercase;
  white-space: nowrap;
`;

export const ClickableEmploymentDocumentBadge = styled(EmploymentDocumentBadge)`
  cursor: pointer;
`;

export const useEmploymentHistory = (
  applicationSummary: ApplicationSummary
): EmploymentHistoryProps => {
  const {
    employmentNonMatchingCriteriaNotes,
    employmentHistoryDuration,
    employmentHistoryRange,
    employmentDurationValid,
  } = applicationSummary;
  return {
    employmentDurationValid,
    employmentHistoryDuration,
    employmentHistoryRange,
    employmentNonMatchingCriteriaNotes,
  };
};

export type EmploymentHistoryProps = {
  employmentNonMatchingCriteriaNotes?: string;
  employmentHistoryRange?: string;
  employmentHistoryDuration?: number;
  employmentDurationValid?: boolean;
};

export const EmploymentHistory: React.FC<EmploymentHistoryProps> = ({
  employmentHistoryDuration,
  employmentNonMatchingCriteriaNotes = "",
  employmentHistoryRange = "",
  employmentDurationValid = false,
}) => {
  const { application } = useContext(ApplicationContext);
  const incomes = _.get(application, "snapshot.incomes");
  const personVrn = _.get(application, "personVrn");
  const { dealDecisionIsMade } = application;
  const snapshotEmployments = _.chain(
    _.get(application, "snapshot.employments")
  )
    .filter((employment) => _.isEmpty(employment.removedAt))
    .value();
  const { employmentsData } = useGetEmployments(
    personVrn,
    !_.isEmpty(personVrn)
  );
  const employments = dealDecisionIsMade ? snapshotEmployments : employmentsData

  const { openModalDialog } = useModal();

  return (
    <Tile
      id={ANCHOR_LINKS.RENTER_PROFILE.EMPLOYMENT_HISTORY}
      header={{
        title: "Employment History",
        collapsibleOnDesktop: true,
        collapsibleOnMobile: true,
      }}
    >
      {!_.isEmpty(employments) &&
        _.map(employments, (employment, index: number) => {
          const manager = _.get(employment, "annotation.managerName");
          const employerPhoneNumber = _.get(
            employment,
            "annotation.employerPhoneNumber"
          );

          const income = _.chain(incomes)
            .filter((income) => {
              return (
                (_.get(income, "employment") ===
                  _.get(employment, "employmentId") ||
                  (_.includes(
                    _.get(employment, "employmentIds", []),
                    _.get(income, "employment")
                  ) &&
                    _.get(income, "source") === "w2")) &&
                _.get(income, "estimatedAnnualGrossAmount")
              );
            })
            .first()
            .value();

          const startDate = _.get(employment, "startDate");
          const title =
            _.get(employment, "title") || _.get(employment, "annotation.title");
          return (
            <Tile.Inner
              key={employment.id}
              borderBottom={calcTileInnerBorderBottom(employments, index)}
              data-testid={`employment-${employment.id}`}
            >
              <Row>
                <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 5 }}>
                  <Tile.Label>Period</Tile.Label>
                  {startDate
                    ? getPeriod(
                        employment.startDate,
                        employment.endDate,
                        employment.status
                      )
                    : _.get(employment, "year")}
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 16 }} lg={{ span: 7 }}>
                  <Spacer hideGteMd />
                  <Tile.Label>Company</Tile.Label>
                  <FlexContainer alignItems="center" gap="16px">
                    <strong>{employment.employer}</strong>
                  </FlexContainer>
                  <CompanyAddress>
                    {_.get(employment, "fullAddress")}
                  </CompanyAddress>
                  {employment.provider === "document" ? (
                    <>
                      <Spacer size={Spacer.SIZES.xs} />
                      <EmploymentDocumentBadge>
                        Document Upload
                      </EmploymentDocumentBadge>
                    </>
                  ) : null}
                  {employment.provider === "argyle" && (
                    <>
                      <Spacer size={Spacer.SIZES.xs} />
                      <Tag color={TAG_COLORS.GREEN}>Linked Automatically</Tag>
                    </>
                  )}
                  {employment.provider !== "argyle" && isManagerApp() && (
                    <>
                      <Spacer size={Spacer.SIZES.xs} />
                      <VEROLynkButton
                        onClick={() =>
                          openModalDialog(ArgyleEmployerSearch, {
                            companyName: _.get(employment, "employer"),
                          })
                        }
                      >
                        VEROLynk Employer Lookup
                      </VEROLynkButton>
                    </>
                  )}
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 4 }}>
                  <Spacer hideGteLg />
                  <Tile.Label>Job Title</Tile.Label>
                  <strong>{title}</strong>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 10 }} lg={{ span: 5 }}>
                  <Spacer hideGteLg />
                  <Tile.Label>Manager</Tile.Label>
                  {!_.isEmpty(manager) ? (
                    <>
                      <ManagerName>{manager}</ManagerName>
                      {!_.isEmpty(employerPhoneNumber) && (
                        <UnderlineLink href={`tel:${employerPhoneNumber}`}>
                          {formatPhone(employerPhoneNumber)}
                        </UnderlineLink>
                      )}
                    </>
                  ) : (
                    "N.A"
                  )}
                </Col>
              </Row>
            </Tile.Inner>
          );
        })}

      {_.isEmpty(employments) && (
        <Tile.Inner
          key={1}
          borderBottom
          data-testid={`employment-history-${0}`}
        >
          No employment history added
          {isManagerApp() && (
            <>
              <Spacer size={Spacer.SIZES.xs} />
              <VEROLynkButton
                onClick={() =>
                  openModalDialog(ArgyleEmployerSearch, {
                    companyName: '',
                  })
                }
              >
                VEROLynk Employer Lookup
              </VEROLynkButton>
            </>
          )}
        </Tile.Inner>
      )}

      {employmentNonMatchingCriteriaNotes && (
        <NonMatchingCriteriaNote
          note={employmentNonMatchingCriteriaNotes}
          durationType={employmentHistoryRange}
          durationValue={employmentHistoryDuration}
          isRequirementMet={employmentDurationValid}
          keyword={APPLICANT_NOTE_KEYWORD.employment}
        />
      )}
    </Tile>
  );
};
