import React from "react";

import { useFormikContext } from "formik";
import PropTypes from "prop-types";

import { ATTACHMENT_TYPES } from "manager/config/constants";
import { SwitchWithCheckboxList } from "shared/components/Form";
import Settings from "shared/components/Settings";
import { APPLICANT_TYPES } from "shared/config/constants";

import ApplicationSetupHeader from "../Header";
import { StyledSkeleton as Skeleton } from "../styled";
import { getAttachmentsFilter } from "../utils";

const attachmentsForApplicants = "requiresApplicantAttachements";
const attachmentsForOccupants = "requiresOccupantAttachements";
const attachmentsForGuarantors = "requiresGuarantorAttachements";

const getRequiredAttachments = getAttachmentsFilter(
  ATTACHMENT_TYPES.APPLICATION
);

const RequiredAttachments = ({ loading, openAttachmentsModal, disabled }) => {
  const { values, setFieldValue } = useFormikContext();

  const onChangeAttachment = (id, checked) => {
    let name = null;
    const index = values.requiredAttachments?.findIndex(
      (item) => item.id === id
    );
    name = `requiredAttachments[${index}].isActive`;
    setFieldValue(name, checked);
  };

  return (
    <Settings
      header={
        <Skeleton loading={loading}>
          <ApplicationSetupHeader
            title="Required Attachments"
            subTitle="Please manage the attachments that you want to require from applicants, occupants and guarantors"
          />
        </Skeleton>
      }
      nodes={[
        {
          key: 1,
          component: (
            <Skeleton loading={loading}>
              <SwitchWithCheckboxList
                title="Applicant Attachments"
                items={getRequiredAttachments(
                  values,
                  APPLICANT_TYPES.applicant
                )}
                onManageList={() => {
                  openAttachmentsModal({
                    title: "Manage Applicant Attachments",
                    dealSetupId: values.id,
                    items: [
                      ...getRequiredAttachments(
                        values,
                        APPLICANT_TYPES.applicant
                      ),
                    ],
                    newItemProps: {
                      attachmentType: ATTACHMENT_TYPES.APPLICATION,
                      applicantType: APPLICANT_TYPES.applicant,
                    },
                  });
                }}
                onChange={onChangeAttachment}
                value={values[attachmentsForApplicants]}
                onChangeTitle={(value) => {
                  setFieldValue(attachmentsForApplicants, value);
                }}
                disabled={disabled}
              />
            </Skeleton>
          ),
        },
        {
          key: 2,
          component: (
            <Skeleton loading={loading}>
              <SwitchWithCheckboxList
                title="Occupant Attachments"
                description="over 18 years old"
                items={getRequiredAttachments(values, APPLICANT_TYPES.occupant)}
                onManageList={() => {
                  openAttachmentsModal({
                    title: "Manage Occupant Attachments",
                    dealSetupId: values.id,
                    items: [
                      ...getRequiredAttachments(
                        values,
                        APPLICANT_TYPES.occupant
                      ),
                    ],
                    newItemProps: {
                      attachmentType: ATTACHMENT_TYPES.APPLICATION,
                      applicantType: APPLICANT_TYPES.occupant,
                    },
                  });
                }}
                onChange={onChangeAttachment}
                value={values[attachmentsForOccupants]}
                onChangeTitle={(value) => {
                  setFieldValue(attachmentsForOccupants, value);
                }}
                disabled={disabled}
              />
            </Skeleton>
          ),
        },
        {
          key: 3,
          component: (
            <Skeleton loading={loading}>
              <SwitchWithCheckboxList
                title="Guarantor Attachments"
                items={getRequiredAttachments(
                  values,
                  APPLICANT_TYPES.guarantor
                )}
                onManageList={() => {
                  openAttachmentsModal({
                    title: "Manage Guarantor Attachments",
                    dealSetupId: values.id,
                    items: [
                      ...getRequiredAttachments(
                        values,
                        APPLICANT_TYPES.guarantor
                      ),
                    ],
                    newItemProps: {
                      attachmentType: ATTACHMENT_TYPES.APPLICATION,
                      applicantType: APPLICANT_TYPES.guarantor,
                    },
                  });
                }}
                onChange={onChangeAttachment}
                value={values[attachmentsForGuarantors]}
                onChangeTitle={(value) => {
                  setFieldValue(attachmentsForGuarantors, value);
                }}
                disabled={disabled}
              />
            </Skeleton>
          ),
        },
      ]}
    />
  );
};

RequiredAttachments.propTypes = {
  loading: PropTypes.bool,
  openAttachmentsModal: PropTypes.func,
  disabled: PropTypes.bool,
};

RequiredAttachments.defaultProps = {
  loading: false,
  openAttachmentsModal: () => {},
  disabled: false,
};

export default RequiredAttachments;
