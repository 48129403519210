import React from "react";

import { RegularText } from "../Typography";

import { NotificationCounter as StyledNotificationCounter } from "./styled";

const NotificationCounter = ({ count }: { count?: number }) => (
  <StyledNotificationCounter>
    <RegularText>{count}</RegularText>
  </StyledNotificationCounter>
);

export default NotificationCounter;
