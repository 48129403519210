import PropTypes from "prop-types";

import { backLinkPropType, keyPropType } from "shared/utils/propTypes";

export interface CredentialsType {
  endpointUrl?: string;
  serverName?: string;
  databaseName?: string;
  platform?: string;
  username?: string;
  password?: string;
  lastSync?: string | Date;
}

const credentialsType = {
  endpointUrl: PropTypes.string,
  serverName: PropTypes.string,
  databaseName: PropTypes.string,
  platform: PropTypes.string,
  username: PropTypes.string,
  password: PropTypes.string,
};

export interface CredentialsHeaderType {
  username?: string;
  inventoryProviderCompanyId?: number;
}

const credentialsHeaderType = {
  username: PropTypes.string,
};

export interface HeaderDataTypes {
  editCredentials: () => void;
  yardiCredentials: CredentialsHeaderType;
}

const headerDataTypes = {
  editCredentials: PropTypes.func.isRequired,
  yardiCredentials: PropTypes.shape(credentialsHeaderType),
};

export interface YardiAttachmentPropType {
  id: number | string;
  value: number | string;
  step?: number;
}

const yardiAttachmentPropType = PropTypes.shape({
  id: keyPropType,
  value: PropTypes.string,
});

export interface YardiAttachmentMappingValuesPropType {
  dealPdfAttachmentType?: number | string;
  executedLeasePdfAttachmentType?: number | string;
  prequalificationReportAttachmentType?: number | string;
}

const yardiAttachmentMappingValuesPropType = PropTypes.shape({
  dealPdfAttachmentType: keyPropType,
  executedLeasePdfAttachmentType: keyPropType,
  adverseActionPdfAttachmentType: keyPropType,
  activitiesAndMemosSummaryPdfAttachmentType: keyPropType,
  prequalificationReportAttachmentType: keyPropType,
});

export interface YardiChargeType {
  id: number | string;
  value: string;
  description?: string;
}

const yardiChargeTypePropType = PropTypes.shape({
  id: keyPropType,
  value: PropTypes.string,
});

export interface YardiChargeTypeMappingValues {
  applicationFeeChargeType?: string | number | undefined;
  securityDepositChargeType?: string | number | undefined;
  firstMonthRentChargeType?: string | number | undefined;
}

const yardiChargeTypeMappingValuesPropType = PropTypes.shape({
  applicationFeeChargeType: keyPropType,
  securityDepositChargeType: keyPropType,
  firstMonthRentChargeType: keyPropType,
});

const veroUnitPropType = PropTypes.shape({
  id: keyPropType,
  bathrooms: PropTypes.string,
  bedrooms: PropTypes.number,
  property: keyPropType,
  squareFeet: PropTypes.number,
  status: PropTypes.string,
  unitType: PropTypes.string,
  unitListingCurrent: PropTypes.shape({
    id: keyPropType,
    isClosed: PropTypes.bool,
    isCurrent: PropTypes.bool,
    numberOfActiveDeals: PropTypes.number,
    rent: PropTypes.number,
    unit: keyPropType,
    unitAvailableOn: PropTypes.string,
    unitClosedAt: PropTypes.string,
  }),
});

const veroUnitsPropType = PropTypes.arrayOf(veroUnitPropType);

const yardiUnitPropType = PropTypes.shape({
  id: keyPropType,
  bathrooms: PropTypes.string,
  bedrooms: PropTypes.number,
  floorPlanId: PropTypes.string,
  isActive: PropTypes.bool,
  name: PropTypes.string,
  rent: PropTypes.number,
  squareFeet: PropTypes.number,
  yardiProperty: keyPropType,
  unit: keyPropType,
  unitType: PropTypes.string,
});

const yardiUnitsPropType = PropTypes.arrayOf(yardiUnitPropType);

const alertsTablePropTypes = {
  alerts: PropTypes.arrayOf(
    PropTypes.shape({
      id: keyPropType,
      description: PropTypes.string,
      createdAt: PropTypes.string,
      alertClass: PropTypes.number,
      alertReason: PropTypes.number,
      alertLevel: PropTypes.number,
    })
  ).isRequired,
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      id: keyPropType,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  currentAlertClass: PropTypes.number,
  currentYardiPropertyId: PropTypes.number,
  currentResolved: PropTypes.bool,
  setCurrentAlertClass: PropTypes.func.isRequired,
  setCurrentYardiPropertyId: PropTypes.func.isRequired,
  setCurrentResolved: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  isTableLoading: PropTypes.bool.isRequired,
  currentPagination: PropTypes.shape({
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
  }).isRequired,
  setCurrentPagination: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  backLink: backLinkPropType.isRequired,
};

const alertsTableDefaultProps = {
  currentAlertClass: undefined,
  currentYardiPropertyId: undefined,
  currentResolved: true,
};

const YARDI_REASON_TYPES = Object.freeze({
  CANCEL: "CANCEL",
  DENY: "DENY",
});

export interface YardiAgent {
  id: number | string;
  fullName: string;
}
export interface YardiAgentsPropType {
  agents?: Array<YardiAgent> | undefined;
}

const yardiAgentsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    id: keyPropType.isRequired,
    fullName: PropTypes.string.isRequired,
  })
);

const CHARGE_TYPES = Object.freeze([
  {
    id: "applicationFeeChargeType",
    label: "Application Fee",
  },
  {
    id: "securityDepositChargeType",
    label: "Security Deposit",
  },
  {
    id: "firstMonthRentChargeType",
    label: "First months rent",
  },
]);

const YARDI_ATTACHMENT_TYPE_STEPS = {
  Tenant: 1,
  Prospect: 2,
};

const DOCUMENT_TYPES = Object.freeze([
  {
    id: "dealPdfAttachmentType",
    label: "Group application",
    step: YARDI_ATTACHMENT_TYPE_STEPS.Prospect,
  },
  {
    id: "adverseActionPdfAttachmentType",
    label: "Adverse Action Letter",
    step: YARDI_ATTACHMENT_TYPE_STEPS.Prospect,
  },
  {
    id: "executedLeasePdfAttachmentType",
    label: "Executed lease",
    step: YARDI_ATTACHMENT_TYPE_STEPS.Tenant,
  },
  {
    id: "activitiesAndMemosSummaryPdfAttachmentType",
    label: "Activities and Memos Summary",
    step: YARDI_ATTACHMENT_TYPE_STEPS.Prospect,
  },
  {
    id: "prequalificationReportAttachmentType",
    label: "Prequalification Report",
    step: YARDI_ATTACHMENT_TYPE_STEPS.Prospect,
  },
]);

const YARDI_RELATED_UNIT_MESSAGES = {
  disabledUnitListingCreate: "The unit listing must be created within Yardi.",
  disabledUnitListingClose: "The unit listing must be closed within Yardi.",
};

const MAPPING_TYPES_COMPLETED_PROPS = Object.freeze({
  chargeTypes: "chargeTypesCompleted",
  documentTypes: "documentTypesCompleted",
  syncOptions: "syncOptionsCompleted",
});

const DEFAULT_YARDI_INTEGRATION_DATA_TEXT = "Not generated yet";

const YARDI_INTERFACES = Object.freeze([
  {
    id: "vero",
    label: "Vero",
  },
  {
    id: "vero leasing",
    label: "Vero Leasing",
  },
]);

export {
  credentialsType,
  credentialsHeaderType,
  headerDataTypes,
  yardiAttachmentPropType,
  yardiAttachmentMappingValuesPropType,
  yardiChargeTypePropType,
  yardiChargeTypeMappingValuesPropType,
  veroUnitPropType,
  veroUnitsPropType,
  yardiUnitPropType,
  yardiUnitsPropType,
  YARDI_REASON_TYPES,
  alertsTablePropTypes,
  alertsTableDefaultProps,
  yardiAgentsPropType,
  CHARGE_TYPES,
  DOCUMENT_TYPES,
  YARDI_RELATED_UNIT_MESSAGES,
  MAPPING_TYPES_COMPLETED_PROPS,
  DEFAULT_YARDI_INTEGRATION_DATA_TEXT,
  YARDI_INTERFACES,
};
