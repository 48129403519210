import { useState } from "react";

import { Col } from "antd";
import startCase from "lodash/startCase";
import { Helmet } from "react-helmet";

import { ApplicantPaymentModal } from "renter/components/Modal";
import { Button } from "shared/components/Button";
import Container from "shared/components/Container";
import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import { UnderlineLink } from "shared/components/Links";
import PageTitle from "shared/components/PageTitle";
import Table from "shared/components/Table";
import useModal from "shared/hooks/useModal";
import { PAYMENT_STATUS } from "shared/interfaces/types";

import { printDollarsFromCents } from "shared/utils/dollar-print";
import { CityBackground } from "./styled";

const ApplicantPayments = ({
  name,
  loading,
  payments,
  applicationId,
  application,
}) => {
  const [isPaymentModalOpened, setPaymentModalOpened] =
    useState<boolean>(false);
  const { openModalDialog } = useModal();

  const { blockSubmissionByPayment } = application || {};

  const makePayment = (payment) => {
    return async () => {
      setPaymentModalOpened(true);
      openModalDialog(ApplicantPaymentModal, {
        applicationId,
        payment,
      }).afterClose.then((success: boolean) => {
        setPaymentModalOpened(false);
        if (success) {
          window.location.reload();
        }
      });
    };
  };

  const notCanceledPayments =
    payments?.filter((payment) => payment.status !== PAYMENT_STATUS.CANCELED) ||
    [];

  return (
    <>
      <CityBackground data-testid="city-bg" />
      <Helmet>
        <title>{name}</title>
      </Helmet>
      <Container noPaddingBottom>
        <PageTitle.Container>
          <PageTitle>{name}</PageTitle>
        </PageTitle.Container>
      </Container>
      <Container noPaddingTop noMobilePaddingX>
        <Table
          loading={loading}
          columns={[
            {
              title: "Application Id",
              dataIndex: "applicationId",
              key: "applicationId",
            },
            {
              title: "Amount",
              dataIndex: "amount",
              key: "amount",
            },
            {
              title: "Line Items",
              dataIndex: "lineItems",
              key: "lineItems",
            },
            {
              title: "Status",
              dataIndex: "status",
              key: "status",
              width: "260px",
            },
            {
              title: "Paid At",
              dataIndex: "paidAt",
              key: "paidAt",
            },
            {
              title: "Receipt",
              dataIndex: "receipt",
              key: "receipt",
            },
            {
              title: "Refunded At",
              dataIndex: "refundedAt",
              key: "refundedAt",
            },
          ]}
          columnBreakpoints={{
            xs: {
              column1: "applicationId",
              column2: "amount",
            },
            sm: ["applicationId", "amount", "status", "receipt"],
            md: ["applicationId", "amount", "status", "receipt"],
            lg: [
              "applicationId",
              "amount",
              "lineItems",
              "status",
              "paidAt",
              "receipt",
              "refundedAt",
            ],
          }}
          expandable={[
            "applicationId",
            "amount",
            "lineItems",
            "status",
            "paidAt",
            "receipt",
            "refundedAt",
          ]}
          placeholder={{
            Icon: Icon.DollarSignGray,
            label: "Payments",
          }}
          noClickableRows
          rows={notCanceledPayments?.map((payment) => ({
            key: payment.id,
            values: [
              <div>{applicationId}</div>,
              <div>{printDollarsFromCents(payment.expectedAmount)}</div>,
              <>
                {payment.lineItems?.map((lineItem) => (
                  <Col>{`${lineItem.name}: ${printDollarsFromCents(
                    lineItem.amount
                  )}`}</Col>
                ))}
              </>,
              <>
                {payment.status === PAYMENT_STATUS.UNPAID &&
                blockSubmissionByPayment === false ? (
                  <FlexContainer alignItems="center" gap="10px">
                    <div>{startCase(payment.status)}</div>
                    {/* @ts-ignore  */}
                    <Button
                      type="primary"
                      onClick={makePayment(payment)}
                      disabled={isPaymentModalOpened}
                      data-testid="make-payment-btn"
                    >
                      Make payment
                    </Button>
                  </FlexContainer>
                ) : (
                  <div>{startCase(payment.status)}</div>
                )}
              </>,
              <div>{payment.paidAt}</div>,
              payment.receiptUrl ? (
                <div>
                  <UnderlineLink href={payment.receiptUrl} target="_blank">
                    receipt
                  </UnderlineLink>
                </div>
              ) : (
                "N/A"
              ),
              <div>{payment.refundedAt}</div>,
            ],
          }))}
        />
      </Container>
    </>
  );
};

export default ApplicantPayments;
