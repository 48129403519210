import styled from "@emotion/styled";

import { MEDIA_QUERIES } from "shared/config/constants";

const Attachment = styled("div")((props) => ({
  display: "flex",
  ...(!props.disabled && {
    marginBottom: "12px",
  }),
  [MEDIA_QUERIES.w.ltSm]: {
    width: "100%",
  },
}));

const InputContainer = styled("div")({
  [MEDIA_QUERIES.w.ltSm]: {
    flexGrow: 1,
  },
});

const DeleteIconContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  marginLeft: "8px",
});

export { Attachment, DeleteIconContainer, InputContainer };
