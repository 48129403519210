import React from "react";

import { useHistory, useParams } from "react-router-dom";

import ROUTES from "renter/config/routes";
import PasswordRecoverySetPasswordPage from "shared/pages/PasswordRecoverySetPasswordPage";

import { ParamsProps } from "./interfaces";

const PasswordRecoverySetPasswordContainer = () => {
  const history = useHistory();

  const { token }: ParamsProps = useParams();

  const onSuccess = () => history.push(ROUTES.applications);

  return (
    <PasswordRecoverySetPasswordPage onSuccessLogin={onSuccess} token={token} />
  );
};

export default PasswordRecoverySetPasswordContainer;
