import React, { useEffect } from "react";

import { Field, useFormikContext } from "formik";

import { useGetCriminalRecordSeverityOptions } from "manager/hooks/api";
import { Select } from "shared/components/Form";
import { addDefaultObjectValues, showError } from "shared/utils/forms";

import BaseCondition from "./BaseCondition";
import { commonFilterConditionPropTypes } from "./lumenFilterConditions.config";
import {
  getBaseConditionPath,
  getConditionPropPath,
} from "./lumenFilterConditions.util";

const ConditionCriminalRecordSeverity = ({ idx, onDelete }) => {
  const { values, errors, touched, setFieldTouched, setFieldValue } =
    useFormikContext();
  const { criminalRecordSeverityOptions } =
    useGetCriminalRecordSeverityOptions();
  const severityRecordTypeProp = "severity";
  const severityRecordTypeBasePath = getBaseConditionPath(idx);
  const severityRecordTypePropPath = getConditionPropPath(
    idx,
    severityRecordTypeProp
  );

  useEffect(() => {
    if (criminalRecordSeverityOptions?.length) {
      const defaultValues = {
        [severityRecordTypeProp]: criminalRecordSeverityOptions[0].id,
      };
      addDefaultObjectValues(
        values,
        severityRecordTypeBasePath,
        defaultValues,
        setFieldValue
      );
    }
  }, [criminalRecordSeverityOptions]);

  return (
    <BaseCondition conditionLabel="Severity equals:" onDelete={onDelete}>
      <div className="big-select">
        <Field
          name={severityRecordTypePropPath}
          id={severityRecordTypePropPath}
          label="Select Severity"
          as={Select}
          error={showError(severityRecordTypePropPath, touched, errors)}
          onChange={(value) => setFieldValue(severityRecordTypePropPath, value)}
          onBlur={() => setFieldTouched(severityRecordTypePropPath, true)}
        >
          {(criminalRecordSeverityOptions || []).map((type) => (
            <Select.Option key={type.id}>{type.value}</Select.Option>
          ))}
        </Field>
      </div>
    </BaseCondition>
  );
};

ConditionCriminalRecordSeverity.propTypes = {
  ...commonFilterConditionPropTypes,
};

export default ConditionCriminalRecordSeverity;
