import _ from 'lodash'
import React, { useState } from 'react'
import { Tooltip, Popover } from 'antd'
import { useDeleteExplanation } from 'manager/hooks/api'
import { buildClassName } from 'shared/utils/vega'
import { RedoIcon } from 'shared/components/vega/icons'
import { Timestamp } from 'shared/components/vega/Timestamp'
import { Formula } from './Formula'

const Refresh = ({ explanation, onClick }) => {
  const title = <div className='vega info explanation model'>
    <div>{explanation?.model}</div>
    <div>{explanation?.id}</div>
    <Timestamp timestamp={explanation?.createdAt} />
    <div className='vega info smaller'>click to regenerate explanation</div>
  </div>

  return (
    <Tooltip title={title}><RedoIcon className='vega button icon small inline' onClick={onClick} /></Tooltip>
  )
}

const Explanation = ({ explanation, small = false, titleOnly = false, badges = undefined }) => {
  const [resolvedExplanation, setExplanation] = useState(explanation)
  const { deleteExplanation, isLoading } = useDeleteExplanation()
  const refreshExplanation = async () => setExplanation(await deleteExplanation(resolvedExplanation.id))

  if (_.isNil(resolvedExplanation?.id)) {
    return null
  }

  if (_.isNil(resolvedExplanation?.output)) {
    return <p className='vega'>We are generating an explanation for this result.</p>
  }

  return (
    <div className='vega flex gap small column'>
      {resolvedExplanation?.output?.title && <div className={buildClassName([!_.isEmpty(badges) && 'vega flex gap small centered'])}>
        <div className={buildClassName(['vega title', small && 'small'])}>{resolvedExplanation?.output?.title}</div>
        {!_.isEmpty(badges) && _.map(badges, tag => <span className='vega badge small' key={tag}>{_.upperCase(tag)}</span>)}
      </div>}

      {!titleOnly && <p className={buildClassName(['vega info', small && 'smaller'])}>
        <span>{resolvedExplanation?.output?.description || resolvedExplanation?.output}</span>
        {resolvedExplanation?.id && (isLoading ? <span className='vega loader inline' /> : <Refresh explanation={resolvedExplanation} onClick={refreshExplanation} />)}
      </p>}
    </div>
  )
}

const ExplanationTooltip = ({ entry, titleOnly = false, children = null }) => {
  const content = <div className='vega content popover'>
    <Explanation explanation={entry?.explanation} small />
  </div>

  return (
    entry?.explanation ? <Popover content={content}>
      {!children && (titleOnly ? <span className='vega info'>{entry?.explanation?.output?.title}</span> : <Formula formula={entry?.evaluatedFormula} className='info smaller evaluated' />)}
      {children}
    </Popover> : children
  )
}

export { Explanation, ExplanationTooltip }
