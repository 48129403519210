import styled from "@emotion/styled";

import { SmallErrorText } from "shared/components/Typography";
import { COLORS } from "shared/config/constants";

const BORDER = `1px solid ${COLORS.borders}`;

interface RadioGroupBoxedListWrapperProps {
  hasTitle?: string;
  orPosition?: any;
  type?: string;
}

const RadioGroupBoxedListWrapper = styled.div<RadioGroupBoxedListWrapperProps>`
  border-top: ${(props) => (!props.hasTitle ? "none" : undefined)};

  .list-title {
    padding: 23px 33px;
    font-weight: 500;
  }

  .list-item {
    padding: 12px 33px;
    border: ${BORDER};

    &.active-item {
      background-color: #edfbf6;
    }

    & + .list-item {
      border-top: none;
    }
  }

  .list-item-subtitle {
    margin-top: 8px;
    font-size: 12px;
  }

  .ant-radio-group {
    width: 100%;
  }

  .ant-radio-wrapper {
    display: flex;
    align-items: flex-start;
    white-space: unset;
    .ant-radio {
      margin-top: 3px;
    }
  }
`;

const Error = SmallErrorText;

export { RadioGroupBoxedListWrapper, Error };
