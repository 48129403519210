import { createAction } from "redux-actions";

import * as types from "./types";

export const receiveAdditionalQuestions = createAction(
  types.RECEIVE_ADDITIONAL_QUESTIONS,
  ({ items, objectId, contentType }) => ({ items, objectId, contentType })
);

export const removeAdditionalQuestions = createAction(
  types.REMOVE_ADDITIONAL_QUESTIONS,
  ({ ids, objectId, contentType }) => ({ ids, objectId, contentType })
);
