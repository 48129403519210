import { useState, useRef } from "react";

import defaultsDeep from "lodash/defaultsDeep";

import { isAdminApp } from "shared/utils/auth";
import {
  arrayBufferToBase64,
  downloadPDF,
  downloadTextFile,
} from "shared/utils/pdf";
import { insertScripts } from "shared/utils/ui";

import {
  TOOL_MODES,
  WEBVIEWER_OPTIONS,
  ANNOTATION_TYPES,
  PDF_TRON_DEPENDENCIES,
  LICENSE_KEY_NAME,
} from "./config";
import { WebViewerRef, WebViewerProps } from "./interfaces";

const ANGLE_90 = 90;
const ANGLE_270 = 270;
const LICENSE_KEY = process.env[LICENSE_KEY_NAME];

(() => {
  if (!LICENSE_KEY) {
    // eslint-disable-next-line no-console
    console.warn("Warning: PDFTron License key has not been found.");
  }
})();

/**
 * PDFTron is only authorized to work on Admin Site
 */

const useWebViewer = (viewerSettings: WebViewerProps = {}) => {
  if (!isAdminApp()) {
    throw new Error("PDFTron is only licensed to work Admin site");
  }

  const { options = {}, backgroundColor } = viewerSettings;
  const webViewerRef = useRef<WebViewerRef>();
  const appliedFields = useRef<number[]>();
  const viewerNodeRef = useRef();
  const [isInitialized, setInitialized] = useState(false);
  const [isDocumentLoading, setDocumentLoading] = useState(false);
  const [isApplyingAnnotations, setAnnotationsApplying] = useState(false);
  const [currentPage, setCurrentPage] = useState();
  const [totalPages, setTotalPages] = useState<number>();
  const [isDocumentLoaded, setDocumentLoaded] = useState(false);
  const [isAnnotationsLoaded, setAnnotationsLoaded] = useState(false);
  const [isDocumentDownloading, setDocumentDownloading] = useState(false);
  const viewerOptions = defaultsDeep({}, options, WEBVIEWER_OPTIONS);

  const onDocumentLoaded = async () => {
    const { PDFNet, docViewer: documentViewer } = webViewerRef.current;

    await PDFNet.initialize();

    setTotalPages(documentViewer.getPageCount());
    setCurrentPage(documentViewer.getCurrentPage());

    documentViewer.setToolMode(
      documentViewer.getTool(TOOL_MODES.AnnotationEdit)
    );
    setDocumentLoading(false);
    setAnnotationsApplying(false);
    setDocumentLoaded(true);

    appliedFields.current = [];
  };

  const onAnnotationsLoaded = () => {
    setAnnotationsLoaded(true);
  };

  const setStyles = () => {
    if (backgroundColor) {
      const iframeDoc = webViewerRef.current.iframeWindow.document;
      const documentContainer = iframeDoc.querySelector(".DocumentContainer");
      documentContainer.setAttribute("style", `background:${backgroundColor}`);
    }
  };

  const initialize = async () => {
    await insertScripts(PDF_TRON_DEPENDENCIES);
    const { default: WebViewer } = await import("@pdftron/webviewer");

    const instance = await WebViewer(
      {
        path: "/webviewer/",
        licenseKey: LICENSE_KEY,
      },
      viewerNodeRef.current
    );

    instance.disableElements([
      "toolsHeader",
      "header",
      "pageNavOverlay",
      "annotationCommentButton",
      "annotationStyleEditButton",
      "linkButton",
      "contextMenuPopup",
    ]);

    /* NOTE: Enabling embedded javascript is causing drastic memory leak on getFileData() call */
    instance.CoreControls.disableEmbeddedJavaScript();
    instance.setFitMode(instance.FitMode.Zoom);
    const { docViewer, annotManager } = instance;
    docViewer.setOptions({ enableAnnotations: true });
    docViewer.on("documentLoaded", onDocumentLoaded);
    // @ts-ignore
    docViewer.on("pageNumberUpdated", setCurrentPage);
    docViewer.on("annotationsLoaded", onAnnotationsLoaded);

    annotManager.setPermissionCheckCallback(() => true);

    webViewerRef.current = instance;
    setStyles();
    setInitialized(true);

    return () => {
      docViewer.off("documentLoaded", onDocumentLoaded);
      // @ts-ignore
      docViewer.off("pageNumberUpdated", setCurrentPage);
    };
  };

  // Viewer API

  const loadDocument = (
    source,
    sourceOptions = { filename: "document.pdf" }
  ) => {
    const { docViewer: documentViewer } = webViewerRef.current;

    setDocumentLoaded(false);
    setAnnotationsLoaded(false);
    setDocumentLoading(true);
    return documentViewer.loadDocument(source, sourceOptions);
  };

  const importAnnotations = (xfdfString) => {
    if (!isDocumentLoaded) return;

    const { annotManager: annotationManager } = webViewerRef.current;
    annotationManager.importAnnotations(xfdfString);
  };

  const zoomTo = (value: number) => {
    const { docViewer: documentViewer } = webViewerRef.current;
    documentViewer.zoomTo(value);
  };

  const addAnnotation = ({ id, name, type, meta }) => {
    const {
      Annotations,
      docViewer: documentViewer,
      annotManager: annotationManager,
    } = webViewerRef.current;
    const pageNumber = documentViewer.getCurrentPage();
    const { annotation: settings, checkbox: checkboxSettings } = viewerOptions;
    const isCheckboxField = type === ANNOTATION_TYPES.checkbox;

    if (pageNumber) {
      const pageWidth = documentViewer.getPageWidth(pageNumber);
      const pageHeight = documentViewer.getPageHeight(pageNumber);

      const annotation = new Annotations.FreeTextAnnotation();
      annotation.LockedContents = true;
      annotation.Width = isCheckboxField
        ? checkboxSettings.Width
        : settings.Width;
      annotation.Height = isCheckboxField
        ? checkboxSettings.Height
        : settings.Height;

      const rotation =
        documentViewer.getCompleteRotation(pageNumber) * ANGLE_90;

      annotation.Rotation = rotation;

      if (rotation === ANGLE_90 || rotation === ANGLE_270) {
        const width = annotation.Width;
        annotation.Width = annotation.Height;
        annotation.Height = width;
      }

      annotation.X = pageWidth / 2 - annotation.Width / 2;
      annotation.Y = pageHeight / 2 - annotation.Height / 2;
      annotation.PageNumber = pageNumber;
      annotation.CustomData = {
        id,
        name,
        type,
        isVeroAnnotation: true,
        meta,
      };
      annotation.setPadding(new Annotations.Rect(...settings.Padding));
      annotation.setContents(isCheckboxField ? "X" : name);
      annotation.FontSize = settings.FontSize;
      annotation.FillColor = new Annotations.Color(...settings.FillColor);
      annotation.TextColor = new Annotations.Color(...settings.TextColor);
      annotation.StrokeThickness = settings.StrokeThickness;
      annotation.StrokeColor = new Annotations.Color(...settings.StrokeColor);
      annotation.TextAlign = settings.TextAlign;

      annotationManager.addAnnotation(annotation);
      annotationManager.redrawAnnotation(annotation);
    }
  };

  const applyFieldAnnotation = (annotation) => {
    const { Annotations, annotManager: annotationManager } =
      webViewerRef.current;

    const flags = new Annotations.WidgetFlags();
    const field = new Annotations.Forms.Field(annotation.CustomData.name, {
      type: "Tx",
      value: annotation.CustomData.name,
      flags,
    });
    const inputAnnotation = new Annotations.TextWidgetAnnotation(field);
    inputAnnotation.PageNumber = annotation.getPageNumber();
    inputAnnotation.X = annotation.getX();
    inputAnnotation.Y = annotation.getY();
    inputAnnotation.rotation = annotation.Rotation;

    const isRotated =
      annotation.Rotation === ANGLE_90 || annotation.Rotation === ANGLE_270;

    inputAnnotation.Width = isRotated
      ? annotation.getHeight()
      : annotation.getWidth();
    inputAnnotation.Height = isRotated
      ? annotation.getWidth()
      : annotation.getHeight();

    annotationManager.getFieldManager().addField(field);
    return inputAnnotation;
  };

  const applyCheckboxAnnotation = (annotation) => {
    const { Annotations, annotManager: annotationManager } =
      webViewerRef.current;

    const flags = new Annotations.WidgetFlags();
    flags.set("Edit", true);

    // create a form field
    const field = new Annotations.Forms.Field(annotation.CustomData.name, {
      type: "Btn",
      value: "Yes",
      flags,
    });
    // create a widget annotation
    const checkboxAnnotation = new Annotations.CheckButtonWidgetAnnotation(
      field,
      {
        appearance: "Yes",
        appearances: {
          Off: {},
          Yes: {},
        },
        captions: {
          Normal: "8",
        },
      }
    );

    // set position and size
    checkboxAnnotation.PageNumber = annotation.getPageNumber();
    checkboxAnnotation.X = annotation.getX();
    checkboxAnnotation.Y = annotation.getY();
    checkboxAnnotation.rotation = annotation.Rotation;

    const isRotated =
      annotation.Rotation === ANGLE_90 || annotation.Rotation === ANGLE_270;

    checkboxAnnotation.Width = isRotated
      ? annotation.getHeight()
      : annotation.getWidth();
    checkboxAnnotation.Height = isRotated
      ? annotation.getWidth()
      : annotation.getHeight();

    // add the form field and widget annotation
    annotationManager.getFieldManager().addField(field);

    return checkboxAnnotation;
  };

  const applyAnnotations = async () => {
    const { docViewer: documentViewer, annotManager: annotationManager } =
      webViewerRef.current;
    setAnnotationsApplying(true);
    const annotationsList = annotationManager.getAnnotationsList();
    const annotationsToDelete = [];
    const annotationsToDraw = [];
    const fields = [];
    const veroAnnotations = annotationsList.filter(
      (annotation) => annotation?.CustomData?.isVeroAnnotation
    );

    await Promise.all(
      veroAnnotations.map(async (annotation) => {
        if (annotation.CustomData.type === ANNOTATION_TYPES.field) {
          const inputAnnotation = applyFieldAnnotation(annotation);
          annotationManager.addAnnotation(inputAnnotation);
          annotationsToDraw.push(inputAnnotation);
          fields.push(annotation.CustomData.id);
          annotationsToDelete.push(annotation);
        } else if (annotation.CustomData.type === ANNOTATION_TYPES.checkbox) {
          const checkboxAnnotation = applyCheckboxAnnotation(annotation);
          annotationManager.addAnnotation(checkboxAnnotation);
          annotationsToDraw.push(checkboxAnnotation);
          fields.push(annotation.CustomData.id);
          annotationsToDelete.push(annotation);
        }
      })
    );

    annotationManager.deleteAnnotations(annotationsToDelete, null, true);
    annotationManager.drawAnnotationsFromList(annotationsToDraw);
    documentViewer.refreshAll();
    documentViewer.updateView();
    setAnnotationsApplying(false);

    const newFields = [...appliedFields.current, ...fields];
    appliedFields.current = newFields;

    return newFields;
  };

  const getAnnotationsList = () => {
    const { annotManager: annotationManager } = webViewerRef.current;
    const annotationsList = annotationManager.getAnnotationsList();
    return annotationsList
      .filter((annotation) => annotation?.CustomData?.isVeroAnnotation)
      .map((item) => item.CustomData);
  };

  const setPage = (num: number) => {
    const { docViewer: documentViewer } = webViewerRef.current;
    if (isInitialized && num > 0) {
      const pageNumber = num > totalPages ? totalPages : num;
      documentViewer.setCurrentPage(pageNumber);
    }
  };

  const getFileDataInArrayBuffer = async () => {
    const { docViewer: documentViewer, annotManager: annotationManager } =
      webViewerRef.current || {};
    const xfdfString = await annotationManager?.exportAnnotations();
    const file = await documentViewer?.getDocument()?.getFileData({
      xfdfString,
    });
    return file;
  };

  const getFileData = async () => {
    const file = await getFileDataInArrayBuffer();
    if (!file) return null;
    const fileContent = arrayBufferToBase64(file);
    return fileContent;
  };

  const exportAnnotations = async () => {
    if (!isDocumentLoaded) return;

    const { docViewer: documentViewer, annotManager: annotationManager } =
      webViewerRef.current;

    const xfdfString = await annotationManager.exportAnnotations({
      links: false,
      widgets: false,
      fields: false,
    });

    const filename = documentViewer
      .getDocument()
      ?.getFilename()
      ?.replace(/.pdf$/, ".xml");

    downloadTextFile(xfdfString, filename);
  };

  const getAppliedFields = () => [...appliedFields.current];

  const getFieldManager = () =>
    webViewerRef.current.annotManager.getFieldManager();

  const downloadDocument = async () => {
    const fileContent = await getFileData();
    if (fileContent) {
      setDocumentDownloading(true);
      const { docViewer: documentViewer } = webViewerRef.current;
      const document = documentViewer.getDocument();
      downloadPDF(fileContent, document.getFilename());
      setDocumentDownloading(false);
    }
  };

  const getZoom = () => webViewerRef.current.getZoomLevel();

  return {
    initialize,
    isInitialized,
    loadDocument,
    zoomTo,
    getZoom,
    addAnnotation,
    applyAnnotations,
    isDocumentLoading,
    isApplyingAnnotations,
    isDocumentLoaded,
    isAnnotationsLoaded,
    currentPage,
    totalPages,
    setPage,
    getFileData,
    getFileDataInArrayBuffer,
    getAppliedFields,
    getFieldManager,
    downloadDocument,
    viewerNodeRef,
    isDocumentDownloading,
    exportAnnotations,
    importAnnotations,
    getAnnotationsList,
  };
};

export default useWebViewer;
