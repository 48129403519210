import React from "react";

import { Modal } from "shared/components/Modal";
import { BREAKPOINT_TYPES } from "shared/config/constants";
import withBreakpoint from "shared/hocs/withBreakpoint";

import LumenDescription from "./LumenDescription";

interface ILumenDescriptionModal {
  breakpoint: BREAKPOINT_TYPES;
}

const LumenDescriptionModal = ({ breakpoint }: ILumenDescriptionModal) => (
  <Modal
    title="How it works"
    submitButtonLabel="GOT IT"
    showCancelLink={false}
    width="730px"
    centered
    fullScreen={BREAKPOINT_TYPES.xs === breakpoint}
  >
    <Modal.Body noPaddingTop noPaddingBottom>
      <LumenDescription />
    </Modal.Body>
  </Modal>
);

export default withBreakpoint(LumenDescriptionModal);
