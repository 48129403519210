import { useEffect } from "react";

import { listenToChildrenChange } from "shared/utils/dom";

/**
 * Hook for navigating to the element specified in the url hash when that element becomes active/visible.
 * This is a common problem in SPA where we do the navigation first, then load the data needed for the screen while
 * showing a spinner on the page. When the data is available the screen is rendered completely.
 * This hook solves this problem by waiting for the element to be active/visible and then navigates to it.
 */
const useAnchorNavigateToElementWhenActive = () => {
  const { hash, pathname } = window.location;
  useEffect(() => {
    let observer = null;
    const cleanUp = () => {
      if (observer) {
        observer.disconnect();
      }
    };

    if (hash) {
      const { body } = document;
      const listener = () => {
        const cleanHash = (hash || "").substring(1);
        const elm = document.getElementById(cleanHash);
        if (elm) {
          elm.scrollIntoView({
            block: "start",
          });
          cleanUp();
        }
      };
      observer = listenToChildrenChange(body, listener);
    } else {
      window.scrollTo(0, 0);
    }

    return cleanUp;
  }, [pathname]);
};

export default useAnchorNavigateToElementWhenActive;
