import React from "react";

import styled from "@emotion/styled";
import PropTypes from "prop-types";

import FlexContainer from "shared/components/FlexContainer";
import BaseTile from "shared/components/Tile";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

const Tile = styled(BaseTile)`
  width: 480px;
  min-height: 280px;
  margin-right: 16px;
  ${MEDIA_QUERIES.w.ltSm} {
    width: 100vw;
    margin-right: 0;
  }
  overflow: auto;
  .tile-children-container {
    justify-content: center;
  }
  .tile-description {
    font-size: 15px;
    align-self: center;
    line-height: 22.5px;
    padding: 0px 44px;
  }
`;

const InnerContainer = styled.div`
  height: 100%;
`;

const TileInner = ({ children }) => (
  <BaseTile.Inner>
    <InnerContainer>{children}</InnerContainer>
  </BaseTile.Inner>
);

const TileLink = styled.a`
  text-align: center;
  height: 100%;
`;

const TileContent = styled(FlexContainer)`
  height: 100%;
`;

TileInner.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]).isRequired,
};

const PartnerLogo = styled.img`
  cursor: pointer;
  max-height: 85px;
  max-width: 150px;
  margin-bottom: 20px;
`;

const ReadMore = styled.div`
  text-align: center;
  margin-top: 14px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  white-space: nowrap;
  color: ${COLORS.accentDark};
`;

const ReadMoreIcon = styled.div`
  height: 15px;
  text-align: left;
  display: inline-block;
  top: 0.125em;
  left: 0.125em;
  position: relative;
`;

export {
  Tile,
  TileInner,
  TileContent,
  TileLink,
  PartnerLogo,
  ReadMore,
  ReadMoreIcon,
};
