import React from "react";

import ConfirmationModal from "shared/components/Modals/ConfirmationModal";
import useModal from "shared/hooks/useModal";

import PropertySettingsFooter from "./PropertySettingsFooter";

interface PropertySettingsFooterContainerProps {
  propertyId: number;
  propertyName: string;
  portfolioName: string;
  copyPortfolioSettings: (id: number) => Promise<any>;
  resetPropertySettings: (id: number) => Promise<any>;
  isPortfolioSettings: boolean;
}

const PropertySettingsFooterContainer = ({
  propertyId,
  propertyName,
  portfolioName,
  copyPortfolioSettings,
  resetPropertySettings,
  isPortfolioSettings,
}: PropertySettingsFooterContainerProps) => {
  const { openModalDialog } = useModal();

  const openCustomizeSettingsModal = () => {
    return openModalDialog(ConfirmationModal, {
      title: "You’re going to update property settings",
      subtitle: (
        <span>
          Property: <strong>{propertyName}</strong>
        </span>
      ),
      submitButtonLabel: "Confirm & Continue",
      message: (
        <>
          <strong>Please note:</strong> Once you click &quot;Confirm and
          Continue&quot; new settings will be created for this specific property
          and the portfolio setup will no longer apply.
        </>
      ),
      onConfirm: () => copyPortfolioSettings(propertyId),
    });
  };

  const openResetSettingsModal = () => {
    return openModalDialog(ConfirmationModal, {
      title: "Reset to portfolio settings?",
      subtitle: (
        <span>
          Portfolio: <strong>{portfolioName}</strong>
        </span>
      ),
      submitButtonLabel: "Reset",
      message: (
        <>
          <strong>Please note:</strong> Once you click &quot;Reset&quot; the
          property settings will be overridden with the current portfolio setup
          and the changes you previously made will no longer apply.
        </>
      ),
      onConfirm: () => resetPropertySettings(propertyId),
    });
  };

  return (
    <PropertySettingsFooter
      isPortfolioSettings={isPortfolioSettings}
      openCustomizeSettingsModal={openCustomizeSettingsModal}
      openResetSettingsModal={openResetSettingsModal}
    />
  );
};

export default PropertySettingsFooterContainer;
