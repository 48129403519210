import styled from "@emotion/styled";

import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

const AttachmentGreenHeader = styled.div`
  display: flex;
  align-items: center;
  background-color: ${COLORS.lightGreen};
  border-bottom: 1px solid ${COLORS.borders};
  padding: 19px 24px;

  .attachment-header-message {
    flex: 1;
    margin-right: 10px;
  }

  .attachment-header-cancel-link {
    margin-right: 24px;
  }

  .attachment-header-confirm-button {
    background-color: transparent;
  }

  ${MEDIA_QUERIES.w.ltMd} {
    flex-direction: column;
    justify-content: flex-start;

    .attachment-header-message {
      margin-right: 0;
    }

    .attachment-header-cancel-link {
      margin-right: 0;
    }

    > * {
      margin-bottom: 10px;
    }
  }
`;

export { AttachmentGreenHeader };
