import styled from "@emotion/styled";
import { Row as BaseRow, Col as BaseCol } from "antd";

import { Select } from "shared/components/Form";

const StyledSelect = styled(Select)`
  width: 200px;
`;

const InputContainer = styled.div`
  display: inline-flex;
  align-items: center;

  .option-input {
    width: 210px;
  }

  .option-delete-icon {
    margin-left: 5px;
    width: 30px;
    height: 30px;
  }
`;

const Row = styled(BaseRow)`
  display: flex;
  flex-wrap: wrap;
`;

const Col = styled(BaseCol)`
  height: 65px;
`;

export { StyledSelect, InputContainer, Row, Col };
