import React from "react";

import { Modal } from "shared/components/Modal";

import { Content } from "./styled";

export interface InformationModalProps {
  title: string;
  description: React.ReactNode;
  subtitle?: React.ReactNode;
  content?: React.ReactNode;
  centered?: boolean;
  width?: number;
}

const InformationModal = ({
  title,
  subtitle = undefined,
  description,
  content = undefined,
  centered = undefined,
  width = undefined,
}: InformationModalProps) => {
  return (
    <Modal
      showSubmitButton={false}
      showCancelLink={false}
      title={title}
      subtitle={subtitle}
      centered={centered}
      width={width}
    >
      <Modal.Body noPaddingTop noPaddingBottom>
        <Content>{description}</Content>
        {content && <Content>{content}</Content>}
      </Modal.Body>
    </Modal>
  );
};

export default InformationModal;
