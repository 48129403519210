import React from "react";

import styled from "@emotion/styled";

import { COLORS } from "shared/config/constants";

const Table = styled("table")({
  borderRadius: "2px",
  width: "100%",
  tableLayout: "auto",
});

const Thead = styled("thead")({
  backgroundColor: COLORS.checkboxTable.header.background,
});

const Tbody = styled("tbody")({
  backgroundColor: COLORS.checkboxTable.body.background,
});

const Tr = styled("tr")({});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Th = styled(({ alt, ...props }) => <th {...props} />)((props) => ({
  padding: "16px 24px",
  fontWeight: 500,
  fontSize: "17px",
  borderRight: `1px solid ${COLORS.checkboxTable.borders}`,
  borderBottom: `1px solid ${COLORS.checkboxTable.borders}`,
  whiteSpace: "nowrap",
  "&:last-child": {
    width: "100%",
    borderRight: 0,
  },
  ...(props.alt === true && {
    backgroundColor: COLORS.checkboxTable.category.background,
    borderBottom: 0,
    borderTop: `1px solid ${COLORS.checkboxTable.borders}`,
  }),
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Td = styled(({ checkbox, last, ...props }) => <td {...props} />)(
  (props) => ({
    padding: "18px 24px",
    borderBottom: `1px solid ${COLORS.checkboxTable.borders}`,
    borderRight: `1px solid ${COLORS.checkboxTable.borders}`,
    whiteSpace: "nowrap",
    "&:last-child": {
      width: "100%",
      borderRight: 0,
    },
    ...(props.checkbox === true && {
      textAlign: "center",
    }),
    ...(props.last === true && {
      borderBottom: 0,
    }),
  })
);

const FirstColFiller = styled("div")({
  width: "380px",
});

export { Table, Thead, Tbody, Tr, Th, Td, FirstColFiller };
