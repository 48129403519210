import React from "react";

import { useParams } from "react-router-dom";

import { useGenerateLeadPDF, useGetLead } from "manager/hooks/api";

import { backLinkPropType } from "shared/utils/propTypes";

import { useLeadDropdownItems } from "../Leads/utils";

import LeadDetails from "./LeadDetails";

const LeadDetailsContainer = ({ backLink }) => {
  const { id: leadId } = useParams();
  const { lead, isLeadLoading } = useGetLead(leadId);
  const { downloadLeadPDF: downloadPdf } = useGenerateLeadPDF(leadId);
  const dropdownItems = useLeadDropdownItems({ lead, downloadPdf });

  return (
    <LeadDetails
      lead={lead}
      isLeadLoading={isLeadLoading}
      backLink={backLink}
      dropdownItems={dropdownItems}
    />
  );
};

LeadDetailsContainer.propTypes = {
  backLink: backLinkPropType.isRequired,
};

export default LeadDetailsContainer;
