import React from "react";

import { Formik } from "formik";
import { Helmet } from "react-helmet";

import {
  StyledFooterBar,
  FooterButton,
  YardiMappingHeader,
} from "manager/pages/Marketplace/integrations/yardi/shared/mapping/styled";
import YardiDropdownMappingField from "manager/pages/Marketplace/integrations/yardi/shared/YardiDropdownMappingField";
import YardiMappingPage from "manager/pages/Marketplace/integrations/yardi/shared/YardiMappingPage";
import { YardiTitleContainer } from "manager/pages/Marketplace/integrations/yardi/styled";
import Container from "shared/components/Container";
import { PreventLeaveFormModal } from "shared/components/Form";
import { BackLink } from "shared/components/Links";
import PageTitle from "shared/components/PageTitle";
import Spacer from "shared/components/Spacer";
import { BackLinkProps } from "shared/interfaces/misc";

import {
  YardiChargeTypeMappingValues,
  YardiChargeType,
  CredentialsHeaderType,
  CHARGE_TYPES,
} from "../shared/yardi.config";
import YardiHeaderCredentials from "../shared/YardiHeaderCredentials";

const title = "Charge Types";

interface SetupYardiChargeTypesProps {
  backLink: BackLinkProps;
  yardiChargeTypes?: Array<YardiChargeType>;
  initialValues?: YardiChargeTypeMappingValues;
  onFormSubmit: (data: any, callbacks: any) => void;
  onSave: (values: any) => void;
  yardiCredentials?: CredentialsHeaderType;
}

const SetupYardiChargeTypes = ({
  backLink,
  yardiChargeTypes,
  initialValues,
  onFormSubmit,
  onSave,
  yardiCredentials,
}: SetupYardiChargeTypesProps) => {
  const dropdownPlaceholder = "Select charge type";
  const options = (yardiChargeTypes || []).map((type) => ({
    value: type.id,
    label: type.description,
  }));

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onFormSubmit}
      enableReinitialize
      validateOnMount
    >
      {({ dirty, isSubmitting, isValid, submitForm, values }) => (
        <>
          <Container expand>
            <Helmet>
              <title>{title}</title>
            </Helmet>
            {backLink && (
              <BackLink.Container>
                <BackLink {...backLink} />
              </BackLink.Container>
            )}
            <PageTitle.Container center>
              <div>
                <YardiTitleContainer>Charge Types</YardiTitleContainer>
              </div>
              <YardiHeaderCredentials yardiCredentials={yardiCredentials} />
            </PageTitle.Container>

            <YardiMappingPage
              title={
                <YardiMappingHeader>
                  Please map the charge types to the corresponding VERO charge
                  types
                </YardiMappingHeader>
              }
              from="VERO"
              to="External Provider"
              elements={CHARGE_TYPES.map(({ id, label }) => ({
                title: label,
                node: (
                  <YardiDropdownMappingField
                    name={id}
                    dropdownPlaceholder={dropdownPlaceholder}
                    options={options}
                  />
                ),
              }))}
            />
            <PreventLeaveFormModal
              title="Exit charge types setup"
              subtitle="Would you like to save your changes?"
              submitForm={() => onSave(values)}
              isSubmitting={isSubmitting}
              preventLeaveWhen={dirty}
              cancelLinkLabel="No"
              submitButtonLabel="Yes"
              customOnCancel={undefined}
              customOnConfirm={undefined}
              onNextLocationTriggered={undefined}
            >
              <Spacer />
            </PreventLeaveFormModal>
          </Container>
          <Container noPaddingBottom noPaddingTop stickToBottom>
            <StyledFooterBar>
              <FooterButton
                type="primary"
                onClick={submitForm}
                disabled={!isValid}
                loading={isSubmitting}
              >
                CONFIRM
              </FooterButton>
            </StyledFooterBar>
          </Container>
        </>
      )}
    </Formik>
  );
};

export default SetupYardiChargeTypes;
