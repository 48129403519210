import { normalize } from "normalizr";
import { handleActions } from "redux-actions";

import * as actions from "../actions";
import * as schemas from "../schema";

const initialState = {};

const dealEntityReducer = handleActions(
  {
    [actions.receiveDeal]: (state, action) => {
      const { deal } = action.payload;
      return {
        ...state,
        ...normalize([deal], schemas.dealList).entities.deal,
      };
    },
    [actions.receiveDeals]: (state, action) => {
      const { deals } = action.payload;

      return {
        ...state,
        ...normalize(deals, schemas.dealList).entities.deal,
      };
    },
  },
  initialState
);

export default dealEntityReducer;
