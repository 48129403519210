import React from "react";

import { Button } from "shared/components/Button";
import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";

import { StyledFooterBar } from "../styled";

interface LeadCompletedFooterProps {
  inviteLeadToApply: () => void;
}

const LeadCompletedFooter = ({
  inviteLeadToApply,
}: LeadCompletedFooterProps) => (
  <StyledFooterBar>
    <FlexContainer alignItems="center" justifyContent="flex-end" fullWidth>
      {/* @ts-ignore */}
      <Button
        type="primary"
        onClick={inviteLeadToApply}
        SuffixIcon={Icon.ButtonForwardArrowIcon}
      >
        INVITE TO APPLY
      </Button>
    </FlexContainer>
  </StyledFooterBar>
);

export default LeadCompletedFooter;
