import { useMutation } from "react-query";

import api from "manager/lib/api";
import { NOTIFICATIONS } from "shared/config/constants";
import { openNotification } from "shared/utils/ui";

export const useTogglePropertyDisplayOnPortal = () => {
  const [
    togglePropertyDisplayOnPortal,
    { isLoading: isSavingDisplayOnPortal },
  ] = useMutation(api.togglePropertyDisplayOnPortal, {
    onError: () => {
      openNotification(
        "Failed to change property availability on portal",
        NOTIFICATIONS.error
      );
    },
  });
  return {
    togglePropertyDisplayOnPortal,
    isSavingDisplayOnPortal,
  };
};

export const useToggleUnitDisplayOnPortal = () => {
  const [toggleUnitDisplayOnPortal, { isLoading: isSavingDisplayOnPortal }] =
    useMutation(api.toggleUnitDisplayOnPortal, {
      onError: () => {
        openNotification(
          "Failed to change unit availability on portal",
          NOTIFICATIONS.error
        );
      },
    });
  return {
    toggleUnitDisplayOnPortal,
    isSavingDisplayOnPortal,
  };
};
