import styled from "@emotion/styled";

import Icon from "shared/components/Icon";
import { RegularText } from "shared/components/Typography";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

const IconContainer = styled.div`
  width: 1px;
`;

const LoaderContainer = styled.div`
  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 70px;
    height: 70px;
    z-index: 20;
  }
`;

const GreenArrowIcon = styled(Icon.GreenArrowThin)`
  width: ${(props) => props.width || "auto"};
  transform: ${(props) => props.rotate || "none"};
  path {
    stroke: ${COLORS.accent};
  }
`;

const VerticalDividerContainer = styled.div`
  max-width: 30px;
  margin: auto;
  > div {
    min-height: 30px;
  }
`;

const InfoBanner = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 45px;
  background-color: ${COLORS.primary};
  span {
    color: white;
  }
  .icons-wrapper {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
  }
`;

const OptionGroupLabel = styled.span`
  color: ${COLORS.lightVioletTheme.stepsWheel};
  font-weight: 500;
`;

const FieldNameWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  > div {
    width: 100%;
  }
  .input-field {
    position: relative;
    width: 100%;
    min-width: 160px;
    color: ${COLORS.grayTheme.textColor};
  }
  .ant-input-affix-wrapper .ant-input-suffix {
    pointer-events: none;
  }
  .error-message {
    position: absolute;
    bottom: -20px;
  }
`;

const FilterBarIconWrapper = styled.div`
  cursor: pointer;
  .icon-question-mark {
    width: 24px;
    height: 24px;
    path {
      fill: ${COLORS.dashboard.lightViolet2};
    }
  }
`;

const ModalSubtitle = styled(RegularText)`
  position: relative;
  font-size: 12px;
  font-weight: 500;
`;

const ModalContent = styled.div`
  background-color: ${COLORS.lightGray2};
  padding: 32px;
`;

const ModalTextTitle = styled(RegularText)`
  font-weight: 500;
  font-size: 15px;
  line-height: 150%;
  margin-top: 24px;
  margin-bottom: 8px;
  text-transform: uppercase;
  color: ${COLORS.dashboard.stackedBarChart.lightViolet3};
  display: block;
  &:first-of-type {
    margin-top: 0;
  }
`;

const ModalText = styled(RegularText)`
  font-weight: 300;
  line-height: 150%;
  display: block;
`;

const TableWrapper = styled.div`
  td.column-index {
    padding-right: 0;
  }
  td.column-name {
    padding-left: 0;
    padding-right: 0;
  }
  th.column-name {
    padding-left: 0;
  }
  td.column-map-to-vero-field-type {
    padding-left: 0;
    padding-right: 0;
  }
  th.column-map-to-vero-field-type {
    padding-left: 0;
  }
  td.column-field-data-points {
    padding-left: 0;
    position: relative;
    .error-message {
      position: absolute;
      bottom: 0;
    }
  }
  th.column-field-data-points {
    padding-left: 0;
  }
  ${MEDIA_QUERIES.w.btwMdXl} {
    td.column-name {
      padding-left: 10px;
      padding-right: 10px;
    }
    th.column-name {
      padding-left: 10px;
    }
    td.column-map-to-vero-field-type {
      padding-left: 10px;
      padding-right: 10px;
    }
    th.column-map-to-vero-field-type {
      padding-left: 10px;
    }
    td.column-field-data-points {
      padding-left: 10px;
    }
    th.column-field-data-points {
      padding-left: 10px;
    }
  }
`;

export {
  LoaderContainer,
  IconContainer,
  GreenArrowIcon,
  VerticalDividerContainer,
  InfoBanner,
  OptionGroupLabel,
  FieldNameWrapper,
  FilterBarIconWrapper,
  ModalSubtitle,
  ModalContent,
  ModalTextTitle,
  ModalText,
  TableWrapper,
};
