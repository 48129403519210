import React from "react";

import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

import Layout from "manager/components/Layout";
import { LayoutProps, useLayout } from "manager/hooks/use-layout";

import { AppSummary } from "./component";

export const AppSummaryPage: React.FC<LayoutProps> = (props) => {
  const { header, sidebar, backLinks } = useLayout(props);
  const params = useParams<{ id: string }>();

  return (
    <Layout
      sidebar={sidebar}
      header={{
        ...header,
        backLink: backLinks.deal,
      }}
      withoutBackToTop
    >
      <Helmet>
        <title>Application Summary</title>
      </Helmet>
      <AppSummary dealId={params.id} />
    </Layout>
  );
};
