import dayjs from "dayjs";
import pluralize from "pluralize";

import { conditionalItem } from "./array";

const MONTHS = "MONTHS";

// NOTE if endDate is not provided we are setting it to the current date
const differenceBetweenDates = (startDate, endDate, unitOfTime) => {
  if (!startDate) {
    return 0;
  }
  const date = endDate || dayjs();

  return Math.abs(dayjs(date).diff(dayjs(startDate), unitOfTime));
};

export const numberOfDaysBetweenDates = (startDate, endDate) =>
  differenceBetweenDates(startDate, endDate, "days");

export const numberOfMonthsBetweenDates = (startDate, endDate) =>
  differenceBetweenDates(startDate, endDate, "months");

export const numberOfYearsBetweenDates = (startDate, endDate) =>
  differenceBetweenDates(startDate, endDate, "years");

export const formattedDifferenceBetweenDates = (
  startDate,
  endDate,
  separator = " ",
  hideDays = false
) => {
  let calculatedStartDate = startDate;
  const years = numberOfYearsBetweenDates(calculatedStartDate, endDate);

  if (years > 0) {
    calculatedStartDate = dayjs(calculatedStartDate)
      .add(years, "year")
      .format();
  }

  const months = numberOfMonthsBetweenDates(calculatedStartDate, endDate);

  if (months > 0) {
    calculatedStartDate = dayjs(calculatedStartDate)
      .add(months, "month")
      .format();
  }

  const days = numberOfDaysBetweenDates(calculatedStartDate, endDate);

  const dateParts = [
    ...conditionalItem(years > 0, `${years} ${pluralize("year", years)}`),
    ...conditionalItem(months > 0, `${months} ${pluralize("month", months)}`),
    ...conditionalItem(
      (days > 0 && !hideDays) || (days > 0 && years === 0 && months === 0),
      `${days} ${pluralize("day", days)}`
    ),
  ];

  return dateParts.join(separator);
};

export const numberOfRequiredDays = (duration, range) => {
  if (range === MONTHS) {
    return duration * 30;
  }
  return duration * 365;
};

export const hasMinimalRequiredDays = (
  allDates,
  requiredDuration,
  requiredRange
) => {
  const numberOfAllDays = allDates.reduce(
    (result, { startDate, endDate }) =>
      result + numberOfDaysBetweenDates(startDate, endDate),
    0
  );

  return (
    numberOfAllDays >= numberOfRequiredDays(requiredDuration, requiredRange)
  );
};

// NOTE we are adding 1 day to match BE calculation
// for example BE 1 month = 01/01/2022 - 01/31/2022 but in FE 1 month = 01/01/2022 - 02/01/2022
export const calculateDurationBetweenMonthsNormalized = (startDate, endDate) =>
  numberOfMonthsBetweenDates(startDate, dayjs(endDate).add(1, "day"));

// NOTE we are removing 1 day to match BE calculation
export const addMonthsNormalized = (date, numberOfMonths) =>
  dayjs(date).add(numberOfMonths, "months").add(-1, "day");

export const isFirstDayOfMonth = (value) => new Date(value).getDate() === 1;
