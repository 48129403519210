import React, { useState, ElementType } from "react";

const withHover = (Icon, IconHovered) => {
  // eslint-disable-next-line react/prop-types
  return function WithHoverComponent(allProps) {
    const { hovered, ...props } = allProps;
    return hovered === "true" || hovered === true ? (
      <IconHovered {...props} />
    ) : (
      <Icon {...props} />
    );
  };
};

export interface IHoverTracker {
  Icon?: ElementType;
  [key: string]: any;
}

export const HoverTracker = ({ Icon, ...props }: IHoverTracker) => {
  const [hovered, setHovered] = useState(false);

  return (
    <Icon
      hovered={hovered || undefined}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      {...props}
    />
  );
};

const getIcon = (Icon, IconHovered, IconDisabled) => (props) => {
  const [hovered, setHovered] = useState(false);
  // eslint-disable-next-line react/prop-types
  const { disabled } = props;

  let StateIcon;

  if (disabled) {
    StateIcon = IconDisabled || Icon;
  } else if (hovered) {
    StateIcon = IconHovered;
  } else {
    StateIcon = Icon;
  }

  return (
    <StateIcon
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      {...props}
    />
  );
};

export { withHover, getIcon };
