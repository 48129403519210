import React from "react";

import { FieldArray, useFormikContext } from "formik";

import { LUMEN_FILTER_CONDITION_TYPE } from "manager/config/lumen.config";
import FlexContainer from "shared/components/FlexContainer";

import AddConditionPanel from "./AddConditionPanel";
import ConditionAmount from "./ConditionAmount";
import ConditionKeywords from "./ConditionKeywords";
import ConditionTime from "./ConditionTime";

const CONDITION_COMPONENTS_PER_TYPE = Object.freeze({
  [LUMEN_FILTER_CONDITION_TYPE.TIME_PERIOD]: ConditionTime,
  [LUMEN_FILTER_CONDITION_TYPE.AMOUNT]: ConditionAmount,
  [LUMEN_FILTER_CONDITION_TYPE.KEYWORDS]: ConditionKeywords,
});

interface LumenFilterConditionsProps {
  mode: string;
}

const LumenFilterConditions = ({ mode }: LumenFilterConditionsProps) => {
  const { values } = useFormikContext();
  // @ts-ignore
  const hasConditions = !!values?.conditions?.length;

  return (
    <FlexContainer className="filter-conditions" flexDirection="column">
      <FieldArray
        name="conditions"
        render={({ remove, push }) => (
          <>
            {hasConditions && (
              <>
                {/* @ts-ignore */}
                {(values?.conditions || []).map((condition, idx) => {
                  const { conditionType } = condition;
                  const FilterCondition =
                    CONDITION_COMPONENTS_PER_TYPE[conditionType];
                  const key = idx + 1;
                  return (
                    <React.Fragment key={key}>
                      <FilterCondition idx={idx} onDelete={() => remove(idx)} />
                    </React.Fragment>
                  );
                })}
              </>
            )}
            <AddConditionPanel push={push} mode={mode} />
          </>
        )}
      />
    </FlexContainer>
  );
};

export default LumenFilterConditions;
