import styled from "@emotion/styled";

const SingleInputContent = styled.div`
  width: 100%;
  flex-direction: column;
  padding-top: 32px;
  padding-bottom: 40px;
  .input-description {
    font-weight: 400;
    margin-bottom: 17px;
    display: block;
  }
  textarea {
    min-height: 150px;
  }
`;

export { SingleInputContent };
