import styled from "@emotion/styled";
import { Tabs } from "antd";

import { COLORS } from "shared/config/constants";

const StyledTabs = styled(Tabs)<{
  tabPosition: any;
  activeKey?: any;
  defaultActiveKey?: string;
}>`
  .ant-tabs-bar {
    background-color: ${COLORS.tabs.background};
    margin-bottom: 0;
  }
  .ant-tabs-tab {
    font-size: 17px;
    font-weight: 300;
    margin: 0;
    padding: 14px 24px;
    &:hover {
      color: ${COLORS.tabs.hover};
      font-weight: 400;
    }
  }
  .ant-tabs-tab-active {
    font-weight: 400;
  }
  .ant-tabs-ink-bar {
    height: 3px;
  }
  .ant-tabs-nav-container-scrolling {
    padding-left: 10px;
    padding-right: 10px;
    .ant-tabs-tab-next {
      right: 0;
    }
  }
  .ant-tabs-tab-prev.ant-tabs-tab-arrow-show,
  .ant-tabs-tab-next.ant-tabs-tab-arrow-show {
    background-color: ${COLORS.tabs.background};
    border-bottom: 1px solid ${COLORS.borders};
  }
  .ant-tabs-tab-btn-disabled {
    display: none;
  }
`;

const NotificationContainer = styled("span")`
  bottom: 5px;
  position: relative;
`;

export { StyledTabs, NotificationContainer };
