import React, { useMemo } from "react";

import { Field, useFormikContext } from "formik";
import get from "lodash/get";

import { Select } from "shared/components/Form";

import { YardiAgentsPropType } from "../yardi.config";

import { YardiDropdownContainer } from "./styled";

interface YardiAgentDropdownProps {
  yardiAgentFormPath: string;
  inputDescription: string;
  agents?: YardiAgentsPropType;
}
const YardiAgentDropdown = ({
  yardiAgentFormPath,
  inputDescription,
  agents,
}: YardiAgentDropdownProps) => {
  const { errors, touched, setFieldValue, setFieldTouched } =
    useFormikContext();

  const options = useMemo(
    () =>
      // @ts-ignore
      agents.map((agent) => (
        <Select.Option key={agent.id}>{agent.fullName}</Select.Option>
      )),
    [agents]
  );

  return (
    <YardiDropdownContainer className="dropdown-container">
      <span className="input-description">{inputDescription}</span>
      <Field
        as={Select}
        name={yardiAgentFormPath}
        id={yardiAgentFormPath}
        label="Select agent"
        error={
          get(touched, yardiAgentFormPath) && get(errors, yardiAgentFormPath)
        }
        onBlur={() => setFieldTouched(yardiAgentFormPath, true)}
        onChange={(value) => setFieldValue(yardiAgentFormPath, value)}
      >
        {options}
      </Field>
    </YardiDropdownContainer>
  );
};

export default YardiAgentDropdown;
