import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { Col, Row } from "antd";
import ROUTES from "manager/config/routes";
import { useGetExperianAuthToken } from "manager/hooks/api/experianAuthQueries";
import { Company } from "manager/interfaces/api/company";
import { CompanyDashboardData } from "manager/interfaces/api/dashboard";
import ExperianAuthModal from "manager/pages/ExperianAuth/ExperianAuthModal";
import { isExperianProvider } from "manager/pages/ExperianAuth/utils";
import Container from "shared/components/Container";
import { BackLink } from "shared/components/Links";
import useModal from "shared/hooks/useModal";
import { BackLinkProps } from "shared/interfaces/misc";
import { getCachedActiveCompany } from "shared/utils/auth";

import { transformRoute } from "shared/utils/routing";

import {
  formatDashboardMoney,
  formatDashboardPercent,
  formatSubmitDistributionItems,
} from "./Dashboard.utils";
import DashboardBarChart from "./DashboardBarChart";
import DashboardCard from "./DashboardCard";
import DashboardHeader from "./DashboardHeader";
import DashboardPieChart from "./DashboardPieChart";
import DashboardStackedBarChart from "./DashboardStackedBarChart";
import DashboardTile from "./DashboardTile";
import RequestDashboardAccessCard from "./RequestDashboardAccessCard";
import { DashboardContainer, LargeLoader } from "./styled";

interface DashboardProps {
  company: Company;
  backLink: BackLinkProps;
  experianPrompt?: boolean;
  companyDashboardData: CompanyDashboardData;
  isLoading: boolean;
  isCompanyDashboardDataLoading: boolean;
  selectedPeriod: number;
  setSelectedPeriod: (value: number) => void;
}

const Dashboard = ({
  company,
  backLink,
  experianPrompt = false,
  companyDashboardData,
  isLoading,
  isCompanyDashboardDataLoading,
  selectedPeriod,
  setSelectedPeriod,
}: DashboardProps) => {
  const [fetchExperian, setFetchExperian] = useState(false);
  const [
    isCompanyDashboardDataLoadingState,
    setIsCompanyDashboardDataLoadingState,
  ] = useState(isCompanyDashboardDataLoading);
  const history = useHistory();
  const { push } = history;
  const { requestExperianAuthToken } = useGetExperianAuthToken();
  const { openModalDialog } = useModal();
  const navigateToExperianAuth = () => {
    push(transformRoute(ROUTES.experianAuthentication));
  };
  const renderExperianAuthModal = () =>
    openModalDialog(ExperianAuthModal, { submit: navigateToExperianAuth });
  const verifyExperianAuthentication = async () => {
    await requestExperianAuthToken(undefined, {
      onSuccess: (data) => {
        const experianResponse = data.experian;
        if (!experianResponse.userAuthenticated) {
          renderExperianAuthModal();
        }
      },
      onError: (error) => {
        // NOTE: not sure what to do if Experian is having issues
        console.log(error);
      },
    });
  };

  const {
    dealsSubmitted,
    dealsApproved,
    dealsCancelled,
    dealsDeclined,
    updatedAt,
    avgAnnualSalary,
    avgRent,
    avgRentPerFloorplan,
    submitDistribution = [],
    conversionRate,
    unitVacancyRate,
  } = companyDashboardData || {};

  const { name, enableDashboard } = company || {};

  const formattedSubmitDistribution =
    formatSubmitDistributionItems(submitDistribution);

  useEffect(() => {
    if (experianPrompt) renderExperianAuthModal();
  }, [experianPrompt]);

  useEffect(() => {
    if (!getCachedActiveCompany() && !isLoading) {
      window.location.href = ROUTES.selectCompany;
    }
  }, [company, isLoading]);

  useEffect(() => {
    if (isCompanyDashboardDataLoading !== isCompanyDashboardDataLoadingState) {
      setIsCompanyDashboardDataLoadingState(false);
      setFetchExperian(true);
    }
  }, [isCompanyDashboardDataLoading]);

  useEffect(() => {
    if (experianPrompt) return;
    if (!isLoading && company && isExperianProvider(company) && fetchExperian) {
      setFetchExperian(false);
      verifyExperianAuthentication();
    }
  }, [fetchExperian, company, isLoading]);

  return (
    <>
      <LargeLoader absoluteCenter showLoader={isLoading} />
      <Helmet>
        <title>{name}</title>
      </Helmet>
      {!isLoading && (
        <DashboardContainer>
          <BackLink.Container className="back-link">
            {/* @ts-ignore */}
            <BackLink {...backLink} />
          </BackLink.Container>
          {/* @ts-ignore */}
          <Container noMobilePaddingX noPaddingBottom>
            <DashboardHeader
              company={company}
              dealsSubmitted={dealsSubmitted}
              dealsApproved={dealsApproved}
              conversionRate={conversionRate}
              updatedAt={updatedAt}
              selectedPeriod={selectedPeriod}
              setSelectedPeriod={setSelectedPeriod}
              shouldExpand={!enableDashboard}
            />
          </Container>
          {enableDashboard ? (
            /* @ts-ignore */
            <Container className="content" noPaddingTop>
              <Row type="flex" gutter={[16, 16]}>
                <Col
                  className="application-submission-col"
                  xs={{ span: 24 }}
                  md={{ span: 16 }}
                >
                  <DashboardTile
                    title="Individual Submissions"
                    subtitle="APPLICATIONS"
                    tooltip="Results are based on each individual application submitted with the most popular submitted timeframes."
                  >
                    <DashboardStackedBarChart
                      chartData={formattedSubmitDistribution}
                      details="Time displayed as Eastern Time (US & Canada) (UTC-05:00)"
                    />
                  </DashboardTile>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                  <Row>
                    <DashboardTile
                      title="Application Conversion"
                      subtitle="APPLICATIONS"
                      tooltip="Results show the percentage for each application decision made."
                    >
                      <DashboardPieChart
                        chartData={[
                          { name: "APPROVED", value: dealsApproved },
                          { name: "CANCELLED", value: dealsCancelled },
                          { name: "DECLINED", value: dealsDeclined },
                        ]}
                      />
                    </DashboardTile>
                  </Row>
                  <Row>
                    <DashboardCard
                      title="Average Salary"
                      subtitle="APPLICATIONS"
                      value={formatDashboardMoney(avgAnnualSalary)}
                    />
                  </Row>
                  <Row>
                    <DashboardCard
                      title="Vacancy Rate"
                      subtitle="INVENTORY"
                      value={formatDashboardPercent(unitVacancyRate)}
                    />
                  </Row>
                </Col>
              </Row>
              <Row type="flex" gutter={[16, 16]}>
                <Col className="dashboard-tile-col" xs={{ span: 24 }}>
                  <DashboardTile
                    title="Average Market Rent"
                    subtitle="INVENTORY"
                    tooltip="Results are based on the average market rent for each floorplan determined by current listings. Floorplans are grouped by bedroom number."
                  >
                    <DashboardBarChart
                      title="Average Market Rent Amount:"
                      value={formatDashboardMoney(avgRent)}
                      legend="Floorplan Type: # Beds"
                      chartData={avgRentPerFloorplan}
                      formatTooltipValue={(value: number) =>
                        formatDashboardMoney(value)
                      }
                    />
                  </DashboardTile>
                </Col>
              </Row>
            </Container>
          ) : (
            <RequestDashboardAccessCard />
          )}
        </DashboardContainer>
      )}
    </>
  );
};

export default Dashboard;
