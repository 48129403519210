import React from "react";

import { Upload } from "antd";
import {
  RcFile,
  RcCustomRequestOptions,
  UploadChangeParam,
} from "antd/lib/upload/interface";

import Icon from "shared/components/Icon";
import { Modal } from "shared/components/Modal";

import { UPLOAD_ALLOWED_FILE_TYPES } from "./config";
import {
  UploadLogoButton,
  ChangeLogoButton,
  UploadWrapper,
  Instructions,
  LogoWrapper,
  LogoPreview,
  LogoDescription,
} from "./styled";

interface UploadPropertyLogoModalProps {
  document: {
    isLoading: boolean;
    file: object;
    preview: string;
  };
  uploadDocuments: (info: UploadChangeParam) => void;
  beforeUpload: (file: RcFile) => boolean;
  onSubmit: () => Promise<void>;
  isUpdatingProcess: boolean;
  propertyName: string;
  customRequest: (options: RcCustomRequestOptions) => void;
  isConfirming: boolean;
}

const UploadPropertyLogoModal = ({
  document,
  uploadDocuments,
  beforeUpload,
  onSubmit,
  isUpdatingProcess,
  propertyName,
  isConfirming,
  customRequest,
}: UploadPropertyLogoModalProps) => (
  // @ts-ignore
  <Modal
    title={isUpdatingProcess ? "Update property logo" : "Upload property logo"}
    subtitle={
      <span data-testid="subtitle">
        Property: <strong>{propertyName}</strong>
      </span>
    }
    closeOnSubmit={false}
    submit={onSubmit}
    submitButtonLabel="Confirm"
    showSubmitButton={Boolean(document.preview)}
    submitButtonDisabled={!document.file}
    submitting={isConfirming}
    showCancelLink={Boolean(document)}
    footer={
      document.preview && (
        <Upload
          onChange={uploadDocuments}
          showUploadList={false}
          beforeUpload={beforeUpload}
          accept={UPLOAD_ALLOWED_FILE_TYPES.join()}
          customRequest={customRequest}
        >
          <ChangeLogoButton data-testid="edit-button">
            Change Logo
          </ChangeLogoButton>
        </Upload>
      )
    }
  >
    <Modal.Body>
      <UploadWrapper data-testid="uploadArea">
        {document.preview ? (
          <>
            <LogoDescription>
              Square area in the middle will be shown as property logo
            </LogoDescription>
            <LogoWrapper>
              <LogoPreview alt="property-logo" src={document.preview} />
            </LogoWrapper>
          </>
        ) : (
          <>
            <Icon.EmptyImageIcon data-testid="empty-image" />
            <Upload
              customRequest={customRequest}
              onChange={uploadDocuments}
              showUploadList={false}
              beforeUpload={beforeUpload}
              accept={UPLOAD_ALLOWED_FILE_TYPES.join()}
            >
              <UploadLogoButton type="primary" data-testid="upload-button">
                Upload logo
              </UploadLogoButton>
            </Upload>
            <Instructions data-testid="doc-instructions">
              <p>
                Supported Formats: <b>PNG, JPEG</b>
              </p>
              <p>
                MAX file size: <b>5 MB</b>
              </p>
            </Instructions>
          </>
        )}
      </UploadWrapper>
    </Modal.Body>
  </Modal>
);

export default UploadPropertyLogoModal;
