import { queryCache } from "react-query";
import { useHistory } from "react-router-dom";

import { HTTP_ERROR_CODES } from "shared/config/constants";
import ROUTES from "shared/config/routes";

const refetchActiveQueries = (predicate) => {
  queryCache.invalidateQueries(predicate, { active: true });
};

const refetchMultipleActiveQueries = (predicates) => {
  predicates.forEach((predicate) =>
    queryCache.invalidateQueries(predicate, { active: true })
  );
};

const removeMultipleQueries = (predicates) => {
  predicates.forEach((predicate) => queryCache.invalidateQueries(predicate));
};

const useNotFoundErrorHandler = () => {
  const history = useHistory();

  const notFoundErrorHandler = (error) => {
    const isNotFoundError = error?.statusCode === HTTP_ERROR_CODES.notFound;

    if (isNotFoundError) {
      history.replace(ROUTES.notFound);
    }

    return isNotFoundError;
  };

  return notFoundErrorHandler;
};

export {
  refetchActiveQueries,
  refetchMultipleActiveQueries,
  removeMultipleQueries,
  useNotFoundErrorHandler,
};
