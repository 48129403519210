import React from "react";

import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import { ActionLink } from "shared/components/Links";
import { IntegrationProvider } from "shared/config/constants";

import { YardiText } from "../../styled";
import { HeaderDataTypes } from "../yardi.config";

import { getExternalProviderAccountName } from "./utils";

interface YardiHeaderCredentialsProps {
  headerData: HeaderDataTypes;
  provider: IntegrationProvider;
}

const YardiHeaderCredentials = ({
  headerData,
  provider,
}: YardiHeaderCredentialsProps) => (
  <FlexContainer alignItems="center">
    <YardiText>
      <Icon.PersonSmallIcon />
    </YardiText>
    <YardiText>External Provider Account:</YardiText>
    <YardiText fontWeight={400}>
      {getExternalProviderAccountName(provider, headerData?.yardiCredentials)}
    </YardiText>
    <YardiText>
      <ActionLink onClick={headerData?.editCredentials}>EDIT</ActionLink>
    </YardiText>
  </FlexContainer>
);

export default YardiHeaderCredentials;
