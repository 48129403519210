import React, { useEffect } from "react";

import { Col, Row } from "antd";
import { Helmet } from "react-helmet";

import { Company as CompanyInterface } from "manager/interfaces/api/company";
import FlexContainer from "shared/components/FlexContainer";
import { BasicHeader } from "shared/components/Header";
import Icon from "shared/components/Icon";
import RequireAuth from "shared/components/RequireAuth";
import { UserProfile } from "shared/interfaces/api";

import { getCompanySlugFromDomain } from "shared/utils/auth";

import {
  Container,
  Content,
  Header,
  Title,
  Subtitle,
  Company,
  CompanyInner,
  CompanyLogo,
  CompanyInfo,
  CompanyName,
  CompanyAddress,
  CompanyArrow,
  DeactivatedCompanyName,
  DeactivatedCompanyAddress,
  DeactivatedCompanyInner,
  DeactivatedMessage,
  NoCompaniesIcon,
  LoadingSpinner,
} from "./styled";

interface SelectCompanyProps {
  user?: UserProfile;
  companies?: CompanyInterface[];
  companySelected: boolean;
  selectCompany: (id: number, name: string) => void;
  logout: () => void;
  loading: boolean;
  dismissCompany: (id: number) => void;
  redirect: () => void;
}

const SelectCompany = ({
  user,
  companies,
  companySelected,
  selectCompany,
  logout,
  loading,
  dismissCompany,
  redirect,
}: SelectCompanyProps) => {
  const hasCompanies = companies?.length > 0;

  useEffect(() => {
    const onlyOneCompany = companies?.length === 1;

    if (onlyOneCompany) {
      if (!companySelected) {
        const company = companies?.[0];
        selectCompany(company.id, company.name);
      } else {
        redirect();
      }
    }

    const slug = getCompanySlugFromDomain();
    if (slug) {
      const company = companies.find((c) => c?.slug === slug);

      if (company) {
        selectCompany(company.id, company.name);
      }
    }
  }, [companies]);

  return (
    <RequireAuth
      render={() => (
        <>
          <Helmet>
            <title>Select Company</title>
          </Helmet>
          <Container data-testid="page-content">
            <BasicHeader
              user={
                user
                  ? {
                      name: `${user.firstName} ${user.lastName}`,
                      email: user.email,
                      phone: user.phoneNumber,
                      id: user.id,
                      signOut: logout,
                    }
                  : null
              }
            />
            <Content>
              <Header>
                <Title>Hi, {user && user.firstName}!</Title>
                <Subtitle>
                  {hasCompanies || loading
                    ? "Select the company you want to work with"
                    : "You have no companies to work with so far"}
                </Subtitle>
              </Header>
              {loading ? (
                <LoadingSpinner data-testid="loading-spinner" />
              ) : (
                <>
                  {hasCompanies ? (
                    <Row gutter={16}>
                      {companies?.map((company) => {
                        const companyAddress = `${company.address}, ${company.city}, ${company.state} ${company.zipcode}`;

                        return (
                          <Col key={company.id} xs={24} lg={12}>
                            {company.isDisabled ? (
                              <Company data-testid={`company-${company.id}`}>
                                <DeactivatedCompanyInner>
                                  <CompanyInfo>
                                    <CompanyLogo
                                      src={company.logo}
                                      alt={company.name}
                                      size="large"
                                    />
                                    <FlexContainer
                                      justifyContent="space-between"
                                      flexDirection="column"
                                      flexGrow="1"
                                    >
                                      <DeactivatedCompanyName>
                                        {company.name}
                                      </DeactivatedCompanyName>
                                      <div>
                                        <Icon.DeactivatedIcon />
                                        <DeactivatedMessage>
                                          You have been deactivated
                                        </DeactivatedMessage>
                                      </div>
                                      <DeactivatedCompanyAddress>
                                        {companyAddress}
                                      </DeactivatedCompanyAddress>
                                    </FlexContainer>
                                  </CompanyInfo>
                                  <div>
                                    <Icon.CloseIcon
                                      onClick={() => dismissCompany(company.id)}
                                      onKeyPress={() =>
                                        dismissCompany(company.id)
                                      }
                                    />
                                  </div>
                                </DeactivatedCompanyInner>
                              </Company>
                            ) : (
                              <Company
                                onClick={() =>
                                  selectCompany(company.id, company.name)
                                }
                                onKeyPress={() =>
                                  selectCompany(company.id, company.name)
                                }
                                role="button"
                                tabIndex={0}
                                data-testid={`company-${company.id}`}
                              >
                                <CompanyInner>
                                  <CompanyInfo>
                                    <CompanyLogo
                                      src={company.logo}
                                      alt={company.name}
                                      size="large"
                                    />
                                    <FlexContainer
                                      justifyContent="space-between"
                                      flexDirection="column"
                                      flexGrow="1"
                                    >
                                      <CompanyName>{company.name}</CompanyName>
                                      <CompanyAddress>
                                        {companyAddress}
                                      </CompanyAddress>
                                    </FlexContainer>
                                  </CompanyInfo>
                                  <CompanyArrow>
                                    <Icon.SelectArrowIcon />
                                  </CompanyArrow>
                                </CompanyInner>
                              </Company>
                            )}
                          </Col>
                        );
                      })}
                    </Row>
                  ) : (
                    <NoCompaniesIcon />
                  )}
                </>
              )}
            </Content>
          </Container>
        </>
      )}
    />
  );
};

export default SelectCompany;
