import axios from "axios";
import pickBy from "lodash/pickBy";

import ROUTES from "renter/config/apiRoutes";
import { API_URL } from "shared/lib/api/setupApi";
import { transformRoute } from "shared/utils/routing";

const api = {
  getTempMagicLinksCompany: ({ companyHashId }) =>
    axios.get(
      `${API_URL}${transformRoute(ROUTES.tempMagicLinksCompany, {
        companyHashId,
      })}`,
      {
        // @ts-ignore
        skipInterceptorErrorHandling: true,
      }
    ),
  postTempMagicLinksCompany: ({ companyHashId, dto }) =>
    axios.post(
      `${API_URL}${transformRoute(ROUTES.tempMagicLinksCompany, {
        companyHashId,
      })}`,
      dto
    ),
  getTempMagicLinksProperties: ({ companyHashId }) =>
    axios.get(
      `${API_URL}${transformRoute(ROUTES.magicLinkProperties, {
        companyHashId,
      })}`,
      {
        // @ts-ignore
        skipInterceptorErrorHandling: true,
      }
    ),
  createMagicDeal: ({
    application,
    dealType,
    companyHashId,
    email,
    externalUrl,
    isQrCode,
    userAgent,
    leaseStart,
    leaseMonths,
    moveInDate,
    externalReference,
  }: CreateMagicDealRequest) =>
    axios.post<CreateMagicDealResponse>(
      `${API_URL}${transformRoute(ROUTES.magicLinkDeal, {
        companyHashId: encodeURIComponent(companyHashId),
      })}`,
      {
        application_id: application,
        dealType,
        email,
        externalUrl,
        isQrCode,
        userAgent,
        lease_start_date: leaseStart,
        leaseMonths,
        moveInDate,
        external_reference: externalReference,
      }
    ),
  getMagicLinkLogo: ({ companyHashId, dealType }) =>
    axios
      .get(`${API_URL}${ROUTES.magicLinkLogo}`, {
        params: {
          magic_link: companyHashId,
          deal_type: dealType,
        },
      })
      .then(({ data }) => data),
  createMagicLinkV2Deal: ({
    application,
    dealType,
    company,
    propertySource,
    property,
    unit,
    leaseStart,
    leaseMonths,
    email,
    externalUrl,
    isQrCode,
    userAgent,
    externalReference,
  }) =>
    axios.post(
      `${API_URL}${ROUTES.magicLinkV2Deal}`,
      pickBy({
        application_id: application,
        deal_type: dealType,
        company_id: company,
        property_source: propertySource,
        property_id: property,
        unit_id: unit,
        lease_start_date: leaseStart,
        lease_months: leaseMonths,
        email,
        external_url: externalUrl,
        is_qr_code: isQrCode,
        user_agent: userAgent,
        external_reference: externalReference,
      })
    ),
  getMagicLinkV2Details: ({
    company,
    propertySource,
    property,
    unit,
    dealType,
  }) =>
    axios
      .get(`${API_URL}${ROUTES.magicLinkV2Details}`, {
        params: {
          company_id: company,
          property_source: propertySource,
          property_id: property,
          unit_id: unit,
          deal_type: dealType,
        },
      })
      .then(({ data }) => data),
};

export type CreateMagicDealResponse = {
  link: string;
};

export type CreateMagicDealRequest = {
  dealType: string;
  companyHashId: string;
  email: string;
  externalUrl: string;
  isQrCode: boolean;
  userAgent: string;
  leaseStart?: string;
  moveInDate?: string;
  leaseMonths?: number;
  application?: string | string[];
  externalReference?: string;
};

export default api;
