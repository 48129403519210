import React from "react";

import PropTypes from "prop-types";

import {
  Container,
  WheelContainer,
  Wheel,
  Fraction,
  CurrentNumber,
  Slash,
  TotalSteps,
  CurrentText,
  NextText,
  STEPS_WHEEL_THEMES,
} from "./styled";

export interface StepsWheelProps {
  items: {
    label?: Node | React.ReactNode;
    link?: string;
    desc?: Node | React.ReactNode;
  }[];
  current: number;
  showNext?: boolean;
  showDesc?: boolean;
  small?: boolean;
  theme?: any;
  [key: string]: any;
}

const StepsWheel = ({
  items,
  current,
  showNext,
  theme,
  small,
  showDesc,
  ...props
}: StepsWheelProps) => {
  const next = items?.[current + 1];
  const totalSteps = items.length;
  const currentNumber = current + 1;
  const progress = ((current + 1) / totalSteps) * 100;
  // @ts-ignore
  const { wheelSize, currentNumSize, slashSize, totalNumSize } = small
    ? {
        wheelSize: "40px",
        currentNumSize: "17px",
        slashSize: "12px",
        totalNumSize: "12px",
      }
    : {};

  return (
    <Container {...props}>
      <WheelContainer wheelSize={wheelSize}>
        <Wheel value={progress} strokeWidth={6} theme={theme}>
          <Fraction data-testid="fraction">
            <CurrentNumber theme={theme} currentNumSize={currentNumSize}>
              {currentNumber}
            </CurrentNumber>
            <Slash theme={theme} slashSize={slashSize}>
              /
            </Slash>
            <TotalSteps theme={theme} totalNumSize={totalNumSize}>
              {totalSteps}
            </TotalSteps>
          </Fraction>
        </Wheel>
      </WheelContainer>
      <div>
        <CurrentText theme={theme}>{items[current]?.label}</CurrentText>
        {showNext && next && (
          <NextText theme={theme}>Next: {next.label}</NextText>
        )}
        {showDesc && items[current]?.desc && (
          <NextText theme={theme}>{items[current]?.desc}</NextText>
        )}
      </div>
    </Container>
  );
};

StepsWheel.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node,
      link: PropTypes.string,
      desc: PropTypes.node,
    })
  ).isRequired,
  current: PropTypes.number.isRequired,
  showNext: PropTypes.bool,
  showDesc: PropTypes.bool,
  small: PropTypes.bool,
  theme: PropTypes.oneOf(Object.values(STEPS_WHEEL_THEMES)),
};

StepsWheel.defaultProps = {
  theme: STEPS_WHEEL_THEMES.default,
  small: false,
  showNext: true,
  showDesc: false,
};

export default StepsWheel;
