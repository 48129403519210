import React from "react";

import { Helmet } from "react-helmet";

import { LUMEN_SECTION_TYPE } from "manager/config/lumen.config";

import { LumenFormulaConfiguratorProps } from "./interfaces";
import LumenFormulaStepCollections from "./LumenFormulaStepCollections";
import LumenFormulaStepCreditProfile from "./LumenFormulaStepCreditProfile";
import LumenFormulaStepCriminalRecords from "./LumenFormulaStepCriminalRecords";
import LumenFormulaStepHousingRecords from "./LumenFormulaStepHousingRecords";
import LumenFormulaStepIncome from "./LumenFormulaStepIncome";
import LumenFormulaStepVerifications from "./LumenFormulaStepVerifications";
import { LumenFormulaConfiguratorContent } from "./styled";

const LumenFormulaConfigurator = ({
  formulaName,
  lumenSection,
}: LumenFormulaConfiguratorProps) => {
  const title = `Lumen formula ${formulaName || ""}`;

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <LumenFormulaConfiguratorContent>
        {LUMEN_SECTION_TYPE.CREDIT === lumenSection && (
          <LumenFormulaStepCreditProfile />
        )}

        {LUMEN_SECTION_TYPE.VERIFICATIONS === lumenSection && (
          <LumenFormulaStepVerifications />
        )}

        {LUMEN_SECTION_TYPE.INCOME === lumenSection && (
          <LumenFormulaStepIncome />
        )}

        {LUMEN_SECTION_TYPE.COLLECTIONS === lumenSection && (
          <LumenFormulaStepCollections formulaName={formulaName} />
        )}

        {LUMEN_SECTION_TYPE.CRIMINAL_RECORDS === lumenSection && (
          <LumenFormulaStepCriminalRecords />
        )}

        {LUMEN_SECTION_TYPE.HOUSING_COURT_RECORDS === lumenSection && (
          <LumenFormulaStepHousingRecords />
        )}
      </LumenFormulaConfiguratorContent>
    </>
  );
};

export default LumenFormulaConfigurator;
