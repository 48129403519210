import {
  LUMEN_FILTER_CONDITION_TYPE,
  LUMEN_FILTER_MODE,
} from "manager/config/lumen.config";
import { generateId } from "shared/utils/misc.util";

export const LUMEN_CONDITION_KEYS = Object.freeze({
  CREDITOR_NAME: "CREDITOR_NAME",
  COMMENTS: "COMMENTS",
  INDIVIDUAL: "INDIVIDUAL",
  TOTAL: "TOTAL",
  OPEN: "OPEN",
  CLOSED: "CLOSED",
  MORE_THAN: "MORE_THAN",
  LESS_THAN: "LESS_THAN",
  BETWEEN: "BETWEEN",
  DAYS: "DAYS",
  MONTHS: "MONTHS",
  YEARS: "YEARS",
});

export const LUMEN_CONDITION_LABELS = Object.freeze({
  [LUMEN_CONDITION_KEYS.CREDITOR_NAME]: "Creditor name",
  [LUMEN_CONDITION_KEYS.COMMENTS]: "Comments",
  [LUMEN_CONDITION_KEYS.INDIVIDUAL]: "Individual account",
  [LUMEN_CONDITION_KEYS.TOTAL]: "Total",
  [LUMEN_CONDITION_KEYS.OPEN]: "Open",
  [LUMEN_CONDITION_KEYS.CLOSED]: "Closed",
  [LUMEN_CONDITION_KEYS.MORE_THAN]: "More than",
  [LUMEN_CONDITION_KEYS.LESS_THAN]: "Less than",
  [LUMEN_CONDITION_KEYS.BETWEEN]: "Between",
  [LUMEN_CONDITION_KEYS.DAYS]: "Days",
  [LUMEN_CONDITION_KEYS.MONTHS]: "Months",
  [LUMEN_CONDITION_KEYS.YEARS]: "Years",
});

const KEYWORD_TYPE_OPTIONS = [
  {
    key: LUMEN_CONDITION_KEYS.CREDITOR_NAME,
    label: LUMEN_CONDITION_LABELS.CREDITOR_NAME,
    disabled: false,
  },
  {
    key: LUMEN_CONDITION_KEYS.COMMENTS,
    label: LUMEN_CONDITION_LABELS.COMMENTS,
    disabled: false,
  },
];

export const AMOUNT_TYPE_OPTIONS = [
  {
    key: LUMEN_CONDITION_KEYS.INDIVIDUAL,
    label: LUMEN_CONDITION_LABELS.INDIVIDUAL,
  },
  { key: LUMEN_CONDITION_KEYS.TOTAL, label: LUMEN_CONDITION_LABELS.TOTAL },
];

export const TIME_TYPE_OPTIONS = [
  { key: LUMEN_CONDITION_KEYS.OPEN, label: LUMEN_CONDITION_LABELS.OPEN },
  { key: LUMEN_CONDITION_KEYS.CLOSED, label: LUMEN_CONDITION_LABELS.CLOSED },
];

export const AMOUNT_OPERATOR_OPTIONS = [
  {
    key: LUMEN_CONDITION_KEYS.MORE_THAN,
    label: LUMEN_CONDITION_LABELS.MORE_THAN,
  },
  {
    key: LUMEN_CONDITION_KEYS.LESS_THAN,
    label: LUMEN_CONDITION_LABELS.LESS_THAN,
  },
];

export const TIME_OPERATOR_OPTIONS = [
  {
    key: LUMEN_CONDITION_KEYS.MORE_THAN,
    label: LUMEN_CONDITION_LABELS.MORE_THAN,
  },
  {
    key: LUMEN_CONDITION_KEYS.LESS_THAN,
    label: LUMEN_CONDITION_LABELS.LESS_THAN,
  },
  {
    key: LUMEN_CONDITION_KEYS.BETWEEN,
    label: LUMEN_CONDITION_LABELS.BETWEEN,
  },
];

export const TIME_UNIT_OPTIONS = [
  { key: LUMEN_CONDITION_KEYS.DAYS, label: LUMEN_CONDITION_LABELS.DAYS },
  { key: LUMEN_CONDITION_KEYS.MONTHS, label: LUMEN_CONDITION_LABELS.MONTHS },
  { key: LUMEN_CONDITION_KEYS.YEARS, label: LUMEN_CONDITION_LABELS.YEARS },
];

export const KEYWORDS_PLACEHOLDER = Object.freeze({
  [LUMEN_CONDITION_KEYS.CREDITOR_NAME]: 'e.g. "Medical"',
  [LUMEN_CONDITION_KEYS.COMMENTS]: 'e.g. "Paid Collection", "Disputed"',
});

export const getDefaultConditionTypeValues = (
  conditionType: string,
  mode: string,
  keywordsConditions?: { conditionSubType: string }[]
) => {
  switch (conditionType) {
    case LUMEN_FILTER_CONDITION_TYPE.TIME_PERIOD:
      return {
        conditionSubType:
          mode === LUMEN_FILTER_MODE.CRUCIAL
            ? LUMEN_CONDITION_KEYS.OPEN
            : LUMEN_CONDITION_KEYS.CLOSED,
        timeOperator: LUMEN_CONDITION_KEYS.MORE_THAN,
        timeValue: null,
        timeUnit: LUMEN_CONDITION_KEYS.YEARS,
        startDate: null,
        endDate: null,
      };
    case LUMEN_FILTER_CONDITION_TYPE.AMOUNT:
      return {
        conditionSubType: LUMEN_CONDITION_KEYS.INDIVIDUAL,
        amountOperator:
          mode === LUMEN_FILTER_MODE.CRUCIAL
            ? LUMEN_CONDITION_KEYS.MORE_THAN
            : LUMEN_CONDITION_KEYS.LESS_THAN,
        amountValue: null,
      };
    case LUMEN_FILTER_CONDITION_TYPE.KEYWORDS: {
      const conditionSubType = !keywordsConditions?.length
        ? LUMEN_CONDITION_KEYS.CREDITOR_NAME
        : keywordsConditions[0]?.conditionSubType ===
          LUMEN_CONDITION_KEYS.COMMENTS
        ? LUMEN_CONDITION_KEYS.CREDITOR_NAME
        : LUMEN_CONDITION_KEYS.COMMENTS;

      return {
        conditionSubType,
        keywords: [],
      };
    }
    default:
      return {};
  }
};

export const getInitialValues = (mode: string) => ({
  mode,
  uuid: generateId(),
  conditions: [
    {
      conditionType: LUMEN_FILTER_CONDITION_TYPE.TIME_PERIOD,
      ...getDefaultConditionTypeValues(
        LUMEN_FILTER_CONDITION_TYPE.TIME_PERIOD,
        mode
      ),
    },
  ],
});

export const getKeywordsDropdownOptions = (
  conditions: { conditionType: string; conditionSubType: string }[] = []
) => {
  const keywordsConditions = conditions.filter(
    ({ conditionType }) =>
      conditionType === LUMEN_FILTER_CONDITION_TYPE.KEYWORDS
  );

  return KEYWORD_TYPE_OPTIONS.map((option) => {
    const optionUsed = keywordsConditions.find(
      ({ conditionSubType }) => conditionSubType === option.key
    );

    return { ...option, disabled: Boolean(optionUsed) };
  });
};

export const EVICTION_CASE_TYPES = [
  "Civil Dismissal",
  "Civil Judgment",
  "Civil New Filing",
  "Forcible Entry/Detainer",
  "Small Claims Judgment",
  "Vacated/Appealed Judgment",
];
