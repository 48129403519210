
import { Col, Row, Tag } from "antd";
import { printDollarsFromCents } from "shared/utils/dollar-print";

import { incomeAssetsIncomesPropType } from "manager/utils/propTypes";
import Spacer from "shared/components/Spacer";
import Tile from "shared/components/Tile";
import { INCOME_STATUSES } from "shared/config/constants";
import { formatDate } from "shared/utils/ui";

import { IncomeStreamTileInner } from "./styled";

export const IncomeStreams = ({ incomes }) => {
  return (
    <Tile noBorder noMarginBottom>
      {incomes?.length ? (
        incomes.map((stream) => (
          <IncomeStreamTileInner borderBottom borderTop>
            <Row type="flex" justify="space-between" key={stream.id}>
              <Col xs={{ span: 12 }} xl={{ span: 8 }} className="text-ellipsis">
                <Tile.Label>Source</Tile.Label>
                <strong>{stream.name}</strong>
              </Col>

              <Col xs={{ span: 12 }} xl={{ span: 4 }}>
                <Tile.Label>Status</Tile.Label>
                {stream.status === INCOME_STATUSES.active ? (
                  <Tag color="green">Active</Tag>
                ) : (
                  <Tag>Inactive</Tag>
                )}
              </Col>

              <Col xs={{ span: 12 }} xl={{ span: 4 }}>
                <Spacer hideGteXl />
                <Tile.Label>Frequency</Tile.Label>
                {stream.frequency}
              </Col>

              <Col xs={{ span: 12 }} xl={{ span: 4 }}>
                <Spacer hideGteXl />
                <Tile.Label>Last Payment</Tile.Label>
                {formatDate(stream.lastPayment)}
              </Col>

              <Col xs={{ span: 12 }} xl={{ span: 4 }}>
                <Spacer hideGteXl />
                <Tile.Label>Average Payment</Tile.Label>
                <strong>{printDollarsFromCents(stream.meanIncome)}</strong>
              </Col>
            </Row>
          </IncomeStreamTileInner>
        ))
      ) : (
        <Tile.Inner noBorder noMarginBottom data-testid={`income-stream-${0}`}>
          No income streams available
        </Tile.Inner>
      )}
    </Tile>
  );
};

IncomeStreams.propTypes = {
  incomes: incomeAssetsIncomesPropType.isRequired,
};
