import React from "react";

import { SourcesCreateEditModalTemplate } from "manager/components/Modal/SourcesCreateEditModal";
import { useYardiResolveCreateMarketingSource } from "manager/hooks/api";
import { useGetMarketingSources } from "shared/hooks/api/sourcesQueries";
import useModal from "shared/hooks/useModal";
import { sanitizeStringSearchValue } from "shared/utils/string";

export const YardiCreateLinkMarketingSourceResolverModalContainer = () => {
  const { currentModalContext } = useModal();
  const { yardiProperty, alert, provider } = currentModalContext;
  const veroPropertyId = yardiProperty?.property?.id;
  const { marketingSources } = useGetMarketingSources(veroPropertyId);
  const { resolveAlertMarketingSource } =
    useYardiResolveCreateMarketingSource();
  const usedNames = (marketingSources || []).map((sourceIt) =>
    sanitizeStringSearchValue(sourceIt.value)
  );

  const customSubtitle = (
    <>
      <span>
        Property: <strong>{yardiProperty?.name}</strong>
      </span>
      <span>
        Marketing source: <strong>{alert?.objectId}</strong>
      </span>
    </>
  );

  const saveAction = async ({ sourceName }) => {
    const payload = {
      source: {
        value: sourceName,
        property: veroPropertyId,
      },
    };
    await resolveAlertMarketingSource({
      provider,
      alertId: alert?.id,
      body: payload,
    });
  };

  return (
    <SourcesCreateEditModalTemplate
      title="Link marketing source with VERO"
      customSubtitle={customSubtitle}
      inputDescription={<strong>Enter new VERO marketing source name</strong>}
      inputPlaceholder="Marketing source name"
      onSubmit={saveAction}
      usedNames={usedNames}
    />
  );
};

export default YardiCreateLinkMarketingSourceResolverModalContainer;
