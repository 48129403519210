import React from "react";

import { Form, Formik, Field } from "formik";

import { Input } from "shared/components/Form";
import { Modal } from "shared/components/Modal";

import { RenamePortfolioModalProps } from "./interfaces";
import { initialValues, ValidationSchema } from "./utils";

const RenamePortfolioModal = ({ submit, name }: RenamePortfolioModalProps) => (
  <Formik
    validationSchema={ValidationSchema}
    initialValues={initialValues}
    isInitialValid={ValidationSchema.isValidSync(initialValues)}
    onSubmit={submit}
  >
    {({ errors, submitForm, isSubmitting, isValid }) => (
      <Form>
        <Modal
          title="Rename Portfolio"
          subtitle={
            <span data-testid="subtitle">
              Portfolio: <b>{name}</b>
            </span>
          }
          submitButtonDisabled={!isValid}
          submit={submitForm}
          submitting={isSubmitting}
          closeOnSubmit={false}
          submitButtonLabel="Save Changes"
          // @ts-ignore
          useCancelLink
        >
          <Modal.Body>
            <Field
              name="name"
              id="name"
              label="Portfolio Name"
              as={Input}
              error={errors.name}
            />
          </Modal.Body>
        </Modal>
      </Form>
    )}
  </Formik>
);

export default RenamePortfolioModal;
