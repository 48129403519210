import React from "react";

import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import { Modal } from "shared/components/Modal";
import { SmallTitle } from "shared/components/Typography";

import { SourcesDeleteModalProps } from "./interfaces";
import { SourceDeleteConfirmationContent, WarningWell } from "./styled";

const SourcesDeleteModal = ({
  title,
  sourceType,
  sourceName,
  warningText,
  contentText,
  onSubmit,
}: SourcesDeleteModalProps) => {
  const subtitle = sourceName && (
    <FlexContainer>
      {sourceType}:&nbsp;<SmallTitle>{sourceName}</SmallTitle>
    </FlexContainer>
  );

  return (
    <Modal
      centered
      showSubmitButton
      showCancelLink
      submitButtonLabel="REMOVE"
      submit={onSubmit}
      title={title}
      subtitle={subtitle}
    >
      <Modal.Body noPaddingTop noPaddingBottom>
        <SourceDeleteConfirmationContent>
          {warningText && (
            <WarningWell>
              <Icon.OrangeExclamation className="warning-icon" />
              {warningText}
            </WarningWell>
          )}
          {contentText}
        </SourceDeleteConfirmationContent>
      </Modal.Body>
    </Modal>
  );
};

export default SourcesDeleteModal;
