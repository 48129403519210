import React from "react";

import { FormikHelpers } from "formik";
import { useHistory } from "react-router-dom";

import { EditBasicInfoModal } from "renter/components/Modal";
import {
  useActiveUser,
  useGetApplicantEmergencyContacts,
  useGetApplicantEmploymentHistory,
  useGetApplicantReferences,
  useGetApplicantResidentialHistory,
  useSetApplicantUserProfile,
} from "shared/hooks/api";
import useModal from "shared/hooks/useModal";
import { UserProfile } from "shared/interfaces/api";
import { onDialogSubmitError } from "shared/utils/api";

import PersonalInfo from "./PersonalInfo";

interface PersonalInfoContainerProps {
  name: string;
}

const PersonalInfoContainer = ({ name }: PersonalInfoContainerProps) => {
  const history = useHistory();
  const { openModalDialog, selfApi } = useModal();
  const { activeUser } = useActiveUser();
  const { emergencyContacts } = useGetApplicantEmergencyContacts();
  const { residentialHistory } = useGetApplicantResidentialHistory();
  const { employmentHistory } = useGetApplicantEmploymentHistory();
  const { references } = useGetApplicantReferences();
  const { setApplicantUserProfile } = useSetApplicantUserProfile();

  const submit = (
    values: UserProfile,
    formikApi: FormikHelpers<UserProfile>
  ) => {
    const onSuccess = selfApi.closeDialog;

    const onError = onDialogSubmitError(formikApi, history);

    setApplicantUserProfile(values, { onSuccess, onError });
  };

  const openEditBasicInfoModal = () =>
    openModalDialog(EditBasicInfoModal, { submit, profile: activeUser });

  return (
    <PersonalInfo
      name={name}
      activeUser={activeUser}
      emergencyContacts={emergencyContacts}
      residentialHistory={residentialHistory}
      employmentHistory={employmentHistory}
      references={references}
      openEditBasicInfoModal={openEditBasicInfoModal}
    />
  );
};

export default PersonalInfoContainer;
