import React, { useEffect, useMemo, useState } from "react";

import pluralize from "pluralize";

import {
  useAssignEmployeesToProperty,
  useGetEmployees,
} from "manager/hooks/api";
import { Employee } from "manager/interfaces/api/employee";
import { NOTIFICATIONS } from "shared/config/constants";
import { useDeviceType } from "shared/hooks";

import useModal from "shared/hooks/useModal";
import { openNotification } from "shared/utils/ui";

import AssignEmployeesToPropertyModal from "./AssignEmployeesToPropertyModal";

interface AssignEmployeesToPropertyModalContainerProps {
  propertyId: string | number;
  propertyName: string;
}

const AssignEmployeesToPropertyModalContainer = ({
  propertyId,
  propertyName,
}: AssignEmployeesToPropertyModalContainerProps) => {
  const { isMobile } = useDeviceType();
  const { closeActiveModalDialog } = useModal();

  const [inputValue, setInputValue] = useState<string>();
  const [checkedItems, setCheckedItems] = useState<number[]>([]);

  const {
    employees,
    isEmployeesLoading,
    refetchEmployees,
    isEmployeesFetching,
  } = useGetEmployees({
    name: inputValue,
    notAssignedToProperty: propertyId,
  });

  const employeeData = employees as Employee[];

  const { assignEmployeesToProperty, isAssignEmployeesToProperty } =
    useAssignEmployeesToProperty({ propertyId: Number(propertyId) });

  const searchAction = (value: string) => setInputValue(value);

  const checkedItemsOnChange = (item: number[]) => setCheckedItems(item);

  const selectedEmployees = useMemo(
    () =>
      employeeData?.filter(
        (employee) => checkedItems?.indexOf(employee.id) > -1
      ),
    [employees, checkedItems]
  );

  useEffect(() => {
    refetchEmployees();
  }, [inputValue]);

  const submit = () => {
    const data: { id: number; isAssigned: boolean }[] = selectedEmployees.map(
      (employee) => ({
        id: employee.id,
        isAssigned: true,
      })
    );
    assignEmployeesToProperty(data, {
      onSuccess: () => {
        closeActiveModalDialog();
        openNotification(
          `The ${pluralize("employee", employeeData?.length)} ${pluralize(
            "has",
            employeeData?.length
          )} been successfully assigned to ${propertyName} Property`,
          NOTIFICATIONS.info
        );
      },
    });
  };

  return (
    <AssignEmployeesToPropertyModal
      employees={employeeData}
      loading={isEmployeesLoading || isEmployeesFetching}
      propertyName={propertyName}
      submitting={isAssignEmployeesToProperty}
      isMobile={isMobile}
      submit={submit}
      searchAction={searchAction}
      checkedItemsOnChange={checkedItemsOnChange}
      selectedEmployees={selectedEmployees}
      hasInputValue={!!inputValue}
    />
  );
};

export default AssignEmployeesToPropertyModalContainer;
