

import { YardiUnitTypesResponse } from "manager/interfaces/api";
import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import Table from "shared/components/Table";
import Tooltip from "shared/components/Tooltip";
import { RegularText } from "shared/components/Typography";

import { printDollarsFromCents } from "shared/utils/dollar-print";
import { TableWrapper } from "./styled";

interface YardiUnitTypesProps {
  loading: boolean;
  // eslint-disable-next-line no-unused-vars
  onPageChange: (page: number) => any;
  unitTypes?: YardiUnitTypesResponse;
  page: number;
  pageSize: number;
}

const YardiUnitTypesTable = ({
  loading,
  onPageChange,
  unitTypes,
  page,
  pageSize,
}: YardiUnitTypesProps) => {
  return (
    <TableWrapper>
      <Table
        columns={[
          {
            title: (
              <FlexContainer alignItems="center">
                <RegularText strong>UNIT TYPE</RegularText>
                &nbsp;
                <Tooltip title="These fields are locked, please make edits to the deposit amount or unit type in Yardi.">
                  <Icon.InfoIcon />
                </Tooltip>
              </FlexContainer>
            ),
            dataIndex: "unitType",
            key: "unitType",
          },
          {
            title: <RegularText weak>Holding Deposit</RegularText>,
            dataIndex: "amount",
            key: "amount",
          },
        ]}
        columnBreakpoints={{
          xs: {
            column1: "unitType",
            column2: "amount",
          },
          sm: ["unitType", "amount"],
          md: ["unitType", "amount"],
          lg: ["unitType", "amount"],
          xl: ["unitType", "amount"],
        }}
        rows={(unitTypes?.results || []).map(({ unitType, amount }) => ({
          key: unitType,
          values: [
            <RegularText disabled>{unitType}</RegularText>,
            <RegularText disabled>{printDollarsFromCents(amount)}</RegularText>,
          ],
        }))}
        placeholder={{
          Icon: Icon.NoUnitsIcon,
          label: "Unit Types",
        }}
        noClickableRows
        alt
        pagination={{
          current: page,
          onChange: onPageChange,
          total: unitTypes?.count,
          defaultPageSize: pageSize,
          hideOnSinglePage: true,
        }}
        loading={loading}
      />
    </TableWrapper>
  );
};

export default YardiUnitTypesTable;
