import { BluemoonPropertiesRequest } from "manager/interfaces/api/bluemoon";
import { DEFAULT_PAGINATION } from "shared/config/constants";

export const ORDER_BY_NAMES = Object.freeze({
  property: "name",
  state: "state",
  license: "bluemoon_property__license_code__code",
  bluemoonProperty: "bluemoon_property__address_line1",
});

export const ORDER_BY = Object.freeze({
  property: {
    ascend: ORDER_BY_NAMES.property,
    descend: `-${ORDER_BY_NAMES.property}`,
  },
  state: {
    ascend: ORDER_BY_NAMES.state,
    descend: `-${ORDER_BY_NAMES.state}`,
  },
  license: {
    ascend: ORDER_BY_NAMES.license,
    descend: `-${ORDER_BY_NAMES.license}`,
  },
  bluemoonProperty: {
    ascend: ORDER_BY_NAMES.bluemoonProperty,
    descend: `-${ORDER_BY_NAMES.bluemoonProperty}`,
  },
});

export const blankFilter: BluemoonPropertiesRequest = {
  status: "bounded",
  searchValue: "",
  ordering: "",
  state: null,
  ...DEFAULT_PAGINATION,
};
