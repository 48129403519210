import styled from '@emotion/styled'
import { Col, Row, Typography } from 'antd'

import Collapse from 'shared/components/Collapse'
import CreditReportCollections from 'shared/components/CreditAndBackgroundCheck/CreditReportCollections'
import { CreditScoreSummary } from 'shared/components/CreditAndBackgroundCheck/interfaces'
import {
  CreditReportSubtitle,
  PaymentHistoryContainer,
  PaymentHistoryLegend,
} from 'shared/components/CreditAndBackgroundCheck/styled'
import FlexContainer from 'shared/components/FlexContainer'
import PaymentHistory from 'shared/components/PaymentHistory'
import Spacer from 'shared/components/Spacer'
import Tile from 'shared/components/Tile'
import { RegularText, SmallText } from 'shared/components/Typography'

import { getFullnameData } from 'shared/utils/applicant'
import { printDollarsFromCents } from 'shared/utils/dollar-print'
import {
  calcTileInnerBorderBottom,
  formatDate,
  getValueOrNA,
} from 'shared/utils/ui'

import { addressFormatter } from '../utils'

export const AddressField = styled.span`
  text-transform: capitalize;
`

const { Paragraph } = Typography

type CreditReportProps = {
  creditScoreSummary?: CreditScoreSummary
  applicantFullName: string
}

const CreditReport = ({
  creditScoreSummary,
  applicantFullName,
}: CreditReportProps) => {
  const { collections } = creditScoreSummary || {}

  return (
    <Tile
      header={{
        title: 'Credit Report',
        collapsibleOnDesktop: true,
        collapsibleOnMobile: true,
      }}
    >
      <Tile.Inner borderBottom>
        <Row type="flex">
          <Col xs={24} xl={11}>
            <Row type="flex">
              <Col xs={24} md={7} xl={24}>
                <FlexContainer
                  flexDirection="column"
                  data-testid="credit-check-name"
                >
                  <RegularText strong>{applicantFullName}</RegularText>
                  {creditScoreSummary?.akas?.length > 0 && (
                    <>
                      <Spacer size={Spacer.SIZES.xs} />
                      <SmallText weak light data-testid="credit-check-alias">
                        Also known as:
                        {creditScoreSummary.akas
                          .map((aka) => getFullnameData(aka))
                          .filter((aka) => aka.hasFullname)
                          .map((aka) => aka.fullNameOrNA)
                          .join(', ')}
                      </SmallText>
                    </>
                  )}
                </FlexContainer>
                <Tile.Divider horizontal hideLtXl />
                <Tile.Divider horizontal hideGteMd />
              </Col>
              <Col xs={0} md={1} xl={0}>
                <Tile.Divider />
              </Col>
              <Col xs={24} md={16} xl={{ span: 24, offset: 0 }}>
                {creditScoreSummary?.addresses.map((item, index) => {
                  const key = index + 1
                  return (
                    <div key={key}>
                      {index !== 0 && <Tile.Divider horizontal />}
                      <Row>
                        <Col xs={24} md={16}>
                          <Tile.Label>Address Reported</Tile.Label>
                          <AddressField
                            data-testid={`address-${index}-reported-address`}
                          >
                            {addressFormatter(item)}
                          </AddressField>
                        </Col>
                        <Col xs={24} md={8}>
                          <Spacer hideGteMd size={Spacer.SIZES.sm} />
                          <Tile.Label>Date Reported</Tile.Label>
                          <span data-testid={`address-${index}-reported-date`}>
                            {item.dateReported
                              ? formatDate(item.dateReported)
                              : 'N/A'}
                          </span>
                        </Col>
                      </Row>
                    </div>
                  )
                })}
              </Col>
            </Row>
          </Col>
          <Col xs={0} xl={1}>
            <Tile.Divider />
          </Col>
          <Col md={24} xl={0}>
            <Tile.Divider horizontal />
          </Col>
          <Col xs={24} md={0}>
            <Tile.Divider horizontal fullWidth />
          </Col>
          <Col xs={24} xl={11}>
            <h4>Score Factors</h4>
            <Paragraph>
              <ol>
                {creditScoreSummary?.scoreFactors.map((scoreFactor) => (
                  <li key={scoreFactor}>{scoreFactor}</li>
                ))}
              </ol>
            </Paragraph>
            <h4>Fraud Indicators</h4>
            <Paragraph>
              <ol>
                {creditScoreSummary?.fraudIndicators.map((fraudIndicator) => (
                  <li key={fraudIndicator}>{fraudIndicator}</li>
                ))}
              </ol>
            </Paragraph>
          </Col>
        </Row>
      </Tile.Inner>

      <CreditReportCollections collections={collections} />

      <Tile.Inner borderBottom borderTop>
        <CreditReportSubtitle>Credit Accounts</CreditReportSubtitle>
      </Tile.Inner>
      {creditScoreSummary?.accounts?.map((account, index) => (
        <Collapse
          data-testid={`account-${account.id}`}
          key={`${account.id}`}
          small
          header={({ CollapseIcon, collapsed }) => (
            <Tile.Inner
              borderBottom={
                calcTileInnerBorderBottom(creditScoreSummary.accounts, index) ||
                !collapsed
              }
            >
              <Row type="flex">
                <Col xs={24} md={11}>
                  <Row>
                    <Col xs={24} md={16} lg={10}>
                      <Tile.Label>Account Name</Tile.Label>
                      <strong>
                        {account.name}
                        <CollapseIcon />
                      </strong>
                    </Col>
                    <Col xs={12} md={8} lg={6}>
                      <Spacer hideGteMd />
                      <Tile.Label>Status</Tile.Label>
                      <strong>{account.status}</strong>
                    </Col>
                    <Col
                      xs={12}
                      md={{ span: 8, offset: 16 }}
                      lg={{ span: 8, offset: 0 }}
                    >
                      <Spacer hideGteLg />
                      <Tile.Label>Account Terms</Tile.Label>
                      {account.terms}
                    </Col>
                  </Row>
                </Col>
                <Col xs={0} md={1}>
                  <Tile.Divider bottomStick={!collapsed} />
                </Col>
                <Col xs={24} md={11}>
                  <Row>
                    <Col xs={12}>
                      <Spacer hideGteMd />
                      <Tile.Label>Current Balance</Tile.Label>
                      <span data-testid="account-current-balance">
                        {printDollarsFromCents(account.balance)}
                      </span>
                    </Col>
                    <Col xs={12}>
                      <Spacer hideGteMd />
                      <Tile.Label>Scheduled Payments</Tile.Label>
                      <span data-testid="account-scheduled-payments">
                        {printDollarsFromCents(account.scheduledPayments)}
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Tile.Inner>
          )}
        >
          <Tile.Inner
            inset
            borderBottom={calcTileInnerBorderBottom(
              creditScoreSummary?.accounts,
              index,
            )}
          >
            <Row type="flex">
              <Col xs={24} md={11}>
                <Row type="flex">
                  <Col xs={12}>
                    <Tile.Label>Current Account Rating</Tile.Label>
                    {account.rating}
                  </Col>
                  <Col xs={12}>
                    <Tile.Label>Account Type</Tile.Label>
                    {account.type}
                  </Col>
                  <Col xs={12}>
                    <Spacer />
                    <Tile.Label>Date Opened</Tile.Label>
                    {formatDate(account.dateOpened)}
                  </Col>
                  <Col xs={12}>
                    <Spacer />
                    <Tile.Label>Times 30/60/90 Days Late</Tile.Label>
                    {`${getValueOrNA(account.times30DaysLate)} / ${getValueOrNA(
                      account.times60DaysLate,
                    )} / ${getValueOrNA(account.times90DaysLate)}`}
                  </Col>
                  <Col xs={12}>
                    <Spacer />
                    <Tile.Label>Worst Payment Status</Tile.Label>
                    {getValueOrNA(account.worstPaymentStatus)}
                  </Col>
                  <Col xs={12}>
                    <Spacer />
                    <Tile.Label>Past Due Balance</Tile.Label>
                    <span data-testid="account-past-due-balance">
                      {printDollarsFromCents(account.pastDueBalance)}
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col xs={0} md={1}>
                <Tile.Divider topStick />
              </Col>
              <Col xs={24} md={11}>
                <Spacer hideGteMd />
                <Tile.Label>Payment History</Tile.Label>
                <PaymentHistoryContainer>
                  <PaymentHistory history={account.paymentPatterns} />
                  <PaymentHistoryLegend>
                    <PaymentHistory.Legend.OnTime />
                    <PaymentHistory.Legend.Late30Days />
                    <PaymentHistory.Legend.Late60Days />
                    <PaymentHistory.Legend.Late90Days />
                    <PaymentHistory.Legend.Late120Days />
                    <PaymentHistory.Legend.Unclassified />
                  </PaymentHistoryLegend>
                </PaymentHistoryContainer>
              </Col>
            </Row>
          </Tile.Inner>
        </Collapse>
      ))}
    </Tile>
  )
}

export default CreditReport
