import React, { useState, ReactNode } from "react";

import { Button } from "shared/components/Button";
import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import { UnderlineLink } from "shared/components/Links";
import Logo from "shared/components/Logo";
import Spacer from "shared/components/Spacer";
import Tile from "shared/components/Tile";

import { BasicContainer, IconContainer } from "../styled";

import { FiltersContainer } from "./styled";

export interface IMobileFiltersHeader {
  FiltersNode?: ReactNode;
  onApplyFilters?: () => void;
  onClearFilters?: () => void;
  title?: string;
}

const MobileFiltersHeader = ({
  FiltersNode = <></>,
  onApplyFilters = () => {},
  onClearFilters = () => {},
  title = "Filters",
}: IMobileFiltersHeader) => {
  const [isCollapsed, setCollapsed] = useState(true);

  const onClose = () => setCollapsed(true);

  const onApplyFiltersClick = () => {
    onApplyFilters();
    onClose();
  };

  const onClearFiltersClick = () => {
    onClearFilters();
    onClose();
  };

  return (
    <>
      <BasicContainer>
        <Logo data-testid="logo" />
        <IconContainer
          onClick={() => setCollapsed(false)}
          data-testid="mobile-filters-icon"
        >
          <Icon.FilterIcon />
        </IconContainer>
      </BasicContainer>
      {!isCollapsed && (
        <FiltersContainer>
          <Tile
            header={{
              title,
              primaryAction: (
                <Icon.CrossRemoveIcon
                  className="close-icon"
                  onClick={onClose}
                  data-testid="mobile-filters-close"
                />
              ),
              inlineOnMobile: true,
              noMobileActionStyles: true,
            }}
          >
            <Tile.Inner>
              {FiltersNode}
              <Spacer />
              <FlexContainer justifyContent="space-between" alignItems="center">
                <Button type="primary" onClick={onApplyFiltersClick}>
                  Apply Filters
                </Button>
                <div>
                  <UnderlineLink onClick={onClearFiltersClick}>
                    Clear Filters
                  </UnderlineLink>
                </div>
              </FlexContainer>
            </Tile.Inner>
          </Tile>
        </FiltersContainer>
      )}
    </>
  );
};

export default MobileFiltersHeader;
