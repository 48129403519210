import React from "react";

import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import ROUTES from "manager/config/routes";
import DealPropTypes from "manager/pages/Deals/Deals.propTypes";
import DealStatus from "manager/pages/Deals/DealsTable/DealStatus";
import ApplicantsCheckList from "shared/components/ApplicantsCheckList";
import Icon from "shared/components/Icon";
import { ActionLink } from "shared/components/Links";
import { Modal } from "shared/components/Modal";
import Table from "shared/components/Table";
import useModal from "shared/hooks/useModal";
import { transformRoute } from "shared/utils/routing";

import { Cell } from "./styled";

const YardiLinkUnitResolverModal = ({
  yardiUnitName,
  deals,
  dealsCount,
  dealStatuses,
  currentPagination: { page, pageSize },
  setCurrentPagination,
  isLoading,
}) => {
  const history = useHistory();
  const { closeActiveModalDialog } = useModal();
  const onPaginationChange = (currentPage) => {
    const newPagination = { page: currentPage, pageSize };
    setCurrentPagination(newPagination);
  };
  return (
    <Modal
      width="906px"
      title="The applications archived due to External Provider updates"
      subtitle={
        <>
          Unit: <strong>{yardiUnitName}</strong>
        </>
      }
      showSubmitButton={false}
      showCancelLink={false}
    >
      <Modal.Body>
        <Table
          alt
          withBorder
          loading={isLoading}
          columns={[
            {
              title: "Applicants",
              dataIndex: "applicants",
              key: "applicants",
            },
            {
              title: "Last application status",
              dataIndex: "dealStatus",
              key: "dealStatus",
            },
            {
              key: "x",
              title: "",
              dataIndex: "x",
            },
          ]}
          columnBreakpoints={{
            xs: {
              column1: "applicants",
              column2: "dealStatus",
            },
          }}
          placeholder={{
            Icon: Icon.NoDealsIcon,
            label: "Applications",
          }}
          rows={deals.map((deal) => {
            return {
              key: deal.id,
              values: [
                <Cell>
                  <ApplicantsCheckList inline items={deal?.applicantsSummary} />
                </Cell>,
                <Cell>
                  <DealStatus deal={deal} dealStatuses={dealStatuses} />
                </Cell>,
              ],
              primaryAction: (
                <ActionLink
                  data-testid="edit-setup-in-table"
                  onClick={() => {
                    closeActiveModalDialog();
                    history.push(transformRoute(ROUTES.deal, { id: deal.id }));
                  }}
                >
                  VIEW APPLICATION
                </ActionLink>
              ),
            };
          })}
          pagination={{
            current: page,
            onChange: onPaginationChange,
            total: dealsCount,
            defaultPageSize: pageSize,
            hideOnSinglePage: true,
          }}
          showActionColumn
        />
      </Modal.Body>
    </Modal>
  );
};

YardiLinkUnitResolverModal.propTypes = {
  yardiUnitName: PropTypes.string,
  deals: DealPropTypes.deals,
  dealsCount: PropTypes.number,
  dealStatuses: DealPropTypes.dealStatuses,
  leaseStatuses: DealPropTypes.leaseStatuses,
  currentPagination: PropTypes.shape({
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
  }).isRequired,
  setCurrentPagination: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

YardiLinkUnitResolverModal.defaultProps = {
  yardiUnitName: "",
  deals: [],
  dealsCount: 0,
  dealStatuses: [],
  leaseStatuses: [],
};

export default YardiLinkUnitResolverModal;
