import React from "react";

import FileSelect from "shared/components/FileSelect";
import { ActionLink } from "shared/components/Links";

import { Container, StyledIcon, Title } from "./styled";

const FileUpload = ({
  selectFile,
}: {
  selectFile: (initialState: File | (() => File)) => void;
}) => (
  <Container>
    <StyledIcon data-testid="documents-icon" />
    <Title>Seems like you haven’t uploaded the document yet</Title>
    <FileSelect
      Node={ActionLink}
      onFileSelect={(files) => selectFile(files[0])}
      innerProps={{ children: "Upload Document" }}
      acceptFormats="application/pdf"
    />
  </Container>
);

export default FileUpload;
