import * as Yup from "yup";

import { REGEX, VALIDATION_MESSAGES } from "shared/config/constants";
import {
  validAddress,
  validFirstName,
  validLastName,
  validPhone,
  validEmail,
} from "shared/forms/validation/fieldValidators";

const checkInfoValidationSchema = Yup.object().shape({
  firstName: validFirstName.required(VALIDATION_MESSAGES.fieldRequired),
  lastName: validLastName.required(VALIDATION_MESSAGES.fieldRequired),
  email: validEmail.required(VALIDATION_MESSAGES.fieldRequired),
  phoneNumber: validPhone.required(VALIDATION_MESSAGES.fieldRequired),
  address: validAddress.required(VALIDATION_MESSAGES.fieldRequired),
  city: Yup.string()
    .nullable()
    .required(VALIDATION_MESSAGES.fieldRequired)
    .min(3, "The City field must contain a minimum of 3 letters.")
    .max(50, "The City field must contain a maximum of 50 letters.")
    .matches(
      REGEX.lettersNumbersSpecialChars,
      "The City field can only contain letters and the allowed special characters: ['.', ' ', '-']"
    ),
  state: Yup.string().nullable().required(VALIDATION_MESSAGES.fieldRequired),
  zipcode: Yup.string()
    .nullable()
    .required(VALIDATION_MESSAGES.fieldRequired)
    .matches(REGEX.zipcode, "Invalid ZipCode."),
  termsAgreed: Yup.bool().oneOf([true], VALIDATION_MESSAGES.checkboxRequired),
  ssn: Yup.string()
    .nullable()
    .min(9, "Must be exactly 9 digits")
    .max(11, "Must be exactly 9 digits")
    .matches(REGEX.ssnChar, "Must only contain digit, hyphen or space")
    .matches(REGEX.ssn, "Invalid format"),
});

export default checkInfoValidationSchema;
