import React from "react";

import { Modal } from "shared/components/Modal";
import Spacer from "shared/components/Spacer";
import { RegularText, Title3 } from "shared/components/Typography";

import { StyledWell } from "./styled";

const HoldingDepositPaymentPointInfoModal = () => {
  return (
    // @ts-ignore
    <Modal
      title="Requesting holding deposits"
      subtitle="Select when your applicant will be requested to pay the holding deposit to secure their unit"
      showSubmitButton={false}
      showCancelLink={false}
      width="730px"
    >
      <Modal.Body noPaddingTop noPaddingBottom>
        <Spacer size={Spacer.SIZES.md} />
        <StyledWell noBorder>
          <div>
            <Title3 strong>BEFORE APPLICATION SUBMISSION</Title3>
            <RegularText weak>
              If enabled, VERO will automatically request a holding deposit to
              be paid by the primary applicant before they submit the
              application.
            </RegularText>
            <Spacer />
          </div>
          <div>
            <Title3 strong>AFTER APPLICATION APPROVAL</Title3>
            <RegularText weak>
              If enabled, VERO will automatically request a holding deposit to
              the primary applicant once the application is approved.
            </RegularText>
          </div>
        </StyledWell>
      </Modal.Body>
    </Modal>
  );
};

export default HoldingDepositPaymentPointInfoModal;
