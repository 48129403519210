import React from "react";

import { Form, Formik, FieldArray } from "formik";
import isEmpty from "lodash/isEmpty";
import * as Yup from "yup";

import ErrorList from "shared/components/ErrorList";
import { Modal } from "shared/components/Modal";
import Spacer from "shared/components/Spacer";
import {
  InviteApplicantsForm,
  InviteApplicantsLink,
  ValidationSchema as ApplicantsValidationSchema,
  initialValues,
} from "shared/forms/InviteApplicantsForm";

import { useGetApplications } from "../../../../hooks/api";
import ApplicationGroup from "../ApplicationGroup/ApplicationGroup";

import {
  InviteApplicantsModalDataFormikProps,
  InviteApplicantsModalProps,
} from "./interfaces";

const ValidationSchema = Yup.object().shape({
  ...ApplicantsValidationSchema,
});

const InviteApplicantsModal = ({
  title,
  id,
  submit,
  context,
  label,
  closeActiveModalDialog,
}: InviteApplicantsModalProps) => {
  const { refetchApplications } = useGetApplications();

  return (
    <Formik
      validationSchema={ValidationSchema}
      initialValues={initialValues}
      isInitialValid={ValidationSchema.isValidSync(initialValues)}
      onSubmit={(values, { resetForm, setSubmitting, setErrors }) => {
        const onSuccess = async () => {
          await refetchApplications?.();
          closeActiveModalDialog();
          setSubmitting(false);
          resetForm();
          setErrors({});
        };

        const onFail = (errors) => {
          setErrors(errors);
          setSubmitting(false);
        };

        submit({ ...values, onSuccess, onFail });
      }}
    >
      {({
        values,
        errors,
        submitForm,
        isSubmitting,
        dirty,
        validateForm,
      }: InviteApplicantsModalDataFormikProps) => {
        const nonFieldErrors = errors?.nonFieldErrors || [];

        return (
          <Form>
            <Modal
              title={title}
              subtitle={
                <>
                  <div data-testid="subtitle">
                    {context.property && context.unit ? (
                      <>
                        Home:&nbsp;
                        <strong>
                          {context.property}, {context.unit}
                        </strong>
                      </>
                    ) : (
                      <>
                        Company Name:&nbsp;
                        <strong>{context.companyName}</strong>
                      </>
                    )}
                  </div>
                  {!!context.applicationGroup?.length && (
                    <ApplicationGroup
                      applicationGroup={context.applicationGroup}
                    />
                  )}
                </>
              }
              submitButtonLabel={title}
              submitButtonDisabled={!dirty}
              submit={submitForm}
              submitting={isSubmitting}
              closeOnSubmit={false}
              id={id}
            >
              {!isEmpty(nonFieldErrors) && (
                <Modal.Body noPaddingBottom>
                  <ErrorList errors={nonFieldErrors} />
                </Modal.Body>
              )}
              <Modal.Body noPaddingTop={!isEmpty(nonFieldErrors)}>
                <FieldArray
                  name="applicants"
                  render={(arrayHelpers) => (
                    <>
                      <InviteApplicantsForm
                        applicants={values.applicants}
                        deleteApplicant={arrayHelpers.remove}
                        size={InviteApplicantsForm.SIZES.small}
                        label={label}
                      />
                      <Spacer size={Spacer.SIZES.sm} />
                      <InviteApplicantsLink
                        applicants={values.applicants}
                        checkValidation={validateForm}
                        addApplicant={arrayHelpers.push}
                      />
                    </>
                  )}
                />
              </Modal.Body>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};

export default InviteApplicantsModal;
