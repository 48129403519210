import React from "react";

import { useFormikContext } from "formik";

import { ConfigurationPanel, LumenFilters } from "manager/components/Lumen";
import {
  LUMEN_SECTION_TYPE,
  LUMEN_SECTION_TYPE_LABELS,
} from "manager/config/lumen.config";
import { SwitchTitle } from "shared/components/Form/SwitchTitle";

interface LumenFormulaStepCollectionsProps {
  formulaName: string;
}

const LumenFormulaStepCollections = ({
  formulaName,
}: LumenFormulaStepCollectionsProps) => {
  const { values, setFieldValue } = useFormikContext();

  return (
    <>
      <strong>
        <SwitchTitle
          id="useCollections"
          title={LUMEN_SECTION_TYPE_LABELS[LUMEN_SECTION_TYPE.COLLECTIONS]}
          // @ts-ignore
          value={values.useCollections}
          onChange={(value) => setFieldValue("useCollections", value)}
          noPadding
        />
      </strong>
      {/* @ts-ignore */}
      <ConfigurationPanel isEnabled={!!values.useCollections}>
        <LumenFilters
          formulaName={formulaName}
          lumenSection={LUMEN_SECTION_TYPE.COLLECTIONS}
          filtersProp="filterCollectionRecords"
          crucialFiltersText='Any records that are considered "Crucial" will cause the applicants and group to be flagged as "Negative" and the suggested action will be "Decline"'
          ignoredFiltersText='Any records that are considered "Ignored" will not have any impact on the group or individual. The Lumen status and suggested action will not be impacted.'
          safetyNetText='Any collection accounts that do not meet the criteria of the above categories will be considered "Safety net". This will cause the applicant and group to be flagged as "Neutral" and the suggested action will be "Review Applicant Credit Reports".'
          safetyNetProp="useCollectionRecordSafetyNet"
        />
      </ConfigurationPanel>
    </>
  );
};

export default LumenFormulaStepCollections;
