import styled from "@emotion/styled";

import { Divider } from "antd";

import Icon from "shared/components/Icon";
import { UnderlineLink } from "shared/components/Links";

import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

const DetailsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  ${MEDIA_QUERIES.w.ltMd}: {
    flex-wrap: wrap;
    margin-top: 10px;
  } ;
`;

const PropertyDescription = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  ${MEDIA_QUERIES.w.ltLg}: {
    margin-top: 15px;
  }
  ${MEDIA_QUERIES.w.ltMd}: {
    align-items: flex-start;
    flex-direction: column;
  } ;
`;

const BlackText = styled.span`
  color: ${COLORS.textColorPrimary};
  white-space: nowrap;
  margin-left: ${(props) => props.marginLeft && "7px"};
`;

const GreyText = styled.span`
  color: ${COLORS.textColorSecondary};
  margin-left: 10px;
`;

const GreyUnderlineLink = styled(UnderlineLink)`
  color: ${COLORS.textColorSecondary};
  margin: 0 0 0 10px;
`;

const EditGreenIcon = styled(Icon.EditGreenIcon)`
  margin-left: 10px;
  padding-bottom: 2px;
  cursor: pointer;
`;

const PropertyDivider = styled(Divider)`
  ${MEDIA_QUERIES.w.ltMd}: {
    display: none;
  } ;
`;

export {
  DetailsContainer,
  PropertyDescription,
  BlackText,
  GreyText,
  GreyUnderlineLink,
  EditGreenIcon,
  PropertyDivider,
};
