import React from "react";

import FlexContainer from "shared/components/FlexContainer";
import Spacer from "shared/components/Spacer";
import { SmallTitle, RegularText } from "shared/components/Typography";

import { SelectPreferrenceProps, LEADS_OPTIONS } from "./interfaces";
import { Wrapper, Option } from "./styled";

const SelectPreferrence = ({ selected, onClick }: SelectPreferrenceProps) => (
  <Wrapper>
    <FlexContainer flexDirection="column">
      <Spacer />
      <Option
        role="button"
        tabIndex={0}
        id={LEADS_OPTIONS.PROSPECT}
        onClick={onClick}
        selected={selected === LEADS_OPTIONS.PROSPECT}
      >
        <div className="radio-circle">
          <div className="inner-circle" />
        </div>
        <div>
          <SmallTitle className="select-title">
            I&apos;m a Potential Applicant
          </SmallTitle>
          <RegularText className="text">
            I&apos;m here because I’m looking for a new home. Use this option if
            unsure.
          </RegularText>
        </div>
      </Option>
      <Spacer />
      <Option
        role="button"
        id={LEADS_OPTIONS.GUEST}
        tabIndex={0}
        onClick={onClick}
        selected={selected === LEADS_OPTIONS.GUEST}
      >
        <div className="radio-circle">
          <div className="inner-circle" />
        </div>
        <div>
          <SmallTitle className="select-title">
            I&apos;m Just Touring Today
          </SmallTitle>
          <RegularText className="text">
            I&apos;m only here with someone else; I don’t need a new home.
          </RegularText>
        </div>
      </Option>
      <Spacer />
    </FlexContainer>
  </Wrapper>
);

export default SelectPreferrence;
