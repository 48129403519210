import * as Yup from "yup";

export const ValidationSchema = Yup.object().shape({
  sample: Yup.string().nullable(),
  description: Yup.string().nullable().required("This field is required"),
});

const choiceValidation = Yup.object().shape({
  nickname: Yup.string().nullable(),
});

export const EnumValidationSchema = Yup.object().shape({
  sample: Yup.string().nullable(),
  description: Yup.string().nullable(),
  choices: Yup.array().of(choiceValidation),
});
