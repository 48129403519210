import styled from "@emotion/styled";

// import { COLORS, MEDIA_QUERIES } from "shared/config/constants";
import PageTitle from "shared/components/PageTitle";

const BluemoonTitleContainer = styled(PageTitle)`
  display: flex;
  align-items: center;
`;

const BluemoonImageContainer = styled(PageTitle.Icon)`
  height: 40px;
`;

const BluemoonImg = styled.img`
  height: 35px;
`;

export { BluemoonTitleContainer, BluemoonImageContainer, BluemoonImg };
