import _ from 'lodash'
import { DEFAULT_DATES, parseQuery } from 'shared/components/vega/SearchFilter'
import { Timestamp } from 'shared/components/vega/Timestamp'
import { Payments } from 'shared/components/vega/Payments'
import { ApplicantWorkflow } from 'shared/components/vega/ApplicantWorkflow'
import ApplicantHeader from 'shared/components/vega/ApplicantSummaryV2/ApplicantHeader'
import { onRowClick, Location } from './utils'
import SearchList from './SearchList'

const Application = ({ application, statusToCategoryLabel }) => {
  return (
    <>
      <a href={`/deals/${application?.identifier}`} className='vega title small link block'>{statusToCategoryLabel(application?.status)}</a>
      <Location application={application} />
      <Timestamp timestamp={application?.updatedAt} small />
    </>
  )
}

export const ApplicantList = ({ statusToCategoryLabel }) => {
  return (
    <SearchList
      indexes={[
        'applicant',
        'application.application',
        'payment.applicant',
        'applicant-workflow-run.applicant'
      ]}
      defaultSearchIndex='applicant'
      aggs={[]}
      initialSort='-applicant.createdAt'
      maxHeight='calc(100vh - 30rem)'
      resolveFilter={filter => _.omitBy({
        'application.type': 'SCREENING',
        'applicant.createdAt.gte': (_.first(filter?.dates || DEFAULT_DATES) as any)?.toISOString(),
        'applicant.createdAt.lte': (_.last(filter?.dates || DEFAULT_DATES) as any)?.toISOString(),
        ...parseQuery(filter?.query, 'applicant')
      }, v => _.isNil(v) || (_.isString(v) && _.isEmpty(v)))}
      columns={[
        {
          title: 'applicant',
          size: 'max-content',
          render: applicant => <ApplicantHeader applicant={applicant} minimal />
        },
        {
          title: 'application',
          size: '2fr',
          render: applicant => <Application application={_.first(applicant.application)} statusToCategoryLabel={statusToCategoryLabel} />
        },
        {
          title: 'date created',
          sort: 'applicant.createdAt',
          size: '1fr',
          render: applicant => <Timestamp timestamp={applicant.createdAt} />
        },
        {
          title: 'payments',
          size: '1fr',
          render: applicant => <Payments payments={applicant?.payment} minimal />
        },
        {
          title: 'workflow',
          size: '1fr',
          render: applicant => <ApplicantWorkflow applicantWorkflow={_.chain(applicant).get('applicant-workflow-run').castArray().first().value()} minimal />
        }
      ]}
      onRowClick={applicant => onRowClick(`/applications/${applicant?.legacyId}`)}
      quicklinks={{
        submitted: 'applicant.status:3',
        paid: 'payment.status:paid'
      }}
    />
  )
}
