import styled from "@emotion/styled";
import { Menu as BaseMenu } from "antd";

import { Button } from "shared/components/Button";
import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import { UnderlineLink } from "shared/components/Links";
import Tag from "shared/components/Tag";
import { SmallText } from "shared/components/Typography";
import { COLORS } from "shared/config/constants";

const PopoverContainer = styled(FlexContainer)`
  &.title-container {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  &.show-top-border {
    border-top: 1px solid ${COLORS.borders};
    padding-top: 12px;
    padding-bottom: 12px;
  }
  padding-left: 16px;
  padding-right: 16px;
`;

const IntegrationStatusTagContainer = styled(Tag)`
  margin-right: 0;
`;

interface IIntegrationStatusDropdownContainer {
  transparentBackground?: boolean;
}

const IntegrationStatusDropdownContainer = styled(
  Tag
)<IIntegrationStatusDropdownContainer>`
  margin-right: 0px;
  height: 36px;
  padding: 0 8px !important;
  cursor: pointer;
  border-radius: 24px;
  display: flex;
  background-color: ${({ transparentBackground }) =>
    transparentBackground ? "transparent !important" : COLORS.borders};
`;

interface IStatusCircle {
  backgroundColor?: string;
}

const StatusCircle = styled.div<IStatusCircle>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  height: 12px;
  width: 12px;
  border-radius: 6px;
  margin-right: 8px;
  margin-left: 6px;
`;

const ChevronDownIcon = styled(Icon.ChevronDownIcon)`
  margin-left: 6px;
`;

const StatusContainer = styled(FlexContainer)`
  width: 300px;

  .last-sync {
    margin: 5px 16px;
  }
`;

const LogoSmall = styled.img`
  height: 24px;
  width: 78px;
  margin: 2px;
  margin-right: 7px;
`;

interface IPrimaryActionButton {
  disabled?: boolean;
  refreshing?: boolean;
}

const PrimaryActionButton = styled(Button)<IPrimaryActionButton>`
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  .button-inner {
    padding: 0;
    margin: 0;
  }

  color: ${COLORS.backLink.default};
  font-weight: bold;
  text-transform: uppercase;
  box-shadow: none;
  ${(props) => props.disabled && `background: none;`}

  svg {
    width: 13px;
    fill: ${COLORS.backLink.default};
    ${(props) => props.refreshing && `animation: rotation 2s infinite linear;`}
  }
`;

const Container = styled(FlexContainer)`
  margin-left: auto;
  padding: 5px 8px;
  height: 32px;
`;

const Menu = styled(BaseMenu)`
  border: none;
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
  height: 49px;

  .ant-menu-submenu-title {
    display: flex;
    align-items: center;
    height: 100%;
  }
`;

const LinkAction = styled(UnderlineLink)``;

const SyncTitle = styled(SmallText)`
  margin-left: 8.33px;
`;

export {
  IntegrationStatusTagContainer,
  IntegrationStatusDropdownContainer,
  StatusCircle,
  ChevronDownIcon,
  Container,
  Menu,
  StatusContainer,
  PrimaryActionButton,
  LinkAction,
  LogoSmall,
  PopoverContainer,
  SyncTitle,
};
