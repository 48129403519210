import styled from "@emotion/styled";

import FlexContainer from "shared/components/FlexContainer";
import { SmallText } from "shared/components/Typography";
import { colors, MEDIA_QUERIES } from "shared/config/constants";

export const InfoContainer = styled(FlexContainer)`
  svg {
    min-width: 24px;
    margin-right: 5px;
  }
  span {
    line-height: 18px;
    font-weight: 600;
    color: ${colors.accent[400]} !important;
    text-transform: uppercase;
  }
`;

export const SpacerTop = styled.div<{ big?: boolean }>`
  margin-top: ${({ big }) => (big ? "210px" : "120px")};
`;

export const ExpirationText = styled(SmallText)`
  ${MEDIA_QUERIES.w.ltSm} {
    width: 70%;
  }
`;
