import React from "react";

import { useHistory } from "react-router-dom";

import { useEditUnit } from "manager/hooks/api";
import useModal from "shared/hooks/useModal";
import { onDialogSubmitError } from "shared/utils/api";

import EditUnitModal from "./EditUnitModal";

const EditUnitModalContainer = () => {
  const history = useHistory();
  const { editUnit } = useEditUnit();
  const { currentModalContext, selfApi } = useModal();

  const submit = (values, formikApi) =>
    editUnit(values, {
      onSuccess: selfApi.closeDialog,
      onError: onDialogSubmitError(formikApi, history),
    });

  return <EditUnitModal context={currentModalContext} submit={submit} />;
};

export default EditUnitModalContainer;
