import React from "react";

import Container from "shared/components/Container";
import FlexContainer from "shared/components/FlexContainer";
import { ActionLink } from "shared/components/Links";
import Tile from "shared/components/Tile";
import { LumenImages } from "shared/images";

import { LumenNotAvailableContent } from "./styled";

const LumenNotAvailable = () => (
  <LumenNotAvailableContent>
    <Container expand noPaddingTop noMobilePaddingX>
      <Tile>
        <Tile.Inner size="lg">
          <FlexContainer
            fullHeight
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <LumenImages.LumenTurnedOff className="not-available-image" />
            <p className="not-available-text">
              Looks like Lumen isn&apos;t active for your company. Please reach
              out to your account manager to turn it on.
            </p>
            <ActionLink
              href="mailto:hello@sayvero.com"
              className="not-available-link"
            >
              CONTACT MANAGER
            </ActionLink>
          </FlexContainer>
        </Tile.Inner>
      </Tile>
    </Container>
  </LumenNotAvailableContent>
);

export default LumenNotAvailable;
