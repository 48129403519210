import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { Upload as BaseUpload } from "antd-latest";

import { ActionLink } from "shared/components/Links";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";
import "antd-latest/lib/upload/style";

const Upload = styled(BaseUpload)`
  display: flex;

  ${MEDIA_QUERIES.w.ltSm} {
    flex-wrap: wrap;
  }

  .ant-latest-upload-select {
    background-color: ${COLORS.uploadWall.upload.background};
  }

  .ant-latest-upload-select,
  .ant-latest-upload-list-item {
    border: 1px solid ${COLORS.borders};
  }

  .ant-latest-upload-list-picture-card-container {
    display: flex;
    flex-direction: column;
    height: auto;

    .internal-only-switch {
      padding-bottom: 0;
    }
  }

  .ant-latest-upload-list-picture-card {
    display: flex;
    flex-wrap: wrap;
  }

  .ant-latest-upload,
  .ant-latest-upload-list-item,
  .ant-latest-upload-list-picture-card-container,
  .ant-latest-upload-list-item-thumbnail {
    min-width: 200px;
    min-height: 200px;
  }

  .ant-latest-upload-list-item {
    height: 200px;
  }

  .ant-latest-upload-list-picture-card-container,
  .ant-latest-upload-select {
    margin: 0 24px 50px 0;
  }

  .ant-latest-upload.ant-latest-upload-select-picture-card {
    display: flex;

    .ant-latest-upload {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .ant-latest-upload-list-item {
    padding: 0;
  }

  .ant-latest-upload-list-item-image {
    border-radius: 2px;
  }

  .ant-latest-upload-list-item-uploading-text {
    text-align: center;
  }

  .ant-latest-upload-list-item-progress {
    width: calc(100% - 48px);
    left: 24px;
  }

  .ant-latest-upload-list-item-name {
    display: block;
    font-weight: 300;
    color: ${COLORS.secondary};

    ${MEDIA_QUERIES.w.ltMd} {
      text-align: left;
    }
  }

  .ant-latest-upload-list-item-info {
    overflow: visible;
  }

  .ant-latest-upload-list-item-actions {
    display: flex;
    align-items: center;
    gap: 30px;

    a,
    button {
      margin: 0 !important;
      padding: 0 !important;
      width: 20px;
      height: 20px;

      span {
        margin: 0 !important;
        padding: 0 !important;
        width: inherit !important;
        height: inherit !important;

        svg {
          width: inherit !important;
          height: inherit !important;
        }
      }
    }
  }
`;

interface UploadLinkProps {
  disabled?: boolean;
}

const UploadLink = styled(ActionLink)<UploadLinkProps>`
  justify-content: center;

  ${(props) =>
    !!props.disabled &&
    css`
      color: ${COLORS.disabledText};
    `}
`;

export { Upload, UploadLink };
