import { Pagination } from "shared/interfaces/pagination";

export enum OrderDirection {
  ascend = "ascend",
  descend = "descend",
}

export interface TablePagination {
  current: number;
  pageSize: number;
}

export interface TableSorting {
  order: OrderDirection;
  columnKey: string;
}

export type DataTableOrderByMapItem = {
  ascend: string;
  descend: string;
};

export type DataTableOrderByMap<OrderMapType> = {
  [key in keyof OrderMapType]: DataTableOrderByMapItem;
};

export interface DataTableHookProps<FiltersType, OrderMapType> {
  storageKey: string;
  orderByMap: DataTableOrderByMap<OrderMapType>;
  blankFilters: FiltersType;
  defaultPagination?: Pagination;
  defaultOrdering: string;
}

export interface TableState<FiltersType> {
  filters: FiltersType;
  pagination: Pagination;
  ordering: string;
}

export type UpdateTableStateFn<FiltersType> = (
  values: Partial<TableState<FiltersType>>,
  resetPagination?
) => void;

export type UpdateFiltersFn<FiltersType> = (
  values: Partial<FiltersType>
) => void;

export type OnTableChangeFn = (
  tablePagination: TablePagination,
  tableFilters,
  sorter: TableSorting
) => void;

export type InitialOrderColumn<OrderMapType> = Partial<{
  [key in keyof OrderMapType]: OrderDirection;
}>;
