import React from "react";

import { Row, Col } from "antd";

import { Input, VeroFormField } from "shared/components/Form";

const CheckboxSignatureData = () => (
  <div data-testid="checkbox-signature">
    <Row gutter={[0, 24]}>
      <Col>
        <VeroFormField
          as={Input}
          id="checkboxSignature.min"
          name="checkboxSignature.min"
          label="Required Selections"
          description="Required number of selected checkboxes"
        />
      </Col>
    </Row>
    <Row gutter={[0, 24]}>
      <Col>
        <VeroFormField
          as={Input}
          id="checkboxSignature.allowed"
          name="checkboxSignature.allowed"
          label="Allowed Selections"
          description="Allowed number of selected checkboxes"
        />
      </Col>
    </Row>
  </div>
);

export default CheckboxSignatureData;
