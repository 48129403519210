import React, { useState } from "react";

import ConfirmLeaveDocument from "./ConfirmLeaveDocument";

export interface ConfirmLeaveDocumentModalContainerProps {
  saveChanges: () => void;
}

const ConfirmLeaveDocumentModalContainer = ({
  saveChanges,
}: ConfirmLeaveDocumentModalContainerProps) => {
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = async () => {
    try {
      setSubmitting(true);
      await saveChanges();
    } finally {
      setSubmitting(false);
    }
  };

  return <ConfirmLeaveDocument onSubmit={onSubmit} submitting={submitting} />;
};

export default ConfirmLeaveDocumentModalContainer;
