import React from 'react'

import type { Income } from 'renter/interfaces/api/income'
import { mapIncomeStreamVerificationMethodToType } from 'shared/utils/employmentAndIncome'

import EditPaystubModal from './EditStreamModals/EditPaystubModal'
import EditW2or1099Modal from './EditStreamModals/EditW2or1099Modal'

interface EditStreamDetailModalProps {
  stream: Income
  submit: (values) => void
}

const EditStreamDetailModal = ({
  stream,
  submit,
}: EditStreamDetailModalProps) => {
  const formType = mapIncomeStreamVerificationMethodToType(
    stream.incomeVerificationMethod,
  )

  return (
    <>
      {formType == 'Paystub' && (
        <EditPaystubModal stream={stream} submit={submit} />
      )}
      {['1099', 'W2'].includes(formType) && (
        <EditW2or1099Modal stream={stream} submit={submit} />
      )}
    </>
  )
}

export default EditStreamDetailModal
