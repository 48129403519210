import React, { useRef } from "react";

import { FormikProps } from "formik";

import { NOTIFICATIONS } from "shared/config/constants";
import { useLogin } from "shared/hooks";
import {
  useGetValidateResetPasswordToken,
  useLoginAuth,
  useResetPassword,
} from "shared/hooks/api";
import { openNotification } from "shared/utils/ui";

import { FormikPasswordRecovery, ValidationTokenError } from "./interfaces";
import PasswordRecoverySetPasswordPage from "./PasswordRecoverySetPasswordPage";

const PasswordRecoverySetPasswordPageContainer = ({
  token,
  onSuccessLogin,
}) => {
  const formikRef = useRef<FormikProps<FormikPasswordRecovery>>();

  const { resetPassword } = useResetPassword();
  const { loginAuth } = useLoginAuth();

  const onErrorLogin = (error) => {
    openNotification(
      !!error ? error?.toString() : "Failed to login automatically.",
      NOTIFICATIONS.error
    );
  };

  const loginCall = async () => {
    const { email, password } = formikRef?.current?.values || {};

    return loginAuth({ email, password });
  };

  const { login } = useLogin({
    loginCall,
    onSuccess: () => onSuccessLogin(),
    onError: (e) => onErrorLogin(e),
  });

  const submit = ({ password }: FormikPasswordRecovery) =>
    resetPassword(
      {
        token,
        password,
      },
      { onSuccess: login }
    );

  const { passwordToken, isPasswordTokenLoading, passwordTokenError } =
    useGetValidateResetPasswordToken(token);

  const { errors }: { errors?: ValidationTokenError } =
    passwordTokenError ?? {};

  return (
    <PasswordRecoverySetPasswordPage
      token={token}
      submit={submit}
      email={passwordToken?.email}
      loading={isPasswordTokenLoading}
      error={errors?.nonFieldErrors}
      formikRef={formikRef}
    />
  );
};

export default PasswordRecoverySetPasswordPageContainer;
