import styled from "@emotion/styled";

import { Modal } from "shared/components/Modal";

const ModalBody = styled(Modal.Body)`
  padding-bottom: 0;
`;

const Note = styled.p`
  font-size: 15px;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 25px;
`;

const FormContainer = styled.div`
  margin-bottom: 32px;
`;

const SelectionTitle = styled.p`
  margin-bottom: 6px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  color: #b2afc8;
`;

const Selection = styled.pre`
  font-size: 12px;
  font-weight: normal;
  line-height: 1.7;
  margin-bottom: 0;
`;

export { Note, ModalBody, FormContainer, SelectionTitle, Selection };
