import styled from "@emotion/styled";

import { MEDIA_QUERIES } from "shared/config/constants";

const DateWrapper = styled.div`
  min-width: 110px;
`;

const CreatedByWrapper = styled.div`
  min-width: 140px;

  ${MEDIA_QUERIES.w.ltSm} {
    width: calc(100vw - 48px);
  }
`;

export { DateWrapper, CreatedByWrapper };
