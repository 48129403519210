import React, {
  useState,
  useEffect,
  ReactNode,
  ChangeEventHandler,
} from "react";

import { TextAreaProps as BaseTextAreaProps } from "antd/lib/input";

import RequiredIndicator from "shared/components/RequiredIndicator";

import { Container, Error } from "../styled";

import { TextArea as StyledTextArea, Label, CharactersCount } from "./styled";

export type TextAreaProps = BaseTextAreaProps & {
  id: string;
  name: string;
  label?: string;
  append?: ReactNode;
  value?: string;
  disabled?: boolean;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  onBlur?: () => void;
  error?: string;
  required?: boolean;
  formikContext?: {
    trimOnBlur?: boolean;
    setFieldValue: (field: string, value: string) => void;
  };
  maxLength?: number;
  showCount?: boolean;
};

export const TextArea = ({
  label,
  id,
  name,
  value: baseValue,
  disabled,
  onChange,
  onBlur,
  error,
  required,
  append,
  formikContext,
  maxLength,
  showCount,
  ...props
}: TextAreaProps) => {
  const [focused, setFocused] = useState(false);
  const [value, setValue] = useState<string>();
  const { trimOnBlur, setFieldValue } = formikContext ?? {};
  useEffect(() => setValue(baseValue), [baseValue]);

  return (
    <>
      <Container>
        {label && (
          <Label
            htmlFor={id}
            focused={focused}
            value={Boolean(value)}
            disabled={disabled}
            valid={!error}
          >
            {label}
            <RequiredIndicator required={required} />
          </Label>
        )}
        <StyledTextArea
          data-testid="textarea"
          {...props}
          // @ts-ignore
          id={id}
          onFocus={() => setFocused(true)}
          onBlur={(event) => {
            if (trimOnBlur && setFieldValue && typeof baseValue === "string") {
              setFieldValue(name, baseValue.trim());
            }

            if (onBlur) {
              onBlur(event);
            }

            setFocused(false);
          }}
          value={value}
          onChange={(event) => {
            if (onChange) {
              onChange(event);
            }
            setValue(event.target.value);
          }}
          disabled={disabled}
          valid={!error}
          maxLength={maxLength}
        />
        {showCount && !!maxLength && (
          <CharactersCount>
            {value?.length}/{maxLength}
          </CharactersCount>
        )}
        {append && append}
      </Container>
      {error && <Error>{error}</Error>}
    </>
  );
};
