import React, { useContext } from 'react'

import _ from 'lodash'

import { useGetAssets } from 'renter/hooks/api/assetQueries'
import FlexContainer from 'shared/components/FlexContainer'
import Icon from 'shared/components/Icon'
import { StyledTable } from 'shared/components/Table/Table/styled'
import Tile from 'shared/components/Tile'
import {
  SmallText,
  RegularText,
  MediumTitle,
} from 'shared/components/Typography'
import { COLORS } from 'shared/config/constants'
import ApplicationContext from 'shared/pages/ApplicationSummary/applicationContext'
import { printDollarsFromCents } from 'shared/utils/dollar-print'

import { IAndBSkeleton } from '../Skeleton/Skeleton'
import { IncomeStreamHeader } from "../IncomeStreamsSection/styled";

export const CashAvailable = () => {
  const { application } = useContext(ApplicationContext)
  const { dealDecisionIsMade, personVrn, snapshot } = application
  const { assetData, isAssetsDataLoading } = useGetAssets(
    {
      person: personVrn as string,
      types: ['depository', 'investment', 'loan'],
    },
    !_.isEmpty(personVrn) && !dealDecisionIsMade,
  )
  const snapshotCashAssets = _.chain(snapshot?.assets)
    .defaultTo([])
    .filter((asset) =>
      _.includes(['depository', 'investment', 'loan'], asset?.type),
    )
    .value()
  const dataSource = (dealDecisionIsMade ? snapshotCashAssets : assetData) ?? []
  const emptyData = _.isEmpty(dataSource)

  return (
    <>
      {isAssetsDataLoading ? (
        <IAndBSkeleton title={{ rows: 2, width: [141, 181] }} />
      ) : (
        <Tile
          id="verified-balances"
          header={{
            subtitle: !emptyData && (
              <FlexContainer flexDirection="column">
                <SmallText extraStrong>Total Balances</SmallText>
                <MediumTitle style={{ color: COLORS.violetTheme.lightViolet2 }}>
                  {printDollarsFromCents(
                    _.sumBy(
                      dataSource,
                      ({ availableBalance }) => availableBalance ?? 0,
                    ),
                  )}
                </MediumTitle>
              </FlexContainer>
            ),
            title: <MediumTitle>Verified Balances</MediumTitle>,
            withoutBorderBottom: true,
          }}
          rounded
        >
          {!emptyData && (
            <Tile.Inner noPaddingTop>
              <StyledTable
                columns={[
                  {
                    title: <IncomeStreamHeader>Account Name</IncomeStreamHeader>,
                    dataIndex: 'name',
                    key: 'name',
                    render: (_, { mask, name }) => (
                      <RegularText>
                        {name}
                        {mask ? ` (*${mask})` : ''}
                      </RegularText>
                    ),
                  },
                  {
                    title: <IncomeStreamHeader>Account Owner</IncomeStreamHeader>,
                    dataIndex: 'owners',
                    key: 'owners',
                    render: (_text, { owners }) => (
                      <RegularText>
                        {_.map(_.flatten(_.map(owners, 'names')), (name) => <div>{name}</div>)}
                      </RegularText>
                    ),
                  },
                  {
                    title: <IncomeStreamHeader>Account Type</IncomeStreamHeader>,
                    dataIndex: 'type',
                    key: 'type',
                    render: (_text, { type, subtype }) => (
                      <RegularText>
                        {_.capitalize(type)}
                        {subtype ? ` / ${_.capitalize(subtype)}` : ''}
                      </RegularText>
                    ),
                  },
                  {
                    title: <IncomeStreamHeader>Bank</IncomeStreamHeader>,
                    dataIndex: 'bank',
                    key: 'bank',
                    render: (_, { institutionName }) => (
                      <RegularText>{institutionName}</RegularText>
                    ),
                  },
                  {
                    title: <IncomeStreamHeader>Cash Available</IncomeStreamHeader>,
                    dataIndex: 'cash',
                    key: 'cash',
                    render: (_, { availableBalance }) => (
                      <RegularText>
                        {printDollarsFromCents(availableBalance ?? 0)}
                      </RegularText>
                    ),
                  },
                ]}
                dataSource={dataSource}
                noBorderBottom
                noClickableRows
                pagination={false}
                rowKey={({ id }) => id}
                size="middle"
              />
            </Tile.Inner>
          )}
          {emptyData && (
            <Tile.Inner noPaddingTop key={0} data-testid={`account-${0}`}>
              <FlexContainer
                alignItems="center"
                flexDirection="row"
                justifyContent="center"
                gap="8px"
              >
                <Icon.InfoBlueIcon />
                <RegularText>
                  There are no bank balances available for this application.
                </RegularText>
              </FlexContainer>
            </Tile.Inner>
          )}
        </Tile>
      )}
    </>
  )
}
