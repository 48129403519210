import { Dispatch, SetStateAction } from "react";

import PropTypes from "prop-types";

export interface SummaryProps {
  numPropertiesConnected: number;
  numUnitsConnected: number;
  areDocumentTypesComplete: boolean;
  areChargeTypesComplete: boolean;
  areLeadSourcesComplete: boolean;
  areSyncSettingsComplete: boolean;
  editDocumentTypes: () => void;
  editChargeTypes: () => void;
  editLeadSources: () => void;
  editSyncSettings: () => void;
}

const summaryPropTypes = {
  numPropertiesConnected: PropTypes.number.isRequired,
  numUnitsConnected: PropTypes.number.isRequired,
  areDocumentTypesComplete: PropTypes.bool.isRequired,
  areChargeTypesComplete: PropTypes.bool.isRequired,
  areLeadSourcesComplete: PropTypes.bool.isRequired,
  areSyncSettingsComplete: PropTypes.bool.isRequired,
  editDocumentTypes: PropTypes.func.isRequired,
  editChargeTypes: PropTypes.func.isRequired,
  editLeadSources: PropTypes.func.isRequired,
  editSyncSettings: PropTypes.func.isRequired,
};

interface YardiProperty {
  id: number;
  name: string;
  address: string;
  fullAddress: string;
  status: string;
  yardiId: string;
}

interface Pagination {
  page: number;
  pageSize: number;
}

interface TableFilters {
  nameOrAddress: string;
  status: string;
}

export interface PropertiesTableProps {
  importedYardiProperties: YardiProperty[];
  importYardiPropertiesAction: () => void;
  filterYardiPropertiesByStatus?: () => void;
  editPropertySetup: (property: any) => void;
  deleteProperty: (property: any) => void;
  isTableLoading: boolean;
  currentPagination: Pagination;
  setCurrentPagination: Dispatch<
    SetStateAction<{ page: number; pageSize: number }>
  >;
  setCurrentOrdering: Dispatch<any>;
  totalCount: number;
  currentStatus?: string;
  filters: TableFilters;
  updateFilters: (values: any) => void;
}

const propertiesTablePropTypes = {
  importedYardiProperties: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
      yardiId: PropTypes.string.isRequired,
    })
  ).isRequired,
  importYardiPropertiesAction: PropTypes.func.isRequired,
  filterYardiPropertiesByStatus: PropTypes.func.isRequired,
  editPropertySetup: PropTypes.func.isRequired,
  deleteProperty: PropTypes.func.isRequired,
  isTableLoading: PropTypes.bool.isRequired,
  currentPagination: PropTypes.shape({
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
  }).isRequired,
  setCurrentPagination: PropTypes.func.isRequired,
  setCurrentOrdering: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  currentStatus: PropTypes.string.isRequired,
  filters: PropTypes.shape({
    nameOrAddress: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  updateFilters: PropTypes.func.isRequired,
};

export interface TableProps extends PropertiesTableProps {
  isFilterActive: boolean;
}

const tablePropTypes = {
  ...propertiesTablePropTypes,
  isFilterActive: PropTypes.bool.isRequired,
};

const YARDI_PROPERTIES_STATUS = Object.freeze({
  SETUP_NOT_STARTED: "SETUP_NOT_STARTED",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
});

const YARDI_PROPERTIES_STATUS_LABELS = {
  [YARDI_PROPERTIES_STATUS.SETUP_NOT_STARTED]: "Setup not started",
  [YARDI_PROPERTIES_STATUS.IN_PROGRESS]: "In progress",
  [YARDI_PROPERTIES_STATUS.COMPLETED]: "Connected",
};

export {
  summaryPropTypes,
  tablePropTypes,
  propertiesTablePropTypes,
  YARDI_PROPERTIES_STATUS,
  YARDI_PROPERTIES_STATUS_LABELS,
};
