import React from "react";

import { useApplicationStatuses } from "shared/hooks/api";

import ApplicationStatus from "./ApplicationStatus";

interface ApplicationStatusContainerProps {
  status: number | string;
}

const ApplicationStatusContainer = ({
  status,
}: ApplicationStatusContainerProps) => {
  const { data: allStatuses } = useApplicationStatuses();

  return <ApplicationStatus allStatuses={allStatuses} status={status} />;
};

export default ApplicationStatusContainer;
