import React from "react";

import isFunction from "lodash/isFunction";
import { Redirect } from "react-router-dom";

import ROUTES from "shared/config/routes";

export interface IConditionalPage {
  children?: any;
  params?: {
    location?: any;
    history?: any;
    match?: any;
  };
  isEnabled?: boolean | ((params: any) => void);
  isInitialized?: boolean;
  redirectTo?: string;
}

const ConditionalPage = ({
  params = undefined,
  isInitialized = false,
  isEnabled = false,
  children = undefined,
  redirectTo = ROUTES.notFound,
}: IConditionalPage) => {
  const { location } = params || {};

  if (!isInitialized) {
    return null;
  }

  const isRouteEnabled = isFunction(isEnabled) ? isEnabled(params) : isEnabled;

  return isRouteEnabled ? (
    children
  ) : (
    <Redirect
      to={{
        pathname: redirectTo,
        state: { from: location },
      }}
    />
  );
};

export default ConditionalPage;
