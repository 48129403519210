import React from "react";

import { useHistory } from "react-router-dom";

import ROUTES from "manager/config/routes";
import Icon from "shared/components/Icon";
import { ActionLink } from "shared/components/Links";
import Table from "shared/components/Table";

import { PortfolioTableProps } from "../interfaces";

const PortfolioTable = ({
  assignPropertiesToPortfolio,
  renamePortfolio,
  portfolios = [],
  loading,
  hasInputValue,
  pagination,
  setPagination,
  portfolioCount,
  onPortfolioTableChange,
}: PortfolioTableProps) => {
  const history = useHistory();

  const { page, pageSize } = pagination;

  const getPortfoliosRows = () =>
    portfolios?.map((portfolio, index) => {
      return {
        key: portfolio.id,
        values: [
          <strong data-testid={`portfolio-${index}-name`}>
            {portfolio.name}
          </strong>,
          <span data-testid={`portfolio-${index}-numProperties`}>
            {portfolio.numProperties}
          </span>,
        ],
        primaryAction: (
          <ActionLink
            onClick={() => {
              assignPropertiesToPortfolio({
                portfolioName: portfolio.name,
                portfolioId: portfolio.id,
              });
            }}
            data-testid={`portfolio-${index}-assignProperties`}
          >
            Assign Properties
          </ActionLink>
        ),
        dropdownItems: [
          {
            key: "SETTINGS",
            label: "Settings",
            onClick: (key) =>
              history.push(ROUTES.portfolioSettings.replace(":id", key)),
          },
          {
            key: "RENAME_PORTFOLIO",
            label: "Rename",
            onClick: () =>
              renamePortfolio({
                name: portfolio.name,
                portfolioId: portfolio.id,
              }),
          },
        ],
      };
    });

  return (
    <Table
      alt
      searching={hasInputValue}
      showActionColumn
      dropdownItems
      columns={[
        {
          title: "Portfolio Name",
          dataIndex: "name",
          key: "name",
          sorter: true,
        },
        {
          title: "Properties",
          dataIndex: "properties",
          key: "properties",
          sorter: true,
        },
      ]}
      rows={getPortfoliosRows()}
      primaryAction={{
        label: "Assign Properties",
      }}
      columnBreakpoints={{
        xs: {
          column1: "name",
          column2: "properties",
        },
        sm: ["name", "properties"],
        md: ["name", "properties"],
        lg: ["name", "properties"],
      }}
      onRowClick={(key) => history.push(ROUTES.portfolio.replace(":id", key))}
      placeholder={{
        label: "Portfolios",
        Icon: Icon.NoPortfoliosIcon,
      }}
      loading={loading}
      pagination={{
        current: page,
        onChange: (currentPage) =>
          setPagination({ page: currentPage, pageSize }),
        total: portfolioCount,
        defaultPageSize: pageSize,
      }}
      onChange={onPortfolioTableChange}
    />
  );
};

export default PortfolioTable;
