import React from "react";

import { Form, useFormikContext } from "formik";
import PropTypes from "prop-types";

import { Input, VeroFormField } from "shared/components/Form";
import { Modal } from "shared/components/Modal";
import Spacer from "shared/components/Spacer";

import { ITEM_NAME } from "./config";
import { ActionLink } from "./styled";

const DealSetupAttachmentsInnerFormElements = ({
  isNewItemShown,
  setNewItemShown,
}) => {
  const { isValid, submitForm, setFieldValue } = useFormikContext();

  return (
    <Form>
      <Modal.Body noPaddingTop>
        {isNewItemShown && (
          <>
            <VeroFormField
              as={Input}
              data-testid="new-item-input"
              id={ITEM_NAME}
              name={ITEM_NAME}
              placeholder="Enter the text of your attachment here..."
              formikContext={{
                trimOnBlur: true,
                setFieldValue,
              }}
              maxLength={255}
            />
            <Spacer size={Spacer.SIZES.sm} />
            <ActionLink
              onClick={() => {
                submitForm();
                if (isValid) {
                  setNewItemShown(false);
                }
              }}
              data-testid="save-item-link"
            >
              Save Attachment
            </ActionLink>
          </>
        )}
        {!isNewItemShown && (
          <ActionLink
            plus
            dashed
            onClick={() => setNewItemShown(true)}
            data-testid="add-item-link"
          >
            Attachment
          </ActionLink>
        )}
      </Modal.Body>
    </Form>
  );
};

DealSetupAttachmentsInnerFormElements.propTypes = {
  isNewItemShown: PropTypes.bool.isRequired,
  setNewItemShown: PropTypes.func.isRequired,
};

export default DealSetupAttachmentsInnerFormElements;
