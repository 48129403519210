import axios from "axios"

import { VEGA_BASE_URL } from "shared/config/apiRoutes"

const api = {
  search: (params) =>
    axios
      .get(`${VEGA_BASE_URL}/api/search`, {
        params,
        // @ts-ignore
        returnRaw: true
      })
      .then(({ data }) => data)
}

export default api
