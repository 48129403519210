import axios from "axios";

import { VEGA_BASE_URL } from "shared/config/apiRoutes";

const PRICING_OFFER_PATH = "/api/pricing-offer";

const api = {
  getPricingOffers: ({ location }) =>
    axios
      .get(`${VEGA_BASE_URL}${PRICING_OFFER_PATH}?location=${location}`)
      .then(({ data }) => data),
};

export default api;
