import cloneDeep from "lodash/cloneDeep";
import { Subject } from "rxjs";

export const events = Object.freeze({
  addItem: "ADD_ITEM",
  removeItem: "REMOVE_ITEM",
  autoSync: "AUTO_SYNC",
  allEvents: "ALL_EVENTS",
});

export class CommonStorageCollectionManager {
  storageListener: (event: any) => void;

  storageKey: any;

  storage: any;

  eventHandlers = {
    [events.addItem]: [],
    [events.removeItem]: [],
    [events.autoSync]: [],
    [events.allEvents]: [],
  };

  constructor({ storageKey, autoSync = false }) {
    this.storageKey = storageKey;
    this.storage = null;
    this.restore();

    if (autoSync) {
      this.storageListener = (event) => {
        if (event.key === storageKey) {
          this.restore();
          this.notifyListeners(events.autoSync, this.storage);
        }
      };
      window.addEventListener("storage", this.storageListener);
    }
  }

  addItem$ = new Subject<{ key: string; value: string }>();

  events = events;

  destroy() {
    if (this.storageListener) {
      window.removeEventListener("storage", this.storageListener);
    }
  }

  // eslint-disable-next-line class-methods-use-this,@typescript-eslint/no-unused-vars
  storageGetItem(key): string {
    throw new Error("Not implemented");
  }

  // eslint-disable-next-line class-methods-use-this,@typescript-eslint/no-unused-vars
  storageSetItem(key, value) {
    throw new Error("Not implemented");
  }

  subscribe(event, callback) {
    if (this.eventHandlers[event]) {
      this.eventHandlers[event].push(callback);
    }

    return {
      unsubscribe: () => {
        this.unsubscribe(event, callback);
      },
    };
  }

  unsubscribe(event, callback) {
    if (this.eventHandlers[event]) {
      this.eventHandlers[event] = this.eventHandlers[event].filter(
        (item) => item !== callback
      );
    }
  }

  notifyListeners(event, ...args) {
    this.eventHandlers[event].forEach((callback) => callback(...args));
    this.eventHandlers[events.allEvents].forEach((callback) =>
      callback(...args)
    );
  }

  restore() {
    let storage = this.storageGetItem(this.storageKey);

    if (storage) {
      try {
        storage = JSON.parse(storage);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn(`Failed to parse storage[${this.storageKey}]`);
      }
    }

    this.storage = storage || {};
  }

  sync() {
    const cache = JSON.stringify(this.storage);
    this.storageSetItem(this.storageKey, cache);
  }

  addItem(key, value) {
    this.storage[key] = value;
    this.sync();
    this.addItem$.next({ key, value });
    this.notifyListeners(events.addItem, { key, value });
  }

  has(key) {
    return key in this.storage;
  }

  getItem(key) {
    return this.storage[key];
  }

  removeItem(key) {
    const value = this.storage[key];
    delete this.storage[key];
    this.sync();
    this.notifyListeners(events.removeItem, { key, value });
  }

  getStorage() {
    return cloneDeep(this.storage);
  }
}
