import React from "react";

import { useParams } from "react-router-dom";

import {
  useGetTempMagicLinksCompany,
  useGetTempMagicLinksProperties,
} from "renter/hooks/api";

import MagicLinkProperties from "./MagicLinkProperties";

const MagicLinkPropertiesContainer = () => {
  const { companyHashId } = useParams();

  const { companyProfile, isCompanyProfileLoading } =
    useGetTempMagicLinksCompany(companyHashId);
  const { logoUrl } = companyProfile || {};

  const { properties, isPropertiesLoading } =
    useGetTempMagicLinksProperties(companyHashId);

  return (
    <MagicLinkProperties
      companyHashId={companyHashId}
      isLoading={isCompanyProfileLoading || isPropertiesLoading}
      logoUrl={logoUrl}
      properties={properties}
    />
  );
};

export default MagicLinkPropertiesContainer;
