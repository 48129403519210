import React from "react";

import { Row, Col } from "antd";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { Formik } from "formik";
import pluralize from "pluralize";

import {
  DatePicker,
  MaskedInput,
  Select,
  VeroFormField,
} from "shared/components/Form";
import { Modal } from "shared/components/Modal";
import Spacer from "shared/components/Spacer";

import { ValidationSchema } from "./config";
import {
  FormikUpdateDealRevenueDetailsProps,
  UpdateDealRevenueDetailsValues,
} from "./interfaces";
import { useUpdateDealRevenueDetailsHook, getLeaseDurationOptions } from "./use-update-deal-revenue-details";

dayjs.extend(isSameOrAfter);

interface IUpdateDealRevenueDetailsModal {
  values: UpdateDealRevenueDetailsValues;
  refetchDeal?: () => void;
  dealId: number;
  minMonths?: number;
  availableDate?: dayjs.Dayjs;
}

const UpdateDealRevenueDetailsModal = ({
  values,
  refetchDeal,
  dealId,
  minMonths,
  availableDate,
}: IUpdateDealRevenueDetailsModal) => {
  const { rent, preferredLeaseStartDate, preferredLeaseDurationMonths } =
    values ?? {};
  const { submit, isDateDisabled, unitAvailabilityTooltip } =
    useUpdateDealRevenueDetailsHook({
      refetchDeal,
      dealId,
      initValues: values,
      availableDate,
    });
  return (
    <Formik
      initialValues={values}
      onSubmit={submit}
      enableReinitialize
      validationSchema={ValidationSchema}
      validateOnMount
    >
      {({
        submitForm,
        isSubmitting,
        setFieldValue,
      }: FormikUpdateDealRevenueDetailsProps) => (
        <Modal
          width="516px"
          title="Edit rent"
          submit={submitForm}
          submitting={isSubmitting}
          submitButtonLabel="Update"
          closeOnSubmit={false}
        >
          <Modal.Body>
            <Row>
              <Col>
                <VeroFormField
                  as={MaskedInput.Formik.Money}
                  name="rent"
                  id="rent"
                  label="Monthly rent"
                  required
                  value={MaskedInput.Money.convertInitialValue(rent)}
                  onChange={(event) =>
                    setFieldValue(
                      "rent",
                      MaskedInput.Money.unmask(event.target.value)
                    )
                  }
                />
              </Col>
            </Row>
            <Spacer />
            {preferredLeaseStartDate && preferredLeaseDurationMonths && (
              <Row gutter={[20, 25]}>
                <Col md={12}>
                  <VeroFormField
                    as={DatePicker}
                    id="preferredLeaseStartDate"
                    name="preferredLeaseStartDate"
                    label="Lease Start Date"
                    data-testid="preferredLeaseStartDate"
                    disabledDate={isDateDisabled}
                    tooltip={unitAvailabilityTooltip}
                  />
                </Col>
                <Col md={12}>
                  <VeroFormField
                    as={Select}
                    type="number"
                    min={0}
                    max={12}
                    id="preferredLeaseDurationMonths"
                    name="preferredLeaseDurationMonths"
                    label="Terms (months)"
                    data-testid="preferredLeaseDurationMonths"
                  >
                    {getLeaseDurationOptions(minMonths)?.map((item) => (
                      <Select.Option
                        key={item}
                        value={item}
                        data-testid={`preferredLeaseDurationMonths-${item}`}
                      >
                        {item} {pluralize("month", item)}
                      </Select.Option>
                    ))}
                  </VeroFormField>
                </Col>
              </Row>
            )}
            <Spacer />
          </Modal.Body>
        </Modal>
      )}
    </Formik>
  );
};

export default UpdateDealRevenueDetailsModal;
