import React, { ReactNode } from "react";

import { SwitchChangeEventHandler } from "antd-latest/lib/switch";

import Tooltip from "shared/components/Tooltip";

import {
  Description,
  Label,
  StyledSwitch,
  SwitchTitleContainer,
  Text,
} from "./styled";

export type SwitchTitleProps = {
  onChange: SwitchChangeEventHandler;
  title?: ReactNode;
  tooltip?: ReactNode;
  description?: string;
  value?: boolean;
  hasItems?: boolean;
  disabled?: boolean;
  noPadding?: boolean;
  className?: string;
  reverseOrder?: boolean;
  size?: string;
  "data-testid"?: string;
  subTitle?: string;
  id?: string;
  name?: string;
};

export const SwitchTitle = ({
  id,
  name,
  title,
  description,
  onChange,
  value,
  hasItems,
  disabled,
  tooltip,
  noPadding,
  className,
  reverseOrder,
  size,
  "data-testid": dataTestId = "switch",
  subTitle,
}: SwitchTitleProps) => (
  <SwitchTitleContainer
    className={className}
    hasItems={hasItems}
    isChecked={value}
    noPadding={noPadding}
    reverseOrder={reverseOrder}
  >
    <Tooltip title={tooltip} theme="light">
      <div className="switch-text-wrapper">
        <Text>
          {title && (
            <Label data-testid="title" size={size}>
              {title}
            </Label>
          )}
          {description && <Description>({description})</Description>}
        </Text>
        <StyledSwitch
          id={id}
          name={name}
          checked={value}
          defaultChecked
          onChange={(targetValue, event) => onChange(targetValue, event)}
          disabled={disabled}
          hasDisabledTooltip={disabled && !!tooltip}
          size={size}
          subTitle={subTitle}
          data-testid={dataTestId}
        />
      </div>
    </Tooltip>
  </SwitchTitleContainer>
);
