import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { COLORS } from "shared/config/constants";

import { SIZES } from "./utils";

const Container = styled("div")<{ size?: string }>`
  display: flex;
  align-items: start;
  margin-bottom: 32px;
  ${(props) =>
    props.size === SIZES.sm &&
    css`
    margin-bottom: 16px'
    `}
  ${(props) =>
    props.size === SIZES.md &&
    css`
    margin-bottom: 24px'
    `}
`;

const Number = styled("div")<{ size?: string }>`
  background-color: ${COLORS.numberTitle.number.background};
  font-size: 12px;
  width: 20px;
  height: 20px
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  ${(props) =>
    props.size === SIZES.sm &&
    css`
      font-size: 10px;
    `}
  margin-top: 3px;
`;

const Title = styled("h6")<{ size?: string }>`
  margin-left: 12px;
  font-size: 17px;
  margin-bottom: 0;
  ${(props) =>
    props.size === SIZES.sm &&
    css`
      font-size: 15px;
      font-weight: 300;
    `}
`;

export { Container, Number, Title };
