import React from "react";

import { Col, Row } from "antd";

import { DatePicker, VeroFormField, MaskedInput } from "shared/components/Form";

import { CLOSE_LISTING_FIELDS } from "./closeListing.config";

const CloseListingInnerFormElements = () => {
  return (
    <>
      <Row gutter={[20, 20]}>
        <Col md={{ span: 12 }}>
          {/* @ts-ignore */}
          <VeroFormField
            as={DatePicker}
            id={CLOSE_LISTING_FIELDS.rentedFrom}
            name={CLOSE_LISTING_FIELDS.rentedFrom}
            label="Lease Start Date"
            data-testid={CLOSE_LISTING_FIELDS.rentedFrom}
          />
        </Col>
        <Col md={{ span: 12 }}>
          {/* @ts-ignore */}
          <VeroFormField
            as={DatePicker}
            id={CLOSE_LISTING_FIELDS.rentedUntil}
            name={CLOSE_LISTING_FIELDS.rentedUntil}
            label="Lease End Date"
            data-testid={CLOSE_LISTING_FIELDS.rentedUntil}
          />
        </Col>
      </Row>
      <br />
      <Row gutter={[20, 20]}>
        <Col md={{ span: 12 }}>
          {/* @ts-ignore */}
          <MaskedInput.Formik.Money
            id={CLOSE_LISTING_FIELDS.rent}
            name={CLOSE_LISTING_FIELDS.rent}
            label="Monthly Rent"
            data-testid={CLOSE_LISTING_FIELDS.rent}
          />
        </Col>
        <Col md={{ span: 12 }}>
          {/* @ts-ignore */}
          <MaskedInput.Formik.Money
            id={CLOSE_LISTING_FIELDS.securityDeposit}
            name={CLOSE_LISTING_FIELDS.securityDeposit}
            label="Security Deposit"
            data-testid={CLOSE_LISTING_FIELDS.securityDeposit}
          />
        </Col>
      </Row>
    </>
  );
};

export default CloseListingInnerFormElements;
