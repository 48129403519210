import styled from "@emotion/styled";

import { COLORS } from "shared/config/constants";

export const Container = styled("div")({
  maxHeight: "100%",
  width: "342px",
  background: COLORS.leasePreview.documentInfo.background,
  padding: "20px 11px 20px 20px",
});

export const Title = styled("div")({
  color: COLORS.leasePreview.documentInfo.title,
  textTransform: "uppercase",
  fontSize: "15px",
  padding: "20px 0",
});

export const ScrollContainer = styled.div({
  maxHeight: "calc(100vh - 160px) ",
  overflowY: "auto",
  paddingRight: 9,
});

export const Row = styled("div")({
  background: COLORS.leasePreview.documentInfo.row,
  display: "flex",
  padding: "12px 16px",
  marginBottom: "4px",
  "& > *": {
    marginRight: "4px",
  },
  flexWrap: "wrap",
});

export const Primary = styled.div({
  color: COLORS.leasePreview.documentInfo.primaryText,
  fontWeight: 600,
  fontSize: "15px",
});

export const Secondary = styled.div({
  color: COLORS.leasePreview.documentInfo.secondaryText,
  fontWeight: 600,
  fontSize: "15px",
  width: "100%",
});
