import styled from "@emotion/styled";

import BaseFlexContainer from "shared/components/FlexContainer";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

const FlexContainer = styled(BaseFlexContainer)`
  div {
    width: 100%;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  border-bottom: 1px solid ${COLORS.borders};
  background-color: ${COLORS.white};
  padding: 15px 24px;

  ${MEDIA_QUERIES.w.ltMd} {
    justify-content: space-between;
  }

  ${MEDIA_QUERIES.w.gteMd} {
    & > div:not(:first-of-type) {
      margin-left: 40px;
    }
  }
`;

export { FlexContainer, CheckboxContainer };
