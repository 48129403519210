import React from "react";

import { Col, Row } from "antd";
import { Helmet } from "react-helmet";

import { useTranslation } from "renter/hooks/useTranslation";
import ResidentialHistory from "shared/components/Applicant/ResidentialHistory";
import Container from "shared/components/Container";
import { ActionLink } from "shared/components/Links";
import PageTitle from "shared/components/PageTitle";
import Tile from "shared/components/Tile";
import {
  UserProfile,
  EmergencyContact,
  EmploymentHistory as IEmploymentHistory,
  Reference,
  ResidentialHistory as IResidentialHistory,
} from "shared/interfaces/api";
import Person from "shared/pages/ApplicationSummary/RenterProfile/Person";
import { getFullnameData } from "shared/utils/applicant";

import EmergencyContacts from "./EmergencyContacts";
import EmploymentHistory from "./EmploymentHistory";
import References from "./References";

interface PersonalInfoProps {
  name: string;
  activeUser?: UserProfile;
  emergencyContacts: EmergencyContact[];
  residentialHistory: IResidentialHistory[];
  employmentHistory: IEmploymentHistory[];
  references: Reference[];
  openEditBasicInfoModal: () => void;
}

const PersonalInfo = ({
  name,
  activeUser = {},
  emergencyContacts,
  residentialHistory,
  employmentHistory,
  references,
  openEditBasicInfoModal,
}: PersonalInfoProps) => {
  const { t } = useTranslation({
    keyPrefix: "personal-info.basic-info",
  });

  const { phoneNumber, email, birthdate } = activeUser;

  const { fullNameOrNA } = getFullnameData(activeUser);

  return (
    <>
      <Helmet>
        <title>{name}</title>
      </Helmet>
      <Container noPaddingBottom>
        <PageTitle.Container>
          <PageTitle data-testid="title">{name}</PageTitle>
        </PageTitle.Container>
      </Container>
      <Container noPaddingTop noMobilePaddingX>
        <Row gutter={14} type="flex">
          <Col xs={24} lg={12}>
            <Tile
              equalHeight
              header={{
                title: t("title"),
                primaryAction: (
                  <ActionLink onClick={openEditBasicInfoModal}>
                    {t("edit")}
                  </ActionLink>
                ),
              }}
            >
              <Tile.Inner data-testid="person">
                <Person
                  name={fullNameOrNA}
                  phoneNumber={phoneNumber}
                  email={email}
                  dateOfBirth={birthdate}
                />
              </Tile.Inner>
            </Tile>
          </Col>
          <Col xs={24} lg={12}>
            <EmergencyContacts emergencyContacts={emergencyContacts} />
          </Col>
        </Row>
        <ResidentialHistory residentialHistory={residentialHistory} />
        <EmploymentHistory employmentHistory={employmentHistory} />
        <References references={references} />
      </Container>
    </>
  );
};

export default PersonalInfo;
