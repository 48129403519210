import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { Radio } from "antd";

import { COLORS, MEDIA_QUERIES, colors } from "shared/config/constants";

import { RadioButtonsTheme, RADIO_BUTTONS_THEMES } from "./interfaces";

const violetTheme = {
  backgroundColor: COLORS.violetTheme.lightVioletButton,
  textColor: COLORS.white,
  hoverTextColor: COLORS.violetTheme.text,
  hoverActiveTextColor: COLORS.violetTheme.text,
  checkedButtonColor: COLORS.violetTheme.text,
  checkedButtonBackgroundColor: COLORS.violetTheme.lightViolet,
};

const darkVioletTheme = {
  backgroundColor: COLORS.darkViolet2,
  textColor: COLORS.white,
  hoverTextColor: COLORS.violetTheme.text,
  hoverActiveTextColor: COLORS.white,
  checkedButtonColor: COLORS.white,
  checkedButtonBackgroundColor: COLORS.lightViolet,
};

const lightVioletTheme = {
  backgroundColor: COLORS.darkViolet3,
  textColor: COLORS.white,
  hoverTextColor: COLORS.violetTheme.text,
  hoverActiveTextColor: COLORS.white,
  checkedButtonColor: COLORS.white,
  checkedButtonBackgroundColor: COLORS.darkPurple,
};

const blueTheme = {
  backgroundColor: COLORS.darkBlue,
  textColor: COLORS.white,
  hoverTextColor: COLORS.violetTheme.text,
  hoverActiveTextColor: COLORS.white,
  checkedButtonColor: COLORS.white,
  checkedButtonBackgroundColor: COLORS.lightBlue,
};

const grayTheme = {
  backgroundColor: COLORS.grayTheme.backgroundColor,
  textColor: COLORS.grayTheme.textColor,
  hoverTextColor: COLORS.grayTheme.hoverTextColor,
  hoverActiveTextColor: COLORS.gray2,
  checkedButtonColor: COLORS.grayTheme.activeTextColor,
  checkedButtonBackgroundColor: COLORS.white,
};

const accent400Theme = {
  backgroundColor: colors.accent[600],
  textColor: colors.shades[0],
  hoverTextColor: colors.shades[0],
  hoverActiveTextColor: colors.shades[0],
  checkedButtonColor: colors.shades[0],
  checkedButtonBackgroundColor: colors.accent[400],
};

const secondary400Theme = {
  backgroundColor: colors.secondary[700],
  textColor: colors.shades[0],
  hoverTextColor: colors.shades[0],
  hoverActiveTextColor: colors.shades[0],
  checkedButtonColor: colors.shades[0],
  checkedButtonBackgroundColor: colors.secondary[400],
};

const primary300Theme = {
  backgroundColor: colors.primary[200],
  textColor: colors.shades[0],
  hoverTextColor: colors.shades[0],
  hoverActiveTextColor: colors.shades[0],
  checkedButtonColor: colors.shades[0],
  checkedButtonBackgroundColor: colors.primary[300],
};

export const THEMES = {
  [RADIO_BUTTONS_THEMES.violet]: violetTheme,
  [RADIO_BUTTONS_THEMES.darkViolet]: darkVioletTheme,
  [RADIO_BUTTONS_THEMES.lightViolet]: lightVioletTheme,
  [RADIO_BUTTONS_THEMES.blue]: blueTheme,
  [RADIO_BUTTONS_THEMES.gray]: grayTheme,
  [RADIO_BUTTONS_THEMES.accent400]: accent400Theme,
  [RADIO_BUTTONS_THEMES.secondary400]: secondary400Theme,
  [RADIO_BUTTONS_THEMES.primary300]: primary300Theme,
};

interface ContainerProps {
  themeName?: RadioButtonsTheme;
}

const Container = styled.div<ContainerProps>`
  background-color: ${({ themeName = RADIO_BUTTONS_THEMES.violet }) =>
    THEMES[themeName].backgroundColor};
  border-radius: 4px;
  padding: 4px;

  ${MEDIA_QUERIES.w.ltSm} {
    overflow-y: auto;
    width: 100%;
  }
`;

const RadioGroup = styled(Radio.Group)`
  display: block;

  ${MEDIA_QUERIES.w.ltSm} {
    display: flex;
    flex-wrap: nowrap;
  }
`;

interface RadioButtonProps {
  themeName?: RadioButtonsTheme;
}

const RadioButton = styled(Radio.Button)<RadioButtonProps>`
  border: none !important;
  border-radius: 4px !important;
  height: 34px;
  line-height: 34px;
  white-space: nowrap;
  outline: none !important;

  &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      outline: none !important;
      box-shadow: none;
      &:focus-within {
        outline: none;
      }
    }
  }

  ${({ themeName = RADIO_BUTTONS_THEMES.violet }) => css`
    background-color: ${THEMES[themeName].backgroundColor};
    color: ${THEMES[themeName].textColor};
    &:hover {
      color: ${THEMES[themeName].hoverTextColor};
    }
    &:not(:first-of-type):before {
      background-color: ${THEMES[themeName].backgroundColor};
    }
    &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      color: ${THEMES[themeName].checkedButtonColor};
      background-color: ${THEMES[themeName].checkedButtonBackgroundColor};

      &:hover {
        background-color: ${THEMES[themeName].checkedButtonBackgroundColor};
        color: ${THEMES[themeName].hoverActiveTextColor};
      }
      &:before {
        background-color: ${THEMES[themeName]
          .checkedButtonBackgroundColor} !important;
      }
    }
  `}
`;

export { Container, RadioGroup, RadioButton };
