import React from "react";

import useModal from "shared/hooks/useModal";

import SingleInputModal from "./SingleInputModal";

const SingleInputModalContainer = () => {
  const { currentModalContext } = useModal();
  const {
    title,
    subtitle,
    inputDescription,
    inputPlaceholder,
    confirmLabel,
    submit,
    initialValue,
    valueValidationSchema,
    closeOnSubmit,
    inputMode,
    maxLength,
    showCount,
    extraContentRenderer,
  } = currentModalContext;

  const onSubmit = async ({ value }, formikApi) => {
    try {
      await submit({ value }, formikApi);
    } finally {
      formikApi.setSubmitting(false);
    }
  };

  return (
    <SingleInputModal
      title={title}
      subtitle={subtitle}
      inputDescription={inputDescription}
      inputPlaceholder={inputPlaceholder}
      confirmLabel={confirmLabel}
      onSubmit={onSubmit}
      initialValue={initialValue}
      valueValidationSchema={valueValidationSchema}
      closeOnSubmit={closeOnSubmit}
      inputMode={inputMode}
      maxLength={maxLength}
      showCount={showCount}
      extraContentRenderer={extraContentRenderer}
    />
  );
};

export default SingleInputModalContainer;
