export const activeUserId = (state) => state?.auth?.activeUser;
export const activeUser = (state) =>
  state?.entities?.user?.[activeUserId(state)];
export const activeUserEmail = (state) => activeUser(state)?.email;
export const activeUserPhoneNumber = (state) => activeUser(state)?.phoneNumber;
export const activeUserBirthdate = (state) => activeUser(state)?.birthdate;

export const users = (state) => state?.entities?.user;

export const getUser = (state, id) => users(state)?.[id];
