import styled from "@emotion/styled";

const Container = styled("div")`
  display: flex;
  align-items: center;
`;

const Text = styled("span")`
  margin-left: 8px;
`;

export { Container, Text };
