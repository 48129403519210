import { useQuery } from "react-query";

import api from "shared/lib/api";

const GET_PMS_PROPERTIES_KEY = "getPmsProperties";

export const useGetPmsProperties = ({ location, provider }) => {
  const { data: pmsProperties, isLoading: isPmsPropertiesLoading } = useQuery(
    [GET_PMS_PROPERTIES_KEY , location, provider],
    () => api.getPmsProperties({ location, provider }),
    {
      onError: () => {},
    }
  );

  return {
    pmsProperties,
    isPmsPropertiesLoading,
  };
}
