import React from 'react'
import styled from '@emotion/styled'
import { colors, typography } from 'shared/config/constants'
import { buildClassName } from 'shared/utils/vega'
import TaskStep from './TaskStep'

const TaskStepContainer = styled(({ className, task }) => {
  return (
    <div className={buildClassName([className, 'vega', 'status-bar', task?.status])}>
      <div className='task-step-inner-container'>
        <TaskStep task={task} />
      </div>
    </div>
  )
})({
  paddingBottom: '1rem',
  display: 'block',
  position: 'relative',
  paddingLeft: '2rem',
  paddingRight: '2rem',
  '&.status-bar': {
    borderLeftStyle: 'solid',
    borderLeftWidth: '.25rem'
  },
  '&:last-child': {
    paddingBottom: 0
  },
  '&::before': {
    position: 'absolute',
    width: '1.5rem',
    height: '1.5rem',
    fontSize: 'calc(1.5rem / 2)',
    padding: 'calc(1rem - (1.5rem / 2))',
    left: 'calc(-1 * (1.5rem / 1.86))',
    color: typography.textColors.dark.primary,
    textAlign: 'center',
    borderRadius: '100%'
  },
  '&.complete::before': {
    content: '\'✓\'',
    backgroundColor: colors.success.base
  },
  '&.next': {
    borderColor: colors.secondary.base
  },
  '&.next::before': {
    content: '\'⧗\'',
    backgroundColor: colors.secondary.base
  },
  '&.possible': {
    borderColor: colors.neutral.base
  },
  '&.possible::before': {
    content: '\'?\'',
    backgroundColor: colors.neutral.base
  },
  '&.retry': {
    borderColor: colors.warning.base
  },
  '&.retry::before': {
    content: '\'✓\'',
    backgroundColor: colors.warning.base
  },
  '&.failed::before': {
    content: '\'✖\'',
    backgroundColor: colors.error.base
  },
  '.task-step-inner-container': {
    display: 'flex',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    borderBottomColor: colors.neutral.base,
    paddingBottom: '1rem'
  },
  '&:last-child .task-step-inner-container': {
    borderBottomStyle: 'none',
    paddingBottom: 0
  }
})

export default TaskStepContainer
