import React, { useEffect } from "react";

import { Field, useFormikContext } from "formik";
import PropTypes from "prop-types";

import { useGetYardiPropertyReasons } from "manager/hooks/api";
import { Select } from "shared/components/Form";
import { keyPropType } from "shared/utils/propTypes";

import { YARDI_REASON_TYPES } from "../yardi.config";

import { YardiReasonFormGroup } from "./styled";

const EXTENSION_PROPS = {
  property: PropTypes.shape({
    yardiPropertyId: keyPropType,
  }),
  marginTop: PropTypes.bool,
};

const DEFAULT_EXTENSION_PROPS = {
  property: undefined,
  marginTop: false,
};

const YardiReasonFormExtension = ({
  property,
  yardiReasonType,
  reasonLabel,
  formFieldName,
  marginTop,
}) => {
  const { touched, errors, setFieldValue, setFieldTouched, validateForm } =
    useFormikContext();

  const { yardiPropertyReasons, isLoading: yardiPropertyReasonsLoading } =
    useGetYardiPropertyReasons(property?.yardiPropertyId, yardiReasonType);

  const validateReason = (value) => {
    if (!value) {
      return "Reason is mandatory";
    }
    return undefined;
  };

  useEffect(() => {
    validateForm();
  }, []);

  return (
    <YardiReasonFormGroup marginTop={marginTop} className="form-group">
      <p className="form-control-label">{reasonLabel}</p>

      <Field
        as={Select}
        validate={validateReason}
        name={formFieldName}
        id={formFieldName}
        label="Select the reason"
        data-testid="reason-select"
        // @ts-ignore
        error={touched.reason && errors.reason}
        onChange={(value) => setFieldValue(formFieldName, value)}
        onBlur={() => setFieldTouched(formFieldName, true)}
        loading={yardiPropertyReasonsLoading}
        disabled={!yardiPropertyReasons.length || yardiPropertyReasonsLoading}
      >
        {yardiPropertyReasons.map((option, index) => (
          <Select.Option
            key={option.id}
            value={option.id}
            data-testid={`option-${index}`}
          >
            {option.value}
          </Select.Option>
        ))}
      </Field>
    </YardiReasonFormGroup>
  );
};

YardiReasonFormExtension.propTypes = {
  property: PropTypes.shape({
    yardiPropertyId: keyPropType,
  }),
  yardiReasonType: PropTypes.oneOf(Object.values(YARDI_REASON_TYPES))
    .isRequired,
  reasonLabel: PropTypes.string.isRequired,
  formFieldName: PropTypes.string,
  marginTop: PropTypes.bool,
};

YardiReasonFormExtension.defaultProps = {
  property: undefined,
  formFieldName: "extraParams.reason",
  marginTop: false,
};

const YardiCancelDealReasonFormExtension = ({ property, marginTop }) => {
  return (
    <YardiReasonFormExtension
      property={property}
      reasonLabel="Please select the reason for cancelling this application"
      yardiReasonType={YARDI_REASON_TYPES.CANCEL}
      marginTop={marginTop}
    />
  );
};

const YardiDeclineDealReasonFormExtension = ({ property, marginTop }) => {
  return (
    <YardiReasonFormExtension
      property={property}
      reasonLabel="Please select the reason for declining this application"
      yardiReasonType={YARDI_REASON_TYPES.DENY}
      marginTop={marginTop}
    />
  );
};

const YardiRemoveMainApplicantReasonFormExtension = ({
  property,
  marginTop,
}) => {
  return (
    <YardiReasonFormExtension
      property={property}
      reasonLabel="Please select the reason for removing this applicant."
      yardiReasonType={YARDI_REASON_TYPES.CANCEL}
      marginTop={marginTop}
    />
  );
};

const YardiAssignDealToNonYardiUnitReasonFormExtension = ({
  property,
  marginTop,
}) => {
  return (
    <YardiReasonFormExtension
      property={property}
      reasonLabel="Please select the reason for changing the assigned unit."
      yardiReasonType={YARDI_REASON_TYPES.CANCEL}
      marginTop={marginTop}
    />
  );
};

YardiCancelDealReasonFormExtension.propTypes = EXTENSION_PROPS;
YardiCancelDealReasonFormExtension.defaultProps = DEFAULT_EXTENSION_PROPS;
YardiDeclineDealReasonFormExtension.propTypes = EXTENSION_PROPS;
YardiDeclineDealReasonFormExtension.defaultProps = DEFAULT_EXTENSION_PROPS;
YardiRemoveMainApplicantReasonFormExtension.propTypes = EXTENSION_PROPS;
YardiRemoveMainApplicantReasonFormExtension.defaultProps =
  DEFAULT_EXTENSION_PROPS;
YardiAssignDealToNonYardiUnitReasonFormExtension.propTypes = EXTENSION_PROPS;
YardiAssignDealToNonYardiUnitReasonFormExtension.defaultProps =
  DEFAULT_EXTENSION_PROPS;

export {
  YardiCancelDealReasonFormExtension,
  YardiDeclineDealReasonFormExtension,
  YardiRemoveMainApplicantReasonFormExtension,
  YardiAssignDealToNonYardiUnitReasonFormExtension,
};
