import PropTypes from "prop-types";

const linkedSourcesPropTypes = PropTypes.shape({
  isLinked: PropTypes.bool.isRequired,
  popoverText: PropTypes.string.isRequired,
  popoverIcon: PropTypes.node.isRequired,
  newSourcePopoverText: PropTypes.string.isRequired,
  deleteWarningText: PropTypes.string.isRequired,
  deleteDescription: PropTypes.string.isRequired,
});

export { linkedSourcesPropTypes };
