import { queryCache, useMutation, useQuery } from "react-query";

import {
  DeleteSurveyQuestionRequest,
  UpdateSurveyQuestionOrderingRequest,
} from "manager/interfaces/api/survey";
import api from "manager/lib/api";
import { HTTP_ERROR_CODES, NOTIFICATIONS } from "shared/config/constants";
import { SurveyModel, SurveyQuestionModel } from "shared/interfaces/api";
import { openNotification } from "shared/utils/ui";

import { APPLICATION_QUERY_KEYS } from "./applicationQueries";
import { DEAL_QUERY_KEYS, GET_DEAL_SETUP } from "./dealSetupQueries";

const GET_SURVEY = "getSurvey";

const QUERY_KEYS = Object.freeze({
  getSurvey: (id: number) => [GET_SURVEY, Number(id)],
});

export const useGetSurvey = (
  { dealSetupId, surveyType },
  config = { enabled: true }
) => {
  const { data: survey, isLoading: isSurveyLoading } = useQuery(
    QUERY_KEYS.getSurvey(dealSetupId),
    () => api.getSurvey(dealSetupId, { surveyType }),
    {
      enabled: Boolean(dealSetupId) && config.enabled,
      onError: (error: any) => {
        if (error.statusCode !== HTTP_ERROR_CODES.notFound) {
          openNotification("Failed to load survey.", NOTIFICATIONS.error);
        }
      },
    }
  );
  return {
    survey,
    isSurveyLoading,
  };
};

export const useCreateSurvey = () => {
  const [mutate, response] = useMutation(
    (payload: SurveyModel) => api.createSurvey(payload),
    {
      onSuccess: () => {
        queryCache.invalidateQueries(GET_DEAL_SETUP);
        queryCache.invalidateQueries(DEAL_QUERY_KEYS.getDefaultDealSetup);
      },
      onError: () => {
        openNotification("Failed to create survey.", NOTIFICATIONS.error);
      },
    }
  );

  return {
    createSurvey: mutate,
    response,
  };
};

export const useCreateSurveyQuestion = () => {
  const [mutate, response] = useMutation(
    (payload: SurveyQuestionModel) => api.createSurveyQuestion(payload),
    {
      onSuccess: () => {
        queryCache.invalidateQueries(GET_SURVEY);
        openNotification(
          `Survey question has been successfully created`,
          NOTIFICATIONS.info
        );
      },
      onError: () => {
        // TODO: Enable the notification here when we remove the onDialogSubmitError util.
        // openNotification(
        //   "Failed to create the additional question.",
        //   NOTIFICATIONS.error
        // );
      },
    }
  );

  return {
    createSurveyQuestion: mutate,
    response,
  };
};

export const useUpdateSurveyQuestion = () => {
  const [mutate, response] = useMutation(
    (payload: SurveyQuestionModel) => api.updateSurveyQuestion(payload),
    {
      onSuccess: () => {
        queryCache.invalidateQueries(GET_SURVEY);
        openNotification(
          `Survey question has been successfully updated`,
          NOTIFICATIONS.info
        );
      },
      onError: () => {
        // TODO: Enable the notification here when we remove the onDialogSubmitError util.
        // openNotification(
        //   "Failed to update the additional question.",
        //   NOTIFICATIONS.error
        // );
      },
    }
  );

  return {
    updateSurveyQuestion: mutate,
    response,
  };
};

export const useDeleteSurveyQuestion = () => {
  const [mutate, status] = useMutation(
    (payload: DeleteSurveyQuestionRequest) => api.deleteSurveyQuestion(payload),
    {
      onSuccess: () => {
        queryCache.invalidateQueries(GET_SURVEY);
        openNotification(
          `The survey question has been successfully deleted`,
          NOTIFICATIONS.info
        );
      },
      onError: () => {
        openNotification(
          "Failed to delete the survey question.",
          NOTIFICATIONS.error
        );
      },
    }
  );

  return {
    deleteSurveyQuestion: mutate,
    isDeleteLoading: status.isLoading,
  };
};

export const useUpdateSurveyQuestionOrdering = () => {
  const [mutate, status] = useMutation(
    (payload: UpdateSurveyQuestionOrderingRequest) =>
      api.updateSurveyQuestionOrdering(payload),
    {
      onSuccess: () => {
        queryCache.invalidateQueries(GET_SURVEY);
        openNotification(
          "The order of the survey questions has been successfully changed.",
          NOTIFICATIONS.info
        );
      },
      onError: () => {
        openNotification(
          "Failed to change order of the survey questions.",
          NOTIFICATIONS.error
        );
      },
    }
  );

  return {
    updateSurveyQuestionOrdering: mutate,
    isUpdateSurveyQuestionOrderingLoading: status.isLoading,
  };
};

export const useRequestSurvey = (applicationId: number) => {
  const [mutate, { isLoading }] = useMutation(
    (id: number) => api.requestSurvey(id),
    {
      onSuccess: () => {
        queryCache.invalidateQueries(
          APPLICATION_QUERY_KEYS.getFullApplication(applicationId)
        );
        openNotification(
          "Successfully sent request for survey",
          NOTIFICATIONS.info
        );
      },
      onError: () =>
        openNotification("Failed to send survey request", NOTIFICATIONS.error),
    }
  );
  return {
    requestSurvey: mutate,
    isRequestingSurvey: isLoading,
  };
};
