import styled from "styled-components";

import { COLORS } from "shared/config/constants";

import FlexContainer from "../FlexContainer";
import { MediumTitle, SmallText } from "../Typography";

export const ApplicationSummaryCardContainer = styled.div<{
  small?: boolean;
  pointer?: boolean;
}>`
  height: ${({ small }) => (small ? "52px" : "72px")};
  cursor: ${({ pointer = true }) => (pointer ? "pointer" : "default")};
  max-width: 280px;
  border-radius: 2px;
  background: white;
  position: relative;
  overflow: hidden;
  box-shadow: rgb(45 41 67 / 30%) 2px 2px 4px;
  &:hover {
    box-shadow: rgb(45 41 67 / 30%) 4px 4px 4px !important;
  }
`;

export const LeftIndicator = styled.div<{ color: string }>`
  height: 100%;
  position: absolute;
  left: 0;
  width: 8px;
  background: ${({ color }) => color};
`;

export const InnerContainer = styled(FlexContainer)<{ logo?: boolean }>`
  margin: 8px 12px 12px 24px;
  ${({ logo }) =>
    logo &&
    `
      margin-top: 12px;
    `}
`;

export const Title = styled(MediumTitle)`
  color: ${COLORS.grayTheme.textColor} !important;
  white-space: nowrap !important;
`;

export const Subtitle = styled(SmallText)`
  color: ${COLORS.grayTheme.textColor};
`;

export const ActionLogo = styled.div`
  cursor: pointer;
`;

export const TitleLogo = styled(SmallText)`
  color: ${COLORS.grayTheme.textColor};
  margin-left: 28px;
  margin-bottom: 5px;
`;
