import styled from "@emotion/styled";

import { Modal } from "shared/components/Modal";

import Well from "shared/components/Well";
import { COLORS } from "shared/config/constants";

export const LeaseWarning = styled(Well)`
  display: flex;
  align-items: center;
`;

export const WarningIcon = styled.div`
  margin-right: 15px;
`;

export const StyledModal = styled(Modal)`
  & .ant-radio-wrapper span,
  .assign-unit-list-item div {
    color: ${COLORS.primary};
  }

  & .ant-radio-wrapper span {
    white-space: normal;
  }
`;

export const ListingForm = styled(Well)`
  padding: 30px 20px;
`;
