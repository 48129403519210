import React from "react";

import { Formik } from "formik";

import { DealToMove } from "manager/lib/dealsApi";
import { Button, BUTTON_SIZES } from "shared/components/Button";
import FlexContainer from "shared/components/FlexContainer";
import { CollapsibleRadioList, SearchableList } from "shared/components/Form";
import Icon from "shared/components/Icon";
import { UnderlineLink } from "shared/components/Links";
import Spacer from "shared/components/Spacer";
import { LargeTitle, RegularText } from "shared/components/Typography";
import { VeroModal } from "shared/components/vero-modal";

import { isDeclinedDeal, isCanceledDeal } from "../../header/utils";
import {
  RemovePrimaryYardiExtensionProps,
  YardiReasonFormExtensionFields,
} from "../hooks";
import { ApplicantsCheckList, Subtitle } from "../styled";

import { ApplicantSummary } from "../types";

import { useMoveApplicantModal } from "./context";
import { DealDescription } from "./deal-description";
import { useHook } from "./hooks";

export interface MoveApplicantModalProps {
  dealId: string | number;
  application: ApplicantSummary;
  yardi: RemovePrimaryYardiExtensionProps;
}

export type MoveApplicantModalForm = YardiReasonFormExtensionFields & {};

export const MoveApplicantModal = (props: MoveApplicantModalProps) => {
  const state = useMoveApplicantModal();
  const { application } = props;
  const {
    deals,
    loading,
    extension,
    setSearchingValue,
    setDestinationDeal,
    move,
  } = useHook({ ...props, enabled: state.visible });
  const { cancel$, submit$, submitting } = state;

  return (
    <Formik
      initialValues={{} as MoveApplicantModalForm}
      onSubmit={(values: YardiReasonFormExtensionFields) =>
        submit$.next(move(values))
      }
      validateOnMount
    >
      {({ submitForm, isValid }) => (
        <VeroModal {...state} style={{ maxWidth: 946 }} width="auto">
          <LargeTitle>Move applicant to another application</LargeTitle>
          <Subtitle>
            <RegularText strong>Applicant:&nbsp;</RegularText>
            <ApplicantsCheckList inline items={[application]} />
          </Subtitle>

          <Spacer size={Spacer.SIZES.default} />

          {extension}

          <p>Select application you want to move this applicant to</p>
          <SearchableList
            loading={loading}
            search={{
              fieldName: "search",
              label: "Search applicants, property or address…",
              action: (value) => {
                setSearchingValue(value);
              },
              width: "370px",
              debounce: true,
              // @ts-ignore
              disabled: loading,
            }}
            List={CollapsibleRadioList}
            listContainerProps={{
              onChange: ({ target: { value } }) => {
                setDestinationDeal(deals.find((item) => item.id === value));
              },
            }}
            listProps={{
              col1Width: "auto",
              items: loading
                ? []
                : (deals ?? []).map((deal: DealToMove) => ({
                    key: deal.id,
                    label: <DealDescription {...deal} />,
                    disabled: !deal?.canApplicationBeMoved,
                    radioTooltipProps: !deal?.canApplicationBeMoved && {
                      title:
                        isDeclinedDeal(deal?.dealStatus) ||
                        isCanceledDeal(deal?.dealStatus)
                          ? "You can not move an applicant to a deal that has already been declined or canceled."
                          : "This application cannot be selected. Screening settings differ from the current application.",
                      theme: "light",
                      placement: "topLeft",
                    },
                  })),
            }}
            placeholder={{
              label: "Applications",
              Icon: Icon.NoDealsIcon,
            }}
            hasInputValue={false}
          />

          <Spacer size={Spacer.SIZES.md} />
          <FlexContainer
            justifyContent="flex-end"
            gap="24px"
            alignItems="center"
          >
            <UnderlineLink onClick={() => cancel$.next()}>Cancel</UnderlineLink>
            <Button
              type="primary"
              onClick={submitForm}
              size={BUTTON_SIZES.lg}
              loading={submitting}
              disabled={!isValid}
            >
              Move To Application
            </Button>
          </FlexContainer>
        </VeroModal>
      )}
    </Formik>
  );
};
