import React from "react";

import useModal from "shared/hooks/useModal";

import YardiImportPropertyConfirmationModal from "./YardiImportPropertyConfirmationModal";

const YardiImportPropertyConfirmationModalContainer = () => {
  const { currentModalContext } = useModal();

  return <YardiImportPropertyConfirmationModal {...currentModalContext} />;
};

export default YardiImportPropertyConfirmationModalContainer;
