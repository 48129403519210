import React from "react";

import ROUTES from "admin/config/routes";
import FileSelect from "shared/components/FileSelect";
import { ActionLink } from "shared/components/Links";
import TablePage from "shared/templates/TablePage";

import DocumentsTable from "./DocumentsTable";
import DraftsTable from "./DraftsTable";
import { DocumentsProps } from "./interfaces";

const NewDocument = (props) => (
  <ActionLink plus {...props}>
    New Document
  </ActionLink>
);

const Documents = ({
  searchParams,
  isLoading,
  documents,
  handleSearchParams,
  drafts,
  isLoadingDrafts,
  onFileSelect,
}: DocumentsProps) => (
  <TablePage
    title="Documents"
    filterTabs={[
      {
        route: ROUTES.documents,
        label: "Saved",
      },
      {
        route: ROUTES.draftDocuments,
        label: "Drafts",
      },
    ]}
    tables={[
      {
        filterBar: {
          search: {
            action: (value) =>
              handleSearchParams("docNameOrCompanyNameOrTagName", value),
            placeholder: "Search by document name, company name or tag",
            width: 430,
            value: searchParams.docNameOrCompanyNameOrTagName,
            debounce: true,
            disabled: isLoading,
          },
        },
        node: (
          <DocumentsTable
            searchParams={searchParams}
            isLoading={isLoading}
            documents={documents}
          />
        ),
      },
      {
        filterBar: {
          search: {
            action: (value) => handleSearchParams("draftName", value),
            placeholder: "Search by document name",
            width: 430,
            value: searchParams.draftName,
            debounce: true,
            disabled: isLoading,
          },
        },
        node: (
          <DraftsTable
            searchParams={searchParams}
            isLoading={isLoadingDrafts}
            documents={drafts}
          />
        ),
      },
    ]}
    actions={[
      {
        key: "NEW_DOCUMENT",
        node: (
          <FileSelect
            onFileSelect={onFileSelect}
            acceptFormats="application/pdf"
            Node={NewDocument}
          />
        ),
      },
    ]}
  />
);

export default Documents;
