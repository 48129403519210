import React from "react";

import { Checkbox, CheckboxProps } from "../Checkbox";
import { SwitchTitle } from "../SwitchTitle";

import { CheckboxRow, Description, StyledActionLink } from "./styled";

type SwitchWithCheckboxListProps = CheckboxProps & {
  onChange: (id: string, checked: boolean) => void;
  onChangeTitle: () => void;
  onManageList: () => void;
  value?: boolean;
  title: string;
  description?: string;
  items: {
    label: string;
    description?: string;
    id: string;
    checked?: boolean;
    disabled?: boolean;
  }[];
  disabled?: boolean;
};

export const SwitchWithCheckboxList: React.FC<SwitchWithCheckboxListProps> = ({
  title,
  description,
  items,
  value,
  onChange,
  onChangeTitle,
  onManageList,
  disabled,
  ...props
}) => (
  <>
    <SwitchTitle
      title={title}
      onChange={onChangeTitle}
      description={description}
      value={value}
      data-testid="switch"
      hasItems={items.length > 0}
      disabled={disabled}
    />
    {value && (
      <>
        {items.map((item, index) => (
          <CheckboxRow key={item.label} isLastItem={items.length === index + 1}>
            <Checkbox
              id={`${item.id}`}
              label={item.label}
              checked={item.checked}
              onChange={() => onChange(item.id, !item.checked)}
              data-testid={item.id}
              disabled={disabled || item.disabled}
              {...props}
            />
            {item.description && (
              <Description>({item.description})</Description>
            )}
          </CheckboxRow>
        ))}
        <StyledActionLink
          onClick={() => onManageList()}
          data-testid="manage-list"
          disabled={disabled}
        >
          Manage List
        </StyledActionLink>
      </>
    )}
  </>
);
