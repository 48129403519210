import React, { useEffect, useRef } from "react";

import {
  useGetEmployeesWithAssignmentsOnProperty,
  useGetYardiAgents,
  useYardiResolveLinkAgents,
} from "manager/hooks/api";
import Icon from "shared/components/Icon";
import useModal from "shared/hooks/useModal";
import { resetFormAndValidate } from "shared/utils/forms";

import YardiGenericListDropdownsResolverModal from "./YardiGenericListDropdownsResolverModal";

const YardiLinkAgentsResolverModalContainer = () => {
  const { currentModalContext } = useModal();
  const { alert, yardiProperty, provider } = currentModalContext;
  const veroPropertyId = yardiProperty?.property?.id;
  const { yardiAgents, isLoading: isYardiAgentsLoading } = useGetYardiAgents(
    provider,
    yardiProperty.id
  );

  const filters = {
    onlyYardiUnmapped: true,
    excludeUnassigned: true,
  };
  const { employees, isLoading: isEmployeesLoading } =
    useGetEmployeesWithAssignmentsOnProperty(provider, veroPropertyId, filters);
  const { resolveAlertLinkAgents } = useYardiResolveLinkAgents();
  const formikRef = useRef();

  const submit = async (values) => {
    const payload = {
      agents: (values.objects || []).map(({ id, linkedId }) => ({
        employee_id: id,
        yardi_agent_id: linkedId,
      })),
    };
    await resolveAlertLinkAgents({
      provider,
      alertId: alert?.id,
      body: payload,
    });
  };

  useEffect(() => {
    if (formikRef.current) {
      const employeesFormList = (employees || []).map(
        ({ id, user: { firstName, lastName } }) => ({
          id,
          inputDescription: `${firstName} ${lastName}`,
          linkedId: null,
        })
      );

      const values = { objects: employeesFormList };
      resetFormAndValidate(formikRef, { values });
    }
  }, [formikRef.current, employees]);

  const isLoading = isEmployeesLoading || isYardiAgentsLoading;

  return (
    <YardiGenericListDropdownsResolverModal
      title="Link VERO agents with External Provider"
      subtitle={
        <>
          Property: <strong>{yardiProperty.name}</strong>
        </>
      }
      message="Please select new agents to link them with VERO agents"
      selectPlaceholder="Select agent"
      items={yardiAgents}
      labelProp="fullName"
      submit={submit}
      formikRef={formikRef}
      isLoading={isLoading}
      PlaceholderIcon={Icon.NoEmployeesIcon}
      placeholderText="Agents"
      validationMessage="Agent is required"
    />
  );
};

export default YardiLinkAgentsResolverModalContainer;
