import React from "react";

import styled from "@emotion/styled";
import { Input } from "antd";

import { COLORS, TRANSITIONS } from "shared/config/constants";

export { Label } from "../styled";

const { TextArea: TextAreaBase } = Input;

export const commonStyles = (props: { valid?: boolean }) => ({
  "&, textarea": {
    padding: "12px 16px",
    borderRadius: "2px",
    borderColor: COLORS.input.default.border,
    lineHeight: 1,
    color: COLORS.input.default.text,
    transition: TRANSITIONS.default,
    "&:disabled": {
      backgroundColor: COLORS.input.disabled.background,
      color: `${COLORS.input.disabled.text} !important`,
    },
    "&:hover": {
      borderColor: COLORS.input.hover.border,
    },
    ...(props.valid && {
      "&:focus": {
        borderColor: `${COLORS.input.focus.border} !important`,
        text: COLORS.input.focus.text,
        boxShadow: "none",
        outline: "none",
      },
    }),
    ...(!props.valid && {
      borderColor: `${COLORS.input.invalid.border} !important`,
      "&:focus": {
        borderColor: `${COLORS.input.invalid.border} !important`,
        text: COLORS.input.focus.text,
        boxShadow: "none",
        outline: "none",
      },
    }),
  },
});

// eslint-disable-next-line vero/styled-tagged-template
export const BaseTextArea = styled(
  // eslint-disable-next-line react/prop-types,@typescript-eslint/no-unused-vars
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  React.forwardRef(({ valid, ...props }, ref) => (
    // @ts-ignore
    <TextAreaBase {...props} ref={ref} />
  ))
)(commonStyles);

export const TextArea = styled(BaseTextArea)`
  .ant-input-clear-icon {
    margin-right: 5px;
  }
`;

export const CharactersCount = styled.div`
  position: absolute;
  right: 0;
  color: ${COLORS.midGray};
`;
