import { css } from "@emotion/core";
import styled from "@emotion/styled";

import { FlexContainerProps } from "./interfaces";

const BaseFlexContainer = styled.div<FlexContainerProps>`
  display: flex;
  align-items: ${(props) => props.alignItems};
  justify-content: ${(props) => props.justifyContent};
  flex-direction: ${(props) => props.flexDirection};
  flex-grow: ${(props) => props.flexGrow};
  flex-wrap: ${(props) => props.flexWrap};
  gap: ${(props) => props.gap};
  ${(props) =>
    props.fullHeight &&
    css`
      height: 100%;
    `};
  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `};

  > * {
    margin: ${(props) => props.nodesMargin};
  }
`;

export { BaseFlexContainer };
