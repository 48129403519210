import { normalize } from "normalizr";
import { handleActions } from "redux-actions";

import * as actions from "../actions";
import * as schemas from "../schema";

const initialState = {};

const employeeEntityReducer = handleActions(
  {
    [actions.receiveEmployees]: (state, action) => {
      const { employees } = action.payload;

      return {
        ...state,
        ...normalize(employees, schemas.employeeList).entities.employee,
      };
    },
    [actions.receiveEmployee]: (state, action) => {
      const { employee } = action.payload;

      return {
        ...state,
        ...normalize([employee], schemas.employeeList).entities.employee,
      };
    },
    [actions.updateEmployee]: (state, action) => {
      const { id, data } = action.payload;

      return {
        ...state,
        [id]: {
          ...state[id],
          ...data,
        },
      };
    },
  },
  initialState
);

export default employeeEntityReducer;
