
import dayjs from "dayjs";

import Icon from "shared/components/Icon";
import Table from "shared/components/Table";
import { DATE_TIME_FORMAT } from "shared/config/constants";
import { printDollarsFromCents } from "shared/utils/dollar-print";

const LedgerTable = ({ records, loading = false }) => (
  <Table
    columns={[
      {
        title: "Date",
        dataIndex: "timestamp",
        key: "timestamp",
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
      },
      {
        title: "Payment",
        dataIndex: "payment",
        key: "payment",
      },
      {
        title: "Fee",
        dataIndex: "fee",
        key: "fee",
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
      },
    ]}
    rows={(records || []).map((record) => ({
      key: record.id,
      values: [
        dayjs(record.timestamp).format(DATE_TIME_FORMAT),
        record.type,
        record.payment,
        record.fee,
        printDollarsFromCents(record.amount),
      ],
    }))}
    columnBreakpoints={{
      xs: {
        column1: "type",
        column2: "amount",
      },
      sm: undefined,
    }}
    placeholder={{
      Icon: Icon.NoDataIcon,
      label: "Ledger",
    }}
    pagination={false}
    noClickableRows
    alt
    loading={loading}
  />
);

export default LedgerTable;
