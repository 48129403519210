export enum Card {
  Income = "Income",
  Balances = "Balances",
  Credit = "Credit",
  Criminal = "Criminal",
  Eviction = "Eviction",
  Employment = "Employment",
  Identity = "Identity",
  VoR = "VoR",
}

export const FIRST_COLUMN_CARDS = [Card.Income, Card.Balances];
export const SECOND_COLUMN_CARDS = [Card.Credit, Card.Criminal, Card.Eviction];
export const THIRD_COLUMN_CARDS = [Card.Employment, Card.Identity, Card.VoR];
