import { Col, Row } from 'antd'

import {
  CreditScoreSummary,
  CriminalRecordsType,
  EvictionsType,
} from 'shared/components/CreditAndBackgroundCheck/interfaces'
import {
  CreditScoreChart,
  CreditScoreHeader,
  CreditScoreRating,
  CreditScoreTitle,
  Statistic,
} from 'shared/components/CreditAndBackgroundCheck/styled'
import {
  getFailedReportMessage,
  reportUnavailableTile,
  isVantageExclusion,
  reportExclusionTile,
} from 'shared/components/CreditAndBackgroundCheck/utils'
import Icon from 'shared/components/Icon'
import Spacer from 'shared/components/Spacer'
import Tile from 'shared/components/Tile'
import Tooltip from 'shared/components/Tooltip'

import { printDollarsFromCents } from 'shared/utils/dollar-print'
import {
  formatCreditScore,
  formatDate,
  getValueOrNA,
  yesNo,
} from 'shared/utils/ui'

type CreditScoreProps = {
  creditScoreSummary?: CreditScoreSummary
  criminalRecords?: CriminalRecordsType
  hideCriminalRecords: boolean
  isEvictions?: boolean
  evictions?: EvictionsType
  noSSNreasonToStay?: string
}

const CreditScore = ({
  creditScoreSummary,
  criminalRecords,
  hideCriminalRecords,
  isEvictions,
  evictions,
  noSSNreasonToStay,
}: CreditScoreProps) => {
  const failedCreditReportMessage = getFailedReportMessage(creditScoreSummary)
  const failedEvictionReportMessage = getFailedReportMessage(evictions)
  const failedStateCriminalReportMessage =
    getFailedReportMessage(criminalRecords)

  const evictionRecordState = `State ${evictions?.lastRecordState}, `
  const criminalRecordState = `State ${criminalRecords?.lastRecordState}, `

  return (
    <Row gutter={14} type="flex">
      {!failedCreditReportMessage && (
        <Col xs={24} xl={8}>
          <Tile equalHeight>
            <Tile.Inner>
              <CreditScoreHeader>
                <CreditScoreTitle>
                  Credit Score
                  {noSSNreasonToStay && (
                    <div className="no-ssn-warning-icon">
                      <Tooltip title={noSSNreasonToStay}>
                        <Icon.OrangeExclamationSmall />
                      </Tooltip>
                    </div>
                  )}
                </CreditScoreTitle>
                <CreditScoreRating data-testid="credit-score-rating">
                  {formatCreditScore(creditScoreSummary?.score)}
                </CreditScoreRating>
                <CreditScoreChart score={creditScoreSummary?.score} />
              </CreditScoreHeader>
              <Spacer size={Spacer.SIZES.lg} hideLtXl />
              <Spacer hideGteXl />
              <Row gutter={[5, 0]}>
                <Col md={4} xl={8}>
                  <Tile.Label>Credit Accounts</Tile.Label>
                  <Statistic bold data-testid="credit-accounts-count">
                    {creditScoreSummary?.accounts.length || 0}
                  </Statistic>
                </Col>
                <Col md={4} xl={15}>
                  <Spacer hideGteMd />
                  <Tile.Label>Total Credit Balance</Tile.Label>
                  <Statistic data-testid="credit-total-balance">
                    {printDollarsFromCents(creditScoreSummary?.creditBalance)}
                  </Statistic>
                </Col>
                <Col md={4} xl={8}>
                  <Spacer hideLtXl />
                  <Spacer hideGteMd />
                  <Tile.Label>Credit Limit</Tile.Label>
                  <Statistic data-testid="credit-limit">
                    {printDollarsFromCents(creditScoreSummary?.creditLimit)}
                  </Statistic>
                </Col>
                <Col md={4} xl={8}>
                  <Spacer hideLtXl />
                  <Spacer hideGteMd />
                  <Tile.Label>Percentage Available</Tile.Label>
                  <Statistic data-testid="credit-percentage-available">
                    {`${creditScoreSummary?.percentageAvailable || 0}%`}
                  </Statistic>
                </Col>
                <Col md={4} xl={8}>
                  <Spacer hideLtXl />
                  <Spacer hideGteMd />
                  <Tile.Label>Total Collections Amount</Tile.Label>
                  <Statistic>
                    {printDollarsFromCents(
                      creditScoreSummary?.totalCollectionsAmount,
                    )}
                  </Statistic>
                </Col>
              </Row>
            </Tile.Inner>
          </Tile>
        </Col>
      )}

      {failedCreditReportMessage && (
        isVantageExclusion(creditScoreSummary) ? (
          <>
            {reportExclusionTile(failedCreditReportMessage)}
          </>
        ) : (
        reportUnavailableTile('Credit Score', failedCreditReportMessage)
        )
      )}

      {!hideCriminalRecords && !failedStateCriminalReportMessage && (
        <Col xs={24} md={12} xl={8}>
          <Tile equalHeight>
            <Tile.Inner>
              <h2>State Criminal Records</h2>
              <Spacer />
              {criminalRecords?.lastRecord && (
                <>
                  <Tile.Label>Last Record</Tile.Label>
                  <strong data-testid="last-record-state">
                    {criminalRecordState}
                  </strong>
                  <span data-testid="last-record-date">
                    {formatDate(criminalRecords?.lastRecord?.date)}
                  </span>
                  <Tile.Label data-testid="last-record-type">
                    {criminalRecords?.lastRecord?.type}
                  </Tile.Label>
                  <Tile.Label noMb data-testid="last-record-outcome">
                    {criminalRecords?.lastRecord?.outcome}
                  </Tile.Label>
                </>
              )}
              <Spacer size={Spacer.SIZES.md} />
              <Row>
                <Col xs={8}>
                  <Tile.Label>States</Tile.Label>
                  <Statistic bold data-testid="criminal-record-state-count">
                    {criminalRecords?.states}
                  </Statistic>
                </Col>
                <Col xs={8}>
                  <Tile.Label>Records</Tile.Label>
                  <Statistic data-testid="criminal-record-count">
                    {criminalRecords?.totalRecords}
                  </Statistic>
                </Col>
                <Col xs={8}>
                  <Tile.Label>Sexual Crime</Tile.Label>
                  <Statistic data-testid="sexual-crime">
                    {yesNo(criminalRecords?.sexualCrime)}
                  </Statistic>
                </Col>
                <Col xs={8}>
                  <Spacer />
                  <Tile.Label>Biggest Severity</Tile.Label>
                  <Statistic data-testid="criminal-record-biggest-severity">
                    {getValueOrNA(criminalRecords?.biggestSeverity)}
                  </Statistic>
                </Col>
                <Col xs={8}>
                  <Spacer />
                  <Tile.Label>Felony</Tile.Label>
                  <Statistic data-testid="criminal-record-felony">
                    {yesNo(criminalRecords?.felony)}
                  </Statistic>
                </Col>
              </Row>
            </Tile.Inner>
          </Tile>
        </Col>
      )}

      {!hideCriminalRecords &&
        failedStateCriminalReportMessage &&
        reportUnavailableTile(
          'State Criminal Records',
          failedStateCriminalReportMessage,
        )}

      {!failedEvictionReportMessage && (
        <Col xs={24} md={12} xl={8}>
          <Tile equalHeight>
            <Tile.Inner>
              <h2>Eviction Related Proceedings</h2>
              <Spacer />
              {isEvictions ? (
                <>
                  {evictions?.lastRecord && (
                    <>
                      <Tile.Label>Last Record</Tile.Label>
                      <strong data-testid="last-housing-court-record-state">
                        {evictionRecordState}
                      </strong>
                      <span data-testid="last-housing-court-record-date">
                        {formatDate(evictions?.lastRecord?.date)}
                      </span>
                      <Tile.Label
                        noMb
                        data-testid="last-housing-court-record-case-type"
                      >
                        {evictions?.lastRecord?.caseType}
                      </Tile.Label>
                    </>
                  )}
                  <Spacer size={Spacer.SIZES.md} />
                  <Row>
                    <Col xs={8}>
                      <Tile.Label>States</Tile.Label>
                      <Statistic
                        bold
                        data-testid="housing-court-record-state-count"
                      >
                        {evictions?.states}
                      </Statistic>
                    </Col>
                  </Row>
                </>
              ) : (
                <Tile.Label>
                  Eviction related proceedings are not considered in this state
                  or this county
                </Tile.Label>
              )}
            </Tile.Inner>
          </Tile>
        </Col>
      )}

      {failedEvictionReportMessage &&
        reportUnavailableTile(
          'Eviction Related Proceedings',
          failedEvictionReportMessage,
        )}
    </Row>
  )
}

export default CreditScore
