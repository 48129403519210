import React, { ReactElement } from "react";

import { useHistory } from "react-router-dom";

import {
  useCloseListingAndMarkUnitAsRented,
  useCloseUnitListing,
} from "manager/hooks/api";

import useModal from "shared/hooks/useModal";
import { onDialogSubmitError } from "shared/utils/api";

import CloseListingModal from "./CloseListingModal";

import { ICloseListingProps, CLOSE_LISTING_TYPE } from ".";

const CloseListingModalContainer = ({
  unit,
}: ICloseListingProps): ReactElement => {
  const { closeUnitListing } = useCloseUnitListing();
  const { closeListingAndMarkUnitAsRented } =
    useCloseListingAndMarkUnitAsRented();
  const { closeActiveModalDialog } = useModal();
  const history = useHistory();

  const submit = (values, formikApi) => {
    if (values.unitListingCloseType === CLOSE_LISTING_TYPE.CLOSE_LISTING) {
      return closeUnitListing(
        /* @ts-ignore */
        {
          id: unit?.unitListingCurrent?.id,
          unitName: unit?.name,
        },
        {
          onSuccess: closeActiveModalDialog,
          onError: onDialogSubmitError(formikApi, history),
        }
      );
    }
    return closeListingAndMarkUnitAsRented(
      /* @ts-ignore */
      {
        id: unit?.unitListingCurrent?.id,
        unitName: unit?.name,
        rentedFrom: values.rentedFrom,
        rentedUntil: values.rentedUntil,
        rent: values.rent,
        securityDeposit: values.securityDeposit,
      },
      {
        onSuccess: closeActiveModalDialog,
        onError: onDialogSubmitError(formikApi, history),
      }
    );
  };

  return <CloseListingModal unit={unit} onSubmit={submit} />;
};

export default CloseListingModalContainer;
