import styled from "@emotion/styled";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import Icon from "shared/components/Icon";

import {
  COLORS,
  MEDIA_QUERIES,
  DIMENSIONS,
  colors,
} from "shared/config/constants";

const Container = styled("div")<{ example?: boolean }>((props) => ({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  zIndex: 200,
  background: COLORS.header.background,
  borderBottom: `1px solid ${COLORS.borders}`,
  height: DIMENSIONS.header.gteMd.height,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  ul: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginRight: "24px",
  },
  ...(props.example && {
    position: "relative",
    width: "100%",
  }),
}));

const IconWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  height: "100%",
  verticalAlign: "-webkit-baseline-middle",
});

const StyledMenu = styled(Menu)({
  height: "100%",
  border: 0,
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MenuItem = styled(({ large, largeRight, last, ...props }) => (
  <Menu.Item {...props} />
))((props) => ({
  padding: "0 8px",
  display: "flex !important",
  alignItems: "center",
  "&.ant-menu-item-active, &.ant-menu-item-selected": {
    borderColor: "transparent !important",
  },
  ...(props.last && {
    paddingRight: 0,
  }),
  ...(props.large && {
    padding: "0 16px",
  }),
  ...(props.largeRight && {
    paddingRight: "16px",
  }),
}));

const LogoContainer = styled(Link)({
  display: "flex",
  alignItems: "center",
  paddingLeft: "24px",
  height: "100%",
  marginRight: "24px",
  borderRight: `1px solid ${COLORS.borders}`,
  width: "150px",
  [MEDIA_QUERIES.w.ltXl]: {
    borderColor: "transparent",
  },
});

const HeaderLeft = styled("div")({
  height: "100%",
  display: "flex",
});

const BackLinkContainer = styled("span")({
  display: "flex",
  [MEDIA_QUERIES.w.ltXl]: {
    display: "none",
  },
});

const HelpIcon = styled(Icon.Help)`
  width: 40px;
  height: 40px;
  padding: 5px;
  &:hover {
    background: ${colors.neutral[200]};
    width: 40px;
    height: 40px;
    border-radius: 20px;
  }
`;

export {
  Container,
  IconWrapper,
  StyledMenu,
  MenuItem,
  LogoContainer,
  HeaderLeft,
  BackLinkContainer,
  HelpIcon,
};
