import styled from "@emotion/styled";

import Well from "shared/components/Well/Well";
import { COLORS } from "shared/config/constants";

const LumenFilterRowContent = styled(Well)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "stretch",
  padding: "18px 16px",
  marginBottom: "24px",
  marginTop: "16px",
  border: "none",
  ".filter-row-section": {
    display: "flex",
    flexDirection: "column",
    ".filter-row-section-title": {
      marginBottom: "6px",
    },
  },
  ".filter-row-section + .filter-row-section": {
    paddingLeft: "20px",
    marginLeft: "20px",
    borderLeft: `1px solid ${COLORS.borders}`,
  },
  ".action-icon": {
    alignSelf: "center",
    cursor: "pointer",

    "& + .action-icon": {
      marginLeft: "16px",
    },
  },
});

export { LumenFilterRowContent };
