import React from "react";

import * as Yup from "yup";

import { YardiCancelDealReasonFormExtension } from "manager/pages/Marketplace/integrations/yardi/shared";

import { cancelDeal } from "shared/lib/api/dealApi";

import { useUpload } from "../hooks";
import { DealSummaryWithProperty } from "../types";

export type Form = {
  sendEmail?: string;
  emailBody?: string;
  extraParams?: Record<string, unknown>;
};

export const useHook = (deal: DealSummaryWithProperty) => {
  const { uploadFile, attachments } = useUpload();

  const schema = Yup.object().shape({
    sendEmail: Yup.bool().required("Choose to send email or not"),
    emailBody: Yup.string().when("sendEmail", {
      is: true,
      then: Yup.string().required("Email body is required"),
    }),
  });

  const unit = deal?.unit;
  const property = deal?.property;
  const modalExtension = (unit || property)?.yardiConnected ? (
    <YardiCancelDealReasonFormExtension property={property} />
  ) : null;

  const dealId = deal?.id;
  const onSubmit = async (values: Form) => {
    const { sendEmail, emailBody, extraParams } = values;
    await cancelDeal({
      id: dealId,
      sendEmail,
      emailBody,
      attachments,
      extraParams,
    });
  };
  const title = "Cancel Application";
  const defaultSubmitLabel = "Cancel Application";
  const emailLabel = "Would you like to send a cancellation email?";
  return {
    onSubmit,
    uploadFile,
    modalExtension,
    title,
    defaultSubmitLabel,
    emailLabel,
    schema,
  };
};
