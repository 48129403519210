import React, { useMemo } from "react";

import { Field, Formik } from "formik";
import * as Yup from "yup";

import { Input, TextArea } from "shared/components/Form";
import { Modal } from "shared/components/Modal";
import Spacer from "shared/components/Spacer";
import { RegularText, SmallText } from "shared/components/Typography";
import useModal from "shared/hooks/useModal";
import { sanitizeStringSearchValue } from "shared/utils/string";

import { MarkFormulaAsTemplateModalContent } from "./styled";

const INITIAL_VALUES = {
  name: "",
  description: "",
};

const MarkFormulaAsTemplateModal = () => {
  const {
    selfApi,
    currentModalContext: { usedTemplateNames, formula },
  } = useModal();

  const ValidationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string()
          .trim()
          .required("Name is required")
          .test(
            "uniqueFormulaName",
            "The name of the formula template must be unique",
            (value) => {
              const sanitizedValue = sanitizeStringSearchValue(value);
              return !usedTemplateNames.includes(sanitizedValue);
            }
          ),
        description: Yup.string().trim().required("Description is required"),
      }),
    [usedTemplateNames]
  );

  const submit = (values) => {
    selfApi.closeDialogWithValue(values);
  };

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      onSubmit={submit}
      validateOnMount
      validationSchema={ValidationSchema}
    >
      {({
        errors,
        touched,
        isValid,
        isSubmitting,
        setFieldValue,
        submitForm,
      }) => (
        <Modal
          title="Mark formula as template"
          submitButtonLabel="CONFIRM"
          submit={submitForm}
          submitting={isSubmitting}
          submitButtonDisabled={!isValid}
          width="516px"
        >
          <Modal.Body noPaddingTop>
            <MarkFormulaAsTemplateModalContent>
              <RegularText>
                Please enter the name and description for the new formula
                template.
              </RegularText>
              <Spacer size={Spacer.SIZES.sm} />
              <SmallText>
                The template will be based on: <strong>{formula.name}</strong>
              </SmallText>
              <Spacer size={Spacer.SIZES.sm} />
              <Field
                name="name"
                id="name"
                label="Name"
                as={Input}
                formikContext={{
                  trimOnBlur: true,
                  setFieldValue,
                }}
                error={touched.name && errors.name}
              />
              <Spacer size={Spacer.SIZES.md} />
              <Field
                name="description"
                id="description"
                label="Description"
                as={TextArea}
                error={touched.description && errors.description}
                rows={5}
                formikContext={{
                  trimOnBlur: true,
                  setFieldValue,
                }}
              />
            </MarkFormulaAsTemplateModalContent>
          </Modal.Body>
        </Modal>
      )}
    </Formik>
  );
};

export default MarkFormulaAsTemplateModal;
