/* eslint-disable react/no-array-index-key */
import React from "react";

import { Row, Col } from "antd";

import DeleteTitle from "shared/components/DeleteTitle";
import { Input, DatePicker, VeroFormField } from "shared/components/Form";

import { isGreaterThan18 } from "./config";
import { AddOccupantsFormProps } from "./interfaces";

export const AddOccupantsForm = ({
  occupants,
  deleteOccupant,
}: AddOccupantsFormProps) => {
  const onDelete = (index: number) => () => deleteOccupant(index);

  return (
    <div data-testid="add-occupants-form">
      {occupants.map((occupant, index) => (
        <div key={index} data-testid={`occupant-${index}`}>
          {index > 0 && <br />}
          {occupants.length > 1 && (
            <DeleteTitle action={onDelete(index)}>
              {`Occupant #${index + 1}`}
            </DeleteTitle>
          )}
          <Row gutter={[20, 25]}>
            <Col md={{ span: 12 }}>
              <VeroFormField
                as={Input}
                id={`occupants.${index}.firstName`}
                name={`occupants.${index}.firstName`}
                label="First Name"
                required
                type="text"
              />
            </Col>
            <Col md={{ span: 12 }}>
              <VeroFormField
                as={Input}
                id={`occupants.${index}.lastName`}
                name={`occupants.${index}.lastName`}
                label="Last Name"
                required
                type="text"
              />
            </Col>
            <Col md={{ span: 12 }}>
              <VeroFormField
                as={DatePicker}
                id={`occupants.${index}.birthdate`}
                name={`occupants.${index}.birthdate`}
                label="Date of Birth"
                required
                disableFuture
              />
            </Col>
            <Col md={{ span: 12 }}>
              <VeroFormField
                as={Input}
                id={`occupants.${index}.relationship`}
                name={`occupants.${index}.relationship`}
                label="Relationship"
                required
                type="text"
              />
            </Col>
            {!!isGreaterThan18(occupants?.[index]?.birthdate) && (
              <Col md={{ span: 24 }}>
                <VeroFormField
                  as={Input}
                  id={`occupants.${index}.email`}
                  name={`occupants.${index}.email`}
                  data-testid={`occupants-${index}-email`}
                  label="Email"
                  required
                  type="email"
                />
              </Col>
            )}
          </Row>
        </div>
      ))}
    </div>
  );
};
