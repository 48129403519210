import React from "react";

import { notification } from "antd";

import Icon from "shared/components/Icon";

const stylesConfig = () => {
  notification.config({
    top: 40,
    duration: 5,
    closeIcon: <Icon.NotificationCloseIcon />,
  });
};

export default stylesConfig;
