import styled from "@emotion/styled";
import { Radio } from "antd";

import { COLORS } from "shared/config/constants";

const PopoverContainer = styled("div")({
  padding: "24px",
});

const Title = styled("span")({
  color: COLORS.secondary,
  fontSize: "12px",
  textTransform: "uppercase",
});

const AgentsContainer = styled("div")({
  marginTop: 18,
  position: "relative",
  minHeight: 120,
  width: 260,
  maxHeight: 300,
  overflowY: "auto",
});

const Option = styled(Radio)({
  display: "block",
  height: "50px",
  color: COLORS.primary,
  fontSize: 15,
});

const Description = styled("p")({
  paddingLeft: 30,
  fontSize: 12,
  color: COLORS.secondary,
});

const Faint = styled("span")({
  color: COLORS.secondary,
});

const Loader = styled("div")({
  position: "absolute",
  zIndex: 100,
  top: "25%",
  left: "42%",
});

const NoAvailableAgents = styled("span")({
  position: "absolute",
  left: "22%",
  top: "26%",
  fontWeight: 300,
});

export {
  PopoverContainer,
  Title,
  Option,
  Description,
  AgentsContainer,
  Faint,
  Loader,
  NoAvailableAgents,
};
