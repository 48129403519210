import React, { useEffect } from "react";

import { isInteger } from "lodash";

import { useEnableLROSettings } from "manager/hooks/api";
import { CheckboxWithUnorderedList, Input } from "shared/components/Form";
import Settings from "shared/components/Settings";
import Spacer from "shared/components/Spacer";

import ApplicationSetupHeader from "../Header";
import { StyledSkeleton, InputContainer } from "../styled";

const LeaseRentOptionsSettings = ({
  loading,
  disabled,
  dealSetup,
  setFieldValue,
  setFieldError,
  shouldShowExpirationInputField,
}) => {
  const [checked, setChecked] = React.useState(false);
  const [selectionValidityLifespan, setSelectionValidityLifespan] =
    React.useState(0); // [hours]
  const [error, setError] = React.useState({
    show: false,
    message: "",
  });
  const [isPortfolio, setIsPortfolio] = React.useState(false);
  const { enableLRO } = useEnableLROSettings(dealSetup?.id);

  const handleChekboxChange = (val) => {
    if (shouldShowExpirationInputField) {
      setFieldValue("lroConfig.enabled", val);
      setChecked(val);
    } else {
      enableLRO(val).then(() => setChecked(val));
    }
  };

  useEffect(() => {
    setChecked(dealSetup?.lroConfig?.enabled);
    setSelectionValidityLifespan(
      dealSetup?.lroConfig?.selectionValidityLifespan
    );
    setIsPortfolio(Number.isInteger(dealSetup?.contentObject?.numProperties));
  }, [dealSetup]);

  const handleChange = (e) => {
    if (!isInteger(Number(e.target.value))) {
      setError({ show: true, message: "Length must be a whole number" });
      setFieldError(
        "lroConfig.selectionValidityLifespan",
        "Length must be a whole number"
      );
    } else {
      setFieldValue(
        "lroConfig.selectionValidityLifespan",
        Number(e.target.value)
      );
      setError({ show: false, message: "" });
    }
  };

  return (
    <Settings
      header={
        <StyledSkeleton loading={loading}>
          <ApplicationSetupHeader
            title="Lease Rent Options settings"
            subTitle="Configurations to filter LRO results for all available Unit Listings"
            description={`applies to ${
              isPortfolio ? "portfolio" : "property only"
            } configuration`}
          />
        </StyledSkeleton>
      }
      nodes={[
        {
          key: 10,
          component: (
            <StyledSkeleton loading={loading}>
              <CheckboxWithUnorderedList
                id="enable-lro-config"
                label={<>Enable LRO&nbsp;</>}
                onChange={(val) => handleChekboxChange(val)}
                value={checked}
                disabled={disabled || loading}
              />
              {shouldShowExpirationInputField && (
                <InputContainer>
                  <Input
                    id="selection-validity-lifespan"
                    label="Length of rental price lock"
                    name="selectionValidityLifespan"
                    className="lifespan-input"
                    type="number"
                    suffix="hours"
                    disabled={!checked || disabled || loading}
                    onChange={handleChange}
                    value={selectionValidityLifespan || undefined}
                    error={error.show ? error.message : null}
                    step={1}
                    min={0}
                  />
                </InputContainer>
              )}
              <Spacer size="lg" />
            </StyledSkeleton>
          ),
        },
      ]}
    />
  );
};

export default LeaseRentOptionsSettings;
