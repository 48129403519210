import React from "react";

import useModal from "shared/hooks/useModal";

import AnimatedPendingActionModal from "./AnimatedPendingActionModal";

const AnimatedPendingActionModalContainer = (props) => {
  const { currentModalContext, currentModalId, selfApi } = useModal();
  const { isLoading } = currentModalContext;
  const { modalId } = props || {};

  const closeModal = () => selfApi.closeDialog();

  return (
    <AnimatedPendingActionModal
      modalId={modalId || currentModalId}
      isLoading={isLoading}
      closeModal={closeModal}
      {...props}
    />
  );
};

export default AnimatedPendingActionModalContainer;
