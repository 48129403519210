import styled from "@emotion/styled";
import { Drawer } from "antd";

import { COLORS } from "shared/config/constants";

const StyledDrawer = styled(Drawer)`
  display: flex;

  .ant-drawer-body {
    padding: 0;
  }

  // drawer close icon position
  .ant-drawer-close {
    left: 0;
    top: 0;
    width: 49px;
    height: 49px;
  }

  // drawer close svg style
  i.anticon-close {
    svg {
      color: ${COLORS.button.color};
      width: 20px;
      height: 20px;
    }
  }

  .ant-drawer-title {
    display: flex;
    justify-content: flex-end;
  }

  .ant-drawer-header {
    padding: 0;
  }
`;

const MobileIconWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export { StyledDrawer, MobileIconWrapper };
