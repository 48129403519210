import { Key } from "react";

import intersection from "lodash/intersection";

export type CheckboxItem = {
  key?: Key;
  parent?: Key;
  collapsible?: boolean;
  children?: Key[];
  siblings?: Key[];
};

export type CheckboxPartition = { checked: Key[]; indeterminate: Key[] };

const checkCheckbox = (key: Key, checkedItems: Key[]) => checkedItems.push(key);
const checkCheckboxes = (keys: Key[], checkedItems: Key[]) =>
  checkedItems.push(...keys);
const uncheckCheckbox = (key: Key, checkedItems: Key[]) => {
  const index = checkedItems.indexOf(key);
  if (index > -1) {
    checkedItems.splice(index, 1);
  }
};
const uncheckCheckboxes = (keys: Key[], checkedItems: Key[]) =>
  keys.forEach((key) => uncheckCheckbox(key, checkedItems));
const setIndeterminate = (key: Key, indeterminateItems: Key[]) =>
  indeterminateItems.push(key);
const setNotIndeterminate = (key: Key, indeterminateItems: Key[]) => {
  const index = indeterminateItems.indexOf(key);
  if (index > -1) {
    indeterminateItems.splice(index, 1);
  }
};

const uncheckParent = (
  { siblings, parent }: CheckboxItem,
  allItems: CheckboxPartition
) => {
  const checkedChildren = intersection(allItems.checked, siblings);
  const noChildrenChecked = checkedChildren.length === 0;
  const allChildrenChecked =
    !noChildrenChecked && checkedChildren.length === siblings.length + 1;
  const onlySomeChildrenChecked = !noChildrenChecked && !allChildrenChecked;

  uncheckCheckbox(parent, allItems.checked);
  if (onlySomeChildrenChecked) {
    setIndeterminate(parent, allItems.indeterminate);
  } else if (noChildrenChecked) {
    setNotIndeterminate(parent, allItems.indeterminate);
  }
};

const checkParent = (
  { siblings, parent }: CheckboxItem,
  allItems: CheckboxPartition
) => {
  const checkedSiblings = intersection(allItems.checked, siblings);
  const allSiblingsChecked = checkedSiblings.length === siblings.length;

  if (allSiblingsChecked) {
    checkCheckbox(parent, allItems.checked);
    setNotIndeterminate(parent, allItems.indeterminate);
  } else {
    setIndeterminate(parent, allItems.indeterminate);
  }
};

const checkChildren = (children: Key[], checkedItems: Key[]) =>
  checkCheckboxes(children, checkedItems);
const uncheckChildren = (children: Key[], checkedItems: Key[]) =>
  uncheckCheckboxes(children, checkedItems);

export const handleCheckboxUncheck = (
  item: CheckboxItem,
  allItems: CheckboxPartition
) => {
  const isParent = item.collapsible === true;
  const isChild = item.parent !== undefined;

  uncheckCheckbox(item.key, allItems.checked);
  if (isParent) {
    uncheckChildren(item.children, allItems.checked);
  } else if (isChild) {
    uncheckParent(item, allItems);
  }

  return allItems;
};

export const handleCheckboxCheck = (
  item: CheckboxItem,
  allItems: CheckboxPartition
) => {
  const isParent = item.collapsible === true;
  const isChild = item.parent !== undefined;

  checkCheckbox(item.key, allItems.checked);
  if (isParent) {
    checkChildren(item.children, allItems.checked);
    setNotIndeterminate(item.key, allItems.indeterminate);
  } else if (isChild) {
    checkParent(item, allItems);
  }

  return allItems;
};

const getChildren = (item: CheckboxItem, allItems: CheckboxItem[]) =>
  allItems
    .filter(({ parent }) => {
      const isChild = parent === item.key;
      return isChild;
    })
    .map(({ key }) => key);

const getSiblings = (item: CheckboxItem, allItems: CheckboxItem[]) =>
  allItems
    .filter(({ parent, key }) => {
      const hasParent = parent !== undefined;
      const isSibling = parent === item.parent;
      const isNotCurrentItem = key !== item.key;

      return hasParent && isSibling && isNotCurrentItem;
    })
    .map(({ key }) => key);

export const annotateItem = (item: CheckboxItem, allItems: CheckboxItem[]) => {
  const annotatedItem = { ...item };

  annotatedItem.children = getChildren(item, allItems);
  annotatedItem.siblings = getSiblings(item, allItems);

  return annotatedItem;
};

export const getIndeterminateItems = (items: CheckboxItem[], checked) => {
  return items.reduce((prev, item) => {
    const isChildItemChecked =
      checked.includes(item.key) &&
      item.parent &&
      !checked.includes(item.parent) &&
      !prev.includes(item.parent);

    if (isChildItemChecked) {
      prev.push(item.parent);
    }

    return prev;
  }, []);
};
