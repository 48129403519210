import { parse, startOfDay } from "date-fns";

import { toNumber } from "shared/utils/number";
import { areSameAsStrings, stringify } from "shared/utils/string";

import {
  destringifyVeroAgentId,
  destringifyVeroMarketingSourceId,
} from "./section/configureYardiPropertySection.util";

const setupAgentsData = (fetchedVeroPropertyEmployees, fetchedYardiAgents) => {
  const veroData =
    fetchedVeroPropertyEmployees &&
    (fetchedVeroPropertyEmployees || [])
      .filter((employee) => !!employee.user && employee.isAssigned)
      .map((employee) => {
        const yardiAgent = (fetchedYardiAgents || []).find((yardiItem) => {
          return (yardiItem.employees || []).indexOf(employee.id) >= 0;
        });

        return {
          id: employee.id,
          label: `${employee.user.firstName} ${employee.user.lastName}`,
          email: employee.user.email,
          yardiAgent: (yardiAgent && yardiAgent.id) || null,
        };
      });
  const yardiData = (fetchedYardiAgents || []).map((agent) => {
    return {
      id: agent.id,
      value: stringify(agent.id),
      label: agent.fullName,
    };
  });
  return {
    yardiAgents: yardiData,
    veroAgents: veroData,
  };
};

const setupMarketingSourcesData = (
  fetchedVeroMarketingSources,
  fetchedYardiMarketingSources
) => {
  const veroData = (fetchedVeroMarketingSources || []).map(
    (marketingSource) => {
      const yardiMarketingSource = (fetchedYardiMarketingSources || []).find(
        (yardiItem) => {
          return (
            (yardiItem.marketingSources || []).indexOf(marketingSource.id) >= 0
          );
        }
      );

      return {
        id: marketingSource.id,
        isDefault: marketingSource.isDefault,
        label: marketingSource.value,
        yardiMarketingSource:
          (yardiMarketingSource && yardiMarketingSource.id) || null,
      };
    }
  );
  const yardiData = (fetchedYardiMarketingSources || []).map(
    (marketingSource) => {
      return {
        id: marketingSource.id,
        value: stringify(marketingSource.id),
        label: marketingSource.value,
      };
    }
  );
  return {
    yardiMarketingSources: yardiData,
    veroMarketingSources: veroData,
  };
};

const convertUnitInForm = (yardiUnitFormKey, unitFormObj, addRent = true) => {
  if (yardiUnitFormKey && unitFormObj) {
    const yardiUnitId = yardiUnitFormKey;
    const result = {
      yardiUnitId,
      unitId: toNumber(unitFormObj.veroUnit),
    };

    if (addRent) {
      result.unitRent = toNumber(unitFormObj.rent);
    }
    return result;
  }
  return undefined;
};

const convertUnitsInForm = (formObj, addRent = true) => {
  return formObj
    ? Object.keys(formObj)
        .map((key) => convertUnitInForm(key, formObj[key], addRent))
        .filter((obj) => !!obj)
    : formObj;
};

const convertAgentInForm = (yardiFormKey, formObj) => {
  if (yardiFormKey && formObj) {
    const employeeId = destringifyVeroAgentId(yardiFormKey);
    return {
      yardiAgentId: toNumber(formObj.yardiAgent),
      employeeId,
    };
  }
  return undefined;
};

const convertAgentsInForm = (formObj) => {
  return formObj
    ? Object.keys(formObj)
        .map((key) => convertAgentInForm(key, formObj[key]))
        .filter((obj) => !!obj)
    : formObj;
};

const convertMarketingSourceInForm = (
  yardiFormKey,
  formObj,
  defaultSelection
) => {
  if (yardiFormKey && formObj) {
    const marketingSourceId = destringifyVeroMarketingSourceId(yardiFormKey);
    return {
      yardiMarketingSourceId: toNumber(formObj.yardiMarketingSource),
      marketingSourceId,
      isDefault: areSameAsStrings(marketingSourceId, defaultSelection),
    };
  }
  return undefined;
};

const convertMarketingSourcesInForm = (formObj, defaultSelection) => {
  return formObj
    ? Object.keys(formObj)
        .map((key) =>
          convertMarketingSourceInForm(key, formObj[key], defaultSelection)
        )
        .filter((obj) => !!obj)
    : formObj;
};

function convertUnitsToFormData(initialYardiUnits, veroUnitsMap, addRent) {
  const unitsFormData = {};
  let mappedUnits = 0;
  (initialYardiUnits || []).forEach((yardiUnit) => {
    mappedUnits += yardiUnit.unit ? 1 : 0;
    const { id } = yardiUnit;
    const veroUnitId = stringify(yardiUnit.unit);
    unitsFormData[id] = {
      veroUnit: veroUnitId,
    };
    if (addRent) {
      const veroUnit = veroUnitsMap && veroUnitId && veroUnitsMap[veroUnitId];
      unitsFormData[id].rent = veroUnit?.rent || null;
    }
  });
  const isUnitsSectionComplete =
    (initialYardiUnits || []).length === mappedUnits;
  return { unitsFormData, isUnitsSectionComplete };
}

function pmsUnitOccupancyStatus(pmsUnit) {
  return pmsUnit.isVacant ? "vacant" : "occupied";
}

function pmsUnitLeasedStatus(pmsUnit) {
  if (pmsUnit.isAvailable) {
    return "available";
  }

  if (pmsUnit.isOnNotice) {
    return "on_notice";
  }

  if (pmsUnit.isReserved) {
    return "leased_reserved";
  }

  if (pmsUnit.isVacant) {
    return "leased";
  }

  return "other";
}

function pmsUnitOnHoldUntil(pmsUnit) {
  const { onHoldUntilDate } = pmsUnit;
  return onHoldUntilDate
    ? parse(onHoldUntilDate, "yyyy-MM-dd", new Date())
    : null;
}

function calculatePmsUnitIsActive(pmsUnit) {
  const occupancyStatus = pmsUnitOccupancyStatus(pmsUnit);
  const leasedStatus = pmsUnitLeasedStatus(pmsUnit);
  const onHoldUntil = pmsUnitOnHoldUntil(pmsUnit);
  const { isWaitUnit } = pmsUnit;
  const availability = Boolean(
    pmsUnit.availableDate || pmsUnit.makeReadyDate || pmsUnit.vacateDate
  );

  const taken =
    (occupancyStatus === "occupied" && leasedStatus !== "on_notice") ||
    (occupancyStatus === "vacant" && leasedStatus === "leased_reserved");
  const onHold = onHoldUntil && onHoldUntil >= startOfDay(new Date());
  return isWaitUnit || !(taken || onHold || !availability);
}

export {
  setupAgentsData,
  setupMarketingSourcesData,
  convertUnitsInForm,
  convertAgentsInForm,
  convertMarketingSourcesInForm,
  convertUnitsToFormData,
  calculatePmsUnitIsActive,
};
