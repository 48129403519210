import React, { useRef, useState } from "react";

import { Row } from "antd";
import { FormikProps } from "formik";
import { Helmet } from "react-helmet";

import { Button } from "shared/components/Button";

import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import { ExperianLogoSvg } from "shared/images";

import { ExperianKiq } from "../../interfaces/api/experianAuth";

import ExperianQuestionsForm from "./ExperianQuestionsForm";
import {
  AuthContainer,
  ExperianContent,
  Header,
  NoticeContent,
  QuestionsContent,
  QuestionsHeader,
  IconWrapper,
  Message,
  ExperianLogo,
  ButtonsFooter,
} from "./styled";

interface ExperianAuthProps {
  getExperianAuthTokenByKiq: (params: Record<string, number>) => void;
  questionsList: ExperianKiq[];
  cleanQuestions: () => void;
}

const ExperianAuthKiq = ({
  cleanQuestions,
  questionsList,
  getExperianAuthTokenByKiq,
}: ExperianAuthProps) => {
  const formikRef = useRef<FormikProps<any>>(null);
  const [isSubmitting, setSubmitting] = useState(false);

  const handleSubmit = async () => {
    if (formikRef.current) {
      setSubmitting(true);
      await formikRef.current.submitForm();
      setSubmitting(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Experian Authentication Questions</title>
      </Helmet>
      <AuthContainer data-testid="page-content">
        <NoticeContent>
          <Row type="flex">
            <Header>
              <div>
                Experian provides these questions from your credit profile to
                verify your identity. You may be required to provide additional
                documentation if you answer incorrectly.
              </div>
            </Header>
          </Row>
        </NoticeContent>
        <QuestionsHeader>Identity Questions</QuestionsHeader>
        <QuestionsContent>
          <FlexContainer alignItems="center" justifyContent="center">
            <ExperianLogo src={ExperianLogoSvg} />
          </FlexContainer>
          <ExperianContent>
            <IconWrapper>
              <Icon.InfoStandard height="70%" width="70%" />
            </IconWrapper>
            <Message>
              Experian has imported the following security questions from
              various sources, including your credit report. The questions will
              vary and may ask you to recall historical financial information.
            </Message>
          </ExperianContent>
          <ExperianQuestionsForm
            formikRef={formikRef}
            questionsList={questionsList}
            onSubmit={getExperianAuthTokenByKiq}
          />
        </QuestionsContent>
        <ButtonsFooter>
          <Button
            type="secondary"
            PrefixIcon={Icon.BlackArrowLeft}
            onClick={cleanQuestions}
          />
          <Button
            type="primary"
            SuffixIcon={Icon.WhiteArrowRight}
            onClick={handleSubmit}
            loading={isSubmitting}
            size="lg"
          >
            <div>Submit</div>
          </Button>
        </ButtonsFooter>
      </AuthContainer>
    </>
  );
};

export default ExperianAuthKiq;
