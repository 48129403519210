import styled from "@emotion/styled";

import { COLORS } from "shared/config/constants";

const SectionTitleLight = styled("span")({
  fontSize: "15px",
  fontWeight: 300,
});

const SectionQuestion = styled.p({
  fontSize: "17px",
  fontWeight: 500,
  color: COLORS.textColorPrimary,
  margin: 0,
});

const CustomSubsection = styled.div({
  ".subsection-title": {
    marginBottom: "12px",
  },
  ".sync-text-input": {
    width: "400px",
    "&:not(:last-child)": {
      marginBottom: "16px",
    },
  },
});

export { SectionTitleLight, SectionQuestion, CustomSubsection };
