import styled from "@emotion/styled";
import { Form as FormikForm } from "formik";

import BaseFooterBar from "shared/components/FooterBar";

export const Form = styled(FormikForm)`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  #form-secondary {
    margin-right: 12px;
  }
`;

export const FooterBar = styled(BaseFooterBar)`
  justify-content: flex-end;
`;
