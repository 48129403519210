import React, { createContext, useContext, useState } from "react";

import { Subject } from "rxjs";

import { combineComponents } from "shared/utils/combine-components";

import { VeroModalContext } from "./types";

function useVeroModal<P = void>(): VeroModalContext<P> {
  const [visible, setVisible] = useState(false);
  const hide = () => {
    setVisible(false);
  };
  const show = () => setVisible(true);
  const ok$ = new Subject<P>();
  const cancel$ = new Subject<void>();
  const submit$ = new Subject<Promise<P>>();
  const [submitting, setSubmitting] = useState(false);
  submit$.subscribe((promise) => {
    setSubmitting(true);
    promise
      .then((x) => ok$.next(x))
      .catch(console.error) // catch unhandled exceptions to prevent app from crashing
      .finally(() => setSubmitting(false));
  });
  ok$.subscribe(hide);
  cancel$.subscribe(hide);

  return {
    visible,
    submitting,
    hide,
    show,
    ok$,
    cancel$,
    submit$,
  };
}

export function createModalContext<P = void>() {
  const Context = createContext<VeroModalContext<P>>(undefined);
  const Provider: React.FC<{}> = ({ children }) => {
    const state = useVeroModal<P>();
    return <Context.Provider value={state}>{children}</Context.Provider>;
  };
  const useModalContext = () => useContext(Context);

  return { Context, Provider, useModalContext };
}

export const withModals = <T extends {}>(
  Component: React.FC<T>,
  ...providers: React.FC<{}>[]
) => {
  const Merged = combineComponents(...providers);
  return (props: T) => (
    <Merged>
      <Component {...props} />
    </Merged>
  );
};
