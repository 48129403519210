import styled from "@emotion/styled";

import { Search as BaseSearch } from "shared/components/Form/Search";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";
import { AlignedCenterFlexPartial } from "shared/styles/commonStyles";

const Container = styled("div")({
  borderBottom: `1px solid ${COLORS.list.border}`,
  borderBottomLeftRadius: "2px",
  borderBottomRightRadius: "2px",
  [MEDIA_QUERIES.w.ltSm]: {
    borderRadius: 0,
  },
});

const Header = styled("div")({
  border: `1px solid ${COLORS.list.border}`,
  borderTopLeftRadius: "2px",
  borderTopRightRadius: "2px",
  padding: "8px 24px",
  backgroundColor: COLORS.searchableList.header.background,
  [MEDIA_QUERIES.w.ltSm]: {
    borderLeft: 0,
    borderRight: 0,
    borderRadius: 0,
  },
});

const Search = styled(BaseSearch)((props) => ({
  [MEDIA_QUERIES.w.gteSm]: {
    width: props.width,
  },
}));

const LoadingContainer = styled.div(() => ({
  display: "flex",
  justifyContent: "center",
  padding: "32px 0",
}));

const HeaderTitle = styled("span")({
  fontWeight: 400,
  color: COLORS.textColorPrimary,
  ".extra-text": {
    color: COLORS.secondary,
  },
});

const CheckboxListHeaderContent = styled("div")({
  ...AlignedCenterFlexPartial,
  justifyContent: "space-between",
  ".first-col": {
    display: "flex",
    width: "335px",
  },
  ".search": {
    display: "flex",
    input: {
      borderBottomRightRadius: 0,
      borderTopRightRadius: 0,
    },
    ".filter-icon": {
      position: "relative",
      padding: "0 7px",
      display: "flex",
      alignItems: "center",
      height: "40px",
      backgroundColor: COLORS.white,
      border: `1px solid ${COLORS.borderColorBase}`,
      borderLeft: "none",
      "&:hover": {
        border: `1px solid ${COLORS.input.hover.border}`,
        borderLeft: "none",
      },
      ".active-filter": {
        position: "absolute",
        right: "4px",
        top: "7px",
        height: "6px",
        width: "6px",
        borderRadius: "6px",
        backgroundColor: COLORS.green,
      },
    },
  },
  [MEDIA_QUERIES.w.ltSm]: {
    flexDirection: "column",
    ".second-col": {
      display: "none",
    },
    ".search": {
      alignSelf: "stretch",
      marginTop: "8px",
    },
  },
});

export {
  Container,
  Header,
  Search,
  LoadingContainer,
  HeaderTitle,
  CheckboxListHeaderContent,
};
