import React from "react";

import Icon from "shared/components/Icon";
import { BREAKPOINT_TYPES } from "shared/config/constants";
import withBreakpoint from "shared/hocs/withBreakpoint";

const SMALL_LOGO_BREAKPOINTS = [BREAKPOINT_TYPES.xs, BREAKPOINT_TYPES.sm];

const LARGE_LOGO_BREAKPOINTS = [
  BREAKPOINT_TYPES.md,
  BREAKPOINT_TYPES.lg,
  BREAKPOINT_TYPES.xl,
  BREAKPOINT_TYPES.xxl,
  BREAKPOINT_TYPES.xxxl,
];

export interface ILogo {
  breakpoint?: BREAKPOINT_TYPES;
  [key: string]: any;
}

const Logo = ({ breakpoint, ...props }: ILogo) => {
  return (
    <>
      {LARGE_LOGO_BREAKPOINTS.indexOf(breakpoint) > -1 && (
        <Icon.LogoIcon data-testid="default-logo" {...props} />
      )}
      {SMALL_LOGO_BREAKPOINTS.indexOf(breakpoint) > -1 && (
        <Icon.LogoMobileIcon data-testid="mobile-logo" {...props} />
      )}
    </>
  );
};

export default withBreakpoint(Logo);
