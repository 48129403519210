import React from "react";

import { css } from "@emotion/core";
import styled from "@emotion/styled";

import { MEDIA_QUERIES } from "shared/config/constants";

import { SIZES } from "./constants";

const Spacer = styled(
  ({
    /* eslint-disable @typescript-eslint/no-unused-vars */
    size,
    hideLtSm,
    hideGteSm,
    hideGteXl,
    hideLtXl,
    hideGteLg,
    hideLtLg,
    hideGteMd,
    hideLtMd,
    /* eslint-enable @typescript-eslint/no-unused-vars */
    ...props
  }) => <span {...props} />
)`
  display: block;
  margin-bottom: 24px;

  ${(props) =>
    props.size === SIZES.xl &&
    css`
      margin-bottom: 80px;
    `};

  ${(props) =>
    props.size === SIZES.lg &&
    css`
      margin-bottom: 56px;
    `};

  ${(props) =>
    props.size === SIZES.md &&
    css`
      margin-bottom: 32px;
    `};

  ${(props) =>
    props.size === SIZES.sm &&
    css`
      margin-bottom: 15px;
    `};

  ${(props) =>
    props.size === SIZES.xs &&
    css`
      margin-bottom: 8px;
    `};

  ${MEDIA_QUERIES.w.gteXl} {
    ${(props) =>
      !!props.hideGteXl &&
      css`
        display: none;
      `}
  }

  ${MEDIA_QUERIES.w.ltXl} {
    ${(props) =>
      !!props.hideLtXl &&
      css`
        display: none;
      `}
  }

  ${MEDIA_QUERIES.w.gteLg} {
    ${(props) =>
      !!props.hideGteLg &&
      css`
        display: none;
      `}
  }

  ${MEDIA_QUERIES.w.ltLg} {
    ${(props) =>
      !!props.hideLtLg &&
      css`
        display: none;
      `}
  }

  ${MEDIA_QUERIES.w.gteMd} {
    ${(props) =>
      !!props.hideGteMd &&
      css`
        display: none;
      `}
  }

  ${MEDIA_QUERIES.w.ltMd} {
    ${(props) =>
      !!props.hideLtMd &&
      css`
        display: none;
      `}
  }

  ${MEDIA_QUERIES.w.gteSm} {
    ${(props) =>
      !!props.hideGteSm &&
      css`
        display: none;
      `}
  }

  ${MEDIA_QUERIES.w.ltSm} {
    ${(props) =>
      !!props.hideLtSm &&
      css`
        display: none;
      `}
  }

  ${MEDIA_QUERIES.w.ltSm} {
    ${(props) =>
      props.size === SIZES.xl &&
      css`
        margin-bottom: 32px;
      `}

    ${(props) =>
      props.size === SIZES.lg &&
      css`
        margin-bottom: 28px;
      `}

      ${(props) =>
      props.size === SIZES.md &&
      css`
        margin-bottom: 24px;
      `}
  }
`;

export { Spacer };
