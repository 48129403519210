import axios from "axios";

import ROUTES from "manager/config/apiRoutes";
import { BluemoonLicense } from "manager/interfaces/api/bluemoonAlertResolver";
import { API_URL } from "shared/config/apiRoutes";
import { transformRoute } from "shared/utils/routing";

const api = {
  postBluemoonAlertResolveConnectLicense: (body: BluemoonLicense) =>
    axios
      .post<BluemoonLicense>(
        `${API_URL}${transformRoute(ROUTES.bluemoonResolveConnectLicense)}`,
        body
      )
      .then(({ data }) => data),
  patchBluemoonAlertResolveEditLicense: (body: BluemoonLicense) =>
    axios
      .patch<BluemoonLicense>(
        `${API_URL}${transformRoute(ROUTES.bluemoonResolveEditLicense, {
          id: body.id,
        })}`,
        body
      )
      .then(({ data }) => data),
};

export default api;
