import axios from "axios";

import ROUTES from "shared/config/apiRoutes";
import { API_URL } from "shared/lib/api/setupApi";
import { transformRoute } from "shared/utils/routing";

const { REACT_APP_PLAID_SLACK_WEBHOOK } = process.env;

const api = {
  /**
   * @param {number} applicationId
   * @param {bool} refresh
   * @returns {Promise.<{link_token: string }>}
   */
  getPlaidCredentials: async ({ applicationId, refresh }) =>
    axios
      .get(
        `${API_URL}${transformRoute(ROUTES.getPlaidCredentials, {
          applicationId,
        })}`,
        {
          params: {
            refresh,
          },
        }
      )
      .then(({ data }) => data),
  submitPlaidMetadata: ({ applicationId, token, metadata, itemId = null }) =>
    axios.post(
      `${API_URL}${transformRoute(ROUTES.submitPlaidMetadata, {
        applicationId,
      })}`,
      {
        publicToken: token,
        itemId,
        metadata,
      }
    ),
  sendPlaidNotification: ({ metadata }) => axios.post(
    `${API_URL}event`,
    {
      'detail-type': 'plaid-metadata',
      detail: metadata,
    },
    {
      headers: {
        'x-event-source': 'vero'
      }
    }
  ),
};

export default api;
