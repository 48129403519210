import React from "react";

import isEmpty from "lodash/isEmpty";
import { Route, useLocation } from "react-router-dom";

import Layout from "manager/components/Layout";
import ROUTES from "manager/config/routes";
import { useGetIntegrationStatuses } from "manager/hooks/api";
import BluemoonFieldsMapping from "manager/pages/Marketplace/integrations/bluemoon/BluemoonFieldsMapping";
import BluemoonPropertyBindings from "manager/pages/Marketplace/integrations/bluemoon/bluemoonPropertyBindings";
import BluemoonDashboard from "manager/pages/Marketplace/integrations/bluemoon/dashboard";
import IntegrationLogin from "manager/pages/Marketplace/integrations/yardi";
import ConfigureYardiProperty from "manager/pages/Marketplace/integrations/yardi/ConfigureYardiProperty";
import YardiDashboard from "manager/pages/Marketplace/integrations/yardi/dashboard";
import SetupYardiChargeTypes from "manager/pages/Marketplace/integrations/yardi/SetupYardiChargeTypes";
import SetupYardiDocumentTypes from "manager/pages/Marketplace/integrations/yardi/SetupYardiDocumentTypes";
import SetupYardiLeadSources from "manager/pages/Marketplace/integrations/yardi/SetupYardiLeadSources";
import SetupYardiProperty from "manager/pages/Marketplace/integrations/yardi/SetupYardiProperty";
import SetupYardiSyncSettings from "manager/pages/Marketplace/integrations/yardi/SetupYardiSyncSettings";
import YardiAlerts from "manager/pages/Marketplace/integrations/yardi/YardiAlerts";
import Marketplace from "manager/pages/Marketplace/Marketplace";
import { PERMISSIONS } from "shared/config/authConstants";
import { IntegrationProvider } from "shared/config/constants";
import { useActiveUser } from "shared/hooks/api";

import { getSelectedIntegrationProvider } from "./config";
import BluemoonBoundProperties from "./integrations/bluemoon/components/BluemoonBoundProperties";

const useGetMarketplaceRoutes = () => {
  const location = useLocation();
  const provider = location.pathname.split("/")?.[2] as IntegrationProvider;
  const { activeUser } = useActiveUser();
  const { integrationStatuses } = useGetIntegrationStatuses({
    enabled: !!activeUser,
  });

  const selectedIntegrationStatus = getSelectedIntegrationProvider(
    integrationStatuses,
    provider
  );

  const getMarketplaceRoutes = (
    sidebar,
    header,
    backLinks,
    permissionsLoaded,
    hasPermission,
    company
  ) => {
    const routes = [];
    const isCompanyLoaded = !isEmpty(company);
    const isIntegrationsAdmin = hasPermission(PERMISSIONS.INTEGRATIONS_ADMIN);
    const isDataLoaded =
      permissionsLoaded && isCompanyLoaded && !!integrationStatuses;

    routes.push(
      <Route
        path={ROUTES.marketplace}
        key={ROUTES.marketplace}
        render={() => (
          <Layout sidebar={sidebar} header={header}>
            <Marketplace />
          </Layout>
        )}
      />
    );

    if (
      !isDataLoaded ||
      (isIntegrationsAdmin && selectedIntegrationStatus?.isEnabled)
    ) {
      routes.push(
        <Route
          path={ROUTES.integrationLogin}
          key={ROUTES.integrationLogin}
          render={() => (
            <Layout
              sidebar={sidebar}
              header={{
                ...header,
                backLink: backLinks.specificIntegration,
              }}
            >
              {isDataLoaded && (
                <IntegrationLogin
                  backLink={backLinks.specificIntegration}
                  editMode={false}
                />
              )}
            </Layout>
          )}
        />
      );
    }
    if (
      !isDataLoaded ||
      (isIntegrationsAdmin && integrationStatuses?.bluemoon?.isEnabled)
    ) {
      routes.push(
        <Route
          exact
          path={ROUTES.bluemoonDashboard}
          key={ROUTES.bluemoonDashboard}
          render={() => (
            <Layout
              sidebar={sidebar}
              header={{
                ...header,
                backLink: backLinks.specificIntegration,
              }}
            >
              {isDataLoaded && (
                <BluemoonDashboard
                  backLink={backLinks.specificIntegration}
                  editMode={false}
                />
              )}
            </Layout>
          )}
        />,
        <Route
          path={ROUTES.bluemoonFieldsMapping}
          key={ROUTES.bluemoonFieldsMapping}
          render={() => (
            <Layout
              sidebar={sidebar}
              header={{
                ...header,
                backLink: backLinks.bluemoonDashboard,
              }}
            >
              {isDataLoaded && (
                <BluemoonFieldsMapping
                  backLink={backLinks.bluemoonDashboard}
                  // @ts-ignore
                  editMode={false}
                />
              )}
            </Layout>
          )}
        />
      );

      routes.push(
        <Route
          exact
          path={ROUTES.bluemoonBoundProperties}
          key={ROUTES.bluemoonBoundProperties}
          render={() => (
            <Layout
              sidebar={sidebar}
              header={{
                ...header,
                backLink: backLinks.bluemoonDashboard,
              }}
            >
              {isDataLoaded && (
                <BluemoonBoundProperties
                  backLink={backLinks.bluemoonDashboard}
                />
              )}
            </Layout>
          )}
        />
      );

      routes.push(
        <Route
          path={ROUTES.bluemoonPropertyBindings}
          key={ROUTES.bluemoonPropertyBindings}
          render={() => (
            <Layout
              sidebar={sidebar}
              header={{
                ...header,
                backLink: backLinks.bluemoonDashboard,
              }}
            >
              {isDataLoaded && (
                <BluemoonPropertyBindings
                  backLink={backLinks.bluemoonDashboard}
                />
              )}
            </Layout>
          )}
        />
      );
    }

    if (!isDataLoaded || selectedIntegrationStatus?.isConnected) {
      routes.push(
        <Route
          path={ROUTES.integrationAlerts}
          key={ROUTES.integrationAlerts}
          render={() => (
            <Layout
              sidebar={sidebar}
              header={{
                ...header,
                backLink: isIntegrationsAdmin
                  ? backLinks.integrationDashboard(provider)
                  : backLinks.specificIntegration,
              }}
            >
              {isDataLoaded && (
                <YardiAlerts
                  backLink={
                    isIntegrationsAdmin
                      ? backLinks.integrationDashboard(provider)
                      : backLinks.specificIntegration
                  }
                />
              )}
            </Layout>
          )}
        />
      );
    }

    if (
      !isDataLoaded ||
      (isIntegrationsAdmin && selectedIntegrationStatus?.isConnected)
    ) {
      routes.push(
        <Route
          path={ROUTES.integrationDashboard}
          key={ROUTES.integrationDashboard}
          render={() => (
            <Layout
              sidebar={sidebar}
              header={{
                ...header,
                backLink: backLinks.specificIntegration,
              }}
            >
              {isDataLoaded && (
                <YardiDashboard company={company} backLink={backLinks.specificIntegration} />
              )}
            </Layout>
          )}
        />
      );

      routes.push(
        <Route
          path={ROUTES.integrationSetupChargeTypes}
          key={ROUTES.integrationSetupChargeTypes}
          render={() => (
            <Layout
              sidebar={sidebar}
              header={{
                ...header,
                backLink: backLinks.integrationDashboard(provider),
              }}
            >
              {isDataLoaded && (
                <SetupYardiChargeTypes
                  backLink={backLinks.integrationDashboard(provider)}
                />
              )}
            </Layout>
          )}
        />
      );

      routes.push(
        <Route
          path={ROUTES.integrationEditCredentials}
          key={ROUTES.integrationEditCredentials}
          render={() => (
            <Layout
              sidebar={sidebar}
              header={{
                ...header,
                backLink: backLinks.integrationDashboard(provider),
              }}
            >
              {isDataLoaded && (
                <IntegrationLogin
                  backLink={backLinks.integrationDashboard(provider)}
                  editMode
                />
              )}
            </Layout>
          )}
        />
      );

      routes.push(
        <Route
          path={ROUTES.integrationSetupProperty}
          key={ROUTES.integrationSetupProperty}
          exact
          render={() => (
            <Layout
              sidebar={sidebar}
              header={{
                ...header,
                backLink: backLinks.integrationDashboard(provider),
              }}
            >
              {isDataLoaded && (
                <SetupYardiProperty
                  backLink={backLinks.integrationDashboard(provider)}
                />
              )}
            </Layout>
          )}
        />
      );

      routes.push(
        <Route
          path={ROUTES.integrationSetupDocumentTypes}
          key={ROUTES.integrationSetupDocumentTypes}
          render={() => (
            <Layout
              sidebar={sidebar}
              header={{
                ...header,
                backLink: backLinks.integrationDashboard(provider),
              }}
            >
              {isDataLoaded && (
                <SetupYardiDocumentTypes
                  backLink={backLinks.integrationDashboard(provider)}
                />
              )}
            </Layout>
          )}
        />
      );

      routes.push(
        <Route
          path={ROUTES.integrationSetupSyncSettings}
          key={ROUTES.integrationSetupSyncSettings}
          render={() => (
            <Layout
              sidebar={sidebar}
              header={{
                ...header,
                backLink: backLinks.integrationDashboard(provider),
              }}
            >
              {isDataLoaded && (
                <SetupYardiSyncSettings
                  backLink={backLinks.integrationDashboard(provider)}
                />
              )}
            </Layout>
          )}
        />
      );

      routes.push(
        <Route
          path={ROUTES.integrationSetupLeadSources}
          key={ROUTES.integrationSetupLeadSources}
          render={() => (
            <Layout
              sidebar={sidebar}
              header={{
                ...header,
                backLink: backLinks.integrationDashboard(provider),
              }}
            >
              {isDataLoaded && (
                <SetupYardiLeadSources
                  backLink={backLinks.integrationDashboard(provider)}
                />
              )}
            </Layout>
          )}
        />
      );

      routes.push(
        <Route
          path={ROUTES.integrationConfigProperty}
          key={ROUTES.integrationConfigProperty}
          render={() => (
            <Layout
              sidebar={sidebar}
              header={{
                ...header,
                backLink: backLinks.integrationDashboard(provider),
              }}
            >
              {isDataLoaded && (
                <ConfigureYardiProperty
                  company={company}
                  backLink={backLinks.integrationDashboard(provider)}
                />
              )}
            </Layout>
          )}
        />
      );
    }

    return routes;
  };

  return { getMarketplaceRoutes };
};

export { useGetMarketplaceRoutes };
