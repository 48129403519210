import React, { ElementType, Ref } from "react";

import { ListWrapper } from "shared/components/Form/ListWrapper";
import { ListProps } from "shared/components/types";

import { Container, Header, Search } from "./styled";
import { SearchPropTypes } from "./types";

export type SearchableListProps = {
  search?: SearchPropTypes;
  List: ElementType;
  listProps: ListProps;
  listContainerProps?: Record<string, unknown>;
  loading?: boolean;
  hasInputValue?: boolean;
  placeholder?: {
    label: string;
    Icon: ElementType;
  };
  customHeaderContent?: React.ReactNode;
  listRef?: Ref<any>;
};

export const SearchableList: React.FC<SearchableListProps> = ({
  search,
  List,
  listProps,
  listContainerProps,
  loading,
  hasInputValue,
  placeholder,
  customHeaderContent,
  listRef,
}) => (
  <Container
    data-testid="searchable-list"
    className="searchable-list-container"
  >
    <Header className="searchable-list-header">
      {!customHeaderContent && (
        <Search
          id={search.fieldName}
          name={search.fieldName}
          onSearch={search.action}
          label={search.label}
          width={search.width}
          debounce={search.debounce}
          value={search.value}
        />
      )}
      {customHeaderContent}
    </Header>
    <ListWrapper
      List={List}
      listProps={listProps}
      listContainerProps={listContainerProps}
      loading={loading}
      hasInputValue={hasInputValue}
      placeholder={placeholder}
      listRef={listRef}
      className="searchable-list-body"
    />
  </Container>
);
