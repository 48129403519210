/* eslint-disable camelcase */
import React, { useRef, useState } from "react";

import { FormikProps } from "formik";
import { useParams } from "react-router-dom";

import { useCreateMagicDeal, useGetMagicLinkLogo } from "renter/hooks/api";
import { getQueryParams } from "shared/utils/routing";

import {
  MagicDealError,
  MagicLinkGetStartedContainerParams,
  MagicLinkGetStartedContainerProps,
  MagicLinkGetStartedContainerQueryParams,
  StepLandingFormValues,
} from "./interfaces";
import MagicLinkGetStarted from "./MagicLinkGetStarted";
import { MAGIC_LINK_LANDING_STEP } from "./magicLinkLanding.config";

const MagicLinkGetStartedContainer = ({
  dealType,
}: MagicLinkGetStartedContainerProps) => {
  const { companyHashId }: MagicLinkGetStartedContainerParams = useParams();
  const {
    source,
    is_qr_code,
    "external-reference": externalReference,
  }: MagicLinkGetStartedContainerQueryParams = getQueryParams();
  const [currentStep, setCurrentStep] = useState(
    MAGIC_LINK_LANDING_STEP.LANDING_STEP
  );
  const [submittedEmail, setSubmittedEmail] = useState<string>();
  const [signupUrl, setSignupUrl] = useState<string | undefined>();
  const { createMagicDeal } = useCreateMagicDeal();
  const { data, isLoading } = useGetMagicLinkLogo({ companyHashId, dealType });
  const formikRef = useRef<FormikProps<StepLandingFormValues>>();

  const externalUrl = source ? decodeURI(source) : document.referrer;
  const isQrCode = is_qr_code === "True";
  const { userAgent } = window.navigator;

  const submit = ({ email }: { email: string }) =>
    createMagicDeal(
      {
        dealType,
        companyHashId,
        email,
        externalUrl,
        isQrCode,
        userAgent,
        externalReference,
      },
      {
        onSuccess: ({ data: responseData }) => {
          const { link } = responseData || {};
          setSubmittedEmail(email);
          setSignupUrl(link);
          setCurrentStep(MAGIC_LINK_LANDING_STEP.CONFIRMATION_STEP);
        },
        onError: (error: MagicDealError) =>
          formikRef.current.setErrors(error?.errors),
      }
    );

  return (
    <MagicLinkGetStarted
      submit={submit}
      isCompanyProfileLoading={isLoading}
      currentStep={currentStep}
      submittedEmail={submittedEmail}
      signupUrl={signupUrl}
      logoUrl={data?.logoUrl}
      propertyName={data?.propertyName}
      unitName={data?.unitName}
      dealType={dealType}
      formikRef={formikRef}
    />
  );
};

export default MagicLinkGetStartedContainer;
