import styled from "@emotion/styled";
import { Divider } from "antd";

import { Button } from "shared/components/Button";
import FlexContainer from "shared/components/FlexContainer";
import { UnderlineLink } from "shared/components/Links/UnderlineLink/styled";
import { MediumTitle, SmallText } from "shared/components/Typography";

import Well from "shared/components/Well";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";
import { Calculating, EmptyBox } from "shared/icons";

export const WellContainer = styled(Well)<{ showLumen?: boolean }>`
  background: ${COLORS.grayTheme.activeTextColor};
  border-radius: 4px 4px 0 0;
  ${({ showLumen }) =>
    showLumen &&
    `
     border-radius: 4px;
  `}
  border: none;
  .ant-popover-arrow {
    background-color: red !important;
  }
`;

export const NoLumenTitle = styled(MediumTitle)`
  color: ${COLORS.lightGray4} !important;
  display: flex;
  align-items: center;
`;

export const ActionMainButton = styled(Button)<{
  color?: string;
  backgroundColor?: string;
}>`
  color: ${({ color }) => color} !important;
  background: ${({ backgroundColor }) => backgroundColor} !important;
  &:disabled {
    color: ${({ color }) => color} !important;
    background: ${({ backgroundColor }) => backgroundColor} !important;
    opacity: 0.3;
    pointer-events: none;
  }
`;

export const MoreActionsButton = styled(Button)`
  border: 1px solid white;
  margin-left: 16px;
`;

export const PopoverContainer = styled.div`
  background: ${COLORS.grayTheme.activeTextColor};
`;

export const StyledActionButton = styled(Button)<{
  color?: string;
}>`
  color: ${({ color }) => color} !important;
  width: 100% !important;
  justify-content: start;
  &:disabled {
    color: ${({ color }) => color} !important;
    background-color: ${COLORS.primary} !important;
    opacity: 0.3;
    pointer-events: none;
  }
`;

export const LumenSublabel = styled(UnderlineLink)<{
  color?: string;
  disabled?: boolean;
}>`
  color: ${COLORS.disabledText};
  font-size: 12px;
  border-bottom: ${({ disabled }) => disabled && "0px"};
`;

export const Subtitle = styled(SmallText)`
  color: white !important;
`;

export const EmptyBoxWhite = styled(EmptyBox)`
  path {
    fill: white;
  }
`;

export const CalculatingWhite = styled(Calculating)`
  path {
    fill: white;
  }
`;

export const ResponsiveFlexContainer = styled(FlexContainer)`
  ${MEDIA_QUERIES.w.ltLg} {
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex: 1 1 auto;
  }
  ,
  & > header.vega {
    padding: 0;
  }
`;
