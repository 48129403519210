import React from "react";

import styled from "@emotion/styled";
import { Button as BaseButton } from "antd";

import Icon from "shared/components/Icon";
import { COLORS, MEDIA_QUERIES, TRANSITIONS } from "shared/config/constants";

const Button = styled(BaseButton)({
  boxSizing: "border-box",
  textTransform: "uppercase",
  transition: TRANSITIONS.default,
  lineHeight: 0,
  height: "36px",
  padding: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: 0,
  "&.ant-btn-primary": {
    "&:disabled, &:disabled:hover": {
      backgroundColor: COLORS.button.primary.disabled.background,
      color: COLORS.button.primary.disabled.text,
    },
    "&:hover": {
      backgroundColor: COLORS.button.primary.hover.background,
      div: {
        borderLeftColor: COLORS.button.primary.hover.innerBorder,
      },
    },
  },
  "&.ant-btn-secondary": {
    border: `1px solid ${COLORS.button.secondary.default.border}`,
    "&:hover": {
      color: COLORS.button.secondary.hover.text,
      borderColor: COLORS.button.secondary.hover.border,
    },
  },
  "&.ant-btn-warning": {
    border: `1px solid ${COLORS.button.warning.default.border}`,
    color: COLORS.button.warning.default.text,
    "&:hover": {
      borderColor: COLORS.button.warning.hover.border,
      color: COLORS.button.warning.hover.text,
    },
  },
  "&.ant-btn-success": {
    backgroundColor: COLORS.button.success.default.background,
    color: COLORS.button.success.default.text,
    "&:hover": {
      backgroundColor: COLORS.button.success.hover.background,
      color: COLORS.button.success.default.text,
    },
  },
  "&.ant-btn-danger": {
    backgroundColor: COLORS.button.danger.default.background,
    color: COLORS.button.danger.default.text,
    "&:hover": {
      backgroundColor: COLORS.button.danger.hover.background,
    },
  },
  "&.ant-btn-sm": {
    fontSize: "14px",
  },
  [MEDIA_QUERIES.w.ltSm]: {
    fontSize: "14px",
  },
});

const Inner = styled("div")<{
  hide?: boolean;
  size?: string;
  borderLeft?: boolean;
}>((props) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "36px",
  padding: "11px 14px",
  transition: TRANSITIONS.default,
  ...(props.hide === true && {
    visibility: "hidden",
  }),
  ...(props.size === "sm" && {
    padding: "11px 10px",
  }),
  ...(props.size === "lg" && {
    padding: "11px 32px",
  }),
  ...(props.borderLeft && {
    borderLeft: `1px solid ${COLORS.button.primary.default.innerBorder}`,
  }),
}));

const PrefixIconContainer = styled("span")<{ hide?: boolean }>((props) => ({
  marginRight: "6px",
  ...(props.hide === true && {
    visibility: "hidden",
  }),
}));

const SuffixIconContainer = styled("span")<{ hide?: boolean }>((props) => ({
  marginLeft: "6px",
  ...(props.hide === true && {
    visibility: "hidden",
  }),
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Children = styled(({ loading, ...otherProps }) => (
  <span {...otherProps} />
))((props) => ({
  ...(props.loading && {
    visibility: "hidden",
  }),
}));

const LoadingSpinner = styled(Icon.LoadingSpinnerIcon)({
  position: "absolute",
  left: "50%",
  transform: "translateX(-50%)",
});

export {
  Button,
  Inner,
  PrefixIconContainer,
  SuffixIconContainer,
  Children,
  LoadingSpinner,
};
