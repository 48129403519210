import React from "react";

import { useFormikContext } from "formik";
import PropTypes from "prop-types";

import { CheckboxWithUnorderedList } from "shared/components/Form";
import Icon from "shared/components/Icon";
import Settings from "shared/components/Settings";
import { RegularText, SmallTitle } from "shared/components/Typography";
import { usePermissions } from "shared/hooks";
import { conditionalItem } from "shared/utils/array";

import ApplicationSetupHeader from "../Header";
import { StyledSkeleton as Skeleton } from "../styled";
import { getPrescreeningAssetsVerificationItem } from "../utils";

import {
  applicantPersonaIdentification,
  applicantArgyleVerification,
  incomeAndAssetVerification,
  assetVerificationWhenLinkedEmployment,
} from "./constants";

const PrescreeningForApplicants = ({ loading, disabled }) => {
  const { values, setFieldValue } = useFormikContext();
  const { isArgyleEnabled, isPersonaEnabled } = usePermissions();

  return (
    <Settings
      header={
        <Skeleton loading={loading}>
          <ApplicationSetupHeader
            title="Prequalification"
            subTitle="Please select the screening level you would like to require from new leads. At least one verification must be selected."
            description="over 18 years old"
          />
        </Skeleton>
      }
      nodes={[
        ...conditionalItem(isPersonaEnabled, {
          key: 10,
          component: (
            <Skeleton loading={loading}>
              <CheckboxWithUnorderedList
                id="applicant-persona-identification"
                label={
                  <>
                    Identity Verification&nbsp;
                    <SmallTitle light>(Snap)</SmallTitle>&nbsp;&nbsp;
                    <Icon.SnapPassed />
                  </>
                }
                onChange={(checked) => {
                  setFieldValue(applicantPersonaIdentification, checked);
                }}
                value={values[applicantPersonaIdentification]}
                disabled={disabled}
              />
            </Skeleton>
          ),
        }),
        ...conditionalItem(isArgyleEnabled, {
          key: 15,
          component: (
            <Skeleton loading={loading}>
              <CheckboxWithUnorderedList
                id="applicant-link-employment"
                label={
                  <>
                    Income Verification&nbsp;
                    <SmallTitle light>(Lynk)</SmallTitle>&nbsp;&nbsp;
                    <Icon.LynkSuccess />
                  </>
                }
                items={[
                  { label: "Employers", id: "employers" },
                  { label: "Income Amount", id: "income-amount" },
                ]}
                onChange={(checked) => {
                  setFieldValue(applicantArgyleVerification, checked);
                }}
                value={values[applicantArgyleVerification]}
                disabled={disabled}
              />
            </Skeleton>
          ),
        }),
        {
          key: 20,
          component: (
            <Skeleton loading={loading}>
              <CheckboxWithUnorderedList
                id="income-assets"
                label="Income &amp; Balances Verification"
                value={values[incomeAndAssetVerification]}
                items={[
                  getPrescreeningAssetsVerificationItem(
                    isArgyleEnabled,
                    values,
                    assetVerificationWhenLinkedEmployment,
                    disabled
                  ),
                  {
                    label: (
                      <>
                        Income Streams&nbsp;
                        <RegularText light>
                          (Will be shown if income verification is not enabled)
                        </RegularText>
                      </>
                    ),
                    id: "income-streams",
                  },
                ]}
                onChange={(checked) => {
                  setFieldValue(incomeAndAssetVerification, checked);
                }}
                onItemChildChange={(id, value) => {
                  setFieldValue(id, value);
                }}
                disabled={disabled}
              />
            </Skeleton>
          ),
        },
      ]}
    />
  );
};

PrescreeningForApplicants.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

PrescreeningForApplicants.defaultProps = {
  loading: false,
  disabled: false,
};

export default PrescreeningForApplicants;
