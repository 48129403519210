import React, { useEffect, useState } from "react";

import { Divider } from "antd";
import isBoolean from "lodash/isBoolean";
import { queryCache } from "react-query";

import {
  GET_DEAL_KEY,
  useGetYardiDealStatus,
  useStartYardiDealStatusSync,
} from "manager/hooks/api";
import { Deal } from "manager/pages/Deals/interfaces";
import { getIntegrationType } from "manager/utils/integration.util";
import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import { ActionLink } from "shared/components/Links";
import { Spacer } from "shared/components/Spacer/styled";
import { SmallText } from "shared/components/Typography";
import { COLORS, INTEGRATION_TYPES } from "shared/config/constants";
import { useActiveCompany } from "shared/hooks";
import { YardiLogoPopup } from "shared/images";
import { formatDateTime } from "shared/utils/ui";

import IntegrationMark from "./IntegrationMark";
import { INTEGRATIONS_MARK_SIZE } from "./IntegrationMark.confg";
import {
  getIconPerIntegration,
  ACCEPTED_INTEGRATIONS,
} from "./IntegrationMark.util";
import { DealExtraDetailsIntegrationMarkProps } from "./interfaces";
import {
  DealIntegrationExtraInfoContainer,
  DealIntegrationExtraInfo,
  PaidStatusContainer,
  DealIntegrationExtraInfoTrackingContent,
  DealIntegrationExtraInfoTrackingMessages,
  YardiDealSyncStatusTag,
} from "./styled";

const REFETCH_SYNC_STATUS_INTERVAL = 3000;

const YARDI_SYNC_INFO_FOR_STATUS = Object.freeze({
  SUCCESS: {
    label: "Successfully synced",
    icon: Icon.CheckGreenSmall,
    color: COLORS.lightGray2,
  }, // the push operation has been sent to yardi
  ERROR: {
    label: "Sync failed",
    icon: Icon.OrangeExclamationSmall,
    color: COLORS.backgroundLightOrange,
  }, // the push operation failed and it will be re-processed later by the scheduled task
  FATAL_ERROR: {
    label: "Manual fix needed",
    icon: Icon.OrangeExclamationSmall,
    color: COLORS.backgroundLightOrange,
  }, // the push operation failed in a way we can't recover without a manual fix
  AUTHENTICATION_ERROR: {
    label: "Authentication error",
    icon: Icon.OrangeExclamationSmall,
    color: COLORS.backgroundLightOrange,
  },
  SKIPPED: {
    label: "Sync skipped",
    icon: Icon.OrangeExclamationSmall,
    color: COLORS.backgroundLightOrange,
  },
  ABORTED: {
    label: "Sync aborted",
    icon: Icon.OrangeExclamationSmall,
    color: COLORS.backgroundLightOrange,
  },
});

const PaidStatus = ({ isPaid }: { isPaid: boolean }) => {
  const showStatus = isBoolean(isPaid);
  return showStatus ? (
    <PaidStatusContainer disabled={!isPaid}>
      {isPaid && (
        <>
          Paid <Icon.DollarSignIcon className="paid-icon" />
        </>
      )}
      {!isPaid && <>Not paid</>}
    </PaidStatusContainer>
  ) : (
    <>N/A</>
  );
};

const getYardiSyncOperationTag = (status: string) => {
  const { label, icon: TagIcon, color } = YARDI_SYNC_INFO_FOR_STATUS[status];
  return (
    <YardiDealSyncStatusTag backgroundColor={color} size="md">
      <TagIcon />
      <SmallText strong className="status-text">
        {label}
      </SmallText>
    </YardiDealSyncStatusTag>
  );
};

const getYardiPopoverContent = (deal: Deal) => {
  const { activeCompany } = useActiveCompany();
  const { yardiSuspended } = activeCompany || {};
  const { yardiPcode, yardiTcode } = deal;
  const isYardiConnected =
    deal?.unit?.yardiConnected || deal?.property?.yardiConnected;
  const isRentPaid = deal?.lease?.rentPaid;
  const isSecurityDepositPaid = deal?.lease?.securityDepositPaid;
  const isMatchedOnYardi = deal?.isMatchedOnYardi;
  const [yardiSyncTaskCode, setStatusSyncTaskCode] = useState();
  const [refetchSyncStatusInterval, setRefetchSyncStatusInterval] =
    useState<number>(undefined);

  const { yardiDealStatus, isLoading: isYardiDealStatusLoading } =
    useGetYardiDealStatus(
      deal?.id,
      isYardiConnected,
      yardiSyncTaskCode,
      refetchSyncStatusInterval
    );
  const isMessagesLoading =
    isYardiDealStatusLoading || refetchSyncStatusInterval;

  const { startYardiDealStatusSync } = useStartYardiDealStatusSync();

  const resyncYardiDealStatus = async () => {
    // @ts-ignore
    const startSyncResult = await startYardiDealStatusSync(deal?.id);
    setStatusSyncTaskCode(startSyncResult.pendingTaskCode);
    setRefetchSyncStatusInterval(REFETCH_SYNC_STATUS_INTERVAL);
  };

  useEffect(() => {
    if (yardiDealStatus?.pendingTaskStatus) {
      setRefetchSyncStatusInterval(undefined);
      // NOTE: goran - after successful sync refresh the deal too
      if (yardiDealStatus?.pendingTaskStatus === "SUCCESS") {
        queryCache.invalidateQueries(GET_DEAL_KEY);
      }
    }
  }, [yardiDealStatus]);

  return (
    <FlexContainer flexDirection="column">
      <p>
        <img src={YardiLogoPopup} alt="yardi-logo" />{" "}
        <SmallText extraStrong>INTEGRATIONS</SmallText>
      </p>
      <DealIntegrationExtraInfoContainer>
        <DealIntegrationExtraInfo>
          <div className="inline-extra-info">
            <SmallText strong>P-Code: {yardiPcode || "N/A"} </SmallText>
          </div>
          <Divider type="vertical" />
          <div className="inline-extra-info">
            <SmallText strong>T-Code: {yardiTcode || "N/A"} </SmallText>
          </div>
        </DealIntegrationExtraInfo>
        <DealIntegrationExtraInfo>
          <div className="inline-extra-info">
            <SmallText strong>
              1st Month Rent: <PaidStatus isPaid={isRentPaid} />
            </SmallText>
          </div>
          <Divider type="vertical" />
          <div className="inline-extra-info">
            <SmallText strong>
              Security D-t: <PaidStatus isPaid={isSecurityDepositPaid} />
            </SmallText>
          </div>
        </DealIntegrationExtraInfo>
        {isMatchedOnYardi && (
          <DealIntegrationExtraInfo>
            <div className="inline-extra-info">
              <SmallText strong>
                Guest Card matched with an existing one
              </SmallText>
            </div>
          </DealIntegrationExtraInfo>
        )}
        {!isYardiDealStatusLoading && (
          <>
            <Spacer />
            {yardiDealStatus?.status && yardiDealStatus?.updatedAt && (
              <FlexContainer alignItems="center" justifyContent="space-between">
                {getYardiSyncOperationTag(yardiDealStatus.status)}
                <SmallText>
                  Last updated:
                  <br />
                  {formatDateTime(yardiDealStatus.updatedAt)}
                </SmallText>
              </FlexContainer>
            )}
            <DealIntegrationExtraInfoTrackingContent>
              {yardiDealStatus?.messages?.length > 0 && (
                <DealIntegrationExtraInfoTrackingMessages>
                  <ul>
                    {yardiDealStatus.messages.map((statusItem) => (
                      <li key={statusItem.id} className="status-message">
                        <SmallText>{statusItem.message}</SmallText>
                      </li>
                    ))}
                  </ul>
                </DealIntegrationExtraInfoTrackingMessages>
              )}
              {!yardiSuspended && (
                <ActionLink
                  data-testid="yardi-deal-status-resync"
                  Icon={
                    isMessagesLoading
                      ? Icon.RefreshRotateIconSmall
                      : Icon.RefreshIconSmall
                  }
                  disabled={isMessagesLoading}
                  onClick={resyncYardiDealStatus}
                >
                  {isMessagesLoading ? "RESYNCING" : "RESYNC"}
                </ActionLink>
              )}
            </DealIntegrationExtraInfoTrackingContent>
          </>
        )}
      </DealIntegrationExtraInfoContainer>
    </FlexContainer>
  );
};

const DealExtraDetailsIntegrationMark = ({
  property,
  unit,
  deal,
  lg,
  dark,
  markRef,
}: DealExtraDetailsIntegrationMarkProps) => {
  const size = lg ? INTEGRATIONS_MARK_SIZE.lg : INTEGRATIONS_MARK_SIZE.sm;
  let popoverLabel = null;
  const integrationType = getIntegrationType(unit || property);

  if (integrationType === INTEGRATION_TYPES.YARDI) {
    popoverLabel = getYardiPopoverContent(deal);
  }

  if (ACCEPTED_INTEGRATIONS.has(integrationType)) {
    const IntegrationIcon = getIconPerIntegration(
      integrationType,
      size,
      false,
      dark
    );
    return (
      <IntegrationMark
        markRef={markRef}
        IntegrationIcon={IntegrationIcon}
        popoverLabel={popoverLabel}
        size={size}
        popoverStyle={{ maxWidth: "400px" }}
        popoverClassName="custom-width-popover"
      />
    );
  }

  return null;
};

export default DealExtraDetailsIntegrationMark;
