import React from "react";

import { getIntegrationType } from "manager/utils/integration.util";
import { INTEGRATION_TYPES } from "shared/config/constants";

import IntegrationMark from "./IntegrationMark";
import { INTEGRATIONS_MARK_SIZE } from "./IntegrationMark.confg";
import {
  getIconPerIntegration,
  ACCEPTED_INTEGRATIONS,
} from "./IntegrationMark.util";
import { UnitIntegrationMarkProps } from "./interfaces";

const YARDI_CONNECTED_UNIT_MSG = "Current unit is connected to Yardi";
const YARDI_NOT_CONNECTED_UNIT_MSG = "Current unit is not connected to Yardi";
const ENTRATA_CONNECTED_UNIT_MSG = "Current unit is connected to Entrata";
const ENTRATA_NOT_CONNECTED_UNIT_MSG =
  "Current unit is not connected to Entrata";
const REAL_PAGE_CONNECTED_UNIT_MSG = "Current unit is connected to Real Page";
const REAL_PAGE_NOT_CONNECTED_UNIT_MSG =
  "Current unit is not connected to Real Page";

const UnitIntegrationMark = ({
  property,
  unit,
  lg = false,
  dark = false,
}: UnitIntegrationMarkProps) => {
  const size = lg ? INTEGRATIONS_MARK_SIZE.lg : INTEGRATIONS_MARK_SIZE.sm;
  let disabledIcon = false;
  let popoverLabel = null;
  const integrationType = getIntegrationType(property || unit);

  if (integrationType === INTEGRATION_TYPES.YARDI) {
    popoverLabel = YARDI_CONNECTED_UNIT_MSG;
    if (!unit.pmsConnected) {
      disabledIcon = true;
      popoverLabel = YARDI_NOT_CONNECTED_UNIT_MSG;
    }
  } else if (integrationType === INTEGRATION_TYPES.ENTRATA) {
    popoverLabel = ENTRATA_CONNECTED_UNIT_MSG;
    if (!unit.pmsConnected) {
      disabledIcon = true;
      popoverLabel = ENTRATA_NOT_CONNECTED_UNIT_MSG;
    }
  } else if (integrationType === INTEGRATION_TYPES.REAL_PAGE) {
    popoverLabel = REAL_PAGE_CONNECTED_UNIT_MSG;
    if (!unit.pmsConnected) {
      disabledIcon = true;
      popoverLabel = REAL_PAGE_NOT_CONNECTED_UNIT_MSG;
    }
  }

  if (ACCEPTED_INTEGRATIONS.has(integrationType)) {
    const IntegrationIcon = getIconPerIntegration(
      integrationType,
      size,
      disabledIcon,
      dark
    );
    return IntegrationIcon ? (
      <IntegrationMark
        IntegrationIcon={IntegrationIcon}
        popoverLabel={popoverLabel}
        size={size}
      />
    ) : null;
  }

  return null;
};

export default UnitIntegrationMark;
