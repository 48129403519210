import { APPLICATION_STEPS as STEPS } from 'shared/config/constants'

export { STEPS };

export const MODALS = {
  addApplicants: "ADD_APPLICANTS",
  addGuarantors: "ADD_GUARANTORS",
  addOccupants: "ADD_OCCUPANTS",
  applicationSubmitted: "APPLICATION_SUBMITTED",
  applicationGroupIndividuals: "APPLICATION_GROUP_INDIVIDUALS",
  contactManager: "CONTACT_MANAGER",
  privacyPolicy: "PRIVACY_POLICY",
  editApplication: "EDIT_APPLICATION",
};

export const APPLICATION_ATTACHMENT_TYPES = {
  applicant: "AP",
  unverifiedIncome: "UI",
  unverifiedBackground: "UB",
};

export const STEP_GROUPS = new Set([
  STEPS.selectTypeAndConfirm,
  STEPS.verifications,
  STEPS.screening,
  STEPS.payment,
  STEPS.initialization,
]);

export const REAL_STEPS = new Set(
  Object.values(STEPS).filter((step) => !STEP_GROUPS.has(step))
);

export const MAX_VERIFICATION_PERCENT = 100;

export const TU_PAGES = {
  enterSSN: "EnterSSN",
  checkProfile: "CheckProfile",
  contactProvider: "ContactProvider",
  exam: "Exam",
  reportGenerating: "ReportGenerating",
  reportUnavailable: "ReportUnavailable",
  reportReady: "ReportReady",
  contactVero: "ContactVero",
};

export const INCOME_ASSETS_VERIFICATION_TYPE = Object.freeze({
  INCOME_AND_ASSETS: "INCOME_AND_ASSETS",
  // NOTE: status is INCOME_ONLY_AUTO_VERIFIED, we should only show the summary
  INCOME_ONLY_AUTO_VERIFIED: "INCOME_ONLY_AUTO_VERIFIED",
  ASSETS_ONLY: "ASSETS_ONLY",
});

export const ARGYLE_SYNC_STATUS = {
  NOT_SYNCED: "NOT_SYNCED",
  SHOULD_SYNC: "SHOULD_SYNC",
  SYNC_IN_PROGRESS: "SYNC_IN_PROGRESS",
  SYNC_COMPLETED: "SYNC_COMPLETED",
  SYNC_FAILED: "SYNC_FAILED",
  OCR_PROCESSING: "OCR_PROCESSING",
};

export const ID_VERIFICATION_STATUS = {
  NOT_STARTED: "NOT_STARTED",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
  SKIPPED: "SKIPPED",
  APPROVED: "APPROVED",
};

export const RENTER_GLOBAL_CONFIG_KEYS = Object.freeze({
  identityVerificationConfig: "identityVerificationConfig",
  linkEmploymentConfig: "linkEmploymentConfig",
  selectAccountsConfig: "selectAccountsConfig",
  submitApplicationConfig: "submitApplicationConfig",
  applicantPaymentModalConfig: "applicantPaymentModalConfig",
  applicantPaymentStepConfig: "applicantPaymentStepConfig",
  incomeAndAssetsSummaryConfig: "incomeAndAssetsSummaryConfig",
});

export const TU_PHONE_NUMBER = Object.freeze({
  TU_SMARTMOVE: "866-775-0961",
  TU_SHAREABLE: "833-458-6338",
});
