import React from "react";

import { VMarkGrayBackground, SurveyBackgroundImg } from "shared/images";

import { BackgroundContent } from "./styled";

const SurveyBackground = () => (
  <BackgroundContent>
    <img src={VMarkGrayBackground} alt="bg" />
    <img src={SurveyBackgroundImg} alt="bg" />
  </BackgroundContent>
);

export default SurveyBackground;
