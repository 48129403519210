import React, { Dispatch, SetStateAction, useEffect } from "react";

import UnitInviteApplicantsModal from "manager/components/Modal/UnitInviteApplicantsModal";
import { Unit } from "manager/interfaces/api/unit";
import useActiveCompany from "shared/hooks/useActiveCompany";
import useModal from "shared/hooks/useModal";

import {
  DefaultPagination,
  InviteApplicantsParams,
  Sorter,
} from "../interfaces";

import UnitsTable from "./UnitsTable";

interface UnitsTableContainerProps {
  units: Unit[];
  refetchPropertiesDropdownData: () => void;
  unitsCount: number;
  loading: boolean;
  hasInputValue: boolean;
  pagination: DefaultPagination;
  setPagination: Dispatch<SetStateAction<DefaultPagination>>;
  onUnitsTableChange: (
    tablePagination: DefaultPagination,
    _tableFilters: object[],
    sorter: Sorter
  ) => void;
}

const UnitsTableContainer = ({
  units,
  refetchPropertiesDropdownData,
  unitsCount,
  loading,
  hasInputValue,
  pagination,
  setPagination,
  onUnitsTableChange,
}: UnitsTableContainerProps) => {
  const { openModalDialog } = useModal();
  const { activeCompany } = useActiveCompany();
  const { showOnlyUnitsNotAttachedToAnotherActiveDeal } = activeCompany || {};
  const inviteApplicants = ({
    unitId,
    unitName,
    unitProvider,
    unitPmsConnected,
    propertyName,
  }: InviteApplicantsParams) =>
    openModalDialog(UnitInviteApplicantsModal, {
      unit: {
        id: unitId,
        name: unitName,
        provider: unitProvider,
        pmsConnected: unitPmsConnected,
      },
      property: {
        name: propertyName,
      },
    });

  useEffect(() => {
    refetchPropertiesDropdownData();
  }, []);

  return (
    <UnitsTable
      units={units}
      loading={loading}
      unitsCount={unitsCount}
      inviteApplicants={inviteApplicants}
      hasInputValue={hasInputValue}
      pagination={pagination}
      setPagination={setPagination}
      onUnitsTableChange={onUnitsTableChange}
      showOnlyUnitsNotAttachedToAnotherActiveDeal={
        showOnlyUnitsNotAttachedToAnotherActiveDeal
      }
    />
  );
};

export default UnitsTableContainer;
