import React from "react";

import { useApplicantInviteApplicants } from "renter/hooks/api";
import { APPLICANT_TYPES } from "shared/config/constants";

import useModal from "shared/hooks/useModal";

import { ApplicantInviteSubmit } from "../InviteApplicantsModal/interfaces";

import AddOccupantsModal from "./AddOccupantsModal";
import { AddOccupantsModalContainerProps } from "./interfaces";

const AddOccupantsModalContainer = (
  context: AddOccupantsModalContainerProps
) => {
  const { dealId } = context;

  const { closeActiveModalDialog } = useModal();

  const { applicantInviteApplicants } = useApplicantInviteApplicants(dealId);
  const submit = ({ occupants, onSuccess, onFail }: ApplicantInviteSubmit) => {
    applicantInviteApplicants(
      {
        applicantType: APPLICANT_TYPES.occupant,
        applicants: occupants.map((occupant) => ({
          ...occupant,
          applicantType: APPLICANT_TYPES.occupant,
        })),
      },
      { onSuccess, onError: onFail }
    );
  };

  return (
    <AddOccupantsModal
      context={context}
      submit={submit}
      closeActiveModalDialog={closeActiveModalDialog}
    />
  );
};

export default AddOccupantsModalContainer;
