import { PersonaIdClass } from "shared/config/constants";

export interface Applicant {
  id: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  email: string;
  phone?: string;
  type: ApplicantType;
  isStudent: boolean;
  isForeigner: boolean;
  isSelfEmployed: boolean;
  income?: ApplicantIncomeSummary;
  balances?: ApplicantBalance;
  credit?: ApplicantCreditScore;
  criminal?: ApplicantCriminalSummary;
  employment?: ApplicantEmployment;
  eviction?: ApplicantEviction;
  identity?: ApplicantIdentity;
  verificationOfResidency?: ApplicantVor;
}

export interface ApplicantBalance {
  verified?: boolean;
  skipped?: boolean;
  skippedReason?: string;
  availableCash?: number;
  currentBalance?: number;
  bankAccounts?: BankAccount[];
}

export interface BankAccount {
  accountType: string;
  availableBalance?: number;
  currentBalance: number;
  institutionName: string;
  name: string;
}

export interface ApplicantCreditScore {
  skipped?: boolean;
  skippedReason?: string;
  creditScore?: number;
  latePayment30?: number;
  latePayment60?: number;
  latePayment90?: number;
  creditBalance?: number;
  creditLimit?: number;
  collectionAccounts?: number;
  collectionTotal?: number;
}

export interface ApplicantCriminalSummary {
  records?: number;
  highestSeverity?: string;
  lastRecordDate?: Date;
  lastRecordState?: string;
}

export interface ApplicantEmployment {
  enabled?: boolean;
  jobTitle?: string;
  employed?: boolean;
  company?: string;
  start?: Date;
  end?: Date;
  skipped?: boolean;
  verificationTypes?: VerificationType[];
}
export interface ApplicantEviction {
  enabled?: boolean;
  skipped?: boolean;
  records?: number;
  judgmentAmount?: number;
  lastRecord?: Date;
  lastRecordState?: string;
}

export interface ApplicantIdentity {
  enabled?: boolean;
  identityType?: string;
  documentType?: PersonaIdClass;
  verified?: boolean;
  identityNumber?: string;
  country?: string;
  expires?: Date;
}

export interface ApplicantIncomeSummary {
  totalIncome?: number;
  percentVerified?: number;
  skipped?: boolean;
  skippedReason?: string;
  primary?: Income;
  stated?: number;
}

export interface Income {
  income: number;
  name: string;
  status: string;
  lastPayment: Date;
  frequency: string;
}

export interface ApplicantVor {
  enabled?: boolean;
  score?: number;
  status?: VorStatus;
  outOf?: number;
  landlordName?: string;
  landlordEmail?: string;
  landlordPhone?: string;
  submission?: Date;
  lowestScore?: number;
}

export enum VorStatus {
  waiting = "waiting",
  notified = "notified",
  submitted = "submitted",
  verified = "verified",
}

export enum ApplicantType {
  unknown = "unknown",
  applicant = "applicant",
  guarantor = "guarantor",
  occupant = "occupant",
  dependent = "dependent",
}

export enum VerificationType {
  LINKED_EMPLOYMENT = "LINKED_EMPLOYMENT",
  DOCUMENT_UPLOADED = "DOCUMENT_UPLOADED",
  LINK_BANK_ACCOUNT = "LINK_BANK_ACCOUNT",
  MANUAL_UPLOAD = "MANUAL_UPLOAD",
  UNEMPLOYED = "UNEMPLOYED",
}
