import camelCase from "lodash/camelCase";
import isObjectLike from "lodash/isObjectLike";
import mapKeys from "lodash/mapKeys";
import mapValues from "lodash/mapValues";
import snakeCase from "lodash/snakeCase";

export const deferred = (fn) => setTimeout(fn, 0);

export const generateId = (prefix) =>
  `${prefix ? `${prefix}_` : ""}${Date.now()}_${Math.random()}`;

/**
 * Checks if a value is empty.
 */
export const isEmptyValue = (value) => {
  if (typeof value === "number") {
    return false;
  }
  if (typeof value === "string") {
    return value.length === 0;
  }
  return !value;
};

export const encodeObjectToBase64 = (inputObject) => {
  const strObject = JSON.stringify(inputObject);
  return btoa(strObject);
};

export const decodeBase64ToObject = (inputBase64) => {
  const decoded = atob(inputBase64);
  return JSON.parse(decoded);
};

export const encodeStringToBase64 = (inputString) => {
  return btoa(inputString);
};

export const decodeBase64ToString = (inputBase64) => {
  return atob(inputBase64);
};

const mapKeysDeepInner = (obj, cb, mapOfPastObjects) => {
  if (Array.isArray(obj)) {
    return obj.map((item) => mapKeysDeepInner(item, cb, mapOfPastObjects));
  }
  if (!isObjectLike(obj)) {
    return obj;
  }

  // NOTE: handling circular reference
  if (mapOfPastObjects.has(obj)) {
    return mapOfPastObjects.get(obj);
  }

  const result = mapKeys(obj, cb);
  mapOfPastObjects.set(obj, result);
  const objWithConvertedValues = mapValues(result, (value) =>
    mapKeysDeepInner(value, cb, mapOfPastObjects)
  );
  return Object.assign(result, objWithConvertedValues);
};

export const mapKeysDeep = (obj, cb) => {
  const mapOfPastObjects = new Map();
  return mapKeysDeepInner(obj, cb, mapOfPastObjects);
};

export const toSnakeCaseKeys = (obj) =>
  mapKeysDeep(obj, (_v, k) => snakeCase(k));
export const toCamelCaseKeys = (obj) =>
  mapKeysDeep(obj, (_v, k) => camelCase(k));

export function getFeeManagementSubtitle(o, d) {
  if (o?.feesDisabled) {
    return "All fees are disabled";
  }

  if (o?.disableFeeManagement) {
    return "Your fees are managed by your external provider";
  }

  return d;
}
