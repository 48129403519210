import React from "react";

import { FormikHelpers } from "formik";
import { useHistory } from "react-router-dom";

import { useCreateProperty } from "manager/hooks/api";
import { Property } from "manager/interfaces/api";
import useModal from "shared/hooks/useModal";
import { onDialogSubmitError } from "shared/utils/api";

import CreatePropertyModal from "./CreatePropertyModal";

interface CreatePropertyModalContainerProps {
  initialValues?: Property;
  afterPropertyCreate?: (property: Property) => Promise<void>;
  subtitle?: string;
}

const CreatePropertyModalContainer = ({
  initialValues,
  afterPropertyCreate,
  subtitle,
}: CreatePropertyModalContainerProps) => {
  const { closeActiveModalDialog } = useModal();
  const { createProperty } = useCreateProperty();
  const history = useHistory();

  const submit = (values: Property, formikApi: FormikHelpers<Property>) => {
    const onSuccess = async (newProperty: Property) => {
      closeActiveModalDialog();
      if (afterPropertyCreate) {
        await afterPropertyCreate(newProperty);
      }
    };

    createProperty(values, {
      onSuccess,
      onError: onDialogSubmitError(
        formikApi,
        history,
        "Failed to create the property."
      ),
    });
  };

  const props = {
    initialValues,
    subtitle,
    submit,
  };

  return <CreatePropertyModal {...props} />;
};

export default CreatePropertyModalContainer;
