import SHARED_ROUTES from "shared/config/routes";

const ROUTES = Object.freeze({
  ...SHARED_ROUTES,
  documents: "/documents",
  draftDocuments: "/documents/drafts",
  documentConstructor: "/constructor",
  draftConstructor: "/constructor/:id",
});

export const GROUPED_ROUTES_KEYS = Object.freeze({
  DOCUMENTS: "DOCUMENTS",
});

export const GROUPED_ROUTES = Object.freeze({
  [GROUPED_ROUTES_KEYS.DOCUMENTS]: [
    ROUTES.documents,
    ROUTES.draftDocuments,
    ROUTES.documentConstructor,
    ROUTES.draftConstructor,
  ],
});

export default ROUTES;
