import { Credit, CreditScoreSummaryResponse } from "./interfaces";

export const mapCredit = (
  creditSummary?: CreditScoreSummaryResponse,
  skipped?: boolean
): Credit => {
  return {
    skipped,
    skippedReason:
      "Applicant skipped Credit & Background check. No data to display.",
    creditScore: creditSummary?.score,
    latePayment30: creditSummary?.accounts.reduce(
      (sum, cur) => sum + cur.times30DaysLate,
      0
    ),
    latePayment60: creditSummary?.accounts.reduce(
      (sum, cur) => sum + cur.times60DaysLate,
      0
    ),
    latePayment90: creditSummary?.accounts.reduce(
      (sum, cur) => sum + cur.times90DaysLate,
      0
    ),
    creditBalance: creditSummary?.creditBalance ?? 0,
    creditLimit: creditSummary?.creditLimit ?? 0,
    collectionAccounts: creditSummary?.collections.length,
    collectionTotal: creditSummary?.totalCollectionsAmount,
  };
};
