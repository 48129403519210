import React from "react";

import { useLocation } from "react-router-dom";

import SetPasswordLinkSentPage from "./SetPasswordLinkSentPage";

const SetPasswordLinkSentPageContainer = () => {
  const { state } = useLocation<{ email: string }>();
  const { email } = state ?? {};

  return <SetPasswordLinkSentPage email={email} />;
};

export default SetPasswordLinkSentPageContainer;
