import React, { useState, useEffect, useCallback } from "react";

import { Formik } from "formik";

import { Modal } from "shared/components/Modal";
import { isSubstrInStr } from "shared/utils/ui";

import { validationSchema } from "./config";
import CurrentSelection from "./CurrentSelection";
import { Company, UnassignDocumentModalProps } from "./interfaces";
import SelectCompanyForm from "./SelectCompanyForm";
import { ModalBody, Note } from "./styled";

const UnassignDocumentModal = ({
  document,
  companies,
  isLoading,
  unassignDocument,
}: UnassignDocumentModalProps) => {
  const [filteredCompanies, setFilteredCompanies] = useState<Company[]>([]);
  const [searchingValue, setSearchingValue] = useState<string>("");
  const [selectedCompany, setSelectedCompany] = useState<Company | undefined>();

  const handleSubmit = useCallback(
    (values) =>
      unassignDocument({
        company: companies.find((company) => company.id === values.company),
        document,
      }),
    [companies, document]
  );

  useEffect(() => {
    setFilteredCompanies(
      companies?.filter((company) =>
        isSubstrInStr(company.name, searchingValue)
      )
    );
  }, [searchingValue, companies]);

  return (
    <Formik
      initialValues={{ company: 0 }}
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={validationSchema}
      validateOnMount
    >
      {({
        isValid,
        submitForm,
        isSubmitting,
        dirty,
        setFieldValue,
        values,
      }) => (
        <Modal
          title="Unassign document from company"
          subtitle={`Document: ${document?.name}`}
          submitButtonLabel="UNASSIGN"
          submitButtonDisabled={!isValid || isSubmitting || !dirty}
          submit={submitForm}
          submitting={isSubmitting}
          width="auto"
          style={{ maxWidth: 946 }}
          closeOnSubmit={false}
        >
          <ModalBody>
            <Note>
              Select company from which you want this document to be unassigned
            </Note>

            <SelectCompanyForm
              isLoading={isLoading}
              companies={filteredCompanies}
              setSearchingValue={setSearchingValue}
              setSelectedCompany={setSelectedCompany}
              searchingValue={searchingValue}
              setFieldValue={setFieldValue}
              selectedCompany={values.company}
            />

            <CurrentSelection selectedCompany={selectedCompany} />
          </ModalBody>
        </Modal>
      )}
    </Formik>
  );
};

export default UnassignDocumentModal;
