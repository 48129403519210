import React from "react";

import { linkedSourcesPropTypes } from "./sources.config";
import Sources from "./SourcesContainer";

const LeadSources = ({ linkedData }) => {
  return (
    <Sources
      title="Lead Sources"
      subTitle="When inviting an applicant to apply, leasing managers will select a lead source within VERO. The lead source will define how the applicant first came in contact with the leasing manager. Please confirm and link all VERO lead sources to the correct External Provider lead source."
      createTitle="Create New Lead Source"
      editTitle="Edit Lead Source"
      createEditInputDescription="Enter Lead Source Name"
      createEditInputPlaceholder="Source Name"
      deleteTitle="Remove Lead Source?"
      deleteDescription="Removing this lead source will impact the options available when inviting an applicant. Are you sure you would like to remove it?"
      sourceType="Lead Source"
      linkedData={linkedData}
    />
  );
};

LeadSources.propTypes = {
  linkedData: linkedSourcesPropTypes.isRequired,
};

export default LeadSources;
