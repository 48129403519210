import axios from "axios";

import ROUTES from "manager/config/apiRoutes";
import { SurveyModel, SurveyQuestionModel } from "shared/interfaces/api";
import { transformRoute } from "shared/utils/routing";

// eslint-disable-next-line import/no-cycle
import { API_URL } from "./api";

const api = {
  getSurvey: (id: number, params) =>
    axios
      .get<SurveyModel>(
        transformRoute(`${API_URL}${ROUTES.dealSetupSurvey}`, {
          id,
        }),
        {
          params: {
            survey_type: params?.surveyType,
          },
        }
      )
      .then((response) => response.data),
  createSurvey: (survey: SurveyModel) =>
    axios
      .post<SurveyModel>(`${API_URL}${ROUTES.survey}`, survey)
      .then(({ data }) => data),
  createSurveyQuestion: ({ surveyId, ...question }: SurveyQuestionModel) =>
    axios
      .post<SurveyQuestionModel>(
        `${API_URL}${transformRoute(ROUTES.surveyQuestions, { id: surveyId })}`,
        question
      )
      .then(({ data }) => data),
  updateSurveyQuestion: ({
    id,
    surveyId,
    ...question
  }: Partial<SurveyQuestionModel>) =>
    axios
      .put<SurveyQuestionModel>(
        `${API_URL}${transformRoute(ROUTES.surveyQuestion, {
          surveyId,
          questionId: id,
        })}`,
        question
      )
      .then(({ data }) => data),
  deleteSurveyQuestion: ({ surveyId, questionId }) =>
    axios.delete(
      `${API_URL}${transformRoute(ROUTES.surveyQuestion, {
        surveyId,
        questionId,
      })}`
    ),
  updateSurveyQuestionOrdering: ({ surveyId, ordering }) =>
    axios.patch(
      `${API_URL}${transformRoute(ROUTES.updateSurveyQuestionsOrdering, {
        surveyId,
      })}`,
      ordering
    ),
  requestSurvey: (id: number) =>
    axios.post<SurveyModel>(
      transformRoute(`${API_URL}${ROUTES.requestSurvey}`, { id })
    ),
};

export default api;
