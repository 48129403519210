import React, { Key } from "react";

import * as Yup from "yup";

import { Select, SelectProps } from "shared/components/Form/Select";

export type CountrySelectProps = SelectProps & {
  countries: {
    id: Key;
    value: string;
  }[];
  loading: boolean;
};

export const CountrySelect = Object.assign(
  ({ countries, loading, ...props }: CountrySelectProps) => (
    <Select loading={loading} data-testid="country-select" {...props}>
      {countries.map((country) => (
        <Select.Option key={country.id}>{country.value}</Select.Option>
      ))}
    </Select>
  ),
  {
    schema: Yup.string(),
  }
);
