import styled from "@emotion/styled";

import { Modal } from "shared/components/Modal";
import { MEDIA_QUERIES } from "shared/config/constants";

const Subtitle = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 17px;
  font-weight: 500;
  line-height: 22px;
  ${MEDIA_QUERIES.w.ltMd} {
    flex-direction: column;
  }
`;

const HeaderIcon = styled.div`
  width: 58px;
  margin-bottom: 10px;
`;

const Description = styled.ul`
  margin-bottom: 0;
  color: #2d2943;
  ${MEDIA_QUERIES.w.gteSm} {
    margin-left: 50px;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

const Property = styled.li`
  margin-bottom: 15px;
`;

const ModalBody = styled(Modal.Body)`
  padding: 25px 48px 30px 48px;
`;

export { Subtitle, HeaderIcon, Description, ModalBody, Property };
