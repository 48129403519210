import React from "react";

import TablePage from "shared/templates/TablePage";

import DownloadCenterTable from "./DownloadCenterTable";

const DownloadCenter = (props) => {
  return (
    <TablePage
      title="Download Center"
      {...props}
      tables={[
        {
          node: <DownloadCenterTable />,
        },
      ]}
    />
  );
};

export default DownloadCenter;
