import React from "react";

import { FieldArray, useFormikContext } from "formik";

import { AdditionalQuestionModel } from "manager/interfaces/api/additionalQuestion";
import { Input, Select, VeroFormField } from "shared/components/Form";
import Icon from "shared/components/Icon";
import { ActionLink } from "shared/components/Links";
import Spacer from "shared/components/Spacer";
import { SmallTitle } from "shared/components/Typography";
import { ANSWER_QUESTION_TYPES } from "shared/config/constants";
import OptionItem from "shared/interfaces/api/optionItem";

import { ADDITIONAL_QUESTION_FIELDS } from "./config";
import { InputContainer, StyledSelect, Col, Row } from "./styled";

const SingleChoiceOptions = () => {
  const { values } = useFormikContext<AdditionalQuestionModel>();

  return (
    <>
      <SmallTitle>Enter response options</SmallTitle>
      <Spacer size={Spacer.SIZES.sm} />
      <FieldArray
        name="options"
        render={(arrayHelpers) => (
          <>
            <Row gutter={[20, 25]}>
              {values.options?.map((_option, index) => {
                const answerFieldPath = `${ADDITIONAL_QUESTION_FIELDS.options}.${index}.${ADDITIONAL_QUESTION_FIELDS.answer}`;
                const key = `${ADDITIONAL_QUESTION_FIELDS.options}-${index}`;

                return (
                  <Col md={{ span: 11 }} key={key}>
                    <InputContainer>
                      <div>
                        {/* @ts-ignore */}
                        <VeroFormField
                          as={Input}
                          id={answerFieldPath}
                          name={answerFieldPath}
                          placeholder={`Option ${index + 1}`}
                          data-testid="option"
                          className="option-input"
                        />
                      </div>
                      {Boolean(index) && (
                        <Icon.HoverTracker
                          Icon={Icon.DeleteSmallIcon}
                          disabled={!index}
                          onClick={() => arrayHelpers.remove(index)}
                          className="option-delete-icon"
                        />
                      )}
                    </InputContainer>
                  </Col>
                );
              })}
            </Row>
            <Spacer size={Spacer.SIZES.md} />
            <ActionLink
              plus
              onClick={() => {
                arrayHelpers.push({ answer: "" });
              }}
              data-testid="add-item-link"
            >
              Option
            </ActionLink>
          </>
        )}
      />
    </>
  );
};

interface AdditionalQuestionInnerFormElementsProps {
  onAnswerTypeChange: Function;
  answerTypeOptions: OptionItem[];
  isAnswerTypeOptionsLoading: boolean;
}

const AdditionalQuestionInnerFormElements = ({
  onAnswerTypeChange,
  answerTypeOptions,
  isAnswerTypeOptionsLoading,
}: AdditionalQuestionInnerFormElementsProps) => {
  const { values, setFieldValue } = useFormikContext<AdditionalQuestionModel>();

  return (
    <>
      {/* @ts-ignore */}
      <VeroFormField
        as={Input}
        required
        data-testid="new-item-input"
        id={ADDITIONAL_QUESTION_FIELDS.questionName}
        name={ADDITIONAL_QUESTION_FIELDS.questionName}
        placeholder="Enter the text of your question here..."
      />
      <Spacer size={Spacer.SIZES.sm} />
      {/* @ts-ignore */}
      <VeroFormField
        as={StyledSelect}
        required
        id={ADDITIONAL_QUESTION_FIELDS.answerType}
        name={ADDITIONAL_QUESTION_FIELDS.answerType}
        label="Question Type"
        loading={isAnswerTypeOptionsLoading}
        data-testid="answer-type-select"
        onChange={(value) => onAnswerTypeChange(value, setFieldValue)}
      >
        {answerTypeOptions?.map((item, index) => (
          <Select.Option
            data-testid={`answer-type-${index}`}
            key={item.id}
            value={item.id}
          >
            {item.value}
          </Select.Option>
        ))}
      </VeroFormField>

      <Spacer size={Spacer.SIZES.sm} />

      {/* If single choice is selected render options */}
      {values[ADDITIONAL_QUESTION_FIELDS.answerType] ===
        ANSWER_QUESTION_TYPES.singleChoice && <SingleChoiceOptions />}
    </>
  );
};

export default AdditionalQuestionInnerFormElements;
