import { LocalStorageCollectionManager } from "shared/utils/localStorage";

const SIGNATURE_FIELDS_STORAGE = "SIGNATURE_FIELDS_STORAGE";

const signatureFieldsStorage = new LocalStorageCollectionManager({
  storageKey: SIGNATURE_FIELDS_STORAGE,
});

const getCheckboxGroupName = ({
  name,
  min,
  allowed,
}: {
  name: string;
  min: number;
  allowed: boolean;
}) => `{{applicant_1_checkbox_group_definition|${name}|${min}|${allowed}}}`;

const getCheckboxGroupItemName = ({ name }: { name: string }) =>
  `{{checkbox_group_item|${name}}}`;

export {
  signatureFieldsStorage,
  getCheckboxGroupName,
  getCheckboxGroupItemName,
};
