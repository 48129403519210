import styled from "@emotion/styled";

import Icon from "shared/components/Icon";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

const BodyText = styled("p")({
  maxHeight: "500px",
  overflowY: "scroll",
  padding: "50px 30px 50px 30px",
  fontSize: "15px",
  border: `1px solid ${COLORS.borderColorBase}`,
  [MEDIA_QUERIES.w.ltSm]: {
    borderLeft: 0,
    borderRight: 0,
    padding: "20px",
  },
});

const TextHeader = styled("p")({
  fontWeight: 500,
  fontSize: "16px",
  color: COLORS.primary,
});

const SubmittedHeader = styled("div")({});

const SubmittedTitle = styled("h2")({
  display: "block",
  margin: "0 auto",
  textAlign: "center",
  fontSize: "22px",
  fontWeight: 400,
  maxWidth: "432px",
  lineHeight: 1.5,
  [MEDIA_QUERIES.w.ltSm]: {
    margin: 0,
    textAlign: "left",
    maxWidth: "100%",
  },
});

const SubmittedSubHeader = styled("div")({
  textAlign: "center",
  fontSize: "17px",
  fontWeight: 400,
  marginTop: "24px",
  [MEDIA_QUERIES.w.ltSm]: {
    textAlign: "left",
  },
});

const SubmittedBody = styled("div")({
  textAlign: "center",
  maxHeight: "500px",
  padding: "24px",
  maxWidth: "700px",
  marginLeft: "auto",
  marginRight: "auto",
  marginBottom: "32px",
  border: `1px solid ${COLORS.borderColorBase}`,
  fontWeight: 400,
  "& > p:last-of-type": {
    marginBottom: 0,
  },
  backgroundColor: COLORS.background,
  color: COLORS.gray2,
  [MEDIA_QUERIES.w.ltSm]: {
    padding: "24px 20px",
    marginLeft: 0,
    marginRight: 0,
    textAlign: "left",
    borderLeft: 0,
    borderRight: 0,
  },
});

const ContactText = styled("p")({
  marginTop: "40px",
  fontWeight: 400,
  color: COLORS.gray2,
  [MEDIA_QUERIES.w.ltSm]: {
    textAlign: "left",
    marginTop: 0,
    marginBottom: "32px",
  },
});

const Logo = styled("img")({
  display: "block",
  marginBottom: "24px",
  maxHeight: 50,
  [MEDIA_QUERIES.w.gteSm]: {
    marginLeft: "auto",
    marginRight: "auto",
  },
});

const BuildingsIcon = styled(Icon.MyPropertyBoldIcon)({
  position: "relative",
  top: "5px",
  marginRight: "5px",
});

export {
  BodyText,
  TextHeader,
  SubmittedHeader,
  SubmittedTitle,
  SubmittedSubHeader,
  SubmittedBody,
  ContactText,
  BuildingsIcon,
  Logo,
};
