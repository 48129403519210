import React from "react";

import { Col, Row } from "antd";

import { Form, Formik } from "formik";

import ErrorList from "shared/components/ErrorList";
import { MaskedInput, VeroFormField } from "shared/components/Form";
import { Modal } from "shared/components/Modal";
import { RegularText } from "shared/components/Typography";
import { numberWithDecimals } from "shared/utils/ui";

import { ValidationSchema } from "./config";
import { EditIncomeAssetsModalDataFormikProps } from "./interfaces";

const EditIncomeAssetsModal = ({
  initialValues,
  application,
  rent,
  submit,
}) => (
  <Formik
    validationSchema={ValidationSchema}
    initialValues={initialValues}
    isInitialValid={ValidationSchema.isValidSync(initialValues)}
    validateOnChange
    onSubmit={submit}
  >
    {({
      errors,
      values,
      submitForm,
      isSubmitting,
      isValid,
      setFieldValue,
      dirty,
    }: EditIncomeAssetsModalDataFormikProps) => (
      <Form>
        <Modal
          title="Edit income & balances"
          subtitle={
            <>
              Applicant:{" "}
              <RegularText strong>
                {application?.firstName} {application?.lastName}
              </RegularText>
            </>
          }
          submit={submitForm}
          submitButtonLabel="Save Changes"
          // @ts-ignore
          useCancelLink
          submitting={isSubmitting}
          closeOnSubmit={false}
          submitButtonDisabled={!isValid || !dirty}
        >
          {errors.nonFieldErrors && (
            <Modal.Body noPaddingBottom>
              <ErrorList errors={errors.nonFieldErrors} />
            </Modal.Body>
          )}
          <Modal.Body>
            <Row gutter={[20, 25]}>
              <Col md={12} xs={24}>
                <VeroFormField
                  as={MaskedInput.Formik.Money}
                  id="income"
                  name="income"
                  label="Total Annual Income"
                  value={MaskedInput.Money.convertInitialValue(values.income)}
                  onChange={(event) =>
                    setFieldValue(
                      "income",
                      MaskedInput.Money.unmask(event.target.value)
                    )
                  }
                />
              </Col>
              <Col md={12} xs={24}>
                <VeroFormField
                  as={MaskedInput.IncomeRentRatio}
                  id="incomeRentRatio"
                  name="assets"
                  label="Annual Income / Monthly Rent"
                  disabled
                  value={
                    rent
                      ? `${numberWithDecimals(values.income / rent, 3)}x`
                      : "N/A"
                  }
                />
              </Col>
            </Row>
            <Row gutter={[20, 25]}>
              <Col md={12} xs={24}>
                <VeroFormField
                  as={MaskedInput.Formik.Money}
                  id="assets"
                  name="assets"
                  label="Total Balances"
                  value={MaskedInput.Money.convertInitialValue(values.assets)}
                  onChange={(event) =>
                    setFieldValue(
                      "assets",
                      MaskedInput.Money.unmask(event.target.value)
                    )
                  }
                />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </Form>
    )}
  </Formik>
);

export default EditIncomeAssetsModal;
