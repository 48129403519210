import styled from "@emotion/styled";

import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

import FlexContainer from "../FlexContainer";

const heightSm = "50px";
const heightMd = "72px";

const FooterBar = styled(FlexContainer)<{ example?: boolean }>`
  left: 0;
  bottom: 0;
  z-index: 100;
  background-color: ${COLORS.footerBar.background};
  ${MEDIA_QUERIES.w.ltMd} {
    min-height: ${heightSm};
    padding: 0 56px;
    box-shadow: 0px 1px 14px ${COLORS.footerBar.boxShadow};
    position: ${({ example }) => !example && "fixed"};
    width: ${({ example }) => !example && "100vw"};
  }
  ${MEDIA_QUERIES.w.gteMd} {
    min-height: ${heightMd};
    padding: 0 56px;
    box-shadow: 0px 1px 8px ${COLORS.footerBar.boxShadow};
    position: ${({ example }) => (!example ? "sticky" : "relative")};
    width: ${({ example }) => (!example ? "100%" : "100%")};
  }
`;

const PushDownSpacer = styled("div")`
  flex-grow: 1;
  padding-top: ${heightMd};
  ${MEDIA_QUERIES.w.ltMd} {
    // Correct margin due to FooterBar's fixed position
    padding-top: ${heightSm};
  }
`;

export { FooterBar, PushDownSpacer };
