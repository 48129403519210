import React from "react";

import Container from "shared/components/Container";
import { BackLink } from "shared/components/Links";
import PageTitle from "shared/components/PageTitle";
import Tooltip from "shared/components/Tooltip";

import { PortfolioNameProps } from "../interfaces";

import { StyledSkeleton } from "./styled";

const PortfolioName = ({
  backLink,
  portfolio,
  rename,
  loading,
}: PortfolioNameProps) => (
  <Container noPaddingBottom>
    <BackLink.Container>
      {/* @ts-ignore */}
      <BackLink {...backLink} />
    </BackLink.Container>
    <StyledSkeleton loading={loading} title={false} noMarginBottom>
      <PageTitle.Container>
        <PageTitle>
          <Tooltip theme='light' title={portfolio?.fqlVrn}>{portfolio?.name}</Tooltip>
          <PageTitle.RenameLink onClick={rename}>Rename</PageTitle.RenameLink>
        </PageTitle>
      </PageTitle.Container>
    </StyledSkeleton>
  </Container>
);

export default PortfolioName;
