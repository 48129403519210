import React from "react";

import { UnderlineLink } from "shared/components/Links";

const AddAttachmentsLink = ({
  addAttachment,
}: {
  addAttachment: (attachment: string) => void;
}) => {
  return (
    <UnderlineLink onClick={() => addAttachment("")} dashed>
      + Attachment
    </UnderlineLink>
  );
};

export default AddAttachmentsLink;
