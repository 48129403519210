import React from "react";

import { useLocation, useHistory } from "react-router-dom";

import SettingsTabs from "manager/components/SettingsTabs";
import ROUTES from "manager/config/routes";

import { usePermissions } from "shared/hooks";
import { conditionalItem } from "shared/utils/array";

import AgentsTable from "./AgentsTable";
import PropertiesTable from "./PropertiesTable";

const MagicLinksDashboard = () => {
  const { pathname } = useLocation();
  const { push } = useHistory();
  const { preventInvitesToTheApplicationWithoutUnit } = usePermissions();

  const settingItems = [
    {
      key: ROUTES.magicLinksProperties,
      label: "Properties",
      node: <PropertiesTable />,
    },
    ...conditionalItem(!preventInvitesToTheApplicationWithoutUnit, {
      key: ROUTES.magicLinksAgents,
      label: "Agents",
      node: <AgentsTable />,
    }),
  ];

  return (
    <SettingsTabs
      onTabChange={(link) => push(link)}
      activeTab={pathname}
      items={settingItems}
    />
  );
};

export default MagicLinksDashboard;
