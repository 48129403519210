import React from "react";

import AdditionalFieldData from "../AdditionalFieldData/AdditionalFieldData";

const ManualFieldData = () => (
  <div data-testid="manual-field-data">
    <AdditionalFieldData />
  </div>
);

export default ManualFieldData;
