import React from "react";

import { Col } from "antd";
import { useHistory } from "react-router-dom";

import {
  Tile,
  TileDescription,
  TileTitle,
  TileSubtitle,
  TileInner,
  TileContent,
} from "./styled";

export interface MarketPlaceTileProps {
  children?: React.ReactNode;
  disabled?: boolean;
  disabledClickAction?: () => void;
  to?: string;
}

export const MarketplaceTile = ({
  children,
  disabled,
  disabledClickAction,
  to,
}: MarketPlaceTileProps) => {
  const history = useHistory();
  const open = (e) => {
    e.preventDefault();
    history.push(to);
  };

  const openIfNotDisabled = (e) => (disabled ? disabledClickAction() : open(e));

  return (
    <Col>
      <Tile
        data-testid="marketplace-tile"
        role="link"
        tabIndex={0}
        onClick={openIfNotDisabled}
        onKeyPress={openIfNotDisabled}
        disabled={disabled}
      >
        {children}
      </Tile>
    </Col>
  );
};

MarketplaceTile.Inner = TileInner;
MarketplaceTile.Description = TileDescription;
MarketplaceTile.Title = TileTitle;
MarketplaceTile.Subtitle = TileSubtitle;
MarketplaceTile.Content = TileContent;
