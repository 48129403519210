import styled from "@emotion/styled";

import { COLORS } from "shared/config/constants";

const TableWrapper = styled.div`
  border: 1px solid ${COLORS.borders};

  .alert-icon {
    margin-left: 8px;
    margin-bottom: -4px;
  }
`;

export { TableWrapper };
