import React from "react";

import { Helmet } from "react-helmet";

import Container from "shared/components/Container";
import FilterBar from "shared/components/FilterBar";
import Tile from "shared/components/Tile";

import { PortfolioProps } from "./interfaces";

import PortfolioName from "./PortfolioName";
import PropertiesDetail from "./PropertiesDetail";
import PropertiesTable from "./PropertiesTable";

const Portfolio = ({
  portfolio,
  backLink,
  updateSearchParams,
  properties = [],
  propertiesSearchParams,
  refreshPortfolioData,
  loading,
  enableSerchAndFilters,
  propertiesLoading,
}: PortfolioProps) => (
  <>
    <Helmet>
      <title>{portfolio?.name}</title>
    </Helmet>
    <PortfolioName
      backLink={backLink}
      portfolio={portfolio}
      loading={loading}
    />
    <Container noMobilePaddingX noPaddingTop>
      <PropertiesDetail portfolio={portfolio} loading={loading} />
      <Tile header={{ title: "Properties" }}>
        <FilterBar
          search={{
            action: (value) => updateSearchParams({ nameOrAddress: value }),
            placeholder: "Search by property name or address",
            width: 350,
            debounce: true,
            disabled: !enableSerchAndFilters,
          }}
        />
        <PropertiesTable
          hasInputValue={!!propertiesSearchParams?.nameOrAddress}
          refreshPortfolioData={refreshPortfolioData}
          properties={properties}
          loading={propertiesLoading}
        />
      </Tile>
    </Container>
  </>
);

export default Portfolio;
