import axios from "axios";

import MANAGER_API_ROUTES from "manager/config/apiRoutes";
import { API_URL } from "shared/lib/api/setupApi";
import { transformRoute } from "shared/utils/routing";

const api = {
  getApplicationAudits: (id) =>
    axios
      .get(
        transformRoute(`${API_URL}${MANAGER_API_ROUTES.getApplicationAudits}`, {
          id,
        })
      )
      .then((response) => response.data),
  createApplicationAuditNote: ({ id, note }) =>
    axios
      .post(
        transformRoute(
          `${API_URL}${MANAGER_API_ROUTES.createApplicationAuditNote}`,
          { id }
        ),
        {
          note,
        }
      )
      .then((response) => response.data),
  getApplicationAuditsCount: (id) =>
    axios
      .get(
        transformRoute(
          `${API_URL}${MANAGER_API_ROUTES.getApplicationAuditsCount}`,
          {
            id,
          }
        )
      )
      .then((response) => response.data),
};

export default api;
