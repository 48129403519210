export enum PAYMENT_PATTERN_STATUSES {
  ON_TIME = "On Time",
  LATE_30_DAYS = "30 Days Late",
  LATE_60_DAYS = "60 Days Late",
  LATE_90_DAYS = "90 Days Late",
  LATE_120_DAYS = "120 Days Late",
  UNCLASSIFIED = "Unclassified",
}

export type PaymentPattern = {
  history: {
    year: number | string;
    months: Array<PAYMENT_PATTERN_STATUSES> | string[];
  }[];
};

// SSO

export enum SSO_PROVIDERS {
  google = "Google",
  facebook = "Facebook",
  linkedIn = "Linkedin",
}
export interface SSOProviderProps {
  name: string;
  type: string;
  baseUrl: string;
}

export type PERSONA_ID_CLASS = {
  CITIZEN_CERTIFICATE: "cct";
  CONSULAR_ID: "cid";
  DRIVER_LICENSE: "dl";
  HEALTHCARE_INSURANCE_CARD: "hic";
  NATIONAL_ID: "id";
  INTERNAL_PASSPORT: "ipp";
  LONG_TERM_PASS_CARD: "ltpass";
  MILITARY_ID: "mid";
  MY_NUBMER_CARD: "myn";
  NBI_CARD: "nbi";
  NRIC: "nric";
  OFW_ID: "ofw";
  PAN_CARD: "pan";
  POSTAL_ID: "pid";
  PASSPORT: "pp";
  PASSPORT_CARD: "ppc";
  PERMANENT_RESIDENT_CARD: "pr";
  RESIDENCY_PERMIT: "rp";
  SOCIAL_SECURITY_ID: "sss";
  UMID: "umid";
  VOTER_ID: "vid";
  VISA: "visa";
  WORK_PERMIT: "wp";
};

export enum VERIFICATION_TYPE {
  ID = "ID",
  EMPLOYMENT = "EMPLOYMENT",
  CREDIT = "CREDIT",
  VOR = "VOR",
}

export enum PERSONA_VERIFICATION_STATUS {
  COMPLETED = "completed",
  PASSED = "passed",
  FAILED = "failed",
  EXPIRED = "expired",
}

export enum PERSONA_FILE_TYPE {
  DOCUMENT_FRONT_PHOTO = "document_front_photo",
  DOCUMENT_BACK_PHOTO = "document_back_photo",
  SELFIE_LEFT_PHOTO = "selfie_left_photo",
  SELFIE_CENTER_PHOTO = "selfie_center_photo",
  SELFIE_RIGHT_PHOTO = "selfie_right_photo",
}

export enum PERSONA_CHECK_STATUS {
  PASSED = "passed",
  FAILED = "failed",
  NOT_APPLICABLE = "not_applicable",
}

export type VerificationCheck = {
  name: string;
  reasons: string[];
  status: PERSONA_CHECK_STATUS;
  requirement: null | "required" | "not_required";
};

export interface VerificationPropType {
  verificationType: VERIFICATION_TYPE;
  status: PERSONA_VERIFICATION_STATUS;
  verificationDate: string;
  details: {
    id: number;
    verificationFiles: any;
    createdAt: string;
    updatedAt: string;
    inquiryId: string;
    firstName: string;
    middleName: string;
    age?: number;
    lastName: string;
    checks: VerificationCheck[];
    dateOfBirth: string;
    identificationNumber: string;
    completedAt: string;
    addressStreet: string;
    addressSecondLine: string;
    addressCity: string;
    addressSubdivision: string;
    addressSubdivisionAbbr: string;
    addressPostalCode: string;
    addressPostalCodeAbbr: string;
    addressCountryCode: string;
    expirationDate: string;
    phoneNumber: string;
    documentClass: any;
    application: number;
  };
}
