import React from "react";

import { Input, VeroFormField } from "shared/components/Form";

import { CheckboxFieldDataContainer, Description } from "./styled";
import WarningMessage from "./WarningMessage";

const CheckboxFieldData = () => (
  <CheckboxFieldDataContainer data-testid="checkbox-field-data">
    <Description data-testid="checkbox-field-description">
      Please create <b>&quot;Yes/No&quot;</b> question for the checkbox.
    </Description>

    <VeroFormField
      as={Input}
      id="question"
      name="question"
      label="Yes/No Question"
      description="This question will be displayed to the leasing manager during lease creation process."
    />

    <WarningMessage />
  </CheckboxFieldDataContainer>
);

export default CheckboxFieldData;
