import axios from "axios";

import ROUTES from "shared/config/apiRoutes";
import { PactSafeConfig } from "shared/interfaces/api/pactSafeConfig";

import { API_URL } from "./setupApi";

export type PactSafeConfigParams = {
  token: string | null;
};

const api = {
  getSSOConfig: () =>
    axios.get(`${API_URL}${ROUTES.ssoConfig}`).then(({ data }) => data),
  getPactSafeConfig: (config?: PactSafeConfigParams) =>
    axios
      .get<PactSafeConfig>(`${API_URL}${ROUTES.pactSafeConfig}`, {
        params: { token: config?.token },
      })
      .then(({ data }) => data),
};

export default api;
