import React from "react";

import { Helmet } from "react-helmet";

import MainBackground from "shared/components/Backgrounds/MainBackground";
import { Button } from "shared/components/Button";
import Icon from "shared/components/Icon";
import Spacer from "shared/components/Spacer";
import { LargeTitle, RegularText } from "shared/components/Typography";
import useDeviceType from "shared/hooks/useDeviceType";

import { Container, InfoPageCard, IconContainer } from "./styled";

export interface IInfoPage {
  title?: string;
  description: React.ReactNode;
  MainIcon: React.ElementType;
  button?: {};
  width?: string;
  showCityBackground?: boolean;
}

const InfoPage = ({
  title = undefined,
  description,
  MainIcon,
  button = undefined,
  width = undefined,
}: IInfoPage) => {
  const { isMobile } = useDeviceType();
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container>
        <InfoPageCard width={width}>
          {!isMobile && <Icon.LogoBigIcon data-testid="big-logo" />}
          {isMobile && <Icon.LogoIcon data-testid="small-logo" />}
          <Spacer size={Spacer.SIZES.lg} />
          <IconContainer data-testid="tile-icon">
            <MainIcon />
          </IconContainer>
          {title && (
            <>
              <Spacer />
              <LargeTitle>{title}</LargeTitle>
            </>
          )}
          <Spacer />
          <RegularText weak>{description}</RegularText>
          {button && (
            <>
              <Spacer size={Spacer.SIZES.md} />
              <Button
                className="action-button"
                type="primary"
                data-testid="tile-button"
                {...button}
              />
            </>
          )}
        </InfoPageCard>
        <MainBackground />
      </Container>
    </>
  );
};

export default InfoPage;
