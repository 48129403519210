import React, { Key } from "react";

import * as Yup from "yup";

import { Select, SelectProps } from "../Select";

export type StateSelectProps = SelectProps & {
  states: {
    id: Key;
    value: string;
  }[];
  loading: boolean;
};

// This is the refactored version of State Select Field that will not need to use react-redux.
export const StateSelect = Object.assign(
  ({ states = [], loading, ...props }: StateSelectProps) => (
    <Select loading={loading} data-testid="state-select" {...props}>
      {states.map((state) => (
        <Select.Option key={state.id}>{state.value}</Select.Option>
      ))}
    </Select>
  ),
  { schema: Yup.string() }
);
