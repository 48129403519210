import React from "react";

import {
  BarChart as BaseBarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

import { COLORS } from "shared/config/constants";

import { BarChartTooltip } from "./styled";

// NOTE: https://recharts.org/en-US/api/Bar
const STROKE_DASH_ARRAY = "3 3";
const BAR_SIZE = 17;

export interface BarChartProps {
  width?: string | number;
  height?: string | number;
  data: {
    name: string;
    value: number;
  }[];
  formatTooltipValue?: Function;
}

const BarChart = ({
  width = "100%",
  height = "100%",
  data,
  formatTooltipValue,
}: BarChartProps) => {
  return (
    <ResponsiveContainer debounce={1} width={width} height={height}>
      <BaseBarChart data={data}>
        <CartesianGrid strokeDasharray={STROKE_DASH_ARRAY} />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip
          content={({ active, label, payload }) => {
            if (active && payload && payload.length) {
              return (
                <BarChartTooltip>
                  {label} :{" "}
                  {formatTooltipValue
                    ? formatTooltipValue(payload[0].value)
                    : payload[0].value}
                </BarChartTooltip>
              );
            }
            return null;
          }}
        />
        <Bar dataKey="value" fill={COLORS.blueViolet} barSize={BAR_SIZE} />
      </BaseBarChart>
    </ResponsiveContainer>
  );
};

export default BarChart;
