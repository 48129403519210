import styled from "@emotion/styled";

import Card from "shared/components/Card";
import Icon from "shared/components/Icon";
import { CenteredCard } from "shared/styles/commonStyles";

const PageLoadSpinner = styled(Icon.LoadingSpinnerGreenIcon)`
  display: block;
  margin: 0 auto;
`;

const PasswordRecoverySetPasswordPageCard = styled(Card)`
  ${CenteredCard}
`;

export { PageLoadSpinner, PasswordRecoverySetPasswordPageCard };
