import styled from "@emotion/styled";

import FlexContainer from "shared/components/FlexContainer";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

import Dashboard from "../assets/dashboard.svg";
import SmallDashboard from "../assets/small-dashboard.svg";

const Container = styled(FlexContainer)({
  background: COLORS.blueViolet,
  position: "relative",
  backgroundImage: `url(${Dashboard})`,
  backgroundPosition: "right",
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  padding: 26,
  paddingRight: 350,
  [MEDIA_QUERIES.w.ltLg]: {
    backgroundImage: `url(${SmallDashboard})`,
    paddingRight: 60,
    backgroundPositionX: "right",
    backgroundPositionY: "top",
    backgroundSize: "auto",
  },
  marginBottom: 16,
});

const Content = styled("div")({
  flexGrow: 1,
});

const Title = styled("h1")({
  color: COLORS.white,
  fontWeight: 500,
  marginBottom: "0 !important",
});

const ContentLine = styled("p")({
  fontSize: 17,
  color: COLORS.white,
  marginBottom: "0 !important",
});

export { Container, Content, Title, ContentLine };
