import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import * as Yup from "yup";

import { formatDate } from "shared/utils/ui";

import { Unit } from "./interfaces";

dayjs.extend(isSameOrBefore);

export const BASE_WARNING =
  "Changing the unit will reset the lease creation and progress will be lost.";

export const LEASE_NOT_ACTIVE_WARNING = `${BASE_WARNING} This unit belongs to a property where leasing isn't active.
If you proceed with this unit then you will not be able to continue issuing a lease.`;

export const E_SIGN_NOT_ACTIVE_WARNING = `${BASE_WARNING} This unit doesn't currently support eSigning.
Please note, if you proceed with this unit you'll need to handle the lease signing process offline.`;

export const MUST_UPDATE_PREFERRED_LEASE_START_DATE_WARNING = (
  preferredLeaseStart,
  unitAvailableOn
) => {
  return `The application has a preferred move-in date of ${formatDate(
    preferredLeaseStart
  )}, but the selected unit isn't available until ${formatDate(
    unitAvailableOn
  )}. Re-assigning the unit will override the preferred move-in date to match the available date of the new unit. \
  Changing the unit will also reset the lease creation and progress will be lost.`;
};

export const getInitialValues = (unitId: number | string) => ({
  unitListingRequired: false,
  unitId,
  unitListing: {
    unit: null,
    rent: null,
    unitAvailableOn: "",
    securityDeposit: null,
  },
});

export const validationSchema = Yup.object().shape({
  unitId: Yup.number().required("Please select a unit"),
  unitListingRequired: Yup.bool().required(),
  unitListing: Yup.object().when("unitListingRequired", {
    is: true,
    then: Yup.object().shape({
      rent: Yup.number()
        .nullable()
        .positive("Please enter a rent amount that is greater than $0")
        .required("Rent is required"),
      securityDeposit: Yup.number()
        .nullable()
        .positive("Please enter a rent amount that is greater than $0"),
      unitAvailableOn: Yup.string().required("Date is required"),
      unit: Yup.number().required(),
    }),
  }),
});

export const getPreferredLeaseStartDate = (deal) =>
  deal?.applicantsSummary.find((application) => application.isPrimary)
    ?.preferredLeaseStartDate;

// Returns false if the preferred move in date of the primary applicant is before the unit listing availability date.
// Otherwise, if some of the dates is not defined or they are aligned it returns true.
export const isUnitAvailableOnDateAlignedWithPreferredDate = (
  unit: Unit,
  preferredLeaseStartDate: string
) => {
  const unitAvailableOn = unit?.unitListingCurrent?.unitAvailableOn;
  return (
    !unitAvailableOn ||
    !preferredLeaseStartDate ||
    dayjs(unitAvailableOn).isSameOrBefore(preferredLeaseStartDate)
  );
};

export const getWarningOptions = (unit, deal: any) => {
  const preferredLeaseStartDate = getPreferredLeaseStartDate(deal);
  const hasLease = Boolean(deal?.lease);
  let warning;
  let shouldShowDatePicker = false;

  if (unit) {
    const isLeasingActive = unit.unitHasActiveDocuments;
    const isESignActive = unit.isElectronicallySigned;
    const isAvailableOnDateAlignedWithPreferredDate =
      isUnitAvailableOnDateAlignedWithPreferredDate(
        unit,
        preferredLeaseStartDate
      );

    if (!isAvailableOnDateAlignedWithPreferredDate) {
      warning = MUST_UPDATE_PREFERRED_LEASE_START_DATE_WARNING(
        preferredLeaseStartDate,
        unit.unitListingCurrent?.unitAvailableOn
      );
      shouldShowDatePicker = true;
    } else if (hasLease && !isLeasingActive) {
      warning = LEASE_NOT_ACTIVE_WARNING;
    } else if (hasLease && !isESignActive) {
      warning = E_SIGN_NOT_ACTIVE_WARNING;
    } else if (hasLease) {
      warning = BASE_WARNING;
    }
  }
  return { warning, shouldShowDatePicker };
};

export const isUnitDisabled = (
  unit: Unit,
  isCurrentUnit: boolean,
  preferredLeaseStartDate: string,
  hasLeaseOverridePermission: boolean
) => {
  const datesAlign = isUnitAvailableOnDateAlignedWithPreferredDate(
    unit,
    preferredLeaseStartDate
  );

  const isYardiConnectedWithNoListing =
    !unit.unitListingCurrent && unit.yardiConnected;

  const unitDisabled =
    unit.isRented ||
    (!hasLeaseOverridePermission && !datesAlign) ||
    isYardiConnectedWithNoListing ||
    isCurrentUnit;

  let tooltipMessage: string;
  if (unitDisabled) {
    if (!datesAlign) {
      const unitAvailableOn = formatDate(
        unit?.unitListingCurrent?.unitAvailableOn
      );
      const preferredLeaseStart = formatDate(preferredLeaseStartDate);
      tooltipMessage = `The main applicant prefers to move in on ${preferredLeaseStart}. This unit isn't available until ${unitAvailableOn}.`;
    } else if (unit.isRented) {
      tooltipMessage =
        "This unit cannot be selected because it is already rented.";
    } else if (isYardiConnectedWithNoListing) {
      tooltipMessage =
        "You can not select this unit as it doesn't have an active listing. Create listings for Yardi-connected units on the Yardi dashboard.";
    } else if (isCurrentUnit) {
      tooltipMessage = "This unit is already assigned to this application.";
    }
  }

  return { unitDisabled, tooltipMessage };
};
