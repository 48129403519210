import styled from "@emotion/styled";

import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

const BackgroundContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;

  background-color: ${COLORS.lightGray2};

  .top-left-bg {
    position: absolute;
    top: 0;
    left: 5%;
    width: 15vw;

    ${MEDIA_QUERIES.w.ltMd} {
      top: auto;
      bottom: 0;
      left: 0;
      width: 55vw;
    }
  }

  .top-right-bg {
    position: absolute;
    top: 0;
    right: 0;
    width: 13vw;

    ${MEDIA_QUERIES.w.ltMd} {
      width: 55vw;
    }
  }

  .bottom-left-bg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 40vw;
    ${MEDIA_QUERIES.w.ltMd} {
      display: none;
    }
  }

  .bottom-right-bg {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 30vw;
    ${MEDIA_QUERIES.w.ltMd} {
      display: none;
    }
  }

  img {
    max-height: 100%;
  }
`;

export { BackgroundContent };
