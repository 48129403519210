import { openDefaultAnimatedActionModal } from "shared/components/Modals/AnimatedPendingActionModal";
import { COMMON_PENDING_ANIMATION_MESSAGES } from "shared/config/constants";
import useModal from "shared/hooks/useModal";

import { resendToPms } from "shared/lib/api/dealApi";
import { notifyError, notifySuccess } from "shared/utils/ui";

import { DealSummary } from "../types";

export const useHook = (deal: DealSummary, hide: () => void) => {
  const { closeActiveModalDialog } = useModal();

  const resendDealToPms = async () => {
    const messages = [...COMMON_PENDING_ANIMATION_MESSAGES, "Sent!"];

    hide();
    const animatedPendingActionApi = openDefaultAnimatedActionModal(messages);
    try {
      await resendToPms({ id: deal?.id });
      animatedPendingActionApi.finishAnimation(() => {
        notifySuccess("Successfully triggered resend to PMS.");
      });
    } catch (err: any) {
      const { error } = err;
      closeActiveModalDialog();
      if (error) {
        notifyError(error);
      } else {
        notifyError("Failed to trigger resend to PMS.");
      }
      throw err;
    }
  };

  return { resendToPms: resendDealToPms };
};
