import React from "react";

import { FooterBar as StyledFooterBar, PushDownSpacer } from "./styled";

interface FooterBarProps {
  children: Element[] | React.ReactNode;
  [key: string]: any;
}

const FooterBar = ({ children, ...props }: FooterBarProps) => (
  <StyledFooterBar alignItems="center" {...props}>
    {children}
  </StyledFooterBar>
);

FooterBar.Spacer = PushDownSpacer;

export default FooterBar;
