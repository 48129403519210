import React from "react";

import StackedBarChart from "shared/components/Chart/StackedBarChart";
import { MediumTitle, RegularText } from "shared/components/Typography";

import { EmptyChart, isChartEmpty } from "../Dashboard.utils";

import { ChartTitle, Chart } from "./styled";

interface DashboardStackedBarChartProps {
  title?: string;
  value?: string | number;
  details?: string;
  chartData: {
    name: string;
    [key: string]: number | string;
  }[];
}

const DashboardStackedBarChart = ({
  title,
  value,
  chartData,
  details,
}: DashboardStackedBarChartProps) => (
  <>
    {isChartEmpty(chartData) ? (
      <EmptyChart />
    ) : (
      <>
        {title && (
          <ChartTitle alignItems="center">
            <MediumTitle>{title}</MediumTitle>
            <div>{value}</div>
          </ChartTitle>
        )}
        <Chart className="stacked-bar-chart">
          <StackedBarChart data={chartData} />
        </Chart>
        {/* @ts-ignore */}
        <RegularText light>{details}</RegularText>
      </>
    )}
  </>
);

export default DashboardStackedBarChart;
