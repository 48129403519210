import React from "react";

import { CurrentSelectionProps } from "../interfaces";
import { SelectionTitle, Selection } from "../styled";

const CurrentSelection = ({ selectedCompany }: CurrentSelectionProps) => (
  <>
    <SelectionTitle data-testid="selectionTitle">
      {selectedCompany?.name ? "Selected company:" : "No selected company yet"}
    </SelectionTitle>

    <Selection data-testid="selection">
      {selectedCompany?.name ?? " "}
    </Selection>
  </>
);

export default CurrentSelection;
