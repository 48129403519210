import styled from "@emotion/styled";

const AuthPageContent = styled.section`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
`;

export { AuthPageContent };
