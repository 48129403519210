import React from "react";

import {
  PortalLowerLeftCorner,
  PortalLowerRightCorner,
  PortalUpperLeftCorner,
  PortalUpperRightCorner,
} from "shared/images/portal";

import { Background } from "./styled";

const PortalBackground = () => (
  <Background>
    <div className="background-row top">
      <img src={PortalUpperLeftCorner} alt="bg" className="upper-left-bg" />
      <img src={PortalUpperRightCorner} alt="bg" className="upper-right-bg" />
    </div>
    <div className="background-row bottom">
      <img src={PortalLowerLeftCorner} alt="bg" className="lower-left-bg" />
      <img src={PortalLowerRightCorner} alt="bg" className="lower-right-bg" />
    </div>
  </Background>
);

export default PortalBackground;
