import { useQuery } from "react-query";

import { NOTIFICATIONS } from "shared/config/constants";
import api from "shared/lib/api";
import { openNotification } from "shared/utils/ui";

export const GET_APPLICANT_USER_PROFILE = "getApplicantUserProfile";

const useGetApplicantProfile = () => {
  const { data: applicantProfile, isLoading } = useQuery(
    [GET_APPLICANT_USER_PROFILE],
    () => api.getApplicantUserProfile(),
    {
      onError: () =>
        openNotification(
          "Failed to load applicant user profile",
          NOTIFICATIONS.error
        ),
    }
  );
  return { applicantProfile, isLoading };
};

export { useGetApplicantProfile };
