import _ from 'lodash'
import React, { useState } from 'react'
import { Tabs } from 'antd'
import { FormulaEditor } from 'shared/components/vega/ExpressionEngine'
import { useLumenV2Mutations } from 'manager/hooks/api'
import CalculationSettings from './CalculationSettings'
import ScoreLabels from './ScoreLabels'
import Outcomes from './Outcomes'

const LumenEditor = ({ formula, canEdit = false }) => {
  const [resolvedFormula, setFormula] = useState(formula)
  const [expressionFormula, setExpressionFormula] = useState(resolvedFormula?.expression?.formula)
  const [calculationSettings, setCalculationSettings] = useState(_.omit(resolvedFormula?.settings, ['scoreLabels', 'outcomes']))
  const [scoreLabels, setScoreLabels] = useState(resolvedFormula?.settings?.scoreLabels)
  const [outcomes, setOutcomes] = useState(resolvedFormula?.settings?.outcomes)

  const { updateLumenV2Formula, deleteLumenV2Formula } = useLumenV2Mutations()

  const canSave = canEdit && _.some([
    resolvedFormula?.expression?.formula !== expressionFormula,
    !_.isEqual(calculationSettings, _.omit(resolvedFormula?.settings, ['scoreLabels', 'outcomes'])),
    !_.isEqual(scoreLabels, resolvedFormula?.settings?.scoreLabels),
    !_.isEqual(outcomes, resolvedFormula?.settings?.outcomes)
  ])

  const onReset = () => {
    setExpressionFormula(resolvedFormula?.expression?.formula)
    setCalculationSettings(_.omit(resolvedFormula?.settings, ['scoreLabels', 'outcomes']))
    setScoreLabels(resolvedFormula?.settings?.scoreLabels)
    setOutcomes(resolvedFormula?.settings?.outcomes)
  }

  const onSave = async () => {
    setFormula(await updateLumenV2Formula({
      id: resolvedFormula?.id,
      data: {
        formula: expressionFormula,
        settings: {
          ...calculationSettings,
          scoreLabels,
          outcomes
        }
      }
    }))
  }

  const onDelete = () => deleteLumenV2Formula(resolvedFormula?.id)

  return (
    <div className='vega flex no-wrap no-gap lumen workflow-editor'>
      <FormulaEditor
        formula={expressionFormula}
        canSave={canSave}
        canEdit={canEdit}
        onChange={setExpressionFormula}
        onReset={onReset}
        onSave={onSave}
        onDelete={onDelete}
      />

      {_.startsWith(resolvedFormula?.identifier, 'application') && <div className='vega content flex column settings'>
        <Tabs defaultActiveKey='calculation-settings' animated={false}>
          <Tabs.TabPane key='calculation-settings' tab='Calculation Settings'>
            <CalculationSettings settings={_.cloneDeep(calculationSettings)} canEdit={canEdit} onChange={setCalculationSettings} />
          </Tabs.TabPane>

          <Tabs.TabPane key='score-labels' tab='Score Labels'>
            <ScoreLabels scoreLabels={_.cloneDeep(scoreLabels || {})} canEdit={canEdit} onChange={setScoreLabels} />
          </Tabs.TabPane>

          <Tabs.TabPane key='outcomes' tab='Outcomes'>
            <Outcomes outcomes={_.cloneDeep(outcomes || {})} canEdit={canEdit} onChange={setOutcomes} />
          </Tabs.TabPane>
        </Tabs>
      </div>}
    </div>
  )
}

export default LumenEditor
