import React from "react";

import { Row, Col } from "antd";

import { Input, VeroFormField } from "shared/components/Form";

const AdditionalFieldData = () => (
  <div data-testid="additional-field-data">
    <Row gutter={[0, 24]}>
      <Col>
        <VeroFormField
          as={Input}
          id="sample"
          name="sample"
          label="Enter Field Sample"
          description="The field sample allows you to check how the real data will  look like in the document when you add a new field there"
        />
      </Col>
    </Row>

    <Row gutter={[0, 24]}>
      <Col>
        <VeroFormField
          as={Input}
          id="description"
          name="description"
          label="Enter Field Description"
          description="The description will be shown to the leasing manager during the lease creation"
          maxLength={150}
        />
      </Col>
    </Row>
  </div>
);

export default AdditionalFieldData;
