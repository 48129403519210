import React from "react";

import { useHistory } from "react-router-dom";

import ROUTES from "manager/config/routes";
import { useBindBluemoonProperties } from "manager/hooks/api";

import {
  SubmitContainer,
  SectionButton,
  SectionColumn,
  StepTitle,
  LightUnderlinedLink,
} from "./styled";

interface BluemoonPropertyBindingsSubmitProps {
  bluemoonPropertyId: number;
  veroPropertyIds: number[];
}

const BluemoonPropertyBindingsSubmit = ({
  bluemoonPropertyId,
  veroPropertyIds,
}: BluemoonPropertyBindingsSubmitProps) => {
  const history = useHistory();
  const openFullyBoundedPropertiesPage = () =>
    history.push(ROUTES.bluemoonBoundProperties);
  const { bindBluemoonProperties } = useBindBluemoonProperties();
  const disabled = !(veroPropertyIds.length && bluemoonPropertyId);

  const bindProperties = () =>
    bindBluemoonProperties({
      bluemoonPropertyId,
      veroPropertyIds,
    }).then((data) => {
      if (data) window.location.reload();
    });

  return (
    <SubmitContainer>
      <SectionColumn alignItems="center" justifyContent="flex-start">
        <StepTitle>3. Bind Properties</StepTitle>
      </SectionColumn>
      <SectionColumn alignItems="center" justifyContent="flex-end">
        <LightUnderlinedLink onClick={openFullyBoundedPropertiesPage}>
          View Bound Properties
        </LightUnderlinedLink>
        <SectionButton onClick={bindProperties} disabled={disabled}>
          Bind Properties
        </SectionButton>
      </SectionColumn>
    </SubmitContainer>
  );
};

export default BluemoonPropertyBindingsSubmit;
