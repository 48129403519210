import { css } from "@emotion/react";
import styled from "@emotion/styled";

/**
 * NOTE: We have duplication of some styles cause in 'styled' files where we are using
 * string interpolation we need to use css format. When you replace some of old styles,
 * please delete appropriate constant if it's not used anymore
 */

const AlignedCenterFlexPartial = {
  display: "flex",
  alignItems: "center",
};

const AlignedCenterFlexPartialCss = css`
  display: flex;
  align-items: center;
`;

const CenteredFlexPartial = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "row",
};

const CenteredFlexPartialCss = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

const VerticalFlexPartialCss = css`
  display: flex;
  flex-direction: column;
`;

const TextEllipsis = {
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
};

const FormGroups = {
  ".form-group, &.form-group": {
    "&:not(:last-of-type)": {
      marginBottom: "32px",
    },
    ".form-control-label, .radio-label": {
      ...AlignedCenterFlexPartial,
      fontWeight: 300,
      fontSize: "15px",
      margin: 0,
      marginBottom: "18px",
      ".popover": {
        marginLeft: "5px",
      },
    },
  },
};

const FormGroupsCss = css`
  .form-group,
  &.form-group {
    &:not(:last-of-type) {
      margin-bottom: 32px;
    }

    .form-control-label,
    .radio-label {
      ${AlignedCenterFlexPartialCss}
      font-weight: 300;
      font-size: 15px;
      margin: 0;
      margin-bottom: 18px;

      .popover {
        margin-left: 5px;
      }
    }
  }
`;

const OverflowEllipsis = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block !important;
`;

const OverflowEllipsisMultiline = (maxLine) => css`
  display: -webkit-box;
  -webkit-line-clamp: ${maxLine};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const CenteredCard = css`
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
`;

const DropdownOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const ModalListScrollbar = (thumbColor) => css`
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    margin: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${thumbColor};
    border-radius: 8px;
  }
`;

export {
  CenteredFlexPartial,
  AlignedCenterFlexPartial,
  AlignedCenterFlexPartialCss,
  TextEllipsis,
  FormGroups,
  FormGroupsCss,
  CenteredFlexPartialCss,
  OverflowEllipsisMultiline,
  OverflowEllipsis,
  CenteredCard,
  VerticalFlexPartialCss,
  DropdownOverlay,
  ModalListScrollbar,
};
