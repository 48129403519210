import React from "react";

import ShareMagicLinksModal from "manager/components/MagicLinks/ShareMagicLinksModal";
import { useGetManagerProfile } from "manager/hooks/api";
import { MAGIC_LINK_TYPE } from "manager/pages/MagicLinks/config";
import useModal from "shared/hooks/useModal";

import ManagerProfileMagicLink from "./ManagerProfileMagicLink";

const ManagerProfileMagicLinkContainer = () => {
  const { openModalDialog } = useModal();
  const { data: profile } = useGetManagerProfile();

  const openShareLinks = () =>
    openModalDialog(ShareMagicLinksModal, {
      magicLink: profile?.magicLink,
      name: profile?.fullName,
      type: MAGIC_LINK_TYPE.AGENT,
      prescreeningMagicLink: profile?.prescreeningMagicLink,
    });

  return <ManagerProfileMagicLink openShareLinks={openShareLinks} />;
};

export default ManagerProfileMagicLinkContainer;
