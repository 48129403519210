import React, { useState } from "react";

import Icon from "shared/components/Icon";
import { UserProfile } from "shared/components/User";

import { MobileSidebarProps } from "../interfaces";
import Sidebar from "../Sidebar/Sidebar";

import DefaultDrawerHeader from "./DrawerHeader";
import SidebarView from "./SidebarView";
import { StyledDrawer, MobileIconWrapper } from "./styled";

const VIEWS = {
  menuItems: "menuItems",
  profile: "profile",
};

const MobileSidebar = ({
  items,
  groupedRoutes,
  user,
  DrawerHeader = DefaultDrawerHeader,
  ...props
}: MobileSidebarProps) => {
  const [isDrawerOpen, setDrawerIsOpen] = useState(false);
  const [activeView, setActiveView] = useState(VIEWS.menuItems);

  const openProfile = () => {
    if (activeView === VIEWS.profile) {
      setActiveView(VIEWS.menuItems);
    } else {
      setActiveView(VIEWS.profile);
    }
  };

  const openDrawer = () => setDrawerIsOpen(true);
  const closeDrawer = () => setDrawerIsOpen(false);

  return (
    <div {...props}>
      <MobileIconWrapper>
        <Icon.MobileMenuIcon onClick={openDrawer} />
      </MobileIconWrapper>
      <StyledDrawer
        title={
          <DrawerHeader
            active={activeView === VIEWS.profile}
            openProfile={openProfile}
          />
        }
        closable
        placement="left"
        onClose={closeDrawer}
        visible={isDrawerOpen}
        mask={false}
        width={global.window.innerWidth}
      >
        {activeView === VIEWS.menuItems && (
          <Sidebar
            items={items}
            groupedRoutes={groupedRoutes}
            afterItemActivate={closeDrawer}
          />
        )}
        {activeView === VIEWS.profile && (
          <SidebarView title={user.name}>
            <UserProfile {...user} />
          </SidebarView>
        )}
      </StyledDrawer>
    </div>
  );
};

export default MobileSidebar;
