import React from "react";

import PropTypes from "prop-types";

import Icon from "shared/components/Icon";

interface LoaderContainerProps {
  loading?: boolean;
  children?: JSX.Element | JSX.Element[] | string;
  Loader?: JSX.Element;
  fetching?: boolean;
}

const LoaderContainer = ({
  loading = false,
  children = <></>,
  Loader = (
    <Icon.LoadingSpinnerGreenIcon
      data-testid="loader-icon"
      className="loading-icon"
    />
  ),
  fetching = false,
}: LoaderContainerProps) => {
  return (
    <>
      {loading ? (
        Loader
      ) : (
        <>
          {fetching && Loader}
          {children}
        </>
      )}
    </>
  );
};

LoaderContainer.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
  Loader: PropTypes.node,
  fetching: PropTypes.bool,
};

LoaderContainer.defaultProps = {
  loading: false,
  fetching: false,
};

LoaderContainer.defaultProps = {
  children: <></>,
  Loader: (
    <Icon.LoadingSpinnerGreenIcon
      data-testid="loader-icon"
      className="loading-icon"
    />
  ),
};

export default LoaderContainer;
