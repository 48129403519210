/**
 * The data handler for the ModalManager.
 * Contains the complete data for the current modals.
 */
class ModalManagerDataHandler {
  currentModals = [];

  /**
   * Gets the data for the modal with the provided ID.
   * @param id the id of the modal
   * @returns the modal data if found, undefined otherwise
   */
  getCurrentModalById = (id) =>
    this.currentModals.find((modal) => modal.id === id);

  /**
   * Gets all current modals data.
   * @returns list of current modal data.
   */
  getAllCurrentModals = () => this.currentModals;

  /**
   * Removes a current modal by the provided ID.
   * @param id the id of the modal
   */
  removeCurrentModalById = (id) => {
    const modalIdx = this.currentModals.findIndex((modal) => modal.id === id);
    if (modalIdx > -1) {
      this.currentModals.splice(modalIdx, 1);
    }
  };

  /**
   * Adds a new current modal.
   * @param modalContext the new modal data
   */
  addNewModal = (modalContext) => this.currentModals.push(modalContext);

  /**
   * Updates the context of the provided modal data.
   * The modal is looked by the provided modal data id.
   * @param modalContext the new modal data.
   */
  updateCurrentModal = (modalContext) => {
    const modalIdx = this.currentModals.findIndex(
      (modal) => modal.id === modalContext.id
    );
    if (modalIdx > -1) {
      this.currentModals[modalIdx] = modalContext;
    }
  };
}

export default ModalManagerDataHandler;
