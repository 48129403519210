import React, { useState } from "react";

import { TooltipPlacement } from "antd-latest/lib/tooltip";

import { useHistory } from "react-router-dom";

import ROUTES from "manager/config/routes";
import { useGetBluemoonPropertiesCount } from "manager/hooks/api";
import Icon from "shared/components/Icon";
import { UnderlineLink } from "shared/components/Links";
import Tooltip from "shared/components/Tooltip";
import { RegularText } from "shared/components/Typography";
import { useDeviceType } from "shared/hooks";

import useModal from "shared/hooks/useModal";

import BluemoonHiddenPropertiesModal from "../../components/BluemoonHiddenPropertiesModal";

import {
  HeaderContainer,
  SectionContainer,
  MobileExpansionContainer,
} from "./styled";

interface BarItemProps {
  label: string;
  value: number | string;
  tooltipText?: string;
  tooltipPlacement?: TooltipPlacement;
  onClick?: () => void;
}

const BarItem = ({
  label,
  value,
  tooltipText,
  tooltipPlacement,
  onClick,
}: BarItemProps) => (
  <SectionContainer>
    <RegularText strong className="label">
      {label}
    </RegularText>
    {tooltipText ? (
      <Tooltip title={tooltipText} placement={tooltipPlacement}>
        <UnderlineLink className="value" onClick={onClick}>
          {value}
        </UnderlineLink>
      </Tooltip>
    ) : (
      <UnderlineLink className="value" onClick={onClick}>
        {value}
      </UnderlineLink>
    )}
  </SectionContainer>
);

const BluemoonPropertyBindingsHeader = () => {
  const { isMobile } = useDeviceType();
  const [isExpanded, setExpanded] = useState(!isMobile);
  const { openModalDialog } = useModal();
  const history = useHistory();

  const openHiddenPropertiesModal = () =>
    openModalDialog(BluemoonHiddenPropertiesModal);

  const openFullyBoundedPropertiesPage = () =>
    history.push(ROUTES.bluemoonBoundProperties);

  const { propertiesCount } = useGetBluemoonPropertiesCount();

  const {
    notBoundProperties = 0,
    fullyBoundProperties = 0,
    hiddenProperties = 0,
  } = propertiesCount || {};

  const ChevronIcon =
    isExpanded && isMobile
      ? Icon.ChevronUpWhiteBoldIcon
      : Icon.ChevronDownWhiteBoldIcon;

  const toggleExpandedSection = () => setExpanded(!isExpanded);

  return (
    <HeaderContainer alignItems="center">
      <MobileExpansionContainer>
        <BarItem
          label="PROPERTIES NOT BOUND"
          value={notBoundProperties}
          tooltipText="Begin binding properties"
          tooltipPlacement="topLeft"
        />
        {isMobile && (
          // @ts-ignore
          <ChevronIcon
            style={{ height: "auto", width: "25px" }}
            className="dropdown-chevron"
            onClick={toggleExpandedSection}
          />
        )}
      </MobileExpansionContainer>
      {isExpanded && (
        <>
          <BarItem
            label="PROPERTIES FULLY BOUND"
            value={fullyBoundProperties}
            tooltipText="View bound properties list"
            tooltipPlacement="bottom"
            onClick={openFullyBoundedPropertiesPage}
          />
          <BarItem
            label="PROPERTIES HIDDEN"
            value={hiddenProperties}
            tooltipText="View hidden properties"
            tooltipPlacement="topRight"
            onClick={openHiddenPropertiesModal}
          />
        </>
      )}
      {/* {!isMobile && (
        <Button>SMART BINDING</Button>
      )} */}
    </HeaderContainer>
  );
};

export default BluemoonPropertyBindingsHeader;
