import React from "react";

import PortalBackground from "shared/components/Backgrounds/PortalBackground";

import { StyledLayout, ContentContainer, VeroLogo } from "./styled";

export interface IPortalLayout {
  children?: React.ReactNode;
  LogoAlt?: React.ReactNode;
  logoUrl?: string;
  logoTitle?: string;
}

const PortalLayout = ({
  logoUrl = "",
  LogoAlt = undefined,
  children = <></>,
}: IPortalLayout) => {
  return (
    <StyledLayout>
      <PortalBackground />
      <ContentContainer>
        <div className="logo-container">
          {logoUrl && <img src={logoUrl} alt="Logo" className="logo" />}
          {LogoAlt}
        </div>
        <div className="content">{children}</div>
        <div className="powered-by">
          Powered by &nbsp;
          <VeroLogo className="logo" data-testid="vero-logo" />
        </div>
      </ContentContainer>
    </StyledLayout>
  );
};

export default PortalLayout;
