import styled from "@emotion/styled";

export const ClickwrapModalContent = styled.div`
  display: flex;
  flex-direction: column;

  .description {
    padding-bottom: 20px;
  }
`;
