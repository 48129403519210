import React, { useEffect, useMemo, useRef, useState } from "react";

import { SourcesCreateEditModal } from "manager/components/Modal";
import {
  useCreateLeadSource,
  useGetLeadSources,
  useGetYardiLeadSources,
  usePutYardiLeadSourceMappingConfiguration,
} from "manager/hooks/api";
import { useGetIntegrationProvider } from "manager/pages/Marketplace/config";
import {
  destringifyVeroLeadSourceId,
  stringifyVeroLeadSourceId,
} from "manager/pages/Marketplace/integrations/yardi/ConfigureYardiProperty/section/configureYardiPropertySection.util";
import useModal from "shared/hooks/useModal";
import { BackLinkProps } from "shared/interfaces/misc";
import { resetFormAndValidate } from "shared/utils/forms";
import {
  areSameAsStrings,
  sanitizeStringSearchValue,
  stringify,
} from "shared/utils/string";

import SetupYardiLeadSources from "./SetupYardiLeadSources";

const MAGIC_LINK = "Magic Link";
const MAGIC_LINK_INFO =
  "Magic Links allow for an applicant to apply via a link on a website or a QR code.";

const setupLeadSourcesData = (
  fetchedVeroLeadSources,
  fetchedYardiLeadSources
) => {
  const veroData = (fetchedVeroLeadSources || []).map((leadSource) => {
    return {
      id: leadSource.id,
      isDefault: leadSource.isDefault,
      label: leadSource.value,
      yardiLeadSource: leadSource.yardiSourceLinked,
      info: leadSource.value === MAGIC_LINK && MAGIC_LINK_INFO,
    };
  });
  const yardiData = (fetchedYardiLeadSources || []).map((leadSource) => {
    return {
      id: leadSource.id,
      value: stringify(leadSource.id),
      label: leadSource.value,
    };
  });
  return {
    yardiLeadSources: yardiData,
    veroLeadSources: veroData,
  };
};

const convertLeadSourceInForm = (yardiFormKey, formObj, defaultSelection) => {
  if (yardiFormKey && formObj) {
    const leadSourceId = destringifyVeroLeadSourceId(yardiFormKey);
    return {
      yardiLeadSource: formObj.yardiLeadSource,
      leadSourceId,
      isDefault: areSameAsStrings(leadSourceId, defaultSelection),
    };
  }
  return undefined;
};

const convertLeadSourcesInForm = (formObj, defaultSelection) => {
  return formObj
    ? Object.keys(formObj)
        .map((key) =>
          convertLeadSourceInForm(key, formObj[key], defaultSelection)
        )
        .filter((obj) => !!obj)
    : formObj;
};

interface SetupYardiLeadSourcesContainerProps {
  backLink: BackLinkProps;
}

const SetupYardiLeadSourcesContainer = ({
  backLink,
}: SetupYardiLeadSourcesContainerProps) => {
  const { provider } = useGetIntegrationProvider();
  const [isInitializingValues, setIsInitializingValues] = useState(true);
  const { openModalDialog } = useModal();

  const formikRef = useRef(null);
  const { updateYardiLeadSourceMapping } =
    usePutYardiLeadSourceMappingConfiguration(provider);
  const { createLeadSource } = useCreateLeadSource();

  const {
    yardiLeadSources: fetchedYardiLeadSources,
    isLoading: areYardiLeadSourceLoading,
  } = useGetYardiLeadSources();
  const {
    leadSources: fetchedVeroLeadSources,
    isLoading: areVeroLeadSourceLoading,
  } = useGetLeadSources();
  const areLeadSourcesLoading =
    areYardiLeadSourceLoading || areVeroLeadSourceLoading;

  const { veroLeadSources, yardiLeadSources } = useMemo(
    () => setupLeadSourcesData(fetchedVeroLeadSources, fetchedYardiLeadSources),
    [fetchedVeroLeadSources, fetchedYardiLeadSources]
  );

  const onSave = (data, config = {}) => {
    const leadSources = convertLeadSourcesInForm(
      data.leadSources,
      data.defaultSelection
    );
    updateYardiLeadSourceMapping(leadSources, config);
  };

  const onFormSubmit = (data, { setSubmitting }) => {
    setSubmitting(true);
    const onSettled = () => setSubmitting(false);
    onSave(data, { onSettled });
  };

  const createNewSource = () => {
    const saveAction = (newName) => {
      const source = { value: newName };
      // @ts-ignore
      return createLeadSource(source);
    };

    const usedNames = (veroLeadSources || []).map((s) =>
      sanitizeStringSearchValue(s.label)
    );

    const context = {
      title: "Create New Lead Source",
      inputDescription: "",
      inputPlaceholder: "Lead Source Name",
      confirmLabel: "CREATE SOURCE",
      saveAction,
      source: {},
      usedNames,
    };
    openModalDialog(SourcesCreateEditModal, context);
  };

  useEffect(() => {
    if (!areLeadSourcesLoading && veroLeadSources) {
      const leadSources = {};
      let defaultSelection = null;
      (veroLeadSources || []).forEach((leadSource) => {
        const id = stringifyVeroLeadSourceId(leadSource.id);
        defaultSelection = leadSource.isDefault
          ? leadSource.id
          : defaultSelection;
        leadSources[id] = {
          yardiLeadSource: stringify(leadSource.yardiLeadSource),
        };
      });

      const values = {
        leadSources,
        defaultSelection,
      };
      resetFormAndValidate(formikRef, { values });
      setIsInitializingValues(false);
    }
  }, [areLeadSourcesLoading, veroLeadSources]);

  const props = {
    backLink,
    veroLeadSources,
    yardiLeadSources,
    isLoading: areLeadSourcesLoading || isInitializingValues,
    formikRef,
    onFormSubmit,
    onSave,
    createNewSource,
  };
  return <SetupYardiLeadSources {...props} />;
};

export default SetupYardiLeadSourcesContainer;
