import React from "react";

import styled from "@emotion/styled";
import { Form, Formik } from "formik";

import { Button } from "shared/components/Button";
import { Input, VeroFormField } from "shared/components/Form";
import { DEAL_TYPES } from "shared/config/constants";

import { Header, SubmitButtonWrapper } from "../styled";

import { INITIAL_VALUES, ValidationSchema } from "./config";
import { StepLandingProps } from "./interfaces";

const HeaderStepLanding = styled(Header)`
  text-align: center;
`;

const Label = styled.label`
  font-weight: bold;
  margin-bottom: 24px;
  display: block;
  text-align: center;
`;

const StepLanding = ({
  submit,
  formikRef,
  propertyName,
  dealType,
}: StepLandingProps) => {
  return (
    <>
      <HeaderStepLanding data-testid="step-landing-header">
        <h1 data-testid="title" className="title">
          {propertyName} uses <strong>VERO</strong> to{" "}
          {dealType === DEAL_TYPES.SCREENING
            ? "securely verify your information"
            : "verify your identity"}
        </h1>
      </HeaderStepLanding>

      <>
        <Formik
          initialValues={INITIAL_VALUES}
          validationSchema={ValidationSchema}
          onSubmit={submit}
          innerRef={formikRef}
        >
          {({ isValid, isSubmitting }) => (
            <Form data-testid="step-landing-form">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <Label htmlFor="email">Enter your email below to continue</Label>
              <VeroFormField
                name="email"
                id="email"
                label="Your email address"
                as={Input}
                data-testid="email"
              />
              <SubmitButtonWrapper>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={!isValid}
                  loading={isSubmitting}
                  className="submit-btn"
                  data-testid="submit"
                >
                  Continue
                </Button>
              </SubmitButtonWrapper>
            </Form>
          )}
        </Formik>
      </>
    </>
  );
};

export default StepLanding;
