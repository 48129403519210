import React, { useState } from "react";

import PropTypes from "prop-types";

import {
  useAssignAgentToDeal,
  useGetAssignableAgents,
} from "manager/hooks/api";
import useModal from "shared/hooks/useModal";
import { keyPropType } from "shared/utils/propTypes";

import AssignAgentToDealModal from "./AssignAgentToDealModal";

const AssignAgentToDealModalContainer = ({
  dealId,
  unitName,
  currentAgentId,
  afterAgentChanged,
}) => {
  const { selfApi } = useModal();
  const [selectedAgent, setAgent] = useState(currentAgentId);
  const { data: agents, isLoading: isAgentsLoading } = useGetAssignableAgents(
    dealId,
    { enabled: Boolean(dealId) }
  );

  const { assignEmployeeToDeal, isLoading: isAssigningAgent } =
    useAssignAgentToDeal();

  const submit = () => {
    assignEmployeeToDeal(
      { dealId, employeeId: selectedAgent },
      {
        onSuccess: () => {
          if (afterAgentChanged) {
            afterAgentChanged();
          }
        },
        onSettled: () => selfApi.closeDialog(),
      }
    );
  };

  return (
    <AssignAgentToDealModal
      unitName={unitName}
      selectedAgent={selectedAgent}
      changeAgent={setAgent}
      submit={submit}
      isAgentsLoading={isAgentsLoading}
      agents={agents}
      isAssigningAgent={isAssigningAgent}
    />
  );
};

AssignAgentToDealModalContainer.propTypes = {
  dealId: keyPropType.isRequired,
  unitName: PropTypes.string,
  currentAgentId: keyPropType.isRequired,
  afterAgentChanged: PropTypes.func.isRequired,
};

AssignAgentToDealModalContainer.defaultProps = {
  unitName: undefined,
};

export default AssignAgentToDealModalContainer;
