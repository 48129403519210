import styled from "@emotion/styled";

const NewFilterModalContent = styled.div({
  ".type-select": {
    marginTop: "16px",
    marginBottom: "40px",
    width: "320px",
  },
});

export { NewFilterModalContent };
