import React from "react";

import FlexContainer from "shared/components/FlexContainer";
import Spacer from "shared/components/Spacer";

import {
  LumenDescriptionContent,
  LumenText,
} from "./styled";

const LumenDescription = () => (
  <LumenDescriptionContent>
    <FlexContainer flexDirection="column">
      <LumenText>
        Lumen categorizes applications with a rating scale based on predetermined formulas and parameters defined in the Company Settings.
      </LumenText>
      <Spacer size={Spacer.SIZES.xs} />
      <LumenText>
        If a specific data point affects the overall group recommendation, the individual detail cards in the application summary will include a colored tab corresponding to the data point&apos;s classification.
      </LumenText>
    </FlexContainer>
  </LumenDescriptionContent>
);

export default LumenDescription;
