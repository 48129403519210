import _ from "lodash";

import { toDollars } from "../utils";

import { Income, IncomeSummary } from "./interfaces";

const mapDays = {
  hourly: 1 / 24,
  weekly: 7,
  biweekly: 14,
  semimonthly: 15.25,
  monthly: 30.5,
  annual: 365.25,
};

export const mapIncomes = (
  incomeAsset?: Record<string, any>,
  income?: Record<string, any>,
  skippedReason?: string
): IncomeSummary => {
  // always return income
  const incomes: Income[] =
    incomeAsset?.incomes.map((income) => ({
      income: toDollars(income.meanIncome),
      name: income.name,
      status: income.status,
      lastPayment: new Date(income.lastPayment),
      frequency: income.frequency,
    })) ?? [];

  // calculate largest active revenue stream
  const dailyIncome = (income: Income) =>
    income.income * mapDays[income.frequency.toLowerCase()] ?? 0;
  const primary = incomes
    .filter((x) => x.status.match(/active/i))
    .reduce(
      (max, income) =>
        dailyIncome(income) > max.value
          ? { value: dailyIncome(income), income }
          : max,
      { value: Number.MIN_VALUE, income: <Income | undefined>undefined }
    ).income;

  const incomeDollars = toDollars(income?.value);
  const skipped = incomeAsset?.isIncomeSkipped || false;
  let totalIncome = 0;
  if (
    incomeDollars &&
    incomeAsset &&
    _.isNumber(incomeAsset.percentIncomeVerified)
  ) {
    totalIncome = incomeDollars * (incomeAsset.percentIncomeVerified / 100);
  } else {
    totalIncome =
      (incomeAsset?.totalIncome || incomeAsset?.totalActiveIncome || 0) / 100;
  }

  return {
    totalIncome,
    skipped,
    skippedReason: skipped ? skippedReason : undefined,
    percentVerified: incomeAsset?.percentIncomeVerified,
    primary,
    stated: toDollars(income?.value) || 0,
  };
};
