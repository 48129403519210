import { useEffect, useState } from "react";
import { queryCache } from "react-query";
import { mapValues } from "lodash";
import { useHistory } from "react-router-dom";
import { merge } from "rxjs";

import ROUTES from "manager/config/routes";
import { useRecalculateLumen } from "manager/hooks/api";
import { useConfettiContext } from "shared/components/confetti";

import { useDeviceType, usePermissions } from "shared/hooks";
import { transformRoute } from "shared/utils/routing";

import {
  useApproveDealModal,
  useMoreInfoDealModal,
  useCancelDealModal,
  useDeclineDealModal,
  useConditionallyProceedModal,
} from "../modals";
import { useSendToEntradaModal } from "../modals/send-to-entrada-modal";
import { useResendToPmsModal } from "../modals/resend-to-pms-modal";
import { AppStatus } from "../types";

import { ActionBarProps } from "./component";
import {
  Actions,
  ButtonType,
  LumenLabelByScore,
  LumenLabelColorByScore,
} from "./interfaces";
import { useVisiblePopover } from "./use-visible-popover";
import { getButtonsByStatus, getDefaultButtonByStatus } from "./utils";

export const useActionBar = ({
  status,
  showLumen,
  lumen,
  approved,
  dealId,
  refetch,
  applicantsSummary,
  conditionalProceed: showConditionalProceed,
  missingUnit,
  countryCriminalSearchInProgress,
  criminalSearchInProgress,
  backgroundReportsUnavailable,
  ocrProcessing,
  canManuallySendToPms,
  hasBeenSentToEntrata,
  lastSentToPmsAt,
  provider,
}: ActionBarProps) => {
  const { canRecalculateLumen } = usePermissions();

  const { recalculateLumen } = useRecalculateLumen({
    dealId,
    onSuccess: async () => {
      await queryCache.invalidateQueries('getLatestLumenV2Run')
      return refetch()
    }
  });

  const { show: sendToPms, ok$: onSendToPms } = useSendToEntradaModal();
  const { show: resendToPms, ok$: onResendToPms } = useResendToPmsModal();
  const { show: approve, ok$: onApprove } = useApproveDealModal();
  const { show: conditional, ok$: onConditional } = useMoreInfoDealModal();
  const { show: decline, ok$: onDecline } = useDeclineDealModal();
  const { show: cancel, ok$: onCancel } = useCancelDealModal();
  const history = useHistory();

  const { conditionallyProceed } = useConditionallyProceedModal({
    dealId,
    refetch,
    applicantsSummary,
  });

  const { visiblePopover, onVisibleChange, actionFromPopover } =
    useVisiblePopover();

  const { explode } = useConfettiContext();

  useEffect(() => {
    if (!refetch) return;
    merge(
      onApprove,
      onConditional,
      onDecline,
      onCancel,
      onSendToPms,
      onResendToPms
    ).subscribe(() => refetch());
  }, [onApprove, onConditional, onDecline, onCancel, onResendToPms, refetch]);

  useEffect(() => {
    onApprove.subscribe(explode);
  }, [onApprove]);

  let actions: Actions = {
    approve,
    cancel,
    conditional,
    decline,
    conditionallyProceed,
    recalculateLumen,
    sendToPms,
    resendToPms,
  };

  actions = mapValues(actions, (x) => () => actionFromPopover(x));

  const { score, items, tooltip } = lumen ?? {};
  const { isMobile, isMobileOrTablet } = useDeviceType();
  const [isCollapse, setIsCollapse] = useState<boolean>(!isMobile);
  const lumenLabel = LumenLabelByScore[score];
  const lumenLabelColor = LumenLabelColorByScore[score];

  if (showLumen && status === AppStatus.Submitted && lumen?.pending)
    status = AppStatus.Submitting;

  const lumenSubtitle =
    status === AppStatus.Empty
      ? "Waiting for all applications to be submitted before showing final Lumen score."
      : status === AppStatus.Submitting
      ? missingUnit
        ? "Please assign the application to a unit so Lumen can continue calculating."
        : countryCriminalSearchInProgress
        ? "You can still see the individual results, but your Lumen formula will be ready when we receive county criminal records."
        : criminalSearchInProgress
        ? "You can still see the individual results, but your Lumen formula will be ready when we receive criminal records."
        : backgroundReportsUnavailable
        ? "You can still see the individual results, but your Lumen formula will be ready when we receive all background records."
        : ocrProcessing
        ? "You can still see the individual results, but your Lumen formula will be ready when document uploads have been processed."
        : `Still calculating; you can see individual results, but please wait for all applications to be submitted and your Lumen formula to be complete before making a decision.`
      : null;

  const showLumenItems = showLumen && Boolean(items?.length);
  const button = getDefaultButtonByStatus({
    approved,
    showConditionalProceed,
    canManuallySendToPms,
    status,
    score,
    showLumen,
  });

  const buttons = getButtonsByStatus({
    approved,
    showConditionalProceed,
    canManuallySendToPms,
    status,
    provider,
  });

  if (showLumen) {
    buttons.push(ButtonType.recalculateLumen);
  }

  return {
    status,
    score,
    items,
    tooltip,
    lumenSubtitle,
    showLumenItems,
    isMobile,
    isMobileOrTablet,
    isCollapse,
    lumenLabel,
    lumenLabelColor,
    button,
    buttons,
    setIsCollapse,
    actions,
    visiblePopover,
    onVisibleChange,
    hasBeenSentToEntrata,
    canManuallySendToPms,
    lastSentToPmsAt,
  };
};
