import React from "react";

import { SIZES } from "shared/components/Modal";
import Spacer from "shared/components/Spacer";
import { RegularText } from "shared/components/Typography";

import { useDeviceType } from "shared/hooks";

import { StyledModal } from "../styled";

interface ExperianAuthModalProps {
  title: string;
  submit: () => void;
}

const ExperianAuthModal = ({ title, submit }: ExperianAuthModalProps) => {
  const { isMobile } = useDeviceType();

  return (
    <StyledModal
      title={title}
      submitButtonLabel="Continue"
      submitButtonSize={SIZES.lg}
      isMobile={isMobile}
      submit={submit}
    >
      <Spacer size={Spacer.SIZES.sm} />
      <RegularText>
        To view applicant credit & background reports from Experian, VERO must
        authenticate your identity and account information annually.
      </RegularText>
      <Spacer size={Spacer.SIZES.sm} />
      <RegularText>Complete this process now by pressing continue.</RegularText>
      <Spacer size={Spacer.SIZES.md} />
    </StyledModal>
  );
};

export default ExperianAuthModal;
