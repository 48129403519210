import React from "react";

import { Container, Step } from "./styled";

export type CustomItem = {
  title: Node | React.ReactNode;
  icon: Node | React.ReactNode;
};

export interface InstructionStepsProps {
  items: string[] | CustomItem[] | any;
  direction?: string;
  current?: number;
}

const InstructionSteps = ({ items }: InstructionStepsProps) => (
  <Container
    progressDot={(iconDot, { index }) =>
      items?.[index]?.icon ? items?.[index].icon : iconDot
    }
    current={items.length}
    direction="vertical"
    instructions="true"
  >
    {items.map((item) =>
      item.title ? (
        <Step key={item.title} title={item.title} />
      ) : (
        <Step key={item} title={item} />
      )
    )}
  </Container>
);

export default InstructionSteps;
