import { Portfolio } from "./interfaces";

export const getAssignedPropertiesLabel = (portfolio: Portfolio) => {
  const total = portfolio.properties.length;

  const assigned = portfolio.isAssigned
    ? portfolio.properties.length
    : portfolio.properties.reduce((prev, curr) => {
        let counter = prev;

        if (curr.isAssigned) {
          counter += 1;
        }

        return counter;
      }, 0);

  let label;

  if (assigned < total) {
    label = `${assigned}/${total}`;
  } else if (total > 0) {
    label = `all ${assigned}`;
  } else {
    label = `${total}`;
  }

  return label;
};

export const getPortfolios = (portfolios: Portfolio[], search: string) => {
  const searchValue = search && search.toLowerCase();

  let filteredPortfolios = portfolios
    .map((portfolio) => ({
      ...portfolio,
      assignedLabel: getAssignedPropertiesLabel(portfolio),
    }))
    .filter(
      (portfolio) =>
        portfolio.isAssigned ||
        portfolio.properties.some((property) => property.isAssigned)
    );

  filteredPortfolios = filteredPortfolios.map((portfolio) => {
    let filteredProperties = portfolio.isAssigned
      ? portfolio.properties
      : portfolio.properties.filter((property) => property.isAssigned);

    filteredProperties = filteredProperties.map((property) => ({
      ...property,
      fullAddress: `${property.address}, ${property.city}, ${property.state} ${property.zipcode}`,
    }));

    filteredProperties = filteredProperties.filter(
      (property) =>
        !search ||
        property.name.toLowerCase().indexOf(searchValue) > -1 ||
        property.fullAddress.toLowerCase().indexOf(searchValue) > -1
    );

    return {
      ...portfolio,
      properties: filteredProperties,
    };
  });

  filteredPortfolios = filteredPortfolios.filter(
    (portfolio) => portfolio.properties.length > 0
  );

  return filteredPortfolios;
};
