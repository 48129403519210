import _ from 'lodash'
import { useHistory, useParams } from 'react-router-dom'

import {
  OtherApplicants,
  useOtherApplicants,
} from 'manager/components/app-summary'
import {
  MoveApplicantModal,
  MoveApplicantModalProps,
  MoveApplicantModalProvider,
  RemoveApplicantModalProvider,
  RemoveApplicantModalV2,
  useMoveApplicantModal,
  useRemoveApplicantModal,
} from 'manager/components/app-summary/modals'
import BaseApplicantDropdown from 'manager/components/ApplicantDropdown'
import { Summary } from 'manager/components/ApplicationSummary'
import ConditionallyProceedInfoBanner from 'manager/components/ConditionallyProceedInfoBanner'
import Layout from 'manager/components/Layout'
import { EditIncomeAssetsModal } from 'manager/components/Modal'
import ROUTES from 'manager/config/routes'
import {
  useAddAttachments,
  useDeleteAttachments,
  useConfirmAttachments,
  useCancelAttachments,
  useGenerateApplicationPDF,
  useGetFullApplicantion,
  useGetDeal,
} from 'manager/hooks/api'
import { useManagerHeaderConfig } from 'manager/hooks/useManagerHeaderConfig'
import useUnblockApplicationItem from 'manager/hooks/useUnblockApplicationItem'
import { VisibleAttachments } from 'shared/components/Attachments/interfaces'
import { Delayed, withModals } from 'shared/components/vero-modal'
import { useApplicantTypes } from 'shared/hooks/api'
import {
  useGetOcrResults,
  useDeleteOcrResultById,
  useDeleteDocumentById,
  useGetOcrJobs,
  useGetDocuments,
} from 'shared/hooks/api/applicationQueries'
import useModal from 'shared/hooks/useModal'
import ApplicationSummary from 'shared/pages/ApplicationSummary'
import { showConditionallyProceed } from 'shared/utils/application'
import { mapOtherAttachments, mapRequiredAttachments } from 'shared/utils/state'

const ApplicationContainer = ({
  header,
  backLink,
  sidebar,
  match,
  ...props
}) => {
  const history = useHistory()
  const { openModalDialog } = useModal()
  const { headerAction } = useManagerHeaderConfig()
  const { id } = useParams<{ id?: string }>()
  const {
    fullApplication: application,
    isFullApplicationLoading,
    refetchFullApplication,
    isFullApplicationFetching,
  } = useGetFullApplicantion(id)

  const { data: applicantTypes } = useApplicantTypes()

  const {
    data: deal,
    isLoading: isDealLoading,
    refetch: refetchDeal,
  } = useGetDeal(application?.deal, {
    enabled: Boolean(application?.deal),
  })

  const personVrn = _.get(application, 'personVrn')
  const { ocrResults, refetchOcrResults } = useGetOcrResults(
    application?.personVrn,
    !_.isEmpty(personVrn),
    true,
  )
  const { ocrJobs } = useGetOcrJobs(
    { person: application?.personVrn },
    !_.isEmpty(personVrn),
  )
  const { documents } = useGetDocuments(
    `vrn:person::${personVrn}`,
    !_.isEmpty(personVrn),
  )

  const failedDocuments = []

  if (!_.isEmpty(ocrJobs) && !_.isEmpty(documents)) {
    const failedOcrJobs = _.filter(
      ocrJobs,
      (ocrJob) => _.get(ocrJob, 'status') === 'failed',
    )
    if (!_.isEmpty(failedOcrJobs)) {
      for (const ocrJob of failedOcrJobs) {
        const doc = _.find(
          documents,
          (document) =>
            _.get(document, 'documentId') === _.get(ocrJob, 'documentId'),
        )
        if (!_.isEmpty(doc)) {
          failedDocuments.push(
            _.chain(doc)
              .cloneDeep()
              .assign({ failedReason: _.get(ocrJob, 'failedReason') })
              .value(),
          )
        }
      }
    }
  }

  const { deleteOcrResultById } = useDeleteOcrResultById()
  const { deleteDocumentById } = useDeleteDocumentById(application?.id)

  const data = {
    application,
    applicantTypes,
    deal,
    loading:
      isFullApplicationLoading || isDealLoading || isFullApplicationFetching,
    requiredAttachments: (application?.requiredAttachments || []).map(
      mapRequiredAttachments,
    ),
    otherAttachments: (application?.otherAttachments || []).map(
      mapOtherAttachments,
    ),
    ocrResults,
    deleteOcrResultById,
    deleteDocumentById,
    refetchOcrResults,
    title: 'Applicant Summary',
    getDeal: refetchDeal,
    failedDocuments,
  }

  const editIncomeAndAssets = (applicationId) =>
    openModalDialog(EditIncomeAssetsModal, {
      applicationId,
      deal,
      refetchFullApplication,
    })

  const { show: showMoveModal, ok$: onMovedApplicant$ } =
    useMoveApplicantModal()
  const { show: showRemoveModal, ok$: onRemovedApplicant$ } =
    useRemoveApplicantModal()

  const { addApplicationAttachments, isLoading: isAddAttachmentsLoading } =
    useAddAttachments(refetchFullApplication)
  const {
    confirmApplicationAttachments,
    isLoading: isConfirmAttachmentsLoading,
  } = useConfirmAttachments(refetchFullApplication)
  const {
    cancelApplicationAttachments,
    isLoading: isCancelAttachmentsLoading,
  } = useCancelAttachments(refetchFullApplication)
  const {
    deleteApplicationAttachments,
    isLoading: isDeleteAttachmentsLoading,
  } = useDeleteAttachments(refetchFullApplication)

  const { downloadApplicationPDF } = useGenerateApplicationPDF(
    application?.applicationSummary?.id,
  )

  const unitName = application?.unitName || ''
  const dealBackLink = {
    children: backLink.children.replace(':unitName', unitName),
    to: backLink.to.replace(':id', application?.deal),
  }
  const summary = application?.applicationSummary
  const moveModalProps: MoveApplicantModalProps = {
    dealId: application?.deal,
    application: {
      id: summary?.id,
      firstName: summary?.firstName,
      lastName: summary?.lastName,
      isSubmitted: summary?.isSubmitted,
    },
    yardi: {
      isPrimary: summary?.isPrimary,
      property: deal?.property,
      unit: deal?.unit,
    },
  }

  const otherApplicantsProps = useOtherApplicants(
    deal?.id?.toString(),
    application?.id,
  )

  const OtherApplicantsList = () => (
    <OtherApplicants {...otherApplicantsProps} />
  )

  onRemovedApplicant$.subscribe(() => {
    otherApplicantsProps.appListProps?.refetchDeal?.() // refetchDeal on this deal id is cached in graphql so redirecting to the deals page on this dealId doesn't force a reload.
    history.push(ROUTES.deal.replace(':id', deal?.id))
  })

  onMovedApplicant$.subscribe(({ dealId }) => {
    history.push(ROUTES.deal.replace(':id', dealId))
  })

  const ApplicantDropdown = ({ canAccessSensitiveDealData }) => {
    const applicationSummary = application?.applicationSummary ?? {}

    const unblockApplicationItem = useUnblockApplicationItem(
      applicationSummary,
      refetchFullApplication,
    )

    return (
      <BaseApplicantDropdown
        downloadPdf={canAccessSensitiveDealData && downloadApplicationPDF}
        hasPermissionToDownloadPDF
        editIncomeAndAssets={() => editIncomeAndAssets(application?.id)}
        moveToDeal={showMoveModal}
        isSubmitted={applicationSummary?.isSubmitted}
        canBeUpdated={applicationSummary?.canBeUpdated}
        remove={showRemoveModal}
        unblockApplicationItem={unblockApplicationItem}
        removeApplicationDisabledTooltip={
          applicationSummary?.canBeRemoved?.helpText
        }
        moveApplicationDisabledTooltip={
          applicationSummary?.canBeMoved?.helpText
        }
      />
    )
  }

  const ManagerConditionallyProceedInfoBanner = () => {
    return <ConditionallyProceedInfoBanner lumenEnabled={deal?.lumenEnabled} />
  }

  const showConditionallyProceedInfoBanner =
    showConditionallyProceed(application)

  return (
    <Layout
      header={{
        ...header,
        backLink: dealBackLink,
        headerAction,
      }}
      sidebar={sidebar}
      withoutBackToTop
    >
      <ApplicationSummary
        title="Applicant Summary"
        // @ts-ignore
        editIncomeAndAssets={editIncomeAndAssets}
        removeApplicant={showRemoveModal}
        openMoveToAnotherDealModal={showMoveModal}
        addApplicationAttachments={addApplicationAttachments}
        deleteApplicationAttachments={deleteApplicationAttachments}
        confirmApplicationAttachments={confirmApplicationAttachments}
        cancelApplicationAttachments={cancelApplicationAttachments}
        attachmentActionInProgress={
          isAddAttachmentsLoading ||
          isDeleteAttachmentsLoading ||
          isCancelAttachmentsLoading ||
          isConfirmAttachmentsLoading
        }
        application={application}
        deal={deal}
        OtherApplicantsList={OtherApplicantsList}
        ApplicantDropdown={ApplicantDropdown}
        ConditionallyProceedInfoBanner={ManagerConditionallyProceedInfoBanner}
        showConditionallyProceedInfoBanner={showConditionallyProceedInfoBanner}
        history={history}
        Summary={Summary}
        backLink={backLink}
        showAttachmentsVisibilityAction
        showAttachmentsHeaders
        visibleAttachments={VisibleAttachments.ALL}
        match={match}
        {...props}
        {...data}
      />

      <Delayed delay={3000}>
        <RemoveApplicantModalV2 {...moveModalProps} />
        <MoveApplicantModal {...moveModalProps} />
      </Delayed>
    </Layout>
  )
}

export default withModals(
  ApplicationContainer,
  MoveApplicantModalProvider,
  RemoveApplicantModalProvider,
)
