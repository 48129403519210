import React, { useMemo } from "react";

import { useFormikContext } from "formik";

import { SelectContainer } from "manager/pages/Marketplace/integrations/yardi/ConfigureYardiProperty/section/styled";

import { Select, VeroFormField } from "shared/components/Form";

import { FullWidthSelect } from "./styled";

interface Option {
  value: any;
  label: string;
}

interface YardiDropdownMappingFieldProps {
  name?: string;
  dropdownPlaceholder?: string;
  options?: Array<Option> | {} | [];
  asFormField?: boolean;
}

const YardiDropdownMappingField = ({
  name = undefined,
  dropdownPlaceholder = undefined,
  options,
  asFormField = false,
}: YardiDropdownMappingFieldProps) => {
  const { values, setFieldValue } = useFormikContext();

  const optionItems = useMemo(
    () =>
      // @ts-ignore
      (options || []).map((option, index) => (
        <Select.Option
          key={option.value}
          value={option.value}
          data-testid={`option-${index}`}
        >
          {option.label}
        </Select.Option>
      )),
    [options]
  );

  if (asFormField) {
    return (
      <SelectContainer>
        <VeroFormField
          as={Select}
          id={name}
          name={name}
          label={dropdownPlaceholder}
          onChange={(value) => setFieldValue(name, value)}
        >
          {optionItems}
        </VeroFormField>
      </SelectContainer>
    );
  }

  return (
    // @ts-ignore
    <FullWidthSelect
      id={name}
      data-testid="select"
      name={name}
      value={values[name]}
      label={dropdownPlaceholder}
      onChange={(value) => {
        setFieldValue(name, value);
      }}
    >
      {optionItems}
    </FullWidthSelect>
  );
};

export default YardiDropdownMappingField;
