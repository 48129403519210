import React from "react";

import { Container, Content, Title, ContentLine } from "./styled";

export const CONTENT =
  "Magic Links allow you to accept applications for your company, properties, units or agents via a URL or QR code. " +
  "These can be embedded anywhere that you want to reach potential applicants. Let the magic begin!";

const MagicLinksHeader = () => (
  <Container>
    <Content>
      <Title data-testid="title">Magic Links</Title>
      <ContentLine>{CONTENT}</ContentLine>
    </Content>
  </Container>
);

export default MagicLinksHeader;
