import React from "react";

import ErrorModal from "./ErrorModal";

interface ErrorModalProps {
  submit: () => void;
  customMessage?: any;
}

const ErrorModalContainer = ({ submit, customMessage }: ErrorModalProps) => {
  return <ErrorModal title="Something went wrong" submit={submit} customMessage={customMessage} />;
};

export default ErrorModalContainer;
