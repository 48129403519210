import React from 'react'
import _ from 'lodash'
import Icon from 'shared/components/Icon'
import { LumenIcon } from 'shared/components/vega/icons'
import { ServiceCard, ServiceHeader } from 'shared/components/vega/Service'
import { Timestamp } from 'shared/components/vega/Timestamp'
import { ExplanationTooltip } from 'shared/components/vega/ExpressionEngine'
import { buildClassName } from 'shared/utils/vega'
import { getColorForLumenRun } from './utils'

const LumenV2Header = ({ lumenRun, result = undefined, card = false, minimal = false }) => {
  const showScore = _.isNil(result)
  const status = result?.evaluation ? (!!result?.result ? 'passed' : 'failed') : 'no-result'
  const lastEntry = _.last(result?.evaluation?.history) as any
  const checks = _.filter(result?.evaluation?.history, entry => _.isBoolean(entry?.result))

  if (showScore) {
    if (lumenRun?.error) {
      return null
    }

    const color = getColorForLumenRun(lumenRun)

    if (minimal) {
      return (
        <>
          <div className={buildClassName(['vega title small lumen-score-label', color?.className && color?.className])}>{lumenRun?.scoreLabel}</div>
          <Timestamp timestamp={lumenRun?.timestamp} small />
        </>
      )
    }

    return (
      <header className='vega flex no-gap'>
        <LumenIcon className='vega icon large lumen' />
        <div className={buildClassName(['vega title x-large lumen-score-label', color?.className && color?.className])} style={color?.style && color?.style}>
          <div className='vega sub-title'>lumen recommends</div>
          <div>{lumenRun?.scoreLabel}</div>
        </div>

        {/*<span className="score">{lumenRun?.score}</span>*/}
      </header>
    )
  }

  const body = <>
    {!result?.evaluation && <span className='vega info'>No formula or not enough data to evaluate.</span>}
    {lastEntry && (card ? <ExplanationTooltip entry={lastEntry} titleOnly /> : <span className='vega info'>{lastEntry?.explanation?.output?.title}</span>)}
  </>

  return card ? <ServiceCard
    status={status}
    label='lumen'
    value={_.lowerCase(status)}
    timestamp={lumenRun?.timestamp}
  >
    {result?.evaluation && <div className='vega flex'>
      <div className='vega flex centered no-gap'>
        <Icon.CheckGreenSmall className='vega icon' />
        <span className='vega info'>{_.chain(checks).filter('result').size().value()}</span>
      </div>

      <div className='vega flex centered no-gap'>
        <Icon.CrossRedSmall className='vega icon' />
        <span className='vega info'>{_.chain(checks).reject('result').size().value()}</span>
      </div>
    </div>}

    {body}
  </ServiceCard> : <ServiceHeader
    title={_.lowerCase(status)}
    status={status}
    timestamp={lumenRun?.timestamp}
  >
    {body}
  </ServiceHeader>
}

export default LumenV2Header
