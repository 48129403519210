import React from "react";

import { useGetSubmittedLeads } from "manager/hooks/api";
import { usePermissions } from "shared/hooks";
import { useGetApplicantTypes } from "shared/hooks/api";

import InviteApplicantsWithLeadsModal from "./InviteApplicantsWithLeadsModal";

const InviteApplicantsWithLeadsModalContainer = ({
  onSubmit,
  applicants: availableApplicants,
}) => {
  const { canInviteLeadsToApply, isPrescreeningEnabled } = usePermissions();
  const canInviteLeads = isPrescreeningEnabled && canInviteLeadsToApply;
  const { submittedLeads, isSubmittedLeadsLoading } = useGetSubmittedLeads({
    enabled: canInviteLeads,
  });

  const { applicantTypes, isLoading: applicantTypesLoading } = useGetApplicantTypes();

  const submit = ({ applicants, leads }, formikHelpers) => {
    const formattedLeads = leads.map(({ email, firstName, lastName }) => ({
      email,
      firstName,
      lastName,
      isLead: true,
    }));

    // NOTE: remove empty applicants and leads
    const allApplicants = [...applicants, ...formattedLeads].filter(
      ({ email }) => email
    );

    return onSubmit({ applicants: allApplicants }, formikHelpers);
  };

  return (
    <InviteApplicantsWithLeadsModal
      applicantTypes={applicantTypes}
      applicantTypesLoading={applicantTypesLoading}
      applicants={availableApplicants}
      submittedLeads={submittedLeads}
      isSubmittedLeadsLoading={isSubmittedLeadsLoading}
      onSubmit={submit}
      canInviteLeads={canInviteLeads}
    />
  );
};

export default InviteApplicantsWithLeadsModalContainer;
