import React from "react";

import { getFullnameData } from "shared/utils/applicant";

import CheckList from "../CheckList";

import { CheckIcon, EmptyIcon, SignedIcon } from "./styled";

interface ListItem {
  firstName: string;
  lastName: string;
  leaseSigned?: boolean;
  isSubmitted?: boolean;
  signed?: boolean;
  [key: string]: string | number | boolean;
}

interface ApplicantsCheckListProps {
  items?: ListItem[] | any;
  inline?: boolean;
  onClick?: () => void;
}

const ApplicantsCheckList = ({
  items = [],
  inline = false,
  ...props
}: ApplicantsCheckListProps) => {
  const itemWithMargin = items.some((item) => item.signed) && !inline;

  const getIcon = (item) => {
    if (item.checked) {
      if (item.signed) {
        return <SignedIcon data-testid="signed-icon" />;
      }
      return <CheckIcon withMargin={itemWithMargin} data-testid="check-icon" />;
    }

    return <EmptyIcon withMargin={itemWithMargin} data-testid="empty-icon" />;
  };

  const checkListItems = items.map(
    ({ firstName, lastName, isSubmitted, leaseSigned, id }) => ({
      key: id,
      label: getFullnameData({ firstName, lastName })?.fullNameOrNA,
      checked: isSubmitted,
      signed: leaseSigned,
    })
  );

  return (
    <CheckList
      items={checkListItems}
      getCustomIcon={getIcon}
      inline={inline}
      {...props}
    />
  );
};

export default ApplicantsCheckList;
