import React from "react";

import { SwitchTitleProps } from "../SwitchTitle/SwitchTitle";

import { SwitchTitleCustom } from "./styled";

const SwitchLight = (props: SwitchTitleProps) => (
  <SwitchTitleCustom {...props} />
);

export default SwitchLight;
