import axios from "axios";

import ROUTES from "manager/config/apiRoutes";
import {
  SubmittedLead,
  InviteLeadToApplyRequest,
} from "manager/interfaces/api";
import { API_URL } from "shared/lib/api/setupApi";
import { transformRoute } from "shared/utils/routing";

interface ArchiveLeadRequest {
  id: number;
  isArchived: boolean;
}

const api = {
  inviteLead: (lead) =>
    axios.post(`${API_URL}${ROUTES.inviteLead}`, lead).then(({ data }) => data),

  reinviteLead: (id) =>
    axios
      .post(
        transformRoute(`${API_URL}${ROUTES.reinviteLead}`, {
          dealId: id,
        }),
        {}
      )
      .then(({ data }) => data),

  updateLeadArchiveStatus: ({ id, isArchived }: ArchiveLeadRequest) =>
    axios.patch<ArchiveLeadRequest>(
      transformRoute(`${API_URL}${ROUTES.updateLeadArchiveStatus}`, { id }),
      {
        isArchived,
      }
    ),

  getLeads: ({
    leadStatus,
    dateMin,
    dateMax,
    nameOrEmail,
    ordering,
    page,
    pageSize,
    isArchived,
    propertyId,
    leadType,
  }) =>
    axios
      .get(`${API_URL}${ROUTES.leads}`, {
        params: {
          deal_status: leadStatus,
          invited_date__gte: dateMin,
          invited_date__lte: dateMax,
          applicant: nameOrEmail,
          ordering,
          page_size: pageSize,
          page,
          is_archived: isArchived,
          property_id__in: propertyId,
          lead_type: leadType.toUpperCase(),
        },
        // @ts-ignore
        clearStorageAndReloadOnBadRequest: true,
      })
      .then(({ data }) => data),

  getLeadsCounts: ({
    leadStatus,
    dateMin,
    dateMax,
    nameOrEmail,
    isArchived,
    propertyId,
  }) =>
    axios
      .get(`${API_URL}${ROUTES.leadsCounts}`, {
        params: {
          deal_status: leadStatus,
          invited_date__gte: dateMin,
          invited_date__lte: dateMax,
          applicant: nameOrEmail,
          is_archived: isArchived,
          property_id__in: propertyId,
        },
        // @ts-ignore
        clearStorageAndReloadOnBadRequest: true,
      })
      .then(({ data }) => data),

  getLead: (id) =>
    axios
      .get(transformRoute(`${API_URL}${ROUTES.lead}`, { id }))
      .then(({ data }) => data),

  togglePropertyEligibilityForPrequalification: (propertyId) =>
    axios.patch(
      `${API_URL}${transformRoute(
        ROUTES.togglePropertyEligibilityForPrequalification,
        {
          propertyId,
        }
      )}`
    ),

  getPrescreeningProperties: () =>
    axios
      .get(transformRoute(`${API_URL}${ROUTES.prescreeningProperties}`))
      .then(({ data }) => data),

  inviteLeadToApply: ({ dealId, unitListing }: InviteLeadToApplyRequest) =>
    axios
      .post(
        transformRoute(`${API_URL}${ROUTES.inviteLeadToApply}`, {
          id: dealId,
        }),
        { unitListing }
      )
      .then(({ data }) => data),

  getSubmittedLeads: () =>
    axios
      .get<void, { data: SubmittedLead[] }>(
        `${API_URL}${ROUTES.getSubmittedLeads}`
      )
      .then(({ data }) => data),

  generateLeadPDF: async ({ leadId }) =>
    axios
      .post(
        `${API_URL}${transformRoute(ROUTES.generateLeadPDF, {
          id: leadId,
        })}`
      )
      .then(({ data }) => data),
};

export default api;
