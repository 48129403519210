import styled from "@emotion/styled";

import { ActionLink } from "shared/components/Links";
import { MEDIA_QUERIES, COLORS } from "shared/config/constants";

const EmployeeActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  & > *:not(:last-child): {
    margin-right: 24px;
  }
`;

const AssignToPropertiesLink = styled(ActionLink)`
  ${MEDIA_QUERIES.w.ltMd} {
    display: none;
  }
`;

const PropertyCount = styled.span`
  font-weight: 300;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center";
  padding: 40px;
`;

const NoPropertiesLabel = styled.label`
  color: #807e8d;
  line-height: 130%;
  font-weight: 300;
  padding-left: 16px;
  font-size: 17px;
`;

const NoPropertiesContainer = styled.div`
  padding: 80px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EmployeeStatus = styled.span<{ isActive?: boolean }>`
  color: ${({ isActive }) =>
    isActive ? COLORS.violetTheme.green : COLORS.primary};
`;

export const EditEmployeeLink = styled.span`
  font-size: 16px;
  color: ${COLORS.accentDark};
  cursor: pointer;
`;

export {
  EmployeeActions,
  PropertyCount,
  AssignToPropertiesLink,
  LoaderContainer,
  NoPropertiesLabel,
  NoPropertiesContainer,
};
