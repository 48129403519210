import React from "react";

import { Form, useFormikContext } from "formik";

import { SearchableList, CollapsibleRadioList } from "shared/components/Form";
import Icon from "shared/components/Icon";

import { SelectCompanyFormProps } from "../interfaces";
import PopoverContent from "../PopoverContent";
import { FormContainer } from "../styled";

const SelectCompanyForm = ({
  isLoading,
  items = [],
  setSearchingValue,
  setSelectedCompany,
}: SelectCompanyFormProps) => {
  const { setFieldValue } = useFormikContext();

  return (
    <FormContainer>
      <Form>
        <SearchableList
          loading={isLoading}
          search={{
            fieldName: "companySearch",
            action: (value) => {
              setSearchingValue(value);
            },
            label: "Search by company name",
            width: "350px",
            debounce: true,
          }}
          placeholder={{
            label: "Search by company name",
            Icon: Icon.SearchIcon,
          }}
          List={CollapsibleRadioList}
          listContainerProps={{
            groupName: "company",
            onChange: ({ target: { value } }) => {
              setFieldValue("company", value);
              setSelectedCompany(items.find((item) => item.id === value));
            },
          }}
          listProps={{
            items: items.map((item) => ({
              description: "",
              key: item.id,
              label: item.name,
              popover: item.documentsAssigned.length
                ? {
                    content: (
                      <PopoverContent documents={item.documentsAssigned} />
                    ),
                  }
                : null,
            })),
          }}
          hasInputValue={false}
        />
      </Form>
    </FormContainer>
  );
};

export default SelectCompanyForm;
