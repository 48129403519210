import React, { useState, useEffect } from "react";

import PhoneInput, { PhoneInputProps } from "react-phone-input-2";

import RequiredIndicator from "shared/components/RequiredIndicator";
import {
  US_PHONE_COUNTRY_CODE,
  US_PHONE_COUNTRY_MASK,
} from "shared/config/constants";

import { Container, Error } from "../styled";

import {
  InputWrapper,
  inputStyle,
  dropdownStyle,
  PhoneInputLabel,
} from "./styled";
import "react-phone-input-2/lib/style.css";

export type PhoneNumberInputProps = PhoneInputProps & {
  id: string;
  label: string;
  error?: string | string[];
  required?: boolean;
  setFieldValue: (name: string, value: string) => void;
  name: string;
  value?: string;
  focused?: boolean;
  disabled?: boolean;
  disableDropdown?: boolean;
};

export const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
  id,
  label,
  error = "",
  required = false,
  setFieldValue,
  name,
  value: baseValue = "",
  focused = false,
  disabled = false,
  disableDropdown = false,
  ...props
}) => {
  const [value, setValue] = useState<string>();

  useEffect(() => {
    setValue(!baseValue ? US_PHONE_COUNTRY_CODE : baseValue);
  }, [baseValue]);

  return (
    <>
      <Container>
        <PhoneInputLabel
          htmlFor={id}
          focused={focused}
          value={Boolean(value)}
          disabled={disabled}
          valid={!error}
        >
          {label}
          <RequiredIndicator required={required} />
        </PhoneInputLabel>
        <InputWrapper>
          <PhoneInput
            {...props}
            disableDropdown={disableDropdown}
            data-testid="phone-number-input"
            placeholder=""
            onChange={(phoneNumber) => {
              setValue(phoneNumber);
              setFieldValue(name, phoneNumber);
            }}
            value={value}
            disabled={disabled}
            inputProps={{ id }}
            inputStyle={inputStyle}
            dropdownStyle={dropdownStyle}
            masks={{ us: US_PHONE_COUNTRY_MASK }}
          />
        </InputWrapper>
      </Container>
      {error && <Error>{error}</Error>}
    </>
  );
};
