import { createAction } from "redux-actions";

import * as types from "./types";

export const receiveUnit = createAction(types.RECEIVE_UNIT, ({ unit }) => ({
  unit,
}));

export const receiveUnits = createAction(types.RECEIVE_UNITS, ({ units }) => ({
  units,
}));

export const receiveUnitsTable = createAction(
  types.RECEIVE_UNITS_TABLE,
  ({ units }) => ({
    units,
  })
);
