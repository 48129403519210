import React from "react";

import PropTypes from "prop-types";

import { MAGIC_LINK_TYPE } from "manager/pages/MagicLinks/config";
import Icon from "shared/components/Icon";
import { ActionLink } from "shared/components/Links";
import Placeholder from "shared/components/Table/Placeholder";

import { UnitNameWrapper, UnitRow } from "./styled";

const UnitsList = ({ units, onShareLinks, propertyName }) => {
  return units.length > 0 ? (
    units.map((unit) => (
      <UnitRow key={unit.id}>
        <UnitNameWrapper data-testid={`name-${unit.id}`}>
          {unit.name}
        </UnitNameWrapper>
        <div>
          <ActionLink
            Icon={Icon.QRCodeGreenIcon}
            data-testid={`share-${unit.id}`}
            onClick={() =>
              onShareLinks({
                magicLink: unit.magicLink,
                prescreeningMagicLink: unit.prescreeningMagicLink,
                name: `${unit.name}, ${propertyName}`,
                type: MAGIC_LINK_TYPE.UNIT,
              })
            }
          >
            Share
          </ActionLink>
        </div>
      </UnitRow>
    ))
  ) : (
    <UnitRow justifyContent="center" alignItems="center" noPadding>
      <Placeholder
        type={Placeholder.TYPES.noData}
        Icon={Icon.NoUnitsIcon}
        label="Units"
      />
    </UnitRow>
  );
};

UnitsList.propTypes = {
  onShareLinks: PropTypes.func.isRequired,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      magicLink: PropTypes.string.isRequired,
    })
  ),
  propertyName: PropTypes.string.isRequired,
};

UnitsList.defaultProps = {
  units: [],
};

export default UnitsList;
