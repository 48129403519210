import React from "react";

import { SidebarViewProps } from "../../interfaces";

import { Header, HeaderTitle } from "./styled";

const SidebarView = ({ children, title }: SidebarViewProps) => (
  <>
    <Header>
      <HeaderTitle>{title}</HeaderTitle>
    </Header>
    {children}
  </>
);

export default SidebarView;
