import React from "react";

import { useHistory } from "react-router-dom";

import ROUTES from "manager/config/routes";
import { useGetBluemoonPropertiesCount } from "manager/hooks/api";
import useModal from "shared/hooks/useModal";

import BluemoonHiddenPropertiesModal from "../BluemoonHiddenPropertiesModal";

import BluemoonPropertiesBar from "./BluemoonPropertiesBar";

const BluemoonPropertiesBarContainer = () => {
  const { openModalDialog } = useModal();
  const history = useHistory();

  const { propertiesCount } = useGetBluemoonPropertiesCount();

  const {
    notBoundProperties = 0,
    fullyBoundProperties = 0,
    hiddenProperties = 0,
  } = propertiesCount || {};

  const hasNotBoundProperties = Boolean(notBoundProperties);

  const handleBindProperties = () => {
    history.push(ROUTES.bluemoonPropertyBindings);
  };

  const openHiddenPropertiesModal = () =>
    openModalDialog(BluemoonHiddenPropertiesModal);

  const openFullyBoundedPropertiesPage = () =>
    history.push(ROUTES.bluemoonBoundProperties);

  const openPropertiesBindingPage = () =>
    history.push(ROUTES.bluemoonPropertyBindings);

  return (
    <BluemoonPropertiesBar
      notBoundProperties={notBoundProperties}
      fullyBoundProperties={fullyBoundProperties}
      hiddenProperties={hiddenProperties}
      hasNotBoundProperties={hasNotBoundProperties}
      handleBindProperties={handleBindProperties}
      openHiddenPropertiesModal={openHiddenPropertiesModal}
      openFullyBoundedPropertiesPage={openFullyBoundedPropertiesPage}
      openPropertiesBindingPage={openPropertiesBindingPage}
    />
  );
};

export default BluemoonPropertiesBarContainer;
