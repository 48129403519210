import PropTypes from "prop-types";

export const SYSTEM_AUDIT_NOTE_TAG = {
  id: "NT",
  value: "Note",
};

export const systemAuditPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  createdAt: PropTypes.string.isRequired,
  description: PropTypes.string,
  note: PropTypes.string,
  tag: PropTypes.string.isRequired,
  createdByName: PropTypes.string,
  createdByEmail: PropTypes.string,
  source: PropTypes.string.isRequired,
  sourceLabel: PropTypes.string.isRequired,
  referenceCode: PropTypes.number.isRequired,
});

export const TEAM_NOTE_LABEL = "Team Note";
