import styled from "@emotion/styled";

import BaseCheckList from "shared/components/ApplicantsCheckList";
import Well from "shared/components/Well";
import { MEDIA_QUERIES } from "shared/config/constants";

export const Subtitle = styled.span`
  display: flex;
  flex-wrap: wrap;
  gap: 8;
`;

export const ApplicantsCheckList = styled(BaseCheckList)`
  flex: 1;
`;

export const MessageWrapper = styled(Well)`
  margin: 24px 0;

  .message-icon {
    margin-right: 24px;
  }
`;

export const UploadWallWrapper = styled.div`
  .ant-latest-upload-list-picture-card > div:nth-child(2n) {
    margin-right: 0;
  }
`;

export const Attachments = styled("div")({
  marginLeft: "24px",
  [MEDIA_QUERIES.w.ltSm]: {
    marginLeft: 0,
  },
});
