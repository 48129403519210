import styled from "@emotion/styled";

import { colors } from "shared/config/constants";
import CheckPrimary from "shared/icons/check-primary.svg";

import { SwitchTitle } from "../SwitchTitle";

export const SwitchTitleCustom = styled(SwitchTitle)`
  .ant-switch {
    height: 20px;
    min-width: 36px;
    &::after {
      width: 12px;
      height: 12px;
      margin-top: 2px;
    }
  }

  .ant-switch-checked {
    background: ${colors.secondary[500]};
    &::after {
      content: url(${CheckPrimary});
      width: 16px;
      height: 16px;
      margin-top: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;
