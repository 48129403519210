import React, { useState } from "react";

import { Search } from "shared/components/Form";
import Icon from "shared/components/Icon";

import { MobileFiltersProps as IMobileFiltersProps } from "./interfaces";
import { Container, SearchContainer, FilterIcon } from "./styled";

interface MobileFiltersProps {
  search: IMobileFiltersProps["search"];
  openFilterModal: () => void;
  showFilters: boolean;
}

const MobileFilters = ({
  search,
  openFilterModal,
  showFilters,
}: MobileFiltersProps) => {
  const [openSearch, setOpenSearch] = useState(false);

  return (
    <>
      <Container
        className="mobile-filters-container"
        data-testid="mobile-filters"
      >
        {showFilters && <FilterIcon onClick={openFilterModal} />}
        {search && (
          <Icon.SearchIcon onClick={() => setOpenSearch(!openSearch)} />
        )}
      </Container>
      {openSearch && (
        <SearchContainer>
          {/* @ts-ignore */}
          <Search
            id="search-table"
            name="search-table"
            value={search.value}
            label={search.placeholder}
            style={{ width: search.width || 310 }}
            onSearch={search.action}
            debounce={search.debounce}
          />
        </SearchContainer>
      )}
    </>
  );
};

export default MobileFilters;
