import { COUNTY_CRIMINAL_SEARCH_STATUS_ENUM } from "shared/config/constants";
import { CountyCriminalSearchStatus } from "shared/graphql";

// The `Request County Records` action is enabled only if the `countyCriminalSearchStatus` is `NOT_REQUESTED` or `PARTIALLY_REQUESTED`,
// otherwise we disable the action. If the status is `NOT_ALLOWED` we hide the action completely.
export const shouldDisableLink = (
  countyCriminalSearchStatus?: CountyCriminalSearchStatus,
  isLoading?: boolean
) =>
  [
    COUNTY_CRIMINAL_SEARCH_STATUS_ENUM.NOT_AVAILABLE,
    COUNTY_CRIMINAL_SEARCH_STATUS_ENUM.IN_PROGRESS,
    COUNTY_CRIMINAL_SEARCH_STATUS_ENUM.PARTIALLY_COMPLETED,
    COUNTY_CRIMINAL_SEARCH_STATUS_ENUM.COMPLETED,
    COUNTY_CRIMINAL_SEARCH_STATUS_ENUM.FAILED,
    COUNTY_CRIMINAL_SEARCH_STATUS_ENUM.NO_COUNTY_TO_RUN,
  ].includes(countyCriminalSearchStatus?.value) || isLoading;

export const shouldShowAction = (countyCriminalSearchStatus?: string) =>
  !!countyCriminalSearchStatus &&
  countyCriminalSearchStatus !== COUNTY_CRIMINAL_SEARCH_STATUS_ENUM.NOT_ALLOWED;
