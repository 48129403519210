import axios from "axios";

import ROUTES from "shared/config/apiRoutes";
import { API_URL } from "shared/lib/api/setupApi";
import { transformRoute } from "shared/utils/routing";

const api = {
  createUnitListing: ({ rent, securityDeposit, unitAvailableOn, unit, note }) =>
    axios.post(`${API_URL}${ROUTES.unitListings}`, {
      rent,
      securityDeposit,
      unitAvailableOn,
      unit,
      note,
    }),
  closeUnitListing: ({ id }) =>
    axios.post(`${API_URL}${transformRoute(ROUTES.closeUnitListing, { id })}`),
  closeListingAndMarkUnitAsRented: ({
    id,
    rentedFrom,
    rentedUntil,
    rent,
    securityDeposit,
  }) =>
    axios.post(
      `${API_URL}${transformRoute(ROUTES.closeListingAndMarkUnitAsRented, {
        id,
      })}`,
      {
        rentedFrom,
        rentedUntil,
        rent,
        securityDeposit,
      }
    ),
  editUnitListing: ({ rent, securityDeposit, unitAvailableOn, id, note }) =>
    axios.patch(`${API_URL}${transformRoute(ROUTES.editUnitListing, { id })}`, {
      rent,
      securityDeposit,
      unitAvailableOn,
      note,
    }),
};

export default api;
