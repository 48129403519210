import React from "react";

import AnimatedBars from "shared/components/AnimatedBars";
import Icon from "shared/components/Icon";
import { MediumTitle } from "shared/components/Typography";
import { useMessagesPlayer } from "shared/hooks/useMessagesPlayer";

import { ProgressContainer, ModalBody, StyledModal } from "./styled";

export interface IAnimatedPendingActionModal {
  modalId: string;
  isLoading: boolean;
  messages: string[];
  timeout: number;
  closeModal: () => void;
  afterCloseOperation?: () => void;
  finalMessageElement?: React.ElementType;
  children?: React.ReactNode | any;
}

const AnimatedPendingActionModal = ({
  modalId,
  isLoading,
  closeModal,
  afterCloseOperation = () => {},
  messages,
  timeout,
  finalMessageElement = Icon.OperationSuccess,
  children = undefined,
}: IAnimatedPendingActionModal) => {
  const finalAction = () => {
    closeModal();
    afterCloseOperation();
  };
  const { message } = useMessagesPlayer({
    isLoading,
    messages,
    timeout,
    finalAction,
  });

  const FinalUiElement = finalMessageElement;

  return (
    <StyledModal
      showTitle={false}
      showFooter={false}
      id={modalId}
      closable={false}
    >
      <ModalBody>
        <MediumTitle>{message}</MediumTitle>
        <ProgressContainer>
          {isLoading ? (
            <AnimatedBars data-testid="animated-bars" />
          ) : (
            <FinalUiElement />
          )}
        </ProgressContainer>
        {children && { ...children }}
      </ModalBody>
    </StyledModal>
  );
};

export default AnimatedPendingActionModal;
