import styled from "@emotion/styled";

import { RegularText } from "shared/components/Typography";
import Well from "shared/components/Well";
import { colors, MEDIA_QUERIES } from "shared/config/constants";
import { AlignedCenterFlexPartialCss } from "shared/styles/commonStyles";

const LumenDescriptionContent = styled.div`
  padding: 0;
  padding-top: 16px;
  padding-bottom: 40px;
  width: 620px;
  ${MEDIA_QUERIES.w.ltMd} {
    width: 100%;
  }
  .message {
    margin: 0;
  }
`;

const LumenDescriptionStatuses = styled(Well)`
  margin-top: 27px;
  border: none;
  padding: 32px 25px;
  background-color: ${colors.neutral[200]};
  border-radius: 4px;
  .lumen-status {
    &:not(:last-of-type) {
      margin-bottom: 24px;
    }
    .lumen-status-row {
      ${{ ...AlignedCenterFlexPartialCss }}
      &:not(:last-of-type) {
        margin-bottom: 0px;
      }
    }
    .first-col {
      ${{ ...AlignedCenterFlexPartialCss }}
      width: 47px;
      min-width: 47px;
      svg {
        height: 32px;
        width: 32px;
      }
    }
  }
`;

const LumenText = styled(RegularText)`
  color: ${colors.primary[400]};
`;

export { LumenDescriptionContent, LumenDescriptionStatuses, LumenText };
