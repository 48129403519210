import React, { Fragment } from "react";

import PropTypes from "prop-types";

import { TabsContainer } from "./styled";

const SettingsTabs = ({ items, activeTab, onTabChange }) => {
  const navigateToTab = (tabName) => onTabChange(tabName);

  return (
    <>
      <TabsContainer
        tabs={items}
        activeKey={activeTab}
        onChange={navigateToTab}
      />
      {items.map((item) => (
        <Fragment key={item.key}>
          {item.key === activeTab && item.node}
        </Fragment>
      ))}
    </>
  );
};

SettingsTabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.node.isRequired,
      node: PropTypes.node.isRequired,
    })
  ).isRequired,
  onTabChange: PropTypes.func.isRequired,
};

export default SettingsTabs;
