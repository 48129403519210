import { Key } from 'react'

import axios from 'axios'
import _ from 'lodash'
import MANAGER_API_ROUTES from 'manager/config/apiRoutes'
import { LumenResultScore as LumenResponseScore } from 'manager/config/lumen.config'
import { API_URL } from 'shared/lib/api/setupApi'
import { notifyUnwrap } from 'shared/lib/api/utils'
import { transformRoute } from 'shared/utils/routing'
import { setPrefix } from 'shared/utils/vrn'

const api = {
  getLumenFormulas: () =>
    axios
      .get(`${API_URL}${MANAGER_API_ROUTES.lumenFormulas}`)
      .then(({ data }) => data),
  createLumenFormula: (createFormulaDto: any) =>
    axios
      .post(`${API_URL}${MANAGER_API_ROUTES.lumenFormulas}`, createFormulaDto)
      .then(({ data }) => data),
  editLumenFormula: (formulaId: Key) =>
    axios.post(
      transformRoute(`${API_URL}${MANAGER_API_ROUTES.editLumenFormula}`, {
        formulaId,
      })
    ),
  renameLumenFormula: ({
    formulaId,
    renameFormulaDto,
  }: RenameLumenFormulaRequest) =>
    axios.patch(
      transformRoute(`${API_URL}${MANAGER_API_ROUTES.lumenFormula}`, {
        formulaId,
      }),
      renameFormulaDto
    ),
  duplicateLumenFormula: ({
    formulaId,
    duplicateFormulaDto,
  }: DuplicateLumenFormulaRequest) =>
    axios.post(
      transformRoute(`${API_URL}${MANAGER_API_ROUTES.duplicateLumenFormula}`, {
        formulaId,
      }),
      duplicateFormulaDto
    ),
  deleteLumenFormula: (formulaId) =>
    axios.delete(
      transformRoute(`${API_URL}${MANAGER_API_ROUTES.lumenFormula}`, {
        formulaId,
      })
    ),
  getLumenFormulaRevision: ({ formulaId, revisionId }) =>
    axios
      .get(
        transformRoute(`${API_URL}${MANAGER_API_ROUTES.lumenFormulaRevision}`, {
          formulaId,
          revisionId,
        })
      )
      .then(({ data }) => data),
  updateLumenFormulaRevision: ({ formulaId, revisionId, updateDto }) =>
    axios.patch(
      transformRoute(`${API_URL}${MANAGER_API_ROUTES.lumenFormulaRevision}`, {
        formulaId,
        revisionId,
      }),
      updateDto
    ),
  completeLumenFormulaRevision: ({
    formulaId,
    revisionId,
    updateDto,
  }: LumenFormulaRevisionRequest) =>
    axios.post(
      transformRoute(
        `${API_URL}${MANAGER_API_ROUTES.completeLumenFormulaRevision}`,
        {
          formulaId,
          revisionId,
        }
      ),
      updateDto
    ),
  getLumenFormulaAssignments: (formulaId) =>
    axios
      .get(
        transformRoute(
          `${API_URL}${MANAGER_API_ROUTES.lumenFormulaAssignments}`,
          {
            formulaId,
          }
        )
      )
      .then(({ data }) => data),
  assignLumenFormula: ({ formulaId, assignments }: AssignLumenFormulaRequest) =>
    axios.post(
      transformRoute(`${API_URL}${MANAGER_API_ROUTES.lumenFormulaAssign}`, {
        formulaId,
      }),
      assignments
    ),
  getLumenFormulaTemplates: () =>
    axios
      .get(
        transformRoute(`${API_URL}${MANAGER_API_ROUTES.lumenFormulaTemplates}`)
      )
      .then(({ data }) => data),
  markLumenFormulaAsTemplate: ({
    formulaId,
    markFormulaAsTemplateDto,
  }: LumenFormulaTemplateRequest) =>
    axios.post(
      transformRoute(
        `${API_URL}${MANAGER_API_ROUTES.lumenFormulaMarkAsTemplate}`,
        {
          formulaId,
        }
      ),
      markFormulaAsTemplateDto
    ),
}

export type RenameLumenFormulaRequest = {
  formulaId: Key;
  renameFormulaDto: any;
};

export type DuplicateLumenFormulaRequest = {
  formulaId: string;
  duplicateFormulaDto: any;
};

export type LumenFormulaRevisionRequest = {
  formulaId: Key;
  revisionId: string;
  updateDto: any;
};

export type LumenFormulaTemplateRequest = {
  formulaId: Key;
  markFormulaAsTemplateDto: { name: string };
};

export type AssignLumenFormulaRequest = {
  formulaId: Key;
  assignments: {
    portfolios: Key[];
    properties: Key[];
  };
};

export enum LumenResponseMessageType {
  highlight = 'HIGHLIGHT',
  lowlight = 'LOWLIGHT',
}

enum LumenResponseApplicantType {
  applicant = 1,
  guarantor = 2,
  occupant = 3,
}

type LumenResponseApplicant = {
  id: Key;
  type: LumenResponseApplicantType;
  score: LumenResponseScore;
  messages?: LumenResponseMessage[];
};

type LumenResponseMessage = {
  type: LumenResponseMessageType;
  text: string;
  code?: LumenCode;
  description?: string;
};

export type LumenResponse = {
  dealId: number;
  score: LumenResponseScore;
  messages: LumenResponseMessage[];
  applicants: LumenResponseApplicant[];
};

export enum LumenCode {
  BCC_SKIPPED = 'BCC_SKIPPED',
  IA_SKIPPED = 'IA_SKIPPED',
  INCOME_SKIPPED = 'INCOME_SKIPPED',
  ASSETS_SKIPPED = 'ASSETS_SKIPPED',
  APPLICANTS_MEET_CS_IDEAL = 'APPLICANTS_MEET_CS_IDEAL',
  APPLICANTS_MEET_CS_MINIMUM = 'APPLICANTS_MEET_CS_MINIMUM',
  SOME_APPLICANTS_CS_NO_MINIMUM = 'SOME_APPLICANTS_CS_NO_MINIMUM',
  GUARANTORS_MEET_CS_IDEAL = 'GUARANTORS_MEET_CS_IDEAL',
  GUARANTORS_MEET_CS_MINIMUM = 'GUARANTORS_MEET_CS_MINIMUM',
  SOME_GUARANTORS_CS_NO_MINIMUM = 'SOME_GUARANTORS_CS_NO_MINIMUM',
  NO_CREDIT_PUBLIC_RECORDS = 'NO_CREDIT_PUBLIC_RECORDS',
  NO_CREDIT_BANKRUPTCIES_RECORDS = 'NO_CREDIT_BANKRUPTCIES_RECORDS',
  NO_CREDIT_CRUCIAL_RECORDS = 'NO_CREDIT_CRUCIAL_RECORDS',
  CREDIT_RECORDS_SAFETYNET = 'CREDIT_RECORDS_SAFETYNET',
  CREDIT_RECORDS_CRUCIAL = 'CREDIT_RECORDS_CRUCIAL',
  CREDIT_RECORDS_IGNORED = 'CREDIT_RECORDS_IGNORED',
  CREDIT_CHECK_NOT_SUPPORTED = 'CREDIT_CHECK_NOT_SUPPORTED',
  INCOME_MEET_IDEAL = 'INCOME_MEET_IDEAL',
  INCOME_MEET_MINIMUM = 'INCOME_MEET_MINIMUM',
  INCOME_NO_MINIMUM = 'INCOME_NO_MINIMUM',
  ASSETS_MEET_IDEAL = 'ASSETS_MEET_IDEAL',
  ASSETS_MEET_MINIMUM = 'ASSETS_MEET_MINIMUM',
  ASSETS_NO_MINIMUM = 'ASSETS_NO_MINIMUM',
  NO_CRIMINAL_RECORDS = 'NO_CRIMINAL_RECORDS',
  NO_CRIMINAL_CRUCIAL_RECORDS = 'NO_CRIMINAL_CRUCIAL_RECORDS',
  CRIMINAL_RECORDS_SAFETYNET = 'CRIMINAL_RECORDS_SAFETYNET',
  CRIMINAL_RECORDS_CRUCIAL = 'CRIMINAL_RECORDS_CRUCIAL',
  CRIMINAL_RECORDS_IGNORED = 'CRIMINAL_RECORDS_IGNORED',
  NO_HOUSING_COURT_RECORDS = 'NO_HOUSING_COURT_RECORDS',
  NO_HOUSING_COURT_CRUCIAL_RECORDS = 'NO_HOUSING_COURT_CRUCIAL_RECORDS',
  HOUSING_COURT_RECORDS_SAFETYNET = 'HOUSING_COURT_RECORDS_SAFETYNET',
  HOUSING_COURT_RECORDS_CRUCIAL = 'HOUSING_COURT_RECORDS_CRUCIAL',
  HOUSING_COURT_RECORDS_IGNORED = 'HOUSING_COURT_RECORDS_IGNORED',
  APPLICANTS_COLLECTIONS_EXCEED_MAX = 'APPLICANTS_COLLECTIONS_EXCEED_MAX',
  GUARANTORS_COLLECTIONS_EXCEED_MAX = 'GUARANTORS_COLLECTIONS_EXCEED_MAX',
  COUNTY_CRIMINAL_REPORT_FAILED = 'COUNTY_CRIMINAL_REPORT_FAILED',
  COUNTY_CRIMINAL_REPORT_PARTIALLY_REQUESTED = 'COUNTY_CRIMINAL_REPORT_PARTIALLY_REQUESTED',
  NO_COLLECTION_RECORDS = 'NO_COLLECTION_RECORDS',
  NO_COLLECTION_CRUCIAL_RECORDS = 'NO_COLLECTION_CRUCIAL_RECORDS',
  COLLECTION_RECORDS_SAFETYNET = 'COLLECTION_RECORDS_SAFETYNET',
  COLLECTION_RECORDS_CRUCIAL = 'COLLECTION_RECORDS_CRUCIAL',
  COLLECTION_RECORDS_IGNORED = 'COLLECTION_RECORDS_IGNORED',
  IDENTITY_NOT_VERIFIED = 'IDENTITY_NOT_VERIFIED',
}

export const getLumenResultForDeal = ({ dealId }): Promise<LumenResponse> =>
  notifyUnwrap(
    () =>
      axios.get<LumenResponse>(
        transformRoute(`${API_URL}${MANAGER_API_ROUTES.lumenResultForDeal}`, {
          dealId,
        })
      ),
    null,
    'Failed to get Lumen result for application.'
  )

export const listLumenV2Formulas = async (location: string, detailed: boolean = false) => {
  if (location) {
    try {
      const response = await axios.get(
        `${API_URL}/next/lumen/formula`,
        {
          params: _.pickBy({
            location,
            detailed
          }),
          // @ts-ignore
          returnRaw: true
        }
      )

      return response?.data
    } catch (error) {
      return ({
        error: error?.errors
      })
    }
  }
}

export const getLatestLumenV2Run = async (application) => {
  if (application) {
    try {
      const response = await axios.get(
        `${API_URL}/next/lumen/run`,
        {
          params: {
            application: setPrefix(application, 'application'),
            detailed: true
          },
          // @ts-ignore
          returnRaw: true
        }
      )

      return response?.data
    } catch (error) {
      return ({
        error: error?.errors
      })
    }
  }
}

export const runLumenV2 = async (application) => {
  if (application) {
    try {
      const response = await axios.post(
        `${API_URL}/next/lumen/run`,
        {
          application: setPrefix(application, 'application')
        },
        {
          // @ts-ignore
          convertToSnakeCase: false,
          returnRaw: true
        }
      )

      return response?.data
    } catch (error) {
      return ({
        error: error?.errors
      })
    }
  }
}

export const createLumenV2Formula = async (data) => {
  try {
    const response = await axios.post(
      `${API_URL}/next/lumen/formula`,
      data,
      {
        // @ts-ignore
        convertToSnakeCase: false,
        returnRaw: true
      }
    )

    return response?.data
  } catch (error) {
    return ({
      error: error?.errors
    })
  }
}

export const updateLumenV2Formula = async ({ id, data }) => {
  try {
    const response = await axios.patch(
      `${API_URL}/next/lumen/formula/${encodeURIComponent(id)}`,
      data,
      {
        // @ts-ignore
        convertToSnakeCase: false,
        returnRaw: true
      }
    )

    return response?.data
  } catch (error) {
    return ({
      error: error?.errors
    })
  }
}

export const deleteLumenV2Formula = async (id) => {
  try {
    const response = await axios.delete(
      `${API_URL}/next/lumen/formula/${encodeURIComponent(id)}`,
      {
        // @ts-ignore
        convertToSnakeCase: false,
        returnRaw: true
      }
    )

    return response?.data
  } catch (error) {
    return ({
      error: error?.errors
    })
  }
}

export const deleteExplanation = async (id: string) => {
  if (id) {
    try {
      const response = await axios.delete(`${API_URL}/explanation/${id}`)

      return response?.data
    } catch (error) {
      return error
    }
  }
}

export default api
