import styled from "@emotion/styled";

import { Select } from "shared/components/Form";

export const Zoom = styled(Select)({
  maxWidth: 110,
  minWidth: 110,
  "& .ant-select-selection": {
    maxWidth: 110,
    minWidth: 110,
  },
});
