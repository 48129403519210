import styled from "@emotion/styled";

import Well from "shared/components/Well";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

interface StyledWellProps {
  isRequirementMet?: boolean;
}

const StyledWell = styled(Well)<StyledWellProps>`
  padding: ${({ isRequirementMet }) =>
    isRequirementMet ? "28px" : "56px 28px 28px"};
  position: relative;

  .label {
    position: absolute;
    top: 0;
    left: 0;
  }

  ${MEDIA_QUERIES.w.ltSm} {
    padding-top: ${({ isRequirementMet }) =>
      isRequirementMet ? "28px" : "80px"};
  }
`;

const RequirementLabel = styled.div`
  padding: 4px 15px;
  border-bottom-right-radius: 15px;
  background: ${COLORS.backgroundLightOrange};
  display: flex;
  align-items: center;

  .warn-icon {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    margin-right: 10px;
  }
`;

export { StyledWell, RequirementLabel };
