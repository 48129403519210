import React, { ReactNode } from "react";

import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import { RegularText, SmallText } from "shared/components/Typography";

import { BaseConditionWrap } from "./styled";

interface BaseConditionProps {
  conditionLabel: string;
  onDelete: () => void;
  children: ReactNode;
  conditionDescription?: string;
}

const BaseCondition = ({
  conditionLabel,
  conditionDescription,
  onDelete,
  children,
}: BaseConditionProps) => {
  return (
    <BaseConditionWrap>
      <FlexContainer flexDirection="column" className="title">
        <RegularText strong>{conditionLabel}</RegularText>
        {conditionDescription && (
          <SmallText light>{conditionDescription}</SmallText>
        )}
      </FlexContainer>
      <div className="content">
        <FlexContainer
          justifyContent="space-between"
          alignItems="center"
          fullWidth
        >
          {children}
          <Icon.BaseDeleteNewIcon className="delete-icon" onClick={onDelete} />
        </FlexContainer>
      </div>
    </BaseConditionWrap>
  );
};

export default BaseCondition;
