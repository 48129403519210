import React from "react";

import styled from "@emotion/styled";

import { getStyles as getUnderlineLinkStyles } from "shared/components/Links/UnderlineLink/styled";
import { MEDIA_QUERIES, COLORS, TRANSITIONS } from "shared/config/constants";

import { StyledLink, StyledStandardLink } from "../styled";

const getStyles = (props) => ({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  ...(props.size === "sm" && {
    fontSize: "12px",
  }),
  ...(props.disabled && {
    color: COLORS.disabledText,
  }),
  [MEDIA_QUERIES.w.gteMd]: {
    "svg path": {
      transition: TRANSITIONS.default,
    },
  },
  ...(!props.light && {
    "&:hover": {
      span: {
        color: `${COLORS.underlineLink.hover.text} !important`,
        borderBottomColor: `${COLORS.underlineLink.hover.borderBottom} !important`,
      },
    },
  }),
  ...(props.light && {
    "&:hover": {
      span: {
        color: `${COLORS.underlineLink.lightHover.text} !important`,
        borderBottomColor: `${COLORS.underlineLink.lightHover.borderBottom} !important`,
      },
    },
  }),
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Link = styled(({ light, ...props }) => <StyledLink {...props} />)(
  getStyles
);
const StandardLink = styled(
  ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    light,
    ...props
  }: {
    light?: boolean;
    disabled: any;
    [key: string]: any;
  }) => <StyledStandardLink {...props} />
)(getStyles);

const Text = styled("span")<{
  size?: string;
  nowrap?: boolean;
  hideTextOnMobile?: boolean;
  right?: boolean;
  // @ts-ignore
}>((props) => ({
  ...getUnderlineLinkStyles(props),
  transition: TRANSITIONS.default,
  alignSelf: "flex-end",
  marginLeft: "7px",
  ...(props.size === "sm" && {
    marginLeft: "3px",
  }),
  ...(props.nowrap && {
    whiteSpace: "nowrap",
  }),
  ...(props.hideTextOnMobile && {
    [MEDIA_QUERIES.w.ltMd]: {
      display: "none",
    },
  }),
  ...(props.right && {
    marginLeft: "0px",
    marginRight: "6px",
  }),
}));

const Content = styled("div")<{ right?: boolean }>((props) => ({
  display: "flex",
  ...(props.right && {
    flexDirection: "row-reverse",
  }),
}));

export { Link, StandardLink, Text, Content };
