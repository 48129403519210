import React, { ReactNode, useEffect, useRef, useState } from "react";

import dayjs from "dayjs";
import { FormikProps } from "formik";
import uniqBy from "lodash/uniqBy";

import { useGetPropertiesWithListing } from "manager/hooks/api";
import { useGetUnitsListForProperty } from "manager/hooks/api/unitsQueries";
import { usePermissions } from "shared/hooks";

import AssignToUnitModal, { DatePickerOptions } from "./AssignToUnitModal";
import {
  getInitialValues,
  getPreferredLeaseStartDate,
  getWarningOptions,
  isUnitDisabled,
} from "./config";
import { AssignToUnitFormValues, Property, Unit } from "./interfaces";

interface AssignToUnitModalContainerProps {
  title: string;
  subtitle: ReactNode;
  note?: ReactNode;
  submitButtonLabel?: string;
  masterWarning?: ReactNode;
  modalExtension?: ReactNode;
  isDealLoading: boolean;
  dealId: number | string;
  unitId?: number | string;
  deal: object;
  setSelectedUnit: Function;
  submit: Function;
  cancelLinkLabel?: string;
  closeOnSubmit: boolean;
}

const AssignToUnitModalContainer = ({
  title,
  subtitle,
  note,
  submitButtonLabel,
  masterWarning,
  modalExtension,
  isDealLoading,
  dealId,
  deal,
  unitId,
  setSelectedUnit,
  submit,
  cancelLinkLabel,
  closeOnSubmit,
}: AssignToUnitModalContainerProps) => {
  const [filter, setFilter] = useState({ nameOrAddress: "" });
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [filteredProperties, setFilteredProperties] = useState<Property[]>([]);
  const [allUnits, setUnits] = useState<Unit[]>([]);
  const [leaseWarning, setLeaseWarning] = useState<string>();
  const [datePickerOptions, setDatePickerOptions] =
    useState<DatePickerOptions>();

  const formikRef = useRef<FormikProps<AssignToUnitFormValues>>();

  const { canOverrideApplicantPreferredLeaseDateOnUnitAssignment } =
    usePermissions();

  useEffect(() => {
    if (formikRef.current && unitId) {
      formikRef.current.resetForm({ values: getInitialValues(unitId) });
    }
  }, [unitId]);

  const onSuccessProperties = (data: Property[]) => {
    const propertiesWithDefaultUnits = data.map((property) => ({
      ...property,
      units: [],
    }));
    setFilteredProperties(propertiesWithDefaultUnits);
    setSelectedProperty(null);
  };

  const { isLoading } = useGetPropertiesWithListing(
    { ...filter, deal: dealId },
    {
      enabled: Boolean(dealId),
      onSuccess: onSuccessProperties,
    }
  );

  const onSuccessUnits = (data: Unit[]) => {
    const updatedProperties = filteredProperties.map((property) => {
      const updatedProperty = { ...property };
      if (property.id === selectedProperty) {
        updatedProperty.units = data;
      }
      return updatedProperty;
    });

    setUnits(uniqBy([...allUnits, ...data], "id"));
    setFilteredProperties(updatedProperties);
  };

  useGetUnitsListForProperty(
    { propertyId: selectedProperty },
    {
      enabled: Boolean(selectedProperty),
      onSuccess: onSuccessUnits,
    }
  );

  const getUnits = async (propertyId: number) => {
    setSelectedProperty(propertyId);
  };

  const unitById = (unitItemId: number | string) =>
    allUnits.find((unitItem) => unitItem.id === unitItemId);

  const updateFilter = (filterValue: string) =>
    setFilter({ nameOrAddress: filterValue });

  const preferredLeaseStartDate = getPreferredLeaseStartDate(deal);

  const updateWarningWrapper = (values: AssignToUnitFormValues) => {
    setLeaseWarning(undefined);
    setDatePickerOptions(undefined);
    const unit = unitById(values?.unitId);

    if (setSelectedUnit) {
      setSelectedUnit(unit);
    }

    const { warning, shouldShowDatePicker } = getWarningOptions(unit, deal);
    if (shouldShowDatePicker) {
      const unitAvailableOn = unit.unitListingCurrent?.unitAvailableOn;
      setDatePickerOptions({
        ...datePickerOptions,
        unitAvailableOn,
        newLeaseStartDate: unitAvailableOn,
        // Can not set preferredLeaseStartDate to before the unit's first available date
        newLeaseStartDisabledDateFn: (current: string) => {
          return current && dayjs(current).isBefore(dayjs(unitAvailableOn));
        },
      });
    }
    setLeaseWarning(warning);
  };

  const isUnitDisabledWrapper = (unit: Unit) =>
    isUnitDisabled(
      unit,
      unitId === unit.id,
      preferredLeaseStartDate,
      canOverrideApplicantPreferredLeaseDateOnUnitAssignment
    );

  return (
    <AssignToUnitModal
      title={title}
      subtitle={subtitle}
      note={note}
      submitButtonLabel={submitButtonLabel}
      masterWarning={masterWarning}
      leaseWarning={leaseWarning}
      datePickerOptions={datePickerOptions}
      setDatePickerOptions={setDatePickerOptions}
      modalExtension={modalExtension}
      properties={filteredProperties}
      filter={filter}
      loading={isLoading || isDealLoading}
      unitById={unitById}
      getUnits={getUnits}
      submit={submit}
      formikRef={formikRef}
      initialValues={getInitialValues(unitId)}
      updateWarning={updateWarningWrapper}
      updateFilter={updateFilter}
      isUnitDisabled={isUnitDisabledWrapper}
      cancelLinkLabel={cancelLinkLabel}
      closeOnSubmit={closeOnSubmit}
    />
  );
};

export default AssignToUnitModalContainer;
