import { queryCache, useMutation, useQuery } from "react-query";

import sourcesApi from "manager/lib/api";
import { NOTIFICATIONS } from "shared/config/constants";
import { openNotification } from "shared/utils/ui";

const MARKETING_SOURCES = "getMarketingSources";
const LEAD_SOURCES = "getLeadSources";

const useCreateMarketingSource = () => {
  const [mutate, status] = useMutation(
    (marketingSource) => sourcesApi.postMarketingSource(marketingSource),
    {
      onError: () => {
        openNotification(
          "Failed to create marketing source",
          NOTIFICATIONS.error
        );
      },
      onSuccess: () => {
        openNotification(
          "Marketing source created successfully",
          NOTIFICATIONS.info
        );
        queryCache.refetchQueries(MARKETING_SOURCES);
      },
    }
  );
  return {
    createMarketingSource: mutate,
    isLoading: status.isLoading,
  };
};

const useUpdateMarketingSources = () => {
  const [mutate, status] = useMutation(
    ({ propertyId, marketingSources }) =>
      sourcesApi.putMarketingSources({ propertyId, marketingSources }),
    {
      onError: () => {
        openNotification(
          "Failed to update marketing sources",
          NOTIFICATIONS.error
        );
      },
      onSuccess: () => {
        openNotification(
          "Marketing sources updated successfully",
          NOTIFICATIONS.info
        );
        queryCache.refetchQueries(MARKETING_SOURCES);
      },
    }
  );
  return {
    updateMarketingSources: mutate,
    isLoading: status.isLoading,
  };
};

const useGetLeadSources = (excludeSystem) => {
  const {
    data: leadSources,
    isLoading,
    isFetching,
  } = useQuery(
    [LEAD_SOURCES, excludeSystem],
    () => sourcesApi.getLeadSources(excludeSystem),
    {
      refetchOnMount: "always",
      onError: () => {
        openNotification("Failed to get lead sources.", NOTIFICATIONS.error);
      },
    }
  );

  return {
    leadSources,
    isLoading: isLoading || isFetching,
  };
};

const useCreateLeadSource = () => {
  const [mutate, status] = useMutation(
    (leadSource) => sourcesApi.postLeadSource(leadSource),
    {
      onError: () => {
        openNotification("Failed to create lead source", NOTIFICATIONS.error);
      },
      onSuccess: () => {
        openNotification(
          "Lead source created successfully",
          NOTIFICATIONS.info
        );
        queryCache.refetchQueries(LEAD_SOURCES);
      },
    }
  );
  return {
    createLeadSource: mutate,
    isLoading: status.isLoading,
  };
};

const useUpdateLeadSources = () => {
  const [mutate, status] = useMutation(
    (leadSources) => sourcesApi.putLeadSources(leadSources),
    {
      onError: () => {
        openNotification("Failed to update lead sources", NOTIFICATIONS.error);
      },
      onSuccess: () => {
        openNotification(
          "Lead sources updated successfully",
          NOTIFICATIONS.info
        );
        queryCache.refetchQueries(LEAD_SOURCES);
      },
    }
  );
  return {
    updateLeadSources: mutate,
    isLoading: status.isLoading,
  };
};

export {
  useCreateMarketingSource,
  useUpdateMarketingSources,
  useGetLeadSources,
  useCreateLeadSource,
  useUpdateLeadSources,
};
