import React from "react";

import { Formik } from "formik";

import { Button, BUTTON_SIZES } from "shared/components/Button";
import FlexContainer from "shared/components/FlexContainer";
import { UnderlineLink } from "shared/components/Links";
import Spacer from "shared/components/Spacer";
import { LargeTitle, RegularText } from "shared/components/Typography";
import { VeroModal } from "shared/components/vero-modal";

import {
  RemovePrimaryYardiExtensionProps,
  YardiReasonFormExtensionFields,
} from "../hooks";
import { ApplicantsCheckList, Subtitle } from "../styled";

import { ApplicantSummary } from "../types";

import { useRemoveApplicantModal } from "./context";
import { useHook } from "./hooks";

export interface RemoveApplicantModalProps {
  dealId: string | number;
  application: ApplicantSummary;
  yardi: RemovePrimaryYardiExtensionProps;
}

export type RemoveApplicantModalResult = YardiReasonFormExtensionFields & {};

export const RemoveApplicantModalV2 = (props: RemoveApplicantModalProps) => {
  const state = useRemoveApplicantModal();
  const { remove, application, extension } = useHook(props, state.hide);
  const { cancel$, submit$, submitting } = state;

  return (
    <Formik
      initialValues={{} as RemoveApplicantModalResult}
      onSubmit={(values: RemoveApplicantModalResult) =>
        submit$.next(remove(values))
      }
      validateOnMount
    >
      {({ submitForm, isValid }) => (
        <VeroModal {...state}>
          <LargeTitle>Remove this applicant?</LargeTitle>
          <Subtitle>
            <RegularText strong>Applicant:&nbsp;</RegularText>
            <ApplicantsCheckList inline items={[application]} />
          </Subtitle>

          <Spacer size={Spacer.SIZES.default} />

          {extension}

          <p>
            <b>Please note:</b> Once you remove {application.firstName}{" "}
            {application.lastName}, you will have to re-invite them back into
            the application in case you change your decision.
          </p>

          <Spacer size={Spacer.SIZES.md} />
          <FlexContainer
            justifyContent="flex-end"
            gap="24px"
            alignItems="center"
          >
            <UnderlineLink onClick={() => cancel$.next()}>Cancel</UnderlineLink>
            <Button
              type="primary"
              onClick={submitForm}
              size={BUTTON_SIZES.lg}
              loading={submitting}
              disabled={!isValid}
            >
              Remove
            </Button>
          </FlexContainer>
        </VeroModal>
      )}
    </Formik>
  );
};
