import React, { useEffect } from "react";

interface ExperianCollectorFieldsProps {
  jscInputId: string;
  hdimInputId: string;
}
export const ExperianCollectorFields = ({
  jscInputId,
  hdimInputId,
}: ExperianCollectorFieldsProps) => {
  useEffect(() => {
    const { adx } = window as any;

    const initExperianCollector = () => {
      if (adx) {
        adx.initiate(null);
      }
    };

    if (adx) {
      initExperianCollector();
    } else {
      window.initExperianCollector = initExperianCollector;
    }
  }, [window.adx]);

  return (
    <>
      <input type="hidden" id={jscInputId} name={jscInputId} />
      <input type="hidden" id={hdimInputId} name={hdimInputId} />
    </>
  );
};
