import { CommonStorageCollectionManager } from "./commonStorageManager";

export class SessionStorageCollectionManager extends CommonStorageCollectionManager {
  // eslint-disable-next-line class-methods-use-this
  storageGetItem(key) {
    return sessionStorage.getItem(key);
  }

  // eslint-disable-next-line class-methods-use-this
  storageSetItem(key, value) {
    sessionStorage.setItem(key, value);
  }
}

export const clearStorageAndReload = () => {
  sessionStorage.clear();
  window.location.reload();
};
