import React, { useEffect, useState } from "react";

import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";

import Table from "shared/components/Table";
import { BREAKPOINT_TYPES } from "shared/config/constants";
import withBreakpoint from "shared/hocs/withBreakpoint";
import { arrayMove } from "shared/utils/ui";

import { StyledRow, DraggableIcon } from "./styled";

const DragHandle = SortableHandle(() => <DraggableIcon />);
const SortableItem = SortableElement((sortableProps) => (
  <StyledRow {...sortableProps} />
));
const SortableContainerEl = SortableContainer((sortableProps) => (
  <tbody {...sortableProps} />
));

interface DraggableTableProps {
  breakpoint: typeof BREAKPOINT_TYPES | string;
  columns: {
    title: React.ReactNode | Element | string;
    dataIndex: string;
    key?: string;
  }[];
  rows: Array<{
    key?: string | number;
    draggableItem: boolean | any;
    values: React.ReactNode[];
  }>;
  onDragChange: (newData?: any) => void;
  columnBreakpoints: {
    xs: { column1: string; column2: string; showPrimaryAction?: boolean };
    sm?: string[];
    md?: string[];
    lg?: string[];
  };
  hideThForSortColumn?: boolean;
  placeholder?: {
    Icon?:
      | any
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>;
    label?: string;
  };
  [key: string]: any;
}

const DraggableTable = ({
  breakpoint,
  columns,
  rows,
  onDragChange,
  columnBreakpoints,
  hideThForSortColumn,
  ...props
}: DraggableTableProps) => {
  const [dataSource, setDataSource] = useState(rows);

  const handleSortStart = ({ node }) => {
    const tds = document.getElementsByClassName("row-dragging")[0].childNodes;
    node.childNodes.forEach((child, idx) => {
      // @ts-ignore
      tds[idx].style.width = `${child.offsetWidth}px`;
    });
  };

  const handleSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove([...dataSource], oldIndex, newIndex)
        .filter((el) => !!el)
        .sort((item1, item2) => item2.draggableItem - item1.draggableItem);
      setDataSource(newData);
      onDragChange(newData);
    }
  };

  const getValues = () => {
    return rows
      .map((item) => ({
        ...rows.find((row) => row.key === item.key),
      }))
      .sort((item1, item2) => item2.draggableItem - item1.draggableItem);
  };

  const getColumnBreakpoints = () => {
    return Object.keys(columnBreakpoints).reduce((acc, key) => {
      if (key !== "xs") {
        acc[key] = ["sort", ...columnBreakpoints[key]];
      } else {
        acc[key] = columnBreakpoints[key];
      }

      return acc;
    }, {});
  };

  useEffect(() => {
    setDataSource(getValues());
  }, [rows]);

  const DraggableContainer = (containerProps) => (
    <SortableContainerEl
      useDragHandle={breakpoint !== BREAKPOINT_TYPES.xs}
      disableAutoscroll={breakpoint !== BREAKPOINT_TYPES.xs}
      helperClass="row-dragging"
      lockAxis="y"
      onSortStart={handleSortStart}
      onSortEnd={handleSortEnd}
      {...containerProps}
    />
  );

  const DraggableBodyRow = ({ ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(
      (item) => item.key === restProps["data-row-key"]
    );
    const isDisabled = !dataSource[index].draggableItem;
    return <SortableItem disabled={isDisabled} index={index} {...restProps} />;
  };

  const [sndColumn, ...otherColumns] = columns;

  return (
    // @ts-ignore
    <Table
      columns={[
        {
          title: "",
          dataIndex: "sort",
          key: "sort",
          width: 30,
          colSpan: hideThForSortColumn ? 0 : 1,
        },
        {
          ...sndColumn,
          colSpan: hideThForSortColumn ? 2 : 1,
        },
        ...(otherColumns ?? []),
      ]}
      rows={dataSource.map((row) => ({
        ...row,
        values: [<>{row.draggableItem && <DragHandle />}</>, ...row.values],
      }))}
      rowKey="key"
      columnBreakpoints={getColumnBreakpoints()}
      components={{
        body: {
          wrapper: DraggableContainer,
          row: DraggableBodyRow,
        },
      }}
      {...props}
    />
  );
};

export default withBreakpoint(DraggableTable);
