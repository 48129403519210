import React, { useRef } from "react";

import { FormikHelpers } from "formik";

import {
  useGetDealSetup,
  useUpdateDealSetup,
} from "manager/hooks/api/dealSetupQueries";
import { DealSetup } from "manager/interfaces/api";

import PrescreeningSettings from "./PrescreeningSettings";

interface PrescreeningSettingsContainerProps {
  dealSetupId: number;
  disabled?: boolean;
  onSubmit?: () => void;
  submitButtonLabel?: string;
  showSecondaryButton?: boolean;
  onSecondaryButtonClick?: () => void;
  secondaryButtonLabel?: string;
}

const PrescreeningSettingsContainer = ({
  dealSetupId,
  disabled,
  onSubmit,
  submitButtonLabel,
  showSecondaryButton = false,
  onSecondaryButtonClick,
  secondaryButtonLabel,
}: PrescreeningSettingsContainerProps) => {
  const formRef = useRef(null);

  const { dealSetup, isDealSetupLoading } = useGetDealSetup(dealSetupId);

  const { updateDealSetup } = useUpdateDealSetup();

  const saveChanges = async (values: DealSetup) => {
    if (formRef.current?.isValid && !disabled) {
      // @ts-ignore
      await updateDealSetup({
        id: dealSetup.id,
        dealSetup: values,
      });
    }
  };

  const defaultOnSubmit = async (
    values: DealSetup,
    { resetForm }: FormikHelpers<DealSetup>
  ) => {
    if (!disabled) {
      await saveChanges(values);
      resetForm({ values });
    }
  };

  return (
    <PrescreeningSettings
      loading={isDealSetupLoading}
      dealSetup={dealSetup}
      saveChanges={saveChanges}
      onSubmit={onSubmit || defaultOnSubmit}
      formRef={formRef}
      disabled={disabled}
      shouldDisableSubmitButton={false}
      submitButtonLabel={submitButtonLabel}
      onSecondaryButtonClick={onSecondaryButtonClick}
      secondaryButtonLabel={secondaryButtonLabel}
      showSecondaryButton={showSecondaryButton}
    />
  );
};

export default PrescreeningSettingsContainer;
