import { Identity, IdVerification } from "./interfaces";

export const mapIdentities = (
  verifications?: IdVerification[],
  enabled?: boolean
): Identity | undefined => {
  if (!verifications) return undefined;
  const id = verifications?.find(
    (verification) => verification?.verificationType === "ID"
  )?.details;

  return {
    documentType: id?.documentClass,
    enabled,
    verified: id?.status === "completed",
    identityNumber: id?.identificationNumber,
    country: id?.addressCountryCode,
    expires: id?.expirationDate ? new Date(id?.expirationDate) : undefined,
  };
};
