import React, { useState, useRef, useEffect } from "react";

import { OverflowedOptionWrapper } from "./styled";

interface DetectableOverflowProps {
  children?: string | any;
  title?: string;
}

const DetectableOverflow = ({
  children = undefined,
  title = undefined,
}: DetectableOverflowProps) => {
  const [overflowed, setOverflowed] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      setOverflowed(ref.current.offsetWidth < ref.current.scrollWidth);
    }
  }, [ref]);

  return (
    <OverflowedOptionWrapper
      title={overflowed ? title || children : undefined}
      ref={ref}
      data-testid="detectable-overflow"
    >
      {children}
    </OverflowedOptionWrapper>
  );
};

export default DetectableOverflow;
