import React, { useState } from "react";

import PropTypes from "prop-types";

import { SearchableCheckboxListWithHeader } from "shared/components/Form";
import Icon from "shared/components/Icon";
import { Modal, SIZES } from "shared/components/Modal";
import { BREAKPOINT_RESOLUTIONS } from "shared/config/constants";
import useWindowSize from "shared/hooks/useWindowSize";
import { keyPropType } from "shared/utils/propTypes";

const YardiImportPropertyModal = ({
  properties,
  visibleProperties,
  importPropertiesAction,
  filterProperties,
  isImporting,
}) => {
  const [checkedItems, setCheckedItems] = useState([]);
  const checkedItemsSet = new Set(checkedItems);
  const selectedProperties = properties.filter((property) =>
    checkedItemsSet.has(property.id)
  );

  const searchProps = {
    fieldName: "propertyAddressName",
    id: "propertyAddressName",
    label: "Search by property or address",
    action: (value) => filterProperties(value),
    width: "288px",
    debounce: true,
  };

  const { width } = useWindowSize();
  const rowSize = width <= BREAKPOINT_RESOLUTIONS.xs ? 50 : 62;
  const rowHeightEstimator = () => rowSize;

  return (
    <Modal
      title="Import properties to VERO"
      subtitle={
        <span data-testid="subtitle">
          Choose which properties you would like to import to VERO
        </span>
      }
      submit={() => importPropertiesAction(selectedProperties)}
      submitButtonLabel="CONFIRM"
      submitButtonSize={SIZES.lg}
      width="auto"
      style={{ maxWidth: 946, top: "10px" }}
      closeOnSubmit={false}
      submitButtonDisabled={selectedProperties.length === 0}
      submitting={isImporting}
    >
      <Modal.Body noMobilePaddingX>
        <SearchableCheckboxListWithHeader
          listProps={{
            items: properties,
            visibleItems: visibleProperties,
            noSeparator: true,
            virtualize: true,
            virtualRowHeightEstimator: rowHeightEstimator,
          }}
          listContainerProps={{
            checkedItemsOnChange: (items) => setCheckedItems(items),
          }}
          searchProps={searchProps}
          headerProps={{ firstColLabel: "Name", secondColLabel: "Address" }}
          placeholder={{
            label: "Properties",
            Icon: Icon.NoPropertiesIcon,
          }}
          loading={false}
          hasInputValue={false}
        />
      </Modal.Body>
    </Modal>
  );
};

const propertiesForListPropType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.any.isRequired,
    name: PropTypes.string.isRequired,
    key: keyPropType.isRequired,
    label: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    sanitizedLabel: PropTypes.string.isRequired,
    sanitizedDescription: PropTypes.string.isRequired,
  })
);
YardiImportPropertyModal.propTypes = {
  properties: propertiesForListPropType,
  visibleProperties: propertiesForListPropType,
  importPropertiesAction: PropTypes.func.isRequired,
  filterProperties: PropTypes.func.isRequired,
  isImporting: PropTypes.bool,
};

YardiImportPropertyModal.defaultProps = {
  properties: [],
  visibleProperties: [],
  isImporting: false,
};

export default YardiImportPropertyModal;
