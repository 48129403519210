import React from "react";

import { Divider } from "antd";
import { Form, Formik } from "formik";
import { Helmet } from "react-helmet";

import MainBackground from "shared/components/Backgrounds/MainBackground";
import FlexContainer from "shared/components/FlexContainer";
import { Input, PhoneNumberInput, VeroFormField } from "shared/components/Form";
import Icon from "shared/components/Icon";
import LargeLoader from "shared/components/LargeLoader";
import Spacer from "shared/components/Spacer";
import {
  LargeTitle,
  SmallTitle,
  SmallText,
} from "shared/components/Typography";

import SelectPreferrence from "./components/SelectPreferrence";
import {
  LEADS_OPTIONS,
  OptionType,
} from "./components/SelectPreferrence/interfaces";
import {
  getInitialValuesVettGuestsEnabled,
  UserShort,
  ValidationSchema,
} from "./config";
import { SubmitPayloadAcceptTerms } from "./LeadAcceptTermsContainer";
import {
  LeadAcceptTermsVettPcodePageCard,
  SubmitButton,
  SelectYourPreferenceContainer,
} from "./styled";

interface LeadAcceptTermsProps {
  user: UserShort;
  isLoading: boolean;
  submit: (payload: SubmitPayloadAcceptTerms) => void;
  applicationId?: string;
  onSelectPreferrence: (e, setFieldValue: any) => void;
  selectedPreferrence: OptionType;
}

const LeadAcceptTermsVettPCode = ({
  user,
  isLoading,
  submit,
  applicationId,
  onSelectPreferrence,
  selectedPreferrence = LEADS_OPTIONS.PROSPECT,
}: LeadAcceptTermsProps) => (
  <>
    <Helmet>
      <title>Prequalification</title>
    </Helmet>
    {isLoading ? (
      <LargeLoader absoluteCenter showLoader data-testid="loading-spinner" />
    ) : (
      <Formik
        initialValues={getInitialValuesVettGuestsEnabled(user)}
        validationSchema={ValidationSchema}
        validateOnBlur={false}
        onSubmit={submit}
      >
        {({ isSubmitting, setFieldValue }) => (
          <LeadAcceptTermsVettPcodePageCard>
            <Form noValidate>
              <FlexContainer className="wrapper">
                <SelectYourPreferenceContainer>
                  <FlexContainer justifyContent="flex-end">
                    <Icon.LogoBigWhiteIcon />
                  </FlexContainer>
                  <Spacer />
                  <SmallTitle className="pink-text">
                    Select your preference before proceeding
                  </SmallTitle>
                  <Spacer />
                  <SmallText className="info-text">
                    We want to document your information as accurately as
                    possible. Using the descriptions below, tell us how you
                    would like to continue.
                  </SmallText>
                  <SelectPreferrence
                    selected={selectedPreferrence}
                    onClick={(e) => onSelectPreferrence(e, setFieldValue)}
                  />
                  <SubmitButton
                    loading={isSubmitting}
                    data-testid="submit-button"
                    type="primary"
                    htmlType="submit"
                    className="submit-button-vett-p-code"
                  >
                    BEGIN PREQUALIFICATION{` `}
                    <Icon.RightArrowDark className="icon" />
                  </SubmitButton>
                </SelectYourPreferenceContainer>
                <Divider type="vertical" className="divider" />
                <FlexContainer flexDirection="column" className="form-column">
                  <FlexContainer flexDirection="column" alignItems="center">
                    <LargeTitle className="title-center">
                      A few steps away from your new home
                    </LargeTitle>
                    <SmallTitle>SIGN UP</SmallTitle>
                  </FlexContainer>
                  <Spacer />
                  <>
                    <VeroFormField
                      as={Input}
                      name="email"
                      id="email"
                      type="email"
                      label="Email"
                      required
                      disabled
                    />
                    <Spacer />
                  </>
                  <>
                    <VeroFormField
                      as={Input}
                      name="firstName"
                      id="firstName"
                      type="firstName"
                      label="First name"
                      required
                    />
                    <Spacer />
                  </>
                  <>
                    <VeroFormField
                      as={Input}
                      name="lastName"
                      id="lastName"
                      type="lastName"
                      label="Last name"
                      required
                    />
                    <Spacer />
                  </>
                  <>
                    <VeroFormField
                      as={PhoneNumberInput}
                      name="phoneNumber"
                      id="phoneNumber"
                      label="Phone number"
                      setFieldValue={setFieldValue}
                      required
                    />
                    <Spacer />
                  </>
                </FlexContainer>
              </FlexContainer>
            </Form>
          </LeadAcceptTermsVettPcodePageCard>
        )}
      </Formik>
    )}
    <MainBackground />
  </>
);

export default LeadAcceptTermsVettPCode;
