import React from "react";

import styled from "@emotion/styled";
import { Tag as BaseTag } from "antd";

import { SIZES } from "./utils";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Tag = styled(({ size, backgroundColor, ...props }) => (
  <BaseTag {...props} />
))((props) => ({
  ...(props.size === SIZES.sm && {
    fontSize: "10px",
    padding: "3px 5px !important",
    lineHeight: 1,
  }),
  ...(props.size === SIZES.md && {
    fontSize: "12px",
    padding: "6px 8px !important",
    lineHeight: 1,
  }),
  ...(props.size === SIZES.lg && {
    fontSize: "15px",
    padding: "6px 12px !important",
  }),
  ...(props.backgroundColor && {
    backgroundColor: `${props.backgroundColor} !important`,
    ...(props.color && { color: `${props.color} !important` }),
  }),
}));

export { Tag };
