import { Key } from "react";

import { queryCache, useMutation, useQuery } from "react-query";

import {
  Property,
  UnassignPropertyFromPortfolioProps,
} from "manager/interfaces/api";
import api from "manager/lib/api";
import {
  getPropertiesDropdown,
  getPropertySettings,
} from "manager/lib/propertyApi";
import { IntegrationProvider, NOTIFICATIONS } from "shared/config/constants";
import {
  copyDealSetupFromPortfolio,
  copyPrescreeningDealSetupFromPortfolio,
  resetPropertyToPortfolioSettings,
  resetToPrescreeningPortfolioSettings,
} from "shared/lib/api/dealSetupApi";
import {
  createProperty,
  getAllPropertiesSimple,
  getEmployeesAssignedToPropertyPerf,
  getEmployeesWithAssignmentsOnProperty,
  getProperties,
  getPropertiesPerf,
  getPropertiesWithInvitation,
  getPropertiesWithLead,
  getPropertiesWithListing,
  getProperty,
  uploadPropertyLogo,
  updateProperty,
  assignEmployeesToProperty,
  unassignPropertyFromPortfolio,
  assignPropertyToPortfolio,
} from "shared/lib/api/propertyApi";
import { openNotification } from "shared/utils/ui";

import { PORTFOLIO_QUERY_KEYS } from "./portfolioQueries";

const GET_PROPERTY = "getProperty";
const GET_PROPERTIES = "getProperties";
const GET_PROPERTIES_ASSIGN_PORTFOLIO = "getPropertiesAssignPortfolio";
const GET_PROPERTIES_WITH_INVITATION = "getPropertiesWithInvitation";
const GET_PROPERTIES_WITH_LEAD = "getPropertiesWithLead";
export const GET_PROPERTY_SETTINGS = "getPropertySettings";
const GET_PROPERTIES_WITH_LISTING = "getPropertiesWithListing";
const GET_ALL_PROPERTIES_SIMPLE = "getAllPropertiesSimple";
export const GET_EMPLOYEES_ASSIGNED_TO_PROPERTY =
  "getEmployeesAssignedToProperty";
const GET_EMPLOYEES_WITH_ASSIGNMENT_ON_PROPERTIES =
  "getEmployeesWithAssignmentsOnProperty";
const GET_PROPERTIES_DROPDOWN = "getPropertiesDropdown";
const GET_PORTFOLIOS_LIST = "getPortfoliosList";

export const PROPERTY_QUERY_KEYS = Object.freeze({
  getProperty: (id) => [GET_PROPERTY, Number(id)],
  getProperties: () => [GET_PROPERTIES],
  getPropertiesAssignPortfolio: () => [GET_PROPERTIES_ASSIGN_PORTFOLIO],
  getPropertiesWithInvitation: () => [GET_PROPERTIES_WITH_INVITATION],
  getPropertiesWithLead: () => [GET_PROPERTIES_WITH_LEAD],
  getPropertySettings: (id: Key) => [GET_PROPERTY_SETTINGS, Number(id)],
  getPropertiesWithListing: (params) => [GET_PROPERTIES_WITH_LISTING, params],
  getAllPropertiesSimple: () => [GET_ALL_PROPERTIES_SIMPLE],
  getEmployeesAssignedToProperty: (id) => [
    GET_EMPLOYEES_ASSIGNED_TO_PROPERTY,
    Number(id),
  ],
  getEmployeesWithAssignmentsOnProperty: (id, params) => [
    GET_EMPLOYEES_WITH_ASSIGNMENT_ON_PROPERTIES,
    Number(id),
    params,
  ],
  getPropertiesDropdown: () => [GET_PROPERTIES_DROPDOWN],
  getPortfoliosList: () => [GET_PORTFOLIOS_LIST],
});

export const useGetProperties = ({ params }, config = {}) => {
  const response = useQuery(
    PROPERTY_QUERY_KEYS.getProperties(),
    () => getProperties(params).then(({ data }) => data),
    {
      onError: () =>
        openNotification("Failed to load the properties", NOTIFICATIONS.error),
      ...config,
    }
  );
  return response;
};

// TODO (POD2-148): Remove the Perf postfix as soon as we have removed the old endpoints.
export const useGetPropertiesPerf = ({ params }, config = {}) => {
  const response = useQuery(
    PROPERTY_QUERY_KEYS.getProperties(),
    () => getPropertiesPerf(params),
    {
      onError: () =>
        openNotification("Failed to load the properties", NOTIFICATIONS.error),
      ...config,
    }
  );
  return response;
};

export const useGetPropertiesPerfAssignToPortfolio = (
  { params },
  config = {}
) => {
  const response = useQuery(
    PROPERTY_QUERY_KEYS.getPropertiesAssignPortfolio(),
    () => getPropertiesPerf(params),
    {
      onError: () =>
        openNotification("Failed to load the properties", NOTIFICATIONS.error),
      ...config,
    }
  );
  return response;
};

export const useGetPropertiesWithListing = (params, config = {}) => {
  const response = useQuery(
    PROPERTY_QUERY_KEYS.getPropertiesWithListing(params),
    () => getPropertiesWithListing(params).then(({ data }) => data),
    {
      refetchOnMount: "always",
      onError: () =>
        openNotification("Failed to load the properties", NOTIFICATIONS.error),
      ...config,
    }
  );
  return response;
};

export const useGetAllPropertiesSimple = (config = {}) => {
  const response = useQuery(
    PROPERTY_QUERY_KEYS.getAllPropertiesSimple(),
    () => getAllPropertiesSimple().then(({ data }) => data),
    {
      onError: () =>
        openNotification("Failed to load the properties", NOTIFICATIONS.error),
      ...config,
    }
  );
  return response;
};

export const useGetProperty = (propertyId) => {
  const {
    data: property,
    isLoading,
    isFetching,
    refetch,
  } = useQuery(
    PROPERTY_QUERY_KEYS.getProperty(propertyId),
    () => getProperty(propertyId).then(({ data }) => data),
    {
      enabled: propertyId,
      onError: () =>
        openNotification("Failed to load the property", NOTIFICATIONS.error),
    }
  );
  return {
    property,
    isLoading: isLoading || isFetching,
    refetch,
  };
};

export const useGetPropertySettings = (propertyId: Key) => {
  const {
    data: propertySettings,
    isLoading,
    isFetching,
  } = useQuery(
    PROPERTY_QUERY_KEYS.getPropertySettings(propertyId),
    () => getPropertySettings(propertyId),
    {
      enabled: Boolean(propertyId),
      onError: () =>
        openNotification(
          "Failed to load the property settings",
          NOTIFICATIONS.error
        ),
    }
  );
  return {
    propertySettings,
    isPropertySettingsLoading: isLoading || isFetching,
  };
};

export const useCopyDealSetupFromPortfolio = () => {
  const [mutate] = useMutation(
    (propertyId) => copyDealSetupFromPortfolio({ propertyId }),
    {
      onSuccess: () => {
        queryCache.invalidateQueries([GET_PROPERTY_SETTINGS]);
        openNotification(
          "You have successfully copied settings from portfolio.",
          NOTIFICATIONS.info
        );
      },
      onError: () => {
        openNotification(
          "Failed to copy settings from portfolio.",
          NOTIFICATIONS.error
        );
      },
    }
  );

  return {
    copyDealSetupFromPortfolio: mutate,
  };
};

export const useResetPropertyToPortfolioSettings = () => {
  const [mutate] = useMutation(
    (propertyId) => resetPropertyToPortfolioSettings({ propertyId }),
    {
      onSuccess: () => {
        queryCache.invalidateQueries([GET_PROPERTY_SETTINGS]);
        openNotification(
          `You have successfully reset settings for the property.`,
          NOTIFICATIONS.info
        );
      },
      onError: () => {
        openNotification(
          "Failed to reset the property settings.",
          NOTIFICATIONS.error
        );
      },
    }
  );

  return {
    resetPropertyToPortfolioSettings: mutate,
  };
};

export const useCopyPrescreeningDealSetupFromPortfolio = () => {
  const [mutate] = useMutation(
    (propertyId) => copyPrescreeningDealSetupFromPortfolio({ propertyId }),
    {
      onSuccess: () => {
        queryCache.invalidateQueries([GET_PROPERTY_SETTINGS]);
        openNotification(
          "You have successfully copied prequalification settings from portfolio.",
          NOTIFICATIONS.info
        );
      },
      onError: () => {
        openNotification(
          "Failed to copy prequalification settings from portfolio.",
          NOTIFICATIONS.error
        );
      },
    }
  );

  return {
    copyPrescreeningDealSetupFromPortfolio: mutate,
  };
};

export const useResetToPrescreeningPortfolioSettings = () => {
  const [mutate] = useMutation(
    (propertyId) => resetToPrescreeningPortfolioSettings({ propertyId }),
    {
      onSuccess: () => {
        queryCache.invalidateQueries([GET_PROPERTY_SETTINGS]);
        openNotification(
          `You have successfully reset prequalification settings for the property.`,
          NOTIFICATIONS.info
        );
      },
      onError: () => {
        openNotification(
          "Failed to reset the prequalification property settings.",
          NOTIFICATIONS.error
        );
      },
    }
  );

  return {
    resetToPrescreeningPortfolioSettings: mutate,
  };
};

export function useGetPropertiesWithInvitation() {
  const { data: properties, isLoading } = useQuery(
    PROPERTY_QUERY_KEYS.getPropertiesWithInvitation(),
    getPropertiesWithInvitation,
    {
      onError: () => {
        openNotification("Failed to load properties", NOTIFICATIONS.error);
      },
      cacheTime: 0,
    }
  );

  return { properties, isLoading };
}

export function useGetPropertiesWithLead() {
  const { data: properties, isLoading } = useQuery(
    PROPERTY_QUERY_KEYS.getPropertiesWithLead(),
    getPropertiesWithLead,
    {
      onError: () => {
        openNotification("Failed to load properties", NOTIFICATIONS.error);
      },
      cacheTime: 0,
    }
  );

  return { properties, isLoading };
}

export const useCreateProperty = () => {
  const [mutate, response] = useMutation(
    (payload: Property) => createProperty(payload),
    {
      onSuccess: ({ name }) => {
        queryCache.invalidateQueries(PROPERTY_QUERY_KEYS.getProperties());
        openNotification(
          `The ${name} property has been successfully created.`,
          NOTIFICATIONS.info
        );
      },
    }
  );

  return {
    createProperty: mutate,
    response,
  };
};

export const useUpdateProperty = () => {
  const [mutate, response] = useMutation(
    ({ id, values }: { id: number; values: Property }) =>
      updateProperty(id, values),
    {
      onSuccess: ({ data }) => {
        openNotification(
          `The ${data.name} property has been successfully updated`,
          NOTIFICATIONS.info
        );
        queryCache.invalidateQueries([GET_PROPERTY]);
        queryCache.invalidateQueries([GET_PROPERTIES]);
      },
      onError: () => {
        openNotification("Failed to update the property.", NOTIFICATIONS.error);
      },
    }
  );

  return {
    updateProperty: mutate,
    response,
  };
};

export const useGetEmployeesAssignedToProperty = (id) => {
  const {
    data: employees,
    isLoading,
    isFetching,
  } = useQuery(
    PROPERTY_QUERY_KEYS.getEmployeesAssignedToProperty(id),
    () => getEmployeesAssignedToPropertyPerf({ id }),
    {
      enabled: !!id,
      onError: () =>
        openNotification(
          "Failed to load the property settings",
          NOTIFICATIONS.error
        ),
    }
  );
  return {
    employees,
    isEmployeesLoading: isLoading || isFetching,
  };
};

export const useGetEmployeesWithAssignmentsOnProperty = (
  provider,
  propertyId,
  filters
) => {
  const {
    data: employees,
    isLoading,
    isFetching,
  } = useQuery(
    PROPERTY_QUERY_KEYS.getEmployeesWithAssignmentsOnProperty(
      propertyId,
      filters
    ),
    () =>
      getEmployeesWithAssignmentsOnProperty({
        id: propertyId,
        filters,
      }).then(({ data }) => data),
    {
      enabled: propertyId && provider !== IntegrationProvider.realPage,
      refetchOnMount: "always",
      onError: () => {
        openNotification("Failed to load employees", NOTIFICATIONS.error);
      },
    }
  );

  return {
    employees: employees || [],
    isLoading: isLoading || isFetching,
  };
};

export const useUploadPropertyLogo = () => {
  const [mutate, status] = useMutation(
    // @ts-ignore
    ({ id, file }) => uploadPropertyLogo(id, file).then(({ data }) => data),
    {
      onSuccess: () => {
        queryCache.invalidateQueries([GET_PROPERTY_SETTINGS]);
        openNotification(
          `Property logo has been successfully uploaded.`,
          NOTIFICATIONS.info
        );
      },
      onError: () => {
        openNotification(
          "Failed to upload property logo.",
          NOTIFICATIONS.error
        );
      },
    }
  );

  return {
    uploadPropertyLogo: mutate,
    isLoading: status.isLoading,
    data: status.data,
  };
};

export const useGetPropertiesDropdown = () => {
  const {
    data: properties,
    isLoading: isPropertiesLoading,
    refetch: refetchProperties,
  } = useQuery(
    PROPERTY_QUERY_KEYS.getPropertiesDropdown(),
    getPropertiesDropdown,
    {
      forceFetchOnMount: true,
      onError: () => {
        openNotification("Failed to load properties", NOTIFICATIONS.error);
      },
    }
  );
  return { properties, isPropertiesLoading, refetchProperties };
};

export const useAssignEmployeesToProperty = ({
  propertyId,
}: {
  propertyId: number;
}) => {
  const [mutate, { isLoading }] = useMutation(
    (employees: { id: number; isAssigned: boolean }[]) =>
      assignEmployeesToProperty({ id: propertyId, employees }),
    {
      onSuccess: () =>
        queryCache.invalidateQueries([GET_EMPLOYEES_ASSIGNED_TO_PROPERTY]),
      onError: () =>
        openNotification(
          "Failed to assign employees to property.",
          NOTIFICATIONS.error
        ),
    }
  );

  return {
    assignEmployeesToProperty: mutate,
    isAssignEmployeesToProperty: isLoading,
  };
};

export const useUnassignPropertyFromPortfolio = ({
  propertyId,
  propertyName,
  portfolioName,
  portfolioId,
}: UnassignPropertyFromPortfolioProps) => {
  const [mutate, { isLoading }] = useMutation(
    () => unassignPropertyFromPortfolio(propertyId),
    {
      onSuccess: () => {
        openNotification(
          `${propertyName} has been successfully unassigned from ${portfolioName} portfolio`,
          NOTIFICATIONS.info
        );
        queryCache.invalidateQueries([GET_PROPERTY_SETTINGS]);
        queryCache.invalidateQueries(PROPERTY_QUERY_KEYS.getProperties());
        queryCache.invalidateQueries(
          PORTFOLIO_QUERY_KEYS.getPortfolio(portfolioId)
        );
      },
      onError: () =>
        openNotification(
          "Failed to unassign property from portfolio.",
          NOTIFICATIONS.error
        ),
    }
  );

  return {
    unassignPropertyFromPortfolio: mutate,
    isUnassignPropertyFromPortfolio: isLoading,
  };
};

export const useAssignPropertyToPortfolio = () => {
  const [mutate, { isLoading }] = useMutation(
    ({ propertyId, portfolioId }: any) =>
      assignPropertyToPortfolio({ propertyId, portfolioId }),
    {
      onSuccess: ({ data }) => {
        openNotification(
          `${data.name}  has been successfully assigned to ${data.portfolioName} portfolio`,
          NOTIFICATIONS.info
        );
        queryCache.invalidateQueries([GET_PROPERTY_SETTINGS]);
        queryCache.invalidateQueries(PROPERTY_QUERY_KEYS.getProperties());
      },
      onError: () =>
        openNotification(
          "Failed to assign property to portfolio.",
          NOTIFICATIONS.error
        ),
    }
  );

  return {
    assignPropertyToPortfolio: mutate,
    isAssignPropertyToPortfolio: isLoading,
  };
};
