import React from "react";

import InviteApplicantsModal from "renter/components/Modal/InviteApplicants/InviteApplicantsModal";
import { MODALS } from "renter/config/constants";
import { useApplicantInviteApplicants } from "renter/hooks/api";
import { APPLICANT_TYPES } from "shared/config/constants";
import useModal from "shared/hooks/useModal";

import { ApplicantInviteSubmit } from "../InviteApplicantsModal/interfaces";

interface AddGuarantorsModalContainerProps {
  property: string;
  unit: string;
  dealId: number;
  companyName: string;
}

const AddGuarantorsModalContainer = (
  context: AddGuarantorsModalContainerProps
) => {
  const { dealId } = context;

  const { closeActiveModalDialog } = useModal();
  const { applicantInviteApplicants } = useApplicantInviteApplicants(dealId);
  const submit = ({ applicants, onSuccess, onFail }: ApplicantInviteSubmit) => {
    applicantInviteApplicants(
      {
        applicantType: APPLICANT_TYPES.guarantor,
        applicants: applicants.map((applicant) => ({
          ...applicant,
          applicantType: APPLICANT_TYPES.guarantor,
        })),
      },
      { onSuccess, onError: onFail }
    );
  };

  return (
    <InviteApplicantsModal
      title="Invite guarantors"
      id={MODALS.addGuarantors}
      submit={submit}
      context={context}
      label="Guarantor"
      closeActiveModalDialog={closeActiveModalDialog}
    />
  );
};

export default AddGuarantorsModalContainer;
