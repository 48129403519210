import { openDefaultAnimatedActionModal } from "shared/components/Modals/AnimatedPendingActionModal";
import { COMMON_PENDING_ANIMATION_MESSAGES } from "shared/config/constants";

import { removeApplicationFromDeal } from "shared/lib/api/dealApi";

import { useRemovePrimaryYardiExtension } from "../hooks";

import {
  RemoveApplicantModalResult,
  RemoveApplicantModalProps,
} from "./component";

export const useHook = (props: RemoveApplicantModalProps, hide: () => void) => {
  const { dealId, application } = props;
  const remove = async ({ extraParams }: RemoveApplicantModalResult) => {
    const messages = [
      ...COMMON_PENDING_ANIMATION_MESSAGES,
      "Individual Removed",
    ];
    hide();
    const loadingModal = openDefaultAnimatedActionModal(messages, 1000);
    try {
      await removeApplicationFromDeal({
        id: String(dealId),
        applicationId: application?.id,
        ...extraParams,
      });
      loadingModal.finishAnimation();
    } catch (err) {
      loadingModal.finishAnimation(() => {}, "Error", false, true);
      throw err;
    }
  };

  const { extension } = useRemovePrimaryYardiExtension(props.yardi);

  return { remove, application, extension };
};
