import React, { useMemo, useState } from "react";

import PropTypes from "prop-types";

import {
  SearchableCheckboxListWithHeader,
  Checkbox,
} from "shared/components/Form";
import { PopoverContent } from "shared/components/Form/Input/styled";
import Icon from "shared/components/Icon";
import { ActionLink } from "shared/components/Links";
import { Popover } from "shared/components/Popover";

import {
  SectionTitleLightActionText,
  TableActionWrapper,
  DisabledTooltipText,
  LinkedUnitsWrapper,
} from "./styled";

const CREATE_LINK_TEXT = "AUTO-CREATE VERO UNITS";
const DISABLED_CHECKBOX_TEXT =
  "You are not able to select this unit, because it is already linked with VERO unit";

const YardiUnitsConfigSection = ({
  sectionId,
  items,
  checkedItems,
  setCheckedItems,
  popoverText,
  autoCreateUnits,
  firstColWidth,
  loading,
}) => {
  const [isShowLinkedUnits, setShowLinkedUnits] = useState(false);

  const yardiUnits = useMemo(() => {
    if (!isShowLinkedUnits) {
      return items.filter((item) => !item.isUnitLinked);
    }

    return items.map((item) => ({
      ...item,
      disabled: item.isUnitLinked,
    }));
  }, [items, isShowLinkedUnits]);

  return (
    <>
      <LinkedUnitsWrapper>
        <Checkbox
          id={sectionId}
          label="Show units already linked"
          checked={isShowLinkedUnits}
          onChange={() => setShowLinkedUnits(!isShowLinkedUnits)}
        />
      </LinkedUnitsWrapper>
      <SearchableCheckboxListWithHeader
        listProps={{
          items: yardiUnits,
          noSeparator: true,
          virtualize: true,
          col1Width: firstColWidth,
          disabledCheckboxText: DISABLED_CHECKBOX_TEXT,
          virtualRowHeightEstimator: () => 90,
        }}
        listContainerProps={{
          checkedItemsOnChange: (selectedItems) =>
            setCheckedItems(selectedItems),
        }}
        headerProps={{
          firstColLabel: "External Provider",
          secondColLabel: "VERO",
        }}
        placeholder={{
          label: "Units",
          Icon: Icon.NoUnitsIcon,
        }}
        customSearchContent={
          <SectionTitleLightActionText>
            <TableActionWrapper>
              {checkedItems.length > 0 ? (
                <ActionLink onClick={autoCreateUnits}>
                  {CREATE_LINK_TEXT}
                </ActionLink>
              ) : (
                <Popover
                  withoutOverlay
                  content={
                    <PopoverContent>
                      Please select at least one unit to enable this action.
                    </PopoverContent>
                  }
                >
                  <DisabledTooltipText>{CREATE_LINK_TEXT}</DisabledTooltipText>
                </Popover>
              )}
            </TableActionWrapper>
            <Popover
              withoutOverlay
              placement="topRight"
              content={<PopoverContent>{popoverText}</PopoverContent>}
            >
              <Icon.InfoIcon />
            </Popover>
          </SectionTitleLightActionText>
        }
        loading={loading}
        hasInputValue={false}
      />
    </>
  );
};

YardiUnitsConfigSection.propTypes = {
  sectionId: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  checkedItems: PropTypes.arrayOf(PropTypes.any).isRequired,
  popoverText: PropTypes.string.isRequired,
  setCheckedItems: PropTypes.func.isRequired,
  autoCreateUnits: PropTypes.func.isRequired,
  firstColWidth: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export { YardiUnitsConfigSection };
