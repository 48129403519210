import axios from "axios";

import ROUTES from "shared/config/apiRoutes";

import { API_URL } from "./setupApi";

const api = {
  getPropertyTypes: async () =>
    axios.get(`${API_URL}${ROUTES.propertyTypes}`).then(({ data }) => data),
  getRequiredAttachmentTypes: async () =>
    axios.get(`${API_URL}${ROUTES.requiredAttachmentTypes}`),
  getApplicantTypes: async () =>
    axios.get(`${API_URL}${ROUTES.applicantTypes}`),
  getApplicationStatuses: async () =>
    axios.get(`${API_URL}${ROUTES.applicationStatuses}`),
  getDealStatuses: async () => axios.get(`${API_URL}${ROUTES.dealStatuses}`),
  getDealCategories: async () =>
    axios.get(`${API_URL}${ROUTES.dealCategories}`),
  getCountries: () =>
    axios.get(`${API_URL}${ROUTES.countries}`).then(({ data }) => data),
  getPets: () => axios.get(`${API_URL}${ROUTES.pets}`).then(({ data }) => data),
  getUnitStatuses: () => axios.get(`${API_URL}${ROUTES.unitStatuses}`),
  getIndustries: () => axios.get(`${API_URL}${ROUTES.industries}`),
  getKnobRiderOptions: () => axios.get(`${API_URL}${ROUTES.knobRiderOptions}`),
  getWindowGuardOptions: () =>
    axios.get(`${API_URL}${ROUTES.windowGuardOptions}`),
  getKidOptions: () => axios.get(`${API_URL}${ROUTES.kidOptions}`),
  getRiderTypes: () => axios.get(`${API_URL}${ROUTES.riderTypes}`),
  getDocumentTypes: () => axios.get(`${API_URL}${ROUTES.documentTypes}`),
  getLivingSituationTypes: async () =>
    axios.get(`${API_URL}${ROUTES.livingSituationTypes}`),
  getDurationTypes: () => axios.get(`${API_URL}${ROUTES.durationTypes}`),
  getAnswerTypeOptions: () =>
    axios.get(`${API_URL}${ROUTES.answerTypeOptions}`),
  getEmergencyContactTypes: () =>
    axios.get(`${API_URL}${ROUTES.emergencyContactTypes}`),
  getCountyCriminalSearchSyncOptions: () =>
    axios.get(`${API_URL}${ROUTES.countyCriminalSearchSyncOptions}`),
  getStates: () =>
    axios.get(`${API_URL}${ROUTES.states}`).then(({ data }) => data),
};

export default api;
