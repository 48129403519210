import { useMutation, useQuery, queryCache } from "react-query";

import { Company } from "admin/components/Modal/AssignDocumentModal/interfaces";
import { CreateDocument, SaveDocumentDraft } from "admin/interfaces/api";
import { PreviewDocument } from "admin/interfaces/api/previewDocument";
import api from "admin/lib/api";
import { Document } from "admin/pages/Documents/interfaces";
import { NOTIFICATIONS } from "shared/config/constants";
import { openNotification } from "shared/utils/ui";

import { COMPANY_QUERRY_KEYS } from "./companyQueries";
import { TAGS_QUERY_KEYS } from "./tagsQueries";

export const DOCUMENT_TEMPLATE_QUERY_KEYS = Object.freeze({
  getDocumentTemplates: () => ["getDocumentTemplates"],
  getDocumentDrafts: () => ["getDocumentDrafts"],
  getDocumentDraft: (documentId: string) => ["getDocumentDraft", documentId],
  getDocumentTable: () => ["getDocumentTable"],
});

export const useCreateDocument = ({ onSuccess, onError }) => {
  const [mutate, response] = useMutation(
    (payload: CreateDocument) =>
      api
        .uploadDocumentTemplate(payload)
        .then(() =>
          openNotification(
            `The ${payload.name} has been successfully created`,
            NOTIFICATIONS.info
          )
        ),
    {
      onSuccess: async () => {
        await queryCache.invalidateQueries(
          DOCUMENT_TEMPLATE_QUERY_KEYS.getDocumentTemplates()
        );
        await queryCache.invalidateQueries(TAGS_QUERY_KEYS.getTags());
        onSuccess();
      },
      onError: (error) => {
        openNotification("Failed to upload the document.", NOTIFICATIONS.error);
        onError(error);
      },
    }
  );

  return {
    createDocument: mutate,
    response,
  };
};

export const useGetDocumentDrafts = () => {
  const response = useQuery(
    DOCUMENT_TEMPLATE_QUERY_KEYS.getDocumentDrafts(),
    () => api.getDocumentDrafts().then(({ data }) => data),
    {
      onError: () =>
        openNotification("Failed to load document drafts", NOTIFICATIONS.error),
    }
  );
  return response;
};

export const useGetDocumentDraft = ({ documentId }: { documentId: string }) => {
  const response = useQuery(
    DOCUMENT_TEMPLATE_QUERY_KEYS.getDocumentDraft(documentId),
    () => api.getDocumentDraft(documentId).then(({ data }) => data),
    {
      enabled: documentId,
      onError: () =>
        openNotification("Failed to load document draft.", NOTIFICATIONS.error),
    }
  );
  return response;
};

export const useSaveDocumentDraft = (config: any) => {
  const [mutate, response] = useMutation(
    (payload: SaveDocumentDraft) => api.postDocumentDrafts(payload),
    config
  );

  return {
    autoSaveDocument: mutate,
    response,
  };
};

export const useDocumentTable = (config) => {
  const response = useQuery(
    DOCUMENT_TEMPLATE_QUERY_KEYS.getDocumentTable(),
    () => api.getDocumentTemplates().then(({ data }) => data),
    config
  );
  return response;
};

export const useAssignDocumentsToCompany = () => {
  const [mutate, response] = useMutation(
    ({
      companyId,
      documentIds,
    }: {
      companyId: number;
      documentIds: number[];
    }) => api.assignDocuments({ companyId, documentIds }),
    {
      onSuccess: async () => {
        await queryCache.invalidateQueries(
          DOCUMENT_TEMPLATE_QUERY_KEYS.getDocumentTemplates()
        );
        await queryCache.invalidateQueries(
          COMPANY_QUERRY_KEYS.getCompaniesToAssignDocuments()
        );
      },
      onError: () =>
        openNotification(
          "Failed to assign document(s) to company.",
          NOTIFICATIONS.error
        ),
    }
  );

  return {
    assignDocuments: mutate,
    response,
  };
};

export const useUnassignDocumentFromCompany = ({ onSuccess, onError }) => {
  const [mutate, response] = useMutation(
    ({ company, document }: { company: Company; document: Document }) =>
      api
        .unassignDocument({
          companyId: company.id,
          documentIds: [document.id],
        })
        .then((data) => {
          openNotification(
            `The ${document.name} has been unassigned from ${company.name}.`,
            NOTIFICATIONS.info
          );
          return data;
        }),
    {
      onSuccess: async () => {
        await queryCache.invalidateQueries(
          DOCUMENT_TEMPLATE_QUERY_KEYS.getDocumentTemplates()
        );
        await queryCache.invalidateQueries(
          COMPANY_QUERRY_KEYS.getCompaniesToAssignDocuments()
        );
        onSuccess();
      },
      onError: (
        error: { errors: string[] },
        payload: { company: { name: string } }
      ) => {
        onError({ properties: error?.errors, company: payload?.company?.name });
      },
    }
  );

  return {
    unassignDocument: mutate,
    response,
  };
};

export const useDeleteDocument = () => {
  const [mutate, response] = useMutation(
    ({
      documentId,
      // NOTE: need the document name into the args to display a proper notification
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      documentName,
    }: {
      documentId: number;
      documentName: string;
    }) => api.deleteDocument(documentId),
    {
      onSuccess: (data, { documentName }) => {
        queryCache.invalidateQueries(
          DOCUMENT_TEMPLATE_QUERY_KEYS.getDocumentTemplates()
        );
        queryCache.invalidateQueries(
          COMPANY_QUERRY_KEYS.getCompaniesToAssignDocuments()
        );

        openNotification(
          `The ${documentName} has been deleted.`,
          NOTIFICATIONS.info
        );
      },
      onError: () =>
        openNotification("Failed to delete the document", NOTIFICATIONS.error),
    }
  );

  return {
    deleteDocument: mutate,
    response,
  };
};

export const usePreviewDocument = () => {
  const [mutate, { isLoading }] = useMutation(
    (payload: PreviewDocument) => api.previewDocumentTemplate(payload),
    {
      onError: () =>
        openNotification("Failed to preview the document", NOTIFICATIONS.error),
    }
  );

  return {
    previewDocument: mutate,
    isLoadingPreviewDocument: isLoading,
  };
};
