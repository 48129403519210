import React from "react";

import SuccessModal from "./SuccessModal";

interface SuccessModalProps {
  validPeriod: string;
  submit: () => void;
}

const SuccessModalContainer = ({ validPeriod, submit }: SuccessModalProps) => {
  return (
    <SuccessModal title="Success" validPeriod={validPeriod} submit={submit} />
  );
};

export default SuccessModalContainer;
