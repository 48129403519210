import React from "react";

import { Formik } from "formik";
import isString from "lodash/isString";

import {
  LUMEN_FILTER_MODE,
  LUMEN_SECTION_TYPE,
} from "manager/config/lumen.config";
import { Modal } from "shared/components/Modal";
import { RegularText, SmallErrorText } from "shared/components/Typography";
import useModal from "shared/hooks/useModal";

import { COLLECTIONS_CONDITIONS_VALIDATION_SCHEMA } from "../LumenFilterConditions/lumenFilterConditions.util";

import LumenFilterConditions from "./components/LumenFilterConditions";
import { ModalBody } from "./styled";
import { getInitialValues } from "./utils";

const LABEL_PER_SECTION = Object.freeze({
  [LUMEN_SECTION_TYPE.COLLECTIONS]: "Collections",
});

const LABEL_PER_MODE = Object.freeze({
  [LUMEN_FILTER_MODE.CRUCIAL]: "Crucial",
  [LUMEN_FILTER_MODE.IGNORED]: "Ignored",
});

interface NewCollectionsFilterModalProps {
  inEditMode?: boolean;
  mode: string;
  lumenSection: string;
  formulaName: string;
  subtitle: string;
  data: any;
}

export const NewCollectionsFilterModal = () => {
  const {
    currentModalContext,
    selfApi,
  }: {
    currentModalContext: NewCollectionsFilterModalProps;
    selfApi: { closeDialogWithValue: (data: any) => void };
  } = useModal();

  const { mode, lumenSection, formulaName, subtitle, data } =
    currentModalContext;

  const onSubmit = (values) => {
    selfApi.closeDialogWithValue(values);
  };

  const sectionLabel = LABEL_PER_SECTION[lumenSection];
  const modeLabel = LABEL_PER_MODE[mode];

  const initialValues = data || getInitialValues(mode);
  const inEditMode = !!data;

  const title = inEditMode
    ? `Edit "${modeLabel}" filter for ${sectionLabel}`
    : `Add new "${modeLabel}" filter for ${sectionLabel}`;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={COLLECTIONS_CONDITIONS_VALIDATION_SCHEMA}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, submitForm, errors }) => (
        <Modal
          title={title}
          subtitle={
            <span>
              Formula: <b>{formulaName}</b>
            </span>
          }
          submitButtonLabel="CONFIRM"
          submit={submitForm}
          submitting={isSubmitting}
          closeOnSubmit={false}
          width="946px"
        >
          <ModalBody>
            <RegularText weak>{subtitle}</RegularText>
            <LumenFilterConditions mode={mode} />
            {errors?.conditions && isString(errors?.conditions) && (
              <SmallErrorText>{errors?.conditions}</SmallErrorText>
            )}
          </ModalBody>
        </Modal>
      )}
    </Formik>
  );
};
