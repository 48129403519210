import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import BaseBanner from "shared/components/Banner";
import { Button } from "shared/components/Button";
import Icon from "shared/components/Icon";
import ProgressBar from "shared/components/ProgressBar";
import Tile from "shared/components/Tile";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

const SummaryCardWrapper = styled.div`
  .tile {
    margin-bottom: 0px;
  }
`;

const TileCard = styled(Tile)`
  height: 300px;
  ${MEDIA_QUERIES.w.ltMd} {
    height: 350px;
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const PropertyContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 9px;
`;

const PropertyIcon = styled(Icon.MyPropertyBoldIcon)`
  margin-right: 14px;
  align-self: flex-start;
`;

const PropertyUnitName = styled.h3`
  font-weight: 500;
  font-size: 17px;
  margin-bottom: 0;
`;

const PropertyAddress = styled.div`
  font-weight: 300;
  color: ${COLORS.secondary};
`;

const Description = styled.div`
  display: flex;
  font-size: 12px;
  color: ${COLORS.primary};
  margin-top: 18px;
  & > *:not(:last-of-type) {
    margin-right: 24px;
  }
`;

const Footer = styled.div`
  align-self: flex-end;
  height: 100%;
  width: 100%;
  margin-top: 24px;
  display: flex;
  ${MEDIA_QUERIES.w.ltSm} {
    flex-direction: column;
    margin-top: 24px;
  }
`;

const FooterDetails = styled.div`
  margin-top: auto;
`;

const AdditionalInformationRequired = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: ${COLORS.warning};
  margin-bottom: 10px;
`;

const CompleteButton = styled(Button)`
  margin-left: auto;
  margin-top: auto;
  ${MEDIA_QUERIES.w.ltSm} {
    margin-left: 0;
    margin-right: auto;
    margin-top: 24px;
  }
`;

const ProgressContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const StyledProgressBar = styled(ProgressBar)`
  width: 200px;
  height: 4px;
  align-self: center;
`;

const ProgressLabel = styled.span`
  font-size: 12px;
  color: ${COLORS.primary};
  margin-right: 10px;
`;
const ProgressValue = styled.span`
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  color: ${COLORS.primary};
  margin-right: 10px;
`;

const FallbackContent = styled.h5`
  font-size: 15px;
  font-weight: 300;
  color: ${COLORS.secondary};
  margin-bottom: 0;
`;

const ButtonWrapper = styled.div`
  margin-left: auto;
  margin-top: auto;
  ${MEDIA_QUERIES.w.ltSm} {
    margin-left: 0;
    margin-right: auto;
    margin-top: 24px;
  }
`;

const Banner = styled(BaseBanner)`
  background-color: ${COLORS.lightBlue};
  color: white;
  margin-left: auto;
  margin-right: auto;
  font-size: 12px;
  padding: 4px;
  justify-content: center;
  margin-bottom: 16px;
  text-align: center;

  svg {
    display: none;
  }

  ${MEDIA_QUERIES.w.ltLg} {
    padding: 8px;
  }
`;

const PaymentContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 8px;
`;

const PaymentLink = styled(Link)`
  font-size: 12px;
  display: inline-block;
  color: ${COLORS.accentDark};
`;

export {
  Body,
  PropertyContainer,
  PropertyIcon,
  PropertyUnitName,
  PropertyAddress,
  Description,
  Footer,
  FooterDetails,
  AdditionalInformationRequired,
  CompleteButton,
  ProgressContainer,
  StyledProgressBar,
  ProgressLabel,
  FallbackContent,
  ButtonWrapper,
  Banner,
  SummaryCardWrapper,
  TileCard,
  PaymentContainer,
  PaymentLink,
  ProgressValue,
};
