import React from "react";

import { useGetApplications } from "renter/hooks/api";
import { useTranslation } from "renter/hooks/useTranslation";

import Applications from "./Applications";

const ApplicationsContainer = () => {
  const { t } = useTranslation({ keyPrefix: "renter-app" });
  const { applications, isApplicationLoading } = useGetApplications();

  return (
    <Applications
      name={t("applications.name")}
      applications={applications}
      loading={isApplicationLoading}
    />
  );
};

export default ApplicationsContainer;
