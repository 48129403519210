import React from "react";

import { animated, easings, useSpring } from "@react-spring/web";

import { useMounted } from "shared/hooks/useMounted";

import { SlideDirection } from "./types";

export { easings };

export interface FadeInProps {
  visible?: boolean;
  collapse?: boolean;
  delay?: number;
  direction?: SlideDirection;
  amount?: number;
  duration?: number;
  easing?: (t: number) => number;
}

export const FadeIn: React.FC<FadeInProps> = ({
  visible,
  delay,
  children,
  collapse,
  direction = SlideDirection.up,
  amount = 24,
  duration,
  easing,
}) => {
  const { mounted } = useMounted();
  visible = visible ?? mounted;
  const isCollapsed = collapse && !visible;
  const styles = useSpring({
    opacity: visible ? 1 : 0,
    y: visible
      ? 0
      : direction === SlideDirection.up
      ? amount
      : direction === SlideDirection.down
      ? -amount
      : 0,
    x: visible
      ? 0
      : direction === SlideDirection.left
      ? amount
      : direction === SlideDirection.right
      ? -amount
      : 0,
    height: isCollapsed ? 0 : "auto",
    pointerEvents: isCollapsed ? "none" : "auto",
    delay,
    config: {
      duration,
      easings: easing,
    },
  });

  // @ts-ignore
  return <animated.div style={styles}>{children}</animated.div>;
};

export default FadeIn;
