import React from "react";

import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";

import { MobileWarningNotice } from "manager/components/EmailTemplates/EmailBuilder";
import Layout from "manager/components/Layout";
import Container from "shared/components/Container";
import { BackLink } from "shared/components/Links";
import PageTitle from "shared/components/PageTitle";
import Spacer from "shared/components/Spacer";
import { BREAKPOINT_RESOLUTIONS } from "shared/config/constants";

import { PAGE_TITLE, BACK_LINK } from "./config";
import EmailBuilderForm from "./EmailBuilderFormContainer";

const EmailBuilder = ({ header, sidebar }) => {
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT_RESOLUTIONS.sm });

  return (
    <Layout
      sidebar={sidebar}
      header={{
        ...header,
        backLink: BACK_LINK,
      }}
    >
      <Helmet>
        <title>{PAGE_TITLE}</title>
      </Helmet>
      <Container noPaddingBottom>
        <BackLink.Container>
          <BackLink {...BACK_LINK} />
        </BackLink.Container>
        {isMobile && (
          <>
            <MobileWarningNotice />
            <Spacer />
          </>
        )}
        <PageTitle.Container>
          <PageTitle>{PAGE_TITLE}</PageTitle>
        </PageTitle.Container>
        <EmailBuilderForm />
      </Container>
    </Layout>
  );
};

EmailBuilder.propTypes = {
  header: PropTypes.object.isRequired,
  sidebar: PropTypes.object.isRequired,
};

export default EmailBuilder;
