import React, { ElementType, ReactNode, Ref } from "react";

import Icon from "shared/components/Icon";
import Placeholder from "shared/components/Table/Placeholder";
import { ListProps } from "shared/components/types";

import { Body, LoadingContainer, ListContainer } from "./styled";

export type ListWrapperProps = {
  List: ElementType;
  listProps: ListProps;
  listContainerProps?: Record<string, unknown>;
  loading?: boolean;
  hasInputValue?: boolean;
  placeholder?: {
    label: string;
    Icon: ElementType;
  };
  customHeaderContent?: ReactNode;
  listRef?: Ref<any>;
  className?: string;
};

export const ListWrapper = ({
  List,
  listProps,
  listContainerProps,
  loading,
  hasInputValue,
  placeholder,
  listRef,
  className,
}: ListWrapperProps) => (
  <Body className={className}>
    {loading ? (
      <LoadingContainer>
        <Icon.LoadingSpinnerGreenLargeIcon data-testid="loading" />
      </LoadingContainer>
    ) : (
      <>
        {listProps.items.length > 0 ? (
          <ListContainer>
            <List
              listRef={listRef}
              {...listContainerProps}
              listProps={{
                ...listProps,
                itemProps: {
                  noBorderBottom: true,
                  firstItemNoBorderTop: true,
                  noBorderX: true,
                  ...listProps.itemProps,
                },
              }}
            />
          </ListContainer>
        ) : (
          <Placeholder
            type={
              hasInputValue
                ? Placeholder.TYPES.search
                : Placeholder.TYPES.noData
            }
            {...placeholder}
          />
        )}
      </>
    )}
  </Body>
);
