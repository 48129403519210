import React, { useState, useEffect } from "react";

type Props = {
  delay?: number;
};

export const Delayed: React.FC<Props> = ({ children, delay = 500 }) => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShown(true);
    }, delay);
    return () => clearTimeout(timer);
  }, [delay]);

  return isShown ? <>{children}</> : null;
};
