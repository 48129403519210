import React from "react";

import { Form } from "formik";

import { SearchableList, CollapsibleRadioList } from "shared/components/Form";
import Icon from "shared/components/Icon";

import { SelectCompanyFormProps } from "./interfaces";
import { FormContainer } from "./styled";

const SelectCompanyForm = ({
  isLoading,
  companies = [],
  setSearchingValue,
  setSelectedCompany,
  searchingValue,
  setFieldValue,
  selectedCompany,
}: SelectCompanyFormProps) => (
  <FormContainer>
    <Form>
      <SearchableList
        hasInputValue={!!searchingValue}
        search={{
          fieldName: "companySearch",
          action: (value) => setSearchingValue(value),
          label: "Search by company name",
          width: "350px",
          debounce: true,
        }}
        placeholder={{
          label: "Search by company name",
          Icon: Icon.SearchIcon,
        }}
        List={CollapsibleRadioList}
        listContainerProps={{
          groupName: "company",
          onChange: ({ target: { value } }) => {
            setFieldValue("company", value);
            setSelectedCompany(companies?.find((item) => item.id === value));
          },
        }}
        listProps={{
          items: companies?.map((company) => ({
            key: company.id,
            label: company.name,
          })),
          selectedItem: selectedCompany,
        }}
        loading={isLoading}
      />
    </Form>
  </FormContainer>
);

export default SelectCompanyForm;
