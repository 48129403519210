import React from "react";
import _ from "lodash";
import { Col, Row } from "antd-latest";
import { Helmet } from "react-helmet";
import {
  useGetApplicationAudits,
  useGetLatestLumenV2Run,
} from "manager/hooks/api";
import { Loader } from "shared/components/LargeLoader";
import Spacer from "shared/components/Spacer";
import { SIZES } from "shared/components/Spacer/constants";
import { usePermissions } from "shared/hooks";
import {
  useGetApplicationFromApplicationService,
  useListApplicantsFromApplicationService,
} from "shared/hooks/api";
import { LumenV2 } from "shared/components/vega/LumenV2";
import { ApplicantSummaryV2 } from "shared/components/vega/ApplicantSummaryV2";
import ConditionallyProceedInfoBanner from "../ConditionallyProceedInfoBanner";
import SystemAuditsTable, {
  useOpenCreateDealAuditNoteModal,
} from "../SystemAuditsTable";
import LedgerTable from "../LedgerTable";
import { ActionBar } from "./action-bar";
import { AppRow, AppRowProps } from "./app-row";
import { AppTabs } from "./app-tabs";
import { Dependents } from "./dependents";
import { DealHeaderV2 } from "./header";
import { useRequestCountyRecords } from "./request-county-records/hook";
import {
  shouldDisableLink,
  shouldShowAction,
} from "./request-county-records/utils";
import {
  ApproveDealModalV2,
  CancelDealModalV2,
  DeclineDealModalV2,
  MoreInfoDealModal,
} from "./modals";
import { SendToEntrataModal } from "./modals/send-to-entrada-modal";
import { ResendToPmsModal } from "./modals/resend-to-pms-modal";
import { useAppSummary } from "./use-app-summary";

export type AppListProps = {
  dealId: string;
};

const Space = () => (
  <>
    &nbsp;
    <Spacer size={SIZES.xs} />
  </>
);

export type AppListInnerProps = {
  apps?: AppRowProps[];
  refetchDeal?: () => void;
  loading?: boolean;
  skipId?: string;
};

export const AppListInner: React.FC<AppListInnerProps> = ({
  apps = [],
  refetchDeal,
  skipId,
  loading,
}) => {
  return (
    <Loader loading={loading}>
      {apps.map(
        (app) =>
          (!skipId || String(app.appId) !== String(skipId)) && (
            <AppRow {...app} refetchDeal={refetchDeal} />
          )
      )}
    </Loader>
  );
};

export const AppList: React.FC<AppListProps> = ({ dealId }) => {
  const {
    title,
    appWellProps,
    dealModalProps,
    headerProps,
    actionBarProps,
    apps = [],
    deps = [],
    loading,
    isMobile,
    isExpanded,
    refetchDeal,
    toggleExpanded,
    addNotes,
    contactAll,
    invite,
    goBack,
    countyRecordsProps,
    applicationAuditsCount,
    conditionalProceed,
    dealStatus,
    fraudIndicators,
    applicationVrn,
  } = useAppSummary(dealId);

  const { application } =
    useGetApplicationFromApplicationService(applicationVrn);
  const { applicants } =
    useListApplicantsFromApplicationService(applicationVrn);
  const applicantOrder = _.chain([
    "primary-applicant",
    "applicant",
    "guarantor",
    "occupant",
  ])
    .invert()
    .mapValues(_.toSafeInteger)
    .value();

  const { canAccessSensitiveDealDataInLumen } = usePermissions();

  const { showLumen } = actionBarProps || {};

  const { lumenRun } = useGetLatestLumenV2Run(applicationVrn, showLumen);

  const {
    openRequestCountyRecordsModal,
    isRequestCountyCriminalSearchLoading,
  } = useRequestCountyRecords(countyRecordsProps, refetchDeal);

  const countyCriminalSearchStatus =
    countyRecordsProps?.countyCriminalSearchStatus;
  const countyRecordsRequestable =
    shouldShowAction(countyCriminalSearchStatus?.value) &&
    !shouldDisableLink(
      countyCriminalSearchStatus,
      isRequestCountyCriminalSearchLoading
    );

  return (
    <Loader loading={loading} absoluteCenter>
      <SendToEntrataModal {...dealModalProps} />
      <ResendToPmsModal {...dealModalProps} />
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {conditionalProceed && (
        <>
          <ConditionallyProceedInfoBanner />
          <Space />
        </>
      )}

      <DealHeaderV2
        deal={headerProps}
        refetchDeal={refetchDeal}
        contactAll={contactAll}
        invite={invite}
        requestCountyRecords={
          countyRecordsRequestable && openRequestCountyRecordsModal
        }
      />

      <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
        <ActionBar
          {...actionBarProps}
          lumenRun={lumenRun}
          refetch={refetchDeal}
        />

        {showLumen && !_.isEmpty(lumenRun) && (
          <LumenV2 lumenRun={lumenRun} summary />
        )}

        <ApproveDealModalV2 {...dealModalProps} />
        <MoreInfoDealModal {...dealModalProps} />
        <DeclineDealModalV2 {...dealModalProps} />
        <CancelDealModalV2 {...dealModalProps} />
      </div>

      <AppTabs
        dealId={dealId}
        Activity={() => {
          // Only query activities when tab is loaded
          const { applicationAudits, isApplicationAuditsLoading } =
            useGetApplicationAudits(dealId);
          const { openCreateAuditNoteModal } = useOpenCreateDealAuditNoteModal({
            dealId,
          });
          return (
            <SystemAuditsTable
              noPadding
              systemAudits={applicationAudits}
              isSystemAuditsLoading={isApplicationAuditsLoading}
              openCreateAuditNoteModal={openCreateAuditNoteModal}
            />
          );
        }}
        Lumen={() => (
          <LumenV2
            lumenRun={lumenRun}
            detailed={canAccessSensitiveDealDataInLumen}
          />
        )}
        Ledger={() => <LedgerTable application={applicationVrn} />}
        manualNotes={applicationAuditsCount?.numberOfManualNotes}
      >
        <Row gutter={[0, 16]}>
          {_.chain(applicants)
            .sortBy((applicant) =>
              _.get(applicantOrder, applicant?.party, 9999)
            )
            .map(({ id }) => (
              <Col span={24}>
                <ApplicantSummaryV2
                  application={application}
                  id={id}
                  lumenRun={lumenRun}
                  legacyApp={_.find(apps, { applicantVrn: id })}
                  refetchDeal={refetchDeal}
                  key={id}
                />
              </Col>
            ))
            .value()}

          <Col span={24}>
            <Dependents dependents={deps} />
          </Col>
        </Row>
      </AppTabs>
    </Loader>
  );
};
