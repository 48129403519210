import React from "react";

export type IConfettiContext = { explode: () => void };

const ConfettiContext = React.createContext<IConfettiContext>({
  explode: () => {},
});

export const ConfettiProvider: React.FC<{ context: IConfettiContext }> = ({
  children,
  context,
}) => {
  return (
    <ConfettiContext.Provider value={context}>
      {children}
    </ConfettiContext.Provider>
  );
};

export const useConfettiContext = () => React.useContext(ConfettiContext);
