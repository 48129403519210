import styled from "@emotion/styled";

import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

const itemSmStyles = {
  padding: "16px 20px",
};

const itemMobileStyles = {
  borderRadius: 0,
  borderLeft: 0,
  borderRight: 0,
};

const Item = styled("div")(
  (props: {
    width?: number;
    firstItemNoBorderTop?: boolean;
    noBorderX?: boolean;
    noBorderBottom?: boolean;
    noBorderTop?: boolean;
    indent?: boolean;
    hide?: boolean;
  }) => ({
    width: props.width,
    backgroundColor: COLORS.list.background,
    display: "flex",
    padding: "20px 24px",
    border: `1px solid ${COLORS.list.border}`,
    borderRadius: "2px",
    [MEDIA_QUERIES.w.ltSm]: {
      ...itemSmStyles,
      ...itemMobileStyles,
    },
    ...(props.firstItemNoBorderTop && {
      "&:first-of-type": {
        borderTop: 0,
      },
    }),
    ...(props.noBorderX && {
      borderLeft: 0,
      borderRight: 0,
    }),
    ...(props.noBorderBottom && {
      borderBottom: 0,
    }),
    ...(props.noBorderTop && {
      borderTop: 0,
    }),
    ...(!props.noBorderBottom && {
      "&:not(:last-of-type)": {
        borderBottom: 0,
      },
    }),
    ...(props.indent && {
      ...itemSmStyles,
      paddingLeft: "40px",
      [MEDIA_QUERIES.w.ltSm]: {
        paddingLeft: "36px",
        ...itemMobileStyles,
      },
    }),
    ...(props.hide && {
      display: "none",
    }),
  })
);

export { Item };
