import axios from "axios";

import ROUTES from "shared/config/apiRoutes";
import { API_URL } from "shared/lib/api/setupApi";
import { transformRoute } from "shared/utils/routing";

const api = {
  editEmployeeName: ({ employeeId, firstName, lastName }) =>
    axios.patch(
      `${API_URL}${transformRoute(ROUTES.editEmployee, {
        employeeId,
      })}`,
      { firstName, lastName }
    ),
};

export default api;

export const editEmployeeName = ({
  employeeId,
  firstName,
  lastName,
}: {
  employeeId: number;
  firstName: string;
  lastName: string;
}) =>
  axios.patch(
    `${API_URL}${transformRoute(ROUTES.editEmployee, {
      employeeId,
    })}`,
    { firstName, lastName }
  );
