import { Dayjs } from "dayjs";

import { dayKey } from "./utils";

export type PropertyUnit = {
  property: string;
  unit: string;
  street: string;
  city: string;
  stateCode: string;
  zipCode: string;
  availableDate: Dayjs;
  nextUnitListingDate?: Dayjs;
};

export type PropertyUnitId = {
  propertyId: string;
  unitId: string;
};

export type UnitRange = {
  id: string;
  startDate: Dayjs;
  endDate: Dayjs;
  price: number;
  term: number;
  hashedId: string;
};

export type DayRange = {
  start: Dayjs;
  end: Dayjs;
};

export class Unit {
  public readonly id: string;

  termLength: number;

  constructor(public readonly day: Dayjs, public readonly range: UnitRange) {
    this.day = day.startOf("day");
    this.termLength = range.term;
    this.id = `${range.id}_${dayKey(day)}`;
  }
}

export enum ListType {
  calendar = "calendar",
  list = "list",
}

export enum Sort {
  price = "price",
  startDate = "startDate",
  duration = "duration",
}

export type PriceRange = {
  key: string;
  name: string;
  min?: number;
  max?: number;
};

export type SortOption = {
  key: string;
  name: string;
  value: Sort;
};
