import React from "react";

import Icon from "shared/components/Icon";

import { NoPropertiesContainer, NoPropertiesLabel } from "./styled";

const EmployeeNoProperties = ({ hasSearch }: { hasSearch: boolean }) => (
  <NoPropertiesContainer>
    {hasSearch ? (
      <>
        <Icon.NoSearchResultsIcon />
        <NoPropertiesLabel>No Search Results</NoPropertiesLabel>
      </>
    ) : (
      <>
        <Icon.NoPropertiesIcon />
        <NoPropertiesLabel>No assigned properties yet</NoPropertiesLabel>
      </>
    )}
  </NoPropertiesContainer>
);

export default EmployeeNoProperties;
