import React from "react";

import { Checkbox as BaseCheckbox } from "antd";
import { CheckboxProps as BaseCheckboxProps } from "antd/lib/checkbox";

import { PopoverContent } from "shared/components/Form/Input/styled";
import { Popover } from "shared/components/Popover";

import { Error } from "../styled";

import {
  Container,
  Checkbox as StyledCheckbox,
  Label,
  DisabledWrapper,
} from "./styled";

export type CheckboxProps = BaseCheckboxProps & {
  id: string;
  label?: React.ReactNode;
  title?: string;
  labelStyle?: object;
  error?: string;
  checked?: boolean;
  value?: boolean; // Formik uses `value` instead of `checked`, so this is required
  useState?: boolean;
  defaultCheckedState?: boolean;
  inline?: boolean;
  indeterminate?: boolean;
  disabled?: boolean;
  disabledCheckboxText?: string;
};

const _Checkbox: React.FC<CheckboxProps> = ({
  error,
  id,
  label,
  title,
  labelStyle,
  value,
  checked,
  onChange,
  useState,
  defaultCheckedState,
  inline,
  disabled,
  disabledCheckboxText,
  ...props
}) => {
  const [checkedState, setCheckedState] = React.useState(defaultCheckedState);

  const handleOnChange = (event) => {
    if (onChange) {
      onChange(event);
    }
    setCheckedState(event.target.checked);
  };
  return (
    <Container inline={inline}>
      <Label
        htmlFor={id}
        className="checkbox-label"
        title={title}
        style={labelStyle}
      >
        <Popover
          withoutOverlay
          key={id}
          placement="topLeft"
          content={<PopoverContent>{disabledCheckboxText}</PopoverContent>}
          disabled={!disabledCheckboxText || !disabled}
        >
          <DisabledWrapper disabled={disabled}>
            <StyledCheckbox
              data-testid="checkbox"
              id={id}
              checked={useState ? checkedState : checked || value}
              onChange={handleOnChange}
              disabled={disabled}
              {...props}
            />
          </DisabledWrapper>
        </Popover>
        {label}
      </Label>
      {error && <Error>{error}</Error>}
    </Container>
  );
};

export const Checkbox = Object.assign(_Checkbox, { Group: BaseCheckbox.Group });
