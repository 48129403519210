import React, { useState } from "react";
import _ from 'lodash'
import queryString from "query-string";

import ROUTES from "manager/config/routes";
import Spacer from "shared/components/Spacer";
import { RegularText } from "shared/components/Typography";
import { useActiveUser, useGetPactSafeConfig } from "shared/hooks/api";

import useExperianCollector from "shared/hooks/useExperianCollector";
import { useGoogleMapsApi } from "shared/hooks/useGoogleMapsApi";

import useModal from "shared/hooks/useModal";

import { formatDate } from "shared/utils/ui";

import {
  useGetExperianAuthToken,
  useGetExperianAuthTokenByKiq,
} from "../../hooks/api/experianAuthQueries";

import {
  ExperianAuthResponseError,
  ExperianAuthTokenParams,
  ExperianKiq,
} from "../../interfaces/api/experianAuth";

import ErrorModal from "./ErrorModal";
import ExperianAuth from "./ExperianAuth";
import ExperianAuthKiq from "./ExperianAuthKiq";
import FailureModal from "./FailureModal";
import OtpVerificationModal from "./OtpVerificationModal";
import SuccessModal from "./SuccessModal";
import DisputeModal from "./DisputeModal";
import { isExperianProvider, getExperianErrorMessages } from "./utils";

const ExperianAuthContainer = ({ company }) => {
  const { activeUser } = useActiveUser();
  const [kiqList, setKiqList] = useState<ExperianKiq[]>(null);
  const [sessionToken, setSessionToken] = useState("");
  const { pactSafeConfig, isPactSafeConfigLoading } = useGetPactSafeConfig();
  const { requestExperianAuthToken } = useGetExperianAuthToken();
  const { requestExperianAuthTokenByKiq } = useGetExperianAuthTokenByKiq();
  const { openModalDialog } = useModal();
  const redirect = () => {
    const urlParams = queryString.parse(window.location.search);
    const redirectPath = urlParams.r;

    if (redirectPath) {
      // @ts-ignore
      window.location.replace(redirectPath);
    } else {
      window.location.replace(ROUTES.dashboard);
    }
  };
  const renderSuccessModal = (validPeriod) =>
    openModalDialog(SuccessModal, { validPeriod, submit: redirect });
  const renderFailureModal = () =>
    openModalDialog(FailureModal, { submit: redirect });
  const renderDisputeModal = () => openModalDialog(DisputeModal);
  const renderErrorModal = (customMessage=null) =>
    openModalDialog(ErrorModal, { submit: redirect,  customMessage });
  const renderOTPModal = ({ params, data }) =>
    openModalDialog(OtpVerificationModal, {
      submittedUser: params,
      experianResponse: data,
      redirect,
      renderSuccessModal,
      renderFailureModal,
      renderErrorModal,
      setSessionToken,
      setKiqList,
    });

  useGoogleMapsApi();
  useExperianCollector();

  const handleSubmit = async (
    params: ExperianAuthTokenParams,
    { setSubmitting, setErrors }
  ) => {
    setSubmitting(true);
    await requestExperianAuthToken(params, {
      onSuccess: (data) => {
        const experianResponse = data.experian;

        if (experianResponse.userAuthenticated) {
          renderSuccessModal(formatDate(experianResponse.expiration));
        }

        if (
          !experianResponse.userAuthenticated &&
          experianResponse.authSession &&
          experianResponse.otpEnabled
        ) {
          renderOTPModal({ params, data });
        }

        if (
          !experianResponse.userAuthenticated &&
          experianResponse.authSession &&
          experianResponse.kiqEnabled
        ) {
          setSessionToken(experianResponse.authSession);
          setKiqList(experianResponse.questionSet);
        }
      },
      onError: (error: ExperianAuthResponseError) => {
        if ([400, 422].includes(error.statusCode)) {
          const errorMessages = getExperianErrorMessages(error)

          renderErrorModal(
            <>
              {_.map(errorMessages, (message) => (
                <>
                  <Spacer size={Spacer.SIZES.sm} />
                  <RegularText>
                    {message}
                  </RegularText>
                </>
              ))}
            </>
          );
          setErrors(errorMessages.join(' '));
        } else {
          renderFailureModal();
        }
      },
    });
    setSubmitting(false);
  };

  if (company?.bccProvider && !isExperianProvider(company)) {
    redirect();
  }

  const cleanQuestions = () => {
    setKiqList(null);
  };

  const handleKiqSubmit = async (params: Record<string, number>) => {
    const reqParams: Record<string, number | string> = {
      ...params,
      "auth-session": sessionToken,
    };
    await requestExperianAuthTokenByKiq(reqParams, {
      onSuccess: (data) => {
        const experianResponse = data.experian;
        if (experianResponse.userAuthenticated) {
          renderSuccessModal(formatDate(experianResponse.expiration));
        }
      },
      onError: (error: ExperianAuthResponseError) => {
        if (error.statusCode === 400) {
          renderErrorModal();
        } else {
          renderFailureModal();
        }
      },
    });
  };

  return (
    <>
      {isExperianProvider(company) && kiqList ? (
        <ExperianAuthKiq
          cleanQuestions={cleanQuestions}
          questionsList={kiqList}
          getExperianAuthTokenByKiq={handleKiqSubmit}
        />
      ) : (
        isExperianProvider(company) && (
          <ExperianAuth
            user={activeUser}
            redirect={redirect}
            getExperianAuthToken={handleSubmit}
            pactSafeConfig={pactSafeConfig}
            isPactSafeConfigLoading={isPactSafeConfigLoading}
            renderDisputeModal={renderDisputeModal}
          />
        )
      )}
    </>
  );
};

export default ExperianAuthContainer;
