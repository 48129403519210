import React, { useState } from "react";

import capitalize from "lodash/capitalize";

import {
  useGetBluemoonFieldsQuery,
  useGetBluemoonFieldsTypesQuery,
  useGetBluemoonFieldsDatapointsQuery,
  useUpdateBluemoonField,
} from "manager/hooks/api";
import useModal from "shared/hooks/useModal";

import BluemoonFieldsMapping from "./BluemoonFieldsMapping";
import { FIELD_TYPES, APP_SOURCE_TYPE } from "./config";
import FieldDescriptionModal from "./FieldDescriptionModal";
import { Filters, Field } from "./interfaces";
import VeroFieldTypeInfoModal from "./VeroFieldTypeInfoModal";

const BluemoonFieldsMappingContainer = ({ backLink }) => {
  const blankFilters = {
    nameType: "",
    type: "",
  };
  const [filters, setFilters] = useState({ ...blankFilters });
  const [selectedField, setSelectedField] = useState(null);
  const [serverError, setServerError] = useState(null);
  const {
    data: fields,
    isLoading,
    isFetching,
  } = useGetBluemoonFieldsQuery(filters);
  const { data: fieldTypes } = useGetBluemoonFieldsTypesQuery();
  const { data: fieldsDatapoints } = useGetBluemoonFieldsDatapointsQuery();
  const { updateBluemoonField, isBluemoonFieldUpdating } =
    useUpdateBluemoonField({
      onError: (fieldError: Error) => {
        const errorList =
          Object.entries(Object?.values(fieldError)[0]).map(([key, value]) => {
            return { field: key, message: value[0] };
          }) || [];
        setServerError(errorList);
      },
      onSuccessCb: () => setServerError(null),
    });

  const { openModalDialog, closeActiveModalDialog } = useModal();
  const updateFilters = (value: Filters) => {
    if (value) {
      setFilters({
        ...filters,
        ...value,
      });
    } else {
      setFilters({ ...blankFilters });
    }
  };

  const updateField = (values: Field | any) => updateBluemoonField(values);

  const handleUpdateFieldName = (value: string, fieldsRow: Field) => {
    const inputValue = value.slice(1, -1);
    const { name, nickname } = fieldsRow;
    setSelectedField({
      id: fieldsRow.id,
    });
    const isValueDifferentThanNameOrNickname: boolean =
      inputValue !== name && inputValue !== nickname;

    if (isValueDifferentThanNameOrNickname) {
      updateField({
        ...fieldsRow,
        nickname: value,
      });
    }
  };

  const handleFieldTypeChange = (option: string, fieldsRow: Field) => {
    setSelectedField({
      fieldType: option,
      id: fieldsRow.id,
    });
    if (
      option !== FIELD_TYPES.VERO_SMART_FIELD &&
      option !== FIELD_TYPES.BLUEMOON_SMART_FIELD
    ) {
      updateField({
        ...fieldsRow,
        veroHasPriority: false,
        source: null,
      });
    }
    return null;
  };

  const handleFieldDatapointChange = (option: string, fieldsRow: Field) => {
    const questionType = option.split("+")[1];
    const field = option.split("+")[0];
    if (fieldsRow?.bluemoonChoices?.length > 0) {
      fieldsRow?.bluemoonChoices?.forEach((choice, i) => {
        fieldsRow.choices[i].nickname = choice.option;
      });
    }
    if (selectedField?.fieldType !== FIELD_TYPES.MANUAL) {
      if (questionType === "property") {
        setSelectedField({
          ...fieldsRow,
          source: {
            app: APP_SOURCE_TYPE.VERO,
            model: capitalize(questionType),
            attribute: field,
          },
        });
        updateField({
          ...fieldsRow,
          source: {
            app: APP_SOURCE_TYPE.VERO,
            model: capitalize(questionType),
            attribute: field,
          },
        });
      } else if (questionType === "lease") {
        setSelectedField({
          ...fieldsRow,
          source: {
            app: APP_SOURCE_TYPE.LEASING,
            model: capitalize(questionType),
            attribute: field,
          },
        });
        updateField({
          ...fieldsRow,
          source: {
            app: APP_SOURCE_TYPE.LEASING,
            model: capitalize(questionType),
            attribute: field,
          },
        });
      } else if (questionType === "applicant") {
        setSelectedField({
          ...fieldsRow,
          source: {
            app: APP_SOURCE_TYPE.LEASING,
            model: APP_SOURCE_TYPE.APPLICANT,
            attribute: field,
          },
        });
        updateField({
          ...fieldsRow,
          source: {
            app: APP_SOURCE_TYPE.LEASING,
            model: APP_SOURCE_TYPE.APPLICANT,
            attribute: field,
          },
        });
      }
    } else {
      setSelectedField({
        ...fieldsRow,
        source: null,
      });
      updateField({
        ...fieldsRow,
        source: null,
      });
    }
  };

  const handleDescriptionUpdate = (fieldsRow: Field) => {
    if (!fieldsRow?.isFeeField) {
      const newValues = { ...fieldsRow };
      updateField({ ...newValues, feeType: null });
      if (!isBluemoonFieldUpdating) {
        setTimeout(() => {
          closeActiveModalDialog();
        }, 300);
      }
    } else {
      updateField({ ...fieldsRow });
      if (!isBluemoonFieldUpdating) {
        setTimeout(() => {
          closeActiveModalDialog();
        }, 300);
      }
    }
  };

  const handleOpenInfoModal = () => {
    openModalDialog(VeroFieldTypeInfoModal);
  };

  const openDescriptionModal = (fieldsRow: Field) => {
    openModalDialog(FieldDescriptionModal, {
      fieldType: fieldsRow?.type,
      fieldsRow,
      isBluemoonFieldUpdating,
      submit: handleDescriptionUpdate,
    });
  };

  return (
    <BluemoonFieldsMapping
      backLink={backLink}
      fields={fields}
      types={fieldTypes}
      dataPoints={fieldsDatapoints}
      filters={filters}
      updateFilters={updateFilters}
      isLoading={isLoading}
      isFieldUpdating={!isLoading && (isBluemoonFieldUpdating || isFetching)}
      handleFieldTypeChange={handleFieldTypeChange}
      handleFieldDatapointChange={handleFieldDatapointChange}
      selectedField={selectedField}
      handleUpdateFieldName={handleUpdateFieldName}
      onFieldTypeInfoIconClick={handleOpenInfoModal}
      onDescriptionClick={openDescriptionModal}
      fieldErrors={serverError}
    />
  );
};

export default BluemoonFieldsMappingContainer;
