import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { Layout } from "antd";

import BaseAvatar from "shared/components/Avatar";
import {
  COLORS,
  MEDIA_QUERIES,
  DIMENSIONS,
  Z_INDEX,
} from "shared/config/constants";

const { Sider } = Layout;

const commonMenuItemCss = css`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${COLORS.borders};
  border-right: 1px solid ${COLORS.borders};
  height: 60px !important;
  padding: 20px 16px !important;
  margin: 0 !important;
  line-height: 16px !important;

  .item-inner-content {
    display: inline-flex;
    align-items: center;
  }

  .item-icon {
    width: 16px;
    height: 16px;
  }

  .item-text-container {
    display: flex;
    align-items: center;
    margin-left: 17px;
  }

  .text-prefix-icon {
    margin-right: 3px;
  }

  .item-text {
    line-height: 12px;
    font-size: 12px;
    font-weight: 400;
    white-space: pre-line;
    margin-bottom: 0;
    text-transform: capitalize;
  }

  &:hover {
    background: ${COLORS.background};

    .item-text {
      font-weight: 600;
    }
  }

  &::after {
    border-right: transparent; // fix onClick transition flicking border
  }
`;

const selectedItemCss = css`
  .item-text {
    font-weight: 600;
  }

  &::after {
    border-left: 3px solid ${COLORS.sidebar.menu.selected};
    border-right: none;
    border-radius: 0 2px 2px 0;
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    content: "";
    transform: scaleY(1);
    opacity: 1;
  }
`;

const StyledSider = styled(Sider)`
  position: fixed;
  top: ${DIMENSIONS.header.gteMd.height};
  z-index: ${Z_INDEX.sidebar};
  height: calc(100vh - ${DIMENSIONS.header.gteMd.height});
  border-right: 1px solid ${COLORS.borders};
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: transparent;

  .ant-menu.ant-menu-root {
    height: auto !important;
    min-height: 100%;
    border-right: 0;
  }

  // Styles for the base menu item
  .ant-menu-item {
    ${commonMenuItemCss}

    &.ant-menu-item-selected {
      ${selectedItemCss}
    }
  }

  // Styles for the submenu in the sidebar
  .ant-menu-submenu {
    // Styles for the submenu root item (the main item)
    .ant-menu-submenu-title {
      ${commonMenuItemCss}
    }

    // Styles for "activating" the root item when a child item is active
    &.submenu-item-child-selected {
      .ant-menu-submenu-title {
        ${selectedItemCss}
      }
    }

    // Overrides for the submenu items
    .ant-menu-item {
      display: list-item;
      padding-left: 48px !important;
      padding-right: 0 !important;

      // Reduce the space between the bullet and the text
      & > * {
        position: relative;
        left: -5px;
      }
    }
  }

  // Compact mode activated for tables, only the icons shown without text
  &.compact-mode {
    .ant-menu-item,
    .ant-menu-submenu-title {
      padding: 0 !important;
      justify-content: center;

      .ant-menu-submenu-arrow {
        right: 5px;
      }
    }
  }

  ${MEDIA_QUERIES.w.gteXl} {
    max-width: ${DIMENSIONS.sidebar.gteXl.width} !important;
    min-width: ${DIMENSIONS.sidebar.gteXl.width} !important;
  }
  ${MEDIA_QUERIES.w.ltXl} {
    max-width: ${DIMENSIONS.sidebar.gteXl.width} !important;
    min-width: ${DIMENSIONS.sidebar.ltXl.width} !important;
    &.compact-mode {
      max-width: ${DIMENSIONS.sidebar.ltXl.width} !important;
    }
  }
  ${MEDIA_QUERIES.w.ltMd} {
    max-width: 100% !important;
    min-width: 100% !important;
  }
`;

const Avatar = styled(BaseAvatar)`
  width: 24px;
  height: 24px;
  margin-right: -5px;
`;

const Badge = styled.div`
  background-color: ${COLORS.badge.default.background};
  color: ${COLORS.badge.default.text};
  padding: 5px;
  border-radius: 2px;
  font-Size: 10px;
  font-weight: 600;
  position: absolute;
  left: 60px;
  line-height: 5px;

  ${MEDIA_QUERIES.w.btwMdXl} {
    left: 43px;
    top: 40px;
    padding: 0;
    background-Color: transparent;
    color: ${COLORS.badge.textOnly.text};
  }

  ${MEDIA_QUERIES.w.ltMd} {
    position: relative;
    left: 10px;
  },
`;

export { StyledSider, Avatar, Badge };
