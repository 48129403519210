import React from "react";

import { Switch, Route, Redirect } from "react-router-dom";

import ROUTES from "renter/config/routes";

import { LeaseRentOptionsPage } from "renter/pages/LeaseRentOptions";

import PropertiesPortal from "./PropertiesPortal";
import UnitsPortal from "./UnitsPortal";

const MagicPortalRouter = () => {
  return (
    <Switch>
      <Route
        exact
        path={ROUTES.magicPortalCompany}
        render={() => <PropertiesPortal />}
      />
      <Route
        exact
        path={[ROUTES.magicPortalCompanyProperty, ROUTES.magicPortalProperty]}
        render={() => <UnitsPortal />}
      />
      <Route
        exact
        path={[ROUTES.magicPortalPropertyUnit]}
        render={() => <LeaseRentOptionsPage />}
      />
      <Redirect from="*" to={ROUTES.notFound} />
    </Switch>
  );
};

export default MagicPortalRouter;
