import isNumber from "lodash/isNumber";
import * as Yup from "yup";

import {
  LUMEN_CREDIT_RECORDS_SCOPE,
  LUMEN_FILTER_MODE,
  LUMEN_INCOME_RENT_RATIO_WITH_GUARANTORS_MODE,
  LUMEN_INCOME_TYPES,
  LUMEN_CASH_RUNWAY_RATIO_WITH_GUARANTORS_MODE,
  LUMEN_SECTION_TYPE,
  LUMEN_FORMULA_MAX_VALUES,
  LUMEN_FILTER_CONDITION_TYPE,
  LUMEN_FILTER_CONDITION_SUB_TYPE,
} from "manager/config/lumen.config";

export const LUMEN_FORMULA_SECTIONS_NAVIGATION = Object.freeze({
  [LUMEN_SECTION_TYPE.CREDIT]: {
    prevSection: () => null,
    nextSection: () => LUMEN_SECTION_TYPE.VERIFICATIONS,
  },
  [LUMEN_SECTION_TYPE.VERIFICATIONS]: {
    prevSection: () => LUMEN_SECTION_TYPE.CREDIT,
    nextSection: () => LUMEN_SECTION_TYPE.INCOME,
  },
  [LUMEN_SECTION_TYPE.INCOME]: {
    prevSection: () => LUMEN_SECTION_TYPE.VERIFICATIONS,
    nextSection: () => LUMEN_SECTION_TYPE.COLLECTIONS,
  },
  [LUMEN_SECTION_TYPE.COLLECTIONS]: {
    prevSection: () => LUMEN_SECTION_TYPE.INCOME,
    nextSection: () => LUMEN_SECTION_TYPE.CRIMINAL_RECORDS,
  },
  [LUMEN_SECTION_TYPE.CRIMINAL_RECORDS]: {
    prevSection: () => LUMEN_SECTION_TYPE.COLLECTIONS,
    nextSection: () => LUMEN_SECTION_TYPE.HOUSING_COURT_RECORDS,
  },
  [LUMEN_SECTION_TYPE.HOUSING_COURT_RECORDS]: {
    prevSection: () => LUMEN_SECTION_TYPE.CRIMINAL_RECORDS,
    nextSection: () => null,
  },
});

const MAIN_SWITCH_PROP_PER_SECTION = Object.freeze({
  [LUMEN_SECTION_TYPE.CREDIT]: "useCreditScore",
  [LUMEN_SECTION_TYPE.VERIFICATIONS]: "useVerifications",
  [LUMEN_SECTION_TYPE.INCOME]: "useIncomeRentRatio",
  [LUMEN_SECTION_TYPE.ASSETS]: "useAssets",
  [LUMEN_SECTION_TYPE.COLLECTIONS]: "useCollections",
  [LUMEN_SECTION_TYPE.CRIMINAL_RECORDS]: "useCriminalRecords",
  [LUMEN_SECTION_TYPE.HOUSING_COURT_RECORDS]: "useHousingCourtRecords",
});

const typeNumber = () => {
  return Yup.number()
    .typeError("Must be a number")
    .moreThan(0, "Must be greater than 0")
    .nullable();
};

const buildFilterValidationFunction = (safetyNetField) => {
  // eslint-disable-next-line func-names
  return function (filters) {
    // NOTE: must be defined with 'function' to have access to 'this'
    const safetyNetValue = !!this.options?.parent?.[safetyNetField];
    return filters?.length > 0 || safetyNetValue;
  };
};

const buildMinIdealValidationFunction = (idealProperty) => {
  // eslint-disable-next-line func-names
  return function (minValue) {
    // NOTE: must be defined with 'function' to have access to 'this'
    const idealValue = this.options?.parent?.[idealProperty];
    if (isNumber(minValue) && isNumber(idealValue) && idealValue > 0) {
      return minValue < idealValue;
    }
    return true;
  };
};

const getFilterValidationSchema = (
  filterProp,
  safetyNetProp,
  isValidationActiveFn,
  ...dataProps
) =>
  Yup.array().when(dataProps, {
    is: isValidationActiveFn,
    then: Yup.array().test(
      filterProp,
      "At least one filter must be specified or the safety net should be enabled",
      buildFilterValidationFunction(safetyNetProp)
    ),
  });

const getFilterValidationSchemaOnSection = (
  section,
  filterProp,
  safetyNetProp
) =>
  getFilterValidationSchema(
    filterProp,
    safetyNetProp,
    (sectionActive) => !!sectionActive,
    MAIN_SWITCH_PROP_PER_SECTION[section]
  );

const getFilterValidationSchemaOnCreditAndMode = () =>
  getFilterValidationSchema(
    "filterCreditPublicRecords",
    "useCreditRecordSafetyNet",
    (sectionActive, creditRecordsScope) =>
      !!sectionActive &&
      !!creditRecordsScope &&
      creditRecordsScope !== LUMEN_CREDIT_RECORDS_SCOPE.NONE,
    MAIN_SWITCH_PROP_PER_SECTION[LUMEN_SECTION_TYPE.CREDIT],
    "creditRecordsScope"
  );

const requiredOnProperty = (property, validationType, message) =>
  Yup.mixed().when(property, {
    is: true,
    then: validationType.required(message).nullable(),
  });

const requiredOnCreditScore = (validationType, message) => {
  return requiredOnProperty(
    MAIN_SWITCH_PROP_PER_SECTION[LUMEN_SECTION_TYPE.CREDIT],
    validationType,
    message
  );
};

const requiredOnVerifications = (validationType, message) => {
  return requiredOnProperty(
    MAIN_SWITCH_PROP_PER_SECTION[LUMEN_SECTION_TYPE.VERIFICATIONS],
    validationType,
    message
  );
};

const requiredOnIncome = (validationType, message) => {
  return requiredOnProperty(
    MAIN_SWITCH_PROP_PER_SECTION[LUMEN_SECTION_TYPE.INCOME],
    validationType,
    message
  );
};

const requiredRatioAndMode = (
  message: string,
  fieldId: string,
  ratioMode: string,
  extendedRequiredValidation?: any
) => {
  const baseRequiredValidation = typeNumber()
    .lessThan(
      LUMEN_FORMULA_MAX_VALUES.RATIO.maxValue,
      LUMEN_FORMULA_MAX_VALUES.RATIO.message
    )
    .required(message);
  const thenValidation = extendedRequiredValidation
    ? extendedRequiredValidation(baseRequiredValidation)
    : baseRequiredValidation;
  return typeNumber().when(
    [MAIN_SWITCH_PROP_PER_SECTION[LUMEN_SECTION_TYPE.INCOME], fieldId],
    {
      is: (useSectionRatio, ratioWithGuarantors) =>
        !!useSectionRatio && ratioWithGuarantors === ratioMode,
      then: thenValidation,
    }
  );
};

const requiredRatioOnIncomeAndMode = (
  message: string,
  extendedRequiredValidation?: any
) => {
  return requiredRatioAndMode(
    message,
    "incomeRentRatioWithGuarantors",
    LUMEN_INCOME_RENT_RATIO_WITH_GUARANTORS_MODE.DIFFERENT_RATIO,
    extendedRequiredValidation
  );
};

const requiredRatioOnCashRunwayAndMode = (
  message: string,
  extendedRequiredValidation?: any
) => {
  return requiredRatioAndMode(
    message,
    "cashRunwayRatioWithGuarantors",
    LUMEN_CASH_RUNWAY_RATIO_WITH_GUARANTORS_MODE.DIFFERENT_RATIO,
    extendedRequiredValidation
  );
};

const requiredOnIncomeAndAssets = (
  defaultValidation: Yup.AnySchema,
  requiredValidation: Yup.AnySchema
) => {
  return defaultValidation.when(
    [MAIN_SWITCH_PROP_PER_SECTION[LUMEN_SECTION_TYPE.INCOME], "useAssets"],
    {
      is: (useIncomeRentRatio, useAssets) =>
        !!useIncomeRentRatio && !!useAssets,
      then: requiredValidation,
    }
  );
};

const VALIDATION_SCHEMA_PER_SECTION = Object.freeze({
  [LUMEN_SECTION_TYPE.CREDIT]: Yup.object().shape({
    useCreditScore: Yup.boolean(),
    minCreditScore: requiredOnCreditScore(
      typeNumber()
        .lessThan(
          LUMEN_FORMULA_MAX_VALUES.SCORE.maxValue,
          LUMEN_FORMULA_MAX_VALUES.SCORE.message
        )
        .test(
          "minLessThanIdealCreditScore",
          "Must be smaller than the ideal score",
          buildMinIdealValidationFunction("idealCreditScore")
        )
        .nullable(),
      "The minimum credit score is required"
    ),
    idealCreditScore: requiredOnCreditScore(
      typeNumber().lessThan(
        LUMEN_FORMULA_MAX_VALUES.SCORE.maxValue,
        LUMEN_FORMULA_MAX_VALUES.SCORE.message
      ),
      "The ideal credit score is required"
    ),
    creditScoreScopeWithGuarantors: requiredOnCreditScore(
      Yup.string(),
      "The credit score with guarantors mode is required"
    ),
    creditRecordsScope: requiredOnCreditScore(
      Yup.string(),
      "The credit records scope is required"
    ),
    filterCreditPublicRecords: getFilterValidationSchemaOnCreditAndMode(),
    useCreditRecordSafetyNet: Yup.boolean().nullable(),
  }),
  [LUMEN_SECTION_TYPE.VERIFICATIONS]: Yup.object().shape({
    useVerifications: Yup.boolean(),
    verifyIdentity: requiredOnVerifications(
      Yup.boolean(),
      "The identity verification scope is required"
    ),
  }),
  [LUMEN_SECTION_TYPE.INCOME]: Yup.object().shape({
    useIncomeRentRatio: Yup.boolean(),
    incomeRentRatioPeriod: requiredOnIncome(
      Yup.string(),
      "The income rent ratio period is required"
    ),
    minIncomeRentRatio: requiredOnIncome(
      typeNumber()
        .lessThan(
          LUMEN_FORMULA_MAX_VALUES.RATIO.maxValue,
          LUMEN_FORMULA_MAX_VALUES.RATIO.message
        )
        .test(
          "minLessThanIdealRentRatio",
          "Must be smaller than the ideal ratio",
          buildMinIdealValidationFunction("idealIncomeRentRatio")
        ),
      "The minimum ratio is required"
    ),
    idealIncomeRentRatio: requiredOnIncome(
      typeNumber().lessThan(
        LUMEN_FORMULA_MAX_VALUES.RATIO.maxValue,
        LUMEN_FORMULA_MAX_VALUES.RATIO.message
      ),
      "The ideal ratio is required"
    ),
    incomeRentRatioWithGuarantors: requiredOnIncome(
      Yup.string(),
      "The income rent ratio with guarantors mode is required"
    ),
    minIncomeRentRatioWithGuarantor: requiredRatioOnIncomeAndMode(
      "The minimum ratio is required",
      (schema) =>
        schema.test(
          "minLessThanIdealRentRatioWithGuarantor",
          "Must be smaller than the ideal ratio",
          buildMinIdealValidationFunction("idealIncomeRentRatioWithGuarantor")
        )
    ),
    idealIncomeRentRatioWithGuarantor: requiredRatioOnIncomeAndMode(
      "The ideal ratio is required"
    ),
    minCashRunwayRatioWithGuarantor: requiredRatioOnCashRunwayAndMode(
      "The minimum ratio is required",
      (schema) =>
        schema.test(
          "minLessThanIdealCashRunwayRatioWithGuarantor",
          "Must be smaller than the ideal ratio",
          buildMinIdealValidationFunction("idealCashRunwayRatioWithGuarantor")
        )
    ),
    idealCashRunwayRatioWithGuarantor: requiredRatioOnCashRunwayAndMode(
      "The ideal ratio is required"
    ),
    totalIncomeWithGuarantor: requiredOnIncome(
      Yup.string(),
      "The total income with guarantors mode is required"
    ),
    incomeConsidered: requiredOnIncome(
      Yup.string(),
      "The income to rent mode is required"
    ),
    useAssets: requiredOnIncome(Yup.bool(), "Field is required"),
    totalAssetsWithGuarantor: requiredOnIncomeAndAssets(
      Yup.string().nullable(),
      Yup.string()
        .nullable()
        .required("The total assets with guarantors mode is required")
    ),
    minAssetsRatio: requiredOnIncomeAndAssets(
      typeNumber(),
      typeNumber()
        .test(
          "minLessThanIdealRentRatio",
          "Must be smaller than the ideal ratio",
          buildMinIdealValidationFunction("idealAssetsRatio")
        )
        .required("The minimum ratio is required")
    ),
    idealAssetsRatio: requiredOnIncomeAndAssets(
      typeNumber(),
      typeNumber().required("The ideal ratio is required")
    ),
  }),
  [LUMEN_SECTION_TYPE.COLLECTIONS]: Yup.object().shape({
    filterCollectionRecords: getFilterValidationSchemaOnSection(
      LUMEN_SECTION_TYPE.COLLECTIONS,
      "filterCollectionRecords",
      "useCollectionRecordSafetyNet"
    ),
    useCollectionRecordSafetyNet: Yup.boolean().nullable(),
  }),
  [LUMEN_SECTION_TYPE.CRIMINAL_RECORDS]: Yup.object().shape({
    filterCriminalRecords: getFilterValidationSchemaOnSection(
      LUMEN_SECTION_TYPE.CRIMINAL_RECORDS,
      "filterCriminalRecords",
      "useCriminalRecordSafetyNet"
    ),
    useCriminalRecordSafetyNet: Yup.boolean().nullable(),
  }),
  [LUMEN_SECTION_TYPE.HOUSING_COURT_RECORDS]: Yup.object().shape({
    filterHousingCourtRecords: getFilterValidationSchemaOnSection(
      LUMEN_SECTION_TYPE.HOUSING_COURT_RECORDS,
      "filterHousingCourtRecords",
      "useHousingCourtRecordSafetyNet"
    ),
    useHousingCourtRecordSafetyNet: Yup.boolean().nullable(),
  }),
});

const cleanUpPayloadBeforeSave = (payload) => {
  const newPayload = { ...payload };
  Object.values(LUMEN_SECTION_TYPE).forEach((section) => {
    const switchProp = MAIN_SWITCH_PROP_PER_SECTION[section];
    if (switchProp && !payload[switchProp]) {
      const fields = Object.keys(
        VALIDATION_SCHEMA_PER_SECTION[section]?.fields || {}
      );

      if (fields?.length) {
        fields
          .filter((field) => field !== switchProp)
          .forEach((field) => {
            newPayload[field] = null;
          });
      }
    }
  });

  // if (!payload.useCreditRecordSafetyNet) {
  //   newPayload.filterCreditPublicRecords = (
  //     payload.filterCreditPublicRecords || []
  //   ).filter((f) => f.mode !== LUMEN_FILTER_MODE.IGNORED);
  // }
  //
  // if (!payload.useCriminalRecordSafetyNet) {
  //   newPayload.filterCriminalRecords = (
  //     payload.filterCriminalRecords || []
  //   ).filter((f) => f.mode !== LUMEN_FILTER_MODE.IGNORED);
  // }
  //
  // if (!payload.useHousingCourtRecordSafetyNet) {
  //   newPayload.filterHousingCourtRecords = (
  //     payload.filterHousingCourtRecords || []
  //   ).filter((f) => f.mode !== LUMEN_FILTER_MODE.IGNORED);
  // }
  //
  // if (!payload.useCollectionRecordSafetyNet) {
  //   newPayload.filterCollectionRecords = (
  //     payload.filterCollectionRecords || []
  //   ).filter((f) => f.mode !== LUMEN_FILTER_MODE.IGNORED);
  // }

  if (
    payload.incomeRentRatioWithGuarantors ===
    LUMEN_INCOME_RENT_RATIO_WITH_GUARANTORS_MODE.NO_CHANGE
  ) {
    newPayload.minIncomeRentRatioWithGuarantor = null;
    newPayload.idealIncomeRentRatioWithGuarantor = null;
  }

  if (
    payload.cashRunwayRatioWithGuarantors ===
    LUMEN_CASH_RUNWAY_RATIO_WITH_GUARANTORS_MODE.NO_CHANGE
  ) {
    newPayload.minCashRunwayRatioWithGuarantor = null;
    newPayload.idealCashRunwayRatioWithGuarantor = null;
  }

  if (!payload.useIncomeRentRatio) {
    // NOTE: By default it should use stated income
    newPayload.incomeConsidered = LUMEN_INCOME_TYPES.STATED;
    // NOTE: By default it should not use assets
    newPayload.useAssets = false;
  }

  // NOTE: the BE doesn't accept "nulls" for these properties
  newPayload.filterCreditPublicRecords =
    newPayload.filterCreditPublicRecords || [];
  newPayload.filterCriminalRecords = newPayload.filterCriminalRecords || [];
  newPayload.filterHousingCourtRecords =
    newPayload.filterHousingCourtRecords || [];
  newPayload.filterCollectionRecords = newPayload.filterCollectionRecords || [];
  newPayload.useCreditRecordSafetyNet =
    newPayload.useCreditRecordSafetyNet || false;
  newPayload.useCriminalRecordSafetyNet =
    newPayload.useCriminalRecordSafetyNet || false;
  newPayload.useHousingCourtRecordSafetyNet =
    newPayload.useHousingCourtRecordSafetyNet || false;
  newPayload.useCollectionRecordSafetyNet =
    newPayload.useCollectionRecordSafetyNet || false;

  // NOTE: format filterHousingCourtRecords KEYWORDS
  newPayload.filterHousingCourtRecords =
    newPayload.filterHousingCourtRecords.map((filter) => {
      const conditions = filter.conditions?.map((condition) => {
        if (
          condition.conditionType === LUMEN_FILTER_CONDITION_TYPE.KEYWORDS &&
          condition.conditionSubType ===
            LUMEN_FILTER_CONDITION_SUB_TYPE.EVICTIONS
        ) {
          return {
            conditionType: LUMEN_FILTER_CONDITION_TYPE.KEYWORDS,
            conditionSubType: LUMEN_FILTER_CONDITION_SUB_TYPE.EVICTIONS,
            keywords: [
              ...(condition?.keywords || []),
              ...(condition?.otherKeywords || []),
            ],
          };
        }
        return condition;
      });
      return { ...filter, conditions };
    });

  return newPayload;
};

const getFirstEnabledSectionOrStart = (lumenFormulaRevision) => {
  const sections = Object.values(LUMEN_SECTION_TYPE);
  const firstEnabledSection = sections.find(
    (section) => lumenFormulaRevision[MAIN_SWITCH_PROP_PER_SECTION[section]]
  );
  return firstEnabledSection || sections[0];
};

export {
  VALIDATION_SCHEMA_PER_SECTION,
  cleanUpPayloadBeforeSave,
  getFirstEnabledSectionOrStart,
};
