import React from "react";

import Logo from "shared/components/Logo";
import DefaultMobileSidebar from "shared/components/Sidebar/MobileSidebar";
import Tooltip from "shared/components/Tooltip";

import {
  ContainerMobile,
  HeaderActionContainer,
  HelpIcon,
  LogoContainer,
} from "./styled";

export interface IMobileHeader {
  user: any;
  sidebarItems: any[];
  groupedRoutes: any;
  logoLink: string;
  headerAction?: React.ReactNode;
  MobileSidebar?: React.ElementType;
  [key: string]: any;
}

const MobileHeader = ({
  user,
  sidebarItems,
  groupedRoutes,
  logoLink,
  headerAction = undefined,
  MobileSidebar = DefaultMobileSidebar,
  ...props
}: IMobileHeader) => {
  const onSupport = () => window.open("https://support.sayvero.com", "_blank");

  return (
    <ContainerMobile {...props}>
      <MobileSidebar
        items={sidebarItems}
        groupedRoutes={groupedRoutes}
        user={user}
        data-testid="mobile-sidebar"
      />
      <LogoContainer to={logoLink}>
        <Logo data-testid="logo" />
      </LogoContainer>

      <HeaderActionContainer>
        {headerAction}
        <Tooltip title="Support">
          <HelpIcon onClick={onSupport} />
        </Tooltip>
      </HeaderActionContainer>
    </ContainerMobile>
  );
};

export default MobileHeader;
