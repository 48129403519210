import React, { useMemo } from "react";

import { Radio } from "antd";
import { Field } from "formik";
import PropTypes from "prop-types";

import { SourcesCreateEditModal } from "manager/components/Modal";
import {
  useAutoCreateVeroMarketingSources,
  useCreateMarketingSource,
} from "manager/hooks/api";
import FlexContainer from "shared/components/FlexContainer";
import { PopoverContent } from "shared/components/Form/Input/styled";
import Icon from "shared/components/Icon";
import { ActionLink } from "shared/components/Links";
import { Popover } from "shared/components/Popover";
import useModal from "shared/hooks/useModal";
import { keyPropType } from "shared/utils/propTypes";
import { sanitizeStringSearchValue } from "shared/utils/string";

import { sectionType } from "../../shared/propTypes";
import YardiDropdownMappingField from "../../shared/YardiDropdownMappingField";

import YardiMappingPage from "../../shared/YardiMappingPage";

import AutoCreateModal from "./AutoCreateModal";
import { stringifyVeroMarketingSourceId } from "./configureYardiPropertySection.util";
import {
  Section,
  SectionTitle,
  SectionSubTitle,
  ColumnHeader,
  ColumnIsDefault,
  SectionTitleLightActionText,
} from "./styled";

const YardiSectionMarketingSources = ({
  section,
  propertyName,
  propertyId,
  yardiPropertyId,
  veroMarketingSources,
  yardiMarketingSources,
  loading,
  provider,
}) => {
  const { openModalDialog } = useModal();
  const { createMarketingSource } = useCreateMarketingSource();
  const { autoCreateVeroMarketingSources } = useAutoCreateVeroMarketingSources(
    provider,
    yardiPropertyId
  );

  const header = (
    <>
      <SectionTitle>{section.name}</SectionTitle>
      <SectionSubTitle>
        <div>
          Applicants will select a marketing source during the VERO application
          process. Please add VERO marketing sources that you would like to make
          available to applicants and then link them to a External Provider
          marketing source.
        </div>
      </SectionSubTitle>
    </>
  );

  const createNewSource = () => {
    const saveAction = (newName) => {
      const source = { value: newName, property: propertyId };
      return createMarketingSource(source);
    };

    const usedNames = (veroMarketingSources || []).map((s) =>
      sanitizeStringSearchValue(s.label)
    );

    const context = {
      title: "Create New Marketing Source",
      propertyName,
      inputDescription: "",
      inputPlaceholder: "Marketing Source Name",
      confirmLabel: "CREATE SOURCE",
      saveAction,
      source: {},
      usedNames,
    };
    openModalDialog(SourcesCreateEditModal, context);
  };

  const autoCreateMarketingSources = () => {
    const onSave = () => {
      return autoCreateVeroMarketingSources();
    };

    const context = {
      title: "Auto-create VERO Marketing Sources?",
      propertyName,
      description:
        "When auto creating marketing sources in VERO we will use the marketing source name provided by the External Provider.",
      onSave,
    };

    openModalDialog(AutoCreateModal, context);
  };

  const from = (
    <>
      <FlexContainer>
        <ColumnHeader>VERO</ColumnHeader>
        <ActionLink onMouseDown={createNewSource} disabled={loading}>
          + NEW SOURCE
        </ActionLink>
      </FlexContainer>
    </>
  );

  const to = (
    <>
      <FlexContainer justifyContent="space-between">
        <ColumnHeader>External Provider</ColumnHeader>
        <SectionTitleLightActionText>
          <ActionLink onClick={autoCreateMarketingSources}>
            AUTO-CREATE MARKETING SOURCES
          </ActionLink>
        </SectionTitleLightActionText>
      </FlexContainer>
    </>
  );

  const buildComponentId = (id, property) => {
    return `marketingSources[${stringifyVeroMarketingSourceId(
      id
    )}].${property}`;
  };

  const elements = useMemo(
    () =>
      (veroMarketingSources || []).map((item) => {
        return {
          title: item.label,
          node: (
            <>
              <YardiDropdownMappingField
                name={buildComponentId(item.id, "yardiMarketingSource")}
                dropdownPlaceholder="Select Marketing Source"
                options={yardiMarketingSources || []}
                asFormField
              />
              <ColumnIsDefault alignItems="center">
                <Field
                  as={Radio}
                  type="radio"
                  id={buildComponentId(item.id, "isDefault")}
                  name="defaultMarketingSource"
                  value={item.id}
                >
                  Use as default
                </Field>
                <Popover
                  className="popover"
                  withoutOverlay
                  content={
                    <PopoverContent>
                      In the event that an applicant is invited to apply without
                      a unit, this marketing source will automatically be
                      applied. We suggest that you choose a generic marketing
                      source as your default option.
                    </PopoverContent>
                  }
                >
                  <Icon.InfoIcon />
                </Popover>
              </ColumnIsDefault>
            </>
          ),
        };
      }),
    [veroMarketingSources, yardiMarketingSources]
  );

  return (
    <Section height="auto" noPaddingBottom>
      <YardiMappingPage
        title={header}
        from={from}
        to={to}
        loading={loading}
        elements={elements || []}
        placeholder={{
          label: "Marketing sources",
          Icon: Icon.NoUnitsIcon,
        }}
      />
    </Section>
  );
};

YardiSectionMarketingSources.propTypes = {
  section: sectionType.isRequired,
  propertyName: PropTypes.string,
  propertyId: keyPropType,
  yardiPropertyId: keyPropType,
  loading: PropTypes.bool,
  veroMarketingSources: PropTypes.arrayOf(PropTypes.object),
  yardiMarketingSources: PropTypes.arrayOf(PropTypes.object),
  provider: PropTypes.string.isRequired,
};

YardiSectionMarketingSources.defaultProps = {
  loading: false,
  propertyName: undefined,
  propertyId: undefined,
  yardiPropertyId: undefined,
  veroMarketingSources: [],
  yardiMarketingSources: [],
};

export default YardiSectionMarketingSources;
