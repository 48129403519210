import React, { useMemo } from "react";

import { useHistory } from "react-router-dom";

import { CreateUnitListingModalTemplate } from "manager/components/Modal/CreateUnitListingModal";
import {
  useGetProperty,
  useGetUnit,
  useGetYardiUnit,
  useYardiResolveCreateUnitListing,
} from "manager/hooks/api";
import useModal from "shared/hooks/useModal";
import { onDialogSubmitError } from "shared/utils/api";

const YardiCreateUnitListingResolverModalContainer = () => {
  const history = useHistory();
  const { currentModalContext, selfApi } = useModal();
  const { yardiProperty, alert, provider } = currentModalContext;
  const veroUnitId = alert.relatedObjectId;
  const veroPropertyId = yardiProperty?.property?.id;
  const { property } = useGetProperty(veroPropertyId);
  const { unit } = useGetUnit(veroUnitId);
  const { resolveAlertUnitListing } = useYardiResolveCreateUnitListing();
  const { yardiUnit } = useGetYardiUnit({
    provider,
    yardiPropertyId: yardiProperty?.id,
    yardiUnitId: alert.refPk,
  });

  const submit = (unitListing, formikApi) => {
    const payload = {
      unitListing,
    };

    const onSuccess = () => selfApi.closeDialog();

    const onError = onDialogSubmitError(
      formikApi,
      history,
      "Failed to resolve unit listing alert."
    );

    resolveAlertUnitListing(
      { provider, alertId: alert?.id, body: payload },
      {
        onSuccess,
        onError,
      }
    );
  };

  const context = {
    id: veroUnitId,
    name: unit?.name,
    propertyName: property?.name,
  };

  const initialValues = useMemo(
    () => ({
      rent: yardiUnit?.rent,
      securityDeposit: yardiUnit?.securityDeposit,
      unitAvailableOn: yardiUnit?.unitAvailableOn,
    }),
    [yardiUnit]
  );

  return (
    <CreateUnitListingModalTemplate
      context={context}
      initialValues={initialValues}
      submit={submit}
      confirmLabel="CONFIRM"
      securityDepositDisabled
      availabilityDateDisabled
    />
  );
};

export default YardiCreateUnitListingResolverModalContainer;
