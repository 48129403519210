import React, { useEffect, useState } from "react";

import { ItemRender } from "antd-latest/lib/upload/interface";

import { AttachmentFile } from "shared/components/Form/UploadWall";
import { UPLOAD_ALLOWED_FILE_TYPES } from "shared/config/constants";

import { useUploadValidation } from "shared/hooks/useUploadValidation";
import { getThumbUrl } from "shared/utils/pdf";

import UploadWall from "./UploadWall";
import { mapFileToUploadItem } from "./UploadWall.utils";

export interface UploadWallContainerProps
  extends React.HTMLAttributes<HTMLDivElement> {
  maxCount?: number;
  existingFiles?: AttachmentFile[];
  uploadFile?: Function;
  deleteFile?: Function;
  deleteFilesById?: boolean;
  acceptFormats?: string[];
  maxSize?: number;
  disabled?: boolean;
  inReadOnlyMode?: boolean;
  enableReinitialize?: boolean;
  itemRender?: ItemRender<AttachmentFile>;
}

export const UploadWallContainer = ({
  maxCount = Infinity,
  existingFiles,
  uploadFile = (file) => Promise.resolve(file),
  deleteFile = () => Promise.resolve(),
  deleteFilesById = true,
  acceptFormats = UPLOAD_ALLOWED_FILE_TYPES,
  maxSize = undefined,
  disabled = false,
  inReadOnlyMode = false,
  enableReinitialize = false,
  itemRender,
  ...props
}: UploadWallContainerProps) => {
  const initialFiles = (existingFiles || []).map(mapFileToUploadItem);
  const [fileList, setFileList] = useState(initialFiles);

  const { isFileNameValid, isFileFormatValid, isFileSizeValid } =
    useUploadValidation();

  const handleChange = ({ file, fileList: files }) => {
    if (file.status === "uploading") {
      setFileList(files);
    }
  };

  const customRequest = async ({ file, onSuccess, onError }) => {
    const savedFiles = fileList.filter((item) => item.id !== file.id);
    try {
      const uploadedFile = await uploadFile(file);
      setFileList([
        ...savedFiles,
        {
          uid: uploadedFile.id,
          ...uploadedFile,
          status: "done",
          thumbUrl: getThumbUrl(uploadedFile),
        },
      ]);
      onSuccess("ok");
    } catch (error) {
      setFileList([...savedFiles, { ...file, status: "error" }]);
      onError(error);
    }
  };

  // eslint-disable-next-line consistent-return
  const onRemove = async (file) => {
    if (file.isRestricted === true) {
      return Promise.resolve();
    }

    if (file.status === "done") {
      if (deleteFilesById) {
        await deleteFile(file.id);
      } else {
        await deleteFile(file);
      }
    }

    const savedFiles = fileList.filter((item) => item.id !== file.id);
    setFileList(savedFiles);
  };

  const beforeUpload = (file) => {
    const { name, size } = file;
    return (
      isFileFormatValid(name, acceptFormats) &&
      isFileNameValid(name, true) &&
      isFileSizeValid(maxSize, size, name)
    );
  };

  useEffect(() => {
    if (enableReinitialize) {
      setFileList(initialFiles);
    }
  }, [existingFiles]);

  return (
    <UploadWall
      maxCount={maxCount}
      acceptFormats={acceptFormats}
      disabled={disabled}
      inReadOnlyMode={inReadOnlyMode}
      fileList={fileList}
      handleChange={handleChange}
      customRequest={customRequest}
      onRemove={onRemove}
      beforeUpload={beforeUpload}
      itemRender={itemRender}
      {...props}
    />
  );
};
