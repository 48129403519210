import { getRole } from "shared/state/Role/selectors";
import { getUser } from "shared/state/User/selectors";

export const employees = (state) => state?.entities?.employee;
export const employeesList = (state) =>
  employees(state) ? Object.values(employees(state)) : [];
export const employee = (state, id) => employees(state)?.[id];
export const getCompleteEmployee = (state, id) => {
  let currentEmployee = employees(state)?.[id];
  if (currentEmployee) {
    currentEmployee = {
      ...currentEmployee,
      user: getUser(state, currentEmployee.user),
      role: getRole(state, currentEmployee.role),
    };
  }
  return currentEmployee;
};
