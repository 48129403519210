export enum ApplicantTypes {
  applicant = 1,
  guarantor = 2,
  occupant = 3,
}

export enum CONCESSION_TYPES {
  amortize = "AM",
  specificMonths = "SM",
}
