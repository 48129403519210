import { toDate } from "../utils";

import {
  Employment,
  EmploymentStatus,
  IncomeAssetsSummaryResponse,
  VerificationTypes,
} from "./interfaces";

export const mapEmployment = (
  employmentStatus: EmploymentStatus,
  employment?: Record<string, any>[],
  linkedEmployment?: boolean,
  isArgyleEnabled?: boolean,
  incomeAsset?: IncomeAssetsSummaryResponse
): Employment => {
  // always return employment
  const active = employment?.filter(
    (history) => history.isPreviousEmployment === false
  );
  if (active?.length) employment = active;
  const em = employment?.sort((a, b) =>
    !a.endDate || a.endDate > b.endDate ? -1 : 1
  )?.[0];
  const annualSalary = em?.annualSalary ?? em?.annualSalary;

  let verificationTypes: VerificationTypes[] = [];
  if (
    incomeAsset &&
    (incomeAsset.assetsStatus === 2 || incomeAsset.assetsStatus === 3)
  )
    verificationTypes.push(VerificationTypes.LINK_BANK_ACCOUNT);
  if (em?.isLinkedAutomatically)
    verificationTypes.push(VerificationTypes.LINKED_EMPLOYMENT);
  else if (em?.fromFormUpload)
    verificationTypes.push(VerificationTypes.DOCUMENT_UPLOADED);
  else if (
    employmentStatus === EmploymentStatus.employed &&
    !em?.isLinkedAutomatically &&
    !em?.fromFormUpload &&
    !verificationTypes.find(
      (type) => type === VerificationTypes.LINK_BANK_ACCOUNT
    )
  )
    verificationTypes.push(VerificationTypes.MANUAL_UPLOAD);
  if (employmentStatus === EmploymentStatus.unemployed)
    verificationTypes = [VerificationTypes.UNEMPLOYED];

  return {
    skipped: !linkedEmployment,
    jobTitle: em?.jobTitle ?? undefined,
    employed: !em
      ? undefined
      : !em.endDate || new Date(em.endDate) > new Date(),
    company: em?.employerName,
    start: toDate(em?.startDate),
    end: toDate(em?.endDate),
    annualSalary,
    monthlySalary: annualSalary >= 0 ? annualSalary / 12 : undefined,
    enabled: isArgyleEnabled,
    verificationTypes,
  };
};
