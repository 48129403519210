import styled from "@emotion/styled";

import { TRANSITIONS } from "shared/config/constants";

type Visible = {
  visible?: boolean;
};
export const DarkOverlay = styled("div")<Visible>((props) => ({
  backgroundColor: "black",
  opacity: 0,
  width: "100vw",
  height: "100vh",
  position: "fixed",
  zIndex: 200,
  top: 0,
  left: 0,
  visibility: "hidden",
  pointerEvents: "none",
  transition: TRANSITIONS.slow,
  ...(props.visible && {
    opacity: 0.05,
    visibility: "visible",
  }),
}));

export const Title = styled("h2")<{ level?: number }>({
  display: "flex",
  alignItems: "center",
  marginBottom: 0,
});

export const PopoverContent = styled("div")({
  padding: "13px 20px",
  maxWidth: "290px",
});
