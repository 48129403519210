import styled from "@emotion/styled";

import BaseProgressBar from "shared/components/ProgressBar";

const AvailableDate = styled("div")({
  fontWeight: 300,
  fontSize: "12px",
  lineHeight: 1,
  marginTop: "6px",
});

const ProgressBar = styled(BaseProgressBar)({
  margin: "16px 0",
});

export { AvailableDate, ProgressBar };
