import queryString from "query-string";

export const transformRoute = (route, params = {}) => {
  let routeWithParams = route;

  Object.keys(params).forEach((key) => {
    routeWithParams = routeWithParams.replace(`:${key}`, params[key]);
  });

  return routeWithParams;
};

export const getQueryParams = () => {
  return queryString.parse(window.location.search);
};

export const clearQueryParams = () => {
  if (window.location.search !== "") {
    window.location.search = "";
  }
};

export const goBackWithReload = (history) => {
  const backLink = history.location?.state?.backLink;
  if (backLink) {
    history.push(backLink);
  } else {
    history.goBack();
    setTimeout(() => window.location.reload(), 1000);
  }
};
