import styled from "@emotion/styled";

import BaseCard from "shared/components/Card";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

const Card = styled(BaseCard)`
  width: 100% !important;
  padding: 80px 80px 24px 80px;

  .card-body {
    height: 380px;
    column-gap: 36px;

    .left-col {
      .content {
        max-width: 360px;

        .title {
          margin-bottom: 8px;
        }

        .subtitle {
          color: ${COLORS.violetTheme.lightViolet};
          margin-bottom: 48px;
        }

        .description {
          display: block;
          margin-bottom: 40px;
        }
      }
    }

    .right-col {
      img {
        float: right;
        width: 100%;
        max-width: 540px;
        min-width: 360px;
        height: auto;
      }
    }

    ${MEDIA_QUERIES.w.gteXxxl} {
      margin: 0 auto;
      max-width: 1000px;
    }

    ${MEDIA_QUERIES.w.ltMd} {
      height: auto;
      align-items: center;

      .left-col {
        .content {
          max-width: 100%;

          .description {
            margin-bottom: 32px;
          }
        }
      }

      .right-col {
        text-align: center;
        margin-bottom: 24px;

        img {
          float: none;
          max-width: 400px;
          min-width: 0;
        }
      }
    }
  }

  ${MEDIA_QUERIES.w.ltLg} {
    padding: 48px 48px 24px 48px;
  }
`;

export { Card };
