import { COLORS, colors } from "shared/config/constants";

export function getFraudRiskTextAndColor (fraudRiskLevel) {
  if (fraudRiskLevel === 'LOW') {
    return { text: "Low Risk", color: COLORS.green }
  } else if (fraudRiskLevel === 'MEDIUM') {
    return { text: "Medium Risk", color: COLORS.darkOrange }
  } else if (fraudRiskLevel === 'HIGH') {
    return { text: "High Risk", color: COLORS.failedRed }
  } else if (fraudRiskLevel === 'VERY_HIGH') {
    return { text: "Very High Risk", color: colors.error[600] }
  } else {
   return { text: "Unsupported", color: "unset"}
  }
}
