import * as Yup from "yup";

import { validEmail } from "shared/forms/validation/fieldValidators";

export const ValidationSchema = Yup.object().shape({
  email: validEmail.required("Email is required."),
});

export const initialValues = {
  email: "",
};
