import React, { useEffect, useState } from "react";

import { useMediaQuery } from "react-responsive";

import Icon from "shared/components/Icon";
import Label from "shared/components/Label";
import { IconLink } from "shared/components/Links";
import { BREAKPOINT_RESOLUTIONS, COLORS } from "shared/config/constants";

import { SIZES, TileProps, TileInnerProps } from "./interfaces";
import {
  Tile as StyledTile,
  Inner,
  Title,
  SmallTitle,
  TileLabel,
  CollapseButton,
  Header,
  HeaderIcon,
  HeaderAction,
  HeaderActions,
  HeaderTitleContainer,
  HeaderSmallTitle,
  HeaderLabel,
  ChildrenContainer,
  Divider,
  DownloadButton,
  StyledTag,
  StyledTagIcon,
} from "./styled";

type TileType = React.FC<TileProps> & {
  Inner: React.ComponentType<TileInnerProps>;
  SmallTitle: React.ElementType;
  Label: React.ElementType;
  Divider: React.ElementType;
  SIZES: typeof SIZES;
};

const Tile: TileType = ({
  children,
  header,
  insetHeader,
  download,
  alt = false,
  sec = false,
  size = SIZES.default,
  tag = false,
  stickyTop = false,
  noBorder = false,
  noBorderTop = false,
  noBorderBottom = false,
  noBorderLeft = false,
  noBorderRight = false,
  expand = false,
  equalHeight = false,
  rounded = false,
  ...props
}) => {
  const isDesktop = useMediaQuery({ minWidth: BREAKPOINT_RESOLUTIONS.md });
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT_RESOLUTIONS.md });
  const [collapsed, setCollapsed] = useState(header?.defaultCollapsed || false);

  const toggleCollapse = (event) => {
    const invalidEls = ["A"];
    const isInvalidEl = invalidEls.indexOf(event.target.tagName) > -1;

    if (!isInvalidEl) {
      setCollapsed(!collapsed);
    }
  };
  const collapsibleOnDesktop = header ? header.collapsibleOnDesktop : undefined;
  const collapsibleOnMobile = header ? header.collapsibleOnMobile : undefined;
  const collapsible =
    header !== undefined && (collapsibleOnMobile || collapsibleOnDesktop);
  const headerOnClick = !header?.notClickable ? toggleCollapse : undefined;

  const isTileCollapsed =
    collapsed &&
    ((isDesktop && collapsibleOnDesktop) || (isMobile && collapsibleOnMobile));

  useEffect(() => {
    if (collapsible && expand) {
      setCollapsed(false);
    }
  }, [expand]);

  return (
    <StyledTile
      altStyle={alt}
      insetStyle={insetHeader}
      secStyle={sec}
      stickyTop={stickyTop}
      noBorder={noBorder}
      noBorderTop={noBorderTop}
      noBorderBottom={noBorderBottom}
      noBorderLeft={noBorderLeft}
      noBorderRight={noBorderRight}
      equalHeight={equalHeight}
      rounded={rounded}
      {...props}
    >
      {header && (
        <>
          <Header
            mobileOnly={header.mobileOnly}
            noMobileActionStyles={header.noMobileActionStyles}
            altStyle={alt}
            borderBottom={!header.withoutBorderBottom}
            collapsed={collapsible && collapsed}
            collapsibleOnDesktop={collapsibleOnDesktop}
            collapsibleOnMobile={collapsibleOnMobile}
            onClick={headerOnClick}
            accent={header?.accent}
            size={size}
            insetStyle={!alt && insetHeader}
            insetHeader={insetHeader}
            alignItems={header.alignItems}
            noPaddingLtMd={header.noPaddingLtMd}
            className="tile-header"
          >
            <HeaderTitleContainer
              className="tile-header-title-container"
              noMobileActionStyles={header.noMobileActionStyles}
              fullWidth={header.fullWidth}
              collapsibleOnMobile={collapsibleOnMobile}
            >
              {header.icon && <HeaderIcon>{header.icon}</HeaderIcon>}
              {header.title && (
                <div>
                  <Title altStyle={alt}>{header.title}</Title>
                  <span>{header.subtitle}</span>
                </div>
              )}
              {header.smallTitle && (
                <HeaderSmallTitle collapsed={collapsed}>
                  {header.smallTitle}
                </HeaderSmallTitle>
              )}
              {header.label && (
                <HeaderLabel mobile>
                  <Label type={header.label.type}>{header.label.text}</Label>
                </HeaderLabel>
              )}
              {typeof tag !== "boolean" && (
                <StyledTag
                  size="lg"
                  color={COLORS.white}
                  backgroundColor={COLORS.tag.violet.background}
                >
                  <StyledTagIcon>
                    <tag.icon />
                  </StyledTagIcon>
                  {tag.description || ""}
                </StyledTag>
              )}
              {download && (
                <DownloadButton>
                  {/* @ts-ignore */}
                  <IconLink
                    onClick={download.onClick}
                    Icon={download.icon}
                    nowrap={download.nowrap}
                    size="sm"
                    light
                    right
                    hoverableIcon={false}
                    disabled={download.disabled}
                  >
                    {download.description || ""}
                  </IconLink>
                </DownloadButton>
              )}
            </HeaderTitleContainer>
            {(header.primaryAction || header.secondaryAction) && (
              <HeaderActions
                collapsed={collapsed}
                collapsibleOnMobile={collapsibleOnMobile}
                noMobileActionStyles={header.noMobileActionStyles}
                inlineOnMobile={header.inlineOnMobile}
              >
                {header.primaryAction && (
                  <HeaderAction
                    className="tile-header-primary-action"
                    noMobileActionStyles={header.noMobileActionStyles}
                    separateActionsStyle={header.separateActionsStyle}
                    inlineOnMobile={header.inlineOnMobile}
                  >
                    {header.primaryAction}
                  </HeaderAction>
                )}
                {header.secondaryAction && (
                  <HeaderAction
                    noMobileActionStyles={header.noMobileActionStyles}
                    inlineOnMobile={header.inlineOnMobile}
                  >
                    {header.secondaryAction}
                  </HeaderAction>
                )}
              </HeaderActions>
            )}
            {header.label && (
              <HeaderLabel desktop>
                <Label type={header.label.type}>{header.label.text}</Label>
              </HeaderLabel>
            )}
            {collapsible && (
              <CollapseButton
                altStyle={alt}
                collapsed={collapsed}
                collapsibleOnDesktop={collapsibleOnDesktop}
                collapsibleOnMobile={collapsibleOnMobile}
                onClick={toggleCollapse}
              >
                <Icon.ChevronDownIcon />
              </CollapseButton>
            )}
          </Header>
        </>
      )}
      {!isTileCollapsed && (
        <ChildrenContainer
          collapsed={collapsible && collapsed}
          collapsibleOnDesktop={collapsibleOnDesktop}
          collapsibleOnMobile={collapsibleOnMobile}
          className="tile-children-container"
        >
          {children}
        </ChildrenContainer>
      )}
    </StyledTile>
  );
};

Tile.Inner = Inner;
Tile.SmallTitle = SmallTitle;
Tile.Label = TileLabel;
Tile.Divider = (props) => <Divider type="vertical" {...props} />;
Tile.SIZES = SIZES;

export default Tile;
