import styled from "@emotion/styled";
import { Button, Card } from "antd-latest";

import { MEDIA_QUERIES, COLORS } from "shared/config/constants";
import { CenteredCard } from "shared/styles/commonStyles";

export const LeadAcceptTermsPageCard = styled(Card)`
  ${CenteredCard}
  width: 410px;
  padding: 40px;
  margin: 0px auto;
  ${MEDIA_QUERIES.w.ltSm} {
    width: 85%;
    padding: 20px;
  }
`;

export const LeadAcceptTermsVettPcodePageCard = styled(Card)`
  ${CenteredCard}
  max-width: 900px;
  padding: 24px;
  margin: 0px auto;
  .wrapper {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .form-column {
    max-width: 410px;
    min-width: 300px;
  }
  .title-center {
    text-align: center;
    color: ${COLORS.darkViolet3};
  }
  .divider {
    opacity: 0;
  }
  ${MEDIA_QUERIES.w.ltMd} {
    width: 85%;
    min-width: none;
    padding: 0;
    .wrapper {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
    }
    .form-column {
      width: 100%;
      max-width: 100%;
    }
  }
  ${MEDIA_QUERIES.w.ltSm} {
    top: 0;
    transform: none;
    overflow-y: scroll;
    width: 100%;
  }
`;

export const SelectYourPreferenceContainer = styled.div`
  padding: 24px;
  min-width: 375px;
  max-width: 450px;
  background: #2d2943;
  background: radial-gradient(rgb(75 72 92) 5%, #2d2943 50%, #2d2943 100%);
  box-shadow: 0px 4px 6px rgba(120, 115, 149, 0.25);
  border-radius: 4px;
  .pink-text {
    color: ${COLORS.incomeVerification.title} !important;
  }
  .info-text {
    color: ${COLORS.lightGray2};
  }
  ${MEDIA_QUERIES.w.ltMd} {
    min-width: 100%;
  }
`;

export const SubmitButton = styled(Button)`
  width: 100%;
  &.submit-button-vett-p-code {
    background: ${COLORS.white};
    color: ${COLORS.textColorPrimary};
    display: flex;
    align-items: center;
    width: max-content;
    .icon {
      margin-left: 10px;
    }
  }
`;
