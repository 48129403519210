import React, { useContext } from "react";

import { format } from "date-fns";
import _ from "lodash";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import "./IncomeBucketChart.css";

import { useGetIncomesByBuckets } from "renter/hooks/api";
import { COLORS } from "shared/config/constants";
import { ChartLoading, EmptyChart } from "shared/images";
import { printDollars } from "shared/utils/dollar-print";

import ApplicationContext from "../../applicationContext";

const chartHeight = 300;
const chartWidth = "100%";

export const IncomeBucketChart = () => {
  const {
    application: { personVrn },
  } = useContext(ApplicationContext);
  const { buckets, isFetching, isLoading } = useGetIncomesByBuckets(
    {
      person: personVrn,
      windoEnd: format(new Date(), "yyyy-MM-dd"),
      bucketDuration: "P1M",
    },
    !_.isEmpty(personVrn)
  );

  const data = buckets?.map((bucket) => ({
    name: format(new Date(bucket.endDate), "MMM ‘yy"),
    income: _.round(
      _.sumBy(bucket.groups, (group) => group.sumAmount) / 100,
      2
    ),
  }));

  const isChartLoading = isFetching || isLoading;
  const emptyData = _.isEmpty(buckets);

  return (
    <>
      {isChartLoading && (
        <ChartLoading height={chartHeight} width={chartWidth} />
      )}
      {!isChartLoading && emptyData && (
        <EmptyChart height={chartHeight} width={chartWidth} />
      )}
      {!isChartLoading && !emptyData && (
        <ResponsiveContainer height={chartHeight} width={chartWidth}>
          <LineChart data={data} margin={{ right: 30, left: 20 }}>
            <CartesianGrid stroke={COLORS.borders} strokeDasharray="3 3" />
            <XAxis
              axisLine={{ stroke: COLORS.borders }}
              dataKey="name"
              dy={15}
              height={40}
              tick={{ fill: COLORS.primary }}
              tickLine={false}
            />
            <YAxis
              axisLine={false}
              tick={{ fill: COLORS.primary }}
              tickFormatter={(value) =>
                printDollars(value, { withCents: false })
              }
              tickLine={false}
              type="number"
            />
            <Tooltip
              formatter={(value, name) => [
                printDollars(Number(value.toString()), { withCents: true }),
                _.capitalize(name.toString()),
              ]}
            />
            <Legend
              formatter={(value) => _.capitalize(value)}
              height={35}
              verticalAlign="top"
            />
            <Line
              type="monotone"
              name="monthly income"
              dataKey="income"
              stroke={COLORS.accent}
            />
          </LineChart>
        </ResponsiveContainer>
      )}
    </>
  );
};
