import React from "react";

import { RegularText } from "shared/components/Typography";

import { VerticalDividerContent } from "./styled";

export interface VerticalDividerProps {
  text?: string;
}

const VerticalDivider = ({ text }: VerticalDividerProps) => {
  return (
    <VerticalDividerContent text={text}>
      <RegularText weak className="divider-text">
        {text}
      </RegularText>
    </VerticalDividerContent>
  );
};

export default VerticalDivider;
