import styled from "@emotion/styled";

import { MEDIA_QUERIES } from "shared/config/constants";
import { getResolution } from "shared/utils/ui";

interface StyledContainerProps {
  noPaddingTop?: boolean;
  noPaddingBottom?: boolean;
  noPaddingRight?: boolean;
  noPaddingLeft?: boolean;
  backgroundColor?: string;
  noPadding?: boolean;
  stickToBottom?: boolean;
  centered?: boolean;
  noMobilePaddingX?: boolean;
  noMobilePaddingY?: boolean;
  maxWidth?: number;
  expand?: boolean;
}

const Container = styled("div")<StyledContainerProps>`
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
  min-height: -webkit-fit-content;
  padding-top: ${(props) => props.noPaddingTop && "0 !important"};
  padding-bottom: ${(props) => props.noPaddingBottom && "0 !important"};
  padding-right: ${(props) => props.noPaddingRight && "0 !important"};
  padding-left: ${(props) => props.noPaddingLeft && "0 !important"};
  flex-grow: ${(props) => props.expand && "1"};
  background-color: ${(props) =>
    props.backgroundColor && props.backgroundColor};
  padding: ${(props) => props.noPadding && "0 !important"};
  position: ${(props) => props.stickToBottom && "sticky"};
  bottom: ${(props) => props.stickToBottom && "0"};
  z-index: ${(props) => props.stickToBottom && "100"};
  max-width: ${(props) =>
    props.maxWidth && `${getResolution(props.maxWidth)}px`};
  align-items: ${(props) => props.centered && "center"};
  ${MEDIA_QUERIES.w.ltMd} {
    padding: ${(props) =>
      props.noMobilePaddingX ? "24px 0 24px 0" : "24px 20px"};
    padding-top: ${(props) => props.noMobilePaddingY && "0 !important"};
    padding-bottom: ${(props) => props.noMobilePaddingY && "0 !important"};
  }
`;

export { Container };
