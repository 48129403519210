import styled from "@emotion/styled";
import { Form } from "formik";

import Icon from "shared/components/Icon";
import { MEDIA_QUERIES } from "shared/config/constants";

const StyledEmailTemplatesSettings = styled.div`
  margin-bottom: 48px;

  .tile-header-title-container {
    width: 100%;
    flex-wrap: nowrap;
  }
`;

const StyledCompanyEmailTemplatesForm = styled(Form)`
  #customEmailConfigurationId {
    width: 100%;
  }

  .custom-email-row {
    margin-bottom: 5px;

    .ant-radio-wrapper {
      padding-bottom: 0;

      .ant-radio {
        padding-top: 3px;
      }
    }

    .edit-icon {
      margin-left: auto;
      margin-right: 10px;
    }

    .delete-icon {
      svg {
        width: 30px;
      }
    }
  }

  .reset-link {
    margin-right: 24px;
  }

  .send-test-button {
    margin-right: 24px;
  }

  .submit-button {
    margin-right: 30px;

    ${MEDIA_QUERIES.w.ltSm} {
      margin-right: 70px;
    }
  }

  .no-templates {
    padding: 100px 0;
    text-align: center;

    ${MEDIA_QUERIES.w.ltSm} {
      padding: 60px 0;
    }
  }
`;

const StyledPropertyEmailForm = styled(Form)`
  .reset-link {
    margin-right: 24px;
  }

  .submit-button {
    margin-right: 30px;

    ${MEDIA_QUERIES.w.ltSm} {
      margin-right: 70px;
    }
  }

  .email-configuration-row {
    align-items: center;
    margin-bottom: 10px;

    .email-configuration-col {
      display: flex;
      align-items: center;

      svg {
        margin-left: 10px;
      }
    }
  }
`;

const StyledLoader = styled(Icon.LoadingSpinnerGreenIcon)`
  margin: 40px auto;
`;

export {
  StyledEmailTemplatesSettings,
  StyledCompanyEmailTemplatesForm,
  StyledPropertyEmailForm,
  StyledLoader,
};
