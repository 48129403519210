import React from "react";

import { useHistory } from "react-router-dom";

import Icon from "shared/components/Icon";
import InfoPage from "shared/templates/InfoPage";

const SetPasswordLinkAcceptedPage = () => {
  const history = useHistory();

  return (
    <InfoPage
      description="The invitation is already accepted"
      button={{
        children: "Sign In",
        onClick: () => {
          window.location.href = `${window.location.origin}/auth/login`
          return null
        },
        SuffixIcon: Icon.ButtonForwardArrowIcon,
      }}
      MainIcon={Icon.CheckGreenXl}
    />
  );
};

export default SetPasswordLinkAcceptedPage;
