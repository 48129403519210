import styled from "@emotion/styled";

const LoadingContainer = styled.div((props: { absoluteCenter: boolean }) => ({
  ...(!props.absoluteCenter && {
    display: "flex",
    justifyContent: "center",
    padding: "32px 0",
  }),
  ...(props.absoluteCenter && {
    position: "absolute",
    left: "50%",
    bottom: "50%",
    transform: "translateX(-50%)",
  }),
}));

export { LoadingContainer };
