import React, { useState } from "react";

import { useMagicLinksExportAgents } from "manager/hooks/api";
import { Modal } from "shared/components/Modal";

import useExportOptionsStep from "../hooks/useExportOptionsStep";
import useSelectAgentsStep from "../hooks/useSelectAgentsStep";

const STEPS = Object.freeze({
  FIRST: 1,
  SECOND: 2,
});

const ExportAgentsModal = () => {
  const [currentStep, setStep] = useState(STEPS.FIRST);
  const { exportAgents } = useMagicLinksExportAgents();

  const onOptionsStepSubmit = () => {
    const nextStep = currentStep + 1;
    setStep(nextStep);
  };

  const optionsStep = useExportOptionsStep({
    title: "",
    exportTitle: "What would you like to export?",
    onSubmit: onOptionsStepSubmit,
    enabled: currentStep === STEPS.FIRST,
  });

  const onSelectAgentsSubmit = (selectedAgents) => {
    const employees = selectedAgents.includes("all") ? [] : [...selectedAgents];
    return exportAgents({ outputs: optionsStep.response, employees });
  };

  const selectAgentsStep = useSelectAgentsStep({
    onBackClick: () => {
      const prevStep = currentStep - 1;
      setStep(prevStep);
    },
    exportOptions: optionsStep.response,
    onSubmit: onSelectAgentsSubmit,
    enabled: currentStep === STEPS.SECOND,
  });

  let step = {
    modalProps: {},
    modalBody: null,
  };

  if (currentStep === STEPS.FIRST) {
    step = optionsStep;
  } else if (currentStep === STEPS.SECOND) {
    step = selectAgentsStep;
  }

  return <Modal {...step.modalProps}>{step.modalBody}</Modal>;
};

export default ExportAgentsModal;
