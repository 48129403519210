import React from "react";

import { Popover } from "shared/components/Popover";
import { IntegrationProvider } from "shared/config/constants";

import { IntegrationStatusDropdown } from "./IntegrationStatusDropdown";
import { Container } from "./styled";
import SyncStatusPopover from "./SyncStatusPopover";
import { useIntegrationSyncStatus } from "./use-integration-sync-status";

export interface IIntegrationSyncStatus {
  provider: IntegrationProvider;
}

const IntegrationSyncStatus = ({ provider }: IIntegrationSyncStatus) => {
  const {
    isSuspended,
    lastSync,
    healthCheckStatus,
    healthCheckMessage,
    onRefresh,
    isRefreshing,
    onViewDetails,
    onFinishSetup,
    beginIntegrationSetup,
  } = useIntegrationSyncStatus({ provider });

  return (
    <Popover
      defaultVisible
      placement="bottomRight"
      trigger="hover"
      align={{
        offset: [-10, 10],
      }}
      content={
        <SyncStatusPopover
          isSuspended={isSuspended}
          lastSync={lastSync}
          healthCheckStatus={healthCheckStatus}
          healthCheckMessage={healthCheckMessage}
          onRefresh={onRefresh}
          isRefreshing={isRefreshing}
          onViewDetails={onViewDetails}
          onFinishSetup={onFinishSetup}
          beginIntegrationSetup={beginIntegrationSetup}
          provider={provider}
        />
      }
    >
      <Container alignItems="center" fullHeight={false}>
        <IntegrationStatusDropdown
          status={healthCheckStatus}
          provider={provider}
        />
      </Container>
    </Popover>
  );
};

export default IntegrationSyncStatus;
