import React, { useEffect, useState } from "react";

import { Formik } from "formik";

import {
  useGetBluemoonProperties,
  useGetVeroPropertiesQuery,
  useGetStates,
} from "manager/hooks/api";
import { BluemoonPropertiesRequest } from "manager/interfaces/api/bluemoon";
import Icon from "shared/components/Icon";
import { DEFAULT_PAGINATION, NOTIFICATIONS } from "shared/config/constants";
import { useDeviceType } from "shared/hooks";

import { openNotification } from "shared/utils/ui";

import { PROPERTY_TYPE } from "./BluemoonPropertyBindings";
import PropertySelectionControls from "./PropertySelectionControls";
import {
  PropertySelectionContainer,
  StepTitle,
  PropertySelectionNote,
  PropertySearchBar,
  FormFieldContainer,
  ScrollableArea,
  PropertyList,
  Property,
  ScrollableControlsContainer,
  ClickableLightGreenLink,
  StateSelectFieldStyled,
} from "./styled";

const PropertySelectionContainers = ({
  propertyType,
  stepText,
  noteText,
  propertiesSelectedCallback,
  selectedBluemoonProperty,
}) => {
  const blankFilter: BluemoonPropertiesRequest = {
    status: propertyType === PROPERTY_TYPE.BLUEMOON ? null : "unbounded",
    searchValue: "",
    state: "",
    ...DEFAULT_PAGINATION,
  };

  const { isMobile } = useDeviceType();
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [searchParams, setSearchParams] = useState(blankFilter);
  const { states: apiStates } = useGetStates();

  let properties = [];
  let refetchProperties = null;
  let total = null;
  if (propertyType === PROPERTY_TYPE.BLUEMOON) {
    const data = useGetBluemoonProperties(searchParams);
    if (!data.isBluemoonPropertiesLoading)
      properties = data.bluemoonPropertiesData.results;
    refetchProperties = data.refetchBluemoonProperties;
  } else {
    const { veroProperties, isVeroPropertiesLoading, refetchVeroProperties } =
      useGetVeroPropertiesQuery(searchParams);
    if (!isVeroPropertiesLoading) properties = veroProperties.results;
    total = veroProperties?.count;
    refetchProperties = refetchVeroProperties;
  }

  const updatePropertiesWithState = (value, label, setFieldValue) => {
    setSearchParams({ ...searchParams, state: value });
    setFieldValue("state", label);
  };

  const updatePropertiesWithQuery = (e, setFieldValue) => {
    setSearchParams({ ...searchParams, searchValue: e.target.value });
    setFieldValue("query", e.target.value);
  };

  const selectProperty = (propertyId) => {
    if (selectedProperties.includes(propertyId)) {
      setSelectedProperties(
        selectedProperties.filter((id) => id !== propertyId)
      );
    } else {
      if (
        propertyType === PROPERTY_TYPE.BLUEMOON &&
        selectedProperties.length === 1
      ) {
        openNotification(
          "You may only select one Bluemoon Property to bind at a time, Please unselect and select another property.",
          NOTIFICATIONS.info
        );
        return;
      }
      setSelectedProperties(selectedProperties.concat(propertyId));
    }
  };

  const selectAll = () => {
    setSelectedProperties(properties.map((property) => property.id));
  };

  const clear = () => {
    setSelectedProperties([]);
  };

  const refreshProperties = () => {
    if (propertyType === PROPERTY_TYPE.BLUEMOON) setSelectedProperties([]);
    refetchProperties();
  };

  useEffect(() => {
    if (propertyType === PROPERTY_TYPE.VERO) {
      propertiesSelectedCallback(selectedProperties);
    } else if (properties) {
      propertiesSelectedCallback(
        properties.filter(
          (property) => selectedProperties[0] === property.id
        )[0]
      );
    }
  }, [selectedProperties]);

  useEffect(() => {
    refetchProperties();
  }, [searchParams]);

  const handleLoadPropertiesOnScroll = (e) => {
    const { currentTarget } = e;
    const isScrolledToBottom =
      Math.abs(
        currentTarget.scrollHeight -
          currentTarget.clientHeight -
          currentTarget.scrollTop
      ) < 1;
    if (isScrolledToBottom) {
      setSearchParams({
        ...searchParams,
        pageSize: searchParams.pageSize + 10,
      });
    }
  };

  return (
    <PropertySelectionContainer>
      <StepTitle>{stepText}</StepTitle>
      <PropertySelectionNote>{noteText}</PropertySelectionNote>
      <Formik initialValues={{ query: "", state: "" }} onSubmit={() => {}}>
        {(formikProps) => (
          <FormFieldContainer>
            <PropertySearchBar
              name="query"
              placeholder="Search by property or address..."
              onChange={(e) => {
                updatePropertiesWithQuery(e, formikProps.setFieldValue);
              }}
              value={formikProps.values.query}
            />
            {!isMobile && (
              <StateSelectFieldStyled
                defaultPlaceholder="State"
                statesCallback={updatePropertiesWithState}
                options={apiStates}
              />
            )}
          </FormFieldContainer>
        )}
      </Formik>
      <ScrollableArea
        onScroll={total > 10 ? handleLoadPropertiesOnScroll : undefined}
      >
        {propertyType === PROPERTY_TYPE.VERO && (
          <ScrollableControlsContainer>
            <ClickableLightGreenLink onClick={selectAll}>
              Select All
            </ClickableLightGreenLink>
            <ClickableLightGreenLink onClick={clear}>
              Clear
            </ClickableLightGreenLink>
          </ScrollableControlsContainer>
        )}
        <PropertyList>
          {properties &&
            properties.map((property) => {
              const addressLine =
                propertyType === PROPERTY_TYPE.BLUEMOON
                  ? property.addressLine1
                  : property.address;
              const propertyDescription = `${property.name}, ${addressLine}, ${property.city}, ${property.state}, ${property.zipcode}`;
              return (
                <Property
                  key={property.id}
                  onClick={() => selectProperty(property.id)}
                  className={
                    selectedProperties.includes(property.id) && "selected"
                  }
                >
                  {propertyDescription}
                  {selectedProperties.includes(property.id) && (
                    <Icon.CheckGreenSmall />
                  )}
                </Property>
              );
            })}
        </PropertyList>
      </ScrollableArea>
      <PropertySelectionControls
        propertyType={propertyType}
        selectedBluemoonProperty={selectedBluemoonProperty}
        refreshPropertiesCallback={refreshProperties}
      />
    </PropertySelectionContainer>
  );
};

export default PropertySelectionContainers;
