import axios from "axios";

import ROUTES from "manager/config/apiRoutes";
import { API_URL } from "shared/lib/api/setupApi";
import { transformRoute } from "shared/utils/routing";

const api = {
  requestCountyCriminalSearch: (dealId) =>
    axios
      .post(
        transformRoute(`${API_URL}${ROUTES.requestCountyCriminalSearch}`, {
          dealId,
        })
      )
      .then(({ data }) => data),
};

export default api;
