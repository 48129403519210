import { queryCache, useMutation, useQuery } from "react-query";

import api from "renter/lib/api";
import { NOTIFICATIONS } from "shared/config/constants";
import { openNotification } from "shared/utils/ui";

const GET_EMPLOYMENTS_KEY = "getEmployments";
const GET_EMPLOYMENT_BY_ID_KEY = "getEmploymentById";

export const EMPLOYMENT_QUERY_KEYS = Object.freeze({
  getEmploymentById: (employmentId: string) => [
    GET_EMPLOYMENT_BY_ID_KEY,
    String(employmentId),
  ],
});

export const useGetEmployments = (person: string, enable: boolean) => {
  const { data, isLoading, refetch } = useQuery(
    [GET_EMPLOYMENTS_KEY, person],
    () => api.getEmployments(person),
    {
      enabled: enable,
      onError: () =>
        openNotification("Failed to get the employments", NOTIFICATIONS.error),
    }
  );

  return {
    refetchEmployments: refetch,
    employmentsData: data,
    isEmploymentsDataLoading: isLoading,
  };
};

export const useGetEmploymentById = (employmentId: string, { enabled }) => {
  const { data, isLoading } = useQuery(
    [GET_EMPLOYMENT_BY_ID_KEY, employmentId],
    () => api.getEmploymentById(employmentId),
    {
      enabled,
      onError: () =>
        openNotification("Failed to get the employments", NOTIFICATIONS.error),
    }
  );
  return {
    employmentData: data,
    isEmploymentDataLoading: isLoading,
  };
};

export const useUpdateEmploymentById = (employmentId) => {
  const [mutation, isLoading] = useMutation(
    // @ts-ignore
    (payload) => api.updateEmploymentById({ employmentId, payload }),
    {
      onError: () =>
        openNotification(
          "Failed to update the employment",
          NOTIFICATIONS.error
        ),
    }
  );

  return {
    updateEmploymentById: mutation,
    isUpdateEmploymentByIdLoading: isLoading,
  };
};

export const useDeleteEmploymentById = () => {
  const [mutation] = useMutation(api.updateEmploymentById, {
    onSuccess: () =>
      openNotification(
        "Successfully deleted the employment",
        NOTIFICATIONS.info
      ),
    onError: () =>
      openNotification("Failed to delete the employment", NOTIFICATIONS.error),
  });

  return {
    deleteEmploymentById: mutation,
  };
};

export const useCreateEmployment = () => {
  const [mutation, isLoading] = useMutation(
    (payload: any) => api.createEmployment({ payload }),
    {
      onSuccess: ({ data }) => {
        queryCache.invalidateQueries(
          EMPLOYMENT_QUERY_KEYS.getEmploymentById(data?.employmentId)
        );
      },
      onError: () =>
        openNotification(
          "Failed to create the employments",
          NOTIFICATIONS.error
        ),
    }
  );

  return {
    createEmployment: mutation,
    isLoading,
  };
};
