import React from "react";

import { useHistory } from "react-router-dom";

import ROUTES from "admin/config/routes";
import LoginPage from "shared/pages/LoginPage";
import { getQueryParams } from "shared/utils/routing";

const LoginPageContainer = () => {
  const history = useHistory();
  const urlParams = getQueryParams();

  const onSuccess = () => {
    window.location.href = `${window.location.origin}${urlParams?.r || ROUTES.documents}`;
    return null;
  }

  return <LoginPage title="Admin Sign In" onSuccess={onSuccess} />;
};

export default LoginPageContainer;
