import React from "react";

import PropTypes from "prop-types";

import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import { ActionLink } from "shared/components/Links";

const SSNViewer = ({
  visibleSSN,
  isMaskedSSNShown,
  openConfirmationModal,
  hideSSN,
  canViewFullSSN,
}) => {
  return (
    <FlexContainer alignItems="center">
      {visibleSSN}
      {canViewFullSSN && (
        <>
          &nbsp;
          {isMaskedSSNShown ? (
            <ActionLink
              onClick={openConfirmationModal}
              title="Show Social Security Number / ITIN"
            >
              <Icon.ShowPasswordActionIcon />
            </ActionLink>
          ) : (
            <ActionLink
              onClick={hideSSN}
              title="Hide Social Security Number / ITIN"
            >
              <Icon.HidePasswordActionIcon />
            </ActionLink>
          )}
        </>
      )}
    </FlexContainer>
  );
};

SSNViewer.propTypes = {
  hideSSN: PropTypes.func.isRequired,
  isMaskedSSNShown: PropTypes.bool.isRequired,
  openConfirmationModal: PropTypes.func.isRequired,
  visibleSSN: PropTypes.string.isRequired,
  canViewFullSSN: PropTypes.bool.isRequired,
};

export default SSNViewer;
