import React from "react";

import { Row } from "antd";
import { Helmet } from "react-helmet";

import { useMediaQuery } from "react-responsive";

import Icon from "shared/components/Icon";
import { BREAKPOINT_RESOLUTIONS } from "shared/config/constants";
import { UserProfile } from "shared/interfaces/api";

import { PactSafeConfig } from "shared/interfaces/api/pactSafeConfig";

import { ExperianAuthTokenParams } from "../../interfaces/api/experianAuth";

import ExperianAuthForm from "./ExperianAuthForm";

import {
  AuthContainer,
  Content,
  HeaderContainer,
  HeaderSide,
  Header,
  Title,
  AuthFooter,
  StyledLink,
  BottomLinkContainer,
} from "./styled";

interface ExperianAuthProps {
  user?: UserProfile;
  getExperianAuthToken: (
    params: ExperianAuthTokenParams,
    { setSubmitting, setErrors }
  ) => void;
  redirect: () => void;
  pactSafeConfig: PactSafeConfig;
  isPactSafeConfigLoading: boolean;
  renderDisputeModal?: () => void;
}

const ExperianAuth = ({
  user,
  redirect,
  getExperianAuthToken,
  pactSafeConfig,
  isPactSafeConfigLoading,
  renderDisputeModal,
}: ExperianAuthProps) => {
  const isDesktop = useMediaQuery({ minWidth: BREAKPOINT_RESOLUTIONS.sm });

  return (
    <>
      <Helmet>
        <title>Experian Authentication</title>
      </Helmet>
      <AuthContainer data-testid="page-content">
        <HeaderContainer>
          <HeaderSide>
            {isDesktop && (
              <StyledLink onClick={redirect} size="lg">
                Back to VERO
              </StyledLink>
            )}
          </HeaderSide>
          <Icon.LogoIcon data-testid="logo-icon" width={160} height={40} />
          <HeaderSide className="right" />
        </HeaderContainer>
        <Content>
          <Row type="flex">
            <Header>
              <Title>Landlord Authentication</Title>
              <div>
                Verify your information to securely review applicant background
                and credit reports.
              </div>
            </Header>
          </Row>
          <ExperianAuthForm
            activeUser={user}
            redirect={redirect}
            onSubmit={getExperianAuthToken}
            pactSafeConfig={pactSafeConfig}
            isPactSafeConfigLoading={isPactSafeConfigLoading}
          />
          {isDesktop ? (
            <AuthFooter>
              <div>888-695-7148</div> |
              <StyledLink
                href="https://legal.sayvero.com/#privacy-policy"
                target="_blank"
                size="lg"
              >
                Privacy Policy
              </StyledLink>{" "}
              |
              <StyledLink
                href="https://legal.sayvero.com/#terms-of-service"
                target="_blank"
                size="lg"
              >
                Terms and Conditions
              </StyledLink>{" "}
              |
              <StyledLink onClick={renderDisputeModal} size="lg">
                Dispute Report
              </StyledLink>
            </AuthFooter>
          ) : (
            <BottomLinkContainer>
              <StyledLink onClick={redirect} size="lg">
                Back to VERO
              </StyledLink>
            </BottomLinkContainer>
          )}
        </Content>
      </AuthContainer>
    </>
  );
};

export default ExperianAuth;
