import React, { Component } from "react";

import { Tabs as BaseTabs, Badge } from "antd";

import { StyledTabs, NotificationContainer } from "./styled";

const { TabPane } = BaseTabs;

export interface TabsState {
  mode: "top" | "bottom" | "left" | "right";
}
export interface TabsProps {
  tabs: {
    key: string;
    label: Node | React.ReactNode;
    notification?: boolean;
    disabled?: boolean;
    [key: string]: any;
  }[];
  onChange: (newRoute: string) => void;
  activeKey?: string;
}

class Tabs extends Component<TabsProps, TabsState> {
  constructor(props) {
    super(props);
    this.state = {
      mode: "top",
    };
  }

  handleModeChange = (e) => {
    this.setState({ mode: e.target.value });
  };

  render() {
    const { mode } = this.state;
    const { tabs, onChange, activeKey, ...props } = this.props;
    return (
      // @ts-ignore
      <StyledTabs
        defaultActiveKey={tabs[0].key}
        tabPosition={mode}
        onChange={onChange}
        activeKey={activeKey}
        {...props}
      >
        {tabs.map((tab) => (
          <TabPane
            tab={
              <>
                {tab.label}
                <NotificationContainer>
                  {tab.notification && <Badge dot />}
                </NotificationContainer>
              </>
            }
            key={tab.key}
            disabled={tab.disabled}
          />
        ))}
      </StyledTabs>
    );
  }
}

export default Tabs;
