import React from "react";

import snakeCase from "lodash/snakeCase";
import { useMediaQuery } from "react-responsive";

import Container from "shared/components/Container";
import FilterBar from "shared/components/FilterBar";
import FlexContainer from "shared/components/FlexContainer";
import { PopoverContent } from "shared/components/Form/Input/styled";
import Icon from "shared/components/Icon";
import { ActionLink, UnderlineLink } from "shared/components/Links";
import MobileFilters from "shared/components/MobileFilters";
import Table from "shared/components/Table/Table/Table";
import Tile from "shared/components/Tile";
import { BREAKPOINT_RESOLUTIONS } from "shared/config/constants";

import { PopoverContainer, YardiText } from "../styled";

import {
  FiltersContainer,
  NoTableItemsTitle,
  TableWrapper,
  TableHeader,
  PropertyStatus,
} from "./styled";
import {
  PropertiesTableProps,
  propertiesTablePropTypes,
  YARDI_PROPERTIES_STATUS,
  YARDI_PROPERTIES_STATUS_LABELS,
} from "./yardiDashboard.config";

const NoImportedYardiProperties = ({ importYardiPropertiesAction }) => (
  <Tile data-testid="no-properties-section">
    <Tile.Inner>
      <FlexContainer
        fullHeight
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Icon.NoPropertiesLinkedIcon />
        <NoTableItemsTitle>
          You haven’t imported any properties from External Provider yet
        </NoTableItemsTitle>
        <ActionLink onClick={importYardiPropertiesAction}>
          IMPORT PROPERTIES
        </ActionLink>
      </FlexContainer>
    </Tile.Inner>
  </Tile>
);

const YardiPropertiesTable = ({
  importedYardiProperties,
  importYardiPropertiesAction,
  editPropertySetup,
  deleteProperty,
  isTableLoading,
  currentPagination: { page, pageSize },
  setCurrentPagination,
  setCurrentOrdering,
  totalCount,
  filters,
  updateFilters,
}: PropertiesTableProps) => {
  const isMiddle = useMediaQuery({ maxWidth: BREAKPOINT_RESOLUTIONS.md });

  const onPaginationChange = (currentPage) => {
    const newPagination = { page: currentPage, pageSize };
    setCurrentPagination(newPagination);
  };

  const onChange = (pagination, filters, sorter) => {
    const direction = (sorter.order || "").includes("desc") ? "-" : "";
    const ordering = direction + snakeCase(sorter.field);
    setCurrentOrdering(ordering);
  };

  const tableFilters = {
    title: "Filter Properties",
    search: {
      action: (value: string) => updateFilters({ nameOrAddress: value }),
      placeholder: "Search by name or address",
      debounce: true,
      disabled: false,
      value: filters.nameOrAddress,
      width: isMiddle ? 260 : 320,
    },
    dropdownFilters: [
      {
        key: "status",
        label: "Status",
        onChange: (value: string) => updateFilters({ status: value }),
        value: filters.status,
        items: [
          {
            label: "All setup statuses",
            key: "",
          },
          ...Object.values(YARDI_PROPERTIES_STATUS).map((s) => {
            return {
              label: YARDI_PROPERTIES_STATUS_LABELS[s],
              key: s,
            };
          }),
        ],
      },
    ],
  };

  return (
    <TableWrapper data-testid="properties-table">
      <TableHeader>
        <h2>Properties within VERO</h2>
        <ActionLink onClick={importYardiPropertiesAction}>
          IMPORT PROPERTIES
        </ActionLink>
      </TableHeader>
      <FiltersContainer>
        <FilterBar {...tableFilters} justifyContent="left" />
        <MobileFilters {...tableFilters} />
      </FiltersContainer>
      <Table
        alt
        columns={[
          {
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: true,
          },
          {
            title: "PMS ID",
            dataIndex: "yardiId",
            key: "yardiId",
          },
          {
            title: "Address",
            dataIndex: "fullAddress",
            key: "fullAddress",
            sorter: true,
          },
          {
            title: "Status",
            dataIndex: "isActive",
            key: "isActive",
          },
        ]}
        rows={importedYardiProperties.map((property) => ({
          key: property.id,
          values: [
            <YardiText noPadding fontWeight={500}>
              {property.name}
            </YardiText>,
            <YardiText noPadding>{property.yardiId}</YardiText>,
            <YardiText noPadding>{property.fullAddress}</YardiText>,
            <>
              {(property.status === YARDI_PROPERTIES_STATUS.SETUP_NOT_STARTED ||
                !property.status) && (
                <ActionLink
                  data-testid="setup-property-in-table"
                  onClick={() => editPropertySetup(property)}
                >
                  SETUP PROPERTY
                </ActionLink>
              )}
              {property.status === YARDI_PROPERTIES_STATUS.IN_PROGRESS && (
                <PropertyStatus>
                  <Icon.FieldPartiallySet className="property-status-icon" />
                  Setup in progress...
                </PropertyStatus>
              )}
              {property.status === YARDI_PROPERTIES_STATUS.COMPLETED && (
                <PropertyStatus>
                  <Icon.CheckIcon className="property-status-icon" />
                  Connected
                </PropertyStatus>
              )}
            </>,
          ],
          primaryAction: (
            <FlexContainer justifyContent="flex-end">
              {property.status === YARDI_PROPERTIES_STATUS.COMPLETED && (
                <UnderlineLink
                  data-testid="edit-setup-in-table"
                  onClick={() => editPropertySetup(property)}
                >
                  Edit Setup
                </UnderlineLink>
              )}
              {property.status === YARDI_PROPERTIES_STATUS.IN_PROGRESS && (
                <UnderlineLink
                  data-testid="finish-setup-in-table"
                  onClick={() => editPropertySetup(property)}
                >
                  Finish Setup
                </UnderlineLink>
              )}
              {/* @ts-ignore */}
              <PopoverContainer
                className="popover"
                withoutOverlay
                disabled={property.status !== YARDI_PROPERTIES_STATUS.COMPLETED}
                content={
                  <PopoverContent>
                    Removing this property from VERO can cause problems with the
                    integration. If you would like to remove it please contact
                    the VERO support team.
                  </PopoverContent>
                }
              >
                <Icon.DeleteSmallNoCircleIcon
                  data-testid="delete-property"
                  className="delete-icon"
                  disabled={
                    property.status === YARDI_PROPERTIES_STATUS.COMPLETED
                  }
                  onClick={() => deleteProperty(property)}
                />
              </PopoverContainer>
            </FlexContainer>
          ),
        }))}
        columnBreakpoints={{
          xs: {
            column1: "name",
            column2: "isActive",
          },
          sm: ["name", "fullAddress", "isActive"],
        }}
        showActionColumn
        placeholder={{
          label: "Properties",
          Icon: Icon.NoPropertiesIcon,
        }}
        onRowClick={() => {}}
        loading={isTableLoading}
        pagination={{
          current: page,
          onChange: onPaginationChange,
          total: totalCount,
          defaultPageSize: pageSize,
        }}
        onChange={onChange}
      />
    </TableWrapper>
  );
};

YardiPropertiesTable.propTypes = propertiesTablePropTypes;

const YardiDashboardTable = ({
  importedYardiProperties,
  importYardiPropertiesAction,
  editPropertySetup,
  deleteProperty,
  isTableLoading,
  currentPagination,
  setCurrentPagination,
  setCurrentOrdering,
  totalCount,
  isFilterActive,
  filters,
  updateFilters,
}) => {
  const hasImportedYardiProperties = !!(
    importedYardiProperties && importedYardiProperties.length
  );
  return (
    <Container expand noPaddingTop>
      {!hasImportedYardiProperties && !isTableLoading && !isFilterActive && (
        <NoImportedYardiProperties
          importYardiPropertiesAction={importYardiPropertiesAction}
        />
      )}
      {(hasImportedYardiProperties || isTableLoading || isFilterActive) && (
        <YardiPropertiesTable
          importedYardiProperties={importedYardiProperties}
          importYardiPropertiesAction={importYardiPropertiesAction}
          editPropertySetup={editPropertySetup}
          deleteProperty={deleteProperty}
          isTableLoading={isTableLoading}
          currentPagination={currentPagination}
          setCurrentPagination={setCurrentPagination}
          setCurrentOrdering={setCurrentOrdering}
          totalCount={totalCount}
          filters={filters}
          updateFilters={updateFilters}
        />
      )}
    </Container>
  );
};

export default YardiDashboardTable;
