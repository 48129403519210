import styled from "@emotion/styled";

import BaseFooterBar from "shared/components/FooterBar";

const FooterBar = styled(BaseFooterBar)`
  justify-content: space-between;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
`;

export { FooterBar, Item };
