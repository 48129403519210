import React from 'react'
import styled from '@emotion/styled'
import { colors } from 'shared/config/constants'
import { buildClassName } from 'shared/utils/vega'
import Payment from './Payment'

const PaymentContainer = styled(({ className, payment }) => {
  return (
    <div className={buildClassName([className, 'vega', payment?.status])}>
      <div className='payment-inner-container'>
        <Payment payment={payment} />
      </div>
    </div>
  )
})({
  display: 'block',
  borderLeftStyle: 'solid',
  borderLeftWidth: '.5rem',
  paddingBottom: '1rem',
  paddingLeft: '1rem',
  '&:last-child': {
    paddingBottom: 0
  },
  '&.created': {
    borderLeftColor: colors.neutral.base
  },
  '&.unpaid, &.charged': {
    borderLeftColor: colors.secondary.base
  },
  '&.paid': {
    borderLeftColor: colors.success.base
  },
  '&.canceled': {
    borderLeftColor: colors.warning.base
  },
  '&.disputed, &.refunded, &.partially-refunded': {
    borderLeftColor: colors.error.base
  },
  '.payment-inner-container': {
    display: 'flex',
    gap: '2rem',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    borderBottomColor: colors.neutral.base,
    paddingBottom: '1rem'
  },
  '&:last-child .payment-inner-container': {
    borderBottomStyle: 'none',
    paddingBottom: 0
  }
})

export default PaymentContainer
