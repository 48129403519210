import React from "react";

import PropTypes from "prop-types";

import FlexContainer from "shared/components/FlexContainer";
import { SmallTitle, MediumTitle } from "shared/components/Typography";
import { conditionalItem } from "shared/utils/array";

import { StyledMarkdownTips, GreenTrianglesImage } from "./styled";

const getContent = ({ hasCta }) => [
  {
    title: "Template Name",
    description:
      "To avoid confusing templates, use dates or specific details to differentiate multiple templates of the same type.",
  },
  {
    title: "Email Subject",
    description:
      "Create a short eye-catching subject line that will be easily recognizable.",
  },
  {
    title: "Email Title",
    description:
      "Start with a sentence to highlight the core details of your message. Let your personality shine and grab your customer's attention.",
  },
  {
    title: "Email Message",
    description:
      "The body of the email should contain a greeting and a signature.",
  },
  ...conditionalItem(hasCta, {
    title: "Call to Action",
    description:
      "The button label should clarify the action that will occur when a user clicks on it.",
  }),
  {
    title: "Attachments",
    description: "Add any useful documents that may help your applicant.",
  },
];

const MarkdownTips = ({ hasCta }) => (
  <StyledMarkdownTips>
    <div className="header">
      <MediumTitle strong className="header-title">
        Email Tips
      </MediumTitle>
      <GreenTrianglesImage className="header-image" />
    </div>
    <FlexContainer
      className="content"
      flexDirection="column"
      justifyContent="space-between"
      flexGrow="1"
    >
      {getContent({ hasCta }).map(({ title, description }) => (
        <div key={title} className="tip">
          <SmallTitle className="tip-title" strong>
            {title}
          </SmallTitle>
          <SmallTitle className="tip-content" strong>
            {description}
          </SmallTitle>
        </div>
      ))}
    </FlexContainer>
  </StyledMarkdownTips>
);

MarkdownTips.propTypes = {
  hasCta: PropTypes.bool,
};

MarkdownTips.defaultProps = {
  hasCta: false,
};

export default MarkdownTips;
