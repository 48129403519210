import React from "react";

import SourcesCreateEditModal from "./SourcesCreateEditModal";

interface SourcesCreateEditModalContainerProps {
  title: string;
  propertyName: string;
  inputDescription: string;
  inputPlaceholder: string;
  confirmLabel: string;
  saveAction: (value: string) => void;
  source: {
    name: string;
  };
  usedNames: string[];
}

const SourcesCreateEditModalContainer = ({
  title,
  propertyName,
  inputDescription,
  inputPlaceholder,
  confirmLabel,
  saveAction,
  source,
  usedNames,
}: SourcesCreateEditModalContainerProps) => {
  const onSubmit = ({ value }, { setSubmitting }) => {
    try {
      saveAction(value);
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <SourcesCreateEditModal
      propertyName={propertyName}
      title={title}
      inputDescription={inputDescription}
      inputPlaceholder={inputPlaceholder}
      confirmLabel={confirmLabel}
      onSubmit={onSubmit}
      name={source?.name}
      usedNames={usedNames}
    />
  );
};

export default SourcesCreateEditModalContainer;
