import React from "react";

import PropTypes from "prop-types";

import { linkedSourcesPropTypes } from "./sources.config";
import Sources from "./SourcesContainer";

const MarketingSources = ({ propertyName, linkedData }) => {
  return (
    <Sources
      title="Marketing Sources"
      subTitle="Applicants will select a marketing source during the VERO application process. Please add VERO marketing sources that you would like to make available to applicants."
      propertyName={propertyName}
      createTitle="Create New Marketing Source"
      editTitle="Edit Marketing Source"
      createEditInputDescription="Enter Marketing Source Name"
      createEditInputPlaceholder="Source Name"
      deleteTitle="Remove Marketing Source?"
      deleteDescription={`Once you remove marketing source from ${propertyName} it won't appear as the dropdown option for the applicant during the application process anymore.`}
      sourceType="Marketing Source"
      linkedData={linkedData}
    />
  );
};

MarketingSources.propTypes = {
  propertyName: PropTypes.string,
  linkedData: linkedSourcesPropTypes.isRequired,
};

MarketingSources.defaultProps = {
  propertyName: undefined,
};

export default MarketingSources;
