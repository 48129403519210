import React from "react";

import { Divider, Radio } from "antd";

import { SmallText } from "../Typography";

import { RadioGroupBoxedListWrapper, Error } from "./styled";

export interface RadioGroupBoxedListProps {
  listTitle?: string;
  listItems: {
    id?: string | number;
    title?: string;
    subTitle?: string;
    [key: string]: any;
  }[];
  currentSelection?: string | number;
  onChangeSelection: (value: any) => void;
  error?: string;
  orPosition?: any;
  type?: string;
}

const RadioGroupBoxedList = ({
  listTitle = "",
  listItems,
  currentSelection = undefined,
  onChangeSelection,
  error,
  orPosition,
}: RadioGroupBoxedListProps) => {
  const isActiveItem = (item) => item.id === currentSelection;

  return (
    <>
      <RadioGroupBoxedListWrapper
        className="radio-group-boxed-list"
        hasTitle={listTitle}
      >
        {listTitle && <div className="list-title">{listTitle}</div>}
        <Radio.Group
          onChange={(e) => onChangeSelection(e.target.value)}
          value={currentSelection}
        >
          {(listItems || []).map((item, index) => (
            <>
              {orPosition === index && (
                <Divider>
                  <SmallText weak>OR</SmallText>
                </Divider>
              )}
              <div
                key={item.id}
                className={`list-item ${
                  isActiveItem(item) ? "active-item" : ""
                }`}
              >
                <Radio value={item.id} data-testid={`option-${item.id}`}>
                  <div className="list-item-title">{item.title}</div>
                  {item.subTitle && (
                    <div className="list-item-subtitle">{item.subTitle}</div>
                  )}
                </Radio>
              </div>
            </>
          ))}
        </Radio.Group>
      </RadioGroupBoxedListWrapper>
      {error && <Error>{error}</Error>}
    </>
  );
};

export default RadioGroupBoxedList;
