import styled from "@emotion/styled";

import { FormGroups } from "shared/styles/commonStyles";

interface YardiReasonFormGroupProps {
  marginTop: boolean;
}

const YardiReasonFormGroup = styled("div")<YardiReasonFormGroupProps>`
  ${{ ...FormGroups }},
  margin-top: ${(props) => props.marginTop && "32px"}
`;

export { YardiReasonFormGroup };
