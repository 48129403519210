import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { useParams, useHistory } from "react-router-dom";

import { PropertyAdditionalSettings } from "manager/components/AdditionalSettings";
import AdditionalQuestions from "manager/components/DealSetupSettings/AdditionalQuestions";
import ApplicationFees from "manager/components/DealSetupSettings/ApplicationFees";
import PaymentAccount from "manager/components/DealSetupSettings/PaymentAccount";
import { LeaseRentOptionsContainer } from "manager/components/DealSetupSettings/LeaseRentOptions";
import RequiredAttachments from "manager/components/DealSetupSettings/RequiredAttachments";
import RequiredAttachmentsBackgroundSetup from "manager/components/DealSetupSettings/RequiredAttachmentsBackgroundSetup";
import RequiredAttachmentsUnverifiedIncome from "manager/components/DealSetupSettings/RequiredAttachmentsUnverifiedIncome";
import ScreeningForApplicantsAndOccupants from "manager/components/DealSetupSettings/ScreeningForApplicantsAndOccupants";
import ScreeningForGuarantors from "manager/components/DealSetupSettings/ScreeningForGuarantors";
import PropertyEmailTemplatesSettings from "manager/components/EmailTemplates/PropertyEmailTemplatesSettings";
import { PropertyHoldingDepositSettings } from "manager/components/HoldingDeposit";
import { PropertyIntegrationMark } from "manager/components/IntegrationMark";
import SettingsTabs from "manager/components/SettingsTabs";
import ROUTES, { PROPERTY_SETTINGS_TABS } from "manager/config/routes";
import SettingsPage from "manager/templates/SettingsPage";
import { getIntegrationType } from "manager/utils/integration.util";
import { propertySettingsPropTypes } from "manager/utils/propTypes";
import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import PageTitle from "shared/components/PageTitle";
import Skeleton from "shared/components/Skeleton";
import Tooltip from "shared/components/Tooltip";
import { RegularText } from "shared/components/Typography";
import { ApplicantWorkflowStudio } from "shared/components/vega/ApplicantWorkflow";
import { LumenStudio } from "shared/components/vega/LumenStudio";
import { INTEGRATION_TYPES } from "shared/config/constants";
import { usePermissions } from "shared/hooks";
import { conditionalItem } from "shared/utils/array";
import { transformRoute } from "shared/utils/routing";

import DealSetupForm from "./DealSetupForm";
import PropertyDetails from "./PropertyDetails";
import PropertyLogo from "./PropertyLogo";
import PropertyPrescreeningSettings from "./PropertyPrescreeningSettings";

import PropertySettingsFooter from "./PropertySettingsFooter";
import { IconContainer, PropertyIntegrationMarkContainer } from "./styled";
import UnitsTable from "./UnitsTable";

const PropertySettings = ({
  propertyId,
  backLink,
  propertySettings,
  isPropertySettingsLoading,
  copyDealSetupFromPortfolio,
  resetPropertyToPortfolioSettings,
  paymentAccountMappings,
  createPaymentAccountMapping,
}) => {
  const { tabName } = useParams();
  const { push } = useHistory();
  const {
    company,
    isEmailCustomizationEnabled,
    canCustomizeEmails,
    enableHoldingDeposit,
    isPrescreeningEnabled,
    isYardiLROEnabled,
  } = usePermissions();

  const isDealSetupFromPortfolio = propertySettings?.isDealSetupFromPortfolio;
  const portfolioIsDefault = propertySettings?.portfolioIsDefault;
  const portfolioName = portfolioIsDefault
    ? "Company Settings"
    : propertySettings?.portfolioName;

  const propertyName = propertySettings?.name;
  const showPrescreeningSetupTab = isPrescreeningEnabled;
  const isPropertyConnectedToYardi = propertySettings?.yardiConnected;
  const showYardiLROTab = isYardiLROEnabled && isPropertyConnectedToYardi;

  const onTabChange = (key) =>
    push(
      transformRoute(ROUTES.propertySetupTab, { tabName: key, id: propertyId })
    );
  const integrationTypeEntrata =
    getIntegrationType(propertySettings) === INTEGRATION_TYPES.ENTRATA;

  const PropertyTitle = (
    <Skeleton title={false} loading={isPropertySettingsLoading} noMarginBottom>
      <PageTitle.Container>
        <PageTitle>
          <FlexContainer alignItems="center">
            <PropertyLogo propertySettings={propertySettings} />
            <Tooltip theme='light' title={propertySettings?.fqlVrn}>{propertyName}</Tooltip>
            <PropertyIntegrationMarkContainer
              opacity={integrationTypeEntrata ? 0.75 : 1}
            >
              <PropertyIntegrationMark property={propertySettings} lg />
            </PropertyIntegrationMarkContainer>
          </FlexContainer>
        </PageTitle>
        <PropertyDetails id={propertyId} propertySettings={propertySettings} />
      </PageTitle.Container>
    </Skeleton>
  );

  const PropertySubtitle = (
    <Skeleton title={false} loading={isPropertySettingsLoading} noMarginBottom>
      {isDealSetupFromPortfolio ? (
        <RegularText>
          {portfolioName} {!portfolioIsDefault && "settings "}are active
        </RegularText>
      ) : (
        <FlexContainer alignItems="center">
          <div>
            <RegularText>Custom settings are applied</RegularText>&nbsp;
            <RegularText light>
              (The property refers to {portfolioName})
            </RegularText>
          </div>
          <Tooltip
            title={`Next time you make settings changes for ${portfolioName}
                portfolio - the property won’t inherit them.`}
          >
            <IconContainer alignItems="center">
              <Icon.HoverTracker Icon={Icon.InfoIcon} />
            </IconContainer>
          </Tooltip>
        </FlexContainer>
      )}
    </Skeleton>
  );

  const DealSetupFooter = (
    <PropertySettingsFooter
      propertyId={propertyId}
      propertyName={propertyName}
      portfolioName={portfolioName}
      isPortfolioSettings={isDealSetupFromPortfolio}
      copyPortfolioSettings={copyDealSetupFromPortfolio}
      resetPropertySettings={resetPropertyToPortfolioSettings}
    />
  );

  return (
    <SettingsPage
      title={propertyName}
      titleNode={PropertyTitle}
      subtitle={PropertySubtitle}
      backLink={backLink}
    >
      <SettingsTabs
        activeTab={tabName}
        onTabChange={onTabChange}
        items={[
          {
            key: PROPERTY_SETTINGS_TABS.units,
            label: "Units",
            node: (
              <UnitsTable
                propertyId={propertyId}
                propertySettings={propertySettings}
              />
            ),
          },
          {
            key: PROPERTY_SETTINGS_TABS.screeningSetup,
            label: "Screening Setup",
            node: (
              <DealSetupForm
                propertySettings={propertySettings}
                isPropertySettingsLoading={isPropertySettingsLoading}
                footerContent={DealSetupFooter}
              >
                <ScreeningForApplicantsAndOccupants />
                <ScreeningForGuarantors />
              </DealSetupForm>
            ),
          },
          {
            key: PROPERTY_SETTINGS_TABS.applicationSetup,
            label: "Application Setup",
            node: (
              <DealSetupForm
                propertySettings={propertySettings}
                isPropertySettingsLoading={isPropertySettingsLoading}
                footerContent={DealSetupFooter}
              >
                <ApplicationFees />
                <PaymentAccount
                  location={_.get(propertySettings, 'location')}
                  paymentAccountMappings={paymentAccountMappings}
                  createPaymentAccountMapping={createPaymentAccountMapping}
                />
                <AdditionalQuestions />
                <RequiredAttachments />
                <RequiredAttachmentsUnverifiedIncome />
                <RequiredAttachmentsBackgroundSetup />
              </DealSetupForm>
            ),
          },
          {
            key: PROPERTY_SETTINGS_TABS.workflow,
            label: "Workflow",
            node: (
              <ApplicantWorkflowStudio
                location={propertySettings?.location}
                readOnlyLocations={[
                  `${company?.slug}#portfolio:${_.kebabCase(propertySettings?.portfolioName)}`,
                  company?.slug
                ]}
              />
            ),
          },
          {
            key: PROPERTY_SETTINGS_TABS.lumen,
            label: "Lumen",
            node: (
              <LumenStudio
                location={propertySettings?.location}
                readOnlyLocations={[
                  `${company?.slug}#portfolio:${_.kebabCase(propertySettings?.portfolioName)}`,
                  company?.slug
                ]}
                property={propertySettings?.name}
              />
            ),
          },
          {
            key: PROPERTY_SETTINGS_TABS.additionalSettings,
            label: "Additional Settings",
            node: (
              <PropertyAdditionalSettings propertySettings={propertySettings} />
            ),
          },
          ...conditionalItem(isEmailCustomizationEnabled, {
            key: PROPERTY_SETTINGS_TABS.emailTemplates,
            label: (
              <Tooltip
                title={
                  !canCustomizeEmails
                    ? "You do not have access to Email Templates; contact your account manager."
                    : ""
                }
              >
                Email Templates
              </Tooltip>
            ),
            node: <PropertyEmailTemplatesSettings />,
            disabled: !canCustomizeEmails,
          }),
          ...conditionalItem(enableHoldingDeposit, {
            key: PROPERTY_SETTINGS_TABS.applicationCharges,
            label: "Application Charges",
            node: (
              <PropertyHoldingDepositSettings
                yardiConnected={propertySettings?.yardiConnected}
              />
            ),
          }),
          ...conditionalItem(showPrescreeningSetupTab, {
            key: PROPERTY_SETTINGS_TABS.prescreening,
            label: "Prequalification",
            node: (
              <PropertyPrescreeningSettings
                propertySettings={propertySettings}
              />
            ),
          }),
          ...conditionalItem(showYardiLROTab, {
            key: PROPERTY_SETTINGS_TABS.yardiLROSetup,
            label: "Yardi Lease Rent Options",
            node: (
              <LeaseRentOptionsContainer
                dealSetupId={propertySettings?.dealSetupId}
                hasRevenueManagementExpirationEnabled={
                  propertySettings?.hasRevenueManagementExpirationEnabled
                }
              />
            ),
          }),
        ]}
      />
    </SettingsPage>
  );
};

PropertySettings.propTypes = {
  backLink: PropTypes.object.isRequired,
  propertySettings: propertySettingsPropTypes,
  isPropertySettingsLoading: PropTypes.bool.isRequired,
  propertyId: PropTypes.string,
  copyDealSetupFromPortfolio: PropTypes.func.isRequired,
  resetPropertyToPortfolioSettings: PropTypes.func.isRequired,
  paymentAccountMappings: PropTypes.array,
  createPaymentAccountMapping: PropTypes.func,
};

PropertySettings.defaultProps = {
  propertySettings: undefined,
  propertyId: undefined,
  paymentAccountMappings: [],
  createPaymentAccountMapping: () => undefined,
};

export default PropertySettings;
