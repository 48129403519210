import React from "react";

import { Form, Formik } from "formik";

import ErrorList from "shared/components/ErrorList";
import { Modal } from "shared/components/Modal";
import { BREAKPOINT_TYPES } from "shared/config/constants";
import withBreakpoint from "shared/hocs/withBreakpoint";

import {
  CreatePropertyValidationSchema,
  initialValuesCreateProperty,
} from "./createProperty.config";
import CreatePropertyInnerFormElements from "./CreatePropertyInnerFormElementsContainer";
import {
  CreatePropertyModalProps,
  FormikValuePropertyProps,
} from "./interfaces";

const CreatePropertyModal = ({
  breakpoint,
  submit,
  initialValues = initialValuesCreateProperty,
  title = "Create New Property",
  subtitle = "Please fill all the fields below.",
  submitButtonText = "Create Property",
  formikRef,
}: CreatePropertyModalProps) => (
  <Formik
    validationSchema={CreatePropertyValidationSchema}
    initialValues={initialValues}
    isInitialValid={CreatePropertyValidationSchema.isValidSync(initialValues)}
    onSubmit={submit}
    innerRef={formikRef}
  >
    {({ errors, submitForm, isSubmitting }: FormikValuePropertyProps) => (
      <Form>
        <Modal
          title={title}
          subtitle={subtitle}
          submitButtonLabel={submitButtonText}
          // @ts-ignore
          useCancelLink
          submit={submitForm}
          submitting={isSubmitting}
          closeOnSubmit={false}
          fullScreen={BREAKPOINT_TYPES.xs === breakpoint}
        >
          {errors.nonFieldErrors && (
            <Modal.Body noPaddingBottom>
              <ErrorList errors={errors.nonFieldErrors} />
            </Modal.Body>
          )}
          <Modal.Body noPaddingTop={!!errors.nonFieldErrors}>
            <CreatePropertyInnerFormElements />
          </Modal.Body>
        </Modal>
      </Form>
    )}
  </Formik>
);

export default withBreakpoint(CreatePropertyModal);
