import React from "react";

import { Dayjs } from "dayjs";

import {
  GridResults,
  ListHeader,
  ListResults,
  NoResults,
  SpacerSmall,
} from "./blocks";
import { ListType, Sort, Unit } from "./types";
import { useSearchResults } from "./use-search-results";
import { DayKey } from "./utils";

export type LeaseRentOptionsResultsProps = {
  units: Unit[];
  unitsByDay: Map<DayKey, Unit[]>;
  toggleListType: () => void;
  listType: ListType;
  sort: Sort;
  setSort: (sort: Sort) => void;
  start: Dayjs;
  setEnd: (end: Dayjs) => void;
  unit: Unit;
  setUnit: (u: Unit) => void;
  flexibleStart: boolean;
  loading: boolean;
  hideToggle?: boolean;
};

export const SearchResults: React.FC<LeaseRentOptionsResultsProps> = ({
  units,
  unitsByDay,
  listType,
  toggleListType,
  sort: sortValue,
  setSort: setSortValue,
  start,
  setEnd,
  unit,
  setUnit,
  flexibleStart,
  loading,
  hideToggle,
}) => {
  if (!start) return <></>;
  const {
    sort,
    setSortByKey,
    sorts,
    grid,
    isDisablePrev,
    next,
    prev,
    range,
    list,
    bestListUnit,
    bestGridUnit,
  } = useSearchResults(
    sortValue,
    setSortValue,
    start,
    units,
    unitsByDay,
    setEnd,
    flexibleStart
  );

  return (
    <>
      <SpacerSmall />
      <ListHeader
        results={list.length}
        onClick={toggleListType}
        type={listType}
        hideToggle={hideToggle}
      />
      <SpacerSmall />

      {listType === ListType.list && (
        <ListResults
          {...{
            list,
            sort,
            sorts,
            setSortByKey,
            unit,
            setUnit,
            best: bestListUnit,
            loading,
          }}
        />
      )}
      {listType === ListType.calendar && (
        <GridResults
          {...{
            grid,
            isDisablePrev,
            next,
            prev,
            range,
            unit,
            setUnit,
            best: bestGridUnit,
            loading,
          }}
        />
      )}

      {!list.length && !loading && <NoResults />}
    </>
  );
};
