import styled from "@emotion/styled";

import Icon from "shared/components/Icon";
import List from "shared/components/List";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";
import { BACKGROUND_MEDIA_QUERIES } from "shared/styles/backgroundMediaQueries";
import { CenteredFlexPartialCss } from "shared/styles/commonStyles";

const LoadingSpinner = styled(Icon.LoadingSpinnerGreenIcon)`
  display: block;
  margin: 0 auto;
`;

const Container = styled.section`
  background-color: ${COLORS.lightGray2};
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
`;

const ContentContainer = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .logo-container {
    margin-top: 20px;
    margin-bottom: 30px;
    display: flex;
    width: 100%;
    flex-flow: row-reverse;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .logo {
    max-height: 55;
    max-width: 55;
    ${MEDIA_QUERIES.w.ltMd} {
      max-height: 100px;
      max-width: 100px;
      margin-bottom: 20px;
    }
  }

  .logo-alt {
    color: ${COLORS.gray2};
    font-weight: 500;
    margin-bottom: 0;
    margin-top: 0;
    font-size: 12px;
    flex-grow: 1;
  }

  .modal {
    flex-grow: 1;
  }
  .title {
    margin-bottom: 8px !important;
    font-size: 17px !important;
    font-weight: 500;
  }
  .powered-by {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    .logo {
      margin-top: 5px;
    }
  }
  .submit-btn {
    width: 100%;
  }
  .footer {
    display: none;
    font-weight: 600;
    .emoji {
      margin-bottom: -8px;
      margin-left: 5px;
    }
  }

  ${BACKGROUND_MEDIA_QUERIES.w.gteMd} {
    .modal {
      width: 424px;
    }
    .logo-container {
      margin-top: 60px;
      margin-bottom: 24px;
      flex-direction: column;
      align-items: center;
      .logo {
        margin-bottom: 20px;
        max-height: 100px;
        max-width: 100px;
      }
      .logo-alt {
        font-size: 17px;
      }
    }
    .submit-btn {
      width: auto;
    }
    .powered-by {
      ${CenteredFlexPartialCss};

      margin-top: 60px;
      .logo {
        margin-left: 5px;
        margin-top: 0;
      }
    }
  }
  ${BACKGROUND_MEDIA_QUERIES.w.gteLg} {
    .modal {
      width: 590px;
      padding: 43px 40px 28px 40px;
      background: ${COLORS.white};
      border: 1px solid ${COLORS.borders};
      box-sizing: border-box;
      box-shadow: 0px 4px 20px ${COLORS.boxShadowLight};
      border-radius: 2px;
    }
    .title {
      font-size: 22px !important;
    }

    .logo-container {
      margin-top: 40px;
      margin-bottom: 40px;
    }
    .powered-by {
      margin-bottom: 80px;
    }
  }
  ${BACKGROUND_MEDIA_QUERIES.w.gteXl} {
    .modal {
      width: 671px;
      padding: 68px 80px 28px 80px;
      &.propertyModal {
        padding-top: 45px;
      }
    }
  }
  ${BACKGROUND_MEDIA_QUERIES.w.gteXxl} {
    .footer {
      display: block;
    }
  }
`;

const Modal = styled.div`
  background: none;
  border: none;
  position: relative;
  width: 272px;
  padding: 0;
`;

const Header = styled.div<{ center?: boolean }>`
  marginbottom: "28px",
    ${(props) =>
      !!props.center &&
      `
  text-align: center;
  `};
`;

const Subtitle = styled.h4`
  font-weight: 400;
  .ant-steps .ant-steps-item-title {
    font-weight: 400;
  }
  .strong-title {
    font-weight: 500;
  }
`;

const NameContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 32px;
  & > div {
    width: 50%;
  }
  .name-divider {
    margin: 0 10px;
  }
  ${MEDIA_QUERIES.w.ltMd} {
    flex-direction: column;
    & > div {
      width: 100%;
    }
    .name-divider {
      margin: 0 0 32px 0;
    }
  }
`;

const FinalInstructionIcon = styled(Icon.CheckIcon)`
  width: 20px;
  height: 20px;
  margin-left: -4px;
  margin-top: -3px;
`;

const VeroLogo = styled(Icon.LogoIcon)`
  height: 10px;
  width: 51px;
`;

const ListItem = styled(List.Item)`
  padding: 15px 20px;
`;

const Text = styled.p`
  padding-right: 5px;
`;

export {
  LoadingSpinner,
  Container,
  ContentContainer,
  Modal,
  Header,
  Subtitle,
  NameContainer,
  FinalInstructionIcon,
  VeroLogo,
  ListItem,
  Text,
};
