import styled from "@emotion/styled";

const LinkEmployeeContent = styled("div")({
  width: "100%",
  flexDirection: "column",
  paddingTop: "24px",
  paddingBottom: "40px",
  ".link-description": {
    marginBottom: "24px",
    fontWeight: 300,
  },
});

const PropertyText = styled("span")({
  fontWeight: 400,
});

export { PropertyText, LinkEmployeeContent };
