import React from "react";

import { useHistory } from "react-router-dom";

import { InviteLeadToApplyModal } from "manager/components/Prescreening";
import ROUTES from "manager/config/routes";
import { fullApplicationPropType } from "manager/utils/propTypes";
import { usePermissions } from "shared/hooks";
import useModal from "shared/hooks/useModal";
import { transformRoute } from "shared/utils/routing";
import { useGetApplicantWorkflowRun } from 'shared/hooks/api'
import LeadSummary from "./LeadSummary";

const LeadSummaryContainer = ({ lead }) => {
  const history = useHistory();
  const { openModalDialog } = useModal();
  const { canInviteLeadsToApply } = usePermissions();
  const { applicantWorkflow } = useGetApplicantWorkflowRun(lead?.applicantVrn);

  const { applicationSummary, property, unit, deal, screeningDealId } =
    lead || {};

  const inviteLeadToApply = () =>
    openModalDialog(InviteLeadToApplyModal, {
      lead: {
        dealId: deal,
        firstName: applicationSummary?.firstName,
        lastName: applicationSummary?.lastName,
        email: applicationSummary?.email,
        propertyId: property?.id,
        unitId: unit?.id,
      },
    });

  const redirectToDeal = () =>
    history.push(transformRoute(ROUTES.deal, { id: screeningDealId }));

  return (
    <LeadSummary
      lead={lead}
      applicantWorkflow={applicantWorkflow}
      inviteLeadToApply={inviteLeadToApply}
      canInviteLeadsToApply={canInviteLeadsToApply}
      redirectToDeal={redirectToDeal}
    />
  );
};

LeadSummaryContainer.propTypes = {
  lead: fullApplicationPropType,
};

LeadSummaryContainer.defaultProps = {
  lead: undefined,
};

export default LeadSummaryContainer;
