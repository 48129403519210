const SURVEY_BASE = "/vero-survey/:surveyHashId/";

const SURVEY_API_ROUTES = {
  survey: `${SURVEY_BASE}start/`,
  verify: `${SURVEY_BASE}verify/`,
  nextSurveyQuestion: `${SURVEY_BASE}next/`,
  prevSurveyQuestion: `${SURVEY_BASE}previous/`,
};

export default SURVEY_API_ROUTES;
