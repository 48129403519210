import Icon from "shared/components/Icon";

const LUMEN_FORMULA_STATUS = Object.freeze({
  DRAFT: "DRAFT",
  COMPLETED: "COMPLETED",
});

const LUMEN_SECTION_TYPE = Object.freeze({
  CREDIT: "CREDIT",
  VERIFICATIONS: "VERIFICATIONS",
  INCOME: "INCOME",
  ASSETS: "ASSETS",
  COLLECTIONS: "COLLECTIONS",
  CRIMINAL_RECORDS: "CRIMINAL_RECORDS",
  HOUSING_COURT_RECORDS: "HOUSING_COURT_RECORDS",
});

const LUMEN_VALIDATION_STATUS_FIELD_FOR_SECTION = Object.freeze({
  [LUMEN_SECTION_TYPE.CREDIT]: "credit",
  [LUMEN_SECTION_TYPE.VERIFICATIONS]: "verifications",
  [LUMEN_SECTION_TYPE.INCOME]: "income",
  [LUMEN_SECTION_TYPE.ASSETS]: "assets",
  [LUMEN_SECTION_TYPE.COLLECTIONS]: "collectionRecords",
  [LUMEN_SECTION_TYPE.CRIMINAL_RECORDS]: "criminalRecords",
  [LUMEN_SECTION_TYPE.HOUSING_COURT_RECORDS]: "housingCourtRecords",
});

const NEW_LUMEN_FILTERS = Object.freeze({
  COLLECTIONS: "COLLECTIONS",
});

const LUMEN_SECTION_VALIDATION_STATUS = Object.freeze({
  INCOMPLETE: "INCOMPLETE",
  UNUSED: "UNUSED",
  COMPLETE: "COMPLETE",
});

const LUMEN_CREDIT_SCORE_WITH_GUARANTORS_MODE = Object.freeze({
  ONLY_GUARANTOR: "ONLY_GUARANTOR",
  APPLICANT_AND_GUARANTOR: "APPLICANT_AND_GUARANTOR",
});

const LUMEN_CREDIT_RECORDS_SCOPE = Object.freeze({
  PUBLIC_AND_BANKRUPTCIES: "PUBLIC_AND_BANKRUPTCIES",
  ONLY_PUBLIC: "ONLY_PUBLIC",
  ONLY_BANKRUPTCIES: "ONLY_BANKRUPTCIES",
  NONE: "NONE",
});

const LUMEN_INCOME_RENT_RATIO_PERIOD = Object.freeze({
  ANNUAL: "ANNUAL",
  MONTHLY: "MONTHLY",
});

const LUMEN_INCOME_RENT_RATIO_WITH_GUARANTORS_MODE = Object.freeze({
  NO_CHANGE: "NO_CHANGE",
  DIFFERENT_RATIO: "DIFFERENT_RATIO",
});

const LUMEN_CASH_RUNWAY_RATIO_WITH_GUARANTORS_MODE = Object.freeze({
  NO_CHANGE: "NO_CHANGE",
  DIFFERENT_RATIO: "DIFFERENT_RATIO",
});

const LUMEN_TOTAL_INCOME_OR_ASSETS_WITH_GUARANTORS_MODE = Object.freeze({
  COMBINE_APPLICANTS_GUARANTORS: "COMBINE_APPLICANTS_GUARANTORS",
  ONLY_GUARANTOR: "ONLY_GUARANTOR",
});

const LUMEN_FILTER_MODE = Object.freeze({
  CRUCIAL: "CRUCIAL",
  IGNORED: "IGNORED",
});

const LUMEN_FILTER_TYPE = Object.freeze({
  PUBLIC_RECORDS: "PUBLIC_RECORDS",
  BANKRUPTCIES: "BANKRUPTCIES",
  OFAC: "OFAC",
  SEXUAL_OFFENDER_REGISTRY: "SEXUAL_OFFENDER_REGISTRY",
  MOST_WANTED: "MOST_WANTED",
  CRIMINAL: "CRIMINAL",
  HOUSING_COURT_RECORD: "HOUSING_COURT_RECORD",
});

const LUMEN_FILTER_TYPES_WITH_BETWEEN_TIME_CONDITION = [
  LUMEN_FILTER_TYPE.PUBLIC_RECORDS,
  LUMEN_FILTER_TYPE.BANKRUPTCIES,
  LUMEN_FILTER_TYPE.HOUSING_COURT_RECORD,
];

const LUMEN_FILTER_LABELS = Object.freeze({
  [LUMEN_FILTER_TYPE.PUBLIC_RECORDS]: "Public Records",
  [LUMEN_FILTER_TYPE.BANKRUPTCIES]: "Bankruptcies",
  [LUMEN_FILTER_TYPE.OFAC]: "OFAC",
  [LUMEN_FILTER_TYPE.SEXUAL_OFFENDER_REGISTRY]: "Sexual offender registry",
  [LUMEN_FILTER_TYPE.MOST_WANTED]: "Most wanted",
  [LUMEN_FILTER_TYPE.CRIMINAL]: "Criminal",
  [LUMEN_FILTER_TYPE.HOUSING_COURT_RECORD]: "Eviction Related Proceedings",
});

const LUMEN_FILTER_TYPES_PER_SECTION = Object.freeze({
  [LUMEN_SECTION_TYPE.CREDIT]: [
    LUMEN_FILTER_TYPE.PUBLIC_RECORDS,
    LUMEN_FILTER_TYPE.BANKRUPTCIES,
  ],
  [LUMEN_SECTION_TYPE.INCOME]: [],
  [LUMEN_SECTION_TYPE.ASSETS]: [],
  [LUMEN_SECTION_TYPE.COLLECTIONS]: [],
  [LUMEN_SECTION_TYPE.CRIMINAL_RECORDS]: [
    LUMEN_FILTER_TYPE.OFAC,
    LUMEN_FILTER_TYPE.SEXUAL_OFFENDER_REGISTRY,
    LUMEN_FILTER_TYPE.MOST_WANTED,
    LUMEN_FILTER_TYPE.CRIMINAL,
  ],
  [LUMEN_SECTION_TYPE.HOUSING_COURT_RECORDS]: [
    LUMEN_FILTER_TYPE.HOUSING_COURT_RECORD,
  ],
});

const LUMEN_FILTER_CONDITION_TYPE = Object.freeze({
  TIME_PERIOD: "TIME_PERIOD",
  AMOUNT: "AMOUNT",
  PUBLIC_RECORD_TYPE: "PUBLIC_RECORD_TYPE",
  KEYWORDS: "KEYWORDS",
  CRIMINAL_RECORD_SEVERITY: "CRIMINAL_RECORD_SEVERITY",
  NUMBER_OF_RECORDS: "NUMBER_OF_RECORDS",
  JUDGEMENT_AMOUNT: "JUDGEMENT_AMOUNT",
});

const LUMEN_FILTER_CONDITION_SUB_TYPE = Object.freeze({
  EVICTIONS: "EVICTIONS",
});

const LUMEN_FILTER_CONDITION_AMOUNT_TIME_OPERATOR_TYPE = Object.freeze({
  LESS_THAN: "LESS_THAN",
  MORE_THAN: "MORE_THAN",
  BETWEEN: "BETWEEN",
});

const LUMEN_FILTER_CONDITION_TIME_UNIT_TYPE = Object.freeze({
  YEARS: "YEARS",
  MONTHS: "MONTHS",
  DAYS: "DAYS",
});

const LUMEN_SECTION_TYPE_LABELS = Object.freeze({
  [LUMEN_SECTION_TYPE.CREDIT]: "CREDIT PROFILE",
  [LUMEN_SECTION_TYPE.VERIFICATIONS]: "VERIFICATIONS",
  [LUMEN_SECTION_TYPE.INCOME]: "INCOME",
  [LUMEN_SECTION_TYPE.ASSETS]: "ASSETS",
  [LUMEN_SECTION_TYPE.COLLECTIONS]: "COLLECTIONS",
  [LUMEN_SECTION_TYPE.CRIMINAL_RECORDS]: "CRIMINAL RECORDS",
  [LUMEN_SECTION_TYPE.HOUSING_COURT_RECORDS]: "EVICTION RELATED PROCEEDINGS",
});

const LUMEN_FILTER_CONDITION_TYPE_LABELS = Object.freeze({
  [LUMEN_FILTER_CONDITION_TYPE.TIME_PERIOD]: "TIME PERIOD",
  [LUMEN_FILTER_CONDITION_TYPE.AMOUNT]: "$ AMOUNT",
  [LUMEN_FILTER_CONDITION_TYPE.PUBLIC_RECORD_TYPE]: "SUBTYPE",
  [LUMEN_FILTER_CONDITION_TYPE.KEYWORDS]: "KEYWORDS",
  [LUMEN_FILTER_CONDITION_TYPE.CRIMINAL_RECORD_SEVERITY]: "SEVERITY",
  [LUMEN_FILTER_CONDITION_TYPE.NUMBER_OF_RECORDS]: "NUMBER OF RECORDS",
  [LUMEN_FILTER_CONDITION_TYPE.JUDGEMENT_AMOUNT]: "JUDGEMENT AMOUNT",
});

const LUMEN_FILTER_CONDITION_AMOUNT_TIME_OPERATOR_LABELS = Object.freeze({
  [LUMEN_FILTER_CONDITION_AMOUNT_TIME_OPERATOR_TYPE.LESS_THAN]: "Less",
  [LUMEN_FILTER_CONDITION_AMOUNT_TIME_OPERATOR_TYPE.MORE_THAN]: "More",
  [LUMEN_FILTER_CONDITION_AMOUNT_TIME_OPERATOR_TYPE.BETWEEN]: "Between",
});

const LUMEN_FILTER_CONDITION_TIME_UNIT_LABELS = Object.freeze({
  [LUMEN_FILTER_CONDITION_TIME_UNIT_TYPE.YEARS]: "Years",
  [LUMEN_FILTER_CONDITION_TIME_UNIT_TYPE.MONTHS]: "Months",
  [LUMEN_FILTER_CONDITION_TIME_UNIT_TYPE.DAYS]: "Days",
});

const LUMEN_CONDITIONS_PER_FILTER_TYPE = Object.freeze({
  [LUMEN_FILTER_TYPE.PUBLIC_RECORDS]: [
    LUMEN_FILTER_CONDITION_TYPE.PUBLIC_RECORD_TYPE,
    LUMEN_FILTER_CONDITION_TYPE.TIME_PERIOD,
    LUMEN_FILTER_CONDITION_TYPE.AMOUNT,
  ],
  [LUMEN_FILTER_TYPE.BANKRUPTCIES]: [
    LUMEN_FILTER_CONDITION_TYPE.PUBLIC_RECORD_TYPE,
    LUMEN_FILTER_CONDITION_TYPE.TIME_PERIOD,
  ],
  [LUMEN_FILTER_TYPE.OFAC]: [LUMEN_FILTER_CONDITION_TYPE.TIME_PERIOD],
  [LUMEN_FILTER_TYPE.SEXUAL_OFFENDER_REGISTRY]: [
    LUMEN_FILTER_CONDITION_TYPE.TIME_PERIOD,
  ],
  [LUMEN_FILTER_TYPE.MOST_WANTED]: [LUMEN_FILTER_CONDITION_TYPE.TIME_PERIOD],
  [LUMEN_FILTER_TYPE.CRIMINAL]: [
    LUMEN_FILTER_CONDITION_TYPE.TIME_PERIOD,
    LUMEN_FILTER_CONDITION_TYPE.CRIMINAL_RECORD_SEVERITY,
    LUMEN_FILTER_CONDITION_TYPE.KEYWORDS,
  ],
  [LUMEN_FILTER_TYPE.HOUSING_COURT_RECORD]: [
    LUMEN_FILTER_CONDITION_TYPE.KEYWORDS,
    LUMEN_FILTER_CONDITION_TYPE.NUMBER_OF_RECORDS,
    LUMEN_FILTER_CONDITION_TYPE.TIME_PERIOD,
    LUMEN_FILTER_CONDITION_TYPE.JUDGEMENT_AMOUNT,
  ],
});

export enum LumenResultScore {
  SO_MUCH_EMPTY = "SO_MUCH_EMPTY",
  STILL_CALCULATING = "STILL_CALCULATING",
  NOT_APPLICABLE = "N/A",
  EXCELLENT = "EXCELLENT",
  GOOD = "GOOD",
  NEUTRAL = "NEUTRAL",
  NEGATIVE = "NEGATIVE",
}

const LUMEN_SUMMARY_MESSAGE_TYPE = Object.freeze({
  HIGHLIGHT: "HIGHLIGHT",
  LOWLIGHT: "LOWLIGHT",
});

const LUMEN_FORMULA_ASSIGNMENT_LEVEL = Object.freeze({
  PORTFOLIO: "AssignmentLevel.Portfolio",
  PROPERTY: "AssignmentLevel.Property",
});

const LUMEN_SMALL_ICONS_BY_SCORE = Object.freeze({
  [LumenResultScore.EXCELLENT]: Icon.BulbGreenSmall,
  [LumenResultScore.GOOD]: Icon.BulbPurpleSmall,
  [LumenResultScore.NEUTRAL]: Icon.BulbOrangeSmall,
  [LumenResultScore.NEGATIVE]: Icon.BulbRedSmall,
});

const LUMEN_LARGE_ICONS_BY_SCORE = Object.freeze({
  [LumenResultScore.EXCELLENT]: Icon.BulbGreenBig,
  [LumenResultScore.GOOD]: Icon.BulbPurpleBig,
  [LumenResultScore.NEUTRAL]: Icon.BulbOrangeBig,
  [LumenResultScore.NEGATIVE]: Icon.BulbRedBig,
});

const LUMEN_FORMULA_MAX_VALUES = Object.freeze({
  RATIO: {
    maxValue: 1000,
    message: "The ratio should be less than 1000x",
  },
  SCORE: {
    maxValue: 100000,
    message: "The score should be less then 100,000",
  },
  TIME: {
    // NOTE: 10800 is approximately the number of days for a 30 year lease (using 15000 as a higher limit)
    maxValue: 15000,
    message: "The time value should be less than 15,000",
  },
  AMOUNT: {
    // NOTE: Need to add one zero extra to account for the cents (the value is stored in cents)
    maxValue: 10000000000000,
    message: "The amount value should be less than 1,000,000,000,000",
  },
  NUM_RECORDS: {
    maxValue: 10000,
    message: "The value should be less than 10,000",
  },
});

const LUMEN_FORMULA_TYPES = Object.freeze({
  blank: "blank",
  template: "template",
});

const LUMEN_INCOME_TYPES = Object.freeze({
  VERIFIED: "VERIFIED",
  VERIFIED_AND_DOCUMENT_EXTRACTION: "VERIFIED_AND_DOCUMENT_EXTRACTION",
  STATED: "STATED",
});

export {
  LUMEN_FORMULA_STATUS,
  LUMEN_CREDIT_SCORE_WITH_GUARANTORS_MODE,
  LUMEN_CREDIT_RECORDS_SCOPE,
  LUMEN_INCOME_RENT_RATIO_PERIOD,
  LUMEN_INCOME_RENT_RATIO_WITH_GUARANTORS_MODE,
  LUMEN_INCOME_TYPES,
  LUMEN_CASH_RUNWAY_RATIO_WITH_GUARANTORS_MODE,
  LUMEN_TOTAL_INCOME_OR_ASSETS_WITH_GUARANTORS_MODE,
  LUMEN_FILTER_MODE,
  LUMEN_FILTER_TYPE,
  LUMEN_FILTER_LABELS,
  LUMEN_SECTION_TYPE,
  LUMEN_SECTION_VALIDATION_STATUS,
  LUMEN_FILTER_TYPES_PER_SECTION,
  LUMEN_FILTER_CONDITION_TYPE,
  LUMEN_FILTER_CONDITION_AMOUNT_TIME_OPERATOR_TYPE,
  LUMEN_FILTER_CONDITION_TIME_UNIT_TYPE,
  LUMEN_CONDITIONS_PER_FILTER_TYPE,
  LUMEN_SECTION_TYPE_LABELS,
  LUMEN_FILTER_CONDITION_TYPE_LABELS,
  LUMEN_FILTER_CONDITION_AMOUNT_TIME_OPERATOR_LABELS,
  LUMEN_FILTER_CONDITION_TIME_UNIT_LABELS,
  LUMEN_SUMMARY_MESSAGE_TYPE,
  LUMEN_FORMULA_ASSIGNMENT_LEVEL,
  LUMEN_SMALL_ICONS_BY_SCORE,
  LUMEN_LARGE_ICONS_BY_SCORE,
  LUMEN_VALIDATION_STATUS_FIELD_FOR_SECTION,
  LUMEN_FORMULA_MAX_VALUES,
  LUMEN_FORMULA_TYPES,
  NEW_LUMEN_FILTERS,
  LUMEN_FILTER_CONDITION_SUB_TYPE,
  LUMEN_FILTER_TYPES_WITH_BETWEEN_TIME_CONDITION,
};
