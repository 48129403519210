import { useCallback, useEffect, useState } from "react";

import copyToClipboard from "copy-to-clipboard";
import { useMediaQuery } from "react-responsive";

import { useHistory } from "react-router-dom";

import { merge } from "rxjs";

import ROUTES from "manager/config/routes";
import useUnblockApplicationItem from "manager/hooks/useUnblockApplicationItem";
import { BREAKPOINT_RESOLUTIONS } from "shared/config/constants";
import { transformRoute } from "shared/utils/routing";
import { notifyInfo } from "shared/utils/ui";

import { AppRowProps } from "./app-row";
import { ApplicantInfoProps } from "./applicant-info";
import {
  useRemoveApplicantModal,
  useMoveApplicantModal,
  RemoveApplicantModalProps,
} from "./modals";
import { useEditIncomeModal } from "./modals/edit-income-modal";
import { UpdateIncomeProps } from "./types";

export const useAppRow = ({
  dealId,
  appId,
  info,
  yardi,
  refetchDeal,
}: Pick<
  AppRowProps,
  "appId" | "info" | "dealId" | "refetchDeal" | "yardi"
>) => {
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT_RESOLUTIONS.sm });
  const history = useHistory();
  const [collapse, setCollapse] = useState(false);

  useEffect(() => {
    setCollapse(isMobile);
  }, [isMobile]);

  const toggle = useCallback(
    () => isMobile && setCollapse(!collapse),
    [isMobile, collapse]
  );

  const onCopyAppLink = () => {
    copyToClipboard(info.inviteLink);
    notifyInfo("Application link copied to clipboard!");
  };

  const [modifiedIncome, setModifiedIncome] = useState<number>();

  const { show: showIncomeModal, ok$: onEditIncome$ } = useEditIncomeModal();

  const onIncomeClick = () => {
    history.push(
      transformRoute(ROUTES.applicationIncomeAssets, {
        id: appId,
      })
    );
  };
  const onCreditBackgroundClick = () => {
    history.push(
      transformRoute(ROUTES.applicationCreditBackground, {
        id: appId,
      })
    );
  };
  const onVerificationClick = () => {
    history.push(
      transformRoute(ROUTES.applicationVerifications, {
        id: appId,
      })
    );
  };
  const onVorVerificationClick = () => {
    history.push(
      transformRoute(ROUTES.applicationVorVerifications, {
        id: appId,
      })
    );
  };

  const onEmploymentClick = () => {
    history.push(
      transformRoute(ROUTES.applicationRenterProfile, {
        id: appId,
      })
    );
  };

  const { show: moveApplicant, ok$: onMovedApplicant$ } =
    useMoveApplicantModal();

  const { show: removeApplicant, ok$: onRemovedApplicant$ } =
    useRemoveApplicantModal();

  const viewAttachments = (appId: string | number) => {
    history.push(
      transformRoute(ROUTES.applicationAttachments, {
        id: appId,
      })
    );
  };
  const viewApplication = (appId: string | number) => {
    history.push(
      transformRoute(ROUTES.application, {
        id: appId,
      })
    );
  };
  const updateIncome = ({
    applicantId,
    income,
  }: UpdateIncomeProps): Promise<void> => {
    console.log(`income: ${income} applicantId: ${applicantId}`);
    return Promise.resolve();
  };

  const infoProps: ApplicantInfoProps = {
    ...info,
    onDelete: () => removeApplicant(),
    onViewAttachments: () => viewAttachments(appId),
    onMove: () => moveApplicant(),
    onViewApp: () => viewApplication(appId),
  };

  const unblock = useUnblockApplicationItem(
    {
      id: +info.applicationId,
      firstName: info.name,
      lastName: "",
      isSubmitted: info.isSubmitted,
      blockDealSubmitIfIncomeDataIsMissing: info.isBlocked,
      canUpdateBlockDealSubmitIfIncomeDataIsMissing: {
        value: info.permission.unblock,
      },
    },
    refetchDeal
  );

  onEditIncome$.subscribe((update) => {
    setModifiedIncome(Number(update.income));
    updateIncome(update).catch(() => setModifiedIncome(null));
  });
  merge(onMovedApplicant$, onRemovedApplicant$).subscribe(() => {
    refetchDeal();
  });

  const modalProps: RemoveApplicantModalProps = {
    application: {
      firstName: info.name,
      lastName: "",
      id: appId,
      isSubmitted: info.isSubmitted,
    },
    dealId,
    yardi,
  };

  return {
    infoProps,
    onIncomeClick,
    onCreditBackgroundClick,
    onVerificationClick,
    onVorVerificationClick,
    onEmploymentClick,
    modifiedIncome,
    showIncomeModal,
    toggle,
    collapse,
    onCopyAppLink,
    isMobile,
    unblock,
    modalProps,
  };
};
