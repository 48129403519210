import React from "react";

import { ErrorTriangleIcon, OrangeExclamation } from "shared/icons";
import { ModalManager } from "shared/utils/modalmanager";

import AnimatedPendingActionModal from "./AnimatedPendingActionModalContainer";

export const DEFAULT_TIMEOUT_ANIMATED_MSGS_MILLIS = 3000;

export const getDefaultAnimatedPendingActionModalContext = (
  isLoading: boolean,
  messages: string[],
  timeout: number,
  afterCloseOperation?: () => void,
  element?: React.ReactNode
) => ({
  isLoading,
  messages,
  timeout,
  afterCloseOperation,
  finalMessageElement: element,
});

/**
 * Opens the animated action modal with some default configuration.
 *
 * @param messages the messages to use
 * @param timeout the timeout to use (there's a default of 3s)
 * @returns {{modalApi: object, finishAnimation: function}}
 */
export const openDefaultAnimatedActionModal = (
  messages: string[],
  timeout = DEFAULT_TIMEOUT_ANIMATED_MSGS_MILLIS
) => {
  const context = getDefaultAnimatedPendingActionModalContext(
    true,
    messages,
    timeout
  );
  const modalApi = ModalManager.openModal(AnimatedPendingActionModal, context);
  const finishAnimation = (
    afterCloseOperation?: () => void,
    message?: string,
    isWarning: boolean = false,
    isError: boolean = false
  ) => {
    if (message) {
      messages[messages.length - 1] = message;
    }
    const element = isWarning
      ? () => <OrangeExclamation width={50} height={50} />
      : isError
      ? () => <ErrorTriangleIcon width={50} height={50} />
      : undefined;

    const newContext = getDefaultAnimatedPendingActionModalContext(
      false,
      messages,
      timeout,
      afterCloseOperation,
      element
    );
    modalApi.updateContext(newContext);
  };

  return {
    modalApi,
    finishAnimation,
  };
};
