import React, { useEffect, useState } from "react";

import MenuOption from "./MenuOption";
import { Container } from "./styled";

interface MenuProps {
  onChange?: (key: string) => void;
  items: {
    key: string | number;
    label: Element | string | any;
    rightSide?: React.ReactNode;
  }[];
  defaultActiveKey?: string | number;
  activeKey?: string | number;
  isLink?: boolean;
}

const Menu = ({
  activeKey: baseActiveKey,
  items,
  onChange = undefined,
  defaultActiveKey = undefined,
  isLink = undefined,
  ...props
}: MenuProps) => {
  const [activeKey, setActiveKey] = useState(
    defaultActiveKey || baseActiveKey || items[0]?.key
  );

  const isActive = ({ key }) => key === Number(activeKey);
  const itemClick =
    ({ key }) =>
    () => {
      setActiveKey(key);

      if (onChange) {
        onChange(key);
      }
    };

  useEffect(() => {
    if (baseActiveKey) {
      setActiveKey(baseActiveKey);
    }
  }, [baseActiveKey]);

  return (
    <Container {...props}>
      {items.map((item) =>
        isLink ? (
          <a href={`#${item.key}`} key={item.key}>
            <MenuOption
              item={item}
              isActive={isActive}
              itemClick={itemClick}
              baseActiveKey={baseActiveKey}
            />
          </a>
        ) : (
          <MenuOption
            item={item}
            key={item.key}
            isActive={isActive}
            itemClick={itemClick}
            baseActiveKey={baseActiveKey}
          />
        )
      )}
    </Container>
  );
};

export default Menu;
