import React from "react";

import { Row, Col } from "antd";

import { Input, Select, VeroFormField } from "shared/components/Form";

import { GeneralFieldDataProps } from "./interfaces";

const GeneralFieldData = ({
  isTypesLoading,
  isCategoriesLoading,
  types = [],
  categories = [],
  onChangeType,
  onChangeCategory,
}: GeneralFieldDataProps) => (
  <div data-testid="general-field-data">
    <Row gutter={[0, 32]}>
      <Col>
        <VeroFormField
          as={Input}
          id="name"
          name="name"
          label="Enter Field Name"
          description="The name will be shown to the leasing manager during the lease creation"
        />
      </Col>
    </Row>

    <Row gutter={[0, 32]}>
      <Col>
        <VeroFormField
          as={Select}
          name="type"
          id="type"
          label="Select Field Type"
          onChange={onChangeType}
          loading={isTypesLoading}
        >
          {types.map((type) => (
            <Select.Option key={type.id}>{type.value}</Select.Option>
          ))}
        </VeroFormField>
      </Col>
    </Row>

    <Row gutter={[0, 32]}>
      <Col>
        <VeroFormField
          name="category"
          id="category"
          label="Category"
          as={Select}
          onChange={onChangeCategory}
          loading={isCategoriesLoading}
        >
          {categories.map((category) => (
            <Select.Option key={category.id}>{category.value}</Select.Option>
          ))}
        </VeroFormField>
      </Col>
    </Row>
  </div>
);

export default GeneralFieldData;
