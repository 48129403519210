import { useEffect, useState } from "react";

export const useMessagesPlayer = ({
  isLoading,
  messages,
  timeout,
  finalAction,
}) => {
  const [index, setIndex] = useState(0);
  const lastIndex = messages.length - 1;

  const updateIndex = (currentIndex) => {
    return currentIndex === lastIndex - 1 ? 0 : currentIndex + 1;
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isLoading) {
      const interval = setInterval(() => {
        setIndex(updateIndex);
      }, timeout);
      return () => clearInterval(interval);
    }

    setIndex(lastIndex);
    setTimeout(finalAction, timeout);
  }, [isLoading]);

  return {
    message: messages[index],
  };
};
