import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { Switch, Redirect } from "react-router-dom";

import ROUTES, { APPLICATION_LEASE_SETUP_TABS } from "manager/config/routes";
import {
  useGetPaymentAccountMappings,
  usePaymentAccountMapping
} from "manager/hooks/api";
import { usePermissions } from "shared/hooks";
import { ConditionalRoute, transformRoute } from "shared/utils/routing";

import ApplicationSetup from './ApplicationSetup'

const ApplicationSetupRouter = ({ backLink }) => {
  const { isEmailCustomizationEnabled, permissionsLoaded } = usePermissions();
  const companySlug = _.get(usePermissions(), 'company.slug', '');
  const location = `vrn:company::${companySlug}`;

  const { paymentAccountMappings } = useGetPaymentAccountMappings({ location }, !_.isEmpty(companySlug));
  const { createPaymentAccountMapping } = usePaymentAccountMapping();

  return (
    <Switch>
      <Redirect
        exact
        from={ROUTES.applicationsSetup}
        to={transformRoute(ROUTES.applicationsSetupTab, {
          tabName: APPLICATION_LEASE_SETUP_TABS.screeningSetup,
        })}
      />
      <ConditionalRoute
        path={ROUTES.applicationsSetupTab}
        isEnabled={({
          match: {
            params: { tabName },
          },
        }) =>
          Object.values(APPLICATION_LEASE_SETUP_TABS)
            .filter(
              (route) =>
                isEmailCustomizationEnabled ||
                route !== APPLICATION_LEASE_SETUP_TABS.emailTemplates
            )
            .includes(tabName)
        }
        redirectTo={ROUTES.notFound}
        isInitialized={permissionsLoaded}
      >
        <ApplicationSetup
          backLink={backLink}
          location={location}
          paymentAccountMappings={paymentAccountMappings}
          createPaymentAccountMapping={createPaymentAccountMapping}
        />
      </ConditionalRoute>
    </Switch>
  );
};

ApplicationSetupRouter.propTypes = {
  backLink: PropTypes.object.isRequired,
};

export default ApplicationSetupRouter
