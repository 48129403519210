import { useQuery } from "react-query";

import api from "renter/lib/api";
import { NOTIFICATIONS } from "shared/config/constants";
import { openNotification } from "shared/utils/ui";

const GET_PERSON_KEY = "getPerson";

export const useGetPerson = (applicationId) => {
  const { data, isLoading, refetch } = useQuery(
    [GET_PERSON_KEY, String(applicationId)],
    () => api.getPerson(),
    {
      onError: () =>
        openNotification("Failed to get the person data", NOTIFICATIONS.error),
    }
  );

  return {
    refetchPerson: refetch,
    personData: data,
    isPersonDataLoading: isLoading,
  };
};
