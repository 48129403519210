import React from "react";

import Icon from "shared/components/Icon";

import { DrawerHeaderProps } from "../../interfaces";

import { Menu, MenuItem, IconWrapper } from "./styled";

const DrawerHeader = ({
  active,
  openProfile,
  InlineAction,
}: DrawerHeaderProps) => {
  return (
    <Menu mode="horizontal">
      {InlineAction}
      <MenuItem>
        <IconWrapper onClick={openProfile} data-testid="profile-icon">
          {/* @ts-ignore */}
          <Icon.ProfileIconWithChevron active={active} />
        </IconWrapper>
      </MenuItem>
    </Menu>
  );
};

export default DrawerHeader;
