import { CHECKBOX_WITH_UNORDERED_LIST_CHILD_TYPE } from "shared/components/Form/CheckboxWithUnorderedList";

export const getAttachmentsFilter =
  (attachmentType) => (values, applicantType) =>
    values.requiredAttachments
      ? values.requiredAttachments
          ?.map((attachment, index) => ({
            id: attachment.id,
            label: attachment.name,
            checked: values?.requiredAttachments[index]?.isActive,
            applicantType: attachment.applicantType,
            isDefault: attachment.isDefault,
            attachmentType: attachment.attachmentType,
          }))
          ?.filter(
            (item) =>
              item.applicantType === applicantType &&
              item.attachmentType === attachmentType
          )
          ?.sort((item1, item2) => item1.id - item2.id)
      : [];

const getAssetsVerificationCommon = (
  isArgyleEnabled,
  values,
  fieldName,
  disabled,
  label,
  id,
  childLabel,
  childExtraInfo
) => {
  const children = [];
  if (isArgyleEnabled) {
    children.push({
      id: fieldName,
      label: childLabel,
      checked: values[fieldName],
      extraInfo: childExtraInfo,
    });
  }

  return {
    label,
    id,
    disabled,
    children,
  };
};

export const getAssetsVerificationItem = (
  isArgyleEnabled,
  values,
  fieldName,
  disabled
) => {
  const label = isArgyleEnabled ? "Balances Verification" : "Balances";
  const id = isArgyleEnabled ? "balances-verification" : "balances";
  const childLabel = "Perform Balances Verification";
  const childExtraInfo = `If enabled and the user links a current company automatically,
      VERO will attempt to collect and confirm the balances from the
      future renter if they choose to connect their bank account during
      the application flow.`;
  return getAssetsVerificationCommon(
    isArgyleEnabled,
    values,
    fieldName,
    disabled,
    label,
    id,
    childLabel,
    childExtraInfo
  );
};

export const getPrescreeningAssetsVerificationItem = (
  isArgyleEnabled,
  values,
  fieldName,
  disabled
) => {
  const label = "Balances";
  const id = "balances-verification";
  const childLabel = "Always allow lead to perform Balance Verification";
  const childExtraInfo = `If checked lead will always be offered to perform a balance verification regardless if
      they connect to Lynk.`;
  return getAssetsVerificationCommon(
    isArgyleEnabled,
    values,
    fieldName,
    disabled,
    label,
    id,
    childLabel,
    childExtraInfo
  );
};

export const getBlockApplicationSubmitIfNoIncomeItem = (
  values,
  prop,
  isGuarantor
) => {
  const userLabel = isGuarantor ? "guarantor" : "applicant/occupant";
  return {
    id: prop,
    label: "Block Application Submission",
    checked: values[prop],
    extraInfo: `To submit applications, ${userLabel} must verify their income or specify a reason why unable. Managers with special permission can unblock the ${userLabel} if needed.`,
  };
};

export const getAllowManagersToViewSSNItem = (fieldName, values) => {
  return {
    id: fieldName,
    label: "Allow managers to view SSN / ITIN",
    checked: values[fieldName],
    extraInfo:
      "If enabled, managers with the correct permission will be able to view the entire SSN / ITIN. Managers without the permission will be able to view the last 4 digits of the SSN / ITIN.",
  };
};

export const getCountyCriminalSearchItem = (fieldName, options, disabled) => {
  return {
    label: "County Criminal Search",
    id: "county-criminal-search",
    disabled,
    children: [
      {
        id: fieldName,
        type: CHECKBOX_WITH_UNORDERED_LIST_CHILD_TYPE.RADIO,
        options: (options || []).map((o) => ({ value: o.id, label: o.value })),
      },
    ],
  };
};

export const getConditionallyProceedItem = (fieldName, values) => {
  return {
    id: fieldName,
    label: "Hide Criminal Records Until Conditionally Proceeded",
    checked: values[fieldName],
    extraInfo:
      "Leasing agents will be required to conditionally proceed with the applicant in order to view their criminal records. This applies to all applicant types on the application.",
  };
};
