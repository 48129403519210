import { IntegrationProvider } from "shared/config/constants";

import { CredentialsHeaderType } from "../yardi.config";

export const getExternalProviderAccountName = (
  provider: IntegrationProvider,
  yardiCredentials?: CredentialsHeaderType
) => {
  switch (provider) {
    case IntegrationProvider.yardi:
    case IntegrationProvider.entrata:
      return yardiCredentials?.username;
    case IntegrationProvider.realPage:
      return yardiCredentials?.inventoryProviderCompanyId;
    default:
      return "";
  }
};
