import styled from "@emotion/styled";

import Icon from "shared/components/Icon";
import { COLORS } from "shared/config/constants";

const StyledLayout = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: ${COLORS.lightGray2};
`;

const ContentContainer = styled.div`
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: auto;

  .logo-container {
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: left;
    align-self: flex-start;

    .logo {
      max-height: 80px;
      max-width: 80px;
      margin-right: 20px;
    }
  }

  .content {
    flex-grow: 1;
  }

  .powered-by {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    margin-top: 20px;
  }
`;

const VeroLogo = styled(Icon.LogoIcon)`
  height: 10px;
  width: 51px;
`;

const Wall = styled.div`
  background: ${COLORS.white};
  border: 1px solid ${COLORS.borders};
  box-sizing: border-box;
  box-shadow: 0px 4px 20px ${COLORS.boxShadowLight};
  border-radius: 2px;
`;

export { StyledLayout, ContentContainer, VeroLogo, Wall };
