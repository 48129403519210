import styled from "@emotion/styled";

export const PropertyIntegrationMarkContainer = styled.div<{
  opacity?: number;
}>`
  opacity: ${({ opacity }) => opacity ?? 1};
`;

export const UnitName = styled.strong`
  display: flex;
  align-items: center;
`;
