import { ReactNode } from "react";

import { IntegrationActivationWarningModal } from "manager/components/Modal";
import { useGetIntegrationStatuses } from "manager/hooks/api";
import Icon from "shared/components/Icon";
import TeaserModal from "shared/components/Modals/TeaserModal";
import { IntegrationProvider } from "shared/config/constants";
import useModal from "shared/hooks/useModal";

import { getIntegrationMessages } from "./config";
import { IntegrationStatus } from "./IntegrationStatus";

export const useMarketplace = () => {
  const { openModalDialog } = useModal();
  const { integrationStatuses, isIntegrationStatusesLoading } =
    useGetIntegrationStatuses();

  const { bluemoon, yardi, entrata, realPage } = integrationStatuses ?? {};

  const displayIntegrationWarning = (message: string, icon: ReactNode) =>
    openModalDialog(IntegrationActivationWarningModal, { message, icon });

  const displayIntegrationTeaser = (
    subtitleMessage: string,
    description: ReactNode
  ) => openModalDialog(TeaserModal, { subtitleMessage, description });

  const disabledClickAction = (provider: IntegrationProvider) => {
    const config = getIntegrationMessages(provider);
    if (!provider) {
      return;
    }
    if (
      (provider === IntegrationProvider.yardi && yardi?.isEnabled) ||
      (provider === IntegrationProvider.bluemoon && bluemoon?.isEnabled) ||
      (provider === IntegrationProvider.entrata && entrata?.isEnabled) ||
      (provider === IntegrationProvider.realPage && realPage?.isEnabled)
    ) {
      displayIntegrationWarning(config?.warning, Icon.NoAccessIcon);
    } else {
      displayIntegrationTeaser(config.subtitle, config.description);
    }
  };

  const updatedProps = {
    loading: isIntegrationStatusesLoading,
    yardi: {
      isEnabled: yardi?.isEnabled,
      isConnected: yardi?.isConnected,
      status: IntegrationStatus[yardi?.status],
    },
    bluemoon: {
      isEnabled: bluemoon?.isEnabled,
      isConnected: bluemoon?.isConnected,
    },
    entrata: {
      isEnabled: entrata?.isEnabled,
      isConnected: entrata?.isConnected,
      status: IntegrationStatus[entrata?.status],
    },
    realPage: {
      isEnabled: realPage?.isEnabled,
      isConnected: realPage?.isConnected,
      status: IntegrationStatus[realPage?.status],
    },
    disabledClickAction,
    displayIntegrationWarning,
    displayIntegrationTeaser,
  };

  return updatedProps;
};
