import React from "react";

import { PropertyIntegrationMark } from "manager/components/IntegrationMark";
import { getIntegrationType } from "manager/utils/integration.util";
import Icon from "shared/components/Icon";
import { ActionLink } from "shared/components/Links";
import Table from "shared/components/Table";
import { INTEGRATION_TYPES } from "shared/config/constants";

import { PropertiesTableProps } from "../interfaces";

import { PropertyIntegrationMarkContainer, UnitName } from "./styled";

const PropertiesTable = ({
  assignEmployeesToProperty,
  unassignFromPortfolio,
  createUnit,
  properties,
  loading,
  hasInputValue,
  onRowClick,
}: PropertiesTableProps) => {
  const getPropertiesRows = () =>
    properties?.map((property, index) => {
      const integrationTypeEntrata =
        getIntegrationType(property) === INTEGRATION_TYPES.ENTRATA;
      return {
        key: property.id,
        values: [
          <UnitName data-testid={`property-${index}-name`}>
            {property.name}{" "}
            <PropertyIntegrationMarkContainer
              opacity={integrationTypeEntrata ? 0.75 : 1}
            >
              <PropertyIntegrationMark property={property} />
            </PropertyIntegrationMarkContainer>
          </UnitName>,
          <span data-testid={`property-${index}-address`}>
            {property.address}
          </span>,
          <span data-testid={`property-${index}-numUnits`}>
            {`${property.numUnitsRented} / ${property.numUnits}`}
          </span>,
        ],
        primaryAction: (
          <ActionLink
            plus
            onClick={() => {
              createUnit({
                propertyId: property.id,
                propertyName: property.name,
              });
            }}
            data-testid={`property-${index}-newUnit`}
          >
            New Unit
          </ActionLink>
        ),
        dropdownItems: [
          {
            key: "ASSIGN_EMPLOYEES",
            label: "Assign Employees",
            onClick: () =>
              assignEmployeesToProperty({
                propertyId: property.id,
                propertyName: property.name,
              }),
          },
          {
            key: "UNASSIGN_FROM_PORTFOLIO",
            label: "Unassign From Portfolio",
            onClick: () =>
              unassignFromPortfolio({
                portfolioName: property.portfolioName,
                portfolioId: property.portfolio,
                property: {
                  id: property.id,
                  name: property.name,
                },
              }),
          },
        ],
      };
    });

  return (
    <Table
      alt
      searching={hasInputValue}
      showActionColumn
      loading={loading}
      columns={[
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "Address",
          dataIndex: "address",
          key: "address",
        },
        {
          title: "Units: Rented/All",
          dataIndex: "numUnits",
          key: "numUnits",
        },
      ]}
      onRowClick={onRowClick}
      dropdownItems
      placeholder={{
        Icon: Icon.NoPropertiesIcon,
        label: "Properties",
      }}
      rows={getPropertiesRows()}
      columnBreakpoints={{
        xs: {
          column1: "name",
          column2: "numUnits",
        },
        sm: ["name", "numUnits"],
        md: ["name", "numUnits"],
        lg: ["name", "address", "numUnits", "units"],
      }}
      pagination={false}
      noBorderBottom
    />
  );
};

export default PropertiesTable;
