import styled from "@emotion/styled";

import { MEDIA_QUERIES, COLORS } from "shared/config/constants";

const BasicContainer = styled("div")<{ example?: boolean }>((props) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  zIndex: 200,
  backgroundColor: COLORS.header.background,
  borderBottom: `1px solid ${COLORS.borders}`,
  padding: "24px",
  ...(props.example && {
    position: "relative",
    width: "100%",
  }),
  [MEDIA_QUERIES.w.ltMd]: {
    padding: "18px 20px",
  },
}));

const IconContainer = styled("span")({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
});

export { BasicContainer, IconContainer };
