import styled from "@emotion/styled";

import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

export const Body = styled("div")({
  overflowY: "scroll",
  maxHeight: "343px",
});

export const ListContainer = styled.div({
  borderLeft: `1px solid ${COLORS.list.border}`,
  borderRight: `1px solid ${COLORS.list.border}`,
  [MEDIA_QUERIES.w.ltSm]: {
    border: 0,
  },
});

export const LoadingContainer = styled.div(() => ({
  display: "flex",
  justifyContent: "center",
  padding: "32px 0",
}));
