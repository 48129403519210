import React from "react";

import Icon from "shared/components/Icon";
import { Modal } from "shared/components/Modal";
import Well from "shared/components/Well";

import { ConfirmationContent } from "./styled";

export interface ConfirmationModalProps {
  title: string;
  subtitle?: React.ReactNode;
  message?: React.ReactNode;
  onConfirm?: () => void;
  description?: React.ReactNode | React.ReactNode[];
  noDescriptionIcon?: boolean;
  DescriptionIcon?: React.ElementType;
  submitButtonLabel?: string;
  cancelLinkLabel?: string;
  showCancelLink?: boolean;
  submitting?: boolean;
}

const ConfirmationModal = ({
  title,
  subtitle = undefined,
  message = undefined,
  description = undefined,
  noDescriptionIcon = false,
  DescriptionIcon = Icon.OrangeExclamation,
  submitButtonLabel = "CONFIRM",
  cancelLinkLabel = "Cancel",
  showCancelLink = true,
  onConfirm,
  submitting = false,
}: ConfirmationModalProps) => {
  const descriptionItems = Array.isArray(description)
    ? description
    : description && [description];

  return (
    <Modal
      title={title}
      subtitle={subtitle}
      submitButtonLabel={submitButtonLabel}
      cancelLinkLabel={cancelLinkLabel}
      submit={onConfirm}
      centered
      showSubmitButton
      showCancelLink={showCancelLink}
      submitting={submitting}
    >
      <Modal.Body>
        <ConfirmationContent
          withDescription={description}
          noDescriptionIcon={noDescriptionIcon}
        >
          {message && <p className="message">{message}</p>}

          {descriptionItems?.map((descriptionItem, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Well className="description-section" key={i}>
              {!noDescriptionIcon && (
                <div className="description-icon">
                  <DescriptionIcon />
                </div>
              )}
              <p className="description-text">{descriptionItem}</p>
            </Well>
          ))}
        </ConfirmationContent>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmationModal;
