import React, { useState } from "react";

import ShareMagicLinksModal from "manager/components/MagicLinks/ShareMagicLinksModal/";
import { useGetMagicLinksAgents } from "manager/hooks/api";
import { useGetRoles } from "shared/hooks/api";
import useModal from "shared/hooks/useModal";

import ExportAgentsModal from "../modals/ExportAgentsModal";

import AgentsTable from "./AgentsTable";

const ORDER_BY_NAMES = Object.freeze({
  firstName: "user__first_name",
  lastName: "user__last_name",
});

const ORDER_BY = Object.freeze({
  name: {
    ascend: [ORDER_BY_NAMES.firstName, ORDER_BY_NAMES.lastName].join(),
    descend: [ORDER_BY_NAMES.firstName, ORDER_BY_NAMES.lastName]
      .map((name) => `-${name}`)
      .join(),
  },
});

const blankFilters = {
  role: null,
  nameOrEmail: "",
};

const AgentsTableContainer = () => {
  const [ordering, setOrdering] = useState("");
  const [filters, setFilters] = useState({ ...blankFilters });
  const { data: roles } = useGetRoles();
  const { data: agents, isLoading: isAgentsLoading } = useGetMagicLinksAgents({
    ...filters,
    ordering,
  });
  const { openModalDialog } = useModal();

  const updateFilters = (values) => {
    if (values) {
      setFilters({
        ...filters,
        ...values,
        ordering,
      });
    } else {
      setFilters({ ...blankFilters, ordering });
    }
  };

  const onTableChange = (tablePagination, tableFilters, sorter) => {
    const newOrdering = sorter.order
      ? `${ORDER_BY[sorter.columnKey][sorter.order]}`
      : undefined;
    setOrdering(newOrdering);
  };

  const onExportLinks = () => {
    openModalDialog(ExportAgentsModal);
  };

  const onShareLinks = (context) =>
    openModalDialog(ShareMagicLinksModal, context);

  return (
    <AgentsTable
      agents={agents}
      isAgentsLoading={isAgentsLoading}
      roles={roles}
      updateFilters={updateFilters}
      filters={filters}
      onTableChange={onTableChange}
      onShareLinks={onShareLinks}
      onExportLinks={onExportLinks}
    />
  );
};

export default AgentsTableContainer;
