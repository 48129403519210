import React from "react";

import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import { RegularText } from "shared/components/Typography";
import InfoPage from "shared/templates/InfoPage";

const SetPasswordLinkSentPage = ({ email }: { email: string }) => (
  <InfoPage
    title="Check your email"
    description={
      <FlexContainer alignItems="center" flexDirection="column">
        <RegularText weak>
          A new link has been sent to <RegularText strong>{email}</RegularText>
        </RegularText>
      </FlexContainer>
    }
    MainIcon={Icon.EmailLight}
  />
);

export default SetPasswordLinkSentPage;
