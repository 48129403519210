import React from "react";

import { Col } from "antd";

import FlexContainer from "shared/components/FlexContainer";
import Spacer from "shared/components/Spacer";
import Tooltip from "shared/components/Tooltip";
import { RegularText, SmallTitle } from "shared/components/Typography";
import { formatDate, getValueOrNA } from "shared/utils/ui";

import { InlineStatusRow } from "./styled";

export interface IVerificationStatusShell {
  verificationType: string;
  typeLabel: React.ReactNode;
  StatusIcon?: React.ElementType;
  statusLabel: string;
  tooltipMessage?: React.ReactNode;
  verificationDate?: string;
  showTitle?: boolean;
}

const VerificationStatusShell = ({
  verificationType,
  typeLabel,
  StatusIcon = undefined,
  statusLabel,
  tooltipMessage = undefined,
  verificationDate = undefined,
  showTitle = true,
}: IVerificationStatusShell) => {
  const isVOR = verificationType === "VOR";
  const surveyNotSubmited = statusLabel !== "Submitted";
  return (
    <InlineStatusRow type="flex" align="middle">
      {showTitle && (
        <Col xs={24} md={8}>
          <SmallTitle strong>{typeLabel}</SmallTitle>
        </Col>
      )}
      <Spacer hideGteMd />
      <Col xs={24} md={14}>
        <FlexContainer alignItems="center">
          <Tooltip title={tooltipMessage}>
            {StatusIcon && <StatusIcon className="status-icon" />}
          </Tooltip>
          <RegularText
            className={isVOR && surveyNotSubmited ? "error-text" : undefined}
          >
            {getValueOrNA(statusLabel)}
          </RegularText>
          {verificationDate && (
            <RegularText style={{ width: 200 }}>
              &nbsp;&nbsp;on {formatDate(verificationDate)}
            </RegularText>
          )}
        </FlexContainer>
      </Col>
      <Spacer hideGteMd />
    </InlineStatusRow>
  );
};

export default VerificationStatusShell;
