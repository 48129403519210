import React, { useRef } from "react";

import { FormikProps } from "formik";
import { isArray } from "lodash";

import { signatureFieldsStorage } from "admin/config/checkboxGroupSignature.config";
import { useCreateField } from "admin/hooks/api";
import useModal from "shared/hooks/useModal";

import { CHECKBOX_GROUP_SIGNATURE_TYPE } from "./config";
import { CreateFieldFormFormik } from "./CreateFieldForm/interfaces";
import CreateFieldModal from "./CreateFieldModal";
import { cookNewFieldBody, getSignatureFieldData } from "./utils";

const CreateFieldModalContainer = () => {
  const formikRef = useRef<FormikProps<CreateFieldFormFormik>>();
  const { closeActiveModalDialog } = useModal();

  const { createField } = useCreateField({
    onSuccess: () => closeActiveModalDialog(),
    onError: (error) => {
      formikRef.current.setErrors(
        isArray(error?.errors)
          ? { nonFieldErrors: error.errors }
          : error?.errors
      );
      formikRef.current.setSubmitting(false);
    },
  });

  const onSubmit = async (values: CreateFieldFormFormik) => {
    if (values.type === CHECKBOX_GROUP_SIGNATURE_TYPE.id) {
      const signature = getSignatureFieldData(values);

      if (signatureFieldsStorage.has(signature.name)) {
        formikRef.current.setFieldError(
          "name",
          "Field with this name already exists"
        );
        return;
      }

      signatureFieldsStorage.addItem(signature.name, signature);
      closeActiveModalDialog();
    } else {
      // @ts-ignore
      await createField(cookNewFieldBody(values));
    }
  };

  return <CreateFieldModal formikRef={formikRef} onSubmit={onSubmit} />;
};

export default CreateFieldModalContainer;
