import styled from "@emotion/styled";

import { MEDIA_QUERIES } from "shared/config/constants";

const HeaderTitleContainer = styled.div(() => ({
  [MEDIA_QUERIES.w.ltSm]: {
    paddingTop: "16px",
    paddingBottom: "16px",
  },
  [MEDIA_QUERIES.w.gteSm]: {
    paddingTop: "24px",
    paddingBottom: "24px",
  },
}));

const HeaderTitle = styled.label(() => ({
  color: "#2D2943",
  [MEDIA_QUERIES.w.ltSm]: {
    lineHeight: "130%",
    fontSize: "20px",
  },
  [MEDIA_QUERIES.w.gteSm]: {
    fontSize: "22px",
  },
}));

const HeaderSubTitle = styled.label(() => ({
  fontWeight: 300,
  [MEDIA_QUERIES.w.ltSm]: {
    lineHeight: "150%",
  },
}));

const HeaderSubTitleContainer = styled.div((props) => ({
  [MEDIA_QUERIES.w.ltSm]: {
    paddingTop: props.hasTitle ? "22px" : "32px",
    paddingBottom: "24px",
  },
  [MEDIA_QUERIES.w.gteSm]: {
    paddingTop: props.hasTitle ? "22px" : "32px",
    paddingBottom: "32px",
  },
  [MEDIA_QUERIES.w.gteLg]: {
    paddingTop: props.hasTitle ? "22px" : "40px",
    paddingBottom: "32px",
  },
}));

export {
  HeaderSubTitle,
  HeaderSubTitleContainer,
  HeaderTitle,
  HeaderTitleContainer,
};
