import React from "react";

import { Col, Row, Divider } from "antd";
import { Form, Formik } from "formik";
import { Helmet } from "react-helmet";

import { Button } from "shared/components/Button";
import ErrorList from "shared/components/ErrorList";
import FlexContainer from "shared/components/FlexContainer";
import { Input, PasswordInput, VeroFormField } from "shared/components/Form";
import Icon from "shared/components/Icon";
import { UnderlineLink } from "shared/components/Links";
import Skeleton from "shared/components/Skeleton";
import Spacer from "shared/components/Spacer";
import SSOButton from "shared/components/SSOButton";
import { LargeTitle, RegularText } from "shared/components/Typography";
import VerticalDivider from "shared/components/VerticalDivider";

import ROUTES from "shared/config/routes";
import AuthPage from "shared/templates/AuthPage";
import { showError } from "shared/utils/forms";

import { initialValues, ValidationSchema } from "./config";
import { LoginFormikProps, LoginPageProps } from "./interfaces";
import {
  LoginPageCard,
  FooterActions,
  ForgotPasswordLink,
  InfoMessage,
} from "./styled";

const LoginPage = ({
  submit,
  title,
  ssoConfig,
  isSSOConfigLoading,
  formikRef,
  isMobile,
  infoBanner,
}: LoginPageProps) => (
  <>
    <Helmet>
      <title>Sign In</title>
    </Helmet>
    <AuthPage>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={ValidationSchema}
        validateOnBlur={false}
        onSubmit={submit}
      >
        {({ errors, touched, isSubmitting }: LoginFormikProps) => (
          <LoginPageCard width="667px">
            {infoBanner?.show && (
              <>
                <InfoMessage>
                  <Icon.InfoWhiteIcon /> {infoBanner?.message}
                </InfoMessage>
                <Spacer
                  size={isMobile ? Spacer.SIZES.xl : Spacer.SIZES.default}
                />
              </>
            )}
            <Row type="flex">
              <Col xs={24} md={10}>
                <Form noValidate>
                  {isMobile && (
                    <FlexContainer
                      justifyContent="center"
                      className="mobile-logo"
                    >
                      <Icon.LogoIcon />
                    </FlexContainer>
                  )}

                  <LargeTitle data-testid="login-page-title">
                    {title}
                  </LargeTitle>
                  {errors.nonFieldErrors && (
                    <ErrorList errors={errors.nonFieldErrors} />
                  )}

                  <Spacer />
                  <VeroFormField
                    name="email"
                    id="email"
                    type="email"
                    label="Email"
                    as={Input}
                    error={showError("email", touched, errors)}
                    tabIndex={0}
                    autoFocus={!initialValues.email}
                  />
                  <Spacer />
                  <VeroFormField
                    name="password"
                    id="password"
                    label="Password"
                    as={PasswordInput}
                    error={showError("password", touched, errors)}
                    autoFocus={Boolean(initialValues.email)}
                  />
                  <Spacer size={Spacer.SIZES.sm} hideGteMd />
                  <Spacer hideLtMd />
                  <FooterActions>
                    <Button
                      type="primary"
                      className="sign-in-button"
                      htmlType="submit"
                      SuffixIcon={Icon.ButtonForwardArrowIcon}
                      loading={isSubmitting}
                      data-testid="submit-button"
                    >
                      Sign In
                    </Button>
                    <ForgotPasswordLink>
                      <UnderlineLink to={ROUTES.passwordRecovery}>
                        Forgot Password?
                      </UnderlineLink>
                    </ForgotPasswordLink>
                  </FooterActions>
                </Form>
              </Col>
              {!isMobile && (
                <Col span={4} className="col-vertical-divider">
                  <VerticalDivider text="OR" />
                </Col>
              )}
              {isMobile && (
                <Col span={24}>
                  <Divider>
                    <RegularText weak>OR</RegularText>
                  </Divider>
                </Col>
              )}
              <Col xs={24} md={10}>
                <FlexContainer flexDirection="column">
                  {!isMobile && (
                    <>
                      <Icon.LogoIcon className="normal-logo" />
                      <Spacer size={Spacer.SIZES.md} />
                    </>
                  )}
                  <Skeleton loading={isSSOConfigLoading}>
                    {ssoConfig && (
                      <FlexContainer flexDirection="column" alignItems="center">
                        {ssoConfig.map((provider) => (
                          <React.Fragment key={provider.name}>
                            {/* @ts-ignore */}
                            <SSOButton provider={provider} />
                            <Spacer />
                          </React.Fragment>
                        ))}
                      </FlexContainer>
                    )}
                  </Skeleton>
                </FlexContainer>
              </Col>
            </Row>
          </LoginPageCard>
        )}
      </Formik>
    </AuthPage>
  </>
);

export default LoginPage;
