import * as Yup from "yup";

import { VALIDATION_MESSAGES } from "shared/config/constants";

export const initialValues = {
  formIncome: null,
};

export const validationSchema = Yup.object().shape({
  formIncome: Yup.number()
    .integer()
    .required(VALIDATION_MESSAGES.fieldRequired)
    .nullable(),
});
