import React, { useState } from "react";

import { AttachmentFile } from "shared/components/Form/UploadWall";
import useModal from "shared/hooks/useModal";

import ViewEditAttachmentsModal from "./ViewEditAttachmentsModal";

const ViewEditAttachmentsModalContainer = () => {
  const { currentModalContext } = useModal();
  const {
    title,
    files,
    inEditMode,
    onConfirm,
    showAttachmentsVisibilityAction,
  } = currentModalContext;

  const [submitting, setSubmitting] = useState(false);
  const [filesList, setFilesList] = useState<Partial<AttachmentFile>[]>(files);

  const submit = async () => {
    if (inEditMode) {
      setSubmitting(true);
      await onConfirm(filesList);
      setSubmitting(false);
    }
  };

  const uploadFile = (fileList: FileList) =>
    setFilesList((prevState) => [...prevState, { file: fileList[0] }]);

  const changeFileVisibility = (index: number, isPublic: boolean) =>
    setFilesList(
      filesList.map((file, i) =>
        i === index
          ? {
              ...file,
              isPublic,
            }
          : file
      )
    );

  const deleteFile = (index: number) =>
    setFilesList((prevState) => prevState.filter((_file, i) => i !== index));

  return (
    <ViewEditAttachmentsModal
      title={title}
      inEditMode={inEditMode}
      submit={submit}
      submitting={submitting}
      filesList={filesList}
      showAttachmentsVisibilityAction={showAttachmentsVisibilityAction}
      deleteFile={deleteFile}
      uploadFile={uploadFile}
      changeFileVisibility={changeFileVisibility}
    />
  );
};

export default ViewEditAttachmentsModalContainer;
