import { useEffect } from "react";

import { insertScript } from "shared/utils/ui";

const STATUS_PAGE_SCRIPT = "https://tdjblqjs3b20.statuspage.io/embed/script.js";

export const useStatusPage = () => {
  useEffect(() => {
    insertScript(STATUS_PAGE_SCRIPT, document.body);
  }, []);
};
