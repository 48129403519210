import snakeCase from "lodash/snakeCase";
import { queryCache, useMutation, useQuery } from "react-query";

import { NOTIFICATIONS } from "shared/config/constants";
import * as api from "shared/lib/api/dealSetupApi";
import { openNotification } from "shared/utils/ui";

export const GET_DEAL_SETUP = "getDealSetup";
const GET_DEFAULT_DEAL_SETUP = "getDefaultDealSetup";
const GET_DEFAULT_PRESCREENING_DEAL_SETUP = "getDefaultPrescreeningDealSetup";

export const DEAL_QUERY_KEYS = Object.freeze({
  getDealSetup: (id) => [GET_DEAL_SETUP, id],
  getDefaultDealSetup: () => [GET_DEFAULT_DEAL_SETUP],
  getDefaultPrescreeningDealSetup: () => [GET_DEFAULT_PRESCREENING_DEAL_SETUP],
});

export const useGetDealSetup = (id) => {
  const {
    data: dealSetup,
    isLoading: isDealSetupLoading,
    isFetching: isDealSetupFetching,
  } = useQuery(
    DEAL_QUERY_KEYS.getDealSetup(id),
    () => api.getDealSetup({ id }),
    {
      enabled: !!id,
      onError: () =>
        openNotification("Failed to get the settings", NOTIFICATIONS.error),
    }
  );
  return {
    dealSetup,
    isDealSetupLoading,
    isDealSetupFetching,
  };
};

export const useGetDefaultDealSetup = (config = {}) => {
  const {
    data: defaultDealSetup,
    isLoading: isDefaultDealSetupLoading,
    isFetching: isDefaultDealSetupFetching,
  } = useQuery(
    DEAL_QUERY_KEYS.getDefaultDealSetup(),
    () => api.getDefaultDealSetup(),
    {
      ...config,
      onError: () =>
        openNotification("Failed to get the settings", NOTIFICATIONS.error),
    }
  );
  return {
    defaultDealSetup,
    isDefaultDealSetupLoading,
    isDefaultDealSetupFetching,
  };
};

export const useGetDefaultPrescreeningDealSetup = (config = {}) => {
  const {
    data: defaultPrescreeningDealSetup,
    isLoading: isDefaultPrescreeningDealSetupLoading,
  } = useQuery(
    DEAL_QUERY_KEYS.getDefaultPrescreeningDealSetup(),
    () => api.getDefaultPrescreeningDealSetup(),
    {
      ...config,
      onError: () =>
        openNotification(
          "Failed to get the prequalificaiton settings",
          NOTIFICATIONS.error
        ),
    }
  );
  return {
    defaultPrescreeningDealSetup,
    isDefaultPrescreeningDealSetupLoading,
  };
};

export const useUpdateDealSetup = () => {
  const [mutate, status] = useMutation(
    ({ id, dealSetup }) =>
      api.updateDealSetup({ id, dealSetup }).then(({ data }) => data),
    {
      throwOnError: true,
      onSuccess: (_result, params) => {
        queryCache.invalidateQueries(DEAL_QUERY_KEYS.getDealSetup(params.id));
        queryCache.invalidateQueries(DEAL_QUERY_KEYS.getDefaultDealSetup());
        openNotification(
          "You have successfully changed settings",
          NOTIFICATIONS.info
        );
      },
      onError: () =>
        openNotification(
          "Failed to save settings changes.",
          NOTIFICATIONS.error
        ),
    }
  );

  return {
    updateDealSetup: mutate,
    ...status,
  };
};

export const useToggleRequiredQuestionActive = (dealSetupId) => {
  const [
    toggleRequiredQuestionActive,
    { isLoading: isToggleRequiredQuestionActiveLoading },
  ] = useMutation(api.toggleRequiredQuestionActive, {
    onSuccess: () => {
      queryCache.invalidateQueries(DEAL_QUERY_KEYS.getDealSetup(dealSetupId));
      queryCache.invalidateQueries(DEAL_QUERY_KEYS.getDefaultDealSetup());
    },
    onError: () => {
      openNotification(
        "Failed to change additional question",
        NOTIFICATIONS.error
      );
    },
  });
  return {
    toggleRequiredQuestionActive,
    isToggleRequiredQuestionActiveLoading,
  };
};

export const useEnableLROSettings = (dealSetupId) => {
  const [mutate, isLoading] = useMutation(
    (enable) => api.enableLROSettings({ id: dealSetupId, enable }),
    {
      onSuccess: () => {
        queryCache.invalidateQueries(DEAL_QUERY_KEYS.getDealSetup(dealSetupId));
        queryCache.invalidateQueries(DEAL_QUERY_KEYS.getDefaultDealSetup());
        openNotification(
          `LRO settings updated succesfully`,
          NOTIFICATIONS.info
        );
      },
      onError: () => {
        const msg =
          "Failed to update LRO settings. Please check if LRO is enabled and Yardi connected";
        openNotification(msg, NOTIFICATIONS.error);
        throw msg;
      },
    }
  );
  return {
    enableLRO: mutate,
    isEnableLROSettingsLoading: isLoading,
  };
};

export const useUpdateLROSettings = (dealSetupId) => {
  const [mutate, isLoading] = useMutation(
    (enabled, selectionValidityLifespan) =>
      api.updateLROSettings({
        id: dealSetupId,
        enabled,
        selectionValidityLifespan,
      }),
    {
      onSuccess: () => {
        queryCache.invalidateQueries(DEAL_QUERY_KEYS.getDealSetup(dealSetupId));
        queryCache.invalidateQueries(DEAL_QUERY_KEYS.getDefaultDealSetup());
        openNotification(
          `LRO settings updated succesfully`,
          NOTIFICATIONS.info
        );
      },
      onError: (error) => {
        if (error.errors) {
          const msg = `${snakeCase(Object.keys(error.errors)[0])}: ${
            Object.values(error.errors)[0]
          }`;
          openNotification(msg, NOTIFICATIONS.error);
        } else {
          const msg =
            "Failed to update LRO settings. Please check if LRO is enabled and Yardi connected";
          openNotification(msg, NOTIFICATIONS.error);
          throw msg;
        }
      },
    }
  );
  return {
    updateLRO: mutate,
    isUpdateLROSettingsLoading: isLoading,
  };
};
