import React from "react";

import withSizes from "react-sizes";

import { formatDate } from "shared/utils/ui";

import { ApplicantSummary } from "../../types";

import { printDollarsFromCents } from "shared/utils/dollar-print";
import {
  Address,
  ApplicantName,
  Applications,
  ColumnData,
  DealWrapper,
  Property,
} from "./styled";

const getColumnWidth = (width: number) => {
  if (width > 570) {
    return [150, 200, 250, 100];
  }

  return [150, 150, 150, 150];
};

type Props = {
  id: number;
  applications: ApplicantSummary[];
  unitName?: string;
  propertyName?: string;
  unitListing?: { unitAvailableOn: Date };
  width?: number;
  rent?: number;
};
const Inner: React.FC<Props> = ({
  id,
  applications,
  unitName,
  propertyName,
  unitListing,
  width,
  rent,
}) => {
  const columnWidth = getColumnWidth(width);
  return (
    <DealWrapper data-testid={`deal-${id}`}>
      <ColumnData width={columnWidth[0]}>
        <Applications>
          {applications?.length
            ? applications?.map((applicant) => (
                <ApplicantName key={applicant.id}>
                  {applicant?.firstName} {applicant?.lastName}
                </ApplicantName>
              ))
            : "N/A"}
        </Applications>
      </ColumnData>
      <ColumnData width={columnWidth[1]}>
        <Address>
          {unitName}
          <Property>{propertyName}</Property>
        </Address>
      </ColumnData>
      <ColumnData width={columnWidth[2]} data-testid="available-on-date">
        {unitListing?.unitAvailableOn
          ? `Available on ${formatDate(unitListing.unitAvailableOn)}`
          : "N/A"}
      </ColumnData>
      <ColumnData width={columnWidth[3]} data-testid="rent">
        {rent ? `Rent: ${printDollarsFromCents(rent)}` : "N/A"}
      </ColumnData>
    </DealWrapper>
  );
};

export const DealDescription = withSizes(({ width }) => ({ width }))(Inner);
