import React from "react";

import Collapse from "shared/components/Collapse";
import Icon from "shared/components/Icon";
import { ActionLink } from "shared/components/Links";
import Table from "shared/components/Table";
import Tile from "shared/components/Tile";
import { calcTileInnerBorderBottom } from "shared/utils/ui";

import EmployeeNoProperties from "./EmployeeNoProperties";
import { EmployeePortfoliosListProps } from "./interfaces";
import { PropertyCount } from "./styled";

const EmployeePortfoliosList = ({
  portfolios,
  employee,
  createUnit,
  unassignFromProperty,
  unassignFromPortfolio,
  openPropertySettings,
  hasSearch,
  goToProperyPage,
}: EmployeePortfoliosListProps) => (
  <>
    {portfolios.length > 0 ? (
      portfolios.map((portfolio, index) => (
        <Collapse
          key={portfolio.id}
          header={({ CollapseIcon, collapsed }) => (
            <Tile.Inner
              borderBottom={
                collapsed ? calcTileInnerBorderBottom(portfolios, index) : true
              }
            >
              <strong>
                <u>
                  {portfolio.isDefault
                    ? "Not assigned to portfolio"
                    : portfolio.name}
                </u>
              </strong>{" "}
              <PropertyCount>
                ({portfolio.assignedLabel} properties)
              </PropertyCount>
              <CollapseIcon />
            </Tile.Inner>
          )}
        >
          <Table
            alt
            showActionColumn
            columns={[
              {
                title: "Name",
                dataIndex: "name",
                key: "name",
              },
              {
                title: "Address",
                dataIndex: "address",
                key: "address",
              },
              {
                title: "Units: Rented/All",
                dataIndex: "units",
                key: "units",
              },
            ]}
            dropdownItems
            onRowClick={(key) => goToProperyPage(key)}
            placeholder={{
              Icon: Icon.NoPropertiesIcon,
              label: "Properties",
            }}
            rows={portfolio.properties.map((property) => ({
              key: property.id,
              values: [
                <strong>{property.name}</strong>,
                property.fullAddress,
                `${property.numUnitsRented} / ${property.numUnits}`,
              ],
              primaryAction: employee?.isActive && (
                <ActionLink
                  plus
                  onClick={() => {
                    createUnit({
                      propertyId: property.id,
                      propertyName: property.name,
                    });
                  }}
                >
                  New Unit
                </ActionLink>
              ),
              dropdownItems: employee?.isActive
                ? [
                    {
                      key: "UNASSIGN_EMPLOYEE",
                      label: `Unassign ${employee?.user?.firstName}`,
                      onClick: () =>
                        unassignFromProperty({
                          values: {
                            properties: [property.id],
                            portfolios: [],
                          },
                          employeeName: employee?.user?.firstName,
                          propertyName: property.name,
                          employeeId: employee?.id,
                        }),
                    },
                    ...(portfolio.isDefault
                      ? []
                      : [
                          {
                            key: "UNASSIGN_FROM_PORTFOLIO",
                            label: "Unassign From Portfolio",
                            onClick: () =>
                              unassignFromPortfolio({
                                values: {
                                  portfolios: [portfolio.id],
                                  properties: [],
                                },
                                employeeName: employee?.user?.firstName,
                                portfolioName: portfolio.name,
                                employeeId: employee?.id,
                              }),
                          },
                        ]),
                    {
                      key: "OPEN_PROPERTY_SETTINGS",
                      label: "Settings",
                      onClick: () => openPropertySettings(property.id),
                    },
                  ]
                : [
                    {
                      key: "DEACTIVATED",
                      label: "Employee was deactivated",
                      disabled: true,
                    },
                  ],
            }))}
            columnBreakpoints={{
              xs: {
                column1: "name",
                column2: "address",
              },
              sm: ["name", "address"],
              md: ["name", "address"],
              lg: ["name", "address", "units"],
            }}
            pagination={false}
            indent
            noBorderBottom={index === portfolios.length - 1}
          />
        </Collapse>
      ))
    ) : (
      <EmployeeNoProperties hasSearch={hasSearch} />
    )}
  </>
);

export default EmployeePortfoliosList;
