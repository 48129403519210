import React, { useMemo } from "react";

import { Radio } from "antd";
import { Field, Formik } from "formik";
import { Helmet } from "react-helmet";

import { stringifyVeroLeadSourceId } from "manager/pages/Marketplace/integrations/yardi/ConfigureYardiProperty/section/configureYardiPropertySection.util";
import {
  ColumnHeader,
  ColumnIsDefault,
} from "manager/pages/Marketplace/integrations/yardi/ConfigureYardiProperty/section/styled";
import {
  FooterButton,
  StyledFooterBar,
  YardiMappingHeader,
} from "manager/pages/Marketplace/integrations/yardi/shared/mapping/styled";
import YardiDropdownMappingField from "manager/pages/Marketplace/integrations/yardi/shared/YardiDropdownMappingField";
import YardiMappingPage from "manager/pages/Marketplace/integrations/yardi/shared/YardiMappingPage";
import { YardiTitleContainer } from "manager/pages/Marketplace/integrations/yardi/styled";
import Container from "shared/components/Container";
import FlexContainer from "shared/components/FlexContainer";
import { PopoverContent, PreventLeaveFormModal } from "shared/components/Form";
import Icon from "shared/components/Icon";
import { ActionLink, BackLink } from "shared/components/Links";
import PageTitle from "shared/components/PageTitle";
import { Popover } from "shared/components/Popover";
import Spacer from "shared/components/Spacer";
import { BackLinkProps } from "shared/interfaces/misc";

import YardiHeaderCredentials from "../shared/YardiHeaderCredentials";

const title = "Setup Lead Sources";

const EMPTY_VALUE = {};

interface SetupYardiLeadSourcesProps {
  backLink: BackLinkProps;
  veroLeadSources?: Array<{
    id?: number | string;
    label?: string;
    info?: string;
  }>;
  yardiLeadSources?: Array<{}>;
  isLoading: boolean;
  formikRef: any;
  onFormSubmit: (data: any, callbacks: any) => void;
  onSave: (values: any) => void;
  createNewSource: () => void;
}

const SetupYardiLeadSources = ({
  backLink,
  veroLeadSources = [],
  yardiLeadSources = [],
  isLoading,
  formikRef,
  onFormSubmit,
  onSave,
  createNewSource,
}: SetupYardiLeadSourcesProps) => {
  const from = (
    <>
      <FlexContainer>
        <ColumnHeader>VERO</ColumnHeader>
        <ActionLink onMouseDown={createNewSource} disabled={isLoading}>
          + NEW SOURCE
        </ActionLink>
      </FlexContainer>
    </>
  );

  const buildComponentId = (id, property) => {
    return `leadSources[${stringifyVeroLeadSourceId(id)}].${property}`;
  };

  const elements = useMemo(
    () =>
      (veroLeadSources || []).map((item) => {
        return {
          title: item.label,
          info: item.info && (
            <Popover
              className="popover"
              withoutOverlay
              title={undefined}
              titleAddon={undefined}
              disabled={undefined}
              closeAfterTimeout={undefined}
              content={<PopoverContent>{item.info}</PopoverContent>}
            >
              <Icon.InfoIcon />
            </Popover>
          ),
          node: (
            <>
              <YardiDropdownMappingField
                name={buildComponentId(item.id, "yardiLeadSource")}
                dropdownPlaceholder="Select lead source"
                options={yardiLeadSources || []}
                asFormField
              />
              <ColumnIsDefault alignItems="center">
                <Field
                  as={Radio}
                  type="radio"
                  id={buildComponentId(item.id, "isDefault")}
                  name="defaultSelection"
                  value={item.id}
                >
                  Use as default
                </Field>
                <Popover
                  className="popover"
                  withoutOverlay
                  content={
                    <PopoverContent>
                      In the event that an applicant is invited to apply without
                      a unit, this lead source will automatically be applied. We
                      suggest that you choose a generic lead source as your
                      default option.
                    </PopoverContent>
                  }
                  title={undefined}
                  titleAddon={undefined}
                  disabled={undefined}
                  closeAfterTimeout={undefined}
                >
                  <Icon.InfoIcon />
                </Popover>
              </ColumnIsDefault>
            </>
          ),
        };
      }),
    [veroLeadSources, yardiLeadSources]
  );

  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={EMPTY_VALUE}
        onSubmit={onFormSubmit}
        validateOnMount
      >
        {({ dirty, isSubmitting, isValid, submitForm, values }) => (
          <>
            <Container expand>
              <Helmet>
                <title>{title}</title>
              </Helmet>
              {backLink && (
                <BackLink.Container>
                  <BackLink {...backLink} />
                </BackLink.Container>
              )}
              <PageTitle.Container center>
                <div>
                  <YardiTitleContainer>Lead Sources</YardiTitleContainer>
                </div>
                <YardiHeaderCredentials loadCredentials />
              </PageTitle.Container>
              <YardiMappingPage
                title={
                  <YardiMappingHeader>
                    When inviting an applicant to apply, leasing managers will
                    select a lead source within VERO. The lead source will
                    define how the applicant first came in contact with the
                    leasing manager. Please confirm and link all VERO lead
                    sources to the correct lead source.
                  </YardiMappingHeader>
                }
                from={from}
                to="External Provider"
                elements={elements || []}
                loading={isLoading}
              />
              <PreventLeaveFormModal
                title="Exit lead sources setup"
                subtitle="Would you like to save your changes?"
                submitForm={() => onSave(values)}
                isSubmitting={isSubmitting}
                preventLeaveWhen={dirty}
                cancelLinkLabel="No"
                submitButtonLabel="Yes"
                customOnCancel={undefined}
                customOnConfirm={undefined}
                onNextLocationTriggered={undefined}
              >
                <Spacer />
              </PreventLeaveFormModal>
            </Container>
            <Container noPaddingBottom noPaddingTop stickToBottom>
              <StyledFooterBar>
                <FooterButton
                  type="primary"
                  onClick={submitForm}
                  disabled={!isValid}
                  loading={isSubmitting}
                >
                  CONFIRM
                </FooterButton>
              </StyledFooterBar>
            </Container>
          </>
        )}
      </Formik>
    </>
  );
};

export default SetupYardiLeadSources;
