import { useQuery } from "react-query";

import api from "manager/lib/api";
import { NOTIFICATIONS } from "shared/config/constants";
import { openNotification } from "shared/utils/ui";
import { getAccessToken, getCachedActiveCompany, isManagerApp } from "shared/utils/auth";

export const useGetManagerProfile = (config = {}) =>
  useQuery(["getManagerProfile"], () => api.getManagerProfile(), {
    onError: () => {
      openNotification("Failed to load manager profile", NOTIFICATIONS.error);
    },
    ...config,
    enabled: !!getAccessToken() && !!getCachedActiveCompany() && isManagerApp(),
    retry: false,
  });
