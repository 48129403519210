import React from "react";

import { useHistory } from "react-router-dom";

import ROUTES from "renter/config/routes";

import LoginPage from "shared/pages/LoginPage";
import { getQueryParams } from "shared/utils/routing";

export type ShowInfoBanner = {
  showInfoBanner?: boolean | undefined;
};

const Login = () => {
  const history = useHistory();
  const urlParams = getQueryParams();
  const { state } = history.location;
  const { showInfoBanner }: ShowInfoBanner = state || false;

  const onSuccess = () => {
    window.location.href = `${window.location.origin}${urlParams?.r || ROUTES.applications}`;
    return null;
  }

  return (
    <LoginPage
      title="Renter Sign In"
      infoBanner={{
        show: showInfoBanner,
        message:
          "It appears you already accepted an invitation; sign back in to continue.",
      }}
      onSuccess={onSuccess}
    />
  );
};

export default Login;
