import React, { useState } from "react";

import { Helmet } from "react-helmet";

import Container from "shared/components/Container";
import { BackLink } from "shared/components/Links";

import BluemoonPropertyBindingsHeader from "./BluemoonPropertyBindingsHeader";
import BluemoonPropertyBindingsSubmit from "./BluemoonPropertyBindingsSubmit";
import PropertySelection from "./PropertySelection";
import { PropertySelectionContainerWrapper } from "./styled";

const title = "Property Bindings";

export enum PROPERTY_TYPE {
  BLUEMOON = "BLUEMOON",
  VERO = "VERO",
}

const BluemoonPropertyBindings = ({ backLink }) => {
  const [bluemoonProperty, setBluemoonProperty] = useState(null);
  const [veroProperties, setVeroProperties] = useState([]);

  const bluemoonIdCallback = (property) => {
    setBluemoonProperty(property);
  };

  const veroIdsCallback = (ids) => {
    setVeroProperties(ids);
  };

  return (
    <div>
      <Container expand noPaddingBottom>
        <Helmet>
          <title>Bluemoon {title}</title>
        </Helmet>
        {backLink && (
          <BackLink.Container>
            <BackLink {...backLink} />
          </BackLink.Container>
        )}
        <BluemoonPropertyBindingsHeader />
        <PropertySelectionContainerWrapper>
          <PropertySelection
            propertyType={PROPERTY_TYPE.BLUEMOON}
            stepText="1. Select Bluemoon Property"
            noteText="Select one Bluemoon property to bind to a VERO property. You can
              choose to hide a Bluemoon property to remove it from this list."
            propertiesSelectedCallback={bluemoonIdCallback}
            selectedBluemoonProperty={bluemoonProperty}
          />
          <PropertySelection
            propertyType={PROPERTY_TYPE.VERO}
            stepText="2. Select Vero Property(s) to bind"
            noteText="Select one or multiple VERO properties to bind to a single Bluemoon
              property. If the property does not exist in VERO, you can create it
              using the Add New Property action."
            propertiesSelectedCallback={veroIdsCallback}
            selectedBluemoonProperty={bluemoonProperty}
          />
        </PropertySelectionContainerWrapper>
        <BluemoonPropertyBindingsSubmit
          bluemoonPropertyId={bluemoonProperty && bluemoonProperty.id}
          veroPropertyIds={veroProperties}
        />
      </Container>
    </div>
  );
};

export default BluemoonPropertyBindings;
