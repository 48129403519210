import React from 'react'
import _ from 'lodash'
import { ServiceCard } from 'shared/components/vega/Service'
import { ExplanationTooltip } from 'shared/components/vega/ExpressionEngine'
import { queryByAnnotation } from './utils'

function resolveStatusEntry (result, statusQueries) {
  return _.chain(statusQueries).castArray().flattenDeep().map(
    ({ query, status }) => ({
      entry: queryByAnnotation(result, query, false),
      status
    })
  ).filter('entry').last().value()
}

const LumenEntryCard = ({ label = undefined, result = undefined, valueQuery = undefined, valueTransformer = undefined, statusQueries = undefined, defaultStatus = 'fail', children = null }) => {
  const value = queryByAnnotation(result, valueQuery)
  const statusEntry = resolveStatusEntry(result, statusQueries) as any

  if (_.isNil(value)) {
    return null
  }

  return <ExplanationTooltip entry={statusEntry?.entry}>
    <ServiceCard
      label={label}
      value={valueTransformer ? valueTransformer(value) : value}
      status={statusEntry?.entry?.result ? statusEntry?.status : defaultStatus}
    >
      {children}
    </ServiceCard>
  </ExplanationTooltip>
}

export default LumenEntryCard
