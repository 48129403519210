import { css } from "@emotion/react";
import styled from "@emotion/styled";

import Icon from "shared/components/Icon";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

const Container = styled("div")`
  display: flex;
  align-items: center;
  ${MEDIA_QUERIES.w.ltMd} {
    align-items: flex-start;
  }
`;

const PropertyIcon = styled(Icon.MyPropertyBoldIcon)``;

const Details = styled("div")<{ horizontal?: boolean }>`
  margin-left: 7px;
  display: flex;
  flex-direction: column;
  ${MEDIA_QUERIES.w.gteXxl} {
    ${(props) =>
      props.horizontal === true &&
      css`
        flex-direction: row;
      `}
`;

const Name = styled("h4")<{ horizontal?: boolean }>`
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0;
  ${MEDIA_QUERIES.w.gteXxl} {
    ${(props) =>
      props.horizontal === true &&
      css`
        &::after {
          content: ",";
          margin-right: 0.3rem;
        }
      `}
  },
`;

const Address = styled("h5")`
  font-size: 15px;
  font-weight: 300;
  color: ${COLORS.secondary};
  margin-bottom: 0;
`;

const FallbackContent = styled("h5")`
  font-size: "15px",
  font-weight: 300;
  color: ${COLORS.secondary};
  margin-bottom: 0;
  `;

export { Container, PropertyIcon, Details, Name, Address, FallbackContent };
