import React, { useEffect, useState } from "react";

import isEqual from "lodash/isEqual";

import { useEmployeeExport, useGetEmployees } from "manager/hooks/api";
import { EmployeesWithCount } from "manager/interfaces/api/employee";
import { DEFAULT_PAGINATION } from "shared/config/constants";
import { useGetRoles } from "shared/hooks/api";
import { BackLinkProps } from "shared/interfaces";

import Employees from "./Employees";
import { SearchParams } from "./interface";

interface EmployeesContainerProps {
  backLink: BackLinkProps;
}

const EmployeesContainer = ({ backLink }: EmployeesContainerProps) => {
  const {
    data: employeeRolesDropdown,
    isLoading: isEmployeeRolesDropdownLoading,
  }: { data: { id: number; name: string }[]; isLoading: boolean } =
    useGetRoles();

  const blankFilter = {
    nameOrEmail: "",
    roleId: null,
    activeOnly: false,
    ...DEFAULT_PAGINATION,
  };
  const employeeRoles = [
    { label: "All roles", key: "" },
    ...(employeeRolesDropdown?.map((role) => ({
      label: role.name,
      key: role.id,
    })) || []),
  ];

  const [hasInputValue, setHasInputValue] = useState(false);
  const [searchParams, setSearchParams] = useState<SearchParams>(blankFilter);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const { exportEmployees, isExportingEmployees } = useEmployeeExport();
  const {
    employees: employeesData,
    isEmployeesLoading,
    isEmployeesFetching,
    refetchEmployees,
  } = useGetEmployees({
    ...searchParams,
    activeOnly: searchParams.activeOnly || undefined,
  });

  const { count: employeesCount, results: employees } =
    (employeesData as EmployeesWithCount) || {};

  const handleSearchParams = (key: string, value: string) => {
    let newFilters = blankFilter;
    if (!key) {
      setPagination(DEFAULT_PAGINATION);
    } else {
      if (key === "nameOrEmail") {
        setHasInputValue(!!value);
      }
      newFilters = { ...searchParams, [key]: value, ...DEFAULT_PAGINATION };
      setSearchParams(newFilters);
      setPagination(DEFAULT_PAGINATION);
      return;
    }
    setSearchParams(newFilters);
  };

  const handleAllSearchParams = (filters) => {
    setSearchParams({ ...searchParams, ...filters });
  };

  const onPaginationChange = () => {
    const newFilters = { ...searchParams, ...pagination };
    setSearchParams(newFilters);
  };

  useEffect(() => {
    refetchEmployees();
  }, [searchParams]);

  useEffect(() => {
    onPaginationChange();
  }, [pagination]);

  const disableSerchAndFilters =
    (employeesCount === 0 && isEqual(searchParams, blankFilter)) ||
    isEmployeesLoading ||
    isEmployeesFetching;

  return (
    <Employees
      isEmployeeRolesDropdownLoading={isEmployeeRolesDropdownLoading}
      employeesCount={employeesCount}
      handleSearchParams={handleSearchParams}
      searchParams={searchParams}
      disableSerchAndFilters={disableSerchAndFilters}
      employeeRoles={employeeRoles}
      handleAllSearchParams={handleAllSearchParams}
      exportEmployees={exportEmployees}
      isExportingEmployees={isExportingEmployees}
      hasInputValue={hasInputValue}
      setPagination={setPagination}
      pagination={pagination}
      backLink={backLink}
      isEmployeesLoading={isEmployeesLoading || isEmployeesFetching}
      employees={employees}
    />
  );
};

export default EmployeesContainer;
