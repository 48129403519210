import React from "react";

import capitalize from "lodash/capitalize";

import Icon from "shared/components/Icon";
import { Modal } from "shared/components/Modal";

import { YardiCredentialEditWarningModalProps } from "./interfaces";
import { YardiCredentialEditWarningContent } from "./styled";

const YardiCredentialEditWarningModal = ({
  onSubmit,
  onCancel,
  provider,
}: YardiCredentialEditWarningModalProps) => {
  const title = capitalize(provider);
  return (
    <Modal
      showSubmitButton
      showCancelLink
      closeOnSubmit
      onCancelLinkClick={onCancel}
      submit={onSubmit}
      showTitle
      title={`Edit ${title} credentials?`}
      submitButtonLabel="CONFIRM & EDIT"
    >
      <Modal.Body noPaddingTop noPaddingBottom>
        <YardiCredentialEditWarningContent>
          <Icon.OrangeExclamation
            data-testid="credentials-warning-icon"
            className="credentials-warning-icon"
          />
          <p className="edit-message">
            Editing {title} credentials could impact your {title} integration
            and should only be done when absolutely necessary. There is a
            possibility that all previous configurations could be lost. Are you
            sure you would like to proceed?
          </p>
        </YardiCredentialEditWarningContent>
      </Modal.Body>
    </Modal>
  );
};

export default YardiCredentialEditWarningModal;
