import styled from "@emotion/styled";

const BackgroundContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  img {
    max-height: 100%;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &:first-of-type {
      z-index: 1;
    }
  }
`;

export { BackgroundContent };
