import React from "react";

import { queryCache, useMutation } from "react-query";

import ROUTES from "manager/config/routes";
import api from "manager/lib/api";
import { NOTIFICATIONS } from "shared/config/constants";
import { openNotification } from "shared/utils/ui";
import { getAccessToken } from "shared/utils/auth";

const DownloadCenterLink = React.createElement(
  "a",
  { href: ROUTES.downloadCenter },
  "Click here to see it"
);

const DownloadSuccessMessage = React.createElement(
  React.Fragment,
  {},
  "Download has been successfully requested! ",
  DownloadCenterLink
);

export const useRequestExport = (mutateFn, failureMessage) => {
  const [mutate, { isLoading }] = useMutation(mutateFn, {
    onSuccess: async () => {
      if (getAccessToken()) {
        await queryCache.invalidateQueries(["getDownloads"]);
        // Await to reload manager download center flag
        await queryCache.fetchQuery(["getManagerProfile"], () =>
          api.getManagerProfile()
        );
        openNotification(DownloadSuccessMessage, NOTIFICATIONS.info);
      }
    },
    onError: () => {
      openNotification(failureMessage, NOTIFICATIONS.error);
    },
  });

  return { mutate, isLoading };
};
