import React, { ReactNode } from "react";

import DealSetupForm from "manager/components/DealSetupSettings/DealSetupForm";
import {
  AdditionalQuestionModal,
  DealSetupAttachmentsModal,
} from "manager/components/Modal";
import {
  useGetDefaultDealSetup,
  useUpdateDealSetup,
} from "manager/hooks/api/dealSetupQueries";
import { CONTENT_TYPE } from "shared/config/constants";
import useModal from "shared/hooks/useModal";

interface DealSetupContainerProps {
  children: ReactNode[];
}

const DealSetupContainer = ({ children }: DealSetupContainerProps) => {
  const { openModalDialog } = useModal();

  const {
    defaultDealSetup,
    isDefaultDealSetupLoading,
    isDefaultDealSetupFetching,
  } = useGetDefaultDealSetup();

  const { updateDealSetup } = useUpdateDealSetup();

  const saveChanges = (
    values: { id: number; data: any },
    resolve: () => void,
    reject: () => void
  ) => {
    updateDealSetup(
      // @ts-ignore
      { id: values.id, dealSetup: values.data },
      { onSuccess: resolve, onError: reject }
    );
  };

  const openQuestionsModal = (context) =>
    openModalDialog(AdditionalQuestionModal, {
      forSettingsLabel: "Company Settings",
      contentType: CONTENT_TYPE.dealSetup,
      ...context,
    });

  const openAttachmentsModal = ({ items, dealSetupId, title, newItemProps }) =>
    openModalDialog(DealSetupAttachmentsModal, {
      title,
      items,
      objectId: dealSetupId,
      forSettingsLabel: "Company Settings",
      contentType: CONTENT_TYPE.dealSetup,
      newItemProps,
    });

  return (
    // @ts-ignore TODO remove this when DealSetupForm is converted to ts
    <DealSetupForm
      forSettingsLabel="Company Settings"
      saveChanges={saveChanges}
      dealSetup={defaultDealSetup}
      loading={isDefaultDealSetupLoading}
      fetching={isDefaultDealSetupFetching}
      openQuestionsModal={openQuestionsModal}
      openAttachmentsModal={openAttachmentsModal}
    >
      {children}
    </DealSetupForm>
  );
};

export default DealSetupContainer;
