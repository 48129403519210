import styled from "@emotion/styled";

import { MEDIA_QUERIES } from "shared/config/constants";

const FiltersContainer = styled.div`
  ${MEDIA_QUERIES.w.ltSm} {
    padding: 0 25px 10px 25px;
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
  }
`;

const ActionButtonWrapper = styled.div`
  margin-left: auto;
`;

const MobileActionWrapper = styled.div`
  margin: 10px 0;
  width: 50%;
`;

export { FiltersContainer, ActionButtonWrapper, MobileActionWrapper };
