import React from "react";

import { colors, COLORS } from "shared/config/constants";

import FlexContainer from "../FlexContainer";

import { CardSize, CardStatus } from "./config";
import {
  ActionLogo,
  ApplicationSummaryCardContainer,
  InnerContainer,
  LeftIndicator,
  Subtitle,
  Title,
  TitleLogo,
} from "./styled";

interface ApplicationSummaryCardProps {
  size: CardSize;
  status: CardStatus;
  logo?: React.ReactNode;
  title: string | number;
  subtitle?: string;
  actionLogo?: React.ReactNode;
  hideColor?: boolean;
  onClick?: () => void;
}

const ApplicationSummaryCard = ({
  size,
  status,
  logo,
  title,
  subtitle,
  actionLogo,
  hideColor,
  onClick,
}: ApplicationSummaryCardProps) => {
  const isLarge = size === CardSize.Large;
  const isSmall = size === CardSize.Small;

  const colorIndicator = () => {
    if (hideColor) {
      return null;
    }
    switch (status) {
      case CardStatus.lumenSuccess:
        return colors.success[400];
      case CardStatus.success:
        return isLarge ? COLORS.violetTheme.lightGreen : COLORS.lightGreen;
      case CardStatus.lumenGood:
        return colors.secondary[400];
      case CardStatus.warn:
        return COLORS.orange;
      case CardStatus.error:
        return COLORS.lightRed2;
      case CardStatus.unavailable:
        return COLORS.darkPurple;
      default:
        return COLORS.violetTheme.lightGreen;
    }
  };

  return (
    <ApplicationSummaryCardContainer
      small={isSmall}
      onClick={() => onClick?.()}
    >
      <LeftIndicator color={colorIndicator()} />
      <InnerContainer justifyContent="space-between" logo={!!logo}>
        {logo ? (
          <FlexContainer alignItems="center">
            {logo}
            <TitleLogo>{title}</TitleLogo>
          </FlexContainer>
        ) : (
          <FlexContainer flexDirection="column">
            {isLarge && <Title>{title}</Title>}
            {isSmall && <Subtitle extraStrong>{title}</Subtitle>}
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
          </FlexContainer>
        )}

        <FlexContainer
          flexDirection="column"
          justifyContent="space-between"
          data-testid="right-container"
        >
          <ActionLogo>{actionLogo}</ActionLogo>
        </FlexContainer>
      </InnerContainer>
    </ApplicationSummaryCardContainer>
  );
};

export default ApplicationSummaryCard;
