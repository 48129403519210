import React, { useContext } from "react";

import styled from "@emotion/styled";

import PropTypes from "prop-types";

import { useRequestSurvey } from "manager/hooks/api";
import Tile from "shared/components/Tile";
import {
  AccordionVerificationStatus,
  NoVerificationsPlaceholder,
  VerificationOfResidencyDetails,
} from "shared/components/Verification";
import { VERIFICATION_ACCORDION_FOR_STATUS } from "shared/components/Verification/utils";
import { VERIFICATION_TYPE } from "shared/config/constants";
import { verificationPropType } from "shared/utils/propTypes";

import applicationContext from "../applicationContext";

import { TileCustom } from "./styled";

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;

const VorVerifications = ({ verifications }) => {
  const { application } = useContext(applicationContext);
  const applicationId = application?.id;
  const { requestSurvey, isRequestingSurvey } = useRequestSurvey(applicationId);

  const handleRequestSurvey = () => {
    const verificationOfResidency = verifications.find(
      (verification) => verification.verificationType === VERIFICATION_TYPE.VOR
    );
    requestSurvey(verificationOfResidency?.details?.survey?.id);
  };

  const renderVerification = (verification) => {
    return (
      <Tile.Inner>
        <VerificationOfResidencyDetails
          verification={verification}
          onRequestSurveyClick={handleRequestSurvey}
          isRequestingSurvey={isRequestingSurvey}
        />
      </Tile.Inner>
    );
  };

  const dropHeader = (verification, verificationType) => {
    return (
      <HeaderContainer>
        <HeaderLeft className="main-header-left vor">
          <AccordionVerificationStatus
            type={verificationType}
            status={verification.status || verification.attributes?.status}
          />
        </HeaderLeft>
      </HeaderContainer>
    );
  };

  const verificationContent = ({ verification, verificationType }) => {
    return (
      <TileCustom
        className={
          VERIFICATION_ACCORDION_FOR_STATUS[
            verification.status || verification.attributes?.status
          ]
        }
        header={{
          title: dropHeader(verification, verificationType),
          collapsibleOnDesktop: true,
          collapsibleOnMobile: true,
        }}
      >
        <Tile key={0} noBorder noMarginBottom>
          {renderVerification(verification)}
        </Tile>
      </TileCustom>
    );
  };

  const vorVerification = verifications?.find(
    (verification) => verification.verificationType === VERIFICATION_TYPE.VOR
  );

  return (
    <>
      {(!verifications || !vorVerification) && (
        <NoVerificationsPlaceholder message="No Residency Verifications Available" />
      )}
      {vorVerification && (
        <>
          {verificationContent({
            verification: vorVerification,
            verificationType: VERIFICATION_TYPE.VOR,
          })}
        </>
      )}
    </>
  );
};

VorVerifications.propTypes = {
  verifications: PropTypes.arrayOf(verificationPropType).isRequired,
};

export default VorVerifications;
