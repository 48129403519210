import React, { ReactNode } from "react";

import { CheckboxChangeEvent } from "antd/lib/checkbox";

import FlexContainer from "shared/components/FlexContainer";
import { Checkbox } from "shared/components/Form/Checkbox";
import {
  RadioGroup,
  RadioGroupOptions,
} from "shared/components/Form/RadioGroup";
import Icon from "shared/components/Icon";
import Tooltip from "shared/components/Tooltip";
import Well from "shared/components/Well";

import { CHECKBOX_WITH_UNORDERED_LIST_CHILD_TYPE } from "./constants";
import {
  CheckboxContainer,
  CheckboxLabel,
  RadioGroupContainer,
  Li,
  Ul,
} from "./styled";

type ChildProps = {
  id: string;
  type?: CHECKBOX_WITH_UNORDERED_LIST_CHILD_TYPE;
  label?: ReactNode;
  // checkbox props
  checked?: boolean;
  checkedContent?: ReactNode;
  extraInfo?: string;
  // radio group props
  options?: RadioGroupOptions[];
  extraContent?: ReactNode;
};

type CheckboxChildItemProps = {
  child: ChildProps;
  disabled?: boolean;
  handleOnItemChildChange: (e: CheckboxChangeEvent, id: string) => void;
};

type RadioChildItemProps = {
  child: ChildProps;
  disabled?: boolean;
};

export type CheckboxWithUnorderedListProps = {
  id: string;
  label: ReactNode;
  value?: boolean;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
  onItemChildChange?: (id: string, checked: boolean) => void;
  items?: {
    id: string;
    label: ReactNode;
    disabled?: boolean;
    children?: ChildProps[];
  }[];
};

const CheckboxChildItem = ({
  child,
  disabled = false,
  handleOnItemChildChange,
}: CheckboxChildItemProps) => {
  return (
    <>
      <CheckboxLabel alignItems="center">
        <Checkbox
          id={child.id}
          checked={child.checked}
          label={child.label}
          onChange={(e) => handleOnItemChildChange(e, child.id)}
          data-testid={child.id}
          disabled={disabled}
        />
        {child.extraInfo && (
          <Tooltip
            theme="light"
            title={<Well noBorder>{child.extraInfo}</Well>}
          >
            <Icon.InfoIcon className="extra-info" />
          </Tooltip>
        )}
      </CheckboxLabel>
      {child.checked && child.checkedContent}
    </>
  );
};

const RadioChildItem = ({ child, disabled = false }: RadioChildItemProps) => {
  // TODO igeshosk: refactor to use the base RadioGroup (without Formik) when merged to "next"
  // PR: https://bitbucket.org/sayvero/vero-front-end/pull-requests/1644#Lsrc/shared/components/Form/RadioGroup/RadioGroup.jsxF1
  return (
    <>
      <RadioGroupContainer>
        <RadioGroup
          id={child.id}
          label={child.label}
          options={child.options}
          disabled={disabled}
          block
        />
      </RadioGroupContainer>
      {child.extraContent}
    </>
  );
};

export const CheckboxWithUnorderedList = ({
  id,
  label,
  items = [],
  onChange,
  onItemChildChange,
  value = false,
  disabled = false,
}: CheckboxWithUnorderedListProps) => {
  const handleOnChange = ({ target: { checked } }: CheckboxChangeEvent) =>
    onChange(checked);
  const handleOnItemChildChange = (
    { target: { checked } }: CheckboxChangeEvent,
    subItemId
  ) => onItemChildChange(subItemId, checked);
  const showItems = value && items?.length;

  return (
    <>
      <CheckboxContainer isCheckboxEnable={value && items?.length > 0}>
        <Checkbox
          id={id}
          data-testid={id}
          checked={value}
          label={label}
          disabled={disabled}
          onChange={handleOnChange}
        />
      </CheckboxContainer>
      {showItems > 0 && (
        <Ul>
          {items.map((item, index) => {
            const hasChildren = item.children?.length > 0;

            return (
              <Li key={item.id} isLastItem={items.length === index + 1}>
                <FlexContainer flexWrap="wrap">{item.label}</FlexContainer>

                {hasChildren &&
                  item.children.map((child) => {
                    const childType =
                      child.type ||
                      CHECKBOX_WITH_UNORDERED_LIST_CHILD_TYPE.CHECKBOX;
                    return (
                      <React.Fragment key={child.id}>
                        {childType ===
                          CHECKBOX_WITH_UNORDERED_LIST_CHILD_TYPE.CHECKBOX && (
                          <CheckboxChildItem
                            disabled={item.disabled}
                            child={child}
                            handleOnItemChildChange={handleOnItemChildChange}
                          />
                        )}
                        {childType ===
                          CHECKBOX_WITH_UNORDERED_LIST_CHILD_TYPE.RADIO && (
                          <RadioChildItem
                            child={child}
                            disabled={item.disabled}
                          />
                        )}
                      </React.Fragment>
                    );
                  })}
              </Li>
            );
          })}
        </Ul>
      )}
    </>
  );
};
