import pluralize from "pluralize";
import * as Yup from "yup";

import ROUTES from "manager/config/routes";
import { CreateUnitListingValidationSchema } from "manager/utils/validationSchemas";
import { NOTIFICATIONS } from "shared/config/constants";
import { ValidationSchema } from "shared/forms/InviteApplicantsForm";
import api from "shared/lib/api";
import * as dealApi from "shared/lib/api/dealApi";
import { transformRoute } from "shared/utils/routing";
import { openNotification } from "shared/utils/ui";

export const MODES = {
  TO_UNIT: "TO_UNIT",
  WITHOUT_UNIT: "WITHOUT_UNIT",
};

export const getLeadSourceSchema = (leadSourceRequired) =>
  Yup.object().shape({
    leadSource: Yup.string().concat(
      // @ts-ignore
      leadSourceRequired
        ? Yup.string().required("Lead Source is required")
        : null
    ),
  });

const ToUnitValidationSchema = Yup.object().shape({
  property: Yup.number().nullable().required("Property is required"),
  unit: Yup.string().required("Unit is required"),
  unitListingRequired: Yup.bool().required(),
  unitListing: Yup.object().when("unitListingRequired", {
    is: true,
    then: CreateUnitListingValidationSchema,
  }),
});

export const getValidationSchema = (mode, leadSourceRequired) => {
  let schema = Yup.object().shape({
    ...ValidationSchema,
  });

  if (mode === MODES.TO_UNIT) {
    schema = schema.concat(ToUnitValidationSchema);
  }

  return schema.concat(getLeadSourceSchema(leadSourceRequired));
};

const inviteApplicantsToDeal = async ({
  dealId,
  applicants,
  unitId,
  noListingUnit,
  leadSource,
  externalPMSId,
}) => {
  let response;

  if (dealId) {
    response = dealApi.inviteApplicantsToDeal({ applicants, id: dealId });
  } else {
    let unitListing;

    if (unitId) {
      const unitResponse = await api.getUnit({ id: unitId });
      const hasLroEnabled = unitResponse?.data?.hasLroEnabled;
      const unitListings = unitResponse?.data?.unitListings || [];
      const revMgmtUnitListings = unitListings.filter(
        (unitListing) => !unitListing.isCurrent
      );

      if (!hasLroEnabled || revMgmtUnitListings.length === 0) {
        unitListing = unitResponse?.data?.unitListingCurrent;
      }
    }
    response = dealApi.inviteApplicants({
      applicants,
      unitListing: unitListing?.id,
      unit: unitId,
      noListingUnit,
      leadSource,
      externalPMSId,
    });
  }

  return response;
};

export const inviteApplicants = ({
  values,
  resolve = null,
  reject,
  history,
  closeActiveModalDialog,
}) => {
  const { applicants, unitId, dealId, unitListing, leadSource, externalPMSId } =
    values;
  let noListingUnit;
  if (unitListing?.rent && unitListing?.unitAvailableOn) {
    noListingUnit = { ...unitListing };
  }
  inviteApplicantsToDeal({
    dealId,
    applicants,
    unitId,
    noListingUnit,
    leadSource,
    externalPMSId,
  })
    .then((response) => {
      if (response.data) {
        history.push(transformRoute(ROUTES.deal, { id: response.data?.id }));
        closeActiveModalDialog();
        openNotification(
          `${pluralize("Applicant", applicants.length)} successfully invited`,
          NOTIFICATIONS.info
        );
        resolve?.();
      }
    })
    .catch((error) => {
      reject(error);
    });
};
