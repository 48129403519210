import React from "react";

import { PrescreeningSettings } from "manager/components/Prescreening";
import {
  useCopyPrescreeningDealSetupFromPortfolio,
  useResetToPrescreeningPortfolioSettings,
} from "manager/hooks/api";
import { PropertySettings } from "manager/interfaces/api";
import Banner from "shared/components/Banner";
import Icon from "shared/components/Icon";
import ConfirmationModal from "shared/components/Modals/ConfirmationModal";
import useModal from "shared/hooks/useModal";

interface PropertyPrescreeningSettingsProps {
  propertySettings: PropertySettings;
}

const PropertyPrescreeningSettings = ({
  propertySettings,
}: PropertyPrescreeningSettingsProps) => {
  const { openModalDialog } = useModal();

  const propertyId = propertySettings?.id;
  const propertyName = propertySettings?.name;
  const portfolioName = propertySettings?.portfolioName;
  const isPrescreeningDealSetupFromPortfolio =
    propertySettings?.isPrescreeningDealSetupFromPortfolio;

  const { copyPrescreeningDealSetupFromPortfolio } =
    useCopyPrescreeningDealSetupFromPortfolio();

  const { resetToPrescreeningPortfolioSettings } =
    useResetToPrescreeningPortfolioSettings();

  const openCustomizeSettingsModal = () => {
    // @ts-ignore
    return openModalDialog(ConfirmationModal, {
      title: "You’re going to update prequalification property settings",
      subtitle: (
        <span>
          Property: <strong>{propertyName}</strong>
        </span>
      ),
      submitButtonLabel: "Confirm & Continue",
      message: (
        <>
          <strong>Please note:</strong> Once you click &quot;Confirm and
          Continue&quot; new prequalification settings will be created for this
          specific property and the portfolio setup will no longer apply.
        </>
      ),
      // @ts-ignore
      onConfirm: () => copyPrescreeningDealSetupFromPortfolio(propertyId),
    });
  };

  const openResetSettingsModal = () => {
    // @ts-ignore
    return openModalDialog(ConfirmationModal, {
      title: "Reset to portfolio prequalification settings?",
      subtitle: (
        <span>
          Portfolio: <strong>{portfolioName}</strong>
        </span>
      ),
      submitButtonLabel: "Reset",
      message: (
        <>
          <strong>Please note:</strong> Once you click &quot;Reset&quot; the
          property prequalification settings will be overridden with the current
          portfolio setup and the changes you previously made will no longer
          apply.
        </>
      ),
      // @ts-ignore
      onConfirm: () => resetToPrescreeningPortfolioSettings(propertyId),
    });
  };

  const onSubmit = isPrescreeningDealSetupFromPortfolio
    ? openCustomizeSettingsModal
    : null;

  const submitButtonLabel = isPrescreeningDealSetupFromPortfolio
    ? "Update settings"
    : "Save Changes";

  const onSecondaryButtonClick = !isPrescreeningDealSetupFromPortfolio
    ? openResetSettingsModal
    : null;

  const settingsSourceIndicator = isPrescreeningDealSetupFromPortfolio
    ? `${portfolioName} settings are active for prequalification`
    : `Custom settings specific to prequalification are applied and refer to ${portfolioName}`;

  return (
    <>
      <Banner Icon={Icon.InfoIcon} message={settingsSourceIndicator} />
      <PrescreeningSettings
        dealSetupId={propertySettings?.prescreeningDealSetupId}
        disabled={isPrescreeningDealSetupFromPortfolio}
        onSubmit={onSubmit}
        submitButtonLabel={submitButtonLabel}
        showSecondaryButton={!isPrescreeningDealSetupFromPortfolio}
        onSecondaryButtonClick={onSecondaryButtonClick}
        secondaryButtonLabel="Reset to portfolio settings"
      />
    </>
  );
};

export default PropertyPrescreeningSettings;
