import React from "react";

import includes from "lodash/includes";
import slice from "lodash/slice";

import DropdownMenu from "shared/components/DropdownMenu";

import StarButton from "../StarButton";

export type AntDFormattedRow = {
  key: string;
  rawData: any;
  primaryAction?: any;
  disabled?: boolean;
  dropdown?: any;
  starButton?: any;
};

const generateEmptyColumn = (name, extraAttrs) => ({
  title: "",
  dataIndex: name,
  key: name,
  ...extraAttrs,
});

const filterColumnsByKeys = (columns, keys) =>
  columns.filter(({ key }) => includes(keys, key));

const buildResponsiveColumns = (allColumns, breakpoint, columnBreakpoints) => {
  let responsiveColumns;
  const columnsForBreakpoint = columnBreakpoints[breakpoint];

  if (columnBreakpoints && columnsForBreakpoint !== undefined) {
    responsiveColumns = filterColumnsByKeys(allColumns, [
      "starButton",
      "dropdown",
      "expand",
      "primaryAction",
      ...columnsForBreakpoint,
    ]);
  } else {
    responsiveColumns = allColumns;
  }

  return responsiveColumns;
};

export const buildColumns = ({
  columns,
  breakpoint,
  columnBreakpoints,
  tableHasPrimaryAction,
  tableHasDropdownItems,
  tableHasStarButtons,
  tableHasExpandButton,
}) => {
  let builtColumns = slice(columns, 0);

  if (tableHasPrimaryAction) {
    builtColumns.push(generateEmptyColumn("primaryAction", { width: 250 }));
  }

  if (tableHasStarButtons) {
    builtColumns.unshift(generateEmptyColumn("starButton", { width: 0 }));
  }

  if (tableHasDropdownItems) {
    builtColumns.push(
      generateEmptyColumn("dropdown", { className: "dropdown-cell", width: 0 })
    );
    if (tableHasExpandButton) {
      builtColumns.push(
        generateEmptyColumn("expand", {
          className: "expand-button-cell",
          width: 0,
        })
      );
    }
  }

  builtColumns = buildResponsiveColumns(
    builtColumns,
    breakpoint,
    columnBreakpoints
  );

  return builtColumns;
};

export const buildRows = ({
  rows,
  columns,
  dropdownItems,
  starButtonOnClick,
}) =>
  rows?.map((row) => {
    const antDFormattedRow: AntDFormattedRow = {
      key: row.key,
      rawData: row.rawData,
    };

    row.values.forEach((value, index) => {
      const { key: columnKey } = columns[index];
      antDFormattedRow[columnKey] = value;
    });

    if (row.primaryAction !== undefined) {
      antDFormattedRow.primaryAction = row.primaryAction;
    }

    if (row.disabled !== undefined) {
      antDFormattedRow.disabled = row.disabled;
    }

    if (dropdownItems !== undefined || row.dropdownItems) {
      const dropdown = row.dropdownItems || dropdownItems;
      const items = dropdown.map((item) => ({
        ...item,
        onClick: () => item.onClick(row.key),
      }));

      antDFormattedRow.dropdown = <DropdownMenu items={items} />;
    }

    if (starButtonOnClick !== undefined) {
      antDFormattedRow.starButton = (
        <StarButton
          onClick={() => starButtonOnClick(row.key)}
          active={row.starButtonIsActive}
        />
      );
    }

    return antDFormattedRow;
  });

export const buildMobileExpandedRow = ({ row, columns, expandable }) => {
  const columnKeys = columns.map((col) => col.dataIndex);
  const data = expandable.map((key) => {
    const valueIndex = columnKeys.indexOf(key);
    if (valueIndex === -1) return {};
    const { title } = columns.find((col) => col.dataIndex === key);
    return {
      label: title,
      value: row.values[valueIndex],
    };
  });

  return data;
};

export const buildMobileRows = ({
  rows,
  columns,
  columnBreakpoints,
  expandable,
}) =>
  rows.map((row) => {
    const columnKeys = columns.map((col) => col.dataIndex);
    const firstFieldKey = columnBreakpoints.xs.column1;
    const firstFieldIndex = columnKeys.indexOf(firstFieldKey);
    const firstFieldValue = row.values[firstFieldIndex];
    const secondFieldIndex = columnKeys.indexOf(columnBreakpoints.xs.column2);
    const secondFieldValue = row.values[secondFieldIndex];
    const thirdFieldIndex = columnKeys.indexOf(columnBreakpoints.xs.column3);
    const thirdFieldValue = row.values[thirdFieldIndex];
    const expandedRow = expandable
      ? buildMobileExpandedRow({ row, columns, expandable })
      : null;
    const dropdown = row.dropdownItems
      ? row.dropdownItems.map((item) => ({
          ...item,
          onClick: () => item.onClick(row.key),
        }))
      : null;

    return {
      key: row.key,
      firstFieldValue,
      secondFieldValue,
      thirdFieldValue,
      primaryAction: row.primaryAction,
      inlinePrimaryAction: row.inlinePrimaryAction,
      inlinePrimaryActionSpan: row.inlinePrimaryActionSpan,
      starButtonIsActive: row.starButtonIsActive,
      viewed: row.viewed,
      dropdownItems: dropdown,
      expandedRow,
    };
  });

export const filterCheckboxActions = (actions, selectedRowCount) => {
  let filteredActions;

  if (selectedRowCount > 1) {
    filteredActions = actions.filter(
      (action) => action.singleSelectionOnly !== true
    );
  } else {
    filteredActions = actions;
  }

  return filteredActions;
};
