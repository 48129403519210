import React from "react";

import {
  Container,
  PropertyIcon,
  Details,
  Name,
  Address,
  FallbackContent,
} from "./styled";

export interface PropertyProps {
  fallbackContent: string;
  name?: string;
  unit?: string;
  address?: string;
  horizontal?: boolean;
  [key: string]: any;
}

const Property = ({
  fallbackContent,
  name = undefined,
  unit = undefined,
  address = undefined,
  horizontal = false,
}: PropertyProps) => {
  const propertyUnitName = [name, unit].filter((item) => item).join(", ");

  return (
    <Container data-testid="property-info">
      <PropertyIcon data-testid="property-icon" />
      <Details horizontal={horizontal}>
        {propertyUnitName ? (
          <>
            <Name horizontal={horizontal}>{propertyUnitName}</Name>
            <Address>{address}</Address>
          </>
        ) : (
          <FallbackContent>{fallbackContent}</FallbackContent>
        )}
      </Details>
    </Container>
  );
};

export default Property;
