import { useContext } from "react";

import { ModalManagerContext, ModalManager } from "shared/utils/modalmanager";

const SELF_API_ERROR =
  "SelfApi available only in modals opened with the ModalManager";

/**
 * The useModal is a hook for working with modals.
 * The same hook is to be used for external consumers, invoking the modal,
 * but also inside the modal components.
 * It works in 2 modes, the <b>old/Redux</b> mode and the <b>new/ModalManager</b> mode.
 * @returns {{
 *    currentModalContext: *,
 *    currentModalId: string,
 *    selfApi: {
 *      closeDialog: (function(): *),
 *      closeDialogWithValue: (function(*): *),
 *      setClosingValue: selfApi.setClosingValue
 *    },
 *    openModalDialog: (function(any, any=): ({closeDialog: function(): void, updateContext: function(*=): void, id: *, afterClose: Promise})),
 *    closeActiveModalDialog: (function(): *),
 *    destroyActiveModalDialog: (function(): *)
 *   }}
 *  Return object description:
 *  - currentModalContext: available only inside a modal component. Contains the context data for the current modal
 *  - currentModalId: available only inside a modal component. The id of the current modal.
 *  - selfApi: available only inside a modal component. The API for the current modal, containing <i>closeDialog</i> and <i>setClosingValue</i>.
 *   The closing value will be used as the resolution value of the <i>afterClose</i> promise.
 *   The <i>setClosingValue</i> is available only if the modal is opened with in the "ModalManager" mode.
 *  - openModalDialog: available everywhere (in modals and outside)
 *  - closeActiveModalDialog: closes all active modal dialogs
 *  - destroyActiveModalDialog: destroys all active modal dialogs
 */
const useModal = () => {
  let currentModalContext;
  let currentModalId;

  const closeActiveModalDialog = () => {
    ModalManager.closeAll();
  };
  const destroyActiveModalDialog = () => {
    ModalManager.destroyAll();
  };

  let selfApi = {
    closeDialog: closeActiveModalDialog,
    destroyDialog: destroyActiveModalDialog,
    closeDialogWithValue: () => {
      throw new Error(SELF_API_ERROR);
    },
    setClosingValue: () => {
      throw new Error(SELF_API_ERROR);
    },
  };

  // NOTE: If the Modal was opened from the ModalManager, we should use a different API
  const modalManagerContext = useContext(ModalManagerContext);
  const fromModalManager = modalManagerContext?.fromModalManager;
  if (fromModalManager) {
    currentModalContext = modalManagerContext.context;
    currentModalId = modalManagerContext.modalId;
    selfApi = modalManagerContext.selfApi;
  }

  /**
   * Opens a modal by using the provided idOrComponent.
   * @param idOrComponent the id as a string or the actual function component. If it's a string, then the old/Redux mode is used, if it's something else, the new/ModalManager mode is used
   * @param context the context data for the modal to be opened
   * @param closeOtherModals closes other opened modals (default: true). Available only when opening a modal with the ModalManager
   * @returns {
   *  {
   *    closeDialog: (function(): void),
   *    destroyDialog: (function(): void),
   *    updateContext: (function(*=): void),
   *    id: *,
   *    afterClose: Promise
   *  }
   *}
   *
   * Return description:
   *  - closeDialog: closes the current modal
   *  - destroyDialog: destroys the current modal
   *  - updateContext: updates the context data of the current modal
   *  - id: the id of the current modal
   *  - afterClose <b>(only for ModalManager)</b>: promise to be resolved on closing the modal (the value will be the <i>closingValue</i>)
   */
  const openModalDialog = (idOrComponent, context, closeOtherModals = true) => {
    // NOTE: if the value is NOT a string, we use the new way from the ModalManager
    return ModalManager.openModal(idOrComponent, context, closeOtherModals);
  };

  return {
    openModalDialog,
    closeActiveModalDialog,
    destroyActiveModalDialog,
    currentModalContext,
    currentModalId,
    selfApi,
  };
};

export default useModal;
