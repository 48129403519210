import { SubmitDistribution } from "manager/interfaces/api/dashboard";
import Icon from "shared/components/Icon";
import { RegularText } from "shared/components/Typography";
import { printDollars } from "shared/utils/dollar-print";
import { EmptyChartFlexContainer } from "./styled";

// Checks if there is any value in chartData
export const isChartEmpty = (
  chartData: {
    name: string;
    [key: string]: number | string;
  }[]
) =>
  !chartData
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ?.map(({ name, ...keepAttrs }) => keepAttrs)
    .some((item) => Object.keys(item).some((key) => item[key]));

export const EmptyChart = () => (
  <EmptyChartFlexContainer
    alignItems="center"
    justifyContent="center"
    flexDirection="column"
  >
    <div className="icons">
      <Icon.SmallBarChart />
      <Icon.PieChart />
    </div>
    {/* @ts-ignore */}
    <RegularText light>
      Sufficient data is unavailable; this chart will display as information is
      collected
    </RegularText>
  </EmptyChartFlexContainer>
);

// Format submitDistribution keys to match the chart labels
export const formatSubmitDistributionItems = (
  submitDistribution: SubmitDistribution[]
) => {
  return submitDistribution.map((item) => ({
    name: item.name,
    "12 AM-6 AM": item.hourSegment0,
    "6 AM-12 PM": item.hourSegment1,
    "12 PM-6 PM": item.hourSegment2,
    "6 PM-12 AM": item.hourSegment3,
  }));
};

export const formatDashboardPercent = (value: number | null) => {
  if (value || value === 0) {
    return `${value.toFixed(0)}%`;
  }
  return "-- %";
};

export const formatDashboardMoney = (value: number | null) => {
  if (value || value === 0) {
    return printDollars(value);
  }
  return "$ --";
};

export const DASHBOARD_DEFAULT_PERIOD = 365;
