import React from "react";

import { useGetIntegrationStatuses } from "manager/hooks/api";
import { IntegrationProvider } from "shared/config/constants";
import usePermissions from "shared/hooks/usePermissions";

import IntegrationSyncStatus from "../IntegrationStatus/IntegrationSyncStatus";

import { IntegrationsExtraControlsWrapper } from "./styled";

const IntegrationsExtraControls = () => {
  const { integrationStatuses } = useGetIntegrationStatuses();
  const { yardi, entrata, realPage } = integrationStatuses || {};
  const isYardiEnabled = yardi?.isEnabled;
  const isEntrataEnabled = entrata?.isEnabled;
  const isRealPageEnabled = realPage?.isEnabled;

  const { isIntegrationsAdmin } = usePermissions();

  if (!isIntegrationsAdmin) {
    return null;
  }

  return (
    <IntegrationsExtraControlsWrapper>
      {isYardiEnabled && (
        <IntegrationSyncStatus provider={IntegrationProvider.yardi} />
      )}
      {isEntrataEnabled && (
        <IntegrationSyncStatus provider={IntegrationProvider.entrata} />
      )}
      {isRealPageEnabled && (
        <IntegrationSyncStatus provider={IntegrationProvider.realPage} />
      )}
    </IntegrationsExtraControlsWrapper>
  );
};

export default IntegrationsExtraControls;
