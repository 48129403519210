import styled from "@emotion/styled";

import Well from "shared/components/Well/Well";
import { AlignedCenterFlexPartialCss } from "shared/styles/commonStyles";

export const SourceDeleteConfirmationContent = styled.div`
  width: 100%;
  padding-top: 32px;
  padding-bottom: 40px;
`;

export const SourceText = styled.span`
  font-weight: 400;
`;

export const WarningWell = styled(Well)`
  ${{ ...AlignedCenterFlexPartialCss }}
  font-weight: 500;
  margin-bottom: 16px;
  .warning-icon {
    margin-right: 16px;
  }
`;
