import React, {
  useEffect,
  useState,
  Children,
  cloneElement,
  useRef,
} from "react";

import { Formik } from "formik";
import isEqual from "lodash/isEqual";
import pick from "lodash/pick";
import PropTypes from "prop-types";
import * as Yup from "yup";

import { REQUIRED_QUESTIONS_NAMES } from "manager/components/DealSetupSettings/AdditionalQuestions";
import {
  Form,
  FooterBar as StyledFootBar,
} from "manager/components/DealSetupSettings/styled";
import { dealSetupPropType } from "manager/utils/propTypes";
import { Button } from "shared/components/Button";
import Container from "shared/components/Container";
import FooterBar from "shared/components/FooterBar";
import { PreventLeaveFormModal } from "shared/components/Form";
import Spacer from "shared/components/Spacer";
import { usePrevious } from "shared/hooks";
import { handleFormSubmit } from "shared/utils/ui";

const INVALID_DURATION_ERROR = "Please enter a number that is greater than 0";
const MAX_NUMBER_ERROR = "Please enter a number that is less than 1000";
const MAX_NUMBER = 1000;

const schema = Yup.object().shape({
  applicantApplicationFee: Yup.number()
    .nullable()
    .required("Fee Amount is required"),
  guarantorApplicationFee: Yup.number()
    .nullable()
    .required("Fee Amount is required"),
  occupantOver18ApplicationFee: Yup.number()
    .nullable()
    .required("Fee Amount is required"),
  requiresResidentialHistoryDurationApplicantOccupant: Yup.bool(),
  residentialHistoryDurationApplicantOccupant: Yup.number()
    .nullable()
    .when("requiresResidentialHistoryDurationApplicantOccupant", {
      is: true,
      then: Yup.number()
        .nullable()
        .typeError(INVALID_DURATION_ERROR)
        .positive(INVALID_DURATION_ERROR)
        .integer(INVALID_DURATION_ERROR)
        .max(MAX_NUMBER, MAX_NUMBER_ERROR)
        .required(INVALID_DURATION_ERROR),
    }),
  requiresResidentialHistoryDurationGuarantor: Yup.bool(),
  residentialHistoryDurationGuarantor: Yup.number()
    .nullable()
    .when("requiresResidentialHistoryDurationGuarantor", {
      is: true,
      then: Yup.number()
        .nullable()
        .typeError(INVALID_DURATION_ERROR)
        .positive(INVALID_DURATION_ERROR)
        .integer(INVALID_DURATION_ERROR)
        .max(MAX_NUMBER, MAX_NUMBER_ERROR)
        .required(INVALID_DURATION_ERROR),
    }),

  requiresEmploymentHistoryDurationApplicantOccupant: Yup.bool(),
  employmentHistoryDurationApplicantOccupant: Yup.number()
    .nullable()
    .when("requiresEmploymentHistoryDurationApplicantOccupant", {
      is: true,
      then: Yup.number()
        .nullable()
        .typeError(INVALID_DURATION_ERROR)
        .positive(INVALID_DURATION_ERROR)
        .integer(INVALID_DURATION_ERROR)
        .max(MAX_NUMBER, MAX_NUMBER_ERROR)
        .required(INVALID_DURATION_ERROR),
    }),
  requiresEmploymentHistoryDurationGuarantor: Yup.bool(),
  employmentHistoryDurationGuarantor: Yup.number()
    .nullable()
    .when("requiresEmploymentHistoryDurationGuarantor", {
      is: true,
      then: Yup.number()
        .nullable()
        .typeError(INVALID_DURATION_ERROR)
        .positive(INVALID_DURATION_ERROR)
        .integer(INVALID_DURATION_ERROR)
        .max(MAX_NUMBER, MAX_NUMBER_ERROR)
        .required(INVALID_DURATION_ERROR),
    }),
});

const DealSetupForm = ({
  saveChanges,
  dealSetup,
  loading,
  fetching,
  preventLeaveModalProps,
  disabled,
  children,
  footerContent,
  ...props
}) => {
  const formRef = useRef();
  const [initialValues, setInitialValues] = useState({});
  const prevDealSetup = usePrevious(dealSetup);

  const onSubmit = (data, { setSubmitting, setErrors, resetForm }) =>
    new Promise((resolve, reject) => {
      handleFormSubmit({
        values: {
          id: data.id,
          data: {
            ...data,
            // Note: Manual format to snake case
            occupant_over_18_application_fee: data.occupantOver18ApplicationFee,
          },
        },
        submit: saveChanges,
        setSubmitting,
        setErrors,
        resetForm: () => resetForm({ values: data }),
        resolve,
        reject,
      });
    });

  const applyCurrentValues = () => {
    const { values, setValues, dirty, resetForm } = formRef.current;

    const requiredQuestions = pick(
      dealSetup,
      Object.values(REQUIRED_QUESTIONS_NAMES)
    );
    const requiredAttachments = dealSetup?.requiredAttachments?.map((item) => {
      const currentValue = values?.requiredAttachments?.find(
        (q) => q.id === item.id
      );
      return {
        ...item,
        isActive: currentValue ? currentValue.isActive : item.isActive,
      };
    });

    const newInitialValues = {
      ...values,
      additionalQuestions: dealSetup.additionalQuestions,
      updatedAt: dealSetup.updatedAt,
      ...requiredQuestions,
      requiredAttachments,
    };

    if (dirty) {
      resetForm({
        values: dealSetup,
      });
      setValues(newInitialValues);
    } else {
      resetForm({
        values: newInitialValues,
      });
    }
  };

  // TODO (POD2-87): Get rid of or simplify all this custom logic
  // for keeping previousValue and applyingCurrentValues if possible.
  useEffect(() => {
    const changedAdditionalQuestions = !isEqual(
      prevDealSetup?.additionalQuestions,
      dealSetup?.additionalQuestions
    );
    const changedRequiredQuestions = !isEqual(
      pick(prevDealSetup, Object.values(REQUIRED_QUESTIONS_NAMES)),
      pick(dealSetup, Object.values(REQUIRED_QUESTIONS_NAMES))
    );
    const changedNumOfRequiredAttachments =
      prevDealSetup?.requiredAttachments?.length !==
      dealSetup?.requiredAttachments?.length;

    if (
      prevDealSetup &&
      prevDealSetup.id === dealSetup.id &&
      (changedAdditionalQuestions ||
        changedRequiredQuestions ||
        changedNumOfRequiredAttachments)
    ) {
      applyCurrentValues();
    } else if (!formRef.current?.dirty || prevDealSetup.id !== dealSetup.id) {
      setInitialValues({
        ...dealSetup,
      });
    }
  }, [dealSetup]);

  const renderChildrenWithProps = () =>
    Children.map(children, (child) =>
      cloneElement(child, { ...props, dealSetup, disabled, loading, fetching })
    );

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={disabled ? undefined : onSubmit}
      innerRef={formRef}
      validationSchema={schema}
      validateOnChange={false}
    >
      {({ isSubmitting, dirty, resetForm }) => {
        return (
          <Form data-testid="application-setup-form">
            <Container noPadding expand>
              {renderChildrenWithProps()}
              <FooterBar.Spacer />
              <Container noPadding stickToBottom>
                <StyledFootBar>
                  {footerContent || (
                    <Button
                      id="form-submit"
                      type="primary"
                      htmlType="submit"
                      loading={isSubmitting}
                      disabled={!dirty}
                      data-testid="submit-button"
                    >
                      Save changes
                    </Button>
                  )}
                </StyledFootBar>
              </Container>
            </Container>
            <PreventLeaveFormModal
              title="Save settings changes?"
              submitForm={() => document.getElementById("form-submit").click()}
              resetForm={resetForm}
              preventLeaveWhen={dirty}
              isSubmitting={isSubmitting}
              {...preventLeaveModalProps}
            >
              <Spacer />
            </PreventLeaveFormModal>
          </Form>
        );
      }}
    </Formik>
  );
};

DealSetupForm.propTypes = {
  dealSetup: dealSetupPropType,
  saveChanges: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  fetching: PropTypes.bool.isRequired,
  preventLeaveModalProps: PropTypes.object,
  SubmitButton: PropTypes.node,
  disabled: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.object).isRequired,
  footerContent: PropTypes.node,
};

DealSetupForm.defaultProps = {
  dealSetup: {},
  preventLeaveModalProps: {},
  SubmitButton: undefined,
  footerContent: undefined,
  disabled: false,
};

export default DealSetupForm;
