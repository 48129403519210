import styled from "@emotion/styled";

import { MEDIA_QUERIES } from "shared/config/constants";

const FrequencyTitleContainer = styled.div(() => ({
  [MEDIA_QUERIES.w.ltSm]: {
    paddingTop: "22px",
  },
  [MEDIA_QUERIES.w.gteSm]: {
    paddingTop: "32px",
  },
  [MEDIA_QUERIES.w.gteLg]: {
    paddingTop: "40px",
  },
}));

export { FrequencyTitleContainer };
