import React from "react";

import PropTypes from "prop-types";

import { Modal } from "shared/components/Modal";

import { IntegrationActivationWarningContent } from "./styled";

const IntegrationActivationWarningModal = ({ message, icon }) => {
  const Icon = icon;
  return (
    <Modal showSubmitButton={false} showCancelLink={false}>
      <Modal.Body noPaddingTop noPaddingBottom>
        <IntegrationActivationWarningContent>
          {icon && (
            <Icon
              data-testid="integration-warning-icon"
              className="integration-warning-icon"
            />
          )}
          {message}
        </IntegrationActivationWarningContent>
      </Modal.Body>
    </Modal>
  );
};

IntegrationActivationWarningModal.propTypes = {
  message: PropTypes.string,
  icon: PropTypes.elementType,
};

IntegrationActivationWarningModal.defaultProps = {
  message: "",
  icon: undefined,
};

export default IntegrationActivationWarningModal;
