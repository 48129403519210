import React, { useEffect, useMemo, useState } from "react";

import { InviteLeadModal } from "manager/components/Prescreening";
import { LEADS_CATEGORIES } from "manager/config/constants";
import {
  useGetLeads,
  useGetLeadsCounts,
  useGetPropertiesWithLead,
} from "manager/hooks/api";
import { DEFAULT_PAGINATION } from "shared/config/constants";
import { usePermissions, useActiveCompany } from "shared/hooks";
import useModal from "shared/hooks/useModal";

import {
  blankFilters,
  KEYS,
  leadsFiltersStorage,
  ORDER_BY,
  getInitialOrderingColumn,
  combineFilters,
} from "./config";
import { MenuItem } from "./interfaces";
import Leads from "./Leads";

const LeadsContainer = () => {
  const { openModalDialog } = useModal();
  const { canInviteLead, isYardiEnabled } = usePermissions();
  const { activeCompany: company } = useActiveCompany();

  const { areVettGuestsEnabled } = company || {};

  const initialFilters =
    leadsFiltersStorage.getItem(KEYS.filters) || blankFilters;
  const initialPagination =
    leadsFiltersStorage.getItem(KEYS.pagination) || DEFAULT_PAGINATION;
  const initialOrdering =
    leadsFiltersStorage.getItem(KEYS.ordering) || ORDER_BY.invitedDate.descend;
  const initialOrderingColumn = getInitialOrderingColumn(initialOrdering);
  const initialShowArchived =
    leadsFiltersStorage.getItem(KEYS.showArchived) || false;
  const initialLeadType =
    leadsFiltersStorage.getItem(KEYS.leadType) || LEADS_CATEGORIES[1].key;

  const [tableState, setTableState] = useState({
    filters: initialFilters,
    pagination: initialPagination,
    ordering: initialOrdering,
    showArchived: initialShowArchived,
    leadType: initialLeadType,
  });

  const [leadType, setLeadType] = useState(initialLeadType);

  const [isPropertiesSettled, setPropertiesSettled] = useState(false);

  const [leadsFilters, setLeadsFilters] = useState(combineFilters(tableState));

  const [menuItems, setMenuItems] = useState<MenuItem[]>(LEADS_CATEGORIES);

  const { properties, isLoading: isPropertiesLoading } =
    useGetPropertiesWithLead();

  const { leads, isLeadsLoading } = useGetLeads(leadsFilters, {
    enabled: !isPropertiesLoading && isPropertiesSettled,
  });

  const { leadsCounts, isLeadsCountsLoading } = useGetLeadsCounts(
    leadsFilters,
    {
      enabled: !isPropertiesLoading && isPropertiesSettled,
    }
  );

  const updateTableState = (values: any, resetPagination?: boolean) => {
    const newTableState = {
      ...tableState,
      ...values,
      ...(resetPagination ? { pagination: { ...DEFAULT_PAGINATION } } : {}),
    };

    setTableState(newTableState);
  };

  const updateFilters = (values) => {
    let newFilters = { ...blankFilters };
    if (values) {
      newFilters = {
        ...tableState.filters,
        ...values,
      };
    }

    updateTableState({ filters: newFilters }, true);
  };

  const onShowArchivedCheckboxChange = () =>
    updateTableState({ showArchived: !tableState.showArchived });

  const onTableChange = (tablePagination, tableFilters, sorter) => {
    const newOrdering = sorter?.order
      ? `${ORDER_BY[sorter.columnKey][sorter.order]}`
      : undefined;

    const pagination = {
      page: tablePagination.current,
      pageSize: DEFAULT_PAGINATION.pageSize,
    };
    updateTableState({ ordering: newOrdering, pagination });
  };

  const inviteLead = () => openModalDialog(InviteLeadModal);
  const loading = isLeadsLoading || isPropertiesLoading;

  const propertySelection = useMemo(
    () => [
      ...(properties || [])?.map(({ id, name }) => ({
        label: name,
        key: `${id}`,
      })),
      {
        label: "Unassigned",
        key: "0",
      },
    ],
    [properties]
  );

  useEffect(() => {
    if (properties) {
      if (
        Number(tableState.filters.propertyId) &&
        !properties.some(
          (property) => property.id === Number(tableState.filters.propertyId)
        )
      ) {
        const cleanFilters = {
          ...tableState.filters,
          propertyId: [],
        };

        updateTableState({ filters: cleanFilters }, true);
      }

      setPropertiesSettled(true);
    }
  }, [properties]);

  useEffect(() => {
    setLeadsFilters(combineFilters(tableState));

    leadsFiltersStorage.addItem(KEYS.filters, tableState.filters);
    leadsFiltersStorage.addItem(KEYS.pagination, tableState.pagination);
    leadsFiltersStorage.addItem(KEYS.ordering, tableState.ordering);
    leadsFiltersStorage.addItem(KEYS.showArchived, tableState.showArchived);
    leadsFiltersStorage.addItem(KEYS.leadType, tableState.leadType);
  }, [tableState]);

  const updateMenuItemsCounts = (leadsCounts) => {
    const updatedMenuItems = LEADS_CATEGORIES.map((category) => ({
      ...category,
      count: leadsCounts[category.key],
    }));
    setMenuItems(updatedMenuItems);
  };

  useEffect(() => {
    if (leadsCounts) {
      updateMenuItemsCounts(leadsCounts);
    }
  }, [leadsCounts]);
  const changeLeadType = (value) => {
    setLeadType(value);
    if (value === "guest" && !areVettGuestsEnabled) {
      return;
    }
    setTableState({
      ...tableState,
      leadType: value,
    });
  };

  return (
    <Leads
      leads={leads?.results}
      leadsCount={leads?.count}
      isCountsLoading={isLeadsCountsLoading}
      filters={tableState.filters}
      pagination={tableState.pagination}
      initialOrderingColumn={initialOrderingColumn}
      hasInputValue={Boolean(tableState.filters.nameOrEmail)}
      showInviteLead={canInviteLead}
      loading={loading}
      isFiltersLoading={isPropertiesLoading}
      updateFilters={updateFilters}
      onTableChange={onTableChange}
      inviteLead={inviteLead}
      isYardiEnabled={isYardiEnabled}
      showArchived={tableState.showArchived}
      onShowArchivedCheckboxChange={onShowArchivedCheckboxChange}
      propertySelection={propertySelection}
      menuItems={menuItems}
      changeLeadType={changeLeadType}
      activeItem={leadType}
      areVettGuestsEnabled={areVettGuestsEnabled}
      companyName={company?.name}
    />
  );
};

export default LeadsContainer;
