import styled from "@emotion/styled";

import Icon from "shared/components/Icon";
import { UnderlineLink } from "shared/components/Links";
import { PopoverContent } from "shared/components/Popover";
import Well from "shared/components/Well/Well";
import { colors, COLORS, MEDIA_QUERIES } from "shared/config/constants";
import { LumenImages } from "shared/images";
import { AlignedCenterFlexPartialCss } from "shared/styles/commonStyles";

const TABLE_BORDER = `1px solid ${COLORS.borders}`;

const LumenDashboardContent = styled.div`
  overflow-y: auto;
  position: relative;
  height: 100%;
`;

const LumenBannerWell = styled(Well)`
  background-color: ${COLORS.primary};
  color: ${COLORS.well.background};
  height: 190px;
  margin-bottom: 16px;
  display: flex;
  align-items: flex-start;
  padding: 0;
  .banner-icon-1 {
    margin: 0 25px;
    height: 100%;
    width: 130px;
    min-width: 130px;
    background-image: url(${LumenImages.LumenDashboardBanner1});
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .banner-icon-2 {
    position: relative;
    height: 190px;
    min-width: 180px;
    margin-right: -14px;
    margin-left: auto;
    background-image: url(${LumenImages.LumenDashboardBanner2});
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .banner-message {
    margin-top: 45px;
    margin-bottom: 24px;
  }

  ${MEDIA_QUERIES.w.ltMd} {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    position: relative;
    overflow: hidden;
    padding: 32px 24px;
    .banner-icon-1 {
      min-height: 74px;
      min-width: 87px;
      margin: 0;
      background-image: url(${LumenImages.LumenDashboardBanner1Mobile});
    }
    .banner-icon-2 {
      position: absolute;
      right: -112px;
      bottom: 0;
    }
    .banner-message {
      margin-top: 20px;
      margin-bottom: 32px;
    }
  }
  z-index: 1;
`;

const NoTableItemsTitle = styled.h2`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const TableWrapper = styled.div`
  border: ${TABLE_BORDER};
  .delete-icon {
    margin-left: 24px;
  }
  .assign-link {
    margin-right: 40px;
  }
  .new-formula {
    display: block;
  }
  .new-formula-mobile {
    display: none;
  }
  .row-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  ${MEDIA_QUERIES.w.ltMd} {
    .new-formula {
      display: none;
    }
    .new-formula-mobile {
      display: block;
      margin-left: 13px;
    }
    .row-actions {
      justify-content: flex-start;
    }
  }
`;

const TableHeader = styled.div`
  ${{ ...AlignedCenterFlexPartialCss }}

  justify-content: space-between;
  padding: 24px;
  border-bottom: ${TABLE_BORDER};
  background-color: ${COLORS.white};
  h2 {
    margin: 0;
  }
  .table-header-title {
    align-items: center;
    .radio-buttons-container {
      margin-left: 48px;
    }
  }
  ${MEDIA_QUERIES.w.ltMd} {
    flex-direction: column;
    .table-header-title {
      flex-direction: column;
      align-items: center;
      width: 100%;
      .radio-buttons-container {
        margin: 0;
        margin-top: 24px;
        width: calc(100% + 47px);
        display: flex;
        justify-content: center;
      }
    }
  }
`;

const LumenNotAvailableContent = styled.div`
  .not-available-text {
    color: ${COLORS.grayTheme.textColor};
    margin-top: 28px;
    margin-bottom: 32px;
    font-size: 17px;
    max-width: 606px;
    text-align: center;
  }
  ${MEDIA_QUERIES.w.ltMd} {
    .not-available-text {
      text-align: start;
    }
    .not-available-image {
      width: 90%;
      height: 90%;
      margin-top: 16px;
    }
    .not-available-link {
      align-self: flex-start;
    }
  }
`;

const PropertiesListPopoverContent = styled(PopoverContent)`
  ul {
    padding: 0;
    margin: 0;
    max-height: 10rem;
    overflow: auto;
  }
  li {
    padding: 0;
    margin-bottom: .5rem;
    list-style: none;
  }
`;

const ListContainer = styled.div`
  border: 1px solid ${COLORS.borders};
  max-height: 300px;
  overflow-y: scroll;
  box-sizing: border-box;
  border-radius: 2px;
`;

const List = styled.ul`
  padding-left: 30px;
`;

const ListItem = styled.li`
  padding: 5px;
  &::marker {
    color: ${COLORS.violetTheme.lightBlueViolet};
  }
`;

const InfoIcon = styled(Icon.Info)`
  width: 21.5px;
  height: 21.5px;
  margin-right: 10px;
  cursor: pointer;
  path {
    fill: ${colors.accent[300]};
    &:hover {
      fill: ${colors.accent[100]} !important;
    }
  }
`;

const LumenHowItWorks = styled(UnderlineLink)`
  font-size: 16px;
  color: ${colors.accent[300]};
  border-bottom-color: ${colors.accent[300]};
  &:hover {
    color: ${colors.accent[100]} !important;
    border-bottom-color: ${colors.accent[100]} !important;
  }
`;

export {
  LumenDashboardContent,
  NoTableItemsTitle,
  TableWrapper,
  TableHeader,
  LumenBannerWell,
  LumenNotAvailableContent,
  PropertiesListPopoverContent,
  ListContainer,
  List,
  ListItem,
  InfoIcon,
  LumenHowItWorks,
};
