import toNumberExternal from "lodash/toNumber";

export const toNumber = (value) => {
  return value ? toNumberExternal(value) : value;
};

export const destringifyWithPrefix = (id, prefix) => {
  const idAsString = (id || "").replace(prefix, "");
  return idAsString ? toNumber(idAsString) : idAsString;
};

export const clamp = (a: number, [min, max]: [number, number]) =>
  Math.min(max, Math.max(min, a));
