import { useQuery } from "react-query";

import api from "renter/lib/api";
import { NOTIFICATIONS } from "shared/config/constants";
import { openNotification } from "shared/utils/ui";

const GET_DOCUMENTS_KEY = "getDocuments";
const GET_OCR_JOBS_KEY = "getOcrJobs";

export const useGetDocuments = (owner: string, enable: boolean) => {
  const { data, isLoading, refetch } = useQuery(
    [GET_DOCUMENTS_KEY, owner],
    () => api.getDocuments(owner),
    {
      enabled: enable,
      onError: () =>
        openNotification("Failed to get the documents", NOTIFICATIONS.error),
    }
  );

  return {
    refetchDocuments: refetch,
    documents: data,
    isDocumentsLoading: isLoading,
  };
};

export const useGetOcrJobs = (person: string, enable: boolean) => {
  const { data, isLoading, refetch } = useQuery(
    [GET_OCR_JOBS_KEY, person],
    () => api.getOcrJobs(person),
    {
      enabled: enable,
      onError: () =>
        openNotification("Failed to get the ocr jobs", NOTIFICATIONS.error),
    }
  );

  return {
    refetchOcrJobs: refetch,
    ocrJobs: data,
    isOcrJobsLoading: isLoading,
  };
};
