import React, { useState } from "react";

import useModal from "shared/hooks/useModal";

import ConfirmationModal from "./ConfirmationModal";

const ConfirmationModalContainer = () => {
  const [submitting, setSubmitting] = useState(false);
  const { currentModalContext, selfApi } = useModal();
  const { onConfirm: onConfirmCallback } = currentModalContext;

  const onConfirm = async () => {
    if (onConfirmCallback) {
      try {
        setSubmitting(true);
        await onConfirmCallback();
      } finally {
        setSubmitting(false);
      }
    }
    selfApi.setClosingValue(true);
  };

  return (
    <ConfirmationModal
      {...currentModalContext}
      onConfirm={onConfirm}
      submitting={submitting}
    />
  );
};

export default ConfirmationModalContainer;
