import * as Yup from "yup";

export const baseSchemaFields = {
  password: Yup.string()
    .min(8, "Must be at least 8 characters long.")
    .max(99, "Must be less than 100 characters long.")
    .matches(/(?=.*[a-z])/, { message: "Must contain a lowercase letter." })
    .matches(/(?=.*\d)/, { message: "Must contain a number." })
    .required("Password is required."),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords don't match.")
    .required("Password confirmation is required."),
  termsOfService: Yup.boolean()
    .required()
    .oneOf([true], "The terms of service must be accepted."),
};
