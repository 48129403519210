import React, { useMemo } from "react";

import { Formik } from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";

import {
  YardiAgentDropdown,
  yardiAgentsPropType,
} from "manager/pages/Marketplace/integrations/yardi/shared";
import LargeLoader from "shared/components/LargeLoader";
import { Modal } from "shared/components/Modal";

import { LinkEmployeeContent, PropertyText } from "./styled";

const YARDI_AGENT_ERROR = "Yardi agent is required";

const ValidationSchema = Yup.object().shape({
  properties: Yup.array().of(
    Yup.object().shape({
      yardiAgent: Yup.string().nullable().required(YARDI_AGENT_ERROR),
    })
  ),
});

const LinkEmployeeToYardiAgent = ({
  firstName,
  lastName,
  yardiAgentsOnProperties,
  isLoading,
  onSubmit,
}) => {
  const employeeName = `${firstName} ${lastName}`;
  const subtitle = (
    <span>
      Agent: <PropertyText>{employeeName}</PropertyText>
    </span>
  );

  const { initialValues, initialErrors } = useMemo(() => {
    const initialValuesProperties = (yardiAgentsOnProperties || []).map(
      (property) => ({
        propertyId: property.propertyId,
        yardiAgent: undefined,
      })
    );
    const initialValuesInternal = {
      properties: initialValuesProperties,
    };

    const initialErrorsProperties = (yardiAgentsOnProperties || []).map(() => ({
      yardiAgent: YARDI_AGENT_ERROR,
    }));
    const initialErrorsInternal = {
      properties: initialErrorsProperties,
    };

    return {
      initialValues: initialValuesInternal,
      initialErrors: initialErrorsInternal,
    };
  }, [yardiAgentsOnProperties]);

  return (
    <Formik
      initialValues={initialValues}
      initialErrors={initialErrors}
      onSubmit={onSubmit}
      enableReinitialize
      validationSchema={ValidationSchema}
      validateOnChange
      validateOnMount
    >
      {({ isValid, values, submitForm, isSubmitting }) => (
        <Modal
          centered
          showSubmitButton
          showCancelLink
          submitButtonLabel="CONFIRM"
          submitButtonDisabled={!isValid}
          submit={() => submitForm(values)}
          submitting={isSubmitting}
          title="Link VERO agent with External Provider"
          subtitle={subtitle}
        >
          <Modal.Body noPaddingTop noPaddingBottom>
            <LargeLoader showLoader={isLoading} />
            {!isLoading && (
              <LinkEmployeeContent>
                <p className="link-description">
                  Please select External Provider agent for each property to
                  link them with agent
                </p>
                {yardiAgentsOnProperties.map(
                  ({ propertyId, propertyName, agents }, idx) => (
                    <YardiAgentDropdown
                      key={propertyId}
                      inputDescription={propertyName}
                      yardiAgentFormPath={`properties[${idx}].yardiAgent`}
                      agents={agents}
                    />
                  )
                )}
              </LinkEmployeeContent>
            )}
          </Modal.Body>
        </Modal>
      )}
    </Formik>
  );
};

const yardiAgentProps = {
  propertyName: PropTypes.string.isRequired,
  agents: yardiAgentsPropType.isRequired,
};

LinkEmployeeToYardiAgent.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  yardiAgentsOnProperties: PropTypes.arrayOf(PropTypes.shape(yardiAgentProps)),
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

LinkEmployeeToYardiAgent.defaultProps = {
  yardiAgentsOnProperties: [],
};

export default LinkEmployeeToYardiAgent;
