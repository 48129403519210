import React from "react";

import useModal from "shared/hooks/useModal";

import EditEmployeeModal from "./EditEmployeeModal";
import { EditEmployeeDataModalProps } from "./interfaces";

const EditEmployeeModalContainer = () => {
  const { currentModalContext } = useModal();

  const { employeeData }: EditEmployeeDataModalProps = currentModalContext;

  return <EditEmployeeModal employeeData={employeeData} />;
};

export default EditEmployeeModalContainer;
