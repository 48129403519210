import React, { Key } from "react";

import { FormikHelpers } from "formik";
import { useHistory } from "react-router-dom";

import { useEditUnitListing } from "manager/hooks/api";
import useModal from "shared/hooks/useModal";
import { onDialogSubmitError } from "shared/utils/api";

import EditUnitListingModal from "./EditUnitListingModal";
import { EditListing } from "./interfaces";

interface EditUnitListingModalContainerProps {
  rent: number;
  securityDeposit: number;
  unitAvailableOn: string;
  unitListingId: Key;
  note: string;
  unitName: string;
  currentIntegrationType: string;
}

const EditUnitListingModalContainer = ({
  rent,
  securityDeposit,
  unitAvailableOn,
  unitListingId,
  note,
  unitName,
  currentIntegrationType,
}: EditUnitListingModalContainerProps) => {
  const history = useHistory();
  const { selfApi } = useModal();
  const { editUnitListing } = useEditUnitListing();

  const submit = (values: EditListing, formikApi: FormikHelpers<EditListing>) =>
    editUnitListing(
      {
        ...values,
        id: unitListingId,
        // @ts-ignore
        unitName,
      },
      {
        onSuccess: selfApi.closeDialog,
        onError: onDialogSubmitError(formikApi, history),
      }
    );

  return (
    <EditUnitListingModal
      currentIntegrationType={currentIntegrationType}
      note={note}
      rent={rent}
      securityDeposit={securityDeposit}
      unitAvailableOn={unitAvailableOn}
      unitName={unitName}
      submit={submit}
    />
  );
};

export default EditUnitListingModalContainer;
