import React, { useEffect, useState } from "react";

import CreateFieldModal from "admin/components/Modal/CreateFieldModal";
import { signatureFieldsStorage } from "admin/config/checkboxGroupSignature.config";
import { FIELD_CATEGORIES } from "admin/config/constants";
import { useFields, useSignatureFields } from "admin/hooks/api";
import useModal from "shared/hooks/useModal";

import FieldsPanel from "./FieldsPanel";
import { Field } from "./interfaces";
import {
  formatApplicantsFields,
  formatDocumentFields,
  formatSignatureFields,
} from "./utils";

interface FieldsPanelContainerProps {
  addField: (field: Field) => void;
  exportAnnotations: any;
  importAnnotations: any;
  isDocumentLoaded: boolean;
  isDocumentDownloaded?: boolean;
}

const FieldsPanelContainer = (props: FieldsPanelContainerProps) => {
  const { openModalDialog } = useModal();
  const createField = () => openModalDialog(CreateFieldModal);
  const {
    isDocumentDownloaded,
    exportAnnotations: exportAnnotationsProps,
    importAnnotations,
  } = props || {};

  const [checkboxGroupSignatures, setCheckboxGroupSignatures] = useState([]);
  const { data: standardFields } = useFields({ groupByCategory: true });
  const { data: signatureFields } = useSignatureFields();

  const fieldsToDisplay = standardFields?.map(({ name, key, fields }) => ({
    name,
    fields:
      key === FIELD_CATEGORIES.applicant
        ? formatApplicantsFields(fields)
        : formatDocumentFields(fields),
  }));

  const signaturesCategory = signatureFields && {
    name: "Signatures",
    fields: formatSignatureFields({
      ...signatureFields,
      checkboxGroupSignatures,
    }),
  };

  const accessibleFields = fieldsToDisplay &&
    signaturesCategory && [signaturesCategory, ...fieldsToDisplay];

  const exportAnnotations = () => {
    if (!isDocumentDownloaded) return;

    if (exportAnnotationsProps) {
      exportAnnotationsProps();
    }
  };

  const onAnnotationFileSelect = (files) => {
    const reader = new FileReader();
    reader.readAsText(files[0]);
    reader.onload = (event) => {
      if (importAnnotations) {
        importAnnotations(event.target.result);
      }
    };
  };

  useEffect(() => {
    const syncSignatures = () => {
      setCheckboxGroupSignatures(
        Object.values(signatureFieldsStorage.getStorage())
      );
    };

    syncSignatures();

    signatureFieldsStorage.subscribe(
      signatureFieldsStorage.events.addItem,
      syncSignatures
    );
    return () =>
      signatureFieldsStorage.unsubscribe(
        signatureFieldsStorage.events.addItem,
        syncSignatures
      );
  }, []);

  return (
    <FieldsPanel
      createField={createField}
      accessibleFields={accessibleFields}
      exportAnnotations={exportAnnotations}
      onAnnotationFileSelect={onAnnotationFileSelect}
      {...props}
    />
  );
};

export default FieldsPanelContainer;
