import get from "lodash/get";
import isObjectLike from "lodash/isObjectLike";
import mapValues from "lodash/mapValues";

import { deferred } from "shared/utils/misc.util";

export const resetFormAndValidate = (formikRef, formikData) => {
  formikRef.current.resetForm(formikData);
  // NOTE: need to postpone the validation after the reset, otherwise there's a conflict with resetForm.
  // Couldn't find a way to reset the form (values and 'touched') and re-run validation with a single call.
  deferred(() => formikRef.current.validateForm());
};

export const setFormValuesAndValidate = (formikRef, values) => {
  if (formikRef?.current?.setValues) {
    formikRef.current.setValues(values);
    deferred(() => formikRef.current.validateForm());
  }
};

export const showError = (propPath, touched, errors) => {
  return get(touched, propPath) ? get(errors, propPath) : undefined;
};

export const hasError = (propPath, errors) => {
  return !!get(errors, propPath);
};

/**
 * Applies the default object values on a specific path of the form values.
 * The default values are merged with the existing values (existing values have priority).
 *
 * @param values existing form values (complete set of values)
 * @param objectPropPath the path to the object that should be updated
 * @param defaultValues the default values
 * @param setFieldValue the setter for the form field values.
 */
export const addDefaultObjectValues = (
  values,
  objectPropPath,
  defaultValues,
  setFieldValue
) => {
  const existingObjectValues = get(values, objectPropPath) || {};
  const objectValues = { ...defaultValues, ...existingObjectValues };
  setFieldValue(objectPropPath, objectValues);
};

/** Util for recursive mapping object values to a new value.
 *  Can be helpful in Formik forms, when you need to set fields touched
 *  to show all errors without form submit.
 *
 * @param {Object} obj
 * @param {Any} newValue
 * @returns {Object}
 */
export const mapObjectValues = (obj, newValue) => {
  if (Array.isArray(obj)) {
    return obj.map((item) => mapObjectValues(item, newValue));
  }

  if (isObjectLike(obj)) {
    return mapValues(obj, (value) => mapObjectValues(value, newValue));
  }

  return newValue;
};

export const setTouchedAndValidate = async (formikRef) => {
  formikRef?.current?.setTouched(
    mapObjectValues(formikRef.current.values, true),
    false
  );

  const errors = await formikRef.current.validateForm();
  return errors;
};
