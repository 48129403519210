import { Col, Row } from 'antd'
import _ from 'lodash'
import isEmpty from 'lodash/isEmpty'

import { CreditScoreSummary } from 'shared/components/CreditAndBackgroundCheck/interfaces'
import { NoCountyCriminalRecordsForApplicant } from 'shared/components/CreditAndBackgroundCheck/styled'
import Spacer from 'shared/components/Spacer'
import Tile from 'shared/components/Tile'
import { MediumTitle } from 'shared/components/Typography'

import { printDollarsFromCents } from 'shared/utils/dollar-print'
import { formatDate } from 'shared/utils/ui'

type PublicRecordsProps = {
  creditScoreSummary?: CreditScoreSummary
}

const PublicRecords = ({ creditScoreSummary }: PublicRecordsProps) => {
  const publicRecords = creditScoreSummary?.publicRecords || []

  return (
    <>
      <Tile
        header={{
          title: 'Public Records',
          withoutBorderBottom: isEmpty(publicRecords),
        }}
      >
        <Tile.Inner inset={isEmpty(publicRecords)}>
          {!isEmpty(publicRecords) ? (
            publicRecords?.map((record, index) => (
              <>
                <Row type="flex">
                  <Col xs={12} md={6}>
                    <Tile.Label>Category</Tile.Label>
                    <strong>{record.category}</strong>
                  </Col>
                  <Spacer />
                  <Col xs={12} md={6}>
                    <Tile.Label>Date</Tile.Label>
                    <strong>{formatDate(record.date)}</strong>
                  </Col>
                  <Col xs={24} md={8}>
                    <Tile.Label>Description</Tile.Label>
                    <span>{record.description}</span>
                  </Col>
                  <Col xs={12} md={2}>
                    <Tile.Label>Type</Tile.Label>
                    <span>{record.type}</span>
                  </Col>
                  <Spacer />
                  <Col xs={12} md={2}>
                    <Tile.Label>Amount</Tile.Label>
                    <span>
                      {printDollarsFromCents(_.get(record, 'amount'))}
                    </span>
                  </Col>
                </Row>
                {!isEmpty(publicRecords) &&
                  index !== publicRecords?.length - 1 && (
                    <Tile.Divider horizontal />
                  )}
              </>
            ))
          ) : (
            <NoCountyCriminalRecordsForApplicant
              alignItems="center"
              justifyContent="center"
            >
              <MediumTitle className="message">
                Applicant has no public records.
              </MediumTitle>
            </NoCountyCriminalRecordsForApplicant>
          )}
        </Tile.Inner>
      </Tile>
    </>
  )
}

export default PublicRecords
