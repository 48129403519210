import React, { HTMLAttributes } from "react";

export enum SIZES {
  default = "default",
  sm = "sm",
  lg = "lg",
}

type SizesStringType = keyof typeof SIZES;

export interface TileProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  header?: {
    collapsibleOnMobile?: boolean;
    collapsibleOnDesktop?: boolean;
    defaultCollapsed?: boolean;
    notClickable?: boolean;
    title?: React.ReactNode;
    subtitle?: React.ReactNode;
    smallTitle?: string;
    icon?: React.ReactNode;
    primaryAction?: React.ReactNode;
    secondaryAction?: React.ReactNode;
    noMobileActionStyles?: boolean;
    inlineOnMobile?: boolean;
    mobileOnly?: boolean;
    label?: {
      type?: string;
      text: string;
    };
    accent?: boolean;
    separateActionsStyle?: boolean;
    withoutBorderBottom?: boolean;
    alignItems?: string;
    noPaddingLtMd?: boolean;
    fullWidth?: boolean;
  };
  download?: {
    // eslint-disable-next-line no-unused-vars
    onClick: (...args: any) => any;
    description: React.ReactNode;
    icon?: React.ElementType;
    disabled?: boolean;
    nowrap?: boolean;
  };
  insetHeader?: boolean;
  alt?: boolean;
  sec?: boolean;
  tag?:
    | boolean
    | {
        description: React.ReactNode;
        icon?: React.ElementType;
      };
  stickyTop?: boolean;
  noBorder?: boolean;
  size?: SIZES;
  noBorderBottom?: boolean;
  noBorderTop?: boolean;
  noBorderLeft?: boolean;
  noBorderRight?: boolean;
  expand?: boolean;
  className?: string;
  noMarginBottom?: boolean;
  equalHeight?: boolean;
  rounded?: boolean;
}

export interface TileInnerProps extends HTMLAttributes<HTMLDivElement> {
  borderBottom?: boolean;
  borderTop?: boolean;
  altStyle?: boolean;
  mdOnly?: boolean;
  clickable?: boolean;
  inset?: boolean;
  small?: boolean;
  noPaddingTopLtMd?: boolean;
  noPaddingBottomLtMd?: boolean;
  spacer?: boolean;
  size?: SizesStringType;
  sizeX?: SizesStringType;
  violet?: boolean;
  deepViolet?: boolean;
  noPadding?: boolean;
  noPaddingTop?: boolean;
  noPaddingBottom?: boolean;
  noPaddingLtMd?: boolean;
}
