import styled from "@emotion/styled";

import { COLORS } from "shared/config/constants";

const Header = styled.div`
  padding: 20px;
  border-bottom: 1px solid ${COLORS.borders};
`;

const HeaderTitle = styled.h2`
  margin-bottom: 0;
`;

export { Header, HeaderTitle };
