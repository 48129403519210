import styled from "@emotion/styled";

import Card from "shared/components/Card";
import { CenteredCard } from "shared/styles/commonStyles";

const PasswordRecoveryEmailSentPageCard = styled(Card)`
  ${CenteredCard}

  .back-button {
    margin: 0 auto;
  }
`;

export { PasswordRecoveryEmailSentPageCard };
