import React from "react";

import { YardiDeclineDealReasonFormExtension } from "manager/pages/Marketplace/integrations/yardi/shared";

import { declineDeal } from "shared/lib/api/dealApi";

import { useUpload } from "../hooks";
import { DealSummaryWithProperty } from "../types";

export const useHook = (deal: DealSummaryWithProperty) => {
  const { uploadFile, excludeFile, attachments } = useUpload();
  const unit = deal?.unit;
  const property = deal?.property;
  const modalExtension = (unit || property)?.yardiConnected ? (
    <YardiDeclineDealReasonFormExtension property={property} />
  ) : null;

  const dealId = deal?.id;
  const onSubmit = async (values) => {
    const { extraParams } = values;
    const response = await declineDeal({
      id: dealId,
      attachments,
      extraParams,
    });
    if (response.status === 200) {
      window?.Intercom?.('trackEvent', 'Deal Decision', {
        deal_ID: dealId,
        decision: 'Decline',
      })
    }
  };
  const title = "Decline Application";
  const submitLabel = "Decline Application";
  return {
    onSubmit,
    uploadFile,
    excludeFile,
    modalExtension,
    title,
    submitLabel,
  };
};
