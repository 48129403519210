import React from "react";

import Container from "shared/components/Container";

import { FormulaTable } from "./interfaces";
import LumenFormulaTable from "./LumenFormulaTable";
import NoLumenFormulaAvailable from "./NoLumenFormulaAvailable";

const LumenDashboardTable = ({
  lumenFormulas,
  createNewLumenFormula,
  formulaStatusFilter,
  setFormulaStatusFilter,
  assignFormula,
  duplicateFormula,
  editFormula,
  renameFormula,
  showFormulaRevisions,
  deleteFormula,
  showMarkFormulaAsTemplate,
  markFormulaAsTemplate,
  isTableLoading,
  totalCount,
}: FormulaTable) => (
  <Container expand noPaddingTop noMobilePaddingX>
    {!isTableLoading && !totalCount && (
      <NoLumenFormulaAvailable createNewLumenFormula={createNewLumenFormula} />
    )}
    {(!!totalCount || isTableLoading) && (
      <LumenFormulaTable
        lumenFormulas={lumenFormulas}
        formulaStatusFilter={formulaStatusFilter}
        setFormulaStatusFilter={setFormulaStatusFilter}
        createNewLumenFormula={createNewLumenFormula}
        assignFormula={assignFormula}
        duplicateFormula={duplicateFormula}
        editFormula={editFormula}
        renameFormula={renameFormula}
        showFormulaRevisions={showFormulaRevisions}
        deleteFormula={deleteFormula}
        showMarkFormulaAsTemplate={showMarkFormulaAsTemplate}
        markFormulaAsTemplate={markFormulaAsTemplate}
        isTableLoading={isTableLoading}
      />
    )}
  </Container>
);

export default LumenDashboardTable;
