import { useQuery, useMutation } from "react-query";

import api from "manager/lib/api";
import { NOTIFICATIONS } from "shared/config/constants";
import { openNotification } from "shared/utils/ui";

export const useGetPaymentAccountMappings = (
  params: {location: string, identifier?: string},
  enable: boolean
) => {
  const { data, isLoading } = useQuery(
    ["getPaymentAccountMappings", params],
    () => api.getPaymentAccountMappings(params).then(({ data }) => data),
    {
      enabled: enable,
      onError: () => 
        openNotification("Failed getting payment account mappings", NOTIFICATIONS.error),
    }
  );

  return {
    paymentAccountMappings: data,
    isPaymentAccountMappingsLoading: isLoading,
  };
};

export const usePaymentAccountMapping = () => {
  const [mutate, status] = useMutation(api.createPaymentAccountMapping, {
    onError: (error: any) => {
      openNotification(
        error?.errors?.message || "Failed to create payment account mapping",
        NOTIFICATIONS.error
      );
    },
  });

  return {
    createPaymentAccountMapping: mutate,
    ...status,
  };
};
