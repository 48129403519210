import styled from "@emotion/styled";

import { COLORS } from "shared/config/constants";

const YardiCredentialEditWarningContent = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 25px;
  background-color: ${COLORS.layoutBodyBackground};
  border: 1px solid ${COLORS.borderColorBase};
  padding: 24px;
  margin-bottom: 40px;
  font-weight: 500;
  height: 180px;
  .credentials-warning-icon {
    width: 48px;
    height: 48px;
    margin-right: 14px;
  }
  .edit-message {
    width: 350px;
  }
`;

export { YardiCredentialEditWarningContent };
