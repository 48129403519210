import React from "react";

import styled from "@emotion/styled";
import { Switch } from "antd";

import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const StyledSwitch = styled(({ hasDisabledTooltip, ...props }) => (
  <Switch {...props} />
))((props: { checked?: boolean; hasDisabledTooltip?: boolean }) => ({
  background: props.checked ? COLORS.checkbox : "",
  // NOTE: needed for a bug related to mouseLeave event on disabled element
  // Reference: https://github.com/facebook/react/issues/4251
  ...(props.hasDisabledTooltip && { pointerEvents: "none" }),
}));

export const getLabelFontSize = ({ size }) => {
  if (size === "small") {
    return "15px";
  }

  return "17px";
};

export const Label = styled.label`
  color: #2d2943;
  padding: 0 8px;
  font-size: ${getLabelFontSize};
`;

export const SwitchTitleContainer = styled.div(
  (props: {
    noPadding?: boolean;
    isChecked?: boolean;
    hasItems?: boolean;
    reverseOrder?: boolean;
  }) => ({
    ...(!props.noPadding && {
      [MEDIA_QUERIES.w.ltLg]: {
        paddingTop: "32px",
        paddingBottom: "32px",
      },
      [MEDIA_QUERIES.w.gteLg]: {
        paddingTop: "40px",
        paddingBottom: !props.isChecked || !props.hasItems ? "40px" : "32px",
      },
    }),
    ".switch-text-wrapper": {
      display: "flex",
      ...(props.noPadding && {
        alignItems: "center",
      }),
      ...(props.reverseOrder && {
        flexDirection: "row-reverse",
        justifyContent: "flex-end",
      }),
      width: "fit-content",
    },
  })
);

export const Description = styled.p(() => ({
  fontSize: "17px",
  color: "#807E8D",
  marginBottom: 0,
  [MEDIA_QUERIES.w.ltSm]: {
    fontWeight: 300,
  },
}));

export const Text = styled.div(() => ({
  display: "flex",
  flexWrap: "wrap",
}));
