import React, { useEffect } from "react";

import { Col, Row } from "antd";
import { useFormikContext } from "formik";
import get from "lodash/get";

import { CheckableTag } from "shared/components/checkable-tag";
import { TagsInput } from "shared/components/Form";
import { SmallErrorText } from "shared/components/Typography";
import { addDefaultObjectValues, showError } from "shared/utils/forms";

import {
  getBaseConditionPath,
  getConditionPropPath,
} from "../../LumenFilterConditions/lumenFilterConditions.util";
import { EVICTION_CASE_TYPES } from "../utils";

import BaseCondition from "./BaseCondition";
import { ConditionWrapper } from "./styled";

interface ConditionEvictionCaseTypeProps {
  onDelete: () => void;
  idx: number;
}

const ConditionEvictionCaseType = ({
  onDelete,
  idx,
}: ConditionEvictionCaseTypeProps) => {
  const keywordsProp = "keywords";
  const otherKeywordsProp = "otherKeywords";
  const otherProp = "other";
  const keywordsBasePath = getBaseConditionPath(idx);
  const keywordsPropPath = getConditionPropPath(idx, keywordsProp);
  const otherKeywordsPropPath = getConditionPropPath(idx, otherKeywordsProp);
  const otherPropPath = getConditionPropPath(idx, otherProp);

  const { values, setFieldValue, errors, touched } = useFormikContext();

  useEffect(() => {
    const defaultValues = {
      [keywordsProp]: [],
      [otherKeywordsProp]: [],
      [otherProp]: false,
    };

    addDefaultObjectValues(
      values,
      keywordsBasePath,
      defaultValues,
      setFieldValue
    );
  }, []);

  const keywordValues: string[] = get(values, keywordsPropPath);
  const otherKeywordValues: string[] = get(values, otherKeywordsPropPath);
  const isOtherSelected: boolean = get(values, otherPropPath);

  const isChecked = (value: string) =>
    !!keywordValues?.find((keyword) => keyword === value);

  const handleChecking = (value: string) => {
    let keywords = [];
    if (keywordValues?.includes(value)) {
      keywords = keywordValues?.filter((keyword) => keyword !== value);
    } else {
      keywords = [...keywordValues, value];
    }

    setFieldValue(keywordsPropPath, keywords);
  };

  const handleCheckingOther = () => {
    setFieldValue(otherPropPath, !isOtherSelected);
    setFieldValue(otherKeywordsPropPath, []);
  };

  const hangleOtherKeywordsChange = (values: string[]) => {
    setFieldValue(otherKeywordsPropPath, values);
  };

  return (
    <BaseCondition
      conditionLabel="Case type (Select at least 1)"
      conditionDescription="Refers to the category of legal disputes or claims that can be filed in a court of law."
      onDelete={onDelete}
    >
      <ConditionWrapper>
        <Row gutter={[32, 16]} type="flex">
          <Col className="eviction-case-type">
            {EVICTION_CASE_TYPES.map((caseType) => (
              <CheckableTag
                key={caseType}
                checked={isChecked(caseType)}
                onChange={() => handleChecking(caseType)}
              >
                {caseType}
              </CheckableTag>
            ))}
            <CheckableTag
              key={otherProp}
              checked={isOtherSelected}
              onChange={handleCheckingOther}
            >
              Other
            </CheckableTag>
          </Col>
        </Row>
        {isOtherSelected && (
          <Row gutter={[32, 16]} type="flex">
            <Col span={16}>
              <TagsInput
                placeholder="Type Keyword"
                value={otherKeywordValues}
                onChange={hangleOtherKeywordsChange}
              />
            </Col>
          </Row>
        )}
        <SmallErrorText>
          {showError(keywordsPropPath, touched, errors) ||
            showError(otherKeywordsPropPath, touched, errors)}
        </SmallErrorText>
      </ConditionWrapper>
    </BaseCondition>
  );
};

export default ConditionEvictionCaseType;
