import * as Yup from "yup";

import { CreateUnitListingValidationSchema } from "manager/utils/validationSchemas";

const RESOLVE_PROPERTY_LINK_OPTIONS = Object.freeze({
  LINK_EXISTING_PROPERTY: "LINK_EXISTING_PROPERTY",
  CREATE_NEW_PROPERTY: "CREATE_NEW_PROPERTY",
});

const RESOLVE_UNIT_LINK_OPTIONS = Object.freeze({
  LINK_EXISTING_UNIT: "LINK_EXISTING_UNIT",
  CREATE_NEW_UNIT: "CREATE_NEW_UNIT",
});

const UnitListingValidationSchema = Yup.object().shape({
  unitListing: CreateUnitListingValidationSchema,
});

export {
  RESOLVE_PROPERTY_LINK_OPTIONS,
  RESOLVE_UNIT_LINK_OPTIONS,
  UnitListingValidationSchema,
};
