import { queryCache, useMutation, useQuery } from 'react-query'

import type {
  IncomesByBucketsRequestParams,
  IncomeByStreamsRequestParams,
  AnnotateIncomeStreamRequestParams,
} from "renter/interfaces/api/income";

import api from "renter/lib/api";
import { NOTIFICATIONS } from "shared/config/constants";
import { openNotification } from "shared/utils/ui";

const GET_INCOMES_KEY = "getIncomes";
const GET_INCOMES_BY_BUCKETS = "getIncomesByBuckets";
export const GET_INCOME_BY_STREAMS = "getIncomeByStreams";

export const useGetIncomes = (
  params: {
    person?: string;
    employment?: string;
  },
  enable: boolean
) => {
  const { data, isLoading, refetch } = useQuery(
    [GET_INCOMES_KEY, JSON.stringify(params)],
    () => api.getIncomes(params),
    {
      enabled: enable,
      onError: () =>
        openNotification("Failed to get the incomes", NOTIFICATIONS.error),
    }
  );

  return {
    refetchIncomes: refetch,
    incomesData: data,
    isIncomesDataLoading: isLoading,
  };
};

export const useCreateIncome = () => {
  const [mutation, isLoading] = useMutation(
    (payload: any) => api.createIncome({ payload }),
    {
      onError: () =>
        openNotification("Failed to create income", NOTIFICATIONS.error),
    }
  );

  return {
    createIncome: mutation,
    isLoading,
  };
};

export const useGetIncomesByBuckets = (
  params: IncomesByBucketsRequestParams,
  enabled: boolean
) => {
  const { data, ...rest } = useQuery(
    GET_INCOMES_BY_BUCKETS,
    () => api.getIncomesByBuckets(params),
    { enabled }
  );
  return { buckets: data, ...rest };
};

export const useGetIncomeByStreams = (
  params: IncomeByStreamsRequestParams,
  enabled: boolean
) => {
  const { data, ...rest } = useQuery(
    GET_INCOME_BY_STREAMS,
    () => api.getIncomeByStream(params),
    { enabled }
  );
  const includedStream =
    data?.flatMap(({ streams }) =>
      streams.filter(({ isIncluded }) => isIncluded)
    ) ?? [];
  const excludedStream =
    data?.flatMap(({ streams }) =>
      streams.filter(({ isIncluded }) => !isIncluded)
    ) ?? [];
  return { allStreams: data, excludedStream, includedStream, ...rest };
};

const onAnnotateIncomeStreamSuccess = async ({
  included,
  incomeStreamName,
}: {
  included: boolean
  incomeStreamName: string
}) => {
  await queryCache.invalidateQueries(GET_INCOME_BY_STREAMS)
  openNotification(
    `Income from ${incomeStreamName} successfully ${
      included ? 'added to' : 'removed from'
    } Verified Income. Lumen score recalculated.`,
    NOTIFICATIONS.info,
  )
}

const onAnnotateIncomeStreamError = () => {
  openNotification('Failed to annotate income stream.', NOTIFICATIONS.error)
}

export const useAnnotateIncomeStream = (
  params: Omit<AnnotateIncomeStreamRequestParams, 'reason'>,
) =>
  useMutation(
    ({ reason }: { reason: string }) =>
      api.annotateIncomeStream({ reason, ...params }),
    {
      onError: onAnnotateIncomeStreamError,
      onSuccess: () =>
        onAnnotateIncomeStreamSuccess({
          included: params.isIncluded,
          incomeStreamName: params.incomeStreamName,
        }),
    },
  )

export const usePatchIncomeStreamAnnotation = (
  params: Omit<AnnotateIncomeStreamRequestParams, 'reason'>,
) =>
  useMutation(
    ({ annotationId, reason }: { annotationId: string; reason: string }) =>
      api.patchIncomeStreamAnnotation({ annotationId, reason, ...params }),
    {
      onError: onAnnotateIncomeStreamError,
      onSuccess: () =>
        onAnnotateIncomeStreamSuccess({
          included: params.isIncluded,
          incomeStreamName: params.incomeStreamName,
        }),
    },
  )
