import * as Yup from "yup";

import { VALIDATION_MESSAGES } from "shared/config/constants";
import { validMoney } from "shared/forms/validation/fieldValidators";

export const ValidationSchema = Yup.object().shape({
  rent: validMoney
    .positive("Please enter a rent amount that is greater than $0")
    .required(VALIDATION_MESSAGES.fieldRequired),
});
