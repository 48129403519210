import React, { useEffect, useRef } from "react";

import { Row, Col } from "antd";
import { Formik, Form, FormikProps } from "formik";

import { Input, Select, VeroFormField } from "shared/components/Form";
import { Modal } from "shared/components/Modal";
import { DOCUMENT_TYPES } from "shared/config/constants/app.constants";

import { validationSchema } from "./config";
import {
  FinishDocumentModalFormik,
  FinishDocumentModalProps,
} from "./interfaces";

const FinishDocumentModal = ({
  onSubmit,
  fileName,
  error,
  documentTypes = [],
  tags = [],
  riderTypes = [],
  isDocumentTypesLoading,
  isTagsLoading,
  isRiderTypesLoading,
}: FinishDocumentModalProps) => {
  const formikRef = useRef<FormikProps<FinishDocumentModalFormik>>();

  const tagNames = tags?.map(({ name }) => name);
  const initialValues = {
    name: fileName,
    type: "",
    riderType: "",
    tags: [],
  };

  useEffect(() => {
    if (error) {
      formikRef.current.setErrors(error.errors || {});
    }
  }, [error]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      innerRef={formikRef}
    >
      {({
        submitForm,
        isSubmitting,
        isValid,
        values,
        dirty,
        setFieldValue,
      }) => {
        const onSelectType = (value) => {
          setFieldValue("type", value);
        };

        const onSelectRiderType = (value) => {
          setFieldValue("riderType", value);
        };

        const onSelectTag = (value) => {
          setFieldValue("tags", value);
        };

        return (
          <Modal
            title="Finish document onboarding"
            subtitle="Enter document name and select type"
            submit={submitForm}
            submitButtonDisabled={!isValid || !dirty}
            submitting={isSubmitting}
            closeOnSubmit={false}
            submitButtonLabel="Save &amp; Finish"
          >
            <Modal.Body>
              <Form>
                <Row gutter={[0, 20]}>
                  <Col span={24}>
                    <VeroFormField
                      as={Input}
                      id="name"
                      name="name"
                      type="text"
                      label="Document Name"
                    />
                  </Col>
                </Row>
                <Row gutter={[0, 20]}>
                  <Col span={24}>
                    <VeroFormField
                      as={Select}
                      id="type"
                      name="type"
                      label="Select Document Type"
                      onChange={onSelectType}
                      loading={isDocumentTypesLoading}
                    >
                      {documentTypes.map((item) => (
                        <Select.Option key={item.id}>
                          {item.value}
                        </Select.Option>
                      ))}
                    </VeroFormField>
                  </Col>
                </Row>
                {values.type === DOCUMENT_TYPES.rider && (
                  <Row gutter={[0, 20]}>
                    <Col span={24}>
                      <VeroFormField
                        as={Select}
                        id="riderType"
                        name="riderType"
                        label="Select Rider Type"
                        onChange={onSelectRiderType}
                        isRiderTypesLoading={isRiderTypesLoading}
                      >
                        {riderTypes.map((item) => (
                          <Select.Option key={item.id}>
                            {item.value}
                          </Select.Option>
                        ))}
                      </VeroFormField>
                    </Col>
                  </Row>
                )}
                <Row gutter={[0, 20]}>
                  <Col span={24}>
                    <VeroFormField
                      as={Select}
                      id="tags"
                      name="tags"
                      label="Add Tags (optional)"
                      mode="tags"
                      onChange={onSelectTag}
                      loading={isTagsLoading}
                    >
                      {tagNames.map((name) => (
                        <Select.Option key={name}>{name}</Select.Option>
                      ))}
                    </VeroFormField>
                  </Col>
                </Row>
              </Form>
            </Modal.Body>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default FinishDocumentModal;
