import React from "react";

import { ActionLink } from "shared/components/Links";

import { Content, StyledContainer, SwitchMagicLinksImage } from "./styled";

export const CONTENT =
  "Looks like Magic Links isn't active for your company. Please reach out to your account manager to turn it on.";

const MagicLinksContactManager = () => (
  <StyledContainer centered noPaddingTop>
    <SwitchMagicLinksImage data-testid="switch-image" />
    <Content>{CONTENT}</Content>
    <ActionLink href="mailto:hello@sayvero.com" data-testid="contact-link">
      Contact Manager
    </ActionLink>
  </StyledContainer>
);

export default MagicLinksContactManager;
