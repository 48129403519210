import React from "react";

import { useFormikContext } from "formik";
import capitalize from "lodash/capitalize";

import { AdditionalQuestionModalMode } from "manager/components/Modal/AdditionalQuestionModal/interfaces";
import { APPLICANT_TYPES_LABEL } from "manager/config/constants";
import {
  AdditionalQuestionModel,
  AdditionalQuestionOption,
} from "manager/interfaces/api/additionalQuestion";
import FlexContainer from "shared/components/FlexContainer";
import { Checkbox, VeroFormField } from "shared/components/Form";
import FullScreenLoader from "shared/components/FullScreenLoader";
import Icon from "shared/components/Icon";
import { ActionLink, IconLink } from "shared/components/Links";
import Tooltip from "shared/components/Tooltip";
import { RegularText } from "shared/components/Typography";
import { ANSWER_QUESTION_TYPES } from "shared/config/constants";
import OptionItem from "shared/interfaces/api/optionItem";
import { ApplicantType } from "shared/interfaces/misc";

import { StyledActionLink, StyledSwitchTitle, StyledTable } from "./styled";

interface AdditionalQuestionsTableProps {
  isLoading: boolean;
  questions: AdditionalQuestionModel[];
  answerTypeOptions: OptionItem[];
  applicantType: ApplicantType;
  openAdditionalQuestionModal: Function;
  openDeleteAdditionalQuestionConfirmation: Function;
  onChangeIsActiveQuestion: Function;
  description?: string;
  disabled?: boolean;
}

const AdditionalQuestionsTable = ({
  isLoading,
  description,
  questions,
  disabled,
  answerTypeOptions,
  applicantType,
  openAdditionalQuestionModal,
  openDeleteAdditionalQuestionConfirmation,
  onChangeIsActiveQuestion,
}: AdditionalQuestionsTableProps) => {
  const { values } = useFormikContext();

  const getOptions = (options: AdditionalQuestionOption[]) => (
    <ul>
      {options?.map(({ id, answer }) => (
        <li key={id}>{answer}</li>
      ))}
    </ul>
  );

  const getRows = () =>
    (questions || []).map((row) => ({
      key: row.id,
      inlinePrimaryAction: (
        <FlexContainer>
          {!row.isDefault && (
            <IconLink
              onClick={() => openDeleteAdditionalQuestionConfirmation(row.id)}
              Icon={Icon.DeleteSmallIcon}
              hoverableIcon={false}
              disabled={disabled}
              // @ts-ignore
              className="action-icon"
            />
          )}
        </FlexContainer>
      ),
      values: [
        <Checkbox
          id={`${row.id}`}
          checked={row.isActive}
          disabled={disabled || row.disabled}
          onChange={() => onChangeIsActiveQuestion(row.id)}
        />,
        <ActionLink
          standardCase
          onClick={() =>
            openAdditionalQuestionModal({
              mode: AdditionalQuestionModalMode.EDIT,
              initialValues: row,
            })
          }
          // @ts-ignore
          disabled={row.isDefault || disabled || isLoading}
        >
          <Tooltip
            title={getOptions(row.options)}
            trigger={
              row.answerType === ANSWER_QUESTION_TYPES.singleChoice
                ? "hover"
                : undefined
            }
          >
            {/* @ts-ignore */}
            <RegularText underline>{row.questionName}</RegularText>
          </Tooltip>
        </ActionLink>,
        answerTypeOptions?.find(({ id }) => id === row.answerType)?.value,
        // @ts-ignore
        <FlexContainer justifyContent="right">
          {!row.isDefault && (
            <IconLink
              onClick={() => openDeleteAdditionalQuestionConfirmation(row.id)}
              Icon={Icon.DeleteSmallIcon}
              hoverableIcon={false}
              disabled={disabled}
              // @ts-ignore
              className="action-icon"
            />
          )}
        </FlexContainer>,
      ],
    }));

  return (
    <>
      {isLoading && <FullScreenLoader />}
      {/* @ts-ignore */}
      <VeroFormField
        as={StyledSwitchTitle}
        title={`Questions for ${capitalize(
          APPLICANT_TYPES_LABEL[applicantType]
        )}s`}
        description={description}
        name={`requiresApplicationQuestions${capitalize(
          APPLICANT_TYPES_LABEL[applicantType]
        )}`}
        data-testid="switch"
        hasItems={questions.length > 0}
        disabled={disabled}
      />
      {values?.[
        `requiresApplicationQuestions${capitalize(
          APPLICANT_TYPES_LABEL[applicantType]
        )}`
      ] && (
        <>
          <StyledTable
            id={`additional-questions-${APPLICANT_TYPES_LABEL[
              applicantType
            ].toLowerCase()}-table`}
            rows={getRows()}
            pagination={false}
            placeholder={{
              Icon: Icon.NoDataIcon,
              label: "Questions",
            }}
            noClickableRows
            columns={[
              {
                title: <RegularText weak>Active</RegularText>,
                dataIndex: "isActive",
                key: "isActive",
                width: 130,
              },
              {
                title: <RegularText weak>Question</RegularText>,
                dataIndex: "questionName",
                key: "questionName",
              },
              {
                title: <RegularText weak>Type</RegularText>,
                dataIndex: "type",
                key: "type",
                width: 200,
              },
              {
                title: "",
                dataIndex: "actions",
                key: "actions",
                width: 100,
                minWidth: 100,
                align: "right",
              },
            ]}
            columnBreakpoints={{
              xs: {
                column1: "isActive",
                column2: "questionName",
                // column4: "actions",
              },
            }}
          />
          <StyledActionLink
            data-testid="add-question"
            plus
            // @ts-ignore
            disabled={disabled}
            onClick={() =>
              openAdditionalQuestionModal({
                mode: AdditionalQuestionModalMode.CREATE,
              })
            }
          >
            Custom Question
          </StyledActionLink>
        </>
      )}
    </>
  );
};

AdditionalQuestionsTable.defaultProps = {
  description: undefined,
  disabled: false,
};

export default AdditionalQuestionsTable;
