import React from "react";

import { useHistory } from "react-router-dom";

import ROUTES from "manager/config/routes";
import { useGetYardiCredentials } from "manager/hooks/api";
import { useGetIntegrationProvider } from "manager/pages/Marketplace/config";
import { transformRoute } from "shared/utils/routing";

import { CredentialsHeaderType } from "../yardi.config";

import YardiHeaderCredentials from "./YardiHeaderCredentials";

interface YardiHeaderCredentialsContainerProps {
  yardiCredentials?: CredentialsHeaderType;
  loadCredentials?: boolean;
}

const YardiHeaderCredentialsContainer = ({
  yardiCredentials,
  loadCredentials,
}: YardiHeaderCredentialsContainerProps) => {
  const history = useHistory();
  const { provider } = useGetIntegrationProvider();

  const { yardiCredentials: loadedYardiCredentials } = useGetYardiCredentials(
    provider,
    false,
    loadCredentials
  );

  const editCredentials = () => {
    history.push(
      transformRoute(ROUTES.integrationEditCredentials, {
        provider,
      })
    );
  };

  const resolvedCredentials = yardiCredentials || loadedYardiCredentials;

  const headerData = {
    yardiCredentials: resolvedCredentials,
    editCredentials,
  };

  return <YardiHeaderCredentials headerData={headerData} provider={provider} />;
};

export default YardiHeaderCredentialsContainer;
