import React from "react";

import isFunction from "lodash/isFunction";

import { useDeleteYardiPropertyMappings } from "manager/hooks/api";
import useModal from "shared/hooks/useModal";

import YardiRemovePropertyConfirmationModal from "./YardiRemovePropertyConfirmationModal";

const YardiRemovePropertyConfirmationModalContainer = () => {
  const { currentModalContext } = useModal();
  const { propertyId, removeFromActive, onSubmitEnd, provider } =
    currentModalContext;

  const { deleteYardiPropertyMappings, isLoading } =
    useDeleteYardiPropertyMappings(provider);

  const onSubmit = async () => {
    await deleteYardiPropertyMappings({
      id: propertyId,
      removeFromActive,
    });
    if (onSubmitEnd && isFunction(onSubmitEnd)) {
      onSubmitEnd();
    }
  };

  const context = {
    title: currentModalContext.title,
    content: currentModalContext.content,
    propertyName: currentModalContext.propertyName,
    veroPropertyName: currentModalContext.veroPropertyName,
    onSubmit,
    isRemoving: isLoading,
  };

  return <YardiRemovePropertyConfirmationModal {...context} />;
};

export default YardiRemovePropertyConfirmationModalContainer;
