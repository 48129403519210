import React from "react";
import _ from 'lodash'
import PropTypes from "prop-types";

import { IncomeBalancesVerification } from "manager/components/Prescreening";
import { fullApplicationPropType } from "manager/utils/propTypes";
import FooterBar from "shared/components/FooterBar";
import { NoVerificationsPlaceholder } from "shared/components/Verification";
import NoRequirementSection from "shared/components/Verification/NoRequirementSection";
import { APPLICATION_STATUSES } from "shared/config/constants";
import { Verifications } from "shared/pages/ApplicationSummary";
import { ApplicantWorkflow } from 'shared/components/vega/ApplicantWorkflow';
import LeadCompletedFooter from "./Footers/LeadCompletedFooter";
import LeadConvertedFooter from "./Footers/LeadConvertedFooter";
import { LeadSummaryContent, LeadSummaryFooter } from "./styled";

const LeadSummary = ({
  lead,
  applicantWorkflow,
  inviteLeadToApply,
  canInviteLeadsToApply,
  redirectToDeal,
}) => {
  const {
    isLeadConverted,
    applicationSummary,
    status,
    idVerifications,
    isArchived,
    personaStatus,
    personaInquiryStatus,
    personaReviewerComment,
  } = lead || {};
  const {
    isPersonaEnabled: isSnapEnabled,
    isSubmitted: isCompleted,
    isArgyleEnabled: isLynkEnabled,
    areIncomeAndAssetChecksEnabled: areIncomeAndAssetsEnabled,
  } = applicationSummary || {};

  const isIncomeVerificationEnabled =
    isLynkEnabled || areIncomeAndAssetsEnabled;

  const noVerificationsMessage =
    status === APPLICATION_STATUSES.inProgress
      ? "The prequalification process has started, but some details still need to be submitted."
      : "The lead has been invited, but has not yet started prequalification process.";

  const showLeadCompletedFooter =
    !isLeadConverted && canInviteLeadsToApply && !isArchived;

  return isCompleted ? (
    <>
      <LeadSummaryContent noPaddingTop>
        {isSnapEnabled ? (
          <Verifications
            idVerifications={idVerifications}
            applicationSummary={applicationSummary}
            personaStatus={personaStatus}
            personaInquiryStatus={personaInquiryStatus}
            personaReviewerComment={personaReviewerComment}
          />
        ) : (
          <NoRequirementSection name="ID Verification" />
        )}
        {isIncomeVerificationEnabled ? (
          <IncomeBalancesVerification lead={lead} />
        ) : (
          <NoRequirementSection name="Income & Balances" />
        )}

        {!_.isEmpty(applicantWorkflow) && <ApplicantWorkflow applicantWorkflow={applicantWorkflow}/>}
      </LeadSummaryContent>
      <FooterBar.Spacer />
      <LeadSummaryFooter noPaddingTop noPaddingBottom stickToBottom>
        {showLeadCompletedFooter && (
          <LeadCompletedFooter inviteLeadToApply={inviteLeadToApply} />
        )}
        {isLeadConverted && (
          <LeadConvertedFooter redirectToDeal={redirectToDeal} />
        )}
      </LeadSummaryFooter>
    </>
  ) : (
    !_.isEmpty(applicantWorkflow) ? <LeadSummaryContent><ApplicantWorkflow applicantWorkflow={applicantWorkflow}/></LeadSummaryContent> : <NoVerificationsPlaceholder message={noVerificationsMessage} column />
  );
};

LeadSummary.propTypes = {
  lead: fullApplicationPropType,
  inviteLeadToApply: PropTypes.func.isRequired,
  redirectToDeal: PropTypes.func.isRequired,
  canInviteLeadsToApply: PropTypes.bool,
};

LeadSummary.defaultProps = {
  lead: undefined,
  canInviteLeadsToApply: false,
};

export default LeadSummary;
