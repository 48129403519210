import React from "react";

import { Tag as StyledTag } from "./styled";
import { SIZES } from "./utils";

export interface TagProps {
  children: Node | React.ReactNode | string | [];
  size?: string;
  [key: string]: any;
}

const Tag = ({ children, size = SIZES.default, ...props }: TagProps) => (
  <StyledTag size={size} {...props}>
    {children}
  </StyledTag>
);

Tag.SIZES = SIZES;

export default Tag;
