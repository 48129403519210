import { useState } from 'react'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import {
  useAnnotateIncomeStream,
  usePatchIncomeStreamAnnotation,
} from 'renter/hooks/api'
import type { IncomeStream } from 'renter/interfaces/api/income'
import FlexContainer from 'shared/components/FlexContainer'
import { Modal } from 'shared/components/Modal'
import Spacer from 'shared/components/Spacer'
import { RegularText } from 'shared/components/Typography'
import { COLORS } from 'shared/config/constants'
import useModal from 'shared/hooks/useModal'
import { printDollarsFromCents } from 'shared/utils/dollar-print'
import { mapIncomeStreamVerificationMethodToSource } from 'shared/utils/employmentAndIncome'

import { EditIncomeStreamReason, StyledModal } from './styled'

interface EditIncomeStreamModalProps {
  applicantVrn: string
  stream: IncomeStream
  type: 'excluded' | 'included'
}

const validationSchema = Yup.object().shape({
  reason: Yup.string().trim().required('This field cannot be left blank.'),
})

export const EditIncomeStreamModal = ({
  applicantVrn,
  stream,
  type,
}: EditIncomeStreamModalProps) => {
  const [annotateIncomeStream] = useAnnotateIncomeStream({
    identifier: stream.identifier,
    incomeStreamName: stream.description,
    owner: applicantVrn,
    isIncluded: type === 'excluded',
  })
  const [patchIncomeStreamAnnotation] = usePatchIncomeStreamAnnotation({
    identifier: stream.identifier,
    incomeStreamName: stream.description,
    owner: applicantVrn,
    isIncluded: type === 'excluded',
  })
  const {
    selfApi: { closeDialog },
  } = useModal()
  const {
    errors,
    isSubmitting,
    isValid,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialErrors: { reason: '' },
    initialValues: { reason: '' },
    validateOnMount: true,
    validationSchema,
    onSubmit: async ({ reason }) => {
      if (!!stream.annotationId) {
        await patchIncomeStreamAnnotation({
          annotationId: stream.annotationId,
          reason,
        })
      } else {
        await annotateIncomeStream({ reason })
      }
      closeDialog()
    },
  })
  const [characterCount, setCharacterCount] = useState(0)

  return (
    <StyledModal
      reverseButtons
      closeOnSubmit={false}
      cancelLinkLabel="Cancel"
      submit={handleSubmit}
      submitting={isSubmitting}
      submitButtonDisabled={!isValid || isSubmitting}
      submitButtonLabel={`Confirm ${
        type === 'excluded' ? 'Verification' : 'Exclusion'
      }`}
      title={`Confirm Income ${
        type === 'excluded' ? 'Verification' : 'Removal'
      }`}
      width="550px"
    >
      <Modal.Body>
        <FlexContainer flexDirection="column">
          <RegularText>
            {type === 'excluded'
              ? 'By clicking “Confirm Verification” you agree to add this income to the Verified Income section, updating the applicant’s total annual income and recalculating the Lumen score.'
              : 'By clicking “Confirm Exclusion” you agree to move this income to Excluded Income, removing it from the applicant’s total annual income and recalculating the Lumen score.'}
          </RegularText>
          <Spacer size={Spacer.SIZES.sm} />
          <ul>
            <li>
              <RegularText>
                Source: <RegularText strong>{stream.description}</RegularText>
              </RegularText>
            </li>
            <li>
              <RegularText>
                Verification:{' '}
                <RegularText strong>
                  {mapIncomeStreamVerificationMethodToSource(
                    stream.streamVerificationMethod,
                  )}
                </RegularText>
              </RegularText>
            </li>
            <li>
              <RegularText>
                Projected Annual Income:{' '}
                <RegularText strong>
                  {printDollarsFromCents(
                    stream.estimatedAnnualGrossAmount ??
                      stream.estimatedAnnualNetAmount ??
                      0,
                  )}
                </RegularText>
              </RegularText>
            </li>
          </ul>
          <form
            style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
          >
            <label htmlFor="reason">
              {/** eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <RegularText>Enter a Reason</RegularText>
              <EditIncomeStreamReason
                disabled={isSubmitting}
                id="reason"
                name="reason"
                maxLength={255}
                rows={5}
                valid={isValid}
                onBlur={handleBlur}
                onChange={(event) => {
                  handleChange(event)
                  setCharacterCount(event.target.value.length)
                }}
              />
            </label>
            <FlexContainer
              alignItems="flex-start"
              flexDirection="row"
              justifyContent="space-between"
            >
              {errors.reason && (
                <RegularText style={{ color: COLORS.red }}>
                  {errors.reason}
                </RegularText>
              )}
              <RegularText style={{ marginLeft: 'auto' }}>
                {characterCount}/255
              </RegularText>
            </FlexContainer>
          </form>
        </FlexContainer>
      </Modal.Body>
    </StyledModal>
  )
}
