import React from "react";

import { Label as StyledLabel } from "./styled";

export type LabelTypes = "primary" | "secondary";

interface LabelProps {
  children: Element | Element[] | React.ReactNode;
  type?: string | LabelTypes;
  [key: string]: any;
}

const Label = ({ children, type = "primary", ...props }: LabelProps) => (
  <StyledLabel type={type} {...props}>
    {children}
  </StyledLabel>
);

export default Label;
