import React, { useMemo } from "react";

import { Field, Formik, Form } from "formik";
import * as Yup from "yup";

import { Input } from "shared/components/Form";
import { Modal } from "shared/components/Modal";

import { INPUT_MODE, COMPONENT_FOR_INPUT_MODE } from "./constants";

import { SingleInputContent } from "./styled";

export interface SingleInputModalProps {
  title: string;
  subtitle?: React.ReactNode;
  inputDescription?: React.ReactNode;
  inputPlaceholder: string;
  confirmLabel?: string;
  onSubmit: (
    { value }: { value: any },
    { setSubmitting }: { setSubmitting: any }
  ) => void;
  initialValue?: string;
  valueValidationSchema?: any;
  closeOnSubmit?: boolean;
  inputMode?: string;
  maxLength?: number;
  showCount?: boolean;
  extraContentRenderer?: (values: any) => void;
  error?: (string | boolean | string[]) & string;
}

const SingleInputModal = ({
  title,
  subtitle = undefined,
  inputDescription = undefined,
  inputPlaceholder,
  confirmLabel = "CONFIRM",
  onSubmit,
  initialValue = undefined,
  valueValidationSchema = undefined,
  closeOnSubmit = undefined,
  inputMode = INPUT_MODE.singleLine,
  maxLength = undefined,
  showCount = false,
  extraContentRenderer = undefined,
}: SingleInputModalProps) => {
  const InputComponent = COMPONENT_FOR_INPUT_MODE[inputMode] || Input;

  const ValidationSchema = useMemo(
    () =>
      Yup.object().shape({
        value: valueValidationSchema,
      }),
    [valueValidationSchema]
  );

  const isMoneyInput = () => inputMode === INPUT_MODE.money;

  return (
    <Formik
      initialValues={{ value: initialValue }}
      onSubmit={onSubmit}
      enableReinitialize
      validationSchema={ValidationSchema}
      validateOnMount
    >
      {({
        values,
        isValid,
        errors,
        submitForm,
        touched,
        isSubmitting,
        dirty,
      }) => (
        <Modal
          centered
          showSubmitButton
          showCancelLink
          submitButtonLabel={confirmLabel}
          submitButtonDisabled={!isValid}
          submit={submitForm}
          submitting={isSubmitting}
          title={title}
          subtitle={subtitle}
          closeOnSubmit={closeOnSubmit}
        >
          <Modal.Body noPaddingTop noPaddingBottom>
            <Form>
              <SingleInputContent>
                <span className="input-description">{inputDescription}</span>
                {/* TODO(V2-3113): Use the components from COMPONENT_FOR_INPUT_MODE instead of the Field wrapper
                    to avoid specific logic as the one we have for the money input. */}
                {isMoneyInput() && (
                  <InputComponent
                    name="value"
                    id="value"
                    label={inputPlaceholder}
                    required
                    // @ts-ignore
                    error={dirty && touched.value && errors.value}
                    tabIndex={0}
                    autoFocus
                  />
                )}
                {!isMoneyInput() && (
                  <Field
                    name="value"
                    id="value"
                    type="text"
                    label={inputPlaceholder}
                    as={InputComponent}
                    error={dirty && touched.value && errors.value}
                    tabIndex={0}
                    autoFocus
                    maxLength={maxLength}
                    showCount={showCount}
                  />
                )}
                {extraContentRenderer && extraContentRenderer(values)}
              </SingleInputContent>
            </Form>
          </Modal.Body>
        </Modal>
      )}
    </Formik>
  );
};

export default SingleInputModal;
