
import { Col, Row } from "antd";

import AssignDealRevenueManagementModal from "manager/components/Modal/AssignDealRevenueManagementModal";
import {
  useAssignDealToUnit,
  useGetDeal,
  usePrimaryApplicantDeal,
  useUnitListingDeal,
} from "manager/hooks/api";
import { assignDealToUnitCheck } from "manager/lib/dealsApi";
import ConfirmationModal from "shared/components/Modals/ConfirmationModal";
import Spacer from "shared/components/Spacer";
import { RegularText } from "shared/components/Typography";
import { NOTIFICATIONS } from "shared/config/constants";
import useModal from "shared/hooks/useModal";
import pricingOfferApi from "shared/lib/api/pricingOfferApi";
import { openNotification } from "shared/utils/ui";

import { printDollarsFromCents } from "shared/utils/dollar-print";
import AssignDealToUnitModal from "./AssignDealToUnitModal";

const AssignDealToUnitModalContainer = () => {
  const {
    currentModalContext: { dealId, refetchDeal },
    openModalDialog,
    selfApi,
  } = useModal();

  const {
    data: deal,
    isLoading: isDealLoading,
    refetch: dealRefetch,
  } = useGetDeal(dealId, {
    enabled: Boolean(dealId),
  });

  const { unitListingDealRefetch } = useUnitListingDeal(dealId, {
    retry: false,
    enabled: deal?.unit?.name,
  });

  const { primaryApplicantDealRefetch } = usePrimaryApplicantDeal(dealId, {
    retry: false,
    enabled: deal?.applications?.find((app) => app?.isPrimary),
  });

  const { assignDealToUnit } = useAssignDealToUnit();

  const resumeAssignDealToUnitFlow = (data) =>
    assignDealToUnit(data, {
      onSuccess: async (updatedDeal) => {
        if (refetchDeal) refetchDeal();
        else dealRefetch();

        unitListingDealRefetch();
        primaryApplicantDealRefetch();

        let pricingOffers;
        if (updatedDeal?.unit?.hasLroEnabled && updatedDeal?.unit?.externalId) {
          try {
            pricingOffers = await pricingOfferApi.getPricingOffers({
              location: updatedDeal.unit.externalId,
            });
          } catch {
            // do nothing
          }
        }

        if (pricingOffers?.length) {
          openModalDialog(AssignDealRevenueManagementModal, {
            dealId: updatedDeal.id,
            refetchDeal: refetchDeal || dealRefetch,
            unitLocation: updatedDeal.unit.externalId,
            propertyName: updatedDeal.property?.name,
            unitName: updatedDeal.unit.name,
            availableOn: updatedDeal.unit.unitAvailableOn,
            pricingOffers,
          });
        } else {
          selfApi.closeDialog();
        }
      },
      onError: () => selfApi.closeDialog(),
    });

  const showWarningConfirmationModal = (data, unitCheckResult) => {
    openModalDialog(ConfirmationModal, {
      title: "Reassign the unit",
      message: (
        <>
          <RegularText weak>
            Please note that the new unit has a different holding deposit than
            the amount already collected.
          </RegularText>
          <Spacer />
          <Row>
            <Col xs={18} sm={12}>
              <RegularText weak>Collected Holding Deposit:</RegularText>
            </Col>
            <Col xs={6} sm={12}>
              <RegularText strong>
                {printDollarsFromCents(
                  unitCheckResult.currentHoldingDepositFee
                )}
              </RegularText>
            </Col>
          </Row>
          <Row>
            <Col xs={18} sm={12}>
              <RegularText weak>New Unit Holding Deposit:</RegularText>
            </Col>
            <Col xs={6} sm={12}>
              <RegularText strong>
                {printDollarsFromCents(unitCheckResult.newHoldingDepositFee)}
              </RegularText>
            </Col>
          </Row>
        </>
      ),
      description: `The application group will not be charged or refunded any difference in the two holding deposit amounts.`,
      onConfirm: () => resumeAssignDealToUnitFlow(data),
    });
  };

  const performUnitCheck = async ({ id, unitId, newLeaseStartDate }) => {
    let unitCheckResult;

    try {
      unitCheckResult = await assignDealToUnitCheck({
        dealId: id,
        newLeaseStartDate,
        unitId,
      });
    } catch (error) {
      openNotification(
        `Failed to check the unit accessability`,
        NOTIFICATIONS.error
      );
      selfApi.closeDialog();
    }

    return unitCheckResult;
  };

  const onAssignDealToUnitSubmit = async (data) => {
    const { id, unitId, newLeaseStartDate } = data;

    const unitCheckResult = await performUnitCheck({
      id,
      unitId,
      newLeaseStartDate,
    });

    if (unitCheckResult) {
      if (
        unitCheckResult.holdingDepositPaymentStarted &&
        unitCheckResult.isHoldingDepositChanged
      ) {
        showWarningConfirmationModal(data, unitCheckResult);
      } else {
        await resumeAssignDealToUnitFlow(data);
      }
    }
  };

  return (
    <AssignDealToUnitModal
      dealId={dealId}
      deal={deal}
      assignDealToUnit={onAssignDealToUnitSubmit}
      isDealLoading={isDealLoading}
    />
  );
};

export default AssignDealToUnitModalContainer;
