import React, { useState } from "react";

import { UnderlineLink } from "shared/components/Links";
import { RegularText } from "shared/components/Typography";
import { ChevronDownGreenIcon, ChevronUpGreenIcon } from "shared/icons";

import { MarketplaceTileProps } from "../interfaces";

import {
  Tile,
  TileInner,
  TileContent,
  TileLink,
  PartnerLogo,
  ReadMore,
  ReadMoreIcon,
} from "./styled";

const MAX_DESCRIPTION_LENGTH = 140;
const READ_MORE_TEXT = "Show More";
const READ_LESS_TEXT = "Show Less";

const MarketplaceTile = ({
  defaultCollapsed = true,
  partner,
}: MarketplaceTileProps) => {
  const [collapsed, setCollapsed] = useState<boolean>(defaultCollapsed);
  const toggleCollapse = () => setCollapsed(!collapsed);

  const getDescription = (description: string) => {
    let resolvedDescription = description;
    if (description.length > MAX_DESCRIPTION_LENGTH) {
      let resolvedLabel = READ_LESS_TEXT;
      let resolvedChevron = <ChevronUpGreenIcon style={{}} />;
      if (collapsed) {
        resolvedDescription = `${description.substring(
          0,
          MAX_DESCRIPTION_LENGTH
        )}...`;
        resolvedLabel = READ_MORE_TEXT;
        resolvedChevron = <ChevronDownGreenIcon />;
      }
      return (
        <RegularText className="tile-description">
          {resolvedDescription}
          <ReadMore onClick={toggleCollapse} onKeyPress={toggleCollapse}>
            <UnderlineLink dashed>{resolvedLabel}</UnderlineLink>
            <ReadMoreIcon>{resolvedChevron}</ReadMoreIcon>
          </ReadMore>
        </RegularText>
      );
    }
    return (
      <RegularText className="tile-description">{description}</RegularText>
    );
  };

  return (
    <Tile tabIndex={0}>
      <MarketplaceTile.Inner>
        <MarketplaceTile.Content
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <MarketplaceTile.Link
            data-testid="renter-marketplace-link"
            href={partner.internalUrl}
            target="_blank"
          >
            <PartnerLogo
              data-testid="renter-marketplace-logo"
              src={partner.logoUrl}
              alt={partner.name}
            />
          </MarketplaceTile.Link>
          {getDescription(partner.description)}
        </MarketplaceTile.Content>
      </MarketplaceTile.Inner>
    </Tile>
  );
};

MarketplaceTile.Inner = TileInner;
MarketplaceTile.Link = TileLink;
MarketplaceTile.Content = TileContent;

export default MarketplaceTile;
