import React from "react";

import { SourcesCreateEditModalTemplate } from "manager/components/Modal/SourcesCreateEditModal";
import {
  useGetLeadSources,
  useYardiResolveCreateLeadSource,
} from "manager/hooks/api";
import useModal from "shared/hooks/useModal";
import { sanitizeStringSearchValue } from "shared/utils/string";

export const YardiCreateLinkLeadSourceResolverModalContainer = () => {
  const { currentModalContext } = useModal();
  const { alert, provider } = currentModalContext;
  const { leadSources } = useGetLeadSources();
  const { resolveAlertLeadSource } = useYardiResolveCreateLeadSource();
  const usedNames = (leadSources || []).map((sourceIt) =>
    sanitizeStringSearchValue(sourceIt.value)
  );

  const customSubtitle = <></>;

  const saveAction = async ({ sourceName }) => {
    const payload = {
      source: {
        value: sourceName,
      },
    };
    await resolveAlertLeadSource({
      provider,
      alertId: alert?.id,
      body: payload,
    });
  };

  return (
    <SourcesCreateEditModalTemplate
      title="Link lead source with VERO"
      customSubtitle={customSubtitle}
      inputDescription={<strong>Enter new VERO lead source name</strong>}
      inputPlaceholder="Lead source name"
      onSubmit={saveAction}
      usedNames={usedNames}
    />
  );
};

export default YardiCreateLinkLeadSourceResolverModalContainer;
