import React from "react";

import { Row, Col } from "antd";
import { Formik, FormikHelpers } from "formik";

import { MODALS } from "manager/config/constants";
import {
  Input,
  PasswordInput,
  StateSelect,
  VeroFormField,
} from "shared/components/Form";
import { Modal } from "shared/components/Modal";
import Spacer from "shared/components/Spacer";
import { RegularText } from "shared/components/Typography";

import { initialValues, ValidationSchema } from "./config";
import { BluemoonLicense } from "./interfaces";

interface BluemoonConnectLicenseModalProps {
  submit: (
    values: BluemoonLicense,
    formikApi: FormikHelpers<BluemoonLicense>
  ) => void;
}

const BluemoonConnectLicenseModal = ({
  submit,
}: BluemoonConnectLicenseModalProps) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submit}
      enableReinitialize
      validationSchema={ValidationSchema}
      validateOnMount
    >
      {({ submitForm, isSubmitting }) => (
        <Modal
          id={MODALS.bluemoonConnectLicense}
          width="516px"
          title="Connect license"
          submit={submitForm}
          submitting={isSubmitting}
          closeOnSubmit={false}
          subtitle={
            <RegularText>
              Provide Bluemoon credentials for each license separately
            </RegularText>
          }
        >
          <Modal.Body>
            <Row gutter={[20, 25]}>
              <Col md={{ span: 12 }}>
                <VeroFormField
                  as={Input}
                  name="code"
                  id="code"
                  label="License"
                  required
                />
              </Col>
              <Col md={{ span: 12 }}>
                <VeroFormField
                  as={StateSelect}
                  name="state"
                  id="state"
                  label="State"
                  required
                />
              </Col>
            </Row>
            <Spacer />
            <Row>
              <Col>
                <VeroFormField
                  as={Input}
                  name="name"
                  id="name"
                  label="License Nickname (optional)"
                />
              </Col>
            </Row>
            <Spacer />
            <Row gutter={[20, 25]}>
              <Col md={12}>
                <VeroFormField
                  as={Input}
                  name="username"
                  id="username"
                  label="User ID"
                  autoComplete="new-username"
                />
              </Col>
              <Col md={12}>
                <VeroFormField
                  as={PasswordInput}
                  name="password"
                  id="password"
                  label="Password"
                  autoComplete="new-password"
                />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      )}
    </Formik>
  );
};

export default BluemoonConnectLicenseModal;
