import React from "react";

import { MODALS } from "renter/config/constants";
import ApplicationStatus from "shared/components/ApplicationStatus";
import UnderlineLink from "shared/components/Links/UnderlineLink";
import List from "shared/components/List";
import { Modal } from "shared/components/Modal";
import { getValueOrNA } from "shared/utils/ui";

import { ApplicationGroupIndividualsModalProps } from "./interfaces";
import {
  Field,
  FieldLabel,
  Fields,
  Label,
  PropertyName,
  StyledListItem,
} from "./styled";

const fields = [
  { label: "Name", width: "250px" },
  { label: "Role", width: "250px" },
  { label: "Application Status", width: "auto" },
];

const ApplicationGroupIndividualsModal = ({
  contactManager,
  applicants,
  unitName,
}: ApplicationGroupIndividualsModalProps) => (
  <Modal
    title="Application group individuals"
    subtitle={
      <>
        Unit: <PropertyName>{`${getValueOrNA(unitName)}`}</PropertyName>
      </>
    }
    id={MODALS.applicationGroupIndividuals}
    showSubmitButton={false}
    showCancelLink={false}
    width="auto"
    style={{ maxWidth: 776 }}
  >
    <Modal.Body noPaddingBottom noMobilePaddingX>
      <List>
        <StyledListItem last={applicants.length === 0}>
          <Fields>
            {fields.map((item) => (
              <Field width={item.width} first key={item.label}>
                <FieldLabel first>{item.label}</FieldLabel>
              </Field>
            ))}
          </Fields>
        </StyledListItem>
        {applicants.map((item, index) => (
          <StyledListItem
            key={item.fullName}
            last={index === applicants.length - 1}
          >
            <Fields>
              <Field width={fields[0].width}>{item.fullName}</Field>
              <Field width={fields[1].width}>{item.type}</Field>
              <Field width={fields[2].width}>
                <ApplicationStatus status={item.status} />
              </Field>
            </Fields>
          </StyledListItem>
        ))}
      </List>
    </Modal.Body>
    <Modal.Body noPaddingTop noPaddingBottom>
      <Label data-testid="message">
        If you notice wrong people in the group, please{" "}
        <UnderlineLink data-testid="click-here" onClick={contactManager}>
          click here
        </UnderlineLink>
      </Label>
    </Modal.Body>
  </Modal>
);

export default ApplicationGroupIndividualsModal;
