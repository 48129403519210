import React, { useEffect, useState } from "react";

import { useParams, useHistory } from "react-router-dom";

import { ROUTES as LEAD_ROUTES } from "leads/config/routes";
import {
  useBeginPrequalification,
  useAreVettGuestsEnabled,
} from "leads/hooks/prequalificationQueries";
import {
  useGetApplicantProfile,
  useGetApplicationInvitation,
} from "renter/hooks/api";
import { getStartingStep } from "renter/utils/application-routing.utils";
import { useAfterSubmitCallbacksApi } from "shared/components/AfterSubmitCallbackRegisterProvider";
import { transformRoute } from "shared/utils/routing";
import { areSameAsStrings } from "shared/utils/string";

import {
  OptionType,
  LEADS_OPTIONS,
} from "./components/SelectPreferrence/interfaces";
import LeadAcceptTermsVettPCode from "./LeadAcceptTermsVettPCode";

interface ParamsTypes {
  id: string;
}

export interface SubmitPayloadAcceptTerms {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  termsOfService: boolean;
}

const LeadAcceptTermsContainer = () => {
  const { id: applicationId } = useParams<ParamsTypes>();
  const history = useHistory();
  const [selectedPreferrence, setSelectedPreferrence] = useState<OptionType>(
    LEADS_OPTIONS.PROSPECT
  );
  const [loader, setLoader] = useState(false);
  const { application, isApplicationLoading, refetchApplicationInvitation } =
    useGetApplicationInvitation(applicationId);

  const { areVettGuestsEnabledData, isVettGuestsEnabledLoading } =
    useAreVettGuestsEnabled(applicationId);

  const { areVettGuestsEnabled } = areVettGuestsEnabledData || {};

  const { applicantProfile, isLoading: applicantProfileIsLoading } =
    useGetApplicantProfile();

  const afterSubmitCallbackApi = useAfterSubmitCallbacksApi();
  const { email, firstName, lastName, phoneNumber } = applicantProfile ?? {};

  const user = {
    email,
    firstName,
    lastName,
    phoneNumber,
  };

  const onSuccess = async () => {
    await afterSubmitCallbackApi.executeAfterSubmitCallbacks();
    await refetchApplicationInvitation();
    setLoader(false);
    history.replace(
      transformRoute(LEAD_ROUTES.leadStart, {
        id: applicationId,
      })
    );
  };

  const { beginPrequalification, isBeginPrequalificationLoading } =
    useBeginPrequalification();

  const submit = (payload: SubmitPayloadAcceptTerms) => {
    setLoader(true);
    beginPrequalification({ applicationId, payload }, { onSuccess });
  };

  useEffect(() => {
    if (areSameAsStrings(application?.id, applicationId)) {
      const url = getStartingStep(application.steps);
      if (url && url !== LEAD_ROUTES.leadAcceptTerms) {
        history.replace(
          transformRoute(LEAD_ROUTES.leadStart, {
            id: applicationId,
          })
        );
      }
    }
  }, [application?.id, applicationId]);

  const handleSelectPreferrence = (e, setFieldValue) => {
    const { id } = e.currentTarget;
    setSelectedPreferrence(id);
    setFieldValue("prescreeningType", id);
  };

  const isLoading =
    applicantProfileIsLoading ||
    !application ||
    isApplicationLoading ||
    isVettGuestsEnabledLoading ||
    isBeginPrequalificationLoading ||
    loader;

  if (!isLoading && !areVettGuestsEnabled) {
    history.replace(
      transformRoute(LEAD_ROUTES.leadStart, {
        id: applicationId,
      })
    );

    return null;
  }

  return <LeadAcceptTermsVettPCode
    user={user}
    isLoading={isLoading}
    submit={submit}
    applicationId={applicationId}
    onSelectPreferrence={handleSelectPreferrence}
    selectedPreferrence={selectedPreferrence}
  />
};

export default LeadAcceptTermsContainer;
