import React from "react";

import { Col, Row } from "antd";
import { Field } from "formik";

import { Select } from "shared/components/Form";
import { Modal } from "shared/components/Modal";
import NumberTitle from "shared/components/NumberTitle";

import { InvitationLeadSourceSelectionProps } from "./interfaces";

const InvitationLeadSourceSelection = ({
  number,
  leadSources = [],
  leadSourcesLoading,
  touched,
  errors,
  setFieldTouched,
  setFieldValue,
}: InvitationLeadSourceSelectionProps) => {
  return (
    <Modal.Body>
      <NumberTitle number={number}>
        How did the applicant first come in contact with you?
      </NumberTitle>
      <Row gutter={[20, 25]}>
        <Col md={8}>
          <Field
            as={Select}
            name="leadSource"
            id="leadSource"
            loading={leadSourcesLoading}
            label="Select Lead Source"
            onChange={(value) => {
              setFieldValue("leadSource", value);
            }}
            onBlur={() => setFieldTouched("leadSource", true)}
            error={touched.leadSource && errors.leadSource}
            data-testid="leadSource-dropdown"
          >
            {leadSources.map((leadSource) => (
              <Select.Option key={leadSource.id}>
                {leadSource.value}
              </Select.Option>
            ))}
          </Field>
        </Col>
      </Row>
    </Modal.Body>
  );
};

export default InvitationLeadSourceSelection;
