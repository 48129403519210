/* eslint-disable no-restricted-syntax */
import { replace } from "connected-react-router";
import { call, all, put } from "redux-saga/effects";

import { NOTIFICATIONS, HTTP_ERROR_CODES } from "shared/config/constants";
import ROUTES from "shared/config/routes";
import { ApiError, logError } from "shared/lib/errors";
import { openNotification } from "shared/utils/ui";

const httpResponseSubscribers = [];

export const notifyHttpResponseSubscribers = (responseOrError) => {
  (httpResponseSubscribers || []).forEach((action) => action(responseOrError));
};

export const subscribeToHttpResponses = (action) => {
  httpResponseSubscribers.push(action);
  const unsubscribe = () => {
    const idx = httpResponseSubscribers.indexOf(action);
    if (idx > -1) {
      httpResponseSubscribers.splice(idx, 1);
    }
  };

  return {
    unsubscribe,
  };
};

export function* notifyAboutErrors(
  error,
  defaultMessage = "Something went wrong"
) {
  yield error.errors?.nonFieldErrors instanceof Array &&
  error.errors?.nonFieldErrors.length > 0
    ? all(
        error.errors.nonFieldErrors.map((item) =>
          call(openNotification, item, NOTIFICATIONS.error)
        )
      )
    : call(openNotification, defaultMessage, NOTIFICATIONS.error);
}

export function* handleSagaError(error, reject, defaultMessage) {
  const isApiError = error instanceof ApiError;

  if (isApiError) {
    if (reject) {
      yield call(reject, error.errors);
    }

    if (error.statusCode === HTTP_ERROR_CODES.notFound) {
      yield put(replace(ROUTES.notFound));
    } else if (
      error.statusCode !== ApiError.SESSION_EXPIRED &&
      error.statusCode !== HTTP_ERROR_CODES.unauthorized
    ) {
      if (defaultMessage && window.location.pathname !== ROUTES.notFound) {
        yield call(notifyAboutErrors, error, defaultMessage);
      }
    }
  } else {
    yield call(logError, error);

    if (reject) {
      yield call(reject, {
        nonFieldErrors: ["Something went wrong"],
      });
    }
  }
}

// TODO (V2-2357) goran: Simplify this util or handle it case by case after we have better centralized error handling.
export const onDialogSubmitError = (formikApi, history, customMessage) => {
  const defaultMessage = customMessage || "Something went wrong.";
  return (error) => {
    formikApi.setSubmitting(false);

    const isApiError = error instanceof ApiError;
    if (isApiError) {
      formikApi.setErrors(error.errors);

      if (error.statusCode === HTTP_ERROR_CODES.notFound) {
        history.replace(ROUTES.notFound);
      } else if (
        error.statusCode !== ApiError.SESSION_EXPIRED &&
        error.statusCode !== HTTP_ERROR_CODES.unauthorized
      ) {
        if (window.location.pathname !== ROUTES.notFound) {
          const hasNonFieldErrors =
            error.errors?.nonFieldErrors instanceof Array &&
            error.errors?.nonFieldErrors.length > 0;
          if (hasNonFieldErrors) {
            error.errors.nonFieldErrors.map((item) =>
              openNotification(item, NOTIFICATIONS.error)
            );
          } else {
            openNotification(defaultMessage, NOTIFICATIONS.error);
          }
        }
      }
    } else {
      logError(error);
      openNotification(defaultMessage, NOTIFICATIONS.error);
    }
  };
};

export function* optionallyCall({ fn, args = [], condition }) {
  if (condition) {
    yield call(fn, ...args);
  }
}

export const cloneFormData = (formData) => {
  const data = new FormData();

  // eslint-disable-next-line no-unused-vars
  for (const pair of formData.entries()) {
    const [key, value] = pair;
    data.set(key, value);
  }

  return data;
};

export const cloneFailedResponseData = (data) => {
  if (data) {
    if (data instanceof FormData) {
      return cloneFormData(data);
    }

    try {
      return JSON.parse(data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn("Unable to parse the response data");
    }
  }

  return data;
};

export const createMockApiResponse = (responseModel = {}, timeout = 3000) =>
  new Promise((resolve) => setTimeout(() => resolve(responseModel), timeout));
