import React from "react";

import Icon from "shared/components/Icon";
import { ActionLink } from "shared/components/Links";
import {
  PERSONA_STATUS,
  PERSONA_VERIFICATION_STATUS_TYPES,
} from "shared/config/constants";
import ROUTES from "shared/config/routes";
import { useDeviceType } from "shared/hooks";
import { transformRoute } from "shared/utils/routing";

import CommonVerificationItem from "./CommonVerificationItem";

export interface SnapVerificationProps {
  isSnapEnabled?: boolean;
  status?: string | PERSONA_VERIFICATION_STATUS_TYPES;
  applicationId?: string;
}

const SNAP_SUCCESS_MSG = "Identity verified by VEROSnap";
const SNAP_PENDING_MSG = "Pending identity verification";
const SNAP_FAILED_MSG =
  "Applicant failed to automatically verify their identity.";

const SnapVerification = ({
  isSnapEnabled,
  status,
  applicationId,
}: SnapVerificationProps) => {
  const { hasTouch, isMobile } = useDeviceType();
  let SnapIcon = Icon.SnapFailed;
  let message = SNAP_FAILED_MSG;

  const isVerified = status === PERSONA_STATUS.COMPLETED;

  const isPending =
    status === PERSONA_STATUS.PENDING || status === PERSONA_STATUS.IN_PROGRESS;

  const verificationsRoute =
    hasTouch || !applicationId
      ? undefined
      : transformRoute(ROUTES.applicationVerifications, {
          id: applicationId,
        });

  if (isVerified) {
    SnapIcon = Icon.SnapPassed;
    message = SNAP_SUCCESS_MSG;
  }
  if (isPending) {
    SnapIcon = Icon.SnapPending;
    message = SNAP_PENDING_MSG;
  }
  return isSnapEnabled ? (
    <CommonVerificationItem message={message}>
      <ActionLink
        Icon={() => <SnapIcon small={isMobile} />}
        to={verificationsRoute}
      />
    </CommonVerificationItem>
  ) : null;
};

export default SnapVerification;
