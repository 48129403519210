import React, { useState } from "react";

import { Col, Row } from "antd";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import Container from "shared/components/Container";
import { BackLink } from "shared/components/Links";
import PageTitle from "shared/components/PageTitle";

import ClientRequestTable from "./ClientRequestTable";
import OperationMessageTable from "./OperationMessageTable";
import OperationTrackingTable from "./OperationTrackingTable";
import { TableTitle } from "./styled";
import VeroEventTable from "./VeroEventTable";
import YardiActionTrackingTable from "./YardiActionTrackingTable";

const DealExtraDetails = ({
  backLink,
  yariOperationTracking,
  isLoadingOperationTracking,
  yardiActionTracking,
  isLoadingActionTracking,
  yardiVeroEvent,
  isLoadingVeroEvent,
}) => {
  const [isOpenMessageTable, setIsOpenMessageTable] = useState(false);
  const [isOpenRequestTable, setIsOpenRequestTable] = useState(false);
  const [trackingId, setTrackingId] = useState(undefined);

  const openYardiOperetionMessagesTable = (id) => {
    setIsOpenMessageTable(true);
    setIsOpenRequestTable(false);
    setTrackingId(id);
  };

  const openYardiClientReqResTable = (id) => {
    setIsOpenMessageTable(false);
    setIsOpenRequestTable(true);
    setTrackingId(id);
  };

  return (
    <>
      <Helmet>
        <title>Extra Details Application</title>
      </Helmet>
      <Container expand noMobilePaddingX>
        <Container noPadding>
          <BackLink.Container>
            <BackLink {...backLink} />
          </BackLink.Container>
          <PageTitle.Container center>
            <PageTitle>Extra Details Application</PageTitle>
          </PageTitle.Container>
        </Container>
        <Row gutter={[10, 30]}>
          <Col span={24}>
            <TableTitle data-testid="tracking-table-title">
              Operation Tracking
            </TableTitle>
            <OperationTrackingTable
              id={trackingId}
              yariOperationTracking={yariOperationTracking}
              isLoadingOperationTracking={isLoadingOperationTracking}
              openYardiOperetionMessagesTable={openYardiOperetionMessagesTable}
              openYardiClientReqResTable={openYardiClientReqResTable}
            />
          </Col>
          {isOpenMessageTable && (
            <Col span={24}>
              <TableTitle data-testid="message-table-title">
                Operation Message
              </TableTitle>
              <OperationMessageTable id={trackingId} />
            </Col>
          )}
          {isOpenRequestTable && (
            <Col span={24}>
              <TableTitle data-testid="request-table-title">
                Client Request
              </TableTitle>
              <ClientRequestTable id={trackingId} />
            </Col>
          )}
          <Col lg={{ span: 12 }} md={{ span: 24 }}>
            <TableTitle data-testid="action-table-title">
              Yardi Action Tracking
            </TableTitle>
            <YardiActionTrackingTable
              yardiActionTracking={yardiActionTracking}
              isLoadingActionTracking={isLoadingActionTracking}
            />
          </Col>
          <Col lg={{ span: 12 }} md={{ span: 24 }}>
            <TableTitle data-testid="event-table-title">VERO Event</TableTitle>
            <VeroEventTable
              yardiVeroEvent={yardiVeroEvent}
              isLoadingVeroEvent={isLoadingVeroEvent}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

DealExtraDetails.propTypes = {
  backLink: PropTypes.object.isRequired,
  yariOperationTracking: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoadingOperationTracking: PropTypes.bool.isRequired,
  yardiActionTracking: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoadingActionTracking: PropTypes.bool.isRequired,
  yardiVeroEvent: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoadingVeroEvent: PropTypes.bool.isRequired,
};

export default DealExtraDetails;
