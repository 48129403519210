import React, {
  useEffect,
  useMemo,
  useContext,
  createContext,
  useRef,
} from "react";

import { useLocation } from "react-router-dom";

import { logError } from "shared/lib/errors";

interface AfterSubmitCallbackRegisterApi {
  registerAfterSubmitCallback: (
    afterSubmitCallback: AfterSubmitCallback
  ) => void;
  executeAfterSubmitCallbacks: (
    onSuccess?: () => void,
    onError?: (e: Error) => void
  ) => Promise<void>;
}

const AfterSubmitCallbackRegisterContext = createContext<
  AfterSubmitCallbackRegisterApi | undefined
>(undefined);

type AfterSubmitCallback = () => void;
type AfterSubmitCallbackRegisterEntries = AfterSubmitCallback[];

export const AfterSubmitCallbackRegisterProvider = ({ children }) => {
  const afterSubmitRegisterRef = useRef<
    AfterSubmitCallbackRegisterEntries | []
  >([]);

  const activeRoute = useLocation().pathname;

  const registerAfterSubmitCallback = (
    afterSubmitCallback: AfterSubmitCallback
  ) => {
    const newAfterSubmitRegister = [
      ...afterSubmitRegisterRef.current,
      afterSubmitCallback,
    ];
    afterSubmitRegisterRef.current = newAfterSubmitRegister;
  };

  const clearRegister = () => {
    afterSubmitRegisterRef.current = [];
  };

  const executeAfterSubmitCallbacks = async (
    onSuccess?: () => void,
    onError?: (e: Error) => void
  ) => {
    const onSuccessCallback = () => {
      clearRegister();
      if (onSuccess) {
        onSuccess();
      }
    };
    const onErrorCallback = (e) => {
      logError(e);
      if (onError) {
        onError(e);
      }
    };
    return Promise.all(
      afterSubmitRegisterRef.current.map((callback) => callback())
    )
      .then(onSuccessCallback)
      .catch(onErrorCallback);
  };

  const registerApi = useMemo(() => {
    return {
      registerAfterSubmitCallback,
      executeAfterSubmitCallbacks,
    };
  }, []);

  useEffect(() => {
    return () => {
      // Clear the after submit register on unmount
      clearRegister();
    };
  }, []);

  useEffect(() => {
    // Clear register when a route changes
    clearRegister();
  }, [activeRoute]);

  return (
    <AfterSubmitCallbackRegisterContext.Provider value={registerApi}>
      {children}
    </AfterSubmitCallbackRegisterContext.Provider>
  );
};

export const useAfterSubmitCallbacksApi = () => {
  const afterSubmitRegisterContext = useContext(
    AfterSubmitCallbackRegisterContext
  );

  return afterSubmitRegisterContext;
};
