import _ from 'lodash'
import React, { useState } from 'react'
import { Input, InputNumber } from 'antd'
import { Button } from 'shared/components/Button'
import { buildClassName } from 'shared/utils/vega'
import { getScoreLabelStyle } from 'shared/components/vega/LumenV2'
import LabelColor from './LabelColor'

const ScoreLabelRangeAndColor = ({ scoreLabel, rangeAndColor, canEdit, onChange, onDelete }) => {
  const [low, high, scoreLabelColor] = rangeAndColor
  const color = getScoreLabelStyle(scoreLabelColor)
  const handleChange = (i) => (value) => onChange(_.set(rangeAndColor, i, value))

  return (
    <div className='vega flex'>
      <div>
        <div className='vega label'>label</div>
        <div className={buildClassName(['vega title lumen-score-label', color?.className && color?.className])} style={color?.style && color?.style}>{scoreLabel}</div>
      </div>

      <div>
        <div className='vega label'>low</div>
        <InputNumber
          min={0}
          max={high}
          value={low}
          onChange={handleChange(0)}
          disabled={!canEdit}
        />
      </div>

      <div>
        <div className='vega label'>high</div>
        <InputNumber
          min={low}
          max={100}
          value={high}
          onChange={handleChange(1)}
          disabled={!canEdit}
        />
      </div>

      <div>
        <div className='vega label'>label color</div>
        <LabelColor
          labelColor={scoreLabelColor}
          canEdit={canEdit}
          onChange={handleChange(2)}
        />
      </div>

      {canEdit && <div className='vega actions end'>
        <Button type='primary' onClick={onDelete}>delete</Button>
      </div>}
    </div>
  )
}

const ScoreLabels = ({ scoreLabels = {}, canEdit, onChange }) => {
  const [keyToAdd, setKeyToAdd] = useState(null)
  const handleChange = (k) => (value) => onChange(_.set(scoreLabels, [k], value))
  const handleDelete = (k) => () => onChange(_.omit(scoreLabels, [k]))

  return (
    <div className='vega flex column list bottom'>
      {_.map(scoreLabels, (rangeAndColor, scoreLabel) => <ScoreLabelRangeAndColor
        scoreLabel={scoreLabel}
        rangeAndColor={rangeAndColor}
        canEdit={canEdit}
        onChange={handleChange(scoreLabel)}
        onDelete={handleDelete(scoreLabel)}
        key={scoreLabel}
      />)}

      {canEdit && <div>
        <div className='vega label'>label</div>
        <div className='vega flex no-wrap'>
          <Input value={keyToAdd} onChange={e => setKeyToAdd(e?.target?.value)} />

          <Button type='primary' onClick={() => {
            if (keyToAdd) {
              handleChange(keyToAdd)([0, 0, 'pass'])
              setKeyToAdd(null)
            }
          }}>add</Button>
        </div>
      </div>}
    </div>
  )
}

export default ScoreLabels
