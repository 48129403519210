import styled from "@emotion/styled";

import FlexContainer from "shared/components/FlexContainer";

const IconContainer = styled(FlexContainer)`
  cursor: pointer;
  margin-left: 7px;
`;

export const PropertyIntegrationMarkContainer = styled.div<{
  opacity?: number;
}>`
  opacity: ${({ opacity }) => opacity ?? 1};
`;

export { IconContainer };
