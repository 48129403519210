import { useMutation, useQuery, queryCache } from "react-query";

import api from "admin/lib/api";
import { NOTIFICATIONS } from "shared/config/constants";
import { openNotification } from "shared/utils/ui";

const GET_FIELDS = "getFields";
const GET_FIELD_PROPERTIES = "getFieldProperties";
const GET_FIELD_TYPES = "getFieldTypes";
const GET_SIGNATURE_FIELDS = "getSignatureFields";
const GET_FIELDS_CATEGORIES_BY_TYPE = "getFieldCategoriesByType";

export const useFields = (params: { groupByCategory: boolean }) => {
  const response = useQuery(
    [GET_FIELDS, params],
    () => api.getFields(params).then(({ data }) => data),
    {
      onError: () =>
        openNotification("Failed to load fields", NOTIFICATIONS.error),
    }
  );
  return response;
};

export const useFieldProperties = (params, { enabled }) => {
  const response = useQuery(
    [GET_FIELD_PROPERTIES, params],
    () => api.getFieldProperties(params).then(({ data }) => data),
    {
      enabled,
      onError: () =>
        openNotification("Failed to load properties", NOTIFICATIONS.error),
    }
  );
  return response;
};

export const useFieldCategoriesByType = () => {
  const { data: categories, isLoading: isCategoriesLoading } = useQuery(
    [GET_FIELDS_CATEGORIES_BY_TYPE],
    () => api.getFieldCategoriesByType()
  );
  return {
    categories,
    isCategoriesLoading,
  };
};

export const useFieldTypes = () => {
  const response = useQuery([GET_FIELD_TYPES], () =>
    api.getFieldTypes().then(({ data }) => data)
  );
  return response;
};

export const useCreateField = ({ onSuccess, ...config }) => {
  const [mutate, response] = useMutation(api.createField, {
    onSuccess: (data) => {
      openNotification(
        "The new field has been successfully created",
        NOTIFICATIONS.info
      );
      queryCache.invalidateQueries([GET_FIELDS]);
      queryCache.invalidateQueries([GET_FIELD_PROPERTIES]);
      onSuccess(data);
    },
    ...config,
  });

  return {
    createField: mutate,
    response,
  };
};

export const useSignatureFields = () => {
  const response = useQuery(
    [GET_SIGNATURE_FIELDS],
    () => api.getSignatureFields().then(({ data }) => data),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      onError: () =>
        openNotification(
          "Failed to load signature fields",
          NOTIFICATIONS.error
        ),
    }
  );
  return response;
};
