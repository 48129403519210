import castArray from "lodash/castArray";

import { ModalManager } from "shared/utils/modalmanager";

import { ClickwrapModalContext, ClickwrapModal } from "./ClickwrapModal";

export const openClickwrapModal = (modalContext: ClickwrapModalContext) => {
  return ModalManager.openModal(ClickwrapModal, modalContext);
};

export const buildFilter = (tags?: object[]): string => {
  if (tags && tags.length > 0) {
    return tags
      .map((o) => {
        const t = Object.keys(o)
          .map((k) => {
            return castArray(o[k])
              .map((v) => `${k}:${v}`)
              .join(",");
          })
          .join(",");

        return `tags==${t}`;
      })
      .join(" and ");
  }
};
