import styled from "@emotion/styled";

// eslint-disable-next-line import/no-unresolved
import { WidthProperty } from "csstype";

import { Checkbox as BaseCheckbox } from "shared/components/Form/Checkbox";
import List from "shared/components/List";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

const CheckboxContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  [MEDIA_QUERIES.w.gteSm]: {
    justifyContent: "center",
  },
});

const Checkbox = styled(BaseCheckbox)({
  marginRight: "14px",
});

const Fields = styled("div")({
  display: "flex",
  [MEDIA_QUERIES.w.ltSm]: {
    flexDirection: "column",
  },
});

const Field = styled("div")(
  (props: { width: WidthProperty<string>; first?: Boolean }) => ({
    width: props.width,
    ...(props.first && {
      [MEDIA_QUERIES.w.ltSm]: {
        fontWeight: 500,
        marginBottom: "16px",
      },
    }),
  })
);

const FieldLabel = styled("div")((props: { first?: boolean }) => ({
  color: COLORS.checkboxList.field.label.text,
  fontSize: "12px",
  fontWeight: 300,
  ...(props.first && {
    [MEDIA_QUERIES.w.ltSm]: {
      display: "none",
    },
  }),
}));

const Item = styled(List.Item)({
  [MEDIA_QUERIES.w.ltSm]: {
    paddingTop: "24px",
    paddingBottom: "24px",
  },
});

export { Checkbox, CheckboxContainer, Fields, Field, FieldLabel, Item };
