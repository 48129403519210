import PropTypes from "prop-types";

import { keyPropType } from "shared/utils/propTypes";

export const dropdownMenuItemsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    key: keyPropType.isRequired,
    label: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
    color: PropTypes.string,
    ltMdOnly: PropTypes.bool,
    disabled: PropTypes.bool,
  })
);
