import React, { useMemo } from "react";

import pluralize from "pluralize";

import { PropertyIntegrationMark } from "manager/components/IntegrationMark";
import { LUMEN_FORMULA_ASSIGNMENT_LEVEL } from "manager/config/lumen.config";
import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import { UnderlineLink } from "shared/components/Links";
import { Popover } from "shared/components/Popover";
import Tooltip from "shared/components/Tooltip";
import { sanitizeStringSearchValue } from "shared/utils/string";

import { PropertyCompatibilityIssuesPopoverContent } from "./styled";

const hasDifferentFormulaAssigned = (item) =>
  !item?.isAssigned && !!item?.hasFormula;

const buildPropertyId = (portfolioId, propertyId) =>
  `${portfolioId}_${propertyId}`;

/* eslint-disable react/prop-types */
const PortfolioDescription = ({ portfolio, listApiRef }) => {
  const allProperties = useMemo(
    () =>
      (portfolio.properties || [])
        .filter((prop) => !!prop.isCompatibleWithFormula)
        .map((prop) => buildPropertyId(portfolio.id, prop.id)),
    [portfolio]
  );

  const allPropertiesSelected =
    listApiRef?.current?.allChecked &&
    listApiRef.current.allChecked(allProperties);

  const selectDeselectLabel = allPropertiesSelected
    ? "Deselect all properties"
    : "Select all properties";

  const onSelectUnselectAll = () => {
    if (!allPropertiesSelected && listApiRef?.current?.selectItemsById) {
      listApiRef.current.selectItemsById(allProperties);
    } else if (
      allPropertiesSelected &&
      listApiRef?.current?.unselectItemsById
    ) {
      listApiRef.current.unselectItemsById(allProperties);
    }
  };

  return (
    <FlexContainer alignItems="center" justifyContent="space-between">
      <UnderlineLink onClick={onSelectUnselectAll}>
        {selectDeselectLabel}
      </UnderlineLink>
    </FlexContainer>
  );
};
/* eslint-enable react/prop-types */

const getPortfolioIncompatiblePropertiesData = (
  portfolio,
  numIncompatibleProperties
) => {
  const incompatiblePropertiesLabel = `${numIncompatibleProperties} ${pluralize(
    "property",
    numIncompatibleProperties
  )}`;

  const collapsibleSuffix = (
    <FlexContainer
      fullHeight
      alignItems="center"
      className="collapsible-suffix"
    >
      <Tooltip
        title={`${incompatiblePropertiesLabel} from this portfolio cannot consider eviction related proceedings or do not allow criminal records to be shown to managers. These properties will not have this formula assigned.`}
        theme="light"
      >
        <div>
          <Icon.OrangeExclamationSmall />
        </div>
      </Tooltip>
    </FlexContainer>
  );

  const warning = `${portfolio.name} portfolio contains ${incompatiblePropertiesLabel} that cannot consider eviction related proceedings or do not allow criminal records to be shown to managers. Those properties will not have this formula assigned.`;

  return { collapsibleSuffix, warning };
};

/* eslint-disable react/prop-types */
const PropertyCompatibilityIssuesPopover = ({ property }) => {
  return property.compatibilityIssues?.length > 0 ? (
    // @ts-ignore
    <Popover
      className="popover"
      withoutOverlay
      // @ts-ignore
      trigger="hover"
      content={
        <PropertyCompatibilityIssuesPopoverContent>
          <ul>
            {property.compatibilityIssues.map((issue) => (
              <li key={issue.code}>{issue.message}</li>
            ))}
          </ul>
        </PropertyCompatibilityIssuesPopoverContent>
      }
    >
      <Icon.OrangeExclamationSmall className="compatibility-issues-warning-icon" />
    </Popover>
  ) : (
    <></>
  );
};
/* eslint-enable react/prop-types */

const compareItems = (p1, p2) => {
  const parent1 = p1.parent || p1.key;
  const parent2 = p2.parent || p2.key;

  if (parent1 > parent2) {
    return 1;
  }
  if (parent1 < parent2) {
    return -1;
  }
  return p1.key > p2.key ? 1 : -1;
};

const getPortfolioItems = (portfolios, checkedItems, listApiRef) => {
  const items = [];
  const checked = [];
  const checkedItemsSet = new Set(checkedItems);
  const warnings = [];
  let countNewlyAssignedPropertiesWithExistingFormula = 0;

  if (portfolios) {
    portfolios.forEach((portfolio) => {
      const {
        id,
        name,
        properties,
        isAssigned: isPortfolioAssigned,
      } = portfolio;
      const portfolioId = `${id}`;
      const isPortfolioSelected = checkedItemsSet.has(portfolioId);
      let numIncompatibleProperties = 0;

      if (isPortfolioAssigned) {
        checked.push(portfolioId);
      }

      properties.forEach((property) => {
        const {
          id: propId,
          name: propName,
          fullAddress,
          assignmentLevel: propertyAssignmentLevel,
          isAssigned: propertyHasAssignment,
        } = property;
        const isPropertyAssigned =
          propertyHasAssignment &&
          propertyAssignmentLevel === LUMEN_FORMULA_ASSIGNMENT_LEVEL.PROPERTY;
        const newId = buildPropertyId(portfolioId, propId);

        const isPropertySelected = checkedItemsSet.has(newId);

        if (isPropertySelected && hasDifferentFormulaAssigned(property)) {
          countNewlyAssignedPropertiesWithExistingFormula += 1;
        }

        if (
          isPortfolioSelected &&
          !property.isCompatibleWithFormula &&
          !hasDifferentFormulaAssigned(property)
        ) {
          numIncompatibleProperties += 1;
        }

        items.push({
          key: newId,
          originalId: propId,
          label: (
            <FlexContainer alignItems="center">
              {propName} <PropertyIntegrationMark property={property} />
              {!property.isCompatibleWithFormula && (
                <PropertyCompatibilityIssuesPopover property={property} />
              )}
            </FlexContainer>
          ),
          description: (
            <FlexContainer alignItems="center" justifyContent="space-between">
              <span>{fullAddress}</span>
            </FlexContainer>
          ),
          disabled: !property.isCompatibleWithFormula,
          parent: portfolioId,
          searchName: sanitizeStringSearchValue(propName),
          parentName: sanitizeStringSearchValue(name),
          searchAddress: sanitizeStringSearchValue(fullAddress),
        });

        if (isPropertyAssigned) {
          checked.push(newId);
        }
      });

      let resolvedCollapsibleSuffix = null;

      if (numIncompatibleProperties > 0) {
        const { collapsibleSuffix, warning } =
          getPortfolioIncompatiblePropertiesData(
            portfolio,
            numIncompatibleProperties
          );

        resolvedCollapsibleSuffix = collapsibleSuffix;
        warnings.push({
          message: warning,
        });
      }

      items.push({
        key: portfolioId,
        label: name,
        description: (
          <PortfolioDescription portfolio={portfolio} listApiRef={listApiRef} />
        ),
        collapsible: true,
        collapsibleSuffix: resolvedCollapsibleSuffix,
        searchName: sanitizeStringSearchValue(name),
      });
    });
  }

  if (countNewlyAssignedPropertiesWithExistingFormula > 0) {
    warnings.push({
      message:
        "Some of the selected properties already have a formula assigned. Once you confirm the selection, the future applications for these properties will use the new formula. Current applications won't be impacted.",
    });
  }

  return {
    items: items.sort(compareItems),
    checked,
    warnings,
  };
};

export const useListItemsAndCheckedItems = (
  lumenFormulaAssignments,
  checkedItems,
  listApiRef
) =>
  useMemo(
    () => getPortfolioItems(lumenFormulaAssignments, checkedItems, listApiRef),
    [lumenFormulaAssignments, checkedItems]
  );

export const useSelectedItemsSummary = (portfolios, checkedItems) => {
  const getPortfolioSelectedProperties = (portfolio) => {
    const checkedItemsSet = new Set(checkedItems);
    return portfolio.properties.filter(({ id }) =>
      checkedItemsSet.has(buildPropertyId(portfolio.id, id))
    ).length;
  };

  return useMemo(
    () =>
      (portfolios || []).map((portfolio) => ({
        key: portfolio.id,
        label: portfolio.name,
        selected: getPortfolioSelectedProperties(portfolio),
        total: portfolio.properties.length,
      })),
    [portfolios, checkedItems]
  );
};

/**
 * Util that checks whether we should show the TOS disclaimer modal.
 * The disclaimer modal should be shown only if new items have been checked.
 * If there are no changes or if some of the items have been unchecked
 * we do not show the disclaimer.
 */
export const showDisclaimerModal = (initiallyChecked, checked) => {
  const initiallyCheckedSet = new Set(initiallyChecked);
  return !!checked.find((c) => !initiallyCheckedSet.has(c));
};
