import React from "react";

import { Form, Formik } from "formik";
import PropTypes from "prop-types";

import { CreateUnitListingValidationSchema } from "manager/utils/validationSchemas";
import { Modal } from "shared/components/Modal";

import CreateUnitListingInnerFormElements from "./CreateUnitListingInnerFormElements";

const CreateUnitListingModal = ({
  context,
  initialValues,
  submit,
  confirmLabel,
  securityDepositDisabled,
  availabilityDateDisabled,
}) => (
  <Formik
    validationSchema={CreateUnitListingValidationSchema}
    initialValues={{
      ...initialValues,
      securityDepositDisabled,
      availabilityDateDisabled,
    }}
    validateOnMount
    enableReinitialize
    onSubmit={(values, formikApi) => {
      return submit(
        { ...values, unit: context.id, unitName: context.name },
        formikApi
      );
    }}
  >
    {({ submitForm, isSubmitting }) => (
      <Form>
        <Modal
          title="Create listing"
          subtitle={
            <span data-testid="subtitle">
              Unit:{" "}
              <strong>
                {context.name}, {context.propertyName}
              </strong>
            </span>
          }
          submit={submitForm}
          submitting={isSubmitting}
          submitButtonLabel={confirmLabel}
          closeOnSubmit={false}
        >
          <Modal.Body>
            <CreateUnitListingInnerFormElements
              securityDepositDisabled={securityDepositDisabled}
              availabilityDateDisabled={availabilityDateDisabled}
            />
          </Modal.Body>
        </Modal>
      </Form>
    )}
  </Formik>
);

CreateUnitListingModal.propTypes = {
  submit: PropTypes.func.isRequired,
  context: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    propertyName: PropTypes.string.isRequired,
  }).isRequired,
  initialValues: PropTypes.shape({
    rent: PropTypes.number,
    securityDeposit: PropTypes.number,
    unitAvailableOn: PropTypes.string,
  }),
  confirmLabel: PropTypes.string,
  securityDepositDisabled: PropTypes.bool,
  availabilityDateDisabled: PropTypes.bool,
};

CreateUnitListingModal.defaultProps = {
  confirmLabel: "Create",
  initialValues: {
    rent: null,
    securityDeposit: null,
    unitAvailableOn: null,
  },
  securityDepositDisabled: false,
  availabilityDateDisabled: false,
};

export default CreateUnitListingModal;
