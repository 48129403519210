import React from "react";

import * as FullStory from "@fullstory/browser";
import ReactDOM from "react-dom";

// import * as serviceWorker from "shared/lib/serviceWorker";
import "shared/styles/bundle.less";
import createAdminStore from "admin/store";
import createManagerStore from "manager/store";
import createRenterStore from "renter/store";
import stylesConfig from "shared/stylesConfig";
import {
  isAdminApp,
  isManagerApp,
  isRenterLikeApp,
  isSurveysApp,
} from "shared/utils/auth";
import { initDataDogRum } from "shared/utils/datadog";
import createSurveysStore from "surveys/store";

import RootApp from "./RootApp";
import Store from "./store";

// Instantiate Datadog Rum
initDataDogRum();

// serviceWorker.register();
stylesConfig();

let storeCreator;

const appName =
  (isAdminApp() && "admin") ||
  (isManagerApp() && "manager") ||
  (isRenterLikeApp() && "renter") ||
  (isSurveysApp() && "surveys");

if (!FullStory?.isInitialized())
  FullStory?.init({
    orgId: "18XBYY",
    devMode: process.env.NODE_ENV !== "production",
    namespace: appName,
    recordCrossDomainIFrames: true,
  });

if (isAdminApp()) {
  storeCreator = createAdminStore;
} else if (isManagerApp()) {
  storeCreator = createManagerStore;
} else if (isRenterLikeApp()) {
  storeCreator = createRenterStore;
} else if (isSurveysApp()) {
  storeCreator = createSurveysStore;
} else {
  throw new Error("No app has been configured for this host.");
}

Store.initialize(storeCreator);

const render = (App) => {
  return ReactDOM.render(
    <App store={Store.instance} />,
    document.querySelector("#root")
  );
};

render(RootApp);

if (module.hot) {
  module.hot.accept("./RootApp", () => {
    const NextApp = require("./RootApp").default;
    render(NextApp);
  });
}
