import React from "react";

import isEmpty from "lodash/isEmpty";

import useModal from "shared/hooks/useModal";

import FilterMobileModal from "../Modals/FilterMobileModal";

import { MobileFiltersProps } from "./interfaces";
import MobileFilters from "./MobileFilters";

const MobileFiltersContainer = (props: MobileFiltersProps) => {
  const { search, ...context } = props;
  const { openModalDialog } = useModal();

  const openFilterModal = () => openModalDialog(FilterMobileModal, context);

  const showFilters = !isEmpty(props);

  return (
    <MobileFilters
      openFilterModal={openFilterModal}
      search={search}
      showFilters={showFilters}
    />
  );
};

export default MobileFiltersContainer;
