import { normalize } from "normalizr";
import { handleActions } from "redux-actions";

import * as dealActions from "shared/state/Deal/actions";
import * as dealSchemas from "shared/state/Deal/schema";
import * as propertyActions from "shared/state/Property/actions";
import * as propertySchemas from "shared/state/Property/schema";
import * as unitActions from "shared/state/Unit/actions";
import * as unitSchemas from "shared/state/Unit/schema";

import * as actions from "../actions";
import * as schemas from "../schema";

const initialState = {};

const unitListingEntityReducer = handleActions(
  {
    [unitActions.receiveUnits]: (state, action) => {
      const { units } = action.payload;

      return {
        ...state,
        ...normalize(units, unitSchemas.unitList).entities.unitListing,
      };
    },
    [actions.receiveUnitListing]: (state, action) => {
      const { unitListing } = action.payload;

      return {
        ...state,
        ...normalize([unitListing], schemas.unitListings).entities.unitListing,
      };
    },
    [dealActions.receiveDeals]: (state, action) => {
      const { deals } = action.payload;

      return {
        ...state,
        ...normalize(deals, dealSchemas.dealList).entities.unitListing,
      };
    },
    [dealActions.receiveDeal]: (state, action) => {
      const { deal } = action.payload;

      return {
        ...state,
        ...normalize([deal], dealSchemas.dealList).entities.unitListing,
      };
    },
    [propertyActions.receiveProperties]: (state, action) => {
      const { properties } = action.payload;

      return {
        ...state,
        ...normalize(properties, propertySchemas.propertyList).entities
          .unitListing,
      };
    },
  },
  initialState
);

export default unitListingEntityReducer;
