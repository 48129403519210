import React from "react";

import { BluemoonDashboardProps } from "../interfaces";

import BluemoonPropertyBindings from "./components/BluemoonPropertyBindings";

const BluemoonPropertyBindingsContainer = ({
  backLink,
}: BluemoonDashboardProps) => {
  return (
    <div>
      <BluemoonPropertyBindings backLink={backLink} />
    </div>
  );
};

export default BluemoonPropertyBindingsContainer;
