import React, { ReactNode } from "react";

// eslint-disable-next-line vero/tooltip-import
import { Tooltip as BaseTooltip } from "antd";
import { TooltipPlacement, TooltipTrigger } from "antd/lib/tooltip";

import { LightTitle } from "./styled";

type TooltipThemes = "default" | "light" | "dark";

const TOOLTIP_THEMES: { [key: string]: TooltipThemes } = {
  light: "light",
  dark: "dark",
  default: "default",
};

const OVERLAY_CLASSES = Object.freeze({
  [TOOLTIP_THEMES.default]: "unverified-tooltip",
  [TOOLTIP_THEMES.light]: "unverified-tooltip",
  [TOOLTIP_THEMES.dark]: "",
});

const getTitleContainer = (theme) => {
  let Container;

  switch (theme) {
    case TOOLTIP_THEMES.default:
      Container = LightTitle;
      break;
    default:
      Container = ({ children }) => <>{children}</>;
  }

  return Container;
};

export interface TooltipProps {
  title?: ReactNode;
  trigger?: TooltipTrigger;
  theme?: TooltipThemes;
  placement?: TooltipPlacement;
  arrowPointAtCenter?: boolean;
}

const Tooltip: React.FC<TooltipProps> = ({
  title,
  trigger,
  theme = TOOLTIP_THEMES.default,
  children,
  placement,
  arrowPointAtCenter,
}) => {
  const TitleContainer = getTitleContainer(theme);
  return (
    <BaseTooltip
      trigger={trigger}
      title={title && <TitleContainer>{title}</TitleContainer>}
      overlayClassName={OVERLAY_CLASSES[theme]}
      placement={placement}
      arrowPointAtCenter={arrowPointAtCenter}
    >
      {children}
    </BaseTooltip>
  );
};

export default Tooltip;
