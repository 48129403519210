import styled from "@emotion/styled";

import FooterBar from "shared/components/FooterBar";
import Well from "shared/components/Well";
import { COLORS } from "shared/config/constants";

const StyledWell = styled(Well)`
  .well-title {
    padding-top: 8px;
  }

  .well-subtitle {
    padding: 24px 0 16px;
    span {
      color: ${COLORS.lightViolet};
    }
  }

  .cta-wrapper {
    max-width: 300px;
  }
`;

const StyledFooter = styled(FooterBar)`
  justify-content: right;

  .footer-action {
    margin-left: 24px;
  }
`;

export { StyledWell, StyledFooter };
