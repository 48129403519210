export type SearchPropTypes = {
  fieldName: string;
  label: string;
  action: (value: string) => void;
  width: string;
  debounce: boolean;
  value?: string;
  id?: string;
  disabled?: boolean;
  loading?: boolean;
};

export enum SELECTION_FILTER_VALUES {
  ALL = "ALL",
  SELECTED = "SELECTED",
  NOT_SELECTED = "NOT_SELECTED",
}
