import styled from "@emotion/styled";

import { MEDIA_QUERIES } from "shared/config/constants";

const ManagerName = styled.span`
  ${MEDIA_QUERIES.w.gteMd} {
    display: block;
  }
  ${MEDIA_QUERIES.w.ltMd} {
    &::after {
      content: ", ";
    }
  }
`;

export { ManagerName };
