import React from "react";

import { Col } from "antd";
import { useHistory } from "react-router-dom";

import { Tile, TileInner, Title, Action } from "./styled";

interface SettingsTileProps {
  Icon: React.FunctionComponent;
  name: string;
  to: string;
  action?: React.ReactNode;
}

const SettingsTile = ({ Icon, name, action, to }: SettingsTileProps) => {
  const history = useHistory();
  const open = () => history.push(to);

  return (
    <Col xs={24} lg={12} xl={12}>
      <Tile
        data-testid="settings-tile"
        equalHeight
        role="link"
        data-href={to}
        tabIndex={0}
        onClick={open}
        onKeyPress={open}
      >
        <TileInner>
          {action && <Action>{action}</Action>}
          <Icon />
          <Title>{name}</Title>
        </TileInner>
      </Tile>
    </Col>
  );
};

export default SettingsTile;
