import produce from "immer";
import merge from "lodash/merge";
import { normalize } from "normalizr";
import { handleActions } from "redux-actions";

import * as dealActions from "shared/state/Deal/actions";
import * as dealSchemas from "shared/state/Deal/schema";
import * as propertyActions from "shared/state/Property/actions";
import * as propertySchemas from "shared/state/Property/schema";
import * as unitListingActions from "shared/state/UnitListing/actions";

import * as actions from "../actions";
import * as schemas from "../schema";

const initialState = {};

const unitEntityReducer = handleActions(
  {
    [actions.receiveUnit]: (state, action) => {
      const { unit } = action.payload;
      return produce(state, (draft) =>
        merge(draft, normalize([unit], schemas.unitList).entities.unit)
      );
    },
    [actions.receiveUnits]: (state, action) => {
      const { units } = action.payload;
      return produce(state, (draft) =>
        merge(draft, normalize(units, schemas.unitList).entities.unit)
      );
    },
    [unitListingActions.receiveUnitListing]: (state, action) => {
      const {
        unitListing: { unit, id, isCurrent },
      } = action.payload;

      const selectedUnit = state[unit];

      return produce(state, (draft) =>
        selectedUnit
          ? merge(draft, {
              [unit]: {
                ...selectedUnit,
                unitListingCurrent: isCurrent ? id : null,
              },
            })
          : draft
      );
    },
    [dealActions.receiveDeals]: (state, action) => {
      const { deals } = action.payload;

      return produce(state, (draft) =>
        merge(draft, normalize(deals, dealSchemas.dealList).entities.unit)
      );
    },
    [dealActions.receiveDeal]: (state, action) => {
      const { deal } = action.payload;
      return produce(state, (draft) =>
        merge(draft, normalize([deal], dealSchemas.dealList).entities.unit)
      );
    },
    [propertyActions.receiveProperties]: (state, action) => {
      const { properties } = action.payload;
      return produce(state, (draft) =>
        merge(
          draft,
          normalize(properties, propertySchemas.propertyList).entities.unit
        )
      );
    },
  },
  initialState
);

export default unitEntityReducer;
