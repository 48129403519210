

import { useTranslation } from "renter/hooks/useTranslation";
import Tile from "shared/components/Tile";
import { formatDate } from "shared/utils/ui";

import { printDollarsFromCents } from "shared/utils/dollar-print";
import { ApplicationDetailsProps } from "./interfaces";

const ApplicationDetails = ({
  unitAvailableOn,
  monthlyRent,
}: ApplicationDetailsProps) => {
  const { t } = useTranslation({ keyPrefix: "renter-app.applications" });

  return (
    <>
      <div>
        <Tile.Label noMb>{t("unit-available-on")}</Tile.Label>
        <div data-testid="unit-available-on">{formatDate(unitAvailableOn)}</div>
      </div>
      <div>
        <Tile.Label noMb>{t("rent-price")}</Tile.Label>
        <div data-testid="unit-rent">
          {printDollarsFromCents(monthlyRent) || "N/A"}
        </div>
      </div>
    </>
  );
};
export default ApplicationDetails;
