import { useState } from "react";

export const useVisiblePopover = () => {
  const [visiblePopover, setVisiblePopover] = useState(false);

  const actionFromPopover = (action: () => any) => {
    action();
    setVisiblePopover(false);
  };

  const showPopover = () => setVisiblePopover(true);

  const hidePopover = () => setVisiblePopover(false);

  const onVisibleChange = (visible: boolean) => setVisiblePopover(visible);

  return {
    visiblePopover,
    onVisibleChange,
    actionFromPopover,
    showPopover,
    hidePopover,
  };
};
