import React from "react";

import { FeeInventoryLevel } from "manager/interfaces/api";
import { useActiveCompany } from "shared/hooks";

import HoldingDepositSettings from "./HoldingDepositSettings";

const CompanySettingsHoldingDeposit = () => {
  const { activeCompany } = useActiveCompany();

  return (
    <HoldingDepositSettings
      id={activeCompany?.id}
      level={FeeInventoryLevel.COMPANY}
    />
  );
};

export default CompanySettingsHoldingDeposit;
