import React from "react";

import Icon from "shared/components/Icon";

import { WarningContainer, IconWrapper, Message } from "./styled";

const WarningMessage = () => (
  <WarningContainer data-testid="checkbox-field-warning">
    <IconWrapper>
      <Icon.OrangeExclamation height="100%" width="100%" />
    </IconWrapper>

    <Message>
      If the leasing manager answers “Yes” the checkbox will be selected. If
      they answer “No” it will be left blank.
    </Message>
  </WarningContainer>
);

export default WarningMessage;
