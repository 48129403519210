import React from "react";

import Collapse from "shared/components/Collapse";
import Tile from "shared/components/Tile";

import FieldsList from "./FieldsList";
import { ApplicantsListProps } from "./interfaces";
import { StyledHeader } from "./styled";

const ApplicantsList = ({
  applicantCategory,
  applicantsDataByNumber,
  addField,
}: ApplicantsListProps) => (
  <>
    {applicantsDataByNumber.map((fields, applicantNumber) => (
      <Collapse
        key={`${applicantCategory} ${applicantNumber + 1}`}
        header={({ CollapseIcon }) => (
          <Tile.Inner style={{ padding: "6px 15px", fontSize: "16px" }}>
            <StyledHeader>
              {`${applicantCategory} ${applicantNumber + 1}`}
              <CollapseIcon />
            </StyledHeader>
          </Tile.Inner>
        )}
      >
        <Tile.Inner style={{ padding: "0 15px" }}>
          <FieldsList fields={fields} addField={addField} />
        </Tile.Inner>
      </Collapse>
    ))}
  </>
);

export default ApplicantsList;
