import React, { ReactNode } from "react";

import { useActiveUser } from "shared/hooks/api";
import { getFullnameData } from "shared/utils/applicant";
import { logout, useLoadActiveUser } from "shared/utils/auth";

import App from "./App";

interface AppContainerProps {
  id: string;
  render: ({ history, user, ...args }) => ReactNode;
}

const AppContainer = ({ id, render }: AppContainerProps) => {
  const { activeUser } = useActiveUser();
  const { loadActiveUser } = useLoadActiveUser();

  const user = {
    name: getFullnameData(activeUser)?.fullNameOrNA,
    email: activeUser?.email,
    phone: activeUser?.phoneNumber,
  };

  return (
    <App
      id={id}
      render={render}
      loadActiveUser={loadActiveUser}
      logout={logout}
      user={user}
    />
  );
};

export default AppContainer;
