import { useState } from "react";

import PropTypes from "prop-types";
import { printDollarsFromCents } from "shared/utils/dollar-print";

import { MaskedInput } from "shared/components/Form/MaskedInput";
import { Popover, PopoverContent } from "shared/components/Popover";
import { basicPropertyPropType } from "shared/utils/propTypes";

import { sectionType } from "../../shared/propTypes";
import {
  veroUnitsPropType,
  yardiUnitsPropType,
} from "../../shared/yardi.config";

import {
  useUnitConfigSection,
} from "./configureYardiPropertySection.util";
import {
  RowLabelContainer,
  Section,
  SectionSubTitle,
  SectionTitle,
  YardiSectionAvailableUnitsWrapper,
} from "./styled";
import { YardiUnitsConfigSection } from "./YardiUnitsConfigSection";

const buildPropPath = (yardiUnit) =>
  `availableUnits[${yardiUnit.id}]`;

/* eslint-disable react/prop-types */
const DescriptionExtension = ({
  yardiUnit,
  veroUnit,
  isLroEnabled,
  /* eslint-enable react/prop-types */
}) => {
  const isRentDisabled = !veroUnit || isLroEnabled;
  const rentInputTooltip =
    !!veroUnit && isLroEnabled
      ? "The value of the rent is updated automatically"
      : null;

  return (
    <Popover
      withoutOverlay
      disabled={!rentInputTooltip}
      content={<PopoverContent>{rentInputTooltip}</PopoverContent>}
    >
      <div className="rent-input">
        <MaskedInput.Formik.Money
          id={`${buildPropPath(yardiUnit)}.rent`}
          name={`${buildPropPath(yardiUnit)}.rent`}
          disabled={isRentDisabled}
          label="VERO Rent"
        />
      </div>
    </Popover>
  );
};

const YardiSectionAvailableUnits = ({
  section,
  veroUnits,
  veroUnitsMap,
  yardiUnits,
  veroProperty,
  yardiProperty,
  firstColWidth,
  loading,
  provider,
}) => {
  const [checkedItems, setCheckedItems] = useState([]);

  const buildLabel = (yardiUnit) => (
    <RowLabelContainer>
      <span className="label-with-input" title={yardiUnit.name}>
        {yardiUnit.name}
      </span>
      <span className="yardi-rent">
        Rent: {printDollarsFromCents(yardiUnit.rent)}
      </span>
    </RowLabelContainer>
  );

  const { tableItems, autoCreateUnits, isAutocreating } = useUnitConfigSection(
    provider,
    buildPropPath,
    buildLabel,
    veroUnits,
    veroUnitsMap,
    yardiUnits,
    veroProperty,
    yardiProperty,
    checkedItems,
    DescriptionExtension
  );

  return (
    <YardiSectionAvailableUnitsWrapper>
      <Section height="auto" noPaddingBottom>
        <SectionTitle>{section.name}</SectionTitle>
        <SectionSubTitle>
          <ul>
            <li>
              Any available unit from an External Provider that is brought into
              VERO will create a unit listing.
            </li>
            <li>
              Please confirm or change the rent amount as it will be shown to
              applicants.
            </li>
            <li>
              Each available unit below needs to be linked to an existing or new
              VERO unit if you would like to accept applications for it.
            </li>
          </ul>
        </SectionSubTitle>
        <YardiUnitsConfigSection
          sectionId={section.anchor}
          items={tableItems}
          popoverText="When auto creating units in VERO we will use the unit name and listing info provided by the External Provider."
          checkedItems={checkedItems}
          setCheckedItems={setCheckedItems}
          autoCreateUnits={autoCreateUnits}
          firstColWidth={firstColWidth}
          loading={loading || isAutocreating}
        />
      </Section>
    </YardiSectionAvailableUnitsWrapper>
  );
};

YardiSectionAvailableUnits.propTypes = {
  section: sectionType.isRequired,
  veroUnits: veroUnitsPropType,
  veroUnitsMap: PropTypes.object.isRequired,
  yardiUnits: yardiUnitsPropType,
  veroProperty: basicPropertyPropType,
  yardiProperty: basicPropertyPropType,
  firstColWidth: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  provider: PropTypes.string.isRequired,
};

YardiSectionAvailableUnits.defaultProps = {
  veroUnits: [],
  yardiUnits: [],
  veroProperty: null,
  yardiProperty: null,
  loading: false,
};

export default YardiSectionAvailableUnits;
