import axios from "axios";

import ROUTES from "shared/config/apiRoutes";
import { transformRoute } from "shared/utils/routing";

import { API_URL } from "./setupApi";

const api = {
  getDisclaimer: async (code) =>
    axios
      .get(`${API_URL}${transformRoute(ROUTES.getDisclaimer, { code })}`)
      .then(({ data }) => data),
};

export default api;
