import styled from "@emotion/styled";

import { Button } from "shared/components/Button";
import Card from "shared/components/Card";
import { CenteredCard } from "shared/styles/commonStyles";

const PasswordRecoveryPageCard = styled(Card)`
  ${CenteredCard}
`;

const SubmitButton = styled(Button)`
  width: 100%;
`;

export { PasswordRecoveryPageCard, SubmitButton };
