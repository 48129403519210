import React from "react";

import { Modal } from "shared/components/Modal";

import { UnassignEmployeeFromPropertyModalProps } from "./interfaces";

const UnassignEmployeeFromPropertyModal = ({
  employeeName,
  propertyName,
  onSubmit,
  submitting,
}: UnassignEmployeeFromPropertyModalProps) => (
  <Modal
    title={`Unassign ${employeeName} from this property?"`}
    subtitle={
      <span data-testid="subtitle">
        Property: <strong>{propertyName}</strong>
      </span>
    }
    submit={onSubmit}
    submitButtonLabel="Unassign"
    submitting={submitting}
  >
    <Modal.Body>
      <p>
        Please note that once you unassign {employeeName} from this property, he
        won&rsquo;t be able to see or manage it.
      </p>
    </Modal.Body>
  </Modal>
);

export default UnassignEmployeeFromPropertyModal;
