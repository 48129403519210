import React from "react";

import { useHistory } from "react-router-dom";

import { UnitInviteApplicantsModal } from "manager/components/Modal";
import EditUnitModal from "manager/components/Modal/EditUnitModal";
import ROUTES from "manager/config/routes";
import { useDeleteUnit } from "manager/hooks/api";
import { Unit } from "manager/interfaces/api/unit";
import {
  checkIsAbleToInviteApplicant,
  hasListing,
} from "manager/utils/unit.util";
import ConfirmationModal from "shared/components/Modals/ConfirmationModal";
import { useDeviceType } from "shared/hooks";
import useModal from "shared/hooks/useModal";
import { transformRoute } from "shared/utils/routing";

import UnitHeader from "./UnitHeader";

interface UnitHeaderContainerProps {
  unit: Unit;
  loading: boolean;
  showOnlyUnitsNotAttachedToAnotherActiveDeal?: boolean;
}

const UnitHeaderContainer = ({
  unit,
  loading,
  showOnlyUnitsNotAttachedToAnotherActiveDeal,
}: UnitHeaderContainerProps) => {
  const history = useHistory();
  const { isMobile } = useDeviceType();
  const { openModalDialog } = useModal();
  const { deleteUnit } = useDeleteUnit();

  const openEditModal = () => {
    openModalDialog(EditUnitModal, { unit });
  };

  const openDeleteModal = () => {
    const onConfirm = () => {
      const onSuccess = () =>
        history.replace(
          transformRoute(ROUTES.property, { id: unit?.property?.id })
        );

      // @ts-ignore
      deleteUnit(unit?.id, { onSuccess });
    };

    const context = {
      title: "Delete this unit?",
      subtitle: (
        <span>
          Unit:{" "}
          <strong>
            {unit?.name}, {unit?.propertyName}
          </strong>
        </span>
      ),
      message: `Please note that once you delete this unit you won't be able to create a listing and invite applicants to it.`,
      noDescriptionIcon: true,
      submitButtonLabel: "Delete",
      onConfirm,
    };
    openModalDialog(ConfirmationModal, context);
  };

  const openInviteApplicantsModal = () => {
    const { id, name, property } = unit;
    openModalDialog(UnitInviteApplicantsModal, {
      unit: { id, name },
      property: {
        name: property?.name,
      },
    });
  };

  const isAbleToInviteApplicants = checkIsAbleToInviteApplicant(unit);

  const hasListingUnit = hasListing(unit);

  return (
    <UnitHeader
      editUnit={openEditModal}
      deleteUnit={openDeleteModal}
      inviteApplicants={openInviteApplicantsModal}
      isMobile={isMobile}
      isAbleToInviteApplicants={isAbleToInviteApplicants}
      loading={loading}
      hasListing={hasListingUnit}
      unit={unit}
      showOnlyUnitsNotAttachedToAnotherActiveDeal={
        showOnlyUnitsNotAttachedToAnotherActiveDeal
      }
    />
  );
};

export default UnitHeaderContainer;
