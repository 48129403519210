import React, { useEffect, useMemo, useState } from "react";

import isEqual from "lodash/isEqual";
import { useHistory } from "react-router-dom";

import {
  CreatePortfolioModal,
  CreatePropertyModal,
  CreatePropertySuccessModal,
} from "manager/components/Modal/";
import ROUTES from "manager/config/routes";
import {
  useGetPortfoliosList,
  useGetPropertiesDropdown,
  useGetPropertiesPerf,
  useGetPropertyUnits,
  useUnitsExport,
} from "manager/hooks/api";
import { Property } from "manager/interfaces/api";
import { DEFAULT_PAGINATION } from "shared/config/constants";
import useModal from "shared/hooks/useModal";
import { transformRoute } from "shared/utils/routing";

import {
  DefaultPagination,
  PortfolioSearchParams,
  PropertiesSearchParams,
  UnitSearchParams,
} from "./interfaces";
import Properties from "./Properties";
import {
  blankPropertiesFilter,
  blankUnitsFilter,
  handleSearchParams,
  ORDER_BY,
  blankPortfolioFilter,
  prepareUnitsSearchParams,
  transformSearchParams,
} from "./utils";

const PropertiesContainer = () => {
  const { openModalDialog, closeActiveModalDialog } = useModal();
  const history = useHistory();
  const [propertiesHasInputValue, setPropertiesHasInputValue] =
    useState<boolean>(false);
  const [propertiesSearchParams, setPropertiesSearchParams] =
    useState<PropertiesSearchParams>(blankPropertiesFilter);
  const [propertiesPagination, setPropertiesPagination] =
    useState<DefaultPagination>(DEFAULT_PAGINATION);

  const [unitsHasInputValue, setUnitsHasInputValue] = useState<boolean>(false);
  const [unitsSearchParams, setUnitsSearchParams] =
    useState<UnitSearchParams>(blankUnitsFilter);
  const [unitsPagination, setUnitsPagination] =
    useState<DefaultPagination>(DEFAULT_PAGINATION);

  const {
    data: properties,
    isLoading: propertiesLoading,
    refetch: refetchProperties,
  } = useGetPropertiesPerf({
    params: transformSearchParams(propertiesSearchParams),
  });

  const { data: portfolios, isLoading: isPortfoliosLoading } =
    useGetPortfoliosList({ includeDefault: true });

  useEffect(() => {
    setPropertiesSearchParams({
      ...propertiesSearchParams,
      ...propertiesPagination,
    });
  }, [propertiesPagination]);

  useEffect(() => {
    refetchProperties();
  }, [propertiesSearchParams]);

  const createProperty = () => {
    const afterPropertyCreate = async ({ id, name }: Property) => {
      closeActiveModalDialog();
      history.push(transformRoute(ROUTES.property, { id }));
      openModalDialog(CreatePropertySuccessModal, {
        propertyName: name,
        propertyId: id,
      });
    };

    openModalDialog(CreatePropertyModal, { afterPropertyCreate });
  };

  const handlePropertiesSearchParams = (field, value) =>
    handleSearchParams(
      field,
      value,
      blankPropertiesFilter,
      setPropertiesHasInputValue,
      setPropertiesPagination,
      setPropertiesSearchParams,
      propertiesSearchParams
    );

  const disablePropertiesFilter =
    (properties?.results?.length === 0 &&
      isEqual(propertiesSearchParams, blankPropertiesFilter)) ||
    propertiesLoading;

  // Units
  const { exportUnits, isExportingUnits } = useUnitsExport();

  const { units, isUnitsLoading, isUnitsFetching, refetchUnits } =
    useGetPropertyUnits(
      unitsSearchParams?.propertyId,
      prepareUnitsSearchParams(unitsSearchParams)
    );

  const unitResut = units?.results;
  const unitsCount = units?.count;

  const {
    properties: propertiesDropdownData,
    isPropertiesLoading: isPropertiesDropdownLoading,
    refetchProperties: refetchPropertiesDropdownData,
  } = useGetPropertiesDropdown();
  const propertiesDropdown = useMemo(
    () =>
      propertiesDropdownData?.map(({ id, name }) => ({
        label: name,
        key: `${id}`,
      })),
    [propertiesDropdownData]
  );

  const portfoliosDropdown = useMemo(
    () =>
      portfolios?.map(({ id, name }) => ({
        label: name,
        key: `${id}`,
      })),
    [portfolios]
  );

  const onUnitsTableChange = (tablePagination, _tableFilters, sorter) => {
    const newOrdering = sorter.order
      ? `${ORDER_BY[sorter.columnKey][sorter.order]}`
      : undefined;
    setUnitsSearchParams({
      ...unitsSearchParams,
      ...unitsPagination,
      page: tablePagination.current,
      pageSize: tablePagination.pageSize,
      // @ts-ignore
      ordering: newOrdering,
    });
  };

  const handleUnitsSearchParams = (field, value) =>
    handleSearchParams(
      field,
      value,
      blankUnitsFilter,
      setUnitsHasInputValue,
      setUnitsPagination,
      setUnitsSearchParams,
      unitsSearchParams
    );

  const disableUnitFilter =
    (unitResut?.length === 0 && isEqual(unitsSearchParams, blankUnitsFilter)) ||
    isUnitsLoading;

  // Portfolios
  const [portfolioHasInputValue, setPortfolioHasInputValue] = useState(false);
  const [portfolioSearchParams, setPortfolioSearchParams] =
    useState<PortfolioSearchParams>(blankPortfolioFilter);
  const [portfolioPagination, setPortfolioPagination] =
    useState<DefaultPagination>(DEFAULT_PAGINATION);

  useEffect(() => {
    refetchUnits();
  }, [unitsSearchParams]);

  useEffect(() => {
    setPortfolioSearchParams({
      ...portfolioSearchParams,
      ...portfolioPagination,
    });
  }, [portfolioPagination]);

  const createPortfolio = () => openModalDialog(CreatePortfolioModal);

  const onPortfolioTableChange = (tablePagination, _tableFilters, sorter) => {
    const newOrdering = sorter.order
      ? `${ORDER_BY[sorter.columnKey][sorter.order]}`
      : undefined;
    setPortfolioSearchParams({
      ...portfolioSearchParams,
      ...portfolioPagination,
      page: tablePagination.current,
      pageSize: tablePagination.pageSize,
      // @ts-ignore
      ordering: newOrdering,
    });
  };

  const handlePortfolioSearchParams = (field, value) =>
    handleSearchParams(
      field,
      value,
      blankPortfolioFilter,
      setPortfolioHasInputValue,
      setPortfolioPagination,
      setPortfolioSearchParams,
      portfolioSearchParams
    );

  const propertiesProps = {
    properties: properties?.results,
    propertiesCount: properties?.count,
    propertiesLoading,
    refetchProperties,
    disablePropertiesFilter,
    propertiesHasInputValue,
    propertiesPagination,
    setPropertiesPagination,
    propertiesSearchParams,
    createProperty,
    handlePropertiesSearchParams,
    portfoliosDropdown,
    isPortfoliosLoading,
  };

  const unitProps = {
    units: unitResut,
    unitsCount,
    isUnitsLoading: isUnitsLoading || isUnitsFetching,
    disableUnitFilter,
    exportUnits,
    refetchUnits,
    isExportingUnits,
    unitsHasInputValue,
    unitsPagination,
    setUnitsPagination,
    unitsSearchParams,
    handleUnitsSearchParams,
    onUnitsTableChange,
    propertiesDropdown,
    isPropertiesDropdownLoading,
    refetchPropertiesDropdownData,
  };

  const portfoliosProps = {
    portfolioHasInputValue,
    portfolioPagination,
    setPortfolioPagination,
    portfolioSearchParams,
    handlePortfolioSearchParams,
    createPortfolio,
    onPortfolioTableChange,
  };

  return (
    <Properties {...propertiesProps} {...unitProps} {...portfoliosProps} />
  );
};

export default PropertiesContainer;
