import React, { ReactElement } from "react";

import { Col, Row } from "antd-latest";

import { FadeIn, Rotate } from "shared/components/animated";
import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import Spacer from "shared/components/Spacer";
import { SIZES } from "shared/components/Spacer/constants";
import { RegularText } from "shared/components/Typography";
import { Delayed } from "shared/components/vero-modal/delayed";
import { formatPhone } from "shared/utils/ui";

import { AnimatedCards } from "./animated-cards";
import { ApplicantInfo, ApplicantInfoProps } from "./applicant-info";
import {
  BalanceCard,
  BalanceCardProps,
  CreditScoreCard,
  CreditScoreCardProps,
  CriminalCard,
  CriminalCardProps,
  EmploymentCard,
  EmploymentCardProps,
  EvictionCard,
  EvictionCardProps,
  IdentityCard,
  IdentityCardProps,
  IncomeCard,
  IncomeCardProps,
  VorCard,
  VorCardProps,
} from "./cards";
import { Card } from "./config";
import {
  RemoveApplicantModalProvider,
  RemoveApplicantModalV2,
  MoveApplicantModal,
  MoveApplicantModalProvider,
} from "./modals";
import {
  EditIncomeModal,
  EditIncomeModalProvider,
} from "./modals/edit-income-modal";
import { RemovePrimaryYardiExtensionProps } from "./modals/hooks";
import {
  CardCol,
  InviteApplicantContainer,
  LinkButton,
  TitleLink,
  UnblockLink,
} from "./styled";
import { useAppRow } from "./use-app-row";
import { calculateVerifiedCashPerRent } from "./utils";

const breakpoints = {
  // flex: 5,
  lg: 6,
  md: 12,
  sm: 12,
  xs: 24,
};

const infoBreakpoint = {
  ...breakpoints,
  // flex: 2
};

export type AppRowProps = {
  appId: string | number;
  dealId: string | number;
  info: Omit<
    ApplicantInfoProps,
    "onDelete" | "onViewAttachments" | "onMove" | "onViewApp"
  >;
  yardi: RemovePrimaryYardiExtensionProps;
  income?: IncomeCardProps;
  balance?: BalanceCardProps;
  credit?: CreditScoreCardProps;
  criminal?: CriminalCardProps;
  employment?: EmploymentCardProps;
  eviction?: EvictionCardProps;
  identity?: IdentityCardProps;
  vor?: VorCardProps;
  refetchDeal?: () => void;
  rent?: number;
  preferredLeaseDurationMonths?: number;
  personVrn?: string;
  applicantVrn?: string;
  party?: string;
  location?: string;
  snapshot?: any;
};

const Inner: React.FC<AppRowProps> = ({
  appId,
  dealId,
  info,
  yardi,
  income,
  balance,
  credit,
  criminal,
  employment,
  eviction,
  identity,
  vor,
  refetchDeal,
  preferredLeaseDurationMonths,
  rent,
}) => {
  const {
    infoProps,
    modifiedIncome,
    collapse,
    onCreditBackgroundClick,
    onIncomeClick,
    onVerificationClick,
    onEmploymentClick,
    toggle,
    onCopyAppLink,
    isMobile,
    unblock,
    modalProps,
  } = useAppRow({ appId, info, dealId, refetchDeal, yardi });
  const balanceValue = balance?.balance;
  const verifiedCashPerRent = calculateVerifiedCashPerRent(
    rent,
    balanceValue,
    preferredLeaseDurationMonths
  );

  const getCard = (x: Card): ReactElement => {
    const card = () => {
      switch (x) {
        case Card.Income:
          return (
            Boolean(income) && (
              <>
                <IncomeCard
                  {...income}
                  income={modifiedIncome ?? income.income}
                  // onEdit={showIncomeModal} // TODO: Add modify income method
                  isEdited={Boolean(modifiedIncome)}
                  onClick={onIncomeClick}
                />
                <EditIncomeModal
                  income={income.income}
                  statedIncome={income.stated}
                  applicantName={info.name}
                  applicantId={appId}
                />
              </>
            )
          );
        case Card.Balances:
          return (
            Boolean(balance) && (
              <BalanceCard
                {...balance}
                verifiedCashPerRent={verifiedCashPerRent}
                onClick={onIncomeClick}
              />
            )
          );
        case Card.Credit:
          return (
            Boolean(credit) && (
              <CreditScoreCard {...credit} onClick={onCreditBackgroundClick} />
            )
          );
        case Card.Criminal:
          return (
            Boolean(criminal) &&
            (!info.hideCriminalHistory || criminal?.skipped) && (
              <CriminalCard {...criminal} onClick={onCreditBackgroundClick} />
            )
          );
        case Card.Eviction:
          return (
            Boolean(eviction.enabled) && (
              <EvictionCard {...eviction} onClick={onCreditBackgroundClick} />
            )
          );
        case Card.Employment:
          return (
            Boolean(employment) &&
            employment.enabled && (
              <EmploymentCard {...employment} onClick={onEmploymentClick} />
            )
          );
        case Card.Identity:
          return (
            Boolean(identity) &&
            identity.enabled && (
              <IdentityCard {...identity} onClick={onVerificationClick} />
            )
          );
        case Card.VoR:
          return (
            Boolean(vor) &&
            vor.enabled && <VorCard {...vor} onClick={onVerificationClick} />
          );
        default:
          return null;
      }
    };
    return (
      <>
        <Spacer size={SIZES.sm} />
        {card()}
      </>
    );
  };

  return (
    <FlexContainer style={{ padding: 30, background: "white" }}>
      <Row
        gutter={[32, 15]}
        onClick={toggle}
        align={info.isSubmitted ? "top" : "middle"}
        style={{ flexGrow: 0.4 }}
      >
        <Col {...infoBreakpoint} style={{ position: "relative", padding: 15 }}>
          {isMobile && info.isSubmitted && (
            <Rotate
              on={!collapse}
              degrees={-180}
              style={{ position: "absolute", top: 15, right: 15 }}
            >
              <Icon.ChevronBoldDownIcon />
            </Rotate>
          )}
          <ApplicantInfo {...infoProps} />
          <Delayed delay={3000}>
            <RemoveApplicantModalV2 {...modalProps} />
            <MoveApplicantModal {...modalProps} />
          </Delayed>
        </Col>
        {!collapse && info.isSubmitted && (
          <>
            <CardCol {...breakpoints}>
              <TitleLink onClick={onIncomeClick}>
                Income &amp; Balances
              </TitleLink>
              <AnimatedCards column={0} getCard={getCard} />
            </CardCol>
            <CardCol {...breakpoints}>
              <TitleLink onClick={onCreditBackgroundClick}>
                Credit &amp; Background
              </TitleLink>
              <AnimatedCards column={1} getCard={getCard} />
            </CardCol>
            <CardCol {...breakpoints}>
              <TitleLink onClick={onVerificationClick}>Verifications</TitleLink>
              <AnimatedCards column={2} getCard={getCard} />
            </CardCol>
          </>
        )}
        {!info.isSubmitted && (
          <Col flex="auto">
            <FlexContainer
              gap="15px"
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
            >
              <FadeIn delay={500}>
                <InviteApplicantContainer>
                  <RegularText>{info.name}</RegularText>
                  &nbsp;
                  <RegularText weak>
                    needs to submit their application
                  </RegularText>
                  <br />
                  <RegularText weak>
                    {info.email}{" "}
                    {info.phone ? `| ${formatPhone(info.phone, "US")}` : ""}
                  </RegularText>
                  <br />
                  {!!unblock.showAction && (
                    <UnblockLink onClick={unblock.action}>
                      Unblock Applicant
                    </UnblockLink>
                  )}
                </InviteApplicantContainer>
              </FadeIn>
              <FadeIn delay={600}>
                <LinkButton onClick={onCopyAppLink}>
                  COPY APPLICATION LINK
                </LinkButton>
              </FadeIn>
            </FlexContainer>
          </Col>
        )}
      </Row>
    </FlexContainer>
  );
};

export const AppRow: typeof Inner = (props) => (
  <RemoveApplicantModalProvider>
    <MoveApplicantModalProvider>
      <EditIncomeModalProvider>
        <Inner {...props} />
      </EditIncomeModalProvider>
    </MoveApplicantModalProvider>
  </RemoveApplicantModalProvider>
);
