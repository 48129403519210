import styled from "@emotion/styled";
import { Form as FormikForm } from "formik";

import FlexContainer from "shared/components/FlexContainer";
import BaseFooterBar from "shared/components/FooterBar";
import Skeleton from "shared/components/Skeleton";

const Form = styled(FormikForm)({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
});

const FooterBar = styled(BaseFooterBar)({
  justifyContent: "flex-end",
});

const StyledSkeleton = styled(Skeleton)`
  padding-top: 24px;
  padding-bottom: 24px;
`;

const ResetButtonContainer = styled("div")({
  marginRight: "38px",
});

const LabelIconContainer = styled(FlexContainer)`
  .extra-info {
    margin-left: 10px;
  }
`;

const InputContainer = styled.div`
  width: 400px;
  /* DISABLE NUMBER INPUT ARROWS, BECAUSE IT OVERLAPS SUFFIX */
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

export {
  StyledSkeleton,
  Form,
  FooterBar,
  ResetButtonContainer,
  LabelIconContainer,
  InputContainer,
};
