import styled from "@emotion/styled";

interface TransitionStyleProps {
  isCollapsed?: boolean;
  width?: string | number;
  minWidth?: string | number;
  height?: string | number;
}

const getTransitionStyles = ({
  isCollapsed,
  width,
  minWidth,
  height,
}: TransitionStyleProps) => ({
  width: isCollapsed ? minWidth : width,
  height,
  webkitTransition: "width 1s ease-in-out",
  mozTransition: "width 1s ease-in-out",
  oTransition: "width 1s ease-in-out",
  transition: "width 1s ease-in-out",
});

export const Panel = styled("div")((props) => ({
  backgroundColor: "#FFFFFF",
  // @ts-ignore
  ...getTransitionStyles(props),
  position: "relative",
}));

export const PanelInner = styled("div")<{ width: number }>`
  width: ${(props) => props.width}px;
`;

export const PanelInnerWrapper = styled("div")`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const Toggle = styled("div")`
  position: absolute;
  top: 50px;
  right: -20px;
  z-ndex: 100;
`;
