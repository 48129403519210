import React from "react";

import { Col, Row } from "antd";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";

import {
  DatePicker,
  MaskedInput,
  TextArea,
  VeroFormField,
} from "shared/components/Form";
import { showError } from "shared/utils/forms";

import { UNIT_LISTING_FIELDS } from "./createUnitListing.config";

const CreateUnitListingInnerFormElements = ({
  parentProp,
  securityDepositDisabled,
  availabilityDateDisabled,
}) => {
  const { errors, touched } = useFormikContext();
  const getId = (prop) => (parentProp ? `${parentProp}.${prop}` : prop);

  return (
    <>
      <Row gutter={[20, 25]}>
        <Col md={{ span: 12 }}>
          <MaskedInput.Formik.Money
            id={getId(UNIT_LISTING_FIELDS.rent)}
            name={getId(UNIT_LISTING_FIELDS.rent)}
            data-testid={UNIT_LISTING_FIELDS.rent}
            label="Unit Rent"
            required
            error={showError(getId(UNIT_LISTING_FIELDS.rent), touched, errors)}
          />
        </Col>
        <Col md={{ span: 12 }}>
          <MaskedInput.Formik.Money
            id={getId(UNIT_LISTING_FIELDS.securityDeposit)}
            name={getId(UNIT_LISTING_FIELDS.securityDeposit)}
            data-testid={UNIT_LISTING_FIELDS.securityDeposit}
            label="Security Deposit"
            error={showError(
              getId(UNIT_LISTING_FIELDS.securityDeposit),
              touched,
              errors
            )}
            disabled={securityDepositDisabled}
          />
        </Col>
      </Row>
      <Row gutter={[20, 25]}>
        <Col md={{ span: 12 }}>
          <VeroFormField
            as={DatePicker}
            id={getId(UNIT_LISTING_FIELDS.unitAvailableOn)}
            name={getId(UNIT_LISTING_FIELDS.unitAvailableOn)}
            data-testid={UNIT_LISTING_FIELDS.unitAvailableOn}
            label="Date Available"
            required
            disabled={availabilityDateDisabled}
          />
        </Col>
      </Row>
      <Row gutter={[20, 25]}>
        <Col>
          <VeroFormField
            as={TextArea}
            name={getId(UNIT_LISTING_FIELDS.note)}
            id={getId(UNIT_LISTING_FIELDS.note)}
            data-testid={UNIT_LISTING_FIELDS.note}
            label="Note"
            maxLength={500}
          />
        </Col>
      </Row>
    </>
  );
};

CreateUnitListingInnerFormElements.propTypes = {
  parentProp: PropTypes.string,
  securityDepositDisabled: PropTypes.bool,
  availabilityDateDisabled: PropTypes.bool,
};

CreateUnitListingInnerFormElements.defaultProps = {
  parentProp: undefined,
  securityDepositDisabled: false,
  availabilityDateDisabled: false,
};

export default CreateUnitListingInnerFormElements;
