import React, { useState, useEffect } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import EventIcon from "@mui/icons-material/Event";
import { ClickAwayListener } from "@mui/material";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";

import { DatePickerProps as BaseDatePickerProps } from "antd/lib/date-picker/interface";
import moment from "moment";

import RequiredIndicator from "shared/components/RequiredIndicator";
import Tooltip from "shared/components/Tooltip";
import { useDeviceType } from "shared/hooks";
import { deferred } from "shared/utils/misc.util";

import { Container, Error } from "../styled";

import {
  checkDevIncompatibility,
  PaperPropsSx,
  DialogPropsSx,
  TextInputSx,
} from "./util";

export type DatePickerProps = Omit<
  BaseDatePickerProps,
  "value" | "onChange"
> & {
  id: string;
  name: string;
  label: string;
  value?: moment.MomentInput;
  disabled?: boolean;
  setFieldValue?: (name: string, date: string) => void;
  error?: string;
  required?: boolean;
  valueFormat?: string;
  displayFormat?: string;
  withoutSuffixIcon?: boolean;
  monthPicker?: boolean;
  tooltip?: (current: moment.Moment, today: moment.Moment) => React.ReactNode;
  onBlur?: () => void;
  onChange?: (date: string) => void;
  disabledDate?: (date: moment.Moment) => boolean;
};

export const DatePicker: React.FC<DatePickerProps> = ({
  label,
  id,
  name,
  value: baseValue,
  disabled,
  setFieldValue,
  error,
  required,
  valueFormat = "YYYY-MM-DD",
  displayFormat = "MMM DD, YYYY",
  withoutSuffixIcon,
  monthPicker,
  tooltip,
  onChange,
  onBlur,
  disabledDate,
  ...props
}) => {
  checkDevIncompatibility({ setFieldValue, onChange });
  const momentDate = baseValue ? moment(baseValue, valueFormat) : null;
  const [focused, setFocused] = useState(false);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(momentDate);
  const { isMobile } = useDeviceType();

  const PickerComponent = isMobile ? MobileDatePicker : DesktopDatePicker;

  const changeLocal = (date: any) => {
    setValue(date);

    if (date === null || date?.isValid()) {
      const dateString = date?.isValid() ? date.format(valueFormat) : "";
      if (setFieldValue) {
        setFieldValue(name, dateString);
      } else if (onChange) {
        onChange(dateString);
      }

      if (!date) {
        deferred(onBlur);
      }
    }
  };

  const dateRender = (
    current: any,
    selectedDates: any,
    pickersDayProps: any
  ) => {
    const tooltipContent = tooltip ? tooltip(current, moment()) : null;
    return (
      <Tooltip title={tooltipContent} theme="light" trigger="hover">
        <div>
          <PickersDay
            {...pickersDayProps}
            style={{
              cursor: disabledDate?.(current) ? "not-allowed" : "pointer",
              pointerEvents: disabledDate?.(current) ? "none" : "auto",
            }}
          />
        </div>
      </Tooltip>
    );
  };

  const clickAwayAction = () => {
    if (focused) {
      setFocused(false);
      if (onBlur) {
        onBlur();
      }
    }
  };

  useEffect(() => {
    setValue(momentDate);
  }, [baseValue]);

  return (
    <>
      <Container>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <PickerComponent
            label={
              <>
                {label}
                <RequiredIndicator required={required} />
              </>
            }
            reduceAnimations
            inputFormat={focused ? "MM/DD/YYYY" : displayFormat}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            views={monthPicker && ["month", "year"]}
            showDaysOutsideCurrentMonth
            components={{
              OpenPickerIcon: withoutSuffixIcon
                ? null
                : value
                ? () => (
                    <CancelIcon
                      onClick={(e) => {
                        e.stopPropagation();
                        changeLocal(null);
                      }}
                    />
                  )
                : EventIcon,
            }}
            DialogProps={{
              sx: DialogPropsSx,
            }}
            PaperProps={{
              sx: PaperPropsSx,
            }}
            value={value}
            shouldDisableDate={disabledDate}
            shouldDisableMonth={disabledDate}
            onChange={changeLocal}
            componentsProps={{
              actionBar: {
                actions: (variant) =>
                  variant === "desktop"
                    ? ["today"]
                    : ["clear", "today", "cancel", "accept"],
              },
            }}
            renderDay={dateRender}
            renderInput={(params) => (
              <ClickAwayListener onClickAway={clickAwayAction}>
                <TextField
                  id={id}
                  name={name}
                  size="small"
                  focused={focused}
                  disabled={disabled}
                  sx={TextInputSx}
                  onClick={() => {
                    setOpen(true);
                    setFocused(true);
                  }}
                  data-testid={props["data-testid"]}
                  fullWidth
                  {...params}
                  error={(value && !value.isValid()) || Boolean(error)}
                />
              </ClickAwayListener>
            )}
            {...props}
          />
        </LocalizationProvider>
      </Container>
      {error && <Error className="error-message">{error}</Error>}
    </>
  );
};
