import React, { useState } from "react";

import { PSClickWrap } from "@pactsafe/pactsafe-react-sdk";

import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import { Modal } from "shared/components/Modal";
import { RegularText } from "shared/components/Typography";
import { useGetPactSafeConfig } from "shared/hooks/api";
import { logError } from "shared/lib/errors";

import useModal from "../../../hooks/useModal";

import { ClickwrapModalContent } from "./styled";

import { buildFilter } from ".";

export interface ClickwrapModalContext {
  onConfirm?: () => void;
  onCancel?: () => void;
  groupKey: string;
  tags?: object[];
  userEmail: string;
  customData?: any;
  title?: string;
  description?: string;
  closable?: boolean;
  showCancelLink?: boolean;
}

export const ClickwrapModal = () => {
  const {
    currentModalContext: {
      onConfirm,
      groupKey,
      tags,
      userEmail,
      customData,
      title,
      description,
      closable,
      showCancelLink,
    },
  }: {
    currentModalContext: ClickwrapModalContext;
  } = useModal();

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const [isClickwrapLoading, setIsClickwrapLoading] = useState(true);

  const { pactSafeConfig, isPactSafeConfigLoading } = useGetPactSafeConfig();
  const accessId = pactSafeConfig?.accessId;

  return (
    <Modal
      data-testid="clickwrap-modal"
      title={title}
      closable={closable}
      submit={onConfirm}
      closeOnSubmit={false}
      submitButtonDisabled={submitButtonDisabled}
      showCancelLink={showCancelLink}
    >
      <Modal.Body>
        {!isPactSafeConfigLoading && (
          <ClickwrapModalContent>
            {/* @ts-ignore */}
            <RegularText className="description">
              {description ||
                "Please agree to the below disclaimers before continuing."}
            </RegularText>
            {isClickwrapLoading && (
              <FlexContainer justifyContent="center">
                <Icon.LoadingSpinnerGreenIcon width={20} height={20} />
              </FlexContainer>
            )}
            {/* @ts-ignore */}
            <PSClickWrap
              accessId={accessId}
              groupKey={groupKey}
              signerId={userEmail}
              customData={customData}
              filter={buildFilter(tags)}
              clickWrapStyle="combined"
              allowDisagreed
              onError={(message, eventType, context) =>
                logError({ message, eventType, context })
              }
              onRendered={() => {
                const company = customData?.company;

                if (company) {
                  const contracts =
                    document.getElementsByClassName("ps-contract");

                  Array.from(contracts).forEach((c) => {
                    const contractIds = c.getAttribute("data-cid").split(",");
                    const links = c.getElementsByClassName("ps-contract-link");

                    Array.from(links).forEach((l, i) => {
                      l.setAttribute(
                        "href",
                        `https://vault.pactsafe.io/s/${accessId}/${company}.html#contract-id-${contractIds[i]}`
                      );
                    });
                  });
                }
              }}
              onDisplayed={() => {
                setIsClickwrapLoading(false);
              }}
              onValid={() => setSubmitButtonDisabled(false)}
              onInvalid={() => setSubmitButtonDisabled(true)}
            />
          </ClickwrapModalContent>
        )}
      </Modal.Body>
    </Modal>
  );
};
