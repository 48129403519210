import styled from "@emotion/styled";

const Container = styled.div`
  padding-top: 10px;
  max-width: 180px;

  .select-wrapper {
    margin-bottom: 16px;
  }
`;

export { Container };
