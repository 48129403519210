import styled from "@emotion/styled";

import { COLORS, MEDIA_QUERIES, TRANSITIONS } from "shared/config/constants";

const Container = styled("div")`
  & .StripeElement {
    padding: 12px 16px;
    border: 1px solid;
    border-radius: 2px;
    border-color: ${COLORS.input.default.border};
    line-height: 1;
    color: ${COLORS.input.default.text};
    transition: ${TRANSITIONS.default};
    &:disabled {
      background-color: ${COLORS.input.disabled.background};
      color: ${COLORS.input.disabled.text} !important;
    }

    &--invalid {
      border-color: ${COLORS.input.invalid.border} !important;
      color: ${COLORS.input.default.text};
    }

    &--focus,
    &--invalid {
      box-shadow: none;
      outline: none;
    }

    &:hover {
      border-color: ${COLORS.input.hover.border};
    }

    &--focus {
      border-color: ${COLORS.input.focus.border} !important;
    }

    &.invalid {
      border-color: ${COLORS.input.invalid.border} !important;
    }
  }

  & .form-row {
    background: #333;
  }

  & *::placeholder {
    color: #807e8d;
  }
`;

const getInputOptions = () => ({
  base: {
    "::selection": {
      color: "#FFFFFF",
      backgroundColor: "#2D2943",
    },
  },
  invalid: {
    color: COLORS.input.default.text,
  },
});

const CardNumberHint = styled("div")`
  padding-top: 32px;
  padding-bottom: 24px;
  display: flex;
  ${MEDIA_QUERIES.w.ltSm} {
    flex-direction: column;
  }
`;

const Strong = styled("span")`
  color: #2d2943;
  font-weight: bold;
`;

const StripeBox = styled("div")`
  display: flex;
  align-items: center;
  margin-left: auto;
  ${MEDIA_QUERIES.w.ltSm} {
    margin-left: 0;
    margin-top: 12px;
  }
`;

const FaintLabel = styled("span")`
  font-size: 12px;
  padding-right: 5px;
`;

const PaymentInProgress = styled("div")`
  padding-top: 32px;
  padding-bottom: 24px;
`;

export {
  Container,
  getInputOptions,
  CardNumberHint,
  Strong,
  FaintLabel,
  StripeBox,
  PaymentInProgress,
};
