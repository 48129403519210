import styled from "@emotion/styled";

import Icon from "shared/components/Icon";
import { COLORS } from "shared/config/constants";

export const StyledRow = styled.tr`
  &.row-dragging {
    background-color: ${COLORS.itemActiveBg};
    border: 1px solid ${COLORS.borders};
    border-left: 3px solid ${COLORS.accent};
    vertical-align: middle;
    td {
      padding: 16px 24px;
    }
    .drag-visible {
      visibility: visible;
    }
  }
`;

export const DraggableIcon = styled(Icon.DraggableIcon)`
  cursor: pointer;
`;
