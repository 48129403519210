import styled from "@emotion/styled";

import { SwitchTitle } from "shared/components/Form";
import { ActionLink } from "shared/components/Links";
import Table from "shared/components/Table";
import { COLORS } from "shared/config/constants";

const StyledSwitchTitle = styled(SwitchTitle)`
  padding-left: 40px;
  border-bottom: 1px solid ${COLORS.borders};

  label {
    padding-left: 0;
  }
`;

const StyledActionLink = styled(ActionLink)`
  padding: 24px 0 24px 40px;
`;

const StyledTable = styled(Table)`
  tr th:first-of-type,
  tr td:first-of-type {
    padding-left: 40px;
  }
`;

export { StyledSwitchTitle, StyledActionLink, StyledTable };
