import React from "react";

import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import {
  useCreatePropertyEmailConfiguration,
  useDeletePropertyEmailConfiguration,
  useGetCustomEmailConfigurations,
  useGetPropertyEmailConfiguration,
} from "manager/hooks/api";

import { DEFAULT_KEY } from "../utils";

import PropertyEmailTemplatesForm from "./PropertyEmailTemplatesForm";

const PropertyEmailTemplatesFormContainer = ({
  defaultConfiguration,
  registerFormChange,
}) => {
  const { id } = useParams();
  const { typeName } = defaultConfiguration;
  const propertyId = Number(id);
  const { customEmailConfigurations, isCustomConfigurationsLoading } =
    useGetCustomEmailConfigurations({ typeName });

  const { propertyEmailConfiguration, isPropertyEmailConfigurationLoading } =
    useGetPropertyEmailConfiguration({ propertyId, typeName });

  const { createPropertyEmailConfiguration } =
    useCreatePropertyEmailConfiguration();

  const { deletePropertyEmailConfiguration } =
    useDeletePropertyEmailConfiguration();

  const onSubmit = (values) => {
    if (values.customEmailConfigurationId === DEFAULT_KEY) {
      return deletePropertyEmailConfiguration({
        propertyId,
        typeName,
        configurationId: propertyEmailConfiguration.id,
      });
    }

    return createPropertyEmailConfiguration({
      propertyId,
      customEmailConfigurationId: Number(values.customEmailConfigurationId),
      typeName,
    });
  };

  return (
    <PropertyEmailTemplatesForm
      isLoading={
        isCustomConfigurationsLoading || isPropertyEmailConfigurationLoading
      }
      configurations={customEmailConfigurations}
      currentConfigurationId={
        propertyEmailConfiguration?.customEmailConfiguration
      }
      onSubmit={onSubmit}
      registerFormChange={registerFormChange}
      typeName={typeName}
    />
  );
};

PropertyEmailTemplatesFormContainer.propTypes = {
  defaultConfiguration: PropTypes.shape({
    typeName: PropTypes.string.isRequired,
  }).isRequired,
  registerFormChange: PropTypes.func.isRequired,
};

export default PropertyEmailTemplatesFormContainer;
