import styled from "@emotion/styled";

import { Modal } from "shared/components/Modal";

export const StyledModal = styled(Modal)`
  top: 40% !important;
`;

export const ModalBody = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const ProgressContainer = styled("div")`
  width: 60px;
  padding-top: 25px;
`;
