import * as Yup from "yup";

import { FIELD_TYPES } from "admin/config/constants";

// Checkbox group signatures

export const CHECKBOX_GROUP_SIGNATURE_TYPE = Object.freeze({
  id: "CHECKBOX_GROUP_SIGNATURE_TYPE",
  value: "Grouped Checkbox (HelloSign)",
});

export const SIGNATURE_CATEGORY = Object.freeze({
  id: "SIGNATURE",
  value: "Signature",
});

export const CHECKBOX_GROUP_SIGNATURE_VALUES = Object.freeze({
  min: null,
  allowed: null,
});

export const APPLICANT_LEVEL = "AQ";

export const isSourceRequired = (value) =>
  Boolean(value) && value !== APPLICANT_LEVEL;

export const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  type: Yup.string().required("Field type is required"),
  category: Yup.string().required("Category is required"),
  sample: Yup.string(),
  description: Yup.string(),
  smartSource: Yup.object().when("type", {
    is: FIELD_TYPES.smart,
    then: Yup.object()
      .shape({
        app: Yup.string().required("App is required"),
        model: Yup.string().required("Model is required"),
        attribute: Yup.string().required("Property is required"),
        format: Yup.string().required("Field format is required"),
      })
      .required(),
    otherwise: Yup.object().nullable(),
  }),
  question: Yup.string().when("type", {
    is: FIELD_TYPES.checkbox,
    then: Yup.string().required("Question is required"),
    otherwise: Yup.string().nullable(),
  }),
  checkboxSignature: Yup.object().when("type", {
    is: CHECKBOX_GROUP_SIGNATURE_TYPE.id,
    then: Yup.object()
      .shape({
        min: Yup.number()
          .nullable()
          .integer("Please enter a positive integer")
          .positive("Please enter a positive integer")
          .required("This field is is required"),
        allowed: Yup.number()
          .nullable()
          .integer("Please enter a positive integer")
          .positive("Please enter a positive integer")
          .required("Total selections number is required")
          .min(
            Yup.ref("min"),
            "The entered value can't be less than `Required Selections` number"
          ),
      })
      .required(),
    otherwise: Yup.object().nullable(),
  }),
});

export const initValues = {
  name: "",
  type: "",
  category: "",
  sample: "",
  description: "",
  smartSource: null,
  checkboxSignature: null,
};
