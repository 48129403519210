import axios from "axios";

import ROUTES from "shared/config/apiRoutes";
import { API_URL } from "shared/lib/api/setupApi";
import { transformRoute } from "shared/utils/routing";

const api = {
  getIncomeAndAsset: ({ applicationId }) =>
    axios
      .get(
        `${API_URL}${transformRoute(ROUTES.getIncomeAndAsset, {
          applicationId,
        })}`
      )
      .then(({ data }) => data),
  skipIncomeAndAsset: ({
    applicationId,
    bankName,
    skippedReason,
    plaidMetadata,
  }) =>
    axios
      .post(
        `${API_URL}${transformRoute(ROUTES.skipIncomeAndAsset, {
          applicationId,
        })}`,
        {
          bankName,
          skippedReason,
          plaidMetadata,
        }
      )
      .then(({ data }) => data),
  confirmIncome: ({ applicationId }) =>
    axios
      .post(
        `${API_URL}${transformRoute(ROUTES.confirmIncome, {
          applicationId,
        })}`
      )
      .then(({ data }) => data),
  resetIncomeAndAssets: ({ applicationId }) =>
    axios
      .delete(
        `${API_URL}${transformRoute(ROUTES.resetIncomeAndAssets, {
          applicationId,
        })}`
      )
      .then(({ data }) => data),
  editIncomeAssetsNote: ({ incomeAndAssetId, note }) =>
    axios
      .post(
        `${API_URL}${transformRoute(ROUTES.editIncomeAssetsNote, {
          id: incomeAndAssetId,
        })}`,
        {
          notes: note,
        }
      )
      .then(({ data }) => data),
};

export default api;
