import { queryCache, useMutation, useQuery, QueryConfig } from "react-query";

import {
  SubmittedLead,
  InviteLeadToApplyRequest,
} from "manager/interfaces/api";
import api from "manager/lib/api";
import { NOTIFICATIONS } from "shared/config/constants";
import { openNotification } from "shared/utils/ui";

const GET_LEADS = "getLeads";
const GET_LEADS_COUNTS = "getLeadsCounts";
const GET_PRESCREENING_PROPERTIES = "getPrescreeningProperties";
const GET_SUBMITTED_LEADS = "getSubmittedLeads";

const QUERY_KEYS = Object.freeze({
  getLeads: () => [GET_LEADS],
  getByFilters: (filters) => [GET_LEADS, filters],
  getLeadsCountsByFilters: (filters) => [GET_LEADS_COUNTS, filters],
  getLeadById: (id) => [GET_LEADS, Number(id)],
  getPrescreeningProperties: () => [GET_PRESCREENING_PROPERTIES],
  getSubmittedLeads: () => [GET_SUBMITTED_LEADS],
});

export const useInviteLead = () => {
  const [mutate, { isLoading }] = useMutation((lead) => api.inviteLead(lead), {
    throwOnError: true,
    onSuccess: () => {
      openNotification("Invite successfully sent!", NOTIFICATIONS.info);
      queryCache.invalidateQueries(QUERY_KEYS.getLeads());
    },
    onError: () => {
      openNotification("Unable to invite lead", NOTIFICATIONS.error);
    },
  });

  return {
    inviteLead: mutate,
    isInviteLeadLoading: isLoading,
  };
};

export const useReinviteLead = () => {
  const [mutate, { isLoading }] = useMutation((id) => api.reinviteLead(id), {
    onSuccess: () => {
      openNotification("Invite successfully sent!", NOTIFICATIONS.info);
      queryCache.invalidateQueries(QUERY_KEYS.getLeads());
    },
    onError: () => {
      openNotification("Unable to reinvite lead", NOTIFICATIONS.error);
    },
  });

  return {
    reinviteLead: mutate,
    isReinviteLeadLoading: isLoading,
  };
};

export const useUpdateLeadArchiveStatus = () => {
  const [mutate, { isLoading }] = useMutation(api.updateLeadArchiveStatus, {
    onSuccess: (_data, { id, isArchived }) => {
      queryCache.invalidateQueries(QUERY_KEYS.getLeadById(id));
      openNotification(
        `Lead has been successfully ${isArchived ? "archived" : "unarchived"}`,
        NOTIFICATIONS.info
      );
    },
    onError: (_data, { isArchived }) => {
      openNotification(
        `Failed to ${isArchived ? "archive" : "unarchive"} lead`,
        NOTIFICATIONS.error
      );
    },
  });

  return {
    updateLeadArchiveStatus: mutate,
    isUpdateLeadArchiveStatusLoading: isLoading,
  };
};

export const useGetLeads = (filters, config = {}) => {
  const {
    data: leads,
    isLoading: isLeadsLoading,
    refetch: refetchLeads,
  } = useQuery(QUERY_KEYS.getByFilters(filters), () => api.getLeads(filters), {
    onError: () => {
      openNotification("Failed to load leads", NOTIFICATIONS.error);
    },
    ...config,
  });

  return {
    leads,
    isLeadsLoading,
    refetchLeads,
  };
};

export const useGetLeadsCounts = (filters, config = {}) => {
  const { data: leadsCounts, isLoading: isLeadsCountsLoading } = useQuery(
    QUERY_KEYS.getLeadsCountsByFilters(filters),
    () => api.getLeadsCounts(filters),
    {
      onError: () => {
        openNotification("Failed to load leads", NOTIFICATIONS.error);
      },
      ...config,
    }
  );

  return {
    leadsCounts,
    isLeadsCountsLoading,
  };
};

export const useGetLead = (id) => {
  const { data, isLoading } = useQuery(
    QUERY_KEYS.getLeadById(id),
    () => api.getLead(id),
    {
      enabled: Boolean(id),
      forceFetchOnMount: true,
      onError: () =>
        openNotification("Failed to load the lead", NOTIFICATIONS.error),
    }
  );
  return {
    lead: data,
    isLeadLoading: isLoading,
  };
};

export const useTogglePropertyPrequalification = () => {
  const [
    togglePropertyPrequalification,
    { isLoading: isSavingPrequalification },
  ] = useMutation(api.togglePropertyEligibilityForPrequalification, {
    onSuccess: () => {
      queryCache.invalidateQueries(QUERY_KEYS.getPrescreeningProperties());
    },
    onError: () => {
      openNotification(
        "Failed to change property eligibility for prequalification",
        NOTIFICATIONS.error
      );
    },
  });
  return {
    togglePropertyPrequalification,
    isSavingPrequalification,
  };
};

export const getPrescreeningProperties = () => {
  const { data, isLoading } = useQuery(
    QUERY_KEYS.getPrescreeningProperties(),
    () => api.getPrescreeningProperties(),
    {
      onError: () =>
        openNotification(
          "Failed to load prequalification properties",
          NOTIFICATIONS.error
        ),
    }
  );
  return {
    prescreeningProperties: data,
    isPrescreeningPropertiesLoading: isLoading,
  };
};

export const useInviteLeadToApply = () => {
  const [mutate, { isLoading }] = useMutation(
    (params: InviteLeadToApplyRequest) => api.inviteLeadToApply(params),
    {
      onSuccess: () => {
        openNotification("Lead invited successfully", NOTIFICATIONS.info);
      },
    }
  );

  return {
    inviteLeadToApply: mutate,
    isInviteLeadToApplyLoading: isLoading,
  };
};

export const useGetSubmittedLeads = (
  config: QueryConfig<SubmittedLead[]> = {}
) => {
  const { data, isLoading } = useQuery(
    QUERY_KEYS.getSubmittedLeads(),
    () => api.getSubmittedLeads(),
    {
      ...config,
      onError: () =>
        openNotification("Failed to fetch leads", NOTIFICATIONS.error),
      ...config,
    }
  );
  return {
    submittedLeads: data,
    isSubmittedLeadsLoading: isLoading,
  };
};
