import React from "react";

import { PrescreeningSettings } from "manager/components/Prescreening";
import { useGetDefaultPrescreeningDealSetup } from "manager/hooks/api/dealSetupQueries";
import SettingsPage from "manager/templates/SettingsPage";
import { BackLinkProps } from "shared/interfaces/misc";

interface CompanyPrescreeningSettingsProps {
  backLink: BackLinkProps;
}

const CompanyPrescreeningSettings = ({
  backLink,
}: CompanyPrescreeningSettingsProps) => {
  const { defaultPrescreeningDealSetup } = useGetDefaultPrescreeningDealSetup();
  return (
    <SettingsPage title="Prequalification Setup" backLink={backLink}>
      <PrescreeningSettings dealSetupId={defaultPrescreeningDealSetup?.id} />
    </SettingsPage>
  );
};

export default CompanyPrescreeningSettings;
