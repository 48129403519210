import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { Layout } from "antd";

import BaseTabs from "shared/components/Tabs";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

const { Content } = Layout;

const Container = styled.div`
  flex: 1;
`;

const ContentContainer = styled(Content)`
  border: 1px solid ${COLORS.borders};
  border-radius: 2px;
  ${MEDIA_QUERIES.w.ltMd} {
    border-left: 0;
    border-right: 0;
  }
`;

const Tabs = styled(BaseTabs)`
  border: 1px solid ${COLORS.borders};
  border-bottom: 0;
  border-radius: 2px;
`;

const FiltersContainer = styled.div<{ noActions?: boolean }>`
  padding: 0 25px 20px 25px;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  min-height: 56px;

  ${({ noActions }) =>
    noActions
      ? css`
          & > div:nth-of-type(1) {
            width: 100%;
          }
        `
      : css`
          justify-content: space-between;
          & > div:nth-of-type(1) {
            padding: 0;
          }
        `}
`;

export { Container, ContentContainer, Tabs, FiltersContainer };
