import React from "react";

import AddApplicantsModalContainer from "renter/components/Modal/InviteApplicants/AddApplicantsModal";
import AddGuarantorsModalContainer from "renter/components/Modal/InviteApplicants/AddGuarantorsModal";
import AddOccupantsModalContainer from "renter/components/Modal/InviteApplicants/AddOccupantsModal";

import useModal from "shared/hooks/useModal";

import InviteApplicants from "./InviteApplicants";

interface InviteApplicantsContainerProps {
  property: string;
  unit: string;
  dealId: number;
  companyName: string;
  hideOccupantsButAllowDependents: boolean;
  hideGuarantorAsApplicantType: boolean;
}

const InviteApplicantsContainer = ({
  property,
  unit,
  dealId,
  companyName,
  hideGuarantorAsApplicantType,
  hideOccupantsButAllowDependents,
}: InviteApplicantsContainerProps) => {
  const { openModalDialog } = useModal();

  const openDialog = (id: React.ReactNode) =>
    openModalDialog(id, {
      property,
      unit,
      dealId,
      companyName,
    });

  const addOccupant = () => openDialog(AddOccupantsModalContainer);

  const addGuarantor = () => openDialog(AddGuarantorsModalContainer);

  const addApplicant = () => openDialog(AddApplicantsModalContainer);

  return (
    <InviteApplicants
      addOccupant={addOccupant}
      addApplicant={addApplicant}
      addGuarantor={addGuarantor}
      hideGuarantorAsApplicantType={hideGuarantorAsApplicantType}
      hideOccupantsButAllowDependents={hideOccupantsButAllowDependents}
    />
  );
};

export default InviteApplicantsContainer;
