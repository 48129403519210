import styled from "@emotion/styled";

import FlexContainer from "shared/components/FlexContainer";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";
import { OverflowEllipsis } from "shared/styles/commonStyles";

import { PortalWall, TilesGrid } from "../styled";

const UnitsWall = styled(PortalWall)`
  .title {
    text-align: center;
    margin-bottom: 40px;
  }

  .header {
    background: ${COLORS.white};
    padding: 40px;
  }

  .filters-panel {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    > * {
      margin: 10px;

      ${MEDIA_QUERIES.w.ltLg} {
        margin: 5px;
      }
    }
  }

  #maxRent {
    max-width: 185px;
  }

  .footer {
    padding: 20px 48px;
    text-align: right;
    display: flex;
    justify-content: space-between;
  }

  .back-link {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 100;
  }

  ${MEDIA_QUERIES.w.ltLg} {
    width: 95%;
  }

  ${MEDIA_QUERIES.w.ltMd} {
    height: calc(100vh - 190px);
  }
`;

const UnitsTilesGrid = styled(TilesGrid)`
  padding-bottom: 40px;
  overflow: auto;

  .magic-unit-tile {
    margin: 0px 15px 30px 15px;
  }

  ${MEDIA_QUERIES.w.ltMd} {
    overflow: visible;
  }

  ${MEDIA_QUERIES.w.ltSm} {
    .magic-unit-tile {
      margin-bottom: 24px;
    }
  }
`;

const StyledPropertyAddress = styled(FlexContainer)`
  .property-separator {
    padding: 0 20px;
    border-left: 1px solid ${COLORS.borderColorBase};
  }

  .property-icon {
    min-width: 28px;
    min-height: 28px;
  }

  ${MEDIA_QUERIES.w.ltMd} {
    .property-name {
      ${OverflowEllipsis}
      width: 30vw;
    }

    .property-address {
      ${OverflowEllipsis}
      width: 30vw;
    }

    .property-separator:first-child {
      border-left: none;
      padding-left: 0;
    }

    .property-icon path {
      fill: ${COLORS.white};
    }
  }
`;

const MobileUnitsWall = styled.div`
  padding-top: 62px;
  padding-bottom: 50px;
  position: relative;

  .logo {
    max-width: 60px;
    max-height: 60px;
    margin: 20px 0;
  }

  .title {
    text-align: center;
    margin-bottom: 20px;
  }

  .property-tile {
    margin-bottom: 0;
  }

  .main-content {
    height: calc(100vh - 190px);
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .pagination-controls {
    margin: 0 auto;
  }
`;

const StyledFiltersPanel = styled.section`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  > * {
    margin: 10px;

    ${MEDIA_QUERIES.w.ltLg} {
      margin: 5px;
    }
  }

  ${MEDIA_QUERIES.w.ltSm} {
    flex-direction: column;
    align-items: stretch;

    > * {
      margin-bottom: 10px;
    }
  }
`;

export {
  UnitsWall,
  UnitsTilesGrid,
  StyledPropertyAddress,
  MobileUnitsWall,
  StyledFiltersPanel,
};
