import axios from "axios";

import type {
  AnnotateIncomeStreamRequestParams,
  IncomeByStreamsRequestParams,
  IncomeByStreamsResponse,
  IncomesByBucketsRepsonse,
  IncomesByBucketsRequestParams,
} from "renter/interfaces/api/income";

import { VEGA_BASE_URL } from "shared/config/apiRoutes";
import { encodeObjectToBase64 } from "shared/utils/misc.util";

const INCOME_SERVICE_PATH = "/api/income";

const api = {
  getIncomes: (params: { person?: string; employment?: string }) =>
    axios
      .get(`${VEGA_BASE_URL}${INCOME_SERVICE_PATH}`, {
        params,
      })
      .then(({ data }) => data),
  createIncome: ({ payload }) =>
    axios
      .post(
        `${VEGA_BASE_URL}${INCOME_SERVICE_PATH}`,
        payload,
        // @ts-ignore
        { convertToSnakeCase: false }
      )
      .then(({ data }) => data),
  updateIncomeById: ({ incomeId, payload }) =>
    axios
      .patch(
        `${VEGA_BASE_URL}${INCOME_SERVICE_PATH}/${encodeURIComponent(
          incomeId
        )}`,
        payload,
        // @ts-ignore
        { convertToSnakeCase: false }
      )
      .then(({ data }) => data),
  deleteIncomeById: ({ incomeId }) =>
    axios
      .delete(`${VEGA_BASE_URL}${INCOME_SERVICE_PATH}/${incomeId}`)
      .then(({ data }) => data),
  getIncomesByBuckets: ({
    windoEnd,
    windowDuration,
    windowStart,
    person,
    bucketDuration,
    groupingRule,
    statsOnly,
  }: IncomesByBucketsRequestParams) =>
    axios
      .get<IncomesByBucketsRepsonse>(
        `${VEGA_BASE_URL}${INCOME_SERVICE_PATH}/bucket`,
        {
          params: {
            person,
            "window-start": windowStart,
            "window-end": windoEnd,
            "bucket-duration": bucketDuration,
            "window-duration": windowDuration,
            "grouping-rule": groupingRule,
            "stats-only": statsOnly,
          },
        }
      )
      .then(({ data }) => data),
  getIncomeByStream: ({
    detailed,
    groupingRule,
    incomeNumberLimit,
    location,
    person,
    applicant,
    referenceDate,
  }: IncomeByStreamsRequestParams) =>
    axios
      .get<IncomeByStreamsResponse>(
        `${VEGA_BASE_URL}${INCOME_SERVICE_PATH}/stream`,
        {
          params: {
            detailed,
            "group-rule": encodeObjectToBase64(groupingRule),
            location,
            person,
            applicant,
            "reference-date": referenceDate,
            "supporting-income-limit": incomeNumberLimit,
          },
        }
      )
      .then(({ data }) => data),
  annotateIncomeStream: (params: AnnotateIncomeStreamRequestParams) => {
    const { identifier, isIncluded, owner, reason } = params
    return axios.post(
      `${VEGA_BASE_URL}${INCOME_SERVICE_PATH}/annotation`,
      {
        identifier,
        owner: `vrn:applicant::${owner}`,
        isIncluded,
        ...(params.isIncluded === true
          ? { includedReasons: reason }
          : { excludedReasons: reason }),
      }, // @ts-ignore
      { convertToSnakeCase: false },
    )
  },
  patchIncomeStreamAnnotation: (
    params: AnnotateIncomeStreamRequestParams & { annotationId: string },
  ) =>
    axios.patch(
      `${VEGA_BASE_URL}${INCOME_SERVICE_PATH}/annotation/${params.annotationId}`,
      {
        isIncluded: params.isIncluded,
        ...(params.isIncluded === true
          ? { includedReasons: params.reason }
          : { excludedReasons: params.reason }),
      }, // @ts-ignore
      { convertToSnakeCase: false },
    ),
}

export default api;
