import React from 'react'
import { Radio } from 'antd'

const LabelColor = ({ labelColor, canEdit, onChange }) => {
  return (
    <Radio.Group
      defaultValue='pass'
      buttonStyle='solid'
      value={labelColor}
      onChange={e => onChange(e?.target?.value)}
      disabled={!canEdit}
    >
      <Radio.Button value='pass' className='vega pass'>Pass</Radio.Button>
      <Radio.Button value='warn' className='vega warn'>Warn</Radio.Button>
      <Radio.Button value='fail' className='vega fail'>Fail</Radio.Button>
    </Radio.Group>
  )
}

export default LabelColor
