import { Skeleton } from 'antd-latest'
import type { SkeletonParagraphProps } from 'antd-latest/lib/skeleton/Paragraph'

import Spacer from 'shared/components/Spacer'
import Tile from 'shared/components/Tile'

interface SkeletonProps {
  title: SkeletonParagraphProps
}

export const IAndBSkeleton = ({ title }: SkeletonProps) => (
  <Tile rounded>
    <Tile.Inner>
      <Skeleton title={false} paragraph={title} />
      <Spacer size={Spacer.SIZES.md} />
      <div style={{ padding: '0 24px' }}>
        <Skeleton title={false} paragraph={{ rows: 3, width: '100%' }} />
      </div>
    </Tile.Inner>
  </Tile>
)
