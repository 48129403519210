import styled from "@emotion/styled";

import { colors } from "shared/config/constants";

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 48px;
  padding-bottom: 0;
  &.edit {
    justify-content: flex-start;
    padding-top: 20px;
  }
  &.no-edit {
    align-items: flex-start;
    padding-top: 20px;
  }
  .margin-left-10 {
    margin-left: 10px;
  }
  .font-size-17 {
    font-size: 17px;
  }
`;

export const CompanyLogoImg = styled.img`
  width: 36px;
  height: 36px;
  margin-right: 10px;
`;

export const EditLink = styled.span`
  color: ${colors.accent[500]};
  text-decoration: underline;
  margin-left: 10px;
  cursor: pointer;
  font-size: 17px;
`;

export const ListWrapper = styled.div`
  margin-top: 20px;
  margin-left: 48px;
  margin-right: 48px;
  padding: 10px;
  height: 300px;
  overflow: scroll;
  border: 1px solid #ccc;
  .list-item {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  &.text {
    display: flex;
    align-items: center;
    font-size: 18px;
    text-align: center;
  }
`;
