import { ApplicationStep, DEAL_UI_STATUS_CODES } from "shared/config/constants";

import { GroupStep, Step } from "./interfaces";

export const toDollars = (cents?: number) => (!cents ? cents : cents / 100);

export const toDate = (date?: string | Date) =>
  !date ? undefined : new Date(date);

export const getSkippedIncomeAssetsReason = (
  appSummary: Record<string, any>
) => {
  const bankName = appSummary.assetBankName;
  const skippedReason = appSummary.assetSkippedReason;
  const reasonTexts = {
    BankError: `An error occurred while individual was attempting to connect to their bank: ${bankName}`,
    UserRefusedSharingCredentials: `Individual chose not to share their bank credentials`,
    BankNotFound: `Individual was unable to locate their Bank: ${bankName}`,
    default: "Individual skipped verification",
  };

  return reasonTexts[skippedReason] || reasonTexts.default;
};

export const firstUnfinishedStep = (
  stepsData?: GroupStep[],
  startedAt?: string,
  assetIncomeEnabled?: boolean,
  skippedBGCC?: boolean,
  identityEnabled?: boolean,
  employmentEnabled?: boolean,
  hasApplicationFee?: boolean
): ApplicationStep | undefined => {
  if (!startedAt) return ApplicationStep.acceptInvitation;
  if (!stepsData) return undefined;
  const flattenedSteps: Step[] = stepsData.flatMap<Step>((step) => step.steps);
  const filteredSteps = flattenedSteps.filter((steps) => {
    if (steps.completed === null) return false;
    if (!assetIncomeEnabled && steps.step === "income_and_asset") {
      return false;
    }
    if (skippedBGCC && steps.step === "background_credit_check") {
      return false;
    }
    if (!identityEnabled && steps.step === "identity_verification") {
      return false;
    }
    if (!employmentEnabled && steps.step === "link_employment") {
      return false;
    }
    if (!hasApplicationFee && steps.step === "payment") {
      return false;
    }
    return true;
  });
  return filteredSteps?.find((step: Step) => !step.completed)?.step;
};

export const showConditionalProceed = (deal: Record<string, any>): boolean => {
  return deal.hasConditionallyProceedEnabled && !deal.isConditionallyProceeded;
};
