import React, { useState, useEffect } from "react";

import { Formik } from "formik";

import { isSubstrInStr } from "shared/utils/ui";

import { validationSchema } from "./config";
import CurrentSelection from "./CurrentSelection";
import { AssignDocumentModalProps, Company } from "./interfaces";
import SelectCompanyForm from "./SelectCompanyForm";
import { Modal, Note } from "./styled";

const AssignDocumentModal = ({
  documents,
  companies,
  isLoading,
  onSubmit,
}: AssignDocumentModalProps) => {
  const [filteredItems, setFilteredItems] = useState<Company[]>([]);
  const [searchingValue, setSearchingValue] = useState("");
  const [selectedCompany, setSelectedCompany] = useState({});
  const documentNames = documents.map((document) => document.name).join(", ");
  const isMultipleDocuments = documents?.length > 1;

  useEffect(() => {
    setFilteredItems(
      companies?.filter((company) =>
        isSubstrInStr(company.name, searchingValue)
      )
    );
  }, [searchingValue, companies]);

  return (
    <Formik
      initialValues={{ company: null }}
      onSubmit={onSubmit}
      enableReinitialize
      validationSchema={validationSchema}
      validateOnMount
    >
      {({ isValid, submitForm, isSubmitting }) => (
        <Modal
          title="Assign document to company"
          subtitle={
            isMultipleDocuments
              ? `Document(s): ${documentNames}`
              : `Document: ${documentNames}`
          }
          submitButtonLabel="ASSIGN"
          submitButtonDisabled={!isValid}
          submit={submitForm}
          submitting={isSubmitting}
          width="auto"
          style={{ maxWidth: 946 }}
          closeOnSubmit={false}
        >
          <Modal.Body>
            <Note>
              Select company to which you want to assign this document
            </Note>

            <SelectCompanyForm
              isLoading={isLoading}
              items={filteredItems}
              setSearchingValue={setSearchingValue}
              setSelectedCompany={setSelectedCompany}
            />

            <CurrentSelection selectedCompany={selectedCompany} />
          </Modal.Body>
        </Modal>
      )}
    </Formik>
  );
};

export default AssignDocumentModal;
