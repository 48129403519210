import axios from "axios";

import ROUTES from "manager/config/apiRoutes";
import {
  BluemoonHiddenPropertiesData,
  BluemoonHiddenPropertiesRequest,
  BluemoonPropertiesCount,
  BluemoonPropertiesData,
  BluemoonPropertiesRequest,
  BindPropertiesRequest,
  BluemoonDashboardData,
} from "manager/interfaces/api/bluemoon";
import {
  Filters,
  FieldsFilters,
} from "manager/pages/Marketplace/integrations/bluemoon/interfaces";
import { API_URL } from "shared/lib/api/setupApi";
import { transformRoute } from "shared/utils/routing";

const api = {
  getBluemoonLicenses: ({ ordering, state, idNameOrState }: Filters) =>
    axios
      .get(`${API_URL}${ROUTES.getBluemoonLicenses}`, {
        params: {
          ordering,
          state,
          id_name_or_state: idNameOrState,
        },
      })
      .then(({ data }) => data),
  getBluemoonFields: ({ nameType, type }: FieldsFilters) =>
    axios
      .get(`${API_URL}${ROUTES.getBluemoonFields}`, {
        params: {
          name_type: nameType,
          type,
        },
      })
      .then(({ data }) => data),
  getBluemoonFieldsTypes: () =>
    axios
      .get(`${API_URL}${ROUTES.getBluemoonFieldsTypes}`, {})
      .then(({ data }) => data),
  getBluemoonFieldsDatapoints: () =>
    axios
      .get(`${API_URL}${ROUTES.getBluemoonFieldsDatapoints}`, {})
      .then(({ data }) => data),
  updateBluemoonField: (field) =>
    axios.patch(
      transformRoute(`${API_URL}${ROUTES.updateBluemoonField}`, {
        id: field.id,
      }),
      { ...field }
    ),
  getVeroProperties: ({
    state,
    status,
    page,
    pageSize,
    searchValue,
    ordering,
  }: BluemoonPropertiesRequest) =>
    axios
      .get(`${API_URL}${ROUTES.getVeroProperties}`, {
        params: {
          state,
          status,
          page,
          ordering,
          page_size: pageSize,
          name_address: searchValue,
        },
      })
      .then(({ data }) => data),
  hideBluemoonProperty: (propertyId: number) =>
    axios
      .patch(`${API_URL}${ROUTES.hideBluemoonProperty}`, {
        bluemoonProperties: [propertyId],
      })
      .then(({ data }) => data),
  getBluemoonPropertiesCount: () =>
    axios
      .get<BluemoonPropertiesCount>(
        `${API_URL}${ROUTES.getBluemoonPropertiesCount}`
      )
      .then(({ data }) => data),
  getBluemoonProperties: ({
    state,
    status,
    page,
    pageSize,
    searchValue,
  }: BluemoonPropertiesRequest) =>
    axios
      .get<BluemoonPropertiesData>(
        `${API_URL}${ROUTES.getBluemoonProperties}`,
        {
          params: {
            state,
            status,
            page,
            page_size: pageSize,
            name_address: searchValue,
          },
        }
      )
      .then(({ data }) => data),
  getBluemoonHiddenProperties: ({
    state,
    page,
    pageSize,
    searchValue,
  }: BluemoonHiddenPropertiesRequest) =>
    axios
      .get<BluemoonHiddenPropertiesData>(
        `${API_URL}${ROUTES.getBluemoonHiddenProperties}`,
        {
          params: {
            state,
            page,
            page_size: pageSize,
            name_address: searchValue,
          },
        }
      )
      .then(({ data }) => data),
  unhideBluemoonProperties: (data: { bluemoonProperties: number[] }) =>
    axios.patch(`${API_URL}${ROUTES.unhideBluemoonProperties}`, data),
  unbindBluemoonProperties: (propertyId: number) =>
    axios.patch(
      `${API_URL}${transformRoute(ROUTES.unbindBluemoonProperties, {
        id: propertyId,
      })}`
    ),
  bindBluemoonProperties: ({
    bluemoonPropertyId,
    veroPropertyIds,
  }: BindPropertiesRequest) => {
    return axios.post(`${API_URL}${ROUTES.bluemoonBindProperties}`, {
      propertyId: bluemoonPropertyId,
      veroProperties: veroPropertyIds,
    });
  },
  getBluemoonDashboardData: () =>
    axios
      .get<BluemoonDashboardData>(
        `${API_URL}${ROUTES.getBluemoonDashboardData}`
      )
      .then(({ data }) => data),
};

export default api;
