const getAddressComponent = (addressComponents, type, fallbackType = null) => {
  let result = addressComponents.find((component) =>
    component.types.includes(type)
  );
  if (!result && fallbackType) {
    result = addressComponents.find((component) =>
      component.types.includes(fallbackType)
    );
  }
  return result;
};

const buildAddress = (addressComponents) => {
  const streetNumber =
    getAddressComponent(addressComponents, "street_number")?.long_name || "";
  const route =
    getAddressComponent(addressComponents, "route")?.long_name || "";
  return `${streetNumber} ${route}`;
};

export const extractAddressDetails = (place) => {
  const address = buildAddress(place.address_components);
  const cityComponent = getAddressComponent(
    place.address_components,
    "locality",
    "sublocality_level_1"
  );
  const city = cityComponent ? cityComponent.long_name : "";
  const state =
    getAddressComponent(place.address_components, "administrative_area_level_1")
      ?.short_name || "";
  const zipcode =
    getAddressComponent(place.address_components, "postal_code")?.long_name ||
    "";
  const country =
    getAddressComponent(place.address_components, "country")?.short_name || "";
  return { address, city, state, zipcode, country };
};
