import React from "react";

import PropTypes from "prop-types";

import { Modal } from "shared/components/Modal";

import { YardiRemovePropertyConfirmationContent, Text } from "./styled";

const YardiRemovePropertyConfirmationModal = ({
  title,
  content,
  propertyName,
  veroPropertyName,
  onSubmit,
  isRemoving,
}) => {
  return (
    <Modal
      showSubmitButton
      showCancelLink
      closeOnSubmit
      submit={onSubmit}
      showTitle
      title={title}
      submitButtonLabel="REMOVE"
      submitting={isRemoving}
    >
      <Modal.Body noPaddingTop noPaddingBottom>
        <YardiRemovePropertyConfirmationContent>
          <p className="title">
            <Text>Property:&nbsp;</Text>
            <Text fontWeight={400}>{propertyName}</Text>
          </p>
          {veroPropertyName && (
            <p className="title">
              <Text>VERO Property:&nbsp;</Text>
              <Text fontWeight={400}>{veroPropertyName}</Text>
            </p>
          )}
          <p className="content">{content}</p>
        </YardiRemovePropertyConfirmationContent>
      </Modal.Body>
    </Modal>
  );
};

YardiRemovePropertyConfirmationModal.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  propertyName: PropTypes.string.isRequired,
  veroPropertyName: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  isRemoving: PropTypes.bool,
};

YardiRemovePropertyConfirmationModal.defaultProps = {
  veroPropertyName: null,
  isRemoving: false,
};

export default YardiRemovePropertyConfirmationModal;
