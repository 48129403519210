const SECTION_TYPES = Object.freeze({
  AVAILABLE_UNITS: "available-units",
  WAIT_UNITS: "wait-units",
  OTHER_UNITS: "other-units",
  AGENTS: "agents",
  MARKETING_SOURCES: "marketing-sources",
});

const SECTIONS = [
  {
    name: "Available Units",
    anchor: SECTION_TYPES.AVAILABLE_UNITS,
  },
  {
    name: "Wait Units",
    anchor: SECTION_TYPES.WAIT_UNITS,
  },
  {
    name: "Other Units",
    anchor: SECTION_TYPES.OTHER_UNITS,
  },
  {
    name: "Agents",
    anchor: SECTION_TYPES.AGENTS,
  },
  {
    name: "Marketing Sources",
    anchor: SECTION_TYPES.MARKETING_SOURCES,
  },
];

export { SECTION_TYPES, SECTIONS };
