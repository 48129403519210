import React, { useEffect } from "react";

import { useHistory } from "react-router-dom";

import {
  YardiConnectionModal,
  YardiCredentialEditWarningModal,
} from "manager/components/Modal";
import ROUTES from "manager/config/routes";
import {
  useGetYardiCredentials,
  useDeleteYardiCredentials,
  useCreateYardiCredentials,
  useGetYardiPlatformTypes,
  usePollYardiCredentials,
  useUpdateYardiCredentials,
  useGetYardiVersion,
  useGetIntegrationStatuses,
} from "manager/hooks/api";
import { IntegrationProvider } from "shared/config/constants";
import { useActiveCompany } from "shared/hooks";
import useModal from "shared/hooks/useModal";
import { BackLinkProps } from "shared/interfaces/misc";
import { transformRoute } from "shared/utils/routing";

import {
  getSelectedIntegrationProvider,
  useGetIntegrationProvider,
} from "../../config";
import { PollIntervalMilli, StatusesToPoll } from "../../IntegrationStatus";

import IntegrationLogin from "./IntegrationLogin";

interface IntegrationLoginContainerProps {
  backLink: BackLinkProps;
  editMode: boolean;
}

const IntegrationLoginContainer = ({
  backLink,
  editMode,
}: IntegrationLoginContainerProps) => {
  const history = useHistory();
  const { provider } = useGetIntegrationProvider();

  const { openModalDialog, closeActiveModalDialog } = useModal();
  const { activeCompany: company } = useActiveCompany();
  const { integrationStatuses } = useGetIntegrationStatuses();
  const selectedIntegrationStatus = getSelectedIntegrationProvider(
    integrationStatuses,
    provider
  );

  const { yardiPlatformTypes } = useGetYardiPlatformTypes();
  const { yardiVersion } = useGetYardiVersion(
    provider,
    editMode && provider === IntegrationProvider.yardi
  );
  let createOrUpdateYardiCredentials;
  let isSubmitLoading;
  let savedYardiCredentials;
  if (editMode) {
    const savedYardiCredentialsData = useGetYardiCredentials(provider);
    savedYardiCredentials = savedYardiCredentialsData.yardiCredentials;
    const updateYardiCredentialsData = useUpdateYardiCredentials({
      provider,
      companyId: company?.id,
    });
    createOrUpdateYardiCredentials =
      updateYardiCredentialsData.updateYardiCredentials;
    isSubmitLoading = updateYardiCredentialsData.isLoading;
  } else {
    const createYardiCredentialsData = useCreateYardiCredentials({
      provider,
      companyId: company?.id,
    });
    createOrUpdateYardiCredentials =
      createYardiCredentialsData.createYardiCredentials;
    isSubmitLoading = createYardiCredentialsData.isLoading;
  }

  const { deleteYardiCredentials } = useDeleteYardiCredentials(provider);

  const {
    yardiCredentials,
    yardiCredentialsError,
    isPollingActive,
    isPollYardiCredentialsError,
    startPolling,
    stopPolling,
  } = usePollYardiCredentials(provider, PollIntervalMilli);

  const openYardiConnectionModal = (data: {
    isLoading: boolean;
    cancelYardiConnectionOperation: () => void;
  }) => openModalDialog(YardiConnectionModal, { provider, ...data });

  const openYardiEditConfirmationModal = (data) =>
    openModalDialog(YardiCredentialEditWarningModal, data);

  const cancelYardiConnectionOperation = () => {
    stopPolling();
    const onSettled = () => closeActiveModalDialog();
    deleteYardiCredentials(null, { onSettled });
  };

  const submit = (values, setSubmitting) => {
    const onSuccess = () => {
      openYardiConnectionModal({
        isLoading: true,
        cancelYardiConnectionOperation,
      });
      startPolling();
    };
    const save = () => {
      createOrUpdateYardiCredentials(values, {
        onSuccess,
        onSettled: () => setSubmitting(false),
      });
    };
    if (editMode) {
      const onCancel = () => {
        closeActiveModalDialog();
        setSubmitting(false);
      };
      const onSubmit = () => {
        save();
      };
      openYardiEditConfirmationModal({ onCancel, onSubmit, provider });
    } else {
      save();
    }
  };

  const cancel = () => {
    history.push(
      transformRoute(ROUTES.integrationDashboard, {
        provider,
      })
    );
  };

  useEffect(() => {
    // NOTE: on the first login, we need to reload the company and wait for the response (isYardiConnected)
    // to be able to redirect to the Yardi Dashboard
    if (selectedIntegrationStatus?.isConnected) {
      const pullStatus = (yardiCredentials?.pullStatus || "").toLowerCase();
      if (isPollingActive && !StatusesToPoll.has(pullStatus)) {
        stopPolling();
        if (!isPollYardiCredentialsError) {
          openYardiConnectionModal({
            isLoading: false,
            cancelYardiConnectionOperation,
          });
        } else {
          // NOTE: if there is some error, means that the polling failed and we should just close the modal
          closeActiveModalDialog();
        }
      }
    }
  }, [
    yardiCredentials,
    yardiCredentialsError,
    selectedIntegrationStatus?.isConnected,
  ]);

  useEffect(() => closeActiveModalDialog, []);

  const updatedProps = {
    provider,
    backLink,
    disabled: selectedIntegrationStatus?.isSuspended,
    editMode,
    savedYardiCredentials,
    yardiPlatformTypes,
    isSubmitLoading,
    yardiVersion,
    submit,
    cancel,
  };
  return <IntegrationLogin {...updatedProps} />;
};

export default IntegrationLoginContainer;
