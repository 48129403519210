import React from 'react'
import _ from 'lodash'
import copyToClipboard from 'copy-to-clipboard'
import { InfoIcon, MoveIcon, DeleteIcon, LinkOneIcon, UnblockIcon } from 'shared/components/vega/icons'
import Tooltip from 'shared/components/Tooltip'
import { Popover } from 'shared/components/Popover'
import { RemoveApplicantModalProvider, MoveApplicantModalProvider, EditIncomeModalProvider, RemoveApplicantModalV2, MoveApplicantModal, EditIncomeModal } from 'manager/components/app-summary/modals'
import { useAppRow } from 'manager/components/app-summary/use-app-row'
import { PAST_PAID_STATUS } from 'shared/interfaces/types'

const ApplicantHeaderBase = ({ applicant, legacyApp, refetchDeal, payments, card = false, minimal = false }) => {
  const legacyAppId = applicant?.legacyId || legacyApp?.appId

  const nameLinkTag = <a className='vega title small link' href={`/applications/${legacyAppId}`}>{applicant?.firstName} {applicant?.lastName}</a>
  const emailLinkTag = <a className='vega sub-title small block email' href={`mailto:${applicant?.email}`}>{applicant?.email}</a>

  if (card || minimal) {
    return (
      <div>
        <div className='vega flex start'>
          {nameLinkTag}
          <span className='vega badge small'>{_.startCase(applicant?.party)}</span>
        </div>

        {emailLinkTag}
      </div>
    )
  }

  if (!legacyApp?.info) {
    return (
      <header className='vega flex'>
        <div className='vega title large'>
          {nameLinkTag}
          {emailLinkTag}
        </div>

        <span className='vega badge'>{_.startCase(applicant?.party)}</span>
      </header>
    )
  }

  const { infoProps, onCopyAppLink, unblock, modalProps } = useAppRow({
    ...legacyApp,
    refetchDeal
  })

  const disableMoveAndDelete = _.get(applicant, 'isPrimaryApplication') &&
    _.get(legacyApp, 'location').includes('yardi') &&
    !_.chain(payments).filter(payment => PAST_PAID_STATUS.includes(_.get(payment, 'status'))).isEmpty().value()

  const pastPaidMessage = disableMoveAndDelete ? 'Action Restricted: The primary applicant cannot be changed or deleted after payment due to integration limitations with your PMS.' : undefined

  const debugInfo = _.join([applicant?.application, applicant?.id, applicant?.person], '\n')

  return (
    <header className='vega flex'>
      <RemoveApplicantModalV2 {...modalProps} />
      <MoveApplicantModal {...modalProps} />
      <EditIncomeModal income={legacyApp?.income.income} statedIncome={legacyApp?.income.stated} applicantName={legacyApp?.info.name} applicantId={legacyAppId} />

      <div className='vega flex'>
        <div className='vega title large'>
          {nameLinkTag}
          {emailLinkTag}
        </div>

        <div>{(_.chain(legacyApp) as any).get('info.badges', []).concat(applicant?.party).map(_.startCase).map((badge) => <span className='vega badge'>{badge}</span>).value()}</div>
      </div>

      <div className='vega actions'>
        {legacyApp?.info?.isBlocked && legacyApp?.info?.permission?.unblock && <Tooltip title='Unblock applicant' theme='light'>
          <UnblockIcon className='vega icon button' onClick={() => legacyApp?.info?.permission?.unblock && unblock?.action()} />
        </Tooltip>}

        {legacyApp?.info?.inviteLink && <Tooltip title='Copy invite link' theme='light'>
          <LinkOneIcon role='button' aria-label='Copy invite link' className='vega icon button' onClick={onCopyAppLink} />
        </Tooltip>}

        <Popover
          placement='topRight'
          content={
            <div className='vega content flex column no-gap'>
              <pre className='vega'>{debugInfo}</pre>
              <span className='vega button actions end' onClick={() => copyToClipboard(debugInfo)}>copy</span>
            </div>
          }
        >
          <InfoIcon className='vega icon button' />
        </Popover>

        <Tooltip title={pastPaidMessage || legacyApp?.info?.permission?.noMoveReason || 'Move to another application'} theme='light'>
          <MoveIcon className='vega icon button' onClick={() => !disableMoveAndDelete && !legacyApp?.info?.permission.noMove && infoProps?.onMove()} />
        </Tooltip>

        <Tooltip title={pastPaidMessage || legacyApp?.info?.permission?.noRemoveReason || 'Remove from application'} theme='light'>
          <DeleteIcon className='vega icon button' onClick={() => !disableMoveAndDelete && !legacyApp?.info?.permission.noRemove && infoProps?.onDelete()} />
        </Tooltip>
      </div>
    </header>
  )
}

const ApplicantHeader = ({ card = false, minimal = false, ...props }: any) => {
  if (card || minimal) {
    return <ApplicantHeaderBase card={card} minimal={minimal} {...props} />
  }

  return (
    <RemoveApplicantModalProvider>
      <MoveApplicantModalProvider>
        <EditIncomeModalProvider>
          <ApplicantHeaderBase {...props} />
        </EditIncomeModalProvider>
      </MoveApplicantModalProvider>
    </RemoveApplicantModalProvider>
  )
}

export default ApplicantHeader
