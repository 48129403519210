import styled from "@emotion/styled";

import { COLORS } from "shared/config/constants";

interface EmployeeStatusProps {
  isActive?: boolean;
}

export const EmployeeStatus = styled.span<EmployeeStatusProps>`
  color: ${(props) =>
    props.isActive ? COLORS.violetTheme.green : COLORS.primary};
`;
