import styled from "@emotion/styled";

import { COLORS } from "shared/config/constants";

const LeadSubtitle = styled.div`
  & > span {
    display: inline-block;
  }

  .lead-data {
    margin-left: 10px;

    &__dot {
      width: 5px;
      height: 5px;
      background: ${COLORS.violetTheme.lightBlueViolet};
      border-radius: 50%;
      display: inline-block;
      margin-bottom: 2.5px;
      margin-right: 6px;
    }
  }
`;

export { LeadSubtitle };
