import { buildClassName } from 'shared/utils/vega'
import { Timestamp } from 'shared/components/vega/Timestamp'

const ServiceHeader = ({ title = undefined, status = undefined, timestamp = undefined, children }) => {
  return (
    <header className={buildClassName(['vega container flex service status-bar', status])}>
      {title && <span className='vega title'>{title}</span>}
      {children}
      {timestamp && <Timestamp timestamp={timestamp} />}
    </header>
  )
}

export default ServiceHeader
