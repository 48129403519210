import React, { useMemo } from "react";

import {
  useCreatePortfolio,
  useGetPortfoliosAssociated,
} from "manager/hooks/api";
import { useDeviceType } from "shared/hooks";
import useModal from "shared/hooks/useModal";

import CreatePortfolioModal from "./CreatePortfolioModal";
import { CreatePortoflioParams } from "./interfaces";

const CreatePortfolioModalContainer = () => {
  const { isMobile } = useDeviceType();
  const { closeActiveModalDialog } = useModal();

  const { portfoliosAssociated, isPortfoliosAssociated } =
    useGetPortfoliosAssociated();

  const { createPortfolio } = useCreatePortfolio();

  const { portfolios, defaultPortfolio } = useMemo(() => {
    let portfolios = [];
    let defaultPortfolio: string;
    if (portfoliosAssociated) {
      portfolios = portfoliosAssociated.map((portfolio) => ({
        key: portfolio.id,
        label: portfolio.name,
        isDefault: portfolio.isDefault,
      }));
      defaultPortfolio =
        portfoliosAssociated
          .find((portfolio) => portfolio.isDefault)
          ?.id.toString() ?? null;
    }
    return {
      portfolios,
      defaultPortfolio,
    };
  }, [portfoliosAssociated]);

  const submit = (values: CreatePortoflioParams) =>
    createPortfolio(values, {
      onSuccess: closeActiveModalDialog,
    });

  return (
    <CreatePortfolioModal
      defaultPortfolio={defaultPortfolio}
      isMobile={isMobile}
      loading={isPortfoliosAssociated}
      submit={submit}
      portfolios={portfolios}
    />
  );
};

export default CreatePortfolioModalContainer;
