import styled from "@emotion/styled";

import { COLORS } from "shared/config/constants";

const Title = styled("h6")`
  font-size: 12px;
  font-weight: 300;
  color: ${COLORS.secondary};
`;

const List = styled("ul")`
  font-size: 12px;
  list-style-type: none;
  margin-bottom: 0;
  padding-left: 0;
`;

const Item = styled("li")``;

export { List, Title, Item };
