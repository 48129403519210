import { DEFAULT_PAGINATION } from "shared/config/constants";

/** Prepares Units table search params to be sent with the api call. */
export const prepareUnitsSearchParams = (searchParams) => {
  const { status, propertyId, ...otherSearchParams } = searchParams;
  return {
    ...otherSearchParams,
    status: (status || []).join(","),
    propertyId: (propertyId || []).join(","),
  };
};

export const ORDER_BY_NAMES = Object.freeze({
  name: "name",
  property: "property__name",
  dateAvailable: "unit_available_on",
  rent: "rent",
  bedrooms: "bedrooms",
  properties: "num_properties",
});

export const ORDER_BY = Object.freeze({
  name: {
    ascend: ORDER_BY_NAMES.name,
    descend: `-${ORDER_BY_NAMES.name}`,
  },
  property: {
    ascend: ORDER_BY_NAMES.property,
    descend: `-${ORDER_BY_NAMES.property}`,
  },
  dateAvailable: {
    ascend: ORDER_BY_NAMES.dateAvailable,
    descend: `-${ORDER_BY_NAMES.dateAvailable}`,
  },
  rent: {
    ascend: ORDER_BY_NAMES.rent,
    descend: `-${ORDER_BY_NAMES.rent}`,
  },
  bedrooms: {
    ascend: ORDER_BY_NAMES.bedrooms,
    descend: `-${ORDER_BY_NAMES.bedrooms}`,
  },
  properties: {
    ascend: ORDER_BY_NAMES.properties,
    descend: `-${ORDER_BY_NAMES.properties}`,
  },
});

export const handleSearchParams = (
  key,
  value,
  blankFilters,
  setInputValue,
  setPagination,
  setSearchParams,
  searchParams
) => {
  let newFilters = blankFilters;
  if (!key) {
    setPagination(DEFAULT_PAGINATION);
  } else {
    if (key === "name" || key === "nameOrAddress") {
      setInputValue(!!value);
    }

    let k = key;
    let v = value;
    if (key === "date") {
      // NOTE: Object.entries returns array of [key, value],
      // in this case we are expecting [["date", ${value}]]
      [[k, v]] = Object.entries(value);
    }

    newFilters = {
      ...searchParams,
      [k]: v,
      ...DEFAULT_PAGINATION,
    };

    setSearchParams(newFilters);
    setPagination(DEFAULT_PAGINATION);
    return;
  }

  setSearchParams(newFilters);
};

export const blankPropertiesFilter = {
  nameOrEmail: "",
  portfolioId: [],
  ...DEFAULT_PAGINATION,
};

export const blankUnitsFilter = {
  name: "",
  status: [],
  propertyId: [],
  /* Note: Keep showOnHold undefined to get all units */
  showOnHold: false,
  ...DEFAULT_PAGINATION,
};

export const blankPortfolioFilter = {
  name: "",
  ...DEFAULT_PAGINATION,
};

export const transformSearchParams = (searchParams) => {
  const { portfolioId, ...otherSearchParams } = searchParams;
  return {
    ...otherSearchParams,
    portfolioId: (portfolioId || []).join(","),
  };
};
