import React from "react";

import { Tag } from "antd";

import ROUTES from "manager/config/routes";
import ApplicantsCheckList from "shared/components/ApplicantsCheckList";
import Icon from "shared/components/Icon";

import { AvailableDate, ProgressBar } from "./styled";

const LeaseSignedIcon = () => <Icon.LeaseSignedIcon title="Lease Signed" />;
const rows = [];

for (let i = 1; i < 30; i += 1) {
  rows.push({
    key: i,
    values: [
      "Manhatten Living Complex",
      <>
        <strong>1</strong>
        <br />
        <AvailableDate>
          Available:
          <br />
          Jan. 30, 2020
        </AvailableDate>
      </>,
      <ApplicantsCheckList
        items={[
          {
            id: 1,
            firstName: "Aaron",
            lastName: "Smith",
            isSubmitted: true,
            Icon: LeaseSignedIcon,
          },
          {
            id: 2,
            firstName: "James",
            lastName: "Smith",
            isSubmitted: true,
            Icon: LeaseSignedIcon,
          },
          {
            id: 3,
            firstName: "Sally",
            lastName: " Smith",
            leaseSigned: true,
          },
          {
            id: 4,
            firstName: "Bob",
            lastName: " Smith",
          },
        ]}
        defaultItemsDisplayed={3}
      />,
      "Sep. 28, 2019",
      "$ 12,000",
      <>
        <Tag>Next: Receive applications</Tag>
        <ProgressBar progress={20} />
        <span style={{ fontWeight: 300 }}>1 of 3 applications submitted</span>
      </>,
    ],
    viewed: i === 3,
  });
}

const getData = (history) => ({
  columns: [
    {
      title: "Property",
      dataIndex: "property",
      key: "property",
      sorter: (a, b) => a.property - b.property,
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
    },
    {
      title: "Applicants",
      dataIndex: "applicants",
      key: "applicants",
    },
    {
      title: "Application Date",
      dataIndex: "applicationDate",
      key: "applicationDate",
      sorter: (a, b) => a.applicationDate - b.applicationDate,
    },
    {
      title: "Rent",
      dataIndex: "rent",
      key: "rent",
      sorter: (a, b) => a.rent - b.rent,
    },
    {
      title: "Application Status",
      dataIndex: "dealStatus",
      key: "dealStatus",
    },
  ],
  rows,
  columnBreakpoints: {
    xs: {
      column1: "property",
      column2: "unit",
    },
    sm: ["property", "unit", "applicants"],
    md: ["property", "unit", "applicants"],
    lg: ["property", "unit", "applicants", "availableOn", "applicationDate"],
  },
  onRowClick: (key) => history.push(ROUTES.deal.replace(":id", key)),
  placeholder: {
    Icon: Icon.NoDealsIcon,
    label: "Applications",
  },
});

export default getData;
