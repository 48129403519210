import { Key, useCallback, useEffect, useState } from "react";

import { queryCache, useMutation, useQuery } from "react-query";

import { ApproveDealRequest } from "manager/interfaces/api";
import {
  getApplicationIds,
  getDeal,
  getDeals,
  getDealsCounts,
  getDealsToMove,
  recalculateLumen,
  updateDealArchiveStatus,
  getLedgerRecords,
} from "manager/lib/dealsApi";
import { NOTIFICATIONS } from "shared/config/constants";
import useQueryErrorHandler from "shared/hooks/useQueryErrorHandler";
import sharedApi from "shared/lib/api";
import {
  approveDeal,
  assignDealToUnit as assignDealToUnitApi,
  conditionallyProceed,
  editDealRent,
  getDealApplicants,
  getPrimaryApplicant,
  getUnitListing,
  updateDealRevenueDetails,
  assignDealRevenueManagement,
} from "shared/lib/api/dealApi";
import { notifyError, notifySuccess, openNotification } from "shared/utils/ui";

import { useCreateUnitListing } from "./unitsQueries";

const GET_DEALS_KEY = "getDeals";
const GET_DEALS_COUNT_KEY = "getDealsCount";
const GET_DEALS_LIST_TO_MOVE_APPLICATION_KEY = "getDealsListToMoveApplication";
export const GET_DEAL_KEY = "getDeal";
const GET_UNIT_LISTING_DEAL = "getUnitListingDeal";
const GET_PRIMARY_APPLICANT_DEAL = "getPrimaryApplicantDeal";
const GET_DEAL_APPLICANT = "getDealApplicants";
const GET_APPLICANT_IDS = "getApplicantsIds";
const GET_LEDGER_RECORDS = "getLedgerRecords";

export const DEAL_MANAGER_QUERY_KEYS = {
  getUnitListingDeal: (id: string | number) => [
    GET_UNIT_LISTING_DEAL,
    Number(id),
  ],
  getPrimaryApplicantDeal: (id: string | number) => [
    GET_PRIMARY_APPLICANT_DEAL,
    Number(id),
  ],
  getDealApplicants: (id: string | number) => [GET_DEAL_APPLICANT, Number(id)],
  getApplicantsIds: (id: string | number) => [GET_APPLICANT_IDS, Number(id)],
};

export const useGetDeal = (id, config) => {
  const response = useQuery([GET_DEAL_KEY, id], () => getDeal(id), {
    onError: () =>
      openNotification("Failed to load the application", NOTIFICATIONS.error),
    ...config,
  });

  return response;
};

export function useGetDeals(filters, config = {}) {
  const response = useQuery([GET_DEALS_KEY, filters], () => getDeals(filters), {
    onError: () => {
      openNotification("Failed to load applications", NOTIFICATIONS.error);
    },
    ...config,
  });

  return response;
}

export function useGetDealsCounts(filters, config = {}) {
  const response = useQuery(
    [GET_DEALS_COUNT_KEY, filters],
    () => getDealsCounts(filters),
    {
      onError: () => {
        openNotification(
          "Failed to load applications summaries",
          NOTIFICATIONS.error
        );
      },
      ...config,
    }
  );

  return response;
}

export const useAssignDealToUnit = () => {
  const { createUnitListing, isLoading: isListingCreated } =
    useCreateUnitListing();

  const [assignDealToUnit, status] = useMutation(assignDealToUnitApi, {
    onSuccess: () => {
      queryCache.invalidateQueries(GET_DEAL_KEY);
    },
    onError: () => {
      openNotification(
        "Failed to assign application to unit",
        NOTIFICATIONS.error
      );
    },
  });

  const assignDealToUnitWithUnitListing = (
    { id, unitId, unitListing, newLeaseStartDate, extraParams },
    { onSuccess, onError }
  ) => {
    if (unitListing) {
      return createUnitListing(unitListing, {
        onSuccess: () => {
          assignDealToUnit(
            { id, unitId, newLeaseStartDate, extraParams },
            { onSuccess, onError }
          );
        },
        onError,
      });
    }

    return assignDealToUnit(
      { id, unitId, newLeaseStartDate, extraParams },
      { onSuccess, onError }
    );
  };

  return {
    assignDealToUnit: assignDealToUnitWithUnitListing,
    isLoading: isListingCreated || status.isLoading,
  };
};

export const useApproveDeal = () => {
  const [mutate, status] = useMutation(
    ({ id, attachments, extraParams }: ApproveDealRequest) =>
      approveDeal({ id, attachments, extraParams })
  );

  return {
    approveDeal: mutate,
    ...status,
  };
};

export const useConditionallyProceedDeal = () => {
  const [mutate, status] = useMutation(conditionallyProceed, {
    onSuccess: () => {
      queryCache.invalidateQueries(GET_DEAL_KEY);
      notifySuccess(
        "Successfully proceeded with the application",
        NOTIFICATIONS.info
      );
    },
    onError: () =>
      notifyError("Failed to conditionally proceed with the application"),
  });

  return {
    conditionallyProceed: mutate,
    ...status,
  };
};

export function useGetDealsToMove({
  dealId,
  enabled = true,
}: {
  dealId: string | number;
  enabled?: boolean;
}) {
  const response = useQuery(
    [GET_DEALS_LIST_TO_MOVE_APPLICATION_KEY, dealId],
    () => getDealsToMove(dealId),
    { enabled }
  );

  return response;
}

export const useEditDealRent = () => {
  const [mutate, { isLoading }] = useMutation(editDealRent, {
    onSuccess: () => {
      queryCache.invalidateQueries(GET_DEAL_KEY);
      openNotification(
        `The rent has been successfully edited`,
        NOTIFICATIONS.info
      );
    },
    onError: () => {
      openNotification("Failed to edit the deal rent.", NOTIFICATIONS.error);
    },
  });

  return {
    editDealRent: mutate,
    isEditDealRentLoading: isLoading,
  };
};

export const useUpdateDealRevenueDetails = ({ dealId }: { dealId: number }) => {
  const [mutate, { isLoading }] = useMutation(updateDealRevenueDetails, {
    onSuccess: () => {
      queryCache.invalidateQueries(GET_DEAL_KEY);
      queryCache.invalidateQueries(
        DEAL_MANAGER_QUERY_KEYS.getPrimaryApplicantDeal(dealId)
      );
      queryCache.invalidateQueries(
        DEAL_MANAGER_QUERY_KEYS.getUnitListingDeal(dealId)
      );

      openNotification(
        `The revenue management data been successfully edited`,
        NOTIFICATIONS.info
      );
    },
    onError: (error: { errors: string[] }) => {
      if (error?.errors?.length > 0) {
        openNotification(
          error.errors[0] ?? "Failed to edit the revenue management data.",
          NOTIFICATIONS.error
        );
      }
    },
  });

  return {
    updateDealRevenueDetails: mutate,
    isDealRevenueDetailsLoading: isLoading,
  };
};

export const useAssignDealRevenueManagement = () => {
  const [mutate] = useMutation(assignDealRevenueManagement, {
    onSuccess: () => {
      openNotification(
        `The revenue management data been successfully edited`,
        NOTIFICATIONS.info
      );
    },
    onError: (error: { errors: string[] }) => {
      if (error?.errors?.length > 0) {
        openNotification(
          error.errors[0] ?? "Failed to edit the revenue management data.",
          NOTIFICATIONS.error
        );
      }
    },
  });

  return {
    assignDealRevenueManagement: mutate,
  };
};

export const useUpdateDealArchiveStatus = () => {
  const [mutate, { isLoading }] = useMutation(updateDealArchiveStatus, {
    onSuccess: (_data, { isArchived }) => {
      openNotification(
        `Application has been successfully ${
          isArchived ? "archived" : "unarchived"
        }`,
        NOTIFICATIONS.info
      );
    },
    onError: (_data, { isArchived }) => {
      openNotification(
        `Failed to ${isArchived ? "archive" : "unarchive"} application`,
        NOTIFICATIONS.error
      );
    },
  });

  return {
    updateDealArchiveStatus: mutate,
    isUpdateDealArchiveStatusLoading: isLoading,
  };
};

export const useRecalculateLumen = ({ dealId, onSuccess }) => {
  const [mutate, status] = useMutation(() => recalculateLumen({ id: dealId }), {
    onSuccess,
    onError: () =>
      openNotification("Failed to recalculate Lumen.", NOTIFICATIONS.error),
  });

  return {
    recalculateLumen: mutate,
    isLoading: status.isLoading,
  };
};

export const useUnitListingDeal = (id: Key, config = {}) => {
  const { data, isLoading, refetch } = useQuery(
    DEAL_MANAGER_QUERY_KEYS.getUnitListingDeal(id),
    () => getUnitListing(id),
    {
      enabled: Boolean(id),
      ...config,
    }
  );
  return {
    unitListingDeal: data,
    isUnitListingDealLoading: isLoading,
    unitListingDealRefetch: refetch,
  };
};

export const usePrimaryApplicantDeal = (id: Key, config = {}) => {
  const { data, isLoading, refetch } = useQuery(
    DEAL_MANAGER_QUERY_KEYS.getPrimaryApplicantDeal(id),
    () => getPrimaryApplicant(id),
    {
      enabled: Boolean(id),
      ...config,
    }
  );
  return {
    primaryApplicantDeal: data,
    isPrimaryApplicantDealLoading: isLoading,
    primaryApplicantDealRefetch: refetch,
  };
};

export const useDealApplicants = (id: Key, config = {}) => {
  const { data, isLoading, refetch, isFetching } = useQuery(
    DEAL_MANAGER_QUERY_KEYS.getDealApplicants(id),
    () => getDealApplicants(id),
    {
      enabled: Boolean(id),
      ...config,
    }
  );
  return {
    dealApplicants: data,
    isDealApplicantLoading: isLoading,
    dealApplicantsRefetch: refetch,
    isDealApplicantsRefetching: isFetching,
  };
};

export const useApplicantsIds = (id: Key, enabled: boolean) => {
  const { data, isLoading, refetch, isFetching } = useQuery(
    DEAL_MANAGER_QUERY_KEYS.getApplicantsIds(id),
    () => getApplicationIds(id),
    {
      enabled,
    }
  );
  return {
    applicantsIds: data,
    isApplicantIdsLoading: isLoading,
    applicantsIdsRefetch: refetch,
    isApplicantsIdsRefetching: isFetching,
  };
};

/**
 * @description This hook is used to get all applications by ids
 */
export const useApplicationsByIds = (ids: number[], enabled) => {
  const [applications, setApplications] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const getAllFullApplicationsByIds = useCallback(
    async (applicationIds?: number[]) => {
      if (error) return;
      setLoading(true);

      const applicationPromises = (applicationIds ?? ids)?.map(
        (applicationId) => sharedApi.getFullApplication({ applicationId })
      );

      try {
        const [...applicationResponses] = await Promise.all([
          ...applicationPromises,
        ]);
        setApplications(applicationResponses);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [ids]
  );

  useEffect(() => {
    if (ids && enabled && !applications && !loading && !error) {
      getAllFullApplicationsByIds();
    }
  }, [ids, enabled, loading, applications, error]);

  return {
    dealApplicants: applications,
    dealApplicantsRefetch: getAllFullApplicationsByIds,
    isDealApplicantLoading: loading,
  };
};

export const useGetLedgerRecords = (params) => {
  const queryErrorHandler = useQueryErrorHandler();

  const {
    data: records,
    isLoading,
    isFetching,
  } = useQuery(
    [GET_LEDGER_RECORDS, params],
    () => getLedgerRecords(params).then(({ data }) => data),
    {
      enabled: params,
      onError: (error) => queryErrorHandler(error, "Failed to load ledger"),
    }
  );

  return { records, isLoading, isFetching };
};
