import React from "react";

import { Switch, Route, Redirect } from "react-router-dom";

import {
  LEAD_APPLICATION_SUBMIT_ROUTES,
  ROUTES as LEAD_ROUTES,
} from "leads/config/routes";
import LeadsHomePage from "leads/pages/LeadsHomePage";
import LeadAcceptTerms from "leads/pages/LeadAcceptTerms";
import LeadsLoginSelfSignedDeepLinkBouncePage from "leads/pages/LeadsLoginSelfSignedDeepLinkBouncePage";
import Login from "leads/pages/Login";
import SetPassword from "leads/pages/SetPassword";
import ROUTES from "renter/config/routes";
import PasswordRecoverySetPassword from "renter/pages/PasswordRecoverySetPassword";
import { AfterSubmitCallbackRegisterProvider } from "shared/components/AfterSubmitCallbackRegisterProvider";
import App from "shared/components/App";
import { RenderModal } from "shared/components/Modal";
import RequireAuth from "shared/components/RequireAuth";
import { APPS } from "shared/config/constants";

/* Pages */
import Error404Page from "shared/pages/Error404Page";
import PasswordRecoveryEmailSentPage from "shared/pages/PasswordRecoveryEmailSentPage";
import PasswordRecoveryPage from "shared/pages/PasswordRecoveryPage";

import { redirectToApplicantFlow } from "../../shared/utils/redirect";

const id: string = APPS.leads;

const LeadsApp = () => (
  <AfterSubmitCallbackRegisterProvider>
    <App
      id={id}
      render={() => {
        return (
          <>
            <Switch>
              <Redirect
                from={ROUTES.applicationInvitation}
                to={LEAD_ROUTES.leadAcceptTerms}
              />

              <Route
                exact
                path={LEAD_ROUTES.leadAcceptTerms}
                render={() => (
                  <RequireAuth render={() => <LeadAcceptTerms />} />
                )}
              />

              {/* Auth views */}
              <Route exact path={ROUTES.login} render={() => <Login />} />

              <Route
                exact
                path={ROUTES.loginSelfSignedDeepLink}
                render={() => <LeadsLoginSelfSignedDeepLinkBouncePage />}
              />

              <Route
                exact
                path={ROUTES.setPassword}
                render={() => <SetPassword />}
              />

              <Route
                exact
                path={ROUTES.passwordRecovery}
                render={() => <PasswordRecoveryPage />}
              />

              <Route
                exact
                path={ROUTES.passwordRecoveryEmailSent}
                render={({ location }) => (
                  // @ts-ignore
                  <PasswordRecoveryEmailSentPage email={location.state.email} />
                )}
              />

              <Route
                exact
                path={ROUTES.passwordRecoverySetPassword}
                render={() => <PasswordRecoverySetPassword />}
              />

              {/* Other views */}
              <Route
                exact
                path="/"
                render={() => (
                  <RequireAuth render={() => <LeadsHomePage />} />
                )}
              />

              <Route
                exact
                path={ROUTES.notFound}
                render={() => <Error404Page />}
              />

              <Route
                path={[
                  LEAD_ROUTES.leadStart,
                  LEAD_ROUTES.leadFinish,
                  ...LEAD_APPLICATION_SUBMIT_ROUTES,
                ]}
                render={redirectToApplicantFlow}
              />
            </Switch>

            {/* Modals */}
            <RenderModal />
          </>
        );
      }}
    />
  </AfterSubmitCallbackRegisterProvider>
);

export default LeadsApp;
