import React from "react";

import Icon from "shared/components/Icon";
import { ActionLink } from "shared/components/Links";
import { ANCHOR_LINKS } from "shared/config/constants";
import ROUTES from "shared/config/routes";
import { useDeviceType } from "shared/hooks";
import { transformRoute } from "shared/utils/routing";

import CommonVerificationItem from "./CommonVerificationItem";

export interface LynkVerificationProps {
  isLynkEnabled?: boolean;
  hasAutoLinkedEmployment?: boolean;
  applicationId?: string;
  lynkSkippedMessage?: string;
  lynkSuccessMessage?: string;
}

const LynkVerification = ({
  isLynkEnabled = false,
  hasAutoLinkedEmployment = false,
  applicationId = undefined,
  lynkSkippedMessage = "Applicant did not link a company through VEROLynk",
  lynkSuccessMessage = "Income and employer verified by VEROLynk",
}: LynkVerificationProps) => {
  const { hasTouch, isMobile } = useDeviceType();
  let LynkIcon = Icon.LynkSkipped;
  let message = lynkSkippedMessage;
  const renterProfileRoute = `${transformRoute(
    ROUTES.applicationRenterProfile,
    {
      id: applicationId,
    }
  )}`;
  const to =
    hasTouch || !applicationId
      ? undefined
      : {
          pathname: renterProfileRoute,
          hash: ANCHOR_LINKS.RENTER_PROFILE.EMPLOYMENT_HISTORY,
        };

  if (hasAutoLinkedEmployment) {
    LynkIcon = Icon.LynkSuccess;
    message = lynkSuccessMessage;
  }

  return isLynkEnabled ? (
    <CommonVerificationItem message={message}>
      <ActionLink Icon={() => <LynkIcon small={isMobile} />} to={to} />
    </CommonVerificationItem>
  ) : null;
};

export default LynkVerification;
