import React, { useCallback, useRef } from "react";

import { Row, Col } from "antd";
import { Field } from "formik";
import mapValues from "lodash/mapValues";
import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";

import { MarkdownTips } from "manager/components/EmailTemplates/EmailBuilder";
import { BUTTON_SIZES, DropdownButton } from "shared/components/Button";
import {
  Checkbox,
  FormPromptShell,
  Input,
  MarkdownEditor,
  UploadWall,
} from "shared/components/Form";
import Spacer from "shared/components/Spacer";
import Tile from "shared/components/Tile";
import { RegularText, SmallText } from "shared/components/Typography";
import {
  BREAKPOINT_RESOLUTIONS,
  MAX_FILE_UPLOAD_SIZE,
} from "shared/config/constants";
import { ApiError } from "shared/lib/errors";
import { showError } from "shared/utils/forms";
import { deferred } from "shared/utils/misc.util";
import { fileUploadHandler } from "shared/utils/upload";

import {
  BACK_LINK,
  emailValidationSchema,
  getEmailInitialValues,
} from "./config";
import { StyledWell } from "./styled";

const EmailBuilderForm = ({
  saveChanges,
  onSaveChangesSuccess,
  initialConfiguration,
  isFormDisabled,
}) => {
  const formikRef = useRef();
  const navigateToRef = useRef();
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT_RESOLUTIONS.sm - 1 });
  const { push } = useHistory();

  const uploadFile = async (file) => {
    const item = await fileUploadHandler(file);
    formikRef.current.setFieldValue("attachments", [
      ...formikRef.current.values.attachments,
      file,
    ]);
    formikRef.current.setFieldTouched("attachments");
    return item;
  };

  const deleteFile = (id) => {
    formikRef.current.setFieldValue("attachments", [
      ...formikRef.current.values.attachments.filter((file) => file.id !== id),
    ]);
    formikRef.current.setFieldTouched("attachments");
  };

  const onSaveChangesError = (error, isSubmitError) => {
    if (error instanceof ApiError) {
      if (!isSubmitError) {
        formikRef.current.setTouched(mapValues(error.errors, () => true));
      }

      deferred(() => formikRef.current.setErrors(error.errors));
    }
  };

  const onSubmit = (values) =>
    saveChanges(values)
      .then((response) => {
        deferred(() => formikRef.current.resetForm());
        deferred(() => onSaveChangesSuccess(response, BACK_LINK.to));
      })
      .catch((error) => onSaveChangesError(error, true));

  const onExitPageSaveChanges = (values) => {
    if (formikRef.current.isValid) {
      saveChanges(values)
        .then((response) => {
          formikRef.current.resetForm();
          /** NOTE: Provide manual navigation to the triggered route */
          deferred(() =>
            onSaveChangesSuccess(response, navigateToRef.current.pathname)
          );
        })
        .catch((error) => onSaveChangesError(error, false));
    }
  };

  const onSecondaryButtonClick = () => push(BACK_LINK.to);

  const EmailVariablesMenu = useCallback(
    ({ onFormatClick, disabled }) => (
      <div>
        <DropdownButton
          data-testid="variables-menu"
          type="secondary"
          size={BUTTON_SIZES.sm}
          light
          disabled={isFormDisabled}
          items={initialConfiguration.variables.map(({ label, key }) => ({
            key,
            label,
            onClick: onFormatClick({
              open: "",
              close: key,
            }),
            disabled,
          }))}
        >
          Select Variables
        </DropdownButton>
      </div>
    ),
    [initialConfiguration.variables]
  );

  return (
    <FormPromptShell
      initialValues={getEmailInitialValues(initialConfiguration)}
      validationSchema={emailValidationSchema}
      onSubmit={onSubmit}
      formRef={formikRef}
      submitButtonLabel="Save Template"
      getPromptProps={({ isValid }) => ({
        title: "Exit Email Builder",
        subtitle: isValid
          ? "Would you like to save the changes?"
          : "Your changes will be lost as soon as you exit the email builder.",
        submitButtonLabel: isValid ? "Yes" : "OK",
        cancelLinkLabel: isValid ? "No" : null,
        onNextLocationTriggered: (nextLocation) => {
          navigateToRef.current = nextLocation;
        },
        /** NOTE: Disable default navigation after valid submit |
         *  confirm navigation if the form is not valid */
        customOnConfirm: (onConfirm, onCancel) =>
          isValid ? onCancel() : onConfirm(),
        /** NOTE: Navigate without saving changes when form valid */
        customOnCancel: (onConfirm) => onConfirm(),
      })}
      saveChanges={onExitPageSaveChanges}
      shouldDisableSubmitButton={false}
      showSecondaryButton
      onSecondaryButtonClick={onSecondaryButtonClick}
    >
      {({ touched, errors, values }) => (
        <Tile
          header={{
            title: `Template: ${initialConfiguration.typeNameDescription}`,
          }}
        >
          <Tile.Inner>
            <Row gutter={[32, 32]} type="flex">
              <Col md={24} lg={16} xl={16} xxl={18}>
                <StyledWell noBorder>
                  <div className="well-title">
                    <RegularText strong>TEMPLATE DETAILS</RegularText>
                  </div>
                  <div className="well-subtitle">
                    <SmallText>
                      Name your template so it will be recognizable to you and
                      your coworkers. Internal use only.
                    </SmallText>
                  </div>
                  <Field
                    as={Input}
                    type="text"
                    id="name"
                    name="name"
                    label="Template Name"
                    error={showError("name", touched, errors)}
                    disabled={isFormDisabled}
                  />
                </StyledWell>
                <Spacer size={Spacer.SIZES.sm} />
                <StyledWell noBorder>
                  <div className="well-title">
                    <RegularText strong>EMAIL MESSAGE</RegularText>
                  </div>
                  <div className="well-subtitle">
                    <SmallText>
                      Create a short eye-catching subject line that will be
                      easily recognizable.
                    </SmallText>
                  </div>
                  <Field
                    as={MarkdownEditor}
                    id="subject"
                    name="subject"
                    label="Email Subject"
                    CustomActions={[EmailVariablesMenu]}
                    rows={1}
                    error={showError("subject", touched, errors)}
                    panelConfig={{
                      hideDefaultActions: true,
                    }}
                    disabled={isFormDisabled}
                  />
                  <Spacer />
                  <Field
                    as={MarkdownEditor}
                    id="title"
                    name="title"
                    label="Email Title"
                    CustomActions={[EmailVariablesMenu]}
                    rows={1}
                    error={showError("title", touched, errors)}
                    panelConfig={{
                      hideDefaultActions: true,
                    }}
                    disabled={isFormDisabled}
                  />
                  <div className="well-subtitle">
                    <SmallText>
                      Using the variables and formatting guide, create a dynamic
                      email message.
                    </SmallText>
                  </div>
                  <Field
                    as={MarkdownEditor}
                    id="body"
                    name="body"
                    CustomActions={[EmailVariablesMenu]}
                    label="Email Body"
                    error={showError("body", touched, errors)}
                    disabled={isFormDisabled}
                  />
                  {initialConfiguration.hasCta && (
                    <>
                      <div className="well-subtitle">
                        <SmallText>
                          Customize the button label for your company.
                        </SmallText>
                      </div>
                      <div className="cta-wrapper">
                        <Field
                          as={Input}
                          type="text"
                          id="ctaLabel"
                          name="ctaLabel"
                          label="CTA Label"
                          error={showError("cta", touched, errors)}
                          disabled={values.hideCta || isFormDisabled}
                        />
                      </div>
                      <Spacer size={Spacer.SIZES.xs} />
                      <Field
                        as={Checkbox}
                        id="hideCta"
                        name="hideCta"
                        label="Hide CTA button in email"
                        error={showError("hideCta", touched, errors)}
                        disabled={isFormDisabled}
                      />
                    </>
                  )}
                </StyledWell>
                <Spacer size={Spacer.SIZES.sm} />
                <StyledWell noBorder>
                  <div className="well-title">
                    <RegularText strong>EMAIL ATTACHMENTS</RegularText>
                  </div>
                  <div className="well-subtitle">
                    <SmallText>
                      Add any attachments like a welcome letter, notice or
                      brochure.
                    </SmallText>
                  </div>
                  <UploadWall
                    maxCount={5}
                    maxSize={MAX_FILE_UPLOAD_SIZE}
                    existingFiles={values.attachments}
                    uploadFile={uploadFile}
                    deleteFile={deleteFile}
                    disabled={isFormDisabled}
                  />
                  <SmallText>Supported Formats: PNG, JPEG, PDF</SmallText>
                  <div>
                    <SmallText>MAX file size: 5 MB</SmallText>
                  </div>
                </StyledWell>
              </Col>
              {!isMobile && (
                <Col md={24} lg={8} xl={8} xxl={6}>
                  <MarkdownTips hasCta={initialConfiguration.hasCta} />
                </Col>
              )}
            </Row>
          </Tile.Inner>
        </Tile>
      )}
    </FormPromptShell>
  );
};

EmailBuilderForm.propTypes = {
  initialConfiguration: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    typeName: PropTypes.string,
    typeNameDescription: PropTypes.string,
    subject: PropTypes.string,
    body: PropTypes.string,
    ctaLabel: PropTypes.string,
    hasCta: PropTypes.bool,
    hideCta: PropTypes.bool,
    defaultConfigurationId: PropTypes.number,
    variables: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.string,
      })
    ),
    attachments: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        url: PropTypes.url,
      })
    ),
  }).isRequired,
  saveChanges: PropTypes.func.isRequired,
  isFormDisabled: PropTypes.bool.isRequired,
  onSaveChangesSuccess: PropTypes.func.isRequired,
};

export default EmailBuilderForm;
