import {
  PasswordInput,
  TextArea,
  Input,
  MaskedInput,
} from "shared/components/Form";

export const INPUT_MODE = Object.freeze({
  singleLine: "singleline",
  multiline: "multiline",
  password: "password",
  money: "money",
});

export const COMPONENT_FOR_INPUT_MODE = Object.freeze({
  [INPUT_MODE.singleLine]: Input,
  [INPUT_MODE.multiline]: TextArea,
  [INPUT_MODE.password]: PasswordInput,
  [INPUT_MODE.money]: MaskedInput.Formik.Money,
});
