import React, { useState, useEffect } from "react";

import { useGetApplicationInvitation } from "renter/hooks/api";
import { useApplicantTypes } from "shared/hooks/api";
import useModal from "shared/hooks/useModal";

import ContactManagerModal from "../ContactManager";

import ApplicationGroupIndividualsModal from "./ApplicationGroupIndividualsModal";

const ApplicationGroupIndividualsModalContainer = ({
  applicationId,
}: {
  applicationId: number;
}) => {
  const { openModalDialog } = useModal();
  const [applicants, setApplicants] = useState([]);
  const { data: types } = useApplicantTypes();

  const { application } = useGetApplicationInvitation(applicationId);

  const contactManager = () =>
    openModalDialog(ContactManagerModal, {
      dealId: application?.deal,
    });

  useEffect(() => {
    if (application && types) {
      const otherApplicants = application.otherApplicants.map((applicant) => ({
        fullName: `${applicant.firstName} ${applicant.lastName}`,
        status: applicant.status,
        type: types.find(
          ({ id }) => Number(applicant.applicantType) === Number(id)
        )?.value,
      }));

      setApplicants(otherApplicants);
    }
  }, [types, application]);

  return (
    <ApplicationGroupIndividualsModal
      applicants={applicants}
      unitName={application?.unitName}
      contactManager={contactManager}
    />
  );
};

export default ApplicationGroupIndividualsModalContainer;
