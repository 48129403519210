import styled from "@emotion/styled";

import FlexContainer from "shared/components/FlexContainer";

export const BlockApplicationIfNoIncomeDataModalContent = styled(FlexContainer)`
  margin-top: 20px;

  .reasons-well {
    align-self: flex-start;
    width: 100%;
    padding: 24px;
  }

  .reasons-well-title {
    align-self: start;
  }

  .reasons-checkbox {
    padding-bottom: 10px;
  }

  .info-icon {
    margin-left: 10px;
  }

  .more-info-text {
    text-align: center;
  }
`;
