import React, { useState } from "react";

import ShareMagicLinksModal from "manager/components/MagicLinks/ShareMagicLinksModal";
import {
  useGetMagicLinksProperties,
  useGetPortfoliosList,
  useTogglePropertyBranding,
  useDownloadQRCode,
} from "manager/hooks/api";
import useModal from "shared/hooks/useModal";

import ExportPropertiesModal from "../modals/ExportPropertiesModal";

import PropertiesTable from "./PropertiesTable";

const ORDER_BY_NAMES = Object.freeze({
  name: "name",
});

const ORDER_BY = Object.freeze({
  name: {
    ascend: ORDER_BY_NAMES.name,
    descend: `-${ORDER_BY_NAMES.name}`,
  },
});

const blankFilters = {
  portfolioId: null,
  nameOrAddress: "",
};

const PropertiesTableContainer = () => {
  const [ordering, setOrdering] = useState("");
  const [filters, setFilters] = useState({ ...blankFilters });
  const { data: properties, isLoading: isPropertiesLoading } =
    useGetMagicLinksProperties({
      ...filters,
      ordering,
    });

  const { downloadQRCode } = useDownloadQRCode();
  const { data: portfolios } = useGetPortfoliosList({ includeDefault: true });
  const { togglePropertyBranding } = useTogglePropertyBranding();
  const { openModalDialog } = useModal();

  const toggleBranding = (property) => {
    property.usePropertyBranding = !property.usePropertyBranding;

    togglePropertyBranding(property.id, {
      onError: () => {
        property.usePropertyBranding = !property.usePropertyBranding;
      },
    });
  };

  const updateFilters = (values) => {
    if (values) {
      setFilters({
        ...filters,
        ...values,
        ordering,
      });
    } else {
      setFilters({ ...blankFilters, ordering });
    }
  };

  const onTableChange = (tablePagination, tableFilters, sorter) => {
    const newOrdering = sorter.order
      ? `${ORDER_BY[sorter.columnKey][sorter.order]}`
      : undefined;
    setOrdering(newOrdering);
  };

  const onExportLinks = () => {
    openModalDialog(ExportPropertiesModal);
  };

  const onShareLinks = (context) =>
    openModalDialog(ShareMagicLinksModal, context);

  return (
    <PropertiesTable
      properties={properties}
      isPropertiesLoading={isPropertiesLoading}
      portfolios={portfolios}
      updateFilters={updateFilters}
      filters={filters}
      onTableChange={onTableChange}
      downloadQRCode={downloadQRCode}
      togglePropertyBranding={toggleBranding}
      onExportLinks={onExportLinks}
      onShareLinks={onShareLinks}
    />
  );
};

export default PropertiesTableContainer;
