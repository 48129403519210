import { useEffect, useState } from "react";

import { DEFAULT_PAGINATION } from "shared/config/constants";
import {
  OrderDirection,
  DataTableHookProps,
  InitialOrderColumn,
  TableState,
  UpdateTableStateFn,
  UpdateFiltersFn,
  OnTableChangeFn,
} from "shared/interfaces/dataTable";
import { Pagination } from "shared/interfaces/pagination";

import { useSessionStorageCollectionManager } from "../useSessionStorageCollectionManager";

enum DataTableStorageKeys {
  filters = "filters",
  pagination = "pagination",
  ordering = "ordering",
}

const getInitialOrderingColumn = <OrderMapType>(
  orderByMap: OrderMapType,
  ordering
) => {
  const columnKey = Object.keys(orderByMap).find((key) => {
    return (
      orderByMap[key].ascend === ordering ||
      orderByMap[key].descend === ordering
    );
  });

  return (
    columnKey
      ? {
          [columnKey]:
            orderByMap[columnKey].ascend === ordering
              ? OrderDirection.ascend
              : OrderDirection.descend,
        }
      : {}
  ) as InitialOrderColumn<OrderMapType>;
};

/**
 * Hook for keeping and managing the state of a data table.
 * The data table has 3 sub-states: filters, sorting, pagination.
 * We should try to fit any state in one of these 3 categories (usually in the filters).
 *
 * @param storageKey the cache key of the table state
 * @param orderByMap the map of fields where ordering is allowed
 * @param blankFilters the initial/blank state of the filters
 * @param defaultPagination the default pagination (used for the "reset")
 * @param defaultOrdering the default ordering (default ordering when none available)
 */
const useDataTable = <FiltersType, OrderMapType>({
  storageKey,
  orderByMap,
  blankFilters,
  defaultPagination = DEFAULT_PAGINATION,
  defaultOrdering,
}: DataTableHookProps<FiltersType, OrderMapType>) => {
  const dataTableStorageManager =
    useSessionStorageCollectionManager(storageKey);

  const initialFilters: FiltersType =
    dataTableStorageManager.getItem(DataTableStorageKeys.filters) ||
    blankFilters;
  const initialPagination: Pagination =
    dataTableStorageManager.getItem(DataTableStorageKeys.pagination) ||
    defaultPagination;
  const initialOrdering: string =
    dataTableStorageManager.getItem(DataTableStorageKeys.ordering) ||
    defaultOrdering;

  const initialOrderingColumn = getInitialOrderingColumn(
    orderByMap,
    initialOrdering
  );

  const [tableState, setTableState] = useState<TableState<FiltersType>>({
    filters: initialFilters,
    pagination: initialPagination,
    ordering: initialOrdering,
  });

  const updateTableState: UpdateTableStateFn<FiltersType> = (
    values,
    resetPagination = false
  ) => {
    const newTableState: TableState<FiltersType> = {
      ...tableState,
      ...values,
      ...(resetPagination ? { pagination: { ...defaultPagination } } : {}),
    };

    setTableState(newTableState);
  };

  const updateFilters: UpdateFiltersFn<FiltersType> = (values) => {
    let newFilters = { ...blankFilters };
    if (values) {
      newFilters = {
        ...tableState.filters,
        ...values,
      };
    }

    updateTableState({ filters: newFilters }, true);
  };

  const onTableChange: OnTableChangeFn = (
    tablePagination,
    tableFilters,
    sorter
  ) => {
    const newOrdering = sorter?.order
      ? `${orderByMap[sorter.columnKey][sorter.order]}`
      : undefined;

    const pagination = {
      page: tablePagination.current,
      pageSize: defaultPagination.pageSize,
    };
    updateTableState({ ordering: newOrdering, pagination });
  };

  useEffect(() => {
    dataTableStorageManager.addItem(
      DataTableStorageKeys.filters,
      tableState.filters
    );
    dataTableStorageManager.addItem(
      DataTableStorageKeys.pagination,
      tableState.pagination
    );
    dataTableStorageManager.addItem(
      DataTableStorageKeys.ordering,
      tableState.ordering
    );
  }, [tableState]);

  return {
    initialOrderingColumn,
    tableState,
    updateTableState,
    updateFilters,
    onTableChange,
  };
};

export default useDataTable;
