import React from "react";

import { Row, Col } from "antd";
import PropTypes from "prop-types";

import Icon from "shared/components/Icon";
import { ActionLink } from "shared/components/Links";
import Table from "shared/components/Table/Table/Table";
import { keyPropType } from "shared/utils/propTypes";

const OperationTrackingTable = ({
  id,
  yariOperationTracking,
  isLoadingOperationTracking,
  openYardiOperetionMessagesTable,
  openYardiClientReqResTable,
}) => {
  return (
    <Table
      alt
      withBorder
      noClickableRows
      columns={[
        {
          title: "Event",
          dataIndex: "event",
          key: "event",
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
        },
        {
          title: "Failure reason",
          dataIndex: "failureReason",
          key: "failureReason",
        },
        {
          title: "Request Id/Task Code",
          dataIndex: "requestId",
          key: "requestId",
        },
        {
          title: "Timestamps",
          dataIndex: "timestamps",
          key: "timestamps",
        },
        {
          title: "Actions",
          dataIndex: "id",
          key: "id",
        },
      ]}
      rows={yariOperationTracking.map((operationTracking) => ({
        key: operationTracking.id,
        viewed:
          id === operationTracking.id || id === operationTracking.requestId,
        values: [
          <div>
            <p>{operationTracking.event}</p>
            <p>
              <strong>Description:</strong>
              <br />
              {operationTracking.description}
            </p>
          </div>,
          operationTracking.status,
          operationTracking.failureReason,
          <div>
            <p>
              <strong>Request Id:</strong>
              <br />
              {operationTracking.requestId}
            </p>
            <p>
              <strong>Task Code:</strong>
              <br />
              {operationTracking.taskCode}
            </p>
          </div>,
          <div>
            <p>
              <strong>Created at:</strong>
              <br />
              {operationTracking.createdAt}
            </p>
            <p>
              <strong>Updated at:</strong>
              <br />
              {operationTracking.updatedAt}
            </p>
          </div>,
          operationTracking.id && (
            <Row gutter={[10, 10]}>
              <Col span={24}>
                <ActionLink
                  data-testid="open-message-table"
                  onClick={() =>
                    openYardiOperetionMessagesTable(operationTracking.id)
                  }
                >
                  View messages
                </ActionLink>
              </Col>
              <Col span={24}>
                <ActionLink
                  data-testid="open-request-table"
                  onClick={() =>
                    openYardiClientReqResTable(operationTracking.requestId)
                  }
                >
                  View Request
                </ActionLink>
              </Col>
            </Row>
          ),
        ],
      }))}
      loading={isLoadingOperationTracking}
      placeholder={{
        label: "Operation Tracking",
        Icon: Icon.NoDataIcon,
      }}
      columnBreakpoints={{
        xs: {
          column1: "event",
          column2: "requestId",
          column4: "id",
        },
        sm: ["event", "requestId", "createdAt", "updatedAt", "id"],
      }}
    />
  );
};

OperationTrackingTable.propTypes = {
  id: keyPropType.isRequired,
  yariOperationTracking: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  isLoadingOperationTracking: PropTypes.bool.isRequired,
  openYardiOperetionMessagesTable: PropTypes.func.isRequired,
  openYardiClientReqResTable: PropTypes.func.isRequired,
};

export default OperationTrackingTable;
