import React, { useEffect, useRef } from "react";

import {
  useGetYardiChargeTypes,
  useGetYardiCredentials,
  useYardiResolveLinkChargeTypes,
} from "manager/hooks/api";
import Icon from "shared/components/Icon";
import useModal from "shared/hooks/useModal";
import { resetFormAndValidate } from "shared/utils/forms";

import { CHARGE_TYPES } from "../shared";

import YardiGenericListDropdownsResolverModal from "./YardiGenericListDropdownsResolverModal";

const YardiLinkChargeTypesResolverModalContainer = () => {
  const { currentModalContext } = useModal();
  const { alert, provider } = currentModalContext;
  const { yardiChargeTypes, isLoading: isYardiChargeTypesLoading } =
    useGetYardiChargeTypes(provider);
  const { yardiCredentials, isLoading: isYardiCredentialsLoading } =
    useGetYardiCredentials(provider);

  const { resolveAlertLinkChargeTypes } = useYardiResolveLinkChargeTypes();
  const formikRef = useRef();

  const submit = async (values) => {
    const chargeTypes = {};
    (values.objects || []).forEach(({ id, linkedId }) => {
      chargeTypes[id] = linkedId;
    });
    const payload = {
      chargeTypes,
    };
    await resolveAlertLinkChargeTypes({
      provider,
      alertId: alert?.id,
      body: payload,
    });
  };

  useEffect(() => {
    if (formikRef.current && yardiCredentials) {
      const chargeTypesFormList = (CHARGE_TYPES || [])
        .filter((chargeType) => !yardiCredentials[chargeType.id])
        .map(({ id, label }) => ({
          id,
          inputDescription: label,
          linkedId: null,
        }));

      const values = { objects: chargeTypesFormList };
      resetFormAndValidate(formikRef, { values });
    }
  }, [formikRef.current, yardiCredentials]);

  const isLoading = isYardiCredentialsLoading || isYardiChargeTypesLoading;

  return (
    <YardiGenericListDropdownsResolverModal
      title="Link VERO charge types with External Provider"
      message="Please select new charge types for the following VERO charge types"
      selectPlaceholder="Select charge type"
      items={yardiChargeTypes}
      labelProp="description"
      submit={submit}
      formikRef={formikRef}
      isLoading={isLoading}
      PlaceholderIcon={Icon.NoDataIcon}
      placeholderText="Charge types"
      validationMessage="Charge type is required"
    />
  );
};

export default YardiLinkChargeTypesResolverModalContainer;
