import React, { useState } from "react";

import { Search } from "shared/components/Form";
import Icon from "shared/components/Icon";
import Logo from "shared/components/Logo";

import { BasicContainer, IconContainer } from "../styled";

import { SearchContainer } from "./styled";

export interface IMobileSearchHeader {
  onSearch?: (search: string) => void;
  label?: string;
  id?: string;
  debounce?: boolean;
  debounceTimeout?: number;
}

const MobileSearchHeader = ({
  label = "Search",
  id = "search",
  debounce = true,
  debounceTimeout = 500,
  onSearch,
}: IMobileSearchHeader) => {
  const [isCollapsed, setCollapsed] = useState(true);

  return (
    <>
      <BasicContainer>
        <Logo data-testid="mobile-header-logo" />
        <IconContainer
          onClick={() => setCollapsed(false)}
          data-testid="search-action-icon"
        >
          <Icon.SearchIcon />
        </IconContainer>
      </BasicContainer>
      <SearchContainer>
        {!isCollapsed && (
          <Search
            name="search"
            id={id}
            onSearch={onSearch}
            debounce={debounce}
            debounceTimeout={debounceTimeout}
            label={label}
            ActionIcon={
              <Icon.CrossRemoveIcon
                onClick={() => setCollapsed(true)}
                data-testid="search-close-icon"
              />
            }
            data-testid="search-input"
          />
        )}
      </SearchContainer>
    </>
  );
};

export default MobileSearchHeader;
