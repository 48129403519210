import React from "react";

import { Container, Number, Title } from "./styled";
import { SIZES, SIZES_TYPES } from "./utils";

interface NumberTitleProps {
  number: number;
  children: React.ReactNode;
  size?: string | SIZES_TYPES;
  [key: string]: any;
}

const NumberTitle = ({
  number,
  children,
  size,
  ...props
}: NumberTitleProps) => (
  <Container size={size} {...props}>
    <Number size={size}>{number}</Number>
    <Title size={size}>{children}</Title>
  </Container>
);

NumberTitle.SIZES = SIZES;

export default NumberTitle;
