import React, { useEffect, useState } from "react";

import { SorterResult, PaginationConfig } from "antd/lib/table";

import { DEAL_CATEGORIES } from "manager/config/constants";
import { useGetDeals } from "manager/hooks/api";
import { DEFAULT_PAGINATION } from "shared/config/constants";

import { DefaultPagination, Filters } from "../interfaces";

import UnitDeals from "./UnitDeals";
import { combineFilters, ORDER_BY } from "./utils";

interface UnitDealsContainerProps {
  id: number;
}

const UnitDealsContainer = ({ id }: UnitDealsContainerProps) => {
  const blankFilters = {
    unitId: id,
    unitPropertyOrApplicant: "",
    dateMin: null,
    dateMax: null,
  };
  const [category, setCategory] = useState<string>(DEAL_CATEGORIES[0].key);
  const [filters, setFilters] = useState<Filters>(blankFilters);
  const [pagination, setPagination] =
    useState<DefaultPagination>(DEFAULT_PAGINATION);
  const [ordering, setOrdering] = useState<string>("");
  const [showArchived, setShowArchived] = useState<boolean>(false);

  const [dealsFilters, setDealsFilters] = useState(
    // @ts-ignore
    combineFilters({
      category,
      filters,
    })
  );

  const updateFilters = (value?: Filters) => {
    if (value) {
      setFilters({
        ...filters,
        ...value,
      });
    } else {
      setFilters({ ...blankFilters });
    }
  };

  const updateCategory = (value: string) => {
    setCategory(value);
  };

  const onShowArchivedCheckboxChange = () => setShowArchived(!showArchived);

  const onTableChange = (
    tablePagination: PaginationConfig,
    tableFilters: Filters,
    sorter: SorterResult<Filters>
  ) => {
    const newOrdering = sorter?.order
      ? `${ORDER_BY[sorter.columnKey][sorter.order]}`
      : undefined;
    setOrdering(newOrdering);
  };

  /** Note
   * Whenever we update filters or category,
   * we should reset the pagination to avoid invalid page calls
   */
  useEffect(() => {
    setPagination({ ...DEFAULT_PAGINATION });
  }, [category, filters, showArchived]);

  /** Note:
   * The actual deals reload is triggered
   * only when pagination and filters are fully settled
   */
  useEffect(() => {
    setDealsFilters(
      combineFilters({
        category,
        filters,
        pagination,
        ordering,
        showArchived,
      })
    );
  }, [pagination, ordering]);

  const {
    data: deals,
    isLoading: isDealsLoading,
    refetch: refetchDeals,
    isFetching: isDealsFetching,
  } = useGetDeals(dealsFilters);

  return (
    <UnitDeals
      dealsCount={deals?.count}
      deals={deals?.results}
      refetchDeals={refetchDeals}
      isLoading={isDealsLoading || isDealsFetching}
      filters={filters}
      updateFilters={updateFilters}
      activeCategory={category}
      setCategory={updateCategory}
      onTableChange={onTableChange}
      setPagination={setPagination}
      pagination={pagination}
      showArchived={showArchived}
      onShowArchivedCheckboxChange={onShowArchivedCheckboxChange}
    />
  );
};

export default UnitDealsContainer;
