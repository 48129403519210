import React from 'react'

import { Col } from 'antd'
import _ from 'lodash'
import pluralize from 'pluralize'

import Chip, { ChipProps } from 'shared/components/Chip'
import FlexContainer from "shared/components/FlexContainer";
import { Popover, PopoverContent } from 'shared/components/Popover'
import Spacer from 'shared/components/Spacer'
import Tile from 'shared/components/Tile'
import { RegularText, MediumTitle, SmallText } from 'shared/components/Typography'
import {
  APPLICATION_COUNTY_CRIMINAL_SEARCH_STATUS,
  BACKGROUND_REPORT_TYPE,
  VANTAGE_SCORE_EXCLUSION_REASONS,
} from 'shared/config/constants'
import {
  TimeIcon,
  NewCheckIcon,
  AttentionIcon,
  CloseOneIcon,
  ErrorTriangleOutlineIcon,
} from 'shared/icons'
import { formatDate } from 'shared/utils/ui'

import { CountyCriminalRecord, CountyCriminalRecordStatus } from './interfaces'
import { CountyCriminalRecordsTitleContainer } from './styled'

export const N_A = 'N/A'

export const addressFormatter = ({
  address,
  city,
  state,
  zipcode,
}: {
  address: string
  city: string
  state: string
  zipcode: string
}) => {
  return !!address && !!city && !!state && !!zipcode
    ? `${address.toLowerCase()}, ${city.toLowerCase()}, ${state.toUpperCase()}, ${zipcode.toLowerCase()}`
    : N_A
}

export const shouldShowCountyCriminalRecords = (
  canAccessCountyCriminalRecords,
  countyCriminalSearchStatus,
  hasCountyCriminalSearch,
  hasTuCountyCriminalSearch,
) => {
  if (canAccessCountyCriminalRecords) {
    if (hasCountyCriminalSearch || hasTuCountyCriminalSearch) {
      return true
    }

    return [
      APPLICATION_COUNTY_CRIMINAL_SEARCH_STATUS.IN_PROGRESS,
      APPLICATION_COUNTY_CRIMINAL_SEARCH_STATUS.FAILED,
      APPLICATION_COUNTY_CRIMINAL_SEARCH_STATUS.COMPLETED,
    ].includes(countyCriminalSearchStatus)
  }

  return (
    countyCriminalSearchStatus ===
    APPLICATION_COUNTY_CRIMINAL_SEARCH_STATUS.NO_COUNTY_TO_RUN
  )
}

export const shouldShowCountyCriminalRecordsList = (
  criminalRecordsByCounty,
) => {
  return criminalRecordsByCounty?.length > 0
}

export const shouldShowNoCountyCriminalRecordsMessage = (
  countyCriminalSearchStatus,
  criminalRecordsByCounty,
) => {
  return (
    countyCriminalSearchStatus ===
      APPLICATION_COUNTY_CRIMINAL_SEARCH_STATUS.COMPLETED &&
    criminalRecordsByCounty?.length === 0
  )
}

export const getCriminalSearchStatusMessage = (countyCriminalSearchStatus) => {
  switch (countyCriminalSearchStatus) {
    case APPLICATION_COUNTY_CRIMINAL_SEARCH_STATUS.IN_PROGRESS:
      return 'Application is pending criminal records. This can take several days, and you will receive an email notification when results are ready for review.'
    case APPLICATION_COUNTY_CRIMINAL_SEARCH_STATUS.FAILED:
      return 'An error occurred while completing the County Criminal Records request. Please contact our support team for further assistance.'
    case APPLICATION_COUNTY_CRIMINAL_SEARCH_STATUS.NO_COUNTY_TO_RUN:
      return 'The county criminal search was not executed because the addresses provided in the residential histories do not have a corresponding county.'
    default:
      return null
  }
}

export const shouldShowCountyCriminalRecordsStatusMessage = (
  hasCountyCriminalSearch,
  hasTuCountyCriminalSearch,
  countCriminalSearchStatusMessage,
) => {
  if (hasCountyCriminalSearch) {
    return false
  }
  if (hasTuCountyCriminalSearch && !!countCriminalSearchStatusMessage) {
    return true
  }
  return false
}

export const getFailedReportMessage = (report) => {
  return _.includes(
    [BACKGROUND_REPORT_TYPE.ERROR, BACKGROUND_REPORT_TYPE.UNAVAILABLE],
    _.get(report, 'reportStatus'),
  )
    ? _.get(report, 'additionalMessage')
    : null
}

export const reportUnavailableTile = (header, message) => {
  return (
    <Col xs={24} xl={8}>
      <Tile equalHeight>
        <Tile.Inner>
          <h2>{header}</h2>
          <Spacer />
          <Tile.Label>{message}</Tile.Label>
        </Tile.Inner>
      </Tile>
    </Col>
  )
}

export const isVantageExclusion = (report) => {
  return (
    BACKGROUND_REPORT_TYPE.UNAVAILABLE === _.get(report, 'reportStatus') &&
    _.includes(
      [VANTAGE_SCORE_EXCLUSION_REASONS.DECEASED, VANTAGE_SCORE_EXCLUSION_REASONS.LACK_OF_INFORMATION, VANTAGE_SCORE_EXCLUSION_REASONS.TOO_MANY_RECORDS],
      _.get(report, 'additionalMessage'),
    )
  )
}

const ExclusionData = Object.freeze({
  [VANTAGE_SCORE_EXCLUSION_REASONS.DECEASED]: {
    "title": "Deceased Record",
    "description1": "The credit report for the applicant indicates that the person associated with the provided information is deceased.",
    "description2": "Please verify the information with the applicant and advise them to contact the credit reporting agency if this is incorrect."
  },
  [VANTAGE_SCORE_EXCLUSION_REASONS.LACK_OF_INFORMATION]: {
    "title": "Insufficient Credit Data",
    "description1": "The applicant’s credit report has too little information to generate a score, often due to a limited credit history or ‘thin file.’",
    "description2": "Please verify with the applicant that all their financial accounts are accurately reported and up-to-date. They may need to contact their financial institutions or the credit reporting agency to confirm that their information is correct and complete."
  },
  [VANTAGE_SCORE_EXCLUSION_REASONS.TOO_MANY_RECORDS]: {
    "title": "Record Limit Exceeded",
    "description1": "The credit profile for the applicant contains more than 500 tradelines, inquiries, and public records, which prevents generating a credit score.",
    "description2": "Please review the details with the applicant and consider additional verification."
  },
})

export const reportExclusionTile = (exclusion) => {
  return (
    <Col xs={24} xl={8}>
      <Tile equalHeight>
        <Tile.Inner>
          <FlexContainer justifyContent="space-between">
            <FlexContainer flexDirection="column">
              <MediumTitle extraStrong>No Credit Score Available:</MediumTitle>
               <MediumTitle>{ExclusionData[exclusion].title}</MediumTitle>
            </FlexContainer>
            <ErrorTriangleOutlineIcon width={32} height={32} />
          </FlexContainer>

          <Spacer size={Spacer.SIZES.sm} />
          <SmallText>{ExclusionData[exclusion].description1}</SmallText>
          <Spacer size={Spacer.SIZES.sm} />
          <SmallText>{ExclusionData[exclusion].description2}</SmallText>
        </Tile.Inner>
      </Tile>
    </Col>
  )
}

const CountyCriminalRecordChipData = Object.freeze({
  [CountyCriminalRecordStatus.IN_PROGRESS]: {
    label: 'In progress',
    color: 'dark-grey',
    icon: <TimeIcon />,
  },
  [CountyCriminalRecordStatus.CLEAR]: {
    label: 'No records',
    color: 'green',
    icon: <NewCheckIcon />,
  },
  [CountyCriminalRecordStatus.COMPLETE]: {
    label: 'Complete',
    color: 'green',
    icon: <NewCheckIcon />,
  },
  [CountyCriminalRecordStatus.NEED_INFO]: {
    label: 'Need info',
    color: 'orange',
    icon: <AttentionIcon />,
  },
  [CountyCriminalRecordStatus.RECORD]: {
    label: 'Records found',
    color: 'orange',
    icon: <AttentionIcon />,
  },
  [CountyCriminalRecordStatus.CANCELLED]: {
    label: 'Cancelled',
    color: 'light-grey',
    icon: <CloseOneIcon />,
  },
})

export const getCountyCriminalRecordHeader = (
  countyRecord: CountyCriminalRecord,
  CollapseIcon: any,
) => {
  const showRecords = [
    CountyCriminalRecordStatus.RECORD,
    CountyCriminalRecordStatus.CLEAR,
  ].includes(countyRecord.status)

  const showAdditionalInfo = [
    CountyCriminalRecordStatus.IN_PROGRESS,
    CountyCriminalRecordStatus.NEED_INFO,
  ].includes(countyRecord.status)

  const handleClickCopy = async () => {
    await navigator.clipboard.writeText(countyRecord.orderId)
  }

  return (
    <CountyCriminalRecordsTitleContainer
      data-testid={`county-records-${countyRecord.county}`}
    >
      {countyRecord.status && (
        <Popover
          withoutOverlay
          closeAfterTimeout={1000}
          trigger="click"
          content={<PopoverContent>order id copied</PopoverContent>}
        >
          <div onClick={handleClickCopy}>
            <Chip
              className="county-status-chip"
              {...(CountyCriminalRecordChipData[
                countyRecord.status
              ] as ChipProps)}
            />
          </div>
        </Popover>
      )}
      <RegularText className="county-name">
        <strong>County {countyRecord.county}</strong>
        {showRecords && (
          <span>
            , {pluralize('Record', countyRecord.records.length, true)}
          </span>
        )}
      </RegularText>
      {showAdditionalInfo && (
        <RegularText className="additional-info">
          {countyRecord.status === CountyCriminalRecordStatus.IN_PROGRESS &&
            `Expected completion: ${
              countyRecord.dueDate
                ? formatDate(countyRecord.dueDate)
                : 'not available at this moment, please check back later'
            }`}
          {countyRecord.status === CountyCriminalRecordStatus.NEED_INFO &&
            `Missing fields ${
              countyRecord.infoNeeded?.join(', ') ?? 'N/A'
            } - Contact VERO Support to provide the missing information so the report can be completed.`}
        </RegularText>
      )}
      {!!countyRecord.records.length && <CollapseIcon />}
    </CountyCriminalRecordsTitleContainer>
  )
}
