import React, { useState, useEffect } from "react";

import { Helmet } from "react-helmet";

import {
  useGetBluemoonDashboardData,
  useGetBluemoonLicensesQuery,
} from "manager/hooks/api";
import Container from "shared/components/Container";
import { BackLink } from "shared/components/Links";
import PageTitle from "shared/components/PageTitle";
import { BluemoonLogo } from "shared/images";

import {
  BluemoonDashboardHeader,
  NoLicenceSection,
  LicensesTable,
} from "../components";
import BluemoonPropertiesBar from "../components/BluemoonPropertiesBar";
import { BluemoonDashboardProps, Filters } from "../interfaces";
import {
  BluemoonTitleContainer,
  BluemoonImageContainer,
  BluemoonImg,
} from "../styled";

const title = "Dashboard";

const REFETCH_INTERVAL = 20000;

let refetchLicensesInterval;

const BluemoonDashboard = ({ backLink }: BluemoonDashboardProps) => {
  const blankFilters = {
    state: "",
    idNameOrState: "",
  };
  const [filters, setFilters] = useState({ ...blankFilters });
  const [initialLoaded, setInitialLoaded] = useState(false);
  const {
    data: licenses,
    isLoading,
    refetch: refetchLicences,
  } = useGetBluemoonLicensesQuery(filters, {}, initialLoaded);

  const { bluemoonDashboardData } = useGetBluemoonDashboardData();

  const updateFilters = (values: Filters) => {
    if (values) {
      setFilters({
        ...filters,
        ...values,
      });
    } else {
      setFilters({ ...blankFilters });
    }
  };

  const isSyncInProgress = () => {
    const isAnySyncInProgress = licenses.find(
      (license) => license.syncInProgress === true
    );
    if (isAnySyncInProgress) {
      refetchLicensesInterval = setInterval(refetchLicences, REFETCH_INTERVAL);
    }
    if (!isAnySyncInProgress && refetchLicensesInterval) {
      clearInterval(refetchLicensesInterval);
    }
  };

  useEffect(() => {
    if (licenses?.length > 0) {
      setInitialLoaded(true);
      isSyncInProgress();
    }
    return () => clearInterval(refetchLicensesInterval);
  }, [licenses]);

  return (
    <div>
      <Container expand noPaddingBottom>
        <Helmet>
          <title>Bluemoon {title}</title>
        </Helmet>
        {backLink && (
          <BackLink.Container>
            {/* @ts-ignore */}
            <BackLink {...backLink} />
          </BackLink.Container>
        )}
        <PageTitle.Container center>
          <BluemoonTitleContainer>
            <BluemoonImageContainer>
              <BluemoonImg src={BluemoonLogo} alt="Bluemoon" />
            </BluemoonImageContainer>
            {title}
          </BluemoonTitleContainer>
          <BluemoonDashboardHeader dashboardData={bluemoonDashboardData} />
          <BluemoonPropertiesBar />
        </PageTitle.Container>
        {!initialLoaded && <NoLicenceSection page="DASHBOARD_PAGE" />}
        {initialLoaded && (
          <LicensesTable
            licenses={licenses}
            loading={isLoading}
            filters={filters}
            updateFilters={updateFilters}
          />
        )}
      </Container>
    </div>
  );
};

export default BluemoonDashboard;
