import React, { CSSProperties } from "react";

import { Modal as BaseModal } from "antd-latest";

import styled from "styled-components";

import Icon from "shared/components/Icon";

import { VeroModalContext } from "./types";

type VeroModalProps = VeroModalContext<unknown> & {
  style?: CSSProperties;
  width?: CSSProperties["width"];
};

const Modal = styled(BaseModal)`
  .ant-latest-modal-body {
    padding: 32px;
  }
`;

export const VeroModal: React.FC<VeroModalProps> = ({
  children,
  visible,
  cancel$,
  width,
  style,
}) => {
  return (
    <Modal
      visible={visible}
      footer={null}
      closeIcon={<Icon.ModalCloseIcon />}
      onCancel={() => cancel$.next()}
      width={width}
      style={style}
    >
      {children}
    </Modal>
  );
};
