import React from "react";

import { FormikHelpers } from "formik";

import { useEditBluemoonLicense } from "manager/hooks/api/bluemoonQueries";

import { NOTIFICATIONS } from "shared/config/constants";
import useModal from "shared/hooks/useModal";
import { ApiError } from "shared/lib/errors";
import { openNotification } from "shared/utils/ui";

import BluemoonEditLicenseModal from "./BluemoonEditLicenseModal";
import { BluemoonLicenseEdit } from "./interfaces";

interface ModalContextProps {
  license: BluemoonLicenseEdit;
}

const BluemoonEditLicenseModalContainer = () => {
  const { closeActiveModalDialog, currentModalContext } = useModal();
  const { license }: ModalContextProps = currentModalContext;

  const { editBluemoonLicense } = useEditBluemoonLicense({ license });

  const onSubmit = (
    values: BluemoonLicenseEdit,
    { setSubmitting, setErrors }: FormikHelpers<BluemoonLicenseEdit>
  ) =>
    editBluemoonLicense(values, {
      onSuccess: closeActiveModalDialog,
      onError: (error: ApiError) => {
        setSubmitting(false);
        setErrors(error.errors);
        let message = "edited";
        if (!license.isActive && values.isActive) {
          message = "activated";
        } else if (license.isActive && !values.isActive) {
          message = "disabled";
        }

        openNotification(
          `An error occurred, Bluemoon license could not be ${message}, please try again.`,
          NOTIFICATIONS.error
        );
      },
    });

  return <BluemoonEditLicenseModal submit={onSubmit} license={license} />;
};

export default BluemoonEditLicenseModalContainer;
