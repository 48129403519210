import { useQuery } from "react-query";

import { NoUnitsFoundNotificationMessage } from "renter/components/MagicPortal/NoUnitsFoundNotification";
import api from "renter/lib/api";
import { NOTIFICATIONS } from "shared/config/constants";
import { useNotFoundErrorHandler } from "shared/utils/queries.utils";
import { openNotification } from "shared/utils/ui";

const QUERY_KEYS = {
  getMagicPortalCompany: (companyHashId) => [
    "getMagicPortalCompany",
    companyHashId,
  ],
  getMagicPortalProperties: ({
    companyHashId,
    page,
    pageSize,
    nameOrAddress,
    prescreeningOnly,
  }) => [
    "getMagicPortalProperties",
    {
      companyHashId,
      page,
      pageSize,
      nameOrAddress,
      prescreeningOnly,
    },
  ],
  getMagicPortalProperty: (propertyHashId) => [
    "getMagicPortalProperty",
    propertyHashId,
  ],
  getMagicPortalUnits: ({
    propertyHashId,
    page,
    pageSize,
    bathrooms,
    bedrooms,
    maxRent,
  }) => [
    "getMagicPortalUnits",
    {
      propertyHashId,
      page,
      pageSize,
      bathrooms,
      bedrooms,
      maxRent,
    },
  ],
};

const useGetMagicPortalCompany = ({ companyHashId }) => {
  const notFoundErrorHandler = useNotFoundErrorHandler();

  const { data: magicPortalCompany, isLoading: isMagicCompanyLoading } =
    useQuery(
      QUERY_KEYS.getMagicPortalCompany(companyHashId),
      () => api.getMagicPortalCompany({ companyHashId }),
      {
        retry: false,
        enabled: Boolean(companyHashId),
        onError: (error) => {
          if (!notFoundErrorHandler(error)) {
            openNotification(
              "Failed to load company data",
              NOTIFICATIONS.error
            );
          }
        },
      }
    );

  return {
    magicPortalCompany,
    isMagicCompanyLoading,
  };
};

const useGetMagicPortalProperties = ({
  companyHashId,
  page,
  pageSize,
  nameOrAddress,
  prescreeningOnly,
}) => {
  const notFoundErrorHandler = useNotFoundErrorHandler();

  const { data: properties, isLoading: isPropertiesLoading } = useQuery(
    QUERY_KEYS.getMagicPortalProperties({
      companyHashId,
      page,
      pageSize,
      nameOrAddress,
      prescreeningOnly,
    }),
    () =>
      api.getMagicPortalProperties({
        companyHashId,
        page,
        pageSize,
        nameOrAddress,
        prescreeningOnly,
      }),
    {
      retry: false,
      enabled: Boolean(companyHashId),
      onSuccess: (items) => {
        if (nameOrAddress && items.length === 0) {
          openNotification(
            "Oops! We were not able to find any results matching your search criteria.",
            NOTIFICATIONS.error
          );
        }
      },
      onError: (error) => {
        if (!notFoundErrorHandler(error)) {
          openNotification("Failed to load properties", NOTIFICATIONS.error);
        }
      },
    }
  );

  return {
    properties,
    isPropertiesLoading,
  };
};

const useGetMagicPortalProperty = (propertyHashId) => {
  const notFoundErrorHandler = useNotFoundErrorHandler();

  const { data: property, isLoading: isPropertyLoading } = useQuery(
    QUERY_KEYS.getMagicPortalProperty(propertyHashId),
    () =>
      api.getMagicPortalProperty({
        propertyHashId,
      }),
    {
      retry: false,
      enabled: Boolean(propertyHashId),
      onError: (error) => {
        if (!notFoundErrorHandler(error)) {
          openNotification("Failed to load property", NOTIFICATIONS.error);
        }
      },
    }
  );

  return {
    property,
    isPropertyLoading,
  };
};

const useGetMagicPortalUnits = ({
  propertyHashId,
  page,
  pageSize,
  bedrooms,
  bathrooms,
  maxRent,
}) => {
  const notFoundErrorHandler = useNotFoundErrorHandler();

  const { data: units, isLoading: isUnitsLoading } = useQuery(
    QUERY_KEYS.getMagicPortalUnits({
      propertyHashId,
      page,
      pageSize,
      bedrooms,
      bathrooms,
      maxRent,
    }),
    () =>
      api.getMagicPortalUnits({
        propertyHashId,
        page,
        pageSize,
        bedrooms,
        bathrooms,
        maxRent,
      }),
    {
      retry: false,
      enabled: Boolean(propertyHashId),
      onSuccess: (items) => {
        if ((bedrooms || bathrooms || maxRent) && items.length === 0) {
          openNotification(
            NoUnitsFoundNotificationMessage,
            NOTIFICATIONS.error
          );
        }
      },
      onError: (error) => {
        if (!notFoundErrorHandler(error)) {
          openNotification("Failed to load units", NOTIFICATIONS.error);
        }
      },
    }
  );

  return {
    units,
    isUnitsLoading,
  };
};

export {
  useGetMagicPortalCompany,
  useGetMagicPortalProperties,
  useGetMagicPortalProperty,
  useGetMagicPortalUnits,
};
