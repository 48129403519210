import React from "react";

export const LEADS_OPTIONS = Object.freeze({
  PROSPECT: "PROSPECT",
  GUEST: "GUEST",
});

export type OptionType =
  | typeof LEADS_OPTIONS.PROSPECT
  | typeof LEADS_OPTIONS.GUEST;

export interface SelectPreferrenceProps {
  selected: OptionType;
  onClick?:
    | React.MouseEventHandler<HTMLDivElement>
    | ((setFieldValue: any) => void);
}
