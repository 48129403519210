import React from "react";

import { Col, Row } from "antd-latest";

import { RegularText } from "shared/components/Typography";

import { AppListInner, AppListInnerProps } from "./app-list";
import { useAppList } from "./use-app-list";

export type OtherApplicantsProps = {
  mainApplicationId?: string;
  appListProps?: AppListInnerProps;
};

export const useOtherApplicants = (
  dealId?: string,
  mainApplicationId?: string
): OtherApplicantsProps => {
  const props = useAppList(dealId);
  return {
    mainApplicationId,
    appListProps: props,
  };
};

export const OtherApplicants = ({
  mainApplicationId,
  appListProps: props,
}: OtherApplicantsProps) => {
  return (
    <>
      <Row gutter={[10, 16]} style={{ padding: "32px" }}>
        {props.apps?.length > 1 || props.loading ? (
          <Col span={24}>
            <AppListInner {...props} skipId={mainApplicationId} />
          </Col>
        ) : (
          <RegularText light>
            No more applicants on this application.
          </RegularText>
        )}
      </Row>
    </>
  );
};
