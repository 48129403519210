import axios from "axios";

import {
  ExperianAuthOtpParams,
  ExperianAuthResponse,
  ExperianAuthTokenParams,
} from "manager/interfaces/api/experianAuth";

import ROUTES, { VEGA_BASE_URL } from "../../config/apiRoutes";

export const getExperianAuthToken = (params?: ExperianAuthTokenParams) =>
  axios
    .get<ExperianAuthResponse>(
      `${VEGA_BASE_URL}${ROUTES.getExperianAuthToken}`,
      {
        params: params
          ? {
              "first-name": params.firstName,
              "last-name": params.lastName,
              "current-address": params.currentAddress,
              "current-city": params.currentCity,
              "current-state": params.currentState,
              "current-zip": params.currentZip,
              "phone-number": params.phoneNumber,
              "jsc-payload": params.jscPayload,
              ssn: params?.ssn,
              email: params.email,
            }
          : {},
      }
    )
    .then(({ data }) => data);

export const getExperianAuthTokenByOtp = (params: ExperianAuthOtpParams) =>
  axios
    .get<any>(`${VEGA_BASE_URL}${ROUTES.getExperianAuthToken}`, {
      params: {
        otp: params.otpCode,
        "auth-session": params.authSession,
      },
    })
    .then(({ data }) => data);

export const getExperianAuthTokenByKiq = (
  params: Record<string, number | string>
) =>
  axios
    .get<any>(`${VEGA_BASE_URL}${ROUTES.getExperianAuthToken}`, {
      params,
    })
    .then(({ data }) => data);
