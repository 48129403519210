import axios from "axios";

import ROUTES from "manager/config/apiRoutes";
import { IIntegrationStatuses } from "manager/interfaces/api";
import { API_URL } from "shared/lib/api/setupApi";

const api = {
  getIntegrationStatuses: () =>
    axios
      .get<IIntegrationStatuses>(`${API_URL}${ROUTES.getIntegrationStatuses}`)
      .then(({ data }) => data),
};

export default api;
