import { css } from "@emotion/core";
import styled from "@emotion/styled";

import { CenteredFlexPartialCss } from "shared/styles/commonStyles";

const ConfirmationContent = styled.div<{
  noDescriptionIcon?: boolean;
  withDescription?: boolean | {};
}>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  .success-text {
    font-weight: 500;
    margin: 24px 0;
  }

  .description-section {
    ${({ noDescriptionIcon }) =>
      !noDescriptionIcon ? CenteredFlexPartialCss : ""}
  }
  .description-section:not(:last-of-type) {
    margin-bottom: 24px;
  }

  .description-icon {
    margin-right: 24px;
  }

  .description-text {
    margin-bottom: 0;
  }

  .message {
    margin-bottom: 0;

    ${({ withDescription }) =>
      withDescription
        ? css`
            margin-bottom: 24px;
          `
        : ""}
  }
`;

export { ConfirmationContent };
