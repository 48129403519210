import React, { useState, useEffect } from "react";

import { Typography } from "antd";

import { PropertyIntegrationMark } from "manager/components/IntegrationMark";
import {
  CollapsibleCheckboxList,
  SearchableList,
} from "shared/components/Form";
import Icon from "shared/components/Icon";
import { Modal, SIZES } from "shared/components/Modal";
import SelectedItemList from "shared/components/SelectedItemList";
import Spacer from "shared/components/Spacer";
import withBreakpoint from "shared/hocs/withBreakpoint";

import { EmployeePropertiesModalProps } from "./interfaces";

const { Text } = Typography;

const DEFAULT_PORTFOLIO_LABEL = "Not assigned to portfolio";

const getPortfolioSelectedProperties = (portfolio, checkedItems) => {
  return portfolio.properties.filter(({ id }) =>
    checkedItems.includes(`${portfolio.id}_${id}`)
  ).length;
};

const getPortfolioItems = (portfolios) => {
  const items = [];
  const checked = [];

  if (portfolios) {
    portfolios.forEach(
      ({
        id,
        name,
        properties,
        isAssigned: isPortfolioAssigned,
        isDefault,
      }) => {
        const portfolioId = `${id}`;
        items.push({
          key: portfolioId,
          label: isDefault ? DEFAULT_PORTFOLIO_LABEL : name,
          collapsible: true,
          value: isDefault ? DEFAULT_PORTFOLIO_LABEL : name,
        });

        if (isPortfolioAssigned) {
          checked.push(portfolioId);
        }

        properties.forEach((property) => {
          const {
            id: propId,
            name: propName,
            address,
            city,
            state,
            zipcode,
            isAssigned: isPropertyAssigned,
          } = property;
          const newId = `${id}_${propId}`;
          const fullAddress = `${address}, ${city}, ${state} ${zipcode}`;

          items.push({
            key: newId,
            label: (
              <div>
                {propName} <PropertyIntegrationMark property={property} />
              </div>
            ),
            description: <Text type="secondary">{fullAddress}</Text>,
            fullAddress,
            value: propName,
            parent: portfolioId,
          });

          if (isPropertyAssigned || isPortfolioAssigned) {
            checked.push(newId);
          }
        });
      }
    );
  }

  return {
    items: items.sort((a, b) => (a.key > b.key ? 1 : -1)),
    checked,
  };
};

const getIndeterminateItems = (items, checked) => {
  return items.reduce((prev, item) => {
    if (checked.includes(item.key)) {
      if (
        item.parent &&
        !checked.includes(item.parent) &&
        !prev.includes(item.parent)
      ) {
        prev.push(item.parent);
      }
    }

    return prev;
  }, []);
};

const getChildKeys = (items, key) => {
  const parentItem = items.find((item) => item.key === key);
  let childItems = [];

  if (parentItem.collapsible) {
    childItems = items
      .filter((item) => item.parent === key)
      .map((item) => item.key);
  }

  return childItems;
};

const searchPortfolios = (portfolioItems, search) => {
  const filteredItems = portfolioItems.filter(({ value, fullAddress }) => {
    return (
      value.toLowerCase().indexOf(search) > -1 ||
      (fullAddress && fullAddress.toLowerCase().indexOf(search) > -1)
    );
  });

  const displayedKeys = filteredItems.map((item) => item.key);

  filteredItems.forEach((item) => {
    if (item.collapsible) {
      const childKeys = getChildKeys(portfolioItems, item.key);
      const missingChildKeys = childKeys.filter(
        (key) => !displayedKeys.includes(key)
      );
      displayedKeys.push(...missingChildKeys);
    } else {
      const isParentIncluded = displayedKeys.includes(item.parent);

      if (!isParentIncluded) {
        displayedKeys.push(item.parent);
      }
    }
  });

  return displayedKeys
    .sort()
    .map((key) => portfolioItems.find((item) => item.key === key));
};

const EmployeePropertiesModal = ({
  isMobile,
  title,
  submitButtonLabel,
  fullName,
  items = [],
  onSubmit,
  helpText,
  loading,
  submitting,
}: EmployeePropertiesModalProps) => {
  const [portfolioItems, setPortfolioItems] = useState([]);
  const [portfolioItemsChecked, setPortfolioItemsChecked] = useState([]);
  const [portfolioItemsIndeterminate, setPortfolioItemsIndeterminate] =
    useState([]);
  const [search, setSearch] = useState<string>();
  const [displayedItems, setDisplayedItems] = useState([]);

  const checkPortfolioItem = (item) => {
    const childKeys = getChildKeys(portfolioItems, item.key);
    const uncheckedChildKeys = childKeys.filter(
      (key) => !portfolioItemsChecked.includes(key)
    );

    const newCheckedKeys = [
      ...portfolioItemsChecked,
      item.key,
      ...uncheckedChildKeys,
    ];
    setPortfolioItemsChecked(newCheckedKeys);

    const newIndeterminateKeys = portfolioItemsIndeterminate.filter(
      (key) => key !== item.key
    );
    setPortfolioItemsIndeterminate(newIndeterminateKeys);
  };

  const checkPropertyItem = (item) => {
    const newCheckedKeys = [...portfolioItemsChecked, item.key];
    const childKeys = getChildKeys(portfolioItems, item.parent);

    const checkParent = childKeys.every((element) =>
      newCheckedKeys.includes(element)
    );
    if (checkParent) {
      newCheckedKeys.push(item.parent);
      setPortfolioItemsIndeterminate(
        portfolioItemsIndeterminate.filter((key) => key !== item.parent)
      );
    } else if (!portfolioItemsChecked.includes(item.parent)) {
      setPortfolioItemsIndeterminate([
        ...portfolioItemsIndeterminate,
        item.parent,
      ]);
    }
    setPortfolioItemsChecked(newCheckedKeys);
  };

  const uncheckPortfolioItem = (item) => {
    const childKeys = getChildKeys(portfolioItems, item.key);

    const newCheckedKeys = portfolioItemsChecked.filter(
      (key) => ![item.key, ...childKeys].includes(key)
    );
    setPortfolioItemsChecked(newCheckedKeys);
    setPortfolioItemsIndeterminate([...portfolioItemsIndeterminate]);
  };

  const uncheckPropertyItem = (item) => {
    const newCheckedKeys = portfolioItemsChecked.filter(
      (key) => ![item.key, item.parent].includes(key)
    );
    setPortfolioItemsChecked(newCheckedKeys);

    const childKeys = getChildKeys(portfolioItems, item.parent);

    const checkedPortfolioProperty = childKeys.some((key) =>
      newCheckedKeys.includes(key)
    );

    const newIndeterminateKeys = portfolioItemsIndeterminate.filter(
      (key) => key !== item.parent
    );
    if (checkedPortfolioProperty) {
      newIndeterminateKeys.push(item.parent);
    }

    setPortfolioItemsIndeterminate(newIndeterminateKeys);
  };

  const toggleCheckbox = (item) => {
    const checking = !portfolioItemsChecked.includes(item.key);
    if (checking && item.collapsible) {
      checkPortfolioItem(item);
    } else if (checking) {
      checkPropertyItem(item);
    } else if (!checking && item.collapsible) {
      uncheckPortfolioItem(item);
    } else {
      uncheckPropertyItem(item);
    }
  };

  const onSaveChanges = () => {
    const portfolios = [];
    const properties = [];

    portfolioItemsChecked.forEach((item) => {
      if (item.includes("_")) {
        const [, propertyId] = item.split("_");
        properties.push(Number(propertyId));
      } else {
        portfolios.push(Number(item));
      }
    });

    onSubmit({ portfolios, properties });
  };

  useEffect(() => {
    if (items.length > 0) {
      const { items: newItems, checked } = getPortfolioItems(items);
      setPortfolioItems(newItems);
      setPortfolioItemsChecked(checked);
      setPortfolioItemsIndeterminate(getIndeterminateItems(newItems, checked));
    }
  }, [items]);

  useEffect(() => {
    const searchValue = search && search.toLowerCase();
    const newDisplayedItems = searchValue
      ? searchPortfolios(portfolioItems, searchValue)
      : [...portfolioItems];

    setDisplayedItems(newDisplayedItems);
  }, [portfolioItems, search]);

  return (
    <Modal
      title={title}
      subtitle={
        <span data-testid="subtitle">
          Employee: <strong>{fullName}</strong>
        </span>
      }
      submit={onSaveChanges}
      closeOnSubmit={false}
      submitButtonLabel={submitButtonLabel}
      submitButtonSize={SIZES.lg}
      width="auto"
      style={{ maxWidth: 946 }}
      submitting={submitting}
      fullScreen={isMobile}
      footer={
        <SelectedItemList
          label="property"
          items={items?.map((portfolio) => ({
            key: portfolio.id,
            label: portfolio.isDefault
              ? DEFAULT_PORTFOLIO_LABEL
              : portfolio.name,
            selected: getPortfolioSelectedProperties(
              portfolio,
              portfolioItemsChecked
            ),
            total: portfolio.properties.length,
          }))}
        />
      }
    >
      {/* @ts-ignore */}
      <Modal.Body noPaddingBottom>
        <p>{helpText}</p>
        <Spacer />
      </Modal.Body>
      {/* @ts-ignore */}
      <Modal.Body noMobilePaddingX noPaddingTop>
        <SearchableList
          search={{
            fieldName: "propertyAddressName",
            label: "Property, address, or portfolio",
            action: (value) => setSearch(value),
            width: "370px",
            debounce: true,
          }}
          // @ts-ignore
          List={CollapsibleCheckboxList.Derived}
          listContainerProps={{
            checkedItems: portfolioItemsChecked,
            indeterminateItems: portfolioItemsIndeterminate,
            toggleCheckbox,
          }}
          listProps={{
            col1Width: "350px",
            items: displayedItems,
          }}
          placeholder={{
            label: "Portfolios & Properties",
            Icon: Icon.NoPortfoliosIcon,
          }}
          loading={loading}
          hasInputValue={!!search}
        />
      </Modal.Body>
    </Modal>
  );
};

export default withBreakpoint(EmployeePropertiesModal);
