import React, { useState, Key } from "react";

import moment from "moment";

import { DROPDOWN_FILTER_MODES } from "shared/components/FilterBar";
import {
  SortOrder,
  DatePickerSpacer,
  Text,
  Select as StyledSelect,
  StyledMultiselect,
} from "shared/components/FilterBar/styled";
import { DatePicker, Select } from "shared/components/Form";
import Icon from "shared/components/Icon";

import { UnderlineLink } from "shared/components/Links";
import { Popover } from "shared/components/Popover";
import { SORT_TYPES } from "shared/config/constants";

import SwitchLight from "../Form/SwitchLight/SwitchLight";

import { FilterItem, FilterLabel, FilterText } from "./styled";

const { Option } = Select;

export type SortType = {
  label: string;
  onClick: () => void;
};

export interface FilterPopoverProps {
  sort?: {
    ascending: SortType;
    descending: SortType;
  };
  dropdownFilters: {
    key?: string;
    onChange: () => void;
    value?: any;
    items: {
      label: string;
      key: Key;
    }[];
    mode?: any;
    minWidth?: any;
    order?: number;
    label?: string;
    disabled?: boolean;
  }[];
  checkboxFilters?: {
    key?: any;
    onChange: () => void;
    value?: boolean;
    order?: number;
    disabled?: boolean;
    label?: string;
  }[];
  dateRange?: {
    label?: string;
    setValues?: (values?: any) => void;
    fromValue?: string;
    toValue?: string;
    date1Id?: string;
    date2Id?: string;
    disabled?: boolean;
  };
  onDropdownVisibleChange?: () => void;
  loadingDropdownOptions?: boolean;
  clearFilters?: {
    onClick?: () => void;
    order?: number;
    grow?: boolean;
  };
}

const FilterPopover = ({
  sort = undefined,
  dropdownFilters = undefined,
  checkboxFilters = undefined,
  dateRange = undefined,
  onDropdownVisibleChange = undefined,
  loadingDropdownOptions = false,
  clearFilters = undefined,
}: FilterPopoverProps) => {
  const [sortOrder, setSortOrder] = useState(SORT_TYPES.ASCENDING);

  let countOfFiltersUsed = dateRange?.fromValue || dateRange?.toValue ? 1 : 0;
  countOfFiltersUsed += (dropdownFilters || []).filter(
    (filter) => filter.value?.toString().length
  ).length;

  const toggleSortOrder = () => {
    const oppositeSort =
      sortOrder === SORT_TYPES.ASCENDING
        ? SORT_TYPES.DESCENDING
        : SORT_TYPES.ASCENDING;
    setSortOrder(oppositeSort);
    sort[sortOrder].onClick();
  };

  return (
    <Popover
      placement="bottom"
      arrowPointAtCenter
      trigger="click"
      content={
        <>
          {sort && (
            <FilterItem onClick={toggleSortOrder} role="button">
              <FilterLabel>Sorting</FilterLabel>
              <SortOrder>
                {sortOrder === SORT_TYPES.ASCENDING ? (
                  <Icon.ArrowDownIcon />
                ) : (
                  <Icon.ArrowUpIcon />
                )}
                <Text>{sort[sortOrder].label}</Text>
              </SortOrder>
            </FilterItem>
          )}
          {dropdownFilters &&
            dropdownFilters.map(
              ({
                key: dropdownKey,
                onChange,
                label: dropdownLabel,
                items,
                mode,
                order,
                value,
                disabled,
                minWidth,
              }) => {
                return mode === DROPDOWN_FILTER_MODES.multiselect ? (
                  <FilterItem key={dropdownKey} order={order}>
                    {dropdownLabel && (
                      <FilterLabel>{dropdownLabel}</FilterLabel>
                    )}
                    <StyledMultiselect
                      value={value}
                      options={items}
                      placeholder={dropdownLabel}
                      onChange={onChange}
                      id={dropdownKey}
                      name={dropdownKey}
                      onDropdownVisibleChange={onDropdownVisibleChange}
                      loading={loadingDropdownOptions}
                      minWidth={minWidth}
                      disabled={disabled}
                      dropdownMatchSelectWidth={false}
                    />
                  </FilterItem>
                ) : (
                  <FilterItem key={dropdownKey} order={order}>
                    {dropdownLabel && (
                      <FilterLabel>{dropdownLabel}</FilterLabel>
                    )}
                    <StyledSelect
                      value={value || items[0].key}
                      onChange={onChange}
                      id={dropdownKey}
                      onDropdownVisibleChange={onDropdownVisibleChange}
                      loading={loadingDropdownOptions}
                      disabled={!!disabled}
                      minWidth={minWidth}
                      dropdownMatchSelectWidth={false}
                    >
                      {items.map(({ label, key }) => (
                        <Option value={key} key={key}>
                          {label}
                        </Option>
                      ))}
                    </StyledSelect>
                  </FilterItem>
                );
              }
            )}
          {checkboxFilters &&
            checkboxFilters.map(
              ({ key: dropdownKey, onChange, label, order, value }) => (
                <FilterItem key={dropdownKey} order={order && order}>
                  <SwitchLight
                    id={dropdownKey}
                    title={<span>{label}</span>}
                    value={value}
                    onChange={onChange}
                  />
                </FilterItem>
              )
            )}
          {dateRange && (
            <FilterItem order={2}>
              <FilterLabel>{dateRange.label}</FilterLabel>
              <DatePicker
                setFieldValue={(name, date) => {
                  dateRange.setValues({
                    [name]: date,
                  });
                }}
                id={dateRange.date1Id}
                name={dateRange.date1Id}
                label="From"
                value={dateRange.fromValue}
                disabled={dateRange.disabled}
              />
              <DatePickerSpacer>&ndash;</DatePickerSpacer>
              <DatePicker
                setFieldValue={(name, date) => {
                  dateRange.setValues({
                    [name]: date,
                  });
                }}
                id={dateRange.date2Id}
                name={dateRange.date2Id}
                label="To"
                value={dateRange.toValue}
                disabledDate={(date) =>
                  dateRange.fromValue &&
                  moment(date).isBefore(dateRange.fromValue)
                }
                disabled={dateRange.disabled}
              />
            </FilterItem>
          )}
          {clearFilters && (
            <FilterItem
              order={clearFilters.order}
              noShrink
              grow={clearFilters.grow}
            >
              <UnderlineLink onClick={clearFilters.onClick}>
                Clear filters
              </UnderlineLink>
            </FilterItem>
          )}
        </>
      }
    >
      <Icon.FilterGreenIcon />
      <FilterText>
        FILTERS {countOfFiltersUsed > 0 && `(${countOfFiltersUsed})`}
      </FilterText>
    </Popover>
  );
};

export default FilterPopover;
