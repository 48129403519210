import styled from "@emotion/styled";
import { Typography } from "antd";

import { COLORS } from "shared/config/constants";

const { Text } = Typography;

const CheckboxFieldDataContainer = styled.div`
  margin-top: 33px;
`;

const WarningContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-top: 15px;
  padding: 16px 20px;
  background-color: ${COLORS.background};
`;

const Description = styled(Text)`
  display: block;
  font-size: 15px;
  line-height: 1.3;
  margin-bottom: 25px;
`;

const IconWrapper = styled.div`
  height: 40px;
  width: 40px;
`;

const Message = styled(Text)`
  font-size: 15px;
  font-weight: 400;
  line-height: 1.3;
  flex: 1;
`;

export {
  CheckboxFieldDataContainer,
  WarningContainer,
  Description,
  IconWrapper,
  Message,
};
