import React from "react";

import { Row, Col } from "antd";

import { Select, VeroFormField } from "shared/components/Form";

import { cookFormatterName } from "../../utils";
import AdditionalFieldData from "../AdditionalFieldData/AdditionalFieldData";

import { SmartFieldDataProps } from "./interfaces";

const SmartFieldData = ({
  isPropertiesLoading,
  properties = [],
  formatters = [],
  onChangeProperty,
  onChangeFormatter,
}: SmartFieldDataProps) => (
  <div data-testid="smart-field-data">
    <Row gutter={[0, 24]}>
      <Col>
        <VeroFormField
          name="smartSource.attribute"
          id="smartSource.attribute"
          label="Data Mapping"
          as={Select}
          onChange={onChangeProperty}
          disabled={!properties.length || isPropertiesLoading}
        >
          {properties.map((property) => (
            <Select.Option key={property.source.attribute}>
              {property.source.attribute}
            </Select.Option>
          ))}
        </VeroFormField>
      </Col>
    </Row>

    <Row gutter={[0, 24]}>
      <Col>
        <VeroFormField
          name="smartSource.format"
          id="smartSource.format"
          label="Field Format"
          as={Select}
          onChange={onChangeFormatter}
          disabled={!formatters.length}
        >
          {formatters.map((formatter) => (
            <Select.Option key={formatter.name}>
              {cookFormatterName(formatter)}
            </Select.Option>
          ))}
        </VeroFormField>
      </Col>
    </Row>

    <AdditionalFieldData />
  </div>
);

export default SmartFieldData;
