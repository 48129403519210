import { useEffect, useRef, useState } from "react";

/**
 * Hook for generating tooltip title
 * when text is overflowed and ellipsis are shown
 * @param {string} title - overflowed text
 * @returns {{ elementRef: object, tooltipTitle: string }}
 * elementRef - ref for your text element
 * tooltipTitle - title for the tooltip
 */
const useEllipsisTooltip = ({ title, multiline = false }) => {
  const elementRef = useRef();
  const [tooltipTitle, setTooltipTitle] = useState("");

  useEffect(() => {
    if (elementRef?.current) {
      let isOverflowed = false;

      isOverflowed = multiline
        ? elementRef.current.scrollHeight > elementRef.current.clientHeight
        : elementRef.current.offsetWidth < elementRef.current.scrollWidth;

      const tooltipText = isOverflowed ? title : "";
      setTooltipTitle(tooltipText);
    }
  }, [
    elementRef.current?.offsetWidth,
    elementRef.current?.scrollWidth,
    elementRef.current?.scrollHeight,
    elementRef.current?.clientHeight,
  ]);

  return {
    elementRef,
    tooltipTitle,
  };
};

export default useEllipsisTooltip;
