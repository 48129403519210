import React from "react";

import { Modal } from "shared/components/Modal";
import Spacer from "shared/components/Spacer";

import { UnassignPropertyFromPortfolioModalProps } from "./interfaces";

const UnassignPropertyFromPortfolioModal = ({
  portfolioName,
  submit,
  loading,
}: UnassignPropertyFromPortfolioModalProps) => (
  <Modal
    title="Unassign From Portfolio?"
    subtitle={
      <span data-testid="subtitle">
        Portfolio: <strong>{portfolioName}</strong>
      </span>
    }
    submit={submit}
    submitButtonLabel="Unassign"
    submitting={loading}
  >
    <Spacer />
  </Modal>
);

export default UnassignPropertyFromPortfolioModal;
