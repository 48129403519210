import styled from "@emotion/styled";

import { COLORS } from "shared/config/constants";

import { StyledLink, StyledStandardLink } from "../styled";

const ACTION_LINK_THEMES = Object.freeze({
  greenForViolet: "greenForViolet",
});

export interface ILinkStyles {
  dashed?: boolean;
  right?: boolean;
  theme?: any;
  standardCase?: boolean;
  standardWeight?: boolean;
  disabled?: boolean;
  [key: string]: any;
}

const linkStyles = ({
  dashed,
  right,
  theme,
  standardCase,
  standardWeight,
}: ILinkStyles) => ({
  color: COLORS.actionLink.default,
  display: "flex",
  fontSize: "15px",
  fontWeight: 500,
  ...(standardWeight && {
    fontWeight: 400,
  }),
  ...(!standardCase && { textTransform: "uppercase" }),
  "&:hover": {
    color: COLORS.actionLink.hover,
    "svg, path": {
      fill: COLORS.actionLink.hover,
    },
  },
  ...(dashed && {
    borderBottom: "1px dashed",
  }),
  ...(right && {
    flexDirection: "row-reverse",
  }),
  ...(theme === ACTION_LINK_THEMES.greenForViolet && {
    color: `${COLORS.violetTheme.green} !important`,
    borderBottomColor: `${COLORS.violetTheme.green} !important`,
    "&:hover": {
      color: `${COLORS.violetTheme.lightGreen} !important`,
      borderBottomColor: `${COLORS.violetTheme.lightGreen} !important`,
    },
  }),
});
// @ts-ignore
const StyledActionLink = styled(StyledLink)(linkStyles);
// @ts-ignore
const StyledStandardActionLink = styled(StyledStandardLink)(linkStyles);

const IconContainer = styled("span")(({ right }: { right?: boolean }) => ({
  marginRight: "9px",
  display: "flex",
  alignItems: "center",
  ...(right && {
    marginRight: 0,
    marginLeft: "9px",
  }),
}));

export {
  StyledActionLink,
  StyledStandardActionLink,
  IconContainer,
  ACTION_LINK_THEMES,
};
