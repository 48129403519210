import { toDate } from "../utils";

import {
  CountyCriminal,
  CountyCriminalApplicant,
  Criminal,
  CriminalRecordsResponse,
} from "./interfaces";

export const mapCriminal = (
  criminalSummary?: CriminalRecordsResponse
): Criminal | undefined => {
  if (!criminalSummary) return undefined;
  return {
    records: criminalSummary?.totalRecords,
    highestSeverity: criminalSummary?.biggestSeverity,
    lastRecordState: criminalSummary?.lastRecordState,
    lastRecordDate: toDate(criminalSummary?.lastRecord?.date),
  };
};

export const mapCountyCriminal = (
  deal: Record<string, any>
): CountyCriminal => {
  return {
    status: {
      helpText: deal.countyCriminalSearchStatus?.helpText,
      params: deal.countyCriminalSearchStatus?.params,
      value: deal.countyCriminalSearchStatus?.value,
    },
    applicants:
      deal.applicantsSummary?.map(
        (applicant) =>
          <CountyCriminalApplicant>{
            isCountyCriminalSearchEligible:
              applicant.isCountyCriminalSearchEligible,
            id: applicant.id,
            firstName: applicant.firstName,
            lastName: applicant.lastName,
            isSubmitted: applicant.isSubmitted,
          }
      ) ?? [],
    startedAt: toDate(deal.countyCriminalSearchStartedAt),
    completedAt: toDate(deal.countyCriminalSearchCompletedAt),
    requestedByName: deal.countyCriminalSearchRequestedByName,
  };
};
