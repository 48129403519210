import React, { useEffect, useRef } from "react";

import {
  useGetYardiMarketingSources,
  useYardiResolveLinkMarketingSources,
} from "manager/hooks/api";
import Icon from "shared/components/Icon";
import { useGetMarketingSources } from "shared/hooks/api/sourcesQueries";
import useModal from "shared/hooks/useModal";
import { resetFormAndValidate } from "shared/utils/forms";

import YardiGenericListDropdownsResolverModal from "./YardiGenericListDropdownsResolverModal";

const YardiLinkMarketingSourcesResolverModalContainer = () => {
  const { currentModalContext } = useModal();
  const { alert, yardiProperty, provider } = currentModalContext;
  const veroPropertyId = yardiProperty?.property?.id;
  const { yardiMarketingSources, isLoading: isYardiMarketingSourcesLoading } =
    useGetYardiMarketingSources(provider, yardiProperty.id);

  const { marketingSources, isLoading: isMarketingSourcesLoading } =
    useGetMarketingSources(veroPropertyId, true);

  const { resolveAlertLinkMarketingSources } =
    useYardiResolveLinkMarketingSources();
  const formikRef = useRef();

  const submit = async (values) => {
    const payload = {
      sources: (values.objects || []).map(({ id, linkedId }) => ({
        marketing_source_id: id,
        yardi_marketing_source_id: linkedId,
      })),
    };
    await resolveAlertLinkMarketingSources({
      provider,
      alertId: alert?.id,
      body: payload,
    });
  };

  useEffect(() => {
    if (formikRef.current) {
      const marketingSourcesFormList = (marketingSources || []).map(
        ({ id, value }) => ({
          id,
          inputDescription: value,
          linkedId: null,
        })
      );

      const values = { objects: marketingSourcesFormList };
      resetFormAndValidate(formikRef, { values });
    }
  }, [formikRef.current, marketingSources]);

  const isLoading = isMarketingSourcesLoading || isYardiMarketingSourcesLoading;

  return (
    <YardiGenericListDropdownsResolverModal
      title="Link VERO marketing sources with Yardi"
      subtitle={
        <>
          Property: <strong>{yardiProperty.name}</strong>
        </>
      }
      message="Please select new marketing sources for the following VERO sources"
      selectPlaceholder="Select Marketing Source"
      items={yardiMarketingSources}
      labelProp="value"
      submit={submit}
      formikRef={formikRef}
      isLoading={isLoading}
      PlaceholderIcon={Icon.NoDataIcon}
      placeholderText="Sources"
      validationMessage="Marketing Source is required"
    />
  );
};

export default YardiLinkMarketingSourcesResolverModalContainer;
