import React from "react";

import { useDeactivateEmployee } from "manager/hooks/api";

import DeactivateEmployeeModal from "./DeactivateEmployeeModal";

interface DeactivateEmployeeModalContainerProps {
  employeeId: number;
  name: string;
}

const DeactivateEmployeeModalContainer = ({
  employeeId,
  name,
}: DeactivateEmployeeModalContainerProps) => {
  const { deactivateEmployee, isDeactivateEmployeeLoading } =
    useDeactivateEmployee(employeeId, name);

  return (
    <DeactivateEmployeeModal
      name={name}
      submit={deactivateEmployee}
      submitting={isDeactivateEmployeeLoading}
    />
  );
};

export default DeactivateEmployeeModalContainer;
