import _ from "lodash";
import React from "react";
import { Col } from "antd";
import { hasListing } from "manager/utils/unit.util";
import { formatDate } from "shared/utils/ui";
import { ActionLink, UnderlineLink } from "shared/components/Links";
import { Popover, PopoverContent } from "shared/components/Popover";
import Skeleton from "shared/components/Skeleton";
import Tile from "shared/components/Tile";
import { printDollarsFromCents } from "shared/utils/dollar-print";
import { ListingsProps } from "../interfaces";

import {
  NoCurrentListing,
  NoCurrentListingContainer,
  StyledTile,
} from "./styled";
import UnitListingRow from "./UnitListingRow";
import { GreyRow } from "./styled";

const Listings = ({
  closeListing,
  editListing,
  createUnitListing,
  unit,
  isCloseVisible,
  isEditVisible,
  isCreateVisible,
  disabledCloseMessage,
  disabledCreateMessage,
  noUnitListingMessage = "There's no current listing for this Unit.",
  loading,
  previousListings,
  pricingOffers
}: ListingsProps) => (
  <>
    <StyledTile
      header={{
        title: "Current Listing",
        primaryAction: isCloseVisible && (
          // @ts-ignore
          <Popover
            className="popover"
            withoutOverlay
            disabled={!disabledCloseMessage}
            content={<PopoverContent>{disabledCloseMessage}</PopoverContent>}
          >
            <div>
              <UnderlineLink
                onClick={closeListing}
                disabled={!!disabledCloseMessage}
              >
                Close Listing
              </UnderlineLink>
            </div>
          </Popover>
        ),
        secondaryAction: isEditVisible && (
          <ActionLink onClick={editListing}>Edit Listing</ActionLink>
        ),
      }}
    >
      <Tile.Inner noPaddingBottom>
        <Skeleton loading={loading} title={false}>
          <>
            {hasListing(unit) ? (
              <UnitListingRow unitListing={unit?.unitListingCurrent} />
            ) : (
              <NoCurrentListingContainer>
                <NoCurrentListing>{noUnitListingMessage}</NoCurrentListing>
                {isCreateVisible && (
                  // @ts-ignore
                  <Popover
                    className="popover"
                    withoutOverlay
                    disabled={!disabledCreateMessage}
                    content={
                      <PopoverContent>{disabledCreateMessage}</PopoverContent>
                    }
                  >
                    <div>
                      <ActionLink
                        disabled={!!disabledCreateMessage}
                        onClick={createUnitListing}
                      >
                        Create Listing
                      </ActionLink>
                    </div>
                  </Popover>
                )}
              </NoCurrentListingContainer>
            )}
          </>
        </Skeleton>
      </Tile.Inner>
    </StyledTile>

    {!_.isEmpty(pricingOffers) && (
      <StyledTile
        header={{
          title: "Pricing Offers",
        }}
      >
        <Tile.Inner noPaddingBottom>
          {_.map(pricingOffers, (pricingOffer) => (
            <GreyRow type="flex" gutter={24}>
              <Col span={2}>
                <Tile.Label>Date Available</Tile.Label>
                <strong data-testid="unitAvailableOn">{formatDate(pricingOffer?.date)}</strong>
              </Col>
              <Col span={1}>
                <Tile.Divider />
              </Col>
              <Col span={2}>
                <Tile.Label>Rent Price</Tile.Label>
                <strong data-testid="rent">{printDollarsFromCents(pricingOffer?.amount)}</strong>
              </Col>
              <Col span={1}>
                <Tile.Divider />
              </Col>
              <Col span={1}>
                <Tile.Label>Term</Tile.Label>
                <strong data-testid="term">{pricingOffer?.term}</strong>
              </Col>
              <Col span={1}>
                <Tile.Divider />
              </Col>
              <Col span={12}>
                <Tile.Label>ID</Tile.Label>
                <span data-testid="pricing-offer-id">{pricingOffer?.id}</span>
              </Col>
              <Col span={2}>
                <Tile.Label>Pulled At</Tile.Label>
                <span data-testid='pricing-created-at'>{formatDate(pricingOffer?.createdAt)}</span>
              </Col>
            </GreyRow>
          ))}
        </Tile.Inner>
      </StyledTile>
    )}

    {previousListings?.length > 0 && (
      <StyledTile
        header={{
          title: "Previous listings",
        }}
      >
        <Tile.Inner noPaddingBottom>
          <Skeleton loading={loading} title={false}>
            {previousListings.map((prevListing) => (
              <UnitListingRow key={prevListing?.id} unitListing={prevListing} />
            ))}
          </Skeleton>
        </Tile.Inner>
      </StyledTile>
    )}
  </>
);

export default Listings;
