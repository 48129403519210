import React from "react";

import Icon from "shared/components/Icon";
import { SIZES } from "shared/components/Modal";
import Spacer from "shared/components/Spacer";
import { RegularText } from "shared/components/Typography";
import { useDeviceType } from "shared/hooks";

import { StyledModal } from "../styled";

interface SuccessModalProps {
  title: string;
  validPeriod: string;
  submit: () => void;
}

const SuccessModal = ({ title, validPeriod, submit }: SuccessModalProps) => {
  const { isMobile } = useDeviceType();
  return (
    <StyledModal
      title={title}
      titleIcon={<Icon.CheckGreenBig />}
      submitButtonLabel="Back to Vero"
      submitButtonSize={SIZES.lg}
      showCancelLink={false}
      isMobile={isMobile}
      closable={false}
      submit={submit}
    >
      <Spacer size={Spacer.SIZES.sm} />
      <RegularText>
        VERO successfully authenticated your information. You are now able to
        view applicant credit & background reports from Experian. VERO will
        remind you when it is time to authenticate again.
      </RegularText>
      <Spacer size={Spacer.SIZES.md} />
      <RegularText>Authentication valid through: {validPeriod}</RegularText>
      <Spacer size={Spacer.SIZES.md} />
    </StyledModal>
  );
};

export default SuccessModal;
