import axios from "axios";
import isArray from "lodash/isArray";

import ROUTES from "renter/config/apiRoutes";
import {
  ActiveUserApplicantType,
  Application,
  ApplicationConditionalRequestData,
  ApplicationPropertyDetails,
  FillRenterProfileData,
  RenterProfile,
} from "renter/interfaces/api/application";
import { AttachmentFile } from "shared/components/Form/UploadWall";
import { API_URL } from "shared/lib/api/setupApi";
import { transformRoute } from "shared/utils/routing";

export interface AdditionalDataFields {
  guarantorEmail: string;
  firstName: string;
  lastName: string;
}

const api = {
  getApplicationInvitation: ({ applicationId }) =>
    axios
      .get<Application>(
        `${API_URL}${transformRoute(ROUTES.applicationInvitation, {
          applicationId,
        })}`
      )
      .then(({ data }) => data),
  getApplicationLogo: ({ applicationId }) =>
    axios
      .get(
        `${API_URL}${transformRoute(ROUTES.applicationLogo, {
          applicationId,
        })}`
      )
      .then(({ data }) => data),

  /**
   * @param {Number} applicationId
   * @param {String} residentialNonMatchingCriteriaNotes
   * @param {String} employmentNonMatchingCriteriaNotes
   * @returns {Promise}
   */
  setApplicationNotes: ({
    applicationId,
    residentialNonMatchingCriteriaNotes,
    employmentNonMatchingCriteriaNotes,
  }) =>
    axios
      .patch(
        `${API_URL}${transformRoute(ROUTES.setApplicationNotes, {
          applicationId,
        })}`,
        {
          residentialNonMatchingCriteriaNotes,
          employmentNonMatchingCriteriaNotes,
        }
      )
      .then(({ data }) => data),
  confirmApplicantGroup: (applicationId: string | number) =>
    axios.patch(
      `${API_URL}${transformRoute(ROUTES.confirmApplicantGroup, {
        applicationId,
      })}`
    ),
  getApplicationPropertyDetails: (applicationId: number) =>
    axios
      .get<ApplicationPropertyDetails>(
        `${API_URL}${transformRoute(ROUTES.applicationPropertyDetails, {
          applicationId,
        })}`
      )
      .then(({ data }) => data),
  fillRenterProfile: ({
    applicationId,
    data,
  }: {
    applicationId: string | number;
    data: FillRenterProfileData;
  }) =>
    axios.post(
      `${API_URL}${transformRoute(ROUTES.fillRenterProfile, {
        applicationId,
      })}`,
      data
    ),
  getRenterProfile: (applicationId: string | number) =>
    axios
      .get<RenterProfile>(
        `${API_URL}${transformRoute(ROUTES.getRenterProfile, {
          applicationId,
        })}`
      )
      .then(({ data }) => data),
  addApplicationAttachment: ({
    applicationId,
    file,
    requiredAttachmentId,
  }: {
    applicationId: string | number;
    file: AttachmentFile;
    requiredAttachmentId?: number;
  }) => {
    const formData = new FormData();
    const files = isArray(file) ? file : [file];
    files.forEach((newFile: AttachmentFile) =>
      formData.append("upload", (newFile?.file || newFile) as Blob)
    );
    if (requiredAttachmentId) {
      formData.append("attachment_request", `${requiredAttachmentId}`);
    }
    return axios
      .post(
        `${API_URL}${transformRoute(ROUTES.addApplicationAttachment, {
          applicationId,
        })}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(({ data }) => data);
  },
  deleteApplicationAttachment: ({
    applicationId,
    attachmentId,
  }: {
    applicationId: string | number;
    attachmentId: number;
  }) =>
    axios.delete(
      `${API_URL}${transformRoute(ROUTES.deleteApplicationAttachments, {
        applicationId,
      })}`,
      {
        data: {
          attachments: isArray(attachmentId) ? attachmentId : [attachmentId],
        },
      }
    ),
  skipApplicationAttachment: ({
    applicationId,
    attachmentRequest,
  }: {
    applicationId: string | number;
    attachmentRequest: number;
  }) =>
    axios.post(
      `${API_URL}${transformRoute(ROUTES.skipApplicationAttachment, {
        applicationId,
      })}`,
      {
        attachmentRequest,
      }
    ),
  submitApplication: (applicationId: string | number) =>
    axios.post(
      `${API_URL}${transformRoute(ROUTES.submitApplication, {
        applicationId,
      })}`
    ),
  getConditionalRequest: (applicationId: string | number) =>
    axios
      .get<ApplicationConditionalRequestData>(
        `${API_URL}${transformRoute(ROUTES.conditionalRequest, {
          applicationId,
        })}`
      )
      .then(({ data }) => data),
  submitConditionalRequest: ({
    applicationId,
    values: { guarantorEmail, firstName, lastName },
  }: {
    applicationId: string | number;
    values: AdditionalDataFields;
  }) =>
    axios.post(
      `${API_URL}${transformRoute(ROUTES.conditionalRequest, {
        applicationId,
      })}`,
      {
        ...(guarantorEmail && { guarantorEmail }),
        ...(firstName && { firstName }),
        ...(lastName && { lastName }),
      }
    ),
  getActiveUserApplicantTypes: (applicationId: string | number) =>
    axios
      .get<ActiveUserApplicantType[]>(
        `${API_URL}${transformRoute(ROUTES.getActiveUserApplicantTypes, {
          applicationId,
        })}`
      )
      .then(({ data }) => data),
  setApplicantType: ({
    applicationId,
    applicantType,
  }: {
    applicationId: string | number;
    applicantType: number;
  }) =>
    axios.patch(
      `${API_URL}${transformRoute(ROUTES.setApplicantType, { applicationId })}`,
      { applicantType }
    ),
  getApplicationSummary: (applicationId: string | number) =>
    axios
      .get(
        `${API_URL}${transformRoute(ROUTES.getApplicationSummary, {
          applicationId,
        })}`
      )
      .then(({ data }) => data),
  markPaymentCompleted: (applicationId) =>
    axios.post(
      `${API_URL}${transformRoute(ROUTES.markPaymentCompleted, {
        applicationId,
      })}`
    ),
};

export default api;
