import styled from "@emotion/styled";

import Tile from "shared/components/Tile";
import Well from "shared/components/Well";

export const NoCompaniesWell = styled(Well)`
  padding: 16px 24px;
  margin: 0 -25px;
`;

export const SkippedIncomeWell = styled(Well)`
  padding: 16px 24px;
`;

export const IncomeStreamTileInner = styled(Tile.Inner)`
  margin-left: -24px;
  margin-right: -24px;
  padding-left: 24px !important;
  padding-right: 24px !important;
`;

export const IncomeNote = styled.div`
  margin-top: 20px;
`;
