import React from "react";

import { Route } from "react-router-dom";

import ROUTES from "shared/config/routes";

import ConditionalPage from "./ConditionalPage";

export interface IConditionalRoute {
  children?: React.ReactNode | undefined;
  isEnabled?: boolean | ((params: any) => void);
  isInitialized?: boolean;
  redirectTo?: string;
  exact?: boolean;
  path?: string | string[];
}

/**
 * Overview
 * Special route type that helps to enable/disable route by flag
 * If enabling flag is false, the user will be redirected to the specified route
 */
const ConditionalRoute = ({
  children = undefined,
  isEnabled = false,
  isInitialized = false,
  redirectTo = ROUTES.notFound,
  exact = undefined,
  path = undefined,
}: IConditionalRoute) => {
  return (
    <Route
      exact={exact}
      path={path}
      render={(params) => {
        return (
          <ConditionalPage
            params={params}
            isInitialized={isInitialized}
            isEnabled={isEnabled}
            redirectTo={redirectTo}
          >
            {children}
          </ConditionalPage>
        );
      }}
    />
  );
};

export default ConditionalRoute;
