import React from "react";

import { Row, Col } from "antd";
import copyToClipboard from "copy-to-clipboard";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import { Button } from "shared/components/Button";
import Container from "shared/components/Container";
import { Input, FormPromptShell } from "shared/components/Form";
import { BackLink, ActionLink } from "shared/components/Links";
import LoaderContainer from "shared/components/LoaderContainer";
import Notice from "shared/components/Notice";
import PageTitle from "shared/components/PageTitle";
import { Popover } from "shared/components/Popover";

import { openApiSettingsPropType, validationSchema } from "./config";
import { OpenApiSettingsProps } from "./interfaces";
import OpenApiSettingsForm from "./OpenApiSettingsForm";
import { SettingsContainer } from "./styled";

const title = "Open API Setup";

const OpenApiSettings = ({
  backLink,
  credentials,
  generateCredentials,
  isGeneratingCredentials,
  onTestWebhook,
  isTestingWebhook,
  onResetPassword,
  isResettingPassword,
  initialValues,
  onSubmit,
  saveChanges,
  getPromptProps,
  formRef,
}: OpenApiSettingsProps) => (
  <>
    <Helmet>
      <title>Open API Settings</title>
    </Helmet>
    <Container noPaddingBottom expand>
      <BackLink.Container>
        <BackLink {...backLink} />
      </BackLink.Container>
      <PageTitle.Container>
        <PageTitle>{title}</PageTitle>
      </PageTitle.Container>
      <SettingsContainer>
        <Notice type="info">Generate credentials to access Open API</Notice>
        <ActionLink
          plus
          onClick={generateCredentials}
          disabled={credentials.email || isGeneratingCredentials}
        >
          Create User
        </ActionLink>
        <LoaderContainer loading={isGeneratingCredentials}>
          {credentials.email && (
            <section className="credentials" data-testid="credentials">
              <Row gutter={[24, 24]}>
                <Col xl={8} md={11} xs={19}>
                  <Input
                    // @ts-ignore
                    id="email"
                    name="email"
                    label="Generated Email"
                    value={credentials.email}
                    disabled
                  />
                </Col>
                <Col span={1} className="copy" data-testid="email-copy">
                  {/* @ts-ignore */}
                  <Popover
                    content="Email is copied!"
                    trigger="click"
                    overlayClassName="success-popover"
                    closeAfterTimeout={500}
                  >
                    <ActionLink
                      onClick={() => copyToClipboard(credentials.email)}
                    >
                      Copy
                    </ActionLink>
                  </Popover>
                </Col>
              </Row>
              {credentials.password && (
                <>
                  <Row gutter={[24, 24]}>
                    <Col xl={8} md={11} xs={19}>
                      <Input
                        // @ts-ignore
                        id="password"
                        name="password"
                        label="Generated Password"
                        value={credentials.password}
                        disabled
                      />
                    </Col>
                    <Col span={1} className="copy" data-testid="password-copy">
                      {/* @ts-ignore */}
                      <Popover
                        content="Password is copied!"
                        trigger="click"
                        overlayClassName="success-popover"
                        closeAfterTimeout={500}
                      >
                        <ActionLink
                          onClick={() => copyToClipboard(credentials.password)}
                        >
                          Copy
                        </ActionLink>
                      </Popover>
                    </Col>
                  </Row>
                  <Row gutter={[20, 24]}>
                    <Col xl={8} md={11} xs={19}>
                      <Notice
                        type="warning"
                        fontWeight={600}
                        // @ts-ignore
                        className="warning"
                      >
                        Please write down the password before leaving the page
                      </Notice>
                    </Col>
                  </Row>
                </>
              )}
              <Row gutter={[24, 24]}>
                <Col>
                  <Button
                    type="warning"
                    onClick={onResetPassword}
                    loading={isResettingPassword}
                    data-testid="regenerate-password"
                  >
                    Regenerate password
                  </Button>
                </Col>
              </Row>
            </section>
          )}
        </LoaderContainer>
      </SettingsContainer>
      {credentials.email && (
        <FormPromptShell
          formRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          saveChanges={saveChanges}
          // @ts-ignore
          getPromptProps={getPromptProps}
          shouldDisableSubmitButton={false}
        >
          <OpenApiSettingsForm
            onTestWebhook={onTestWebhook}
            isTestingWebhook={isTestingWebhook}
          />
        </FormPromptShell>
      )}
    </Container>
  </>
);

OpenApiSettings.propTypes = {
  backLink: PropTypes.object.isRequired,
  credentials: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
  }),
  generateCredentials: PropTypes.func.isRequired,
  isGeneratingCredentials: PropTypes.bool.isRequired,
  onTestWebhook: PropTypes.func.isRequired,
  isTestingWebhook: PropTypes.bool.isRequired,
  onResetPassword: PropTypes.func.isRequired,
  isResettingPassword: PropTypes.bool.isRequired,
  initialValues: openApiSettingsPropType.isRequired,
  onSubmit: PropTypes.func.isRequired,
  saveChanges: PropTypes.func.isRequired,
  getPromptProps: PropTypes.func.isRequired,
  formRef: PropTypes.shape({
    current: PropTypes.object,
  }),
};

OpenApiSettings.defaultProps = {
  credentials: undefined,
  formRef: undefined,
};

export default OpenApiSettings;
