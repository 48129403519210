import React, { useEffect, useRef } from "react";

import {
  useGetIntegrationStatuses,
  useGetLeadSources,
  useUpdateLeadSources,
} from "manager/hooks/api";
import { FormPromptShell } from "shared/components/Form";
import Skeleton from "shared/components/Skeleton";
import { INTEGRATION_TYPES } from "shared/config/constants";
import { resetFormAndValidate } from "shared/utils/forms";

import { INTEGRATIONS_MARK_SIZE } from "../IntegrationMark/IntegrationMark.confg";
import { getIconPerIntegration } from "../IntegrationMark/IntegrationMark.util";

import {
  cleanupSources,
  convertRawSources,
  isSourceLinked,
} from "./additionalSettings.util";
import { LeadSources } from "./Sources";

import { PropertyIntegrationMarkContainer } from "./styled";

const CompanyAdditionalSettings = () => {
  const formRef = useRef();
  const { isLoading, leadSources } = useGetLeadSources(true);
  const { updateLeadSources } = useUpdateLeadSources();
  const { integrationStatuses } = useGetIntegrationStatuses();
  const { yardi, entrata, realPage } = integrationStatuses || {};
  const isYardiEnabled = yardi?.isEnabled;
  const isEntrataEnabled = entrata?.isEnabled;
  const isRealPageEnabled = realPage?.isEnabled;

  const saveChanges = (sources) => updateLeadSources(sources);

  const cleanupAndSaveChanges = (values) => {
    const resolvedSources = cleanupSources(values.sources);
    return saveChanges(resolvedSources);
  };

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      await cleanupAndSaveChanges(values);
    } finally {
      setSubmitting(false);
    }
  };

  const resolvedSources = convertRawSources(leadSources);

  const initialValues = { sources: resolvedSources };

  const providerName = () => {
    if (isYardiEnabled) {
      return "Yardi";
    }

    if (isEntrataEnabled) {
      return "Entrata";
    }

    if (isRealPageEnabled) {
      return "Real Page";
    }

    return "";
  };

  const providerIcon = () => {
    let provider = "";
    if (isYardiEnabled) {
      provider = INTEGRATION_TYPES.YARDI;
    }

    if (isEntrataEnabled) {
      provider = INTEGRATION_TYPES.ENTRATA;
    }

    if (isRealPageEnabled) {
      provider = INTEGRATION_TYPES.REAL_PAGE;
    }

    const IntegrationIcon = getIconPerIntegration(
      provider,
      INTEGRATIONS_MARK_SIZE.sm
    );
    return IntegrationIcon ? (
      <PropertyIntegrationMarkContainer opacity={isEntrataEnabled ? 0.75 : 1}>
        <IntegrationIcon className="title-popover" />
      </PropertyIntegrationMarkContainer>
    ) : null;
  };

  const isLinked = !!(leadSources || []).find(isSourceLinked);
  const linkedData = {
    isLinked,
    popoverText: `Lead sources are connected to ${providerName()}`,
    popoverIcon: providerIcon(),
    newSourcePopoverText: `You’re not able to add new lead sources to the company which is connected to ${providerName()}`,
    deleteWarningText: `Current lead source is linked with ${providerName()}`,
    deleteDescription: `Once you remove lead source from the company it won’t be recorded and sent to ${providerName()} when syncing anymore.`,
  };

  useEffect(() => {
    if (!isLoading && leadSources) {
      resetFormAndValidate(formRef, { sources: resolvedSources });
    }
  }, [isLoading, leadSources]);

  return (
    <FormPromptShell
      initialValues={initialValues}
      formRef={formRef}
      onSubmit={onSubmit}
      saveChanges={cleanupAndSaveChanges}
    >
      <Skeleton loading={isLoading}>
        <LeadSources linkedData={linkedData} />
      </Skeleton>
    </FormPromptShell>
  );
};

export default CompanyAdditionalSettings;
