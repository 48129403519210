import React, { useEffect, useState } from "react";

import {
  useGetBluemoonHiddenProperties,
  useGetStates,
  useUnhideBluemoonProperties,
} from "manager/hooks/api";
import { BluemoonHiddenPropertiesRequest } from "manager/interfaces/api/bluemoon";
import { DEFAULT_PAGINATION } from "shared/config/constants";

import BluemoonHiddenPropertiesModal from "./BluemoonHiddenPropertiesModal";

const blankFilter: BluemoonHiddenPropertiesRequest = {
  searchValue: "",
  state: null,
  ...DEFAULT_PAGINATION,
};

const BluemoonHiddenPropertiesModalContainer = () => {
  const { states = [] } = useGetStates();

  const [searchParams, setSearchParams] = useState(blankFilter);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [hasInputValue, setHasInputValue] = useState(false);
  const [selectedProperties, setSelectedProperties] = useState<number[]>([]);

  const {
    bluemoonHiddenPropertiesData,
    isBluemoonHiddenPropertiesFetching,
    refetchBluemoonHiddenProperties,
  } = useGetBluemoonHiddenProperties(searchParams);

  const { unhideBluemoonProperties, isUnhideBluemoonPropertiesLoading } =
    useUnhideBluemoonProperties();

  const { count: propertiesCount, results: properties } =
    bluemoonHiddenPropertiesData || {};

  const handleSearchParams = (key: string, value: string) => {
    let newFilters = blankFilter;
    if (!key) {
      setPagination(DEFAULT_PAGINATION);
    } else {
      if (key === "searchValue") {
        setHasInputValue(!!value);
      }
      newFilters = { ...searchParams, [key]: value, ...DEFAULT_PAGINATION };
      setSearchParams(newFilters);
      setPagination(DEFAULT_PAGINATION);
      return;
    }
    setSearchParams(newFilters);
  };

  const onPaginationChange = (currentPage: number) => {
    const newPagination = { page: currentPage, pageSize: pagination.pageSize };
    const newFilters = { ...searchParams, ...newPagination };
    setPagination(newPagination);
    setSearchParams(newFilters);
  };

  const allStates = [
    { label: "All states", key: "" },
    ...states.map((state) => ({ label: state.id, key: state.id })),
  ];

  useEffect(() => {
    refetchBluemoonHiddenProperties();
  }, [searchParams]);

  return (
    <BluemoonHiddenPropertiesModal
      propertiesCount={propertiesCount}
      isBluemoonHiddenPropertiesFetching={isBluemoonHiddenPropertiesFetching}
      properties={properties}
      hasInputValue={hasInputValue}
      states={allStates}
      searchParams={searchParams}
      handleSearchParams={handleSearchParams}
      onPaginationChange={onPaginationChange}
      pagination={pagination}
      unhideBluemoonProperties={unhideBluemoonProperties}
      isUnhideBluemoonPropertiesLoading={isUnhideBluemoonPropertiesLoading}
      selectedProperties={selectedProperties}
      setSelectedProperties={setSelectedProperties}
    />
  );
};

export default BluemoonHiddenPropertiesModalContainer;
