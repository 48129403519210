export const ADDRESS_FIELDS = Object.freeze({
  address: "address",
  city: "city",
  state: "state",
  country: "country",
  zipcode: "zipcode",
});

export const EXPERIAN_COLLECTOR = Object.freeze({
  jscInputId: "user_prefs",
  hdimInputId: "user_prefs2",
});
