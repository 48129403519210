import unitListingApi from "manager/lib/unitListingApi";

import applicationApi from "./applicationApi";
import appVersionApi from "./appVersionApi";
import authApi from "./authApi";
import backgroundAndCreditCheckApi from "./backgroundAndCreditCheckApi";
import companyApi from "./companyApi";
import configApi from "./configApi";
import disclaimersApi from "./disclaimersApi";
import documentApi from "./documentsApi";
import partnerApi from "./partnerApi";
import pmsPropertyApi from "./pmsPropertyApi";
import pmsUnitApi from "./pmsUnitApi";
import portfolioApi from "./portfolioApi";
import pricingOfferApi from "./pricingOfferApi";
import requiredAttachmentsApi from "./requiredAttachmentsApi";
import roleApi from "./roleApi";
import searchApi from "./searchApi";
import { setupApi } from "./setupApi";
import typeApi from "./typeApi";
import unitApi from "./unitApi";

setupApi();

const api = {
  ...applicationApi,
  ...authApi,
  ...configApi,
  ...companyApi,
  ...portfolioApi,
  ...roleApi,
  ...typeApi,
  ...unitApi,
  ...unitListingApi,
  ...requiredAttachmentsApi,
  ...backgroundAndCreditCheckApi,
  ...documentApi,
  ...partnerApi,
  ...disclaimersApi,
  ...appVersionApi,
  ...pricingOfferApi,
  ...pmsPropertyApi,
  ...pmsUnitApi,
  ...searchApi,
};

export default api;
export * from "./dealApi";
export * from "./propertyApi";
export * from "./dealSetupApi";
