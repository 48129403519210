import styled from "@emotion/styled";

import Well from "shared/components/Well";

const FileWrapper = styled(Well)`
  margin-bottom: 20px;
`;

const FileView = styled.img`
  height: 30px;
  width: 30px;
  margin-right: 14px;
`;

export { FileWrapper, FileView };
