import React from "react";

import { useFormikContext } from "formik";

import { ConfigurationPanel, LumenFilters } from "manager/components/Lumen";
import {
  LUMEN_SECTION_TYPE,
  LUMEN_SECTION_TYPE_LABELS,
} from "manager/config/lumen.config";
import { SwitchTitle } from "shared/components/Form/SwitchTitle";

import { LumenFormulaFormikValues } from "./interfaces";

const LumenFormulaStepCriminalRecords = () => {
  const { values, setFieldValue } =
    useFormikContext<LumenFormulaFormikValues>();
  // NOTE: goran - We should prevent the user to enable this section if the company do not allow
  // managers and applicants to see criminal record data,
  // i.e. hideCriminalHistory=true on company level or on property that has the formula assigned to it.
  const shouldPreventUseOfCriminalRecords = !values.canUseCriminalRecords;

  return (
    <>
      <strong>
        <SwitchTitle
          id="useCriminalRecords"
          title={LUMEN_SECTION_TYPE_LABELS[LUMEN_SECTION_TYPE.CRIMINAL_RECORDS]}
          value={values.useCriminalRecords}
          onChange={(value) => setFieldValue("useCriminalRecords", value)}
          noPadding
          disabled={shouldPreventUseOfCriminalRecords}
          tooltip={
            shouldPreventUseOfCriminalRecords
              ? "This company does not allow for criminal records to be shown to managers and applicants."
              : null
          }
        />
      </strong>
      {/* @ts-ignore */}
      <ConfigurationPanel isEnabled={!!values.useCriminalRecords}>
        <LumenFilters
          lumenSection={LUMEN_SECTION_TYPE.CRIMINAL_RECORDS}
          filtersProp="filterCriminalRecords"
          crucialFiltersText={`Any offenses that are considered "Crucial" will cause the applicants and group to be flagged as "Negative" and the suggested action will be "Decline"`}
          ignoredFiltersText={`Any records that are considered "Ignored" will not have any impact on the group or individual. The Lumen status and suggested action will not be impacted.`}
          safetyNetText={`Any offenses that do not meet the criteria of the above categories will be considered "Safety net". This will cause the applicant and group to be flagged as "Neutral" and the suggested action will be "Review Background".`}
          safetyNetProp="useCriminalRecordSafetyNet"
        />
      </ConfigurationPanel>
    </>
  );
};

export default LumenFormulaStepCriminalRecords;
