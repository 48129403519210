import { all } from "redux-saga/effects";

import {
  actions as additionalQuestionActions,
  reducers as additionalQuestionReducers,
  selectors as additionalQuestionSelectors,
} from "./AdditionalQuestion";
import {
  actions as applicationActions,
  reducers as applicationReducers,
  selectors as applicationSelectors,
} from "./Application";
import {
  actions as dealActions,
  reducers as dealReducers,
  selectors as dealSelectors,
} from "./Deal";
import {
  actions as dealSetupActions,
  reducers as dealSetupReducers,
  selectors as dealSetupSelectors,
} from "./DealSetup";
import {
  actions as documentTemplateActions,
  reducers as documentTemplateReducers,
  selectors as documentTemplateSelectors,
} from "./DocumentTemplate";
import {
  actions as employeeActions,
  reducers as employeeReducers,
  selectors as employeeSelectors,
} from "./Employee";
import { actions as miscActions } from "./Misc";
import {
  actions as modalActions,
  reducers as modalReducers,
  selectors as modalSelectors,
} from "./Modal";
import {
  actions as portfolioActions,
  reducers as portfolioReducers,
  selectors as portfolioSelectors,
} from "./Portfolio";
import {
  actions as propertyActions,
  reducers as propertyReducers,
  selectors as propertySelectors,
} from "./Property";
import {
  actions as requiredAttachmentActions,
  reducers as requiredAttachmentReducers,
  // selectors as requiredAttachmentSelectors
} from "./RequiredAttachment";
import {
  actions as rolesActions,
  reducers as rolesReducers,
  selectors as rolesSelectors,
} from "./Role";
import {
  actions as typeActions,
  reducers as typeReducers,
  selectors as typeSelectors,
} from "./Type";
import {
  actions as unitActions,
  reducers as unitReducers,
  selectors as unitSelectors,
} from "./Unit";
import {
  actions as unitListingActions,
  reducers as unitListingReducers,
  selectors as unitListingSelectors,
} from "./UnitListing";
import {
  actions as userActions,
  reducers as userReducers,
  selectors as userSelectors,
} from "./User";

export const actions = {
  ...applicationActions,
  ...dealActions,
  ...employeeActions,
  ...miscActions,
  ...modalActions,
  ...portfolioActions,
  ...propertyActions,
  ...rolesActions,
  ...typeActions,
  ...unitActions,
  ...unitListingActions,
  ...userActions,
  ...dealSetupActions,
  ...additionalQuestionActions,
  ...requiredAttachmentActions,
  ...documentTemplateActions,
};

export const reducers = {
  application: applicationReducers,
  deal: dealReducers,
  employee: employeeReducers,
  modal: modalReducers,
  portfolio: portfolioReducers,
  property: propertyReducers,
  role: rolesReducers,
  types: typeReducers,
  unit: unitReducers,
  unitListing: unitListingReducers,
  user: userReducers,
  dealSetup: dealSetupReducers,
  additionalQuestion: additionalQuestionReducers,
  requiredAttachment: requiredAttachmentReducers,
  documentTemplate: documentTemplateReducers,
};

export function* rootSaga() {
  yield all([]);
}

export const selectors = {
  ...applicationSelectors,
  ...dealSelectors,
  ...employeeSelectors,
  ...modalSelectors,
  ...portfolioSelectors,
  ...propertySelectors,
  ...rolesSelectors,
  ...typeSelectors,
  ...unitListingSelectors,
  ...unitSelectors,
  ...userSelectors,
  ...dealSetupSelectors,
  ...additionalQuestionSelectors,
  ...dealSelectors,
  ...applicationSelectors,
  ...documentTemplateSelectors,
};
