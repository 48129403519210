import * as Yup from "yup";

import { ANSWER_QUESTION_TYPES } from "shared/config/constants";

const ADDITIONAL_QUESTION_FIELDS = Object.freeze({
  questionName: "questionName",
  answerType: "answerType",
  options: "options",
  answer: "answer",
  position: "position",
});

const AdditionalQuestionValidationSchema = Yup.object().shape({
  [ADDITIONAL_QUESTION_FIELDS.questionName]: Yup.string()
    .trim()
    .matches(
      /^(?!.*(ssn|social|itin)).*$/i,
      "For security best practices, you should not be requesting such identifiers in a custom question. A secure functionality can be enabled to fulfill your request. Please contact your account manager."
    )
    .required("This field is required"),
  [ADDITIONAL_QUESTION_FIELDS.answerType]: Yup.number()
    .oneOf(Object.values(ANSWER_QUESTION_TYPES))
    .required("This field is required")
    .nullable(),
  [ADDITIONAL_QUESTION_FIELDS.options]: Yup.array().of(
    Yup.object().shape({
      [ADDITIONAL_QUESTION_FIELDS.answer]: Yup.string().nullable(),
    })
  ),
  [`${ADDITIONAL_QUESTION_FIELDS.options}[0]`]: Yup.object()
    .shape({
      [ADDITIONAL_QUESTION_FIELDS.answer]: Yup.string(),
    })
    .test(
      "test-first-option",
      "First option is required",
      function firstOptionTest() {
        const answerType = this.resolve(
          Yup.ref(ADDITIONAL_QUESTION_FIELDS.answerType)
        );

        if (
          answerType === ANSWER_QUESTION_TYPES.singleChoice &&
          !this.parent?.[ADDITIONAL_QUESTION_FIELDS.options]?.[0]?.[
            ADDITIONAL_QUESTION_FIELDS.answer
          ]
        ) {
          return this.createError({
            message: "First option is required",
            path: `${ADDITIONAL_QUESTION_FIELDS.options}[0].${ADDITIONAL_QUESTION_FIELDS.answer}`,
          });
        }

        return true;
      }
    )
    .nullable(),
});

export { ADDITIONAL_QUESTION_FIELDS, AdditionalQuestionValidationSchema };
