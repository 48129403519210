import React from "react";

import { Field, Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";
import * as Yup from "yup";

import { Button } from "shared/components/Button";
import { Input } from "shared/components/Form";
import Icon from "shared/components/Icon";
import { InstructionSteps } from "shared/components/Steps";
import { BREAKPOINT_RESOLUTIONS } from "shared/config/constants";
import {
  validEmail,
  validFirstName,
  validLastName,
} from "shared/forms/validation/fieldValidators";
import { refPropType } from "shared/utils/propTypes";

import {
  FinalInstructionIcon,
  Header,
  NameContainer,
  Subtitle,
  SubmitButtonWrapper,
} from "../styled";

const ValidationSchema = Yup.object().shape({
  firstName: validFirstName.required("First name is required."),
  lastName: validLastName.required("Last name is required."),
  email: validEmail.required("Email is required."),
});

const INITIAL_VALUES = {
  firstName: "",
  lastName: "",
  email: "",
};

const StepLanding = ({ submit, formikRef }) => {
  const isDesktop = useMediaQuery({ minWidth: BREAKPOINT_RESOLUTIONS.md });

  const title = isDesktop
    ? "Applying for an home is as easy as..."
    : "Applying is as easy as...";

  const firstItems = isDesktop
    ? [
        "Provide basic information about yourself",
        "Verify your income, background & credit",
        "Receive a decision from the property manager",
      ]
    : [
        "Provide basic information",
        "Verify income, background & credit",
        "Get the decision from the property manager",
      ];

  return (
    <>
      <Header>
        <h1 data-testid="title" className="title">
          {title}
        </h1>
        <Subtitle>
          <InstructionSteps
            direction="vertical"
            current={2}
            items={[
              ...firstItems,
              {
                title: (
                  <div className="strong-title">You’ve got this! Ready?</div>
                ),
                icon: <FinalInstructionIcon />,
              },
            ]}
          />
        </Subtitle>
      </Header>

      <>
        <Formik
          innerRef={formikRef}
          initialValues={INITIAL_VALUES}
          validationSchema={ValidationSchema}
          onSubmit={submit}
        >
          {({ errors, isValid, isSubmitting, touched }) => (
            <Form>
              <NameContainer>
                <div>
                  <Field
                    name="firstName"
                    id="firstName"
                    type="text"
                    label="First Name"
                    as={Input}
                    error={touched?.firstName && errors?.firstName}
                  />
                </div>
                <span className="name-divider" />
                <div>
                  <Field
                    name="lastName"
                    id="lastName"
                    type="text"
                    label="Last Name"
                    as={Input}
                    error={touched?.lastName && errors?.lastName}
                  />
                </div>
              </NameContainer>
              <Field
                name="email"
                id="email"
                label="Your email address"
                as={Input}
                error={touched?.email && errors?.email}
              />
              <SubmitButtonWrapper>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={!isValid}
                  loading={isSubmitting}
                  className="submit-btn"
                >
                  LET&apos;S GET STARTED
                </Button>
              </SubmitButtonWrapper>
              <div className="footer">
                P.S. The average application takes 11 minutes to complete. Think
                you can beat that? <Icon.Emoji className="emoji" />
              </div>
            </Form>
          )}
        </Formik>
      </>
    </>
  );
};

StepLanding.propTypes = {
  submit: PropTypes.func.isRequired,
  formikRef: refPropType.isRequired,
};

export default StepLanding;
