import React, { Dispatch, SetStateAction } from "react";

import {
  UnassignPropertyFromPortfolioModal,
  AssignPropertyToPortfolioModal,
} from "manager/components/Modal";
import AssignEmployeesToPropertyModal from "manager/components/Modal/AssignEmployeesToPropertyModal";
import { openCreateUnitWithSuccessModal } from "manager/components/Modal/CreateUnitModal";
import {
  useTogglePropertyDisplayOnPortal,
  useTogglePropertyPrequalification,
} from "manager/hooks/api";
import { Property } from "manager/interfaces/api";
import { usePermissions } from "shared/hooks";
import useModal from "shared/hooks/useModal";

import {
  AssignEmployeesToPropertyParams,
  AssignPropertyToPortfolioParams,
  CreateUnitParams,
  DefaultPagination,
  PropertiesSearchParams,
  UnassignPropertyFromPortfolioParams,
} from "../interfaces";

import PropertiesTable from "./PropertiesTable";

interface PropertiesTableContainerProps {
  hasInputValue: boolean;
  setPagination: Dispatch<SetStateAction<DefaultPagination>>;
  pagination: DefaultPagination;
  isLoading: boolean;
  properties: Property[];
  propertiesCount: number;
  refetchProperties: () => void;
  propertiesSearchParams: PropertiesSearchParams;
}

const PropertiesTableContainer = ({
  hasInputValue,
  setPagination,
  pagination,
  isLoading,
  properties,
  propertiesCount,
  refetchProperties,
  propertiesSearchParams,
}: PropertiesTableContainerProps) => {
  const { openModalDialog } = useModal();

  // eslint-disable-next-line react/prop-types
  const { togglePropertyDisplayOnPortal, isSavingDisplayOnPortal } =
    useTogglePropertyDisplayOnPortal();
  const { togglePropertyPrequalification, isSavingPrequalification } =
    useTogglePropertyPrequalification();
  const {
    isMagicPortalEnabled,
    isPrescreeningEnabled,
    canManagePrequalificationOnProperty,
  } = usePermissions();

  const onTogglePropertyDisplayOnPortal = (propertyId) =>
    togglePropertyDisplayOnPortal(propertyId, {
      onSuccess: () => refetchProperties(),
    });

  const onTogglePropertyPrequalification = (propertyId) =>
    togglePropertyPrequalification(propertyId, {
      onSuccess: () => refetchProperties(),
    });

  const createUnit = (context: CreateUnitParams) =>
    openCreateUnitWithSuccessModal(context);

  const assignEmployeesToProperty = (
    context: AssignEmployeesToPropertyParams
  ) => openModalDialog(AssignEmployeesToPropertyModal, context);

  const unassignPropertyFromPortfolio = (
    context: UnassignPropertyFromPortfolioParams
  ) => openModalDialog(UnassignPropertyFromPortfolioModal, context);

  const assignPropertyToPortfolio = (
    context: AssignPropertyToPortfolioParams
  ) => openModalDialog(AssignPropertyToPortfolioModal, context);

  return (
    <PropertiesTable
      onTogglePropertyDisplayOnPortal={onTogglePropertyDisplayOnPortal}
      onTogglePropertyPrequalification={onTogglePropertyPrequalification}
      isMagicPortalEnabled={isMagicPortalEnabled}
      showPrequalificationCheckbox={
        isPrescreeningEnabled && canManagePrequalificationOnProperty
      }
      loading={isLoading || isSavingDisplayOnPortal || isSavingPrequalification}
      createUnit={createUnit}
      assignEmployeesToProperty={assignEmployeesToProperty}
      unassignPropertyFromPortfolio={unassignPropertyFromPortfolio}
      assignPropertyToPortfolio={assignPropertyToPortfolio}
      properties={properties}
      propertiesCount={propertiesCount}
      hasInputValue={hasInputValue}
      setPagination={setPagination}
      pagination={pagination}
      propertiesSearchParams={propertiesSearchParams}
    />
  );
};

export default PropertiesTableContainer;
