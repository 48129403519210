import React from "react";

import { Switch, Route } from "react-router-dom";

import App from "shared/components/App";
import { APPS } from "shared/config/constants";
import Error404Page from "shared/pages/Error404Page";
import ROUTES from "surveys/config/routes";

/* Pages */
import VerificationOfResidency from "surveys/pages/VerificationOfResidency";

const id: string = APPS.surveys;

const SurveysApp = () => (
  <App
    id={id}
    render={() => {
      return (
        <>
          <Switch>
            {/* VOR */}
            <Route
              exact
              path={ROUTES.verificationOfResidency}
              render={() => <VerificationOfResidency />}
            />

            {/* Other views */}
            <Route render={() => <Error404Page />} />
          </Switch>
        </>
      );
    }}
  />
);

export default SurveysApp;
