import { useMutation, useQuery, queryCache } from "react-query";

import { TU_PAGES } from "renter/config/constants";
import { EnterSSNErrors } from "renter/interfaces/api/backgroundCreditCheck";
import api from "renter/lib/api";
import { NOTIFICATIONS, TU_STATUSES } from "shared/config/constants";
import { openNotification } from "shared/utils/ui";

import { QUERY_KEYS as APPLICATION_QUERY_KEYS } from "./applicationQueries";

const GET_BACKGROUND_AND_CREDIT_CACHE_KEY = "getBackgroundAndCreditCheck";
const GET_APPLICANT_CHECK_INFO = "getApplicantCheckInfo";

const QUERY_KEYS = Object.freeze({
  getBackgroundCredit: (id: string | number) => [
    GET_BACKGROUND_AND_CREDIT_CACHE_KEY,
    Number(id),
  ],
  getApplicantCheckInfo: (id: string | number) => [
    GET_APPLICANT_CHECK_INFO,
    Number(id),
  ],
});

export const useBackgroundCheck = (applicationId: string | number) => {
  const { data, isLoading, isFetching, refetch } = useQuery(
    QUERY_KEYS.getBackgroundCredit(applicationId),
    () => api.getBackgroundAndCreditCheck({ applicationId }),
    {
      onError: () =>
        openNotification(
          "Failed to open background and credit check",
          NOTIFICATIONS.error
        ),
    }
  );
  return {
    backgroundCheckData: data,
    isBackgroundCheckLoading: isLoading,
    isBackgroundCheckFetching: isFetching,
    refetchBackgroundCheck: refetch,
  };
};

export const useEnterSSN = ({ applicationId }) => {
  const [mutate, response] = useMutation(api.submitSSN, {
    onSuccess: ({ data }) => {
      queryCache.setQueryData(
        QUERY_KEYS.getBackgroundCredit(applicationId),
        data
      );
      queryCache.invalidateQueries(
        APPLICATION_QUERY_KEYS.getApplicationInvitation(applicationId)
      );
    },
    onError: (error: EnterSSNErrors) => {
      queryCache.invalidateQueries(
        QUERY_KEYS.getBackgroundCredit(applicationId)
      );
      if (!error?.errors?.ssn) {
        openNotification("Failed to submit ssn", NOTIFICATIONS.error);
      }
    },
  });

  return {
    submitSSN: mutate,
    response,
  };
};

export const useSubmitQuiz = ({ applicationId }) => {
  const [mutate, response] = useMutation(api.submitCreditCheckQuiz, {
    onSuccess: ({ data }) => {
      queryCache.setQueryData(
        QUERY_KEYS.getBackgroundCredit(applicationId),
        data
      );
      const step = data?.step;
      let msg;
      let msgType;
      if (step === TU_PAGES.reportGenerating || step === TU_PAGES.reportReady) {
        msg = "Successfully confirmed identity.";
        msgType = NOTIFICATIONS.info;
      } else {
        const evaluation = data?.data?.evaluation;
        switch (evaluation) {
          case TU_STATUSES.fail:
            msg = "The questions were answered incorrectly, please try again";
            msgType = NOTIFICATIONS.error;
            break;
          case TU_STATUSES.additionalQuestions:
            msg =
              "One or more questions were answered incorrectly, please answer additional questions";
            msgType = NOTIFICATIONS.error;
            break;
          default:
        }
      }
      if (msg) {
        openNotification(msg, msgType);
      }
    },
    onError: () =>
      openNotification("Failed to submit quiz", NOTIFICATIONS.error),
  });

  return {
    submitQuiz: mutate,
    response,
  };
};

export const useApplicantCheckInfo = (applicationId: string | number) => {
  const response = useQuery(
    QUERY_KEYS.getApplicantCheckInfo(applicationId),
    () => api.getApplicantCheckInfo({ applicationId }),
    {
      onError: () =>
        openNotification("Failed to open applicant info", NOTIFICATIONS.error),
    }
  );
  return response;
};

export const useUpdateApplicantCheckInfo = ({ applicationId }) => {
  const [mutate, response] = useMutation(api.updateApplicantCheckInfo, {
    onSuccess: ({ data }) => {
      queryCache.invalidateQueries(
        QUERY_KEYS.getBackgroundCredit(applicationId)
      );
      queryCache.setQueryData(
        QUERY_KEYS.getApplicantCheckInfo(applicationId),
        data
      );
    },
    onError: () => {
      openNotification("Failed to submit info", NOTIFICATIONS.error);
    },
  });

  return {
    submitApplicantCheckInfo: mutate,
    response,
  };
};

export const useUpdateApplicantReasonToStay = ({ applicationId }) => {
  const [mutate, response] = useMutation(api.updateApplicantReasonToStay, {
    onSuccess: ({ data }) => {
      queryCache.setQueryData(
        QUERY_KEYS.getBackgroundCredit(applicationId),
        data
      );
      queryCache.invalidateQueries(
        APPLICATION_QUERY_KEYS.getApplicationInvitation(applicationId)
      );
    },
    onError: () =>
      openNotification("Failed to submit reason to stay", NOTIFICATIONS.error),
  });

  return {
    submitReasonToStay: mutate,
    response,
  };
};

export const useResetBackgroundAndCreditCheck = () => {
  const [mutate, response] = useMutation(api.resetBackgroundAndCreditCheck, {
    onSuccess: ({ data }, { applicationId }) => {
      queryCache.setQueryData(
        QUERY_KEYS.getBackgroundCredit(applicationId),
        data
      );
    },
    onError: () =>
      openNotification(
        "Failed to reset the background and credit check",
        NOTIFICATIONS.error
      ),
  });

  return {
    resetBackgroundAndCreditCheck: mutate,
    response,
  };
};

export const useCompleteCreditAndBackgroundCheck = () => {
  const [mutate, response] = useMutation(api.completeCreditAndBackgroundCheck, {
    onError: () =>
      openNotification(
        "Failed to confirm the background and credit check",
        NOTIFICATIONS.error
      ),
  });

  return {
    completeCreditAndBackgroundCheck: mutate,
    response,
  };
};
