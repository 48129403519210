import styled from "@emotion/styled";

import BaseTile from "shared/components/Tile";

const Tile = styled(BaseTile)`
  cursor: pointer;
`;

const TileInner = styled(BaseTile.Inner)`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h3`
  margin-top: 16px;
  margin-bottom: 0;
`;

const Action = styled.div`
  align-self: flex-end;
`;

export { Tile, TileInner, Title, Action };
