import { useQuery } from "react-query";

import { NOTIFICATIONS } from "shared/config/constants";
import api from "shared/lib/api";
import { openNotification } from "shared/utils/ui";

const GET_PRICING_OFFERS_KEY = "getPricingOffers";

export const useGetPricingOffers = (location: string) => {
  const { data, isLoading } = useQuery(
    [GET_PRICING_OFFERS_KEY , location],
    () => api.getPricingOffers({ location }),
    {
      onError: () => {},
    }
  );

  return {
    pricingOffers: data,
    isPricingOffersLoading: isLoading,
  };
}
