import _ from "lodash"
import { BACKGROUND_CREDIT_CHECK_PROVIDER } from "./interfaces"
import {
  ExperianAuthResponseError,
} from "../../interfaces/api/experianAuth"

export const SIZES = {
  sm: "sm",
  md: "md",
  default: "default",
};

export enum SIZES_TYPES {
  sm = "sm",
  md = "md",
  default = "default",
}

export const isExperianProvider = (company) => {
  return (
    company?.bccProvider ===
    BACKGROUND_CREDIT_CHECK_PROVIDER.EXPERIAN_CONSUMER_CONNECT
  );
};


export const ExperianErrorCodes = {
  INVALID_REQUEST: '1004',
  INVALID_ZIPCODE: '2049',
  OFAC_HIT: '2053',
  USERS_FILE_IS_BLOCKED_OR_FROZEN: '3913',
  USERS_REPORT_IS_BLOCKED: '9116',
  USER_EXCEEDED_LIMIT_OF_AUTHENTICATION_ATTEMPTS: '2051',
}

export const getExperianErrorMessages = (
  experianError: ExperianAuthResponseError,
) => {
  const errors = _.get(experianError, 'errors.errors')
  const errorCodes = []
  const messages = _.chain(errors)
    .map('error')
    .map((error) => {
      const code = _.get(error, 'code')

      if (code === ExperianErrorCodes.INVALID_ZIPCODE) {
        return `Please enter a valid zipcode.`
      }

      if (code == ExperianErrorCodes.INVALID_REQUEST) {
        return _.chain(error)
          .get('fieldErrors')
          .keys()
          .map(
            (key) =>
              `${_.startCase(key)}: ${_.get(error, `fieldErrors.${key}`)}`,
          )
          .value()
      }

      if (code === ExperianErrorCodes.USERS_FILE_IS_BLOCKED_OR_FROZEN) {
        return `It appears your credit might be blocked or frozen. Contact Experian for support.`
      }

      if (
        code ===
        ExperianErrorCodes.USER_EXCEEDED_LIMIT_OF_AUTHENTICATION_ATTEMPTS
      ) {
        return `You have exceeded the limit of authentication attempts. Error code: ${code}`
      }

      errorCodes.push(code)
    })
    .flatten()
    .compact()
    .value()

    if (!_.isEmpty(errorCodes)) {
    messages.push(`One or more error occurred. Error code(s): ${errorCodes.join(', ')}`)
  }

  return messages
}
