import { UnitIntegrationMark } from "manager/components/IntegrationMark";
import { UNIT_STATUSES } from "manager/config/constants";
import ROUTES from "manager/config/routes";
import { Unit } from "manager/interfaces/api/unit";
import { getIntegrationType } from "manager/utils/integration.util";
import {
  checkIsAbleToInviteApplicant,
  hasListing,
} from "manager/utils/unit.util";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import Icon from "shared/components/Icon";
import { ActionLink } from "shared/components/Links";
import { Popover, PopoverContent } from "shared/components/Popover";
import Table from "shared/components/Table";
import Tooltip from "shared/components/Tooltip";
import {
  BREAKPOINT_RESOLUTIONS,
  INTEGRATION_TYPES,
} from "shared/config/constants";
import { printDollarsFromCents } from "shared/utils/dollar-print";
import { formatDate, getValueOrNA } from "shared/utils/ui";
import { UnitsTableProps } from "../interfaces";
import { PropertyIntegrationMarkContainer, UnitName } from "./styled";

const UnitsTable = ({
  units = [],
  loading,
  hasInputValue,
  pagination,
  setPagination,
  unitsCount,
  onUnitsTableChange,
  inviteApplicants,
  showOnlyUnitsNotAttachedToAnotherActiveDeal,
}: UnitsTableProps) => {
  const history = useHistory();
  const isXxlScreen = useMediaQuery({ minWidth: BREAKPOINT_RESOLUTIONS.xxl });
  const isXxxlScreen = useMediaQuery({ minWidth: BREAKPOINT_RESOLUTIONS.xxxl });

  const { page, pageSize } = pagination;

  const onPaginationChange = (currentPage: number) => {
    const newPagination = { page: currentPage, pageSize };
    setPagination(newPagination);
  };

  const shouldDisableInviteApplicants = (numOfActiveDeals) =>
    showOnlyUnitsNotAttachedToAnotherActiveDeal && numOfActiveDeals > 0;

  const inviteApplicantsLink = (unit, index) => {
    const shouldDisableInvite = shouldDisableInviteApplicants(
      unit?.unitListingCurrent?.numberOfActiveDeals
    );
    return (
      <Popover
        className="popover"
        withoutOverlay
        disabled={hasListing(unit) && !shouldDisableInvite}
        content={
          <PopoverContent>
            {hasListing(unit) && shouldDisableInvite
              ? "This unit is already attached to an application."
              : null}
            {!hasListing(unit)
              ? "Applicants cannot be invited to a unit without a listing"
              : null}
          </PopoverContent>
        }
      >
        <div>
          <ActionLink
            disabled={
              !hasListing(unit) || (hasListing(unit) && shouldDisableInvite)
            }
            data-testid={`unit-${index}-inviteApplicants`}
            onClick={() => {
              inviteApplicants({
                unitId: unit.id,
                unitName: unit.name,
                unitProvider: unit.provider,
                unitPmsConnected: unit.pmsConnected,
                propertyName: unit.propertyName,
              });
            }}
          >
            Invite Applicants
          </ActionLink>
        </div>
      </Popover>
    );
  };

  const getNameWithTooltip = (
    name: string,
    dataTestId: string,
    unit?: Unit
  ) => {
    // eslint-disable-next-line no-nested-ternary
    const tooltipLength = isXxxlScreen ? 40 : isXxlScreen ? 20 : 10;
    const isLongName = name?.length > tooltipLength;
    const isIntegrationEntrata =
      getIntegrationType(unit) === INTEGRATION_TYPES.ENTRATA;
    return (
      <Tooltip theme="light" placement="topLeft" title={isLongName ? name : ""}>
        <UnitName data-testid={dataTestId}>
          {`${name?.substring(0, tooltipLength)}${isLongName ? "..." : ""}`}
          {unit && (
            <PropertyIntegrationMarkContainer
              opacity={isIntegrationEntrata ? 0.75 : 1}
            >
              <UnitIntegrationMark unit={unit} />
            </PropertyIntegrationMarkContainer>
          )}
        </UnitName>
      </Tooltip>
    );
  };

  const getUnitsRows = () =>
    units.map((unit, index) => {
      const isAbleToInviteApplicants = checkIsAbleToInviteApplicant(unit);
      return {
        key: unit.id,
        values: [
          getNameWithTooltip(unit.name, `unit-${index}-name`, unit),
          getNameWithTooltip(unit.propertyName, `unit-${index}-property`),
          <div data-testid={`unit-${index}-status`}>
            {UNIT_STATUSES[unit.status]?.label}
          </div>,
          <div data-testid={`unit-${index}-date-available`}>
            {unit?.unitListingCurrent?.unitAvailableOn
              ? formatDate(unit.unitListingCurrent.unitAvailableOn)
              : "N/A"}
          </div>,
          <div data-testid={`unit-${index}-rent`}>
            {unit?.unitListingCurrent?.rent
              ? printDollarsFromCents(unit.unitListingCurrent.rent)
              : "N/A"}
          </div>,
          <div data-testid={`unit-${index}-bedrooms-bathrooms`}>
            {unit.bedrooms || unit.bathrooms
              ? `${getValueOrNA(unit.bedrooms)}/${getValueOrNA(unit.bathrooms)}`
              : "N/A"}
          </div>,
          <div data-testid={`unit-${index}-listing-note`}>
            <Tooltip
              title={unit.unitListingCurrent?.note}
              theme="light"
              placement="topLeft"
            >
              <Icon.NoteIcon
                disabled={!unit.unitListingCurrent?.note}
                width={22}
                height={22}
              />
            </Tooltip>
          </div>,
        ],
        primaryAction:
          isAbleToInviteApplicants && inviteApplicantsLink(unit, index),
      };
    });

  return (
    <Table
      alt
      searching={hasInputValue}
      showActionColumn
      columns={[
        {
          title: "Unit",
          dataIndex: "name",
          key: "name",
          sorter: true,
        },
        {
          title: "Property",
          dataIndex: "property",
          key: "property",
          sorter: true,
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
        },
        {
          title: "Date Available",
          dataIndex: "dateAvailable",
          key: "dateAvailable",
          sorter: true,
        },
        {
          title: "Rent",
          dataIndex: "rent",
          key: "rent",
          sorter: true,
        },
        {
          title: "Bed/Bath",
          dataIndex: "bedrooms",
          key: "bedrooms",
          sorter: true,
        },
        {
          title: "Note",
          dataIndex: "note",
          key: "note",
        },
      ]}
      rows={getUnitsRows()}
      primaryAction={{
        label: "Assign Properties",
      }}
      columnBreakpoints={{
        xs: {
          column1: "name",
          column2: "property",
        },
        sm: ["name", "property"],
        md: ["name", "property"],
        lg: ["name", "property", "status", "dateAvailable", "rent", "note"],
      }}
      onRowClick={(key) =>
        history.push(ROUTES.unit.replace(":id", key), {
          prevPath: history.location.pathname,
        })
      }
      placeholder={{
        label: "Units",
        Icon: Icon.NoPortfoliosIcon,
      }}
      loading={loading}
      pagination={{
        current: page,
        onChange: onPaginationChange,
        total: unitsCount,
        defaultPageSize: pageSize,
      }}
      onChange={onUnitsTableChange}
    />
  );
};

export default UnitsTable;
