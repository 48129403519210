import React from "react";

import { Helmet } from "react-helmet";

import Container from "shared/components/Container";
import { BackLink } from "shared/components/Links";

import { LumenDashboardProps } from "./interfaces";
import LumenDashboardBanner from "./LumenDashboardBanner";
import LumenDashboardTable from "./LumenDashboardTable";
import LumenNotAvailable from "./LumenNotAvailable";
import { LumenDashboardContent } from "./styled";

const title = "Dashboard";

const LumenDashboard = ({
  isLumenEnabled,
  permissionsLoaded,
  tableData,
  backLink,
}: LumenDashboardProps) => (
  <LumenDashboardContent className="screen-content">
    <Container noPaddingBottom noMobilePaddingX>
      <Helmet>
        <title>Lumen {title}</title>
      </Helmet>
      <Container noPaddingBottom>
        <BackLink.Container>
          <BackLink {...backLink} />
        </BackLink.Container>
      </Container>
      <LumenDashboardBanner />
    </Container>
    {!!isLumenEnabled && <LumenDashboardTable {...tableData} />}
    {!!permissionsLoaded && !isLumenEnabled && <LumenNotAvailable />}
  </LumenDashboardContent>
);

export default LumenDashboard;
