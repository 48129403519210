export interface DollarPrintOptions {
  withCents: boolean;
  numberOfCentDigits?: number;
}

interface NumberFormatConfig {
  style: string;
  currency: string;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
}

type Amount = number | null | undefined

const defaultOptions: DollarPrintOptions = {
  withCents: true,
  numberOfCentDigits: 2
}

const numberOfCentsInDollar = 100

export function printDollars(amount: Amount, options: DollarPrintOptions = defaultOptions): string {

  if (amount === undefined || amount === null) {
      return "N/A"
  }

  const config: NumberFormatConfig = {
      style: "currency",
      currency: "USD",
  }

  if (!options.withCents) {
      config.maximumFractionDigits = 0
      config.minimumFractionDigits = 0
  } else if (options.numberOfCentDigits) {
      config.maximumFractionDigits = options.numberOfCentDigits
      config.minimumFractionDigits = options.numberOfCentDigits
  }

  const printer = Intl.NumberFormat("en-US", config)

  return printer.format(amount)
}

function centsToDollars(amount: number): number {
  return amount / numberOfCentsInDollar
}

export function printDollarsFromCents(amount: Amount, options: DollarPrintOptions = defaultOptions): string {
  if (amount === undefined || amount === null) {
      return "N/A"
  }

  const asDollars: number = centsToDollars(amount)

  return printDollars(asDollars, options)
}


