import axios from "axios";

import ROUTES from "shared/config/apiRoutes";
import { transformRoute } from "shared/utils/routing";

import { API_URL } from "./setupApi";

export const getDefaultDealSetup = async () =>
  axios.get(`${API_URL}${ROUTES.defaultDealSetup}`).then(({ data }) => data);

export const getDefaultPrescreeningDealSetup = async () =>
  axios
    .get(`${API_URL}${ROUTES.defaultPrescreeningDealSetup}`)
    .then(({ data }) => data);

export const getDealSetup = async ({ id }) =>
  axios
    .get(`${API_URL}${transformRoute(ROUTES.dealSetup, { id })}`)
    .then(({ data }) => data);

export const getDealSetups = async ({ propertyId }) =>
  axios.get(`${API_URL}${ROUTES.dealSetups}`, {
    // @ts-ignore
    property_id: propertyId,
  });

export const updateDealSetup = async ({ id, dealSetup }) =>
  axios.post(
    `${API_URL}${transformRoute(ROUTES.updateDealSetup, { id })}`,
    dealSetup
  );
export const copyDealSetupFromPortfolio = async ({ propertyId }) =>
  axios
    .post(
      `${API_URL}${transformRoute(ROUTES.copyDealSetupFromPortfolio, {
        propertyId,
      })}`
    )
    .then(({ data }) => data);
export const resetPropertyToPortfolioSettings = ({ propertyId }) =>
  axios
    .delete(
      `${API_URL}${transformRoute(ROUTES.resetPropertyToPortfolioSettings, {
        propertyId,
      })}`
    )
    .then(({ data }) => data);
export const copyPrescreeningDealSetupFromPortfolio = ({ propertyId }) =>
  axios
    .post(
      `${API_URL}${transformRoute(
        ROUTES.copyPrescreeningDealSetupFromPortfolio,
        {
          propertyId,
        }
      )}`
    )
    .then(({ data }) => data);
export const resetToPrescreeningPortfolioSettings = ({ propertyId }) =>
  axios
    .delete(
      `${API_URL}${transformRoute(ROUTES.resetToPrescreeningPortfolioSettings, {
        propertyId,
      })}`
    )
    .then(({ data }) => data);
export const getPortfolioDealSetup = ({ portfolioId }) =>
  axios.get(
    `${API_URL}${transformRoute(ROUTES.portfolioDealSetup, { portfolioId })}`
  );
export const toggleRequiredQuestionActive = ({ id, requiredQuestionsId }) =>
  axios.patch(
    `${API_URL}${transformRoute(ROUTES.toggleRequiredQuestionActive, {
      id,
    })}`,
    {
      requiredQuestionsId,
    }
  );
export const enableLROSettings = ({ id, enable }) =>
  axios
    .patch(`${API_URL}${transformRoute(ROUTES.enableLROConfig, { id })}`, {
      enable,
    })
    .then(({ data }) => data);
export const updateLROSettings = ({ id, enabled }) =>
  axios
    .post(
      `${API_URL}${transformRoute(ROUTES.updateLROConfig, { id })}`,
      enabled
    )
    .then(({ data }) => data);
