import { LumenResponse } from "manager/lib/lumenApi";

import {
  ApplicationStep,
  DEAL_UI_STATUS_CODES,
  PersonaIdClass,
} from "shared/config/constants";

import { CountyCriminal } from "./criminal/interfaces";
import { Dependent } from "./dependants/interfaces";
import { Fees } from "./fees/interfaces";
import { AppPermission, Permission } from "./permissions/interfaces";
import { Property } from "./property/interfaces";
import { Unit } from "./unit/interfaces";

export type Step = {
  completed?: boolean | null;
  step: ApplicationStep;
};

export type GroupStep = {
  group: string;
  completed: boolean | null;
  steps: Step[];
};

export enum ArgyleStatus {
  NOT_SYNCED = "NOT_SYNCED",
  SHOULD_SYNC = "SHOULD_SYNC",
  SYNC_IN_PROGRESS = "SYNC_IN_PROGRESS",
  SYNC_COMPLETED = "SYNC_COMPLETED",
  SYNC_FAILED = "SYNC_FAILED",
  OCR_PROCESSING = "OCR_PROCESSING",
}

export enum ResultScoreEnum {
  STILL_CALCULATING = "STILL_CALCULATING",
}

export enum LumenScore {
  EXCELLENT = "EXCELLENT",
  NEGATIVE = "NEGATIVE",
}

interface Agent {
  id: string;
  firstName?: string;
  lastName?: string;
  name?: string;
}

export interface Applicant {
  id: string | number;
  firstName: string;
  lastName: string;
  middleName?: string;
  email: string;
  phone?: string;
  type: ApplicantType;
  isStudent: boolean;
  isForeigner: boolean;
  isSelfEmployed: boolean;
  income?: ApplicantIncomeSummary;
  balances?: ApplicantBalance;
  credit?: ApplicantCreditScore;
  criminal?: ApplicantCriminalSummary;
  employment?: ApplicantEmployment;
  eviction?: ApplicantEviction;
  identity?: ApplicantIdentity;
  verificationOfResidency?: ApplicantVor;
  applicantVrn?: string;
}

export interface ApplicantBalance {
  verified?: boolean;
  skipped?: boolean;
  skippedReason?: string;
  availableCash?: number;
  currentBalance?: number;
  bankAccounts?: BankAccount[];
}

export interface BankAccount {
  accountType: string;
  availableBalance?: number;
  currentBalance: number;
  institutionName: string;
  name: string;
}

export interface ApplicantCreditScore {
  skipped?: boolean;
  skippedReason?: string;
  creditScore?: number;
  latePayment30?: number;
  latePayment60?: number;
  latePayment90?: number;
  creditBalance?: number;
  creditLimit?: number;
  collectionAccounts?: number;
  collectionTotal?: number;
}

export interface ApplicantCriminalSummary {
  records?: number;
  highestSeverity?: string;
  lastRecordDate?: Date;
  lastRecordState?: string;
}

export interface ApplicantEmployment {
  enabled?: boolean;
  jobTitle?: string;
  employed?: boolean;
  company?: string;
  start?: Date;
  end?: Date;
  skipped?: boolean;
  verificationTypes?: VerificationType[];
}
export interface ApplicantEviction {
  enabled?: boolean;
  skipped?: boolean;
  records?: number;
  judgmentAmount?: number;
  lastRecord?: Date;
  lastRecordState?: string;
}

export interface ApplicantIdentity {
  enabled?: boolean;
  identityType?: string;
  documentType?: PersonaIdClass;
  verified?: boolean;
  identityNumber?: string;
  country?: string;
  expires?: Date;
}

export interface ApplicantIncomeSummary {
  totalIncome?: number;
  percentVerified?: number;
  skipped?: boolean;
  skippedReason?: string;
  primary?: Income;
  stated?: number;
}

export interface Income {
  income: number;
  name: string;
  status: string;
  lastPayment: Date;
  frequency: string;
}

export enum VorStatus {
  waiting = "waiting",
  notified = "notified",
  submitted = "submitted",
  verified = "verified",
}

export enum ApplicantType {
  unknown = "unknown",
  applicant = "applicant",
  guarantor = "guarantor",
  occupant = "occupant",
  dependent = "dependent",
}

export enum VerificationType {
  LINKED_EMPLOYMENT = "LINKED_EMPLOYMENT",
  DOCUMENT_UPLOADED = "DOCUMENT_UPLOADED",
  LINK_BANK_ACCOUNT = "LINK_BANK_ACCOUNT",
  MANUAL_UPLOAD = "MANUAL_UPLOAD",
  UNEMPLOYED = "UNEMPLOYED",
}

export interface ApplicantVor {
  enabled?: boolean;
  score?: number;
  status?: VorStatus;
  outOf?: number;
  landlordName?: string;
  landlordEmail?: string;
  landlordPhone?: string;
  submission?: Date;
  lowestScore?: number;
}

export interface App {
  id: string | number;
  isPrimary?: boolean;
  hideCriminalHistory?: boolean;
  fraudIndicators?: string[];
  isSubmitted: boolean;
  applicant: Applicant;
  step?: ApplicationStep;
  inviteLink?: string;
  permission?: AppPermission;
  isBlocked?: boolean;
  hasConditionallyProceedEnabled?: boolean;
  isConditionallyProceeded?: boolean;
  argyleStatus?: ArgyleStatus;
  lumenEvaluates?: string;
  verifiedIncome?: number;
  totalIncome?: number;
  payments?: any[];
  applicantVrn?: string;
  isCriminalComplete?: boolean;
  hasUnavailableBackgroundReports?: boolean;
  personVrn?: string;
  location?: string;
  party?: string;
  snapshot?: any;
}

export interface Lumen extends Pick<LumenResponse, "messages" | "score"> {}

export interface Yardi {
  pCode?: string;
  tCode?: string;
}

interface LumenRecords {
  conditions: {
    conditionSubType: string;
    conditionType: string;
    timeOperator: string;
    timeUnit: string;
    timeValue: number;
  }[];
  formulaRevision: number;
  id: number;
  mode: string;
  type: string;
}

export interface LumenRevision {
  id: number;
  deleted: Date;
  createdAt: Date;
  updatedAt: Date;
  status: string;
  isCurrentRevision: boolean;
  useCreditScore: boolean;
  useIncomeRentRatio: boolean;
  useAssets: boolean;
  useCollections: boolean;
  useCriminalRecords: boolean;
  useHousingCourtRecords: boolean;
  useVerifications: boolean;
  minCreditScore: number;
  idealCreditScore: number;
  creditScoreScopeWithGuarantors: string;
  creditRecordsScope: string;
  useCreditRecordSafetyNet: boolean;
  incomeRentRatioPeriod: string;
  minIncomeRentRatio: number;
  idealIncomeRentRatio: number;
  incomeRentRatioWithGuarantors: string;
  minIncomeRentRatioWithGuarantor: any;
  idealIncomeRentRatioWithGuarantor: any;
  totalIncomeWithGuarantor: string;
  cashRunwayRatioWithGuarantors: string;
  minCashRunwayRatioWithGuarantor: any;
  idealCashRunwayRatioWithGuarantor: any;
  incomeConsidered: string;
  minAssetsRatio: any;
  idealAssetsRatio: any;
  totalAssetsWithGuarantor: any;
  maxCollectionsAmount: any;
  useCollectionRecordSafetyNet: boolean;
  useCriminalRecordSafetyNet: boolean;
  useHousingCourtRecordSafetyNet: boolean;
  verifyIdentity: boolean;
  lumenFormula: number;
  employee: number;
  filterCollectionRecords: LumenRecords[];
  filterCreditPublicRecords: LumenRecords[];
  filterCriminalRecords: LumenRecords[];
  filterHousingCourtRecords: LumenRecords[];
  validationStatus: {
    assets: string;
    collectionRecords: string;
    credit: string;
    criminalRecords: string;
    housingCourtRecords: string;
    income: string;
    verifications: string;
  };
}

export interface LastLumenRun {
  id: number;
  deleted: Date;
  createdAt: Date;
  updatedAt: Date;
  executedAt: Date;
  endedAt: Date;
  status: string;
  score: string;
  totalIncomeAmount: number;
  incomeToRentRatio: string;
  totalAssetsAmount: number;
  cashRunwayRatio: string;
  totalLeaseAmount: number;
  deal: number;
}

type FraudIndicator = {
  name: string;
  description: string;
};

export interface Deal {
  id: string | number;
  companySlug?: string;
  applications: App[];
  dependents: Dependent[];
  status: DEAL_UI_STATUS_CODES;
  agent: Agent;
  unit?: Unit;
  property?: Property;
  fees: Fees;
  lumenEnabled: boolean;
  lumen?: any;
  moveInDate?: Date;
  countyCriminal?: CountyCriminal;
  permission: Permission;
  yardi?: Yardi;
  conditionalProceed: boolean;
  isApproved: boolean;
  isMagicDeal?: boolean;
  isOpenApiDeal?: boolean;
  isArchived?: boolean;
  lumenRevision?: LumenRevision;
  applicantsSummaryShort?: {
    id: string | number;
    skippedBgcc: boolean;
  }[];
  lumenResult?: LumenResponse;
  canManuallySendToPms?: boolean;
  lastSentToPmsAt?: Date;
  fraudIndicators?: FraudIndicator[];
}
