import styled from "@emotion/styled";

import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import Well from "shared/components/Well/Well";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

const StyledMarketplace = styled.div`
  .ant-col {
    ${MEDIA_QUERIES.w.ltSm} {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }
`;

const MarketplaceBanner = styled(Well)`
  background-color: ${COLORS.primary};
  color: ${COLORS.lightGray};
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  z-index: 1;

  .banner-icon {
    margin-right: 18px;

    ${MEDIA_QUERIES.w.ltMd} {
      display: none;
    }
  }
`;

const YardiLogo = styled.img`
  padding-top: 13px;

  ${MEDIA_QUERIES.w.ltSm} {
    padding-top: 0px;
    width: 95%;
    max-width: 130px;
  }
`;

const BluemoonLogoWrapper = styled.img`
  padding-top: 13px;

  ${MEDIA_QUERIES.w.ltSm} {
    padding-top: 0px;
    width: 95%;
    max-width: 154.51px;
  }
`;

const TitleWrapper = styled(FlexContainer)`
  .health-check-status {
    height: 24px;
  }
`;

const ContentWrapper = styled(FlexContainer)`
  div {
    max-width: 330px;
  }

  .status-select-icon {
    width: 50px;

    ${MEDIA_QUERIES.w.gteSm} {
      margin-bottom: 90px;
    }
  }
`;

const ArrowIcon = styled(Icon.SelectArrowIcon)`
  width: 50px;
  path {
    stroke: ${COLORS.violetTheme.lightViolet};
  }
`;

export {
  MarketplaceBanner,
  StyledMarketplace,
  YardiLogo,
  BluemoonLogoWrapper,
  TitleWrapper,
  ContentWrapper,
  ArrowIcon,
};
