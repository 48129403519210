import React from "react";

import { Modal } from "shared/components/Modal";
import { RegularText } from "shared/components/Typography";

import { CreatePropertySuccessModalProps } from "./interfaces";

const CreatePropertySuccessModal = ({
  submit,
  name,
}: CreatePropertySuccessModalProps) => (
  <Modal
    title="Congratulations!"
    submit={submit}
    cancelLinkLabel="I'll do it later"
    submitButtonLabel="Create Unit"
  >
    <Modal.Body>
      <RegularText data-testid="confirmationMessage">
        <RegularText strong>{name}</RegularText> has been successfully created.
        Please create your unit listings or contact your Account Manager to
        onboard your units.
      </RegularText>
    </Modal.Body>
  </Modal>
);

export default CreatePropertySuccessModal;
