import React from "react";

import isNumber from "lodash/isNumber";

import { TAG_COLORS } from "shared/components/Tag";
import Tooltip from "shared/components/Tooltip";
import { VERIFICATION_STATUSES } from "shared/config/constants";

import { StyledApplicationDataTag } from "./styled";

const getTagColorFromVerificationStatus = (
  status,
  skipped = false,
  percentageVerified
) => {
  let color = null;

  if (status === VERIFICATION_STATUSES.EDITED) {
    color = null;
  } else if (skipped) {
    color = TAG_COLORS.ORANGE;
  } else if (status === VERIFICATION_STATUSES.VERIFIED) {
    color = TAG_COLORS.GREEN;
  } else if (status === VERIFICATION_STATUSES.UNVERIFIED) {
    color = TAG_COLORS.RED;
    if (isNumber(percentageVerified) && percentageVerified > 0) {
      color = TAG_COLORS.ORANGE;
    }
  }

  return color;
};

const getTagText = (status, skipped, percentageVerified, skippedLabel) => {
  let tagText = status || "Unknown";

  if (skipped && status !== VERIFICATION_STATUSES.EDITED) {
    tagText = skippedLabel || "Skipped";
  } else if (status === VERIFICATION_STATUSES.VERIFIED) {
    tagText = isNumber(percentageVerified)
      ? `${percentageVerified}% VEROfied`
      : "VEROfied";
  } else if (
    status === VERIFICATION_STATUSES.UNVERIFIED &&
    isNumber(percentageVerified)
  ) {
    tagText = `${percentageVerified}% VEROfied`;
  }

  return tagText;
};

interface ApplicationDataTagProps {
  verificationStatus?:
    | typeof VERIFICATION_STATUSES.VERIFIED
    | typeof VERIFICATION_STATUSES.UNVERIFIED
    | typeof VERIFICATION_STATUSES.EDITED;
  verificationSkipped?: boolean;
  percentageVerified?: number;
  skippedMessage?: string;
  skippedLabel?: string;
}

const ApplicationDataTag = ({
  verificationStatus,
  verificationSkipped,
  skippedMessage,
  skippedLabel,
  percentageVerified,
}: ApplicationDataTagProps) => {
  // NOTE: in case of Argyle/Lynk, the I&B verification is skipped, but the income is 100% verified by Argyle
  const isSkipped =
    verificationSkipped &&
    verificationStatus !== VERIFICATION_STATUSES.VERIFIED;
  const showTag = verificationStatus || isSkipped;
  const showPopover =
    !!isSkipped &&
    !!skippedMessage &&
    verificationStatus !== VERIFICATION_STATUSES.EDITED;

  return showTag ? (
    <Tooltip title={showPopover ? skippedMessage : ""}>
      <StyledApplicationDataTag
        data-testid="application-data-tag"
        color={getTagColorFromVerificationStatus(
          verificationStatus,
          isSkipped,
          percentageVerified
        )}
        size="sm"
      >
        {getTagText(
          verificationStatus,
          isSkipped,
          percentageVerified,
          skippedLabel
        )}
      </StyledApplicationDataTag>
    </Tooltip>
  ) : null;
};

export default ApplicationDataTag;
