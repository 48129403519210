export const COLORS = {
  // Ant D overrides
  primary: "#2D2943",
  secondary: "#807E8D",
  checkbox: "#05CE88",
  radio: {
    default: "#05CE88",
    disabled: "#BFBEC6",
  },
  itemActiveBg: "#F9FAFC",
  layoutBodyBackground: "#F3F4F9",
  inputHoverBorderColor: "#BDC9D5",
  borderColorBase: "#DEDCE5",
  textColorPrimary: "#2D2943",
  textColorSecondary: "#807E8D",
  tooltipBg: "#2D2943",
  // Custom variables
  accent: "#05CE88",
  accentDark: "#03AB70",
  background: "#F3F4F9",
  borders: "#E8E7EE",
  innerBorders: "#F2F2F5",
  boxShadow: "0px 1px 8px rgba(126, 153, 168, 0.4)",
  boxShadowLight: "rgba(0, 0, 0, 0.05)",
  boxShadowDeep: "0px 8px 10px 0px #78739540",
  warning: "#E17251",
  numberTitle: {
    number: {
      background: "#F1F4F7",
    },
  },
  badge: {
    default: {
      background: "#05CE88",
      text: "#FFF",
    },
    textOnly: {
      text: "#03AB70",
    },
  },
  actionLink: {
    default: "#03AB70",
    hover: "#2D2943",
  },
  underlineLink: {
    default: {
      text: "#2D2943",
      borderBottom: "#BFBEC6",
    },
    hover: {
      text: "#03AB70",
      borderBottom: "#03AB70",
    },
    light: {
      text: "#FFFFFF",
      borderBottom: "#BFBEC6",
    },
    lightHover: {
      borderBottom: "#FFFFFF",
      text: "#FFFFFF",
    },
  },
  backLink: {
    default: "#8052D0",
    hover: "#2D2943",
  },
  sortOrder: {
    hover: "#03AB70",
  },
  checkboxList: {
    field: {
      label: {
        text: "#807E8D",
      },
    },
  },
  input: {
    default: {
      placeholder: "#807E8D",
      border: "#DEDCE5",
      text: "#2D2943",
    },
    hover: {
      border: "#BDC9D5",
    },
    focus: {
      border: "#807E8D",
      text: "#2D2943",
    },
    invalid: {
      border: "#D8362F",
      placeholder: "#D8362F",
      message: "#D8362F",
    },
    disabled: {
      background: "#F4F5F9",
      text: "#46435A",
      placeholder: "#A19EAE",
    },
  },
  select: {
    icon: "#2D2943",
    input: {
      hover: {
        border: "#BDC9D5",
      },
      open: {
        borderBottom: "#E8E7EE",
      },
    },
    option: {
      background: "#FFF",
      text: "#807E8D",
      hover: {
        background: "#F9FAFC",
        text: "#2D2943",
      },
    },
  },
  button: {
    primary: {
      default: {
        background: "#2D2943",
        innerBorder: "#46435A",
      },
      hover: {
        background: "#46435A",
        innerBorder: "#646175",
      },
      disabled: {
        background: "#BFBEC6",
        text: "#FFF",
      },
    },
    secondary: {
      default: {
        border: "#2D2943",
      },
      hover: {
        text: "#46435A",
        border: "#46435A",
      },
    },
    success: {
      default: {
        background: "#28BF8C",
        text: "#FFF",
      },
      hover: {
        background: "#19AB7A",
      },
    },
    danger: {
      default: {
        background: "#E26982",
        text: "#FFF",
      },
      hover: {
        background: "#D85B75",
      },
    },
    warning: {
      default: {
        background: "#FFF",
        text: "#E99377",
        border: "#E99377",
      },
      hover: {
        border: "#CC6C4D",
        text: "#C95937",
      },
    },
    transparent: {
      blue: {
        default: {
          background: "transparent",
          text: "#18A4EF",
        },
        hover: {
          text: "#22E295",
        },
      },
      green: {
        default: {
          background: "transparent",
          text: "#22E295",
        },
        hover: {
          text: "#18A4EF",
        },
      },
    },
  },
  progress: {
    approved: "#28BF8C",
    conditionalApprove: "#E17251",
    declined: "#E0637D",
  },
  table: {
    default: {
      header: {
        background: "#FFF",
      },
      row: {
        default: {
          hover: "#FFF",
          background: "#FFF",
        },
        viewed: {
          background: "#F9FAFC",
        },
      },
    },
    alt: {
      header: {
        background: "#F9FAFC",
      },
    },
  },
  typography: {
    text: "#807E8D",
  },
  tabs: {
    background: "#FFF",
    hover: "#2D2943",
    inkBar: "#05CE88",
  },
  footerBar: {
    background: "#FFF",
    boxShadow: "rgba(126, 153, 168, 0.75)",
  },
  dropdownMenu: {
    background: "#2D2943",
    text: "#FFF",
    item: {
      hover: {
        background: "#46435A",
      },
    },
  },
  tile: {
    default: {
      background: "#FFF",
      text: "#807e8d",
      border: "#E8E7EE",
      innerBorder: "#E8E7EE",
    },
    alt: {
      background: "#443D6D",
      innerBackground: "#524986",
      text: "#FFF",
      border: "transparent",
      innerBorder: "#86849A",
    },
    sec: {
      background: "#413c5f",
      border: "transparent",
    },
    accent: {
      headerBackground: "#F3F4F9",
    },
    inset: {
      background: "#F9FAFC",
    },
  },
  tooltip: {
    text: "#FFFFFF",
  },
  header: {
    background: "#FFFFFF",
  },
  scrollToTopButton: {
    background: "#FFF",
  },
  notificationList: {
    item: {
      viewed: {
        background: "#F9FAFC",
      },
    },
  },
  notificationPopover: {
    header: {
      background: "#F9FAFC",
    },
  },
  popover: {
    default: {
      header: {
        background: "#FFF",
      },
    },
    gray: {
      header: {
        background: "#F9FAFC",
      },
    },
  },
  label: {
    primary: {
      background: "#F1F4F7",
      fontWeight: "500px",
    },
    secondary: {
      background: "transparent",
      fontWeight: "300px",
    },
  },
  notification: {
    info: {
      background: "#ACE3D1",
    },
    warning: {
      background: "#FFEDE5",
    },
    error: {
      background: "#FFCCC7",
    },
  },
  datePicker: {
    calendar: {
      day: {
        default: {
          background: "#05CE88",
          border: "#05CE88",
          text: "#FFFFFF",
        },
        hover: {
          background: "#F9FAFC",
        },
      },
    },
  },
  sidebar: {
    image: {
      border: "#E8E7EE",
    },
    menu: {
      selected: "#05CE88",
    },
  },
  tag: {
    default: {
      background: "#E8E7EE",
      text: "#2D2943",
    },
    green: {
      background: "#D8F3EA",
    },
    red: {
      background: "#FAE4E9",
    },
    violet: {
      background: "#595475",
    },
    orange: {
      background: "#FFE4CC",
    },
  },
  timeline: {
    plot: {
      head: "#BFBEC6",
      tail: "#BFBEC6",
    },
    date: "#807E8D",
    description: {
      border: "#E8E7EE",
      background: "#F9FAFC",
      title: "#807E8D",
      message: "#2D2943",
    },
  },
  list: {
    background: "#FFF",
    border: "#E8E7EE",
  },
  bulletList: {
    gray: "#bfbec6",
  },
  searchableList: {
    header: {
      background: "#F9FAFC",
    },
  },
  creditScore: {
    scores: {
      veryBad: "#ED7777",
      bad: "#ED7777",
      fair: "#EDBE77",
      good: "#00D93D",
      excellent: "#00D93D",
    },
    indicator: "#2D2943",
  },
  paymentHistory: {
    onTime: "#28BF8C",
    late30Days: "#FFCC16",
    late60Days: "#F28D18",
    late90Days: "#F54141",
    late120Days: "#565656",
    unclassified: "#D7D7D7",
    month: "#807E8D",
    legend: "#807E8D",
  },
  menu: {
    item: {
      default: {
        text: "#2D2943",
      },
      active: {
        background: "#F9FAFC",
        border: "#05CE88",
      },
    },
  },
  filterBar: {
    default: {
      background: "#FFF",
    },
    alt: {
      background: "#F9FAFC",
    },
  },
  uploadWall: {
    upload: {
      background: "#FFF",
    },
  },
  progressBar: {
    background: "#E8E7EE",
    progress: "#05CE88",
  },
  checkboxTable: {
    borders: "#E8E7EE",
    header: {
      background: "#FFF",
    },
    category: {
      background: "#F9FAFC",
    },
    body: {
      background: "#FFF",
    },
  },
  avatar: {
    background: "#FFF",
  },
  steps: {
    dot: "#05CE88",
    tail: "#05CE88",
    title: "#2D2943",
    unfinished: "#E8E7EE",
  },
  well: {
    border: "#dedce5",
    background: "#f9fafc",
    backgroundError: "#FFCCC7",
    backgroundLightError: "#FCEEEE",
    backgroundDark: "#E8E7EE",
  },
  stepsWheel: {
    wheel: {
      background: "#FFF",
      progressIncomplete: "#E8E7EE",
      progressComplete: "#05CE88",
    },
  },
  incomeAssets: {
    verified: "#28BF8C",
    notVerified: "#FF8C6A",
  },
  leasePreview: {
    background: "#151323",
    documentInfo: {
      background: "#2D2943",
      title: "#EFEFEF",
      primaryText: "#B2AFC8",
      secondaryText: "#FFFFFF",
      row: "#151323",
    },
  },
  dashboard: {
    headerBackground: "#2D2943",
    lightViolet2: "#D0B8F9",
    stackedBarChart: {
      lightViolet1: "#524986",
      lightViolet2: "#8052D0",
      lightViolet3: "#A374F5",
      lightViolet4: "#D0B8F9",
    },
  },
  violetTheme: {
    text: "#C8C4E1",
    backgroundColor: "#412878",
    backgroundColorDeep: "#371E6C",
    backgroundColorDark: "#141220",
    emptyIcon: "#695FA7",
    stepsWheel: "#311866",
    deepBlueViolet: "#151323",
    blueViolet: "#413B5F",
    lightViolet: "#A374F5",
    lightVioletButton: "#8A54E9",
    lightBlueViolet: "#B2AFC8",
    lightGrey: "#EFEFEF",
    green: "#05CE88",
    lightGreen: "#22E295",
    white: "#FFFFFF",
    border: "#9D6AF5",
    headerBackground: "#3D3663",
    lightViolet2: "#8052D0",
    lightViolet3: "#C2A2F8",
  },
  lightVioletTheme: {
    stepsWheel: "#8052D0",
  },
  grayTheme: {
    backgroundColor: "#F3F4F9",
    textColor: "#787395",
    hoverTextColor: "#C8C4E1",
    activeTextColor: "#2D2943",
    hoverActiveTextColor: "#695FA7",
  },
  addTagInput: {
    border: "#323232",
    label: "#807E8D",
    background: "#333333",
  },
  pdfTag: {
    background: "#676767",
  },
  incomeVerification: {
    title: "#E0D1FC",
  },
  lightGray: "#F1F1F9",
  lightGray1: "#ECEEF2",
  lightGray2: "#F9FAFC",
  lightGray3: "#F5FCFE",
  lightGray4: "#E8E7EE",
  gray1: "#BFBEC6",
  gray2: "#787395",
  gray3: "#B0BBC6",
  gray4: "#EEEDF2",
  disabledText: "#B2AFC8",
  midGray: "#D8D7E3",
  darkGrayShadow: "#7E99A8BF",
  orange: "#FFAF66",
  lightOrange: "#FFEDE5",
  lightOrange2: "#FFE1CC",
  midOrange: "#FFA37F",
  darkOrange: "#EE7200",
  lightGreen: "#EBFBF5",
  lightGreen2: "#22E295",
  lightGreen3: "#D9F7EB",
  darkGreen: "#0B5638",
  green: "#05CE88",
  lightRed: "#F9E1E0",
  lightRed2: "#E26982",
  lightViolet: "#423973", // "#524986",
  darkViolet: "#453D70",
  darkViolet2: "#3D3663",
  darkViolet3: "#403A5F",
  red: "#D8362F",
  dangerRed: "#E26982",
  failedRed: "#B92443",
  excludedRed: "#A20B2E",
  excludedOrange: "#FFAD61",
  white: "#FFFFFF",
  backgroundGrey: "#A0ABB6",
  backgroundGrey2: "#F3F4F9",
  blueViolet: "#6841BA",
  lightPurple: "#787395",
  backgroundLightOrange: "#FFE4CC",
  black: "#1A1825",
  lightGrayBoxShadow: "rgba(45, 41, 67, 0.1)",
  googleButtonLabel: "rgba(0, 0, 0, 0.54)",
  facebookButtonLabel: "#1877F2",
  linkedInButtonLabel: "#2867B2",
  darkBlue: "#0E8ACD",
  lightBlue: "#18A4EF",
  purple: "#A374F5",
  darkPurple: "#908BAD",
  darkYellow: "#773900",
  darkRed: "#5C1221",
  lightViolet1: "#524986",
};

export const BREAKPOINT_RESOLUTIONS = {
  xs: 576,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1280,
  xxl: 1400,
  xxxl: 1600,
};

const queryBase = `@media all and`;
const ltQuery = (resolution, orientation) =>
  `${queryBase} (max-${orientation}: ${resolution - 1}px)`;
const gteQuery = (resolution, orientation) =>
  `${queryBase} (min-${orientation}: ${resolution}px)`;
const btwQuery = (res1, res2, orientation) =>
  `${queryBase} (min-${orientation}: ${res1}px) and (max-${orientation}: ${
    res2 - 1
  }px)`;

export const ltQueryW = (resolution) => ltQuery(resolution, "width");
export const gteQueryW = (resolution) => gteQuery(resolution, "width");
export const btwQueryW = (res1, res2) => btwQuery(res1, res2, "width");
export const ltQueryH = (resolution) => ltQuery(resolution, "height");
export const gteQueryH = (resolution) => gteQuery(resolution, "height");
export const btwQueryH = (res1, res2) => btwQuery(res1, res2, "height");

export const MEDIA_QUERIES = {
  w: {
    ltSm: ltQueryW(BREAKPOINT_RESOLUTIONS.sm),
    ltMd: ltQueryW(BREAKPOINT_RESOLUTIONS.md),
    ltLg: ltQueryW(BREAKPOINT_RESOLUTIONS.lg),
    ltXl: ltQueryW(BREAKPOINT_RESOLUTIONS.xl),
    ltXxl: ltQueryW(BREAKPOINT_RESOLUTIONS.xxl),
    gteSm: gteQueryW(BREAKPOINT_RESOLUTIONS.sm),
    gteMd: gteQueryW(BREAKPOINT_RESOLUTIONS.md),
    gteLg: gteQueryW(BREAKPOINT_RESOLUTIONS.lg),
    gteXl: gteQueryW(BREAKPOINT_RESOLUTIONS.xl),
    gteXxl: gteQueryW(BREAKPOINT_RESOLUTIONS.xxl),
    gteXxxl: gteQueryW(BREAKPOINT_RESOLUTIONS.xxxl),
    btwSmMd: btwQueryW(BREAKPOINT_RESOLUTIONS.sm, BREAKPOINT_RESOLUTIONS.md),
    btwMdLg: btwQueryW(BREAKPOINT_RESOLUTIONS.md, BREAKPOINT_RESOLUTIONS.lg),
    btwMdXl: btwQueryW(BREAKPOINT_RESOLUTIONS.md, BREAKPOINT_RESOLUTIONS.xl),
    btwLgXl: btwQueryW(BREAKPOINT_RESOLUTIONS.lg, BREAKPOINT_RESOLUTIONS.xl),
    btwXlXxl: btwQueryW(BREAKPOINT_RESOLUTIONS.xl, BREAKPOINT_RESOLUTIONS.xxl),
  },
  h: {
    ltSm: ltQueryH(BREAKPOINT_RESOLUTIONS.sm),
    ltMd: ltQueryH(BREAKPOINT_RESOLUTIONS.md),
    ltLg: ltQueryH(BREAKPOINT_RESOLUTIONS.lg),
    ltXl: ltQueryH(BREAKPOINT_RESOLUTIONS.xl),
    ltXxl: ltQueryH(BREAKPOINT_RESOLUTIONS.xxl),
    gteSm: gteQueryH(BREAKPOINT_RESOLUTIONS.sm),
    gteMd: gteQueryH(BREAKPOINT_RESOLUTIONS.md),
    gteLg: gteQueryH(BREAKPOINT_RESOLUTIONS.lg),
    gteXl: gteQueryH(BREAKPOINT_RESOLUTIONS.xl),
    gteXxl: gteQueryH(BREAKPOINT_RESOLUTIONS.xxl),
    btwSmMd: btwQueryH(BREAKPOINT_RESOLUTIONS.sm, BREAKPOINT_RESOLUTIONS.md),
    btwMdLg: btwQueryH(BREAKPOINT_RESOLUTIONS.md, BREAKPOINT_RESOLUTIONS.lg),
    btwMdXl: btwQueryH(BREAKPOINT_RESOLUTIONS.md, BREAKPOINT_RESOLUTIONS.xl),
    btwLgXl: btwQueryH(BREAKPOINT_RESOLUTIONS.lg, BREAKPOINT_RESOLUTIONS.xl),
    btwXlXxl: btwQueryH(BREAKPOINT_RESOLUTIONS.xl, BREAKPOINT_RESOLUTIONS.xxl),
  },
};

export const DIMENSIONS = {
  header: {
    gteMd: {
      height: "64px",
    },
    ltMd: {
      height: "50px",
    },
  },
  sidebar: {
    gteXl: {
      width: "150px",
      widthInt: 150,
    },
    ltXl: {
      width: "60px",
      widthInt: 60,
    },
  },
};

export const TRANSITIONS = {
  default: "all 0.1s ease-out",
};

export const ANTD_LATEST_PREFIX = `ant-latest`;

export const Z_INDEX = {
  dropdownMenu: 600,
  submitApplicationFooterContainer: 500,
  fullScreenLoader: 700,
  container: 1,
  sidebar: 99,
  loader: 100,
  phoneNumberInputFlagDropdown: 4,
};
