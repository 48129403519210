import React from "react";

import pluralize from "pluralize";

import Icon from "shared/components/Icon";
import { Modal } from "shared/components/Modal";
import RadioGroupBoxedList from "shared/components/RadioGroupBoxedList";

import { AssignEmployeeToPropertiesSuccessModalProps } from "./interfaces";
import { AssignEmployeeSuccessWrapper } from "./styled";

const AssignEmployeeToPropertiesSuccessModal = ({
  employeeName,
  listTitle,
  listItems = [],
  currentSelection,
  onChangeSelection,
  numNewProperties,
  onSubmit,
}: AssignEmployeeToPropertiesSuccessModalProps) => (
  <Modal
    submit={onSubmit}
    closable={false}
    showCancelLink={false}
    submitButtonLabel="CONFIRM"
    submitButtonDisabled={!currentSelection}
  >
    <Modal.Body noPaddingTop>
      <AssignEmployeeSuccessWrapper>
        <Icon.OperationSuccess />
        <p className="modal-description">
          You&apos;ve successfully assigned {employeeName} to {numNewProperties}{" "}
          new {pluralize("property", numNewProperties)}:
        </p>
        <RadioGroupBoxedList
          listTitle={listTitle}
          currentSelection={currentSelection}
          onChangeSelection={onChangeSelection}
          listItems={listItems}
        />
      </AssignEmployeeSuccessWrapper>
    </Modal.Body>
  </Modal>
);

export default AssignEmployeeToPropertiesSuccessModal;
