import React from "react";

const NoUnitsFoundNotification = () => (
  <div className="text-centered">
    Oops! We were not able to find any results matching your search criteria.
    <div>Please re-apply filters to view unit availability.</div>
  </div>
);

export default NoUnitsFoundNotification;

const NoUnitsFoundNotificationMessage = <NoUnitsFoundNotification />;

export { NoUnitsFoundNotificationMessage };
