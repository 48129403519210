import { queryCache, useMutation, useQuery } from "react-query";

import { useRequestExport } from "manager/hooks/api/commonQueries";
import managerApi from "manager/lib/api";
import { NOTIFICATIONS } from "shared/config/constants";
import useQueryErrorHandler from "shared/hooks/useQueryErrorHandler";
import api from "shared/lib/api";
import { openNotification } from "shared/utils/ui";

import { PROPERTY_QUERY_KEYS } from "./propertyQueries";

const UNIT_QUERIES = Object.freeze({
  getUnit: "getUnit",
  getUnits: "getUnits",
  getUnitFees: "getUnitFees",
  getPropertyUnitsTable: "getPropertyUnitsTable",
  getAllUnitsSimple: "getAllUnitsSimple",
  getUnitsListForDealAssignment: "getUnitsListForDealAssignment",
  getPropertyUnitsDropdown: "getPropertyUnitsDropdown",
});

const invalidateCacheAfterUnitChange = () => {
  queryCache.invalidateQueries([UNIT_QUERIES.getPropertyUnitsTable]);
  queryCache.invalidateQueries([UNIT_QUERIES.getUnits]);
  queryCache.invalidateQueries([UNIT_QUERIES.getAllUnitsSimple]);
  queryCache.invalidateQueries([UNIT_QUERIES.getUnitsListForDealAssignment]);
  queryCache.invalidateQueries(PROPERTY_QUERY_KEYS.getProperties());
};

const useGetUnit = (unitId) => {
  const queryErrorHandler = useQueryErrorHandler();

  const {
    data: unit,
    isLoading,
    isFetching,
  } = useQuery(
    [UNIT_QUERIES.getUnit, unitId],
    () => api.getUnit({ id: unitId }).then(({ data }) => data),
    {
      enabled: unitId,
      onError: (error) => queryErrorHandler(error, "Failed to load unit"),
    }
  );

  return { unit, isLoading, isFetching };
};

const useGetUnitFees = (location) => {
  const queryErrorHandler = useQueryErrorHandler();

  const {
    data: fees,
    isLoading,
    isFetching,
  } = useQuery(
    [UNIT_QUERIES.getUnitFees, location],
    () => api.getUnitFees(location).then(({ data }) => data),
    {
      enabled: location,
      onError: (error) => queryErrorHandler(error, "Failed to load unit"),
    }
  );

  return { fees, isLoading, isFetching };
};

const useGetUnits = (propertyId) => {
  const { data: units, isLoading } = useQuery(
    [UNIT_QUERIES.getUnits, propertyId],
    () => api.getUnits({ propertyId }).then(({ data }) => data),
    {
      enabled: propertyId,
      onError: () => {
        openNotification("Failed to load units", NOTIFICATIONS.error);
      },
    }
  );

  return { units: units || [], isLoading };
};

const useGetPropertyUnits = (propertyId, filters) => {
  const {
    data: units,
    isLoading: isUnitsLoading,
    refetch: refetchUnits,
    isFetching: isUnitsFetching,
  } = useQuery(
    [UNIT_QUERIES.getPropertyUnitsTable, propertyId, filters],
    () => api.getUnits({ propertyId, ...filters }).then(({ data }) => data),
    {
      enabled: propertyId,
      onError: () => {
        openNotification("Failed to load property units", NOTIFICATIONS.error);
      },
    }
  );

  return { units, isUnitsLoading, isUnitsFetching, refetchUnits };
};

const useGetAllUnitsSimple = (propertyId) => {
  const {
    data: units,
    isLoading,
    isFetching,
  } = useQuery(
    [UNIT_QUERIES.getAllUnitsSimple, propertyId],
    () => api.getAllUnitsSimple({ propertyId }).then(({ data }) => data),
    {
      enabled: propertyId,
      onError: () => {
        openNotification("Failed to load units", NOTIFICATIONS.error);
      },
    }
  );

  return { units: units || [], isLoading: isLoading || isFetching };
};

const useGetUnitsListForProperty = ({ propertyId }, config) => {
  const {
    data: units,
    isLoading,
    isFetching,
  } = useQuery(
    [UNIT_QUERIES.getUnitsListForDealAssignment, propertyId],
    () =>
      api
        .getUnitsListForDealAssignment({ propertyId })
        .then(({ data }) => data),
    {
      onError: () => {
        openNotification("Failed to get units list.", NOTIFICATIONS.error);
      },
      ...config,
    }
  );

  return { units: units || [], isLoading: isLoading || isFetching };
};

const useUnitsExport = () => {
  const { mutate, isLoading } = useRequestExport(
    (filters) => managerApi.unitsExport(filters),
    "Failed to request a units download."
  );

  return {
    exportUnits: mutate,
    isExportingUnits: isLoading,
  };
};

const useCreateUnit = () => {
  const [mutate, response] = useMutation(
    (payload) => api.createUnit(payload).then(({ data }) => data),
    {
      onSuccess: (result) => {
        invalidateCacheAfterUnitChange();
        openNotification(
          `The ${result.name} unit has been successfully created`,
          NOTIFICATIONS.info
        );
      },
      onError: () => {
        // TODO (V2-2357) goran: Enable the notification here when we remove the onDialogSubmitError util.
        // openNotification("Failed to create the unit.", NOTIFICATIONS.error);
      },
    }
  );

  return {
    createUnit: mutate,
    response,
  };
};

const useEditUnit = () => {
  const [mutate, response] = useMutation(
    (payload) => api.editUnit(payload).then(({ data }) => data),
    {
      onSuccess: (result) => {
        invalidateCacheAfterUnitChange();
        queryCache.invalidateQueries([UNIT_QUERIES.getUnit]);
        openNotification(
          `The ${result.name} unit has been successfully edited`,
          NOTIFICATIONS.info
        );
      },
      onError: () => {
        // TODO (V2-2357) goran: Enable the notification here when we remove the onDialogSubmitError util.
        // openNotification("Failed to edit the unit.", NOTIFICATIONS.error);
      },
    }
  );

  return {
    editUnit: mutate,
    response,
  };
};

const useDeleteUnit = () => {
  const [mutate, status] = useMutation((id) => api.deleteUnit({ id }), {
    onSuccess: () => {
      invalidateCacheAfterUnitChange();
      openNotification(
        `The unit has been successfully deleted`,
        NOTIFICATIONS.info
      );
    },
    onError: () => {
      openNotification("Failed to delete the unit.", NOTIFICATIONS.error);
    },
  });

  return {
    deleteUnit: mutate,
    isDeleteLoading: status.isLoading,
  };
};

const useCreateUnitListing = () => {
  const [mutate, status] = useMutation(api.createUnitListing, {
    onSuccess: () => {
      invalidateCacheAfterUnitChange();
      queryCache.invalidateQueries([UNIT_QUERIES.getUnit]);
      openNotification(
        `The unit listing has been successfully created`,
        NOTIFICATIONS.info
      );
    },
    onError: () => {
      // TODO (V2-2357) goran: Enable the notification here when we remove the onDialogSubmitError util.
      // openNotification("Failed to create unit listing.", NOTIFICATIONS.error);
    },
  });

  return {
    createUnitListing: mutate,
    ...status,
  };
};

const useEditUnitListing = () => {
  const [editUnitListing, status] = useMutation(api.editUnitListing, {
    onSuccess: (_, { unitName }) => {
      queryCache.invalidateQueries([UNIT_QUERIES.getUnit]);
      openNotification(
        `You have successfully edited the listing for ${unitName} unit`,
        NOTIFICATIONS.info
      );
    },
    onError: () => {
      // TODO: Enable the notification here when we remove the onDialogSubmitError util.
      // openNotification("Failed to edit unit listing.", NOTIFICATIONS.error);
    },
  });

  return {
    editUnitListing,
    ...status,
  };
};

const useCloseUnitListing = () => {
  const [closeUnitListing, status] = useMutation(
    ({ id }) => api.closeUnitListing({ id }),
    {
      onSuccess: (_, { unitName }) => {
        queryCache.invalidateQueries([UNIT_QUERIES.getUnit]);
        openNotification(
          `You have successfully closed the listing for ${unitName} unit`,
          NOTIFICATIONS.info
        );
      },
      onError: () => {},
    }
  );

  return {
    closeUnitListing,
    isLoading: status.isLoading,
  };
};

const useCloseListingAndMarkUnitAsRented = () => {
  const [closeListingAndMarkUnitAsRented, status] = useMutation(
    ({ id, rentedFrom, rentedUntil, rent, securityDeposit }) =>
      api.closeListingAndMarkUnitAsRented({
        id,
        rentedFrom,
        rentedUntil,
        rent,
        securityDeposit,
      }),
    {
      onSuccess: (_, { unitName }) => {
        queryCache.invalidateQueries([UNIT_QUERIES.getUnit]);
        openNotification(
          `You have successfully closed the listing and marked the ${unitName} unit as rented`,
          NOTIFICATIONS.info
        );
      },
      onError: () => {},
    }
  );

  return {
    closeListingAndMarkUnitAsRented,
    isClosingUnitListing: status.isLoading,
  };
};

const useGetPropertyUnitsDropdown = (params) => {
  const {
    data: units,
    isLoading: isUnitsLoading,
    refetch: refetchUnits,
  } = useQuery(
    [UNIT_QUERIES.getPropertyUnitsDropdown, params.propertyId],
    () => managerApi.getPropertyUnitsDropdown(params),
    {
      enabled: false,
      onError: () => {
        openNotification("Failed to load units", NOTIFICATIONS.error);
      },
    }
  );
  return { units, isUnitsLoading, refetchUnits };
};

export {
  useGetUnit,
  useGetUnitFees,
  useGetUnits,
  useGetPropertyUnits,
  useGetAllUnitsSimple,
  useGetUnitsListForProperty,
  useUnitsExport,
  useCreateUnit,
  useEditUnit,
  useDeleteUnit,
  useCreateUnitListing,
  useEditUnitListing,
  useCloseUnitListing,
  useGetPropertyUnitsDropdown,
  useCloseListingAndMarkUnitAsRented,
};
