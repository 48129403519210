import snakeCase from "lodash/snakeCase";

import { FIELD_TYPES } from "admin/config/constants";

import { CreateFieldFormFormik } from "./CreateFieldForm/interfaces";

interface cookNewFieldBodyProps {
  name: string;
  type: string;
  category: string;
  sample: string;
  description: string;
  smartSource: {
    app: string;
    model: string;
    attribute: string;
    format: string;
  };
  question: string;
}

export const cookNewFieldBody = ({
  name,
  type,
  category,
  sample,
  description,
  smartSource,
  question,
}: cookNewFieldBodyProps) => {
  const generalData = {
    name,
    type,
    category,
  };

  switch (type) {
    case FIELD_TYPES.manual:
      return {
        ...generalData,
        sample,
        description,
        smartSource: null,
      };
    case FIELD_TYPES.smart:
      return {
        ...generalData,
        sample,
        description,
        smartSource: {
          app: smartSource?.app,
          model: smartSource?.model,
          attribute: smartSource?.attribute,
          formatter: smartSource?.format,
          entityType: null,
          entityIndex: null,
        },
      };
    case FIELD_TYPES.checkbox:
      return {
        ...generalData,
        sample: "True",
        description: question,
        smartSource: null,
      };

    default:
      return {};
  }
};

export const cookFormatterName = ({
  type,
  name,
}: {
  type: string;
  name: string;
}) => `${type} (${name})`;

export const getSignatureFieldData = (values: CreateFieldFormFormik) => ({
  name: snakeCase(values.name),
  type: values.type,
  category: values.category,
  min: values.checkboxSignature.min,
  allowed: values.checkboxSignature.allowed,
});
