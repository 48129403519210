import { schema } from "normalizr";

import { unitList } from "shared/lib/schema";
import { portfolio } from "shared/state/Portfolio/schema";

export const property = new schema.Entity("property", {
  portfolio,
  units: unitList,
});
export const propertyList = [property];
