import styled from "@emotion/styled";

import PageTitle from "shared/components/PageTitle";
import { MEDIA_QUERIES } from "shared/config/constants";

const StyledPageTitle = styled(PageTitle)`
  ${MEDIA_QUERIES.w.ltSm} {
    font-size: 24px;
    line-height: 130%;
  }
`;

const PageTitleContainer = styled.div`
  margin-bottom: 23px;
`;

export { StyledPageTitle, PageTitleContainer };
