import React from 'react'
import { Switch } from 'antd'

const Toggle = ({ label, checked, onChange }) => {
  return (
    <div className='vega toggle'>
      <span>{label}</span>
      <Switch checked={checked} onChange={onChange} size='small' />
    </div>
  )
}

export default Toggle
