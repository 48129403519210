import styled from "@emotion/styled";

import { COLORS } from "shared/config/constants";

const firstColumnWith = "350px";

const YardiMappingContent = styled("div")`
  margin-bottom: 10px;
  border: 1px solid ${COLORS.borderColorBase};
  background-color: white;
  .columns {
    background-color: ${COLORS.layoutBodyBackground};
    border-bottom: 1px solid ${COLORS.borderColorBase};
    display: flex;
    padding: 17px 0;
    .header-from {
      width: ${firstColumnWith};
      padding-left: 30px;
    }
    .header-to {
      padding-right: 30px;
      width: calc(100% - ${firstColumnWith});
    }
  }
  .field-container {
    padding: 17px 0;
    border-bottom: 1px solid ${COLORS.innerBorders};
    .field-from {
      font-weight: bold;
      width: ${firstColumnWith};
      padding-left: 30px;
    }
    .field-to {
      padding-right: 30px;
      width: calc(100% - ${firstColumnWith});
    }
  }
`;

export { YardiMappingContent };
