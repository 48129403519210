import React, { useRef } from "react";

import { useHistory } from "react-router-dom";

import ROUTES from "manager/config/routes";
import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import Table from "shared/components/Table";
import Tooltip from "shared/components/Tooltip";
import { RegularText, SmallText } from "shared/components/Typography";
import {
  LynkVerification,
  SnapVerification,
} from "shared/components/Verification";
import { BREAKPOINT_TYPES } from "shared/config/constants";
import withBreakpoint from "shared/hocs/withBreakpoint";
import { getFullnameData } from "shared/utils/applicant";
import { transformRoute } from "shared/utils/routing";
import { formatDateTime, formatPhone } from "shared/utils/ui";

import { LeadsTableProps } from "./interfaces";
import { TableContainer, Cell, LeadType } from "./styled";
import { useLeadsTable } from "./utils";

const LeadsTable = ({
  leads = [],
  leadsCount = 0,
  loading,
  hasInputValue,
  pagination,
  onTableChange,
  initialOrderingColumn,
  breakpoint,
  areVettGuestsEnabled,
  activeItem,
}: LeadsTableProps) => {
  const { page, pageSize } = pagination;
  const history = useHistory();
  const tableRef = useRef<any>();
  const { getLeadsStatus, getActionLink, columnWidths } = useLeadsTable(
    tableRef.current?.offsetWidth,
    breakpoint
  );

  const onRowClick = (key) => {
    const leadUrl = transformRoute(ROUTES.lead, {
      id: key,
    });
    history.push(leadUrl);
  };

  return (
    <TableContainer ref={tableRef}>
      <Table
        id="leads-table"
        searching={hasInputValue}
        alt
        loading={loading}
        columns={[
          {
            title: "Name",
            dataIndex: "applicant",
            key: "applicant",
            sorter: true,
            defaultSortOrder: initialOrderingColumn.name,
            width: columnWidths[0],
          },
          {
            title: "Identifier",
            dataIndex: "prospectCode",
            key: "prospectCode",
            width: columnWidths[1],
          },
          {
            title: "Phone Number",
            dataIndex: "phoneNumber",
            key: "phoneNumber",
            sorter: true,
            defaultSortOrder: initialOrderingColumn.phoneNumber,
            width: columnWidths[2],
          },
          {
            title: "Invited On",
            dataIndex: "invitedDate",
            key: "invitedDate",
            sorter: true,
            defaultSortOrder: initialOrderingColumn.invitedDate,
            width: columnWidths[3],
          },
          {
            title: "Status",
            dataIndex: "leadStatus",
            key: "leadStatus",
            width: 210,
          },
          {
            title: "",
            dataIndex: "verifications",
            key: "verifications",
            width: columnWidths[5],
            className: "verification-column",
          },
        ]}
        columnBreakpoints={{
          xs: {
            column1: "applicant",
            column2: "invitedDate",
          },
          sm: ["applicant", "leadStatus"],
          md: ["applicant", "prospectCode", "invitedDate", "leadStatus"],
          lg: [
            "applicant",
            "prospectCode",
            "phoneNumber",
            "invitedDate",
            "leadStatus",
          ],
          xl: [
            "applicant",
            "prospectCode",
            "phoneNumber",
            "invitedDate",
            "leadStatus",
          ],
          xxl: [
            "applicant",
            "prospectCode",
            "phoneNumber",
            "invitedDate",
            "leadStatus",
            "verifications",
          ],
        }}
        onRowClick={onRowClick}
        placeholder={{
          Icon: Icon.LeadsIcon,
          label: "Leads",
        }}
        rows={leads.map((lead, index) => {
          const {
            email,
            dealId: id,
            isArgyleEnabled,
            hasAutoLinkedEmployment,
            hasAutoLinkedNotCurrentEmployment,
            isPersonaEnabled,
            personaStatus,
            propertyName,
            unitName,
            yardiPcode,
            prescreeningType,
          } = lead;
          const { fullNameOrNA } = getFullnameData(lead);
          const phoneNumber = formatPhone(lead.phoneNumber);
          const invitedDate = formatDateTime(lead.invitedDate);
          const status = getLeadsStatus(lead);
          const yardiPushedAt = formatDateTime(lead.yardiPushedAt);

          const actionLink = getActionLink(lead);

          const lynkSkippedMessage = hasAutoLinkedNotCurrentEmployment
            ? "Lead did not link a current company through VEROLynk"
            : "Lead did not link a company through VEROLynk";

          const statusCell = (
            <Cell>
              <div data-testid="lead-status">
                {status}
                {actionLink || <></>}
              </div>
            </Cell>
          );

          const verificationsCell = (
            <FlexContainer alignItems="center">
              <SnapVerification
                isSnapEnabled={isPersonaEnabled}
                status={personaStatus}
              />
              <LynkVerification
                isLynkEnabled={isArgyleEnabled}
                hasAutoLinkedEmployment={hasAutoLinkedEmployment}
                lynkSkippedMessage={lynkSkippedMessage}
              />
            </FlexContainer>
          );

          const propertyUnitLabel =
            propertyName && unitName
              ? `${propertyName}, ${unitName}`
              : propertyName;

          return {
            key: id,
            values:
              !areVettGuestsEnabled && activeItem === "guest"
                ? [<Cell style={{ minHeight: 0 }} />]
                : [
                    <Cell data-testid={`lead-${index}-applicant`}>
                      <RegularText strong className="leads-column">
                        {fullNameOrNA}{" "}
                        <LeadType type={prescreeningType?.toLowerCase()}>
                          {prescreeningType}
                        </LeadType>
                      </RegularText>
                      <SmallText light>{email}</SmallText>
                      {propertyUnitLabel ? (
                        <SmallText light extraStrong>
                          {propertyUnitLabel}
                        </SmallText>
                      ) : (
                        <Tooltip
                          title="No property was selected when lead was invited"
                          placement="topLeft"
                        >
                          <SmallText light extraStrong>
                            no property linked
                          </SmallText>
                        </Tooltip>
                      )}
                    </Cell>,

                    <Cell>
                      {yardiPcode ? (
                        <>
                          <RegularText>{yardiPcode}</RegularText>
                          <SmallText light>
                            Inserted at: {yardiPushedAt}
                          </SmallText>
                        </>
                      ) : (
                        <RegularText className="no-identifier-text">
                          No identifier
                        </RegularText>
                      )}
                    </Cell>,
                    <Cell>
                      <RegularText data-testid={`lead-${index}-phoneNumber`}>
                        {phoneNumber}
                      </RegularText>
                    </Cell>,
                    <Cell>
                      <RegularText data-testid={`lead-${index}-invitedDate`}>
                        {invitedDate}
                      </RegularText>
                    </Cell>,
                    statusCell,
                    <Cell>{verificationsCell}</Cell>,
                  ],
            primaryAction: statusCell,
            inlinePrimaryActionSpan: 3,
            inlinePrimaryAction: verificationsCell,
            viewed: false,
          };
        })}
        pagination={{
          current: page,
          total: leadsCount,
          defaultPageSize: pageSize,
          hideOnSinglePage: true,
          size: breakpoint === BREAKPOINT_TYPES.xs && "small",
        }}
        onChange={onTableChange}
      />
    </TableContainer>
  );
};

export default withBreakpoint(LeadsTable);
