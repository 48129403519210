import React from "react";

import { Helmet } from "react-helmet";

import {
  LEADS_CATEGORY_TYPES,
  LEADS_CATEGORY_LABEL_INFO,
} from "manager/config/constants";
import { Button } from "shared/components/Button";
import Container from "shared/components/Container";
import FilterBar, { DROPDOWN_FILTER_MODES } from "shared/components/FilterBar";
import FlexContainer from "shared/components/FlexContainer";
import SwitchLight from "shared/components/Form/SwitchLight/SwitchLight";
import MobileFilters from "shared/components/MobileFilters";
import NewFeatureBanner from "shared/components/NewFeatureBanner";
import PageTitle from "shared/components/PageTitle";
import TablePageCardHeader from "shared/components/TablePageCardHeader";
import { BREAKPOINT_TYPES } from "shared/config/constants";
import withBreakpoint from "shared/hocs/withBreakpoint";
import { ContentContainer } from "shared/templates/TablePage/styled";

import { LEAD_STATUS_ITEMS } from "./constants";
import { LeadsProps } from "./interfaces";
import LeadsTable from "./LeadsTable";
import { CheckboxContainer, ArchiveLeadContainer } from "./styled";

const Leads = ({
  breakpoint,
  leads = [],
  leadsCount = 0,
  isCountsLoading,
  filters,
  pagination,
  initialOrderingColumn,
  hasInputValue,
  showInviteLead,
  isFiltersLoading,
  loading,
  updateFilters,
  onTableChange,
  inviteLead,
  isYardiEnabled,
  showArchived,
  onShowArchivedCheckboxChange,
  propertySelection,
  menuItems,
  changeLeadType,
  activeItem,
  areVettGuestsEnabled,
  companyName,
}: LeadsProps) => {
  const disabledSearch =
    leads?.length === 0 && !Object.keys(filters).some((key) => filters[key]);
  const disabledFilters =
    (leads?.length === 0 &&
      !Object.keys(filters).some((key) => filters[key])) ||
    loading;

  const filterBarAction = showInviteLead
    ? {
        key: "INVITE_LEAD",
        node: (
          <Button type="secondary" onClick={inviteLead}>
            Invite Lead
          </Button>
        ),
      }
    : null;

  const filterBar = {
    title: "Filter Leads",
    search: {
      action: (value) => updateFilters({ nameOrEmail: value }),
      placeholder: "Search in leads",
      width: 350,
      debounce: true,
      disabled: disabledSearch,
      value: filters.nameOrEmail,
    },
    dateRange: {
      label: "Invitation Sent Date",
      date1Id: "dateMin",
      date2Id: "dateMax",
      setValues: updateFilters,
      fromValue: filters.dateMin,
      toValue: filters.dateMax,
      disabled: disabledFilters,
    },
    loadingDropdownOptions: isFiltersLoading,
    dropdownFilters: [
      {
        key: "propertyId",
        label: "Properties",
        value: filters.propertyId,
        onChange: (value) => updateFilters({ propertyId: value }),
        items: propertySelection,
        mode: DROPDOWN_FILTER_MODES.multiselect,
        disabled: disabledFilters,
      },
      {
        key: "leadStatus",
        label: "Lead statuses",
        value: filters.leadStatus,
        onChange: (value) =>
          updateFilters({
            leadStatus: value,
          }),
        items: LEAD_STATUS_ITEMS,
        disabled: disabledFilters,
        showOnEnd: true,
      },
    ],
    setFilters: (filtersValue) => updateFilters(filtersValue),
    clearFilters: {
      onClick: () => updateFilters(null),
      order: 2,
    },
    breakpointFilterPopover: [
      BREAKPOINT_TYPES.sm,
      BREAKPOINT_TYPES.md,
      BREAKPOINT_TYPES.lg,
      BREAKPOINT_TYPES.xl,
    ],
    action: filterBarAction,
  };

  const ShowArchivedLeadsCheckbox = (
    <CheckboxContainer data-testid="archived-checkbox">
      <SwitchLight
        id="show-archived-leads"
        value={showArchived}
        onChange={onShowArchivedCheckboxChange}
        title="Include archived"
        noPadding
      />
    </CheckboxContainer>
  );

  const isMobile = breakpoint === BREAKPOINT_TYPES.xs;

  return (
    <>
      <Helmet>
        <title>Leads</title>
      </Helmet>
      {isMobile && (
        <>
          <Container>
            <FlexContainer
              justifyContent="space-between"
              alignItems="center"
              flexWrap="nowrap"
            >
              <PageTitle>Leads</PageTitle>
              {/* @ts-ignore */}
              <MobileFilters {...filterBar} />
            </FlexContainer>
          </Container>
        </>
      )}
      <Container noMobilePaddingX noMobilePaddingY>
        <TablePageCardHeader
          title="Leads"
          areVettGuestsEnabled={areVettGuestsEnabled}
          categoryItems={menuItems}
          activeItem={activeItem}
          categoryTypes={LEADS_CATEGORY_TYPES}
          categoryLabelInfo={LEADS_CATEGORY_LABEL_INFO}
          isCountsLoading={isCountsLoading}
          onChange={changeLeadType}
        />
        <ContentContainer>
          <FilterBar {...filterBar} />
          <ArchiveLeadContainer>
            <FlexContainer justifyContent="space-between" flexWrap="wrap">
              {ShowArchivedLeadsCheckbox}
              <Button
                className="invite-lead-mobile"
                type="secondary"
                onClick={inviteLead}
                size="sm"
              >
                Invite Lead
              </Button>
            </FlexContainer>
          </ArchiveLeadContainer>
          {!areVettGuestsEnabled && activeItem === "guest" ? (
            <NewFeatureBanner
              title="Enhanced Lead"
              subtitle="Filter leads as prospect or touring guest"
              // @ts-ignore
              mailto={`mailto:hello@sayvero.com?subject=${companyName} - Access request - Lead Type Categories`}
            />
          ) : (
            <LeadsTable
              hasInputValue={hasInputValue}
              pagination={pagination}
              leads={leads}
              leadsCount={leadsCount}
              loading={loading}
              onTableChange={onTableChange}
              initialOrderingColumn={initialOrderingColumn}
              // @ts-ignore
              isYardiEnabled={isYardiEnabled}
              areVettGuestsEnabled={areVettGuestsEnabled}
              activeItem={activeItem}
            />
          )}
        </ContentContainer>
      </Container>
    </>
  );
};

export default withBreakpoint(Leads);
