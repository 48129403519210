import styled from "@emotion/styled";

import { MEDIA_QUERIES } from "shared/config/constants";

const SearchContainer = styled.div`
  position: fixed;
  top: 60px;
  left: 0;
  width: 100vw;
  z-index: 200;

  .ant-input-suffix {
    right: 22px;
  }

  ${MEDIA_QUERIES.w.gteMd} {
    top: 70px;

    .ant-input-suffix {
      right: 26px;
    }
  }
`;

export { SearchContainer };
