import React from "react";

import { SkeletonProps } from "antd/lib/skeleton";

import { StyledSkeleton } from "./styled";

interface VeroSkeletonProps extends SkeletonProps {
  noMarginBottom?: boolean;
  children: React.ReactNode;
  loading?: boolean;
  childrenWrapper?: boolean;
}

const Skeleton = ({
  noMarginBottom = false,
  children,
  loading = false,
  childrenWrapper = true,
  ...props
}: VeroSkeletonProps) => {
  const resolvedChildren = childrenWrapper ? (
    <span data-testid="children">{children}</span>
  ) : (
    <>{children}</>
  );
  return loading ? (
    <span data-testid="skeleton">
      <StyledSkeleton active noMarginBottom={noMarginBottom} {...props} />
    </span>
  ) : (
    resolvedChildren
  );
};

export default Skeleton;
