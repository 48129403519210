import { toDate } from "../utils";

import { Unit } from "./interfaces";

export const mapUnit = (
  unit?: Record<string, any>,
  unitListing?: Record<string, any>
): Unit | undefined => {
  if (!unit) return undefined;
  return {
    id: unit.id,
    name: unit.name,
    yardiConnected: unit.yardiConnected,
    unitType: unit.unitType,
    bedrooms: unit.bedrooms,
    bathrooms: unit.bathrooms,
    squareFootage: unit.squareFeet,
    availableOn: toDate(unit?.unitAvailableOn || unitListing?.unitAvailableOn),
    hasLroEnabled: unit.hasLroEnabled,
    externalId: unit.externalId,
    provider: unit.provider,
  };
};
