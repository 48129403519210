import { useEffect, useState } from "react";

import { useInterval } from "react-use";

import { NOTIFICATIONS } from "../config/constants";
import { openNotification } from "../utils/ui";

export default function useAppOffline() {
  const [isAppOffline, setIsAppOffline] = useState<boolean>(!navigator?.onLine);

  useInterval(() => {
    setIsAppOffline(!navigator?.onLine);
  }, 1000);

  useEffect(() => {
    if (isAppOffline) {
      openNotification(
        "Your network connection is unstable right now.",
        NOTIFICATIONS.error
      );
    }
  }, [isAppOffline]);
}
