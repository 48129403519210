import React from "react";

import EmailTemplatesSettings from "../EmailTemplatesSettings";

import PropertyEmailTemplatesFormContainer from "./PropertyEmailTemplatesFormContainer";

const PropertyEmailTemplatesSettings = () => (
  <EmailTemplatesSettings
    showDescription
    EmailTemplatesForm={PropertyEmailTemplatesFormContainer}
  />
);

export default PropertyEmailTemplatesSettings;
