import styled from "@emotion/styled";

import { PopoverContent } from "shared/components/Popover";
import { MEDIA_QUERIES } from "shared/config/constants";

export const AssignFormulaToPropertiesModalContent = styled.div<{
  hasWarnings?: boolean;
}>`
  .checkbox-list-header {
    justify-content: flex-start;
    .first-col {
      margin-right: 40px;
      width: auto !important;
      .extra-text {
        display: none;
      }
    }
    .ant-radio-group {
      display: none;
    }
  }
  .searchable-list-body {
    .description-cell {
      width: 100%;
    }
  }
  .searchable-list-container {
    ${({ hasWarnings }) =>
      hasWarnings &&
      `
    border-bottom: none;
`}
  }
  .collapsible-suffix {
    margin-left: 10px;
  }
  .compatibility-issues-warning-icon {
    margin-left: 10px;
  }
  .portfolio-description-icon {
    margin-right: -4px;
  }
  ${MEDIA_QUERIES.w.ltSm} {
    .checkbox-list-header {
      align-items: flex-start;
    }
  }
`;

export const PropertyCompatibilityIssuesPopoverContent = styled(PopoverContent)`
  ul {
    padding-left: 12px;
    margin-bottom: 0;
  }

  li {
    margin-bottom: 8px;
  }
`;
