import React, { useEffect, useRef } from "react";

import { useUpdateMarketingSources } from "manager/hooks/api";
import { propertySettingsPropTypes } from "manager/utils/propTypes";
import { FormPromptShell } from "shared/components/Form";
import Icon from "shared/components/Icon";
import Skeleton from "shared/components/Skeleton";
import { useGetMarketingSources } from "shared/hooks/api/sourcesQueries";
import { resetFormAndValidate } from "shared/utils/forms";

import { cleanupSources, convertRawSources } from "./additionalSettings.util";
import { MarketingSources } from "./Sources";

const PropertyAdditionalSettings = ({ propertySettings }) => {
  const formRef = useRef();
  const {
    id: propertyId,
    name,
    yardiConnected,
  } = propertySettings || PropertyAdditionalSettings.defaultProps;

  const { isLoading, marketingSources } = useGetMarketingSources(propertyId);
  const { updateMarketingSources } = useUpdateMarketingSources();

  const saveChanges = (sources) =>
    updateMarketingSources({ propertyId, marketingSources: sources });

  const cleanupAndSaveChanges = (values) => {
    const resolvedSources = cleanupSources(values.sources);
    return saveChanges(resolvedSources);
  };

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      await cleanupAndSaveChanges(values);
    } finally {
      setSubmitting(false);
    }
  };

  const resolvedSources = convertRawSources(marketingSources);

  const initialValues = { sources: resolvedSources };

  const isLinked = !!yardiConnected;
  const linkedData = {
    isLinked,
    popoverText: "Marketing sources are connected to Yardi",
    popoverIcon: <Icon.YardiGreenSmall className="title-popover" />,
    newSourcePopoverText:
      "You’re not able to add new marketing sources to the property which is connected to Yardi",
    deleteWarningText: "Current marketing source is linked with Yardi",
    deleteDescription: `Once you remove marketing source from ${name} it won’t be recorded and sent to Yardi when syncing anymore.`,
  };

  useEffect(() => {
    if (!isLoading && marketingSources) {
      resetFormAndValidate(formRef, { sources: resolvedSources });
    }
  }, [isLoading, marketingSources]);

  return (
    <FormPromptShell
      initialValues={initialValues}
      formRef={formRef}
      onSubmit={onSubmit}
      saveChanges={cleanupAndSaveChanges}
    >
      <Skeleton loading={isLoading}>
        <MarketingSources propertyName={name} linkedData={linkedData} />
      </Skeleton>
    </FormPromptShell>
  );
};

PropertyAdditionalSettings.propTypes = {
  propertySettings: propertySettingsPropTypes,
};

PropertyAdditionalSettings.defaultProps = {
  propertySettings: {
    id: undefined,
    name: undefined,
    yardiConnected: false,
  },
};

export default PropertyAdditionalSettings;
