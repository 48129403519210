import React from "react";

import { EditUnitListingModal } from "manager/components/Modal";
import CloseListingModal from "manager/components/Modal/CloseListingModal";
import CreateUnitListingModal from "manager/components/Modal/CreateUnitListingModal";
import { Unit } from "manager/interfaces/api/unit";
import { getIntegrationType } from "manager/utils/integration.util";
import { hasListing } from "manager/utils/unit.util";
import useModal from "shared/hooks/useModal";
import { useGetPricingOffers } from "shared/hooks/api";

import Listings from "./Listings";
import { getDisabledMessages } from "./utils";

interface ListingsContainerProps {
  unit: Unit;
  loading: boolean;
}

const ListingsContainer = ({ unit, loading }: ListingsContainerProps) => {
  const { openModalDialog } = useModal();

  const currentIntegrationType = getIntegrationType(unit);

  const openCloseListingModal = () =>
    openModalDialog(CloseListingModal, {
      unit,
    });

  const editListing = () =>
    openModalDialog(EditUnitListingModal, {
      rent: unit?.unitListingCurrent?.rent,
      securityDeposit: unit?.unitListingCurrent?.securityDeposit,
      unitAvailableOn: unit?.unitListingCurrent?.unitAvailableOn,
      unitListingId: unit?.unitListingCurrent?.id,
      note: unit?.unitListingCurrent?.note,
      unitName: unit?.name,
      currentIntegrationType,
    });
  const createUnitListing = () =>
    openModalDialog(CreateUnitListingModal, {
      name: unit?.name,
      id: unit?.id,
      propertyName: unit?.property?.name,
    });

  const isCurrentAndNotClosedListing = hasListing(unit);

  const { disabledCloseMessage, disabledCreateMessage } = getDisabledMessages(
    currentIntegrationType
  );

  const { pricingOffers } = useGetPricingOffers(unit?.location)

  const previousListings = unit?.unitListings?.filter(
    (listing) => !listing.isCurrent
  );

  return (
    <Listings
      closeListing={openCloseListingModal}
      editListing={editListing}
      createUnitListing={createUnitListing}
      unit={unit}
      isCloseVisible={isCurrentAndNotClosedListing}
      isCreateVisible
      isEditVisible={isCurrentAndNotClosedListing}
      disabledCloseMessage={disabledCloseMessage}
      disabledCreateMessage={disabledCreateMessage}
      loading={loading}
      previousListings={previousListings}
      pricingOffers={pricingOffers}
    />
  );
};

export default ListingsContainer;
