import { useQuery } from "react-query";

import typeApi from "manager/lib/typeApi";
import { openNotification } from "shared/utils/ui";

export const useGetCreditPublicRecordOptions = () => {
  const { data: creditPublicRecordOptions } = useQuery(
    ["getCreditPublicRecordOptions"],
    () => typeApi.getCreditPublicRecordOptions().then(({ data }) => data),
    {
      onError: () =>
        openNotification("Failed to load credit public record options"),
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
  return { creditPublicRecordOptions };
};

export const useGetCreditPublicRecordBankruptcyOptions = () => {
  const { data: creditPublicRecordBankruptcyOptions } = useQuery(
    ["getCreditPublicRecordBankruptcyOptions"],
    () =>
      typeApi.getCreditPublicRecordBankruptcyOptions().then(({ data }) => data),
    {
      onError: () =>
        openNotification(
          "Failed to load credit public record bankruptcy options"
        ),
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
  return { creditPublicRecordBankruptcyOptions };
};

export const useGetCriminalRecordSeverityOptions = () => {
  const { data: criminalRecordSeverityOptions } = useQuery(
    ["getCriminalRecordSeverityOptions"],
    () => typeApi.getCriminalRecordSeverityOptions().then(({ data }) => data),
    {
      onError: () =>
        openNotification("Failed to load criminal record severity options"),
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
  return { criminalRecordSeverityOptions };
};

export const useGetSystemAuditSourceTypes = () => {
  const { data: systemAuditSourceTypes } = useQuery(
    ["getSystemAuditSourceTypes"],
    () => typeApi.getSystemAuditSourceTypes().then(({ data }) => data),
    {
      onError: () =>
        openNotification("Failed to load system audit source types"),
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
  return { systemAuditSourceTypes };
};

export const useGetDashboardPeriods = () => {
  const { data: dashboardPeriods } = useQuery(
    ["getDashboardPeriods"],
    () => typeApi.getDashboardPeriods(),
    {
      onError: () => openNotification("Failed to load dashboard periods"),
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
  return { dashboardPeriods };
};
