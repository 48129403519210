import find from "lodash/find";
import * as Yup from "yup";

const getEmailConfigurationInitialValues = (configurations) => {
  const customEmailConfigurationId =
    find(configurations, "selected")?.id || null;

  return {
    isDefaultConfiguration: !customEmailConfigurationId,
    customEmailConfigurationId,
  };
};

const emailConfigurationValidationSchema = Yup.object().shape({
  isDefaultConfiguration: Yup.bool(),
  customEmailConfigurationId: Yup.number()
    .nullable()
    .when("isDefaultConfiguration", {
      is: false,
      then: Yup.number()
        .nullable()
        .required("Please select a custom email configuration"),
    }),
});

const DEFAULT_KEY = "default";

export {
  getEmailConfigurationInitialValues,
  emailConfigurationValidationSchema,
  DEFAULT_KEY,
};
