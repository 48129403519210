import React from "react";

import { COLORS } from "shared/config/constants";

export interface CircularPercentageBarProps {
  percentage: number;
  colour: string;
  customLabel?: string | number;
  radius?: number;
}

export interface CircleProps {
  percentage?: number;
  colour: string;
  radius?: number;
}

const cleanPercentage = (percentage) => {
  // we can set non-numbers to 0 here
  const isNegativeOrNaN = !Number.isFinite(+percentage) || percentage < 0;
  const isTooHigh = percentage > 100;
  // eslint-disable-next-line no-nested-ternary
  return isNegativeOrNaN ? 0 : isTooHigh ? 100 : +percentage;
};

const Circle = ({ colour, percentage, radius }: CircleProps) => {
  const circ = 2 * Math.PI * radius;
  // where stroke will start, e.g. from 15% to 100%.
  const strokePct = ((100 - percentage) * circ) / 100;
  return (
    <circle
      r={radius}
      cx={100}
      cy={100}
      fill="transparent"
      // remove colour as 0% sets full circumference
      stroke={strokePct !== circ ? colour : ""}
      strokeWidth="8px"
      strokeDasharray={circ}
      strokeDashoffset={percentage ? strokePct : 0}
    />
  );
};

const Text = ({ percentage, customText }) => {
  return (
    <text
      x="50%"
      y="50%"
      dominantBaseline="central"
      textAnchor="middle"
      fontSize="64px"
    >
      {customText || percentage.toFixed(0)}
    </text>
  );
};

const CIRCLE_RADIUS = 80;

const CircularPercentageBar = ({
  percentage,
  colour,
  customLabel,
  radius = CIRCLE_RADIUS,
}: CircularPercentageBarProps) => {
  const pct = cleanPercentage(percentage);
  return (
    <svg width={200} height={200}>
      <g transform={`rotate(0 ${"100 100"})`}>
        <Circle radius={radius} colour={COLORS.layoutBodyBackground} />
        <Circle radius={radius} colour={colour} percentage={pct} />
      </g>
      <Text percentage={pct} customText={customLabel} />
    </svg>
  );
};

export default CircularPercentageBar;
