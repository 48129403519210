import { useEffect } from 'react'

import _ from 'lodash'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'

import Attachments from 'shared/components/Attachments'
import { VisibleAttachments } from 'shared/components/Attachments/interfaces'
import Container from 'shared/components/Container'
import FooterBar from 'shared/components/FooterBar'
import { BackLink } from 'shared/components/Links'
import PageTitle from 'shared/components/PageTitle'
import Property from 'shared/components/Property'
import Tabs from 'shared/components/Tabs'
import Tile from 'shared/components/Tile'
import { ApplicantWorkflow } from 'shared/components/vega/ApplicantWorkflow'
import { LumenV2 } from 'shared/components/vega/LumenV2'
import { Payments } from 'shared/components/vega/Payments'
import { APPLICATION_STATUSES } from 'shared/config/constants'
import ROUTES from 'shared/config/routes'
import { ApplicantType } from 'shared/interfaces'
import { getFullnameData } from 'shared/utils/applicant'
import { isRenterApp } from 'shared/utils/auth'
import { useVeroFlags } from 'shared/utils/launchDarkly'
import {
  ocrResultsPropType,
  otherAttachmentsPropType,
  requiredAttachmentsPropType,
} from 'shared/utils/propTypes'
import { transformRoute } from 'shared/utils/routing'

import CreditAndBackgroundCheck from './CreditAndBackgroundCheck'
import IdVerification from './IdVerification'
import IncomeAndAssets from './IncomeAndAssets'
import RenterProfile from './RenterProfile'
import {
  DesktopApplicantDropdown,
  FooterBar as StyledFooterBar,
  FooterButton,
  LoadingSpinner,
  MobileApplicantDropdown,
} from './styled'
import DefaultSummary from './Summary'
import Verifications from './Verifications'
import VorVerifications from './VorVerifications'
import AttachmentsRedesigned from 'shared/components/Attachments/AttachmentsRedesigned'

const Application = ({
  addApplicationAttachments,
  ApplicantDropdown,
  applicantWorkflow,
  application,
  attachmentActionInProgress,
  backLink,
  canAccessCountyCriminalRecords,
  canAccessSensitiveDealData,
  cancelApplicationAttachments,
  canViewOcrResults,
  canViewRestrictedAttachments,
  ConditionallyProceedInfoBanner,
  confirmApplicationAttachments,
  creditBackgroundIndicator,
  deal,
  deleteApplicationAttachments,
  deleteDocumentById,
  deleteOcrResultById,
  failedDocuments,
  getDeal,
  history,
  incomeBalancesIndicator,
  isSensitiveDealDataPermissionLoaded,
  loading,
  lumenRun,
  match,
  ocrResults,
  openWithdraw,
  otherAttachments,
  payments,
  refetchOcrResults,
  requiredAttachments,
  showAttachmentsHeaders,
  showAttachmentsVisibilityAction,
  showConditionallyProceedInfoBanner,
  showDropdown,
  showOtherApplicants,
  showProperty,
  verificationsIndicator,
}) => {
  const { id } = match.params
  const activeRoute = useLocation().pathname
  const { useAttachmentsRedesign } = useVeroFlags()
  const isRenter = isRenterApp()

  const isWithdrawable =
    isRenter &&
    application?.status === APPLICATION_STATUSES.submitted &&
    !application?.preventEditApplicationAfterSubmit &&
    !application?.dealDecisionIsMade

  useEffect(() => {
    if (application?.deal && showOtherApplicants) {
      getDeal(application?.deal)
    }
  }, [application?.deal])

  const baseApplicationRoute = transformRoute(ROUTES.application, {
    id,
  })
  const renterProfileRoute = canAccessSensitiveDealData
    ? transformRoute(ROUTES.applicationRenterProfile, {
        id,
      })
    : baseApplicationRoute
  const incomeAndAssetsRoute = transformRoute(ROUTES.applicationIncomeAssets, {
    id,
  })
  const creditAndBackgroundRoute = transformRoute(
    ROUTES.applicationCreditBackground,
    {
      id,
    },
  )
  const attachmentsRoute = transformRoute(ROUTES.applicationAttachments, {
    id,
  })
  const verificationsRoute = transformRoute(ROUTES.applicationVerifications, {
    id,
  })
  const idVerificationRoute = transformRoute(ROUTES.applicationIdVerification, {
    id,
  })
  const vorVerificationsRoute = transformRoute(
    ROUTES.applicationVorVerifications,
    {
      id,
    },
  )
  const workflowRoute = transformRoute(ROUTES.applicationWorkflow, {
    id,
  })
  const paymentsRoute = transformRoute(ROUTES.applicationPayments, {
    id,
  })
  const lumenRoute = transformRoute(ROUTES.applicationLumen, {
    id,
  })

  // NOTE: preserving the hash on redirect
  const redirectTo = {
    pathname: baseApplicationRoute,
    hash: (window.location.hash || '').substring(1),
  }

  const unitName = application?.unitName || ''

  const dealBackLink = {
    children: backLink.children.replace(':unitName', unitName),
    to: backLink.to.replace(':id', application?.deal),
  }
  const propertyName = application?.property?.name
  const propertyAddress = application?.property?.address
  const applicationSummary = application?.applicationSummary
  const incomeAsset = application?.incomeAsset
  const creditReport = application?.backgroundCreditCheck
  const countyCriminalRecords = application?.countyCriminalRecords
  const countyCriminalSearchStatus = application?.countyCriminalSearchStatus

  const { fullNameOrNA, hasFullname } = getFullnameData(applicationSummary)
  const name = hasFullname ? fullNameOrNA : 'Applicant'
  const pageTitle = `${name} Application`

  const showRenterIdVerification = isRenter
  const showManagerVerifications = !isRenter
  const showLumen = !isRenter
  const hasSSN = applicationSummary?.hasSsn
  const reasonToStay =
    !hasSSN && applicationSummary?.reasonToStay
      ? `${isRenter ? 'You' : name} did not provide an SSN/ITIN. Reason: ${
          applicationSummary?.reasonToStay
        }`
      : undefined

  /* Tabs definition - start */
  const tabs = []

  tabs.push(
    {
      key: baseApplicationRoute,
      label: isRenter ? 'My Profile' : 'Renter Profile',
    },
    {
      key: incomeAndAssetsRoute,
      label: <>Income & Balances {incomeBalancesIndicator}</>,
    },
  )

  if (canAccessSensitiveDealData) {
    tabs.push({
      key: creditAndBackgroundRoute,
      label: <>Credit & Background {creditBackgroundIndicator}</>,
    })
  }

  tabs.push({
    key: attachmentsRoute,
    label: 'Attachments',
  })

  if (showRenterIdVerification) {
    tabs.push({
      key: idVerificationRoute,
      label: 'ID Verification',
    })
  }

  if (showManagerVerifications) {
    tabs.push({
      key: verificationsRoute,
      label: <>Identity Verifications {verificationsIndicator}</>,
    })
    tabs.push({
      key: vorVerificationsRoute,
      label: <>Residency Verifications</>,
    })
  }

  tabs.push({
    key: workflowRoute,
    label: <>Workflow</>,
  })

  tabs.push({
    key: paymentsRoute,
    label: <>Payments</>,
  })

  if (showLumen) {
    tabs.push({
      key: lumenRoute,
      label: <>Lumen</>,
    })
  }

  /* Tabs definition - end */

  const showLoader =
    (loading && !application) || !isSensitiveDealDataPermissionLoaded

  const applicantType = application?.applicantType
  let applicantTypeLabel
  if (applicantType === ApplicantType.APPLICANT) {
    applicantTypeLabel = 'Applicant'
  } else if (applicantType === ApplicantType.GUARANTOR) {
    applicantTypeLabel = 'Guarantor'
  } else if (applicantType === ApplicantType.OCCUPANT) {
    applicantTypeLabel = 'Occupant'
  }

  const party = _.upperCase(application?.party)

  return (
    <>
      {showLoader ? (
        <LoadingSpinner />
      ) : (
        <>
          <Helmet>
            <title>{pageTitle}</title>
          </Helmet>
          <Container noPaddingBottom>
            <BackLink.Container>
              <BackLink {...dealBackLink} />
            </BackLink.Container>
            {showConditionallyProceedInfoBanner && (
              <Container noPaddingTop noPaddingRight noPaddingLeft>
                <ConditionallyProceedInfoBanner />
              </Container>
            )}
            <PageTitle.Container>
              <PageTitle>{pageTitle}</PageTitle>
              <PageTitle.Actions mobileRightAlign rightAlign={showProperty}>
                {showProperty && (
                  <Property
                    horizontal
                    name={propertyName}
                    address={propertyAddress}
                    unit={unitName}
                    fallbackContent={`Application for ${
                      application?.companyName || 'company'
                    }`}
                  />
                )}
                {showDropdown && (
                  <>
                    <DesktopApplicantDropdown>
                      <ApplicantDropdown
                        canAccessSensitiveDealData={canAccessSensitiveDealData}
                      />
                    </DesktopApplicantDropdown>
                    <MobileApplicantDropdown>
                      <ApplicantDropdown
                        canAccessSensitiveDealData={canAccessSensitiveDealData}
                      />
                    </MobileApplicantDropdown>
                  </>
                )}
              </PageTitle.Actions>
            </PageTitle.Container>
          </Container>
          <Container noMobilePaddingX noPaddingTop>
            <Tile noBorderBottom>
              <Tabs
                activeKey={activeRoute}
                tabs={tabs}
                onChange={(newRoute) => history.push(newRoute)}
              />
            </Tile>
            <Switch>
              <Route
                exact
                path={baseApplicationRoute}
                render={() => (
                  <RenterProfile
                    type={party || applicantTypeLabel}
                    name={name}
                    applicationSummary={applicationSummary}
                  />
                )}
              />
              <Route exact path={renterProfileRoute}>
                <Redirect to={redirectTo} />
              </Route>
              <Route
                exact
                path={incomeAndAssetsRoute}
                render={() => (
                  <IncomeAndAssets
                    summary={incomeAsset}
                    applicationSummary={applicationSummary}
                    ocrResults={ocrResults}
                    canViewOcrResults={canViewOcrResults}
                  />
                )}
              />
              {canAccessSensitiveDealData && (
                <Route
                  exact
                  path={creditAndBackgroundRoute}
                  render={() => (
                    <CreditAndBackgroundCheck
                      creditScoreSummary={creditReport?.creditScoreSummary}
                      criminalRecords={creditReport?.criminalRecords}
                      hideCriminalRecords={application?.hideCriminalRecords}
                      isEvictions={!application?.isInNoEvictionState}
                      evictions={creditReport?.evictionRecords}
                      countyCriminalRecords={countyCriminalRecords}
                      noSSNreasonToStay={reasonToStay}
                      canAccessCountyCriminalRecords={
                        canAccessCountyCriminalRecords
                      }
                      countyCriminalSearchStatus={countyCriminalSearchStatus}
                      hasCountyCriminalSearch={
                        application?.hasCountyCriminalSearch
                      }
                      hasTuCountyCriminalSearch={
                        application?.hasTuCountyCriminalSearch
                      }
                      deal={deal}
                      application={application}
                    />
                  )}
                />
              )}
              <Route
                exact
                path={attachmentsRoute}
                render={() =>
                  useAttachmentsRedesign ? (
                    <AttachmentsRedesigned />
                  ) : (
                    <Attachments
                      ocrResults={ocrResults}
                      deleteOcrResultById={deleteOcrResultById}
                      deleteDocumentById={deleteDocumentById}
                      refetchOcrResults={refetchOcrResults}
                      canViewOcrResults={canViewOcrResults}
                      canViewRestrictedAttachments={
                        canViewRestrictedAttachments
                      }
                      showAttachmentsHeaders={showAttachmentsHeaders}
                      showAttachmentsVisibilityAction={
                        showAttachmentsVisibilityAction
                      }
                      requiredAttachments={requiredAttachments}
                      otherAttachments={otherAttachments}
                      uploadAttachment={(params) =>
                        addApplicationAttachments({
                          applicationId: id,
                          ...params,
                        })
                      }
                      deleteAttachment={(attachmentId) =>
                        deleteApplicationAttachments({
                          applicationId: id,
                          attachmentId,
                        })
                      }
                      confirmApplicationAttachments={(
                        attachmentsChangedVisiblility,
                        onSuccess,
                      ) =>
                        confirmApplicationAttachments(
                          {
                            applicationId: id,
                            attachmentsChangedVisiblility,
                          },
                          { onSuccess },
                        )
                      }
                      cancelApplicationAttachments={(onSuccess) =>
                        cancelApplicationAttachments(
                          {
                            applicationId: id,
                          },
                          { onSuccess },
                        )
                      }
                      loading={attachmentActionInProgress || loading}
                      withOtherAttachmentsSection
                      inEditMode={!isRenter}
                      dealDecisionIsMade={application?.dealDecisionIsMade}
                      failedDocuments={failedDocuments}
                      documents={application?.documents}
                      applicantDocuments={_.get(
                        application,
                        'snapshot.applicantDocuments',
                      )}
                    />
                  )
                }
              />

              {showRenterIdVerification && (
                <Route
                  exact
                  path={idVerificationRoute}
                  render={() => (
                    <IdVerification
                      verification={application?.idVerification}
                    />
                  )}
                />
              )}
              {showManagerVerifications && (
                <Route
                  exact
                  path={verificationsRoute}
                  render={() => (
                    <Verifications
                      verifications={application?.verifications}
                      idVerifications={application?.idVerifications}
                      personaStatus={application?.personaStatus}
                      personaInquiryStatus={application?.personaInquiryStatus}
                      personaReviewerComment={
                        application?.personaReviewerComment
                      }
                    />
                  )}
                />
              )}
              {showManagerVerifications && (
                <Route
                  exact
                  path={vorVerificationsRoute}
                  render={() => (
                    <VorVerifications
                      verifications={application?.verifications}
                    />
                  )}
                />
              )}
              <Route
                exact
                path={workflowRoute}
                render={() => (
                  <ApplicantWorkflow applicantWorkflow={applicantWorkflow} />
                )}
              />
              <Route
                exact
                path={paymentsRoute}
                render={() => <Payments payments={payments} />}
              />
              {showLumen && (
                <Route
                  exact
                  path={lumenRoute}
                  render={() => (
                    <LumenV2
                      lumenRun={lumenRun}
                      applicantId={application?.applicantVrn}
                      detailed={canAccessSensitiveDealData}
                    />
                  )}
                />
              )}

              <Redirect to={redirectTo} />
            </Switch>
          </Container>
          <FooterBar.Spacer />
          <Container noPaddingBottom noPaddingTop stickToBottom>
            {isWithdrawable && (
              <StyledFooterBar>
                <FooterButton
                  type="secondary"
                  onClick={() => openWithdraw(application.id, propertyName)}
                >
                  Edit Application
                </FooterButton>
              </StyledFooterBar>
            )}
          </Container>
        </>
      )}
    </>
  )
}

Application.propTypes = {
  editIncomeAndAssets: PropTypes.func,
  removeApplicant: PropTypes.func,
  backLink: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  application: PropTypes.object,
  applicantTypes: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  getDeal: PropTypes.func,
  showOtherApplicants: PropTypes.bool,
  showDropdown: PropTypes.bool,
  showProperty: PropTypes.bool,
  showDateStamp: PropTypes.bool,
  openWithdraw: PropTypes.func,
  requiredAttachments: requiredAttachmentsPropType,
  requiredAttachmentsRaw: requiredAttachmentsPropType,
  otherAttachments: otherAttachmentsPropType,
  otherAttachmentsRaw: otherAttachmentsPropType,
  ocrResults: ocrResultsPropType,
  deleteOcrResultById: PropTypes.func,
  refetchOcrResults: PropTypes.func,
  OtherApplicantsList: PropTypes.elementType.isRequired,
  ApplicantDropdown: PropTypes.elementType,
  ConditionallyProceedInfoBanner: PropTypes.elementType,
  showConditionallyProceedInfoBanner: PropTypes.bool,
  title: PropTypes.string.isRequired,
  addApplicationAttachments: PropTypes.func,
  deleteApplicationAttachments: PropTypes.func,
  confirmApplicationAttachments: PropTypes.func,
  cancelApplicationAttachments: PropTypes.func,
  attachmentActionInProgress: PropTypes.bool,
  canAccessSensitiveDealData: PropTypes.bool,
  canViewOcrResults: PropTypes.bool,
  isSensitiveDealDataPermissionLoaded: PropTypes.bool,
  canAccessCountyCriminalRecords: PropTypes.bool,
  Summary: PropTypes.elementType,
  showAttachmentsHeaders: PropTypes.bool,
  showAttachmentsVisibilityAction: PropTypes.bool,
  visibleAttachments: PropTypes.oneOf([
    VisibleAttachments.ALL,
    VisibleAttachments.PUBLIC,
  ]),
  creditBackgroundIndicator: PropTypes.elementType,
  incomeBalancesIndicator: PropTypes.elementType,
  verificationsIndicator: PropTypes.elementType,
  applicantWorkflow: PropTypes.object,
  payments: PropTypes.array,
  lumenRun: PropTypes.object,
}

Application.defaultProps = {
  application: undefined,
  showOtherApplicants: false,
  showProperty: false,
  showDateStamp: false,
  getDeal: undefined,
  editIncomeAndAssets: undefined,
  removeApplicant: undefined,
  showDropdown: true,
  ApplicantDropdown: undefined,
  ConditionallyProceedInfoBanner: undefined,
  showConditionallyProceedInfoBanner: false,
  openWithdraw: undefined,
  requiredAttachments: [],
  requiredAttachmentsRaw: [],
  otherAttachments: [],
  otherAttachmentsRaw: [],
  addApplicationAttachments: undefined,
  deleteApplicationAttachments: undefined,
  confirmApplicationAttachments: undefined,
  cancelApplicationAttachments: undefined,
  attachmentActionInProgress: false,
  canAccessSensitiveDealData: false,
  isSensitiveDealDataPermissionLoaded: false,
  canAccessCountyCriminalRecords: false,
  canViewOcrResults: false,
  Summary: DefaultSummary,
  showAttachmentsHeaders: false,
  showAttachmentsVisibilityAction: false,
  applicantWorkflow: {},
  payments: [],
  lumenRun: {},
}

export default Application
