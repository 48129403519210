import React, { useEffect, useState } from "react";

import useModal from "shared/hooks/useModal";

import { ITEM_NAME, bulkUpdateAttachments } from "./config";
import DealSetupAttachmentsModal from "./DealSetupAttachmentsModal";

const DealSetupAttachmentsModalContainer = () => {
  const { currentModalContext } = useModal();
  const {
    objectId,
    contentType,
    items,
    forSettingsLabel,
    title,
    newItemProps,
  } = currentModalContext;

  const [isSubmitting, setSubmitting] = useState(false);
  const [newItems, setNewItems] = useState([]);
  const [removedItems, setRemovedItems] = useState([]);
  const [savedItems, setSavedItems] = useState([...items]);
  const [isNewItemShown, setNewItemShown] = useState(false);

  useEffect(() => {
    if (isNewItemShown) {
      document.getElementById(ITEM_NAME).focus();
    }
  }, [isNewItemShown]);

  const onSaveChanges = (resolve, reject) => {
    const newAttachments = newItems.map(
      ({ label, applicantType, attachmentType }) => ({
        name: label,
        objectId,
        contentType,
        applicantType,
        isActive: true,
        attachmentType,
      })
    );
    const data = {
      newItems: newAttachments,
      removedItems: removedItems.map((item) => item.id),
      objectId,
      contentType,
    };
    bulkUpdateAttachments(data, resolve, reject);
  };

  const isDirty = newItems.length > 0 || removedItems.length > 0;

  const onRemoveNewItem = (id) =>
    setNewItems(newItems.filter((item) => item.id !== id));

  const onRemoveSavedItem = (id) => {
    const deletedItem = {
      ...savedItems.find((item) => item.id === id),
    };

    setSavedItems(savedItems.filter((item) => item.id !== id));
    setRemovedItems([...removedItems, deletedItem]);
  };

  const onSaveNewItem = (item) => {
    setNewItems([
      ...newItems,
      {
        id: `${Date.now()}`,
        label: item.itemName,
        isDefault: false,
        ...item,
        ...newItemProps,
      },
    ]);
  };

  const submitChanges = () =>
    new Promise((resolve, reject) => {
      onSaveChanges(resolve, reject);

      setSubmitting(true);
    }).finally(() => setSubmitting(false));

  const initialValues = {
    itemName: "",
  };

  return (
    <DealSetupAttachmentsModal
      title={title}
      forSettingsLabel={forSettingsLabel}
      initialValues={initialValues}
      submitChanges={submitChanges}
      newItemProps={newItemProps}
      isDirty={isDirty}
      isSubmitting={isSubmitting}
      savedItems={savedItems}
      newItems={newItems}
      onRemoveNewItem={onRemoveNewItem}
      onRemoveSavedItem={onRemoveSavedItem}
      onSaveNewItem={onSaveNewItem}
      isNewItemShown={isNewItemShown}
      setNewItemShown={setNewItemShown}
    />
  );
};

export default DealSetupAttachmentsModalContainer;
