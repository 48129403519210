import React from "react";

import Banner from "shared/components/Banner";
import Icon from "shared/components/Icon";

const ConditionallyProceedInfoBanner = () => {
  const message = `You can access your criminal check after the manager conditionally proceeds with your application.`;
  return <Banner message={message} Icon={Icon.OrangeExclamationSmall} />;
};

export default ConditionallyProceedInfoBanner;
