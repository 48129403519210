import React from "react";

import { FIELD_TYPES_LABELS } from "admin/config/constants";
import Icon from "shared/components/Icon";

import { FieldDescriptionProps } from "../interfaces";

import {
  DescriptionContent,
  Parameter,
  PropName,
  PropValue,
  IconWrapper,
} from "./styled";

const FieldDescription = ({ field }: FieldDescriptionProps) => (
  <DescriptionContent>
    {field.displayedType && (
      <Parameter>
        <PropName>Type: </PropName>
        <PropValue>
          {field.displayedType}
          {field.displayedType === FIELD_TYPES_LABELS.SM && (
            <IconWrapper>
              <Icon.SmartFieldIcon />
            </IconWrapper>
          )}
          {field.displayedType === FIELD_TYPES_LABELS.CB && (
            <IconWrapper checkbox>
              <Icon.CheckboxFieldIcon />
            </IconWrapper>
          )}
        </PropValue>
      </Parameter>
    )}
    {field.displayedCategory && (
      <Parameter>
        <PropName>Category: </PropName>
        <PropValue>{field.displayedCategory}</PropValue>
      </Parameter>
    )}
    {field.dataMapping && (
      <Parameter>
        <PropName>Data Mapping: </PropName>
        <PropValue>{field.dataMapping}</PropValue>
      </Parameter>
    )}
    {field.format && (
      <Parameter>
        <PropName>Format: </PropName>
        <PropValue>{field.format}</PropValue>
      </Parameter>
    )}
    {field.sample && (
      <Parameter>
        <PropName>Sample: </PropName>
        <PropValue>{field.sample}</PropValue>
      </Parameter>
    )}
    {field.description && (
      <Parameter>
        <PropName>Description: </PropName>
        <PropValue>{field.description}</PropValue>
      </Parameter>
    )}
  </DescriptionContent>
);

export default FieldDescription;
