import styled from "@emotion/styled";

const DropdownWrapper = styled("div")({
  "&.dropdown-container:not(:first-of-type)": {
    marginTop: "24px",
  },
  ".input-description": {
    fontWeight: 400,
    marginBottom: "17px",
    display: "block",
  },
  ".link-description": {
    marginBottom: 0,
    fontWeight: 300,
  },
});

export { DropdownWrapper };
