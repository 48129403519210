import React, { ReactNode } from "react";

import { Dropdown, Menu } from "antd";

import { DropDownProps } from "antd/lib/dropdown";

import Icon from "shared/components/Icon";
import Tooltip from "shared/components/Tooltip";

import { DropdownMenuItem } from "./interfaces";
import { DropdownLink, MenuItem } from "./styled";

const itemClick = (allItems: DropdownMenuItem[], key: number | string) => {
  const clickedItem = allItems.filter((item) => item.key === key)[0];
  clickedItem.onClick();
};

const withTooltip = ({ tooltip, label }) => {
  if (tooltip) {
    return <Tooltip {...tooltip}>{label}</Tooltip>;
  }

  return label;
};

interface DropdownMenuProps extends Partial<DropDownProps> {
  children?: ReactNode;
  items: DropdownMenuItem[];
  light?: boolean;
}

const DropdownMenu = ({
  children = <Icon.HoverTracker Icon={Icon.DropdownIcon} />,
  items,
  placement = "bottomLeft",
  onVisibleChange,
  light = false,
  align,
  ...props
}: DropdownMenuProps) => (
  <Dropdown
    placement={placement}
    overlay={
      <Menu
        className={light ? "light-dropdown" : ""}
        onClick={({ key }) => {
          itemClick(items, key);

          if (onVisibleChange) {
            onVisibleChange(false);
          }
        }}
      >
        {items.map(({ key, label, color, ltMdOnly, disabled, tooltip }) => (
          <MenuItem
            key={key}
            color={color}
            ltMdOnly={ltMdOnly}
            disabled={disabled}
            data-testid={key}
          >
            {withTooltip({ label, tooltip })}
          </MenuItem>
        ))}
      </Menu>
    }
    trigger={["click"]}
    onVisibleChange={onVisibleChange}
    align={align}
  >
    <DropdownLink
      data-testid="dropdown-menu"
      className="ant-dropdown-link"
      {...props}
    >
      {children}
    </DropdownLink>
  </Dropdown>
);

export default DropdownMenu;
