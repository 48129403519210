import React from "react";

import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";

import { PropertyIntegrationMark } from "manager/components/IntegrationMark";
import ROUTES from "manager/config/routes";
import { getIntegrationType } from "manager/utils/integration.util";
import { Checkbox } from "shared/components/Form";
import Icon from "shared/components/Icon";
import Table from "shared/components/Table";
import Tooltip from "shared/components/Tooltip";
import {
  BREAKPOINT_RESOLUTIONS,
  INTEGRATION_TYPES,
} from "shared/config/constants";
import { transformRoute } from "shared/utils/routing";

import { PropertiesTableProps } from "../interfaces";

import {
  PortfolioLabel,
  GreyLabel,
  PropertyIntegrationMarkContainer,
} from "./styled";

const PropertiesTable = ({
  properties = [],
  assignEmployeesToProperty,
  unassignPropertyFromPortfolio,
  assignPropertyToPortfolio,
  createUnit,
  loading,
  propertiesCount,
  hasInputValue,
  setPagination,
  pagination,
  propertiesSearchParams,
  onTogglePropertyDisplayOnPortal,
  onTogglePropertyPrequalification,
  isMagicPortalEnabled,
  showPrequalificationCheckbox,
}: PropertiesTableProps) => {
  const history = useHistory();
  const isXxlScreen = useMediaQuery({ minWidth: BREAKPOINT_RESOLUTIONS.xxl });
  const isXxxlScreen = useMediaQuery({ minWidth: BREAKPOINT_RESOLUTIONS.xxxl });

  const { page, pageSize } = pagination;

  const onChangePropertyDisplayPortal = (propertyId) =>
    onTogglePropertyDisplayOnPortal(propertyId);

  const onChangePropertyPrequalification = (propertyId) =>
    onTogglePropertyPrequalification(propertyId);

  const onPaginationChange = (currentPage) => {
    const newPagination = { page: currentPage, pageSize };
    setPagination(newPagination);
  };

  const getFullAddressNode = (address: string, index: number) => {
    // eslint-disable-next-line no-nested-ternary
    const isTooltipLength = isXxxlScreen ? 92 : isXxlScreen ? 71 : 57;

    return (
      <Tooltip
        theme="light"
        placement="topRight"
        title={address.length < isTooltipLength ? "" : address}
      >
        <GreyLabel data-testid={`property-${index}-address`}>
          {address}
        </GreyLabel>
      </Tooltip>
    );
  };

  const getNameWithTooltip = (property, dataTestId) => {
    const { name } = property;
    const integrationEntrata =
      getIntegrationType(property) === INTEGRATION_TYPES.ENTRATA;

    // eslint-disable-next-line no-nested-ternary
    const tooltipLength = isXxxlScreen ? 50 : isXxlScreen ? 30 : 20;
    const isLongName = name.length > tooltipLength;
    const Text = (
      <GreyLabel data-testid={dataTestId}>
        <Tooltip
          theme="light"
          placement="topLeft"
          title={isLongName ? name : ""}
        >
          {`${name.substring(0, tooltipLength)}${isLongName ? "..." : ""}`}
        </Tooltip>
        <PropertyIntegrationMarkContainer
          opacity={integrationEntrata ? 0.75 : 1}
        >
          <PropertyIntegrationMark property={property} />
        </PropertyIntegrationMarkContainer>
      </GreyLabel>
    );

    return Text;
  };

  const propertiesRows = () =>
    properties.map((property, index) => {
      const fullAddress = `${property.address}, ${property?.city}, ${property?.state} ${property?.zipcode}`;

      return {
        key: property.id,
        values: [
          getNameWithTooltip(property, `property-${index}-name`),
          getFullAddressNode(fullAddress, index),
          property.portfolioIsDefault ? (
            <GreyLabel>No portfolio</GreyLabel>
          ) : (
            <PortfolioLabel>
              {property.portfolioName}
              {property.dealSetupId && (
                <Tooltip
                  theme="light"
                  title="This property has settings different from current portfolio settings"
                >
                  <Icon.InfoIcon />
                </Tooltip>
              )}
            </PortfolioLabel>
          ),
          ...(isMagicPortalEnabled
            ? [
                // @ts-ignore
                <Checkbox
                  id={`displayOnPortal-${property.id}`}
                  onChange={() => onChangePropertyDisplayPortal(property.id)}
                  value={property.displayOnPortal}
                />,
              ]
            : []),
          ...(showPrequalificationCheckbox
            ? [
                // @ts-ignore
                <Checkbox
                  id={`prequalification-${property.id}`}
                  onChange={() => onChangePropertyPrequalification(property.id)}
                  value={property.eligibleForPrescreening}
                />,
              ]
            : []),
          <span data-testid={`property-${index}-numUnits`}>
            {`${property.numUnitsRented} / ${property.numUnits}`}
          </span>,
        ],
        dropdownItems: [
          {
            key: "NEW_UNIT",
            label: "+ New Unit",
            onClick: () => {
              createUnit({
                propertyName: property.name,
                propertyId: property.id,
                propertiesSearchParams,
              });
            },
          },
          {
            key: "ASSIGN_EMPLOYEES",
            label: "Assign Employees",
            onClick: () =>
              assignEmployeesToProperty({
                propertyId: property.id,
                propertyName: property.name,
              }),
          },
          property.portfolioIsDefault
            ? {
                key: "ASSIGN_TO_PORTFOLIO",
                label: "Assign to portfolio",
                onClick: () =>
                  assignPropertyToPortfolio({
                    propertyId: property.id,
                    propertyName: property.name,
                    portfolioId: property.portfolio,
                  }),
              }
            : {
                key: "UNASSIGN_FROM_PORTFOLIO",
                label: "Unassign From Portfolio",
                onClick: () =>
                  unassignPropertyFromPortfolio({
                    portfolioId: property.portfolio,
                    portfolioName: property.portfolioName,
                    property: {
                      id: property.id,
                      name: property.name,
                    },
                  }),
              },
        ],
      };
    });

  return (
    <Table
      searching={hasInputValue}
      dropdownItems
      alt
      loading={loading}
      columns={[
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          sorter: (a, b) =>
            a.name.props.children[0].localeCompare(b.name.props.children[0]),
        },
        {
          title: "Address",
          dataIndex: "address",
          key: "address",
        },
        {
          title: "Portfolio",
          dataIndex: "portfolio",
          key: "portfolio",
          sorter: (a, b) =>
            a.portfolio.props.children[0].localeCompare(
              b.portfolio.props.children[0]
            ),
        },
        ...(isMagicPortalEnabled
          ? [
              {
                title: "Display on Portal",
                dataIndex: "displayOnPortal",
                key: "displayOnPortal",
                onCell: () => ({
                  className: "cursor-arrow",
                  onClick: (event) => {
                    event.preventDefault();
                    event.stopPropagation();
                  },
                }),
              },
            ]
          : []),
        ...(showPrequalificationCheckbox
          ? [
              {
                title: "Prequalification",
                dataIndex: "prequalification",
                key: "prequalification",
                onCell: () => ({
                  className: "cursor-arrow",
                  onClick: (event) => {
                    event.preventDefault();
                    event.stopPropagation();
                  },
                }),
              },
            ]
          : []),
        {
          title: "Units: Rented / All",
          dataIndex: "numUnits",
          key: "numUnits",
        },
      ]}
      rows={propertiesRows()}
      columnBreakpoints={{
        xs: {
          column1: "name",
          column2: "numUnits",
        },
        sm: ["name", "numUnits", "displayOnPortal", "prequalification"],
        md: ["name", "numUnits", "displayOnPortal", "prequalification"],
        lg: ["name", "numUnits", "displayOnPortal", "prequalification"],
        xl: [
          "name",
          "portfolio",
          "numUnits",
          "displayOnPortal",
          "prequalification",
        ],
      }}
      onRowClick={(key) =>
        history.push(transformRoute(ROUTES.property, { id: key }))
      }
      placeholder={{
        label: "Properties",
        Icon: Icon.NoPropertiesIcon,
      }}
      pagination={{
        current: page,
        onChange: onPaginationChange,
        total: propertiesCount,
        defaultPageSize: pageSize,
      }}
    />
  );
};

export default PropertiesTable;
