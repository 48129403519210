import styled from "@emotion/styled";

import FlexContainer from "shared/components/FlexContainer";
import { RegularText } from "shared/components/Typography";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

const UnitDealsWrapper = styled.div`
  background-color: ${COLORS.white};
  padding: 5px;

  ${MEDIA_QUERIES.w.ltSm} {
    & > div {
      width: 100%;
    }
  }
`;

const FilterWrapper = styled.div`
  background-color: ${COLORS.white};
  display: flex;
  padding: 20px 24px 28px 24px;

  ${MEDIA_QUERIES.w.ltLg} {
    padding-top: 5px;
    flex-direction: column;
    align-items: flex-start;
    & > div:first-of-type {
      margin-bottom: 10px;
    }

    div:nth-of-type(2) {
      padding-left: 0;
    }
  }

  ${MEDIA_QUERIES.w.ltSm} {
    padding: 15px 0;
  }
`;

const PrimaryText = styled(RegularText)`
  & ul li {
    margin-left: 0;
    padding-left: 0;
  }
`;

const FlexWrapper = styled(FlexContainer)`
  & > * {
    margin-right: 5px;
  }
`;

const Cell = styled(FlexContainer)`
  flex-direction: column;
  justify-content: center;
  min-height: 70px;
  ${MEDIA_QUERIES.w.ltSm} {
    min-height: auto;
  }
`;

const CheckboxContainer = styled.div`
  margin-top: 10px;
  margin-left: 21px;

  ${MEDIA_QUERIES.w.ltSm} {
    margin: 5px 0 0 0;
  }
`;

export {
  UnitDealsWrapper,
  FilterWrapper,
  PrimaryText,
  FlexWrapper,
  Cell,
  CheckboxContainer,
};
