import _ from 'lodash'
import { DEAL_CATEGORY_TYPES, DEAL_CATEGORIES } from 'manager/config/constants'
import { buildClassName } from 'shared/utils/vega'
import api from 'shared/lib/api'

export const splitAndJoin = (s: (string | string[])) => {
  return _.chain(s).castArray().compact().map(v => _.chain(v).split(',').map(_.trim).compact().value()).flattenDeep().join(',').value()
}

export function categoryToStatus (category, categories) {
  if (category) {
    category = _.toLower(category)

    if (category === 'all') {
      return _.chain(categories).values().flattenDeep().map('id').uniq().value()
    }

    return (_.chain(categories).get(category) as any).flattenDeep().map('id').uniq().value()
  }
}

export function statusToCategory (status, categories) {
  return _.findKey(categories, statuses => _.chain(statuses).map('id').includes(status).value())
}

export function statusToCategoryLabel (status, categories) {
  // @ts-ignore
  const map = _.chain(categories).values().flattenDeep().reduce((r, o: any) => _.set(r, o?.id, o?.label || o?.value || o?.id), {}).value()

  if (status) {
    status = _.toUpper(status)
    return _.get(map, status, status)
  }
}

export function buildCategoryItems (aggregations, categories) {
  return _.chain(aggregations).map(
    (count, status) => ({
      key: statusToCategory(status, categories),
      count
    })
  ).concat(
    _.chain(DEAL_CATEGORY_TYPES).values().map(c => ({ key: c, count: 0 })).value()
  ).groupBy('key').map(
    (v, k) => ({
      key: k,
      name: k,
      count: _.sumBy(v, 'count')
    })
  ).sortBy(o => _.chain(DEAL_CATEGORIES).map('key').invert().get(o?.key).toSafeInteger().value()).value()
}

export function onRowClick (path) {
  return (event) => {
    if (event?.altKey || event?.metaKey) {
      window.open(path)
    } else {
      window.location.href = path
    }
  }
}

export function search (company, indexes, sort, aggs, params, next, size = 500) {
  return api.search(_.omitBy({
    company,
    indexes: splitAndJoin(indexes),
    sort: splitAndJoin(sort),
    aggs: splitAndJoin(aggs),
    next,
    size,
    ...params
  }, _.isNil))
}

export const Location = ({ application }) => {
  const unit = _.first(application?.unit) as any
  const property = _.first(application?.property) as any

  return (
    <div>
      {unit && <div className='vega title small'>{unit?.name}</div>}
      <span className={buildClassName(['vega', property?.marketingName ? 'sub-title' : 'id'])}>{property?.marketingName || unit?.property || application?.location}</span>
    </div>
  )
}
