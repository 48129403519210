export const SIZES = {
  sm: "sm",
  md: "md",
  default: "default",
};

export enum SIZES_TYPES {
  sm = "sm",
  md = "md",
  default = "default",
}
