import { string } from "yup";

import { useCreateApplicationAuditNote } from "manager/hooks/api";
import SingleInputModal, {
  INPUT_MODE,
} from "shared/components/Modals/SingleInputModal";
import useModal from "shared/hooks/useModal";

const getAddNoteModalProps = ({ createAuditNote }) => ({
  title: "Add Note",
  submit: ({ value: note }) => createAuditNote(note),
  inputMode: INPUT_MODE.multiline,
  valueValidationSchema: string().nullable().required("This field is required"),
  inputPlaceholder: "Note text",
  maxLength: 500,
  confirmLabel: "SUBMIT",
});

const useOpenCreateDealAuditNoteModal = ({ dealId }) => {
  const { openModalDialog } = useModal();
  const { createApplicationAuditNote } = useCreateApplicationAuditNote();

  const createAuditNote = (note) =>
    createApplicationAuditNote({ id: dealId, note });

  const openCreateAuditNoteModal = () => {
    openModalDialog(
      SingleInputModal,
      getAddNoteModalProps({ createAuditNote })
    );
  };

  return {
    openCreateAuditNoteModal,
  };
};

export { useOpenCreateDealAuditNoteModal };
