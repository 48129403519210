export const WORKFLOWS_DISPLAY_NAMES = {
  "person:login": "Login Step",
  "applicant:type:select": "Select Type",
  "application:lease-preferences": "Lease Preferences",
  "terms:show": "Terms & Conditions",
  "terms:show:conditional-offer-of-housing": "Conditional Offer of Housing Terms & Conditions",
  "persona:connect": "ID Verification",
  "applicant:basic-info": "Basic Info",
  "plaid:connect": "Add Financials",
  "employment:input": "Income Sources",
  "argyle:connect": "Add Income",
  "income:input": "Income Sources",
  "background-credit:connect": "Credit & Background Check",
  "conditional-proceed:wait": "Wait for Conditional Proceed",
  "address:input": "Residential History",
  "pet:input": "Add a pet",
  "vehicle:input": "Add a vehicle",
  "applicant:questionnaire": "Additional Question(s)",
  "reference:input": "Add References",
  "contact:input": "Emergency Contacts",
  "applicant:submit": "Review & Submit",
  "property:select": "Select a property",
  "unit:select": "Select a unit",
  "doc:upload": "Document Upload",
  "payment:pay": "Payment",
};
