import React, { ReactNode, useEffect } from "react";

import { Col, Row } from "antd";
import dayjs from "dayjs";
import { Formik, FormikProps } from "formik";
import flatten from "lodash/flatten";
import { Moment } from "moment";
import pluralize from "pluralize";

import UnitIntegrationMark from "manager/components/IntegrationMark/UnitIntegrationMark";
import ErrorList from "shared/components/ErrorList";
import {
  CollapsibleRadioList,
  DatePicker,
  MaskedInput,
  SearchableList,
  VeroFormField,
} from "shared/components/Form";
import Icon from "shared/components/Icon";
import { Modal } from "shared/components/Modal";
import Spacer from "shared/components/Spacer";
import Tooltip from "shared/components/Tooltip";
import { RegularText, SmallText } from "shared/components/Typography";
import { DATE_FORMAT } from "shared/config/constants";
import { useDeviceType } from "shared/hooks";

import { printDollarsFromCents } from "shared/utils/dollar-print";
import { validationSchema } from "./config";
import {
  AssignToUnitFilter,
  AssignToUnitFormValues,
  Property,
  Unit,
} from "./interfaces";
import { LeaseWarning, ListingForm, StyledModal, WarningIcon } from "./styled";

const getUnitDescription = (unit: Unit, unitDisabled: boolean) => {
  const description = [];
  const separatorProps = {
    style: { margin: "0 14px" },
  };

  let rent: string;

  if (unit.isRented) {
    rent = "Rented";
  } else if (unit.unitListingCurrent) {
    rent = printDollarsFromCents(unit.unitListingCurrent.rent);
  } else {
    rent = "No listing";
  }

  description.push({
    node: <SmallText disabled={unitDisabled}>{rent}</SmallText>,
    noSeparator: true,
    separatorProps,
  });

  const availableOn = {
    node: (
      <SmallText disabled={unitDisabled}>
        {unit.unitListingCurrent?.unitAvailableOn &&
          `Available on ${dayjs(unit.unitListingCurrent.unitAvailableOn).format(
            DATE_FORMAT
          )}`}
      </SmallText>
    ),
    separatorProps,
  };

  const utilities = {
    node: (
      <SmallText disabled={unitDisabled}>
        {/* @ts-ignore */}
        {`${unit.bedrooms || "N/A"} ${pluralize("bedroom", unit.bedrooms)}, ${
          unit.bathrooms || "N/A"
          // @ts-ignore
        } ${pluralize("bathroom", unit.bathrooms)}`}
      </SmallText>
    ),
    separatorProps,
  };

  const activeDeals = {
    node: (
      <SmallText disabled={unitDisabled}>
        {unit.activeDeals > 0
          ? `${unit.activeDeals} active ${pluralize(
              "application",
              unit.activeDeals
            )}`
          : "No active applications"}
      </SmallText>
    ),
    separatorProps,
  };

  if (!unit.isRented && unit.unitListingCurrent) {
    description.push(...[availableOn, utilities, activeDeals]);
  }

  return description;
};

const getUnitName = (
  unit: Unit,
  unitDisabled: boolean,
  tooltipMessage: string
) => {
  return unitDisabled ? (
    <Tooltip theme="light" trigger="hover" title={tooltipMessage}>
      {unit.name}
    </Tooltip>
  ) : (
    <>{unit.name}</>
  );
};

export interface DatePickerOptions {
  unitAvailableOn?: string;
  newLeaseStartDisabledDateFn?: (current: string) => boolean;
  newLeaseStartDate?: string;
}

export interface AssignToUnitModalProps {
  title: string;
  subtitle: ReactNode;
  note?: ReactNode;
  submitButtonLabel?: string;
  masterWarning?: ReactNode;
  leaseWarning?: string;
  datePickerOptions: DatePickerOptions;
  setDatePickerOptions: (options: DatePickerOptions) => void;
  modalExtension?: ReactNode;
  properties: Property[];
  filter: AssignToUnitFilter;
  loading: boolean;
  unitById: (id: number | string) => Unit | undefined;
  getUnits: (propertyId: number) => void;
  submit: Function;
  formikRef: React.MutableRefObject<FormikProps<AssignToUnitFormValues>>;
  initialValues: AssignToUnitFormValues;
  updateWarning: (values: AssignToUnitFormValues) => void;
  updateFilter: (filterValue: string) => void;
  isUnitDisabled: (unit: Unit) => {
    unitDisabled: boolean;
    tooltipMessage: string;
  };
  cancelLinkLabel?: string;
  closeOnSubmit: boolean;
}

const AssignToUnitModal = ({
  title,
  subtitle,
  note,
  submitButtonLabel = "Assign to unit",
  masterWarning,
  leaseWarning,
  datePickerOptions,
  setDatePickerOptions,
  modalExtension,
  properties,
  filter,
  loading,
  unitById,
  getUnits,
  submit,
  formikRef,
  initialValues,
  updateWarning,
  updateFilter,
  isUnitDisabled,
  cancelLinkLabel,
  closeOnSubmit,
}: AssignToUnitModalProps) => {
  const { isMobile } = useDeviceType();

  return (
    <StyledModal
      // @ts-ignore
      title={title}
      subtitle={subtitle}
      submit={formikRef?.current?.handleSubmit}
      submitButtonLabel={submitButtonLabel}
      width="auto"
      style={{ maxWidth: 946 }}
      submitting={formikRef?.current?.isSubmitting}
      fullScreen={isMobile}
      cancelLinkLabel={cancelLinkLabel}
      closeOnSubmit={closeOnSubmit}
    >
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, formikHelpers) =>
          submit(
            {
              unitId: values?.unitId,
              unitListing: values?.unitListingRequired
                ? values?.unitListing
                : null,
              newLeaseStartDate: datePickerOptions?.newLeaseStartDate,
              extraParams: values?.extraParams,
            },
            formikHelpers
          )
        }
        validateOnMount={false}
      >
        {({ values, errors, setValues, setErrors, dirty }) => {
          useEffect(() => {
            if (dirty) {
              updateWarning(values);
            }
          }, [dirty, values]);

          return (
            <>
              <Modal.Body
                noMobilePaddingX
                noPaddingBottom={values?.unitListingRequired && !masterWarning}
              >
                {!masterWarning && (
                  <>
                    {note}
                    {errors.unitId && <ErrorList errors={[errors.unitId]} />}
                    <SearchableList
                      hasInputValue={Boolean(filter.nameOrAddress)}
                      loading={loading}
                      placeholder={{
                        label: "Units",
                        Icon: Icon.NoUnitsIcon,
                      }}
                      search={{
                        fieldName: "propertyAddressName",
                        label: "Search property or address...",
                        action: (filterValue) => updateFilter(filterValue),
                        width: "288px",
                        debounce: true,
                        disabled: loading,
                        loading,
                      }}
                      List={CollapsibleRadioList}
                      listContainerProps={{
                        groupName: "deals",
                      }}
                      listProps={{
                        col1Width: "200px",
                        setFieldValue: (item: number | string) => {
                          const unit = unitById(item);
                          setErrors({});
                          setValues(
                            {
                              unitId: item,
                              unitListingRequired:
                                !unit?.unitListingCurrent &&
                                !unit?.yardiConnected,
                              unitListing: {
                                rent: null,
                                unitAvailableOn: "",
                                securityDeposit: null,
                                unit: item,
                              },
                            },
                            false
                          );
                        },
                        selectedItem: values?.unitId || initialValues?.unitId,
                        items: loading
                          ? []
                          : flatten(
                              properties?.map((property) => {
                                return [
                                  {
                                    key: `property${property.id}`,
                                    label: property.name,
                                    description: `${property.address}, ${property.city}, ${property.zipcode}`,
                                    collapsible: true,
                                    onCollapseHeadingClick: () =>
                                      getUnits(property.id),
                                  },
                                  ...property.units.map((unit) => {
                                    const { unitDisabled, tooltipMessage } =
                                      isUnitDisabled(unit);
                                    return {
                                      key: unit.id,
                                      label: (
                                        <>
                                          {getUnitName(
                                            unit,
                                            unitDisabled,
                                            tooltipMessage
                                          )}
                                          <UnitIntegrationMark
                                            property={property}
                                            unit={unit}
                                          />
                                        </>
                                      ),
                                      parent: `property${property.id}`,
                                      description: getUnitDescription(
                                        unit,
                                        unitDisabled
                                      ),
                                      disabled: unitDisabled,
                                    };
                                  }),
                                ];
                              })
                            ),
                        itemProps: {
                          width: 830,
                          className: "assign-unit-list-item",
                        },
                      }}
                    />
                  </>
                )}

                {(masterWarning || leaseWarning) && (
                  <LeaseWarning>
                    <WarningIcon data-testid="warning">
                      <Icon.FirstVerificationErrorSmallIcon />
                    </WarningIcon>
                    <strong>{masterWarning || leaseWarning}</strong>
                  </LeaseWarning>
                )}

                {datePickerOptions && (
                  <>
                    <br />
                    <DatePicker
                      id="newLeaseStartDate"
                      name="newLeaseStartDate"
                      label="Update the preferred move-in date"
                      value={datePickerOptions.unitAvailableOn}
                      disabledDate={(date: Moment) =>
                        datePickerOptions.newLeaseStartDisabledDateFn(
                          date.toString()
                        )
                      }
                      onChange={(x: string) =>
                        setDatePickerOptions({
                          ...datePickerOptions,
                          newLeaseStartDate: x,
                        })
                      }
                    />
                  </>
                )}

                {modalExtension}
              </Modal.Body>
              {values?.unitListingRequired && !masterWarning && (
                <>
                  <Spacer size={Spacer.SIZES.sm} />
                  <Modal.Body noPaddingTop>
                    <ListingForm noBorder>
                      <RegularText data-testid="no-listing-hint">
                        Please fill data for the new listing for this unit.
                      </RegularText>
                      <Spacer />
                      <Row gutter={[20, 20]}>
                        <Col md={8}>
                          {/* @ts-ignore */}
                          <MaskedInput.Formik.Money
                            id="rent"
                            name="unitListing.rent"
                            label="Unit Rent"
                            data-testid="rent-input"
                          />
                        </Col>
                        <Col md={8}>
                          {/* @ts-ignore */}
                          <MaskedInput.Formik.Money
                            id="securityDeposit"
                            name="unitListing.securityDeposit"
                            label="Security Deposit"
                            data-testid="deposit-input"
                          />
                        </Col>
                        <Col md={8}>
                          {/* @ts-ignore */}
                          <VeroFormField
                            as={DatePicker}
                            id="unitAvailableOn"
                            name="unitListing.unitAvailableOn"
                            label="Date Available"
                            data-testid="date-input"
                          />
                        </Col>
                      </Row>
                    </ListingForm>
                  </Modal.Body>
                </>
              )}
            </>
          );
        }}
      </Formik>
    </StyledModal>
  );
};

export default AssignToUnitModal;
