import { Key } from "react";

import axios from "axios";

import { Employee } from "manager/interfaces/api/employee";

import ROUTES from "shared/config/apiRoutes";
import { transformRoute } from "shared/utils/routing";

import { API_URL } from "./setupApi";

export const getEmployees = (params: {
  name?: string;
  notAssignedToProperty?: string | number;
  roleId?: Key;
  nameOrEmail?: string;
  activeOnly?: boolean;
  page?: number;
  pageSize?: number;
}) =>
  axios
    .get(`${API_URL}${ROUTES.getEmployees}`, {
      params: {
        name: params?.name,
        not_assigned_to_property: params?.notAssignedToProperty,
        role: params?.roleId,
        name_or_email: params?.nameOrEmail,
        active_only: params?.activeOnly,
        page: params?.page,
        page_size: params?.pageSize,
      },
    })
    .then(({ data }) => data);

export const deactivateEmployee = (employeeId: number) =>
  axios.patch(
    transformRoute(`${API_URL}${ROUTES.deactivateEmployee}`, { employeeId })
  );

export const getEmployeePortfoliosProperties = (employeeId: number) =>
  axios
    .get(
      transformRoute(`${API_URL}${ROUTES.employeePortfoliosProperties}`, {
        employeeId,
      })
    )
    .then(({ data }) => data);

export const assignEmployeePortfoliosProperties = (
  employeeId: number,
  { portfolios, properties }
) =>
  axios.post(
    transformRoute(`${API_URL}${ROUTES.assignPortfoliosProperties}`, {
      employeeId,
    }),
    { portfolios, properties }
  );

export const unassignEmployeePortfoliosProperties = (
  employeeId: Key,
  { portfolios, properties }
) =>
  axios.post(
    transformRoute(`${API_URL}${ROUTES.unassignPortfoliosProperties}`, {
      employeeId,
    }),
    { portfolios, properties }
  );

export const getEmployee = (employeeId: number) =>
  axios
    .get<Employee>(
      transformRoute(`${API_URL}${ROUTES.getEmployee}`, {
        employeeId,
      })
    )
    .then(({ data }) => data);

export const exportEmployees = (params: {
  roleId?: Key;
  nameOrEmail?: string;
  activeOnly?: boolean;
}) =>
  axios.get(`${API_URL}${ROUTES.exportEmployees}`, {
    params: {
      role: params?.roleId,
      name_or_email: params?.nameOrEmail,
      active_only: params?.activeOnly,
    },
  });
