import React from "react";

import { Row, Col } from "antd";

import Spacer from "shared/components/Spacer";
import Tile from "shared/components/Tile";
import {
  VerificationStatus,
  NoVerificationsPlaceholder,
  IdVerificationDetails,
} from "shared/components/Verification";

import { verificationPropType } from "shared/utils/propTypes";

const IdVerification = ({ verification }) => {
  return (
    <>
      {!verification && (
        <NoVerificationsPlaceholder message="ID verification not available" />
      )}
      {verification && (
        <Tile noBorder>
          <Tile.Inner>
            <Row gutter={[16, 16]} type="flex">
              <Col span={24}>
                <VerificationStatus
                  type={verification.verificationType}
                  status={verification.status}
                  verificationDate={verification.verificationDate}
                />
              </Col>
            </Row>
            <Spacer />
            <IdVerificationDetails verification={verification} inset />
          </Tile.Inner>
        </Tile>
      )}
    </>
  );
};

IdVerification.propTypes = {
  verification: verificationPropType,
};

IdVerification.defaultProps = {
  verification: undefined,
};

export default IdVerification;
