import styled from "@emotion/styled";

import { COLORS } from "shared/config/constants";

const LightTitle = styled.div`
  background-color: ${COLORS.lightGray2};
  padding: 10px;
`;

export { LightTitle };
