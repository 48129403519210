import React from "react";

import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";

import { MAGIC_LINK_TYPE } from "manager/pages/MagicLinks/config";
import { PrimaryText, SecondaryText } from "manager/pages/MagicLinks/styled";
import { Button } from "shared/components/Button";
import FilterBar from "shared/components/FilterBar";
import Icon from "shared/components/Icon";
import { ActionLink } from "shared/components/Links";
import MobileFilters from "shared/components/MobileFilters";
import Table from "shared/components/Table";
import { BREAKPOINT_RESOLUTIONS } from "shared/config/constants";

import {
  FiltersContainer,
  ActionButtonWrapper,
  MobileActionWrapper,
} from "../styled";

const AgentsTable = ({
  isAgentsLoading,
  agents,
  roles,
  updateFilters,
  filters,
  onTableChange,
  onExportLinks,
  onShareLinks,
}) => {
  const isMiddle = useMediaQuery({ maxWidth: BREAKPOINT_RESOLUTIONS.md });
  const isSmall = useMediaQuery({ maxWidth: BREAKPOINT_RESOLUTIONS.sm - 1 });

  const tableFilters = {
    title: "Filter Agents",
    search: {
      action: (value) => updateFilters({ nameOrEmail: value }),
      placeholder: "Search by name or email",
      width: isMiddle ? 260 : 320,
      debounce: true,
      disabled: false,
      value: filters.nameOrEmail,
    },
    dropdownFilters: [
      {
        key: "roles",
        label: "Roles",
        onChange: (value) => updateFilters({ role: value }),
        value: filters.role,
        items: [
          {
            label: "All Roles",
            key: "",
          },

          ...roles.map((item) => ({ label: item.name, key: `${item.id}` })),
        ],
        minWidth: 280,
      },
    ],
    action: {
      key: "EXPORT_LINKS",
      node: (
        <ActionButtonWrapper>
          <Button type="secondary" onClick={onExportLinks}>
            Export
          </Button>
        </ActionButtonWrapper>
      ),
    },
  };

  return (
    <>
      <FiltersContainer>
        <FilterBar {...tableFilters} justifyContent="left" />
        {isSmall && (
          <MobileActionWrapper>
            <Button type="secondary" onClick={onExportLinks}>
              Export
            </Button>
          </MobileActionWrapper>
        )}
        <MobileFilters {...tableFilters} />
      </FiltersContainer>
      <Table
        searching={Boolean(filters.nameOrEmail)}
        alt
        loading={isAgentsLoading}
        columns={[
          {
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: true,
          },
          {
            title: "Email",
            dataIndex: "email",
            key: "email",
          },
          {
            title: "",
            dataIndex: "share",
            key: "share",
            width: 150,
          },
        ]}
        columnBreakpoints={{
          xs: {
            column1: "name",
            column2: "share",
          },
          sm: ["name", "email", "share"],
          md: ["name", "email", "share"],
          lg: ["name", "email", "share"],
        }}
        placeholder={{
          Icon: Icon.NoEmployeesIcon,
          label: "Agents",
        }}
        rows={agents?.map((agent) => ({
          key: agent.id,
          values: [
            <PrimaryText data-testid={`name-${agent.id}`}>
              {agent.name}
            </PrimaryText>,
            <SecondaryText data-testid={`email-${agent.id}`}>
              {agent.email}
            </SecondaryText>,
            <div>
              <ActionLink
                data-testid={`share-${agent.id}`}
                Icon={Icon.QRCodeGreenIcon}
                onClick={() =>
                  onShareLinks({
                    magicLink: agent.magicLink,
                    name: agent.name,
                    prescreeningMagicLink: agent.prescreeningMagicLink,
                    type: MAGIC_LINK_TYPE.AGENT,
                  })
                }
              >
                Share
              </ActionLink>
            </div>,
          ],
        }))}
        onChange={onTableChange}
        noClickableRows
        expandable={["email"]}
      />
    </>
  );
};

AgentsTable.propTypes = {
  agents: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    })
  ),
  filters: PropTypes.shape({
    nameOrEmail: PropTypes.string,
    role: PropTypes.string,
  }).isRequired,
  isAgentsLoading: PropTypes.bool.isRequired,
  onTableChange: PropTypes.func.isRequired,
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  updateFilters: PropTypes.func.isRequired,
  onExportLinks: PropTypes.func.isRequired,
  onShareLinks: PropTypes.func.isRequired,
};

AgentsTable.defaultProps = {
  agents: [],
  roles: [],
};

export default AgentsTable;
