import React, { useState } from "react";

import { PSClickWrap } from "@pactsafe/pactsafe-react-sdk";

import { SmallErrorText } from "shared/components/Typography";
import { useGetPactSafeConfig } from "shared/hooks/api";
import { logError } from "shared/lib/errors";

import FlexContainer from "../FlexContainer";
import Icon from "../Icon";

import { CheckboxContainer } from "./styled";

export interface ClickwrapApi {
  sendUserAgreed: ({
    signerId,
    customData,
  }: {
    signerId: string;
    customData?: object;
  }) => void;
}

interface ClickwrapProps {
  id: string;
  name: string;
  groupKey: string;
  userEmail: string;
  customData?: any;
  onChange: (event: Event) => void;
  onBlur: (event: Event) => void;
  error?: string;
}

export const ClickwrapCheckbox = ({
  id,
  name,
  groupKey,
  userEmail,
  customData,
  onChange,
  onBlur,
  error,
}: ClickwrapProps) => {
  const { pactSafeConfig, isPactSafeConfigLoading } = useGetPactSafeConfig();
  const [isClickwrapLoading, setIsClickwrapLoading] = useState(true);

  return (
    !isPactSafeConfigLoading && (
      <CheckboxContainer>
        {isClickwrapLoading && (
          <FlexContainer justifyContent="center">
            <Icon.LoadingSpinnerGreenIcon width={20} height={20} />
          </FlexContainer>
        )}
        <PSClickWrap
          accessId={pactSafeConfig?.accessId}
          groupKey={groupKey}
          signerId={userEmail}
          customData={customData}
          clickWrapStyle="combined"
          onError={(message, eventType, context) =>
            logError({ message, eventType, context })
          }
          onDisplayed={(element: HTMLElement) => {
            setIsClickwrapLoading(false);
            const checkbox: HTMLInputElement =
              element.getElementsByTagName("input")[0];

            checkbox.setAttribute("id", id);
            checkbox.setAttribute("name", name);

            checkbox.addEventListener("change", (event: Event) => {
              onChange(event);
            });

            checkbox.addEventListener("blur", (event: Event) => {
              onBlur(event);
            });
          }}
        />
        {error && <SmallErrorText>{error}</SmallErrorText>}
      </CheckboxContainer>
    )
  );
};
