import { AdditionalQuestionModel } from "manager/interfaces/api/additionalQuestion";
import { ApplicantType } from "shared/interfaces/misc";

export enum AdditionalQuestionModalMode {
  CREATE = "CREATE",
  EDIT = "EDIT",
}
export interface AdditionalQuestionModalContext {
  forSettingsLabel: string;
  applicantType: ApplicantType;
  initialValues: AdditionalQuestionModel;
  mode: AdditionalQuestionModalMode;
  objectId: number;
  contentType: string;
}
