import _ from 'lodash'
import React, { useState } from 'react'
import { Input, Radio } from 'antd'
import { Button } from 'shared/components/Button'
import { buildClassName } from 'shared/utils/vega'
import { getScoreLabelStyle } from 'shared/components/vega/LumenV2'
import { Formula } from 'shared/components/vega/ExpressionEngine'
import LabelColor from './LabelColor'

const Outcome = ({ formula, data, canEdit, onChange, onDelete }) => {
  const color = getScoreLabelStyle(data?.scoreLabelColor)
  const handleChange = (key) => (value) => onChange(_.set(data, key, value))

  return (
    <div className='vega flex column'>
      <div className='vega flex'>
        <div>
          <div className='vega label'>formula</div>
          <Formula formula={formula} highlight />
        </div>

        <div>
          <div className='vega label'>result</div>
          <Radio.Group
            defaultValue={true}
            buttonStyle='solid'
            value={data?.result}
            onChange={e => handleChange('result')(e?.target?.value)}
            disabled={!canEdit}
          >
            <Radio.Button value={true} className='vega pass'>true</Radio.Button>
            <Radio.Button value={false} className='vega fail'>false</Radio.Button>
          </Radio.Group>
        </div>
      </div>

      <div className='vega flex'>
        <div>
          <div className='vega label'>label</div>
          <Input
            className={buildClassName(['vega lumen-score-label', color?.className && color?.className])}
            style={color?.style && color?.style}
            value={data?.scoreLabel}
            onChange={e => handleChange('scoreLabel')(e?.target?.value)}
            disabled={!canEdit}
          />
        </div>

        <div>
          <div className='vega label'>label color</div>
          <LabelColor labelColor={data?.scoreLabelColor} canEdit={canEdit} onChange={handleChange('scoreLabelColor')} />
        </div>
      </div>

      <div>
        <div className='vega label'>recommendations</div>
        <Input.TextArea
          value={_.join(data?.recommendations, '\n')}
          onChange={e => handleChange('recommendations')(_.split(e?.target?.value, '\n'))}
          autoSize={true}
          disabled={!canEdit}
        />
      </div>

      {canEdit && <div className='vega actions end'>
        <Button type='primary' onClick={onDelete}>delete</Button>
      </div>}
    </div>
  )
}

const Outcomes = ({ outcomes = {}, canEdit, onChange }) => {
  const [keyToAdd, setKeyToAdd] = useState(null)
  const handleChange = (k) => (value) => onChange(_.set(outcomes, [k], value))
  const handleDelete = (k) => () => onChange(_.omit(outcomes, [k]))

  return (
    <div className='vega flex column list bottom'>
      {_.map(outcomes, (data, formula) => <Outcome
        formula={formula}
        data={data}
        canEdit={canEdit}
        onChange={handleChange(formula)}
        onDelete={handleDelete(formula)}
        key={formula}
      />)}

      {canEdit && <div>
        <div className='vega label'>formula</div>
        <div className='vega flex no-wrap'>
          <Input value={keyToAdd} onChange={e => setKeyToAdd(e?.target?.value)} />

          <Button type='primary' onClick={() => {
            if (keyToAdd) {
              handleChange(keyToAdd)({})
              setKeyToAdd(null)
            }
          }}>add</Button>
        </div>
      </div>}
    </div>
  )
}

export default Outcomes
