
import pickBy from "lodash/pickBy";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { printDollarsFromCents } from "shared/utils/dollar-print";

import ROUTES from "renter/config/routes";
import FlexContainer from "shared/components/FlexContainer";
import { UnderlineLink } from "shared/components/Links";
import Tooltip from "shared/components/Tooltip";
import { RegularText, SmallText } from "shared/components/Typography";
import { useEllipsisTooltip } from "shared/hooks";
import { getQueryParams, transformRoute } from "shared/utils/routing";

import { Tile } from "./styled";

const PropertyTile = ({
  name,
  address,
  city,
  state,
  zipcode,
  startingPrice,
  hasUnits,
  logoUrl,
  companyHashId,
  hashedId,
  uuid,
  preventPropertiesWithoutUnit,
  companyUuid,
  isPrescreeningMagicLinksV2,
  isMagicLinksV2,
}) => {
  const { elementRef: nameRef, tooltipTitle: nameTooltip } = useEllipsisTooltip(
    { title: name, multiline: true }
  );

  const fullAddress = `${address}, ${city}, ${state} ${zipcode}`;

  const { elementRef: fullAddressRef, tooltipTitle: fullAddressTooltip } =
    useEllipsisTooltip({ title: fullAddress, multiline: true });

  const priceLabel =
    startingPrice && `STARTING AT: ${printDollarsFromCents(startingPrice)}`;

  let linkProps;
  if (isMagicLinksV2 || isPrescreeningMagicLinksV2) {
    const { application } = getQueryParams();

    const queryParams = pickBy({
      company: companyUuid,
      "property-source": "vero",
      property: uuid,
      application,
    });

    const buttonText = hasUnits
      ? "View Availability"
      : isPrescreeningMagicLinksV2
      ? "Prequalify Now"
      : "Apply Now";

    const buttonRoute = isPrescreeningMagicLinksV2
      ? ROUTES.prescreeningMagicLinkV2GetStarted
      : ROUTES.magicLinkV2GetStarted;

    if (!hasUnits) {
      queryParams["show-landing"] = true;
    }

    const queryParamsString = Object.keys(queryParams)
      .map((p) => `${p}=${queryParams[p]}`)
      .join("&");

    linkProps = {
      to: `${buttonRoute}?${queryParamsString}`,
      children: buttonText,
    };
  } else if (hasUnits) {
    linkProps = {
      to: transformRoute(ROUTES.magicPortalCompanyProperty, {
        companyHashId,
        propertyHashId: hashedId,
      }),
      children: "View Availability",
    };
  } else {
    linkProps = {
      to: transformRoute(ROUTES.magicLinkGetStarted, {
        companyHashId: hashedId,
      }),
      children: "Apply Now",
    };
  }

  const PropertyPicture = () => {
    const picture = <div className="property-photo" />;
    return preventPropertiesWithoutUnit ? (
      picture
    ) : (
      <Link to={linkProps.to}>{picture}</Link>
    );
  };

  const PropertyLink = () => {
    if (
      !isPrescreeningMagicLinksV2 &&
      !hasUnits &&
      preventPropertiesWithoutUnit
    ) {
      return "No Availability";
    }
    return <UnderlineLink {...linkProps} />;
  };

  return (
    <Tile photo={logoUrl} className="magic-property-tile">
      <div className="property-tile-header">
        <Tooltip
          title={
            nameTooltip && (
              <div className="tooltip-inner-gray">{nameTooltip}</div>
            )
          }
          theme="light"
        >
          <RegularText extraStrong ref={nameRef}>
            {name}
          </RegularText>
        </Tooltip>
      </div>
      <FlexContainer className="property-tile-content">
        <PropertyPicture />
        <div className="property-description">
          <div className="property-address">
            <Tooltip
              title={
                fullAddressTooltip && (
                  <div className="tooltip-inner-gray">{fullAddressTooltip}</div>
                )
              }
              theme="light"
            >
              <SmallText ref={fullAddressRef}>{fullAddress}</SmallText>
            </Tooltip>
          </div>
          <SmallText extraStrong className="property-price">
            {priceLabel}
          </SmallText>
          <div>
            <PropertyLink />
          </div>
        </div>
      </FlexContainer>
    </Tile>
  );
};

PropertyTile.propTypes = {
  address: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zipcode: PropTypes.string,
  name: PropTypes.string.isRequired,
  startingPrice: PropTypes.number,
  hasUnits: PropTypes.bool.isRequired,
  logoUrl: PropTypes.string,
  companyHashId: PropTypes.string.isRequired,
  hashedId: PropTypes.string.isRequired,
  uuid: PropTypes.string,
  preventPropertiesWithoutUnit: PropTypes.bool,
  companyUuid: PropTypes.string,
  isPrescreeningMagicLinksV2: PropTypes.bool,
  isMagicLinksV2: PropTypes.bool,
};

PropertyTile.defaultProps = {
  address: "",
  city: "",
  state: "",
  zipcode: "",
  logoUrl: "",
  startingPrice: undefined,
  preventPropertiesWithoutUnit: false,
};

export default PropertyTile;
