import React, { useMemo, useRef, useState } from "react";

import {
  useAssignLumenFormula,
  useGetLumenFormulaAssignments,
} from "manager/hooks/api";
import { useAfterSubmitCallbacksApi } from "shared/components/AfterSubmitCallbackRegisterProvider";
import { openClickwrapModal } from "shared/components/Modals/ClickwrapModal";
import StatusNotificationModal from "shared/components/Modals/StatusNotificationModal";
import { useGetPactSafeConfig, useActiveUser } from "shared/hooks/api";
import useModal from "shared/hooks/useModal";
import {
  sanitizeStringSearchValue,
  valueMatchesFilter,
} from "shared/utils/string";

import AssignFormulaToPropertiesModal from "./AssignFormulaToPropertiesModal";
import {
  useSelectedItemsSummary,
  useListItemsAndCheckedItems,
  showDisclaimerModal,
} from "./assignFormulaToPropertiesModal.utils";

interface AssignFormulaToPropertiesModalContainerProps {
  formula: any;
}

const AssignFormulaToPropertiesModalContainer = ({
  formula,
}: AssignFormulaToPropertiesModalContainerProps) => {
  const { openModalDialog, selfApi } = useModal();

  const formulaId = formula.id;
  const formulaName = formula.name;

  const { lumenFormulaAssignments, isLoading } =
    useGetLumenFormulaAssignments(formulaId);
  const { assignLumenFormula, isLoading: isUpdating } = useAssignLumenFormula();
  const [currentFilter, setCurrentFilter] = useState(null);
  const [checkedItems, setCheckedItems] = useState([]);
  const listApiRef = useRef();
  const { activeUser } = useActiveUser();
  const { pactSafeConfig } = useGetPactSafeConfig();
  const afterSubmitCallbackApi = useAfterSubmitCallbacksApi();

  const {
    items,
    checked: initiallyCheckedItems,
    warnings,
  } = useListItemsAndCheckedItems(
    lumenFormulaAssignments,
    checkedItems,
    listApiRef
  );

  const selectedItemsSummary = useSelectedItemsSummary(
    lumenFormulaAssignments,
    checkedItems
  );

  const visibleItems = useMemo(() => {
    const visibleKeys = new Set();
    (items || []).forEach((p) => {
      const matchesFilter =
        valueMatchesFilter(p.searchName, currentFilter) ||
        valueMatchesFilter(p.parentName, currentFilter) ||
        valueMatchesFilter(p.searchAddress, currentFilter);

      if (matchesFilter) {
        visibleKeys.add(p.key);
        if (p.parent) {
          visibleKeys.add(p.parent);
        }
      }
    });

    return (items || []).filter((p) => visibleKeys.has(p.key));
  }, [items, currentFilter]);

  const saveAssignments = async () => {
    const checkedItemsSet = new Set(checkedItems);
    const checkedItemsObj = items.filter((it) => checkedItemsSet.has(it.key));

    const portfolios = checkedItemsObj
      .filter((it) => !it.parent)
      .map((it) => it.key);
    const properties = checkedItemsObj
      .filter((it) => !!it.parent)
      .map((it) => it.originalId);

    const onSuccess = async (withAfterSubmitCallback) => {
      const message = `Successfully updated the availability of formula ${formulaName}!`;
      openModalDialog(StatusNotificationModal, { message });
      if (withAfterSubmitCallback) {
        afterSubmitCallbackApi.executeAfterSubmitCallbacks();
      }
    };

    const onSettled = () => selfApi.closeDialog();

    const saveChanges = ({ withAfterSubmitCallback }) => {
      const assignments = {
        portfolios,
        properties,
      };

      return assignLumenFormula(
        { formulaId, assignments },
        { onSuccess: () => onSuccess(withAfterSubmitCallback), onSettled }
      );
    };

    if (showDisclaimerModal(initiallyCheckedItems, checkedItems)) {
      openClickwrapModal({
        onConfirm: () => saveChanges({ withAfterSubmitCallback: true }),
        groupKey: pactSafeConfig?.termsOfServiceLumenGroupKey,
        userEmail: activeUser?.email,
        customData: {
          formulaId,
          portfolios,
          properties,
        },
        title: "Terms of Service",
        // @ts-ignore
        errorMessage: "The disclaimers must be accepted.",
      });
    } else {
      saveChanges({ withAfterSubmitCallback: false });
    }
  };

  const filterItems = (value) =>
    setCurrentFilter(sanitizeStringSearchValue(value));

  const searchProps = useMemo(
    () => ({
      fieldName: "propertyAddressName",
      id: "propertyAddressName",
      label: "Search by property, address or portfolio",
      action: (value) => filterItems(value),
      width: "370px",
      debounce: true,
    }),
    [filterItems]
  );

  return (
    <AssignFormulaToPropertiesModal
      listApiRef={listApiRef}
      formulaName={formulaName}
      items={items}
      visibleItems={visibleItems}
      initiallyCheckedItems={initiallyCheckedItems}
      checkedItems={checkedItems}
      setCheckedItems={setCheckedItems}
      saveAssignments={saveAssignments}
      selectedItemsSummary={selectedItemsSummary}
      warnings={warnings}
      isLoading={isLoading}
      isUpdating={isUpdating}
      searchProps={searchProps}
      hasWarnings={!!warnings?.length}
    />
  );
};

export default AssignFormulaToPropertiesModalContainer;
