import { toDate } from "../utils";

import { VerificationOfResidency } from "./interfaces";

export const mapVoR = (
  verifications?: Record<string, any>[],
  enabled?: boolean
): VerificationOfResidency | undefined => {
  if (!verifications) return undefined;
  const vor = verifications?.find(
    (verification) => verification?.verificationType === "VOR"
  );
  const details = vor?.details;
  if (!details) return undefined;
  const lowestScore = details.survey?.surveyQuestions
    ?.map((e) => Number(e.answer ?? undefined))
    .filter((e) => !!e || e === 0)
    .reduce((min, next) => (min === null || next < min ? next : min), null);
  return {
    submission: toDate(vor?.verificationDate) ?? undefined,
    landlordEmail: details.landlord?.email,
    landlordName: details.landlord?.fullName,
    landlordPhone: details.landlord?.phoneNumber,
    score: details.survey?.score,
    outOf: details.survey?.outOf,
    status: details.survey?.status,
    lowestScore: lowestScore ?? undefined,
    enabled,
  };
};
