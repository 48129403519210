import _ from "lodash";

import type {
  IncomeVerificationMethod,
  StreamVerificationMethod,
} from "renter/interfaces/api/income";

export const EMPLOYMENT_PROVIDER = {
  ARGYLE: "argyle",
  VERO: "vero",
  DOCUMENT: "document",
};

export function getExistingEmployments(employments) {
  return _.chain(employments)
    .filter((employment) => _.isEmpty(_.get(employment, "removedAt")))
    .value();
}

export function getCurrentEmployments(employments) {
  return _.chain(employments)
    .filter(
      (employment) =>
        !_.isEmpty(_.get(employment, "startDate")) &&
        _.isEmpty(_.get(employment, "endDate"))
    )
    .value();
}

export function getAutoLinkedEmployments(employments) {
  return _.chain(employments)
    .filter(
      (employment) =>
        _.get(employment, "provider") === EMPLOYMENT_PROVIDER.ARGYLE
    )
    .value();
}

export function getArgyleNonEmploymentIncomes(incomes) {
  return _.chain(incomes)
    .filter(
      (income) =>
        _.get(income, "provider") === "argyle" &&
        _.isEmpty(_.get(income, "employment")) &&
        _.get(income, "estimatedAnnualGrossAmount")
    )
    .value();
}

export const mapIncomeStreamVerificationMethodToSource = (
  method: StreamVerificationMethod
) => {
  switch (method) {
    case "argyle-paystubs":
    case "argyle-identity":
      return "Linked Employment";
    case "argyle-paystubs-benefits":
    case "argyle-identity-benefits":
      return "Linked Benefits";
    case "plaid-stream":
      return "Linked Bank Account";
    case "ocr-1099":
      return "1099 Upload";
    case "ocr-bank-stream":
      return "Bank Statement Upload";
    case "ocr-paystubs":
      return "Paystubs Upload";
    case "ocr-w2":
      return "W2 Upload";
    case "ocr-w2-paystubs":
      return "W2 & Paystubs Upload";
    case "stated":
      return "Stated";
    default:
      return "Unknown";
  }
};

export const mapIncomeStreamVerificationMethodToType = (
  method: IncomeVerificationMethod
) => {
  switch (method) {
    case "argyle-paystub":
    case "ocr-paystub":
      return "Paystub";
    case "plaid-transaction":
      return "Bank Deposit";
    case "ocr-1099":
      return "1099";
    case "ocr-w2":
      return "W2";
    case "ocr-transaction":
      return "Bank Deposit";
    default:
      return "Unknown";
  }
};

export const editableStreamVerificationMethodType = (
  method: IncomeVerificationMethod
) => {
  return ["ocr-paystub", "ocr-1099", "ocr-w2"].includes(method);
};
