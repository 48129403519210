import React from "react";

import dayjs from "dayjs";

import Icon from "shared/components/Icon";
import { IconLink, UnderlineLink } from "shared/components/Links";
import Table from "shared/components/Table";
import { DATE_FORMAT } from "shared/config/constants";
import { downloadDocument } from "shared/utils/ui";

import { DraftsTableProps } from "../interfaces";

const DraftsTable = ({
  loading,
  drafts = [],
  openConstructorDocument,
}: DraftsTableProps) => (
  <Table
    alt
    loading={loading}
    columns={[
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
      },
      {
        title: "Date Uploaded",
        dataIndex: "createdAt",
        key: "createdAt",
      },
    ]}
    columnBreakpoints={{
      xs: {
        column1: "name",
        column2: "createdAt",
      },
      sm: ["name", "createdAt"],
      md: ["name", "type", "createdAt"],
    }}
    placeholder={{
      Icon: Icon.NoDataIcon,
      label: "Drafts",
    }}
    rows={drafts?.map((draft) => ({
      key: draft.id,
      values: [
        <UnderlineLink onClick={() => openConstructorDocument(draft)}>
          {draft.name}
        </UnderlineLink>,
        <span>{draft.type}</span>,
        <span>{dayjs(draft.createdAt).format(DATE_FORMAT)}</span>,
      ],
      primaryAction: (
        <IconLink
          onClick={() =>
            downloadDocument({
              path: draft.url,
              name: draft.name,
            })
          }
          Icon={Icon.DownloadDocumentIcon}
          hideTextOnMobile
        />
      ),
    }))}
    showActionColumn
    noClickableRows
  />
);

export default DraftsTable;
