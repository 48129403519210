import { ApplicantType, Dependent } from "./interfaces";

export const mapDependents = (deal: Record<string, any>): Dependent[] => {
  return (
    deal.otherOccupants?.map((occupant) => ({
      id: occupant?.id,
      firstName: occupant?.firstName,
      lastName: occupant?.lastName,
      age: occupant?.age,
      relationship: occupant?.relationship,
      type: deal.hideOccupantsButAllowDependents
        ? ApplicantType.dependent
        : ApplicantType.occupant,
    })) ?? []
  );
};
