import styled from "@emotion/styled";

import { COLORS } from "shared/config/constants";

const SettingsContainer = styled.div`
  > * {
    margin-bottom: 20px;
  }

  .warning {
    border: 1px solid ${COLORS.borders};
    background: ${COLORS.white};
    padding: 20px;
  }

  .copy {
    padding-top: 20px !important;
  }

  .credentials {
    margin-bottom: 40px;
    margin-top: 10px;
  }
`;

export { SettingsContainer };
