import styled from "@emotion/styled";

import Card from "shared/components/Card";
import { MEDIA_QUERIES, COLORS } from "shared/config/constants";
import { CenteredCard } from "shared/styles/commonStyles";

const LoginPageCard = styled(Card)`
  ${CenteredCard}

  ${MEDIA_QUERIES.w.ltMd} {
    top: 40px;
    transform: none;
  }

  .col-vertical-divider {
    display: flex;
    justify-content: center;
  }

  .normal-logo {
    align-self: end;
  }

  .mobile-logo {
    margin-bottom: 40px;
  }
`;

const FooterActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${MEDIA_QUERIES.w.ltMd} {
    flex-direction: column;
    flex-flow: column-reverse;
  }
`;

const ForgotPasswordLink = styled.div`
  font-size: 12px;
  & > a {
    border-bottom: none;
  }
  ${MEDIA_QUERIES.w.ltMd} {
    align-self: flex-start;
    margin-bottom: 35px;
  }
`;

const InfoMessage = styled.div`
  width: 100%;
  background: ${COLORS.lightBlue};
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  font-size: 12px;
  line-height: 150%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 12px;
  gap: 16px;
`;

export { LoginPageCard, FooterActions, ForgotPasswordLink, InfoMessage };
