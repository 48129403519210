import React, { Key, useState } from "react";

import { RadioChangeEvent } from "antd/lib/radio";
import isString from "lodash/isString";

import Icon from "shared/components/Icon";
import List from "shared/components/List";
import { ItemProps } from "shared/components/types";
import useVirtualizedList from "shared/hooks/useVirtualizedList";
import { InfoIcon } from "shared/icons";
import { CheckboxItem } from "shared/utils/checkbox";
import { filterOnClick } from "shared/utils/ui";

import {
  Divider,
  LeftCol,
  PopoverTextContainer,
  StyledPopover,
  Description,
  CollapseHeading,
  CollapseText,
  CollapseDescription,
  CollapseIconContainer,
} from "./styled";

export type CollapsibleListProps = {
  disabledCheckboxText?: string;
  items: ItemProps[];
  visibleItems?: ItemProps[];
  renderInput?: (input: {
    item: ItemProps;
    onChange: (event: RadioChangeEvent) => void;
  }) => void;
  renderCollapseInput?: boolean;
  setSelectedItems?: (item: CheckboxItem, value?: boolean) => void;
  col1Width?: string;
  itemProps?: object;
  selectedItem?: Key;
  hideUnchecked?: boolean;
  selectedItems?: Key[];
  hideIcon?: boolean;
  noSeparator?: boolean;
  virtualize?: boolean;
  virtualRowHeightEstimator?: () => number;

  setFieldValue?: (key: Key) => void;
};

export const CollapsibleList = ({
  items,
  visibleItems = undefined,
  renderInput,
  renderCollapseInput = false,
  setSelectedItems,
  col1Width = "335px",
  selectedItem = undefined,
  itemProps = undefined,
  hideUnchecked = false,
  selectedItems = [],
  hideIcon = false,
  noSeparator = false,
  virtualize = false,
  virtualRowHeightEstimator = () => 60,
}) => {
  const parentsWithSelectedChildrenKeys =
    items
      ?.filter((item) => !item.collapsible && item.key === selectedItem)
      .map((item) => item.parent) || [];

  const [collapsedItems, setCollapsedItems] = useState([
    ...items
      .filter((item) => item.collapsible)
      // don't collapse if there is a selected item
      .filter((item) => parentsWithSelectedChildrenKeys.indexOf(item.key) < 0)
      .map((item) => item.key),
  ]);

  const isCollapsed = (key: Key) => {
    if (hideUnchecked) {
      return !(selectedItems.indexOf(key) > -1);
    }
    return collapsedItems.indexOf(key) > -1;
  };
  const collapseItem = (key: Key, onCollapseHeadingClick?: () => void) =>
    filterOnClick(() => {
      if (isCollapsed(key)) {
        const nextCollapsedItems = [...collapsedItems];
        const itemIndex = collapsedItems.indexOf(key);
        nextCollapsedItems.splice(itemIndex, 1);
        setCollapsedItems(nextCollapsedItems);
        if (onCollapseHeadingClick) {
          onCollapseHeadingClick();
        }
      } else {
        setCollapsedItems([...collapsedItems, key]);
      }
    }, ["INPUT"]);

  const getRenderedInput = (item) =>
    renderInput({
      item,
      onChange: () => {
        setSelectedItems(item);
      },
    });

  const currentElement = React.useRef();
  const availableItems = visibleItems || items;
  const {
    getRealItem,
    innerContainerHeight,
    completeItems,
    getStyle,
    getVirtualItem,
  } = useVirtualizedList({
    enabled: virtualize,
    availableItems,
    currentElement,
    virtualRowHeightEstimator,
  });

  const getSecondColProps = (item) => {
    return virtualize
      ? {
          className: "text-ellipsis description-cell",
          title: isString(item.description) ? item.description : undefined,
        }
      : {};
  };

  return (
    <List>
      <div
        ref={currentElement}
        style={{
          height: innerContainerHeight,
          width: "100%",
          position: "relative",
        }}
      >
        {completeItems.map((completeItem) => {
          const item = getRealItem(completeItem);
          const { key } = item;

          let description = item.description || [];

          if (!Array.isArray(description)) {
            description = [{ node: description }];
          }

          return (
            <List.Item
              key={key}
              data-testid={`item-${item.key}`}
              indent={Boolean(item.parent)}
              hide={item.parent ? isCollapsed(item.parent) : undefined}
              {...itemProps}
              // @ts-ignore
              style={getStyle(getVirtualItem(completeItem))}
              className={item.className}
            >
              <LeftCol
                width={col1Width}
                strictWidth={virtualize}
                weight={item.parent ? 400 : 500}
              >
                {item.collapsible ? (
                  <CollapseHeading
                    data-testid={`collapse-${item.key}`}
                    onClick={collapseItem(
                      item.key,
                      item.onCollapseHeadingClick
                    )}
                    onKeyPress={collapseItem(item.key)}
                    role="button"
                    tabIndex={0}
                  >
                    {renderCollapseInput && getRenderedInput(item)}
                    <CollapseText>
                      {item.label}
                      {item.description && (
                        <CollapseDescription>
                          {item.description}
                        </CollapseDescription>
                      )}
                    </CollapseText>
                    {hideIcon ? null : (
                      <CollapseIconContainer>
                        {isCollapsed(item.key) ? (
                          <Icon.ChevronDownIcon data-testid="collapsed-icon" />
                        ) : (
                          // @ts-ignore
                          <Icon.ChevronUpIcon data-testid="uncollapsed-icon" />
                        )}
                      </CollapseIconContainer>
                    )}
                    {item.collapsibleSuffix}
                  </CollapseHeading>
                ) : (
                  getRenderedInput(item)
                )}
                {item.popover && (
                  <StyledPopover
                    placement={item.placement || "top"}
                    trigger={item.trigger || "hover"}
                    content={item.popover.content}
                    title={item.popover.title}
                    withoutOverlay={item.withoutOverlay || true}
                  >
                    <PopoverTextContainer>
                      {!!item.popover.text && item.popover.text}
                      {item.popover.icon ? item.popover.icon : <InfoIcon />}
                    </PopoverTextContainer>
                  </StyledPopover>
                )}
              </LeftCol>

              {description.map((desc, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <React.Fragment key={`${desc}_${index}`}>
                  {!noSeparator && !desc.noSeparator && (
                    <Divider type="vertical" {...desc.separatorProps} />
                  )}
                  <Description
                    className="description-cell"
                    {...getSecondColProps(item)}
                    // @ts-ignore
                    disabled={item.disabled}
                  >
                    {desc.node}
                  </Description>
                </React.Fragment>
              ))}
            </List.Item>
          );
        })}
      </div>
    </List>
  );
};

// Below is needed or else unit tests fail
CollapsibleList.defaultProps = {
  col1Width: "335px",
  itemProps: undefined,
  visibleItems: undefined,
  renderCollapseInput: false,
  hideUnchecked: false,
  hideIcon: false,
  noSeparator: false,
  selectedItems: [],
  selectedItem: undefined,
  virtualize: false,
  virtualRowHeightEstimator: () => 60,
};
