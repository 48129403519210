import { css } from "@emotion/react";
import styled from "@emotion/styled";

import Icon from "shared/components/Icon";
import { COLORS } from "shared/config/constants";

const Container = styled("div")`
  display: flex;
  align-items: center;
`;

const Text = styled("span")<{ fontWeight?: number }>`
  margin-left: 16px;
  color: ${COLORS.primary};
  font-weight: ${(props) => props.fontWeight},
  line-height: 26px;
`;

const iconStylesCSS = css`
  flex-shrink: 0;
  width: 20px;
  height: 20px;
`;

const InfoIcon = styled(Icon.InfoIcon)`
  ${{ ...iconStylesCSS }}
`;
const WarningIcon = styled(Icon.WarningIcon)`
  ${{ ...iconStylesCSS }}
`;
const ValidationErrorIcon = styled(Icon.CrossRedSmall)`
  ${{ ...iconStylesCSS }}
`;
const OrangeExclamationIcon = styled(Icon.OrangeExclamationSmall)`
  ${{ ...iconStylesCSS }}
`;

export {
  Container,
  Text,
  InfoIcon,
  WarningIcon,
  ValidationErrorIcon,
  OrangeExclamationIcon,
};
