import styled from "@emotion/styled";
import { Typography } from "antd";

import { COLORS } from "admin/config/constants";
import { ActionLink } from "shared/components/Links";

const { Text } = Typography;

export const Title = styled(Text)`
  color: ${COLORS.documentConstructor.text};
  font-size: 24px;
`;

export const Subtitle = styled.p`
  color: ${COLORS.documentConstructor.text};
  font-size: 12px;
`;

export const StyledLink = styled(ActionLink)`
color: ${COLORS.documentConstructor.actionLink.primary};

&:hover {
  color: ${COLORS.documentConstructor.actionLink.hover}
  svg {
    fill  ${COLORS.documentConstructor.primary}
  }
  path:nth-of-type(2), path:nth-of-type(3) {
      fill: ${COLORS.documentConstructor.actionLink.hover}
    }
  }
  display: none;
  `;

export const Header = styled.div`
  background-color: ${COLORS.documentConstructor.primary};
  padding: 16px 25px;
`;
