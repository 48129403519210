import styled from "@emotion/styled";

import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

const Container = styled.section`
  background-color: ${COLORS.white};
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-content: center;
  position: relative;

  ${MEDIA_QUERIES.w.gteMd} {
    align-items: center;
  }
`;

const ContentContainer = styled.div`
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;

  ${MEDIA_QUERIES.w.ltLg} {
    div {
      font-size: 13px;
    }

    .powered-by {
      margin-top: 20px;
      margin-bottom: 10px;
      padding-left: 30px;
    }

    .logo {
      width: 85px;
    }

    .learn-more {
      margin-left: 25px;
      font-size: 12px;
      text-transform: uppercase;
    }
  }

  ${MEDIA_QUERIES.w.gteLg} {
    .powered-by {
      position: absolute;
      right: -40px;
      transform: translateX(100%);
      bottom: 0;

      ${MEDIA_QUERIES.w.ltXl} {
        right: -20px;
      }

      div {
        font-size: 32px;
        color: ${COLORS.primary};
        margin-bottom: 13px;
        display: block;

        ${MEDIA_QUERIES.w.ltXl} {
          font-size: 25px;
          margin-bottom: 5px;
        }
      }

      .logo {
        height: 44px;
        width: 210px;
        display: block;
        margin-bottom: 16px;

        ${MEDIA_QUERIES.w.ltXl} {
          width: 150px;
          margin-bottom: 10px;
        }
      }

      .learn-more {
        font-size: 18px;
        margin-bottom: 0;
        color: ${COLORS.lightPurple};

        ${MEDIA_QUERIES.w.ltXl} {
          font-size: 13px;
        }
      }
    }
  }
`;

const Modal = styled.div`
  background: none;
  position: relative;
  padding: 0;

  ${MEDIA_QUERIES.w.gteMd} {
    width: 624px;
    border: 1px solid ${COLORS.borders};
    box-sizing: border-box;
    box-shadow: 0px 4px 20px ${COLORS.boxShadowLight};
    border-radius: 2px;
  }
`;

export { Container, ContentContainer, Modal };
