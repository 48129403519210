import { ReactElement } from "react";

import styled from "@emotion/styled";

import { COLORS, TRANSITIONS, Z_INDEX } from "shared/config/constants";

import { Label } from "../styled";

export const InputWrapper = styled("div")(({ children }) => {
  const error = (children as ReactElement).props?.isValid;
  return {
    ".react-tel-input": {
      display: "flex",
      flexDirection: "column-reverse",
    },
    ".invalid-number-message": {
      color: COLORS.input.invalid.message,
      marginTop: 3,
      fontSize: "80%",
    },
    ".react-tel-input .flag-dropdown.open": {
      zIndex: Z_INDEX.phoneNumberInputFlagDropdown,
      background: "transparent",
      borderTop: "none",
    },
    ".react-tel-input .flag-dropdown.open .selected-flag": {
      background: "transparent",
    },
    input: {
      width: "100%",
      height: 40,
      padding: "4px 15px",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: COLORS.input.default.border,
      color: COLORS.input.default.text,
      transition: TRANSITIONS.default,
      "&:disabled": {
        backgroundColor: COLORS.input.disabled.background,
        color: `${COLORS.input.disabled.text} !important`,
      },
      "&:hover": {
        borderColor: COLORS.input.hover.border,
      },
      ...(error && {
        "&:focus": {
          boxShadow: "none",
          borderColor: `${COLORS.input.focus.border} !important`,
          text: COLORS.input.focus.text,
        },
      }),
      ...(!error && {
        boxShadow: "none",
        borderColor: `${COLORS.input.invalid.border} !important`,
        "&:focus": {
          boxShadow: "none",
          borderColor: `${COLORS.input.invalid.border} !important`,
          text: COLORS.input.focus.text,
        },
      }),
    },
  };
});

export const inputStyle = {
  height: "40px",
  width: "inherit",
  zIndex: 0,
};

export const dropdownStyle = {
  zIndex: 0,
};

export const PhoneInputLabel = styled(Label)`
  left: 48px;
`;
