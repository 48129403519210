import styled from "@emotion/styled";

import { COLORS } from "shared/config/constants";

const Container = styled("div")`
  display: flex;
  height: 3px;
  position: relative;
`;

const Score = styled("div")``;

const ScoreVeryBad = styled(Score)`
  width: 47%;
  background-color: ${COLORS.creditScore.scores.veryBad};
`;

const ScoreBad = styled(Score)`
  width: 17%;
  background-color: ${COLORS.creditScore.scores.bad};
`;

const ScoreFair = styled(Score)`
  width: 9%;
  background-color: ${COLORS.creditScore.scores.fair};
`;

const ScoreGood = styled(Score)`
  width: 9%;
  background-color: ${COLORS.creditScore.scores.good};
`;

const ScoreExcellent = styled(Score)`
  width: 18%;
  background-color: ${COLORS.creditScore.scores.excellent};
`;

const Indicator = styled("div")<{ score: number }>`
  height: 11px;
  width: 4px;
  position: absolute;
  background-color: ${COLORS.creditScore.indicator};
  transform: translateY(-4px);
  left: ${(props) => (props.score ? props.score : "")}%;
`;

export {
  Container,
  ScoreVeryBad,
  ScoreBad,
  ScoreFair,
  ScoreGood,
  ScoreExcellent,
  Indicator,
};
