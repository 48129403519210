/* eslint-disable camelcase */
import React, { useState } from "react";

import PropTypes from "prop-types";

import ROUTES from "renter/config/routes";
import {
  useCreateMagicLinkV2Deal,
  useGetMagicLinkV2Details,
} from "renter/hooks/api";
import { DEAL_TYPES } from "shared/config/constants";
import { transformRoute, getQueryParams } from "shared/utils/routing";

import MagicLinkGetStarted from "./MagicLinkGetStarted";
import { MAGIC_LINK_LANDING_STEP } from "./magicLinkLanding.config";

type MAGIC_LINK_STEP = "LANDING_STEP" | "CONFIRMATION_STEP" | "ERROR_STEP";

const MagicLinkV2GetStartedContainer = ({ dealType }) => {
  const {
    company,
    "property-source": propertySource,
    property,
    unit,
    "lease-start": leaseStart,
    "lease-months": leaseMonthsParam,
    source,
    "is-qr-code": isQrCodeParam,
    "show-landing": showLandingParam,
    application,
    "external-reference": externalReference,
  } = getQueryParams();

  const [currentStep, setCurrentStep] = useState(
    MAGIC_LINK_LANDING_STEP.LANDING_STEP
  );
  const [submittedEmail, setSubmittedEmail] = useState();
  const [signupUrl, setSignupUrl] = useState<string | undefined>();
  const { createMagicLinkV2Deal, error } = useCreateMagicLinkV2Deal();
  const {
    data,
    isLoading,
    isError: errorFetchingDetails,
  } = useGetMagicLinkV2Details({
    company,
    propertySource,
    property,
    unit,
    dealType,
  });

  const leaseMonths = parseInt(leaseMonthsParam as string, 10);
  const externalUrl = source ? decodeURI(source as string) : document.referrer;
  const isQrCode = ((isQrCodeParam as string) || "").toLowerCase() === "true";
  const showLanding =
    ((showLandingParam as string) || "").toLowerCase() === "true";
  const { userAgent } = window.navigator;

  const submit = ({ email }) =>
    createMagicLinkV2Deal(
      {
        application,
        dealType,
        company,
        propertySource,
        property,
        unit,
        leaseStart,
        leaseMonths,
        email,
        externalUrl,
        isQrCode,
        userAgent,
        externalReference,
      },
      {
        onSuccess: ({ data: responseData }) => {
          if (application) {
            window.location.href = transformRoute(
              ROUTES.submitApplication.payment,
              { id: application }
            );
          } else {
            const { link } = responseData || {};
            setSubmittedEmail(email);
            setSignupUrl(link);
            setCurrentStep(MAGIC_LINK_LANDING_STEP.CONFIRMATION_STEP);
          }
        },
        onError: () => {
          setCurrentStep(MAGIC_LINK_LANDING_STEP.ERROR_STEP);
        },
      }
    );

  return (
    <MagicLinkGetStarted
      submit={submit}
      isCompanyProfileLoading={isLoading}
      currentStep={currentStep as MAGIC_LINK_STEP}
      // @ts-ignore:next-line
      submitErrors={error?.errors}
      submittedEmail={submittedEmail}
      application={application}
      signupUrl={signupUrl}
      logoUrl={data?.logoUrl}
      propertyName={data?.propertyName}
      unitName={data?.unitName}
      dealType={dealType}
      isLroEnabled={data?.isLroEnabled}
      companyMagicFragment={data?.companyMagicFragment}
      propertyMagicFragment={data?.propertyMagicFragment}
      unitMagicFragment={data?.unitMagicFragment}
      errorFetchingDetails={errorFetchingDetails}
      showLanding={showLanding}
      isMagicPortalEnabled={data?.isMagicPortalEnabled}
    />
  );
};

MagicLinkV2GetStartedContainer.propTypes = {
  dealType: PropTypes.oneOf(Object.values(DEAL_TYPES)).isRequired,
};

export default MagicLinkV2GetStartedContainer;
