import styled from "@emotion/styled";

import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import {
  MediumTitle,
  SmallText,
  SmallTitle,
} from "shared/components/Typography";
import { COLORS } from "shared/config/constants";

export const SandWatchIcon = styled(Icon.SandWatchIcon)`
  margin-right: 10px;
  path {
    fill: ${COLORS.gray2};
  }
`;

export const TileNoIntegrationContainer = styled(FlexContainer)`
  width: 480px;
  height: 240px;
  margin-right: 16px;
`;

export const NotIntegrationTitle = styled(SmallTitle)`
  color: ${COLORS.gray2} !important;
`;

export const NotIntegrationText = styled(MediumTitle)`
  color: ${COLORS.gray2} !important;
  margin-top: 12px !important;
`;

export const NotIntegrationDesc = styled(SmallText)`
  color: ${COLORS.gray2} !important;
  width: 252px;
  text-align: center;
`;
