import { useQuery } from "react-query";

import api from "admin/lib/api";
import { NOTIFICATIONS } from "shared/config/constants";
import { openNotification } from "shared/utils/ui";

export const COMPANY_QUERRY_KEYS = Object.freeze({
  getCompaniesToAssignDocuments: () => ["getCompaniesToAssignDocuments"],
  getCompaniesDocumentAssigned: (documentId: number | string) => [
    "getCompaniesDocumentAssigned",
    documentId,
  ],
});

export const useCompaniesToAssignDocuments = ({
  documentNames,
}: {
  documentNames: string[];
}) => {
  const response = useQuery(
    [COMPANY_QUERRY_KEYS.getCompaniesToAssignDocuments(), { documentNames }],
    () =>
      api
        .getCompaniesToAssignDocuments({ documentNames })
        .then(({ data }) => data),
    {
      onError: () =>
        openNotification(
          "Failed to load companies to assign.",
          NOTIFICATIONS.error
        ),
    }
  );
  return response;
};

export const useCompaniesDocumentAssigned = ({
  documentId,
}: {
  documentId: number | string;
}) => {
  const response = useQuery(
    COMPANY_QUERRY_KEYS.getCompaniesDocumentAssigned(documentId),
    () => api.getCompaniesDocumentAssigned(documentId).then(({ data }) => data),
    {
      onError: () =>
        openNotification(
          "Failed to load companies to which the document assigned.",
          NOTIFICATIONS.error
        ),
    }
  );
  return response;
};
