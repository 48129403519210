import React from "react";

import { Col, Row, Divider } from "antd";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";

import ErrorList from "shared/components/ErrorList";
import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import Skeleton from "shared/components/Skeleton";
import Spacer from "shared/components/Spacer";
import SSOButton from "shared/components/SSOButton";
import { LargeTitle, RegularText } from "shared/components/Typography";
import VerticalDivider from "shared/components/VerticalDivider";
import ROUTES from "shared/config/routes";

import { SetPasswordForm } from "shared/forms/SetPasswordForm";
import useDeviceType from "shared/hooks/useDeviceType";
import AuthPage from "shared/templates/AuthPage";

import { LOGIN_STATUS_TYPE } from "./config";
import { SetPasswordPageProps } from "./interfaces";
import { PageLoadSpinner, SetPasswordPageCard } from "./styled";

const LogoCentered = () => (
  <FlexContainer justifyContent="center" className="mobile-logo">
    <Icon.LogoIcon />
  </FlexContainer>
);

const SetPasswordPage = ({
  token,
  submit,
  email,
  loading,
  error,
  ssoConfig,
  isSSOConfigLoading,
  pactSafeGroupKey,
  isPactSafeConfigLoading,
  formikRef,
  preValidationSSOButton,
  applicationId,
}: SetPasswordPageProps) => {
  const history = useHistory();
  const errorDetails = error?.errors;
  const cardWidth = !errorDetails ? "710px" : null;

  const { isMobile } = useDeviceType();

  const errorStatus = errorDetails?.status;
  if (errorStatus === LOGIN_STATUS_TYPE.INVITATION_EXPIRED) {
    history.push(ROUTES.setPasswordLinkExpired.replace(":token", token));
  }

  if (errorStatus === LOGIN_STATUS_TYPE.INVITATION_ALREADY_ACCEPTED) {
    history.push(ROUTES.setPasswordLinkAccepted);
  }

  const initvalues = {
    email,
    password: "",
    passwordConfirmation: "",
    termsOfService: false,
  };

  return (
    <>
      <Helmet>
        <title>Welcome to VERO!</title>
      </Helmet>
      <AuthPage>
        <SetPasswordPageCard width={cardWidth}>
          <Row type="flex">
            <Col xs={24} md={10}>
              {isMobile && <LogoCentered />}
              <LargeTitle>Welcome to VERO!</LargeTitle>
              <RegularText weak>Please set your password.</RegularText>
              <Spacer />

              {loading ? (
                <PageLoadSpinner data-testid="loading-icon" />
              ) : (
                <>
                  {errorDetails?.errors?.nonFieldErrors && (
                    <ErrorList errors={errorDetails.errors.nonFieldErrors} />
                  )}
                  {!errorDetails && (
                    <SetPasswordForm
                      formikRef={formikRef}
                      initialValues={initvalues}
                      submit={submit}
                      token={token}
                      showEmail
                      showVeroTermsClickwrap
                      pactSafeGroupKey={pactSafeGroupKey}
                      isPactSafeConfigLoading={isPactSafeConfigLoading}
                      applicationId={applicationId}
                    />
                  )}
                </>
              )}
            </Col>
            {!isMobile && (
              <Col span={4} className="col-vertical-divider">
                <VerticalDivider text="OR" />
              </Col>
            )}
            {isMobile && (
              <Col span={24}>
                <Divider>
                  <RegularText weak>OR</RegularText>
                </Divider>
              </Col>
            )}
            <Col xs={24} md={10}>
              <FlexContainer flexDirection="column">
                {!isMobile && (
                  <>
                    <Icon.LogoIcon className="normal-logo" />
                    <Spacer size={Spacer.SIZES.xl} />
                  </>
                )}
                <Skeleton loading={isSSOConfigLoading}>
                  {ssoConfig && (
                    <FlexContainer flexDirection="column" alignItems="center">
                      {ssoConfig.map((provider) => (
                        <React.Fragment key={provider.name}>
                          <SSOButton
                            // @ts-ignore
                            provider={provider}
                            preValidation={preValidationSSOButton}
                          />
                          <Spacer />
                        </React.Fragment>
                      ))}
                    </FlexContainer>
                  )}
                </Skeleton>
              </FlexContainer>
            </Col>
          </Row>
        </SetPasswordPageCard>
      </AuthPage>
    </>
  );
};

export default SetPasswordPage;
