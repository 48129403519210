// @ts-nocheck

import { ReactComponent as ApplicantInviteBase } from "./applicant-invite-base.svg";
import { ReactComponent as ApplicantInviteDisabled } from "./applicant-invite-disabled.svg";
import { ReactComponent as ApplicantInviteHover } from "./applicant-invite-hover.svg";
import { ReactComponent as Applications } from "./applications.svg";
import { ReactComponent as BankWarning } from "./bank-warning.svg";
import { ReactComponent as BarChart } from "./bar-chart.svg";
import { ReactComponent as BlackArrowLeft } from "./black-arrow-left.svg";
import { ReactComponent as BulbGraySmall } from "./bulb-gray-small.svg";
import { ReactComponent as BulbGreenSmall } from "./bulb-green-small.svg";
import { ReactComponent as BulbOrangeSmall } from "./bulb-orange-small.svg";
import { ReactComponent as BulbPurpleSmall } from "./bulb-purple-small.svg";
import { ReactComponent as BulbRedSmall } from "./bulb-red-small.svg";
import { ReactComponent as BulbWhiteSmall } from "./bulb-white-small.svg";
import { ReactComponent as Calculating } from "./calculating.svg";
import { ReactComponent as CheckGreenBig } from "./check-green-big.svg";
import { ReactComponent as CheckGreenSmall } from "./check-green-small.svg";
import { ReactComponent as ChevronDownGreenIcon } from "./chevron-down-green.svg";
import { ReactComponent as ChevronDownWhiteBoldIcon } from "./chevron-down-white-bold.svg";
import { ReactComponent as CrossRedBig } from "./cross-red-big.svg";
import { ReactComponent as CrossRedSmall } from "./cross-red-small.svg";
import { ReactComponent as DocumentProgressIcon } from "./document-progress.svg";
import { ReactComponent as DocumentWhiteCheckedIcon } from "./document-white-checked.svg";
import { ReactComponent as DocumentWhiteIcon } from "./document-white.svg";
import { ReactComponent as DocumentsEmptyGreenIcon } from "./documents-empty-green.svg";
import { ReactComponent as DocumentsEmptyIcon } from "./documents-empty.svg";
import { ReactComponent as DownloadPdfDocHover } from "./download-pdf-doc-hover.svg";
import { ReactComponent as DownloadPdfDocBase } from "./download-pdf-doc.svg";
import { ReactComponent as EditPencilIcon } from "./edit-pencil.svg";
import { ReactComponent as EditPencilLargeIcon } from "./edit-pencil-large.svg";
import { ReactComponent as EmailLight } from "./email-light.svg";
import { ReactComponent as EmailSentIcon } from "./email-sent.svg";
import { ReactComponent as EmailIcon } from "./email.svg";
import { ReactComponent as EmptyBox } from "./empty-box.svg";
import { ReactComponent as FacebookLogo } from "./facebook-logo.svg";
import { ReactComponent as GoogleLogo } from "./google-logo.svg";
import { ReactComponent as BlackExclamationHollow } from "./black-exclamation-hollow.svg";
import { ReactComponent as GrayExclamationHollow } from "./gray-exclamation-hollow.svg";
import { ReactComponent as HourglassIcon } from "./hourglass.svg";
import { ReactComponent as IdVerificationNotVerified } from "./id-verification-not-verified.svg";
import { ReactComponent as IdVerificationStart } from "./id-verification-start.svg";
import { ReactComponent as IdVerificationVerified } from "./id-verification-verified.svg";
import { ReactComponent as InfoDarkCircle } from "./info-dark-circle.svg";
import { ReactComponent as InfoDark } from "./info-dark.svg";
import { ReactComponent as InfoStandard } from "./info-standard.svg";
import { ReactComponent as InfoIcon } from "./info.svg";
import { ReactComponent as LinkEmploymentHasCompany } from "./link-employment-has-company.svg";
import { ReactComponent as LinkEmploymentNoCompany } from "./link-employment-no-company.svg";
import { ReactComponent as LinkEmployment } from "./link-employment.svg";
import { ReactComponent as LinkedInLogo } from "./linkedin-logo.svg";
import { ReactComponent as LumenBig } from "./lumen-big.svg";
import { ReactComponent as LumenSmallBackground } from "./lumen-small-background.svg";
import { ReactComponent as BaseLynkSkipped } from "./lynk-skipped.svg";
import { ReactComponent as BaseLynkSuccess } from "./lynk-success.svg";
import { ReactComponent as MagicPortalLinkIcon } from "./magic-portal-link.svg";
import { ReactComponent as NoPhotosAvailable } from "./no-photos-available.svg";
import { ReactComponent as NoVerificationsAvailable } from "./no-verifications-available.svg";
import { ReactComponent as NotRenewingIcon } from "./not-renewing.svg";
import { ReactComponent as OpenApiIcon } from "./openapis-icon.svg";
import { ReactComponent as OrangeExclamationHollow } from "./orange-exclamation-hollow.svg";
import { ReactComponent as DarkOrangeExclamationHollow } from "./dark-orange-exclamation-hollow.svg";
import { ReactComponent as OrangeExclamation } from "./orange-exclamation.svg";
import { ReactComponent as PrescreeningLeadsHover } from "./prescreening-leads-hover.svg";
import { ReactComponent as PrescreeningLeadsVerification } from "./prescreening-leads-verification.svg";
import { ReactComponent as PrescreeningLeads } from "./prescreening-leads.svg";
import { ReactComponent as QRCodeGreenIcon } from "./qrcode-green.svg";
import { ReactComponent as QRCodeIcon } from "./qrcode.svg";
import { ReactComponent as QuestionBadge } from "./question-badge.svg";
import { ReactComponent as RedExclamationHollow } from "./red-exclamation-hollow.svg";
import { ReactComponent as RemoveOne } from "./remove-one.svg";
import { ReactComponent as RipplingLogo } from "./rippling-logo.svg";
import { ReactComponent as SignLeaseIcon } from "./sign-lease.svg";
import { ReactComponent as BaseSnapFailed } from "./snap-failed.svg";
import { ReactComponent as BaseSnapPassed } from "./snap-passed.svg";
import { ReactComponent as BaseSnapPending } from "./snap-pending.svg";
import { ReactComponent as ViewReportDisabled } from "./view-report-disabled.svg";
import { ReactComponent as ViewReport } from "./view-report.svg";
import { ReactComponent as WhiteArrowRight } from "./white-arrow-right.svg";

export { ReactComponent as ActiveLinkIcon } from "./active-link.svg";
export { ReactComponent as ActiveListingUnitIcon } from "./active-listing-unit.svg";
export { ReactComponent as AddAttachmentPlusDisabled } from "./add-attachment-plus-disabled.svg";
export { ReactComponent as AddAttachmentPlusHover } from "./add-attachment-plus-hover.svg";
export { ReactComponent as AddAttachmentPlusBase } from "./add-attachment-plus.svg";
export { ReactComponent as AddressIcon } from "./address.svg";
export { ReactComponent as ApplicantWithArrowIcon } from "./applicant-with-arrow.svg";
export { ReactComponent as ApplicantsTenantsHover } from "./applicants-tenants-hover.svg";
export { ReactComponent as ApplicantsTenants } from "./applicants-tenants.svg";
export { ReactComponent as ApplicationInProgressIcon } from "./application-in-progress.svg";
export { ReactComponent as ApplicationNotStartedIcon } from "./application-not-started.svg";
export { ReactComponent as ApplicationsHover } from "./applications-hover.svg";
export { ReactComponent as ArchivedApplication } from "./archived-application.svg";
export { ReactComponent as ArrowCircleDownIconXs } from "./arrow-circle-down-xs.svg";
export { ReactComponent as ArrowCircleUpIconXs } from "./arrow-circle-up-xs.svg";
export { ReactComponent as ArrowDownIcon } from "./arrow-down.svg";
export { ReactComponent as ArrowDownWithGreyBackgroundIcon } from "./arrow-icon-down-grey-bg.svg";
export { ReactComponent as ArrowDownWithBackgroundIcon } from "./arrow-icon-down.svg";
export { ReactComponent as ArrowEnter } from "./arrow-enter.svg";
export { ReactComponent as ArrowUpWithBackgroundIcon } from "./arrow-icon-up.svg";
export { ReactComponent as ArrowLeftBoldIcon } from "./arrow-left-bold.svg";
export { ReactComponent as ArrowLeftIcon } from "./arrow-left.svg";
export { ReactComponent as BellHover } from "./bell-hover.svg";
export { ReactComponent as Bell } from "./bell.svg";
export { ReactComponent as ButtonBackArrowIcon } from "./button-back-arrow.svg";
export { ReactComponent as ButtonForwardArrowIcon } from "./button-forward-arrow.svg";
export { ReactComponent as CalendarIconXs } from "./calendar-xs.svg";
export { ReactComponent as GreenCheckNoCircle } from "./check-green-no-circle.svg";
export { ReactComponent as CheckLargeFullPurple } from "./check-large-full-purple.svg";
export { ReactComponent as CheckLargeIcon } from "./check-large.svg";
export { ReactComponent as CheckIcon } from "./check.svg";
export { ReactComponent as CheckboxFieldIcon } from "./checkbox-field.svg";
export { ReactComponent as ChevronBoldDownIcon } from "./chevron-down-bold.svg";
export { ReactComponent as ChevronDownNewIcon } from "./chevron-down-new.svg";
export { ReactComponent as ChevronDownIcon } from "./chevron-down.svg";
export { ReactComponent as CityIconXs } from "./city-xs.svg";
export { ReactComponent as CloseIcon } from "./close.svg";
export { ReactComponent as CloudDownloadIcon } from "./cloud-download.svg";
export { ReactComponent as ConditionallyApprovedIcon } from "./conditionally-approved.svg";
export { ReactComponent as ConnectLinkIcon } from "./connect-link.svg";
export { ReactComponent as CreditCheckError } from "./credit-check-error.svg";
export { ReactComponent as CrossRemoveHovered } from "./cross-remove-hovered.svg";
export { ReactComponent as CrossRemove } from "./cross-remove.svg";
export { ReactComponent as DatepickerIcon } from "./datepicker.svg";
export { ReactComponent as DeactivatedIcon } from "./deactivated.svg";
export { ReactComponent as DealApprovedIcon } from "./deal-approved.svg";
export { ReactComponent as DealClosedIcon } from "./deal-closed.svg";
export { ReactComponent as DealDeclinedIcon } from "./deal-declined.svg";
export { ReactComponent as DealSignedIcon } from "./deal-signed.svg";
export { ReactComponent as DealsHover } from "./deals-hover.svg";
export { ReactComponent as DealsLargeIcon } from "./deals-large.svg";
export { ReactComponent as Deals } from "./deals.svg";
export { ReactComponent as DeclinedIcon } from "./declined.svg";
export { ReactComponent as DefaultLogoIcon } from "./default-logo.svg";
export { ReactComponent as DeleteHoverIcon } from "./delete-hover.svg";
export { ReactComponent as BaseDeleteNewIcon } from "./delete-new.svg";
export { ReactComponent as DeleteSmallHoverIcon } from "./delete-small-hover.svg";
export { ReactComponent as BaseDeleteSmallNoCircleIcon } from "./delete-small-no-circle.svg";
export { ReactComponent as BaseDeleteSmallIcon } from "./delete-small.svg";
export { ReactComponent as BaseDeleteIcon } from "./delete.svg";
export { ReactComponent as Documents } from "./documents.svg";
export { ReactComponent as DollarSignGray } from "./dollar-sign-gray.svg";
export { ReactComponent as DollarSignGreen } from "./dollar-sign-green.svg";
export { ReactComponent as DownloadDocumentIcon } from "./download-document.svg";
export { ReactComponent as DownloadPDF } from "./download-pdf.svg";
export { ReactComponent as DraggableIcon } from "./draggableIcon.svg";
export { ReactComponent as DropdownButtonChevronDownIcon } from "./dropdown-button-chevron-down.svg";
export { ReactComponent as DropdownHoverIcon } from "./dropdown-hover.svg";
export { ReactComponent as BaseDropdownIcon } from "./dropdown.svg";
export { ReactComponent as EditGreenIcon } from "./edit-green.svg";
export { ReactComponent as EditPencileGrayIcon } from "./edit-pencile-gray.svg";
export { ReactComponent as EditIcon } from "./edit.svg";
export { ReactComponent as Emoji } from "./emoji.svg";
export { ReactComponent as EmployeesHover } from "./employees-hover.svg";
export { ReactComponent as EmployeesLargeIcon } from "./employees-large.svg";
export { ReactComponent as Employees } from "./employees.svg";
export { ReactComponent as EmploymentLinkedIcon } from "./employment-linked.svg";
export { ReactComponent as EmptyImageIcon } from "./empty-image.svg";
export { ReactComponent as EncryptedIcon } from "./encrypted.svg";
export { ReactComponent as EqualHousingIcon } from "./equal-housing-opportunity.svg";
export { ReactComponent as Error404Icon } from "./error-404.svg";
export { ReactComponent as Error500Icon } from "./error-500.svg";
export { ReactComponent as ErrorDarkXwarning } from "./error-dark-x-warning.svg";
export { ReactComponent as ErrorDescriptionIcon } from "./error-description.svg";
export { ReactComponent as ErrorTriangleOutlineIcon } from "./error-triangle-outline.svg";
export { ReactComponent as ErrorTrianglewhiteOutlineIcon } from "./error-triangle-white-outline.svg";
export { ReactComponent as ErrorTriangleIcon } from "./error-triangle.svg";
export { ReactComponent as ErrorXwarning } from "./error-x-warning.svg";
export { ReactComponent as ErrorIcon } from "./error.svg";
export { ReactComponent as ExportIconGreen } from "./export-table-hov.svg";
export { ReactComponent as ExportIcon } from "./export-table-normal.svg";
export { ReactComponent as FastSmallIcon } from "./fast-small.svg";
export { ReactComponent as FastIcon } from "./fast.svg";
export { ReactComponent as FileFailedIconXs } from "./file-failed-xs.svg";
export { ReactComponent as FilterIcon } from "./filter.svg";
export { ReactComponent as FireworksSmallIcon } from "./fireworks-small.svg";
export { ReactComponent as FireworksIcon } from "./fireworks.svg";
export { ReactComponent as FirstVerificationErrorSmallIcon } from "./first-verification-error-small.svg";
export { ReactComponent as FirstVerificationErrorIcon } from "./first-verification-error.svg";
export { ReactComponent as FolderFileIcon } from "./folder-icon.svg";
export { ReactComponent as GrayQuestionMark } from "./gray-question-mark.svg";
export { ReactComponent as GreenArrowThin } from "./green-arrow-thin.svg";
export { ReactComponent as FilterGreenIcon } from "./green-filter.svg";
export { ReactComponent as HalfVerifiedIcon } from "./half-verified.svg";
export { ReactComponent as Help } from "./help.svg";
export { ReactComponent as HidePasswordHover } from "./hide-password-hover.svg";
export { ReactComponent as HidePassword } from "./hide-password.svg";
export { ReactComponent as HistoryIcon } from "./history.svg";
export { ReactComponent as HoursOfOperationFilledIcon } from "./hours-of-operation-filled.svg";
export { ReactComponent as HoursOfOperationIcon } from "./hours-of-operation.svg";
export { ReactComponent as FieldPartiallySet } from "./icon-partially-set.svg";
export { ReactComponent as IdCardIconXs } from "./id-card-h-xs.svg";
export { ReactComponent as ImportantHover } from "./important-hover.svg";
export { ReactComponent as Important } from "./important.svg";
export { ReactComponent as IncomeVerificationIcon } from "./income-verification.svg";
export { ReactComponent as InfoBannerBlueWarning } from "./info-banner-blue-warning.svg";
export { ReactComponent as InfoBannerRedWarning } from "./info-banner-red-warning.svg";
export { ReactComponent as InfoBlueIcon } from "./info-blue.svg";
export { ReactComponent as GrayInfoIcon } from "./info-gray.svg";
export { ReactComponent as InfoWhiteIcon } from "./info-white.svg";
export { ReactComponent as InputInfoIcon } from "./input-info.svg";
export { ReactComponent as InstantIcon } from "./instant.svg";
export { ReactComponent as IntegrationTextIcon } from "./integration-text-icon.svg";
export { ReactComponent as IntegrationsMenuItemHover } from "./integrations-menu-item-hover.svg";
export { ReactComponent as IntegrationsMenuItem } from "./integrations-menu-item.svg";
export { ReactComponent as LargeInfoIcon } from "./large-info-icon.svg";
export { ReactComponent as LeaseEye } from "./lease-eye.svg";
export { ReactComponent as LeaseSignedIcon } from "./lease-signed.svg";
export { ReactComponent as LeasesHover } from "./leases-hover.svg";
export { ReactComponent as Leases } from "./leases.svg";
export { ReactComponent as LeftOver } from "./left-over.svg";
export { ReactComponent as LeftUp } from "./left-up.svg";
export { ReactComponent as LinkBankAccountIcon } from "./link-bank-account.svg";
export { ReactComponent as LinkIncome } from "./link-income.svg";
export { ReactComponent as Link } from "./link.svg";
export { ReactComponent as ListViewIcon } from "./list-view.svg";
export { ReactComponent as LiveChatIcon } from "./live-chat.svg";
export { ReactComponent as LoadingSpinnerGreenLargeIcon } from "./loading-spinner-green-large.svg";
export { ReactComponent as LoadingSpinnerGreenIcon } from "./loading-spinner-green.svg";
export { ReactComponent as LoadingSpinnerIcon } from "./loading-spinner.svg";
export { ReactComponent as LocationIconXs } from "./location-xs.svg";
export { ReactComponent as LockIcon } from "./lock.svg";
export { ReactComponent as Lock2Icon } from "./lock2.svg";
export { ReactComponent as LockClosed } from "./lock-closed.svg";
export { ReactComponent as LogoBigWhiteIcon } from "./logo-big-white.svg";
export { ReactComponent as LogoBigIcon } from "./logo-big.svg";
export { ReactComponent as LogoGrayBigIcon } from "./logo-gray-big.svg";
export { ReactComponent as LogoGrayIcon } from "./logo-gray.svg";
export { ReactComponent as LogoMobileIcon } from "./logo-mobile.svg";
export { ReactComponent as LogoIcon } from "./logo.svg";
export { ReactComponent as MagicLinksLogoIcon } from "./magic-links-logo.svg";
export { ReactComponent as MeIconMd } from "./me-md.svg";
export { ReactComponent as MeIconSm } from "./me-sm.svg";
export { ReactComponent as MobileBackIcon } from "./mobile-back.svg";
export { ReactComponent as MobileMenuIcon } from "./mobile-menu.svg";
export { ReactComponent as ModalCloseIcon } from "./modal-close.svg";
export { ReactComponent as MyPropertyHover } from "./my-property-hover.svg";
export { ReactComponent as MyProperty } from "./my-property.svg";
export { ReactComponent as NoAccessIcon } from "./no-access-icon.svg";
export { ReactComponent as NoCompaniesIcon } from "./no-companies.svg";
export { ReactComponent as NoDataIcon } from "./no-data.svg";
export { ReactComponent as NoDealsIcon } from "./no-deals.svg";
export { ReactComponent as NoEmployeesIcon } from "./no-employees.svg";
export { ReactComponent as NoListingUnitIcon } from "./no-listing-unit.svg";
export { ReactComponent as NoPortfoliosIcon } from "./no-portfolios.svg";
export { ReactComponent as NoPropertiesLinkedIcon } from "./no-properties-linked.svg";
export { ReactComponent as NoPropertiesIcon } from "./no-properties.svg";
export { ReactComponent as NoRentersApplicantsIcon } from "./no-renters-applicants.svg";
export { ReactComponent as NoSearchResultsIcon } from "./no-search-results.svg";
export { ReactComponent as NoTemplatesIcon } from "./no-templates.svg";
export { ReactComponent as NoUnitsIcon } from "./no-units.svg";
export { ReactComponent as NotVerifiedIncomeAssetsSmall } from "./not-verified-income-assets-small.svg";
export { ReactComponent as NotVerifiedIncomeAssets } from "./not-verified-income-assets.svg";
export { ReactComponent as NoteActiveIcon } from "./note-icon-active.svg";
export { ReactComponent as NoteDisabledIcon } from "./note-icon-disabled.svg";
export { ReactComponent as NoteHoverIcon } from "./note-icon-hover.svg";
export { ReactComponent as NotificationCloseIcon } from "./notification-close.svg";
export { ReactComponent as NotificationsLargeIcon } from "./notifications-large.svg";
export { ReactComponent as OperationSuccess } from "./operation-success.svg";
export { ReactComponent as PaidTagIcon } from "./paid-tag-icon.svg";
export { ReactComponent as PdfDownloadDesktopHover } from "./pdf-download-desktop-hover.svg";
export { ReactComponent as PdfDownloadDesktop } from "./pdf-download-desktop.svg";
export { ReactComponent as PdfDownloadMobileHover } from "./pdf-download-mobile-hover.svg";
export { ReactComponent as PdfDownloadMobile } from "./pdf-download-mobile.svg";
export { ReactComponent as PDFReadyIcon } from "./pdf-ready.svg";
export { ReactComponent as PDFPreviewIcon } from "./pdf.svg";
export { ReactComponent as PendingSpinner } from "./pending-spinner.svg";
export { ReactComponent as PendingTagIcon } from "./pending-tag-icon.svg";
export { ReactComponent as PeopleIconMd } from "./people-md.svg";
export { ReactComponent as PeopleIconXs } from "./people-xs.svg";
export { ReactComponent as PersonSmallIcon } from "./person-small.svg";
export { ReactComponent as PersonIcon } from "./person.svg";
export { ReactComponent as PersonalInfoHover } from "./personal-info-hover.svg";
export { ReactComponent as PersonalInfo } from "./personal-info.svg";
export { ReactComponent as PhoneTelephoneIconXs } from "./phone-telephone-xs.svg";
export { ReactComponent as PhoneIcon } from "./phone.svg";
export { ReactComponent as PieChart } from "./pie-chart.svg";
export { ReactComponent as ProfileHover } from "./profile-hover.svg";
export { ReactComponent as Profile } from "./profile.svg";
export { ReactComponent as PropertiesLargeIcon } from "./properties-large.svg";
export { ReactComponent as PropertiesIcon } from "./properties.svg";
export { ReactComponent as PurpleQuestionMark } from "./purple-question-mark.svg";
export { ReactComponent as RefreshRotateIcon } from "./refresh-rotate.svg";
export { ReactComponent as RefreshIcon } from "./refresh.svg";
export { ReactComponent as RentedUnitIcon } from "./rented-unit.svg";
export { ReactComponent as ResetIncomeAssetsIcon } from "./reset-income-assets.svg";
export { ReactComponent as RightOver } from "./right-over.svg";
export { ReactComponent as RightUp } from "./right-up.svg";
export { ReactComponent as RightArrowDark } from "./right_arrow_dark_violet.svg";
export { ReactComponent as SandWatchIcon } from "./sand-watch.svg";
export { ReactComponent as SearchLgIcon } from "./search-lg.svg";
export { ReactComponent as SearchIcon } from "./search.svg";
export { ReactComponent as SecondVerificationErrorSmallIcon } from "./second-verification-error-small.svg";
export { ReactComponent as SecureSmallIcon } from "./secure-small.svg";
export { ReactComponent as SecureIcon } from "./secure.svg";
export { ReactComponent as SelectArrowIconDisabled } from "./select-arrow-disabled.svg";
export { ReactComponent as SelectArrowIcon } from "./select-arrow.svg";
export { ReactComponent as SettingsHover } from "./settings-hover.svg";
export { ReactComponent as SettingsSingleIcon } from "./settings-single.svg";
export { ReactComponent as SettingsTemplateIcon } from "./settings-template.svg";
export { ReactComponent as Settings } from "./settings.svg";
export { ReactComponent as ShowPasswordHover } from "./show-password-hover.svg";
export { ReactComponent as ShowPassword } from "./show-password.svg";
export { ReactComponent as SidebarApplicationsHover } from "./sidebar-applications-icon-hover.svg";
export { ReactComponent as SidebarApplications } from "./sidebar-applications-icon.svg";
export { ReactComponent as SidebarInventoryHover } from "./sidebar-inventory-icon-hover.svg";
export { ReactComponent as SidebarInventory } from "./sidebar-inventory-icon.svg";
export { ReactComponent as SidebarLeadsHover } from "./sidebar-leads-icon-hover.svg";
export { ReactComponent as SidebarLeads } from "./sidebar-leads-icon.svg";
export { ReactComponent as SidebarMarketplaceHover } from "./sidebar-marketplace-icon-hover.svg";
export { ReactComponent as SidebarMarketplace } from "./sidebar-marketplace-icon.svg";
export { ReactComponent as SidebarSettingsHover } from "./sidebar-settings-icon-hover.svg";
export { ReactComponent as SidebarSettings } from "./sidebar-settings-icon.svg";
export { ReactComponent as SignOutHover } from "./sign-out-hover.svg";
export { ReactComponent as SignOut } from "./sign-out.svg";
export { ReactComponent as SignedDocumentIcon } from "./signed-document.svg";
export { ReactComponent as SkipAttachment } from "./skip-attachment.svg";
export { ReactComponent as SmallBarChart } from "./small-bar-chart.svg";
export { ReactComponent as SmartFieldIcon } from "./smart-field.svg";
export { ReactComponent as SsnFailIcon } from "./ssn-fail-icon.svg";
export { ReactComponent as StarFilledIcon } from "./star-filled.svg";
export { ReactComponent as StarOutlineHoverIcon } from "./star-outline-hover.svg";
export { ReactComponent as BaseStarOutlineIcon } from "./star-outline.svg";
export { ReactComponent as StepBackIcon } from "./step-back.svg";
export { ReactComponent as StepForwardIcon } from "./step-forward.svg";
export { ReactComponent as StripeIcon } from "./stripe.svg";
export { ReactComponent as SuccessIcon } from "./success.svg";
export { ReactComponent as SupportHover } from "./support-hover.svg";
export { ReactComponent as Support } from "./support.svg";
export { ReactComponent as ThumbsDownIcon } from "./thumbs-down.svg";
export { ReactComponent as ThumbsUpIcon } from "./thumbs-up.svg";
export { ReactComponent as TotallySecureIcon } from "./totally-secure.svg";
export { ReactComponent as TransUnionIcon } from "./transUnion_logo.svg";
export { ReactComponent as TransUnionIconBig } from "./transUnion_logo_big.svg";
export { ReactComponent as TransparentSmallIcon } from "./transparent-small.svg";
export { ReactComponent as TransparentIcon } from "./transparent.svg";
export { ReactComponent as TrustedIcon } from "./trusted.svg";
export { ReactComponent as UnitsIcon } from "./units.svg";
export { ReactComponent as UploadAttachIcon } from "./upload-file.svg";
export { ReactComponent as UploadNewIcon } from "./upload-new.svg";
export { ReactComponent as UploadIcon } from "./upload.svg";
export { ReactComponent as VeroPlusLogoBigIcon } from "./v-plus-mark-big.svg";
export { ReactComponent as VeroPlusLogoMenuIcon } from "./v-plus-mark-menu.svg";
export { ReactComponent as SendIcon } from "./vector.svg";
export { ReactComponent as VerifiedIncomeAssetsSmall } from "./verified-income-assets-small.svg";
export { ReactComponent as WarningIcon } from "./warning.svg";
export { ReactComponent as WorkflowCircleBlackIcon } from "./workflow-circle-black.svg";
export { ReactComponent as YardiGraySmall } from "./yardi-gray-small.svg";
export { ReactComponent as YardiGray } from "./yardi-gray.svg";
export { ReactComponent as YardiGreenSmall } from "./yardi-green-small.svg";
export { ReactComponent as YardiGreen } from "./yardi-green.svg";
export { ReactComponent as YardiPurpleSmall } from "./yardi-purple-small.svg";
export { ReactComponent as ZoomMinusDisabled } from "./zoom-minus-disabled.svg";
export { ReactComponent as ZoomMinusHover } from "./zoom-minus-hover.svg";
export { ReactComponent as ZoomMinus } from "./zoom-minus.svg";
export { ReactComponent as ZoomPlusDisabled } from "./zoom-plus-disabled.svg";
export { ReactComponent as ZoomPlusHover } from "./zoom-plus-hover.svg";
export { ReactComponent as ZoomPlus } from "./zoom-plus.svg";

export { ReactComponent as AttentionIcon } from "./attention.svg";
export { ReactComponent as CloseOneIcon } from "./close-one.svg";
export * from "./entrata";
export { ReactComponent as NewCheckIcon } from "./new-check.svg";
export { ReactComponent as PowerdByVero } from "./powered-by-vero.svg";
export { ReactComponent as Vero } from "./vero.svg";
export * from "./real-page";
export { ReactComponent as TimeIcon } from "./time.svg";
export { ReactComponent as TextRecognition } from "./text-recognition.svg";
export { ReactComponent as Document } from './document_default.svg'
export { ReactComponent as BlueQuestionMark } from './blue-question-mark.svg'

// NOTE: Example of providing tags
Applications.tags = ["renter", "screening"];
LumenBig.tags = ["lumen", "big", "bulb"];
LumenSmallBackground.tags = ["lumen", "small", "bulb", "background"];
BulbGreenSmall.tags = ["lumen", "small", "bulb", "green"];
BulbPurpleSmall.tags = ["lumen", "small", "bulb", "purple"];
BulbOrangeSmall.tags = ["lumen", "small", "bulb", "orange"];
BulbRedSmall.tags = ["lumen", "small", "bulb", "red"];
BulbGraySmall.tags = ["lumen", "small", "bulb", "gray"];
CheckGreenBig.tags = ["check", "big", "approve", "green"];
CrossRedBig.tags = ["cross", "big", "decline", "red"];
ViewReport.tags = ["report", "button", "green"];
ViewReportDisabled.tags = ["report", "button", "disabled", "green"];
CheckGreenSmall.tags = ["check", "small", "green"];
CrossRedSmall.tags = ["cross", "small", "red"];
EmptyBox.tags = ["box", "empty"];
Calculating.tags = ["calculate", "calculator"];
OrangeExclamation.tags = ["exclamation", "warning", "orange", "big"];
OrangeExclamationHollow.tags = [
  "exclamation",
  "warning",
  "orange",
  "big",
  "hollow",
];
QRCodeIcon.tags = ["QR", "download"];
QRCodeGreenIcon.tags = ["QR", "download", "green"];
DocumentsEmptyIcon.tags = ["copy", "documents"];
DocumentsEmptyGreenIcon.tags = ["copy", "documents", "green"];
OpenApiIcon.tags = ["Open Api"];
InfoDark.tags = ["info", "small", "dark"];
DownloadPdfDocBase.tags = ["download", "pdf", "document"];
DownloadPdfDocHover.tags = ["download", "pdf", "document", "hover"];
InfoIcon.tags = ["info", "exclamation", "green", "light"];

ApplicantInviteBase.tags = ["applicant", "individual", "invite", "add", "plus"];
ApplicantInviteDisabled.tags = [
  "applicant",
  "individual",
  "invite",
  "add",
  "plus",
  "disabled",
];
ApplicantInviteHover.tags = [
  "applicant",
  "individual",
  "invite",
  "add",
  "plus",
  "hover",
];
ChevronDownGreenIcon.tags = ["arrow", "down", "expand", "show"];
ChevronDownWhiteBoldIcon.tags = ["arrow", "down", "expand", "show"];
EmailIcon.tags = ["email", "letter", "violet", "dark"];
EmailSentIcon.tags = ["email", "sent", "letter"];
EmailLight.tags = ["email", "letter", "violet", "light"];
MagicPortalLinkIcon.tags = ["Magic Links", "Magic Portal"];

LinkEmployment.tags = ["employment", "link", "argyle", "briefcase"];

LinkEmploymentNoCompany.tags = [
  "employment",
  "link",
  "argyle",
  "briefcase",
  "no company",
  "red mark",
];

LinkEmploymentHasCompany.tags = [
  "employment",
  "link",
  "argyle",
  "briefcase",
  "has company",
  "green mark",
];

IdVerificationStart.tags = [
  "id",
  "identity",
  "verification",
  "start",
  "initial",
  "begin",
];

IdVerificationNotVerified.tags = [
  "id",
  "identity",
  "verification",
  "fail",
  "not verified",
  "error",
  "red",
];

IdVerificationVerified.tags = [
  "id",
  "identity",
  "verification",
  "success",
  "verified",
  "green",
];

NoVerificationsAvailable.tags = [
  "verification",
  "not available",
  "violet",
  "light",
];

NoPhotosAvailable.tags = ["verification", "photo", "violet", "light"];

BankWarning.tags = ["verification", "bank", "warning"];

BarChart.tags = ["bar", "chart", "light", "violet"];

BaseLynkSuccess.tags = ["argyle", "lynk", "success", "verification", "green"];
BaseLynkSkipped.tags = ["argyle", "lynk", "skipped", "verification", "orange"];
BaseSnapPassed.tags = [
  "persona",
  "snap",
  "success",
  "passed",
  "verification",
  "green",
];
BaseSnapFailed.tags = [
  "persona",
  "snap",
  "failed",
  "skipped",
  "verification",
  "orange",
];
GoogleLogo.tags = ["google", "logo"];
FacebookLogo.tags = ["facebook", "fb", "logo"];
LinkedInLogo.tags = ["linkedin", "logo"];
RipplingLogo.tags = ["rippling", "logo"];

PrescreeningLeadsVerification.tags = ["prescreening", "leads", "verification"];
PrescreeningLeads.tags = ["prescreening", "leads", "menu"];
PrescreeningLeadsHover.tags = ["prescreening", "leads", "menu", "hover"];

EditPencilIcon.tags = ["edit", "pencil"];
EditPencilLargeIcon.tags = ["edit", "pencil"];
HourglassIcon.tags = ["renewal", "wait", "clock", "hourglass"];
DocumentProgressIcon.tags = ["renewal", "lease", "document", "waiting"];
DocumentWhiteIcon.tags = ["renewal", "lease", "document"];
NotRenewingIcon.tags = ["renewal"];
SignLeaseIcon.tags = ["renewal", "lease", "document", "sign"];
DocumentWhiteCheckedIcon.tags = ["renewal", "lease", "document", "checked"];
QuestionBadge.tags = ["question", "info"];

export {
  ApplicantInviteBase,
  ApplicantInviteDisabled,
  ApplicantInviteHover,
  Applications,
  BankWarning,
  BarChart,
  BaseLynkSkipped,
  BaseLynkSuccess,
  BaseSnapFailed,
  BaseSnapPassed,
  BaseSnapPending,
  BulbWhiteSmall,
  BulbGraySmall,
  BulbGreenSmall,
  BulbOrangeSmall,
  BulbPurpleSmall,
  BulbRedSmall,
  Calculating,
  CheckGreenBig,
  CheckGreenSmall,
  ChevronDownGreenIcon,
  ChevronDownWhiteBoldIcon,
  CrossRedBig,
  CrossRedSmall,
  DocumentProgressIcon,
  DocumentWhiteCheckedIcon,
  DocumentWhiteIcon,
  DocumentsEmptyGreenIcon,
  DocumentsEmptyIcon,
  DownloadPdfDocBase,
  DownloadPdfDocHover,
  EditPencilIcon,
  EditPencilLargeIcon,
  EmailIcon,
  EmailLight,
  EmailSentIcon,
  EmptyBox,
  FacebookLogo,
  GoogleLogo,
  HourglassIcon,
  IdVerificationNotVerified,
  IdVerificationStart,
  IdVerificationVerified,
  InfoDark,
  InfoDarkCircle,
  InfoIcon,
  LinkEmployment,
  LinkEmploymentHasCompany,
  LinkEmploymentNoCompany,
  LinkedInLogo,
  LumenBig,
  LumenSmallBackground,
  MagicPortalLinkIcon,
  NoPhotosAvailable,
  NoVerificationsAvailable,
  NotRenewingIcon,
  OpenApiIcon,
  InfoStandard,
  BlackArrowLeft,
  WhiteArrowRight,
  OrangeExclamation,
  OrangeExclamationHollow,
  DarkOrangeExclamationHollow,
  BlackExclamationHollow,
  GrayExclamationHollow,
  RedExclamationHollow,
  PrescreeningLeads,
  PrescreeningLeadsHover,
  PrescreeningLeadsVerification,
  QRCodeGreenIcon,
  QRCodeIcon,
  QuestionBadge,
  RipplingLogo,
  SignLeaseIcon,
  ViewReport,
  ViewReportDisabled,
  RemoveOne,
};
