import * as Yup from "yup";

import { DOCUMENT_TYPES } from "shared/config/constants";

export const validationSchema = Yup.object().shape({
  name: Yup.string().required("Document name is required"),
  type: Yup.string().required("Document type is required"),
  riderType: Yup.string().when("type", {
    is: DOCUMENT_TYPES.rider,
    then: Yup.string().required("Rider type is required"),
    otherwise: Yup.string(),
  }),
  tags: Yup.array().of(Yup.string()),
});
