import React from "react";
import _ from 'lodash'
import { Route, Switch, useLocation, useHistory } from "react-router-dom";

import ROUTES from "manager/config/routes";
import NotificationCounter from "shared/components/NotificationCounter";
import Tabs from "shared/components/Tabs";
import Tile from "shared/components/Tile";
import { transformRoute } from "shared/utils/routing";

export const AppTabs: React.FC<{
  dealId: string;
  manualNotes?: number;
  Activity: React.FC;
  Lumen: React.FC;
  Ledger: React.FC;
}> = ({ dealId, manualNotes, Activity, Lumen, Ledger, children }) => {
  const history = useHistory();
  const dealRoute = transformRoute(ROUTES.deal, { id: dealId });
  const dealActivityRoute = transformRoute(ROUTES.dealActivity, { id: dealId });
  const dealLumenRoute = transformRoute(ROUTES.dealLumen, { id: dealId });
  const dealLedgerRoute = transformRoute(ROUTES.dealLedger, { id: dealId });
  const activeRoute = useLocation().pathname;
  const tabs = _.compact([
    { key: dealRoute, label: "Applicants" },
    {
      key: dealActivityRoute,
      label: (
        <>
          <span style={{ marginRight: 5 }}>Activity</span>
          {!!manualNotes && <NotificationCounter count={manualNotes} />}
        </>
      ),
    },
    Lumen && { key: dealLumenRoute, label: "Lumen" },
    { key: dealLedgerRoute, label: "Ledger" },
  ]);

  return (
    <>
      <Tile>
        <Tabs
          activeKey={activeRoute}
          tabs={tabs}
          onChange={(newRoute: string) => {
            history.push(newRoute);
          }}
        />
      </Tile>

      <Switch>
        <Route exact path={dealRoute} render={() => children} />
        <Route exact path={dealActivityRoute} render={() => <Activity />} />
        <Route exact path={dealLumenRoute} render={() => <Lumen />} />
        <Route exact path={dealLedgerRoute} render={() => <Ledger />} />
      </Switch>
    </>
  );
};
