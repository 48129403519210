import styled from "@emotion/styled";

export const ScrollContainerWrapper = styled("div")({
  position: "absolute",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
});

export const ScrollContainer = styled("div")({
  overflow: "auto",
  height: "100%",
  width: "100%",
  position: "absolute",
  top: 0,
  left: 0,
});

export const LoaderContainer = styled("div")({
  position: "absolute",
  top: "40%",
  left: "40%",
});

export const FileUploadContainer = styled("div")({
  position: "absolute",
  top: "30%",
  left: "30%",
  zIndex: 100,
});

export const SlidingContainer = styled("div")({
  display: "flex",
  height: "100%",
  alignContent: "stretch",
  flexGrow: 1,
});

export const SlidingContentContainer = styled("div")({
  flexGrow: 1,
  position: "relative",
});

export const TooltipContainer = styled("div")({
  marginLeft: "auto",
});
