import React, { useState } from "react";

import dayjs from "dayjs";

import Collapse from "shared/components/Collapse";
import { DocumentInfo } from "shared/components/DocumentViewer";
import { Select } from "shared/components/Form";
import Tag from "shared/components/Tag";
import { COLORS, DATE_FORMAT } from "shared/config/constants";

import { DocumentInfoContentProps } from "../interfaces";

import {
  CollapseHeader,
  TagsRow,
  AddTagsContainer,
  styledCloseIcon as CloseIcon,
  AddTagsLink,
} from "./styled";

const { Row, Primary, Secondary } = DocumentInfo;

const DocumentInfoContent = ({
  document,
  tags = [],
  isTagsLoading,
  onAddTag,
  onDeleteTag,
  isTagAdding,
  isTagDeleting,
  selectedTags = [],
}: DocumentInfoContentProps) => {
  const [isTagsVisible, setTagsVisible] = useState(false);

  const formattedDate = dayjs(document.createdAt).format(DATE_FORMAT);
  const companiesCount = document.companiesAssigned?.length;
  const companies = document.companiesAssigned?.join(", ");
  const fields = document.fields?.map(({ name }) => name).join(", ");

  const switchTagsVisibility = () => {
    const toggle = !isTagsVisible;
    setTagsVisible(toggle);
  };

  return (
    <>
      <Row>
        <Primary>Name:</Primary>
        <Secondary>{document.name}</Secondary>
      </Row>
      <Row>
        <Primary>Type:</Primary>
        <Secondary>{document.typeDisplayName}</Secondary>
      </Row>
      {document.riderTypeDisplayName && (
        <Row>
          <Primary>Rider Type:</Primary>
          <Secondary>{document.riderTypeDisplayName}</Secondary>
        </Row>
      )}
      <TagsRow>
        <Primary>Tags:</Primary>
        <Secondary>
          {selectedTags?.map((tag) => (
            <Tag
              key={tag}
              backgroundColor={COLORS.violetTheme.blueViolet}
              color={COLORS.violetTheme.white}
            >
              {tag}
              <CloseIcon
                data-testid={`delete-${tag}`}
                onClick={() => onDeleteTag(tag)}
              />
            </Tag>
          ))}
        </Secondary>
        <AddTagsContainer>
          {!isTagsVisible && (
            <AddTagsLink
              onClick={switchTagsVisibility}
              dashed
              violetTheme
              data-testid="add-tag-link"
            >
              + TAGS
            </AddTagsLink>
          )}
          {isTagsVisible && (
            <Select
              id="tags"
              name="tags"
              placeholder="Type the tag name"
              mode="tags"
              onSelect={onAddTag}
              onDeselect={onDeleteTag}
              value={selectedTags}
              loading={isTagsLoading || isTagAdding || isTagDeleting}
              violetTheme
              onBlur={switchTagsVisibility}
            >
              {tags.map((name) => (
                <Select.Option key={name}>{name}</Select.Option>
              ))}
            </Select>
          )}
        </AddTagsContainer>
      </TagsRow>
      <Row>
        <Primary>Date uploaded:</Primary>
        <Secondary>{formattedDate}</Secondary>
      </Row>
      <Row>
        <Collapse
          key="companies"
          header={({ CollapseIcon }) => (
            <CollapseHeader>
              <Primary>Companies assigned:</Primary>
              <Secondary>
                {companiesCount} <CollapseIcon />
              </Secondary>
            </CollapseHeader>
          )}
        >
          <Secondary>{companies}</Secondary>
        </Collapse>
      </Row>
      <Row>
        <Primary>Fields used:</Primary>
        <Secondary>{fields}</Secondary>
      </Row>
    </>
  );
};

export default DocumentInfoContent;
