import styled from "@emotion/styled";
import { Row, Col } from "antd";

import Banner from "shared/components/Banner";
import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import Tag from "shared/components/Tag";
import Tile from "shared/components/Tile";
import { SmallText } from "shared/components/Typography";
import { colors, COLORS, MEDIA_QUERIES } from "shared/config/constants";
import Collapse from "shared/components/Collapse";

const ReasonWrapper = styled(FlexContainer)`
  padding: 5px;
  .warning-icon {
    margin-right: 8px;
  }
`;

const TileCustom = styled(Tile)`
  .tile-header-title-container h2 {
    color: ${colors.secondary[500]};
  }
`;

const EmploymentHistoryCustom = styled.div`
  .tile-header-title-container h2 {
    color: ${colors.secondary[500]};
  }
`;

const TitleCustom = styled.h2`
  color: ${colors.secondary[500]};
`;

const TagColor = styled(Tag)<{ color?: string }>`
  background: ${({ color }) => `${color} !important`};
`;

const IconInfo = styled(Icon.Info)`
  width: 14.33px;
  height: 14.33px;
  margin-left: 10px;
  cursor: pointer;
`;

const RowIncomeStreams = styled(Row)`
  background: ${colors.neutral[100]};
  border-radius: 4px;
  margin-top: 7px !important;
  width: 102%;
  margin-left: -16px;
  padding-left: 16px;
  margin-top: 25px !important;

  ${MEDIA_QUERIES.w.ltMd} {
    flex-direction: column;
  }
`;

const AveragePaymentCol = styled(Col)`
  ${MEDIA_QUERIES.w.gteXxl} {
    background: red !important;
    margin-left: -11px;
  }
`;

const MinMaxText = styled(SmallText)`
  ${MEDIA_QUERIES.w.gteXxxl} {
    margin-left: -5px;
  }
`;

const AveragePaymentText = styled(SmallText)`
  ${MEDIA_QUERIES.w.gteLg} {
    margin-left: 12px;
  }

  ${MEDIA_QUERIES.w.gteXl} {
    margin-left: 16px;
  }

  ${MEDIA_QUERIES.w.gteXxl} {
    margin-left: 30px;
  }
  ${MEDIA_QUERIES.w.gteXxxl} {
    margin-left: 38px;
  }
`;

const LastPaymentText = styled(SmallText)`
  ${MEDIA_QUERIES.w.gteLg} {
    margin-left: 15px;
  }

  ${MEDIA_QUERIES.w.gteXl} {
    margin-left: 18px;
  }

  ${MEDIA_QUERIES.w.gteXxl} {
    margin-left: 24px;
  }
  ${MEDIA_QUERIES.w.gteXxxl} {
    margin-left: 45px;
  }
`;

const InactiveReasonCollapse = styled(Collapse)`
  background: ${colors.neutral[100]};
  margin-left: -16px;
  margin-right: -14px;
  padding-left: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
`;

const IncomeNote = styled(SmallText)`
  padding-top: 24px;
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

const FraudBanner = styled(Banner)`
  background-color: ${COLORS.excludedRed};
  font-size: 12px;
  padding: 16px 24px;
  span, a {
    color: ${COLORS.white} !important;
  }

  ${MEDIA_QUERIES.w.ltLg} {
    padding: 8px;
  }
`;
const RiskBanner = styled(Banner)`
  background-color: ${COLORS.excludedOrange};
  font-size: 12px;
  padding: 16px 24px;

  ${MEDIA_QUERIES.w.ltLg} {
    padding: 8px;
  }
`;

const BannerContentContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export {
  ReasonWrapper,
  TileCustom,
  EmploymentHistoryCustom,
  TitleCustom,
  TagColor,
  IconInfo,
  RowIncomeStreams,
  AveragePaymentCol,
  AveragePaymentText,
  MinMaxText,
  LastPaymentText,
  InactiveReasonCollapse,
  IncomeNote,
  FraudBanner,
  RiskBanner,
  BannerContentContainer,
};
