import React from "react";

import LargeLoader from "./LargeLoader";

export type LoaderProps = {
  loading: boolean;
  absoluteCenter?: boolean;
};

export const Loader: React.FC<LoaderProps> = ({
  loading,
  children,
  absoluteCenter = false,
}) => {
  return loading ? (
    <LargeLoader showLoader absoluteCenter={absoluteCenter} />
  ) : (
    <>{children}</>
  );
};
