import styled from "@emotion/styled";

import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

const QuestionStepWrapper = styled.div`
  .header {
    .title {
      color: ${COLORS.white} !important;
      margin-bottom: 8px;

      ${MEDIA_QUERIES.w.ltMd} {
        font-size: 17px;
      }
    }

    .subtitle {
      ${MEDIA_QUERIES.w.ltMd} {
        font-size: 12px;
      }
    }

    .content {
      padding: 16px;
      background-color: ${COLORS.primary};

      ${MEDIA_QUERIES.w.ltMd} {
        padding: 18px 30px;
      }
    }

    .steps-wheel > div:first-of-type {
      width: 80px;
      height: 80px;
      margin-right: 40px;

      ${MEDIA_QUERIES.w.ltMd} {
        min-width: 60px;
        width: 60px;
        min-height: 60px;
        height: 60px;
        margin-right: 30px;
      }

      .CircularProgressbar > .CircularProgressbar-trail {
        stroke: ${COLORS.lightPurple};
      }

      div[data-test-id="CircularProgressbarWithChildren"]
        div[data-test-id="CircularProgressbarWithChildren__children"] {
        width: 80px !important;
        height: 80px !important;

        ${MEDIA_QUERIES.w.ltMd} {
          width: 60px !important;
          height: 60px !important;
        }

        & > span {
          span:first-of-type {
            font-size: 30px;

            ${MEDIA_QUERIES.w.ltMd} {
              font-size: 22px;
            }
          }

          span:not(:first-of-type) {
            font-size: 22px;
          }
        }
      }
    }
  }

  .content {
    padding: 48px;

    ${MEDIA_QUERIES.w.ltMd} {
      padding: 60px 30px;
    }

    .question {
      margin-bottom: 0;
    }

    .answers-container {
      margin-top: 40px;
      display: inline-block;
      width: fit-content;
      position: relative;

      br {
        display: none;

        ${MEDIA_QUERIES.w.ltMd} {
          &:nth-of-type(5) {
            display: inline;
          }
        }
      }

      & > div:last-of-type {
        span {
          position: absolute;
        }

        span:first-of-type {
          ${MEDIA_QUERIES.w.gteMd} {
            left: 0;
            bottom: 0;
            transform: translateY(100%);
          }

          ${MEDIA_QUERIES.w.ltMd} {
            top: 8px;
            transform: translateY(-100%);
          }
        }

        span:last-of-type {
          right: 0;
          bottom: 0;
          transform: translateY(100%);
        }
      }
    }

    .comment-link {
      margin-top: 32px;
      margin-bottom: 64px;

      textarea {
        margin-top: 10px;
      }

      ${MEDIA_QUERIES.w.ltMd} {
        & + div > a {
          border: 1px solid ${COLORS.primary};
          border-radius: 13px;
          min-width: 50px;
          height: 50px;
          justify-content: center;
          margin-right: 24px;

          svg path {
            stroke: ${COLORS.primary};
          }
        }
      }
    }

    .next-btn {
      margin-left: auto;

      ${MEDIA_QUERIES.w.ltMd} {
        width: 100%;
        height: 50px;
        border-radius: 13px;
        .button-children + span {
          display: none;
        }
      }
    }
  }
`;

interface AnswerProps {
  readonly active?: boolean;
}

const Answer = styled.div<AnswerProps>`
  width: 40px;
  height: 40px;
  border: 1px solid ${COLORS.primary};
  border-radius: 2px;
  color: ${COLORS.lightPurple};
  font-size: 22px !important;
  display: inline-flex;
  text-align: center;
  cursor: pointer;
  margin-right: 4px;
  align-items: center;
  justify-content: center;

  ${MEDIA_QUERIES.w.gteMd} {
    &:last-of-type {
      margin-right: 0;
    }
  }

  ${MEDIA_QUERIES.w.ltMd} {
    margin-right: 12px;
    margin-top: 12px;

    &:nth-of-type(5n) {
      margin-right: 0;
    }
  }

  ${({ active }) =>
    active &&
    `
    background-color: ${COLORS.midGray};
    &:focus {
      background-color: ${COLORS.midGray};
    }
  `}
`;

export { QuestionStepWrapper, Answer };
