import React from "react";

import Icon from "shared/components/Icon";
import OverflowText from "shared/components/OverflowText";
import { RegularText } from "shared/components/Typography";
import { getDurationLabel } from "shared/utils/ui";

import { StyledWell, RequirementLabel } from "./styled";

const APPLICANT_NOTE_KEYWORD = {
  residential: "residential",
  employment: "employment",
};

interface NonMatchingCriteriaNoteProps {
  durationType?: string;
  durationValue?: number;
  keyword?: string;
  note?: string;
  isRequirementMet?: boolean;
}

const NonMatchingCriteriaNote = ({
  durationType = "",
  durationValue = undefined,
  keyword = undefined,
  note = "",
  isRequirementMet = false,
}: NonMatchingCriteriaNoteProps) => {
  const duration = getDurationLabel(durationType, durationValue);

  return (
    <StyledWell noBorder isRequirementMet={isRequirementMet}>
      {!isRequirementMet && (
        <RequirementLabel className="label">
          <Icon.OrangeExclamation className="warn-icon" />
          <RegularText>
            {`The ${keyword} history doesn’t meet the minimum of ${duration} required`}
          </RegularText>
        </RequirementLabel>
      )}
      <RegularText strong>Applicant&apos;s note:&nbsp;</RegularText>
      <OverflowText TextElement={RegularText} content={note} />
    </StyledWell>
  );
};

export default NonMatchingCriteriaNote;

export { APPLICANT_NOTE_KEYWORD };
