import { Form, Formik, FormikProps } from "formik";
import React from "react";
import { Helmet } from "react-helmet";
import ErrorList from "shared/components/ErrorList";
import FlexContainer from "shared/components/FlexContainer";
import { Input, VeroFormField } from "shared/components/Form";
import Icon from "shared/components/Icon";
import Spacer from "shared/components/Spacer";
import { LargeTitle, RegularText } from "shared/components/Typography";
import AuthPage from "shared/templates/AuthPage";
import { initialValues, ValidationSchema } from "./config";
import {
  PasswordRecoveryPageDataFormik,
  PasswordRecoveryPageDataFormikProps,
} from "./interfaces";
import { PasswordRecoveryPageCard, SubmitButton } from "./styled";

interface PasswordRecoveryPageProps {
  submit: (values: PasswordRecoveryPageDataFormik) => void;
  formikRef?: React.MutableRefObject<
    FormikProps<PasswordRecoveryPageDataFormik>
  >;
}

const PasswordRecoveryPage = ({
  submit,
  formikRef,
}: PasswordRecoveryPageProps) => (
  <>
    <Helmet>
      <title>Password Recovery</title>
    </Helmet>
    <AuthPage>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={ValidationSchema}
        onSubmit={submit}
      >
        {({ errors, isSubmitting }: PasswordRecoveryPageDataFormikProps) => (
          <PasswordRecoveryPageCard>
            <Form noValidate>
              <FlexContainer justifyContent="center">
                <Icon.LogoIcon />
              </FlexContainer>

              <Spacer size={Spacer.SIZES.md} />

              <LargeTitle className="title">Password Recovery</LargeTitle>

              <RegularText weak className="description">
                Please enter your email and we will send a recovery link to your
                inbox.
              </RegularText>

              <Spacer size={Spacer.SIZES.md} />

              {errors.nonFieldErrors && (
                <ErrorList errors={errors.nonFieldErrors} />
              )}
              <VeroFormField
                name="email"
                id="email"
                type="email"
                label="Email"
                as={Input}
                tabIndex={0}
                autoFocus
              />
              <Spacer />
              <SubmitButton
                type="primary"
                data-testid="submit-button"
                htmlType="submit"
                loading={isSubmitting}
              >
                Get Recovery Link
              </SubmitButton>
            </Form>
          </PasswordRecoveryPageCard>
        )}
      </Formik>
    </AuthPage>
  </>
);

export default PasswordRecoveryPage;
