import axios from "axios";

import MANAGER_API_ROUTES from "manager/config/apiRoutes";
import { API_URL } from "shared/lib/api/setupApi";
import { transformRoute } from "shared/utils/routing";

const getRoute = (relativeRoute, alertId) =>
  transformRoute(`${API_URL}${relativeRoute}`, { alertId });

const api = {
  postYardiAlertResolveLinkProperty: (provider, alertId, body) =>
    axios
      .post(
        getRoute(MANAGER_API_ROUTES.yardiResolveLinkProperty, alertId),
        body,
        { params: { provider } }
      )
      .then((response) => response.data),
  postYardiAlertResolveLinkUnit: (provider, alertId, body) =>
    axios
      .post(getRoute(MANAGER_API_ROUTES.yardiResolveLinkUnit, alertId), body, {
        params: { provider },
      })
      .then((response) => response.data),
  postYardiAlertResolveCreateMarketingSource: (provider, alertId, body) =>
    axios
      .post(
        getRoute(MANAGER_API_ROUTES.yardiResolveCreateMarketingSource, alertId),
        body,
        { params: { provider } }
      )
      .then((response) => response.data),
  postYardiAlertResolveCreateLeadSource: (provider, alertId, body) =>
    axios
      .post(
        getRoute(MANAGER_API_ROUTES.yardiResolveCreateLeadSource, alertId),
        body,
        { params: { provider } }
      )
      .then((response) => response.data),
  postYardiAlertResolveCreateUnitListing: (provider, alertId, body) =>
    axios
      .post(
        getRoute(MANAGER_API_ROUTES.yardiResolveCreateUnitListing, alertId),
        body,
        { params: { provider } }
      )
      .then((response) => response.data),
  postYardiAlertResolveLinkAgents: (provider, alertId, body) =>
    axios
      .post(
        getRoute(MANAGER_API_ROUTES.yardiResolveLinkAgents, alertId),
        body,
        { params: { provider } }
      )
      .then((response) => response.data),
  postYardiAlertResolveLinkMarketingSources: (provider, alertId, body) =>
    axios
      .post(
        getRoute(MANAGER_API_ROUTES.yardiResolveLinkMarketingSources, alertId),
        body,
        { params: { provider } }
      )
      .then((response) => response.data),
  postYardiAlertResolveLinkChargeTypes: (provider, alertId, body) =>
    axios
      .post(
        getRoute(MANAGER_API_ROUTES.yardiResolveLinkChargeTypes, alertId),
        body,
        { params: { provider } }
      )
      .then((response) => response.data),
  postYardiAlertResolveLinkAttachmentTypes: (provider, alertId, body) =>
    axios
      .post(
        getRoute(MANAGER_API_ROUTES.yardiResolveLinkAttachmentTypes, alertId),
        body,
        { params: { provider } }
      )
      .then((response) => response.data),
};

export default api;
