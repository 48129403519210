import React from "react";

import { useHistory } from "react-router-dom";

import { inviteApplicants } from "manager/components/Modal/InviteApplicantsModal/util";
import InviteApplicantsWithLeadsModal from "manager/components/Modal/InviteApplicantsWithLeadsModal";
import useModal from "shared/hooks/useModal";
import { onDialogSubmitError } from "shared/utils/api";

const DealInviteApplicantsModalContainer = () => {
  const history = useHistory();
  const {
    currentModalContext: { dealId, refetchDeal, applicantsSummary },
    closeActiveModalDialog,
  } = useModal();

  const submit = (values, formikApi) =>
    inviteApplicants({
      values,
      resolve: () => refetchDeal?.(),
      reject: onDialogSubmitError(formikApi, history),
      history,
      closeActiveModalDialog,
    });

  const onSubmit = ({ applicants }, formikApi) =>
    submit({ applicants, dealId }, formikApi);

  return (
    <InviteApplicantsWithLeadsModal
      applicants={applicantsSummary}
      onSubmit={onSubmit}
    />
  );
};

export default DealInviteApplicantsModalContainer;
