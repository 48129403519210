import React from "react";

import PropTypes from "prop-types";

import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";

import { FilterCondition } from "./styled";

const BaseCondition = ({ conditionLabel, onDelete, children }) => {
  return (
    <FilterCondition>
      <span className="first-col">{conditionLabel}</span>
      <div className="second-col">
        <FlexContainer
          justifyContent="space-between"
          alignItems="center"
          fullWidth
        >
          <FlexContainer alignItems="center" fullWidth>
            {children}
          </FlexContainer>
          <Icon.DeleteIcon className="delete-icon" onClick={onDelete} />
        </FlexContainer>
      </div>
    </FilterCondition>
  );
};

BaseCondition.propTypes = {
  conditionLabel: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default BaseCondition;
