import styled from "@emotion/styled";

import Icon from "shared/components/Icon";
import Tile from "shared/components/Tile";
import { COLORS } from "shared/config/constants";

const TileCustom = styled(Tile)`
  .tile-header {
    align-items: center;
  }
  .tile-header-title-container {
    width: 100%;
    > div,
    h2 > div {
      width: 100%;
      align-items: center;
    }
    div {
      font-size: 15px;
      font-weight: 400;
    }
    .main-header-left {
      &.vor {
        width: 100%;
        > div {
          width: 70%;
        }
      }
      .bold {
        font-size: 17px;
        font-weight: 500;
      }
    }
  }
  &.verified {
    background-color: ${COLORS.lightGreen};
  }
  &.unverified {
    background-color: ${COLORS.well.backgroundLightError};
  }
`;

const SubTileCustom = styled(Tile)`
  .tile-header-title-container h2 {
    font-size: 15px;
    font-weight: 400;
  }
`;

const PersonIcon = styled(Icon.PersonIcon)`
  width: 24px;
  height: 24px;
  margin-right: 11px;
`;

const VerifiedIcon = styled(Icon.CheckIcon)`
  width: 16px;
  height: 16px;
  margin-right: 10px;
`;

const FailedIcon = styled(Icon.ErrorTriangleIcon)`
  width: 16px;
  height: 16px;
  margin-right: 10px;
`;

const Details = styled.span<{ bold?: boolean }>`
  font-size: 15px;
  font-weight: ${(props) => props.bold && 400};
`;

const VerificationContainer = styled(Tile.Inner)`
  background-color: ${COLORS.lightGray2};
  padding: 0px;
`;

export {
  TileCustom,
  SubTileCustom,
  PersonIcon,
  VerifiedIcon,
  FailedIcon,
  Details,
  VerificationContainer,
};
