import React from "react";
import _ from "lodash";
import { FadeIn } from "shared/components/animated";
import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import Spacer from "shared/components/Spacer";
import Tooltip from "shared/components/Tooltip";
import {
  MediumTitle,
  RegularText,
  SmallText,
} from "shared/components/Typography";

import { ApplicantType } from "shared/graphql";
import { useDeviceType } from "shared/hooks";
import { ApplicantInfoWrapper, Badge } from "./styled";

const Space = () => <Spacer size={Spacer.SIZES.sm} />;

export interface ApplicantInfoProps {
  applicationId: string | number;
  name: string;
  type?: ApplicantType;
  step?: string;
  isSubmitted: boolean;
  isApproved: boolean;
  badges?: string[];
  email: string;
  phone: string;
  inviteLink?: string;
  isBlocked?: boolean;
  hideCriminalHistory?: boolean;
  isPrimary?: boolean;
  hasConditionallyProceedEnabled?: boolean;
  isConditionallyProceeded?: boolean;
  permission?: {
    noMove?: boolean;
    noMoveReason?: string;
    noRemove?: boolean;
    noRemoveReason?: string;
    unblock?: boolean;
    canSendToPms?: boolean;
  };
  onDelete: () => void;
  onViewAttachments: () => void;
  onMove: () => void;
  onViewApp: () => void;
  applicantVrn?: string;
  party?: string;
  location?: string;
}

export const ApplicantInfo = ({
  applicationId,
  name,
  email,
  type,
  badges = [],
  isSubmitted,
  isPrimary,
  onDelete,
  onViewAttachments,
  onMove,
  onViewApp,
  permission = {},
  applicantVrn,
  party
}: ApplicantInfoProps) => {
  const { isMobile } = useDeviceType();

  return (
    <ApplicantInfoWrapper>
      <FadeIn delay={400}>
        <MediumTitle className="applicant-name-title" strong>
          <a
            role="button"
            tabIndex={0}
            href={`/applications/${applicationId}`}
            onClick={onViewApp}
            onKeyPress={onViewApp}
          >
            {name}
          </a>
        </MediumTitle>
        <Tooltip title={applicantVrn} theme="light"><SmallText strong>{email}</SmallText></Tooltip>
        <FlexContainer justifyContent="flex-start" alignItems="center">
          <SmallText light extraStrong>{_.upperCase(party || type)}</SmallText>
        </FlexContainer>
        {Boolean(badges?.length) && (
          <>
            <Space />
            <FlexContainer
              alignItems="center"
              justifyContent="flex-start"
              flexWrap="wrap"
              gap="5px"
            >
              {badges.map((badge) => (
                <Badge>{badge}</Badge>
              ))}
            </FlexContainer>
          </>
        )}

        <Space />
        <FlexContainer alignItems="center" gap="12px">
          <Tooltip
            title={
              permission.noMoveReason ??
              (!isMobile && `Move to another application`)
            } // fix: on mobile clicking this icon shows both tooltip and modal
          >
            <Icon.ApplicantWithArrowIcon
              onClick={!permission.noMove && onMove}
            />
          </Tooltip>
          <Tooltip
            title={
              permission.noRemoveReason ??
              (!isMobile && "Remove from application")
            }
          >
            <Icon.DeleteSmallNoCircleIcon
              onClick={!permission.noRemove && onDelete}
            />
          </Tooltip>
        </FlexContainer>

        {isSubmitted && (
          <>
            <Space />
            {/* @ts-ignore */}
            <RegularText className="action-link" onClick={onViewAttachments}>
              View Attachments
            </RegularText>
          </>
        )}
      </FadeIn>
    </ApplicantInfoWrapper>
  );
};
