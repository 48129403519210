import React from "react";

import { useFormikContext } from "formik";
import PropTypes from "prop-types";

import { SwitchTitle } from "shared/components/Form";
import ConfirmationModal from "shared/components/Modals/ConfirmationModal";
import useModal from "shared/hooks/useModal";

import {
  Section,
  SectionBody,
  SectionTitle,
  YardiGeneralSettingsWrapper,
} from "./styled";

const LRO_FORM_FIELD = "isLroEnabled";

const YardiSectionGeneralSettings = ({ yardiUnitsMap, loading }) => {
  const { openModalDialog } = useModal();
  const { values, setFieldValue, setValues } = useFormikContext();

  const setLroValue = (isLroEnabled) => {
    if (isLroEnabled) {
      const context = {
        title: "Enable Automatic Rent Synchronization",
        message: (
          <>
            Enabling Automatic Rent Synchronization on the current property will
            copy the rent values from the units to the mapped VERO units. After
            that, the rent is read-only, and can only be updated automatically
            by External Provider.
            <br key={1} />
            <br key={2} />
            Do you want to proceed?
          </>
        ),
      };
      openModalDialog(ConfirmationModal, context).afterClose.then((result) => {
        if (result) {
          // NOTE: copy the rents from the YardiUnit to the VeroUnit
          const availableUnits = values?.availableUnits;
          Object.keys(availableUnits).forEach((key) => {
            const mapping = availableUnits[key];
            if (mapping.veroUnit) {
              const yardiUnitId = key;
              const yardiUnit = yardiUnitsMap[yardiUnitId];
              mapping.rent = yardiUnit?.rent || mapping.rent;
            }
          });

          const newValues = {
            ...values,
            isLroEnabled,
            availableUnits,
          };
          setValues(newValues);
        }
      });
    } else {
      setFieldValue(LRO_FORM_FIELD, isLroEnabled);
    }
  };

  return (
    <YardiGeneralSettingsWrapper>
      <Section height="auto" noPaddingBottom>
        <SectionTitle>General settings</SectionTitle>
        <SectionBody>
          <SwitchTitle
            className="switch"
            onChange={(value) => setLroValue(value)}
            value={values.isLroEnabled}
            title="Automatic Rent Synchronization enabled"
            noPadding
            disabled={loading}
          />
        </SectionBody>
      </Section>
    </YardiGeneralSettingsWrapper>
  );
};

YardiSectionGeneralSettings.propTypes = {
  yardiUnitsMap: PropTypes.object.isRequired,
  loading: PropTypes.bool,
};

YardiSectionGeneralSettings.defaultProps = {
  loading: false,
};

export default YardiSectionGeneralSettings;
