import _ from 'lodash'
import React, { useState } from 'react'
import copyToClipboard from 'copy-to-clipboard'
import Prism from 'prismjs'
import './prism-one-light.css'
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter'
import Editor from 'react-simple-code-editor'
import Engine from '@vero/expression-core-node/engine/engine.js'
import { Button } from 'shared/components/Button'
import { buildClassName } from 'shared/utils/vega'

const engine = new Engine(true)

const Formula = ({ formula, highlight = false, format = false, className = undefined }) => {
  const resolvedClassName = buildClassName(['vega formula', format && 'formatted', highlight && 'highlighted', className])

  const resolvedFormula = format ? formula : _.chain(formula).replace(/#[^:]+:/g, '').replace(/^\((.+)\)$/, '$1').value()
  const codeTagProps = {
    className: 'vega'
  }

  try {
    const formattedFormula = format ? engine.format(resolvedFormula) : resolvedFormula

    return (
      <div className={resolvedClassName}>
        {highlight && <SyntaxHighlighter language='javascript' codeTagProps={codeTagProps} wrapLongLines={!format}>{formattedFormula}</SyntaxHighlighter>}
        {!highlight && <code className='vega'>{resolvedFormula}</code>}
      </div>
    )
  } catch (error) {
    return (
      <div className={resolvedClassName}>
        {error && <div className='vega content info error'>{error?.message}</div>}
        {highlight && <SyntaxHighlighter language='javascript' codeTagProps={codeTagProps} wrapLongLines>{resolvedFormula}</SyntaxHighlighter>}
        {!highlight && <code className='vega'>{resolvedFormula}</code>}
      </div>
    )
  }
}

const FormulaEditor = ({ formula, canSave = false, canEdit = true, onChange = undefined, onReset = undefined, onSave = undefined, onDelete = undefined }) => {
  const [error, setError] = useState(null)

  const handleChange = (code) => {
    try {
      setError(null)
      onChange(code)

      engine.format(code)
    } catch (error) {
      setError(error?.message)
    }
  }

  const onFormat = () => {
    try {
      setError(null)
      onChange(engine.format(formula))
    } catch (error) {
      setError(error?.message)
    }
  }

  const onMinimize = () => {
    try {
      setError(null)
      onChange(engine.minimize(formula))
    } catch (error) {
      setError(error?.message)
    }
  }

  return (
    <div className='vega content flex column formula-editor'>
      <div className='vega actions end'>
        <Button type='secondary' onClick={() => copyToClipboard(formula)}>copy</Button>
        {canEdit && <Button type='secondary' onClick={onFormat}>format</Button>}
        {canEdit && <Button type='secondary' onClick={onMinimize}>minimize</Button>}

        {(canSave || error) && canEdit && <Button type='secondary' onClick={() => {
          setError(null)
          onReset()
        }}>reset</Button>}

        {onSave && canSave && canEdit && <Button type='success' onClick={() => {
          setError(null)
          onSave()
        }}>save</Button>}

        {onDelete && canEdit && <Button type='danger' onClick={() => {
          setError(null)
          onDelete()
        }}>delete</Button>}
      </div>

      {error && <div className='vega content info error'>{error}</div>}

      <div className='vega code'>
        {canEdit ? <Editor
          value={formula}
          onValueChange={code => handleChange(code)}
          highlight={code => Prism.highlight(code, Prism.languages.javascript, 'javascript')}
          className='vega editor'
          preClassName='vega'
          textareaClassName='vega'
        /> : <Formula formula={formula} format highlight />}
      </div>
    </div>
  )
}

export { Formula, FormulaEditor }
