import styled from "@emotion/styled";

import { MEDIA_QUERIES } from "shared/config/constants";
import { CenteredFlexPartialCss } from "shared/styles/commonStyles";

const SubmitResultContainer = styled.div`
  ${CenteredFlexPartialCss};
  padding: 45px 0;
  flex-direction: column;

  .confirmation-icon,
  .error-icon {
    margin-bottom: 25px;
  }

  p {
    text-align: center;
  }

  .confirmation-message,
  .error-message {
    font-size: 22px;
    ${MEDIA_QUERIES.w.ltSm} {
      b {
        word-break: break-word;
        display: block;
      }
    }
  }
`;

export { SubmitResultContainer };
