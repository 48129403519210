import { queryCache, useMutation, useQuery } from "react-query";

import { AssignEmployeePortfoliosProperties } from "manager/components/Modal/EmployeePropertiesModal/interfaces";
import { useRequestExport } from "manager/hooks/api/commonQueries";
import {
  GetEmployeesParams,
  UnassignEmployeeFromPortfolioProps,
} from "manager/interfaces/api/employee";
import { editEmployeeName } from "manager/lib/employeeApi";
import { NOTIFICATIONS } from "shared/config/constants";
import {
  assignEmployeePortfoliosProperties,
  deactivateEmployee,
  exportEmployees,
  getEmployee,
  getEmployeePortfoliosProperties,
  getEmployees,
  unassignEmployeePortfoliosProperties,
} from "shared/lib/api/employeeApi";
import { openNotification } from "shared/utils/ui";

export const GET_EMPLOYEES = "getEmployees";
export const GET_EMPLOYEE = "getEmployee";
export const GET_EMPLOYEE_PORTFOLIO_PROPERTIES =
  "getEmployeePortfoliosProperties";

export const EMPLOYEE_QUERY_KEYS = Object.freeze({
  getEmployees: () => [GET_EMPLOYEES],
  getEmployee: (employeeId: number) => [GET_EMPLOYEE, employeeId],
  getEmployeePortfoliosProperties: (employeeId: number | string) => [
    GET_EMPLOYEE_PORTFOLIO_PROPERTIES,
    employeeId,
  ],
});

export const useEmployeeExport = () => {
  const { mutate, isLoading } = useRequestExport(
    (filters: GetEmployeesParams) => exportEmployees(filters),
    "Failed to request an employee download."
  );
  return {
    exportEmployees: mutate,
    isExportingEmployees: isLoading,
  };
};

export const useEditEmployeeName = () => {
  const [mutate, status] = useMutation(editEmployeeName, {
    onSuccess: () => {
      openNotification(
        "Employee name successfully changed",
        NOTIFICATIONS.info
      );
      queryCache.invalidateQueries(EMPLOYEE_QUERY_KEYS.getEmployees());
    },
    onError: () => {
      openNotification("Failed to change employee name", NOTIFICATIONS.error);
    },
  });

  return {
    editEmployeeName: mutate,
    ...status,
  };
};

export const useGetEmployees = (params?: GetEmployeesParams) => {
  const { data, isLoading, refetch, isFetching } = useQuery(
    EMPLOYEE_QUERY_KEYS.getEmployees(),
    () => getEmployees(params),
    {
      onError: () => {
        openNotification("Failed to load employees", NOTIFICATIONS.error);
      },
    }
  );

  return {
    employees: data,
    isEmployeesLoading: isLoading,
    isEmployeesFetching: isFetching,
    refetchEmployees: refetch,
  };
};

export const useGetEmployee = (employeeId: number) => {
  const { data, isLoading, refetch, isFetching } = useQuery(
    EMPLOYEE_QUERY_KEYS.getEmployee(employeeId),
    () => getEmployee(employeeId),
    {
      onError: () => {
        openNotification("Failed to load employee", NOTIFICATIONS.error);
      },
    }
  );

  return {
    employee: data,
    isEmployeeLoading: isLoading,
    isEmployeeFetching: isFetching,
    refetchEmployee: refetch,
  };
};

export const useGetEmployeePortfoliosProperties = (employeeId: number) => {
  const { data, isLoading, refetch, isFetching } = useQuery(
    EMPLOYEE_QUERY_KEYS.getEmployeePortfoliosProperties(employeeId),
    () => getEmployeePortfoliosProperties(employeeId),
    {
      enabled: !!employeeId,
      onError: () =>
        openNotification(
          "Failed to load employee portfolio properties",
          NOTIFICATIONS.error
        ),
    }
  );

  return {
    portfolios: data,
    isPortfoliosLoading: isLoading,
    isPortfoliosFetching: isFetching,
    refetchPortfolios: refetch,
  };
};

export const useDeactivateEmployee = (employeeId: number, name: string) => {
  const [mutate, { isLoading }] = useMutation(
    () => deactivateEmployee(employeeId),
    {
      onSuccess: () => {
        openNotification(
          `Successfully deactivated employee ${name}`,
          NOTIFICATIONS.info
        );
        queryCache.invalidateQueries(EMPLOYEE_QUERY_KEYS.getEmployees());
        queryCache.invalidateQueries(
          EMPLOYEE_QUERY_KEYS.getEmployee(employeeId)
        );
      },
      onError: () =>
        openNotification(
          `Failed to deactivate employee ${name}`,
          NOTIFICATIONS.error
        ),
    }
  );

  return {
    deactivateEmployee: mutate,
    isDeactivateEmployeeLoading: isLoading,
  };
};

export const useUnassignEmployeePortfoliosProperties = ({
  employeeId,
  values,
}: UnassignEmployeeFromPortfolioProps) => {
  const [mutate, { isLoading }] = useMutation(
    () => unassignEmployeePortfoliosProperties(employeeId, values),
    {
      onSuccess: () =>
        queryCache.invalidateQueries(
          EMPLOYEE_QUERY_KEYS.getEmployeePortfoliosProperties(employeeId)
        ),
    }
  );

  return {
    unassignEmployeePortfoliosProperties: mutate,
    isUnassignEmployeePortfoliosPropertiesLoading: isLoading,
  };
};

export const useAssignEmployeePortfoliosProperties = (employeeId: number) => {
  const [mutate, { isLoading }] = useMutation(
    (values: AssignEmployeePortfoliosProperties) =>
      assignEmployeePortfoliosProperties(employeeId, values),
    {
      onSuccess: () => {
        queryCache.invalidateQueries(
          EMPLOYEE_QUERY_KEYS.getEmployeePortfoliosProperties(employeeId)
        );
        queryCache.invalidateQueries(EMPLOYEE_QUERY_KEYS.getEmployees());
        queryCache.invalidateQueries(
          EMPLOYEE_QUERY_KEYS.getEmployee(employeeId)
        );
      },
      onError: () =>
        openNotification(
          "Failed to assign the employee to the selected properties",
          NOTIFICATIONS.error
        ),
    }
  );

  return {
    assignEmployeePortfoliosProperties: mutate,
    isAssignEmployeePortfoliosPropertiesLoading: isLoading,
  };
};
