import styled from "@emotion/styled";

import BaseCard from "shared/components/Card";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

const Card = styled(BaseCard)`
  width: 100% !important;
  height: 130px;
  margin-bottom: 16px;
  background-color: ${COLORS.dashboard.headerBackground};
  padding: 20px 32px 16px 32px !important;

  .card-header * {
    color: ${COLORS.lightGray2};
  }

  .card-value {
    font-size: 44px;
    position: absolute;
    right: 16px;
    bottom: 0;
    color: ${COLORS.violetTheme.lightViolet};
  }

  ${MEDIA_QUERIES.w.ltLg} {
    padding: 16px 32px 16px 16px !important;

    .card-value {
      font-size: 32px;
    }
  }
`;

export { Card };
