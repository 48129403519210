import styled from "@emotion/styled";

import { Modal } from "shared/components/Modal";

const ModalBody = styled(Modal.Body)({
  paddingTop: 0,
  " .searchable-list-container": {
    marginTop: 25,
  },
});

export { ModalBody };
