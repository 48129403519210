export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const FILE_STATUS = Object.freeze({
  uploading: "uploading",
  done: "done",
  error: "error",
});

export const UPLOAD_ALLOWED_FILE_TYPES = [".png", ".jpg", ".jpeg"];
