import React, { ReactNode } from "react";

import { RegularText } from "../Typography";

import { StyledChip } from "./styled";

export interface ChipProps {
  icon?: ReactNode;
  label: string;
  color?: "dark-grey" | "green" | "orange" | "light-grey";
  className?: string;
}

const Chip = ({ icon, label, color = "light-grey", className }: ChipProps) => {
  return (
    <StyledChip color={color} className={className}>
      {icon}
      <RegularText>{label}</RegularText>
    </StyledChip>
  );
};

export default Chip;
