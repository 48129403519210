import React from "react";

import PropTypes from "prop-types";

import { dealDetailsPropType } from "manager/utils/propTypes";

import DealDetails from "./DealDetails";

const DealDetailsContainer = ({ deal, columnWidth, ...props }) => {
  return <DealDetails deal={deal} columnWidth={columnWidth} {...props} />;
};

DealDetailsContainer.propTypes = {
  deal: dealDetailsPropType.isRequired,
  columnWidth: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ).isRequired,
};

export default DealDetailsContainer;
