import React from "react";

import { Helmet } from "react-helmet";

import Container from "shared/components/Container";
import { BackLink } from "shared/components/Links";
import PageTitle from "shared/components/PageTitle";

import { SettingsPageProps } from "./interfaces";
import { StyledPageTitle, PageTitleContainer } from "./styled";

const SettingsPage = ({
  title = "",
  titleNode,
  subtitle = "",
  backLink,
  children,
}: SettingsPageProps) => (
  <>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    <Container noPaddingBottom>
      <BackLink.Container>
        <BackLink {...backLink} />
      </BackLink.Container>
      <PageTitleContainer>
        {titleNode || <StyledPageTitle>{title}</StyledPageTitle>}
        {subtitle && <PageTitle.Subtitle>{subtitle}</PageTitle.Subtitle>}
      </PageTitleContainer>
    </Container>
    <Container noPaddingTop noPaddingBottom noMobilePaddingX expand>
      {children}
    </Container>
  </>
);

export default SettingsPage;
