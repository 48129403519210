export const getPropertyTypes = (state) =>
  state?.requests?.shared?.types?.getPropertyTypes;
export const getPropertyTypesRequested = (state) =>
  getPropertyTypes(state)?.requested;
export const getPropertyTypesFetching = (state) =>
  getPropertyTypes(state)?.fetching;
export const getPropertyTypesError = (state) => getPropertyTypes(state)?.error;
export const propertyTypes = (state) => state?.types?.property;
export const propertyTypesList = (state) =>
  propertyTypes(state) ? Object.values(propertyTypes(state)) : [];

export const applicantTypes = (state) => state?.types?.applicant;

export const getRequiredAttachmentTypes = (state) =>
  state?.requests?.shared?.types?.getRequiredAttachmentTypes;
export const getRequiredAttachmentTypesRequested = (state) =>
  getRequiredAttachmentTypes(state)?.requested;
export const getRequiredAttachmentTypesFetching = (state) =>
  getRequiredAttachmentTypes(state)?.fetching;
export const getRequiredAttachmentTypesError = (state) =>
  getRequiredAttachmentTypes(state)?.error;
export const requiredAttachmentTypes = (state) =>
  state?.types?.requiredAttachment;
export const requiredAttachmentTypesList = (state) =>
  requiredAttachmentTypes(state)
    ? Object.values(requiredAttachmentTypes(state))
    : [];

export const getApplicationStatuses = (state) =>
  state?.requests?.shared?.types?.getApplicationStatuses;
export const getApplicationStatusesRequested = (state) =>
  getApplicationStatuses(state)?.requested;
export const getApplicationStatusesFetching = (state) =>
  getApplicationStatuses(state)?.fetching;
export const getApplicationStatusesError = (state) =>
  getApplicationStatuses(state)?.error;
export const applicationStatuses = (state) => state?.types?.applicationStatus;
export const applicationStatusesList = (state) =>
  applicationStatuses(state) ? Object.values(applicationStatuses(state)) : [];

export const getDealStatuses = (state) =>
  state?.requests?.shared?.types?.getDealStatuses;
export const getDealStatusesRequested = (state) =>
  getDealStatuses(state)?.requested;
export const getDealStatusesFetching = (state) =>
  getDealStatuses(state)?.fetching;
export const getDealStatusesError = (state) => getDealStatuses(state)?.error;

export const dealStatuses = (state) => state?.types?.dealStatus;
export const dealStatusesList = (state) =>
  dealStatuses(state) ? Object.values(dealStatuses(state)) : [];

export const getUnitStatuses = (state) =>
  state?.requests?.shared?.types?.getUnitStatuses;
export const getUnitStatusesRequested = (state) =>
  getUnitStatuses(state)?.requested;
export const getUnitStatusesFetching = (state) =>
  getUnitStatuses(state)?.fetching;
export const getUnitStatusesError = (state) => getUnitStatuses(state)?.error;
export const unitStatuses = (state) => state?.types?.unitStatus;
export const unitStatusesList = (state) =>
  unitStatuses(state) ? Object.values(unitStatuses(state)) : [];
