import React from "react";

import styled from "@emotion/styled";

import { COLORS } from "shared/config/constants";
import { AlignedCenterFlexPartial } from "shared/styles/commonStyles";

// eslint-disable-next-line react/prop-types
const RawPanel = ({ isEnabled, children, className }) => (
  <div className={className}>{isEnabled && children}</div>
);

const ConfigurationPanel = styled(RawPanel)({
  marginTop: "32px",
});

const ConfigurationSection = styled.div({
  width: "100%",
  ".configuration-section-header": {
    ...AlignedCenterFlexPartial,
    backgroundColor: COLORS.lightGray2,
    padding: "14px 24px",
    fontWeight: 500,
  },
  ".configuration-section-body": {
    ...AlignedCenterFlexPartial,
    backgroundColor: COLORS.white,
    width: "100%",
    padding: "32px 24px",
    ".formula-input": {
      marginRight: "24px",
      minWidth: "220px",
    },
    ".ant-radio-wrapper:last-of-type": {
      paddingBottom: 0,
    },
  },
});

export { ConfigurationPanel, ConfigurationSection };
