import axios from "axios";

import ROUTES from "manager/config/apiRoutes";
import { API_URL } from "shared/lib/api/setupApi";
import { transformRoute } from "shared/utils/routing";

const api = {
  getMagicLinksAgents: async ({ nameOrEmail, role, ordering }) => {
    return axios
      .get(`${API_URL}${ROUTES.magicLinksAgents}`, {
        params: {
          name_or_email: nameOrEmail,
          role,
          ordering,
        },
      })
      .then(({ data }) => data);
  },

  getCompanyMagicLink: async () => {
    return axios
      .get(`${API_URL}${ROUTES.companyMagicLink}`)
      .then(({ data }) => data);
  },

  getMagicLinkQRCode: async ({ magicLink }) => {
    return axios
      .get(`${API_URL}${ROUTES.magicLinkQRCode}`, {
        params: {
          magic_link: magicLink,
        },
      })
      .then(({ data }) => data);
  },

  getMagicLinksProperties: async ({ nameOrAddress, portfolioId, ordering }) => {
    return axios
      .get(`${API_URL}${ROUTES.magicLinkProperties}`, {
        params: {
          name_or_address: nameOrAddress,
          portfolio_id: portfolioId,
          ordering,
        },
      })
      .then(({ data }) => data);
  },

  togglePropertyBranding: (propertyId) =>
    axios.patch(
      `${API_URL}${transformRoute(ROUTES.magicLinkToggleBranding, {
        propertyId,
      })}`
    ),

  magicLinksExportAgents: async ({ employees, outputs }) => {
    return axios
      .post(`${API_URL}${ROUTES.magicLinksExportAgents}`, {
        employees,
        outputs,
      })
      .then(({ data }) => data);
  },

  magicLinksExportProperties: async ({ exports, outputs }) => {
    return axios.post(`${API_URL}${ROUTES.magicLinksExportProperties}`, {
      exports,
      outputs,
    });
  },

  getDownloads: async () => {
    return axios
      .get(`${API_URL}${ROUTES.downloads}`, {
        params: {},
      })
      .then(({ data }) => data);
  },
};

export default api;
