import React from "react";

import { DropdownButton } from "shared/components/Button";

import { ExportInventoryButtonProps } from "./interfaces";
import { prepareUnitsSearchParams } from "./utils";

const ExportInventoryButton = ({
  exportUnits,
  unitsSearchParams,
  disabled = false,
  ...props
}: ExportInventoryButtonProps) => (
  <DropdownButton
    type="secondary"
    light
    disabled={disabled}
    {...props}
    items={[
      {
        key: "all",
        label: "All Inventory",
        onClick: exportUnits,
      },
      {
        key: "filtered",
        label: "Filtered Inventory",
        onClick: () => exportUnits(prepareUnitsSearchParams(unitsSearchParams)),
      },
    ]}
  >
    Export Inventory
  </DropdownButton>
);

export default ExportInventoryButton;
