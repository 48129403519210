
import { Col } from "antd";

import DetectableOverflowTooltip from "shared/components/DetectableOverflowTooltip";
import Spacer from "shared/components/Spacer";
import Tile from "shared/components/Tile";
import { RegularText } from "shared/components/Typography";
import { formatDate } from "shared/utils/ui";

import { UnitListingRowProps } from "../interfaces";

import { printDollarsFromCents } from "shared/utils/dollar-print";
import { GreyRow } from "./styled";

const UnitListingRow = ({ unitListing }: UnitListingRowProps) => {
  const { unitAvailableOn, rent, securityDeposit, daysOnMarket, note } =
    unitListing;

  return (
    <GreyRow type="flex" gutter={24}>
      <Col xs={12} md={5} lg={4} xl={3}>
        <Tile.Label>Date Available</Tile.Label>
        <span data-testid="unitAvailableOn">{formatDate(unitAvailableOn)}</span>
      </Col>
      <Col xs={0} md={1}>
        <Tile.Divider />
      </Col>
      <Col xs={12} md={4} xl={3}>
        <Tile.Label>Rent Price</Tile.Label>
        <strong data-testid="rent">{printDollarsFromCents(rent)}</strong>
      </Col>
      <Col xs={0} md={1}>
        <Tile.Divider />
      </Col>
      <Col xs={12} md={4} xl={3}>
        <Spacer hideGteMd />
        <Tile.Label>Security Deposit</Tile.Label>
        <strong data-testid="security-deposit">
          {printDollarsFromCents(securityDeposit)}
        </strong>
      </Col>
      <Col xs={0} md={1}>
        <Tile.Divider />
      </Col>
      <Col xs={12} md={4} xl={3}>
        <Spacer hideGteMd />
        <Tile.Label>Days on Market</Tile.Label>
        <strong data-testid="days-on-market">{daysOnMarket}</strong>
      </Col>
      <Col xs={0} md={1}>
        <Tile.Divider />
      </Col>
      <Col xs={12} md={4} xl={6}>
        <Spacer hideGteMd />
        <Tile.Label>Note</Tile.Label>
        <DetectableOverflowTooltip
          title={note}
          theme="light"
          placement="topLeft"
        >
          <RegularText strong data-testid="listing-note">
            {note || "N/A"}
          </RegularText>
        </DetectableOverflowTooltip>
      </Col>
    </GreyRow>
  );
};

export default UnitListingRow;
