import React from "react";

export interface RequiredIndicatorProps {
  required?: boolean;
}

const RequiredIndicator = ({ required = false }: RequiredIndicatorProps) => (
  <>{required ? "*" : ""}</>
);

export default RequiredIndicator;
