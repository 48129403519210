import styled from "@emotion/styled";

import { COLORS } from "shared/config/constants";

const Container = styled.div`
  padding: 24px;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${COLORS.borders};
  }
`;

const Field = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
`;

export { Container, Field };
