import React from "react";

import Icon from "shared/components/Icon";

import { SIZES } from "./constants";
import { BackLink as StyledBackLink, TextContainer, Container } from "./styled";

export interface IBackLink {
  to?: string;
  children: React.ReactNode;
  size?: string;
  nowrap?: boolean;
  [key: string]: any;
}

const BackLink = ({ children, size = SIZES.default, ...props }: IBackLink) => (
  <StyledBackLink size={size} {...props}>
    {size === SIZES.default && (
      <Icon.ArrowLeftIcon data-testid="default-icon" />
    )}
    {size === SIZES.large && (
      <Icon.ArrowLeftBoldIcon
        className="back-link-icon"
        data-testid="large-icon"
      />
    )}
    <TextContainer size={size}>{children}</TextContainer>
  </StyledBackLink>
);

BackLink.SIZES = SIZES;
BackLink.Container = Container;

export default BackLink;
