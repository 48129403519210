import React, { useMemo } from "react";

import { useFormikContext } from "formik";
import noop from "lodash/noop";
import PropTypes from "prop-types";

import ApplicationSetupHeader from "manager/components/DealSetupSettings/Header";
import FlexContainer from "shared/components/FlexContainer";
import { PopoverContent } from "shared/components/Form/Input/styled";
import Icon from "shared/components/Icon";
import { ActionLink } from "shared/components/Links";
import { Popover } from "shared/components/Popover";
import Settings from "shared/components/Settings";

import { linkedSourcesPropTypes } from "./sources.config";
import {
  SourcesContainer,
  SourceName,
  SourceRow,
  SourceActions,
} from "./styled";

const Sources = ({
  title,
  subTitle,
  createNewSource,
  editSource,
  deleteSource,
  linkedData,
}) => {
  const { values } = useFormikContext();

  const linkedPopover = linkedData.isLinked && (
    <Popover
      className="popover"
      withoutOverlay
      content={<PopoverContent>{linkedData.popoverText}</PopoverContent>}
    >
      {linkedData.popoverIcon}
    </Popover>
  );

  const items = useMemo(
    () =>
      (values?.sources || []).map((source, idx) => ({
        key: source.id,
        component: (
          <SourceRow data-testid="source-row">
            <SourceName>{source.name}</SourceName>
            <SourceActions>
              <ActionLink
                data-testid="edit-action"
                className="edit-action"
                onClick={source.isLinked ? noop : () => editSource(source, idx)}
                disabled={source.isLinked}
              >
                EDIT
              </ActionLink>
              <Icon.DeleteSmallIcon
                data-testid="delete-action"
                className="delete-action"
                onClick={() => deleteSource(source, idx)}
              />
            </SourceActions>
          </SourceRow>
        ),
      })),
    [values?.sources, linkedData?.isLinked]
  );

  return (
    <SourcesContainer>
      <Settings
        header={
          <ApplicationSetupHeader
            title={
              <div className="sources-title-action">
                <FlexContainer alignItems="center">
                  {title} {linkedData.isLinked && linkedPopover}
                </FlexContainer>
                <Popover
                  withoutOverlay
                  content={
                    <PopoverContent>
                      {linkedData.newSourcePopoverText}
                    </PopoverContent>
                  }
                  disabled={!linkedData.isLinked}
                >
                  <div>
                    <ActionLink
                      data-testid="create-action"
                      onClick={linkedData.isLinked ? noop : createNewSource}
                      disabled={linkedData.isLinked}
                    >
                      +NEW SOURCE
                    </ActionLink>
                  </div>
                </Popover>
              </div>
            }
            subTitle={subTitle}
          />
        }
        nodes={items}
      />
    </SourcesContainer>
  );
};

Sources.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  createNewSource: PropTypes.func.isRequired,
  editSource: PropTypes.func.isRequired,
  deleteSource: PropTypes.func.isRequired,
  linkedData: linkedSourcesPropTypes.isRequired,
};

export default Sources;
