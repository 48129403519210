import * as Yup from "yup";

import {
  validEmail,
  validFirstName,
  validLastName,
  validPhone,
} from "shared/forms/validation/fieldValidators";

import {
  LEADS_OPTIONS,
  OptionType,
} from "./components/SelectPreferrence/interfaces";

export interface UserShort {
  email: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  prescreeningType?: OptionType;
}

export const ValidationSchema = Yup.object().shape({
  email: validEmail.required("Email is required."),
  firstName: validFirstName.required("First name is required."),
  lastName: validLastName.required("Last name is required."),
  phoneNumber: validPhone.required("Phone number is required."),
  termsOfService: Yup.boolean()
    .required()
    .oneOf([true], "The terms of service must be accepted."),
});

export const getInitialValues = (user: UserShort) => {
  const { email, firstName, lastName, phoneNumber } = user;

  return {
    email,
    firstName,
    lastName,
    phoneNumber,
    termsOfService: false,
  };
};
export const getInitialValuesVettGuestsEnabled = (user: UserShort) => {
  const { email, firstName, lastName, phoneNumber } = user;

  return {
    email,
    firstName,
    lastName,
    phoneNumber,
    termsOfService: true,
    prescreeningType: LEADS_OPTIONS.PROSPECT,
  };
};
