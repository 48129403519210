import React from 'react'
import _ from 'lodash'
import { ServiceCard } from 'shared/components/vega/Service'

const RequiredDocuments = ({ documents, card = false }) => {
  const required = (_.chain(documents) as any).filter('isRequired').size().value()
  const attached = (_.chain(documents) as any).filter('isRequired').sumBy('attachmentLength').value()

  return (
    <ServiceCard
      label='required attachments'
      value={`${attached} / ${required}`}
      status={attached >= required}
    />
  )
}

export default RequiredDocuments
