import React, { useState } from "react";

import { Typography } from "antd";

import { MODALS } from "renter/config/constants";
import { TextArea } from "shared/components/Form/TextArea";
import Icon from "shared/components/Icon";
import { Modal } from "shared/components/Modal";
import { getFullnameData } from "shared/utils/applicant";

import { Applicant, ContactManagerModalProps } from "./interfaces";
import {
  CheckboxContainer,
  LoadingContainer,
  Manager,
  StyledCheckbox,
  StyledCheckboxGroup,
} from "./styled";

const { Text } = Typography;

const ContactManagerModal = ({
  loading,
  isSubmitting,
  users,
  submit,
}: ContactManagerModalProps) => {
  const [additionalMessage, setAdditionalMessage] = useState<string>();
  const [checkAll, setCheckAll] = useState(false);
  const [applications, setApplications] = useState([]);
  const [otherOccupants, setOtherOccupants] = useState([]);

  const buildUserList = (list: Applicant[]) =>
    (list || []).map((user) => {
      const { fullNameOrNA } = getFullnameData(user);
      return {
        label: fullNameOrNA,
        value: user.id,
      };
    });

  return (
    <Modal
      title="Contact manager"
      subtitle={
        users?.employee ? (
          <>
            Manager:{" "}
            <Manager>
              {users?.employee?.firstName} {users?.employee?.lastName}
            </Manager>
          </>
        ) : undefined
      }
      id={MODALS.contactManager}
      width="auto"
      style={{ maxWidth: 600 }}
      submitButtonDisabled={
        applications.length === 0 && otherOccupants.length === 0
      }
      submit={() =>
        submit({
          applications: users?.applications?.filter((application) =>
            applications.includes(application.id)
          ),
          otherOccupants: users?.otherOccupants?.filter((occupant) =>
            otherOccupants.includes(occupant.id)
          ),
          additionalMessage,
        })
      }
      submitting={isSubmitting}
      closeOnSubmit={false}
    >
      <Modal.Body>
        <Text data-testid="message">
          Indicate members who do not belong to your application group. <br /> A
          manager will answer you by email or by phone.
        </Text>
        {loading ? (
          <LoadingContainer>
            <Icon.LoadingSpinnerGreenLargeIcon data-testid="spinner" />
          </LoadingContainer>
        ) : (
          <>
            <CheckboxContainer>
              <StyledCheckbox
                id="checkbox-1"
                name="checkbox-1"
                label="All"
                data-testid="all-options"
                value={checkAll}
                onChange={() => {
                  setApplications(
                    checkAll ? [] : users?.applications?.map((user) => user.id)
                  );
                  setOtherOccupants(
                    checkAll
                      ? []
                      : users?.otherOccupants?.map((user) => user.id)
                  );
                  setCheckAll(!checkAll);
                }}
              />
              <StyledCheckboxGroup
                value={applications}
                name="checkbox-group"
                options={buildUserList(users?.applications)}
                onChange={(e) => setApplications(e)}
              />
              <StyledCheckboxGroup
                value={otherOccupants}
                name="checkbox-group"
                options={buildUserList(users?.otherOccupants)}
                onChange={(e) => setOtherOccupants(e)}
              />
            </CheckboxContainer>
            <TextArea
              name=""
              value={additionalMessage}
              id="additionalMessage"
              onChange={({ target: { value } }) => setAdditionalMessage(value)}
              placeholder="Message text (optional)"
            />
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ContactManagerModal;
