import isEmpty from "lodash/isEmpty";

import { isRenterApp } from "shared/utils/auth";

import usePermissions from "./usePermissions";

/**
 * Hook for returning permissions value for accessing sensitive data on deals.
 * The final permission value depends on the provided deal and the profile of the current user.
 *
 * @param deal the deal where the check is applied
 * @returns {{canAccessSensitiveDealData: boolean}} true if the user can access sensitive deal info, false otherwise
 * @returns {{canAccessCountyCriminalRecords: boolean}} true if the user is manager and requesting county criminal search is enabled on the deal, false otherwise
 */
const useDealSensitiveInfo = (deal) => {
  const { canAccessSensitiveDealDataInLumen, permissionsLoaded } =
    usePermissions();
  const isRenter = isRenterApp();

  const canAccessSensitiveDealData = !!(
    isRenter ||
    (deal && (!deal.lumenEnabled || canAccessSensitiveDealDataInLumen))
  );

  const isSensitiveDealDataPermissionLoaded =
    isRenter || (!isEmpty(deal) && permissionsLoaded);

  const canAccessCountyCriminalRecords =
    !isRenter && deal?.canRunCountyCriminalSearch;

  const canViewOcrResults = deal?.canViewOcrResults;
  const canViewRestrictedAttachments = deal?.canViewRestrictedAttachments;

  return {
    canAccessSensitiveDealData,
    canAccessCountyCriminalRecords,
    isSensitiveDealDataPermissionLoaded,
    canViewOcrResults,
    canViewRestrictedAttachments,
  };
};

export default useDealSensitiveInfo;
