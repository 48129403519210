import React from "react";

import PropTypes from "prop-types";

import { Button } from "shared/components/Button";
import { Modal } from "shared/components/Modal";
import { MediumTitle, RegularText } from "shared/components/Typography";
import useModal from "shared/hooks/useModal";

import { CourtesyMessageContent, StyledModalBody } from "./styled";

const CourtesyMessageModal = ({ message }) => {
  const { closeActiveModalDialog } = useModal();

  return (
    <Modal showSubmitButton={false} showCancelLink={false} centered fullScreen>
      <StyledModalBody noPaddingTop noPaddingBottom>
        <CourtesyMessageContent
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <MediumTitle className="courtesy-message-title">
            So much to display!
          </MediumTitle>
          <div className="courtesy-message-image" />
          <RegularText className="courtesy-message-text">{message}</RegularText>
          <Button type="primary" onClick={() => closeActiveModalDialog()}>
            GOT IT
          </Button>
        </CourtesyMessageContent>
      </StyledModalBody>
    </Modal>
  );
};

CourtesyMessageModal.propTypes = {
  message: PropTypes.string.isRequired,
};

export default CourtesyMessageModal;
