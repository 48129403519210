import React from "react";

import { Row, Col } from "antd";
import { Helmet } from "react-helmet";

import Container from "shared/components/Container";
import Icon from "shared/components/Icon";
import PageTitle from "shared/components/PageTitle";
import Skeleton from "shared/components/Skeleton";
import { VeroPlusMarketplaceLogo } from "shared/images";

import { MarketplaceProps } from "./interfaces";
import MarketplaceTile from "./MarketplaceTile";
import { MarketplaceBanner, MarketplaceBackground } from "./styled";

const title = "Marketplace";

const Marketplace = ({ partners, isLoading }: MarketplaceProps) => (
  <div>
    <MarketplaceBackground />
    <Helmet>
      <title>{title}</title>
    </Helmet>

    <Container noPaddingBottom>
      <PageTitle.Container>
        <PageTitle>
          <PageTitle.Icon>
            <img src={VeroPlusMarketplaceLogo} alt="vero" />
          </PageTitle.Icon>
          {title}
        </PageTitle>
      </PageTitle.Container>
    </Container>
    <Container noMobilePaddingX noPaddingTop maxWidth="xl">
      <MarketplaceBanner>
        <Icon.IntegrationTextIcon className="banner-icon" />
        <div>
          We understand that moving is quite a bit of work! For this reason, we
          created Marketplace. Here you will find curated partners that are
          dedicated simplifying and assisting during the move in process.
        </div>
      </MarketplaceBanner>
      <Skeleton loading={isLoading}>
        <Row type="flex">
          {partners?.map((partner) => (
            <Col key={partner.id}>
              <MarketplaceTile partner={partner} />
            </Col>
          ))}
        </Row>
      </Skeleton>
    </Container>
  </div>
);

export default Marketplace;
