import React from "react";

import isEmpty from "lodash/isEmpty";

import { useGetManagerProfile } from "manager/hooks/api";
import { usePermissions } from "shared/hooks";

import Settings from "./Settings";

const SettingsContainer = () => {
  const {
    company,
    isLumenAdmin,
    isOpenApiEnabled,
    isPrescreeningEnabled,
    permissionsLoaded,
  } = usePermissions();

  const { data: profile } = useGetManagerProfile({
    enabled: !isEmpty(company),
  });

  return (
    <Settings
      isDownloadCenterVisible={profile?.isDownloadCenterVisible}
      isLumenAdmin={isLumenAdmin}
      isOpenApiEnabled={isOpenApiEnabled}
      isPrescreeningEnabled={isPrescreeningEnabled}
      loading={!permissionsLoaded}
    />
  );
};

export default SettingsContainer;
