import styled from "@emotion/styled";
import { Divider } from "antd";
import { Dayjs } from "dayjs";
import React, { useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import { getMonthLabel } from "renter/pages/LeaseRentOptions/utils";
import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import Tooltip from "shared/components/Tooltip";
import Well from "shared/components/Well";
import { BREAKPOINT_RESOLUTIONS, colors } from "shared/config/constants";
import { printDollarsFromCents } from "shared/utils/dollar-print";
import { formatDate, getValueOrNA } from "shared/utils/ui";
import { DealUnitHeaderItemsContent, UnitPopoverContent } from "./styled";
import { DealHeaderDeal } from "./types";
import { getApplicationSource, getSecurityDepositBalance } from "./utils";

type UnitPopoverProps = {
  deal: Pick<
    DealHeaderDeal,
    | "agent"
    | "unit"
    | "remainingSecurityDeposit"
    | "isSecurityDepositDeducted"
    | "isMagicDeal"
    | "isOpenApiDeal"
  >;
};

export const UnitPopover = ({ deal }: UnitPopoverProps) => {
  const isSmallDesktopScreen = useMediaQuery({
    maxWidth: BREAKPOINT_RESOLUTIONS.xl,
  });

  const {
    agent,
    unit,
    remainingSecurityDeposit,
    isOpenApiDeal,
    isMagicDeal,
    isSecurityDepositDeducted,
  } = deal ?? {};

  const hasUnit = !!unit;
  const unitAvailableOn = unit?.availableOn;
  const items = [];

  if (isSmallDesktopScreen) {
    if (unitAvailableOn) {
      const formattedDate = formatDate(unitAvailableOn);
      items.push(`Available: ${formattedDate}`);
    }
    const agentName = agent
      ? `${agent?.firstName ?? ""} ${agent?.lastName ?? ""}`.trim()
      : "No agent";
    items.push(agentName);
  }

  if (hasUnit) {
    items.push(
      ...[
        `Type: ${getValueOrNA(unit?.unitType)}`,
        `Bedrooms: ${getValueOrNA(unit?.bedrooms)}`,
        `Bathrooms: ${getValueOrNA(unit?.bathrooms)}`,
        `Sq. Feet: ${getValueOrNA(unit?.squareFeet)}`,
        `Security Dep.: ${getSecurityDepositBalance(
          remainingSecurityDeposit,
          isSecurityDepositDeducted
        )}`,
      ]
    );
  }

  const source = getApplicationSource({ isMagicDeal, isOpenApiDeal });
  const hasSource = !!source;
  const hasItems = !!(items && items.length);

  return (
    <UnitPopoverContent>
      {hasItems && (
        <>
          <p className="popover-title">UNIT DETAILS</p>
          <Well className="popover-items">
            {items.map((item) => (
              <p key={item}>{item}</p>
            ))}
          </Well>
        </>
      )}
      {hasSource && (
        <>
          <p className="popover-title application-title">APPLICATION DETAILS</p>
          <Well className="popover-items">
            <p>Source: {source}</p>
          </Well>
        </>
      )}
    </UnitPopoverContent>
  );
};

const Divide = () => (
  <Divider
    type="vertical"
    style={{
      backgroundColor: colors.secondary.base,
      margin: "0 16px",
    }}
  />
);

const DealUnitItemsMobileStyled = styled(FlexContainer)(() => ({
  flexDirection: "column",
  ".deal-unit-header-item": {
    // ...TextEllipsis,
    backgroundColor: "rgba(0,0,0,0.20)",
    border: "none",
    padding: "10px 24px",
    maxWidth: "none",
    textAlign: "left",
    margin: "0 -24px",
  },
}));

export type DealUnitItemsMobileProps = {
  formattedSecurityDeposit: string;
  availableDate?: Dayjs;
  moveInDate?: Dayjs;
  agentName: string;
  unit: DealHeaderDeal["unit"];
  hasListing?: boolean;
  source: string;
  canEditRent?: boolean;
  rent: number;
  editRent?: () => void;
  term?: number;
  leaseStartDate?: Dayjs;
};

/* eslint-disable react/prop-types */
export const DealUnitItemsMobile = ({
  formattedSecurityDeposit,
  availableDate,
  moveInDate,
  agentName,
  unit,
  hasListing,
  source,
  canEditRent,
  rent,
  term,
  leaseStartDate,
  editRent,
}: DealUnitItemsMobileProps) => {
  const bathrooms = getValueOrNA(unit?.bathrooms);
  const bedrooms = getValueOrNA(unit?.bedrooms);
  const squareFeet = getValueOrNA(unit?.squareFeet);

  return (
    <DealUnitItemsMobileStyled>
      {Boolean(hasListing) && (
        <>
          <span className="deal-unit-header-item">
            Rent:{" "}
            <span> {printDollarsFromCents(rent, { withCents: true })}</span>
            {!!canEditRent && (
              <Icon.EditIcon className="edit-rent-icon" onClick={editRent} />
            )}
          </span>
          <span className="deal-unit-header-item">
            Lease Start: <span>{leaseStartDate?.format("MMM. D, YYYY")}</span>
          </span>
          {term && (
            <span className="deal-unit-header-item">
              Term: <span>{getMonthLabel(term)}</span>
            </span>
          )}
          <span className="deal-unit-header-item">
            Security Deposit: <span>{formattedSecurityDeposit}</span>
          </span>
          <span className="deal-unit-header-item">
            Available: <span>{availableDate?.format("MMM. D, YYYY")}</span>
          </span>
          {!!moveInDate && (
            <span className="deal-unit-header-item">
              Move In: <span>{moveInDate?.format("MMM. D, YYYY")}</span>
            </span>
          )}
        </>
      )}
      {!hasListing && <span className="deal-unit-header-item">No listing</span>}

      <span className="deal-unit-header-item">
        {agentName && (
          <>
            Agent: <span title={agentName}>{agentName}</span>
          </>
        )}
        {!agentName && <span>No agent</span>}
      </span>

      {Boolean(unit) && (
        <>
          <span className="deal-unit-header-item">
            Type: <span>{unit?.unitType}</span>
          </span>

          <div className="deal-unit-header-item">
            <span>{bathrooms} bath.</span>
            <Divide />
            <span>{bedrooms} bed.</span>
            <Divide />
            <span>{squareFeet} sq.feet</span>
          </div>
        </>
      )}

      {Boolean(source) && (
        <span className="deal-unit-header-item">
          Application Source: <span>{source}</span>
        </span>
      )}
    </DealUnitItemsMobileStyled>
  );
};

export type DealItemsDesktopProps = {
  availableDate?: Dayjs;
  moveInDate?: Dayjs;
  agentName?: string;
  canEditRent?: boolean;
  rent?: number;
  hasListing?: boolean;
  editRent?: () => void;
  term?: number;
  leaseStartDate?: Dayjs;
};

const Item: React.FC<{}> = ({ children }) => (
  <div style={{ whiteSpace: "nowrap" }}>{children}</div>
);

export const DealUnitItemsDesktop = ({
  availableDate,
  moveInDate,
  agentName,
  canEditRent,
  rent,
  hasListing,
  term,
  leaseStartDate,
  editRent,
}: DealItemsDesktopProps) => {
  const groups = useMemo(() => {
    const components = [
      !hasListing && (
        <Item>
          <div>No listing</div>
        </Item>
      ),
      !!rent && (
        <Item>
          {printDollarsFromCents(rent, { withCents: true })}
          {canEditRent && (
            <Icon.EditIcon className="edit-rent-icon" onClick={editRent} />
          )}
        </Item>
      ),
      !!leaseStartDate && (
        <Item>
          Lease Start:{" "}
          <Tooltip placement="bottom">
            {leaseStartDate.format("MMM. D, YYYY")}
          </Tooltip>
        </Item>
      ),
      !!term && <Item>Term: {getMonthLabel(term)}</Item>,
      !!availableDate && (
        <Item>
          Available:{" "}
          <Tooltip placement="bottom">
            {availableDate.format("MMM. D, YYYY")}
          </Tooltip>
        </Item>
      ),
      !!moveInDate && (
        <Item>
          Move In:{" "}
          <Tooltip placement="bottom">
            {moveInDate.format("MMM. D, YYYY")}
          </Tooltip>
        </Item>
      ),
      <Item>
        {agentName && (
          <>
            Agent:{" "}
            <Tooltip placement="bottom" title={agentName}>
              {agentName}
            </Tooltip>
          </>
        )}
        {!agentName && <span>No agent</span>}
      </Item>,
    ].filter((e) => !!e);

    const groups = [];
    for (let i = components.length - 1; i >= 0; i -= 2) {
      groups.push([components[i - 1], components[i]].filter((e) => !!e));
    }
    groups.reverse();
    return groups;
  }, [
    availableDate,
    term,
    leaseStartDate,
    moveInDate,
    rent,
    agentName,
    hasListing,
  ]);

  return (
    <FlexContainer flexWrap="wrap" gap="3px">
      {groups.map((group, i) => (
        <FlexContainer alignItems="center" flexWrap="wrap">
          {group.map((g, j) => (
            <>
              {g}
              {!(i === groups.length - 1 && j === group.length - 1) && (
                <Divide />
              )}
            </>
          ))}
        </FlexContainer>
      ))}
    </FlexContainer>
  );
};

export type DealHeaderItemsProps = DealItemsDesktopProps &
  DealUnitItemsMobileProps & {
    expanded?: boolean;
    isMobile: boolean;
  };

export const DealUnitHeaderItems = ({
  isMobile,
  expanded,
  ...props
}: DealHeaderItemsProps) => {
  return (
    <>
      {/* <Expand on={expanded && isMobile}>
        <DealUnitItemsMobile {...props} />
      </Expand> */}
      {expanded && isMobile && <DealUnitItemsMobile {...props} />}
      {!isMobile && (
        <DealUnitHeaderItemsContent>
          <DealUnitItemsDesktop {...props} />
        </DealUnitHeaderItemsContent>
      )}
    </>
  );
};
