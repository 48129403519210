import React from "react";

import { useFormikContext } from "formik";

import { Button } from "shared/components/Button";
import FlexContainer from "shared/components/FlexContainer";
import { ActionLink } from "shared/components/Links";

interface PropertySettingsFooterProps {
  isPortfolioSettings: boolean;
  openCustomizeSettingsModal: () => void;
  openResetSettingsModal: () => void;
}

const PropertySettingsFooter = ({
  isPortfolioSettings,
  openCustomizeSettingsModal,
  openResetSettingsModal,
}: PropertySettingsFooterProps) => {
  const { dirty, isSubmitting } = useFormikContext();
  return isPortfolioSettings ? (
    /* @ts-ignore */
    <Button
      type="primary"
      htmlType="button"
      onClick={openCustomizeSettingsModal}
    >
      Update Settings
    </Button>
  ) : (
    <FlexContainer nodesMargin="0 0 0 36px" alignItems="center">
      <ActionLink onClick={openResetSettingsModal}>
        Reset to portfolio settings
      </ActionLink>
      {/* @ts-ignore */}
      <Button
        id="form-submit"
        type="primary"
        htmlType="submit"
        loading={isSubmitting}
        disabled={!dirty}
        data-testid="submit-button"
      >
        Save changes
      </Button>
    </FlexContainer>
  );
};

export default PropertySettingsFooter;
