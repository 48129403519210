import React, { Dispatch, SetStateAction } from "react";

import { useHistory } from "react-router-dom";

import { EditEmployeeModal } from "manager/components/Modal";
import ROUTES from "manager/config/routes";
import { Employee } from "manager/interfaces/api/employee";
import Icon from "shared/components/Icon";
import { UnderlineLink } from "shared/components/Links";
import Table from "shared/components/Table";
import useModal from "shared/hooks/useModal";
import { Pagination } from "shared/interfaces";

import {
  AssignEmployeeToPropertyParams,
  DeactivateEmployeeParams,
} from "../interface";

import { EmployeeStatus } from "./styled";

interface EmployeesTableProps {
  employees: Employee[];
  loading: boolean;
  assignEmployeeToProperty: (params: AssignEmployeeToPropertyParams) => void;
  deactivateEmployee: (params: DeactivateEmployeeParams) => void;
  hasInputValue: boolean;
  pagination: Pagination;
  setPagination: Dispatch<SetStateAction<Pagination>>;
  employeesCount: number;
}

const EmployeesTable = ({
  employees,
  loading,
  assignEmployeeToProperty,
  deactivateEmployee,
  hasInputValue,
  pagination,
  setPagination,
  employeesCount,
}: EmployeesTableProps) => {
  const history = useHistory();
  const { openModalDialog } = useModal();
  const { page, pageSize } = pagination;

  const onPaginationChange = (currentPage: number) => {
    const newPagination = { page: currentPage, pageSize };
    setPagination(newPagination);
  };

  return (
    <Table
      alt
      searching={hasInputValue}
      loading={loading}
      columns={[
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          sorter: (a, b) =>
            a.name.props.children.localeCompare(b.name.props.children),
        },
        {
          title: "Email",
          dataIndex: "email",
          key: "email",
          sorter: (a, b) =>
            a.email.props.children.props.children.localeCompare(
              b.email.props.children.props.children
            ),
        },
        {
          title: "Assigned to Properties",
          dataIndex: "propertiesAssigned",
          key: "propertiesAssigned",
          sorter: (a, b) =>
            a.propertiesAssigned.props.children -
            b.propertiesAssigned.props.children,
        },
        {
          title: "Role",
          dataIndex: "role",
          key: "role",
          sorter: (a, b) =>
            a.role.props.children.localeCompare(b.role.props.children),
        },
        {
          title: "Status",
          dataIndex: "isActive",
          key: "isActive",
          sorter: (a, b) => {
            return a.isActive.props.children.localeCompare(
              b.isActive.props.children
            );
          },
        },
      ]}
      dropdownItems
      rows={
        !loading
          ? employees?.map((employee, index) => {
              return {
                key: employee.id,
                values: [
                  <strong data-testid={`employee-${index}-name`}>
                    {`${employee.user?.firstName} ${employee.user?.lastName}`}
                  </strong>,
                  <span data-testid={`employee-${index}-email`}>
                    <UnderlineLink href={`mailto:${employee.user?.email}`}>
                      {employee.user?.email}
                    </UnderlineLink>
                  </span>,
                  <span data-testid={`employee-${index}-properties`}>
                    {employee.numAssignedProperties}
                  </span>,
                  <span data-testid={`employee-${index}-role`}>
                    {employee.role?.name}
                  </span>,
                  <EmployeeStatus
                    data-testid={`employee-${index}-status`}
                    isActive={employee.isActive}
                  >
                    {employee.isActive ? "Active" : "Deactivated"}
                  </EmployeeStatus>,
                ],
                dropdownItems: employee.isActive
                  ? [
                      {
                        key: "EDIT_EMPLOYEE",
                        label: "Edit employee",
                        onClick: () =>
                          openModalDialog(EditEmployeeModal, {
                            employeeData: {
                              employeeId: employee.id,
                              firstName: employee.user?.firstName,
                              lastName: employee.user?.lastName,
                              email: employee.user?.email,
                            },
                          }),
                      },
                      {
                        key: "ASSIGN_TO_PROPERTIES",
                        label: "Assign to properties",
                        onClick: () =>
                          assignEmployeeToProperty({
                            employeeId: employee.id,
                            firstName: employee.user?.firstName,
                            lastName: employee.user?.lastName,
                          }),
                      },
                      {
                        key: "DEACTIVATE",
                        label: "Deactivate",
                        onClick: () =>
                          deactivateEmployee({
                            employeeId: employee.id,
                            name: employee.user?.firstName,
                          }),
                      },
                    ]
                  : [
                      {
                        key: "DEACTIVATED",
                        label: "Employee was deactivated",
                        disabled: true,
                      },
                    ],
              };
            })
          : []
      }
      columnBreakpoints={{
        xs: {
          column1: "name",
          column2: "email",
        },
        sm: ["name", "email", "role"],
        md: ["name", "email", "role"],
      }}
      onRowClick={(key) => {
        history.push(ROUTES.employee.replace(":id", key));
      }}
      placeholder={{
        label: "Employees",
        Icon: Icon.NoEmployeesIcon,
      }}
      pagination={{
        current: page,
        onChange: onPaginationChange,
        total: employeesCount,
        defaultPageSize: pageSize,
      }}
    />
  );
};

export default EmployeesTable;
