import React, { useEffect, useState } from "react";

import { Radio } from "antd";

import { RadioChangeEvent, RadioProps } from "antd/lib/radio";

import {
  CollapsibleList,
  CollapsibleListProps,
} from "shared/components/Form/CollapsibleList";
import Tooltip, { TooltipProps } from "shared/components/Tooltip";

import { RadioGroup } from "./styled";

// eslint-disable-next-line react/prop-types
type TooltipRadioProps = RadioProps & {
  radioTooltipProps: TooltipProps;
};
const TooltipRadio: React.FC<TooltipRadioProps> = ({
  radioTooltipProps,
  ...props
}) => {
  if (radioTooltipProps) {
    return (
      <Tooltip {...radioTooltipProps}>
        <Radio {...props} />
      </Tooltip>
    );
  }
  return <Radio {...props} />;
};

export type CollapsibleRadioListProps = {
  onChange?: (event: RadioChangeEvent) => {};
  groupName: string;
  listProps: CollapsibleListProps;
};
export const CollapsibleRadioList: React.FC<CollapsibleRadioListProps> = ({
  groupName,
  listProps,
  onChange,
}) => {
  const selectedValue = listProps?.selectedItem;
  const [value, setValue] = useState(selectedValue);

  useEffect(() => {
    setValue?.(selectedValue);
  }, [selectedValue]);

  return (
    <RadioGroup value={value} name={groupName}>
      <CollapsibleList
        {...listProps}
        setSelectedItems={({ key }) => {
          listProps?.setFieldValue?.(key);
          setValue(key);
        }}
        renderInput={(input) => (
          <TooltipRadio
            value={input.item.key}
            data-testid={`radio-${input?.item.key}`}
            onChange={(e) => {
              onChange?.(e);
              input.onChange(e);
            }}
            disabled={input?.item.disabled}
            radioTooltipProps={input.item.radioTooltipProps}
          >
            {input?.item.label}
          </TooltipRadio>
        )}
      />
    </RadioGroup>
  );
};
