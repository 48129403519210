import React from "react";

import { Col, Row } from "antd";

import { useTranslation } from "renter/hooks/useTranslation";
import { UnderlineLink } from "shared/components/Links";
import Spacer from "shared/components/Spacer";
import Tile from "shared/components/Tile";
import { Reference } from "shared/interfaces/api";
import { calcTileInnerBorderBottom } from "shared/utils/ui";

interface ReferencesProps {
  references: Reference[];
}

const References = ({ references }: ReferencesProps) => {
  const { t } = useTranslation({
    keyPrefix: "personal-info.references",
  });

  return (
    <Tile
      header={{
        title: t("title"),
        collapsibleOnDesktop: true,
        collapsibleOnMobile: true,
      }}
    >
      {references?.length ? (
        references.map((reference, index) => (
          <Tile.Inner
            key={reference.id}
            borderBottom={calcTileInnerBorderBottom(references, index)}
            data-testid={`reference-${reference.id}`}
          >
            <Row type="flex">
              <Col xs={24} md={6} lg={5}>
                <Tile.Label>{t("name-label")}</Tile.Label>
                {reference?.name ||
                  `${reference.firstName} ${reference.lastName}`}
              </Col>
              <Col xs={24} md={8} lg={7}>
                <Spacer hideGteMd />
                <Tile.Label>{t("email-label")}</Tile.Label>
                <UnderlineLink href={`mailto:${reference.email}`}>
                  {reference.email}
                </UnderlineLink>
              </Col>
              <Col xs={24} md={10} lg={12}>
                <Spacer hideGteMd />
                <Tile.Label>{t("relationship-label")}</Tile.Label>
                {reference.relationship}
              </Col>
            </Row>
          </Tile.Inner>
        ))
      ) : (
        <Tile.Inner key={0} borderBottom data-testid={`reference-${0}`}>
          {t("no-items")}
        </Tile.Inner>
      )}
    </Tile>
  );
};

export default References;
