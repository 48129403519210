import React, { useState } from "react";

import { useMediaQuery } from "react-responsive";

import Icon from "shared/components/Icon";
import { Modal } from "shared/components/Modal";
import SelectableTiles from "shared/components/SelectableTiles";
import { BREAKPOINT_RESOLUTIONS } from "shared/config/constants";
import { MagicLinkExportLinks, MagicLinkExportQR } from "shared/images";

import { DOWNLOAD_OPTIONS } from "../../MagicLinksDashboard.config";

const useExportOptionsStep = ({ title, exportTitle, onSubmit }) => {
  const [activeOptions, setOptions] = useState([]);
  const isLarge = useMediaQuery({ maxWidth: BREAKPOINT_RESOLUTIONS.lg });

  const onOptionClick = (value) => {
    if (activeOptions.includes(value)) {
      const newOptions = activeOptions.filter((item) => item !== value);
      setOptions(newOptions);
    } else {
      setOptions([...activeOptions, value]);
    }
  };

  return {
    modalProps: {
      title,
      submitButtonLabel: "Next",
      width: isLarge ? "95%" : 946,
      submitButtonSuffixIcon: Icon.ButtonForwardArrowIcon,
      submitButtonDisabled: activeOptions.length === 0,
      submit: onSubmit,
      closeOnSubmit: false,
    },
    modalBody: (
      <Modal.Body>
        <SelectableTiles
          title={exportTitle}
          tiles={[
            {
              key: DOWNLOAD_OPTIONS.LINKS,
              name: "Magic Links",
              Icon: <MagicLinkExportLinks />,
            },
            {
              key: DOWNLOAD_OPTIONS.QR,
              name: "QR Codes",
              Icon: <MagicLinkExportQR />,
            },
          ]}
          activeTiles={activeOptions}
          onTileClick={onOptionClick}
        />
      </Modal.Body>
    ),
    response: activeOptions,
  };
};

export default useExportOptionsStep;
