import React, { useMemo, useState } from "react";

import {
  useGetYardiProperties,
  useImportPmsProperties,
} from "manager/hooks/api";
import { useGetPmsProperties } from "shared/hooks/api";
import useModal from "shared/hooks/useModal";
import {
  sanitizeStringSearchValue,
  valueMatchesFilter,
} from "shared/utils/string";

import YardiImportPropertyConfirmationModal from "../YardiImportPropertyConfirmationModal";

import YardiImportPropertyModal from "./YardiImportPropertyModal";

const YardiImportPropertyModalContainer = () => {
  const {
    currentModalContext: { afterPropertiesImport, company, provider },
    openModalDialog,
    closeActiveModalDialog,
  } = useModal();
  const [currentFilter, setCurrentFilter] = useState(null);

  const { importPmsProperties, isLoading: isImporting } =
    useImportPmsProperties({ provider });

  const { yardiProperties } = useGetYardiProperties({
    provider,
    isActive: true,
    page: 1,
    pageSize: 1000,
    truncated: true,
  });

  const importedPropertyIds = new Set(
    (yardiProperties?.results ?? []).map((yp) => yp.yardiId)
  );

  const { pmsProperties } = useGetPmsProperties({
    location: company.slug,
    provider,
  });

  const properties = (pmsProperties || []).filter((pmsProperty) => {
    return !importedPropertyIds.has(pmsProperty.identifier);
  });

  const filterProperties = (value) => setCurrentFilter(value);

  const adaptedProperties = useMemo(() => {
    const getLabel = (property) => `${property.marketingName} (${property.identifier})`;
    return (properties || []).map((property) => ({
      ...property,
      key: property.id,
      label: getLabel(property),
      description: property.fullAddress,
      sanitizedLabel: sanitizeStringSearchValue(getLabel(property)),
      sanitizedDescription: sanitizeStringSearchValue(property.fullAddress),
    }));
  }, [properties]);

  const filteredAdaptedProperties = useMemo(() => {
    return (adaptedProperties || []).filter((p) => {
      const sanitizedFilter = sanitizeStringSearchValue(currentFilter);
      return (
        valueMatchesFilter(p.sanitizedLabel, sanitizedFilter) ||
        valueMatchesFilter(p.sanitizedDescription, sanitizedFilter)
      );
    });
  }, [adaptedProperties, currentFilter]);

  const importPropertiesAction = (selectedProperties) => {
    const selectPropertiesIds = new Set(
      (selectedProperties || []).map((p) => p.key)
    );
    const propertiesToImport = (properties || []).filter((p) =>
      selectPropertiesIds.has(p.id)
    );
    const onSuccess = () => {
      const context = {
        numOfImportedProperties: propertiesToImport.length,
      };
      if (afterPropertiesImport) {
        afterPropertiesImport();
      }
      openModalDialog(YardiImportPropertyConfirmationModal, context);
    };
    const onError = () => {
      closeActiveModalDialog();
    };
    importPmsProperties(propertiesToImport, { onSuccess, onError });
  };

  const props = {
    properties: adaptedProperties,
    visibleProperties: filteredAdaptedProperties,
    isImporting,
    importPropertiesAction,
    filterProperties,
  };

  return <YardiImportPropertyModal {...props} />;
};

export default YardiImportPropertyModalContainer;
