export enum FeeInventoryLevel {
  COMPANY = "COMPANY",
  PORTFOLIO = "PORTFOLIO",
  PROPERTY = "PROPERTY",
}

export enum FeePaymentPoint {
  BEFORE_APPLICATION_SUBMIT = "BEFORE_APPLICATION_SUBMIT",
  AFTER_APPLICATION_APPROVE = "AFTER_APPLICATION_APPROVE",
}

export enum FeeTarget {
  SECURITY_DEPOSIT = "SECURITY_DEPOSIT",
  NONE = "NONE",
}

export interface FeeInventoryItem {
  level: string;
  amount?: number;
}

export interface ApplicationChargesModel extends FeeInventoryItem {
  holdingDepositPaymentPoint: FeePaymentPoint;
  holdingDepositChargeTarget: FeeTarget;
  resolvedFee?: FeeInventoryItem;
  feesDisabled?: boolean;
  disableFeeManagement?: boolean;
}

export interface GetApplicationChargesRequest {
  id: number;
  level: FeeInventoryLevel;
}

export interface SaveApplicationChargesRequest {
  id: number;
  level: FeeInventoryLevel;
  payload: {
    amount?: number;
    holdingDepositPaymentPoint: FeePaymentPoint;
    holdingDepositChargeTarget: FeeTarget;
  };
}
