import styled from "@emotion/styled";

import { COLORS } from "shared/config/constants";

interface StyledWellProps {
  noPadding?: boolean;
  transparent?: boolean;
  bold?: boolean;
  noBorder?: boolean;
  error?: boolean;
  lightError?: boolean;
  lightWarning?: boolean;
  largePadding?: boolean;
  white?: boolean;
  dark?: boolean;
}

const getPadding = (props: StyledWellProps) => {
  let padding = "16px";

  if (props.noPadding) {
    padding = "0px";
  } else if (props.largePadding) {
    padding = "24px";
  }
  return padding;
};

const getBackgroundColor = (props: StyledWellProps) => {
  let backgroundColor = COLORS.well.background;

  if (props.transparent) {
    backgroundColor = "transparent";
  } else if (props.error) {
    backgroundColor = COLORS.well.backgroundError;
  } else if (props.lightError) {
    backgroundColor = COLORS.well.backgroundLightError;
  } else if (props.lightWarning) {
    backgroundColor = COLORS.lightOrange2;
  } else if (props.white) {
    backgroundColor = COLORS.white;
  } else if (props.dark) {
    backgroundColor = COLORS.well.backgroundDark;
  }

  return backgroundColor;
};

const getBorder = (props: StyledWellProps) => {
  let border = `1px solid ${COLORS.well.border}`;

  if (props.noBorder || props.error || props.lightError || props.lightWarning) {
    border = "none";
  }

  return border;
};

const getFontWeight = (props: StyledWellProps) => {
  let fontWeight = 400;

  if (props.bold) {
    fontWeight = 500;
  }

  return fontWeight;
};

const Container = styled.div<StyledWellProps>`
  border-radius: 2px;
  background-color: ${getBackgroundColor};
  border: ${getBorder};
  padding: ${getPadding};
  font-weight: ${getFontWeight};
`;

export { Container };
