import { getValueOrNA } from "shared/utils/ui";

export const getFullnameData = (profile) => {
  const { firstName, lastName, middleName } = profile || {};
  const fullName = [firstName, middleName, lastName]
    .filter((v) => !!v)
    .join(" ");
  const hasFullname = !!fullName;
  const fullNameOrNA = getValueOrNA(fullName);
  return {
    hasFullname,
    fullNameOrNA,
  };
};
