import React from "react";
import _ from "lodash";
import { Helmet } from "react-helmet";
import { useHistory, useLocation } from "react-router-dom";

import Layout from "manager/components/Layout";
import SettingsTabs from "manager/components/SettingsTabs";
import ROUTES from "manager/config/routes";
import Container from "shared/components/Container";
import { BackLink } from "shared/components/Links";
import Spacer from "shared/components/Spacer";
import { useActiveCompany } from "shared/hooks";

import { transformRoute } from "shared/utils/routing";

import UnitFees from "./Fees";
import { UnitProps } from "./interfaces";
import Listings from "./Listings";
import UnitDeals from "./UnitDeals";
import UnitHeader from "./UnitHeader";
import { ApplicantWorkflowStudio } from "shared/components/vega/ApplicantWorkflow";
import { LumenStudio } from "shared/components/vega/LumenStudio";

const Unit = ({ sidebar, header, unit, company, loading, fetching }: UnitProps) => {
  const history = useHistory();
  const { activeCompany } = useActiveCompany();
  const { showOnlyUnitsNotAttachedToAnotherActiveDeal } = activeCompany || {};
  const { pathname, state } = useLocation();
  const { prevPath }: { prevPath?: string } = state ?? {};

  const backLink = prevPath
    ? {
        to: prevPath,
        children: "Units",
      }
    : {
        to: transformRoute(ROUTES.property, { id: unit?.property?.id }),
        children: unit?.property?.name,
      };

  return (
    <Layout
      sidebar={sidebar}
      header={{
        ...header,
        backLink,
      }}
    >
      <Helmet>
        {!loading && (
          <title>{`Unit ${unit?.name} | ${unit?.property?.name}`}</title>
        )}
      </Helmet>
      <Container noPaddingBottom>
        {backLink && (
          <BackLink.Container>
            <BackLink {...backLink} />
          </BackLink.Container>
        )}
      </Container>
      <Container noMobilePaddingX noPaddingTop>
        <span data-testid="unitHeader">
          <UnitHeader
            unit={unit}
            loading={loading}
            showOnlyUnitsNotAttachedToAnotherActiveDeal={
              showOnlyUnitsNotAttachedToAnotherActiveDeal
            }
          />
        </span>
        <Spacer size={Spacer.SIZES.md} />
        <SettingsTabs
          onTabChange={(link) => history.push(link)}
          activeTab={pathname}
          items={[
            {
              key: transformRoute(ROUTES.unitListings, { id: unit?.id }),
              label: "Listings",
              node: <Listings unit={unit} loading={loading || fetching} />,
            },
            {
              key: transformRoute(ROUTES.unitDeals, { id: unit?.id }),
              label: "Applications",
              node: <UnitDeals id={unit?.id} />,
            },
            {
              key: transformRoute(ROUTES.unitFees, { id: unit?.id }),
              label: "Fees",
              node: <UnitFees unit={unit} />,
            },
            {
              key: transformRoute(ROUTES.unitWorkflow, { id: unit?.id }),
              label: "Workflow",
              node: <ApplicantWorkflowStudio
                location={unit?.location}
                readOnlyLocations={[
                  unit?.property?.location,
                  `${company?.slug}#portfolio:${_.kebabCase(unit?.property?.portfolioName)}`,
                  company?.slug
                ]}
              />,
            },
            {
              key: transformRoute(ROUTES.unitLumen, { id: unit?.id }),
              label: "Lumen",
              node: <LumenStudio
                location={unit?.location}
                readOnlyLocations={[
                  unit?.property?.location,
                  `${company?.slug}#portfolio:${_.kebabCase(unit?.property?.portfolioName)}`,
                  company?.slug
                ]}
              />,
            },
          ]}
        />
      </Container>
    </Layout>
  );
};

export default Unit;
