import styled from "@emotion/styled";

import { RegularText, SmallText } from "shared/components/Typography";

import { COLORS } from "shared/config/constants";

const StyledTile = styled.div`
  width: 272px;
  height: 150px;
  /** Vertical flex collapses tiles without min-height on older iphones */
  min-height: 150px;
  border-radius: 6px;
  border: 1px solid ${COLORS.layoutBodyBackground};
  box-shadow: 0px 4px 22px ${COLORS.lightGrayBoxShadow};
  background: ${COLORS.primary};
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .tile-row {
    display: flex;
    justify-content: space-between;
  }

  .bordered-item {
    padding: 0 5px;
    border-right: 1px solid ${COLORS.white};
  }

  .bordered-item:first-child {
    padding-left: 0;
  }

  .bordered-item:last-child {
    border-right: none;
  }

  .unit-amenities {
    display: flex !important;
    max-width: 230px;
    color: ${COLORS.violetTheme.lightBlueViolet};
  }
`;

const UnitNameTitle = styled(RegularText)`
  color: ${COLORS.violetTheme.lightGreen};
  font-weight: 600;
  max-width: 120px;
  text-transform: uppercase;
`;

const UnitAvailableTitle = styled.div`
  color: ${COLORS.white};
  font-weight: 600;
  max-width: 80px;
  text-align: right;
  font-size: 12px;
`;

const UnitWhiteText = styled(RegularText)`
  color: ${COLORS.white};
  font-weight: 600;
  text-transform: uppercase;
  &.no-bedroom-description {
    max-width: 90px;
  }
`;

const UnitDetails = styled(SmallText)`
  color: ${COLORS.violetTheme.lightBlueViolet};
`;

export {
  StyledTile,
  UnitNameTitle,
  UnitAvailableTitle,
  UnitWhiteText,
  UnitDetails,
};
