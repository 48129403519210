import React from "react";

import { Col, Row } from "antd";

import Icon from "shared/components/Icon";

import { ViewerHeaderProps } from "./interfaces";
import { Header, Title, Subtitle, StyledLink } from "./styled";

const ViewerHeader = ({ selectedFile }: ViewerHeaderProps) => (
  <Header>
    <Row type="flex" justify="space-between" align="middle">
      <Col>
        <Title>Document Constructor</Title>
        <Subtitle>
          {selectedFile?.name
            ? `File name: ${selectedFile.name}`
            : "No file uploaded"}
        </Subtitle>
      </Col>
      <Col>
        <StyledLink to="#" Icon={Icon.LeaseEyeIcon}>
          Lease Preview
        </StyledLink>
      </Col>
    </Row>
  </Header>
);

export default ViewerHeader;
