import React from "react";

import { FormikHelpers } from "formik";
import sortBy from "lodash/sortBy";
import { useHistory } from "react-router-dom";

import {
  useCreateQuestion,
  useUpdateQuestion,
} from "manager/hooks/api/additionalQuestionsQueries";
import {
  AdditionalQuestionModel,
  AnswerType,
} from "manager/interfaces/api/additionalQuestion";
import ConfirmationModal from "shared/components/Modals/ConfirmationModal";
import { ANSWER_QUESTION_TYPES } from "shared/config/constants";
import { useAnswerTypeOptions } from "shared/hooks/api";
import useModal from "shared/hooks/useModal";
import { onDialogSubmitError } from "shared/utils/api";

import AdditionalQuestionModal from "./AdditionalQuestionModal";
import { ADDITIONAL_QUESTION_FIELDS } from "./config";
import {
  AdditionalQuestionModalContext,
  AdditionalQuestionModalMode,
} from "./interfaces";

const AdditionalQuestionModalContainer = () => {
  const history = useHistory();
  const { currentModalContext, selfApi, openModalDialog } = useModal();
  const { answerTypeOptions, isAnswerTypeOptionsLoading } =
    useAnswerTypeOptions();
  const { createQuestion } = useCreateQuestion();
  const { updateQuestion } = useUpdateQuestion();
  const {
    forSettingsLabel,
    applicantType,
    initialValues: initialValuesEdit,
    mode,
    objectId,
    contentType,
  }: AdditionalQuestionModalContext = currentModalContext;

  const initialValues: Partial<AdditionalQuestionModel> = {
    questionName: "",
    answerType: null,
    options: [],
  };

  const onAnswerTypeChange = (value: AnswerType, setFieldValue: Function) => {
    if (value === ANSWER_QUESTION_TYPES.freeText) {
      setFieldValue(ADDITIONAL_QUESTION_FIELDS.options, []);
    } else if (value === ANSWER_QUESTION_TYPES.singleChoice) {
      setFieldValue(
        ADDITIONAL_QUESTION_FIELDS.options,
        Array(4).fill({ answer: "" })
      );
    }
    setFieldValue(ADDITIONAL_QUESTION_FIELDS.answerType, value);
  };

  const submit = (
    values: AdditionalQuestionModel,
    formikApi: FormikHelpers<AdditionalQuestionModel>
  ) => {
    const onSuccess = selfApi.closeDialog;

    const onError = onDialogSubmitError(formikApi, history);

    const newQuestion: AdditionalQuestionModel = {
      objectId,
      applicantType,
      contentType,
      isActive: true,
      ...values,
    } as AdditionalQuestionModel;

    if (mode === AdditionalQuestionModalMode.CREATE) {
      createQuestion(newQuestion, { onSuccess, onError });
    } else if (newQuestion.hasLinkedDocuments) {
      // @ts-ignore
      openModalDialog(ConfirmationModal, {
        title: `Edit Question`,
        message: `By editing this question and its answers, you are going to reset the Lease Package(s) associated with this question.`,
        onConfirm: () => updateQuestion(newQuestion, { onSuccess, onError }),
      });
    } else {
      updateQuestion(newQuestion, { onSuccess, onError });
    }
  };

  const prepareInitialValues = () => {
    initialValuesEdit[ADDITIONAL_QUESTION_FIELDS.options] = sortBy(
      initialValuesEdit[ADDITIONAL_QUESTION_FIELDS.options],
      ADDITIONAL_QUESTION_FIELDS.position
    );
    initialValuesEdit.isActive = undefined;
    return initialValuesEdit;
  };

  return (
    <AdditionalQuestionModal
      initialValues={
        mode === AdditionalQuestionModalMode.CREATE
          ? initialValues
          : prepareInitialValues()
      }
      submit={submit}
      mode={mode}
      applicantType={applicantType}
      forSettingsLabel={forSettingsLabel}
      onAnswerTypeChange={onAnswerTypeChange}
      answerTypeOptions={answerTypeOptions}
      isAnswerTypeOptionsLoading={isAnswerTypeOptionsLoading}
    />
  );
};

export default AdditionalQuestionModalContainer;
