import React from "react";

import dayjs from "dayjs";
import isNil from "lodash/isNil";

import {
  ApplicationSummaryCard,
  CardSize,
  CardStatus,
} from "shared/components/ApplicationSummaryCard";
import {
  ApplicationSummaryTooltip,
  TooltipColumnWidth,
} from "shared/components/ApplicationSummaryTooltip";
import { VorStatus } from "shared/graphql";
import { formatPhone } from "shared/utils/ui";

import { EMPTY_INDICATOR, getValue } from "../utils";

export interface VorCardProps {
  enabled: boolean;
  score?: number;
  outOf?: number;
  status?: VorStatus;
  name?: string;
  email?: string;
  phone?: string;
  submission?: dayjs.Dayjs;
  lowestScore?: number;
  onClick?: () => void;
  lumenEnabled?: boolean;
}

export const VorCard = ({
  score,
  name,
  email,
  submission,
  lowestScore,
  outOf,
  status,
  phone,
  onClick,
  lumenEnabled,
}: VorCardProps) => {
  const formatScore = (score?: number, outOf?: number) =>
    isNil(score) ? EMPTY_INDICATOR : `${score}${outOf ? `/${outOf}` : ""}`;

  const additionalProps = () => {
    if (
      !status ||
      [VorStatus.notified, VorStatus.waiting, VorStatus.verified].includes(
        status
      )
    ) {
      return {
        title: "NOT COMPLETED",
        status: CardStatus.unavailable,
      };
    }
    return {
      title: isNil(score) ? "COMPLETED" : formatScore(score, outOf),
      status: CardStatus.success,
    };
  };

  return (
    <ApplicationSummaryTooltip
      layout={[
        {
          title: "VoR Contact:",
          subject: getValue(name),
          subtitle:
            !email && !phone
              ? EMPTY_INDICATOR
              : `${email}${!phone ? "" : ` | ${formatPhone(phone)}`}`,
          width: TooltipColumnWidth.full,
        },
        {
          title: "Submission Time and Date:",
          subtitle: submission && dayjs(submission).format("H:MM A   M/D/YYYY"),
          width: TooltipColumnWidth.full,
        },
        {
          title: "Lowest Score Received:",
          subtitle: formatScore(lowestScore, outOf),
          width: TooltipColumnWidth.full,
        },
      ]}
    >
      <div>
        <ApplicationSummaryCard
          onClick={onClick}
          subtitle="Residential History"
          size={CardSize.Small}
          hideColor={lumenEnabled}
          {...additionalProps()}
        />
      </div>
    </ApplicationSummaryTooltip>
  );
};
