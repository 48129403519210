import React from "react";

import {
  useGetYardiAgentsOnVeroProperties,
  usePutYardiAgentsOnPropertiesMapping,
} from "manager/hooks/api";
import { getIntegrationType } from "manager/utils/integration.util";
import { INTEGRATION_TYPES } from "shared/config/constants";
import useModal from "shared/hooks/useModal";

import LinkEmployeeToYardiAgent from "./LinkEmployeeToYardiAgent";

const LinkEmployeeToYardiAgentContainer = () => {
  const { currentModalContext } = useModal();
  const { employeeId, firstName, lastName, newlyAssignedProperties } =
    currentModalContext;

  const newPropertiesIds = (newlyAssignedProperties || [])
    .filter(
      (property) => getIntegrationType(property) === INTEGRATION_TYPES.YARDI
    )
    .map((property) => property.id);
  const newPropertiesIdsQueryParam = newPropertiesIds.join(",");
  const { yardiAgentsOnProperties, isLoading } =
    useGetYardiAgentsOnVeroProperties(newPropertiesIdsQueryParam);
  const { updateYardiAgentsMappings } = usePutYardiAgentsOnPropertiesMapping();

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      const properties = values?.properties;
      const mappings = (properties || []).map(({ propertyId, yardiAgent }) => ({
        propertyId,
        agents: [
          {
            employeeId,
            yardiAgentId: yardiAgent,
          },
        ],
      }));
      await updateYardiAgentsMappings(mappings);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <LinkEmployeeToYardiAgent
      firstName={firstName}
      lastName={lastName}
      yardiAgentsOnProperties={yardiAgentsOnProperties}
      isLoading={isLoading}
      onSubmit={onSubmit}
    />
  );
};

export default LinkEmployeeToYardiAgentContainer;
