import styled from "@emotion/styled";
import { Radio } from "antd";

import { COLORS } from "shared/config/constants";

export const RadioGroup = styled(Radio.Group)({
  display: "block",
  "& .ant-radio-wrapper.ant-radio-wrapper-disabled span": {
    color: `${COLORS.textColorSecondary}`,
  },
  ".ant-radio-wrapper": {
    display: "flex",
    alignItems: "center",
  },
});
