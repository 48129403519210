import { Key } from "react";

import axios from "axios";

import ROUTES from "manager/config/apiRoutes";
import { PropertySettings } from "manager/interfaces/api";
import { API_URL } from "shared/lib/api/setupApi";
import { transformRoute } from "shared/utils/routing";

export const getPropertySettings = (propertyId: Key) =>
  axios
    .get<PropertySettings>(
      `${API_URL}${transformRoute(ROUTES.propertySettings, {
        id: propertyId,
      })}`
    )
    .then(({ data }) => data);

export const getPropertiesDropdown = () =>
  axios.get(`${API_URL}${ROUTES.propertiesList}`).then(({ data }) => data);
