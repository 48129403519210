import React from "react";

import { useGetPartners } from "renter/hooks/api";

import Marketplace from "./Marketplace";

const MarketplaceContainer = () => {
  const { partners, isPartnersLoading } = useGetPartners();

  return <Marketplace partners={partners} isLoading={isPartnersLoading} />;
};

export default MarketplaceContainer;
