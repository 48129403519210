import { createContext, useContext, useState } from "react";

/**
 * The context for storing the global config of the current application.
 */
const GlobalConfigContext = createContext({});

/**
 * The hook for getting access to the global config context.
 * @returns {{getConfigItem: function, setPartialConfig: function, globalConfig: *, setConfigItem: function}}
 */
const useGlobalConfig = () => {
  const globalConfigContext = useContext(GlobalConfigContext);
  // NOTE: destructuring to make it clear what's available in the global config context.
  const { globalConfig, setConfigItem, setPartialConfig, getConfigItem } =
    globalConfigContext;
  return {
    globalConfig,
    setConfigItem,
    setPartialConfig,
    getConfigItem,
  };
};

/**
 * The hook for getting access to a certain item in the global config context.
 * @param itemKey the key of the item.
 * @returns {*}
 */
const useGlobalConfigItem = (itemKey) => {
  const { getConfigItem } = useGlobalConfig();
  return getConfigItem(itemKey);
};

/**
 * The hook for defining the global config context
 * @returns {{getConfigItem: (function(*): *), setPartialConfig: setPartialConfig, globalConfig: {}, setConfigItem: setConfigItem}}
 */
const useGlobalConfigProvider = () => {
  const [globalConfig, setGlobalConfig] = useState({});

  const setConfigItem = (key, value) => {
    const newConfig = {
      ...(globalConfig || {}),
      [key]: value,
    };
    setGlobalConfig(newConfig);
  };

  const setPartialConfig = (partialConfig) => {
    const newConfig = {
      ...(globalConfig || {}),
      ...(partialConfig || {}),
    };
    setGlobalConfig(newConfig);
  };

  const getConfigItem = (key) => (globalConfig || {})[key];

  return {
    globalConfig,
    setConfigItem,
    setPartialConfig,
    getConfigItem,
  };
};

export {
  GlobalConfigContext,
  useGlobalConfigProvider,
  useGlobalConfig,
  useGlobalConfigItem,
};
