import React from "react";

import {
  BarChart as BaseBarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from "recharts";

import { COLORS as BASE_COLORS } from "shared/config/constants";

const COLORS = [
  BASE_COLORS.dashboard.stackedBarChart.lightViolet1,
  BASE_COLORS.dashboard.stackedBarChart.lightViolet2,
  BASE_COLORS.dashboard.stackedBarChart.lightViolet3,
  BASE_COLORS.dashboard.stackedBarChart.lightViolet4,
];
// NOTE: https://recharts.org/en-US/api/Bar
const STROKE_DASH_ARRAY = "3 3";
const BAR_SIZE = 57;
const STACK_ID = "1";

export interface StackedBarChartProps {
  width?: string | number;
  height?: string | number;
  data: {
    name: string;
    [key: string]: number | string;
  }[];
}

const StackedBarChart = ({
  width = "100%",
  height = "100%",
  data,
}: StackedBarChartProps) => {
  return (
    <ResponsiveContainer debounce={1} width={width} height={height}>
      <BaseBarChart data={data}>
        <CartesianGrid strokeDasharray={STROKE_DASH_ARRAY} />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        {Object.keys(data[0] || {}).map((key, index) => {
          if (key === "name") {
            return null;
          }
          return (
            <Bar
              key={key}
              dataKey={key}
              // NOTE: Since the first key in object should be the name that shouldn't be a Bar that's why we are doing index - 1
              fill={COLORS[index - 1]}
              barSize={BAR_SIZE}
              stackId={STACK_ID}
            />
          );
        })}
      </BaseBarChart>
    </ResponsiveContainer>
  );
};

export default StackedBarChart;
