import React from "react";

import { Modal } from "shared/components/Modal";

import { DeactivateEmployeeModalProps } from "./interfaces";

const DeactivateEmployeeModal = ({
  name,
  submit,
  submitting,
}: DeactivateEmployeeModalProps) => (
  <Modal
    title={`Deactivate ${name}?`}
    submit={submit}
    submitButtonLabel="Deactivate"
    submitting={submitting}
  >
    <Modal.Body>
      <p>
        Please note that once you deactivate this employee he will automatically
        be unassigned from all the properties he managed before.
      </p>
    </Modal.Body>
  </Modal>
);

export default DeactivateEmployeeModal;
