import React from "react";

import isEmpty from "lodash/isEmpty";

import { UnderlineLink } from "shared/components/Links";

import { InviteApplicantsLinkProps } from "./interfaces";

export const InviteApplicantsLink = ({
  checkValidation,
  addApplicant,
}: InviteApplicantsLinkProps) => {
  const onClick = async () => {
    const res = await checkValidation();
    if (isEmpty(res?.applicants)) {
      addApplicant({ email: "", firstName: "", lastName: "" });
    }
  };

  return (
    <UnderlineLink
      onClick={onClick}
      dashed
      data-testid="invite-applicants-link"
    >
      Invite More
    </UnderlineLink>
  );
};
