import React, { useEffect, useState } from "react";

import { Redirect, useLocation, useParams } from "react-router-dom";

import {
  getMagicPortalUnitLro,
  MagicPortalUnitLroResponse,
} from "renter/lib/magicPortalApi";
import LoaderContainer from "shared/components/LoaderContainer";
import { DealType } from "shared/config/constants";
import ROUTES from "shared/config/routes";

import { transformRoute } from "shared/utils/routing";

import { MagicLinkGetStarted } from "../MagicLink/MagicLinkGetStarted";

export const MagicLinkUnitRedirect: React.FC<{}> = () => {
  const Default = () => <MagicLinkGetStarted dealType={DealType.SCREENING} />;

  const { companyHashId: unitHashId } =
    useParams<{ companyHashId: string | null }>();

  const skipLro = new URLSearchParams(useLocation().search).has("skipLro");

  if (!unitHashId?.match(/^un_/i) || skipLro) return <Default />;

  const [loading, setLoading] = useState(true);
  const [isLro, setIsLro] = useState<MagicPortalUnitLroResponse>({
    enabled: false,
    propertyHashId: "",
    unitHashId: "",
  });

  useEffect(() => {
    setLoading(true);
    getMagicPortalUnitLro({ unitHashId })
      .then((data) => {
        setIsLro(data);
      })
      .finally(() => setLoading(false));
  }, []);

  return loading ? (
    <LoaderContainer fetching />
  ) : isLro.enabled ? (
    <Redirect
      to={transformRoute(ROUTES.magicPortalPropertyUnit, {
        unitHashId,
        propertyHashId: isLro.propertyHashId,
      })}
    />
  ) : (
    <Default />
  );
};
