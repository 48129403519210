import styled from "@emotion/styled";

import Card from "shared/components/Card";
import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

const Container = styled(FlexContainer)`
  position: relative;
  flex-direction: column;

  ${MEDIA_QUERIES.w.ltMd} {
    padding: 0 24px;
  }
`;

const Title = styled.h1`
  color: ${COLORS.darkViolet3};
  font-weight: 500;
  margin-bottom: 0 !important;

  ${MEDIA_QUERIES.w.ltMd} {
    display: none;
  }
`;

const CardGroup = styled(FlexContainer)`
  flex-wrap: wrap;
  row-gap: 16px;
  column-gap: 16px;
  padding: 24px 0;
`;

interface FilterCardProps {
  isActive?: boolean;
}

const StyledFilterCard = styled(Card)<FilterCardProps>`
  cursor: ${(props) => (props.isActive ? "default" : "pointer")};
  color: ${COLORS.primary};
  min-width: 250px;
  width: 250px !important;
  min-height: 77px;
  height: 77px !important;
  padding: 12px !important;
  border-radius: 8px;
  border: ${(props) => (props.isActive ? "1px solid" : "none")};
  border-color: ${(props) =>
    props.isActive ? COLORS.violetTheme.lightBlueViolet : "none"};
  box-shadow: ${(props) => (props.isActive ? COLORS.boxShadowDeep : "none")};

  .ant-card-body {
    height: 100%;

    .filter-content {
      display: flex;
      column-gap: 15px;
      height: 100%;

      .filter-count {
        display: flex;
        font-size: 28px;
        font-weight: 400;
        justify-content: center;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
        align-items: center;
        width: 80px;
        transition: opacity 0.5s, transform 0.5s;
        opacity: 1;
      }

      .filter-count.loading {
        opacity: 0;
      }

      .filter-description {
        width: 100%;

        .filter-notice {
          display: flex;
          justify-content: space-between;
          font-size: 10px;
          font-weight: 400;
          line-height: 15px;
          letter-spacing: 0em;
          text-align: left;
          color: ${(props) =>
            props.isActive ? COLORS.violetTheme.green : COLORS.primary};

          &.active {
            font-weight: 600;
          }
        }

        .filter-type {
          font-size: 15px;
          font-weight: 400;
          line-height: 19px;
          letter-spacing: 0em;
          text-align: left;
        }
      }
    }
  }

  :hover {
    box-shadow: ${COLORS.boxShadowDeep};
  }
`;

const StyledCloseIcon = styled(Icon.CloseOneIcon)`
  width: 14px;
  height: 14px;
  cursor: pointer;
`;

export { Container, Title, CardGroup, StyledFilterCard, StyledCloseIcon };
