import React, { useRef } from "react";

import { Formik } from "formik";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import { YARDI_PROPERTIES_STATUS } from "manager/pages/Marketplace/integrations/yardi/dashboard/yardiDashboard.config";
import Container from "shared/components/Container";
import FlexContainer from "shared/components/FlexContainer";
import FooterBar from "shared/components/FooterBar";
import { PreventLeaveFormModal, PopoverContent } from "shared/components/Form";
import Icon from "shared/components/Icon";
import { ActionLink, BackLink } from "shared/components/Links";
import PageTitle from "shared/components/PageTitle";
import Spacer from "shared/components/Spacer";
import { IntegrationProvider } from "shared/config/constants";
import useWindowSize from "shared/hooks/useWindowSize";
import { LogoIcon, CheckIcon } from "shared/icons";
import { getScrollableParent, SCROLL_DIRECTION } from "shared/utils/dom";
import {
  backLinkPropType,
  basicPropertyPropType,
  refPropType,
} from "shared/utils/propTypes";

import { FooterButton } from "../shared/mapping/styled";
import { mappingType, sectionsType } from "../shared/propTypes";
import { veroUnitsPropType, yardiUnitsPropType } from "../shared/yardi.config";
import YardiHeaderCredentials from "../shared/YardiHeaderCredentials";
import { PopoverContainer, YardiTitleContainer } from "../styled";

import { SECTION_TYPES } from "./configureYardiProperty.config";
import {
  YardiSectionGeneralSettings,
  YardiSectionAvailableUnits,
  YardiSectionOtherUnits,
  YardiSectionWaitUnits,
  YardiSectionAgents,
  YardiSectionMarketingSources,
} from "./section";
import {
  YardiPropertyTile,
  YardiPropertyTileContent,
  YardiPropertyConfigContainer,
  MappingHintTitle,
  RightLane,
  ConfigurationMenuTile,
  ConfigSectionMenu,
  ContentLane,
  ConfigFooterBar,
  SpinnerIcon,
} from "./styled";

const title = "Configure Property";

const ConfigurationMenu = ({ sections, refs }) => {
  const onMenuChange = (key) => {
    const domRef = refs[key];
    domRef.current.scrollIntoView({
      block: "start",
    });
    // we need to scroll down a bit because the scrollable container has a marginTop of 95px
    // this scroll needs to be in a timeout to be applied
    setTimeout(() => {
      getScrollableParent(domRef.current, SCROLL_DIRECTION.Y).scrollBy({
        top: -95,
      });
    }, 0);
  };
  return (
    <>
      <ConfigurationMenuTile stickyTop>
        <ConfigSectionMenu
          defaultActiveKey={sections[0].anchor}
          items={sections
            .filter((section) => section.isVisible)
            .map((section) => ({
              key: section.anchor,
              label: section.name,
              rightSide: section.loading ? (
                <SpinnerIcon />
              ) : (
                section.completed && <CheckIcon />
              ),
            }))}
          onChange={onMenuChange}
        />
      </ConfigurationMenuTile>
    </>
  );
};

ConfigurationMenu.propTypes = {
  sections: sectionsType.isRequired,
  refs: PropTypes.object.isRequired,
};

const ConfigureYardiProperty = ({
  backLink,
  veroProperty,
  yardiProperty,
  onChangePropertyMapping,
  sections,
  saveOnLeave,
  onFormSubmit,
  formikRef,
  veroUnits,
  veroUnitsMap,
  areUnitsLoading,
  yardiUnitsMap,
  availableYardiUnits,
  otherYardiUnits,
  waitYardiUnits,
  marketingSourceSection,
  agentsSection,
  provider,
}) => {
  const EMPTY_VALUE = {
    isLroEnabled: provider === IntegrationProvider.realPage ?? undefined,
  };

  const refs = {};
  sections.forEach((section) => {
    refs[section.anchor] = useRef(null);
  });

  const isAnythingLoading =
    areUnitsLoading || marketingSourceSection.loading || agentsSection.loading;

  const { width } = useWindowSize();
  const firstColWidth = `${Math.max(355, width * 0.25)}px`;

  return (
    <YardiPropertyConfigContainer
      expand
      noPaddingBottom
      firstColWidth={firstColWidth}
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {backLink && (
        <BackLink.Container>
          <BackLink {...backLink} />
        </BackLink.Container>
      )}
      <PageTitle.Container center>
        <div>
          <YardiTitleContainer>{yardiProperty?.name} ({yardiProperty?.yardiId}) Setup</YardiTitleContainer>
          <PageTitle.Subtitle>{yardiProperty?.address}</PageTitle.Subtitle>
        </div>
        <YardiHeaderCredentials loadCredentials />
      </PageTitle.Container>
      <Formik
        initialValues={EMPTY_VALUE}
        onSubmit={onFormSubmit}
        validateOnMount
        validateOnChange
        innerRef={formikRef}
      >
        {({ dirty, isSubmitting, isValid, submitForm, values }) => (
          <Container noMobilePaddingX noPadding expand>
            <YardiPropertyTile>
              <YardiPropertyTileContent>
                <div className="property-header">
                  <span>
                    <LogoIcon height={12} /> property:
                  </span>
                  <span>
                    <b>{veroProperty?.name},&nbsp;</b>
                    {veroProperty?.address}
                  </span>
                </div>
                <PopoverContainer
                  className="popover"
                  withoutOverlay
                  disabled={
                    yardiProperty.status !== YARDI_PROPERTIES_STATUS.COMPLETED
                  }
                  content={
                    <PopoverContent>
                      Removing this property from VERO can cause problems with
                      the integration. If you would like to remove it please
                      contact the VERO support team.
                    </PopoverContent>
                  }
                >
                  <Icon.MyPropertyIcon
                    disabled={
                      yardiProperty.status === YARDI_PROPERTIES_STATUS.COMPLETED
                    }
                  />
                  <ActionLink
                    onClick={onChangePropertyMapping}
                    disabled={
                      yardiProperty.status === YARDI_PROPERTIES_STATUS.COMPLETED
                    }
                  >
                    CHANGE PROPERTY
                  </ActionLink>
                </PopoverContainer>
              </YardiPropertyTileContent>
            </YardiPropertyTile>
            <MappingHintTitle>
              Map External Provider data with VERO data
            </MappingHintTitle>
            <FlexContainer>
              <RightLane>
                <ConfigurationMenu sections={sections} refs={refs} />
              </RightLane>
              <ContentLane>
                {provider !== IntegrationProvider.realPage && (
                  <YardiSectionGeneralSettings
                    yardiUnitsMap={yardiUnitsMap}
                    loading={areUnitsLoading}
                  />
                )}
                {sections
                  .filter((section) => section.isVisible)
                  .map((section) => (
                    <div key={section.anchor} ref={refs[section.anchor]}>
                      {section.anchor === SECTION_TYPES.AVAILABLE_UNITS && (
                        <YardiSectionAvailableUnits
                          provider={provider}
                          section={section}
                          veroUnits={veroUnits}
                          veroUnitsMap={veroUnitsMap}
                          yardiUnits={availableYardiUnits}
                          veroProperty={veroProperty}
                          yardiProperty={yardiProperty}
                          firstColWidth={firstColWidth}
                          loading={areUnitsLoading}
                        />
                      )}
                      {section.anchor === SECTION_TYPES.WAIT_UNITS && (
                        <YardiSectionWaitUnits
                          provider={provider}
                          section={section}
                          veroUnits={veroUnits}
                          veroUnitsMap={veroUnitsMap}
                          yardiUnits={waitYardiUnits}
                          veroProperty={veroProperty}
                          yardiProperty={yardiProperty}
                          firstColWidth={firstColWidth}
                          loading={areUnitsLoading}
                        />
                      )}
                      {section.anchor === SECTION_TYPES.OTHER_UNITS && (
                        <YardiSectionOtherUnits
                          provider={provider}
                          section={section}
                          veroUnits={veroUnits}
                          veroUnitsMap={veroUnitsMap}
                          yardiUnits={otherYardiUnits}
                          veroProperty={veroProperty}
                          yardiProperty={yardiProperty}
                          firstColWidth={firstColWidth}
                          loading={areUnitsLoading}
                        />
                      )}
                      {section.anchor === SECTION_TYPES.AGENTS && (
                        <YardiSectionAgents
                          section={section}
                          loading={agentsSection.loading}
                          veroAgents={agentsSection.vero}
                          yardiAgents={agentsSection.yardi}
                          firstColWidth={firstColWidth}
                        />
                      )}
                      {section.anchor === SECTION_TYPES.MARKETING_SOURCES && (
                        <YardiSectionMarketingSources
                          provider={provider}
                          section={section}
                          loading={marketingSourceSection.loading}
                          propertyName={veroProperty?.name}
                          propertyId={veroProperty?.id}
                          yardiPropertyId={yardiProperty?.id}
                          veroMarketingSources={marketingSourceSection.vero}
                          yardiMarketingSources={marketingSourceSection.yardi}
                        />
                      )}
                    </div>
                  ))}
              </ContentLane>
              <PreventLeaveFormModal
                title="Exit property setup"
                subtitle="Would you like to save your changes?"
                customOnConfirm={(onConfirm, onCancel) =>
                  saveOnLeave(values, { onCancel, onConfirm })
                }
                customOnCancel={(onConfirm) => onConfirm()}
                isSubmitting={isSubmitting}
                preventLeaveWhen={dirty && !isAnythingLoading}
                cancelLinkLabel="No"
                submitButtonLabel="Yes"
              >
                <Spacer />
              </PreventLeaveFormModal>
              <FooterBar.Spacer />
            </FlexContainer>
            <Container noPadding stickToBottom>
              <ConfigFooterBar>
                <FooterButton
                  type="primary"
                  loading={isSubmitting}
                  disabled={!dirty || !isValid || isAnythingLoading}
                  onClick={submitForm}
                >
                  Save changes
                </FooterButton>
              </ConfigFooterBar>
            </Container>
          </Container>
        )}
      </Formik>
    </YardiPropertyConfigContainer>
  );
};

ConfigureYardiProperty.propTypes = {
  backLink: backLinkPropType.isRequired,
  veroProperty: basicPropertyPropType,
  yardiProperty: basicPropertyPropType,
  onChangePropertyMapping: PropTypes.func.isRequired,
  sections: sectionsType.isRequired,
  marketingSourceSection: mappingType.isRequired,
  agentsSection: mappingType.isRequired,
  veroUnits: veroUnitsPropType,
  veroUnitsMap: PropTypes.object.isRequired,
  areUnitsLoading: PropTypes.bool,
  yardiUnitsMap: PropTypes.object.isRequired,
  availableYardiUnits: yardiUnitsPropType,
  otherYardiUnits: yardiUnitsPropType,
  waitYardiUnits: yardiUnitsPropType,
  formikRef: refPropType.isRequired,
  saveOnLeave: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  provider: PropTypes.string.isRequired,
};

ConfigureYardiProperty.defaultProps = {
  veroProperty: null,
  yardiProperty: null,
  areUnitsLoading: false,
  veroUnits: [],
  availableYardiUnits: [],
  otherYardiUnits: [],
  waitYardiUnits: [],
};

export default ConfigureYardiProperty;
