import { handleActions } from "redux-actions";

import {
  openModal,
  closeActiveModal,
  unrenderActiveModal,
  updateModalContext,
} from "../actions";

const INITIAL_STATE = {
  activeModal: undefined,
};

const modalCommonReducer = handleActions(
  {
    [openModal]: (state, { payload: { id, context } }) => ({
      ...state,
      activeModal: {
        id,
        context,
        visible: true,
      },
    }),
    [closeActiveModal]: (state) => ({
      ...state,
      activeModal: {
        ...state.activeModal,
        visible: false,
      },
    }),
    [unrenderActiveModal]: (state) => ({
      ...state,
      activeModal: undefined,
    }),
    [updateModalContext]: (state, { payload: { context } }) => ({
      ...state,
      activeModal: {
        ...state.activeModal,
        context: {
          ...state.activeModal.context,
          ...context,
        },
      },
    }),
  },
  INITIAL_STATE
);

export default modalCommonReducer;
