import styled from "@emotion/styled";

import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

const InnerContainer = styled(FlexContainer)`
  background: ${COLORS.layoutBodyBackground};
  padding: 40px 0 50px;
  ${MEDIA_QUERIES.w.ltSm} {
    padding: 20px 10px;
    width: 100%;
  }
`;

const TileContainer = styled(FlexContainer)`
  ${MEDIA_QUERIES.w.ltSm} {
    width: 100%;
  }
`;

const Title = styled("h2")`
  font-weight: 500;
  color: ${COLORS.primary};
`;

const Tile = styled("div")<{ active?: boolean }>`
  width: 320;
  height: 280;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.active ? COLORS.lightGreen : COLORS.white};
  position: relative;
  &:first-of-type {
    margin-right: 30px;
    ${MEDIA_QUERIES.w.ltMd} {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
  &:hover {
    cursor: pointer;
  }
  ${MEDIA_QUERIES.w.ltLg} {
    width: 280px;
    height: 240px;
  }
  ${MEDIA_QUERIES.w.ltSm} {
    width: 240px;
    height: 200px;
  }
`;

const TileName = styled("span")`
  color: ${COLORS.primary};
  font-size: 22px;
`;

const CheckIcon = styled(Icon.CheckIcon)`
  width: 45px;
  height: 45px;
  position: absolute;
  top: 24px;
  left: 24px;
`;

export { InnerContainer, TileContainer, Tile, Title, TileName, CheckIcon };
