import { useMutation } from "react-query";

import api from "manager/lib/api";
import { NOTIFICATIONS } from "shared/config/constants";
import { openNotification } from "shared/utils/ui";

export const useRequestCountyCriminalSearch = (successMessage) => {
  const [mutate, status] = useMutation(api.requestCountyCriminalSearch, {
    onSuccess: () => openNotification(successMessage, NOTIFICATIONS.info),
    onError: () =>
      openNotification(
        "Failed to request County Criminal Records search.",
        NOTIFICATIONS.error
      ),
  });

  return {
    requestCountyCriminalSearch: mutate,
    isLoading: status.isLoading,
  };
};
