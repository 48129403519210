/* eslint-disable func-names */
import isEmpty from "lodash/isEmpty";
import * as Yup from "yup";

import {
  APPLICANT_TYPES,
  VALIDATION_MESSAGES,
  LIVING_SITUATION_TYPES,
  OTHER,
  REGEX,
} from "shared/config/constants";
import { isGreaterThan18, isLessThan100 } from "shared/forms/AddOccupantsForm";
import {
  validAddress,
  validEmail,
  validFirstName,
  validLastName,
  validMiddleName,
  validMoney,
  validPhone,
} from "shared/forms/validation/fieldValidators";
import { hasMinimalRequiredDays } from "shared/utils/dates";

export const BasicInfoValidationSchema = Yup.object().shape({
  firstName: validFirstName.required(VALIDATION_MESSAGES.fieldRequired),
  middleName: validMiddleName,
  lastName: validLastName.required(VALIDATION_MESSAGES.fieldRequired),
  birthdate: Yup.string()
    .nullable()
    .required(VALIDATION_MESSAGES.fieldRequired)
    .test(
      "greaterThan18",
      "You should be over 18 years old to apply",
      (value) => {
        return isGreaterThan18(value);
      }
    )
    .test(
      "lessThan100",
      "You should be under 100 years old to apply",
      (value) => {
        return isLessThan100(value);
      }
    ),
  phoneNumber: validPhone.required(VALIDATION_MESSAGES.fieldRequired),
});

export const LeasePreferenceValidationSchema = Yup.mixed().when(
  "additionalApplicationInfo.applicantType",
  {
    is: APPLICANT_TYPES.applicant,
    then: Yup.object().shape({
      preferredLeaseDurationMonths: Yup.number()
        .integer()
        .nullable()
        .min(0, "Enter a duration of 1 to 120 months")
        .max(120, "Enter a duration of 1 to 120 months")
        .required(VALIDATION_MESSAGES.fieldRequired),
      preferredLeaseStartDate: Yup.date()
        .nullable()
        .required(VALIDATION_MESSAGES.fieldRequired),
    }),
  }
);

export const EmergencyContactsValidationSchema = Yup.mixed().when(
  "additionalApplicationInfo.applicantType",
  {
    is: APPLICANT_TYPES.applicant,
    then: Yup.array().of(
      Yup.object().shape({
        firstName: validFirstName.required(VALIDATION_MESSAGES.fieldRequired),
        lastName: validLastName.required(VALIDATION_MESSAGES.fieldRequired),
        phoneNumber: validPhone.required(VALIDATION_MESSAGES.fieldRequired),
        type: Yup.string()
          .nullable()
          .required(VALIDATION_MESSAGES.fieldRequired),
        relationship: Yup.string()
          .nullable()
          .when("type", {
            is: OTHER,
            then: Yup.string()
              .nullable()
              .max(64, "Field cannot have more than 64 characters")
              .required(VALIDATION_MESSAGES.fieldRequired),
          }),
      })
    ),
  }
);

export const AdditionalInformationValidationSchema = Yup.lazy(
  (additionalInformation) => {
    if (isEmpty(additionalInformation)) {
      return Yup.mixed().notRequired();
    }

    const additionalInformationKeys = Object.keys(additionalInformation);

    return Yup.object().shape({
      ...(additionalInformationKeys.includes("industry") && {
        industry: Yup.string()
          .nullable()
          .required(VALIDATION_MESSAGES.fieldRequired),
      }),
      ...(additionalInformationKeys.includes("pets") && {
        pets: Yup.array().of(
          Yup.object().shape({
            age: Yup.number()
              .min(0)
              .required(VALIDATION_MESSAGES.fieldRequired),
            breed: Yup.string().required(VALIDATION_MESSAGES.fieldRequired),
            gender: Yup.string().required(VALIDATION_MESSAGES.fieldRequired),
            petType: Yup.string().required(VALIDATION_MESSAGES.fieldRequired),
            weight: Yup.string().required(VALIDATION_MESSAGES.fieldRequired),
          })
        ),
      }),
      ...(additionalInformationKeys.includes("vehicles") && {
        vehicles: Yup.array().of(
          Yup.object().shape({
            color: Yup.string().required(VALIDATION_MESSAGES.fieldRequired),
            make: Yup.string().required(VALIDATION_MESSAGES.fieldRequired),
            model: Yup.string().required(VALIDATION_MESSAGES.fieldRequired),
            tag: Yup.string().required(VALIDATION_MESSAGES.fieldRequired),
          })
        ),
      }),
      ...(additionalInformationKeys.includes("kid") && {
        kid: Yup.string()
          .nullable()
          .required(VALIDATION_MESSAGES.fieldRequired),
      }),
      ...(additionalInformationKeys.includes("knobRider") && {
        knobRider: Yup.string()
          .nullable()
          .required(VALIDATION_MESSAGES.fieldRequired),
      }),
      ...(additionalInformationKeys.includes("windowGuard") && {
        windowGuard: Yup.string()
          .nullable()
          .required(VALIDATION_MESSAGES.fieldRequired),
      }),
      ...(additionalInformationKeys.includes("marketingSource") && {
        marketingSource: Yup.string()
          .nullable()
          .required(VALIDATION_MESSAGES.fieldRequired),
      }),
      ...(additionalInformationKeys.includes("answers") && {
        answers: Yup.array().of(
          Yup.object().shape(
            {
              answerText: Yup.string().when("answerOption", {
                is: (answerOption) => !answerOption,
                then: Yup.string()
                  .max(255, "This field is limited to 255 characters")
                  .required(VALIDATION_MESSAGES.fieldRequired),
                otherwise: Yup.string(),
              }),
              answerOption: Yup.string().when("answerText", {
                is: (answerText) => !answerText,
                then: Yup.string().required(VALIDATION_MESSAGES.fieldRequired),
                otherwise: Yup.string(),
              }),
            },
            [["answerText", "answerOption"]]
          )
        ),
      }),
    });
  }
);

const HomeOwnerValidationObject = {
  mortgage: validMoney.required(VALIDATION_MESSAGES.fieldRequired),
  moveInDate: Yup.date().nullable().required(VALIDATION_MESSAGES.fieldRequired),
  moveOutDate: Yup.mixed()
    .nullable()
    .when("livesHere", {
      is: false,
      then: Yup.date()
        .nullable()
        .required(VALIDATION_MESSAGES.fieldRequired)
        .min(Yup.ref("moveInDate"), VALIDATION_MESSAGES.moveOutDate),
    }),
};

const RenterValidationObject = {
  monthlyRent: validMoney.required(VALIDATION_MESSAGES.fieldRequired),
  moveInDate: Yup.date().nullable().required(VALIDATION_MESSAGES.fieldRequired),
  moveOutDate: Yup.mixed()
    .nullable()
    .when("livesHere", {
      is: false,
      then: Yup.date()
        .nullable()
        .required(VALIDATION_MESSAGES.fieldRequired)
        .min(Yup.ref("moveInDate"), VALIDATION_MESSAGES.moveOutDate),
    }),
  paidMonthlyRent: validMoney.required(VALIDATION_MESSAGES.fieldRequired),
  landlordName: Yup.string()
    .nullable()
    .required(VALIDATION_MESSAGES.fieldRequired),
  landlordPhoneNumber: validPhone,
  landlordEmail: validEmail.required(VALIDATION_MESSAGES.fieldRequired),
};

const CampusorFamilyFriendsValidationObject = {
  moveInDate: Yup.date().nullable().required(VALIDATION_MESSAGES.fieldRequired),
  moveOutDate: Yup.mixed()
    .nullable()
    .when("livesHere", {
      is: false,
      then: Yup.date()
        .nullable()
        .required(VALIDATION_MESSAGES.fieldRequired)
        .min(Yup.ref("moveInDate"), VALIDATION_MESSAGES.moveOutDate),
    }),
};

const LivingSituationDescriptionValidationObject = {
  livingSituationDescription: Yup.string()
    .nullable()
    .required(VALIDATION_MESSAGES.fieldRequired),
};

export const ResidentialHistoryValidationSchema = Yup.array().of(
  Yup.lazy((residentialHistory) =>
    Yup.object().shape({
      address: validAddress.required(VALIDATION_MESSAGES.fieldRequired),
      city: Yup.string()
        .nullable()
        .required(VALIDATION_MESSAGES.fieldRequired)
        .min(3, "The City field must contain a minimum of 3 letters.")
        .max(50, "The City field must contain a maximum of 50 letters.")
        .matches(
          REGEX.lettersNumbersSpecialChars,
          "The City field can only contain letters and the allowed special characters: ['.', ' ', '-']"
        ),
      livesHere: Yup.bool()
        .nullable()
        .required(VALIDATION_MESSAGES.fieldRequired),
      livingSituation: Yup.string()
        .nullable()
        .required(VALIDATION_MESSAGES.fieldRequired),
      state: Yup.string()
        .nullable()
        .required(VALIDATION_MESSAGES.fieldRequired),
      zipcode: Yup.string()
        .nullable()
        .required(VALIDATION_MESSAGES.fieldRequired)
        .matches(REGEX.zipcode, "Invalid ZipCode."),
      ...(residentialHistory.livingSituation ===
        LIVING_SITUATION_TYPES.homeOwner.key && HomeOwnerValidationObject),
      ...(residentialHistory.livingSituation ===
        LIVING_SITUATION_TYPES.renter.key && RenterValidationObject),
      ...([
        LIVING_SITUATION_TYPES.campus.key,
        LIVING_SITUATION_TYPES.familyOrFriends.key,
      ].includes(residentialHistory.livingSituation) &&
        CampusorFamilyFriendsValidationObject),
      ...(residentialHistory.livingSituation ===
        LIVING_SITUATION_TYPES.other.key &&
        LivingSituationDescriptionValidationObject),
    })
  )
);

export const ReferencesValidationSchema = Yup.array().of(
  Yup.lazy((reference) => {
    const shouldBeRequired = Object.values(reference).some((value) => !!value);
    return shouldBeRequired
      ? Yup.object().shape({
          firstName: validFirstName.required(VALIDATION_MESSAGES.fieldRequired),
          lastName: validLastName.required(VALIDATION_MESSAGES.fieldRequired),
          email: validEmail.required(VALIDATION_MESSAGES.fieldRequired),
          relationship: Yup.string()
            .nullable()
            .required(VALIDATION_MESSAGES.fieldRequired),
        })
      : Yup.object().shape({
          firstName: validFirstName,
          lastName: validLastName,
          email: validEmail,
          relationship: Yup.string().nullable(),
        });
  })
);

export const ResidentialNonMatchingCriteriaNotesValidationSchema =
  Yup.mixed().test(
    "requiredResidentialHistoryDuration",
    "Minimal required residential history",
    function () {
      const {
        residentialHistory,
        requiredResidentialHistoryDuration,
        residentialNonMatchingCriteriaNotes,
      } = this.parent || {};
      if (
        !requiredResidentialHistoryDuration ||
        !requiredResidentialHistoryDuration.requiresResidentialHistoryDuration ||
        residentialNonMatchingCriteriaNotes
      ) {
        return true;
      }

      if (residentialHistory?.every((rh) => rh.livingSituation)) {
        const { residentialHistoryDuration, residentialHistoryRange } =
          requiredResidentialHistoryDuration;
        const residentialHistoryDates = residentialHistory.map((rh) => ({
          startDate: rh.moveInDate,
          endDate: rh.moveOutDate,
        }));

        return hasMinimalRequiredDays(
          residentialHistoryDates,
          residentialHistoryDuration,
          residentialHistoryRange
        );
      }
      return true;
    }
  );

export const EmploymentNonMatchingCriteriaNotesValidationSchema =
  Yup.mixed().test(
    "requiredEmploymentHistoryDuration",
    "Minimal required employment history",
    function () {
      const {
        currentEmployments,
        previousEmployments,
        futureEmployment,
        requiredEmploymentHistoryDuration,
        employmentNonMatchingCriteriaNotes,
      } = this.parent || {};
      if (
        !requiredEmploymentHistoryDuration ||
        !requiredEmploymentHistoryDuration.requiresEmploymentHistoryDuration ||
        employmentNonMatchingCriteriaNotes
      ) {
        return true;
      }

      const employments = [
        ...currentEmployments,
        ...previousEmployments,
        ...futureEmployment,
      ];

      const { employmentHistoryDuration, employmentHistoryRange } =
        requiredEmploymentHistoryDuration;
      const employmentHistoryDates = employments.map((emp) => ({
        startDate: emp.startDate,
        endDate: emp.endDate,
      }));

      return hasMinimalRequiredDays(
        employmentHistoryDates,
        employmentHistoryDuration,
        employmentHistoryRange
      );
    }
  );
