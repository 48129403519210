import React from "react";

import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import {
  useGetYardiPushOperationTracking,
  useGetYardiYardiActionTracking,
  useGetYardiVeroEvent,
} from "manager/hooks/api";

import DealExtraDetails from "./DealExtraDetails";

const DealExtraDetailsContainer = ({ backLink, ...props }) => {
  const { id: dealId } = useParams();

  const { yariOperationTracking, isLoading } =
    useGetYardiPushOperationTracking(dealId);

  const { yardiActionTracking, isLoadingActionTracking } =
    useGetYardiYardiActionTracking(dealId);

  const { yardiVeroEvent, isLoadingVeroEvent } = useGetYardiVeroEvent(dealId);

  return (
    <DealExtraDetails
      backLink={backLink}
      yariOperationTracking={yariOperationTracking}
      isLoadingOperationTracking={isLoading}
      yardiActionTracking={yardiActionTracking}
      isLoadingActionTracking={isLoadingActionTracking}
      yardiVeroEvent={yardiVeroEvent}
      isLoadingVeroEvent={isLoadingVeroEvent}
      {...props}
    />
  );
};

DealExtraDetailsContainer.propTypes = {
  backLink: PropTypes.object.isRequired,
};

export default DealExtraDetailsContainer;
