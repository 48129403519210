import React from "react";

import { useFormikContext } from "formik";

import { CheckboxWithUnorderedList } from "shared/components/Form";
import Icon from "shared/components/Icon";
import Settings from "shared/components/Settings";
import Tooltip from "shared/components/Tooltip";
import { RegularText, WeakText } from "shared/components/Typography";
import Well from "shared/components/Well";
import { SurveyModel } from "shared/interfaces/api";
import OptionItem from "shared/interfaces/api/optionItem";
import { conditionalItem } from "shared/utils/array";

import ApplicationSetupHeader from "../Header";
import ResidenceEmploymentDuration, {
  DEFAULT_DURATION_TYPE,
  DEFAULT_DURATION_VALUE,
} from "../ResidenceEmploymentDuration";
import { LabelIconContainer, StyledSkeleton as Skeleton } from "../styled";
import {
  getAllowManagersToViewSSNItem,
  getAssetsVerificationItem,
  getBlockApplicationSubmitIfNoIncomeItem,
  getCountyCriminalSearchItem,
  getConditionallyProceedItem,
} from "../utils";

import {
  applicantPersonaIdentification,
  applicantArgyleVerification,
  renterProfile,
  renterProfileLeasePreferences,
  renterProfileReferences,
  incomeAndAssetVerification,
  assetVerificationWhenLinkedEmployment,
  blockApplicationSubmitIfNoIncome,
  backgroundCreditCheck,
  allowManagerViewSSN,
  countyCriminalSearch,
  residentialHistoryDurationFlag,
  residentialHistoryDurationType,
  residentialHistoryDurationValue,
  employmentHistoryDurationFlag,
  employmentHistoryDurationType,
  employmentHistoryDurationValue,
  usesConditionallyProceed,
  verificationOfResidency,
} from "./constants";
import VorQuestionsTable from "./VorQuestionsTable";

interface ScreeningForApplicantsAndOccupantsProps {
  isArgyleEnabled: boolean;
  isPersonaEnabled: boolean;
  isCountyCriminalSearchEnabled: boolean;
  isVerificationOfResidencyEnabled: boolean;
  isCountyCriminalSearchSyncOptionsLoading: boolean;
  isConditionallyProceedEnabled: boolean;
  loading?: boolean;
  disabled?: boolean;
  countyCriminalSearchSyncOptions: OptionItem;
  isSurveyLoading: boolean;
  vorSurvey: SurveyModel;
  forSettingsLabel: string;
  toggleVor: Function;
}

const ScreeningForApplicantsAndOccupants = ({
  isArgyleEnabled,
  isPersonaEnabled,
  isCountyCriminalSearchEnabled,
  isConditionallyProceedEnabled,
  isVerificationOfResidencyEnabled,
  isCountyCriminalSearchSyncOptionsLoading,
  loading,
  disabled,
  countyCriminalSearchSyncOptions,
  isSurveyLoading,
  vorSurvey,
  forSettingsLabel,
  toggleVor,
}: ScreeningForApplicantsAndOccupantsProps) => {
  const { values, setFieldValue, setValues } = useFormikContext();

  const assetsVerificationItem = getAssetsVerificationItem(
    isArgyleEnabled,
    values,
    assetVerificationWhenLinkedEmployment,
    disabled
  );

  const blockApplicationSubmitIfNoIncomeItem =
    getBlockApplicationSubmitIfNoIncomeItem(
      values,
      blockApplicationSubmitIfNoIncome
    );

  return (
    <Settings
      header={
        <Skeleton loading={loading || isCountyCriminalSearchSyncOptionsLoading}>
          <ApplicationSetupHeader
            title="Screening For Applicants & Occupants"
            subTitle="Please select the screening level you would like to require from applicants."
            description="over 18 years old"
          />
        </Skeleton>
      }
      nodes={[
        ...conditionalItem(isPersonaEnabled, {
          key: 10,
          component: (
            <Skeleton loading={loading}>
              <CheckboxWithUnorderedList
                id="applicant-persona-identification"
                label="Identity Verification"
                onChange={(checked) => {
                  setFieldValue(applicantPersonaIdentification, checked);
                }}
                value={values[applicantPersonaIdentification]}
                disabled={disabled}
              />
            </Skeleton>
          ),
        }),
        ...conditionalItem(isArgyleEnabled, {
          key: 15,
          component: (
            <Skeleton loading={loading}>
              <CheckboxWithUnorderedList
                id="applicant-link-employment"
                label="Link Employment"
                onChange={(checked) => {
                  setFieldValue(applicantArgyleVerification, checked);
                }}
                value={values[applicantArgyleVerification]}
                disabled={disabled}
              />
            </Skeleton>
          ),
        }),
        {
          key: 20,
          component: (
            <Skeleton loading={loading}>
              <CheckboxWithUnorderedList
                id="income-assets"
                label="Income &amp; Balances Verification"
                value={values[incomeAndAssetVerification]}
                items={[
                  { label: "Income", id: "income" },
                  assetsVerificationItem,
                  {
                    label: "Block Applications Without Verified Income",
                    id: "block-application-submission-if-no-income",
                    disabled,
                    children: [blockApplicationSubmitIfNoIncomeItem],
                  },
                ]}
                onChange={(checked) => {
                  setFieldValue(incomeAndAssetVerification, checked);
                  if (checked === false) {
                    const assetsVerificationItemChildren =
                      assetsVerificationItem.children || [];
                    assetsVerificationItemChildren.forEach((child) => {
                      setFieldValue(child.id, false);
                    });
                    setFieldValue(
                      blockApplicationSubmitIfNoIncomeItem.id,
                      false
                    );
                  }
                }}
                onItemChildChange={(id, value) => {
                  setFieldValue(id, value);
                }}
                disabled={disabled}
              />
            </Skeleton>
          ),
        },
        {
          key: 30,
          component: (
            <Skeleton loading={loading}>
              <CheckboxWithUnorderedList
                id="background-credit-check"
                label="Background &amp; Credit check"
                value={values[backgroundCreditCheck]}
                disabled={disabled}
                onChange={(checked) => {
                  setFieldValue(backgroundCreditCheck, checked);
                }}
                onItemChildChange={(id, value) => {
                  setFieldValue(id, value);
                }}
                items={[
                  { label: "Credit Check", id: "credit-check" },
                  {
                    label: "Eviction Related Proceedings History",
                    id: "housing-court-records-history",
                  },
                  {
                    label: "Background Check",
                    id: "background-check",
                    disabled,
                    children: [
                      getAllowManagersToViewSSNItem(
                        allowManagerViewSSN,
                        values
                      ),
                      ...conditionalItem(
                        isConditionallyProceedEnabled,
                        getConditionallyProceedItem(
                          usesConditionallyProceed,
                          values
                        )
                      ),
                    ],
                  },
                  ...conditionalItem(
                    isCountyCriminalSearchEnabled,
                    getCountyCriminalSearchItem(
                      countyCriminalSearch,
                      countyCriminalSearchSyncOptions,
                      disabled
                    )
                  ),
                ]}
              />
            </Skeleton>
          ),
        },
        ...conditionalItem(isVerificationOfResidencyEnabled, {
          key: 35,
          component: (
            <Skeleton loading={loading}>
              <CheckboxWithUnorderedList
                id="applicant-vor"
                label={
                  <LabelIconContainer alignItems="center">
                    <RegularText>
                      Verification of Residency <WeakText>(VoR)</WeakText>
                    </RegularText>
                    <Tooltip
                      title={
                        <Well noBorder>
                          VERO will automatically send an email to each
                          applicant’s current landlord requesting them to take a
                          survey regarding their tenancy.
                        </Well>
                      }
                    >
                      <Icon.InfoIcon className="extra-info" />
                    </Tooltip>
                  </LabelIconContainer>
                }
                onChange={(checked) => toggleVor(checked, setFieldValue)}
                value={values[verificationOfResidency]}
                disabled={disabled}
              />
              {values[verificationOfResidency] && vorSurvey && (
                <VorQuestionsTable
                  isSurveyLoading={isSurveyLoading}
                  survey={vorSurvey}
                  disabled={disabled}
                  forSettingsLabel={forSettingsLabel}
                />
              )}
            </Skeleton>
          ),
        }),
        {
          key: 40,
          component: (
            <Skeleton loading={loading}>
              <CheckboxWithUnorderedList
                id="renter-profile-for-applicants"
                label="Renter Profile"
                value={values[renterProfile]}
                disabled
                items={[
                  {
                    id: "residential-history-duration",
                    label: "Residential History",
                    disabled,
                    children: [
                      {
                        id: residentialHistoryDurationFlag,
                        label: "Set Minimum History",
                        checked: values[residentialHistoryDurationFlag],
                        checkedContent: (
                          <ResidenceEmploymentDuration
                            durationTypeFieldName={
                              residentialHistoryDurationType
                            }
                            durationValueFieldName={
                              residentialHistoryDurationValue
                            }
                            disabled={disabled}
                          />
                        ),
                      },
                    ],
                  },
                  {
                    id: "employment-history-duration",
                    label: "Employment History",
                    disabled,
                    children: [
                      {
                        id: employmentHistoryDurationFlag,
                        label: "Set Minimum History",
                        checked: values[employmentHistoryDurationFlag],
                        checkedContent: (
                          <ResidenceEmploymentDuration
                            durationTypeFieldName={
                              employmentHistoryDurationType
                            }
                            durationValueFieldName={
                              employmentHistoryDurationValue
                            }
                            disabled={disabled}
                          />
                        ),
                      },
                    ],
                  },
                  {
                    id: "lease-preferences",
                    label: "Lease Preferences",
                    disabled: true,
                    children: [
                      {
                        id: renterProfileLeasePreferences,
                        label: "Always Require",
                        checked: values[renterProfileLeasePreferences],
                      },
                    ],
                  },
                  {
                    label: "Additional Information",
                    id: "additional-information",
                  },
                  { label: "Emergency Contacts", id: "emergency-contacts" },
                  {
                    id: "references",
                    label: "References (optional)",
                    disabled: true,
                    children: [
                      {
                        id: renterProfileReferences,
                        label: "Always Require",
                        checked: values[renterProfileReferences],
                      },
                    ],
                  },
                ]}
                onItemChildChange={(id, value) => {
                  if (id === residentialHistoryDurationFlag) {
                    setValues({
                      // @ts-ignore
                      ...values,
                      [residentialHistoryDurationFlag]: value,
                      [residentialHistoryDurationType]: DEFAULT_DURATION_TYPE,
                      [residentialHistoryDurationValue]: DEFAULT_DURATION_VALUE,
                    });
                  } else if (id === employmentHistoryDurationFlag) {
                    setValues({
                      // @ts-ignore
                      ...values,
                      [employmentHistoryDurationFlag]: value,
                      [employmentHistoryDurationType]: DEFAULT_DURATION_TYPE,
                      [employmentHistoryDurationValue]: DEFAULT_DURATION_VALUE,
                    });
                  } else {
                    setFieldValue(id, value);
                  }
                }}
              />
            </Skeleton>
          ),
        },
      ]}
    />
  );
};

export default ScreeningForApplicantsAndOccupants;
