import React from "react";

import { Modal } from "shared/components/Modal";
import useModal from "shared/hooks/useModal";

const AutoCreateTaskSubmittedModal = () => {
  const { currentModalContext } = useModal();
  const { title, propertyName, description } = currentModalContext;

  return (
    <Modal
      title={title}
      subtitle={
        <>
          Property: <strong>{propertyName}</strong>
        </>
      }
      submitButtonLabel="CONFIRM"
      cancelLinkLabel=""
    >
      <Modal.Body>
        <p>{description}</p>
      </Modal.Body>
    </Modal>
  );
};

export default AutoCreateTaskSubmittedModal;
