import { useQuery } from "react-query";

import { IntegrationProvider, NOTIFICATIONS } from "shared/config/constants";
import applicationApi from "shared/lib/api/applicationApi";
import sourcesApi from "shared/lib/api/sourcesApi";
import { isRenterApp } from "shared/utils/auth";
import { openNotification } from "shared/utils/ui";

const MARKETING_SOURCES = "getMarketingSources";

export const useGetMarketingSources = (
  provider,
  propertyId,
  onlyYardiUnmapped
) => {
  const {
    data: marketingSources,
    isLoading,
    isFetching,
  } = useQuery(
    [MARKETING_SOURCES, propertyId, onlyYardiUnmapped],
    () => sourcesApi.getMarketingSources({ propertyId, onlyYardiUnmapped }),
    {
      enabled: Boolean(propertyId) && provider !== IntegrationProvider.realPage,
      refetchOnMount: "always",
      onError: () => {
        openNotification(
          "Failed to get marketing sources.",
          NOTIFICATIONS.error
        );
      },
    }
  );

  return {
    marketingSources,
    isLoading: isLoading || isFetching,
  };
};

/**
 * Conditionally fetches the marketing sources based on the current active app.
 * Uses different queries for the renter and manager apps.
 * When the renter app is active it expects an applicationId.
 * Otherwise, when the manager app is active it expects a propertyId.
 * It uses the corresponding queries for the api call.
 */
export const useGetMarketingSourcesByApp = ({ applicationId, propertyId }) => {
  const isRenter = isRenterApp();
  const params = isRenter ? { applicationId } : { propertyId };
  const apiCall = isRenter
    ? applicationApi.getApplicationMarketingSources
    : sourcesApi.getMarketingSources;

  const {
    data: marketingSources,
    isLoading,
    isFetching,
  } = useQuery([MARKETING_SOURCES, params, isRenter], () => apiCall(params), {
    enabled: isRenter
      ? Boolean(params.applicationId)
      : Boolean(params.propertyId),
    refetchOnMount: "always",
    onError: () => {
      openNotification("Failed to get marketing sources.", NOTIFICATIONS.error);
    },
  });

  return {
    marketingSources,
    isLoading: isLoading || isFetching,
  };
};
