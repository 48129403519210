import React from "react";

import Icon from "shared/components/Icon";

export interface ExpandRowIconProps {
  expanded: boolean;
  onExpand: (record: any, event) => void;
  record: any;
}

const ExpandRowIcon = ({ expanded, record, onExpand }: ExpandRowIconProps) => {
  const ChevronIcon = expanded
    ? Icon.ArrowUpWithBackgroundIcon
    : Icon.ArrowDownWithBackgroundIcon;

  return (
    <ChevronIcon
      className="chevron-icon"
      onClick={(event) => onExpand(record, event)}
      data-testid="chevron-icon"
      style={{ height: 35, width: 35 }}
    />
  );
};

export default ExpandRowIcon;
