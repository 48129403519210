import { useCallback, useEffect, useMemo, useState } from "react";

import dayjs, { Dayjs } from "dayjs";

import minMax from "dayjs/plugin/minMax";
import { Moment } from "moment";

import ROUTES from "renter/config/routes";
import {
  useCreateMagicDeal,
  useGetApplicationInvitation,
} from "renter/hooks/api";
import {
  getLroConfig,
  getMagicPortalPropertyUnit,
  LroConfigResponse,
  LroSource,
} from "renter/lib/magicPortalApi";
import { getStartingStep } from "renter/utils/application-routing.utils";
import { transformRoute } from "shared/utils/routing";

import { LeaseRentOptionsProps } from "./component";
import { LEASE_LOAD_DAYS } from "./constants";
import {
  DayRange,
  ListType,
  PriceRange,
  PropertyUnit,
  Sort,
  Unit,
} from "./types";
import { UnitListingService } from "./unit-listing-service";
import { DayKey } from "./utils";

dayjs.extend(minMax);

export const useLeaseRentOptions = ({
  propertyId,
  firstName,
  email: initEmail,
  isTestMode,
  leaseStart,
  leaseMonths,
  appId,
}: LeaseRentOptionsProps) => {
  const _service = useMemo(() => new UnitListingService(isTestMode), []);
  const [email, setEmail] = useState(initEmail || "");
  const [valid, setValid] = useState(!!appId);
  const [loading, setLoading] = useState(false);
  const [range, setRange] = useState<Partial<DayRange>>({});
  const priceRanges: PriceRange[] = [
    { key: "0", name: "Any" },
    { key: "1", name: "$1-2K", min: 1000, max: 2000 },
    { key: "2", name: "$2-3K", min: 2000, max: 3000 },
    { key: "3", name: "$3K +", min: 3000 },
  ];
  const [priceRange, setPriceRange] = useState(priceRanges[0]);
  const [leaseTerms, setLeaseTerms] = useState<number[]>([]);
  const [leaseTerm, setLeaseTerm] = useState(leaseMonths || leaseTerms[0]);
  const [isFlexibleStart, setIsFlexibleStart] = useState(true);
  const [hideFlexibleStart, setHideFlexibleStart] = useState(false);
  const [hideListToggle, setHideListToggle] = useState(false);
  const [isFlexibleTerm, setIsFlexibleTerm] = useState(true);
  const [units, setUnits] = useState([] as Unit[]);
  const [unit, setUnit] = useState<Unit>(null);
  const [unitsByDay, setUnitsByDay] = useState(new Map<DayKey, Unit[]>());
  const [sort, setSort] = useState(Sort.price);
  const [unitFqlVrn, setUnitFqlVrn] = useState(null);
  const [confirmed, setConfirmed] = useState<string | undefined>();
  const [dealCreated, setDealCreated] = useState<boolean>();
  const [listType, setListType] = useState(ListType.calendar);
  const { createMagicDeal, isLoading: submitting } = useCreateMagicDeal();

  const toggleListType = () =>
    listType === ListType.calendar
      ? setListType(ListType.list)
      : setListType(ListType.calendar);

  const setPriceRangeByKey = (key: string) => setPriceRange(priceRanges[+key]);
  const setStart = (start: Dayjs) =>
    setRange({ start, end: start.add(LEASE_LOAD_DAYS, "day") });
  const setEnd = (end: Dayjs) => {
    if (!range.end || end.isAfter(range.end)) setRange({ ...range, end });
  };

  const [property, setProperty] = useState<PropertyUnit>(
    !isTestMode
      ? {
          availableDate: dayjs().startOf("day"),
          city: "",
          property: "",
          unit: "",
          stateCode: "",
          street: "",
          zipCode: "",
        }
      : {
          availableDate: dayjs().startOf("day"),
          city: "Brooklyn",
          property: "Big Prop",
          unit: "Unit 001",
          stateCode: "NY",
          street: "85 West Summer Road",
          zipCode: "11237",
        }
  );

  const [loadingProperty, setLoadingProperty] = useState(false);

  useEffect(() => {
    if (isTestMode) {
      setLoadingProperty(true);
      setTimeout(() => setLoadingProperty(false), 1000);
    }
  }, []);

  const title = useMemo(
    () => `${property?.property ? `${property.property} - ` : ""}Lease Options`,
    [property]
  );

  const header = firstName
    ? `${firstName}, you're invited to apply for`
    : `You're invited to apply for`;

  const [config, setConfig] = useState<LroConfigResponse>({
    // source: LroSource.LRO,
  });

  const [maxDate, setMaxDate] = useState<Dayjs | undefined>();

  useEffect(() => {
    if (config.source === LroSource.LRO) {
      setIsFlexibleStart(false);
      setHideFlexibleStart(true);
      setHideListToggle(true);
      setListType(ListType.list);
      const window = config.lroWindow || 14;
      const max = dayjs
        .max(property.availableDate, dayjs())
        .startOf("day")
        .add(window - 1, "day");
      setMaxDate(max);
    }
  }, [config, property]);

  useEffect(() => {
    if (!isTestMode) {
      setLoadingProperty(true);
      getLroConfig(propertyId)
        .then((lro) => setConfig(lro || {}))
        .catch();
      getMagicPortalPropertyUnit({
        propertyHashId: propertyId.propertyId,
        unitHashId: propertyId.unitId,
      })
        .then((unit) => {
          setUnitFqlVrn(unit?.fqlVrn);
          const today = dayjs().startOf("day");

          const defaultStartDay =
            Boolean(leaseStart) && dayjs(leaseStart).isValid()
              ? dayjs(leaseStart).startOf("day")
              : today;

          const nextUnitListingDate = unit.nextUnitListingDate
            ? dayjs(unit.nextUnitListingDate)
            : null;

          if (!nextUnitListingDate) {
            window.location.href = transformRoute(
              `${ROUTES.magicLinkGetStarted}?skipLro=true`,
              { companyHashId: propertyId.unitId }
            );
            return;
          }

          const available = unit.unitAvailableOn
            ? dayjs(unit.unitAvailableOn).startOf("day")
            : nextUnitListingDate;
          setProperty({
            availableDate: available,
            property: unit.propertyName,
            city: unit.city,
            stateCode: unit.state,
            street: unit.address,
            unit: `Unit ${unit.name}`,
            zipCode: unit.zipcode,
            nextUnitListingDate,
          });
          setStart(
            nextUnitListingDate && nextUnitListingDate.isAfter(defaultStartDay)
              ? nextUnitListingDate
              : defaultStartDay
          );
        })
        .finally(() => setLoadingProperty(false));
    }
  }, [propertyId.propertyId, propertyId.unitId]);

  useEffect(() => {
    if (!range.start || !range.end) return;
    setLoading(true);
    _service
      .units({
        property: propertyId,
        start: range.start!,
        end: range.end!,
        flexibleTerm: isFlexibleTerm,
        priceMin: priceRange?.min,
        priceMax: priceRange?.max,
        term: leaseTerm,
        sort,
        unitFqlVrn
      })
      .then(({ units, unitsByDay, terms }) => {
        setUnits(units);
        setUnitsByDay(unitsByDay);
        setLeaseTerms(Array.from(terms).sort((a, b) => a - b));
      })
      .finally(() => setLoading(false));
  }, [
    range.start,
    range.end,
    isFlexibleStart,
    leaseTerm,
    isFlexibleTerm,
    priceRange,
    sort,
    unitFqlVrn
  ]);

  // Show list view if isFlexibleStart is unchecked because we want to keep showing multiple days and navigation on the grid view.
  useEffect(() => {
    if (!isFlexibleStart) setListType(ListType.list);
  }, [isFlexibleStart]);

  const { application } = useGetApplicationInvitation(appId, !!appId);

  const onSubmit = () => {
    (isTestMode
      ? Promise.resolve({ data: { link: window.location.href } })
      : createMagicDeal({
          application: appId,
          companyHashId: unit.range.hashedId,
          dealType: "SCREENING",
          email,
          isQrCode: false,
          externalUrl: window.location.href,
          userAgent: window.navigator.userAgent,
          leaseStart: unit.day.format("YYYY-MM-DD"),
          moveInDate: unit.day.format("YYYY-MM-DD"),
          leaseMonths: unit.termLength,
        })
    ).then((response) => {
      if (application) {
        const redirectRoute = getStartingStep(application.steps);
        window.location.href = transformRoute(redirectRoute, {
          id: application.id,
        });
      } else {
        setConfirmed(response?.data?.link);
        setDealCreated(true);
      }
    });
  };

  const isDisabledDate = useCallback(
    (current: Moment | null) => {
      if (!current) return true;
      const cur = dayjs(current.toDate()).startOf("day");
      const min = property.availableDate.startOf("day");
      const max = maxDate;
      return cur.isBefore(min) || (max && cur.isAfter(max));
    },
    [property, maxDate]
  );

  return {
    loading,
    leaseTerm,
    setLeaseTerm,
    leaseTerms,
    priceRange,
    setPriceRangeByKey,
    priceRanges,
    setStart,
    setEnd,
    range,
    isFlexibleStart,
    setIsFlexibleStart,
    isFlexibleTerm,
    setIsFlexibleTerm,
    units,
    unitsByDay,
    email,
    setEmail,
    listType,
    toggleListType,
    sort,
    setSort,
    unit,
    setUnit,
    confirmed,
    setConfirmed,
    dealCreated,
    valid,
    setValid,
    onSubmit,
    submitting,
    property,
    loadingProperty,
    title,
    application: appId,
    header,
    hideListToggle,
    hideFlexibleStart,
    isDisabledDate,
    selectionValidityLifespan: config?.selectionValidityLifespan,
  };
};
