import React, { useEffect, useState } from "react";

import { Menu } from "antd";
import { matchPath, useHistory, useLocation } from "react-router-dom";

import { useDeviceType } from "shared/hooks";

import { MenuItemProps, SidebarItem, SidebarProps } from "../interfaces";

import { StyledSider } from "./styled";
import {
  MenuItem,
  SubMenu,
  extendItems,
  getFlattenedItemsByKey,
} from "./utils";

const Sidebar = ({
  items,
  groupedRoutes = {},
  afterItemActivate,
  ...siderProps
}: SidebarProps) => {
  const history = useHistory();
  const { isTablet } = useDeviceType();
  const [hoveredItem, setHoveredItem] = useState();
  const [subMenuOpened, setSubMenuOpened] = useState(false);
  const resolvedItems = extendItems(items);
  const flattenedItemsByKey = getFlattenedItemsByKey(resolvedItems);

  const getItemByKey = (key: string): SidebarItem | undefined =>
    flattenedItemsByKey[key];

  const getKeyByRoute = (route: string): string => {
    return Object.keys(groupedRoutes).find((key) =>
      matchPath(route, {
        path: Object.values(groupedRoutes[key]),
        exact: true,
      })
    );
  };

  const activeRoute = useLocation().pathname;
  const activeItemKey = getKeyByRoute(activeRoute);
  const activeItem = getItemByKey(activeItemKey);
  const selectedKeys = activeItemKey ? [String(activeItemKey)] : undefined;
  const isCompactMode = isTablet && !subMenuOpened;
  const siderClass = isCompactMode ? "compact-mode" : "";
  const defaultOpenKeys =
    activeItem?.parentKey && !isTablet ? [activeItem?.parentKey] : [];

  const [openKeys, setOpenKeys] = useState(defaultOpenKeys);

  const activateItem = (arg) => {
    const { key } = arg;
    const clickedItem = getItemByKey(key);
    if (clickedItem) {
      history.push(clickedItem.route);
      if (afterItemActivate) {
        afterItemActivate();
      }
    }
  };

  const onSubmenuOpen = (arg) => {
    setSubMenuOpened(arg?.length > 0);
    setOpenKeys(arg);

    // NOTE: If the newly opened submenu has a single item, we should
    // activate it immediately
    const newKeys = (arg || []).filter((key) => !openKeys.includes(key));
    const openedGroup = getItemByKey(newKeys[0]);
    if (openedGroup?.items?.length === 1) {
      const firstItem = openedGroup?.items?.[0];
      if (firstItem?.route) {
        activateItem(firstItem);
      }
    }
  };

  // NOTE: sometimes the Sidebar doesn't unmount-mount, because it's the same
  // structure of the nodes. In that case, when the route changes, but the Sidebar
  // doesn't unmount-mount, we have to reinitialize the "openKeys" list.
  useEffect(() => {
    setOpenKeys(defaultOpenKeys);
  }, [activeItemKey]);

  const getItemParams = (item: SidebarItem): MenuItemProps => ({
    key: item.key,
    item,
    showText: !isCompactMode,
    setHoveredItem,
    hoveredItem,
    activeItemKey,
  });

  return (
    <StyledSider {...siderProps} className={siderClass}>
      <Menu
        mode="inline"
        selectedKeys={selectedKeys}
        openKeys={openKeys}
        onOpenChange={onSubmenuOpen}
        onClick={activateItem}
      >
        {resolvedItems.map((item) =>
          // NOTE: If the item has more than one subitem, we should render a SubMenu
          item.items?.length > 0 ? (
            item.items?.length < 2 ? (
              <MenuItem
                {...getItemParams({ ...item.items[0], icon: item.icon })}
              />
            ) : (
              <SubMenu {...getItemParams(item)} />
            )
          ) : (
            <MenuItem {...getItemParams(item)} />
          )
        )}
      </Menu>
    </StyledSider>
  );
};

export default Sidebar;
