import React from "react";

import capitalize from "lodash/capitalize";
import { useHistory } from "react-router-dom";

import { InviteLeadToApplyModal } from "manager/components/Prescreening";
import ROUTES from "manager/config/routes";
import { useUpdateLeadArchiveStatus, useReinviteLead } from "manager/hooks/api";
import Icon from "shared/components/Icon";
import ConfirmationModal from "shared/components/Modals/ConfirmationModal";
import { RegularText, SmallText } from "shared/components/Typography";
import {
  BREAKPOINT_TYPES,
  DEAL_UI_STATUS_CODES,
} from "shared/config/constants";
import { usePermissions } from "shared/hooks";
import useModal from "shared/hooks/useModal";
import useWindowSize from "shared/hooks/useWindowSize";
import { getFullnameData } from "shared/utils/applicant";
import { transformRoute } from "shared/utils/routing";
import {
  formatDateTime,
  replaceNegativeWithLeftoverSpace,
  formatDate,
} from "shared/utils/ui";

import { Lead } from "./interfaces";
import { ActionLinkCustom, ArchivedColumn } from "./styled";

const STATUSES_WITH_REINVITE_ALLOWED = [
  DEAL_UI_STATUS_CODES.invitesSent,
  DEAL_UI_STATUS_CODES.applicationsIncomplete,
];

const getColumnWidths = (tableWidth, breakpoint) => {
  let columnWidths = [];
  if (tableWidth) {
    if (BREAKPOINT_TYPES.xs === breakpoint) {
      columnWidths = [];
    } else if (BREAKPOINT_TYPES.sm === breakpoint) {
      columnWidths = [-1, undefined, undefined, undefined, 210];
    } else if (BREAKPOINT_TYPES.md === breakpoint) {
      columnWidths = [-1, -1, undefined, 150, 150, 136];
    } else if (BREAKPOINT_TYPES.lg === breakpoint) {
      columnWidths = [-1, -1, 170, 150, 180, 150];
    } else if (BREAKPOINT_TYPES.xl === breakpoint) {
      columnWidths = [-1, -1, 220, 230, 220, 150];
    } else if (BREAKPOINT_TYPES.xxl === breakpoint) {
      columnWidths = [-1, -1, 220, 300, 220, 150];
    } else if (BREAKPOINT_TYPES.xxxl === breakpoint) {
      columnWidths = [-1, -1, -1, -1, 250, 150];
    }
  }

  return replaceNegativeWithLeftoverSpace(columnWidths, tableWidth);
};

const useLeadsTable = (tableWidth, breakpoint) => {
  const history = useHistory();
  const { openModalDialog } = useModal();
  const { reinviteLead } = useReinviteLead();
  const { canInviteLead, canInviteLeadsToApply } = usePermissions();
  // NOTE: Listen to the window-size changes to react properly when calculating the columns width
  useWindowSize();

  const getActionLink = (lead) => {
    const {
      dealUiStatus,
      dealId,
      isLeadConverted,
      screeningDealId,
      isArchived,
    } = lead;
    let actionLink = null;

    // NOTE: We don't show any action link if the Lead is archived.
    if (isArchived) {
      return null;
    }

    if (STATUSES_WITH_REINVITE_ALLOWED.includes(dealUiStatus)) {
      const { fullNameOrNA } = getFullnameData(lead);

      if (canInviteLead) {
        const resendInvite = () => {
          const context = {
            title: "Resend invite to the lead?",
            subtitle: (
              <>
                <RegularText weak key="1">
                  Lead:{" "}
                </RegularText>
                <RegularText key="2">{fullNameOrNA}</RegularText>
              </>
            ),
            message:
              "Resending an invite will re-invite the lead to prequalify",
            description: (
              <SmallText light>
                Last invite sent on {formatDateTime(lead.invitedDate)}
              </SmallText>
            ),
            noDescriptionIcon: true,
            submitButtonLabel: "Resend",
            onConfirm: () => {
              return reinviteLead(dealId);
            },
          };
          openModalDialog(ConfirmationModal, context);
        };

        actionLink = (
          <ActionLinkCustom
            onClick={resendInvite}
            data-testid={`resend-lead-invite-${dealId}`}
          >
            RESEND INVITE
          </ActionLinkCustom>
        );
      }
    } else if (DEAL_UI_STATUS_CODES.submitted === dealUiStatus) {
      const inviteLeadToApply = () => {
        history.push(
          transformRoute(ROUTES.lead, {
            id: dealId,
          })
        );
        openModalDialog(InviteLeadToApplyModal, { lead });
      };

      if (isLeadConverted) {
        actionLink = (
          <ActionLinkCustom
            to={transformRoute(ROUTES.deal, { id: screeningDealId })}
          >
            VIEW APPLICATION
          </ActionLinkCustom>
        );
      } else if (canInviteLeadsToApply) {
        actionLink = (
          <ActionLinkCustom
            onClick={inviteLeadToApply}
            data-testid={`invite-lead-to-apply-${dealId}`}
          >
            INVITE TO APPLY
          </ActionLinkCustom>
        );
      }
    }
    return actionLink;
  };

  const getLeadsStatus = ({
    dealStatus,
    isLeadConverted,
    convertedAt,
    isArchived,
    lastArchivedAt,
  }: Lead) => {
    let status: React.ReactNode | string = "";
    if (isArchived) {
      status = (
        <ArchivedColumn data-testid="lead-status-archived">
          <Icon.ArchivedApplication />
          Archived on {formatDate(lastArchivedAt)}
        </ArchivedColumn>
      );
    } else if (DEAL_UI_STATUS_CODES.invitesSent === dealStatus) {
      status = "Invite Sent";
    } else if (DEAL_UI_STATUS_CODES.applicationsIncomplete === dealStatus) {
      status = "Partially Complete";
    } else if (DEAL_UI_STATUS_CODES.submitted === dealStatus) {
      if (isLeadConverted) {
        status = (
          <>
            <RegularText className="converted-lead-status">
              Invited to apply
            </RegularText>
            <SmallText light>On: {formatDateTime(convertedAt)}</SmallText>
          </>
        );
      } else {
        status = "Screening Complete";
      }
    }
    return status;
  };

  const columnWidths = getColumnWidths(tableWidth, breakpoint);

  return {
    getActionLink,
    getLeadsStatus,
    columnWidths,
  };
};

const useLeadDropdownItems = ({ lead, downloadPdf }) => {
  const { openModalDialog } = useModal();
  const { updateLeadArchiveStatus } = useUpdateLeadArchiveStatus();

  const { canBeArchived, isArchived, deal, applicationSummary } = lead || {};

  const dropdownItems = [];

  if (applicationSummary?.isSubmitted) {
    dropdownItems.push({
      key: "DOWNLOAD_LEAD_PDF",
      label: "Download Lead PDF",
      onClick: downloadPdf,
    });
  }

  if (canBeArchived?.value) {
    const actionLabel = isArchived ? "unarchive" : "archive";
    const title = `${capitalize(actionLabel)} Lead`;
    const message = `Confirm you would like to ${actionLabel} this lead. Select the "Show archived leads" filter to include archived leads within your table view.`;

    const context = {
      title,
      message,
      noDescriptionIcon: true,
      onConfirm: () => {
        return updateLeadArchiveStatus({
          id: deal,
          isArchived: !isArchived,
        });
      },
    };

    dropdownItems.push({
      key: "ARCHIVE_UNARCHIVE_LEAD",
      label: title,
      onClick: () => openModalDialog(ConfirmationModal, context),
    });
  }

  return dropdownItems;
};

export { useLeadsTable, useLeadDropdownItems };
