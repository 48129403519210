import _ from 'lodash'
import { useState } from 'react'
import { buildClassName } from 'shared/utils/vega'
import { UpIcon, DownIcon } from 'shared/components/vega/icons'

function isSorted (key, sort) {
  return _.trim(sort, '-') === key
}

function isDesc (sort) {
  return _.startsWith(sort, '-')
}

function setDesc (key) {
  return `-${_.trim(key, '-')}`
}

function invertSort (key, sort) {
  return _.chain([!_.startsWith(sort, '-') && '-', key]).compact().join('').value()
}

const Table = ({ loading, columns, dataset, minHeight = undefined, maxHeight = undefined, sort = undefined, onSort = undefined, onNext = undefined, onRowClick = undefined }) => {
  const [hoveredItem, setHoveredItem] = useState(null)

  const noResults = _.isEmpty(dataset) && !loading

  return (
    <div className='vega table'>
      <div
        className='vega grid table'
        style={{
          gridTemplateRows: 'max-content',
          gridTemplateColumns: _.chain(columns).castArray().map('size').join(' ').value(),
          minHeight: noResults ? 0 : (minHeight || '10rem'),
          maxHeight,
          overflowY: 'auto'
        }}
        onScroll={(event: any) => {
          if (onNext && (event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight + 5)) {
            onNext()
          }
        }}
        onMouseLeave={() => setHoveredItem(null)}
      >
        {_.map(columns, col => {
          const isSortedCol = col?.sort && isSorted(col?.sort, sort)

          return (
            <header
              key={_.kebabCase(col?.title)}
              className={buildClassName(['vega sticky top', col?.sort && 'sortable', isSortedCol && 'sorted', isSortedCol && (isDesc(sort) ? 'desc' : 'asc')])}
            >
              <span className='vega flex no-gap centered spread'>
                <span
                  className='vega button label'
                  onClick={col?.sort ? () => onSort(isSortedCol ? invertSort(col?.sort, sort) : col?.sort) : null}
                >
                  {col?.title}
                </span>

                {col?.sort && <div className='vega flex no-gap'>
                  <UpIcon className='vega icon button asc' onClick={() => onSort(col?.sort)} />
                  <DownIcon className='vega icon button desc' onClick={() => onSort(setDesc(col?.sort))} />
                </div>}
              </span>
            </header>
          )
        })}

        {_.chain(dataset).map(item => _.map(columns, col => {
          const isSortedCol = col?.sort && isSorted(col?.sort, sort)
          const isHovered = item?.id === hoveredItem

          return (
            <div
              key={_.chain([item?.id, col?.title]).compact().join('-').kebabCase().value()}
              className={buildClassName(['vega', isSortedCol && 'sort', isHovered && 'hover'])}
              onClick={onRowClick ? onRowClick(item) : null}
              onMouseEnter={() => setHoveredItem(item?.id)}
            >
              {col?.render(item)}
            </div>
          )
        })).flattenDeep().compact().value()}
      </div>

      {noResults && <div className='vega content'>
        <p className='vega info centered'>No Results Found.</p>
      </div>}

      <footer className='vega flex spread'>
        {loading && (
          <div>
            <span className='vega loader inline icon' />
            <span className='vega info label'>loading more results</span>
          </div>
        )}

        {!loading && (onNext ? <div className='vega button label' onClick={onNext}>scroll or click for more results</div> : <div className='vega info label'>no more results</div>)}
        {<div className='vega info label'>{_.size(dataset)}{onNext ? '+' : ''} results found</div>}
      </footer>
    </div>
  )
}

export default Table
