import styled from "@emotion/styled";

import { Modal } from "shared/components/Modal";
import { MEDIA_QUERIES } from "shared/config/constants";

const Subtitle = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 17px;
  font-weight: 500;
  line-height: 22px;
  ${MEDIA_QUERIES.w.ltMd} {
    flex-direction: column;
  }
`;

const HeaderIcon = styled.div`
  width: 58px;
  margin-bottom: 10px;
`;

const Description = styled.div`
  text-align: center;
`;

const ModalBody = styled(Modal.Body)`
  padding: 25px 48px 30px 48px;
`;

export { Subtitle, HeaderIcon, Description, ModalBody };
