import { createAction } from "redux-actions";

import * as types from "./types";

export const receiveProperty = createAction(
  types.RECEIVE_PROPERTY,
  ({ property }) => ({ property })
);

export const receiveProperties = createAction(
  types.RECEIVE_PROPERTIES,
  ({ properties }) => ({ properties })
);
