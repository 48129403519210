import React from "react";

import { useParams, useHistory } from "react-router-dom";

import useVerifyEmailTemplatesAccess from "manager/components/EmailTemplates/useVerifyEmailTemplatesAccess";
import {
  useCreateCustomEmailConfiguration,
  useGetCustomEmailConfiguration,
  useUpdateCustomEmailConfiguration,
  useGetEmailTemplatesVariables,
  useGetDefaultEmailConfigurationById,
} from "manager/hooks/api";
import LoaderContainer, {
  BigLoadingSpinner,
} from "shared/components/LoaderContainer";

import { getEmailInitialConfiguration, getEmailSubmitValues } from "./config";
import EmailBuilderForm from "./EmailBuilderForm";

const EmailBuilderContainer = () => {
  const { templateId, defaultConfigurationId: defaultConfigurationIdParam } =
    useParams();
  const { push } = useHistory();

  const id = templateId ? Number(templateId) : null;
  const isEditMode = Boolean(id);

  const { canCustomizeEmails } = useVerifyEmailTemplatesAccess({
    pageName: "Email Builder ",
  });

  const { customEmailConfiguration, isCustomConfigurationLoading } =
    useGetCustomEmailConfiguration(id, { enabled: canCustomizeEmails });

  const defaultConfigurationId = isEditMode
    ? customEmailConfiguration?.defaultConfigurationId
    : Number(defaultConfigurationIdParam);

  const { defaultEmailConfiguration, isDefaultConfigurationLoading } =
    useGetDefaultEmailConfigurationById(defaultConfigurationId, {
      enabled: canCustomizeEmails,
    });

  const { createCustomEmailConfiguration } =
    useCreateCustomEmailConfiguration();

  const { updateCustomEmailConfiguration } =
    useUpdateCustomEmailConfiguration();

  const { emailVariables, isEmailVariablesLoading } =
    useGetEmailTemplatesVariables();

  const isPageLoading =
    isDefaultConfigurationLoading ||
    isCustomConfigurationLoading ||
    isEmailVariablesLoading;

  const initialConfiguration = getEmailInitialConfiguration({
    defaultEmailConfiguration,
    customEmailConfiguration,
    emailVariables,
    isEditMode,
  });

  const saveChanges = (values) => {
    const data = getEmailSubmitValues(values, isEditMode);

    if (isEditMode) {
      return updateCustomEmailConfiguration(data);
    }

    return createCustomEmailConfiguration(data);
  };

  const onSaveChangesSuccess = (response, navigateTo) => {
    const state = !isEditMode
      ? {
          newEmailTemplateId: response.id,
          newEmailTemplateDefaultConfigId: defaultConfigurationId,
        }
      : undefined;

    push(navigateTo, state);
  };

  return (
    <LoaderContainer loading={isPageLoading} Loader={<BigLoadingSpinner />}>
      <EmailBuilderForm
        saveChanges={saveChanges}
        onSaveChangesSuccess={onSaveChangesSuccess}
        initialConfiguration={initialConfiguration}
        isFormDisabled={!canCustomizeEmails}
      />
    </LoaderContainer>
  );
};

export default EmailBuilderContainer;
