import React from "react";

import capitalize from "lodash/capitalize";
import { Helmet } from "react-helmet";

import Container from "shared/components/Container";
import { BackLink } from "shared/components/Links";
import PageTitle from "shared/components/PageTitle";

import {
  alertsTablePropTypes,
  alertsTableDefaultProps,
} from "../shared/yardi.config";

import YardiAlertsTable from "./YardiAlertsTable";

const YardiAlerts = ({
  alerts,
  properties,
  filters,
  currentAlertClass,
  currentYardiPropertyId,
  currentResolved,
  setCurrentAlertClass,
  setCurrentYardiPropertyId,
  setCurrentResolved,
  clearFilters,
  isTableLoading,
  currentPagination,
  setCurrentPagination,
  totalCount,
  isIntegrationsAdmin,
  backLink,
  provider,
}) => {
  const title = capitalize(provider);
  return (
    <Container expand>
      <Helmet>
        <title>{title} updates</title>
      </Helmet>
      {backLink && (
        <BackLink.Container>
          <BackLink {...backLink} />
        </BackLink.Container>
      )}
      <PageTitle.Container>
        <PageTitle>{title} updates</PageTitle>
        <PageTitle.Subtitle>
          Please review the updates and confirm them with actions where needed
        </PageTitle.Subtitle>
      </PageTitle.Container>
      <YardiAlertsTable
        alerts={alerts}
        properties={properties}
        filters={filters}
        currentAlertClass={currentAlertClass}
        currentYardiPropertyId={currentYardiPropertyId}
        currentResolved={currentResolved}
        setCurrentAlertClass={setCurrentAlertClass}
        setCurrentYardiPropertyId={setCurrentYardiPropertyId}
        setCurrentResolved={setCurrentResolved}
        clearFilters={clearFilters}
        isTableLoading={isTableLoading}
        currentPagination={currentPagination}
        setCurrentPagination={setCurrentPagination}
        totalCount={totalCount}
        isIntegrationsAdmin={isIntegrationsAdmin}
        provider={provider}
      />
    </Container>
  );
};

YardiAlerts.propTypes = alertsTablePropTypes;

YardiAlerts.defaultProps = alertsTableDefaultProps;

export default YardiAlerts;
