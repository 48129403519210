import React from "react";
import Icon from "shared/components/Icon";
import { ImageContainer } from './styled';

interface ImageLinkProps {
  imageUrl: string;
  width?: string;
  height?: string;
  color?: string;
}

const ImageLink = ({
  imageUrl,
  width='20px',
  height='20px',
  color
}: ImageLinkProps) => {
  return (
    <ImageContainer
      target="_blank"
      rel="noreferrer"
      href={imageUrl}
      width={width}
      height={height}
      color={color}
    >
      <Icon.EmptyImageIcon />
    </ImageContainer>
  )
}

export default ImageLink;
