import React from "react";

import { Field, Formik } from "formik";

import styled from "styled-components";

import ApplicantsCheckList from "shared/components/ApplicantsCheckList";
import { Button, BUTTON_SIZES } from "shared/components/Button";
import FlexContainer from "shared/components/FlexContainer";

import { RadioGroup, TextArea, UploadWall } from "shared/components/Form";
import Icon from "shared/components/Icon";
import { UnderlineLink } from "shared/components/Links";
import { Popover, PopoverContent } from "shared/components/Popover";
import Spacer from "shared/components/Spacer";
import { LargeTitle, RegularText } from "shared/components/Typography";
import { VeroModal } from "shared/components/vero-modal";

import { FormGroupsCss } from "shared/styles/commonStyles";

import { Subtitle } from "../styled";
import { DealSummaryWithProperty } from "../types";

import { useCancelDealModal } from "./context";
import { useHook } from "./hooks";

const Body = styled.div`
  ${
    // @ts-ignore
    FormGroupsCss
  }

  .ant-latest-upload-list-picture-card > div:nth-child(2n) {
    margin-right: 0;
  }
`;

interface Props {
  deal: DealSummaryWithProperty;
}

export const CancelDealModalV2 = ({ deal }: Props) => {
  const {
    onSubmit,
    uploadFile,
    modalExtension,
    title,
    defaultSubmitLabel,
    emailLabel,
    schema,
  } = useHook(deal);
  const state = useCancelDealModal();
  const { cancel$, submit$, submitting } = state;

  return (
    <Formik
      initialValues={{} as { sendEmail?: string; emailBody?: string }}
      validateOnMount
      validationSchema={schema}
      onSubmit={(values) => submit$.next(onSubmit(values))}
    >
      {({ errors, touched, isValid, submitForm, values }) => (
        <VeroModal {...state}>
          <LargeTitle>{title}</LargeTitle>
          <Subtitle>
            <RegularText strong>Applicants:&nbsp;</RegularText>
            <ApplicantsCheckList inline items={deal?.applicantsSummary} />
          </Subtitle>

          <Spacer size={Spacer.SIZES.default} />

          <Body>
            {modalExtension}

            <div className="form-group">
              <RadioGroup
                id="sendEmail"
                name="sendEmail"
                label={emailLabel}
                options={[
                  {
                    value: true,
                    label: "Yes",
                  },
                  {
                    value: false,
                    label: "No",
                  },
                ]}
              />
            </div>

            {values?.sendEmail && (
              <>
                <div className="form-group">
                  <p className="form-control-label">
                    What would you like the body of the email to say?
                    {/* @ts-ignore */}
                    <Popover
                      className="popover"
                      withoutOverlay
                      content={
                        <PopoverContent>
                          The subject line will read &quot;Your application has
                          been canceled&quot;
                        </PopoverContent>
                      }
                    >
                      <Icon.InfoIcon />
                    </Popover>
                  </p>

                  <Field
                    as={TextArea}
                    id="emailBody"
                    name="emailBody"
                    label="Email body"
                    rows={5}
                    error={touched?.emailBody && errors?.emailBody}
                  />
                </div>

                <div className="form-group">
                  <p className="form-control-label">
                    Choose which attachments to include:
                  </p>

                  <UploadWall
                    maxCount={5}
                    existingFiles={[]}
                    uploadFile={uploadFile}
                  />
                </div>
              </>
            )}
          </Body>

          <Spacer size={Spacer.SIZES.md} />
          <FlexContainer
            justifyContent="flex-end"
            gap="24px"
            alignItems="center"
          >
            <UnderlineLink onClick={() => cancel$.next()}>Cancel</UnderlineLink>
            <Button
              type="primary"
              onClick={submitForm}
              size={BUTTON_SIZES.lg}
              loading={submitting}
              disabled={!isValid}
            >
              {values?.sendEmail ? "Confirm and Send" : defaultSubmitLabel}
            </Button>
          </FlexContainer>
        </VeroModal>
      )}
    </Formik>
  );
};
