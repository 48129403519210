import React, { useEffect, useRef, useState } from "react";

import { isEmpty, isEqual } from "lodash";
import PropTypes from "prop-types";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { NoUnitsFoundNotificationMessage } from "renter/components/MagicPortal/NoUnitsFoundNotification";
import ROUTES from "renter/config/routes";
import {
  useGetMagicPortalProperty,
  useGetMagicPortalUnits,
} from "renter/hooks/api";
import { MaskedInput } from "shared/components/Form";
import { NOTIFICATIONS } from "shared/config/constants";
import { transformRoute } from "shared/utils/routing";
import { openNotification } from "shared/utils/ui";

import { ANY_OPTION_KEY, PAGE_SIZE } from "../config";

import UnitsPortal from "./UnitsPortal";

const transformFiltersParams = (params) => {
  return {
    ...params,
    maxRent: params.maxRent
      ? MaskedInput.Money.unmask(params.maxRent)
      : undefined,
    bedrooms: params.bedrooms === ANY_OPTION_KEY ? undefined : params.bedrooms,
    bathrooms:
      params.bathrooms === ANY_OPTION_KEY ? undefined : params.bathrooms,
  };
};

const FILTER_BAR_DEFAULTS = {
  bedrooms: undefined,
  bathrooms: undefined,
  maxRent: undefined,
};

const UnitsPortalContainer = ({
  companyMagicFragment,
  propertyMagicFragment,
}) => {
  const location = useLocation();
  const isMagicLinksV2 = location.pathname.includes(
    ROUTES.magicLinkV2GetStarted
  );
  const isPrescreeningMagicLinksV2 = location.pathname.includes(
    ROUTES.prescreeningMagicLinkV2GetStarted
  );
  const isV2 = isMagicLinksV2 || isPrescreeningMagicLinksV2;

  let companyHashId;
  let propertyHashId;
  if (isV2 && propertyMagicFragment && companyMagicFragment) {
    companyHashId = companyMagicFragment;
    propertyHashId = propertyMagicFragment;
  } else {
    ({ companyHashId, propertyHashId } = useParams());
  }

  const { push, replace } = useHistory();
  const unitsRef = useRef();
  const [filtersBar, setFiltersBar] = useState({
    ...FILTER_BAR_DEFAULTS,
  });
  const [unitsQueryParams, setUnitsQueryParams] = useState({
    propertyHashId,
    page: 1,
    pageSize: PAGE_SIZE,
    ...FILTER_BAR_DEFAULTS,
  });
  const propertyMagicLink = transformRoute(ROUTES.magicLinkGetStarted, {
    companyHashId: propertyHashId,
  });

  const { property } = useGetMagicPortalProperty(propertyHashId);
  const { units, isUnitsLoading } = useGetMagicPortalUnits(unitsQueryParams);

  if (units) {
    unitsRef.current = units;
  }

  let backRoute;
  let onApplyNowClick;
  if (isV2 && property) {
    const baseRoute = isPrescreeningMagicLinksV2
      ? ROUTES.prescreeningMagicLinkV2GetStarted
      : ROUTES.magicLinkV2GetStarted;
    backRoute = `${baseRoute}?company=${property.companyUuid}`;
    onApplyNowClick = () => {
      const queryParams = `${location.search}&show-landing=true`;
      push(`${location.pathname}${queryParams}`);
    };
  } else {
    backRoute = companyHashId
      ? transformRoute(ROUTES.magicPortalCompany, { companyHashId })
      : undefined;
    onApplyNowClick = () => push(propertyMagicLink);
  }

  const onPageChange = (page) => {
    const nextState = {
      ...unitsQueryParams,
      page,
    };
    setUnitsQueryParams(nextState);
  };

  const onFilterChange = (name, value) => {
    const nextState = {
      ...filtersBar,
      [name]: value,
    };

    setFiltersBar(nextState);
  };

  const onApplyFilters = () => {
    const nextState = {
      ...unitsQueryParams,
      ...transformFiltersParams(filtersBar),
      page: 1,
    };

    /** Note: When user clicks more than one time to apply the same filters,
     *  they should always see an error notification, if there is no results returned
     */
    if (isEqual(unitsQueryParams, nextState) && isEmpty(units)) {
      openNotification(NoUnitsFoundNotificationMessage, NOTIFICATIONS.error);
    }

    setUnitsQueryParams(nextState);
  };

  const onClearFilters = () => {
    const nextQueryState = {
      ...unitsQueryParams,
      ...FILTER_BAR_DEFAULTS,
      page: 1,
    };

    setFiltersBar({
      ...FILTER_BAR_DEFAULTS,
    });

    setUnitsQueryParams(nextQueryState);
  };

  useEffect(() => {
    if (isV2 && property && !property.hasUnits) {
      const queryParams = `${location.search}&show-landing=true`;
      replace(`${location.pathname}${queryParams}`);
    } else if (property && !property.hasUnits) {
      replace(propertyMagicLink);
    }
  }, [property]);

  const innerProps = {
    units: unitsRef.current?.results,
    property,
    isUnitsLoading,
    backRoute,
    onApplyNowClick,
    isMagicLinksV2,
    isPrescreeningMagicLinksV2,
    total: unitsRef.current?.count,
    pagination: unitsQueryParams,
    filtersBar,
    onPageChange,
    onFilterChange,
    onApplyFilters,
    onClearFilters,
  };

  return <UnitsPortal {...innerProps} />;
};

UnitsPortalContainer.propTypes = {
  companyMagicFragment: PropTypes.string,
  propertyMagicFragment: PropTypes.string,
};

UnitsPortalContainer.defaultProps = {
  companyMagicFragment: undefined,
  propertyMagicFragment: undefined,
};

export default UnitsPortalContainer;
