import React, { useEffect, useRef, useState } from "react";

import {
  useGetAllPropertiesSimple,
  useYardiResolveLinkProperty,
} from "manager/hooks/api";
import useModal from "shared/hooks/useModal";
import { resetFormAndValidate } from "shared/utils/forms";

import { RESOLVE_PROPERTY_LINK_OPTIONS } from "./yardiAlertsResolvers.config";
import YardiLinkPropertyResolverModal from "./YardiLinkPropertyResolverModal";

const YardiLinkPropertyResolverModalContainer = () => {
  const { currentModalContext } = useModal();
  const { alert, yardiProperty, provider } = currentModalContext;
  const { data: veroProperties } = useGetAllPropertiesSimple();
  const [currentSelection, setCurrentSelection] = useState(
    RESOLVE_PROPERTY_LINK_OPTIONS.CREATE_NEW_PROPERTY
  );
  const { resolveAlertLinkProperty } = useYardiResolveLinkProperty();
  const formikRef = useRef();

  const submit = async (values) => {
    let payload = {};
    if (
      currentSelection === RESOLVE_PROPERTY_LINK_OPTIONS.LINK_EXISTING_PROPERTY
    ) {
      const { veroPropertyId } = values;
      payload = { property: { id: veroPropertyId } };
    } else if (
      currentSelection === RESOLVE_PROPERTY_LINK_OPTIONS.CREATE_NEW_PROPERTY
    ) {
      payload = { property: values };
    }
    await resolveAlertLinkProperty({
      provider,
      alertId: alert?.id,
      body: payload,
    });
  };

  useEffect(() => {
    if (!!formikRef.current && currentSelection) {
      let values = {};
      if (
        currentSelection ===
          RESOLVE_PROPERTY_LINK_OPTIONS.CREATE_NEW_PROPERTY &&
        yardiProperty
      ) {
        values = {
          name: yardiProperty?.name,
          address: yardiProperty?.address,
          city: yardiProperty?.city,
          state: yardiProperty?.state,
          zipcode: yardiProperty?.zipcode,
        };
      }
      resetFormAndValidate(formikRef, { values });
    }
  }, [formikRef.current, yardiProperty, currentSelection]);

  return (
    <YardiLinkPropertyResolverModal
      yardiPropertyName={yardiProperty.name}
      currentSelection={currentSelection}
      setCurrentSelection={setCurrentSelection}
      veroProperties={veroProperties}
      submit={submit}
      formikRef={formikRef}
    />
  );
};

export default YardiLinkPropertyResolverModalContainer;
