import { Col, Row } from 'antd'

import Collapse from 'shared/components/Collapse'
import Spacer from 'shared/components/Spacer'
import Tile from 'shared/components/Tile'
import { CollectionAccount } from 'shared/interfaces/creditAndBackgroundCheck'
import { printDollarsFromCents } from 'shared/utils/dollar-print'
import {
  calcTileInnerBorderBottom,
  formatDate,
  getValueOrNA,
} from 'shared/utils/ui'

import { CreditReportSubtitle } from './styled'

interface CreditReportCollectionsProps {
  collections?: CollectionAccount[]
}

const CreditReportCollections = ({
  collections,
}: CreditReportCollectionsProps) => {
  return (
    <>
      <Tile.Inner borderBottom>
        <CreditReportSubtitle>Collections</CreditReportSubtitle>
      </Tile.Inner>
      {collections?.length ? (
        collections.map((collection, index) => {
          const key = `collection-${index}`
          return (
            // @ts-ignore
            <Collapse
              key={key}
              small
              header={({ CollapseIcon, collapsed }) => (
                <Tile.Inner
                  borderBottom={
                    calcTileInnerBorderBottom(collections, index) || !collapsed
                  }
                >
                  <Row type="flex">
                    <Col xs={{ span: 24 }} md={{ span: 11 }}>
                      <Row>
                        <Col
                          xs={{ span: 24 }}
                          md={{ span: 16 }}
                          lg={{ span: 10 }}
                        >
                          <Tile.Label>Creditor’s Name</Tile.Label>
                          <strong>
                            {getValueOrNA(collection.creditorsName)}
                            <CollapseIcon />
                          </strong>
                        </Col>
                        <Col
                          xs={{ span: 12 }}
                          md={{ span: 8 }}
                          lg={{ span: 6 }}
                        >
                          <Spacer hideGteMd />
                          <Tile.Label>Account Type</Tile.Label>
                          <strong>
                            {getValueOrNA(collection.accountType)}
                          </strong>
                        </Col>
                        <Col
                          xs={{ span: 12 }}
                          md={{ span: 8, offset: 16 }}
                          lg={{ span: 8, offset: 0 }}
                        >
                          <Spacer hideGteLg />
                          <Tile.Label>Account Designator</Tile.Label>
                          {getValueOrNA(collection.accountDesignator)}
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={{ span: 0 }} md={{ span: 1 }}>
                      <Tile.Divider bottomStick={!collapsed} />
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 11 }}>
                      <Row>
                        <Col xs={{ span: 12 }}>
                          <Spacer hideGteMd />
                          <Tile.Label>Current Balance</Tile.Label>
                          <span>
                            {printDollarsFromCents(collection.currentBalance)}
                          </span>
                        </Col>
                        <Col xs={{ span: 12 }}>
                          <Spacer hideGteMd />
                          <Tile.Label>Highest Balance</Tile.Label>
                          <span>
                            {printDollarsFromCents(collection.highestBalance)}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Tile.Inner>
              )}
            >
              <Tile.Inner
                inset
                borderBottom={calcTileInnerBorderBottom(collections, index)}
              >
                <Row type="flex">
                  <Col xs={{ span: 24 }} md={{ span: 11 }}>
                    <Row type="flex">
                      <Col
                        xs={{ span: 12 }}
                        md={{ span: 16 }}
                        lg={{ span: 10 }}
                      >
                        <Tile.Label>Current Manner of Payment</Tile.Label>
                        {getValueOrNA(collection.currentMannerOfPayment)}
                      </Col>
                      <Col xs={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
                        <Tile.Label>Date Opened</Tile.Label>
                        {formatDate(collection.date)}
                      </Col>
                      <Col xs={{ span: 12 }} md={{ span: 16 }} lg={{ span: 8 }}>
                        <Spacer hideGteLg />
                        <Tile.Label>Date Closed</Tile.Label>
                        {formatDate(collection.dateClosed)}
                      </Col>
                      <Col
                        xs={{ span: 12 }}
                        md={{ span: 8 }}
                        lg={{ span: 12, offset: 10 }}
                      >
                        <Spacer />
                        <Tile.Label>Loan Type</Tile.Label>
                        {getValueOrNA(collection.loanType)}
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={{ span: 0 }} md={{ span: 1 }}>
                    <Tile.Divider topStick />
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 11 }}>
                    <Spacer hideGteMd />
                    <Tile.Label>Comments</Tile.Label>
                    {getValueOrNA(collection.comments)}
                  </Col>
                </Row>
              </Tile.Inner>
            </Collapse>
          )
        })
      ) : (
        <Tile.Inner>No collection accounts</Tile.Inner>
      )}
    </>
  )
}

export default CreditReportCollections
