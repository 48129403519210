import Icon from "shared/components/Icon";
import { INTEGRATION_TYPES } from "shared/config/constants";

import { INTEGRATIONS_MARK_SIZE } from "./IntegrationMark.confg";

const DEFAULT_ICONS_PER_INTEGRATION = {
  [INTEGRATION_TYPES.YARDI]: Icon.YardiGreenSmall,
  [INTEGRATION_TYPES.ENTRATA]: Icon.EntrataLogoSmall,
  [INTEGRATION_TYPES.REAL_PAGE]: Icon.RealPageLogoSmall,
};

const DEFAULT_DARK_ICONS_PER_INTEGRATION = {
  [INTEGRATION_TYPES.YARDI]: Icon.YardiPurpleSmall,
  [INTEGRATION_TYPES.ENTRATA]: Icon.EntrataLogoSmall,
  [INTEGRATION_TYPES.REAL_PAGE]: Icon.RealPageLogoSmall,
};

const DEFAULT_ICONS_PER_INTEGRATION_LARGE = {
  [INTEGRATION_TYPES.YARDI]: Icon.YardiGreen,
  [INTEGRATION_TYPES.ENTRATA]: Icon.EntrataLogoMedium,
  [INTEGRATION_TYPES.REAL_PAGE]: Icon.RealPageLogoMedium,
};

const DEFAULT_DISABLED_ICONS_PER_INTEGRATION = {
  [INTEGRATION_TYPES.YARDI]: Icon.YardiGraySmall,
  [INTEGRATION_TYPES.ENTRATA]: Icon.EntrataLogoSmallDisabled,
  [INTEGRATION_TYPES.REAL_PAGE]: Icon.RealPageLogoSmallDisabled,
};

const DEFAULT_DISABLED_ICONS_PER_INTEGRATION_LARGE = {
  [INTEGRATION_TYPES.YARDI]: Icon.YardiGray,
  [INTEGRATION_TYPES.ENTRATA]: Icon.EntrataLogoMediumDisabled,
  [INTEGRATION_TYPES.REAL_PAGE]: Icon.RealPageLogoMediumDisabled,
};

const ACCEPTED_INTEGRATIONS = new Set([
  INTEGRATION_TYPES.YARDI,
  INTEGRATION_TYPES.ENTRATA,
  INTEGRATION_TYPES.REAL_PAGE,
]);

const getIconPerIntegration = (
  integrationType: string,
  size: string,
  disabled?: boolean,
  dark?: boolean
) => {
  let iconMap;
  if (disabled) {
    if (dark) {
      iconMap = {};
    } else {
      iconMap =
        size === INTEGRATIONS_MARK_SIZE.sm
          ? DEFAULT_DISABLED_ICONS_PER_INTEGRATION
          : DEFAULT_DISABLED_ICONS_PER_INTEGRATION_LARGE;
    }
  } else if (dark) {
    iconMap =
      size === INTEGRATIONS_MARK_SIZE.sm
        ? DEFAULT_DARK_ICONS_PER_INTEGRATION
        : {};
  } else {
    iconMap =
      size === INTEGRATIONS_MARK_SIZE.sm
        ? DEFAULT_ICONS_PER_INTEGRATION
        : DEFAULT_ICONS_PER_INTEGRATION_LARGE;
  }
  return iconMap[integrationType];
};

export { getIconPerIntegration, ACCEPTED_INTEGRATIONS };
