import React, { Key, useEffect, useRef, useState } from "react";

import { FormikProps } from "formik";
import { useMediaQuery } from "react-responsive";
import { useParams, useHistory } from "react-router-dom";

import { LUMEN_SECTION_TYPE } from "manager/config/lumen.config";
import ROUTES from "manager/config/routes";
import {
  useFinishLumenFormulaRevision,
  useGetLumenFormulaRevision,
  useUpdateLumenFormulaRevision,
} from "manager/hooks/api";
import { useAfterSubmitCallbacksApi } from "shared/components/AfterSubmitCallbackRegisterProvider";
import Container from "shared/components/Container";
import { FormPromptShell } from "shared/components/Form";
import { openClickwrapModal } from "shared/components/Modals/ClickwrapModal";
import ConfirmationModal from "shared/components/Modals/ConfirmationModal";
import Skeleton from "shared/components/Skeleton";
import { BREAKPOINT_RESOLUTIONS } from "shared/config/constants";
import { useActiveUser, useGetPactSafeConfig } from "shared/hooks/api";
import useModal from "shared/hooks/useModal";
import { getQueryParams } from "shared/utils/routing";

import { LumenFormulaFormikValues } from "./interfaces";
import LumenFormulaConfigurator from "./LumenFormulaConfigurator";
import {
  VALIDATION_SCHEMA_PER_SECTION,
  cleanUpPayloadBeforeSave,
  getFirstEnabledSectionOrStart,
  LUMEN_FORMULA_SECTIONS_NAVIGATION,
} from "./lumenFormulaConfigurator.util";
import LumenFormulaFootBar from "./LumenFormulaFootBar";
import { LumenFormulaStepper } from "./LumenFormulaStepper";

const INITIAL_VALUES = {};

const LumenFormulaConfiguratorContainer = () => {
  const formRef = useRef<FormikProps<LumenFormulaFormikValues>>();
  const { openModalDialog, closeActiveModalDialog } = useModal();
  const { formulaId, revisionId }: { formulaId?: Key; revisionId?: Key } =
    useParams();
  const history = useHistory();
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT_RESOLUTIONS.sm });
  const {
    currentSection: currentSectionFromParams,
  }: { currentSection?: string } = getQueryParams();
  const [currentSection, setCurrentSection] = useState<string>(
    LUMEN_SECTION_TYPE.CREDIT
  );
  const sectionNavigation = LUMEN_FORMULA_SECTIONS_NAVIGATION[currentSection];
  const prevSection = sectionNavigation.prevSection();
  const nextSection = sectionNavigation.nextSection();
  const { lumenFormulaRevision, isLoading: isRevisionLoading } =
    useGetLumenFormulaRevision(formulaId, revisionId);
  const { updateLumenFormulaRevision, isLoading: isRevisionUpdating } =
    useUpdateLumenFormulaRevision();
  const { finishLumenFormulaRevision, isLoading: isRevisionCompleting } =
    useFinishLumenFormulaRevision();
  const { activeUser } = useActiveUser();
  const { pactSafeConfig } = useGetPactSafeConfig();
  const afterSubmitCallbackApi = useAfterSubmitCallbacksApi();

  const isLoading =
    isRevisionLoading || isRevisionUpdating || isRevisionCompleting;

  const saveChanges = async (dto) => {
    if (formRef?.current?.isValid) {
      const updateDto = cleanUpPayloadBeforeSave(dto);
      await updateLumenFormulaRevision({ formulaId, revisionId, updateDto });
    }
  };

  const resetForm = () =>
    formRef?.current.resetForm({ values: lumenFormulaRevision });

  const finishFormulaAndNavigate = async (updateDto, onAfterSubmit) => {
    await finishLumenFormulaRevision(
      {
        formulaId,
        revisionId,
        updateDto,
      },
      {
        onSuccess: () => {
          return onAfterSubmit && onAfterSubmit();
        },
      }
    );

    resetForm();
    history.push(ROUTES.lumenDashboard);
  };

  const finishFormula = (dto, onAfterSubmit) => {
    const updateDto = cleanUpPayloadBeforeSave(dto);

    openClickwrapModal({
      onConfirm: () => finishFormulaAndNavigate(updateDto, onAfterSubmit),
      groupKey: pactSafeConfig?.termsOfServiceLumenGroupKey,
      userEmail: activeUser?.email,
      title: "Terms of Service",
      // @ts-ignore
      errorMessage: "The disclaimers must be accepted.",
    });
  };

  const onAfterSubmit = () => {
    return afterSubmitCallbackApi.executeAfterSubmitCallbacks();
  };

  const saveAndChangeSection = async (values, section) => {
    if (section) {
      await saveChanges(values);
      setCurrentSection(section);
    } else {
      await finishFormula(values, onAfterSubmit);
    }
  };

  const saveAndNavigateForwardOrComplete = async (values) => {
    saveAndChangeSection(values, nextSection);
  };

  const saveAndExit = async (values) => {
    const context = {
      title: "Exit formula creation?",
      description:
        'Please note, once you exit the setup all the data will be saved and the formula will have the "Draft" status. ',
      submitButtonLabel: "CONFIRM & EXIT",
      cancelLinkLabel: "Continue editing",
    };
    openModalDialog(ConfirmationModal, context).afterClose.then(
      async (result) => {
        if (result) {
          await saveChanges(values);
          resetForm();
          history.push(ROUTES.lumenDashboard);
        }
      }
    );
  };

  const navigateToPrev = () => {
    if (prevSection) {
      setCurrentSection(prevSection);
      resetForm();
    }
  };

  const navigateToFirstEnabledSectionOrStart = () => {
    setCurrentSection(getFirstEnabledSectionOrStart(lumenFormulaRevision));
  };

  const getPromptProps = ({ isValid }) => {
    return {
      title: "Unsaved changes",
      subtitle: isValid
        ? "Would you like to save the changes?"
        : "The changes you made are not valid and will not be saved.",
      submitButtonLabel: isValid ? "Yes" : "OK",
      cancelLinkLabel: isValid ? "No" : null,
    };
  };

  useEffect(() => {
    if (isMobile) {
      history.push(ROUTES.lumenDashboard);
    }
  }, [isMobile]);

  useEffect(() => {
    if (currentSectionFromParams) {
      setCurrentSection(currentSectionFromParams);
    }
  }, [currentSectionFromParams]);

  useEffect(() => {
    resetForm();
  }, [lumenFormulaRevision]);

  useEffect(() => {
    if (lumenFormulaRevision) {
      navigateToFirstEnabledSectionOrStart();
    }
    // NOTE: need to navigate to the first enabled section only on the initial load
  }, [lumenFormulaRevision?.id]);

  useEffect(() => {
    return () => closeActiveModalDialog();
  }, []);

  const validationSchema = VALIDATION_SCHEMA_PER_SECTION[currentSection];

  return (
    <Container expand noPaddingBottom>
      <FormPromptShell
        formRef={formRef}
        getPromptProps={getPromptProps}
        initialValues={lumenFormulaRevision || INITIAL_VALUES}
        validationSchema={validationSchema}
        saveChanges={saveChanges}
        onSubmit={saveAndNavigateForwardOrComplete}
        CustomFootBar={() => (
          <LumenFormulaFootBar
            currentSection={currentSection}
            prevSection={prevSection}
            nextSection={nextSection}
            navigateToPrev={navigateToPrev}
            saveAndExit={saveAndExit}
            validationStatus={lumenFormulaRevision?.validationStatus}
          />
        )}
      >
        <Skeleton loading={isLoading && !lumenFormulaRevision}>
          {lumenFormulaRevision ? (
            <LumenFormulaStepper
              formulaName={lumenFormulaRevision.name}
              currentSection={currentSection}
              saveAndChangeSection={saveAndChangeSection}
              validationStatus={lumenFormulaRevision.validationStatus}
            />
          ) : (
            <></>
          )}
        </Skeleton>
        <Skeleton loading={isLoading}>
          <LumenFormulaConfigurator
            formulaName={lumenFormulaRevision?.name}
            lumenSection={currentSection}
          />
        </Skeleton>
      </FormPromptShell>
    </Container>
  );
};

export default LumenFormulaConfiguratorContainer;
