import React, { useMemo } from "react";

import { ConfigProvider } from "antd-latest";
import { ConnectedRouter } from "connected-react-router";
import { LDProvider } from "launchdarkly-react-client-sdk";
import PropTypes from "prop-types";
import { ReactQueryConfigProvider } from "react-query";
import { ReactQueryDevtools } from "react-query-devtools";
import { Provider } from "react-redux";
import VegaGlobalStyles from "shared/styles/vega";
import "shared/forms/validation/globalValidators";
import AdminApp from "admin/App";
import LeadsApp from "leads/App";
import ManagerApp from "manager/App";
import RenterApp from "renter/RenterApp";
import { AfterSubmitCallbackRegisterProvider } from "shared/components/AfterSubmitCallbackRegisterProvider";
import {
  Confetti,
  ConfettiProvider,
  useConfetti,
} from "shared/components/confetti";
import ErrorBoundary from "shared/components/ErrorBoundary";
import { Messenger } from "shared/components/Messenger";
import { ANTD_LATEST_PREFIX, QUERY_OPTIONS } from "shared/config/constants";
import {
  GlobalConfigContext,
  useGlobalConfigProvider,
} from "shared/hooks/useGlobalConfig";
import { history } from "shared/store";
import {
  isAdminApp,
  isLeadsApp,
  isManagerApp,
  isRenterApp,
  isSurveysApp,
} from "shared/utils/auth";
import "shared/i18nConfig";
import { getLDConfig, WatchLaunchDarkly } from "shared/utils/launchDarkly";
import SurveysApp from "surveys/App";

import useAppOffline from "./shared/hooks/useAppOffline";

const RootApp = ({ store }) => {
  let App;
  const globalConfigContext = useGlobalConfigProvider();
  useAppOffline();

  if (isAdminApp()) {
    App = AdminApp;
  } else if (isManagerApp()) {
    App = ManagerApp;
  } else if (isRenterApp()) {
    App = RenterApp;
  } else if (isLeadsApp()) {
    App = LeadsApp;
  } else if (isSurveysApp()) {
    App = SurveysApp;
  } else {
    throw new Error("No app has been configured for this host.");
  }

  const ldConfig = useMemo(() => getLDConfig(), []);
  const confetti = useConfetti();

  return (
    // NOTE: We use the Select component from antd v4+ and we need to set a with different class prefix for it
    // so we don't break the existing antd v3 components and styles.
    <LDProvider {...ldConfig}>
      <ConfettiProvider context={confetti.context}>
        <ConfigProvider prefixCls={ANTD_LATEST_PREFIX}>
          <Provider store={store}>
            <ReactQueryConfigProvider config={QUERY_OPTIONS}>
              <ConnectedRouter history={history}>
                <ErrorBoundary history={history}>
                  <GlobalConfigContext.Provider value={globalConfigContext}>
                    <AfterSubmitCallbackRegisterProvider>
                      <>
                        <VegaGlobalStyles/>
                        <App />
                        <Messenger />
                        <WatchLaunchDarkly />
                        <Confetti show={confetti.show} />
                      </>
                    </AfterSubmitCallbackRegisterProvider>
                  </GlobalConfigContext.Provider>
                </ErrorBoundary>
              </ConnectedRouter>
              <ReactQueryDevtools position="bottom-left" />
            </ReactQueryConfigProvider>
          </Provider>
        </ConfigProvider>
      </ConfettiProvider>
    </LDProvider>
  );
};

RootApp.propTypes = {
  store: PropTypes.object.isRequired,
};

export default RootApp;
