import styled from "@emotion/styled";

import Icon from "shared/components/Icon";
import { UnderlineLink } from "shared/components/Links";
import { COLORS } from "shared/config/constants";

export const CollapseHeader = styled("div")({
  display: "flex",
  "& > *": {
    marginRight: "4px",
  },
  "& path": {
    stroke: "#FFFFFF",
  },
});

export const TagsRow = styled("div")({
  background: COLORS.leasePreview.documentInfo.row,
  display: "flex",
  flexDirection: "column",
  padding: "12px 16px",
  marginBottom: "4px",
  "& > *": {
    marginRight: "4px",
    padding: "10px 0",
  },
  "& > *:first-of-type": {
    padding: "0",
  },
  "& > *:last-of-type": {
    padding: "0",
  },
});

export const AddTagsContainer = styled("div")({
  background: COLORS.leasePreview.documentInfo.row,
  marginBottom: "4px",
  "& > *": {
    marginRight: "4px",
  },
});

export const styledCloseIcon = styled(Icon.CloseIcon)({
  width: 7,
  height: 7,
  cursor: "pointer",
  "& > rect": {
    fill: "#AEACB9",
  },
});

export const AddTagsLink = styled(UnderlineLink)({
  fontSize: "12px",
  fontWeight: 600,
});
