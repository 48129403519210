import styled from "@emotion/styled";

import { COLORS, MEDIA_QUERIES } from "shared/config/constants";
import { NewFeatureImage } from "shared/images";

export const Container = styled.div`
  display: flex;
  width: 100%;
  background-color: ${COLORS.white};
  height: 480px;
  margin-top: 20px;
  position: relative;
  ${MEDIA_QUERIES.w.ltLg} {
    height: auto;
    .flex-container {
      align-items: flex-end !important;
      flex-direction: column-reverse !important;
    }
  }
`;

export const Content = styled.div`
  position: relative;
  z-index: 1;
  flex: 1;
  padding: 50px;
  .new {
    color: ${COLORS.blueViolet};
    font-size: 18px;
    font-weight: 600;
  }
  .title {
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 130%;
    display: block;
    margin-bottom: 20px;
    color: ${COLORS.primary};
  }
  .subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 130%;
    display: block;
    margin-bottom: 16px;
    color: ${COLORS.primary};
  }
  ${MEDIA_QUERIES.w.ltLg} {
    padding: 0 24px 24px 24px;
  }
`;

export const BackgroundImage = styled.div`
  background: url("${NewFeatureImage}") no-repeat;
  width: 100%;
  height: 100%;
  background-position: 100%;
  position: absolute;
  top: 0;
  ${MEDIA_QUERIES.w.ltLg} {
    position: relative;
    height: 280px;
    background-size: cover;
  }
`;

export const List = styled.ul`
  padding: 0;
  li {
    list-style: none;
    display: flex;
    align-items: center;
    color: ${COLORS.gray2};
    font-size: 15px;
    font-weight: 400;
    line-height: 175%;
    span {
      margin-left: 5px;
    }
  }
`;
