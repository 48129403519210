import React, { Key } from "react";

import * as Yup from "yup";

import { Select, SelectProps } from "../Select";

export type PetSelectProps = SelectProps & {
  pets: {
    id: Key;
    value: string;
  }[];
  loading: boolean;
};
export const PetSelect = Object.assign(
  ({ pets = [], loading, ...props }: PetSelectProps) => (
    <Select loading={loading} data-testid="pet-select" {...props}>
      {pets.map((pet) => (
        <Select.Option key={pet.id}>{pet.value}</Select.Option>
      ))}
    </Select>
  ),
  { schema: Yup.string() }
);
