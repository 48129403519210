import React, { useMemo } from "react";

import FilterBar from "shared/components/FilterBar";
import Icon from "shared/components/Icon";
import Table from "shared/components/Table/Table/Table";
import { BREAKPOINT_TYPES } from "shared/config/constants";

import { alertsTablePropTypes } from "../shared/yardi.config";

import { TableWrapper } from "./styled";
import getYardiAlertRow from "./YardiAlertRow";
import {
  YARDI_ALERT_CLASSES,
  YARDI_ALERT_CLASSES_LABELS,
} from "./YardiAlerts.constants";

const YardiAlertsTable = ({
  alerts,
  properties,
  isTableLoading,
  setFilters,
  currentAlertClass,
  currentYardiPropertyId,
  currentResolved,
  setCurrentAlertClass,
  setCurrentYardiPropertyId,
  setCurrentResolved,
  clearFilters,
  currentPagination: { page, pageSize },
  setCurrentPagination,
  totalCount,
  isIntegrationsAdmin,
  provider,
}) => {
  const alertClassOptions = useMemo(() => {
    let options = [
      {
        key: null,
        label: "All categories",
      },
    ];

    if (isIntegrationsAdmin) {
      options.push(
        ...Object.values(YARDI_ALERT_CLASSES).map((id) => ({
          key: id,
          label: YARDI_ALERT_CLASSES_LABELS[id],
        }))
      );
    } else {
      options = [
        {
          label:
            YARDI_ALERT_CLASSES_LABELS[YARDI_ALERT_CLASSES.UnitAvailability],
          key: YARDI_ALERT_CLASSES.UnitAvailability,
        },
      ];
    }

    return options;
  }, [isIntegrationsAdmin]);

  const alertPropertyOptions = useMemo(
    () => [
      {
        label: "All Properties",
        key: null,
      },
      ...properties?.map((property) => ({
        label: property.name,
        key: property.id,
      })),
    ],
    [properties]
  );

  const onPaginationChange = (currentPage) => {
    const newPagination = { page: currentPage, pageSize };
    setCurrentPagination(newPagination);
  };

  const tableFilters = {
    dropdownFilters: [
      {
        key: "alertClass",
        label: "All categories",
        onChange: (value) => setCurrentAlertClass(value),
        value: currentAlertClass,
        items: alertClassOptions,
        order: 1,
        minWidth: "165px",
        disabled: !isIntegrationsAdmin,
      },
      {
        key: "alertProperty",
        label: "All",
        onChange: (value) => setCurrentYardiPropertyId(value),
        value: currentYardiPropertyId,
        items: alertPropertyOptions,
        order: 2,
        minWidth: "165px",
      },
    ],
    checkboxFilters: [
      {
        key: "showCompleted",
        label: "Show completed tasks",
        onChange: (targetValue) => setCurrentResolved(targetValue),
        value: currentResolved,
        order: 3,
      },
    ],
    setFilters,
    clearFilters: {
      onClick: () => clearFilters(),
      order: 3,
      grow: true,
    },
    breakpointFilterPopover: [
      BREAKPOINT_TYPES.sm,
      BREAKPOINT_TYPES.md,
      BREAKPOINT_TYPES.lg,
      BREAKPOINT_TYPES.xl,
    ],
  };

  const propertiesMap = new Map(
    properties.map((property) => [property.id, property])
  );

  return (
    <TableWrapper data-testid="alerts-table">
      <FilterBar {...tableFilters} />
      <Table
        alt
        columns={[
          {
            title: "Category",
            dataIndex: "alertClass",
            key: "alertClass",
          },
          {
            title: "Description",
            dataIndex: "description",
            key: "description",
          },
          {
            title: "Property",
            dataIndex: "yardiPropertyId",
            key: "yardiPropertyId",
          },
          {
            title: "Date",
            dataIndex: "date",
            key: "date",
          },
        ]}
        rows={alerts?.map((alert) =>
          getYardiAlertRow({
            alert,
            yardiProperty: propertiesMap.get(alert.propertyId),
            isIntegrationsAdmin,
            provider,
          })
        )}
        columnBreakpoints={{
          xs: {
            column1: "alertClass",
            column2: "description",
            column3: "yardiPropertyId",
            column4: "date",
          },
          sm: ["alertClass", "description", "yardiPropertyId", "date"],
        }}
        showActionColumn
        placeholder={{
          label: "Updates",
          Icon: Icon.BellIcon,
        }}
        onRowClick={() => {}}
        loading={isTableLoading}
        pagination={{
          current: page,
          onChange: onPaginationChange,
          total: totalCount,
          defaultPageSize: pageSize,
        }}
      />
    </TableWrapper>
  );
};

YardiAlertsTable.propTypes = alertsTablePropTypes;

export default YardiAlertsTable;
