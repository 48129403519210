import React from "react";

import { useParams } from "react-router-dom";

import { FeeInventoryLevel } from "manager/interfaces/api";

import HoldingDepositSettings from "./HoldingDepositSettings";

export interface PropertySettingsHoldingDepositProps {
  yardiConnected: boolean;
}

const PropertySettingsHoldingDeposit = ({
  yardiConnected,
}: PropertySettingsHoldingDepositProps) => {
  const { id } = useParams<{ id: string }>();
  const propertyId = Number(id);

  return (
    <HoldingDepositSettings
      id={propertyId}
      level={FeeInventoryLevel.PROPERTY}
      showUnitTypeHoldingDepositMapping={yardiConnected}
    />
  );
};

export default PropertySettingsHoldingDeposit;
