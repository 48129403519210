import { queryCache, useMutation, useQuery } from "react-query";

import { NOTIFICATIONS } from "shared/config/constants";
import useQueryErrorHandler from "shared/hooks/useQueryErrorHandler";
import sharedApi from "shared/lib/api";
import { openNotification } from "shared/utils/ui";

import { PROPERTY_QUERY_KEYS } from "./propertyQueries";

const GET_PORTFOLIO = "getPortfolio";
const GET_PORTFOLIOS = "getPortfolios";
const GET_PORTFOLIOS_LIST = "getPortfoliosList";
const GET_PORTFOLIOS_ASSOCIATED = "getPortfoliosAssociated";
const GET_PORTFOLIOS_FOR_DOCUMENTS = "getPortfoliosForDocuments";

export const PORTFOLIO_QUERY_KEYS = Object.freeze({
  getPortfolio: (id) => [GET_PORTFOLIO, Number(id)],
  getPortfolios: () => [GET_PORTFOLIOS],
  getPortfoliosList: () => [GET_PORTFOLIOS_LIST],
  getPortfoliosAssociated: () => [GET_PORTFOLIOS_ASSOCIATED],
  getPortfoliosForDocuments: (id: number) => [GET_PORTFOLIOS_FOR_DOCUMENTS, id],
});

export const useGetPortfolio = (id) => {
  const queryErrorHandler = useQueryErrorHandler();

  const {
    data: portfolio,
    isLoading: isPortfolioLoading,
    refetch: refetchPortfolio,
  } = useQuery(
    PORTFOLIO_QUERY_KEYS.getPortfolio(id),
    () => sharedApi.getPortfolio(id),
    {
      onError: (error) =>
        queryErrorHandler(error, "Failed to load portfolio data"),
    }
  );
  return { portfolio, isPortfolioLoading, refetchPortfolio };
};

export const useGetPortfolios = (params, config = {}) => {
  const response = useQuery(
    PORTFOLIO_QUERY_KEYS.getPortfolios(),
    () => sharedApi.getPortfolios(params).then(({ data }) => data),
    {
      onError: () =>
        openNotification("Failed to load the portfolios", NOTIFICATIONS.error),
      ...config,
    }
  );
  return response;
};

export const useGetPortfoliosList = (params, config = {}) => {
  const response = useQuery(
    PORTFOLIO_QUERY_KEYS.getPortfoliosList(),
    () => sharedApi.getPortfoliosList(params).then(({ data }) => data),
    {
      onError: () =>
        openNotification("Failed to load the portfolios", NOTIFICATIONS.error),
      ...config,
    }
  );
  return response;
};

export const useRenamePortfolio = ({ portfolioId, oldName }) => {
  const [mutate, { isLoading }] = useMutation(
    (name: string) => sharedApi.updatePortfolio({ portfolioId, name }),
    {
      onSuccess: ({ data }) => {
        queryCache.invalidateQueries(PORTFOLIO_QUERY_KEYS.getPortfolios());
        queryCache.invalidateQueries(
          PORTFOLIO_QUERY_KEYS.getPortfolio(portfolioId)
        );
        openNotification(
          `You have successfully renamed portfolio from ${oldName} to ${data?.name}`,
          NOTIFICATIONS.info
        );
      },
      onError: () =>
        openNotification("Failed to rename portfolio.", NOTIFICATIONS.error),
    }
  );

  return {
    renamePortfolio: mutate,
    isRenamePortfolio: isLoading,
  };
};

export const useAssignPropertiesToPortfolio = ({
  portfolioId,
}: {
  portfolioId: number;
}) => {
  const [mutate, { isLoading }] = useMutation(
    (properties: number[]) =>
      sharedApi.assignPropertiesToPortfolio(portfolioId, properties),
    {
      onSuccess: () =>
        queryCache.invalidateQueries(PROPERTY_QUERY_KEYS.getProperties()),
      onError: () =>
        openNotification(
          "Failed to assign properties to portfolio.",
          NOTIFICATIONS.error
        ),
    }
  );

  return {
    assignPropertiesToPortfolio: mutate,
    isAssignPropertiesToPortfolio: isLoading,
  };
};

export const useGetPortfoliosAssociated = () => {
  const { data, isLoading } = useQuery(
    PORTFOLIO_QUERY_KEYS.getPortfoliosAssociated(),
    sharedApi.getPortfoliosAssociated,
    {
      onError: () =>
        openNotification("Failed to fetch portfolios", NOTIFICATIONS.error),
    }
  );
  return {
    portfoliosAssociated: data,
    isPortfoliosAssociated: isLoading,
  };
};

export const useCreatePortfolio = () => {
  const [mutate, { isLoading }] = useMutation(sharedApi.createPortfolio, {
    onSuccess: ({ data }) => {
      openNotification(
        `Successfully created portfolio ${data.name}`,
        NOTIFICATIONS.info
      );
      queryCache.invalidateQueries(PORTFOLIO_QUERY_KEYS.getPortfolios());
      queryCache.invalidateQueries(
        PORTFOLIO_QUERY_KEYS.getPortfoliosAssociated()
      );
    },
    onError: () =>
      openNotification("Failed to create portfolio.", NOTIFICATIONS.error),
  });

  return {
    createPortfolio: mutate,
    isCreatePortfolio: isLoading,
  };
};
