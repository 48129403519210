import styled from "@emotion/styled";

import Icon from "shared/components/Icon";
import { COLORS } from "shared/config/constants";

const InfoIcon = styled(Icon.InfoIcon)`
  vertical-align: middle;
`;

const Title = styled.p`
  font-weight: 300;
  margin: 0;
  padding-top: 35px;
  padding-bottom: 20px;
`;

const WarningContainer = styled.div`
  background-color: ${COLORS.background};
  border: 1px solid ${COLORS.borders};
  border-top: 0px;
  display: flex;
  align-items: center;
  padding: 15px 40px 15px 25px;
`;

const WarningText = styled.p`
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  margin-bottom: 0px;
  margin-left: 15px;
`;

const WarningIcon = styled(Icon.NotVerifiedIncomeAssetsIcon)`
  height: 40px;
  width: 40px;
`;

export { InfoIcon, Title, WarningContainer, WarningText, WarningIcon };
