import { queryCache, useMutation } from "react-query";

import yardiAlertResolversApi from "manager/lib/yardiAlertResolversApi";
import { NOTIFICATIONS } from "shared/config/constants";
import { openNotification } from "shared/utils/ui";

const onSuccess = () => {
  queryCache.invalidateQueries();
};

const useYardiResolveLinkProperty = () => {
  const [mutate, status] = useMutation(
    ({ provider, alertId, body }) =>
      yardiAlertResolversApi.postYardiAlertResolveLinkProperty(
        provider,
        alertId,
        body
      ),
    {
      onSuccess,
      onError: () => {
        openNotification(
          "Failed to resolve property alert",
          NOTIFICATIONS.error
        );
      },
    }
  );

  return {
    resolveAlertLinkProperty: mutate,
    isLoading: status.isLoading,
  };
};

const useYardiResolveLinkUnit = () => {
  const [mutate, status] = useMutation(
    ({ provider, alertId, body }) =>
      yardiAlertResolversApi.postYardiAlertResolveLinkUnit(
        provider,
        alertId,
        body
      ),
    {
      onSuccess,
      onError: () => {
        openNotification("Failed to resolve unit alert", NOTIFICATIONS.error);
      },
    }
  );

  return {
    resolveAlertLinkUnit: mutate,
    isLoading: status.isLoading,
  };
};

const useYardiResolveCreateMarketingSource = () => {
  const [mutate, status] = useMutation(
    ({ provider, alertId, body }) =>
      yardiAlertResolversApi.postYardiAlertResolveCreateMarketingSource(
        provider,
        alertId,
        body
      ),
    {
      onSuccess,
      onError: () => {
        openNotification(
          "Failed to resolve marketing source alert",
          NOTIFICATIONS.error
        );
      },
    }
  );

  return {
    resolveAlertMarketingSource: mutate,
    isLoading: status.isLoading,
  };
};

const useYardiResolveCreateLeadSource = () => {
  const [mutate, status] = useMutation(
    ({ provider, alertId, body }) =>
      yardiAlertResolversApi.postYardiAlertResolveCreateLeadSource(
        provider,
        alertId,
        body
      ),
    {
      onSuccess,
      onError: () => {
        openNotification(
          "Failed to resolve lead source alert",
          NOTIFICATIONS.error
        );
      },
    }
  );

  return {
    resolveAlertLeadSource: mutate,
    isLoading: status.isLoading,
  };
};

const useYardiResolveCreateUnitListing = () => {
  const [mutate, status] = useMutation(
    ({ provider, alertId, body }) =>
      yardiAlertResolversApi.postYardiAlertResolveCreateUnitListing(
        provider,
        alertId,
        body
      ),
    {
      onSuccess,
      onError: () => {
        // TODO (V2-2357) goran: Enable the notification here when we remove the onDialogSubmitError util.
        // openNotification(
        //   "Failed to resolve Yardi unit listing alert",
        //   NOTIFICATIONS.error
        // );
      },
    }
  );

  return {
    resolveAlertUnitListing: mutate,
    isLoading: status.isLoading,
  };
};

const useYardiResolveLinkAgents = () => {
  const [mutate, status] = useMutation(
    ({ provider, alertId, body }) =>
      yardiAlertResolversApi.postYardiAlertResolveLinkAgents(
        provider,
        alertId,
        body
      ),
    {
      onSuccess,
      onError: () => {
        openNotification("Failed to resolve agents alert", NOTIFICATIONS.error);
      },
    }
  );

  return {
    resolveAlertLinkAgents: mutate,
    isLoading: status.isLoading,
  };
};

const useYardiResolveLinkMarketingSources = () => {
  const [mutate, status] = useMutation(
    ({ provider, alertId, body }) =>
      yardiAlertResolversApi.postYardiAlertResolveLinkMarketingSources(
        provider,
        alertId,
        body
      ),
    {
      onSuccess,
      onError: () => {
        openNotification(
          "Failed to resolve marketing sources alert",
          NOTIFICATIONS.error
        );
      },
    }
  );

  return {
    resolveAlertLinkMarketingSources: mutate,
    isLoading: status.isLoading,
  };
};

const useYardiResolveLinkChargeTypes = () => {
  const [mutate, status] = useMutation(
    ({ provider, alertId, body }) =>
      yardiAlertResolversApi.postYardiAlertResolveLinkChargeTypes(
        provider,
        alertId,
        body
      ),
    {
      onSuccess,
      onError: () => {
        openNotification(
          "Failed to resolve charge types alert",
          NOTIFICATIONS.error
        );
      },
    }
  );

  return {
    resolveAlertLinkChargeTypes: mutate,
    isLoading: status.isLoading,
  };
};

const useYardiResolveLinkAttachmentTypes = () => {
  const [mutate, status] = useMutation(
    ({ provider, alertId, body }) =>
      yardiAlertResolversApi.postYardiAlertResolveLinkAttachmentTypes(
        provider,
        alertId,
        body
      ),
    {
      onSuccess,
      onError: () => {
        openNotification(
          "Failed to resolve document types alert",
          NOTIFICATIONS.error
        );
      },
    }
  );

  return {
    resolveAlertLinkAttachmentTypes: mutate,
    isLoading: status.isLoading,
  };
};

export {
  useYardiResolveLinkProperty,
  useYardiResolveLinkUnit,
  useYardiResolveCreateMarketingSource,
  useYardiResolveCreateLeadSource,
  useYardiResolveCreateUnitListing,
  useYardiResolveLinkAgents,
  useYardiResolveLinkMarketingSources,
  useYardiResolveLinkChargeTypes,
  useYardiResolveLinkAttachmentTypes,
};
