import React from "react";

import { Col, Row } from "antd";

import {
  PERSONA_CHECK_STATUS,
  PERSONA_VERIFICATION_TYPES,
} from "shared/config/constants";
import { isRenterApp } from "shared/utils/auth";
import { VerificationPropType } from "shared/utils/interfaces";

import Spacer from "../Spacer";
import Tile from "../Tile";

import ChecksViewer from "./ChecksViewer";
import PersonDocumentCard from "./PersonDocumentCard";
import VerificationErrorMessages from "./VerificationErrorMessages";
import VerificationFilesCard from "./VerificationFilesCard";

export interface IdVerificationDetailsProps {
  verification: VerificationPropType;
  inset?: boolean;
  phoneNumber?: string;
  verificationType?: string;
}

const IdVerificationDetails = ({
  verification,
  inset = false,
  phoneNumber = undefined,
  verificationType = undefined,
}: IdVerificationDetailsProps) => {
  const isRenter = isRenterApp();
  const failedChecks = verification.details.checks.filter(
    (check) => check.status === PERSONA_CHECK_STATUS.FAILED
  );
  const shouldShowFailedChecks = failedChecks.length > 0 && !isRenter;
  const address = `${verification.details.addressStreet}, ${verification.details.addressCity}, ${verification.details.addressSubdivisionAbbr}, ${verification.details.addressPostalCodeAbbr}, ${verification.details.addressCountryCode}`;
  return (
    <Row>
      <Row type="flex">
        {shouldShowFailedChecks && (
          <Col span={24}>
            <VerificationErrorMessages checks={failedChecks} />
          </Col>
        )}
      </Row>
      {shouldShowFailedChecks && <Spacer size={Spacer.SIZES.sm} />}
      <Row>
        <Col>
          <Tile noBorder>
            <ChecksViewer
              firstName={verification.details.firstName}
              middleName={verification.details.middleName}
              lastName={verification.details.lastName}
              verificationType={verificationType}
              verificationChecks={verification.details.checks}
              inset={inset}
              isRenter={isRenter}
            />
            {verificationType !== PERSONA_VERIFICATION_TYPES.SELFIE && (
              <PersonDocumentCard
                dateOfBirth={verification.details.dateOfBirth}
                address={address}
                age={verification.details.age}
                phoneNumber={verification.details.phoneNumber || phoneNumber}
                documentClass={verification.details.documentClass}
                identificationNumber={verification.details.identificationNumber}
                expirationDate={verification.details.expirationDate}
                inset={inset}
              />
            )}
            <VerificationFilesCard
              verificationFiles={verification.details.verificationFiles}
              inset={inset}
            />
          </Tile>
        </Col>
      </Row>
    </Row>
  );
};

export default IdVerificationDetails;
