/* eslint-disable */
import styled from "@emotion/styled";
import { Typography, Divider as BaseDivider } from "antd";
import React from "react";

import { COLORS, MEDIA_QUERIES, TRANSITIONS } from "shared/config/constants";
import Tag from "shared/components/Tag";

import { TileInnerProps } from "./interfaces";

const { Text } = Typography;

const tileMarginBottom = "16px";

const Tile = styled(
  ({
    altStyle,
    secStyle,
    noBorderTop,
    noBorderBottom,
    noBorderLeft,
    noBorderRight,
    noMarginBottom,
    equalHeight,
    insetStyle,
    stickyTop,
    noBorder,
    rounded,
    ...props
  }) => <div {...props} />
)((props) => ({
  borderRadius: "2px",
  borderWidth: "1px",
  borderStyle: "solid",
  ...(props.noBorder && {
    borderStyle: "none",
  }),
  marginBottom: tileMarginBottom,
  display: "flex",
  flexDirection: "column",
  ...(props.stickyTop && {
    position: "sticky",
    top: "48px",
  }),
  ...(props.insetStyle && {
    marginBottom: 0,
  }),
  backgroundColor: COLORS.tile.default.background,
  borderColor: COLORS.tile.default.border,
  [MEDIA_QUERIES.w.ltMd]: {
    borderRadius: 0,
    borderLeft: 0,
    borderRight: 0,
  },
  ...(props.noMarginBottom && {
    marginBottom: 0,
  }),
  ...(props.altStyle && {
    backgroundColor: COLORS.tile.alt.background,
    borderColor: COLORS.tile.alt.border,
    "&, .ant-typography": {
      color: COLORS.tile.alt.text,
    },
  }),
  ...(props.secStyle && {
    backgroundColor: COLORS.tile.sec.background,
    borderColor: COLORS.tile.sec.border,
  }),
  ...(props.noBorderTop && {
    borderTopWidth: 0,
  }),
  ...(props.noBorderBottom && {
    borderBottomWidth: 0,
  }),
  ...(props.noBorderLeft && {
    borderLeftWidth: 0,
  }),
  ...(props.noBorderRight && {
    borderRightWidth: 0,
  }),
  ...(props.equalHeight && {
    height: `calc(100% - ${tileMarginBottom})`,
  }),
  ...(props.rounded && {
    borderRadius: "8px",
    borderStyle: "none",
  }),
}));

const tileInnerPadding = 24;

const Inner = styled(
  ({
    borderBottom,
    borderTop,
    altStyle,
    mdOnly,
    clickable,
    inset,
    small,
    noPaddingTopLtMd,
    noPaddingBottomLtMd,
    spacer,
    size,
    sizeX,
    violet,
    deepViolet,
    noPadding,
    noPaddingTop,
    noPaddingBottom,
    noPaddingLtMd,
    ...props
  }: TileInnerProps) => <div {...props} />
)((props) => ({
  height: "100%",
  padding: `${tileInnerPadding}px`,
  ...(props.size === "sm" && {
    padding: "0",
  }),
  ...(props.size === "lg" && {
    padding: "40px",
  }),
  ...(props.sizeX === "lg" && {
    paddingLeft: "40px",
    paddingRight: "40px",
  }),
  ...(props.borderBottom === true && {
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    borderBottomColor: props.altStyle
      ? COLORS.tile.alt.innerBorder
      : COLORS.tile.default.innerBorder,
  }),
  ...(props.borderTop === true && {
    borderTopWidth: "1px",
    borderTopStyle: "solid",
    borderTopColor: props.altStyle
      ? COLORS.tile.alt.innerBorder
      : COLORS.tile.default.innerBorder,
  }),
  ...(props.mdOnly === true && {
    display: "none",
  }),
  ...(props.clickable === true && {
    cursor: "pointer",
    "&:hover": {
      boxShadow: "0px 1px 8px rgba(126, 153, 168, 0.4)",
      borderColor: "transparent",
    },
  }),
  ...(props.inset && {
    backgroundColor: COLORS.tile.inset.background,
  }),
  ...(props.small && {
    paddingTop: "16px",
    paddingBottom: "16px",
  }),
  ...(props.spacer === true && {
    borderBottom: `1px solid ${COLORS.borders}`,
  }),
  ...(props.violet && {
    backgroundColor: COLORS.violetTheme.backgroundColor,
    borderWidth: "0",
    color: COLORS.violetTheme.text,
  }),
  ...(props.deepViolet && {
    backgroundColor: COLORS.violetTheme.backgroundColorDeep,
    borderWidth: "0",
    color: COLORS.violetTheme.text,
  }),
  ...(props.noPaddingTop && {
    paddingTop: 0,
  }),
  ...(props.noPaddingBottom && {
    paddingBottom: 0,
  }),
  ...(props.noPadding && {
    padding: 0,
  }),
  [MEDIA_QUERIES.w.btwMdLg]: {
    ...(props.mdOnly === true && {
      display: "block",
    }),
  },
  [MEDIA_QUERIES.w.ltMd]: {
    ...(!props.noPaddingLtMd && {
      padding: "20px",
    }),
    ...(props.noPaddingTopLtMd && {
      paddingTop: 0,
    }),
    ...(props.noPaddingBottomLtMd && {
      paddingBottom: 0,
    }),
    ...(props.small && {
      paddingTop: "16px",
      paddingBottom: "16px",
    }),
  },
}));

// Typography

// eslint-disable-next-line jsx-a11y/heading-has-content
const Title = styled(({ altStyle, ...props }) => <h2 {...props} />)(
  (props) => ({
    marginBottom: "0 !important",
    display: "flex",
    alignItems: "center",
    ...(props.altStyle && {
      color: COLORS.tile.alt.text,
    }),
    [MEDIA_QUERIES.w.ltSm]: {
      marginRight: 10,
    },
  })
);

const SmallTitle = styled(Text)({
  display: "flex",
  alignItems: "end",
  fontWeight: 500,
  marginBottom: "24px",
  [MEDIA_QUERIES.w.ltMd]: {
    marginBottom: 0,
  },
});

const TileLabel = styled(({ alt, noMb, ...props }) => <div {...props} />)(
  (props) => ({
    display: "block",
    marginBottom: "7px",
    fontSize: "12px",
    fontWeight: 300,
    color: COLORS.tile.default.text,
    ...(props.alt && {
      color: COLORS.tile.alt.text,
      fontWeight: 400,
    }),
    ...(props.noMb && {
      marginBottom: 0,
    }),
  })
);

const CollapseButton = styled(
  ({
    collapsibleOnDesktop,
    collapsibleOnMobile,
    collapsed,
    altStyle,
    ...props
  }) => <div {...props} />
)((props) => ({
  cursor: "pointer",
  marginLeft: "auto",
  display: "flex",
  alignItems: "center",
  paddingLeft: "12px",
  [MEDIA_QUERIES.w.gteMd]: {
    ...(!props.collapsibleOnDesktop && {
      display: "none",
    }),
  },
  svg: {
    transition: "transform .24s, -webkit-transform .24s",
  },
  ...(props.altStyle && {
    svg: {
      path: {
        fill: COLORS.white,
      },
    },
  }),
  ...(!props.collapsed && {
    svg: {
      transform: "rotate(180deg)",
      ...(props.altStyle && {
        path: {
          fill: COLORS.white,
        },
      }),
    },
  }),
}));

const Header = styled(
  ({
    mobileOnly,
    collapsed,
    noMobileActionStyles,
    collapsibleOnMobile,
    collapsibleOnDesktop,
    accent,
    size,
    insetStyle,
    insetHeader,
    alignItems,
    noPaddingLtMd,
    ...props
  }) => <Inner {...props} />
)((props) => ({
  height: "auto",
  display: "flex",
  alignItems: props.alignItems || "baseline",
  ...(props.accent && {
    backgroundColor: COLORS.tile.accent.headerBackground,
  }),
  ...(props.altStyle &&
    props.insetHeader && {
      borderLeft: `4px solid ${COLORS.tabs.inkBar}`,
    }),
  ...(props.size === "lg" && {
    padding: "24px 40px",
  }),
  ...(props.insetStyle && {
    backgroundColor: COLORS.tile.inset.background,
  }),
  [MEDIA_QUERIES.w.gteMd]: {
    ...(props.mobileOnly && {
      display: "none",
    }),
    ...(props.collapsed &&
      props.collapsibleOnDesktop && {
        borderBottom: 0,
      }),
  },
  [MEDIA_QUERIES.w.ltMd]: {
    ...(props.noMobileActionStyles && {
      alignItems: "center",
    }),
    ...(!props.noMobileActionStyles && {
      flexDirection: "column",
      ...(props.collapsed &&
        props.collapsibleOnMobile && {
          borderBottom: 0,
        }),
    }),
    ...(props.noPaddingLtMd && {
      padding: 0,
    }),
  },
}));

const HeaderTitleContainer = styled(
  ({ noMobileActionStyles, collapsibleOnMobile, fullWidth, ...props }) => (
    <div {...props} />
  )
)((props) => ({
  display: "flex",
  ...(props.fullWidth && {
    width: "100%",
  }),
  [MEDIA_QUERIES.w.ltMd]: {
    ...(!props.noMobileActionStyles && {
      width: "100%",
    }),
  },
  [MEDIA_QUERIES.w.ltSm]: {
    ...(!props.collapsibleOnMobile && {
      flexWrap: "wrap",
    }),
  },
}));

const HeaderSmallTitle = styled(({ collapsed, ...props }) => (
  <SmallTitle {...props} />
))((props) => ({
  display: "block",
  alignSelf: "center",
  marginBottom: 0,
  ...(props.collapsed && {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  }),
}));

const HeaderIcon = styled("div")({
  display: "flex",
  marginRight: "12px",
  alignItems: "center",
});

const HeaderActions = styled(
  ({
    noMobileActionStyles,
    collapsed,
    collapsibleOnMobile,
    collapsibleOnDesktop,
    inlineOnMobile,
    ...props
  }) => <div {...props} />
)((props) => ({
  display: "flex",
  [MEDIA_QUERIES.w.gteMd]: {
    marginLeft: "16px",
  },
  [MEDIA_QUERIES.w.ltMd]: {
    ...(!props.inlineOnMobile && { flexDirection: "column" }),
    ...(props.noMobileActionStyles && {
      justifyContent: "space-between",
    }),
    ...(!props.noMobileActionStyles && {
      marginTop: "24px",
      ...(props.collapsed &&
        props.collapsibleOnMobile && {
          display: "none",
        }),
    }),
  },
}));

const HeaderAction = styled(
  ({
    noMobileActionStyles,
    separateActionsStyle,
    inlineOnMobile,
    ...props
  }) => <div {...props} />
)((props) => ({
  "&:first-of-type": {
    marginLeft: "auto",
    ...(props.separateActionsStyle && {
      marginLeft: "0",
      marginRight: "auto",
    }),
    [MEDIA_QUERIES.w.ltMd]: {
      marginLeft: "0",
    },
  },
  "&:not(:first-of-type)": {
    marginLeft: "20px",
    [MEDIA_QUERIES.w.ltMd]: {
      marginLeft: "0",
      ...(!props.inlineOnMobile && { marginTop: "20px" }),
    },
  },
}));

const ChildrenContainer = styled(
  ({ collapsed, collapsibleOnDesktop, collapsibleOnMobile, ...props }) => (
    <div {...props} />
  )
)(() => ({
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
}));

const HeaderLabel = styled(({ desktop, mobile, ...props }) => (
  <div {...props} />
))((props) => ({
  marginLeft: "auto",
  ...(props.desktop && {
    [MEDIA_QUERIES.w.ltMd]: {
      display: "none",
    },
  }),
  ...(props.mobile && {
    [MEDIA_QUERIES.w.gteMd]: {
      display: "none",
    },
  }),
}));

const horizontalDividerStyles = {
  height: "1px",
  width: "100%",
  margin: "24px 0",
};

const Divider = styled(
  ({
    alt,
    horizontal,
    bottomStick,
    topStick,
    hideLtXl,
    hideGteMd,
    hideLtMd,
    fullWidth,
    fullHeight,
    horizontalLtXl,
    horizontalLtLg,
    horizontalLtMd,
    ...props
  }) => <BaseDivider {...props} />
)((props) => ({
  height: "100%",
  margin: "0 auto",
  display: "block",
  transition: TRANSITIONS.default,
  ...(props.alt && {
    backgroundColor: COLORS.tile.alt.innerBorder,
  }),
  ...(props.horizontal && horizontalDividerStyles),
  ...(props.horizontal &&
    props.fullWidth && {
      width: `calc(100% + ${tileInnerPadding * 2}px)`,
      left: `-${tileInnerPadding}px`,
    }),
  ...((props.bottomStick || props.topStick) && {
    height: `calc(100% + ${tileInnerPadding + 1}px)`,
  }),
  ...(props.topStick && {
    top: `-${tileInnerPadding}px`,
  }),
  ...(props.fullHeight && {
    height: `calc(100% + ${tileInnerPadding * 2}px)`,
    top: `-${tileInnerPadding}px`,
  }),
  [MEDIA_QUERIES.w.ltXl]: {
    ...(props.hideLtXl && {
      display: "none",
    }),
    ...(props.horizontalLtXl && horizontalDividerStyles),
  },
  [MEDIA_QUERIES.w.ltLg]: {
    ...(props.horizontalLtLg && horizontalDividerStyles),
  },
  [MEDIA_QUERIES.w.gteMd]: {
    ...(props.hideGteMd && {
      display: "none",
    }),
  },
  [MEDIA_QUERIES.w.ltMd]: {
    ...(props.hideLtMd && { display: "none" }),
    ...(props.horizontalLtMd && horizontalDividerStyles),
  },
}));

const DownloadButton = styled("div")({
  display: "flex",
  alignItems: "center",
  marginLeft: 20,
  [MEDIA_QUERIES.w.ltSm]: {
    marginLeft: 0,
    marginTop: 10,
  },
});

const StyledTag = styled(({ collapsed, ...props }) => <Tag {...props} />)({
  display: "flex",
  marginLeft: "10px",
  fontWeight: "lighter",

  [MEDIA_QUERIES.w.ltMd]: {
    margin: "24px",
  },

  [MEDIA_QUERIES.w.ltSm]: {
    marginTop: 0,
  },
});

const StyledTagIcon = styled("div")({
  width: "18px",
  height: "18px",
  display: "flex",
  position: "relative",
  bottom: "2px",
  marginRight: "6px",
});

export {
  Tile,
  Inner,
  Title,
  SmallTitle,
  TileLabel,
  CollapseButton,
  Header,
  HeaderIcon,
  HeaderTitleContainer,
  HeaderSmallTitle,
  HeaderAction,
  HeaderActions,
  ChildrenContainer,
  HeaderLabel,
  Divider,
  DownloadButton,
  StyledTag,
  StyledTagIcon,
};
