/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Key } from "react";

import { Typography, Checkbox, Col, Row } from "antd";
import isEmpty from "lodash/isEmpty";
import isObject from "lodash/isObject";

import { DropdownMenuItem } from "shared/components/DropdownMenu/interfaces";
import Icon from "shared/components/Icon";
import MobileActionBar from "shared/components/Table/MobileActionBar";
import StarButton from "shared/components/Table/StarButton";

import { StyledTable } from "../Table/styled";

import {
  StyledDropdownMenu,
  TableColumn,
  Spacer,
  SmallText,
  BgText,
  Wrapper,
} from "./styled";

const { Text } = Typography;

export type Record = {
  key: Key;
  firstFieldValue: any;
  secondFileldValue?: any;
  secondFieldValue?: any;
  thirdFieldValue?: any;
  starButtonIsActive?: boolean;
  primaryAction?: any;
  viewed?: boolean;
  inlinePrimaryAction?: any;
  inlinePrimaryActionSpan?: number;
  dropdownItems?:
    | boolean
    | { key?: Key; label: any; onClick: () => void }[]
    | DropdownMenuItem[];
};

type DataRow = Omit<Record, "dropdownItems" | "secondFieldValue">;

export interface MobileTableProps {
  alt?: boolean;
  rows: DataRow[];
  secondFieldLabel?: string;
  thirdFieldLabel?: string;
  components?: any;
  starButtonOnClick?: (key?: Key) => void;
  dropdownItems?:
    | boolean
    | {
        key?: Key;
        label: any;
        onClick: () => void;
      }[];
  selectedRowKeys: Key[];
  onRowSelectionChange: any;
  checkboxActions?: {
    key: Key;
    label: string;
    onSelect: (values?: any) => void;
    singleSelectOnly?: boolean;
    singleSelectionOnly?: boolean;
    primary?: boolean;
    icon?: any;
  }[];
  placeholder?: any;
  pagination?: boolean | any;
  onRowClick?: any;
  expandable?: string[];
  loading?: boolean;
  noClickableRows?: boolean;
  withBorder: boolean;
}

const MobileTable = ({
  alt,
  rows,
  components,
  starButtonOnClick,
  dropdownItems,
  onRowClick,
  checkboxActions,
  selectedRowKeys,
  onRowSelectionChange,
  loading,
  pagination,
  expandable,
  noClickableRows,
  withBorder,
  ...props
}: MobileTableProps) => {
  const showDropdowns = dropdownItems !== undefined;
  const showStarButtons = starButtonOnClick !== undefined;
  const showCheckboxes = checkboxActions !== undefined;

  const rowCheckboxOnChange = (key) => (event) => {
    if (event.target.checked) {
      onRowSelectionChange([...selectedRowKeys, key]);
    } else {
      const nextSelectedRows = [...selectedRowKeys];
      const selectedRowIndex = nextSelectedRows.indexOf(key);
      nextSelectedRows.splice(selectedRowIndex, 1);
      onRowSelectionChange(nextSelectedRows);
    }
  };

  const primaryCheckboxOnChange = (event) => {
    if (event.target.checked) {
      onRowSelectionChange(rows.map((row) => row.key));
    } else {
      onRowSelectionChange([]);
    }
  };

  const primaryCheckboxAction = showCheckboxes
    ? checkboxActions.filter((action) => action.primary === true)[0]
    : undefined;

  const secondaryCheckboxAction = showCheckboxes
    ? checkboxActions.filter(
        (action) =>
          action.primary !== true && action.singleSelectionOnly !== true
      )[0]
    : undefined;

  const expandedRowRender = ({ expandedRow }) => {
    return expandedRow.map((row, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Wrapper key={index} className="mobile-expanded-row">
        {row.label && <SmallText>{row.label}: </SmallText>}
        {isObject(row.value) ? row.value : <BgText>{row.value}</BgText>}
      </Wrapper>
    ));
  };

  return (
    <div {...props}>
      <StyledTable
        alt={alt}
        dataSource={rows}
        components={components}
        onRow={(record) => ({
          onClick: onRowClick?.((record as DataRow).key),
        })}
        pagination={pagination}
        marginForPagination={
          pagination?.total / pagination?.defaultPageSize > 3
        }
        showHeader={false}
        expandIcon={({ expanded, onExpand, record }) =>
          expandable &&
          (expanded ? (
            <div onClick={(e) => onExpand(record, e)}>
              {/* @ts-ignore */}
              <Icon.ChevronBoldUpIcon />
            </div>
          ) : (
            <div onClick={(e) => onExpand(record, e)}>
              <Icon.ChevronDownIcon />
            </div>
          ))
        }
        expandable={!isEmpty(expandable)}
        expandedRowRender={expandable && expandedRowRender}
        loading={
          loading
            ? {
                indicator: (
                  <Icon.LoadingSpinnerGreenLargeIcon data-testid="loading" />
                ),
              }
            : false
        }
        noClickableRows={noClickableRows}
      >
        <TableColumn
          key="content"
          render={(text, record: Record) => (
            <Row type="flex" align="middle">
              {showStarButtons && (
                <Col xs={{ span: 2 }}>
                  <StarButton
                    data-testid={`star-button-${record.key}`}
                    onClick={() => starButtonOnClick(record.key)}
                    active={record.starButtonIsActive}
                  />
                </Col>
              )}
              {showCheckboxes && (
                <Col xs={{ span: 2 }}>
                  <Checkbox
                    data-testid={`checkbox-${record.key}`}
                    onChange={rowCheckboxOnChange(record.key)}
                    checked={selectedRowKeys.indexOf(record.key) > -1}
                  />
                </Col>
              )}
              <Col xs={{ span: 19 }}>
                <div>{record.firstFieldValue}</div>
                <Spacer />
                <Text>{record.secondFieldValue || "N/A"}</Text>
                {record.primaryAction && (
                  <>
                    <Spacer />
                    {record.primaryAction}
                  </>
                )}
                {record.thirdFieldValue && (
                  <Text>{record.thirdFieldValue}</Text>
                )}
              </Col>
              {record.inlinePrimaryAction && (
                <Col xs={{ span: record.inlinePrimaryActionSpan }}>
                  {record.inlinePrimaryAction}
                </Col>
              )}
              {showDropdowns && (
                <Col xs={{ span: 3 }}>
                  <StyledDropdownMenu
                    data-testid="dropdown-menu"
                    // @ts-ignore
                    items={record.dropdownItems || dropdownItems}
                  />
                </Col>
              )}
            </Row>
          )}
        />
      </StyledTable>
      {showCheckboxes && selectedRowKeys.length > 0 && (
        <MobileActionBar
          dropdownItems={checkboxActions
            .filter((action) => action.primary !== true)
            .map((action) => ({
              key: action.key,
              label: action.label,
              onClick: () => action.onSelect(selectedRowKeys),
            }))}
          primaryAction={{
            onClick: () => primaryCheckboxAction?.onSelect(selectedRowKeys),
            label: primaryCheckboxAction?.label,
          }}
          secondaryAction={{
            onClick: () => secondaryCheckboxAction.onSelect(selectedRowKeys),
            label: secondaryCheckboxAction?.label,
            icon: secondaryCheckboxAction?.icon,
          }}
          selectedRowsLength={selectedRowKeys.length}
          allRowsLength={rows.length}
          checkboxOnChange={primaryCheckboxOnChange}
          withBorder={withBorder}
        />
      )}
    </div>
  );
};

export default MobileTable;
