import React from 'react'
import _ from 'lodash'
import { ExplanationTooltip } from 'shared/components/vega/ExpressionEngine'
import { ApplicantWorkflow } from 'shared/components/vega/ApplicantWorkflow'
import { Payments } from 'shared/components/vega/Payments'
import { findLumenResult, queryByAnnotation, LumenV2 } from 'shared/components/vega/LumenV2'
import { LinkCard, ServiceCard } from 'shared/components/vega/Service'
import { Timestamp } from 'shared/components/vega/Timestamp'
import { RequiredDocuments } from 'shared/components/vega/Documents'
import { useGetApplicantFromApplicationService, useResolveApplicantPayments, useGetApplicantWorkflowRun, isWorkflowStarted } from 'shared/hooks/api'
import { printDollarsFromCents } from 'shared/utils/dollar-print'
import { everyNil } from 'shared/utils/vega'
import ApplicantHeader from './ApplicantHeader'

const ApplicantSummaryV2 = ({ application, id, lumenRun, legacyApp, refetchDeal }) => {
  const { applicant } = useGetApplicantFromApplicationService(id)
  const legacyAppId = applicant?.legacyId || legacyApp?.appId
  const basePath = `/applications/${legacyAppId}`

  const payments = useResolveApplicantPayments(applicant?.id, application?.feeLocation, 'before-application-submit', applicant?.party)

  const { applicantWorkflow } = useGetApplicantWorkflowRun(applicant?.id)
  const hasWorkflowStarted = isWorkflowStarted(applicantWorkflow)
  const hasStarted = hasWorkflowStarted || legacyApp?.info?.isSubmitted
  const isDone = applicantWorkflow?.done || legacyApp?.info?.isSubmitted

  const applicantLumenResult = findLumenResult(lumenRun, applicant?.id)
  const applicantLumenEvaluationHistory = applicantLumenResult?.evaluation?.history

  const hasBackgroundData = !_.isEmpty(legacyApp?.credit) || !_.isEmpty(legacyApp?.credit?.collectionAccounts) || !_.isEmpty(legacyApp?.criminal?.records) || !_.isEmpty(legacyApp?.eviction?.records)
  const showBackgroundCreditSection = hasStarted && applicant?.shouldRunBackgroundAndCreditChecks && hasBackgroundData && (!legacyApp?.info?.hasConditionallyProceedEnabled || legacyApp?.info?.isConditionallyProceeded)
  const backgroundCreditProgressStatus = applicant?.backgroundCreditCheck?.wasSkipped ? 'skipped' : (!applicant?.backgroundCreditCheck?.isComplete ? 'in-progress' : null)

  const creditScore = queryByAnnotation(applicantLumenResult, 'credit-score') || legacyApp?.credit?.creditScore
  const creditIdeal = queryByAnnotation(applicantLumenResult, ['credit-score-ideal', 'credit-ideal'], false)
  const creditConditionalRange = queryByAnnotation(applicantLumenResult, ['credit-score-range-conditional', 'credit-range-conditional'], false)
  const creditEntry = _.chain([creditIdeal, creditConditionalRange]).compact().last().value()
  const creditStatus = everyNil([creditIdeal, creditConditionalRange]) ? null : (creditIdeal?.result ? 'pass' : (creditConditionalRange?.result ? 'warn' : 'fail'))

  const identityVerificationStatus = applicant?.personaInquiryId ? (queryByAnnotation(applicantLumenResult, 'identity-verification') || !!legacyApp?.identity?.verified) : 'in-progress'

  return (
    <div className='vega container content'>
      <ApplicantHeader applicant={applicant} legacyApp={legacyApp} refetchDeal={refetchDeal} payments={payments}/>

      <div className='vega flex content'>
        <section className='vega flex column'>
          {isDone && <LinkCard path={`${basePath}/lumen`}>
            <LumenV2 lumenRun={lumenRun} applicantId={applicant?.id} card />
          </LinkCard>}

          <LinkCard path={`${basePath}/workflow`}>
            <ApplicantWorkflow applicantWorkflow={applicantWorkflow} card />
          </LinkCard>
        </section>

        {hasStarted && (legacyApp?.income || legacyApp?.balance || legacyApp?.employment) && <section className='vega flex column'>
          {legacyApp?.income && <ServiceCard
            label='VEROfied income'
            path={`${basePath}/income-and-assets-verification`}
            value={printDollarsFromCents(legacyApp?.income?.verifiedIncome)}
          />}

          {legacyApp?.balance && <ServiceCard
            label='VEROfied balances'
            path={`${basePath}/income-and-assets-verification`}
            value={printDollarsFromCents(legacyApp?.balance?.balance * 100)}
          />}

          {legacyApp?.employment && <ServiceCard
            label='employment'
            path={basePath}
            value={(legacyApp?.employment?.skipped && !legacyApp?.employment?.company) ? 'skipped' : legacyApp?.employment?.status}
          >
            {legacyApp?.employment?.company && <div>
              <div className='vega name'>{legacyApp?.employment?.company}</div>
              <div className='vega label smaller'>{legacyApp?.employment?.verificationType}</div>
            </div>}
          </ServiceCard>}
        </section>}

        {showBackgroundCreditSection && <section className='vega flex column'>
          {legacyApp?.credit && applicant?.backgroundCreditCheck && <ExplanationTooltip entry={creditEntry}>
            <ServiceCard
              label='credit score'
              path={`${basePath}/credit-and-background-check`}
              value={_.lowerCase(backgroundCreditProgressStatus) || creditScore}
              status={backgroundCreditProgressStatus || creditStatus}
            />
          </ExplanationTooltip>}

          {legacyApp?.credit?.collectionAccounts > 0 && <ServiceCard
            label='collection records'
            path={`${basePath}/credit-and-background-check`}
            value={_.lowerCase(backgroundCreditProgressStatus) || `${legacyApp?.credit?.collectionAccounts} records`}
            status={backgroundCreditProgressStatus}
          >
            {legacyApp?.credit?.collectionTotal && <span className='vega info amount'>{printDollarsFromCents(legacyApp?.credit?.collectionTotal)}</span>}
          </ServiceCard>}

          {legacyApp?.criminal?.records > 0 && <ServiceCard
            label='criminal records'
            path={`${basePath}/credit-and-background-check`}
            value={_.lowerCase(backgroundCreditProgressStatus) || `${legacyApp?.criminal?.records} records`}
            status={backgroundCreditProgressStatus}
          >
            {legacyApp?.criminal?.lastRecord && <span className='vega info'>{legacyApp?.criminal?.lastRecord}</span>}
          </ServiceCard>}

          {legacyApp?.eviction?.enabled && legacyApp?.eviction?.records > 0 && <ServiceCard
            label='eviction related proceedings'
            path={`${basePath}/credit-and-background-check`}
            value={_.lowerCase(backgroundCreditProgressStatus) || `${legacyApp?.eviction?.records} records`}
            status={backgroundCreditProgressStatus}
          >
            {legacyApp?.eviction?.lastRecord && <span className='vega info'>{legacyApp?.eviction?.lastRecord}</span>}
          </ServiceCard>}
        </section>}

        {hasStarted && <section className='vega flex column'>
          {legacyApp?.identity?.enabled && <ServiceCard
            label='identity'
            path={`${basePath}/verifications`}
            value={_.chain(applicant?.personaStatus).lowerCase().capitalize().value()}
            status={identityVerificationStatus}
          >
            {legacyApp?.identity?.documentType && <span className='vega name'>{legacyApp?.identity?.country} {legacyApp?.identity?.documentType}</span>}
          </ServiceCard>}

          {legacyApp?.vor?.enabled && !_.isNil(legacyApp?.vor?.score) && <ServiceCard
            label='VoR score'
            path={`${basePath}/vor-verifications`}
            value={legacyApp?.vor?.score}
            status={legacyApp?.vor?.score > 99 ? 'pass' : null}
          />}
        </section>}

        <section className='vega flex column'>
          <LinkCard path={`${basePath}/payments`}>
            <Payments payments={payments} card />
          </LinkCard>

          <LinkCard path={`${basePath}/attachments`}>
            <RequiredDocuments documents={applicant?.documents} card />
          </LinkCard>
        </section>
      </div>

      {applicant?.updatedAt && <Timestamp timestamp={applicant?.updatedAt} />}
    </div>
  )
}

export default ApplicantSummaryV2
