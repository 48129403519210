import React from "react";

import { Modal } from "shared/components/Modal";

import { CreateUnitSuccessModalProps } from "./interfaces";
import { Text } from "./styled";

const CreateUnitSuccessModal = ({
  unitName,
  propertyName,
  submit,
}: CreateUnitSuccessModalProps) => (
  <Modal
    title="Congratulations!"
    submit={submit}
    cancelLinkLabel="I'll do it later"
    submitButtonLabel="Create Listing"
  >
    <Modal.Body>
      <Text weak data-testid="confirmationMessage">
        Unit <strong>{unitName}</strong> has been successfully created for{" "}
        <strong>{propertyName}</strong>. Create a listing to invite applicants.
      </Text>
    </Modal.Body>
  </Modal>
);

export default CreateUnitSuccessModal;
