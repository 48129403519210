import type { Key } from 'react'

import _ from 'lodash'
import { queryCache, useMutation, useQuery } from 'react-query'

import { APPLICATION_QUERY_KEYS } from 'manager/hooks/api/applicationQueries'
import { GET_INCOME_BY_STREAMS } from 'renter/hooks/api'
import { QUERY_KEYS as APPLICATION_RENTER_QUERY_KEYS } from 'renter/hooks/api/applicationQueries'
import { IncomeByStreamsResponse } from 'renter/interfaces/api/income'
import { NOTIFICATIONS } from 'shared/config/constants'
import {
  EditIncomeAndAssetsParams,
  ApplicationError,
} from 'shared/interfaces/api'
import type {
  Document,
  DocumentInApplication,
} from 'shared/interfaces/api/document'
import api from 'shared/lib/api'
import { isManagerApp } from 'shared/utils/auth'
import { convertDollarsToCents, openNotification } from 'shared/utils/ui'

const GET_APPLICANT_EMERGENCY_CONTACTS = 'getApplicantEmergencyContacts'
const GET_APPLICANT_RESIDENTIAL_HISTORY = 'getApplicantResidentialHistory'
const GET_APPLICANT_EMPLOYMENT_HISTORY = 'getApplicantEmploymentHistory'
const GET_APPLICANT_REFERENCES = 'getApplicantReferences'
const LIST_APPLICANT_WORKFLOWS = 'listApplicantWorkflows'
const GET_APPLICANT_WORKFLOW_RUN = 'getApplicantWorkflowRun'
const GET_APPLICATION_FROM_APPLICATION_SERVICE =
  'getApplicationFromApplicationService'
const LIST_APPLICANTS_FROM_APPLICATION_SERVICE =
  'listApplicantsFromApplicationService'
const GET_APPLICANT_FROM_APPLICATION_SERVICE =
  'getApplicantFromApplicationService'
const LIST_APPLICANT_PAYMENTS = 'listApplicantPayments'
const UPSERT_APPLICANT_PAYMENT = 'upsertApplicantPayment'
const GET_OCR_RESULTS = 'getOcrResults'
const GET_OCR_RESULT_URL_BY_ID = 'getOcrResultUrlById'
const GET_OCR_RESULT_BY_ID = 'getOcrResultById'
const GET_OCR_JOBS = 'getOcrJobs'
const GET_DOCUMENTS = 'getDocuments'
const GET_DOCUMENT_DETAIL = 'getDocumentDetail'

// NOTE: Only an authorized user (manager) can fetch the full SSN.
// So, besides the special permission, as an additional security layer
// we check the user's password along with the application id to confirm
// they have the required privileges to access such information.
export const useViewFullSSN = () => {
  const [viewFullSSN, { isLoading: isLoadingSSN }] = useMutation(
    api.viewFullSSN,
  )

  return {
    viewFullSSN,
    isLoadingSSN,
  }
}

export function useGetApplicantEmergencyContacts() {
  const { data: emergencyContacts, isLoading: isEmergencyContactsLoading } =
    useQuery(
      [GET_APPLICANT_EMERGENCY_CONTACTS],
      () => api.getApplicantEmergencyContacts(),
      {
        onError: () => {
          openNotification(
            'Failed to load emergency contacts',
            NOTIFICATIONS.error,
          )
        },
      },
    )

  return {
    emergencyContacts,
    isEmergencyContactsLoading,
  }
}

export function useGetApplicantResidentialHistory() {
  const { data: residentialHistory, isLoading: isResidentialHistoryLoading } =
    useQuery(
      [GET_APPLICANT_RESIDENTIAL_HISTORY],
      () => api.getApplicantResidentialHistory(),
      {
        onError: () => {
          openNotification(
            'Failed to load residential history',
            NOTIFICATIONS.error,
          )
        },
      },
    )

  return {
    residentialHistory,
    isResidentialHistoryLoading,
  }
}

export function useGetApplicantEmploymentHistory() {
  const { data: employmentHistory, isLoading: isEmploymentHistoryLoading } =
    useQuery(
      [GET_APPLICANT_EMPLOYMENT_HISTORY],
      () => api.getApplicantEmploymentHistory(),
      {
        onError: () => {
          openNotification(
            'Failed to load employment history',
            NOTIFICATIONS.error,
          )
        },
      },
    )

  return {
    employmentHistory,
    isEmploymentHistoryLoading,
  }
}

export function useGetApplicantReferences() {
  const { data: references, isLoading: isReferencesLoading } = useQuery(
    [GET_APPLICANT_REFERENCES],
    () => api.getApplicantReferences(),
    {
      onError: () => {
        openNotification('Failed to load references', NOTIFICATIONS.error)
      },
    },
  )

  return {
    references,
    isReferencesLoading,
  }
}

export const useWithdrawApplication = (applicationId: number) => {
  const [withdrawApplication, { isLoading: isWithdrawApplication }] =
    useMutation(() => api.withdrawApplication({ applicationId }), {
      onSuccess: () => {
        openNotification(
          'You have successfully withdrawn application.',
          NOTIFICATIONS.info,
        )
        queryCache.invalidateQueries(
          APPLICATION_RENTER_QUERY_KEYS.getApplicationInvitation(applicationId),
        )
      },
      onError: (error: ApplicationError) =>
        openNotification(
          error.errors?.error || 'Failed to withdraw application.',
          NOTIFICATIONS.error,
        ),
    })

  return {
    withdrawApplication,
    isWithdrawApplication,
  }
}

export const useEditIncomeAndAssets = (applicationId: Key) => {
  const [editIncomeAndAssets, { isLoading: isLoadingEditIncomeAndAssets }] =
    useMutation(
      ({ income, assets }: EditIncomeAndAssetsParams) =>
        api.editIncomeAndAssets({ applicationId, income, assets }),
      {
        onSuccess: () => {
          openNotification(
            'You have successfully edited the applicant\'s total income and balances.',
            NOTIFICATIONS.info,
          )
          queryCache.invalidateQueries(
            APPLICATION_RENTER_QUERY_KEYS.getApplicationInvitation(
              applicationId,
            ),
          )
        },
        onError: (error: ApplicationError) =>
          openNotification(
            error.errors?.error || 'Failed to edit income and balances.',
            NOTIFICATIONS.error,
          ),
      },
    )

  return {
    editIncomeAndAssets,
    isLoadingEditIncomeAndAssets,
  }
}

export function useGetOcrResults(
  person: string,
  enable: boolean,
  includeRemoved?: boolean,
) {
  const {
    data: ocrResults,
    isLoading: isOcrResultsLoading,
    refetch,
  } = useQuery(
    [GET_OCR_RESULTS, { person }],
    () => api.getOcrResults(person, includeRemoved),
    {
      enabled: enable,
      onError: () => {
        openNotification('Failed to get ocr results', NOTIFICATIONS.error)
      },
    },
  )

  return {
    ocrResults: ocrResults?.data,
    isOcrResultsLoading,
    refetchOcrResults: refetch,
  }
}

export function useGetOcrResultById(id: string) {
  const { data: ocrResults } = useQuery(
    [GET_OCR_RESULT_BY_ID, { id }],
    () => api.getOcrResultById(id),
    {
      onError: () => {
        openNotification('Failed to get ocr results', NOTIFICATIONS.error)
      },
    },
  )

  return {
    ocrResult: ocrResults?.data,
  }
}

export function useGetOcrResultUrlById(id: string) {
  const { data: ocrResults } = useQuery(
    [GET_OCR_RESULT_URL_BY_ID, { id }],
    () => api.getOcrResultUrlById(id),
    {
      onError: () => {
        openNotification('Failed to get ocr results', NOTIFICATIONS.error)
      },
    },
  )

  return {
    ocrResult: ocrResults?.data,
  }
}

export const usePatchOcrResultById = () => {
  const [mutation] = useMutation(api.patchOcrResultById, {
    onSuccess: async (_, { id, data }) => {
      const notRevertPatch = Object.values(data).some(Boolean)
      await queryCache.invalidateQueries([GET_OCR_RESULT_BY_ID, { id }])
      if (notRevertPatch) {
        const {
          payer,
          formYear,
          payPeriodEndDate,
          payPeriodStartDate,
          payDate,
          grossAmount,
          netAmount,
        } = data
        queryCache.setQueryData(
          GET_INCOME_BY_STREAMS,
          (streams: IncomeByStreamsResponse) =>
            streams.map(({ streams, ...rest }) => ({
              streams: streams.map(({ incomes, ...rest }) => ({
                incomes: incomes.map(({ sourceOcrResult, ...rest }) => {
                  if (sourceOcrResult === id) {
                    return {
                      sourceOcrResult,
                      ...rest,
                      ...(payer && { description: payer }),
                      ...(payPeriodStartDate && {
                        periodStart: payPeriodStartDate,
                      }),
                      ...(payPeriodEndDate && { periodEnd: payPeriodEndDate }),
                      ...(payDate && { dateReceived: payDate }),
                      ...(grossAmount && {
                        grossAmount: convertDollarsToCents(Number(grossAmount)),
                      }),
                      ...(netAmount && {
                        netAmount: convertDollarsToCents(Number(netAmount)),
                      }),
                      ...(formYear && { year: Number(formYear) }),
                    }
                  }
                  return { sourceOcrResult, ...rest }
                }),
                ...rest,
              })),
              ...rest,
            })),
        )
      } else {
        setTimeout(async () => {
          await queryCache.invalidateQueries(GET_INCOME_BY_STREAMS)
        }, 1500)
      }
      openNotification(
        'Successfully updated the OCR result',
        NOTIFICATIONS.info,
      )
    },
    onError: () =>
      openNotification('Failed updating the OCR result', NOTIFICATIONS.error),
  })

  return {
    patchOcrResultById: mutation,
  }
}

export const useDeleteOcrResultById = () => {
  const [mutation] = useMutation(api.deleteOcrResults, {
    onSuccess: () =>
      openNotification(
        "Successfully removed the document upload result from the applicant's income",
        NOTIFICATIONS.info,
      ),
    onError: () =>
      openNotification(
        "Failed to remove the document upload result from the applicant's income",
        NOTIFICATIONS.error,
      ),
  })

  return {
    deleteOcrResultById: mutation,
  }
}

export function useGetApplicationFromApplicationService(id) {
  const { data: application, isLoading } = useQuery(
    [GET_APPLICATION_FROM_APPLICATION_SERVICE, { id }],
    () => api.getApplicationFromApplicationService(id),
    {
      enabled: !!id,
    },
  )

  return {
    application,
    isLoading,
  }
}

export function useListApplicantsFromApplicationService(application) {
  const { data: applicants, isLoading } = useQuery(
    [LIST_APPLICANTS_FROM_APPLICATION_SERVICE, { application }],
    () => api.listApplicantsFromApplicationService(application),
    {
      enabled: !!application,
    },
  )

  return {
    applicants,
    isLoading,
  }
}

export function useGetApplicantFromApplicationService(id) {
  const { data: applicant, isLoading } = useQuery(
    [GET_APPLICANT_FROM_APPLICATION_SERVICE, { id }],
    () => api.getApplicantFromApplicationService(id),
    {
      enabled: !!id,
    },
  )

  return {
    applicant,
    isLoading,
  }
}

export function useListApplicantWorkflows(
  location: string,
  detailed: boolean = false,
) {
  const { data: workflows, isLoading } = useQuery(
    [LIST_APPLICANT_WORKFLOWS, _.pickBy({ location, detailed })],
    () => api.listApplicantWorkflows(location, detailed),
    {
      enabled: !!location,
    },
  )

  return {
    workflows,
    isLoading,
  }
}

export function useGetApplicantWorkflowRun(id) {
  const {
    data: applicantWorkflow,
    isLoading,
    refetch,
  } = useQuery(
    [GET_APPLICANT_WORKFLOW_RUN, { id }],
    () => api.getApplicantWorkflowRun(id),
    {
      enabled: !!id,
    },
  )

  return {
    applicantWorkflow,
    isLoading,
    refetch,
  }
}

export function isWorkflowStarted(applicantWorkflow) {
  const errorMessage =
    applicantWorkflow?.error?.message ||
    applicantWorkflow?.error?.errorMessage ||
    'An error occurred while getting the workflow'
  return !/^((person.+not found)|not found)$/i.test(errorMessage)
}

export function useApplicantWorkflowMutations() {
  const handlers = (s) => ({
    onSuccess: async (data) => {
      await queryCache.invalidateQueries('listApplicantWorkflows')
      await queryCache.invalidateQueries('getApplicantWorkflowRun')

      openNotification(
        _.chain([s, data?.id]).compact().join(' ').value(),
        NOTIFICATIONS.info,
      )
    },
    onError: (error) =>
      openNotification(
        error?.message || error?.errorMessage || error,
        NOTIFICATIONS.error,
      ),
  })

  const [runApplicantWorkflowMutation, runApplicantWorkflowStatus] =
    useMutation(api.runApplicantWorkflow, handlers('ran workflow'))
  const [createApplicantWorkflowMutation, createApplicantWorkflowStatus] =
    useMutation(api.createApplicantWorkflow, handlers('created workflow'))
  const [updateApplicantWorkflowMutation, updateApplicantWorkflowStatus] =
    useMutation(api.updateApplicantWorkflow, handlers('saved workflow'))
  const [deleteApplicantWorkflowMutation, deleteApplicantWorkflowStatus] =
    useMutation(api.deleteApplicantWorkflow, handlers('deleted workflow'))

  return {
    runApplicantWorkflow: runApplicantWorkflowMutation,
    runApplicantWorkflowLoading: runApplicantWorkflowStatus?.isLoading,
    createApplicantWorkflow: createApplicantWorkflowMutation,
    createApplicantWorkflowLoading: createApplicantWorkflowStatus?.isLoading,
    updateApplicantWorkflow: updateApplicantWorkflowMutation,
    updateApplicantWorkflowLoading: updateApplicantWorkflowStatus?.isLoading,
    deleteApplicantWorkflow: deleteApplicantWorkflowMutation,
    deleteApplicantWorkflowLoading: deleteApplicantWorkflowStatus?.isLoading,
  }
}

export function useListApplicantPayments(applicant) {
  const {
    data: payments,
    isLoading,
    refetch,
  } = useQuery(
    [LIST_APPLICANT_PAYMENTS, { applicant }],
    () => api.listApplicantPayments(applicant),
    {
      enabled: !!applicant,
    },
  )

  return {
    payments,
    isLoading,
    refetch,
  }
}

export function useUpsertApplicantPayment(applicant, location, step, party) {
  const {
    data: payment,
    isLoading,
    refetch,
  } = useQuery(
    [UPSERT_APPLICANT_PAYMENT, { applicant, location, step, party }],
    () => api.upsertApplicantPayment(applicant, location, `${step}:${party}`),
    {
      enabled: applicant && location && step && party,
    },
  )

  return {
    payment,
    isLoading,
    refetch,
  }
}

export function useResolveApplicantPayments(applicant, location, step, party) {
  return _.chain([
    useListApplicantPayments(applicant)?.payments,
    useUpsertApplicantPayment(applicant, location, step, party)?.payment,
  ])
    .compact()
    .flatten()
    .uniqBy('id')
    .value()
}

export const useGetOcrJobs = (
  {
    id,
    person,
  }: { id: string; person?: never } | { id?: never; person: string },
  enable: boolean,
) => {
  const {
    data: ocrJobs,
    isLoading: isOcrJobsLoading,
    refetch,
  } = useQuery(
    [GET_OCR_JOBS, { ...(id && { id }), ...(person && { person }) }],
    () => api.getOcrJobs(id, person),
    {
      enabled: enable,
      onError: () => {
        openNotification(
          'Failed to get document processing jobs',
          NOTIFICATIONS.error,
        )
      },
    },
  )

  return {
    ocrJobs: ocrJobs?.data,
    isOcrJobsLoading,
    refetchOcrJobs: refetch,
  }
}

export const useGetDocuments = (owner: string, enable: boolean) => {
  const {
    data: documents,
    isLoading: isDocumentsLoading,
    refetch,
  } = useQuery([GET_DOCUMENTS, { owner }], () => api.getDocuments(owner), {
    enabled: enable,
    onError: () => {
      openNotification(
        'Failed to get document processing jobs',
        NOTIFICATIONS.error,
      )
    },
  })

  return {
    documents: documents?.data,
    isDocumentsLoading,
    refetchDocuments: refetch,
  }
}

export const useGetDocumentDetail = (id: string) => {
  const { data: documents, ...rest } = useQuery(
    [GET_DOCUMENT_DETAIL, id],
    () => api.getDocumentDetail(id),
    {
      onError: () => {
        openNotification(
          'Failed to get document processing jobs',
          NOTIFICATIONS.error,
        )
      },
    },
  )

  return {
    document: documents?.data,
    ...rest,
  }
}

export const useDeleteDocumentById = (applicationId: number) => {
  const applicationQueryKey = isManagerApp()
    ? [APPLICATION_QUERY_KEYS.getFullApplication(applicationId)]
    : APPLICATION_RENTER_QUERY_KEYS.getApplicationSummary(applicationId)
  const [mutation, rest] = useMutation(api.deleteDocument, {
    onMutate: (id) => {
      queryCache.cancelQueries(applicationQueryKey)
      const previousApplication = queryCache.getQueryData(applicationQueryKey)
      const updateRequiredDocument = (documents: DocumentInApplication[]) =>
        documents.map(({ documents, ...rest }) => ({
          documents: documents.filter((document) => document.id !== id),
          ...rest,
        }))
      const updateDocument = (documents: Document[]) =>
        documents.filter((document) => document.id !== id)

      queryCache.setQueryData(applicationQueryKey, (application) =>
        _.chain(application)
          .update('financialDocuments', updateRequiredDocument)
          .update('requiredDocuments', updateRequiredDocument)
          .update('unverifiedIncomeDocuments', updateRequiredDocument)
          .update('unverifiedBackgroundDocuments', updateRequiredDocument)
          .update('snapshot.applicantDocuments', updateDocument)
          .update('snapshot.originalDocuments', updateDocument)
          .value(),
      )
      return { previousApplication }
    },
    onSuccess: () => {
      openNotification(
        'Successfully removed the document from the application',
        NOTIFICATIONS.info,
      )
    },
    onError: (error, doc, context: { previousApplication: any }) => {
      queryCache.setQueryData(applicationQueryKey, context.previousApplication)
      openNotification(
        'Failed to remove the document from the application',
        NOTIFICATIONS.error,
      )
    },
  })

  return {
    deleteDocumentById: mutation,
    ...rest,
  }
}

export const useUploadDocument = (applicationId: number) => {
  const applicationQueryKey = isManagerApp()
    ? [APPLICATION_QUERY_KEYS.getFullApplication(applicationId)]
    : APPLICATION_RENTER_QUERY_KEYS.getApplicationSummary(applicationId)
  const [mutation, rest] = useMutation(
    ({
      description,
      filename,
      ocr,
      owner,
      ownerType,
    }: Pick<Document, 'description' | 'filename' | 'owner' | 'ownerType'> & {
      file: File
      ocr: boolean
    }) =>
      api.createDocument({
        description,
        filename,
        ocr,
        owner,
        ownerType,
      }),
    {
      onError: () => {
        openNotification(
          'The file upload failed, please try a different file or try again.',
          NOTIFICATIONS.error,
        )
      },
      onSuccess: async ({ data: response }, { file, ownerType }) => {
        await api.uploadDocument(response.uploadUrl, file)
        queryCache.setQueryData(
          applicationQueryKey,
          (application: {
            financialDocuments: Document[]
            snapshot: {
              applicantDocuments: Document[]
              originalDocuments: Document[]
            }
          }) => {
            const updateRequiredDocument = (
              requiredDocuments: DocumentInApplication[],
            ) =>
              requiredDocuments.map(
                ({ documents, formType, name, ...rest }) => ({
                  documents:
                    formType === response.type || name === response.description
                      ? [...documents, response]
                      : documents,
                  formType,
                  name,
                  ...rest,
                }),
              )

            return _.chain(application)
              .update('financialDocuments', updateRequiredDocument)
              .update('requiredDocuments', updateRequiredDocument)
              .update('unverifiedIncomeDocuments', updateRequiredDocument)
              .update('unverifiedBackgroundDocuments', updateRequiredDocument)
              .update(
                `snapshot.${
                  ownerType === 'person'
                    ? 'originalDocuments'
                    : 'applicantDocuments'
                }`,
                (documents: Document[]) => [...documents, response],
              )
              .value()
          },
        )
      },
    },
  )
  return { uploadDocument: mutation, ...rest }
}
