import styled from "@emotion/styled";

import { Row, Col } from "antd";

import { COLORS } from "shared/config/constants";

export const Layout = styled("section")`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${COLORS.leasePreview.background};
`;

export const Content = styled(Col)`
  height: 100%;
  position: relative;
`;

export const StyledRow = styled(Row)`
  height: 100%;
`;

export const SideBar = styled(Col)`
  height: 100%;
  padding-left: 10px;
`;
