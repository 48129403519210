import React from "react";

import Icon from "shared/components/Icon";

import { MessagesListBoxContent } from "./styled";

interface MessagesListBoxProps {
  messages: {
    icon?: React.ElementType;
    message: string | React.ReactNode;
  }[];
  noBorderTop?: boolean;
  className?: string;
}

const MessagesListBox = ({
  messages,
  noBorderTop = false,
  className = undefined,
}: MessagesListBoxProps) => (
  <MessagesListBoxContent
    numItems={messages?.length}
    noBorderTop={noBorderTop}
    className={className}
  >
    <ul>
      {messages.map((messageItem) => {
        const MessageIcon = messageItem.icon || Icon.OrangeExclamation;
        return (
          // @ts-ignore
          <li key={messageItem.message}>
            <div className="message-icon">
              <MessageIcon />
            </div>
            <strong>{messageItem.message}</strong>
          </li>
        );
      })}
    </ul>
  </MessagesListBoxContent>
);

export default MessagesListBox;
