import React from "react";

import { Col, Row } from "antd";
import pluralize from "pluralize";

import { Button } from "shared/components/Button";
import { AttachmentFile } from "shared/components/Form/UploadWall";
import { UnderlineLink } from "shared/components/Links";
import { RegularText, SmallText } from "shared/components/Typography";

import { AttachmentGreenHeader } from "./styled";

interface AttachmentHeadersProps {
  showPendingUpdatesHeader: boolean;
  addedAttachmentsCount: number;
  deletedAttachmentsCount: number;
  confirmApplicationAttachments?: (
    attachmentsChangedVisiblility: AttachmentFile[],
    callback: () => void
  ) => void;
  cancelApplicationAttachments?: (callback: () => void) => void;
  loading?: boolean;
  attachmentsChangedVisiblility: AttachmentFile[];
}

const AttachmentHeaders = ({
  showPendingUpdatesHeader,
  addedAttachmentsCount,
  deletedAttachmentsCount,
  confirmApplicationAttachments,
  cancelApplicationAttachments,
  loading,
  attachmentsChangedVisiblility,
}: AttachmentHeadersProps) => (
  <>
    {showPendingUpdatesHeader && (
      <AttachmentGreenHeader>
        <div className="attachment-header-message">
          <>
            <span>You&apos;ve&nbsp;</span>
            <span>
              uploaded&nbsp;
              <strong>
                {addedAttachmentsCount} new&nbsp;
                {pluralize("attachment", addedAttachmentsCount)}
              </strong>
            </span>
            {deletedAttachmentsCount > 0 && (
              <span>
                &nbsp;and&nbsp;deleted&nbsp;
                <strong>
                  {deletedAttachmentsCount}&nbsp;
                  {pluralize("attachment", deletedAttachmentsCount)}
                </strong>
              </span>
            )}
            <span>.&nbsp;</span>
          </>
          <span>
            Please confirm to update the attachments and recreate the report.
          </span>
          <br />

          <>
            <SmallText>
              Here’s a breakdown of the visibility updates on those attachments
            </SmallText>
            <Row>
              <Col xs={12} md={8}>
                {/* @ts-ignore */}
                <RegularText underline>
                  Internal Only Attachments (Managers Only)
                </RegularText>
                {attachmentsChangedVisiblility
                  .filter(({ isPublic }) => !isPublic)
                  .map(({ id, name }) => (
                    <div key={id}>{name}</div>
                  ))}
              </Col>
              <Col xs={12} md={8}>
                {/* @ts-ignore */}
                <RegularText underline>
                  Renter Facing Attachments (Visible to Applicant)
                </RegularText>
                {attachmentsChangedVisiblility
                  .filter(({ isPublic }) => isPublic)
                  .map(({ id, name }) => (
                    <div key={id}>{name}</div>
                  ))}
              </Col>
            </Row>
          </>
        </div>
        <UnderlineLink
          className="attachment-header-cancel-link"
          disabled={loading}
          onClick={cancelApplicationAttachments}
        >
          Cancel
        </UnderlineLink>
        <Button
          type="secondary"
          className="attachment-header-confirm-button"
          loading={loading}
          onClick={confirmApplicationAttachments}
        >
          Confirm
        </Button>
      </AttachmentGreenHeader>
    )}
  </>
);

export default AttachmentHeaders;
