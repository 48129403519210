import React from "react";

import { Button } from "shared/components/Button";
import FlexContainer from "shared/components/FlexContainer";

import { StyledFooterBar } from "../styled";

interface LeadConvertedFooterProps {
  redirectToDeal: () => void;
}

const LeadConvertedFooter = ({ redirectToDeal }: LeadConvertedFooterProps) => (
  <StyledFooterBar>
    <FlexContainer alignItems="center" justifyContent="flex-end" fullWidth>
      {/* @ts-ignore */}
      <Button type="primary" onClick={redirectToDeal}>
        VIEW APPLICATION
      </Button>
    </FlexContainer>
  </StyledFooterBar>
);

export default LeadConvertedFooter;
