import React from "react";
import _ from "lodash";
import { useMediaQuery } from "react-responsive";

import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import InformationModal from "shared/components/Modals/InformationModal";
import { Modal } from "shared/components/Modal";
import Table from "shared/components/Table";
import Tile from "shared/components/Tile";
import Tooltip from "shared/components/Tooltip";
import { RegularText } from "shared/components/Typography";
import { ImageLink } from "shared/components/Links";
import {
  BREAKPOINT_RESOLUTIONS,
  COLORS,
  colors,
} from "shared/config/constants";
import useModal from "shared/hooks/useModal";

import { formatDate } from "../../../utils/ui";

import {
  AccordionContainer,
  OcrolusSignalHeader,
  HeaderMainLabel,
  TileCustom,
  CapitalizedContainer,
  SupportingDataText,
  FraudFormImageLink,
  NoFraudFormImageContainer,
} from "./styled";

const SIGNAL_STATUS = Object.freeze({
  VALID: "valid",
  INVALID: "invalid",
  REMOVED: "removed",
  FRAUDULENT: "fraudulent"
});

const SIGNAL_TYPES = Object.freeze({
  BANK_STATEMENT: {
    formType: "bank-statement",
    label: "Bank Statement",
  },
  PAYSTUB: {
    formType: "paystub",
    label: "PayStub",
  },
  W2: {
    formType: "w2",
    label: "W2",
  },
  UNCLASSIFIED: {
    formType: "unclassified",
    label: "Unclassified",
  },
  1040: {
    formType: "1040",
    label: "1040",
  },
  1099: {
    formType: "1099",
    label: "1099",
  },
  other: {
    formType: "other",
    label: "other",
  },
});

const OCROLUS_STATUS_VIEW_INFO = Object.freeze({
  [SIGNAL_STATUS.VALID]: {
    Icon: Icon.CheckGreenBig,
    color: COLORS.accentDark,
  },
  [SIGNAL_STATUS.INVALID]: {
    Icon: Icon.OrangeExclamationHollow,
    color: COLORS.darkOrange,
  },
  [SIGNAL_STATUS.REMOVED]: {
    Icon: Icon.GrayExclamationHollow,
    color: colors.neutral[600],
  },
  [SIGNAL_STATUS.FRAUDULENT]: {
    Icon: Icon.RedExclamationHollow,
    color: COLORS.red,
  },
});

const fraudSignalColumns = [
  {
    title: "Fraud Signals",
    dataIndex: "fraudSignals",
    key: "fraudSignals",
  },
  {
    title: "Instances",
    dataIndex: "instances",
    key: "instances",
  },
  {
    title: "Details",
    dataIndex: "details",
    key: "details",
  },
  {
    title: "Fraud Visualizations",
    dataIndex: "fraudVisualizations",
    key: "fraudVisualizations",
  },
];

const responsiveColumns = {
  xs: {
    column1: "fraudSignals",
    column2: "instances",
    column3: "details",
    column5: "fraudVisualizations",
  },
  sm: ["fraudSignals", "instances"],
  md: ["fraudSignals", "details", "instances"],
};

const supportingDataColumns = [
  {
    title: "Field Name",
    dataIndex: "key",
    key: "keyField",
  },
  {
    title: "Fraud Type",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Value",
    dataIndex: "value",
    key: "value",
  },
];

const supportingResponsiveColumns = {
  xs: {
    column1: "key",
    column2: "name",
    column3: "value",
  },
  sm: ["key", "name"],
  md: ["key", "name", "value"],
};

const noDataPlaceholder = {
  Icon: Icon.NoDataIcon,
  label: "Signals",
};

const confirmDeleteOcrResultModal = ({ submit, filename }) => (
  <Modal title="Remove Income Document?" submit={submit}>
    <Modal.Body>
      <div>
        By selecting “Confirm” below, you will exclude the following {_.toLower(filename)} result from the applicant&apos;s income calculation.
        This can also affect their Lumen formula output as a result.
      </div>
      <br/>
      <div>
        <strong>This action cannot be reversed.</strong>
      </div>
      <br/>
      <div>
        Are you sure you wish to continue?
      </div>
    </Modal.Body>
  </Modal>
);

const OcrolusAccordion = ({
  ocrolusSignals,
  canViewOcrResults,
  dealDecisionIsMade,
  deleteOcrResultById,
  refetchOcrResults,
}) => {
  const filteredOcrResults = _.filter(ocrolusSignals, (result) => {
    return (
      _.isEmpty(result.removedAt) ||
      (!_.isEmpty(result.removedAt) &&
        _.get(result, "removeReason") === "excluded-by-employee")
    );
  });
  const { openModalDialog } = useModal();
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT_RESOLUTIONS.sm });

  const sortFraudSignals = (signals) => {
    const removedByEmployee = _.filter(signals, (signal) => {
      return (
        !_.isEmpty(_.get(signal, "removedAt")) &&
        _.get(signal, "removeReason") === "excluded-by-employee"
      );
    });
    const activeSignals = _.filter(signals, (signal) =>
      _.isEmpty(_.get(signal, "removedAt"))
    );

    // Split into positive and negative buckets
    const positive = activeSignals.filter(
      (signal) => signal.fraudSignals.length <= 0
    );
    const negative = activeSignals.filter(
      (signal) => signal.fraudSignals.length > 0
    );
    const fraudulent = _.filter(negative, ocrResult => ocrResult.isFraudulent);
    const notFraudulent = _.filter(negative, ocrResult => !ocrResult.isFraudulent);

    const getDate = (signal) => {
      if (signal.formType === "paystub") {
        return new Date(signal.captured?.payPeriodStartDate?.value);
      }
      if (signal.formType === "w2") {
        return new Date(signal.captured?.formYear?.value);
      }
      return new Date(0);
    };
    // Compare dates
    const compareDates = (a, b) => {
      return getDate(b).getTime() - getDate(a).getTime();
    };

    // Sort within each bucket
    const sortWithinBucket = (bucket) => {
      const groups = bucket.reduce((acc, signal) => {
        (acc[signal.formType] = acc[signal.formType] || []).push(signal);
        return acc;
      }, {});

      return Object.keys(groups)
        .sort()
        .flatMap((formType) => groups[formType].sort(compareDates));
    };

    return [
      ...sortWithinBucket(positive),
      ...sortWithinBucket(notFraudulent),
      ...sortWithinBucket(fraudulent),
      ...sortWithinBucket(removedByEmployee),
    ];
  };

  const ocrolusHeader = (signal) => {
    let signalType = signal.formType.toUpperCase();

    if (signalType === 'BANK-STATEMENT') {
      signalType = 'BANK_STATEMENT'
    } else if (signalType.includes('1099')) {
      signalType = '1099'
    } else if (signalType.includes('1040')) {
      signalType = '1040'
    } else if (!SIGNAL_TYPES[signalType]) {
      signalType = 'other'
    }

    const signalFraudInstances = signal.fraudSignals.length;
    const isRemoved = !_.isEmpty(_.get(signal, "removedAt"));
    const isFraudulent = _.get(signal, 'isFraudulent');

    const ocrResultStatus = isRemoved
      ? SIGNAL_STATUS.REMOVED
      : isFraudulent
        ? SIGNAL_STATUS.FRAUDULENT
        :signalFraudInstances > 0
          ? SIGNAL_STATUS.INVALID
          : SIGNAL_STATUS.VALID

    const statusInfo = OCROLUS_STATUS_VIEW_INFO[ocrResultStatus];

    const isPayStubWithStartDate =
      signalType === SIGNAL_TYPES.PAYSTUB.formType.toUpperCase() &&
      signal.captured?.payPeriodStartDate?.value !== "N/A";

    const isW2WithYear =
      signalType === SIGNAL_TYPES.W2.formType.toUpperCase() &&
      signal.captured?.formYear?.value !== "N/A";

    const fraudSignalText = (
      <>
        <HeaderMainLabel color={statusInfo?.color}>
          {`${SIGNAL_TYPES[signalType].label}`}
        </HeaderMainLabel>
        (
        {signalFraudInstances === 1
          ? `1 Potential Fraud Signal`
          : signalFraudInstances > 1
          ? `${signalFraudInstances} Potential Fraud Signals`
          : "No Fraud Detected"}
        )
      </>
    );

    const signalFormImageUrl = signal.url;
    const FraudSignalTextContainer = signalFormImageUrl
      ? FraudFormImageLink
      : NoFraudFormImageContainer;

    return (
      <OcrolusSignalHeader>
        <FlexContainer alignItems="center" gap="4px">
          {statusInfo?.Icon && (
            <statusInfo.Icon width="24" height="24" className="status-icon" />
          )}
          <FraudSignalTextContainer
            target="_blank"
            rel="noreferrer"
            href={signalFormImageUrl}
            color={statusInfo?.color}
          >
            {fraudSignalText}
          </FraudSignalTextContainer>
          {signalFormImageUrl && !isMobile && (
            <ImageLink imageUrl={signalFormImageUrl} color={statusInfo?.color} />
          )}
          {signalFraudInstances > 0 &&
            !dealDecisionIsMade &&
            canViewOcrResults &&
            !isRemoved &&
            !isFraudulent && (
              <Tooltip title="Remove Income Document">
                <Icon.RemoveOne
                  onClick={() => {
                    openModalDialog(confirmDeleteOcrResultModal, {
                      submit: () => {
                        deleteOcrResultById(signal?.id);
                        setTimeout(() => {
                          refetchOcrResults();
                        }, 5000);
                      },
                      filename: `${SIGNAL_TYPES[signalType].label}`,
                    });
                  }}
                />
              </Tooltip>
            )}
            {
              isFraudulent && (
                <NoFraudFormImageContainer
                  color={statusInfo?.color}
                >
                  Excluded Due to Fraud Detection
                </NoFraudFormImageContainer>
              )
            }
            {
              isRemoved && (
                <NoFraudFormImageContainer
                  color={statusInfo?.color}
                >
                  Manually Excluded
                </NoFraudFormImageContainer>
              )
            }
        </FlexContainer>
        {!isMobile && (
          <FlexContainer>
            {isPayStubWithStartDate && (
              <FlexContainer>
                {formatDate(signal.captured?.payPeriodStartDate?.value)} -{" "}
                {formatDate(signal.captured?.payPeriodEndDate?.value)}
              </FlexContainer>
            )}
            {isW2WithYear && (
              <FlexContainer>{signal.captured?.formYear?.value}</FlexContainer>
            )}
          </FlexContainer>
        )}
      </OcrolusSignalHeader>
    );
  };

  const renderSupportingData = ({ key, name, value }) => {
    return {
      key,
      values: [
        <CapitalizedContainer>
          <RegularText>{key}</RegularText>
        </CapitalizedContainer>,
        <CapitalizedContainer>
          <RegularText>{name}</RegularText>
        </CapitalizedContainer>,
        <CapitalizedContainer>
          <RegularText>{value}</RegularText>
        </CapitalizedContainer>,
      ],
    };
  };

  const renderSignal = (signal) => {
    const hasFraudSignals = signal.fraudSignals.length > 0;
    const hasFraudSignalsAndCanViewResults =
      hasFraudSignals && canViewOcrResults;
    const isRemoved = !_.isEmpty(_.get(signal, "removedAt"));
    const isFraudulent = _.get(signal, 'isFraudulent');
    const fraudClass = isFraudulent
      ? SIGNAL_STATUS.FRAUDULENT
      : isRemoved
        ? SIGNAL_STATUS.REMOVED
        : hasFraudSignals
          ? SIGNAL_STATUS.INVALID
          : SIGNAL_STATUS.VALID;
    const viewClass = hasFraudSignalsAndCanViewResults ? "can-view" : "";
    const fraudVisualizations =
      signal.fraudVisualizations.length > 0 ? (
        <FlexContainer>
          {signal.fraudVisualizations.map((fraudVisualization) => (
              <ImageLink 
              width="30px"
              height="30px"
              imageUrl={_.get(fraudVisualization, 'signedUrl')}
            />
            ))}
        </FlexContainer>
      ) : (
        <CapitalizedContainer>No Image Generated</CapitalizedContainer>
      );
    return (
      <TileCustom
        header={{
          title: ocrolusHeader(signal),
          collapsibleOnDesktop: hasFraudSignalsAndCanViewResults,
          collapsibleOnMobile: hasFraudSignalsAndCanViewResults,
          defaultCollapsed: true,
        }}
        className={`${fraudClass} ${viewClass}`}
      >
        {hasFraudSignalsAndCanViewResults && (
          <Tile key={0} noBorder noMarginBottom>
            <div>
              <Table
                columns={fraudSignalColumns}
                columnBreakpoints={responsiveColumns}
                placeholder={noDataPlaceholder}
                rows={signal.fraudSignals.map((fraudSignal, index) => ({
                  key: `${fraudSignal.name}-${index}`,
                  values: [
                    <CapitalizedContainer>
                      <RegularText>{fraudSignal.name}</RegularText>
                    </CapitalizedContainer>,
                    <CapitalizedContainer>
                      <RegularText>{fraudSignal.count}</RegularText>
                    </CapitalizedContainer>,
                    <CapitalizedContainer>
                      <SupportingDataText
                        onClick={() => {
                          openModalDialog(InformationModal, {
                            title: "Supporting Data",
                            description: (
                              <div>
                                <Table
                                  id={`${fraudSignal.name}-${index}`}
                                  columns={supportingDataColumns}
                                  columnBreakpoints={
                                    supportingResponsiveColumns
                                  }
                                  placeholder={noDataPlaceholder}
                                  rows={fraudSignal.supportingData.map(
                                    renderSupportingData
                                  )}
                                  pagination={false}
                                  scroll={{ y: 600 }}
                                  noClickableRows
                                  alt
                                />
                              </div>
                            ),
                            centered: true,
                            width: 1000,
                          });
                        }}
                      >
                        View Details
                      </SupportingDataText>
                    </CapitalizedContainer>,
                    fraudVisualizations,
                  ],
                }))}
                pagination={false}
                noClickableRows
              />
            </div>
          </Tile>
        )}
      </TileCustom>
    );
  };

  return (
    <AccordionContainer className="accordionContainer">
      {sortFraudSignals(filteredOcrResults).map((signal) =>
        renderSignal(signal)
      )}
    </AccordionContainer>
  );
};

export default OcrolusAccordion;
