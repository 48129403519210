import styled from "@emotion/styled";
import { Row } from "antd";

const PersonInfoDetails = styled(Row)`
  flex-grow: 1;

  .divider {
    margin: 7px 12px 0 12px;
    height: 10px;
  }
`;

const PersonIcon = styled.div`
  margin-right: 16px;
`;

const PersonName = styled.strong`
  font-size: 17px;
`;

export { PersonInfoDetails, PersonIcon, PersonName };
