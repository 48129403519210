import _ from 'lodash'
import React from 'react'
import RadioButtons from 'shared/components/RadioButtons'

const CalculationSettings = ({ settings, canEdit, onChange }) => {
  const {
    incomeWindowPeriod,
    incomeRentRatioPeriod,
    totalIncomeWithGuarantor,
    totalAssetsWithGuarantor,
    creditScoreScope
  } = settings

  const handleChange = (k) => (value) => onChange(_.set(settings, k, value))

  return (
    <div className='vega flex gap small column'>
      <div className='vega flex centered'>
        <span className='vega label larger'>Income Window Period:</span>
        <RadioButtons
          menuItems={[{ key: 'P1Y', name: 'Annual' }, { key: 'P1M', name: 'Monthly' }]}
          activeItem={incomeWindowPeriod || 'P1Y'}
          onChange={handleChange('incomeWindowPeriod')}
          disabled={!canEdit}
          theme='gray'
        />
      </div>

      <div className='vega flex centered'>
        <span className='vega label larger'>Income Rent Ratio Period:</span>
        <RadioButtons
          menuItems={[{ key: 'P1Y', name: 'Annual' }, { key: 'P1M', name: 'Monthly' }]}
          activeItem={incomeRentRatioPeriod || 'P1Y'}
          onChange={handleChange('incomeRentRatioPeriod')}
          disabled={!canEdit}
          theme='gray'
        />
      </div>

      <div className='vega flex centered'>
        <span className='vega label larger'>Total Income With Guarantor:</span>
        <RadioButtons
          menuItems={[{ key: 'COMBINE_APPLICANTS_GUARANTORS', name: 'Combine Applicants and Guarantor' }, { key: 'ONLY_GUARANTOR', name: 'Only Guarantors' }]}
          activeItem={totalIncomeWithGuarantor || 'COMBINE_APPLICANTS_GUARANTORS'}
          onChange={handleChange('totalIncomeWithGuarantor')}
          disabled={!canEdit}
          theme='gray'
        />
      </div>

      <div className='vega flex centered'>
        <span className='vega label larger'>Total Assets With Guarantor:</span>
        <RadioButtons
          menuItems={[{ key: 'COMBINE_APPLICANTS_GUARANTORS', name: 'Combine Applicants and Guarantor' }, { key: 'ONLY_GUARANTOR', name: 'Only Guarantors' }]}
          activeItem={totalAssetsWithGuarantor || 'COMBINE_APPLICANTS_GUARANTORS'}
          onChange={handleChange('totalAssetsWithGuarantor')}
          disabled={!canEdit}
          theme='gray'
        />
      </div>

      <div className='vega flex centered'>
        <span className='vega label larger'>Credit Score Scope:</span>
        <RadioButtons
          menuItems={[{ key: 'APPLICANT_AND_GUARANTOR', name: 'Applicant and Guarantor' }, { key: 'ONLY_GUARANTOR', name: 'Only Guarantor' }]}
          activeItem={creditScoreScope || 'APPLICANT_AND_GUARANTOR'}
          onChange={handleChange('creditScoreScope')}
          disabled={!canEdit}
          theme='gray'
        />
      </div>
    </div>
  )
}

export default CalculationSettings
