import React, { useMemo } from "react";

import { useGetApplicationSummary } from "manager/hooks/application-summary/useGetApplicationSummary";

import { ErrorTriangleIcon, OrangeExclamationSmall } from "shared/icons";
import { isManagerApp } from "shared/utils/auth";

import { mapDealToAppTabsProps } from "./utils";

export enum IndicatorTab {
  IncomeBalances = "IncomeBalances",
  CreditBackground = "CreditBackground",
  Verifications = "Verifications",
}

export enum ApplicantIndicatorType {
  Error,
  Warning,
  None,
}

export interface ApplicantIndicatorTab {
  incomeBalance: ApplicantIndicatorType;
  creditBackground: ApplicantIndicatorType;
  verifications: ApplicantIndicatorType;
}

const ErrorComponent = () => <ErrorTriangleIcon height={12} width={12} />;

const WarningComponent = () => (
  <OrangeExclamationSmall height={12} width={12} />
);

export const useIndicatorTabs = ({
  dealId,
  applicationId,
}: {
  dealId: string;
  applicationId: string;
}) => {
  const enabled = isManagerApp() && !!dealId && !!applicationId;

  const { deal } = useGetApplicationSummary({ dealId, enabled });

  const app = useMemo(
    () => mapDealToAppTabsProps(deal, applicationId),
    [deal, applicationId]
  );

  const mapTypeToIndicator = (type: ApplicantIndicatorType) =>
    type === ApplicantIndicatorType.Error ? (
      <ErrorComponent />
    ) : type === ApplicantIndicatorType.Warning ? (
      <WarningComponent />
    ) : null;

  return {
    incomeBalancesIndicator: mapTypeToIndicator(app.incomeBalance),
    creditBackgroundIndicator: mapTypeToIndicator(app.creditBackground),
    verificationsIndicator: mapTypeToIndicator(app.verifications),
  };
};
