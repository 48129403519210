import React from "react";

import PropTypes from "prop-types";
import { Switch, useParams, Redirect } from "react-router-dom";

import ROUTES, { PORTFOLIO_SETTINGS_TABS } from "manager/config/routes";
import { ConditionalRoute, transformRoute } from "shared/utils/routing";

import { PortfolioSettingsRouterProps } from "./interfaces";
import PortfolioSettingsContainer from "./PortfolioSettingsContainer";

const PortfolioSettingsRouter = ({
  backLink,
}: PortfolioSettingsRouterProps) => {
  const { id }: { id?: string } = useParams();

  return (
    <Switch>
      <Redirect
        exact
        from={ROUTES.portfolioSettings}
        to={transformRoute(ROUTES.portfolioSettingsTab, {
          tabName: PORTFOLIO_SETTINGS_TABS.screeningSetup,
          id,
        })}
      />
      <ConditionalRoute
        path={ROUTES.portfolioSettingsTab}
        isEnabled={({
          match: {
            params: { tabName },
          },
        }) => Object.values(PORTFOLIO_SETTINGS_TABS).includes(tabName)}
        redirectTo={ROUTES.notFound}
        isInitialized
      >
        <PortfolioSettingsContainer backLink={backLink} />
      </ConditionalRoute>
    </Switch>
  );
};

PortfolioSettingsRouter.propTypes = {
  backLink: PropTypes.object.isRequired,
};

export default PortfolioSettingsRouter;
