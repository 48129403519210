import styled from "@emotion/styled";

import { CenteredFlexPartialCss } from "shared/styles/commonStyles";

const StatusNotificationModalContent = styled.div`
  ${{ ...CenteredFlexPartialCss }}
  flex-direction: column;
  .status-text {
    text-align: center;
    font-weight: 500;
    margin: 24px 0;
  }
`;

export { StatusNotificationModalContent };
