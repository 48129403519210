import React from "react";

import FlexContainer from "../FlexContainer";
import Icon from "../Icon";

import { MenuItem } from "./interfaces";

import {
  Container,
  Title,
  CardGroup,
  StyledFilterCard,
  StyledCloseIcon,
} from "./styled";

interface categoryProps {
  [key: string]: string;
}

interface TablePageHeaderProps {
  title?: string;
  categoryItems: MenuItem[];
  activeItem: string;
  categoryTypes: categoryProps;
  categoryLabelInfo: categoryProps;
  isCountsLoading: boolean;
  areVettGuestsEnabled?: boolean;
  onChange: (value: string) => void;
}

const TablePageHeader = ({
  title,
  categoryItems,
  activeItem,
  categoryTypes,
  categoryLabelInfo,
  isCountsLoading,
  areVettGuestsEnabled = undefined,
  onChange,
}: TablePageHeaderProps) => {
  const isActive = (key) => {
    return key === activeItem;
  };

  const handleClick = (event) => {
    event.stopPropagation();
    onChange(event.target.dataset.key || event.currentTarget?.dataset.key);
  };

  const formatCount = (count: number) => {
    if (count < 1000) return count;

    if (count < 10000) {
      return `${Math.floor(count / 100) / 10}K+`;
    }

    return `${Math.floor(count / 1000)}K+`;
  };

  const filterContent = (key) => {
    if (isActive(key)) {
      const allCategoryKey = categoryItems.find(
        (item) => item.name.toLowerCase() === categoryTypes.ALL
      )?.key;

      return (
        <div
          className="filter-notice active"
          data-testid={`filter-notice-${key}`}
        >
          <div>FILTERING BY</div>
          <StyledCloseIcon
            data-key={allCategoryKey}
            onClick={(event) => handleClick(event)}
          />
        </div>
      );
    }
    return (
      <div className="filter-notice" data-testid={`filter-notice-${key}`}>
        <div>FILTER BY</div>
      </div>
    );
  };

  const isAllFilter = (categoryItem) => {
    return (
      categoryItem.key?.toLowerCase() === categoryTypes.ALL ||
      categoryItem.name?.toLowerCase() === categoryTypes.ALL
    );
  };

  return (
    <Container data-testid="container-header">
      {title && <Title data-testid="title-header">{title}</Title>}
      <CardGroup>
        {categoryItems
          .filter((item) => !isAllFilter(item))
          .map((item) => (
            <StyledFilterCard
              data-key={item.key}
              onClick={(event) => handleClick(event)}
              isActive={isActive(item.key)}
              data-testid="filter-card"
            >
              <div className="filter-content">
                {!areVettGuestsEnabled && item.key === "guest" ? (
                  <FlexContainer
                    alignItems="center"
                    data-testid="filter-not-authorized"
                  >
                    <Icon.LockClosed />
                  </FlexContainer>
                ) : (
                  item?.count >= 0 && (
                    <div
                      className={`filter-count ${
                        isCountsLoading ? "loading" : ""
                      }`}
                      data-testid={`filter-count-${item.key}`}
                    >
                      {formatCount(item.count)}
                    </div>
                  )
                )}
                <div className="filter-description">
                  {filterContent(item.key)}
                  <div className="filter-type">
                    {categoryLabelInfo[item.key]}
                  </div>
                </div>
              </div>
            </StyledFilterCard>
          ))}
      </CardGroup>
    </Container>
  );
};

export default TablePageHeader;
