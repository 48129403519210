import styled from "@emotion/styled";

import { Modal } from "shared/components/Modal";
import { MEDIA_QUERIES } from "shared/config/constants";

interface ModalProps {
  isMobile: boolean;
}
const StyledModal = styled(Modal)<ModalProps>`
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    width: 480px;
    padding: 32px;
    border-radius: 30px;
    ${({ isMobile }) =>
      `
      ${MEDIA_QUERIES.w.ltMd} {
        width: ${isMobile ? "100%" : "480px"}
      }
    `}
  }
  .ant-modal-header {
    padding: 0;
  }
  .ant-modal-footer {
    padding: 0;
  }
`;

const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export { StyledModal, CenterContainer };
