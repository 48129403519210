import React, { useEffect, useMemo, useState } from "react";

import { useParams } from "react-router-dom";

import { useGetPropertiesPerf } from "manager/hooks/api";
import { useGetPortfolio } from "manager/hooks/api/portfolioQueries";
import { BackLinkProps } from "shared/components/Links/interfaces";

import Portfolio from "./Portfolio";

interface PortfolioContainerProps {
  backLink: BackLinkProps;
}

const PortfolioContainer = ({ backLink }: PortfolioContainerProps) => {
  const { id: portfolioId }: { id?: string | number } = useParams();
  const { portfolio, isPortfolioLoading, refetchPortfolio } =
    useGetPortfolio(portfolioId);

  const initialSearchParams = {
    portfolioId,
    nameOrAddress: "",
    portfolioIsDefault: false,
  };

  const [propertiesSearchParams, setPropertiesSearchParams] =
    useState(initialSearchParams);

  const {
    data: properties,
    isLoading: propertiesLoading,
    isFetching: isPropertiesFetching,
    refetch: refetchProperties,
  } = useGetPropertiesPerf({
    params: propertiesSearchParams,
  });

  const updateSearchParams = (values) => {
    if (values) {
      setPropertiesSearchParams({
        ...propertiesSearchParams,
        ...values,
      });
    } else {
      setPropertiesSearchParams({ ...initialSearchParams });
    }
  };

  const enableSerchAndFilters =
    !!properties?.length || !!propertiesSearchParams?.nameOrAddress;

  const refreshPortfolioData = () => {
    refetchProperties();
    refetchPortfolio();
  };

  useEffect(() => {
    refetchProperties();
  }, [propertiesSearchParams]);

  const propertiesNoDefault = useMemo(() => {
    if (properties?.length) {
      return properties.filter((property) => !property?.portfolioIsDefault);
    }
    return [];
  }, [properties]);

  return (
    <Portfolio
      backLink={backLink}
      portfolio={portfolio}
      properties={propertiesNoDefault}
      propertiesSearchParams={propertiesSearchParams}
      updateSearchParams={updateSearchParams}
      refreshPortfolioData={refreshPortfolioData}
      loading={isPortfolioLoading}
      propertiesLoading={propertiesLoading || isPropertiesFetching}
      enableSerchAndFilters={enableSerchAndFilters}
    />
  );
};

export default PortfolioContainer;
