import styled from "@emotion/styled";

import { COLORS } from "shared/config/constants";

const BarChartTooltip = styled.div`
  background-color: ${COLORS.white};
  padding: 15px 10px;
  border: 1px solid ${COLORS.gray1};
`;

export { BarChartTooltip };
