import React, { useEffect, useRef, useState } from "react";

import groupBy from "lodash/groupBy";
import uniq from "lodash/uniq";
import uniqBy from "lodash/uniqBy";
import { useParams, useHistory, useLocation } from "react-router-dom";

import { Notification } from "admin/App/interfaces";
import FinishDocumentModal from "admin/components/Modal/FinishDocumentModal";
import { ANNOTATION_TYPES, useWebViewer } from "admin/components/PDFTronViewer";
import ROUTES from "admin/config/routes";
import {
  useCreateDocument,
  useSaveDocumentDraft,
  useGetDocumentDraft,
  usePreviewDocument,
} from "admin/hooks/api";
import { BackLinkProps } from "shared/components/Links/interfaces";
import { SidebarProps } from "shared/components/Sidebar";
import { UserProfileProps } from "shared/components/User";
import { NOTIFICATIONS } from "shared/config/constants";
import useModal from "shared/hooks/useModal";

import { base64ToArrayBuffer } from "shared/utils/pdf";
import { openNotification } from "shared/utils/ui";

import ConfirmLeaveDocument from "./ConfirmLeaveDocument";
import DocumentConstructor from "./DocumentConstructor";

const TIMEOUT = 90000;

interface DocumentConstructorContainerProps {
  sidebar: SidebarProps;
  header: {
    logoLink: string;
    notifications: Notification[];
    user: UserProfileProps;
  };
  backLink: BackLinkProps;
}

const DocumentConstructorContainer = ({
  sidebar,
  header,
  backLink,
}: DocumentConstructorContainerProps) => {
  const history = useHistory();
  const { id: draftId }: { id?: string } = useParams();
  const { openModalDialog } = useModal();
  const [selectedFile, setSelectedFile] = useState(null);
  const [isPreviewMode, setPreviewMode] = useState(false);
  const cachedFile = useRef(null);
  const location = useLocation<any>();
  const viewerApi = useWebViewer();
  const isRequestActive = useRef(false);
  const isPreviewModeActive = useRef(false);
  const finishDocumentModalContext = useRef<any>();

  const { previewDocument } = usePreviewDocument();

  const { data: draft } = useGetDocumentDraft({
    documentId: draftId,
  });

  const finishOnboarding = (data) => {
    finishDocumentModalContext.current = openModalDialog(
      FinishDocumentModal,
      data
    );
  };
  const onSuccess = () => {
    finishDocumentModalContext.current.closeDialog();
    history.push(ROUTES.documents);
  };

  const onError = (error) =>
    finishDocumentModalContext.current.updateContext(
      {
        error: { ...error },
      },
      true
    );

  const { createDocument } = useCreateDocument({
    onSuccess,
    onError,
  });

  const { autoSaveDocument } = useSaveDocumentDraft({
    retry: false,
  });

  const saveDocument = async ({ overwrite, fileName }, config?) => {
    const fields = viewerApi.getAnnotationsList();
    const fileContent = await viewerApi.getFileData();
    const numberOfPages = viewerApi.totalPages;
    const addedFields = uniqBy(
      fields.filter((field) => field.id).map((field) => ({ id: field.id })),
      "id"
    );
    await autoSaveDocument(
      {
        name: fileName,
        numberOfPages,
        type: null,
        isStabilizedOnly: null,
        tags: null,
        fileContent,
        fields: addedFields,
        overwrite,
      },
      config
    );
  };

  const isPreviewResolved = () => isPreviewMode === isPreviewModeActive.current;

  const runAutoSave = ({ overwrite, fileName }) => {
    if (
      !isRequestActive.current &&
      !isPreviewModeActive.current &&
      isPreviewResolved()
    ) {
      isRequestActive.current = true;
      saveDocument({ overwrite, fileName }).finally(() => {
        isRequestActive.current = false;
      });
    }
  };

  const onAddField = (field) => {
    if (viewerApi.isDocumentLoaded && !isPreviewMode) {
      viewerApi.addAnnotation(field);
    } else if (isPreviewMode) {
      openNotification(
        "Turn off the preview mode to add a new field",
        NOTIFICATIONS.error
      );
    }
  };

  const validateAnnotations = () => {
    const annotations = viewerApi.getAnnotationsList();
    const checkboxGroups = annotations.filter(
      (annotation) =>
        annotation.type === ANNOTATION_TYPES.checkboxGroupSignature
    );

    if (checkboxGroups.length === 0) return true;

    const groups = Object.values(
      groupBy(checkboxGroups, (item) => item.meta.groupName)
    );

    let error;

    for (let i = 0; i < groups.length; i += 1) {
      const group = groups[i];
      const { meta } = group[0];
      const signatureGroup = groupBy(group, "name");

      if (!signatureGroup[meta.controllerName]) {
        error = `Signature field '${meta.controllerDisplayedName}' is not added on the document`;
      } else if (signatureGroup[meta.controllerName].length > 1) {
        error = `Signature field '${meta.controllerDisplayedName}' was added more than once`;
      } else if (!signatureGroup[meta.checkboxName]) {
        error = `Signature field '${meta.checkboxDisplayedName}' is not added on the document`;
      } else if (signatureGroup[meta.checkboxName].length < meta.allowed) {
        error = `Signature field '${meta.checkboxDisplayedName}' count is ${
          signatureGroup[meta.checkboxName].length
        } of ${meta.allowed}`;
      }

      if (error) {
        openNotification(error, NOTIFICATIONS.error);
        break;
      }
    }

    return !error;
  };

  const onConfirmClick = async () => {
    const fileName = selectedFile.name;
    const numberOfPages = viewerApi.totalPages;

    const onFinishOnboarding = async (values) => {
      const fields = await viewerApi.applyAnnotations();
      const fileContent = await viewerApi.getFileData();
      const addedFields = uniq(fields.map((id) => ({ id })));

      return createDocument({
        ...values,
        fileContent,
        fields: addedFields,
        numberOfPages,
      });
    };

    const isValid = validateAnnotations();

    if (isValid) {
      finishOnboarding({
        fileName,
        onFinishOnboarding,
      });
    }
  };

  useEffect(() => {
    setSelectedFile(location.state?.file);
    history.replace({ ...location, state: {} });
  }, []);

  useEffect(() => {
    if (draft) {
      setSelectedFile({
        ...draft,
        overwrite: true,
      });
    }
  }, [draft]);

  const showPreviewFile = async () => {
    const fields = await viewerApi.applyAnnotations();
    const fileContent = await viewerApi.getFileData();
    const addedFields = uniq(fields.map((id) => ({ id })));
    const { currentPage } = viewerApi;

    previewDocument(
      {
        name: `preview_${selectedFile.name}`,
        type: selectedFile.type,
        fields: addedFields.map(({ id }) => ({ id })),
        fileContent,
      },
      {
        onSuccess: async (pdf) => {
          const fileBuffer = base64ToArrayBuffer(pdf);
          await viewerApi.loadDocument(fileBuffer.slice(0), {
            filename: selectedFile.name,
          });
          await viewerApi.setPage(currentPage);
        },
        onError: () => setPreviewMode(false),
      }
    );
  };

  const getUpdatedBackLink = () => {
    const link: {
      children?: React.ReactNode;
      onClick?: () => void;
      to: string;
    } = {
      to: "",
    };

    if (
      viewerApi.isDocumentLoaded &&
      viewerApi.isAnnotationsLoaded &&
      selectedFile
    ) {
      link.onClick = () => {
        let enableRedirect = true;

        openModalDialog(ConfirmLeaveDocument, {
          saveChanges: () =>
            saveDocument(
              { overwrite: true, fileName: selectedFile.name },
              {
                onError: () => {
                  enableRedirect = false;
                },
              }
            ),
        }).afterClose.then(() => {
          if (enableRedirect) {
            history.push(ROUTES.draftDocuments);
          } else {
            openNotification(
              "Unable to save changes. Please download the document manually",
              NOTIFICATIONS.error
            );
          }
        });
      };
    } else {
      link.to = backLink?.to;
    }
    return link;
  };

  const onSwitchMode = async (isPreviewOn: boolean) => {
    if (isPreviewResolved()) {
      const { currentPage } = viewerApi;
      isPreviewModeActive.current = isPreviewOn;

      if (isPreviewOn) {
        const fileContent = await viewerApi.getFileDataInArrayBuffer();
        cachedFile.current = fileContent;
        await showPreviewFile();
      } else {
        await viewerApi.loadDocument(cachedFile.current);
        await viewerApi.setPage(currentPage);
      }
      /** Note: add a small timeout for a smooth animation of toggle */
      setTimeout(() => setPreviewMode(isPreviewOn), 1000);
    }
  };

  useEffect(() => {
    if (viewerApi.isInitialized && selectedFile) {
      viewerApi.loadDocument(
        selectedFile.url ? selectedFile.url : selectedFile,
        { filename: selectedFile.name }
      );
    }
  }, [viewerApi.isInitialized, selectedFile]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (
      viewerApi.isDocumentLoaded &&
      viewerApi.isAnnotationsLoaded &&
      selectedFile
    ) {
      runAutoSave({
        overwrite: Boolean(selectedFile.overwrite),
        fileName: selectedFile.name,
      });

      const interval = setInterval(() => {
        runAutoSave({ overwrite: true, fileName: selectedFile.name });
      }, TIMEOUT);
      return () => {
        clearInterval(interval);
      };
    }
  }, [viewerApi.isDocumentLoaded, viewerApi.isAnnotationsLoaded, selectedFile]);

  return (
    <DocumentConstructor
      sidebar={sidebar}
      header={header}
      viewerApi={viewerApi}
      selectedFile={selectedFile}
      setSelectedFile={setSelectedFile}
      onConfirmClick={onConfirmClick}
      isPreviewResolved={isPreviewResolved}
      isPreviewMode={isPreviewMode}
      onSwitchMode={onSwitchMode}
      onAddField={onAddField}
      getUpdatedBackLink={getUpdatedBackLink}
    />
  );
};

export default DocumentConstructorContainer;
