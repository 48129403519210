import React, { useEffect, useState } from "react";

import { useWebViewer } from "admin/components/PDFTronViewer";
import { usePreviewDocument } from "admin/hooks/api";
import { COLORS } from "shared/config/constants";
import { useLoadFileByUrl } from "shared/hooks/api/documentQueries";
import { base64ToArrayBuffer } from "shared/utils/pdf";

import { Document } from "./interfaces";
import PreviewDocumentModal from "./PreviewDocumentModal";

interface PreviewDocumentModalContainerProps {
  document: Document;
}

const PreviewDocumentModalContainer = ({
  document,
}: PreviewDocumentModalContainerProps) => {
  const { previewDocument, isLoadingPreviewDocument } = usePreviewDocument();
  const { fileByUrl, isLoadingFileByUrl } = useLoadFileByUrl(document.url);

  const viewerApi = useWebViewer({
    backgroundColor: COLORS.leasePreview.background,
  });
  const [isPreviewMode, setPreviewMode] = useState(false);
  const [originalFile, setOriginalFile] = useState<File>();
  const [previewFile, setPreviewFile] = useState<any>();

  const showPreviewFile = async () => {
    const { currentPage } = viewerApi;

    if (previewFile) {
      await viewerApi.loadDocument(previewFile.slice(0), {
        filename: document.name,
      });
      await viewerApi.setPage(currentPage);
    } else {
      const content = await viewerApi.getFileData();
      previewDocument(
        {
          name: `preview_${document.name}`,
          type: document.type,
          fields: document.fields.map(({ id }) => ({ id })),
          fileContent: content,
        },
        {
          onSuccess: async (pdf) => {
            const fileBuffer = base64ToArrayBuffer(pdf);
            await viewerApi.loadDocument(fileBuffer.slice(0), {
              filename: document.name,
            });
            await viewerApi.setPage(currentPage);
            setPreviewFile(fileBuffer);
          },
          onError: () => setPreviewMode(false),
        }
      );
    }
  };

  const showOriginalFile = async () => {
    const { currentPage } = viewerApi;

    if (originalFile) {
      await viewerApi.loadDocument(originalFile?.slice(0), {
        filename: document.name,
      });
      await viewerApi.setPage(currentPage);
    } else {
      await viewerApi.loadDocument(fileByUrl.slice(0), {
        filename: document.name,
      });
      await viewerApi.setPage(currentPage);
      setOriginalFile(fileByUrl);
    }
  };

  const onSwitchMode = (isPreviewOn: boolean) => {
    if (isPreviewOn) {
      showPreviewFile();
    } else {
      showOriginalFile();
    }

    setPreviewMode(isPreviewOn);
  };

  useEffect(() => {
    if (viewerApi.isInitialized) {
      showOriginalFile();
    }
  }, [viewerApi.isInitialized]);

  return (
    <PreviewDocumentModal
      document={document}
      loading={isLoadingPreviewDocument || isLoadingFileByUrl}
      viewerApi={viewerApi}
      isPreviewMode={isPreviewMode}
      onSwitchMode={onSwitchMode}
    />
  );
};

export default PreviewDocumentModalContainer;
