import React from "react";

import { useGetDashboardPeriods } from "manager/hooks/api";
import { Company } from "manager/interfaces/api/company";

import DashboardHeader from "./DashboardHeader";

interface DashboardHeaderContainerProps {
  company: Company;
  dealsSubmitted: number;
  dealsApproved: number;
  conversionRate: number;
  updatedAt: string;
  shouldExpand: boolean;
  selectedPeriod: number;
  setSelectedPeriod: (value: number) => void;
}

const DashboardHeaderContainer = ({
  company,
  dealsSubmitted,
  dealsApproved,
  conversionRate,
  updatedAt,
  shouldExpand,
  selectedPeriod,
  setSelectedPeriod,
}: DashboardHeaderContainerProps) => {
  const { name, address, city, state, zipcode } = company || {};
  const companyAddress = `${address}, ${city}, ${state} ${zipcode}`;

  const { dashboardPeriods } = useGetDashboardPeriods();

  return (
    <DashboardHeader
      companyName={name}
      companyAddress={companyAddress}
      dealsSubmitted={dealsSubmitted}
      dealsApproved={dealsApproved}
      conversionRate={conversionRate}
      dashboardPeriods={dashboardPeriods}
      updatedAt={updatedAt}
      shouldExpand={shouldExpand}
      selectedPeriod={selectedPeriod}
      setSelectedPeriod={setSelectedPeriod}
    />
  );
};

export default DashboardHeaderContainer;
