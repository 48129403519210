import React from "react";

import { getIntegrationType } from "manager/utils/integration.util";
import { INTEGRATION_TYPES } from "shared/config/constants";

import IntegrationMark from "./IntegrationMark";
import { INTEGRATIONS_MARK_SIZE } from "./IntegrationMark.confg";
import {
  getIconPerIntegration,
  ACCEPTED_INTEGRATIONS,
} from "./IntegrationMark.util";
import { PropertyIntegrationMarkProps } from "./interfaces";

const YARDI_CONNECTED_PROPERTY_MSG = "Current property is connected to Yardi";
const ENTRATA_CONNECTED_PROPERTY_MSG =
  "Current property is connected to Entrata";
const REAL_PAGE_CONNECTED_PROPERTY_MSG =
  "Current property is connected to Real Page";

const PropertyIntegrationMark = ({
  property,
  lg = false,
}: PropertyIntegrationMarkProps) => {
  const size = lg ? INTEGRATIONS_MARK_SIZE.lg : INTEGRATIONS_MARK_SIZE.sm;
  let popoverLabel = null;
  const integrationType = getIntegrationType(property);

  if (integrationType === INTEGRATION_TYPES.YARDI) {
    popoverLabel = YARDI_CONNECTED_PROPERTY_MSG;
  } else if (integrationType === INTEGRATION_TYPES.ENTRATA) {
    popoverLabel = ENTRATA_CONNECTED_PROPERTY_MSG;
  } else if (integrationType === INTEGRATION_TYPES.REAL_PAGE) {
    popoverLabel = REAL_PAGE_CONNECTED_PROPERTY_MSG;
  }

  if (ACCEPTED_INTEGRATIONS.has(integrationType)) {
    const IntegrationIcon = getIconPerIntegration(integrationType, size, false);
    return (
      <IntegrationMark
        IntegrationIcon={IntegrationIcon}
        popoverLabel={popoverLabel}
        size={size}
      />
    );
  }

  return null;
};

export default PropertyIntegrationMark;
