import React from "react";

import { CurrentSelectionProps } from "./interfaces";
import { SelectionTitle, Selection } from "./styled";

const CurrentSelection = ({ selectedCompany }: CurrentSelectionProps) => (
  <>
    <SelectionTitle>
      {selectedCompany ? "Selected company:" : "No selected company yet"}
    </SelectionTitle>

    <Selection>{selectedCompany?.name ?? " "}</Selection>
  </>
);

export default CurrentSelection;
