import styled from "@emotion/styled";
import { Table as BaseTable } from "antd";

import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

interface StyledTableProps {
  alt?: boolean;
  expandable?: boolean;
  indent?: boolean;
  marginForPagination?: boolean;
  noBorderBottom?: boolean;
  noClickableRows?: boolean;
  withBorder?: boolean;
}

// eslint-disable-next-line vero/styled-tagged-template
const StyledTable = styled<typeof BaseTable, StyledTableProps>(BaseTable)(
  ({
    alt,
    expandable,
    indent,
    marginForPagination,
    noBorderBottom,
    noClickableRows,
    withBorder,
  }) => ({
    backgroundColor: "white",
    "& table": {
      borderCollapse: "collapse",
      fontSize: "15px",
      ...(withBorder && {
        border: `1px solid ${COLORS.borders}`,
      }),
    },
    "& thead > tr > th": {
      backgroundColor: `${COLORS.table.default.header.background}`,
      color: `${COLORS.typography.text}`,
      fontWeight: 300,
      padding: "18px 24px",
      ...(alt && {
        backgroundColor: `${COLORS.table.alt.header.background}`,
      }),
      "&:first-of-type": {
        ...(indent && {
          paddingLeft: "58px",
        }),
      },
      "&.ant-table-selection-column": {
        textAlign: "initial",
      },
    },
    "& tbody > tr > td": {
      fontWeight: 400,
      cursor: "pointer",
      paddingLeft: "24px",
      paddingRight: "24px",
      ...(noClickableRows && {
        cursor: "default",
      }),
      "&:first-of-type": {
        ...(indent && {
          paddingLeft: "58px",
        }),
      },
    },
    "& tbody > tr": {
      "&:not(.ant-table-expanded-row):hover": {
        boxShadow: noClickableRows ? "none" : `${COLORS.boxShadow}`,
        "& > td": {
          backgroundColor: `${COLORS.table.default.row.default.hover} !important`,
        },
      },
      "&.row-viewed": {
        backgroundColor: COLORS.table.default.row.viewed.background,
        "&:hover": {
          "& > td": {
            backgroundColor: `${COLORS.table.default.row.viewed.background} !important`,
          },
        },
      },
      "&:last-of-type > td": {
        ...(noBorderBottom && {
          borderBottom: "0 !important",
        }),
      },
      "&.ant-table-expanded-row": {
        td: {
          padding: 0,
        },
      },
      ...(expandable && {
        display: "grid",
        gridAutoFlow: "row",
        td: {
          width: "auto",
          "&.ant-table-row-expand-icon-cell": {
            backgroundColor: COLORS.table.alt.header.background,
            padding: "0 5px",
            "& > *": {
              width: "100%",
            },
          },
          "&:first-of-type": {
            order: 2,
          },
        },
        "&.ant-table-expanded-row": {
          td: {
            display: "flex",
            flexWrap: "wrap",
            paddingLeft: "24px",
            paddingRight: "24px",
            "&:first-of-type": {
              display: "none",
            },
          },
        },
      }),
    },
    ".chevron-icon": {
      "&:hover": {
        circle: {
          fill: COLORS.table.default.row.viewed.background,
        },
        cursor: "pointer",
      },
    },
    ".ant-pagination": {
      marginRight: "60px",
      [MEDIA_QUERIES.w.ltSm]: {
        marginRight: 0,
        float: "none",
        display: "flex",
        justifyContent: "center",
        ...(marginForPagination && {
          marginRight: 50,
        }),
      },
    },
    ".ant-table-placeholder": {
      borderBottom: 0,
    },
    ".ant-spin-dot": {
      width: "auto",
      height: "auto",
      transform: "translateX(-50%)",
    },
    ".ant-spin-container::after": {
      display: "none",
    },
    ".ant-spin-blur": {
      opacity: 1,
    },
    ".disabled-row": {
      backgroundColor: "#F9FAFC",
      color: "#807E8D",
      pointerEvents: "none",
    },
  })
);

const Icon = styled("span")({
  verticalAlign: "sub",
  marginLeft: "7px",
});

const EmptyText = styled("div")(() => ({
  marginTop: "-1px",
  padding: "70px",
}));

export { EmptyText, StyledTable, Icon };
