import styled from "@emotion/styled";

import FlexContainer from "shared/components/FlexContainer";

const SubTitleContainer = styled(FlexContainer)`
  span {
    text-transform: capitalize;
  }
  svg {
    margin-left: 5px;
  }
`;

const ColumnInputWrapper = styled.div`
  height: 60px;
`;

export { SubTitleContainer, ColumnInputWrapper };
