import React, { useRef } from "react";

import { FormikProps } from "formik";
import Cookies from "js-cookie";

import { NOTIFICATIONS } from "shared/config/constants";
import { useLogin } from "shared/hooks";
import { useGetSSOConfig, useLoginAuth } from "shared/hooks/api";
import useDeviceType from "shared/hooks/useDeviceType";
import { openNotification } from "shared/utils/ui";

import { LAST_LOGIN_EMAIL } from "./config";
import {
  LoginFormikValue,
  LoginPageContainerProps,
} from "./interfaces";
import LoginPage from "./LoginPage";

const LoginPageContainer = ({
  onSuccess,
  title,
  infoBanner,
}: LoginPageContainerProps) => {
  const { ssoConfig, isSSOConfigLoading } = useGetSSOConfig();
  const { loginAuth } = useLoginAuth();

  const formikRef = useRef<FormikProps<LoginFormikValue>>();

  const { isMobile } = useDeviceType();

  const onError = (error: any) => {
    openNotification(error?.errors?.message, NOTIFICATIONS.error);
    formikRef.current.setSubmitting(false);
  };

  const loginCall = () => {
    const { email, password } = formikRef?.current?.values ?? {};
    return loginAuth(
      { email, password },
      {
        onSuccess: async (data, request) => Cookies.set(LAST_LOGIN_EMAIL, request?.email)
      }
    );
  };

  const { login } = useLogin({
    loginCall,
    onSuccess,
    onError,
  });

  return (
    <LoginPage
      title={title}
      submit={login}
      ssoConfig={ssoConfig}
      isSSOConfigLoading={isSSOConfigLoading}
      formikRef={formikRef}
      isMobile={isMobile}
      infoBanner={infoBanner}
    />
  );
};

export default LoginPageContainer;
