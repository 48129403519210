import _ from 'lodash'
import React from 'react'
import Tile from 'shared/components/Tile'
import Tooltip from 'shared/components/Tooltip'
import { Timestamp } from 'shared/components/vega/Timestamp'

const Workflow = ({ workflow, readOnly = false, children = null }) => {
  const tooltipTitle = <div>
    <div>{workflow?.id}</div>
    <div>{workflow?.person}</div>
  </div>

  const title = <Tooltip title={tooltipTitle} theme='light'>
    <div>{_.startCase(workflow?.identifier)}</div>
  </Tooltip>

  const subtitle = <div>
    <div className='vega info'>{workflow?.location}</div>
    <Timestamp timestamp={workflow?.updatedAt || workflow?.createdAt} small />
  </div>

  return (
    <Tile header={{
      title,
      subtitle,
      collapsibleOnDesktop: true,
      collapsibleOnMobile: true,
      defaultCollapsed: true,
      accent: readOnly
    }} noMarginBottom>
      <Tile.Inner noPadding>
        {children}
      </Tile.Inner>
    </Tile>
  )
}

export default Workflow
