import React from "react";

import {
  useContactManager,
  useDealManager,
} from "renter/hooks/api/dealQueries";
import { ContactManager } from "renter/interfaces/api/deal";
import useModal from "shared/hooks/useModal";

import ContactManagerModal from "./ContactManagerModal";

const ContactManagerModalContainer = ({ dealId }: { dealId: number }) => {
  const { manager, isManagerLoading } = useDealManager(dealId);
  const { closeActiveModalDialog } = useModal();

  const { contactManager, isContactManager } = useContactManager({
    onSuccess: closeActiveModalDialog,
  });

  const submit = (payload: ContactManager) =>
    contactManager({
      ...payload,
      id: dealId,
    });

  return (
    <ContactManagerModal
      loading={isManagerLoading}
      users={manager}
      isSubmitting={isContactManager}
      submit={submit}
    />
  );
};

export default ContactManagerModalContainer;
