import React, { useEffect, useMemo, useRef, useState } from "react";

import differenceWith from "lodash/differenceWith";
import isEqual from "lodash/isEqual";
import keyBy from "lodash/keyBy";
import merge from "lodash/merge";
import PropTypes from "prop-types";
import { useHistory, useParams } from "react-router-dom";

import { YardiRemovePropertyConfirmationModal } from "manager/components/Modal";
import ROUTES from "manager/config/routes";
import {
  useGetAllUnitsSimple,
  useGetEmployeesWithAssignmentsOnProperty,
  useGetYardiAgents,
  useGetYardiMarketingSources,
  useGetYardiProperty,
  useGetYardiUnits,
  useRemoveYardiConfigurePropertyQueries,
  useUpdateYardiPropertyMappings,
} from "manager/hooks/api";
import { useGetIntegrationProvider } from "manager/pages/Marketplace/config";
import { YARDI_PROPERTIES_STATUS } from "manager/pages/Marketplace/integrations/yardi/dashboard/yardiDashboard.config";
import Skeleton from "shared/components/Skeleton";
import { IntegrationProvider } from "shared/config/constants";
import { useGetPmsUnits } from "shared/hooks/api";
import { useGetMarketingSources } from "shared/hooks/api/sourcesQueries";
import useModal from "shared/hooks/useModal";
import {
  resetFormAndValidate,
  setFormValuesAndValidate,
} from "shared/utils/forms";
import { backLinkPropType } from "shared/utils/propTypes";
import { transformRoute } from "shared/utils/routing";
import { stringify } from "shared/utils/string";

import ConfigureYardiProperty from "./ConfigureYardiProperty";
import { SECTIONS, SECTION_TYPES } from "./configureYardiProperty.config";
import {
  setupAgentsData,
  setupMarketingSourcesData,
  convertUnitsInForm,
  convertAgentsInForm,
  convertMarketingSourcesInForm,
  convertUnitsToFormData,
  calculatePmsUnitIsActive,
} from "./configureYardiProperty.util";
import {
  stringifyVeroAgentId,
  stringifyVeroMarketingSourceId,
} from "./section/configureYardiPropertySection.util";

const ConfigureYardiPropertyContainer = ({ company, backLink }) => {
  const history = useHistory();
  const { provider } = useGetIntegrationProvider();
  const { openModalDialog } = useModal();
  const [isInitializingValues, setIsInitializingValues] = useState(true);
  const formikRef = useRef(null);
  const { propertyId: currentYardiPropertyId } = useParams();

  const { yardiProperty, isLoading: loading } = useGetYardiProperty(
    provider,
    currentYardiPropertyId
  );
  const veroProperty = yardiProperty?.property;

  const { updateYardiPropertyMappings } =
    useUpdateYardiPropertyMappings(provider);
  const { units: veroUnits, isLoading: areVeroUnitsLoading } =
    useGetAllUnitsSimple(yardiProperty?.property?.id);
  const { yardiUnits: yus, isLoading: areYardiUnitsLoading } = useGetYardiUnits(
    provider,
    currentYardiPropertyId
  );
  const { pmsUnits, isPmsUnitsLoading } = useGetPmsUnits({
    location: `${company.slug}:${provider}/${yardiProperty?.yardiId}`,
    enabled: !!yardiProperty?.yardiId,
  });
  const areUnitsLoading =
    areVeroUnitsLoading || areYardiUnitsLoading || isPmsUnitsLoading;

  const yardiUnitsByIdentifier = useMemo(
    () => keyBy(yus || [], (item) => item.yardiId),
    [yus]
  );

  const yardiUnits = (pmsUnits || []).map((pmsUnit) => {
    const yardiUnit = (yardiUnitsByIdentifier || {})[pmsUnit.identifier];

    return {
      ...pmsUnit,
      isActive: yardiUnit?.isActive ?? calculatePmsUnitIsActive(pmsUnit),
      unit: yardiUnit?.unit ?? null,
      rent: yardiUnit?.rent ?? pmsUnit.minMarketRent,
    };
  });

  const veroUnitsMap = useMemo(
    () => keyBy(veroUnits || [], (item) => stringify(item.id)),
    [veroUnits]
  );
  const yardiUnitsMap = useMemo(
    () => keyBy(yardiUnits || [], (item) => item.id),
    [yardiUnits]
  );

  const {
    yardiMarketingSources: fetchedYardiMarketingSources,
    isLoading: areYardiMarketingSourceLoading,
  } = useGetYardiMarketingSources(provider, currentYardiPropertyId);
  const {
    marketingSources: fetchedVeroMarketingSources,
    isLoading: areVeroMarketingSourceLoading,
  } = useGetMarketingSources(provider, yardiProperty?.property?.id);
  const areMarketingSourcesLoading =
    areYardiMarketingSourceLoading || areVeroMarketingSourceLoading;

  const { yardiAgents: fetchedYardiAgents, isLoading: areYardiAgentsLoading } =
    useGetYardiAgents(provider, currentYardiPropertyId);
  const {
    employees: fetchedVeroPropertyEmployees,
    isLoading: areVeroEmployeesLoading,
  } = useGetEmployeesWithAssignmentsOnProperty(
    provider,
    yardiProperty?.property?.id
  );

  const areAgentsLoading = areYardiAgentsLoading || areVeroEmployeesLoading;

  const isAnythingLoading =
    loading ||
    areUnitsLoading ||
    areMarketingSourcesLoading ||
    areAgentsLoading;

  const availableYardiUnits = useMemo(
    () =>
      (yardiUnits || []).filter((unit) => unit.isActive && !unit.isWaitUnit),
    [yardiUnits]
  );

  const waitYardiUnits = useMemo(
    () => (yardiUnits || []).filter((unit) => !!unit.isWaitUnit),
    [yardiUnits]
  );

  const otherYardiUnits = useMemo(
    () =>
      (yardiUnits || []).filter((unit) => !unit.isActive && !unit.isWaitUnit),
    [yardiUnits]
  );

  const { veroAgents, yardiAgents } = useMemo(
    () => setupAgentsData(fetchedVeroPropertyEmployees, fetchedYardiAgents),
    [fetchedVeroPropertyEmployees, fetchedYardiAgents]
  );

  const { veroMarketingSources, yardiMarketingSources } = useMemo(
    () =>
      setupMarketingSourcesData(
        fetchedVeroMarketingSources,
        fetchedYardiMarketingSources
      ),
    [fetchedVeroMarketingSources, fetchedYardiMarketingSources]
  );

  const onChangePropertyMapping = () => {
    if (yardiProperty.status !== YARDI_PROPERTIES_STATUS.COMPLETED) {
      const context = {
        provider,
        title: "Change property mapping?",
        content:
          "This operation will remove all current VERO property mapping and all the configuration saved in this page.",
        propertyId: yardiProperty.id,
        propertyName: yardiProperty.name,
        veroPropertyName: yardiProperty.property.name,
        removeFromActive: false,
        onSubmitEnd: () => {
          const propertySetupUrl = transformRoute(
            ROUTES.integrationSetupProperty,
            {
              provider,
              propertyId: yardiProperty.id,
            }
          );
          history.push(propertySetupUrl, { skipPrompt: true });
        },
      };

      openModalDialog(YardiRemovePropertyConfirmationModal, context);
    }
  };

  function internalSave(unitsData, data, config) {
    const mappings = {
      units: unitsData,
      agents: convertAgentsInForm(data.agents),
      marketingSources: convertMarketingSourcesInForm(
        data.marketingSources,
        data.defaultMarketingSource
      ),
      isLroEnabled: data.isLroEnabled,
    };

    const callData = {
      id: currentYardiPropertyId,
      mappings,
    };
    return updateYardiPropertyMappings(callData, { ...config });
  }

  const getChangedUnitsList = (yardiUnits, dataUnits, addRent) => {
    const { unitsFormData: previousUnits } = convertUnitsToFormData(
      yardiUnits,
      veroUnitsMap,
      addRent
    );
    const previousUnitsList = convertUnitsInForm(previousUnits, addRent);
    const currentUnitsList = convertUnitsInForm(dataUnits, addRent);
    return differenceWith(currentUnitsList, previousUnitsList, isEqual);
  };

  const onSave = (data, config = {}, confirmationCallbacks = {}) => {
    const changedAvailableUnitsList = getChangedUnitsList(
      availableYardiUnits,
      data.availableUnits,
      true
    );
    const changedWaitUnitsList = getChangedUnitsList(
      waitYardiUnits,
      data.waitUnits,
      true
    );
    const changedOthertUnitsList = getChangedUnitsList(
      otherYardiUnits,
      data.otherUnits,
      false
    );

    const unitsData = [
      ...changedAvailableUnitsList,
      ...changedWaitUnitsList,
      ...changedOthertUnitsList,
    ];

    const { onConfirm } = confirmationCallbacks;
    if (onConfirm) {
      onConfirm();
    }
    return internalSave(unitsData, data, config);
  };

  const onFormSubmit = (data, { setSubmitting }) => {
    const onSettled = () => setSubmitting(false);
    onSave(data, { onSettled });
  };

  const saveOnLeave = (data, { onCancel, onConfirm }) => {
    onSave(data, {}, { onCancel, onConfirm });
  };

  const setupInitialState = ({
    initialYardiProperty,
    initialAvailableYardiUnits,
    initialWaitYardiUnits,
    initialOtherYardiUnits,
    initialAgents,
    initialMarketingSources,
  }) => {
    const { unitsFormData: availableUnits } = convertUnitsToFormData(
      initialAvailableYardiUnits,
      veroUnitsMap,
      true
    );

    const { unitsFormData: waitUnits } = convertUnitsToFormData(
      initialWaitYardiUnits,
      veroUnitsMap,
      false
    );

    const { unitsFormData: otherUnits } = convertUnitsToFormData(
      initialOtherYardiUnits,
      veroUnitsMap,
      false
    );

    const agents = {};
    (initialAgents || []).forEach((agent) => {
      const id = stringifyVeroAgentId(agent.id);
      agents[id] = {
        yardiAgent: stringify(agent.yardiAgent),
      };
    });

    const marketingSources = {};
    let defaultMarketingSource = null;
    (initialMarketingSources || []).forEach((marketingSource) => {
      const id = stringifyVeroMarketingSourceId(marketingSource.id);
      defaultMarketingSource = marketingSource.isDefault
        ? marketingSource.id
        : defaultMarketingSource;
      marketingSources[id] = {
        yardiMarketingSource: stringify(marketingSource.yardiMarketingSource),
      };
    });

    const newValues = {
      isLroEnabled: initialYardiProperty?.isLroEnabled,
      availableUnits,
      waitUnits,
      otherUnits,
      agents: provider === IntegrationProvider.realPage ? {} : agents,
      marketingSources,
      defaultMarketingSource,
    };

    const oldValues = formikRef?.current?.values || {};
    const values = merge({}, oldValues, newValues);

    resetFormAndValidate(formikRef, { values: newValues });
    // NOTE: need to reset first (set the initial values), then update the values to have a correct `dirty` state
    setFormValuesAndValidate(formikRef, values);
    setIsInitializingValues(false);
  };

  useEffect(() => {
    if (
      !isAnythingLoading &&
      yardiUnits &&
      veroAgents &&
      veroMarketingSources
    ) {
      setupInitialState({
        initialYardiProperty: yardiProperty,
        initialAvailableYardiUnits: availableYardiUnits,
        initialWaitYardiUnits: waitYardiUnits,
        initialOtherYardiUnits: otherYardiUnits,
        initialAgents: veroAgents,
        initialMarketingSources: veroMarketingSources,
      });
    }
  }, [
    isAnythingLoading,
    yardiProperty,
    yardiUnits,
    veroUnits,
    veroAgents,
    veroMarketingSources,
  ]);

  const areUnitsSectionsLoading = areUnitsLoading || isInitializingValues;

  const marketingSourceSection = {
    yardi: yardiMarketingSources,
    vero: veroMarketingSources,
    loading: areMarketingSourcesLoading || isInitializingValues,
  };

  const agentsSection = {
    yardi: yardiAgents,
    vero: veroAgents,
    loading: areAgentsLoading || isInitializingValues,
  };

  const loadingSections = {
    [SECTION_TYPES.AVAILABLE_UNITS]: areUnitsSectionsLoading,
    [SECTION_TYPES.WAIT_UNITS]: areUnitsSectionsLoading,
    [SECTION_TYPES.OTHER_UNITS]: areUnitsSectionsLoading,
    [SECTION_TYPES.AGENTS]: agentsSection.loading,
    [SECTION_TYPES.MARKETING_SOURCES]: marketingSourceSection.loading,
  };

  const sectionsCompleted = useMemo(() => {
    const { isUnitsSectionComplete: isAvailableUnitsSectionComplete } =
      convertUnitsToFormData(availableYardiUnits, veroUnitsMap);

    const { isUnitsSectionComplete: isWaitUnitsSectionComplete } =
      convertUnitsToFormData(waitYardiUnits, veroUnitsMap);

    const { isUnitsSectionComplete: isOtherUnitsSectionComplete } =
      convertUnitsToFormData(otherYardiUnits, veroUnitsMap);

    let mappedAgents = 0;
    (veroAgents || []).forEach((agent) => {
      mappedAgents += agent.yardiAgent ? 1 : 0;
    });

    const isAgentsSectionComplete =
      provider === IntegrationProvider.realPage
        ? true
        : (veroAgents || []).length === mappedAgents;

    let defaultMarketingSource = null;
    let mappedMarketingSources = 0;
    (veroMarketingSources || []).forEach((marketingSource) => {
      mappedMarketingSources += marketingSource.yardiMarketingSource ? 1 : 0;
      defaultMarketingSource = marketingSource.isDefault
        ? marketingSource.id
        : defaultMarketingSource;
    });

    const isMarketingSourcesSectionComplete =
      provider === IntegrationProvider.realPage
        ? true
        : (veroMarketingSources || []).length === mappedMarketingSources &&
          mappedMarketingSources > 0 &&
          !!defaultMarketingSource;

    return {
      [SECTION_TYPES.AVAILABLE_UNITS]: isAvailableUnitsSectionComplete,
      [SECTION_TYPES.WAIT_UNITS]: isWaitUnitsSectionComplete,
      [SECTION_TYPES.OTHER_UNITS]: isOtherUnitsSectionComplete,
      [SECTION_TYPES.AGENTS]: isAgentsSectionComplete,
      [SECTION_TYPES.MARKETING_SOURCES]: isMarketingSourcesSectionComplete,
    };
  }, [
    availableYardiUnits,
    veroUnitsMap,
    waitYardiUnits,
    otherYardiUnits,
    veroAgents,
    veroMarketingSources,
  ]);

  const sections = useMemo(() => {
    return SECTIONS.map((section) => {
      return {
        ...section,
        isVisible:
          section.anchor === SECTION_TYPES.MARKETING_SOURCES
            ? provider !== IntegrationProvider.realPage
            : section.anchor !== SECTION_TYPES.WAIT_UNITS ||
              !!waitYardiUnits?.length,
        completed: sectionsCompleted[section.anchor],
        loading: loadingSections[section.anchor],
      };
    });
  }, [SECTIONS, SECTION_TYPES, provider, loadingSections, sectionsCompleted]);

  useEffect(
    () => () => useRemoveYardiConfigurePropertyQueries(currentYardiPropertyId),
    []
  );

  const props = {
    veroProperty,
    yardiProperty,
    onChangePropertyMapping,
    sections,
    formikRef,
    saveOnLeave,
    onFormSubmit,
    veroUnits,
    veroUnitsMap,
    areUnitsLoading: areUnitsSectionsLoading,
    yardiUnitsMap,
    availableYardiUnits,
    otherYardiUnits,
    waitYardiUnits,
    marketingSourceSection,
    agentsSection,
    backLink,
    provider,
  };
  return (
    <Skeleton loading={loading}>
      <ConfigureYardiProperty {...props} />
    </Skeleton>
  );
};

ConfigureYardiPropertyContainer.propTypes = {
  company: PropTypes.object.isRequired,
  backLink: backLinkPropType.isRequired,
};

export default ConfigureYardiPropertyContainer;
