import styled from "@emotion/styled";
import { Typography, Col } from "antd";
import { MEDIA_QUERIES } from "shared/config/constants";
import { Button } from "shared/components/Button";

const { Text } = Typography;

const Container = styled("div")({
  padding: "40px 0",
  [MEDIA_QUERIES.w.gteXl]: {
    maxWidth: "1200px",
  },
});

const Title = styled(Text)({
  fontWeight: "normal",
  fontSize: "17px",
  color: "#2D2943",
});

const Fade = styled("span")({
  color: "#807E8D",
  fontWeight: 300,
});

Title.Fade = Fade;

const TitleContainer = styled("div")({
  paddingBottom: "24px",
});


const Notice = styled("div")({
  display: "flex",
});

const FieldCol = styled(Col)({
  display: "flex",
  "flex-direction": "column",
  "justify-content": "space-between"
});

const ButtonCol = styled(Col)({
  display: "flex",
  alignItems: "end"
});

const SubmitButton = styled(Button)`
  &:not(:last-of-type) {
    margin-right: 16px;
  }
  ${MEDIA_QUERIES.w.btwMdLg} {
    flex-grow: 1;
    flex-basis: 0;
  }
  ${MEDIA_QUERIES.w.gteLg} {
    &:nth-of-type(1) {
      order: 3;
    }
    &:nth-of-type(2) {
      order: 2;
    }
    &:nth-of-type(3) {
      order: 1;
    }
  }
`;
export { Container, Title, TitleContainer, Notice, SubmitButton, ButtonCol, FieldCol };
