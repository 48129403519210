import React from "react";

import ShareMagicLinksModal from "manager/components/MagicLinks/ShareMagicLinksModal";
import { useGetCompanyMagicLink } from "manager/hooks/api";
import { useActiveCompany, usePermissions } from "shared/hooks";
import useModal from "shared/hooks/useModal";

import { MAGIC_LINK_TYPE } from "../config";

import MagicLinksCompany from "./MagicLinksCompany";

const MagicLinksCompanyContainer = () => {
  const { activeCompany } = useActiveCompany();
  const { name } = activeCompany ?? {};
  const { preventInvitesToTheApplicationWithoutUnit } = usePermissions();

  const { data, isLoading } = useGetCompanyMagicLink();
  const { openModalDialog } = useModal();

  const onShareLinks = () =>
    openModalDialog(ShareMagicLinksModal, {
      magicLink: data.magicLink,
      magicPortalLink: data.magicPortalLink,
      name,
      prescreeningMagicLink: data.prescreeningMagicLink,
      type: MAGIC_LINK_TYPE.COMPANY,
    });
  return (
    <MagicLinksCompany
      name={name}
      onShareLinks={onShareLinks}
      isLoading={isLoading}
      disableMagicLink={preventInvitesToTheApplicationWithoutUnit}
      hasMagicPortalLink={!!data?.magicPortalLink}
    />
  );
};

export default MagicLinksCompanyContainer;
