import PropTypes from "prop-types";

import { useIndicatorTabs } from "manager/components/app-summary";
import { useGetLatestLumenV2Run } from "manager/hooks/api";
import {
  usePets,
  useResolveApplicantPayments,
  useGetApplicantWorkflowRun,
} from "shared/hooks/api";
import { useGetMarketingSourcesByApp } from "shared/hooks/api/sourcesQueries";
import {
  useIndustries,
  useKidOptions,
  useWindowGuardOptions,
  useKnobRiderOptions,
} from "shared/hooks/api/typeQueries";
import useDealSensitiveInfo from "shared/hooks/useDealSensitiveInfo";

import { setPrefix } from "shared/utils/vrn";

import Application from "./Application";
import ApplicationContext from "./applicationContext";

const ApplicationContainer = (props) => {
  const { application, deal } = props;
  const { pets: petTypes } = usePets();

  const { data: industries } = useIndustries();
  const { data: kidOptions } = useKidOptions();
  const { data: windowOptions } = useWindowGuardOptions();
  const { data: knobOptions } = useKnobRiderOptions();

  const {
    creditBackgroundIndicator,
    incomeBalancesIndicator,
    verificationsIndicator,
  } = useIndicatorTabs({
    dealId: application?.deal,
    applicationId: application?.id,
  });

  const { marketingSources } = useGetMarketingSourcesByApp({
    applicationId: application?.id,
    propertyId: deal?.property?.id,
  });

  const {
    canAccessSensitiveDealData,
    canAccessCountyCriminalRecords,
    isSensitiveDealDataPermissionLoaded,
    canViewOcrResults,
    canViewRestrictedAttachments,
  } = useDealSensitiveInfo(deal);

  const { applicantWorkflow } = useGetApplicantWorkflowRun(
    application?.applicantVrn
  );

  const payments = useResolveApplicantPayments(
    application?.applicantVrn,
    deal?.location,
    "before-application-submit",
    application?.party
  );

  const { lumenRun } = useGetLatestLumenV2Run(
    setPrefix(`${deal.companySlug}/${deal.id}`, "application"),
    deal?.lumenEnabled
  );

  const contextData = {
    petTypes,
    industries,
    kidOptions,
    windowOptions,
    knobOptions,
    marketingSources,
    application,
  };

  return (
    <ApplicationContext.Provider value={contextData}>
      <Application
        canAccessSensitiveDealData={canAccessSensitiveDealData}
        canViewOcrResults={canViewOcrResults}
        canViewRestrictedAttachments={canViewRestrictedAttachments}
        isSensitiveDealDataPermissionLoaded={
          isSensitiveDealDataPermissionLoaded
        }
        canAccessCountyCriminalRecords={canAccessCountyCriminalRecords}
        creditBackgroundIndicator={creditBackgroundIndicator}
        incomeBalancesIndicator={incomeBalancesIndicator}
        verificationsIndicator={verificationsIndicator}
        applicantWorkflow={applicantWorkflow}
        payments={payments}
        lumenRun={lumenRun}
        deal={deal}
        {...props}
      />
    </ApplicationContext.Provider>
  );
};

ApplicationContainer.propTypes = {
  application: PropTypes.object,
  deal: PropTypes.object,
};

ApplicationContainer.defaultProps = {
  application: undefined,
  deal: {},
};

export default ApplicationContainer;
