import React from "react";

import { Col, Row } from "antd";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";

import DetectableOverflow from "shared/components/DetectableOverflow";
import { Select, VeroFormField } from "shared/components/Form";
import { Modal } from "shared/components/Modal";
import Spacer from "shared/components/Spacer";
import { RegularText, SmallTitle } from "shared/components/Typography";
import { VALIDATION_MESSAGES } from "shared/config/constants";
import { useDeviceType } from "shared/hooks";

import { FormProps } from "./interfaces";
import { LeadSubtitle } from "./styled";

interface InviteLeadToApplyModalProps {
  leadFullName: string;
  leadEmail: string;
  properties: { id: number; name: string }[];
  isPropertiesLoading: boolean;
  leadPropertyId?: number | undefined;
  leadUnitId?: number | undefined;
  units: { id: number; name: string; unitListing: number }[];
  isUnitsLoading: boolean;
  selectProperty: (
    value: number,
    setValues: (
      values: React.SetStateAction<FormProps>,
      shouldValidate?: boolean
    ) => void
  ) => void;
  submit: (values: FormProps, formikHelpers: FormikHelpers<FormProps>) => void;
}

const getInitialValues = (
  leadPropertyId: number | undefined,
  leadUnitId: number | undefined
) => ({
  property: leadPropertyId,
  unit: leadUnitId,
});

export const VALIDATION_SCHEMA = Yup.object().shape({
  property: Yup.number().nullable().required(VALIDATION_MESSAGES.fieldRequired),
  unit: Yup.number().required(VALIDATION_MESSAGES.fieldRequired),
});

const InviteLeadToApplyModal = ({
  leadFullName,
  leadEmail,
  properties,
  isPropertiesLoading,
  leadPropertyId,
  leadUnitId,
  units,
  isUnitsLoading,
  selectProperty,
  submit,
}: InviteLeadToApplyModalProps) => {
  const { isMobile } = useDeviceType();
  return (
    <Formik
      initialValues={getInitialValues(leadPropertyId, leadUnitId)}
      validationSchema={VALIDATION_SCHEMA}
      enableReinitialize
      onSubmit={submit}
    >
      {({ submitForm, isSubmitting, setValues, values }) => {
        return (
          <Form>
            {/* @ts-ignore */}
            <Modal
              title="Invite lead to apply"
              subtitle={
                <LeadSubtitle>
                  <RegularText weak>Lead:</RegularText>
                  {/* @ts-ignore */}
                  <RegularText className="lead-data">
                    <div className="lead-data__dot" />
                    {leadFullName}, {leadEmail}
                  </RegularText>
                </LeadSubtitle>
              }
              submitButtonLabel="Invite"
              submit={submitForm}
              submitting={isSubmitting}
              closeOnSubmit={false}
              width="auto"
              style={{ maxWidth: 946 }}
              fullScreen={isMobile}
            >
              <Modal.Body>
                <SmallTitle>Find and select property</SmallTitle>
                <Spacer size={Spacer.SIZES.md} />
                <Row gutter={[20, 32]}>
                  <Col md={{ span: 8 }}>
                    <VeroFormField
                      as={Select}
                      name="property"
                      id="property"
                      label="Select Property"
                      required
                      onChange={(value: number) =>
                        selectProperty(value, setValues)
                      }
                      loading={isPropertiesLoading}
                      onBlur={undefined}
                      optionFilterProp="label"
                    >
                      {properties?.map((property) => (
                        <Select.Option
                          key={property.id}
                          label={property.name}
                          value={property.id}
                        >
                          <DetectableOverflow title={property.name}>
                            {property.name}
                          </DetectableOverflow>
                        </Select.Option>
                      ))}
                    </VeroFormField>
                  </Col>
                  <Col md={{ span: 8 }}>
                    <VeroFormField
                      as={Select}
                      name="unit"
                      id="unit"
                      required
                      label="Select Unit"
                      disabled={!values.property || isUnitsLoading}
                      loading={isUnitsLoading}
                      onBlur={undefined}
                      onChange={undefined}
                      optionFilterProp="label"
                    >
                      {units?.map((unit) => (
                        <Select.Option
                          key={unit.id}
                          label={unit.name}
                          value={unit.id}
                        >
                          <DetectableOverflow title={unit.name}>
                            {unit.name}
                          </DetectableOverflow>
                        </Select.Option>
                      ))}
                    </VeroFormField>
                  </Col>
                </Row>
              </Modal.Body>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};

export default InviteLeadToApplyModal;
