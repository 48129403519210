import styled from "@emotion/styled";

import FlexContainer from "shared/components/FlexContainer";
import { SmallTitle, RegularText } from "shared/components/Typography";
import {
  COLORS,
  MEDIA_QUERIES,
  BREAKPOINT_RESOLUTIONS,
} from "shared/config/constants";

const HeaderContainer = styled(FlexContainer)`
  position: relative;
  width: 100%;
  background-color: ${COLORS.primary};
  min-height: 200px;
  padding: 30px;
  flex-grow: 1;
  ${MEDIA_QUERIES.w.ltSm} {
    flex-direction: column !important;
    min-height: 0;
    padding: 20px;
  }
  .collapse-icon {
    position: absolute;
    right: 20px;
    top: 20px;
    min-height: 15px;
    min-width: 15px;
    path {
      stroke: ${COLORS.violetTheme.white};
    }
  }
  @media (min-width: ${BREAKPOINT_RESOLUTIONS.xs}px) {
    .collapse-container {
      width: 100%;
      > div {
        display: flex;
      }
    }
  }
`;

const HeaderTitle = styled(SmallTitle)`
  color: ${COLORS.violetTheme.white} !important;
  text-transform: uppercase;
  a {
    color: ${COLORS.violetTheme.white};
  }
`;

const HeaderColumn = styled(FlexContainer)`
  flex: 3;
  padding: 20px;
  border-right: 1px solid ${COLORS.secondary};
  min-height: 110px;
  &:last-child {
    border-right: none;
  }
  &:first-of-type {
    > div {
      text-align: left;
      span {
        padding-top: 20px;
      }
    }
  }
  ${MEDIA_QUERIES.w.ltSm} {
    border-right: none;
    width: 100%;
    flex-direction: column !important;
    align-items: flex-start !important;
    &:first-of-type {
      display: none;
    }
  }
  &.collapse-header-column {
    padding-top: 0;
    padding-bottom: 0;
    min-height: 0;
    span {
      margin-top: 10px;
    }
  }
`;
const CollapseHeader = styled(FlexContainer)`
  display: none;
  ${MEDIA_QUERIES.w.ltSm} {
    display: flex;
  }
`;

const Text = styled(RegularText)`
  display: block;
`;

const NoLicenceSectionContainer = styled(FlexContainer)`
  background-color: ${COLORS.violetTheme.white};
  padding: 30px;
`;

const NoLicenceSectionText = styled(SmallTitle)`
  margin: 10px auto 10px auto !important;
`;

const NoLicenceLinkImage = styled.img`
  padding-top: 10px;
`;

const TableActionLinkContainer = styled(FlexContainer)`
  justify-content: flex-end;
  ${MEDIA_QUERIES.w.ltSm} {
    justify-content: flex-start;
  }
`;

export {
  HeaderContainer,
  HeaderTitle,
  HeaderColumn,
  Text,
  NoLicenceSectionContainer,
  NoLicenceSectionText,
  NoLicenceLinkImage,
  TableActionLinkContainer,
  CollapseHeader,
};
