import React from "react";

import {
  BackgroundBottomLeftIllustration,
  BackgroundBottomRightIllustration,
  BackgroundTopLeftIllustration,
  BackgroundTopRightIllustration,
} from "shared/images/mainBackground";

import { BackgroundContent } from "./styled";

const MainBackground = () => (
  <BackgroundContent data-testid="main-background-content">
    <img src={BackgroundTopLeftIllustration} alt="bg" className="top-left-bg" />
    <img
      src={BackgroundTopRightIllustration}
      alt="bg"
      className="top-right-bg"
    />
    <img
      src={BackgroundBottomLeftIllustration}
      alt="bg"
      className="bottom-left-bg"
    />
    <img
      src={BackgroundBottomRightIllustration}
      alt="bg"
      className="bottom-right-bg"
    />
  </BackgroundContent>
);

export default MainBackground;
