import React from "react";

import { css } from "@emotion/core";
import styled from "@emotion/styled";

import { COLORS } from "shared/config/constants";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const VerticalDividerContent = styled(({ text, ...props }) => (
  <div {...props} />
))`
  position: relative;
  display: inline-block;
  min-height: 1rem;
  height: 100%;
  margin: 0 16px;
  text-align: center;

  .divider-text {
    position: absolute;
    top: calc(50% - 10px);
    transform: translateX(-50%);
    color: ${COLORS.primary};
  }

  &:before,
  &:after {
    position: absolute;
    content: "";
    left: 50%;
    border-left: 1px solid ${COLORS.midGray};
  }
  &:before {
    bottom: 50%;
    top: -10%;
    ${(props) =>
      props.text &&
      css`
        margin-bottom: 20px;
      `};
  }
  &:after {
    top: 50%;
    bottom: -10%;
    ${(props) =>
      props.text &&
      css`
        margin-top: 20px;
      `};
  }
`;

export { VerticalDividerContent };
