import { createAction } from "redux-actions";

import * as types from "./types";

export const receiveEmployees = createAction(
  types.RECEIVE_EMPLOYEES,
  ({ employees }) => ({ employees })
);

export const receiveEmployee = createAction(
  types.RECEIVE_EMPLOYEE,
  ({ employee }) => ({ employee })
);

export const updateEmployee = createAction(
  types.UPDATE_EMPLOYEE,
  (id, data) => ({ id, data })
);
