import React from "react";

import { Switch, Route, Redirect } from "react-router-dom";

import ROUTES, {
  APPLICATION_SUBMIT_ROUTES,
  GROUPED_ROUTES,
  GROUPED_ROUTES_KEYS,
} from "renter/config/routes";
import { useTranslation } from "renter/hooks/useTranslation";
import ApplicantPayments from "renter/pages/ApplicantPayments";
import Applications from "renter/pages/Applications";
import ApplicationSummary from "renter/pages/ApplicationSummary/ApplicationSummary";
import Login from "renter/pages/Login";
import {
  MagicLinkGetStarted,
  MagicLinkV2GetStarted,
} from "renter/pages/MagicLink/MagicLinkGetStarted";
import MagicLinkLanding from "renter/pages/MagicLink/MagicLinkLanding";
import MagicLinkProperties from "renter/pages/MagicLink/MagicLinkProperties";
import MagicPortal from "renter/pages/MagicPortal";
import Marketplace from "renter/pages/Marketplace";
import PasswordRecoverySetPassword from "renter/pages/PasswordRecoverySetPassword";
import PersonalInfo from "renter/pages/PersonalInfo";
import SetPassword from "renter/pages/SetPassword";
import App from "shared/components/App";
import Icon from "shared/components/Icon";
import { RenderModal } from "shared/components/Modal";
import { APPS, DealType, DEAL_TYPES } from "shared/config/constants";
import { useGoogleMapsApi } from "shared/hooks/useGoogleMapsApi";

/* Pages */
import Error404Page from "shared/pages/Error404Page";
import LoginSelfSignedDeepLinkBouncePage from "shared/pages/LoginSelfSignedDeepLinkBouncePage";
import PasswordRecoveryEmailSentPage from "shared/pages/PasswordRecoveryEmailSentPage";
import PasswordRecoveryPage from "shared/pages/PasswordRecoveryPage";
import SetPasswordLinkAcceptedPage from "shared/pages/SetPasswordLinkAcceptedPage";
import SetPasswordLinkExpiredPage from "shared/pages/SetPasswordLinkExpiredPage";
import SetPasswordLinkSentPage from "shared/pages/SetPasswordLinkSentPage";
import Layout from "shared/templates/Layout";

import { redirectToApplicantFlow } from "../shared/utils/redirect";

import { LeaseRentOptionsPage } from "./pages/LeaseRentOptions";
import { MagicLinkUnitRedirect } from "./pages/LeaseRentOptions/magic-link-unit-redirect";
import { MagicLinkInvalidPage } from "./pages/MagicLink/MagicLinkInvalidPage";

const RenterApp = () => {
  useGoogleMapsApi();

  const { t } = useTranslation({ keyPrefix: "renter-app" });
  const id: string = APPS.renter;
  return (
    <App
      id={id}
      render={({ user }) => {
        const backLinks = {
          applications: {
            children: t("back-link.applications"),
            to: ROUTES.applications,
          },
          personalInfo: {
            children: t("back-link.personal-info"),
            to: ROUTES.personalInfo,
          },
        };
        const { APPLICATIONS, PERSONAL_INFO, MARKETPLACE } = GROUPED_ROUTES_KEYS
        const sidebar = {
          items: [
            {
              key: APPLICATIONS,
              text: t("sidebar-title.applications"),
              route: ROUTES.applications,
              icon: Icon.ApplicationsIcon,
            },
            {
              key: PERSONAL_INFO,
              text: t("sidebar-title.personal-info"),
              route: ROUTES.personalInfo,
              icon: Icon.ProfileIcon,
            },
            {
              key: MARKETPLACE,
              text: t("sidebar-title.marketplace"),
              icon: Icon.IntegrationsMenuItemIcon,
              route: ROUTES.marketplace,
            },
          ],
          groupedRoutes: GROUPED_ROUTES,
        };
        const header = {
          logoLink: "/",
          notifications: [],
          user,
        };

        return (
          <>
            <Switch>
              {/* Redirects */}
              <Redirect exact from="/" to={ROUTES.applications} />
              {/* Application */}
              <Route
                exact
                path={ROUTES.applications}
                render={() => (
                  <Layout
                    sidebar={sidebar}
                    header={{
                      ...header,
                    }}
                  >
                    <Applications />
                  </Layout>
                )}
              />
              <Route
                exact
                path={ROUTES.applicantPayments}
                render={() => (
                  <Layout
                    sidebar={sidebar}
                    header={{
                      ...header,
                    }}
                  >
                    <ApplicantPayments />
                  </Layout>
                )}
              />
              <Route
                exact
                path={ROUTES.personalInfo}
                render={() => (
                  <Layout
                    sidebar={sidebar}
                    header={{
                      ...header,
                    }}
                  >
                    {/* @ts-ignore */}
                    <PersonalInfo name={t("personal-info.name")} />
                  </Layout>
                )}
              />
              <Route
                exact
                path={ROUTES.marketplace}
                render={() => (
                  <Layout
                    sidebar={sidebar}
                    header={{
                      ...header,
                    }}
                  >
                    <Marketplace />
                  </Layout>
                )}
              />

              <Route
                path={[
                  ROUTES.applicationInvitation,
                  ...APPLICATION_SUBMIT_ROUTES,
                ]}
                render={redirectToApplicantFlow}
              />
              {/* Auth views */}
              <Route exact path={ROUTES.login} render={() => <Login />} />
              <Route
                exact
                path={ROUTES.loginSelfSignedDeepLink}
                render={() => <LoginSelfSignedDeepLinkBouncePage />}
              />
              <Route
                exact
                path={ROUTES.setPassword}
                render={() => <SetPassword />}
              />
              <Route
                exact
                path={ROUTES.setPasswordLinkExpired}
                render={({ match }) => (
                  <SetPasswordLinkExpiredPage token={match.params.token} />
                )}
              />
              <Route
                exact
                path={ROUTES.passwordRecovery}
                render={() => <PasswordRecoveryPage />}
              />
              <Route
                exact
                path={ROUTES.passwordRecoveryEmailSent}
                render={({ location }) => (
                  // @ts-ignore
                  <PasswordRecoveryEmailSentPage email={location.state.email} />
                )}
              />
              <Route
                exact
                path={ROUTES.setPasswordLinkSent}
                render={() => <SetPasswordLinkSentPage />}
              />
              <Route
                exact
                path={ROUTES.setPasswordLinkAccepted}
                render={() => <SetPasswordLinkAcceptedPage />}
              />
              <Route
                exact
                path={ROUTES.passwordRecoverySetPassword}
                render={() => <PasswordRecoverySetPassword />}
              />
              <Route
                path={ROUTES.application}
                render={({ match }) => (
                  <ApplicationSummary
                    backLink={backLinks.applications}
                    sidebar={sidebar}
                    header={header}
                    match={match}
                  />
                )}
              />
              {/* Magic Links */}
              <Route
                exact
                path={ROUTES.leaseRentOptionsPage}
                render={() => <LeaseRentOptionsPage />}
              />
              <Route
                exact
                path={ROUTES.magicLinkLandingPage}
                render={() => <MagicLinkLanding />}
              />
              <Route
                exact
                path={ROUTES.magicLinkPropertiesPage}
                render={() => <MagicLinkProperties />}
              />
              <Route
                exact
                path={ROUTES.magicLinkGetStarted}
                render={() => <MagicLinkUnitRedirect />}
              />
              <Route
                exact
                path={ROUTES.prescreeningMagicLinkGetStarted}
                render={() => (
                  <MagicLinkGetStarted dealType={DealType.PRESCREENING} />
                )}
              />
              <Route
                exact
                path={ROUTES.magicLinkUnavailable}
                render={() => <MagicLinkInvalidPage />}
              />

              {/* Magic Link V2 */}
              <Route
                exact
                path={ROUTES.magicLinkV2GetStarted}
                render={() => (
                  <MagicLinkV2GetStarted dealType={DEAL_TYPES.SCREENING} />
                )}
              />
              <Route
                exact
                path={ROUTES.prescreeningMagicLinkV2GetStarted}
                render={() => (
                  <MagicLinkV2GetStarted dealType={DEAL_TYPES.PRESCREENING} />
                )}
              />

              {/* MagicPortal */}
              <Route path={ROUTES.magicPortal} render={() => <MagicPortal />} />

              {/* Other views */}
              <Route path={ROUTES.notFound} render={() => <Error404Page />} />
              <Route path="*" render={() => <Error404Page />} />
            </Switch>
            {/* Modals */}
            <RenderModal />
          </>
        );
      }}
    />
  );
};

export default RenterApp;
