import React, { useEffect } from "react";

import { useHistory } from "react-router-dom";

import { RequireAuthProps } from "./interfaces";
import { LoadingIcon } from "./styled";

const RequireAuth = ({
  loggedIn,
  isActiveUserLoaded,
  render,
}: RequireAuthProps) => {
  const history = useHistory();
  const showSpinner = !isActiveUserLoaded || !loggedIn;
  const currentRoute = history.location.pathname;

  useEffect(() => {
    if (!loggedIn && currentRoute !== '/login' && currentRoute !== '/auth/login') {
      window.location.href = `${window.location.origin}/auth/login?r=${currentRoute}`;
    }
  }, [loggedIn]);

  return showSpinner ? <LoadingIcon data-testid="loading-spinner" /> : render();
};

export default RequireAuth;
