import React from "react";

import { Row, Col } from "antd";
import { Form, Formik } from "formik";

import { useEditEmployeeName } from "manager/hooks/api";
import { Input, VeroFormField } from "shared/components/Form";
import { Modal } from "shared/components/Modal";
import Spacer from "shared/components/Spacer";
import { WeakText, RegularText } from "shared/components/Typography";

import {
  EditEmployeeValidationSchema,
  EDIT_EMPLOYEE_FIELDS,
} from "./editEmployee.config";
import { EditEmployeeDataModalProps } from "./interfaces";

export interface EditEmployee {
  firstName: string;
  lastName: string;
}

const EditEmployeeModal = ({ employeeData }: EditEmployeeDataModalProps) => {
  const { editEmployeeName } = useEditEmployeeName();
  const modalSubtitle = (
    <WeakText>
      Employee email <RegularText strong>{employeeData?.email}</RegularText>
    </WeakText>
  );

  const { firstName, lastName } = employeeData || {};

  return (
    <Formik
      validationSchema={EditEmployeeValidationSchema}
      initialValues={{
        firstName,
        lastName,
      }}
      onSubmit={(values: EditEmployee) =>
        editEmployeeName({
          employeeId: employeeData?.employeeId,
          firstName: values.firstName,
          lastName: values.lastName,
        })
      }
    >
      {({ submitForm, isSubmitting, isValid }) => (
        <Form>
          {/* @ts-ignore */}
          <Modal
            title="Edit Employee"
            subtitle={modalSubtitle}
            submitButtonDisabled={isSubmitting}
            submit={submitForm}
            submitButtonLabel="Edit"
            closeOnSubmit={isValid}
          >
            <Modal.Body>
              <Row gutter={[20, 20]}>
                <Col md={{ span: 24 }}>
                  {/* @ts-ignore */}
                  <VeroFormField
                    id={EDIT_EMPLOYEE_FIELDS.firstName}
                    name={EDIT_EMPLOYEE_FIELDS.firstName}
                    data-testid={EDIT_EMPLOYEE_FIELDS.firstName}
                    label="First Name"
                    type="text"
                    required
                    as={Input}
                  />
                </Col>
              </Row>
              <Spacer />
              <Row>
                <Col md={{ span: 24 }}>
                  {/* @ts-ignore */}
                  <VeroFormField
                    id={EDIT_EMPLOYEE_FIELDS.lastName}
                    name={EDIT_EMPLOYEE_FIELDS.lastName}
                    data-testid={EDIT_EMPLOYEE_FIELDS.lastName}
                    label="Last Name"
                    type="text"
                    required
                    as={Input}
                  />
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default EditEmployeeModal;
