import { useQuery } from "react-query";

import { NOTIFICATIONS } from "shared/config/constants";
import api from "shared/lib/api";
import { openNotification } from "shared/utils/ui";

const GET_LOAD_FILE_BY_URL = "getLoadFileByUrl";

export function useLoadFileByUrl(url: string) {
  const { data: fileByUrl, isLoading: isLoadingFileByUrl } = useQuery(
    [GET_LOAD_FILE_BY_URL],
    () => api.loadFileByUrl(url),
    {
      onError: () => {
        openNotification("Failed to load the document", NOTIFICATIONS.error);
      },
    }
  );

  return {
    fileByUrl,
    isLoadingFileByUrl,
  };
}
