import React, { useEffect } from "react";

import { useHistory } from "react-router-dom";

import TeaserModal from "shared/components/Modals/TeaserModal";
import { usePermissions } from "shared/hooks";
import useModal from "shared/hooks/useModal";

const useVerifyEmailTemplatesAccess = ({ pageName }) => {
  const { canCustomizeEmails } = usePermissions();
  const { openModalDialog } = useModal();
  const { goBack } = useHistory();

  useEffect(() => {
    if (!canCustomizeEmails) {
      openModalDialog(TeaserModal, {
        subtitleMessage: `You do not have access to ${pageName}`,
        description: (
          <>
            You do not have access to <strong>{pageName}</strong>. Contact your
            account manager to enable this feature.
          </>
        ),
      }).afterClose.then(() => goBack());
    }
  }, [canCustomizeEmails]);

  return {
    canCustomizeEmails,
  };
};

export default useVerifyEmailTemplatesAccess;
