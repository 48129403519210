import { useGetCompany } from "manager/hooks/api";
import { getCachedActiveCompanySlug, getCachedActiveCompanyId, isManagerApp } from "shared/utils/auth";

const useActiveCompany = () => {
  const isManager = isManagerApp();

  // NOTE: don't call useGetCompany if it's not Manager App
  if (!isManager) {
    return { activeCompany: null, isActiveCompanyLoading: false };
  }

  const companyId = getCachedActiveCompanyId();
  const companySlug = getCachedActiveCompanySlug();

  const { company, isGetCompanyLoading } = useGetCompany(companyId || companySlug);

  return {
    activeCompany: company,
    isActiveCompanyLoading: isGetCompanyLoading,
  };
};

export default useActiveCompany;
