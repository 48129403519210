import styled from "@emotion/styled";
import { Skeleton } from "antd";

const StyledSkeleton = styled(Skeleton)`
  .ant-skeleton-paragraph:last-of-type {
    margin-bottom: ${(props: { noMarginBottom?: boolean }) =>
      props.noMarginBottom ? 0 : undefined};
  }
`;

export { StyledSkeleton };
