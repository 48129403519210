import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import adminTranslation from "admin/i18n/en/translation.json";
import leadsTranslation from "leads/i18n/en/translation.json";
import managerTranslation from "manager/i18n/en/translation.json";
import renterTranslation from "renter/i18n/en/translation.json";
import { APPS } from "shared/config/constants";
import {
  isAdminApp,
  isLeadsApp,
  isManagerApp,
  isRenterApp,
  isSurveysApp,
  getActiveApp,
} from "shared/utils/auth";
import surveysTranslation from "surveys/i18n/en/translation.json";

export const resources = {
  en: {
    admin: isAdminApp() ? { ...adminTranslation } : {},
    renter: isRenterApp() ? { ...renterTranslation } : {},
    manager: isManagerApp() ? { ...managerTranslation } : {},
    leads: isLeadsApp() ? { ...leadsTranslation } : {},
    surveys: isSurveysApp() ? { ...surveysTranslation } : {},
  },
} as const;

i18n.use(initReactI18next).init({
  lng: "en",
  ns: [APPS.admin, APPS.leads, APPS.manager, APPS.renter, APPS.surveys],
  defaultNS: getActiveApp(),
  resources,
});
