import React, { useState } from "react";

import {
  getPrescreeningProperties,
  useGetLeadSources,
  useInviteLead,
} from "manager/hooks/api";
import { PROVIDER } from "shared/config/constants";
import useModal from "shared/hooks/useModal";

import InviteLeadModal from "./InviteLeadModal";

const InviteLeadModalContainer = () => {
  const { closeActiveModalDialog } = useModal();
  const { inviteLead } = useInviteLead();
  const [leadSourceRequired, setLeadSourceRequired] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState();

  const { prescreeningProperties, isPrescreeningPropertiesLoading } =
    getPrescreeningProperties();
  const { leadSources, isLoading: leadSourcesLoading } =
    useGetLeadSources(true);

  const submit = async (values, { setSubmitting, setErrors }) => {
    try {
      await inviteLead(values);
      closeActiveModalDialog();
    } catch (e) {
      if (e?.errors) {
        setErrors(e.errors);
      }
    } finally {
      setSubmitting(false);
    }
  };

  const onPropertyChange = (propertyId, setFieldValue, setFieldTouched) => {
    const selectedProperty = prescreeningProperties.find(
      (property) => property.id === propertyId
    );
    setLeadSourceRequired(
      selectedProperty.yardiConnected ||
        selectedProperty.provider === PROVIDER.ENTRATA
    );
    setSelectedProperty(selectedProperty);
    setFieldValue("property", propertyId);
    setFieldValue("leadSource", undefined);
    setFieldTouched("leadSource", false);
    setFieldValue("floorplan", undefined);
  };

  return (
    <InviteLeadModal
      submit={submit}
      onPropertyChange={onPropertyChange}
      leadSources={leadSources}
      leadSourcesLoading={leadSourcesLoading}
      leadSourceRequired={leadSourceRequired}
      selectedProperty={selectedProperty}
      prescreeningProperties={prescreeningProperties}
      isPrescreeningPropertiesLoading={isPrescreeningPropertiesLoading}
    />
  );
};

export default InviteLeadModalContainer;
