import React, {
  Key,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";

import isBoolean from "lodash/isBoolean";
import isEqual from "lodash/isEqual";
import isString from "lodash/isString";

import {
  CollapsibleListProps,
  CollapsibleList,
} from "shared/components/Form/CollapsibleList";

import { CheckboxItem } from "shared/utils/checkbox";

import { CollapsibleCheckboxListDerived } from "./CollapsibleCheckboxListDerived";
import { Checkbox } from "./styled";

export type CollapsibleCheckboxListProps = {
  detachParentChildren?: boolean;
  listProps: CollapsibleListProps;
  checkedItems?: Key[];
  indeterminateItems?: Key[];
  checkedItemsOnChange?: (keys: Key[]) => void;
  listRef?: React.Ref<any>;
};

const _CollapsibleCheckboxList = ({
  listProps,
  checkedItems: initialCheckedItems,
  indeterminateItems: initialIndeterminateItems,
  checkedItemsOnChange,
  detachParentChildren = false,
  listRef,
}: CollapsibleCheckboxListProps) => {
  const allItems = listProps?.items || [];
  const allVisibleItems = (listProps?.visibleItems || []).filter(
    (item) => !item.disabled
  );
  const allActiveItems = allItems.filter((item) => !item.disabled);
  const [checkedItems, setCheckedItems] = useState(initialCheckedItems || []);
  const [indeterminateItems, setIndeterminateItems] = useState(
    initialIndeterminateItems || []
  );
  const checkedActiveItems =
    // @ts-ignore
    checkedItems?.filter((item) => !item.disabled) || [];
  const checkedItemsSet = new Set(checkedItems);
  const indeterminateItemsSet = new Set(indeterminateItems);

  const isChecked = (key: Key) => checkedItemsSet.has(key);
  const isIndeterminate = (key: Key) => indeterminateItemsSet.has(key);

  // NOTE: calculate the parent-children relationship
  const { parentToChildrenMap, childToParentMap } = useMemo(() => {
    const parentToChildren = new Map<Key, Key[]>();
    const childToParent = new Map<Key, Key>();
    allItems.forEach((item) => {
      const { key, parent } = item;
      if (parent) {
        const children = parentToChildren.get(parent) || [];
        children.push(item.key);
        parentToChildren.set(parent, children);
        childToParent.set(key, parent);
      } else {
        parentToChildren.set(parent, parentToChildren.get(parent) || []);
      }
    });
    return {
      parentToChildrenMap: parentToChildren,
      childToParentMap: childToParent,
    };
  }, [allItems]);

  const toggleManyCheckboxes = (
    currentCheckedItems: Key[],
    items: CheckboxItem[],
    value: boolean
  ) => {
    const newCheckedItems = new Set(currentCheckedItems);
    const newIndeterminateItems = new Set();

    const countCheckedChildrenPerParent = new Map();

    // NOTE: Selection/toggle process consists of 4 steps:
    // 1. Apply updates on the specified items in this method (check or uncheck)
    // 2. Check if a parent item was modified, changes should be propagated to the children
    // 3. Calculate number of checked children for every parent
    // 4. Calculate state of every parent based on the state of the children (checked, indeterminate, unchecked)
    //  This step makes sure the parent is consistent when there's a change ONLY on a child item,
    //  step 2. handles the case when the parent was modified directly

    // NOTE: Step 1. apply updates on the specified items
    const resolvedItems = items || [];
    resolvedItems.forEach((item) => {
      const newValue = isBoolean(value) ? value : !isChecked(item.key);
      if (newValue) {
        newCheckedItems.add(item.key);
      } else {
        newCheckedItems.delete(item.key);
      }
    });

    if (!detachParentChildren) {
      // NOTE: Step 2. side-effect -> if a parent item was modified, the change should be
      // propagated to the children
      resolvedItems
        .filter((i) => !i.parent)
        .forEach(({ key }) => {
          const children = parentToChildrenMap.get(key) || [];
          if (newCheckedItems.has(key)) {
            children.forEach((child) => newCheckedItems.add(child));
          } else {
            children.forEach((child) => newCheckedItems.delete(child));
          }
        });

      // NOTE: Step 3. calculate how many checked children has every parent item
      // (preparation for the next step)
      newCheckedItems.forEach((checkedKey) => {
        const parent = childToParentMap.get(checkedKey);
        if (parent) {
          countCheckedChildrenPerParent.set(
            parent,
            (countCheckedChildrenPerParent.get(parent) || 0) + 1
          );
        }
      });

      // NOTE: Step 4. side-effect -> check how many checked children every parent has.
      // Used to calculate the state of the parent: checked, indeterminate, unchecked.
      parentToChildrenMap.forEach((children, parentKey) => {
        if (children && children.length) {
          const countChecked = countCheckedChildrenPerParent.get(parentKey);
          if (countChecked === children.length) {
            newCheckedItems.add(parentKey);
          } else if (countChecked > 0) {
            newCheckedItems.delete(parentKey);
            newIndeterminateItems.add(parentKey);
          } else {
            newCheckedItems.delete(parentKey);
          }
        }
      });
    }

    // @ts-ignore
    setCheckedItems([...newCheckedItems]);
    // @ts-ignore
    setIndeterminateItems([...newIndeterminateItems]);
  };

  const toggleCheckbox = (item: CheckboxItem, value: boolean) =>
    toggleManyCheckboxes(checkedItems, [item], value);

  const applyValueToAllItems = (value: boolean) =>
    toggleManyCheckboxes(checkedItems, allActiveItems, value);

  const applyValueToAllVisibleItems = (value: boolean) =>
    toggleManyCheckboxes([], allVisibleItems, value);

  const selectItemsById = (itemIds: Key[]) => {
    const itemIdsSet = new Set(itemIds);
    const itemsToSelect = allItems.filter((item) => itemIdsSet.has(item.key));
    toggleManyCheckboxes(checkedItems, itemsToSelect, true);
  };

  const unselectItemsById = (itemIds: Key[]) => {
    const itemIdsSet = new Set(itemIds);
    const itemsToSelect = allItems.filter((item) => itemIdsSet.has(item.key));
    toggleManyCheckboxes(checkedItems, itemsToSelect, false);
  };

  const allChecked = (itemIds?: Key[]) => {
    if (itemIds?.length) {
      return (itemIds || []).every((itemId) => isChecked(itemId));
    }

    return checkedActiveItems.length === allActiveItems.length;
  };

  const allVisibleChecked = (itemIds?: Key[]) => {
    if (itemIds?.length) {
      return (itemIds || []).every((itemId) => isChecked(itemId));
    }

    return checkedActiveItems.length === allVisibleItems.length;
  };

  const noneChecked = () => {
    return checkedItems?.length === 0;
  };

  useImperativeHandle(listRef, () => ({
    selectAll: () => applyValueToAllItems(true),
    selectAllVisible: () => applyValueToAllVisibleItems(true),
    unselectAll: () => applyValueToAllItems(false),
    selectItemsById,
    unselectItemsById,
    allChecked,
    allVisibleChecked,
    noneChecked,
    someChecked: () => !noneChecked() && !allChecked(),
    someVisibleChecked: () => !noneChecked() && !allVisibleChecked(),
  }));

  useEffect(() => {
    const newItems = {
      checked: [...checkedItems],
      indeterminate: [...indeterminateItems],
    };

    const allKeys = new Set((allItems || []).map((item) => item.key));
    // NOTE: in case the list of items was changed, we need to filter out
    // checked and indeterminate items that don't exist anymore
    newItems.checked = newItems.checked.filter((item) => allKeys.has(item));
    newItems.indeterminate = newItems.indeterminate.filter((item) =>
      allKeys.has(item)
    );

    // NOTE: set checked and indeterminate items ONLY if there's a change
    // otherwise an infinite loop could be created
    const newCheckedItemsSet = new Set(newItems.checked);
    if (!isEqual(new Set(checkedItems), newCheckedItemsSet)) {
      // @ts-ignore
      setCheckedItems([...newCheckedItemsSet]);
    }
    const newIndeterminateItemsSet = new Set(newItems.indeterminate);
    if (!isEqual(new Set(indeterminateItems), newIndeterminateItemsSet)) {
      // @ts-ignore
      setIndeterminateItems([...newIndeterminateItemsSet]);
    }
  }, [allItems]);

  useEffect(() => {
    if (checkedItemsOnChange) {
      checkedItemsOnChange(checkedItems);
    }
  }, [checkedItems]);

  return (
    <CollapsibleList
      {...listProps}
      setSelectedItems={toggleCheckbox}
      selectedItems={checkedItems}
      renderCollapseInput
      renderInput={({ item, onChange }) => {
        const key = `checkbox-${item.key}`;
        const indeterminate = isIndeterminate(item.key);

        return item.notCheckBox ? (
          item.label
        ) : (
          <Checkbox
            id={key}
            name={key}
            data-testid={key}
            checked={isChecked(item.key)}
            indeterminate={indeterminate}
            data-indeterminate={indeterminate}
            label={!item.collapsible && item.label}
            title={
              listProps?.virtualize && isString(item.label)
                ? item.label
                : undefined
            }
            onChange={onChange}
            disabledCheckboxText={listProps.disabledCheckboxText}
            disabled={item.disabled}
          />
        );
      }}
    />
  );
};

export const CollapsibleCheckboxList = Object.assign(_CollapsibleCheckboxList, {
  Derived: CollapsibleCheckboxListDerived,
});
