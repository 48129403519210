import React from "react";

import PropTypes from "prop-types";

import { ActionLink } from "shared/components/Links";
import Tooltip from "shared/components/Tooltip";

import {
  Container,
  Description,
  SubTitle,
  Title,
  TitleContainer,
  DisabledLink,
} from "./styled";

const Header = ({
  title,
  subTitle,
  description,
  actionLink,
  disableLink,
  tooltip,
  actionLinkClick,
  LinkComponent,
}) => (
  <Container>
    <TitleContainer hasSubTitle={!!subTitle}>
      <Title className="header-title-label flex-grow" data-testid="title">
        {title}
      </Title>
      {description && (
        <Description data-testid="description" className="flex-grow">
          ({description})
        </Description>
      )}
      <div className="header-action-link">
        {actionLink && !disableLink && (
          <LinkComponent onClick={actionLinkClick} data-testid="actionLink">
            {actionLink}
          </LinkComponent>
        )}
        {disableLink && tooltip && (
          <Tooltip theme="light" title={tooltip} placement="topRight">
            <DisabledLink strong>{actionLink}</DisabledLink>
          </Tooltip>
        )}
      </div>
    </TitleContainer>
    {subTitle && <SubTitle data-testid="subTitle">{subTitle}</SubTitle>}
  </Container>
);

Header.propTypes = {
  title: PropTypes.node.isRequired,
  subTitle: PropTypes.string,
  description: PropTypes.string,
  actionLink: PropTypes.string,
  disableLink: PropTypes.bool,
  tooltip: PropTypes.string,
  actionLinkClick: PropTypes.func,
  LinkComponent: PropTypes.elementType,
};

Header.defaultProps = {
  subTitle: undefined,
  description: undefined,
  actionLink: undefined,
  disableLink: false,
  tooltip: undefined,
  actionLinkClick: () => {},
  LinkComponent: ActionLink,
};

export default Header;
