import React, { useEffect, useRef, useState } from "react";

import {
  useGetYardiCredentials,
  useGetYardiPcodePoints,
  useGetYardiSyncIntevals,
  useGetYardiTcodePoints,
  useUpdateYardiMappingConfiguration,
  useGetYardiLeaseSignEventPoints,
  useGetYardiSecurityDepositPreferences,
} from "manager/hooks/api";
import { useGetIntegrationProvider } from "manager/pages/Marketplace/config";
import { IntegrationProvider } from "shared/config/constants";
import { resetFormAndValidate } from "shared/utils/forms";
import { backLinkPropType } from "shared/utils/propTypes";

import { MAPPING_TYPES_COMPLETED_PROPS } from "../shared";

import SetupYardiSyncSettings from "./SetupYardiSyncSettings";

const createOption = (item) => ({
  value: item.id,
  label: item.value,
});

const mapLowerValuesToDisabled = (referenceValue, option) => {
  return {
    ...option,
    ...(referenceValue && option.value < referenceValue && { disabled: true }),
  };
};
const mapHigherValuesToDisabled = (referenceValue, option) => {
  return {
    ...option,
    ...(referenceValue && option.value > referenceValue && { disabled: true }),
  };
};

const SetupYardiSyncSettingsContainer = ({ backLink }) => {
  const { provider } = useGetIntegrationProvider();
  const formikRef = useRef(null);
  const { yardiCredentials, isLoading } = useGetYardiCredentials(provider);
  const { updateYardiMappingConfiguration } =
    useUpdateYardiMappingConfiguration({
      provider,
      completeSaveMessage: "Sync settings successfully updated",
      incompleteSaveMessage: "Progress Saved. Sync settings are incomplete.",
      completedProp: MAPPING_TYPES_COMPLETED_PROPS.syncOptions,
    });
  const { yardiPcodePoints } = useGetYardiPcodePoints();
  const { yardiTcodePoints } = useGetYardiTcodePoints();
  const { yardiSyncIntervals } = useGetYardiSyncIntevals();
  const { yardiLeaseSignPoints } = useGetYardiLeaseSignEventPoints();
  const { yardiSecurityDepositPreferences } =
    useGetYardiSecurityDepositPreferences();

  const [allOptions, setAllOptions] = useState({
    autoSyncOptions: [],
    prospectCreationOptions: [],
    tenantCreationOptions: [],
    leaseSignEventOptions: [],
    securityDepositOptions: [],
  });

  const updateOptions = (values = {}) => {
    const { pcodeCreationPoint, tcodeCreationPoint, leaseSignPoint } = values;

    const autoSyncOptions = (yardiSyncIntervals || []).map(createOption);
    const prospectCreationOptions = (yardiPcodePoints || [])
      .map(createOption)
      .map((o) =>
        provider === IntegrationProvider.entrata
          ? // NOTE: For Entrata only available option is 'Application submitted by main applicant'
            mapLowerValuesToDisabled(600, o)
          : mapHigherValuesToDisabled(tcodeCreationPoint, o)
      );
    const tenantCreationOptions = (yardiTcodePoints || [])
      .map(createOption)
      .map((o) => mapLowerValuesToDisabled(pcodeCreationPoint, o))
      .map((o) => mapHigherValuesToDisabled(leaseSignPoint, o));
    const leaseSignEventOptions = (yardiLeaseSignPoints || [])
      .map(createOption)
      .map((o) => mapLowerValuesToDisabled(tcodeCreationPoint, o));
    const securityDepositOptions = (yardiSecurityDepositPreferences || []).map(
      createOption
    );

    const newOptions = {
      autoSyncOptions,
      prospectCreationOptions,
      tenantCreationOptions,
      leaseSignEventOptions,
      securityDepositOptions,
    };

    setAllOptions(newOptions);
  };

  const setupInitialState = (data) => {
    const values = {
      syncInterval: data.syncInterval,
      pcodeCreationPoint: data.pcodeCreationPoint,
      tcodeCreationPoint: data.tcodeCreationPoint,
      sendAddressIntoGuestCard: data.sendAddressIntoGuestCard,
      leaseSignPoint: data.leaseSignPoint,
      securityDepositPreference: data.securityDepositPreference,
      alertEmailInterval: data.alertEmailInterval,
      alertEmailRecipients: [...(data.alertEmailRecipients || []), ""],
      rentEditableDuringLeasing: data.rentEditableDuringLeasing,
    };

    resetFormAndValidate(formikRef, { values });
  };

  const saveData = (data, config) => {
    const updatedData = {
      ...data,
      alertEmailRecipients: data.alertEmailRecipients.filter((e) => !!e),
    };
    return updateYardiMappingConfiguration(updatedData, config);
  };

  const onSubmit = (data, { setSubmitting }) => {
    const onSettled = () => setSubmitting(false);
    saveData(data, { onSettled });
  };

  useEffect(() => {
    if (!isLoading && yardiCredentials) {
      setupInitialState(yardiCredentials);
    }
  }, [isLoading, yardiCredentials]);

  useEffect(() => {
    updateOptions(formikRef.current?.values);
  }, [
    yardiPcodePoints,
    yardiTcodePoints,
    yardiSyncIntervals,
    yardiLeaseSignPoints,
    yardiSecurityDepositPreferences,
  ]);

  return (
    <SetupYardiSyncSettings
      onSubmit={onSubmit}
      saveData={saveData}
      formikRef={formikRef}
      autoSyncOptions={allOptions?.autoSyncOptions}
      prospectCreationOptions={allOptions?.prospectCreationOptions}
      tenantCreationOptions={allOptions?.tenantCreationOptions}
      leaseSignEventOptions={allOptions?.leaseSignEventOptions}
      securityDepositOptions={allOptions?.securityDepositOptions}
      updateOptions={updateOptions}
      backLink={backLink}
    />
  );
};

SetupYardiSyncSettingsContainer.propTypes = {
  backLink: backLinkPropType.isRequired,
};

export default SetupYardiSyncSettingsContainer;
