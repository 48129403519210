import React from "react";

import LoginSelfSignedDeepLinkBouncePage from "shared/pages/LoginSelfSignedDeepLinkBouncePage";

const LeadsLoginSelfSignedDeepLinkBouncePage = () => (
  <LoginSelfSignedDeepLinkBouncePage
    hideBackButton
    defaultDescription={
      <>Please contact the leasing agent to invite you again.</>
    }
  />
);

export default LeadsLoginSelfSignedDeepLinkBouncePage;
