import React from "react";

import { Helmet } from "react-helmet";

import {
  StyledTileInner,
  SetupYardiPropertyTitle,
  SelectExtension,
} from "manager/pages/Marketplace/integrations/yardi/shared/mapping/styled";
import { YardiTitleContainer } from "manager/pages/Marketplace/integrations/yardi/styled";
import { Button } from "shared/components/Button";
import Container from "shared/components/Container";
import FlexContainer from "shared/components/FlexContainer";
import { Select } from "shared/components/Form/Select";
import Icon from "shared/components/Icon";
import { ActionLink, BackLink } from "shared/components/Links";
import PageTitle from "shared/components/PageTitle";
import Tile from "shared/components/Tile";
import { IntegrationProvider } from "shared/config/constants";
import { BackLinkProps } from "shared/interfaces/misc";

import YardiHeaderCredentials from "../shared/YardiHeaderCredentials";

const title = "Setup Property";

export interface BasicPropertyType {
  id: number;
  name: string;
  address: string;
  yardiConnected?: boolean;
  fullAddress?: string;
  provider?: IntegrationProvider;
}

interface SetupYardiPropertyProps {
  backLink: BackLinkProps;
  createNewProperty: (data: any) => void;
  selectProperty: (propId: number | string) => void;
  setupCompleted: () => void;
  selectedVeroProperty?: BasicPropertyType;
  veroProperties?: Array<BasicPropertyType>;
  currentYardiProperty?: BasicPropertyType;
}

const SetupYardiProperty = ({
  backLink,
  selectProperty,
  createNewProperty,
  setupCompleted,
  selectedVeroProperty,
  veroProperties,
  currentYardiProperty,
}: SetupYardiPropertyProps) => {
  return (
    <Container expand>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {backLink && (
        <BackLink.Container>
          <BackLink {...backLink} />
        </BackLink.Container>
      )}
      <PageTitle.Container center>
        <div>
          <YardiTitleContainer>
            {currentYardiProperty?.name} Setup
          </YardiTitleContainer>
          <PageTitle.Subtitle>
            {currentYardiProperty?.address}
          </PageTitle.Subtitle>
        </div>
        <YardiHeaderCredentials loadCredentials />
      </PageTitle.Container>
      <Tile>
        <StyledTileInner>
          <FlexContainer flexDirection="column" alignItems="center">
            <Icon.PropertiesLargeIcon />
            <SetupYardiPropertyTitle data-testid="setup-question">
              Which VERO property should be connected to{" "}
              {currentYardiProperty?.name}?
            </SetupYardiPropertyTitle>
            <div className="vero-properties-select-container">
              <Select
                data-testid="properties-select"
                id="selectVeroProperty"
                name="selectVeroProperty"
                className="vero-properties-select"
                value={selectedVeroProperty && `${selectedVeroProperty.id}`}
                onChange={selectProperty}
                customDropdownFooter={
                  <SelectExtension data-testid="select-dropdown-extension">
                    <ActionLink onMouseDown={createNewProperty}>
                      + CREATE NEW
                    </ActionLink>
                  </SelectExtension>
                }
              >
                {veroProperties?.map((p) => (
                  <Select.Option
                    key={p.id}
                    data-testid={`option-${p.id}`}
                    disabled={Boolean(p.provider)}
                  >
                    {p.name}
                    {p.provider ? " (already synced to External Provider)" : ""}
                  </Select.Option>
                ))}
              </Select>
              <p className="vero-properties-select-address">
                {selectedVeroProperty?.fullAddress}
              </p>
            </div>
            <Button
              type="primary"
              className="action-button"
              disabled={!selectedVeroProperty}
              onClick={setupCompleted}
            >
              NEXT
            </Button>
          </FlexContainer>
        </StyledTileInner>
      </Tile>
    </Container>
  );
};

export default SetupYardiProperty;
