import styled from "@emotion/styled";

import { Col } from "antd";

import ActionLink from "shared/components/Links/ActionLink";
import Tile from "shared/components/Tile";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";
import { colors } from "shared/config/constants/app.theme";
import { AlignedCenterFlexPartialCss } from "shared/styles/commonStyles";

const TABLE_BORDER = `1px solid ${COLORS.borders}`;

interface SummaryValueProps {
  disabled?: boolean;
}

const YardiSummaryTile = styled(Tile)`
  background-color: ${COLORS.primary};
`;

const SummaryLabel = styled("p")`
  font-weight: 500;
  margin-bottom: 5px;
`;

const SummaryValue = styled("p")<SummaryValueProps>`
  display: flex;
  align-items: center;
  font-weight: 400;
  margin-bottom: 5px;
  color: ${(props) => props.disabled && COLORS.disabledText};
  .summary-value-icon {
    margin-right: 8px;
  }
`;

const SummaryActionLink = styled(ActionLink)`
  margin-top: 10px;
  color: ${COLORS.button.transparent.green.default.text};
  &:hover {
    color: ${colors.shades[0]};
  }
`;

const NoTableItemsTitle = styled("h2")`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const TableWrapper = styled("div")`
  border: ${TABLE_BORDER};
  .delete-icon {
    margin-left: 24px;
  }
`;

const TableHeader = styled("div")`
  ${{ ...AlignedCenterFlexPartialCss }}
  justify-content: space-between;
  padding: 24px;
  border-bottom: ${TABLE_BORDER},
  background-color: ${COLORS.white};
  h2 {
    margin: 0;
  }
  .property-status-filter {
    margin-left: 24px;
  }
  ${MEDIA_QUERIES.w.ltLg} {
    flex-direction: column;
    .table-header-title {
      flex-direction: column;
      align-items: center;
      .property-status-filter {
        margin: 8px 0;
      }
    }
  }
`;

const FiltersContainer = styled.div`
  ${MEDIA_QUERIES.w.ltSm} {
    padding: 0 25px 10px 25px;
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
  }
`;

const PropertyStatus = styled("p")`
  margin: 0;
  ${{ ...AlignedCenterFlexPartialCss }}
  .property-status-icon {
    margin-right: 8px;
  }
`;

// V2-2208: hide the Charge Types setting
const YardiHiddenCol = styled(Col)`
  display: none;
`;

export {
  FiltersContainer,
  YardiSummaryTile,
  SummaryLabel,
  SummaryValue,
  SummaryActionLink,
  NoTableItemsTitle,
  TableWrapper,
  TableHeader,
  PropertyStatus,
  YardiHiddenCol,
};
