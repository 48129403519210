import { ElementType } from "react";

import Icon from "shared/components/Icon";
import { COLORS, IntegrationProvider } from "shared/config/constants";
import { EntrataLogoSvg, RealPageLogoSvg } from "shared/icons";
import { YardiLogoDarkSvg } from "shared/images";

export enum IntegrationStatus {
  SUSPENDED = "SUSPENDED",
  NOT_CONNECTED = "NOT_CONNECTED",
  SETUP_INCOMPLETE = "SETUP_INCOMPLETE",
  IN_ERROR = "IN_ERROR",
  NEEDS_UPDATE = "NEEDS_UPDATE",
  CONNECTED = "CONNECTED",
  IN_PROGRESS = "IN_PROGRESS",
}

export enum MessageType {
  ONLY_AVAILABILITY_ALERTS = "ONLY_AVAILABILITY_ALERTS",
  ONLY_OTHER_ALERTS = "ONLY_OTHER_ALERTS",
  MIXED_ALERTS = "MIXED_ALERTS",
}

const IntegrationBaseStatusConfig = {
  [IntegrationStatus.NOT_CONNECTED]: {
    text: "Disconnected",
    circleBackgroundColor: COLORS.midGray,
    message: {},
  },
  [IntegrationStatus.SUSPENDED]: {
    text: "Suspended",
    circleBackgroundColor: COLORS.midGray,
    message: {
      title: "Suspended",
      description:
        "The connection has been temporarily suspended by your VERO admin.",
      integrationIcon: Icon.Info,
    },
  },
  [IntegrationStatus.CONNECTED]: {
    text: "Connected",
    circleBackgroundColor: COLORS.green,
    message: {
      title: "Connection is active.",
      integrationIcon: Icon.CheckIcon,
    },
  },
  [IntegrationStatus.IN_PROGRESS]: {
    text: "Connected",
    circleBackgroundColor: COLORS.green,
    message: {
      title: "Refreshing",
      description: "Refresh is in-progress.",
      integrationIcon: Icon.Info,
    },
  },
  [IntegrationStatus.NEEDS_UPDATE]: {
    text: "Connected",
    circleBackgroundColor: COLORS.green,
  },
  [IntegrationStatus.IN_ERROR]: {
    text: "Connected",
    circleBackgroundColor: COLORS.green,
  },
};

const IntegrationYardiStatusConfig = {
  ...IntegrationBaseStatusConfig,
  [IntegrationStatus.SETUP_INCOMPLETE]: {
    text: "Incomplete",
    circleBackgroundColor: COLORS.midOrange,
    message: {
      title: "Setup incomplete.",
      description: "Please complete the setup process to confirm connection.",
      integrationIcon: Icon.Info,
    },
  },
};

const IntegrationEntrataStatusConfig = {
  ...IntegrationBaseStatusConfig,
  [IntegrationStatus.SETUP_INCOMPLETE]: {
    text: "Incomplete",
    circleBackgroundColor: COLORS.midOrange,
    message: {
      title: "Setup incomplete.",
      description:
        "Please complete the Entrata setup process to confirm connection.",
      integrationIcon: Icon.Info,
    },
  },
};

const IntegrationRealPageStatusConfig = {
  ...IntegrationBaseStatusConfig,
  [IntegrationStatus.SETUP_INCOMPLETE]: {
    text: "Incomplete",
    circleBackgroundColor: COLORS.midOrange,
    message: {
      title: "Setup incomplete.",
      description: "Please complete the setup process to confirm connection.",
      integrationIcon: Icon.Info,
    },
  },
};

export const IntegrationStatusConfig = {
  [IntegrationProvider.yardi]: IntegrationYardiStatusConfig,
  [IntegrationProvider.entrata]: IntegrationEntrataStatusConfig,
  [IntegrationProvider.realPage]: IntegrationRealPageStatusConfig,
};

export const IntegrationLogo = {
  [IntegrationProvider.yardi]: YardiLogoDarkSvg,
  [IntegrationProvider.entrata]: EntrataLogoSvg,
  [IntegrationProvider.realPage]: RealPageLogoSvg,
};

export const PollIntervalMilli = 5000;

export const StatusesToPoll = new Set(["pending", "inprogress"]);

interface IIntegrationStatusConfig {
  text: string;
  circleBackgroundColor: string;
  message: {
    title: string;
    description: ElementType;
    integrationIcon: ElementType;
  };
}

export const getIntegrationStatusConfig = (
  provider: IntegrationProvider,
  status: IntegrationStatus = IntegrationStatus.NOT_CONNECTED
): IIntegrationStatusConfig => IntegrationStatusConfig[provider][status];
