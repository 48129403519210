import RENTER_ROUTES from "renter/config/routes";

export const ROUTES = {
  leadStart: "/applications/:id/lead-start",
  leadFinish: "/applications/:id/lead-finish",
  leadAcceptTerms: "/applications/:id/lead-accept-terms",
};

export const LEAD_APPLICATION_SUBMIT_ROUTES = [
  RENTER_ROUTES.submitIdentityVerification,
  RENTER_ROUTES.submitLinkEmployment,
  RENTER_ROUTES.submitApplication.payment,
  RENTER_ROUTES.submitApplication.incomeAssetsVerification.selectAccounts,
  RENTER_ROUTES.submitApplication.incomeAssetsVerification.summary,
  RENTER_ROUTES.submitApplication.incomeAssetsVerification.oAuth,
  RENTER_ROUTES.submitApplication.submitApplicationDedicatedStep,
];
