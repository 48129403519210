import styled from "@emotion/styled";

import { Modal } from "shared/components/Modal";

const ModalBody = styled(Modal.Body)`
  padding-bottom: 0;
`;

const DocumentListContainer = styled.div`
  background-color: #f9fafc;
  border-radius: 2px;
  padding: 15px 27px;
  margin-bottom: 8px;

  &:last-of-type {
    margin-bottom: 24px;
  }
`;

const DocumentList = styled.ul`
  list-style-position: inside;
  padding: 0;
  margin: 0;
`;

const DocumentListTitle = styled.p`
  font-weight: 500;
  font-size: 15px;
  line-height: 150%;
  color: #2d2943;
  margin-bottom: 3px;
`;

const DocumentListTitleNote = styled.span`
  color: #b2afc8;
`;

const DocumentListItem = styled.li`
  font-size: 15px;
  line-height: 150%;
  color: #2d2943;
  margin-bottom: 3px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export {
  DocumentListContainer,
  DocumentList,
  DocumentListTitle,
  DocumentListTitleNote,
  DocumentListItem,
  ModalBody,
};
