import axios from "axios";

import ROUTES from "renter/config/apiRoutes";
import { ApplicantDeal } from "renter/interfaces/api/deal";
import { API_URL } from "shared/lib/api/setupApi";
import { transformRoute } from "shared/utils/routing";

export const getApplicantDeal = (id: number) =>
  axios
    .get<ApplicantDeal>(
      `${API_URL}${transformRoute(ROUTES.applicantDeal, { id })}`
    )
    .then(({ data }) => data);
