

import {
  ApplicationSummaryCard,
  CardSize,
  CardStatus,
} from "shared/components/ApplicationSummaryCard";
import {
  ApplicationSummaryTooltip,
  TooltipColumnWidth,
} from "shared/components/ApplicationSummaryTooltip";
import Tooltip from "shared/components/Tooltip";

import { printDollars } from "shared/utils/dollar-print";
import { getValue } from "../utils";

export interface EvictionCardProps {
  skipped?: boolean;
  skippedReason?: string;
  records?: number;
  judgmentAmount?: number;
  lastRecord?: string;
  onClick?: () => void;
  lumenEnabled?: boolean;
  lumenRecords?: {
    crucial?: boolean;
    safetyNet?: boolean;
  };
  useHousingCourtRecords?: boolean;
  enabled?: boolean;
}

export const EvictionCard = ({
  skipped,
  skippedReason,
  records,
  judgmentAmount,
  lastRecord,
  onClick,
  lumenEnabled,
  lumenRecords,
  useHousingCourtRecords,
}: EvictionCardProps) => {
  const additionalProps = () => {
    if (lumenEnabled) {
      const { crucial, safetyNet } = lumenRecords ?? {};
      if (skipped) {
        return {
          title: "SKIPPED",
          status: CardStatus.error,
        };
      }
      if (crucial) {
        return {
          title: "YES",
          status: CardStatus.error,
        };
      }
      if (safetyNet) {
        return {
          title: "YES",
          status: CardStatus.warn,
        };
      }
      if (!records && records !== 0) {
        return {
          title: "IN PROCESS",
          status: CardStatus.unavailable,
        };
      }
      return {
        title: records > 0 ? "Yes" : "No",
        status: CardStatus.lumenSuccess,
      };
    }

    if (skipped) {
      return {
        title: "SKIPPED",
        status: CardStatus.error,
      };
    }
    if (!records && records !== 0) {
      return {
        title: "IN PROGRESS",
        status: CardStatus.unavailable,
      };
    }
    if (records > 0) {
      return {
        title: "YES",
        status: CardStatus.error,
      };
    }
    return {
      title: "NO",
      status: CardStatus.success,
    };
  };

  const card = (
    <div>
      <ApplicationSummaryCard
        onClick={onClick}
        subtitle="Eviction Related Proceedings"
        size={CardSize.Small}
        {...additionalProps()}
        hideColor={lumenEnabled && !useHousingCourtRecords}
      />
    </div>
  );

  return skipped ? (
    <Tooltip title={skippedReason}>{card}</Tooltip>
  ) : (
    <ApplicationSummaryTooltip
      layout={[
        {
          title: "Court Reported Amount*:",
          subtitle: printDollars(judgmentAmount),
          width: TooltipColumnWidth.half,
        },
        {
          title: "Most Recent Record:",
          subtitle: getValue(lastRecord),
          width: TooltipColumnWidth.half,
        },
        {
          title: "",
          subtitle:
            "*Court Reported Amount means the amount reported by the court as a final judgment amount, or an amount reflecting full or partial satisfaction of such judgment and may include attorney or court fees or costs.",
          width: TooltipColumnWidth.full,
        },
      ]}
    >
      {card}
    </ApplicationSummaryTooltip>
  );
};
