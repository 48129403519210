import { useQuery } from "react-query";

import api from "shared/lib/api";

const GET_PMS_UNITS_KEY = "getPmsUnits";

export const useGetPmsUnits = ({ location, enabled }) => {
  const { data: pmsUnits, isLoading: isPmsUnitsLoading } = useQuery(
    [GET_PMS_UNITS_KEY, location],
    () => api.getPmsUnits({ location }),
    {
      enabled,
      onError: () => {},
    }
  );

  return {
    pmsUnits,
    isPmsUnitsLoading,
  };
};
