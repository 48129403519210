import styled from "@emotion/styled";

const Container = styled("div")`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  width: 100%;
`;

const Title = styled("h6")`
  margin-right: 9px;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 0;
`;

const IconWrapper = styled("span")`
  cursor: pointer;
  display: flex;
`;

export { Container, Title, IconWrapper };
