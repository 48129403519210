export const redirectToApplicantFlow = ({ match }) => {
  const redirectPath = match?.params?.id
    ? `/applicant/workflow/${match?.params?.id}`
    : "/applicant/workflow/";
  const redirectUri = `${window.location.origin}${redirectPath}`;

  if (window.location.href !== redirectUri) {
    window.location.href = redirectUri;
  } else {
    window.location.reload();
  }

  return null;
};
