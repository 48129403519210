import React, { useMemo } from "react";

import PropTypes from "prop-types";

import YardiDropdownMappingField from "manager/pages/Marketplace/integrations/yardi/shared/YardiDropdownMappingField";

import {
  SearchableList,
  CollapsibleCheckboxList,
  CheckboxListHeader,
} from "shared/components/Form";
import Icon from "shared/components/Icon";

import { stringifyVeroAgentId } from "../../ConfigureYardiProperty/section/configureYardiPropertySection.util";
import { sectionType } from "../../shared/propTypes";

import {
  Section,
  SectionTitle,
  SectionSubTitle,
  ColumnVeroAgent,
} from "./styled";

const YardiSectionAgents = ({
  section,
  loading,
  veroAgents,
  yardiAgents,
  firstColWidth,
}) => {
  const items = useMemo(
    () =>
      (veroAgents || []).map((veroAgent) => {
        return {
          key: veroAgent.id,
          label: (
            <ColumnVeroAgent>
              <div>{veroAgent.label}</div>
              <div className="subtitle">{veroAgent.email}</div>
            </ColumnVeroAgent>
          ),
          notCheckBox: true,
          description: (
            <YardiDropdownMappingField
              name={`agents[${stringifyVeroAgentId(veroAgent.id)}].yardiAgent`}
              dropdownPlaceholder="Select agent"
              options={yardiAgents || []}
              asFormField
            />
          ),
        };
      }),
    [veroAgents, yardiAgents]
  );
  return (
    <Section height="auto" noPaddingBottom>
      <SectionTitle>{section.name}</SectionTitle>
      <SectionSubTitle>
        <div>
          Each VERO agent must be mapped to an External Provider agent.
          Remember, a single External Provider agent can be linked to multiple
          VERO agents.
        </div>
      </SectionSubTitle>
      <SearchableList
        customHeaderContent={
          <CheckboxListHeader
            headerCheckboxVisible={false}
            firstColLabel="VERO"
            secondColLabel="External Provider"
          />
        }
        List={CollapsibleCheckboxList}
        listProps={{
          items,
          noSeparator: true,
          virtualize: true,
          col1Width: firstColWidth,
          virtualRowHeightEstimator: () => 90,
        }}
        headerProps={{
          firstColLabel: "VERO",
          secondColLabel: "External Provider",
        }}
        loading={loading}
        hasInputValue={false}
        placeholder={{
          label: "Agents",
          Icon: Icon.NoEmployeesIcon,
        }}
      />
    </Section>
  );
};

YardiSectionAgents.propTypes = {
  section: sectionType.isRequired,
  loading: PropTypes.bool,
  veroAgents: PropTypes.arrayOf(PropTypes.object),
  firstColWidth: PropTypes.string.isRequired,
  yardiAgents: PropTypes.arrayOf(PropTypes.object),
};

YardiSectionAgents.defaultProps = {
  loading: false,
  veroAgents: [],
  yardiAgents: [],
};

export default YardiSectionAgents;
