import styled from "@emotion/styled";

import Icon from "shared/components/Icon";

const iconStyles = {
  cursor: "pointer",
};

export const SearchIcon = styled(Icon.SearchIcon)(iconStyles);
export const SearchLgIcon = styled(Icon.SearchLgIcon)(iconStyles);
