import React from "react";

import PropTypes from "prop-types";

import MainBackground from "shared/components/Backgrounds/MainBackground";
import FlexContainer from "shared/components/FlexContainer";

import { AuthPageContent } from "./styled";

const AuthPage = ({ children }) => (
  <AuthPageContent>
    <FlexContainer flexDirection="column" alignItems="center">
      {children}
    </FlexContainer>
    <MainBackground />
  </AuthPageContent>
);

AuthPage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default AuthPage;
