import axios from "axios";

import ROUTES from "renter/config/apiRoutes";
import { API_URL } from "shared/lib/api/setupApi";
import { transformRoute } from "shared/utils/routing";

const api = {
  getPersonaData: ({ applicationId }) =>
    axios
      .get(
        `${API_URL}${transformRoute(ROUTES.personaData, {
          applicationId,
        })}`
      )
      .then(({ data }) => data),
  getPersonaTokens: ({ applicationId }) =>
    axios
      .post(
        `${API_URL}${transformRoute(ROUTES.personaTokens, {
          applicationId,
        })}`
      )
      .then(({ data }) => data),
  postSetPersonaInquiryId: ({ applicationId, personaInquiryId }) =>
    axios
      .post(
        `${API_URL}${transformRoute(ROUTES.setPersonaInquiryId, {
          applicationId,
        })}`,
        {
          personaInquiryId,
        }
      )
      .then(({ data }) => data),
  postPersonaSetSkipped: ({ applicationId }) =>
    axios
      .post(
        `${API_URL}${transformRoute(ROUTES.personaSetSkipped, {
          applicationId,
        })}`
      )
      .then(({ data }) => data),
  postPersonaSync: ({ applicationId }) =>
    axios
      .post(
        `${API_URL}${transformRoute(ROUTES.personaSync, {
          applicationId,
        })}`
      )
      .then(({ data }) => data),
};

export default api;
