import styled from "@emotion/styled";

import BaseWell from "shared/components/Well";
import { COLORS } from "shared/config/constants";

export const Well = styled(BaseWell)`
  margin: 24px 0;
  padding: 20px;
  background-color: ${COLORS.backgroundGrey2};

  & > * {
    display: block;
  }

  .applicant-name {
    color: ${COLORS.lightViolet};
  }

  .income {
    color: ${COLORS.darkViolet3};
  }
`;
