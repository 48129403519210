import _ from 'lodash'
import moment from 'moment'
import queryString from 'query-string'
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Input, DatePicker } from 'antd-latest'
import { CloseOneIcon, ArrowCircleLeftIcon } from 'shared/components/vega/icons'

export const FIELD_SEPERATOR = '.'
export const VALUE_SEPERATOR = ':'
export const DEFAULT_DATES = [moment().subtract(1, 'months').startOf('day'), moment().endOf('day')] as any

function getTermStartEnd (query, curPos = undefined) {
  const resolvedCurPos = curPos || _.size(query)

  const first = _.chain(query).split('').reverse().findIndex(v => v === ' ', _.size(query) - resolvedCurPos).value()
  const last = _.chain(query).split('').findIndex(v => v === ' ', resolvedCurPos).value()

  return [
    first < 0 ? 0 : _.size(query) - first,
    last < 0 ? _.size(query) : last
  ]
}

function getTerm (query, curPos = undefined) {
  const resolvedCurPos = curPos || _.size(query)
  const [first, last] = getTermStartEnd(query, curPos)

  return _.chain(query).slice(first, last).join('').value()
}

export function parseQuery (query, defaultIndex = null) {
  return _.chain(query).split(' ').reduce((r, q) => {
    const [index, v] = _.chain(q).split(VALUE_SEPERATOR).compact().value()

    if (_.includes(index, '.')) {
      return _.set(r, [_.endsWith(v, '*') ? `${index}.*` : index], v)
    }

    if (!v && defaultIndex) {
      return _.set(r, [`${defaultIndex}.query`], index)
    }

    return _.set(r, [`${index}.query`], v)
  }, {}).pickBy().value()
}

const KeyList = ({ dataset, onKeyClick, term, name = null, type = null }) => {
  const resolvedName = name || type

  const key = _.chain(term).split(VALUE_SEPERATOR).first().value()
  const index = _.chain(key).split(FIELD_SEPERATOR).first().value()
  const field = _.chain(key).split(FIELD_SEPERATOR).tail().join(FIELD_SEPERATOR).value()
  const value = _.chain(term).split(VALUE_SEPERATOR).tail().join(VALUE_SEPERATOR).value()

  const isIndexList = resolvedName === index

  const list = _.chain(dataset).map(type).flattenDeep().map(
    o => _.omitBy(o, (v, k) => _.isPlainObject(v) || _.isArray(v) || _.isNil(v) || _.includes(['_index', 'company'], k) || _.endsWith(k, 'At'))
  ).value()

  const keys = _.chain(list).map(_.keys).flattenDeep().uniq().filter(k => isIndexList ? (field ? _.includes(_.toLower(k), _.toLower(field)) : true) : _.includes(_.toLower(k), _.toLower(field || key))).sortBy().value()
  const values = _.chain(list).map(field || key).flattenDeep().uniq().filter(v => _.includes(_.toLower(v), _.toLower(value))).sortBy().value()

  if ((field && !isIndexList)) {
    return null
  }

  return (
    <div className='vega flex column'>
      <header
        className='vega no-padding title small button'
        onClick={() => {
          onKeyClick(`${resolvedName}${VALUE_SEPERATOR}`)
        }}
      >
        {resolvedName}
      </header>

      <div>
        {_.map(keys, k => {
          const key = _.chain([resolvedName, k]).compact().join(FIELD_SEPERATOR).value()
          const resolvedKey = `${key}${VALUE_SEPERATOR}`

          return (
            <div key={resolvedKey}>
              <div
                className='vega sub-title button key'
                onClick={() => {
                  onKeyClick(resolvedKey)
                }}
              >
                {_.startCase(k)}
              </div>

              {isIndexList && !_.isEmpty(values) && <div className='vega indent'>
                {_.map(values, v => {
                  const resolvedValue = _.includes(v, ' ') ? `"${_.trim(encodeURIComponent(v), '"')}"` : encodeURIComponent(v)
                  const resolvedKeyValue = _.chain([resolvedKey, resolvedValue]).compact().map(s => _.trim(s, VALUE_SEPERATOR)).join(VALUE_SEPERATOR).value()

                  return (
                    <div
                      key={resolvedKeyValue}
                      className='vega button key info'
                      onClick={() => {
                        onKeyClick(resolvedKeyValue)
                      }}
                    >{_.toString(v)}</div>
                  )
                })}
              </div>}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export const SearchFilter = ({ indexes, defaultSearchIndex, dataset, loading, onFilter, quicklinks = {} }) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const [query, setQuery] = useState(() => _.chain(queryString.parse(window.location.search)).reduce((r, v, k) => _.concat(r, `${k}:${v}`), []).join(' ').value())
  const [dates, setDates] = useState(DEFAULT_DATES)
  const [term, setTerm] = useState(null)
  const [curPos, setCurPos] = useState(0)

  const history = useHistory()

  const [rootIndex, ...otherIndexes] = indexes

  const updateQueryParams = q => history.replace(
    q ? `${window.location.pathname}?${queryString.stringify(parseQuery(q, defaultSearchIndex || _.first(indexes)))}` : window.location.pathname
  )

  const getTermFromEvent = (e) => {
    return getTerm(e.target.value, e.target.selectionStart)
  }

  const setTermFromEvent = (e) => {
    const t = getTermFromEvent(e)

    setTerm(t)
    setCurPos(e.target.selectionStart)

    return t
  }

  const onKeyClick = newTerm => {
    const [first, last] = getTermStartEnd(query, curPos)
    const resolvedTerm = term || getTerm(query, curPos)

    const q = _.chain([query?.slice(0, first), query?.slice(first, last).replace(resolvedTerm, newTerm), query?.slice(last)]).compact().join('').value() || newTerm

    const newPos = getTermStartEnd(q, first + _.size(newTerm))[1]

    setQuery(q)
    updateQueryParams(q)
    setCurPos(newPos)
    setTerm(getTerm(q, newPos))

    if (!_.endsWith(newTerm, VALUE_SEPERATOR)) {
      setShowDropdown(false)
      onFilter({ query: q, dates })
    }
  }

  useEffect(() => {
    onFilter({ query, dates })
  }, [])

  return (
    <div className='vega content grid filter-bar'>
      <div className='vega filter search'>
        <Input.Search
          allowClear
          value={query}
          loading={loading}
          onChange={e => {
            const t = setTermFromEvent(e)
            const q = e.target.value

            setShowDropdown(!_.isEmpty(q))
            setQuery(q)

            if (_.endsWith(t, VALUE_SEPERATOR) || _.endsWith(t, FIELD_SEPERATOR)) {
              onFilter({ query: q, dates })
            }
          }}
          onSearch={(q) => {
            setQuery(q)
            updateQueryParams(q)
            setShowDropdown(false)
            onFilter({ query: q, dates })
          }}
          onClick={(e: any) => {
            setTermFromEvent(e)

            if (_.isEmpty(query)) {
              setShowDropdown(true)
            }
          }}
          onFocus={e => {
            setTermFromEvent(e)
            setShowDropdown(true)
          }}
          onKeyDown={(e: any) => {
            if (e.key === 'Escape') {
              setShowDropdown(false)
              e.target.blur()
            }
          }}
        />

        {showDropdown && <div className='vega dropdown-container'>
          <div className='vega dropdown'>
            <div className='vega container actions spread sticky top'>
              <div className='vega flex gap small'>
                {_.map(quicklinks, (v, k) => <div className='vega button badge' onClick={() => onKeyClick(v)} key={k}>{k}</div>)}
              </div>

              <div className='vega flex gap small'>
                {term && <ArrowCircleLeftIcon className='vega icon button' onClick={() => setTerm(null)} />}
                <CloseOneIcon className='vega icon button' onClick={() => setShowDropdown(false)} />
              </div>
            </div>

            <div className='vega content flex'>
              <KeyList
                dataset={dataset}
                name={rootIndex}
                onKeyClick={onKeyClick}
                term={term}
                key={rootIndex}
              />

              {_.chain(otherIndexes).map(index => _.chain(index).split(FIELD_SEPERATOR).first().value()).sortBy().map(
                index => (
                  <KeyList
                    dataset={dataset}
                    type={index}
                    onKeyClick={onKeyClick}
                    term={term}
                    key={index}
                  />
                )
              ).value()}
            </div>
          </div>
        </div>}
      </div>

      <div className='vega filter date'>
        <DatePicker.RangePicker
          allowClear={false}
          showTime
          value={dates}
          disabled={loading}
          ranges={{
            Today: [moment().startOf('day'), moment().endOf('day')],
            'Last Hour': [moment().subtract(1, 'hours'), moment()],
            'This Week': [moment().startOf('week'), moment().endOf('week')],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'This Year': [moment().startOf('year'), moment().endOf('year')]
          }}
          onCalendarChange={d => {
            setDates(d)
            onFilter({ query, dates: d })
          }}
        />
      </div>
    </div>
  )
}
