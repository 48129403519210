import styled from '@emotion/styled'

import { CreditScoreSummary } from 'shared/components/CreditAndBackgroundCheck/interfaces'
import Icon from 'shared/components/Icon'
import Tile from 'shared/components/Tile'
import { formatDate } from 'shared/utils/ui'

import { colors } from 'shared/config/constants'

import { isManagerApp } from 'shared/utils/auth'


import {
  ExpiredContainer,
  ExpiredRow,
  ExpiredTitle,
  ExpiredParagraph,
} from './ExpiredReport.styles'

// icon

export const LockClosedIcon = styled(Icon.LockClosed)`
  display: block;
  width: 29px;
  height: 29px;
  padding: 5px;
  color: ${colors.warning[500]};
  stroke: ${colors.warning[500]};
`

type ExpirationInfoProps = {
  creditScoreSummary?: CreditScoreSummary
}

const N_A = 'N/A'

const ExpiredInfo = ({ creditScoreSummary }: ExpirationInfoProps) => {
  const expirationMessage = isManagerApp()
    ? 'The applicant’s background and credit report have expired. Reports expire after 30 days.'
    : 'Your background and credit report have expired. Reports expire after 30 days.'

  const expirationDetails = isManagerApp()
    ? [
        'The background and credit check step is marked as incomplete.',
        'The applicant needs to edit their application and complete the outstanding task to generate a new report.',
      ]
    : [
        'The background and credit check step is incomplete.',
        'Please edit your application and complete the outstanding task to generate a new report',
      ]

  const creationDate = creditScoreSummary?.creationDate
    ? formatDate(creditScoreSummary?.creationDate)
    : N_A
  const expirationDate = creditScoreSummary?.expirationDate
    ? formatDate(creditScoreSummary?.expirationDate)
    : N_A

  return (
    <>
      <Tile>
        <Tile.Inner borderBottom>
          <ExpiredContainer>
            <ExpiredRow>
              <LockClosedIcon />
              <ExpiredTitle>Credit & Background Report Expired</ExpiredTitle>
            </ExpiredRow>
            <ExpiredRow>
              <ExpiredParagraph>{expirationMessage}</ExpiredParagraph>
            </ExpiredRow>
            <ExpiredParagraph>
              <ul style={{ listStyleType: 'disc' }}>
                {expirationDetails.map((detail) => (
                  <li style={{ margin: 8 }} key={detail}>
                    {detail}
                  </li>
                ))}
              </ul>
            </ExpiredParagraph>
            <ExpiredParagraph>
              <div style={{ margin: 8 }}>
                Report Creation Date: {creationDate}
              </div>
              <div style={{ margin: 8 }}>
                Report Expiration Date: {expirationDate}
              </div>
            </ExpiredParagraph>
          </ExpiredContainer>
        </Tile.Inner>
      </Tile>
    </>
  )
}

export default ExpiredInfo
