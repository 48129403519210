/**
 * React utility to merge to refs.
 * Useful in situation where we want the same value "assigned" to more than 1 ref
 *
 * @param inputRefs the list of refs
 * @returns {(function(*=): void)|*} the merged ref
 */
export const mergeRefs = (...inputRefs) => {
  const filteredInputRefs = inputRefs.filter((ref) => !!ref);

  if (filteredInputRefs.length <= 1) {
    return filteredInputRefs[0];
  }

  return (ref) => {
    filteredInputRefs.forEach((inputRef) => {
      if (typeof inputRef === "function") {
        inputRef(ref);
      } else {
        // eslint-disable-next-line no-param-reassign
        inputRef.current = ref;
      }
    });
  };
};
