import { useEffect, useState } from "react";

import useForceUpdate from "./useForceUpdate";

const SCROLL_OFFSET = -100;

const useShowScrollToTopButton = (nodeRef: HTMLDivElement) => {
  const [scrollOffset, setScrollOfset] = useState(0);
  const showScrollToTopButton: boolean = scrollOffset <= SCROLL_OFFSET;
  const { pathname } = window.location;
  const { forceUpdate } = useForceUpdate();
  useEffect(() => {
    forceUpdate();
    const handleScroll = () =>
      setScrollOfset(nodeRef?.firstElementChild?.getBoundingClientRect()?.top);
    nodeRef?.addEventListener("scroll", handleScroll);
    return () => nodeRef?.removeEventListener("scroll", handleScroll);
  }, [pathname, nodeRef]);

  return showScrollToTopButton;
};
export default useShowScrollToTopButton;
