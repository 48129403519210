const SCROLL_REGEX = /(auto|scroll)/;

const SCROLL_DIRECTION = Object.freeze({
  BOTH: "BOTH",
  Y: "Y",
  X: "X",
});

const DEFAULT_OBSERVER_CONFIG = Object.freeze({
  childList: true,
  subtree: true,
});

const getParents = (node, ps) => {
  if (node.parentNode === null) {
    return ps;
  }

  return getParents(node.parentNode, ps.concat([node]));
};

const getStyle = (node, prop) => {
  return getComputedStyle(node, null).getPropertyValue(prop);
};

const getOverflowStyles = (node, scrollDirection = SCROLL_DIRECTION.BOTH) => {
  let overflow = getStyle(node, "overflow");
  if (
    scrollDirection === SCROLL_DIRECTION.BOTH ||
    scrollDirection === SCROLL_DIRECTION.Y
  ) {
    overflow += getStyle(node, "overflow-y");
  }
  if (
    scrollDirection === SCROLL_DIRECTION.BOTH ||
    scrollDirection === SCROLL_DIRECTION.X
  ) {
    overflow += getStyle(node, "overflow-x");
  }
  return overflow;
};

const isScrollable = (node, scrollDirection) =>
  SCROLL_REGEX.test(getOverflowStyles(node, scrollDirection));

const getScrollableParent = (node, scrollDirection) => {
  if (!(node instanceof HTMLElement || node instanceof SVGElement)) {
    return undefined;
  }

  const parents = getParents(node.parentNode, []);

  for (let i = 0; i < parents.length; i += 1) {
    if (isScrollable(parents[i], scrollDirection)) {
      return parents[i];
    }
  }

  return document.scrollingElement || document.documentElement;
};

const parseOrZero = (value) => parseInt(value, 10) || 0;

/**
 * Gets the outer width of the provided element.
 * The outer width contains: offset width, margins, borders.
 *
 * NOTE: providing a unit test is a bit difficult because the offsetWidth in the tests is always 0.
 *
 * @param elm the element
 * @returns the outer width (number)
 */
const getOuterWidth = (elm) => {
  let width = 0;

  if (elm) {
    const computedStyle = window.getComputedStyle(elm);
    width =
      elm.offsetWidth +
      parseOrZero(computedStyle.marginLeft) +
      parseOrZero(computedStyle.marginRight) +
      parseOrZero(computedStyle.borderLeftWidth) +
      parseOrZero(computedStyle.borderRightWidth);
  }

  return width;
};

/**
 * Registers listeners to changes in the children of "elm" (DOM element).
 * The return value is the observer that has to be cleaned up using `observer.disconnect()`.
 * @param elm the element to listen to
 * @param callback the listener of the changes
 * @returns {MutationObserver} the observer
 */
const listenToChildrenChange = (elm, callback) => {
  const observer = new MutationObserver(callback);
  observer.observe(elm, DEFAULT_OBSERVER_CONFIG);
  return observer;
};

/** Creates an html event-like object
 *  @param {string} name - control name
 *  @param {any} value - control value
 *  @returns {Object} event-like object
 */
const createEventLikeObject = (name, value) => ({
  target: { name, value },
});

export {
  getScrollableParent,
  getOuterWidth,
  listenToChildrenChange,
  SCROLL_DIRECTION,
  createEventLikeObject,
};
