import React, { useState, useEffect } from "react";

import { useFormikContext } from "formik";

import { FIELD_TYPES } from "admin/config/constants";
import {
  useFieldCategoriesByType,
  useFieldProperties,
  useFieldTypes,
} from "admin/hooks/api";

import {
  CHECKBOX_GROUP_SIGNATURE_TYPE,
  CHECKBOX_GROUP_SIGNATURE_VALUES,
  SIGNATURE_CATEGORY,
} from "../config";

import CreateFieldForm from "./CreateFieldForm";
import { AddOccupantsModalDataFormikProps } from "./interfaces";

const CreateFieldFormContainer = () => {
  const [formattersToDisplay, setFormattersToDisplay] = useState([]);
  const { values, setFieldValue, setValues }: AddOccupantsModalDataFormikProps =
    useFormikContext();
  const isGeneralInfoCompleted =
    Boolean(values.type) && Boolean(values.category) && Boolean(values.name);
  const isManualField =
    isGeneralInfoCompleted && values.type === FIELD_TYPES.manual;
  const isSmartField =
    isGeneralInfoCompleted && values.type === FIELD_TYPES.smart;
  const isCheckboxField =
    isGeneralInfoCompleted && values.type === FIELD_TYPES.checkbox;
  const isCheckboxGroupSignature =
    values.type === CHECKBOX_GROUP_SIGNATURE_TYPE.id;

  const { isCategoriesLoading, categories = {} } = useFieldCategoriesByType();
  const { isLoading: isTypesLoading, data: types = [] } = useFieldTypes();
  const { isLoading: isPropertiesLoading, data: properties } =
    useFieldProperties(
      { category: values.category },
      {
        enabled: isGeneralInfoCompleted && !isCheckboxGroupSignature,
      }
    );

  const onChangeType = (value) => {
    setValues({
      ...values,
      type: value,
      sample: "",
      description: "",
      category: "",
      smartSource: {},
      checkboxSignature:
        value === CHECKBOX_GROUP_SIGNATURE_TYPE.id
          ? { ...CHECKBOX_GROUP_SIGNATURE_VALUES }
          : null,
    });
  };
  const onChangeCategory = (value) => {
    setValues({
      ...values,
      category: value,
      smartSource: {},
    });
  };
  const onChangeProperty = (value) => {
    setFieldValue("smartSource", {
      ...properties.find((property) => property.source.attribute === value)
        ?.source,
    });
  };
  const onChangeFormatter = (value) => {
    setFieldValue("smartSource", { ...values.smartSource, format: value });
  };

  // filter formatters by selected 'Data Mapping' field type
  useEffect(() => {
    const selectedProperty = properties?.find(
      (property) => property.name === values.smartSource?.attribute
    );

    setFormattersToDisplay(selectedProperty?.formatters || []);
  }, [values.smartSource?.attribute]);

  // automatically set formatter after "Data Mapping" select
  useEffect(() => {
    setFieldValue("smartSource", {
      ...values.smartSource,
      format: formattersToDisplay[0]?.name,
    });
  }, [formattersToDisplay]);

  return (
    <CreateFieldForm
      isTypesLoading={isTypesLoading}
      isCategoriesLoading={isCategoriesLoading}
      isPropertiesLoading={isPropertiesLoading}
      types={[...types, CHECKBOX_GROUP_SIGNATURE_TYPE]}
      categories={
        isCheckboxGroupSignature
          ? [SIGNATURE_CATEGORY]
          : categories[values.type?.toLowerCase()]
      }
      properties={properties}
      formatters={formattersToDisplay}
      isManualField={isManualField}
      isSmartField={isSmartField}
      isCheckboxField={isCheckboxField}
      isCheckboxGroupSignature={isCheckboxGroupSignature}
      onChangeType={onChangeType}
      onChangeCategory={onChangeCategory}
      onChangeFormatter={onChangeFormatter}
      onChangeProperty={onChangeProperty}
    />
  );
};

export default CreateFieldFormContainer;
