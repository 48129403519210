import { useMemo } from "react";

import keyBy from "lodash/keyBy";
import pluralize from "pluralize";
import PropTypes from "prop-types";
import { printDollarsFromCents } from "shared/utils/dollar-print";

import {
  LUMEN_FILTER_CONDITION_AMOUNT_TIME_OPERATOR_TYPE,
  LUMEN_FILTER_CONDITION_SUB_TYPE,
  LUMEN_FILTER_CONDITION_TYPE,
  LUMEN_FILTER_CONDITION_TYPE_LABELS,
  LUMEN_FILTER_LABELS,
  LUMEN_FILTER_TYPE,
  LUMEN_SECTION_TYPE,
  NEW_LUMEN_FILTERS,
} from "manager/config/lumen.config";
import {
  useGetCreditPublicRecordBankruptcyOptions,
  useGetCreditPublicRecordOptions,
  useGetCriminalRecordSeverityOptions,
} from "manager/hooks/api";
import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import { SmallText } from "shared/components/Typography";
import { formatDate } from "shared/utils/ui";

import { LUMEN_CONDITION_LABELS } from "../NewCollectionsFilterModal/utils";

import { LumenFilterRowContent } from "./styled";

const LumenFilterRow = ({
  type,
  conditions,
  lumenSection,
  onEdit,
  onDelete,
}) => {
  const { creditPublicRecordOptions } = useGetCreditPublicRecordOptions();
  const { creditPublicRecordBankruptcyOptions } =
    useGetCreditPublicRecordBankruptcyOptions();
  const { criminalRecordSeverityOptions } =
    useGetCriminalRecordSeverityOptions();

  const creditPublicRecordMap = useMemo(
    () =>
      keyBy(
        [
          ...(creditPublicRecordOptions || []),
          ...(creditPublicRecordBankruptcyOptions || []),
        ],
        (o) => o.id
      ),
    [criminalRecordSeverityOptions, creditPublicRecordBankruptcyOptions]
  );

  const criminalRecordSeverityMap = useMemo(
    () => keyBy([...(criminalRecordSeverityOptions || [])], (o) => o.id),
    [criminalRecordSeverityOptions]
  );

  const conditionToLabel = (condition) => {
    if (LUMEN_FILTER_CONDITION_TYPE.TIME_PERIOD === condition.conditionType) {
      if (
        condition.timeOperator ===
        LUMEN_FILTER_CONDITION_AMOUNT_TIME_OPERATOR_TYPE.BETWEEN
      ) {
        return `between ${formatDate(condition.startDate)} and ${formatDate(
          condition.endDate
        )}`;
      }
      const operator =
        condition.timeOperator ===
        LUMEN_FILTER_CONDITION_AMOUNT_TIME_OPERATOR_TYPE.MORE_THAN
          ? "after"
          : "within";
      const value = condition.timeValue;
      const unit = pluralize(condition?.timeUnit.toLowerCase(), value);
      return `${operator} ${value} ${unit}`;
    }

    if (
      LUMEN_FILTER_CONDITION_TYPE.AMOUNT === condition.conditionType ||
      LUMEN_FILTER_CONDITION_TYPE.JUDGEMENT_AMOUNT === condition.conditionType
    ) {
      const operator =
        condition.amountOperator ===
        LUMEN_FILTER_CONDITION_AMOUNT_TIME_OPERATOR_TYPE.MORE_THAN
          ? "more than"
          : "less than";
      const value = printDollarsFromCents(condition.amountValue);
      return `${operator} ${value}`;
    }

    if (
      LUMEN_FILTER_CONDITION_TYPE.NUMBER_OF_RECORDS === condition.conditionType
    ) {
      const operator =
        condition.numOfRecordsOperator ===
        LUMEN_FILTER_CONDITION_AMOUNT_TIME_OPERATOR_TYPE.MORE_THAN
          ? "more than"
          : "less than";
      return `${operator} ${condition.numOfRecordsValue}`;
    }

    if (LUMEN_FILTER_CONDITION_TYPE.KEYWORDS === condition.conditionType) {
      if (
        LUMEN_FILTER_CONDITION_SUB_TYPE.EVICTIONS === condition.conditionSubType
      ) {
        return [
          ...(condition.keywords || []),
          ...(condition.otherKeywords || []),
        ].join(", ");
      }
      return (condition.keywords || []).join(", ");
    }

    if (
      LUMEN_FILTER_CONDITION_TYPE.PUBLIC_RECORD_TYPE === condition.conditionType
    ) {
      return creditPublicRecordMap[condition.subtype]?.value;
    }

    if (
      LUMEN_FILTER_CONDITION_TYPE.CRIMINAL_RECORD_SEVERITY ===
      condition.conditionType
    ) {
      return criminalRecordSeverityMap[condition.severity]?.value;
    }

    return "";
  };

  return (
    <LumenFilterRowContent>
      <FlexContainer>
        {!Object.hasOwn(NEW_LUMEN_FILTERS, lumenSection) && (
          <div className="filter-row-section">
            <SmallText light className="filter-row-section-title">
              TYPE
            </SmallText>
            <span>{LUMEN_FILTER_LABELS[type]}</span>
          </div>
        )}
        {conditions.map((condition) => (
          <div className="filter-row-section" key={condition.conditionType}>
            <SmallText light className="filter-row-section-title">
              {LUMEN_FILTER_CONDITION_TYPE_LABELS[condition.conditionType]}{" "}
              {condition.conditionSubType &&
                LUMEN_CONDITION_LABELS[condition.conditionSubType] &&
                `- ${LUMEN_CONDITION_LABELS[
                  condition.conditionSubType
                ].toUpperCase()}`}
            </SmallText>
            <span>{conditionToLabel(condition)}</span>
          </div>
        ))}
      </FlexContainer>
      <FlexContainer alignItems="center">
        <Icon.EditGreenIcon className="action-icon" onClick={onEdit} />
        <Icon.DeleteIcon className="action-icon" onClick={onDelete} />
      </FlexContainer>
    </LumenFilterRowContent>
  );
};

LumenFilterRow.propTypes = {
  type: PropTypes.oneOf(Object.values(LUMEN_FILTER_TYPE)).isRequired,
  lumenSection: PropTypes.oneOf(Object.values(LUMEN_SECTION_TYPE)),
  conditions: PropTypes.arrayOf(PropTypes.object),
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

LumenFilterRow.defaultProps = {
  conditions: [],
};

export default LumenFilterRow;
