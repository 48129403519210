import React from "react";

import {
  Button as StyledButton,
  Inner,
  PrefixIconContainer,
  SuffixIconContainer,
  Children,
  LoadingSpinner,
} from "./styled";
import { SIZES } from "./utils";

export interface ButtonProps {
  children?: React.ReactNode;
  plus?: boolean;
  PrefixIcon?: any;
  SuffixIcon?: any;
  BorderedSuffixIcon?: any;
  size?: string;
  loading?: boolean;
  disabled?: boolean;
  type?: any;
  [key: string]: any;
}

const Button = ({
  children,
  plus = false,
  PrefixIcon = undefined,
  SuffixIcon = undefined,
  BorderedSuffixIcon = undefined,
  size = SIZES.default,
  loading = false,
  disabled = false,
  ...props
}: ButtonProps) => (
  <StyledButton data-testid="button" {...props} disabled={disabled || loading}>
    <Inner className="button-inner" size={size}>
      {PrefixIcon && (
        <PrefixIconContainer hide={loading} data-testid="prefix-icon">
          <PrefixIcon />
        </PrefixIconContainer>
      )}
      {plus && "+ "}
      <Children className="button-children" loading={loading}>
        {children}
      </Children>
      {loading && <LoadingSpinner data-testid="loading-spinner" />}
      {SuffixIcon && (
        <SuffixIconContainer hide={loading} data-testid="suffix-icon">
          <SuffixIcon />
        </SuffixIconContainer>
      )}
    </Inner>
    {BorderedSuffixIcon && (
      <Inner
        borderLeft
        size={size}
        hide={loading}
        data-testid="bordered-suffix-icon"
      >
        <BorderedSuffixIcon />
      </Inner>
    )}
  </StyledButton>
);

export default Button;
