import React from "react";

import Icon from "shared/components/Icon";
import Logo from "shared/components/Logo";
import { UserProfilePopover } from "shared/components/User";

import { BasicContainer, IconContainer } from "../styled";

export interface IBasicHeader {
  user?: any;
  example?: boolean;
}

const BasicHeader = ({ user, example }: IBasicHeader) => (
  <BasicContainer example={example}>
    <Logo data-testid="logo" />
    {user && (
      <UserProfilePopover user={user}>
        <IconContainer data-testid="user-profile">
          <Icon.HoverTracker Icon={Icon.ProfileIconWithChevron} />
        </IconContainer>
      </UserProfilePopover>
    )}
  </BasicContainer>
);

export default BasicHeader;
