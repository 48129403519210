import React from "react";

import { Modal } from "shared/components/Modal";

import AssignedDocuments from "./AssignedDocuments";
import { AssignDocumentSuccessModalProps } from "./interfaces";
import NoActionDocuments from "./NoActionDocuments";
import { ModalBody } from "./styled";

const AssignDocumentSuccessModal = ({
  documents,
  company,
}: AssignDocumentSuccessModalProps) => {
  const documentsToAssign = documents?.map((document) => document.name);

  const assignedDocuments = documentsToAssign.filter(
    (documentName) => !company.documentsAssigned.includes(documentName)
  );
  const noActionDocuments = documentsToAssign.filter((documentName) =>
    company.documentsAssigned.includes(documentName)
  );

  return (
    <Modal
      submitButtonLabel="GOT IT"
      closeOnSubmit
      showCancelLink={false}
      width="auto"
      style={{ maxWidth: 655 }}
    >
      <ModalBody>
        {!!assignedDocuments.length && (
          <AssignedDocuments
            companyName={company?.name}
            documentNames={assignedDocuments}
          />
        )}

        {!!noActionDocuments.length && (
          <NoActionDocuments
            companyName={company?.name}
            documentNames={noActionDocuments}
          />
        )}
      </ModalBody>
    </Modal>
  );
};

export default AssignDocumentSuccessModal;
