import React from "react";

import Icon from "shared/components/Icon";
import {
  DEAL_UI_STATUS_CODES,
  DEAL_UI_STATUS_LABEL,
} from "shared/config/constants";

import DealPropTypes from "../../Deals.propTypes";
import { ProgressBar, PrimaryText, FlexWrapper } from "../styled";

const DealStatus = ({ deal, dealStatuses }) => {
  const numCompleteApplications = deal.applicantsSummary.filter(
    (applicant) => applicant.isSubmitted
  ).length;
  const numTotalApplications = deal.applicantsSummary.length;
  const isDealCompleted = deal.progressPercent === 100;

  const getDealStatusText = (code) =>
    DEAL_UI_STATUS_LABEL[code] ||
    dealStatuses.find(({ id }) => id === code)?.value ||
    "Unknown";

  let tag;

  switch (deal.dealUiStatus) {
    case DEAL_UI_STATUS_CODES.partiallySubmitted:
      tag = (
        <PrimaryText>
          {DEAL_UI_STATUS_LABEL[deal.dealUiStatus](
            numCompleteApplications,
            numTotalApplications
          )}
        </PrimaryText>
      );
      break;
    case DEAL_UI_STATUS_CODES.invitesSent:
    case DEAL_UI_STATUS_CODES.applicationsIncomplete:
    case DEAL_UI_STATUS_CODES.submitted:
    case DEAL_UI_STATUS_CODES.condApproved:
      tag = (
        <PrimaryText>{DEAL_UI_STATUS_LABEL[deal.dealUiStatus]}</PrimaryText>
      );
      break;
    case DEAL_UI_STATUS_CODES.approved:
      tag = (
        <FlexWrapper>
          {isDealCompleted && (
            <Icon.DealApprovedIcon data-testid="approved-icon" />
          )}
          <PrimaryText>{DEAL_UI_STATUS_LABEL[deal.dealUiStatus]}</PrimaryText>
        </FlexWrapper>
      );
      break;

    case DEAL_UI_STATUS_CODES.cancelled:
    case DEAL_UI_STATUS_CODES.declined:
    case DEAL_UI_STATUS_CODES.listingClosed:
      tag = (
        <FlexWrapper>
          <Icon.DealDeclinedIcon data-testid="decline-icon" />
          <PrimaryText>{DEAL_UI_STATUS_LABEL[deal.dealUiStatus]}</PrimaryText>
        </FlexWrapper>
      );
      break;

    default:
      tag = <PrimaryText>{getDealStatusText(deal.dealUiStatus)}</PrimaryText>;
  }
  return (
    <div data-testid="deal-status">
      {tag}
      {!isDealCompleted && <ProgressBar progress={deal.progressPercent} />}
    </div>
  );
};

DealStatus.propTypes = {
  deal: DealPropTypes.deal.isRequired,
  dealStatuses: DealPropTypes.dealStatuses,
};

DealStatus.defaultProps = {
  dealStatuses: [],
};

export default DealStatus;
