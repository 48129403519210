import styled from "@emotion/styled";

import Icon from "shared/components/Icon";
import { MEDIA_QUERIES } from "shared/config/constants";

import { Wall } from "shared/templates/PortalLayout";

const PortalWall = styled(Wall)`
  width: 976px;
  max-height: calc(100vh - 240px);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 600px;

  ${MEDIA_QUERIES.w.ltLg} {
    width: 95%;
  }

  ${MEDIA_QUERIES.w.ltMd} {
    height: calc(100vh - 190px);
  }
`;

const TilesGrid = styled.section`
  display: flex;
  flex-wrap: wrap;
  padding: 0 16px 0 32px;
  flex-grow: 1;
  align-content: flex-start;

  ${MEDIA_QUERIES.w.ltMd} {
    display: block;
    padding: 0 24px;
  }
`;

const Loader = styled(Icon.LoadingSpinnerGreenLargeIcon)`
  position: absolute;
  top: calc(50% - 35px);
  left: calc(50% - 35px);
`;

export { PortalWall, Loader, TilesGrid };
