import styled from "@emotion/styled";

import { COLORS } from "shared/config/constants";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  font-weight: 300;
  color: ${COLORS.typography.text};
  padding: 50px 0;
`;

export { Container };
