import styled from "@emotion/styled";

import BaseTile from "shared/components/Tile";
import { COLORS } from "shared/config/constants";

const Tile = styled(BaseTile)`
  height: 100%;

  .tile-header {
    height: 85px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${COLORS.tile.alt.innerBackground};

    .title {
      color: ${COLORS.white};
      display: block;
    }

    .subtitle {
      color: ${COLORS.midGray};
    }

    .tooltip-icon {
      width: 28px;
      height: 28px;
    }
  }

  .tile-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

const TileInner = BaseTile.Inner;

export { Tile, TileInner };
