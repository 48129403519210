import React from "react";

import Icon from "shared/components/Icon";

import { LoadingContainer } from "./styled";

type LargeLoaderProps = {
  showLoader: boolean;
  absoluteCenter?: boolean;
};
const LargeLoader = ({
  showLoader,
  absoluteCenter = false,
}: LargeLoaderProps) => {
  return (
    showLoader && (
      <LoadingContainer absoluteCenter={absoluteCenter}>
        <Icon.LoadingSpinnerGreenLargeIcon />
      </LoadingContainer>
    )
  );
};

export default LargeLoader;
