import React from "react";

import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";

import PropertyTile from "renter/components/MagicPortal/PropertyTile";
import FooterBar from "shared/components/FooterBar";
import { Search } from "shared/components/Form";
import { MobileSearchHeader } from "shared/components/Header";
import PaginationControls from "shared/components/PaginationControls";
import { MediumTitle } from "shared/components/Typography";
import { BREAKPOINT_RESOLUTIONS } from "shared/config/constants";
import PortalLayout from "shared/templates/PortalLayout";

import { Loader } from "../styled";

import {
  PropertiesWall,
  PropertiesTilesGrid,
  MobilePropertiesWall,
} from "./styled";

const Properties = ({
  properties,
  total,
  pagination,
  onSearch,
  onChange,
  isPropertiesLoading,
  companyHashId,
  company,
  companyUuid,
  isPrescreeningMagicLinksV2,
  isMagicLinksV2,
}) => {
  const {
    name: companyName,
    logo,
    preventInvitesToTheApplicationWithoutUnit,
  } = company;
  const { page, pageSize } = pagination;
  const title = companyName ? `${companyName} Portal` : "VERO";
  const isDesktop = useMediaQuery({ minWidth: BREAKPOINT_RESOLUTIONS.md });

  const PropertiesPortalDesktop = (
    <PortalLayout logoUrl={logo}>
      <PropertiesWall>
        <section className="header">
          <MediumTitle strong className="title">
            Select Your Future Home
          </MediumTitle>
          <Search
            label="Search by property or address"
            id="search"
            debounce
            debounceTimeout={1000}
            onSearch={onSearch}
          />
        </section>
        <PropertiesTilesGrid>
          {isPropertiesLoading && <Loader data-testid="loader" />}
          {properties.map((item) => (
            <PropertyTile
              {...item}
              key={item.name}
              companyHashId={companyHashId}
              preventPropertiesWithoutUnit={
                preventInvitesToTheApplicationWithoutUnit
              }
              isPrescreeningMagicLinksV2={isPrescreeningMagicLinksV2}
              isMagicLinksV2={isMagicLinksV2}
              companyUuid={companyUuid}
            />
          ))}
        </PropertiesTilesGrid>
        <section className="footer">
          <PaginationControls
            current={page}
            total={total}
            pageSize={pageSize}
            data-testid="pagination-controls"
            onChange={onChange}
          />
        </section>
      </PropertiesWall>
    </PortalLayout>
  );

  const PropertiesPortalMobile = (
    <MobilePropertiesWall>
      <MobileSearchHeader
        label="Search by property or address"
        id="search"
        debounce
        debounceTimeout={1000}
        onSearch={onSearch}
      />
      <section className="main-content">
        {logo && <img src={logo} alt="Logo" className="logo" />}
        <MediumTitle strong className="title">
          Select Your Future Home
        </MediumTitle>
        <PropertiesTilesGrid>
          {isPropertiesLoading && <Loader />}
          {properties.map((item) => (
            <PropertyTile
              {...item}
              key={item.hashedId}
              className="tile"
              companyHashId={companyHashId}
              preventPropertiesWithoutUnit={
                preventInvitesToTheApplicationWithoutUnit
              }
              isPrescreeningMagicLinksV2={isPrescreeningMagicLinksV2}
              isMagicLinksV2={isMagicLinksV2}
              companyUuid={companyUuid}
            />
          ))}
        </PropertiesTilesGrid>
      </section>
      <FooterBar>
        <PaginationControls
          current={page}
          total={total}
          pageSize={pageSize}
          onChange={onChange}
          className="pagination-controls"
          data-testid="pagination-controls"
          size="small"
        />
      </FooterBar>
    </MobilePropertiesWall>
  );

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {isDesktop ? PropertiesPortalDesktop : PropertiesPortalMobile}
    </>
  );
};

Properties.propTypes = {
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      address: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.string,
      zipcode: PropTypes.string,
      startingPrice: PropTypes.number,
      hasUnits: PropTypes.bool.isRequired,
      logoUrl: PropTypes.string,
      hashedId: PropTypes.string.isRequired,
      uuid: PropTypes.string.isRequired,
    })
  ),
  total: PropTypes.number,
  pagination: PropTypes.shape({
    page: PropTypes.number,
    pageSize: PropTypes.number,
  }).isRequired,
  onSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onApplyNowClick: PropTypes.func.isRequired,
  isPropertiesLoading: PropTypes.bool.isRequired,
  companyHashId: PropTypes.string.isRequired,
  company: PropTypes.shape({
    name: PropTypes.string,
    logo: PropTypes.string,
    preventInvitesToTheApplicationWithoutUnit: PropTypes.bool,
  }),
  companyUuid: PropTypes.string,
  isPrescreeningMagicLinksV2: PropTypes.bool,
  isMagicLinksV2: PropTypes.bool,
};

Properties.defaultProps = {
  properties: [],
  total: 1,
  company: {
    name: "",
    logo: "",
    preventInvitesToTheApplicationWithoutUnit: false,
  },
  companyUuid: undefined,
};

export default Properties;
