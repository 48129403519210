import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { Menu } from "antd";

import FlexContainer from "shared/components/FlexContainer";
import { MEDIA_QUERIES } from "shared/config/constants";

const DropdownLink = styled(FlexContainer)`
  cursor: pointer;
`;

interface MenuItemProps {
  color?: string;
  ltMdOnly?: boolean;
  disabled?: boolean;
}

const MenuItem = styled(Menu.Item)<MenuItemProps>`
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
  ${({ ltMdOnly }) =>
    ltMdOnly &&
    css`
      ${MEDIA_QUERIES.w.gteMd} {
        display: none;
      }
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      color: #787395 !important;
    `}
`;

export { DropdownLink, MenuItem };
