import React from "react";

import { Formik } from "formik";

import { Modal } from "shared/components/Modal";

import { initValues, validationSchema } from "./config";
import CreateFieldForm from "./CreateFieldForm";
import { CreateFieldModalProps } from "./interfaces";

const CreateFieldModal = ({ formikRef, onSubmit }: CreateFieldModalProps) => (
  <Formik
    initialValues={initValues}
    onSubmit={onSubmit}
    validationSchema={validationSchema}
    validateOnBlur={false}
    innerRef={formikRef}
  >
    {({ isValid, dirty, submitForm, isSubmitting }) => (
      <Modal
        title="Create new field"
        submitButtonLabel="Create field"
        submitButtonDisabled={!isValid || !dirty}
        submit={submitForm}
        submitting={isSubmitting}
        closeOnSubmit={false}
      >
        <Modal.Body>
          <CreateFieldForm />
        </Modal.Body>
      </Modal>
    )}
  </Formik>
);

export default CreateFieldModal;
