import { createModalContext } from "shared/components/vero-modal";

export type OnMovedApplicant = {
  dealId: string;
};
export const {
  Context: MoveApplicantModalContext,
  Provider: MoveApplicantModalProvider,
  useModalContext: useMoveApplicantModal,
} = createModalContext<OnMovedApplicant>();
