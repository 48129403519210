import styled from "@emotion/styled";

import FlexContainer from "shared/components/FlexContainer";
import { COLORS } from "shared/config/constants";

import { commonStyles } from "../TextArea/styled";

// eslint-disable-next-line vero/styled-tagged-template
const BaseTextArea = styled.textarea(commonStyles);

export const StyledTextArea = styled(BaseTextArea)`
  width: 100%;
`;

export const StyledToolbar = styled(FlexContainer)`
  align-items: center;
  padding-bottom: 8px;
  flex-wrap: wrap;

  > .panel-action {
    width: 40px;
    height: 40px;
    border: 1px solid ${COLORS.lightGray};
  }

  > * {
    margin-right: 4px;
    margin-bottom: 8px;
  }
`;
