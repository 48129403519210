import React from "react";

import { useFormikContext } from "formik";

import {
  ConfigurationPanel,
  ConfigurationSection,
} from "manager/components/Lumen";
import {
  LUMEN_INCOME_RENT_RATIO_PERIOD,
  LUMEN_INCOME_RENT_RATIO_WITH_GUARANTORS_MODE,
  LUMEN_INCOME_TYPES,
  LUMEN_CASH_RUNWAY_RATIO_WITH_GUARANTORS_MODE,
  LUMEN_SECTION_TYPE,
  LUMEN_SECTION_TYPE_LABELS,
  LUMEN_TOTAL_INCOME_OR_ASSETS_WITH_GUARANTORS_MODE,
} from "manager/config/lumen.config";
import { MaskedInput, RadioGroup, SwitchTitle } from "shared/components/Form";
import { LightText } from "shared/components/Typography";
import { showError } from "shared/utils/forms";

import { LumenFormulaFormikValues } from "./interfaces";

const LumenFormulaStepIncome = () => {
  const { values, touched, errors, setFieldValue } =
    useFormikContext<LumenFormulaFormikValues>();
  return (
    <>
      <strong>
        <SwitchTitle
          id="useIncomeRentRatio"
          title={LUMEN_SECTION_TYPE_LABELS[LUMEN_SECTION_TYPE.INCOME]}
          value={values.useIncomeRentRatio}
          onChange={(value) => setFieldValue("useIncomeRentRatio", value)}
          noPadding
        />
      </strong>
      {/* @ts-ignore */}
      <ConfigurationPanel isEnabled={!!values.useIncomeRentRatio}>
        <ConfigurationSection>
          <div className="configuration-section-header">
            Which of the following would you like to measure when evaluating an
            application?
          </div>
          <div className="configuration-section-body">
            <RadioGroup
              options={[
                {
                  value: LUMEN_INCOME_RENT_RATIO_PERIOD.ANNUAL,
                  label: "Annual Income/Rent",
                },
                {
                  value: LUMEN_INCOME_RENT_RATIO_PERIOD.MONTHLY,
                  label: "Monthly Income/Rent",
                },
              ]}
              id="incomeRentRatioPeriod"
              name="incomeRentRatioPeriod"
              block
            />
          </div>
        </ConfigurationSection>

        <ConfigurationSection>
          <div className="configuration-section-header">
            Please enter the income/rent ratios you would like to use when
            evaluating an application.
          </div>
          <div className="configuration-section-body">
            <div className="formula-input">
              {/* @ts-ignore */}
              <MaskedInput.Formik.IncomeRentRatio
                id="minIncomeRentRatio"
                name="minIncomeRentRatio"
                label="Minimum Ratio"
                error={showError("minIncomeRentRatio", touched, errors)}
              />
            </div>
            <div className="formula-input">
              {/* @ts-ignore */}
              <MaskedInput.Formik.IncomeRentRatio
                className="formula-input"
                id="idealIncomeRentRatio"
                name="idealIncomeRentRatio"
                label="Ideal Ratio"
                error={showError("idealIncomeRentRatio", touched, errors)}
              />
            </div>
          </div>
        </ConfigurationSection>

        <ConfigurationSection>
          <div className="configuration-section-header">
            When guarantors exist on an application, which of the following fits
            your workflow?
          </div>
          <div className="configuration-section-body">
            <RadioGroup
              options={[
                {
                  value: LUMEN_INCOME_RENT_RATIO_WITH_GUARANTORS_MODE.NO_CHANGE,
                  label: "The income/rent ratio doesn't change",
                },
                {
                  value:
                    LUMEN_INCOME_RENT_RATIO_WITH_GUARANTORS_MODE.DIFFERENT_RATIO,
                  label: "A different income/rent ratio is required",
                },
              ]}
              id="incomeRentRatioWithGuarantors"
              name="incomeRentRatioWithGuarantors"
              block
            />
          </div>
          {values.incomeRentRatioWithGuarantors ===
            LUMEN_INCOME_RENT_RATIO_WITH_GUARANTORS_MODE.DIFFERENT_RATIO && (
            <div className="configuration-section-body pt-0">
              <div className="formula-input">
                {/* @ts-ignore */}
                <MaskedInput.Formik.IncomeRentRatio
                  id="minIncomeRentRatioWithGuarantor"
                  name="minIncomeRentRatioWithGuarantor"
                  label="Minimum Ratio"
                  error={showError(
                    "minIncomeRentRatioWithGuarantor",
                    touched,
                    errors
                  )}
                />
              </div>
              <div className="formula-input">
                {/* @ts-ignore */}
                <MaskedInput.Formik.IncomeRentRatio
                  className="formula-input"
                  id="idealIncomeRentRatioWithGuarantor"
                  name="idealIncomeRentRatioWithGuarantor"
                  label="Ideal Ratio"
                  error={showError(
                    "idealIncomeRentRatioWithGuarantor",
                    touched,
                    errors
                  )}
                />
              </div>
            </div>
          )}
        </ConfigurationSection>

        <ConfigurationSection>
          <div className="configuration-section-header">
            How would you like to calculate total income on an application with
            a guarantor?
          </div>
          <div className="configuration-section-body">
            <RadioGroup
              options={[
                {
                  value:
                    LUMEN_TOTAL_INCOME_OR_ASSETS_WITH_GUARANTORS_MODE.COMBINE_APPLICANTS_GUARANTORS,
                  label: "Combine income from applicants and guarantors",
                },
                {
                  value:
                    LUMEN_TOTAL_INCOME_OR_ASSETS_WITH_GUARANTORS_MODE.ONLY_GUARANTOR,
                  label: (
                    <>
                      Consider only guarantor’s income when calculating results{" "}
                      <LightText>
                        (applicant&apos;s income will be ignored)
                      </LightText>
                    </>
                  ),
                },
              ]}
              id="totalIncomeWithGuarantor"
              name="totalIncomeWithGuarantor"
              block
            />
          </div>
        </ConfigurationSection>

        <ConfigurationSection>
          <div className="configuration-section-header">
            Which of the following would you like to use for the income to rent
            calculation?
          </div>
          <div className="configuration-section-body">
            <RadioGroup
              options={[
                {
                  value: LUMEN_INCOME_TYPES.VERIFIED,
                  label: "VEROfied Income",
                },
                {
                  value: LUMEN_INCOME_TYPES.VERIFIED_AND_DOCUMENT_EXTRACTION,
                  label: "VEROfied Income + Document Extraction",
                },
                {
                  value: LUMEN_INCOME_TYPES.STATED,
                  label: "Stated Income",
                },
              ]}
              id="incomeConsidered"
              name="incomeConsidered"
              block
            />
          </div>
        </ConfigurationSection>

        <ConfigurationSection>
          <div className="configuration-section-header">
            Would you like to use Cash Runway (VEROfied Cash Balances / [Monthly
            Rent x Lease Duration] ) as a fallback filter if the VEROfied income
            does not meet the minimum income to rent ratio?
          </div>
          <div className="configuration-section-body">
            <RadioGroup
              options={[
                {
                  value: true,
                  label: "Yes",
                },
                {
                  value: false,
                  label: "No",
                },
              ]}
              id="useAssets"
              name="useAssets"
              block
            />
          </div>
        </ConfigurationSection>

        {values.useAssets && (
          <ConfigurationSection>
            <div className="configuration-section-header">
              How would you like to calculate cash runway on an application with
              a guarantor?
            </div>
            <div className="configuration-section-body">
              <RadioGroup
                options={[
                  {
                    value:
                      LUMEN_TOTAL_INCOME_OR_ASSETS_WITH_GUARANTORS_MODE.COMBINE_APPLICANTS_GUARANTORS,
                    label:
                      "Consider both the applicant's and guarantor's cash balances",
                  },
                  {
                    value:
                      LUMEN_TOTAL_INCOME_OR_ASSETS_WITH_GUARANTORS_MODE.ONLY_GUARANTOR,
                    label:
                      "Only evaluate the guarantor's balances (The applicant's balances will not be considered)",
                  },
                ]}
                id="totalAssetsWithGuarantor"
                name="totalAssetsWithGuarantor"
                block
              />
            </div>
          </ConfigurationSection>
        )}

        {values.useAssets && (
          <ConfigurationSection>
            <div className="configuration-section-header">
              Please enter the cash runway ratios you would like to use when
              evaluating an application.
            </div>
            <div className="configuration-section-body">
              <div className="formula-input">
                {/* @ts-ignore */}
                <MaskedInput.Formik.IncomeRentRatio
                  id="minAssetsRatio"
                  name="minAssetsRatio"
                  label="Minimum Ratio"
                  error={showError("minAssetsRatio", touched, errors)}
                />
              </div>
              <div className="formula-input">
                {/* @ts-ignore */}
                <MaskedInput.Formik.IncomeRentRatio
                  className="formula-input"
                  id="idealAssetsRatio"
                  name="idealAssetsRatio"
                  label="Ideal Ratio"
                  error={showError("idealAssetsRatio", touched, errors)}
                />
              </div>
            </div>

            <div className="configuration-section-header">
              When guarantors exist on an application, which of the following
              fits your workflow?
            </div>
            <div className="configuration-section-body">
              <RadioGroup
                options={[
                  {
                    value:
                      LUMEN_CASH_RUNWAY_RATIO_WITH_GUARANTORS_MODE.NO_CHANGE,
                    label: "The cash runway ratio doesn't change",
                  },
                  {
                    value:
                      LUMEN_CASH_RUNWAY_RATIO_WITH_GUARANTORS_MODE.DIFFERENT_RATIO,
                    label: "A different different ratio is required",
                  },
                ]}
                id="cashRunwayRatioWithGuarantors"
                name="cashRunwayRatioWithGuarantors"
                block
              />
            </div>
            {values.cashRunwayRatioWithGuarantors ===
              LUMEN_INCOME_RENT_RATIO_WITH_GUARANTORS_MODE.DIFFERENT_RATIO && (
              <div className="configuration-section-body pt-0">
                <div className="formula-input">
                  {/* @ts-ignore */}
                  <MaskedInput.Formik.IncomeRentRatio
                    id="minCashRunwayRatioWithGuarantor"
                    name="minCashRunwayRatioWithGuarantor"
                    label="Minimum Ratio"
                    error={showError(
                      "minCashRunwayRatioWithGuarantor",
                      touched,
                      errors
                    )}
                  />
                </div>
                <div className="formula-input">
                  {/* @ts-ignore */}
                  <MaskedInput.Formik.IncomeRentRatio
                    className="formula-input"
                    id="idealCashRunwayRatioWithGuarantor"
                    name="idealCashRunwayRatioWithGuarantor"
                    label="Ideal Ratio"
                    error={showError(
                      "idealCashRunwayRatioWithGuarantor",
                      touched,
                      errors
                    )}
                  />
                </div>
              </div>
            )}
          </ConfigurationSection>
        )}
      </ConfigurationPanel>
    </>
  );
};

export default LumenFormulaStepIncome;
