import React from "react";

import { useFormikContext } from "formik";

import {
  ConfigurationPanel,
  ConfigurationSection,
  LumenFilters,
} from "manager/components/Lumen";
import {
  LUMEN_CREDIT_SCORE_WITH_GUARANTORS_MODE,
  LUMEN_CREDIT_RECORDS_SCOPE,
  LUMEN_SECTION_TYPE,
  LUMEN_SECTION_TYPE_LABELS,
} from "manager/config/lumen.config";
import { Input, RadioGroup, VeroFormField } from "shared/components/Form";
import { SwitchTitle } from "shared/components/Form/SwitchTitle";
import { LightText, SmallText } from "shared/components/Typography";

import { LumenFormulaFormikValues } from "./interfaces";

const LumenFormulaStepCreditProfile = () => {
  const { values, setFieldValue } =
    useFormikContext<LumenFormulaFormikValues>();
  return (
    <>
      <strong>
        <SwitchTitle
          id="useCreditScore"
          title={LUMEN_SECTION_TYPE_LABELS[LUMEN_SECTION_TYPE.CREDIT]}
          value={values.useCreditScore}
          onChange={(value) => setFieldValue("useCreditScore", value)}
          noPadding
        />
      </strong>
      {/* @ts-ignore */}
      <ConfigurationPanel isEnabled={!!values.useCreditScore}>
        <ConfigurationSection>
          <div className="configuration-section-header">
            Please enter the credit score values you would like to use when
            evaluating each applicant.
          </div>
          <div className="configuration-section-body">
            <div className="formula-input">
              <VeroFormField
                as={Input}
                id="minCreditScore"
                name="minCreditScore"
                label="Minimum Score"
              />
            </div>
            <div className="formula-input">
              <VeroFormField
                className="formula-input"
                as={Input}
                id="idealCreditScore"
                name="idealCreditScore"
                label="Ideal Score"
              />
            </div>
            <SmallText light>
              Ideal score represents a highly qualified renter
            </SmallText>
          </div>
        </ConfigurationSection>

        <ConfigurationSection>
          <div className="configuration-section-header">
            When guarantors exist on an application, which of the following fits
            your workflow?
          </div>
          <div className="configuration-section-body">
            <RadioGroup
              options={[
                {
                  value: LUMEN_CREDIT_SCORE_WITH_GUARANTORS_MODE.ONLY_GUARANTOR,
                  label: (
                    <>
                      <strong>Only</strong> evaluate the{" "}
                      <strong>guarantor&apos;s</strong> score{" "}
                      <LightText>
                        (The applicant&apos;s credit score will not be
                        considered)
                      </LightText>
                    </>
                  ),
                },
                {
                  value:
                    LUMEN_CREDIT_SCORE_WITH_GUARANTORS_MODE.APPLICANT_AND_GUARANTOR,
                  label: (
                    <>
                      Consider the{" "}
                      <strong>applicant&apos;s and guarantor&apos;s</strong>{" "}
                      credit score
                    </>
                  ),
                },
              ]}
              id="creditScoreScopeWithGuarantors"
              name="creditScoreScopeWithGuarantors"
              block
            />
          </div>
        </ConfigurationSection>

        <ConfigurationSection>
          <div className="configuration-section-header">
            Please select which credit records you would like to screen
            applicants for
          </div>
          <div className="configuration-section-body">
            <RadioGroup
              options={[
                {
                  value: LUMEN_CREDIT_RECORDS_SCOPE.PUBLIC_AND_BANKRUPTCIES,
                  label: "Public Records and Bankruptcies",
                },
                {
                  value: LUMEN_CREDIT_RECORDS_SCOPE.ONLY_PUBLIC,
                  label: "Only Public Records",
                },
                {
                  value: LUMEN_CREDIT_RECORDS_SCOPE.ONLY_BANKRUPTCIES,
                  label: "Only Bankruptcies",
                },
                {
                  value: LUMEN_CREDIT_RECORDS_SCOPE.NONE,
                  label: "None of the above",
                },
              ]}
              id="creditRecordsScope"
              name="creditRecordsScope"
              block
              onChange={(value) => {
                setFieldValue("creditRecordsScope", value);

                if (value === LUMEN_CREDIT_RECORDS_SCOPE.NONE) {
                  setFieldValue("filterCreditPublicRecords", []);
                  setFieldValue("useCreditRecordSafetyNet", false);
                }
              }}
            />
          </div>
        </ConfigurationSection>

        {values.creditRecordsScope &&
          values.creditRecordsScope !== LUMEN_CREDIT_RECORDS_SCOPE.NONE && (
            <LumenFilters
              lumenSection={LUMEN_SECTION_TYPE.CREDIT}
              filtersProp="filterCreditPublicRecords"
              crucialFiltersText={`Any records that are considered "Crucial" will cause the applicants and group to be flagged as "Negative" and the suggested action will be "Decline"`}
              ignoredFiltersText={`Any records that are considered "Ignored" will not have any impact on the group or individual. The Lumen status and suggested action will not be impacted.`}
              safetyNetText={`Any records that do not meet the criteria of the above categories will be considered "Safety net". This will cause the applicant and group to be flagged as "Neutral" and the suggested action will be "Review Background".`}
              safetyNetProp="useCreditRecordSafetyNet"
            />
          )}
      </ConfigurationPanel>
    </>
  );
};

export default LumenFormulaStepCreditProfile;
