import styled from "@emotion/styled";

import FlexContainer from "shared/components/FlexContainer";
import { ActionLink } from "shared/components/Links";
import { RegularText as BaseRegularText } from "shared/components/Typography";
import { COLORS, MEDIA_QUERIES, colors } from "shared/config/constants";

const Header = styled(FlexContainer)`
  background: ${COLORS.lightPurple};
  position: relative;
  padding: 22px;
  margin-bottom: 10px;

  ${MEDIA_QUERIES.w.ltSm} {
    margin-top: 16px;
    padding: 0;
  }

  .title {
    color: ${COLORS.white} !important;
    margin-right: 75px;
    margin-bottom: 0 !important;
  }

  .triangles-image {
    position: absolute;
    top: 0;
    height: 100%;
    right: 260px;

    path {
      fill: ${COLORS.lightGreen2};
    }

    ${MEDIA_QUERIES.w.ltLg} {
      right: 0;
    }
    ${MEDIA_QUERIES.w.ltSm} {
      display: none;
    }
  }
`;

const TableContainer = styled.div`
  #leads-table {
    .verification-column {
      @media (max-width: 1500px) {
        padding-right: 0;
        padding-left: 0;
      }
    }
    .ant-table-body {
      overflow: hidden;
    }
  }
`;

const Cell = styled.div`
  min-height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${MEDIA_QUERIES.w.ltSm} {
    min-height: auto;
  }

  .yardi-icon {
    margin-left: 8px;
    vertical-align: middle;
  }

  .converted-lead-status {
    display: block;
    color: inherit;
  }

  .leads-column {
    min-width: max-content;
  }
  .no-identifier-text {
    font-size: 12px;
    color: ${COLORS.gray2};
  }
`;

const CheckboxContainer = styled.div`
  background: ${COLORS.white};
`;

const ArchivedColumn = styled(BaseRegularText)`
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }
`;

const ArchiveLeadContainer = styled.div`
  padding: 15px 24px;
  background: ${COLORS.white};
  .invite-lead-mobile {
    display: none;
    ${MEDIA_QUERIES.w.ltSm} {
      display: block;
    }
  }
`;

const LeadType = styled.div<{ type?: string }>`
  position: relative;
  display: inline-block;
  font-size: 6px;
  color: ${({ type }) =>
    type === "prospect" ? COLORS.violetTheme.lightViolet2 : COLORS.primary};
  border: 1px solid
    ${({ type }) =>
      type === "prospect"
        ? COLORS.violetTheme.lightViolet3
        : COLORS.darkViolet3};
  border-radius: 2px;
  bottom: 3px;
  padding: 2px 3px;
  text-transform: uppercase;
`;

const ActionLinkCustom = styled(ActionLink)`
  color: ${colors.secondary.base};
`;

export {
  Header,
  TableContainer,
  Cell,
  CheckboxContainer,
  ArchivedColumn,
  LeadType,
  ArchiveLeadContainer,
  ActionLinkCustom,
};
