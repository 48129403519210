import styled from "@emotion/styled";

import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

export const Wrapper = styled.div`
  .select-title {
    color: ${COLORS.incomeVerification.title} !important;
    font-size: 15px;
  }

  .text-guest {
    color: ${COLORS.gray2};
    font-size: 13px !important;
  }
`;

export const Option = styled.div<{ selected?: boolean }>`
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  height: 85px;
  cursor: pointer;
  transition: 0.25s border ease;
  border: 1px solid
    ${(props) => (props.selected === true ? COLORS.background : COLORS.gray2)};
  .text {
    color: ${(props) =>
      props.selected === true ? COLORS.white : COLORS.gray2};
    font-size: 13px !important;
  }
  .radio-circle {
    min-width: 16px;
    min-height: 16px;
    max-width: 16px;
    max-height: 16px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    margin-right: 10px;
    border: 1px solid;
    border-color: ${(props) =>
      props.selected === true
        ? COLORS.dashboard.stackedBarChart.lightViolet2
        : "#bcb9ce"};
    .inner-circle {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: ${COLORS.dashboard.stackedBarChart.lightViolet2};
      opacity: ${(props) => (props.selected === true ? 1 : 0)};
    }
  }
  ${MEDIA_QUERIES.w.ltSm} {
    height: 110px;
  }
`;
