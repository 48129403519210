import axios from "axios";

import ROUTES from "shared/config/apiRoutes";
import { API_URL } from "shared/lib/api/setupApi";

import { notifyUnwrap } from "./utils";

export const getIntercomHash = async () =>
  notifyUnwrap(() => axios.get(`${API_URL}${ROUTES.getIntercomHash}`)).catch(
    console.info
  );
