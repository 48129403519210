import React from "react";

import PropTypes from "prop-types";

import { useGetYardiClientReqRes } from "manager/hooks/api";
import yardiIntegrationApi from "manager/lib/yardiIntegrationApi";
import Icon from "shared/components/Icon";
import { ActionLink } from "shared/components/Links";
import Table from "shared/components/Table/Table/Table";
import { downloadTextFile } from "shared/utils/pdf";

import { WrappedPreformatted, ClientTableWrapper } from "../styled";

const ClientRequestTable = ({ id }) => {
  const { yardiClientReqRes, isLoadingClientReqRes } =
    useGetYardiClientReqRes(id);

  const downloadSoapResponse = async () => {
    const name = `req-${id}-soap-response.xml`;
    const data = await yardiIntegrationApi.getYardiClientReqResSoapResponse(id);
    downloadTextFile(data, name);
  };

  const downloadYardiServiceResponse = async () => {
    const name = `req-${id}-yardi-service-response.json`;
    const data = await yardiIntegrationApi.getYardiClientReqResServiceResponse(
      id
    );
    downloadTextFile(JSON.stringify(data, null, 2), name);
  };

  return yardiClientReqRes ? (
    <ClientTableWrapper>
      <Table
        alt
        withBorder
        noClickableRows
        columns={[
          {
            title: "VERO Request",
            dataIndex: "veroRequest",
            key: "veroRequest",
          },
          {
            title: "Yardi Soap Response",
            dataIndex: "yardiSoapResponse",
            key: "yardiSoapResponse",
          },
          {
            title: "Yardi Service Response",
            dataIndex: "yardiServiceResponse",
            key: "yardiServiceResponse",
          },
        ]}
        rows={[
          {
            key: id,
            values: [
              <WrappedPreformatted>
                {JSON.stringify(yardiClientReqRes, null, 2)}
              </WrappedPreformatted>,
              <div className="download-link">
                <ActionLink onClick={downloadSoapResponse}>Download</ActionLink>
              </div>,
              <div className="download-link">
                <ActionLink onClick={downloadYardiServiceResponse}>
                  Download
                </ActionLink>
              </div>,
            ],
          },
        ]}
        loading={isLoadingClientReqRes}
        placeholder={{
          label: "Data",
          Icon: Icon.NoDataIcon,
        }}
        columnBreakpoints={{
          xs: {
            column1: "veroRequest",
            column2: "yardiSoapResponse",
          },
          sm: ["veroRequest", "yardiSoapResponse", "yardiServiceResponse"],
        }}
        pagination={false}
      />
    </ClientTableWrapper>
  ) : (
    <p>No data</p>
  );
};

ClientRequestTable.propTypes = {
  id: PropTypes.number.isRequired,
};

export default ClientRequestTable;
