import React from "react";

import { Divider } from "antd";

import { getIntegrationType } from "manager/utils/integration.util";
import FlexContainer from "shared/components/FlexContainer";
import { INTEGRATION_TYPES } from "shared/config/constants";

import IntegrationMark from "./IntegrationMark";
import { INTEGRATIONS_MARK_SIZE } from "./IntegrationMark.confg";
import {
  getIconPerIntegration,
  ACCEPTED_INTEGRATIONS,
} from "./IntegrationMark.util";
import { DealIntegrationMarkProps } from "./interfaces";
import { DealIntegrationExtraInfo } from "./styled";

const YARDI_CONNECTED_DEAL_MSG = "Current application is connected to Yardi.";

const DealIntegrationMark = ({
  property,
  unit,
  deal,
  lg,
}: DealIntegrationMarkProps) => {
  const size = lg ? INTEGRATIONS_MARK_SIZE.lg : INTEGRATIONS_MARK_SIZE.sm;
  let popoverLabel = null;
  const integrationType = getIntegrationType(unit || property);

  if (integrationType === INTEGRATION_TYPES.YARDI) {
    const { yardiPcode, yardiTcode } = deal;
    popoverLabel = (
      <FlexContainer flexDirection="column">
        {YARDI_CONNECTED_DEAL_MSG}
        <DealIntegrationExtraInfo>
          <div className="inline-extra-info">P-Code: {yardiPcode || "N/A"}</div>
          <Divider type="vertical" />
          <div className="inline-extra-info">T-Code: {yardiTcode || "N/A"}</div>
        </DealIntegrationExtraInfo>
      </FlexContainer>
    );
  }

  if (ACCEPTED_INTEGRATIONS.has(integrationType)) {
    const IntegrationIcon = getIconPerIntegration(integrationType, size, false);
    return (
      <IntegrationMark
        IntegrationIcon={IntegrationIcon}
        popoverLabel={popoverLabel}
        size={size}
        popoverStyle={{ maxWidth: "500px" }}
        popoverClassName="custom-width-popover"
      />
    );
  }

  return null;
};

export default DealIntegrationMark;
