import React from "react";

import { useFormikContext } from "formik";
import PropTypes from "prop-types";

import { ATTACHMENT_TYPES } from "manager/config/constants";
import { SwitchWithCheckboxList } from "shared/components/Form";
import Settings from "shared/components/Settings";
import Skeleton from "shared/components/Skeleton";
import { APPLICANT_TYPES } from "shared/config/constants";

import ApplicationSetupHeader from "../Header";
import { getAttachmentsFilter } from "../utils";

const applicantAdditionalAttachments =
  "requiresApplicantAdditionalAttachementsForBackground";
const guarantorAdditionalAttachments =
  "requiresGuarantorAdditionalAttachementsForBackground";
const occupantAdditionalAttachments =
  "requiresOccupantAdditionalAttachementsForBackground";

const getRequiredAttachments = getAttachmentsFilter(
  ATTACHMENT_TYPES.UNVERIFIED_BACKGROUND
);

const RequiredAttachmentsBackgroundSetup = ({
  loading,
  openAttachmentsModal,
  disabled,
}) => {
  const { values, setFieldValue } = useFormikContext();

  const onChangeAttachment = (id, checked) => {
    let name = null;
    const index = values.requiredAttachments?.findIndex(
      (item) => item.id === id
    );
    name = `requiredAttachments[${index}].isActive`;
    setFieldValue(name, checked);
  };

  return (
    <Settings
      header={
        <Skeleton loading={loading}>
          <ApplicationSetupHeader
            title="Unverified Credit & Background Setup"
            subTitle="Please manage the attachments that you want to require from applicants, occupants and guarantors in case their credit & background are not verified"
          />
        </Skeleton>
      }
      nodes={[
        {
          key: 1,
          component: (
            <Skeleton loading={loading}>
              <SwitchWithCheckboxList
                title="Applicant Attachments"
                items={getRequiredAttachments(
                  values,
                  APPLICANT_TYPES.applicant
                )}
                onManageList={() => {
                  openAttachmentsModal({
                    title: "Manage Applicant Attachments",
                    dealSetupId: values.id,
                    items: [
                      ...getRequiredAttachments(
                        values,
                        APPLICANT_TYPES.applicant
                      ),
                    ],
                    newItemProps: {
                      applicantType: APPLICANT_TYPES.applicant,
                      attachmentType: ATTACHMENT_TYPES.UNVERIFIED_BACKGROUND,
                    },
                  });
                }}
                onChange={onChangeAttachment}
                value={values[applicantAdditionalAttachments]}
                onChangeTitle={(value) => {
                  setFieldValue(applicantAdditionalAttachments, value);
                }}
                disabled={disabled}
              />
            </Skeleton>
          ),
        },
        {
          key: 2,
          component: (
            <Skeleton loading={loading}>
              <SwitchWithCheckboxList
                title="Occupant Attachments"
                description="over 18 years old"
                items={getRequiredAttachments(values, APPLICANT_TYPES.occupant)}
                onManageList={() => {
                  openAttachmentsModal({
                    title: "Manage Occupant Attachments",
                    dealSetupId: values.id,
                    items: [
                      ...getRequiredAttachments(
                        values,
                        APPLICANT_TYPES.occupant
                      ),
                    ],
                    newItemProps: {
                      applicantType: APPLICANT_TYPES.occupant,
                      attachmentType: ATTACHMENT_TYPES.UNVERIFIED_BACKGROUND,
                    },
                  });
                }}
                onChange={onChangeAttachment}
                value={values[occupantAdditionalAttachments]}
                onChangeTitle={(value) => {
                  setFieldValue(occupantAdditionalAttachments, value);
                }}
                disabled={disabled}
              />
            </Skeleton>
          ),
        },
        {
          key: 3,
          component: (
            <Skeleton loading={loading}>
              <SwitchWithCheckboxList
                title="Guarantor Attachments"
                items={getRequiredAttachments(
                  values,
                  APPLICANT_TYPES.guarantor
                )}
                onManageList={() => {
                  openAttachmentsModal({
                    title: "Manage Guarantor Attachments",
                    dealSetupId: values.id,
                    items: [
                      ...getRequiredAttachments(
                        values,
                        APPLICANT_TYPES.guarantor
                      ),
                    ],
                    newItemProps: {
                      applicantType: APPLICANT_TYPES.guarantor,
                      attachmentType: ATTACHMENT_TYPES.UNVERIFIED_BACKGROUND,
                    },
                  });
                }}
                onChange={onChangeAttachment}
                value={values[guarantorAdditionalAttachments]}
                onChangeTitle={(value) => {
                  setFieldValue(guarantorAdditionalAttachments, value);
                }}
                disabled={disabled}
              />
            </Skeleton>
          ),
        },
      ]}
    />
  );
};

RequiredAttachmentsBackgroundSetup.propTypes = {
  loading: PropTypes.bool,
  openAttachmentsModal: PropTypes.func,
  disabled: PropTypes.bool,
};

RequiredAttachmentsBackgroundSetup.defaultProps = {
  loading: false,
  openAttachmentsModal: () => {},
  disabled: false,
};

export default RequiredAttachmentsBackgroundSetup;
