import SHARED_ROUTES from "shared/config/routes";

const ROUTES = {
  ...SHARED_ROUTES,
  leaseRentOptionsPage: "/lro",
  applications: "/applications",
  personalInfo: "/personal-info",
  submittedApplications: "/submittedApplications",
  archiveApplications: "/archiveApplications",
  marketplace: "/marketplace",
  // Get started
  applicationInvitation: "/applications/:id/invitation",
  // Submit application
  submitIdentityVerification: "/applications/:id/submit/identity-verification",
  submitLinkEmployment: "/applications/:id/submit/link-employment",
  submitApplicationRenterProfile: "/applications/:id/submit/renter-profile",
  submitApplication: {
    payment: "/applications/:id/submit/payment",
    incomeAssetsVerification: {
      selectAccounts:
        "/applications/:id/submit/income-assets-verification/select-accounts",
      summary: "/applications/:id/submit/income-assets-verification/summary",
      oAuth: "/plaid-oauth",
    },
    creditBackgroundCheck: {
      enterSSN: "/applications/:id/submit/credit-background-check/enter-ssn",
      noSSN: "/applications/:id/submit/credit-background-check/no-ssn",
      quiz: "/applications/:id/submit/credit-background-check/quiz",
      summary: "/applications/:id/submit/credit-background-check/summary",
      verificationError:
        "/applications/:id/submit/credit-background-check/verification-error",
      checkInfo: "/applications/:id/submit/credit-background-check/check-info",
    },
    attachDocuments: "/applications/:id/submit/attach-documents",
    additionalData: "/applications/:id/submit/additional-data",
    submitApplicationDedicatedStep:
      "/applications/:id/submit/submit-application",
  },
  applicationPayment: "/applications/:id/payment",
  applicantPayments: "/applicant/payments",
};

export const APPLICATION_SUBMIT_ROUTES = [
  ROUTES.submitIdentityVerification,
  ROUTES.submitLinkEmployment,
  ROUTES.submitApplicationRenterProfile,
  ROUTES.submitApplication.payment,
  ROUTES.submitApplication.incomeAssetsVerification.selectAccounts,
  ROUTES.submitApplication.incomeAssetsVerification.summary,
  ROUTES.submitApplication.incomeAssetsVerification.oAuth,
  ROUTES.submitApplication.creditBackgroundCheck.enterSSN,
  ROUTES.submitApplication.creditBackgroundCheck.noSSN,
  ROUTES.submitApplication.creditBackgroundCheck.quiz,
  ROUTES.submitApplication.creditBackgroundCheck.summary,
  ROUTES.submitApplication.creditBackgroundCheck.verificationError,
  ROUTES.submitApplication.creditBackgroundCheck.checkInfo,
  ROUTES.submitApplication.attachDocuments,
  ROUTES.submitApplication.additionalData,
];

export const GROUPED_ROUTES_KEYS = Object.freeze({
  APPLICATIONS: "APPLICATIONS",
  PERSONAL_INFO: "PERSONAL_INFO",
  MARKETPLACE: "MARKETPLACE",
});

export const GROUPED_ROUTES = {
  [GROUPED_ROUTES_KEYS.APPLICATIONS]: [
    ...APPLICATION_SUBMIT_ROUTES,
    ROUTES.applications,
    ROUTES.submittedApplications,
    ROUTES.archiveApplications,
    ROUTES.applicationInvitation,
    ROUTES.submitApplicationDedicatedStep,
    ROUTES.submitApplication.submitApplicationDedicatedStep,
    ROUTES.application,
    ROUTES.applicationAttachments,
    ROUTES.applicationCreditBackground,
    ROUTES.applicationIdVerification,
    ROUTES.applicationVorVerifications,
    ROUTES.applicationIncomeAssets,
    ROUTES.applicationRenterProfile,
    ROUTES.applicationVerifications,
    ROUTES.applicationPayment,
    ROUTES.applicationWorkflow,
    ROUTES.applicationPayments,
    ROUTES.applicationLumen
  ],
  [GROUPED_ROUTES_KEYS.PERSONAL_INFO]: [ROUTES.personalInfo],
  [GROUPED_ROUTES_KEYS.MARKETPLACE]: [ROUTES.marketplace],
};

export default ROUTES;
