import { useQuery, useMutation, queryCache } from "react-query";

import { GET_DEAL_KEY } from "manager/hooks/api/dealQueries";
import api from "manager/lib/api";
import { HTTP_ERROR_CODES, NOTIFICATIONS } from "shared/config/constants";
import { openNotification } from "shared/utils/ui";

export const useGetAssignableAgents = (dealId, config = {}) => {
  const response = useQuery(
    ["getAssignableEmployees", dealId],
    () => api.getAssignableEmployees(dealId).then(({ data }) => data),
    {
      onError: (error) => {
        const message =
          error.statusCode === HTTP_ERROR_CODES.forbidden
            ? "You are not allowed to perform this action. Please contact your administrator."
            : "Failed to load agents";
        openNotification(message, NOTIFICATIONS.error);
      },
      retry: false,
      ...config,
    }
  );
  return response;
};

export const useAssignAgentToDeal = () => {
  const [mutate, status] = useMutation(api.assignEmployeeToDeal, {
    onSuccess: () => {
      queryCache.invalidateQueries(GET_DEAL_KEY);
    },
    onError: () => {
      openNotification(
        "Failed to assign employee to application",
        NOTIFICATIONS.error
      );
    },
  });

  return {
    assignEmployeeToDeal: mutate,
    ...status,
  };
};
