import React from "react";

import { Field, Form, Formik } from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";

import { CreateUnitListingInnerFormElements } from "manager/components/Modal/CreateUnitListingModal";
import {
  CreateUnitInnerFormElements,
  CreateUnitValidationSchema,
} from "manager/components/Modal/CreateUnitModal";
import ErrorList from "shared/components/ErrorList";
import { Select } from "shared/components/Form/Select";
import { Modal } from "shared/components/Modal";
import RadioGroupBoxedList from "shared/components/RadioGroupBoxedList";
import Spacer from "shared/components/Spacer";
import { basicUnitPropType, refPropType } from "shared/utils/propTypes";

import { YardiAlertResolverWrapper } from "./styled";
import {
  RESOLVE_UNIT_LINK_OPTIONS,
  UnitListingValidationSchema,
} from "./yardiAlertsResolvers.config";

const ResolutionOptionItems = [
  {
    id: RESOLVE_UNIT_LINK_OPTIONS.LINK_EXISTING_UNIT,
    title: "Link with existing VERO unit",
  },
  {
    id: RESOLVE_UNIT_LINK_OPTIONS.CREATE_NEW_UNIT,
    title: "Create new VERO unit",
  },
];

const SelectUnitValidationSchema = Yup.object().shape({
  veroUnitId: Yup.number().required("The VERO unit is required"),
});

const YardiLinkUnitResolverModal = ({
  yardiUnitName,
  currentSelection,
  setCurrentSelection,
  veroUnits,
  submit,
  createListing,
  formikRef,
}) => {
  let validationSchema =
    currentSelection === RESOLVE_UNIT_LINK_OPTIONS.CREATE_NEW_UNIT
      ? CreateUnitValidationSchema
      : SelectUnitValidationSchema;

  if (createListing) {
    validationSchema = validationSchema.concat(UnitListingValidationSchema);
  }

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{}}
      onSubmit={submit}
      innerRef={formikRef}
    >
      {({
        errors,
        touched,
        submitForm,
        isSubmitting,
        isValid,
        setFieldValue,
        setFieldTouched,
      }) => (
        <Form>
          <Modal
            title="Link unit with VERO"
            subtitle={
              <>
                Unit: <strong>{yardiUnitName}</strong>
              </>
            }
            submitButtonLabel="CONFIRM"
            submitButtonDisabled={!isValid}
            submit={submitForm}
            submitting={isSubmitting}
          >
            <YardiAlertResolverWrapper>
              {errors.nonFieldErrors && (
                <Modal.Body noPaddingBottom>
                  <ErrorList errors={errors.nonFieldErrors} />
                </Modal.Body>
              )}
              <Modal.Body>
                <RadioGroupBoxedList
                  currentSelection={currentSelection}
                  onChangeSelection={setCurrentSelection}
                  listItems={ResolutionOptionItems}
                />
                <Spacer size={Spacer.SIZES.md} />
                {currentSelection ===
                  RESOLVE_UNIT_LINK_OPTIONS.CREATE_NEW_UNIT && (
                  <>
                    <p className="section-label">
                      Please fill in all the required fields below
                    </p>
                    <CreateUnitInnerFormElements />
                    {createListing && (
                      <>
                        <Spacer size={Spacer.SIZES.md} />
                        <CreateUnitListingInnerFormElements
                          parentProp="unitListing"
                          securityDepositDisabled
                          availabilityDateDisabled
                        />
                      </>
                    )}
                  </>
                )}
                {currentSelection ===
                  RESOLVE_UNIT_LINK_OPTIONS.LINK_EXISTING_UNIT && (
                  <>
                    <p className="section-label">
                      {createListing
                        ? "Please select VERO unit for linking & confirm the listing"
                        : "Please select VERO unit for linking"}
                    </p>
                    <Field
                      as={Select}
                      name="veroUnitId"
                      id="veroUnitId"
                      label="Select Unit"
                      error={touched.veroUnitId && errors.veroUnitId}
                      onChange={(value) => setFieldValue("veroUnitId", value)}
                      onBlur={() => setFieldTouched("veroUnitId", true)}
                    >
                      {veroUnits.map((p) => (
                        <Select.Option
                          key={p.id}
                          data-testid={`option-${p.id}`}
                          disabled={Boolean(p.provider)}
                        >
                          {p.name}
                          {p.provider
                            ? " (already synced to External Provider)"
                            : ""}
                        </Select.Option>
                      ))}
                    </Field>
                    {createListing && (
                      <>
                        <Spacer size={Spacer.SIZES.md} />
                        <CreateUnitListingInnerFormElements
                          parentProp="unitListing"
                          securityDepositDisabled
                          availabilityDateDisabled
                        />
                      </>
                    )}
                  </>
                )}
              </Modal.Body>
            </YardiAlertResolverWrapper>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

YardiLinkUnitResolverModal.propTypes = {
  yardiUnitName: PropTypes.string,
  currentSelection: PropTypes.oneOf(Object.values(RESOLVE_UNIT_LINK_OPTIONS)),
  setCurrentSelection: PropTypes.func.isRequired,
  veroUnits: PropTypes.arrayOf(basicUnitPropType),
  submit: PropTypes.func.isRequired,
  createListing: PropTypes.bool,
  formikRef: refPropType.isRequired,
};

YardiLinkUnitResolverModal.defaultProps = {
  yardiUnitName: "",
  currentSelection: undefined,
  veroUnits: [],
  createListing: false,
};

export default YardiLinkUnitResolverModal;
