import * as detectIt from "detect-it";
import { useMediaQuery } from "react-responsive";

import { BREAKPOINT_RESOLUTIONS } from "shared/config/constants";

const DEVICE_TYPE = Object.freeze({
  MOUSE_ONLY: "mouseOnly",
  TOUCH_ONLY: "touchOnly",
  HYBRID: "hybrid",
});

const TOUCH_DEVICE_TYPES = [DEVICE_TYPE.TOUCH_ONLY, DEVICE_TYPE.HYBRID];

/**
 * Hook for device specific properties.
 * At the moment it checks if the current device has a touchscreen and if it's a mobile (checking the size).
 * <br/>
 * Library references:
 * - <a href="https://github.com/rafgraph/detect-it">https://github.com/rafgraph/detect-it</a>.
 * - <a href="https://github.com/yocontra/react-responsive">https://github.com/yocontra/react-responsive</a>.
 *
 * @returns {{
 * hasTouch: boolean,
 * isMobile: boolean,
 * isTablet: boolean,
 * isDesktop: boolean,
 * isBetweenMdAndLg: boolean,
 * isLargeDesktop: boolean,
 * isMobileOrTablet: boolean}}
 */
const useDeviceType = () => {
  const { deviceType } = detectIt;
  const hasTouch = TOUCH_DEVICE_TYPES.includes(deviceType);
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT_RESOLUTIONS.md - 1 });
  const isTablet = useMediaQuery({
    minWidth: BREAKPOINT_RESOLUTIONS.md,
    maxWidth: BREAKPOINT_RESOLUTIONS.xl,
  });
  const isDesktop = useMediaQuery({
    minWidth: BREAKPOINT_RESOLUTIONS.xl,
  });
  const isBetweenMdAndLg = useMediaQuery({
    maxWidth: BREAKPOINT_RESOLUTIONS.lg - 1,
    minWidth: BREAKPOINT_RESOLUTIONS.md,
  });
  const isLargeDesktop = useMediaQuery({
    minWidth: BREAKPOINT_RESOLUTIONS.xxxl,
  });

  const isMobileOrTablet = isMobile || isBetweenMdAndLg;

  return {
    hasTouch,
    isMobile,
    isTablet,
    isDesktop,
    isBetweenMdAndLg,
    isLargeDesktop,
    isMobileOrTablet,
  };
};

export default useDeviceType;
