import styled from "@emotion/styled";
import { Menu as BaseMenu } from "antd";

const Menu = styled(BaseMenu)`
  border: none;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  height: 49px;

  .ant-menu-submenu-title {
    display: flex;
    align-items: center;
    height: 100%;
  }
`;

const MenuItem = styled(BaseMenu.Item)`
  display: flex !important;
  align-items: center;

  &.ant-menu-item-selected,
  &:hover {
    border-bottom-color: transparent !important;
  }
`;

const IconWrapper = styled.div`
  display: flex;
`;

export { Menu, MenuItem, IconWrapper };
