import React, { useState } from "react";

import LinkEmployeeToYardiAgent from "manager/components/Modal/LinkEmployeeToYardiAgent";
import {
  getIntegrationType,
  hasIntegration,
} from "manager/utils/integration.util";
import { INTEGRATION_TYPES } from "shared/config/constants";
import useModal from "shared/hooks/useModal";

import AssignEmployeeToPropertiesSuccessModal from "./AssignEmployeeToPropertiesSuccessModal";

const LinkOperation = {
  LINK: "LINK",
  DONT_LINK: "DONT_LINK",
};

const getDataForIntegration = (
  integrationType,
  numNewProperties,
  numNewLinkedProperties
) => {
  let listTitle;
  let listItems;
  let ResolutionComponent;
  if (INTEGRATION_TYPES.YARDI === integrationType) {
    listTitle = `${numNewLinkedProperties} of ${numNewProperties} properties support Yardi integration. Please select the option below:`;
    listItems = [
      {
        id: LinkOperation.LINK,
        title: "Link VERO agent with corresponding Yardi agent",
      },
      {
        id: LinkOperation.DONT_LINK,
        title: "Don't link VERO agent with Yardi",
        subTitle:
          "This won't break the integration, however we recommend to link agents for data consistency",
      },
    ];
    ResolutionComponent = LinkEmployeeToYardiAgent;
  }

  return {
    listTitle,
    listItems,
    ResolutionComponent,
  };
};

const AssignEmployeeToPropertiesSuccessModalContainer = () => {
  const [currentSelection, setCurrentSelection] = useState(null);
  const { currentModalContext, openModalDialog } = useModal();
  const { employeeId, firstName, lastName, newlyAssignedProperties } =
    currentModalContext;

  const newProperties = newlyAssignedProperties || [];

  const employeeName = `${firstName} ${lastName}`;
  const numNewProperties = newProperties.length;
  const numNewLinkedProperties = newProperties.filter((property) =>
    hasIntegration(property)
  ).length;
  const integrationType = newProperties
    .map((property) => getIntegrationType(property))
    .filter((foundType) => !!foundType)[0];
  const { listTitle, listItems, ResolutionComponent } = getDataForIntegration(
    integrationType,
    numNewProperties,
    numNewLinkedProperties
  );

  const onSubmit = () => {
    if (currentSelection === LinkOperation.LINK) {
      const context = {
        employeeId,
        firstName,
        lastName,
        newlyAssignedProperties,
      };
      openModalDialog(ResolutionComponent, context);
    }
  };

  return (
    <AssignEmployeeToPropertiesSuccessModal
      onSubmit={onSubmit}
      employeeName={employeeName}
      listTitle={listTitle}
      listItems={listItems}
      numNewProperties={numNewProperties}
      currentSelection={currentSelection}
      onChangeSelection={setCurrentSelection}
    />
  );
};

export default AssignEmployeeToPropertiesSuccessModalContainer;
