import React from "react";

import { useConditionallyProceedDeal } from "manager/hooks/api";
import ApplicantsCheckList from "shared/components/ApplicantsCheckList";
import FlexContainer from "shared/components/FlexContainer";
import { openDefaultAnimatedActionModal } from "shared/components/Modals/AnimatedPendingActionModal";
import ConfirmationModal from "shared/components/Modals/ConfirmationModal";
import { RegularText } from "shared/components/Typography";
import { COMMON_PENDING_ANIMATION_MESSAGES } from "shared/config/constants";
import useModal from "shared/hooks/useModal";

import { ApplicantSummary } from ".";

interface ConditionallyProceedProps {
  dealId?: string | number;
  refetch: () => void;
  applicantsSummary: ApplicantSummary[];
}

export const useConditionallyProceedModal = ({
  dealId,
  refetch,
  applicantsSummary,
}: ConditionallyProceedProps) => {
  const { conditionallyProceed: conditionallyProceedAction } =
    useConditionallyProceedDeal();
  const { openModalDialog } = useModal();

  const conditionallyProceed = () =>
    openModalDialog(ConfirmationModal, {
      title: "Conditionally Proceed With Application",
      subtitle: (
        <FlexContainer>
          <RegularText weak>Applicants:&nbsp;</RegularText>
          <ApplicantsCheckList inline items={applicantsSummary} />
        </FlexContainer>
      ),

      description:
        "Please confirm you wish to conditionally proceed with this application. Once confirmed, you can view the criminal records if any exist for the listed applicants.",
      noDescriptionIcon: true,
    }).afterClose.then(async (result) => {
      if (result) {
        const messages = [
          ...COMMON_PENDING_ANIMATION_MESSAGES,
          "Conditionally Proceeded!",
        ];
        const animatedPendingActionApi =
          openDefaultAnimatedActionModal(messages);
        conditionallyProceedAction(
          { id: dealId },
          {
            onSuccess: () => {
              const afterAnimatedPendingModalCloseAction = () => {
                refetch();
              };

              animatedPendingActionApi.finishAnimation(
                afterAnimatedPendingModalCloseAction
              );
            },
            onError: () => {
              animatedPendingActionApi.finishAnimation();
            },
          }
        );
      }
    });

  return { conditionallyProceed };
};
