import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import {
  useCopyDealSetupFromPortfolio,
  useGetPropertySettings,
  useResetPropertyToPortfolioSettings,
  useGetPaymentAccountMappings,
  usePaymentAccountMapping
} from "manager/hooks/api";

import PropertySettings from "./PropertySettings";

const PropertySettingsContainer = ({ backLink }) => {
  const { id: propertyId } = useParams();

  const { propertySettings, isPropertySettingsLoading } =
    useGetPropertySettings(propertyId);

  const { copyDealSetupFromPortfolio } = useCopyDealSetupFromPortfolio();

  const { resetPropertyToPortfolioSettings } =
    useResetPropertyToPortfolioSettings();

  const location = _.get(propertySettings, 'location');

  const { paymentAccountMappings } = useGetPaymentAccountMappings({ location }, !_.isEmpty(location));

  const { createPaymentAccountMapping } = usePaymentAccountMapping();

  return (
    <PropertySettings
      backLink={backLink}
      propertyId={propertyId}
      propertySettings={propertySettings}
      isPropertySettingsLoading={isPropertySettingsLoading}
      copyDealSetupFromPortfolio={copyDealSetupFromPortfolio}
      resetPropertyToPortfolioSettings={resetPropertyToPortfolioSettings}
      paymentAccountMappings={paymentAccountMappings}
      createPaymentAccountMapping={createPaymentAccountMapping}
    />
  );
};

PropertySettingsContainer.propTypes = {
  backLink: PropTypes.object.isRequired,
};

export default PropertySettingsContainer;
