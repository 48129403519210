import React, { useEffect } from "react";

import { useHistory, useParams } from "react-router-dom";

import ROUTES from "renter/config/routes";
import { useGetApplicationInvitation } from "renter/hooks/api";
import SetPasswordPage from "shared/pages/SetPasswordPage";
import { getQueryParams } from "shared/utils/routing";

const SetPasswordContainer = () => {
  const history = useHistory();
  const urlParams = getQueryParams();
  const { application_id: applicationId } = urlParams || {}

  const { token } = useParams<{ token: string }>();

  const { application, refetchApplicationInvitation } =
    useGetApplicationInvitation(applicationId as string, false);

  useEffect(() => {
    if (application) {
      window.location.href = `${window.location.origin}/applicant/workflow/${applicationId}`
    }
  }, [application]);

  const onSuccess = async () => {
    if (applicationId) {
      await refetchApplicationInvitation();
    } else {
      history.push(ROUTES.applications);
    }
  };

  return (
    <SetPasswordPage
      onSuccessLogin={onSuccess}
      token={token}
      applicationId={applicationId}
    />
  );
};

export default SetPasswordContainer;
