import styled from "@emotion/styled";

import Well from "shared/components/Well";

const StyledWell = styled(Well)`
  .icon-wrapper {
    margin-right: 10px;
  }
`;

export { StyledWell };
