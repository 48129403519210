import React from "react";

import { ToggleVisibilityButton as StyledToggleVisibilityButton } from "../ToggleVisibilityButton/styled";

export type ToggleVisibilityButtonProps = {
  onClick: () => void;
  setHovered: (hover: boolean) => void;
  title: string;
};
export const ToggleVisibilityButton: React.FC<ToggleVisibilityButtonProps> = ({
  onClick,
  children,
  setHovered,
  title,
}) => (
  <StyledToggleVisibilityButton
    onClick={onClick}
    onKeyPress={onClick}
    role="button"
    tabIndex={0}
    onMouseEnter={() => setHovered(true)}
    onMouseLeave={() => setHovered(false)}
    title={title}
  >
    {children}
  </StyledToggleVisibilityButton>
);
