import React from 'react'

import { printDollarsFromCents } from 'shared/utils/dollar-print'

import LumenEntryCard from './LumenEntryCard'
import Outcomes from './Outcomes'
import { findLumenResult } from './utils'

const LumenSummary = ({ lumenRun }) => {
  const result = findLumenResult(lumenRun)

  return (
    <div className='vega container'>
      <section className='vega flex content'>
        <LumenEntryCard
          label='estimated total annual income'
          result={result}
          valueQuery='total-verified-income'
          valueTransformer={printDollarsFromCents}
          statusQueries={[{ query: 'has-deduped-income', status: 'warn' }]}
          defaultStatus=''
        />

        <LumenEntryCard
          label='total balances'
          result={result}
          valueQuery='total-verified-assets'
          valueTransformer={printDollarsFromCents}
          statusQueries={[{ query: 'has-deduped-asset', status: 'warn' }]}
          defaultStatus=''
        />

        <LumenEntryCard
          label='income to rent ratio'
          result={result}
          valueQuery='income-to-rent'
          statusQueries={[
            { query: 'income-to-rent-ideal', status: 'pass' },
            { query: 'income-to-rent-range-conditional', status: 'warn' }
          ]}
        />

        <LumenEntryCard
          label='cash runway ratio'
          result={result}
          valueQuery='cash-runway'
          statusQueries={[
            { query: 'cash-runway-ideal', status: 'pass' },
            { query: 'cash-runway-range-conditional', status: 'warn' }
          ]}
        />

        <LumenEntryCard
          label='average credit score'
          result={result}
          valueQuery='avg-credit-score'
          statusQueries={[
            { query: ['avg-credit-score-ideal', 'credit-scores-ideal'], status: 'pass' },
            { query: ['avg-credit-score-range-conditional', 'credit-scores-range-conditional'], status: 'warn' }
          ]}
        />
      </section>

      <Outcomes lumenRun={lumenRun} />
    </div>
  )
}

export default LumenSummary
