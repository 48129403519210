import React from "react";

import { useGetUnitFees } from "manager/hooks/api";

import Fees from "./UnitFees";

const UnitFeesContainer = ({ unit }) => {
  const location = unit?.location;

  if (location) {
    const { fees, isLoading } = useGetUnitFees(location);

    return <Fees loading={isLoading} fees={fees} />;
  }

  return <Fees fees={[]} />;
};

export default UnitFeesContainer;
