import styled from "@emotion/styled";
import { Radio } from "antd-latest";

import { COLORS } from "shared/config/constants";

export const BaseConditionWrap = styled.div`
  border: 2px solid ${COLORS.lightGray2};

  .title {
    background-color: ${COLORS.lightGray2};
    padding: 14px 24px;
  }

  .content {
    padding: 14px 34px 14px 24px;
  }

  .delete-icon {
    cursor: pointer;
  }
`;

interface ConditionWrapperProps {
  fullWidth?: boolean;
}

export const ConditionWrapper = styled.div<ConditionWrapperProps>`
  width: ${(props) => (props.fullWidth ? "100%" : "90%")};

  .between-operator-and-col {
    display: block;
    margin-top: 10px;
  }

  .eviction-case-type {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    row-gap: 16px;
  }
`;

export const EmptySpacer = styled.span`
  display: block;
  margin-bottom: 22px;
`;

// TODO move this as a new shared component
export const RadioGroup = styled(Radio.Group)`
  .ant-latest-radio-button-wrapper {
    border: 1px solid ${COLORS.lightViolet1};
    color: ${COLORS.lightViolet1};
    border-radius: 2px;

    &:focus-within {
      box-shadow: none;
    }

    &::before {
      display: none;
    }

    &:not(:last-child) {
      margin-right: 12px;
    }

    &.ant-latest-radio-button-wrapper-checked {
      color: white;
      border-color: ${COLORS.blueViolet} !important;
      background-color: ${COLORS.blueViolet};
    }
  }
`;
