import styled from "@emotion/styled";

import FlexContainer from "shared/components/FlexContainer";
import { ActionLink as BaseLink } from "shared/components/Links";
import Skeleton from "shared/components/Skeleton";
import Tile from "shared/components/Tile";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

export const StyledSkeleton = styled(Skeleton)<{
  loading?: boolean;
  paragraph?: boolean;
}>`
  margin-bottom: 35px;
`;

export const HeaderUnitInfo = styled(FlexContainer)`
  background-color: ${COLORS.violetTheme.headerBackground};
  border-radius: 2px 0 0 2px;
  flex-wrap: wrap;
  color: ${COLORS.white};
  min-height: 115px;
  padding: 21px;
`;

export const HeaderUnitDetails = styled(FlexContainer)`
  background-color: ${COLORS.lightViolet};
  border-radius: 0 2px 2px 0;
  padding: 24px;
`;

export const UnitName = styled.h2`
  color: ${COLORS.white};
  font-size: 28px;
  margin: 0 0 0 7px;
`;

export const UnitTile = styled(Tile)`
  margin-bottom: 0;
`;

export const MobileDetails = styled.div`
  background-color: ${COLORS.tile.alt.innerBackground};
  margin-top: 2px;
  padding: 15px 20px;
`;

export const UnitNameWrapper = styled(FlexContainer)`
  margin-top: 5px;
  margin-right: 35px;
`;

export const StatusContainer = styled(FlexContainer)`
  background-color: ${COLORS.lightViolet};
  border-radius: 2px;
  padding: 8px;
  span {
    margin-left: 8px;
  }
  ${MEDIA_QUERIES.w.ltMd} {
    margin: 0 20px 9px;
    width: max-content;
  }
`;

export const ActionLink = styled(BaseLink)`
  color: ${COLORS.violetTheme.lightGreen};
  margin-right: 15px;
  &:hover {
    color: ${COLORS.white};
  }
`;

export const UnitDetailsWrapper = styled(FlexContainer)`
  color: ${COLORS.white};
  span {
    font-size: 12px;
  }
`;

export const PropertyIntegrationMarkContainer = styled.div<{
  opacity?: number;
}>`
  opacity: ${({ opacity }) => opacity ?? 1};
  display: flex;
`;
