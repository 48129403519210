import { useRef } from "react";

import { useMutation } from "react-query";

import api from "manager/lib/api";
import DownloadPDFStatusModal from "shared/components/Modals/DownloadPDFStatusModal";
import { NOTIFICATIONS } from "shared/config/constants";
import useModal from "shared/hooks/useModal";
import { generateApplicationPDF } from "shared/lib/api/applicationApi";
import { generateDealPDF } from "shared/lib/api/dealApi";
import { downloadPDF } from "shared/utils/pdf";
import { openNotification } from "shared/utils/ui";

const onError = (error) => {
  const [statusCode, errorMessage] = Object?.values(error) ?? [];
  const notificationStatus =
    statusCode === 409 ? NOTIFICATIONS.warning : NOTIFICATIONS.error;

  if (typeof errorMessage?.error === "string") {
    openNotification(errorMessage?.error, notificationStatus);
  } else {
    openNotification("Failed to generate the PDF", notificationStatus);
  }
};

const onSuccess = ({ reportContent, reportFilename }) => {
  downloadPDF(reportContent, `${reportFilename}.pdf`);
};

export const useGenerateDealPDF = (dealId) => {
  const { openModalDialog } = useModal();
  const modalContext = useRef();

  const [mutate, status] = useMutation(() => generateDealPDF(dealId), {
    onMutate: () => {
      // @ts-ignore
      modalContext.current = openModalDialog(DownloadPDFStatusModal, {
        isLoading: true,
      });
    },
    onSuccess,
    onError,
    onSettled: () => {
      // @ts-ignore
      modalContext.current?.updateContext({ isLoading: false });
    },
  });

  return { downloadDealPDF: mutate, isLoading: status.isLoading };
};

export const useGenerateApplicationPDF = (applicationId) => {
  const { openModalDialog } = useModal();
  const modalContext = useRef();

  const [mutate, status] = useMutation(
    () => generateApplicationPDF({ applicationId }),
    {
      onMutate: () => {
        // @ts-ignore
        modalContext.current = openModalDialog(DownloadPDFStatusModal, {
          isLoading: true,
        });
      },
      onSuccess,
      onError,
      onSettled: () => {
        // @ts-ignore
        modalContext.current?.updateContext({ isLoading: false });
      },
    }
  );

  return { downloadApplicationPDF: mutate, isLoading: status.isLoading };
};

export const useGenerateLeadPDF = (leadId) => {
  const { openModalDialog } = useModal();
  const modalContext = useRef();

  const [mutate, status] = useMutation(() => api.generateLeadPDF({ leadId }), {
    onMutate: () => {
      // @ts-ignore
      modalContext.current = openModalDialog(DownloadPDFStatusModal, {
        isLoading: true,
      });
    },
    onSuccess,
    onError,
    onSettled: () => {
      // @ts-ignore
      modalContext.current?.updateContext({ isLoading: false });
    },
  });

  return {
    downloadLeadPDF: mutate,
    isLoading: status.isLoading,
  };
};
