import styled from "@emotion/styled";
import { Radio as BaseRadio } from "antd";

import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

export const Label = styled.label(() => ({
  display: "flex",
  color: COLORS.textColorPrimary,
  marginBottom: "12px",
  fontSize: "17px",
  [MEDIA_QUERIES.w.ltMd]: {
    whiteSpace: "normal",
  },
}));

export const RadioGroup = styled(BaseRadio.Group)(() => ({}));

export const Radio = styled(BaseRadio)<{ block?: boolean }>(({ block }) => ({
  ...(block && {
    display: "flex",
    paddingBottom: "18px",
    "& span:last-child": {
      whiteSpace: "normal",
    },
    "& span:first-child": {
      paddingTop: "5px",
    },
  }),
  [MEDIA_QUERIES.w.ltMd]: {
    marginBottom: "18px",
    whiteSpace: "normal",
  },
}));
