import React from "react";

import { useField } from "formik";
import PropTypes from "prop-types";

import { useGetDurationTypes } from "shared/hooks/api";

import ResidenceEmploymentDuration from "./ResidenceEmploymentDuration";

const ResidenceEmploymentDurationContainer = ({
  durationTypeFieldName,
  durationValueFieldName,
  disabled,
}) => {
  const { durationTypes, isDurationTypesLoading } = useGetDurationTypes();
  const [
    { value: currentDurationType },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    durationTypeMeta,
    { setValue: setDurationType },
  ] = useField(durationTypeFieldName);

  const [
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    field,
    { touched: isDurationValueTouched, error: durationValueError },
  ] = useField(durationValueFieldName);

  return (
    <ResidenceEmploymentDuration
      durationTypeFieldName={durationTypeFieldName}
      durationValueFieldName={durationValueFieldName}
      currentDurationType={durationTypes && currentDurationType}
      onDurationTypeChange={setDurationType}
      durationValueError={isDurationValueTouched ? durationValueError : ""}
      durationTypes={durationTypes}
      isDurationTypesLoading={isDurationTypesLoading}
      disabled={disabled}
    />
  );
};

ResidenceEmploymentDurationContainer.propTypes = {
  durationTypeFieldName: PropTypes.string.isRequired,
  durationValueFieldName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

ResidenceEmploymentDurationContainer.defaultProps = {
  disabled: undefined,
};

export default ResidenceEmploymentDurationContainer;
