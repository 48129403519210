import React from "react";

import { Field, Form, Formik } from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";

import {
  CreatePropertyInnerFormElements,
  CreatePropertyValidationSchema,
} from "manager/components/Modal/CreatePropertyModal";
import ErrorList from "shared/components/ErrorList";
import { Select } from "shared/components/Form/Select";
import { Modal } from "shared/components/Modal";
import RadioGroupBoxedList from "shared/components/RadioGroupBoxedList";
import Spacer from "shared/components/Spacer";
import { basicPropertyPropType, refPropType } from "shared/utils/propTypes";

import { YardiAlertResolverWrapper } from "./styled";
import { RESOLVE_PROPERTY_LINK_OPTIONS } from "./yardiAlertsResolvers.config";

const ResolutionOptionItems = [
  {
    id: RESOLVE_PROPERTY_LINK_OPTIONS.LINK_EXISTING_PROPERTY,
    title: "Link with existing VERO property",
  },
  {
    id: RESOLVE_PROPERTY_LINK_OPTIONS.CREATE_NEW_PROPERTY,
    title: "Create new VERO property",
  },
];

const SelectPropertyValidationSchema = Yup.object().shape({
  veroPropertyId: Yup.number().required("The VERO property is required"),
});

const YardiLinkPropertyResolverModal = ({
  yardiPropertyName,
  currentSelection,
  setCurrentSelection,
  veroProperties,
  submit,
  formikRef,
}) => (
  <Formik
    validationSchema={
      currentSelection === RESOLVE_PROPERTY_LINK_OPTIONS.CREATE_NEW_PROPERTY
        ? CreatePropertyValidationSchema
        : SelectPropertyValidationSchema
    }
    initialValues={{}}
    onSubmit={submit}
    innerRef={formikRef}
  >
    {({
      errors,
      touched,
      submitForm,
      isSubmitting,
      isValid,
      setFieldValue,
      setFieldTouched,
    }) => (
      <Form>
        <Modal
          title="Link property with VERO"
          subtitle={
            <>
              Property: <strong>{yardiPropertyName}</strong>
            </>
          }
          submitButtonLabel="CONFIRM"
          submitButtonDisabled={!isValid}
          submit={submitForm}
          submitting={isSubmitting}
        >
          <YardiAlertResolverWrapper>
            {errors.nonFieldErrors && (
              <Modal.Body noPaddingBottom>
                <ErrorList errors={errors.nonFieldErrors} />
              </Modal.Body>
            )}
            <Modal.Body>
              <RadioGroupBoxedList
                currentSelection={currentSelection}
                onChangeSelection={setCurrentSelection}
                listItems={ResolutionOptionItems}
              />
              <Spacer size={Spacer.SIZES.md} />
              {currentSelection ===
                RESOLVE_PROPERTY_LINK_OPTIONS.CREATE_NEW_PROPERTY && (
                <>
                  <p className="section-label">
                    Please fill in all the fields below
                  </p>
                  <CreatePropertyInnerFormElements />
                </>
              )}
              {currentSelection ===
                RESOLVE_PROPERTY_LINK_OPTIONS.LINK_EXISTING_PROPERTY && (
                <>
                  <p className="section-label">
                    Please select VERO property for linking
                  </p>
                  <Field
                    as={Select}
                    name="veroPropertyId"
                    id="veroPropertyId"
                    label="Select Property"
                    error={touched.veroPropertyId && errors.veroPropertyId}
                    onChange={(value) => setFieldValue("veroPropertyId", value)}
                    onBlur={() => setFieldTouched("veroPropertyId", true)}
                  >
                    {veroProperties.map((p) => (
                      <Select.Option
                        key={p.id}
                        data-testid={`option-${p.id}`}
                        disabled={Boolean(p.provider)}
                      >
                        {p.name}
                        {p.provider
                          ? " (already synced to External Provider)"
                          : ""}
                      </Select.Option>
                    ))}
                  </Field>
                </>
              )}
            </Modal.Body>
          </YardiAlertResolverWrapper>
        </Modal>
      </Form>
    )}
  </Formik>
);

YardiLinkPropertyResolverModal.propTypes = {
  yardiPropertyName: PropTypes.string,
  currentSelection: PropTypes.oneOf(
    Object.values(RESOLVE_PROPERTY_LINK_OPTIONS)
  ),
  setCurrentSelection: PropTypes.func.isRequired,
  veroProperties: PropTypes.arrayOf(basicPropertyPropType),
  submit: PropTypes.func.isRequired,
  formikRef: refPropType.isRequired,
};

YardiLinkPropertyResolverModal.defaultProps = {
  yardiPropertyName: "",
  currentSelection: undefined,
  veroProperties: [],
};

export default YardiLinkPropertyResolverModal;
