import React from "react";

import { Row, Col } from "antd";
import PropTypes from "prop-types";

import ApplicationStatus from "shared/components/ApplicationStatus";
import { UnderlineLink } from "shared/components/Links";
import Tile from "shared/components/Tile";
import { RegularText } from "shared/components/Typography";
import { keyPropType } from "shared/utils/propTypes";
import { formatDate, getMailToLink } from "shared/utils/ui";

import { NotSubmittedText, LinkEllipsisWrapper } from "./styled";

const ApplicantList = ({ applicants, applicantTypes }) => (
  <>
    {applicants?.length ? (
      applicants.map((applicant, index) => (
        <Tile.Inner
          data-testid={`applicant-${applicant.id}`}
          borderBottom={applicants.length !== index + 1}
          key={applicant.id}
        >
          {applicant.isSubmitted && (
            <Row>
              <Col lg={{ span: 5 }} md={{ span: 8 }}>
                <Tile.Label data-testid="applicant-name">Name</Tile.Label>
                {`${applicant.firstName} ${applicant.lastName}`}
              </Col>
              <Col lg={{ span: 4 }} md={{ span: 6 }}>
                <Tile.Label data-testid="applicant-type">Type</Tile.Label>
                {applicantTypes?.[applicant.applicantType]?.value}
              </Col>
              <Col lg={{ span: 5 }} md={{ span: 6 }}>
                <Tile.Label data-testid="applicant-email">E-mail</Tile.Label>
                <LinkEllipsisWrapper>
                  <UnderlineLink
                    onClick={() => {
                      window.location.href = getMailToLink([applicant.email]);
                    }}
                  >
                    {applicant.email}
                  </UnderlineLink>
                </LinkEllipsisWrapper>
              </Col>
              <Col lg={{ span: 4 }} md={{ span: 6 }}>
                <Tile.Label data-testid="applicant-status">
                  Application Status
                </Tile.Label>
                <ApplicationStatus status={applicant.status} />
              </Col>
              <Col lg={{ span: 4 }} md={{ span: 6 }}>
                <Tile.Label data-testid="submit-date">Submit Date</Tile.Label>
                {formatDate(applicant.submittedAt)}
              </Col>
            </Row>
          )}
          {!applicant.isSubmitted && (
            <NotSubmittedText>
              {`${applicant.firstName} ${applicant.lastName}`} has to submit
              their application
            </NotSubmittedText>
          )}
        </Tile.Inner>
      ))
    ) : (
      <Tile.Inner data-testid="no-applicant" borderBottom key={0}>
        <Row>
          <Col lg={{ span: 5 }} md={{ span: 8 }} xs={{ span: 21 }}>
            <RegularText type="secondary">
              No more applicants on this application
            </RegularText>
          </Col>
        </Row>
      </Tile.Inner>
    )}
  </>
);

ApplicantList.propTypes = {
  applicants: PropTypes.arrayOf(
    PropTypes.shape({
      id: keyPropType.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      incomeRentMultiple: PropTypes.number,
      creditScore: PropTypes.number,
      criminalRecords: PropTypes.bool,
      evictions: PropTypes.bool,
      applicantType: PropTypes.string,
      isSubmitted: PropTypes.bool,
    })
  ),
  applicantTypes: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
};
ApplicantList.defaultProps = {
  applicants: [],
  applicantTypes: {},
};

export default ApplicantList;
