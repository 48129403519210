import React, { ReactNode } from "react";

import DealSetupForm from "manager/components/DealSetupSettings/DealSetupForm";
import { DealSetupAttachmentsModal } from "manager/components/Modal";
import AdditionalQuestionModal from "manager/components/Modal/AdditionalQuestionModal";
import {
  useGetDealSetup,
  useUpdateDealSetup,
} from "manager/hooks/api/dealSetupQueries";
import { PropertySettings } from "manager/interfaces/api";
import { CONTENT_TYPE } from "shared/config/constants";
import useModal from "shared/hooks/useModal";

interface DealSetupFormContainerProps {
  children: ReactNode | ReactNode[];
  footerContent: ReactNode;
  isPropertySettingsLoading: boolean;
  propertySettings: PropertySettings;
}

const DealSetupFormContainer = ({
  children,
  footerContent,
  isPropertySettingsLoading,
  propertySettings,
}: DealSetupFormContainerProps) => {
  const { openModalDialog } = useModal();

  const isPortfolioSettings = propertySettings?.isDealSetupFromPortfolio;

  const { dealSetup, isDealSetupLoading, isDealSetupFetching } =
    useGetDealSetup(propertySettings?.dealSetupId);

  const { updateDealSetup } = useUpdateDealSetup();

  const saveChanges = (
    values: { id: number; data: any },
    resolve: () => void,
    reject: () => void
  ) => {
    updateDealSetup(
      // @ts-ignore
      { id: values.id, dealSetup: values.data },
      { onSuccess: resolve, onError: reject }
    );
    resolve();
  };

  const openQuestionsModal = (context) =>
    openModalDialog(AdditionalQuestionModal, {
      forSettingsLabel: "Property Settings",
      contentType: CONTENT_TYPE.dealSetup,
      ...context,
    });

  const openAttachmentsModal = ({
    items,
    dealSetupId: objectId,
    title,
    newItemProps,
  }) =>
    openModalDialog(DealSetupAttachmentsModal, {
      title,
      items,
      objectId,
      forSettingsLabel: "Property Settings",
      contentType: CONTENT_TYPE.dealSetup,
      newItemProps,
    });

  return (
    // @ts-ignore TODO remove this when DealSetupForm is converted to ts
    <DealSetupForm
      footerContent={footerContent}
      isPropertySettingsLoading={isPropertySettingsLoading}
      propertySettings={propertySettings}
      forSettingsLabel="Property Settings"
      dealSetup={dealSetup}
      loading={isDealSetupLoading}
      fetching={isDealSetupFetching}
      saveChanges={saveChanges}
      openQuestionsModal={openQuestionsModal}
      openAttachmentsModal={openAttachmentsModal}
      disabled={isPortfolioSettings}
    >
      {children}
    </DealSetupForm>
  );
};

export default DealSetupFormContainer;
