import React from "react";

import {
  Container,
  ScoreVeryBad,
  ScoreBad,
  ScoreFair,
  ScoreGood,
  ScoreExcellent,
  Indicator,
} from "./styled";

const scoreMin = 300;
const scoreMax = 850;
const getScorePercentage = (score) =>
  ((score - scoreMin) / (scoreMax - scoreMin)) * 100;

interface CreditScoreChartProps {
  score?: number;
  [key: string]: any;
}

const CreditScoreChart = ({ score, ...props }: CreditScoreChartProps) => (
  <Container title={`Credit score: ${score}`} {...props}>
    <ScoreVeryBad data-testid="score-very-bad" />
    <ScoreBad data-testid="score-bad" />
    <ScoreFair data-testid="score-fair" />
    <ScoreGood data-testid="score-good" />
    <ScoreExcellent data-testid="score-excellent" />
    <Indicator score={getScorePercentage(score)} data-testid="indicator" />
  </Container>
);

export default CreditScoreChart;
