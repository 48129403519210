import React from 'react'
import _ from 'lodash'
import { printDollarsFromCents } from 'shared/utils/dollar-print'
import { buildClassName } from 'shared/utils/vega'
import { ServiceCard, ServiceHeader } from 'shared/components/vega/Service'
import { Timestamp } from 'shared/components/vega/Timestamp'
import LineItems from './LineItems'
import PaymentContainer from './PaymentContainer'

function resolveTitle (payments) {
  if (!_.isEmpty(payments)) {
    if (_.every(payments, { status: 'paid' })) {
      return 'paid'
    }

    if (_.some(payments, { status: 'refunded' })) {
      return 'refunded'
    }

    if (_.some(payments, { status: 'disputed' })) {
      return 'disputed'
    }

    return 'unpaid'
  }
}

const Payments = ({ payments, card = false, minimal = false }) => {
  const title = resolveTitle(payments) || 'no payments required'
  const status = title ? (title === 'paid' ? 'complete' : ((title === 'refunded' || title === 'disputed') ? 'error' : 'in-progress')) : null
  const total = _.sumBy(payments, 'feeAmount')
  const lastPayment = _.last(payments) as any

  if (minimal) {
    return (
      <div className={buildClassName(['vega flex gap small column'])}>
        <span className='vega title small'>{title}</span>
        <LineItems lineItems={payments} />
        <Timestamp timestamp={lastPayment?.updatedAt} />
      </div>
    )
  }

  return (
    <div className='vega container'>
      {card && <ServiceCard
        status={status}
        label='payments'
        value={title}
        timestamp={lastPayment?.updatedAt}
      >
        <div className='vega flex column'>{_.map(payments, (payment) => <LineItems lineItems={payment?.lineItems} key={payment?.id} />)}</div>
      </ServiceCard>}

      {!card && <ServiceHeader
        title={title}
        status={status}
        timestamp={lastPayment?.updatedAt}
      >
        {total > 0 && <span className='vega info amount'>{printDollarsFromCents(total)}</span>}
      </ServiceHeader>}

      {!card && <div className='vega content'>
        {_.isEmpty(payments) && <p>No payments required at this time.</p>}
        {_.map(payments, payment => <PaymentContainer key={payment?.id} payment={payment} />)}
      </div>}
    </div>
  )
}

export default Payments
