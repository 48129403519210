import React from "react";

import ActiveDocumentErrorModal from "admin/components/Modal/ActiveDocumentErrorModal";
import {
  useCompaniesDocumentAssigned,
  useUnassignDocumentFromCompany,
} from "admin/hooks/api";

import useModal from "shared/hooks/useModal";

import { Document } from "./interfaces";
import UnassignDocumentModal from "./UnassignDocumentModal";

interface UnassignDocumentModalContainerProps {
  document: Document;
}

const UnassignDocumentModalContainer = ({
  document,
}: UnassignDocumentModalContainerProps) => {
  const { openModalDialog, closeActiveModalDialog } = useModal();

  const { isLoading, data: companies } = useCompaniesDocumentAssigned({
    documentId: document?.id,
  });

  const onSuccess = () => closeActiveModalDialog();

  const onError = ({ properties, company }) =>
    openModalDialog(ActiveDocumentErrorModal, { properties, company });

  const { unassignDocument } = useUnassignDocumentFromCompany({
    onSuccess,
    onError,
  });

  return (
    <UnassignDocumentModal
      document={document}
      companies={companies}
      isLoading={isLoading}
      unassignDocument={unassignDocument}
    />
  );
};

export default UnassignDocumentModalContainer;
