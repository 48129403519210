import React, { useEffect } from "react";

import { Field, useFormikContext } from "formik";

import { LUMEN_FILTER_TYPE } from "manager/config/lumen.config";
import {
  useGetCreditPublicRecordBankruptcyOptions,
  useGetCreditPublicRecordOptions,
} from "manager/hooks/api";
import { Select } from "shared/components/Form";
import { addDefaultObjectValues, showError } from "shared/utils/forms";

import BaseCondition from "./BaseCondition";
import { commonFilterConditionPropTypes } from "./lumenFilterConditions.config";
import {
  getBaseConditionPath,
  getConditionPropPath,
} from "./lumenFilterConditions.util";

const ConditionPublicRecordType = ({ idx, onDelete }) => {
  const { values, errors, touched, setFieldTouched, setFieldValue } =
    useFormikContext();
  const isBankruptcy = values.type === LUMEN_FILTER_TYPE.BANKRUPTCIES;
  const { creditPublicRecordOptions } = useGetCreditPublicRecordOptions();
  const { creditPublicRecordBankruptcyOptions } =
    useGetCreditPublicRecordBankruptcyOptions();
  const publicRecordTypeProp = "subtype";
  const publicRecordTypeBasePath = getBaseConditionPath(idx);
  const publicRecordTypePropPath = getConditionPropPath(
    idx,
    publicRecordTypeProp
  );
  const items = isBankruptcy
    ? creditPublicRecordBankruptcyOptions
    : creditPublicRecordOptions;

  useEffect(() => {
    if (items?.length) {
      const defaultValues = {
        [publicRecordTypeProp]: items[0].id,
      };
      addDefaultObjectValues(
        values,
        publicRecordTypeBasePath,
        defaultValues,
        setFieldValue
      );
    }
  }, [items]);

  return (
    <BaseCondition conditionLabel="Select subtype:" onDelete={onDelete}>
      <div className="big-select">
        <Field
          name={publicRecordTypePropPath}
          id={publicRecordTypePropPath}
          label="Select Public Record type"
          as={Select}
          error={showError(publicRecordTypePropPath, touched, errors)}
          onChange={(value) => setFieldValue(publicRecordTypePropPath, value)}
          onBlur={() => setFieldTouched(publicRecordTypePropPath, true)}
          dropdownMatchSelectWidth={false}
        >
          {(items || []).map((type) => (
            <Select.Option key={type.id}>{type.value}</Select.Option>
          ))}
        </Field>
      </div>
    </BaseCondition>
  );
};

ConditionPublicRecordType.propTypes = {
  ...commonFilterConditionPropTypes,
};

export default ConditionPublicRecordType;
