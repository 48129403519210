import { useEffect, useRef } from "react";

const useOnUnmountRegistry = () => {
  const registry = useRef([]);

  useEffect(() => {
    return () => {
      registry.current.forEach((cb) => cb());
    };
  }, []);

  const registerOnUnmountAction = (cb) => {
    registry.current.push(cb);
  };

  return {
    registerOnUnmountAction,
  };
};

export default useOnUnmountRegistry;
