import styled from '@emotion/styled'

import { BACKGROUND_CREDIT_CHECK_PROVIDER } from 'manager/pages/ExperianAuth/interfaces'
import type { Application } from 'renter/interfaces/api/application'
import ApplicantInfo from 'shared/components/CreditAndBackgroundCheck/components/ApplicantInfo'
import CreditReport from 'shared/components/CreditAndBackgroundCheck/components/CreditReport'
import CreditScore from 'shared/components/CreditAndBackgroundCheck/components/CreditScore'
import Evictions from 'shared/components/CreditAndBackgroundCheck/components/Evictions'
import ExpiredInfo from 'shared/components/CreditAndBackgroundCheck/components/ExpiredReport'
import PublicRecords from 'shared/components/CreditAndBackgroundCheck/components/PublicRecords'
import StateCriminalRecords from 'shared/components/CreditAndBackgroundCheck/components/StateCriminalRecords'
import { WarningBanner } from './styled'
import Icon from 'shared/components/Icon'
import {
  APPLICATION_COUNTY_CRIMINAL_SEARCH_STATUS,
  APPLICATION_STATUSES,
  colors,
  COLORS,
} from 'shared/config/constants'
import { isManagerApp } from 'shared/utils/auth'

import {
  CountyCriminalRecordsProps,
  CreditScoreSummary,
  CriminalRecordsType,
  EvictionsType,
} from './interfaces'
import { AddressPartial, DealPartial } from './types'

// icon

export const HelpIcon = styled(Icon.Help)`
  display: block;
  width: 16px;
  height: 16px;
  color: ${colors.accent[500]};
`

const defaultCreditScoreSummary: Partial<{
  firstName: string
  middleName: string
  lastName: string
  addresses: AddressPartial[]
}> = {
  firstName: null,
  middleName: null,
  lastName: null,
  addresses: [{ address: null, city: null, state: null, zipcode: null }],
}

type CreditAndBackgroundCheckProps = {
  creditScoreSummary?: CreditScoreSummary
  criminalRecords?: CriminalRecordsType
  hideCriminalRecords: boolean
  isEvictions?: boolean
  evictions?: EvictionsType
  canAccessCountyCriminalRecords?: boolean
  countyCriminalRecords?: CountyCriminalRecordsProps
  countyCriminalSearchStatus?: typeof APPLICATION_COUNTY_CRIMINAL_SEARCH_STATUS
  noSSNreasonToStay?: string
  hasCountyCriminalSearch?: boolean
  hasTuCountyCriminalSearch?: boolean
  deal?: DealPartial | null
  application?: Application | null
}

const N_A = 'N/A'

const CreditAndBackgroundCheck = ({
  creditScoreSummary,
  criminalRecords,
  hideCriminalRecords,
  isEvictions,
  evictions,
  canAccessCountyCriminalRecords,
  countyCriminalRecords,
  countyCriminalSearchStatus,
  noSSNreasonToStay,
  hasCountyCriminalSearch,
  hasTuCountyCriminalSearch,
  deal = null,
  application = null,
}: CreditAndBackgroundCheckProps) => {
  const { firstName, middleName, lastName } =
    creditScoreSummary ?? defaultCreditScoreSummary

  const applicantFullName =
    !!firstName || !!lastName
      ? [firstName, middleName, lastName].filter(Boolean).join(' ')
      : N_A

  const isExpired =
    application?.bccProvider ===
      BACKGROUND_CREDIT_CHECK_PROVIDER.EXPERIAN_CONSUMER_CONNECT &&
    creditScoreSummary?.expired
  const { dealDecisionIsMade, status } = application
  const isSubmitted = APPLICATION_STATUSES.submitted === application.status
  const displayDisclaimerBanner = dealDecisionIsMade && isSubmitted && isExpired && isManagerApp()

  return isExpired ? (
    <>
      {displayDisclaimerBanner && (
        <WarningBanner>
          <Icon.DarkOrangeExclamationHollow width={24} height={24} />
          <span className="banner-message">
            Background and credit report information from a submitted application, once a decision is made, is restricted under our license to that specific application and cannot be used for future leasing decisions.
          </span>
        </WarningBanner>
      )}
      <ExpiredInfo creditScoreSummary={creditScoreSummary} />
    </>
  ) : (
    <>
      <ApplicantInfo
        creditScoreSummary={creditScoreSummary}
        deal={deal}
        applicantFullName={applicantFullName}
        application={application}
      />

      <CreditScore
        creditScoreSummary={creditScoreSummary}
        criminalRecords={criminalRecords}
        hideCriminalRecords={hideCriminalRecords}
        isEvictions={isEvictions}
        evictions={evictions}
        noSSNreasonToStay={noSSNreasonToStay}
      />

      <CreditReport
        creditScoreSummary={creditScoreSummary}
        applicantFullName={applicantFullName}
      />

      <PublicRecords creditScoreSummary={creditScoreSummary} />

      <StateCriminalRecords
        canAccessCountyCriminalRecords={canAccessCountyCriminalRecords}
        countyCriminalRecords={countyCriminalRecords}
        countyCriminalSearchStatus={countyCriminalSearchStatus}
        criminalRecords={criminalRecords}
        hasCountyCriminalSearch={hasCountyCriminalSearch}
        hasTuCountyCriminalSearch={hasTuCountyCriminalSearch}
        hideCriminalRecords={hideCriminalRecords}
      />

      <Evictions evictions={evictions} isEvictions={isEvictions} />
    </>
  )
}

export default CreditAndBackgroundCheck
