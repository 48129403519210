import React from "react";

import { useFormikContext } from "formik";

import { useGetSurvey, useCreateSurvey } from "manager/hooks/api";
import { DealSetup } from "manager/interfaces/api";
import { CONTENT_TYPE } from "shared/config/constants";
import { usePermissions } from "shared/hooks";
import { useCountyCriminalSearchSyncOptions } from "shared/hooks/api";
import { SurveyType } from "shared/interfaces/api";

import { verificationOfResidency } from "./constants";
import ScreeningForApplicantsAndOccupants from "./ScreeningForApplicantsAndOccupants";

interface ScreeningForApplicantsAndOccupantsContainerProps {
  loading?: boolean;
  disabled?: boolean;
  dealSetup: DealSetup;
  forSettingsLabel: string;
}

const ScreeningForApplicantsAndOccupantsContainer = ({
  loading,
  disabled,
  dealSetup,
  forSettingsLabel,
}: ScreeningForApplicantsAndOccupantsContainerProps) => {
  const { values } = useFormikContext();

  const {
    isArgyleEnabled,
    isPersonaEnabled,
    isCountyCriminalSearchEnabled,
    isVerificationOfResidencyEnabled,
    isConditionallyProceedEnabled,
  } = usePermissions();
  const {
    countyCriminalSearchSyncOptions,
    isCountyCriminalSearchSyncOptionsLoading,
  } = useCountyCriminalSearchSyncOptions();

  const { survey, isSurveyLoading } = useGetSurvey(
    {
      dealSetupId: dealSetup?.id,
      surveyType: SurveyType.VOR,
    },
    {
      enabled:
        isVerificationOfResidencyEnabled && values[verificationOfResidency],
    }
  );
  const { createSurvey } = useCreateSurvey();

  const toggleVor = (checked: boolean, setFieldValue: Function) => {
    if (checked && !survey) {
      createSurvey(
        {
          objectId: dealSetup?.id,
          contentType: CONTENT_TYPE.dealSetup,
          surveyType: SurveyType.VOR,
          surveyQuestions: [],
        },
        {
          onSuccess: () => {
            setFieldValue(verificationOfResidency, checked);
          },
        }
      );
    } else {
      setFieldValue(verificationOfResidency, checked);
    }
  };

  return (
    <ScreeningForApplicantsAndOccupants
      isArgyleEnabled={isArgyleEnabled}
      isPersonaEnabled={isPersonaEnabled}
      isCountyCriminalSearchEnabled={isCountyCriminalSearchEnabled}
      isVerificationOfResidencyEnabled={isVerificationOfResidencyEnabled}
      isConditionallyProceedEnabled={isConditionallyProceedEnabled}
      isCountyCriminalSearchSyncOptionsLoading={
        isCountyCriminalSearchSyncOptionsLoading
      }
      isSurveyLoading={isSurveyLoading}
      forSettingsLabel={forSettingsLabel}
      loading={loading}
      disabled={disabled}
      countyCriminalSearchSyncOptions={countyCriminalSearchSyncOptions}
      vorSurvey={survey}
      toggleVor={toggleVor}
    />
  );
};

export default ScreeningForApplicantsAndOccupantsContainer;
