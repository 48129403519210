import React, { useEffect, useState } from "react";

import Icon from "shared/components/Icon";
import { ActionLink } from "shared/components/Links";
import Spacer from "shared/components/Spacer";

const getShortContent = (text, limit) => text.slice(0, limit);

interface OverflowTextProps {
  content?: string;
  maxSymbolsCount?: number;
  TextElement?: any;
}

const OverflowText = ({
  maxSymbolsCount = 150,
  content = "",
  TextElement = (children) => <TextElement>{children}</TextElement>,
}: OverflowTextProps) => {
  const [preview, setPreviewContent] = useState("");
  const [isCollapsed, setCollapsed] = useState<boolean>();
  const [isContentOverflowed, setContentOverflowed] = useState<boolean>();

  const linkLabel = isCollapsed ? "Show More" : "Show Less";

  const switchVisibility = () => {
    const isNextCollapsed = !isCollapsed;
    setPreviewContent(
      isNextCollapsed ? getShortContent(content, maxSymbolsCount) : content
    );
    setCollapsed(isNextCollapsed);
  };

  useEffect(() => {
    const shortContent = getShortContent(content, maxSymbolsCount);
    const isOverflowed = shortContent.length < content.length;
    setContentOverflowed(isOverflowed);
    setPreviewContent(shortContent);
    setCollapsed(isOverflowed);
  }, [content, maxSymbolsCount]);

  return (
    preview && (
      <>
        <TextElement>
          {preview}
          {isCollapsed && "..."}
        </TextElement>
        {isContentOverflowed && (
          <>
            <Spacer size={Spacer.SIZES.xs} />
            <ActionLink
              onClick={switchVisibility}
              Icon={
                isCollapsed
                  ? Icon.ChevronDownGreenIcon
                  : Icon.ChevronUpGreenIcon
              }
              right
              dashed
            >
              {linkLabel}
            </ActionLink>
          </>
        )}
      </>
    )
  );
};

export default OverflowText;
