import React from "react";

import { FieldArray, useFormikContext } from "formik";
import PropTypes from "prop-types";

import {
  LUMEN_FILTER_CONDITION_SUB_TYPE,
  LUMEN_FILTER_CONDITION_TYPE,
} from "manager/config/lumen.config";

import ConditionEvictionCaseType from "../NewCollectionsFilterModal/components/ConditionEvictionCaseType";

import AddConditionPanel from "./AddConditionPanel";
import ConditionAmount from "./ConditionAmount";
import ConditionCriminalRecordSeverity from "./ConditionCriminalRecordSeverity";
import ConditionJudgementAmount from "./ConditionJudgementAmount";
import ConditionKeywords from "./ConditionKeywords";
import ConditionNumberOfRecords from "./ConditionNumberOfRecords";
import ConditionPublicRecordType from "./ConditionPublicRecordType";
import ConditionTime from "./ConditionTime";
import { FilterConditionsContent } from "./styled";

const getConditionComponent = (conditionType, conditionSubType) => {
  switch (conditionType) {
    case LUMEN_FILTER_CONDITION_TYPE.TIME_PERIOD:
      return ConditionTime;
    case LUMEN_FILTER_CONDITION_TYPE.AMOUNT:
      return ConditionAmount;
    case LUMEN_FILTER_CONDITION_TYPE.PUBLIC_RECORD_TYPE:
      return ConditionPublicRecordType;
    case LUMEN_FILTER_CONDITION_TYPE.KEYWORDS:
      if (conditionSubType === LUMEN_FILTER_CONDITION_SUB_TYPE.EVICTIONS) {
        return ConditionEvictionCaseType;
      }
      return ConditionKeywords;
    case LUMEN_FILTER_CONDITION_TYPE.CRIMINAL_RECORD_SEVERITY:
      return ConditionCriminalRecordSeverity;
    case LUMEN_FILTER_CONDITION_TYPE.NUMBER_OF_RECORDS:
      return ConditionNumberOfRecords;
    case LUMEN_FILTER_CONDITION_TYPE.JUDGEMENT_AMOUNT:
      return ConditionJudgementAmount;
    default:
      return null;
  }
};

const LumenFilterConditions = ({ lumenSection }) => {
  const { values } = useFormikContext();
  const hasConditions = !!values?.conditions?.length;

  return (
    <FilterConditionsContent>
      <FieldArray
        name="conditions"
        render={({ remove, push }) => (
          <>
            {hasConditions && (
              <div className="conditions-container">
                {(values?.conditions || []).map((condition, idx, array) => {
                  const { conditionType, conditionSubType } = condition;
                  const FilterCondition = getConditionComponent(
                    conditionType,
                    conditionSubType
                  );
                  const key = idx + 1;
                  const isLast = idx === array.length - 1;
                  return (
                    <React.Fragment key={key}>
                      <FilterCondition idx={idx} onDelete={() => remove(idx)} />
                      {!isLast && <div className="and-separator">and</div>}
                    </React.Fragment>
                  );
                })}
              </div>
            )}
            <AddConditionPanel push={push} lumenSection={lumenSection} />
          </>
        )}
      />
    </FilterConditionsContent>
  );
};

LumenFilterConditions.propTypes = {
  lumenSection: PropTypes.string.isRequired,
};

export default LumenFilterConditions;
