import sharedApi from "shared/lib/api";

import companyApi from "./companyApi";
import documentsApi from "./documentsApi";
import documentFieldsApi from "./fieldsApi";
import tagsApi from "./tagsApi";

const api = {
  ...sharedApi,
  ...companyApi,
  ...documentsApi,
  ...documentFieldsApi,
  ...tagsApi,
};

export default api;
