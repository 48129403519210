import React from "react";

import isEmpty from "lodash/isEmpty";

import { UnderlineLink } from "shared/components/Links";

import { emptyOccupant } from "./config";
import { AddOccupantsLinkProps } from "./interfaces";

export const AddOccupantsLink = ({
  checkValidation,
  addOccupant,
}: AddOccupantsLinkProps) => {
  const onClick = async () => {
    const res = await checkValidation();
    if (isEmpty(res)) {
      addOccupant(emptyOccupant);
    }
  };

  return (
    <UnderlineLink onClick={onClick} dashed data-testid="add-occupants-link">
      Invite More
    </UnderlineLink>
  );
};
