import styled from "@emotion/styled";

import { ActionLink } from "shared/components/Links";
import { MEDIA_QUERIES, COLORS } from "shared/config/constants";

const TitleWrapper = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "10px 24px",
  backgroundColor: `${COLORS.filterBar.default.background}`,
  borderBottom: `1px solid ${COLORS.borders}`,
  "& > div": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
});

const Title = styled.p({
  fontSize: 22,
  marginRight: 15,
  marginBottom: 0,
});

const SearchWrapper = styled.div({
  [MEDIA_QUERIES.w.ltSm]: {
    display: "none",
  },
});

const FilterAction = styled(ActionLink)({
  border: `1px solid ${COLORS.button.secondary.default.border}`,
  borderRadius: "3px",
  color: `${COLORS.textColorPrimary}`,
  padding: "8px 12px",
});

const PropertyIntegrationMarkContainer = styled.div`
  opacity: ${({ opacity }) => opacity ?? 1};
`;

export {
  TitleWrapper,
  Title,
  SearchWrapper,
  FilterAction,
  PropertyIntegrationMarkContainer,
};
