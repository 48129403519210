import _ from 'lodash'
import { useState } from 'react'
import { DEAL_CATEGORY_TYPES, DEAL_CATEGORY_LABEL_INFO } from 'manager/config/constants'
import { printDollarsFromCents } from 'shared/utils/dollar-print'
import TablePageCardHeader from 'shared/components/TablePageCardHeader'
import { DEFAULT_DATES, parseQuery } from 'shared/components/vega/SearchFilter'
import { Timestamp } from 'shared/components/vega/Timestamp'
import { LumenV2Header } from 'shared/components/vega/LumenV2'
import ApplicantHeader from 'shared/components/vega/ApplicantSummaryV2/ApplicantHeader'
import LineItems from 'shared/components/vega/Payments/LineItems'
import { onRowClick, splitAndJoin, Location, buildCategoryItems } from './utils'
import SearchList from './SearchList'

const Applicant = ({ applicant, payments }) => {
  return (
    <div className='vega flex gap small'>
      <ApplicantHeader applicant={applicant} minimal />
      <LineItems lineItems={payments} minimal />
    </div>
  )
}

const Applicants = ({ application }) => {
  return (
    <div className='vega flex column'>
      {(_.chain(application).get('applicant') as any).map(a => (
        <Applicant
          applicant={a}
          payments={(_.chain(application).get('payment') as any).filter({ applicant: _.replace(a?.id, 'vrn:applicant::', '') }).value()}
          key={a?.id}
        />
      )).value()}
    </div>
  )
}

const Lumen = ({ application }) => {
  const lumenRun = (_.chain(application).get('lumen-run') as any).orderBy('timestamp', 'desc').first().value()
  return (lumenRun && !lumenRun?.error) ? <LumenV2Header lumenRun={lumenRun} minimal /> : null
}

const Status = ({ application, statusToCategoryLabel }) => {
  return (
    <>
      <a href={`/deals/${application?.identifier}`} className='vega title small link block'>{statusToCategoryLabel(application?.status)}</a>
      <Timestamp timestamp={application?.updatedAt} small />
    </>
  )
}

export const ApplicationList = ({ categories, categoryToStatus, statusToCategoryLabel }) => {
  const [category, setCategory] = useState('pending')

  return (
    <SearchList
      indexes={[
        'application',
        'property.property',
        'unit.unit',
        'applicant.application',
        'lumen-run.application',
        'payment.application'
      ]}
      defaultSearchIndex='applicant'
      aggs={['application.status.keyword']}
      initialSort='-application.createdAt'
      maxHeight='calc(100vh - 30rem)'
      resolveFilter={filter => _.omitBy({
        'application.type': 'SCREENING',
        'application.status': splitAndJoin(categoryToStatus(category)),
        'application.createdAt.gte': (_.first(filter?.dates || DEFAULT_DATES) as any)?.toISOString(),
        'application.createdAt.lte': (_.last(filter?.dates || DEFAULT_DATES) as any)?.toISOString(),
        ...parseQuery(filter?.query, 'applicant')
      }, v => _.isNil(v) || (_.isString(v) && _.isEmpty(v)))}
      columns={[
        {
          title: 'location',
          sort: 'application.location.keyword',
          size: '1fr',
          render: application => <Location application={application} />
        },
        {
          title: 'applicants',
          size: 'minmax(20%, max-content)',
          render: application => <Applicants application={application} />
        },
        {
          title: 'date created',
          sort: 'application.createdAt',
          size: '1fr',
          render: application => <Timestamp timestamp={application.createdAt} />
        },
        {
          title: 'gross rent',
          sort: 'application.rent',
          size: '1fr',
          render: application => printDollarsFromCents(application.rent)
        },
        {
          title: 'application status',
          sort: 'application.status.keyword',
          size: 'max-content',
          render: application => <Status application={application} statusToCategoryLabel={statusToCategoryLabel} />
        },
        {
          title: 'lumen result',
          size: '1fr',
          render: application => <Lumen application={application} />
        }
      ]}
      onRowClick={application => onRowClick(`/deals/${application?.identifier}`)}
      quicklinks={{
        archived: 'application.archived:true',
        paid: 'payment.status:paid'
      }}
      deps={[category]}
      header={(currentPage, loading, clearSearchResults, results, aggregations, next, searchError, resolvedFilter) => (
        <div className='vega content neutral'>
          <TablePageCardHeader
            categoryItems={buildCategoryItems(aggregations, categories)}
            categoryTypes={DEAL_CATEGORY_TYPES}
            categoryLabelInfo={DEAL_CATEGORY_LABEL_INFO}
            activeItem={category}
            isCountsLoading={loading}
            onChange={c => {
              clearSearchResults()
              setCategory(c)
            }}
          />
        </div>
      )}
    />
  )
}
