import React, { useState, useEffect } from "react";

import { Input } from "antd";

import axios from "axios";
import isEmpty from "lodash/isEmpty";

import FlexContainer from "shared/components/FlexContainer";
import { Modal } from "shared/components/Modal";
import { RegularText } from "shared/components/Typography";
import API_ROUTES, { API_URL } from "shared/config/apiRoutes";
import { NOTIFICATIONS } from "shared/config/constants";
import { openNotification } from "shared/utils/ui";

import {
  InputContainer,
  CompanyLogoImg,
  EditLink,
  ListWrapper,
} from "./styled";

const ALLOWED_EMPLOYER_STATUSES = ["issues", "healthy"];
const ALLOWED_EMPLOYER_QUERY = `status=${ALLOWED_EMPLOYER_STATUSES.join(",")}`;

const ArgyleEmployerSearch = (companyName?: { companyName?: string }) => {
  const [data, setData] = useState([]);
  const [showInput, setShowInput] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [noResultsMessage, setNoResultsMessage] = useState(null);
  const { companyName: company } = companyName || {};

  const handleSearch = () => {
    if (searchValue) {
      axios
        .get(
          `${API_URL}${API_ROUTES?.argyleEmployerSearch}?q=${searchValue}&${ALLOWED_EMPLOYER_QUERY}`
        )
        .then((res) => {
          setData(res?.data?.results);
          if (isEmpty(res?.data?.results)) {
            setNoResultsMessage(
              "There are no employers matching the provided keywords."
            );
          } else {
            setNoResultsMessage(null);
          }
        })
        .catch((err) => {
          if (err) {
            openNotification(
              "Something went wrong. Please try again later.",
              NOTIFICATIONS.error
            );
          }
        });
    } else {
      setData([]);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value?.length > 0) {
      setSearchValue(value);
    } else {
      // Clear search results
      setData([]);
    }
  };
  useEffect(() => {
    if (company) {
      setSearchValue(company);
    }
  }, [company]);

  useEffect(() => {
    if (
      !showInput &&
      company &&
      searchValue?.length > 0 &&
      company === searchValue
    ) {
      handleSearch();
    }
  }, [company, searchValue, showInput]);

  const handleEdit = () => {
    setSearchValue("");
    setData([]);
    setShowInput(true);
  };

  const options =
    data?.map((d) => (
      <div key={d.name}>
        <FlexContainer alignItems="center" className="list-item">
          <CompanyLogoImg src={d.logoUrl} alt={d.name} />
          {d.name}
        </FlexContainer>
      </div>
    )) || [];

  return (
    <Modal
      title="VEROLynk Employer Lookup"
      subtitle="Search an employer to confirm its availability in VERO. After changing the employer name, press enter to perform a new search."
      showSubmitButton={false}
      submit={handleSearch}
      showCancelLink={false}
    >
      {!showInput && (
        <>
          <InputContainer className="edit">
            <RegularText className="font-size-17" strong>
              Employer name:
            </RegularText>{" "}
            <RegularText className="margin-left-10 font-size-17">
              {" "}
              {company}{" "}
            </RegularText>
            <EditLink onClick={handleEdit}> Edit</EditLink>
          </InputContainer>
          {!isEmpty(data) ? (
            <ListWrapper>{options}</ListWrapper>
          ) : (
            <ListWrapper className="text">{noResultsMessage}</ListWrapper>
          )}
        </>
      )}
      {showInput && (
        <>
          <InputContainer className="no-edit">
            <Input
              id="argyleEmployerSearchField"
              name="argyleEmployerSearchField"
              placeholder="Search for employer"
              defaultValue={searchValue || undefined}
              onChange={handleChange}
              onPressEnter={() => handleSearch()}
              allowClear
            />
          </InputContainer>
          {!isEmpty(data) ? (
            <ListWrapper>{options}</ListWrapper>
          ) : (
            <ListWrapper className="text">{noResultsMessage}</ListWrapper>
          )}
        </>
      )}
    </Modal>
  );
};

export default ArgyleEmployerSearch;
