import React from "react";

import { Modal } from "shared/components/Modal";

import {
  ModalSubtitle,
  ModalContent,
  ModalTextTitle,
  ModalText,
} from "./styled";

const VeroFieldTypeInfoModal = () => {
  return (
    <Modal
      width="700px"
      title="VERO Field Type"
      closeOnSubmit={false}
      subtitle={<ModalSubtitle>SELECT THE PROPER FIELD TYPE</ModalSubtitle>}
      showFooter={false}
    >
      <Modal.Body>
        <ModalContent>
          <ModalTextTitle>MANUAL</ModalTextTitle>
          <ModalText>
            Manual type allows the user to enter a custom response or amount
            into the section.
          </ModalText>
          <ModalTextTitle>Smart (VERo Priority)</ModalTextTitle>
          <ModalText>
            Smart type questions will allow sections to be autofilled with data
            already stored in the system. Information within{" "}
            <strong>VERO</strong> will take priority over any data in Bluemoon.
          </ModalText>
          <ModalTextTitle>smart (Bluemoon Priority)</ModalTextTitle>
          <ModalText>
            Smart type questions will allow sections to be autofilled with data
            already stored in the system. Information within{" "}
            <strong>Bluemoon</strong> will take priority over any data in VERO.
          </ModalText>
        </ModalContent>
      </Modal.Body>
    </Modal>
  );
};

export default VeroFieldTypeInfoModal;
