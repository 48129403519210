import React from "react";

import DisputeModal from "./DisputeModal";

interface DisputeModalProps {
  submit: () => void;
}

const DisputeModalContainer = ({ submit }: DisputeModalProps) => {
  return <DisputeModal title="Dispute contact info" submit={submit} />;
};

export default DisputeModalContainer;
