import React, { Key, useEffect, useState } from "react";

import type { TableSize } from "antd/lib/table/interface";

import Icon from "shared/components/Icon";
import MobileTable from "shared/components/Table/MobileTable";
import Placeholder from "shared/components/Table/Placeholder";
import { BREAKPOINT_TYPES } from "shared/config/constants";
import withBreakpoint from "shared/hocs/withBreakpoint";
import { filterOnClick } from "shared/utils/ui";

import ExpandRowIcon from "../ExpandRowIcon";

import { EmptyText, StyledTable } from "./styled";
import {
  buildColumns,
  buildMobileRows,
  buildRows,
  filterCheckboxActions,
} from "./utils";

export interface TableProps {
  id?: any;
  columns: {
    title: any;
    dataIndex: string;
    key?: Key;
    width?: number | string;
    colSpan?: number;
    [key: string]: any;
  }[];
  rows: {
    key?: Key;
    values: any[];
    primaryAction?: any;
    starButtonIsActive?: boolean;
    viewed?: boolean;
    inlinePrimaryAction?: any;
    inlinePrimaryActionSpan?: number;
    disabled?: boolean;
  }[];
  rowKey?: string;
  components?: any;
  primaryAction?: any;
  onRowClick?: (key, event) => void;
  onRowSelection?: (_keys: any) => void;
  starButtonOnClick?: () => void;
  dropdownItems?:
    | boolean
    | {
        key?: Key;
        label: any;
        onClick: () => void;
      }[];
  showActionColumn?: boolean;
  checkboxActions?: {
    key: Key;
    label: string;
    onSelect: (values?: any) => void;
    singleSelectOnly?: boolean;
    primary?: boolean;
    icon?: any;
  }[];
  searching?: boolean;
  breakpoint?: any;
  columnBreakpoints: {
    xs?: {
      column1: string;
      column2: string;
      column3?: string;
      showPrimaryAction?: boolean;
      showActionColumn?: boolean;
    };
    sm?: string[];
    md?: string[];
    lg?: string[];
    [key: string]: any;
  };
  expandable?: string[];
  alt?: boolean;
  placeholder?: {
    Icon: any;
    label: string;
  };
  pagination?: boolean | any;
  onChange?: (() => void) | any;
  indent?: boolean;
  noBorderBottom?: boolean;
  loading?: boolean;
  noClickableRows?: boolean;
  size?: TableSize;
  showColumnTitles?: boolean;
  /* Expandable rows props antd */
  onExpand?: () => void;
  getOtherRowEvent?: () => void;
  expandedRowKeys?: any[];
  expandIcon?: any;
  expandedRowRender?: (() => void) | any;
  expandIconColumnIndex?: number;
  expandedRowClassName?: () => void;
  withBorder?: boolean;
  scroll?: {
    y?: number;
    x?: number;
  };
}

const Table = ({
  columns,
  rows,
  breakpoint = undefined,
  columnBreakpoints,
  expandable = undefined,
  starButtonOnClick = undefined,
  dropdownItems = undefined,
  showActionColumn = false,
  onRowClick = undefined,
  checkboxActions = undefined,
  searching = false,
  alt = false,
  placeholder: placeholderProp,
  pagination = undefined,
  onChange = undefined,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onRowSelection = (_keys) => {},
  indent = false,
  noBorderBottom = false,
  loading = false,
  noClickableRows = false,
  onExpand = undefined,
  getOtherRowEvent = undefined,
  expandIcon = undefined,
  expandedRowRender = undefined,
  expandIconColumnIndex = undefined,
  expandedRowClassName = undefined,
  withBorder = false,
  size = undefined,
  expandedRowKeys = undefined,
  ...otherProps
}: TableProps) => {
  const mobileSecondFieldLabel = columns.filter(
    (col) => col.dataIndex === columnBreakpoints.xs.column2
  )[0]?.title;
  const mobileThirdFieldLabel = columns.filter(
    (col) => col.dataIndex === columnBreakpoints.xs.column3
  )[0]?.title;
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const filteredCheckboxActions = checkboxActions
    ? filterCheckboxActions(checkboxActions, selectedRowKeys.length)
    : undefined;

  const onRowSelectionChange = (keys) => {
    setSelectedRowKeys(keys);
    if (onRowSelection) {
      onRowSelection(keys);
    }
  };

  const rowSelection = checkboxActions
    ? {
        selectedRowKeys,
        onChange: onRowSelectionChange,
        hideDefaultSelections: true,
        selections: filteredCheckboxActions.map((action) => ({
          key: action.key,
          text: action.label,
          onSelect: () => action.onSelect(selectedRowKeys),
        })),
      }
    : undefined;

  const placeholder = (
    <Placeholder
      type={searching ? Placeholder.TYPES.search : Placeholder.TYPES.noData}
      Icon={placeholderProp.Icon}
      label={placeholderProp.label}
    />
  );

  const handleRowClick = (key) =>
    filterOnClick((event) => onRowClick(key, event));

  const generateRowClassName = ({ key }) => {
    const classes = [];
    const row = rows.filter((r) => r.key === key)[0];

    if (row.viewed) classes.push("row-viewed");
    if (row.disabled) classes.push("disabled-row");

    return classes.join(" ");
  };

  const onRow = (record) => {
    if (noClickableRows) {
      return undefined;
    }
    if (getOtherRowEvent) {
      return {
        // @ts-ignore
        ...getOtherRowEvent(record),
        onClick: handleRowClick(record.key),
      };
    }
    return {
      onClick: handleRowClick(record.key),
    };
  };

  useEffect(() => {
    // NOTE: cleaning up previously selected rows that are not available anymore
    if (!loading && selectedRowKeys?.length) {
      const availableRowKeysSet = new Set(rows.map((k) => k.key));
      const updatedSelectedRowKeys = selectedRowKeys.filter((k) =>
        availableRowKeysSet.has(k)
      );
      if (selectedRowKeys?.length !== updatedSelectedRowKeys?.length) {
        setSelectedRowKeys(updatedSelectedRowKeys);
      }
    }
  }, [rows, loading]);

  return (
    <>
      {breakpoint === BREAKPOINT_TYPES.xs ? (
        // @ts-ignore
        <MobileTable
          data-testid="mobile-table"
          expandable={expandable}
          rows={buildMobileRows({
            rows,
            columns,
            columnBreakpoints,
            // @ts-ignore
            selectedRowKeys,
            expandable,
          })}
          secondFieldLabel={mobileSecondFieldLabel}
          thirdFieldLabel={mobileThirdFieldLabel}
          starButtonOnClick={starButtonOnClick}
          dropdownItems={dropdownItems}
          onRowClick={!noClickableRows ? handleRowClick : undefined}
          selectedRowKeys={selectedRowKeys}
          onRowSelectionChange={onRowSelectionChange}
          checkboxActions={
            checkboxActions ? filteredCheckboxActions : undefined
          }
          placeholder={placeholder}
          pagination={!loading && pagination}
          loading={loading}
          noClickableRows={noClickableRows}
          withBorder={withBorder}
          {...(expandedRowKeys ? { expandedRowKeys } : {})}
          {...otherProps}
        />
      ) : (
        <StyledTable
          alt={alt}
          data-testid="default-table"
          dataSource={buildRows({
            rows,
            columns,
            // @ts-ignore
            columnBreakpoints,
            dropdownItems,
            starButtonOnClick,
          })}
          columns={buildColumns({
            columns,
            breakpoint,
            columnBreakpoints,
            tableHasPrimaryAction: showActionColumn,
            tableHasDropdownItems: dropdownItems !== undefined,
            tableHasStarButtons: starButtonOnClick !== undefined,
            tableHasExpandButton: Boolean(expandedRowRender),
          })}
          onRow={onRow}
          rowClassName={generateRowClassName}
          rowSelection={rowSelection}
          locale={
            loading ? { emptyText: <EmptyText /> } : { emptyText: placeholder }
          }
          pagination={pagination}
          onChange={onChange}
          indent={indent}
          noBorderBottom={noBorderBottom}
          noClickableRows={noClickableRows}
          loading={
            loading
              ? {
                  indicator: (
                    <Icon.LoadingSpinnerGreenLargeIcon data-testid="loading" />
                  ),
                }
              : false
          }
          expandIcon={
            Boolean(expandedRowRender) && (expandIcon || ExpandRowIcon)
          }
          onExpand={onExpand}
          expandIconColumnIndex={expandIconColumnIndex}
          expandedRowRender={expandedRowRender}
          expandIconAsCell={false}
          // @ts-ignore
          expandedRowClassName={expandedRowClassName}
          withBorder={withBorder}
          size={size}
          {...(expandedRowKeys ? { expandedRowKeys } : {})}
          {...otherProps}
        />
      )}
    </>
  );
};

export default withBreakpoint(Table);
