import React from "react";

import styled from "@emotion/styled";
import { Typography } from "antd";

import { COLORS } from "shared/config/constants";

const { Text, Title } = Typography;

const lightColor = {
  color: COLORS.lightPurple,
};

const standardTextProps = {
  fontWeight: 400,
  color: COLORS.primary,
};

const disabledColor = {
  color: COLORS.disabledText,
};

const weakTextProps = {
  fontWeight: 300,
};

const strongTextProps = {
  fontWeight: 500,
};

const extraStrongTextProps = {
  fontWeight: 600,
};

const italicTextProps = {
  fontStyle: 'italic',
}

const underlineTextProps = {
  textDecoration: 'underline',
}

const getTextStylesFn =
  (fontSize) =>
  ({
    light = false,
    disabled = false,
    strong = false,
    weak = false,
    extraStrong = false,
    italic = false,
    underline = false,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    className = undefined,
  }: {
    light?: boolean;
    disabled?: boolean;
    weak?: boolean;
    extraStrong?: boolean;
    strong?: boolean;
    italic?: boolean;
    underline?: boolean
    className?: string;
  }) => ({
    fontSize,
    ...standardTextProps,
    ...(light && lightColor),
    ...(disabled && disabledColor),
    ...(weak && weakTextProps),
    ...(strong && strongTextProps),
    ...(extraStrong && extraStrongTextProps),
    ...(italic && italicTextProps),
    ...(underline && underlineTextProps),
  });

const getTitleStylesFn =
  (fontSize: string | number, lineHeight?: string | number) =>
  ({
    light,
    strong,
    extraStrong,
    weak,
  }: {
    light?: boolean;
    weak?: boolean;
    extraStrong?: boolean;
    strong?: boolean;
  }) => ({
    fontSize,
    lineHeight: lineHeight || "130%",
    "&.ant-typography": {
      color: COLORS.primary,
      fontSize,
      ...(light && lightColor),
      ...(strong && strongTextProps),
      ...(extraStrong && extraStrongTextProps),
      ...(weak && weakTextProps),
    },
  });

const WeakText = styled.span<{ light?: boolean }>(({ light }) => ({
  ...weakTextProps,
  ...(light && lightColor),
}));

const StrongText = styled.span({
  ...strongTextProps,
});

const ExtraStrongText = styled.span({
  ...extraStrongTextProps,
});

const ExtraSmallText = styled.span(getTextStylesFn('8px'))

const SmallText = styled.span(getTextStylesFn('12px'))

const SmallErrorText = styled(SmallText)({
  color: COLORS.input.invalid.message,
  display: "block",
  marginTop: "3px",
  ":first-letter": {
    textTransform: "uppercase",
  },
});

const RegularText = styled(
  React.forwardRef(
    (
      {
        // eslint-disable-next-line react/prop-types,@typescript-eslint/no-unused-vars
        light,
        // eslint-disable-next-line react/prop-types,@typescript-eslint/no-unused-vars
        weak,
        // eslint-disable-next-line react/prop-types,@typescript-eslint/no-unused-vars
        strong,
        // eslint-disable-next-line react/prop-types,@typescript-eslint/no-unused-vars
        extraStrong,
        className,
        ...props
      }: {
        light?: boolean;
        weak?: boolean;
        strong?: boolean;
        extraStrong?: boolean;
        className?: string;
        [key: string]: any;
      },
      ref
    ) => (
      /** Note: setContentRef should be replaced with ref in ANTD 4 */
      // @ts-ignore
      <Text setContentRef={ref} className={className} {...props} />
    )
  )
)(getTextStylesFn("15px"));

// TODO (POD1-186): implement typography from the design system
const Title3 = styled(({ ...props }) => <Title level={4} {...props} />)(
  getTitleStylesFn("15px", "150%")
);

// TODO (V2-2229): use level={5} when AntD is upgraded
const SmallTitle = styled(({ ...props }) => <Title level={4} {...props} />)(
  getTitleStylesFn("17px")
);

const MediumTitle = styled(({ ...props }) => <Title level={4} {...props} />)(
  getTitleStylesFn("22px")
);

const LargeTitle = styled(({ ...props }) => <Title level={2} {...props} />)(
  getTitleStylesFn("28px")
);

const LightText = styled.span({
  ...lightColor,
});

export {
  WeakText,
  StrongText,
  ExtraStrongText,
  ExtraSmallText,
  SmallText,
  SmallErrorText,
  RegularText,
  Title3,
  SmallTitle,
  MediumTitle,
  LargeTitle,
  LightText,
  extraStrongTextProps,
};
