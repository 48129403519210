import React from "react";

import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";

import { SelectLabelContainer } from "shared/components/Form";
import Icon from "shared/components/Icon";
import LargeLoader from "shared/components/LargeLoader";
import { Modal } from "shared/components/Modal";
import Placeholder from "shared/components/Table/Placeholder";
import { refPropType } from "shared/utils/propTypes";

import { YardiAlertResolverWrapper } from "./styled";

const YardiGenericListDropdownsResolverModal = ({
  title,
  subtitle,
  message,
  selectPlaceholder,
  items,
  idProp,
  labelProp,
  submit,
  formikRef,
  isLoading,
  PlaceholderIcon,
  placeholderText,
  validationMessage,
}) => {
  const validationSchema = Yup.object().shape({
    objects: Yup.array().of(
      Yup.object().shape({
        linkedId: Yup.string().nullable().required(validationMessage),
      })
    ),
  });

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{}}
      onSubmit={submit}
      innerRef={formikRef}
    >
      {({ values, submitForm, isSubmitting, isValid }) => (
        <Form>
          <Modal
            title={title}
            subtitle={subtitle}
            submitButtonLabel="CONFIRM"
            submitButtonDisabled={!isValid}
            submit={submitForm}
            submitting={isSubmitting}
          >
            <Modal.Body>
              <LargeLoader showLoader={isLoading} />

              {!isLoading && !values?.objects?.length && (
                <Placeholder
                  type={Placeholder.TYPES.noData}
                  label={placeholderText}
                  Icon={PlaceholderIcon}
                />
              )}
              {!isLoading && !!values?.objects?.length && (
                <YardiAlertResolverWrapper>
                  <p className="section-label">{message}</p>
                  {(values?.objects || []).map((obj, idx) => (
                    <SelectLabelContainer
                      key={obj.id}
                      inputDescription={obj.inputDescription}
                      formPath={`objects[${idx}].linkedId`}
                      selectPlaceholder={selectPlaceholder}
                      idProp={idProp}
                      labelProp={labelProp}
                      items={items}
                    />
                  ))}
                </YardiAlertResolverWrapper>
              )}
            </Modal.Body>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

YardiGenericListDropdownsResolverModal.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.node,
  message: PropTypes.node.isRequired,
  selectPlaceholder: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.object),
  idProp: PropTypes.string,
  labelProp: PropTypes.string,
  submit: PropTypes.func.isRequired,
  formikRef: refPropType.isRequired,
  isLoading: PropTypes.bool.isRequired,
  PlaceholderIcon: PropTypes.elementType,
  placeholderText: PropTypes.string.isRequired,
  validationMessage: PropTypes.string.isRequired,
};

YardiGenericListDropdownsResolverModal.defaultProps = {
  subtitle: undefined,
  idProp: undefined,
  labelProp: undefined,
  items: [],
  PlaceholderIcon: Icon.NoDataIcon,
};

export default YardiGenericListDropdownsResolverModal;
