import styled from "@emotion/styled";

export const DescriptionContent = styled.div`
  padding: 15px 13px;
  width: 300px;
`;

export const Parameter = styled.div`
  padding: 12px;
  display: flex;
  background-color: #f1f1f9;
  margin: 4px 0;
  &:last-child {
    margin-bottom: 0;
  }
  &:first-of-type: {
    margin-top: 0;
  }
`;

export const PropName = styled.div`
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;
  color: #787395;
  width: 110px;
  display: flex;
  align-items: center;
`;

export const PropValue = styled.div`
  fontweight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #2d2943;
  padding-left: 4px;
  width: 130px;
  display: flex;
  align-tems: center;
`;

export const IconWrapper = styled.div<{ checkbox?: boolean }>`
  line-height: 7px;
  margin-left: 4px;
  height: 18px;
  ${({ checkbox }) =>
    !!checkbox &&
    `
    height: 14px;
`}
`;
