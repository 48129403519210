import axios from "axios";

import { API_URL } from "shared/config/apiRoutes";
import { SurveyQuestionModel } from "shared/interfaces/api/survey";
import { transformRoute } from "shared/utils/routing";
import ROUTES from "surveys/config/apiRoutes";
import {
  GetSurveyResponse,
  ChangeSurveyQuestionRequest,
  VerifyEmailRequest,
} from "surveys/interfaces/api";

const api = {
  getSurvey: (surveyHashId: number) =>
    axios
      .get<GetSurveyResponse>(
        transformRoute(`${API_URL}${ROUTES.survey}`, {
          surveyHashId,
        })
      )
      .then(({ data }) => data),
  nextSurveyQuestion: ({
    surveyHashId,
    ...question
  }: ChangeSurveyQuestionRequest) =>
    axios
      .post<SurveyQuestionModel>(
        `${API_URL}${transformRoute(ROUTES.nextSurveyQuestion, {
          surveyHashId,
        })}`,
        question
      )
      .then(({ data }) => data),
  prevSurveyQuestion: ({
    surveyHashId,
    ...question
  }: ChangeSurveyQuestionRequest) =>
    axios
      .post<SurveyQuestionModel>(
        `${API_URL}${transformRoute(ROUTES.prevSurveyQuestion, {
          surveyHashId,
        })}`,
        question
      )
      .then(({ data }) => data),
  verifyEmail: ({ surveyHashId, email }: VerifyEmailRequest) =>
    axios
      .post<any>(
        `${API_URL}${transformRoute(ROUTES.verify, {
          surveyHashId,
        })}`,
        { email }
      )
      .then(({ data }) => data),
};

export default api;
