import { useMemo } from "react";

import { useGetApplicationSummary } from "manager/hooks/application-summary/useGetApplicationSummary";

import { AppListInnerProps } from "./app-list";
import { mapDealToAppRowProps } from "./utils";

export const useAppList = (dealId: string): AppListInnerProps => {
  const {
    deal,
    isLoading: loading,
    dealRefetch: refetchDeal,
  } = useGetApplicationSummary({ dealId });

  const apps = useMemo(() => mapDealToAppRowProps(deal), [deal]);

  return { apps, refetchDeal, loading };
};
