import _ from 'lodash'
import React, { useState } from 'react'
import { Toggle } from 'shared/components/vega/Toggle'
import HistoryEntryContainer from './HistoryEntryContainer'

const HistoryEntries = ({ entries, detailed = false }) => {
  const [showAllEntries, setShowAllEntries] = useState(false)
  const stepNumbers = _.chain(entries).map((entry, i) => (showAllEntries || _.isBoolean(entry?.result)) ? (i + 1) : false).compact().value()
  const resolveEntries = showAllEntries ? entries : _.filter(entries, entry => _.isBoolean(entry?.result))

  return (
    <div>
      {detailed && <div className='vega actions'>
        {_.size(entries) > 1 && <Toggle label='Show All' checked={showAllEntries} onChange={() => setShowAllEntries(!showAllEntries)} />}
      </div>}

      {_.map(resolveEntries, (entry, i) => <HistoryEntryContainer
        key={entry?.formula}
        entry={entry}
        stepNumber={stepNumbers[i]}
        detailed={detailed} />
      )}
    </div>
  )
}

export default HistoryEntries
