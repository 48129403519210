import { useEffect } from "react";

const useExperianCollector = () => {
  useEffect(() => {
    const src = "/prefs.js";

    if (document.querySelector(`script[src="${src}"]`)) {
      return () => {};
    }

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = src;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);
};

export default useExperianCollector;
