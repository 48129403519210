import React, { useState } from "react";

import {
  useCreateTag,
  useAddTagToTemplate,
  useDeleteTagFromTemplate,
  useGetAllTags,
} from "admin/hooks/api";

import { Document } from "../interfaces";

import DocumentInfoContent from "./DocumentInfoContent";

interface DocumentInfoContentContainerProps {
  document: Document;
}

const DocumentInfoContentContainer = ({
  document,
}: DocumentInfoContentContainerProps) => {
  const [selectedTags, setSelectedTags] = useState(
    document.tags.map(({ name }) => name)
  );

  const { isTagsLoading, tags: sourceTags } = useGetAllTags();
  const tags = sourceTags?.map(({ name }) => name);

  const {
    createTag,
    response: { isLoading: isTagCreating },
  } = useCreateTag();

  const {
    addTagToTemplate,
    response: { isLoading: isTagAdding },
  } = useAddTagToTemplate();

  const {
    deleteTagFromTemplate,
    response: { isLoading: isTagDeleting },
  } = useDeleteTagFromTemplate();

  const onAddTag = async (value: string) => {
    const savedTag = sourceTags.find(
      (tag) => tag.name.toLowerCase() === value.toLowerCase()
    );

    const { data: tag } =
      (savedTag
        ? await addTagToTemplate({
            tagId: savedTag.id,
            templateId: document.id,
            name: savedTag.name,
          })
        : await createTag({
            name: value,
            templateId: document.id,
          })) ?? {};

    setSelectedTags([...selectedTags, tag.name]);
  };

  const onDeleteTag = async (name: string) => {
    const savedTag = sourceTags.find(
      (tag) => tag.name.toLowerCase() === name.toLowerCase()
    );

    await deleteTagFromTemplate({
      tagId: savedTag.id,
      templateId: document.id,
    });

    const newSelectedTags = selectedTags.filter((tag) => tag !== name);
    setSelectedTags(newSelectedTags);
  };

  return (
    <DocumentInfoContent
      document={document}
      tags={tags}
      selectedTags={selectedTags}
      isTagsLoading={isTagsLoading}
      onAddTag={onAddTag}
      onDeleteTag={onDeleteTag}
      isTagAdding={isTagAdding || isTagCreating}
      isTagDeleting={isTagDeleting}
    />
  );
};

export default DocumentInfoContentContainer;
