import React from "react";

import { Col, Row } from "antd";
import { Field, useFormikContext } from "formik";
import PropTypes from "prop-types";

import {
  DatePicker,
  Input,
  PhoneNumberInput,
  VeroFormField,
} from "shared/components/Form";
import { showError } from "shared/utils/forms";

export const BasicInfoForm = ({ propPrefix }) => {
  const { errors, touched, setFieldValue } = useFormikContext();

  const buildPropPath = (prop) =>
    [propPrefix, prop].filter((p) => !!p).join(".");

  const firstNamePath = buildPropPath("firstName");
  const middleNamePath = buildPropPath("middleName");
  const lastNamePath = buildPropPath("lastName");
  const birthdatePath = buildPropPath("birthdate");
  const phoneNumberPath = buildPropPath("phoneNumber");

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col md={{ span: 12 }}>
          <Field
            name={firstNamePath}
            id={firstNamePath}
            type="text"
            label="First Name"
            required
            as={Input}
            error={showError(firstNamePath, touched, errors)}
          />
        </Col>
        <Col md={{ span: 12 }}>
          <Field
            name={middleNamePath}
            id={middleNamePath}
            type="text"
            label="Middle Name"
            as={Input}
            error={showError(middleNamePath, touched, errors)}
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="row-margin-space">
        <Col md={{ span: 12 }}>
          <Field
            name={lastNamePath}
            id={lastNamePath}
            type="text"
            label="Last Name"
            required
            as={Input}
            error={showError(lastNamePath, touched, errors)}
          />
        </Col>
        <Col md={{ span: 12 }}>
          <VeroFormField
            name={birthdatePath}
            id={birthdatePath}
            label="Date of Birth"
            required
            as={DatePicker}
            disableFuture
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="row-margin-space">
        <Col md={{ span: 12 }}>
          <Field
            name={phoneNumberPath}
            id={phoneNumberPath}
            label="Phone Number"
            required
            as={PhoneNumberInput}
            error={showError(phoneNumberPath, touched, errors)}
            setFieldValue={setFieldValue}
          />
        </Col>
      </Row>
    </>
  );
};

BasicInfoForm.propTypes = {
  propPrefix: PropTypes.string,
};

BasicInfoForm.defaultProps = {
  propPrefix: undefined,
};
