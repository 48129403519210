import React, { CSSProperties } from "react";

import { Col, ColProps, Row } from "antd-latest";

import { range as lodashRange } from "lodash";

import { FadeIn, SlideDirection, useFadeIn } from "shared/components/animated";
import { Button } from "shared/components/Button";
import { SIZES } from "shared/components/Button/Button";
import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import RadioButtons, {
  RADIO_BUTTONS_THEMES,
} from "shared/components/RadioButtons";
import Spacer from "shared/components/Spacer";
import {
  MediumTitle,
  RegularText,
  SmallText,
  SmallTitle,
} from "shared/components/Typography";
import Well from "shared/components/Well";
import { COLORS } from "shared/config/constants";
import { numberWithCommas } from "shared/utils/ui";

import { ExpirationText, InfoContainer } from "./styled";
import { DayRange, ListType, SortOption, Unit } from "./types";
import { daysInRange, getMonthLabel } from "./utils";

export const SpacerSmall = () => <Spacer size={Spacer.SIZES.sm} />;
export const SpacerTiny = () => <Spacer size={Spacer.SIZES.xs} />;

const navStyle = (disabled = false): CSSProperties => ({
  visibility: disabled ? "hidden" : "visible",
  cursor: disabled ? "inherit" : "pointer",
  color: disabled ? "gray" : COLORS.lightViolet,
  fontWeight: "bold" as "bold",
  fill: disabled ? "gray" : COLORS.lightViolet,
});

const unselectedStyle: CSSProperties = {
  border: `1px solid ${COLORS.lightGray4}`,
  borderRadius: "4px",
  cursor: "pointer",
};
const selectedStyle: CSSProperties = {
  border: `1px solid ${COLORS.lightViolet}`,
  borderRadius: "4px",
  backgroundColor: COLORS.itemActiveBg,
  cursor: "pointer",
};

const loadingStyle: CSSProperties = {
  ...unselectedStyle,
  backgroundColor: COLORS.lightGray4,
  cursor: "progress",
};

type ListHeaderProps = {
  type: ListType;
  onClick: () => void;
  results: number;
  hideToggle?: boolean;
};

export const ListHeader: React.FC<ListHeaderProps> = ({
  results,
  type,
  onClick,
  hideToggle,
}) => {
  return (
    <FlexContainer justifyContent="space-between" alignItems="center">
      <MediumTitle>
        {type === ListType.list ? `${results} results` : "Date grid"}
      </MediumTitle>
      {!hideToggle && (
        <FlexContainer alignItems="center" onClick={onClick} style={navStyle()}>
          {type === ListType.list ? (
            <Icon.DatepickerIcon style={{ transform: "scale(.75)" }} />
          ) : (
            <Icon.ListViewIcon style={{ transform: "scale(.75)" }} />
          )}
          <div>&nbsp;</div>
          <SmallText style={navStyle()}>
            {type === ListType.list ? "CALENDAR VIEW" : "LIST VIEW"}
          </SmallText>
        </FlexContainer>
      )}
    </FlexContainer>
  );
};

export const BestPriceChip: React.FC<{ style?: CSSProperties }> = ({
  style,
}) => (
  <span
    style={{
      borderRadius: 4,
      background: COLORS.lightGreen3,
      padding: "2px 6px",
      ...style,
    }}
  >
    <SmallText>Best price</SmallText>
  </span>
);

type ListResultProps = {
  list: Unit[];
  sort: SortOption;
  sorts: SortOption[];
  setSortByKey: (k: string) => void;
  unit: Unit;
  setUnit: (u: Unit) => void;
  best?: Unit;
  loading: boolean;
};

export const ListResults: React.FC<ListResultProps> = ({
  list,
  sort,
  sorts,
  setSortByKey,
  unit: selectedUnit,
  setUnit,
  best,
  loading,
}) => {
  const fade = useFadeIn({
    stagger: 50,
    delay: 0,
    direction: SlideDirection.left,
    amount: 50,
  });
  return (
    <>
      <FlexContainer>
        <RadioButtons
          menuItems={sorts}
          activeItem={sort.key}
          theme={RADIO_BUTTONS_THEMES.darkViolet}
          onChange={setSortByKey}
        />
      </FlexContainer>
      <SpacerSmall />
      <Row gutter={[15, 15]}>
        {loading &&
          lodashRange(12).map(() => (
            <Col xs={24} md={12} xl={8}>
              <FlexContainer
                alignItems="center"
                justifyContent="space-between"
                style={{
                  padding: 15,
                  position: "relative",
                  ...loadingStyle,
                }}
              >
                <MediumTitle>&nbsp;</MediumTitle>
                <RegularText>&nbsp;</RegularText>
              </FlexContainer>
            </Col>
          ))}

        {!loading &&
          list.map((unit) => (
            <Col xs={24} md={12} xl={8} key={unit.id}>
              <FadeIn {...fade()}>
                <FlexContainer
                  alignItems="center"
                  justifyContent="space-between"
                  onClick={() => setUnit(unit)}
                  style={{
                    padding: 15,
                    position: "relative",
                    ...(unit === selectedUnit
                      ? selectedStyle
                      : unselectedStyle),
                  }}
                >
                  <div>
                    {best?.range?.price === unit.range.price && (
                      <BestPriceChip />
                    )}
                    <MediumTitle>
                      ${numberWithCommas(unit.range.price)}
                    </MediumTitle>
                  </div>
                  <FlexContainer flexDirection="column" alignItems="flex-end">
                    <RegularText>From {unit.day.format("M/D/YY")}</RegularText>
                    <RegularText>{unit.range.term} months</RegularText>
                  </FlexContainer>
                </FlexContainer>
              </FadeIn>
            </Col>
          ))}
      </Row>
    </>
  );
};

const GridResult: React.FC<{
  unit: Unit;
  selectedUnit: Unit;
  setUnit: (u: Unit) => void;
  best?: Unit;
}> = ({ unit, setUnit, selectedUnit, best }) => (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus
  <div
    style={{
      padding: "8px",
      ...(unit === selectedUnit ? selectedStyle : unselectedStyle),
    }}
    onClick={() => setUnit(unit)}
    role="button"
  >
    <div>
      {best?.range?.price === unit.range.price ? (
        <BestPriceChip style={{ top: "5px", left: "5px" }} />
      ) : (
        <SmallTitle>&nbsp;</SmallTitle>
      )}
    </div>
    <SpacerTiny />
    <SmallTitle>${numberWithCommas(unit.range.price)}</SmallTitle>
    <SpacerTiny />
    <SmallText>
      {getMonthLabel(unit.range.term)}
      {", "}
      {unit.day
        .add(unit.range.term, "month")
        .subtract(1, "day")
        .format("M/D/YY")}
    </SmallText>
  </div>
);

const LoadingGridResult: React.FC<{}> = () => (
  <Col span={8}>
    <div
      style={{
        padding: "8px",
        ...loadingStyle,
      }}
    >
      <div>
        <SmallTitle>&nbsp;</SmallTitle>
      </div>
      <SpacerTiny />
      <SmallTitle>&nbsp;</SmallTitle>
      <SpacerTiny />
      <SmallText>&nbsp;</SmallText>
    </div>
  </Col>
);

type GridResultsProps = {
  grid: Unit[][];
  isDisablePrev: boolean;
  next: () => void;
  prev: () => void;
  range: DayRange;
  unit: Unit;
  setUnit: (u: Unit) => void;
  best?: Unit;
  loading: boolean;
};

export const GridResults: React.FC<GridResultsProps> = ({
  grid,
  isDisablePrev,
  prev,
  next,
  range,
  unit: selectedUnit,
  setUnit,
  best,
  loading,
}) => {
  const fade = useFadeIn({
    stagger: 50,
    delay: 0,
    direction: SlideDirection.left,
    amount: 50,
  });

  return (
    <>
      <Row gutter={15}>
        {daysInRange(range).map((day) => (
          <Col span={8}>
            <FlexContainer flexDirection="column" alignItems="center">
              <SmallText strong light>
                {day.format("dddd").toUpperCase()}
              </SmallText>
              <SmallText strong light>
                {day.format("MMMM D").toUpperCase()}
              </SmallText>
            </FlexContainer>
          </Col>
        ))}
      </Row>
      <SpacerTiny />
      <Row gutter={[15, 15]}>
        {loading && lodashRange(15).map(() => <LoadingGridResult />)}
        {!loading &&
          grid.flatMap((units) =>
            units.map((unit) => (
              <Col span={8}>
                {unit && (
                  <FadeIn {...fade()}>
                    <GridResult
                      {...{ setUnit, unit, selectedUnit, key: unit.id, best }}
                    />
                  </FadeIn>
                )}
              </Col>
            ))
          )}
      </Row>
      <SpacerSmall />
      <FlexContainer justifyContent="space-between">
        <FlexContainer
          alignItems="center"
          style={navStyle(isDisablePrev)}
          onClick={prev}
        >
          <Icon.ChevronBoldDownIcon style={{ transform: "rotate(90deg)" }} />
          <SmallText style={navStyle(isDisablePrev)} strong>
            PREVIOUS
          </SmallText>
        </FlexContainer>
        <FlexContainer alignItems="center" style={navStyle()} onClick={next}>
          <SmallText style={navStyle()} strong>
            NEXT
          </SmallText>
          <Icon.ChevronBoldDownIcon style={{ transform: "rotate(-90deg)" }} />
        </FlexContainer>
      </FlexContainer>
    </>
  );
};

export const InfoWell: React.FC<{}> = () => {
  return (
    <Well>
      <RegularText strong>What&apos;s next?</RegularText>
      <ul style={{ margin: 0, paddingLeft: 15 }}>
        <li>
          <SpacerTiny />
          <RegularText>Provide basic information about yourself</RegularText>
        </li>
        <li>
          <SpacerTiny />
          <RegularText>Verify your income, background &amp; credit</RegularText>
        </li>
        <li>
          <SpacerTiny />
          <RegularText>
            Receive a decision from the property manager
          </RegularText>
        </li>
      </ul>
    </Well>
  );
};

export const NoResults: React.FC<{}> = () => (
  <>
    <div style={{ textAlign: "center", padding: "0 30px" }}>
      <Icon.SearchIcon width={75} height={75} />
      <MediumTitle>No matching views</MediumTitle>
      <SpacerSmall />
      <SmallTitle light>
        We didn&apos;t find any lease option for your search. Try again.
      </SmallTitle>
    </div>
    <SpacerTiny />
    &nbsp;
  </>
);

export const LetsGo: React.FC<{
  unit?: Unit;
  valid: boolean;
  selectionValidityLifespan?: number;
  onClick: () => void;
  loading: boolean;
  breakpoints: ColProps;
}> = ({
  unit,
  valid,
  selectionValidityLifespan,
  loading,
  onClick,
  breakpoints,
}) => (
  <Row
    justify="center"
    style={{
      boxShadow: "0px 0 10px rgb(0 0 0 / 20%)",
      backgroundColor: COLORS.white,
      position: "fixed",
      bottom: 0,
      left: 0,
      right: 0,
    }}
  >
    <Col {...breakpoints} style={{ padding: "12px 24px 24px 24px" }}>
      {unit && (
        <>
          <RegularText>Your selection</RegularText>
          <br />
          <RegularText extraStrong>
            ${numberWithCommas(unit.range.price)}{" "}
          </RegularText>
          <RegularText>{`${unit.day.format("M/D/YY")} for ${getMonthLabel(
            unit.range.term
          )}`}</RegularText>
        </>
      )}
      {!unit && <RegularText strong>Select an option to continue</RegularText>}
      <SpacerSmall />
      <Button
        type="primary"
        size={SIZES.lg}
        disabled={!unit || !valid}
        onClick={() => onClick()}
        style={{ width: "min(85vw, 300px)" }}
        loading={loading}
      >
        Let&apos;s go
      </Button>
      {!!selectionValidityLifespan && (
        <>
          <Spacer />
          <InfoContainer flexDirection="row" alignItems="center">
            <Icon.InfoBlueIcon />
            <ExpirationText>
              {`Once a rent price is selected, you have ${selectionValidityLifespan} hours to apply at the locked-in rate. You must choose a new rent price if the time elapses.`}
            </ExpirationText>
          </InfoContainer>
        </>
      )}
    </Col>
  </Row>
);
