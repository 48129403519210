import React, { useMemo, useState } from "react";

import {
  useAssignPropertyToPortfolio,
  useGetPortfoliosList,
} from "manager/hooks/api";
import { useDeviceType } from "shared/hooks";

import AssignPropertyToPortfolioModal from "./AssignPropertyToPortfolioModal";

interface AssignPropertyToPortfolioModalContainerProps {
  propertyId: string | number;
  propertyName: string;
}

const AssignPropertyToPortfolioModalContainer = ({
  propertyId,
  propertyName,
}: AssignPropertyToPortfolioModalContainerProps) => {
  const { isMobile } = useDeviceType();

  const { assignPropertyToPortfolio, isAssignPropertyToPortfolio } =
    useAssignPropertyToPortfolio();

  const [filter, setFilter] = useState({ name: "" });
  const [selectedPortfolio, setSelectedPortfolio] = useState<number>();
  const [hasInputValue, setHasInputValue] = useState<boolean>(false);
  const { data: portfolios = [], isLoading } = useGetPortfoliosList({
    ...filter,
  });

  const enableSerchAndFilters = Boolean(portfolios?.length);

  const searchAction = (value: string) => {
    setFilter({ name: value });
    setSelectedPortfolio(null);
    setHasInputValue(!!value);
  };

  const onChangeSearch = ({ target: { value } }) => setSelectedPortfolio(value);

  const submit = () => {
    assignPropertyToPortfolio({
      propertyId,
      portfolioId: selectedPortfolio,
    });
  };

  const portfoliosList = useMemo(
    () =>
      portfolios.map((portfolio) => ({
        label: portfolio.name,
        key: portfolio.id,
      })),
    [portfolios]
  );

  return (
    <AssignPropertyToPortfolioModal
      loading={isLoading}
      portfolios={portfoliosList}
      searchAction={searchAction}
      enableSerchAndFilters={enableSerchAndFilters}
      onChangeSearch={onChangeSearch}
      hasInputValue={hasInputValue}
      propertyName={propertyName}
      selectedPortfolio={selectedPortfolio}
      isMobile={isMobile}
      isSubmitting={isAssignPropertyToPortfolio}
      submit={submit}
    />
  );
};

export default AssignPropertyToPortfolioModalContainer;
