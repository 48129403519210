import styled from "@emotion/styled";

import { Button } from "shared/components/Button";
import { UnderlineLink } from "shared/components/Links";

const SubmitButton = styled(Button)`
  width: 100%;
`;

const TermsOfServiceLink = styled(UnderlineLink)`
  font-weight: 500;
  font-size: 12px;
`;

export { SubmitButton, TermsOfServiceLink };
