
import isNil from "lodash/isNil";

import {
  ApplicationSummaryCard,
  CardSize,
  CardStatus,
} from "shared/components/ApplicationSummaryCard";
import {
  ApplicationSummaryTooltip,
  TooltipColumnWidth,
} from "shared/components/ApplicationSummaryTooltip";
import Tooltip from "shared/components/Tooltip";

import { printDollars } from "shared/utils/dollar-print";
import {
  EMPTY_INDICATOR,
  VERIFIED_PCT_SUCCESS,
  VERIFIED_PCT_WARN,
} from "../utils";

export interface BalanceCardProps {
  verifiedPercent?: number;
  skipped?: boolean;
  skippedReason?: string;
  balance?: number;
  accounts?: number;
  cashPerRent?: number;
  verifiedCashPerRent?: number;
  onClick?: () => void;
  lumenEnabled?: boolean;
  useAssets?: boolean;
  isCashRunwayNotMeet?: boolean;
  lumenRecords?: {
    ideal?: boolean;
    good?: boolean;
    negative?: boolean;
    lumenSkipped?: boolean;
    groupDealLumenSkipped?: boolean;
  };
  isTotalAssetsWithApplicant?: boolean;
}

export const BalanceCard = ({
  balance,
  verifiedPercent,
  skipped = false,
  skippedReason,
  accounts,
  verifiedCashPerRent,
  onClick,
  lumenEnabled,
  useAssets,
  isCashRunwayNotMeet,
  lumenRecords,
  isTotalAssetsWithApplicant,
}: BalanceCardProps) => {
  const status = () => {
    if (lumenEnabled && useAssets) {
      const { ideal, good, negative, lumenSkipped, groupDealLumenSkipped } =
        lumenRecords;

      if (lumenSkipped || groupDealLumenSkipped) {
        return CardStatus.error;
      }

      if (negative) {
        return CardStatus.error;
      }

      if (good) {
        return CardStatus.lumenGood;
      }

      if (ideal) {
        return CardStatus.lumenSuccess;
      }
    }

    return skipped || isNil(balance)
      ? CardStatus.error
      : verifiedPercent > VERIFIED_PCT_SUCCESS
      ? CardStatus.success
      : verifiedPercent > VERIFIED_PCT_WARN
      ? CardStatus.warn
      : CardStatus.error;
  };

  const hideColor = () => {
    if (!lumenEnabled) {
      return false;
    }

    if (!useAssets) {
      return true;
    }

    if (isCashRunwayNotMeet || isTotalAssetsWithApplicant) {
      return true;
    }
    return false;
  };

  const lumenTitle = () => {
    const { lumenSkipped, groupDealLumenSkipped } = lumenRecords;
    return lumenSkipped && groupDealLumenSkipped
      ? "Skipped"
      : printDollars(balance);
  };

  const card = (
    <div>
      <ApplicationSummaryCard
        onClick={onClick}
        title={
          lumenEnabled
            ? lumenTitle()
            : skipped
            ? "Not Completed"
            : printDollars(balance)
        }
        subtitle="VEROfied Balances"
        size={CardSize.Large}
        status={status()}
        hideColor={hideColor()}
      />
    </div>
  );

  return skipped ? (
    <Tooltip title={skippedReason}>{card}</Tooltip>
  ) : (
    <ApplicationSummaryTooltip
      layout={[
        {
          title: "VEROfied Cash Avail. / Mthly. Rent x Lease Duration",
          subtitle: !isNil(verifiedCashPerRent)
            ? `${verifiedCashPerRent.toFixed(1)}x`
            : EMPTY_INDICATOR,
          width: TooltipColumnWidth.full,
        },
        {
          title: "VEROfied Balances:",
          subtitle: printDollars(balance),
          width: TooltipColumnWidth.half,
        },
        {
          title: "No. of Accounts:",
          subtitle: accounts ?? 0,
          width: TooltipColumnWidth.half,
        },
      ]}
    >
      {card}
    </ApplicationSummaryTooltip>
  );
};
