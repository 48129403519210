import React from "react";
import _ from "lodash";
import { Col, Row } from "antd";

import FileSelect from "shared/components/FileSelect";
import FlexContainer from "shared/components/FlexContainer";
import { SwitchTitle } from "shared/components/Form";
import { AttachmentFile } from "shared/components/Form/UploadWall";
import Icon from "shared/components/Icon";
import { ActionLink, UnderlineLink } from "shared/components/Links";
import { Modal } from "shared/components/Modal";
import Spacer from "shared/components/Spacer";
import { SmallText } from "shared/components/Typography";
import { getThumbUrl } from "shared/utils/pdf";

import { ATTACHMENTS_ALLOWED_FILE_TYPES_STRING } from "../Attachments.config";

import AttachmentVisibilitySwitchTitle from "../AttachmentVisibilitySwitchTitle";

import { FileWrapper, FileView } from "./styled";

interface ViewEditAttachmentsModalProps {
  title: string;
  inEditMode: boolean;
  submit: () => void;
  submitting: boolean;
  filesList: any[];
  showAttachmentsVisibilityAction: boolean;
  deleteFile: (id: number) => void;
  uploadFile: (files: FileList) => void;
  changeFileVisibility: (index: number, isPublic: boolean) => void;
}

const ViewEditAttachmentsModal = ({
  title,
  inEditMode,
  submit,
  submitting,
  filesList,
  showAttachmentsVisibilityAction,
  deleteFile,
  uploadFile,
  changeFileVisibility,
}: ViewEditAttachmentsModalProps) => (
  // @ts-ignore
  <Modal
    title={`${title} attachments`}
    submitButtonLabel={inEditMode ? "Confirm" : "Close"}
    submit={submit}
    submitting={submitting}
    showCancelLink={false}
  >
    <Modal.Body noPaddingBottom>
      {filesList.map((_file, index) => {
        const { id, isRestricted, url, isPublic, description } = _file
        const uploadedByApplicant = !_.get(_file, 'tags', []).includes('manager-upload')
        const disableDelete = description === 'agreement' || description === 'contract'

        const key = `${index}-${id}`;
        const file = _file?.file || _file;
        return (
          <FileWrapper key={key}>
            {showAttachmentsVisibilityAction && (
              <Row>
                <Col span={19} offset={3}>
                  <FlexContainer alignItems="center" justifyContent="center">
                    <SmallText>
                      Uploaded by{" "}
                      {uploadedByApplicant ? "Applicant" : "Manager"}
                    </SmallText>
                  </FlexContainer>
                </Col>
              </Row>
            )}
            <Row type="flex" align="middle">
              <Col span={3}>
                <FileView src={getThumbUrl(file)} />
              </Col>
              <Col span={19}>
                <UnderlineLink href={url} rel="noreferrer" target="_blank">
                  {file.documentName}
                </UnderlineLink>
              </Col>
              <Col span={2}>
                {inEditMode && !disableDelete && (
                  <Icon.BaseDeleteSmallNoCircleIcon
                    className="action-icon"
                    onClick={() => deleteFile(index)}
                  />
                )}
              </Col>
            </Row>
            {showAttachmentsVisibilityAction && (
              <Row>
                <Col span={19} offset={3}>
                  <Spacer />
                  <SwitchTitle
                    noPadding
                    title={
                      <AttachmentVisibilitySwitchTitle
                        uploadedByApplicant={uploadedByApplicant}
                        isRestricted={isRestricted}
                      />
                    }
                    onChange={(value) => {
                      changeFileVisibility(index, value);
                    }}
                    value={isPublic}
                    disabled={!_.isEmpty(id) || isRestricted}
                  />
                </Col>
              </Row>
            )}
          </FileWrapper>
        );
      })}

      {inEditMode && (
        <FileSelect
          Node={({ onClick }) => (
            <ActionLink onClick={onClick}>Upload more</ActionLink>
          )}
          acceptFormats={ATTACHMENTS_ALLOWED_FILE_TYPES_STRING}
          onFileSelect={(file) => uploadFile(file)}
        />
      )}
    </Modal.Body>
  </Modal>
);

export default ViewEditAttachmentsModal;
