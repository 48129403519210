const YARDI_ALERT_LEVEL = Object.freeze({
  Major: 1,
  Minor: 2,
});

const YARDI_ALERT_CLASSES = {
  Property: 1,
  Unit: 2,
  UnitAvailability: 3,
  Agent: 4,
  LeadSource: 5,
  MarketingSource: 6,
  DocumentType: 7,
  ChargeType: 8,
};

const YARDI_ALERT_CLASSES_LABELS = {
  1: "Properties",
  2: "Units",
  3: "Available units",
  4: "Agents",
  5: "Lead sources",
  6: "Marketing sources",
  7: "Document types",
  8: "Charge types",
};

const YARDI_ALERT_REASON = {
  Created: 1,
  Deleted: 2,
};

export {
  YARDI_ALERT_LEVEL,
  YARDI_ALERT_CLASSES,
  YARDI_ALERT_CLASSES_LABELS,
  YARDI_ALERT_REASON,
};
