import React from "react";

import { Radio, Row, Col } from "antd";
import PropTypes from "prop-types";

import { MAGIC_LINK_TYPE } from "manager/pages/MagicLinks/config";
import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import { ActionLink } from "shared/components/Links";
import { Modal } from "shared/components/Modal";
import { Popover } from "shared/components/Popover";
import Spacer from "shared/components/Spacer";
import {
  LargeTitle,
  MediumTitle,
  SmallTitle,
  SmallText,
  RegularText,
} from "shared/components/Typography";
import { DEAL_TYPES } from "shared/config/constants";
import { usePermissions } from "shared/hooks";
import useDeviceType from "shared/hooks/useDeviceType";

import { Container, ModalBody } from "./styled";

const MAGIC_LINKS_DESC = `
Allow applicants to jump directly into the application
process with one click
`;

const MAGIC_PORTAL_DESC = `
Display your availability for applicants to apply for
the unit or property of their choice
`;

const PRESCREENING_MAGIC_LINKS_DESC = `
Allow individuals to prequalify before submitting an application
`;

const ShareMagicLinksModal = ({
  name,
  magicLink,
  magicPortalLink,
  prescreeningMagicLink,
  onDealModeChange,
  mode,
  onCopyLink,
  onDownloadQRCode,
  type,
}) => {
  const { isMobile } = useDeviceType();
  const { preventInvitesToTheApplicationWithoutUnit, isPrescreeningEnabled } =
    usePermissions();

  const ModalTitle = isMobile ? SmallTitle : LargeTitle;
  const ModalSubtitle = isMobile ? RegularText : MediumTitle;
  const ModalBodyTitle = isMobile ? RegularText : SmallTitle;

  const shouldShowPrescreening =
    Boolean(prescreeningMagicLink) && isPrescreeningEnabled;

  return (
    <Modal
      title={<ModalTitle>Share via QR Codes and URLs</ModalTitle>}
      subtitle={<ModalSubtitle weak>{name}</ModalSubtitle>}
      width="auto"
      style={{ maxWidth: 1000 }}
      showFooter={false}
    >
      <ModalBody>
        <Container>
          <ModalBodyTitle className="share-title">
            Select the type of invite and then choose how you would like to
            share
          </ModalBodyTitle>
          {shouldShowPrescreening && (
            <>
              <Radio.Group
                onChange={(event) => onDealModeChange(event.target.value)}
                value={mode}
              >
                <Radio value={DEAL_TYPES.SCREENING}>Invite to Apply</Radio>
                <Radio value={DEAL_TYPES.PRESCREENING}>
                  Invite to Prequalify
                </Radio>
              </Radio.Group>
              <Spacer size={Spacer.SIZES.md} />
            </>
          )}
          {(mode === DEAL_TYPES.PRESCREENING ||
            !preventInvitesToTheApplicationWithoutUnit ||
            type === MAGIC_LINK_TYPE.UNIT) && (
            <FlexContainer className="share-row" alignItems="center">
              <Row className="w-100" type="flex">
                <Col md={{ span: 14 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                  <FlexContainer
                    className="share-row-desc"
                    flexWrap="wrap"
                    alignItems="center"
                  >
                    {!isMobile && (
                      <Icon.MagicLinksLogoIcon
                        width={50}
                        data-testid="magic-links-logo"
                      />
                    )}
                    <div className="share-row-name">
                      <div>
                        {isMobile && (
                          <Icon.MagicLinksLogoIcon
                            data-testid="magic-links-logo"
                            width={50}
                          />
                        )}
                        <MediumTitle>
                          {mode === DEAL_TYPES.SCREENING
                            ? "Magic Links"
                            : "Magic Links: Prequalification"}
                        </MediumTitle>
                      </div>
                      <SmallText light>
                        {mode === DEAL_TYPES.SCREENING
                          ? MAGIC_LINKS_DESC
                          : PRESCREENING_MAGIC_LINKS_DESC}
                      </SmallText>
                    </div>
                  </FlexContainer>
                </Col>
                <Col md={{ span: 5 }} sm={{ span: 12 }}>
                  <SmallText light>via QR Code</SmallText>
                  <ActionLink
                    data-testid="magic-links-qr-icon"
                    Icon={Icon.QRCodeGreenIcon}
                    className="share-row-qr"
                    onClick={() =>
                      onDownloadQRCode(
                        mode === DEAL_TYPES.SCREENING
                          ? magicLink
                          : prescreeningMagicLink
                      )
                    }
                  />
                </Col>
                <Col md={{ span: 5 }} sm={{ span: 12 }}>
                  <Popover
                    content="Link is copied!"
                    trigger="click"
                    overlayClassName="success-popover"
                    closeAfterTimeout={500}
                  >
                    <SmallText light>via URL</SmallText>
                    <ActionLink
                      onClick={() =>
                        onCopyLink(
                          mode === DEAL_TYPES.SCREENING
                            ? magicLink
                            : prescreeningMagicLink
                        )
                      }
                      data-testid="magic-links-copy"
                      className="share-row-link"
                    >
                      Copy Link
                    </ActionLink>
                  </Popover>
                </Col>
              </Row>
            </FlexContainer>
          )}
          {magicPortalLink && mode === DEAL_TYPES.SCREENING && (
            <>
              <Spacer size={Spacer.SIZES.sm} />
              <FlexContainer className="share-row" alignItems="center">
                <Row className="w-100" type="flex">
                  <Col md={{ span: 14 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                    <FlexContainer
                      className="share-row-desc"
                      flexWrap="wrap"
                      alignItems="center"
                    >
                      {!isMobile && (
                        <Icon.MagicPortalLinkIcon
                          width={50}
                          data-testid="magic-portal-logo"
                        />
                      )}
                      <div className="share-row-name">
                        <div>
                          {isMobile && (
                            <Icon.MagicPortalLinkIcon
                              data-testid="magic-portal-logo"
                              width={50}
                            />
                          )}
                          <MediumTitle>Magic Portal</MediumTitle>
                        </div>
                        <SmallText light>{MAGIC_PORTAL_DESC}</SmallText>
                      </div>
                    </FlexContainer>
                  </Col>
                  <Col md={{ span: 5 }} sm={{ span: 12 }}>
                    <SmallText light>via QR Code</SmallText>
                    <ActionLink
                      Icon={Icon.QRCodeGreenIcon}
                      onClick={() => onDownloadQRCode(magicPortalLink)}
                      className="share-row-qr"
                      data-testid="magic-portal-qr-icon"
                    />
                  </Col>
                  <Col md={{ span: 5 }} sm={{ span: 12 }}>
                    <Popover
                      content="Link is copied!"
                      trigger="click"
                      overlayClassName="success-popover"
                      closeAfterTimeout={500}
                    >
                      <SmallText light>via URL</SmallText>
                      <ActionLink
                        onClick={() => onCopyLink(magicPortalLink)}
                        data-testid="magic-portal-copy"
                        className="share-row-link"
                      >
                        Copy Link
                      </ActionLink>
                    </Popover>
                  </Col>
                </Row>
              </FlexContainer>
            </>
          )}
        </Container>
      </ModalBody>
    </Modal>
  );
};

ShareMagicLinksModal.propTypes = {
  name: PropTypes.string.isRequired,
  magicLink: PropTypes.string.isRequired,
  magicPortalLink: PropTypes.string,
  prescreeningMagicLink: PropTypes.string,
  onDealModeChange: PropTypes.func.isRequired,
  mode: PropTypes.string,
  onCopyLink: PropTypes.func.isRequired,
  onDownloadQRCode: PropTypes.func.isRequired,
  type: PropTypes.oneOf(Object.values(MAGIC_LINK_TYPE)),
};

ShareMagicLinksModal.defaultProps = {
  magicPortalLink: undefined,
};

export default ShareMagicLinksModal;
