import axios from "axios";

import { VEGA_BASE_URL } from "shared/config/apiRoutes";

const EMPLOYMENT_SERVICE_PATH = "/api/employment";

const api = {
  getEmployments: (person: string) =>
    axios
      .get(`${VEGA_BASE_URL}${EMPLOYMENT_SERVICE_PATH}`, {
        params: {
          person
        },
      })
      .then(({ data }) => data),
  createEmployment: ({ payload }) =>
    axios
      .post(
        `${VEGA_BASE_URL}${EMPLOYMENT_SERVICE_PATH}`,
        payload,
        // @ts-ignore
        { convertToSnakeCase: false }
      )
      .then(({ data }) => data),
  getEmploymentById: (employmentId: string) =>
    axios
      .get(
        `${VEGA_BASE_URL}${EMPLOYMENT_SERVICE_PATH}/${encodeURIComponent(
          employmentId
        )}`
      )
      .then(({ data }) => data),
  updateEmploymentById: ({ employmentId, payload }) =>
    axios
      .put(
        `${VEGA_BASE_URL}${EMPLOYMENT_SERVICE_PATH}/${encodeURIComponent(
          employmentId
        )}`,
        payload,
        // @ts-ignore
        { convertToSnakeCase: false }
      )
      .then(({ data }) => data),
  deleteEmploymentById: ({ employmentId }) =>
    axios
      .delete(`${VEGA_BASE_URL}${EMPLOYMENT_SERVICE_PATH}/${employmentId}`)
      .then(({ data }) => data),
};

export default api;
