import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router-dom";

import api from "renter/lib/api";
import { HTTP_ERROR_CODES, NOTIFICATIONS } from "shared/config/constants";
import ROUTES from "shared/config/routes";
import { transformRoute } from "shared/utils/routing";
import { openNotification } from "shared/utils/ui";

const TEMP_MAGIC_LINKS_COMPANY_PROFILE = "getTempMagicLinksCompany";

export const useGetTempMagicLinksCompany = (companyHashId) => {
  const history = useHistory();

  const { data: companyProfile, isLoading: isCompanyProfileLoading } = useQuery(
    [TEMP_MAGIC_LINKS_COMPANY_PROFILE, companyHashId],
    () =>
      api.getTempMagicLinksCompany({ companyHashId }).then(({ data }) => data),
    {
      enabled: companyHashId,
      retry: false,
      onError: (error) => {
        if (error?.response?.status === HTTP_ERROR_CODES.notFound) {
          history.replace(ROUTES.notFound);
        }
      },
    }
  );
  return {
    companyProfile,
    isCompanyProfileLoading,
  };
};

export const usePostTempMagicLinksCompany = () => {
  const [mutate, status] = useMutation(api.postTempMagicLinksCompany, {
    onError: () => {
      openNotification("Failed to send applicant data.", NOTIFICATIONS.error);
    },
    throwOnError: true,
  });

  return {
    sendTempMagicLinkApplicationData: mutate,
    status,
  };
};

export const useGetTempMagicLinksProperties = (companyHashId) => {
  const history = useHistory();

  const { data: properties, isLoading: isPropertiesLoading } = useQuery(
    ["getTempMagicLinksProperties", companyHashId],
    () =>
      api
        .getTempMagicLinksProperties({ companyHashId })
        .then(({ data }) => data),
    {
      enabled: companyHashId,
      retry: false,
      onError: (error) => {
        if (error?.response?.status === HTTP_ERROR_CODES.notFound) {
          history.replace(ROUTES.notFound);
        }
      },
    }
  );
  return {
    properties,
    isPropertiesLoading,
  };
};

export const useCreateMagicDeal = () => {
  const [mutate, status] = useMutation(api.createMagicDeal, {
    onError: (error) => {
      if (error.statusCode !== HTTP_ERROR_CODES.badRequest) {
        openNotification("Failed to send applicant data.", NOTIFICATIONS.error);
      }
    },
  });

  return {
    createMagicDeal: mutate,
    ...status,
  };
};

export const useGetMagicLinkLogo = ({ companyHashId, dealType }) => {
  const history = useHistory();
  const response = useQuery(
    ["getMagicLinkLogo", companyHashId, dealType],
    () => api.getMagicLinkLogo({ companyHashId, dealType }),
    {
      onError: (error) => {
        if (error?.statusCode === HTTP_ERROR_CODES.forbidden) {
          let entityName = error?.errors?.propertyName;
          // for company MLs
          if (!entityName) {
            entityName = error?.errors?.companyName;
          }
          history.replace(
            transformRoute(ROUTES.magicLinkUnavailable, {
              entityName,
            })
          );
        }
      },
      retry: false,
    }
  );
  return response;
};

export const useCreateMagicLinkV2Deal = () => {
  const [mutate, status] = useMutation(api.createMagicLinkV2Deal);

  return {
    createMagicLinkV2Deal: mutate,
    ...status,
  };
};

export const useGetMagicLinkV2Details = ({
  company,
  propertySource,
  property,
  unit,
  dealType,
}) => {
  const response = useQuery(
    [
      "getMagicLinkV2Details",
      company,
      propertySource,
      property,
      unit,
      dealType,
    ],
    () =>
      api.getMagicLinkV2Details({
        company,
        propertySource,
        property,
        unit,
        dealType,
      }),
    {
      retry: false,
    }
  );
  return response;
};
