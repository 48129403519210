import styled from "@emotion/styled";

import { specs } from "shared/components/Form/Input/styled";

export const ToggleVisibilityButton = styled("div")({
  cursor: "pointer",
  position: "absolute",
  right: specs.horizontalPadding,
  top: "50%",
  transform: "translateY(-50%)",
  display: "flex",
  alignItems: "center",
});
