import styled from "@emotion/styled";
import BaseMaskedInput from "react-text-mask";

import { Input as BaseInput } from "shared/components/Form/Input";
import {
  getInputStyles,
  InputStyledProps,
} from "shared/components/Form/Input/styled";

// @ts-ignore
export const Input = styled(BaseInput)((props: InputStyledProps) => ({
  height: "40px",
  ...getInputStyles(props),
  borderWidth: "1px",
  borderStyle: "solid",
  width: "100%",
}));

export const StyledMaskedInput = styled(BaseMaskedInput)(
  // @ts-ignore
  (props: InputStyledProps) => ({
    height: "40px",
    ...getInputStyles(props),
    borderWidth: "1px",
    borderStyle: "solid",
    width: "100%",
  })
);
