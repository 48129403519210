import React from "react";

import { Popover } from "shared/components/Popover";
import { RegularText } from "shared/components/Typography";
import { PropertiesListPopoverContent } from "./styled";

const PropertiesListPopover = ({
  assignments,
}) => {
  return (
    <Popover
      className="popover"
      withoutOverlay
      disabled={!assignments.length}
      trigger="click"
      content={
        <PropertiesListPopoverContent>
          <ul>
            {assignments.sort().map((name) => (
              <li key={name}>{name}</li>
            ))}
          </ul>
        </PropertiesListPopoverContent>
      }
    >
      <RegularText underline={assignments.length > 0}>{assignments.length}</RegularText>
    </Popover>
  );
};

export default PropertiesListPopover;
