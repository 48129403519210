import React, { useRef, useState } from "react";

import PropTypes from "prop-types";
import { useHistory, useLocation, useParams } from "react-router-dom";

import ROUTES from "renter/config/routes";

import {
  useGetMagicPortalCompany,
  useGetMagicPortalProperties,
} from "renter/hooks/api/magicPortalQueries";
import { getQueryParams } from "shared/utils/routing";

import { PAGE_SIZE } from "../config";

import PropertiesPortal from "./PropertiesPortal";

const PropertiesContainer = ({ companyMagicFragment }) => {
  let companyHashId;
  if (companyMagicFragment) {
    companyHashId = companyMagicFragment;
  } else {
    ({ companyHashId } = useParams());
  }

  const location = useLocation();
  const { push } = useHistory();
  const isPrescreeningMagicLinksV2 = location.pathname.includes(
    ROUTES.prescreeningMagicLinkV2GetStarted
  );
  const isMagicLinksV2 = location.pathname.includes(
    ROUTES.magicLinkV2GetStarted
  );

  const { company: companyUuid } = getQueryParams();

  const [propertiesQueryParams, setPropertiesQueryParams] = useState({
    companyHashId,
    page: 1,
    pageSize: PAGE_SIZE,
    nameOrAddress: "",
    prescreeningOnly: isPrescreeningMagicLinksV2,
  });
  const propertiesRef = useRef();

  const { magicPortalCompany } = useGetMagicPortalCompany({
    companyHashId,
  });

  const { properties, isPropertiesLoading } = useGetMagicPortalProperties(
    propertiesQueryParams
  );

  if (properties) {
    propertiesRef.current = properties;
  }

  const onSearch = (term) => {
    const nextState = {
      ...propertiesQueryParams,
      page: 1,
      nameOrAddress: term,
    };
    setPropertiesQueryParams(nextState);
  };

  const onPageChange = (page) => {
    const nextState = {
      ...propertiesQueryParams,
      page,
    };
    setPropertiesQueryParams(nextState);
  };

  const onApplyNowClick = () => {
    const queryParams = `${location.search}&show-landing=true`;
    push(`${location.pathname}${queryParams}`);
  };

  const innerProps = {
    properties: propertiesRef.current?.results,
    company: magicPortalCompany,
    isPrescreeningMagicLinksV2,
    isMagicLinksV2,
    onSearch,
    onChange: onPageChange,
    onApplyNowClick,
    total: propertiesRef.current?.count,
    pagination: propertiesQueryParams,
    isPropertiesLoading,
    companyHashId,
    companyUuid,
  };

  return <PropertiesPortal {...innerProps} />;
};

PropertiesContainer.propTypes = {
  companyMagicFragment: PropTypes.string,
};

PropertiesContainer.defaultProps = {
  companyMagicFragment: undefined,
};

export default PropertiesContainer;
