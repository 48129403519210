import React from "react";

import { SIZES } from "./constants";
import { Spacer as StyledSpacer } from "./styled";

export interface SpacerProps {
  hideLtSm?: boolean;
  hideGteSm?: boolean;
  hideLtMd?: boolean;
  hideGteMd?: boolean;
  hideLtLg?: boolean;
  hideGteLg?: boolean;
  hideLtXl?: boolean;
  hideGteXl?: boolean;
  size?: string | "xs" | "sm" | "default" | "md" | "lg" | "xl";
}

const Spacer = ({
  hideLtSm,
  hideGteSm,
  hideLtMd,
  hideGteMd,
  hideLtLg,
  hideGteLg,
  hideLtXl,
  hideGteXl,
  size,
}: SpacerProps) => (
  <StyledSpacer
    data-testid="spacer"
    size={size}
    hideLtSm={hideLtSm}
    hideGteSm={hideGteSm}
    hideLtMd={hideLtMd}
    hideGteMd={hideGteMd}
    hideLtLg={hideLtLg}
    hideGteLg={hideGteLg}
    hideLtXl={hideLtXl}
    hideGteXl={hideGteXl}
  />
);

Spacer.SIZES = SIZES;

export default Spacer;
