import React from "react";

import { useGenerateApplicationPDF } from "manager/hooks/api";
import Icon from "shared/components/Icon";
import { ActionLink } from "shared/components/Links";
import OptionItem from "shared/interfaces/api/optionItem";
import BaseSummary from "shared/pages/ApplicationSummary/Summary";
import { getMailToLink } from "shared/utils/ui";

type downloadActionType = {
  description: string;
  onClick: () => void;
  icon: (props: any) => React.ReactNode;
  disabled: boolean;
  nowrap: boolean;
};

interface SummaryProps {
  label: {
    type: string;
    text: string;
  };
  otherApplicants: Array<any>;
  applicantTypes: { [key: string]: OptionItem };
  // TODO: Provide ApplicationSummary interface
  applicationSummary: any;
  showOtherApplicants: boolean;
  OtherApplicantsList: (props: any) => React.ReactNode;
  title: string;
  isEvictionEnabled: boolean;
  hideCriminalHistory: boolean;
  downloadAction: downloadActionType;
  otherApplicantsAction: React.ReactNode;
}

const Summary = (props: SummaryProps) => {
  const {
    applicationSummary: { isSubmitted, id },
    otherApplicants,
  } = props;

  const { downloadApplicationPDF, isLoading: isLoadingPDF } =
    useGenerateApplicationPDF(id);

  const downloadAction: downloadActionType = isSubmitted
    ? {
        description: "Download Application",
        onClick: downloadApplicationPDF,
        icon: Icon.DownloadPDF,
        disabled: isLoadingPDF,
        nowrap: true,
      }
    : null;

  const otherApplicantsAction =
    otherApplicants?.length > 0 ? (
      <ActionLink
        onClick={() => {
          const emails = otherApplicants.map((applicant) => applicant?.email);
          window.location.href = getMailToLink(emails);
        }}
      >
        Contact
      </ActionLink>
    ) : undefined;

  return (
    <BaseSummary
      {...props}
      downloadAction={downloadAction}
      otherApplicantsAction={otherApplicantsAction}
    />
  );
};

export default Summary;
