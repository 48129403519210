import React, { useState, useEffect, useRef } from "react";

import isFunction from "lodash/isFunction";
import PropTypes from "prop-types";

import Container from "shared/components/Container";
import {
  ZoomControls,
  ZoomSelect,
  PageNumber,
} from "shared/components/DocumentViewer";
import Icon from "shared/components/Icon";
import { IconLink } from "shared/components/Links";

import { ViewerProps } from "./interfaces";
import {
  ScrollContainer,
  Content,
  Panel,
  ZoomContainer,
  LoaderContainer,
  DownloadButton,
  Tools,
  DefaultTools,
} from "./styled";

const Viewer = ({
  CentralTools,
  LeftTools,
  RightTools,
  viewerApi,
  showZoomControls,
  loading,
  showDownloadButton,
}: ViewerProps) => {
  const cleanUpRef = useRef<any>();
  const [selectedZoom, setZoom] = useState(ZoomSelect.OPTIONS["100%"]);
  const onPageChange = (event) => {
    const num = Number(event.target.value);

    if (num > 0) {
      viewerApi.setPage(num);
    }
  };

  const onZoomIn = () => {
    const nextZoom = Number(selectedZoom) + ZoomSelect.STEP;

    if (nextZoom <= Number(ZoomSelect.OPTIONS["200%"])) {
      setZoom(`${nextZoom}`);
    }
  };

  const onZoomOut = () => {
    const nextZoom = Number(selectedZoom) - ZoomSelect.STEP;

    if (nextZoom >= Number(ZoomSelect.OPTIONS["25%"])) {
      setZoom(`${nextZoom}`);
    }
  };

  useEffect(() => {
    (async () => {
      cleanUpRef.current = await viewerApi.initialize();
    })();

    return () => {
      if (isFunction(cleanUpRef.current)) {
        cleanUpRef.current();
      }
    };
  }, []);

  useEffect(() => {
    if (viewerApi.isDocumentLoaded) {
      // @ts-ignore
      viewerApi.zoomTo(selectedZoom * ZoomSelect.PERCENT_COEFFICIENT);
    }
  }, [viewerApi.isDocumentLoaded, selectedZoom]);

  const Loader = (viewerApi.isDocumentLoading || loading) && (
    <LoaderContainer>
      <Icon.LoadingSpinnerGreenLargeIcon data-testid="loading" />
    </LoaderContainer>
  );

  return (
    <Content>
      <Container noPadding expand>
        {Loader}
        <ScrollContainer
          ref={viewerApi.viewerNodeRef}
          data-testid="viewer"
          visible={viewerApi.isInitialized && viewerApi.isDocumentLoaded}
        />
      </Container>
      <Container stickToBottom noPadding>
        <Panel>
          <Tools>{LeftTools}</Tools>
          <DefaultTools>
            <PageNumber
              value={viewerApi.currentPage}
              onChange={onPageChange}
              id="page"
              name="page"
              data-testid="page"
              total={viewerApi.totalPages}
              disabled={!viewerApi.isDocumentLoaded}
            />
            <ZoomSelect
              id="zoom"
              data-testid="zoom"
              value={selectedZoom}
              // @ts-ignore
              onChange={setZoom}
              disabled={!viewerApi.isDocumentLoaded}
            />
            {showDownloadButton && (
              <DownloadButton>
                {viewerApi.isDocumentDownloading ? (
                  <Icon.LoadingSpinnerGreenIcon width={30} height={30} />
                ) : (
                  <IconLink
                    onClick={viewerApi.downloadDocument}
                    Icon={Icon.DownloadDocumentIcon}
                    hideTextOnMobile
                  />
                )}
              </DownloadButton>
            )}

            {CentralTools}
          </DefaultTools>
          <Tools>{RightTools}</Tools>
        </Panel>
      </Container>
      {showZoomControls && (
        <ZoomContainer>
          <ZoomControls
            data-testid="zoom-controls"
            onZoomIn={onZoomIn}
            onZoomOut={onZoomOut}
            zoomInDisabled={
              !viewerApi.isDocumentLoaded ||
              selectedZoom === ZoomSelect.OPTIONS["200%"]
            }
            zoomOutDisabled={
              !viewerApi.isDocumentLoaded ||
              selectedZoom === ZoomSelect.OPTIONS["25%"]
            }
          />
        </ZoomContainer>
      )}
    </Content>
  );
};

Viewer.propTypes = {
  RightTools: PropTypes.node,
  LeftTools: PropTypes.node,
  CentralTools: PropTypes.node,
  viewerApi: PropTypes.shape({
    isInitialized: PropTypes.bool.isRequired,
    setPage: PropTypes.func.isRequired,
    zoomTo: PropTypes.func.isRequired,
    currentPage: PropTypes.number,
    totalPages: PropTypes.number,
    isDocumentLoaded: PropTypes.bool,
    initialize: PropTypes.func.isRequired,
    isDocumentLoading: PropTypes.bool.isRequired,
    downloadDocument: PropTypes.func.isRequired,
    viewerNodeRef: PropTypes.shape({
      current: PropTypes.object,
    }).isRequired,
    isDocumentDownloading: PropTypes.bool,
  }).isRequired,
  showZoomControls: PropTypes.bool,
  loading: PropTypes.bool,
  showDownloadButton: PropTypes.bool,
};

Viewer.defaultProps = {
  RightTools: undefined,
  LeftTools: undefined,
  CentralTools: undefined,
  showZoomControls: false,
  loading: false,
  showDownloadButton: true,
};

export default Viewer;
