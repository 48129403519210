import React from "react";

import { Select, SelectProps } from "shared/components/Form/Select";

export const TagsInput = (props: SelectProps) => {
  return (
    <Select
      {...props}
      dropdownStyle={{ display: "none" }}
      mode="tags"
      allowClear
    />
  );
};
