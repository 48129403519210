import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";

import MainBackground from "shared/components/Backgrounds/MainBackground";
import { Button } from "shared/components/Button";
import FlexContainer from "shared/components/FlexContainer";
import { Search } from "shared/components/Form";
import Icon from "shared/components/Icon";
import List from "shared/components/List";
import { BREAKPOINT_TYPES } from "shared/config/constants";
import ROUTES from "shared/config/routes";
import withBreakpoint from "shared/hocs/withBreakpoint";
import { generateId } from "shared/utils/misc.util";
import { breakpointPropType } from "shared/utils/propTypes";
import { transformRoute } from "shared/utils/routing";

import {
  Container as Wrapper,
  ContentContainer,
  Header,
  LoadingSpinner,
  Modal,
  VeroLogo,
  SearchContainer,
  ListHeader,
  ListContainer,
  ListItem,
  Text,
} from "../styled";

const MagicLinkProperties = ({
  breakpoint,
  logoUrl,
  properties,
  companyHashId,
  isLoading,
}) => {
  const history = useHistory();
  const [filterProperties, setFilterProperties] = useState([]);
  const withoutSelectButton = [
    BREAKPOINT_TYPES.sm,
    BREAKPOINT_TYPES.xs,
  ].includes(breakpoint);

  useEffect(() => {
    if (properties) {
      setFilterProperties(properties);
    }
  }, [properties]);

  const searchProperties = (searchParam) => {
    if (searchParam) {
      setFilterProperties(
        properties.filter((property) =>
          property.address.toLowerCase().includes(searchParam.toLowerCase())
        )
      );
    } else {
      setFilterProperties(properties);
    }
  };

  const selectProperty = (address) => {
    history.push({
      pathname: transformRoute(ROUTES.magicLinkLandingPage, { companyHashId }),
      search: `?source=${encodeURI(address)}`,
    });
  };

  const handleClickRow = (address) => {
    if (withoutSelectButton) {
      selectProperty(address);
    }
  };

  const resolvedLogo = logoUrl ? (
    <img src={logoUrl} alt="Logo" className="logo" />
  ) : (
    <Icon.LogoGrayIcon />
  );

  return (
    <>
      <Helmet>
        <title>Company properties</title>
      </Helmet>
      <Wrapper>
        <ContentContainer>
          <div className="logo-container">{!isLoading && resolvedLogo}</div>
          <Modal className="modal propertyModal">
            <Header center>
              <h1 data-testid="title" className="title">
                Which property would you like to apply to?
              </h1>
              <SearchContainer>
                <Search
                  data-testid="search-field"
                  id="search"
                  name="search"
                  debounce
                  onSearch={searchProperties}
                  label="Search"
                />
              </SearchContainer>
            </Header>
            <div>
              {isLoading && <LoadingSpinner />}
              {!isLoading && (
                <>
                  <ListHeader data-testid="list-header">
                    {filterProperties.length
                      ? "Property Address"
                      : "No properties to apply"}
                  </ListHeader>
                  <ListContainer>
                    <List>
                      {filterProperties.map((property) => (
                        <ListItem
                          key={generateId()}
                          onClick={() => handleClickRow(property.address)}
                        >
                          <FlexContainer
                            fullWidth
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Text>{property.address}</Text>
                            {!withoutSelectButton && (
                              <Button
                                type="secondary"
                                size="sm"
                                onClick={() => selectProperty(property.address)}
                              >
                                Select
                              </Button>
                            )}
                          </FlexContainer>
                        </ListItem>
                      ))}
                    </List>
                  </ListContainer>
                </>
              )}
            </div>
            <div className="powered-by inner">
              Powered by <VeroLogo className="logo" />
            </div>
          </Modal>
          <div className="powered-by">
            Powered by <VeroLogo className="logo" />
          </div>
        </ContentContainer>
        <MainBackground />
      </Wrapper>
    </>
  );
};

MagicLinkProperties.propTypes = {
  breakpoint: breakpointPropType.isRequired,
  isLoading: PropTypes.bool.isRequired,
  companyHashId: PropTypes.string.isRequired,
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.string,
    })
  ),
  logoUrl: PropTypes.string,
};

MagicLinkProperties.defaultProps = {
  properties: [],
  logoUrl: undefined,
};

export default withBreakpoint(MagicLinkProperties);
