import { useQuery } from "react-query";

import { NOTIFICATIONS } from "shared/config/constants";
import api from "shared/lib/api/typeApi";

import { openNotification } from "shared/utils/ui";

const STATES = "getStates";

export const useGetStates = () => {
  const { data, isLoading } = useQuery([STATES], api.getStates, {
    cacheTime: Infinity,
    staleTime: Infinity,
    onError: () =>
      openNotification("Failed to get States.", NOTIFICATIONS.error),
  });
  return {
    states: data,
    isStatesLoading: isLoading,
  };
};
