import React from "react";

import dayjs from "dayjs";

import {
  ApplicationSummaryCard,
  CardSize,
  CardStatus,
} from "shared/components/ApplicationSummaryCard";
import {
  ApplicationSummaryTooltip,
  TooltipColumnWidth,
} from "shared/components/ApplicationSummaryTooltip";
import { SnapPassed, SnapFailed, SnapPending } from "shared/icons";

import { getValue } from "../utils";

export interface IdentityCardProps {
  enabled: boolean;
  skipped: boolean;
  verified?: boolean;
  documentType?: string;
  status?: string;
  identityNumber?: string;
  country?: string;
  expires?: dayjs.Dayjs;
  onClick?: () => void;
  lumenEnabled?: boolean;
  useVerifications?: boolean;
  verifyIdentity?: boolean;
}

export const IdentityCard = ({
  skipped,
  verified,
  documentType,
  status,
  identityNumber,
  country,
  expires,
  onClick,
  lumenEnabled,
  useVerifications,
  verifyIdentity,
}: IdentityCardProps) => {
  const additionalProps = () => {
    if (lumenEnabled && verifyIdentity) {
      let status = null;
      let logo = null;
      if (skipped) {
        status = CardStatus.warn;
        logo = <SnapPending small={false} />;
      } else if (verified === false) {
        status = CardStatus.error;
        logo = <SnapFailed small={false} />;
      } else if (verified === true) {
        status = CardStatus.lumenSuccess;
        logo = <SnapPassed small={false} />;
      }

      return {
        status,
        logo,
      };
    }

    return {
      status:
        verified === false
          ? CardStatus.error
          : skipped
          ? CardStatus.warn
          : CardStatus.success,
      logo:
        !skipped && verified ? (
          <SnapPassed small={false} />
        ) : (
          <SnapFailed small={false} />
        ),
    };
  };

  return (
    <ApplicationSummaryTooltip
      layout={[
        {
          title: "Doc. Type:",
          subtitle: getValue(documentType),
          width: TooltipColumnWidth.full,
        },
        {
          title: "Status:",
          subtitle: getValue(status),
          width: TooltipColumnWidth.half,
        },
        {
          title: "Doc. Number:",
          subtitle: getValue(identityNumber),
          width: TooltipColumnWidth.half,
        },
        {
          title: "Doc. Country:",
          subtitle: getValue(country),
          width: TooltipColumnWidth.half,
        },
        {
          title: "Doc. Exp. Date:",
          subtitle: dayjs(expires).format("M/D/YYYY"),
          width: TooltipColumnWidth.half,
        },
      ]}
    >
      <div>
        <ApplicationSummaryCard
          onClick={onClick}
          title="Identity"
          size={CardSize.Small}
          {...additionalProps()}
          hideColor={lumenEnabled && !useVerifications}
        />
      </div>
    </ApplicationSummaryTooltip>
  );
};
