export const PAYMENT_PATTERN_STATUSES = {
  ON_TIME: 'On Time',
  LATE_30_DAYS: '30 Days Late',
  LATE_60_DAYS: '60 Days Late',
  LATE_90_DAYS: '90 Days Late',
  LATE_120_DAYS: '120 Days Late',
  UNCLASSIFIED: 'Unclassified',
}

export interface PaymentPattern {
  year: string
  months: typeof PAYMENT_PATTERN_STATUSES[]
}

export interface CreditScoreSummary {
  firstName: string
  middleName?: string
  lastName: string
  akas?: string[]
  score: number
  creditLimit?: number
  creditBalance?: number
  percentage?: number
  scoreFactors: string[]
  fraudIndicators: string[]
  collections?: any
  addresses?: {
    address: string
    city: string
    state: string
    zipcode: string
    dateReported?: string
  }[]
  accounts: {
    id: string | number
    name?: string
    status: string
    terms: string
    scheduledPayments?: number
    rating?: string
    type?: string
    balance?: number
    dateOpened: string
    worstPaymentStatus?: string
    pastDueBalance?: number
    times30DaysLate?: number
    times60DaysLate?: number
    times90DaysLate?: number
    paymentPatterns: {
      year: string
      months: string[]
    }[]
  }[]
  creationDate?: string
  expirationDate?: string
  expired?: boolean
  [key: string]: any
}

export interface CriminalRecordType {
  date?: string
  type?: string
  docket: string
  outcome?: string
  severity?: string
  felony?: boolean
  chargeText?: string
}

export interface CriminalRecordsType {
  criminalRecordGroup?: {
    startDate: string
    endDate?: string
    state: string
    numRecords?: number
    records?: CriminalRecordType[]
    [key: string]: any
  }[]
  lastRecord?: CriminalRecordType
  lastRecordState?: string
  states?: number
  totalRecords?: number
  sexualCrime: boolean
  biggestSeverity?: string
  felony?: boolean
  [key: string]: any
}

export interface EvictionRecordProps {
  caseType: string
  fileNumber: string
  judgement: string
  judgementAmount?: number
  date?: string | Date
  recordSource?: string
  internalRecordId?: string
  releaseDate?: string | Date
}

export interface EvictionsType {
  evictionRecordGroup: {
    startDate: string
    endDate?: string
    state: string
    numRecords?: number
    records: EvictionRecordProps[]
  }[]
  lastRecord: EvictionRecordProps
  lastRecordState?: string
  states?: number
  totalEvictions?: number
}

export enum CountyCriminalRecordStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE',
  CANCELLED = 'CANCELLED',
  NEED_INFO = 'NEED_INFO',
  CLEAR = 'CLEAR',
  RECORD = 'RECORD',
}

export interface CountyCriminalRecord {
  county: string
  startDate?: string
  endDate?: string
  dueDate?: string
  status: CountyCriminalRecordStatus
  infoNeeded?: string[]
  records: {
    date?: string
    courtRecordId: string
    chargeStatus?: string
    chargeDescription?: string
    severity?: string
  }[]
  orderId?: string
}

export interface CountyCriminalRecordsProps {
  criminalRecordsByCounty?: CountyCriminalRecord[]
}
