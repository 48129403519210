import React from "react";

import PropTypes from "prop-types";

import Icon from "shared/components/Icon";
import InfoPage from "shared/templates/InfoPage";

export interface MagicLinkInvalidPageProps {
  entityName: string;
}

const MagicLinkInvalidPage = ({ entityName }: MagicLinkInvalidPageProps) => (
  <InfoPage
    title="Oops!"
    description={`Looks like this property does not have any available units.
    Please reach out to the leasing agent at ${entityName}
    for next steps.`}
    MainIcon={Icon.OrangeExclamationHollow}
  />
);

MagicLinkInvalidPage.propTypes = {
  entityName: PropTypes.string.isRequired,
};

export default MagicLinkInvalidPage;
