/* eslint-disable no-undef */
import React, { useState } from "react";

import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";

/* Components */
import Container from "shared/components/Container";
import FilterBar from "shared/components/FilterBar";
import { BackLink } from "shared/components/Links";
import MobileFilters from "shared/components/MobileFilters";
import PageTitle from "shared/components/PageTitle";
import Spacer from "shared/components/Spacer";
import {
  BREAKPOINT_RESOLUTIONS,
  EXPORT_INVENTORY_KEY,
} from "shared/config/constants";

import {
  Container as MainContainer,
  ContentContainer,
  Tabs,
  FiltersContainer,
} from "./styled";

export interface ITablePage {
  title?: string;
  subtitle?: string;
  actions?: {
    key: string;
    node: React.ReactNode;
  }[];
  onlyMobileActions?: boolean;
  filterTabs?: {
    route: string;
    label: string;
  }[];
  backLink?: any;
  tables: {
    filterBar?: {};
    node: React.ReactNode;
  }[];
  example?: boolean;
  banner?: React.ReactNode;
}

const TablePage = ({
  actions = undefined,
  onlyMobileActions = false,
  title,
  subtitle = undefined,
  filterTabs = undefined,
  backLink = undefined,
  tables,
  example = false,
  banner = undefined,
}: ITablePage) => {
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT_RESOLUTIONS.sm - 1 });
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(
    example ? filterTabs[0].route : history.location.pathname
  );
  const tabsOnChange = (route) => {
    setActiveTab(route);
    history.push(route);
  };

  const activeTabIndex =
    filterTabs && filterTabs.findIndex((tab) => tab.route === activeTab);
  const activeTable = filterTabs ? tables[activeTabIndex] : tables[0];
  const filteredActions = actions?.filter(
    (action) => action.key !== EXPORT_INVENTORY_KEY
  );
  const exportAction = actions?.find(
    (action) => action.key === EXPORT_INVENTORY_KEY
  );

  return (
    <MainContainer>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container noPaddingBottom>
        {backLink && (
          <BackLink.Container>
            <BackLink {...backLink} />
          </BackLink.Container>
        )}
        <PageTitle.Container>
          <div>
            <PageTitle>{title}</PageTitle>
            {subtitle && (
              <PageTitle.Subtitle hideOnMobile>{subtitle}</PageTitle.Subtitle>
            )}
          </div>
          {filteredActions && (
            <PageTitle.Actions onlyMobileActions={onlyMobileActions}>
              {filteredActions.map(({ key, node }) => (
                <PageTitle.Action key={key}>{node}</PageTitle.Action>
              ))}
            </PageTitle.Actions>
          )}
        </PageTitle.Container>
      </Container>
      <Container noPaddingTop noMobilePaddingX>
        {filterTabs && (
          <>
            <Tabs
              tabs={filterTabs.map((tab) => ({
                key: tab.route,
                label: tab.label,
              }))}
              activeKey={activeTab}
              onChange={tabsOnChange}
            />
            <Spacer size={Spacer.SIZES.sm} />
          </>
        )}
        {isMobile && activeTable?.filterBar && (
          <FiltersContainer noActions={!exportAction}>
            {exportAction && <div>{exportAction.node}</div>}
            {/* @ts-ignore */}
            <MobileFilters {...activeTable.filterBar} />
          </FiltersContainer>
        )}
        <ContentContainer>
          {banner}
          {activeTable.filterBar && <FilterBar {...activeTable.filterBar} />}
          {activeTable.node}
        </ContentContainer>
      </Container>
    </MainContainer>
  );
};

export default TablePage;
