import styled from "@emotion/styled";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";

import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

const darkWheelDarkBackgroundTheme = {
  progressIncomplete: COLORS.black,
  currentNumberColor: COLORS.stepsWheel.wheel.background,
  totalNumberColor: COLORS.lightPurple,
  currentTextColor: COLORS.stepsWheel.wheel.background,
  nextTextColor: COLORS.background,
};

const lightVioletDarkBackgroundTheme = {
  progressIncomplete: COLORS.lightVioletTheme.stepsWheel,
  currentNumberColor: COLORS.stepsWheel.wheel.background,
  totalNumberColor: COLORS.stepsWheel.wheel.background,
  currentTextColor: COLORS.stepsWheel.wheel.background,
  nextTextColor: COLORS.violetTheme.text,
};

const lightVioletLightBackgroundTheme = {
  progressIncomplete: COLORS.lightVioletTheme.stepsWheel,
  currentNumberColor: COLORS.primary,
  totalNumberColor: COLORS.primary,
  currentTextColor: COLORS.primary,
  nextTextColor: COLORS.primary,
};

const violetTheme = {
  progressIncomplete: COLORS.violetTheme.stepsWheel,
  currentNumberColor: COLORS.stepsWheel.wheel.background,
  totalNumberColor: COLORS.secondary,
  currentTextColor: COLORS.stepsWheel.wheel.background,
  nextTextColor: COLORS.violetTheme.text,
};

const defaultTheme = {
  progressIncomplete: COLORS.stepsWheel.wheel.progressIncomplete,
  currentNumberColor: null,
  totalNumberColor: COLORS.secondary,
  currentTextColor: null,
  nextTextColor: COLORS.secondary,
};

const STEPS_WHEEL_THEMES = Object.freeze({
  darkWheelDarkBackground: "darkWheelDarkBackground",
  lightVioletDarkBackground: "lightVioletDarkBackground",
  lightVioletLightBackground: "lightVioletLightBackground",
  violet: "violet",
  default: "default",
});

const THEMES = {
  [STEPS_WHEEL_THEMES.darkWheelDarkBackground]: darkWheelDarkBackgroundTheme,
  [STEPS_WHEEL_THEMES.lightVioletDarkBackground]:
    lightVioletDarkBackgroundTheme,
  [STEPS_WHEEL_THEMES.lightVioletLightBackground]:
    lightVioletLightBackgroundTheme,
  [STEPS_WHEEL_THEMES.violet]: violetTheme,
  [STEPS_WHEEL_THEMES.default]: defaultTheme,
};

const Container = styled("div")({
  display: "flex",
  alignItems: "center",
});

const wheelSize = "54px";

export interface StyledProps {
  wheelSize?: number;
  theme?: any;
  currentNumSize?: number;
  slashSize?: string;
  totalNumSize?: string;
}

const WheelContainer = styled("div")<StyledProps>((props) => ({
  width: props.wheelSize || wheelSize,
  height: props.wheelSize || wheelSize,
  marginRight: "14px",
  "div[data-test-id='CircularProgressbarWithChildren__children']": {
    top: 0,
    marginTop: "0 !important",
    width: `${props.wheelSize || wheelSize} !important`,
    height: `${props.wheelSize || wheelSize} !important`,
  },
}));

const Wheel = styled(CircularProgressbarWithChildren)<StyledProps>((props) => ({
  ".CircularProgressbar-path": {
    stroke: COLORS.stepsWheel.wheel.progressComplete,
  },
  ".CircularProgressbar-trail": {
    stroke: THEMES[props.theme].progressIncomplete,
  },
  ".CircularProgressbar-background": {
    fill: COLORS.stepsWheel.wheel.background,
  },
}));

const Fraction = styled("span")({});

const CurrentNumber = styled("span")<StyledProps>((props) => ({
  fontSize: props.currentNumSize || "20px",
  fontWeight: 500,
  color: THEMES[props.theme].currentNumberColor,
}));

const Slash = styled("span")<StyledProps>((props) => ({
  color: THEMES[props.theme].totalNumberColor,
  fontSize: props.slashSize || "15px",
}));

const TotalSteps = styled("span")<StyledProps>((props) => ({
  color: THEMES[props.theme].totalNumberColor,
  fontSize: props.totalNumSize || "15px",
}));

const CurrentText = styled("div")<StyledProps>((props) => ({
  fontWeight: 500,
  color: THEMES[props.theme].currentTextColor,
}));

const NextText = styled("div")<StyledProps>`
  font-weight: 300;
  font-size: 12px;
  color: ${(props) => THEMES[props.theme].nextTextColor};
  ${MEDIA_QUERIES.w.ltSm} {
    position: absolute;
    top: 15;
    right: 20;
  }
`;

export {
  Container,
  WheelContainer,
  Wheel,
  Fraction,
  CurrentNumber,
  Slash,
  TotalSteps,
  CurrentText,
  NextText,
  STEPS_WHEEL_THEMES,
};
