import React from "react";

import { FormikHelpers } from "formik";
import { useParams } from "react-router-dom";

import { useVerifyEmail } from "surveys/hooks/api";
import { SurveyUrlParams } from "surveys/interfaces/api";

import { EmailVerificationFormProps } from "./interfaces";
import LandingStep from "./LandingStep";

const LandingStepContainer = ({ applicantName }) => {
  const { surveyHashId } = useParams<SurveyUrlParams>();
  const { verifyEmail } = useVerifyEmail();

  const submit = async (
    values: EmailVerificationFormProps,
    formikApi: FormikHelpers<EmailVerificationFormProps>
  ) => {
    try {
      await verifyEmail({
        ...values,
        surveyHashId,
      });
    } finally {
      formikApi.setSubmitting(false);
    }
  };

  return <LandingStep applicantName={applicantName} submit={submit} />;
};

export default LandingStepContainer;
