import React, { useEffect, useState } from "react";

import pluralize from "pluralize";

import {
  useAssignPropertiesToPortfolio,
  useGetPropertiesPerfAssignToPortfolio,
} from "manager/hooks/api";
import { NOTIFICATIONS } from "shared/config/constants";
import { useDeviceType } from "shared/hooks";
import { openNotification } from "shared/utils/ui";

import AssignPropertiesToPortfolioModal from "./AssignPropertiesToPortfolioModal";

interface AssignPropertiesToPortfolioModalContainerProps {
  portfolioId: number;
  portfolioName: string;
}

const AssignPropertiesToPortfolioModalContainer = ({
  portfolioId,
  portfolioName,
}: AssignPropertiesToPortfolioModalContainerProps) => {
  const { isMobile } = useDeviceType();
  const [checkedItems, setCheckedItems] = useState([]);
  const [nameOrAddress, setNameOrAddress] = useState<string>();

  const {
    data: properties,
    isLoading: propertiesLoading,
    isFetching: isPropertiesFetching,
    refetch: refetchProperties,
  } = useGetPropertiesPerfAssignToPortfolio({
    params: {
      hasPortfolio: 1,
      nameOrAddress,
    },
  });

  const { assignPropertiesToPortfolio, isAssignPropertiesToPortfolio } =
    useAssignPropertiesToPortfolio({ portfolioId });

  const selectedProperties = properties?.filter(
    (property) => checkedItems?.indexOf(property.id) > -1
  );

  const showWarning = selectedProperties?.some(
    (property) => !property.isDealSetupFromPortfolio
  );

  const checkedItemsOnChange = (items: number[]) => setCheckedItems(items);

  const enableSerchAndFilters = Boolean(properties?.length);

  const actionSearch = (value: string) => setNameOrAddress(value);

  useEffect(() => {
    refetchProperties();
  }, [nameOrAddress]);

  const submit = () =>
    assignPropertiesToPortfolio(
      selectedProperties.map((selectedProperty) => selectedProperty.id),
      {
        onSuccess: () =>
          openNotification(
            `${pluralize(
              "Property",
              properties.length
            )} has been successfully assign to ${portfolioName} portfolio`,
            NOTIFICATIONS.info
          ),
      }
    );

  return (
    <AssignPropertiesToPortfolioModal
      properties={properties}
      loading={propertiesLoading || isPropertiesFetching}
      submitting={isAssignPropertiesToPortfolio}
      assignPropertiesToPortfolio={submit}
      checkedItemsOnChange={checkedItemsOnChange}
      enableSerchAndFilters={enableSerchAndFilters}
      portfolioName={portfolioName}
      selectedProperties={selectedProperties}
      actionSearch={actionSearch}
      showWarning={showWarning}
      hasInputValue={!!nameOrAddress}
      isMobile={isMobile}
    />
  );
};

export default AssignPropertiesToPortfolioModalContainer;
