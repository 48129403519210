import React from "react";

import { FormikProps, FormikHelpers } from "formik";

import { PrescreeningForApplicants } from "manager/components/DealSetupSettings/ScreeningForApplicantsAndOccupants";
import { DealSetup } from "manager/interfaces/api";
import { FormPromptShell } from "shared/components/Form";

const INITIAL_VALUES = {};

interface PrescreeningSettingsProps {
  loading: boolean;
  dealSetup: DealSetup;
  saveChanges: (values: DealSetup) => void;
  onSubmit: (values: DealSetup, form: FormikHelpers<DealSetup>) => void;
  formRef: React.Ref<FormikProps<DealSetup>>;
  disabled: boolean;
  shouldDisableSubmitButton?: boolean;
  submitButtonLabel?: string;
  showSecondaryButton: boolean;
  onSecondaryButtonClick?: () => void;
  secondaryButtonLabel?: string;
}

const PrescreeningSettings = ({
  loading,
  dealSetup,
  saveChanges,
  onSubmit,
  formRef,
  disabled,
  shouldDisableSubmitButton = true,
  submitButtonLabel,
  showSecondaryButton,
  onSecondaryButtonClick,
  secondaryButtonLabel,
}: PrescreeningSettingsProps) => {
  const getPromptProps = ({ isValid }) => {
    return {
      title: "Unsaved changes",
      subtitle: isValid
        ? "Would you like to save the changes?"
        : "The changes you made are not valid and will not be saved.",
      submitButtonLabel: isValid ? "Yes" : "OK",
      cancelLinkLabel: isValid ? "No" : null,
    };
  };
  return (
    <FormPromptShell
      initialValues={dealSetup || INITIAL_VALUES}
      onSubmit={onSubmit}
      submitButtonLabel={submitButtonLabel}
      shouldDisableSubmitButton={shouldDisableSubmitButton}
      saveChanges={saveChanges}
      formRef={formRef}
      getPromptProps={getPromptProps}
      showSecondaryButton={showSecondaryButton}
      onSecondaryButtonClick={onSecondaryButtonClick}
      secondaryButtonLabel={secondaryButtonLabel}
    >
      <PrescreeningForApplicants loading={loading} disabled={disabled} />
    </FormPromptShell>
  );
};

export default PrescreeningSettings;
