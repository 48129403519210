import React from "react";

import { useHistory } from "react-router-dom";

import ROUTES from "admin/config/routes";
import { useGetDocumentDrafts } from "admin/hooks/api";
import useFileUpload from "admin/hooks/useFileUpload";
import { transformRoute } from "shared/utils/routing";

import FileUpload from "./FileUpload";

const FileUploadContainer = ({
  onFileSelect,
}: {
  onFileSelect: (initialState: File | (() => File)) => void;
}) => {
  const { fileUpload } = useFileUpload();
  const history = useHistory();

  const { data: drafts } = useGetDocumentDrafts();

  const selectFile = (file: File) => {
    fileUpload({
      file,
      drafts,
      isDraftSuccess: (draft) =>
        history.push(transformRoute(ROUTES.draftConstructor, { id: draft.id })),
      isNoDaftUpload: () => onFileSelect(file),
    });
  };

  return <FileUpload selectFile={selectFile} />;
};

export default FileUploadContainer;
