import React from "react";

import { UNIT_STATUSES } from "manager/config/constants";
import Icon from "shared/components/Icon";

import { UnitStatusProps } from "../interfaces";

import { StatusContainer } from "./styled";

const UnitStatus = ({ status = "N/A" }: UnitStatusProps) => {
  const getIcon = () => {
    switch (status) {
      case UNIT_STATUSES.RE.key:
        return <Icon.RentedUnitIcon />;
      case UNIT_STATUSES.AL.key:
      case UNIT_STATUSES.PL.key:
        return <Icon.ActiveListingUnitIcon />;
      default:
        return <Icon.NoListingUnitIcon />;
    }
  };

  return (
    <StatusContainer>
      {getIcon()}
      <span>{UNIT_STATUSES[status]?.label}</span>
    </StatusContainer>
  );
};

export default UnitStatus;
