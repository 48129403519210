import styled from "@emotion/styled";

import FlexContainer from "shared/components/FlexContainer";
import { MEDIA_QUERIES } from "shared/config/constants";

const UnitNameWrapper = styled("div")({
  flexGrow: 1,
});

const UnitRow = styled(FlexContainer)((props) => ({
  padding: !props.noPadding && "24px 33px 24px 58px",
  background: "#F9FAFC",
  border: "1px solid #E8E7EE",
  borderBottom: "none",
  marginLeft: 24,
  width: "calc(100% - 48px)",
  "&:last-child": {
    border: "1px solid #E8E7EE",
  },
  [MEDIA_QUERIES.w.ltSm]: {
    padding: 10,
    marginLeft: 0,
    width: "100%",
  },
}));

export { UnitNameWrapper, UnitRow };
