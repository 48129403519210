import axios from "axios";

import ROUTES from "manager/config/apiRoutes";
import { API_URL } from "shared/lib/api/setupApi";
import { transformRoute } from "shared/utils/routing";

const api = {
  getAssignableEmployees: (dealId) =>
    axios.get(
      `${API_URL}${transformRoute(ROUTES.assignableEmployees, { id: dealId })}`
    ),
  assignEmployeeToDeal: ({ dealId, employeeId }) =>
    axios.patch(
      `${API_URL}${transformRoute(ROUTES.assignEmployeeToDeal, {
        id: dealId,
      })}`,
      { employeeId }
    ),
};

export default api;
