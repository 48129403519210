import React from "react";

import * as Yup from "yup";

import { Input, InputProps } from "../Input";

const schema = Yup.string().max(12, "Zip code must be 12 characters at most");

export const ZipCodeInput = Object.assign(
  ({ ...props }: InputProps) => (
    <Input
      label="Zip Code"
      id="zipcode"
      name="zipcode"
      maxLength={12}
      type="text"
      {...props}
    />
  ),
  { schema }
);
