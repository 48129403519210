import styled from "@emotion/styled";

import { PopoverContent } from "shared/components/Popover";

export const StatedEmployersPopoverContent = styled(PopoverContent)`
  padding: 16px;

  .employer-name {
    display: block;
    line-height: 130%;
    padding-bottom: 5px;

    &:last-child {
      padding-bottom: 0;
    }
  }
`;

export const MultipleEmployersWrapper = styled.div`
  display: inline-block;
  > * {
    display: inline-block;
  }
  border-bottom: 1px dashed;
  cursor: pointer;
`;
