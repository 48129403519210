import React from "react";

import {
  Container,
  Text,
  InfoIcon,
  WarningIcon,
  ValidationErrorIcon,
  OrangeExclamationIcon,
} from "./styled";

export enum NOTICE_TYPES {
  INFO = "info",
  WARNING = "warning",
  VALIDATION_ERROR = "validationError",
  LIGHT_WARNING = "lightWarning",
}

interface NoticeProps {
  children: React.ReactNode;
  type: "info" | "warning" | "validationError" | "lightWarning";
  fontWeight?: number;
  [key: string]: any;
}
const Notice = (allProps: NoticeProps) => {
  const { children, type, fontWeight, ...props } = allProps;
  return (
    <Container {...props}>
      {type === "info" && <InfoIcon data-testid="info-icon" />}
      {type === "warning" && <WarningIcon data-testid="warning-icon" />}
      {type === "validationError" && (
        <ValidationErrorIcon data-testid="validation-icon" />
      )}
      {type === "lightWarning" && (
        <OrangeExclamationIcon data-testid="light-warning-icon" />
      )}
      <Text fontWeight={fontWeight}>{children}</Text>
    </Container>
  );
};

export default Notice;
