import React from "react";

import { BREAKPOINT_TYPES } from "shared/config/constants";

import { Container as StyledContainer } from "./styled";

interface ContainerProps {
  children: React.ReactNode | Element;
  noPadding?: boolean;
  noPaddingTop?: boolean;
  noPaddingBottom?: boolean;
  noPaddingRight?: boolean;
  noPaddingLeft?: boolean;
  noMobilePaddingX?: boolean;
  noMobilePaddingY?: boolean;
  expand?: boolean;
  stickToBottom?: boolean;
  maxWidth?: number | typeof BREAKPOINT_TYPES | any;
  backgroundColor?: string;
  centered?: boolean;
  className?: string;
  [key: string]: any;
}

const Container = ({
  noPadding = false,
  noPaddingTop = false,
  noPaddingBottom = false,
  noPaddingRight = false,
  noPaddingLeft = false,
  noMobilePaddingX = false,
  noMobilePaddingY = false,
  expand = false,
  stickToBottom = false,
  maxWidth = undefined,
  children,
  backgroundColor = undefined,
  ...props
}: ContainerProps) => (
  <StyledContainer
    {...props}
    noPadding={noPadding}
    noPaddingTop={noPaddingTop}
    noPaddingBottom={noPaddingBottom}
    noPaddingRight={noPaddingRight}
    noPaddingLeft={noPaddingLeft}
    noMobilePaddingX={noMobilePaddingX}
    noMobilePaddingY={noMobilePaddingY}
    expand={expand}
    stickToBottom={stickToBottom}
    maxWidth={maxWidth}
    backgroundColor={backgroundColor}
  >
    {children}
  </StyledContainer>
);

export default Container;
