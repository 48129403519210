import styled from "@emotion/styled";

import FlexContainer from "shared/components/FlexContainer";
import BaseLargeLoader from "shared/components/LargeLoader";
import { MEDIA_QUERIES } from "shared/config/constants";

const DashboardContainer = styled.div`
  .back-link {
    margin: 16px 0px 0px 24px;
  }

  .application-submission-col {
    height: 743px;

    .stacked-bar-chart {
      height: 530px;
    }
  }

  ${MEDIA_QUERIES.w.ltMd} {
    .content > div {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  .dashboard-tile-col {
    height: 580px;
  }
`;

const EmptyChartFlexContainer = styled(FlexContainer)`
  height: 100%;
  text-align: center;
  max-width: 350px;
  margin: auto;

  .icons {
    margin-bottom: 32px;

    svg:first-of-type {
      margin-right: 32px;
    }
  }
`;

const LargeLoader = styled(BaseLargeLoader)`
  z-index: 1000;
`;

export { DashboardContainer, EmptyChartFlexContainer, LargeLoader };
