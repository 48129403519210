import styled from "@emotion/styled";
import { Steps } from "antd";

import { COLORS } from "shared/config/constants";

const { Step: BaseStep } = Steps;

export interface StyledProps {
  instructions?: string | boolean;
}

// eslint-disable-next-line vero/styled-tagged-template
const Container = styled(Steps)(({ instructions }: StyledProps) => ({
  ".ant-steps-item-finish": {
    ".ant-steps-item-tail::after": {
      backgroundColor: `${COLORS.steps.tail} !important`,
    },
  },
  ".ant-steps-item-finish, .ant-steps-item-process": {
    ".ant-steps-icon-dot": {
      backgroundColor: `${COLORS.steps.dot} !important`,
    },
  },
  ".ant-steps-item-wait": {
    ".ant-steps-icon-dot, .ant-steps-item-tail::after": {
      backgroundColor: "#E8E7EE !important",
    },
  },
  ".ant-steps-item-tail": {
    top: "4px",
    margin: "0 0 0 65px",
    "&::after": {
      height: "4px",
      width: "100%",
    },
  },
  ".ant-steps-icon-dot": {
    width: "12px !important",
    height: "12px !important",
    top: "0 !important",
  },
  ".ant-steps-item-title": {
    fontWeight: 300,
    fontSize: "15px",
    color: `${COLORS.steps.title} !important`,
  },
  ".ant-steps-item-content": {
    marginTop: "14px",
  },
  ...(instructions && {
    "&.ant-steps-vertical.ant-steps-dot .ant-steps-item > .ant-steps-item-container":
      {
        "& > .ant-steps-item-tail": {
          top: "-6px",
          left: "-7px",
        },
        ".ant-steps-item-tail::after": {
          backgroundColor: `${COLORS.borders} !important`,
          height: "calc(100% + 35px)",
        },
        ".ant-steps-item-content": {
          minHeight: "25px",
          width: "auto",
        },
        ".ant-steps-icon": {
          top: "-3px",
        },
      },
  }),
}));

const Step = styled(BaseStep)``;

export { Container, Step };
