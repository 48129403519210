import styled from "@emotion/styled";

import { RegularText } from "shared/components/Typography";
import { MEDIA_QUERIES, COLORS } from "shared/config/constants";

const Container = styled.div(() => ({
  paddingRight: "20px",
}));

const Description = styled.label(() => ({
  color: COLORS.textColorSecondary,
  marginBottom: 0,
}));

const TitleContainer = styled.div((props) => ({
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",

  ".flex-grow:last-of-type": {
    flexGrow: 1,
  },

  [MEDIA_QUERIES.w.ltLg]: {
    fontSize: "22px",
    paddingTop: "24px",
    paddingBottom: props.hasSubTitle ? "12px" : "24px",
  },
  [MEDIA_QUERIES.w.ltSm]: {
    paddingTop: "16px",
    paddingBottom: props.hasSubTitle ? "12px" : "16px",
    fontSize: "20px",
    lineHeight: "130%",
  },
  [MEDIA_QUERIES.w.gteLg]: {
    fontSize: "22px",
    paddingTop: "24px",
    paddingBottom: props.hasSubTitle ? "12px" : "24px",
  },
}));

const SubTitle = styled.label(() => ({
  fontWeight: 300,
  display: "flex",
  alignItems: "center",
  paddingBottom: "24px",
  [MEDIA_QUERIES.w.ltSm]: {
    lineHeight: "150%",
    paddingBottom: "16px",
  },
}));

const Title = styled.label(() => ({
  color: COLORS.textColorPrimary,
  marginRight: "6px",
  [MEDIA_QUERIES.w.ltSm]: {
    lineHeight: "130%",
  },
}));

const DisabledLink = styled(RegularText)`
  color: ${COLORS.button.primary.disabled.background};
  cursor: pointer;
  text-transform: uppercase;
`;

export {
  Container,
  Description,
  SubTitle,
  Title,
  TitleContainer,
  DisabledLink,
};
