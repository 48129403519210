import styled from "@emotion/styled";

import ApplicantsCheckList from "shared/components/ApplicantsCheckList";
import FlexContainer from "shared/components/FlexContainer";
import { ActionLink } from "shared/components/Links";
import BaseProgressBar from "shared/components/ProgressBar";
import { RegularText, SmallText } from "shared/components/Typography";
import { COLORS, MEDIA_QUERIES, colors } from "shared/config/constants";

const ProgressBar = styled(BaseProgressBar)`
  margin: 16px 0 12px;
  width: 200px;
`;

const NotAvailableSpan = styled.span`
  font-weight: 300;
  color: ${COLORS.secondary};
`;

const PrimaryText = styled(RegularText)`
  & ul li {
    margin-left: 0;
    padding-left: 0;
  }
`;

const SecondaryText = styled(SmallText)`
  color: ${COLORS.secondary};
`;

const FlexWrapper = styled(FlexContainer)`
  & > * {
    margin-right: 5px;
  }
`;

const Cell = styled(FlexContainer)`
  flex-direction: column;
  justify-content: center;
  min-height: 70px;
  ${MEDIA_QUERIES.w.ltSm} {
    min-height: auto;
  }
`;

const StyledCheckList = styled(ApplicantsCheckList)`
  color: ${COLORS.primary};
`;

const Link = styled(ActionLink)`
  min-height: 40px;
  align-items: center;
  color: ${colors.secondary.base};
`;

const LinkText = styled(FlexContainer)`
  min-height: 40px;
  align-items: center;
  color: ${colors.secondary.base};
  text-transform: uppercase;
  font-weight: 500;
`;

export {
  ProgressBar,
  NotAvailableSpan,
  PrimaryText,
  SecondaryText,
  FlexWrapper,
  Cell,
  StyledCheckList,
  Link,
  LinkText,
};
