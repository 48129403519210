import React, { useRef, useState } from "react";

import { useParams } from "react-router-dom";

import {
  useGetTempMagicLinksCompany,
  usePostTempMagicLinksCompany,
} from "renter/hooks/api";
import { getQueryParams } from "shared/utils/routing";

import MagicLinkLanding from "./MagicLinkLanding";
import { MAGIC_LINK_LANDING_STEP } from "./magicLinkLanding.config";

const MagicLinkLandingContainer = () => {
  const formikRef = useRef(null);
  const { companyHashId } = useParams();
  const { source } = getQueryParams();
  const { companyProfile, isCompanyProfileLoading } =
    useGetTempMagicLinksCompany(companyHashId);
  const { logoUrl } = companyProfile || {};
  const { sendTempMagicLinkApplicationData } = usePostTempMagicLinksCompany();
  const [currentStep, setCurrentStep] = useState(
    MAGIC_LINK_LANDING_STEP.LANDING_STEP
  );
  const [email, setEmail] = useState();
  const externalUrl = source ? decodeURI(source) : document.referrer;

  const submit = async (values, { setSubmitting }) => {
    setEmail(values?.email);
    const dto = {
      firstName: values?.firstName,
      lastName: values?.lastName,
      email: values?.email,
      externalUrl,
    };

    try {
      await sendTempMagicLinkApplicationData({ companyHashId, dto });
      setSubmitting(false);
      setCurrentStep(MAGIC_LINK_LANDING_STEP.CONFIRMATION_STEP);
    } catch (error) {
      if (error?.errors && formikRef.current) {
        formikRef.current.setErrors(error.errors);
      }
      setSubmitting(false);
    }
  };

  return (
    <MagicLinkLanding
      submit={submit}
      isCompanyProfileLoading={isCompanyProfileLoading}
      logoUrl={logoUrl}
      currentStep={currentStep}
      email={email}
      formikRef={formikRef}
    />
  );
};

export default MagicLinkLandingContainer;
