import { normalize } from "normalizr";
import { handleActions } from "redux-actions";

import * as actions from "../actions";
import * as schemas from "../schema";

const initialState = {};

const documentTemplateEntityReducer = handleActions(
  {
    [actions.receiveDocumentTemplates]: (state, action) => {
      const { documents } = action.payload;

      return {
        ...state,
        ...normalize(documents, schemas.documentTemplates).entities
          .documentTemplate,
      };
    },
  },
  initialState
);

export default documentTemplateEntityReducer;
