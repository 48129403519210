import axios from "axios";
import isArray from "lodash/isArray";

import ROUTES from "manager/config/apiRoutes";
import { API_URL } from "shared/lib/api/setupApi";
import { transformRoute } from "shared/utils/routing";

const api = {
  addApplicationAttachments: async ({
    applicationId,
    file,
    metadata: { requiredAttachmentId },
  }) => {
    const formData = new FormData();
    const files = isArray(file) ? file : [file];
    files.forEach((newFile) => {
      formData.append("upload", newFile?.file || newFile);
      formData.append("is_public", Boolean(newFile?.isPublic));
    });
    if (requiredAttachmentId) {
      formData.append("attachment_request", `${requiredAttachmentId}`);
    }
    return axios
      .post(
        `${API_URL}${transformRoute(ROUTES.addAttachments, {
          applicationId,
        })}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(({ data }) => data);
  },
  deleteApplicationAttachments: async ({ applicationId, attachmentId }) =>
    axios.delete(
      `${API_URL}${transformRoute(ROUTES.deleteAttachments, {
        applicationId,
      })}`,
      {
        data: {
          attachments: isArray(attachmentId) ? attachmentId : [attachmentId],
        },
      }
    ),
  cancelAttachments: ({ applicationId }) =>
    axios.post(
      `${API_URL}${transformRoute(ROUTES.cancelAttachments, {
        applicationId,
      })}`
    ),
  confirmAttachments: ({ applicationId, attachmentsChangedVisiblility }) =>
    axios
      .post(
        `${API_URL}${transformRoute(ROUTES.confirmAttachments, {
          applicationId,
        })}`,
        attachmentsChangedVisiblility
      )
      .then(({ data }) => data),
  setBlockDealSubmitIfIncomeMissing: ({
    applicationId,
    blockDealSubmitIfIncomeDataIsMissing,
  }) =>
    axios
      .post(
        `${API_URL}${transformRoute(ROUTES.blockDealSubmitIfIncomeMissing, {
          applicationId,
        })}`,
        {
          blockDealSubmitIfIncomeDataIsMissing,
        }
      )
      .then(({ data }) => data),
};

export default api;
