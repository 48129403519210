import styled from "@emotion/styled";

import { ActionLink } from "shared/components/Links";
import { MEDIA_QUERIES } from "shared/config/constants";

export const CheckboxRow = styled.div<{ isLastItem?: Boolean }>((props) => ({
  display: "flex",
  flexWrap: "wrap",
  paddingBottom: props.isLastItem ? "32px" : "25px",
  color: "#2D2943",
  [MEDIA_QUERIES.w.ltSm]: {
    paddingBottom: props.isLastItem ? "33px" : "25px",
    lineHeight: "150%",
  },
}));

export const StyledActionLink = styled(ActionLink)(() => ({
  paddingBottom: "40px",
  textTransform: "uppercase",
  [MEDIA_QUERIES.w.ltLg]: {
    paddingBottom: "32px",
  },
  [MEDIA_QUERIES.w.gteLg]: {
    paddingBottom: "40px",
  },
}));

export const Description = styled.label(() => ({
  color: "#807E8D",
  marginLeft: "6px",
  [MEDIA_QUERIES.w.ltSm]: {
    display: "flex",
    marginLeft: "27px",
  },
}));
