import React, { Dispatch, SetStateAction, useEffect } from 'react'

import {
  AssignPropertiesToPortfolioModal,
  RenamePortfolioModal,
} from 'manager/components/Modal'
import { useGetPortfolios } from 'manager/hooks/api'
import useModal from 'shared/hooks/useModal'

import { PortfolioSearchParams, DefaultPagination, Sorter } from '../interfaces'

import PortfoliosTable from './PortfoliosTable'

interface PortfoliosTableContainerProps {
  portfolioSearchParams: PortfolioSearchParams
  hasInputValue: boolean
  setPagination: Dispatch<SetStateAction<DefaultPagination>>
  pagination: DefaultPagination
  onPortfolioTableChange: (
    tablePagination: DefaultPagination,
    _tableFilters: object[],
    sorter: Sorter,
  ) => void
}

const PortfoliosTableContainer = ({
  portfolioSearchParams,
  hasInputValue,
  setPagination,
  pagination,
  onPortfolioTableChange,
}: PortfoliosTableContainerProps) => {
  const { openModalDialog } = useModal()
  const {
    data: portfoliosTableData,
    isLoading,
    refetch,
  } = useGetPortfolios({
    ...portfolioSearchParams,
  })

  const renamePortfolio = (context) =>
    openModalDialog(RenamePortfolioModal, context)
  const assignPropertiesToPortfolio = (context) =>
    openModalDialog(AssignPropertiesToPortfolioModal, context)

  useEffect(() => {
    refetch()
  }, [portfolioSearchParams])

  return (
    <PortfoliosTable
      hasInputValue={hasInputValue}
      setPagination={setPagination}
      pagination={pagination}
      onPortfolioTableChange={onPortfolioTableChange}
      portfolios={portfoliosTableData?.results}
      loading={isLoading}
      portfolioCount={portfoliosTableData?.count}
      renamePortfolio={renamePortfolio}
      assignPropertiesToPortfolio={assignPropertiesToPortfolio}
    />
  )
}

export default PortfoliosTableContainer
