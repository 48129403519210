import React from "react";

import { Formik } from "formik";

import flatten from "lodash/flatten";

import styled from "styled-components";

import { Button, BUTTON_SIZES } from "shared/components/Button";
import FlexContainer from "shared/components/FlexContainer";
import { CollapsibleCheckboxContainer } from "shared/components/Form";
import Icon from "shared/components/Icon";
import { UnderlineLink } from "shared/components/Links";
import Spacer from "shared/components/Spacer";
import { LargeTitle, RegularText } from "shared/components/Typography";
import { VeroModal } from "shared/components/vero-modal";

import { ApplicantsCheckList, Subtitle } from "../styled";

import { DealSummary } from "../types";

import ConditionallyApproveForm from "./blocks";
import { useMoreInfoDealModal } from "./context";
import { useHook } from "./hooks";

const StyledList = styled(CollapsibleCheckboxContainer)({
  padding: 0,
});

const PersonName = styled("span")({
  fontSize: "16px",
  marginLeft: "10px",
});

const PersonIcon = styled(Icon.PersonSmallIcon)({
  flexShrink: 0,
});

export const MoreInfoDealModal = ({ deal }: { deal: DealSummary }) => {
  const { init, schema, approve } = useHook(deal);
  const state = useMoreInfoDealModal();
  const { cancel$, submit$, submitting } = state;
  return (
    <Formik
      initialValues={init}
      validationSchema={schema}
      validateOnMount
      enableReinitialize
      onSubmit={(values) => submit$.next(approve(values))}
    >
      {({ errors, submitForm, values, setValues, isValid }) => (
        <VeroModal {...state} style={{ maxWidth: 776 }} width="auto">
          <LargeTitle>Request More Info</LargeTitle>
          <Subtitle>
            <RegularText strong>Applicants:&nbsp;</RegularText>
            <ApplicantsCheckList inline items={deal?.applicantsSummary} />
          </Subtitle>
          <Spacer size={Spacer.SIZES.default} />

          <p>
            Which applicant would you like to request additional requirements?
          </p>

          <StyledList
            checkedItemsOnChange={(value: string[]) => {
              const checkedItems = value.filter(
                (item) => !`${item}`.startsWith("form")
              );
              setValues({
                applicants: values.applicants.map((data) => ({
                  ...data,
                  selected: checkedItems.indexOf(data.application) > -1,
                })),
              });
            }}
            listProps={{
              itemProps: {
                noBorderBottom: true,
                noBorderTop: true,
                noBorderX: true,
                style: { paddingBottom: 0 },
              },
              hideUnchecked: true,
              col1Width: "600px",
              items:
                flatten(
                  deal?.applicantsSummary?.map(
                    ({ firstName, lastName, id }, index) => [
                      {
                        key: id,
                        component: (
                          <>
                            <PersonIcon />
                            <PersonName>
                              {firstName} {lastName}
                            </PersonName>
                          </>
                        ),
                        collapsible: true,
                      },
                      {
                        key: `form${id}`,
                        parent: id,
                        notCheckBox: true,
                        component: ConditionallyApproveForm,
                        componentProps: {
                          errors: errors?.applicants?.[index],
                          index,
                        },
                      },
                    ]
                  )
                ) || [],
            }}
          />

          <Spacer size={Spacer.SIZES.md} />
          <FlexContainer
            justifyContent="flex-end"
            gap="24px"
            alignItems="center"
          >
            <UnderlineLink onClick={() => cancel$.next()}>Cancel</UnderlineLink>
            <Button
              type="primary"
              onClick={submitForm}
              size={BUTTON_SIZES.lg}
              loading={submitting}
              disabled={
                !values.applicants?.some(({ selected }) => selected) || !isValid
              }
            >
              Confirm &amp; Send
            </Button>
          </FlexContainer>
        </VeroModal>
      )}
    </Formik>
  );
};
