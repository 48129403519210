import React from "react";

import { DocumentsProps } from "./interfaces";
import {
  DocumentListContainer,
  DocumentList,
  DocumentListTitle,
  DocumentListItem,
} from "./styled";

const AssignedDocuments = ({
  companyName = "",
  documentNames = [],
}: DocumentsProps) => (
  <DocumentListContainer>
    <DocumentList>
      <DocumentListTitle>Assigned to {companyName}:</DocumentListTitle>
      {documentNames.map((documentName) => (
        <DocumentListItem key={documentName}>{documentName}</DocumentListItem>
      ))}
    </DocumentList>
  </DocumentListContainer>
);

export default AssignedDocuments;
