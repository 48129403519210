import styled from "@emotion/styled";

import Image from "shared/components/Image";
import { DIMENSIONS, MEDIA_QUERIES } from "shared/config/constants";

const CityBackground = styled(Image.CityBackground)`
position: fixed;
left: 50%;
padding: 0 24px;
bottom: 0;
  max-width: 100%;
  height: ;auto;
  ${MEDIA_QUERIES.w.gteXl} {
    transform: translateX(calc(-50% + ${
      DIMENSIONS.sidebar.gteXl.widthInt / 2
    }px)),
  };
  ${MEDIA_QUERIES.w.ltXl} {
    transform: translateX(calc(-50% + ${
      DIMENSIONS.sidebar.ltXl.widthInt / 2
    }px));
  }
  ${MEDIA_QUERIES.w.ltSm} {
    display: none;
  }
  `;

export { CityBackground };
