import React from "react";

import { useGetStates } from "manager/hooks/api";
import FilterBar from "shared/components/FilterBar";
import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import { ActionLink, IconLink } from "shared/components/Links";
import Table from "shared/components/Table";
import { RegularText } from "shared/components/Typography";
import useModal from "shared/hooks/useModal";

import {
  BluemoonConnectLicenseModal,
  BluemoonEditLicenseModal,
} from "../bluemoonAlertsResolvers";

import { Filters } from "../interfaces";

import { TableActionLinkContainer } from "./styled";

const LicensesTable = ({ licenses = [], loading, filters, updateFilters }) => {
  const { openModalDialog } = useModal();
  const { states: apiStates } = useGetStates();

  const tableFilters = {
    title: "",
    search: {
      action: (value: Filters) => updateFilters({ idNameOrState: value }, 200),
      placeholder: "Search by name or state",
      width: 320,
      debounce: true,
      disabled: false,
      value: filters.idNameOrState,
    },
    action: {
      node: (
        <ActionLink
          onClick={() => openModalDialog(BluemoonConnectLicenseModal)}
        >
          connect a license
        </ActionLink>
      ),
    },
    dropdownFilters: apiStates?.length > 0 && [
      {
        key: "state",
        label: "State",
        onChange: (value: Filters) => updateFilters({ state: value }),
        value: filters.state,
        items: [
          {
            label: "State",
            key: "",
          },
          ...apiStates?.map((state) => ({
            key: state.id,
            label: state.value,
          })),
        ],
      },
    ],
    clearFilters: {
      onClick: () => updateFilters(),
      order: 2,
    },
  };

  return (
    <>
      <FilterBar {...tableFilters} />
      <Table
        alt
        searching={Boolean(filters.idNameOrState)}
        noClickableRows
        loading={loading}
        showActionColumn
        columns={[
          {
            title: "Licence ID",
            dataIndex: "code",
            key: "code",
          },
          {
            title: "Licence Name",
            dataIndex: "name",
            key: "name",
          },
          {
            title: "State",
            dataIndex: "state",
            key: "state",
          },
          {
            title: "Bluemoon Properties",
            dataIndex: "propertiesCount",
            key: "propertiesCount",
          },
        ]}
        rows={licenses?.map((license) => ({
          key: license.code,
          viewed: !license.isActive,
          values: [
            <FlexContainer alignItems="center">
              <RegularText strong light={!license.isActive}>
                {license.code}
                {!license.isActive && (
                  <RegularText weak> (Deactivated)</RegularText>
                )}
              </RegularText>{" "}
              {license.syncInProgress && (
                <>
                  &nbsp; <Icon.RefreshIconSmall />
                </>
              )}
            </FlexContainer>,
            <RegularText light={!license.isActive}>{license.name}</RegularText>,
            <RegularText light={!license.isActive}>
              {license.state}
            </RegularText>,
            <RegularText light={!license.isActive}>
              {license.propertiesCount}
            </RegularText>,
          ],

          primaryAction: (
            <TableActionLinkContainer>
              <IconLink
                onClick={() =>
                  openModalDialog(BluemoonEditLicenseModal, { license })
                }
                Icon={Icon.EditGreenIcon}
                hoverableIcon={false}
                right
                // @ts-ignore
                className="action-icon"
              />
            </TableActionLinkContainer>
          ),
        }))}
        columnBreakpoints={{
          xs: {
            column1: "code",
            column2: "state",
            showActionColumn: false,
          },
          sm: ["code", "name", "state"],
          md: ["code", "name", "state"],
          lg: ["code", "name", "state", "propertiesCount"],
        }}
        placeholder={{
          Icon: Icon.NoDataIcon,
          label: "Licenses",
        }}
      />
    </>
  );
};

export default LicensesTable;
