import axios from "axios";

const bucketInstance = axios.create();

const api = {
  loadFileByUrl: async (url: string) =>
    bucketInstance
      .get(url, {
        responseType: "arraybuffer",
      })
      .then(({ data }) => data),
};

export default api;
