// @ts-nocheck

import { useEffect, useRef } from "react";

import { useVirtual } from "react-virtual";

import { getScrollableParent, SCROLL_DIRECTION } from "shared/utils/dom";

import useForceUpdate from "./useForceUpdate";

const useVirtualizedList = ({
  enabled,
  currentElement,
  availableItems,
  virtualRowHeightEstimator,
}) => {
  let innerContainerHeight = "100%";
  let completeItems = availableItems;
  let getStyle = () => ({});
  let getRealItem = (completeItem) => completeItem;
  let getVirtualItem = () => ({});
  const { forceUpdate } = useForceUpdate();

  const parentRef = useRef(null);
  const rowVirtualizer = useVirtual({
    size: availableItems.length,
    parentRef,
    estimateSize: virtualRowHeightEstimator,
    overscan: 5,
  });

  useEffect(() => {
    if (enabled && currentElement.current != null) {
      parentRef.current = getScrollableParent(
        currentElement.current,
        SCROLL_DIRECTION.Y
      );
      // NOTE: must force an update/re-render when the parent is changed/set
      forceUpdate();
    }
  }, [currentElement.current, enabled]);

  if (enabled) {
    innerContainerHeight = `${rowVirtualizer.totalSize}px`;
    getStyle = (virtualRow) => ({
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: `${virtualRow.size}px`,
      transform: `translateY(${virtualRow.start}px)`,
    });
    completeItems = rowVirtualizer.virtualItems;
    getRealItem = (completeItem) => availableItems[completeItem.index];
    getVirtualItem = (completeItem) => completeItem;
  }

  return {
    completeItems,
    innerContainerHeight,
    getStyle,
    getRealItem,
    getVirtualItem,
  };
};

export default useVirtualizedList;

// import { CSSProperties, ReactNode, RefObject, useEffect, useRef } from "react";
// import { useVirtual, VirtualItem } from "react-virtual";

// import { getScrollableParent, SCROLL_DIRECTION } from "shared/utils/dom";

// import useForceUpdate from "./useForceUpdate";

// export type VirtualizedListProps<T extends ReactNode = {}> = {
//   enabled: boolean;
//   currentElement: RefObject<VirtualItem>;
//   availableItems: T[];
//   virtualRowHeightEstimator: (index: number) => number;
// };

// const useVirtualizedList = <T extends ReactNode = {}>({
//   enabled,
//   currentElement,
//   availableItems,
//   virtualRowHeightEstimator,
// }: VirtualizedListProps<T>) => {
//   let innerContainerHeight = "100%";
//   let getStyle = (row?: VirtualItem): CSSProperties => ({});
//   let getRealItem = (completeItem: VirtualItem): T => ({} as T);
//   let getVirtualItem = (completeItem?: VirtualItem): VirtualItem =>
//     ({} as VirtualItem);
//   const { forceUpdate } = useForceUpdate();

//   const parentRef = useRef(null);
//   const rowVirtualizer = useVirtual({
//     size: availableItems.length,
//     parentRef,
//     estimateSize: virtualRowHeightEstimator,
//     overscan: 5,
//   });

//   let completeItems = rowVirtualizer.virtualItems;

//   useEffect(() => {
//     if (enabled && currentElement.current != null) {
//       parentRef.current = getScrollableParent(
//         currentElement.current,
//         SCROLL_DIRECTION.Y
//       );
//       // NOTE: must force an update/re-render when the parent is changed/set
//       forceUpdate();
//     }
//   }, [currentElement.current, enabled]);

//   if (enabled) {
//     innerContainerHeight = `${rowVirtualizer.totalSize}px`;
//     getStyle = (virtualRow) => ({
//       position: "absolute",
//       top: 0,
//       left: 0,
//       width: "100%",
//       height: `${virtualRow.size}px`,
//       transform: `translateY(${virtualRow.start}px)`,
//     });
//     completeItems = rowVirtualizer.virtualItems;
//     getRealItem = (completeItem) => availableItems[completeItem.index];
//     getVirtualItem = (completeItem) => completeItem;
//   }

//   return {
//     completeItems,
//     innerContainerHeight,
//     getStyle,
//     getRealItem,
//     getVirtualItem,
//   };
// };

// export default useVirtualizedList;
