import styled from "@emotion/styled";

import { COLORS, TRANSITIONS } from "shared/config/constants";

const Container = styled("ul")`
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
`;

const Item = styled("li")<{ active: boolean }>`
  cursor: pointer;
  display: block;
  padding: 12px 24px;
  border-left: 3px solid
    ${(props) =>
      props.active ? COLORS.menu.item.active.border : "transparent"};
  background-color: ${(props) =>
    props.active ? COLORS.menu.item.active.background : "transparent"};
  color: ${COLORS.menu.item.default.text};
  transition: ${TRANSITIONS.default};

  &.flex-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const Label = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export { Container, Item, Label };
