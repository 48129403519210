import React from "react";

import { useHistory } from "react-router-dom";

import { UNIT_STATUSES } from "manager/config/constants";
import ROUTES from "manager/config/routes";
import { Button } from "shared/components/Button";
import { DROPDOWN_FILTER_MODES } from "shared/components/FilterBar";
import { UnderlineLink } from "shared/components/Links";
import { BREAKPOINT_TYPES } from "shared/config/constants";
import TablePage from "shared/templates/TablePage";

import ExportInventoryButton from "./ExportInventoryButton";
import { PropertiesProps } from "./interfaces";
import PortfoliosTable from "./PortfoliosTable";
import PropertiesTable from "./PropertiesTable";
import { StyledActionLink, ExportWrapper, StyledContainer } from "./styled";
import UnitsTable from "./UnitsTable";

const Properties = ({
  // Properties
  properties,
  propertiesCount,
  propertiesLoading,
  refetchProperties,
  disablePropertiesFilter,
  propertiesHasInputValue,
  propertiesPagination,
  setPropertiesPagination,
  propertiesSearchParams,
  createProperty,
  handlePropertiesSearchParams,
  portfoliosDropdown,
  isPortfoliosLoading,
  // Units
  units,
  unitsCount,
  isUnitsLoading,
  exportUnits,
  isExportingUnits,
  disableUnitFilter,
  unitsHasInputValue,
  unitsPagination,
  setUnitsPagination,
  unitsSearchParams,
  handleUnitsSearchParams,
  onUnitsTableChange,
  refetchPropertiesDropdownData,
  propertiesDropdown,
  isPropertiesDropdownLoading,
  // Portfolios
  portfolioHasInputValue,
  portfolioPagination,
  setPortfolioPagination,
  portfolioSearchParams,
  handlePortfolioSearchParams,
  createPortfolio,
  onPortfolioTableChange,
}: PropertiesProps) => {
  const history = useHistory();
  const isUnitsTab = history.location.pathname === ROUTES.units;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { NA, OH, ...U_STATUSES } = UNIT_STATUSES;

  const bedAndBathDropdown = Array.from({ length: 9 }, (_, i) => i + 1).map(
    (key) => ({
      key,
      label: String(key),
    })
  );

  return (
    <StyledContainer>
      <TablePage
        title="Inventory Management"
        subtitle="Add, view and manage your properties, units and portfolios"
        onlyMobileActions
        actions={[
          {
            key: "NEW_PROPERTY",
            node: (
              <StyledActionLink
                data-testid="new-property-link"
                onClick={createProperty}
                plus
              >
                New Property
              </StyledActionLink>
            ),
          },
          {
            key: "NEW_PORTFOLIO",
            node: (
              <UnderlineLink onClick={createPortfolio}>
                + New Portfolio
              </UnderlineLink>
            ),
          },
          ...(isUnitsTab
            ? [
                {
                  key: "EXPORT_INVENTORY",
                  node: (
                    <ExportWrapper>
                      {/* @ts-ignore */}
                      <ExportInventoryButton
                        disabled={units?.length === 0 || isExportingUnits}
                        filters={unitsSearchParams}
                        exportUnits={exportUnits}
                        loading={isExportingUnits}
                        size="small"
                      />
                    </ExportWrapper>
                  ),
                },
              ]
            : []),
        ]}
        filterTabs={[
          {
            route: ROUTES.properties,
            label: "Properties",
          },
          {
            route: ROUTES.units,
            label: "Units",
          },
          {
            route: ROUTES.portfolios,
            label: "Portfolios",
          },
        ]}
        tables={[
          {
            filterBar: {
              search: {
                action: (value) =>
                  handlePropertiesSearchParams("nameOrAddress", value),
                placeholder: "Search by property or address...",
                width: 320,
                debounce: true,
                disabled: disablePropertiesFilter,
              },
              dropdownFilters: [
                {
                  key: "PORTFOLIO",
                  label: "Portfolio",
                  value: propertiesSearchParams.portfolioId,
                  disabled: disablePropertiesFilter,
                  onChange: (selectedFilter) => {
                    handlePropertiesSearchParams("portfolioId", selectedFilter);
                  },
                  items: portfoliosDropdown,
                  mode: DROPDOWN_FILTER_MODES.multiselect,
                },
              ],
              loadingDropdownOptions: isPortfoliosLoading,
              action: {
                key: "NEW_PROPERTY",
                node: (
                  <Button
                    data-testid="new-property-button"
                    type="secondary"
                    onClick={createProperty}
                    plus
                  >
                    New Property
                  </Button>
                ),
              },
            },
            node: (
              <PropertiesTable
                hasInputValue={propertiesHasInputValue}
                setPagination={setPropertiesPagination}
                pagination={propertiesPagination}
                properties={properties}
                propertiesCount={propertiesCount}
                isLoading={propertiesLoading}
                refetchProperties={refetchProperties}
                propertiesSearchParams={propertiesSearchParams}
              />
            ),
          },
          {
            filterBar: {
              search: {
                action: (value) => handleUnitsSearchParams("name", value),
                placeholder: "Search by unit name...",
                width: 250,
                debounce: true,
                disabled: disableUnitFilter,
              },
              dropdownFilters: [
                {
                  key: "STATUS",
                  label: "Unit statuses",
                  value: unitsSearchParams.status,
                  disabled: disableUnitFilter,
                  minWidth: 205,
                  onChange: (selectedFilter) =>
                    handleUnitsSearchParams("status", selectedFilter),
                  items: Object.values(U_STATUSES),
                  mode: DROPDOWN_FILTER_MODES.multiselect,
                },
                {
                  key: "PROPERTY",
                  label: "Properties",
                  value: unitsSearchParams.propertyId,
                  disabled: disableUnitFilter,
                  minWidth: 205,
                  onChange: (selectedFilter) =>
                    handleUnitsSearchParams("propertyId", selectedFilter),
                  items: propertiesDropdown,
                  mode: DROPDOWN_FILTER_MODES.multiselect,
                },
                {
                  key: "BEDROOMS",
                  label: "Bedrooms",
                  value: unitsSearchParams.bedrooms,
                  disabled: disableUnitFilter,
                  minWidth: 160,
                  onChange: (selectedFilter) =>
                    handleUnitsSearchParams(
                      "bedrooms",
                      selectedFilter === "ALL_BEDROOMS" ? null : selectedFilter
                    ),
                  items: [
                    {
                      label: "All Bedrooms",
                      key: "ALL_BEDROOMS",
                    },
                    ...bedAndBathDropdown,
                  ],
                },
                {
                  key: "BATHROOMS",
                  label: "Bathrooms",
                  value: unitsSearchParams.bathrooms,
                  disabled: disableUnitFilter,
                  minWidth: 65,
                  onChange: (selectedFilter) =>
                    handleUnitsSearchParams(
                      "bathrooms",
                      selectedFilter === "ALL_BATHROOMS" ? null : selectedFilter
                    ),
                  items: [
                    {
                      label: "All Bathrooms",
                      key: "ALL_BATHROOMS",
                    },
                    ...bedAndBathDropdown,
                  ],
                },
              ],
              checkboxFilters: [
                {
                  key: "ON_HOLD",
                  label: "On Hold",
                  onChange: () =>
                    handleUnitsSearchParams(
                      "showOnHold",
                      unitsSearchParams.showOnHold === false ? undefined : false
                    ),

                  value: typeof unitsSearchParams.showOnHold === "undefined",
                },
              ],
              dateRange: {
                label: "Available Date",
                date1Id: "startDate",
                date2Id: "endDate",
                fromValue: unitsSearchParams.startDate,
                toValue: unitsSearchParams.endDate,
                setValues: (values) => handleUnitsSearchParams("date", values),
                disabled: disableUnitFilter,
              },
              breakpointFilterPopover: [
                BREAKPOINT_TYPES.sm,
                BREAKPOINT_TYPES.md,
                BREAKPOINT_TYPES.lg,
                BREAKPOINT_TYPES.xl,
                BREAKPOINT_TYPES.xxl,
              ],
              loadingDropdownOptions: isPropertiesDropdownLoading,
              action: {
                key: "EXPORT",
                node: (
                  <ExportInventoryButton
                    disabled={units?.length === 0 || isExportingUnits}
                    exportUnits={exportUnits}
                    loading={isExportingUnits}
                    unitsSearchParams={unitsSearchParams}
                    className="export-units"
                  />
                ),
              },
            },
            node: (
              <UnitsTable
                hasInputValue={unitsHasInputValue}
                setPagination={setUnitsPagination}
                pagination={unitsPagination}
                units={units}
                onUnitsTableChange={onUnitsTableChange}
                refetchPropertiesDropdownData={refetchPropertiesDropdownData}
                unitsCount={unitsCount}
                loading={isUnitsLoading}
              />
            ),
          },
          {
            filterBar: {
              search: {
                action: (value) => handlePortfolioSearchParams("name", value),
                placeholder: "Search portfolio name",
                width: 320,
                debounce: true,
                disabled: isPortfoliosLoading,
              },
              action: {
                key: "NEW_PORTFOLIO",
                node: (
                  <Button type="secondary" onClick={createPortfolio} plus>
                    New Portfolio
                  </Button>
                ),
              },
            },
            node: (
              <PortfoliosTable
                hasInputValue={portfolioHasInputValue}
                setPagination={setPortfolioPagination}
                pagination={portfolioPagination}
                portfolioSearchParams={portfolioSearchParams}
                onPortfolioTableChange={onPortfolioTableChange}
              />
            ),
          },
        ]}
      />
    </StyledContainer>
  );
};

export default Properties;
