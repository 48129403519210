import React from "react";

import { Col, Row } from "antd";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import getDeals from "manager/components/DealsTable/data";
import Container from "shared/components/Container";
import Icon from "shared/components/Icon";
import { BackLink, UnderlineLink } from "shared/components/Links";
import PageTitle from "shared/components/PageTitle";
import Spacer from "shared/components/Spacer";
import Table from "shared/components/Table";
import Tile from "shared/components/Tile";
import { formatDate, formatPhone } from "shared/utils/ui";

const Employee = ({
  name,
  email,
  phoneNumber,
  dateOfBirth,
  history,
  backLink,
}) => (
  <>
    <Helmet>
      <title>{name}</title>
    </Helmet>
    <Container noPaddingBottom>
      <BackLink.Container>
        <BackLink {...backLink} />
      </BackLink.Container>
      <PageTitle.Container>
        <PageTitle data-testid="title">
          <PageTitle.Icon>
            <Icon.PersonIcon />
          </PageTitle.Icon>
          {name}
        </PageTitle>
      </PageTitle.Container>
    </Container>
    <Container noPaddingTop noMobilePaddingX>
      <Tile>
        <Tile.Inner>
          <Row type="flex">
            <Col xs={{ span: 22 }} md={{ span: 17 }} xl={{ span: 18 }}>
              <Row type="flex" gutter={24}>
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 12 }}
                  xl={{ span: 6 }}
                  xxl={{ span: 4 }}
                >
                  <Tile.Label>Email</Tile.Label>
                  <UnderlineLink href={`mailto:${email}`}>
                    {email}
                  </UnderlineLink>
                </Col>
                <Col xs={{ span: 0 }} xl={{ span: 1 }}>
                  <Tile.Divider />
                </Col>
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 12 }}
                  xl={{ span: 5 }}
                  xxl={{ span: 4 }}
                >
                  <Spacer hideGteMd />
                  <Tile.Label>Phone Number</Tile.Label>
                  <UnderlineLink href={`tel:${phoneNumber}`}>
                    {formatPhone(phoneNumber)}
                  </UnderlineLink>
                </Col>
                <Col xs={{ span: 0 }} xl={{ span: 1 }}>
                  <Tile.Divider />
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 4 }}>
                  <Spacer hideGteXl />
                  <Tile.Label>Date of Birth</Tile.Label>
                  {formatDate(dateOfBirth)}
                </Col>
                <Col xs={{ span: 0 }} xl={{ span: 1 }}>
                  <Tile.Divider />
                </Col>
              </Row>
            </Col>
          </Row>
        </Tile.Inner>
      </Tile>
      <Tile
        header={{
          title: "Activity",
        }}
      >
        <Table {...getDeals(history)} />
      </Tile>
    </Container>
  </>
);

Employee.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  dateOfBirth: PropTypes.instanceOf(Date).isRequired,
  history: PropTypes.object.isRequired,
  backLink: PropTypes.object.isRequired,
};

export default Employee;
