import React from "react";

import {
  useAssignDocumentsToCompany,
  useCompaniesToAssignDocuments,
} from "admin/hooks/api";
import useModal from "shared/hooks/useModal";

import AssignDocumentSuccessModal from "../AssignDocumentSuccessModal";

import AssignDocumentModal from "./AssignDocumentModal";

interface AssignDocumentModalContainerProps {
  documents: {
    id: number;
    name: string;
  }[];
}

const AssignDocumentModalContainer = ({
  documents,
}: AssignDocumentModalContainerProps) => {
  const { openModalDialog } = useModal();
  const documentNames = documents.map((document) => document.name);
  const { isLoading, data: companies } = useCompaniesToAssignDocuments({
    documentNames,
  });
  const { assignDocuments } = useAssignDocumentsToCompany();

  const submit = (values) => {
    assignDocuments(
      {
        companyId: values.company,
        documentIds: documents?.map((item) => item.id),
      },
      {
        onSuccess: () => {
          const company = companies.find((item) => item.id === values.company);
          openModalDialog(AssignDocumentSuccessModal, { company, documents });
        },
      }
    );
  };

  return (
    <AssignDocumentModal
      documents={documents}
      companies={companies}
      isLoading={isLoading}
      onSubmit={submit}
    />
  );
};

export default AssignDocumentModalContainer;
