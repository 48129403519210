import React from "react";

import { SIZES } from "shared/components/Modal";
import Spacer from "shared/components/Spacer";
import {
  RegularText,
  LargeTitle,
  ExtraStrongText,
} from "shared/components/Typography";
import { useDeviceType } from "shared/hooks";

import { StyledModal } from "../styled";
import { CenterContainer } from "./styled";

interface DisputeModalProps {
  title: string;
  submit: () => void;
}

const DisputeModal = ({ title }: DisputeModalProps) => {
  const { isMobile } = useDeviceType();
  return (
    <StyledModal
      title={title}
      submitButtonLabel="Back to Vero"
      submitButtonSize={SIZES.lg}
      isMobile={isMobile}
      showCancelLink={false}
    >
      <Spacer size={Spacer.SIZES.sm} />
      <RegularText>
        To dispute the contact information on file for the information you
        submitted, please call the number below.
      </RegularText>
      <CenterContainer>
        <LargeTitle strong>800-695-7148</LargeTitle>
        <RegularText>24/7 support / Toll Free</RegularText>
      </CenterContainer>
      <Spacer size={Spacer.SIZES.md} />
    </StyledModal>
  );
};

export default DisputeModal;
