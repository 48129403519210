import React, { useState, useRef, useEffect } from "react";

import Tooltip from "shared/components/Tooltip";

import { OverflowedOptionWrapper } from "./styled";

interface DetectableOverflowTooltipProps {
  children?: React.ReactNode | string;
  title?: string;
  [key: string]: any;
}

const DetectableOverflowTooltip = ({
  children = undefined,
  title = undefined,
  ...props
}: DetectableOverflowTooltipProps) => {
  const [overflowed, setOverflowed] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      setOverflowed(ref.current.offsetWidth < ref.current.scrollWidth);
    }
  }, [ref, children]);

  return (
    <Tooltip
      {...props}
      title={overflowed ? title || children : undefined}
      data-testid="detectable-overflow-tooltip"
    >
      <OverflowedOptionWrapper
        ref={ref}
        data-testid="overflow-wrapper"
        className="text-ellipsis"
      >
        {children}
      </OverflowedOptionWrapper>
    </Tooltip>
  );
};

export default DetectableOverflowTooltip;
