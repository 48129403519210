import { useQuery } from "react-query";

import { NOTIFICATIONS } from "shared/config/constants";
import api from "shared/lib/api";
import { openNotification } from "shared/utils/ui";

const GET_PARTNERS_QUERY = "getPartners";

export const useGetPartners = () => {
  const { data: partners, isLoading } = useQuery(
    [GET_PARTNERS_QUERY],
    api.getPartners,
    {
      onError: () =>
        openNotification("Failed to load partners", NOTIFICATIONS.error),
    }
  );

  return { partners, isPartnersLoading: isLoading };
};
