import React from "react";

import { PageInput, Container, Label } from "./styled";

type InputProps = {
  [key: string]: any;
};

export type PageNumberTypes = InputProps & {
  onChange: () => void;
  value?: number;
  total?: number;
};

export const PageNumber = ({ value, onChange, total, ...other }) => (
  <Container>
    <Label>Page</Label>
    {/* @ts-ignore */}
    <PageInput value={value} onChange={onChange} {...other} />
    <Label>of {total}</Label>
  </Container>
);
