import _ from 'lodash'
import React from 'react'
import { useListApplicantWorkflows } from 'shared/hooks/api'
import { Loader } from 'shared/components/LargeLoader'
import { Workflow, Workflows } from 'shared/components/vega/WorkflowStudio'
import ApplicantWorkflowEditor from './ApplicantWorkflowEditor'

const ApplicantWorkflows = ({ workflows, readOnlyWorkflows = [] }) => {
  const builder = (workflow, readOnly) => {
    return (
      <Workflow workflow={workflow} readOnly={readOnly} key={workflow?.id}>
        <ApplicantWorkflowEditor workflow={workflow} canEdit={!readOnly} />
      </Workflow>
    )
  }

  return (
    <Workflows workflows={workflows} readOnlyWorkflows={readOnlyWorkflows} builder={builder} />
  )
}

const ApplicantWorkflowStudio = ({ location, readOnlyLocations = [] }) => {
  const { workflows, isLoading } = useListApplicantWorkflows(location, true)

  const readOnlyWorkflows = _.chain(readOnlyLocations).map(l => {
    const { workflows } = useListApplicantWorkflows(l, true)
    return workflows
  }).flatten().uniqBy('id').value()

  return (
    <div className='vega container'>
      <Loader loading={isLoading}>
        <ApplicantWorkflows
          workflows={workflows}
          readOnlyWorkflows={readOnlyWorkflows}
        />
      </Loader>
    </div>
  )
}

export default ApplicantWorkflowStudio
