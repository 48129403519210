import React from "react";

import styled from "@emotion/styled";
import { Select as BaseSelect } from "antd";

import { COLORS, TRANSITIONS, Z_INDEX } from "shared/config/constants";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Select = styled(({ error, ...otherProps }) => (
  <BaseSelect {...otherProps} />
))((props) => ({
  minWidth: "150px",
  width: "100%",
  transition: TRANSITIONS.default,
  "&.ant-select-focused .ant-select-selection": {
    boxShadow: "none",
  },
  ".ant-select-selection": {
    minWidth: props.minWidth || "150px",
    borderRadius: "2px",
    ...(props.error && {
      borderColor: `${COLORS.input.invalid.border} !important`,
      boxShadow: "none",
      outline: "none",
    }),
  },
  ".ant-select-selection__rendered": {
    marginRight: "35px",
  },
  ".ant-select-arrow-icon": {
    color: COLORS.select.icon,
  },
  "&.ant-select-open": {
    "& > .ant-select-selection": {
      borderColor: "transparent",
      borderBottomColor: COLORS.select.input.open.borderBottom,
      boxShadow: "0px 1px 6px rgba(126, 153, 168, 0.45)",
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  ...(props.violetTheme && {
    ".ant-select-selection": {
      backgroundColor: COLORS.violetTheme.deepBlueViolet,
      color: COLORS.violetTheme.lightGrey,
      borderColor: COLORS.violetTheme.blueViolet,
    },
    ".ant-select-selection__choice": {
      backgroundColor: COLORS.violetTheme.deepBlueViolet,
      color: COLORS.violetTheme.lightGrey,
    },
    ".ant-select-selection__choice__remove:hover": {
      color: COLORS.violetTheme.lightGrey,
    },
  }),
}));

const DropdownMenu = styled("div")<{ violetTheme?: boolean }>((props) => ({
  transform: "translateY(-4px)",
  ".ant-select-dropdown-menu": {
    borderRadius: "0 0 2px 2px",
    zIndex: Z_INDEX.dropdownMenu,
  },
  ".ant-select-dropdown-menu-item": {
    backgroundColor: COLORS.select.option.background,
    color: COLORS.select.option.text,
    borderRadius: "0 !important",
    transition: TRANSITIONS.default,
    fontSize: "15px",
    paddingTop: "10px",
    paddingBottom: "10px",
    "&:hover": {
      backgroundColor: `${COLORS.select.option.hover.background} !important`,
      color: `${COLORS.select.option.hover.text} !important`,
    },
    "&-selected": {
      backgroundColor: `${COLORS.select.option.background} !important`,
    },
    "&-disabled, &-disabled:hover": {
      color: "#DEDCE5 !important",
    },
  },
  ...(props.violetTheme && {
    backgroundColor: COLORS.violetTheme.deepBlueViolet,

    ".ant-select-dropdown-menu-item": {
      backgroundColor: COLORS.violetTheme.deepBlueViolet,
      color: COLORS.violetTheme.lightGrey,
      "&:hover": {
        backgroundColor: `${COLORS.violetTheme.blueViolet} !important`,
        color: COLORS.violetTheme.lightGrey,
        "& svg": {
          color: COLORS.violetTheme.lightGrey,
        },
      },
      "&-selected": {
        backgroundColor: `${COLORS.violetTheme.blueViolet} !important`,
        color: COLORS.violetTheme.lightGrey,
        "& svg": {
          color: COLORS.violetTheme.lightGrey,
        },
      },
    },
  }),
}));

const dropdownStyles = {
  border: "2px solid red !important",
  borderRadius: "2px",
  boxShadow: "0px 1px 6px rgba(126, 153, 168, 0.45)",
};

const dropdownStylesViolet = {
  border: "2px solid red !important",
  borderRadius: "2px",
  boxShadow: "0px 1px 6px rgba(126, 153, 168, 0.45)",
  backgroundColor: `${COLORS.violetTheme.deepBlueViolet}`,
};

export { Select, DropdownMenu, dropdownStyles, dropdownStylesViolet };
