import styled from "@emotion/styled";
import { Row } from "antd";

import Tile from "shared/components/Tile";
import { COLORS } from "shared/config/constants";

export const NoCurrentListingContainer = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 30px;
  flex-wrap: wrap;
`;

export const NoCurrentListing = styled.label`
  font-weight: 300;
  color: ${COLORS.textColorPrimary};
  margin-right: 8px;
`;

export const StyledTile = styled(Tile)`
  border: none;
  > div {
    border-bottom: 0;
    padding-bottom: 0;
  }
  h2 {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    width: 100%;
  }
`;

export const GreyRow = styled(Row)`
  background-color: ${COLORS.background};
  border-radius: 2px;
  margin-bottom: 20px;
  padding: 24px 18px;
`;
