import styled from "@emotion/styled";

import Container from "shared/components/Container";
import BaseFooterBar from "shared/components/FooterBar";
import { Z_INDEX } from "shared/config/constants";

const LeadSummaryContent = styled(Container)`
  z-index: ${Z_INDEX.container};
`;

const LeadSummaryFooter = styled(Container)`
  z-index: ${Z_INDEX.container};
`;

const StyledFooterBar = styled(BaseFooterBar)`
  padding-right: 60px;

  button {
    margin-right: 16px;
  }
`;

export { LeadSummaryContent, LeadSummaryFooter, StyledFooterBar };
