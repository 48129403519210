import React from "react";

import { Col } from "antd";

import { Button } from "shared/components/Button";
import Container from "shared/components/Container";
import FlexContainer from "shared/components/FlexContainer";
import {
  LargeTitle,
  RegularText,
  SmallTitle,
} from "shared/components/Typography";
import { RequestDashboardAccessIllustration } from "shared/images";

import { Card } from "./styled";

const RequestDashboardAccessCard = () => {
  return (
    /* @ts-ignore */
    <Container noMobilePaddingX noPaddingTop>
      {/* @ts-ignore */}
      <Card>
        <FlexContainer
          flexDirection="column"
          flexWrap="wrap"
          className="card-body"
        >
          <Col
            xs={{ span: 24 }}
            md={{ span: 12 }}
            order={1}
            className="left-col"
          >
            <div className="content">
              <LargeTitle extraStrong className="title">
                NEW Precision Statistics and Detailed Insights
              </LargeTitle>
              <SmallTitle className="subtitle">
                with the VERO Dashboard
              </SmallTitle>
            </div>
          </Col>
          <Col
            xs={{ span: 24, order: 3 }}
            md={{ span: 12, order: 2 }}
            className="left-col"
          >
            <div className="content">
              {/* @ts-ignore */}
              <RegularText className="description" light>
                Gain deeper understanding of your leasing process with the
                improved dashboard feature. Contact your VERO account manager
                for access.
              </RegularText>
              {/* @ts-ignore */}
              <Button
                type="primary"
                onClick={() => {
                  window.open("https://support.sayvero.com", "_blank");
                }}
              >
                CONTACT US
              </Button>
            </div>
          </Col>
          <Col
            xs={{ span: 24, order: 2 }}
            md={{ span: 12, order: 3 }}
            order={2}
            className="right-col"
          >
            <img src={RequestDashboardAccessIllustration} alt="Dashboard" />
          </Col>
        </FlexContainer>
      </Card>
    </Container>
  );
};

export default RequestDashboardAccessCard;
