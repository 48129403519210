import React from "react";

import { useHistory, useParams } from "react-router-dom";

import { ROUTES } from "leads/config/routes";

import SetPasswordPage from "shared/pages/SetPasswordPage";
import { getQueryParams } from "shared/utils/routing";

const SetPasswordContainer = () => {
  const history = useHistory();
  const urlParams = getQueryParams();

  const { token } = useParams<{ token: string }>();

  const onSuccess = () => {
    window.location.href = `${window.location.origin}${urlParams?.r || ROUTES.leadStart}`;
    return null;
  }

  return <SetPasswordPage onSuccessLogin={onSuccess} token={token} />;
};

export default SetPasswordContainer;
