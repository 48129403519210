import React, { useState } from "react";

import { Modal } from "shared/components/Modal";
import Spacer from "shared/components/Spacer";
import Well from "shared/components/Well/Well";
import useModal from "shared/hooks/useModal";

const AutoCreateModal = () => {
  const { currentModalContext } = useModal();
  const { title, propertyName, wellMessage, description, onSave } =
    currentModalContext;
  const [submitting, setSubmitting] = useState(false);

  const submit = async () => {
    setSubmitting(true);
    await onSave();
    setSubmitting(false);
  };

  return (
    <Modal
      title={title}
      subtitle={
        <>
          Property: <strong>{propertyName}</strong>
        </>
      }
      submitButtonLabel="CONFIRM & CREATE"
      submit={submit}
      submitting={submitting}
    >
      <Modal.Body>
        {wellMessage && (
          <>
            <Well>
              <strong>{wellMessage}</strong>
            </Well>
            <Spacer />
          </>
        )}
        <p>{description}</p>
      </Modal.Body>
    </Modal>
  );
};

export default AutoCreateModal;
