import React from "react";

import { Col, Row } from "antd";
import { useFormikContext } from "formik";

import DeleteTitle from "shared/components/DeleteTitle";
import FlexContainer from "shared/components/FlexContainer";
import { Select, VeroFormField } from "shared/components/Form";
import Spacer from "shared/components/Spacer";
import useDeviceType from "shared/hooks/useDeviceType";
import { getFullnameData } from "shared/utils/applicant";

import AddMoreLeadsLink from "./AddMoreLeadsLink";
import { emptyLead, Lead } from "./utils";

interface AddLeadsFormProps {
  selectedLeads: Lead[];
  addLead: (lead: Lead) => void;
  deleteLead: (index: number) => void;
  leadOptions: Lead[];
  isLeadOptionsLoading: boolean;
}

const AddLeadsForm = ({
  selectedLeads,
  addLead,
  deleteLead,
  leadOptions,
  isLeadOptionsLoading,
}: AddLeadsFormProps) => {
  const { isMobile } = useDeviceType();
  const { setFieldValue } = useFormikContext();
  const isAddMoreDisabled = leadOptions.length === selectedLeads.length;

  return (
    <>
      {selectedLeads.map((_lead, index) => {
        const key = `lead-select-${index}`;
        const leadPropPath = `leads.${index}`;
        return (
          <div key={key}>
            {index > 0 && <br />}
            {selectedLeads.length > 1 && (
              <DeleteTitle action={() => deleteLead(index)}>
                {`Lead #${index + 1}`}
              </DeleteTitle>
            )}
            <Row gutter={[20, 25]}>
              <FlexContainer alignItems="center">
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <VeroFormField
                    as={Select}
                    name={`${leadPropPath}.id`}
                    id={`${leadPropPath}.id`}
                    label="Select Leads"
                    loading={isLeadOptionsLoading}
                    onChange={(value: number) => {
                      const selectedLead = leadOptions.find(
                        (leadOption) => leadOption.id === value
                      );
                      setFieldValue(leadPropPath, selectedLead || emptyLead);
                    }}
                    allowClear
                  >
                    {leadOptions.map((leadOption) => {
                      const { id, email } = leadOption;
                      const { fullNameOrNA } = getFullnameData(leadOption);
                      const isOptionDisabled = !!selectedLeads.find(
                        (lead) => lead.id === leadOption.id
                      );
                      return (
                        <Select.Option
                          disabled={isOptionDisabled}
                          key={id}
                          value={id}
                        >
                          {fullNameOrNA} ({email})
                        </Select.Option>
                      );
                    })}
                  </VeroFormField>
                </Col>
                {!isMobile && selectedLeads.length === index + 1 && (
                  <AddMoreLeadsLink
                    leads={selectedLeads}
                    addLead={addLead}
                    disabled={isAddMoreDisabled}
                  />
                )}
              </FlexContainer>
            </Row>
            {isMobile && selectedLeads.length === index + 1 && (
              <>
                <Spacer size={Spacer.SIZES.sm} />
                <AddMoreLeadsLink
                  leads={selectedLeads}
                  addLead={addLead}
                  disabled={isAddMoreDisabled}
                />
              </>
            )}
          </div>
        );
      })}
    </>
  );
};

export default AddLeadsForm;
