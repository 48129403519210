import { normalize } from "normalizr";
import { handleActions } from "redux-actions";

import { CONTENT_TYPE } from "shared/config/constants";
import {
  receiveAdditionalQuestions,
  removeAdditionalQuestions,
} from "shared/state/AdditionalQuestion/actions";
import {
  receiveRequiredAttachments,
  removeRequiredAttachments,
} from "shared/state/RequiredAttachment/actions";

import * as actions from "../actions";
import * as schemas from "../schema";

const initialState = {};

const dealSetupEntityReducer = handleActions(
  {
    [actions.receiveDealSetup]: (state, action) => {
      const dealSetup = action.payload;

      return {
        ...state,
        ...normalize([dealSetup], schemas.dealSetupList).entities.dealSetup,
      };
    },
    [receiveAdditionalQuestions]: (state, action) => {
      const { items, objectId, contentType } = action.payload;
      let newState = state;

      if (contentType === CONTENT_TYPE.dealSetup) {
        const questionIds = items.map((item) => item.id);

        newState = {
          ...state,
          [objectId]: {
            ...state[objectId],
            additionalQuestions: [
              ...state[objectId].additionalQuestions,
              ...questionIds,
            ],
          },
        };
      }

      return newState;
    },
    [removeAdditionalQuestions]: (state, action) => {
      const { ids, objectId, contentType } = action.payload;
      let newState = state;

      if (contentType === CONTENT_TYPE.dealSetup) {
        newState = {
          ...state,
          [objectId]: {
            ...state[objectId],
            additionalQuestions: state[objectId].additionalQuestions.filter(
              (id) => !ids.includes(id)
            ),
          },
        };
      }

      return newState;
    },
    [receiveRequiredAttachments]: (state, action) => {
      const { items, objectId, contentType } = action.payload;
      let newState = state;

      if (contentType === CONTENT_TYPE.dealSetup) {
        const attachmentIds = items.map((item) => item.id);

        newState = {
          ...state,
          [objectId]: {
            ...state[objectId],
            requiredAttachments: [
              ...state[objectId].requiredAttachments,
              ...attachmentIds,
            ],
          },
        };
      }

      return newState;
    },
    [removeRequiredAttachments]: (state, action) => {
      const { ids, objectId, contentType } = action.payload;
      let newState = state;

      if (contentType === CONTENT_TYPE.dealSetup) {
        newState = {
          ...state,
          [objectId]: {
            ...state[objectId],
            requiredAttachments: state[objectId].requiredAttachments.filter(
              (id) => !ids.includes(id)
            ),
          },
        };
      }

      return newState;
    },
  },
  initialState
);

export default dealSetupEntityReducer;
