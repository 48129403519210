import styled from "@emotion/styled";
import { Typography } from "antd";

import Icon from "shared/components/Icon";

const { Text } = Typography;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledIcon = styled(Icon.DocumentsIcon)`
  width: 50px;
  height: 60px;
  opacity: 0.1;
`;

export const Title = styled(Text)`
  color: #787395;
  font-size: 20px;
`;
