import styled from "@emotion/styled";

import { Z_INDEX } from "shared/config/constants";

export const LoadingContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: calc(100vh - 72px);
  width: 100vw;
  z-index: ${Z_INDEX.fullScreenLoader};

  svg {
    position: absolute;
    top: calc(50% + 36px);
    left: 50%;
  }
`;
