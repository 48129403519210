import { Key } from "react";

import { queryCache, useMutation, useQuery } from "react-query";

import api from "manager/lib/api";
import { NOTIFICATIONS } from "shared/config/constants";
import sharedApi from "shared/lib/api";

import {
  MoveApplicationRequest,
  moveApplicationToAnotherDeal,
} from "shared/lib/api/applicationApi";
import { openNotification } from "shared/utils/ui";

const GET_FULL_APPLICATION = "getFullApplication";

export const APPLICATION_QUERY_KEYS = Object.freeze({
  getFullApplication: (id: number) => [GET_FULL_APPLICATION, id],
});

export const useMoveApplication = ({ onSuccess }) => {
  const [mutate, status] = useMutation(
    (params: MoveApplicationRequest) => moveApplicationToAnotherDeal(params),
    {
      onSuccess,
    }
  );

  return {
    moveApplicationToAnotherDeal: mutate,
    ...status,
  };
};

export const useAddAttachments = (refetchDeal) => {
  const [mutate, status] = useMutation(
    (params) => api.addApplicationAttachments(params),
    {
      onSuccess: (data, variables: Record<string, unknown>) => {
        queryCache.invalidateQueries(
          APPLICATION_QUERY_KEYS.getFullApplication(
            Number(variables.applicationId)
          )
        );
        refetchDeal();
        openNotification("Attachments successfully added.", NOTIFICATIONS.info);
      },
      onError: (e: any) => {
        const message = e?.errors?.error || "Failed to add the attachments.";
        openNotification(message, NOTIFICATIONS.error);
      },
    }
  );

  return {
    addApplicationAttachments: mutate,
    ...status,
  };
};

export const useDeleteAttachments = (refetchDeal) => {
  const [mutate, status] = useMutation(
    (params) => api.deleteApplicationAttachments(params),
    {
      onSuccess: (data, variables: Record<string, unknown>) => {
        queryCache.invalidateQueries(
          APPLICATION_QUERY_KEYS.getFullApplication(
            Number(variables.applicationId)
          )
        );
        refetchDeal();
        openNotification(
          "Attachments successfully deleted.",
          NOTIFICATIONS.info
        );
      },
      onError: (e: any) => {
        const message = e?.errors?.error || "Failed to delete the attachments.";
        openNotification(message, NOTIFICATIONS.error);
      },
    }
  );

  return {
    deleteApplicationAttachments: mutate,
    ...status,
  };
};

export const useCancelAttachments = (refetchDeal) => {
  const [mutate, status] = useMutation(
    (params) => api.cancelAttachments(params),
    {
      onSuccess: (data, variables: Record<string, unknown>) => {
        queryCache.invalidateQueries(
          APPLICATION_QUERY_KEYS.getFullApplication(
            Number(variables.applicationId)
          )
        );
        refetchDeal();
        openNotification(
          "Attachments update has been cancelled successfully.",
          NOTIFICATIONS.info
        );
      },
      onError: (e: any) => {
        const message = e?.errors?.error || "Failed to cancel the attachments.";
        openNotification(message, NOTIFICATIONS.error);
      },
    }
  );

  return {
    cancelApplicationAttachments: mutate,
    ...status,
  };
};

export const useConfirmAttachments = (refetchDeal) => {
  const [mutate, status] = useMutation(
    (params) => api.confirmAttachments(params),
    {
      onSuccess: (data, variables: Record<string, unknown>) => {
        refetchDeal();
        queryCache.invalidateQueries(
          APPLICATION_QUERY_KEYS.getFullApplication(
            Number(variables.applicationId)
          )
        );
        openNotification(
          `Attachments have been successfully updated and the application report has been recreated.`,
          NOTIFICATIONS.info
        );
      },
      onError: (e: any) => {
        const message =
          e?.errors?.error ||
          "Failed to update the attachments and recreate the application report.";
        openNotification(message, NOTIFICATIONS.error);
      },
    }
  );

  return {
    confirmApplicationAttachments: mutate,
    ...status,
  };
};

export const useUnblockApplicationIfNoIncomeVerified = (applicationId) => {
  const [mutate, { isLoading }] = useMutation(
    () =>
      api.setBlockDealSubmitIfIncomeMissing({
        applicationId,
        blockDealSubmitIfIncomeDataIsMissing: false,
      }),
    {
      onSuccess: () => {
        openNotification(
          "Successfully unblocked application submission.",
          NOTIFICATIONS.info
        );
      },
      onError: () => {
        openNotification(
          "Failed to unblock application submission.",
          NOTIFICATIONS.error
        );
      },
    }
  );

  return {
    unblockApplicationIfNoIncome: mutate,
    isUnblockApplicationIfNoIncome: isLoading,
  };
};

export function useGetFullApplicantion(applicationId: Key) {
  const {
    data: fullApplication,
    isLoading: isFullApplicationLoading,
    refetch: refetchFullApplication,
    isFetching: isFullApplicationFetching,
  } = useQuery(
    [APPLICATION_QUERY_KEYS.getFullApplication(Number(applicationId))],
    () => sharedApi.getFullApplication({ applicationId }),
    {
      enabled: !!applicationId,
      onError: () =>
        openNotification(
          "Failed to load full application.",
          NOTIFICATIONS.error
        ),
    }
  );

  return {
    fullApplication,
    isFullApplicationLoading,
    refetchFullApplication,
    isFullApplicationFetching,
  };
}
