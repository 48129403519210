import axios from "axios";

import ROUTES from "renter/config/apiRoutes";
import { API_URL } from "shared/lib/api/setupApi";
import { transformRoute } from "shared/utils/routing";

const api = {
  /**
   *
   * @param {string} companyHashId
   * @returns {Promise.<{ name: string, logo: string }>}
   */
  getMagicPortalCompany: ({ companyHashId }) =>
    axios
      .get(
        `${API_URL}${transformRoute(ROUTES.getMagicPortalCompany, {
          companyHashId,
        })}`
      )
      .then(({ data }) => data),

  /**
   *
   * @param {string} companyHashId
   * @param {number} page
   * @param {number} pageSize
   * @param {string} nameOrAddress
   * @param {string} prescreeningOnly
   * @returns {Promise.<{ count: number, next: string, previous: string, results: Array.<object> }>}
   */
  getMagicPortalProperties: ({
    companyHashId,
    page,
    pageSize,
    nameOrAddress,
    prescreeningOnly,
  }) =>
    axios
      .get(
        `${API_URL}${transformRoute(ROUTES.getMagicPortalProperties, {
          companyHashId,
        })}`,
        {
          params: {
            page,
            page_size: pageSize,
            name_or_address: nameOrAddress,
            prescreening_only: prescreeningOnly,
          },
        }
      )
      .then(({ data }) => data),

  getMagicPortalProperty: ({ propertyHashId }) =>
    axios
      .get<MagicPortalPropertyResponse>(
        `${API_URL}${transformRoute(ROUTES.getMagicPortalProperty, {
          propertyHashId,
        })}`
      )
      .then(({ data }) => data),

  getMagicPortalUnits: ({
    propertyHashId,
    page,
    pageSize,
    bedrooms,
    bathrooms,
    maxRent,
  }) =>
    axios
      .get(
        `${API_URL}${transformRoute(ROUTES.getMagicPortalPropertyUnits, {
          propertyHashId,
        })}`,
        {
          params: {
            page,
            page_size: pageSize,
            bedrooms,
            bathrooms,
            max_rent: maxRent,
          },
        }
      )
      .then(({ data }) => data),
};

export const getMagicPortalUnitListings = (props: {
  propertyHashId: string;
  unitHashId: string;
  startDate: string;
  endDate: string;
}): Promise<MagicPortalUnitListingsResponse> =>
  axios
    .get(
      `${API_URL}${transformRoute(ROUTES.getMagicPortalPropertyUnitListings, {
        unitHashId: props.unitHashId,
      })}`,
      { params: { start_date: props.startDate, end_date: props.endDate } }
    )
    .then(({ data }) => data);

export const getMagicPortalPropertyUnit = ({ propertyHashId, unitHashId }) =>
  axios
    .get<MagicPortalPropertyUnitResponse>(
      `${API_URL}${transformRoute(ROUTES.getMagicPortalPropertyUnit, {
        propertyHashId,
        unitHashId,
      })}`
    )
    .then(({ data }) => data);

export const getLroConfig = ({ propertyId, unitId }) =>
  axios
    .get<LroConfigResponse>(
      `${API_URL}${transformRoute(ROUTES.getLroConfig, {})}`,
      {
        params: {
          propertyId,
          unitId,
        },
      }
    )
    .then(({ data }) => data);

export const getMagicPortalUnitLro = ({ unitHashId }) =>
  axios
    .get<MagicPortalUnitLroResponse>(
      `${API_URL}${transformRoute(ROUTES.getMagicPortalUnitLro, {
        unitHashId,
      })}`
    )
    .then(({ data }) => data);

export type MagicPortalUnitListingsResponse = {
  isCurrent: true;
  rent?: number;
  unitAvailableOn?: Date;
  unitClosedAt?: Date | null;
  hashedId?: string; // "ul__001__gn",
  deleted?: Date | null;
  securityDeposit?: number | null;
  note?: string | null;
  term?: number;
  startDate?: Date;
  endDate?: Date;
  date?: Date;
  amount?: number;
  id?: string;
}[];

export type MagicPortalPropertyResponse = {
  name: string;
  logoText: string;
  logoUrl: string;
  address: string;
  zipcode: string;
  city: string;
  state: string;
  country: string;
  hashedId: string;
  magicLink: string;
  hasUnits: boolean;
  noBedroomDescription: string;
  preventInvitesToTheApplicationWithoutUnit: boolean;
  hasLroEnabled: boolean;
};

export type MagicPortalPropertyUnitResponse = {
  bedrooms: number;
  bathrooms: string;
  hashedId: string;
  address: string;
  city: string;
  state: string;
  zipcode: string;
  propertyName: string;
  squareFeet: number;
  name: string;
  unitAvailableOn?: Date;
  nextUnitListingDate?: Date;
  fqlVrn?: string;
};

export type MagicPortalUnitLroResponse = {
  enabled: boolean;
  propertyHashId: string;
  unitHashId: string;
};

export enum LroSource {
  LRO = "LRO",
  YIELDSTAR = "YIELDSTAR",
}

export type LroConfigResponse = {
  lroWindow?: number;
  source?: LroSource;
  daysFromUnitAvailability?: number;
  allowedMoveinDays?: number[];
  selectionValidityLifespan?: number;
};

export default api;
