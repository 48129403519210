import React, { useEffect, useState } from "react";

import { Location } from "history";
import isFunction from "lodash/isFunction";
import NavigationPrompt, {
  NavigationPromptProps,
} from "react-router-navigation-prompt";

import { Modal, ModalProps } from "shared/components/Modal/Modal";

export type PreventLeaveFormModalProps = NavigationPromptInnerProps & {
  preventLeaveWhen: NavigationPromptProps["when"];
  customOnCancel?: (onConfirm: () => void, onCancel: () => void) => void;
  customOnConfirm?: (onConfirm: () => void, onCancel: () => void) => void;
  onNextLocationTriggered?: (next: Location<any>) => void;
};

type NavigationPromptInnerProps = Omit<ModalProps, "afterClose" | "close"> & {
  onCancel?: () => void;
  onConfirm?: () => void;
  isActive?: boolean;
  customAfterHooks?: boolean;
  originOnCancel?: () => void;
  submitForm?: () => void;
  resetForm?: () => void;
  children?: React.ReactNode;
  isSubmitting?: boolean;
  cancelLinkLabel?: string;
  submitButtonLabel?: string;
  afterClose?: () => void;
  close?: () => void;
};

export const NavigationPromptInner = ({
  onCancel = () => {},
  onConfirm = () => {},
  isActive,
  customAfterHooks,
  submitForm = () => {},
  resetForm = () => {},
  children,
  isSubmitting,
  cancelLinkLabel = "Don't save",
  submitButtonLabel = "Save Changes",
  originOnCancel = () => {},
  ...props
}: NavigationPromptInnerProps) => {
  const [internalVisible, setInternalVisible] = useState(true);

  // NOTE: if custom hooks are used, the flow is customized, but the visibility of this modal is handled internally
  // (must respect the 'Confirm' and 'Cancel' actions)
  const isInternallyVisible = !customAfterHooks || internalVisible;

  useEffect(() => {
    // NOTE: reset the internal value each time the external `active` is set to true,
    // after that the modal handles the state internally (should be used only if provided custom hooks)
    if (isActive) {
      setInternalVisible(true);
    }
  }, [isActive]);

  return (
    <Modal
      visible={isActive && isInternallyVisible}
      close={() => {
        setInternalVisible(false);
        originOnCancel();
      }}
      afterClose={() => {}}
      submitButtonHTMLType="button"
      submit={async () => {
        await submitForm();
        setInternalVisible(false);
        onConfirm();
      }}
      onCancelLinkClick={() => {
        resetForm();
        // NOTE: the default is to execute the url change, if custom hooks are used, we expose the base API
        // and let the consumer decide what to do
        setInternalVisible(false);

        if (customAfterHooks) {
          onCancel();
        } else {
          onConfirm();
        }
      }}
      cancelLinkLabel={cancelLinkLabel}
      submitButtonLabel={submitButtonLabel}
      submitting={isSubmitting}
      closeOnSubmit={false}
      {...props}
    >
      {children}
    </Modal>
  );
};

export const PreventLeaveFormModal = ({
  preventLeaveWhen,
  customOnCancel,
  customOnConfirm,
  onNextLocationTriggered,
  ...props
}: PreventLeaveFormModalProps) => {
  const preventLeaveWrapper: NavigationPromptProps["when"] = (
    oldRoute: Location<any>,
    newRoute: Location<any>
  ) => {
    if (isFunction(onNextLocationTriggered)) {
      onNextLocationTriggered(newRoute);
    }

    if (newRoute.state && newRoute.state.skipPrompt) {
      return false;
    }
    return isFunction(preventLeaveWhen)
      ? preventLeaveWhen(oldRoute, newRoute)
      : preventLeaveWhen;
  };

  return (
    <NavigationPrompt
      renderIfNotActive
      when={preventLeaveWrapper}
      disableNative
    >
      {({ onCancel, onConfirm, isActive }) => (
        <NavigationPromptInner
          onCancel={
            customOnCancel
              ? () => customOnCancel(onConfirm, onCancel)
              : onCancel
          }
          onConfirm={
            customOnConfirm
              ? () => customOnConfirm(onConfirm, onCancel)
              : onConfirm
          }
          isActive={isActive}
          customAfterHooks={!!customOnConfirm || !!customOnCancel}
          originOnCancel={onCancel}
          {...props}
        />
      )}
    </NavigationPrompt>
  );
};
