import React from "react";

import { Row, Col } from "antd";
import { useFormikContext } from "formik";

import { Button } from "shared/components/Button";
import { Input, Checkbox, VeroFormField } from "shared/components/Form";
import Spacer from "shared/components/Spacer";

import { OpenApiSettingsFormProps } from "./interfaces";

const webhookEnabledProp = "webhookEnabled";
const webhookUrlProp = "webhookUrl";
const redirectUrlProp = "redirectUrl";

const OpenApiSettingsForm = ({
  onTestWebhook,
  isTestingWebhook,
}: OpenApiSettingsFormProps) => {
  const { values, errors } = useFormikContext();

  return (
    <section data-testid="settings-form">
      <Row gutter={[24, 24]}>
        <Col xl={8} md={11} xs={19}>
          <VeroFormField
            id={webhookEnabledProp}
            name={webhookEnabledProp}
            label="Enable webhook notifications"
            as={Checkbox}
          />
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        <Col xl={8} md={11} xs={19}>
          <VeroFormField
            id={webhookUrlProp}
            name={webhookUrlProp}
            label="Webhook"
            as={Input}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            disabled={!values[webhookUrlProp] || !!errors[webhookUrlProp]}
            onClick={() => onTestWebhook(values[webhookUrlProp])}
            loading={isTestingWebhook}
            type="primary"
          >
            Test WebHook
          </Button>
        </Col>
      </Row>
      <Spacer size={Spacer.SIZES.md} />
      <Row gutter={[24, 24]}>
        <Col xl={8} md={11} xs={19}>
          <VeroFormField
            id={redirectUrlProp}
            name={redirectUrlProp}
            label="Redirect Url"
            as={Input}
          />
        </Col>
      </Row>
    </section>
  );
};

export default OpenApiSettingsForm;
