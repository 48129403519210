import * as Yup from "yup";

export const ValidationSchema = Yup.object().shape({
  code: Yup.string().required("License is required."),
  state: Yup.string().required("State is required"),
  username: Yup.string().required("Username is required."),
  password: Yup.string().required("Password is required."),
});

export const initialValues = {
  code: "",
  state: "",
  name: "",
  username: "",
  password: "",
};
