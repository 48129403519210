import React from "react";

import { Formik } from "formik";

import { Button, BUTTON_SIZES } from "shared/components/Button";
import FlexContainer from "shared/components/FlexContainer";
import { UnderlineLink } from "shared/components/Links";
import Spacer from "shared/components/Spacer";
import { LargeTitle, RegularText } from "shared/components/Typography";
import { VeroModal } from "shared/components/vero-modal";

import { ApproveDealModalProps } from "../approve-deal-modal";

import { ApplicantsCheckList, Subtitle } from "../styled";

import { useSendToEntradaModal } from "./context";
import { useHook } from "./hooks";

export const SendToEntrataModal = ({ deal }: ApproveDealModalProps) => {
  const state = useSendToEntradaModal();
  const { sendToPms } = useHook(deal, state.hide);
  const { cancel$, submit$, submitting } = state;

  return (
    <Formik initialValues={{}} onSubmit={() => submit$.next(sendToPms())}>
      {({ submitForm }) => (
        <VeroModal {...state}>
          <LargeTitle>Send to Entrata</LargeTitle>
          <Subtitle>
            <RegularText strong>Applicants:&nbsp;</RegularText>
            <ApplicantsCheckList inline items={deal?.applicantsSummary} />
          </Subtitle>

          <Spacer size={Spacer.SIZES.default} />

          <FlexContainer
            justifyContent="flex-end"
            gap="24px"
            alignItems="center"
          >
            <UnderlineLink onClick={() => cancel$.next()}>Cancel</UnderlineLink>
            <Button
              type="primary"
              onClick={submitForm}
              size={BUTTON_SIZES.lg}
              loading={submitting}
            >
              Send to Entrata
            </Button>
          </FlexContainer>
        </VeroModal>
      )}
    </Formik>
  );
};
