import styled from "@emotion/styled";
import { Typography } from "antd";

import { COLORS } from "shared/config/constants";

export const QuestionListContainer = styled.div`
  display: flex;
  flex-direction: column;
  & > div:not(:last-child) {
    margin-bottom: 25px;
  }
`;

export const Question = styled(Typography.Text)`
  display: block;
  font-size: 16px;
  line-height: 1.3;
  margin-bottom: 15;
`;

export const AnswerListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 7;
`;

export const AnswerContainer = styled.div`
  background-color: ${COLORS.background};
  padding: 15px;
`;

export const AnswerApplicant = styled(Typography.Text)`
  display: block;
  font-size: 12px;
  font-weight: 500;
  lineheight: 1.3;
  color: #787395;
  margin-bottom: 8px;
`;

export const Answer = styled(Typography.Text)`
  display: block;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.3;
  color: #2d2943;
`;

export const AnswerDescription = styled(Typography.Text)`
  font-size: 15px;
  font-weight: 400,
  line-height: 1.3,
  white-space: pre-line;
  color: #2D2943;
`;
