import React from "react";

import { ANNOTATION_TYPES } from "admin/components/PDFTronViewer/webviewer";
import { FIELD_TYPES_LABELS } from "admin/config/constants";
import Collapse from "shared/components/Collapse";
import Icon from "shared/components/Icon";
import { Popover } from "shared/components/Popover";
import Tile from "shared/components/Tile";

import FieldDescription from "./FieldDescription";
import { FieldsListProps } from "./interfaces";
import {
  StyledField,
  NoFieldsMessage,
  StyledHeader,
  IconWrapper,
} from "./styled";

const FieldsList = ({ fields, addField }: FieldsListProps) => (
  <>
    {fields.length ? (
      fields.map((field) =>
        field.collapsible ? (
          <Collapse
            key={field.displayedName}
            header={({ CollapseIcon }) => (
              <Tile.Inner style={{ padding: "6px 15px", fontSize: "15px" }}>
                <StyledHeader>
                  {field.displayedName}
                  <CollapseIcon />
                </StyledHeader>
              </Tile.Inner>
            )}
          >
            <Tile.Inner style={{ padding: "0 15px" }}>
              <FieldsList fields={field.fields} addField={addField} />
            </Tile.Inner>
          </Collapse>
        ) : (
          <Tile.Inner key={field.name} style={{ padding: "0 15px" }}>
            {field.type === ANNOTATION_TYPES.signature ? (
              <StyledField
                // @ts-ignore
                onClick={() => addField(field)}
                data-testid={field.name}
                shifted={field.shifted}
              >
                {field.displayedName}
              </StyledField>
            ) : (
              // @ts-ignore
              <Popover
                trigger="hover"
                mouseEnterDelay={1}
                content={<FieldDescription field={field} />}
              >
                <StyledField
                  // @ts-ignore
                  onClick={() => addField(field)}
                  data-testid={field.name}
                  shifted={field.shifted}
                >
                  {field.displayedName}
                  {field.displayedType === FIELD_TYPES_LABELS.SM && (
                    <IconWrapper>
                      <Icon.SmartFieldIcon />
                    </IconWrapper>
                  )}
                  {field.displayedType === FIELD_TYPES_LABELS.CB && (
                    <IconWrapper>
                      <Icon.CheckboxFieldIcon />
                    </IconWrapper>
                  )}
                </StyledField>
              </Popover>
            )}
          </Tile.Inner>
        )
      )
    ) : (
      <NoFieldsMessage>No fields available</NoFieldsMessage>
    )}
  </>
);

export default FieldsList;
