import { schema } from "normalizr";

export const property = new schema.Entity("property");
export const propertyList = [property];

export const applicant = new schema.Entity("applicant");
export const applicantList = [applicant];

export const requiredAttachment = new schema.Entity("requiredAttachment");
export const requiredAttachmentList = [requiredAttachment];

export const applicationStatus = new schema.Entity("applicationStatus");
export const applicationStatusList = [applicationStatus];

export const dealStatus = new schema.Entity("dealStatus");
export const dealStatusList = [dealStatus];

export const unitStatus = new schema.Entity("unitStatus");
export const unitStatusList = [unitStatus];
