import React from "react";

import Viewer from "admin/components/PDFTronViewer";
import { SwitchTitle } from "shared/components/Form";
import { Modal } from "shared/components/Modal";
import LeasePreviewLayout from "shared/templates/LeasePreviewLayout";

import DocumentInfoContent from "./DocumentInfoContent";
import { PreviewDocumentModalProps } from "./interfaces";

const PreviewDocumentModal = ({
  document,
  viewerApi,
  isPreviewMode,
  onSwitchMode,
  loading,
}: PreviewDocumentModalProps) => (
  <Modal title={document.name} showFooter={false} fullScreen>
    <LeasePreviewLayout
      ViewerContent={
        <Viewer
          viewerApi={viewerApi}
          showZoomControls
          RightTools={
            <SwitchTitle
              value={isPreviewMode}
              onChange={onSwitchMode}
              title="Preview data"
              disabled={loading || viewerApi.isDocumentLoading}
            />
          }
          loading={loading}
        />
      }
      DocumentInfoContent={<DocumentInfoContent document={document} />}
    />
  </Modal>
);

export default PreviewDocumentModal;
