import React from "react";

import { usePropertyTypes } from "shared/hooks/api";

import CreatePropertyInnerFormElements from "./CreatePropertyInnerFormElements";

const CreatePropertyInnerFormElementsContainer = () => {
  const { propertyTypes } = usePropertyTypes();

  return <CreatePropertyInnerFormElements propertyTypes={propertyTypes} />;
};

export default CreatePropertyInnerFormElementsContainer;
