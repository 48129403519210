import { useEffect } from "react";

import api from "shared/lib/api";
import { notifyInfo, notifyWarning } from "shared/utils/ui";

const CHECK_FOR_UPDATES_INTERVAL_SECONDS = 300; // 5 min
const FORCED_AUTO_REFRESH_SECONDS = CHECK_FOR_UPDATES_INTERVAL_SECONDS * 3; // 15 min
const AUTO_REFRESH_TIMEOUT = 60;

let intervalId;

function clearIntervalId() {
  if (intervalId) {
    clearInterval(intervalId);
    intervalId = null;
  }
}

function delay(f, ttl) {
  clearIntervalId();
  intervalId = setTimeout(f, ttl * 1000);
  return intervalId;
}

function clearVersion() {
  sessionStorage.removeItem("version");
  sessionStorage.removeItem("last-check-with-update");
}

function setVersion(version) {
  sessionStorage.setItem("version", version);
}

async function checkVersion() {
  const currentVersion = sessionStorage.getItem("version");
  const lastUpdate = parseInt(
    sessionStorage.getItem("last-check-with-update") || Date.now(),
    10
  );

  const version = await api.getAppVersion();

  if (!currentVersion) {
    console.info("initial version", version);
    setVersion(version);
  }

  if (currentVersion && version && currentVersion !== version) {
    console.info("new version", version);
    const msg =
      "New version available! Save your work and refresh the page to maintain the best experience.";
    const diff = Date.now() - lastUpdate;

    if (diff > FORCED_AUTO_REFRESH_SECONDS) {
      notifyWarning(
        `${msg} This page will automatically refresh in ${AUTO_REFRESH_TIMEOUT} seconds`,
        {
          key: version,
          duration: AUTO_REFRESH_TIMEOUT,
        }
      );

      delay(() => window.location.reload(), AUTO_REFRESH_TIMEOUT);
    } else {
      notifyInfo(msg, {
        key: version,
        duration: 15,
      });

      sessionStorage.setItem("last-check-with-update", lastUpdate.toString());
      delay(checkVersion, CHECK_FOR_UPDATES_INTERVAL_SECONDS);
    }
  } else {
    delay(checkVersion, CHECK_FOR_UPDATES_INTERVAL_SECONDS);
  }
}

const useNewAppVersionListener = () => {
  useEffect(() => {
    clearVersion();
    checkVersion();
  }, []);
};

export default useNewAppVersionListener;
