import React from "react";

import { Row } from "antd";
import { Helmet } from "react-helmet";

import ROUTES from "manager/config/routes";
import Container from "shared/components/Container";
import Icon from "shared/components/Icon";
import PageTitle from "shared/components/PageTitle";
import Skeleton from "shared/components/Skeleton";
import { IntegrationProvider } from "shared/config/constants";
import { usePermissions } from "shared/hooks";
import { EntrataLogoLargeSvg, RealPageLogoLargeSvg } from "shared/icons";
import {
  BluemoonLogo,
  VeroPlusMarketplaceLogo,
  YardiLogoDarkSvg,
} from "shared/images";
import { transformRoute } from "shared/utils/routing";

import {
  IntegrationStatus,
  IntegrationStatusDropdown,
} from "./IntegrationStatus";
import { MarketplaceTile } from "./MarketplaceTile";
import {
  MarketplaceBanner,
  StyledMarketplace,
  YardiLogo,
  BluemoonLogoWrapper,
  TitleWrapper,
  ContentWrapper,
  ArrowIcon,
} from "./styled";
import { TileNoIntegration } from "./TileNoIntegration";
import { useMarketplace } from "./use-marketplace";

const title = "Marketplace";

const Marketplace = () => {
  const { loading, yardi, bluemoon, entrata, realPage, disabledClickAction } =
    useMarketplace();

  const { isIntegrationsAdmin, companyPermissions } = usePermissions();

  const { canUseBluemoon } = companyPermissions || {};

  return (
    <Skeleton loading={loading}>
      <StyledMarketplace>
        <Helmet>
          <title>{title}</title>
        </Helmet>

        <Container noPaddingBottom>
          <PageTitle.Container>
            <PageTitle>
              <PageTitle.Icon>
                <img src={VeroPlusMarketplaceLogo} alt="vero" />
              </PageTitle.Icon>
              {title}
            </PageTitle>
          </PageTitle.Container>
        </Container>
        <Container noMobilePaddingX noPaddingTop maxWidth="xl">
          <MarketplaceBanner>
            <Icon.IntegrationTextIcon className="banner-icon" />
            <div>
              VERO is committed to automating your workflow by integrating with
              the best-in-class partners. Marketplace allows you to manage your
              integrations in a simple and secure way.
            </div>
          </MarketplaceBanner>

          <Row type="flex">
            <MarketplaceTile
              disabled={!yardi?.isEnabled || !isIntegrationsAdmin}
              disabledClickAction={() =>
                disabledClickAction(IntegrationProvider.yardi)
              }
              to={transformRoute(
                yardi?.isConnected
                  ? ROUTES.integrationDashboard
                  : ROUTES.integrationLogin,
                { provider: IntegrationProvider.yardi }
              )}
            >
              <MarketplaceTile.Inner>
                <MarketplaceTile.Content>
                  <TitleWrapper fullWidth justifyContent="space-between">
                    <div>
                      <YardiLogo src={YardiLogoDarkSvg} alt="Yardi" />
                    </div>
                    <div className="health-check-status">
                      <IntegrationStatusDropdown
                        status={yardi?.status}
                        provider={IntegrationProvider.yardi}
                        hideIcon
                        transparentBackground
                      />
                    </div>
                  </TitleWrapper>
                  <ContentWrapper
                    fullWidth
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <MarketplaceTile.Description>
                      Real estate investment & property management software
                    </MarketplaceTile.Description>
                    <ArrowIcon />
                  </ContentWrapper>
                </MarketplaceTile.Content>
              </MarketplaceTile.Inner>
            </MarketplaceTile>

            <MarketplaceTile
              disabled={!bluemoon?.isEnabled || !canUseBluemoon}
              disabledClickAction={() =>
                disabledClickAction(IntegrationProvider.bluemoon)
              }
              to={bluemoon.isEnabled ? ROUTES.bluemoonDashboard : undefined}
            >
              <MarketplaceTile.Inner>
                <MarketplaceTile.Content>
                  <TitleWrapper fullWidth justifyContent="space-between">
                    <div>
                      <BluemoonLogoWrapper src={BluemoonLogo} alt="Bluemoon" />
                    </div>
                    <div className="health-check-status">
                      <IntegrationStatusDropdown
                        status={
                          bluemoon.isConnected
                            ? IntegrationStatus.CONNECTED
                            : IntegrationStatus.NOT_CONNECTED
                        }
                        hideIcon
                        transparentBackground
                      />
                    </div>
                  </TitleWrapper>
                  <ContentWrapper
                    fullWidth
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <MarketplaceTile.Description>
                      Industry standard lease forms for properties across the
                      country
                    </MarketplaceTile.Description>
                    <ArrowIcon />
                  </ContentWrapper>
                </MarketplaceTile.Content>
              </MarketplaceTile.Inner>
            </MarketplaceTile>

            <MarketplaceTile
              disabled={!entrata?.isEnabled || !isIntegrationsAdmin}
              disabledClickAction={() =>
                disabledClickAction(IntegrationProvider.entrata)
              }
              to={transformRoute(
                entrata?.isConnected
                  ? ROUTES.integrationDashboard
                  : ROUTES.integrationLogin,
                { provider: IntegrationProvider.entrata }
              )}
            >
              <MarketplaceTile.Inner>
                <MarketplaceTile.Content>
                  <TitleWrapper fullWidth justifyContent="space-between">
                    <div>
                      <YardiLogo src={EntrataLogoLargeSvg} alt="Entrata" />
                    </div>
                    <div className="health-check-status">
                      <IntegrationStatusDropdown
                        status={entrata?.status}
                        provider={IntegrationProvider.entrata}
                        hideIcon
                        transparentBackground
                      />
                    </div>
                  </TitleWrapper>
                  <ContentWrapper
                    fullWidth
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <MarketplaceTile.Description>
                      Real estate investment & property management software
                    </MarketplaceTile.Description>
                    <ArrowIcon />
                  </ContentWrapper>
                </MarketplaceTile.Content>
              </MarketplaceTile.Inner>
            </MarketplaceTile>

            <MarketplaceTile
              disabled={!realPage?.isEnabled || !isIntegrationsAdmin}
              disabledClickAction={() =>
                disabledClickAction(IntegrationProvider.realPage)
              }
              to={transformRoute(
                realPage?.isConnected
                  ? ROUTES.integrationDashboard
                  : ROUTES.integrationLogin,
                { provider: IntegrationProvider.realPage }
              )}
            >
              <MarketplaceTile.Inner>
                <MarketplaceTile.Content>
                  <TitleWrapper fullWidth justifyContent="space-between">
                    <div>
                      <YardiLogo src={RealPageLogoLargeSvg} alt="Real Page" />
                    </div>
                    <div className="health-check-status">
                      <IntegrationStatusDropdown
                        status={realPage?.status}
                        provider={IntegrationProvider.realPage}
                        hideIcon
                        transparentBackground
                      />
                    </div>
                  </TitleWrapper>
                  <ContentWrapper
                    fullWidth
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <MarketplaceTile.Description>
                      Real estate and property management software
                    </MarketplaceTile.Description>
                    <ArrowIcon />
                  </ContentWrapper>
                </MarketplaceTile.Content>
              </MarketplaceTile.Inner>
            </MarketplaceTile>

            <TileNoIntegration />
          </Row>
        </Container>
      </StyledMarketplace>
    </Skeleton>
  );
};

export default Marketplace;
