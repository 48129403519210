
import flatMap from "lodash/flatMap";
import flatten from "lodash/flatten";
import values from "lodash/values";

import { Button } from "shared/components/Button";
import { CollapsibleRadioList, ListWrapper } from "shared/components/Form";
import Icon from "shared/components/Icon";
import { Modal, SIZES } from "shared/components/Modal";
import { printDollarsFromCents } from "shared/utils/dollar-print";
import { formatDate } from "shared/utils/ui";

interface AssignDealRevenueManagementModalProps {
  pricingOffersByLeaseStartDate?: any;
  propertyName: string;
  unitName: string;
  availableOn: string;
  isMobile: boolean;
  submit: () => void;
  setPricingOffer: (value: any) => void;
  selectedPricingOffer: any;
  isPricingOffersLoading: boolean;
  emptyPricingOffer: any;
  manuallyEditRent: () => void;
}

const AssignDealRevenueManagementModal = ({
  pricingOffersByLeaseStartDate = {},
  propertyName,
  unitName,
  availableOn,
  isMobile,
  submit,
  setPricingOffer,
  selectedPricingOffer,
  isPricingOffersLoading,
  emptyPricingOffer,
  manuallyEditRent,
}: AssignDealRevenueManagementModalProps) => (
  <Modal
    title="Select Lease Terms"
    subtitle={
      <span data-testid="subtitle">
        <strong>
          {propertyName} - {unitName}
        </strong>
        <br />
        Available: {formatDate(availableOn)}
      </span>
    }
    submit={submit}
    submitButtonLabel="Confirm"
    submitButtonSize={SIZES.lg}
    submitButtonDisabled={!selectedPricingOffer?.id}
    closeOnSubmit={false}
    width="auto"
    style={{ maxWidth: 946 }}
    fullScreen={isMobile}
    footer={
      <Button type="primary" onClick={manuallyEditRent}>
        Manually Edit Rent
      </Button>
    }
  >
    <Modal.Body>
      <ListWrapper
        hasInputValue={false}
        className="assign-revenue-management-list"
        List={CollapsibleRadioList}
        listProps={{
          setFieldValue: (pricingOfferId) => {
            const pricingOffers = flatten(
              values(pricingOffersByLeaseStartDate)
            );
            const pricingOffer = pricingOffers.find(
              (po) => po.id === pricingOfferId
            );
            setPricingOffer(pricingOffer || emptyPricingOffer);
          },
          selectedItem: selectedPricingOffer?.id,
          items: isPricingOffersLoading
            ? []
            : flatMap(
                pricingOffersByLeaseStartDate,
                (pricingOffers, leaseStartDate) => {
                  return [
                    {
                      key: `lease-start-${leaseStartDate}`,
                      label: `Lease Start: ${formatDate(leaseStartDate)}`,
                      collapsible: true,
                    },
                    ...pricingOffers.map((pricingOffer) => ({
                      key: pricingOffer.id,
                      parent: `lease-start-${leaseStartDate}`,
                      label: `Term: ${pricingOffer?.term} months`,
                      description: [
                        {
                          node: `Rent: ${printDollarsFromCents(
                            pricingOffer?.amount,
                            {
                              withCents: true,
                            }
                          )}`,
                          noSeparator: true,
                        },
                      ],
                      className:
                        pricingOffer.id === selectedPricingOffer?.id
                          ? "selected-item"
                          : undefined,
                    })),
                  ];
                }
              ),
          itemProps: {
            noBorderBottom: false,
            firstItemNoBorderTop: false,
          },
        }}
        listContainerProps={{
          groupName: "pricingOffers",
        }}
        placeholder={{
          label: "Revenue Management",
          Icon: Icon.NoDataIcon,
        }}
        loading={isPricingOffersLoading}
      />
    </Modal.Body>
  </Modal>
);

export default AssignDealRevenueManagementModal;
