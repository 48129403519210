import React from "react";

import { useUnblockApplicationIfNoIncomeVerified } from "manager/hooks/api";
import ConfirmationModal from "shared/components/Modals/ConfirmationModal";
import { RegularText } from "shared/components/Typography";
import useModal from "shared/hooks/useModal";

export type UnblockApplicationItemProps = {
  id: number;
  isSubmitted: boolean;
  canUpdateBlockDealSubmitIfIncomeDataIsMissing: { value: boolean };
  blockDealSubmitIfIncomeDataIsMissing: boolean;
  firstName: string;
  lastName: string;
};

const useUnblockApplicationItem = (
  applicationSummary: UnblockApplicationItemProps,
  onSuccess: () => void
) => {
  const { openModalDialog } = useModal();

  const {
    id,
    isSubmitted,
    canUpdateBlockDealSubmitIfIncomeDataIsMissing,
    blockDealSubmitIfIncomeDataIsMissing,
    firstName,
    lastName,
  } = applicationSummary ?? {};

  const { unblockApplicationIfNoIncome } =
    useUnblockApplicationIfNoIncomeVerified(id);

  const label = "Unblock Applicant";

  const unblockApplicationItem = {
    label,
    showAction:
      !isSubmitted &&
      !!canUpdateBlockDealSubmitIfIncomeDataIsMissing?.value &&
      blockDealSubmitIfIncomeDataIsMissing,
    action: () => {
      const context = {
        title: label,
        subtitle: (
          <RegularText>
            Applicant:{" "}
            <b>
              {firstName} {lastName}
            </b>
          </RegularText>
        ),
        message:
          "Unblocking the applicant will allow them to proceed and submit the application without having to verify their income. The applicant must refresh their browser to dismiss the blocker.",
        submitButtonLabel: label,
      };
      return openModalDialog(ConfirmationModal, context).afterClose.then(
        (result) => {
          if (result) {
            unblockApplicationIfNoIncome(null, { onSuccess });
          }
        }
      );
    },
  };

  return unblockApplicationItem;
};

export default useUnblockApplicationItem;
