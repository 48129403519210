import { useState } from "react";

import { useQuery } from "react-query";

import api from "manager/lib/api";

const GET_INTEGRATION_STATUSES = "getIntegrationStatuses";

export const INTEGRATION_QUERY_KEYS = Object.freeze({
  getIntegrationStatuses: () => [GET_INTEGRATION_STATUSES],
});

export const useGetIntegrationStatuses = (config = {}) => {
  const { data, isLoading } = useQuery(
    INTEGRATION_QUERY_KEYS.getIntegrationStatuses(),
    api.getIntegrationStatuses,
    {
      ...config,
      retry: false,
    }
  );
  return {
    integrationStatuses: data,
    isIntegrationStatusesLoading: isLoading,
  };
};

export const usePollIntegrationStatuses = (interval: number) => {
  const [isPollingActive, setPollingActive] = useState(false);

  const {
    data,
    error: integrationStatusesError,
    isLoading,
    isFetching,
    isError: isPollIntegrationStatusesError,
  } = useQuery(
    INTEGRATION_QUERY_KEYS.getIntegrationStatuses(),
    api.getIntegrationStatuses,
    {
      refetchInterval: isPollingActive ? interval : false,
    }
  );

  return {
    startPolling: () => setPollingActive(true),
    stopPolling: () => setPollingActive(false),
    isLoading,
    isFetching,
    isPollIntegrationStatusesError,
    integrationStatuses: data,
    integrationStatusesError,
    isPollingActive,
  };
};
