import React from "react";

import Icon from "shared/components/Icon";
import AnimatedPendingActionModal from "shared/components/Modals/AnimatedPendingActionModal";

import { LOADING_STATUSES, TIMEOUT } from "./config";

const DownloadPDFStatusModal = () => (
  <AnimatedPendingActionModal
    finalMessageElement={() => <Icon.PDFReadyIcon data-testid="pdf-icon" />}
    messages={LOADING_STATUSES}
    timeout={TIMEOUT}
  />
);

export default DownloadPDFStatusModal;
