import styled from "@emotion/styled";

import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

const LandingStepWrapper = styled.div`
  .header {
    background-color: ${COLORS.primary};
    padding: 48px;

    ${MEDIA_QUERIES.w.ltMd} {
      padding: 30px;
    }

    .title {
      font-size: 55px;
      color: ${COLORS.white};
      font-weight: 200;
      display: block;
      line-height: 1;

      span {
        font-size: 82px;
        font-weight: bold;
      }
    }

    .description {
      padding-right: 90px;

      ${MEDIA_QUERIES.w.ltMd} {
        padding-right: 60px;
        margin-top: 90px;
      }

      span {
        color: ${COLORS.white} !important;
        font-weight: 300;

        &:fist-of-type {
          font-size: 16px;
        }
      }

      .ant-divider-horizontal {
        margin: 16px 0;
      }
    }
  }

  .content {
    padding: 48px 52px;

    ${MEDIA_QUERIES.w.ltMd} {
      padding: 48px 30px;

      .btn-col,
      .btn-col button {
        width: 100%;
      }

      .btn-col button {
        margin-top: 24px;
        border-radius: 13px;
      }
    }

    .btn-col > div {
      height: 40px;
    }
  }
`;

export { LandingStepWrapper };
