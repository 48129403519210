import styled from '@emotion/styled'

import BaseCreditScoreChart from 'shared/components/CreditScoreChart'
import { MEDIA_QUERIES, COLORS } from 'shared/config/constants'

import FlexContainer from '../FlexContainer'

const CreditScoreHeader = styled.div`
  display: flex;
  width: 100%;
  ${MEDIA_QUERIES.w.gteXl} {
    flex-direction: column;
  }
  ${MEDIA_QUERIES.w.ltMd} {
    flex-wrap: wrap;
  }
  ${MEDIA_QUERIES.w.ltXl} {
    align-items: center;
  }
`

const CreditScoreTitle = styled('h2')`
  display: flex;
  align-items: center;
  .no-ssn-warning-icon {
    margin-left: 16px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
  ${MEDIA_QUERIES.w.ltXl} {
    margin-bottom: 0;
  }
  ${MEDIA_QUERIES.w.ltMd} {
    width: 100%;
    margin-bottom: 24px;
  }
`

const CreditScoreRating = styled.h3`
  font-size: 17px;
  font-weight: 500;
  order: 3;

  small {
    font-weight: 300;
    color: ${COLORS.secondary};
    font-size: 12px;
    ${MEDIA_QUERIES.w.gteXl} {
      font-size: 14px;
    }
  }

  ${MEDIA_QUERIES.w.gteXl} {
    font-size: 22px;
    order: 2;
  }
  ${MEDIA_QUERIES.w.ltXl} {
    margin-bottom: 0;
  }
`

const CreditScoreChart = styled(BaseCreditScoreChart)`
  ${MEDIA_QUERIES.w.gteXl} {
    order: 3;
  }
  ${MEDIA_QUERIES.w.ltXl} {
    margin-right: 8px;
    width: 111px;
  }
  ${MEDIA_QUERIES.w.btwMdXl} {
    margin-left: 14px;
  }
`

const Statistic = styled.span<{ bold?: boolean }>`
  font-size: 17px;
  font-weight: ${(props) => props.bold && 500};
`

const CreditReportSubtitle = styled('h3')`
  margin-bottom: 0;
`

const PaymentHistoryContainer = styled('div')`
  display: flex;
  ${MEDIA_QUERIES.w.ltXl} {
    flex-direction: column;
  }
`

const PaymentHistoryLegend = styled('div')`
  display: flex;
  ${MEDIA_QUERIES.w.gteXl} {
    margin-left: 30px;
    flex-direction: column;
    > *:not(:first-of-type) {
      margin-top: 12px;
    }
  }
  ${MEDIA_QUERIES.w.ltXl} {
    flex-wrap: wrap;
    margin-top: 18px;
    > * {
      margin-top: 12px;
      margin-right: 24px;
    }
  }
`

const CriminalRecordsTitleContainer = styled.div`
  display: flex;
  align-items: center;

  ${MEDIA_QUERIES.w.ltMd} {
    flex-direction: column;
    flex-grow: 1;
  }
`

const CriminalRecordsCount = styled.span`
  margin-left: 0.5em;
  ${MEDIA_QUERIES.w.gteMd} {
    ::after {
      content: ', ';
    }
  }
`

const CriminalRecordsPeriod = styled.span`
  ${MEDIA_QUERIES.w.gteMd} {
    margin-left: 0.5em;
  }
  ${MEDIA_QUERIES.w.ltMd} {
    color: ${COLORS.secondary};
    font-size: 12px;
    font-weight: 300;
  }
`

const BreakWordSpan = styled.span`
  overflow-wrap: break-word;
`

const CountyCriminalRecordsTitle = styled(FlexContainer)`
  .warning-icon {
    padding-left: 10px;
  }
`

const NoCountyCriminalRecordsForApplicant = styled(FlexContainer)`
  height: 100px;
  padding: 24px 40px;
  background-color: ${COLORS.tile.inset.background};

  .message {
    // NOTE: goran - color aligned with the non-ideal states we use in verification tabs
    color: ${COLORS.grayTheme.textColor} !important;
  }
`

const CountyCriminalSearchStatusMessageWrapper = styled(FlexContainer)`
  min-height: 200px;
  padding: 24px 40px;
  background-color: ${COLORS.tile.inset.background};

  .message {
    // NOTE: goran - color aligned with the non-ideal states we use in verification tabs
    color: ${COLORS.grayTheme.textColor} !important;
    margin-bottom: 25px;
    text-align: center;
  }
`

const CountyCriminalRecordsTitleContainer = styled.div`
  display: flex;
  align-items: center;

  .county-status-chip {
    margin-right: 24px;
    flex-shrink: 0;
  }

  .county-name {
    flex-shrink: 0;
  }

  .additional-info {
    margin-left: 20px;
  }

  ${MEDIA_QUERIES.w.ltMd} {
    flex-direction: column;
    flex-grow: 1;
    gap: 4px;

    .county-status-chip {
      margin-right: 0px;
    }
  }
`

const WarningBanner = styled.div`
  background-color: ${COLORS.notification.warning.background};
  color: ${COLORS.darkOrange};
  border: 1px solid ${COLORS.darkOrange};
  border-radius: 12px;
  display: flex;
  column-gap: 16px;
  align-items: center;
  padding: 16px 24px;
  margin-bottom: 24px;

  .banner-message {
    flex: 1;
    font-size: 15px;
  }
`

export {
  CreditScoreHeader,
  CreditScoreTitle,
  CreditScoreRating,
  CreditScoreChart,
  Statistic,
  CreditReportSubtitle,
  PaymentHistoryContainer,
  PaymentHistoryLegend,
  CriminalRecordsTitleContainer,
  CriminalRecordsCount,
  CriminalRecordsPeriod,
  BreakWordSpan,
  CountyCriminalRecordsTitle,
  NoCountyCriminalRecordsForApplicant,
  CountyCriminalSearchStatusMessageWrapper,
  CountyCriminalRecordsTitleContainer,
  WarningBanner,
}
