import { combineReducers } from "redux";
import { handleActions } from "redux-actions";

import * as utils from "shared/utils/state";

import * as actions from "../actions";

export const getPropertyTypes = handleActions(
  {
    [actions.getPropertyTypesRequest]: () => utils.requestStart(),
    [actions.getPropertyTypesSuccess]: () => utils.requestSuccess(),
    [actions.getPropertyTypesError]: (state, action) =>
      utils.requestError({ error: action.payload.error }),
  },
  utils.request()
);

export const getApplicantTypes = handleActions(
  {
    [actions.getApplicantTypesRequest]: () => utils.requestStart(),
    [actions.getApplicantTypesSuccess]: () => utils.requestSuccess(),
    [actions.getApplicantTypesError]: (state, action) =>
      utils.requestError({ error: action.payload.error }),
  },
  utils.request()
);

export const getRequiredAttachmentTypes = handleActions(
  {
    [actions.getRequiredAttachmentTypesRequest]: () => utils.requestStart(),
    [actions.getRequiredAttachmentTypesSuccess]: () => utils.requestSuccess(),
    [actions.getRequiredAttachmentTypesError]: (state, action) =>
      utils.requestError({ error: action.payload.error }),
  },
  utils.request()
);

export const getApplicationStatuses = handleActions(
  {
    [actions.getApplicationStatusesRequest]: () => utils.requestStart(),
    [actions.getApplicationStatusesSuccess]: () => utils.requestSuccess(),
    [actions.getApplicationStatusesError]: (state, action) =>
      utils.requestError({ error: action.payload.error }),
  },
  utils.request()
);

export const getDealStatuses = handleActions(
  {
    [actions.getDealStatusesRequest]: () => utils.requestStart(),
    [actions.getDealStatusesSuccess]: () => utils.requestSuccess(),
    [actions.getDealStatusesError]: (state, action) =>
      utils.requestError({ error: action.payload.error }),
  },
  utils.request()
);

export const getUnitStatuses = handleActions(
  {
    [actions.getUnitStatusesRequest]: () => utils.requestStart(),
    [actions.getUnitStatusesSuccess]: () => utils.requestSuccess(),
    [actions.getUnitStatusesError]: (state, action) =>
      utils.requestError({ error: action.payload.error }),
  },
  utils.request()
);

const typeRequestReducer = combineReducers({
  getPropertyTypes,
  getRequiredAttachmentTypes,
  getApplicationStatuses,
  getDealStatuses,
  getUnitStatuses,
});

export default typeRequestReducer;
