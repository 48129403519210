import PropTypes from "prop-types";

const sectionType = PropTypes.shape({
  name: PropTypes.string,
  anchor: PropTypes.string,
});

const sectionsType = PropTypes.arrayOf(sectionType);

const mappingType = PropTypes.shape({
  yardi: PropTypes.arrayOf(PropTypes.object),
  vero: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
});

export { sectionType, sectionsType, mappingType };
