import React from "react";

import EmailTemplatesSettings from "../EmailTemplatesSettings";

import CompanyEmailTemplatesFormContainer from "./CompanyEmailTemplatesFormContainer";

const CompanyEmailTemplatesSettings = () => (
  <EmailTemplatesSettings
    EmailTemplatesForm={CompanyEmailTemplatesFormContainer}
  />
);

export default CompanyEmailTemplatesSettings;
