import React from "react";

import { ActionLink, UnderlineLink } from "shared/components/Links";
import Tile from "shared/components/Tile";
import { SignOutIcon } from "shared/icons";
import { formatPhone } from "shared/utils/ui";

import { UserProfileProps } from "../interfaces";

import { Container, Field } from "./styled";

const UserProfile = ({
  email,
  phone,
  signOut,
  extraFields = [],
}: UserProfileProps) => (
  <div>
    <Container>
      <Field>
        <Tile.Label>Email</Tile.Label>
        {/* @ts-ignore */}
        <UnderlineLink href={`mailto:${email}`}>{email}</UnderlineLink>
      </Field>
      {phone && (
        <Field>
          <Tile.Label>Phone Number</Tile.Label>
          {/* @ts-ignore */}
          <UnderlineLink href={`tel:${phone}`}>
            {formatPhone(phone)}
          </UnderlineLink>
        </Field>
      )}
      {extraFields.map((item) => (
        <Field key={item.key}>{item.node}</Field>
      ))}
    </Container>
    <Container>
      <ActionLink onClick={signOut} Icon={SignOutIcon}>
        Sign Out
      </ActionLink>
    </Container>
  </div>
);

export default UserProfile;
