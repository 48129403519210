

import {
  ApplicationSummaryCard,
  CardSize,
  CardStatus,
} from "shared/components/ApplicationSummaryCard";
import {
  ApplicationSummaryTooltip,
  TooltipColumnWidth,
} from "shared/components/ApplicationSummaryTooltip";
import { EditPencileGrayIcon } from "shared/icons";
import { printDollarsFromCents } from "shared/utils/dollar-print";

export interface IncomeCardProps {
  pending?: boolean;
  note?: string;
  status: CardStatus;
  income?: number;
  stated?: number;
  monthlyIncomePerRent?: number;
  isEdited?: boolean;
  onEdit?: () => void;
  onClick?: () => void;
  lumenEnabled?: boolean;
  lumenEvaluates?: string;
  verifiedIncome?: number;
  totalIncome?: number;
  useIncomeRentRatio?: boolean;
  lumenRecords?: {
    ideal?: boolean;
    good?: boolean;
    negative?: boolean;
    skipped?: boolean;
    groupSkipped?: boolean;
    argyleFailed?: boolean;
    ocrProcessing?: boolean;
    isTotalIncomeWithApplicant?: boolean;
  };
}

export const IncomeCard = ({
  pending,
  note,
  monthlyIncomePerRent,
  lumenEvaluates,
  verifiedIncome,
  totalIncome,
  isEdited,
  status,
  onEdit,
  onClick,
  lumenEnabled,
  useIncomeRentRatio,
  lumenRecords,
}: IncomeCardProps) => {
  const lumenStatus = () => {
    const {
      skipped,
      groupSkipped,
      argyleFailed,
      ocrProcessing,
      ideal,
      good,
      negative,
    } = lumenRecords;
    if (skipped || argyleFailed || negative || groupSkipped) {
      return CardStatus.error;
    }

    if (ocrProcessing) {
      return CardStatus.unavailable;
    }

    if (good) {
      return CardStatus.lumenGood;
    }

    if (ideal) {
      return CardStatus.lumenSuccess;
    }

    return CardStatus.error;
  };

  const lumenTitle = () => {
    const { skipped, groupSkipped, argyleFailed, ocrProcessing } = lumenRecords;

    if (argyleFailed) {
      return "Not Completed";
    }

    if (ocrProcessing) {
      return "In Progress";
    }

    if (groupSkipped && skipped) {
      return "Skipped";
    }

    return printDollarsFromCents(verifiedIncome);
  };

  const hideColor = () => {
    if (!lumenEnabled) {
      return false;
    }

    if (!useIncomeRentRatio) {
      return true;
    }

    if (lumenRecords?.isTotalIncomeWithApplicant) {
      return true;
    }

    return false;
  };

  const card = (
    <div>
      <ApplicationSummaryCard
        title={
          lumenEnabled
            ? lumenTitle()
            : pending
            ? "Not Completed"
            : printDollarsFromCents(verifiedIncome)
        }
        subtitle={`VEROfied Income${isEdited ? " - Edited" : ""}`}
        size={CardSize.Large}
        status={lumenEnabled ? lumenStatus() : status}
        onClick={onClick}
        actionLogo={
          onEdit ? (
            <EditPencileGrayIcon
              onClick={(e) => {
                onEdit?.();
                e.stopPropagation();
              }}
            />
          ) : undefined
        }
        hideColor={hideColor()}
      />
    </div>
  );

  return (
    <ApplicationSummaryTooltip
      layout={[
        {
          title: "VEROfied Income:",
          subtitle: printDollarsFromCents(verifiedIncome),
          width: TooltipColumnWidth.half,
        },
        {
          title: "Applicant Stated:",
          subtitle: printDollarsFromCents(totalIncome),
          width: TooltipColumnWidth.half,
        },

        ...(lumenEnabled
          ? [
              {
                title: "Lumen Evaluates:",
                subtitle: lumenEvaluates,
                width: TooltipColumnWidth.half,
              },
            ]
          : []),
        {
          title: "Income/Rent:",
          subtitle: monthlyIncomePerRent
            ? `${monthlyIncomePerRent.toFixed(1)}x`
            : "-",
          width: TooltipColumnWidth.half,
        },
        ...(note
          ? [
              {
                title: "Note",
                subtitle: note,
                width: TooltipColumnWidth.full,
              },
            ]
          : []),
      ]}
    >
      {card}
    </ApplicationSummaryTooltip>
  );
};
