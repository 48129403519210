import dayjs, { Dayjs } from "dayjs";
import minMax from "dayjs/plugin/minMax";

import { DayRange } from "./types";

dayjs.extend(minMax);

export type DayKey = string;
export const dayKey = (day: Dayjs) => day.format("YYYY-MM-DD") as DayKey;
export const daysInRange = ({ start, end }: DayRange): Dayjs[] => {
  start = start.startOf("day");
  end = end.startOf("day");
  const days: Dayjs[] = [];
  for (
    let day = start;
    day.isBefore(end) || day.isSame(end);
    day = day.add(1, "day")
  ) {
    days.push(day);
  }
  return days;
};

export const getMonthLabel = (count: number) =>
  `${count} ${count === 1 ? "month" : "months"}`;
