import { useMutation, useQuery, queryCache } from "react-query";

import {
  BindPropertiesRequest,
  BluemoonHiddenPropertiesRequest,
  BluemoonPropertiesRequest,
} from "manager/interfaces/api/bluemoon";
import { BluemoonLicense } from "manager/interfaces/api/bluemoonAlertResolver";
import api from "manager/lib/api";
import { BluemoonLicenseEdit } from "manager/pages/Marketplace/integrations/bluemoon/bluemoonAlertsResolvers/interfaces";
import {
  Filters,
  FieldsFilters,
} from "manager/pages/Marketplace/integrations/bluemoon/interfaces";
import { NOTIFICATIONS } from "shared/config/constants";
import { openNotification } from "shared/utils/ui";

const BLUEMOON_LICENSES = "getBluemoonLicenses";
const BLUEMOON_FIELDS = "getBluemoonFields";
const BLUEMOON_FIELDS_TYPES = "getBluemoonFieldsTypes";
const BLUEMOON_FIELDS_DATAPOINTS = "getBluemoonFieldsDatapoints";

const BLUEMOON_PROPERTIES = "getBluemoonProperties";
const VERO_PROPERTIES = "getVeroProperties";
const BLUEMOON_PROPERTIES_COUNT = "getBluemoonPropertiesCount";
const BLUEMOON_HIDDEN_PROPERTIES = "getBluemoonHiddenProperties";
const BLUEMOON_DASHBOARD_DATA = "getBluemoonDashboardData";

export function useGetBluemoonLicensesQuery(
  filters: Filters,
  config = {},
  refetchPropertiesCount
) {
  const response = useQuery(
    [BLUEMOON_LICENSES, filters],
    () => api.getBluemoonLicenses(filters),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      onSuccess: () =>
        refetchPropertiesCount &&
        queryCache.invalidateQueries(BLUEMOON_PROPERTIES_COUNT),
      onError: () => {
        openNotification(
          "Failed to get Bluemoon licenses.",
          NOTIFICATIONS.error
        );
      },
      ...config,
    }
  );

  return response;
}
export function useGetBluemoonFieldsQuery(filters: FieldsFilters, config = {}) {
  const response = useQuery(
    [BLUEMOON_FIELDS, filters],
    () => api.getBluemoonFields(filters),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      onError: () => {
        openNotification("Failed to get Bluemoon fields.", NOTIFICATIONS.error);
      },
      ...config,
    }
  );

  return response;
}
export function useGetBluemoonFieldsTypesQuery() {
  const response = useQuery(
    [BLUEMOON_FIELDS_TYPES],
    api.getBluemoonFieldsTypes,
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      onError: () => {
        openNotification(
          "Failed to get Bluemoon fields types.",
          NOTIFICATIONS.error
        );
      },
    }
  );

  return response;
}
export function useGetBluemoonFieldsDatapointsQuery() {
  const response = useQuery(
    [BLUEMOON_FIELDS_DATAPOINTS],
    api.getBluemoonFieldsDatapoints,
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      onError: () => {
        openNotification(
          "Failed to get Bluemoon fields datapoints.",
          NOTIFICATIONS.error
        );
      },
    }
  );

  return response;
}

export const useUpdateBluemoonField = ({ onError, onSuccessCb }) => {
  const [updateBluemoonField, { isLoading: isBluemoonFieldUpdating }] =
    useMutation((payload) => api.updateBluemoonField(payload), {
      onSuccess: () => {
        queryCache.invalidateQueries(BLUEMOON_FIELDS);
        onSuccessCb();
      },
      onError: (error: any) => onError({ fieldError: error?.errors }),
    });

  return {
    updateBluemoonField,
    isBluemoonFieldUpdating,
  };
};
export const useSaveBluemoonLicense = () => {
  const [saveBluemoonLicense, { isLoading: isBluemoonLicenseLoading }] =
    useMutation(
      (payload: BluemoonLicense) =>
        api.postBluemoonAlertResolveConnectLicense(payload),
      {
        onSuccess: () => {
          openNotification("Bluemoon license is saved.", NOTIFICATIONS.info);
          queryCache.invalidateQueries(BLUEMOON_LICENSES);
          queryCache.invalidateQueries(BLUEMOON_DASHBOARD_DATA);
        },
      }
    );

  return {
    saveBluemoonLicense,
    isBluemoonLicenseLoading,
  };
};

export const useEditBluemoonLicense = ({
  license,
}: {
  license: BluemoonLicenseEdit;
}) => {
  const [editBluemoonLicense, { isLoading: isBluemoonLicenseLoading }] =
    useMutation(
      (payload: BluemoonLicenseEdit) =>
        api.patchBluemoonAlertResolveEditLicense(payload),
      {
        onSuccess: (data: BluemoonLicenseEdit) => {
          let message = "was edited";
          if (!license.isActive && data.isActive) {
            message = "is now active";
          } else if (license.isActive && !data.isActive) {
            message = "is now disabled";
          }

          openNotification(
            `Bluemoon license "${data.code}" ${message}.`,
            NOTIFICATIONS.info
          );
          queryCache.invalidateQueries(BLUEMOON_LICENSES);
          queryCache.invalidateQueries(BLUEMOON_DASHBOARD_DATA);
        },
      }
    );

  return {
    editBluemoonLicense,
    isBluemoonLicenseLoading,
  };
};

export const useGetBluemoonPropertiesCount = () => {
  const { data, isLoading } = useQuery(
    [BLUEMOON_PROPERTIES_COUNT],
    () => api.getBluemoonPropertiesCount(),
    {
      onError: () => {
        openNotification(
          "Failed to get Bluemoon properties data.",
          NOTIFICATIONS.error
        );
      },
    }
  );
  return {
    propertiesCount: data,
    isPropertiesCountLoading: isLoading,
  };
};

export const useGetVeroPropertiesQuery = (
  params: BluemoonPropertiesRequest
) => {
  const { data, isLoading, refetch } = useQuery(
    [VERO_PROPERTIES],
    () => api.getVeroProperties(params),
    {
      onError: () =>
        openNotification("Failed to get Vero Properties.", NOTIFICATIONS.error),
    }
  );
  return {
    veroProperties: data,
    isVeroPropertiesLoading: isLoading,
    refetchVeroProperties: refetch,
  };
};

export const useHideBluemoonProperty = () => {
  const [hideBluemoonProperty] = useMutation(
    (propertyId: number) => api.hideBluemoonProperty(propertyId),
    {
      onSuccess: () => {
        openNotification(
          "Bluemoon property successfully hidden.",
          NOTIFICATIONS.info
        );
        queryCache.invalidateQueries(BLUEMOON_PROPERTIES);
        queryCache.invalidateQueries(BLUEMOON_PROPERTIES_COUNT);
      },
      onError: () => {
        openNotification("Failed to get Hide Bluemoon Property.");
      },
    }
  );
  return { hideBluemoonProperty };
};

export const useGetBluemoonProperties = (params: BluemoonPropertiesRequest) => {
  const { data, isLoading, refetch, isFetching } = useQuery(
    [BLUEMOON_PROPERTIES],
    () => api.getBluemoonProperties(params),
    {
      onError: () => {
        openNotification(
          "Failed to get Bluemoon Properties.",
          NOTIFICATIONS.error
        );
      },
    }
  );

  return {
    bluemoonPropertiesData: data,
    isBluemoonPropertiesLoading: isLoading,
    refetchBluemoonProperties: refetch,
    isBluemoonPropertiesFetching: isFetching,
  };
};

export const useGetBluemoonHiddenProperties = (
  params: BluemoonHiddenPropertiesRequest
) => {
  const { data, isLoading, refetch, isFetching } = useQuery(
    [BLUEMOON_HIDDEN_PROPERTIES],
    () => api.getBluemoonHiddenProperties(params),
    {
      onError: () => {
        openNotification(
          "Failed to get hidden Bluemoon Properties.",
          NOTIFICATIONS.error
        );
      },
    }
  );
  return {
    bluemoonHiddenPropertiesData: data,
    isBluemoonHiddenPropertiesLoading: isLoading,
    refetchBluemoonHiddenProperties: refetch,
    isBluemoonHiddenPropertiesFetching: isFetching,
  };
};

export const useUnhideBluemoonProperties = () => {
  const [unhideBluemoonProperties, { isLoading }] = useMutation(
    api.unhideBluemoonProperties,
    {
      onSuccess: () => {
        openNotification(
          "Bluemoon Property successfully unhidden.",
          NOTIFICATIONS.info
        );
        queryCache.invalidateQueries(BLUEMOON_PROPERTIES);
        queryCache.invalidateQueries(BLUEMOON_HIDDEN_PROPERTIES);
        queryCache.invalidateQueries(BLUEMOON_PROPERTIES_COUNT);
      },
      onError: () =>
        openNotification("Failed to unhide properties.", NOTIFICATIONS.error),
    }
  );
  return {
    unhideBluemoonProperties,
    isUnhideBluemoonPropertiesLoading: isLoading,
  };
};

export const useUnbindBluemoonProperties = () => {
  const [unbindBluemoonProperties, { isLoading }] = useMutation(
    api.unbindBluemoonProperties,
    {
      onSuccess: () => {
        openNotification(
          "Properties are successfully unbind.",
          NOTIFICATIONS.info
        );
        queryCache.invalidateQueries(VERO_PROPERTIES);
        queryCache.invalidateQueries(BLUEMOON_PROPERTIES);
        queryCache.invalidateQueries(BLUEMOON_PROPERTIES_COUNT);
      },
      onError: () =>
        openNotification("Failed to unbind properties.", NOTIFICATIONS.error),
    }
  );
  return {
    unbindBluemoonProperties,
    isUnbindBluemoonPropertiesLoading: isLoading,
  };
};

export const useBindBluemoonProperties = () => {
  const [bindBluemoonProperties, { isLoading }] = useMutation(
    (payload: BindPropertiesRequest) => api.bindBluemoonProperties(payload),
    {
      onSuccess: () =>
        openNotification("Properties successfully bound.", NOTIFICATIONS.info),
      onError: () =>
        openNotification(
          "An error occured, properties could not be bound, please try again.",
          NOTIFICATIONS.error
        ),
    }
  );
  return {
    bindBluemoonProperties,
    isBindBluemoonPropertiesLoading: isLoading,
  };
};

export const useGetBluemoonDashboardData = () => {
  const { data } = useQuery(
    [BLUEMOON_DASHBOARD_DATA],
    api.getBluemoonDashboardData,
    {
      onError: () =>
        openNotification(
          "Failed to get Bluemoon dashboard data.",
          NOTIFICATIONS.error
        ),
    }
  );
  return {
    bluemoonDashboardData: data,
  };
};
