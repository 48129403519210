import _ from 'lodash'
import React from 'react'
import { Checkbox } from 'antd'
import Engine from '@vero/expression-core-node/engine/engine.js'

const engine = new Engine(true)

const BasicEditor = ({ formula, canEdit = false, onChange }) => {
  const possibleExpressions = [
    { value: 'person.email', label: 'Email', disabled: true },
    { value: 'applicant.party', label: 'Applicant Party', disabled: true },
    { value: 'vrn:expression::default/terms-and-conditions', label: 'Terms & Conditions' },
    { value: 'vrn:expression::default/identity', label: 'Identity Verification' },
    { value: 'vrn:expression::default/basic-info', label: 'Basic Info', disabled: true },
    { value: 'vrn:expression::default/income', label: 'Income Verification' },
    { value: 'vrn:expression::default/income-with-financial-documents', label: 'Income Verification With Financial Documents' },
    { value: 'vrn:expression::default/asset', label: 'Asset Verification' },
    { value: 'vrn:expression::default/employment', label: 'Employment Verification' },
    { value: 'vrn:expression::default/employment-with-financial-documents', label: 'Employment Verification With Financial Documents' },
    { value: 'vrn:expression::default/doc', label: 'Legacy Documents' },
    { value: 'vrn:expression::default/financial-documents', label: 'Financial Documents' },
    { value: 'vrn:expression::default/unverified-income-documents', label: 'Unverified Income Documents' },
    { value: 'vrn:expression::default/address', label: 'Residential History' },
    { value: 'vrn:expression::default/transunion', label: 'Transunion' },
    { value: 'vrn:expression::default/experian', label: 'Experian' },
    { value: 'vrn:expression::default/conditional-proceed', label: 'Conditional Proceed' },
    { value: 'vrn:expression::default/lease-preferences', label: 'Lease Preferences' },
    { value: 'vrn:expression::default/pet', label: 'Pets' },
    { value: 'vrn:expression::default/vehicle', label: 'Vehicles' },
    { value: 'vrn:expression::default/questionnaire', label: 'Questionnaire' },
    { value: 'vrn:expression::default/contact', label: 'Contacts' },
    { value: 'vrn:expression::default/reference', label: 'References' },
    { value: 'vrn:expression::default/required-documents', label: 'Required Documents' },
    { value: 'vrn:expression::default/unverified-background-documents', label: 'Unverified Background Documents' },
    { value: 'vrn:expression::default/payment', label: 'Payment' },
    { value: 'vrn:expression::default/submit', label: 'Submit', disabled: true }
  ]

  const enabledExpressions = _.chain(formula).split('\n').compact().join(' ').split(' && ').compact().map(s => _.trim(s, '()& ')).value()
  const unsupportedExpressions = _.chain(enabledExpressions).difference(_.map(possibleExpressions, 'value')).uniq().value()

  return (
    <div className='vega content flex column basic-editor'>
      {!_.isEmpty(unsupportedExpressions) && <div className='vega content info warn'>
        <p className='vega'>This formula contains unsupported expressions for the basic editor to function. Please use the raw formula editor.</p>
        <ul>
          {_.map(
            unsupportedExpressions,
            expression => (
              <li key={expression}>
                <code className='vega'>{expression}</code>
              </li>
            )
          )}
        </ul>
      </div>}

      <Checkbox.Group
        className='vega flex column'
        options={possibleExpressions}
        value={enabledExpressions}
        onChange={(expressions = []) => onChange(engine.format(_.join(expressions, ' && ')))}
        disabled={!canEdit || !_.isEmpty(unsupportedExpressions)}
      />
    </div>
  )
}

export default BasicEditor
