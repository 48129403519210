import { useState } from "react";

import { interval, take } from "rxjs";

import { IConfettiContext } from "./context";

export const useConfetti = () => {
  const [show, setShow] = useState(false);
  const explode = () => {
    if (show) return;
    setShow(true);
    interval(10000)
      .pipe(take(1))
      .subscribe(() => setShow(false));
  };
  return {
    show,
    context: <IConfettiContext>{ explode },
  };
};
