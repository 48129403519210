import React from 'react'
import _ from 'lodash'
import { Timestamp } from 'shared/components/vega/Timestamp'

const LumenSettingValue = ({ value, name }) => {
  const resolvedValue = _.isPlainObject(value) ? JSON.stringify(value) : (_.isArray(value) ? _.join(value, ', ') : _.toString(value))

  return (
    <div>
      {name && <span className='vega label'>{_.startCase(name)}:</span>}
      <span>{resolvedValue}</span>
    </div>
  )
}

const LumenFilterCondition = ({ condition }) => {
  return (
    <li>
      <div className='vega name'>{condition?.conditionType}</div>
      {_.chain(condition).omit(['conditionType']).map((v, k) => <LumenSettingValue value={v} name={k} key={k} />).value()}
    </li>
  )
}

const LumenFilterConditions = ({ conditions }) => {
  return (
    <ul>
      {_.map(conditions, (condition, i) => <LumenFilterCondition condition={condition} key={i} />)}
    </ul>
  )
}

const LumenFilter = ({ filter }) => {
  return (
    <div>
      <span className='vega title small'>{filter?.type}</span>
      <span className='vega badge'>{filter?.mode}</span>

      <LumenFilterConditions conditions={filter?.conditions} />
    </div>
  )
}

const LumenFilters = ({ filters, name }) => {
  return (
    <div className='vega flex gap small column'>
      <div className='vega title'>{_.startCase(name)}</div>
      {_.map(filters, filter => <LumenFilter filter={filter} key={filter?.id} />)}
    </div>
  )
}

const LumenSetting = ({ formulaRevision, name }) => {
  const value = _.get(formulaRevision, name)
  const isLumenFilters = _.isArray(value)

  return (
    <div>{isLumenFilters ? <LumenFilters filters={value} name={name} /> : <LumenSettingValue value={value} name={name} />}</div>
  )
}

const LumenSettings = ({ title, children }) => {
  return (
    <div className='vega flex no-gap column'>
      <div className='vega title large'>{_.startCase(title)}</div>
      {children}
    </div>
  )
}

const FormulaHeader = ({ formula }) => {
  return (
    <header className='vega flex'>
      <div className='vega title'>
        <div>
          <span>{formula?.name}</span>
          <span className='vega badge'>{formula?.status}</span>
        </div>

        <div className='vega sub-title'>{formula?.createdBy}</div>
      </div>

      {formula?.updatedAt && <Timestamp timestamp={formula?.updatedAt} />}
    </header>
  )
}

const LumenV1Reader = ({ lumenFormulaRevision }) => {
  return (
    <div className='vega flex column'>
      {lumenFormulaRevision?.useIncomeRentRatio && <LumenSettings title='income'>
        <LumenSetting formulaRevision={lumenFormulaRevision} name='incomeConsidered' />
        <LumenSetting formulaRevision={lumenFormulaRevision} name='incomeRentRatioPeriod' />
        <LumenSetting formulaRevision={lumenFormulaRevision} name='minIncomeRentRatio' />
        <LumenSetting formulaRevision={lumenFormulaRevision} name='idealIncomeRentRatio' />
        <LumenSetting formulaRevision={lumenFormulaRevision} name='minIncomeRentRatioWithGuarantor' />
        <LumenSetting formulaRevision={lumenFormulaRevision} name='idealIncomeRentRatioWithGuarantor' />
        <LumenSetting formulaRevision={lumenFormulaRevision} name='incomeRentRatioWithGuarantors' />
        <LumenSetting formulaRevision={lumenFormulaRevision} name='totalIncomeWithGuarantor' />
      </LumenSettings>}

      {lumenFormulaRevision?.useAssets && <LumenSettings title='assets'>
        <LumenSetting formulaRevision={lumenFormulaRevision} name='minAssetsRatio' />
        <LumenSetting formulaRevision={lumenFormulaRevision} name='idealAssetsRatio' />
        <LumenSetting formulaRevision={lumenFormulaRevision} name='minCashRunwayRatioWithGuarantor' />
        <LumenSetting formulaRevision={lumenFormulaRevision} name='idealCashRunwayRatioWithGuarantor' />
        <LumenSetting formulaRevision={lumenFormulaRevision} name='totalAssetsWithGuarantor' />
      </LumenSettings>}

      {lumenFormulaRevision && <LumenSettings title='verifications'>
        <LumenSetting formulaRevision={lumenFormulaRevision} name='verifyIdentity' />
      </LumenSettings>}

      {lumenFormulaRevision?.useCreditScore && <LumenSettings title='credit'>
        <LumenSetting formulaRevision={lumenFormulaRevision} name='minCreditScore' />
        <LumenSetting formulaRevision={lumenFormulaRevision} name='idealCreditScore' />
        <LumenSetting formulaRevision={lumenFormulaRevision} name='creditScoreScopeWithGuarantors' />
        <LumenSetting formulaRevision={lumenFormulaRevision} name='creditRecordsScope' />

        {lumenFormulaRevision?.filterCreditPublicRecords && <LumenSetting formulaRevision={lumenFormulaRevision} name='filterCreditPublicRecords' />}
        {lumenFormulaRevision?.filterCreditPublicRecords && <LumenSetting formulaRevision={lumenFormulaRevision} name='useCreditRecordSafetyNet' />}
      </LumenSettings>}

      {lumenFormulaRevision?.useCollections && <LumenSettings title='collections'>
        <LumenSetting formulaRevision={lumenFormulaRevision} name='maxCollectionsAmount' />

        {lumenFormulaRevision?.filterCollectionRecords && <LumenSetting formulaRevision={lumenFormulaRevision} name='filterCollectionRecords' />}
        {lumenFormulaRevision?.filterCollectionRecords && <LumenSetting formulaRevision={lumenFormulaRevision} name='useCollectionRecordSafetyNet' />}
      </LumenSettings>}

      {lumenFormulaRevision?.useCriminalRecords && <LumenSettings title='criminal records'>
        {lumenFormulaRevision?.filterCriminalRecords && <LumenSetting formulaRevision={lumenFormulaRevision} name='filterCriminalRecords' />}
        {lumenFormulaRevision?.filterCriminalRecords && <LumenSetting formulaRevision={lumenFormulaRevision} name='useCriminalRecordSafetyNet' />}
      </LumenSettings>}

      {lumenFormulaRevision?.useHousingCourtRecords && <LumenSettings title='eviction records'>
        {lumenFormulaRevision?.filterHousingCourtRecords && <LumenSetting formulaRevision={lumenFormulaRevision} name='filterHousingCourtRecords' />}
        {lumenFormulaRevision?.filterHousingCourtRecords && <LumenSetting formulaRevision={lumenFormulaRevision} name='useHousingCourtRecordSafetyNet' />}
      </LumenSettings>}
    </div>
  )
}

export default LumenV1Reader
