import styled from "@emotion/styled";

import Tile from "shared/components/Tile";

const StyledTile = styled(Tile)`
  margin-bottom: 0px;

  h2 {
    font-size: 17px;
  }
`;

export { StyledTile };
