import React from "react";

import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { Card } from "antd";

import { MEDIA_QUERIES } from "shared/config/constants";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const CardContent = styled(({ width, ...props }) => <Card {...props} />)`
  .ant-card-body {
    padding: 0;
    zoom: 0;
  }

  padding: 48px;
  ${(props) =>
    css`
      width: ${props.width || "386px"};
    `};

  ${MEDIA_QUERIES.w.ltMd} {
    width: 85vw;
    padding: 48px 30px;
  }
`;
