import React from "react";

import { Row } from "antd";
import { Formik, Form } from "formik";

import { RadioGroup } from "../../../shared/components/Form";
import { ExperianKiq } from "../../interfaces/api/experianAuth";

import { AuthFormContainer, QuestionContainer } from "./styled";

interface ExperianQuestionsAuthProps {
  formikRef: any;
  onSubmit: (params: Record<string, number>) => void;
  questionsList: ExperianKiq[];
}

const ExperianQuestionsForm = ({
  formikRef,
  onSubmit,
  questionsList,
}: ExperianQuestionsAuthProps) => {
  const initialValues = questionsList.reduce((obj, _, index) => {
    obj[`answer-${index + 1}`] = 1;
    return obj;
  }, {});

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      innerRef={formikRef}
      onSubmit={onSubmit}
    >
      <Form data-testid="application-setup-form">
        <AuthFormContainer noPadding expand rowGap>
          {questionsList.map((question, index) => {
            const answerNum = index + 1;
            return (
              <Row key={question.questionType}>
                <QuestionContainer>
                  <RadioGroup
                    label={`${answerNum}. ${question.questionText}`}
                    required
                    id={`answer-${answerNum}`}
                    name={`answer-${answerNum}`}
                    options={question.questionSelect.questionChoice.map(
                      (choice, idx) => {
                        const optionNum = idx + 1;
                        return {
                          key: idx,
                          label: choice,
                          value: optionNum,
                        };
                      }
                    )}
                  />
                </QuestionContainer>
              </Row>
            );
          })}
        </AuthFormContainer>
      </Form>
    </Formik>
  );
};

export default ExperianQuestionsForm;
