import { gteQueryW } from "shared/config/constants";

// TODO(V2-3087): Replace the BACKGROUND_MEDIA_QUERIES with MEDIA_QUERIES where used.
const BACKGROUND_BREAKPOINTS = {
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1440,
};

const BACKGROUND_MEDIA_QUERIES = {
  w: {
    gteMd: gteQueryW(BACKGROUND_BREAKPOINTS.md),
    gteLg: gteQueryW(BACKGROUND_BREAKPOINTS.lg),
    gteXl: gteQueryW(BACKGROUND_BREAKPOINTS.xl),
    gteXxl: gteQueryW(BACKGROUND_BREAKPOINTS.xxl),
  },
};

export { BACKGROUND_MEDIA_QUERIES };
