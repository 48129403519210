import React, { useEffect, useRef } from "react";

import {
  useGetYardiAttachmentTypes,
  useGetYardiCredentials,
  useYardiResolveLinkAttachmentTypes,
} from "manager/hooks/api";
import Icon from "shared/components/Icon";
import useModal from "shared/hooks/useModal";
import { resetFormAndValidate } from "shared/utils/forms";

import { DOCUMENT_TYPES } from "../shared";

import YardiGenericListDropdownsResolverModal from "./YardiGenericListDropdownsResolverModal";

const YardiLinkDocumentTypesResolverModalContainer = () => {
  const { currentModalContext } = useModal();
  const { alert, provider } = currentModalContext;
  const { yardiAttachmentTypes, isLoading: isYardiAttachmentTypesLoading } =
    useGetYardiAttachmentTypes(provider);
  const { yardiCredentials, isLoading: isYardiCredentialsLoading } =
    useGetYardiCredentials(provider);

  const { resolveAlertLinkAttachmentTypes } =
    useYardiResolveLinkAttachmentTypes();
  const formikRef = useRef();

  const submit = async (values) => {
    const attachmentTypes = {};
    (values.objects || []).forEach(({ id, linkedId }) => {
      attachmentTypes[id] = linkedId;
    });
    const payload = {
      attachmentTypes,
    };
    await resolveAlertLinkAttachmentTypes({
      provider,
      alertId: alert?.id,
      body: payload,
    });
  };

  useEffect(() => {
    if (formikRef.current && yardiCredentials) {
      const documentTypesFormList = (DOCUMENT_TYPES || [])
        .filter((documentType) => !yardiCredentials[documentType.id])
        .map(({ id, label }) => ({
          id,
          inputDescription: label,
          linkedId: null,
        }));

      const values = { objects: documentTypesFormList };
      resetFormAndValidate(formikRef, { values });
    }
  }, [formikRef.current, yardiCredentials]);

  const isLoading = isYardiCredentialsLoading || isYardiAttachmentTypesLoading;

  return (
    <YardiGenericListDropdownsResolverModal
      title="Link VERO document types with External Provider"
      message="Please select new document types for the following VERO document types"
      selectPlaceholder="Select document type "
      items={yardiAttachmentTypes}
      labelProp="value"
      submit={submit}
      formikRef={formikRef}
      isLoading={isLoading}
      PlaceholderIcon={Icon.NoDataIcon}
      placeholderText="Document types"
      validationMessage="Document type is required"
    />
  );
};

export default YardiLinkDocumentTypesResolverModalContainer;
