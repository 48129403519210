import React from "react";

import styled from "@emotion/styled";

import {
  Search as BaseSearch,
  Select as BaseSelect,
  Multiselect,
} from "shared/components/Form";
import { COLORS, TRANSITIONS, MEDIA_QUERIES } from "shared/config/constants";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Container = styled(({ alt, justifyContent, ...props }) => (
  <div {...props} />
))((props) => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: props.justifyContent || "space-between",
  padding: props.padding || "10px 24px",
  backgroundColor: `${COLORS.filterBar.default.background}`,
  borderBottom: `1px solid ${COLORS.borders}`,
  fontSize: "15px",
  ...(props.alt === true && {
    backgroundColor: `${COLORS.filterBar.alt.background}`,
  }),
  ...(props.noBorderBottom && {
    borderBottom: "none",
  }),
  "& > div": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    margin: "4px 0",
  },

  [MEDIA_QUERIES.w.ltSm]: {
    ...(!props.displayOnMobile && {
      display: "none",
    }),
  },
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FilterItem = styled(({ order, noShrink, grow, ...props }) => (
  <div {...props} />
))((props) => ({
  display: "flex",
  alignItems: "center",
  marginRight: "16px",
  "&.ant-input-search .ant-input-affix-wrapper": {
    border: "none",
    outline: "none",
  },
  ...(props.order && {
    order: props.order,
  }),
  ...(props.noShrink === true && {
    flexShrink: 0,
  }),
  ...(props.grow === true && {
    flexGrow: 1,
    justifyContent: "flex-end !important",
  }),
}));

const Text = styled("span")({
  lineHeight: 2,
  marginLeft: "5px",
});

const Search = styled(BaseSearch)({});

const Select = styled(BaseSelect)<{ minWidth?: string }>(({ minWidth }) => ({
  minWidth: minWidth || "130px",
}));

const StyledMultiselect = styled(Multiselect)<{ minWidth?: string }>`
  min-width: ${({ minWidth }) => minWidth || "225px"};
  display: block;
`;

const SortOrder = styled("span")({
  "&, path": {
    transition: TRANSITIONS.default,
  },
  "&:hover": {
    "&, path": {
      color: COLORS.sortOrder.hover,
      stroke: COLORS.sortOrder.hover,
    },
  },
});

const DatePickerSpacer = styled("div")({
  margin: "0 7px",
});

export {
  Container,
  FilterItem,
  Text,
  Search,
  Select,
  StyledMultiselect,
  SortOrder,
  DatePickerSpacer,
};
