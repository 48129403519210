
import { Formik } from "formik";

import { Button } from "shared/components/Button";
import FlexContainer from "shared/components/FlexContainer";
import { MaskedInput } from "shared/components/Form";
import { UnderlineLink } from "shared/components/Links";

import Spacer from "shared/components/Spacer";
import {
  LargeTitle,
  RegularText,
  SmallText,
} from "shared/components/Typography";
import { VeroModal } from "shared/components/vero-modal";

import { printDollars } from "shared/utils/dollar-print";
import { initialValues, validationSchema } from "./config";
import { useEditIncomeModal } from "./context";
import { Well } from "./styled";

interface EditIncomeModalProps {
  income: number;
  statedIncome: number;
  applicantName: string;
  applicantId: string | number;
}

export const EditIncomeModal = ({
  income,
  statedIncome,
  applicantName,
  applicantId,
}: EditIncomeModalProps) => {
  const state = useEditIncomeModal();
  const { ok$, cancel$ } = state;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={({ formIncome }) =>
        ok$.next({ income: formIncome / 100, applicantId: String(applicantId) })
      }
    >
      {({ submitForm }) => (
        <VeroModal {...state}>
          <LargeTitle>Edit Income</LargeTitle>
          <RegularText weak>
            Enter a new amount in the box below to override the current VEROfied
            Income
          </RegularText>

          <Well noBorder>
            <SmallText className="applicant-name" extraStrong>
              {applicantName.toLocaleUpperCase()}
            </SmallText>
            <RegularText className="income">
              VEROfied Income: <b>{printDollars(income)}</b>
            </RegularText>
            <RegularText className="income">
              Stated Income: <b>{printDollars(statedIncome)}</b>
            </RegularText>
          </Well>

          {/* @ts-ignore */}
          <MaskedInput.Formik.Money
            id="formIncome"
            name="formIncome"
            label="Enter new income amount"
          />

          <Spacer size={Spacer.SIZES.md} />
          <FlexContainer
            justifyContent="flex-end"
            gap="24px"
            alignItems="center"
          >
            <UnderlineLink onClick={() => cancel$.next()}>Cancel</UnderlineLink>
            <Button type="primary" onClick={submitForm}>
              Save
            </Button>
          </FlexContainer>
        </VeroModal>
      )}
    </Formik>
  );
};
