import { LUMEN_FORMULA_STATUS } from "manager/config/lumen.config";
import { sanitizeStringSearchValue } from "shared/utils/string";

import { LumenFormulaListItem } from "./interfaces";

export const LUMEN_FORMULA_FILTER = Object.freeze({
  ALL: "all",
  DRAFT: LUMEN_FORMULA_STATUS.DRAFT,
  ACTIVE: LUMEN_FORMULA_STATUS.COMPLETED,
});

export const getUsedFormulaNames = (
  formulas: LumenFormulaListItem[],
  formula?: LumenFormulaListItem
) =>
  formulas
    .filter((formulaId) => formulaId.id !== formula?.id)
    .map((sourceIt) => sanitizeStringSearchValue(sourceIt.name));

export const getUsedTemplateNames = (templates) =>
  templates.map((template) => sanitizeStringSearchValue(template.name));
