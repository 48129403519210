import React, { HTMLAttributes } from "react";

import Icon from "shared/components/Icon";

import { Container } from "./styled";

export type ZoomControlProps = HTMLAttributes<HTMLDivElement> & {
  onZoomIn: (zoom: number) => void;
  onZoomOut: (zoom: number) => void;
  zoomInDisabled?: boolean;
  zoomOutDisabled?: boolean;
};

export const ZoomControls = ({
  onZoomIn,
  onZoomOut,
  zoomInDisabled = false,
  zoomOutDisabled = false,
  ...other
}: ZoomControlProps) => (
  <Container {...other}>
    <Icon.ZoomPlusIcon
      disabled={zoomInDisabled}
      onClick={zoomInDisabled ? undefined : onZoomIn}
      data-testid="zoom-in"
    />
    <Icon.ZoomMinusIcon
      disabled={zoomOutDisabled}
      onClick={zoomOutDisabled ? undefined : onZoomOut}
      data-testid="zoom-out"
    />
  </Container>
);
