import React, { useEffect } from "react";

import { useHistory } from "react-router-dom";

import { DOWNLOAD_STATUSES } from "manager/config/constants";
import { useGetDownloads } from "manager/hooks/api";

import DownloadsTable from "./DownloadsTable";

const DownloadsTableContainer = () => {
  const TIMEOUT = 5000;
  const history = useHistory();
  const onError = () => history.goBack();
  const {
    data: downloads,
    isLoading: isDownloadsLoading,
    refetch,
  } = useGetDownloads({
    onError,
  });

  const isGenerating = downloads?.find(
    (download) => download.status === DOWNLOAD_STATUSES.inProgress
  );

  useEffect(() => {
    const refresher = setInterval(() => {
      if (isGenerating) {
        refetch();
      }
    }, TIMEOUT);
    return () => clearInterval(refresher);
  }, [isGenerating]);

  return (
    <DownloadsTable
      downloads={downloads}
      isDownloadsLoading={isDownloadsLoading}
    />
  );
};

export default DownloadsTableContainer;
