import React from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Typography } from "antd";

import { UnderlineLink } from "shared/components/Links";
import { MEDIA_QUERIES, COLORS } from "shared/config/constants";

const { Title: BaseTitle } = Typography;

const Title = styled((props) => <BaseTitle level={1} {...props} />)`
  margin-bottom: 0 !important;
  ${MEDIA_QUERIES.w.ltSm} {
    width: 100%;
  }
  ${(props) =>
    props.rightAlign &&
    css`
      text-align: left;
      margin-left: 80%;
    `}
  ${(props) =>
    props.documentStyle &&
    css`
      border-left: 4px solid ${COLORS.tabs.inkBar};
      border-top: 1px solid ${COLORS.borders};
      padding: 24px;
      width: 100%;
      background-color: ${COLORS.header.background};
      &:not(:first-of-type) {
        page-break-before: always !important;
      }
    `}
`;

const Subtitle = styled("h3")<{ hideOnMobile?: boolean }>`
  font-weight: 300;
  font-size: 15px;
  margin-top: 12px !important;
  margin-bottom: 0;
  ${MEDIA_QUERIES.w.ltSm} {
    display: ${(props) => (props.hideOnMobile === true ? "none" : "")};
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TitleContainer = styled(({ center, justifyLeft, ...props }) => (
  <div {...props} />
))`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 32px;
  ${MEDIA_QUERIES.w.ltMd} {
    align-items: baseline;
  }
  ${(props) =>
    props.center &&
    css`
      align-items: center;
    `}
  ${(props) =>
    props.justifyLeft &&
    css`
      justify-content: left;
    `}
  ${MEDIA_QUERIES.w.ltSm} {
    margin-bottom: 16px;
  }
`;

const Actions = styled("div")<{
  rightAlign?: boolean;
  mobileRightAlign?: boolean;
  onlyMobileActions?: boolean;
}>`
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: center;
  ${(props) =>
    props.rightAlign &&
    css`
      justify-content: flex-end;
    `}
  ${MEDIA_QUERIES.w.ltMd} {
    ${(props) =>
      !props.mobileRightAlign &&
      css`
        width: 100%;
        margin-top: 20px;
      `}
    ${(props) =>
      props.mobileRightAlign &&
      css`
        flex-direction: row-reverse;
      `}
  }
  ${MEDIA_QUERIES.w.gteSm} {
    ${(props) =>
      props.onlyMobileActions &&
      css`
        display: none;
      `}
  }
`;

const Action = styled("span")`
  margin-left: 20px;
  ${MEDIA_QUERIES.w.ltMd} {
    margin-left: 0;
    margin-right: 10px;
  }
`;

const Icon = styled("div")`
  display: inline;
  vertical-align: middle;
  margin-right: 16px;
`;

const RenameLink = styled(UnderlineLink)`
  font-size: 12px;
  margin-left: 6px;
`;

export { Actions, Action, Title, TitleContainer, Subtitle, Icon, RenameLink };
