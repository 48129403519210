import React, { ElementType, ReactNode } from 'react'

import _ from 'lodash'

import ApplicationGroupIndividualsModal from 'renter/components/Modal/ApplicationGroupIndividuals'
import { ARGYLE_SYNC_STATUS } from 'renter/config/constants'
import {
  useGetApplicationConditionalRequest,
  useGetDocuments,
  useGetPerson,
} from 'renter/hooks/api'
import useModal from 'shared/hooks/useModal'

import { APPLICANT_TYPES } from '../../../shared/config/constants'
import AddGuarantorsModalContainer from '../Modal/InviteApplicants/AddGuarantorsModal'

import SummaryCard from './SummaryCard'

interface SummaryCardContainerProps {
  applicationId: string | number
  action?: {
    visible?: boolean
    label: string
    onClick: () => void
    disabled?: boolean
  }
  otherApplicants: any[]
  Details?: ElementType
  AdditionalDescription?: ElementType
  FooterActions?: ElementType
  additionalInfoMessage?: string
  fallbackContent?: string
  ActionAdditionalView?: ElementType
  propertyName: string
  unitName?: string
  propertyAddress: string
  extraFooterContent?: ReactNode
  companyId?: number
  companySlug?: string
  dealId?: number
  companyName?: string
  status?: number
  ingestionFlowEnabled?: boolean
}

const SummaryCardContainer = (props: SummaryCardContainerProps) => {
  const {
    applicationId,
    propertyName,
    unitName,
    dealId,
    companyName,
    action,
    otherApplicants,
  } = props
  const { openModalDialog } = useModal()

  const { applicationConditionalRequestData } =
    useGetApplicationConditionalRequest({ applicationId })

  const individualsGroup = () =>
    openModalDialog(ApplicationGroupIndividualsModal, { applicationId })

  const { personData } = useGetPerson(applicationId)
  const personId = _.get(personData, 'id', '')

  const { documents } = useGetDocuments(personId, !_.isEmpty(personId))

  const hasPendingDocumentAnalysis = _.some(documents, [
    'hasPendingAnalysis',
    true,
  ])

  const syncStatus = hasPendingDocumentAnalysis
    ? ARGYLE_SYNC_STATUS.OCR_PROCESSING
    : ''

  const addGuarantor = () =>
    openModalDialog(AddGuarantorsModalContainer, {
      property: propertyName,
      unit: unitName,
      dealId,
      companyName,
    })

  const isMissingGuarantor =
    applicationConditionalRequestData?.requiresAdditionalGuarantor &&
    !otherApplicants?.some(
      ({ applicantType }) => +applicantType === APPLICANT_TYPES.guarantor,
    )

  return (
    <SummaryCard
      {...props}
      individualsGroup={individualsGroup}
      argyleSyncStatus={syncStatus}
      action={
        action && {
          ...action,
          onClick: isMissingGuarantor ? addGuarantor : action.onClick,
          label: isMissingGuarantor ? 'Add Guarantor' : action.label,
        }
      }
    />
  )
}

export default SummaryCardContainer
