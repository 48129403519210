import React from "react";

import { List, Item } from "./styled";

interface ErrorListProps {
  errors: string | string[] | any;
  noMarginBottom?: boolean;
  [key: string]: any;
}

const ErrorList = ({ errors, ...props }: ErrorListProps) => (
  <List {...props}>
    {errors.map((error) => (
      <Item key={error}>{error}</Item>
    ))}
  </List>
);

export default ErrorList;
