import React from "react";

import { useGetExperianAuthTokenByOtp } from "manager/hooks/api/experianAuthQueries";
import {
  ExperianAuthOtpParams,
  ExperianAuthResponse,
  ExperianAuthResponseError,
  ExperianKiq,
} from "manager/interfaces/api/experianAuth";
import useModal from "shared/hooks/useModal";
import { UserProfile } from "shared/interfaces/api";

import { formatDate } from "shared/utils/ui";

import OtpVerificationModal from "./OtpVerificationModal";

interface OtpVerificationModalProps {
  submittedUser: UserProfile;
  experianResponse: ExperianAuthResponse;
  renderSuccessModal: (validPeriod: string) => void;
  renderFailureModal: () => void;
  renderErrorModal: () => void;
  setSessionToken: (authSession: string) => void;
  setKiqList: (kiqQuestions: ExperianKiq[]) => void;
}

const OtpVerificationModalContainer = ({
  submittedUser,
  experianResponse,
  renderSuccessModal,
  renderFailureModal,
  renderErrorModal,
  setSessionToken,
  setKiqList,
}: OtpVerificationModalProps) => {
  const { requestExperianAuthTokenByOtp } = useGetExperianAuthTokenByOtp();
  const { selfApi } = useModal();

  const handleSubmit = async (
    values: ExperianAuthOtpParams,
    { setSubmitting }
  ) => {
    setSubmitting(true);
    await requestExperianAuthTokenByOtp(values, {
      onSuccess: (data) => {
        const experianResponse = data.experian;
        if (experianResponse.userAuthenticated) {
          renderSuccessModal(formatDate(experianResponse.expiration));
        }

        if (
          !experianResponse.userAuthenticated &&
          experianResponse.authSession &&
          experianResponse.kiqEnabled
        ) {
          setSessionToken(experianResponse.authSession);
          setKiqList(experianResponse.questionSet);
          selfApi.closeDialog();
        }
      },
      onError: (error: ExperianAuthResponseError) => {
        if (error.statusCode === 400) {
          renderFailureModal();
        } else {
          renderErrorModal();
        }
      },
    });
    setSubmitting(false);
  };

  return (
    <OtpVerificationModal
      title="Verification"
      submittedUser={submittedUser}
      experianResponse={experianResponse}
      submit={handleSubmit}
    />
  );
};

export default OtpVerificationModalContainer;
