import React from "react";

import useModal from "shared/hooks/useModal";

import { FIELD_DATA_TYPES, TOOLTIP_MESSAGES } from "./constants";
import EnumFieldDescriptionModal from "./EnumFieldDescriptionModal";
import FieldDescriptionModal from "./FieldDescriptionModal";

const FieldDescriptionModalContainer = () => {
  const { currentModalContext } = useModal();
  const { fieldType, fieldsRow, submit } = currentModalContext;
  function getFeldTypeByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  const getTooltipMessageForFieldType =
    TOOLTIP_MESSAGES[getFeldTypeByValue(FIELD_DATA_TYPES, fieldType)];

  const modaldProps = {
    fieldType,
    fieldsRow,
    tooltipMessage: getTooltipMessageForFieldType,
    submit,
  };

  return fieldType === FIELD_DATA_TYPES.ENUM ? (
    <EnumFieldDescriptionModal {...modaldProps} />
  ) : (
    <FieldDescriptionModal {...modaldProps} />
  );
};

export default FieldDescriptionModalContainer;
