import * as Yup from "yup";

// eslint-disable-next-line func-names
Yup.addMethod(Yup.array, "uniqueBy", function (mapper, message) {
  return this.test("uniqueBy", message, (list) => {
    return list.length === new Set(list.map(mapper)).size;
  });
});

Yup.setLocale({
  string: {
    // eslint-disable-next-line no-template-curly-in-string
    max: "Ensure this field has no more than ${max} characters",
  },
});
