import React from "react";

import PieChart from "shared/components/Chart/PieChart";

import { EmptyChart, isChartEmpty } from "../Dashboard.utils";

import { PieChartContainer } from "./styled";

interface DashboardPieChartProps {
  chartData: {
    name: string;
    value: number;
  }[];
}

const DashboardBarChart = ({ chartData }: DashboardPieChartProps) => {
  return (
    <PieChartContainer>
      {isChartEmpty(chartData) ? <EmptyChart /> : <PieChart data={chartData} />}
    </PieChartContainer>
  );
};

export default DashboardBarChart;
