import React from "react";

import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

import { useGetDefaultEmailConfigurations } from "manager/hooks/api";
import { PreventLeaveFormModal } from "shared/components/Form";
import LoaderContainer, {
  BigLoadingSpinner,
} from "shared/components/LoaderContainer";
import Tile from "shared/components/Tile";
import useFormsManager from "shared/hooks/useFormsManager";

import { StyledEmailTemplatesSettings } from "./styled";
import useVerifyEmailTemplatesAccess from "./useVerifyEmailTemplatesAccess";

const EmailTemplatesSettings = ({ showDescription, EmailTemplatesForm }) => {
  const { registerFormChange, isAllValid, isSomeDirty, submitAllForms } =
    useFormsManager();
  const { state } = useLocation();
  /** NOTE: Default email configuration id of newly created template from EmailBuilder page */
  const { newEmailTemplateDefaultConfigId = null, typeName: routeTypeName } =
    state || {};

  const { canCustomizeEmails } = useVerifyEmailTemplatesAccess({
    pageName: "Email Templates",
  });

  const { defaultEmailConfigurations, isDefaultConfigurationsLoading } =
    useGetDefaultEmailConfigurations({}, { enabled: canCustomizeEmails });

  return (
    <LoaderContainer
      loading={isDefaultConfigurationsLoading}
      Loader={<BigLoadingSpinner />}
    >
      <StyledEmailTemplatesSettings>
        <Tile
          header={{
            title: "Email Templates",
            withoutBorderBottom: !showDescription,
          }}
        >
          {showDescription && (
            <Tile.Inner>
              <span>
                Please select the preferred email. To edit the templates or
                adjust the Company Default go to Company Settings.
              </span>
            </Tile.Inner>
          )}
        </Tile>
        {defaultEmailConfigurations?.map((item) => {
          /** NOTE: Display section with newly created email template
           *  or if typeName passed to router state
           */
          const showTileContent =
            newEmailTemplateDefaultConfigId === item.id ||
            item.typeName === routeTypeName;

          return (
            <Tile
              key={item.typeName}
              header={{
                title: item.typeNameDescription,
                subtitle: item.description,
                collapsibleOnDesktop: canCustomizeEmails,
                collapsibleOnMobile: canCustomizeEmails,
                defaultCollapsed: !showTileContent,
              }}
              noBorder={!canCustomizeEmails}
            >
              {canCustomizeEmails && (
                <EmailTemplatesForm
                  defaultConfiguration={item}
                  registerFormChange={registerFormChange}
                />
              )}
            </Tile>
          );
        })}
        <PreventLeaveFormModal
          title={
            isAllValid ? "Save settings changes?" : "Exit Email Templates?"
          }
          subtitle={
            isAllValid
              ? "Would you like to save your changes?"
              : "The current unsaved changes are not valid, if you exit you will lose the progress. Are you sure you want to continue?"
          }
          preventLeaveWhen={isSomeDirty}
          submitForm={() => {
            if (isAllValid) {
              return submitAllForms();
            }

            return Promise.resolve();
          }}
          submitButtonLabel={isAllValid ? "Yes" : "OK"}
          cancelLinkLabel={isAllValid ? "No" : null}
        />
      </StyledEmailTemplatesSettings>
    </LoaderContainer>
  );
};

EmailTemplatesSettings.propTypes = {
  showDescription: PropTypes.bool,
  EmailTemplatesForm: PropTypes.elementType.isRequired,
};

EmailTemplatesSettings.defaultProps = {
  showDescription: false,
};

export default EmailTemplatesSettings;
