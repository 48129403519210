import React from "react";

import { useFormikContext } from "formik";
import get from "lodash/get";

import { showError } from "shared/utils/forms";
import { deferred } from "shared/utils/misc.util";

import { RadioGroup, RadioGroupOptions, RadioGroupProps } from "./RadioGroup";

export type RadioGroupFormikProps<T> = Omit<
  RadioGroupProps<T>,
  "error" | "onChange"
> & {
  onChange?: (value: string | boolean | number) => void;
};

export const RadioGroupFormik = <T extends object = RadioGroupOptions>({
  id,
  label,
  onChange,
  options,
  name,
  block,
  disabled = false,
  className,
  required = false,
  radioOptionRenderer,
}: RadioGroupFormikProps<T>) => {
  const { setFieldValue, setFieldTouched, values, errors, touched } =
    useFormikContext();
  const error = showError(id || name, touched, errors);

  return (
    <RadioGroup
      id={id}
      label={label}
      value={get(values, id || name)}
      name={name}
      onChange={(event) => {
        setFieldValue(name || id, event.target.value, true);
        deferred(() => setFieldTouched(name || id, true, false));

        if (onChange) {
          onChange(event.target.value);
        }
      }}
      disabled={disabled}
      error={error}
      className={className}
      block={block}
      options={options}
      required={required}
      radioOptionRenderer={radioOptionRenderer}
    />
  );
};
