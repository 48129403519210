import React, { useEffect, useMemo, useState } from "react";
import { SorterResult, PaginationConfig } from "antd/lib/table";
import flatten from "lodash/flatten";

import {
  DEAL_CATEGORY_TYPES,
  DEAL_CATEGORIES,
  DEAL_CATEGORY_FILTERS_MAPPING,
} from "manager/config/constants";
import {
  useGetPropertiesWithInvitation,
  useGetDeals,
  useGetDealsCounts,
} from "manager/hooks/api";
import { MenuItem } from "shared/components/TablePageCardHeader/interfaces";
import { DEFAULT_PAGINATION } from "shared/config/constants";
import { useDealStatuses, useDealCategories } from "shared/hooks/api";
import { PaginationProps } from "shared/interfaces";
import { useVeroFlags } from 'shared/utils/launchDarkly'
import { LumenScore } from "../../components/app-summary/action-bar";

import {
  blankFilters,
  combineFilters,
  dealsFiltersStorage,
  getInitialOrderingColumn,
  dealsCountsStorage,
  KEYS,
  ORDER_BY,
} from "./config";
import Deals from "./Deals";
import { DealsCounts, Filters } from "./interfaces";

interface DealsProps {
  experianPrompt?: boolean;
}

const DealsContainer = ({ experianPrompt }: DealsProps) => {
  const { enableSearchService, useSearchService } = useVeroFlags()
  const [showSearchServiceResults, setShowSearchServiceResults] = useState(useSearchService)

  const initialFilters =
    dealsFiltersStorage.getItem(KEYS.filters) || blankFilters;
  const initialCategory =
    dealsFiltersStorage.getItem(KEYS.category) || DEAL_CATEGORY_TYPES.PENDING;
  const initialPagination =
    dealsFiltersStorage.getItem(KEYS.pagination) || DEFAULT_PAGINATION;
  const initialOrdering = dealsFiltersStorage.getItem(KEYS.ordering) || "";
  const initialOrderingColumn = getInitialOrderingColumn(initialOrdering);
  const initialShowArchived =
    dealsFiltersStorage.getItem(KEYS.showArchived) || false;
  const initialShowOnlyPaidFees =
    typeof dealsFiltersStorage.getItem(KEYS.showOnlyPaidFees) === "boolean"
      ? dealsFiltersStorage.getItem(KEYS.showOnlyPaidFees)
      : true;
  const initialDealsCounts =
    dealsCountsStorage.getItem(KEYS.dealsCounts) || DEAL_CATEGORIES;

  const [category, setCategory] = useState<string>(initialCategory);
  const [showArchived, setShowArchived] =
    useState<boolean>(initialShowArchived);
  const [showOnlyPaidFees, setShowOnlyPaidFees] = useState<boolean>(
    initialShowOnlyPaidFees
  );
  const [filters, setFilters] = useState<Filters>(initialFilters);
  const [pagination, setPagination] =
    useState<PaginationProps>(initialPagination);
  const [ordering, setOrdering] = useState(initialOrdering);
  const [dealsFilters, setDealsFilters] = useState(
    combineFilters({
      category,
      filters,
      pagination,
      ordering,
      showArchived,
      showOnlyPaidFees,
    })
  );
  const [isPropertiesSettled, setPropertiesSettled] = useState(false);
  const [menuItems, setMenuItems] = useState<MenuItem[]>(initialDealsCounts);

  const { data: dealCategories, isLoading: isDealCategoriesLoading } =
    useDealCategories();

  const { data: dealStatuses, isLoading: isDealStatusesLoading } =
    useDealStatuses();

  const { properties, isLoading: isPropertiesLoading } =
    useGetPropertiesWithInvitation();

  const isFiltersLoading =
    isPropertiesLoading || isDealStatusesLoading;

  const {
    data: deals,
    isLoading: isDealsLoading,
    refetch: refetchDeals,
    isFetching: isDealsFetching,
  } = useGetDeals(dealsFilters, {
    enabled: !isFiltersLoading && isPropertiesSettled && !showSearchServiceResults,
  });

  const {
    data: dealsCounts,
    isLoading: isDealsCountsLoading,
    isFetching: isDealsCountsFetching,
  } = useGetDealsCounts(dealsFilters, {
    enabled: !isFiltersLoading && isPropertiesSettled && !showSearchServiceResults,
  });

  const updateMenuItemsCounts = (dealsCounts: DealsCounts, dealsFilters) => {
    const { dealStatus, dealCategory } = dealsFilters;

    setMenuItems((prevMenuItems: MenuItem[]): MenuItem[] => {
      let items;

      if (dealStatus && dealCategory) {
        items = prevMenuItems.map(
          (item: MenuItem): MenuItem =>
            item.key === dealCategory
              ? { ...item, count: dealsCounts[dealCategory] }
              : item
        );
      } else {
        items = DEAL_CATEGORIES.map(
          (category): MenuItem => ({
            key: category.key,
            name: category.name,
            count: dealsCounts[category.key],
          })
        );
      }

      dealsCountsStorage.addItem(KEYS.dealsCounts, items);
      return items;
    });
  };

  const updateFilters = (values: Filters) => {
    setFilters(
      values
        ? {
            ...filters,
            ...values,
          }
        : { ...blankFilters }
    );
  };

  const updatedAllFilters = (filterValues) => {
    setFilters({ ...filters, ...filterValues });
    setPagination({ ...DEFAULT_PAGINATION });
  };

  const onTableChange = (
    _tablePagination: PaginationConfig,
    _tableFilters: Filters,
    sorter: SorterResult<Filters>
  ) => {
    const newOrdering = sorter?.order
      ? `${ORDER_BY[sorter.columnKey][sorter.order]}`
      : undefined;
    setOrdering(newOrdering);
  };

  useEffect(() => {
    if (properties) {
      if (
        Number(filters.propertyId) &&
        !properties.some(
          (property) => property.id === Number(filters.propertyId)
        )
      ) {
        const cleanFilters = {
          ...filters,
          propertyId: [],
        };

        setFilters(cleanFilters);
      }

      setPropertiesSettled(true);
    }
  }, [properties]);

  useEffect(() => {
    if (dealsCounts) {
      updateMenuItemsCounts(dealsCounts, dealsFilters);
    }
  }, [dealsCounts]);

  /** NOTE: Whenever we update filters or category,
   * we should reset the pagination to avoid invalid page calls */
  useEffect(() => {
    setPagination({ ...DEFAULT_PAGINATION });
    dealsFiltersStorage.addItem(KEYS.filters, filters);
    dealsFiltersStorage.addItem(KEYS.category, category);
    dealsFiltersStorage.addItem(KEYS.showArchived, showArchived);
    dealsFiltersStorage.addItem(KEYS.showOnlyPaidFees, showOnlyPaidFees);
  }, [category, filters, showArchived, showOnlyPaidFees]);

  useEffect(() => {
    setDealsFilters(
      combineFilters({
        category,
        filters,
        pagination,
        ordering,
        showArchived,
        showOnlyPaidFees,
      })
    );

    dealsFiltersStorage.addItem(KEYS.pagination, pagination);
    dealsFiltersStorage.addItem(KEYS.ordering, ordering);
  }, [pagination, ordering]);

  const dealStatusSelection = useMemo(() => {
    const statuses =
      category === "all" && dealCategories
        ? flatten(Object.keys(dealCategories).map((c) => dealCategories[c]))
        : dealCategories?.[category] || [];

    if (statuses && statuses.length > 0) {
      Object.keys(statuses).forEach((key) => {
        statuses[key].label =
          DEAL_CATEGORY_FILTERS_MAPPING[statuses[key].value.toUpperCase()];
      });
    }
    return (statuses || [])
      .sort((a, b) => a.value.localeCompare(b.value))
      .map(({ id, value, label }) => ({ value, key: id, label }));
  }, [dealCategories, dealStatuses, category]);

  const propertySelection = useMemo(
    () => [
      ...(properties || [])?.map(({ id, name }) => ({
        label: name,
        key: `${id}`,
      })),
      {
        label: "Unassigned",
        key: "0",
      },
    ],
    [properties]
  );

  const lastLumenResultSelection = useMemo(() => {
    const labelToKeysMap = Object.keys(LumenScore).reduce((acc, key) => {
      const label = LumenScore[key as keyof LumenScore].replace("_", " ");
      acc[label] = acc[label] ? [...acc[label], key] : [key];
      return acc;
    }, {});

    return Object.keys(labelToKeysMap).map((label) => ({
      label,
      key: labelToKeysMap[label].join(","),
    }));
  }, []);

  const noFiltersApplied = !Object.keys(filters).some((key) => filters[key]);
  const loading =
    isDealCategoriesLoading ||
    isDealsLoading ||
    isDealsCountsLoading ||
    isDealsCountsFetching ||
    isDealsFetching ||
    isFiltersLoading;

  const changeCategory = (value: string) => {
    setCategory(value);
    updateFilters({
      dealStatus: [],
    });
  };

  return (
    <Deals
      deals={deals?.results}
      dealsCount={deals?.count}
      refetchDeals={refetchDeals}
      updateFilters={updateFilters}
      updatedAllFilters={updatedAllFilters}
      filters={filters}
      pagination={pagination}
      hasInputValue={Boolean(filters.unitPropertyOrApplicant)}
      setPagination={setPagination}
      dealStatuses={dealStatuses}
      dealCategories={dealCategories}
      isFiltersLoading={isFiltersLoading}
      isDealsCountsLoading={isDealsCountsLoading}
      loading={loading}
      onTableChange={onTableChange}
      menuItems={menuItems}
      activeItem={category}
      onShowArchivedCheckboxChange={() => setShowArchived(!showArchived)}
      showArchived={showArchived}
      onShowOnlyPaidFeesCheckboxChange={() =>
        setShowOnlyPaidFees(!showOnlyPaidFees)
      }
      showOnlyPaidFees={showOnlyPaidFees}
      initialOrderingColumn={initialOrderingColumn}
      disabledSearch={deals?.length === 0 && noFiltersApplied}
      disabledFilters={(deals?.length === 0 && noFiltersApplied) || loading}
      propertySelection={propertySelection}
      dealStatusSelection={dealStatusSelection}
      lumenResultSelection={lastLumenResultSelection}
      changeCategory={changeCategory}
      experianPrompt={experianPrompt}
      enableSearchService={enableSearchService}
      showSearchServiceResults={showSearchServiceResults}
      setShowSearchServiceResults={setShowSearchServiceResults}
    />
  );
};

export default DealsContainer;
