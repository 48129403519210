import styled from "@emotion/styled";

import { RegularText } from "shared/components/Typography";
import { COLORS } from "shared/config/constants";

const Text = styled(RegularText)`
  margin-bottom: 0;
  color: ${COLORS.primary};
`;

export { Text };
