import { queryCache, useMutation, useQuery } from "react-query";

import { APPLICATION_AUDITS_QUERY } from "manager/config/constants";
import api from "manager/lib/api";
import { NOTIFICATIONS } from "shared/config/constants";
import { openNotification } from "shared/utils/ui";

const APPLICATION_AUDITS_COUNT_QUERY = "getApplicationAuditsCount";

export const useGetApplicationAudits = (dealId) => {
  const { data: applicationAudits, isLoading: isApplicationAuditsLoading } =
    useQuery(
      [APPLICATION_AUDITS_QUERY, dealId],
      () => api.getApplicationAudits(dealId),
      {
        enabled: Boolean(dealId),
        onError: () => {
          openNotification(
            "Failed to get application audits.",
            NOTIFICATIONS.error
          );
        },
      }
    );

  return {
    applicationAudits,
    isApplicationAuditsLoading,
  };
};

export const useCreateApplicationAuditNote = () => {
  const [mutate, status] = useMutation(
    (params) => api.createApplicationAuditNote(params),
    {
      onError: () => {
        openNotification(
          "Failed to create application audit note",
          NOTIFICATIONS.error
        );
      },
      onSuccess: (data, { id }) => {
        queryCache.invalidateQueries([APPLICATION_AUDITS_QUERY, id]);
        queryCache.invalidateQueries([APPLICATION_AUDITS_COUNT_QUERY, id]);
        openNotification(
          "Application audit note created successfully",
          NOTIFICATIONS.info
        );
      },
    }
  );
  return {
    createApplicationAuditNote: mutate,
    isCreateApplicationAuditNoteLoading: status.isLoading,
  };
};

export const useGetApplicationAuditsCount = (dealId) => {
  const {
    data: applicationAuditsCount,
    isLoading: isApplicationAuditsCountLoading,
  } = useQuery(
    [APPLICATION_AUDITS_COUNT_QUERY, dealId],
    () => api.getApplicationAuditsCount(dealId),
    {
      enabled: Boolean(dealId),
      onError: () => {
        openNotification(
          "Failed to get application audits count.",
          NOTIFICATIONS.error
        );
      },
    }
  );

  return {
    applicationAuditsCount,
    isApplicationAuditsCountLoading,
  };
};
