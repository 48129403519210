import { useState } from "react";

import isArray from "lodash/isArray";
import { useMutation, useQuery, queryCache } from "react-query";

import yardiIntegrationApi from "manager/lib/yardiIntegrationApi";
import { IntegrationProvider, NOTIFICATIONS } from "shared/config/constants";
import {
  refetchActiveQueries,
  refetchMultipleActiveQueries,
  removeMultipleQueries,
} from "shared/utils/queries.utils";
import { openNotification } from "shared/utils/ui";

import { COMPANY_QUERY_KEYS } from "./companyQueries";
import { INTEGRATION_QUERY_KEYS } from "./integrationQueries";

const YARDI_AGENTS_ON_PROPERTIES = "getYardiAgentsOnVeroProperties";
const YARDI_PROPERTY_REASONS = "getYardiPropertyReasons";
const YARDI_PLATFORM_TYPES = "getYardiPlatformTypes";
const YARDI_PCODE_POINTS = "getYardiPcodePoints";
const YARDI_TCODE_POINTS = "getYardiTcodePoints";
const YARDI_LEASE_SIGN_POINTS = "getYardiLeaseSignEventPoints";
const YARDI_SECURITY_DEPOSIT_PREFERENCES = "getYardiSecurityDepositPreferences";
const YARDI_ALERTS = "getYardiAlerts";
const YARDI_LEAD_SOURCES = "getYardiLeadSources";
const YARDI_CHARGE_TYPES = "getYardiChargeTypes";
const YARDI_ATTACHMENT_TYPES = "getYardiAttachmentTypes";
const YARDI_INTEGRATION_STATUS = "getYardiIntegrationStatus";
const YARDI_UNITS = "getYardiUnits";
const YARDI_UNIT = "getYardiUnit";
const YARDI_AGENTS = "getYardiAgents";
const YARDI_MARKETING_SOURCES = "getYardiMarketingSources";
const YARDI_PROPERTY = "getYardiProperty";
const YARDI_PROPERTIES = "getYardiProperties";
const YARDI_CREDENTIALS = "getYardiCredentials";
const YARDI_SYNC_INTERVALS = "getYardiSyncIntervals";
const YARDI_DEAL_STATUS_SYNC = "getYardiDealSync";
const UNITS = "getUnits";
const ALL_UNITS_SIMPLE = "getAllUnitsSimple";
const EMPLOYEES_ASSIGNED_TO_PROPERTY = "getEmployeesAssignedToProperty";
const PROPERTIES = "getProperties";
const ALL_PROPERTIES_SIMPLE = "getAllPropertiesSimple";
const MARKETING_SOURCES = "getMarketingSources";
const LEAD_SOURCES = "getLeadSources";

const useGetYardiPlatformTypes = () => {
  const { data: yardiPlatformTypes, isLoading } = useQuery(
    [YARDI_PLATFORM_TYPES],
    () => yardiIntegrationApi.getYardiPlatformTypes(),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      onError: () => {
        openNotification("Failed to get platform types.", NOTIFICATIONS.error);
      },
    }
  );

  return {
    yardiPlatformTypes,
    isLoading,
  };
};

const useGetYardiPcodePoints = () => {
  const { data: yardiPcodePoints, isLoading } = useQuery(
    [YARDI_PCODE_POINTS],
    () => yardiIntegrationApi.getYardiPcodePoints(),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      onError: () => {
        openNotification("Failed to get PCode points.", NOTIFICATIONS.error);
      },
    }
  );

  return {
    yardiPcodePoints,
    isLoading,
  };
};

const useGetYardiTcodePoints = () => {
  const { data: yardiTcodePoints, isLoading } = useQuery(
    [YARDI_TCODE_POINTS],
    () => yardiIntegrationApi.getYardiTcodePoints(),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      onError: () => {
        openNotification("Failed to get TCode points.", NOTIFICATIONS.error);
      },
    }
  );

  return {
    yardiTcodePoints,
    isLoading,
  };
};

const useGetYardiLeaseSignEventPoints = () => {
  const { data: yardiLeaseSignPoints, isLoading } = useQuery(
    [YARDI_LEASE_SIGN_POINTS],
    () => yardiIntegrationApi.getYardiLeaseSignEventPoints(),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      onError: () => {
        openNotification(
          "Failed to get lease sign event points.",
          NOTIFICATIONS.error
        );
      },
    }
  );

  return {
    yardiLeaseSignPoints,
    isLoading,
  };
};

const useGetYardiSecurityDepositPreferences = () => {
  const { data: yardiSecurityDepositPreferences, isLoading } = useQuery(
    [YARDI_SECURITY_DEPOSIT_PREFERENCES],
    () => yardiIntegrationApi.getYardiSecurityDepositPreferences(),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      onError: () => {
        openNotification(
          "Failed to get Security deposit preferences.",
          NOTIFICATIONS.error
        );
      },
    }
  );

  return {
    yardiSecurityDepositPreferences,
    isLoading,
  };
};

const useGetYardiSyncIntevals = () => {
  const { data: yardiSyncIntervals, isLoading } = useQuery(
    [YARDI_SYNC_INTERVALS],
    () => yardiIntegrationApi.getYardiSyncIntervals(),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      onError: () => {
        openNotification("Failed to get sync intervals.", NOTIFICATIONS.error);
      },
    }
  );

  return {
    yardiSyncIntervals,
    isLoading,
  };
};

const useCreateYardiCredentials = ({ provider, companyId }) => {
  const [mutate, status] = useMutation(
    (credentials) =>
      yardiIntegrationApi.postYardiCredentials(provider, credentials),
    {
      onSuccess: () =>
        queryCache.invalidateQueries(COMPANY_QUERY_KEYS.getCompany(companyId)),
      onError: (error) => {
        const message =
          isArray(error.errors) && error.errors?.length
            ? error.errors[0]
            : "Failed to create credentials";
        openNotification(message, NOTIFICATIONS.error);
      },
    }
  );

  return {
    createYardiCredentials: mutate,
    isLoading: status.isLoading,
  };
};

const useUpdateYardiCredentials = ({ provider, companyId }) => {
  const [mutate, status] = useMutation(
    (credentials) =>
      yardiIntegrationApi.patchYardiCredentials(provider, credentials),
    {
      onSuccess: () =>
        queryCache.invalidateQueries(COMPANY_QUERY_KEYS.getCompany(companyId)),
      onError: (error) => {
        const message =
          isArray(error.errors) && error.errors?.length
            ? error.errors[0]
            : "Failed to update credentials";
        openNotification(message, NOTIFICATIONS.error);
      },
    }
  );

  return {
    updateYardiCredentials: mutate,
    isLoading: status.isLoading,
  };
};

const useDeleteYardiCredentials = (provider) => {
  const [mutate, status] = useMutation(
    () => yardiIntegrationApi.deleteYardiCredentials(provider),
    {
      onError: () => {
        openNotification("Failed to delete credentials", NOTIFICATIONS.error);
      },
    }
  );

  return {
    deleteYardiCredentials: mutate,
    isLoading: status.isLoading,
  };
};

const useGetYardiCredentials = (
  provider,
  showFailNotification = false,
  enabled = true
) => {
  const {
    data: yardiCredentials,
    isLoading,
    isError,
    isFetched,
  } = useQuery(
    [YARDI_CREDENTIALS, provider],
    () => yardiIntegrationApi.getYardiCredentials(provider),
    {
      enabled,
      onError: () => {
        if (showFailNotification) {
          openNotification("Failed to get credentials.", NOTIFICATIONS.error);
        }
      },
      retry: false,
    }
  );

  return {
    yardiCredentials,
    isLoading,
    isError,
    isFetched,
  };
};

const usePollYardiCredentials = (provider, interval) => {
  const [isPollingActive, setPollingActive] = useState(false);

  const {
    data: yardiCredentials,
    error: yardiCredentialsError,
    isLoading,
    isError: isPollYardiCredentialsError,
  } = useQuery(
    [YARDI_CREDENTIALS, provider],
    () => yardiIntegrationApi.getYardiCredentials(provider),
    {
      enabled: isPollingActive,
      refetchInterval: isPollingActive ? interval : false,
      onSettled: () => {
        queryCache.invalidateQueries(
          INTEGRATION_QUERY_KEYS.getIntegrationStatuses()
        );
      },
    }
  );

  return {
    startPolling: () => setPollingActive(true),
    stopPolling: () => setPollingActive(false),
    isLoading,
    isPollYardiCredentialsError,
    yardiCredentials,
    yardiCredentialsError,
    isPollingActive,
  };
};

const useStartYardiPull = ({ provider, onSuccess }) => {
  const [mutate, status] = useMutation(
    () => yardiIntegrationApi.postYardiPull(provider),
    {
      onError: () => {
        openNotification("Failed to refresh integration", NOTIFICATIONS.error);
      },
      onSuccess: (data) => {
        queryCache.invalidateQueries(
          INTEGRATION_QUERY_KEYS.getIntegrationStatuses()
        );
        onSuccess(data);
      },
    }
  );

  return {
    startYardiPull: mutate,
    isLoading: status.isLoading,
  };
};

const useGetYardiProperties = ({
  isActive,
  page,
  pageSize,
  status,
  nameOrAddress,
  ordering,
  truncated,
  provider,
} = {}) => {
  const {
    data: yardiProperties,
    isLoading,
    refetch: refetchProperties,
  } = useQuery(
    [
      YARDI_PROPERTIES,
      isActive,
      page,
      pageSize,
      status,
      nameOrAddress,
      ordering,
      truncated,
      provider,
    ],
    () =>
      yardiIntegrationApi.getYardiProperties({
        isActive,
        page,
        pageSize,
        status,
        nameOrAddress,
        ordering,
        truncated,
        provider,
      }),
    {
      refetchOnMount: "always",
      onError: () => {
        openNotification("Failed to get properties.", NOTIFICATIONS.error);
      },
    }
  );

  return {
    refetchProperties,
    yardiProperties,
    isLoading,
  };
};

const useGetYardiProperty = (provider, propertyId) => {
  const {
    data: yardiProperty,
    refetch,
    isLoading,
  } = useQuery(
    [YARDI_PROPERTY, propertyId, provider],
    () => yardiIntegrationApi.getYardiProperty(provider, propertyId),
    {
      refetchOnMount: "always",
      onError: () => {
        openNotification("Failed to get property.", NOTIFICATIONS.error);
      },
    }
  );

  return {
    yardiProperty,
    refetch,
    isLoading,
  };
};

const useImportPmsProperties = ({ provider }) => {
  const [mutate, status] = useMutation(
    (properties) =>
      yardiIntegrationApi.importPmsProperties(provider, properties),
    {
      onError: () => {
        openNotification("Failed to import properties", NOTIFICATIONS.error);
      },
    }
  );
  return {
    importPmsProperties: mutate,
    isLoading: status.isLoading,
  };
};

const useUpdateYardiProperty = () => {
  const [mutate, status] = useMutation(
    ({ provider, id, veroPropertyId }) =>
      yardiIntegrationApi.putYardiProperty(provider, id, veroPropertyId),
    {
      onError: () => {
        openNotification("Failed to update property", NOTIFICATIONS.error);
        throw new Error("Failed to update property");
      },
      onSuccess: () => {
        queryCache.invalidateQueries([PROPERTIES]);
        queryCache.invalidateQueries([ALL_PROPERTIES_SIMPLE]);
      },
    }
  );

  return {
    updateYardiProperty: mutate,
    isLoading: status.isLoading,
  };
};

const getYardiConfigurePropertyQueries = (id) => [
  [YARDI_UNITS, id],
  [ALL_UNITS_SIMPLE],
  [EMPLOYEES_ASSIGNED_TO_PROPERTY],
  [YARDI_AGENTS, id],
  [YARDI_MARKETING_SOURCES, id],
  [MARKETING_SOURCES],
  YARDI_PROPERTIES,
  YARDI_PROPERTY,
];

const useRemoveYardiConfigurePropertyQueries = (id) => {
  removeMultipleQueries(getYardiConfigurePropertyQueries(id));
};

const useUpdateYardiPropertyMappings = (provider) => {
  const [mutate, status] = useMutation(
    ({ id, mappings }) =>
      yardiIntegrationApi.putYardiPropertyMappings(provider, id, mappings),
    {
      onError: () => {
        openNotification(
          "Failed to update property mappings",
          NOTIFICATIONS.error
        );
      },
      onSuccess: (data, { id }) => {
        const message = data.completed
          ? "Property configuration successfully updated"
          : "Progress Saved. Property configuration is incomplete.";
        const notificationType = data.completed
          ? NOTIFICATIONS.info
          : NOTIFICATIONS.warning;
        openNotification(message, notificationType);
        refetchMultipleActiveQueries(getYardiConfigurePropertyQueries(id));
      },
    }
  );

  return {
    updateYardiPropertyMappings: mutate,
    isLoading: status.isLoading,
  };
};

const useDeleteYardiPropertyMappings = (provider) => {
  const [mutate, status] = useMutation(
    ({ id, removeFromActive }) =>
      yardiIntegrationApi.deleteYardiPropertyMappings(
        provider,
        id,
        removeFromActive
      ),
    {
      onError: () => {
        openNotification(
          "Failed to delete property mappings",
          NOTIFICATIONS.error
        );
      },
      onSuccess: () => {
        openNotification(
          "Property mapping successfully deleted",
          NOTIFICATIONS.info
        );
        queryCache.invalidateQueries(YARDI_PROPERTIES);
        queryCache.invalidateQueries(YARDI_PROPERTY);
        queryCache.invalidateQueries([PROPERTIES]);
        queryCache.invalidateQueries([ALL_PROPERTIES_SIMPLE]);
      },
    }
  );

  return {
    deleteYardiPropertyMappings: mutate,
    isLoading: status.isLoading,
  };
};

const useGetYardiPropertyReasons = (yardiPropertyId, type) => {
  const { data: yardiPropertyReasons, isLoading } = useQuery(
    [YARDI_PROPERTY_REASONS, yardiPropertyId, type],
    () =>
      yardiIntegrationApi.getYardiPropertyReasons({
        id: yardiPropertyId,
        type,
      }),
    {
      enabled: yardiPropertyId,
      onError: () => {
        openNotification(
          "Failed to get property reasons.",
          NOTIFICATIONS.error
        );
      },
    }
  );

  return {
    yardiPropertyReasons: yardiPropertyReasons || [],
    isLoading,
  };
};

const useGetYardiIntegrationStatus = (provider) => {
  const { data: yardiIntegrationStatus, isLoading } = useQuery(
    [YARDI_INTEGRATION_STATUS, provider],
    () => yardiIntegrationApi.getYardiIntegrationStatus(provider),
    {
      refetchOnMount: "always",
      onError: () => {
        openNotification(
          "Failed to get integration status.",
          NOTIFICATIONS.error
        );
      },
    }
  );

  return {
    yardiIntegrationStatus,
    isLoading,
  };
};

const useGetYardiAttachmentTypes = (provider) => {
  const { data: yardiAttachmentTypes, isLoading } = useQuery(
    [YARDI_ATTACHMENT_TYPES, provider],
    () => yardiIntegrationApi.getYardiAttachmentTypes(provider),
    {
      onError: () => {
        openNotification(
          "Failed to get attachment types.",
          NOTIFICATIONS.error
        );
      },
    }
  );

  return {
    yardiAttachmentTypes,
    isLoading,
  };
};

const useGetYardiChargeTypes = (provider) => {
  const { data: yardiChargeTypes, isLoading } = useQuery(
    [YARDI_CHARGE_TYPES, provider],
    () => yardiIntegrationApi.getYardiChargeTypes(provider),
    {
      onError: () => {
        openNotification("Failed to get charge types.", NOTIFICATIONS.error);
      },
    }
  );

  return {
    yardiChargeTypes,
    isLoading,
  };
};

const useUpdateYardiMappingConfiguration = ({
  provider,
  completeSaveMessage,
  incompleteSaveMessage,
  completedProp,
}) => {
  const [mutate, status] = useMutation(
    (configuration) =>
      yardiIntegrationApi.patchYardiMappingConfiguration(
        provider,
        configuration
      ),
    {
      onError: () => {
        openNotification(
          "Failed to update mapping configuration",
          NOTIFICATIONS.error
        );
      },
      onSuccess: (data) => {
        const isCompleted = !!data[completedProp];
        const message = isCompleted
          ? completeSaveMessage
          : incompleteSaveMessage;
        const notificationType = isCompleted
          ? NOTIFICATIONS.info
          : NOTIFICATIONS.warning;
        openNotification(message, notificationType);
        queryCache.invalidateQueries(YARDI_CREDENTIALS);
      },
    }
  );

  return {
    updateYardiMappingConfiguration: mutate,
    isLoading: status.isLoading,
  };
};

const useGetYardiUnits = (provider, propertyId) => {
  const {
    data: yardiUnits,
    isLoading,
    isFetching,
  } = useQuery(
    [YARDI_UNITS, propertyId, provider],
    () => yardiIntegrationApi.getYardiUnits(provider, propertyId),
    {
      refetchOnMount: "always",
      onError: () => {
        openNotification("Failed to get units.", NOTIFICATIONS.error);
      },
    }
  );

  return {
    yardiUnits,
    isLoading: isLoading || isFetching,
  };
};

const useGetYardiUnit = ({ provider, yardiPropertyId, yardiUnitId }) => {
  const {
    data: yardiUnit,
    isLoading,
    isFetching,
  } = useQuery(
    [YARDI_UNIT, yardiPropertyId, yardiUnitId, provider],
    () =>
      yardiIntegrationApi.getYardiUnit(provider, yardiPropertyId, yardiUnitId),
    {
      enabled: yardiPropertyId && yardiUnitId,
      refetchOnMount: "always",
      onError: () => {
        openNotification("Failed to get unit.", NOTIFICATIONS.error);
      },
    }
  );

  return {
    yardiUnit,
    isLoading: isLoading || isFetching,
  };
};

const useAutocreateVeroUnits = (provider, yardiPropertyId) => {
  const [mutate, status] = useMutation(
    (configuration) =>
      yardiIntegrationApi.postAutocreateVeroUnits(
        provider,
        yardiPropertyId,
        configuration
      ),
    {
      onError: () => {
        openNotification(
          "Failed to create and map some Unit",
          NOTIFICATIONS.error
        );
      },
      onSuccess: () => {
        openNotification(
          "Unit successfully created and mapped",
          NOTIFICATIONS.info
        );
        refetchActiveQueries([YARDI_UNITS]);
        refetchActiveQueries([UNITS]);
        refetchActiveQueries([ALL_UNITS_SIMPLE]);
      },
    }
  );

  return {
    autocreateVeroUnits: mutate,
    isLoading: status.isLoading,
  };
};

const useGetYardiMarketingSources = (provider, propertyId) => {
  const {
    data: yardiMarketingSources,
    isLoading,
    isFetching,
  } = useQuery(
    [YARDI_MARKETING_SOURCES, propertyId, provider],
    () => yardiIntegrationApi.getYardiMarketingSources(provider, propertyId),
    {
      enabled: propertyId && provider !== IntegrationProvider.realPage,
      refetchOnMount: "always",
      onError: () => {
        openNotification(
          "Failed to get marketing sources.",
          NOTIFICATIONS.error
        );
      },
    }
  );

  return {
    yardiMarketingSources,
    isLoading: isLoading || isFetching,
  };
};

const useAutoCreateVeroMarketingSources = (provider, yardiPropertyId) => {
  const [mutate, status] = useMutation(
    () =>
      yardiIntegrationApi.postAutoCreateVeroMarketingSources(
        provider,
        yardiPropertyId
      ),
    {
      onError: () => {
        openNotification(
          "Failed to create and map some marketing sources",
          NOTIFICATIONS.error
        );
      },
      onSuccess: () => {
        openNotification(
          "Marketing sources successfully created and mapped",
          NOTIFICATIONS.info
        );
        refetchActiveQueries([MARKETING_SOURCES]);
        refetchActiveQueries([YARDI_MARKETING_SOURCES]);
      },
    }
  );

  return {
    autoCreateVeroMarketingSources: mutate,
    isLoading: status.isLoading,
  };
};

const useGetYardiAgents = (provider, propertyId) => {
  const {
    data: yardiAgents,
    isLoading,
    isFetching,
  } = useQuery(
    [YARDI_AGENTS, propertyId, provider],
    () => yardiIntegrationApi.getYardiAgents(provider, propertyId),
    {
      enabled: propertyId && provider !== IntegrationProvider.realPage,
      refetchOnMount: "always",
      onError: () => {
        openNotification("Failed to get agents.", NOTIFICATIONS.error);
      },
    }
  );

  return {
    yardiAgents,
    isLoading: isLoading || isFetching,
  };
};

const useGetYardiAgentsOnVeroProperties = (veroPropertiesIds) => {
  const { data, isLoading, isFetching } = useQuery(
    [YARDI_AGENTS_ON_PROPERTIES, veroPropertiesIds],
    () => yardiIntegrationApi.getYardiAgentsOnVeroProperties(veroPropertiesIds),
    {
      enabled: veroPropertiesIds,
      refetchOnMount: "always",
      onError: () => {
        openNotification(
          "Failed to get agents on properties.",
          NOTIFICATIONS.error
        );
      },
    }
  );

  return {
    yardiAgentsOnProperties: data || [],
    isLoading: isLoading || isFetching,
  };
};

const usePutYardiAgentsOnPropertiesMapping = () => {
  const [mutate, status] = useMutation(
    (mappings) =>
      yardiIntegrationApi.putYardiAgentsOnPropertiesMapping({ mappings }),
    {
      onError: () => {
        openNotification(
          "Failed to update agents mappings",
          NOTIFICATIONS.error
        );
      },
      onSuccess: () => {
        openNotification(
          "Agents mappings successfully updated",
          NOTIFICATIONS.info
        );
        queryCache.invalidateQueries(YARDI_AGENTS_ON_PROPERTIES);
      },
    }
  );

  return {
    updateYardiAgentsMappings: mutate,
    isLoading: status.isLoading,
  };
};

const useGetYardiLeadSources = () => {
  const {
    data: yardiLeadSources,
    isLoading,
    isFetching,
  } = useQuery(
    [YARDI_LEAD_SOURCES],
    () => yardiIntegrationApi.getYardiLeadSources(),
    {
      refetchOnMount: "always",
      onError: () => {
        openNotification("Failed to get lead sources.", NOTIFICATIONS.error);
      },
    }
  );

  return {
    yardiLeadSources,
    isLoading: isLoading || isFetching,
  };
};

const usePutYardiLeadSourceMappingConfiguration = (provider) => {
  const [mutate, status] = useMutation(
    (leadSourcesMappings) =>
      yardiIntegrationApi.putYardiLeadSourceMapping(
        provider,
        leadSourcesMappings
      ),
    {
      onError: () => {
        openNotification(
          "Failed to update lead source mapping",
          NOTIFICATIONS.error
        );
      },
      onSuccess: (data) => {
        const message = data.completed
          ? "Lead source mapping successfully updated"
          : "Progress Saved. Lead source mapping is incomplete.";
        const notificationType = data.completed
          ? NOTIFICATIONS.info
          : NOTIFICATIONS.warning;
        openNotification(message, notificationType);
        queryCache.invalidateQueries(YARDI_LEAD_SOURCES);
        queryCache.invalidateQueries(LEAD_SOURCES);
      },
    }
  );

  return {
    updateYardiLeadSourceMapping: mutate,
    isLoading: status.isLoading,
  };
};

const useGetYardiAlerts = (
  provider,
  yardiPropertyId,
  resolved,
  alertClass,
  page,
  pageSize
) => {
  const result = useQuery(
    [
      YARDI_ALERTS,
      yardiPropertyId,
      resolved,
      alertClass,
      page,
      pageSize,
      provider,
    ],
    () =>
      yardiIntegrationApi.getYardiAlerts(
        provider,
        yardiPropertyId,
        resolved,
        alertClass,
        page,
        pageSize
      ),
    {
      cacheTime: 0,
      refetchOnMount: "always",
      onError: () => {
        openNotification("Failed to get alerts.", NOTIFICATIONS.error);
      },
    }
  );

  return {
    refetchAlerts: result.refetch,
    yardiAlerts: result.data || [],
    isLoading: result.isLoading,
  };
};

const useGetYardiPushOperationTracking = (dealId) => {
  const result = useQuery(
    ["getYardiPushOperationTracking", dealId],
    () => yardiIntegrationApi.getYardiPushOperationTracking(dealId),
    {
      onError: () => {
        openNotification(
          "Failed to get operation tracking.",
          NOTIFICATIONS.error
        );
      },
    }
  );

  return {
    yariOperationTracking: result.data || [],
    refetchOperationTracking: result.refetch,
    isLoading: result.isLoading,
  };
};

const useGetYardiPushOperationMessage = (id) => {
  const result = useQuery(
    ["getYardiPushOperationMessage", id],
    () => yardiIntegrationApi.getYardiPushOperationMessage(id),
    {
      onError: () => {
        openNotification(
          "Failed to get operation messages.",
          NOTIFICATIONS.error
        );
      },
    }
  );

  return {
    yardiOperetionMessages: result.data || [],
    refetchOperationMessages: result.refetch,
    isLoadingMessages: result.isLoading,
  };
};

const useGetYardiYardiActionTracking = (id) => {
  const result = useQuery(
    ["getYardiYardiActionTracking", id],
    () => yardiIntegrationApi.getYardiActionTracking(id),
    {
      onError: () => {
        openNotification("Failed to get action tracking.", NOTIFICATIONS.error);
      },
    }
  );

  return {
    yardiActionTracking: result.data || [],
    refetchActionTracking: result.refetch,
    isLoadingActionTracking: result.isLoading,
  };
};

const useGetYardiVeroEvent = (id) => {
  const result = useQuery(
    ["getYardiVeroEvent", id],
    () => yardiIntegrationApi.getYardiVeroEvent(id),
    {
      onError: () => {
        openNotification(
          "Failed to get VERO events related to a application.",
          NOTIFICATIONS.error
        );
      },
    }
  );

  return {
    yardiVeroEvent: result.data || [],
    refetchVeroEvent: result.refetch,
    isLoadingVeroEvent: result.isLoading,
  };
};

const useGetYardiClientReqRes = (id) => {
  const result = useQuery(
    ["getYardiClientReqRes", id],
    () => yardiIntegrationApi.getYardiClientReqRes(id),
    {
      onError: () => {
        openNotification("Failed to get client req/res", NOTIFICATIONS.error);
      },
    }
  );

  return {
    yardiClientReqRes: result.data || [],
    refetchClientReqRes: result.refetch,
    isLoadingClientReqRes: result.isLoading,
  };
};

const useGetYardiVersion = (provider, enabled) => {
  const { data, isLoading } = useQuery(
    ["getYardiVersion", provider],
    () => yardiIntegrationApi.getYardiVersion(provider),
    {
      enabled,
      onError: () => {
        openNotification("Failed to get the version", NOTIFICATIONS.error);
      },
    }
  );

  return {
    yardiVersion: data?.version,
    isYardiVersionLoading: isLoading,
  };
};

const useGetYardiDealStatus = (
  id,
  isYardiConnected,
  taskCode,
  refetchInterval
) => {
  const {
    data: yardiDealStatus,
    refetch: refetchYardiDealStatus,
    isLoading,
  } = useQuery(
    [YARDI_DEAL_STATUS_SYNC, id],
    () => yardiIntegrationApi.getYardiDealStatus(id, taskCode),
    {
      enabled: !!id && !!isYardiConnected,
      refetchInterval,
      onError: () => {
        openNotification(
          `Failed to get status for the application: ${id}.`,
          NOTIFICATIONS.error
        );
      },
    }
  );

  return {
    yardiDealStatus,
    refetchYardiDealStatus,
    isLoading,
  };
};

const useStartYardiDealStatusSync = () => {
  const [mutate, { isLoading }] = useMutation(
    (id) => yardiIntegrationApi.postYardiDealStatusSync(id),
    {
      onError: () => {
        openNotification(
          `Failed to start sync for the application.`,
          NOTIFICATIONS.error
        );
      },
    }
  );

  return {
    startYardiDealStatusSync: mutate,
    isLoading,
  };
};

export {
  useGetYardiPlatformTypes,
  useGetYardiPcodePoints,
  useGetYardiTcodePoints,
  useGetYardiLeaseSignEventPoints,
  useGetYardiSecurityDepositPreferences,
  useGetYardiSyncIntevals,
  useCreateYardiCredentials,
  useUpdateYardiCredentials,
  useDeleteYardiCredentials,
  usePollYardiCredentials,
  useGetYardiCredentials,
  useGetYardiProperties,
  useStartYardiPull,
  useGetYardiProperty,
  useGetYardiAlerts,
  useUpdateYardiProperty,
  useUpdateYardiPropertyMappings,
  useRemoveYardiConfigurePropertyQueries,
  useDeleteYardiPropertyMappings,
  useGetYardiPropertyReasons,
  useGetYardiIntegrationStatus,
  useGetYardiAttachmentTypes,
  useGetYardiChargeTypes,
  useUpdateYardiMappingConfiguration,
  useGetYardiUnits,
  useGetYardiUnit,
  useGetYardiMarketingSources,
  useGetYardiAgents,
  useGetYardiAgentsOnVeroProperties,
  usePutYardiAgentsOnPropertiesMapping,
  useGetYardiLeadSources,
  usePutYardiLeadSourceMappingConfiguration,
  useGetYardiPushOperationTracking,
  useGetYardiPushOperationMessage,
  useGetYardiYardiActionTracking,
  useGetYardiVeroEvent,
  useGetYardiClientReqRes,
  useAutocreateVeroUnits,
  useAutoCreateVeroMarketingSources,
  useGetYardiVersion,
  useGetYardiDealStatus,
  useStartYardiDealStatusSync,
  useImportPmsProperties,
};
