import React from "react";

import { useMediaQuery } from "react-responsive";

import FlexContainer from "shared/components/FlexContainer";
import { BREAKPOINT_RESOLUTIONS } from "shared/config/constants";

import LumenHowItWorksAction from "./LumenHowItWorksAction";
import { LumenBannerWell } from "./styled";

const LumenDashboardBanner = () => {
  const isSmallScreen = useMediaQuery({ maxWidth: BREAKPOINT_RESOLUTIONS.lg });

  const text = isSmallScreen
    ? "Lumen will work to uncover the most important insights about applicants based on the configurations you choose."
    : "Lumen will work to uncover the most important insights about applicants based on the configurations you choose. From this dashboard you can create, edit and assign different formulas of Lumen to your portfolios and properties.";

  return (
    <LumenBannerWell>
      <div className="banner-icon-1" />
      <div>
        <p className="banner-message">{text}</p>
        <FlexContainer>
          <LumenHowItWorksAction />
        </FlexContainer>
      </div>
      <div className="banner-icon-2" />
    </LumenBannerWell>
  );
};

export default LumenDashboardBanner;
