/* eslint-disable camelcase */
import { useEffect, useMemo, useState } from "react";

import { isEmpty, compact } from "lodash";

import { useGetManagerProfile } from "manager/hooks/api";
import { useActiveUser } from "shared/hooks/api";
import { getIntercomHash } from "shared/lib/api/intercomApi";
import { isManagerApp, useClaims } from "shared/utils/auth";

const useIntercom = () => {
  const { REACT_APP_INTERCOM_APP_ID: APP_ID } = process.env;

  const { claims, company } = useClaims();
  const { activeUser } = useActiveUser();
  const { data: profile } = useGetManagerProfile({
    enabled: !isEmpty(company) && !isEmpty(activeUser) && isManagerApp(),
  });

  const email = claims?.email || claims?.username;
  const [hash, setHash] = useState<string>();

  useEffect(() => {
    if (email) {
      getIntercomHash().then((token: any) =>
        setHash(token?.intercom?.userHash)
      );
    }
  }, [email, activeUser]);

  const settings = useMemo(
    () => ({
      api_base: "https://api-iam.intercom.io",
      app_id: APP_ID,
      email,
      name: compact([claims?.givenName, claims?.familyName]).join(" ").trim(),
      user_hash: hash,
      created_at: claims?.createdAt,
      login_at: claims?.iat || claims?.origIat,
      role: profile?.role ?? "",
      company: company
        ? {
            id: String(company.id),
            name: company.name || String(company.id),
          }
        : null,
      renter_app_version: isManagerApp() ? "" : "1.0",
    }),
    [claims, company, activeUser, hash, profile]
  );

  const intercom = (...args) => {
    // @ts-ignore
    return window?.Intercom?.(...args);
  };

  const api = {
    boot: (settings) => intercom("boot", settings),
    update: (settings) => intercom("update", settings),
    hide: () => intercom("hide"),
    shutdown: () => intercom("shutdown"),
    show: () => intercom("show"),
  };

  useEffect(() => {
    if (hash) api.update(settings);
  }, [hash, activeUser, settings]);

  useEffect(() => {
    const script = `
      var APP_ID = '${settings.app_id}';
      (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;
    // eslint-disable-next-line no-new-func
    new Function(script)();
  }, [APP_ID]);

  useEffect(() => {
    api.boot(
      hash
        ? settings
        : {
            api_base: "https://api-iam.intercom.io",
            app_id: APP_ID,
          }
    );
  }, [APP_ID]);

  return api;
};

export const Intercom = () => {
  useIntercom();
  return null;
};
