import React from "react";

import Icon from "shared/components/Icon";
import { UnderlineLink } from "shared/components/Links";
import Spacer from "shared/components/Spacer";
import { SmallText } from "shared/components/Typography";
import { DEAL_TYPES } from "shared/config/constants";

import { StepConfirmationProps } from "./interfaces";
import { SubmitResultContainer } from "./styled";

const StepConfirmation = ({
  email,
  signupUrl,
  dealType = DEAL_TYPES.SCREENING,
}: StepConfirmationProps) => {
  const dealTypeLabel =
    dealType === DEAL_TYPES.SCREENING ? "application" : "prequalification";
  const confirmationMessage = `Thanks! We just sent the ${dealTypeLabel} link to`;
  const notificationMessage = `Please be on the lookout for the invitation to start your ${dealTypeLabel} from`;
  return (
    <SubmitResultContainer data-testid="step-confirmation">
      <Icon.EmailSentIcon
        data-testid="icon-check"
        className="confirmation-icon"
      />
      <p className="confirmation-message">
        {confirmationMessage} <b>{email}</b>
      </p>
      <p data-testid="notification-message">
        {notificationMessage} <b>no-reply@mail.sayvero.com</b>
      </p>
      <Spacer />
      {signupUrl && (
        <SmallText light data-testid="signup-url-message-testing">
          To start the application click{" "}
          <UnderlineLink href={signupUrl} data-testid="signup-url-testing">
            here
          </UnderlineLink>
        </SmallText>
      )}
    </SubmitResultContainer>
  );
};

export default StepConfirmation;
