import styled from "@emotion/styled";

import Icon from "shared/components/Icon";

const InfoIcon = styled(Icon.InfoIcon)`
  vertical-align: middle;
  margin-left: 6px;
`;

export { InfoIcon };
