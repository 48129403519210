import PropTypes from "prop-types";

import { dealUiStatusPropType } from "shared/utils/propTypes";

const dealStatuses = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })
);

const filters = PropTypes.shape({
  dateMax: PropTypes.string,
  dateMin: PropTypes.string,
  dealStatus: dealUiStatusPropType,
  propertyId: PropTypes.arrayOf(PropTypes.string),
});

const deal = PropTypes.shape({
  id: PropTypes.number.isRequired,
  unit: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  unitListing: PropTypes.shape({
    unitAvailableOn: PropTypes.string.isRequired,
    rent: PropTypes.number,
  }),
  property: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  dealUiStatus: dealUiStatusPropType,
  applicantsSummary: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      isSubmitted: PropTypes.bool.isRequired,
      leaseSigned: PropTypes.bool.isRequired,
    })
  ),
  progressPercent: PropTypes.number.isRequired,
  applicationDate: PropTypes.string.isRequired,
  leaseId: PropTypes.number,
  yardiPcode: PropTypes.string,
  yardiTcode: PropTypes.string,
  lastLumenScore: PropTypes.string,
});

const deals = PropTypes.arrayOf(deal);

export default {
  dealStatuses,
  filters,
  deal,
  deals,
};
