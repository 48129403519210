import styled from "@emotion/styled";

import { Modal } from "shared/components/Modal";
import { COLORS } from "shared/config/constants";

export const ModalBody = styled(Modal.Body)`
  .filter-conditions {
    margin-top: 22px;
    gap: 12px;
    margin-bottom: 12px;
  }

  .filter-buttons {
    gap: 20px;

    .filter-button {
      background-color: ${COLORS.layoutBodyBackground};
      border-radius: 16px;
      color: ${COLORS.violetTheme.lightViolet2};
    }
  }
`;
