import React from "react";

import PropTypes from "prop-types";

import { unblockApplicationItemPropType } from "manager/utils/propTypes";
import DropdownMenu from "shared/components/DropdownMenu";
import FlexContainer from "shared/components/FlexContainer";

const ApplicantDropdown = ({
  isSubmitted,
  canBeUpdated,
  viewAttachments,
  editIncomeAndAssets,
  remove,
  downloadPdf,
  moveToDeal,
  unblockApplicationItem,
  removeApplicationDisabledTooltip,
  moveApplicationDisabledTooltip,
  hasPermissionToDownloadPDF,
}) => {
  const items = [];
  const showDownloadButton =
    hasPermissionToDownloadPDF && isSubmitted && downloadPdf;

  items.unshift({
    key: "REMOVE_FROM_APPLICATION",
    label: "Remove From Application",
    onClick: remove,
    disabled: Boolean(removeApplicationDisabledTooltip),
    ...(removeApplicationDisabledTooltip && {
      tooltip: {
        title: removeApplicationDisabledTooltip,
      },
    }),
  });
  items.unshift({
    key: "MOVE_TO_ANOTHER_APPLICATION",
    label: "Move To Another Application",
    onClick: moveToDeal,
    disabled: Boolean(moveApplicationDisabledTooltip),
    ...(moveApplicationDisabledTooltip && {
      tooltip: {
        title: moveApplicationDisabledTooltip,
      },
    }),
  });
  if (isSubmitted && canBeUpdated) {
    items.unshift({
      key: "EDIT_INCOME_ASSETS",
      label: "Edit Income",
      onClick: editIncomeAndAssets,
    });
  }

  if (unblockApplicationItem.showAction) {
    items.unshift({
      key: "UNBLOCK_UNBLOCK_APPLICATION_IF_NO_INCOME",
      label: unblockApplicationItem.label,
      onClick: unblockApplicationItem.action,
    });
  }

  if (isSubmitted && viewAttachments) {
    items.unshift({
      key: "VIEW_ATTACHMENTS",
      label: "View Attachments",
      onClick: viewAttachments,
    });
  }

  if (showDownloadButton) {
    items.unshift({
      key: "DOWNLOAD_PDF",
      label: "Download Application PDF",
      onClick: downloadPdf,
    });
  }

  return (
    items.length > 0 && (
      <FlexContainer fullWidth justifyContent="flex-end">
        <DropdownMenu data-testid="applicant-dropdown" items={items} />
      </FlexContainer>
    )
  );
};

ApplicantDropdown.propTypes = {
  downloadPdf: PropTypes.func,
  editIncomeAndAssets: PropTypes.func.isRequired,
  isSubmitted: PropTypes.bool,
  canBeUpdated: PropTypes.bool,
  moveApplicationDisabledTooltip: PropTypes.string,
  moveToDeal: PropTypes.func,
  remove: PropTypes.func.isRequired,
  removeApplicationDisabledTooltip: PropTypes.string,
  unblockApplicationItem: unblockApplicationItemPropType.isRequired,
  viewAttachments: PropTypes.func,
  hasPermissionToDownloadPDF: PropTypes.bool.isRequired,
};

ApplicantDropdown.defaultProps = {
  isSubmitted: false,
  canBeUpdated: true,
  downloadPdf: undefined,
  viewAttachments: undefined,
  moveToDeal: undefined,
  disableEditingActions: false,
  moveApplicationDisabledTooltip: "",
  removeApplicationDisabledTooltip: "",
};

export default ApplicantDropdown;
