import React from "react";

import { useFormikContext } from "formik";
import PropTypes from "prop-types";

import Settings from "shared/components/Settings";
import { useAnchorNavigateToElementWhenActive } from "shared/hooks";
import { ApplicantType } from "shared/interfaces/misc";
import { keyPropType } from "shared/utils/propTypes";

import ApplicationSetupHeader from "../Header";
import { StyledSkeleton as Skeleton } from "../styled";

import AdditionalQuestionsTable from "./AdditionalQuestionsTable";
import { getAdditionalQuestions, getRequiredQuestions } from "./config";

const AdditionalQuestions = ({
  loading,
  fetching,
  dealSetup,
  openQuestionsModal,
  disabled,
}) => {
  const { values } = useFormikContext();
  useAnchorNavigateToElementWhenActive();

  return (
    <Settings
      hasComponentPadding={false}
      header={
        <Skeleton loading={loading}>
          <span id="additionalQuestions" />
          <ApplicationSetupHeader
            title="Additional Questions"
            subTitle="Please manage the questions that you want to require from applicants, occupants and guarantors"
          />
        </Skeleton>
      }
      nodes={[
        {
          key: 1,
          component: (
            <Skeleton loading={loading}>
              <AdditionalQuestionsTable
                objectId={dealSetup?.id}
                questions={[
                  ...getRequiredQuestions(values, dealSetup),
                  ...getAdditionalQuestions(values, ApplicantType.APPLICANT),
                ]}
                disabled={disabled}
                applicantType={ApplicantType.APPLICANT}
                openQuestionsModal={openQuestionsModal}
                fetching={fetching}
              />
            </Skeleton>
          ),
        },
        {
          key: 2,
          component: (
            <Skeleton loading={loading}>
              <AdditionalQuestionsTable
                objectId={dealSetup?.id}
                description="over 18 years old"
                questions={getAdditionalQuestions(
                  values,
                  ApplicantType.OCCUPANT
                )}
                disabled={disabled}
                applicantType={ApplicantType.OCCUPANT}
                openQuestionsModal={openQuestionsModal}
              />
            </Skeleton>
          ),
        },
        {
          key: 3,
          component: (
            <Skeleton loading={loading}>
              <AdditionalQuestionsTable
                objectId={dealSetup?.id}
                description="over 18 years old"
                questions={getAdditionalQuestions(
                  values,
                  ApplicantType.GUARANTOR
                )}
                disabled={disabled}
                applicantType={ApplicantType.GUARANTOR}
                openQuestionsModal={openQuestionsModal}
              />
            </Skeleton>
          ),
        },
      ]}
    />
  );
};

AdditionalQuestions.propTypes = {
  loading: PropTypes.bool,
  fetching: PropTypes.bool,
  openQuestionsModal: PropTypes.func,
  disabled: PropTypes.bool,
  dealSetup: PropTypes.shape({
    id: keyPropType,
    extraQuestionDetails: PropTypes.object,
  }),
};

AdditionalQuestions.defaultProps = {
  loading: false,
  fetching: false,
  openQuestionsModal: () => {},
  disabled: false,
  dealSetup: undefined,
};

export default AdditionalQuestions;
