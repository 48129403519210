import React, { CSSProperties } from "react";

import { animated, useSpring } from "@react-spring/web";

export interface RotateProps {
  on: boolean;
  degrees: number;
  children: React.ReactNode;
  style?: CSSProperties;
}

export const Rotate = ({ on, degrees, children, style }: RotateProps) => {
  const styles = useSpring({
    rotate: on ? degrees : 0,
  });

  return (
    <animated.div style={{ ...style, ...styles }}>{children}</animated.div>
  );
};
