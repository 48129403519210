import * as Yup from "yup";

import { REGEX } from "shared/config/constants";

const otpValidationSchema = Yup.object().shape({
  otpCode: Yup.string()
    .nullable()
    .min(6, "Must be exactly 6 digits")
    .max(6, "Must be exactly 6 digits")
    .matches(REGEX.otpCode, "Must be only digits"),
});

export { otpValidationSchema };
