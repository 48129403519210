import React from "react";

import { CreatePropertyModal } from "manager/components/Modal";
import { useHideBluemoonProperty } from "manager/hooks/api";
import Tooltip from "shared/components/Tooltip";
import useModal from "shared/hooks/useModal";

import BluemoonHiddenPropertiesModal from "../../components/BluemoonHiddenPropertiesModal";

import { PROPERTY_TYPE } from "./BluemoonPropertyBindings";
import {
  PropertyControlsContainer,
  LightUnderlinedLink,
  ClickableGreenLink,
} from "./styled";

const PropertySelectionControls = ({
  propertyType,
  selectedBluemoonProperty,
  refreshPropertiesCallback,
}) => {
  const { openModalDialog } = useModal();
  const { hideBluemoonProperty } = useHideBluemoonProperty();

  const openHiddenPropertiesModal = () =>
    openModalDialog(BluemoonHiddenPropertiesModal).afterClose.then(() =>
      refreshPropertiesCallback()
    );

  const hideProperty = () => {
    hideBluemoonProperty(selectedBluemoonProperty.id);
    refreshPropertiesCallback();
  };

  const showModal = () => {
    let initialValues = {};
    if (selectedBluemoonProperty) {
      const addressComponents = selectedBluemoonProperty.fullAddress.split(",");
      initialValues = {
        name: selectedBluemoonProperty.name,
        address: addressComponents[0].trim(),
        city: addressComponents[1].trim(),
        state: addressComponents[2].trim(),
        zipcode: addressComponents[3].trim(),
      };
    }
    openModalDialog(CreatePropertyModal, { initialValues }).afterClose.then(
      () => refreshPropertiesCallback()
    );
  };

  return (
    <PropertyControlsContainer>
      {propertyType === PROPERTY_TYPE.BLUEMOON ? (
        <Tooltip
          title="Removes property from the list and all warnings"
          placement="top"
        >
          <ClickableGreenLink onClick={hideProperty}>
            HIDE PROPERTY
          </ClickableGreenLink>
        </Tooltip>
      ) : (
        <Tooltip
          title="Create a new property in VERO to be bound"
          placement="top"
        >
          <ClickableGreenLink onClick={showModal}>
            + NEW PROPERTY
          </ClickableGreenLink>
        </Tooltip>
      )}
      {propertyType === PROPERTY_TYPE.BLUEMOON && (
        <LightUnderlinedLink onClick={openHiddenPropertiesModal}>
          View All Hidden
        </LightUnderlinedLink>
      )}
    </PropertyControlsContainer>
  );
};

export default PropertySelectionControls;
