import React from "react";
import _ from "lodash";
import { Row } from "antd";
import { Formik, Form, Field } from "formik";
import PropTypes from "prop-types";

import { Input } from "shared/components/Form";
import Settings from "shared/components/Settings";
import { useVeroFlags } from 'shared/utils/launchDarkly'

import ApplicationSetupHeader from "../Header";
import { StyledSkeleton as Skeleton } from "../styled";

import { Container, Title, TitleContainer, SubmitButton, ButtonCol, FieldCol } from "./styled";

const PaymentAccount = ({
  loading,
  paymentAccountMappings,
  createPaymentAccountMapping,
  location,
}) => {
  const { enablePaymentAccountMapping } = useVeroFlags()
  const paymentAccountMapping = _.chain(paymentAccountMappings).first().value()
  const paymentAccountMappingLocation = _.get(paymentAccountMapping, 'location')

  const onSubmit = (data, callbacks) => {
    const { setSubmitting, resetForm } = callbacks;
    setSubmitting(true);
    const onSettled = () => setSubmitting(false);
    const accountId = _.get(data, 'accountId');
    const onSuccess = () => {
      resetForm(data);
      window.location.reload();
    };
    createPaymentAccountMapping({ location, accountId }, { onSuccess, onSettled });
  }

  const locationsAreNotTheSame = location !== paymentAccountMappingLocation;
  const accountId = _.get(paymentAccountMapping, 'accountId');
  const propertyAccountIdPlaceholder = locationsAreNotTheSame ? undefined : accountId;

  const nodes = [
    {
      key: 1,
      component: (
        <Skeleton loading={loading}>
          <Container>
            <Formik
              initialValues={{}}
              onSubmit={onSubmit}
            >
              {({ dirty, isSubmitting, isValid, submitForm, values }) => (
                <Form>
                  <Row gutter={[48, 16]} type="flex">
                    {locationsAreNotTheSame && accountId &&
                      <FieldCol lg={8} md={12} sm={12} xs={24}>
                        <TitleContainer>
                          <Title>location: {paymentAccountMappingLocation}</Title>
                        </TitleContainer>
                        <Field
                          as={Input}
                          id="defaultAccountId"
                          name="defaultAccountId"
                          label="Default Stripe Connect Account Id"
                          value={accountId}
                          disabled
                        />
                      </FieldCol>
                    }
                    <FieldCol lg={8} md={12} sm={12} xs={24}>
                      <TitleContainer>
                        <Title>location: {location}</Title>
                      </TitleContainer>
                      <Field
                        as={Input}
                        id="accountId"
                        name="test"
                        label="Stripe Connect Account Id"
                        value={propertyAccountIdPlaceholder}
                        disabled={!enablePaymentAccountMapping}
                      />
                    </FieldCol>
                    <ButtonCol lg={8} md={12} sm={12} xs={24}>
                      <SubmitButton
                        type="primary"
                        disabled={_.chain(values).get('accountId').isEmpty().value() || !dirty || !isValid || !enablePaymentAccountMapping}
                        onClick={submitForm}
                        loading={isSubmitting}
                      >
                        Set Account Id
                      </SubmitButton>
                    </ButtonCol>
                  </Row>
                </Form>
              )}
            </Formik>
          </Container>
        </Skeleton>
      ),
    },
  ];

  return (
    <Settings
      header={
        <Skeleton loading={loading}>
          <ApplicationSetupHeader
            title="Payment Account"
            subTitle="Manage Stripe Connect account id"
          />
        </Skeleton>
      }
      nodes={nodes}
    />
  );
};

PaymentAccount.propTypes = {
  loading: PropTypes.bool,
  paymentAccountMappings: PropTypes.array,
  location: PropTypes.string,
  createPaymentAccountMapping: PropTypes.func,
};

PaymentAccount.defaultProps = {
  paymentAccountMappings: [],
  createPaymentAccountMapping: () => undefined,
  location: "",
};

export default PaymentAccount;
