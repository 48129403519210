import React from "react";

import { Field } from "formik";
import find from "lodash/find";
import PropTypes from "prop-types";

import { Select, Input, VeroFormField } from "shared/components/Form";

import { Container } from "./styled";

const ResidenceEmploymentDuration = ({
  durationTypeFieldName,
  durationValueFieldName,
  currentDurationType,
  onDurationTypeChange,
  durationValueError,
  durationTypes,
  isDurationTypesLoading,
  disabled,
}) => {
  const durationTypeLabel = find(durationTypes, [
    "id",
    currentDurationType,
  ])?.value;

  return (
    <Container>
      <div className="select-wrapper">
        <VeroFormField
          id={durationTypeFieldName}
          name={durationTypeFieldName}
          label="Duration Type"
          as={Select}
          onChange={onDurationTypeChange}
          disabled={disabled}
          loading={isDurationTypesLoading}
        >
          {durationTypes.map((item) => (
            <Select.Option key={item.id}>{item.value}</Select.Option>
          ))}
        </VeroFormField>
      </div>
      <Field
        as={Input}
        id={durationValueFieldName}
        name={durationValueFieldName}
        label={durationTypeLabel ? `Number of ${durationTypeLabel}` : ""}
        error={durationValueError}
        disabled={disabled || isDurationTypesLoading}
      />
    </Container>
  );
};

ResidenceEmploymentDuration.propTypes = {
  currentDurationType: PropTypes.string,
  durationTypeFieldName: PropTypes.string.isRequired,
  durationTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  durationValueError: PropTypes.string,
  durationValueFieldName: PropTypes.string.isRequired,
  onDurationTypeChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isDurationTypesLoading: PropTypes.bool.isRequired,
};

ResidenceEmploymentDuration.defaultProps = {
  durationTypes: [],
  currentDurationType: undefined,
  durationValueError: undefined,
  disabled: undefined,
};

export default ResidenceEmploymentDuration;
