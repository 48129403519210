import React from "react";

import Icon from "shared/components/Icon";
import { SIZES } from "shared/components/Modal";
import Spacer from "shared/components/Spacer";
import { RegularText } from "shared/components/Typography";

import { useDeviceType } from "shared/hooks";
import useModal from "shared/hooks/useModal";

import DisputeModal from "../DisputeModal";
import { StyledLink, StyledModal } from "../styled";

interface FailureModalProps {
  title: string;
  submit: () => void;
}

const FailureModal = ({ title, submit }: FailureModalProps) => {
  const { isMobile } = useDeviceType();
  const { openModalDialog } = useModal();
  const renderDisputeModal = () => openModalDialog(DisputeModal, { submit });

  return (
    <StyledModal
      title={title}
      titleIcon={<Icon.OrangeExclamationHollow width={32} height={32} />}
      submitButtonLabel="Back to Vero"
      submitButtonSize={SIZES.lg}
      isMobile={isMobile}
      submit={submit}
    >
      <Spacer size={Spacer.SIZES.sm} />
      <RegularText>
        It looks like VERO was unable to verify your identity. You will not be
        able to view applicant credit & background reports from Experian until
        you are verified.
      </RegularText>
      <Spacer size={Spacer.SIZES.md} />
      <StyledLink onClick={renderDisputeModal}>
        Contact support for assistance.
      </StyledLink>
      <Spacer size={Spacer.SIZES.md} />
    </StyledModal>
  );
};

export default FailureModal;
