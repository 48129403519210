import React from "react";

import styled from "@emotion/styled";

import { MEDIA_QUERIES } from "shared/config/constants";

import { combineComponents } from "shared/utils/combine-components";

import { AppList, AppListProps } from "./app-list";
import {
  ApproveDealModalProvider,
  MoreInfoDealModalProvider,
  CancelDealModalProvider,
  DeclineDealModalProvider,
} from "./modals";
import { FraudIndicatorsModalProvider } from "./modals/fraud-indicators-modal";
import { SendToEntradaModalProvider } from "./modals/send-to-entrada-modal";
import { ResendToPmsModalProvider } from "./modals/resend-to-pms-modal";

export type AppSummaryProps = AppListProps;

const Container = styled.div`
  padding: 25px;
  ${MEDIA_QUERIES.w.ltSm} {
    padding: 8px;
  }
`;

const providers = [
  FraudIndicatorsModalProvider,
  ApproveDealModalProvider,
  MoreInfoDealModalProvider,
  CancelDealModalProvider,
  DeclineDealModalProvider,
  SendToEntradaModalProvider,
  ResendToPmsModalProvider,
];

const Providers = combineComponents(...providers);

export const AppSummary: React.FC<AppSummaryProps> = ({ dealId }) => {
  return (
    <Container>
      <Providers>
        <AppList dealId={dealId} />
      </Providers>
    </Container>
  );
};
