import React from "react";

import dayjs from "dayjs";

import {
  ApplicationSummaryCard,
  CardSize,
  CardStatus,
} from "shared/components/ApplicationSummaryCard";
import {
  ApplicationSummaryTooltip,
  TooltipColumnWidth,
} from "shared/components/ApplicationSummaryTooltip";
import { SHORT_DATE } from "shared/config/constants";
import { LynkSkipped, LynkSuccess } from "shared/icons";

import { EMPTY_INDICATOR, getValue } from "../utils";

export enum EmploymentStatus {
  Employed = "Employed",
  Student = "Student",
  Unemployed = "Unemployed",
}

export interface EmploymentCardProps {
  enabled: boolean;
  skipped?: boolean;
  jobTitle?: string;
  verificationType?: string;
  company?: string;
  status?: EmploymentStatus;
  start?: dayjs.Dayjs;
  end?: dayjs.Dayjs;
  onClick?: () => void;
  lumenEnabled?: boolean;
}

export const EmploymentCard = ({
  skipped,
  jobTitle,
  company,
  status = EmploymentStatus.Unemployed,
  start,
  end,
  verificationType,
  onClick,
  lumenEnabled,
}: EmploymentCardProps) => {
  const currentEmployment = () => {
    if (!status || status === EmploymentStatus.Unemployed) {
      return EmploymentStatus.Unemployed;
    }
    return `${status} - ${jobTitle || "N/A"}`;
  };
  return (
    <ApplicationSummaryTooltip
      layout={[
        {
          title: "Current Employment:",
          subtitle: currentEmployment(),
          width: TooltipColumnWidth.full,
        },
        {
          title: "Company:",
          subtitle: getValue(company),
          width: TooltipColumnWidth.half,
        },
        {
          title: "Employment Date:",
          subtitle:
            !start && !end
              ? EMPTY_INDICATOR
              : `${
                  start ? dayjs(start).format(SHORT_DATE) : EMPTY_INDICATOR
                } - ${end ? dayjs(end).format(SHORT_DATE) : "Current"}`,
          width: TooltipColumnWidth.half,
        },
        {
          title: "Verification Type:",
          subtitle: getValue(verificationType),
          width: TooltipColumnWidth.full,
        },
      ]}
    >
      <div>
        <ApplicationSummaryCard
          onClick={onClick}
          title="Employment"
          size={CardSize.Small}
          status={skipped ? CardStatus.warn : CardStatus.success}
          hideColor={lumenEnabled}
          logo={
            skipped ? (
              <LynkSkipped small={false} />
            ) : (
              <LynkSuccess small={false} />
            )
          }
        />
      </div>
    </ApplicationSummaryTooltip>
  );
};
