import React from "react";

import isNumber from "lodash/isNumber";

import { AddMoreLeadsUnderlineLink } from "./styled";
import { emptyLead, Lead } from "./utils";

interface AddMoreLeadsLinkProps {
  leads: Lead[];
  addLead: (lead: Lead) => void;
  disabled?: boolean;
}

const AddMoreLeadsLink = ({
  leads,
  addLead,
  disabled,
}: AddMoreLeadsLinkProps) => {
  const onClick = () => {
    if (leads.every(({ id }) => isNumber(id))) {
      addLead(emptyLead);
    }
  };

  return (
    <AddMoreLeadsUnderlineLink onClick={onClick} disabled={disabled} dashed>
      Add More
    </AddMoreLeadsUnderlineLink>
  );
};

export default AddMoreLeadsLink;
