import React from "react";

import PropTypes from "prop-types";

import { keyPropType } from "shared/utils/propTypes";

import { Item, Label } from "./styled";

interface MenuOptionProps {
  item: {
    key: string | number;
    label: Element | string | any;
    rightSide?: React.ReactNode;
  };
  isActive: (key: any) => boolean;
  itemClick: (item) => React.MouseEventHandler<HTMLLIElement>;
  baseActiveKey?: string | number;
}

const MenuOption = ({
  item,
  isActive,
  itemClick,
  baseActiveKey,
}: MenuOptionProps) => (
  <Item
    active={item.key === baseActiveKey || isActive(item)}
    onClick={itemClick(item)}
    // @ts-ignore
    onKeyPress={itemClick(item)}
    role="button"
    tabIndex={0}
    data-testid={`item-${item.key}`}
    className={item.rightSide ? "flex-container" : ""}
  >
    <Label>{item.label}</Label>
    {item.rightSide}
  </Item>
);

MenuOption.propTypes = {
  item: PropTypes.shape({
    key: keyPropType.isRequired,
    label: PropTypes.node.isRequired,
    rightSide: PropTypes.node,
  }).isRequired,
  isActive: PropTypes.func.isRequired,
  itemClick: PropTypes.func.isRequired,
  baseActiveKey: keyPropType,
};

MenuOption.defaultProps = {
  baseActiveKey: undefined,
};

export default MenuOption;
