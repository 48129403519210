import styled from "@emotion/styled";

import { CenteredFlexPartial } from "shared/styles/commonStyles";

const YardiImportPropertyConfirmationContent = styled("div")({
  ...CenteredFlexPartial,
  flexDirection: "column",
  ".success-text": {
    fontWeight: 500,
    margin: "24px 0",
  },
});

export { YardiImportPropertyConfirmationContent };
