import React, { useContext } from "react";

import { ModalManagerContext } from "shared/utils/modalmanager";

import { Modal as ModalBase, ModalProps } from "./Modal";

export type ModalContainerProps = Omit<ModalProps, "close" | "afterClose"> & {
  close?: () => void;
  afterClose?: () => void;
};

const _ModalContainer: React.FC<ModalContainerProps> = (props) => {
  let visible;
  let context;
  let close;
  let afterClose;

  const modalManagerContext = useContext(ModalManagerContext);
  const openedWithModalManager = modalManagerContext?.fromModalManager;
  if (openedWithModalManager) {
    visible = modalManagerContext.visible;
    context = modalManagerContext.context;
    close = () => modalManagerContext.selfApi.closeDialog();
    afterClose = () => modalManagerContext.selfApi.destroyDialog();
  }

  const newProps = {
    ...props,
    visible,
    context,
    close,
    afterClose,
  };

  return <ModalBase {...newProps} />;
};

export const ModalContainer = Object.assign(_ModalContainer, {
  Body: ModalBase.Body,
});
