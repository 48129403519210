export enum CardSize {
  Large = "large",
  Small = "small",
}

export enum CardStatus {
  lumenSuccess = "lumenSuccess",
  lumenGood = "lumenGood",
  success = "success",
  warn = "warn",
  error = "error",
  unavailable = "unavailable",
}
