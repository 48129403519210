import React from "react";

import styled from "@emotion/styled";
import { Input as BaseInput } from "antd";

import { COLORS, TRANSITIONS, INPUT_SIZES } from "shared/config/constants";

import { InputProps } from "./Input";

export const specs = {
  horizontalPadding: "16px",
};

export const inputStyles = (props: InputStyledProps) => ({
  padding: `12px ${specs.horizontalPadding}`,
  borderRadius: "2px",
  ...(props.size === INPUT_SIZES.lg && {
    paddingLeft: "24px",
    paddingRight: "24px",
    height: "57px",
  }),
  borderColor: COLORS.input.default.border,
  lineHeight: 1,
  color: COLORS.input.default.text,
  transition: TRANSITIONS.default,
  "&:disabled": {
    backgroundColor: COLORS.input.disabled.background,
    color: `${COLORS.input.disabled.text} !important`,
  },
  "&:invalid, &:focus:invalid": {
    borderColor: `${COLORS.input.invalid.border} !important`,
  },
  "&:focus, &:invalid": {
    boxShadow: "none",
    outline: "none",
  },
  "&:hover": {
    borderColor: COLORS.input.hover.border,
  },
  "&:focus": {
    borderColor: `${COLORS.input.focus.border} !important`,
    text: COLORS.input.focus.border,
  },
  "&[type=number]": {
    MozAppearance: "textfield !important",
    WebkitAppearance: "none",
  },
});

export type InputStyledProps = {
  withContainer?: boolean;
  size?: INPUT_SIZES;
};

export const getInputStyles = (props: InputStyledProps) => ({
  // If the input has a container, the `input` element will be a child
  ...(props.withContainer
    ? {
        input: inputStyles(props),
      }
    : inputStyles(props)),
  ".ant-input-clear-icon": {
    marginRight: "5px",
  },
});

export const StyledInput = styled(
  // eslint-disable-next-line react/prop-types,@typescript-eslint/no-unused-vars
  React.forwardRef<BaseInput, InputStyledProps & InputProps>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ({ withContainer, ...props }, ref) => {
      return <BaseInput {...props} ref={ref} />;
    }
  )
  // @ts-ignore
)(getInputStyles);

export const PopoverContent = styled("div")({
  padding: "13px 20px",
  maxWidth: "290px",
});
