import React from "react";

import { useGetLedgerRecords } from "manager/hooks/api";

import LedgerTable from "./LedgerTable";

const LedgerTableContainer = ({ application }) => {
  if (application) {
    const { records, isLoading } = useGetLedgerRecords({ application });

    return <LedgerTable loading={isLoading} records={records} />;
  }

  return <LedgerTable records={[]} />;
};

export default LedgerTableContainer;
