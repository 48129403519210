import React, { useRef } from "react";

import { FormikProps } from "formik";
import { useHistory } from "react-router-dom";

import ROUTES from "manager/config/routes";
import { useSendPasswordRecoveryEmail } from "shared/hooks/api";
import { PasswordRecovery } from "shared/interfaces/api/password";

import PasswordRecoveryPage from "./PasswordRecoveryPage";

const PasswordRecoveryContainer = () => {
  const history = useHistory();
  const formikRef = useRef<FormikProps<PasswordRecovery>>();

  const onSuccess = () =>
    history.push({
      pathname: ROUTES.passwordRecoveryEmailSent,
      state: { email: formikRef.current.values.email },
    });

  const { sendPasswordRecoveryEmail } = useSendPasswordRecoveryEmail({
    onSuccess,
  });

  const submit = ({ email }: PasswordRecovery) => {
    sendPasswordRecoveryEmail({ email });
  };

  return <PasswordRecoveryPage submit={submit} formikRef={formikRef} />;
};

export default PasswordRecoveryContainer;
