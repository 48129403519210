import React, { useMemo, useState } from "react";

import { useParams } from "react-router-dom";

import { CreatePropertyModal } from "manager/components/Modal";
import ROUTES from "manager/config/routes";
import {
  useGetAllPropertiesSimple,
  useGetYardiProperty,
  useUpdateYardiProperty,
} from "manager/hooks/api";
import { useGetIntegrationProvider } from "manager/pages/Marketplace/config";
import useModal from "shared/hooks/useModal";
import { BackLinkProps } from "shared/interfaces/misc";
import { transformRoute } from "shared/utils/routing";
import { areSameAsStrings, stringify } from "shared/utils/string";

import SetupYardiProperty from "./SetupYardiProperty";

interface SetupYardiPropertyContainerProps {
  backLink: BackLinkProps;
}

const SetupYardiPropertyContainer = ({
  backLink,
}: SetupYardiPropertyContainerProps) => {
  const {
    propertyId: currentYardiPropertyId,
  }: { propertyId?: number | string } = useParams();
  const { openModalDialog } = useModal();
  const { provider } = useGetIntegrationProvider();

  const { data: veroProperties, refetch: refetchVeroProperties } =
    useGetAllPropertiesSimple();
  const { yardiProperty: currentYardiProperty } = useGetYardiProperty(
    provider,
    currentYardiPropertyId
  );

  const { updateYardiProperty } = useUpdateYardiProperty();

  const [selectedVeroPropertyId, setSelectedVeroPropertyId] = useState(null);

  const selectedVeroProperty = useMemo(
    () =>
      (veroProperties || []).find((p) =>
        areSameAsStrings(p.id, selectedVeroPropertyId)
      ),
    [veroProperties, selectedVeroPropertyId]
  );

  const selectProperty = (propId) => {
    setSelectedVeroPropertyId(stringify(propId));
  };

  const createNewProperty = () => {
    const afterPropertyCreate = async (newProperty) => {
      await refetchVeroProperties();
      selectProperty(newProperty.id);
    };

    const initialValues = {
      name: currentYardiProperty?.name,
      address: currentYardiProperty?.address,
      city: currentYardiProperty?.city,
      state: currentYardiProperty?.state,
      zipcode: currentYardiProperty?.zipcode,
    };
    const subtitle = "Please confirm or edit the info about property";
    const context = { initialValues, afterPropertyCreate, subtitle };
    openModalDialog(CreatePropertyModal, context);
  };

  const setupCompleted = async () => {
    try {
      // @ts-ignore
      await updateYardiProperty({
        provider,
        id: currentYardiPropertyId,
        veroPropertyId: selectedVeroPropertyId,
      });
      const propertySetupUrl = transformRoute(
        ROUTES.integrationConfigProperty,
        {
          provider,
          propertyId: currentYardiPropertyId,
        }
      );
      // history.push(propertySetupUrl); // loading the page this way causes a crash.
      window.location.href = propertySetupUrl;
    } catch {
      // NOP
    }
  };

  const props = {
    selectProperty,
    createNewProperty,
    setupCompleted,
    selectedVeroProperty,
    veroProperties,
    currentYardiProperty,
    backLink,
  };
  return <SetupYardiProperty {...props} />;
};

export default SetupYardiPropertyContainer;
