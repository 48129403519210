import styled from "@emotion/styled";

import { ActionLink } from "shared/components/Links";

export const AddApplicantLink = styled(ActionLink)`
  font-size: 12px;
  display: inline-block;
  margin-top: 16px;
  &:not(:last-of-type) {
    margin-right: 24px;
  }
`;
