const DOWNLOAD_OPTIONS = Object.freeze({
  LINKS: "url",
  QR: "qr_code",
});

const DOWNLOAD_PROPERTIES_OPTIONS = Object.freeze({
  ALL_PROPERTIES: "properties",
  ALL_UNITS: "units",
});

export { DOWNLOAD_OPTIONS, DOWNLOAD_PROPERTIES_OPTIONS };
