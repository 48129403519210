import React from "react";

import { FormikErrors, FormikProps } from "formik";

import { APPLICANT_TYPES } from "shared/config/constants";

export interface BackLinkProps {
  children: string;
  to?: string;
  onClick?: any;
}

export interface SelectItem {
  key: string | number;
  label: React.ReactNode;
}

export enum ApplicantType {
  APPLICANT = APPLICANT_TYPES.applicant,
  OCCUPANT = APPLICANT_TYPES.occupant,
  GUARANTOR = APPLICANT_TYPES.guarantor,
}

export const applicantType = {
  APPLICANT: "applicant",
  OCCUPANT: "occupant",
  GUARANTOR: "guarantor",
  PRIMARY_APPLICANT: "primary-applicant",
};

export const ApplicantTypePartyMap = {
  [`${APPLICANT_TYPES.applicant}`]: applicantType.APPLICANT,
  [`${APPLICANT_TYPES.occupant}`]: applicantType.OCCUPANT,
  [`${APPLICANT_TYPES.guarantor}`]: applicantType.GUARANTOR,
};

type VeroFormikErrors<T> = FormikErrors<T> & {
  nonFieldErrors?: string[];
};

export interface VeroFormikProps<T> extends Omit<FormikProps<T>, "errors"> {
  errors: VeroFormikErrors<T>;
}
