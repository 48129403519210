import { LabelHTMLAttributes } from "react";

import { css } from "@emotion/core";
import styled from "@emotion/styled";

import { SmallErrorText } from "shared/components/Typography";
import { COLORS, TRANSITIONS, INPUT_SIZES } from "shared/config/constants";

export const Container = styled.div`
  position: relative;
`;

export interface LabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
  disabled?: boolean;
  focused?: boolean;
  value?: boolean;
  valid?: boolean;
  violetTheme?: boolean;
  size?: "default" | "large";
}

export const Label = styled.label<LabelProps>`
  position: absolute;
  z-index: 3;
  top: 20px;
  left: 16px;
  transform: translateY(-50%);
  color: ${COLORS.input.default.placeholder};
  transition: ${TRANSITIONS.default};
  font-size: 15px;
  line-height: 12px;
  padding: 0 2px;
  border-radius: 4px;
  pointer-events: none;

  ${(props) =>
    props.size === INPUT_SIZES.lg &&
    css`
      font-size: 17px;
      left: 24px;
      top: 28px;
    `};

  ${(props) =>
    !!props.disabled &&
    css`
      cursor: not-allowed;
      color: ${COLORS.input.disabled.placeholder};
    `};

  ${(props) =>
    (!!props.focused || !!props.value) &&
    props.valid === false &&
    css`
      color: ${COLORS.input.invalid.placeholder} !important;
    `};

  ${(props) =>
    (!!props.focused || !!props.value) &&
    css`
      background-color: ${COLORS.white};
      top: 0;
      font-size: 12px;
      ${props.size === INPUT_SIZES.lg &&
      css`
        font-size: 14px;
      `}
    `};

  ${(props) =>
    props.violetTheme &&
    css`
      background-color: ${COLORS.violetTheme.deepBlueViolet};
      color: ${COLORS.violetTheme.lightGrey};
    `}
`;

export const Error = SmallErrorText;

export const OptionalText = styled.div`
  font-weight: 300;
  font-size: 12px;
  color: ${COLORS.secondary};
  margin-top: 3px;
`;
