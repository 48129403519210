/* eslint-disable react/no-array-index-key */
import React from "react";

import { Row, Col } from "antd";
import isString from "lodash/isString";

import DeleteTitle from "shared/components/DeleteTitle";
import ErrorList from "shared/components/ErrorList";
import { Input, Select, VeroFormField } from "shared/components/Form";

import { InviteApplicantsFormProps } from "./interfaces";

const SIZES = {
  small: "sm",
  default: "default",
};

export const InviteApplicantsForm = ({
  applicantTypes,
  applicantTypesLoading,
  applicants,
  deleteApplicant,
  errors,
  size = SIZES.default,
  label = "Applicant",
  showPmsIdSection = false,
}: InviteApplicantsFormProps) => {
  const onDelete = (index: number) => () => deleteApplicant(index);
  const generalError = isString(errors) && errors;

  let emailSize: number;
  let nameSize: number;

  switch (size) {
    case SIZES.small:
      emailSize = 24;
      nameSize = 12;
      break;
    default:
      emailSize = 8;
      nameSize = 8;
  }

  return (
    <div data-testid="invite-applicants-form">
      {generalError && <ErrorList errors={[generalError]} />}
      {applicants.map((applicant, index) => (
        <div key={index} data-testid={`applicant-${index}`}>
          {index > 0 && <br />}
          {applicants.length > 1 && (
            <DeleteTitle action={onDelete(index)}>
              {`${label} #${index + 1}`}
            </DeleteTitle>
          )}
          <Row gutter={[20, 25]}>
            <Col md={{ span: emailSize }}>
              <VeroFormField
                as={Input}
                id={`applicants.${index}.email`}
                name={`applicants.${index}.email`}
                label="Email"
                required
                type="email"
              />
            </Col>
            <Col md={nameSize}>
              <VeroFormField
                as={Input}
                id={`applicants.${index}.firstName`}
                name={`applicants.${index}.firstName`}
                label="First Name"
                required
                type="text"
              />
            </Col>
            <Col md={nameSize}>
              <VeroFormField
                as={Input}
                id={`applicants.${index}.lastName`}
                name={`applicants.${index}.lastName`}
                label="Last Name"
                required
                type="text"
              />
            </Col>
          </Row>
          {(applicantTypes?.length > 0 || showPmsIdSection) && (
            <Row gutter={[20, 25]}>
              {applicantTypes?.length > 0 && (
                <Col md={nameSize}>
                  <VeroFormField
                    as={Select}
                    id={`applicants.${index}.applicantType`}
                    name={`applicants.${index}.applicantType`}
                    label="Applicant Type"
                    loading={applicantTypesLoading}
                  >
                    {applicantTypes.map((applicantType) => (
                      <Select.Option key={applicantType.id}>
                        {applicantType.value}
                      </Select.Option>
                    ))}
                  </VeroFormField>
                </Col>
              )}
              {showPmsIdSection && (
                <Col md={nameSize}>
                  <VeroFormField
                    as={Input}
                    id={`applicants.${index}.externalPMSId`}
                    name={`applicants.${index}.externalPMSId`}
                    label="Applicant PMS ID"
                    type="text"
                  />
                </Col>
              )}
            </Row>
          )}
        </div>
      ))}
    </div>
  );
};

InviteApplicantsForm.SIZES = SIZES;
