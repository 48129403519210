import React from "react";

import { Col, Row } from "antd";

import { fullApplicationPropType } from "manager/utils/propTypes";
import Icon from "shared/components/Icon";
import Tile from "shared/components/Tile";
import {
  VerificationItemTile,
  VerificationStatusShell,
} from "shared/components/Verification";
import { VERIFICATION_STATUSES } from "shared/config/constants";
import { getSkippedIncomeAssetsInfo } from "shared/utils/application";

import { Balances } from "./Balances";
import { Income } from "./Income";

const INCOME_VERIFICATION = "income verification";
const BALANCES_VERIFICATION = "balances verification";

const getVerificationStatusData = (applicationSummary, incomes) => {
  let status = "Unverified";
  let StatusIcon = Icon.CrossRedSmall;
  let tooltipMessage = "";

  const isIncomeVerified =
    applicationSummary?.income?.status === VERIFICATION_STATUSES.VERIFIED ||
    incomes?.length > 0;
  const areBalancesVerified =
    applicationSummary?.assets?.status === VERIFICATION_STATUSES.VERIFIED;
  const skippedIncome = applicationSummary?.skippedIncome;
  const skippedBalances = applicationSummary?.skippedAsset;
  const areIncomeAndAssetsEnabled =
    applicationSummary?.areIncomeAndAssetChecksEnabled;

  if (skippedIncome && skippedBalances) {
    status = "Skipped";
    tooltipMessage = "Lead skipped income verification";
    StatusIcon = Icon.OrangeExclamationSmall;
  } else if (
    (!areIncomeAndAssetsEnabled && isIncomeVerified) ||
    (areIncomeAndAssetsEnabled && isIncomeVerified && areBalancesVerified)
  ) {
    status = "Verified";
    StatusIcon = Icon.CheckGreenSmall;
  } else if (
    areIncomeAndAssetsEnabled &&
    (isIncomeVerified || areBalancesVerified)
  ) {
    status = "Partially Skipped";
    StatusIcon = Icon.OrangeExclamationSmall;
    let completedVerification;
    let skippedVerification;

    if (isIncomeVerified) {
      completedVerification = INCOME_VERIFICATION;
    } else if (areBalancesVerified) {
      completedVerification = BALANCES_VERIFICATION;
    }

    if (skippedIncome) {
      skippedVerification = INCOME_VERIFICATION;
    } else if (skippedBalances) {
      skippedVerification = BALANCES_VERIFICATION;
    }

    if (completedVerification && skippedVerification) {
      tooltipMessage = `Lead skipped ${skippedVerification} but completed ${completedVerification}`;
    }
  }

  return {
    status,
    tooltipMessage,
    StatusIcon,
  };
};

const IncomeBalancesVerification = ({ lead }) => {
  const snapshot = lead?.snapshot;
  const applicationSummary = lead?.applicationSummary;
  const summary = lead?.incomeAsset;
  const incomes = summary?.incomes;
  const accounts = summary?.accounts;
  const isIncomeEdited =
    summary?.incomeVerificationStatus === VERIFICATION_STATUSES.EDITED;
  const isAssetsEdited =
    summary?.assetsVerificationStatus === VERIFICATION_STATUSES.EDITED;
  const income = isIncomeEdited
    ? summary?.editedIncome
    : summary?.totalIncome || summary?.totalActiveIncome;
  const assets = isAssetsEdited ? summary?.editedAssets : summary?.totalAssets;

  const skippedIncomeAssets = getSkippedIncomeAssetsInfo(
    summary?.skippedReason,
    summary?.bankName
  );

  // NOTE: if there's an auto-linked employment (Argyle), we show the Income summary and Balances summary in a single row
  const singleRowData = applicationSummary?.hasAutoLinkedEmployment;

  const { status, tooltipMessage, StatusIcon } = getVerificationStatusData(
    applicationSummary,
    incomes
  );

  return (
    <VerificationItemTile
      verificationStatus={
        <VerificationStatusShell
          typeLabel="Income & Balances"
          statusLabel={status}
          StatusIcon={StatusIcon}
          tooltipMessage={tooltipMessage}
          verificationDate={lead?.submittedAt}
        />
      }
    >
      <Tile.Inner inset>
        <Row gutter={14} type="flex">
          <Col xs={{ span: 24 }} xl={{ span: 16 }}>
            <Income
              income={income}
              summary={summary}
              applicationSummary={applicationSummary}
              skippedIncomeAssets={skippedIncomeAssets}
              snapshot={snapshot}
            />
          </Col>
          <Col xs={{ span: 24 }} xl={{ span: 8 }}>
            <Balances
              assets={assets}
              accounts={accounts}
              summary={summary}
              applicationSummary={applicationSummary}
              skippedIncomeAssets={skippedIncomeAssets}
              singleRowData={singleRowData}
            />
          </Col>
        </Row>
      </Tile.Inner>
    </VerificationItemTile>
  );
};

IncomeBalancesVerification.propTypes = {
  lead: fullApplicationPropType,
};

IncomeBalancesVerification.defaultProps = {
  lead: undefined,
};

export default IncomeBalancesVerification;
