import React, { useState, useEffect } from "react";

import { useFormikContext } from "formik";
import get from "lodash/get";

import { isEmptyValue } from "shared/utils/misc.util";

import { InputProps } from "../Input";

import { MoneyInputProps } from "./MaskedInput";

export type TextFieldProps = InputProps & {
  name: string;
  unmask?: (value: string) => string;
  onAfterChange?: (...any) => void;
};

type InputComponent = ((t: MoneyInputProps) => JSX.Element) & {
  unmask?: (value: string) => number;
  convertInitialValue?: (value: string) => string;
};

export const getFormikMaskedInput = (InputComponent: InputComponent) => {
  const TextField: React.FC<MoneyInputProps & TextFieldProps> = ({
    name,
    unmask = (value) => value,
    onAfterChange,
    ...props
  }) => {
    const uniqueGuid = `${name}_${Date.now()}`;
    const { setFieldValue, values, errors, touched, setFieldTouched } =
      useFormikContext();
    const currentValue = get(values, name);
    const [maskedValue, setMaskedValue] = useState("");
    const unmaskValue = InputComponent.unmask || unmask;
    const isRestarted = unmaskValue(maskedValue) !== currentValue;

    const onChange = (event) => {
      const rawValue = unmaskValue
        ? unmaskValue(event.target.value)
        : event.target.value;

      const formikValue = get(values, name);
      if (
        rawValue !== formikValue &&
        (!isEmptyValue(rawValue) || !isEmptyValue(formikValue))
      ) {
        setFieldValue(name, rawValue);
      }

      setMaskedValue(event.target.value);
      onAfterChange?.(event, event.target.value, rawValue);
    };

    const onBlur = () => setFieldTouched(name, true);

    // TODO: Hanna - consider refactoring of this component to use refs

    /* Note: Triggers 'onChange' initial value manually on 'onComponentDidMount'
       Raise 'input' event manually: https://github.com/facebook/react/issues/11488#issuecomment-347775628
    */
    useEffect(() => {
      if (isRestarted) {
        const input = document.querySelector(
          `[unique_id="${uniqueGuid}"]`
        ) as HTMLInputElement & { _valueTracker: any };
        const lastValue = input.value;
        input.value =
          InputComponent.convertInitialValue && currentValue
            ? InputComponent.convertInitialValue(currentValue)
            : currentValue;
        const event = new Event("input", { bubbles: true }) as any;
        event.simulated = true;
        /* eslint-disable-next-line no-underscore-dangle */
        const tracker = input._valueTracker;

        if (tracker) {
          tracker.setValue(lastValue);
        }

        input.dispatchEvent(event);
      }

      // NOTE: using `useEffect` with `currentValue` as dependency for the following reason (previously `isRestarted` was a dep):
      // isRestarted = unmaskValue(maskedValue) !== currentValue
      // if the first item is `null` and the second one is `undefined` (can happen during initialization)
      // then `isRestarted` is true, on the second re-render when we have the actual `currentValue`,
      // isRestarted is again `true` but the effect will not run because it's the same value as the previous render.
    }, [currentValue]);

    return (
      <InputComponent
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={maskedValue}
        error={get(touched, name) && get(errors, name)}
        // @ts-ignore
        unique_id={uniqueGuid}
        {...props}
      />
    );
  };

  Object.keys(InputComponent).forEach((property) => {
    TextField[property] = InputComponent[property];
  });

  return TextField;
};
