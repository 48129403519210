import React, { useRef } from "react";

import PropTypes from "prop-types";

import { useUploadValidation } from "shared/hooks/useUploadValidation";

import { FileInput } from "./styled";

interface FileSelectProps {
  onFileSelect: ((file: FileList) => void) | ((files: File[]) => void);
  acceptFormats?: string;
  Node: Element | React.ReactNode;
  innerProps?: any;
  disabled?: boolean;
}

const FileSelect = ({
  onFileSelect,
  acceptFormats = undefined,
  Node,
  innerProps = {},
  disabled = false,
}: FileSelectProps) => {
  const fileRef: any = useRef();
  const { isFileNameValid } = useUploadValidation();

  const onClick = () => fileRef?.current?.click();

  const onChange = (event) => {
    const [file] = event.target.files;
    if (!isFileNameValid(file.name, true)) {
      return;
    }

    onFileSelect(event.target.files);
    // eslint-disable-next-line no-param-reassign
    event.target.value = "";
  };

  return (
    <>
      <FileInput
        type="file"
        ref={fileRef}
        onChange={onChange}
        accept={acceptFormats}
        id="file-select"
        disabled={disabled}
      />
      {/* @ts-ignore */}
      <Node onClick={onClick} {...innerProps} />
    </>
  );
};
FileSelect.propTypes = {
  onFileSelect: PropTypes.func.isRequired,
  acceptFormats: PropTypes.string,
  Node: PropTypes.elementType.isRequired,
  innerProps: PropTypes.object,
  disabled: PropTypes.bool,
};

FileSelect.defaultProps = {
  acceptFormats: undefined,
  innerProps: {},
  disabled: false,
};

export default FileSelect;
