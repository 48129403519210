import React from "react";

import { Button } from "shared/components/Button";
import FlexContainer from "shared/components/FlexContainer";
import { TextArea } from "shared/components/Form";
import Icon from "shared/components/Icon";
import { BackLink, UnderlineLink } from "shared/components/Links";
import StepsWheel, { STEPS_WHEEL_THEMES } from "shared/components/StepsWheel";
import Tile from "shared/components/Tile";
import {
  LargeTitle,
  RegularText,
  WeakText,
} from "shared/components/Typography";
import { SurveyQuestionModel } from "shared/interfaces/api";

import { QuestionStepWrapper, Answer } from "./styled";

interface QuestionStepProps {
  applicantName: string;
  questionCount: number;
  surveyQuestion: SurveyQuestionModel;
  setSurveyQuestion: Function;
  showCommentField: boolean;
  setShowCommentField: Function;
  onNext: Function;
  onBack: Function;
  loading: boolean;
}

const QuestionStep = ({
  applicantName,
  questionCount,
  surveyQuestion,
  setSurveyQuestion,
  showCommentField,
  setShowCommentField,
  onNext,
  onBack,
  loading,
}: QuestionStepProps) => (
  <QuestionStepWrapper>
    {/* @ts-ignore */}
    <Tile alt noBorderBottom noMarginBottom className="header">
      <Tile.Inner className="content">
        {/* @ts-ignore */}
        <StepsWheel
          current={surveyQuestion?.position - 1}
          items={Array.from({ length: questionCount }, () => ({
            label: (
              <LargeTitle className="title">Complete the survey</LargeTitle>
            ),
            desc: (
              // @ts-ignore
              <RegularText className="subtitle" weak>
                Verification of Residency for: <b>{applicantName}</b>
              </RegularText>
            ),
          }))}
          showNext={false}
          showDesc
          theme={STEPS_WHEEL_THEMES.darkWheelDarkBackground}
          className="steps-wheel"
        />
      </Tile.Inner>
    </Tile>
    {/* @ts-ignore */}
    <Tile noBorderBottom noMarginBottom className="content">
      <LargeTitle weak className="question">
        {surveyQuestion?.question}
      </LargeTitle>
      <RegularText weak>
        Review the statement and rank your agreement
      </RegularText>
      <div className="answers-container">
        {Array.from({ length: 10 }, (_, i) => (
          <React.Fragment key={`answer-${i}`}>
            {/* @ts-ignore */}
            <Answer
              onClick={() =>
                setSurveyQuestion({ ...surveyQuestion, answer: `${i + 1}` })
              }
              active={surveyQuestion?.answer === `${i + 1}`}
            >
              {i + 1}
            </Answer>
            <br />
          </React.Fragment>
        ))}
        <FlexContainer justifyContent="space-between">
          <WeakText>Disagree</WeakText>
          <WeakText>Agree</WeakText>
        </FlexContainer>
      </div>
      <div className="comment-link">
        {/* @ts-ignore */}
        <UnderlineLink onClick={() => setShowCommentField(true)}>
          INCLUDE A COMMENT
        </UnderlineLink>
        {(showCommentField || surveyQuestion?.comment) && (
          // @ts-ignore
          <TextArea
            value={surveyQuestion?.comment}
            id="comment"
            onChange={({ target: { value } }) =>
              setSurveyQuestion({ ...surveyQuestion, comment: value })
            }
            placeholder="Message text (optional)"
          />
        )}
      </div>
      <FlexContainer justifyContent="space-between">
        {surveyQuestion?.position !== 1 && (
          <BackLink onClick={onBack} size="lg" disabled={loading}>
            Back
          </BackLink>
        )}
        {/* @ts-ignore */}
        <Button
          type="primary"
          SuffixIcon={
            questionCount !== surveyQuestion?.position
              ? Icon.ButtonForwardArrowIcon
              : null
          }
          disabled={!surveyQuestion?.answer}
          onClick={onNext}
          className="next-btn"
          loading={loading}
        >
          {questionCount === surveyQuestion?.position ? "Submit" : "Next"}
        </Button>
      </FlexContainer>
    </Tile>
  </QuestionStepWrapper>
);

export default QuestionStep;
