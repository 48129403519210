import styled from "@emotion/styled";

import Icon from "shared/components/Icon";
import { Z_INDEX } from "shared/config/constants";

const BigLoadingSpinner = styled(Icon.LoadingSpinnerGreenLargeIcon)`
  position: absolute;
  top: calc(50% - 35px);
  left: calc(50% - 35px);
  z-index: ${Z_INDEX.loader};
`;

export { BigLoadingSpinner };
