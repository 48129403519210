import React from "react";

import EmployeePropertiesModal from "./EmployeePropertiesModal";

const AssignEmployeeToPropertiesModal = ({
  firstName,
  lastName,
  portfolios,
  onSubmit,
  isMobile,
  loading,
  submitting,
}) => (
  <EmployeePropertiesModal
    title="Manage employee access to properties"
    submitButtonLabel="Assign"
    items={portfolios}
    fullName={`${firstName} ${lastName}`}
    helpText="Select properties or entire portfolios to which you want the employee
      to be assigned for"
    onSubmit={onSubmit}
    isMobile={isMobile}
    loading={loading}
    submitting={submitting}
  />
);

export default AssignEmployeeToPropertiesModal;
