/* eslint-disable react/prop-types */
import React from "react";

import range from "lodash/range";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";

import UnitTile from "renter/components/MagicPortal/UnitTile";
import { Button } from "shared/components/Button";
import FlexContainer from "shared/components/FlexContainer";
import FooterBar from "shared/components/FooterBar";
import { Select, MaskedInput } from "shared/components/Form";
import MobileFiltersHeader from "shared/components/Header/MobileFiltersHeader";
import Icon from "shared/components/Icon";
import { UnderlineLink, BackLink } from "shared/components/Links";
import PaginationControls from "shared/components/PaginationControls";
import Spacer from "shared/components/Spacer";
import Tile from "shared/components/Tile";
import Tooltip from "shared/components/Tooltip";
import { MediumTitle, RegularText } from "shared/components/Typography";
import { BREAKPOINT_RESOLUTIONS } from "shared/config/constants";
import { useEllipsisTooltip } from "shared/hooks";
import PortalLayout from "shared/templates/PortalLayout";

import { ANY_OPTION_KEY } from "../config";

import { Loader } from "../styled";

import {
  StyledPropertyAddress,
  StyledFiltersPanel,
  UnitsWall,
  UnitsTilesGrid,
  MobileUnitsWall,
} from "./styled";

const getNumberOptions = (max) => {
  const items = range(max).map((i) => (
    <Select.Option key={i + 1}>+{i + 1}</Select.Option>
  ));

  items.unshift(<Select.Option key={ANY_OPTION_KEY}>Any</Select.Option>);
  return items;
};

const UnitsPortal = ({
  units,
  total,
  pagination,
  property,
  filtersBar,
  onPageChange,
  isUnitsLoading,
  onApplyNowClick,
  backRoute,
  isMagicLinksV2,
  isPrescreeningMagicLinksV2,
  onFilterChange,
  onApplyFilters,
  onClearFilters,
}) => {
  const { page, pageSize } = pagination;
  const {
    logoUrl,
    name: propertyName,
    address,
    city,
    state,
    zipcode,
    hashedId,
  } = property;
  const { bedrooms, bathrooms, maxRent } = filtersBar;
  const title = propertyName ? `${propertyName} Portal` : "VERO";
  const fullAddress =
    address &&
    city &&
    state &&
    zipcode &&
    `${address}, ${city}, ${state} ${zipcode}`;

  const applyNowLabel = isPrescreeningMagicLinksV2
    ? "PREQUALIFY WITHOUT SELECTING A UNIT"
    : "APPLY WITHOUT SELECTING A UNIT";

  const shouldShowApplyNowButton =
    isPrescreeningMagicLinksV2 ||
    (property?.preventInvitesToTheApplicationWithoutUnit !== true &&
      !property?.hasLroEnabled);

  const isDesktop = useMediaQuery({ minWidth: BREAKPOINT_RESOLUTIONS.md });

  const { elementRef: propertyNameRef, tooltipTitle: propertyNameTooltip } =
    useEllipsisTooltip({ title: propertyName });

  const {
    elementRef: propertyAddressRef,
    tooltipTitle: propertyAddressTooltip,
  } = useEllipsisTooltip({ title: fullAddress });

  const PropertyAddress = (
    <StyledPropertyAddress alignItems="center">
      <FlexContainer className="property-separator" alignItems="center">
        <Icon.MyPropertyBoldIcon className="property-icon" />
        &nbsp;
        <Tooltip
          title={
            propertyNameTooltip && (
              <div className="tooltip-inner-gray">{propertyNameTooltip}</div>
            )
          }
          theme="light"
        >
          <RegularText ref={propertyNameRef} className="property-name">
            {propertyName}
          </RegularText>
        </Tooltip>
      </FlexContainer>
      <div className="property-separator">
        <Tooltip
          title={
            propertyAddressTooltip && (
              <div className="tooltip-inner-gray">{propertyAddressTooltip}</div>
            )
          }
          trigger="hover"
          theme="light"
        >
          <RegularText
            light
            className="property-address"
            ref={propertyAddressRef}
          >
            {fullAddress}
          </RegularText>
        </Tooltip>
      </div>
    </StyledPropertyAddress>
  );

  const FiltersPanel = (
    <StyledFiltersPanel>
      <Select
        id="bedrooms"
        label="Beds"
        onChange={(value) => onFilterChange("bedrooms", value)}
        value={bedrooms}
        data-testid="bedrooms"
      >
        {getNumberOptions(4)}
      </Select>
      <Select
        id="bathrooms"
        label="Baths"
        onChange={(value) => onFilterChange("bathrooms", value)}
        value={bathrooms}
        data-testid="bathrooms"
      >
        {getNumberOptions(4)}
      </Select>
      <MaskedInput.Money
        id="maxRent"
        name="maxRent"
        label="Max monthly rent"
        onChange={({ target: { value } }) => onFilterChange("maxRent", value)}
        value={maxRent}
        data-testid="maxRent"
      />

      {isDesktop && (
        <>
          <Button type="primary" onClick={onApplyFilters}>
            Apply Filters
          </Button>
          <div>
            <UnderlineLink onClick={onClearFilters}>
              Clear Filters
            </UnderlineLink>
          </div>
        </>
      )}
    </StyledFiltersPanel>
  );

  const UnitsPortalDesktop = (
    <PortalLayout logoUrl={logoUrl} LogoAlt={PropertyAddress}>
      <UnitsWall>
        <section className="header">
          {backRoute && (
            <BackLink
              to={backRoute}
              size={BackLink.SIZES.large}
              className="back-link"
            >
              Back
            </BackLink>
          )}
          <MediumTitle strong className="title">
            Select Your Future Home
          </MediumTitle>
          {FiltersPanel}
        </section>
        <UnitsTilesGrid>
          {isUnitsLoading && <Loader data-testid="loader" />}
          {units.map((item) => (
            <UnitTile
              key={item.name}
              {...item}
              propertyHashedId={hashedId}
              hasLroEnabled={property.hasLroEnabled}
              companyUuid={property.companyUuid}
              propertyUuid={property.uuid}
              isPrescreeningMagicLinksV2={isPrescreeningMagicLinksV2}
              isMagicLinksV2={isMagicLinksV2}
            />
          ))}
        </UnitsTilesGrid>
        <section className="footer">
          {shouldShowApplyNowButton && (
            <Button type="secondary" onClick={onApplyNowClick}>
              {applyNowLabel}
            </Button>
          )}
          <PaginationControls
            current={page}
            total={total}
            pageSize={pageSize}
            data-testid="pagination-controls"
            onChange={onPageChange}
          />
        </section>
      </UnitsWall>
    </PortalLayout>
  );

  const UnitsPortalMobile = (
    <MobileUnitsWall>
      <MobileFiltersHeader
        FiltersNode={FiltersPanel}
        onApplyFilters={onApplyFilters}
        onClearFilters={onClearFilters}
      />
      <Tile alt className="property-tile">
        <Tile.Inner>{PropertyAddress}</Tile.Inner>
      </Tile>
      <section className="main-content">
        {logoUrl && <img src={logoUrl} alt="Logo" className="logo" />}
        <MediumTitle strong className="title">
          Select Your Future Home
        </MediumTitle>
        <UnitsTilesGrid>
          {isUnitsLoading && <Loader />}
          {units.map((item) => (
            <UnitTile
              key={item.hashedId}
              {...item}
              propertyHashedId={hashedId}
              hasLroEnabled={property.hasLroEnabled}
              companyUuid={property.companyUuid}
              propertyUuid={property.uuid}
              isPrescreeningMagicLinksV2={isPrescreeningMagicLinksV2}
              isMagicLinksV2={isMagicLinksV2}
            />
          ))}
        </UnitsTilesGrid>
        {shouldShowApplyNowButton && (
          <Button type="secondary" onClick={onApplyNowClick}>
            {applyNowLabel}
          </Button>
        )}
        <Spacer />
      </section>
      <FooterBar>
        {backRoute && (
          <BackLink
            to={backRoute}
            size={BackLink.SIZES.large}
            className="back-link"
          >
            Back
          </BackLink>
        )}
        <PaginationControls
          current={page}
          total={total}
          pageSize={pageSize}
          onChange={onPageChange}
          className="pagination-controls"
          data-testid="pagination-controls"
          size="small"
        />
      </FooterBar>
    </MobileUnitsWall>
  );

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {isDesktop ? UnitsPortalDesktop : UnitsPortalMobile}
    </>
  );
};

UnitsPortal.propTypes = {
  backRoute: PropTypes.string,
  isPrescreeningMagicLinksV2: PropTypes.bool,
  isMagicLinksV2: PropTypes.bool,
  filtersBar: PropTypes.shape({
    bathrooms: PropTypes.string,
    bedrooms: PropTypes.string,
    maxRent: PropTypes.string,
  }).isRequired,
  isUnitsLoading: PropTypes.bool.isRequired,
  onApplyFilters: PropTypes.func.isRequired,
  onApplyNowClick: PropTypes.func.isRequired,
  onClearFilters: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  pagination: PropTypes.shape({
    page: PropTypes.number,
    pageSize: PropTypes.number,
  }).isRequired,
  property: PropTypes.shape({
    address: PropTypes.string,
    city: PropTypes.string,
    logoUrl: PropTypes.string,
    name: PropTypes.string,
    state: PropTypes.string,
    zipcode: PropTypes.string,
    preventInvitesToTheApplicationWithoutUnit: PropTypes.bool.isRequired,
    hashedId: PropTypes.string.isRequired,
    hasLroEnabled: PropTypes.bool,
    companyUuid: PropTypes.string,
  }),
  total: PropTypes.number,
  company: PropTypes.shape({
    name: PropTypes.string.isRequired,
    logo: PropTypes.string,
    preventInvitesToTheApplicationWithoutUnit: PropTypes.bool,
  }),
  units: PropTypes.arrayOf(
    PropTypes.shape({
      bathrooms: PropTypes.string,
      bedrooms: PropTypes.number,
      listing: PropTypes.shape({
        rent: PropTypes.number,
        securityDeposit: PropTypes.number,
        unitAvailableOn: PropTypes.string,
      }),
      name: PropTypes.string.isRequired,
      rent: PropTypes.number,
      securityDeposit: PropTypes.number,
      squareFeet: PropTypes.number,
      hashedId: PropTypes.string.isRequired,
      hasLroEnabled: PropTypes.bool.isRequired,
    })
  ),
};

UnitsPortal.defaultProps = {
  property: {
    logoUrl: "",
    name: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    companyUuid: "",
  },
  total: 1,
  units: [],
  backRoute: "",
};

export default UnitsPortal;
