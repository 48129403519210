import styled from "@emotion/styled";

import NumberTitle from "shared/components/NumberTitle";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

export const Title = styled.h2`
  font-size: 17px;
  font-weight: 500;
  margin: 15px 0;
  text-align: center;
`;

export const IconContainer = styled.div`
  text-align: center;
`;

export const UpdatesContainer = styled.div`
  background-color: ${COLORS.background};
  border: 1px solid ${COLORS.borders};
  border-radius: 2px 2px 0 0;
  padding: 24px 20px;
  ul {
    margin-bottom: 0;
    padding-left: 121px;
    width: max-content;
    ${MEDIA_QUERIES.w.ltSm} {
      margin-right: auto;
      margin-left: auto;
      padding-left: 30px;
    }
    li:not(:last-of-type) {
      margin-bottom: 15px;
    }
  }
`;

export const StepsContainer = styled.div`
  border-radius: 0 0 2px 2px;
  border-color: ${COLORS.borders};
  border-style: solid;
  border-width: 0 1px 1px 1px;
  padding: 40px 20px;
`;

export const CenterContainer = styled.div`
  margin-right: auto;
  margin-left: auto;
  width: max-content;
`;

export const StepsTitle = styled.p`
  color: ${COLORS.textColorPrimary};
  font-size: 15px;
  font-weight: 500;
  text-align: center;
`;

export const Step = styled(NumberTitle)`
  margin-bottom: 12px;
  h6 {
    color: ${COLORS.textColorPrimary};
    font-size: 15px;
    font-weight: 400;
  }
`;
