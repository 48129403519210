import { normalize } from "normalizr";
import { combineReducers } from "redux";
import { handleActions } from "redux-actions";

import * as actions from "../actions";
import * as schemas from "../schema";

const initialState = {};

export const propertyTypeReducer = handleActions(
  {
    [actions.receivePropertyTypes]: (state, action) => {
      const propertyTypes = action.payload;

      return {
        ...state,
        ...normalize(propertyTypes, schemas.propertyList).entities.property,
      };
    },
  },
  initialState
);

export const applicantTypeReducer = handleActions(
  {
    [actions.receiveApplicantTypes]: (state, action) => {
      const types = action.payload;

      return {
        ...state,
        ...normalize(types, schemas.applicantList).entities.applicant,
      };
    },
  },
  initialState
);

export const requiredAttachmentTypeReducer = handleActions(
  {
    [actions.receiveRequiredAttachmentTypes]: (state, action) => {
      const types = action.payload;

      return {
        ...state,
        ...normalize(types, schemas.requiredAttachmentList).entities
          .requiredAttachment,
      };
    },
  },
  initialState
);

export const applicationStatusReducer = handleActions(
  {
    [actions.receiveApplicationStatuses]: (state, action) => {
      const types = action.payload;

      return {
        ...state,
        ...normalize(types, schemas.applicationStatusList).entities
          .applicationStatus,
      };
    },
  },
  initialState
);

export const dealStatusReducer = handleActions(
  {
    [actions.receiveDealStatuses]: (state, action) => {
      const types = action.payload;

      return {
        ...state,
        ...normalize(types, schemas.dealStatusList).entities.dealStatus,
      };
    },
  },
  initialState
);

export const unitStatusReducer = handleActions(
  {
    [actions.receiveUnitStatuses]: (state, action) => {
      const unitStatus = action.payload;

      return {
        ...state,
        ...normalize(unitStatus, schemas.unitStatusList).entities.unitStatus,
      };
    },
  },
  initialState
);

const typeCommonReducer = combineReducers({
  property: propertyTypeReducer,
  applicant: applicantTypeReducer,
  requiredAttachment: requiredAttachmentTypeReducer,
  applicationStatus: applicationStatusReducer,
  dealStatus: dealStatusReducer,
  unitStatus: unitStatusReducer,
});

export default typeCommonReducer;
