import React, { useState } from "react";

import { Input, InputProps } from "shared/components/Form/Input";
import { ToggleVisibilityButton } from "shared/components/Form/PasswordInput/ToggleVisibilityButton";
import Icon from "shared/components/Icon";

export type PasswordInputProps = InputProps & {
  showToggle?: boolean;
};

export const PasswordInput = ({ showToggle = true, id, name, ...props }) => {
  const [hidden, setHidden] = useState(true);
  const [visibilityButtonHovered, setVisibilityButtonHovered] = useState(false);

  const show = () => setHidden(false);
  const hide = () => setHidden(true);

  const toggle = (
    <ToggleVisibilityButton
      onClick={hidden ? show : hide}
      setHovered={setVisibilityButtonHovered}
      title={`${hidden ? "Show" : "Hide"} password`}
    >
      {hidden ? (
        <Icon.ShowPasswordIcon hovered={visibilityButtonHovered} />
      ) : (
        <Icon.HidePasswordIcon hovered={visibilityButtonHovered} />
      )}
    </ToggleVisibilityButton>
  );

  return (
    <Input
      data-testid="password-input"
      id={id}
      name={name}
      {...props}
      type={hidden ? "password" : "text"}
      append={showToggle && toggle}
    />
  );
};
