import axios from "axios";

import ROUTES from "manager/config/apiRoutes";
import { API_URL } from "shared/lib/api/setupApi";

const api = {
  getManagerProfile: () =>
    axios.get(`${API_URL}${ROUTES.managerProfile}`).then(({ data }) => data),
};

export default api;
