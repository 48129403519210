import { css } from "@emotion/core";
import styled from "@emotion/styled";

import { COLORS, MEDIA_QUERIES, TRANSITIONS } from "shared/config/constants";

import ActionLink from "../ActionLink";

import { SIZES } from "./constants";

const BackLink = styled(ActionLink)<{ size?: string }>((props) => ({
  display: "flex",
  alignItems: "center",
  color: COLORS.backLink.default,
  fontSize: "12px",
  textTransform: "uppercase",
  path: {
    stroke: COLORS.backLink.default,
    transition: TRANSITIONS.default,
  },
  ...(props.size === SIZES.large && {
    fontSize: "15px",
    fontWeight: 500,
  }),
  "&:hover": {
    color: COLORS.backLink.hover,
    path: {
      stroke: COLORS.backLink.hover,
    },
  },
}));

const TextContainer = styled("span")<{ size?: string }>((props) => ({
  marginLeft: "5px",
  ...(props.size === SIZES.large && {
    marginLeft: "8px",
  }),
  [MEDIA_QUERIES.w.ltSm]: {
    display: "none",
  },
}));

const Container = styled.div`
  ${({ noMarginBottom }: { noMarginBottom?: boolean }) =>
    !noMarginBottom
      ? css`
          margin-bottom: 16px;
        `
      : undefined}

  ${MEDIA_QUERIES.w.gteXl} {
    display: none;
  }
`;

export { BackLink, TextContainer, Container };
