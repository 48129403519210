import React from "react";

import styled from "@emotion/styled";

import moment from "moment";

import PropTypes from "prop-types";

import { ID_VERIFICATION_STATUS } from "renter/config/constants";
import Tile from "shared/components/Tile";
import {
  AccordionVerificationStatus,
  IdVerificationDetails,
  NoVerificationsPlaceholder,
} from "shared/components/Verification";
import { VERIFICATION_ACCORDION_FOR_STATUS } from "shared/components/Verification/utils";
import {
  VERIFICATION_TYPE,
  PERSONA_VERIFICATION_STATUS,
} from "shared/config/constants";
import { idVerificationPropType } from "shared/utils/propTypes";

import { SubTileCustom, TileCustom, VerificationContainer } from "./styled";

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HeaderLeft = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const Verifications = ({
  idVerifications,
  applicationSummary,
  personaStatus,
  personaInquiryStatus,
  personaReviewerComment,
}) => {
  const verificationHeader = (verification, verificationType) => {
    return (
      <AccordionVerificationStatus
        type={verificationType}
        verificationType={verification?.type}
        status={verification.status || verification.attributes?.status}
        verificationDate={
          verification.verificationDate || verification.attributes?.completedAt
        }
        subHeader
      />
    );
  };

  const formatIdVerification = (verification) => {
    return {
      details: {
        checks: verification.attributes.checks,
        firstName: verification.attributes.nameFirst,
        middleName: verification.attributes.nameMiddle,
        lastName: verification.attributes.nameLast,
        dateOfBirth: verification.attributes.birthdate,
        age: moment().diff(verification.attributes.birthdate, "years"),
        phoneNumber: verification.attributes.phoneNumber,
        documentClass: verification.attributes.idClass,
        identificationNumber: verification.attributes.identificationNumber,
        expirationDate: verification.attributes.expirationDate,
        addressStreet: verification.attributes.addressStreet1,
        addressCity: verification.attributes.addressCity,
        addressSubdivisionAbbr: verification.attributes.addressSubdivision,
        addressPostalCodeAbbr: verification.attributes.addressPostalCode,
        addressCountryCode: verification.attributes.countryCode,
        verificationFiles: verification.verificationFiles,
      },
    };
  };

  const idVerificationContent = (verification) => {
    return (
      <Tile.Inner>
        <IdVerificationDetails
          verification={formatIdVerification(verification)}
          phoneNumber={applicationSummary?.phoneNumber}
          verificationType={verification?.type}
        />
      </Tile.Inner>
    );
  };

  const renderVerification = (verification, verificationType, index) => {
    const content = idVerificationContent(verification);
    return (
      <SubTileCustom
        header={{
          title: verificationHeader(verification, verificationType),
          collapsibleOnDesktop: true,
          collapsibleOnMobile: true,
          defaultCollapsed: index !== 0,
        }}
        noBorderTop
        noBorderLeft
        noBorderRight
        noMarginBottom
      >
        <VerificationContainer>{content}</VerificationContainer>
      </SubTileCustom>
    );
  };

  const isManuallyApproved = () => {
    return (
      personaStatus?.toUpperCase() === ID_VERIFICATION_STATUS.APPROVED ||
      personaInquiryStatus?.toUpperCase() === ID_VERIFICATION_STATUS.APPROVED
    );
  };

  const verificationStatus = (status) => {
    if (isManuallyApproved()) {
      return PERSONA_VERIFICATION_STATUS.PASSED;
    }
    return status;
  };

  const dropHeader = (verification, verificationType) => {
    return (
      <HeaderContainer>
        <HeaderLeft className="main-header-left">
          <AccordionVerificationStatus
            type={verificationType}
            status={verificationStatus(personaInquiryStatus)}
            verificationDate={
              verification.verificationDate ||
              verification.attributes?.completedAt.slice(0, 10)
            }
            isManuallyApproved={isManuallyApproved()}
            personaReviewerComment={personaReviewerComment}
          />
        </HeaderLeft>
      </HeaderContainer>
    );
  };

  const verificationContent = ({ verifications, verificationType }) => {
    return (
      <TileCustom
        className={
          VERIFICATION_ACCORDION_FOR_STATUS[
            verificationStatus(personaInquiryStatus)
          ]
        }
        header={{
          title: dropHeader(verifications[0], verificationType),
          collapsibleOnDesktop: true,
          collapsibleOnMobile: true,
        }}
      >
        <Tile key={0} noBorder noMarginBottom>
          {verifications.map((verification, index) => {
            return renderVerification(verification, verificationType, index);
          })}
        </Tile>
      </TileCustom>
    );
  };

  const personaVerifications = idVerifications?.slice().reverse();

  return (
    <>
      {(!personaVerifications || personaVerifications?.length === 0) && (
        <NoVerificationsPlaceholder message="No Identity Verifications Available" />
      )}
      {personaVerifications && personaVerifications?.length > 0 && (
        <>
          {personaVerifications.length > 0 &&
            verificationContent({
              verifications: personaVerifications,
              verificationType: VERIFICATION_TYPE.ID,
            })}
        </>
      )}
    </>
  );
};

Verifications.propTypes = {
  idVerifications: PropTypes.arrayOf(idVerificationPropType).isRequired,
  applicationSummary: PropTypes.object,
  personaStatus: PropTypes.string,
  personaInquiryStatus: PropTypes.string,
  personaReviewerComment: PropTypes.string,
};

Verifications.defaultProps = {
  applicationSummary: undefined,
};

export default Verifications;
