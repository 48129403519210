import { css, keyframes } from '@emotion/core'
import styled from '@emotion/styled'
import { Radio, Row } from 'antd'

import FlexContainer from 'shared/components/FlexContainer'
import { UnderlineLink } from 'shared/components/Links'
import {
  SmallTitle,
  RegularText,
  SmallText,
} from 'shared/components/Typography'
import { COLORS, MEDIA_QUERIES } from 'shared/config/constants'

import Icon from '../Icon'
import { Modal as BaseModal } from '../Modal'
import { StyledTable } from '../Table/Table/styled'

interface DocumentProps {
  expand?: boolean
  withBorderRight?: boolean
  marginTop?: boolean
}

const Document = styled(FlexContainer)<DocumentProps>`
  ${(props) =>
    !!props.expand &&
    css`
      flex-grow: 1;
    `}
  ${(props) =>
    !!props.withBorderRight &&
    css`
      border-right: 1px solid ${COLORS.borders};
    `}

  border-bottom: 1px solid ${COLORS.borders};
  padding: 40px 16px 30px 40px;
  min-width: 200px;

  ${MEDIA_QUERIES.w.ltSm} {
    border-right: none;
  }

  ${(props) =>
    !!props.marginTop &&
    css`
      .ant-latest-upload-select {
        margin-top: 24px;
      }
    `}
`

const DocumentList = styled(Row)`
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid ${COLORS.borders};

  ${MEDIA_QUERIES.w.ltMd} {
    flex-direction: column;
    border-bottom: none;
  }
`

const DocumentTitle = styled(SmallTitle)`
  max-width: 200px;
  height: 36px;
`

const Support = styled.div`
  padding: 30px 45px 45px;
`

interface FolderWrapperProps {
  marginTop?: boolean
}

const FolderWrapper = styled(FlexContainer)<FolderWrapperProps>`
  border: 1px solid ${COLORS.borders};
  border-radius: 2px;
  margin: 0 24px 50px 0;
  height: 200px;
  width: 200px;

  ${(props) =>
    !!props.marginTop &&
    css`
      margin-top: 24px;
    `}
`

const FolderItems = styled.p`
  color: ${COLORS.gray2};
  margin-top: 16px;
  margin-bottom: 8px;
  max-width: 130px;
  text-align: center;
`

const NoDocumentUploaded = styled(FolderWrapper)`
  background-color: ${COLORS.itemActiveBg};
  ${(props) =>
    !!props.marginTop &&
    css`
      margin-top: 24px;
    `}
`

const StyledLink = styled(UnderlineLink)`
  width: max-content;
`

const WarningBanner = styled.div`
  background-color: ${COLORS.lightOrange2};
  color: ${COLORS.darkOrange};
  border: 1px solid ${COLORS.darkOrange};
  border-radius: 12px;
  height: 82px;
  display: flex;
  align-items: center;
  padding: 0;
  margin-bottom: 24px;

  .text-recognition-icon {
    margin-left: 16px;
  }

  .view-detail-button {
    margin-right: 16px;
  }

  .banner-message {
    flex: 1;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 14px;
  }
`

const SupportingDataText = styled(RegularText)`
  cursor: pointer;
  color: ${COLORS.darkOrange};
`

const DescriptionText = styled.div`
  margin-bottom: 24px;
`

const CapitalizedContainer = styled(FlexContainer)`
  text-transform: capitalize;
`

const DocumentTable = styled<typeof StyledTable>(StyledTable)`
  border-radius: 8px;
  ${[MEDIA_QUERIES.w.ltMd]} {
    border-radius: 0;
  }
  border-bottom: 1px solid ${COLORS.borders};
  border-left: 1px solid ${COLORS.borders};
  border-right: 1px solid ${COLORS.borders};
  overflow: scroll;
  & .ant-table {
    border: 0;
    border-radius: 0;
  }
  & .ant-table-thead {
    & tr {
      border: 0 !important;
    }
    & tr > th {
      background-color: ${COLORS.primary} !important;
      border: 0;
      padding: 9px 16px !important;
      &:first-child {
        border-top-left-radius: 8px !important;
        ${[MEDIA_QUERIES.w.ltMd]} {
          border-top-left-radius: 0 !important;
        }
      }
      &:last-child {
        border-top-right-radius: 8px !important;
        ${[MEDIA_QUERIES.w.ltMd]} {
          border-top-right-radius: 0 !important;
        }
      }
    }
  }
  & .ant-table-body {
    margin: 0 !important;
  }
  & .ant-table-tbody > tr > td,
  .ant-table-column-sort {
    background: unset;
    padding: 9px 16px !important;
  }
`

const DocumentTableHeader = styled<typeof RegularText>(RegularText)`
  color: ${COLORS.white};
  text-align: center;
`

const DeleteDocumentIcon = styled(Icon.BaseDeleteNewIcon)`
  cursor: pointer;
  & path {
    fill: ${COLORS.primary};
  }
  float: right;
`
const Modal = styled<typeof BaseModal>(BaseModal)`
  & .ant-modal-header,
  .ant-modal-content {
    border-radius: 30px;
  }
`

const LumenReadyLink = styled<typeof UnderlineLink, { color: string }>(
  UnderlineLink,
)`
  ${({ color }) => `
    border-bottom-color: ${color} !important;
    color: ${color} !important;
    &:hover {
      border-bottom-color: ${color} !important;
      color: ${color} !important;
    }
  `}
`

const Link = styled<typeof UnderlineLink>(UnderlineLink)`
  border-bottom-color: ${COLORS.primary} !important;
  &:hover {
    border-bottom-color: ${COLORS.primary} !important;
    color: ${COLORS.primary} !important;
  }
`

const radioButtonHeight = 56
const radioGroupGap = 8

const translateRadioButton = ({
  selectedOptionIndex,
}: {
  selectedOptionIndex: number
}) => keyframes`
10% {
    transform: translateY(
      -${
        selectedOptionIndex * radioButtonHeight +
        selectedOptionIndex * radioGroupGap
      }px
    );
  }
  100% {
    transform: translateY(
      -${
        selectedOptionIndex * radioButtonHeight +
        selectedOptionIndex * radioGroupGap
      }px
    );
  }
`

const hideRadioButton = keyframes`
  10% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 0;s
    visibility: hidden;
  }
`

const revertHideRadioButton = keyframes`
  0% {
    opacity: 0;
    visibility: hidden;
  }
  10% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
`

const DocumentName = styled<typeof SmallText>(SmallText)`
  max-width: 100%;
`

const DocumentTypeOptions = styled<
  typeof Radio.Group,
  { animate: boolean; selectedOptionIndex: number; touched: boolean }
>(Radio.Group)`
  display: flex;
  flex-direction: column;
  gap: ${radioGroupGap}px;
  position: relative;
  width: 100%;
  & .ant-radio-wrapper {
    margin-right: 0 !important;
  }
  ${({ animate, selectedOptionIndex, touched }) =>
    animate &&
    css`
      & label:has(input[type='radio']:checked) {
        animation: ${translateRadioButton({ selectedOptionIndex })} 1.5s
          ease-in-out forwards;
      }
      & label:not(:has(input[type='radio']:checked)) {
        ${touched
          ? css`
              animation: ${hideRadioButton} 1.5s ease-in-out forwards;
            `
          : css`
              animation: ${revertHideRadioButton} 0.8s ease-in-out forwards;
            `}
      }
      & label:has(input[type='radio']:checked) ~ .success-message-container {
        opacity: 1;
        transition: opacity 0.8s ease-in-out;
        visibility: visible;
      }
    `}
`

const DocumentType = styled<typeof Radio>(Radio)`
  align-items: center;
  border: 1px solid ${COLORS.disabledText};
  border-radius: 4px;
  display: flex;
  height: ${radioButtonHeight}px;
  padding: 16px 12px;
  & .ant-radio {
    border-color: ${COLORS.disabledText};
    &:hover {
      & .ant-radio-inner {
        border-color: ${COLORS.disabledText};
      }
    }
  }
  & .ant-radio-checked,
  & .ant-radio-inner {
    border-color: ${COLORS.disabledText};
    &:after {
      display: block;
      background-color: ${COLORS.disabledText};
    }
  }
  & .ant-radio-checked:after {
    border-color: ${COLORS.disabledText};
  }
  &.ant-radio-wrapper:hover .ant-radio {
    border-color: ${COLORS.disabledText};
  }
  & .ant-radio-input:focus + .ant-radio-inner {
    border-color: ${COLORS.disabledText};
    box-shadow: none;
  }
  & span:nth-child(2) {
    white-space: break-spaces;
  }
`

const SuccessMessageContainer = styled<
  typeof FlexContainer,
  { numberOfOptions: number }
>(FlexContainer)`
  position: absolute;
  opacity: 0;
  top: ${({ numberOfOptions }) =>
    (radioButtonHeight * numberOfOptions +
      radioGroupGap * (numberOfOptions - 1) -
      168 / 2) /
    2}px;
  visibility: hidden;
  width: 100%;
`
const UploadZone = styled<typeof FlexContainer>(FlexContainer)`
  background-color: ${COLORS.backgroundGrey2};
  border: 1px solid ${COLORS.violetTheme.lightViolet2};
  border-radius: 8px;
  padding: 20px 70px;
  &:hover {
    background-color: ${COLORS.incomeVerification.title};
    cursor: pointer;
  }
`

const UploadIconContainer = styled<typeof FlexContainer>(FlexContainer)`
  background-color: ${COLORS.itemActiveBg};
  border-radius: 8px;
  box-shadow: 0 2px 4px #78739540;
  height: 44px;
  width: 44px;
`

export {
  Document,
  DocumentList,
  DocumentTitle,
  FolderWrapper,
  FolderItems,
  NoDocumentUploaded,
  StyledLink,
  Support,
  WarningBanner,
  SupportingDataText,
  CapitalizedContainer,
  DescriptionText,
  DocumentTable,
  DocumentTableHeader,
  DeleteDocumentIcon,
  Modal,
  LumenReadyLink,
  Link,
  DocumentName,
  DocumentTypeOptions,
  DocumentType,
  SuccessMessageContainer,
  UploadZone,
  UploadIconContainer,
}
