import { useHistory } from "react-router-dom";

import { getQueryParams } from "shared/utils/routing";
import ROUTES from "manager/config/routes";

import { getCachedActiveCompany, getAccessToken } from "./auth";

export const useManageCompany = () => {
  const history = useHistory();

  const manageCompany = () => {
    const currentRoute = history.location.pathname;

    const cachedCompany = getCachedActiveCompany();
    const loggedIn = getAccessToken();
    const { r } = getQueryParams();

    const redirectToSelectCompany = !cachedCompany && loggedIn && currentRoute !== ROUTES.selectCompany;
    if (redirectToSelectCompany) {
      window.location.href = `${ROUTES.selectCompany}?r=${r || ((window.location.pathname !== '/login' && window.location.pathname !== '/auth/login') ? window.location.pathname : '/')}`;
    }
  };

  return { manageCompany };
};
