import styled from "@emotion/styled";

const TableTitle = styled.p({
  fontSize: 16,
  fontWeight: 500,
});

const WrappedPreformatted = styled.pre({
  whiteSpace: "pre-wrap",
  wordWrap: "break-word",
  width: "calc(100vw - 540px)",
});

const ClientTableWrapper = styled.div({
  td: {
    position: "relative",
  },
  ".download-link": {
    position: "absolute",
    top: "20px",
  },
});

export { TableTitle, WrappedPreformatted, ClientTableWrapper };
