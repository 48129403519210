import { toDollars } from "../utils";

import { Fees } from "./interfaces";

export const mapFees = (deal: Record<string, any>): Fees => {
  const holdingDeposit = deal?.isHoldingDepositEnabled
    ? Number(toDollars(deal?.chargesConfig?.holdingDepositFee))
    : undefined;

  const isSecurityDepositDeducted =
    deal?.securityDepositDeductionStatus === "HOLDING_DEPOSIT_DEDUCTED";
  const initialSecurityDeposit = toDollars(deal?.securityDeposit);
  const securityDeposit =
    isSecurityDepositDeducted && initialSecurityDeposit && holdingDeposit
      ? initialSecurityDeposit - holdingDeposit
      : initialSecurityDeposit;

  return {
    holdingDeposit,
    isHoldingDepositPaid: deal?.isHoldingDepositPaid,
    securityDeposit,
    isSecurityDepositPaid: !deal.remainingSecurityDeposit,
    rent: toDollars(deal.rent),
    isRentPaid: !!deal.lease?.rentPaid,
    isSecurityDepositDeducted,
  };
};
