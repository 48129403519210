import React from "react";

import { useMediaQuery } from "react-responsive";

import Spacer from "shared/components/Spacer";
import { BREAKPOINT_RESOLUTIONS } from "shared/config/constants";

import {
  InnerContainer,
  TileContainer,
  Tile,
  Title,
  TileName,
  CheckIcon,
} from "./styled";

const flexColumn = {
  flexDirection: "column",
  alignItems: "center",
};

export interface SelectableTilesProps {
  activeTiles?: Array<string | number>;
  onTileClick?: (key?: string | number) => void;
  tiles?: {
    key?: string | number;
    name: string;
    Icon?: Node | React.ReactNode;
  }[];
  title?: string;
}

const SelectableTiles = ({
  tiles = [],
  title = "",
  activeTiles = [],
  onTileClick,
}: SelectableTilesProps) => {
  const isMiddle = useMediaQuery({ maxWidth: BREAKPOINT_RESOLUTIONS.md });

  return (
    // @ts-ignore
    <InnerContainer {...flexColumn}>
      <Title>{title}</Title>
      <Spacer size={Spacer.SIZES.md} />
      {/* @ts-ignore */}
      <TileContainer {...(isMiddle && flexColumn)}>
        {tiles.map(({ key, name, Icon }) => {
          const isActive = activeTiles.includes(key);
          return (
            <Tile
              key={key}
              onClick={() => onTileClick(key)}
              data-testid={`tile-${key}`}
              active={isActive}
            >
              {isActive && <CheckIcon data-testid="check-icon" />}
              {Icon}
              <TileName> {name} </TileName>
            </Tile>
          );
        })}
      </TileContainer>
    </InnerContainer>
  );
};

export default SelectableTiles;
