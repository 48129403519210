import sharedApi from "shared/lib/api";

import applicationApi from "./applicationApi";
import assetApi from "./assetApi";
import documentApi from "./documentApi";
import employmentApi from "./employmentApi";
import identityVerificationApi from "./identityVerificationApi";
import incomeAssetsApi from "./incomeAndAssetsApi";
import incomeApi from "./incomeApi";
import linkEmploymentApi from "./linkEmploymentApi";
import magicLinksApi from "./magicLinksApi";
import magicPortalApi from "./magicPortalApi";
import personApi from "./personApi";
import plaidApi from "./plaidApi";

const api = {
  ...sharedApi,
  ...magicLinksApi,
  ...applicationApi,
  ...magicPortalApi,
  ...linkEmploymentApi,
  ...identityVerificationApi,
  ...linkEmploymentApi,
  ...plaidApi,
  ...incomeAssetsApi,
  ...personApi,
  ...employmentApi,
  ...documentApi,
  ...incomeApi,
  ...assetApi,
};

export default api;
