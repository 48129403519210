import React from "react";

import { RenamePortfolioModal } from "manager/components/Modal";
import { BackLinkProps } from "shared/components/Links/interfaces";
import useModal from "shared/hooks/useModal";

import PortfolioName from "./PortfolioName";

interface PortfolioNameContainerProps {
  backLink: BackLinkProps;
  portfolio: {
    id: number;
    name: string;
  };
  loading: boolean;
}

const PortfolioNameContainer = ({
  portfolio,
  backLink,
  loading,
}: PortfolioNameContainerProps) => {
  const { openModalDialog } = useModal();
  const rename = () =>
    openModalDialog(RenamePortfolioModal, {
      name: portfolio?.name,
      portfolioId: portfolio?.id,
    });

  return (
    <PortfolioName
      rename={rename}
      portfolio={portfolio}
      backLink={backLink}
      loading={loading}
    />
  );
};

export default PortfolioNameContainer;
