import React from "react";

import { Row, Col } from "antd";
import { Form, Formik } from "formik";

import { CreateUnitListingValidationSchema } from "manager/utils/validationSchemas";
import ErrorList from "shared/components/ErrorList";
import {
  MaskedInput,
  DatePicker,
  TextArea,
  VeroFormField,
} from "shared/components/Form";
import { Modal } from "shared/components/Modal";
import { INTEGRATION_TYPES } from "shared/config/constants";
import { showError } from "shared/utils/forms";

import { UNIT_LISTING_FIELDS } from "../CreateUnitListingModal/createUnitListing.config";

import { EditUnitListingModalProps } from "./interfaces";

const EditUnitListingModal = ({
  submit,
  rent,
  securityDeposit,
  unitAvailableOn,
  note,
  currentIntegrationType,
  unitName,
}: EditUnitListingModalProps) => {
  const initialValues = {
    rent,
    securityDeposit,
    unitAvailableOn,
    note,
  };

  const isControlledByIntegration =
    INTEGRATION_TYPES.YARDI === currentIntegrationType;

  return (
    <Formik
      validationSchema={CreateUnitListingValidationSchema}
      initialValues={initialValues}
      onSubmit={submit}
    >
      {({ errors, submitForm, isSubmitting, touched }) => (
        <Form>
          <Modal
            title="Edit listing"
            subtitle={
              <span data-testid="subtitle">
                Unit: <strong>{unitName}</strong>
              </span>
            }
            submit={submitForm}
            submitting={isSubmitting}
            submitButtonLabel="Save Changes"
            closeOnSubmit={false}
          >
            {errors.nonFieldErrors && (
              <Modal.Body noPaddingBottom>
                <ErrorList errors={errors.nonFieldErrors} />
              </Modal.Body>
            )}
            <Modal.Body>
              <Row gutter={[20, 25]}>
                <Col md={12}>
                  <MaskedInput.Formik.Money
                    id={UNIT_LISTING_FIELDS.rent}
                    name={UNIT_LISTING_FIELDS.rent}
                    data-testid={UNIT_LISTING_FIELDS.rent}
                    label="Unit Rent"
                    required
                    error={showError(UNIT_LISTING_FIELDS.rent, touched, errors)}
                  />
                </Col>
                <Col md={12}>
                  <MaskedInput.Formik.Money
                    id={UNIT_LISTING_FIELDS.securityDeposit}
                    name={UNIT_LISTING_FIELDS.securityDeposit}
                    data-testid={UNIT_LISTING_FIELDS.securityDeposit}
                    label="Security Deposit"
                    error={showError(
                      UNIT_LISTING_FIELDS.securityDeposit,
                      touched,
                      errors
                    )}
                    disabled={isControlledByIntegration}
                  />
                </Col>
              </Row>
              <Row gutter={[20, 25]}>
                <Col md={12}>
                  <VeroFormField
                    as={DatePicker}
                    id={UNIT_LISTING_FIELDS.unitAvailableOn}
                    name={UNIT_LISTING_FIELDS.unitAvailableOn}
                    data-testid={UNIT_LISTING_FIELDS.unitAvailableOn}
                    label="Date Available"
                    required
                    disabled={isControlledByIntegration}
                  />
                </Col>
              </Row>
              <Row gutter={[20, 25]}>
                <Col>
                  <VeroFormField
                    as={TextArea}
                    name={UNIT_LISTING_FIELDS.note}
                    id={UNIT_LISTING_FIELDS.note}
                    data-testid={UNIT_LISTING_FIELDS.note}
                    label="Note"
                    maxLength={500}
                  />
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default EditUnitListingModal;
