import axios from "axios";

import { VEGA_BASE_URL } from "shared/config/apiRoutes";

const PERSON_SERVICE_PATH = "/api/person";

const api = {
  getPerson: () =>
    axios
      .get(`${VEGA_BASE_URL}${PERSON_SERVICE_PATH}/me`)
      .then(({ data }) => data),
  getArgyleUserToken: () =>
    axios
      .get(`${VEGA_BASE_URL}${PERSON_SERVICE_PATH}/me/token?provider=argyle`)
      .then(({ data }) => data),
  getPlaidAccessToken: ({ token }) =>
    axios
      .get(
        `${VEGA_BASE_URL}${PERSON_SERVICE_PATH}/me/token?provider=plaid&public-token=${token}`
      )
      .then(({ data }) => data),
  getPlaidLinkToken: ({ redirectUrl }) =>
    axios
      .get(
        `${VEGA_BASE_URL}${PERSON_SERVICE_PATH}/me/token?provider=plaid&redirect-url=${encodeURIComponent(
          redirectUrl
        )}`
      )
      .then(({ data }) => data),
};

export default api;
