import React from "react";

import { useHistory, useParams } from "react-router-dom";

import RenterApplicantList from "renter/components/ApplicantList";
import RenterConditionallyProceedInfoBanner from "renter/components/ConditionallyProceedInfoBanner";
import EditApplicationModal from "renter/components/Modal/EditApplication";
import { useApplicantDeal, useGetApplicationSummary } from "renter/hooks/api";
import { useApplicantTypes } from "shared/hooks/api";
import useModal from "shared/hooks/useModal";
import { BackLinkProps } from "shared/interfaces";
import ApplicationSummary from "shared/pages/ApplicationSummary";
import Layout from "shared/templates/Layout";
import { showConditionallyProceed } from "shared/utils/application";
import {
  mapOtherAttachments,
  mapRequiredAttachments,
} from "shared/utils/state";

const OtherApplicantsList = ({ applicants, applicantTypes }) => {
  return (
    <RenterApplicantList
      applicants={applicants}
      applicantTypes={applicantTypes}
    />
  );
};

interface ApplicationSummaryContainerProps {
  backLink: BackLinkProps;
  header: any;
  sidebar: any;
  match: any;
}

const ApplicationSummaryContainer = ({
  backLink,
  header,
  sidebar,
  match,
}: ApplicationSummaryContainerProps) => {
  const { id: applicationId } = useParams<{ id: string }>();
  const history = useHistory();
  const { openModalDialog } = useModal();

  const {
    applicationSummary,
    isApplicationSummaryFetching,
    isApplicationSummaryLoading,
  } = useGetApplicationSummary(applicationId);

  const loading = isApplicationSummaryFetching || isApplicationSummaryLoading;

  const {
    otherAttachments: otherAttachmentsRaw,
    requiredAttachments: requiredAttachmentsRaw,
    deal: dealId,
  } = applicationSummary || {};

  const requiredAttachments = requiredAttachmentsRaw?.map(
    mapRequiredAttachments
  );
  const otherAttachments = otherAttachmentsRaw?.map(mapOtherAttachments);

  const applicantTypes = {};
  const { data: applicantTypeArray } = useApplicantTypes();
  applicantTypeArray?.forEach((applicantType) => {
    applicantTypes[applicantType.id] = applicantType;
  });

  const showConditionallyProceedInfoBanner =
    showConditionallyProceed(applicationSummary);

  const { applicantDeal, refetchApplicantDeal } = useApplicantDeal(dealId);

  const openWithdraw = (id: number, apartment: string) =>
    openModalDialog(EditApplicationModal, { applicationId: id, apartment });

  return (
    <Layout
      header={{
        ...header,
        backLink,
      }}
      backLink={backLink}
      sidebar={sidebar}
      withoutBackToTop
    >
      <ApplicationSummary
        // @ts-ignore
        title="My Application Summary"
        application={applicationSummary}
        applicantTypes={applicantTypes}
        deal={applicantDeal}
        loading={loading}
        requiredAttachments={requiredAttachments}
        requiredAttachmentsRaw={requiredAttachmentsRaw}
        otherAttachments={otherAttachments}
        otherAttachmentsRaw={otherAttachmentsRaw}
        openWithdraw={openWithdraw}
        showDropdown={false}
        showProperty
        showDateStamp
        OtherApplicantsList={OtherApplicantsList}
        ConditionallyProceedInfoBanner={RenterConditionallyProceedInfoBanner}
        showConditionallyProceedInfoBanner={showConditionallyProceedInfoBanner}
        history={history}
        backLink={backLink}
        match={match}
        // NOTE keep this until we refactor src/shared/pages/ApplicationSummary
        getDeal={refetchApplicantDeal}
      />
    </Layout>
  );
};

export default ApplicationSummaryContainer;
