import React, { useState } from "react";

import { useGetDeals, useGetYardiUnit } from "manager/hooks/api";
import { useDealStatuses } from "shared/hooks/api";
import useModal from "shared/hooks/useModal";

import YardiArchivedDealsResolverModal from "./YardiArchivedDealsResolverModal";

const TABLE_PAGE_SIZE = 5;

const YardiArchivedDealsResolverModalContainer = () => {
  const { currentModalContext } = useModal();
  const { yardiProperty, alert, provider } = currentModalContext;
  const veroUnitId = alert?.relatedObjectId;
  const { yardiUnit } = useGetYardiUnit({
    provider,
    yardiPropertyId: yardiProperty?.id,
    yardiUnitId: alert.refPk,
  });
  const { data: dealStatuses, isLoading: isDealStatusesLoading } =
    useDealStatuses();

  const [currentPagination, setCurrentPagination] = useState({
    page: 1,
    pageSize: TABLE_PAGE_SIZE,
  });
  const filters = {
    ...currentPagination,
    unitId: veroUnitId,
    dealCategory: "rejected",
    hasYardiStatus: true,
  };
  const { data: deals, isLoading: isDealsLoading } = useGetDeals(filters);

  const yardiUnitName = `${yardiUnit?.name}, ${yardiProperty?.name}`;

  const isLoading = isDealsLoading || isDealStatusesLoading
  return (
    <YardiArchivedDealsResolverModal
      yardiUnitName={yardiUnitName}
      deals={deals?.results}
      dealsCount={deals?.count}
      dealStatuses={dealStatuses}
      currentPagination={currentPagination}
      setCurrentPagination={setCurrentPagination}
      isLoading={isLoading}
    />
  );
};

export default YardiArchivedDealsResolverModalContainer;
