import styled from "@emotion/styled";

import DraggableTable from "shared/components/DraggableTable";
import FlexContainer from "shared/components/FlexContainer";
import { COLORS } from "shared/config/constants";

const StyledDraggableTable = styled(DraggableTable)`
  table {
    border-collapse: separate;
    border-spacing: 0 8px;
  }

  th:first-of-type {
    padding-left: 0;
  }

  thead > tr > th {
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: 0;
  }

  tbody > tr > td {
    background: ${COLORS.lightGray2};
    padding: 14px;
    border-bottom: 0;
  }

  tbody > tr:not(.ant-table-expanded-row):hover > td {
    background-color: ${COLORS.lightGray2} !important;
  }
`;

const ActionsColumnContainer = styled(FlexContainer)`
  .action-icon:not(:first-of-type),
  span:not(:first-of-type) {
    margin-left: 30px;
  }
`;

export { StyledDraggableTable, ActionsColumnContainer };
