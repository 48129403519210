import React from "react";

import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";

import Icon from "shared/components/Icon";
import LargeLoader from "shared/components/LargeLoader";
import { UnderlineLink } from "shared/components/Links";
import { SUPPORT_EMAIL } from "shared/config/constants";
import InfoPage from "shared/templates/InfoPage";
import { encodeStringToBase64 } from "shared/utils/misc.util";

import { LoginSelfSignedDeepLinkBouncePageProps } from "./interfaces";

const LoginSelfSignedDeepLinkBouncePage = ({
  isLoading,
  errorMessage,
  fallbackUrl,
  hideBackButton,
  defaultDescription,
}: LoginSelfSignedDeepLinkBouncePageProps) => {
  const history = useHistory();
  const showErrorMessage = !isLoading && !!errorMessage;

  const backButton = hideBackButton
    ? null
    : {
        children: "Back",
        onClick: () => {
          if (fallbackUrl) {
            window.location.href = `${fallbackUrl}?error=${encodeStringToBase64(
              errorMessage
            )}`;
          } else {
            history.goBack();
          }
        },
        PrefixIcon: Icon.ButtonBackArrowIcon,
      };

  return (
    <>
      <Helmet>
        <title>Sign In - Self-signed deeplink</title>
      </Helmet>
      <LargeLoader showLoader={isLoading} absoluteCenter />
      {showErrorMessage && (
        <InfoPage
          title={errorMessage.message}
          description={
            errorMessage.description ||
            defaultDescription || (
              <>
                You can contact the VERO support team at{" "}
                <UnderlineLink href={`mailto:${SUPPORT_EMAIL}`}>
                  {SUPPORT_EMAIL}
                </UnderlineLink>{" "}
                or
                <br />
                use the live chat.
              </>
            )
          }
          button={backButton}
          MainIcon={Icon.OrangeExclamationHollow}
        />
      )}
    </>
  );
};

export default LoginSelfSignedDeepLinkBouncePage;
