import React, { Key } from "react";

import useModal from "shared/hooks/useModal";

import CreateUnitListingModal from "../CreateUnitListingModal";

import CreateUnitSuccessModal from "./CreateUnitSuccessModal";

interface CreateUnitSuccessModalContainerProps {
  unitId: Key;
  unitName: string;
  propertyName: string;
}

const CreateUnitSuccessModalContainer = ({
  unitId,
  unitName,
  propertyName,
}: CreateUnitSuccessModalContainerProps) => {
  const { openModalDialog } = useModal();

  const submit = () =>
    openModalDialog(CreateUnitListingModal, {
      name: unitName,
      id: unitId,
      propertyName,
    });

  return (
    <CreateUnitSuccessModal
      propertyName={propertyName}
      unitName={unitName}
      submit={submit}
    />
  );
};

export default CreateUnitSuccessModalContainer;
