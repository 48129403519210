import styled from "@emotion/styled";

import { COLORS } from "shared/config/constants";

const FilterItem = styled("div")<{
  order?: number;
  noShrink?: boolean;
  grow?: any;
}>((props) => ({
  display: "flex",
  padding: "24px",
  borderBottom: `1px solid ${COLORS.borderColorBase}`,
  ...(props.order && {
    order: props.order,
  }),
}));

const FilterLabel = styled.p`
  font-size: 15px;
  margin-right: 30px;
  width: 105px;
`;

const FilterText = styled.span`
  font-weight: 500;
  font-size: 15px;
  color: ${COLORS.actionLink.default};
  cursor: pointer;
  margin: 0 10px;
`;

export { FilterItem, FilterLabel, FilterText };
