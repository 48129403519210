import React from "react";

import { Formik } from "formik";
import { Helmet } from "react-helmet";

import Container from "shared/components/Container";
import DetectableOverflow from "shared/components/DetectableOverflow";
import FlexContainer from "shared/components/FlexContainer";
import { Input, Select, VeroFormField } from "shared/components/Form";
import Icon from "shared/components/Icon";
import { BackLink, UnderlineLink } from "shared/components/Links";
import PageTitle from "shared/components/PageTitle";
import Spacer from "shared/components/Spacer";
import Table from "shared/components/Table";
import Tooltip from "shared/components/Tooltip";
import { RegularText } from "shared/components/Typography";
import VerticalDivider from "shared/components/VerticalDivider";
import TablePage from "shared/templates/TablePage";

import NoLicenceSection from "../components/NoLicenceSection";

import { FIELD_TYPES } from "./config";
import { BluemoonFieldsMappingProps } from "./interfaces";
import {
  LoaderContainer,
  IconContainer,
  GreenArrowIcon,
  VerticalDividerContainer,
  InfoBanner,
  OptionGroupLabel,
  FieldNameWrapper,
  FilterBarIconWrapper,
  TableWrapper,
} from "./styled";

const title = "Fields Mapping";

const BluemoonFieldsMapping = ({
  backLink,
  fields = [],
  types,
  dataPoints,
  filters,
  updateFilters,
  isLoading,
  handleUpdateFieldName,
  handleFieldTypeChange,
  handleFieldDatapointChange,
  selectedField,
  onFieldTypeInfoIconClick,
  onDescriptionClick,
  isFieldUpdating,
  fieldErrors,
}: BluemoonFieldsMappingProps) => {
  const getFieldTypeValue = (fieldsRow) => {
    if (fieldsRow.veroHasPriority) {
      return "Smart (VERO Priority)";
    }
    if (!fieldsRow.veroHasPriority && fieldsRow.source) {
      return "Smart (Bluemoon Priority)";
    }
    return "Manual";
  };
  return (
    <>
      {isFieldUpdating && (
        <LoaderContainer>
          <Icon.LoadingSpinnerGreenLargeIcon
            className="loader"
            data-testid="loading"
          />
        </LoaderContainer>
      )}
      {fields?.length >= 0 ? (
        <Formik onSubmit={() => null} initialValues={null}>
          <TablePage
            title={title}
            subtitle="Edit and view field mappings for lease builder"
            backLink={backLink}
            banner={
              <InfoBanner>
                <div className="icons-wrapper">
                  <GreenArrowIcon width="30px" />
                  <GreenArrowIcon width="30px" rotate="rotateZ(180deg)" />
                </div>
                <RegularText light>
                  Fields are imported from Bluemoon and mapped to a VERO Field
                  Type. Select the proper field type and add additional data
                  points as required. If Field Type is uncertain, fields are
                  mapped by default to &quot;Manual&quot; until changed.
                </RegularText>
              </InfoBanner>
            }
            tables={[
              {
                filterBar: {
                  search: {
                    action: (value) => updateFilters({ nameType: value }),
                    placeholder: "Search by name, nickname, or type...",
                    width: 340,
                    debounce: true,
                    disabled: false,
                    value: filters.nameType,
                  },
                  dropdownFilters: types?.length > 0 && [
                    {
                      key: "fieldTypes",
                      label: "Field Types",
                      onChange: (value) => updateFilters({ type: value }),
                      value: filters.type,
                      items: [
                        {
                          label: "Field Types",
                          key: "",
                        },
                        ...types?.map((fieldType) => ({
                          key: fieldType[0],
                          label: fieldType[1],
                        })),
                      ],
                      minWidth: 280,
                    },
                  ],
                  justifyContent: "flex-start",
                  action: {
                    node: (
                      <FilterBarIconWrapper onClick={onFieldTypeInfoIconClick}>
                        <Icon.PurpleQuestionMark className="icon-question-mark" />
                      </FilterBarIconWrapper>
                    ),
                  },
                },
                node: (
                  <TableWrapper>
                    <Table
                      alt
                      loading={isLoading}
                      placeholder={{
                        Icon: Icon.NoDataIcon,
                        label: "Fields",
                      }}
                      columns={[
                        {
                          title: "",
                          dataIndex: "index",
                          key: "index",
                          className: "column-index",
                        },
                        {
                          title: "Bluemoon Field Name",
                          dataIndex: "name",
                          key: "name",
                          className: "column-name",
                        },
                        {
                          title: "",
                          dataIndex: "arrow",
                          key: "arrow",
                          className: "column-arrow",
                        },
                        {
                          title: "Map To VERO Field Type",
                          dataIndex: "mapToVeroFieldType",
                          key: "mapToVeroFieldType",
                          className: "column-map-to-vero-field-type",
                        },
                        {
                          title: "",
                          dataIndex: "divider",
                          key: "divider",
                        },
                        {
                          title: "Complete Field Datapoints",
                          dataIndex: "completeFieldDatapoints",
                          key: "completeFieldDatapoints",
                          className: "column-field-data-points",
                        },
                        {
                          title: "",
                          dataIndex: "actionLink",
                          key: "actionLink",
                        },
                      ]}
                      rows={fields.map((fieldsRow, index) => ({
                        key: fieldsRow.id,
                        values: [
                          <span>{index + 1}.</span>,
                          <FieldNameWrapper>
                            <Tooltip
                              title={
                                fieldsRow.nickname
                                  ? `Bluemoon name <${fieldsRow.name}> was updated`
                                  : "Updating a Field Name only changes the name displayed to users in the Lease Builder"
                              }
                              trigger="hover"
                              placement={
                                fieldsRow.nickname ? "topRight" : "topLeft"
                              }
                              arrowPointAtCenter={false}
                            >
                              <VeroFormField
                                as={Input}
                                name={fieldsRow.name}
                                id={fieldsRow.name}
                                value={
                                  fieldsRow.nickname ??
                                  fieldsRow.bluemoonNickname ??
                                  `<${fieldsRow.name}>`
                                }
                                onChange={() => undefined}
                                onBlur={(e) =>
                                  handleUpdateFieldName(
                                    e.target.value,
                                    fieldsRow
                                  )
                                }
                                error={
                                  fieldsRow.id === selectedField?.id &&
                                  fieldErrors?.find(
                                    (error) => error.field === "nickname"
                                  )?.message
                                }
                                className="input-field"
                                suffix={
                                  fieldsRow.nickname ? (
                                    <Icon.InputInfoIcon className="info-icon" />
                                  ) : null
                                }
                              />
                            </Tooltip>
                          </FieldNameWrapper>,
                          <IconContainer>
                            <GreenArrowIcon />
                          </IconContainer>,
                          <VeroFormField
                            as={Select}
                            name="fieldType"
                            id="fieldType"
                            label=""
                            value={
                              fieldsRow.id === selectedField?.id
                                ? selectedField?.fieldType ||
                                  getFieldTypeValue(fieldsRow)
                                : getFieldTypeValue(fieldsRow)
                            }
                            onChange={(option) =>
                              handleFieldTypeChange(option, fieldsRow)
                            }
                          >
                            {types.map((field) => (
                              <Select.Option key={field[1]} value={field[0]}>
                                <DetectableOverflow title={field[1]}>
                                  {field[1]}
                                </DetectableOverflow>
                              </Select.Option>
                            ))}
                          </VeroFormField>,
                          <VerticalDividerContainer>
                            <VerticalDivider />
                          </VerticalDividerContainer>,
                          <VeroFormField
                            as={Select}
                            name="fieldDatapoint"
                            id="fieldDatapoint"
                            label="Data Mapping"
                            disabled={
                              (!fieldsRow.veroHasPriority &&
                                !fieldsRow.source?.attribute &&
                                fieldsRow.id !== selectedField?.id) ||
                              (selectedField?.fieldType ===
                                FIELD_TYPES.MANUAL &&
                                fieldsRow.id === selectedField?.id)
                            }
                            error={
                              fieldsRow.id === selectedField?.id &&
                              !fieldsRow.source &&
                              selectedField?.fieldType !== FIELD_TYPES.MANUAL &&
                              "Must select data mapping"
                            }
                            value={fieldsRow.source?.attribute}
                            optionFilterProp="label"
                            onChange={(option) =>
                              handleFieldDatapointChange(option, fieldsRow)
                            }
                          >
                            <Select.OptGroup
                              label={
                                <OptionGroupLabel>
                                  Lease level questions
                                </OptionGroupLabel>
                              }
                            >
                              {dataPoints?.lease.sort().map((dataPoint) => (
                                <Select.Option
                                  label={dataPoint}
                                  key={`lease-${dataPoint}`}
                                  value={`${dataPoint}+lease`}
                                >
                                  <DetectableOverflow title={dataPoint}>
                                    {dataPoint}
                                  </DetectableOverflow>
                                </Select.Option>
                              ))}
                            </Select.OptGroup>
                            <Select.OptGroup
                              label={
                                <OptionGroupLabel>
                                  Property level questions
                                </OptionGroupLabel>
                              }
                            >
                              {dataPoints?.property.sort().map((dataPoint) => (
                                <Select.Option
                                  label={dataPoint}
                                  key={`property-${dataPoint}`}
                                  value={`${dataPoint}+property`}
                                >
                                  <DetectableOverflow title={dataPoint}>
                                    {dataPoint}
                                  </DetectableOverflow>
                                </Select.Option>
                              ))}
                            </Select.OptGroup>
                            <Select.OptGroup
                              label={
                                <OptionGroupLabel>
                                  Applicant level questions
                                </OptionGroupLabel>
                              }
                            >
                              {dataPoints?.applicant.sort().map((dataPoint) => (
                                <Select.Option
                                  label={dataPoint}
                                  key={`applicant-${dataPoint}`}
                                  value={`${dataPoint}+applicant`}
                                >
                                  <DetectableOverflow title={dataPoint}>
                                    {dataPoint}
                                  </DetectableOverflow>
                                </Select.Option>
                              ))}
                            </Select.OptGroup>
                          </VeroFormField>,
                          <FlexContainer justifyContent="flex-end">
                            <UnderlineLink
                              onClick={() => onDescriptionClick(fieldsRow)}
                            >
                              Description
                            </UnderlineLink>
                          </FlexContainer>,
                        ],
                      }))}
                      columnBreakpoints={{
                        xs: {
                          column1: "name",
                          column2: "mapToVeroFieldType",
                        },
                        sm: ["name", "mapToVeroFieldType"],
                        md: [
                          "name",
                          "mapToVeroFieldType",
                          "completeFieldDatapoints",
                        ],
                        lg: [
                          "name",
                          "arrow",
                          "mapToVeroFieldType",
                          "completeFieldDatapoints",
                          "actionLink",
                        ],
                      }}
                      noClickableRows
                    />
                  </TableWrapper>
                ),
              },
            ]}
          />
        </Formik>
      ) : (
        <div>
          <Container expand noPaddingBottom>
            <Helmet>
              <title>Bluemoon {title}</title>
            </Helmet>
            {backLink && (
              <BackLink.Container>
                {/* @ts-ignore */}
                <BackLink {...backLink} />
              </BackLink.Container>
            )}
            <div>
              <PageTitle>{title}</PageTitle>
              <PageTitle.Subtitle>
                Edit and view Bluemoon field mappings for VERO lease builder
              </PageTitle.Subtitle>
            </div>
            <Spacer />
            <NoLicenceSection page="FIELDS_MAPPING" />
          </Container>
        </div>
      )}
    </>
  );
};

export default BluemoonFieldsMapping;
