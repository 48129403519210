import styled from "@emotion/styled";

import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

const PortfolioLabel = styled.label`
  color: ${COLORS.textColorPrimary};
  display: flex;
  align-items: center;
  line-height: 130%;
  svg {
    margin-left: 7px;
  }
`;

const GreyLabel = styled.p`
  display: flex;
  align-items: center;
  color: ${COLORS.textColorSecondary};
  line-height: 130%;
  margin-bottom: 0;
  ${MEDIA_QUERIES.w.gteXl} {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-wwidth: 440px;
  }
  ${MEDIA_QUERIES.w.gteXxl} {
    max-width: 570px;
  }
  ${MEDIA_QUERIES.w.gteXxxl} {
    max-width: 720px;
  }
`;

const PropertyIntegrationMarkContainer = styled.div<{
  opacity?: number;
}>`
  opacity: ${({ opacity }) => opacity ?? 1};
`;

export { PortfolioLabel, GreyLabel, PropertyIntegrationMarkContainer };
