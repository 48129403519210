import { DEAL_CATEGORIES } from "manager/config/constants";
import { OrderingProp, OrderingType } from "shared/interfaces";
import { SessionStorageCollectionManager } from "shared/utils/sessionStorage";

export const DEALS_FILTERS_STORAGE = "DEALS_FILTERS_STORAGE";
export const DEALS_COUNTS_STORAGE = "DEALS_COUNTS_STORAGE";

// @ts-ignore
export const dealsFiltersStorage = new SessionStorageCollectionManager({
  storageKey: DEALS_FILTERS_STORAGE,
});

export const dealsCountsStorage = new SessionStorageCollectionManager({
  storageKey: DEALS_COUNTS_STORAGE,
});
export const ORDER_BY_NAMES = Object.freeze({
  unitName: "unit__name",
  propertyName: "_property__name",
  createdAt: "created_at",
  rent: "combined_rent",
  lumenResult: "last_lumen_score",
});

export const ORDER_BY = Object.freeze({
  unit: {
    ascend: [ORDER_BY_NAMES.propertyName, ORDER_BY_NAMES.unitName].join(),
    descend: [ORDER_BY_NAMES.propertyName, ORDER_BY_NAMES.unitName]
      .map((name) => `-${name}`)
      .join(),
  },
  applicationDate: {
    ascend: ORDER_BY_NAMES.createdAt,
    descend: `-${ORDER_BY_NAMES.createdAt}`,
  },
  rent: {
    ascend: ORDER_BY_NAMES.rent,
    descend: `-${ORDER_BY_NAMES.rent}`,
  },
  lumenResult: {
    ascend: ORDER_BY_NAMES.lumenResult,
    descend: `-${ORDER_BY_NAMES.lumenResult}`,
  },
});

export const combineFilters = ({
  category,
  filters,
  pagination,
  ordering,
  showArchived,
  showOnlyPaidFees,
}) => {
  const { propertyId, dealStatus, lastLumenResult, ...otherFilters } = filters;

  const dealStatusFilter = (dealStatus || []).join(",");
  const propertyIdFilter = (propertyId || []).join(",");
  const lastLumenResultFilter = (lastLumenResult || []).join(",");

  return {
    ...otherFilters,
    dealCategory: DEAL_CATEGORIES.find((item) => item.key === category)?.apiKey,
    dealStatus: dealStatusFilter,
    propertyId: propertyIdFilter,
    lastLumenResult: lastLumenResultFilter,
    ...pagination,
    ordering,
    isArchived: showArchived ? null : false,
    isAnyApplicationFeePaid: showOnlyPaidFees ? true : null,
  };
};

export const blankFilters = {
  dateMin: null,
  dateMax: null,
  propertyId: [],
  unitPropertyOrApplicant: "",
  dealUiStatus: [],
  lastLumenResults: [],
};

export const KEYS = Object.freeze({
  filters: "filters",
  category: "category",
  pagination: "pagination",
  ordering: "ordering",
  showArchived: "showArchived",
  showOnlyPaidFees: "showOnlyPaidFees",
  dealsCounts: "dealsCounts",
});

export const getInitialOrderingColumn = (ordering: string): OrderingProp => {
  const columnKey = Object.keys(ORDER_BY).find((key) => {
    return (
      ORDER_BY[key].ascend === ordering || ORDER_BY[key].descend === ordering
    );
  });

  return columnKey
    ? {
        [columnKey]:
          ORDER_BY[columnKey].ascend === ordering
            ? OrderingType.ASC
            : OrderingType.DESC,
      }
    : {};
};
