import React from "react";

import PropTypes from "prop-types";

import {
  HeaderSubTitle,
  HeaderSubTitleContainer,
  HeaderTitle,
  HeaderTitleContainer,
} from "./styled";

const Header = ({ title, subTitle }) => (
  <>
    {title && (
      <HeaderTitleContainer>
        <HeaderTitle data-testid="title">{title}</HeaderTitle>
      </HeaderTitleContainer>
    )}
    {subTitle && (
      <HeaderSubTitleContainer hasTitle={!!title}>
        <HeaderSubTitle data-testid="subTitle">{subTitle}</HeaderSubTitle>
      </HeaderSubTitleContainer>
    )}
  </>
);

Header.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
};

Header.defaultProps = {
  title: undefined,
  subTitle: undefined,
};

export default Header;
