import React from "react";

import { CardContent } from "./styled";

export interface CardProps {
  children?: React.ReactNode | React.ReactNode[];
  width?: string;
  [key: string]: any;
}

const Card = ({ children, width, ...other }: CardProps) => {
  return (
    <CardContent className="card-content" width={width} {...other}>
      {children}
    </CardContent>
  );
};

export default Card;
