import React from "react";

import { Form, Formik, FieldArray, FormikHelpers } from "formik";

import { SubmittedLead } from "manager/interfaces/api";
import ErrorList from "shared/components/ErrorList";
import { Modal } from "shared/components/Modal";
import Spacer from "shared/components/Spacer";
import { BREAKPOINT_TYPES } from "shared/config/constants";
import {
  InviteApplicantsForm,
  InviteApplicantsLink,
} from "shared/forms/InviteApplicantsForm";
import withBreakpoint from "shared/hocs/withBreakpoint";
import OptionItem from "shared/interfaces/api/optionItem";

import AddLeadsForm from "./AddLeadsForm";
import { ApplicantsCheckList, Subtitle, LeadsTitle } from "./styled";
import {
  Applicant,
  initialValues,
  InviteApplicantsValues,
  validationSchema,
} from "./utils";

interface InviteApplicantsWithLeadsModalProps {
  submittedLeads?: SubmittedLead[];
  isSubmittedLeadsLoading: boolean;
  onSubmit: (
    values: InviteApplicantsValues,
    helpers: FormikHelpers<InviteApplicantsValues>
  ) => void;
  canInviteLeads: boolean;
  applicantTypes?: { [key: string]: OptionItem };
  applicantTypesLoading?: boolean;
  applicants?: Applicant[];
  breakpoint?: BREAKPOINT_TYPES;
}

const InviteApplicantsWithLeadsModal = ({
  submittedLeads = [],
  isSubmittedLeadsLoading,
  onSubmit,
  canInviteLeads,
  applicantTypes,
  applicantTypesLoading,
  applicants = [],
  breakpoint,
}: InviteApplicantsWithLeadsModalProps) => {
  const hasApplicants = !!applicants?.length || undefined;
  const showInviteLeadsSection = canInviteLeads && submittedLeads?.length > 0;

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={initialValues}
      validateOnChange
      validateOnBlur
      onSubmit={onSubmit}
    >
      {({
        errors,
        touched,
        values,
        submitForm,
        isSubmitting,
        validateForm,
      }) => (
        <Form>
          <Modal
            width="auto"
            title="Invite Applicants"
            subtitle={
              hasApplicants && (
                <Subtitle>
                  <span>Applicants:&nbsp;</span>
                  <ApplicantsCheckList inline items={applicants} />
                </Subtitle>
              )
            }
            submit={submitForm}
            submitButtonLabel="Invite Applicants"
            style={{ maxWidth: 946 }}
            submitting={isSubmitting}
            closeOnSubmit={false}
            fullScreen={BREAKPOINT_TYPES.xs === breakpoint}
          >
            {errors.nonFieldErrors && (
              <Modal.Body noPaddingBottom>
                <ErrorList errors={errors.nonFieldErrors} />
              </Modal.Body>
            )}
            <Modal.Body spacer={showInviteLeadsSection}>
              <FieldArray
                name="applicants"
                render={(arrayHelpers) => (
                  <>
                    <InviteApplicantsForm
                      applicantTypes={applicantTypes}
                      applicantTypesLoading={applicantTypesLoading}
                      applicants={values.applicants}
                      errors={errors.applicants}
                      touched={touched.applicants}
                      deleteApplicant={arrayHelpers.remove}
                    />
                    <Spacer size={Spacer.SIZES.sm} />
                    <InviteApplicantsLink
                      applicants={values.applicants}
                      checkValidation={validateForm}
                      addApplicant={arrayHelpers.push}
                    />
                  </>
                )}
              />
            </Modal.Body>
            {showInviteLeadsSection && (
              <Modal.Body>
                <LeadsTitle>
                  You can invite a lead to apply and add to this application
                  group
                </LeadsTitle>
                <FieldArray
                  name="leads"
                  render={(arrayHelpers) => (
                    <AddLeadsForm
                      selectedLeads={values.leads}
                      deleteLead={arrayHelpers.remove}
                      addLead={arrayHelpers.push}
                      leadOptions={submittedLeads}
                      isLeadOptionsLoading={isSubmittedLeadsLoading}
                    />
                  )}
                />
              </Modal.Body>
            )}
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default withBreakpoint(InviteApplicantsWithLeadsModal);
