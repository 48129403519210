import React from "react";

import { Col, Row } from "antd-latest";

import { FadeIn, SlideDirection, useFadeIn } from "shared/components/animated";
import { Button } from "shared/components/Button";
import { SIZES } from "shared/components/Button/Button";
import Icon from "shared/components/Icon";
import Spacer from "shared/components/Spacer";
import { MediumTitle, RegularText } from "shared/components/Typography";
import { COLORS } from "shared/config/constants";
import { numberWithCommas } from "shared/utils/ui";

import { InfoWell, SpacerSmall } from "./blocks";
import { PropertyUnit, Unit } from "./types";
import { getMonthLabel } from "./utils";

export const LeaseRentOptionsConfirmed: React.FC<{
  link: string | undefined;
  unit: Unit;
  property: PropertyUnit;
  email: string;
}> = ({ unit, property, email, link }) => {
  const breakpoints = { xs: 24, sm: 18, xl: 16, xxl: 12 };
  const onClick = () => {
    window.location.href = link;
  };
  const fade = useFadeIn({
    stagger: 100,
    delay: 50,
    direction: SlideDirection.left,
    amount: 50,
  });

  return (
    <>
      <Row justify="center" style={{ backgroundColor: COLORS.backgroundGrey2 }}>
        <Col
          {...breakpoints}
          style={{
            padding: "24px",
            textAlign: "center",
          }}
        >
          <SpacerSmall />
          <FadeIn {...fade({ direction: null })}>
            <Icon.SuccessIcon width={100} height={100} fill="#22E295" />
          </FadeIn>

          <Spacer />
          <FadeIn {...fade()}>
            <MediumTitle>
              Well done! Your leasing{" "}
              <span style={{ whiteSpace: "nowrap" }}>
                application has started
              </span>
            </MediumTitle>
          </FadeIn>

          <SpacerSmall />

          <FadeIn {...fade()}>
            <RegularText>
              {property.property}, {property.unit}
            </RegularText>
          </FadeIn>
          <FadeIn {...fade()}>
            <RegularText>
              {property.street} {property.city}, {property.stateCode}{" "}
              {property.zipCode}
            </RegularText>
          </FadeIn>
        </Col>
      </Row>
      <Row justify="center" style={{ backgroundColor: COLORS.white }}>
        <Col {...breakpoints} style={{ padding: "24px" }}>
          <FadeIn {...fade()}>
            <RegularText>
              We have received your details and preferences. Please check your
              email to proceed with your application.
            </RegularText>
          </FadeIn>

          <Spacer />
          <FadeIn {...fade()}>
            <RegularText>{email}</RegularText>
          </FadeIn>

          <SpacerSmall />
          <FadeIn {...fade()}>
            <RegularText>Rent price:</RegularText>{" "}
            <RegularText strong>
              ${numberWithCommas(unit.range.price)}
            </RegularText>
          </FadeIn>

          <SpacerSmall />
          <FadeIn {...fade()}>
            <RegularText>Move-in:</RegularText>{" "}
            <RegularText strong>{unit.day.format("MMMM D, YYYY")}</RegularText>
          </FadeIn>

          <SpacerSmall />

          <FadeIn {...fade()}>
            <RegularText>Lease duration:</RegularText>{" "}
            <RegularText strong>{getMonthLabel(unit.range.term)}</RegularText>
          </FadeIn>

          <Spacer />
          <FadeIn {...fade()}>
            <InfoWell />
          </FadeIn>

          <Spacer />
          {Boolean(link) && (
            <FadeIn {...fade()}>
              <Button
                type="secondary"
                size={SIZES.lg}
                style={{ width: "300px" }}
                onClick={onClick}
              >
                GOT IT
              </Button>
            </FadeIn>
          )}
        </Col>
      </Row>
    </>
  );
};
