// @ts-nocheck
import React from "react";

import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import { TRANSITIONS } from "shared/config/constants";

const linkStyles = ({ nowrap }) => ({
  transition: TRANSITIONS.default,
  whiteSpace: nowrap && "nowrap",
  width: "fit-content",
});

// NOTE: using a wrapper for disabled links only to show the "cursor: not-allowed".
// We disable links (anchor) with "pointer-events: none", which prevents showing the cursor, that's why we need this wrapper.
// The wrapper is applied only in cases when the link is disabled (not always) to reduce the impact of this approach.
const DisabledWrapper = styled.span`
  cursor: not-allowed;
`;

const StyledLinkBase = styled(Link || "a")(linkStyles);

const StyledStandardLinkBase = styled("a")(linkStyles);

// eslint-disable-next-line react/prop-types
const StyledLink = ({ disabled, ...props }) =>
  disabled ? (
    <DisabledWrapper>
      <StyledLinkBase disabled={disabled} {...props} />
    </DisabledWrapper>
  ) : (
    <StyledLinkBase disabled={disabled} {...props} />
  );

// eslint-disable-next-line react/prop-types
const StyledStandardLink = ({ disabled, ...props }) =>
  disabled ? (
    <DisabledWrapper>
      <StyledStandardLinkBase disabled={disabled} {...props} />
    </DisabledWrapper>
  ) : (
    <StyledStandardLinkBase disabled={disabled} {...props} />
  );

export { StyledLink, StyledStandardLink };
