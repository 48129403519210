import React from "react";

import { Row, Col } from "antd";

import { formatDate, formatDateTime } from "shared/utils/ui";

import Spacer from "../Spacer";
import Tile from "../Tile";
import { RegularText, SmallText } from "../Typography";

import {
  SurveyQuestionListItem,
  SurveyNotSubmitedTitle,
  RequestSurveyButton,
} from "./styled";
import { SurveyDetails } from "./verification.interfaces";

export interface SurveyResponseCardProps {
  verificationDate: string;
  survey: SurveyDetails;
  handleRequestSurvey: Function;
  showQuestions: boolean;
  isLoading: boolean;
}

const SurveyResponseCard = ({
  verificationDate,
  survey,
  handleRequestSurvey,
  showQuestions,
  isLoading,
}: SurveyResponseCardProps) => {
  return (
    <Tile noBorder>
      <Tile.Inner>
        <RegularText strong>SURVEY RESPONSE</RegularText>
        {verificationDate && (
          <div>Submitted: {formatDateTime(verificationDate)}</div>
        )}
      </Tile.Inner>
      <Tile.Inner>
        {!showQuestions ? (
          <Row type="flex" justify="center">
            <Col>
              <SurveyNotSubmitedTitle>
                Survey has not been submitted
              </SurveyNotSubmitedTitle>
              {/* @ts-ignore */}
              <RequestSurveyButton
                type="primary"
                // @ts-ignore
                onClick={handleRequestSurvey}
                loading={isLoading}
              >
                REQUEST SURVEY
              </RequestSurveyButton>
              <Col>
                <Row type="flex" justify="center">
                  <RegularText strong>
                    {`Last Requested: ${formatDate(survey?.lastNotification)}`}
                  </RegularText>
                </Row>
                <Row type="flex" justify="center">
                  <SmallText>{`(Total Requests: ${survey?.numOfNotifications})`}</SmallText>
                </Row>
              </Col>
            </Col>
          </Row>
        ) : (
          <Row>
            {(survey?.surveyQuestions || []).map((surveyItem, index) => {
              return (
                <Col xs={24} key={surveyItem.id}>
                  <SurveyQuestionListItem>
                    <Row>
                      {/* @ts-ignore */}
                      <RegularText className="question-number">
                        {index + 1}
                      </RegularText>{" "}
                      <RegularText strong>{surveyItem.question}</RegularText>
                    </Row>
                    <Spacer />
                    <Row>
                      <Col xs={10}>
                        {surveyItem.answer ? (
                          //  @ts-ignore
                          <RegularText className="survey-score">
                            Score:&nbsp;&nbsp;{surveyItem.answer}
                            /10
                          </RegularText>
                        ) : (
                          //  @ts-ignore
                          <RegularText className="survey-score">
                            N/A
                          </RegularText>
                        )}
                      </Col>
                      <Col xs={14}>
                        {surveyItem.comment && (
                          // @ts-ignore
                          <RegularText className="comment">
                            Comment:&nbsp;&nbsp;{surveyItem.comment}
                          </RegularText>
                        )}
                      </Col>
                    </Row>
                  </SurveyQuestionListItem>
                </Col>
              );
            })}
          </Row>
        )}
        <Spacer />
      </Tile.Inner>
    </Tile>
  );
};

export default SurveyResponseCard;
