import React from "react";

import styled from "@emotion/styled";

import FlexContainer from "shared/components/FlexContainer";

import { COLORS, MEDIA_QUERIES, colors } from "shared/config/constants";

import * as icons from "./icons";
import { getIcon, HoverTracker, withHover } from "./icons.utils";

const BellIcon = withHover(icons.Bell, icons.BellHover);
const ProfileIcon = withHover(icons.Profile, icons.ProfileHover);
const SupportIcon = withHover(icons.Support, icons.SupportHover);
const SettingsIcon = withHover(
  icons.SidebarSettings,
  icons.SidebarSettingsHover
);
const EmployeesIcon = withHover(icons.Employees, icons.EmployeesHover);
const MyPropertyBoldIcon = icons.MyPropertyHover;
const MyPropertyIcon = withHover(
  icons.SidebarInventory,
  icons.SidebarInventoryHover
);
const DealsIcon = withHover(
  icons.SidebarApplications,
  icons.SidebarApplicationsHover
);
const LeadsIcon = withHover(icons.SidebarLeads, icons.SidebarLeadsHover);
const ApplicantsTenantsIcon = withHover(
  icons.ApplicantsTenants,
  icons.ApplicantsTenantsHover
);
const ApplicantInvite = getIcon(
  icons.ApplicantInviteBase,
  icons.ApplicantInviteHover,
  icons.ApplicantInviteDisabled
);
ApplicantInvite.tags = [
  "applicant",
  "invite",
  "plus",
  "hover",
  "disabled",
  "full",
  "complete",
];

const StarOutlineIcon = withHover(
  icons.BaseStarOutlineIcon,
  icons.StarOutlineHoverIcon
);
const DropdownIcon = withHover(icons.BaseDropdownIcon, icons.DropdownHoverIcon);

const DeleteIconWithHover = withHover(
  icons.BaseDeleteIcon,
  icons.DeleteHoverIcon
);
const DeleteIcon = (props) => (
  <HoverTracker Icon={DeleteIconWithHover} {...props} />
);
const DeleteSmallIcon = withHover(
  icons.BaseDeleteSmallIcon,
  icons.DeleteSmallHoverIcon
);
const ImportantIcon = withHover(icons.Important, icons.ImportantHover);
const SignOutIcon = withHover(icons.SignOut, icons.SignOutHover);
const PersonalInfoIcon = withHover(icons.PersonalInfo, icons.PersonalInfoHover);
const ShowPasswordIcon = withHover(icons.ShowPassword, icons.ShowPasswordHover);
const HidePasswordIcon = withHover(icons.HidePassword, icons.HidePasswordHover);
const ApplicationsIcon = withHover(icons.Applications, icons.ApplicationsHover);
const LeaseEyeIcon = withHover(icons.LeaseEye, icons.LeaseEye);
const DocumentsIcon = withHover(icons.Documents, icons.Documents);
const ArrowLeftUpIcon = getIcon(icons.LeftUp, icons.LeftOver);
const ArrowRightUpIcon = getIcon(icons.RightUp, icons.RightOver);
const ZoomPlusIcon = getIcon(
  icons.ZoomPlus,
  icons.ZoomPlusHover,
  icons.ZoomPlusDisabled
);
const ZoomMinusIcon = getIcon(
  icons.ZoomMinus,
  icons.ZoomMinusHover,
  icons.ZoomMinusDisabled
);
const CrossRemoveIcon = getIcon(icons.CrossRemove, icons.CrossRemoveHovered);
const IntegrationsMenuItemIcon = withHover(
  icons.SidebarMarketplace,
  icons.SidebarMarketplaceHover
);
const SelectArrowCompleteIcon = getIcon(
  icons.SelectArrowIcon,
  icons.SelectArrowIcon,
  icons.SelectArrowIconDisabled
);
const DollarSignIcon = getIcon(
  icons.DollarSignGreen,
  icons.DollarSignGreen,
  icons.DollarSignGray
);

const NoteIcon = getIcon(
  icons.NoteActiveIcon,
  icons.NoteHoverIcon,
  icons.NoteDisabledIcon
);

const DownloadPdfDoc = getIcon(
  icons.DownloadPdfDocBase,
  icons.DownloadPdfDocHover
);

DownloadPdfDoc.tags = ["pdf", "download"];

const DownloadPdfDocGray = getIcon(
  styled(icons.DownloadPdfDocBase)`
    path {
      fill: ${COLORS.gray3};
    }
  `,
  icons.DownloadPdfDocBase
);

DownloadPdfDocGray.tags = ["pdf", "download", "gray"];

const BaseDeleteSmallNoCircleDisabledIcon = styled(
  icons.BaseDeleteSmallNoCircleIcon
)({
  opacity: 0.3,
});
const DeleteSmallNoCircleIcon = getIcon(
  icons.BaseDeleteSmallNoCircleIcon,
  icons.DeleteSmallHoverIcon,
  BaseDeleteSmallNoCircleDisabledIcon
);

const ArrowUpIcon = () => (
  <icons.ArrowDownIcon style={{ transform: "rotate(180deg)" }} />
);

// eslint-disable-next-line react/prop-types
const ChevronUpIcon = ({ style, ...props }) => (
  <icons.ChevronDownIcon
    style={{ transform: "rotate(180deg)", ...style }}
    {...props}
  />
);

// eslint-disable-next-line react/prop-types
const ChevronUpNewIcon = ({ style, ...props }) => (
  <icons.ChevronDownNewIcon
    style={{ transform: "rotate(180deg)", ...style }}
    {...props}
  />
);

// eslint-disable-next-line react/prop-types
const ChevronUpGreenIcon = ({ style, ...props }) => (
  <icons.ChevronDownGreenIcon
    style={{ transform: "rotate(180deg)", ...style }}
    {...props}
  />
);

// eslint-disable-next-line react/prop-types
const ChevronUpWhiteBoldIcon = ({ style, ...props }) => (
  <icons.ChevronDownWhiteBoldIcon
    style={{ transform: "rotate(180deg)", ...style }}
    {...props}
  />
);

// eslint-disable-next-line react/prop-types
const ChevronBoldUpIcon = ({ style, ...props }) => (
  <icons.ChevronBoldDownIcon
    style={{ transform: "rotate(180deg)", ...style }}
    {...props}
  />
);

const DropdownButtonChevronUpIcon = (props) => (
  <icons.DropdownButtonChevronDownIcon
    {...props}
    style={{ transform: "rotate(180deg)" }}
  />
);

const ProfileContainer = styled(FlexContainer)`
  width: 56px;
  height: 40px;
  padding: 4px;
  border-radius: 4px;
  background: transparent;
  &:hover {
    background: ${colors.neutral[200]};
  }
`;

// TODO: rename for consistency
// eslint-disable-next-line react/prop-types
const ProfileIconWithChevron = ({ active = false, hovered, ...props }) => (
  <ProfileContainer alignItems="center">
    <icons.Profile {...props} hovered={hovered} />
    {active ? (
      <ChevronUpNewIcon style={{ marginLeft: "3px" }} width={16} height={16} />
    ) : (
      <icons.ChevronDownNewIcon
        style={{ marginLeft: "3px", width: "16px", height: "16px" }}
      />
    )}
  </ProfileContainer>
);

const PdfMobileDownloadIcon = styled(
  withHover(icons.PdfDownloadMobile, icons.PdfDownloadMobileHover)
)({
  [MEDIA_QUERIES.w.gteMd]: {
    display: "none",
  },
});

const PdfDesktopDownloadIcon = styled(
  withHover(icons.PdfDownloadDesktop, icons.PdfDownloadDesktopHover)
)({
  [MEDIA_QUERIES.w.ltMd]: {
    display: "none",
  },
});

const PdfDownloadIcon = (props) => (
  <>
    <PdfMobileDownloadIcon {...props} />
    <PdfDesktopDownloadIcon {...props} />
  </>
);

const VerifiedIncomeAssetsDesktop = styled(icons.OperationSuccess)({
  [MEDIA_QUERIES.w.ltMd]: {
    display: "none",
  },
});
const VerifiedIncomeAssetsMobile = styled(icons.VerifiedIncomeAssetsSmall)({
  [MEDIA_QUERIES.w.gteMd]: {
    display: "none",
  },
});
const VerifiedIncomeAssetsIcon = (props) => (
  <>
    <VerifiedIncomeAssetsDesktop {...props} />
    <VerifiedIncomeAssetsMobile {...props} />
  </>
);

const NotVerifiedIncomeAssetsDesktop = styled(icons.NotVerifiedIncomeAssets)({
  [MEDIA_QUERIES.w.ltMd]: {
    display: "none",
  },
});
const NotVerifiedIncomeAssetsMobile = styled(
  icons.NotVerifiedIncomeAssetsSmall
)({
  [MEDIA_QUERIES.w.gteMd]: {
    display: "none",
  },
});
const NotVerifiedIncomeAssetsIcon = (props) => (
  <>
    <NotVerifiedIncomeAssetsDesktop {...props} />
    <NotVerifiedIncomeAssetsMobile {...props} />
  </>
);

const QRCodeGreenHoverIcon = withHover(icons.QRCodeIcon, icons.QRCodeGreenIcon);
QRCodeGreenHoverIcon.tags = ["QR", "download", "green", "hover"];

const DocumentsEmptyGreenHoverIcon = withHover(
  icons.DocumentsEmptyIcon,
  icons.DocumentsEmptyGreenIcon
);

DocumentsEmptyGreenHoverIcon.tags = ["documents", "copy", "green", "hover"];

const ExportIconHover = styled(
  withHover(icons.ExportIcon, icons.ExportIconGreen)
)({
  cursor: "pointer",
});

ExportIconHover.tags = ["export", "green", "hover"];

const OrangeExclamationSmall = (props) => (
  <icons.OrangeExclamation height="16px" width="16px" {...props} />
);
OrangeExclamationSmall.tags = ["exclamation", "warning", "orange", "small"];

// Bulb icons
const BulbGreenBig = () => <icons.BulbGreenSmall height="32px" width="32px" />;
const BulbPurpleBig = () => (
  <icons.BulbPurpleSmall height="32px" width="32px" />
);
const BulbOrangeBig = () => (
  <icons.BulbOrangeSmall height="32px" width="32px" />
);
const BulbRedBig = () => <icons.BulbRedSmall height="32px" width="32px" />;

BulbGreenBig.tags = ["lumen", "big", "bulb", "green"];
BulbOrangeBig.tags = ["lumen", "big", "bulb", "orange"];
BulbPurpleBig.tags = ["lumen", "big", "bulb", "purple"];
BulbRedBig.tags = ["lumen", "big", "bulb", "red"];

const AddAttachmentPlus = getIcon(
  icons.AddAttachmentPlusBase,
  icons.AddAttachmentPlusHover,
  icons.AddAttachmentPlusDisabled
);

AddAttachmentPlus.tags = ["attachment", "plus", "circle"];

const RefreshIconSmall = () => <icons.RefreshIcon height="15px" width="15px" />;
const RefreshRotateIconSmall = () => (
  <icons.RefreshRotateIcon height="15px" width="15px" />
);
RefreshIconSmall.tags = ["refresh", "small", "green"];
RefreshRotateIconSmall.tags = [
  "refresh",
  "rotate",
  "loading",
  "small",
  "green",
];

const ErrorXWarningGray = styled(icons.ErrorDarkXwarning)`
  path {
    fill: ${COLORS.gray3};
  }
`;
ErrorXWarningGray.tags = ["error", "warning", "gray"];

const ShowPasswordActionIcon = (props) => (
  <HoverTracker Icon={ShowPasswordIcon} {...props} />
);

const HidePasswordActionIcon = (props) => (
  <HoverTracker Icon={HidePasswordIcon} {...props} />
);

const LynkSuccessSmall = () => (
  <icons.BaseLynkSuccess height="24px" width="24px" />
);
const LynkSkippedSmall = () => (
  <icons.BaseLynkSkipped height="24px" width="24px" />
);

const SnapPassedSmall = () => (
  <icons.BaseSnapPassed height="24px" width="28px" />
);
const SnapFailedSmall = () => (
  <icons.BaseSnapFailed height="24px" width="28px" />
);
const SnapPendingSmall = () => (
  <icons.BaseSnapPending height="24px" width="28px" />
);

/* eslint-disable react/prop-types */
const LynkSuccess = ({ small }) =>
  small ? <LynkSuccessSmall /> : <icons.BaseLynkSuccess />;
const LynkSkipped = ({ small }) =>
  small ? <LynkSkippedSmall /> : <icons.BaseLynkSkipped />;

const SnapPassed = ({ small }) =>
  small ? <SnapPassedSmall /> : <icons.BaseSnapPassed />;
const SnapFailed = ({ small }) =>
  small ? <SnapFailedSmall /> : <icons.BaseSnapFailed />;
const SnapPending = ({ small }) =>
  small ? <SnapPendingSmall /> : <icons.BaseSnapPending />;
/* eslint-enable react/prop-types */

LynkSuccess.tags = [...icons.BaseLynkSuccess.tags, "dynamic", "small", "large"];
LynkSkipped.tags = [...icons.BaseLynkSkipped.tags, "dynamic", "small", "large"];
SnapPassed.tags = [...icons.BaseSnapPassed.tags, "dynamic", "small", "large"];
SnapFailed.tags = [...icons.BaseSnapFailed.tags, "dynamic", "small", "large"];
SnapPending.tags = [...icons.BaseSnapFailed.tags, "dynamic", "small", "large"];

const LinkedInLogoSmall = () => (
  <icons.LinkedInLogo height="18px" width="18px" />
);
LinkedInLogoSmall.tags = ["linkedin", "logo", "small"];

const CheckGreenXl = () => <icons.CheckGreenBig height="74px" width="74px" />;
CheckGreenXl.tags = ["check", "xl", "approve", "green"];

const PDFPreviewIconGray = styled(icons.PDFPreviewIcon)`
  path {
    fill: ${COLORS.gray2};
  }
`;

const PDFPreviewIconBlue = styled(icons.PDFPreviewIcon)`
  path {
    fill: ${COLORS.lightBlue};
  }
`;

const LogoIconSmall = styled(icons.LogoIcon)`
  height: 10px;
  width: 51px;
`;

export {
  BellIcon,
  ProfileIcon,
  SupportIcon,
  SettingsIcon,
  ArrowUpIcon,
  EmployeesIcon,
  MyPropertyBoldIcon,
  MyPropertyIcon,
  DealsIcon,
  LeadsIcon,
  ApplicantsTenantsIcon,
  ApplicantInvite,
  StarOutlineIcon,
  DropdownIcon,
  DeleteIcon,
  DeleteSmallIcon,
  DeleteSmallNoCircleIcon,
  ChevronUpIcon,
  ChevronUpGreenIcon,
  ChevronBoldUpIcon,
  ProfileIconWithChevron,
  PdfDownloadIcon,
  ImportantIcon,
  IntegrationsMenuItemIcon,
  SignOutIcon,
  PersonalInfoIcon,
  ShowPasswordIcon,
  ShowPasswordActionIcon,
  HidePasswordIcon,
  HidePasswordActionIcon,
  DropdownButtonChevronUpIcon,
  SelectArrowCompleteIcon,
  ApplicationsIcon,
  VerifiedIncomeAssetsIcon,
  NotVerifiedIncomeAssetsIcon,
  DocumentsIcon,
  LeaseEyeIcon,
  ArrowLeftUpIcon,
  ArrowRightUpIcon,
  ZoomPlusIcon,
  ZoomMinusIcon,
  CrossRemoveIcon,
  DollarSignIcon,
  NoteIcon,
  QRCodeGreenHoverIcon,
  DocumentsEmptyGreenHoverIcon,
  OrangeExclamationSmall,
  BulbGreenBig,
  BulbOrangeBig,
  BulbPurpleBig,
  BulbRedBig,
  DownloadPdfDoc,
  ExportIconHover,
  AddAttachmentPlus,
  RefreshIconSmall,
  RefreshRotateIconSmall,
  ErrorXWarningGray,
  DownloadPdfDocGray,
  LynkSuccess,
  LynkSkipped,
  SnapPassed,
  SnapFailed,
  SnapPending,
  LinkedInLogoSmall,
  CheckGreenXl,
  PDFPreviewIconGray,
  PDFPreviewIconBlue,
  LogoIconSmall,
  ChevronUpWhiteBoldIcon,
};
