import React from "react";

import styled from "@emotion/styled";
import { Steps } from "antd";

import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

const darkTheme = {
  backgroundColor: COLORS.primary,
  tail: COLORS.grayTheme.textColor,
  active: {
    text: COLORS.violetTheme.white,
    iconBackground: "transparent",
    iconBorder: COLORS.violetTheme.lightGreen,
    iconText: COLORS.violetTheme.white,
  },
  finish: {
    text: COLORS.disabledText,
    iconBackground: "transparent",
    iconBorder: COLORS.violetTheme.lightGreen,
    iconText: COLORS.violetTheme.lightGreen,
    hover: {
      text: COLORS.midGray,
      iconBorder: COLORS.midGray,
      iconText: COLORS.midGray,
    },
  },
  wait: {
    text: COLORS.disabledText,
    iconBackground: COLORS.black,
    iconBorder: COLORS.black,
    iconText: COLORS.disabledText,
    disabled: {
      text: COLORS.gray2,
      iconText: COLORS.gray2,
    },
    hover: {
      text: COLORS.midGray,
      iconText: COLORS.midGray,
    },
  },
};
export interface DarkTheme {
  backgroundColor?: string;
  tail?: string;
  active?: {
    text?: string;
    iconBackground?: string;
    iconBorder?: string;
    iconText?: string;
  };
  finish?: {
    text?: string;
    iconBackground?: string;
    iconBorder?: string;
    iconText?: string;
    hover?: {
      text?: string;
      iconBorder?: string;
      iconText?: string;
    };
  };
  wait?: {
    text?: string;
    iconBackground?: string;
    iconBorder?: string;
    iconText?: string;
    disabled?: {
      text?: string;
      iconText?: string;
    };
    hover?: {
      text?: string;
      iconText?: string;
    };
  };
}

const NUMBERED_STEPS_THEMES = Object.freeze({
  dark: "dark",
});

// const THEMES = {
//   [NUMBERED_STEPS_THEMES.dark]: darkTheme,
// };

const withThemeObj = (Component) => {
  return (props) => {
    // eslint-disable-next-line react/prop-types
    const { theme } = props;
    const themeObj = theme;
    return <Component themeObj={themeObj} {...props} />;
  };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const BaseStyledSteps = styled(({ themeObj, ...props }) => (
  <Steps {...props} />
))`
  border-radius: 2px;
  padding: 24px;
  background-color: ${(props) =>
    props.themeObj?.backgroundColor || COLORS.primary};
`;

const StyledSteps = withThemeObj(BaseStyledSteps);

// eslint-disable-next-line vero/styled-tagged-template
const StyledStep = styled(Steps.Step)(
  ({ ...props }: { theme: DarkTheme; vertical?: number }) => ({
    ".ant-steps-item-content": {
      ...(!props.vertical && {
        width: "164px",
      }),
      [MEDIA_QUERIES.w.ltLg]: {
        width: "100px",
      },
    },

    ".ant-steps-item-icon": {
      border: 0,
      ...(!props.vertical && {
        marginLeft: "64px",
        [MEDIA_QUERIES.w.ltLg]: {
          marginLeft: "34px",
        },
      }),
    },

    ".ant-steps-item-tail": {
      ...(!props.vertical && {
        marginLeft: "82px",
        paddingLeft: "64px",
        paddingRight: "64px",

        [MEDIA_QUERIES.w.ltLg]: {
          marginLeft: "50px",
          paddingLeft: "30px",
          paddingRight: "30px",
        },
      }),
      "&::after": {
        backgroundColor: `${darkTheme.tail} !important`,
      },
    },

    ".ant-steps-item-title": {
      fontSize: "15px",
      lineHeight: 1.25,
    },

    "&.ant-steps-item-finish": {
      ".ant-steps-item-container:hover": {
        ".ant-steps-item-title": {
          color: `${darkTheme.finish?.hover?.text} !important`,
        },
        ".ant-steps-icon": {
          color: `${darkTheme.finish?.hover?.iconText} !important`,
        },
        ".ant-steps-item-icon": {
          borderColor: `${darkTheme.finish?.hover?.iconBorder} !important`,
        },
      },
      ".ant-steps-item-icon": {
        color: darkTheme.finish?.iconText,
        backgroundColor: darkTheme.finish?.iconBackground,
        ...(darkTheme.finish?.iconBorder && {
          border: `2px solid ${darkTheme.finish?.iconBorder}`,
        }),
      },
      ".ant-steps-icon": {
        color: darkTheme.finish?.iconText,
      },
      ".ant-steps-item-content": {
        ".ant-steps-item-title": {
          color: darkTheme.finish?.text,
        },
      },
    },

    "&.ant-steps-item-wait": {
      ".ant-steps-item-icon": {
        color: darkTheme.wait?.iconText,
        backgroundColor: darkTheme.wait?.iconBackground,
        ...(darkTheme.wait?.iconBorder && {
          border: `2px solid ${darkTheme.wait?.iconBorder} !important`,
        }),
      },
      ".ant-steps-icon": {
        color: darkTheme.wait?.iconText,
      },
      ".ant-steps-item-content": {
        ".ant-steps-item-title": {
          color: darkTheme.wait?.text,
        },
      },

      "&:not(.ant-steps-item-disabled) .ant-steps-item-container:hover": {
        ".ant-steps-item-title": {
          color: `${darkTheme.wait?.hover?.text} !important`,
        },
        ".ant-steps-icon": {
          color: `${darkTheme.wait?.hover?.iconText} !important`,
        },
      },

      "&.ant-steps-item-disabled": {
        ".ant-steps-item-content": {
          ".ant-steps-item-title": {
            color: darkTheme.wait?.disabled?.text,
          },
        },
        ".ant-steps-icon": {
          color: darkTheme.wait?.disabled?.iconText,
        },
      },
    },

    "&.ant-steps-item-active": {
      ".ant-steps-item-icon": {
        color: darkTheme.active?.iconText,
        backgroundColor: darkTheme.active?.iconBackground,
        ...(darkTheme.active?.iconBorder && {
          border: `2px solid ${darkTheme.active?.iconBorder}`,
        }),
      },
      ".ant-steps-icon": {
        color: darkTheme.active?.iconText,
      },
      ".ant-steps-item-content": {
        ".ant-steps-item-title": {
          color: darkTheme.active?.text,
        },
      },
    },
  })
);

export { StyledSteps, StyledStep, NUMBERED_STEPS_THEMES };
