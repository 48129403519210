import React, { ElementType } from "react";

import { ModalManagerContext } from "shared/utils/modalmanager";

export type ModalManagerDataRendererProps = {
  id: string;
  component: ElementType;
  data?: object;
  selfApi: {
    closeDialog: () => void;
    setClosingValue: () => void;
  };
  visible: boolean;
};

export const ModalManagerDataRenderer = ({
  id,
  component,
  data = {},
  selfApi,
  visible,
}: ModalManagerDataRendererProps) => {
  const Modal = component;

  const modalContext = {
    modalId: id,
    context: data,
    selfApi,
    visible,
    fromModalManager: true,
  };

  return Modal ? (
    <ModalManagerContext.Provider value={modalContext}>
      <Modal {...data} />
    </ModalManagerContext.Provider>
  ) : null;
};
