import React, { useMemo } from "react";

import { Formik, Form } from "formik";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import * as Yup from "yup";

import { Button } from "shared/components/Button";
import ErrorList from "shared/components/ErrorList";
import FlexContainer from "shared/components/FlexContainer";
import { Checkbox } from "shared/components/Form";
import Icon from "shared/components/Icon";
import { UnderlineLink } from "shared/components/Links";
import { Modal } from "shared/components/Modal";
import Skeleton from "shared/components/Skeleton";
import Spacer from "shared/components/Spacer";
import Tooltip from "shared/components/Tooltip";
import { RegularText, SmallText } from "shared/components/Typography";
import Well from "shared/components/Well";

import { BlockApplicationIfNoIncomeDataModalContent } from "./styled";

const REASONS_SUMMARY_ERROR_NAME = "reasonsSummary";

const BlockApplicationIfNoIncomeDataModal = ({
  reasons,
  reasonsLoading,
  onConnectPayroll,
  onConnectBankAccount,
  onSubmit,
  companyName,
  employeeContact,
  isLynkEmploymentEnabled,
  isIncomeAssetEnabled,
}) => {
  const initialValues = {};

  const ValidationSchema = useMemo(() => {
    const validationObjectShape = {};
    reasons.forEach((reason) => {
      validationObjectShape[reason.key] = Yup.boolean();
    });
    return Yup.object()
      .shape(validationObjectShape)
      .test({
        name: "reasons",
        /* eslint-disable react/no-this-in-sfc */
        test(values) {
          const isValid = !!Object.values(values).includes(true);

          if (isValid) {
            return true;
          }
          return this.createError({
            path: REASONS_SUMMARY_ERROR_NAME,
            message: "At least one reason must be selected",
          });
        },
        /* eslint-enable react/no-this-in-sfc */
      });
  }, [reasons]);

  const showORAfterLynkButton = isIncomeAssetEnabled || reasons?.length > 0;
  const showORAfterConnectBankButton = reasons?.length > 0;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, formikApi) => {
        const error = await formikApi.validateForm();
        if (isEmpty(error)) {
          const checkedKeys = Object.keys(values).filter(
            (key) => !!values[key]
          );
          onSubmit(checkedKeys, formikApi);
        }
      }}
      validationSchema={ValidationSchema}
    >
      {({ submitForm, isSubmitting, values, errors, handleChange }) => {
        return (
          <Modal
            title="Verify Income To Continue!"
            submitButtonLabel="Continue"
            showFooter={false}
            submit={submitForm}
            isSubmitting={isSubmitting}
            closable={false}
          >
            <Modal.Body noPaddingTop>
              <BlockApplicationIfNoIncomeDataModalContent
                flexDirection="column"
                alignItems="center"
              >
                <RegularText strong>
                  {companyName} requires applicants to confirm their income
                  before submitting their application to speed up the leasing
                  decision.
                </RegularText>

                {isLynkEmploymentEnabled && (
                  <>
                    <Spacer size={Spacer.SIZES.sm} />
                    <FlexContainer alignItems="center">
                      <Button type="secondary" onClick={onConnectPayroll}>
                        CONNECT EMPLOYER/PAYROLL
                      </Button>
                      <Tooltip title="VERO securely allows you to connect your payroll or employer account to automatically verify your income.">
                        <Icon.InfoIcon className="info-icon" />
                      </Tooltip>
                    </FlexContainer>
                    {showORAfterLynkButton && (
                      <>
                        <Spacer size={Spacer.SIZES.xs} />
                        <SmallText strong>OR</SmallText>{" "}
                      </>
                    )}
                  </>
                )}

                {isIncomeAssetEnabled && (
                  <>
                    <Spacer size={Spacer.SIZES.xs} />
                    <FlexContainer alignItems="center">
                      <Button type="secondary" onClick={onConnectBankAccount}>
                        CONNECT BANK ACCOUNT
                      </Button>
                      <Tooltip title="VERO securely allows you to connect your bank account and choose which accounts to select.">
                        <Icon.InfoIcon className="info-icon" />
                      </Tooltip>
                    </FlexContainer>
                    {showORAfterConnectBankButton && (
                      <>
                        <Spacer size={Spacer.SIZES.xs} />
                        <SmallText strong>OR</SmallText>
                      </>
                    )}
                  </>
                )}

                {reasons?.length > 0 && (
                  <>
                    <Spacer size={Spacer.SIZES.xs} />
                    <Well noBorder className="reasons-well">
                      <Form>
                        <RegularText strong className="reasons-well-title">
                          Select reason(s) why you couldn’t verify income
                        </RegularText>
                        <Spacer size={Spacer.SIZES.xs} />
                        <Skeleton loading={reasonsLoading}>
                          {reasons.map((reason) => {
                            return (
                              <FlexContainer
                                key={reason.key}
                                className="reasons-checkbox"
                                flexWrap="wrap"
                              >
                                <Checkbox
                                  id={reason.key}
                                  name={reason.key}
                                  label={reason.label}
                                  value={values[reason.key]}
                                  onChange={handleChange}
                                />
                              </FlexContainer>
                            );
                          })}
                        </Skeleton>
                        {errors && errors[REASONS_SUMMARY_ERROR_NAME] && (
                          <ErrorList
                            errors={[errors[REASONS_SUMMARY_ERROR_NAME]]}
                          />
                        )}
                        <FlexContainer justifyContent="center">
                          <Button type="primary" onClick={submitForm}>
                            CONFIRM
                          </Button>
                        </FlexContainer>
                      </Form>
                    </Well>
                  </>
                )}
                <Spacer size={Spacer.SIZES.sm} />
                <SmallText weak className="more-info-text">
                  If you need more info, please contact your manager at{" "}
                  <UnderlineLink href={`mailto:${employeeContact}`}>
                    {employeeContact}
                  </UnderlineLink>
                </SmallText>
              </BlockApplicationIfNoIncomeDataModalContent>
            </Modal.Body>
          </Modal>
        );
      }}
    </Formik>
  );
};

BlockApplicationIfNoIncomeDataModal.propTypes = {
  reasons: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  reasonsLoading: PropTypes.bool.isRequired,
  onConnectPayroll: PropTypes.func.isRequired,
  onConnectBankAccount: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  companyName: PropTypes.string.isRequired,
  employeeContact: PropTypes.string.isRequired,
  isLynkEmploymentEnabled: PropTypes.bool.isRequired,
  isIncomeAssetEnabled: PropTypes.bool.isRequired,
};

BlockApplicationIfNoIncomeDataModal.defaultProps = {
  reasons: [],
};

export default BlockApplicationIfNoIncomeDataModal;
