import styled from "@emotion/styled";
import { Layout } from "antd";

import { Button } from "shared/components/Button";
import { COLORS, MEDIA_QUERIES, DIMENSIONS } from "shared/config/constants";

const Container = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  ${MEDIA_QUERIES.w.gteXl} {
    margin-left: ${DIMENSIONS.sidebar.gteXl.width};
    max-width: calc(100% - ${DIMENSIONS.sidebar.gteXl.width});
  }
  ${MEDIA_QUERIES.w.gteMd} {
    margin-top: ${DIMENSIONS.header.gteMd.height};
  }
  ${MEDIA_QUERIES.w.ltXl} {
    margin-left: ${DIMENSIONS.sidebar.ltXl.width};
    max-width: calc(100% - ${DIMENSIONS.sidebar.ltXl.width});
  }
  ${MEDIA_QUERIES.w.ltMd} {
    margin-left: 0;
    margin-top: ${DIMENSIONS.header.ltMd.height};
    max-width: 100%;
  }
  @media print and (max-width: 768px) {
    margin-left: 65px;
  }
`;

const StyledLayout = styled(Layout)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  ${MEDIA_QUERIES.w.ltMd} {
    flex-direction: column !important;
  }
  overflow: auto;
`;

const ScrollToTopButton = styled(Button)`
  width: 32px;
  height: 32px;
  position: fixed;
  bottom: 180px;
  right: 20px;
  background-color: ${COLORS.scrollToTopButton.background};
  border-radius: 50%;
  box-shadow: 0px 1px 8px rgba(126, 153, 168, 0.3);
`;

export { Container, StyledLayout, ScrollToTopButton };
