import React from "react";

import PropTypes from "prop-types";

import Icon from "shared/components/Icon";
import { ActionLink } from "shared/components/Links";

import { PrimaryTextLarge, SecondaryTextLarge } from "../styled";

import { Container, SpaceWrapper } from "./styled";

const MagicLinksCompany = ({
  name,
  disableMagicLink,
  hasMagicPortalLink,
  onShareLinks,
  isLoading,
}) => {
  return (
    <Container alignItems="center">
      <SpaceWrapper>
        <SecondaryTextLarge data-testid="company-name">
          Company: <PrimaryTextLarge>{name}</PrimaryTextLarge>
        </SecondaryTextLarge>
      </SpaceWrapper>
      {(!disableMagicLink || hasMagicPortalLink) && (
        <SpaceWrapper>
          <ActionLink
            Icon={Icon.QRCodeGreenIcon}
            onClick={onShareLinks}
            data-testid="share-link"
            disabled={isLoading}
          >
            Share
          </ActionLink>
        </SpaceWrapper>
      )}
    </Container>
  );
};

MagicLinksCompany.propTypes = {
  name: PropTypes.string,
  disableMagicLink: PropTypes.bool.isRequired,
  hasMagicPortalLink: PropTypes.bool.isRequired,
  onShareLinks: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

MagicLinksCompany.defaultProps = {
  name: undefined,
};

export default MagicLinksCompany;
