const adminPrefix = "/admin";

const COMPANY_ROUTES = {
  getAdminCompanies: `${adminPrefix}/companies/`,
  assignDocuments: `${adminPrefix}/companies/:id/assign-documents/`,
  unassignDocuments: `${adminPrefix}/companies/:id/unassign-documents/`,
};

const DOCUMENT_TEMPLATE_ROUTES = {
  documentTemplates: `${adminPrefix}/document-templates/`,
  documentTemplate: `${adminPrefix}/document-templates/:id/`,
  getDocumentFields: `${adminPrefix}/fields/?group_by_category=1`,
  getCompaniesDocumentAssigned: `${adminPrefix}/document-templates/:id/companies/`,
  previewDocumentTemplate: `${adminPrefix}/document-templates/preview/`,
  getDocumentDrafts: `${adminPrefix}/document-template-drafts/`,
  getDocumentDraft: `${adminPrefix}/document-template-drafts/:id/`,
};

const FIELD_ROUTES = {
  fields: `${adminPrefix}/fields/`,
  fieldApps: `${adminPrefix}/fields/apps/`,
  fieldModels: `${adminPrefix}/fields/models/`,
  fieldProperties: `${adminPrefix}/fields/properties/`,
  fieldCategoriesByType: `${adminPrefix}/fields/categories-by-type/`,
  fieldTypes: `${adminPrefix}/fields/types/`,
  signatures: `${adminPrefix}/signature/`,
};

const TAGS_ROUTES = {
  tags: "/tags/",
  tag: "/tags/:id/",
  tagRemoveTemplate: "/tags/:tagId/remove-template/:templateId/",
};

const ADMIN_API_ROUTES = {
  ...COMPANY_ROUTES,
  ...DOCUMENT_TEMPLATE_ROUTES,
  ...FIELD_ROUTES,
  ...TAGS_ROUTES,
};

export default ADMIN_API_ROUTES;
