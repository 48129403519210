import React from "react";

import { useHistory } from "react-router-dom";

import Icon from "shared/components/Icon";
import InfoPage from "shared/templates/InfoPage";
import { goBackWithReload } from "shared/utils/routing";

const Error404Page = () => {
  const history = useHistory();

  return (
    <InfoPage
      title="Page Not Found"
      description="Sorry, the page you are looking for doesn’t exist"
      MainIcon={Icon.Error404Icon}
      button={{
        children: "Back",
        PrefixIcon: Icon.ButtonBackArrowIcon,
        onClick: () => goBackWithReload(history),
      }}
      width="900px"
    />
  );
};

export default Error404Page;
