import styled from "@emotion/styled";

import Container from "shared/components/Container";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

import { ReactComponent as SwitchML } from "../assets/switch-ml.svg";

const Content = styled("div")({
  color: COLORS.gray2,
  fontSize: 17,
  maxWidth: 600,
  textAlign: "center",
  padding: "32px 0",
});

const SwitchMagicLinksImage = styled(SwitchML)({
  [MEDIA_QUERIES.w.ltSm]: {
    width: 300,
  },
});

const StyledContainer = styled(Container)({
  backgroundColor: COLORS.white,
  paddingBottom: 70,
  marginBottom: 100,
});

export { Content, StyledContainer, SwitchMagicLinksImage };
