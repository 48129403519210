import React from "react";

import {
  Container,
  Title,
  ScrollContainer,
  Row,
  Primary,
  Secondary,
} from "./styled";

const _DocumentInfo: React.FC<{}> = ({ children }) => (
  <Container>
    <Title>Document Info</Title>
    <ScrollContainer>{children}</ScrollContainer>
  </Container>
);

export const DocumentInfo = Object.assign(_DocumentInfo, {
  Row,
  Primary,
  Secondary,
});
