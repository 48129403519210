import React, { useMemo } from "react";

import { Field, useFormikContext } from "formik";
import get from "lodash/get";

import { Select } from "shared/components/Form/Select";

import { DropdownWrapper } from "./styled";

export type SelectLabelContainerProps = {
  formPath: string;
  inputDescription: string;
  selectPlaceholder: string;
  idProp?: string;
  labelProp?: string;
  items?: Record<string, unknown>[];
};

export const SelectLabelContainer = ({
  formPath,
  inputDescription,
  selectPlaceholder,
  items = [],
  idProp = "id",
  labelProp = "label",
}) => {
  const { errors, touched, setFieldValue, setFieldTouched } =
    useFormikContext();

  const options = useMemo(
    () =>
      items.map((item) => (
        <Select.Option key={get(item, idProp)}>
          {get(item, labelProp)}
        </Select.Option>
      )),
    [items]
  );

  return (
    <DropdownWrapper className="dropdown-container">
      <label htmlFor={formPath} className="input-description">
        {inputDescription}
      </label>
      <Field
        as={Select}
        name={formPath}
        id={formPath}
        label={selectPlaceholder}
        error={get(touched, formPath) && get(errors, formPath)}
        onBlur={() => setFieldTouched(formPath, true)}
        onChange={(value) => setFieldValue(formPath, value)}
      >
        {options}
      </Field>
    </DropdownWrapper>
  );
};
