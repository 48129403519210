import styled from "@emotion/styled";
import { Button, Form } from "antd";

import { Field } from "formik";

import FlexContainer from "shared/components/FlexContainer";
import { SelectDropdownField } from "shared/components/Form/SelectDropdownField";
import { SmallTitle, MediumTitle } from "shared/components/Typography";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

export const HeaderContainer = styled(FlexContainer)`
  width: 100%;
  min-height: 80px;
  flex-grow: 1;
  padding-left: 30px;
  padding-right: 10px;
  margin-bottom: 20px;
  background-color: ${COLORS.primary};

  .dropdown-chevron {
    cursor: pointer;
  }

  ${MEDIA_QUERIES.w.ltSm} {
    display: flex;
    flex-direction: column;
    align-content: space-between;
    height: auto;
    position: relative;
    overflow: hidden;
    padding: 10px;
    justify-content: center;
  }
`;

export const Container = styled(FlexContainer)`
  background-color: ${COLORS.primary};
  width: 100%;
  margin-top: 16px;
  padding: 16px 40px;
  gap: 50px;

  .bind-properties-btn {
    flex: 1;

    button {
      background-color: inherit;
      border-color: ${COLORS.button.primary.default.background};
    }
  }

  .dropdown-chevron {
    cursor: pointer;
  }

  ${MEDIA_QUERIES.w.ltLg} {
    padding-left: 16px;
    gap: 16px;
  }

  ${MEDIA_QUERIES.w.ltMd} {
    display: block;
    position: relative;
    padding: 16px;

    & > div {
      justify-content: space-between;
      margin-bottom: 16px;
      margin-right: 50px;
    }

    .dropdown-chevron {
      position: absolute;
      top: 24px;
      right: 24px;
    }
  }
`;

export const SectionContainer = styled(FlexContainer)`
  margin-right: 60px;
  align-items: center;

  .label {
    color: ${COLORS.white};
    width: 105px;
    line-height: 130%;
    margin-right: 10px;
  }

  .value {
    font-size: 28px;
    margin-left: 16px;
    border-bottom: 2px solid ${COLORS.primary};
    line-height: 1;
    color: ${COLORS.white};
    text-decoration: underline;
  }

  ${MEDIA_QUERIES.w.ltLg} {
    display: flex;
    padding: 10px 20px;
    width: 100%;
    margin-right: unset;
    .label {
      font-size: 16px;
    }

    .value {
      font-size: 28px;
      margin-left: 10px;
    }
  }
`;

export const SubmitContainer = styled(FlexContainer)`
  width: 100%;
  min-height: 80px;
  flex-grow: 1;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
  background-color: ${COLORS.violetTheme.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${MEDIA_QUERIES.w.ltSm} {
    flex-direction: column;
  }
`;

export const HeaderTitle = styled(SmallTitle)`
  color: ${COLORS.violetTheme.white};
  text-transform: uppercase;
  display: flex;
  justify-content: flex-end;
  width: min-content;
  min-width: 130px;
`;

export const SectionColumn = styled(FlexContainer)`
  flex: 3;
  min-height: 70px;
  display: flex;

  ${MEDIA_QUERIES.w.ltSm} {
    flex-direction: column-reverse;
    min-height: unset;
    margin: 5px;
  }
`;

export const LightUnderlinedLink = styled.div`
  color: ${COLORS.primary};
  text-decoration: underline;
  cursor: pointer;
`;

export const SectionButton = styled(Button)`
  color: ${COLORS.violetTheme.white};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.primary};
  padding: 15px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid ${COLORS.violetTheme.white};
  font-size: 18px;
  text-transform: uppercase;
  margin-left: 20px;

  ${MEDIA_QUERIES.w.ltSm} {
    margin: 0px 0px 10px 0px;
  }
`;

export const StepTitle = styled(MediumTitle)`
  width: auto;
  color: ${COLORS.primary};
  font-size: 22px !important;
`;

export const PropertySelectionContainerWrapper = styled(FlexContainer)`
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  display: flex;

  ${MEDIA_QUERIES.w.ltSm} {
    flex-direction: column;
  }
`;

export const PropertySelectionContainer = styled(FlexContainer)`
  flex-direction: column;
  align-items: flex-start;
  background-color: ${COLORS.violetTheme.white};
  width: 49%;
  padding: 20px;

  ${MEDIA_QUERIES.w.ltSm} {
    width: 100%;
    margin: 5px;
  }
`;

export const PropertySelectionNote = styled(FlexContainer)`
  background-color: ${COLORS.background};
  align-items: center;
  justify-content: center;
  padding: 6px;
  font-size: 12px;
  margin-top: 15px;
  margin-bottom: 10px;
`;

export const FormFieldContainer = styled(Form)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 5px;
`;

export const PropertySearchBar = styled(Field)`
  width: 75%;
  padding: 5px;

  ${MEDIA_QUERIES.w.ltSm} {
    width: 100%;
  }
`;

export const ScrollableArea = styled(FlexContainer)`
  display: flex;
  flex-direction: column;
  border: 1px solid ${COLORS.borders};
  width: 100%;
  height: 250px;
  overflow-y: auto;
  margin-bottom: 20px;

  ${MEDIA_QUERIES.w.ltSm} {
    height: 100px;
  }
`;

export const PropertyList = styled.ul`
  padding: unset;
  list-style: none;

  .selected {
    background-color: ${COLORS.background};
    display: flex;
    justify-content: space-between;
  }
`;

export const Property = styled.div`
  padding: 8px;
  cursor: pointer;
  ${MEDIA_QUERIES.w.ltSm} {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const PropertyControlsContainer = styled(FlexContainer)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const ClickableGreenLink = styled.div`
  color: ${COLORS.accentDark};
  font-size: 15px;
  cursor: pointer;
`;

export const ClickableLightGreenLink = styled.div`
  color: ${COLORS.lightGreen2};
  font-size: 15px;
  cursor: pointer;
`;

export const ModalFormFieldContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const PropertyModalField = styled(Field)`
  padding: 10px;
  font-size: 15px;
  width: inherit;
  border: 1px solid ${COLORS.borders};
  max-width: 400px;
  margin: 10px;
`;

export const PropertyModalRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  max-width: 400px;
`;

export const PropertyTypeField = styled(Field)`
  width: 100%;
  margin: 10px;
  padding: 5px;
`;

export const MobileExpansionContainer = styled.div`
  display: flex;

  ${MEDIA_QUERIES.w.ltSm} {
    width: 100%;
    justify-content: space-between;
  }
`;

export const ScrollableControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 10px 5px 10px;
`;

export const StateSelectFieldStyled = styled(SelectDropdownField)`
  width: 22%;
  padding: 5px;
  cursor: pointer;
`;
