import styled from "@emotion/styled";

const YardiDropdownContainer = styled("div")`
  &.dropdown-container:not(:first-of-type) {
    margin-top: 24px;
  }
  .input-description {
    font-weight: 400;
    margin-bottom: 17px;
    display: block;
  }
  .link-description: {
    margin-bottom: 0;
    font-weight: 300;
  }
`;

export { YardiDropdownContainer };
