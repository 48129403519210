import React from "react";

import PropTypes from "prop-types";
import { Switch, useParams, Redirect } from "react-router-dom";

import ROUTES, { PROPERTY_SETTINGS_TABS } from "manager/config/routes";
import { usePermissions } from "shared/hooks";
import { ConditionalRoute, transformRoute } from "shared/utils/routing";

import PropertySettingsContainer from "./PropertySettingsContainer";

const PropertySettingsRouter = ({ backLink }) => {
  const { id } = useParams();
  const { isEmailCustomizationEnabled, permissionsLoaded } = usePermissions();

  return (
    <Switch>
      <Redirect
        exact
        from={ROUTES.property}
        to={transformRoute(ROUTES.propertySetupTab, {
          tabName: PROPERTY_SETTINGS_TABS.units,
          id,
        })}
      />
      <ConditionalRoute
        path={ROUTES.propertySetupTab}
        isEnabled={({
          match: {
            params: { tabName },
          },
        }) =>
          Object.values(PROPERTY_SETTINGS_TABS)
            .filter(
              (route) =>
                isEmailCustomizationEnabled ||
                route !== PROPERTY_SETTINGS_TABS.emailTemplates
            )
            .includes(tabName)
        }
        redirectTo={ROUTES.notFound}
        isInitialized={permissionsLoaded}
      >
        <PropertySettingsContainer backLink={backLink} />
      </ConditionalRoute>
    </Switch>
  );
};

PropertySettingsRouter.propTypes = {
  backLink: PropTypes.object.isRequired,
};

export default PropertySettingsRouter;
