import React from "react";

import { Pagination } from "antd";

export interface PaginationControlsProps {
  className?: string;
  current: number;
  onChange?: () => void;
  size?: string | "small";
  total: number;
  pageSize?: number;
  "data-testid"?: string;
}

const PaginationControls = ({
  current,
  total,
  pageSize = 10,
  onChange = undefined,
  className = undefined,
  size = undefined,
  "data-testid": dataTestId = "pagination-controls",
}: PaginationControlsProps) => {
  return (
    <Pagination
      current={current}
      total={total}
      pageSize={pageSize}
      onChange={onChange}
      className={className}
      size={size}
      data-testid={dataTestId}
    />
  );
};

export default PaginationControls;
