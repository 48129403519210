import React from "react";

import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import Container from "shared/components/Container";
import { BackLink } from "shared/components/Links";

import MagicLinksHeader from "./MagicLinksHeader";
import MagicLinksRouter from "./MagicLinksRouter";

const MagicLinks = ({ backLink }) => {
  return (
    <>
      <Helmet>
        <title>Magic Links</title>
      </Helmet>
      <Container noPaddingBottom>
        <BackLink.Container>
          <BackLink {...backLink} />
        </BackLink.Container>
      </Container>
      <Container noPaddingTop noMobilePaddingX>
        <MagicLinksHeader />
        <MagicLinksRouter />
      </Container>
    </>
  );
};

MagicLinks.propTypes = {
  backLink: PropTypes.object,
};

MagicLinks.defaultProps = {
  backLink: undefined,
};

export default MagicLinks;
