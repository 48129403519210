import React, { useState } from "react";
import _ from 'lodash'
import dayjs from "dayjs";

import groupBy from "lodash/groupBy";

import UpdateDealRevenueDetailsModal from "manager/components/Modal/UpdateDealRevenueDetailsModal";
import { useAssignDealRevenueManagement } from "manager/hooks/api";
import { useDeviceType } from "shared/hooks";
import { useGetPricingOffers } from "shared/hooks/api";
import useModal from "shared/hooks/useModal";

import AssignDealRevenueManagementModal from "./AssignDealRevenueManagementModal";

interface AssignDealRevenueManagementModalContainerProps {
  dealId: number;
  refetchDeal: () => void;
  unitLocation: string;
  propertyName: string;
  unitName: string;
  availableOn: string;
  pricingOffers?: any[];
  rent?: number;
  preferredLeaseDurationMonths?: number;
  preferredLeaseStartDate?: Date;
  availableDate?: dayjs.Dayjs;
}

const AssignDealRevenueManagementModalContainer = ({
  dealId,
  refetchDeal,
  unitLocation,
  propertyName,
  unitName,
  availableOn,
  pricingOffers = [],
  rent,
  preferredLeaseDurationMonths,
  preferredLeaseStartDate,
  availableDate,
}: AssignDealRevenueManagementModalContainerProps) => {
  let isPricingOffersLoading;
  if (!pricingOffers?.length) {
    ({ pricingOffers, isPricingOffersLoading } =
      useGetPricingOffers(unitLocation));
  }

  (pricingOffers || []).sort((a, b) => {
    if (a?.date < b?.date) {
      return -1;
    }
    if (a?.date > b?.date) {
      return 1;
    }
    return a?.term - b?.term;
  });

  const pricingOffersByLeaseStartDate = groupBy(
    pricingOffers || [],
    v => v?.leaseStartDate || v?.date
  );

  const { assignDealRevenueManagement } = useAssignDealRevenueManagement();
  const { isMobile } = useDeviceType();
  const { selfApi, openModalDialog } = useModal();
  const emptyPricingOffer = {
    id: null,
    leaseStartDate: null,
    termLength: null,
    rent: null,
  };
  const [selectedPricingOffer, setPricingOffer] = useState(emptyPricingOffer);

  const submit = () => {
    const values = {
      leaseStartDate: _.get(selectedPricingOffer, 'date'),
      term: _.get(selectedPricingOffer, 'term'),
      rent: _.get(selectedPricingOffer, 'amount'),
      pricingOfferId: selectedPricingOffer?.id,
    };

    assignDealRevenueManagement(
      {
        dealId,
        values,
      },
      {
        onSuccess: () => {
          refetchDeal();
        },
        onSettled: () => selfApi.closeDialog(),
      }
    );
  };

  const manuallyEditRent = () => {
    selfApi.closeDialog();

    openModalDialog(UpdateDealRevenueDetailsModal, {
      values: {
        rent,
        preferredLeaseDurationMonths,
        preferredLeaseStartDate,
      },
      refetchDeal,
      dealId,
      availableDate,
    });
  };

  return (
    <AssignDealRevenueManagementModal
      pricingOffersByLeaseStartDate={pricingOffersByLeaseStartDate}
      propertyName={propertyName}
      unitName={unitName}
      availableOn={availableOn}
      isMobile={isMobile}
      submit={submit}
      setPricingOffer={setPricingOffer}
      selectedPricingOffer={selectedPricingOffer}
      isPricingOffersLoading={isPricingOffersLoading}
      emptyPricingOffer={emptyPricingOffer}
      manuallyEditRent={manuallyEditRent}
    />
  );
};

export default AssignDealRevenueManagementModalContainer;
