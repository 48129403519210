import React from 'react'
import { getTaskDisplayName } from './utils'

const TaskStep = ({ task }) => {
  return (
    <div className='vega flex column'>
      <div className='vega title'>
        {getTaskDisplayName(task?.slug)}
        <div className='vega sub-title slug'>{task?.slug}</div>
        {task?.description && <p className='vega info'>{task?.description}</p>}
      </div>

      <div>
        {(task?.timings?.next || task?.timings?.complete) && <div>
          <span className='vega label'>started at:</span>
          <span className='vega timestamp'>{task?.timings?.next || task?.timings?.complete}</span>
        </div>}

        {task?.timings?.complete && <div>
          <span className='vega label'>completed at:</span>
          <span className='vega timestamp'>{task?.timings?.complete}</span>
        </div>}

        {!task?.timings?.next && !task?.timings?.complete && <div>
          <span className='vega label'>not stared yet</span>
        </div>}
      </div>
    </div>
  )
}

export default TaskStep
