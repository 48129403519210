import React from "react";

import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import { ActionLink } from "shared/components/Links";
import Tile from "shared/components/Tile";

import { NoLumenFormulaAvailableProps } from "./interfaces";
import { NoTableItemsTitle } from "./styled";

const NoLumenFormulaAvailable = ({
  createNewLumenFormula,
}: NoLumenFormulaAvailableProps) => (
  <Tile data-testid="no-formulas-section">
    <Tile.Inner size="lg">
      <FlexContainer
        fullHeight
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Icon.LumenSmallBackground />
        <NoTableItemsTitle>Create your first Lumen formula</NoTableItemsTitle>
        <ActionLink onClick={createNewLumenFormula}>+ NEW FORMULA</ActionLink>
      </FlexContainer>
    </Tile.Inner>
  </Tile>
);

export default NoLumenFormulaAvailable;
