import styled from "@emotion/styled";

import { AlignedCenterFlexPartial } from "shared/styles/commonStyles";

const Container = styled("div")({
  paddingTop: "40px",
  paddingBottom: "40px",
});

const SourcesContainer = styled("div")({
  ".header-title-label": {
    width: "100%",
  },
  ".sources-title-action": {
    ...AlignedCenterFlexPartial,
    justifyContent: "space-between",
  },
  ".title-popover": {
    marginLeft: "12px",
  },
});

const SourceRow = styled("div")({
  ...AlignedCenterFlexPartial,
  width: "100%",
  justifyContent: "space-between",
  height: "60px",
});

const SourceName = styled("span")({
  fontSize: "15px",
  fontWeight: 500,
});

const SourceActions = styled("div")({
  ...AlignedCenterFlexPartial,
  ".edit-action": {
    marginRight: "32px",
  },
  ".delete-action": {
    cursor: "pointer",
  },
});

export { Container, SourcesContainer, SourceRow, SourceName, SourceActions };
