import axios from "axios";

import ROUTES from "shared/config/apiRoutes";
import { Partner } from "shared/interfaces/api/partner";

import { API_URL } from "./setupApi";

const api = {
  getPartners: () =>
    axios
      .get<Partner[]>(`${API_URL}${ROUTES.partners}`)
      .then(({ data }) => data),
};

export default api;
