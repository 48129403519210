import React, { useMemo } from "react";

import * as Yup from "yup";

import FlexContainer from "shared/components/FlexContainer";
import { SingleInputModalTemplate } from "shared/components/Modals/SingleInputModal";
import { SmallTitle } from "shared/components/Typography";
import { sanitizeStringSearchValue } from "shared/utils/string";

import { SourcesCreateEditModalProps } from "./interfaces";

const SourcesCreateEditModal = ({
  propertyName,
  title,
  customSubtitle,
  inputDescription,
  inputPlaceholder,
  confirmLabel = "CONFIRM",
  onSubmit,
  name,
  usedNames = [],
}: SourcesCreateEditModalProps) => {
  const subtitle =
    customSubtitle ||
    (propertyName && (
      <FlexContainer>
        Property:&nbsp;
        <SmallTitle>{propertyName}</SmallTitle>
      </FlexContainer>
    ));

  const valueValidationSchema = useMemo(
    () =>
      Yup.string()
        .required("Source name is required")
        .nullable()
        .test(
          "uniqueSourceName",
          "The name of the source must be unique",
          (value) => {
            const sanitizedValue = sanitizeStringSearchValue(value);
            return !usedNames.includes(sanitizedValue);
          }
        ),
    [usedNames]
  );

  return (
    <SingleInputModalTemplate
      title={title}
      subtitle={subtitle}
      onSubmit={onSubmit}
      inputPlaceholder={inputPlaceholder}
      inputDescription={inputDescription}
      confirmLabel={confirmLabel}
      initialValue={name}
      valueValidationSchema={valueValidationSchema}
    />
  );
};

export default SourcesCreateEditModal;
