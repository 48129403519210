import styled from "@emotion/styled";

import FlexContainer from "shared/components/FlexContainer";
import { Modal } from "shared/components/Modal";
import { LumenImages } from "shared/images";

export const CourtesyMessageContent = styled(FlexContainer)({
  height: "100%",
  width: "100%",

  ".courtesy-message-title": {
    lineHeight: "33px",
    fontWeight: 400,
    paddingBottom: "32px",
  },

  ".courtesy-message-image": {
    height: "130px",
    width: "224px",
    minWidth: "224px",
    backgroundImage: `url(${LumenImages.LumenCourtesyMessage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },

  ".courtesy-message-text": {
    lineHeight: "22.5px",
    fontWeight: 400,
    padding: "36px 50px",
    textAlign: "center",
  },
});

export const StyledModalBody = styled(Modal.Body)({
  height: "100%",
});
