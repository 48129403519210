import styled from "@emotion/styled";

import FooterBar from "shared/components/FooterBar";
import { MEDIA_QUERIES } from "shared/config/constants";

export const Panel = styled(FooterBar)`
  display: flex;
  justify-content: center;
  max-height: 72px;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
`;

export const ScrollContainer = styled.div<{ visible?: boolean }>`
  flex-grow: 1;
  display: flex;
  align-items: stretch;
  ${({ visible }) =>
    !visible &&
    `
  visibility: hidden;
`}
  &>iframe {
    height: auto;
  }
`;

export const ZoomContainer = styled.div`
  position: absolute;
  bottom: 200px;
  right: 50px;
  z-index: 200;
  ${MEDIA_QUERIES.w.ltMd} {
    bottom: 70px;
    left: 0px;
  }
`;

export const LoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 200;
`;

export const DownloadButton = styled.div`
  margin-left: 30px;
`;

export const DefaultTools = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`;

export const Tools = styled.div`
  display: flex;
  flex-grow: 1;
`;
