import { useMemo, useState } from "react";

import { useGetDealsToMove } from "manager/hooks/api";
import { DealToMove } from "manager/lib/dealsApi";
import { moveApplicationToAnotherDeal } from "shared/lib/api/applicationApi";
import { isSubstrInStr } from "shared/utils/ui";

import { useRemovePrimaryYardiExtension } from "../hooks";

import { MoveApplicantModalProps, MoveApplicantModalForm } from "./component";

import { OnMovedApplicant } from "./context";

export const useHook = ({
  application,
  dealId,
  yardi,
  enabled = true,
}: MoveApplicantModalProps & { enabled: boolean }) => {
  const [destinationDeal, setDestinationDeal] = useState<Partial<DealToMove>>(
    {}
  );
  const [searchingValue, setSearchingValue] = useState("");

  const { isLoading: isDealsLoading, data } = useGetDealsToMove({
    dealId,
    enabled,
  });
  const move = async (
    result: MoveApplicantModalForm
  ): Promise<OnMovedApplicant> => {
    await moveApplicationToAnotherDeal({
      applicationId: application.id,
      dealId: destinationDeal.id,
      ...result.extraParams,
    });
    return { dealId: destinationDeal.id };
  };

  const deals = useMemo(
    () =>
      data?.filter((deal) => {
        const applicants = deal?.applications?.map(
          (app) => `${app.firstName} ${app.lastName}`
        );
        const applicantsSearch = applicants?.filter((app) =>
          isSubstrInStr(app, searchingValue)
        );
        const propertyNameSearch = isSubstrInStr(
          deal.propertyName,
          searchingValue
        );
        const unitNameSearch = isSubstrInStr(deal.unitName, searchingValue);

        return applicantsSearch?.length || propertyNameSearch || unitNameSearch;
      }),
    [searchingValue, data]
  );

  const { extension } = useRemovePrimaryYardiExtension(yardi);

  return {
    move,
    loading: isDealsLoading,
    deals,
    extension,
    setDestinationDeal,
    setSearchingValue,
  };
};
