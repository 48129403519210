import _ from 'lodash'
import { useState, useEffect } from 'react'
import { getCachedActiveCompanySlug } from 'shared/utils/auth'
import { Table } from 'shared/components/vega/Table'
import { SearchFilter } from 'shared/components/vega/SearchFilter'
import { search } from './utils'

const SearchList = ({ indexes, columns, onRowClick, initialSort, resolveFilter, defaultSearchIndex = undefined, aggs = [], quicklinks = {}, deps = [], maxHeight = undefined, header = null }) => {
  const [sort, setSort] = useState(() => initialSort)
  const [filter, setFilter] = useState(null)

  const [currentPage, setCurrentPage] = useState(null)

  const [loading, setLoading] = useState(false)
  const [results, setResults] = useState([])
  const [aggregations, setAggregations] = useState(null)
  const [next, setNext] = useState(null)

  const [searchError, setSearchError] = useState(null)
  const [searchCount, setSearchCount] = useState(0)

  const company = getCachedActiveCompanySlug()

  const resolvedFilter = resolveFilter(filter)

  const clearSearchResults = () => {
    setResults([])
    setNext(null)
    setCurrentPage(null)
    setAggregations(null)
    setSearchError(null)
    setSearchCount(searchCount + 1)
  }

  useEffect(() => {
    async function fetchResults () {
      try {
        setLoading(true)

        const searchResults = await search(
          company,
          indexes,
          sort,
          aggs,
          resolvedFilter,
          currentPage
        )

        setResults(_.unionBy(results, searchResults?.results, 'id'))
        setNext(searchResults?.next)

        if (!aggregations) {
          setAggregations(_.get(searchResults?.aggregations, aggs))
        }
      } catch (error) {
        setSearchError(_.toString(error?.errors?.error || error?.message || error))
      } finally {
        setLoading(false)
      }
    }

    if (company && filter) {
      fetchResults()
    }
  }, [company, sort, currentPage, JSON.stringify(aggs), JSON.stringify(resolvedFilter), searchCount, ...deps])

  return (
    <div className='vega container'>
      {_.isFunction(header) ? header(currentPage, loading, clearSearchResults, results, aggregations, next, searchError, resolvedFilter) : null}

      <SearchFilter
        loading={loading}
        indexes={indexes}
        defaultSearchIndex={defaultSearchIndex}
        dataset={results}
        onFilter={f => {
          clearSearchResults()
          setFilter(f)
        }}
        quicklinks={quicklinks}
      />

      {searchError && <div className='vega content info error'>{searchError}</div>}

      <Table
        columns={columns}
        dataset={results}
        loading={loading}
        maxHeight={maxHeight}
        sort={sort}
        onSort={s => {
          clearSearchResults()
          setSort(s)
        }}
        onNext={next ? () => setCurrentPage(next) : null}
        onRowClick={onRowClick}
      />
    </div>
  )
}

export default SearchList
