import React from "react";

import { useGetUnit } from "manager/hooks/api";
import { SidebarProps } from "shared/components/Sidebar";

import { HeaderProps } from "./interfaces";
import Unit from "./Unit";

interface UnitContainerProps {
  id: string;
  sidebar: SidebarProps;
  header: HeaderProps;
  company?: {
    slug: string;
  };
}

const UnitContainer = ({
  id,
  sidebar,
  header,
  company,
}: UnitContainerProps) => {
  const { unit, isLoading, isFetching } = useGetUnit(id);

  return (
    <Unit
      unit={unit}
      loading={isLoading}
      fetching={isFetching}
      sidebar={sidebar}
      header={header}
      company={company}
    />
  );
};

export default UnitContainer;
