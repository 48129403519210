import styled from '@emotion/styled'
import { Button, Divider as BaseDivider, Menu } from 'antd'

import Chip from 'shared/components/Chip'
import FlexContainer from 'shared/components/FlexContainer'
import { ActionLink } from 'shared/components/Links'
import { Modal } from 'shared/components/Modal'
import { StyledTable } from 'shared/components/Table/Table/styled'
import Tile from 'shared/components/Tile'
import {
  SmallTitle,
  SmallText,
  RegularText,
} from 'shared/components/Typography'
import { COLORS, MEDIA_QUERIES, colors } from 'shared/config/constants'

const IncomeStreamsTable = styled<typeof StyledTable>(StyledTable)`
  & .ant-table-footer {
    background-color: ${COLORS.tile.default.background};
    border: 0;
    padding: 16px 0 16px !important;
    &:before {
      display: none;
    }
  }
  & .ant-table-thead > tr > th .ant-table-header-column {
    vertical-align: unset;
  }
  & .ant-table-tbody > tr:nth-last-child(1 of .ant-table-row) > td {
    border-bottom: 0 !important;
  }
  & .ant-table-tbody > tr > td {
    cursor: pointer;
  }
  & .not-expandable > td {
    cursor: default !important;
  }
`

const IncomeStreamDetailTable = styled<typeof StyledTable>(StyledTable)`
  background-color: ${COLORS.background};
  & .ant-table {
    border: 0;
    border-radius: 0;
    border-left: 1px solid ${COLORS.borders};
    cursor: default;
  }
  & .ant-table-title {
    padding: 0 !important;
  }
  & .ant-table-body {
    margin: 0 !important;
  }
  & .ant-table-thead {
    background-color: ${COLORS.gray4};
    & tr:first-child > th:first-child {
      border-top-left-radius: 0;
    }
    & tr:first-child > th:last-child {
      border-top-right-radius: 0;
    }
    & tr > th {
      padding: 6px 24px !important;
    }
  }
  & .ant-table-tbody > tr > td {
    cursor: default;
    padding: 6px 24px !important;
  }
  & tbody > tr {
    &:not(.ant-table-expanded-row):hover {
      & > td {
        background-color: ${COLORS.background} !important;
      }
    }
  }
  & .ant-table-tbody > tr.income-ocr-expanded > td {
    padding: 0px !important;
  }
  & .ant-table-tbody > tr > td {
    cursor: pointer;
  }
`

const DocumentLink = styled(Button)`
  color: ${COLORS.backLink.default};
  font-size: 15px;
  font-weight: 400;
  & > span {
    text-decoration: underline;
    text-decoration-color: ${COLORS.backLink.default};
  }
  &:hover span {
    color: ${COLORS.backLink.hover};
    text-decoration-color: ${COLORS.backLink.hover};
  }
  float: right;
`

const Divider = styled(BaseDivider)`
  background-color: ${COLORS.black};
  margin: 0 0 24px;
`

// Ocrolus Accordion components

const HeaderMainLabel = styled(SmallTitle)`
  margin-right: 5px !important;
  font-weight: 600 !important;
  color: ${(props) => props.color} !important;
`

const TileCustom = styled(Tile)`
  margin-bottom: 0;
  border: none;
  .tile-header {
    padding: 15px;
    align-items: center;
    border-bottom-color: transparent;
  }
  .tile-header-title-container {
    width: 100%;
    > div,
    h2 > div {
      width: 100%;
      align-items: center;
    }
    div {
      font-size: 15px;
      font-weight: 400;
    }
    .main-header-left {
      &.vor {
        width: 100%;
        > div {
          width: 70%;
        }
      }
      .bold {
        font-size: 17px;
        font-weight: 500;
      }
    }
  }
  &.invalid.can-view {
    cursor: pointer;
  }
  & .ant-table-thead {
    border-top: 1px solid ${COLORS.borders};
    border-bottom: 1px solid ${COLORS.borders};
    & tr > th {
      background-color: ${COLORS.white};
      color: ${COLORS.primary};
      font-size: 12px;
      font-weight: 500;
    }
  }
  & .ant-table-body {
    & tr > td {
      background: ${COLORS.white};
    }
    span,
    div {
      font-size: 12px;
    }
    .details {
      font-size: 15px;
    }
  }
`

const FraudSignalContainer = styled(FlexContainer)`
  background-color: ${COLORS.gray4};
  align-items: center;
  padding: 8px 0px 8px 72px;
  column-gap: 8px;
  border-bottom: 1px solid ${COLORS.midGray};
`

const CapitalizedContainer = styled(FlexContainer)`
  text-transform: capitalize;
`

const SupportingDataText = styled(RegularText)`
  cursor: pointer;
  color: ${COLORS.violetTheme.lightViolet};
`

const RevertButton = styled(Button)`
  border-color: ${COLORS.blueViolet};
  border-radius: 4px;
  color: ${COLORS.blueViolet};
  display: inline;
  text-transform: uppercase;
  &:hover {
    border-color: ${COLORS.purple} !important;
    color: ${COLORS.purple} !important;
  }
  &:focus {
    border-color: ${COLORS.purple} !important;
    color: ${COLORS.purple} !important;
  }
  ${[MEDIA_QUERIES.w.ltSm]} {
    align-self: flex-end;
  }
`

const IncomeStreamHeader = styled(SmallText)`
  font-weight: 600;
  text-transform: uppercase;
`

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
`

const EditedChip = styled(Chip)`
  border: 1px solid ${COLORS.primary};
  width: 56px;
  height: 16px;
  display: flex;
  justify-content: center;
  padding: unset;
  span {
    font-size: 12px;
  }
`

const MoreLink = styled(ActionLink)`
  align-self: end;
  font-size: 12px;
  font-weight: 400;
  padding-left: 10px;
  text-decoration: underline;
  color: ${colors.secondary[500]};
`

const StyledMenu = styled(Menu)`
  border-radius: 8px !important;
  padding: 8px 0 !important;
  &::before {
    content: '' !important;
    height: 0 !important;
    width: 0 !important;
  }
`

const StyledModal = styled(Modal)`
  .ant-modal-header,
  .ant-modal-content {
    border-radius: 16px;
    & .ant-modal-body > div:first-of-type {
      padding: 20px 48px;
      & ul {
        padding-left: 14px;
      }
    }
  }
`

const EditIncomeStreamReason = styled.textarea<{ valid: boolean }>`
  border-color: ${COLORS.secondary};
  border-radius: 8px;
  color: ${COLORS.primary};
  display: block;
  padding: 4px;
  resize: none;
  width: 100%;
  &::selection {
    background: ${COLORS.purple};
  }
  ${({ valid }) =>
    !valid &&
    ` border-color: ${COLORS.red};
      &:focus-visible {
        outline: 2px ${COLORS.red} solid;
      }
    `};
  ${({ valid }) =>
    valid &&
    `&:focus-visible {
      outline: 2px ${COLORS.purple} solid;
    }
  `}
`

export {
  Divider,
  DocumentLink,
  IncomeStreamsTable,
  IncomeStreamDetailTable,
  HeaderMainLabel,
  TileCustom,
  FraudSignalContainer,
  CapitalizedContainer,
  SupportingDataText,
  RevertButton,
  IncomeStreamHeader,
  ActionsContainer,
  EditedChip,
  MoreLink,
  EditIncomeStreamReason,
  StyledMenu,
  StyledModal,
}
