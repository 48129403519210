import React, { useState } from "react";

import { useParams } from "react-router-dom";

import { SurveyQuestionModel } from "shared/interfaces/api";
import {
  useNextSurveyQuestion,
  usePrevSurveyQuestion,
} from "surveys/hooks/api";
import { GetSurveyResponse, SurveyUrlParams } from "surveys/interfaces/api";

import QuestionStep from "./QuestionStep";

interface QuestionStepContainerProps {
  survey: GetSurveyResponse;
}

const QuestionStepContainer = ({ survey }: QuestionStepContainerProps) => {
  const { surveyHashId } = useParams<SurveyUrlParams>();
  const [surveyQuestion, setSurveyQuestion] = useState(survey?.question);
  const [showCommentField, setShowCommentField] = useState(false);

  const { nextSurveyQuestion, isNextSurveyQuestionLoading } =
    useNextSurveyQuestion();

  const { prevSurveyQuestion, isPrevSurveyQuestionLoading } =
    usePrevSurveyQuestion();

  const onSuccess = (question: SurveyQuestionModel) => {
    setSurveyQuestion(question);
  };

  const onNext = () => {
    nextSurveyQuestion({ ...surveyQuestion, surveyHashId }, { onSuccess });
    setShowCommentField(false);
  };

  const onBack = () => {
    prevSurveyQuestion({ ...surveyQuestion, surveyHashId }, { onSuccess });
    setShowCommentField(false);
  };

  return (
    surveyQuestion && (
      <QuestionStep
        applicantName={survey?.applicantName}
        questionCount={survey?.questionCount}
        surveyQuestion={surveyQuestion}
        setSurveyQuestion={setSurveyQuestion}
        showCommentField={showCommentField}
        setShowCommentField={setShowCommentField}
        onNext={onNext}
        onBack={onBack}
        loading={isNextSurveyQuestionLoading || isPrevSurveyQuestionLoading}
      />
    )
  );
};

export default QuestionStepContainer;
