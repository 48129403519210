export enum SurveyType {
  VOR = "VOR",
}

export enum SurveyStatus {
  WAITING = "waiting",
  NOTIFIED = "notified",
  SUBMITTED = "submitted",
  VERIFIED = "verified",
}

export interface SurveyModel {
  id?: number;
  objectId: number;
  contentType: string;
  surveyType: SurveyType;
  surveyQuestions: SurveyQuestionModel[];
}

export interface SurveyQuestionModel {
  id: number;
  question: string;
  position: number;
  surveyId: number;
  answer?: string;
  comment?: string;
}
