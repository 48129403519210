import axios from "axios";
import snakeCase from "lodash/snakeCase";

import ROUTES from "manager/config/apiRoutes";
import { API_URL } from "shared/lib/api/setupApi";
import { transformRoute } from "shared/utils/routing";

const getCustomEmailFormData = (params) => {
  const formData = new FormData();

  Object.keys(params).forEach((name) => {
    if (name === "attachments") {
      params[name].forEach((file, idx) =>
        formData.append(`${snakeCase(name)}${idx + 1}`, file)
      );
    } else {
      formData.append(snakeCase(name), params[name]);
    }
  });

  return formData;
};

const api = {
  getCustomEmailConfigurations: ({ typeName }) =>
    axios
      .get(`${API_URL}${ROUTES.customEmailConfigurations}`, {
        params: {
          type_name: typeName,
        },
      })
      .then(({ data }) => data),

  getCustomEmailConfiguration: (id) =>
    axios
      .get(
        `${API_URL}${transformRoute(ROUTES.customEmailConfiguration, { id })}`
      )
      .then(({ data }) => data),

  deleteCustomEmailConfiguration: (id) =>
    axios
      .delete(
        `${API_URL}${transformRoute(ROUTES.customEmailConfiguration, { id })}`
      )
      .then(({ data }) => data),

  /**
   * @param {Object} params
   * @param {String} params.name
   * @param {String} params.subject
   * @param {String} params.title
   * @param {String} params.body
   * @param {String} params.ctaLabel
   * @param {Number} params.defaultConfigurationId
   * @param {Boolean} params.hideCta
   * @param {Array<File>} params.attachments
   */
  createCustomEmailConfiguration: (params) => {
    const formData = getCustomEmailFormData(params);

    return axios
      .post(`${API_URL}${ROUTES.customEmailConfigurations}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data }) => data);
  },

  /**
   * @param {Object} payload
   * @param {Number} payload.id
   * @param {String} payload.name
   * @param {String} payload.subject
   * @param {String} payload.title
   * @param {String} payload.body
   * @param {String} payload.ctaLabel
   * @param {Number} payload.defaultConfigurationId
   * @param {Boolean} payload.hideCta
   * @param {Array<File>} payload.attachments
   * @param {Array<number>} payload.previousAttachmentIds - attachments remaining from previous save
   */
  updateCustomEmailConfiguration: (payload) => {
    const { id, ...params } = payload;
    const formData = getCustomEmailFormData(params);

    return axios
      .patch(
        `${API_URL}${transformRoute(ROUTES.customEmailConfiguration, { id })}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(({ data }) => data);
  },

  setCustomEmailConfigurationAsDefault: (id) =>
    axios
      .patch(
        `${API_URL}${transformRoute(
          ROUTES.setCustomEmailConfigurationAsDefault,
          { id }
        )}`
      )
      .then(({ data }) => data),

  getDefaultEmailConfigurations: ({ typeName }) =>
    axios
      .get(`${API_URL}${ROUTES.defaultEmailConfigurations}`, {
        params: { type_name: typeName },
      })
      .then(({ data }) => data),

  getDefaultEmailConfiguration: (id) =>
    axios
      .get(
        `${API_URL}${transformRoute(ROUTES.defaultEmailConfiguration, { id })}`
      )
      .then(({ data }) => data),

  restoreDefaultEmailConfiguration: ({ typeName }) =>
    axios
      .patch(`${API_URL}${ROUTES.restoreDefaultEmailConfiguration}`, {
        typeName,
      })
      .then(({ data }) => data),

  createPropertyEmailConfiguration: ({
    propertyId,
    customEmailConfigurationId,
  }) =>
    axios
      .post(`${API_URL}${ROUTES.propertyEmailConfigurations}`, {
        property: propertyId,
        customEmailConfiguration: customEmailConfigurationId,
      })
      .then(({ data }) => data),

  getPropertyEmailConfigurations: ({ propertyId, typeName }) =>
    axios
      .get(`${API_URL}${ROUTES.propertyEmailConfigurations}`, {
        params: {
          property_id: propertyId,
          type_name: typeName,
        },
      })
      .then(({ data }) => data),

  deletePropertyEmailConfiguration: (configurationId) =>
    axios
      .delete(
        `${API_URL}${transformRoute(ROUTES.propertyEmailConfiguration, {
          configurationId,
        })}`
      )
      .then(({ data }) => data),

  customEmailConfigurationSendTest: (id) =>
    axios
      .post(
        `${API_URL}${transformRoute(ROUTES.customEmailConfigurationSendTest, {
          id,
        })}`
      )
      .then(({ data }) => data),

  defaultEmailConfigurationSendTest: ({ typeName }) =>
    axios
      .post(`${API_URL}${ROUTES.defaultEmailConfigurationSendTest}`, {
        typeName,
      })
      .then(({ data }) => data),

  /**
   * @returns {Promise<[{
   *  key: String,
   *  label: String,
   *  sampleValue: String
   * }]>}
   * */
  getEmailTemplateVariables: () =>
    axios
      .get(`${API_URL}${ROUTES.emailTemplateVariables}`)
      .then(({ data }) => data),
};

export default api;
