import React from "react";

import PropTypes from "prop-types";

import {
  CollapsibleCheckboxList,
  SearchableList,
} from "shared/components/Form";
import Icon from "shared/components/Icon";
import { keyPropType } from "shared/utils/propTypes";

import { ModalBody } from "./styled";

const SelectAgentsStep = ({
  items,
  onSearch,
  nameOrEmail,
  isAgentsLoading,
  checkedItemsOnChange,
  checkedItems,
  indeterminateItems,
}) => {
  return (
    <ModalBody>
      <SearchableList
        search={{
          fieldName: "nameOrEmail",
          label: "Search by name or email",
          action: onSearch,
          width: "370px",
          debounce: true,
          disabled: false,
          value: nameOrEmail,
        }}
        List={CollapsibleCheckboxList}
        listProps={{
          items,
        }}
        listContainerProps={{
          checkedItemsOnChange,
          checkedItems,
          indeterminateItems,
        }}
        loading={isAgentsLoading}
        hasInputValue={false}
        placeholder={{
          label: "Agents",
          Icon: Icon.NoEmployeesIcon,
        }}
      />
    </ModalBody>
  );
};

SelectAgentsStep.propTypes = {
  checkedItems: PropTypes.arrayOf(keyPropType).isRequired,
  checkedItemsOnChange: PropTypes.func.isRequired,
  indeterminateItems: PropTypes.arrayOf(keyPropType).isRequired,
  isAgentsLoading: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: keyPropType.isRequired,
      label: PropTypes.node.isRequired,
      description: PropTypes.node,
      parent: keyPropType,
    })
  ).isRequired,
  nameOrEmail: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
};

SelectAgentsStep.defaultProps = {
  nameOrEmail: undefined,
};

export default SelectAgentsStep;
