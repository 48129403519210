import React from "react";

import { Form, Formik, Field } from "formik";

import { Input, Select, VeroFormField } from "shared/components/Form";
import { Modal } from "shared/components/Modal";
import Spacer from "shared/components/Spacer";
import Tooltip from "shared/components/Tooltip";

import { CreatePortfolioModalProps } from "./interfaces";
import { InfoIcon } from "./styled";
import { ValidationSchema } from "./utils";

const CreatePortfolioModal = ({
  isMobile,
  submit,
  portfolios = [],
  loading,
  defaultPortfolio,
}: CreatePortfolioModalProps) => {
  const initialValues = {
    name: undefined,
    sourcePortfolio: defaultPortfolio,
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={ValidationSchema}
      initialValues={initialValues}
      isInitialValid={ValidationSchema.isValidSync(initialValues)}
      onSubmit={submit}
    >
      {({ errors, submitForm, isSubmitting, isValid, setFieldValue }) => (
        <Form>
          <Modal
            title="Create New Portfolio"
            submitButtonDisabled={!isValid}
            submit={submitForm}
            submitting={isSubmitting}
            closeOnSubmit={false}
            submitButtonLabel="Continue"
            fullScreen={isMobile}
          >
            <Modal.Body>
              <p>Please enter the name of the new portfolio</p>
              <Field
                id="name"
                name="name"
                label="Portfolio Name"
                as={Input}
                error={errors.name}
              />
              <Spacer />
              <p>
                Which portfolio you would like to inherit configurations from?
                <Tooltip
                  theme="dark"
                  title="Any settings or preferences will be copied over from this portfolio to the one you are creating."
                >
                  <InfoIcon />
                </Tooltip>
              </p>
              <VeroFormField
                name="sourcePortfolio"
                id="sourcePortfolio"
                label="Portfolio Source"
                as={Select}
                error={errors.sourcePortfolio}
                onChange={(value) => setFieldValue("sourcePortfolio", value)}
                loading={loading}
              >
                {portfolios.map((portfolio) => (
                  <Select.Option key={portfolio.key}>
                    {portfolio.label}
                  </Select.Option>
                ))}
              </VeroFormField>
            </Modal.Body>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default CreatePortfolioModal;
