import React from "react";

import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import Tooltip from "shared/components/Tooltip";
import { RegularText } from "shared/components/Typography";

interface AttachmentVisibilitySwitchTitleProps {
  uploadedByApplicant: boolean;
  isRestricted: boolean;
}

const titleText = (uploadedByApplicant: boolean, isRestricted: boolean) => {
  if (uploadedByApplicant)
    return "Attachments uploaded by the applicant cannot be made internal only.";

  if (isRestricted)
    return "Restricted attachments cannot be made visible to the renter.";

  return "Enabling will allow the applicant to view the attachment within the Renter Portal";
};

const AttachmentVisibilitySwitchTitle = ({
  uploadedByApplicant,
  isRestricted,
}: AttachmentVisibilitySwitchTitleProps) => (
  <FlexContainer alignItems="center" justifyContent="space-between">
    <RegularText>Visible to Renter</RegularText>
    <Tooltip title={titleText(uploadedByApplicant, isRestricted)}>
      <Icon.InfoIcon />
    </Tooltip>
  </FlexContainer>
);

export default AttachmentVisibilitySwitchTitle;
