export const fileUploadHandler = (file) => {
  const item = {
    ...file,
    id: `${Date.now()}`,
    name: file.name.toLowerCase(),
    status: "done",
  };
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (loadedFile) => {
      item.url = loadedFile.target.result;
      resolve(item);
    };
  });
};
