import _ from 'lodash'
import React from 'react'
import Well from 'shared/components/Well'
import Disclaimer from './Disclaimer'

const Result = ({ result }) => {
  return (
    <div className='vega result'>
      {_.isBoolean(result) && <Well white>
        <span className={`vega badge large ${result ? 'pass' : 'fail'}`}>{result?.toString()}</span>
      </Well>}

      {(_.isPlainObject(result) || _.isArray(result)) && <div>
        <Well>
          <pre className='vega'>{JSON.stringify(result, null, 2)}</pre>
        </Well>

        <Disclaimer />
      </div>}

      {(_.isString(result) || _.isNumber(result) || _.isNil(result)) && <div>
        <Well>
          <code className='vega'>{_.isNil(result) ? 'null' : result?.toString()}</code>
        </Well>

        <Disclaimer />
      </div>}
    </div>
  )
}

export default Result
