import React from "react";

import PropTypes from "prop-types";

import Icon from "shared/components/Icon";
import Table from "shared/components/Table/Table/Table";

const VeroEventTable = ({ yardiVeroEvent, isLoadingVeroEvent }) => {
  return (
    <Table
      alt
      withBorder
      noClickableRows
      columns={[
        {
          title: "Value",
          dataIndex: "value",
          key: "value",
        },
        {
          title: "Source",
          dataIndex: "source",
          key: "source",
        },
        {
          title: "Created At",
          dataIndex: "createdAt",
          key: "createdAt",
        },
      ]}
      rows={yardiVeroEvent.map((veroEvent) => ({
        key: veroEvent.id,
        values: [veroEvent.value, veroEvent.source, veroEvent.createdAt],
      }))}
      loading={isLoadingVeroEvent}
      placeholder={{
        label: "VERO Events",
        Icon: Icon.NoDataIcon,
      }}
      columnBreakpoints={{
        xs: {
          column1: "value",
          column2: "source",
          column3: "createdAt",
        },
        sm: ["value", "source", "createdAt"],
      }}
      pagination={false}
    />
  );
};

VeroEventTable.propTypes = {
  yardiVeroEvent: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  isLoadingVeroEvent: PropTypes.bool.isRequired,
};

export default VeroEventTable;
