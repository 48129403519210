import React from 'react'
import _ from 'lodash'
import Icon from 'shared/components/Icon'
import { Explanation } from 'shared/components/vega/ExpressionEngine'

function getOutcomeIcon (outcome) {
  switch(outcome?.scoreLabelColor) {
    case 'fail':
      return Icon.CrossRedSmall
    case 'warn':
      return Icon.OrangeExclamation
    case 'pass':
    default:
      return Icon.CheckGreenSmall
  }
}

const Outcome = ({ outcome, entry }) => {
  const OutcomeIcon = getOutcomeIcon(outcome)

  return (
    <div className='vega flex no-wrap'>
      <OutcomeIcon className='vega icon large' />

      <div className='vega flex column'>
        <Explanation explanation={entry?.explanation} titleOnly />

        {_.size(outcome?.recommendations) ? <div className='vega flex gap small column'>
          <span className='vega title small'>possible fixes:</span>
          <ul className='vega'>{_.map(outcome?.recommendations, r => <li className='vega info' key={r}>{r}</li>)}</ul>
        </div> : null}
      </div>
    </div>
  )
}

const Outcomes = ({ lumenRun }) => {
  const entries = _.flatMap(lumenRun?.results, result => result?.evaluation?.history)

  return <>
    {!_.isEmpty(lumenRun?.outcomes) && <div className='vega content'>
      {_.chain(lumenRun?.outcomes).map(
        outcome => ({
          outcome,
          entry: _.find(entries, entry => _.includes(entry?.formula, outcome?.formula))
        })
      ).uniqBy(({ outcome }) => _.chain(outcome?.recommendations).castArray().join().value()).map(({ outcome, entry }) => {
        return <Outcome outcome={outcome} entry={entry} key={outcome?.formula} />
      }).value()}
    </div>}
  </>
}

export default Outcomes
