import dayjs from "dayjs";

import range from "lodash/range";
import { useUpdateDealRevenueDetails } from "manager/hooks/api";
import { DATE_FORMAT } from "shared/config/constants";
import useModal from "shared/hooks/useModal";

import { UpdateDealRevenueDetailsValues } from "./interfaces";

const MAXIMUM_LEASE_DURATION = 36;
export const getLeaseDurationOptions = (minMonths) => {
  const options = range(1, MAXIMUM_LEASE_DURATION + 1);

  if (minMonths) {
    return options.slice(minMonths - 1);
  }
  return options;
};

export const useUpdateDealRevenueDetailsHook = ({
  refetchDeal,
  dealId,
  initValues,
  availableDate,
}: {
  refetchDeal?: () => void;
  dealId: number;
  initValues: UpdateDealRevenueDetailsValues;
  availableDate: dayjs.Dayjs;
}) => {
  const { updateDealRevenueDetails } = useUpdateDealRevenueDetails({ dealId });
  const { closeActiveModalDialog } = useModal();
  const submit = (
    values: UpdateDealRevenueDetailsValues,
    { setSubmitting, setErrors }
  ) => {
    const {
      preferredLeaseStartDate: initPreferredLeaseStartDate,
      preferredLeaseDurationMonths: initPreferredLeaseDurationMonths,
    } = initValues;
    const { rent } = values;
    updateDealRevenueDetails(
      {
        dealId,
        values:
          initPreferredLeaseStartDate && initPreferredLeaseDurationMonths
            ? values
            : { rent },
      },
      {
        onSuccess: () => {
          closeActiveModalDialog();
          refetchDeal?.();
        },
        onError: (error) => {
          setSubmitting(false);
          setErrors(error.errors);
        },
      }
    );
  };

  const isDateDisabled = (date: dayjs.Dayjs): boolean =>
    Boolean(availableDate?.subtract(1, "day").isAfter(date, "day"));

  const unitAvailabilityTooltip = (date: dayjs.Dayjs) => {
    const dateDisabled = isDateDisabled(date);
    return !!availableDate && dateDisabled
      ? `The unit is only available after ${availableDate.format(DATE_FORMAT)}`
      : null;
  };

  return {
    submit,
    isDateDisabled,
    unitAvailabilityTooltip,
  };
};
