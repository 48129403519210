import axios from "axios";

import ROUTES from "shared/config/apiRoutes";

import { API_URL } from "./setupApi";

const api = {
  listRoles: () => {
    return axios.get(`${API_URL}${ROUTES.listRoles}`);
  },
};

export default api;
