import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import * as Yup from "yup";

import {
  CreateLumenFormulaModal,
  AssignFormulaToPropertiesModal,
} from "manager/components/Lumen";
import CourtesyMessageModal from "manager/components/Lumen/CourtesyMessageModal";
import MarkFormulaAsTemplateModal from "manager/components/Lumen/MarkFormulaAsTemplateModal";
import {
  LUMEN_FORMULA_STATUS,
  LUMEN_FORMULA_TYPES,
} from "manager/config/lumen.config";
import ROUTES from "manager/config/routes";
import {
  useCreateLumenFormula,
  useDeleteLumenFormula,
  useDuplicateLumenFormula,
  useEditLumenFormula,
  useGetLumenFormulas,
  useGetLumenFormulaTemplates,
  useMarkLumenFormulaAsTemplate,
  useRenameLumenFormula,
} from "manager/hooks/api";
import ConfirmationModal from "shared/components/Modals/ConfirmationModal";
import SingleInputModal from "shared/components/Modals/SingleInputModal";
import { useDeviceType, usePermissions } from "shared/hooks";
import useModal from "shared/hooks/useModal";
import { BackLinkProps } from "shared/interfaces";
import { transformRoute } from "shared/utils/routing";
import { sanitizeStringSearchValue } from "shared/utils/string";

import { LumenFormulaListItem } from "./interfaces";
import LumenDashboard from "./LumenDashboard";
import {
  getUsedFormulaNames,
  getUsedTemplateNames,
  LUMEN_FORMULA_FILTER,
} from "./lumenDashboard.config";

interface LumenDashboardContainerProps {
  backLink: BackLinkProps;
}

const LumenDashboardContainer = ({
  backLink,
}: LumenDashboardContainerProps) => {
  const history = useHistory();
  const { openModalDialog } = useModal();
  const { isLumenEnabled, isLumenFormulaExporter, permissionsLoaded } =
    usePermissions();
  const { lumenFormulas: allLumenFormulas, isLoading: isLumenFormulasLoading } =
    useGetLumenFormulas(isLumenEnabled);
  const { createLumenFormula } = useCreateLumenFormula();
  const { duplicateLumenFormula } = useDuplicateLumenFormula();
  const { renameLumenFormula } = useRenameLumenFormula();
  const { deleteLumenFormula } = useDeleteLumenFormula();
  const { editLumenFormula } = useEditLumenFormula();
  const { markLumenFormulaAsTemplate } = useMarkLumenFormulaAsTemplate();
  const { lumenFormulaTemplates, isLoading: isLumenFormulaTemplatesLoading } =
    useGetLumenFormulaTemplates(isLumenEnabled);

  const { isMobile } = useDeviceType();
  const [formulaStatusFilter, setFormulaStatusFilter] = useState<string>(
    LUMEN_FORMULA_FILTER.ACTIVE
  );

  const lumenFormulas = (allLumenFormulas || []).filter(
    (formula) =>
      formulaStatusFilter === LUMEN_FORMULA_FILTER.ALL ||
      formula.status === formulaStatusFilter
  );

  const isTableLoading = isLumenFormulasLoading;

  const totalCount = allLumenFormulas.length;

  const navigateToFormulaRevision = (formulaId, revisionId) => {
    history.push(
      transformRoute(ROUTES.lumenFormulaRevision, { formulaId, revisionId })
    );
  };

  const makeOpenCourtesyModal = (message: string) => () =>
    openModalDialog(CourtesyMessageModal, {
      message,
    });

  const openCreateFormulaCourtesyModal = makeOpenCourtesyModal(
    "Please open VERO on your desktop to create new Lumen formula."
  );

  const openReviewFormulaCourtesyModal = makeOpenCourtesyModal(
    "Please open VERO on your desktop to review a detailed Lumen configuration."
  );

  const createNewLumenFormula = () => {
    if (isMobile) {
      openCreateFormulaCourtesyModal();
    } else {
      const usedNames = getUsedFormulaNames(allLumenFormulas);
      openModalDialog(CreateLumenFormulaModal, {
        usedNames,
        lumenFormulaTemplates,
        isLumenFormulaTemplatesLoading,
      }).afterClose.then((data) => {
        if (data) {
          let dto = {
            name: data.name,
          };

          if (LUMEN_FORMULA_TYPES.template === data.formulaType) {
            dto = {
              ...dto,
              // @ts-ignore
              formulaTemplateId: data.formulaTemplateId,
            };
          } else {
            dto = {
              ...dto,
              ...data.sections,
            };
          }

          const onSuccess = (newFormula) => {
            navigateToFormulaRevision(newFormula.id, newFormula.revisionId);
          };
          // @ts-ignore
          createLumenFormula(dto, { onSuccess });
        }
      });
    }
  };

  const duplicateFormula = (formula: LumenFormulaListItem) => {
    if (isMobile) {
      openReviewFormulaCourtesyModal();
    } else {
      const context = {
        title: `Duplicate ${formula.name} formula`,
        inputDescription: "Please enter the new formula name",
        inputPlaceholder: "The name of the duplicate",
        confirmLabel: "CONFIRM & CONTINUE",
        valueValidationSchema: Yup.string().required("Name is required"),
        submit: ({ value }) => {
          const onSuccess = ({ data }) => {
            navigateToFormulaRevision(data.newFormulaId, data.newRevisionId);
          };
          return duplicateLumenFormula(
            {
              // @ts-ignore
              formulaId: formula.id,
              duplicateFormulaDto: {
                name: value,
              },
            },
            { onSuccess }
          );
        },
      };
      openModalDialog(SingleInputModal, context);
    }
  };

  const editAndNavigate = (formula: LumenFormulaListItem) => {
    const onSuccess = ({ data }) => {
      navigateToFormulaRevision(formula.id, data.newRevisionId);
    };
    editLumenFormula(formula.id, { onSuccess });
  };

  const editFormula = (formula: LumenFormulaListItem) => {
    if (isMobile) {
      openReviewFormulaCourtesyModal();
    } else if (formula.status === LUMEN_FORMULA_STATUS.DRAFT) {
      navigateToFormulaRevision(formula.id, formula.revisionId);
    } else if (formula.numPropertiesAssigned > 0) {
      const context = {
        title: `Edit formula?`,
        description:
          "Current formula has properties assigned. From the moment you update it only new applications will have the formula updates applied.",
        submitButtonLabel: "CONFIRM & CONTINUE",
      };
      openModalDialog(ConfirmationModal, context).afterClose.then((result) => {
        if (result) {
          editAndNavigate(formula);
        }
      });
    } else {
      editAndNavigate(formula);
    }
  };

  const renameFormula = (formula: LumenFormulaListItem) => {
    const usedNames = getUsedFormulaNames(allLumenFormulas, formula);
    const context = {
      title: `Rename ${formula.name} formula`,
      inputDescription: "Please edit the formula name ",
      inputPlaceholder: "Formula Name",
      confirmLabel: "SAVE CHANGES",
      closeOnSubmit: true,
      initialValue: formula.name,
      valueValidationSchema: Yup.string()
        .required("Name is required")
        .nullable()
        .test(
          "uniqueFormulaName",
          "The name of the formula must be unique",
          (value) => {
            const sanitizedValue = sanitizeStringSearchValue(value);
            return !usedNames.includes(sanitizedValue);
          }
        ),
      submit: ({ value }) => {
        return renameLumenFormula({
          formulaId: formula.id,
          renameFormulaDto: {
            name: value,
          },
        });
      },
    };
    openModalDialog(SingleInputModal, context);
  };

  const deleteFormula = (formula) => {
    const context = {
      title: `Remove ${formula.name} formula?`,
      message:
        "Please note, once you remove this formula you won't be able to restore it.",
      submitButtonLabel: "REMOVE",
    };
    openModalDialog(ConfirmationModal, context).afterClose.then((result) => {
      if (result) {
        deleteLumenFormula(formula.id);
      }
    });
  };

  const markFormulaAsTemplate = (formula: LumenFormulaListItem) => {
    if (isMobile) {
      openCreateFormulaCourtesyModal();
    } else {
      const usedTemplateNames = getUsedTemplateNames(lumenFormulaTemplates);
      openModalDialog(MarkFormulaAsTemplateModal, {
        formula,
        usedTemplateNames,
      }).afterClose.then((data) => {
        if (data) {
          markLumenFormulaAsTemplate({
            formulaId: formula.id,
            markFormulaAsTemplateDto: data,
          });
        }
      });
    }
  };

  const showMarkFormulaAsTemplate = isLumenFormulaExporter;

  const showFormulaRevisions = (formula: LumenFormulaListItem) => {
    // TODO igeshosk implement
    // eslint-disable-next-line no-console
    console.log("Show formula revisions: ", formula);
  };

  const assignFormula = (formula: LumenFormulaListItem) => {
    openModalDialog(AssignFormulaToPropertiesModal, {
      formula,
    });
  };

  useEffect(() => {
    if (isMobile) {
      openReviewFormulaCourtesyModal();
      history.replace(ROUTES.lumenDashboard);
    }
  }, []);

  const tableData = {
    lumenFormulas,
    createNewLumenFormula,
    formulaStatusFilter,
    setFormulaStatusFilter,
    assignFormula,
    duplicateFormula,
    editFormula,
    renameFormula,
    showFormulaRevisions,
    deleteFormula,
    showMarkFormulaAsTemplate,
    markFormulaAsTemplate,
    isTableLoading,
    totalCount,
  };

  return (
    <LumenDashboard
      isLumenEnabled={isLumenEnabled}
      permissionsLoaded={permissionsLoaded}
      tableData={tableData}
      backLink={backLink}
    />
  );
};

export default LumenDashboardContainer;
