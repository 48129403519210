import styled from "@emotion/styled";

import PageTitle from "shared/components/PageTitle";
import { Popover } from "shared/components/Popover";
import { COLORS } from "shared/config/constants";
import {
  AlignedCenterFlexPartial,
  CenteredFlexPartialCss,
} from "shared/styles/commonStyles";

interface YardiTextProps {
  fontWeight?: number;
  noPadding?: boolean;
  noWrap?: string;
}

const StyledIntegrationLogin = styled.div`
  ${CenteredFlexPartialCss}
  margin-top: 80px;

  form {
    width: 290px;
  }

  button {
    width: 100%;
    margin-top: 32px;
  }

  img {
    margin-bottom: 24px;
  }

  .subtitle {
    font-size: 17px;
    margin-bottom: 32px;
    text-align: center;
  }

  .back-link {
    align-self: flex-start;
    margin: 32px 0px 0px 0px;
  }

  .yardi-login-content {
    ${CenteredFlexPartialCss}
    flex-direction: column;
    width: 100%;
  }

  .yardi-cancel-container {
    text-align: center;
    margin-top: 15px;
  }

  .submit-button {
    background-color: ${COLORS.lightViolet1};
    border-radius: 4px;
    height: 40px;
  }
`;

const YardiImg = styled.img`
  max-height: 35px;
`;

const YardiTitleContainer = styled(PageTitle)`
  display: flex;
  align-items: center;
`;

const YardiImageContainer = styled(PageTitle.Icon)`
  height: 40px;
`;

const YardiText = styled.span<YardiTextProps>`
  font-weight: ${(props) => props.fontWeight || 300};
  padding-right: 5px;
  &:first-of-type {
    padding-right: 10px;
  }
  &:last-of-type {
    padding-left: 10px;
  }
  padding: ${(props) => props.noPadding && "padding: 0 !important"};
  white-space: ${(props) => props.noWrap && "nowrap"};
`;

const PopoverContainer = styled(Popover)`
  ${{ ...AlignedCenterFlexPartial }}
`;

const YardiVersion = styled.p`
  font-size: 13px;
  margin-top: 16px;
  text-align: center;
`;

export {
  StyledIntegrationLogin,
  YardiImg,
  YardiTitleContainer,
  YardiImageContainer,
  YardiText,
  YardiVersion,
  PopoverContainer,
};
