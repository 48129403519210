import { Property } from "./interfaces";

export const mapProperty = (
  unit?: Record<string, any>
): Property | undefined => {
  if (!unit || !unit.property) return undefined;
  return {
    id: unit.property.id,
    name: unit.property.name,
    address: unit.property.address,
    yardiConnected: unit.property.yardiConnected,
    yardiPropertyId: unit.property.yardiPropertyId,
  };
};
