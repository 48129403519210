import styled from "@emotion/styled";

import Tabs from "shared/components/Tabs";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

const TabsContainer = styled(Tabs)(() => ({
  marginBottom: "16px",
  border: `1px solid ${COLORS.borders}`,
  borderBottom: 0,
  borderRadius: "2px",
  [MEDIA_QUERIES.w.ltMd]: {
    borderLeft: 0,
    borderRight: 0,
  },
}));

export { TabsContainer };
