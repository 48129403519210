import axios from "axios";

import ROUTES from "manager/config/apiRoutes";
import {
  SaveApplicationChargesRequest,
  ApplicationChargesModel,
  GetApplicationChargesRequest,
  YardiUnitTypesRequest,
  YardiUnitTypesResponse,
} from "manager/interfaces/api";
import { API_URL } from "shared/lib/api/setupApi";
import { transformRoute } from "shared/utils/routing";

const api = {
  getApplicationCharges: ({ id, level }: GetApplicationChargesRequest) =>
    axios
      .get<ApplicationChargesModel>(
        transformRoute(`${API_URL}${ROUTES.applicationCharges}`, {
          id,
          level,
        })
      )
      .then((response) => response.data),
  saveApplicationCharges: ({
    id,
    level,
    payload,
  }: SaveApplicationChargesRequest) =>
    axios
      .post<ApplicationChargesModel>(
        transformRoute(`${API_URL}${ROUTES.applicationCharges}`, {
          id,
          level,
        }),
        payload
      )
      .then((response) => response.data),
  getYardiUnitTypes: ({
    propertyId,
    page,
    pageSize,
    ordering,
  }: YardiUnitTypesRequest) =>
    axios
      .get<YardiUnitTypesResponse>(
        transformRoute(`${API_URL}${ROUTES.unitTypes}`, {
          propertyId,
        }),
        {
          params: {
            page_size: pageSize,
            page,
            ordering,
          },
        }
      )
      .then((response) => response.data),
};

export default api;
