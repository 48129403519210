import React, { useEffect, useState } from "react";

import { FormikHelpers } from "formik";
import { useHistory } from "react-router-dom";

import ROUTES from "manager/config/routes";
import {
  useGetPropertiesDropdown,
  useGetPropertyUnitsDropdown,
  useInviteLeadToApply,
} from "manager/hooks/api";
import useModal from "shared/hooks/useModal";
import { onDialogSubmitError } from "shared/utils/api";
import { getFullnameData } from "shared/utils/applicant";
import { transformRoute } from "shared/utils/routing";

import { FormProps, ModalContextProps } from "./interfaces";
import InviteLeadToApplyModal from "./InviteLeadToApplyModal";

const InviteLeadToApplyModalContainer = () => {
  const history = useHistory();
  const { currentModalContext, closeActiveModalDialog } = useModal();

  const { properties, isPropertiesLoading } = useGetPropertiesDropdown();

  const { lead }: ModalContextProps = currentModalContext;
  const { firstName, lastName, email, dealId, propertyId, unitId } = lead || {};

  const { fullNameOrNA } = getFullnameData({ firstName, lastName });

  const [selectedProperty, setSelectedPropery] = useState<number>(propertyId);

  const {
    units: unitsData,
    isUnitsLoading,
    refetchUnits,
  } = useGetPropertyUnitsDropdown({
    propertyId: selectedProperty,
    onlyActiveListingUnits: true,
  });

  const { inviteLeadToApply } = useInviteLeadToApply();

  const units = unitsData?.map((unit) => ({
    id: unit.id,
    name: unit.name,
    unitListing: unit.unitListingCurrent?.id,
  }));

  const leadUnitStillAvailable = unitsData
    ?.map((unit) => unit.id)
    .includes(unitId);
  const leadUnitId = leadUnitStillAvailable ? unitId : undefined;

  useEffect(() => {
    if (selectedProperty) {
      refetchUnits();
    }
  }, [selectedProperty]);

  const selectProperty = (
    value: number,
    setValues: (
      values: React.SetStateAction<FormProps>,
      shouldValidate?: boolean
    ) => void
  ) => {
    setSelectedPropery(value);
    setValues(
      {
        property: value,
        unit: undefined,
      },
      true
    );
  };

  const submit = (values: FormProps, formikApi: FormikHelpers<FormProps>) => {
    const { unitListing } = units.find(({ id }) => values.unit === id);
    inviteLeadToApply(
      {
        dealId,
        unitListing,
      },
      {
        onSuccess: ({ id }) => {
          closeActiveModalDialog();
          history.push(transformRoute(ROUTES.deal, { id }));
        },
        onError: onDialogSubmitError(
          formikApi,
          history,
          "Unable to invite lead to apply"
        ),
      }
    );
  };

  return (
    <InviteLeadToApplyModal
      leadFullName={fullNameOrNA}
      leadEmail={email}
      properties={properties}
      isPropertiesLoading={isPropertiesLoading}
      leadPropertyId={propertyId}
      leadUnitId={leadUnitId}
      units={units}
      isUnitsLoading={isUnitsLoading}
      selectProperty={selectProperty}
      submit={submit}
    />
  );
};

export default InviteLeadToApplyModalContainer;
