import { schema } from "normalizr";

import { additionalQuestionList } from "shared/state/AdditionalQuestion/schema";
import { requiredAttachmentList } from "shared/state/RequiredAttachment/schema";

export const dealSetup = new schema.Entity("dealSetup", {
  additionalQuestions: additionalQuestionList,
  requiredAttachments: requiredAttachmentList,
});

export const dealSetupList = [dealSetup];
