import React from "react";

import { useActiveUser } from "shared/hooks/api";
import { getAccessToken } from "shared/utils/auth";

import RequireAuth from "./RequireAuth";

const RequireAuthContainer = ({ render }: { render: () => JSX.Element }) => {
  const loggedIn = !!getAccessToken();
  const { activeUser } = useActiveUser();

  return (
    <RequireAuth
      loggedIn={loggedIn}
      isActiveUserLoaded={!!activeUser}
      render={render}
    />
  );
};

export default RequireAuthContainer;
