import axios from "axios";

import type {
  ListOfAssetRequestParams,
  ListOfAssetResponse,
} from "renter/interfaces/api/assetService";

import { VEGA_BASE_URL } from "shared/config/apiRoutes";

const ASSET_SERVICE_PATH = "/api/asset";

const api = {
  getAssets: ({ person, types }: ListOfAssetRequestParams) =>
    axios
      .get<ListOfAssetResponse>(`${VEGA_BASE_URL}${ASSET_SERVICE_PATH}`, {
        params: {
          person,
          types: types.join(","),
        },
      })
      .then(({ data }) => data),
};

export default api;
