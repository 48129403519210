import { queryCache, useMutation, useQuery } from "react-query";

import { ARGYLE_SYNC_STATUS } from "renter/config/constants";
import api from "renter/lib/api";
import { NOTIFICATIONS } from "shared/config/constants";
import { openNotification } from "shared/utils/ui";

const ARGYLE_SETUP_DATA_KEY = "getArgyleSetupData";
const GET_EMPLOYMENT_DATA_KEY = "getEmploymentData";
const GET_ARGYLE_SYNC_STATUS_KEY = "getArgyleSyncStatus";
const ARGYLE_STATUSES_TO_REFETCH_DATA = new Set([
  ARGYLE_SYNC_STATUS.SYNC_COMPLETED,
  ARGYLE_SYNC_STATUS.SYNC_FAILED,
]);
const ARGYLE_SYNC_STATUS_POLLING_INTERVAL_MILLIS = 5000;

const QUERY_KEYS = Object.freeze({
  getArgyleSyncStatus: (id) => [GET_ARGYLE_SYNC_STATUS_KEY, Number(id)],
});

export const useGetArgyleSetupData = (applicationId) => {
  const { data, refetch, isLoading } = useQuery(
    [ARGYLE_SETUP_DATA_KEY],
    () => api.getArgyleSetupData({ applicationId }),
    {
      onError: () =>
        openNotification(
          "Failed to get the Lynk setup data",
          NOTIFICATIONS.error
        ),
    }
  );
  return {
    argyleUserToken: data?.userToken,
    argyleCustomizationId: data?.argyleCustomizationId,
    refetchArgyleToken: refetch,
    isArgyleSetupDataLoading: isLoading,
  };
};

export const useGetArgyleSyncStatus = ({ applicationId, isPollingActive }) => {
  const { data, isLoading } = useQuery(
    QUERY_KEYS.getArgyleSyncStatus(applicationId),
    () => api.getArgyleSyncStatus({ applicationId }),
    {
      refetchInterval: isPollingActive
        ? ARGYLE_SYNC_STATUS_POLLING_INTERVAL_MILLIS
        : false,
      onSuccess: (responseData) => {
        const status = responseData?.argyleStatus;
        if (ARGYLE_STATUSES_TO_REFETCH_DATA.has(status)) {
          queryCache.invalidateQueries(GET_EMPLOYMENT_DATA_KEY);
        }
      },
      onError: () =>
        openNotification(
          "Failed to get the Lynk sync status",
          NOTIFICATIONS.error
        ),
    }
  );
  return {
    argyleSyncStatus: data?.argyleStatus,
    isArgyleSyncStatusLoading: isLoading,
  };
};

export const usePostArgyleEvent = () => {
  const [mutate, response] = useMutation(api.postArgyleEvent, {
    onSuccess: (_data, { applicationId }) => {
      queryCache.invalidateQueries(
        QUERY_KEYS.getArgyleSyncStatus(applicationId)
      );
    },
  });

  return {
    postArgyleEvent: mutate,
    isPostArgyleEventLoading: response.isLoading,
  };
};

export const usePostStartArgyleSync = () => {
  const [mutate, response] = useMutation(api.postStartArgyleSync, {
    onSuccess: (_data, { applicationId }) => {
      queryCache.invalidateQueries(
        QUERY_KEYS.getArgyleSyncStatus(applicationId)
      );
    },
  });

  return {
    startArgyleSync: mutate,
    isStartArgyleSyncLoading: response.isLoading,
  };
};

export const usePostCompleteLinkEmploymentStep = () => {
  const [mutate, response] = useMutation(api.postCompleteLinkEmploymentStep);

  return {
    completeLinkEmploymentStep: mutate,
    isCompleteLinkEmploymentStepLoading: response.isLoading,
  };
};
