import React from "react";

import { DealExtraDetailsIntegrationMark } from "manager/components/IntegrationMark";
import { FadeIn, Rotate } from "shared/components/animated";
import DropdownMenu from "shared/components/DropdownMenu";
import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import { ActionLink, IconLink } from "shared/components/Links";
import { Popover } from "shared/components/Popover";
import { ExtraStrongText } from "shared/components/Typography";

import { DealUnitHeaderItems, UnitPopover } from "./blocks";
import { useDealHeaderInternal } from "./hooks";
import { DealHeaderContent, InfoDark, MainText } from "./styled";
import { DealHeaderDeal } from "./types";
import { isCanceledDeal, isDeclinedDeal, TitlePopoverWrapper } from "./utils";

export type DealHeaderPropsV2 = {
  deal: DealHeaderDeal;
  refetchDeal?: () => void;
  contactAll?: any;
  invite?: any;
  requestCountyRecords?: any;
};

export const DealHeaderV2: React.FC<DealHeaderPropsV2> = ({
  deal,
  refetchDeal,
  contactAll,
  invite,
  requestCountyRecords
}) => {
  const {
    dealHeaderTitleContainerRef,
    dropdownItems,
    extraContentRef,
    hasProperty,
    hasSource,
    hasUnit,
    isExpanded,
    isMobile,
    mainTitle,
    pdfProps,
    propertyAddress,
    status,
    unitHeaderTitle,
    items,
    toggleExpandedSection,
    assignUnit,
    managerPropertyUrl
  } = useDealHeaderInternal({ deal, refetchDeal, contactAll, invite, requestCountyRecords });
  const shouldHideDownloadPDFButton =
    isCanceledDeal(status) && !deal?.primaryApplicantDeal?.isSubmitted;

  return (
    <DealHeaderContent
      isCancelledDeal={isCanceledDeal(status)}
      isDeclinedDeal={isDeclinedDeal(status)}
    >
      <div
        className="deal-header-first-col"
        role="button"
        tabIndex={0}
        onClick={() => isMobile && toggleExpandedSection()}
        onKeyPress={() => null}
        style={{
          cursor: isMobile ? "pointer" : "default",
          position: "relative",
        }}
      >
        <FadeIn delay={100}>
          <div
            ref={dealHeaderTitleContainerRef}
            className={`deal-header-title-container ${
              !hasUnit ? "deal-header-title-no-unit" : ""
            }`}
          >
            <TitlePopoverWrapper
              title={unitHeaderTitle}
              propertyAddress={propertyAddress}
            >
              <MainText><a href={managerPropertyUrl}>{mainTitle}</a></MainText>
          </TitlePopoverWrapper>
            {(hasUnit || hasProperty) && (
              <DealExtraDetailsIntegrationMark
                property={deal.property}
                unit={deal.unit}
                deal={deal}
                dark
                markRef={extraContentRef}
              />
            )}
            {!hasUnit && (
              <div ref={extraContentRef}>
                <ActionLink
                  className="deal-header-assign-unit"
                  onClick={assignUnit}
                >
                  <ExtraStrongText>ASSIGN UNIT</ExtraStrongText>
                </ActionLink>
              </div>
            )}
            {(hasUnit || hasSource) && isMobile && (
              <FlexContainer
                justifyContent="flex-end"
                style={{ marginLeft: 10 }}
              >
                <Rotate degrees={-180} on={isExpanded}>
                  <Icon.DropdownButtonChevronDownIcon
                    className="dropdown-chevron"
                    onClick={toggleExpandedSection}
                  />
                </Rotate>
              </FlexContainer>
            )}
          </div>
        </FadeIn>

        <FadeIn delay={150}>
          <FlexContainer alignItems="center">
            <DealUnitHeaderItems {...items} />

            {!isMobile && (
              <Popover
                className="popover"
                withoutOverlay
                placement="bottom"
                // @ts-ignore
                trigger="hover"
                content={<UnitPopover deal={deal} />}
              >
                <InfoDark />
              </Popover>
            )}
          </FlexContainer>
        </FadeIn>
      </div>
      <div className="deal-header-second-col">
        <FadeIn delay={250}>
          <div className="deal-header-actions">
            {shouldHideDownloadPDFButton ||
              (pdfProps.hasPermissionToDownloadPDF &&
                pdfProps.isDealPDFDownloadable && (
                  <IconLink
                    onClick={pdfProps.downloadDealPDF}
                    Icon={
                      isCanceledDeal(status)
                        ? Icon.DownloadPdfDocGray
                        : Icon.DownloadPdfDoc
                    }
                    size="sm"
                    light
                    right
                    hoverableIcon={false}
                    disabled={pdfProps.isLoadingPDF}
                    className="deal-header-pdf-download"
                  />
                ))}
            <DropdownMenu placement="bottomRight" items={dropdownItems} />
          </div>
        </FadeIn>
      </div>
    </DealHeaderContent>
  );
};
