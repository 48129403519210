import { ApproveStatus, AppStatus } from "../types";

import { ButtonType } from "./interfaces";

export const getDefaultButtonByStatus = ({
  approved,
  canManuallySendToPms,
  status,
  showLumen,
  score,
  showConditionalProceed,
}: {
  approved: ApproveStatus;
  canManuallySendToPms: boolean;
  status: AppStatus;
  showLumen: boolean;
  score: string;
  showConditionalProceed: boolean;
}): ButtonType => {
  return canManuallySendToPms
    ? ButtonType.sendToPms
    : approved === ApproveStatus.CreatedLease
    ? null
    : status === AppStatus.Submitted
    ? !showLumen || !score || /EXCELLENT|GOOD|approve/i.test(score)
      ? showConditionalProceed
        ? ButtonType.conditionallyProceed
        : ButtonType.approve
      : /NEUTRAL|conditional/i.test(score)
      ? ButtonType.conditional
      : /NEGATIVE|decline/i.test(score)
      ? ButtonType.decline
      : null
    : ButtonType.cancel;
};

export const getButtonsByStatus = ({
  approved,
  showConditionalProceed,
  canManuallySendToPms,
  status,
  provider,
}: {
  approved: ApproveStatus;
  showConditionalProceed: boolean;
  canManuallySendToPms: boolean;
  status: AppStatus;
  provider: string;
}) => {
  const createdLeaseButtons = [ButtonType.cancel];
  const approvedButtons = [ButtonType.cancel];
  const showConditionalProceedButtons = [
    ButtonType.conditionallyProceed,
    ButtonType.conditional,
    ButtonType.decline,
    ButtonType.cancel,
  ];
  const submittedAndCanManuallySendToPmsButtons = [
    ButtonType.sendToPms,
    ButtonType.cancel,
  ];
  const submittedButtons = [
    ButtonType.approve,
    ButtonType.conditional,
    ButtonType.decline,
    ButtonType.cancel,
  ];
  const submittingAndEmptyButtons = [ButtonType.cancel];

  if (provider === "entrata") {
    [approvedButtons, showConditionalProceedButtons, submittedButtons].forEach(
      (buttons) => buttons.push(ButtonType.resendToPms)
    );
  }

  return approved === ApproveStatus.CreatedLease
    ? createdLeaseButtons
    : approved === ApproveStatus.Approved
    ? approvedButtons
    : showConditionalProceed
    ? showConditionalProceedButtons
    : status === AppStatus.Submitted && canManuallySendToPms
    ? submittedAndCanManuallySendToPmsButtons
    : status === AppStatus.Submitted
    ? submittedButtons
    : status === AppStatus.Submitting || status === AppStatus.Empty
    ? submittingAndEmptyButtons
    : [];
};
