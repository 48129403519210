import React, { useState } from "react";

import { DEAL_UI_STATUS_CODES } from "shared/config/constants";

import { getConfiguration, MODAL_STEP } from "./assignDealToUnitModal.util";
import AssignToUnitModal from "./AssignToUnitModal";
import { AssignDealToUnitModalProps } from "./interfaces";
import { ApplicantsCheckList, Subtitle, Note } from "./styled";

const AssignDealToUnitModal = ({
  dealId,
  deal,
  assignDealToUnit,
  isDealLoading,
}: AssignDealToUnitModalProps) => {
  const initialUnitId = deal?.unitListing?.unit;
  const isAssignBeforeLease =
    DEAL_UI_STATUS_CODES.approved === deal?.dealUiStatus && !initialUnitId;
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [currentModalStep, setCurrentModalStep] = useState(
    MODAL_STEP.INITIAL_STEP
  );
  const currentUnit = deal?.unit;
  const currentProperty = deal?.property;

  const defaultSubmit = ({
    unitListing,
    unitId,
    newLeaseStartDate,
    extraParams,
  }) => {
    return assignDealToUnit({
      id: dealId,
      newLeaseStartDate,
      unitId,
      unitListing,
      extraParams,
    });
  };

  const moveToNextStep = (values, { setSubmitting }) => {
    setSubmitting(false);
    setCurrentModalStep(MODAL_STEP.CONFIRMATION_STEP);
  };

  const { submit, submitButtonLabel, masterWarning, modalExtension } =
    getConfiguration(
      currentUnit,
      currentProperty,
      selectedUnit,
      currentModalStep,
      defaultSubmit,
      moveToNextStep
    );

  return (
    <AssignToUnitModal
      title={initialUnitId ? "Change assigned unit" : "Assign a unit listing"}
      subtitle={
        <Subtitle>
          Applicants:
          <ApplicantsCheckList inline items={deal?.applicantsSummary} />
        </Subtitle>
      }
      note={
        <Note>
          Find property and select unit you want to assign this application to.{" "}
        </Note>
      }
      unitId={initialUnitId}
      setSelectedUnit={setSelectedUnit}
      submit={submit}
      cancelLinkLabel={isAssignBeforeLease ? "I'll do it later" : undefined}
      closeOnSubmit={false}
      submitButtonLabel={submitButtonLabel}
      masterWarning={masterWarning}
      modalExtension={modalExtension}
      isDealLoading={isDealLoading}
      dealId={dealId}
      deal={deal}
    />
  );
};

export default AssignDealToUnitModal;
