import styled from "@emotion/styled";
import { Tag } from "antd-latest";

import { COLORS } from "shared/config/constants";

const { CheckableTag } = Tag;

export const StyledCheckableTag = styled(CheckableTag)`
  color: ${COLORS.lightViolet1};
  font-size: 15px;
  border: 1px solid ${COLORS.lightViolet1};
  padding: 2px 12px;
  margin-right: 0px;

  &.ant-latest-tag-checkable-checked {
    color: ${COLORS.white};
    background-color: ${COLORS.lightViolet1};
  }
`;
