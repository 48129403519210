import React from "react";

import { Row, Col } from "antd";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";

import { MaskedInput } from "shared/components/Form";
import Settings from "shared/components/Settings";
import { getFeeManagementSubtitle } from "shared/utils/misc.util";

import ApplicationSetupHeader from "../Header";
import { StyledSkeleton as Skeleton } from "../styled";

import { Container, FeeTitle, FeeTitleContainer } from "./styled";

const applicantApplicationFee = "applicantApplicationFee";
const guarantorApplicationFee = "guarantorApplicationFee";
const occupantOver18ApplicationFee = "occupantOver18ApplicationFee";

const ApplicationFees = ({ loading, disabled, dealSetup }) => {
  const { errors } = useFormikContext();

  const feesDisabled = dealSetup?.feesDisabled;
  const disableFeeManagement = dealSetup?.disableFeeManagement;

  const nodes =
    !feesDisabled && !disableFeeManagement
      ? [
          {
            key: 1,
            component: (
              <Skeleton loading={loading}>
                <Container>
                  <Row gutter={[48, 16]} type="flex">
                    <Col lg={8} md={12} sm={12} xs={24}>
                      <FeeTitleContainer>
                        <FeeTitle>Applicants</FeeTitle>
                      </FeeTitleContainer>
                      <MaskedInput.Formik.Money
                        id="applicant-fee"
                        name={applicantApplicationFee}
                        label="Fee Amount"
                        data-testid="applicant-fee-input"
                        error={errors[applicantApplicationFee]}
                        disabled={disabled}
                      />
                    </Col>
                    <Col lg={8} md={12} sm={12} xs={24}>
                      <FeeTitleContainer>
                        <FeeTitle>Guarantors</FeeTitle>
                      </FeeTitleContainer>
                      <MaskedInput.Formik.Money
                        id="guarantor-fee"
                        name={guarantorApplicationFee}
                        label="Fee Amount"
                        data-testid="guarantor-fee-input"
                        error={errors[guarantorApplicationFee]}
                        disabled={disabled}
                      />
                    </Col>
                    <Col lg={8} md={12} sm={12} xs={24}>
                      <FeeTitleContainer>
                        <FeeTitle>
                          Occupants <FeeTitle.Fade>(over 18)</FeeTitle.Fade>
                        </FeeTitle>
                      </FeeTitleContainer>
                      <MaskedInput.Formik.Money
                        id="occupant-fee"
                        name={occupantOver18ApplicationFee}
                        label="Fee Amount"
                        data-testid="occupant-fee-input"
                        error={errors[occupantOver18ApplicationFee]}
                        disabled={disabled}
                      />
                    </Col>
                  </Row>
                </Container>
              </Skeleton>
            ),
          },
        ]
      : [];

  return (
    <Settings
      header={
        <Skeleton loading={loading}>
          <ApplicationSetupHeader
            title="Application Fees"
            subTitle={getFeeManagementSubtitle(
              dealSetup,
              "Please manage fees that applicants will need to pay for one application"
            )}
          />
        </Skeleton>
      }
      nodes={nodes}
    />
  );
};

ApplicationFees.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  dealSetup: PropTypes.object,
};

ApplicationFees.defaultProps = {
  disabled: false,
  loading: false,
  dealSetup: {},
};

export default ApplicationFees;
