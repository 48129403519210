import React from "react";

import PropTypes from "prop-types";

import { FrequencyTitleContainer } from "./styled";

const FrequencyTitle = ({ title }) => (
  <FrequencyTitleContainer>{title}</FrequencyTitleContainer>
);

FrequencyTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default FrequencyTitle;
