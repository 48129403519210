import React from "react";

import { Col, Row } from "antd";

import LandLordInfoCard from "./LandLordInfoCard";
import PropertyDetailsCard from "./PropertyDetailsCard";
import SurveyResponseCard from "./SurveyResponseCard";
import {
  SurveyDetails,
  PropertyDetails,
  LandLordDetails,
} from "./verification.interfaces";
import VoRScore from "./VoRScore";

export enum SurveyStatus {
  WAITING = "waiting",
  NOTIFIED = "notified",
  SUBMITTED = "submitted",
  VERIFIED = "verified",
}
export interface VerificationOfResidencyDetailsProps {
  verification: {
    status: string;
    verificationDate: string;
    details: {
      survey: SurveyDetails;
      property: PropertyDetails;
      landlord: LandLordDetails;
    };
  };
  onRequestSurveyClick: (id: number) => void;
  isRequestingSurvey: boolean;
}

const VerificationOfResidencyDetails = ({
  verification,
  onRequestSurveyClick,
  isRequestingSurvey,
}: VerificationOfResidencyDetailsProps) => {
  const score = verification?.details?.survey?.score;
  const outOf = verification?.details?.survey?.outOf;
  const scorePercentage = Number(((score / outOf) * 100).toFixed(3));
  const showVORScore =
    score > 0 &&
    (verification.status === SurveyStatus.SUBMITTED ||
      verification.status === SurveyStatus.VERIFIED);
  return (
    <Row gutter={16} type="flex">
      <Col xs={24} md={8} lg={8}>
        {showVORScore && (
          <Row>
            <Col>
              <VoRScore surveyScore={score} percentage={scorePercentage} />
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <LandLordInfoCard
              fullName={verification?.details?.landlord?.fullName}
              phoneNumber={verification?.details?.landlord?.phoneNumber}
              email={verification?.details?.landlord?.email}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <PropertyDetailsCard
              tennancyPeriodStart={
                verification?.details?.property?.tennancyPeriodStart
              }
              tennancyPeriodEnd={
                verification?.details?.property?.tennancyPeriodEnd
              }
              address={verification?.details?.property?.address}
            />
          </Col>
        </Row>
      </Col>
      <Col xs={24} md={16}>
        <Row>
          <SurveyResponseCard
            verificationDate={verification.verificationDate}
            handleRequestSurvey={onRequestSurveyClick}
            isLoading={isRequestingSurvey}
            showQuestions={showVORScore}
            survey={verification.details.survey}
          />
        </Row>
      </Col>
    </Row>
  );
};

export default VerificationOfResidencyDetails;
