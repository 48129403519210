import styled from "@emotion/styled";

import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

const FileWrapper = styled("div")`
  > div {
    max-height: 520px;
    border: 1px solid ${COLORS.borders};
    border-radius: 2px;
  }
  .document-container {
    width: 100%;
  }
  ${MEDIA_QUERIES.w.ltSm} {
    .pg-viewer-wrapper {
      border-left: 0;
      border-right: 0;
      border-radius: 0;
    }
  }
`;

export { FileWrapper };
