import React from "react";

import dayjs from "dayjs";

import Icon from "shared/components/Icon";
import { ActionLink } from "shared/components/Links";
import { DATE_TIME_FORMAT } from "shared/config/constants";
import { ModalManager } from "shared/utils/modalmanager";

import { YardiText } from "../styled";

import {
  YardiLinkPropertyResolverModal,
  YardiLinkUnitResolverModal,
  YardiCreateUnitListingResolverModal,
  YardiCreateLinkLeadSourceResolverModal,
  YardiCreateLinkMarketingSourceResolverModal,
  YardiArchivedDealsResolverModal,
  YardiLinkAgentsResolverModal,
  YardiLinkMarketingSourcesResolverModal,
  YardiLinkChargeTypesResolverModal,
  YardiLinkDocumentTypesResolverModal,
} from "../yardiAlertsResolvers";

import {
  YARDI_ALERT_REASON,
  YARDI_ALERT_LEVEL,
  YARDI_ALERT_CLASSES,
  YARDI_ALERT_CLASSES_LABELS,
} from "./YardiAlerts.constants";

const getYardiAlertRow = ({
  alert,
  yardiProperty,
  isIntegrationsAdmin,
  provider,
}) => {
  const alertReasonClassActionMapping = {
    [[YARDI_ALERT_CLASSES.Property, YARDI_ALERT_REASON.Created]]: (
      <ActionLink
        onClick={() => {
          ModalManager.openModal(YardiLinkPropertyResolverModal, {
            provider,
            alert,
            yardiProperty,
          });
        }}
        disabled={!isIntegrationsAdmin}
      >
        Link Property
      </ActionLink>
    ),
    [[YARDI_ALERT_CLASSES.Unit, YARDI_ALERT_REASON.Created]]: (
      <ActionLink
        onClick={() => {
          ModalManager.openModal(YardiLinkUnitResolverModal, {
            provider,
            alert,
            yardiProperty,
          });
        }}
        disabled={!isIntegrationsAdmin}
      >
        Link Unit
      </ActionLink>
    ),
    [[YARDI_ALERT_CLASSES.UnitAvailability, YARDI_ALERT_REASON.Created]]: (
      <ActionLink
        onClick={() => {
          ModalManager.openModal(YardiCreateUnitListingResolverModal, {
            provider,
            alert,
            yardiProperty,
          });
        }}
        disabled={!isIntegrationsAdmin}
      >
        Create Listing
      </ActionLink>
    ),
    [[YARDI_ALERT_CLASSES.UnitAvailability, YARDI_ALERT_REASON.Deleted]]: (
      <ActionLink
        onClick={() => {
          ModalManager.openModal(YardiArchivedDealsResolverModal, {
            provider,
            alert,
            yardiProperty,
          });
        }}
      >
        View Applications
      </ActionLink>
    ),
    [[YARDI_ALERT_CLASSES.LeadSource, YARDI_ALERT_REASON.Created]]: (
      <ActionLink
        onClick={() => {
          ModalManager.openModal(YardiCreateLinkLeadSourceResolverModal, {
            provider,
            alert,
          });
        }}
        disabled={!isIntegrationsAdmin}
      >
        Link Source
      </ActionLink>
    ),
    [[YARDI_ALERT_CLASSES.MarketingSource, YARDI_ALERT_REASON.Created]]: (
      <ActionLink
        onClick={() => {
          ModalManager.openModal(YardiCreateLinkMarketingSourceResolverModal, {
            provider,
            alert,
            yardiProperty,
          });
        }}
        disabled={!isIntegrationsAdmin}
      >
        Link Source
      </ActionLink>
    ),
    [[YARDI_ALERT_CLASSES.Agent, YARDI_ALERT_REASON.Deleted]]: (
      <ActionLink
        onClick={() => {
          ModalManager.openModal(YardiLinkAgentsResolverModal, {
            provider,
            alert,
            yardiProperty,
          });
        }}
        disabled={!isIntegrationsAdmin}
      >
        Link Agent
      </ActionLink>
    ),
    [[YARDI_ALERT_CLASSES.MarketingSource, YARDI_ALERT_REASON.Deleted]]: (
      <ActionLink
        onClick={() => {
          ModalManager.openModal(YardiLinkMarketingSourcesResolverModal, {
            provider,
            alert,
            yardiProperty,
          });
        }}
        disabled={!isIntegrationsAdmin}
      >
        Link Source
      </ActionLink>
    ),
    [[YARDI_ALERT_CLASSES.ChargeType, YARDI_ALERT_REASON.Deleted]]: (
      <ActionLink
        onClick={() => {
          ModalManager.openModal(YardiLinkChargeTypesResolverModal, {
            alert,
            yardiProperty,
            provider,
          });
        }}
        disabled={!isIntegrationsAdmin}
      >
        Link Type
      </ActionLink>
    ),
    [[YARDI_ALERT_CLASSES.DocumentType, YARDI_ALERT_REASON.Deleted]]: (
      <ActionLink
        onClick={() => {
          ModalManager.openModal(YardiLinkDocumentTypesResolverModal, {
            alert,
            yardiProperty,
            provider,
          });
        }}
        disabled={!isIntegrationsAdmin}
      >
        Link Type
      </ActionLink>
    ),
  };

  return {
    key: alert.id,
    values: [
      <YardiText noPadding noWrap fontWeight={400}>
        {YARDI_ALERT_CLASSES_LABELS[alert.alertClass]}
        {alert.alertLevel === YARDI_ALERT_LEVEL.Major && (
          <Icon.ErrorIcon className="alert-icon" />
        )}
        {alert.alertLevel === YARDI_ALERT_LEVEL.Minor && (
          <Icon.WarningIcon className="alert-icon" />
        )}
      </YardiText>,
      <YardiText noPadding fontWeight={400}>
        {alert.message || "-"}
      </YardiText>,
      <YardiText noPadding noWrap fontWeight={400}>
        {yardiProperty?.name || "All Properties"}
      </YardiText>,
      <YardiText noPadding noWrap fontWeight={400}>
        {dayjs(alert.createdAt).format(DATE_TIME_FORMAT)}
      </YardiText>,
    ],
    primaryAction:
      !alert.resolved &&
      alertReasonClassActionMapping[[alert.alertClass, alert.alertReason]],
    disabled: alert.resolved,
  };
};

export default getYardiAlertRow;
