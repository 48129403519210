import styled from "@emotion/styled";

import FlexContainer from "shared/components/FlexContainer";
import BaseTile from "shared/components/Tile";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

const Tile = styled(BaseTile)`
  background-color: ${COLORS.dashboard.headerBackground};

  .tile-header {
    padding-bottom: 0;
    .title {
      color: ${COLORS.white}!important;
      margin-right: 32px;
    }

    .address {
      display: flex;
      align-items: center;
      color: ${COLORS.violetTheme.lightBlueViolet};

      svg {
        margin-right: 7px;
      }
    }

    .dashboard-select {
      width: 175px;
      & > div {
        color: ${COLORS.midGray};
        border-color: ${COLORS.midGray};
        background: ${COLORS.dashboard.headerBackground};
      }

      svg {
        color: ${COLORS.midGray};
      }
    }

    ${MEDIA_QUERIES.w.ltMd} {
      & > div {
        display: block;
      }

      .title-container {
        display: block;
        margin-bottom: 16px;
      }

      .title {
        margin-bottom: 16px;
      }
    }
  }

  .divider {
    margin: 16px 0 0 0;
    background-color: ${COLORS.tile.alt.innerBackground};
  }

  .tile-body {
    padding-top: 8px;

    ${MEDIA_QUERIES.w.ltMd} {
      padding-top: 24px;
    }
  }

  .dropdown-title {
    color: ${COLORS.lightGray2};
  }

  .dropdown-chevron {
    cursor: pointer;
  }
`;

const LastUpdatedContainer = styled(FlexContainer)`
  margin-bottom: 8px;

  span {
    color: ${COLORS.violetTheme.lightBlueViolet};
  }

  ${MEDIA_QUERIES.w.ltMd} {
    margin-bottom: 0;
    margin-top: 16px;
  }
`;

const MetricItemContainer = styled(FlexContainer)`
  margin: 0 10px;
  .label {
    width: 92px;
    color: ${COLORS.midGray};
    line-height: 150%;
  }

  .metric {
    font-size: 40px;
    margin-left: 16px;
    color: ${COLORS.violetTheme.lightViolet};
  }

  ${MEDIA_QUERIES.w.ltMd} {
    margin-right: 0px !important;
    width: 100%;
    margin-top: 16px;

    .label {
      width: 100%;
    }
  }
`;

interface MetricItemsContainerProps {
  addMarginRight: boolean;
}

const MetricItemsContainer = styled(FlexContainer)<MetricItemsContainerProps>`
  ${({ addMarginRight }) =>
    addMarginRight &&
    `
    .metric-item:not(:last-child) {
      margin-right: 76px;
    }
  `}
`;

const TileInner = BaseTile.Inner;
const TileDivider = BaseTile.Divider;

export {
  Tile,
  TileInner,
  TileDivider,
  MetricItemContainer,
  MetricItemsContainer,
  LastUpdatedContainer,
};
