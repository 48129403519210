import { useState } from "react";

import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";
import { printDollarsFromCents } from "shared/utils/dollar-print";

import AssignAgentPopover from "manager/components/AssignAgentPopover";
import { DEFAULT_YARDI_INTEGRATION_DATA_TEXT } from "manager/pages/Marketplace/integrations/yardi/shared";
import ConditionalText from "shared/components/ConditionalText";
import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import { UnderlineLink } from "shared/components/Links";
import { Popover } from "shared/components/Popover";
import { BREAKPOINT_RESOLUTIONS } from "shared/config/constants";
import { formatDate } from "shared/utils/ui";

import DealsPropTypes from "../../Deals.propTypes";
import {
  FlexWrapper,
  PrimaryText,
  SecondaryText,
  StyledCheckList,
} from "../styled";

import {
  AgentName,
  AssignAgentSection,
  CellWrapper,
  DataRow,
  DealDetailsContainer,
  UnitAvailable,
} from "./styled";

const DealDetails = ({
  deal,
  columnWidth,
  onClick,
  onMouseLeave,
  applicationStatus,
  refetchDeals,
}) => {
  const [visiblePopover, setVisiblePopover] = useState(false);
  const isLaptop = useMediaQuery({ maxWidth: BREAKPOINT_RESOLUTIONS.lg });
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT_RESOLUTIONS.md });
  const isRentPaid = deal?.lease?.rentPaid;
  const isSecurityDepositPaid = deal?.lease?.securityDepositPaid;
  const applicants = deal?.applicantsSummary || [];

  const AssignAgentLink = (
    <AssignAgentSection className="w-100">
      {deal?.employee ? (
        <>
          <Icon.PersonSmallIcon />
          <AgentName className="text-ellipsis">
            {`${deal.employee.user.firstName} ${deal.employee.user.lastName}`}
          </AgentName>
          <Icon.ChevronBoldDownIcon />
        </>
      ) : (
        <>
          <Icon.PersonSmallIcon />
          <UnderlineLink to="#">Assign Agent</UnderlineLink>
        </>
      )}
    </AssignAgentSection>
  );

  return (
    <DealDetailsContainer
      onMouseLeave={(e) => {
        setVisiblePopover(false);
        onMouseLeave(e);
      }}
      onClick={onClick}
    >
      {applicationStatus && (
        <CellWrapper>
          <DataRow isLaptop={isLaptop}>
            <SecondaryText>APPLICATION STATUS</SecondaryText>
          </DataRow>
          <DataRow isLaptop={isLaptop}>{applicationStatus}</DataRow>
        </CellWrapper>
      )}
      <CellWrapper width={columnWidth[0]} onClick={(e) => e.stopPropagation()}>
        <DataRow isLaptop={isLaptop}>
          <SecondaryText>AGENT</SecondaryText>
        </DataRow>
        <DataRow>
          <Popover
            // @ts-ignore
            trigger="hover"
            visible={visiblePopover}
            onVisibleChange={(visible) => setVisiblePopover(visible)}
            content={
              <AssignAgentPopover
                dealId={deal?.id}
                currentAgentId={deal?.employee?.id}
                afterAgentChanged={refetchDeals}
              />
            }
            placement="bottom"
          >
            {AssignAgentLink}
          </Popover>
        </DataRow>
      </CellWrapper>
      {deal?.unit?.yardiConnected && (
        <CellWrapper width={isLaptop ? columnWidth[1] : columnWidth[0]}>
          <DataRow isLaptop={isLaptop}>
            <SecondaryText>PAYMENTS</SecondaryText>
          </DataRow>
          <DataRow>
            <FlexWrapper>
              <PrimaryText>1st month rent:</PrimaryText>
              {isRentPaid && <Icon.CheckIcon />}
            </FlexWrapper>
          </DataRow>
          <DataRow>
            <PrimaryText>Security Deposit:</PrimaryText>
            {isSecurityDepositPaid && <Icon.CheckIcon />}
          </DataRow>
        </CellWrapper>
      )}
      {deal?.unit?.yardiConnected && (
        <CellWrapper width={columnWidth[1]}>
          <DataRow isLaptop={isLaptop}>
            <SecondaryText>YARDI DATA</SecondaryText>
          </DataRow>
          <DataRow>
            <PrimaryText>
              <FlexContainer alignItems="center">
                P-Code:&nbsp;
                <ConditionalText
                  text={deal?.yardiPcode}
                  placeholderPopoverText={DEFAULT_YARDI_INTEGRATION_DATA_TEXT}
                />
              </FlexContainer>
            </PrimaryText>
          </DataRow>
          <DataRow>
            <PrimaryText>
              <FlexContainer alignItems="center">
                T-Code:&nbsp;
                <ConditionalText
                  text={deal?.yardiTcode}
                  placeholderPopoverText={DEFAULT_YARDI_INTEGRATION_DATA_TEXT}
                />
              </FlexContainer>
            </PrimaryText>
          </DataRow>
        </CellWrapper>
      )}
      <CellWrapper width={columnWidth[1]}>
        <DataRow isLaptop={isLaptop}>
          <SecondaryText>AVAILABLE ON</SecondaryText>
        </DataRow>
        <DataRow>
          <UnitAvailable>
            {formatDate(deal?.unitListing?.unitAvailableOn)}
          </UnitAvailable>
        </DataRow>
      </CellWrapper>
      {isLaptop && (
        <CellWrapper width={isMobile ? columnWidth[0] : columnWidth[1]}>
          <DataRow isLaptop={isLaptop}>
            <SecondaryText>GROSS RENT</SecondaryText>
          </DataRow>
          <DataRow>
            <UnitAvailable>{printDollarsFromCents(deal?.rent)}</UnitAvailable>
          </DataRow>
        </CellWrapper>
      )}
      {isLaptop && (
        <CellWrapper width={columnWidth[0]}>
          <DataRow isLaptop={isLaptop}>
            <SecondaryText>APPLICANTS</SecondaryText>
          </DataRow>
          <DataRow>
            <StyledCheckList items={applicants} />
          </DataRow>
        </CellWrapper>
      )}
    </DealDetailsContainer>
  );
};

DealDetails.propTypes = {
  deal: DealsPropTypes.deal,
  onClick: PropTypes.func,
  onMouseLeave: PropTypes.func,
  columnWidth: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ).isRequired,
  applicationStatus: PropTypes.node,
  refetchDeals: PropTypes.func,
};

DealDetails.defaultProps = {
  deal: undefined,
  onClick: () => {},
  onMouseLeave: () => {},
  applicationStatus: undefined,
};

export default DealDetails;
