import styled from "@emotion/styled";

import Card from "shared/components/Card";
import { MEDIA_QUERIES } from "shared/config/constants";

const Container = styled.section`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InfoPageCard = styled(Card)`
  z-index: 1;
  text-align: center;

  .action-button {
    margin: 0 auto;
  }
`;

const IconContainer = styled.div`
  ${MEDIA_QUERIES.w.ltMd} {
    transform: scale(0.75);
  }
`;

export { Container, InfoPageCard, IconContainer };
