import styled from "@emotion/styled";
import { Col } from "antd-latest";

import { Button } from "shared/components/Button";
import { UnderlineLink } from "shared/components/Links";
import { RegularText } from "shared/components/Typography";
import { colors, MEDIA_QUERIES } from "shared/config/constants";

export const InviteApplicantContainer = styled.div`
  width: 400px;

  ${MEDIA_QUERIES.w.ltSm} {
    width: 300px;
  }
`;

export const LinkButton = styled(Button)`
  border-radius: 16px;
  background: ${colors.neutral[200]};
  color: ${colors.secondary[600]};
  cursor: pointer;
  padding: 8px 16px;
  &:hover {
    background: #eee;
  }
  &:active {
    background: #eee;
  }
  &:focus {
    background: #eee;
    color: ${colors.secondary[600]};
  }
`;

export const TitleLink = styled(UnderlineLink)`
  &:hover {
    color: black !important;
    border-bottom-color: black !important;
  }
`;

export const UnblockLink = styled(RegularText)`
  color: ${colors.secondary[600]};
  cursor: pointer;
`;

export const CardCol = styled(Col)`
  width: 250px;
`;
