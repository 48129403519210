import React from "react";

import { Col, Row } from "antd";

import { PERSONA_FILE_TYPE_LABELS } from "shared/config/constants";

import Icon from "../Icon";
import Tile from "../Tile";
import { RegularText } from "../Typography";

import {
  NoPhotosAvailableWrapper,
  VerificationFileLabel,
  VerificationFilePhoto,
} from "./styled";

export interface IVerificationFilesCard {
  verificationFiles: {
    url: string;
    key: string;
  }[];
  inset?: boolean;
}

/**
 * Card component, displaying verification files.
 */
const VerificationFilesCard = ({
  verificationFiles,
  inset = false,
}: IVerificationFilesCard) => {
  return (
    <Tile.Inner inset={inset}>
      {verificationFiles.length === 0 && (
        <NoPhotosAvailableWrapper alignItems="center" justifyContent="center">
          <Icon.NoPhotosAvailable />
          <RegularText className="message">No photos available</RegularText>
        </NoPhotosAvailableWrapper>
      )}
      {verificationFiles.length > 0 && (
        <Row type="flex" gutter={[16, 16]}>
          {verificationFiles.map((file, index) => {
            const fileLabel =
              PERSONA_FILE_TYPE_LABELS[file.key] || `Photo ${index}`;
            return (
              <Col key={file.key}>
                <a href={file.url} target="_blank" rel="noreferrer">
                  <VerificationFilePhoto>
                    <img className="photo" alt={fileLabel} src={file.url} />
                    <div className="photo-overlay" />
                  </VerificationFilePhoto>
                </a>
                <VerificationFileLabel>{fileLabel}</VerificationFileLabel>
              </Col>
            );
          })}
        </Row>
      )}
    </Tile.Inner>
  );
};

export default VerificationFilesCard;
