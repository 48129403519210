import React from "react";

import { Radio } from "antd";
import PropTypes from "prop-types";

import Icon from "shared/components/Icon";

import {
  Option,
  Description,
  Title,
  PopoverContainer,
  AgentsContainer,
  Faint,
  Loader,
  NoAvailableAgents,
} from "./styled";

const AssignAgent = ({
  isAgentsLoading,
  selectedAgent,
  changeAgent,
  agents,
  isAssigningAgent,
}) => {
  return (
    <PopoverContainer data-testid="assign-agent">
      <Title data-testid="assign-agent-title">Assign Agent</Title>
      <AgentsContainer>
        {(isAssigningAgent || isAgentsLoading) && (
          <Loader>
            <Icon.LoadingSpinnerGreenIcon data-testid="agents-loader-icon" />
          </Loader>
        )}
        {agents.length === 0 && !isAgentsLoading && (
          <NoAvailableAgents>No available agents</NoAvailableAgents>
        )}
        <Radio.Group
          onChange={(event) => changeAgent(event.target.value)}
          value={selectedAgent}
          disabled={isAssigningAgent}
        >
          {agents.map((agent) => (
            <Option
              value={agent.id}
              key={agent.id}
              data-testid={`agent-${agent.id}`}
            >
              <span data-testid={`agent-name-${agent.id}`}>
                {agent.name} {agent.isCurrent && <Faint>(me)</Faint>}
              </span>
              <Description data-testid={`agent-email-${agent.id}`}>
                {agent.email}
              </Description>
            </Option>
          ))}
        </Radio.Group>
      </AgentsContainer>
    </PopoverContainer>
  );
};

AssignAgent.propTypes = {
  selectedAgent: PropTypes.number,
  changeAgent: PropTypes.func.isRequired,
  agents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      isCurrent: PropTypes.bool.isRequired,
    })
  ),
  isAssigningAgent: PropTypes.bool.isRequired,
  isAgentsLoading: PropTypes.bool.isRequired,
};

AssignAgent.defaultProps = {
  selectedAgent: undefined,
  agents: [],
};

export default AssignAgent;
