import _ from 'lodash'

function findRangeIndex (ranges, score) {
  return _.findIndex(ranges, range => _.inRange(score, range[0], range[1] + 1)) || 0
}

function calculateRangeWeight (ranges, score) {
  const rangeIndex = findRangeIndex(ranges, score)
  return (score / 100) * ((rangeIndex + 1) / _.size(ranges))
}

function calculateAngle (ranges, score) {
  return _.round(120 * calculateRangeWeight(ranges, score), 2)
}

export function getGradientColorForLumenRun (lumenRun) {
  const ranges = lumenRun?.settings?.scoreLabels ? _.orderBy(_.values(lumenRun.settings.scoreLabels), range => _.max(_.take(range, 2))) : [[0, 99], [100, 100]]
  const deg = calculateAngle(ranges, lumenRun?.score)

  return deg ? `hsl(${deg} 50% 50%)` : 'white'
}

export function getScoreLabelStyle (scoreLabelColor, defaultColor = 'black') {
  if (scoreLabelColor && !_.startsWith(scoreLabelColor, '#')) {
    return {
      className: scoreLabelColor
    }
  }

  return {
    style: {
      color: scoreLabelColor || defaultColor
    }
  }
}

export function getColorForLumenRun (lumenRun) {
  return getScoreLabelStyle(lumenRun?.scoreLabelColor, getGradientColorForLumenRun(lumenRun))
}

export function findLumenResult (lumenRun, applicant = undefined) {
  return _.find(lumenRun?.results, applicant ? result => _.includes(result?.applicant, applicant) : 'application') || {}
}

export function queryEvaluationHistory (history, query, key = undefined) {
  const entry = _.find(history, entry => _.startsWith(entry?.formula, query))
  return key ? _.get(entry, key) : entry
}

export function queryByAnnotation (result, annotations, key: any = 'result') {
  return _.chain(annotations).castArray().map(
    annotation => queryEvaluationHistory(result?.evaluation?.history, `#${annotation}:`, key)
  ).reject(_.isNil).first().value()
}
