import React from "react";

import startCase from "lodash/startCase";

import { useHistory, useParams } from "react-router-dom";

import ROUTES from "manager/config/routes";
import {
  IIntegrationStatus,
  IIntegrationStatuses,
} from "manager/interfaces/api";
import { UnderlineLink } from "shared/components/Links/UnderlineLink/styled";
import { IntegrationProvider } from "shared/config/constants";

export const getIntegrationMessages = (
  integrationType: IntegrationProvider
) => ({
  subtitle: `Whoops! Looks like ${startCase(
    integrationType
  )} hasn't been activated for your company.`,
  description: (
    <>
      Are you ready to integrate your workflow?
      <br key={1} />
      Reach out to us at&nbsp;
      <UnderlineLink key={2} href="mailto:hello@sayvero.com">
        hello@sayvero.com
      </UnderlineLink>{" "}
      today!
    </>
  ),
  warning: `You do not have the proper permissions to view the ${startCase(
    integrationType
  )} integration configuration. Please contact your manager if you need access.`,
});

export const useGetIntegrationProvider = () => {
  const history = useHistory();
  const { provider } = useParams<{ provider: IntegrationProvider }>();

  if (!provider || !Object.values(IntegrationProvider).includes(provider)) {
    history.push(ROUTES.marketplace);
  }
  return { provider };
};

export const getSelectedIntegrationProvider = (
  integrationStatuses: IIntegrationStatuses | undefined,
  provider: IntegrationProvider
): IIntegrationStatus | undefined =>
  Object.values(integrationStatuses || {}).find(
    (integrationStatus: IIntegrationStatus) =>
      integrationStatus.provider === provider
  );
