import { Unit } from "manager/interfaces/api/unit";

import CloseListingModalContainer from "./CloseListingModalContainer";

export default CloseListingModalContainer;
export * from "./closeListing.config";

export interface ICloseListingProps {
  unit: Unit;
  onSubmit: Function;
}
