import UnitIntegrationMark from "manager/components/IntegrationMark/UnitIntegrationMark";
import { UNIT_STATUSES } from "manager/config/constants";
import ROUTES from "manager/config/routes";
import { YARDI_RELATED_UNIT_MESSAGES } from "manager/pages/Marketplace/integrations/yardi/shared";
import { GreyLabel } from "manager/pages/Properties/PropertiesTable/styled";
import { getIntegrationType } from "manager/utils/integration.util";
import {
  propertySettingsPropTypes,
  unitPropType,
} from "manager/utils/propTypes";
import {
  checkIsAbleToInviteApplicant,
  hasListing,
} from "manager/utils/unit.util";
import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";
import FlexContainer from "shared/components/FlexContainer";
import { Checkbox, Search } from "shared/components/Form";
import Icon from "shared/components/Icon";
import { ActionLink } from "shared/components/Links";
import { Popover, PopoverContent } from "shared/components/Popover";
import Table from "shared/components/Table";
import Tooltip from "shared/components/Tooltip";
import {
  BREAKPOINT_RESOLUTIONS,
  INTEGRATION_TYPES,
} from "shared/config/constants";
import useActiveCompany from "shared/hooks/useActiveCompany";
import { conditionalItem } from "shared/utils/array";
import { printDollarsFromCents } from "shared/utils/dollar-print";
import { transformRoute } from "shared/utils/routing";
import { formatDate } from "shared/utils/ui";
import {
  FilterAction,
  PropertyIntegrationMarkContainer,
  SearchWrapper,
  Title,
  TitleWrapper,
} from "./styled";

const ORDER_BY_NAMES = Object.freeze({
  name: "name",
  dateAvailable: "unit_available_on",
  rent: "rent",
});

const ORDER_BY = Object.freeze({
  name: {
    ascend: ORDER_BY_NAMES.name,
    descend: `-${ORDER_BY_NAMES.name}`,
  },
  dateAvailable: {
    ascend: ORDER_BY_NAMES.dateAvailable,
    descend: `-${ORDER_BY_NAMES.dateAvailable}`,
  },
  rent: {
    ascend: ORDER_BY_NAMES.rent,
    descend: `-${ORDER_BY_NAMES.rent}`,
  },
});

const getDisabledCreateMessage = (unit) => {
  const integrationType = getIntegrationType(unit);
  return INTEGRATION_TYPES.YARDI === integrationType
    ? YARDI_RELATED_UNIT_MESSAGES.disabledUnitListingCreate
    : undefined;
};

const isDisplayOnPortalDisabled = (unit) => {
  return unit?.status === UNIT_STATUSES.OH.key || !unit?.unitListingCurrent;
};

const getDisplayOnPortalMessage = (unit) => {
  if (unit?.status === UNIT_STATUSES.OH.key) {
    return "Unit cannot be displayed on the portal because the unit is currently On Hold.";
  }
  if (!unit?.unitListingCurrent) {
    return "Please create a unit listing for this unit to be shown on the units portal.";
  }

  return null;
};

const UnitsTable = ({
  propertyId,
  propertySettings,
  history,
  units,
  unitsCount,
  filters,
  isUnitsLoading,
  onSearchStringChange,
  onPaginationChange,
  onSortOrderChange,
  inviteApplicants,
  createUnit,
  editUnit,
  deleteUnit,
  createUnitListing,
  isMagicPortalEnabled,
  onToggleUnitDisplayOnPortal,
}) => {
  const { activeCompany } = useActiveCompany();
  const { showOnlyUnitsNotAttachedToAnotherActiveDeal } = activeCompany || {};
  const isXxlScreen = useMediaQuery({ minWidth: BREAKPOINT_RESOLUTIONS.xxl });
  const isXxxlScreen = useMediaQuery({ minWidth: BREAKPOINT_RESOLUTIONS.xxxl });

  const onUnitsTableChange = (newPagination, _tableFilters, sorter) => {
    // Update pagination if there is a change
    if (newPagination.current !== filters.page) {
      onPaginationChange(newPagination.current);
    }

    // Update sort order if there is a change
    const newOrdering = sorter.order
      ? `${ORDER_BY[sorter.columnKey][sorter.order]}`
      : null;

    // NOTE: goran - Just in case the values are different when they are empty (null and undefined)
    // we align them to perform proper comparison. Otherwise, it will change the
    // sort unnecessarily and will override the last change of the filters state.
    if ((newOrdering || null) !== (filters.ordering || null)) {
      onSortOrderChange(newOrdering);
    }
  };

  const shouldDisableInviteApplicants = (numOfActiveDeals) =>
    showOnlyUnitsNotAttachedToAnotherActiveDeal && numOfActiveDeals > 0;

  const inviteApplicantsLink = (unit, index) => {
    const isAbleToInviteApplicants = checkIsAbleToInviteApplicant(unit);
    const disabledMessage = shouldDisableInviteApplicants(
      unit?.unitListingCurrent?.numberOfActiveDeals
    )
      ? "This unit is already attached to an application."
      : null;
    return isAbleToInviteApplicants ? (
      <Tooltip title={disabledMessage} placement="top">
        <div>
          <ActionLink
            disabled={shouldDisableInviteApplicants(
              unit?.unitListingCurrent?.numberOfActiveDeals
            )}
            data-testid={`unit-${index}-inviteApplicants`}
            onClick={() => {
              inviteApplicants({
                unitId: unit.id,
                unitName: unit.name,
                unitProvider: unit.provider,
                unitPmsConnected: unit.pmsConnected,
                propertyName: unit.propertyName,
              });
            }}
          >
            Invite Applicants
          </ActionLink>
        </div>
      </Tooltip>
    ) : null;
  };

  const createUnitListingLink = (unit, index) => {
    const disabledMessage = getDisabledCreateMessage(unit);
    return (
      <Popover
        className="popover"
        withoutOverlay
        disabled={!disabledMessage}
        content={<PopoverContent>{disabledMessage}</PopoverContent>}
      >
        <div>
          <ActionLink
            disabled={!!disabledMessage}
            onClick={() => {
              createUnitListing({
                name: unit.name,
                id: unit.id,
                propertyName: unit.propertyName,
              });
            }}
            data-testid={`unit-${index}-createListing`}
          >
            Create Listing
          </ActionLink>
        </div>
      </Popover>
    );
  };

  const getNameWithTooltip = (unit, dataTestId) => {
    const { name } = unit;
    // eslint-disable-next-line no-nested-ternary
    const tooltipLength = isXxxlScreen ? 50 : isXxlScreen ? 30 : 20;
    const isLongName = name.length > tooltipLength;
    const integrationTypeEntrata =
      getIntegrationType(propertySettings || unit) ===
      INTEGRATION_TYPES.ENTRATA;
    const Text = (
      <GreyLabel data-testid={dataTestId}>
        {`${name.substring(0, tooltipLength)}${isLongName ? "..." : ""}`}
        <PropertyIntegrationMarkContainer
          opacity={integrationTypeEntrata ? 0.75 : 1}
        >
          <UnitIntegrationMark property={propertySettings} unit={unit} />
        </PropertyIntegrationMarkContainer>
      </GreyLabel>
    );

    return !isLongName ? (
      Text
    ) : (
      <Tooltip theme="light" placement="topLeft" title={name}>
        {Text}
      </Tooltip>
    );
  };

  const unitsRows = () =>
    units.map((unit, index) => {
      const displayOnPortalMessage = getDisplayOnPortalMessage(unit);
      return {
        key: unit.id,
        values: [
          getNameWithTooltip(unit, `unit-${index}-name`),
          <span data-testid={`unit-${index}-status`}>
            {UNIT_STATUSES[unit.status]?.label}
          </span>,
          <span data-testid={`unit-${index}-unitAvailableOn`}>
            {hasListing(unit) && unit.unitListingCurrent.unitAvailableOn
              ? formatDate(unit.unitListingCurrent.unitAvailableOn)
              : "N/A"}
          </span>,
          <span data-testid={`unit-${index}-rent`}>
            {hasListing(unit) && unit.unitListingCurrent.rent
              ? printDollarsFromCents(unit.unitListingCurrent.rent)
              : "N/A"}
          </span>,
          ...(isMagicPortalEnabled
            ? [
                <FlexContainer alignItems="center">
                  <Checkbox
                    id={`displayOnPortal-${unit.id}`}
                    onChange={() => onToggleUnitDisplayOnPortal(unit.id)}
                    value={
                      isDisplayOnPortalDisabled(unit)
                        ? false
                        : unit.displayOnPortal
                    }
                    disabled={isDisplayOnPortalDisabled(unit)}
                  />
                  {displayOnPortalMessage && (
                    <Tooltip
                      trigger="hover"
                      title={
                        <div className="tooltip-inner-gray">
                          {displayOnPortalMessage}
                        </div>
                      }
                      placement="topLeft"
                    >
                      <Icon.OrangeExclamationSmall />
                    </Tooltip>
                  )}
                </FlexContainer>,
              ]
            : []),
          <span data-testid={`unit-${index}-listing-note`}>
            <Tooltip title={unit.unitListingCurrent?.note} placement="topLeft">
              <Icon.NoteIcon
                disabled={!unit.unitListingCurrent?.note}
                width={22}
                height={22}
              />
            </Tooltip>
          </span>,
        ],
        primaryAction: hasListing(unit)
          ? inviteApplicantsLink(unit, index)
          : createUnitListingLink(unit, index),
        dropdownItems: [
          {
            key: "EDIT",
            label: "Edit Unit",
            onClick: () => editUnit(unit),
          },
          ...conditionalItem(!hasListing(unit), {
            key: "DELETE",
            label: "Delete",
            onClick: () => deleteUnit(unit),
            disabled: hasListing(unit),
          }),
        ],
      };
    });

  return (
    <>
      <TitleWrapper>
        <div>
          <Title>Units</Title>
          <SearchWrapper>
            <Search
              id="search-table"
              name="search-table"
              label="Search by unit name…"
              style={{ width: 300 }}
              onSearch={(name) => onSearchStringChange(name)}
              debounce
            />
          </SearchWrapper>
        </div>
        <FilterAction
          onClick={() => {
            createUnit({
              propertyId,
              propertyName: propertySettings?.name,
            });
          }}
          plus
        >
          New Unit
        </FilterAction>
      </TitleWrapper>
      <Table
        showActionColumn
        loading={isUnitsLoading}
        searching={!!filters.name}
        columns={[
          {
            title: "Unit",
            dataIndex: "name",
            key: "name",
            sorter: true,
          },
          {
            title: "Unit Status",
            dataIndex: "unitStatus",
            key: "unitStatus",
          },
          {
            title: "Date Available",
            dataIndex: "dateAvailable",
            key: "dateAvailable",
            sorter: true,
          },
          {
            title: "Rent",
            dataIndex: "rent",
            key: "rent",
            sorter: true,
          },
          ...(isMagicPortalEnabled
            ? [
                {
                  title: "Display on Portal",
                  dataIndex: "displayOnPortal",
                  key: "displayOnPortal",
                  onCell: () => ({
                    className: "cursor-arrow",
                    onClick: (event) => {
                      event.preventDefault();
                      event.stopPropagation();
                    },
                  }),
                },
              ]
            : []),
          {
            title: "Note",
            dataIndex: "note",
            key: "note",
          },
        ]}
        rows={unitsRows()}
        columnBreakpoints={{
          xs: {
            column1: "name",
            column2: "unitStatus",
          },
          sm: ["name", "unitStatus"],
          md: ["name", "unitStatus", "displayOnPortal"],
          lg: [
            "name",
            "unitStatus",
            "dateAvailable",
            "rent",
            "displayOnPortal",
          ],
        }}
        onRowClick={(key) =>
          history.push(transformRoute(ROUTES.unit, { id: key }))
        }
        placeholder={{
          Icon: Icon.NoUnitsIcon,
          label: "Units",
        }}
        dropdownItems
        pagination={{
          current: filters.page,
          total: unitsCount,
          defaultPageSize: filters.pageSize,
        }}
        onChange={onUnitsTableChange}
      />
    </>
  );
};

UnitsTable.propTypes = {
  propertyId: PropTypes.string.isRequired,
  propertySettings: propertySettingsPropTypes,
  history: PropTypes.object.isRequired,
  units: PropTypes.arrayOf(unitPropType),
  unitsCount: PropTypes.number,
  isUnitsLoading: PropTypes.bool.isRequired,
  filters: PropTypes.shape({
    name: PropTypes.string,
    ordering: PropTypes.string,
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
  }).isRequired,
  onSearchStringChange: PropTypes.func.isRequired,
  onPaginationChange: PropTypes.func.isRequired,
  onSortOrderChange: PropTypes.func.isRequired,
  inviteApplicants: PropTypes.func.isRequired,
  createUnit: PropTypes.func.isRequired,
  editUnit: PropTypes.func.isRequired,
  deleteUnit: PropTypes.func.isRequired,
  createUnitListing: PropTypes.func.isRequired,
  isMagicPortalEnabled: PropTypes.bool.isRequired,
  onToggleUnitDisplayOnPortal: PropTypes.func.isRequired,
};

UnitsTable.defaultProps = {
  propertySettings: undefined,
  units: [],
  unitsCount: 0,
};

export default UnitsTable;
