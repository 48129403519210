import React, { useState, useContext } from "react";

import PropTypes from "prop-types";

import {
  useAssignAgentToDeal,
  useGetAssignableAgents,
} from "manager/hooks/api";
import { PopoverContext } from "shared/components/Popover";

import AssignAgentPopover from "./AssignAgentPopover";

const AssignAgentContainer = ({
  dealId,
  currentAgentId,
  afterAgentChanged,
  closeOnSuccess,
}) => {
  const [selectedAgent, setAgent] = useState(currentAgentId);
  const { data: agents, isLoading: isAgentsLoading } = useGetAssignableAgents(
    dealId,
    { enabled: Boolean(dealId) }
  );
  const context = useContext(PopoverContext);

  const { assignEmployeeToDeal, isLoading: isAssigningAgent } =
    useAssignAgentToDeal();

  const changeAgent = (employeeId) => {
    assignEmployeeToDeal(
      { dealId, employeeId },
      {
        onSuccess: () => {
          if (afterAgentChanged) {
            afterAgentChanged();
          }

          if (closeOnSuccess) {
            context.closePopover();
          }
        },
      }
    );
    setAgent(employeeId);
  };

  return (
    <AssignAgentPopover
      selectedAgent={selectedAgent}
      changeAgent={changeAgent}
      isAgentsLoading={isAgentsLoading}
      agents={agents}
      isAssigningAgent={isAssigningAgent}
    />
  );
};

AssignAgentContainer.propTypes = {
  dealId: PropTypes.number,
  currentAgentId: PropTypes.number,
  afterAgentChanged: PropTypes.func,
  closeOnSuccess: PropTypes.bool,
};

AssignAgentContainer.defaultProps = {
  dealId: undefined,
  currentAgentId: undefined,
  afterAgentChanged: undefined,
  closeOnSuccess: true,
};

export default AssignAgentContainer;
