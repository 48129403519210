import { useEffect, useMemo, useState } from "react";

import { Dayjs } from "dayjs";

import { DAYS_VISIBLE } from "./constants";
import { DayRange, Sort, SortOption, Unit } from "./types";
import { dayKey, DayKey, daysInRange } from "./utils";

export const useSearchResults = (
  sortValue: Sort,
  setSortValue: (s: Sort) => void,
  startDate: Dayjs,
  units: Unit[],
  unitsByDay: Map<DayKey, Unit[]>,
  setEnd: (end: Dayjs) => void,
  flexibleStart: boolean
) => {
  const sorts: SortOption[] = [
    { key: "0", name: "Price", value: Sort.price },
    { key: "1", name: "Start date", value: Sort.startDate },
    { key: "2", name: "Duration", value: Sort.duration },
  ];
  const [sort, setSort] = useState(sorts[0]);
  const setSortByKey = (key: string) => {
    const sort = sorts[+key];
    setSort(sort);
    setSortValue(sort.value);
  };

  useEffect(() => {
    if (sort.value !== sortValue) {
      setSort(sorts.filter((s) => s.value === sortValue)[0]);
    }
  }, [sortValue]);

  const getRange = (start: Dayjs): DayRange => {
    return { start, end: start.add(DAYS_VISIBLE - 1, "day") };
  };

  const [range, setRange] = useState<DayRange>(getRange(startDate));

  useEffect(() => {
    setRange(getRange(startDate));
  }, [startDate]);

  const next = () => {
    const r = getRange(range.start.add(DAYS_VISIBLE, "day"));
    setEnd(r.end);
    setRange(r);
  };

  const prev = () => {
    let start = range.start.subtract(DAYS_VISIBLE, "day");
    if (start.isBefore(startDate)) start = startDate;
    setRange(getRange(start));
  };

  const isDisablePrev = range.start.isSame(startDate);

  const list = useMemo(() => {
    return flexibleStart ? units : units.filter((e) => startDate.isSame(e.day));
  }, [flexibleStart, units, startDate]);

  const [grid, setGrid] = useState<Unit[][]>([]);
  useEffect(() => {
    const days = daysInRange(range).map(
      (day) => unitsByDay.get(dayKey(day)) ?? []
    );
    const maxLength = days.reduce((max, next) => Math.max(max, next.length), 0);
    const _grid = new Array<Unit[]>(maxLength);
    for (let i = 0; i < maxLength; ++i) {
      _grid[i] = days.map<Unit>((units) =>
        i < units.length ? units[i] : null
      );
    }
    setGrid([]); // to retrigger animations
    setTimeout(() => setGrid(_grid), 0);
  }, [range.start, unitsByDay]);

  const getMin = (u?: Unit, v?: Unit) =>
    (u?.range?.price ?? Infinity) < (v?.range?.price ?? Infinity) ? u : v;
  const bestListUnit = useMemo(
    () => list.reduce((min, unit) => getMin(min, unit), null),
    [list]
  );
  const bestGridUnit = useMemo(
    () =>
      grid.reduce<Unit>((min, units) => {
        const unit = units.reduce((m, u) => getMin(m, u));
        return getMin(unit, min);
      }, null),
    [grid]
  );

  return {
    sorts,
    sort,
    setSortByKey,
    isDisablePrev,
    next,
    prev,
    grid,
    list,
    range,
    bestListUnit,
    bestGridUnit,
  };
};
