import React from "react";

import { Col, Row } from "antd";
import { useFormikContext } from "formik";

import { MaskedInput, Select, VeroFormField } from "shared/components/Form";
import { SmallText } from "shared/components/Typography";
import { showError } from "shared/utils/forms";

import { getConditionPropPath } from "../../LumenFilterConditions/lumenFilterConditions.util";

import { AMOUNT_TYPE_OPTIONS, AMOUNT_OPERATOR_OPTIONS } from "../utils";

import BaseCondition from "./BaseCondition";
import { ConditionWrapper, EmptySpacer } from "./styled";

interface ConditionAmountProps {
  onDelete: () => void;
  idx: number;
}

const ConditionAmount = ({ idx, onDelete }: ConditionAmountProps) => {
  const conditionSubTypeProp = "conditionSubType";
  const amountOperatorProp = "amountOperator";
  const amountValueProp = "amountValue";

  const conditionSubTypePropPath = getConditionPropPath(
    idx,
    conditionSubTypeProp
  );
  const amountOperatorPropPath = getConditionPropPath(idx, amountOperatorProp);
  const amountValuePropPath = getConditionPropPath(idx, amountValueProp);

  const { touched, errors } = useFormikContext();

  return (
    <BaseCondition conditionLabel="Amount" onDelete={onDelete}>
      <ConditionWrapper>
        <Row gutter={[32, 16]} type="flex">
          <Col span={8}>
            <SmallText light>Amount of</SmallText>
            <VeroFormField
              as={Select}
              name={conditionSubTypePropPath}
              id={conditionSubTypePropPath}
            >
              {AMOUNT_TYPE_OPTIONS.map((option) => (
                <Select.Option key={option.key}>{option.label}</Select.Option>
              ))}
            </VeroFormField>
          </Col>
          <Col span={8}>
            <SmallText light>Is</SmallText>
            <VeroFormField
              as={Select}
              name={amountOperatorPropPath}
              id={amountOperatorPropPath}
            >
              {AMOUNT_OPERATOR_OPTIONS.map((option) => (
                <Select.Option key={option.key}>{option.label}</Select.Option>
              ))}
            </VeroFormField>
          </Col>
          <Col span={8}>
            <EmptySpacer />
            {/* @ts-ignore */}
            <MaskedInput.Formik.Money
              id={amountValuePropPath}
              name={amountValuePropPath}
              label="Value"
              error={showError(amountValuePropPath, touched, errors)}
            />
          </Col>
        </Row>
      </ConditionWrapper>
    </BaseCondition>
  );
};

export default ConditionAmount;
