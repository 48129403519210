import React from "react";

import { MediumTitle, SmallText } from "shared/components/Typography";

import { Card } from "./styled";

export interface DashboardCardProps {
  title: string;
  subtitle: string;
  value: string;
}

const DashboardCard = ({ title, subtitle, value }: DashboardCardProps) => {
  return (
    /* @ts-ignore */
    <Card>
      <div className="card-header">
        <MediumTitle>{title}</MediumTitle>
        {/* @ts-ignore */}
        <SmallText extraStrong>{subtitle}</SmallText>
      </div>
      <div className="card-value">{value}</div>
    </Card>
  );
};

export default DashboardCard;
