import { queryCache, useMutation, useQuery } from "react-query";

import { NOTIFICATIONS } from "shared/config/constants";
import { LoginAuth } from "shared/interfaces/api";

import api from "shared/lib/api";
import { getAccessToken, isRenterLikeApp } from "shared/utils/auth";
import { openNotification } from "shared/utils/ui";

const GET_ACTIVE_USER = "getActiveUser";

export const useActiveUser = () => {
  const jwt = getAccessToken();
  const isRenter = isRenterLikeApp();
  const { data: activeUser, isLoading: isActiveUserLoading } = useQuery(
    [GET_ACTIVE_USER],
    () => (isRenter ? api.getApplicantUserProfile() : api.getUserProfile()),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!jwt,
      retry: false,
    }
  );

  return {
    activeUser,
    isActiveUserLoading,
  };
};

export const useLoginSelfSignedDeepLink = () => {
  const [loginSelfSignedDeepLink, { isLoading }] = useMutation(
    api.loginSelfSignedDeepLink,
    { throwOnError: true }
  );

  return {
    loginSelfSignedDeepLink,
    isLoading,
  };
};

export const useLoginAuth = () => {
  const [loginAuth, { isLoading }] = useMutation(
    ({ email, password }: LoginAuth) => api.login(email, password),
    {
      throwOnError: true,
    }
  );

  return {
    loginAuth,
    isLoading,
  };
};

export const useSetApplicantUserProfile = () => {
  const [setApplicantUserProfile] = useMutation(api.setApplicantUserProfile, {
    onSuccess: () => {
      openNotification(
        "Your profile has successfully been updated.",
        NOTIFICATIONS.info
      );
      queryCache.invalidateQueries(GET_ACTIVE_USER);
    },
    onError: () => {
      openNotification("Failed to update profile!", NOTIFICATIONS.error);
    },
  });

  return {
    setApplicantUserProfile,
  };
};

export const useSendNewInvitationLink = ({ token }: { token: string }) => {
  const [data, { isLoading }] = useMutation(
    () => api.sendNewInvitationLink(token),
    {
      onError: () =>
        openNotification("Invalid invitation link.", NOTIFICATIONS.error),
    }
  );

  return {
    sendNewInvitationLink: data,
    isSendNewInvitationLink: isLoading,
  };
};
