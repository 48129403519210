import styled from '@emotion/styled'

import isEmpty from "lodash/isEmpty"
import { BACKGROUND_CREDIT_CHECK_PROVIDER } from 'manager/pages/ExperianAuth/interfaces'
import type { Application } from 'renter/interfaces/api/application'
import { CreditScoreSummary } from 'shared/components/CreditAndBackgroundCheck/interfaces'
import Icon from 'shared/components/Icon'
import { UnderlineLink } from 'shared/components/Links'
import Tile from 'shared/components/Tile'

import { colors } from 'shared/config/constants'

import { isManagerApp } from 'shared/utils/auth'

import { DealPartial } from '../types'
import { addressFormatter } from '../utils'

import {
  ApplicantContainer,
  ApplicantRow,
  ApplicantInfoTitle,
  TextColumn,
  ApplicantParagraph,
  ApplicantInfoName,
  ColumnParapgraph,
} from './ApplicantInfo.styles'

// icon

export const HelpIcon = styled(Icon.Help)`
  display: block;
  width: 16px;
  height: 16px;
  color: ${colors.accent[500]};
`

type ApplicantInfoProps = {
  creditScoreSummary?: CreditScoreSummary
  deal?: DealPartial | null
  applicantFullName: string
  application?: Application | null
}

const N_A = 'N/A'

const ApplicantInfo = ({
  creditScoreSummary,
  deal = null,
  application = null,
  applicantFullName,
}: ApplicantInfoProps) => {
  const transUnionInfo = isManagerApp()
    ? 'If you are unsure that this is your applicant, or have a question on the information below, please call TransUnion Customer Support at 1-833-458-6338'
    : 'If you are unsure that this is your information, or have a question on the information below, please call TransUnion Customer Support at 1-833-458-6338'

  const experianInfo = isManagerApp() ? (
    <>
      If you have questions or concerns about the information below, please
      contact{' '}
      <UnderlineLink onClick={() => window?.Intercom?.('showNewMessage')}>
        VERO Support
      </UnderlineLink>{' '}
      to file a dispute.
    </>
  ) : (
    <>
      If you are an applicant and have questions or concerns about the
      information below, please contact{' '}
      <UnderlineLink onClick={() => window?.Intercom?.('showNewMessage')}>
        VERO Support
      </UnderlineLink>{' '}
      to file a dispute.
    </>
  )
  const currentResidence = application?.residentialHistories?.find((residence) => residence.livesHere) ?? {}
  const [{ address = null, city = null, state = null, zipcode = null } = {}] =
    !isEmpty(currentResidence) ? [currentResidence] : creditScoreSummary?.addresses ?? [{}]
  const { firstName: agentFirstName, lastName: agentLastName } = deal?.employee
    ?.user ?? { firstName: null, lastName: null }
  const {
    address: propertyAddress,
    city: propertyCity,
    state: propertyState,
    zipcode: propertyZipcode,
  } = deal?.property ?? {}

  const isExperianProvider =
    application?.bccProvider ===
    BACKGROUND_CREDIT_CHECK_PROVIDER.EXPERIAN_CONSUMER_CONNECT

  const applicantAddress = addressFormatter({ address, city, state, zipcode })
  const agentName =
    !!agentFirstName || !!agentLastName
      ? `${agentFirstName} ${agentLastName}`
      : N_A
  const agentAddress = addressFormatter({
    address: propertyAddress,
    city: propertyCity,
    state: propertyState,
    zipcode: propertyZipcode,
  })

  return (
    <>
      <Tile>
        <Tile.Inner borderBottom>
          <ApplicantContainer>
            <ApplicantRow>
              <ApplicantInfoTitle>
                Information submitted by applicant
              </ApplicantInfoTitle>
            </ApplicantRow>
            <ApplicantRow>
              <ColumnParapgraph>
                <TextColumn>
                  <ApplicantInfoName>{applicantFullName}</ApplicantInfoName>
                  <ApplicantParagraph>Current address:</ApplicantParagraph>
                  <ApplicantParagraph>{applicantAddress}</ApplicantParagraph>
                </TextColumn>

                <TextColumn>
                  <ApplicantParagraph>
                    Requested by Agent: {agentName}
                  </ApplicantParagraph>
                  <ApplicantParagraph>
                    Property address: {agentAddress}
                  </ApplicantParagraph>
                </TextColumn>
              </ColumnParapgraph>
            </ApplicantRow>
            <ApplicantRow>
              <HelpIcon />
              {isExperianProvider ? (
                <ApplicantParagraph>{experianInfo}</ApplicantParagraph>
              ) : (
                <ApplicantParagraph>{transUnionInfo}</ApplicantParagraph>
              )}
            </ApplicantRow>
          </ApplicantContainer>
        </Tile.Inner>
      </Tile>
    </>
  )
}

export default ApplicantInfo
