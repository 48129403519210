import axios from "axios";

import ROUTES from "admin/config/apiRoutes";
import { API_URL } from "shared/lib/api/setupApi";
import { transformRoute } from "shared/utils/routing";

const api = {
  getTags: () => axios.get(`${API_URL}${ROUTES.tags}`),
  createTag: ({
    name,
    templateId,
  }: {
    name: string;
    templateId: string | number;
  }) => axios.post(`${API_URL}${ROUTES.tags}`, { name, template: templateId }),
  deleteTagFromTemplate: ({
    tagId,
    templateId,
  }: {
    tagId: string | number;
    templateId: string | number;
  }) =>
    axios.delete(
      `${API_URL}${transformRoute(ROUTES.tagRemoveTemplate, {
        tagId,
        templateId,
      })}`
    ),
  addTagToTemplate: ({ tagId, templateId, name }) =>
    axios.put(
      `${API_URL}${transformRoute(ROUTES.tag, {
        id: tagId,
      })}`,
      {
        template: templateId,
        name,
      }
    ),
};

export default api;
