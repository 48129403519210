import axios from "axios";

import { BackgroundCreditCheck } from "renter/interfaces/api/backgroundCreditCheck";
import ROUTES from "shared/config/apiRoutes";
import { transformRoute } from "shared/utils/routing";

import { API_URL } from "./setupApi";

const api = {
  getBackgroundAndCreditCheck: ({
    applicationId,
  }: {
    applicationId: string | number;
  }) =>
    axios
      .get<BackgroundCreditCheck>(
        `${API_URL}${transformRoute(ROUTES.getBackgroundAndCreditCheck, {
          applicationId,
        })}`
      )
      .then(({ data }) => data),
  submitSSN: ({ id, ssn }) =>
    axios.post(
      `${API_URL}${transformRoute(ROUTES.submitSSN, { applicationId: id })}`,
      {
        SSN: ssn,
      },
      // @ts-ignore
      { convertToSnakeCase: false }
    ),
  getCreditCheckQuiz: async ({ applicationId, ssn }) =>
    axios.post(
      `${API_URL}${transformRoute(ROUTES.getCreditCheckQuiz, {
        applicationId,
      })}`,
      { SSN: ssn },
      // @ts-ignore
      { convertToSnakeCase: false }
    ),
  submitCreditCheckQuiz: async ({ applicationId, data }) =>
    axios.post(
      `${API_URL}${transformRoute(ROUTES.submitCreditCheckQuiz, {
        applicationId,
      })}`,
      { ...data }
    ),
};

export default api;
