import _ from 'lodash'
import Tooltip from 'shared/components/Tooltip'
import { buildClassName } from 'shared/utils/vega'
import { printDollarsFromCents } from 'shared/utils/dollar-print'

const LineItem = ({ lineItem, card = false, minimal = false }) => {
  const statusMap = {
    success: ['paid'],
    'in-progress': ['unpaid', 'charged'],
    warn: ['canceled'],
    error: ['disputed', 'refunded', 'partially-refunded'],
  }

  const status = _.findKey(statusMap, l => _.includes(l, lineItem?.status))

  if (minimal) {
    return <span className={buildClassName(['vega badge small', status])} title={lineItem?.id}>{lineItem?.status}</span>
  }

  return (
    <div className={buildClassName(['vega flex no-gap column', card && 'card'])}>
      <Tooltip theme='light' title={lineItem?.id}>
        <span className='vega name'>{lineItem?.name}</span>
        <span className={buildClassName(['vega badge', status])}>{lineItem?.status}</span>
      </Tooltip>

      <div className='vega amount'>{printDollarsFromCents(lineItem?.amount || lineItem?.feeAmount)}</div>
    </div>
  )
}

const LineItems = ({ lineItems, card = false, minimal = false }) => {
  return (
    <div className={buildClassName(['vega flex', minimal && 'start'])}>
      {_.chain(lineItems).uniqBy('id').map(lineItem => <LineItem lineItem={lineItem} card={card} minimal={minimal} key={lineItem?.id} />).value()}
    </div>
  )
}

export default LineItems
