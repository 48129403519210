import _ from 'lodash'
import PropTypes from "prop-types";
import withSizes from "react-sizes";
import { printDollarsFromCents } from "shared/utils/dollar-print";
import {
  LumenScore,
} from "manager/components/app-summary/action-bar";
import DealStatusColumn from "manager/components/DealStatusColumn";
import DealIntegrationMark from "manager/components/IntegrationMark/DealIntegrationMark";
import { useDealsTable } from "manager/hooks/useDealsTable";
import { dropdownLeaseStatusesPropType } from "manager/utils/propTypes";
import Icon from "shared/components/Icon";
import Table from "shared/components/Table";
import {
  DEAL_UI_STATUS_CODES,
  DEAL_UI_STATUS_LABEL,
} from "shared/config/constants";
import { paginationPropType } from "shared/utils/propTypes";
import { formatDate, getValueOrNA } from "shared/utils/ui";

import DealPropTypes from "../Deals.propTypes";

import {
  Cell,
  FlexWrapper,
  NotAvailableSpan,
  PrimaryText,
  SecondaryText,
  StyledCheckList,
} from "./styled";

const DealsTable = ({
  deals,
  dealsCount,
  loading,
  dealStatuses,
  hasInputValue,
  setPagination,
  pagination,
  onTableChange,
  initialOrderingColumn,
  refetchDeals,
  experianPrompt,
}) => {
  const { page, pageSize } = pagination;

  const {
    getActionLink,
    goToDealSummary,
    getOtherRowEvent,
  } = useDealsTable({ refetchDeals });

  const onPaginationChange = (currentPage) => {
    const newPagination = { page: currentPage, pageSize };
    setPagination(newPagination);
  };

  const getDealStatusText = (code, complete, total) => {
    const isPartiallySubmitted =
      code === DEAL_UI_STATUS_CODES.partiallySubmitted;
    const dealStatus = isPartiallySubmitted
      ? DEAL_UI_STATUS_LABEL[code](complete, total)
      : DEAL_UI_STATUS_LABEL[code];
    return (
      dealStatus ||
      dealStatuses.find(({ id }) => id === code)?.value ||
      "Unknown"
    );
  };

  const noUnitAssigned = <NotAvailableSpan>No unit assigned</NotAvailableSpan>;

  return (
    <Table
      searching={hasInputValue}
      alt
      loading={loading}
      columns={[
        {
          title: "Unit",
          dataIndex: "unit",
          key: "unit",
          sorter: true,
          defaultSortOrder: initialOrderingColumn.unit,
        },
        {
          title: "Name",
          dataIndex: "applicants",
          key: "applicants",
        },
        {
          title: "Date Created",
          dataIndex: "applicationDate",
          key: "applicationDate",
          sorter: true,
          defaultSortOrder: initialOrderingColumn.applicationDate,
        },
        {
          title: "Gross Rent",
          dataIndex: "rent",
          key: "rent",
          sorter: true,
          defaultSortOrder: initialOrderingColumn.rent,
        },
        {
          title: "Status",
          dataIndex: "dealStatus",
          key: "dealStatus",
        },
        {
          title: "Lumen Result",
          dataIndex: "lumenResult",
          key: "lumenResult",
          sorter: true,
          defaultSortOrder: initialOrderingColumn.lumenResult,
        }
      ]}
      columnBreakpoints={{
        xs: {
          column1: "unit",
          column2: "applicationDate",
        }
      }}
      onRowClick={(key) => goToDealSummary(key, experianPrompt)}
      placeholder={{
        Icon: Icon.NoDealsIcon,
        label: "Applications",
      }}
      rows={deals.map((deal, index) => {
        const applicants = deal.applicantsSummary || [];
        const numTotalApplications = deal.applicantsSummary.length;
        const numCompleteApplications = deal.applicantsSummary.filter(
          (applicant) => applicant.isSubmitted
        ).length;
        const isRentIcon = deal.unit?.yardiConnected && deal.lease?.rentPaid;

        const actionLink = getActionLink(deal, experianPrompt);

        const dealStatus = getDealStatusText(
          deal.dealUiStatus,
          numCompleteApplications,
          numTotalApplications
        );
        const { rent, isArchived, lastArchivedAt } = deal;

        const getDealStatus = () => (
          <DealStatusColumn
            actionLink={actionLink}
            dealUiStatus={dealStatus}
            status={dealStatus}
            isArchived={isArchived}
            lastArchivedAt={lastArchivedAt}
          />
        );

        const lumenScoreLabel = deal.lastLumenScore ? (!['IS', 'AI', 'APS'].includes(deal.dealUiStatus) ? deal.lastLumenScore : _.upperCase(LumenScore.STILL_CALCULATING)) : LumenScore.NOT_APPLICABLE

        return {
          key: deal.id,
          rawData: deal,
          values: [
            <Cell>
              {deal.unit || deal.property ? (
                <>
                  <PrimaryText data-testid={`deal-${index}-unitName`}>
                    {getValueOrNA(deal.unit?.name)}
                    <DealIntegrationMark
                      property={deal.property}
                      unit={deal.unit}
                      deal={deal}
                    />
                  </PrimaryText>
                  <SecondaryText data-testid={`deal-${index}-propertyName`}>
                    {deal.property?.name}
                  </SecondaryText>
                </>
              ) : (
                noUnitAssigned
              )}
            </Cell>,
            <Cell>
              <PrimaryText data-testid={`deal-${index}-applicants`}>
                <StyledCheckList
                  items={applicants}
                  onClick={() => goToDealSummary(deal.id, experianPrompt)}
                />
              </PrimaryText>
            </Cell>,
            <Cell>
              <PrimaryText data-testid={`deal-${index}-applicationDate`}>
                {formatDate(deal.applicationDate)}
              </PrimaryText>
            </Cell>,
            <Cell>
              <FlexWrapper alignItems="center">
                <PrimaryText data-testid={`deal-${index}-rent`}>
                  {printDollarsFromCents(rent)}
                </PrimaryText>
                {isRentIcon && <Icon.DollarSignIcon />}
              </FlexWrapper>
            </Cell>,
            <Cell>{getDealStatus()}</Cell>,
            <Cell>
              <FlexWrapper alignItems="center">
                <PrimaryText data-testid={`deal-${index}-lumen-score`}>{lumenScoreLabel}</PrimaryText>
              </FlexWrapper>
            </Cell>
          ],
          viewed: false,
        };
      })}
      pagination={{
        current: page,
        onChange: onPaginationChange,
        total: dealsCount,
        defaultPageSize: pageSize,
        hideOnSinglePage: true
      }}
      getOtherRowEvent={getOtherRowEvent}
      onChange={onTableChange}
    />
  );
};

DealsTable.propTypes = {
  deals: DealPropTypes.deals,
  refetchDeals: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  hasInputValue: PropTypes.bool.isRequired,
  dealsCount: PropTypes.number.isRequired,
  setPagination: PropTypes.func.isRequired,
  pagination: paginationPropType.isRequired,
  dealStatuses: DealPropTypes.dealStatuses,
  onTableChange: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
  initialOrderingColumn: PropTypes.shape({
    unit: PropTypes.string,
    applicationDate: PropTypes.string,
    rent: PropTypes.string,
    lumenResult: PropTypes.string,
  }).isRequired,
  experianPrompt: PropTypes.bool,
};

DealsTable.defaultProps = {
  deals: [],
  dealStatuses: [],
};

export default withSizes(({ width }) => ({ width }))(DealsTable);
