import { CommonStorageCollectionManager } from "./commonStorageManager";

export class LocalStorageCollectionManager extends CommonStorageCollectionManager {
  // eslint-disable-next-line class-methods-use-this
  storageGetItem(key) {
    return localStorage.getItem(key);
  }

  // eslint-disable-next-line class-methods-use-this
  storageSetItem(key, value) {
    localStorage.setItem(key, value);
  }
}
