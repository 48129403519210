import React, { Ref } from "react";

import { Formik, FormikProps } from "formik";
import isFunction from "lodash/isFunction";
import { BaseSchema } from "yup";

import { Button } from "shared/components/Button";
import Container from "shared/components/Container";
import FooterBar from "shared/components/FooterBar";
import {
  PreventLeaveFormModal,
  PreventLeaveFormModalProps,
} from "shared/components/Form/PreventLeaveFormModal";
import Spacer from "shared/components/Spacer";

import { Form, FooterBar as StyledFootBar } from "./styled";

type PromptProps = Pick<
  PreventLeaveFormModalProps,
  | "onNextLocationTriggered"
  | "customOnConfirm"
  | "customOnCancel"
  | "title"
  | "subtitle"
  | "submitButtonLabel"
  | "cancelLinkLabel"
>;

const DEFAULT_PROMPT_PROPS = {
  title: "Save settings changes?",
  subtitle: null,
  submitButtonLabel: "Yes",
  cancelLinkLabel: "No",
};

export type FormPromptShellProps = {
  children:
    | ((arg0: any) => React.ReactNode)
    | React.ReactNode
    | React.ReactElement;
  saveChanges: (arg0: any) => void | Promise<any>;
  onSubmit: (arg0: any, arg1: any) => void | Promise<any>;
  submitButtonLabel?: string;
  validationSchema?: BaseSchema;
  validateOnChange?: boolean;
  validateOnBlur?: boolean;
  initialValues: object;
  getPromptProps?:
    | ((arg0: any) => object)
    | ((props: FormikProps<any>) => PromptProps);
  formRef?: Ref<FormikProps<any>>;
  showFooterWithSubmit?: boolean;
  CustomFootBar?: React.ElementType;
  shouldDisableSubmitButton?: boolean;
  showSecondaryButton?: boolean;
  onSecondaryButtonClick?: () => void;
  secondaryButtonLabel?: string;
};

export const FormPromptShell: React.FC<FormPromptShellProps> = ({
  children,
  saveChanges = () => {},
  onSubmit = () => {},
  submitButtonLabel = "SAVE CHANGES",
  validationSchema,
  validateOnChange = true,
  validateOnBlur = true,
  initialValues,
  getPromptProps,
  formRef,
  showFooterWithSubmit = true,
  CustomFootBar,
  shouldDisableSubmitButton = true,
  showSecondaryButton = false,
  onSecondaryButtonClick = () => {},
  secondaryButtonLabel = "Cancel",
}) => {
  return (
    <Container noPadding expand>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
        innerRef={formRef}
        validateOnChange={validateOnChange}
        validateOnBlur={validateOnBlur}
        validationSchema={validationSchema}
      >
        {(formContext) => {
          const { values, isSubmitting, dirty, isValid } = formContext;

          const promptProps = {
            ...DEFAULT_PROMPT_PROPS,
            ...(getPromptProps && getPromptProps(formContext)),
          };

          return (
            <Form noValidate>
              {isFunction(children) ? children(formContext) : children}
              <FooterBar.Spacer />
              {showFooterWithSubmit && (
                <Container noPadding stickToBottom>
                  {!CustomFootBar && (
                    <StyledFootBar>
                      {showSecondaryButton && (
                        <Button
                          type="secondary"
                          id="form-secondary"
                          onClick={onSecondaryButtonClick}
                        >
                          {secondaryButtonLabel}
                        </Button>
                      )}

                      <Button
                        id="form-submit"
                        type="primary"
                        htmlType="submit"
                        loading={isSubmitting}
                        disabled={
                          shouldDisableSubmitButton && (!dirty || !isValid)
                        }
                        data-testid="submit-button"
                      >
                        {submitButtonLabel}
                      </Button>
                    </StyledFootBar>
                  )}
                  {CustomFootBar && <CustomFootBar />}
                </Container>
              )}
              <PreventLeaveFormModal
                submitForm={() => saveChanges(values)}
                isSubmitting={isSubmitting}
                preventLeaveWhen={dirty}
                {...promptProps}
              >
                <Spacer />
              </PreventLeaveFormModal>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};
