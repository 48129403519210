import { INTEGRATION_TYPES } from "shared/config/constants";

const getIntegrationType = (obj) => {
  const provider = obj?.provider?.toUpperCase();
  if (obj?.yardiConnected) {
    return INTEGRATION_TYPES.YARDI;
  }
  if (provider === INTEGRATION_TYPES.ENTRATA) {
    return INTEGRATION_TYPES.ENTRATA;
  }
  if (provider === INTEGRATION_TYPES.REAL_PAGE) {
    return INTEGRATION_TYPES.REAL_PAGE;
  }
  return null;
};

const hasIntegration = (obj) => {
  return !!getIntegrationType(obj);
};

export { hasIntegration, getIntegrationType };
