import React, { useMemo, useRef, useState, useEffect, Key, Ref } from "react";

import { Radio } from "antd";
import { CheckboxChangeEvent } from "antd-latest/lib/checkbox";
import isBoolean from "lodash/isBoolean";

import DropdownMenu from "shared/components/DropdownMenu";
import FlexContainer from "shared/components/FlexContainer";
import { Checkbox } from "shared/components/Form/Checkbox";
import { CollapsibleCheckboxList } from "shared/components/Form/CollapsibleCheckboxList";
import Icon from "shared/components/Icon";
import { ListProps } from "shared/components/types";
import { mergeRefs } from "shared/utils/react";

import { SearchableList, SearchableListProps } from "./SearchableList";
import { Search, HeaderTitle, CheckboxListHeaderContent } from "./styled";
import { SearchPropTypes, SELECTION_FILTER_VALUES } from "./types";

const ACTIVE_SELECTION_FILTER_VALUES = new Set([
  SELECTION_FILTER_VALUES.SELECTED,
  SELECTION_FILTER_VALUES.NOT_SELECTED,
]);

export type CheckboxListHeaderProps = {
  searchProps?: SearchPropTypes;
  customSearchContent?: React.ReactNode;
  headerCheckboxVisible?: boolean;
  headerCheckboxDisabled?: boolean;
  numChecked?: number;
  isChecked?: boolean;
  isIndeterminate?: boolean;
  firstColLabel?: string;
  secondColLabel?: string;
  onChange?: (items: CheckboxChangeEvent) => void;
  selectionFilterChange?: (value: string) => void;
  selectionFilter?: SELECTION_FILTER_VALUES;
};

export const CheckboxListHeader: React.FC<CheckboxListHeaderProps> = ({
  headerCheckboxVisible = true,
  headerCheckboxDisabled,
  numChecked = 0,
  isChecked,
  isIndeterminate,
  firstColLabel,
  secondColLabel,
  onChange,
  selectionFilter = SELECTION_FILTER_VALUES.ALL,
  selectionFilterChange,
  customSearchContent,
  searchProps,
}) => (
  <CheckboxListHeaderContent className="checkbox-list-header">
    <FlexContainer>
      <div className="first-col">
        {headerCheckboxVisible && (
          <Checkbox
            id="chkbox"
            inline
            checked={isChecked}
            onChange={onChange}
            disabled={headerCheckboxDisabled}
            indeterminate={isIndeterminate}
          />
        )}
        {firstColLabel && (
          <div>
            <HeaderTitle>
              {firstColLabel}
              {headerCheckboxVisible && (
                <span className="extra-text">
                  &nbsp;({numChecked} selected)
                </span>
              )}
            </HeaderTitle>
          </div>
        )}
      </div>
      {secondColLabel && (
        <HeaderTitle className="second-col">{secondColLabel}</HeaderTitle>
      )}
    </FlexContainer>
    {customSearchContent}
    {searchProps && (
      <div className="search">
        <Search
          id={searchProps.id}
          name={searchProps.fieldName}
          onSearch={searchProps.action}
          label={searchProps.label}
          width={searchProps.width}
          debounce={searchProps.debounce}
        />
        <Radio.Group
          onChange={(e) => selectionFilterChange(e.target.value)}
          value={selectionFilter}
        >
          <DropdownMenu
            light
            items={[
              {
                key: SELECTION_FILTER_VALUES.ALL,
                label: <Radio value={SELECTION_FILTER_VALUES.ALL}>All</Radio>,
                onClick: () => {},
              },
              {
                key: SELECTION_FILTER_VALUES.SELECTED,
                label: (
                  <Radio value={SELECTION_FILTER_VALUES.SELECTED}>
                    Selected
                  </Radio>
                ),
                onClick: () => {},
              },
              {
                key: SELECTION_FILTER_VALUES.NOT_SELECTED,
                label: (
                  <Radio value={SELECTION_FILTER_VALUES.NOT_SELECTED}>
                    Not selected
                  </Radio>
                ),
                onClick: () => {},
              },
            ]}
          >
            <div className="filter-icon">
              {ACTIVE_SELECTION_FILTER_VALUES.has(selectionFilter) && (
                <div className="active-filter" />
              )}
              <Icon.FilterIcon />
            </div>
          </DropdownMenu>
        </Radio.Group>
      </div>
    )}
  </CheckboxListHeaderContent>
);

export type SearchableCheckboxListWithHeaderProps = SearchableListProps & {
  listProps: ListProps;
  searchProps?: SearchPropTypes;
  listApiRef?: Ref<any>;
  customSearchContent?: React.ReactNode;
  headerProps: {
    headerCheckboxVisible?: boolean;
    firstColLabel?: string;
    secondColLabel?: string;
  };
  listContainerProps: {
    checkedItemsOnChange?: (items: Key[]) => void;
  };
};

export const SearchableCheckboxListWithHeader: React.FC<SearchableCheckboxListWithHeaderProps> =
  ({
    listProps,
    listContainerProps,
    headerProps,
    customSearchContent,
    searchProps,
    listApiRef,
    loading,
    hasInputValue,
    placeholder,
    ...props
  }) => {
    const listRef = useRef(null);
    const [isChecked, setChecked] = useState(false);
    const [numChecked, setNumChecked] = useState(0);
    const [isIndeterminate, setIndeterminate] = useState(false);
    const [selectionFilter, setSelectionFilter] = useState(
      SELECTION_FILTER_VALUES.ALL
    );
    const [selectedItems, setSelectedItems] = useState(new Set([]));

    const allItems = listProps?.items || [];
    const headerCheckboxDisabled = !allItems || !allItems.length;
    const providedVisibleItems =
      listProps?.visibleItems || listProps?.items || [];

    const visibleItems = useMemo(() => {
      if (selectionFilter === SELECTION_FILTER_VALUES.SELECTED) {
        return providedVisibleItems.filter((i) => selectedItems.has(i.key));
      }
      if (selectionFilter === SELECTION_FILTER_VALUES.NOT_SELECTED) {
        return providedVisibleItems.filter((i) => !selectedItems.has(i.key));
      }
      return providedVisibleItems;
    }, [selectionFilter, selectedItems, providedVisibleItems]);

    const handleCheckedItems = (items: Key[]) => {
      setChecked(listRef.current.allVisibleChecked());
      setIndeterminate(listRef.current.someVisibleChecked());
      setNumChecked(items.length);
      setSelectedItems(new Set(items));
    };

    const handleHeaderCheckboxClick = (event) => {
      const { checked } = event.target;
      if (checked && !isIndeterminate) {
        listRef.current.selectAllVisible();
      } else {
        listRef.current.unselectAll();
      }
    };

    const handleSelectionFilterChange = (newSelectionFilter) => {
      setSelectionFilter(newSelectionFilter);
    };

    const checkedItemsOnChange = (items: Key[]) => {
      listContainerProps?.checkedItemsOnChange?.(items);
      handleCheckedItems(items);
    };

    useEffect(() => {
      if (
        isBoolean(headerProps?.headerCheckboxVisible) &&
        !headerProps?.headerCheckboxVisible &&
        listRef?.current
      ) {
        listRef.current.unselectAll();
      }
    }, [headerProps?.headerCheckboxVisible, listRef]);

    const newListProps = {
      ...listProps,
      visibleItems,
    };

    return (
      <SearchableList
        {...{ loading, hasInputValue, placeholder }}
        listRef={mergeRefs(listRef, listApiRef)}
        customHeaderContent={
          <CheckboxListHeader
            onChange={handleHeaderCheckboxClick}
            selectionFilterChange={handleSelectionFilterChange}
            {...{
              headerCheckboxDisabled,
              selectionFilter,
              isChecked,
              isIndeterminate,
              searchProps,
              customSearchContent,
              numChecked,
            }}
            {...headerProps}
          />
        }
        List={CollapsibleCheckboxList}
        listContainerProps={{
          ...listContainerProps,
          checkedItemsOnChange,
        }}
        listProps={newListProps}
        {...props}
      />
    );
  };
