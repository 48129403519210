import { createAction } from "redux-actions";

import * as types from "./types";

export const receivePortfolios = createAction(
  types.RECEIVE_PORTFOLIOS,
  ({ portfolios }) => ({ portfolios })
);

export const receivePortfolio = createAction(
  types.RECEIVE_PORTFOLIO,
  ({ portfolio }) => ({ portfolio })
);
