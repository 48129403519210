import React, { useState } from "react";

import { useHistory } from "react-router-dom";

import ROUTES from "admin/config/routes";
import { useDocumentTable, useGetDocumentDrafts } from "admin/hooks/api";
import useFileUpload from "admin/hooks/useFileUpload";
import { NOTIFICATIONS } from "shared/config/constants";
import { transformRoute } from "shared/utils/routing";
import { openNotification } from "shared/utils/ui";

import Documents from "./Documents";

const initialSearch = {
  docNameOrCompanyNameOrTagName: "",
  draftName: "",
};

const DocumentsContainer = () => {
  const history = useHistory();
  const { fileUpload } = useFileUpload();

  const [searchParams, setSearchParams] = useState(initialSearch);

  const handleSearchParams = (id: string, value: string) =>
    setSearchParams((previousValue) => ({ ...previousValue, [id]: value }));

  const { isLoading, data: documents } = useDocumentTable({
    onError: () =>
      openNotification("Failed to load document table", NOTIFICATIONS.error),
  });

  const { isLoading: isLoadingDrafts, data: drafts } = useGetDocumentDrafts();

  const onFileSelect = (files) => {
    fileUpload({
      file: files[0],
      drafts,
      isDraftSuccess: (draft) =>
        history.push(transformRoute(ROUTES.draftConstructor, { id: draft.id })),
      isNoDaftUpload: () =>
        history.push(ROUTES.documentConstructor, { file: files[0] }),
    });
  };

  return (
    <Documents
      searchParams={searchParams}
      isLoading={isLoading}
      documents={documents}
      handleSearchParams={handleSearchParams}
      drafts={drafts}
      isLoadingDrafts={isLoadingDrafts}
      onFileSelect={onFileSelect}
    />
  );
};

export default DocumentsContainer;
