import dayjs from "dayjs";
import { queryCache, useMutation, useQuery } from "react-query";

import {
  ApplicantInviteApplicants,
  ContactManager,
} from "renter/interfaces/api/deal";
import { getApplicantDeal } from "renter/lib/dealApi";
import { APPLICANT_TYPES, NOTIFICATIONS } from "shared/config/constants";
import * as dealApi from "shared/lib/api/dealApi";
import { openNotification } from "shared/utils/ui";

const GET_MANAGER = "getManager";

const GET_APPLICANT_DEAL = "getApplicantDeal";

export const DEAL_QUERY_KEYS = {
  getApplicantDeal: (id: string | number) => [GET_APPLICANT_DEAL, Number(id)],
};

export const useApplicantDeal = (id: number) => {
  const { data, isLoading, refetch } = useQuery(
    DEAL_QUERY_KEYS.getApplicantDeal(id),
    () => getApplicantDeal(id),
    {
      enabled: Boolean(id),
      onError: () =>
        openNotification("Failed to load deal", NOTIFICATIONS.error),
    }
  );
  return {
    applicantDeal: data,
    isApplicantDealLoading: isLoading,
    refetchApplicantDeal: refetch,
  };
};

export const useDealManager = (id: number) => {
  const { data: manager, isLoading: isManagerLoading } = useQuery(
    [GET_MANAGER, id],
    () => dealApi.getManager({ id }),
    {
      onError: () =>
        openNotification("Failed to load deal manager", NOTIFICATIONS.error),
    }
  );
  return { manager, isManagerLoading };
};

export const useContactManager = ({ onSuccess }) => {
  const [mutate, { isLoading: isContactManager }] = useMutation(
    (payload: ContactManager) => dealApi.contactManager(payload),
    {
      onSuccess,
      onError: () =>
        openNotification(
          "Failed to set screening agreement date",
          NOTIFICATIONS.error
        ),
    }
  );

  return {
    contactManager: mutate,
    isContactManager,
  };
};

export const useApplicantInviteApplicants = (dealId: number) => {
  const [mutate, { isLoading: isApplicantInviteApplicants }] = useMutation(
    ({ applicantType, applicants }: ApplicantInviteApplicants) =>
      dealApi.applicantInviteApplicants({
        applicants:
          applicantType === APPLICANT_TYPES.occupant
            ? applicants.filter(
                (applicant) =>
                  dayjs().diff(
                    dayjs(applicant.birthdate, "YYYY-MM-DD"),
                    "years"
                  ) >= 18
              )
            : applicants,
        otherOccupants:
          applicantType === APPLICANT_TYPES.occupant
            ? applicants.filter(
                (applicant) =>
                  dayjs().diff(
                    dayjs(applicant.birthdate, "YYYY-MM-DD"),
                    "years"
                  ) < 18
              )
            : [],
        dealId,
      }),
    {
      // eslint-disable-next-line no-empty-pattern
      onSuccess: ({}, { applicantType, applicants }) => {
        let message = "Invite successfully sent!";
        if (applicantType === APPLICANT_TYPES.occupant) {
          const hasOccupantUnder18 =
            applicants.filter(
              (applicant) =>
                dayjs().diff(
                  dayjs(applicant.birthdate, "YYYY-MM-DD"),
                  "years"
                ) < 18
            ).length > 0;
          const hasOccupantOver18 =
            applicants.filter(
              (applicant) =>
                dayjs().diff(
                  dayjs(applicant.birthdate, "YYYY-MM-DD"),
                  "years"
                ) >= 18
            ).length > 0;

          if (hasOccupantUnder18 && !hasOccupantOver18) {
            message =
              "The individual was successfully added to the application.";
          } else if (hasOccupantUnder18 && hasOccupantOver18) {
            message =
              "Invitations were sent, and individuals under 18 were successfully added to the application.";
          }
        }
        queryCache.invalidateQueries(DEAL_QUERY_KEYS.getApplicantDeal(dealId));
        openNotification(
          `${message} Invite another or click continue to proceed.`,
          NOTIFICATIONS.info
        );
      },
    }
  );

  return {
    applicantInviteApplicants: mutate,
    isApplicantInviteApplicants,
  };
};
