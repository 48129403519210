import styled from "@emotion/styled";

import { COLORS } from "shared/config/constants";

const TextBase = {
  color: COLORS.textColorPrimary,
};

const TextPrimary = {
  ...TextBase,
  fontWeight: 500,
};

const TextSecondary = {
  ...TextBase,
  fontWeight: 400,
};

const TextFaint = {
  color: COLORS.textColorSecondary,
};

const PrimaryText = styled("span")({
  ...TextPrimary,
});

const PrimaryTextLarge = styled("span")({
  ...TextPrimary,
  fontSize: "17px",
});

const SecondaryText = styled("span")({
  ...TextSecondary,
});

const SecondaryTextLarge = styled("span")({
  ...TextSecondary.color,
  fontSize: "17px",
});

const TextFaintSmall = styled("span")({
  ...TextFaint,
  fontSize: "12px",
});

export {
  PrimaryText,
  SecondaryText,
  PrimaryTextLarge,
  SecondaryTextLarge,
  TextFaintSmall,
};
