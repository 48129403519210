import withSizes from "react-sizes";

import { getBreakpoint } from "shared/utils/ui";

const mapSizesToProps = ({ width }) => ({
  breakpoint: getBreakpoint(width),
});

const withBreakpoint: <T>(c: T) => T = (component) =>
  withSizes(mapSizesToProps)(component);

export default withBreakpoint;
