import React from "react";

import Icon from "shared/components/Icon";
import { Modal } from "shared/components/Modal";
import Well from "shared/components/Well";

import { ActiveDocumentErrorModalProps } from "./interfaces";
import {
  Subtitle,
  HeaderIcon,
  ModalBody,
  Description,
  Property,
} from "./styled";

const ActiveDocumentErrorModal = ({
  company,
  properties,
}: ActiveDocumentErrorModalProps) => (
  <Modal
    subtitle={
      <Subtitle>
        <HeaderIcon>
          <Icon.ErrorDescriptionIcon />
        </HeaderIcon>
        <span>
          This document can&#39;t be unassigned from {company} because it is
          currently assigned to the following properties:
        </span>
      </Subtitle>
    }
    submitButtonLabel="Got it"
    showCancelLink={false}
  >
    <ModalBody data-testid="active-document-message">
      <Well transparent>
        <Description>
          {properties?.map((property) => (
            <Property key={property}>{property}</Property>
          ))}
        </Description>
      </Well>
    </ModalBody>
  </Modal>
);

export default ActiveDocumentErrorModal;
