import React from "react";

import { useFormikContext } from "formik";
import PropTypes from "prop-types";

import {
  SourcesCreateEditModal,
  SourcesDeleteModal,
} from "manager/components/Modal";
import useModal from "shared/hooks/useModal";
import { sanitizeStringSearchValue } from "shared/utils/string";

import { generateSourceId } from "../additionalSettings.util";

import Sources from "./Sources";
import { linkedSourcesPropTypes } from "./sources.config";

const SourcesContainer = ({
  title,
  subTitle,
  propertyName,
  createTitle,
  editTitle,
  createEditInputDescription,
  createEditInputPlaceholder,
  deleteTitle,
  deleteDescription,
  sourceType,
  linkedData,
}) => {
  const { openModalDialog } = useModal();
  const { setFieldValue, values } = useFormikContext();

  const getSources = () => values?.sources || [];
  const getUsedSourceNames = (source) =>
    getSources()
      .filter((sourceIt) => sourceIt.id !== source.id)
      .map((sourceIt) => sanitizeStringSearchValue(sourceIt.name));

  const updateFormSources = (updateAction) => {
    const formSources = [...getSources()];
    updateAction(formSources);
    formSources.sort((s1, s2) => s1.name.localeCompare(s2.name));
    setFieldValue("sources", formSources);
  };

  const createNewSource = () => {
    const source = {
      id: generateSourceId(),
      name: null,
    };

    const saveAction = (newName) => {
      source.name = newName;
      updateFormSources((formSources) => formSources.push(source));
    };

    const context = {
      title: createTitle,
      propertyName,
      inputDescription: createEditInputDescription,
      inputPlaceholder: createEditInputPlaceholder,
      saveAction,
      source,
      usedNames: getUsedSourceNames(source),
    };
    openModalDialog(SourcesCreateEditModal, context);
  };

  const editSource = (source, idx) => {
    const saveAction = (newName) => {
      updateFormSources((formSources) => {
        // eslint-disable-next-line no-param-reassign
        formSources[idx] = { ...formSources[idx], name: newName };
      });
    };

    const context = {
      title: editTitle,
      propertyName,
      inputDescription: createEditInputDescription,
      inputPlaceholder: createEditInputPlaceholder,
      saveAction,
      source,
      usedNames: getUsedSourceNames(source),
    };
    openModalDialog(SourcesCreateEditModal, context);
  };

  const deleteSource = (source, idx) => {
    const deleteAction = () => {
      updateFormSources((formSources) => {
        // eslint-disable-next-line no-param-reassign
        formSources.splice(idx, 1);
      });
    };

    const context = {
      title: deleteTitle,
      sourceType,
      sourceName: source.name,
      warningText: source.isLinked ? linkedData.deleteWarningText : undefined,
      contentText: source.isLinked
        ? linkedData.deleteDescription
        : deleteDescription,
      onSubmit: deleteAction,
    };

    openModalDialog(SourcesDeleteModal, context);
  };

  return (
    <Sources
      title={title}
      subTitle={subTitle}
      createNewSource={createNewSource}
      editSource={editSource}
      deleteSource={deleteSource}
      linkedData={linkedData}
    />
  );
};

SourcesContainer.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  propertyName: PropTypes.string,
  createTitle: PropTypes.string.isRequired,
  editTitle: PropTypes.string.isRequired,
  createEditInputDescription: PropTypes.string.isRequired,
  createEditInputPlaceholder: PropTypes.string.isRequired,
  deleteTitle: PropTypes.string.isRequired,
  deleteDescription: PropTypes.string.isRequired,
  sourceType: PropTypes.string.isRequired,
  linkedData: linkedSourcesPropTypes.isRequired,
};

SourcesContainer.defaultProps = {
  propertyName: undefined,
};

export default SourcesContainer;
