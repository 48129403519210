import React from "react";

import { Formik } from "formik";

import styled from "styled-components";

import ApplicantsCheckList from "shared/components/ApplicantsCheckList";
import { Button, BUTTON_SIZES } from "shared/components/Button";
import FlexContainer from "shared/components/FlexContainer";
import { UploadWall } from "shared/components/Form/UploadWall";
import { UnderlineLink } from "shared/components/Links";
import Spacer from "shared/components/Spacer";
import { LargeTitle, RegularText } from "shared/components/Typography";
import { VeroModal } from "shared/components/vero-modal";

import { FormGroupsCss } from "shared/styles/commonStyles";

import { Subtitle } from "../styled";

import { DealSummaryWithProperty } from "../types";

import { useDeclineDealModal } from "./context";
import { useHook } from "./hooks";

const Body = styled.div`
  ${
    // @ts-ignore
    FormGroupsCss
  }

  .ant-latest-upload-list-picture-card > div:nth-child(2n) {
    margin-right: 0;
  }
`;

export interface DeclineDealModalProps {
  deal: DealSummaryWithProperty;
}

export const DeclineDealModalV2 = ({ deal }: DeclineDealModalProps) => {
  const {
    onSubmit,
    uploadFile,
    excludeFile,
    modalExtension,
    title,
    submitLabel,
  } = useHook(deal);
  const state = useDeclineDealModal();
  const { cancel$, submit$, submitting } = state;

  return (
    <Formik
      initialValues={{}}
      validateOnMount
      onSubmit={(values) => submit$.next(onSubmit(values))}
    >
      {({ submitForm, isValid }) => (
        <VeroModal {...state}>
          <LargeTitle>{title}</LargeTitle>
          <Subtitle>
            <RegularText strong>Applicants:&nbsp;</RegularText>
            <ApplicantsCheckList inline items={deal?.applicantsSummary} />
          </Subtitle>

          <Spacer size={Spacer.SIZES.default} />

          <Body>
            {modalExtension}
            <div className="form-group">
              <p className="form-control-label">
                Choose which attachments to include:
              </p>

              <UploadWall
                maxCount={5}
                existingFiles={[]}
                uploadFile={uploadFile}
                deleteFile={excludeFile}
                deleteFilesById={false}
                disabled={submitting}
              />
            </div>
          </Body>

          <Spacer size={Spacer.SIZES.md} />
          <FlexContainer
            justifyContent="flex-end"
            gap="24px"
            alignItems="center"
          >
            {!submitting && (
              <UnderlineLink onClick={() => cancel$.next()}>
                Cancel
              </UnderlineLink>
            )}
            <Button
              type="primary"
              onClick={submitForm}
              size={BUTTON_SIZES.lg}
              loading={submitting}
              disabled={!isValid}
            >
              {submitLabel}
            </Button>
          </FlexContainer>
        </VeroModal>
      )}
    </Formik>
  );
};
