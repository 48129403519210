/* eslint-disable max-lines */

import React, { useContext } from 'react'

import { Col, Row } from 'antd'
import _ from 'lodash'
import PropTypes from 'prop-types'

import { useGetIncomeByStreams } from 'renter/hooks/api'
import ApplicationDataTag from 'shared/components/ApplicationDataTag'
import Icon from 'shared/components/Icon'
import Spacer from 'shared/components/Spacer'
import Tile from 'shared/components/Tile'
import { MediumTitle, RegularText } from 'shared/components/Typography'
import Well from 'shared/components/Well'
import { EMAILS_WITH_INCOME_GRAPH_VIEW_PERMISSION } from "shared/config/authConstants";
import {
  MAX_INCOMES_NUMBER_IN_STREAM,
  VERIFICATION_STATUSES,
} from 'shared/config/constants'

import { usePermissions } from "shared/hooks";
import { useActiveUser, useGetRoles } from 'shared/hooks/api'
import { getSkippedIncomeAssetsInfo } from 'shared/utils/application'
import { isManagerApp } from "shared/utils/auth";
import { printDollarsFromCents } from 'shared/utils/dollar-print'
import { getCurrentEmployments } from 'shared/utils/employmentAndIncome'
import { incomeAndAssetPropType } from 'shared/utils/propTypes'

import ApplicationContext from '../applicationContext'
import { BankAccounts } from '../components/AssetsSection/BankAccounts'
import { CashAvailable } from '../components/AssetsSection/CashAvailable'
import { IncomeBucketChart } from '../components/IncomesBucketsChart/IncomeBucketChart'

import { ExcludedIncomeSection } from '../components/IncomeStreamsSection/ExcludedIncomeSection'
import { IncludedIncomeSection } from '../components/IncomeStreamsSection/IncludedIncomeSection'
import { IncomeStreams } from '../components/IncomeStreamsSection/IncomeStreams'
import { IAndBSkeleton } from '../components/Skeleton/Skeleton'
import StatedEmployers from '../components/StatedEmployers'

import { EmploymentHistory, useEmploymentHistory } from '../EmploymentHistory'

import {
  EmploymentHistoryCustom,
  ReasonWrapper,
  TileCustom,
  TitleCustom,
  IncomeNote,
  FraudBanner,
  RiskBanner,
  BannerContentContainer,
} from './styled'

const IncomeAndAssets = ({ summary, applicationSummary, ocrResults, canViewOcrResults }) => {
  const { activeUser } = useActiveUser()
  const { data: userRoles } = useGetRoles()
  const { application } = useContext(ApplicationContext)
  const {
    dealDecisionIsMade,
    incomeGroupRule,
    location,
    originalSubmitDate,
    personVrn,
    applicantVrn,
    snapshot,
  } = application
  const {
    excludedStream,
    includedStream,
    isLoading: isIncomeByStreamsLoading,
  } = useGetIncomeByStreams(
    {
      detailed: true,
      incomeNumberLimit: MAX_INCOMES_NUMBER_IN_STREAM,
      location,
      person: personVrn,
      applicant: applicantVrn,
      groupingRule: incomeGroupRule,
      referenceDate: originalSubmitDate,
    },
    !_.isEmpty(application) &&
      !_.isEmpty(personVrn) &&
      !dealDecisionIsMade
  )
  const { companyPermissions } = usePermissions();
  const { canEditOcrResults } = companyPermissions || {};
  const includedIncomeStream = dealDecisionIsMade
    ? snapshot?.incomeStreams?.flatMap(({ streams }) =>
        streams.filter(({ isIncluded }) => isIncluded),
      ) ?? []
    : includedStream
  const excludedIncomeStream = dealDecisionIsMade
    ? snapshot?.incomeStreams?.flatMap(({ streams }) =>
        streams.filter(({ isIncluded }) => !isIncluded),
      ) ?? []
    : excludedStream
  const snapshotEmployments = _.get(application, 'snapshot.employments')
  const verifiedIncome = _.get(application, 'verifiedIncome')
  const currentEmployments = getCurrentEmployments(snapshotEmployments)

  const statedEmployers = _.chain(currentEmployments).map('employer').value()

  const accounts = summary?.accounts
  const isAssetsEdited =
    summary?.assetsVerificationStatus === VERIFICATION_STATUSES.EDITED

  const assets = isAssetsEdited ? summary?.editedAssets : summary?.totalAssets

  const skippedIncomeAssets = getSkippedIncomeAssetsInfo(
    summary?.skippedReason,
    summary?.bankName,
  )

  const showFraudBanner = isManagerApp() && canViewOcrResults && excludedIncomeStream.some((stream) => stream.isFraudulent);
  const showRiskBanner = isManagerApp() && excludedIncomeStream.length > 0;

  const showReasonsToSkipApplicationBlock =
    applicationSummary?.skippedIncome &&
    applicationSummary?.skippedAsset &&
    applicationSummary?.reasonsSkipBlockDealSubmitIfIncomeDataIsMissing
      ?.length > 0

  const employmentProps = useEmploymentHistory(applicationSummary)

  const showIncomeByBucketChart =
    activeUser?.email?.endsWith('@sayvero.com') &&
    EMAILS_WITH_INCOME_GRAPH_VIEW_PERMISSION.includes(activeUser?.email)

  return (
    <>
      {showFraudBanner && (
        <>
          <FraudBanner
            Icon={Icon.ErrorTrianglewhiteOutlineIcon}
            message={
              <BannerContentContainer>
                <RegularText>
                  Some of the applicant's income has been excluded due to potential fraud risk.
                </RegularText>
                <RegularText extraStrong>
                  <a href="#unverified-income">VIEW FRAUD SIGNALS</a>
                </RegularText>
              </BannerContentContainer>
            }
          />
          <Spacer size={showRiskBanner ? Spacer.SIZES.xs : Spacer.SIZES.sm } />
        </>
      )}
      {showRiskBanner && (
        <>
          <RiskBanner
            Icon={Icon.BlackExclamationHollow}
            message={
              <BannerContentContainer>
                <RegularText>
                  Some of the applicant’s income has been excluded due to validation checks. Please review the details to ensure accurate assessment.
                </RegularText>
                <RegularText extraStrong>
                  <a href="#unverified-income">VIEW EXCLUDED INCOME</a>
                </RegularText>
              </BannerContentContainer>
            }
          />
          <Spacer size={Spacer.SIZES.sm} />
        </>
      )}
      {showIncomeByBucketChart && (
        <TileCustom rounded>
          <Tile.Inner>
            <IncomeBucketChart />
            <IncomeNote>
              <Icon.InfoBlueIcon width={16} height={16} />
              <div>
                The graph only illustrates actual income per month, omitting any
                anticipated income.
              </div>
            </IncomeNote>
          </Tile.Inner>
        </TileCustom>
      )}
      {showReasonsToSkipApplicationBlock && (
        <Row gutter={14} type="flex">
          <Col span={24}>
            <Well lightWarning largePadding>
              <RegularText>
                Reasons applicant provided to unblock the application
                submission:
              </RegularText>
              <Spacer size={Spacer.SIZES.xs} />
              {applicationSummary?.reasonsSkipBlockDealSubmitIfIncomeDataIsMissing?.map(
                (reason) => (
                  <ReasonWrapper key={reason.key} alignItems="center">
                    <Icon.OrangeExclamationSmall className="warning-icon" />
                    <RegularText>{reason.label}</RegularText>
                  </ReasonWrapper>
                ),
              )}
            </Well>
            <Spacer size={Spacer.SIZES.sm} />
          </Col>
        </Row>
      )}
      {isIncomeByStreamsLoading ? (
        <IAndBSkeleton title={{ rows: 3, width: [141, 181, 69] }} />
      ) : (
        <IncludedIncomeSection streams={includedIncomeStream} canViewOcrResults={canViewOcrResults} canEditOcrResults={canEditOcrResults} />
      )}
      <CashAvailable />
      {!isIncomeByStreamsLoading && (
        <ExcludedIncomeSection streams={excludedIncomeStream} ocrResults={ocrResults} canViewOcrResults={canViewOcrResults} canEditOcrResults={canEditOcrResults} />
      )}
    </>
  )
}

IncomeAndAssets.propTypes = {
  summary: incomeAndAssetPropType,
  applicationSummary: PropTypes.shape({
    skippedIncome: PropTypes.bool,
    skippedAsset: PropTypes.bool,
    reasonsSkipBlockDealSubmitIfIncomeDataIsMissing: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      }),
    ),
  }),
}

IncomeAndAssets.defaultProps = {
  summary: undefined,
  applicationSummary: undefined,
}

export default IncomeAndAssets
