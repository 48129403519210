import React from "react";

import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import { fullApplicationPropType } from "manager/utils/propTypes";
import DropdownMenu, {
  dropdownMenuItemsPropType,
} from "shared/components/DropdownMenu";
import FlexContainer from "shared/components/FlexContainer";
import LargeLoader from "shared/components/LargeLoader";
import { BackLink } from "shared/components/Links";
import LoaderContainer from "shared/components/LoaderContainer";
import PageTitle from "shared/components/PageTitle";
import { RegularText } from "shared/components/Typography";
import { getFullnameData } from "shared/utils/applicant";
import { backLinkPropType } from "shared/utils/propTypes";
import { formatDateTime } from "shared/utils/ui";

import LeadSummary from "./LeadSummary";
import { LeadDetailsContent, TitleWrapper } from "./styled";

const LeadDetails = ({ lead, isLeadLoading, applicantWorkflow, backLink, dropdownItems }) => {
  const { applicationSummary, prescreeningType, yardiPcode, submittedAt } = lead || {};
  const { fullNameOrNA } = getFullnameData(applicationSummary);
  const title = `${fullNameOrNA}'s Prequalification`;

  return (
    <LoaderContainer
      Loader={<LargeLoader showLoader absoluteCenter />}
      loading={isLeadLoading}
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <LeadDetailsContent noPaddingBottom>
        <BackLink.Container>
          <BackLink {...backLink} />
        </BackLink.Container>
        <PageTitle.Container
          justifyLeft
          center
          className="page-title-container"
        >
          <TitleWrapper>
            <PageTitle>{title}</PageTitle>
            <PageTitle.Actions className="actions-wrapper">
              {dropdownItems?.length > 0 && (
                <DropdownMenu
                  className="dropdown-menu"
                  placement="bottomLeft"
                  items={dropdownItems}
                />
              )}
            </PageTitle.Actions>
          </TitleWrapper>
        </PageTitle.Container>
        <FlexContainer className="subtitle-container">
          <RegularText strong className="pre-screening-type">
            {`${!prescreeningType ? "PROSPECT" : prescreeningType}`}&nbsp;
          </RegularText>
          <RegularText strong className="p-code-text">{` (${
            yardiPcode || "No identifier"
          })`}</RegularText>
          {submittedAt && (
            <>
              <RegularText className="spacer">•</RegularText>
              <RegularText className="completed-on-text">
                Completed on {formatDateTime(submittedAt)}
              </RegularText>
            </>
          )}
        </FlexContainer>
      </LeadDetailsContent>
      <LeadSummary lead={lead} />
    </LoaderContainer>
  );
};

LeadDetails.propTypes = {
  lead: fullApplicationPropType,
  isLeadLoading: PropTypes.bool.isRequired,
  backLink: backLinkPropType.isRequired,
  dropdownItems: dropdownMenuItemsPropType,
};

LeadDetails.defaultProps = {
  lead: undefined,
  dropdownItems: [],
};

export default LeadDetails;
