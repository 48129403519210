import React from "react";

import { useUnassignPropertyFromPortfolio } from "manager/hooks/api";

import UnassignPropertyFromPortfolioModal from "./UnassignPropertyFromPortfolioModal";

interface UnassignPropertyFromPortfolioModalContainerProps {
  portfolioId: string | number;
  portfolioName: string;
  property: {
    id: string | number;
    name: string;
  };
}

const UnassignPropertyFromPortfolioModalContainer = ({
  portfolioId,
  portfolioName,
  property,
}: UnassignPropertyFromPortfolioModalContainerProps) => {
  const { unassignPropertyFromPortfolio, isUnassignPropertyFromPortfolio } =
    useUnassignPropertyFromPortfolio({
      portfolioId,
      portfolioName,
      propertyId: property?.id,
      propertyName: property?.name,
    });
  return (
    <UnassignPropertyFromPortfolioModal
      loading={isUnassignPropertyFromPortfolio}
      portfolioName={portfolioName}
      submit={unassignPropertyFromPortfolio}
    />
  );
};

export default UnassignPropertyFromPortfolioModalContainer;
