import styled from "@emotion/styled";

import Card from "shared/components/Card";
import Icon from "shared/components/Icon";
import { MEDIA_QUERIES } from "shared/config/constants";
import { CenteredCard } from "shared/styles/commonStyles";

const PageLoadSpinner = styled(Icon.LoadingSpinnerGreenIcon)`
  display: block;
  margin: 0 auto;
`;

const SetPasswordPageCard = styled(Card)`
  ${CenteredCard}

  ${MEDIA_QUERIES.w.ltMd} {
    top: 40px;
    transform: none;
  }

  .col-vertical-divider {
    display: flex;
    justify-content: center;
  }

  .normal-logo {
    align-self: end;
  }

  .mobile-logo {
    margin-bottom: 40px;
  }
`;

export { PageLoadSpinner, SetPasswordPageCard };
