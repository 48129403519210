import axios from "axios";

const api = {
  getAppVersion: () =>
    axios
      .get(`${process.env.PUBLIC_URL}/version.txt?noCache=${Date.now()}`)
      .then(({ data }) => data),
};

export default api;
