import styled from "@emotion/styled";

const IntegrationActivationWarningContent = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  ".integration-warning-icon": {
    marginBottom: "25px",
  },
});

export { IntegrationActivationWarningContent };
