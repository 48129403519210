import React from "react";

import { Form, Formik, FormikHelpers } from "formik";
import capitalize from "lodash/capitalize";

import { APPLICANT_TYPES_LABEL } from "manager/config/constants";
import { AdditionalQuestionModel } from "manager/interfaces/api/additionalQuestion";
import ErrorList from "shared/components/ErrorList";
import { Modal } from "shared/components/Modal";
import { RegularText } from "shared/components/Typography";
import OptionItem from "shared/interfaces/api/optionItem";
import { ApplicantType, VeroFormikProps } from "shared/interfaces/misc";

import AdditionalQuestionInnerFormElements from "./AdditionalQuestionInnerFormElements";
import {
  AdditionalQuestionValidationSchema,
  ADDITIONAL_QUESTION_FIELDS,
} from "./config";

interface AdditionalQuestionModalProps {
  submit: (
    values: AdditionalQuestionModel,
    formikHelpers: FormikHelpers<AdditionalQuestionModel>
  ) => void;
  forSettingsLabel: string;
  initialValues?: Partial<AdditionalQuestionModel>;
  mode: string;
  applicantType: ApplicantType;
  onAnswerTypeChange: Function;
  isAnswerTypeOptionsLoading: boolean;
  answerTypeOptions: OptionItem[];
}

const AdditionalQuestionModal = ({
  submit,
  forSettingsLabel,
  initialValues = {
    [ADDITIONAL_QUESTION_FIELDS.questionName]: undefined,
    [ADDITIONAL_QUESTION_FIELDS.options]: undefined,
  },
  mode,
  applicantType,
  onAnswerTypeChange,
  isAnswerTypeOptionsLoading,
  answerTypeOptions,
}: AdditionalQuestionModalProps) => (
  <Formik
    validationSchema={AdditionalQuestionValidationSchema}
    initialValues={initialValues}
    onSubmit={submit}
  >
    {({
      submitForm,
      isSubmitting,
      errors,
    }: VeroFormikProps<AdditionalQuestionModel>) => (
      <Form>
        {/* @ts-ignore */}
        <Modal
          title={`${capitalize(mode)} Question for ${capitalize(
            APPLICANT_TYPES_LABEL[applicantType]
          )}s`}
          subtitle={
            <RegularText data-testid="subtitle">
              For <b>{forSettingsLabel}</b>
            </RegularText>
          }
          submit={submitForm}
          submitting={isSubmitting}
          closeOnSubmit={false}
          submitButtonLabel="Save question"
          width={650}
        >
          {errors.nonFieldErrors && (
            <Modal.Body noPaddingBottom>
              <ErrorList errors={errors.nonFieldErrors} />
            </Modal.Body>
          )}
          <Modal.Body>
            <AdditionalQuestionInnerFormElements
              onAnswerTypeChange={onAnswerTypeChange}
              isAnswerTypeOptionsLoading={isAnswerTypeOptionsLoading}
              answerTypeOptions={answerTypeOptions}
            />
          </Modal.Body>
        </Modal>
      </Form>
    )}
  </Formik>
);

export default AdditionalQuestionModal;
