import React, { useEffect } from "react";

import { Formik, Field } from "formik";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

import ROUTES from "manager/config/routes";
import { Button } from "shared/components/Button";
import FlexContainer from "shared/components/FlexContainer";
import { RadioGroup, SwitchTitle } from "shared/components/Form";
import { Radio } from "shared/components/Form/RadioGroup";
import Icon from "shared/components/Icon";
import { ActionLink, IconLink, UnderlineLink } from "shared/components/Links";
import LoaderContainer from "shared/components/LoaderContainer";
import Spacer from "shared/components/Spacer";
import Tile from "shared/components/Tile";
import Tooltip from "shared/components/Tooltip";
import { RegularText } from "shared/components/Typography";
import Well from "shared/components/Well";
import { showError } from "shared/utils/forms";
import { transformRoute } from "shared/utils/routing";

import { StyledCompanyEmailTemplatesForm, StyledLoader } from "../styled";
import { emailConfigurationValidationSchema } from "../utils";

const CompanyEmailTemplatesForm = ({
  defaultConfigurationId,
  currentConfiguration,
  customEmailConfigurations,
  onSubmit,
  isLoading,
  onDeleteCustomEmailConfiguration,
  sendTestEmail,
  isSendingEmail,
  registerFormChange,
}) => {
  const { state } = useLocation();
  const { newEmailTemplateId, newEmailTemplateDefaultConfigId } = state || {};
  const hasNewEmail =
    newEmailTemplateDefaultConfigId === defaultConfigurationId;

  const helperText =
    customEmailConfigurations && customEmailConfigurations.length > 0
      ? "You can also create and select a custom template from the list below."
      : "You can create a new custom template with your own language.";

  return (
    <LoaderContainer loading={isLoading} Loader={<StyledLoader />}>
      <Formik
        initialValues={currentConfiguration}
        onSubmit={onSubmit}
        validationSchema={emailConfigurationValidationSchema}
        enableReinitialize
      >
        {({
          values,
          touched,
          errors,
          setValues,
          resetForm,
          isSubmitting,
          dirty,
          isValid,
          submitForm,
        }) => {
          /** NOTE: Auto-select a newly created email template after navigation from EmailBuilder */
          useEffect(() => {
            if (newEmailTemplateId && hasNewEmail) {
              setValues({
                isDefaultConfiguration: false,
                customEmailConfigurationId: newEmailTemplateId,
              });
            }
          }, [newEmailTemplateId, hasNewEmail]);

          useEffect(() => {
            registerFormChange(defaultConfigurationId, {
              isValid,
              dirty,
              submitForm: () => onSubmit(values),
            });
          }, [isValid, dirty, submitForm, values]);

          return (
            <StyledCompanyEmailTemplatesForm>
              <Tile.Inner borderBottom>
                <Field
                  as={SwitchTitle}
                  id="isDefaultConfiguration"
                  name="isDefaultConfiguration"
                  title="Use the standard VERO template"
                  reverseOrder
                  onChange={(value) =>
                    setValues({
                      isDefaultConfiguration: value,
                      customEmailConfigurationId: null,
                    })
                  }
                  value={values.isDefaultConfiguration}
                  noPadding
                  disabled={customEmailConfigurations.length === 0}
                />
              </Tile.Inner>
              <Tile.Inner>
                <FlexContainer justifyContent="space-between">
                  <RegularText>{helperText}</RegularText>
                  <ActionLink
                    plus
                    to={transformRoute(ROUTES.emailBuilder, {
                      defaultConfigurationId,
                    })}
                  >
                    New Template
                  </ActionLink>
                </FlexContainer>
                <Spacer />
                {customEmailConfigurations.length > 0 ? (
                  <RadioGroup
                    id="customEmailConfigurationId"
                    name="customEmailConfigurationId"
                    options={customEmailConfigurations.map((item) => ({
                      label: item.name,
                      value: item.id,
                    }))}
                    disabled={values.isDefaultConfiguration}
                    block
                    radioOptionRenderer={(option) => (
                      <Well
                        key={option.value}
                        noBorder
                        className="custom-email-row"
                      >
                        <FlexContainer alignItems="center">
                          <Radio
                            key={option.value}
                            value={option.value}
                            block
                            disabled={values.isDefaultConfiguration}
                          >
                            {option.label}
                          </Radio>
                          <IconLink
                            Icon={Icon.EditGreenIcon}
                            className="edit-icon"
                            to={transformRoute(ROUTES.emailBuilderEdit, {
                              templateId: option.value,
                            })}
                          />
                          <Tooltip
                            title={
                              dirty
                                ? "Please save changes before deleting this template."
                                : null
                            }
                          >
                            <div>
                              <IconLink
                                Icon={Icon.DeleteIcon}
                                className="delete-icon"
                                onClick={() =>
                                  onDeleteCustomEmailConfiguration(option)
                                }
                                disabled={dirty}
                              />
                            </div>
                          </Tooltip>
                        </FlexContainer>
                      </Well>
                    )}
                    error={showError(
                      "customEmailConfigurationId",
                      touched,
                      errors
                    )}
                  />
                ) : (
                  <div className="no-templates">
                    <Icon.NoTemplatesIcon />
                  </div>
                )}
                <Spacer />
                <FlexContainer alignItems="center" justifyContent="right">
                  <div className="reset-link">
                    <UnderlineLink onClick={resetForm} disabled={!dirty}>
                      Reset
                    </UnderlineLink>
                  </div>
                  <Button
                    className="send-test-button"
                    type="secondary"
                    onClick={() => sendTestEmail(values)}
                    loading={isSendingEmail}
                    disabled={!isValid}
                  >
                    Send Test
                  </Button>
                  <Button
                    className="submit-button"
                    htmlType="submit"
                    type="primary"
                    disabled={!dirty}
                    loading={isSubmitting}
                  >
                    Save
                  </Button>
                </FlexContainer>
              </Tile.Inner>
            </StyledCompanyEmailTemplatesForm>
          );
        }}
      </Formik>
    </LoaderContainer>
  );
};

CompanyEmailTemplatesForm.propTypes = {
  currentConfiguration: PropTypes.shape({
    isDefaultConfiguration: PropTypes.bool,
    customEmailConfigurationId: PropTypes.number,
  }).isRequired,
  customEmailConfigurations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  isLoading: PropTypes.bool.isRequired,
  onDeleteCustomEmailConfiguration: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  defaultConfigurationId: PropTypes.number.isRequired,
  sendTestEmail: PropTypes.func.isRequired,
  isSendingEmail: PropTypes.bool.isRequired,
  registerFormChange: PropTypes.func.isRequired,
};

CompanyEmailTemplatesForm.defaultProps = {
  customEmailConfigurations: [],
};

export default CompanyEmailTemplatesForm;
