import React from "react";

import styled from "@emotion/styled";

import { Button } from "shared/components/Button";
import { COLORS } from "shared/config/constants";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const StyledSSOButton = styled(({ color, ...props }) => (
  <Button {...props} />
))`
  width: 230px;
  border-color: ${COLORS.borderColorBase} !important;
  padding: 8px;

  .button-inner {
    width: 100%;
    height: 100%;
    padding: 0;
  }

  .button-children {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .label {
    font-size: 14px;
    color: ${(props) => props.color};
    margin-left: 12px;
  }
`;
