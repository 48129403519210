import React from "react";

import { useHistory } from "react-router-dom";

import { AssignPropertiesToPortfolioModal } from "manager/components/Modal";
import ROUTES from "manager/config/routes";
import useModal from "shared/hooks/useModal";
import { transformRoute } from "shared/utils/routing";

import { Portfolio } from "../interfaces";

import PropertiesDetail from "./PropertiesDetail";

interface PropertiesDetailContainerProps {
  portfolio: Portfolio;
  loading: boolean;
}

const PropertiesDetailContainer = ({
  portfolio,
  loading,
}: PropertiesDetailContainerProps) => {
  const history = useHistory();
  const { openModalDialog } = useModal();

  const assignProperties = () =>
    openModalDialog(AssignPropertiesToPortfolioModal, {
      portfolioName: portfolio?.name,
      portfolioId: portfolio?.id,
    });
  const openSettings = () =>
    history.push(
      transformRoute(ROUTES.portfolioSettings, { id: portfolio?.id })
    );

  return (
    <PropertiesDetail
      assignProperties={assignProperties}
      openSettings={openSettings}
      portfolio={portfolio}
      loading={loading}
    />
  );
};

export default PropertiesDetailContainer;
