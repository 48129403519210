import styled from "@emotion/styled";

import Icon from "shared/components/Icon";
import List from "shared/components/List";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";
import { BACKGROUND_MEDIA_QUERIES } from "shared/styles/backgroundMediaQueries";
import { CenteredFlexPartial } from "shared/styles/commonStyles";

const LoadingSpinner = styled(Icon.LoadingSpinnerGreenIcon)({
  display: "block",
  margin: "0 auto",
});

const Container = styled("section")({
  backgroundColor: COLORS.lightGray2,
  width: "100vw",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  position: "relative",
});

const ContentContainer = styled("div")({
  zIndex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  ".logo-container": {
    marginTop: "20px",
    marginBottom: "24px",
    ".logo": {
      maxHeight: "50px",
    },
  },
  ".title": {
    marginBottom: "8px !important",
    fontSize: "17px !important",
    fontWeight: 500,
  },
  ".powered-by": {
    ...CenteredFlexPartial,
    display: "none",
    marginTop: "32px",
    ".logo": {
      marginLeft: "5px",
    },
    "&.inner": {
      margin: 0,
      display: "flex",
    },
  },
  ".submit-btn": {
    width: "100%",
  },
  ".footer": {
    display: "none",
    fontWeight: 600,
    ".emoji": {
      marginBottom: "-8px",
      marginLeft: "5px",
    },
  },
  [BACKGROUND_MEDIA_QUERIES.w.gteMd]: {
    ".modal": {
      width: "424px",
    },
    ".logo-container": {
      marginTop: "24px",
      marginBottom: "24px",
    },
    ".logo": {
      maxHeight: "55px !important",
    },
    ".submit-btn": {
      width: "auto",
    },
  },
  [BACKGROUND_MEDIA_QUERIES.w.gteLg]: {
    ".modal": {
      width: "590px",
      padding: "43px 40px 28px 40px",
      background: COLORS.white,
      border: `1px solid ${COLORS.borders}`,
      boxSizing: "border-box",
      boxShadow: `0px 4px 20px ${COLORS.boxShadowLight}`,
      borderRadius: "2px",
    },
    ".title": {
      fontSize: "22px !important",
    },
  },
  [BACKGROUND_MEDIA_QUERIES.w.gteXl]: {
    ".logo-container": {
      marginTop: "40px",
      marginBottom: "40px",
    },
    ".logo": {
      maxHeight: "75px !important",
    },
    ".modal": {
      width: "671px",
      padding: "68px 80px 28px 80px",
      "&.propertyModal": {
        paddingTop: "45px",
      },
    },
  },
  [BACKGROUND_MEDIA_QUERIES.w.gteXxl]: {
    ".powered-by": {
      display: "flex",
      "&.inner": {
        display: "none",
      },
    },
    ".footer": {
      display: "block",
    },
  },
});

const Modal = styled("div")({
  background: "none",
  border: "none",
  position: "relative",
  width: "272px",
  padding: 0,
});

const Header = styled("div")((props) => ({
  marginBottom: "28px",
  ...(props.center && {
    h1: {
      textAlign: "center",
    },
  }),
}));

const Subtitle = styled("h4")({
  fontWeight: 400,
  ".ant-steps .ant-steps-item-title": {
    fontWeight: 400,
  },
  ".strong-title": {
    fontWeight: 500,
  },
});

const NameContainer = styled("div")({
  display: "flex",
  alignItems: "flex-start",
  width: "100%",
  marginBottom: "32px",
  "& > div": {
    width: "50%",
  },
  ".name-divider": {
    margin: "0 10px",
  },
  [MEDIA_QUERIES.w.ltMd]: {
    flexDirection: "column",
    "& > div": {
      width: "100%",
    },
    ".name-divider": {
      margin: "0 0 32px 0",
    },
  },
});

const FinalInstructionIcon = styled(Icon.CheckIcon)({
  width: "20px",
  height: "20px",
  marginLeft: "-4px",
  marginTop: "-3px",
});

const VeroLogo = styled(Icon.LogoIcon)({
  height: "10px",
  width: "51px",
});

const SubmitButtonWrapper = styled("div")({
  ...CenteredFlexPartial,
  marginTop: "28px",
  marginBottom: "28px",
});

const SearchContainer = styled("div")({
  marginTop: "15px",
  padding: " 0 24px",
  [MEDIA_QUERIES.w.ltSm]: {
    padding: 0,
  },
});

const ListHeader = styled("div")({
  backgroundColor: COLORS.table.alt.header.background,
  color: COLORS.typography.text,
  padding: "10px",
  border: `1px solid ${COLORS.borders}`,
});

const ListContainer = styled("div")({
  marginBottom: "20px",
  maxHeight: "50vh",
  overflow: "auto",
  [BACKGROUND_MEDIA_QUERIES.w.gteXxl]: {
    maxHeight: "45vh",
  },
});

const ListItem = styled(List.Item)({
  padding: "15px 20px",
});

const Text = styled("p")({
  paddingRight: "5px",
});

export {
  LoadingSpinner,
  Container,
  ContentContainer,
  Modal,
  Header,
  Subtitle,
  NameContainer,
  FinalInstructionIcon,
  VeroLogo,
  SubmitButtonWrapper,
  SearchContainer,
  ListHeader,
  ListContainer,
  ListItem,
  Text,
};
