import _ from 'lodash'
import React, { useState } from 'react'
import { intervalToDuration, formatDuration } from 'date-fns'
import { Row, Col } from 'antd-latest'
import { isManagerApp } from 'shared/utils/auth'
import { useApplicantWorkflowMutations } from 'shared/hooks/api'
import { RedoIcon } from 'shared/components/vega/icons'
import { Toggle } from 'shared/components/vega/Toggle'
import { HistoryEntries } from 'shared/components/vega/ExpressionEngine'
import { Timestamp } from 'shared/components/vega/Timestamp'
import { ServiceCard, ServiceHeader } from 'shared/components/vega/Service'
import { resolveWorkflowStatus, resolveWorkflowErrorMessage, getTaskDisplayName } from './utils'
import TaskSteps from './TaskSteps'

const ApplicantWorkflow = ({ applicantWorkflow, card = false, minimal = false }) => {
  const [showHistory, setShowHistory] = useState(false)
  const canShowHistory = isManagerApp()
  const resolvedShowHistory = canShowHistory && showHistory
  const { runApplicantWorkflow, runApplicantWorkflowLoading } = useApplicantWorkflowMutations()

  const historyEntries = _.chain(applicantWorkflow?.history).reject((entry) => _.startsWith(entry?.formula, '@')).value()
  const status = resolveWorkflowStatus(applicantWorkflow)

  const timestamps = _.chain(applicantWorkflow?.timings).values().filter(_.isNumber).sortBy().value()
  const start = _.first(timestamps) || _.now()
  const end = status === 'in-progress' ? _.now() : (_.last(timestamps) || start)
  const duration = formatDuration(intervalToDuration({ start, end }))

  const resolvedErrorMessage = resolveWorkflowErrorMessage(applicantWorkflow)
  const currentStep = getTaskDisplayName(_.first(applicantWorkflow?.tasks?.next))
  const title = _.chain(status).lowerCase().capitalize().value()

  if (minimal) {
    return (
      <div className='vega flex gap small column'>
        <div className='vega title small'>{currentStep}</div>
        <Timestamp timestamp={applicantWorkflow?.timestamp} />
      </div>
    )
  }

  const body = <>
    {applicantWorkflow?.error && <span className='vega info error'>{resolvedErrorMessage}</span>}
    {duration && <span className='vega info'>{duration}</span>}

    {currentStep && <div>
      <div className='vega flex spread centered'>
        <div className='vega label'>step:</div>
        {applicantWorkflow?.applicant &&
          (runApplicantWorkflowLoading
              ? <span className='vega loader inline' />
              : (
                <RedoIcon
                  className='vega button icon small'
                  onClick={e => {
                    e.preventDefault()
                    return runApplicantWorkflow(applicantWorkflow?.applicant)
                  }}
                />
              )
          )
        }
      </div>

      <div className='vega name'>{currentStep}</div>
    </div>}
  </>

  return (
    <div className='vega container'>
      {card && <ServiceCard
        status={status}
        label='workflow'
        value={title}
        timestamp={applicantWorkflow?.timestamp}
      >{body}</ServiceCard>}

      {!card && <ServiceHeader
        status={status}
        title={title}
        timestamp={applicantWorkflow?.timestamp}
      >{body}</ServiceHeader>}

      {applicantWorkflow?.id && !applicantWorkflow?.error && !card && <div className='vega content'>
        <div className='vega actions'>
          <Toggle label='Show Details' checked={showHistory} onChange={() => setShowHistory(!showHistory)} />
        </div>

        <Row>
          <Col span={showHistory ? 12 : 24}>
            <TaskSteps applicantWorkflow={applicantWorkflow} />
          </Col>

          {resolvedShowHistory && <Col span={12}>
            <HistoryEntries entries={historyEntries} detailed />
          </Col>}
        </Row>
      </div>}
    </div>
  )
}

export default ApplicantWorkflow
