import React from "react";

import { DocumentInfo } from "shared/components/DocumentViewer";

import { Layout, Content, SideBar, StyledRow } from "./styled";

export interface ILeasePreviewLayout {
  DocumentInfoContent?: React.ReactNode;
  ViewerContent: React.ReactNode;
}

const LeasePreviewLayout = ({
  DocumentInfoContent = undefined,
  ViewerContent,
}: ILeasePreviewLayout) => (
  <Layout>
    <StyledRow>
      <Content span={DocumentInfoContent ? 18 : 24}>{ViewerContent}</Content>
      {DocumentInfoContent && (
        <SideBar span={6}>
          <DocumentInfo>{DocumentInfoContent}</DocumentInfo>
        </SideBar>
      )}
    </StyledRow>
  </Layout>
);

export default LeasePreviewLayout;
