import React, { ReactNode } from "react";

import Icon from "shared/components/Icon";
import { Popover, PopoverContent } from "shared/components/Popover";

interface ConditionalTextProps {
  text?: ReactNode | any;
  placeholderPopoverText?: ReactNode;
  className?: string;
}

const ConditionalText = ({
  text = undefined,
  placeholderPopoverText = undefined,
  className = undefined,
}: ConditionalTextProps) => {
  return (
    text || (
      <Popover
        className={className}
        disabled={!placeholderPopoverText}
        content={<PopoverContent>{placeholderPopoverText}</PopoverContent>}
      >
        <Icon.GrayQuestionMark />
      </Popover>
    )
  );
};

export default ConditionalText;
