import React from "react";

import {
  UploadFile,
  UploadChangeParam,
  RcFile,
  ItemRender,
} from "antd-latest/lib/upload/interface";

import Icon from "shared/components/Icon";

import { AttachmentFile } from "./interfaces";
import { Upload, UploadLink } from "./styled";

interface UploadWallProps extends React.HTMLAttributes<HTMLDivElement> {
  maxCount: number;
  acceptFormats: string[];
  disabled: boolean;
  inReadOnlyMode: boolean;
  fileList: UploadFile<AttachmentFile>[];
  handleChange: (info: UploadChangeParam) => void;
  customRequest: (options) => void;
  onRemove: (file: UploadFile) => boolean | Promise<void>;
  beforeUpload: (file: RcFile, FileList: RcFile[]) => any;
  itemRender?: ItemRender;
}

const UploadWall = ({
  maxCount,
  acceptFormats,
  disabled,
  inReadOnlyMode,
  fileList,
  handleChange,
  customRequest,
  onRemove,
  beforeUpload,
  itemRender,
  ...props
}: UploadWallProps) => (
  <div className="clearfix" {...props}>
    <Upload
      listType="picture-card"
      fileList={fileList}
      onChange={handleChange}
      customRequest={customRequest}
      onRemove={disabled ? () => false : onRemove}
      accept={acceptFormats.join()}
      beforeUpload={beforeUpload}
      showUploadList={{
        showRemoveIcon: !disabled && !inReadOnlyMode,
        removeIcon: (file: AttachmentFile) =>
          file.isRestricted ? <div id="removeIcon" /> : undefined,
      }}
      disabled={disabled}
      itemRender={itemRender}
    >
      {!inReadOnlyMode && fileList.length < maxCount ? (
        <div>
          <Icon.UploadAttachIcon />
          <UploadLink disabled={disabled}>Upload</UploadLink>
        </div>
      ) : null}
    </Upload>
  </div>
);

export default UploadWall;
