import React from "react";

import { Menu } from "antd";
import keyBy from "lodash/keyBy";

import { useActiveCompany } from "shared/hooks";

import {
  MenuItemInnerContentProps,
  MenuItemProps,
  SidebarItem,
} from "../interfaces";

import { Avatar, Badge as StyledBadge } from "./styled";

export const MenuItemInnerContent = ({
  item,
  showText,
  hoveredItem,
  activeItemKey,
}: MenuItemInnerContentProps) => {
  const { activeCompany } = useActiveCompany();
  return (
    <div className="item-inner-content">
      {item.notifications && <StyledBadge>{item.notifications}</StyledBadge>}
      {item.icon && (
        <item.icon
          className="item-icon"
          hovered={hoveredItem === item.key || activeItemKey === item.key}
        />
      )}
      {item.avatar && activeCompany && (
        <Avatar
          size="default"
          src={activeCompany?.logo}
          alt={item.avatar.alt}
        />
      )}
      {showText && (
        <div className="item-text-container">
          {item.textPrefixIcon && (
            <item.textPrefixIcon className="text-prefix-icon" />
          )}
          <span className="item-text">{item.text}</span>
        </div>
      )}
    </div>
  );
};

export const MenuItem = ({
  item,
  showText,
  setHoveredItem,
  hoveredItem,
  activeItemKey,
  ...componentLibProps
}: MenuItemProps) => {
  return (
    <Menu.Item
      key={item.key}
      onMouseEnter={() => setHoveredItem(item.key)}
      onMouseLeave={() => setHoveredItem(undefined)}
      title={item.text}
      {...componentLibProps}
    >
      <MenuItemInnerContent
        item={item}
        showText={showText}
        hoveredItem={hoveredItem}
        activeItemKey={activeItemKey}
      />
    </Menu.Item>
  );
};

export const SubMenu = ({
  item,
  showText,
  setHoveredItem,
  hoveredItem,
  activeItemKey,
  ...componentLibProps
}: MenuItemProps) => {
  const isChildActive = !!(item.items || []).find(
    ({ key }) => key === activeItemKey
  );
  const parentActiveKey = isChildActive ? item.key : activeItemKey;
  const subMenuClass = isChildActive ? "submenu-item-child-selected" : "";
  return (
    <Menu.SubMenu
      className={subMenuClass}
      key={item.key}
      onTitleMouseEnter={() => setHoveredItem(item.key)}
      onTitleMouseLeave={() => setHoveredItem(undefined)}
      title={
        <MenuItemInnerContent
          item={item}
          showText={showText}
          hoveredItem={hoveredItem}
          activeItemKey={parentActiveKey}
        />
      }
      {...componentLibProps}
    >
      {(item.items || []).map((subItem) => (
        <Menu.Item
          key={subItem.key}
          onMouseEnter={() => setHoveredItem(subItem.key)}
          onMouseLeave={() => setHoveredItem(undefined)}
          title={subItem.text}
        >
          <span className="item-text">{subItem.text}</span>
        </Menu.Item>
      ))}
    </Menu.SubMenu>
  );
};

export const extendChildrenWithParent = (children, parentKey) => {
  return (children || []).map((c) => ({ ...c, parentKey }));
};

export const extendItems = (items: SidebarItem[]): SidebarItem[] => {
  return (items || []).map((item) => {
    return {
      ...item,
      ...(item.items?.length > 1
        ? { items: extendChildrenWithParent(item.items, item.key) }
        : {}),
    };
  });
};

export const getFlattenedItemsByKey = (
  items: SidebarItem[]
): { [key: string]: SidebarItem } => {
  const flatItems = (items || [])
    .map((item) => [item, ...(item.items || [])])
    .flat();
  return keyBy(flatItems, "key");
};
