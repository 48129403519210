import React from "react";

import styled from "@emotion/styled";
import PropTypes from "prop-types";

import FlexContainer from "shared/components/FlexContainer";
import Tile from "shared/components/Tile";
import { COLORS } from "shared/config/constants";
import {
  AlignedCenterFlexPartial,
  TextEllipsis,
} from "shared/styles/commonStyles";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Section = styled(({ height, noPaddingBottom, ...props }) => (
  <Tile {...props} />
))(({ height, noPaddingBottom }) => ({
  height: height || "750px",
  paddingTop: "24px",
  paddingBottom: noPaddingBottom ? 0 : "24px",
  marginBottom: "12px",
  ".mapping-content": {
    border: 0,
  },
}));

const SectionTitle = styled("h5")({
  fontSize: "22px",
  marginBottom: "16px",
  marginLeft: "24px",
});

const SectionSubTitle = styled.div({
  fontSize: "15px",
  marginBottom: "15px",
  div: {
    ...AlignedCenterFlexPartial,
    marginLeft: "24px",
    marginRight: "24px",
  },
  ".popover": {
    marginLeft: "5px",
  },
  ul: {
    li: {
      paddingLeft: "10px",
      marginBottom: "5px",
    },
  },
});

const SectionBody = styled.div({
  marginLeft: "24px",
});

const SectionTitleLightActionText = styled("span")({
  ...AlignedCenterFlexPartial,
  fontSize: "15px",
});

Section.propTypes = {
  noPaddingBottom: PropTypes.bool,
  height: PropTypes.string,
};

Section.defaultProps = {
  noPaddingBottom: undefined,
  height: undefined,
};

const SelectContainer = styled("div")({
  ...AlignedCenterFlexPartial,
  height: "55px",
  width: "355px",
  "&>*": {
    width: "100%",
  },
});

const RowLabelContainer = styled("div")({
  display: "inline-flex",
  alignItems: "center",
  height: "55px",
  'input[type="text"]': {
    marginLeft: "10px",
    width: "140px",
  },
  width: "300px",
  ".label-with-input": {
    ...TextEllipsis,
    maxWidth: "calc(100% - 140px)",
  },
  ".label-without-input": {
    ...TextEllipsis,
    maxWidth: "100%",
  },
  ".yardi-rent": {
    marginLeft: "30px",
    fontWeight: 400,
    color: COLORS.typography.text,
  },
});

const ColumnHeader = styled.span({
  marginRight: "10px",
});

const ColumnIsDefault = styled(FlexContainer)({
  marginLeft: "50px",
});

const ColumnVeroAgent = styled.div({
  height: "55px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  ".subtitle": {
    fontWeight: 400,
    fontSize: "12px",
  },
});

const YardiSectionAvailableUnitsWrapper = styled.div({
  ".description-cell": {
    display: "flex !important",
    width: "100%",
    alignItems: "center",
    ".rent-input": {
      width: "160px",
      marginLeft: "24px",
    },
    ".rent-tooltip": {
      marginLeft: "auto",
      paddingRight: "14px",
      paddingLeft: "10px",
    },
  },
});

const YardiGeneralSettingsWrapper = styled.div({
  ".switch": {
    marginBottom: "24px",
  },
});

const TableActionWrapper = styled.div({
  marginRight: "10px",
});

const DisabledTooltipText = styled.span({
  color: COLORS.gray1,
  cursor: "pointer",
});

const LinkedUnitsWrapper = styled.div({
  borderTop: `1px solid ${COLORS.borders}`,
  padding: "18px 24px 23px",
});

export {
  Section,
  SectionTitle,
  SectionSubTitle,
  SectionBody,
  SectionTitleLightActionText,
  SelectContainer,
  RowLabelContainer,
  ColumnHeader,
  ColumnIsDefault,
  ColumnVeroAgent,
  YardiSectionAvailableUnitsWrapper,
  YardiGeneralSettingsWrapper,
  TableActionWrapper,
  DisabledTooltipText,
  LinkedUnitsWrapper,
};
