import React, { useState, ReactNode } from "react";

import Icon from "shared/components/Icon";
import { BackLink } from "shared/components/Links";
import Logo from "shared/components/Logo";
import Tooltip from "shared/components/Tooltip";
import { UserProfilePopover } from "shared/components/User";

import {
  IconWrapper,
  Container,
  StyledMenu,
  MenuItem,
  LogoContainer,
  HeaderLeft,
  BackLinkContainer,
  HelpIcon,
} from "./styled";

export interface ICustomHeader {
  logoLink: string;
  backLink?: any;
  user?: any;
  headerAction?: ReactNode;
  extraControls?: ReactNode;
  [key: string]: any;
}

const CustomHeader = ({
  backLink = undefined,
  user,
  logoLink,
  headerAction = undefined,
  extraControls = undefined,
  ...props
}: ICustomHeader) => {
  const [state, setState] = useState<{
    profileHover?: boolean;
    bellHover?: boolean;
  }>({
    profileHover: false,
    bellHover: false,
  });

  const onSupport = () => window.open("https://support.sayvero.com", "_blank");

  return (
    <Container {...props}>
      <HeaderLeft>
        <LogoContainer to={logoLink}>
          <Logo />
        </LogoContainer>
        <BackLinkContainer>
          {backLink && <BackLink {...backLink} />}
        </BackLinkContainer>
      </HeaderLeft>
      {extraControls}
      <StyledMenu mode="horizontal">
        {headerAction && <MenuItem largeRight>{headerAction}</MenuItem>}
        <MenuItem large>
          <Tooltip title="Support">
            <HelpIcon onClick={onSupport} />
          </Tooltip>
        </MenuItem>
        <MenuItem large last>
          <UserProfilePopover user={user}>
            <IconWrapper
              onMouseEnter={() => setState({ profileHover: true })}
              onMouseLeave={() => setState({ profileHover: false })}
            >
              <Icon.ProfileIconWithChevron
                hovered={state.profileHover}
                active={state.profileHover}
              />
            </IconWrapper>
          </UserProfilePopover>
        </MenuItem>
      </StyledMenu>
    </Container>
  );
};

export default CustomHeader;
