import _ from "lodash";

import { toDate } from "../utils";

import { Eviction, EvictionRecordsResponse } from "./interfaces";

export const mapEvictions = (
  evictionRecords?: EvictionRecordsResponse,
  skipped?: boolean,
  enabled?: boolean
): Eviction | undefined => {
  const totalJudgementAmount =
    _.sumBy(_.get(evictionRecords, "evictionRecordGroup", []), (group) => {
      return _.sumBy(_.get(group, "records", []), "judgementAmount");
    }) / 100;

  return {
    records: evictionRecords?.totalEvictions,
    judgmentAmount: totalJudgementAmount,
    skipped,
    enabled,
    lastRecordState: evictionRecords?.lastRecordState,
    lastRecord: toDate(evictionRecords?.lastRecord?.date),
  };
};
