import styled from "@emotion/styled";

import BaseWell from "shared/components/Well";
import { COLORS } from "shared/config/constants";

export const FraudIndicatorsNumber = styled.span`
  color: ${COLORS.dangerRed};
`;

export const Well = styled(BaseWell)`
  padding: 16px 35px 4px 20px;
  background-color: ${COLORS.backgroundGrey2};

  .title {
    gap: 10px;
    color: ${COLORS.lightViolet};
  }

  .description {
    color: ${COLORS.lightViolet3};
    margin-left: 24px;
    margin-bottom: 16px;
  }
`;
