import { queryCache, useMutation } from "react-query";

import { AdditionalQuestionModel } from "manager/interfaces/api/additionalQuestion";
import api from "manager/lib/api";
import { NOTIFICATIONS } from "shared/config/constants";

import { openNotification } from "shared/utils/ui";

import { DEAL_QUERY_KEYS, GET_DEAL_SETUP } from "./dealSetupQueries";

export const useCreateQuestion = () => {
  const [mutate, response] = useMutation(
    (payload: AdditionalQuestionModel) => api.createQuestion(payload),
    {
      onSuccess: ({ objectId }) => {
        queryCache.invalidateQueries(DEAL_QUERY_KEYS.getDealSetup(objectId));
        queryCache.invalidateQueries(DEAL_QUERY_KEYS.getDefaultDealSetup());
        openNotification(
          `Additional question has been successfully created`,
          NOTIFICATIONS.info
        );
      },
      onError: () => {
        // TODO: Enable the notification here when we remove the onDialogSubmitError util.
        // openNotification(
        //   "Failed to create the additional question.",
        //   NOTIFICATIONS.error
        // );
      },
    }
  );

  return {
    createQuestion: mutate,
    response,
  };
};

export const useUpdateQuestion = () => {
  const [mutate, response] = useMutation(
    (payload: AdditionalQuestionModel) => api.updateQuestion(payload),
    {
      onSuccess: ({ objectId }) => {
        queryCache.invalidateQueries(DEAL_QUERY_KEYS.getDealSetup(objectId));
        queryCache.invalidateQueries(DEAL_QUERY_KEYS.getDefaultDealSetup());
        openNotification(
          `Additional question has been successfully updated`,
          NOTIFICATIONS.info
        );
      },
      onError: () => {
        // TODO: Enable the notification here when we remove the onDialogSubmitError util.
        // openNotification(
        //   "Failed to update the additional question.",
        //   NOTIFICATIONS.error
        // );
      },
    }
  );

  return {
    updateQuestion: mutate,
    response,
  };
};

export const useDeleteAdditionalQuestion = () => {
  const [mutate, status] = useMutation((id: number) => api.deleteQuestion(id), {
    onSuccess: () => {
      queryCache.invalidateQueries(GET_DEAL_SETUP);
      queryCache.invalidateQueries(DEAL_QUERY_KEYS.getDefaultDealSetup());
      openNotification(
        `The additional question has been successfully deleted`,
        NOTIFICATIONS.info
      );
    },
    onError: () => {
      openNotification(
        "Failed to delete the additional question.",
        NOTIFICATIONS.error
      );
    },
  });

  return {
    deleteAdditionalQuestion: mutate,
    isDeleteLoading: status.isLoading,
  };
};

export const useToggleAdditionalQuestionActive = (dealSetupId: number) => {
  const [
    toggleAdditionalQuestionActive,
    { isLoading: isToggleAdditionalQuestionActiveLoading },
  ] = useMutation(api.toggleAdditionalQuestionActive, {
    onSuccess: () => {
      queryCache.invalidateQueries(DEAL_QUERY_KEYS.getDealSetup(dealSetupId));
      queryCache.invalidateQueries(DEAL_QUERY_KEYS.getDefaultDealSetup());
    },
    onError: () => {
      openNotification(
        "Failed to change additional question",
        NOTIFICATIONS.error
      );
    },
  });
  return {
    toggleAdditionalQuestionActive,
    isToggleAdditionalQuestionActiveLoading,
  };
};
