import React from "react";

import ConfettiExplosion from "react-confetti-explosion";

export const Confetti = ({ show }: { show: boolean }) => {
  return (
    show && (
      <div
        style={{
          top: "50%",
          right: "50%",
          left: "50%",
          bottom: "50%",
          position: "fixed",
          pointerEvents: "none",
          overflow: "visible",
          zIndex: 2000, // show above modals which are at 1000.
        }}
      >
        <ConfettiExplosion
          {...{
            force: 0.6,
            duration: 5000,
            particleCount: 200,
            height: 1600,
            width: 1600,
            colors: ["#18A4EF", "#8BD2F7", "#8052D0", "#E0D1FC"],
          }}
        />
      </div>
    )
  );
};
