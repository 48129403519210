import React, { useState } from "react";

import { Helmet } from "react-helmet";

import StepConfirmation from "renter/components/MagicLink/StepConfirmation";
import { LeaseRentOptionsPage } from "renter/pages/LeaseRentOptions";
import { MagicLinkV2InvalidPage } from "renter/pages/MagicLink/MagicLinkInvalidPage";
import PropertiesPortal from "renter/pages/MagicPortal/PropertiesPortal";
import UnitsPortal from "renter/pages/MagicPortal/UnitsPortal";
import MainBackground from "shared/components/Backgrounds/MainBackground";

import { DealType } from "shared/config/constants";

import { MagicLinkGetStartedProps } from "./interfaces";
import {
  MAGIC_LINK_LANDING_STEP,
  MAGIC_LINK_PAGES,
} from "./magicLinkLanding.config";
import StepError from "./StepError";
import StepLanding from "./StepLanding";

import {
  LoadingSpinner,
  Container,
  ContentContainer,
  VeroLogo,
  Modal,
} from "./styled";

const title = "Let's get started";

const MagicLinkGetStarted = ({
  submit,
  currentStep,
  logoUrl,
  isCompanyProfileLoading,
  submittedEmail,
  application,
  signupUrl,
  propertyName,
  unitName,
  dealType = DealType.SCREENING,
  formikRef,
  isLroEnabled,
  companyMagicFragment,
  propertyMagicFragment,
  unitMagicFragment,
  errorFetchingDetails,
  showLanding,
  isMagicPortalEnabled,
}: MagicLinkGetStartedProps) => {
  const [submitting, setSubmitting] = useState(false);

  const propertyUnitName = [propertyName, unitName]
    .filter((item) => Boolean(item))
    .join(", ");

  let pageToDisplay;
  if (errorFetchingDetails && !isCompanyProfileLoading) {
    pageToDisplay = MAGIC_LINK_PAGES.INVALID_PAGE;
  } else if (
    isMagicPortalEnabled &&
    !showLanding &&
    companyMagicFragment &&
    !propertyMagicFragment
  ) {
    pageToDisplay = MAGIC_LINK_PAGES.PROPERTIES_PORTAL;
  } else if (
    isMagicPortalEnabled &&
    !showLanding &&
    companyMagicFragment &&
    propertyMagicFragment &&
    !unitMagicFragment
  ) {
    pageToDisplay = MAGIC_LINK_PAGES.UNITS_PORTAL;
  } else if (
    isLroEnabled &&
    !showLanding &&
    propertyMagicFragment &&
    unitMagicFragment
  ) {
    pageToDisplay = MAGIC_LINK_PAGES.LEASE_RENT_OPTIONS_PAGE;
  } else if (!submitting && !isLroEnabled && unitMagicFragment && application) {
    setSubmitting(true);
    submit({ email: submittedEmail });
    pageToDisplay = MAGIC_LINK_PAGES.MAGIC_LINK_LANDING_PAGE;
  } else {
    pageToDisplay = MAGIC_LINK_PAGES.MAGIC_LINK_LANDING_PAGE;
  }

  return (
    <>
      {pageToDisplay === MAGIC_LINK_PAGES.INVALID_PAGE && (
        <MagicLinkV2InvalidPage />
      )}

      {pageToDisplay === MAGIC_LINK_PAGES.PROPERTIES_PORTAL && (
        <PropertiesPortal companyMagicFragment={companyMagicFragment} />
      )}

      {pageToDisplay === MAGIC_LINK_PAGES.UNITS_PORTAL && (
        <UnitsPortal
          companyMagicFragment={companyMagicFragment}
          propertyMagicFragment={propertyMagicFragment}
        />
      )}

      {pageToDisplay === MAGIC_LINK_PAGES.LEASE_RENT_OPTIONS_PAGE && (
        <LeaseRentOptionsPage
          propertyMagicFragment={propertyMagicFragment}
          unitMagicFragment={unitMagicFragment}
        />
      )}

      {pageToDisplay === MAGIC_LINK_PAGES.MAGIC_LINK_LANDING_PAGE && (
        <>
          <Helmet>
            <title>{title}</title>
          </Helmet>
          <Container>
            <ContentContainer>
              <div className="logo-container">
                {logoUrl && <img src={logoUrl} alt="Logo" className="logo" />}
                {propertyUnitName && (
                  <p className="logo-alt">{propertyUnitName}</p>
                )}
              </div>
              <Modal className="modal">
                {isCompanyProfileLoading && (
                  <LoadingSpinner data-testid="loader" />
                )}
                {!isCompanyProfileLoading && (
                  <>
                    {currentStep === MAGIC_LINK_LANDING_STEP.LANDING_STEP && (
                      <StepLanding
                        propertyName={propertyName}
                        submit={submit}
                        dealType={dealType}
                        formikRef={formikRef}
                      />
                    )}
                    {currentStep ===
                      MAGIC_LINK_LANDING_STEP.CONFIRMATION_STEP && (
                      <StepConfirmation
                        email={submittedEmail}
                        signupUrl={signupUrl}
                        dealType={dealType}
                      />
                    )}
                    {currentStep === MAGIC_LINK_LANDING_STEP.ERROR_STEP && (
                      // @ts-ignore
                      <StepError dealType={dealType} />
                    )}
                  </>
                )}
              </Modal>
              <div className="powered-by">
                Powered by <VeroLogo className="logo" data-testid="vero-logo" />
              </div>
            </ContentContainer>
            <MainBackground />
          </Container>
        </>
      )}
    </>
  );
};

export default MagicLinkGetStarted;
