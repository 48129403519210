import React from "react";

import { useFormikContext } from "formik";
import { string } from "yup";

import {
  useUpdateSurveyQuestionOrdering,
  useDeleteSurveyQuestion,
  useUpdateSurveyQuestion,
  useCreateSurveyQuestion,
} from "manager/hooks/api";
import ConfirmationModal from "shared/components/Modals/ConfirmationModal";
import SingleInputModal, {
  INPUT_MODE,
} from "shared/components/Modals/SingleInputModal";
import Spacer from "shared/components/Spacer";
import { SmallText, WeakText } from "shared/components/Typography";
import { useActiveCompany } from "shared/hooks";
import useModal from "shared/hooks/useModal";
import { SurveyModel, SurveyQuestionModel } from "shared/interfaces/api";

import { verificationOfResidency } from "../constants";

import { SurveyQuestionModalMode } from "./interfaces";
import VorQuestionsTable from "./VorQuestionsTable";

interface VorQuestionsTableContainerProps {
  isSurveyLoading?: boolean;
  survey: SurveyModel;
  disabled?: boolean;
  forSettingsLabel: string;
}

const VorQuestionsTableContainer = ({
  isSurveyLoading,
  survey,
  disabled,
  forSettingsLabel,
}: VorQuestionsTableContainerProps) => {
  const { activeCompany } = useActiveCompany();

  const { openModalDialog } = useModal();

  const { createSurveyQuestion } = useCreateSurveyQuestion();
  const { updateSurveyQuestion } = useUpdateSurveyQuestion();
  const { deleteSurveyQuestion } = useDeleteSurveyQuestion();
  const {
    updateSurveyQuestionOrdering,
    isUpdateSurveyQuestionOrderingLoading,
  } = useUpdateSurveyQuestionOrdering();

  const { setFieldValue } = useFormikContext();

  const openDeleteSurveyQuestionConfirmation = (questionId: number) =>
    openModalDialog(ConfirmationModal, {
      title: `VoR Delete Survey Question`,
      submitButtonLabel: "Okay, got it",
      message: "You are attempting to permanently delete this survey question.",
      description: `This question will no longer appear on survey for ${activeCompany?.name}.`,
      onConfirm: () => {
        const isLastRemainingQuestion = survey?.surveyQuestions.length === 1;
        if (isLastRemainingQuestion) {
          setFieldValue(verificationOfResidency, false);
        }
        deleteSurveyQuestion({ surveyId: survey?.id, questionId });
      },
    });

  const openSurveyQuestionModal = (
    mode: SurveyQuestionModalMode,
    surveyQuestion: SurveyQuestionModel
  ) =>
    openModalDialog(SingleInputModal, {
      title: "VoR Survey Questions",
      subtitle: (
        <>
          For <b>{forSettingsLabel}</b>
        </>
      ),
      inputDescription: (
        <>
          <WeakText>
            All questions are listed as an opinion scale from 1-10
          </WeakText>
          <br />
          <SmallText strong>
            (1 = Disagree with the statement, 10 = Agree with the statement)
          </SmallText>
          <Spacer />
        </>
      ),
      confirmLabel: "Save",
      valueValidationSchema: string().trim().required("Question is required"),
      inputMode: INPUT_MODE.multiline,
      inputPlaceholder: "Enter the text of your statement here...",
      initialValue: surveyQuestion?.question ?? "",
      maxLength: 250,
      showCount: true,
      submit: ({ value: question }) => {
        const newSurveyQuestion = {
          ...surveyQuestion,
          surveyId: survey?.id,
          question,
        };
        if (mode === SurveyQuestionModalMode.CREATE) {
          createSurveyQuestion(newSurveyQuestion);
        } else if (mode === SurveyQuestionModalMode.EDIT) {
          updateSurveyQuestion(newSurveyQuestion);
        }
      },
    });

  return (
    <VorQuestionsTable
      loading={isSurveyLoading || isUpdateSurveyQuestionOrderingLoading}
      disabled={disabled}
      survey={survey}
      openSurveyQuestionModal={openSurveyQuestionModal}
      updateSurveyQuestionOrdering={updateSurveyQuestionOrdering}
      openDeleteSurveyQuestionConfirmation={
        openDeleteSurveyQuestionConfirmation
      }
    />
  );
};

export default VorQuestionsTableContainer;
