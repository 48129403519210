import React from "react";

import PropTypes from "prop-types";

import Tile from "shared/components/Tile";
import { VERIFICATION_STATUSES } from "shared/config/constants";
import { applicantTypesPropType } from "shared/utils/propTypes";

import ApplicationSummary from "./components/ApplicationSummary";


const Summary = ({
  label,
  otherApplicants,
  applicantTypes,
  applicationSummary,
  showOtherApplicants,
  OtherApplicantsList,
  title,
  isEvictionEnabled,
  hideCriminalRecords,
  downloadAction,
  otherApplicantsAction,
}) => {
  const incomeVerificationStatus = applicationSummary?.income?.status;
  const assetsVerificationStatus = applicationSummary?.assets?.status;

  return (
    <>
      <ApplicationSummary
        title={title}
        label={label}
        annualIncome={applicationSummary?.income?.value}
        incomeRentMultiple={applicationSummary?.incomeRentMultiple}
        totalAssets={applicationSummary?.assets?.value}
        incomeEdited={incomeVerificationStatus === VERIFICATION_STATUSES.EDITED}
        incomeVerified={
          incomeVerificationStatus === VERIFICATION_STATUSES.VERIFIED
        }
        assetsEdited={assetsVerificationStatus === VERIFICATION_STATUSES.EDITED}
        skippedIncome={applicationSummary?.skippedIncome}
        skippedAsset={applicationSummary?.skippedAsset}
        skippedBgcc={applicationSummary?.skippedBgcc}
        averageCreditScore={applicationSummary?.creditScore}
        criminalRecords={applicationSummary?.criminalRecords}
        notes={applicationSummary?.notes}
        isEvictionEnabled={isEvictionEnabled}
        hideCriminalRecords={hideCriminalRecords}
        evictions={applicationSummary?.evictions}
        assetSkippedReason={applicationSummary?.assetSkippedReason}
        assetBankName={applicationSummary?.assetBankName}
        download={downloadAction}
      />
      {showOtherApplicants && (
        <Tile
          header={{
            title: "Other Applicants",
            collapsibleOnMobile: true,
            secondaryAction: otherApplicantsAction,
          }}
        >
          {OtherApplicantsList && (
            <OtherApplicantsList
              applicants={otherApplicants}
              applicantTypes={applicantTypes}
            />
          )}
        </Tile>
      )}
    </>
  );
};

Summary.propTypes = {
  otherApplicants: PropTypes.arrayOf(PropTypes.object),
  applicantTypes: applicantTypesPropType.isRequired,
  applicationSummary: PropTypes.object,
  showOtherApplicants: PropTypes.bool,
  label: PropTypes.shape({
    type: PropTypes.string,
    text: PropTypes.string,
  }),
  OtherApplicantsList: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  isEvictionEnabled: PropTypes.bool.isRequired,
  hideCriminalRecords: PropTypes.bool,
  downloadAction: PropTypes.shape({
    description: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.elementType,
    disabled: PropTypes.bool,
    nowrap: PropTypes.bool,
  }),
  otherApplicantsAction: PropTypes.node,
};

Summary.defaultProps = {
  otherApplicants: [],
  applicationSummary: {},
  label: {},
  showOtherApplicants: false,
  hideCriminalRecords: false,
  downloadAction: undefined,
  otherApplicantsAction: undefined,
};

export default Summary;
