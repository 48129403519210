import React from "react";

import { useHistory } from "react-router-dom";

import ROUTES from "renter/config/routes";

import { useWithdrawApplication } from "shared/hooks/api";
import useModal from "shared/hooks/useModal";

import EditApplicationModal from "./EditApplication";

interface EditApplicationContainerProps {
  applicationId: number;
  apartment: string;
}

const EditApplicationContainer = ({
  applicationId,
  apartment,
}: EditApplicationContainerProps) => {
  const { closeActiveModalDialog } = useModal();
  const history = useHistory();
  const { withdrawApplication, isWithdrawApplication } =
    useWithdrawApplication(applicationId);

  const submit = () =>
    withdrawApplication(null, {
      onSuccess: async () => {
        await closeActiveModalDialog();
        history.push(ROUTES.applications);
      },
    });

  return (
    <EditApplicationModal
      submit={submit}
      loading={isWithdrawApplication}
      apartment={apartment}
    />
  );
};

export default EditApplicationContainer;
