import React, { ReactElement } from "react";

import Tooltip from "shared/components/Tooltip";

export interface CommonVerificationItemProps {
  children: ReactElement;
  message?: string;
}

const CommonVerificationItem = ({
  children,
  message = undefined,
}: CommonVerificationItemProps) => (
  <Tooltip title={message} placement="topLeft">
    <span className="verification-icon">{children}</span>
  </Tooltip>
);

export default CommonVerificationItem;
