import styled from "@emotion/styled";

import { Modal } from "shared/components/Modal";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

interface ModalProps {
  isMobile: boolean;
}
const StyledModal = styled(Modal)<ModalProps>`
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    width: 480px;
    padding: 40px 32px;
    border-radius: 30px;
    .phone-number {
      font-size: 16px;
    }
    .otpField {
      input#otpCode {
        border-color: ${COLORS.lightPurple} !important;
      }
      span.error-message {
        margin-left: 16px;
        color: ${COLORS.primary} !important;
      }
    }
    ${({ isMobile }) =>
      `
      ${MEDIA_QUERIES.w.ltMd} {
        width: ${isMobile ? "100%" : "480px"}
      }
    `}
  }
  .ant-modal-header {
    padding: 0;
  }
  .ant-modal-footer {
    padding: 0;
  }
`;

export { StyledModal };
