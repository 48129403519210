import React from "react";

import { useHistory } from "react-router-dom";

import { useCreateUnitListing } from "manager/hooks/api";
import useModal from "shared/hooks/useModal";
import { onDialogSubmitError } from "shared/utils/api";

import CreateUnitListingModal from "./CreateUnitListingModal";

const CreateUnitListingModalContainer = () => {
  const history = useHistory();
  const { createUnitListing } = useCreateUnitListing();
  const { currentModalContext, selfApi } = useModal();

  const submit = (values, formikApi) =>
    createUnitListing(values, {
      onSuccess: selfApi.closeDialog,
      onError: onDialogSubmitError(formikApi, history),
    });

  return (
    <CreateUnitListingModal context={currentModalContext} submit={submit} />
  );
};

export default CreateUnitListingModalContainer;
