import { createAction } from "redux-actions";

import * as types from "./types";

export const receiveRequiredAttachments = createAction(
  types.RECEIVE_REQUIRED_ATTACHMENTS,
  ({ items, objectId, contentType }) => ({ items, objectId, contentType })
);

export const removeRequiredAttachments = createAction(
  types.REMOVE_REQUIRED_ATTACHMENTS,
  ({ ids, objectId, contentType }) => ({ ids, objectId, contentType })
);
