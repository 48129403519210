export enum FeeType {
  applicationFee = "application_fee",
  holdingDepositFee = "holding_deposit_fee",
  stripeProcessingFee = "processing_fee",
}

export enum ApplicantPaymentStatus {
  created = "CREATED",
  unpaid = "UNPAID",
  charged = "CHARGED",
  paid = "PAID",
  refunded = "REFUNDED",
  // eslint-disable-next-line camelcase
  partially_refunded = "PARTIALLY_REFUNDED",
  disputed = "DISPUTED",
  canceled = "CANCELED",
}

export enum PaymentPoint {
  beforeApplicationSubmit = "BEFORE_APPLICATION_SUBMIT",
  afterApplicationApprove = "AFTER_APPLICATION_APPROVE",
}

export enum PaymentStep {
  BEFORE_APPLICATION_SUBMIT = "before-application-submit",
  AFTER_APPLICATION_APPROVE = "after-application-approve",
}

export enum PAYMENT_STATUS {
  CREATED = "created",
  UNPAID = "unpaid",
  CHARGED = "charged",
  PAID = "paid",
  REFUNDED = "refunded",
  PARTIALLY_REFUNDED = "partially-refunded",
  DISPUTED = "disputed",
  CANCELED = "canceled",
}

export const UNPAID_STATUS = [
  PAYMENT_STATUS.CREATED,
  PAYMENT_STATUS.CHARGED,
  PAYMENT_STATUS.UNPAID,
];

export const PAST_PAID_STATUS = [
  PAYMENT_STATUS.PAID,
  PAYMENT_STATUS.REFUNDED,
  PAYMENT_STATUS.PARTIALLY_REFUNDED,
  PAYMENT_STATUS.DISPUTED,
]
