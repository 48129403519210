import React from "react";

import dayjs from "dayjs";

import Icon from "shared/components/Icon";
import { IconLink, UnderlineLink } from "shared/components/Links";
import Table from "shared/components/Table";
import Tag from "shared/components/Tag";
import Tooltip from "shared/components/Tooltip";
import { SmallText } from "shared/components/Typography";
import { DATE_FORMAT } from "shared/config/constants";
import { downloadDocument } from "shared/utils/ui";

import { DocumentsTableProps } from "../interfaces";
import { TagContainer, InfoIcon, TooltipContainer } from "../styled";

const DocumentsTable = ({
  documents = [],
  loading,
  openAssignDocumentModal,
  openUnassignDocumentModal,
  openPreviewDocumentModal,
  handleDeleteDocument,
}: DocumentsTableProps) => (
  <Table
    alt
    loading={loading}
    columns={[
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Type",
        dataIndex: "typeDisplayName",
        key: "typeDisplayName",
      },
      {
        title: "Tags",
        dataIndex: "tags",
        key: "tags",
      },
      {
        title: "Companies Assigned",
        dataIndex: "companiesAssigned",
        key: "companiesAssigned",
      },
      {
        title: "Date Uploaded",
        dataIndex: "createdAt",
        key: "createdAt",
      },
    ]}
    columnBreakpoints={{
      xs: {
        column1: "name",
        column2: "typeDisplayName",
      },
      sm: ["name", "typeDisplayName", "tags"],
      md: ["name", "typeDisplayName", "tags", "companiesAssigned"],
    }}
    placeholder={{
      Icon: Icon.NoDataIcon,
      label: "Documents",
    }}
    showActionColumn
    dropdownItems
    rows={documents?.map((document) => ({
      key: document.id,
      values: [
        <UnderlineLink onClick={() => openPreviewDocumentModal(document)}>
          {document.name}
        </UnderlineLink>,
        <>
          <div>{document.typeDisplayName}</div>
          {document.riderTypeDisplayName && (
            <SmallText>({document.riderTypeDisplayName})</SmallText>
          )}
        </>,
        <TagContainer>
          {document.tags.map((tag) => (
            <div key={tag.id}>
              <Tag>{tag.name}</Tag>
            </div>
          ))}
        </TagContainer>,
        <TooltipContainer>
          <b>{document.companiesAssigned.length}</b>
          {document.companiesAssigned.length > 0 && (
            <Tooltip
              title={
                <ul>
                  {document.companiesAssigned.map((company) => (
                    <li key={company}>{company}</li>
                  ))}
                </ul>
              }
              theme="light"
            >
              <InfoIcon />
            </Tooltip>
          )}
        </TooltipContainer>,
        <span>{dayjs(document.createdAt).format(DATE_FORMAT)}</span>,
      ],
      dropdownItems: [
        {
          key: "ASSIGN_TO_COMPANY",
          label: "Assign to company",
          onClick: () => openAssignDocumentModal([document]),
        },
        {
          key: "UNASSIGN_TO_COMPANY",
          label: "Unassign from company",
          onClick: () => openUnassignDocumentModal(document),
        },
        {
          key: "REMOVE",
          label: "Remove",
          disabled: document.companiesAssigned.length > 0,
          onClick: () =>
            handleDeleteDocument({
              documentId: document.id,
              documentName: document.name,
            }),
        },
      ],
      primaryAction: (
        <IconLink
          onClick={() => {
            downloadDocument({
              path: document.url,
              name: document.name,
            });
          }}
          Icon={Icon.DownloadDocumentIcon}
          hideTextOnMobile
        />
      ),
    }))}
    noClickableRows
    checkboxActions={[
      {
        key: "ASSIGN_TO",
        label: "Assign To",
        onSelect: (values) => {
          if (values.length) {
            openAssignDocumentModal(
              documents.filter((document) => values.includes(document.id))
            );
          }
        },
      },
    ]}
  />
);

export default DocumentsTable;
