import { normalize } from "normalizr";
import { handleActions } from "redux-actions";

import * as actions from "../actions";
import * as schemas from "../schema";

const initialState = {};

export const applicationEntityReducer = handleActions(
  {
    [actions.receiveApplications]: (state, action) => {
      const { applications } = action.payload;
      return {
        ...state,
        ...normalize(applications, schemas.applicationList).entities
          .application,
      };
    },
    [actions.receiveApplication]: (state, action) => {
      const { application } = action.payload;
      return {
        ...state,
        ...normalize([application], schemas.applicationList).entities
          .application,
      };
    },
  },
  initialState
);

export default applicationEntityReducer;
