import React from "react";

import PropTypes from "prop-types";

import {
  CheckboxContainer,
  CheckboxListTitle,
  Description,
  StyledCheckbox,
} from "./styled";

const CheckboxList = ({ onChange, title, isBoldTitle, items }) => (
  <>
    <CheckboxListTitle isBoldTitle={isBoldTitle}>{title}</CheckboxListTitle>
    {items.map((item, index) => (
      <CheckboxContainer
        key={item.id}
        isLastElement={items.length === index + 1}
      >
        <StyledCheckbox
          id={item.id}
          label={item.label}
          checked={item.checked}
          value={item.value}
          onChange={(e) => onChange(item.id, e.target.checked)}
          disabled={item.disabled}
          data-testid={item.id}
        />
        {item.description && <Description>({item.description})</Description>}
      </CheckboxContainer>
    ))}
  </>
);

CheckboxList.propTypes = {
  onChange: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  isBoldTitle: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      checked: PropTypes.bool,
      value: PropTypes.bool,
      description: PropTypes.string,
      disabled: PropTypes.bool,
    })
  ).isRequired,
};

CheckboxList.defaultProps = {
  isBoldTitle: true,
};

export default CheckboxList;
