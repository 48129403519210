import { UploadFileStatus } from "antd-latest/lib/upload/interface";

import { getThumbUrl } from "shared/utils/pdf";

import { AttachmentFile } from "./interfaces";

export const mapFileToUploadItem = (
  file: AttachmentFile
): AttachmentFile & {
  uid: string;
  status: UploadFileStatus;
  thumbUrl: string;
} => ({
  ...file,
  uid: `${file.id}`,
  status: "done",
  thumbUrl: getThumbUrl(file),
});
