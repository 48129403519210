import compact from "lodash/compact";
import { useMutation, useQuery } from "react-query";

import { NOTIFICATIONS } from "shared/config/constants";
import { PasswordRecovery, SetPassword } from "shared/interfaces/api/password";
import api from "shared/lib/api";
import { SetPasswordError } from "shared/pages/SetPasswordPage/interfaces";
import { openNotification } from "shared/utils/ui";

const GET_VALIDATE_PASSWORD_QUERY = "getValidateSetPassword";

const getNonFieldErrors = (error: any) => {
  return (
    error?.errors?.errors?.nonFieldErrors || error?.errors?.nonFieldErrors || []
  );
};

export const useGetValidateSetPasswordToken = (
  token: string,
  onError: (error: SetPasswordError) => void
) => {
  const { data: passwordToken, isLoading } = useQuery(
    [GET_VALIDATE_PASSWORD_QUERY],
    () => api.validateSetPasswordToken(token),
    {
      retry: false,
      onError: (error: SetPasswordError) => onError(error),
    }
  );

  return { passwordToken, isPasswordTokenLoading: isLoading };
};

export const useSetPassword = () => {
  const [setPassword, { isLoading: isSetPasswordLoading }] = useMutation(
    ({ token, password }: SetPassword) => api.setPassword(token, password),
    {
      onError: (error: any) =>
        openNotification(
          compact(["Failed to set password.", ...getNonFieldErrors(error)]),
          NOTIFICATIONS.error
        ),
    }
  );

  return {
    setPassword,
    isSetPasswordLoading,
  };
};

export const useGetValidateResetPasswordToken = (token: string) => {
  const {
    data: passwordToken,
    isLoading,
    error,
  } = useQuery([GET_VALIDATE_PASSWORD_QUERY], () =>
    api.validateResetPasswordToken(token)
  );

  return {
    passwordToken,
    isPasswordTokenLoading: isLoading,
    passwordTokenError: error,
  };
};

const isErrorObjectWithStatusOrMessage = (error: any) => {
  return typeof error === "object" && (!!error?.status || !!error?.message);
};

export const useResetPassword = () => {
  const [resetPassword, { isLoading: isResetPasswordLoading }] = useMutation(
    ({ token, password }: SetPassword) => api.resetPassword(token, password),
    {
      onError: (error: any) => {
        const errorString: string =
          !!error && typeof error === "string"
            ? error
            : "Failed to reset password";
        const errorStatus: Partial<{ status: string }> | null =
          isErrorObjectWithStatusOrMessage(error) ? error?.status : null;
        const errorMessage: Partial<{ message: string }> | null =
          isErrorObjectWithStatusOrMessage(error) ? error?.message : null;
        const errorFields = compact([errorString, ...getNonFieldErrors(error)]);

        const errorMessageFormatted = `${errorFields} ${
          !!errorMessage ? errorMessage : ""
        } ${!!errorStatus ? errorStatus : ""}`;

        openNotification(errorMessageFormatted, NOTIFICATIONS.error);
      },
    }
  );

  return {
    resetPassword,
    isResetPasswordLoading,
  };
};

export const useSendPasswordRecoveryEmail = ({ onSuccess }) => {
  const [
    sendPasswordRecoveryEmail,
    { isLoading: isResetPasswordRecoveryLoading },
  ] = useMutation(
    ({ email }: PasswordRecovery) => api.sendPasswordRecoveryEmail(email),
    {
      onSuccess,
      onError: () =>
        openNotification(
          "Failed to send password recovery email.",
          NOTIFICATIONS.error
        ),
    }
  );

  return {
    sendPasswordRecoveryEmail,
    isResetPasswordRecoveryLoading,
  };
};
