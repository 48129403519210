import React from "react";

import { CollapsibleRadioList, SearchableList } from "shared/components/Form";
import Icon from "shared/components/Icon";
import { Modal, SIZES } from "shared/components/Modal";

import { AssignPropertyToPortfolioModalProps } from "./interfaces";

const AssignPropertyToPortfolioModal = ({
  isMobile,
  submit,
  portfolios = [],
  loading,
  isSubmitting,
  enableSerchAndFilters,
  propertyName,
  hasInputValue,
  selectedPortfolio,
  searchAction,
  onChangeSearch,
}: AssignPropertyToPortfolioModalProps) => (
  <Modal
    title="Assign property to portfolio"
    subtitle={
      <span data-testid="subtitle">
        Property: <strong>{propertyName}</strong>
      </span>
    }
    submit={submit}
    submitButtonLabel="Assign"
    submitButtonSize={SIZES.lg}
    submitButtonDisabled={!selectedPortfolio}
    width="auto"
    style={{ maxWidth: 946 }}
    submitting={isSubmitting}
    fullScreen={isMobile}
  >
    <Modal.Body noMobilePaddingX>
      <SearchableList
        loading={loading}
        search={{
          fieldName: "search-portfolio",
          action: searchAction,
          debounce: true,
          label: "Name",
          width: "370px",
          // @ts-ignore
          disabled: !enableSerchAndFilters,
        }}
        List={CollapsibleRadioList}
        listContainerProps={{
          groupName: "portfolios",
          onChange: onChangeSearch,
        }}
        listProps={{
          col1Width: "350px",
          items: portfolios,
        }}
        placeholder={{
          label: "Portfolios",
          Icon: Icon.NoPortfoliosIcon,
        }}
        hasInputValue={hasInputValue}
      />
    </Modal.Body>
  </Modal>
);

export default AssignPropertyToPortfolioModal;
