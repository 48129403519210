import React from "react";

import { Col, Row } from "antd";
import capitalize from "lodash/capitalize";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";

import Spacer from "shared/components/Spacer";
import Tile from "shared/components/Tile";
import { PETS_GENDERS } from "shared/config/constants";
import { keyPropType } from "shared/utils/propTypes";
import { getAnswerForCustomQuestion } from "shared/utils/questions";
import { yesNo } from "shared/utils/ui";

import { Answer } from "./styled";

const QuestionDescription = (description) => {
  const { id, question, answer, comment } = description;
  return (
    <Tile.Inner borderBottom data-testid="additional-info">
      <Row type="flex" data-testid={id}>
        <Col xs={{ span: 24 }} md={{ span: 11 }}>
          <Tile.Label>Question</Tile.Label>
          {question}
        </Col>
        <Col xs={{ span: 0 }} md={{ span: 1 }}>
          <Tile.Divider />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 11 }}>
          <Spacer hideGteMd />
          <Tile.Label>Answer</Tile.Label>
          <Answer>
            {answer}
            {comment && (
              <>
                <br />
                Comment: {comment}
              </>
            )}
          </Answer>
        </Col>
      </Row>
    </Tile.Inner>
  );
};

const separator = `.\r\n`;

const AdditionalInfo = ({
  additionalInfo,
  petTypes,
  industries,
  kidOptions,
  windowOptions,
  knobOptions,
  marketingSourceOptions,
  requiredAdditionalInfo,
  isReport,
  person,
}) => {
  const hasQuestionToDisplay =
    Boolean(additionalInfo?.answers?.length) ||
    Object.values(requiredAdditionalInfo).some((value) => value);
  const petsSummary = (person?.pets ?? additionalInfo?.pets ?? [])
    ?.map((pet) => {
      const petTypeName = petTypes?.find(
        (pt) => pt.id === (pet.species || pet.petType)
      )?.value;
      return `${petTypeName}, ${pet.breed}, ${pet.weight} pounds, ${
        PETS_GENDERS[pet.gender]
      }${pet.isNeutered ? ", Neutered" : ""}${
        pet.servicePet ? ", Service Animal" : ""
      }`;
    })
    .join(separator);

  const vehicles = person?.vehicles ?? additionalInfo?.vehicles ?? [];

  const vehicleSummary =
    vehicles.length > 0
      ? vehicles
          .map(
            (vehicle) =>
              `${capitalize(vehicle.make)}, ${capitalize(
                vehicle.model
              )}, ${capitalize(vehicle.color)}, ${vehicle.tag?.toUpperCase()}`
          )
          .join(separator)
      : "No";

  const industry = industries
    ? industries.find(({ id }) => additionalInfo?.industry === id)?.value ||
      "N/A"
    : "Loading...";

  const kid = kidOptions
    ? kidOptions.find(({ id }) => additionalInfo?.kid === id)?.value || "N/A"
    : "Loading...";

  const window = windowOptions
    ? windowOptions.find(({ id }) => additionalInfo?.windowGuard === id)
        ?.value || "N/A"
    : "Loading...";

  const knob = knobOptions
    ? knobOptions.find(({ id }) => additionalInfo?.knobRider === id)?.value ||
      "N/A"
    : "Loading...";

  const marketingSource = marketingSourceOptions
    ? marketingSourceOptions.find(
        ({ id }) => additionalInfo?.marketingSource === id
      )?.value || "N/A"
    : "Loading...";

  const hasPets = !isEmpty(person?.pets) || !isEmpty(additionalInfo?.pets);

  return (
    <Tile
      header={{
        title: "Additional Info",
        collapsibleOnDesktop: !isReport,
        collapsibleOnMobile: !isReport,
      }}
      insetHeader={isReport}
    >
      {hasQuestionToDisplay ? (
        <>
          {Boolean(additionalInfo?.answers?.length) &&
            additionalInfo?.answers?.map((answer, i) => {
              const key = i + 1;
              return (
                <QuestionDescription
                  question={answer?.additionalQuestion?.questionName}
                  answer={getAnswerForCustomQuestion(answer)}
                  id="additional-questions"
                  key={key}
                />
              );
            })}
          {requiredAdditionalInfo.pets && (
            <QuestionDescription
              question="Do you have pets?"
              answer={yesNo(hasPets) + (petsSummary ? `. ${petsSummary}.` : "")}
              comment={hasPets && additionalInfo?.petComments}
              id="pet-questions"
            />
          )}
          {requiredAdditionalInfo.smokes && (
            <QuestionDescription
              question="Do you smoke?"
              answer={yesNo(additionalInfo?.smokes)}
              id="smoke-questions"
            />
          )}
          {requiredAdditionalInfo.marketingSource && (
            <QuestionDescription
              question="How did you hear about the home?"
              answer={marketingSource}
              id="marketing-source-question"
            />
          )}
          {requiredAdditionalInfo.vehicles && (
            <QuestionDescription
              question="Do you currently have a vehicle?"
              answer={vehicleSummary}
              id="vehicle-question"
            />
          )}
          {requiredAdditionalInfo.industry && (
            <QuestionDescription
              question="Which industry do you work in?"
              answer={industry}
              id="industry-question"
            />
          )}
          {requiredAdditionalInfo.kid && (
            <QuestionDescription
              question="Do you have kids that will live with you in the home?"
              answer={kid}
              id="kid-question"
            />
          )}
          {requiredAdditionalInfo.windowGuard && (
            <QuestionDescription
              question="Would you like window guards to be installed in the home?"
              answer={window}
              id="window-question"
            />
          )}
          {requiredAdditionalInfo.knobRider && (
            <QuestionDescription
              question="Would you like stove knob covers to be installed in the home?"
              answer={knob}
              id="knob-question"
            />
          )}
        </>
      ) : (
        <Tile.Inner borderBottom>No answers provided</Tile.Inner>
      )}
    </Tile>
  );
};

AdditionalInfo.propTypes = {
  person: PropTypes.shape({
    pets: PropTypes.arrayOf(
      PropTypes.shape({
        petType: PropTypes.string.isRequired,
      })
    ).isRequired,
    vehicles: PropTypes.arrayOf(
      PropTypes.shape({
        make: PropTypes.string,
        model: PropTypes.string,
        color: PropTypes.string,
        tag: PropTypes.string,
      })
    ).isRequired,
  }),
  additionalInfo: PropTypes.shape({
    id: keyPropType.isRequired,
    answers: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.shape({
          answerText: PropTypes.string.isRequired,
          additionalQuestion: PropTypes.shape({
            questionName: PropTypes.string.isRequired,
          }).isRequired,
        }),
        PropTypes.shape({
          answerOption: PropTypes.shape({
            answer: PropTypes.string.isRequired,
          }).isRequired,
          additionalQuestion: PropTypes.shape({
            questionName: PropTypes.string.isRequired,
          }).isRequired,
        }),
      ])
    ),
    pets: PropTypes.arrayOf(
      PropTypes.shape({
        petType: PropTypes.string.isRequired,
      })
    ),
    vehicles: PropTypes.arrayOf(
      PropTypes.shape({
        make: PropTypes.string,
        model: PropTypes.string,
        color: PropTypes.string,
        tag: PropTypes.string,
      })
    ),
    petComments: PropTypes.string,
    smokes: PropTypes.bool,
    industry: PropTypes.string,
    kid: PropTypes.string,
    windowGuard: PropTypes.string,
    knobRider: PropTypes.string,
    marketingSource: keyPropType,
  }),
  petTypes: PropTypes.object,
  industries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  kidOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  windowOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  knobOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  marketingSourceOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  requiredAdditionalInfo: PropTypes.shape({
    industry: PropTypes.bool,
    kid: PropTypes.bool,
    knobRider: PropTypes.bool,
    marketingSource: PropTypes.bool,
    pets: PropTypes.bool,
    smokes: PropTypes.bool,
    vehicles: PropTypes.bool,
    windowGuard: PropTypes.bool,
  }),
  isReport: PropTypes.bool,
};

AdditionalInfo.defaultProps = {
  additionalInfo: undefined,
  petTypes: {},
  requiredAdditionalInfo: {},
  industries: undefined,
  kidOptions: undefined,
  windowOptions: undefined,
  knobOptions: undefined,
  marketingSourceOptions: undefined,
  isReport: false,
};

export default AdditionalInfo;
