import React from "react";

import _ from "lodash";
import PropTypes from "prop-types";

import {
  applicationSummaryPropType,
  incomeAssetsSummaryPropType,
  skippedIncomeAssetsPropType,
} from "manager/utils/propTypes";
import ApplicationDataTag from "shared/components/ApplicationDataTag";
import FlexContainer from "shared/components/FlexContainer";
import Tile from "shared/components/Tile";
import { SmallText } from "shared/components/Typography";
import { LynkVerification } from "shared/components/Verification";
import { EMPLOYMENT_STATUSES } from "shared/config/constants";

import {
  getExistingEmployments,
  getCurrentEmployments,
  getAutoLinkedEmployments,
} from "shared/utils/employmentAndIncome";

import { IncomeStreams } from "./IncomeStreams";
import { IncomeSummary } from "./IncomeSummary";
import { NoCompaniesWell, SkippedIncomeWell, IncomeNote } from "./styled";

export const Income = ({
  summary,
  applicationSummary,
  skippedIncomeAssets,
  income,
  snapshot,
}) => {
  const incomes = summary?.incomes;
  const {
    isArgyleEnabled: isLynkEnabled,
    hasAutoLinkedEmployment,
    skippedIncome,
    employmentStatus,
  } = applicationSummary || {};
  const employments = getExistingEmployments(_.get(snapshot, "employments"));
  const currentEmployments = getCurrentEmployments(employments);
  const autoLinkedEmployments = getAutoLinkedEmployments(currentEmployments);
  const hasAutoLinkedNotCurrentEmployments =
    !_.isEmpty(getAutoLinkedEmployments(employments)) &&
    _.isEmpty(autoLinkedEmployments);

  const noIncomeVerificationAvailable =
    !hasAutoLinkedEmployment && !incomes?.length;

  const showIncome = hasAutoLinkedEmployment;

  const showIncomeStreams = !hasAutoLinkedEmployment && incomes?.length > 0;

  const showIncomeVerificationTag = hasAutoLinkedEmployment || skippedIncome;

  const skippedIncomeMessage =
    employmentStatus === EMPLOYMENT_STATUSES.unemployed
      ? "The lead stated that they have never been employed"
      : "The lead skipped income verification";

  const lynkSkippedMessage = hasAutoLinkedNotCurrentEmployments
    ? "The lead did not link any current companies through VEROLynk"
    : "Lead did not link a company through VEROLynk";

  return (
    <Tile noBorder equalHeight>
      <Tile.Inner data-testid="income-summary">
        <FlexContainer justifyContent="space-between">
          <h2>
            Income{" "}
            {showIncomeVerificationTag && (
              <ApplicationDataTag
                verificationStatus={summary?.incomeVerificationStatus}
                verificationSkipped={applicationSummary?.skippedIncome}
                percentageVerified={summary?.percentIncomeVerified}
                skippedLabel={skippedIncomeAssets.label}
                skippedMessage={skippedIncomeAssets.message}
              />
            )}
          </h2>
          <LynkVerification
            isLynkEnabled={isLynkEnabled}
            hasAutoLinkedEmployment={!_.isEmpty(autoLinkedEmployments)}
            lynkSkippedMessage={lynkSkippedMessage}
          />
        </FlexContainer>
        {showIncome && (
          <IncomeSummary
            income={income}
            summary={summary}
            statedEmployments={currentEmployments}
          />
        )}
        {showIncomeStreams && (
          <>
            {isLynkEnabled &&
              (hasAutoLinkedNotCurrentEmployments ? (
                <NoCompaniesWell noBorder>
                  The lead did not link any current companies but connected bank
                  accounts.
                </NoCompaniesWell>
              ) : (
                <NoCompaniesWell noBorder>
                  The lead did not link any companies but connected bank
                  accounts.
                </NoCompaniesWell>
              ))}
            <IncomeStreams incomes={incomes} />
          </>
        )}
        {noIncomeVerificationAvailable && (
          <SkippedIncomeWell noBorder>{skippedIncomeMessage}</SkippedIncomeWell>
        )}
        {summary?.notes && (
          <IncomeNote>
            <SmallText strong>Notes:</SmallText>{" "}
            <SmallText>{summary?.notes}</SmallText>
          </IncomeNote>
        )}
      </Tile.Inner>
    </Tile>
  );
};

Income.propTypes = {
  summary: incomeAssetsSummaryPropType.isRequired,
  applicationSummary: applicationSummaryPropType.isRequired,
  skippedIncomeAssets: skippedIncomeAssetsPropType.isRequired,
  income: PropTypes.number.isRequired,
  snapshot: PropTypes.any,
};
