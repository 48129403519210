import axios from "axios";

import { VEGA_BASE_URL } from "shared/config/apiRoutes";

const PMS_UNIT_PATH = "/api/unit";

const api = {
  getPmsUnits: ({ location }) =>
    axios
      .get(`${VEGA_BASE_URL}${PMS_UNIT_PATH}?location=${location}`)
      .then(({ data }) => data),
};

export default api;
