import React from "react";

import { ComponentDivider, ContainerDivider, Divider } from "./styled";

export interface SettingsProps {
  header?: Node | React.ReactNode;
  hasContainerDivider?: boolean;
  hasComponentDivider?: boolean;
  hasComponentPadding?: boolean;
  hasContainerPadding?: boolean;
  nodes: {
    key?: string | number;
    component?: Node | React.ReactNode;
    [key: string]: any;
  };
}

const Settings = ({
  header,
  nodes,
  hasContainerDivider = true,
  hasComponentDivider = true,
  hasComponentPadding = true,
  hasContainerPadding = true,
}: SettingsProps) => (
  <Divider>
    <ContainerDivider
      hasContainerDivider={hasContainerDivider}
      hasContainerPadding={hasContainerPadding}
    >
      {header}
    </ContainerDivider>
    {nodes.map((node, index) => (
      <ComponentDivider
        key={node.key}
        data-testid="component"
        isLastElement={nodes.length === index + 1}
        hasComponentDivider={hasComponentDivider}
        hasComponentPadding={hasComponentPadding}
      >
        {node.component}
      </ComponentDivider>
    ))}
  </Divider>
);

export default Settings;
