import _ from "lodash";
import isArray from "lodash/isArray";

import {
  useGetDeal,
  useGetLumenResultForDeal,
  useApplicationsByIds,
  useApplicantsIds,
} from "manager/hooks/api";

import { BACKGROUND_REPORT_TYPE } from "shared/config/constants";
import { usePermissions } from "shared/hooks";
import { setPrefix } from "shared/utils/vrn";

import {
  mapAppPermissions,
  mapBalances,
  mapCountyCriminal,
  mapCredit,
  mapCriminal,
  mapDependents,
  mapEmployment,
  mapEvictions,
  mapFees,
  mapIdentities,
  mapIncomes,
  mapPermissions,
  mapProperty,
  mapUnit,
  mapVoR,
} from "./mappers";
import { ApplicantType } from "./mappers/dependants/interfaces";
import { ArgyleStatus, Deal } from "./mappers/interfaces";
import {
  firstUnfinishedStep,
  getSkippedIncomeAssetsReason,
  showConditionalProceed,
  toDate,
} from "./mappers/utils";

interface IGetApplicationSummary {
  dealId: number | string;
  enabled?: boolean;
}

const APPLICANT_TYPES = [
  ApplicantType.applicant,
  ApplicantType.guarantor,
  ApplicantType.occupant,
];

export const useGetApplicationSummary = ({
  dealId,
  enabled = true,
}: IGetApplicationSummary) => {
  const {
    data: dealFull,
    isLoading: isDealFullLoading,
    refetch: dealRefetch,
  } = useGetDeal(dealId, {
    enabled: Boolean(dealId) && enabled,
  });

  const {
    lumenResult,
    isLoading: isLumenLoading,
    refetchLumenResult,
  } = useGetLumenResultForDeal(dealId, enabled);

  const {
    applicantsIds,
    isApplicantIdsLoading,
    isApplicantsIdsRefetching,
    applicantsIdsRefetch,
  } = useApplicantsIds(dealId, Boolean(dealId));

  const { dealApplicants, dealApplicantsRefetch, isDealApplicantLoading } =
    useApplicationsByIds(
      applicantsIds
        ? isArray(applicantsIds)
          ? applicantsIds?.map((d) => d.id)
          : [applicantsIds?.id]
        : undefined,
      {
        enabled: enabled && !!dealId && !isDealFullLoading,
      }
    );
  const { companyPermissions } = usePermissions();
  const refetchDeal = () => {
    dealRefetch();
    applicantsIdsRefetch().then((data) =>
      dealApplicantsRefetch(isArray(data) ? data.map((d) => d.id) : [data.id])
    );
    refetchLumenResult();
  };

  const unit = mapUnit(dealFull?.unit, dealFull?.unitListing);
  const property = mapProperty(dealFull?.unit) || dealFull?.property;
  const fees = dealFull ? mapFees(dealFull) : undefined;
  const applications = [];
  dealApplicants?.forEach(async (app) => {
    const { incomeAsset, applicantVrn, personVrn, party, location } = app;
    const appSummary = app.applicationSummary;
    const creditSummary = app.backgroundCreditCheck?.creditScoreSummary;
    const criminalSummary = app.backgroundCreditCheck?.criminalRecords;
    const evictionRecords = app.backgroundCreditCheck?.evictionRecords;
    const employment = app.employmentHistories;

    const type =
      APPLICANT_TYPES[(app.applicantType ?? 0) - 1] ?? ApplicantType.unknown;

    let appRequiresBackgroundCreditCheck = false;
    if ([ApplicantType.applicant, ApplicantType.occupant].includes(type)) {
      appRequiresBackgroundCreditCheck =
        dealFull?.requiresBackgroundCreditCheckApplicantOccupant;
    } else if (type === ApplicantType.guarantor) {
      appRequiresBackgroundCreditCheck =
        dealFull?.requiresBackgroundCreditCheckGuarantor;
    }

    const skippedIncomeAssetReason = getSkippedIncomeAssetsReason(appSummary);
    applications.push({
      id: String(appSummary.id),
      isPrimary: app.isPrimaryApplication,
      hideCriminalHistory: app.hideCriminalHistory,
      step: firstUnfinishedStep(
        appSummary.steps,
        app.startedAt,
        appSummary.areIncomeAndAssetChecksEnabled,
        app.skippedBgcc,
        appSummary.isPersonaEnabled,
        appSummary.isArgyleEnabled,
        appSummary.hasApplicationFee
      ),
      inviteLink: appSummary?.applicationLink,
      permission: mapAppPermissions(appSummary),
      lumenEvaluates: app.lumenEvaluates,
      verifiedIncome: app.verifiedIncome,
      totalIncome: incomeAsset?.totalIncome,
      argyleStatus: ArgyleStatus[appSummary.argyleStatus] || undefined,
      applicant: {
        id: appSummary.applicant as unknown as string,
        firstName: appSummary.firstName,
        middleName: appSummary.middleName,
        lastName: appSummary.lastName,
        email: appSummary.email,
        phone: appSummary.phoneNumber,
        birthDate: toDate(appSummary.birthdate),
        type,
        isStudent: appSummary.isStudent,
        isForeigner: appSummary.isUsCitizen === false,
        isSelfEmployed: false,
        income: mapIncomes(
          incomeAsset,
          appSummary.income,
          skippedIncomeAssetReason
        ),
        balances: mapBalances(
          incomeAsset,
          skippedIncomeAssetReason,
          app.skippedAsset
        ),
        credit: mapCredit(creditSummary, app.skippedBgcc),
        criminal: mapCriminal(criminalSummary),
        employment: mapEmployment(
          appSummary.employmentStatus,
          employment,
          appSummary.hasAutoLinkedEmployment,
          appSummary.isArgyleEnabled,
          app.incomeAsset
        ),
        eviction: mapEvictions(
          evictionRecords,
          app.skippedBgcc,
          appRequiresBackgroundCreditCheck && !dealFull?.isInNoEvictionState
        ),
        identity: mapIdentities(app.verifications, appSummary.isPersonaEnabled),
        verificationOfResidency: mapVoR(
          app.verifications,
          dealFull?.company?.enableVerificationOfResidency
        ),
      },
      fraudIndicators:
        app.backgroundCreditCheck?.creditScoreSummary?.fraudIndicators,
      isSubmitted: !!app.submittedAt,
      isBlocked: appSummary.blockDealSubmitIfIncomeDataIsMissing,
      hasConditionallyProceedEnabled: dealFull?.hasConditionallyProceedEnabled,
      isConditionallyProceeded: dealFull?.isConditionallyProceeded,
      isCriminalComplete:
        !appRequiresBackgroundCreditCheck ||
        !!app?.backgroundCreditCheckCompleted,
      hasUnavailableBackgroundReports: _.some(
        [creditSummary, criminalSummary, evictionRecords],
        (report) =>
          _.includes(
            [BACKGROUND_REPORT_TYPE.ERROR, BACKGROUND_REPORT_TYPE.UNAVAILABLE],
            _.get(report, "reportStatus")
          )
      ),
      payments: [],
      snapshot: app?.snapshot,
      applicantVrn: setPrefix(applicantVrn, "applicant"),
      personVrn,
      party,
      location
    });
  });

  const { lumenRevision, applicantsSummary } = dealFull ?? {};

  const applicantsSummaryShort = applicantsSummary?.map(
    ({ id, skippedBgcc }) => ({
      id,
      skippedBgcc,
    })
  );

  const deal: Deal = dealFull
    ? {
        id: dealFull?.id,
        companySlug: dealFull?.companySlug,
        status: dealFull?.dealStatus,
        applications,
        dependents: mapDependents(dealFull),
        yardi: { pCode: dealFull?.yardiPcode, tCode: dealFull?.yardiTcode },
        agent: {
          id: dealFull?.employee?.id,
          firstName: dealFull?.employee?.user?.firstName,
          lastName: dealFull?.employee?.user?.lastName,
        },
        unit,
        property,
        fees,
        lumenEnabled: dealFull?.lumenEnabled,
        moveInDate:
          dealFull?.appSummary?.lroMoveInDate ??
          dealFull?.applicantsSummary?.preferredLeaseStartDate,
        lumen: lumenResult
          ? {
              score: lumenResult.score,
              messages: lumenResult?.messages,
            }
          : undefined,
        permission: mapPermissions(dealFull, companyPermissions),
        countyCriminal: mapCountyCriminal(dealFull),
        conditionalProceed: showConditionalProceed(dealFull),
        isApproved: !!dealFull?.approvedAt,
        isMagicDeal: dealFull?.isMagicDeal,
        isOpenApiDeal: dealFull?.isOpenApiDeal,
        isArchived: dealFull?.isArchived,
        lumenRevision,
        applicantsSummaryShort,
        canManuallySendToPms: dealFull?.canManuallySendToPms,
        lumenResult,
        fraudIndicators: dealFull?.fraudIndicators,
      }
    : undefined;

  return {
    deal,
    isLoading:
      isDealFullLoading ||
      isLumenLoading ||
      isDealApplicantLoading ||
      isApplicantIdsLoading ||
      isApplicantsIdsRefetching,
    dealRefetch: refetchDeal,
  };
};
