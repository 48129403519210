import React from "react";

import { Button } from "shared/components/Button";
import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import Spacer from "shared/components/Spacer";

import { Container, Content, BackgroundImage, List } from "./styled";

interface NewFeatureBannerProps {
  title: string;
  subtitle: string;
  mailto?: Location | (string & Location);
}

const NewFeatureBanner = ({
  title,
  subtitle,
  mailto,
}: NewFeatureBannerProps) => {
  const sendEmail = () => {
    window.location = mailto;
  };
  return (
    <Container>
      <FlexContainer
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap-reverse"
        className="flex-container"
      >
        <Content>
          <span className="new">NEW</span>
          <span className="title">{title}</span>
          <span className="subtitle">{subtitle}</span>
          <List>
            <li>
              <Icon.GreenCheckNoCircle />{" "}
              <span>Save time identifying future applicants</span>
            </li>
            <li>
              <Icon.GreenCheckNoCircle />{" "}
              <span>Verification of all lead types</span>
            </li>
            <li>
              <Icon.GreenCheckNoCircle />{" "}
              <span>Only prospects in your property management system</span>
            </li>
          </List>
          <Spacer />
          <Button type="primary" onClick={sendEmail}>
            request access
          </Button>
        </Content>
        <BackgroundImage />
      </FlexContainer>
    </Container>
  );
};

export default NewFeatureBanner;
