export type UpdateIncomeProps = {
  income: string | number;
  applicantId: string;
};

export type FraudIndicator = {
  name: string;
  description: string;
};

export enum AppStatus {
  Empty = "Empty",
  Submitting = "Submitting",
  Submitted = "Submitted",
  Done = "Done",
}

export enum ApproveStatus {
  Approved = "Approved",
  CreatedLease = "LeaseCreated",
}
