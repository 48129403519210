import React, { Dispatch, SetStateAction } from "react";

import {
  DeactivateEmployeeModal,
  AssignEmployeeToPropertiesModal,
} from "manager/components/Modal";
import { Employee } from "manager/interfaces/api/employee";
import useModal from "shared/hooks/useModal";
import { Pagination } from "shared/interfaces";

import {
  AssignEmployeeToPropertyParams,
  DeactivateEmployeeParams,
} from "../interface";

import EmployeesTable from "./EmployeesTable";

interface EmployeesTableContainerProps {
  employees: Employee[];
  loading: boolean;
  employeesCount: number;
  hasInputValue: boolean;
  pagination: Pagination;
  setPagination: Dispatch<SetStateAction<Pagination>>;
}

const EmployeesTableContainer = ({
  employees,
  loading,
  employeesCount,
  hasInputValue,
  pagination,
  setPagination,
}: EmployeesTableContainerProps) => {
  const { openModalDialog } = useModal();

  const deactivateEmployee = ({ employeeId, name }: DeactivateEmployeeParams) =>
    openModalDialog(DeactivateEmployeeModal, {
      employeeId,
      name,
    });
  const assignEmployeeToProperty = ({
    employeeId,
    firstName,
    lastName,
  }: AssignEmployeeToPropertyParams) =>
    openModalDialog(AssignEmployeeToPropertiesModal, {
      employeeId,
      firstName,
      lastName,
    });

  return (
    <EmployeesTable
      employees={employees}
      loading={loading}
      employeesCount={employeesCount}
      hasInputValue={hasInputValue}
      pagination={pagination}
      setPagination={setPagination}
      deactivateEmployee={deactivateEmployee}
      assignEmployeeToProperty={assignEmployeeToProperty}
    />
  );
};

export default EmployeesTableContainer;
