import React, { ReactNode } from "react";

import { StyledCheckableTag } from "./styled";

interface CheckableTagProps {
  children: ReactNode;
  checked: boolean;
  onChange: () => void;
}

export const CheckableTag = ({
  children,
  checked,
  onChange,
}: CheckableTagProps) => (
  <StyledCheckableTag checked={checked} onChange={onChange}>
    {children}
  </StyledCheckableTag>
);
