import React from "react";

import { Col, Row } from "antd";
import { useFormikContext } from "formik";
import get from "lodash/get";

import { Select, TagsInput, VeroFormField } from "shared/components/Form";
import { SmallText } from "shared/components/Typography";

import {
  getBaseConditionPath,
  getConditionPropPath,
} from "../../LumenFilterConditions/lumenFilterConditions.util";
import { getKeywordsDropdownOptions, KEYWORDS_PLACEHOLDER } from "../utils";

import BaseCondition from "./BaseCondition";
import { ConditionWrapper, EmptySpacer } from "./styled";

interface ConditionKeywordsProps {
  onDelete: () => void;
  idx: number;
}

const ConditionKeywords = ({ idx, onDelete }: ConditionKeywordsProps) => {
  const conditionSubTypeProp = "conditionSubType";
  const keywordsProp = "keywords";

  const conditionSubTypePropPath = getConditionPropPath(
    idx,
    conditionSubTypeProp
  );
  const keywordsPropPath = getConditionPropPath(idx, keywordsProp);

  const keywordsBasePath = getBaseConditionPath(idx);

  const { values } = useFormikContext();

  const conditionValues = get(values, keywordsBasePath);

  return (
    <BaseCondition conditionLabel="Keywords" onDelete={onDelete}>
      <ConditionWrapper>
        <Row gutter={[32, 16]} type="flex">
          <Col span={8}>
            <EmptySpacer />
            <VeroFormField
              as={Select}
              name={conditionSubTypePropPath}
              id={conditionSubTypePropPath}
            >
              {/* @ts-ignore */}
              {getKeywordsDropdownOptions(values?.conditions).map((option) => (
                <Select.Option key={option.key} disabled={option.disabled}>
                  {option.label}
                </Select.Option>
              ))}
            </VeroFormField>
          </Col>
          <Col span={16}>
            <SmallText light>Contains</SmallText>
            <VeroFormField
              as={TagsInput}
              id={keywordsPropPath}
              name={keywordsPropPath}
              placeholder={
                KEYWORDS_PLACEHOLDER[conditionValues[conditionSubTypeProp]] ||
                ""
              }
              tokenSeparators={[',']}
            />
          </Col>
        </Row>
      </ConditionWrapper>
    </BaseCondition>
  );
};

export default ConditionKeywords;
