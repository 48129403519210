
import { Col, Row } from "antd";

import { useTranslation } from "renter/hooks/useTranslation";
import { UnderlineLink } from "shared/components/Links";
import Spacer from "shared/components/Spacer";
import Tile from "shared/components/Tile";
import { SmallText } from "shared/components/Typography";
import { EmploymentHistory as IEmploymentHistory } from "shared/interfaces/api";
import {
  calcTileInnerBorderBottom,
  formatPhone,
  getPeriod,
} from "shared/utils/ui";

import { printDollarsFromCents } from "shared/utils/dollar-print";
import { ManagerName } from "../styled";

interface EmploymentHistoryProps {
  employmentHistory?: IEmploymentHistory[];
}

const EmploymentHistory = ({ employmentHistory }: EmploymentHistoryProps) => {
  const { t } = useTranslation({
    keyPrefix: "personal-info.employment-history",
  });

  return (
    <Tile
      header={{
        title: t("title"),
        collapsibleOnDesktop: true,
        collapsibleOnMobile: true,
      }}
    >
      {employmentHistory?.length ? (
        employmentHistory.map((eh, index) => (
          <Tile.Inner
            key={eh.id}
            borderBottom={calcTileInnerBorderBottom(employmentHistory, index)}
            data-testid={`employment-history-${eh.id}`}
          >
            <Row type="flex">
              <Col xs={24} md={8} lg={5}>
                <Tile.Label>{t("period-label")}</Tile.Label>
                {getPeriod(eh.startDate, eh.endDate)}
              </Col>
              <Col xs={24} md={16} lg={7}>
                <Spacer hideGteMd />
                <Tile.Label>{t("company-label")}</Tile.Label>
                <strong>{eh.employerName}</strong>
                <br />
                <SmallText weak>
                  {eh.companyAddress}, {eh.companyCity}, {eh.companyState},{" "}
                  {eh.companyCountry}, {eh.companyZipcode}
                </SmallText>
              </Col>
              <Col xs={24} md={8} lg={4}>
                <Spacer hideGteLg />
                <Tile.Label>{t("job-title-label")}</Tile.Label>
                <strong>{eh.jobTitle}</strong>
              </Col>
              <Col xs={24} md={10} lg={5}>
                <Spacer hideGteLg />
                <Tile.Label>{t("manager-label")}</Tile.Label>
                {eh.managerName ? (
                  <>
                    <ManagerName>{eh.managerName}</ManagerName>
                    {eh.managerPhoneNumber && (
                      <UnderlineLink href={`tel:${eh.managerPhoneNumber}`}>
                        {formatPhone(eh.managerPhoneNumber)}
                      </UnderlineLink>
                    )}
                  </>
                ) : (
                  "N.A"
                )}
              </Col>
              <Col xs={24} md={6} lg={3}>
                <Spacer hideGteLg />
                <Tile.Label>{t("annual-salary-label")}</Tile.Label>
                <strong>{printDollarsFromCents(eh.annualSalary)}</strong>
              </Col>
            </Row>
          </Tile.Inner>
        ))
      ) : (
        <Tile.Inner
          key={0}
          borderBottom
          data-testid={`employment-history-${0}`}
        >
          {t("no-items")}
        </Tile.Inner>
      )}
    </Tile>
  );
};

export default EmploymentHistory;
