import React, { useEffect, useState } from "react";

import { SorterResult, PaginationConfig } from "antd/lib/table";

import {
  useGetStates,
  useGetVeroPropertiesQuery,
  useUnbindBluemoonProperties,
} from "manager/hooks/api";
import ConfirmationModal from "shared/components/Modals/ConfirmationModal";
import { DEFAULT_PAGINATION } from "shared/config/constants";
import useModal from "shared/hooks/useModal";

import BluemoonBoundProperties from "./BluemoonBoundProperties";
import { Filters } from "./interfaces";
import { blankFilter, ORDER_BY } from "./utils";

interface BluemoonBoundPropertiesContainerProps {
  backLink: { children: string; to: string };
}

const BluemoonBoundPropertiesContainer = ({
  backLink,
}: BluemoonBoundPropertiesContainerProps) => {
  const { states = [] } = useGetStates();

  const { openModalDialog } = useModal();

  const [searchParams, setSearchParams] = useState(blankFilter);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [hasInputValue, setHasInputValue] = useState(false);

  const { veroProperties, isVeroPropertiesLoading, refetchVeroProperties } =
    useGetVeroPropertiesQuery(searchParams);

  const { unbindBluemoonProperties } = useUnbindBluemoonProperties();

  const onUnbindProperties = (id: number) => {
    openModalDialog(ConfirmationModal, {
      title: "Unbind Properties",
      submitButtonLabel: "Confirm",
      subtitle:
        "Unbind these VERO and Bluemoon properties, and remove from the Bound Properties list",
      description:
        "Leases in progress won’t be affected by unbinding. Visit the Property Binding page to bind to a different Bluemoon property. ",
      onConfirm: () => unbindBluemoonProperties(id),
    });
  };

  const { count: propertiesCount, results: properties } = veroProperties || {};

  const handleSearchParams = (key: string, value: string) => {
    let newFilters = blankFilter;
    if (!key) {
      setPagination(DEFAULT_PAGINATION);
    } else {
      if (key === "searchValue") {
        setHasInputValue(!!value);
      }
      newFilters = { ...searchParams, [key]: value, ...DEFAULT_PAGINATION };
      setSearchParams(newFilters);
      setPagination(DEFAULT_PAGINATION);
      return;
    }
    setSearchParams(newFilters);
  };

  const onPaginationChange = (currentPage: number) => {
    const newPagination = { page: currentPage, pageSize: pagination.pageSize };
    const newFilters = { ...searchParams, ...newPagination };
    setPagination(newPagination);
    setSearchParams(newFilters);
  };

  const onTableChange = (
    _tablePagination: PaginationConfig,
    _tableFilters: Filters,
    sorter: SorterResult<Filters>
  ) => {
    const newOrdering = sorter.order
      ? `${ORDER_BY[sorter.columnKey][sorter.order]}`
      : undefined;
    const newFilters = {
      ...searchParams,
      ...DEFAULT_PAGINATION,
      ordering: newOrdering,
    };
    setSearchParams(newFilters);
    setPagination(DEFAULT_PAGINATION);
  };

  const allStates = [
    { label: "All states", key: "" },
    ...states.map((state) => ({ label: state.id, key: state.id })),
  ];

  useEffect(() => {
    refetchVeroProperties();
  }, [searchParams]);

  return (
    <BluemoonBoundProperties
      pagination={pagination}
      searchParams={searchParams}
      hasInputValue={hasInputValue}
      backLink={backLink}
      isBluemoonPropertiesFetching={isVeroPropertiesLoading}
      propertiesCount={propertiesCount}
      properties={properties}
      handleSearchParams={handleSearchParams}
      onPaginationChange={onPaginationChange}
      states={allStates}
      onUnbindProperties={onUnbindProperties}
      onTableChange={onTableChange}
    />
  );
};

export default BluemoonBoundPropertiesContainer;
