import React from "react";

import { Helmet } from "react-helmet";

import Viewer from "admin/components/PDFTronViewer";
import { Button } from "shared/components/Button";
import Container from "shared/components/Container";
import { SwitchTitle } from "shared/components/Form";
import Spacer from "shared/components/Spacer";
import Tooltip from "shared/components/Tooltip";
import { BREAKPOINT_TYPES } from "shared/config/constants";
import Layout from "shared/templates/Layout";

import FieldsPanel from "./FieldsPanel";
import FileUpload from "./FileUpload";
import { DocumentConstructorProps } from "./interfaces";
import {
  ScrollContainerWrapper,
  SlidingContainer,
  SlidingContentContainer,
  FileUploadContainer,
  TooltipContainer,
} from "./styled";
import ViewerHeader from "./ViewerHeader";

const DocumentConstructor = ({
  sidebar,
  header,
  viewerApi,
  selectedFile,
  setSelectedFile,
  onConfirmClick,
  isPreviewResolved,
  isPreviewMode,
  onSwitchMode,
  onAddField,
  getUpdatedBackLink,
}: DocumentConstructorProps) => {
  const FooterButtons = (
    <>
      <SwitchTitle
        value={isPreviewMode}
        onChange={onSwitchMode}
        title="Preview data"
        disabled={
          !viewerApi.isDocumentLoaded ||
          viewerApi.isApplyingAnnotations ||
          !isPreviewResolved()
        }
        noPadding
      />
      <Tooltip
        title={isPreviewMode && "Please turn off preview in order to confirm"}
        theme="dark"
      >
        <TooltipContainer>
          <Button
            data-testid="confirm-btn"
            type="primary"
            onClick={onConfirmClick}
            disabled={
              !viewerApi.isDocumentLoaded ||
              !isPreviewResolved() ||
              isPreviewMode
            }
          >
            Confirm
          </Button>
        </TooltipContainer>
      </Tooltip>
    </>
  );

  return (
    <Layout
      sidebar={sidebar}
      header={{
        ...header,
        backLink: getUpdatedBackLink(),
      }}
    >
      <Helmet>
        <title>Document Constructor</title>
      </Helmet>
      <Container noPaddingBottom maxWidth={BREAKPOINT_TYPES.xxxl}>
        <ViewerHeader selectedFile={selectedFile} />
      </Container>
      <Spacer size={Spacer.SIZES.xs} />
      <Container
        noPaddingTop
        noPaddingBottom
        expand
        maxWidth={BREAKPOINT_TYPES.xxxl}
      >
        <SlidingContainer>
          <FieldsPanel
            addField={onAddField}
            exportAnnotations={viewerApi.exportAnnotations}
            importAnnotations={viewerApi.importAnnotations}
            isDocumentLoaded={viewerApi.isDocumentLoaded}
          />
          <SlidingContentContainer>
            <ScrollContainerWrapper>
              {!viewerApi.isDocumentLoading && !selectedFile && (
                <FileUploadContainer>
                  <FileUpload onFileSelect={setSelectedFile} />
                </FileUploadContainer>
              )}
              <Viewer
                viewerApi={viewerApi}
                RightTools={FooterButtons}
                loading={viewerApi.isApplyingAnnotations}
              />
            </ScrollContainerWrapper>
          </SlidingContentContainer>
        </SlidingContainer>
      </Container>
    </Layout>
  );
};

export default DocumentConstructor;
