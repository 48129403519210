import styled from "@emotion/styled";

import Icon from "shared/components/Icon";
import { UnderlineLink } from "shared/components/Links";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

interface StyledContainerProps {
  inline?: boolean;
}

interface StyledItemProps {
  inline?: boolean;
  withoutComma?: boolean;
  violet?: boolean;
}

const Container = styled("ul")<StyledContainerProps>`
    display:  ${(props) => (props.inline ? "flex" : "block")}
    flex-wrap: wrap;
    gap: 4px 7px;
    font-weight: 400;
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
    white-space: nowrap;
  })
`;

const Item = styled("li")<StyledItemProps>`
  display: flex;
  align-items: center;
  white-space: break-spaces;
  &:not(:last-of-type) {
    margin-bottom: ${(props) => (!props.inline ? "4px" : "0")}
  }
  color: ${(props) => props.violet && COLORS.violetTheme.text};
  ${MEDIA_QUERIES.w.gteMd} {
    &:not(:last-of-type) {
      whiite-space: ${(props) => props.inline && "nowrap"}
        &::after {
          content: ${(props) => (props.withoutComma ? "''" : "','")}
        }
    }
  }
`;

interface StyledEmptyIconProps {
  violet?: boolean;
}

const EmptyIcon = styled("div")<StyledEmptyIconProps>`
  width: 4px;
  height: 4px;
  background-color: ${COLORS.bulletList.gray};
  margin-left: 4px;
  margin-right: 7px;
  border-radius: 50%;
  flex-shrink: 0;
  background-color: ${(props) => props.violet && COLORS.violetTheme.emptyIcon};
`;

const CheckIcon = styled(Icon.CheckIcon)`
  margin-right: 3px;
  flex-shrink: 0;
`;

const ToggleDisplayAllLink = styled(UnderlineLink)`
  font-size: 12px;
`;

const ToggleDisplayAllHoverLink = styled.li`
  color: white;
  text-decoration: underline;
`;

const ItemIcon = styled("span")`
  margin-left: 4px;
  display: flex;
  align-items: center;
`;

const PopoverList = styled.ul`
  margin-bottom: 0;
  padding-left: 20px;
`;

export {
  Container,
  Item,
  EmptyIcon,
  CheckIcon,
  ToggleDisplayAllLink,
  ToggleDisplayAllHoverLink,
  ItemIcon,
  PopoverList,
};
