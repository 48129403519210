import React from "react";

import {
  useAssignEmployeePortfoliosProperties,
  useGetEmployeePortfoliosProperties,
} from "manager/hooks/api";
import { getIntegrationType } from "manager/utils/integration.util";

import { INTEGRATION_TYPES, NOTIFICATIONS } from "shared/config/constants";
import { useDeviceType, useActiveCompany } from "shared/hooks";
import useModal from "shared/hooks/useModal";
import { openNotification } from "shared/utils/ui";

import AssignEmployeeToPropertiesModal from "./AssignEmployeeToPropertiesModal";
import AssignEmployeeToPropertiesSuccessModal from "./AssignEmployeeToPropertiesSuccessModalContainer";
import { AssignEmployeePortfoliosProperties } from "./interfaces";

interface AssignEmployeeToPropertiesModalContainerProps {
  employeeId: number;
  firstName: string;
  lastName: string;
}

const AssignEmployeeToPropertiesModalContainer = ({
  employeeId,
  firstName,
  lastName,
}: AssignEmployeeToPropertiesModalContainerProps) => {
  const { activeCompany } = useActiveCompany();
  const { isMobile } = useDeviceType();
  const { closeActiveModalDialog, openModalDialog } = useModal();
  const { portfolios, isPortfoliosLoading } =
    useGetEmployeePortfoliosProperties(employeeId);

  const {
    assignEmployeePortfoliosProperties,
    isAssignEmployeePortfoliosPropertiesLoading,
  } = useAssignEmployeePortfoliosProperties(employeeId);

  const anyNewPropertyHasIntegration = (newlyAssignedProperties) => {
    const foundIntegrationType = (newlyAssignedProperties || [])
      .map((property) => getIntegrationType(property))
      .find((integrationType) => !!integrationType);
    let existsPropertyWithIntegration = !!foundIntegrationType;
    if (foundIntegrationType === INTEGRATION_TYPES.YARDI) {
      existsPropertyWithIntegration = !activeCompany?.yardiSuspended;
    }
    return existsPropertyWithIntegration;
  };

  const onSubmit = (values: AssignEmployeePortfoliosProperties) => {
    assignEmployeePortfoliosProperties(values, {
      onSuccess: ({ data }) => {
        if (anyNewPropertyHasIntegration(data.newlyAssignedProperties)) {
          const context = {
            employeeId,
            firstName,
            lastName,
            newlyAssignedProperties: data.newlyAssignedProperties,
          };
          openModalDialog(AssignEmployeeToPropertiesSuccessModal, context);
        } else {
          openNotification(
            "The employee has been successfully assigned to the selected properties",
            NOTIFICATIONS.info
          );
          closeActiveModalDialog();
        }
      },
    });
  };

  return (
    <AssignEmployeeToPropertiesModal
      firstName={firstName}
      lastName={lastName}
      portfolios={portfolios}
      onSubmit={onSubmit}
      loading={isPortfoliosLoading}
      isMobile={isMobile}
      submitting={isAssignEmployeePortfoliosPropertiesLoading}
    />
  );
};

export default AssignEmployeeToPropertiesModalContainer;
