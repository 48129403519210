import React from "react";

import FlexContainer from "shared/components/FlexContainer";
import { SIZES } from "shared/components/Tag/utils";
import { RegularText } from "shared/components/Typography";
import { IntegrationProvider } from "shared/config/constants";

import {
  IntegrationStatusDropdownContainer,
  StatusCircle,
  ChevronDownIcon,
} from "./styled";
import { IntegrationStatus, getIntegrationStatusConfig } from "./types";

interface IIntegrationStatusDropdown {
  status?: IntegrationStatus;
  provider?: IntegrationProvider;
  hideIcon?: boolean;
  transparentBackground?: boolean;
}

export const IntegrationStatusDropdown = ({
  status = IntegrationStatus.NOT_CONNECTED,
  provider = IntegrationProvider.yardi,
  hideIcon,
  transparentBackground,
}: IIntegrationStatusDropdown) => {
  const { text, circleBackgroundColor } = getIntegrationStatusConfig(
    provider,
    status
  );
  return (
    <IntegrationStatusDropdownContainer
      size={SIZES.md}
      transparentBackground={transparentBackground}
    >
      <FlexContainer alignItems="center">
        <StatusCircle
          data-testid="integration-status-circle"
          backgroundColor={circleBackgroundColor}
        />
        <RegularText strong>{text}</RegularText>
        {!hideIcon && (
          <ChevronDownIcon data-testid="integration-status-chevron" />
        )}
      </FlexContainer>
    </IntegrationStatusDropdownContainer>
  );
};
