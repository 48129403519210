import React from "react";

import { Row } from "antd-latest";

import FlexContainer from "../FlexContainer";
import { Popover } from "../Popover";
import { SmallText } from "../Typography";

import { TooltipColumnWidth } from "./config";
import { Column, PopoverContainer } from "./styled";

interface ApplicationSummaryTooltipProps {
  children: React.ReactNode;
  layout: {
    title: string;
    subject?: string;
    subtitle: string | number;
    width: TooltipColumnWidth;
  }[];
}

const ApplicationSummaryTooltip = ({
  children,
  layout,
}: ApplicationSummaryTooltipProps) => (
  // @ts-ignore
  <Popover
    trigger="hover"
    withoutOverlay
    placement="topLeft"
    content={
      <PopoverContainer>
        <Row>
          {layout.map(({ title, subtitle, subject, width }) => (
            <Column
              span={
                width === TooltipColumnWidth.full
                  ? 24
                  : width === TooltipColumnWidth.half
                  ? 12
                  : 12
              }
            >
              <FlexContainer flexDirection="column">
                <SmallText extraStrong>
                  {title} {subject && <SmallText>{subject}</SmallText>}
                </SmallText>
                <SmallText>{subtitle ?? "-"}</SmallText>
              </FlexContainer>
            </Column>
          ))}
        </Row>
      </PopoverContainer>
    }
  >
    {children}
  </Popover>
);

export default ApplicationSummaryTooltip;
