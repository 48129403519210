import * as Yup from "yup";

const EDIT_EMPLOYEE_FIELDS = Object.freeze({
  firstName: "firstName",
  lastName: "lastName",
});

const EditEmployeeValidationSchema = Yup.object().shape({
  firstName: Yup.string().min(3).max(100).required("First name is required"),
  lastName: Yup.string().min(3).max(100).required("Last name is required"),
});

export { EditEmployeeValidationSchema, EDIT_EMPLOYEE_FIELDS };
