import React from "react";

import {
  UnderlineLink as StyledUnderlineLink,
  StandardUnderlineLink as StyledStandardUnderlineLink,
} from "./styled";

export interface IUnderlineLink {
  children?: React.ReactNode;
  to?: string;
  href?: string;
  onClick?: any;
  nowrap?: boolean;
  disabled?: boolean;
  [key: string]: any;
}

const UnderlineLink = ({
  children = undefined,
  to = undefined,
  href = undefined,
  onClick = undefined,
  ...props
}: IUnderlineLink) => {
  let link;

  if (to) {
    link = (
      <StyledUnderlineLink {...props} to={to}>
        {children}
      </StyledUnderlineLink>
    );
  } else {
    link = (
      <StyledStandardUnderlineLink {...props} href={href} onClick={onClick}>
        {children}
      </StyledStandardUnderlineLink>
    );
  }

  return link;
};

export default UnderlineLink;
