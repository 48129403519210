import styled from "@emotion/styled";

import { colors } from "shared/config/constants";

interface StyledChipProps {
  color: "dark-grey" | "green" | "orange" | "light-grey";
}

export const StyledChip = styled.div<StyledChipProps>`
  padding: 7px 16px 7px 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 8px;

  background-color: ${({ color }) => {
    switch (color) {
      case "dark-grey":
        return colors.neutral[600];
      case "green":
        return colors.success[400];
      case "orange":
        return colors.warning[400];
      case "light-grey":
        return colors.neutral[300];
      default:
        return colors.neutral[300];
    }
  }};
`;
