import axios from "axios";

import ROUTES from "manager/config/apiRoutes";
import { API_URL } from "shared/lib/api/setupApi";
import { toSnakeCaseKeys } from "shared/utils/misc.util";

const api = {
  unitsExport: (filters) =>
    axios.post(`${API_URL}${ROUTES.unitsExport}`, null, {
      params: {
        property_id: filters?.propertyId,
        status: filters?.status,
        bedrooms: filters?.bedrooms,
        bathrooms: filters?.bathrooms,
        name__icontains: filters?.name || null,
        start_date: filters?.startDate || null,
        end_date: filters?.endDate || null,
        show_on_hold: filters?.showOnHold,
      },
    }),
  getPropertyUnitsDropdown: (params) => {
    if (!params.propertyId) {
      return Promise.resolve([]);
    }

    return axios
      .get(`${API_URL}${ROUTES.getUnitsListForProperty}`, {
        params: toSnakeCaseKeys(params),
      })
      .then(({ data }) => data);
  },
};

export default api;
