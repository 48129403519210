import React from "react";

import { Row, Col } from "antd";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";

import { unitPropType } from "manager/utils/propTypes";
import ErrorList from "shared/components/ErrorList";
import { Input, VeroFormField } from "shared/components/Form";
import { Modal } from "shared/components/Modal";
import Spacer from "shared/components/Spacer";
import { BREAKPOINT_TYPES } from "shared/config/constants";
import withBreakpoint from "shared/hocs/withBreakpoint";
import { breakpointPropType } from "shared/utils/propTypes";

import {
  CreateUnitValidationSchema,
  CREATE_UNIT_FIELDS,
} from "../CreateUnitModal";

const EditUnitModal = ({ breakpoint, context, submit }) => {
  const { unit } = context || {};
  const initialValues = {
    name: unit?.name,
    unitType: unit?.unitType,
    bedrooms: unit?.bedrooms,
    bathrooms: unit?.bathrooms,
    squareFeet: unit?.squareFeet,
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={CreateUnitValidationSchema}
      initialValues={initialValues}
      onSubmit={(values, formikApi) => {
        return submit(
          {
            ...values,
            id: context.unit.id,
            property: context.unit.propertyId,
          },
          formikApi
        );
      }}
    >
      {({ submitForm, isSubmitting, errors }) => (
        <Form>
          <Modal
            title="Edit Unit"
            subtitle={
              <span data-testid="subtitle">
                Unit: <strong>{context?.unit?.name}</strong>
              </span>
            }
            submit={submitForm}
            submitting={isSubmitting}
            closeOnSubmit={false}
            submitButtonLabel="Save Changes"
            fullScreen={BREAKPOINT_TYPES.xs === breakpoint}
          >
            {errors.nonFieldErrors && (
              <Modal.Body noPaddingBottom>
                <ErrorList errors={errors.nonFieldErrors} />
              </Modal.Body>
            )}
            <Modal.Body>
              <Row gutter={[20, 25]}>
                <Col md={{ span: 12 }}>
                  <VeroFormField
                    id={CREATE_UNIT_FIELDS.name}
                    name={CREATE_UNIT_FIELDS.name}
                    data-testid={CREATE_UNIT_FIELDS.name}
                    label="Unit Name"
                    type="text"
                    as={Input}
                    required
                  />
                </Col>
                <Col md={{ span: 12 }}>
                  <VeroFormField
                    id={CREATE_UNIT_FIELDS.unitType}
                    name={CREATE_UNIT_FIELDS.unitType}
                    data-testid={CREATE_UNIT_FIELDS.unitType}
                    label="Unit Type"
                    type="text"
                    optional
                    as={Input}
                  />
                </Col>
              </Row>
              <Spacer />
              <Row gutter={[20, 25]}>
                <Col md={{ span: 8 }}>
                  <VeroFormField
                    id={CREATE_UNIT_FIELDS.bedrooms}
                    name={CREATE_UNIT_FIELDS.bedrooms}
                    data-testid={CREATE_UNIT_FIELDS.bedrooms}
                    label="Bedrooms"
                    type="number"
                    optional
                    min={0}
                    as={Input}
                  />
                </Col>
                <Col md={{ span: 8 }}>
                  <VeroFormField
                    id={CREATE_UNIT_FIELDS.bathrooms}
                    name={CREATE_UNIT_FIELDS.bathrooms}
                    data-testid={CREATE_UNIT_FIELDS.bathrooms}
                    label="Bathrooms"
                    type="number"
                    step="0.5"
                    optional
                    min={0}
                    as={Input}
                  />
                </Col>
                <Col md={{ span: 8 }}>
                  <VeroFormField
                    id={CREATE_UNIT_FIELDS.squareFeet}
                    name={CREATE_UNIT_FIELDS.squareFeet}
                    data-testid={CREATE_UNIT_FIELDS.squareFeet}
                    label="Square Feet"
                    type="number"
                    optional
                    min={0}
                    as={Input}
                  />
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

EditUnitModal.propTypes = {
  breakpoint: breakpointPropType.isRequired,
  submit: PropTypes.func.isRequired,
  context: PropTypes.shape({
    unit: unitPropType.isRequired,
  }),
};

EditUnitModal.defaultProps = {
  context: undefined,
};

export default withBreakpoint(EditUnitModal);
