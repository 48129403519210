import styled from "@emotion/styled";

import Container from "shared/components/Container";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;

  h1 {
    margin-right: 43px;
    word-break: break-all;
  }

  ${MEDIA_QUERIES.w.ltMd} {
    width: 100%;
    display: flex;
    justify-content: space-between;

    h1 {
      margin-bottom: 10px !important;
      margin-right: 0;
      width: calc(100% - 100px);
    }

    .actions-wrapper {
      width: 100px;
      justify-content: flex-end;
      margin-top: 0;
    }
  }
`;

// NOTE: Added `z-index: 1` to fix a rendering issue on Safari 13.
// Reference to some similar bugs related to rendering in Safari (not the same solution):
// - https://github.com/google/material-design-lite/issues/4741
// - https://github.com/google/material-design-lite/issues/4354
export const LeadDetailsContent = styled(Container)`
  z-index: 1;
  .subtitle-container {
    margin-bottom: 32px;
  }
  .page-title-container {
    margin-bottom: 10px;
  }
  .pre-screening-type {
    color: ${COLORS.primary};
    text-transform: uppercase;
  }
  .p-code-text {
    color: ${COLORS.gray2};
  }
  .spacer {
    margin-left: 5px;
    margin-right: 5px;
    color: ${COLORS.gray2};
  }
  .completed-on-text {
    color: ${COLORS.gray2};
    font-weight: 400;
  }
  .dropdown-menu {
    margin-right: 20px;
  }
`;
