import { ModalManager } from "shared/utils/modalmanager";

import CreateUnitSuccessModal from "../CreateUnitSuccessModal";

import CreateUnitModal from "./CreateUnitModalContainer";

/**
 * Util that opens CreateUnit modal followed by CreateUnitSuccess modal.
 *
 * @param {*} property the property in which the unit should reside
 */
export const openCreateUnitWithSuccessModal = ({
  propertyId,
  propertyName,
}) => {
  const params = {
    propertyId,
    propertyName,
    afterUnitCreate: (unit) => {
      ModalManager.openModal(CreateUnitSuccessModal, {
        unitId: unit.id,
        unitName: unit.name,
        propertyName: unit.propertyName,
      });
    },
  };
  ModalManager.openModal(CreateUnitModal, params);
};
