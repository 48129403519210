import React from "react";

import Collapse from "shared/components/Collapse";
import Tile from "shared/components/Tile";

import ApplicantsList from "./ApplicantsList";
import { ApplicantsCategoriesListProps } from "./interfaces";
import { StyledHeader } from "./styled";

const ApplicantsCategoriesList = ({
  applicantsDataByCategories,
  addField,
}: ApplicantsCategoriesListProps) => (
  <>
    {Object.keys(applicantsDataByCategories).map((applicantCategory) => (
      <Collapse
        key={applicantCategory}
        header={({ CollapseIcon }) => (
          <Tile.Inner style={{ padding: "6px 15px", fontSize: "17px" }}>
            <StyledHeader>
              {applicantCategory}
              <CollapseIcon />
            </StyledHeader>
          </Tile.Inner>
        )}
      >
        <Tile.Inner style={{ padding: "0 15px" }}>
          <ApplicantsList
            applicantCategory={applicantCategory}
            applicantsDataByNumber={
              applicantsDataByCategories[applicantCategory]
            }
            addField={addField}
          />
        </Tile.Inner>
      </Collapse>
    ))}
  </>
);
export default ApplicantsCategoriesList;
