import React, { useEffect } from "react";

import { Field, useFormikContext } from "formik";
import PropTypes from "prop-types";

import { LUMEN_FILTER_CONDITION_AMOUNT_TIME_OPERATOR_TYPE } from "manager/config/lumen.config";
import { MaskedInput, Select } from "shared/components/Form";
import { addDefaultObjectValues, showError } from "shared/utils/forms";

import BaseCondition from "./BaseCondition";
import { commonFilterConditionPropTypes } from "./lumenFilterConditions.config";
import {
  getBaseConditionPath,
  getConditionAmountOfTimeOperatorOptions,
  getConditionPropPath,
} from "./lumenFilterConditions.util";

const ConditionAmount = ({ idx, onDelete, label }) => {
  const { values, errors, touched, setFieldTouched, setFieldValue } =
    useFormikContext();
  const amountOperatorProp = "amountOperator";
  const amountValueProp = "amountValue";
  const amountBasePath = getBaseConditionPath(idx);
  const amountOperatorPropPath = getConditionPropPath(idx, amountOperatorProp);
  const amountValuePropPath = getConditionPropPath(idx, amountValueProp);

  useEffect(() => {
    const defaultValues = {
      [amountOperatorProp]:
        LUMEN_FILTER_CONDITION_AMOUNT_TIME_OPERATOR_TYPE.LESS_THAN,
      [amountValueProp]: null,
    };
    addDefaultObjectValues(
      values,
      amountBasePath,
      defaultValues,
      setFieldValue
    );
  }, []);

  return (
    <BaseCondition conditionLabel={label} onDelete={onDelete}>
      <div className="medium-select">
        <Field
          name={amountOperatorPropPath}
          id={amountOperatorPropPath}
          label="Select"
          as={Select}
          error={showError(amountOperatorPropPath, touched, errors)}
          onChange={(value) => setFieldValue(amountOperatorPropPath, value)}
          onBlur={() => setFieldTouched(amountOperatorPropPath, true)}
        >
          {getConditionAmountOfTimeOperatorOptions().map((option) => (
            <Select.Option key={option.key}>{option.label}</Select.Option>
          ))}
        </Field>
      </div>

      <span className="than-concat">than</span>

      <div className="normal-input">
        <MaskedInput.Formik.Money
          id={amountValuePropPath}
          name={amountValuePropPath}
          label="Value"
          error={showError(amountValuePropPath, touched, errors)}
        />
      </div>
    </BaseCondition>
  );
};

ConditionAmount.propTypes = {
  ...commonFilterConditionPropTypes,
  label: PropTypes.string,
};

ConditionAmount.defaultProps = {
  label: "Amount:",
};

export default ConditionAmount;
