import { Key } from "react";

export enum ApplicantType {
  applicant = "applicant", // 1
  guarantor = "guarantor", // 2
  occupant = "occupant", // 3
  unknown = "unknown",
  dependent = "dependent",
}

export interface Dependent {
  id?: Key;
  lastName?: string;
  firstName?: string;
  age?: number;
  relationship?: string;
  type?: ApplicantType;
}
