import React from "react";

import { Col } from "antd";

import FlexContainer from "shared/components/FlexContainer";
import Spacer from "shared/components/Spacer";
import {
  COLORS,
  PERSONA_VERIFICATION_STATUS,
  PERSONA_VERIFICATION_TYPES_LABELS,
  VERIFICATION_OF_RESIDENCY_STATUS,
  PERSONA_VERIFICATION_STATUS_TYPES,
  VERIFICATION_OF_RESIDENCY_STATUS_TYPES,
  VERIFICATION_TYPES,
} from "shared/config/constants";
import { formatDateTime, getValueOrNA } from "shared/utils/ui";

import Icon from "../Icon";
import { RegularText } from "../Typography";

import {
  StatusWrapper,
  StatusLeftContainer,
  StatusLabel,
  ManualApprovalLabel,
  InlineStatusRow,
} from "./styled";

const VERIFICATION_STATUS_VIEW_INFO = Object.freeze({
  [PERSONA_VERIFICATION_STATUS.COMPLETED]: {
    label: "Verified",
    subHeaderLabel: "verification attempt completed",
    Icon: Icon.CheckGreenBig,
    color: COLORS.accentDark,
  },
  [PERSONA_VERIFICATION_STATUS.PASSED]: {
    label: "Verified",
    subHeaderLabel: "verification attempt completed",
    Icon: Icon.CheckGreenBig,
    color: COLORS.accentDark,
  },
  [PERSONA_VERIFICATION_STATUS.FAILED]: {
    label: "Verification Failed",
    subHeaderLabel: "verification attempt failed",
    Icon: Icon.CrossRedBig,
    color: COLORS.failedRed,
  },
  [PERSONA_VERIFICATION_STATUS.EXPIRED]: {
    label: "Expired",
    subHeaderLabel: "Expired",
    Icon: Icon.OrangeExclamation,
    color: COLORS.midOrange,
  },
  [VERIFICATION_OF_RESIDENCY_STATUS.SUBMITTED]: {
    label: "Submitted",
    subHeaderLabel: "Submitted",
    Icon: Icon.CheckGreenBig,
    color: COLORS.accentDark,
  },
  [VERIFICATION_OF_RESIDENCY_STATUS.WAITING]: {
    label: "Not submitted",
    subHeaderLabel: "Not submitted",
    Icon: null,
    color: COLORS.failedRed,
  },
  [VERIFICATION_OF_RESIDENCY_STATUS.STARTED]: {
    label: "Not submitted",
    subHeaderLabel: "Not submitted",
    Icon: null,
    color: COLORS.failedRed,
  },
  [VERIFICATION_OF_RESIDENCY_STATUS.NOTIFIED]: {
    label: "Notified",
    subHeaderLabel: "Notified",
    Icon: null,
    color: COLORS.midOrange,
  },
  [VERIFICATION_OF_RESIDENCY_STATUS.VERIFIED]: {
    label: "Notified",
    subHeaderLabel: "Notified",
    Icon: null,
    color: COLORS.midOrange,
  },
});

const VERIFICATION_TYPE_VIEW_INFO = Object.freeze({
  [VERIFICATION_TYPES.ID]: "ID",
  [VERIFICATION_TYPES.VOR]: "Residency",
});

/**
 * Component that displays the verification status details.
 */

export interface IVerificationStatus {
  type: VERIFICATION_TYPES;
  verificationType?: string;
  status:
    | PERSONA_VERIFICATION_STATUS_TYPES
    | VERIFICATION_OF_RESIDENCY_STATUS_TYPES;
  verificationDate?: string;
  subHeader?: boolean;
  isManuallyApproved?: boolean;
  personaReviewerComment?: string;
}

const AccordionVerificationStatus = ({
  type,
  verificationType = undefined,
  status,
  verificationDate = undefined,
  subHeader = false,
  isManuallyApproved = false,
  personaReviewerComment = undefined,
}: IVerificationStatus) => {
  const statusInfo = VERIFICATION_STATUS_VIEW_INFO[status];
  const displayType = verificationType
    ? PERSONA_VERIFICATION_TYPES_LABELS[verificationType]
    : VERIFICATION_TYPE_VIEW_INFO[type];

  const renderSubHeader = () => {
    return (
      <InlineStatusRow type="flex" align="middle">
        <Col xs={24} md={24} lg={24}>
          <FlexContainer justifyContent="space-between">
            <FlexContainer alignItems="center">
              {statusInfo?.Icon && (
                <statusInfo.Icon
                  width="14.5"
                  height="14.5"
                  className="status-icon"
                />
              )}
              <RegularText>
                {getValueOrNA(`${displayType} ${statusInfo?.subHeaderLabel}`)}
              </RegularText>
            </FlexContainer>
            <FlexContainer>
              {verificationDate && (
                <RegularText>{formatDateTime(verificationDate)}</RegularText>
              )}
            </FlexContainer>
          </FlexContainer>
        </Col>
        <Spacer hideGteMd />
      </InlineStatusRow>
    );
  };

  return (
    <>
      {subHeader && renderSubHeader()}
      {!subHeader && (
        <StatusWrapper>
          <StatusLeftContainer>
            {statusInfo?.Icon && (
              <statusInfo.Icon width="24" height="24" className="status-icon" />
            )}
            <StatusLabel color={statusInfo?.color} fontWeight={600}>
              {getValueOrNA(
                `${VERIFICATION_TYPE_VIEW_INFO[type]} ${statusInfo?.label}`
              )}
            </StatusLabel>
          </StatusLeftContainer>
          {isManuallyApproved && (
            <ManualApprovalLabel color={statusInfo?.color}>
              <div>
                <b>Approved by Vero Support at Request of the Customer</b>
              </div>
              {personaReviewerComment && (
                <div>
                  <b>Reason:</b> {personaReviewerComment}
                </div>
              )}
            </ManualApprovalLabel>
          )}
        </StatusWrapper>
      )}
    </>
  );
};

export default AccordionVerificationStatus;
