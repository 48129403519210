import { normalize } from "normalizr";
import { handleActions } from "redux-actions";

import * as employeeActions from "shared/state/Employee/actions";
import * as employeeSchemas from "shared/state/Employee/schema";

import * as actions from "../actions";
import * as schemas from "../schema";

const initialState = {};

const userEntityReducer = handleActions(
  {
    [actions.receiveUser]: (state, action) => {
      const { user } = action.payload;

      return {
        ...state,
        ...normalize([user], schemas.userList).entities.user,
      };
    },
    [actions.receiveUsers]: (state, action) => {
      const { users } = action.payload;

      return {
        ...state,
        ...normalize(users, schemas.userList).entities.user,
      };
    },
    [employeeActions.receiveEmployees]: (state, action) => {
      const { employees } = action.payload;

      return {
        ...state,
        ...normalize(employees, employeeSchemas.employeeList).entities.user,
      };
    },
    [employeeActions.receiveEmployee]: (state, action) => {
      const { employee } = action.payload;

      return {
        ...state,
        ...normalize([employee], employeeSchemas.employeeList).entities.user,
      };
    },
  },
  initialState
);

export default userEntityReducer;
