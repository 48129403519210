import Icon from "shared/components/Icon";
import { COLORS } from "shared/config/constants";

export interface LumenItem {
  title: string;
  recommendation: string;
}

export enum LumenScore {
  EXCELLENT = "EXCELLENT",
  GOOD = "GOOD",
  NEUTRAL = "NEUTRAL",
  NEGATIVE = "NEGATIVE",
  STILL_CALCULATING = "IN_PROGRESS",
  SO_MUCH_EMPTY = "IN_PROGRESS",
  NOT_APPLICABLE = "N/A",
}

export enum LumenScoreDescription {
  EXCELLENT = "All applicants meet or exceed your ideal level",
  GOOD = "All applicants meet or exceed your minimum level but not all applicants meet or exceed your ideal level",
  NEUTRAL = "Some but not all applicants meet or exceed your minimum level",
  NEGATIVE = "No applicants met your minimum level for any variables relating to income or an applicant has crucial items relating to criminal, public records, housing records, or collection accounts",
}

export enum ButtonType {
  approve,
  conditional,
  decline,
  conditionallyProceed,
  cancel,
  recalculateLumen,
  sendToPms,
  resendToPms,
}

export type Actions = {
  cancel: () => void;
  conditional: () => void;
  decline: () => void;
  approve: () => void;
  conditionallyProceed: () => void;
  recalculateLumen?: () => void;
  sendToPms: () => void;
  resendToPms: () => void;
};

export const LumenSmallIconsByScore: Record<
  LumenScore,
  typeof Icon.BulbGreenSmall
> = {
  [LumenScore.EXCELLENT]: Icon.BulbGreenSmall,
  [LumenScore.GOOD]: Icon.BulbPurpleSmall,
  [LumenScore.NEUTRAL]: Icon.BulbOrangeSmall,
  [LumenScore.NEGATIVE]: Icon.BulbRedSmall,
  [LumenScore.STILL_CALCULATING]: Icon.BulbGraySmall,
  [LumenScore.SO_MUCH_EMPTY]: Icon.BulbGraySmall,
  [LumenScore.NOT_APPLICABLE]: Icon.BulbGraySmall,
};

export const LumenLabelByScore: Record<LumenScore, string> = {
  [LumenScore.EXCELLENT]: "Excellent Application",
  [LumenScore.GOOD]: "Good Application",
  [LumenScore.NEUTRAL]: "Neutral Application",
  [LumenScore.NEGATIVE]: "Negative Application",
  [LumenScore.STILL_CALCULATING]: "Application In Progress",
  [LumenScore.SO_MUCH_EMPTY]: "Application In Progress",
  [LumenScore.NOT_APPLICABLE]: "Lumen Score Not Applicable",
};

export const LumenLabelColorByScore: Record<LumenScore, string> = {
  [LumenScore.EXCELLENT]: COLORS.violetTheme.lightGreen,
  [LumenScore.GOOD]: COLORS.violetTheme.lightViolet,
  [LumenScore.NEUTRAL]: COLORS.orange,
  [LumenScore.NEGATIVE]: COLORS.dangerRed,
  [LumenScore.STILL_CALCULATING]: COLORS.grayTheme.textColor,
  [LumenScore.SO_MUCH_EMPTY]: COLORS.grayTheme.textColor,
  [LumenScore.NOT_APPLICABLE]: COLORS.grayTheme.textColor,
};
