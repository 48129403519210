import React from "react";

import capitalize from "lodash/capitalize";
import { Helmet } from "react-helmet";

import { useGetIntegrationProvider } from "manager/pages/Marketplace/config";
import Container from "shared/components/Container";
import { BackLink } from "shared/components/Links";
import PageTitle from "shared/components/PageTitle";
import { IntegrationProvider } from "shared/config/constants";
import { EntrataLogoSvg, RealPageLogoSvg } from "shared/icons";
import { YardiLogo } from "shared/images";
import { BackLinkProps } from "shared/interfaces/misc";

import { CredentialsType } from "../shared/yardi.config";
import YardiHeaderCredentials from "../shared/YardiHeaderCredentials";
import { YardiImg, YardiTitleContainer, YardiImageContainer } from "../styled";

import { SummaryProps, TableProps } from "./yardiDashboard.config";
import YardiDashboardSummary from "./YardiDashboardSummary";
import YardiDashboardTable from "./YardiDashboardTable";

const title = "Dashboard";

export interface YardiDashboardProps {
  yardiCredentials?: CredentialsType;
  summaryData: SummaryProps;
  tableData: TableProps;
  backLink: BackLinkProps;
}

const Logo = Object.freeze({
  [IntegrationProvider.yardi]: YardiLogo,
  [IntegrationProvider.entrata]: EntrataLogoSvg,
  [IntegrationProvider.realPage]: RealPageLogoSvg,
});

const YardiDashboard = ({
  summaryData,
  tableData,
  yardiCredentials = undefined,
  backLink,
}: YardiDashboardProps) => {
  const { provider } = useGetIntegrationProvider();
  return (
    <div>
      <Container expand noPaddingBottom>
        <Helmet>
          <title>{`${capitalize(provider)} ${title}`}</title>
        </Helmet>
        {backLink && (
          <BackLink.Container>
            <BackLink {...backLink} />
          </BackLink.Container>
        )}
        <PageTitle.Container center>
          <YardiTitleContainer>
            <YardiImageContainer>
              <YardiImg src={Logo[provider]} alt="Provider" />
            </YardiImageContainer>
            {title}
          </YardiTitleContainer>
          <YardiHeaderCredentials yardiCredentials={yardiCredentials} />
        </PageTitle.Container>
      </Container>
      <YardiDashboardSummary {...summaryData} />
      <YardiDashboardTable {...tableData} />
    </div>
  );
};

export default YardiDashboard;
