import styled from '@emotion/styled'

// containers

export const ColumnParagraph = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0;
  padding: 0;
  gap: 0;
  width: 100%;
`

export const ExpiredContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.25rem;
`

export const TextColumn = styled.div`
  display: grid;
  gap: 0.5rem;
  align-content: end;
`

export const ExpiredRow = styled.div`
  display: flex;
  gap: 0.25rem;
  padding: 0;
  margin: 0;
  width: 100%;
`

// text

export const ExpiredTitle = styled.h3`
  font-size: 22px;
  margin: 0;
  padding: 0;
  line-height: normal;
`

export const ExpiredParagraph = styled.p`
  font-size: 15px;
  margin: 0;
  padding: 0;
  line-height: normal;
`
