import React from "react";

import PropTypes from "prop-types";

import Icon from "shared/components/Icon";
import Table from "shared/components/Table/Table/Table";

const YardiActionTrackingTable = ({
  yardiActionTracking,
  isLoadingActionTracking,
}) => {
  return (
    <Table
      alt
      withBorder
      noClickableRows
      columns={[
        {
          title: "Created At",
          dataIndex: "createdAt",
          key: "createdAt",
        },
        {
          title: "Action Type",
          dataIndex: "actionType",
          key: "actionType",
        },
        {
          title: "First Contact",
          dataIndex: "firstContact",
          key: "firstContact",
        },
      ]}
      rows={yardiActionTracking.map((action) => ({
        key: action.id,
        values: [action.createdAt, action.actionType, action.firstContact],
      }))}
      loading={isLoadingActionTracking}
      placeholder={{
        label: "Action Tracking",
        Icon: Icon.NoDataIcon,
      }}
      columnBreakpoints={{
        xs: {
          column1: "actionType",
          column2: "firstContact",
        },
        sm: ["actionType", "firstContact"],
      }}
      pagination={false}
    />
  );
};

YardiActionTrackingTable.propTypes = {
  yardiActionTracking: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  isLoadingActionTracking: PropTypes.bool.isRequired,
};

export default YardiActionTrackingTable;
