import styled from "@emotion/styled";

import { Checkbox as BaseCheckbox } from "shared/components/Form/Checkbox";

export const Checkbox = styled(BaseCheckbox)({
  display: "inline",
});

export const CollapsingContainer = styled("div")({
  width: "600px",
});
