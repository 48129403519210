import axios from "axios";

import ROUTES from "renter/config/apiRoutes";
import { API_URL } from "shared/lib/api/setupApi";
import { transformRoute } from "shared/utils/routing";

const api = {
  getEmploymentData: ({ applicationId }) =>
    axios
      .get(
        `${API_URL}${transformRoute(ROUTES.employmentData, {
          applicationId,
        })}`
      )
      .then(({ data }) => data),
  getArgyleSetupData: ({ applicationId }) =>
    axios
      .post(`${API_URL}${ROUTES.argyleSetupData}`, { applicationId })
      .then(({ data }) => data),
  getArgyleSyncStatus: ({ applicationId }) =>
    axios
      .get(
        `${API_URL}${transformRoute(ROUTES.argyleSyncStatus, {
          applicationId,
        })}`
      )
      .then(({ data }) => data),
  postArgyleEvent: ({ argyleUserId, applicationId, argyleEvent }) =>
    axios
      .post(
        `${API_URL}${transformRoute(ROUTES.postArgyleEvent, {
          applicationId,
        })}`,
        {
          argyleUserId,
          event: argyleEvent,
        }
      )
      .then(({ data }) => data),
  postStartArgyleSync: ({ applicationId }) =>
    axios
      .post(
        `${API_URL}${transformRoute(ROUTES.argyleStartSync, {
          applicationId,
        })}`
      )
      .then(({ data }) => data),
  postCompleteLinkEmploymentStep: ({ applicationId, neverBeenEmployed }) =>
    axios
      .post(
        `${API_URL}${transformRoute(ROUTES.completeLinkEmploymentStep, {
          applicationId,
        })}`,
        {
          neverBeenEmployed,
        }
      )
      .then(({ data }) => data),
};

export default api;
