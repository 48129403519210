import { NOTIFICATIONS } from "shared/config/constants";
import { useNotFoundErrorHandler } from "shared/utils/queries.utils";
import { openNotification } from "shared/utils/ui";

const useQueryErrorHandler = () => {
  const notFoundErrorHandler = useNotFoundErrorHandler();

  const queryErrorHandler = (error, message) => {
    if (!notFoundErrorHandler(error)) {
      openNotification(message, NOTIFICATIONS.error);
    }
  };

  return queryErrorHandler;
};

export default useQueryErrorHandler;
