import axios from "axios";

import ROUTES from "manager/config/apiRoutes";
import { API_URL } from "manager/lib/api";

const api = {
  getCreditPublicRecordOptions: () =>
    axios.get(`${API_URL}${ROUTES.creditPublicRecordOptions}`),
  getCreditPublicRecordBankruptcyOptions: () =>
    axios.get(`${API_URL}${ROUTES.creditPublicRecordBankruptcyOptions}`),
  getCriminalRecordSeverityOptions: () =>
    axios.get(`${API_URL}${ROUTES.criminalRecordSeverityOptions}`),
  getSystemAuditSourceTypes: () =>
    axios.get(`${API_URL}${ROUTES.getSystemAuditSourceTypes}`),
  getDashboardPeriods: () =>
    axios
      .get(`${API_URL}${ROUTES.getDashboardPeriods}`)
      .then(({ data }) => data),
  getApplicantTypes: async () =>
    axios.get(`${API_URL}${ROUTES.applicantTypes}`).then(({ data }) => data),
};

export default api;
