import styled from "@emotion/styled";

import Icon from "shared/components/Icon";
import { MEDIA_QUERIES } from "shared/config/constants";

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 60px;

  ${MEDIA_QUERIES.w.gteSm} {
    display: none;
  }
`;

const FilterIcon = styled(Icon.FilterIcon)`
  margin-right: 10px;
`;

const SearchContainer = styled.div`
  margin: 15px 0;

  @media only screen and (max-width: 320px) {
    margin-left: -15px;
  }
`;

export { Container, SearchContainer, FilterIcon };
