import { css } from "@emotion/core";
import styled from "@emotion/styled";

import FlexContainer from "shared/components/FlexContainer";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

export const CheckboxContainer = styled.div<{ isCheckboxEnable?: boolean }>(
  (props) => ({
    marginRight: "16px",
    color: COLORS.primary,
    fontSize: "17px",
    [MEDIA_QUERIES.w.ltLg]: {
      paddingTop: "32px",
      paddingBottom: props.isCheckboxEnable ? "22px" : "32px",
    },
    [MEDIA_QUERIES.w.gteLg]: {
      paddingTop: "40px",
      paddingBottom: props.isCheckboxEnable ? "22px" : "40px",
    },
  })
);

export const Ul = styled.ul`
  padding-left: 40px;
  margin-bottom: 0;
`;

export const Li = styled.li<{ isLastItem?: boolean }>`
  margin-left: 12px;
  font-size: 15px;
  color: ${COLORS.primary};
  ${MEDIA_QUERIES.w.ltLg} {
    ${({ isLastItem }) =>
      isLastItem
        ? css`
            padding-bottom: 32px;
          `
        : css`
            padding-bottom: 24px;
          `}
  }

  ${MEDIA_QUERIES.w.gteLg} {
    ${({ isLastItem }) =>
      isLastItem
        ? css`
            padding-bottom: 40px;
          `
        : css`
            padding-bottom: 24px;
          `}
  }
`;

export const CommonChildStyles = css`
  margin-top: 17px;
  font-weight: 300;
`;

export const CheckboxLabel = styled(FlexContainer)`
  ${CommonChildStyles};

  .extra-info {
    margin-left: 10px;
  }
`;

export const RadioGroupContainer = styled.div`
  ${CommonChildStyles};
`;
