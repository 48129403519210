import { createAction } from "redux-actions";

import * as types from "./types";

// Get property types

export const getPropertyTypes = createAction(types.GET_PROPERTY_TYPES);
export const getPropertyTypesRequest = createAction(
  types.GET_PROPERTY_TYPES_REQUEST
);
export const getPropertyTypesSuccess = createAction(
  types.GET_PROPERTY_TYPES_SUCCESS,
  (propertyTypes) => propertyTypes
);
export const getPropertyTypesError = createAction(
  types.GET_PROPERTY_TYPES_ERROR,
  ({ error }) => ({ error })
);
export const receivePropertyTypes = createAction(
  types.RECEIVE_PROPERTY_TYPES,
  (propertyTypes) => propertyTypes
);

// Get applicant types

export const getApplicantTypes = createAction(types.GET_APPLICANT_TYPES);

export const getApplicantTypesRequest = createAction(
  types.GET_APPLICANT_TYPES_REQUEST
);

export const getApplicantTypesSuccess = createAction(
  types.GET_APPLICANT_TYPES_SUCCESS,
  (applicantTypes) => applicantTypes
);

export const getApplicantTypesError = createAction(
  types.GET_APPLICANT_TYPES_ERROR,
  ({ error }) => ({ error })
);

export const receiveApplicantTypes = createAction(
  types.RECEIVE_APPLICANT_TYPES,
  (applicantTypes) => applicantTypes
);

// Get required attachment types

export const getRequiredAttachmentTypes = createAction(
  types.GET_REQUIRED_ATTACHMENT_TYPES
);
export const getRequiredAttachmentTypesRequest = createAction(
  types.GET_REQUIRED_ATTACHMENT_TYPES_REQUEST
);
export const getRequiredAttachmentTypesSuccess = createAction(
  types.GET_REQUIRED_ATTACHMENT_TYPES_SUCCESS,
  (requiredAttachmentTypes) => requiredAttachmentTypes
);
export const getRequiredAttachmentTypesError = createAction(
  types.GET_REQUIRED_ATTACHMENT_TYPES_ERROR,
  ({ error }) => ({ error })
);
export const receiveRequiredAttachmentTypes = createAction(
  types.RECEIVE_REQUIRED_ATTACHMENT_TYPES,
  (requiredAttachmentTypes) => requiredAttachmentTypes
);

// Get application statuses

export const getApplicationStatuses = createAction(
  types.GET_APPLICATION_STATUSES
);
export const getApplicationStatusesRequest = createAction(
  types.GET_APPLICATION_STATUSES_REQUEST
);
export const getApplicationStatusesSuccess = createAction(
  types.GET_APPLICATION_STATUSES_SUCCESS,
  (applicationStatuses) => applicationStatuses
);
export const getApplicationStatusesError = createAction(
  types.GET_APPLICATION_STATUSES_ERROR,
  ({ error }) => ({ error })
);
export const receiveApplicationStatuses = createAction(
  types.RECEIVE_APPLICATION_STATUSES,
  (applicationStatuses) => applicationStatuses
);

// Get deal statuses
export const getDealStatuses = createAction(types.GET_DEAL_STATUSES);
export const getDealStatusesRequest = createAction(
  types.GET_DEAL_STATUSES_REQUEST
);
export const getDealStatusesSuccess = createAction(
  types.GET_DEAL_STATUSES_SUCCESS,
  (dealStatuses) => dealStatuses
);
export const getDealStatusesError = createAction(
  types.GET_DEAL_STATUSES_ERROR,
  ({ error }) => ({ error })
);
export const receiveDealStatuses = createAction(
  types.RECEIVE_DEAL_STATUSES,
  (dealStatuses) => dealStatuses
);

export const getUnitStatuses = createAction(types.GET_UNIT_STATUSES);
export const getUnitStatusesRequest = createAction(
  types.GET_UNIT_STATUSES_REQUEST
);
export const getUnitStatusesSuccess = createAction(
  types.GET_UNIT_STATUSES_SUCCESS,
  (unitStatuses) => unitStatuses
);
export const getUnitStatusesError = createAction(
  types.GET_UNIT_STATUSES_ERROR,
  ({ error }) => ({ error })
);
export const receiveUnitStatuses = createAction(
  types.RECEIVE_UNIT_STATUSES,
  (unitStatuses) => unitStatuses
);
