import axios from "axios";

import { VEGA_BASE_URL } from "shared/config/apiRoutes";

const DOCUMENT_SERVICE_PATH = "/api/document";
const OCR_JOB_PATH = `${DOCUMENT_SERVICE_PATH}/ocr-job`;

const api = {
  getDocuments: (owner: string) =>
    axios
      .get(`${VEGA_BASE_URL}${DOCUMENT_SERVICE_PATH}`, {
        params: {
          owner,
        },
      })
      .then(({ data }) => data),
  getOcrJobs: (person: string) =>
    axios
      .get(`${VEGA_BASE_URL}${OCR_JOB_PATH}`, {
        params: {
          person,
        },
      })
      .then(({ data }) => data),
};

export default api;
