/* eslint-disable max-lines */
import dayjs from "dayjs";
import flatMap from "lodash/flatMap";
import isEmpty from "lodash/isEmpty";
import kebabCase from "lodash/kebabCase";
import orderBy from "lodash/orderBy";
import values from "lodash/values";
import { queryCache, useMutation, useQuery } from "react-query";

import { SubmitApplicationError } from "renter/interfaces/api/application";
import api from "renter/lib/api";
import { NOTIFICATIONS } from "shared/config/constants";
import { activeApplicationIdStorage } from "shared/utils/application";
import { cacheActiveCompany } from "shared/utils/auth";
import { openNotification } from "shared/utils/ui";

import { GET_APPLICANT_USER_PROFILE } from "./applicantQueries";

const GET_MARKETING_SOURCES = "getMarketingSources";
const GET_SKIP_INCOME_REASONS = "getSkipIncomeReasons";
const GET_APPLICATION_INVITATION = "getApplicationInvitation";
const GET_APPLICATION_PROPERTY_DETAILS = "getApplicationPropertyDetails";
const GET_RENTER_PROFILE = "getRenterProfile";
const GET_APPLICATION_CONDITIONAL_REQUEST = "getApplicationConditionalRequest";
const GET_ACTIVE_USER_APPLICANT_TYPES = "getActiveUserApplicantTypes";
const GET_APPLICATION = "getApplication";
const GET_APPLICANT_LEADS = "getApplicantLeads";
const GET_APPLICATION_SUMMARY = "getApplicationSummary";
const GET_APPLICATION_FULL = "getApplicationFull";
const GET_PAYMENT_BY_ID = "getPaymentById";

export const QUERY_KEYS = Object.freeze({
  getRenterProfile: (id: string | number) => [GET_RENTER_PROFILE, Number(id)],
  getApplicationInvitation: (id: string | number) => [
    GET_APPLICATION_INVITATION,
    Number(id),
  ],
  getApplicationConditionalRequest: (id: string | number) => [
    GET_APPLICATION_CONDITIONAL_REQUEST,
    Number(id),
  ],
  getActiveUserApplicantTypes: (id: string | number) => [
    GET_ACTIVE_USER_APPLICANT_TYPES,
    Number(id),
  ],
  getApplicationSummary: (id: string | number) => [
    GET_APPLICATION_SUMMARY,
    Number(id),
  ],
});

const useGetApplicationInvitation = (
  applicationId: string | number,
  enabled = true
) => {
  const {
    data: application,
    isLoading: isApplicationLoading,
    isFetching: isApplicationFetching,
    isError: isApplicationError,
    refetch: refetchApplicationInvitation,
  } = useQuery(
    QUERY_KEYS.getApplicationInvitation(applicationId),
    () => api.getApplicationInvitation({ applicationId }),
    {
      enabled,
      onSuccess: (application) => {
        queryCache.invalidateQueries(GET_APPLICANT_USER_PROFILE);
        cacheActiveCompany({
          id: application.companyId,
          name: application.companyName,
          slug: kebabCase(application.companyName)
        });
        activeApplicationIdStorage.addItem(
          activeApplicationIdStorage.storageKey,
          applicationId
        );
      },
      onError: () =>
        openNotification("Failed to load application", NOTIFICATIONS.error),
    }
  );
  return {
    application,
    isApplicationLoading,
    isApplicationFetching,
    isApplicationError,
    refetchApplicationInvitation,
  };
};

const useGetApplicationMarketingSources = (applicationId) => {
  const { data: marketingSources, isLoading } = useQuery(
    [GET_MARKETING_SOURCES, applicationId],
    () => api.getApplicationMarketingSources({ applicationId }),
    {
      onError: () =>
        openNotification(
          "Failed to load marketing sources",
          NOTIFICATIONS.error
        ),
    }
  );
  return { marketingSources: marketingSources || [], isLoading };
};

const useGetApplicationLogo = (applicationId, enabled = false) => {
  const response = useQuery(
    ["getApplicationLogo", applicationId],
    () => api.getApplicationLogo({ applicationId }),
    {
      onError: () =>
        openNotification(
          "Failed to load application logo",
          NOTIFICATIONS.error
        ),
      enabled,
      cacheTime: 5 * 60 * 1000,
      staleTime: 0,
    }
  );

  return response;
};

const useSetScreeningAgreementDate = (applicationId: string | number) => {
  const [mutate, { isLoading: isSettingAgreementDate }] = useMutation(
    api.setScreeningAgreementDate,
    {
      onSuccess: () =>
        queryCache.invalidateQueries(
          QUERY_KEYS.getApplicationInvitation(applicationId)
        ),
      onError: () => {
        openNotification(
          "Failed to set screening agreement date",
          NOTIFICATIONS.error
        );
      },
    }
  );

  return {
    setScreeningAgreementDate: mutate,
    isSettingAgreementDate,
  };
};

const useSetApplicationNotes = () => {
  const [setApplicationNotes, { isLoading: isSettingNotes }] = useMutation(
    api.setApplicationNotes,
    {
      onError: () => {
        openNotification(
          "Failed to update application notes",
          NOTIFICATIONS.error
        );
      },
    }
  );

  return {
    setApplicationNotes,
    isSettingNotes,
  };
};

const useInviteDependents = ({ onSuccess }) => {
  const [inviteDependents, { isLoading: isInvitingDependents }] = useMutation(
    api.inviteDependents,
    {
      onSuccess: () => {
        onSuccess();
        openNotification("Successfully added dependents", NOTIFICATIONS.info);
      },
      onError: () => {
        openNotification("Failed to add dependents", NOTIFICATIONS.error);
      },
    }
  );

  return {
    inviteDependents,
    isInvitingDependents,
  };
};

const useGetIncomeSkipReasons = (applicationId, enabled = true) => {
  const { data: incomeSkipReasons, isLoading } = useQuery(
    [GET_SKIP_INCOME_REASONS, Number(applicationId)],
    () => api.getIncomeSkipReasons({ applicationId }),
    {
      enabled,
      onError: () =>
        openNotification(
          "Failed to load income skip sources for application",
          NOTIFICATIONS.error
        ),
    }
  );
  return { incomeSkipReasons, isIncomeSkipReasonsLoading: isLoading };
};

const useSetIncomeSkipReasons = (applicationId: string | number) => {
  const [setIncomeSkipReasons, { isLoading: isSetIncomeSkipReasonsLoading }] =
    useMutation(api.setIncomeSkipReasons, {
      onSuccess: () => {
        openNotification(
          "Successfully submitted reasons to skip income verification",
          NOTIFICATIONS.info
        );
        queryCache.invalidateQueries(
          QUERY_KEYS.getApplicationInvitation(applicationId)
        );
      },
      onError: () => {
        openNotification(
          "Failed to submit reasons to skip income verification",
          NOTIFICATIONS.error
        );
      },
    });

  return {
    setIncomeSkipReasons,
    isSetIncomeSkipReasonsLoading,
  };
};

const useConfirmApplicantGroup = (applicationId: string | number) => {
  const [confirmApplicantGroup, { isLoading: isConfirmingApplicantGroup }] =
    useMutation(api.confirmApplicantGroup, {
      onSuccess: () =>
        queryCache.invalidateQueries(
          QUERY_KEYS.getApplicationInvitation(applicationId)
        ),
      onError: () => {
        openNotification(
          "Failed to confirm applicant group",
          NOTIFICATIONS.error
        );
      },
    });

  return {
    confirmApplicantGroup,
    isConfirmingApplicantGroup,
  };
};

const useTrackCurrentApplicationStep = () => {
  const [trackCurrentApplicationStep] = useMutation(
    api.trackCurrentApplicationStep
  );

  return {
    trackCurrentApplicationStep,
  };
};

const useGetApplicationPropertyDetails = (applicationId) => {
  const { data: propertyDetails, isLoading: isPropertyDetailsLoading } =
    useQuery(
      [GET_APPLICATION_PROPERTY_DETAILS, Number(applicationId)],
      () => api.getApplicationPropertyDetails(applicationId),
      {
        onError: () =>
          openNotification(
            "Failed to load the application property details",
            NOTIFICATIONS.error
          ),
      }
    );
  return { propertyDetails, isPropertyDetailsLoading };
};

const useFillRenterProfile = (applicationId: string | number) => {
  const [fillRenterProfile] = useMutation(api.fillRenterProfile, {
    onSuccess: () => {
      queryCache.invalidateQueries(QUERY_KEYS.getRenterProfile(applicationId));
      queryCache.invalidateQueries(
        QUERY_KEYS.getApplicationInvitation(applicationId)
      );
    },
    onError: (error) => {
      const errors = Object.values(error);
      const errorMessagesList =
        Object.values(errors?.[1])?.filter((error) => !isEmpty(error)) || [];
      const stringValues = flatMap(errorMessagesList, values);
      if (stringValues?.length > 0) {
        stringValues?.map((error) =>
          openNotification(error, NOTIFICATIONS.error)
        );
      } else {
        openNotification(
          "Please fill all the required fields",
          NOTIFICATIONS.error
        );
      }
    },
  });
  return {
    fillRenterProfile,
  };
};

const useGetRenterProfile = ({
  applicationId,
}: {
  applicationId: string | number;
}) => {
  const { data, isLoading, error, refetch } = useQuery(
    QUERY_KEYS.getRenterProfile(applicationId),
    () => api.getRenterProfile(applicationId),
    {
      onError: () =>
        openNotification(
          "Failed to load your renter profile.",
          NOTIFICATIONS.error
        ),
    }
  );

  return {
    renterProfile: data && {
      ...data,
      employmentHistory: orderBy(
        data.employmentHistory,
        ["isPreviousEmployment", (item) => dayjs(item.startDate).unix()],
        ["asc", "desc"]
      ),
    },
    isRenterProfileLoading: isLoading,
    renterProfileError: Boolean(error),
    refetchRenterProfile: refetch,
  };
};

const useUploadApplicationAttachment = (applicationId: string | number) => {
  const [uploadApplicationAttachment, { isLoading }] = useMutation(
    api.addApplicationAttachment,
    {
      onSuccess: () =>
        queryCache.invalidateQueries(
          QUERY_KEYS.getApplicationInvitation(applicationId)
        ),
      onError: () =>
        openNotification(
          "Failed to upload the attachment.",
          NOTIFICATIONS.error
        ),
    }
  );

  return {
    uploadApplicationAttachment,
    isUploadApplicationAttachmentLoading: isLoading,
  };
};

const useDeleteApplicationAttachment = (applicationId: string | number) => {
  const [deleteApplicationAttachment, { isLoading }] = useMutation(
    api.deleteApplicationAttachment,
    {
      onSuccess: () =>
        queryCache.invalidateQueries(
          QUERY_KEYS.getApplicationInvitation(applicationId)
        ),
      onError: () =>
        openNotification(
          "Failed to delete the attachment.",
          NOTIFICATIONS.error
        ),
    }
  );

  return {
    deleteApplicationAttachment,
    isDeleteApplicationAttachmentLoading: isLoading,
  };
};

const useSkipApplicationAttachment = (applicationId: string | number) => {
  const [skipApplicationAttachment] = useMutation(
    api.skipApplicationAttachment,
    {
      onSuccess: () =>
        queryCache.invalidateQueries(
          QUERY_KEYS.getApplicationInvitation(applicationId)
        ),
      onError: () =>
        openNotification("Failed to skip the attachment.", NOTIFICATIONS.error),
    }
  );

  return {
    skipApplicationAttachment,
  };
};

const useSubmitApplication = (applicationId: number) => {
  const [submitApplication, { isLoading }] = useMutation(
    api.submitApplication,
    {
      onSuccess: () =>
        queryCache.invalidateQueries(
          QUERY_KEYS.getApplicationInvitation(applicationId)
        ),
      onError: (error: SubmitApplicationError) => {
        if (error?.errors?.nonFieldErrors instanceof Array) {
          error?.errors?.nonFieldErrors.forEach((errorMessage) =>
            openNotification(errorMessage, NOTIFICATIONS.error)
          );
        } else {
          openNotification(
            error?.errors?.error || "Failed to submit application",
            NOTIFICATIONS.error
          );
        }
      },
    }
  );

  return {
    submitApplication,
    isSubmitApplicationLoading: isLoading,
  };
};

const useGetApplicationConditionalRequest = ({
  applicationId,
}: {
  applicationId: string | number;
}) => {
  const { data, isLoading, refetch } = useQuery(
    QUERY_KEYS.getApplicationConditionalRequest(applicationId),
    () => api.getConditionalRequest(applicationId),
    {
      onError: () => {},
    }
  );

  return {
    applicationConditionalRequestData: data,
    isApplicationConditionalRequestDataLoading: isLoading,
    refetchApplicationConditionalRequest: refetch,
  };
};

const useSubmitApplicationConditionalRequest = (applicationId: number) => {
  const [submitApplicationConditionalRequest] = useMutation(
    api.submitConditionalRequest,
    {
      onSuccess: () =>
        queryCache.invalidateQueries(
          QUERY_KEYS.getApplicationInvitation(applicationId)
        ),
      onError: () =>
        openNotification(
          "There are errors with your application.",
          NOTIFICATIONS.error
        ),
    }
  );

  return {
    submitApplicationConditionalRequest,
  };
};

const useGetActiveUserApplicantTypes = (id: string | number) => {
  const { data, isLoading, isError } = useQuery(
    QUERY_KEYS.getActiveUserApplicantTypes(id),
    () => api.getActiveUserApplicantTypes(id),
    {
      enabled: Boolean(id),
      onError: () =>
        openNotification("Failed to load applicant types", NOTIFICATIONS.error),
    }
  );

  return {
    activeUserApplicantTypes: data,
    isActiveUserApplicantTypesLoading: isLoading,
    isActiveUserApplicantTypesError: isError,
  };
};

const useSetApplicantType = (applicationId: string | number) => {
  const [setApplicantType] = useMutation(api.setApplicantType, {
    onSuccess: () => {
      queryCache.invalidateQueries(
        QUERY_KEYS.getApplicationInvitation(applicationId)
      );
      queryCache.invalidateQueries(
        QUERY_KEYS.getActiveUserApplicantTypes(applicationId)
      );
    },
    onError: () =>
      openNotification("Failed to set applicant type.", NOTIFICATIONS.error),
  });

  return {
    setApplicantType,
  };
};

const useGetApplications = () => {
  const { data, isLoading, refetch } = useQuery(
    [GET_APPLICATION],
    api.getApplications,
    {
      onError: () =>
        openNotification("Failed to load applicant types", NOTIFICATIONS.error),
    }
  );

  return {
    applications: data,
    isApplicationLoading: isLoading,
    refetchApplications: refetch,
  };
};

const useGetApplicantLeads = () => {
  const { data, isLoading, refetch } = useQuery(
    [GET_APPLICANT_LEADS],
    api.getApplicantLeads,
    {
      onError: () =>
        openNotification("Failed to load applications", NOTIFICATIONS.error),
    }
  );

  return {
    applications: data,
    isLoading,
    refetchApplications: refetch,
  };
};

const useGetApplicationSummary = (id: string | number) => {
  const { data, isLoading, isFetching, refetch } = useQuery(
    QUERY_KEYS.getApplicationSummary(id),
    () => api.getApplicationSummary(id),
    {
      onError: () =>
        openNotification(
          "Failed to load application summary",
          NOTIFICATIONS.error
        ),
    }
  );

  return {
    applicationSummary: data,
    isApplicationSummaryLoading: isLoading,
    isApplicationSummaryFetching: isFetching,
    refetchApplicationSummary: refetch,
  };
};

const useGetApplicationFull = (id: string) => {
  const { data, isLoading } = useQuery(
    [GET_APPLICATION_FULL, { id }],
    () => api.getApplicationFull(id),
    {
      onError: () =>
        openNotification(
          "Failed getting full application",
          NOTIFICATIONS.error
        ),
    }
  );
  return {
    application: data?.data,
    isApplicationLoading: isLoading,
  };
};

const useGetPaymentById = (id, config) => {
  const { refetchInterval } = config || {};
  const { data, isLoading } = useQuery(
    [GET_PAYMENT_BY_ID, { id }],
    () => api.getPaymentById(id),
    {
      onError: () =>
        openNotification("Failed getting payment by id", NOTIFICATIONS.error),
      refetchInterval,
    }
  );

  return {
    payment: data?.data,
    isPaymentLoading: isLoading,
  };
};

export {
  useGetApplicationInvitation,
  useGetApplicationMarketingSources,
  useGetApplicationLogo,
  useSetScreeningAgreementDate,
  useSetApplicationNotes,
  useInviteDependents,
  useGetIncomeSkipReasons,
  useSetIncomeSkipReasons,
  useConfirmApplicantGroup,
  useTrackCurrentApplicationStep,
  useGetApplicationPropertyDetails,
  useFillRenterProfile,
  useGetRenterProfile,
  useUploadApplicationAttachment,
  useDeleteApplicationAttachment,
  useSkipApplicationAttachment,
  useSubmitApplication,
  useGetApplicationConditionalRequest,
  useSubmitApplicationConditionalRequest,
  useGetActiveUserApplicantTypes,
  useSetApplicantType,
  useGetApplications,
  useGetApplicantLeads,
  useGetApplicationSummary,
  useGetApplicationFull,
  useGetPaymentById,
};
