import React from "react";

import { SorterResult, PaginationConfig } from "antd/lib/table";

import ROUTES from "manager/config/routes";
import {
  BluemoonPropertiesRequest,
  BluemoonVeroProperty,
} from "manager/interfaces/api/bluemoon";
import Icon from "shared/components/Icon";
import { UnderlineLink } from "shared/components/Links";
import Table from "shared/components/Table";
import Tooltip from "shared/components/Tooltip";
import { RegularText } from "shared/components/Typography";
import TablePage from "shared/templates/TablePage";
import { transformRoute } from "shared/utils/routing";

import { Filters } from "./interfaces";
import { BaseDeleteIcon } from "./styled";

interface BluemoonBoundPropertiesProps {
  backLink: { children: string; to: string };

  states: { label: string; key: string }[];
  searchParams: BluemoonPropertiesRequest;
  handleSearchParams: (key: string, value: string) => void;
  hasInputValue: boolean;
  onPaginationChange: (currentPage: number) => void;
  pagination: {
    page: number;
    pageSize: number;
  };
  propertiesCount: number;
  properties: BluemoonVeroProperty[];
  isBluemoonPropertiesFetching: boolean;
  onUnbindProperties: (id: number) => void;
  onTableChange: (
    tablePagination: PaginationConfig,
    tableFilters: Filters,
    sorter: SorterResult<Filters>
  ) => void;
}

const BluemoonBoundProperties = ({
  backLink,
  states,
  searchParams,
  handleSearchParams,
  hasInputValue,
  onPaginationChange,
  pagination,
  propertiesCount,
  properties,
  isBluemoonPropertiesFetching,
  onUnbindProperties,
  onTableChange,
}: BluemoonBoundPropertiesProps) => {
  const getRows = () =>
    (properties || []).map((property) => ({
      key: property.id,
      values: [
        <RegularText>
          <UnderlineLink
            to={transformRoute(ROUTES.property, { id: property.id })}
          >
            {property.name}
          </UnderlineLink>
        </RegularText>,
        <RegularText>{property.state}</RegularText>,
        <RegularText strong>
          {property?.bluemoonProperty?.licenseCode}
        </RegularText>,
        <RegularText>{property?.bluemoonProperty?.fullAddress}</RegularText>,
        <Tooltip
          title="Unbind property and remove from bound properties list"
          placement="topRight"
        >
          <BaseDeleteIcon onClick={() => onUnbindProperties(property.id)} />
        </Tooltip>,
      ],
    }));

  return (
    <TablePage
      title="Bound Properties"
      subtitle="Edit and view bound properties in VERO"
      backLink={backLink}
      tables={[
        {
          filterBar: {
            search: {
              action: (value: string) =>
                handleSearchParams("searchValue", value),
              placeholder: "Search by property, address, or license...",
              width: 440,
              debounce: true,
              value: searchParams.searchValue,
              disabled: isBluemoonPropertiesFetching,
            },
            dropdownFilters: [
              {
                key: "state",
                label: "State",
                value: searchParams.state,
                onChange: (selectedFilter: string) =>
                  handleSearchParams(
                    "state",
                    selectedFilter === "" ? null : selectedFilter
                  ),
                items: states,
                disabled: isBluemoonPropertiesFetching,
              },
            ],
          },
          node: (
            <Table
              alt
              loading={isBluemoonPropertiesFetching}
              searching={hasInputValue}
              noClickableRows
              columns={[
                {
                  title: "VERO Property",
                  dataIndex: "property",
                  key: "property",
                  sorter: true,
                },
                {
                  title: "State",
                  dataIndex: "state",
                  key: "state",
                  sorter: true,
                },
                {
                  title: "License",
                  dataIndex: "license",
                  key: "license",
                  sorter: true,
                },
                {
                  title: "Bluemoon Property",
                  dataIndex: "bluemoonProperty",
                  key: "bluemoonProperty",
                  sorter: true,
                },
                {
                  title: "",
                  dataIndex: "action",
                  key: "action",
                  width: 80,
                },
              ]}
              rows={getRows()}
              columnBreakpoints={{
                xs: {
                  column1: "property",
                  column2: "lisenseId",
                },
                sm: [
                  "property",
                  "state",
                  "license",
                  "bluemoonProperty",
                  "action",
                ],
                md: [
                  "property",
                  "state",
                  "license",
                  "bluemoonProperty",
                  "action",
                ],
              }}
              placeholder={{
                Icon: Icon.NoDataIcon,
                label: "Bound Properties",
              }}
              pagination={{
                current: pagination?.page,
                onChange: onPaginationChange,
                total: propertiesCount,
                defaultPageSize: pagination?.pageSize,
              }}
              onChange={onTableChange}
            />
          ),
        },
      ]}
    />
  );
};

export default BluemoonBoundProperties;
