import dayjs from "dayjs";
import * as Yup from "yup";

import {
  validFirstName,
  validLastName,
} from "shared/forms/validation/fieldValidators";

export const isGreaterThan18 = (birthdate: string) =>
  birthdate && dayjs().diff(dayjs(birthdate, "YYYY-MM-DD"), "years") >= 18;

export const isLessThan100 = (birthdate: string) =>
  birthdate && dayjs().diff(dayjs(birthdate, "YYYY-MM-DD"), "years") < 100;

export const ValidationSchema = {
  occupants: Yup.array()
    .of(
      Yup.object().shape({
        firstName: validFirstName.required("First name is required."),
        lastName: validLastName.required("Last name is required."),
        birthdate: Yup.string()
          .nullable()
          .required("Date of birth is required."),
        relationship: Yup.string().required("Relationship is required."),
        email: Yup.string().when("birthdate", {
          is: (birthdate) => isGreaterThan18(birthdate),
          then: Yup.string()
            .email("You must enter a valid email.")
            .required("Email is required."),
        }),
      })
    )
    .required("At least one occupant is required"),
};

export const emptyOccupant = {
  firstName: "",
  lastName: "",
  birthdate: "",
  relationship: "",
};

export const initialValues = {
  occupants: [emptyOccupant],
};
