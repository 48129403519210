import { useQuery } from "react-query";

import { YardiUnitTypesRequest } from "manager/interfaces/api";
import api from "manager/lib/api";
import { NOTIFICATIONS } from "shared/config/constants";
import { openNotification } from "shared/utils/ui";

const GET_YARDI_UNIT_TYPES = "GET_YARDI_UNIT_TYPES";

export const useGetYardiUnitTypes = (params: YardiUnitTypesRequest) => {
  const { data: unitTypes, isLoading: isUnitTypesLoading } = useQuery(
    [GET_YARDI_UNIT_TYPES, params],
    () => api.getYardiUnitTypes(params),
    {
      onError: () =>
        openNotification("Failed to load unit types", NOTIFICATIONS.error),
    }
  );

  return {
    unitTypes,
    isUnitTypesLoading,
  };
};
