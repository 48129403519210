import React, { useState } from "react";

import PropTypes from "prop-types";

import Icon from "shared/components/Icon";

import { Panel, Toggle, PanelInner, PanelInnerWrapper } from "./styled";

export interface SlidingPanelProps {
  children?: Node | React.ReactNode;
  width?: string | number;
  minWidth?: string | number;
  height?: string | number;
}

const SlidingPanel = ({
  children = undefined,
  width = "340px",
  ...props
}: SlidingPanelProps) => {
  const [isCollapsed, setCollapsed] = useState(false);
  const onToggle = () => setCollapsed(!isCollapsed);

  return (
    // @ts-ignore
    <Panel isCollapsed={isCollapsed} {...props} width={width}>
      <Toggle onClick={onToggle} data-testid="toggle-btn">
        {isCollapsed ? <Icon.ArrowRightUpIcon /> : <Icon.ArrowLeftUpIcon />}
      </Toggle>
      <PanelInnerWrapper>
        {/* @ts-ignore */}
        <PanelInner width={width}>{children}</PanelInner>
      </PanelInnerWrapper>
    </Panel>
  );
};

SlidingPanel.propTypes = {
  children: PropTypes.node,
  width: PropTypes.string,
  minWidth: PropTypes.string,
  height: PropTypes.string,
};

SlidingPanel.defaultProps = {
  children: undefined,
  width: "340px",
  minWidth: "4px",
  height: "auto",
};

export default SlidingPanel;
