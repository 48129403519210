import React from "react";

import { BREAKPOINT_TYPES } from "shared/config/constants";
import withBreakpoint from "shared/hocs/withBreakpoint";

import CustomHeader from "../CustomHeader";
import DefaultMobileHeader from "../MobileHeader";

const BREAKPOINTS = {
  MOBILE_HEADER: [BREAKPOINT_TYPES.xs, BREAKPOINT_TYPES.sm],
  CUSTOM_HEADER: [
    BREAKPOINT_TYPES.md,
    BREAKPOINT_TYPES.lg,
    BREAKPOINT_TYPES.xl,
    BREAKPOINT_TYPES.xxl,
    BREAKPOINT_TYPES.xxxl,
  ],
};

export interface IHeader {
  breakpoint?: BREAKPOINT_TYPES;
  user: any;
  backLink?: any;
  sidebarItems: any[];
  groupedRoutes?: any;
  logoLink: string;
  example?: boolean;
  headerAction?: React.ReactNode;
  extraControls?: React.ReactNode;
  MobileHeader?: React.ElementType;
  [key: string]: any;
}

export const Header = ({
  breakpoint,
  backLink = undefined,
  user,
  sidebarItems,
  groupedRoutes,
  example = false,
  logoLink,
  headerAction = undefined,
  extraControls = undefined,
  MobileHeader = DefaultMobileHeader,
}: IHeader) => (
  <>
    {BREAKPOINTS.MOBILE_HEADER.indexOf(breakpoint) > -1 && (
      <MobileHeader
        data-testid="mobile-header"
        user={user}
        sidebarItems={sidebarItems}
        groupedRoutes={groupedRoutes}
        example={example}
        logoLink={logoLink}
        headerAction={headerAction}
      />
    )}
    {BREAKPOINTS.CUSTOM_HEADER.indexOf(breakpoint) > -1 && (
      <CustomHeader
        data-testid="custom-header"
        backLink={backLink}
        user={user}
        example={example}
        logoLink={logoLink}
        headerAction={headerAction}
        extraControls={extraControls}
      />
    )}
  </>
);

export default withBreakpoint(Header);
