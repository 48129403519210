import styled from "@emotion/styled";

import { Checkbox } from "shared/components/Form";
import { MEDIA_QUERIES } from "shared/config/constants";

const CheckboxListTitle = styled.label((props) => ({
  display: "flex",
  color: "#2D2943",
  fontWeight: props.isBoldTitle ? 500 : 300,
  [MEDIA_QUERIES.w.ltSm]: {
    paddingBottom: "22px",
  },
  [MEDIA_QUERIES.w.gteSm]: {
    paddingBottom: "26px",
  },
}));

const CheckboxContainer = styled.div((props) => ({
  color: "#2D2943",
  [MEDIA_QUERIES.w.ltSm]: {
    paddingBottom: props.isLastElement ? "33px" : "16px",
  },
  [MEDIA_QUERIES.w.gteSm]: {
    paddingBottom: props.isLastElement ? "33px" : "25px",
  },
  [MEDIA_QUERIES.w.gteMd]: {
    display: "flex",
  },
}));

const Description = styled.label(() => ({
  fontWeight: 300,
  color: "#807E8D",
  marginLeft: "6px",
  [MEDIA_QUERIES.w.ltMd]: {
    marginLeft: "27px",
  },
}));

const StyledCheckbox = styled(Checkbox)(() => ({
  [MEDIA_QUERIES.w.ltSm]: {
    lineHeight: "150%",
  },
}));

export { CheckboxContainer, CheckboxListTitle, Description, StyledCheckbox };
