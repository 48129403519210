import styled from "@emotion/styled";

import Well from "shared/components/Well/Well";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";
import MarketplaceBackgroundImg from "shared/images/marketplace-background.svg";

const MarketplaceBackground = styled.div`
  background-image: url(${MarketplaceBackgroundImg});
  background-repeat: no-repeat;
  background-rosition: right;
  background-size: contain;
  position: absolute;
  bottom: 0;
  zindex: 0;
  width: 100%;
  height: calc(100% - 181px);
`;

const MarketplaceBanner = styled(Well)`
  background-color: ${COLORS.primary};
  color: ${COLORS.lightGray};
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  .banner-icon {
    margin-right: 18px;

    ${MEDIA_QUERIES.w.ltMd} {
      display: none;
    }
  }
  z-index: 1;
`;

export { MarketplaceBanner, MarketplaceBackground };
