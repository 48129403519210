import React from 'react'

import { useDeleteDocumentById } from 'shared/hooks/api'

import { Modal as BaseModal } from '../Modal'
import { RegularText } from '../Typography'

import { Modal } from './styled'

interface DeleteDocumentModalProps {
  applicationId: number
  documentId: string
}

export const DeleteDocumentModal = ({
  applicationId,
  documentId,
}: DeleteDocumentModalProps) => {
  const { deleteDocumentById, isLoading } = useDeleteDocumentById(applicationId)

  return (
    <Modal
      title="Delete document"
      submitButtonLabel="DELETE"
      submitButtonDisabled={isLoading}
      submit={() => {
        deleteDocumentById(documentId)
      }}
      submitting={isLoading}
      width="auto"
      style={{ maxWidth: 335 }}
      closeOnSubmit
    >
      <BaseModal.Body>
        <RegularText>
          Deleting the document will remove it from the application. You will
          need to re-upload the document to restore it.
        </RegularText>
      </BaseModal.Body>
    </Modal>
  )
}
