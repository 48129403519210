import dayjs, { Dayjs } from "dayjs";
import _ from 'lodash'
import {
  getMagicPortalUnitListings,
  MagicPortalUnitListingsResponse,
} from "renter/lib/magicPortalApi";
import api from 'shared/lib/api';
import { sleep } from "shared/utils/async";

import { DayRange, PropertyUnitId, Sort, Unit, UnitRange } from "./types";
import { DayKey, dayKey, daysInRange } from "./utils";

export type UnitFilter = {
  start: Dayjs;
  end: Dayjs;
  term?: number;
  flexibleTerm: boolean;
  priceMin?: number;
  priceMax?: number;
  sort: Sort;
  property: PropertyUnitId;
  unitFqlVrn?: string;
};

export class UnitListingService {
  // eslint-disable-next-line no-useless-constructor,no-empty-function
  constructor(private _isTestMode = false) {}

  private _rangeIds = new Set<string>();

  private _days = new Set<DayKey>();

  private _unitsByDay = new Map<DayKey, Unit[]>();

  private _terms = new Set<number>();

  public async units(filter: UnitFilter): Promise<{
    units: Unit[];
    unitsByDay: Map<DayKey, Unit[]>;
    terms: Set<number>;
  }> {
    let unitsByDay = new Map<string, Unit[]>();
    let units = [];

    const range = { start: filter.start, end: filter.end };
    await this.downloadAndSaveMissingUnits(range, filter.property, filter.unitFqlVrn);
    units = this.unitsInRange(range);

    const term = filter.flexibleTerm ? null : filter.term;
    if (term || filter.priceMin || filter.priceMax) {
      units = units.filter(
        (unit) =>
          (!term || unit.range.term === term) &&
          (!filter.priceMin || unit.range.price >= filter.priceMin) &&
          (!filter.priceMax || unit.range.price <= filter.priceMax)
      );
    }

    units.sort((a, b) =>
      filter.sort === Sort.price
        ? this.sortByPrice(a, b)
        : filter.sort === Sort.startDate
        ? this.sortByStartDate(a, b)
        : filter.sort === Sort.duration
        ? this.sortByDuration(a, b)
        : 0
    );

    // apply sort on units first so unitsByDay preserves ordering.
    unitsByDay = daysInRange(range).reduce(
      (acc, day) => acc.set(dayKey(day), []),
      new Map<DayKey, Unit[]>()
    );
    units.forEach((unit) => {
      unitsByDay.get(dayKey(unit.day)).push(unit);
    });

    return { units, unitsByDay, terms: this._terms };
  }

  private sortByPrice(a: Unit, b: Unit): number {
    return a.range.price - b.range.price;
  }

  private sortByDuration(a: Unit, b: Unit): number {
    return a.range.term - b.range.term || this.sortByPrice(a, b);
  }

  private sortByStartDate(a: Unit, b: Unit): number {
    return a.day.diff(b.day, "day") || this.sortByPrice(a, b);
  }

  private unitsInRange(range: DayRange): Unit[] {
    const units = daysInRange(range)
      .map((day) => this._unitsByDay.get(dayKey(day)))
      .filter((u) => !!u);
    return [].concat(...units);
  }

  private async downloadAndSaveMissingUnits(
    range: DayRange,
    property: PropertyUnitId,
    unitFqlVrn: string
  ) {
    const missingRange = this.getMissingDates(range);
    if (!missingRange) return;
    const downloads = await this.downloadUnitRanges(missingRange, property, unitFqlVrn);
    const units = downloads
      .filter((e) => !this._rangeIds.has(e.id))
      .reduce<Unit[]>(
        (flat, range) => flat.concat(this.generateUnitsFromRange(range)),
        []
      );
    this.save(range, units, downloads);
  }

  private save(range: DayRange, units: Unit[], ranges: UnitRange[]) {
    ranges.forEach((r) => this._terms.add(r.term));
    units.forEach((unit) => {
      const key = dayKey(unit.day);
      this._rangeIds.add(unit.range.id);
      if (!this._unitsByDay.has(key)) this._unitsByDay.set(key, []);
      this._unitsByDay.get(key).push(unit);
    });
    daysInRange(range).forEach((day) => this._days.add(dayKey(day)));
  }

  private generateUnitsFromRange(range: UnitRange) {
    const dateRange = { start: range.startDate, end: range.endDate };
    return daysInRange(dateRange).map((day) => new Unit(day, range));
  }

  private getMissingDates(range: DayRange): DayRange | null {
    const dates = daysInRange(range).filter(
      (day) => !this._days.has(dayKey(day))
    );
    return dates.length
      ? { start: dates[0], end: dates[dates.length - 1] }
      : null;
  }

  private async downloadUnitRanges(
    range: DayRange,
    property: PropertyUnitId,
    unitFqlVrn: string
  ): Promise<UnitRange[]> {
    if (this._isTestMode) {
      await sleep(1000);
      return this.randomlyGenerateUnitRanges(range);
    }

    const pricingOfferServiceListings = await api.getPricingOffers({ location: unitFqlVrn })
    const listings = !_.isEmpty(pricingOfferServiceListings) ? pricingOfferServiceListings : await getMagicPortalUnitListings({
      propertyHashId: property.propertyId,
      unitHashId: property.unitId,
      startDate: dayjs(range.start).format("YYYY-MM-DD"),
      endDate: dayjs(range.end).format("YYYY-MM-DD")
    })

    return listings?.map((listing) => ({
      endDate: dayjs(listing?.endDate || listing?.date),
      startDate: dayjs(listing?.startDate || listing?.date),
      id: listing?.id || this.hash(listing),
      hashedId: listing?.hashedId || listing?.id,
      price: Math.round((listing?.rent || listing?.amount) / 100),
      term: listing.term,
    }));
  }

  private hash(listing: MagicPortalUnitListingsResponse[0]) {
    return [
      new Date(listing?.startDate || listing?.date || Date.now()).getTime(),
      new Date(listing?.endDate || listing?.date || Date.now()).getTime(),
      listing.term,
      listing.rent || listing?.amount,
    ].join("_");
  }

  private rand(min: number, max: number) {
    return Math.round(Math.random() * (max - min) + min);
  }

  private static _id = 0;

  private randomlyGenerateUnitRanges(
    range: DayRange,
    countMin = 0,
    countMax = 6,
    priceMin = 1000,
    priceMax = 3000,
    termMin = 3,
    termMax = 24
  ) {
    return daysInRange(range).flatMap<UnitRange>((day) =>
      Array(this.rand(countMin, countMax))
        .fill(0)
        .map(() => {
          const id = String(++UnitListingService._id);
          return {
            id,
            hashedId: id,
            startDate: day,
            endDate: day,
            price: this.rand(priceMin, priceMax),
            term: this.rand(termMin, termMax),
          };
        })
    );
  }
}
