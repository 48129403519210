import React from "react";

import { PopoverContentProps } from "./interfaces";
import { PopoverList, PopoverTitle, PopoverListItem } from "./styled";

const PopoverContent = ({ documents = [] }: PopoverContentProps) => (
  <PopoverList>
    <PopoverTitle>Already assigned to this company:</PopoverTitle>

    {documents.map((documentName) => (
      <PopoverListItem key={documentName}>{documentName}</PopoverListItem>
    ))}
  </PopoverList>
);

export default PopoverContent;
