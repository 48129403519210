import React, { ElementType, ReactNode } from 'react'

import pluralize from 'pluralize'

import ROUTES from 'renter/config/routes'
import Icon from 'shared/components/Icon'
import { UnderlineLink } from 'shared/components/Links'
import Spacer from 'shared/components/Spacer'
import Tile from 'shared/components/Tile'
import { SmallText } from 'shared/components/Typography'

import { APPLICATION_STATUS_LABEL } from '../../../shared/config/constants'
import { OtherApplicant } from '../../interfaces/api/application'

import {
  Body,
  PropertyContainer,
  PropertyIcon,
  PropertyUnitName,
  PropertyAddress,
  Description,
  Footer,
  FooterDetails,
  CompleteButton,
  ProgressContainer,
  ProgressLabel,
  ProgressValue,
  AdditionalInformationRequired,
  FallbackContent,
  ButtonWrapper,
  Banner,
  SummaryCardWrapper,
  TileCard,
  PaymentContainer,
  PaymentLink,
} from './styled'

interface SummaryCardProps {
  individualsGroup: () => void
  applicationId: string | number
  action?: {
    visible?: boolean
    label: string
    onClick: () => void
    disabled?: boolean
  }
  otherApplicants: OtherApplicant[]
  Details?: ElementType
  AdditionalDescription?: ElementType
  FooterActions?: ElementType
  additionalInfoMessage?: string
  fallbackContent?: string
  ActionAdditionalView?: ElementType
  propertyName: string
  unitName?: string
  propertyAddress: string
  extraFooterContent?: ReactNode
  argyleSyncStatus?: string
  companySlug?: string
  dealId?: number
  status?: number
  ingestionFlowEnabled?: boolean
}

const SummaryCard = ({
  propertyName,
  unitName,
  propertyAddress,
  applicationId,
  fallbackContent = '',
  Details,
  AdditionalDescription,
  FooterActions,
  action,
  additionalInfoMessage,
  individualsGroup,
  otherApplicants = [],
  ActionAdditionalView,
  extraFooterContent,
  argyleSyncStatus,
  companySlug,
  dealId,
  status,
  ingestionFlowEnabled,
}: SummaryCardProps) => {
  const isOCRProcessing = false

  return (
    <SummaryCardWrapper>
      <TileCard
        className={isOCRProcessing ? 'tile' : ''}
        data-testid={`summary-card-${applicationId}`}
        equalHeight
      >
        <Tile.Inner>
          <Body>
            <PropertyContainer>
              <PropertyIcon />
              <div>
                {propertyName && propertyAddress ? (
                  <>
                    <PropertyUnitName data-testid="property-unit-name">
                      {propertyName}
                      {unitName ? `, ${unitName}` : ''}
                    </PropertyUnitName>
                    <PropertyAddress data-testid="property-address">
                      {propertyAddress}
                    </PropertyAddress>
                  </>
                ) : (
                  <FallbackContent>{fallbackContent}</FallbackContent>
                )}
                <Spacer size={Spacer.SIZES.md} />
                <Description>{Details && <Details />}</Description>
              </div>
              {AdditionalDescription && <AdditionalDescription />}
            </PropertyContainer>
            <Footer>
              <FooterDetails>
                {Boolean(status) && (
                  <ProgressContainer data-testid="progress-line">
                    <ProgressLabel>Progress:</ProgressLabel>{' '}
                    <ProgressValue>
                      {APPLICATION_STATUS_LABEL[status]}
                    </ProgressValue>
                  </ProgressContainer>
                )}
                <PaymentContainer>
                  <Icon.DollarSignGreen />
                  <PaymentLink
                    to={`${ROUTES.applicantPayments}?applicationId=${applicationId}&deal=${dealId}&company=${companySlug}`}
                  >
                    Payments
                  </PaymentLink>
                </PaymentContainer>
                {additionalInfoMessage && (
                  <AdditionalInformationRequired>
                    {additionalInfoMessage}
                  </AdditionalInformationRequired>
                )}
                <SmallText data-testid="applicants-count">
                  <div>
                    The application group includes{' '}
                    {otherApplicants?.length ?? 0} other{' '}
                    {otherApplicants?.length > 0 ? (
                      <UnderlineLink
                        data-testid="individuals"
                        onClick={individualsGroup}
                      >
                        {pluralize('individuals', otherApplicants?.length ?? 0)}
                      </UnderlineLink>
                    ) : (
                      'individuals'
                    )}
                  </div>
                </SmallText>
                {FooterActions && !ingestionFlowEnabled && <FooterActions />}
              </FooterDetails>
              {action?.visible &&
                (ActionAdditionalView ? (
                  <ButtonWrapper>
                    <ActionAdditionalView />
                  </ButtonWrapper>
                ) : (
                  <CompleteButton
                    data-testid="action-button"
                    onClick={action?.onClick}
                    type="primary"
                    disabled={action.disabled}
                  >
                    {action?.label}
                  </CompleteButton>
                ))}
            </Footer>
          </Body>
        </Tile.Inner>
        {extraFooterContent}
      </TileCard>
      {isOCRProcessing && (
        <Banner message="Your document upload(s) are currently processing. Your financial profile will be updated when completed." />
      )}
    </SummaryCardWrapper>
  )
}

export default SummaryCard
