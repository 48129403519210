/* eslint-disable no-undef */
import React, { useRef } from "react";

import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

/* Components */
import { Header as DefaultHeader } from "shared/components/Header";
import RequireAuth from "shared/components/RequireAuth";
import { Sidebar } from "shared/components/Sidebar";
import { BREAKPOINT_TYPES } from "shared/config/constants";
import withBreakpoint from "shared/hocs/withBreakpoint";
import useShowScrollToTopButton from "shared/hooks/useShowScrollToTopButton";
import { ChevronUpIcon } from "shared/icons";
import { breakpointPropType } from "shared/utils/propTypes";

import { Container, StyledLayout, ScrollToTopButton } from "./styled";

const sidebarBreakpoints = [
  BREAKPOINT_TYPES.md,
  BREAKPOINT_TYPES.lg,
  BREAKPOINT_TYPES.xl,
  BREAKPOINT_TYPES.xxl,
  BREAKPOINT_TYPES.xxxl,
];

const Layout = ({
  children,
  breakpoint = undefined,
  header,
  sidebar,
  withoutBackToTop = false,
  LayoutHeader = DefaultHeader,
  ...props
}) => {
  const containerRef = useRef(null);
  const container = containerRef?.current;
  const showScrollToTopButton = useShowScrollToTopButton(container);
  const handleScrollTop = () => container?.scrollTo(0, 0);

  return (
    <RequireAuth
      render={() => (
        <>
          <Helmet titleTemplate="%s | VERO">
            <title>Index</title>
          </Helmet>
          <LayoutHeader
            sidebarItems={sidebar.items}
            groupedRoutes={sidebar.groupedRoutes}
            {...header}
          />
          <StyledLayout data-testid="layout">
            {sidebarBreakpoints.indexOf(breakpoint) > -1 && (
              <Sidebar {...sidebar} data-testid="sidebar" />
            )}
            <Container ref={containerRef} {...props}>
              {children}
            </Container>
          </StyledLayout>
          {!withoutBackToTop && showScrollToTopButton && (
            <ScrollToTopButton onClick={handleScrollTop}>
              <ChevronUpIcon />
            </ScrollToTopButton>
          )}
        </>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  breakpoint: breakpointPropType.isRequired,
  header: PropTypes.object.isRequired,
  sidebar: PropTypes.object.isRequired,
  withoutBackToTop: PropTypes.bool,
  LayoutHeader: PropTypes.elementType,
};

Layout.defaultProps = {
  withoutBackToTop: false,
  LayoutHeader: DefaultHeader,
};

export default withBreakpoint(Layout);
