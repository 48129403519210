import React, { useState, useMemo } from "react";

import orderBy from "lodash/orderBy";
import { useMediaQuery } from "react-responsive";

import { useGetMagicLinksAgents } from "manager/hooks/api";
import { PrimaryText, SecondaryText } from "manager/pages/MagicLinks/styled";
import { BackLink } from "shared/components/Links";
import { BREAKPOINT_RESOLUTIONS } from "shared/config/constants";
import { getIndeterminateItems } from "shared/utils/checkbox";

import { DOWNLOAD_OPTIONS } from "../../MagicLinksDashboard.config";
import SelectAgentsStep from "../components/SelectAgentsStep/SelectAgentsStep";

const DOWNLOAD_OPTION_LABELS = Object.freeze({
  [DOWNLOAD_OPTIONS.LINKS]: "magic links",
  [DOWNLOAD_OPTIONS.QR]: "QR codes",
});

const useSelectAgentsStep = ({
  onBackClick,
  exportOptions,
  onSubmit,
  enabled,
}) => {
  const isLarge = useMediaQuery({ maxWidth: BREAKPOINT_RESOLUTIONS.lg });
  const [nameOrEmail, setNameOrEmail] = useState();
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);

  const exportText = orderBy(
    exportOptions.map((item) => DOWNLOAD_OPTION_LABELS[item]),
    (item) => item,
    "desc"
  ).join(" & ");
  const title = "";
  const subtitle = `Select agents ${exportText} for which you would like to export`;

  const { data: agents = [], isLoading: isAgentsLoading } =
    useGetMagicLinksAgents(
      {
        nameOrEmail,
        ordering: "user__first_name,user__last_name",
      },
      { enabled }
    );

  const items = useMemo(() => {
    const all = {
      key: "all",
      label: <PrimaryText>All agents</PrimaryText>,
    };
    const mappedAgents = agents.map((agent) => ({
      key: agent.id,
      label: <SecondaryText>{agent.name}</SecondaryText>,
      description: <SecondaryText>{agent.email}</SecondaryText>,
      parent: "all",
    }));

    return [all, ...mappedAgents];
  }, [agents]);

  const submit = async () => {
    setSubmitting(true);
    await onSubmit(selectedAgents);
  };

  return {
    modalProps: {
      title,
      subtitle: <SecondaryText>{subtitle}</SecondaryText>,
      subtitleProps: { style: { marginTop: 32 } },
      width: isLarge ? "95%" : 946,
      submitButtonLabel: "Confirm",
      footer: <BackLink onClick={onBackClick}>Back</BackLink>,
      submitButtonDisabled: selectedAgents.length === 0,
      submit,
      submitting: isSubmitting,
    },
    modalBody: (
      <SelectAgentsStep
        items={items}
        onSearch={setNameOrEmail}
        nameOrEmail={nameOrEmail}
        isAgentsLoading={isAgentsLoading}
        checkedItemsOnChange={setSelectedAgents}
        checkedItems={selectedAgents}
        indeterminateItems={getIndeterminateItems(items, selectedAgents)}
      />
    ),
    response: selectedAgents,
  };
};

export default useSelectAgentsStep;
