import React from "react";

import BarChart from "shared/components/Chart/BarChart";
import { MediumTitle, SmallText } from "shared/components/Typography";

import { EmptyChart, isChartEmpty } from "../Dashboard.utils";

import { ChartTitle, Chart } from "./styled";

interface DashboardBarChartProps {
  title: string;
  value: string;
  legend: string;
  chartData: {
    name: string;
    value: number;
  }[];
  formatTooltipValue: Function;
}

const DashboardBarChart = ({
  title,
  value,
  chartData,
  legend,
  formatTooltipValue,
}: DashboardBarChartProps) => {
  return (
    <>
      {isChartEmpty(chartData) ? (
        <EmptyChart />
      ) : (
        <>
          <ChartTitle alignItems="center">
            <MediumTitle>{title}</MediumTitle>
            <div>{value}</div>
          </ChartTitle>
          <Chart>
            <BarChart
              data={chartData}
              formatTooltipValue={formatTooltipValue}
            />
          </Chart>
          {/* @ts-ignore */}
          <SmallText extraStrong>{legend}</SmallText>
        </>
      )}
    </>
  );
};

export default DashboardBarChart;
