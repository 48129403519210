import React from "react";

import { RegularText, SmallText } from "shared/components/Typography";
import { useDeviceType } from "shared/hooks";
import { formatDate } from "shared/utils/ui";

import { formatDashboardPercent } from "../Dashboard.utils";

import {
  MetricItemContainer,
  MetricItemsContainer,
  LastUpdatedContainer,
} from "./styled";

export interface MetricItemProps {
  label: string;
  value: number | string;
}

export interface MetricItemsPorps {
  dealsSubmitted: number;
  dealsApproved: number;
  conversionRate: number;
}

export interface LastUpdatedProps {
  updatedAt: string;
}

const MetricItem = ({ label, value }: MetricItemProps) => (
  <MetricItemContainer
    /* @ts-ignore */
    className="metric-item"
    alignItems="center"
  >
    {/* @ts-ignore */}
    <RegularText className="label">{label}</RegularText>
    <div className="metric">{value}</div>
  </MetricItemContainer>
);

export const MetricItems = ({
  dealsSubmitted,
  dealsApproved,
  conversionRate,
}: MetricItemsPorps) => {
  const { isLargeDesktop } = useDeviceType();
  return (
    /* @ts-ignore */
    <MetricItemsContainer
      flexWrap="wrap"
      justifyContent={!isLargeDesktop ? "flex-start" : null}
      addMarginRight={isLargeDesktop}
    >
      <MetricItem label="Applications Submitted" value={dealsSubmitted} />
      <MetricItem label="Applications Approved" value={dealsApproved} />
      <MetricItem
        label="Application Conv. Rate"
        value={formatDashboardPercent(conversionRate)}
      />
    </MetricItemsContainer>
  );
};

export const LastUpdated = ({ updatedAt }: LastUpdatedProps) => (
  /* @ts-ignore */
  <LastUpdatedContainer justifyContent="end">
    {/* @ts-ignore */}
    <SmallText>Updated Daily, Last Update: {formatDate(updatedAt)}</SmallText>
  </LastUpdatedContainer>
);
