const colors = {
  neutral: {
    base: "#E8E7EE",
    100: "#F9FAFC",
    200: "#F3F4F9",
    300: "#EEEDF2",
    400: "#E8E7EE",
    500: "#D8D7E3",
    600: "#BCB9CE",
    700: "#908BAD",
  },
  primary: {
    base: "#2D2943",
    100: "#B2AFC8",
    200: "#787395",
    300: "#403A5F",
    400: "#2D2943",
    500: "#1E1B2D",
    600: "#1E1C2E",
    700: "#1A1825",
  },
  secondary: {
    base: "#A374F5",
    100: "#E0D1FC",
    200: "#D1BAFA",
    300: "#C2A2F8",
    400: "#A374F5",
    500: "#8052D0",
    600: "#6841BA",
    700: "#524986",
  },
  accent: {
    base: "#18A4EF",
    100: "#B2E1FA",
    200: "#8BD2F7",
    300: "#65C2F4",
    400: "#18A4EF",
    500: "#0E8ACD",
    600: "#09537B",
    700: "#063752",
    800: "#E1EEFF",
  },
  success: {
    base: "#22E295",
    100: "#EBFBF5",
    200: "#D9F7EB",
    300: "#A5F3D4",
    400: "#22E295",
    500: "#05CE88",
    600: "#03AB70",
    700: "#0B5638",
  },
  warning: {
    base: "#FFAF66",
    100: "#FFF6F2",
    200: "#FFEDE5",
    300: "#FFE1CC",
    400: "#FFAF66",
    500: "#EE7200",
    600: "#B35500",
    700: "#773900",
  },
  error: {
    base: "#E26982",
    100: "#FCEEEE",
    200: "#F9E1E0",
    300: "#F1B4C0",
    400: "#E26982",
    500: "#B92443",
    600: "#8B1B32",
    700: "#5C1221",
  },
  shades: {
    0: "#FFFFFF",
    100: "#1A1825",
  },
};
const typography = {
  textColors: {
    light: {
      primary: colors.primary.base,
      secondary: colors.primary[200],
      neutral: colors.neutral[600],
      buttonTextDefault: colors.accent[600],
      buttonTextDisabled: colors.neutral[500],
    },
    dark: {
      primary: colors.shades[0],
      secondary: colors.primary[100],
      neutral: colors.neutral[600],
      buttonTextDefault: colors.accent.base,
      buttonTextDisabled: colors.primary[200],
    },
  },
  textStyles: {
    desktop: {
      heading1: {
        fontFamily: "Work Sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "28px",
        lineHeight: "130%",
      },
      heading2: {
        fontFamily: "Work Sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "22px",
        lineHeight: "130%",
      },
      heading3: {
        fontFamily: "Work Sans",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "15px",
        lineHeight: "150%",
        textTransform: "uppercase",
      },
      heading4: {
        fontFamily: "Work Sans",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "12px",
        lineHeight: "150%",
        textTransform: "uppercase",
      },
      paragraph1: {
        fontFamily: "Work Sans",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "22px",
        lineHeight: "130%",
      },
      paragraph2: {
        fontFamily: "Work Sans",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "17px",
        lineHeight: "130%",
      },
      paragraph3: {
        fontFamily: "Work Sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "17px",
        lineHeight: "130%",
      },
      paragraph4: {
        fontFamily: "Work Sans",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "15px",
        lineHeight: "150%",
        textTransform: "uppercase",
      },
      paragraph5: {
        fontFamily: "Work Sans",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "15px",
        lineHeight: "150%",
      },
      paragraph6: {
        fontFamily: "Work Sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "15px",
        lineHeight: "150%",
      },
      paragraph7: {
        fontFamily: "Work Sans",
        fontStyle: "normal",
        fontWeight: 300,
        fontSize: "15px",
        lineHeight: "150%",
      },
      label1: {
        fontFamily: "Work Sans",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "12px",
        lineHeight: "150%",
      },
      label2: {
        fontFamily: "Work Sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "150%",
      },
    },
    mobile: {
      heading1: {
        fontFamily: "Work Sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "22px",
        lineHeight: "130%",
      },
      heading2: {
        fontFamily: "Work Sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "17px",
        lineHeight: "130%",
      },
      heading3: {
        fontFamily: "Work Sans",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "12px",
        lineHeight: "150%",
        textTransform: "uppercase",
      },
      paragraph1: {
        fontFamily: "Work Sans",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "17px",
        lineHeight: "130%",
      },
      paragraph2: {
        fontFamily: "Work Sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "17px",
        lineHeight: "130%",
      },
    },
  },
};
const dropShadow = {
  none: {
    boxShadow: "none",
    backgroundColor: colors.shades[0],
    border: `1px solid ${colors.neutral.base}`,
    borderRadius: 2,
  },
  xs: {
    boxShadow: "0px 1px 6px rgba(126, 153, 168, 0.45)",
    backgroundColor: colors.shades[0],
    border: `1px solid ${colors.neutral.base}`,
    borderRadius: 2,
  },
  sm: {
    boxShadow: "0px 1px 8px rgba(126, 153, 168, 0.75)",
    backgroundColor: colors.shades[0],
    border: `1px solid ${colors.neutral.base}`,
    borderRadius: 2,
  },
  md: {
    boxShadow: "0px 3px 12px rgba(126, 153, 168, 0.75)",
    backgroundColor: colors.shades[0],
    border: `1px solid ${colors.neutral.base}`,
    borderRadius: 2,
  },
  lg: {
    boxShadow: "0px 5px 16px rgba(126, 153, 168, 0.75)",
    backgroundColor: colors.shades[0],
    border: `1px solid ${colors.neutral.base}`,
    borderRadius: 2,
  },
  xl: {
    boxShadow: "0px 7px 20px rgba(126, 153, 168, 0.75)",
    backgroundColor: colors.shades[0],
    border: `1px solid ${colors.neutral.base}`,
    borderRadius: 2,
  },
};
const transitions = {
  default: "all 0.1s ease-out",
};
export { colors, typography, dropShadow, transitions };
