import React from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

import Well from "shared/components/Well";
import { AlignedCenterFlexPartialCss } from "shared/styles/commonStyles";

const MessagesListBoxContent = styled(({ ...otherProps }) => (
  <Well {...otherProps} />
))<{
  numItems?: number;
  noBorderTop?: boolean;
}>`
  ${(props) =>
    props.noBorderTop &&
    css`
      border-top: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    `}
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    li {
      ${{ ...AlignedCenterFlexPartialCss }}
    }
    li + li {
      margin-top: 8px;
    }
    .message-icon {
      ${{ ...AlignedCenterFlexPartialCss }},
      margin-right: 16px;
      svg {
        height: 24px;
        width: 24px;
        ${(props) =>
          props.numItems === 1 &&
          css`
            height: 40px;
            width: 40px;
          `}
      }
    }
  }
`;

export { MessagesListBoxContent };
