import React from "react";

import Icon from "shared/components/Icon";

import { Container, Title, IconWrapper } from "./styled";

interface DeleteTitleProps {
  children: string;
  action: () => void;
  className?: string;
}

const DeleteTitle = ({
  children,
  action,
  className = undefined,
}: DeleteTitleProps) => (
  <Container className={className}>
    <Title>{children}</Title>
    <IconWrapper>
      <Icon.HoverTracker
        Icon={Icon.DeleteSmallIcon}
        onClick={action}
        data-testid="delete-icon"
      />
    </IconWrapper>
  </Container>
);

export default DeleteTitle;
