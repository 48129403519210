import React, { useState } from 'react'

import _ from 'lodash'
import find from 'lodash/find'
import partition from 'lodash/partition'
import some from 'lodash/some'
import unionBy from 'lodash/unionBy'
// TODO: remove useLocation once this feature is ready

import { AttachmentFile } from 'shared/components/Form/UploadWall'
import {
  useGetOcrResultUrlById,
  useGetDocumentDetail,
} from 'shared/hooks/api/applicationQueries'
import useModal from 'shared/hooks/useModal'

import Attachments from './Attachments'
import { OtherAttachmentMapped, RequiredAttachment } from './interfaces'
import OcrolusAccordion from './OcrolusAccordion'
import ViewEditAttachmentsModal from './ViewEditAttachmentsModal'

interface AttachmentsContainerProps {
  ocrResults?: object
  canViewOcrResults?: boolean
  canViewRestrictedAttachments?: boolean
  header?: {
    title: string | Node
  }
  loading?: boolean
  uploadAttachment?: Function
  deleteAttachment?: Function
  inEditMode?: boolean
  otherAttachments?: OtherAttachmentMapped[]
  requiredAttachments?: RequiredAttachment[]
  confirmApplicationAttachments?: (
    attachmentsChangedVisiblility: AttachmentFile[],
    callback: () => void,
  ) => void
  cancelApplicationAttachments?: (callback: () => void) => void
  skipAttachment?: Function
  showAttachmentsHeaders?: boolean
  showAttachmentsVisibilityAction?: boolean
  dealDecisionIsMade?: boolean
  deleteOcrResultById?: (id) => void
  deleteDocumentById?: (id) => void
  refetchOcrResults?: () => void
  failedDocuments?: any[]
  documents?: any[]
  applicantDocuments?: any[]
}

const AttachmentsContainer = ({
  ocrResults,
  canViewOcrResults,
  canViewRestrictedAttachments,
  header,
  loading,
  uploadAttachment,
  deleteAttachment,
  inEditMode,
  otherAttachments,
  requiredAttachments,
  confirmApplicationAttachments,
  cancelApplicationAttachments,
  skipAttachment,
  showAttachmentsHeaders,
  showAttachmentsVisibilityAction,
  dealDecisionIsMade,
  deleteOcrResultById,
  deleteDocumentById,
  refetchOcrResults,
  failedDocuments,
  documents,
  applicantDocuments,
}: AttachmentsContainerProps) => {
  const { openModalDialog } = useModal()
  const [attachmentsChangedVisiblility, setAttachmentsChangedVisiblility] =
    useState([])

  const addedUrlDocuments = _.chain(documents)
    .map((document) => {
      const attachments = _.get(document, 'attachment')
      const addedUrl = _.map(attachments, (attachment) => {
        const documentId = _.get(attachment, 'documentId')
        const ocrResultId = _.get(attachment, 'ocrResultId')
        if (documentId) {
          const { document } = useGetDocumentDetail(documentId)
          if (document) {
            _.assign(attachment, { url: _.get(document, 'url') })
          }
        }
        if (ocrResultId) {
          const { ocrResult } = useGetOcrResultUrlById(ocrResultId)
          if (ocrResult) {
            _.assign(attachment, {
              url: _.get(ocrResult, 'url'),
              upload: _.get(ocrResult, 'url'),
            })
          }
        }
        return attachment
      })
      _.assign(document, {
        attachment: addedUrl,
        metadata: {
          requiredAttachmentId: _.get(document, 'attachmentRequestId'),
        },
      })
      return document
    })
    .value()

  const addedUlrApplicantDocuments = _.map(
    applicantDocuments,
    (applicantDocument) => {
      const { document } = useGetDocumentDetail(_.get(applicantDocument, 'id'))
      if (document) {
        _.assign(applicantDocument, { url: _.get(document, 'url') })
      }
      return applicantDocument
    },
  )

  const openViewEditModal = (document) => {
    const { attachment } = document

    const onConfirm = (files: AttachmentFile[]) => {
      const newFilesIds = new Set(files.map((file) => file.id))

      const deletedFilesId = attachment
        .map((file) => file.id)
        .filter((id) => !newFilesIds.has(id))
      const addedFiles = files.filter((file) => !file.id)

      if (addedFiles.length > 0) {
        uploadAttachment({
          file: addedFiles,
          metadata: document.metadata,
        })
      }

      if (deletedFilesId.length > 0) {
        _.map(deletedFilesId, (id: string) => {
          if (
            id.includes('vrn:document') ||
            id.includes('vrn:applicant-document')
          ) {
            deleteDocumentById(id)
          }
          if (id.includes('vrn:ocr-result')) {
            deleteOcrResultById(id)
          }
        })
        setAttachmentsChangedVisiblility(
          attachmentsChangedVisiblility.filter(({ id }) =>
            deletedFilesId.includes(id),
          ),
        )
      }

      if (showAttachmentsVisibilityAction) {
        const [changedVisibility, notChangedVisiblility] = partition(
          files,
          ({ id, isPublic }) => {
            const file = find(attachment, { id })
            return Boolean(file?.isPublic) !== Boolean(isPublic)
          },
        )

        setAttachmentsChangedVisiblility(
          unionBy(
            changedVisibility,
            attachmentsChangedVisiblility.filter(
              ({ id }) => !some(notChangedVisiblility, { id }),
            ),
            'id',
          ),
        )
      }
    }

    const context = {
      title: document?.name,
      files: attachment?.map(
        (f) => find(attachmentsChangedVisiblility, { id: f?.id }) ?? f,
      ),
      inEditMode,
      onConfirm,
      showAttachmentsVisibilityAction,
    }

    openModalDialog(ViewEditAttachmentsModal, context)
  }

  const onChangeAttachmentVisibility = (
    isPublic: boolean,
    file: AttachmentFile,
  ) => {
    if (Boolean(file?.isPublic) !== isPublic) {
      setAttachmentsChangedVisiblility([
        ...attachmentsChangedVisiblility,
        {
          ...file,
          isPublic,
        },
      ])
    } else {
      setAttachmentsChangedVisiblility(
        attachmentsChangedVisiblility.filter(({ id }) => id !== file?.id),
      )
    }
  }

  const onConfirm = () =>
    confirmApplicationAttachments(attachmentsChangedVisiblility, () =>
      setAttachmentsChangedVisiblility([]),
    )

  const onCancel = () =>
    cancelApplicationAttachments(() => setAttachmentsChangedVisiblility([]))

  const renderAttachments = () => {
    return (
      <>
        {ocrResults && canViewOcrResults && (
          <OcrolusAccordion
            ocrolusSignals={ocrResults}
            canViewOcrResults={canViewOcrResults}
            dealDecisionIsMade={dealDecisionIsMade}
            deleteOcrResultById={deleteOcrResultById}
            refetchOcrResults={refetchOcrResults}
          />
        )}
        <Attachments
          header={header}
          requiredAttachments={requiredAttachments}
          otherAttachments={otherAttachments}
          uploadAttachment={uploadAttachment}
          deleteAttachment={deleteAttachment}
          cancelApplicationAttachments={onCancel}
          inEditMode={inEditMode}
          loading={loading}
          showAttachmentsVisibilityAction={showAttachmentsVisibilityAction}
          showAttachmentsHeaders={showAttachmentsHeaders}
          attachmentsChangedVisiblility={attachmentsChangedVisiblility}
          onChangeAttachmentVisibility={onChangeAttachmentVisibility}
          confirmApplicationAttachments={onConfirm}
          skipAttachment={skipAttachment}
          openViewEditModal={openViewEditModal}
          failedDocuments={failedDocuments}
          canViewOcrResults={canViewOcrResults}
          canViewRestrictedAttachments={canViewRestrictedAttachments}
          documents={addedUrlDocuments}
          applicantDocuments={addedUlrApplicantDocuments}
        />
      </>
    )
  }

  return <>{renderAttachments()}</>
}

export default AttachmentsContainer
