import React, {
  ChangeEvent,
  ChangeEventHandler,
  useEffect,
  useRef,
  useState,
} from "react";

import RequiredIndicator from "shared/components/RequiredIndicator";
import { createEventLikeObject } from "shared/utils/dom";
import { deferred, isEmptyValue } from "shared/utils/misc.util";

/** Note: HTML-textarea is used as a base.
 *  AntD textarea has a bug in version 3x:
 *  - missing textarea.setSelectionRange() API
 *  - https://github.com/ant-design/ant-design/issues/27533  */

import { Error, Label, Container, LabelProps } from "../styled";

import {
  MarkdownToolbar as DefaultMarkdownToolbar,
  MarkdownToolbarProps,
} from "./MarkdownToolbar";
import { StyledTextArea } from "./styled";

export type MarkdownEditorProps = {
  CustomActions?: React.ElementType[];
  MarkdownToolbar?: (props: MarkdownToolbarProps) => React.ReactElement;
  disabled?: boolean;
  error?: string;
  id: string;
  label?: string;
  name: string;
  onBlur?: React.MouseEventHandler;
  onChange: ChangeEventHandler<HTMLTextAreaElement>;
  onFocus?: React.MouseEventHandler;
  placeholder?: string;
  required?: boolean;
  rows?: number;
  size?: LabelProps["size"];
  value?: string;
  panelConfig?: {
    hideBold?: boolean;
    hideHead1?: boolean;
    hideHead2?: boolean;
    hideHead3?: boolean;
    hideItalic?: boolean;
    hideLink?: boolean;
    hideDefaultActions?: boolean;
  };
};

const SELECTION_DEFAULT = {
  caret: 0,
  selectionStart: 0,
  selectionEnd: 0,
};

export const MarkdownEditor = ({
  onChange: onChangeCallback,
  onFocus: onFocusCallback,
  onBlur: onBlurCallback,
  value = "",
  name,
  error,
  placeholder,
  CustomActions,
  MarkdownToolbar = DefaultMarkdownToolbar,
  rows = 20,
  disabled,
  label,
  id,
  size,
  required,
  panelConfig = {},
}: MarkdownEditorProps) => {
  const ref = useRef<HTMLTextAreaElement>(null);
  const [selection, setSelection] = useState(SELECTION_DEFAULT);
  const [focused, setFocused] = useState(false);

  const isValuePresented = !isEmptyValue(value);

  const recalculateSelection = () => {
    const { selectionStart, selectionEnd } = ref?.current || {};

    return setSelection({
      caret: (selectionStart || 0) && selectionStart - 1,
      selectionStart: selectionStart || 0,
      selectionEnd: selectionEnd || 0,
    });
  };

  const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    event.persist();
    onChangeCallback(event);
    recalculateSelection();
  };

  const onFocus = (event) => {
    setFocused(true);
    event.persist();

    if (onFocusCallback) {
      onFocusCallback(event);
    }
  };

  const onBlur = (event) => {
    setFocused(false);
    event.persist();

    if (onBlurCallback) {
      onBlurCallback(event);
    }
  };

  const onFormatClick =
    ({ open, close, defaultText }) =>
    () => {
      const start = value.substr(0, selection.selectionStart);
      const targetSelection = value.substr(
        selection.selectionStart,
        selection.selectionEnd - selection.selectionStart
      );
      const targetText = targetSelection || defaultText || "";
      const end = value.substr(selection.selectionEnd);
      const newText = `${start}${open}${targetText}${close}${end}`;

      onChangeCallback(createEventLikeObject(name, newText));

      const newSelectionPosition = newText.length - end.length;

      /** Note: Setting focus back in the end of event queue,
       *  after re-render has finished with setTimeout(, 0) */
      deferred(() => {
        ref?.current?.focus();
        ref?.current?.setSelectionRange(
          newSelectionPosition,
          newSelectionPosition
        );
        setSelection({
          caret: newSelectionPosition,
          selectionStart: newSelectionPosition,
          selectionEnd: newSelectionPosition,
        });
      });
    };

  /** Note: Listening document mouseup, because on fast mouse selection,
   *  the cursor can jump outside of textarea and not catch mouseup gracefully
   */
  useEffect(() => {
    document.addEventListener("mouseup", recalculateSelection);
    return () => document.removeEventListener("mouseup", recalculateSelection);
  }, []);

  return (
    <>
      <MarkdownToolbar
        onFormatClick={onFormatClick}
        CustomActions={CustomActions}
        disabled={disabled}
        {...panelConfig}
      />
      <Container>
        {label && (
          <Label
            htmlFor={id}
            focused={focused}
            value={isValuePresented}
            disabled={disabled}
            valid={!error}
            size={size}
          >
            {label}
            <RequiredIndicator required={required} />
          </Label>
        )}
        <StyledTextArea
          id={id}
          name={name}
          ref={ref}
          onChange={onChange}
          value={value}
          rows={rows}
          onMouseUp={recalculateSelection}
          onKeyUp={recalculateSelection}
          onFocus={onFocus}
          onBlur={onBlur}
          valid={!error}
          placeholder={placeholder}
          disabled={disabled}
        />
      </Container>
      {error && <Error>{error}</Error>}
    </>
  );
};
