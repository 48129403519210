import React from "react";

import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";

import Container from "shared/components/Container";
import FilterBar from "shared/components/FilterBar";
import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import { ActionLink } from "shared/components/Links";
import Table from "shared/components/Table";
import { BREAKPOINT_RESOLUTIONS } from "shared/config/constants";
import { formatDateTime, getValueOrNA } from "shared/utils/ui";

import ActivityTitle from "./ActivityTitle";
import { systemAuditPropType } from "./config";
import { CreatedByWrapper, DateWrapper } from "./styled";

const getCreatedBy = ({ createdByName, createdByEmail, sourceLabel }) =>
  getValueOrNA(createdByName || createdByEmail || sourceLabel);

const SystemAuditsTable = ({
  isSystemAuditsLoading,
  systemAudits,
  openCreateAuditNoteModal,
  ...props
}) => {
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT_RESOLUTIONS.sm });

  const getRows = () =>
    systemAudits.map((audit, index) => {
      const createdBy = getCreatedBy(audit);
      const createdAt = formatDateTime(audit.createdAt);
      return {
        key: audit.id,
        values: [
          <ActivityTitle audit={audit} index={index} />,
          <DateWrapper data-testid={`unit-${index}-date`}>
            {createdAt}
          </DateWrapper>,
          <CreatedByWrapper data-testid={`unit-${index}-activity-by`}>
            {!isMobile ? (
              <span>{createdBy}</span>
            ) : (
              <FlexContainer fullWidth justifyContent="space-between">
                <span>{createdBy}</span>
                <span>{createdAt}</span>
              </FlexContainer>
            )}
          </CreatedByWrapper>,
        ],
      };
    });

  return (
    <Container noPaddingTop noMobilePaddingX {...props}>
      <FilterBar
        action={{
          key: "NOTE",
          node: (
            <ActionLink
              onClick={openCreateAuditNoteModal}
              plus
              data-testid="note-button"
            >
              Note
            </ActionLink>
          ),
        }}
        displayOnMobile
      />
      <Table
        columns={[
          {
            title: "Activity",
            dataIndex: "activity",
            key: "activity",
          },
          {
            title: "Activity Date",
            dataIndex: "date",
            key: "date",
          },
          {
            title: "Activity By",
            dataIndex: "activity-by",
            key: "activity-by",
          },
        ]}
        rows={getRows()}
        columnBreakpoints={{
          xs: {
            column1: "activity",
            column2: "activity-by",
          },
          sm: ["activity", "date", "activity-by"],
          md: ["activity", "date", "activity-by"],
          lg: ["activity", "date", "activity-by"],
          xl: ["activity", "date", "activity-by"],
        }}
        noClickableRows
        placeholder={{
          label: "Notes",
          Icon: Icon.NoteActiveIcon,
        }}
        loading={isSystemAuditsLoading}
      />
    </Container>
  );
};

SystemAuditsTable.propTypes = {
  openCreateAuditNoteModal: PropTypes.func.isRequired,
  isSystemAuditsLoading: PropTypes.bool.isRequired,
  systemAudits: PropTypes.arrayOf(systemAuditPropType),
};

SystemAuditsTable.defaultProps = {
  systemAudits: [],
};

export default SystemAuditsTable;
