import axios from "axios";

import { VEGA_BASE_URL } from "shared/config/apiRoutes";

const PMS_PROPERTY_PATH = "/api/property";

const api = {
  getPmsProperties: ({ location, provider }) =>
    axios
      .get(`${VEGA_BASE_URL}${PMS_PROPERTY_PATH}?location=${location}&provider=${provider}`)
      .then(({ data }) => data),
};

export default api;
