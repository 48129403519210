import React from "react";

import { Route, Switch } from "react-router-dom";

import { Notification } from "admin/App/interfaces";
import Layout from "manager/components/Layout";
import ROUTES from "manager/config/routes";
import { SidebarProps } from "shared/components/Sidebar";
import { UserProfileProps } from "shared/components/User";
import { usePermissions } from "shared/hooks";
import { BackLinkProps } from "shared/interfaces";
import Error404Page from "shared/pages/Error404Page";
import LumenDashboard from "./LumenDashboard";
import LumenFormulaConfigurator from "./LumenFormulaConfigurator";

interface LumenRoutesProps {
  sidebar: SidebarProps;
  header: {
    logoLink: string;
    notifications: Notification[];
    user: UserProfileProps;
  };
  backLinks: {
    children: string;
    to: string;
    lumenFormula: BackLinkProps;
    lumenDashboard: BackLinkProps;
  };
}

const LumenRoutes = ({ sidebar, header, backLinks }: LumenRoutesProps) => {
  const routes = [];
  const { company, permissionsLoaded, isLumenEnabled, isLumenAdmin } = usePermissions();

  const canAccessLumenDashboard = !permissionsLoaded || isLumenAdmin;
  const canAccessLumenConfigurator =
    !permissionsLoaded || (isLumenEnabled && isLumenAdmin);

  // TODO (V2-2240) igeshosk: use PermittedRoute once merged from develop (currently available on another branch)
  if (canAccessLumenDashboard) {
    routes.push(
      <Route
        exact
        path={ROUTES.lumenDashboard}
        key={ROUTES.lumenDashboard}
        render={() => (
          <Layout
            sidebar={sidebar}
            header={{
              ...header,
              backLink: backLinks.lumenDashboard,
            }}
          >
            {permissionsLoaded && (
              <LumenDashboard backLink={backLinks.lumenDashboard} />
            )}
          </Layout>
        )}
      />
    );
  }

  if (canAccessLumenConfigurator) {
    routes.push(
      <Route
        exact
        path={ROUTES.lumenFormulaRevision}
        key={ROUTES.lumenFormulaRevision}
        render={() => (
          <Layout
            sidebar={sidebar}
            header={{
              ...header,
              backLink: backLinks.lumenFormula,
            }}
          >
            {permissionsLoaded && <LumenFormulaConfigurator />}
          </Layout>
        )}
      />
    );
  }

  routes.push(<Route key="LUMEN_FALLBACK" render={() => <Error404Page />} />);

  return <Switch>{routes}</Switch>;
};

export { LumenRoutes };
