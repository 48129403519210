import styled from "@emotion/styled";
import { Row } from "antd";

import { Button } from "shared/components/Button";
import { BREAKPOINT_RESOLUTIONS, COLORS } from "shared/config/constants";

import FlexContainer from "../FlexContainer";
import Tile from "../Tile";
import { MediumTitle, RegularText, SmallTitle } from "../Typography";

export const PersonIcon = styled.div`
  margin-right: 10px;
`;

export const InfoListIcon = styled.div`
  display: flex;
  margin-right: 5px;
`;

export const VerificationFilePhoto = styled.div`
  .photo {
    height: 200px;
  }

  .photo-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.3s ease;
    background-color: ${COLORS.white};
  }

  :hover {
    opacity: 0.5;
  }
`;

export const VerificationFileLabel = styled(SmallTitle)`
  text-align: center;
  padding-top: 15px;
`;

export const StatusWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .status-icon {
    margin-right: 15px;
  }
`;

export const IconLabelStatus = styled.div`
  display: flex;
`;

export const StatusLeftContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StatusLabel = styled(MediumTitle)`
  margin-right: 35px !important;
  font-weight: 600 !important;
  color: ${(props) => props.color} !important;
`;

export const ManualApprovalLabel = styled(MediumTitle)`
  color: ${(props) => props.color} !important;
  text-align: right;
`;

export const InlineStatusRow = styled(Row)`
  .status-icon {
    margin-right: 5px;
  }
  .error-text {
    background-color: ${COLORS.lightRed};
    padding: 7px 12px;
  }
`;

export const ReasonListWrapper = styled.div`
  padding: 16px;

  .reason-row {
    margin-bottom: 8px;
  }

  .info-icon {
    margin-right: 10px;
  }
`;

export const NoPhotosAvailableWrapper = styled(FlexContainer)`
  height: 162px;
  padding: 24px;

  .message {
    color: ${COLORS.grayTheme.textColor} !important;
    margin-left: 15px;
  }
`;

export const ChartContainer = styled(FlexContainer)`
  .recharts-legend-wrapper {
    display: none;
  }
  .recharts-tooltip-wrapper {
    display: none;
  }
`;

export const NoVerificationsPlaceholderContent = styled(FlexContainer)`
  min-height: 162px;
  padding: 24px;
  background-color: ${COLORS.tile.default.background};

  .message {
    color: ${COLORS.grayTheme.textColor} !important;
    margin-left: 15px;
  }

  svg {
    min-height: 32px;
    min-width: 32px;
  }
`;

export const LandLordInfoContainer = styled(Tile)`
  .email {
    word-break: break-all;
  }
`;

export const VORScoreContainer = styled(Tile)`
  svg.popup-text {
    position: absolute;
    right: 24px;
    top: 24px;
    width: 24px;
    height: 24px;
    fill: white;
    box-sizing: border-box;
    circle {
      fill: white;
    }
    path {
      fill: black;
    }
  }
`;

export const RequestSurveyButton = styled(Button)`
  margin: 20px auto;
`;

export const SurveyNotSubmitedTitle = styled(RegularText)`
  color: ${COLORS.grayTheme.textColor};
  font-size: 22px;
`;

export const SurveyQuestionListItem = styled.div`
  width: 100%;
  padding: 16px;
  background-color: ${COLORS.layoutBodyBackground};
  margin-bottom: 16px;
  .question-number {
    background-color: ${COLORS.primary};
    color: white;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    font-weight: bold;
  }
  .survey-score {
    color: ${COLORS.grayTheme.textColor};
    padding-left: 40px;
  }
  .comment {
    color: ${COLORS.grayTheme.textColor};
  }
`;

export const MediumTextPrimary = styled(MediumTitle)`
  color: ${COLORS.textColorPrimary};
`;

export const NoRequirementSectionTile = styled(FlexContainer)`
  height: 240px;
`;

export const ResponsiveChecksButton = styled(FlexContainer)`
  justify-content: flex-start;
  @media (max-width: ${BREAKPOINT_RESOLUTIONS.xs}px) {
    margin-top: 17px;
  }
  @media (min-width: ${BREAKPOINT_RESOLUTIONS.xs}px) {
    justify-content: flex-end;
  }
`;

export const CapitalizedContainer = styled(FlexContainer)`
  text-transform: capitalize;
`;
