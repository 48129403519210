import styled from "@emotion/styled";

import FlexContainer from "shared/components/FlexContainer";
import Well from "shared/components/Well";
import { COLORS } from "shared/config/constants";
import {
  AlignedCenterFlexPartial,
  CenteredFlexPartial,
} from "shared/styles/commonStyles";

const FilterConditionsContent = styled.div({
  display: "flex",
  flexDirection: "column",
  ".conditions-container": {
    marginTop: "20px",
    ".and-separator": {
      ...CenteredFlexPartial,
      marginTop: "16px",
      marginBottom: "16px",
      color: COLORS.lightPurple,
      fontWeight: 500,
    },
  },
});

const FilterCondition = styled(Well)({
  ...AlignedCenterFlexPartial,
  padding: "24px",
  border: "none",

  ".first-col": {
    width: "183px",
    fontWeight: 500,
  },
  ".second-col": {
    display: "flex",
    alignItems: "flex-start",
    flex: 1,
    flexDirection: "column",
  },
  ".keywords-input-field-container": {
    maxWidth: "550px",
  },
  ".delete-icon": {
    marginLeft: "10px",
    cursor: "pointer",
  },
  ".big-select": {
    minWidth: "320px",
  },
  ".medium-select": {
    width: "150px",
  },
  ".small-input": {
    width: "80px",
    marginRight: "10px",
  },
  ".normal-input": {
    width: "220px",
  },
  ".than-concat": {
    margin: "0 17px",
  },
  ".error-list": {
    marginTop: "12px",
    marginBottom: 0,
    fontSize: "12px",
  },
  ".error-message": {
    position: "absolute",
  },
  "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
    WebkitAppearance: "none",
    margin: 0,
  },
  "input[type=number]": {
    MozAppearance: "textfield",
  },
});

const AddConditionPanelContent = styled(FlexContainer)`
  margin-top: 24px;
  gap: 20px;

  .filter-button {
    background-color: ${COLORS.layoutBodyBackground};
    border-radius: 16px;
    color: ${COLORS.violetTheme.lightViolet2};
  }
`;

const BetweenWrapper = styled(FlexContainer)`
  margin-left: 24px;
`;

export {
  FilterCondition,
  FilterConditionsContent,
  AddConditionPanelContent,
  BetweenWrapper,
};
