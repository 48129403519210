import React from "react";

import Icon from "shared/components/Icon";
import { APPLICATION_STATUSES } from "shared/config/constants";

import { Container, Text } from "./styled";

interface Status {
  id: number | string;
  value: string;
}

interface ApplicationStatusProps {
  status: number | string;
  allStatuses?: Status[];
}

const ApplicationStatus = ({
  status,
  allStatuses = [],
}: ApplicationStatusProps) => (
  <Container>
    {status === APPLICATION_STATUSES.notStarted && (
      <Icon.ApplicationNotStartedIcon data-testid="not-started-icon" />
    )}
    {status === APPLICATION_STATUSES.inProgress && (
      <Icon.ApplicationInProgressIcon data-testid="in-progress-icon" />
    )}
    {status === APPLICATION_STATUSES.submitted && (
      <Icon.CheckIcon data-testid="submitted-icon" />
    )}
    {(status === APPLICATION_STATUSES.approved ||
      status === APPLICATION_STATUSES.notRequired) && (
      <Icon.CheckIcon data-testid="approved-icon" />
    )}
    <Text>{allStatuses.find(({ id }) => id === status)?.value}</Text>
  </Container>
);

export default ApplicationStatus;
