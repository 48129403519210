import styled from "@emotion/styled";

import { COLORS } from "shared/config/constants";

const NotificationCounter = styled.div`
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  background-color: ${COLORS.lightGreen};
  width: 24px;
  line-height: 24px;

  span {
    color: ${COLORS.green};
  }
`;

export { NotificationCounter };
