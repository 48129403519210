import styled from "@emotion/styled";

import { RegularText } from "shared/components/Typography";

import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

export const DealDetailsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 16px 24px;

  ${MEDIA_QUERIES.w.ltSm} {
    padding: 0;
  }
`;

const minColumnWidth = "150px";
const columnMarginBottom = "20px";

export const CellWrapper = styled.div`
  width: ${({ width }) => (width ? `${width}px` : "100%")};
  min-width: ${minColumnWidth};
  margin-bottom: ${columnMarginBottom};

  ${MEDIA_QUERIES.w.ltSm} {
    min-width: 200px;
  }
`;

export const UnitAvailable = styled.span`
  color: ${COLORS.primary};
  min-width: ${minColumnWidth};
  margin-bottom: ${columnMarginBottom};
  ${MEDIA_QUERIES.w.ltSm} {
    min-width: 200px;
  }
`;

export const DataRow = styled.div`
  padding-bottom: ${({ isLaptop }) => (isLaptop ? "8px" : "16px")};
`;

export const AssignAgentSection = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;

  & > * {
    margin-right: 5px;
  }

  svg {
    flex-shrink: 0;
  }
`;

export const AgentName = styled(RegularText)`
  min-width: 80px;
  width: min-content;
`;
