import React from "react";

import { Row, Col } from "antd";
import { Form, useFormikContext } from "formik";

import ErrorList from "shared/components/ErrorList";

import CheckboxFieldData from "./CheckboxFieldData";
import CheckboxSignatureData from "./CheckboxSignatureData";
import GeneralFieldData from "./GeneralFieldData";
import {
  AddOccupantsModalDataFormikProps,
  CreateFieldFormProps,
} from "./interfaces";
import ManualFieldData from "./ManualFieldData";
import SmartFieldData from "./SmartFieldData";

const CreateFieldForm = ({
  isTypesLoading,
  isCategoriesLoading,
  isPropertiesLoading,
  types = [],
  categories = [],
  properties = [],
  formatters = [],
  isManualField,
  isSmartField,
  isCheckboxGroupSignature,
  isCheckboxField,
  onChangeType,
  onChangeCategory,
  onChangeProperty,
  onChangeFormatter,
}: CreateFieldFormProps) => {
  const { errors }: AddOccupantsModalDataFormikProps = useFormikContext();

  return (
    <Form>
      {errors.nonFieldErrors && (
        <Row gutter={[0, 40]}>
          <Col>
            <ErrorList errors={errors.nonFieldErrors} />
          </Col>
        </Row>
      )}

      <GeneralFieldData
        isTypesLoading={isTypesLoading}
        isCategoriesLoading={isCategoriesLoading}
        types={types}
        categories={categories}
        onChangeType={onChangeType}
        onChangeCategory={onChangeCategory}
      />

      {isManualField && <ManualFieldData />}

      {isSmartField && (
        <SmartFieldData
          isPropertiesLoading={isPropertiesLoading}
          properties={properties}
          formatters={formatters}
          onChangeProperty={onChangeProperty}
          onChangeFormatter={onChangeFormatter}
        />
      )}

      {isCheckboxField && <CheckboxFieldData />}

      {isCheckboxGroupSignature && <CheckboxSignatureData />}
    </Form>
  );
};

export default CreateFieldForm;
