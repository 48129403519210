import { Col, Row } from "antd";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import FadeIn from "shared/components/animated/FadeIn";
import ApplicationDataTag from "shared/components/ApplicationDataTag";
import Spacer from "shared/components/Spacer";
import Tile from "shared/components/Tile";
import {
  SKIPPED_BACKGROUND_AND_CREDIT_VERIFICATION_MSG,
  VERIFICATION_STATUSES,
} from "shared/config/constants";
import { getSkippedIncomeAssetsInfo } from "shared/utils/application";
import { printDollarsFromCents } from "shared/utils/dollar-print";
import {
  formatCreditScore,
  formatIncomeRentRatio,
  yesNo,
} from "shared/utils/ui";
import {
  DataItem,
  IncomeAssetVerificationNotes,
  Statistic,
  StyledCol,
} from "./styled";

const ApplicationSummary = ({
  title,
  label,
  download,
  annualIncome,
  incomeRentMultiple,
  totalAssets,
  incomeEdited,
  incomeVerified,
  assetsEdited,
  skippedIncome,
  skippedAsset,
  skippedBgcc,
  averageCreditScore,
  criminalRecords,
  hideCriminalRecords,
  evictions,
  notes,
  primaryAction,
  secondaryAction,
  insetHeader,
  isEvictionEnabled,
  tag,
  hasWaitingApplications,
  assetSkippedReason,
  assetBankName,
}) => {
  const showAssetsValue = !skippedAsset || (skippedAsset && assetsEdited);
  const skippedIncomeAssets = getSkippedIncomeAssetsInfo(
    assetSkippedReason,
    assetBankName
  );
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);
  return (
    <Tile
      alt
      header={{
        title,
        primaryAction,
        secondaryAction,
        label,
        withoutBorderBottom: true,
      }}
      download={download}
      data-testid="application-summary"
      insetHeader={insetHeader}
      tag={tag}
    >
      <FadeIn visible={visible}>
        <Tile.Inner noPaddingTop>
          <Row type="flex">
            <StyledCol withBorder lg={{ span: 12 }} xs={{ span: 24 }}>
              <Tile.SmallTitle>Income & Balances</Tile.SmallTitle>
              <Row>
                <Col xxl={{ span: 7 }} lg={{ span: 8 }} md={{ span: 6 }}>
                  <Spacer hideGteMd />
                  <Tile.Label alt>Total Annual Income</Tile.Label>
                  <DataItem>
                    <Statistic>{printDollarsFromCents(annualIncome)}</Statistic>
                    {!incomeVerified && (
                      <ApplicationDataTag
                        verificationStatus={
                          incomeEdited
                            ? VERIFICATION_STATUSES.EDITED
                            : undefined
                        }
                        verificationSkipped={skippedIncome}
                        skippedLabel={skippedIncomeAssets.label}
                        skippedMessage={skippedIncomeAssets.message}
                      />
                    )}
                  </DataItem>
                </Col>
                <Col xxl={{ span: 7 }} lg={{ span: 10 }} md={{ span: 7 }}>
                  <Spacer hideGteMd />
                  <Tile.Label alt>Annual Income/Monthly Rent</Tile.Label>
                  <DataItem>
                    <Statistic null={incomeRentMultiple == null}>
                      {formatIncomeRentRatio(incomeRentMultiple)}
                    </Statistic>
                  </DataItem>
                </Col>
                <Col xxl={{ span: 6 }} lg={{ span: 6 }} md={{ span: 7 }}>
                  <Spacer hideGteMd />
                  <Tile.Label alt>Total Balances</Tile.Label>
                  <DataItem>
                    {showAssetsValue && (
                      <Statistic>
                        {printDollarsFromCents(totalAssets)}
                      </Statistic>
                    )}
                    <ApplicationDataTag
                      verificationStatus={
                        assetsEdited ? VERIFICATION_STATUSES.EDITED : undefined
                      }
                      verificationSkipped={skippedAsset}
                      skippedLabel={skippedIncomeAssets.label}
                      skippedMessage={skippedIncomeAssets.message}
                    />
                  </DataItem>
                </Col>
              </Row>
              {notes && (
                <IncomeAssetVerificationNotes>
                  {/* <b>Notes:</b> {notes?.map(({ content }) => content)?.join(", ")} */}
                </IncomeAssetVerificationNotes>
              )}
            </StyledCol>
            <StyledCol xs={{ span: 24 }} lg={{ span: 12 }}>
              <Tile.SmallTitle>Credit & Background Check&nbsp;</Tile.SmallTitle>
              <Row>
                <Col xxl={{ span: 7 }} lg={{ span: 11 }} md={{ span: 7 }}>
                  <Spacer hideGteMd />
                  <Tile.Label alt>Average Credit Score</Tile.Label>
                  <DataItem>
                    {!skippedBgcc && (
                      <Statistic null={averageCreditScore == null}>
                        {formatCreditScore(averageCreditScore)}
                      </Statistic>
                    )}
                    <ApplicationDataTag
                      verificationSkipped={skippedBgcc}
                      skippedMessage={
                        SKIPPED_BACKGROUND_AND_CREDIT_VERIFICATION_MSG
                      }
                    />
                  </DataItem>
                </Col>
                {!hideCriminalRecords && (
                  <Col xxl={{ span: 6 }} lg={{ span: 9 }} md={{ span: 6 }}>
                    <Spacer hideGteMd />
                    <Tile.Label alt>Criminal Records</Tile.Label>
                    <DataItem>
                      {!skippedBgcc && (
                        <Statistic
                          null={
                            criminalRecords == null || hasWaitingApplications
                          }
                        >
                          {yesNo(criminalRecords, hasWaitingApplications)}
                        </Statistic>
                      )}
                      <ApplicationDataTag
                        verificationSkipped={skippedBgcc}
                        skippedMessage={
                          SKIPPED_BACKGROUND_AND_CREDIT_VERIFICATION_MSG
                        }
                      />
                    </DataItem>
                  </Col>
                )}
                {isEvictionEnabled && (
                  <Col xxl={{ span: 6 }} lg={{ span: 4 }} md={{ span: 7 }}>
                    <Spacer hideGteMd />
                    <Tile.Label alt>Eviction Related Proceedings</Tile.Label>
                    <DataItem>
                      {!skippedBgcc && (
                        <Statistic
                          null={evictions == null || hasWaitingApplications}
                        >
                          {yesNo(evictions, hasWaitingApplications)}
                        </Statistic>
                      )}
                      <ApplicationDataTag
                        verificationSkipped={skippedBgcc}
                        skippedMessage={
                          SKIPPED_BACKGROUND_AND_CREDIT_VERIFICATION_MSG
                        }
                      />
                    </DataItem>
                  </Col>
                )}
              </Row>
            </StyledCol>
          </Row>
        </Tile.Inner>
      </FadeIn>
    </Tile>
  );
};

ApplicationSummary.propTypes = {
  annualIncome: PropTypes.number,
  assetsEdited: PropTypes.bool,
  averageCreditScore: PropTypes.number,
  assetBankName: PropTypes.string,
  criminalRecords: PropTypes.bool,
  download: PropTypes.shape({
    description: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
    icon: PropTypes.elementType,
    onClick: PropTypes.func.isRequired,
  }),
  evictions: PropTypes.bool,
  hasWaitingApplications: PropTypes.bool,
  hideCriminalRecords: PropTypes.bool,
  incomeEdited: PropTypes.bool,
  incomeVerified: PropTypes.bool,
  incomeRentMultiple: PropTypes.number,
  insetHeader: PropTypes.bool,
  isEvictionEnabled: PropTypes.bool,
  label: PropTypes.shape({
    text: PropTypes.string,
    type: PropTypes.string,
  }),
  notes: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
      id: PropTypes.number,
    })
  ),
  primaryAction: PropTypes.node,
  secondaryAction: PropTypes.node,
  skippedAsset: PropTypes.bool,
  skippedBgcc: PropTypes.bool,
  skippedIncome: PropTypes.bool,
  assetSkippedReason: PropTypes.string,
  tag: PropTypes.oneOfType([
    PropTypes.shape({
      description: PropTypes.node.isRequired,
      icon: PropTypes.elementType,
    }),
    PropTypes.bool,
  ]),
  title: PropTypes.string.isRequired,
  totalAssets: PropTypes.number,
};

ApplicationSummary.defaultProps = {
  primaryAction: undefined,
  secondaryAction: undefined,
  incomeEdited: false,
  incomeVerified: false,
  assetsEdited: false,
  skippedIncome: false,
  skippedAsset: false,
  skippedBgcc: false,
  notes: undefined,
  annualIncome: 0,
  incomeRentMultiple: undefined,
  totalAssets: undefined,
  averageCreditScore: undefined,
  criminalRecords: undefined,
  hideCriminalRecords: false,
  evictions: undefined,
  download: undefined,
  insetHeader: false,
  isEvictionEnabled: false,
  tag: undefined,
  hasWaitingApplications: false,
  label: undefined,
  assetSkippedReason: "",
  assetBankName: "",
};

export default ApplicationSummary;
