import React from "react";

import PropTypes from "prop-types";

import ROUTES from "manager/config/routes";
import { propertySettingsPropTypes } from "manager/utils/propTypes";
import Icon from "shared/components/Icon";
import { UnderlineLink } from "shared/components/Links";
import Skeleton from "shared/components/Skeleton";
import Tooltip from "shared/components/Tooltip";
import { transformRoute } from "shared/utils/routing";

import {
  DetailsContainer,
  PropertyDescription,
  BlackText,
  GreyText,
  GreyUnderlineLink,
  EditGreenIcon,
  PropertyDivider,
} from "./styled";

const PropertyDetails = ({
  propertySettings,
  employees,
  isEmployeesLoading,
  assignEmployees,
  unassignFromPortfolio,
  assignPropertyToPortfolio,
}) => {
  const editPortfolio = (portfolioIsDefault) =>
    portfolioIsDefault
      ? assignPropertyToPortfolio({
          propertyId: propertySettings.id,
          propertyName: propertySettings.name,
          portfolioId: propertySettings.portfolio,
        })
      : unassignFromPortfolio({
          portfolioId: propertySettings.portfolio,
          portfolioName: propertySettings.portfolioName,
          property: {
            id: propertySettings.id,
            name: propertySettings.name,
          },
        });

  return (
    <Skeleton title={false} noMarginBottom loading={isEmployeesLoading}>
      <PropertyDescription>
        <DetailsContainer>
          <Icon.MyPropertyBoldIcon />
          <BlackText marginLeft data-testid="property-address">
            {propertySettings?.address}, {propertySettings?.city},{" "}
            {propertySettings?.state} {propertySettings?.zipcode}
          </BlackText>
          {propertySettings?.portfolioIsDefault ? (
            <GreyText data-testid="portfolio-name">
              {propertySettings?.portfolioName}
            </GreyText>
          ) : (
            <GreyUnderlineLink
              data-testid="portfolio-name"
              to={ROUTES.portfolio.replace(":id", propertySettings?.portfolio)}
            >
              {propertySettings?.portfolioName}
            </GreyUnderlineLink>
          )}
          {propertySettings && (
            <EditGreenIcon
              data-testid="unassign-from-portfolio"
              onClick={() => editPortfolio(propertySettings.portfolioIsDefault)}
            />
          )}
        </DetailsContainer>
        <PropertyDivider type="vertical" />
        <DetailsContainer>
          <BlackText>Employees assigned:</BlackText>
          {employees.length > 0 ? (
            <Tooltip
              theme="dark"
              placement="bottom"
              title={
                <>
                  <ul>
                    {employees.map((employee) => (
                      <li key={employee.id}>
                        <UnderlineLink
                          to={transformRoute(ROUTES.employee, {
                            id: employee.id,
                          })}
                        >
                          {`${employee?.user.firstName} ${employee?.user.lastName}`}
                        </UnderlineLink>
                      </li>
                    ))}
                  </ul>
                </>
              }
            >
              <BlackText marginLeft>
                <UnderlineLink data-testid="usersAssignedQuantity">
                  {employees.length}
                </UnderlineLink>
              </BlackText>
            </Tooltip>
          ) : (
            <BlackText marginLeft>0</BlackText>
          )}
          <EditGreenIcon
            onClick={() =>
              assignEmployees({
                propertyId: propertySettings.id,
                propertyName: propertySettings.name,
              })
            }
          />
        </DetailsContainer>
      </PropertyDescription>
    </Skeleton>
  );
};

PropertyDetails.propTypes = {
  propertySettings: propertySettingsPropTypes,
  employees: PropTypes.arrayOf(
    PropTypes.shape({
      user: PropTypes.shape({
        id: PropTypes.number.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
      }),
    }).isRequired
  ),
  isEmployeesLoading: PropTypes.bool.isRequired,
  assignEmployees: PropTypes.func.isRequired,
  unassignFromPortfolio: PropTypes.func.isRequired,
  assignPropertyToPortfolio: PropTypes.func.isRequired,
};

PropertyDetails.defaultProps = {
  propertySettings: undefined,
  employees: [],
};

export default PropertyDetails;
