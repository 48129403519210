import { createContext } from "react";

const ApplicationContext = createContext({
  petTypes: [],
  industries: [],
  kidOptions: [],
  windowOptions: [],
  knobOptions: [],
  marketingSources: [],
  application: {},
});

export default ApplicationContext;
