import styled from "@emotion/styled";
import { Steps } from "antd";

import { COLORS } from "shared/config/constants";

const { Step: BaseStep } = Steps;

const Step = styled(BaseStep)`
  .ant-steps-item-icon {
    border: none;
    background-color: ${COLORS.background};
    .ant-steps-icon {
      color: ${COLORS.lightPurple};
      font-weight: 500;
    }
  }
  .ant-steps-item-tail {
    top: -8px !important;
    &:after {
      background-color: ${COLORS.background} !important;
      height: calc(100% + 16px) !important;
    }
  }
  .ant-steps-item-title {
    color: ${COLORS.primary} !important;
    font-size: 17px;
  }
`;

export { Step };
