import fileTypeChecker from 'file-type-checker'
import _ from 'lodash'
import type { FileWithPath } from 'react-dropzone'

import { NOTIFICATIONS } from 'shared/config/constants'
import { openNotification } from 'shared/utils/ui'

const pdfjsLib = require('pdfjs-dist/legacy/build/pdf.js')

pdfjsLib.GlobalWorkerOptions.workerSrc = '/pdfjs/pdf.worker.js'

export const isFileEncrypted = async (file: FileWithPath | DataTransferItem) =>
  new Promise<boolean>((resolve) => {
    if (file.type !== 'application/pdf') {
      resolve(false)
    }
    const fileReader = new FileReader()
    fileReader.onload = async ({ target }: ProgressEvent<FileReader>) => {
      if (target && target.result && pdfjsLib) {
        try {
          const pdf = await pdfjsLib.getDocument(target.result).promise
          await pdf.getPage(1)
          resolve(false)
        } catch (error) {
          if (error instanceof Error && error.name === 'PasswordException') {
            resolve(true)
          }
          resolve(false)
        }
      } else {
        resolve(false)
      }
    }

    if (file instanceof DataTransferItem) {
      const item = file.getAsFile()
      if (item) {
        fileReader.readAsArrayBuffer(item)
      } else {
        resolve(false)
      }
    } else {
      fileReader.readAsArrayBuffer(file)
    }
  })

export const isFileFormatValid = (name: string, acceptFormats: string[]) => {
  if (!acceptFormats.some((format) => name.toLowerCase().endsWith(format))) {
    openNotification(
      `${name} format is not supported. (PNG, JPEG, or PDF only)`,
      NOTIFICATIONS.error,
    )
    return false
  }
  return true
}

export const isFileSizeValid = (
  maxSize: number,
  size: number,
  name: string,
) => {
  if (maxSize && size > maxSize) {
    openNotification(
      `${name} exceeds the max size allowed. (Max file size: 5 MB)`,
      NOTIFICATIONS.error,
    )
    return false
  }
  return true
}

export const isFileContentValid = async (
  file: FileWithPath | DataTransferItem & {name?: string},
  acceptFormats: string[]
) => {
  return new Promise<boolean>((resolve) => {
    try {
      const reader = new FileReader()

      reader.onload = ({ target }: ProgressEvent<FileReader>) => {
        if (target && target.result && typeof target.result !== 'string') {
          const detectedFile = fileTypeChecker.detectFile(target.result)
          if ( // verify whether the detected file type is accepted
            !_.some(acceptFormats, (format) =>
              _.chain(`.${detectedFile?.extension}`)
                .toLower()
                .endsWith(format)
                .value()
            )
          ) {
            openNotification(
              `${file.name} detected format is not supported. (PNG, JPEG, or PDF only)`,
              NOTIFICATIONS.error,
            )
            resolve(false)
          } else if ( // allow uploaded file if the detected file extension and file extension are jpg/jpeg
            /.*.(jpg|jpeg)$/.test(file.name || '') &&
            /.*.(jpg|jpeg)$/.test(`.${detectedFile?.extension}`)
          ) {
            resolve(true)
          } else if ( // verify whether the detected file extension matches the file extension
            !_.chain(file.name)
              .toLower()
              .endsWith(detectedFile?.extension)
              .value()
          ) {
            openNotification(
              `${file.name} detected format is not supported. (PNG, JPEG, or PDF only)`,
              NOTIFICATIONS.error,
            )
            resolve(false)
          }
          resolve(true)
        }
        resolve(true)
      }

      if (file instanceof DataTransferItem) {
        const item = file.getAsFile()
        item ? reader.readAsArrayBuffer(item) : resolve(false)
      } else {
        reader.readAsArrayBuffer(file)
      }
    } catch (error) {
      resolve(false)
    }
  })
}
