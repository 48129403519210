import _ from 'lodash'
import { buildClassName } from 'shared/utils/vega'
import Tooltip from 'shared/components/Tooltip'
import { Timestamp } from 'shared/components/vega/Timestamp'
import LineItems from './LineItems'

const PaymentDate = ({ label, date }) => (
  <div>
    <span className='vega label'>{label}:</span>
    <Timestamp timestamp={date} />
  </div>
)

const Payment = ({ payment }) => {
  return (
    <div className={buildClassName(['vega flex', payment?.status, payment?.targetApplicant && 'off'])}>
      <div className='vega flex column'>
        {payment?.party && <Tooltip theme='light' title={payment?.id}>
          <div className='vega title'>
            <div>{_.startCase(payment?.party)}</div>
            <div className='vega sub-title'>{_.lowerCase(payment?.step)}</div>
          </div>
        </Tooltip>}

        <div className='vega flex no-gap column'>
          {payment?.sourceApplicant && <div>
            <span className='vega label'>moved from:</span>
            <span className='vega info'>{payment.sourceApplicant}</span>
          </div>}

          {payment?.targetApplicant && <div>
            <span className='vega label'>moved to:</span>
            <span className='vega info'>{payment.targetApplicant}</span>
          </div>}

          {payment?.paidAt && <PaymentDate label='paid at' date={payment.paidAt} />}
          {payment?.refundedAt && <PaymentDate label='refunded at' date={payment.refundedAt} />}
          {payment?.disputedAt && <PaymentDate label='disputed at' date={payment.disputedAt} />}

          {payment?.canceledAt && <>
            <PaymentDate label='canceled at' date={payment.canceledAt} />

            <div>
              <span className='vega label'>Reason:</span>
              <span>{payment?.canceledReason}</span>
            </div>
          </>}
        </div>
      </div>

      <LineItems lineItems={payment?.lineItems} />
    </div>
  )
}

export default Payment
