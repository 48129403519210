import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";

import { Button } from "shared/components/Button";
import FlexContainer from "shared/components/FlexContainer";
import Icon from "shared/components/Icon";
import Spacer from "shared/components/Spacer";
import { LargeTitle, RegularText } from "shared/components/Typography";
import AuthPage from "shared/templates/AuthPage";

import { PasswordRecoveryEmailSentPageCard } from "./styled";

interface PasswordRecoveryEmailSentPageProps {
  email: string;
}

const PasswordRecoveryEmailSentPage = ({
  email,
}: PasswordRecoveryEmailSentPageProps) => {
  const history = useHistory();

  return (
    <>
      <Helmet>
        <title>Password Recovery - Email Sent</title>
      </Helmet>
      <AuthPage>
        <PasswordRecoveryEmailSentPageCard>
          <FlexContainer justifyContent="center">
            <Icon.LogoIcon />
          </FlexContainer>
          <Spacer size={Spacer.SIZES.md} />

          <LargeTitle>Password Recovery</LargeTitle>
          <Spacer size={Spacer.SIZES.md} />

          <RegularText weak>
            A recovery link has been sent to{" "}
            <b className="text-break">{email}</b>.
          </RegularText>
          <Spacer />

          <Button
            className="back-button"
            type="primary"
            onClick={() => {
              window.location.href = `${window.location.origin}/auth/login`
              return null
            }}
            SuffixIcon={Icon.ButtonForwardArrowIcon}
          >
            Sign In
          </Button>
        </PasswordRecoveryEmailSentPageCard>
      </AuthPage>
    </>
  );
};

export default PasswordRecoveryEmailSentPage;
