import styled from "@emotion/styled";

import { Modal } from "shared/components/Modal";

const ModalBody = styled(Modal.Body)`
  padding-bottom: 0;
`;

const Note = styled.p`
  font-size: 15px;
  font-weight: 300;
  line-height: 100%;
  margin-bottom: 25px;
`;

const FormContainer = styled.div`
  margin-bottom: 32px;
`;

const SelectionTitle = styled.p`
  margin-bottom: 6px;
  font-size: 12px;
  font-weight: 500;
  line-height: 100%;
  color: #b2afc8;
`;

const Selection = styled.pre`
  font-size: 12px;
  font-weight: normal;
  line-height: 170%;
  margin-bottom: 0;
`;

const PopoverList = styled.ul`
  list-style-position: inside;
  padding: 0;
  margin: 0;
`;

const PopoverTitle = styled.p`
font-size: 15px;
font-weight: 500;
line-height:"150%;
margin: 0;
margin-bottom: 9px;
`;

const PopoverListItem = styled.li`
  font-size: 15px;
  line-height: 130%;
`;

export {
  Note,
  Modal,
  FormContainer,
  SelectionTitle,
  Selection,
  PopoverList,
  PopoverTitle,
  PopoverListItem,
  ModalBody,
};
