import React from "react";

import { Row, Col, Radio } from "antd";
import { Formik } from "formik";

import { Input, VeroFormField, Select } from "shared/components/Form";
import Icon from "shared/components/Icon";
import { Modal } from "shared/components/Modal";
import Spacer from "shared/components/Spacer";
import Tooltip from "shared/components/Tooltip";
import { RegularText, SmallText } from "shared/components/Typography";

import { Field } from "../interfaces";

import { ValidationSchema } from "./config";
import { SubTitleContainer } from "./styled";

export interface FieldDescriptionModalProps {
  fieldType: string;
  fieldsRow: Field;
  isBluemoonFieldUpdating?: boolean;
  tooltipMessage?: string;
  submit?: (values: Field) => void;
}

const FieldDescriptionModal = ({
  fieldType,
  fieldsRow,
  isBluemoonFieldUpdating,
  tooltipMessage,
  submit,
}: FieldDescriptionModalProps) => (
  <Formik
    initialValues={{ ...fieldsRow, isFeeField: Boolean(fieldsRow?.feeType) }}
    onSubmit={submit}
    validateOnMount
    validationSchema={ValidationSchema}
  >
    {({
      submitForm,
      isSubmitting,
      values,
      setFieldValue,
      handleBlur,
      dirty,
      isValid,
    }) => (
      <Modal
        title="VERO Field Type"
        submitButtonLabel="Save"
        submit={isValid && submitForm}
        submitting={isSubmitting || isBluemoonFieldUpdating}
        submitButtonDisabled={
          !dirty || !isValid || isSubmitting || isBluemoonFieldUpdating
        }
        closeOnSubmit={false}
        subtitle={
          <SubTitleContainer alignItems="center">
            <RegularText weak>{`Data Style • `}&nbsp;</RegularText>
            <Tooltip title={tooltipMessage} placement="topLeft" trigger="hover">
              <SubTitleContainer alignItems="center">
                <RegularText>{`${fieldType}`}</RegularText>
                <Icon.InputInfoIcon />
              </SubTitleContainer>
            </Tooltip>
          </SubTitleContainer>
        }
      >
        <Modal.Body>
          <RegularText weak>
            Descriptions to guide the user during lease build
          </RegularText>
          <Spacer />
          {!values?.veroHasPriority && !values?.source && (
            <Row gutter={[20, 25]}>
              <Col xs={24}>
                <RegularText weak>
                  Does the field represent a kind of one-time fee or a rentable
                  item charge?
                </RegularText>
                <Spacer size={Spacer.SIZES.xs} />
                <VeroFormField
                  onChange={(e) => setFieldValue("isFeeField", e.target.value)}
                  value={Boolean(values?.isFeeField)}
                  name="isFeeField"
                  as={Radio.Group}
                >
                  <Radio value={false}>No</Radio>
                  <Radio value>Yes</Radio>
                </VeroFormField>
              </Col>
            </Row>
          )}
          {values?.isFeeField && (
            <Row gutter={[20, 25]}>
              <Col xs={14}>
                <VeroFormField
                  as={Select}
                  id="feeType"
                  name="feeType"
                  label="Fee Type"
                  onChange={(value) => setFieldValue("feeType", value)}
                >
                  <Select.Option key="charges" value="CH">
                    Charges (One-time)
                  </Select.Option>
                  <Select.Option key="rentable items" value="RI">
                    Rentable items (Reccuring)
                  </Select.Option>
                </VeroFormField>
                <SmallText weak>Select the fee type</SmallText>
              </Col>
            </Row>
          )}
          <Row gutter={[20, 25]}>
            <Col xs={24}>
              <VeroFormField
                as={Input}
                name="sample"
                id="sample"
                label="Field Sample"
                value={values.sample}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFieldValue("sample", e.target.value)
                }
                onBlur={handleBlur}
              />
              <SmallText weak>
                Input sample to be displayed on the lease builder
              </SmallText>
            </Col>
          </Row>
          <Row gutter={[20, 25]}>
            <Col xs={24}>
              <VeroFormField
                as={Input}
                name="description"
                id="description"
                label="Field Description"
                value={values.description}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFieldValue("description", e.target.value)
                }
                onBlur={handleBlur}
              />
              <SmallText weak>
                Further details to be displayed on the lease builder
              </SmallText>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    )}
  </Formik>
);

export default FieldDescriptionModal;
