import React from "react";

import {
  useGetYardiAttachmentTypes,
  useGetYardiCredentials,
  useUpdateYardiMappingConfiguration,
} from "manager/hooks/api";
import { useGetIntegrationProvider } from "manager/pages/Marketplace/config";
import { backLinkPropType } from "shared/utils/propTypes";

import { MAPPING_TYPES_COMPLETED_PROPS } from "../shared";

import SetupYardiDocumentTypes from "./SetupYardiDocumentTypes";

const SetupYardiDocumentTypesContainer = ({ backLink }) => {
  const { provider } = useGetIntegrationProvider();
  const { yardiAttachmentTypes, isLoading: isYardiAttachmentTypesLoading } =
    useGetYardiAttachmentTypes(provider);
  const { yardiCredentials } = useGetYardiCredentials(provider);
  const { updateYardiMappingConfiguration } =
    useUpdateYardiMappingConfiguration({
      provider,
      completeSaveMessage: "Document types mapping successfully updated",
      incompleteSaveMessage:
        "Progress Saved. Document types mapping is incomplete.",
      completedProp: MAPPING_TYPES_COMPLETED_PROPS.documentTypes,
    });

  const initialValues = yardiCredentials
    ? {
        dealPdfAttachmentType: yardiCredentials.dealPdfAttachmentType,
        executedLeasePdfAttachmentType:
          yardiCredentials.executedLeasePdfAttachmentType,
        adverseActionPdfAttachmentType:
          yardiCredentials.adverseActionPdfAttachmentType,
        activitiesAndMemosSummaryPdfAttachmentType:
          yardiCredentials.activitiesAndMemosSummaryPdfAttachmentType,
        prequalificationReportAttachmentType:
          yardiCredentials.prequalificationReportAttachmentType,
      }
    : {};

  const onFormSubmit = (data, callbacks) => {
    const { setSubmitting } = callbacks;
    setSubmitting(true);
    const onSettled = () => setSubmitting(false);

    updateYardiMappingConfiguration(data, { onSettled });
  };

  const onSave = (data) => {
    updateYardiMappingConfiguration(data);
  };

  const props = {
    backLink,
    yardiAttachmentTypes,
    initialValues,
    yardiCredentials,
    onFormSubmit,
    onSave,
    isYardiAttachmentTypesLoading,
  };
  return <SetupYardiDocumentTypes {...props} />;
};

SetupYardiDocumentTypesContainer.propTypes = {
  backLink: backLinkPropType.isRequired,
};

export default SetupYardiDocumentTypesContainer;
