import styled from "@emotion/styled";

export const LogoContainer = styled("span")({
  display: "flex",
  alignItems: "center",
  marginRight: "16px",
  position: "relative",
  minWidth: 48,
  minHeight: 48,
});

export const LogoPreview = styled("img")({
  maxWidth: 48,
  maxHeight: 48,
});

export const IconContainer = styled("div")({
  height: "20px",
  width: "20px",
  position: "absolute",
  bottom: 0,
  right: -7,
  "& > *": {
    cursor: "pointer",
    position: "absolute",
  },
});
