import React from "react";

import { useHistory, useParams } from "react-router-dom";

import ROUTES from "manager/config/routes";
import SetPasswordPage from "shared/pages/SetPasswordPage";

const SetPasswordContainer = () => {
  const history = useHistory();

  const { token } = useParams<{ token: string }>();

  const onSuccess = () => window.location.href = ROUTES.selectCompany;

  return <SetPasswordPage onSuccessLogin={onSuccess} token={token} />;
};

export default SetPasswordContainer;
