import React from "react";

import { useFormikContext } from "formik";
import PropTypes from "prop-types";

import {
  LUMEN_CONDITIONS_PER_FILTER_TYPE,
  LUMEN_FILTER_CONDITION_SUB_TYPE,
  LUMEN_FILTER_CONDITION_TYPE,
  LUMEN_FILTER_CONDITION_TYPE_LABELS,
  LUMEN_SECTION_TYPE,
} from "manager/config/lumen.config";
import { Button } from "shared/components/Button";

import { AddConditionPanelContent } from "./styled";

const AddConditionPanel = ({ push, lumenSection }) => {
  const { values } = useFormikContext();
  const usedConditionTypes = new Set(
    (values?.conditions || []).map((c) => c.conditionType)
  );
  const allConditionTypes = LUMEN_CONDITIONS_PER_FILTER_TYPE[values.type];
  const availableConditionTypes = (allConditionTypes || []).filter(
    (t) => !usedConditionTypes.has(t)
  );
  return (
    <AddConditionPanelContent>
      {availableConditionTypes.map((conditionType) => (
        <Button
          className="filter-button"
          plus
          key={conditionType}
          onClick={() =>
            push({
              conditionType,
              conditionSubType:
                lumenSection === LUMEN_SECTION_TYPE.HOUSING_COURT_RECORDS &&
                conditionType === LUMEN_FILTER_CONDITION_TYPE.KEYWORDS
                  ? LUMEN_FILTER_CONDITION_SUB_TYPE.EVICTIONS
                  : null,
            })
          }
        >
          {LUMEN_FILTER_CONDITION_TYPE_LABELS[conditionType]}
        </Button>
      ))}
    </AddConditionPanelContent>
  );
};

AddConditionPanel.propTypes = {
  push: PropTypes.func.isRequired,
  lumenSection: PropTypes.string.isRequired,
};

export default AddConditionPanel;
