
import { Col, Row } from "antd";

import NonMatchingCriteriaNote, {
  APPLICANT_NOTE_KEYWORD,
} from "shared/components/Applicant/NonMatchingCriteriaNote";
import { UnderlineLink } from "shared/components/Links";
import Tile from "shared/components/Tile";
import { LIVING_SITUATION_TYPE_LABELS } from "shared/config/constants";
import { ResidentialHistory as IResidentialHistory } from "shared/interfaces/api";
import {
  isResidenceOther,
  isResidenceOwner,
  isResidenceRenter,
} from "shared/utils/application";
import { printDollarsFromCents } from "shared/utils/dollar-print";
import {
  calcTileInnerBorderBottom,
  formatPhone,
  getPeriod,
  getValueOrNA,
} from "shared/utils/ui";

interface ResidentialHistoryProps {
  residentialHistory?: IResidentialHistory[];
  residentialNonMatchingCriteriaNotes?: string;
  residentialHistoryDuration?: number;
  residentialHistoryRange?: string;
  residentialDurationValid?: boolean;
}

const ResidentialHistory = ({
  residentialHistory,
  residentialNonMatchingCriteriaNotes,
  residentialHistoryDuration,
  residentialHistoryRange,
  residentialDurationValid,
}: ResidentialHistoryProps) => {
  return (
    <Tile
      header={{
        title: "Residential History",
        collapsibleOnDesktop: true,
        collapsibleOnMobile: true,
      }}
    >
      {residentialHistory?.length ? (
        residentialHistory.map((residence, index) => {
          const livingSituationLabel =
            LIVING_SITUATION_TYPE_LABELS[residence?.livingSituation];
          let livingSituation = null;
          if (livingSituationLabel) {
            livingSituation = residence.livesHere
              ? livingSituationLabel.labelPresent
              : livingSituationLabel.labelPast;
          }

          const address = !(
            residence.address ||
            residence.city ||
            residence.state ||
            residence.country ||
            residence.zipcode
          )
            ? "-"
            : `${getValueOrNA(residence.address)}, ${getValueOrNA(
                residence.city
              )}, ${getValueOrNA(residence.state)}, ${getValueOrNA(
                residence.country
              )}, ${getValueOrNA(residence.zipcode)}`;

          return (
            <Tile.Inner
              key={residence.id}
              borderBottom={calcTileInnerBorderBottom(
                residentialHistory,
                index
              )}
              data-testid={`residential-history-${residence.id}`}
            >
              <Row type="flex" gutter={[0, 20]}>
                <Col xs={24} md={8} lg={5}>
                  <Tile.Label>Period</Tile.Label>
                  {residence.moveInDate
                    ? getPeriod(residence.moveInDate, residence.moveOutDate)
                    : "N/A"}
                </Col>
                <Col xs={24} md={16} lg={7}>
                  <Tile.Label>Address</Tile.Label>
                  {address}
                </Col>
                <Col xs={0} lg={1}>
                  <Tile.Divider />
                </Col>
                <Col xs={24} md={8} lg={3}>
                  <Tile.Label>Situation</Tile.Label>
                  {livingSituation}
                </Col>
                <Col xs={0} lg={1}>
                  &nbsp;
                </Col>

                {isResidenceOwner(residence.livingSituation) && (
                  <>
                    <Col xs={24} md={16} lg={3}>
                      <Tile.Label>Monthly Mortgage Payment</Tile.Label>
                      <strong>
                        {printDollarsFromCents(residence.mortgage)}
                      </strong>
                    </Col>
                  </>
                )}

                {isResidenceRenter(residence.livingSituation) && (
                  <>
                    <Col xs={24} md={6} lg={3}>
                      <Tile.Label>Landlord</Tile.Label>
                      {residence?.landlordName ||
                      residence?.landlordPhoneNumber ||
                      residence?.landlordEmail ? (
                        <>
                          {residence?.landlordName && (
                            <>
                              <span className="text-break">
                                {residence?.landlordName}
                              </span>
                              <br />
                            </>
                          )}
                          {residence?.landlordPhoneNumber && (
                            <>
                              <UnderlineLink
                                className="text-break"
                                href={`tel:${residence.landlordPhoneNumber}`}
                              >
                                {formatPhone(residence.landlordPhoneNumber)}
                              </UnderlineLink>
                              <br />
                            </>
                          )}
                          {residence?.landlordEmail && (
                            <UnderlineLink
                              className="text-break"
                              href={`mailto:${residence.landlordEmail}`}
                            >
                              {residence.landlordEmail}
                            </UnderlineLink>
                          )}
                        </>
                      ) : (
                        <strong>N/A</strong>
                      )}
                    </Col>
                    <Col xs={24} md={5} lg={2}>
                      <Tile.Label>Monthly Rent</Tile.Label>
                      <strong>
                        {printDollarsFromCents(residence.monthlyRent)}
                      </strong>
                    </Col>
                    <Col xs={24} md={5} lg={2}>
                      <Tile.Label>Amount Paid Monthly</Tile.Label>
                      <strong>
                        {printDollarsFromCents(residence.paidMonthlyRent)}
                      </strong>
                    </Col>
                  </>
                )}

                {isResidenceOther(residence.livingSituation) && (
                  <>
                    <Col xs={24} md={16} lg={6}>
                      <Tile.Label>Description</Tile.Label>
                      {residence.livingSituationDescription}
                    </Col>
                  </>
                )}
              </Row>
            </Tile.Inner>
          );
        })
      ) : (
        <Tile.Inner
          key={0}
          borderBottom
          data-testid={`residential-history-${0}`}
        >
          No residential history added
        </Tile.Inner>
      )}
      {residentialNonMatchingCriteriaNotes && (
        <NonMatchingCriteriaNote
          durationType={residentialHistoryRange}
          durationValue={residentialHistoryDuration}
          keyword={APPLICANT_NOTE_KEYWORD.residential}
          note={residentialNonMatchingCriteriaNotes}
          isRequirementMet={residentialDurationValid}
        />
      )}
    </Tile>
  );
};

export default ResidentialHistory;
