import { CompanyPermissions } from "../../../../../shared/hooks/usePermissions";

import { AppPermission, Permission } from "./interfaces";

export const format = (can?: { value: boolean; helpText: string }) => ({
  enable: can?.value === true,
  disable: can?.value === false,
  reason: can?.value === false ? can?.helpText : undefined,
});

export const mapAppPermissions = (appSummary: any): AppPermission => {
  const move = format(appSummary.canBeMoved);
  const remove = format(appSummary.canBeRemoved);
  const unblock = format(
    appSummary.canUpdateBlockDealSubmitIfIncomeDataIsMissing
  );

  return {
    noMove: move.disable,
    noMoveReason: move.reason,
    noRemove: remove.disable,
    noRemoveReason: remove.reason,
    showSsn: appSummary.canShowSsn === true,
    canUnblock: unblock.enable,
  };
};

export const mapPermissions = (
  deal: any,
  companyPermissions: CompanyPermissions
): Permission => {
  const approve = format(deal.canBeApproved);
  const archive = format(deal.canBeArchived);
  const cancel = format(deal.canBeCanceled);
  const requestInfo = format(deal.canBeConditionallyApproved);
  const conditionalProceed = format(deal.canBeConditionallyProceeded);
  const decline = format(deal.canBeDeclined);
  const createLease = format(deal.canCreateLease);
  const editRent = format(deal.canEditRent);
  return {
    noApprove: approve.disable,
    noApproveReason: approve.reason,
    noArchive: archive.disable,
    noArchiveReason: archive.reason,
    noCancel: cancel.disable,
    noCancelReason: cancel.reason,
    noConditionalProceed: conditionalProceed.disable,
    noConditionalProceedReason: conditionalProceed.reason,
    noCreateLease: createLease.disable,
    noCreateLeaseReason: createLease.reason,
    noDecline: decline.disable,
    noDeclineReason: decline.reason,
    noEditRent: editRent.disable,
    noEditRentReason: editRent.reason,
    noRequestInfo: requestInfo.disable,
    noRequestInfoReason: requestInfo.reason,
    canSendToPms: companyPermissions?.canSendToPms,
  };
};
