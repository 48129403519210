import React from "react";

import { useParams } from "react-router-dom";

import { SUBMITTED_VOR_SURVEY_URL } from "shared/config/constants";
import { SurveyStatus } from "shared/interfaces/api";
import { useGetSurvey } from "surveys/hooks/api";
import { SurveyUrlParams } from "surveys/interfaces/api";

import VerificationOfResidency from "./VerificationOfResidency";

const VerificationOfResidencyContainer = () => {
  const { surveyHashId } = useParams<SurveyUrlParams>();

  const { survey } = useGetSurvey({ surveyHashId });

  if (survey?.status === SurveyStatus.SUBMITTED) {
    window.location.href = SUBMITTED_VOR_SURVEY_URL;
  }

  return <VerificationOfResidency survey={survey} />;
};

export default VerificationOfResidencyContainer;
