import React from "react";

import { PaymentPattern } from "shared/utils/interfaces";
import {
  PAYMENT_PATTERN_STATUSES,
  paymentPatternPropType,
} from "shared/utils/propTypes";

import {
  Container,
  Header,
  HeaderCell,
  Body,
  BodyCell,
  Row,
  OnTime,
  Late30Days,
  Late60Days,
  Late90Days,
  Late120Days,
  Unclassified,
  Legend,
  LegendText,
} from "./styled";

const legendWithCell = (Cell, label) => () =>
  (
    <Legend>
      <Cell /> <LegendText>{label}</LegendText>
    </Legend>
  );

const OnTimeCell = () => <OnTime title={PAYMENT_PATTERN_STATUSES.ON_TIME} />;
const OnTimeLegend = legendWithCell(
  OnTimeCell,
  PAYMENT_PATTERN_STATUSES.ON_TIME
);
const Late30DaysCell = () => (
  <Late30Days title={PAYMENT_PATTERN_STATUSES.LATE_30_DAYS} />
);
const Late30DaysLegend = legendWithCell(
  Late30DaysCell,
  PAYMENT_PATTERN_STATUSES.LATE_30_DAYS
);
const Late60DaysCell = () => (
  <Late60Days title={PAYMENT_PATTERN_STATUSES.LATE_60_DAYS} />
);
const Late60DaysLegend = legendWithCell(
  Late60DaysCell,
  PAYMENT_PATTERN_STATUSES.LATE_60_DAYS
);
const Late90DaysCell = () => (
  <Late90Days title={PAYMENT_PATTERN_STATUSES.LATE_90_DAYS} />
);
const Late90DaysLegend = legendWithCell(
  Late90DaysCell,
  PAYMENT_PATTERN_STATUSES.LATE_90_DAYS
);
const Late120DaysCell = () => (
  <Late120Days title={PAYMENT_PATTERN_STATUSES.LATE_120_DAYS} />
);
const Late120DaysLegend = legendWithCell(
  Late120DaysCell,
  PAYMENT_PATTERN_STATUSES.LATE_120_DAYS
);
const UnclassifiedCell = () => (
  <Unclassified title={PAYMENT_PATTERN_STATUSES.UNCLASSIFIED} />
);
const UnclassifiedLegend = legendWithCell(
  UnclassifiedCell,
  PAYMENT_PATTERN_STATUSES.UNCLASSIFIED
);

const getHistoryCell = (status) => {
  let cell;

  if (status === PAYMENT_PATTERN_STATUSES.ON_TIME) {
    cell = <OnTimeCell />;
  } else if (status === PAYMENT_PATTERN_STATUSES.LATE_30_DAYS) {
    cell = <Late30DaysCell />;
  } else if (status === PAYMENT_PATTERN_STATUSES.LATE_60_DAYS) {
    cell = <Late60DaysCell />;
  } else if (status === PAYMENT_PATTERN_STATUSES.LATE_90_DAYS) {
    cell = <Late90DaysCell />;
  } else if (status === PAYMENT_PATTERN_STATUSES.LATE_120_DAYS) {
    cell = <Late120DaysCell />;
  } else if (status === PAYMENT_PATTERN_STATUSES.UNCLASSIFIED) {
    cell = <UnclassifiedCell />;
  }

  return cell;
};

const PaymentHistory = ({ history }: PaymentPattern) => {
  return (
    <Container data-testid="payment-history">
      <Header>
        <Row>
          <HeaderCell />
          <HeaderCell title="January">J</HeaderCell>
          <HeaderCell title="February">F</HeaderCell>
          <HeaderCell title="March">M</HeaderCell>
          <HeaderCell title="April">A</HeaderCell>
          <HeaderCell title="May">M</HeaderCell>
          <HeaderCell title="June">J</HeaderCell>
          <HeaderCell title="July">J</HeaderCell>
          <HeaderCell title="August">A</HeaderCell>
          <HeaderCell title="September">S</HeaderCell>
          <HeaderCell title="October">O</HeaderCell>
          <HeaderCell title="November">N</HeaderCell>
          <HeaderCell title="December">D</HeaderCell>
        </Row>
      </Header>
      <Body>
        {history.map((item) => (
          <Row key={item.year}>
            <BodyCell>{item.year}</BodyCell>
            {Object.values(item.months).map((status, index) => (
              <BodyCell key={`${status}-${item.year}-${index + 1}`}>
                {getHistoryCell(status)}
              </BodyCell>
            ))}
          </Row>
        ))}
      </Body>
    </Container>
  );
};

PaymentHistory.propTypes = {
  history: paymentPatternPropType.isRequired,
};

PaymentHistory.STATUSES = PAYMENT_PATTERN_STATUSES;
PaymentHistory.Legend = {
  OnTime: OnTimeLegend,
  Late30Days: Late30DaysLegend,
  Late60Days: Late60DaysLegend,
  Late90Days: Late90DaysLegend,
  Late120Days: Late120DaysLegend,
  Unclassified: UnclassifiedLegend,
};

export default PaymentHistory;
