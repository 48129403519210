import React from "react";

import { Row, Col } from "antd";
import { Formik, FormikHelpers } from "formik";

import { MODALS } from "manager/config/constants";
import Banner from "shared/components/Banner";
import {
  Input,
  PasswordInput,
  StateSelect,
  SwitchTitle,
  VeroFormField,
} from "shared/components/Form";
import Icon from "shared/components/Icon";
import { Modal } from "shared/components/Modal";
import Spacer from "shared/components/Spacer";
import { RegularText } from "shared/components/Typography";

import { ValidationSchema } from "./config";
import { BluemoonLicenseEdit } from "./interfaces";
import { TypeSection } from "./styled";

interface BluemoonEditLicenseModalProps {
  license: BluemoonLicenseEdit;
  submit: (
    values: BluemoonLicenseEdit,
    formikApi: FormikHelpers<BluemoonLicenseEdit>
  ) => void;
}

const BluemoonEditLicenseModal = ({
  license,
  submit,
}: BluemoonEditLicenseModalProps) => {
  return (
    <Formik
      initialValues={license}
      onSubmit={submit}
      enableReinitialize
      validationSchema={ValidationSchema}
      validateOnMount
    >
      {({ submitForm, isSubmitting, values, setFieldValue }) => (
        <Modal
          id={MODALS.bluemoonConnectLicense}
          width="516px"
          title="Edit license"
          submit={submitForm}
          submitting={isSubmitting}
          closeOnSubmit={false}
          submitButtonLabel="Save"
          subtitle={
            <RegularText>
              You can edit a license only when it is active
            </RegularText>
          }
        >
          <Modal.Body>
            <SwitchTitle
              reverseOrder
              value={values.isActive}
              onChange={(value: boolean) => setFieldValue("isActive", value)}
              title="License Active"
            />
            {!values.isActive && (
              <>
                <TypeSection data-testid="applicant-questions">
                  <Banner
                    message="Deactivating the license prevents future lease creation with this license. Leases currently in progress will not be affected."
                    Icon={Icon.OrangeExclamationHollow}
                  />
                </TypeSection>
                <Spacer />
              </>
            )}
            <Row gutter={[20, 25]}>
              <Col md={{ span: 12 }}>
                <VeroFormField
                  as={Input}
                  name="code"
                  id="code"
                  label="License"
                  required
                  disabled={!values.isActive}
                />
              </Col>
              <Col md={{ span: 12 }}>
                <VeroFormField
                  as={StateSelect}
                  name="state"
                  id="state"
                  label="State"
                  required
                  disabled={!values.isActive}
                />
              </Col>
            </Row>
            <Spacer />
            <Row>
              <Col>
                <VeroFormField
                  as={Input}
                  name="name"
                  id="name"
                  label="License Nickname (optional)"
                  disabled={!values.isActive}
                />
              </Col>
            </Row>
            <Spacer />
            <Row gutter={[20, 25]}>
              <Col md={12}>
                <VeroFormField
                  as={Input}
                  name="username"
                  id="username"
                  label="User ID"
                  autoComplete="new-username"
                  disabled={!values.isActive}
                />
              </Col>
              <Col md={12}>
                <VeroFormField
                  as={PasswordInput}
                  name="password"
                  id="password"
                  label="Password"
                  autoComplete="new-password"
                  disabled={!values.isActive}
                />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      )}
    </Formik>
  );
};

export default BluemoonEditLicenseModal;
