import axios from "axios";

import API_ROUTES from "shared/config/apiRoutes";
import { API_URL } from "shared/lib/api/setupApi";

const api = {
  getMarketingSources: ({ propertyId, onlyYardiUnmapped }) =>
    axios
      .get(`${API_URL}${API_ROUTES.marketingSources}`, {
        params: {
          property_id: propertyId,
          only_yardi_unmapped: onlyYardiUnmapped,
        },
      })
      .then((response) => response.data),
};

export default api;
