import _ from 'lodash'
import md5 from 'md5'
import crypt from 'crypt'
import { isWorkflowStarted } from 'shared/hooks/api'
import { WORKFLOWS_DISPLAY_NAMES } from 'shared/config/constants/applicant-workflow'
import { safeJsonParse } from 'shared/utils/vega'

export function getTaskTimingSlug (task) {
  if (_.includes(task, '(')) {
    const [resolvedTaskWithName, ...taskArg] = _.chain(task).trim(')').split('(').value()
    const resolvedTaskArg = _.join(taskArg, '(')
    const [resolvedTask, taskName] = _.split(resolvedTaskWithName, '::')
    const resolvedTaskName = taskName || safeJsonParse(resolvedTaskArg)?.name || _.trim(crypt.bytesToBase64(md5(resolvedTaskArg, { asBytes: true })), '=')

    return _.camelCase(`${resolvedTask}:${resolvedTaskName}`)
  }

  return _.camelCase(task)
}

export function getTaskDisplayName (slug) {
  return _.get(WORKFLOWS_DISPLAY_NAMES, slug, _.startCase(getTaskTimingSlug(slug)))
}

export function getTimings (applicantWorkflow, task) {
  const slug = getTaskTimingSlug(task)

  return _.chain(applicantWorkflow?.timings).pick([
    `${slug}Next`,
    `${slug}Complete`,
  ]).mapKeys(
    (v, k) => _.chain(k).replace(slug, '').toLower().value()
  ).mapValues(
    v => (new Date(_.toSafeInteger(v))).toLocaleString()
  ).value()
}

export function resolveTaskStatus (task) {
  if (task?.status !== 'next') {
    if (task?.timings?.complete) {
      if (task?.status === 'possible') {
        return 'retry'
      }

      return 'complete'
    }

    if (task?.status === 'possible' && !task?.timings?.complete && task?.timings?.next) {
      return 'next'
    }
  }

  return task?.status
}

export function resolveWorkflowErrorMessage (applicantWorkflow) {
  const started = isWorkflowStarted(applicantWorkflow)
  const errorMessage = applicantWorkflow?.error?.message || applicantWorkflow?.error?.errorMessage || 'An error occurred while getting the workflow'

  return !started ? 'Applicant has not signed in and started the workflow' : (/Application must have a primary applicant/.test(errorMessage) ? 'Application must have a primary applicant before the workflow can be run' : errorMessage)
}

export function resolveWorkflowStatus (applicantWorkflow) {
  return applicantWorkflow?.error ? (!isWorkflowStarted(applicantWorkflow) ? 'not-started' : 'error') : (applicantWorkflow?.tasks?.failure ? 'failed' : (applicantWorkflow?.done ? 'complete' : 'in-progress'))
}
