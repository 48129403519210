import React from "react";

import flatten from "lodash/flatten";
import intersection from "lodash/intersection";
import unionBy from "lodash/unionBy";

import { AttachmentFile } from "shared/components/Form/UploadWall";

import { OtherAttachmentMapped, RequiredAttachment } from "../interfaces";

import AttachmentHeaders from "./AttachmentHeaders";

interface AttachmentHeadersContainerProps {
  requiredAttachments: RequiredAttachment[];
  otherAttachments: OtherAttachmentMapped[];
  confirmApplicationAttachments: (
    attachmentsChangedVisiblility: AttachmentFile[],
    callback: () => void
  ) => void;
  cancelApplicationAttachments: (callback: () => void) => void;
  loading: boolean;
  attachmentsChangedVisiblility: AttachmentFile[];
}

const AttachmentHeadersContainer = ({
  requiredAttachments,
  otherAttachments,
  confirmApplicationAttachments,
  cancelApplicationAttachments,
  loading,
  attachmentsChangedVisiblility,
}: AttachmentHeadersContainerProps) => {
  const flatRequiredAttachments = flatten(
    requiredAttachments.map((document) => document.files)
  );

  const addedRequiredAttachments = flatRequiredAttachments.filter(
    (file) => file?.pending
  );
  const addedRequiredAttachmentsIds = addedRequiredAttachments.map(
    (file) => file.id
  );
  const deletedRequiredAttachmentsIds = flatRequiredAttachments
    .filter((file) => file?.toBeDeleted)
    .map((file) => file.id);

  const addedOtherAttachments = otherAttachments
    .map((document) => document.file)
    .filter((file) => file?.pending);
  const addedOtherAttachmentsIds = addedOtherAttachments.map((file) => file.id);
  const deletedOtherAttachmentsIds = otherAttachments
    .map((document) => document.file)
    .filter((file) => file?.toBeDeleted)
    .map((file) => file.id);

  const addedAndChangedVisibilityAttachments = unionBy(
    attachmentsChangedVisiblility,
    [...addedRequiredAttachments, ...addedOtherAttachments],
    "id"
  );

  const intersectionRequired = intersection(
    addedRequiredAttachmentsIds,
    deletedRequiredAttachmentsIds
  );
  const intersectionOther = intersection(
    addedOtherAttachmentsIds,
    deletedOtherAttachmentsIds
  );
  const intersectionCount =
    intersectionRequired.length + intersectionOther.length;

  const addedAttachmentsCount =
    addedRequiredAttachmentsIds.length +
    addedOtherAttachmentsIds.length -
    intersectionCount;

  const deletedAttachmentsCount =
    deletedRequiredAttachmentsIds.length +
    deletedOtherAttachmentsIds.length -
    intersectionCount;

  const showPendingUpdatesHeader =
    addedAttachmentsCount > 0 ||
    deletedAttachmentsCount > 0 ||
    attachmentsChangedVisiblility?.length > 0;

  return (
    <AttachmentHeaders
      showPendingUpdatesHeader={showPendingUpdatesHeader}
      addedAttachmentsCount={addedAttachmentsCount}
      deletedAttachmentsCount={deletedAttachmentsCount}
      confirmApplicationAttachments={confirmApplicationAttachments}
      cancelApplicationAttachments={cancelApplicationAttachments}
      loading={loading}
      attachmentsChangedVisiblility={addedAndChangedVisibilityAttachments}
    />
  );
};

export default AttachmentHeadersContainer;
