import React from "react";

import ExperianAuthModal from "./ExperianAuthModal";

interface ExperianAuthModalProps {
  submit: () => void;
}

const ExperianAuthModalContainer = ({ submit }: ExperianAuthModalProps) => {
  return <ExperianAuthModal title="Authentication required" submit={submit} />;
};

export default ExperianAuthModalContainer;
