import React from "react";

import Icon from "shared/components/Icon";
import { ActionLink, IconLink } from "shared/components/Links";
import { RegularText } from "shared/components/Typography";
import { BREAKPOINT_TYPES } from "shared/config/constants";
import withBreakpoint from "shared/hocs/withBreakpoint";
import { SurveyModel } from "shared/interfaces/api";

import { SurveyQuestionModalMode } from "./interfaces";
import { ActionsColumnContainer, StyledDraggableTable } from "./styled";

interface VorQuestionsTableProps {
  disabled?: boolean;
  loading: boolean;
  survey: SurveyModel;
  openSurveyQuestionModal: Function;
  updateSurveyQuestionOrdering: Function;
  openDeleteSurveyQuestionConfirmation: Function;
  breakpoint?: BREAKPOINT_TYPES;
}

const VorQuestionsTable = ({
  disabled,
  survey,
  loading,
  openSurveyQuestionModal,
  openDeleteSurveyQuestionConfirmation,
  updateSurveyQuestionOrdering,
  breakpoint,
}: VorQuestionsTableProps) => {
  const columns = [
    {
      title: <RegularText strong>SURVEY QUESTIONS</RegularText>,
      dataIndex: "question",
      key: "question",
      align: "left",
    },
    {
      title: !disabled && (
        <ActionLink
          data-testid="create-action"
          onClick={() =>
            openSurveyQuestionModal(SurveyQuestionModalMode.CREATE)
          }
        >
          + QUESTION
        </ActionLink>
      ),
      dataIndex: "surveyQuestionActions",
      key: "surveyQuestionActions",
      width: 170,
      minWidth: 170,
      align: "right",
    },
  ];

  const getRows = () =>
    (survey?.surveyQuestions || [])?.map((row) => ({
      key: row.id,
      draggableItem: !disabled,
      values: [
        row.question,
        !disabled && (
          <ActionsColumnContainer justifyContent="flex-end" alignItems="center">
            <IconLink
              onClick={() =>
                openSurveyQuestionModal(SurveyQuestionModalMode.EDIT, row)
              }
              Icon={Icon.EditGreenIcon}
              hoverableIcon={false}
            />
            <IconLink
              onClick={() => openDeleteSurveyQuestionConfirmation(row.id)}
              Icon={Icon.DeleteSmallIcon}
              hoverableIcon={false}
            />
          </ActionsColumnContainer>
        ),
      ],
    }));

  return (
    <StyledDraggableTable
      hideThForSortColumn
      // @ts-ignore
      columns={columns}
      columnBreakpoints={{
        xs: {
          column1: "question",
          column2: "surveyQuestionActions",
        },
        sm: ["question", "surveyQuestionActions"],
      }}
      placeholder={{
        Icon: Icon.NoDataIcon,
        label: "Survey Questions",
      }}
      onDragChange={(orders) => {
        updateSurveyQuestionOrdering({
          surveyId: survey?.id,
          ordering: orders.map((item, index) => ({
            surveyQuestionId: item.key,
            position: index + 1,
          })),
        });
      }}
      noBorderBottom
      loading={loading}
      pagination={false}
      noClickableRows
      rows={getRows()}
      breakpoint={breakpoint}
    />
  );
};

export default withBreakpoint(VorQuestionsTable);
