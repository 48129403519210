import styled from "@emotion/styled";

import { COLORS } from "shared/config/constants";

const Label = styled("div")<{ type: string }>`
  max-width: max-content;
  padding: 7px 16px;
  border-radius: 2px;
  font-weight: ${(props) => COLORS.label[props.type].fontWeight};
  background-color: ${(props) => COLORS.label[props.type].background};
`;

export { Label };
