import React from "react";

import { BluemoonDashboardProps } from "../interfaces";

import BluemoonDashboard from "./BluemoonDashboard";

const BluemoonDashboardContainer = ({ backLink }: BluemoonDashboardProps) => {
  return (
    <div>
      <BluemoonDashboard backLink={backLink} />
    </div>
  );
};

export default BluemoonDashboardContainer;
