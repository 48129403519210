import React, { ReactNode } from "react";

import Icon from "shared/components/Icon";
import Tooltip from "shared/components/Tooltip";
import {
  MediumTitle,
  RegularText,
  SmallText,
} from "shared/components/Typography";
import { useDeviceType } from "shared/hooks";

import { Tile, TileInner } from "./styled";

export interface DashboardTileProps {
  title: string;
  subtitle: string;
  tooltip: string;
  children: ReactNode;
}

const DashboardTile = ({
  title,
  subtitle,
  tooltip,
  children,
}: DashboardTileProps) => {
  const { isBetweenMdAndLg } = useDeviceType();

  return (
    /* @ts-ignore */
    <Tile>
      {/* @ts-ignore */}
      <TileInner className="tile-header">
        <div>
          {/* @ts-ignore */}
          {isBetweenMdAndLg ? (
            /* @ts-ignore */
            <RegularText className="title">{title}</RegularText>
          ) : (
            <MediumTitle className="title">{title}</MediumTitle>
          )}
          {/* @ts-ignore */}
          <SmallText className="subtitle" extraStrong>
            {subtitle}
          </SmallText>
        </div>
        <Tooltip title={tooltip} placement="topRight">
          <Icon.PurpleQuestionMark className="tooltip-icon" />
        </Tooltip>
        {/* @ts-ignore */}
      </TileInner>
      {/* @ts-ignore */}
      <TileInner className="tile-children">{children}</TileInner>
    </Tile>
  );
};

export default DashboardTile;
