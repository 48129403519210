import { ANSWER_QUESTION_TYPES } from "shared/config/constants";

const REQUIRED_QUESTIONS_NAMES = Object.freeze({
  requiresApplicantPetInformation: "requiresApplicantPetInformation",
  requiresApplicantSmokeInformation: "requiresApplicantSmokeInformation",
  requiresApplicantVehicleInformation: "requiresApplicantVehicleInformation",
  requiresApplicantMarketingSource: "requiresApplicantMarketingSource",
  requiresApplicantIndustry: "requiresApplicantIndustry",
  requiresApplicantWindowGuard: "requiresApplicantWindowGuard",
  requiresApplicantKnobRider: "requiresApplicantKnobRider",
  requiresApplicantKids: "requiresApplicantKids",
});

const REQUIRED_QUESTIONS_FOR_APPLICANTS = [
  {
    label: "Do you have pets?",
    id: REQUIRED_QUESTIONS_NAMES.requiresApplicantPetInformation,
    answerType: ANSWER_QUESTION_TYPES.freeText,
  },
  {
    label: "Do you smoke?",
    id: REQUIRED_QUESTIONS_NAMES.requiresApplicantSmokeInformation,
    answerType: ANSWER_QUESTION_TYPES.freeText,
  },
  {
    label: "Do you currently have a vehicle?",
    id: REQUIRED_QUESTIONS_NAMES.requiresApplicantVehicleInformation,
    answerType: ANSWER_QUESTION_TYPES.freeText,
  },
  {
    label: "How did you hear about the home?",
    id: REQUIRED_QUESTIONS_NAMES.requiresApplicantMarketingSource,
    answerType: ANSWER_QUESTION_TYPES.freeText,
  },
  {
    label: "Which industry do you work in?",
    id: REQUIRED_QUESTIONS_NAMES.requiresApplicantIndustry,
    answerType: ANSWER_QUESTION_TYPES.singleChoice,
  },
  {
    label: "Do you have kids that will live with you in the home?",
    id: REQUIRED_QUESTIONS_NAMES.requiresApplicantKids,
    answerType: ANSWER_QUESTION_TYPES.singleChoice,
  },
  {
    label: "Would you like window guards to be installed in the home?",
    id: REQUIRED_QUESTIONS_NAMES.requiresApplicantWindowGuard,
    answerType: ANSWER_QUESTION_TYPES.singleChoice,
  },
  {
    label: "Would you like stove knob covers to be installed in the home?",
    id: REQUIRED_QUESTIONS_NAMES.requiresApplicantKnobRider,
    answerType: ANSWER_QUESTION_TYPES.singleChoice,
  },
];

const getRequiredQuestions = (values, dealSetup) =>
  REQUIRED_QUESTIONS_FOR_APPLICANTS.map(({ id, label, answerType }) => ({
    id,
    questionName: label,
    answerType,
    isActive: values[REQUIRED_QUESTIONS_NAMES[id]],
    isDefault: true,
    disabled:
      dealSetup?.extraQuestionDetails?.[REQUIRED_QUESTIONS_NAMES[id]]
        ?.mandatory,
  }));

const getAdditionalQuestions = (values, applicantType) =>
  (values.additionalQuestions || [])
    ?.filter((q) => q.applicantType === applicantType)
    ?.map(
      ({
        id,
        questionName,
        isActive,
        answerType,
        options,
        hasLinkedDocuments,
      }) => ({
        id,
        questionName,
        isActive,
        answerType,
        applicantType,
        options,
        isDefault: false,
        hasLinkedDocuments,
      })
    );

export {
  REQUIRED_QUESTIONS_NAMES,
  REQUIRED_QUESTIONS_FOR_APPLICANTS,
  getRequiredQuestions,
  getAdditionalQuestions,
};
