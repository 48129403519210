import axios from "axios";

import snakeCase from "lodash/snakeCase";

import ROUTES from "admin/config/apiRoutes";
import { API_URL } from "shared/lib/api/setupApi";

const api = {
  getFields: (params: { groupByCategory: boolean }) =>
    axios.get(`${API_URL}${ROUTES.fields}`, {
      params: {
        group_by_category: params?.groupByCategory,
      },
    }),

  getFieldApps: () => axios.get(`${API_URL}${ROUTES.fieldApps}`),

  getFieldProperties: (params) =>
    axios.get(`${API_URL}${ROUTES.fieldProperties}`, {
      params: {
        category: params?.category,
      },
    }),

  getFieldCategoriesByType: () =>
    axios
      .get(`${API_URL}${ROUTES.fieldCategoriesByType}`)
      .then(({ data }) => data),

  getFieldTypes: () => axios.get(`${API_URL}${ROUTES.fieldTypes}`),

  createField: ({ name, type, category, sample, description, smartSource }) =>
    axios.post(`${API_URL}${ROUTES.fields}`, {
      name: `<${snakeCase(name)}>`,
      sample,
      type,
      category,
      description,
      smartSource,
    }),

  getSignatureFields: () => axios.get(`${API_URL}${ROUTES.signatures}`),
};

export default api;
