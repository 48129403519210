import React, { useEffect, useState, useRef } from "react";

import { FormikProps } from "formik";

import {
  useCreateOpenApiUser,
  useTestOpenApiWebhook,
  useResetOpenApiPassword,
  useSetOpenApiSettings,
} from "manager/hooks/api";
import ConfirmationModal from "shared/components/Modals/ConfirmationModal";
import { useActiveCompany } from "shared/hooks";
import useModal from "shared/hooks/useModal";
import { BackLinkProps } from "shared/interfaces/misc";
import { backLinkPropType } from "shared/utils/propTypes";

import { OpenApiInitialValues } from "./interfaces";
import OpenApiSettings from "./OpenApiSettings";

interface OpenApiSettingsContainerProps {
  backLink: BackLinkProps;
}

const OpenApiSettingsContainer = ({
  backLink,
}: OpenApiSettingsContainerProps) => {
  const { activeCompany } = useActiveCompany();
  const {
    openApiEmail,
    openApiWebhook,
    redirectUrlAfterApplicationSubmission,
    enableWebhookEvents,
  } = activeCompany ?? {};
  const initialValues = {
    webhookEnabled: enableWebhookEvents,
    webhookUrl: openApiWebhook,
    redirectUrl: redirectUrlAfterApplicationSubmission,
  };

  const [credentials, setCredentials] = useState({
    email: openApiEmail,
    password: "",
  });
  const { openModalDialog } = useModal();
  const formRef = useRef<FormikProps<OpenApiInitialValues>>();

  // Api
  const { testOpenApiWebhook, isTestingWebhook } = useTestOpenApiWebhook();

  const { createOpenApiUser, openApiUser, isCreatingUser } =
    useCreateOpenApiUser();

  const { password, resetOpenApiPassword, isResettingPassword } =
    useResetOpenApiPassword();

  const { setOpenApiSettings } = useSetOpenApiSettings();

  // Callbacks

  const onTestWebhook = (webhookUrl: string) => testOpenApiWebhook(webhookUrl);

  const onResetPassword = () => {
    openModalDialog(ConfirmationModal, {
      title: "Regenerate password",
      message:
        "The old password will be lost. Are you sure you want to continue?",
      description:
        "Password regeneration will cause invalidation of current OpenApi sessions",
      submitButtonLabel: "Confirm",
    }).afterClose.then((isConfirmed) => {
      if (isConfirmed) {
        resetOpenApiPassword();
      }
    });
  };

  const saveChanges = (values) => {
    if (formRef?.current?.isValid) {
      setOpenApiSettings(values);
    }
  };

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      await saveChanges(values);
    } finally {
      setSubmitting(false);
    }
  };

  const getPromptProps = ({ isValid }) => ({
    title: "Unsaved changes",
    subtitle: isValid
      ? "Would you like to save the changes?"
      : "The changes you made are not valid and will not be saved.",
    submitButtonLabel: isValid ? "Yes" : "OK",
    cancelLinkLabel: isValid ? "No" : null,
  });

  // Effects

  useEffect(() => {
    if (openApiUser) {
      setCredentials({
        email: openApiUser.username,
        password: openApiUser.password,
      });
    }
  }, [openApiUser]);

  useEffect(() => {
    if (password) {
      setCredentials({
        ...credentials,
        password,
      });
    }
  }, [password]);

  return (
    <OpenApiSettings
      backLink={backLink}
      credentials={credentials}
      generateCredentials={createOpenApiUser}
      isGeneratingCredentials={isCreatingUser}
      onTestWebhook={onTestWebhook}
      isTestingWebhook={isTestingWebhook}
      onResetPassword={onResetPassword}
      isResettingPassword={isResettingPassword}
      initialValues={initialValues}
      onSubmit={onSubmit}
      saveChanges={saveChanges}
      getPromptProps={getPromptProps}
      formRef={formRef}
    />
  );
};

OpenApiSettingsContainer.propTypes = {
  backLink: backLinkPropType.isRequired,
};

export default OpenApiSettingsContainer;
