import React, { useState, useEffect } from "react";

import { InputProps as BaseInputProps } from "antd-latest/lib/input";
import isBoolean from "lodash/isBoolean";

import Icon from "shared/components/Icon";
import { Popover } from "shared/components/Popover";
import RequiredIndicator from "shared/components/RequiredIndicator";
import { INPUT_SIZES } from "shared/config/constants";

import { Container, Label, Error, OptionalText } from "../styled";

import { StyledInput, PopoverContent } from "./styled";

export type InputProps = Omit<BaseInputProps, "size"> & {
  id: string;
  label?: string;
  append?: React.ReactNode;
  as?: React.FC;
  value?: string | number;
  disabled?: boolean;
  error?: string[] | string | boolean;
  required?: boolean;
  size?: INPUT_SIZES;
  optional?: boolean;
  name: string;
  suffix?: React.ReactNode;
  description?: string;
  maxLength?: number;
  withContainer?: boolean;
  formikContext?: {
    trimOnBlur?: boolean;
    setFieldValue: () => {};
  };
};

const _Input = React.forwardRef<unknown, InputProps>(
  (
    {
      as = StyledInput,
      label,
      id,
      value: baseValue,
      disabled,
      onChange,
      onBlur,
      error,
      required,
      append,
      size = INPUT_SIZES.default,
      optional,
      name,
      suffix,
      description,
      maxLength,
      formikContext: {
        trimOnBlur = false,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        setFieldValue = (name: string, value: string) => {},
      } = {},
      ...props
    },
    ref
  ) => {
    const [focused, setFocused] = useState(false);
    const [value, setValue] = useState<InputProps["value"]>();
    const [valid, setValid] = useState<boolean>();
    const Tag = as;
    const isValuePresented =
      value !== null && typeof value !== "undefined" && value !== "";

    const getSuffix = () => {
      if (description) {
        return (
          <Popover
            withoutOverlay
            content={<PopoverContent>{description}</PopoverContent>}
          >
            <Icon.InputInfoIcon />
          </Popover>
        );
      }
      if (suffix) {
        return suffix;
      }
      return undefined;
    };

    useEffect(() => {
      const input = document.getElementById(id) as HTMLInputElement;

      if (error) {
        input.setCustomValidity(
          (Array.isArray(error) ? error : [error ?? ""]).join(", ")
        );
      } else {
        input.setCustomValidity("");
      }

      setValid(input.checkValidity());
    });

    useEffect(() => {
      setValue(baseValue);
    }, [baseValue]);

    return (
      <>
        <Container>
          {label && (
            <Label
              htmlFor={id}
              focused={focused}
              value={isValuePresented}
              disabled={disabled}
              valid={valid}
              size={size}
            >
              {label}
              <RequiredIndicator required={required} />
            </Label>
          )}
          <Tag
            data-testid="input"
            {...props}
            // @ts-ignore
            id={id}
            onFocus={() => setFocused(true)}
            onBlur={(event: React.FocusEvent<HTMLInputElement, Element>) => {
              if (
                trimOnBlur &&
                setFieldValue &&
                typeof baseValue === "string"
              ) {
                setFieldValue(name, (baseValue as string).trim());
              }

              if (onBlur) {
                onBlur(event);
              }

              setFocused(false);
            }}
            value={value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (onChange) {
                onChange(event);
              }
              setValue(event.target.value);
              setValid(event.target.checkValidity());
            }}
            disabled={disabled}
            size={size}
            suffix={getSuffix()}
            maxLength={maxLength}
            withContainer={description || suffix}
            ref={ref}
          />
          {append && append}
        </Container>
        {error && !Array.isArray(error) && !isBoolean(error) && (
          <Error className="error-message">{error}</Error>
        )}
        {error &&
          Array.isArray(error) &&
          error.map((e) => <Error key={e}>{e}</Error>)}
        {optional && <OptionalText>Optional</OptionalText>}
      </>
    );
  }
);

export const Input = Object.assign(_Input, { SIZES: INPUT_SIZES });
