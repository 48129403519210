import styled from "@emotion/styled";
import UnderlineLink from "../UnderlineLink";

export const ImageContainer = styled(UnderlineLink)`
  display: flex;
  column-gap: 10px;
  margin-right: 10px;
  border-bottom: none;
  svg {
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    stroke: ${(props) => props.color};
  }
`;
