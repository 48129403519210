export const COLORS = Object.freeze({
  documentConstructor: {
    primary: "#2D2943",
    secondary: "#413B5F",
    text: "#F8F8FF",
    hoveredText: "#E8E8E8",
    fieldMark: "#9c70eb",
    secondaryText: "#C8C4E1",

    actionLink: {
      primary: "#22E295;",
      hover: "#22E295",
    },
  },
});

export const FIELD_CATEGORIES = Object.freeze({
  applicant: "AQ",
  lease: "LQ",
  property: "PQ",
});

export const FIELD_CATEGORIES_LABELS = Object.freeze({
  AQ: "Applicant",
  LQ: "Lease",
  PQ: "Property",
});

export const FIELD_TYPES = Object.freeze({
  smart: "SM",
  manual: "MN",
  checkbox: "CB",
});

export const FIELD_TYPES_LABELS = Object.freeze({
  SM: "Smart",
  MN: "Manual",
  CB: "Checkbox",
});
