import _ from 'lodash'
import { buildClassName } from 'shared/utils/vega'

const Timestamp = ({ timestamp, small = false }) => (
  <span
    className={buildClassName(['vega info timestamp', small && 'small'])}
  >
    {(new Date(timestamp || _.now())).toLocaleString()}
  </span>
)

export default Timestamp
