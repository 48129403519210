import React from "react";


import { UnderlineLink } from "shared/components/Links";
import { Popover } from "shared/components/Popover";
import { SmallText } from "shared/components/Typography";
import { DEAL_UI_STATUS_CODES } from "shared/config/constants";

import { printDollarsFromCents } from "shared/utils/dollar-print";
import { TitlePopoverContent } from "./styled";

const NO_UNIT_LABEL = "No unit assigned";

const CANCELLED_DEAL_STATUSES = new Set<DEAL_UI_STATUS_CODES>([
  DEAL_UI_STATUS_CODES.listingClosed,
  DEAL_UI_STATUS_CODES.cancelled,
  DEAL_UI_STATUS_CODES.cancelledInYardi,
]);

const DECLINED_DEAL_STATUSES = new Set<DEAL_UI_STATUS_CODES>([
  DEAL_UI_STATUS_CODES.declined,
  DEAL_UI_STATUS_CODES.declinedInYardi,
]);

export const isDeclinedDeal = (dealUiStatus: DEAL_UI_STATUS_CODES) =>
  DECLINED_DEAL_STATUSES.has(dealUiStatus);

export const isCanceledDeal = (dealUiStatus: DEAL_UI_STATUS_CODES) =>
  CANCELLED_DEAL_STATUSES.has(dealUiStatus);

export const getApplicationSource = ({
  isMagicDeal,
  isOpenApiDeal,
}: { isMagicDeal?: boolean; isOpenApiDeal?: boolean } = {}): string | null => {
  if (isMagicDeal) return "Magic Deal";
  if (isOpenApiDeal) return "Open API";
  return null;
};

export const TitlePopoverWrapper: React.FC<{
  title?: string;
  propertyAddress?: string;
}> = ({ title, propertyAddress, children }) => {
  return (
    <Popover
      className="popover"
      withoutOverlay
      placement="bottom"
      // @ts-ignore
      trigger="hover"
      disabled={!title && !propertyAddress}
      content={
        <TitlePopoverContent>
          <p className="popover-title">{title}</p>
          <SmallText light>{propertyAddress}</SmallText>
        </TitlePopoverContent>
      }
    >
      {children}
    </Popover>
  );
};

export const getNoUnitText = (
  isMagicDeal?: boolean,
  externalUrl?: string,
  propertyName?: string
) => {
  let noUnitText;
  if (isMagicDeal) {
    const getAddressView = () => {
      return externalUrl?.startsWith("http") ? (
        <UnderlineLink target="_blank" href={externalUrl}>
          {externalUrl}
        </UnderlineLink>
      ) : (
        <>{externalUrl || NO_UNIT_LABEL}</>
      );
    };
    // NOTE: if it's a magic deal and the deal is on a property,
    // show the property name, otherwise show the external source
    noUnitText = propertyName || getAddressView();
  } else {
    noUnitText = NO_UNIT_LABEL;
  }
  return noUnitText;
};

export const getSecurityDepositBalance = (
  securityDeposit?: number,
  isDeducted?: boolean
) => {
  const formattedBalance = `${printDollarsFromCents(securityDeposit)}${
    isDeducted ? " (Deducted)" : ""
  }`;

  return formattedBalance;
};
