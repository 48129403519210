import React from "react";

import styled from "@emotion/styled";
import { Divider as BaseDivider } from "antd";

// eslint-disable-next-line import/no-unresolved
import { FontWeightProperty, WidthProperty } from "csstype";

import { Popover } from "shared/components/Popover";
import { MEDIA_QUERIES } from "shared/config/constants";
import { TextEllipsis } from "shared/styles/commonStyles";

const Description = styled("div")({
  verticalAlign: "middle",
  marginTop: "auto",
  marginBottom: "auto",
  whiteSpace: "nowrap",
  [MEDIA_QUERIES.w.ltSm]: {
    display: "none",
  },
});

const Divider = styled(BaseDivider)({
  height: "24px",
  margin: "auto 24px",
  [MEDIA_QUERIES.w.ltSm]: {
    display: "none",
  },
});

export type LeftColProps = {
  strictWidth?: boolean;
  width: WidthProperty<string>;
  weight: FontWeightProperty;
};

const LeftCol = styled.div((props: LeftColProps) => ({
  display: "flex",
  width: props.width,
  minWidth: props.width,
  ...(props.strictWidth && {
    display: "block",
    minWidth: props.width,
    maxWidth: props.width,
    ".checkbox-label": {
      ...TextEllipsis,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      display: "block !important",
    },
  }),
  fontWeight: props.weight,
  [MEDIA_QUERIES.w.ltSm]: {
    width: "100%",
  },
}));

const StyledPopover = styled(({ className, ...props }) => (
  <Popover {...props} overlayClassName={className} />
))({
  ".ant-popover-inner": {
    padding: 15,
    maxWidth: "400px",
    minWidth: "200px",
  },
  ".ant-popover-title": {
    padding: 0,
    margin: 0,
    marginBottom: 9,
    border: "none",
    paddingLeft: "0px !important",
  },
});

const PopoverTextContainer = styled("span")({
  display: "inline-flex",
  alignItems: "center",
  marginLeft: 10,
});

const CollapseHeading = styled("div")({
  cursor: "pointer",
  display: "flex",
  "&:focus": {
    outline: "none",
  },
  [MEDIA_QUERIES.w.ltSm]: {
    width: "100%",
    alignItems: "center",
  },
});

const CollapseText = styled("div")({
  display: "flex",
  [MEDIA_QUERIES.w.ltSm]: {
    flexDirection: "column",
  },
});

const CollapseDescription = styled("small")({
  fontWeight: 300,
  display: "block",
  [MEDIA_QUERIES.w.gteSm]: {
    display: "none",
  },
});

const CollapseIconContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginLeft: "10px",
  [MEDIA_QUERIES.w.ltSm]: {
    marginLeft: "auto",
  },
});

export {
  Divider,
  LeftCol,
  StyledPopover,
  PopoverTextContainer,
  Description,
  CollapseHeading,
  CollapseText,
  CollapseDescription,
  CollapseIconContainer,
};
