import React from "react";

import InviteApplicantsModal from "renter/components/Modal/InviteApplicants/InviteApplicantsModal";
import { MODALS } from "renter/config/constants";
import { useApplicantInviteApplicants } from "renter/hooks/api";
import { APPLICANT_TYPES } from "shared/config/constants";
import useModal from "shared/hooks/useModal";

import { ApplicantInviteSubmit } from "../InviteApplicantsModal/interfaces";

interface AddApplicantsModalContainerProps {
  property: string;
  unit: string;
  dealId: number;
  companyName: string;
}

const AddApplicantsModalContainer = (
  context: AddApplicantsModalContainerProps
) => {
  const { dealId } = context;
  const { closeActiveModalDialog } = useModal();
  const { applicantInviteApplicants } = useApplicantInviteApplicants(dealId);
  const submit = ({ applicants, onSuccess, onFail }: ApplicantInviteSubmit) => {
    applicantInviteApplicants(
      {
        applicantType: APPLICANT_TYPES.applicant,
        applicants: applicants.map((applicant) => ({
          ...applicant,
          applicantType: APPLICANT_TYPES.applicant,
        })),
      },
      { onSuccess, onError: onFail }
    );
  };

  return (
    <InviteApplicantsModal
      title="Invite applicants"
      id={MODALS.addApplicants}
      submit={submit}
      context={context}
      closeActiveModalDialog={closeActiveModalDialog}
    />
  );
};

export default AddApplicantsModalContainer;
