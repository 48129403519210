import React, { useState } from "react";

import { useLocation } from "react-router-dom";

import { useGetCompanyDashboardData } from "manager/hooks/api/dashboardQueries";
import { useActiveCompany } from "shared/hooks";
import { BackLinkProps } from "shared/interfaces/misc";

import Dashboard from "./Dashboard";
import { DASHBOARD_DEFAULT_PERIOD } from "./Dashboard.utils";

interface DashboardContainerProps {
  backLink: BackLinkProps;
}

const DashboardContainer = ({ backLink }: DashboardContainerProps) => {
  const { activeCompany, isActiveCompanyLoading } = useActiveCompany();
  const { state } = useLocation();
  const { experianPrompt }: { experianPrompt?: boolean } = state ?? {};
  const [selectedPeriod, setSelectedPeriod] = useState(
    DASHBOARD_DEFAULT_PERIOD
  );

  const { companyDashboardData, isCompanyDashboardDataLoading } =
    useGetCompanyDashboardData(activeCompany?.id, selectedPeriod);

  return (
    <Dashboard
      company={activeCompany}
      backLink={backLink}
      experianPrompt={experianPrompt}
      companyDashboardData={companyDashboardData}
      isLoading={isCompanyDashboardDataLoading || isActiveCompanyLoading}
      isCompanyDashboardDataLoading={isCompanyDashboardDataLoading}
      selectedPeriod={selectedPeriod}
      setSelectedPeriod={setSelectedPeriod}
    />
  );
};

export default DashboardContainer;
