import { normalize } from "normalizr";
import { handleActions } from "redux-actions";

import * as dealActions from "shared/state/Deal/actions";
import * as dealSchemas from "shared/state/Deal/schema";

import * as actions from "../actions";
import * as schemas from "../schema";

const initialState = {};

const portfolioEntityReducer = handleActions(
  {
    [actions.receivePortfolio]: (state, action) => {
      const { portfolio } = action.payload;
      return {
        ...state,
        ...normalize([portfolio], schemas.portfolioList).entities.portfolio,
      };
    },
    [actions.receivePortfolios]: (state, action) => {
      const { portfolios } = action.payload;

      return {
        ...state,
        ...normalize(portfolios, schemas.portfolioList).entities.portfolio,
      };
    },
    [dealActions.receiveDeal]: (state, action) => {
      const { deal } = action.payload;

      return {
        ...state,
        ...normalize([deal], dealSchemas.dealList).entities.portfolio,
      };
    },
  },
  initialState
);

export default portfolioEntityReducer;
