import React from "react";

import {
  AssignPropertyToPortfolioModal,
  UnassignPropertyFromPortfolioModal,
} from "manager/components/Modal";
import AssignEmployeesToPropertyModal from "manager/components/Modal/AssignEmployeesToPropertyModal";
import { useGetEmployeesAssignedToProperty } from "manager/hooks/api";
import { propertySettingsPropTypes } from "manager/utils/propTypes";
import useModal from "shared/hooks/useModal";
import { keyPropType } from "shared/utils/propTypes";

import PropertyDetails from "./PropertyDetails";

const PropertyDetailsContainer = ({ id, propertySettings }) => {
  const { openModalDialog } = useModal();

  const assignEmployees = (context) =>
    openModalDialog(AssignEmployeesToPropertyModal, context);
  const unassignFromPortfolio = (context) =>
    openModalDialog(UnassignPropertyFromPortfolioModal, context);
  const assignPropertyToPortfolio = (context) =>
    openModalDialog(AssignPropertyToPortfolioModal, context);

  const { employees, isEmployeesLoading } =
    useGetEmployeesAssignedToProperty(id);

  return (
    <PropertyDetails
      propertySettings={propertySettings}
      employees={employees}
      isEmployeesLoading={isEmployeesLoading}
      assignEmployees={assignEmployees}
      unassignFromPortfolio={unassignFromPortfolio}
      assignPropertyToPortfolio={assignPropertyToPortfolio}
    />
  );
};

PropertyDetailsContainer.propTypes = {
  id: keyPropType.isRequired,
  propertySettings: propertySettingsPropTypes,
};

PropertyDetailsContainer.defaultProps = {
  propertySettings: undefined,
};

export default PropertyDetailsContainer;
