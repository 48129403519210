import React, { useEffect } from "react";

import dayjs from "dayjs";
import { Field, useFormikContext } from "formik";
import get from "lodash/get";

import {
  LUMEN_FILTER_CONDITION_AMOUNT_TIME_OPERATOR_TYPE,
  LUMEN_FILTER_CONDITION_TIME_UNIT_TYPE,
  LUMEN_FILTER_CONDITION_TIME_UNIT_LABELS,
  LUMEN_FILTER_TYPES_WITH_BETWEEN_TIME_CONDITION,
} from "manager/config/lumen.config";
import {
  DatePicker,
  Input,
  Select,
  VeroFormField,
} from "shared/components/Form";
import { showError, addDefaultObjectValues } from "shared/utils/forms";

import BaseCondition from "./BaseCondition";
import { commonFilterConditionPropTypes } from "./lumenFilterConditions.config";
import {
  getBaseConditionPath,
  getConditionAmountOfTimeOperatorOptions,
  getConditionPropPath,
} from "./lumenFilterConditions.util";
import { BetweenWrapper } from "./styled";

const ConditionTime = ({ idx, onDelete }) => {
  const { values, errors, touched, setFieldTouched, setFieldValue } =
    useFormikContext();
  const timeOperatorProp = "timeOperator";
  const timeValueProp = "timeValue";
  const timeUnitProp = "timeUnit";
  const startDateProp = "startDate";
  const endDateProp = "endDate";
  const timeBasePath = getBaseConditionPath(idx);
  const timeOperatorPropPath = getConditionPropPath(idx, timeOperatorProp);
  const timeValuePropPath = getConditionPropPath(idx, timeValueProp);
  const timeUnitPropPath = getConditionPropPath(idx, timeUnitProp);
  const startDatePropPath = getConditionPropPath(idx, startDateProp);
  const endDatePropPath = getConditionPropPath(idx, endDateProp);

  const timeOperatorValue = get(values, timeOperatorPropPath);
  const startDateValue = get(values, startDatePropPath);
  const conditionValues = get(values, timeBasePath);

  const isBeforeDate = (isoBeforeDate) => (date) =>
    dayjs(date).isBefore(isoBeforeDate);

  const isDateDisabled = (date) => {
    const isBeforeStartDate = isBeforeDate(startDateValue);
    return isBeforeStartDate(date);
  };

  useEffect(() => {
    const defaultValues = {
      [timeOperatorProp]:
        LUMEN_FILTER_CONDITION_AMOUNT_TIME_OPERATOR_TYPE.LESS_THAN,
      [timeValueProp]: null,
      [timeUnitProp]: LUMEN_FILTER_CONDITION_TIME_UNIT_TYPE.MONTHS,
      [startDateProp]: null,
      [endDateProp]: null,
    };
    addDefaultObjectValues(values, timeBasePath, defaultValues, setFieldValue);
  }, []);

  const onTimeOperatorChange = (value) => {
    let updatedValues = {};
    if (value === LUMEN_FILTER_CONDITION_AMOUNT_TIME_OPERATOR_TYPE.BETWEEN) {
      updatedValues = {
        timeValue: null,
        timeUnit: LUMEN_FILTER_CONDITION_TIME_UNIT_TYPE.MONTHS,
      };
      setFieldTouched(startDatePropPath, false);
      setFieldTouched(endDatePropPath, false);
    } else {
      updatedValues = {
        startDate: null,
        endDate: null,
      };
      setFieldTouched(timeValuePropPath, false);
    }
    setFieldValue(timeBasePath, {
      ...conditionValues,
      ...updatedValues,
      timeOperator: value,
    });
  };

  return (
    <BaseCondition conditionLabel="Time since record:" onDelete={onDelete}>
      <div className="medium-select">
        <Field
          name={timeOperatorPropPath}
          id={timeOperatorPropPath}
          label="Select"
          as={Select}
          error={showError(timeOperatorPropPath, touched, errors)}
          onChange={onTimeOperatorChange}
          onBlur={() => setFieldTouched(timeOperatorPropPath, true)}
        >
          {getConditionAmountOfTimeOperatorOptions(
            LUMEN_FILTER_TYPES_WITH_BETWEEN_TIME_CONDITION.includes(values.type)
          ).map((option) => (
            <Select.Option key={option.key}>{option.label}</Select.Option>
          ))}
        </Field>
      </div>

      {timeOperatorValue ===
      LUMEN_FILTER_CONDITION_AMOUNT_TIME_OPERATOR_TYPE.BETWEEN ? (
        <BetweenWrapper alignItems="center">
          <VeroFormField
            as={DatePicker}
            name={startDatePropPath}
            id={startDatePropPath}
            placeholder="MM/DD/YYYY"
          />
          <span className="than-concat">and</span>
          <VeroFormField
            as={DatePicker}
            name={endDatePropPath}
            id={endDatePropPath}
            placeholder="MM/DD/YYYY"
            disabledDate={isDateDisabled}
          />
        </BetweenWrapper>
      ) : (
        <>
          <span className="than-concat">than</span>

          <div className="small-input">
            <Field
              id={timeValuePropPath}
              name={timeValuePropPath}
              label="Enter"
              as={Input}
              type="number"
              error={showError(timeValuePropPath, touched, errors)}
            />
          </div>

          <div className="medium-select">
            <Field
              name={timeUnitPropPath}
              id={timeUnitPropPath}
              label="Select"
              as={Select}
              error={showError(timeUnitPropPath, touched, errors)}
              onChange={(value) => setFieldValue(timeUnitPropPath, value)}
              onBlur={() => setFieldTouched(timeUnitPropPath, true)}
            >
              {Object.values(LUMEN_FILTER_CONDITION_TIME_UNIT_TYPE).map(
                (type) => (
                  <Select.Option key={type}>
                    {LUMEN_FILTER_CONDITION_TIME_UNIT_LABELS[type]}
                  </Select.Option>
                )
              )}
            </Field>
          </div>
        </>
      )}
    </BaseCondition>
  );
};

ConditionTime.propTypes = {
  ...commonFilterConditionPropTypes,
};

export default ConditionTime;
