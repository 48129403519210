import React, { useState } from "react";

import { useMagicLinksExportProperties } from "manager/hooks/api";
import { Modal } from "shared/components/Modal";

import useExportOptionsStep from "../hooks/useExportOptionsStep";
import useExportPropertiesStep from "../hooks/useExportPropertiesStep";

const STEPS = Object.freeze({
  FIRST: 1,
  SECOND: 2,
});

const ExportPropertiesModal = () => {
  const [currentStep, setStep] = useState(STEPS.FIRST);
  const { exportProperties } = useMagicLinksExportProperties();

  const onOptionsStepSubmit = () => {
    const nextStep = currentStep + 1;
    setStep(nextStep);
  };

  const optionsStep = useExportOptionsStep({
    title: "",
    exportTitle: "Which file type would you like to export? ",
    onSubmit: onOptionsStepSubmit,
    enabled: currentStep === STEPS.FIRST,
  });

  const propertiesStep = useExportPropertiesStep({
    title: "",
    exportTitle: "Which items would you like to export?",
    onSubmit: (exports) =>
      exportProperties({ exports, outputs: optionsStep.response }),
    enabled: currentStep === STEPS.SECOND,
    onBackClick: () => {
      const prevStep = currentStep - 1;
      setStep(prevStep);
    },
  });

  let step = {
    modalProps: {},
    modalBody: null,
  };

  if (currentStep === STEPS.FIRST) {
    step = optionsStep;
  } else if (currentStep === STEPS.SECOND) {
    step = propertiesStep;
  }

  return <Modal {...step.modalProps}>{step.modalBody}</Modal>;
};

export default ExportPropertiesModal;
