import styled from "@emotion/styled";

import { Button } from "shared/components/Button";
import FlexContainer from "shared/components/FlexContainer";
import { UnderlineLink } from "shared/components/Links";
import { COLORS, MEDIA_QUERIES } from "shared/config/constants";

interface ContainerProps {
  hasNotBoundProperties: boolean;
}

export const Container = styled(FlexContainer)<ContainerProps>`
  background-color: ${({ hasNotBoundProperties }) =>
    hasNotBoundProperties ? `${COLORS.lightOrange}` : `${COLORS.lightGreen}`};
  width: 100%;
  margin-top: 16px;
  padding: 16px 40px;
  gap: 50px;

  .bind-properties-btn {
    flex: 1;

    button {
      background-color: inherit;
      border-color: ${COLORS.button.primary.default.background};
    }
  }

  .dropdown-chevron {
    cursor: pointer;
  }

  ${MEDIA_QUERIES.w.ltLg} {
    padding-left: 16px;
    gap: 16px;
  }

  ${MEDIA_QUERIES.w.ltMd} {
    display: block;
    position: relative;
    padding: 16px;

    & > div {
      justify-content: space-between;
      margin-bottom: 16px;
      margin-right: 50px;
    }

    .dropdown-chevron {
      position: absolute;
      top: 24px;
      right: 24px;
    }
  }
`;

export const BarItemContainer = styled(FlexContainer)`
  .label {
    width: 105px;
    line-height: 130%;
  }

  .value {
    font-size: 28px;
    margin-left: 16px;
    border-bottom: 2px solid ${COLORS.primary};
    line-height: 1;
  }

  ${MEDIA_QUERIES.w.ltLg} {
    .label {
      font-size: 12px;
    }
    .value {
      font-size: 22px;
      margin-left: 8px;
    }
  }
`;

export const BindPropertiesLink = styled(UnderlineLink)`
  font-weight: 500;
`;

export const MobileButton = styled(Button)`
  margin-top: 16px;
  background-color: inherit;
`;
