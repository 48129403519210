import React from "react";

import { Col, Row } from "antd";

import Container from "shared/components/Container";
import Icon from "shared/components/Icon";
import Spacer from "shared/components/Spacer";
import Tile from "shared/components/Tile";

import {
  SummaryActionLink,
  SummaryLabel,
  SummaryValue,
  YardiHiddenCol,
  YardiSummaryTile,
} from "./styled";
import { SummaryProps } from "./yardiDashboard.config";

const SummaryWithAction = ({ isComplete, label, onEdit }) => (
  <>
    <Spacer size={Spacer.SIZES.sm} />
    <SummaryLabel>{label}</SummaryLabel>
    {!isComplete && (
      <>
        <SummaryValue disabled>Setup pending</SummaryValue>
        <SummaryActionLink onClick={onEdit}>SETUP</SummaryActionLink>
      </>
    )}
    {isComplete && (
      <>
        <SummaryValue>
          <Icon.CheckIcon className="summary-value-icon" />
          Complete
        </SummaryValue>
        <SummaryActionLink onClick={onEdit}>EDIT</SummaryActionLink>
      </>
    )}
  </>
);

const YardiDashboardSummary = ({
  numPropertiesConnected,
  numUnitsConnected,
  areDocumentTypesComplete,
  areChargeTypesComplete,
  areLeadSourcesComplete,
  areSyncSettingsComplete,
  editDocumentTypes,
  editChargeTypes,
  editLeadSources,
  editSyncSettings,
}: SummaryProps) => (
  <Container noMobilePaddingX noPaddingTop noPaddingBottom expand>
    <YardiSummaryTile alt data-testid="summary-tile">
      <Tile.Inner>
        <Row type="flex" justify="space-around">
          <Col xs={24} lg={7}>
            <Row>
              <Col xxl={12} lg={12} md={12}>
                <Spacer size={Spacer.SIZES.sm} />
                <SummaryLabel>PROPERTIES</SummaryLabel>
                <SummaryValue data-testid="properties-num-connected">
                  {numPropertiesConnected} connected
                </SummaryValue>
              </Col>
              <Col xxl={12} lg={12} md={12}>
                <Spacer size={Spacer.SIZES.sm} />
                <SummaryLabel>UNITS</SummaryLabel>
                <SummaryValue data-testid="units-num-connected">
                  {numUnitsConnected} connected
                </SummaryValue>
              </Col>
            </Row>
          </Col>
          <Col xs={24} lg={1}>
            <Tile.Divider alt horizontalLtLg />
          </Col>
          <Col xs={24} lg={16}>
            <Row>
              <Col xxl={6} lg={6} md={6} data-testid="document-types-setup">
                <SummaryWithAction
                  isComplete={areDocumentTypesComplete}
                  label="DOCUMENT TYPES"
                  onEdit={editDocumentTypes}
                />
              </Col>
              {/* V2-2208: hide the Charge Types setting */}
              <YardiHiddenCol
                xxl={6}
                lg={6}
                md={6}
                data-testid="charge-types-setup"
              >
                <SummaryWithAction
                  isComplete={areChargeTypesComplete}
                  label="CHARGE TYPES"
                  onEdit={editChargeTypes}
                />
              </YardiHiddenCol>
              <Col xxl={6} lg={6} md={6} data-testid="lead-sources-setup">
                <SummaryWithAction
                  isComplete={areLeadSourcesComplete}
                  label="LEAD SOURCES"
                  onEdit={editLeadSources}
                />
              </Col>
              <Col xxl={6} lg={6} md={6} data-testid="sync-settings-setup">
                <SummaryWithAction
                  isComplete={areSyncSettingsComplete}
                  label="SYNC SETTINGS"
                  onEdit={editSyncSettings}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Tile.Inner>
    </YardiSummaryTile>
  </Container>
);

export default YardiDashboardSummary;
