export enum VerificationTypes {
  LINKED_EMPLOYMENT = "LINKED_EMPLOYMENT",
  DOCUMENT_UPLOADED = "DOCUMENT_UPLOADED",
  LINK_BANK_ACCOUNT = "LINK_BANK_ACCOUNT",
  MANUAL_UPLOAD = "MANUAL_UPLOAD",
  UNEMPLOYED = "UNEMPLOYED",
}

enum IncomeAssetStatusCode {
  Pending = 1,
  Complete = 2,
  Confirmed = 3,
  Skipped = 4,
}

type IncomeAssetsAccounts = {
  id?: number;
  name?: string;
  accountType?: string;
  currentBalance?: number;
  availableBalance?: number;
  isIncluded?: boolean;
}[];

type IncomeAssetsIncomes = {
  frequency?: string;
  maxIncome?: number;
  meanIncome?: number;
  status?: string;
  lastPayment?: string;
  name?: string;
  isIncluded?: boolean;
  id?: number;
}[];

export enum EmploymentStatus {
  employed = "EM",
  employedNotStarted = "NS",
  unemployed = "NE",
}

export type IncomeAssetsSummaryResponse = {
  accounts?: IncomeAssetsAccounts;
  incomes?: IncomeAssetsIncomes;
  percentIncomeVerified?: number;
  totalIncome?: number;
  isInTheGreen?: boolean;
  averageMonthlyIncome?: string;
  numberOfActiveIncomeStreams?: number;
  statedEmployer?: string[];
  notes?: string;
  assetsStatus?: IncomeAssetStatusCode;
  totalAssets?: number;
};

export interface Employment {
  jobTitle?: string;
  employed?: boolean;
  company?: string;
  start?: Date;
  end?: Date;
  annualSalary?: number;
  monthlySalary?: number;
  skipped?: boolean;
  enabled?: boolean;
  verificationTypes?: VerificationTypes[];
}
